import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	templateUrl: './contrat-financement-loi-roulage.component.html'
})
export class ContratFinancementLoiRoulageComponent extends PageContentItem implements OnInit {
	/** Loi de roulage **/
	@Input() loiRoulage: any;

	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Suppression de la loi de roulage **/
	@Input() deleteLoiRoulage: Function;

	/** Indicateur de loi de roulage initiale **/
	isLoiInitiale: boolean = false;

	/** Loi de roulage sauvegardée **/
	savedLoiRoulage: any;

	/** Liste des types d'affichage des prix **/
	listeTypesAffichagePrix: Array<{ code: string,libelle: string }>;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(private contratFinancementService: ContratFinancementService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la loi initiale
		this.isLoiInitiale = this.contratFinancement.listeLoisRoulage.length == 0 || this.loiRoulage.reference == this.contratFinancement.reference;

		//Vérification de la loi initiale
		if (this.isLoiInitiale) {
			//Définition de la date et de la référence
			this.loiRoulage.date = this.contratFinancement.dateDebut;
			this.loiRoulage.reference = this.contratFinancement.reference;
		}

		//Récupération de la liste des types d'affichage du prix
		this.listeTypesAffichagePrix = this.contratFinancementService.getListeTypesAffichagePrix();

		//Initialisation de l'indicateur d'affichage du détail des loyers
		this.loiRoulage.isDetailLoyerDisplayed = this.loiRoulage.loyerFinancier || this.loiRoulage.loyerMaintenance || this.loiRoulage.loyerAssistance || this.loiRoulage.loyerGarantiePerteFinanciere || this.loiRoulage.loyerGestionCarburant || this.loiRoulage.loyerGestionSinistres || this.loiRoulage.loyerTelematique || this.loiRoulage.loyerPneumatiques || this.loiRoulage.loyerVehiculeRemplacement || this.loiRoulage.loyerAsssurance || this.loiRoulage.loyerAutres;

		//Enregistrement de la loi de roulage
		this.savedLoiRoulage = cloneDeep(this.loiRoulage);
	}

	/**
	 * Calcul des éléments d'une loi de roulage
	 */
	doCalculLoiRoulage() {
		//Réinitialisation des valeurs déterminées
		this.loiRoulage.loyerTotal = 0;
		this.loiRoulage.loyerAnnuel = 0;
		this.loiRoulage.coutDistance = 0;
		this.loiRoulage.coutHoraire = 0;

		//Vérification de la présence du loyer mensuel
		if (this.loiRoulage.loyerMensuel) {
			//Détermination du loyer annuel
			this.loiRoulage.loyerAnnuel = this.loiRoulage.loyerMensuel * 12;

			//Vérification de la présence de la durée
			if (this.loiRoulage.duree)
				//Détermination du loyer total
				this.loiRoulage.loyerTotal = this.loiRoulage.loyerMensuel * this.loiRoulage.duree;
		} else
			//Mise à zéro du loyer
			this.loiRoulage.loyerMensuel = 0;

		//Vérification de la présence du loyer total et de la distance
		if (this.loiRoulage.loyerTotal && this.loiRoulage.distance && this.loiRoulage.distance != 0 && this.contratFinancement.vehicule.uniteUsage == 'DISTANCE')
			//Détermination du coût au km
			this.loiRoulage.coutDistance = this.loiRoulage.loyerTotal / this.loiRoulage.distance;
		else if (this.loiRoulage.loyerTotal && this.loiRoulage.temps && this.loiRoulage.temps != 0 && this.contratFinancement.vehicule.uniteUsage == 'TEMPS')
			//Détermination du coût horaire
			this.loiRoulage.coutHoraire = this.loiRoulage.loyerTotal / this.loiRoulage.temps;
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Comparaison des lois de roulage
		return !isEqual(this.savedLoiRoulage,this.loiRoulage);
	}

	/**
	 * Enregistrement de la loi de roulage
	 */
	saveLoiRoulage() {
		//Calcul des informations complémentaires de la loi de roulage
		this.doCalculLoiRoulage();

		//Vérification du type de contrat
		if (this.contratFinancement.type.mode == 'ACQUISITION')
			//Définition de la date de début comme la date d'achat
			this.contratFinancement.dateDebut = this.contratFinancement.dateAchat;

		//Définition du contrat de financement
		this.loiRoulage.financement = this.contratFinancement;

		//Enregistrement de la loi de roulage
		this.contratFinancementService.saveLoiRoulage(this.contratFinancement.idContrat,this.contratFinancement.objectVersion,this.loiRoulage).pipe(first()).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du contrat de financement
					this.contratFinancement = result.data.contrat;

					//Fermeture de l'édition
					this.close({
						contratFinancement: this.contratFinancement,
						loiRoulage: result.data.loiRoulage,
						listeEcheances: result.data.listeEcheances
					});
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du fournisseur
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));

					//Vérification du véhicule
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.vehicule'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification de la validité de la date
	 */
	isDateValid(): boolean {
		//Vérification de l'absence de loi de roulage sur le même mois
		return !this.contratFinancement.listeLoisRoulage?.some(l => this.loiRoulage.idLoiRoulage !== l.idLoiRoulage && moment(this.loiRoulage.date).month() == moment(l.date).month() && moment(this.loiRoulage.date).year() == moment(l.date).year());
	}
}