import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FieldKeyValuePipe } from './field-key-value.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [FieldKeyValuePipe],
	exports: [FieldKeyValuePipe]
})
export class FieldKeyValueModule { }
