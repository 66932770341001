import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';

import { ChatbotComponent } from './chatbot.component';
import { ChatbotService } from './chatbot.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { DisplayMoreModule } from 'src/app/share/components/display-more/display-more.module';

@NgModule({
	imports: [CommonModule,DragDropModule,RouterModule,TranslateModule,FormsModule,IconModule,DisplayMoreModule,MarkdownModule],
	declarations: [ChatbotComponent],
	exports: [ChatbotComponent],
	providers: [ChatbotService]
})
export class ChatbotModule { }