import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LicenceConsommationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private messagingService: MessagingService,private translateService: TranslateService) { }

	/**
	 * Chargement d'une consommation de licence
	 */
	public loadLicenceConsommation(idLicenceConsommation: number): Observable<Result> {
		//Chargement d'une consommation de licence
		return this.http.post<Result>(`${environment.baseUrl}/controller/Licence/loadLicenceConsommation/${idLicenceConsommation}`,null);
	}

	/**
	 * Suppression d'une consommation de licence
	 */
	public deleteLicenceConsommation(licenceConsommation: any): Observable<Result> {
		//Suppression d'une consommation de licence
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Licence/deleteLicenceConsommation/${licenceConsommation.idLicenceConsommation}`);
	}

	/**
	 * Calcul des licences
	 */
	public computeLicenceConsommations(): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Licence/computeLicenceConsommations`,
			progressConfig: {
				libelle: this.translateService.instant('licence.consommation.computation.progression'),
				icone: 'autorenew'
			}
		}).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}
}