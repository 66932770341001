import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { UserCategorieService } from './user-categorie.service';

@Component({
	selector: 'user-categorie-add',
	templateUrl: './user-categorie-add.component.html'
})
export class UserCategorieAddComponent extends AutocompleteAddComponent {
	/** Catégorie d'utilisateur **/
	categorie: any = {
		actif: true
	};

	/** Taille de la popup **/
	public static modalSize: string = '';

	/**
	 * Constructeur
	 */
	constructor(private userCategorieService: UserCategorieService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<UserCategorieAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem(): void {
		//Enregistrement de l'élément
		this.userCategorieService.saveCategorie(this.categorie).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de la catégorie
					this.addedItem = result.data.categorie;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
