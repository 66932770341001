<form #genreForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!genre.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot()">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>vehicule.genre.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="genre.libelle" maxlength="300" required [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>vehicule.genre.reference</label>
							<div>
								<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="genre.reference" maxlength="100" required [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
							<label translate>vehicule.genre.pays</label>
							<div>
								<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="genre.pays" required [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'"></autocomplete>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>vehicule.genre.actif.libelle</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="genre.actif" [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'"><span translate>vehicule.genre.actif.description</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
							<label translate>vehicule.genre.type.libelle</label>
							<div class="half">
								<selectpicker name="type" #typeField="ngModel" [(ngModel)]="genre.type" required [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'">
									<mat-option *ngFor="let type of listeTypes" [value]="type"><span [translate]="'vehicule.genre.type.'+type.toUpperCase()"></span></mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': modeAffichagePrixField.invalid }">
							<label translate>vehicule.genre.modeAffichagePrix.libelle</label>
							<div class="half">
								<selectpicker name="modeAffichagePrix" #modeAffichagePrixField="ngModel" [(ngModel)]="genre.modeAffichagePrix" required [disabled]="genre.typeSource == 'EXTERNE_CATALOGUE'">
									<mat-option *ngFor="let modeAffichagePrix of listeModeAffichagePrix" [value]="modeAffichagePrix"><span [translate]="'vehicule.genre.modeAffichagePrix.'+modeAffichagePrix.toUpperCase()"></span></mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: genreForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteGenre();" *ngIf="genre?.idGenre && (null | right:'suppression') && rightService.isRoot() && genre.typeSource != 'EXTERNE_CATALOGUE'">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveGenre();" *ngIf="(null | right:'creation') && rightService.isRoot()" [disabled]="genreForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>