import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { NatureEditComponent } from './nature-edit.component';
import { NatureFournisseurListItemComponent } from './nature-fournisseur-list-item.component';
import { NatureFournisseurListComponent } from './nature-fournisseur-list.component';
import { NatureListItemComponent } from './nature-list-item.component';
import { NatureListComponent } from './nature-list.component';
import { NatureCompteListComponent } from './nature-compte-list.component';
import { NatureComponent } from './nature.component';
import { NatureService } from './nature.service';
import { NatureCodeListComponent } from './nature-code-list.component';
import { NatureCodeListItemComponent } from './nature-code-list-item.component';
import { NatureFieldListComponent } from './nature-field-list.component';
import { ComptabiliteModule } from 'src/app/components/comptabilite/comptabilite.module';

@NgModule({
	imports: [ShareModule,ComptabiliteModule],
	declarations: [NatureListComponent,NatureListItemComponent,NatureComponent,NatureEditComponent,NatureFournisseurListComponent,NatureFournisseurListItemComponent,NatureCompteListComponent,NatureCodeListComponent,NatureCodeListItemComponent,NatureFieldListComponent],
	providers: [NatureService]
})
export class NatureModule {}
