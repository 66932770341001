<div class="container dashboard">
	<div *ngIf="referentielList" class="row">
		<div *ngFor="let item of referentielList.listeItems" class="col-sm-6 col-md-4">
			<div class="mini-charts-item clickable" matRipple (click)="this.layoutService.goToByUrl(item.redirectionUrl)">
				<div class="chart stats-bar icon">
					<icon *ngIf="!item.icone?.libelle" class="icon-hc-5x" [name]="item.icone" [type]="item.iconeType"></icon>
					<span *ngIf="item.icone?.libelle" style="font-size: 44px;" [translate]="item.icone.libelle"></span>
				</div>
				<div class="count">
					<div class="top-container">
						<div class="chart stats-bar icon">
							<icon *ngIf="!item.icone?.libelle" class="icon-hc-4x" [name]="item.icone" [type]="item.iconeType"></icon>
							<span *ngIf="item.icone?.libelle" style="font-size: 44px;" [translate]="item.icone.libelle"></span>
						</div>
						<h2>{{ item.counter }}</h2>
					</div>
					<div class="chart-label">
						<strong [translate]="item.libelle"></strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>