<div class="pull-left lv-avatar">{{ data.duree }}</div>
<div class="media-body">
	<div class="lv-title">
		<span>
			<a [routerLink]="[]" (click)="extraOptions?.addLoiRoulage?.(data,deleteLoiRoulage)">
				<span *ngIf="data.reference">{{ data.reference }}</span>
				<span *ngIf="!data.reference" translate>contrat.financement.loiRoulage.referenceNonDefinie</span>
			</a>
		</span>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="extraOptions.contratFinancement.type.mode != 'ACQUISITION'"><span translate>contrat.financement.loiRoulage.date</span>&#160;:&#160;{{ data.date | date:'shortDate' }}</li>
		<li><span translate>contrat.financement.loiRoulage.item</span>&#160;:&#160;<span translate [translateParams]="{ duree: data.duree,distance: (data.financement.vehicule.uniteUsage == 'DISTANCE' ? (data.distance | number) : (data.temps | number)),unite: data.financement.vehicule.uniteUsage == 'DISTANCE' ? (data.financement.vehicule?.unite?.libelleCourt || extraOptions.user?.unite?.libelleCourt) : ('duree.heure.libelleCourt' | translate) }">contrat.financement.loiRoulage.dureeMois</span></li>
		<li *ngIf="extraOptions.contratFinancement.type.mode != 'ACQUISITION'"><span translate>contrat.financement.loiRoulage.loyerMensuel</span>&#160;:&#160;{{ data.loyerMensuel | currency:'.2-2':extraOptions.contratFinancement.devise }}&#160;({{ 'contrat.typeAffichagePrix.short.' + data.typeAffichagePrix | translate }})</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && (data.reference != extraOptions.contratFinancement.reference || extraOptions.contratFinancement.listeLoisRoulage?.length == 1)">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="deleteLoiRoulage(data);" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>