import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { GenreListItemComponent } from './genre-list-item.component';
import { GenreListComponent } from './genre-list.component';
import { GenreComponent } from './genre.component';
import { GenreService } from './genre.service';

@NgModule({
	imports: [ShareModule],
	declarations: [GenreListComponent,GenreListItemComponent,GenreComponent],
	providers: [GenreService]
})
export class GenreModule { }
