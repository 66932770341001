import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReleveCompteurContentComponent } from './releve-compteur-content.component';
import { ReleveCompteurListItemComponent } from './releve-compteur-list-item.component';
import { ReleveCompteurListComponent } from './releve-compteur-list.component';
import { ReleveCompteurComponent } from './releve-compteur.component';
import { ReleveCompteurService } from './releve-compteur.service';
import { VehiculeReleveCompteurListItemComponent } from './vehicule-releve-compteur-list-item.component';
import { VehiculeReleveCompteurListComponent } from './vehicule-releve-compteur-list.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ReleveCompteurListComponent,ReleveCompteurListItemComponent,ReleveCompteurComponent,VehiculeReleveCompteurListComponent,VehiculeReleveCompteurListItemComponent,ReleveCompteurContentComponent],
	exports: [ReleveCompteurContentComponent],
	providers: [ReleveCompteurService]
})
export class ReleveCompteurModule { }