<form #structureExportDefinitionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20" *ngIf="listeDetails.length > 1">
	<div class="card detail">
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || isRestricted">
				<h4><span translate>structureExport.definition.title</span></h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label translate>structureExport.fichier.selection</label>
							<div class="half">
								<selectpicker name="selectedDetail" [(ngModel)]="selectedDetail">
									<mat-option *ngFor="let detail of listeDetails" [value]="detail">{{ detail.libelle || ('structureExport.typeDetail.FICHIER' | translate) }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
</form>
<div class="card" *ngIf="selectedDetail">
	<div class="card-body p-t-20">
		<div class="alert alert-danger" *ngIf="selectedDetail.listeItems?.length && !selectedDetail.valid">
			<icon name="warning"></icon>
			<div>
				<strong translate>structureExport.definition.alerte.rubriqueAnomalie.title</strong>
				<div translate>structureExport.definition.alerte.rubriqueAnomalie.message</div>
			</div>
		</div>

		<div class="structure-export definition">
			<structure-export-definition-detail [structureExport]="structureExport" [detail]="selectedDetail" [fichierDetail]="selectedDetail" [isRestricted]="isRestricted" (onStructureExportUpdate)="onStructureExportUpdate($event)" (onRefresh)="onRefresh()"></structure-export-definition-detail>
		</div>
	</div>
</div>