import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { RightPipe } from './right.pipe';
import { RightService } from './right.service';

@NgModule({
	imports: [StoreModule,CoreComponentsModule],
	exports: [RightPipe],
	declarations: [RightPipe],
	providers: [RightService,RightPipe]
})
export class RightModule {}