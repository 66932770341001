import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PlanEntretienDetailListItemComponent } from './plan-entretien-detail-list-item.component';

@Component({
	selector: 'plan-entretien-detail-list',
	templateUrl: './plan-entretien-detail-list.component.html'
})
export class PlanEntretienDetailListComponent extends PageContentItem implements OnInit {
	/** Plan d'entretien **/
	@Input() planEntretien: any;

	/** Fonction d'édition **/
	@Input() addDetail: Function;

	/** Resumé **/
	@Input() resume: { nbDetails: number };

	/** Données **/
	liste: ListView<any,PlanEntretienDetailListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PlanEntretienDetailListItemComponent>({
			uri: () => `/controller/VehiculeReferentiel/filtrePlanEntretienDetails/${this.planEntretien.idPlanEntretien}`,
			component: PlanEntretienDetailListItemComponent,
			isLocal: true,
			extraOptions: {
				addDetail: this.addDetail
			},
			listeFilters: [{
				clef: 'typeEntretien',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'typeEntretien'
				},
				isDefault: true
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idPlanEntretienDetail > 0)
			//Mise à jour des compteurs
			this.resume.nbDetails--;
	}
}