import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TenantAuthConfigListItemComponent } from './tenant-auth-config-list-item.component';

@Component({
	selector: 'tenant-auth-config-list',
	templateUrl: './tenant-auth-config-list.component.html'
})
export class TenantAuthConfigListComponent extends PageContentItem implements OnInit {
	/** Tenant **/
	@Input() tenant: any;

	/** Fonction d'édition **/
	@Input() addAuthConfig: () => void;

	/** Résumé **/
	@Input() resume: { nbAuthConfigs: number };

	/** Données **/
	liste: ListView<any,TenantAuthConfigListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantAuthConfigListItemComponent>({
			uri: `/controller/AuthConfig/filtreAuthConfigs/${this.tenant.idTenant}`,
			component: TenantAuthConfigListItemComponent,
			isLocal: true,
			extraOptions: {
				addAuthConfig: this.addAuthConfig,
				resume: this.resume
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			getKeyFieldName: () => 'idConfig'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idConfig > 0)
			//Mise à jour des compteurs
			this.resume.nbAuthConfigs--;
	}
}