import { Component,OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { DocumentService } from 'src/app/share/components/document/document.service';
import { EtatLieuxService } from 'src/app/components/vehicule/etat-lieux/etat-lieux.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConducteurEtatLieuxEditComponent } from './conducteur-etat-lieux-edit.component';

@Component({
	templateUrl: './conducteur-etat-lieux-list-item.component.html'
})
export class ConducteurEtatLieuxListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Information sur l'élément **/
	public statut: { libelle: string,code: string,icone: string,iconeType?: string };

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxService: EtatLieuxService,private documentService: DocumentService,private pageContentService: PageContentService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération du statut associé à l'état des lieux
		this.statut = this.etatLieuxService.getListeStatuts().find(s => s.code == this.data.statut);
	}

	/**
	 * Affichage de l'état des lieux
	 */
	showEtatLieux() {
		//Chargement complet de l'état des lieux
		this.etatLieuxService.loadEtatLieux(this.data.idEtatLieux).pipe(switchMap(result => {
			//Ouverture du composant d'édition
			return this.pageContentService.open(ConducteurEtatLieuxEditComponent,{
				data: {
					etatLieux: result?.data?.etatLieux
				}
			},'sub');
		})).subscribe((data: { etatLieux: any }) => {
			//Vérification de la présence de données
			if (data)
				//Rechargement de la liste
				this.liste.refresh();
		});
	}

	/**
	 * Génération d'un document PDF
	 */
	generateDocument() {
		//Génération d'un document PDF
		this.documentService.generateDocument('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux);
	}
}