import { Component,Input,OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './template-mail-rubrique-list.component.html'
})
export class TemplateMailRubriqueListComponent implements OnInit {
	/** Template de mail **/
	@Input() templateMail: any;

	/** Résumé **/
	@Input() resume: { nbRubriques: number };

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Modification des rubriques **/
	@Input() editListeRubriques: (isAddNewRubrique?: boolean) => void;

	/**
	 * Constructeur
	 */
	constructor(private domSanitizer: DomSanitizer,public rightService: RightService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Parcours des rubriques
		this.templateMail.listeTemplateStructure.map(content => content.listeTemplateBloc[0]).forEach(c => {
			//Récupération du contenu brut
			c.safeContenu = this.domSanitizer.bypassSecurityTrustHtml(c.contenu);
		});
	}
}