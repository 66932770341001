<form #codeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>fournisseur.code.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot()">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>fournisseur.code.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="code.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>fournisseur.code.reference</label>
							<div>
								<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="code.reference" maxlength="100" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': nature.invalid }">
							<label translate>fournisseur.code.nature</label>
							<div>
								<autocomplete name="nature" type="fournisseurNature" #nature="ngModel" [(ngModel)]="code.nature" required></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>fournisseur.code.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="code.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteCode();" *ngIf="code?.idCode && (null | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveCode();" *ngIf="null | right:'creation'" [disabled]="codeForm.invalid">
			<span [translate]="'actions.'+(code?.idCode ? 'enregistrer' : 'continuer')"></span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: codeForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>