import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { NatureListItemComponent } from './nature-list-item.component';
import { NatureService } from './nature.service';

@Component({
	templateUrl: './nature-list.component.html'
})
export class NatureListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,NatureListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,public natureService: NatureService,private router: Router) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,NatureListItemComponent>({
			uri: `/controller/Fournisseur/filtreNatures`,
			title: this.translateService.instant('nature.liste.title'),
			component: NatureListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'idNature',
				title: this.translateService.instant('search.identifiant'),
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'listeMetiers',
				title: this.translateService.instant('search.fournisseurMetier'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurMetier'
				}
			},{
				clef: 'typeReconciliation',
				type: TypeFilter.LONG,
				listeValues: this.natureService.getListeTypesLien()
			},{
				clef: 'typeFamille',
				type: TypeFilter.LONG,
				listeValues: this.natureService.getListeTypesFamille()
			}],
			extraOptions: {
				listeTypesFamille: this.natureService.getListeTypesFamille(),
				listeTypesLien: this.natureService.getListeTypesLien()
			},
			doMainAction: this.rightService.hasRight(null,'creation') && this.rightService.isRoot() && (() => {
				//Redirection vers la création d'une nature
				this.router.navigate(['/Fournisseur/loadNature',0]);
			}),
			getKeyFieldName: () => 'idNature'
		});
	}
}