import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConversationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private confirmService: ConfirmService,private translateService: TranslateService,private messagingService: MessagingService) {}

	/**
	 * Chargement d'une conversation
	 */
	public loadConversation(idSession: number): Observable<Result> {
		//Chargement d'une conversation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chatbot/loadConversation/${idSession}`,null);
	}

	/**
	 * Suppression d'une conversation
	 */
	public deleteConversation(session: any): Observable<Result> {
		//Suppression d'une conversation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Chatbot/deleteConversation/${session.idSession}`);
	}

	/**
	 * Suppressions des éléments selectionnés
	 */
	public deleteAllForSelection(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmationMasse')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed)
					//Lancement en masse
					this.doDeleteAllForSelection(messagingOptions,subject);
			}
		});

		return subject;
	}

	/**
	 * Déclenchement de la suppression en masse
	 */
	private doDeleteAllForSelection(messagingOptions: MessagingOptions,subject: Subject<any>) {
		let messaging$: MessagingObservables;

		//Démarrage de la suppression par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}
}