<div class="pull-left lv-avatar">{{ data?.user?.nom?.substring(0,1)?.toUpperCase() + data?.user?.prenom?.substring(0,1)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addUser?.(data,deleteLienUser)">{{ data.user | display:'user' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idLien">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.user?.enabled ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idLien && ((null | right:'suppression') && !extraOptions.restriction.isRestricted || (TypeDroit.ADMIN_UTILISATEUR | right:'consultation'))">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_UTILISATEUR | right:'consultation'"><a [routerLink]="[]" (click)="goToUser();"><span translate>comptabilite.compteAuxiliaire.actions.accederUser</span></a></li>
			<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteLienUser();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>