import { AfterViewInit,Component,ElementRef,forwardRef,Inject,Input,LOCALE_ID,OnDestroy,OnInit } from '@angular/core';
import { ControlValueAccessor,NG_VALIDATORS,NG_VALUE_ACCESSOR } from '@angular/forms';

import { Telephone } from 'src/app/domain/phone-number/telephone';
import { GLOBALS } from 'src/app/utils/globals';

@Component({
	selector: '[phoneNumber]',
	template: '',
	exportAs: 'phoneNumber',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PhoneNumberComponent),
		multi: true
	},{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => PhoneNumberComponent),
		multi: true
	}]
})
export class PhoneNumberComponent implements OnInit,OnDestroy,AfterViewInit,ControlValueAccessor {
	/** Etat du composant **/
	@Input() disabled?: boolean;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Observateur de changements sur le formulaire **/
	private fieldsetMutationObserver: MutationObserver;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Téléphone courant **/
	_value: Telephone;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,@Inject(LOCALE_ID) private locale: string) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let fieldset;

		//Initialisation de la bibliothèque
		GLOBALS.$(this.elementRef.nativeElement).intlTelInput({
			defaultCountry: this.locale?.split('-')[0] || 'fr',
			preferredCountries: [this.locale?.split('-')[0]]
		});

		//Recherche du formulaire le plus proche
		fieldset = this.elementRef.nativeElement.closest('fieldset');

		//Vérification de la présence d'un formulaire
		if (!this.disabled && fieldset) {
			//Lecture du champ de désactivation du formulaire
			this.disabled = GLOBALS.$(fieldset).is(':disabled');

			//Création de l'observateur d'évènements sur le formulaire
			this.fieldsetMutationObserver = new MutationObserver(() => {
				//Lecture du champ de désactivation du formulaire
				this.disabled = GLOBALS.$(fieldset).is(':disabled');
			});

			//Ecoute des changements
			this.fieldsetMutationObserver.observe(fieldset,{
				attributes: true,
				attributeFilter: ['disabled']
			});
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Arrêt de l'observation des changements
		this.fieldsetMutationObserver?.disconnect();

		//Destruction du composant
		GLOBALS.$(this.elementRef.nativeElement).intlTelInput('destroy');
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(value: any) {
		//Mise à jour de la valeur
		this.value = value;

		//Vérification de la présence d'une valeur
		if (value) {
			//Initialisation des variables du composant
			GLOBALS.$(this.elementRef.nativeElement).intlTelInput('selectCountry',value.codePays);
			GLOBALS.$(this.elementRef.nativeElement).intlTelInput('setNumber',value.numero);
		}
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;
		});

		//Ajout d'un intercepteur (changement / appui sur une touche)
		GLOBALS.$(this.elementRef.nativeElement).on('change keyup',() => {
			//Traitement des changements dans la saisie
			this.onInputChange();
		});

		//Vérification de l'état du composant
		if (this.disabled) {
			//Désactivation de l'élément
			GLOBALS.$(this.elementRef.nativeElement.parentElement).find('.selected-flag').off('click');
			GLOBALS.$(this.elementRef.nativeElement.parentElement).find('.selected-flag .arrow').addClass('hide');
			GLOBALS.$(this.elementRef.nativeElement.parentElement).find('input').attr('disabled','true')
		}
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);

			//Vérification de la présence d'une valeur
			if (value != null)
				//Définition de la valeur
				this._value = value
		}
	}

	/**
	 * Interception d'un changement dans la saisie
	 */
	onInputChange() {
		let country: any;
		let number: any;
		let isValidNumber: boolean = true;

		//Récupération des informations depuis le composant
		country = GLOBALS.$(this.elementRef.nativeElement).intlTelInput('getSelectedCountryData');
		number = GLOBALS.$(this.elementRef.nativeElement).intlTelInput('getNumber');
		isValidNumber = GLOBALS.$(this.elementRef.nativeElement).intlTelInput('isValidNumber');

		//Définition du téléphone
		this.value = isValidNumber ? {
			...this._value,
			numero: this.elementRef?.nativeElement?.value,
			codePays: country?.iso2,
			indicatif: country?.dialCode,
			numeroBrut: number?.replace(/[^\d]/,'')
		} : null;
	}


	/**
	 * Validation du champs de saisie
	 */
	validate() {
		let number: any;
		let isValidNumber: boolean;

		//Récupération de la valeur
		number = this.elementRef?.nativeElement?.value;

		//Vérification de la validité du numéro depuis le composant
		isValidNumber = GLOBALS.$(this.elementRef.nativeElement).intlTelInput('isValidNumber');

		//Vérification de la validité du champ
		return number?.length != 0 && !isValidNumber && {
			invalid: true
		};
	}
}