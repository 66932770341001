<div class="container-fluid">
	<form #offreForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>offre.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="offre.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>offre.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="offre.reference" maxlength="100" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label translate>offre.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="offre.actif"><span translate>offre.actif.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>

					<basket [model]="offre" propertyName="listeRevendeurs" colWidthLabel="2" colWidthAutocomplete="6" type="revendeur" [labelAutocomplete]="'offre.revendeur.ajout' | translate" [labelListe]="'offre.revendeur.liste' | translate"></basket>

					<div class="form-group">
						<label translate>offre.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="offre.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteOffre();" *ngIf="offre?.idOffre && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveOffre(offre,close);" *ngIf="null | right:'creation'" [disabled]="offreForm.invalid">
				<span [translate]="'actions.' + (offre?.idOffre ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="offre?.idOffre" [formDetectChanges]="{ doAction: close,isDirty: offreForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>