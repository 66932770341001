<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idEcheance">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data}">{{ data?.affectation?.user?.nom?.substring(0,1)?.toUpperCase() + data?.affectation?.user?.prenom?.substring(0,1)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ (data?.dateEcheance | date:'MMMM YYYY' | titlecase) + ' - ' + data?.affectation?.user?.prenom + ' '+ data?.affectation?.user?.nom + ' (' + data?.affectation?.user?.matricule + (data?.affectation?.user?.societe ? ' - ' + data?.affectation?.user?.societe.libelle : '') + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idEcheance">
		<li *ngIf="data.affectation?.vehicule"><span translate>affectationEcheance.vehicule</span>&#160;:&#160;<span>{{ data.affectation?.vehicule?.reference + (data.affectation?.vehicule?.numeroInterne ? ' - ' + data.affectation?.vehicule?.numeroInterne : '') }}</span></li>
		<li *ngIf="data.affectation?.dateDebut"><span translate>affectationEcheance.debutAffectation</span>&#160;:&#160;<span>{{ data.affectation?.dateDebut | date:'short' }}</span></li>
		<li *ngIf="data.affectation?.dateFin"><span translate>affectationEcheance.finAffectation</span>&#160;:&#160;<span>{{ data.affectation?.dateFin | date:'short' }}</span></li>
		<li *ngIf="data.affectation?.montantAvantageNature"><span translate>affectationEcheance.montantAvantageNature</span>&#160;:&#160;<span>{{ data.affectation?.montantAvantageNature || 0 | currency:'.2-2':user.devise }}</span></li>
		<li *ngIf="data.affectation?.montantParticipation"><span translate>affectationEcheance.montantParticipation</span>&#160;:&#160;<span>{{ data.affectation?.montantParticipation || 0 | currency:'.2-2':user.devise }}</span></li>
		<li *ngIf="data.typeCalculAvantageNature && data.plafondPrixBase != null"><span translate>affectationEcheance.typeCalculAvantageNature.item</span>&#160;:&#160;<span [translate]="'affectationEcheance.typeCalculAvantageNature.' + (data.typeCalculAvantageNature || 'NON_DEFINI')"></span></li>
		<li *ngIf="data.typeCalculAvantageNature && data.plafondPrixBase != null">
			<span><span translate>affectationEcheance.avantageNatureCalcule</span>&#160;:&#160;</span>
			<span *ngIf="data.typeCalculAvantageNature == 'ACHAT_AVEC_CARBURANT'">{{ (data.montantAvantageNatureAchatAvecCarburant || 0) | currency:'.2-2':user?.devise }}</span>
			<span *ngIf="data.typeCalculAvantageNature == 'ACHAT_SANS_CARBURANT'">{{ (data.montantAvantageNatureAchatSansCarburant || 0) | currency:'.2-2':user?.devise }}</span>
			<span *ngIf="data.typeCalculAvantageNature == 'LOCATION_AVEC_CARBURANT'">{{(data.montantAvantageNatureLocationAvecCarburant || 0) | currency:'.2-2':user?.devise }}</span>
			<span *ngIf="data.typeCalculAvantageNature == 'LOCATION_SANS_CARBURANT'">{{ (data.montantAvantageNatureLocationSansCarburant || 0) | currency:'.2-2':user?.devise }}</span>
		</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idEcheance && ((TypeDroit.ADMIN_VEHICULE | right:'consultation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation')) && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_VEHICULE | right:'consultation'"><a [routerLink]="[]" (click)="goToVehicule();"><span translate>affectationEcheance.actions.accederVehicule</span></a></li>
			<li *ngIf="TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation'"><a [routerLink]="[]" (click)="goToConducteur();"><span translate>affectationEcheance.actions.accederConducteur</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<affectation-echeance [affectationEcheance]="data" (onObjectChange)="onObjectChange($event)"></affectation-echeance>
</div>