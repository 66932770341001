import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';

@Component({
	selector: 'taxe-taux-list',
	templateUrl: './taxe-taux-list.component.html'
})
export class TaxeTauxListComponent extends PageContentItem {
	/** Taxe courante **/
	@Input() taxe: any;

	/** Ajout d'un taux **/
	@Input() addTaux:  Function;

	/** Enregistrement de la taxe **/
	@Input() saveTaxe: Function;

	/**
	 * Constructeur
	 */
	constructor(private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(taux: any) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.taxe.Taux',taux.idTaux);
	}

	/**
	 * Suppression du taux
	 */
	deleteTaux(index: number) {
		let taxe: any;

		//Duplication de l'objet avant enregistrement
		taxe = cloneDeep(this.taxe);

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed)
		).subscribe({
			next: () => {
				//Suppression du taux
				taxe.listeTaux.splice(index,1);

				//Enregistrement de la taxe
				this.saveTaxe(taxe,this.close);
			}
		});
	}
}