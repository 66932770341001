/**
 * Enumération des types de statuts
 */
export enum TypeStatut {
	/** Enumération **/
	NOUVELLE_DEMANDE = 'NOUVELLE_DEMANDE',
	ANOMALIE = 'ANOMALIE',
	A_TRANSMETTRE = 'A_TRANSMETTRE',
	TRANSMIS = 'TRANSMIS',
	INTEGREE = 'INTEGREE',
	ENVOI_PAPIER = 'ENVOI_PAPIER'
}