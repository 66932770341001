import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'entretien-intervention-add',
	templateUrl: './entretien-intervention-add.component.html'
})
export class EntretienInterventionAddComponent implements OnInit {
	/** Entretien courant **/
	@Input() entretien: any;

	/** Lien **/
	link: any;

	/** Résultat **/
	result: { link: any };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<EntretienInterventionAddComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation du détail
		this.link = {
			vehicule: this.entretien.vehicule,
			planEntretienDetail: null,
			statut: 'REALISE',
			checked: true
		};
	}

	/**
	 * Enregistrement de l'intervention
	 */
	public saveLink() {
		//Définition du résultat
		this.result = {
			link: this.link
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}