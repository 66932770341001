import { Component,EventEmitter,Input,Output } from '@angular/core';

@Component({
	selector: 'structure-export-rubrique-fonction-si-condition',
	templateUrl: './structure-export-rubrique-fonction-si-condition.component.html'
})
export class StructureExportRubriqueFonctionSiConditionComponent {
	/** Fonction parent **/
	@Input() parentFonction: any;

	/** Fonction racine **/
	@Input() rootFonction: any;

	/** Position de la condition **/
	@Input() idxCondition: number;

	/** Parent **/
	@Input() parent: any;

	/** Clé du parent **/
	@Input() parentKey: string;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Changement du type de rubrique **/
	@Output() onTypeRubriqueChange : EventEmitter<{ parent: any,parentKey: string }> = new EventEmitter<{ parent: any,parentKey: string }>();

	/**
	 * Initialisation de la condition
	 */
	initCondition(root: any,key: string) {
		//Vérification de la condition
		if (!root[key])
			//Création d'une nouvelle condition
			root[key] = {};

		//Création d'une nouvelle liste si nécessaire
		root[key].listeItems = root[key].listeItems || [];

		//Ajout d'un nouvel élément
		root[key].listeItems.push({
			operateur: 'EQUAL',
			valeur: null
		});
	}

	/**
	 * Initialisation d'une nouvelle condition
	 */
	initGroupeConditions(root: any,key: string) {
		//Vérification de la condition
		if (!root[key])
			//Création d'une nouvelle condition
			root[key] = {};

		//Création d'une nouvelle liste si nécessaire
		root[key].listeItems = root[key].listeItems || [];

		//Ajout d'un nouvel élément
		root[key].listeItems.push({
			condition: {},
			operateur: null,
			valeur: null
		});
	}

	/**
	 * Suppression de la condition
	 */
	deleteCondition(parent: any,index: number) {
		//Suppression de l'élément
		parent.condition.listeItems.splice(index,1);

		//Vérification de la liste des éléments
		if (!parent.condition.listeItems.length)
			//Suppression de la condition
			parent.condition = null;
	}
}