import { Action } from 'src/app/domain/action';

/**
 * Export des actions
 */
export const UPDATE_OPEN_CREATION = 'UPDATE_OPEN_CREATION';
export const UPDATE_SELECTED_SELECTOR = 'UPDATE_SELECTED_SELECTOR';

/**
 * Interface
 */
export interface NavigationState {
	/** Données **/
	openCreation?: string;
	mapSelectedSelectors: { [state: string]: any };
}

/**
 * Création du reducer
 */
export function navigationReducer(state: NavigationState = { mapSelectedSelectors: {} },action: Action): NavigationState {
	//Vérification de l'action
	switch (action.type) {
	case UPDATE_OPEN_CREATION:
		//Définition du state pour lequel créer un élément lors de son ouverture
		state = {
			...state,
			openCreation: action.payload
		};
		break;
	case UPDATE_SELECTED_SELECTOR:
		//Définition du sélecteur sélectionné pour le state
		state = {
			...state,
			mapSelectedSelectors: {
				...state.mapSelectedSelectors,
				[action.payload.state]: action.payload.selector
			}
		};
		break;
	}

	//Retour de l'état
	return state;
}