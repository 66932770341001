import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ImportService } from './import.service';

@Component({
	selector: 'import-relance',
	templateUrl: './import-relance.component.html'
})
export class ImportRelanceComponent implements AfterContentChecked,OnInit {
	/** Données **/
	@Input() typeImport: 'DELTA' | 'FULL' = 'FULL';

	/** Types d'import **/
	listeTypesImport: Array<{ code: string,libelle: string }>;

	/** Résultat **/
	result: { typeImport: 'DELTA' | 'FULL' };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ImportRelanceComponent>,public importService: ImportService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition des types d'import
		this.listeTypesImport = this.importService.getListeTypesImport();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du mode d'import pour la relance
	 */
	public saveTypeImport() {
		//Définition du résultat
		this.result = {
			typeImport: this.typeImport
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}