<div class="modal-header">
	<h4 class="modal-title" translate>upgrade.item.log.title</h4>
</div>
<div class="modal-body m-0">
	<div class="card">
		<ngx-codemirror [(ngModel)]="log" [options]="editorOptions"></ngx-codemirror>
	</div>
</div>
<div class="modal-footer">
	<span class="pull-left">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
	</span>
</div>