import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PlanComptableCompteListItemComponent } from './plan-comptable-compte-list-item.component';
import { PlanComptableCompteAuxiliaireListItemComponent } from './plan-comptable-compte-auxiliaire-list-item.component';
import { PlanComptableCompteListComponent } from './plan-comptable-compte-list.component';
import { PlanComptableCompteAuxiliaireListComponent } from './plan-comptable-compte-auxiliaire-list.component';
import { PlanComptableEditComponent } from './plan-comptable-edit.component';
import { PlanComptableListItemComponent } from './plan-comptable-list-item.component';
import { PlanComptableListComponent } from './plan-comptable-list.component';
import { PlanComptableComponent } from './plan-comptable.component';
import { PlanComptableService } from './plan-comptable.service';
import { PlanComptableCompteEditComponent } from './plan-comptable-compte-edit.component';
import { PlanComptableCompteAuxiliaireEditComponent } from './plan-comptable-compte-auxiliaire-edit.component';

@NgModule({
	imports: [ShareModule],
	declarations: [PlanComptableListComponent,PlanComptableListItemComponent,PlanComptableComponent,PlanComptableEditComponent,PlanComptableCompteListComponent,PlanComptableCompteListItemComponent,PlanComptableCompteEditComponent,PlanComptableCompteAuxiliaireListComponent,PlanComptableCompteAuxiliaireListItemComponent,PlanComptableCompteAuxiliaireEditComponent],
	providers: [PlanComptableService]
})
export class PlanComptableModule { }
