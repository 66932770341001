<form #userResponsableForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>user.elements.userResponsable.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="userResponsable.type == 'RESPONSABLE' && !(TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'creation') || userResponsable.type == 'VALIDEUR' && !(TypeDroit.ADMIN_UTILISATEUR | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
							<label translate>user.userResponsable.type.item</label>
							<div>
								<selectpicker name="type" #typeField="ngModel" [(ngModel)]="userResponsable.type" (ngModelChange)="onTypeChange($event)">
									<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="userResponsable.type == 'RESPONSABLE'">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
							<label translate>user.userResponsable.societe</label>
							<div>
								<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="userResponsable.societe" (ngModelChange)="userResponsable.service = null" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
							<label translate>user.userResponsable.service</label>
							<div>
								<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="userResponsable.service" [filter]="{ idSociete: userResponsable?.societe?.idService }" [disabled]="!userResponsable?.societe || userResponsable?.societe?.extension?.nbSousServices == 0" [required]="userResponsable?.societe?.extension?.nbSousServices > 0"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="userResponsable.type == 'VALIDEUR'">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
							<label translate>user.userResponsable.user</label>
							<div>
								<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="userResponsable.user" required></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="userResponsable.type == 'RESPONSABLE_POOL'">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': pool.invalid }">
							<label translate>user.userResponsable.pool</label>
							<div>
								<autocomplete name="pool" type="pool" #pool="ngModel" [(ngModel)]="userResponsable.pool" required></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="userResponsable.type">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeValideurField.invalid }">
							<label [translate]="'user.userResponsable.type.' + userResponsable.type + '.item'"></label>
							<div>
								<selectpicker name="typeValideur" #typeValideurField="ngModel" [(ngModel)]="userResponsable.typeValideur" required>
									<mat-option *ngFor="let typeValideur of listeTypesValideur" [value]="typeValideur.code">{{ typeValideur.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right" *ngIf="userResponsable.type">
		<button mat-stroked-button color="primary" (click)="deleteUserResponsable(userResponsable,close);" *ngIf="deleteUserResponsable && userResponsable?.idObject && (userResponsable?.type == 'RESPONSABLE' && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'creation') || userResponsable?.type == 'VALIDEUR' && (TypeDroit.ADMIN_UTILISATEUR | right:'creation') || userResponsable?.type == 'RESPONSABLE_POOL' && (TypeDroit.VEHICULE_POOL | right:'creation'))">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveUserResponsable();" *ngIf="userResponsable?.type == 'RESPONSABLE' && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'creation') || userResponsable?.type == 'VALIDEUR' && (TypeDroit.ADMIN_UTILISATEUR | right:'creation') || userResponsable?.type == 'RESPONSABLE_POOL' && (TypeDroit.VEHICULE_POOL | right:'creation')" [disabled]="userResponsableForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: userResponsableForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>