<div *ngIf="todoListService.isDisplayed()" class="toolbox-modal todo-list visible" cdkDrag>
	<div class="header">
		<div class="title" cdkDragHandle>
			<span translate>todoList.title</span>
			<div class="dropdown m-r-5">
				<mat-button-toggle-group value="SELECT" class="title-selectors" data-toggle="dropdown">
					<mat-button-toggle value="SELECT"><span [translate]="'todoList.typeStatut.' + selectedStatut"></span></mat-button-toggle>
					<mat-button-toggle><icon name="expand_more"></icon></mat-button-toggle>
				</mat-button-toggle-group>
				<ul class="dropdown-menu dropdown-menu-right">
					<li *ngFor="let statut of listeStatuts">
						<a [routerLink]="[]" (click)="selectedStatut = statut" [translate]="'todoList.typeStatut.' + statut"></a>
					</li>
				</ul>
			</div>
			<icon name="close" (click)="todoListService.toggleDisplay()"></icon>
		</div>
	</div>
	<page-content class="body" mode="todoList">
		<div>
			<div>
				<mat-chip-listbox>
					<mat-chip-option (click)="selectedPartage = (selectedPartage != partage ? partage : null)" *ngFor="let partage of listePartages">
						<span [translate]="'todoList.typePartage.' + partage"></span>
					</mat-chip-option>
				</mat-chip-listbox>
			</div>
			<list-view [liste]="liste" *ngIf="liste"></list-view>
		</div>
	</page-content>
	<div class="footer" *ngIf="selectedStatut == 'DONE'">
		<button mat-raised-button color="primary" (click)="deleteAll('DONE');">
			<span translate>actions.toutSupprimer</span>
		</button>
	</div>
</div>