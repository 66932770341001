import { NgModule } from '@angular/core';

import { CapitalizeFirstPipe } from './capitalize-first.pipe';

@NgModule({
	declarations: [CapitalizeFirstPipe],
	exports: [CapitalizeFirstPipe],
	providers: [CapitalizeFirstPipe]
})
export class CapitalizeFirstModule {}
