import { Component,Input } from '@angular/core';
import { ListView } from 'src/app/domain/common/list-view';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LotComptableCheckFactureListItemComponent } from './lot-comptable-check-facture-list-item.component';

@Component({
	selector: 'lot-compable-check-facture-list',
	templateUrl: './lot-comptable-check-facture-list.component.html'
})
export class LotComptableCheckFactureListComponent {
	/** Indicateur d'affichage des anomalies **/
	@Input() isAnomalie: boolean;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Ouverture de la popup de contrôle des écritures en mode édition **/
	@Input() showLotComptableChecker: (selectedItem: any,selectionItems: any) => void;

	/** Données **/
	liste: ListView<any,LotComptableCheckFactureListItemComponent>;

	/** Résultat **/
	result: { selectionItems: any,isDeletion: boolean };

	/**
	 * Constructeur
	 */
	constructor(public translateService: TranslateService,private confirmService: ConfirmService,public bsModalRef: BsModalRef<LotComptableCheckFactureListComponent>) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckFactureListItemComponent>({
			uri: () => `/controller/LotComptable/filtreFacturesForSelection/${this.isAnomalie ? 'ANOMALIE' : 'CORRECT'}`,
			component: LotComptableCheckFactureListItemComponent,
			isLocal: true,
			extraOptions: {
				isAnomalie: this.isAnomalie,
				selectionItems: this.selectionItems,
				removeListeFactures: this.removeListeFactures.bind(this),
				showLotComptableChecker: this.showLotComptableChecker.bind(this)
			},
			listeFilters: [{
				clef: 'fournisseur.libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: '-date,fournisseur.libelle,reference',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })],
			getKeyFieldName: () => 'idFacture',
			listeActions: [!this.isAnomalie && {
				libelle: 'comptabilite.lotComptable.check.actions.confirmationExclusion',
				icon: 'remove_circle_outline',
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.removeListeFactures(messagingOptions)
			}].filter(a => !!a)

		});
	}

	/**
	 * Retrait des factures
	 */
	removeListeFactures(messagingOptions: MessagingOptions) {
		let actionMasse: ActionMasse;

		this.confirmService.showConfirm(this.translateService.instant('comptabilite.lotComptable.check.actions.confirmationExclusion'),{ actionColor: 'primary' }).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Récupération de l'action de masse
					actionMasse = messagingOptions.params as ActionMasse;

					//Vérification de l'action de masse
					if (actionMasse?.searchSpec?.extraData)
						//Retrait des données supplémentaires
						delete actionMasse.searchSpec.extraData;

					//Vérification de la liste des actions en masse
					if (this.selectionItems.listeActionsMasseToExclude)
						//Ajout de l'action en masse
						this.selectionItems.listeActionsMasseToExclude.push(actionMasse);
					else
						//Ajout de l'action
						this.selectionItems.listeActionsMasseToExclude = [actionMasse];

					//Mise à jour du nombre
					this.selectionItems.nbSelectedItems = this.selectionItems.nbSelectedItems - actionMasse.nbSelected;

					//Définition du résultat
					this.result = {
						selectionItems: this.selectionItems,
						isDeletion: true
					};

					//Fermeture de la modal
					this.bsModalRef.hide();
				}
			}
		});
	}
}
