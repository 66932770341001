import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienService } from './entretien/entretien.service';

@Component({
	templateUrl: './vehicule-entretien-list-item.component.html'
})
export class VehiculeEntretienListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private entretienService: EntretienService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteEntretien = this.deleteEntretien.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteEntretien(entretien: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.entretienService.deleteEntretien(entretien))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Edition de l'entretien
	 */
	addEntretien() {
		//Chargement complet de l'entretien
		this.entretienService.loadEntretien(this.data.idEntretien).subscribe({
			next: (result: Result) => {
				let lastCompteur: any;
				let nbInterventions: number;

				//Vérifcation du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'entretien
					Object.assign(this.data,result.data.entretien);

					//Vérification de la présence d'un dernier compteur
					if (result.data.lastCompteur)
						//Récupération du dernier relevé de référence pour le véhicule
						lastCompteur = result.data.lastCompteur;

					//Récupération du nombre d'interventions
					nbInterventions = result.data?.nbInterventions || 0;

					//Edition de l'entretien
					this.extraOptions?.addEntretien?.(this.data,this.deleteEntretien,lastCompteur,nbInterventions);
				}
			}
		})
	}

	/**
	 * Accès à la facture
	 */
	goToFacture() {
		//Récupération de l'identifiant de la facture liée à l'entretien
		this.entretienService.findIdFactureForEntretien(this.data).pipe(filter(result => result.data?.idFacture)).subscribe({
			next: result => {
				//Navigation vers la facture
				this.layoutService.goToByState('listeFactures-load',{
					routeParams: {
						idFacture: result.data.idFacture
					},
					withGoBack: true
				});
			}
		})
	}
}