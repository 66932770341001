<form #profilForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>user.elements.profil.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': profilForm?.controls?.['profilField']?.invalid }">
							<label translate>user.profil</label>
							<div *ngIf="!lienProfil?.profil || lienProfil?.profil?.typeProfil != 'MOBILE'">
								<selectpicker name="profil" #profilField="ngModel" [(ngModel)]="lienProfil.profil" [compareWith]="selectPickerService.getComparator('idProfil')" required>
									<mat-option *ngFor="let profil of listeProfils" [value]="profil">{{ profil?.libelle + ' (' + profil?.reference +')' }}</mat-option>
								</selectpicker>
							</div>
							<div *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'">
								<span>{{ lienProfil?.profil?.libelle }}</span>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': profilForm?.controls?.['login']?.invalid }" *ngIf="lienProfil.profil">
							<label translate>user.login</label>
							<div *ngIf="!lienProfil?.profil || lienProfil?.profil?.typeProfil != 'MOBILE'">
								<input type="text" name="login" #login="ngModel" class="form-control" [(ngModel)]="lienProfil.user.login" (ngModelChange)="onLoginChange(lienProfil.user)" maxlength="100" required/>
							</div>
							<div *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'">
								<span>{{ userAccountMobile?.login }}</span>
							</div>
						</div>
						<div class="form-group" *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'">
							<label translate>user.device.item</label>
							<div>
								<a [routerLink]="[]" (click)="showListeDevices()" translate>user.device.info</a>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right" *ngIf="lienProfil?.profil?.typeProfil != 'MOBILE'">
		<button mat-stroked-button color="primary" (click)="deleteLienProfil(lienProfil,close,index);" *ngIf="deleteLienProfil && lienProfil?.idLien && (TypeDroit.ADMIN_PROFIL | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienProfil();" *ngIf="TypeDroit.ADMIN_PROFIL | right:'consultation'" [disabled]="profilForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: profilForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>