import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EquipementAffectationEditComponent } from './equipement-affectation-edit.component';
import { EquipementAffectationListComponent } from './equipement-affectation-list.component';
import { EquipementEditComponent } from './equipement-edit.component';
import { EquipementFactureDetailListComponent } from './equipement-facture-detail-list.component';
import { EquipementReleveCompteurEditComponent } from './equipement-releve-compteur-edit.component';
import { EquipementReleveCompteurListComponent } from './equipement-releve-compteur-list.component';
import { EquipementReleveEnergieEditComponent } from './equipement-releve-energie-edit.component';
import { EquipementReleveEnergieListComponent } from './equipement-releve-energie-list.component';
import { EquipementService } from './equipement.service';

@Component({
	selector: 'equipement',
	templateUrl: './equipement.component.html'
})
export class EquipementComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Equipement courant **/
	public equipement: any;

	/** Résumé **/
	private resume: { nbAffectations: number,nbDetailsFacture: number,nbRelevesCompteur: number,nbRelevesCarburant: number } = {
		nbAffectations: 0,
		nbDetailsFacture: 0,
		nbRelevesCompteur: 0,
		nbRelevesCarburant: 0,
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.EQUIPEMENT;

	/**
	 * Constructeur
	 */
	constructor(private equipementService: EquipementService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService
			,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private datePipe: DatePipe) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.equipement.Equipement';

	/**
	 * Récupération de l'identifiant de l'équipement à charger
	 */
	getIdObject: () => number = () => this.equipement?.idEquipement || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.equipement;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'equipement.type.item',
				key: data => this.translateService.instant(`equipement.type.${data.type}`)
			},{
				libelle: 'equipement.fournisseur',
				key: 'fournisseur',
				type: 'DISPLAY',
				format: 'fournisseur'
			},{
				libelle: 'equipement.reference',
				key: 'reference'
			},{
				libelle: 'equipement.periode.title',
				key: (data) => this.translateService.instant(`equipement.periode.item`,{ debut: this.datePipe.transform(data.dateDebut,'shortDate'),fin: this.datePipe.transform(data.dateFin,'shortDate') })
			}],
			isFormCustomization: true,
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editEquipement(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editEquipement(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteEquipement(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeElements: [{
				type: 'AFFECTATION',
				libelle: 'equipement.elements.affectation',
				component: EquipementAffectationListComponent,
				retrieveComponentData: () => ({
					equipement: this.equipement,
					resume: this.resume,
					addAffectation: this.addAffectation.bind(this)
				}),
				doAction: () => this.addAffectation(),
				count: () => this.resume.nbAffectations,
				libelleAction: this.translateService.instant('equipement.elements.affectation.ajouter'),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isVisible: () => this.rightService.hasRight(null,'consultation')
			},{
				type: 'FACTURE_DETAIL',
				libelle: 'equipement.elements.factureDetail',
				component: EquipementFactureDetailListComponent,
				retrieveComponentData: () => ({
					equipement: this.equipement,
					resume: this.resume
				}),
				doAction: () => {
					//Redirection vers la facture
					this.layoutService.goToByState('listeFactures-load',{
						routeParams: {
							idFacture: 0,
							objetLien: this.equipement,
							source: 'EQUIPEMENT'
						},
						withGoBack: true
					});
				},
				count: () => this.resume.nbDetailsFacture,
				libelleAction: this.translateService.instant('equipement.elements.factureDetail.ajouter'),
				isActionVisible: () => false,
				isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'consultation') || this.rightService.hasRight(TypeDroit.FACTURE_DETAIL,'consultation')
			},{
				type: 'RELEVE_COMPTEUR',
				libelle: 'equipement.elements.releveCompteur',
				component: EquipementReleveCompteurListComponent,
				retrieveComponentData: () => ({
					equipement: this.equipement,
					resume: this.resume,
					addReleveCompteur: this.addReleveCompteur.bind(this)
				}),
				doAction: () => this.addReleveCompteur(),
				count: () => this.resume.nbRelevesCompteur,
				libelleAction: this.translateService.instant('equipement.elements.releveCompteur.ajouter'),
				isActionVisible: () => false,
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_COMPTEUR,'consultation')
			},{
				type: 'RELEVE_CARBURANT',
				libelle: 'equipement.elements.releveEnergie',
				component: EquipementReleveEnergieListComponent,
				retrieveComponentData: () => ({
					equipement: this.equipement,
					resume: this.resume,
					addReleveEnergie: this.addReleveEnergie.bind(this)
				}),
				doAction: () => this.addReleveEnergie(),
				count: () => this.resume.nbRelevesCarburant,
				libelleAction: this.translateService.instant('equipement.elements.releveEnergie.ajouter'),
				isActionVisible: () => false,
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_RELEVE_ENERGIE,'consultation')
			}]
		};

		//Récupération de l'identifiant de l'équipement à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idEquipement);

				//Chargement du résumé
				this.initResume(params.idEquipement);
			}
		});
	}

	/**
	 * Chargement du résumé
	 */
	private initResume(idEquipement: number) {
		//Chargement du résumé
		this.equipementService.loadResume(idEquipement).subscribe({
			next: (result: Result) => {
				//Vérification de la présence d'une erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du résumé
					Object.assign(this.resume,{
						nbAffectations: result.data?.nbAffectations || 0,
						nbDetailsFacture: result.data?.nbDetailsFacture || 0,
						nbRelevesCompteur: result.data?.nbRelevesCompteur || 0,
						nbRelevesCarburant: result.data?.nbRelevesCarburant || 0
					});
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idEquipement: number) {
		//Chargement de l'équipement
		this.equipementService.loadEquipement(idEquipement).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'équipement
					this.equipement = result.data?.equipement;

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant de l'équipement
					if (!this.equipement?.idEquipement) {
						//Création d'un objet vierge
						this.equipement = {};

						//Edition de l'équipement
						this.editEquipement();
					}
				}
			}
		});
	}

	/**
	 * Edition de l'équipement
	 */
	private editEquipement() {
		//Ouverture du composant d'édition
		this.pageContentService.open(EquipementEditComponent,{
			data: {
				equipement: this.equipement.idEquipement ? cloneDeep(this.equipement) : this.equipement,
				deleteEquipement: this.deleteEquipement.bind(this)
			}
		}).subscribe({
			next: (equipement: any) => {
				//Vérification des données
				if (equipement) {
					//Mise à jour de l'équipement
					Object.assign(this.equipement,equipement);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.equipement);
				}
			}
		});
	}

	/**
	 * Suppression de l'équipement
	 */
	protected deleteEquipement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.equipementService.deleteEquipement(this.equipement))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Modification de l'affectation
	 */
	protected addAffectation(affectation: any = { equipement: Object.assign({},this.equipement),type: !['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(this.equipement.type) ? 'VEHICULE' : null },deleteAffectation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(EquipementAffectationEditComponent,{
			data: {
				affectation: cloneDeep(affectation),
				deleteAffectation: deleteAffectation,
				source: 'EQUIPEMENT'
			}
		},'sub').subscribe({
			next: (data: { affectation: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AFFECTATION',affectation.idAffectation != data.affectation?.idAffectation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!affectation.idAffectation && data.affectation?.idAffectation) {
						//Ajout de l'affectation à la liste des affectations de l'équipement
						this.equipement.listeAffectations.push(affectation);

						//Incrémentation du compteur
						this.resume.nbAffectations++;
					}

					//Mise à jour de l'affectation
					Object.assign(affectation,data.affectation);
				}
			}
		});
	}

	/**
	 * Modification du relevé de compteur
	 */
	protected addReleveCompteur(releveCompteur: any = { equipement: Object.assign({},this.equipement),typeSourceReleve: 'COLLABORATEUR',valeurReference: true },deleteReleveCompteur?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(EquipementReleveCompteurEditComponent,{
			data: {
				releveCompteur: cloneDeep(releveCompteur),
				deleteReleveCompteur
			}
		},'sub').subscribe({
			next: (data: { releveCompteur: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RELEVE_COMPTEUR',releveCompteur.idCompteur != data.releveCompteur?.idCompteur);

					//Vérification de la présence d'une création ou d'une suppression
					if (!releveCompteur.idCompteur && data.releveCompteur?.idCompteur)
						//Incrémentation du compteur
						this.resume.nbRelevesCompteur++;
					else if (releveCompteur.idCompteur && !data.releveCompteur?.idCompteur)
						//Décrémentation du compteur
						this.resume.nbRelevesCompteur--;

					//Mise à jour du relevé de compteur
					Object.assign(releveCompteur,data.releveCompteur);
				}
			}
		});
	}

	/**
	* Modification du relevé d'énergie
	*/
	protected addReleveEnergie(releveEnergie: any = { equipement: Object.assign({},this.equipement),typeSourceReleve: 'COLLABORATEUR' },deleteReleveEnergie?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(EquipementReleveEnergieEditComponent,{
			data: {
				releveEnergie: cloneDeep(releveEnergie),
				deleteReleveEnergie
			}
		},'sub').subscribe({
			next: (data: { releveEnergie: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RELEVE_CARBURANT',releveEnergie.idReleve != data.releveEnergie?.idReleve);

					//Vérification de la présence d'une création ou d'une suppression
					if (!releveEnergie.idReleve && data.releveEnergie?.idReleve)
						//Incrémentation du compteur
						this.resume.nbRelevesCarburant++;
					else if (releveEnergie.idReleve && !data.releveEnergie?.idReleve)
						//Décrémentation du compteur
						this.resume.nbRelevesCarburant--;

					//Mise à jour du relevé d'énergie
					Object.assign(releveEnergie,data.releveEnergie);
				}
			}
		});
	}
}