<div class="modal-header">
	<h4 class="modal-title"><span translate>vehicule.modele.options.title</span></h4>
</div>
<div class="modal-body configurateur">
	<div class="text-center p-10" *ngIf="!isOptionsSynced">
		<please-wait><h5 translate>vehicule.modele.options.synchronisation</h5></please-wait>
	</div>
	<div class="text-center p-10" *ngIf="isOptionsSynced && !listeDetails">
		<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
	</div>
	<div class="text-center p-10" *ngIf="isOptionsSynced && listeDetails?.length == 0">
		<h5 translate>liste.empty</h5>
	</div>
	<option-list *ngIf="isOptionsSynced && listeDetails?.length > 0" [listeDetails]="listeDetails"></option-list>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>