<form #sinistreForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.elements.sinistre.title</h2>
		</div>
		<div class="card-body card-padding">
			<sinistre-content #sinistreContent [sinistre]="sinistre" source="VEHICULE" (close)="close({ sinistre: $event })"></sinistre-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteSinistre(sinistre,close);" *ngIf="deleteSinistre && sinistre?.idSinistre && (TypeDroit.VEHICULE_SINISTRE | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="sinistreContent.saveSinistre()" *ngIf="TypeDroit.VEHICULE_SINISTRE | right:'creation'" [disabled]="sinistreForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: sinistreForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>