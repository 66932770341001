<form #listeRubriquesForm="ngForm" autocomplete="off" (keydown.enter)="onKeydownEnter($event)">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>article.rubrique.liste.title</h2>
			</div>
			<div class="card-body card-padding">
				<div class="listview">
					<div class="lv-body">
						<bs-sortable name="listeContents" [(ngModel)]="article.listeContents" [itemTemplate]="itemTemplate" itemActiveClass="invisible"></bs-sortable>

						<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="addContent();" *ngIf="null | right:'creation'">
							<h5><a [routerLink]="[]" translate>article.rubrique.ajoutContenu</a></h5>
						</div>
					</div>
				</div>
			</div>
		</div>

		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveArticle(article,close);" [disabled]="listeRubriquesForm.invalid" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</div>

	<ng-template #itemTemplate let-content="item" let-index="index">
		<div class="lv-item media p-0">
			<div class="sortable-item">
				<div class="flex-1 p-t-5 p-b-5">
					<span class="handle" *ngIf="null | right:'creation'"><icon class="icon-hc-2x" name="open_with"></icon></span>
					<div class="form-group flex-1 m-b-0">
						<div>
							<input type="text" class="form-control" data-test-id="titre" [name]="'titre_'+content.value.uuid" #titre="ngModel" [(ngModel)]="content.value.titre" [placeholder]="'article.rubrique.titre' | translate" required/>
						</div>
					</div>
				</div>
				<ul class="m-b-0">
					<li *ngIf="article.listeContents?.length && index != (article.listeContents.length - 1)">
						<button mat-icon-button color="primary" (click)="moveContent(index,1);">
							<mat-icon>arrow_circle_down</mat-icon>
						</button>
					</li>
					<li *ngIf="index != 0">
						<button mat-icon-button color="primary" (click)="moveContent(index,-1);">
							<mat-icon>arrow_circle_up</mat-icon>
						</button>
					</li>
					<li>
						<button mat-icon-button color="primary" (click)="content.value.isToggled = !content.value.isToggled;">
							<mat-icon>{{ !content.value.isToggled ? 'chevron_right' : 'expand_more' }}</mat-icon>
						</button>
					</li>
					<li>
						<button mat-icon-button color="primary" (click)="deleteContent(index);">
							<mat-icon>delete</mat-icon>
						</button>
					</li>
				</ul>
			</div>
			<div class="row m-0" [collapse]="!content.value.isToggled">
				<div class="col-md-12 p-0">
					<div class="template-bloc">
						<summernote [config]="summernoteConfig" [name]="'content_'+content.value.uuid" [(ngModel)]="content.value.content"></summernote>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</form>