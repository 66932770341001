import { Component,ElementRef,EventEmitter,HostListener,Injector,Input,OnDestroy,OnInit,Output,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DisplayGrid,GridType,GridsterConfig } from 'angular-gridster2';
import { cloneDeep,isEqual } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription,combineLatest } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { ChartOptions } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDashboard,TypeLayout } from 'src/app/domain/dashboard/dashboard';
import { User } from 'src/app/domain/user/user';
import { UPDATE_OPEN_CREATION } from 'src/app/reducers/navigation';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PageTitleService } from 'src/app/share/layout/page-title.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GLOBALS } from 'src/app/utils/globals';
import { DashboardCalendarCustomizerComponent } from './dashboard-calendar-customizer.component';
import { DashboardChartCustomizerComponent } from './dashboard-chart-customizer.component';
import { DashboardService } from './dashboard.service';
import { DashboardFavorisService } from './favoris/dashboard-favoris.service';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit,OnDestroy {
	/** Type du dashboard courant **/
	@Input() typeDashboard: TypeDashboard;

	/** Identifiant de l'objet courant **/
	@Input() idObjet?: number;

	/** Liste des graphiques statiques **/
	@Input() listeStaticCharts?: Array<{ options: ChartOptions,data: Page<any>,x: number,y: number,cols: number,rows: number }>;

	/** Dashboard courant **/
	dashboard: any;

	/** Utilisateur connecté **/
	user: User;

	/** Type de layout **/
	typeLayout: TypeLayout = TypeLayout.WEB;

	/** Profil courant **/
	@Input() profil?: any;

	/** Indicateur d'édition du dashboard **/
	isEdition: boolean = false;

	/** Identifiant du dashboard **/
	@Input() idDashboard?: number;

	/** Indicateur du scope du dashboard **/
	@Input() isScopeFormulaire?: boolean = false;

	/** Notification de la fermeture **/
	@Output() onClose?: EventEmitter<any> = new EventEmitter<any>();

	/** Notification du changement du mode édition **/
	@Output() onSwitchEdition?: EventEmitter<any> = new EventEmitter<any>();

	/** Liste des actions supplémentaires pour le bouton flottant **/
	listeExtraActions: Array<{ icone: string,libelleKey: string,doAction: Function }>;

	/** Liste des filtres partagés **/
	listeSharedFilters: Array<any>;

	/** Enumération des types de layout **/
	public TypeLayout: typeof TypeLayout = TypeLayout;

	/** Hauteur du dashboard **/
	dashboardHeight: string;

	/** Hauteur du dashboard statique **/
	staticDashboardHeight: string;

	/** Classe de l'entité filtrante **/
	filteringEntity: string;

	/** Options de la grille **/
	gridOptions: GridsterConfig = {
		gridType: GridType.Fit,
		displayGrid: DisplayGrid.Always,
		draggable: {
			enabled: false
		},
		resizable: {
			enabled: false
		},
		minCols: 12,
		maxCols: 12,
		defaultItemCols: 3,
		defaultItemRows: 3
	}

	/** Options de la grille statique **/
	staticGridOptions: GridsterConfig;

	/** Indicateur de chargement **/
	isLoading: boolean = true;

	/** Container de la grille **/
	private dashboardContainerRef: ElementRef;

	/** Timeout de redimensionnement de la fenêtre **/
	private resizeTimeout: any;

	/** Nombre de lignes supplémentaires à ajouter **/
	private extraRows: number = 6;

	/** Sauvegarde du positionnement du dashboard **/
	private savedListeDashboardCharts: Array<any>;

	/** Souscription pour l'écoute du redimensionnement des graphiques **/
	private onChartResizeSubscription: Subscription;

	/** Souscription pour l'écoute de l'ajout de filtres partagés **/
	private onRuleFilterAddedSubscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,public dashboardService: DashboardService,private activatedRoute: ActivatedRoute,private entiteService: EntiteService,private layoutService: LayoutService,private pageTitleService: PageTitleService,private translateService: TranslateService,private rightService: RightService
			,private dashboardFavorisService: DashboardFavorisService,public extractionService: ExtractionService,private confirmService: ConfirmService,private toastrService: ToastrService,private injector: Injector,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.onDashboardResize = this.onDashboardResize.bind(this);
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		combineLatest([this.store.select<User>(state => state.session?.user).pipe(filter(user => user?.tenant)),this.activatedRoute.params.pipe(map(params => params?.typeDashboard))]).pipe(first()).subscribe({
			next: ( [user,typeDashboard] ) => {
				//Vérification de l'utilisateur connecté
				if (this.user?.idUser != user.idUser)
					//Suppression du cache
					this.dashboardService.evictCache();

				//Définition de l'utilisateur connecté
				this.user = user;

				//Vérification de l'absence d'identifiant d'objet
				if (!this.idObjet) {
					//Vérification de la présence d'un profil
					if (!this.profil)
						//Définition du type du dashboard
						this.typeDashboard = typeDashboard;

					//Récupération de la liste des dashboards disponibles
					this.dashboardService.getListeAvailableDashboards(this.user).subscribe({
						next: listeEntites => {
							let listeSelectors: Array<{ libelle: string,doAction: Function,typeDashboard?: TypeDashboard,isGeneral?: boolean }>;

							//Création de la liste des sélecteurs
							listeSelectors = listeEntites?.map(d => ({
								libelle: this.entiteService.translateEntityCode(d.type.split('.').pop()),
								typeDashboard: d.typeDashboard,
								doAction: () => {
									//Navigation vers l'URL
									this.layoutService.goToByState('dashboard-noreset',{
										reloadOnSameUrl: true,
										routeParams: {
											typeDashboard: d.typeDashboard
										}
									});
								}
							}));

							//Ajout du dashboard global
							listeSelectors.splice(0,0,{
								libelle: this.translateService.instant('dashboard.general'),
								isGeneral: true,
								doAction: () => {
									//Navigation vers le dashboard général
									this.layoutService.goToByState('dashboard',{
										reloadOnSameUrl: true
									});
								}
							});

							//Tri des sélecteurs par libellé
							listeSelectors.sort((a,b) => a.libelle?.localeCompare(b.libelle));

							//Mise à jour de la liste des sélecteurs
							!this.profil && this.pageTitleService.setListeSelectors([
								...(this.typeDashboard && listeSelectors.filter(s => s.typeDashboard == this.typeDashboard) || []),
								...(listeSelectors.filter(s => s.isGeneral)),
								...(listeSelectors.filter(s => !s.isGeneral && (!this.typeDashboard || s.typeDashboard != this.typeDashboard)))
							]);
						}
					});
				}

				//Réinitialisation de la liste des actions supplémentaires du bouton flottant
				this.listeExtraActions = null;

				//Vérification du type de dashboard
				if (this.typeDashboard) {
					//Récupération du paramétrage du dashboard courant
					this.dashboardService.getDashboardForType(this.typeDashboard).pipe(
						filter(dashboard => dashboard.creation && dashboard.typeDroit && this.rightService.hasAnyRight(Array.isArray(dashboard.typeDroit) ? dashboard.typeDroit : [dashboard.typeDroit],'creation'))
					).subscribe({
						next: dashboard => {
							//Mise à jour de la liste des actions supplémentaires du bouton flottant
							dashboard.getListeActions?.(this.rightService,this.injector).pipe(first()).subscribe({
								next: listeActions => {
									//Mise à jour de la liste des actions
									this.listeExtraActions = listeActions;
								}
							});

							//Définition de l'entité filtrante
							this.filteringEntity = dashboard.type;

							//Définition de l'action
							!this.idObjet && !this.profil && this.pageTitleService.setListeActions([{
								libelle: this.translateService.instant('dashboard.actions.ajouterElement'),
								icon: 'add',
								doAction: () => {
									//Définition de l'indicateur de création
									this.store.dispatch({
										type: UPDATE_OPEN_CREATION,
										payload: dashboard.creation.state
									});

									//Navigation vers la route
									this.layoutService.goToByState(dashboard.creation.state);
								}
							}]);
						}
					});
				} else {
					//Chargement de la liste des actions issues des favoris
					!this.profil && this.loadListeActionsFromFavoris();
				}

				//Chargement du dashboard
				this.dashboardService.loadDashboard(this.typeDashboard,null,null,!!this.idObjet || this.isScopeFormulaire,this.idDashboard,this.profil?.idProfil).pipe(first()).subscribe({
					next: result => {
						//Vérification du dashboard
						if (result?.data?.dashboard) {
							//Vérification de la nécessité de naviguer vers la liste
							if (!this.profil && this.typeDashboard && !this.idObjet && !result.data.dashboard.listeDashboardCharts?.length && !history.state.isForceEdition) {
								//Redirection vers la liste
								this.dashboardService.showListe(this.typeDashboard);
							} else {
								//Récupération du type de layout
								this.typeLayout = this.dashboardService.getCurrentLayout();

								//Vérification de l'absence de profil
								if (!this.profil) {
									//Récupération du dashboard
									this.dashboard = result.data.dashboard;

									//Initialisation du layout
									this.dashboardService.initLayout(this.dashboard,this.typeLayout,this.gridOptions);
								}
							}

							//Mise à jour de la hauteur du dashboard
							this.computeDashboardHeight();
						} else
							//Retrait du dashboard
							this.dashboard = null;

						//Vérification du dashboard
						if (this.dashboard) {
							//Ecoute de l'ajout de filtres partagés
							this.onRuleFilterAddedSubscription = this.dashboardService.onRuleFilterAdded$.pipe(
								filter(({ entity }) => this.dashboard.entity == entity),
								map(({ entity }) => this.dashboardService.getRule(entity))
							).subscribe({
								next: (rule: any) => {
									//Vérification de la règle
									if (rule?.listeDetails?.length) {
										//Mise à jour des filtres
										this.listeSharedFilters = rule.listeDetails.filter(detail => detail.isSelected).map(detail => {
											let dashboardChart: any;

											//Récupération du chart
											dashboardChart = this.dashboard.listeDashboardCharts.find(lien => lien.chart.idChart == detail.idChart);

											return {
												idChart: detail.idChart,
												libelle: (dashboardChart?.customLibelle || dashboardChart?.chart?.libelle || '') + ' : ' + detail.libelle
											}
										});
									} else
										//Aucun filtre
										this.listeSharedFilters = null;
								}
							});

							//Chargement des filtres initiaux
							this.dashboardService.onRuleFilterAddedSubject.next({ entity: this.dashboard.entity });
						}

						//Vérification de la présence d'un profil
						if (this.profil) {
							//Récupération du dashboard
							this.dashboard = result.data?.dashboard;

							//Définition du profil dans le dashboard
							!this.dashboard.profil && (this.dashboard.profil = this.profil);

							//Initialisation du layout
							this.dashboardService.initLayout(this.dashboard,this.typeLayout,this.gridOptions);

							//Edition du dashboard
							this.editDashboard();
						}
					},
					complete: () => {
						//Fin du chargement
						this.isLoading = false;
					}
				});
			}
		});

		//Ecoute du redimensionnement des graphiques
		this.onChartResizeSubscription = this.dashboardService.onChartResize$.pipe(filter(({ isMenu}) => isMenu)).subscribe({
			next: () => {
				//Mise à jour de la hauteur de la grille
				this.computeDashboardHeight();

				//Mise à jour des options de la grille (redimensionnement)
				this.gridOptions.api.optionsChanged();

				//Mise à jour des options de la grille statique (redimensionnement)
				this.staticGridOptions?.api?.optionsChanged?.();

				//Redéclenchement du redimensionnement
				setTimeout(() => this.dashboardService.onChartResizeSubject.next({ isResizing: false }),1000);
			}
		});

		//Copie des options de la grille standard pour la grille statique
		this.staticGridOptions = cloneDeep(this.gridOptions);

		//Définition de la hauteur de la grille statique
		this.staticGridOptions.minRows = this.listeStaticCharts?.reduce((height,chart) => height + chart.rows,0) || 0;
		this.staticGridOptions.maxRows = this.staticGridOptions.minRows;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.onChartResizeSubscription?.unsubscribe?.();
		this.onRuleFilterAddedSubscription?.unsubscribe?.();

		//Suppression de l'ensemble des tooltips
		GLOBALS.$('.chart-tip').remove();
	}

	/**
	 * Récupération de la référence de l'élément du dashboard
	 */
	@ViewChild('dashboardContainer')
	set setDashboardContainer(elementRef: ElementRef) {
		//Vérification du contenu
		if (elementRef)
			//Mise à jour de la référence
			this.dashboardContainerRef = elementRef;
	}

	/**
	 * Chargement de la liste des actions issues des favoris
	 */
	private loadListeActionsFromFavoris() {
		//Chargement de la liste des favoris de création
		this.dashboardFavorisService.findAllFavorisCreation().pipe(first()).subscribe({
			next: listeFavoris => {
				let listeActions: Array<{ libelle: string,icon: string,iconType?: string,doAction: Function }>;

				//Définition de la liste des actions
				listeActions = listeFavoris.map(f => ({
					libelle: f.libelle,
					icon: 'add',
					doAction: () => {
						//Réalisation de l'action liée au favoris
						this.dashboardFavorisService.doActionForFavoris(f);
					}
				}));

				//Tri des actions (par libellé)
				listeActions.sort((a,b) => a.libelle?.localeCompare(b.libelle));

				//Vérification des droits de l'utilisateur
				if (!this.user.listeDroits?.length || this.user.listeDroits.some(d => d.creation)) {
					//Ajout de la sélection des favoris
					listeActions.push({
						libelle: this.translateService.instant('dashboard.actions.ajouterElement'),
						icon: 'add',
						doAction: () => {
							//Affichage de la sélection des favoris
							this.dashboardFavorisService.showListeFavoris(listeFavoris).pipe(first(),filter(hasChanged => hasChanged)).subscribe({
								next: () => {
									//Rechargement des favoris
									this.loadListeActionsFromFavoris();
								}
							});
						}
					})
				}

				//Définition de la liste des actions
				this.pageTitleService.setListeActions(listeActions);
			}
		})
	}

	/**
	 * Edition du dashboard
	 */
	editDashboard() {
		//Mémorisation du positionnement des graphiques
		this.savedListeDashboardCharts = cloneDeep(this.dashboard.listeDashboardCharts);

		//Définition du nombre de lignes supplémentaires à ajouter
		this.extraRows = 6;

		//Passage du dashboard en mode 'Edition'
		this.isEdition = true;

		//Modification du mode de gestion de la grille
		this.gridOptions.draggable.enabled = true;
		this.gridOptions.resizable.enabled = true;
		this.gridOptions.draggable.dragHandleClass = '.handle';

		//Définition des méthodes de redimensionnement
		this.gridOptions.resizable.start = () => this.dashboardService.onChartResizeSubject.next({ isResizing: true });
		this.gridOptions.resizable.stop = () => this.dashboardService.onChartResizeSubject.next({ isResizing: false });

		//Mise à jour des dimensions
		this.computeDashboardHeight();

		//Mise à jour de la grille par rapport aux nouvelles options
		this.gridOptions.api?.optionsChanged();

		//Notification du changement du mode édition
		this.onSwitchEdition.emit(this.isEdition);
	}

	/**
	 * Suppression du dashboard
	 */
	deleteDashboard() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.dashboardService.deleteDashboard(this.dashboard.idDashboard))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage des extractions
	 */
	showExtraction() {
		//Vérification du type de dashboard
		if (this.typeDashboard) {
			//Sélection du dashboard
			this.dashboardService.getDashboardForType(this.typeDashboard).subscribe({
				next: dashboard => {
					//Affichage des extractions
					this.extractionService.selectExtraction(null,dashboard.type);
				}
			});
		} else
			//Affichage des extractions
			this.extractionService.selectExtraction(null,null);
	}

	/**
	 * Interception du redimensionnement de la fenêtre
	 */
	@HostListener('window:resize',['$event'])
	public onWindowResize() {
		//Annulation du timeout
		clearTimeout(this.resizeTimeout);

		//Mise en cycle avec annulation préalable
		this.resizeTimeout = setTimeout(this.onDashboardResize,100);
	}

	/**
	 * Redimensionnement du dashboard
	 */
	private onDashboardResize() {
		//Vérification du mode
		if (!this.isEdition)
			//Récupération du type de layout
			this.typeLayout = this.dashboardService.getCurrentLayout();

		//Initialisation du layout
		this.dashboardService.initLayout(this.dashboard,this.typeLayout,this.gridOptions);

		//Mise à jour de la hauteur de la grille
		this.computeDashboardHeight();

		//Mise à jour des options de la grille (redimensionnement)
		this.gridOptions.api.optionsChanged();

		//Mise à jour des options de la grille statique (redimensionnement)
		this.staticGridOptions?.api?.optionsChanged?.();
	}

	/**
	 * Modification du layout
	 */
	public changeLayout(typeLayout: TypeLayout) {
		//Mémorisation de la position des graphiques
		this.defineChartLayout();

		//Démarrage du redimensionnement
		this.dashboardService.onChartResizeSubject.next({ isResizing: true });

		//Définition du layout courant
		this.typeLayout = typeLayout;

		//Récupération des positions des graphiques pour le layout
		this.dashboardService.initLayout(this.dashboard,this.typeLayout,this.gridOptions);

		//Mise à jour de la hauteur de la grille
		this.computeDashboardHeight();

		//Mise à jour des options de la grille (redimensionnement)
		this.gridOptions.api.optionsChanged();

		//Mise à jour des options de la grille statique (redimensionnement)
		this.staticGridOptions?.api?.optionsChanged?.();

		//Fin du redimensionnement
		setTimeout(() => this.dashboardService.onChartResizeSubject.next({ isResizing: false }),1000);
	}

	/**
	 * Définition de la position des graphiques sur le dashboard courant
	 */
	private defineChartLayout() {
		//Numérotation des positions des graphiques
		this.dashboard.listeDashboardCharts?.forEach((dashboardChart: any,idxLien: number) => {
			let chartLayout: any;

			//Récupération du layout spécifique
			chartLayout = dashboardChart.listeChartLayout?.find(l => l.typeLayout == this.typeLayout);

			//Vérification de la présence d'un layout
			if (chartLayout) {
				//Mise à jour du layout depuis le graphique
				chartLayout.positionX = dashboardChart.x;
				chartLayout.positionY = dashboardChart.y;
				chartLayout.sizeX = dashboardChart.cols || this.gridOptions.defaultItemCols;
				chartLayout.sizeY = dashboardChart.rows || this.gridOptions.defaultItemRows;
			} else {
				//Création du layout
				chartLayout = {
					typeLayout: this.typeLayout,
					positionX: dashboardChart.x,
					positionY: dashboardChart.y,
					sizeX: dashboardChart.cols || this.gridOptions.defaultItemCols,
					sizeY: dashboardChart.rows || this.gridOptions.defaultItemRows
				};

				//Vérification de la liste des layouts du graphique
				if (!dashboardChart.listeChartLayout)
					//Création de la liste
					dashboardChart.listeChartLayout = [];

				//Ajout du layout à la liste
				dashboardChart.listeChartLayout.push(chartLayout);
			}

			//Définition de la position
			dashboardChart.position = idxLien;
		});
	}

	/**
	 * Ajout d'un graphique
	 */
	addChart() {
		//Sélection d'un chart
		this.dashboardService.selectChart(this.typeDashboard,this.idObjet,this.profil?.idProfil).subscribe({
			next: dashboardChart => {
				//Vérification de la sélection
				if (dashboardChart) {
					//Vérification de la liste et création si nécessaire
					this.dashboard.listeDashboardCharts = this.dashboard.listeDashboardCharts || [];

					//Ajout à la liste
					this.dashboard.listeDashboardCharts.push(dashboardChart);
				}
			}
		});
	}

	/**
	 * Calcul de la hauteur du dashboard
	 */
	private computeDashboardHeight() {
		let maxRows: number;

		//Calcul de la hauteur maximale
		maxRows = (this.dashboard?.listeDashboardCharts || []).reduce((previousValue,dashboardChart) => Math.max(previousValue,dashboardChart.y + (dashboardChart.rows || this.gridOptions.defaultItemRows)),3);

		//Vérification du mode 'Edition'
		if (this.isEdition)
			//Ajout de 6 lignes supplémentaires pour l'édition
			maxRows += this.extraRows;

		//Définition du nombre de lignes de la grille
		this.gridOptions.minRows = maxRows;
		this.gridOptions.maxRows = maxRows;

		//Mise en cycle
		setTimeout(() => {
			//Mise à jour de la hauteur du dashboard
			this.dashboardHeight = `${maxRows * ((this.dashboardContainerRef?.nativeElement?.offsetWidth || 0) + 20) / 12}px`;

			//Mise à jour de la hauteur du dashboard statique
			this.staticDashboardHeight = `${this.staticGridOptions.maxRows * ((this.dashboardContainerRef?.nativeElement?.offsetWidth || 0) + 20) / 12}px`;
		});
	}

	/**
	 * Ajout de lignes à la grille
	 */
	addRows() {
		//Définition de l'agencement du dashboard
		this.defineChartLayout();

		//Définition du nombre de lignes de la grille
		this.extraRows += 6;

		//Mise à jour des dimensions
		this.computeDashboardHeight();

		//Mise à jour de la grille par rapport aux nouvelles options
		this.gridOptions.api.optionsChanged();
	}

	/**
	 * Enregistrement du dashboard
	 */
	saveDashboard() {
		//Mise à jour du positionnement des graphiques
		this.defineChartLayout();

		//Enregistrement du dashboard
		this.dashboardService.saveDashboard(this.profil ? 'PROFIL' : 'USER',this.dashboard).pipe(first()).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du dashboard
					this.dashboard = result.data.dashboard;

					//Annulation de l'édition
					this.isEdition = false;

					//Modification du mode de gestion de la grille
					this.gridOptions.draggable.enabled = false;
					this.gridOptions.resizable.enabled = false;

					//Déclenchement d'un redimensionnement du dashboard
					this.onDashboardResize();

					//Vérification de l'existance d'un profil
					if (this.profil) {
						//Notification de la fermeture du composant
						this.onClose?.emit(this.dashboard);

						//Notification du changement du mode édition
						this.onSwitchEdition.emit(this.isEdition);
					}
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: this.translateService.instant('actions.doublon.entite')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Fermeture de l'édition
	 */
	close() {
		//Vérification de l'absence de profil
		if (!this.profil) {
			//Restauration du positionnement des graphiques
			this.dashboard.listeDashboardCharts = this.savedListeDashboardCharts;

			//Annulation de l'édition
			this.isEdition = false;

			//Modification du mode de gestion de la grille
			this.gridOptions.draggable.enabled = false;
			this.gridOptions.resizable.enabled = false;

			//Mise à jour des dimensions
			this.computeDashboardHeight();

			//Mise à jour de la grille par rapport aux nouvelles options
			this.gridOptions.api.optionsChanged();

			//Notification du changement du mode édition
			this.onSwitchEdition.emit(this.isEdition);
		}

		//Notification de la fermeture du composant
		this.onClose?.emit();
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Comparaison des deux listes de graphiques
		return !isEqual(this.dashboard.listeDashboardCharts,this.savedListeDashboardCharts);
	}

	/**
	 * Interception de la suppression d'un graphique
	 */
	onDashboardChartDeletion(dashboardChart: any) {
		//Vérification de la présence de l'identifiant du lien
		if (dashboardChart.idLien)
			//Suppression de l'élément par son identifiant de lien
			this.dashboard.listeDashboardCharts.splice(this.dashboard.listeDashboardCharts.findIndex(d => d.idLien == dashboardChart.idLien),1);
		else if (dashboardChart.chart?.idChart)
			//Suppression de l'élément par son identifiant de graphique et sa position
			this.dashboard.listeDashboardCharts.splice(this.dashboard.listeDashboardCharts.findIndex(d => d.chart && d.chart.idChart == dashboardChart.chart.idChart && d.position == dashboardChart.position),1);
		else if (dashboardChart.calendrier?.idCalendrier)
			//Suppression de l'élément par son identifiant de calendrier et sa position
			this.dashboard.listeDashboardCharts.splice(this.dashboard.listeDashboardCharts.findIndex(d => d.calendrier && d.calendrier.idCalendrier == dashboardChart.calendrier.idCalendrier && d.position == dashboardChart.position),1);
	}

	/**
	 * Suppression d'un filtre
	 */
	removeSharedFilter(idChart: number) {
		//Vérification de la règle de l'entité
		if (idChart && this.dashboardService.getRule(this.dashboard.entity)) {
			//Suppression du filtre de l'entité
			this.dashboardService.removeFilterByIdChart(this.dashboard.entity,idChart);

			//Rechargement des charts
			this.dashboardService.onRuleFilterAddedSubject.next({ entity: this.dashboard.entity });
		}
	}

	/**
	 * Interception de la personnalisation d'un graphique
	 */
	onDashboardChartCustomization(event: { dashboardChart: any,callback: () => void }) {
		let bsCalendarCustomizerModal: BsModalRef<DashboardCalendarCustomizerComponent>;
		let bsChartCustomizerModalRef: BsModalRef<DashboardChartCustomizerComponent>;

		//Vérification du type d'élément
		if (event.dashboardChart.calendrier) {
			//Affichage de la popup de personnalisation des calendriers
			bsCalendarCustomizerModal = this.bsModalService.show(DashboardCalendarCustomizerComponent,{
				initialState: {
					dashboardChart: event.dashboardChart,
					dashboard: this.dashboard,
					profil: this.profil
				},
				class: 'modal-max'
			});

			//Retour du résultat
			bsCalendarCustomizerModal.onHidden.pipe(
				first(),
				map(() => bsCalendarCustomizerModal.content?.result?.dashboardChart),
				filter(dashboardChart => !!dashboardChart)
			).subscribe(dashboardChart => {
				//Actualisation des données de personnalisation
				Object.assign(event.dashboardChart,dashboardChart);

				//Déclenchement du callback
				event.callback?.();
			});
		} else if (event.dashboardChart.chart) {
			//Affichage de la popup
			bsChartCustomizerModalRef = this.bsModalService.show(DashboardChartCustomizerComponent,{
				initialState: {
					dashboardChart: cloneDeep(event.dashboardChart),
					dashboard: this.dashboard
				},
				class: 'modal-max'
			});

			//Retour du résultat
			bsChartCustomizerModalRef.onHidden.pipe(
				first(),
				map(() => bsChartCustomizerModalRef.content?.result?.dashboardChart),
				filter(dashboardChart => !!dashboardChart)
			).subscribe({
				next: (dashboardChart: any) => {
					//Actualisation des données de personnalisation
					Object.assign(event.dashboardChart,dashboardChart);

					//Déclenchement du callback
					event.callback?.();
				}
			});
		}
	}
}