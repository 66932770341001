import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeVehiculeVehiculeInformationsComplementairesComponent } from './demande-vehicule-vehicule-informations-complementaires.component';

@Component({
	templateUrl: './demande-vehicule-vehicule-list-item.component.html'
})
export class DemandeVehiculeVehiculeListItemComponent extends ListViewItem<any> {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private vehiculeService: VehiculeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private bsModalService: BsModalService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idVehicule,object?.idVehicule && !this.data?.idVehicule);
	}

	/**
	 * Affichage des informations complémentaires du véhicule
	 */
	showVehiculeInformationsComplementaires() {
		//Chargement complet du véhicule
		this.vehiculeService.loadVehicule(this.data.idVehicule).subscribe(result => {
			let bsModalRef: BsModalRef<DemandeVehiculeVehiculeInformationsComplementairesComponent>;

			//Vérification du chargement
			if (result?.data?.vehicule) {
				//Affichage de la popup
				bsModalRef = this.bsModalService.show(DemandeVehiculeVehiculeInformationsComplementairesComponent,{
					initialState: {
						demandeVehicule: this.extraOptions.demandeVehicule,
						vehicule: cloneDeep(this.data),
						configurationPays: result.data.configurationPays
					}
				});

				//Retour du résultat
				bsModalRef.onHidden.pipe(
					first(),
					map(() => bsModalRef.content.savedVehicule),
					filter(vehicule => !!vehicule)
				).subscribe(vehicule => {
					//Mise à jour du véhicule de la demande
					this.extraOptions.demandeVehicule.vehicule = vehicule;

					//Mise à jour du véhicule dans la liste
					this.data = vehicule;
				});
			}
		});
	}

	/**
	 * Affichage du véhicule
	 */
	showVehicule() {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.data.idVehicule
			},
			withGoBack: true,
			goBackParams: {
				paramsToRestore: {
					idDemandeVehicule: this.extraOptions.demandeVehicule.idDemandeVehicule
				}
			}
		});
	}

	/**
	 * Suppression du véhicule
	 */
	deleteVehicule() {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => isConfirmed),
			switchMap(() => this.vehiculeService.deleteVehicule(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression du véhicule de la demande
				delete this.extraOptions.demandeVehicule.vehicule;

				//Suppression du véhicule dans la liste
				this.onObjectChange(null);

				//Mise à jour du résumé
				this.extraOptions.resume.nbVehicules = 0;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}
}