import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ChartModule } from './chart/chart.module';
import { ClientCRMModule } from './client-crm/client-crm.module';
import { ConnecteurModule } from './connecteur/connecteur.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PaysModule } from './pays/pays.module';
import { ReferentielListModule } from './referentiel-list/referentiel-list.module';
import { TenantModule } from './tenant/tenant.module';
import { UserModule } from './user/user.module';
import { ContratCRMModule } from './contrat-crm/contrat-crm.module';
import { CompteServiceModule } from './compte-service/compte-service.module';
import { CalendrierModule } from './calendrier/calendrier.module';
import { FournisseurModule } from './fournisseur/fournisseur.module';
import { DataCustomizationModule } from './data-customization/data-customization.module';
import { VehiculeModule } from './vehicule/vehicule.module';
import { DeviseModule } from './devise/devise.module';
import { OffreModule } from './offre/offre.module';
import { SocieteModule } from './societe/societe.module';
import { MaterielModule } from './materiel/materiel.module';
import { CommandeModule } from './commande/commande.module';
import { StructureExportModule } from './structure-export/structure-export.module';
import { ComptabiliteModule } from './comptabilite/comptabilite.module';
import { ArticleModule } from './article/article.module';
import { FactureModule } from './facture/facture.module';
import { GroupeModule } from './groupe/groupe.module';
import { LicenceModule } from './licence/licence.module';
import { ExtractionModule } from './extraction/extraction.module';
import { TemplateMailModule } from './template-mail/template-mail.module';
import { ProfilModule } from './profil/profil.module';
import { FiscaliteModule } from './fiscalite/fiscalite.module';
import { SgiModule } from './sgi/sgi.module';
import { WorkflowModule } from './workflow/workflow.module';
import { RevendeurModule } from './revendeur/revendeur.module';
import { AuthentificationModule } from './authentification/authentification.module';
import { LocaleModule } from './locale/locale.module';
import { DatabaseModule } from './database/database.module';
import { ServiceModule } from './service/service.module';
import { UpgradeModule } from './upgrade/upgrade.module';
import { PersonalDataModule } from './personal-data/personal-data.module';
import { ArticleCRMModule } from './article-crm/article-crm.module';
import { PlanningModule } from './planning/planning.module';
import { RegleAutomatiqueModule } from './regle-automatique/regle-automatique.module';
import { ConversationModule } from './conversation/conversation.module';

@NgModule({
	imports: [StoreModule,ClientCRMModule,PaysModule,ReferentielListModule,DashboardModule,ConnecteurModule,TenantModule,UserModule,ChartModule,ContratCRMModule,CompteServiceModule,CalendrierModule,FournisseurModule,DataCustomizationModule,VehiculeModule,DeviseModule,OffreModule
		,SocieteModule,MaterielModule,CommandeModule,StructureExportModule,ComptabiliteModule,ArticleModule,FactureModule,GroupeModule,LicenceModule,ExtractionModule,TemplateMailModule,ProfilModule,FiscaliteModule,SgiModule,WorkflowModule,RevendeurModule,AuthentificationModule
		,LocaleModule,DatabaseModule,ServiceModule,UpgradeModule,PersonalDataModule,ArticleCRMModule,PlanningModule,RegleAutomatiqueModule,ConversationModule
	]
})
export class ComponentModule {

}