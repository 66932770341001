import { DecimalPipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,concat } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,combineLatest } from 'rxjs';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { Action,TypeAction as DemandeVehiculeTypeAction } from 'src/app/domain/vehicule/demande-vehicule/demande-vehicule';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DemandeVehiculeCotationListComponent } from './demande-vehicule-cotation-list.component';
import { DemandeVehiculeEditComponent } from './demande-vehicule-edit.component';
import { DemandeVehiculeFinancementEditComponent } from './demande-vehicule-financement-edit.component';
import { DemandeVehiculeFinancementListComponent } from './demande-vehicule-financement-list.component';
import { DemandeVehiculeLivraisonComponent } from './demande-vehicule-livraison.component';
import { DemandeVehiculeModeleListComponent } from './demande-vehicule-modele-list.component';
import { DemandeVehiculeVehiculeListComponent } from './demande-vehicule-vehicule-list.component';
import { DemandeVehiculeService } from './demande-vehicule.service';

@Component({
	selector: 'demande-vehicule',
	templateUrl: './demande-vehicule.component.html'
})
export class DemandeVehiculeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Demande de véhicule courante **/
	public demandeVehicule: any;

	/** Mode 'Validation' **/
	public isValidationMode: boolean = false;

	/** Indicateur de présence d'une grille d'attribution active **/
	public isGrilleActiveAvailable: boolean = false;

	/** Indicateur de l'existence d'une grille d'attribution active pour le conducteur **/
	private isGrilleActiveForUser: boolean = false;

	/** Grille de fluidité **/
	public grilleFluidite: any;

	/** Grille d'attribution spécifique **/
	public grilleAttributionSpecifique: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.DEMANDE_VEHICULE;

	/** Utilisateur connecté **/
	user: User;

	/** Résumé **/
	public resume: { nbFinancements: number,nbVehicules: number,nbModeles: number,nbCotations: number } = {
		nbFinancements: 0,
		nbVehicules: 0,
		nbModeles: 0,
		nbCotations: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private notificationService: NotificationService,private activatedRoute: ActivatedRoute,private demandeVehiculeService: DemandeVehiculeService,private attachmentService: AttachmentService,private translateService: TranslateService,private displayPipe: DisplayPipe,private decimalPipe: DecimalPipe
			,private rightService: RightService,private pageContentService: PageContentService,private layoutService: LayoutService,private vehiculeService: VehiculeService,private workflowService: WorkflowService,private confirmService: ConfirmService,private toastrService: ToastrService,private bsModalService: BsModalService
			,private cotationService: CotationService,private demandeCotationService: DemandeCotationService) {
		//Binding des méthodes
		this.deleteFinancement = this.deleteFinancement.bind(this);
		this.consultCotation = this.consultCotation.bind(this);
		this.showCotation = this.showCotation.bind(this);
		this.addFinancement = this.addFinancement.bind(this);
		this.createDemandeCotation = this.createDemandeCotation.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.cotation.DemandeVehicule';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.demandeVehicule?.idDemandeVehicule || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.demandeVehicule;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		combineLatest([this.store.select<User>(state => state.session?.user)
			,this.notificationService.isNotificationAvailableFor(this.getClassName())
			,this.workflowService.isWorkflowAvailableForEntity(this.getClassName())
			,this.activatedRoute.params.pipe(map(params => params.idDemandeVehicule))
		]).pipe(first()).subscribe(([user,hasNotification,isWorkflowAvailable,idDemandeVehicule]) => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Chargement des données
			this.loadData(idDemandeVehicule).subscribe({
				next: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [{
							libelle: 'demandeVehicule.statut.item',
							key: (demandeVehicule: any) => this.translateService.instant(`demandeVehicule.statut.${demandeVehicule.statut}`)
						},{
							libelle: 'demandeVehicule.beneficiaire.item',
							key: (demandeVehicule: any) => (this.demandeVehicule?.user || this.demandeVehicule?.societe) && this.displayPipe.transform(demandeVehicule.user ? demandeVehicule.user : demandeVehicule.societe && demandeVehicule.service ? demandeVehicule.service : demandeVehicule.societe,demandeVehicule.user ? 'user' : demandeVehicule.societe && demandeVehicule.service ? 'service' : 'societe') || ''
						},this.user.type != 'VIRTUAL' && this.demandeVehicule?.distanceMensuelleEstimee && {
							libelle: 'demandeVehicule.distanceMensuelleEstimee',
							key: (demandeVehicule: any) => this.decimalPipe.transform(demandeVehicule.distanceMensuelleEstimee,'.0-0') + ' ' + this.user.unite.libelleCourt
						},this.demandeVehicule?.vehiculeSource && {
							libelle: 'demandeVehicule.vehiculeARenouveler',
							key: 'vehiculeSource',
							type: 'DISPLAY',
							format: 'vehicule',
							isVisible: () => this.demandeVehicule?.vehiculeSource
						},this.user.type != 'VIRTUAL' && {
							libelle: 'demandeVehicule.grilleAttribution',
							key: 'grilleAttribution.libelle',
							isVisible: () => this.demandeVehicule?.grilleAttribution
						}].filter(f => !!f),
						listeActions: [{
							libelle: 'actions.notifier',
							doAction: () => this.notificationService.showSelectionMailForEntite(this.getClassName(),this.demandeVehicule.idDemandeVehicule,{
								getOwningEntity: () => this.demandeVehicule,
								typeAttachment: TypeAttachment.DEMANDE_VEHICULE
							}),
							isVisible: () => hasNotification
						},{
							libelle: 'actions.transmettre',
							doAction: () => this.doAction('TRANSMETTRE'),
							isVisible: () => !this.isValidationMode && this.rightService.hasRight(null,'creation') && isWorkflowAvailable && this.demandeVehicule?.statut != 'VALIDEE' && this.isActionValid('TRANSMETTRE',this.demandeVehicule) && (!this.isGrilleActiveAvailable || this.demandeVehicule.modele)
						},{
							libelle: 'actions.commander',
							doAction: () => this.doAction('COMMANDER'),
							isVisible: () => !this.isValidationMode && this.rightService.hasRight(null,'creation') && (isWorkflowAvailable ? this.demandeVehicule.statut == 'VALIDEE' : !this.isGrilleActiveAvailable || this.demandeVehicule.modele) && this.isActionValid('COMMANDER',this.demandeVehicule)
						},{
							libelle: 'actions.rappeler',
							doAction: () => this.doAction('RAPPELER'),
							isVisible: () => !this.isValidationMode && this.rightService.hasRight(null,'creation') && this.isActionValid('RAPPELER',this.demandeVehicule)
						},{
							libelle: 'actions.livrer',
							doAction: () => this.showLivraison(),
							isVisible: () => this.rightService.hasRight(null,'creation') && this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') && this.isActionValid('LIVRER',this.demandeVehicule)
						},{
							libelle: 'actions.annuler',
							doAction: () => this.doAction('ANNULER'),
							isVisible: () => this.rightService.hasRight(null,'suppression') && this.isActionValid('ANNULER',this.demandeVehicule)
						},{
							libelle: 'actions.modifier',
							doAction: () => this.editDemandeVehicule(),
							isVisible: () => !this.isValidationMode && this.rightService.hasRight(null,'creation') && !['LIVRE','ANNULEE'].includes(this.demandeVehicule.statut),
							type: TypeAction.EDITION
						},{
							libelle: 'actions.consulter',
							doAction: () => this.editDemandeVehicule(),
							isVisible: () => this.isValidationMode && this.rightService.hasRight(null,'creation') || !this.rightService.hasRight(null,'creation') || ['LIVRE','ANNULEE'].includes(this.demandeVehicule.statut),
							type: TypeAction.CONSULTATION
						},{
							libelle: 'actions.supprimer',
							doAction: () => this.deleteDemandeVehicule(),
							isVisible: () => !this.isValidationMode && this.rightService.hasRight(null,'suppression') && this.isActionValid('SUPPRIMER',this.demandeVehicule),
							type: TypeAction.SUPPRESSION
						},{
							libelle: 'actions.valider',
							doAction: () => this.doAction('VALIDER'),
							isVisible: () => this.isValidationMode && this.isActionValid('VALIDER',this.demandeVehicule)
						},{
							libelle: 'actions.rejeter',
							doAction: () => this.doAction('REJETER'),
							isVisible: () => this.isValidationMode && this.isActionValid('REJETER',this.demandeVehicule)
						},{
							libelle: 'actions.workflow.consulter',
							doAction: () => this.workflowService.showWorkflowHistory(this.getClassName(),this.demandeVehicule.idDemandeVehicule),
							isVisible: () => isWorkflowAvailable
						}].filter(() => this.user.type != 'VIRTUAL'),
						listeAlertes: [{
							icon: 'directions_car',
							level: () => this.isGrilleActiveAvailable ? 'ERROR' : null,
							title: this.translateService.instant('demandeVehicule.alerte.modele.title') + (this.isGrilleActiveAvailable && isWorkflowAvailable && this.user.type != 'VIRTUAL' ? ' (' + this.translateService.instant('demandeVehicule.alerte.modele.titleTransmettre') + ')' : this.isGrilleActiveAvailable && !isWorkflowAvailable && user.type != 'VIRTUAL' ? ' (' + this.translateService.instant('demandeVehicule.alerte.modele.obligatoireCommander') + ')' : ''),
							message: this.translateService.instant('demandeVehicule.alerte.modele.message'),
							isVisible: () => !this.pageContentService.isOpened() && this.resume.nbModeles == 0 && (this.user.type != 'VIRTUAL' || this.demandeVehicule.selectionBeneficiaire && (!this.isGrilleActiveAvailable || this.isGrilleActiveForUser) && !['LIVRE','EN_COMMANDE','ANNULEE'].includes(this.demandeVehicule.statut) && !this.demandeVehicule.modele),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'creation') && !['VALIDEE','EN_COMMANDE','LIVRE','ANNULEE'].includes(this.demandeVehicule.statut),
							doAction: () => this.showAddModele()
						},{
							icon: 'directions_car',
							level: 'ERROR',
							title: this.translateService.instant('demandeVehicule.alerte.differenceModeles.title'),
							message: this.translateService.instant('demandeVehicule.alerte.differenceModeles.message'),
							isVisible: () => this.user.type != 'VIRTUAL' && this.demandeVehicule.vehicule && this.demandeVehicule.anomalieModele,
							doAction: () => this.complexPage.setSelectedElementByType('MODELE',true)
						},{
							icon: 'attach_money',
							title: this.translateService.instant('demandeVehicule.alerte.emptyFinancement.title'),
							message: this.translateService.instant('demandeVehicule.alerte.emptyFinancement.message'),
							isVisible: () => !this.pageContentService.isOpened() && this.user.type != 'VIRTUAL' && this.resume.nbFinancements == 0,
							isActionVisible: () => !['LIVRE','ANNULEE'].includes(this.demandeVehicule.statut) && this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'creation'),
							doAction: () => this.showAddFinancement()
						},{
							icon: 'directions_car',
							title: this.translateService.instant('demandeVehicule.alerte.cpi.title'),
							message: this.translateService.instant('demandeVehicule.alerte.cpi.message'),
							isVisible: () => !this.pageContentService.isOpened() && this.user.type != 'VIRTUAL' && this.resume.nbVehicules == 0 && this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') && this.demandeVehicule.statut == 'EN_COMMANDE',
							doAction: () => this.selectImmatriculation()
						},{
							icon: 'warning',
							level: 'INFO',
							title: this.translateService.instant('demandeVehicule.alerte.noAction.title'),
							message: this.translateService.instant('demandeVehicule.alerte.noAction.message'),
							isVisible: () => this.user.type == 'VIRTUAL' && (!this.demandeVehicule.selectionBeneficiaire || ['LIVRE','EN_COMMANDE','ANNULEE'].includes(this.demandeVehicule.statut) || this.resume.nbModeles == 1)
						},{
							icon: 'directions_car',
							level: 'ERROR',
							title: this.translateService.instant('demandeVehicule.alerte.noGrilleAttribution.title'),
							message: this.translateService.instant('demandeVehicule.alerte.noGrilleAttribution.message'),
							isVisible: () => this.user.type == 'VIRTUAL' && this.isGrilleActiveAvailable && !this.isGrilleActiveForUser && this.demandeVehicule.selectionBeneficiaire
						}],
						listeElements: [{
							type: 'MODELE',
							libelle: 'demandeVehicule.elements.modele',
							component: DemandeVehiculeModeleListComponent,
							count: () => this.resume?.nbModeles || 0,
							retrieveComponentData: () => ({
								demandeVehicule: this.demandeVehicule,
								resume: this.resume,
								listeModeles: this.getListeModeles(this.demandeVehicule),
								isValidationMode: this.isValidationMode,
								user: this.user,
								updateDemandeVehicule: (demandeVehicule: any) => {
									//Mise à jour de la demande de véhicule
									Object.assign(this.demandeVehicule,demandeVehicule);

									//Mise à jour du résumé
									this.resume.nbModeles = this.getListeModeles(this.demandeVehicule).length || 0;
								}
							}),
							isVisible: () => this.user.type != 'VIRTUAL' || this.demandeVehicule.modele,
							isTabHidden: this.user.type == 'VIRTUAL',
							doAction: () => this.showAddModele(),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'creation') && this.resume.nbModeles === 0 && ['VALIDEE','EN_COMMANDE','LIVRE','ANNULEE'].indexOf(this.demandeVehicule.statut) == -1,
							libelleAction: this.translateService.instant('demandeVehicule.actions.ajouterModele')
						},this.user.type != 'VIRTUAL' && this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'consultation') && {
							type: 'COTATION',
							libelle: 'demandeVehicule.elements.cotation',
							component: DemandeVehiculeCotationListComponent,
							retrieveComponentData: () => ({
								demandeVehicule: this.demandeVehicule,
								resume: this.resume,
								user: this.user,
								consultCotation: this.consultCotation,
								showCotation: this.showCotation
							}),
							count: () => this.resume.nbCotations,
							libelleAction: this.translateService.instant('demandeVehicule.elements.cotation.ajouter'),
							doAction: () => this.createDemandeCotation(),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'creation') && this.resume.nbCotations == 0 && this.resume.nbModeles == 1 && this.resume.nbFinancements == 0 && ['EN_COURS','A_VALIDER','VALIDEE'].indexOf(this.demandeVehicule.statut) > -1,
							isVisible: () => true
						},this.user.type != 'VIRTUAL' && this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'consultation') && {
							type: 'FINANCEMENT',
							libelle: 'demandeVehicule.elements.financement',
							component: DemandeVehiculeFinancementListComponent,
							retrieveComponentData: () => ({
								demandeVehicule: this.demandeVehicule,
								resume: this.resume,
								user: this.user,
								deleteFinancement: this.deleteFinancement,
								addFinancement: this.addFinancement
							}),
							doAction: () => this.showAddFinancement(),
							count: () => this.resume.nbFinancements,
							libelleAction: this.translateService.instant('demandeVehicule.elements.financement.ajouter'),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'creation') && this.resume.nbFinancements == 0 && this.demandeVehicule.statut != 'LIVRE' && this.demandeVehicule.statut != 'ANNULEE',
							isVisible: () => true
						},this.user.type != 'VIRTUAL' && {
							type: 'VEHICULE',
							libelle: 'demandeVehicule.elements.vehicule',
							component: DemandeVehiculeVehiculeListComponent,
							count: () => this.resume?.nbVehicules || 0,
							retrieveComponentData: () => ({
								demandeVehicule: this.demandeVehicule,
								resume: this.resume
							}),
							isVisible: () => this.demandeVehicule.statut != 'EN_COURS',
							doAction: () => this.selectImmatriculation(),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') && this.resume.nbVehicules === 0 && this.demandeVehicule.statut == 'EN_COMMANDE',
							libelleAction: this.translateService.instant('demandeVehicule.actions.ajouterVehicule')
						}].filter(e => !!e),
						isFormCustomization: this.user.type != 'VIRTUAL'
					};
				}
			});
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idDemandeVehicule: number): Observable<any> {
		//Chargement de la demande de véhicule
		return this.demandeVehiculeService.loadDemandeVehicule(idDemandeVehicule).pipe(
			first(),
			tap(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Vérification de la demande de véhicule
					if (result.data?.demandeVehicule) {
						//Définition de la demande de véhicule
						this.demandeVehicule = result.data.demandeVehicule;

						//Définition de la grille de fluidité
						this.grilleFluidite = result.data.grilleFluidite;

						//Vérification du mode validation
						this.isValidationMode = this.activatedRoute.snapshot.queryParams?.typeListe == 'A_VALIDER' && this.demandeVehicule.statut == 'A_VALIDER' && this.workflowService.isValideurFor(this.user,this.getClassName()) && result.data.workflowCheck?.valideurForObjet;

						//Récupération de l'indicateur de l'existence d'une grille d'attribution active
						this.isGrilleActiveAvailable = result.data.isGrilleActiveAvailable;

						//Récupération de la grille d'attribution spécifique
						this.grilleAttributionSpecifique = result.data.grilleAttributionSpecifique;

						//Vérification de l'utilisateur virtuel
						if (this.user.type == 'VIRTUAL')
							//Récupération de la présence de grille d'attribution active pour l'utilisateur
							this.isGrilleActiveForUser = result.data.isGrilleActiveForUser;

						//Initialisation des compteurs
						this.resume = {
							nbFinancements: this.demandeVehicule.financement ? 1 : 0,
							nbVehicules: this.demandeVehicule.vehicule ? 1 : 0,
							nbModeles: this.getListeModeles(this.demandeVehicule).length || 0,
							nbCotations: result.data.nbCotations || 0
						};
					} else {
						//Définition de la demande de véhicule
						this.demandeVehicule = history.state.demandeVehicule || { source: 'NOUVEAU_VEHICULE' };

						//Vérification de la présence d'un véhicule à renouveler
						if (this.demandeVehicule?.vehiculeSource) {
							//Chargement du véhicule
							this.vehiculeService.loadVehicule(this.demandeVehicule.vehiculeSource.idVehicule).pipe(first()).subscribe({
								next: result => {
									//Vérification du chargement
									if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
										//Mise à jour du véhicule
										this.demandeVehicule.vehiculeSource = result.data.vehicule;

										//Vérification de l'utilisateur affecté
										if (this.demandeVehicule.vehiculeSource?.extension?.userAffecte) {
											//Définition du bénéficiare
											this.demandeVehicule.typeBeneficiaire = 'COLLABORATEUR';
											this.demandeVehicule.user = this.demandeVehicule.vehiculeSource.extension.userAffecte;

											//Définition de la société et du service d'imputation
											this.demandeVehicule.societeImputation = this.demandeVehicule.vehiculeSource.extension.userAffecte.societe;
											this.demandeVehicule.serviceImputation = this.demandeVehicule.vehiculeSource.extension.userAffecte.service;

											//Vérification de l'affectation courante
											if (this.demandeVehicule.vehiculeSource.extension.affectationCourante)
												//Définition du type d'affectaton
												this.demandeVehicule.typeAffectation = this.demandeVehicule.vehiculeSource.extension.affectationCourante.typeAffectation;
										}

										//Edition de la demande de véhicule
										this.editDemandeVehicule();
									}
								}
							})
						}

						//Définition du résumé
						this.resume = {
							nbFinancements: 0,
							nbVehicules: 0,
							nbModeles: 0,
							nbCotations: 0
						};
					}

					//Initialisation du compteur de pièces-jointes
					this.user.type != 'VIRTUAL' && this.attachmentService.initAttachments(this);

					//Vérification mode 'Création'
					if (this.user.type != 'VIRTUAL' && !this.demandeVehicule?.idDemandeVehicule && !this.demandeVehicule?.vehiculeSource)
						//Affichage du formulaire d'édition
						this.editDemandeVehicule();
				}
			})
		);
	}

	/**
	 * Edition de la demande de véhicule
	 */
	public editDemandeVehicule() {
		//Ouverture du composant d'édition
		this.pageContentService.open(DemandeVehiculeEditComponent,{
			data: {
				demandeVehicule: this.demandeVehicule.idDemandeVehicule ? cloneDeep(this.demandeVehicule) : this.demandeVehicule,
				user: this.user,
				deleteDemandeVehicule: this.deleteDemandeVehicule.bind(this),
				isActionValid: this.isActionValid.bind(this)
			}
		}).subscribe(({ demandeVehicule,isGrilleActiveAvailable }: { demandeVehicule: any,isGrilleActiveAvailable: boolean }) => {
			//Vérification de la présence de données
			if (demandeVehicule) {
				//Mise à jour de la demande de véhicule
				Object.assign(this.demandeVehicule,demandeVehicule);

				//Définition de la disponibilité d'une grille d'attribution
				this.isGrilleActiveAvailable = isGrilleActiveAvailable;

				//Mise à jour de l'identifiant contenu dans l'URL
				this.layoutService.replaceUrlWith(this.demandeVehicule);
			}
		});
	}

	/**
	 * Suppression de la demande de véhicule
	 */
	protected deleteDemandeVehicule() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.demandeVehiculeService.deleteDemandeVehicule(this.demandeVehicule))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.demandeVehicule;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage de la popup d'ajout d'un financement
	 */
	private showAddFinancement() {
		//Récupération de la liste des types d'ajout de financement disponibles pour la demande
		this.demandeVehiculeService.retrieveListeTypesAjoutFinancement(this.demandeVehicule).subscribe({
			next: (listeTypesAjoutFinancement: Array<Action>) => {
				let actionObserver: any;

				//Observateur pour la souscription de l'action
				actionObserver = (result: any) => {
					//Vérification du type de retour
					if (result.type == DemandeVehiculeTypeAction.CREATE_DEMANDE_COTATION) {
						//Rafraichissement de l'onglet
						this.complexPage.setSelectedElementByType('COTATION',true);

						//Consultation de la cotation
						result.cotation && this.consultCotation(result.cotation);
					} else if (result.type == DemandeVehiculeTypeAction.SELECT_COTATION) {
						//Mise à jour de la demande de véhicule
						Object.assign(this.demandeVehicule,result.demandeVehicule);

						//Rafraichissement de l'onglet
						this.complexPage.setSelectedElementByType('COTATION',true);

						//Mise à jour des compteurs
						this.resume.nbFinancements = this.demandeVehicule.financement ? 1 : 0;
					} else if (result.type == DemandeVehiculeTypeAction.CREATE_FINANCEMENT) {
						//Ajout d'un financement
						this.addFinancement();
					} else if (result.type == DemandeVehiculeTypeAction.SELECT_FINANCEMENT) {
						//Mise à jour du financement de la demande de véhicule
						this.demandeVehicule.financement = Object.assign(result.financement,{
							idFinancement: null,
							modele: null,
							grilleAttribution: null
						});

						//Parcours des pièces jointes du financement
						(this.demandeVehicule.financement.listeLinks || []).forEach(link => {
							//Suppression de l'objet d'origine
							link.objet = null;

							//Modification du type de pièce jointe pour la désérialisation
							link._type = 'com.notilus.data.attachment.link.LinkDemandeVehiculeFinancement';
						});

						//Vérification de la grille de fluidité
						if (result.financement.grilleFluidite)
							//Mise à jour de la grille de fluidité
							this.grilleFluidite = Object.assign(this.grilleFluidite || {},result.financement.grilleFluidite);

						//Enregistrement de la demande de véhicule
						this.saveDemandeVehiculeFinancement();
					}
				};

				//Vérification de la liste
				if (listeTypesAjoutFinancement.length > 1) {
					//Ouverture de la popup de sélection du mode d'intégration
					this.demandeVehiculeService.selectActionAjoutFinancement(listeTypesAjoutFinancement).subscribe({
						next: (typeIntegration: Action) => {
							//Exécution de l'action choisie
							typeIntegration.doAction().subscribe(actionObserver);
						}
					});
				} else {
					//Exécution de la première action
					listeTypesAjoutFinancement[0].doAction().subscribe(actionObserver);
				}
			}
		});
	}

	/**
	 * Ajout d'un financement
	 */
	protected addFinancement(financement: any = { devise: this.user.devise,typeAffichagePrix: 'TTC' },deleteFinancement?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(DemandeVehiculeFinancementEditComponent,{
			data: {
				financement: cloneDeep(financement),
				demandeVehicule: this.demandeVehicule,
				grilleFluidite: this.grilleFluidite,
				deleteFinancement,
				onGrilleFluiditeSelected: grilleFluidite => {
					//Définition de la grille de fluidité
					this.grilleFluidite = grilleFluidite;
				}
			}
		},'sub').subscribe({
			next: (data: { financement: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Mise à jour du financement
					this.demandeVehicule.financement = data.financement;

					//Enregistrement de la demande de véhicule
					this.saveDemandeVehiculeFinancement();
				}
			}
		});
	}

	/**
	 * Accès à la cotation
	 */
	private consultCotation(cotation) {
		//Redirection vers la demande de cotation
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesCotation-detail',{
			routeParams: {
				idDemandeCotation: cotation.demandeCotation?.idDemandeCotation
			},
			routeData: {
				idCotation: cotation.idCotation,
				origine: 'DEMANDE_VEHICULE'
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Création/modification/suppression du financement par l'enregistrement de la demande de véhicule
	 */
	private saveDemandeVehiculeFinancement(close?: Function) {
		//Enregistrement de la demande de véhicule
		this.demandeVehiculeService.saveDemandeVehiculeFinancement(this.demandeVehicule.idDemandeVehicule,this.demandeVehicule.financement).subscribe(result => {
			//Vérification du code d'erreur
			if (result.codeErreur == 0) {
				//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
				close?.(null);

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la demande de véhicule
				this.demandeVehicule = result.data.demandeVehicule;

				//Raffraichissement du financement
				this.complexPage.setSelectedElementByType('FINANCEMENT',true);

				//Mise à jour des compteurs
				this.resume.nbFinancements = this.demandeVehicule.financement ? 1 : 0;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Suppression du financement
	 */
	private deleteFinancement(close?: Function) {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(filter(isConfirmed => !!isConfirmed)).subscribe(() => {
			//Retrait du financement
			this.demandeVehicule.financement.removed = true;

			//Enregistrement de la demande de véhicule
			this.saveDemandeVehiculeFinancement(close);
		});
	}

	/**
	 * Affichage d'une cotation
	 */
	private showCotation(cotation) {
		//Vérification du statut de la cotation
		if (['A_COMPLETER','A_TRANSMETTRE','ANNULEE'].indexOf(cotation.statut) > -1) {
			//Accès au formulaire complexe de la demande de cotation
			this.consultCotation(cotation);
		} else if (['EMISE_FOURNISSEUR','RETOUR_PARTIEL','RETOUR_COMPLET','SOLDEE'].indexOf(cotation.statut) > -1) {
			//Affichage de l'outil de comparaison
			this.cotationService.showComparaisonForCotation(cotation).subscribe({
				next: (result) => {
					//Vérification de la présence de la cotation
					if (result?.cotation)
						//Définition de la cotation
						Object.assign(cotation,result?.cotation);

					//Vérification de la création du financement
					if (result?.demandeVehicule) {
						//Mise à jour de la demande de véhicule
						Object.assign(this.demandeVehicule,result?.demandeVehicule)

						//Mise à jour du compteur
						this.resume.nbFinancements = this.demandeVehicule.financement ? 1 : 0;
					}
				}
			});
		}
	}

	/**
	 * Création d'une demande de cotation
	 */
	private createDemandeCotation() {
		let demandeCotation: any;
		let cotation: any;

		//Enregistrement de la demande de cotation
		this.demandeCotationService.saveDemandeCotation({ origine: 'DEMANDE_VEHICULE' }).subscribe({
			next: (result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Récupération de la demande de cotation
					demandeCotation = result.data.demandeCotation;

					//Définition d'une cotation associée au modèle de la demande de véhicule
					cotation = {
						modele: this.demandeVehicule.modele,
						demandeCotation: demandeCotation,
						demandeVehicule: this.demandeVehicule
					};

					//Enregistrement de la cotation
					this.cotationService.saveCotation(cotation).subscribe({
						next: (result) => {
							//Vérification du résultat
							if (result && result.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

								//Affichage de la liste des cotations
								this.complexPage.setSelectedElementByType('COTATION',true);

								//Affichage de la cotation
								this.consultCotation(result.data.cotation);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						}
					});
				} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification de la disponibilité de l'action
	 */
	public isActionValid: Function = (action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER' | 'SUPPRIMER' | 'LIVRER',demandeVehicule: any) => this.demandeVehiculeService.isActionValid(demandeVehicule,action);

	/**
	 * Réalisation de l'action Workflow
	 */
	public doAction(action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER') {
		//Réalisation de l'action Workflow
		this.demandeVehiculeService.doAction(action,this.demandeVehicule).subscribe({
			next: () => {
				//Vérification de l'action
				if (action == 'COMMANDER') {
					//Rechargement de la demande de véhicule
					this.demandeVehiculeService.loadDemandeVehicule(this.demandeVehicule.idDemandeVehicule).pipe(
							first(),
							filter(result => result.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.demandeVehicule),
							map(result => result.data?.demandeVehicule)
						).subscribe({
						next: demandeVehicule => {
							//Mise à jour de la demande de véhicule
							Object.assign(this.demandeVehicule,demandeVehicule);
						}
					});
				} else
					//Retour en arrière
					this.layoutService.goBack();
			}
		});
	}

	/**
	 * Affichage de la popup de livraison
	 */
	showLivraison() {
		let bsModalRef: BsModalRef<DemandeVehiculeLivraisonComponent>;
		let livraison: any;
		let canDuplicateAttachments: boolean;

		//Récupération de la possibilité de dupliquer les pièces jointes vers le véhicule livré
		canDuplicateAttachments = this.attachmentService.getNbAttachments() > 0 || this.demandeVehicule.financement?.listeLinks?.length > 0;

		//Définition de la livraison
		livraison = {
			idDemandeVehicule: this.demandeVehicule.idDemandeVehicule,
			objectVersion: this.demandeVehicule.objectVersion,
			vehicule: this.demandeVehicule.vehicule,
			societeImputation: this.demandeVehicule.societeImputation,
			serviceImputation: this.demandeVehicule.serviceImputation,
			createContrat: !!this.demandeVehicule.financement,
			createAffectation: !!this.demandeVehicule.user,
			takeOutVehicule: this.demandeVehicule.vehiculeSource && !this.demandeVehicule.vehiculeSource.dateSortie,
			typeAffectation: this.demandeVehicule.typeAffectation,
			montantParticipation: this.demandeVehicule.montantParticipation,
			montantAvantageNature: this.demandeVehicule.montantAvantageNature,
			duplicationAttachments: !!canDuplicateAttachments
		};

		//Affichage de la popup de livraison
		bsModalRef = this.bsModalService.show(DemandeVehiculeLivraisonComponent,{
			initialState: {
				demandeVehicule: this.demandeVehicule,
				livraison,
				canDuplicateAttachments,
				nbAttachments: this.attachmentService.getNbAttachments() || 0,
				user: this.user
			},
			class: 'modal-md',
			backdrop: 'static'
		});

		//Interception de la fermeture
		bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.savedLivraison),
			filter(livraison => !!livraison),
			switchMap(livraison => this.demandeVehiculeService.doLivraisonForDemandeVehicule(livraison)),
			first()
		).subscribe(livraisonResult => {
			//Message d'information
			this.toastrService.success(this.translateService.instant('demandeVehicule.actions.LIVRER.success'));

			//Mise à jour de la demande de véhicule
			this.demandeVehicule = Object.assign(this.demandeVehicule,livraisonResult.result.data.demandeVehicule);

			//Rafraichissement de l'onglet
			this.complexPage.setSelectedElementByType('VEHICULE',true);

			//Mise à jour des compteurs
			this.resume = {
				nbFinancements: this.demandeVehicule.financement ? 1 : 0,
				nbVehicules: this.demandeVehicule.vehicule ? 1 : 0,
				nbModeles:  this.getListeModeles(this.demandeVehicule).length || 0,
				nbCotations: this.resume.nbCotations
			};

			//Vérification que l'utilisateur n'a pas pu quitter la pop-up
			if (!livraisonResult.isDismissAllowed) {
				//Mise en cycle
				setTimeout(() => {
					//Redirection vers le véhicule
					this.layoutService.goToByState('listeVehicules-loadVehicule',{
						routeParams: {
							idVehicule: livraison.takeOutVehicule ? this.demandeVehicule.vehiculeSource.idVehicule : this.demandeVehicule.vehicule.idVehicule
						},
						routeData: {
							isSortieNeeded: livraison.takeOutVehicule
						}
					});
				});
			}
		});
	}

	/**
	 * Ajout d'un modèle dans la demande de véhicule
	 */
	private showAddModele(demandeVehicule = this.demandeVehicule) {
		//Affichage de la pop-up de sélection des modèles (grille d'attribution ou recherche avancée)
		this.demandeVehiculeService.showAddModele(demandeVehicule,{ isGrilleActiveAvailable: this.isGrilleActiveAvailable,user: this.user,grilleAttributionSpecifique: this.grilleAttributionSpecifique }).pipe(
			map(({ modele,grilleAttribution }: { modele: any,grilleAttribution: any }) => Object.assign(cloneDeep(demandeVehicule),{ modele,grilleAttribution })),
			switchMap((demandeVehicule: any) => this.demandeVehiculeService.saveDemandeVehicule(demandeVehicule))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Vérification de l'ajout du modèle
					if (!demandeVehicule?.modele && result.data?.demandeVehicule?.modele)
						//Incrémentation du compteur
						this.resume.nbModeles++;

					//Mise à jour de l'objet
					Object.assign(demandeVehicule,result.data.demandeVehicule);

					//Définition de la disponibilité d'une grille d'attribution
					this.isGrilleActiveAvailable = result.data.isGrilleActiveAvailable;

					//Affichage de la liste des modèle
					this.complexPage.setSelectedElementByType('MODELE',true);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Sélection de l'immatriculation
	 */
	private selectImmatriculation() {
		//Sélection de l'immatriculation
		this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any,vehicule: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Enregistrement du véhicule
				this.demandeVehiculeService.saveVehiculeForDemandeVehicule(this.demandeVehicule.idDemandeVehicule,data.immatriculation.idImmatriculation,data.params?.modele?.idModele || 0).subscribe(result => {
					//Vérification du code d'erreur
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR && result?.data?.demandeVehicule) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour de la demande de véhicule
						this.demandeVehicule = Object.assign(this.demandeVehicule,result.data.demandeVehicule);

						//Mise à jour du résumé
						this.resume.nbVehicules = 1;

						//Rafraichissement de l'onglet
						this.complexPage.setSelectedElementByType('VEHICULE',true);
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				});
			}
		},{
			isDateEntreeVisible: false,
			isWithEngin: false,
			useExistingImmatriculation: true,
			modeleInitial: this.demandeVehicule.modele,
			isSynchro: true
		}).subscribe();
	}

	/**
	 * Récupération de la liste des modèles
	*/
	private getListeModeles(demandeVehicule: any) {
		//Récupération de la liste des modèles
		return concat(demandeVehicule.modele && demandeVehicule.vehicule?.modeleConstructeur && demandeVehicule.vehicule.modeleConstructeur.idModele != demandeVehicule.modele.idModele && [Object.assign(demandeVehicule.vehicule.modeleConstructeur,{ isLivre: true })] || [],demandeVehicule.modele && [Object.assign(demandeVehicule.modele,{ isCommande: true })] || []);
	}
}