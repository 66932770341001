import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { environment } from 'src/environments/environment';

@Injectable()
export class MotifService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression d'un motif
	 */
	public deleteMotif(typeReferentiel: TypeReferentiel,motif: any): Observable<Result> {
		//Suppression du motif
		return this.http.delete<Result>(`${environment.baseUrl}/controller/WorkflowReferentiel/deleteMotif/${typeReferentiel}/${motif.idMotif}`);
	}

	/**
	 * Enregistrement du motif
	 */
	public saveMotif(typeReferentiel: TypeReferentiel,motif: any): Observable<Result> {
		//Enregistrement du motif
		return this.http.put<Result>(`${environment.baseUrl}/controller/WorkflowReferentiel/saveMotif/${typeReferentiel}`,motif);
	}
}