'use strict';

var URL = require('url-parse');
var debug = function () {};
if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')('sockjs-client:utils:url');
}
module.exports = {
  getOrigin: function (url) {
    if (!url) {
      return null;
    }
    var p = new URL(url);
    if (p.protocol === 'file:') {
      return null;
    }
    var port = p.port;
    if (!port) {
      port = p.protocol === 'https:' ? '443' : '80';
    }
    return p.protocol + '//' + p.hostname + ':' + port;
  },
  isOriginEqual: function (a, b) {
    var res = this.getOrigin(a) === this.getOrigin(b);
    debug('same', a, b, res);
    return res;
  },
  isSchemeEqual: function (a, b) {
    return a.split(':')[0] === b.split(':')[0];
  },
  addPath: function (url, path) {
    var qs = url.split('?');
    return qs[0] + path + (qs[1] ? '?' + qs[1] : '');
  },
  addQuery: function (url, q) {
    return url + (url.indexOf('?') === -1 ? '?' + q : '&' + q);
  },
  isLoopbackAddr: function (addr) {
    return /^127\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(addr) || /^\[::1\]$/.test(addr);
  }
};