import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { SinistreService } from './sinistre/sinistre.service';

@Component({
	templateUrl: './vehicule-sinistre-list-item.component.html'
})
export class VehiculeSinistreListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private sinistreService: SinistreService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteSinistre = this.deleteSinistre.bind(this)
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteSinistre(sinistre: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.sinistreService.deleteSinistre(sinistre))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Edition du sinistre
	 */
	addSinistre() {
		//Chargement complet du sinistre
		this.sinistreService.loadSinistre(this.data.idSinistre).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du sinistre
					Object.assign(this.data,result?.data?.sinistre);

					//Edition du sinistre
					this.extraOptions?.addSinistre?.(this.data,this.deleteSinistre);
				}
			}
		});
	}
}