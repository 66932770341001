<div class="modal-header">
	<h4 class="modal-title" translate>vehicule.sortie.title</h4>
</div>
<div class="modal-body">
	<form #vehiculeSortieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="alert alert-danger" *ngIf="!periodeValidity.isDateEntreeValid || !periodeValidity.isDateSortieValid">
			<icon name="warning"></icon>
			<div>
				<strong translate>vehicule.alerte.chevauchementPeriode.title</strong>
				<div *ngIf="!periodeValidity.isDateEntreeValid" translate>vehicule.alerte.chevauchementPeriode.affectationEntree</div>
				<div *ngIf="!periodeValidity.isDateSortieValid" translate>vehicule.alerte.chevauchementPeriode.affectationSortie</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.item</h4>
			</div>
		</div>
		<div class="form-group">
			<label translate>vehicule.immatriculation.item</label>
			<div *ngIf="vehicule.immatriculation?.typeSource == 'IMPORT' && configurationPays?.synchroSIV">
				<div class="input-group">
					<a [routerLink]="[]" (click)="showImmatriculation()"><span>{{ vehicule.reference }}</span></a>
					<div class="input-group-addon no-border">
						<span translate>vehicule.immatriculation.SIV</span>
					</div>
				</div>
			</div>
			<div *ngIf="vehicule?.immatriculation?.typeSource != 'IMPORT' || !configurationPays?.synchroSIV">
				<a [routerLink]="[]" (click)="showImmatriculation()"><span>{{ vehicule.reference }}</span></a>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': dateSortie.invalid }">
			<label translate>vehicule.dateSortie.item</label>
			<div class="half">
				<input type="text" name="dateSortie" class="form-control" #dateSortie="ngModel" [(ngModel)]="vehicule.dateSortie" (ngModelChange)="onDateSortieChange($event)" datePicker format="datetime" [minDate]="vehicule.dateEntree" required/>
			</div>
		</div>

		<ng-container *ngIf="currentAffectation">
			<div class="row">
				<div class="col-xs-12">
					<h4 translate>vehicule.sortie.affectationCourante</h4>
				</div>
			</div>
			<div class="form-group" *ngIf="currentAffectation.typeAffectation != 'VEHICULE_IMMOBILISE'" [ngClass]="{ 'has-error': user.invalid }">
				<label translate>vehicule.sortie.collaborateur</label>
				<div>
					<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="currentAffectation.user" required></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
				<label translate>vehicule.sortie.dateFinAffectation</label>
				<div class="half">
					<input type="text" name="dateFin" class="form-control" #dateFin="ngModel" [(ngModel)]="currentAffectation.dateFin" datePicker format="datetime" [minDate]="currentAffectation.dateDebut" [maxDate]="vehicule.dateSortie" required/>
				</div>
			</div>
		</ng-container>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="vehiculeSortieForm.invalid" (click)="saveVehicule()">
		<span translate>actions.enregistrer</span>
	</button>
</div>