<form #coursForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!cours.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': source.invalid }">
							<label translate>devise.cours.source.libelle</label>
							<div>
								<selectpicker name="source" #source="ngModel" [(ngModel)]="cours.source">
									<mat-option *ngFor="let source of listeSources" [value]="source"><span [translate]="'devise.cours.source.'+source?.toUpperCase()"></span></mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': devise.invalid }">
							<label translate>devise.cours.devise</label>
							<div>
								<autocomplete name="devise" type="devise" #devise="ngModel" [(ngModel)]="cours.deviseObject" [filter]="{ referentiel: 'STANDARD',idExcludedDevise: cours?.deviseContrepartieObject?.idDevise }" [disabled]="isEdition" (ngModelChange)="cours.devise = cours?.deviseObject?.code" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': deviseContrepartie.invalid }">
							<label translate>devise.cours.deviseContrepartie</label>
							<div>
								<autocomplete name="deviseContrepartie" type="devise" #deviseContrepartie="ngModel" [(ngModel)]="cours.deviseContrepartieObject" [filter]="{ referentiel: 'STANDARD',idExcludedDevise: cours?.deviseObject?.idDevise }" [disabled]="isEdition" (ngModelChange)="cours.deviseContrepartie = cours?.deviseContrepartieObject?.code" required></autocomplete>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': dateEchange.invalid }">
							<label translate>devise.cours.dateChange</label>
							<div class="half">
								<input type="text" class="form-control" name="dateEchange" #dateEchange="ngModel" [(ngModel)]="cours.dateEchange" datePicker [storeDate]="true" maxDate="today" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': coursValue.invalid }">
							<label translate>devise.cours.cours</label>
							<div class="half">
								<input type="text" nNumber="10" min="0" noZero="true" class="form-control" name="coursValue" #coursValue="ngModel" [(ngModel)]="cours.cours" required/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: coursForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteCours();" *ngIf="cours.idCours && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveCours();" *ngIf="null | right:'creation'" [disabled]="coursForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>