import { AfterViewInit,Component,forwardRef } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'color-picker',
	templateUrl: './color-picker.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => ColorPickerComponent),
		multi: true
	}]
})
export class ColorPickerComponent implements ControlValueAccessor,AfterViewInit {
	/** Valeur initiale du modèle **/
	initialValue: any;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Mode 'Ré-initialisation' désactivé **/
	isReset: boolean = false;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Dernière valeur **/
	lastValue: any;

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any): void {
		//Définition de la valeur
		this.value = value;
	}

	/**
	 * Enregistrement de la fonction d'interception du changement
	 */
	registerOnChange(fn: any): void {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement de la fonction d'interception de l'appui
	 */
	registerOnTouched(fn: any): void {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;
		});
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: string) {
		//Vérification de la valeur
		if (value !== undefined && !value?.startsWith('#'))
			//Ajout du '#'
			value = '#' + value;

		//Définition de la valeur initiale
		this.initialValue = value?.toUpperCase?.();

		//Vérification de la valeur
		if (value?.startsWith('#'))
			//Suppression de '#'
			value = value.replace('#','');

		//Déclenchement de l'interception du changement
		this.isInit && this.lastValue != value && this.onChange?.(value);

		//Sauvegarde de la valeur précédente
		this.lastValue = value;
	}

	/**
	 * Mise à jour la couleur
	 */
	updateColor(event: string) {
		//Vérification de la valeur
		if (event?.startsWith('#'))
			//Suppression du '#'
			event = event.replace('#','');

		//Vérification du mode 'Ré-initialisation' non activé
		if (!this.isReset)
			//Définition de la valeur
			this.value = event?.toUpperCase();

		//Mode 'Ré-initialisation' désactivé
		this.isReset = false;
	}

	/**
	 * Ré-initialisation de la valeur
	 */
	resetColor() {
		//Mode réinitialiser actif
		this.isReset = true;

		//Définition de la valeur à 'undefined'
		this.value = undefined;
	}
}