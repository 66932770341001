import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID,DEFAULT_CURRENCY_CODE } from '@angular/core';
import { HttpClient,provideHttpClient,withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { EffectsModule } from '@ngrx/effects';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { reducers,metaReducer } from './reducers/reducers';
import { AppComponent } from './app.component';
import { TestComponent } from './test.component';
import { ShareModule } from './share/share.module';
import { CoreModule } from './core/core.module';
import { ComponentModule } from './components/component.module';
import { LayoutModule } from './share/layout/layout.module';
import { LoggedUserService } from './share/login/logged-user.service';

//Définition de la locale
registerLocaleData(localeFr);

/**
 * Création d'un loader pour les traductions
 */
export function createTranslateLoader(http: HttpClient) {
	//Création du loader
	return new TranslateHttpLoader(http,`.${environment.production && '/app/browser' || ''}/assets/i18n/`,'.json?v='+new Date().getTime());
}

@NgModule({
	declarations: [AppComponent,TestComponent],
	imports: [BrowserModule,BrowserAnimationsModule,
		CoreModule,ShareModule,AppRoutingModule,ComponentModule,
		StoreModule.forRoot(reducers,{
			metaReducers: [metaReducer]
		}),
		EffectsModule.forRoot([]),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-left'
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		LayoutModule
	],
	providers: [CookieService,{
		provide: LOCALE_ID,
		useValue: 'fr-FR'
	},{
		provide: DEFAULT_CURRENCY_CODE,
		useValue: 'EUR'
	},{
		provide: LOCALE_ID,
		deps: [LoggedUserService],
		useFactory: (loggedUserService: LoggedUserService) => loggedUserService.getLocale()
	},{
		provide: DEFAULT_CURRENCY_CODE,
		deps: [LoggedUserService],
		useFactory: (loggedUserService: LoggedUserService) => loggedUserService.getDevise()
	},provideHttpClient(withInterceptorsFromDi())],
	bootstrap: [AppComponent],
})
export class AppModule { }
