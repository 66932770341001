import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DocumentService } from 'src/app/share/components/document/document.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { mapEntites } from './etat-lieux.entites';
import { EtatLieuxService } from './etat-lieux.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';

@Component({
	templateUrl: './etat-lieux-list-item.component.html'
})
export class EtatLieuxListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Statuts des états des lieux **/
	mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,public etatLieuxService: EtatLieuxService,private toastrService: ToastrService,private historyService: HistoryService
			,private mailHistoryService: MailHistoryService,public notificationService: NotificationService,private documentService: DocumentService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des statuts
		this.mapStatuts = this.etatLieuxService.getMapStatuts();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Suppression d'un état des lieux
	 */
	deleteEtatLieux() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'état des lieux
					this.etatLieuxService.deleteEtatLieux(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'état des lieux
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux);
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux);
	}

	/**
	 * Génération d'un document PDF
	 */
	generateDocument() {
		//Génération d'un document PDF
		this.documentService.generateDocument('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.ETAT_LIEUX
		});
	}

	/**
	 * Accéder à la réservation
	 */
	goToReservation(etatLieux: any = this.data) {
		//Navigation vers la réservation
		this.layoutService.goToByState('listeReservations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idReservation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: etatLieux.reservation.idReservation,
					title: this.translateService.instant('vehicule.etatLieux.navigationReservation',{ reference: etatLieux.vehicule.reference })
				}]
			}
		});
	}
}