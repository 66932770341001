import { Directive } from '@angular/core';
import { combineLatest,of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { ListView } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';

/**
 * Interface représentant une liste d'entités issues du référentiel mixte
 */
@Directive()
export abstract class ReferentielEntiteList {
	/** Nom de l'entité du référentiel **/
	abstract type: string;

	/** Endpoint de rafraichissement de la liste **/
	abstract getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string;

	/** Liste **/
	abstract liste: ListView<any,any>;

	/** Référentiel **/
	referentiel: { nbItems?: number,nbItemsForLocal?: number } = {};

	/**
	 * Constructeur
	 */
	constructor(protected referentielService: ReferentielService,protected rightService: RightService) {}

	/**
	 * Initialisation du composant
	 */
	init() {
		//Dénombrement des éléments
		combineLatest([!this.rightService.isRoot() ? this.referentielService.countItems([this.type]).pipe(map(result => result?.data[this.type])) : of(null)
			,!this.rightService.isRoot() ? this.referentielService.countItemsForLocal(this.getUrlForTypeListe(TypeReferentiel.LOCAL)).pipe(map(page => page?.totalElements)) : of(null)
		]).subscribe(([nbItems,nbItemsForLocal]) => {
			//Mise à jour du nombre d'éléments pour le type
			this.referentiel.nbItems = nbItems;
			this.referentiel.nbItemsForLocal = nbItemsForLocal;
		});
	}
}

/**
 * Enumération des types de référentiels
 */
export enum TypeReferentiel {
	/** Enumération **/
	LOCAL = 'LOCAL',
	STANDARD = 'STANDARD'
}

/**
 * Enumération des entités disposant d'un référentiel mixte
 */
export enum TypeEntiteWithReferentiel {
	/** Enumération **/
	DEVISE = 'DEVISE',
	FOURNISSEUR = 'FOURNISSEUR',
	TEMPLATE_MAIL = 'TEMPLATE_MAIL',
	STRUCTURE_EXPORT = 'STRUCTURE_EXPORT',
	WORKFLOW_MOTIF = 'WORKFLOW_MOTIF'
}