import { UserAddComponent } from 'src/app/components/user/user-add.component';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des utilisateurs
 */
export default class UserOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.user';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return filter?.typeRestrictionProfil ? `/controller/Profil/filtreUsersWithTypeRestriction/${filter.typeRestrictionProfil}` : `/controller/User/findUserListe${filter?.isShowAdministrateur ? '/true' : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.prenom} ${item.nom} (${item.matricule + (item.societe ? ' - ' + item.societe.libelle : '')})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return (item?.prenom?.substring(0,1)?.toUpperCase() || '') + (item?.nom?.substring(0,1)?.toUpperCase() || ''); }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.user.User';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idUser'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'nom',
			isDefault: true
		},{
			clef: 'prenom',
			isDefault: true
		},{
			clef: 'matricule',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [!filter?.isShowAll && {
			clef: filter?.typeRestrictionProfil ? 'isEnabled' : 'enabled',
			valeur: 'true',
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		}].filter(f => !!f);
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'user.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return UserAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_UTILISATEUR;
}