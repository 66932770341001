<div class="pull-left lv-avatar">{{ data.numero.toString().substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="showCompteAuxiliaire();">{{ data.numero + ' (' + data.libelle + ')' }}</a></div>
	<ul class="lv-attrs">
		<li><span translate>comptabilite.compteAuxiliaire.compteCollectif</span>&#160;:&#160;{{ data.compte.numero + ' (' + data.compte.libelle + ')' }}</li>
	</ul>

	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="!extraOptions?.compte"><a [routerLink]="[]" (click)="showCompte();" translate>comptabilite.actions.accederCompte</a></li>
			<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteCompteAuxiliaire();" translate>actions.supprimer</a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>