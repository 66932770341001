import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { User } from 'src/app/domain/user/user';
import { DemandeVehiculeModeleListItemComponent } from './demande-vehicule-modele-list-item.component';

@Component({
	selector: 'demande-vehicule-vehicule-list',
	templateUrl: './demande-vehicule-vehicule-list.component.html'
})
export class DemandeVehiculeModeleListComponent extends PageContentItem implements OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Résumé **/
	@Input() resume: { nbModeles: number };

	/** Liste des modèles **/
	@Input() listeModeles: any;

	/** Mode 'Validation' **/
	@Input() isValidationMode: boolean;

	/** Mise à jour de la demande de véhicule **/
	@Input() updateDemandeVehicule: Function;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Données **/
	liste: ListView<any,DemandeVehiculeModeleListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DemandeVehiculeModeleListItemComponent>({
			uri: () => null,
			component: DemandeVehiculeModeleListItemComponent,
			isLocal: true,
			noSearch: true,
			isLoadingDisabled: true,
			extraOptions: {
				demandeVehicule: this.demandeVehicule,
				resume: this.resume,
				isValidationMode: this.isValidationMode,
				updateDemandeVehicule: this.updateDemandeVehicule,
				user: this.user
			}
		});

		//Définition du contenu statique de la liste
		this.liste.data = {
			content: this.listeModeles
		};
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idModele > 0)
			//Mise à jour des compteurs
			this.resume.nbModeles--;
	}
}