import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@Component({
	selector: 'grille-attribution-modele-remise-edit',
	templateUrl: './grille-attribution-modele-remise-edit.component.html'
})
export class GrilleAttributionModeleRemiseEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() remise: any;

	/** Elément courant **/
	@Input() deleteRemise: (remise: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionModeleService: GrilleAttributionModeleService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveRemise() {
		//Enregistrement de la remise
		this.grilleAttributionModeleService.saveRemise(this.remise).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.remise,result.data?.grilleAttributionModeleRemise);

					//Fermeture de la fenêtre
					this.close({ remise: this.remise,configuration: result?.data?.configuration });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon)
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}