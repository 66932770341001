import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { LicenceConsommationElementListItemComponent } from './licence-consommation-element-list-item.component';

@Component({
	templateUrl: './licence-consommation-element-list.component.html'
})
export class LicenceConsommationElementListComponent implements OnInit {
	/** Consommation de licence **/
	@Input() licenceConsommation: any;

	/** Type **/
	@Input() type: any;

	/** Données **/
	liste: ListView<any,LicenceConsommationElementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LicenceConsommationElementListItemComponent>({
			uri: () => `/controller/Licence/filtreLicenceConsommationDetails/${this.licenceConsommation?.idLicenceConsommation}/${this.type}`,
			title: this.translateService.instant('licence.consommation.elements.vehicule.liste.title'),
			component: LicenceConsommationElementListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'identifiant',
				isDefault: true
			}]
		});
	}
}