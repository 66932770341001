import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des groupes
 */
export default class TypeFinancementOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.typeFinancement';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeReferentiel/listeTypeFinancementsActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.TypeFinancement';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTypeFinancement'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: { isWithGrilleFluidite?: boolean }): Array<Filter> {
		//Retour des filtres statiques
		return [!filter?.isWithGrilleFluidite && {
			clef: 'mode',
			valeur: 'GRILLE_FLUIDITE',
			typeComparaison: TypeComparaison.NOT_EQUALS,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}
}