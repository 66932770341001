import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { ConducteurService } from 'src/app/components/vehicule/conducteur/conducteur.service';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DemandeIdentificationService } from './demande-identification.service';

@Component({
	templateUrl: './demande-identification-designation.component.html'
})
export class DemandeIdentificationDesignationComponent extends PageContentItem implements OnInit {
	/** Demande d'identification **/
	@Input() demandeIdentification: any;

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Enregistrement de la demande d'identification **/
	@Input() saveDemandeIdentification: (demandeIdentification: any,isPublish: boolean,close?: Function) => void;

	/** Modification de la désignation **/
	@Input() editDesignation: Function;

	/** Suppression de la désignation **/
	@Input() deleteDesignation: Function;

	/** Liste des types de désignation **/
	public listeTypesDesignation: Array<{ code: string,_type?: string,libelle: string }>;

	/** Indicateurs de présence d'une adresse ou d'un permis de conduire **/
	private mapData: { isAdresse: boolean,isPermisConduire: boolean,adressePerso: any,permisConduire: any } = {
		isAdresse: false,
		isPermisConduire: false,
		adressePerso: null,
		permisConduire: null
	};

	/**
	 * Constructeur
	 */
	constructor(private demandeIdentificationService: DemandeIdentificationService,private affectationService: AffectationService,private conducteurService: ConducteurService,private confirmService: ConfirmService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de la demande d'identification
		this.demandeIdentification = cloneDeep(this.demandeIdentification);

		//Création de la désignation éventuelle
		this.demandeIdentification.designation = this.demandeIdentification.designation || {};

		//Récupération de la liste des types de désignation
		this.listeTypesDesignation = this.demandeIdentificationService.getListeTypesDesignation();

		//Déclenchement d'un changement de type de désignation
		this.isEdition && this.onTypeDesignationChange();

		//Recherche du conducteur associé à la désignation
		this.isEdition && this.demandeIdentification.typeDesignation == 'CONDUCTEUR' && this.demandeIdentification.designation.user && this.searchPermisAndAdresseConducteur(this.demandeIdentification.designation.user.idUser,true);
	}

	/**
	 * Affichage de l'historique des affectations
	 */
	showListeAffectations() {
		//Affichage de la liste des affectations du véhicule
		this.affectationService.showListeAffectations(this.demandeIdentification.vehicule).subscribe({
			next: affectation => {
				//Vérification de l'affectation sélectionnée
				if (affectation) {
					//Mise à jour de la désignation
					this.demandeIdentification.designation.user = affectation.user;

					//Déclenchement d'un changement de conducteur
					this.onConducteurChange();
				}
			}
		});
	}

	/**
	 * Affichage du permis de conduire
	 */
	showPermisConduire() {
		//Affichage du permis de conduire
		this.conducteurService.showPermisConduire(this.demandeIdentification.designation?.permisConduire || {},!this.isEdition).pipe(filter(p => !!p)).subscribe(permisConduire => {
			//Mise à jour du permis de conduire
			this.demandeIdentification.designation.permisConduire = permisConduire;
		});
	}

	/**
	 * Interception du changement de type de désignation
	 */
	onTypeDesignationChange() {
		//Vérification du type de désignation
		switch (this.demandeIdentification.typeDesignation) {
		case 'CONDUCTEUR':
			//Retrait de la société
			this.demandeIdentification.designation.societe = null;

			//Vérification de l'absence d'utilisateur
			if (!this.demandeIdentification.designation?.user) {
				//Vérification de la présence d'un conducteur théorique
				if (this.demandeIdentification.extension?.userTheorique) {
					//Définition du collaborateur affecté au véhicule lors de l'infraction
					this.demandeIdentification.designation.user = this.demandeIdentification.extension.userTheorique;

					//Recherche d'un permis de conduire et de l'adresse
					this.searchPermisAndAdresseConducteur(this.demandeIdentification.extension.userTheorique.idUser);
				}
			}

			//Vérification de la désignation et de la notification
			if (!this.demandeIdentification.designation.notification)
				//Initialisation de la demande de notification
				this.demandeIdentification.designation.notification = true;

			//Vérification de la désignation et de la transmission du mail collaborateur
			if (!this.demandeIdentification.designation.transmissionMail)
				//Initialisation de la demande de transmission du mail collaborateur
				this.demandeIdentification.designation.transmissionMail = true;
			break;
		case 'SOCIETE':
			//Retrait du conducteur
			this.demandeIdentification.designation.user = null;
			this.demandeIdentification.designation.adresse = null;
			this.demandeIdentification.designation.permisConduire = null;
			break;
		default:
			//Retrait du conducteur et de la société
			this.demandeIdentification.designation.user = null;
			this.demandeIdentification.designation.adresse = null;
			this.demandeIdentification.designation.permisConduire = null;
			this.demandeIdentification.designation.societe = null;
			break;
		}
	}

	/**
	 * Interception du changement de conducteur
	 */
	onConducteurChange() {
		//Recherche d'un permis de conduire et de l'adresse
		this.demandeIdentification.designation.user && this.searchPermisAndAdresseConducteur(this.demandeIdentification.designation.user.idUser);
	}

	/**
	 * Recherche du permis de conduire et de l'adresse du conducteur
	 */
	searchPermisAndAdresseConducteur(idUser: number,isWithoutUpdate: boolean = false) {
		//Recherche du permis de conduire et de l'adresse
		this.conducteurService.findPermisConducteur(idUser).subscribe({
			next: result => {
				//Vérification du retour
				if (result?.data) {
					//Vérification de la mise à jour
					if (!isWithoutUpdate)
						//Définition du permis de conduire
						this.demandeIdentification.designation.permisConduire = result.data.permisConduire;

					//Vérification du permis de conduire
					if (this.demandeIdentification.designation.permisConduire) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant
							this.demandeIdentification.designation.permisConduire.idPermis = null;

						//Mémorisation du permis de conduire
						this.mapData.permisConduire = result.data.permisConduire;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = true;
					} else {
						//Ré-initialisation du permis de conduire
						this.mapData.permisConduire = null;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = false;
					}

					//Vérification du conducteur
					if (result.data.user?.email && this.demandeIdentification.designation.user)
						//Définition du mail du conducteur
						this.demandeIdentification.designation.user.email = result.data.user.email;

					//Vérification de l'adresse du conducteur
					if (result.data.user?.userDescription?.adressePerso) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour de l'adresse
							this.demandeIdentification.designation.adresse = result.data.user.userDescription.adressePerso;

						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant de l'adresse
							delete this.demandeIdentification.designation.adresse.idAdresse;

						//Mémorisation de l'adresse
						this.mapData.adressePerso = result.data.user.userDescription.adressePerso;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = true;
					} else {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'adresse
							this.demandeIdentification.designation.adresse = null;

						//Ré-initialisation de l'adresse
						this.mapData.adressePerso = null;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = false;
					}
				}
			}
		});
	}

	/**
	 * Enregistrement de la désignation
	 */
	public saveDesignation() {
		let typeDesignation: { code: string,_type?: string,libelle: string };
		let doSave: (isPublish: boolean) => void

		//Recherche du type de désignation
		typeDesignation = this.listeTypesDesignation.find(t => t.code == this.demandeIdentification.typeDesignation);

		//Vérification du type de désignation
		if (typeDesignation._type)
			//Définition du type
			this.demandeIdentification.designation._type = typeDesignation._type;

		//Définition de la méthode d'enregistrement
		doSave = (isPublish: boolean) => {
			//Vérification du type de désignation
			if (this.demandeIdentification.typeDesignation == 'ENVOI_PAPIER')
				//Retrait de la désignation
				delete this.demandeIdentification.designation;

			//Enregistrement de la demande d'identification
			this.saveDemandeIdentification(this.demandeIdentification,isPublish,this.close);
		}

		//Vérification du mode de désignation
		if (this.demandeIdentification.typeDesignation == 'CONDUCTEUR') {
			//Comparaison des adresses
			this.mapData.isAdresse = isEqual(omit(this.demandeIdentification.designation.adresse,['idAdresse']),omit(this.mapData.adressePerso,['idAdresse']));

			//Comparaison des permis de conduire
			this.mapData.isPermisConduire = isEqual(omit(this.demandeIdentification.designation.permisConduire,['idPermis','user']),omit(this.mapData.permisConduire,['idPermis','user']));
		}

		//Vérification de l'absence d'adresse ou du permis de conduire
		if (this.demandeIdentification.typeDesignation == 'CONDUCTEUR' && (!this.mapData.isAdresse || !this.mapData.isPermisConduire)) {
			//Affichage du message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('demandeIdentification.designation.confirmation.title'),{
				withTitle: true,
				buttonType: 'YES_NO',
				actionColor: 'primary',
				alerteInfo: `
					<p></p>
					<div>
						<span>${this.translateService.instant('demandeIdentification.designation.confirmation.message')}</span>
						<ul class="p-t-40">
							${!this.mapData.isAdresse && this.demandeIdentification.designation.adresse ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.adressePersonnelle')}</b>&#160;:&#160;<span>${this.demandeIdentification.designation.adresse.label}</span></li>` : ''}
							${!this.mapData.isPermisConduire && this.demandeIdentification.designation.permisConduire ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.permisConduire')}</b>&#160;:&#160;<span>${this.demandeIdentification.designation.permisConduire.reference}</span></li>` : ''}
						</ul>
					</div>
				`
			}).subscribe({
				next: isConfirmed => {
					//Réalisation de l'enregistrement
					doSave(isConfirmed);
				}
			});
		} else {
			//Enregistrement de la demande
			doSave(false);
		}
	}
}