import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { CalendrierEntiteComponent } from './calendrier-entite.component';
import { CalendrierService } from './calendrier.service';

@Component({
	templateUrl: './calendrier-entite-list.component.html'
})
export class CalendrierEntiteListComponent {
	/** Liste des entités disponibles **/
	listeEntites: Array<any>;

	/** Récupération du calendrier **/
	getCalendrier: () => any;

	/** Mise à jour du calendrier **/
	onCalendrierUpdated: (calendrier: any) => void;

	/**
	 * Constructeur
	 */
	constructor(private calendrierService: CalendrierService,private entiteService: EntiteService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {}

	/**
	 * Récupération de la liste
	 */
	getListe() {
		//Retour de la liste triée
		return this.getCalendrier().listeDetails?.sort((a,b) => a.entite > b.entite ? 1 : -1);
	}

	/**
	 * Récupération de l'icône pour un détail
	 */
	getIconeFor(detail: any): { icone: string,iconeType?: string } {
		let entite;

		//Recherche de l'entité correspondante
		entite =  this.listeEntites?.filter(entite => entite.code == detail.entite)[0] || null;

		//Retour de l'icône
		return { icone: entite?.icone,iconeType: entite?.iconeType };
	}

	/**
	 * Récupération de la traduction d'une entité
	 */
	translateEntityCode(entite) {
		//Traduction de l'entité
		return this.entiteService.translateEntityCode(entite);
	}

	/**
	 * Affichage d'un détail
	 */
	showDetail(detail) {
		let idxDetail;

		//Recherche de la position de la ligne de détail dans la liste
		idxDetail = detail?.idDetail ? this.getCalendrier().listeDetails.findIndex(d => d === detail) : null;

		//Affichage du détail
		this.pageContentService.open(CalendrierEntiteComponent,{
			data: {
				calendrier: cloneDeep(this.getCalendrier()),
				detail: detail,
				listeEntites: this.listeEntites,
				idxDetail
			}
		},'sub').subscribe({
			next: (calendrier) => {
				//Vérification du calendrier
				if (calendrier)
					//Mise à jour du calendrier
					this.onCalendrierUpdated(calendrier);
			}
		});
	}

	/**
	 * Suppression d'un détail
	 */
	deleteDetail(detail) {
		let calendrier;

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Création d'une copie du calendrier
					calendrier = Object.assign({},this.getCalendrier());

					//Retrait du détail de la liste
					calendrier.listeDetails.splice(calendrier.listeDetails.findIndex(d => d === detail),1);

					//Enregistrement du calendrier
					this.calendrierService.saveCalendrier(calendrier).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Mise à jour du calendrier
								this.onCalendrierUpdated(result.data.calendrier);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}