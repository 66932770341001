<form #connecteurParamsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>connecteur.parametre.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': connecteurParamsForm.controls['value_'+idxParam]?.invalid }" *ngFor="let param of listeParams; let idxParam = index">
				<label>{{ param.param.libelle + ' (' + param.param.name + ')' }}</label>
				<div [ngClass]="['date'].includes(param.param.type) && 'half' || ''">
					<div class="input-group">
						<ng-container [ngSwitch]="param.param.type">
							<ng-container *ngSwitchCase="'string'">
								<input [type]="param.showPass || !param.param.secret ? 'text' : 'password'" [name]="'value_'+idxParam" #value="ngModel" class="form-control" [(ngModel)]="param.value" [required]="!param.param.optional" (ngModelChange)="param.valueUpdated = true"/>
								<span *ngIf="param.param.secret && canSeeSecret()" class="input-group-addon clickable" (click)="readParamSecret(param)">
									<icon [name]="param.showPass ? 'visibility_off' : 'visibility'"></icon>
								</span>
							</ng-container>
							<ng-container *ngSwitchCase="'date'">
								<input type="text" class="form-control" [name]="'value_'+idxParam" #value="ngModel" [(ngModel)]="param.dateValue" datePicker [required]="!param.param.optional" (ngModelChange)="param.valueUpdated = true"/>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveParams()" [disabled]="connecteurParamsForm.invalid" *ngIf="null | right:'creation'">
			<span [translate]="options?.buttonLabel || 'actions.enregistrer'"></span>
		</button>
	</div>
</form>