import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { UserCategorieModule } from './categorie/user-categorie.module';
import { DeviceModule } from './device/device.module';
import { UserAddComponent } from './user-add.component';
import { UserEditComponent } from './user-edit.component';
import { UserImputationEditComponent } from './user-imputation-edit.component';
import { UserImputationListItemComponent } from './user-imputation-list-item.component';
import { UserImputationListComponent } from './user-imputation-list.component';
import { UserListItemComponent } from './user-list-item.component';
import { UserListComponent } from './user-list.component';
import { UserProfilEditComponent } from './user-profil-edit.component';
import { UserProfilListComponent } from './user-profil-list.component';
import { UserUserResponsableEditComponent } from './user-user-responsable-edit.component';
import { UserUserResponsableListItemComponent } from './user-user-responsable-list-item.component';
import { UserUserResponsableListComponent } from './user-user-responsable-list.component';
import { UserComponent } from './user.component';
import { UserService } from './user.service';
import { UserAutorisationListComponent } from './user-autorisation-list.component';
import { UserAutorisationListItemComponent } from './user-autorisation-list-item.component';
import { ExtractionModule } from '../extraction/extraction.module';
import { UserExtractionListComponent } from './user-extraction-list.component';
import { UserExtractionListItemComponent } from './user-extraction-list-item.component';
import { UserExtractionEditComponent } from './user-extraction-edit.component';

@NgModule({
	imports: [ShareModule,UserCategorieModule,DeviceModule,ExtractionModule],
	declarations: [UserAddComponent,UserListComponent,UserListItemComponent,UserComponent,UserEditComponent,UserImputationListComponent,UserImputationListItemComponent,UserImputationEditComponent,UserProfilListComponent,UserProfilEditComponent,UserUserResponsableListComponent,UserUserResponsableListItemComponent,UserUserResponsableEditComponent,UserAutorisationListComponent,UserAutorisationListItemComponent
		,UserExtractionListComponent,UserExtractionListItemComponent,UserExtractionEditComponent
	],
	exports: [UserCategorieModule],
	providers: [UserService]
})
export class UserModule { }
