<tr class="nodes branch">
	<td class="node">
		<table [cellPadding]="0" [cellSpacing]="0">
			<tbody>
				<!-- Ligne pour une branche -->
				<ng-template [ngTemplateOutlet]="workflowBranchLine" [ngTemplateOutletContext]="{ position: 'top' }"></ng-template>

				<tr class="branch">
					<td [colSpan]="2" *ngFor="let branche of etape.listeBranches; let index = index">
						<table [cellPadding]="0" [cellSpacing]="0">
							<tbody>
								<!-- Branche -->
								<tr class="nodes">
									<td class="node" [colSpan]="2">
										<table [cellPadding]="0" [cellSpacing]="0">
											<tbody>
												<tr class="nodes">
													<td class="node">
														<!-- Détail de la branche -->
														<ng-template [ngTemplateOutlet]="workflowNodeBranche" [ngTemplateOutletContext]="{ branche,index }"></ng-template>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>

								<!-- Ligne horizontale -->
								<workflow-detail-line *ngIf="!branche.isCollapsed" mode="horizontal"></workflow-detail-line>

								<!-- Etape -->
								<ng-container *ngIf="!branche.isCollapsed && branche.listeEtapes?.length > 0">
									<workflow-detail-step *ngFor="let etape of branche.listeEtapes; let index = index" [index]="index" [workflow]="workflow" [etape]="etape" [currentBranche]="branche" [isEdition]="isEdition"></workflow-detail-step>
								</ng-container>

								<!-- Ligne horizontale -->
								<workflow-detail-line *ngIf="branche.isCollapsed" mode="counter-branche" [listeEtapes]="branche.listeEtapes" [index]="index"></workflow-detail-line>

								<!-- Bouton pour la branche -->
								<workflow-detail-button *ngIf="!branche.isCollapsed" [workflow]="workflow" [currentBranche]="branche" [isBranche]="true"></workflow-detail-button>

								<!-- Ligne étendue -->
								<tr class="extend">
									<td class="line line-left"></td>
									<td class="line line-right"></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>

				<!-- Ligne pour une branche -->
				<ng-template [ngTemplateOutlet]="workflowBranchLine" [ngTemplateOutletContext]="{ position: 'bottom' }"></ng-template>
			</tbody>
		</table>
	</td>
</tr>

<!-- Ligne pour les branches -->
<ng-template #workflowBranchLine ngModelGroup="workflowDetailStep" let-position="position">
	<tr class="lines">
		<ng-container *ngFor="let branche of etape.listeBranches; let first = first; let last = last">
			<td class="branchline line-left" [ngClass]="{ 'line-top': position == 'top' && !first,'line-bottom': position == 'bottom' && !first }"></td>
			<td class="branchline line-right" [ngClass]="{ 'line-top': position == 'top' && !last,'line-bottom': position == 'bottom' && !last }"></td>
		</ng-container>
	</tr>
</ng-template>

<!-- Détail de la branche -->
<ng-template #workflowNodeBranche let-branche="branche" let-index="index">
	<div class="node branch" [ngClass]="{ 'no-response': branche.libelle == null,'has-error': brancheLibelle.invalid }">
		<div class="content" (mouseenter)="branchKeyWithHover = (branche.idBranche || branche.key)" (mouseleave)="branchKeyWithHover = null">
			<div class="title" [title]="branche.libelle">
				<div [hidden]="branche.libelle === null">
					<div class="form-group">
						<div [ngClass]="{ 'input-group': isShowAddonForBranch(branche.idBranche || branche.key) }">
							<input type="text" [name]="'branche_libelle_' + (branche.idBranche || branche.key?.split('-').pop(-1))" #brancheLibelle="ngModel" data-test-id="brancheLibelleTestId" class="form-control" [(ngModel)]="branche.libelle" maxlength="300" [disabled]="!isEdition" [validator]="!(branche.libelle != null && !branche.rule)" [required]="branche.libelle != null"/>
							<div class="input-group-addon no-border" *ngIf="(null | right:'creation') && branche.libelle != null && !etape.readOnly && isShowAddonForBranch(branche.idBranche || branche.key)">
								<ul class="actions">
									<li #dropdownMenu (click)="branchKeyWithDropdown = (branche.idBranche || branche.key)">
										<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_horiz"></icon></a>
										<ul class="dropdown-menu dropdown-menu-right">
											<li>
												<a [routerLink]="[]" (click)="editRule(branche)">
													<span [translate]="'actions.'+(isEdition ? 'modifier' : 'consulter')"></span>
												</a>
											</li>
											<li *ngIf="isEdition && branche?.deletable">
												<a [routerLink]="[]" (click)="deleteBranch(index)" translate>actions.supprimer</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<span *ngIf="branche.libelle === null" translate>workflow.detail.branche.noResponse</span>
			</div>
		</div>
		<div class="collapse-container" (click)="branche.isCollapsed = !branche.isCollapsed">
			<icon [name]="!branche.isCollapsed ? 'expand_less' : 'expand_more'"></icon>
		</div>
	</div>
</ng-template>
