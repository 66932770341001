import { Component,Input,OnChanges,SimpleChanges,ViewChild } from '@angular/core';

import { Element } from 'src/app/domain/common/complex-page/element';
import { Options } from 'src/app/domain/common/complex-page/options';
import { ComplexPageElementsComponent } from './complex-page-elements.component';

@Component({
	selector: 'complex-page',
	templateUrl: './complex-page.component.html'
})
export class ComplexPageComponent implements OnChanges {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/** Composant de gestion des éléments **/
	@ViewChild('complexPageElements') complexPageElements: ComplexPageElementsComponent;

	/**
	 * Détection d'une modification de la donnée
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de l'initialisation du composant
		if (changes.data?.firstChange)
			//Mise à jour de l'élément sélectionné par défaut
			setTimeout(() => this.onActionSelected(this.options.listeElements?.filter(e => !e.isVisible || e.isVisible())?.filter(e => !e.isElementVisible || e.isElementVisible())?.[0]));
	}

	/**
	 * Sélection d'un élément dans les actions rapides
	 */
	onActionSelected(element: Element) {
		//Définition de l'élément sélectionné dans le composant de gestion des éléments
		element && this.complexPageElements?.setSelectedElement(element);
	}

	/**
	 * Définition de l'élément sélectionné (par son type)
	 */
	public setSelectedElementByType(type: string,forceRefresh: boolean = false) {
		let element: Element;

		//Vérification du besoin de charger la page
		if (forceRefresh || this.complexPageElements?.selectedElement?.type != type) {
			//Recherche de l'élément
			element = this.options.listeElements.find(e => e.type == type);

			//Sélection de l'élément
			this.onActionSelected(element);
		}
	}

	/**
	 * Vérification de la mise en plein écran
	 */
	public isFullScreen(): boolean {
		//Vérification de la mise en plein écran
		return typeof this.options?.isFullScreen == 'boolean' && this.options?.isFullScreen || typeof this.options?.isFullScreen == 'function' && this.options?.isFullScreen?.();
	}
}
