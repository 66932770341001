import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'chart-data-edit',
	templateUrl: './chart-data-edit.component.html'
})
export class ChartDataEditComponent extends PageContentItem {
	/** Graphique **/
	@Input() chart: any;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private extractionService: ExtractionService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Fermeture de l'écran de modification
	 */
	onClose(listeFields: Array<any>) {
		let extraction: any;
		let isFieldMissingForDefinition: boolean;

		//Vérification de la présence de données
		if (listeFields) {
			//Vérification de l'utilisation des clés/valeurs
			isFieldMissingForDefinition = this.chart.listeDefinitions?.length && !this.checkListeFields(listeFields);

			//Confirmation de l'enregistrement des colonnes
			(isFieldMissingForDefinition ? this.confirmService.showConfirm(this.translateService.instant('chart.confirmation.suppressionColonneUsed')) : of(true)).subscribe(isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Copie de l'extraction
					extraction = cloneDeep(this.chart.extraction);

					//Définition de la liste des champs
					extraction.listeFields = listeFields;

					//Enregistrement de l'extraction
					this.extractionService.saveExtraction(extraction).subscribe(result => {
						let listeDoublons = new Array<string>();

						//Vérification du code d'erreur
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour de l'extraction
							Object.assign(this.chart.extraction,result.data?.extraction);

							//Fermeture de la fenêtre
							this.close({
								chart: this.chart,
								isDeleteDefinition: isFieldMissingForDefinition
							});
						} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
							//Vérification du libellé
							if (result.data.doublon & Math.pow(2,0))
								//Ajout du libellé
								listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

							//Vérification de la référence
							if (result.data.doublon & Math.pow(2,1))
								//Ajout du reference
								listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
								field: listeDoublons.join(', ')
							}));
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						}
					});
				} else {
					//Fermeture de la fenêtre sans modifications
					this.close();
				}
			});
		} else
			//Fermeture de la fenêtre sans modifications
			this.close();
	}

	/**
	 * Vérification de la présence des clés/valeurs des définitions dans la liste des champs
	 * @returns _true_ si tous les champs utilisés par les définitions sont disponibles, _false_ sinon
	 */
	private checkListeFields(listeFields: Array<any>): boolean {
		let listeClesDefinition: Array<any>;

		//Récupération des noms des champs utilisés par les définitions (clés + valeurs)
		listeClesDefinition = [
			...this.chart.listeDefinitions.map(defintion => defintion.value),
			...this.chart.listeDefinitions.flatMap(definition => definition.listeCles.map(c => c.cle))
		];

		//Vérification que tous les champs utilisés sont disponibles
		return listeClesDefinition.filter(key => !!key).every(key => listeFields.some(f => f.name == key));
	}
}
