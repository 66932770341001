import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReponseCotationReponseContentComponent } from './reponse-cotation-reponse-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ReponseCotationReponseContentComponent],
	exports: [ReponseCotationReponseContentComponent]
})
export class ReponseDemandeCotationContentModule { }