import { NgModule } from '@angular/core';
import { ShareModule } from 'src/app/share/share.module';

import { ImportListItemComponent } from './import-list-item.component';
import { ImportListComponent } from './import-list.component';
import { ImportComponent } from './import.component';
import { ImportService } from './import.service';
import { ImportRelanceComponent } from './import-relance.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ImportListComponent,ImportListItemComponent,ImportComponent,ImportRelanceComponent],
	providers: [ImportService]
})
export class ImportModule {}