import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des unités
 */
export default class UniteOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.unite';

	/**
	 * Récupération de l'URL
	 */
	url(filter: 'CONTENANCE' | 'DISTANCE' | 'AUCUN'): string {
		//URL
		return `/controller/Unite/listeUnitesActives/${filter}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.libelleCourt})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Récupération du type de la classe
	 */
	type = 'com.notilus.data.unite.Unite';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idUnite'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		}];
	}
}