import { Component,Input } from '@angular/core';
import { uniqBy } from 'lodash-es';

@Component({
	selector: 'regle-automatique-check-selection',
	templateUrl: './regle-automatique-check-selection.component.html'
})
export class RegleAutomatiqueCheckSelectionComponent {
	/**	Règle automatique **/
	@Input() regleAutomatique: any;

	/** Liste des types de champ calculé **/
	@Input() readonly listeTypesComputedField: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>;

	/** Liste des entités disponibles **/
	public listeEntities: Array<{ code: string,libelle: string }>;

	/** Liste des champs disponibles **/
	public listeFields: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des entités
		this.listeEntities = uniqBy(this.listeTypesComputedField.map(type => ({ code: type.entity,libelle: type.libelleEntity })),'code').filter((value: any,index: number,self: Array<any>) => self.indexOf(value) === index);

		//Définition de la liste des types de règles automatiques disponibles pour l'entité
		this.onEntityChange();
	}

	/**
	 * Sélection d'une entité
	 */
	public onEntityChange(entity: any = this.regleAutomatique?.typeComputedField?.entity) {
		//Liste des types de règles automatiques disponibles pour l'entité
		this.listeFields = this.listeTypesComputedField.filter((type: any) => type.entity == entity);
	}

	/**
	 * Sélection d'un type de règle automatique
	 */
	public onTypeFieldChange(code: any) {
		//Mise à jour du type de règle automatique
		this.regleAutomatique.typeComputedField = this.listeTypesComputedField.find(type => type.code == code);
	}
}
