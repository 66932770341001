import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des modèles de véhicule
 */
export default class ModeleOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.modele';

	/**
	 * URL
	 */
	url(filter?: { typeSource: null | 'LOCAL' | 'EXTERNE_CATALOGUE' | 'EXTERNE_IMMATRICULATION' }): string {
		//Retour de l'URL
		return filter?.typeSource ? `/controller/VehiculeReferentiel/listeModelesActifs/${filter.typeSource}` : `/controller/VehiculeReferentiel/filtreModeles/HORS_CATALOGUE/LOCAL`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item.marque.libelle} ${item.libelle}${item.finition ? ' ' + item.finition : ''} (${item?.reference} - ${translateService.instant('referentiel.typeReferentiel.'+item?.typeSource)})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.VehiculeModele';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idModele'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		},{
			clef: '*marque.libelle',
			isDefault: true
		}];
	}

	/**
	 * Tri par défaut
	 */
	defaultOrder = 'libelle.raw,reference,marque.libelle.raw';

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idMarque && {
			clef: 'marque.idMarque',
			valeur: filter.idMarque,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Liste des champs supplémentaires pour la recherche avancée
		return [{
			title: 'vehicule.modele.reference',
			getValue: (item: any) => item?.reference
		},{
			title: 'vehicule.modele.typeSource.item',
			getValue: (item: any,translateService: TranslateService) => item?.typeSource ? translateService.instant('referentiel.typeReferentiel.'+item?.typeSource) : translateService.instant('common.nonDefini')
		}];
	}
}