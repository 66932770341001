<form #userDeviceListForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>user.device.liste.title</h4>
	</div>
	<div class="modal-body">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>