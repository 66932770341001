import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurInfractionListItemComponent } from './conducteur-infraction-list-item.component';

@Component({
	selector: 'conducteur-infraction-list',
	templateUrl: './conducteur-infraction-list.component.html'
})
export class ConducteurInfractionListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Résumé **/
	@Input() resume: { nbInfractions: number };

	/** Données **/
	liste: ListView<any,ConducteurInfractionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConducteurInfractionListItemComponent>({
			uri: () => `/controller/DemandeIdentification/filtreInfractionsByUser/${this.conducteur.user.idUser}`,
			component: ConducteurInfractionListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: '-dateInfraction'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idObject > 0) {
			//Mise à jour des compteurs
			this.resume.nbInfractions -= 1;

			//Rafraichissement de la liste
			this.liste.refresh();
		}
	}
}