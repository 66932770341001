import { Component,Input } from '@angular/core';

import { ChartOptions } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDashboard } from 'src/app/domain/dashboard/dashboard';

@Component({
	templateUrl: './dashboard-embedded.component.html'
})
export class DashboardEmbeddedComponent extends PageContentItem {
	/** Type du dashboard courant **/
	@Input() typeDashboard: TypeDashboard;

	/** Identifiant de l'objet courant **/
	@Input() idObjet?: number;

	/** Liste des graphiques statiques **/
	@Input() listeStaticCharts?: Array<{ options: ChartOptions,data: Page<any>,x: number,y: number,cols: number,rows: number }>;

	/** Profil du dashboard **/
	@Input() profil?: any;

	/** Identifiant du dashboard **/
	@Input() idDashboard?: number;

	/** Indicateur du scope du dashboard **/
	@Input() isScopeFormulaire?: boolean = false;

	/** Notification d'un changement du mode d'édition **/
	@Input() onSwitchEdition?: (isEdition: boolean) => boolean;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Fermeture du composant
	 */
	onDashboardClose(dashboard: any) {
		//Fermeture
		this.close(dashboard);
	}
}