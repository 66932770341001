import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { Droit } from 'src/app/domain/droit/droit';
import { TypeGroupe,TypeRestriction } from 'src/app/domain/security/right';
import { DroitService } from 'src/app/share/components/droit/droit.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'profil-droit',
	templateUrl: './profil-droit.component.html'
})
export class ProfilDroitComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() profil: any;

	/** Fonction d'édition **/
	@Input() editDroits: (typeGroupe: TypeGroupe) => void;

	/** Mode 'Edition' **/
	@Input() edition: { isEdition: boolean,typeGroupe?: TypeGroupe } =  { isEdition: false };

	/** Enregistrement de l'objet **/
	@Input() saveProfil: (profil: any,close: (data?: any) => void) => void;

	/** Types de groupe **/
	public TypeGroupe: typeof TypeGroupe = TypeGroupe;

	/**
	 * Constructeur
	 */
	constructor(private droitService: DroitService,private rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la possibilité de fermer le composant
		if (this.close)
			//Binding de la méthode
			this.close = this.close.bind(this);

		//Binding des méthodes
		this.applyRestriction = this.applyRestriction.bind(this);
	}

	/**
	 * Application d'une restriction
	 */
	applyRestriction(mapDroits: Map<any,any>): Map<any,any> {
		//Vérification du type de profil
		if ([TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL,TypeRestriction.CLIENT].indexOf(this.profil.typeRestriction) != -1)
			//Application des restrictions et retour de la map filtrée
			return this.doApplyRestriction(mapDroits);
		else
			//Retour de la map non filtrée
			return this.addUserDroit(mapDroits);
	}

	/**
	 * Ajout des informations du droit utilisateur dans la map de droits du profil
	 */
	private addUserDroit(mapDroits: Map<any,any>): Map<any,any> {
		const mapUserDroits = new Map<any,any>();

		//Parcours de la map de droits
		mapDroits.forEach((mapDroitsItem: Map<any,any> | Droit,key: any) => {
			//Vérification de la présence d'une map
			if (mapDroitsItem instanceof Map)
				//Ajout dans la map
				mapUserDroits.set(key,this.addUserDroit(mapDroitsItem));
			else if (mapDroitsItem.typeDroit)
				//Ajout du droit de l'utilisateur
				mapUserDroits.set(key,{ ...mapDroitsItem,userDroit: this.rightService.getRight(mapDroitsItem.typeDroit) });
		});

		//Retour de la map
		return mapUserDroits;
	}

	/**
	 * Application de la restriction pour une map de droits
	 */
	doApplyRestriction(mapDroits: Map<any,any>): Map<any,any> {
		const mapRestrictedDroits = new Map<any,any>();

		//Parcours de la map de droits
		mapDroits.forEach((item: Map<any,any> | Droit,key: any) => {
			let restrictedItem: Map<any,any>;

			//Vérification de la présence d'une map
			if (item instanceof Map) {
				//Application des restrictions sur la map
				restrictedItem = this.doApplyRestriction(item);

				//Vérification du nombre de droits restreints et ajout dans la map
				restrictedItem.size && mapRestrictedDroits.set(key,restrictedItem);
			} else if (item.typeDroit && this.droitService.getListeRestrictions(item.typeDroit).indexOf(this.profil.typeRestriction) != -1)
				//Ajout du droit
				mapRestrictedDroits.set(key,{ ...item,userDroit: this.rightService.getRight(item.typeDroit) });
		});

		//Retour de la map restreinte
		return mapRestrictedDroits;
	}

	/**
	 * Enregistrement des droits
	 */
	onSave(event: { listeDroits: Array<Droit>,close: Function }) {
		let profil: any;

		//Duplication du profil
		profil = cloneDeep(this.profil);

		//Définition des nouveaux droits
		profil.listeDroits = event?.listeDroits;

		//Enregistrement des droits
		this.saveProfil(profil,this.close.bind(this));
	}

	/**
	 * Passage en mode édition
	 */
	onEdit(typeGroupe: TypeGroupe) {
		//Affichage du composant d'édition des droits
		this.editDroits?.(typeGroupe);
	}

	/**
	 * Fermeture du composant de droits
	 */
	onClose() {
		//Fermeture du formulaire d'édition
		this.close(null);
	}
}