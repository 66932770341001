import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'extraction-field-edit',
	templateUrl: './extraction-field-edit.component.html'
})
export class ExtractionFieldEditComponent extends PageContentItem {
	/** Extraction **/
	@Input() extraction: any;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private extractionService: ExtractionService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Fermeture de l'écran de modification
	 */
	onClose(listeFields: Array<any>) {
		let extraction: any;

		//Vérification de la présence des données
		if (listeFields) {
			//Copie de l'extraction
			extraction = cloneDeep(this.extraction);

			//Définition de la liste des champs
			extraction.listeFields = listeFields;

			//Enregistrement de l'extraction
			this.extractionService.saveExtraction(extraction).subscribe({
				next: (result: Result) => {
					let listeDoublons = new Array<string>();

					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour de l'extraction
						Object.assign(this.extraction,result.data?.extraction);

						//Fermeture de la fenêtre
						this.close(this.extraction);
					} else {
						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
							//Vérification du libellé
							if (result.data.doublon & Math.pow(2,0))
								//Ajout du libellé
								listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

							//Vérification de la référence
							if (result.data.doublon & Math.pow(2,1))
								//Ajout du reference
								listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
								field: listeDoublons.join(', ')
							}));
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						}
					}
				}
			});
		} else
			//Fermeture de la fenêtre
			this.close();
	}
}
