<div class="modal-header">
	<h4 class="modal-title" translate>tenant.selectionContrat.title</h4>
</div>
<div class="modal-body">
	<form #selectionContratForm="ngForm" autocomplete="off">
		<div class="alert alert-info">
			<icon name="info"></icon>
			<div>
				<strong translate>tenant.selectionContrat.message</strong>
				<div translate>tenant.selectionContrat.description</div>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': selectionContratForm.invalid }">
			<label translate>tenant.contrat</label>
			<div>
				<autocomplete name="contrat" type="contratCRM" [(ngModel)]="contrat" required></autocomplete>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="selectionContratForm.invalid" (click)="bsModalRef.hide()">
		<span translate>actions.selectionner</span>
	</button>
</div>