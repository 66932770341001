import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Action,DataChange,Options } from 'src/app/domain/comparator/comparator';

@Component({
	selector: 'comparator',
	templateUrl: './comparator.component.html'
})
export class ComparatorComponent<T> implements OnInit,AfterContentChecked {
	/** Options du comparateur **/
	@Input() options: Options<T>;

	/** Liste des données **/
	@Input() listeItems: Array<T>;

	/** Notification d'un changement **/
	@Input() @Output() onDataChange: Subject<DataChange<T>>;

	/** Position **/
	public position: number = 0;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ComparatorComponent<T>>,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Abonnement au changement de données
		this.onDataChange.subscribe({
			next: ({ options,listeItems,canHide }) => {
				//Vérification de la présence des options
				if (options)
					//Définition des options
					this.options = options;

				//Définition de la liste des éléments
				this.listeItems = listeItems;

				//Vérification de la necéssité de fermer le composant
				if (canHide)
					//Fermeture du comparateur
					this.close();
			}
		});
	}

	/**
	 * Récupération de la liste des actions visibles
	 */
	getListeVisibleActions(item: any,listeActions: Array<Action<T>>): Array<Action<T>> {
		//Retour des actions visibles
		return listeActions?.filter(a => a.isVisible?.(item));
	}

	/**
	 * Fermeture du comparator
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}