import { Component,Input } from '@angular/core';

import { Action } from 'src/app/domain/common/complex-page/action';
import { Field } from 'src/app/domain/common/complex-page/field';
import { Options } from 'src/app/domain/common/complex-page/options';

@Component({
	selector: 'complex-page-info',
	templateUrl: './complex-page-info.component.html'
})
export class ComplexPageInfoComponent {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/** Affichage des informations secondaires **/
	public isDisplaySecondary: boolean = false;

	/**
	 * Récupération de la liste des actions visibles
	 */
	getListeVisibleActions(): Array<Action> {
		//Retour de la liste des actions visibles
		return this.options.listeActions?.filter(a => !a.isVisible || a.isVisible());
	}

	/**
	 * Vérification de la présence d'un champ secondaire dans la liste
	 */
	hasSecondaryFields(): boolean {
		//Vérification de la présence d'un champ secondaire
		return this.options?.listeFields.some(field => field.isSecondary);
	}

	/**
	 * Indicateur de visibilité du champ
	 */
	isFieldVisible(field: Field): any {
		//Vérification de la visibilité du champ
		return typeof field.isVisible == 'function' ? field.isVisible() : (field.isVisible || field.isVisible === undefined);
	}
}
