import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { DemandeVehiculeVehiculeListItemComponent } from './demande-vehicule-vehicule-list-item.component';

@Component({
	selector: 'demande-vehicule-vehicule-list',
	templateUrl: './demande-vehicule-vehicule-list.component.html'
})
export class DemandeVehiculeVehiculeListComponent extends PageContentItem implements OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Résumé **/
	@Input() resume: { nbVehicules: number };

	/** Données **/
	liste: ListView<any,DemandeVehiculeVehiculeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DemandeVehiculeVehiculeListItemComponent>({
			uri: () => null,
			component: DemandeVehiculeVehiculeListItemComponent,
			isLocal: true,
			noSearch: true,
			isLoadingDisabled: true,
			extraOptions: {
				demandeVehicule: this.demandeVehicule,
				resume: this.resume
			}
		});

		//Définition du contenu statique de la liste
		this.liste.data = {
			content: this.demandeVehicule.vehicule ? [this.demandeVehicule.vehicule] : []
		};
	}
}