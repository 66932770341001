import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minBy,orderBy,pull,unionBy } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map,take } from 'rxjs/operators';

import { OptionCategorieService } from '../option-categorie/option-categorie.service';
import { ConfigurationContrainteComponent } from './configuration-contrainte.component';
import { ConfigurationService } from './configuration.service';

@Component({
	selector: 'configuration-option-list-item',
	templateUrl: './configuration-option-list-item.component.html'
})
export class ConfigurationOptionListItemComponent {
	/** Détail **/
	@Input() detail: any;

	/** Configuration **/
	@Input() configuration: any;

	/** Indicateur d'affichage de la checkbox **/
	@Input() isShowCheckbox: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(public configurationService: ConfigurationService,private translateService: TranslateService,private bsModalService: BsModalService,public optionCategorieService: OptionCategorieService) { }

	/**
	 * Sélection/désélection d'un détail de la configuration
	 */
	public onOptionSelectionChange(detail: any) {
		let listeInclusions: Array<any>;
		let listeExclusions: Array<any>;
		let listeObligations: Array<any>;
		let contrainteObject: any;

		//Vérification du nouveau statut du détail
		if (detail?.selected) {
			//Vérification de la présence de contraintes
			if (detail.option?.listeContraintes?.length) {
				//Initialisation des éléments de contrainte
				listeInclusions = [];
				listeExclusions = [];
				listeObligations = [];

				//Définition de la contrainte
				contrainteObject = {
					detail: detail
				};

				//Parcours des contraintes
				detail?.option?.listeContraintes.forEach(contrainte => {
					//Vérification du type contrainte
					if (contrainte?.typeContrainte == 'OBLIGATION')
						//Ajout de la référence externe de la contrainte à la liste
						listeObligations.push(contrainte);
					else if (contrainte?.typeContrainte == 'EXCLUSION')
						//Ajout de la référence externe de la contrainte à la liste
						listeExclusions.push(contrainte.eqtextId);
					else if (contrainte?.typeContrainte == 'INCLUSION')
						//Ajout de la contrainte à la liste
						listeInclusions.push(contrainte);
				});

				//Récupération des détails associés aux contraintes
				contrainteObject.listeObligations = orderBy(listeObligations?.map(contrainte => ({ ...contrainte,detail: this.configuration?.listeDetails?.find(detail => detail?.option?.eqtextId == contrainte?.eqtextId) })).filter(contrainte => !!contrainte?.detail),['prixTTC','libelle'],['desc','asc']);
				contrainteObject.listeExclusions = listeExclusions?.length && this.configuration?.listeDetails?.filter(detail => detail?.selected && listeExclusions?.indexOf(detail.option?.eqtextId) != -1) || [];
				contrainteObject.listeInclusions = listeInclusions?.map(contrainte => ({ ...contrainte,detail: this.configuration?.listeDetails?.find(detail => detail?.option?.eqtextId == contrainte?.eqtextId) })).filter(contrainte => !!contrainte?.detail);
				contrainteObject.packDetail = detail?.option?.listePackDetails?.map(packDetail => packDetail.libelle).join(', ') || null;

				//Parcours des contraintes d'obligation
				contrainteObject?.listeObligations?.forEach(obligation => {
					//Parcours des sous-contraintes d'inclusion et d'exclusion engendrées par l'obligation
					obligation?.detail?.option?.listeContraintes?.filter(c => ['INCLUSION','EXCLUSION'].indexOf(c.typeContrainte) != -1).forEach(contrainte => {
						//Récupération du détail associé à la contrainte
						contrainte.detail = this.configuration.listeDetails.find((detail: any) => detail?.option?.eqtextId == contrainte?.eqtextId);
					});

					//Retrait des sous-contraintes ciblant des options indisponibles
					obligation.detail.option.listeContraintes = obligation?.detail?.option?.listeContraintes?.filter(contrainte => ['INCLUSION','EXCLUSION'].indexOf(contrainte.typeContrainte) == -1 || contrainte.detail);
				});

				//Vérification de la présence de contraintes calculées
				if (contrainteObject.listeObligations?.length || contrainteObject?.listeExclusions?.length || contrainteObject?.listeInclusions?.some(inclusion => !inclusion?.detail?.selected)) {
					//Affichage des contraintes
					this.showContrainte(detail,contrainteObject).pipe(take(1)).subscribe({
						next: (result: any) => {
							//Vérification de la présence d'un résultat
							if (result) {
								//Mise à jour des prix de la configuration
								this.configuration.prixTTC += result.prixTTCOptions;
								this.configuration.prixHT += result.prixHTOptions;

								//Vérification de la nécessité de cocher ou décocher des options
								if (result.listeIncludedOptions.length || result.listeRemoveOptions.length || result.listeObligedOptions.length) {
									//Parcours des détails
									this.configuration.listeDetails.forEach(d => {
										let inclusion;
										let obligation;

										//Vérification que l'option est couverte par une inclusion
										if (inclusion = result.listeIncludedOptions.find(option => option.detail == d)) {
											//Sélection de l'option
											d.selected = true;

											//Fusion des sources d'inclusion de l'option
											d.listeSourcesInclusion = unionBy(d.listeSourcesInclusion,inclusion.listeSourcesInclusion,'detail');

											//Suppression de l'indicateur d'option issue d'une obligation
											d.listeSourcesObligation = null;
										}

										//Vérification que l'option doit être dé-sélectionnée
										if (result.listeRemoveOptions.indexOf(d.option.eqtextId) != -1)
											//Désélection de l'option
											this.unselectOption(d);

										//Vérification que l'option est couverte par une obligation
										if (obligation = result.listeObligedOptions.find(option => option.eqtextId == d.option.eqtextId)) {
											//Sélection de l'option
											d.selected = true;

											//Fusion des sources d'obligation de l'option
											d.listeSourcesObligation = unionBy(d.listeSourcesObligation,[obligation.sourceObligation],'detail');
										}
									});
								}
							} else
								//Déselection
								detail.selected = false;
						}
					});
				} else {
					//Mise à jour des prix de la configuration
					this.configuration.prixTTC += detail.option?.prixTTC;
					this.configuration.prixHT += detail.option?.prixHT;

					//Parcours des contraintes d'inclusion
					contrainteObject?.listeInclusions?.forEach(inclusion => {
						let oldPrixHT;
						let oldPrixTTC;

						//Vérification de la situation initiale de l'option à inclure
						if (inclusion.detail.listeSourcesInclusion) {
							//Récupération du prix le plus faible obtenu par inclusion
							oldPrixHT = minBy(inclusion.detail.listeSourcesInclusion,(i: any) => i.contrainte.prixHT).contrainte.prixHT;
							oldPrixTTC = minBy(inclusion.detail.listeSourcesInclusion,(i: any) => i.contrainte.prixTTC).contrainte.prixTTC;

							//Retrait de la différence avec le nouveau prix d'inclusion s'il est inférieur
							this.configuration.prixHT -= inclusion.prixHT < oldPrixHT ? oldPrixHT - inclusion.prixHT : 0;
							this.configuration.prixTTC -= inclusion.prixTTC < oldPrixTTC ? oldPrixTTC - inclusion.prixTTC : 0;
						} else if (inclusion.detail.listeSourcesObligation) {
							//Récupération du prix le plus faible obtenu par obligation
							oldPrixHT = minBy(inclusion.detail.listeSourcesObligation,(o: any) => o.contrainte.prixHT).contrainte.prixHT;
							oldPrixTTC = minBy(inclusion.detail.listeSourcesObligation,(o: any) => o.contrainte.prixTTC).contrainte.prixTTC;

							//Remplacement du prix issu de l'obligation par celui de l'inclusion
							this.configuration.prixHT += inclusion.prixHT - oldPrixHT;
							this.configuration.prixTTC += inclusion.prixTTC - oldPrixTTC;
						} else {
							//Remplacement du prix hors contrainte par celui de l'inclusion
							this.configuration.prixHT += inclusion.prixHT - inclusion.detail.option.prixHT;
							this.configuration.prixTTC += inclusion.prixTTC - inclusion.detail.option.prixTTC;
						}

						//Ajout de l'option courante comme nouvelle source d'inclusion
						inclusion.detail.listeSourcesInclusion = unionBy(inclusion.detail.listeSourcesInclusion,[{ detail: detail,contrainte: inclusion }],'detail');

						//Suppression de l'indicateur d'option issue d'une obligation
						inclusion.detail.listeSourcesObligation = null;
					});
				}
			} else {
				//Mise à jour des prix de la configuration
				this.configuration.prixTTC += detail.option.prixTTC;
				this.configuration.prixHT += detail.option.prixHT;
			}
		} else {
			//Vérification que l'option était cochée par le biais d'une obligation
			if (detail.listeSourcesObligation) {
				//Retrait du prix de l'option en tant qu'obligation
				this.configuration.prixTTC -= minBy(detail.listeSourcesObligation,(o: any) => o.contrainte.prixHT).contrainte.prixHT;
				this.configuration.prixHT -= minBy(detail.listeSourcesObligation,(o: any) => o.contrainte.prixTTC).contrainte.prixTTC;
			} else {
				//Retrait du prix de l'option
				this.configuration.prixTTC -= detail.option.prixTTC;
				this.configuration.prixHT -= detail.option.prixHT;
			}

			//Désélection de l'option
			this.unselectOption(detail);
		}
	}

	/**
	 * Désélection d'une option avec recherche des inclusions et des obligations de l'option
	 */
	private unselectOption(detail: any,parent?: any) {
		let inclusionByParent: any;
		let obligationByParent: any;
		let newPrixHT: number;
		let newPrixTTC: number;

		//Récupération de la contrainte d'inclusion exercée par le parent sur l'option
		inclusionByParent = parent ? detail?.listeSourcesInclusion?.find(obligation => obligation.detail == parent) : null;

		//Récupération de la contrainte d'obligation exercée par le parent sur l'option
		obligationByParent = parent ? detail?.listeSourcesObligation?.find(inclusion => inclusion.detail == parent) : null;

		//Vérification que l'option doit être désélectionnée
		if (!parent || inclusionByParent && detail?.listeSourcesInclusion?.length == 1) {
			//Traitement de désélection
			detail.selected = false;

			//Suppression de l'indicateur d'option incluse
			detail.listeSourcesInclusion = null;

			//Suppression de l'indicateur d'option issue d'une obligation
			detail.listeSourcesObligation = null;

			//Vérification de la présence d'une contrainte d'inclusion
			if (inclusionByParent) {
				//Mise à jour des prix de la configuration
				this.configuration.prixTTC -= inclusionByParent?.contrainte?.prixTTC;
				this.configuration.prixHT -= inclusionByParent?.contrainte?.prixHT;
			}

			//Vérification de la présence de contraintes
			if (detail.option?.listeContraintes?.length) {
				//Parcours des détails
				this.configuration?.listeDetails?.forEach(d => {
					//Vérification que le détail est inclus ou forcé par l'option désélectionnée
					if (d.selected && d.listeSourcesInclusion?.some(inclusion => inclusion?.detail == detail))
						//Retrait du lien d'inclusion en cascade sur l'option
						this.unselectOption(d,detail);
					else if (d.selected && d.listeSourcesObligation?.some(obligation => obligation?.detail == detail))
						//Retrait du lien d'obligation en cascade sur l'option
						this.unselectOption(d,detail);
				});
			}
		} else if (inclusionByParent) {
			//Retrait de la source d'inclusion du détail
			pull(detail.listeSourcesInclusion,inclusionByParent);

			//Récupération du nouveau prix de l'option en tant qu'inclusion
			newPrixHT = minBy(detail.listeSourcesInclusion,(inclusion: any) => inclusion.contrainte?.prixHT)?.contrainte?.prixHT;
			newPrixTTC = minBy(detail.listeSourcesInclusion,(inclusion: any) => inclusion.contrainte?.prixTTC)?.contrainte?.prixTTC;

			//Ajout de la différence avec le nouveau prix d'inclusion s'il est supérieur
			this.configuration.prixHT += newPrixHT > inclusionByParent?.contrainte?.prixHT ? newPrixHT - inclusionByParent?.contrainte?.prixHT : 0;
			this.configuration.prixTTC += newPrixTTC > inclusionByParent?.contrainte?.prixTTC ? newPrixTTC - inclusionByParent?.contrainte?.prixTTC : 0;
		} else if (detail?.listeSourcesObligation?.length == 1) {
			//Suppression de l'indicateur d'option issue d'une obligation
			detail.listeSourcesObligation = null;

			//Remplacement du prix de l'obligation par le prix hors contrainte de l'option (elle reste sélectionnée)
			this.configuration.prixTTC += detail.option?.prixTTC - obligationByParent?.contrainte?.prixTTC;
			this.configuration.prixHT += detail.option?.prixHT - obligationByParent?.contrainte?.prixHT;
		} else {
			//Retrait du parent des sources d'obligation du détail
			pull(detail.listeSourcesObligation,obligationByParent);

			//Récupération du nouveau prix de l'option en tant qu'obligation
			newPrixHT = minBy(detail.listeSourcesObligation,(obligation: any) => obligation?.contrainte?.prixHT).contrainte?.prixHT;
			newPrixTTC = minBy(detail.listeSourcesObligation,(obligation: any) => obligation?.contrainte?.prixTTC).contrainte?.prixTTC;

			//Ajout de la différence avec le nouveau prix d'obligation s'il est supérieur
			this.configuration.prixHT += newPrixHT > obligationByParent?.contrainte?.prixHT ? newPrixHT - obligationByParent?.contrainte?.prixHT : 0;
			this.configuration.prixTTC += newPrixTTC > obligationByParent?.contrainte?.prixTTC ? newPrixTTC - obligationByParent?.contrainte?.prixTTC : 0;
		}
	}

	/**
	 * Récupération du titre du sélecteur d'option
	 */
	public getSelectorTitleForDetail(detail): string {
		//Vérification de la situation de l'option
		if (detail?.option.type == 'SERIE')
			//Équipement de série
			return this.translateService.instant('vehicule.modele.configuration.option.serie');
		else if (detail?.listeSourcesInclusion)
			//Récupération du parent source de l'inclusion au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.incluse',{ option: minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte.prixHT).detail?.option?.libelle });
		else if (detail?.listeSourcesObligation)
			//Récupération du parent source de l'obligation au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.ajoutee',{ option: minBy(detail.listeSourcesObligation,(o: any) => o.contrainte.prixHT).detail?.option?.libelle });
	}

	/**
	 * Récupération de la liste sérialisée des sous-catégories d'une option
	 */
	public getListeSubCategories(option): string {
		//Retour des libellés des sous-catégories
		return option?.listeSubCategories?.map(subCategorie => subCategorie?.libelle).join(', ');
	}

	/**
	 * Récupération du prix effectif d'un détail en tenant compte de ses liens d'inclusion et d'obligation
	 */
	public getActualPrice(detail: any): number {
		//Vérification de la situation de l'option
		if (detail?.listeSourcesInclusion)
			//Retour du prix le plus faible obtenu par inclusion
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixHT).contrainte?.prixHT;
		else if (detail?.listeSourcesObligation)
			//Retour du prix le plus faible obtenu par obligation
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixHT).contrainte?.prixHT;
		else
			//Retour du prix hors contrainte de l'option
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? detail?.option?.prixTTC : detail?.option?.prixHT;
	}

	/**
	 * Ouverture de la popup des containtes
	 */
	private showContrainte(detail: any,contrainteObject: any): Observable<any> {
		let bsModalRef: BsModalRef<ConfigurationContrainteComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ConfigurationContrainteComponent,{
			initialState: {
				configuration: this.configuration,
				contrainteObject,
				detail
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			take(1),
			map(() => bsModalRef.content.result?.detail)
		);
	}
}