import { NgModule } from '@angular/core';
import { ShareModule } from 'src/app/share/share.module';

import { GrilleAttributionListItemComponent } from './grille-attribution-list-item.component';
import { GrilleAttributionListComponent } from './grille-attribution-list.component';
import { GrilleAttributionComponent } from './grille-attribution.component';
import { GrilleAttributionService } from './grille-attribution.service';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';
import { GrilleAttributionEditComponent } from './grille-attribution-edit.component';
import { GrilleAttributionModeleListComponent } from './grille-attribution-modele-list.component';
import { GrilleAttributionModeleListItemComponent } from './grille-attribution-modele-list-item.component';
import { GrilleAttributionModeleModule } from './grille-attribution-modele/grille-attribution-modele.module';
import { GrilleAttributionSelectionAddModeleComponent } from './grille-attribution-selection-add-modele.component';

@NgModule({
	imports: [ShareModule,GrilleAttributionModeleModule],
	declarations: [GrilleAttributionListComponent,GrilleAttributionListItemComponent,GrilleAttributionComponent,GrilleAttributionEditComponent,GrilleAttributionModeleListComponent,GrilleAttributionModeleListItemComponent,GrilleAttributionSelectionAddModeleComponent],
	exports: [GrilleAttributionModeleModule],
	providers: [GrilleAttributionService,GrilleAttributionModeleService]
})
export class GrilleAttributionModule { }