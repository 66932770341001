<div class="pull-left lv-avatar">{{ data?.type?.substring(0,1)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/RegleAutomatique/loadRegleAutomatique/',data.idRegleAutomatique]">{{ data.typeComputedField?.libelle + ' - ' + (data.typeComputedField?.i18nRootKey + '.' + data.valeur | translate) }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idRegleAutomatique">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.dateApplication"><span translate>regleAutomatique.dateApplication</span>&#160;:&#160;<span>{{ data.dateApplication | date }}</span></li>
		<li *ngIf="data.typeComputedField?.libelleEntity"><span translate>regleAutomatique.entity</span>&#160;:&#160;<span>{{ data.typeComputedField?.libelleEntity }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idRegleAutomatique">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data?.rule?.listeDetails?.length && (null | right:'creation')"><a [routerLink]="[]" (click)="duplicateRegleAutomatique();"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteRegleAutomatique();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>