import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'conducteur-etat-lieux-edit',
	templateUrl: './conducteur-etat-lieux-edit.component.html'
})
export class ConducteurEtatLieuxEditComponent extends PageContentItem {
	/** État des lieux **/
	@Input() etatLieux: any;

	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Fermeture de l'état des lieux après enregistrement
	 */
	onContentClose(data: any) {
		//Fermeture du formulaire d'édition
		this.close({ etatLieux: data || null });
	}
}