import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { MarqueComponent } from 'src/app/components/vehicule/marque/marque.component';
import { MarqueListItemComponent } from './marque-list-item.component';
import { MarqueListComponent } from './marque-list.component';
import { MarqueService } from './marque.service';

@NgModule({
	imports: [ShareModule],
	declarations: [MarqueListComponent,MarqueListItemComponent,MarqueComponent],
	providers: [MarqueService]
})
export class MarqueModule { }