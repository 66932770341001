import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CotationModule } from './cotation/cotation.module';
import { DemandeCotationModule } from './demande-cotation/demande-cotation.module';
import { ReponseDemandeCotationModule } from './reponse-cotation/reponse-demande-cotation.module';

@NgModule({
	imports: [ShareModule,DemandeCotationModule,CotationModule,ReponseDemandeCotationModule],
	exports: [DemandeCotationModule,CotationModule,ReponseDemandeCotationModule]
})
export class CommandeModule {}