<form #userCategorieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>user.categorie.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>user.categorie.libelle</label>
			<div>
				<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="categorie.libelle" maxlength="300" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>user.categorie.reference</label>
			<div>
				<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="categorie.reference" maxlength="100" required/>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="userCategorieForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>