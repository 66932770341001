import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,skip,switchMap } from 'rxjs/operators';

import { EquipementService } from 'src/app/components/materiel/equipement/equipement.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReleveCompteurService } from './releve-compteur.service';

@Component({
	selector: 'releve-compteur-content',
	templateUrl: './releve-compteur-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ReleveCompteurContentComponent implements OnInit {
	/** Elément courant **/
	@Input() releveCompteur: any;

	/** Source **/
	@Input() source: 'RELEVE_COMPTEUR' | 'VEHICULE' | 'CONDUCTEUR' | 'EQUIPEMENT';

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des types de source **/
	public listeTypesSource: Array<{ code: string,libelle: string }>;

	/** Date courante **/
	public today = moment().startOf('day');

	/** Date du relevé précédent **/
	private previousDateReleve: Date = null;

	/**
	 * Constructeur
	 */
	constructor(private releveCompteurService: ReleveCompteurService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private layoutService: LayoutService
			,public rightService: RightService,public vehiculeService: VehiculeService,private datePipe: DatePipe,private decimalPipe: DecimalPipe,private equipementService: EquipementService,private messagingService: MessagingService) {
		//Binding des méthodes
		this.saveReleveCompteur = this.saveReleveCompteur.bind(this);
		this.deleteReleveCompteur = this.deleteReleveCompteur.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de source
		this.listeTypesSource = this.releveCompteurService.getListeTypesSource();

		//Exécution initiale des traitements sur la date du relevé pour les équipements
		this.source == 'EQUIPEMENT' && this.onDateChange();
	}

	/**
	 * Détection d'un changement de date de relevé
	 */
	onDateChange() {
		//Vérification du changement de date de relevé
		if (this.previousDateReleve != this.releveCompteur.dateReleve) {
			//Recherche du véhicule affecté à l'équipement à la date donnée
			this.equipementService.findVehiculeForDate(this.releveCompteur.equipement.idEquipement,this.releveCompteur.dateReleve).subscribe(result => {
				//Mise à jour du véhicule
				this.releveCompteur.vehicule = result?.data?.vehicule || null;
			});
		}

		//Mise à jour de la date du relevé précédente
		this.previousDateReleve = this.releveCompteur.dateReleve;
	}

	/**
	 * Enregistrement du relevé de compteur
	 */
	saveReleveCompteur() {
		let releveCompteur: any;
		let messaging$: MessagingObservables;
		let observablesSubject: Subject<boolean> = new Subject();

		//Copie du compteur avant sauvegarde
		releveCompteur = cloneDeep(this.releveCompteur);

		//Vérification de la provenance du relevé de compteur
		if (releveCompteur.typeSourceReleve == 'COLLABORATEUR')
			//Suppression des données liées au fournisseur
			delete releveCompteur.fournisseur;

		//Enregistrement du compteur avec rafraichissement des données calculées
		messaging$ = this.messagingService.init({
			method: 'PUT',
			entryPoint: 'controller/VehiculeCompteur/saveCompteur',
			outputPoint: '/messaging/vehiculeCompteur/saveCompteur/status',
			params: releveCompteur
		}).onFinish({
			next: () => {
				//Chargement complet du compteur
				releveCompteur.idCompteur && this.releveCompteurService.loadReleveCompteur(releveCompteur.idCompteur).subscribe({
					next: result => {
						//Mise à jour du relevé de compteur
						this.close.emit(result.data.compteur);
					},
					complete: () => {
						//Notification de la fin du traitement 'onFinish'
						observablesSubject.next(null);
					}
				});
			}
		}).onError({
			next: () => {
				//Notification de la fin du traitement 'onError'
				releveCompteur.idCompteur && observablesSubject.next(null);
			}
		}).onResult({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Vérification de la création d'un compteur
					if (!releveCompteur.idCompteur)
						//Fermeture du formulaire
						this.close.emit(result.data?.compteur);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Vérification de la présence de l'indicateur de cohérence
					if (result.data?.dateAndCompteurConflitByReleve) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('vehicule.releveCompteur.erreur.coherence',{
							dateReleve: this.datePipe.transform(result.data.dateAndCompteurConflitByReleve.date,'shortDate'),
							releveCompteur: this.decimalPipe.transform(result.data.dateAndCompteurConflitByReleve.compteur,'1.0-0')
						}));
					} else
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				//Notification de la fin du traitement 'onResult'
				observablesSubject.next(null);
			}
		});

		//Attente de la fin des traitements consécutifs à l'enregistrement
		observablesSubject.pipe(skip(releveCompteur.idCompteur ? 1 : 0),first()).subscribe(() => {
			//Fermeture des souscriptions
			messaging$.unsubscribe();
		});
	}

	/**
	 * Suppression du relevé de compteur
	 */
	deleteReleveCompteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.releveCompteurService.deleteReleveCompteur(this.releveCompteur))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	showVehicule() {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.releveCompteur.vehicule?.idVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers l'équipement
	 */
	redirectToEquipement() {
		//Redirection vers l'équipement
		this.layoutService.goToByState('materielReferentiel-listeEquipements-loadEquipement',{
			routeParams: {
				idEquipement: this.releveCompteur.equipement.idEquipement
			},
			withGoBack: true
		});
	}
}