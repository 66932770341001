import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-dimension',
	templateUrl: './modele-dimension.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleDimensionComponent {
	/** Dimensions **/
	@Input() dimension: any;
}
