import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';

import { PosteChargeCompteEditComponent } from 'src/app/components/comptabilite/poste-charge/poste-charge-compte-edit.component';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ServiceAliasEditComponent } from './service-alias-edit.component';
import { ServiceAliasListComponent } from './service-alias-list.component';
import { ServiceCompteListComponent } from './service-compte-list.component';
import { ServiceEditComponent } from './service-edit.component';
import { ServiceFacturationEditComponent } from './service-facturation-edit.component';
import { ServiceFacturationListComponent } from './service-facturation-list.component';
import { ServicePlanComptableListComponent } from './service-plan-comptable-list.component';
import { ServiceServiceListComponent } from './service-service-list.component';
import { ServiceUserListComponent } from './service-user-list.component';
import { ServiceVehiculeListComponent } from './service-vehicule-list.component';
import { ServiceService } from './service.service';

@Component({
	selector: 'service',
	templateUrl: './service.component.html'
})
export class ServiceComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Service courant **/
	public service: any;

	/** Résumé **/
	private resume: { nbComptes: number,nbComptesFacturation: number,nbPlansComptable: number,nbServices: number,nbUsers: number,nbAlias: number,nbVehicules: number } = {
		nbComptes: 0,
		nbComptesFacturation: 0,
		nbPlansComptable: 0,
		nbServices: 0,
		nbUsers: 0,
		nbAlias: 0,
		nbVehicules: 0
	}

	/** Utilisateur connecté **/
	private user: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private serviceService: ServiceService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private store: Store<AppState>) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.service.Service';

	/**
	 * Récupération de l'identifiant du service à charger
	 */
	getIdObject: () => number = () => this.service?.idService || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.service;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'service.libelle',
				key: 'libelle'
			},{
				libelle: 'service.reference',
				key: 'reference'
			},{
				libelle: 'service.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			},{
				libelle: 'service.pays',
				key: 'pays',
				format: 'pays',
				type: 'DISPLAY'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editService(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editService(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteService(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'n-icon-plan-comptable',
				iconType: 'icon-venus',
				title: this.translateService.instant('service.alerte.planComptable.title'),
				message: this.translateService.instant('service.alerte.planComptable.message'),
				isVisible: () => this.resume.nbPlansComptable == 0 && this.service.type == 'SOCIETE' && !this.pageContentService.isOpened(),
				doAction: () => this.addPlanComptable(),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation')
			}],
			listeElements: [{
				type: 'SERVICE',
				libelle: 'service.elements.service',
				component: ServiceServiceListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addService: this.addService.bind(this)
				}),
				doAction: () => this.addService(),
				count: () => this.resume.nbServices,
				libelleAction: this.translateService.instant('service.elements.service.ajouter'),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			},{
				type: 'PLAN_COMPTABLE',
				libelle: 'service.elements.planComptable',
				component: ServicePlanComptableListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addPlanComptable: this.addPlanComptable.bind(this)
				}),
				doAction: () => this.addPlanComptable(),
				count: () => this.resume.nbPlansComptable,
				libelleAction: this.translateService.instant('service.elements.planComptable.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation') && this.service.type == 'SOCIETE'
			},{
				type: 'COMPTE',
				libelle: 'service.elements.compte',
				component: ServiceCompteListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addCompte: this.addCompte.bind(this)
				}),
				doAction: () => this.addCompte(),
				count: () => this.resume.nbComptes,
				libelleAction: this.translateService.instant('service.elements.compte.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation') && this.service.type == 'SOCIETE'

			},{
				type: 'FACTURATION',
				libelle: 'service.elements.facturation',
				component: ServiceFacturationListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addLienFacturation: this.addLienFacturation.bind(this)
				}),
				doAction: () => this.addLienFacturation(),
				count: () => this.resume.nbComptesFacturation,
				libelleAction: this.translateService.instant('service.elements.facturation.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'consultation') && this.service.type == 'SOCIETE'
			},{
				type: 'USER',
				libelle: 'service.elements.user',
				component: ServiceUserListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addUser: this.addUser.bind(this)
				}),
				doAction: () => this.addUser(),
				count: () => this.resume.nbUsers,
				libelleAction: this.translateService.instant('service.elements.user.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'consultation')
			},{
				type: 'ALIAS',
				libelle: 'service.elements.alias',
				component: ServiceAliasListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume,
					addAlias: this.addAlias.bind(this)
				}),
				doAction: () => this.addAlias(),
				count: () => this.resume.nbAlias,
				libelleAction: this.translateService.instant('service.elements.alias.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.FACTURE_LAD,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE_LAD,'consultation') && this.service.type == 'SOCIETE'
			},{
				type: 'VEHICULE',
				libelle: 'service.elements.vehicule',
				component: ServiceVehiculeListComponent,
				retrieveComponentData: () => ({
					service: this.service,
					resume: this.resume
				}),
				count: () => this.resume.nbVehicules,
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')
			}]
		};

		//Récupération de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: (user: User) => {
				//Définition de l'utilisateur connecté
				this.user = user;

				//Récupération de l'identifiant du service à charger
				this.activatedRoute.params.pipe(first()).subscribe({
					next: params => {
						//Chargement des données
						this.loadData(params.idService);
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idService: number,service = history.state.service) {
		//Enregistrement du service
		this.serviceService.loadService(idService).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du service
					this.service = result.data?.service;

					//Définition du résumé
					this.resume = {
						nbComptes: result.data?.nbComptes || 0,
						nbComptesFacturation: result.data?.nbComptesFacturation || 0,
						nbPlansComptable: result.data?.nbPlansComptable || 0,
						nbServices: result.data?.nbServices || 0,
						nbUsers: result.data?.nbUsers || 0,
						nbAlias: result.data?.nbAlias || 0,
						nbVehicules: result.data?.nbVehicules || 0
					}

					//Vérification de l'identifiant du service
					if (!this.service?.idService) {
						//Création d'un objet vierge
						this.service = {
							actif: true,
							pays: service?.pays || this.user.tenant?.tenantDescription?.pays,
							typeCalculAvantageNature: null,
							...service
						};

						//Edition du service
						this.editService(service != null);
					}
				}
			}
		});
	}

	/**
	 * Edition du service
	 */
	private editService(isForceParent: boolean = false) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ServiceEditComponent,{
			data: {
				service: cloneDeep(this.service),
				deleteService: this.deleteService.bind(this),
				isForceParent
			}
		}).subscribe({
			next: (service: any) => {
				//Vérification des données
				if (service) {
					//Mise à jour de l'service
					this.service = Object.assign(this.service,service);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.service);
				}
			}
		});
	}

	/**
	 * Suppression du service
	 */
	protected deleteService() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.serviceService.deleteService(this.service))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout d'un service
	 */
	protected addService(service?: any,parent: any = this.service) {
		//Navigation vers le service
		this.layoutService.goToByState('listeServices-service',{
			routeParams: {
				idService: service?.idService || 0
			},
			routeData: {
				service: !service ? {
					parent,
					societe: parent?.societe || parent,
					type: 'SERVICE'
				} : null
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Ajout d'un plan comptable
	 */
	protected addPlanComptable(planComptable?: any,service: any = this.service) {
		//Navigation vers le plan comptable
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: planComptable?.idPlanComptable || 0
			},
			routeData: {
				planComptable: !planComptable ? {
					listeSocietes: [service]
				} : null
			},
			reloadOnSameUrl: true,
			withGoBack: true
		});
	}

	/**
	 * Ajout d'un compte
	 */
	protected addCompte(lien: any = { societe: Object.assign({},this.service),type: 'SOCIETE' },deleteLienCompteDetail?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(PosteChargeCompteEditComponent,{
			data: {
				lien: cloneDeep(lien),
				deleteLienCompteDetail,
				source: 'BANQUE'
			}
		},'sub').subscribe({
			next: (data: { lien: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('COMPTE',lien.idLien != data.lien?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lien.idLien && data.lien?.idLien)
						//Incrémentation du compteur
						this.resume.nbComptes++;
					else if (lien.idLien && !data.lien?.idLien)
						//Décrémentation du compteur
						this.resume.nbComptes--;

					//Mise à jour du compte
					Object.assign(lien,data.lien);
				}
			}
		});
	}

	/**
	 * Modification du compte de facturation
	 */
	protected addLienFacturation(lienFacturation: any = { societe: Object.assign({},this.service),type: 'SOCIETE' },deleteLienFacturation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ServiceFacturationEditComponent,{
			data: {
				lienFacturation: cloneDeep(lienFacturation),
				deleteLienFacturation
			}
		},'sub').subscribe({
			next: (data: { lienFacturation: any }) => {
				//Vérification de la présence de donnée
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FACTURATION',lienFacturation.idLien != data.lienFacturation?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienFacturation.idLien && data.lienFacturation?.idLien)
						//Incrémentation du compteur
						this.resume.nbComptesFacturation++;
					else if (lienFacturation.idLien && !data.lienFacturation?.idLien)
						//Décrémentation du compteur
						this.resume.nbComptesFacturation--;

					//Mise à jour du lien
					Object.assign(lienFacturation,data.lienFacturation);
				}
			}
		});
	}

	/**
	 * Affichage de l'utilisateur
	 */
	protected addUser(user?: any,service: any = this.service) {
		//Navigation vers l'utilisateur
		this.layoutService.goToByState('listeUsers-user',{
			routeParams: {
				idUser: user?.idUser || 0
			},
			routeData: {
				user: !user ? {
					societe: service.type == 'SOCIETE' ? service : service?.societe,
					service: service.type == 'SERVICE' ? service : null
				} : null
			},
			reloadOnSameUrl: true,
			withGoBack: true
		});
	}

	/**
	 * Modification de l'alias
	 */
	protected addAlias(alias: any = { societe: Object.assign({},this.service) },deleteAlias?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ServiceAliasEditComponent,{
			data: {
				alias: cloneDeep(alias),
				deleteAlias
			}
		},'sub').subscribe({
			next: (data: { alias: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('ALIAS',alias.idAlias != data.alias?.idAlias);

					//Vérification de la présence d'une création ou d'une suppression
					if (!alias.idAlias && data.alias?.idAlias)
						//Incrémentation du compteur
						this.resume.nbAlias++;
					else if (alias.idAlias && !data.alias?.idAlias)
						//Décrémentation du compteur
						this.resume.nbAlias--;

					//Mise à jour de l'alias
					alias = Object.assign(alias,data.alias);
				}
			}
		});
	}
}