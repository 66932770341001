import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { StructureExportLineItemComponent } from './structure-export-line-item.component';
import { StructureExportRubriqueImportComponent } from './structure-export-rubrique-import.component';
import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export-line',
	templateUrl: './structure-export-line.component.html'
})
export class StructureExportLineComponent extends PageContentItem implements OnInit {
	/** Structure d'export courante **/
	@Input() structureExport: any;

	/** Ligne courante **/
	@Input() ligne: any;

	/** Fichier courant **/
	@Input() fichierDetail: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Mise à jour de la structure d'export et de la ligne **/
	@Input() onStructureExportUpdate: (payload: { structureExport: any,ligne?: any }) => void;

	/** Liste des rubriques **/
	listeRubriques: ListView<any,StructureExportLineItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private toastrService: ToastrService,private translateService: TranslateService,private bsModalService: BsModalService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	*/
	ngOnInit() {
		//Chargement de la liste des compteurs disponibles
		this.structureExportService.findAllCompteursForLigne(this.ligne.idLigne).subscribe({
			next: listeCompteurs => {
				//Liste des rubriques de la ligne
				this.listeRubriques = new ListView<any,StructureExportLineItemComponent>({
					uri: () => '/controller/StructureExport/filtreRubriques/'+this.ligne.idLigne,
					component: StructureExportLineItemComponent,
					listeFilters: [{
						clef: 'libelle',
						isDefault: true
					}],
					defaultOrder: 'position',
					extraOptions: {
						structureExport: this.structureExport,
						fichier: this.fichierDetail.fichier,
						ligne: this.ligne,
						listeCompteurs,
						isRestricted: this.isRestricted,
						onStructureExportUpdate: this.onStructureExportUpdate
					},
					listeActions: !this.isRestricted && [{
						icon: 'edit',
						messagingOptions: {
							entryPoint: `controller/StructureExport/updateAllRubriques/${this.ligne.idLigne}`
						},
						onPress: (actionMasse: ActionMasse,messagingOptions: MessagingOptions) => {
							//Affichage de la création de la rubrique
							this.structureExportService.showRubrique(this.structureExport,this.fichierDetail.fichier,this.ligne,listeCompteurs,true,{},actionMasse.nbSelected,this.isRestricted).pipe(
								filter(result => !!result)
							).subscribe({
								next: result => {
									let actionMasse: ActionMasse;

									//Récupération de l'action de masse
									actionMasse = messagingOptions.params as ActionMasse;

									//Alimentation des données
									actionMasse.data = result.rubrique != null ? Object.assign(result.rubrique,{
										_type: 'com.notilus.data.export.StructureExportRubrique'
									}) : undefined;

									//Réalisation de l'action
									this.structureExportService.updateAllRubriques(messagingOptions).subscribe({
										complete: () => this.listeRubriques.refresh()
									});
								}
							});
						}
					}].filter(i => !!i),
					getKeyFieldName: () => 'idRubrique',
					isLocal: true
				});
			}
		});
	}

	/**
	 * Enregistrement de la ligne
	 */
	saveLigne() {
		//Sauvegarde du détail
		this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de la fenêtre
					this.close({
						structureExport: result.data.structureExport
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Affichage de l'import des rubriques
	 */
	public showImportRubriques() {
		let bsModalRef: BsModalRef<StructureExportRubriqueImportComponent>;

		//Affichage de la pop up
		bsModalRef = this.bsModalService.show(StructureExportRubriqueImportComponent,{
			initialState: {
				ligne: this.ligne,
				fichier: this.fichierDetail.fichier
			},
			class: 'modal-lg'
		});

		//Interception du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result),
			filter(result => result.isSuccess)
		).subscribe({
			next: () => {
				//Enregistrement de la ligne pour mettre à jour la validité
				this.saveLigne();
			}
		});
	}
}