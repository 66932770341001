import { Component,OnInit,Renderer2 } from '@angular/core';
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

import { MailHistoryService } from './mail-history.service';

@Component({
	selector: 'progress-erreur',
	templateUrl: './mail-history-detail.component.html'
})
export class MailHistoryDetailComponent implements OnInit {
	/** Historique du mail **/
	data: any;

	/** Liste des destinataires principaux **/
	listeDestinatairesPrincipaux: Array<any>;

	/** Liste des destinataires en copie **/
	listeDestinatairesCopie: Array<any>;

	/** Limite des destinataires à afficher **/
	limitDestinataires = 4;

	/** Indicateur d'affichage de l'ensemble des destinataires principaux **/
	isShowMoreDestinataires = false;

	/** Indicateur d'affichage de l'ensemble des destinataires en copie **/
	isShowMoreCopies = false;

	/** Contenu du mail **/
	contenu: SafeHtml;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef,private mailHistoryService: MailHistoryService,private domSanitizer: DomSanitizer,private renderer: Renderer2) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement du contenu
		this.mailHistoryService.loadMailHistoryContent(this.data.idMailHistory).pipe(take(1)).subscribe({
			next: result => {
				//Vérification du contenu
				if (result.data.content != null)
					//Récupération du corps du mail
					this.contenu = this.domSanitizer.bypassSecurityTrustHtml(result.data.content);
			}
		})
	}

	/**
	 * Intercepteur du chargement du contenu du mail
	 */
	onLoad(element) {
		//Définition de la taille de la frame à la taille du contenu
		element.style.height = element.contentDocument.body.scrollHeight + 'px';

		//Vérification du contenu
		if (element.contentDocument?.body) {
			//Interception des clics sur le corps de la frame
			this.renderer.listen(element.contentDocument.body,'click',event => {
				//Annulation de l'évènement par défaut
				event.preventDefault();
			});
		}
	}
}