<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.extraction?.entity?.split('.').pop(-1).substring(0,1).toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Chart/loadChart/',data.idChart]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>chart.entite</span>&#160;:&#160;<span [translate]="'businessData.'+ data.extraction.entity?.split('.').pop(-1)"></span></li>
		<li><span translate>chart.typeGraphique.item</span>&#160;:&#160;<span *ngIf="data.listeDefinitions[0]" [translate]="'chart.typeGraphique.'+data.listeDefinitions[0]?.type"></span><span *ngIf="data.listeDefinitions[1]">{{ ' -> ' + ('chart.typeGraphique.'+data.listeDefinitions[1]?.type | translate) }}</span><span *ngIf="!data.listeDefinitions?.length" translate>common.nonDefini</span></li>
		<li><span translate>chart.typeDiffusion.item</span>&#160;:&#160;<span [translate]="'chart.typeDiffusion.'+data.typeDiffusion"></span></li>
	</ul>
	<div [hidden]="" class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="duplicateChart();"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteChart();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>