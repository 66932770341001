import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { LicenceConsommationService } from 'src/app/components/licence/consommation/licence-consommation.service';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LicenceConsommationListItemComponent } from './licence-consommation-list-item.component';

@Component({
	templateUrl: './licence-consommation-list.component.html'
})
export class LicenceConsommationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,LicenceConsommationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private licenceConsommationService: LicenceConsommationService,private confirmService: ConfirmService,private toastrService:ToastrService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LicenceConsommationListItemComponent>({
			uri: `/controller/Licence/filtreLicenceConsommations`,
			title: this.translateService.instant('licence.consommation.liste.title'),
			component: LicenceConsommationListItemComponent,
			listeFilters: [{
				clef: 'tenant',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'tenant'
				},
				title: this.translateService.instant('search.client'),
				isDefault: true
			},{
				clef: 'periode',
				type: TypeFilter.MONTH
			}],
			getKeyFieldName: () => 'idLicenceConsommation',
			defaultOrder: '-periode,tenant.nom',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.doComputeLicenceConsommation()
		});
	}

	/**
	 * Déclenchement du calcul des licences
	 */
	doComputeLicenceConsommation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('licence.consommation.computation.confirmation'),{ actionColor: 'primary' }).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Calcul des licences
					this.licenceConsommationService.computeLicenceConsommations().subscribe({
						complete: () => this.liste?.refresh?.(),
						error: () => this.toastrService.error(this.translateService.instant('licence.consommation.computation.error'))
					});
				}
			}
		});
	}
}