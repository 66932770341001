import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des contrats de financement
 */
export default class ContratFinancementOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.contratFinancement';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Vehicule/filtreContratsFinancement`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.reference} (${item.fournisseur.libelle} - ${item.type.libelle})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.type?.reference?.substring?.(0,3) || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.Financement';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idContrat'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'fournisseur.libelle',
			isDefault: true
		},{
			clef: 'vehicule.reference',
			isDefault: true
		}];
	}
}