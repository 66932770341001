import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { TaxeService } from './taxe.service';

@Component({
	templateUrl: './taxe.component.html'
})
export class TaxeComponent implements AfterContentChecked,OnInit {
	/** Liste des taxes de l'objet courant **/
	@Input() listeTaxes: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }>;

	/** Montant TTC **/
	@Input() montantTTC: number;

	/** Etat du composant **/
	@Input() disabled?: boolean = false;

	/** Résultat **/
	result: { listeTaxes: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }> };

	/** Indicateur de présence d'une erreur **/
	hasError: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<TaxeComponent>,public taxeService: TaxeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la présence d'une erreur
		this.hasError = !this.taxeService.verifyListeTaxes(this.montantTTC,this.listeTaxes);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la liste des taxes
	 */
	public saveTaxe() {
		//Vérification de la présence d'une erreur
		this.hasError = !this.taxeService.verifyListeTaxes(this.montantTTC,this.listeTaxes);

		//Vérification des taxes
		if (!this.hasError) {
			//Définition du résultat
			this.result = {
				listeTaxes: this.listeTaxes
			};

			//Fermeture de la modal
			this.bsModalRef.hide();
		}
	}

	/**
	 * Fermeture de la liste des taxes
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}