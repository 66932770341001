import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { UserListItemComponent } from './user-list-item.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { mapEntites } from './user.entites';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,UserListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,private rightService: RightService,private layoutService: LayoutService,public extractionService: ExtractionService,public connecteurService: ConnecteurService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UserListItemComponent>({
			uri: `/controller/User/filtreUsers`,
			title: this.translateService.instant('user.liste.title'),
			component: UserListItemComponent,
			listeFilters: [{
				clef: 'nom',
				isDefault: true
			},{
				clef: 'prenom',
				isDefault: true
			},{
				clef: 'matricule',
				isDefault: true
			},{
				clef: 'login',
				isDefault: true
			},{
				clef: 'email',
				isDefault: true
			},{
				clef: 'enabled',
				title: this.translateService.instant('search.isActif'),
				type: TypeFilter.BOOLEAN
			},{
				clef: 'societe',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			},{
				clef: 'service',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'service'
				}
			},{
				clef: 'dateSortie',
				title: this.translateService.instant('search.sortie'),
				type: TypeFilter.DATE
			}],
			defaultOrder: '-isEnabled,nom,prenom,matricule,login,email',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/User/loadUser/0')),
			getKeyFieldName: () => 'idUser'
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});

		//Vérification de la présence d'imports
		this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().subscribe({
			next: (isImportAvailable: boolean) => {
				//Définition de la présence d'imports
				this.hasImports = isImportAvailable;
			}
		});
	}
}