import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { AppState } from 'src/app/domain/appstate';
import { AttachmentEntity,LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PlanComptableCompteAuxiliaireEditComponent } from './plan-comptable-compte-auxiliaire-edit.component';
import { PlanComptableCompteAuxiliaireListComponent } from './plan-comptable-compte-auxiliaire-list.component';
import { PlanComptableCompteEditComponent } from './plan-comptable-compte-edit.component';
import { PlanComptableCompteListComponent } from './plan-comptable-compte-list.component';
import { PlanComptableEditComponent } from './plan-comptable-edit.component';
import { PlanComptableService } from './plan-comptable.service';

@Component({
	selector: 'plan-comptable',
	templateUrl: './plan-comptable.component.html'
})
export class PlanComptableComponent implements OnInit,IEntity,AttachmentEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Plan comptable courant **/
	public planComptable: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.PLAN_COMPTABLE;

	/** Utilisateur connecté **/
	user: User;

	/** Résumé **/
	public resume: { nbComptes: number,nbComptesAuxiliaires: number } = {
		nbComptes: 0,
		nbComptesAuxiliaires: 0
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Identifiant du plan comptable à dupliquer **/
	public idPlanComptableSource: number = null;

	/** Restriction sur le plan comptable **/
	public restriction: { isRestricted: boolean } = null;

	/**
	 * Constructeur
	 */
	constructor(private planComptableService: PlanComptableService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute,private compteService: CompteService
			,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private attachmentService: AttachmentService,private layoutService: LayoutService,private store: Store<AppState>) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.comptabilite.PlanComptable';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => !this.idPlanComptableSource && this.planComptable?.idPlanComptable || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.planComptable;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'comptabilite.planComptable.libelle',
				key: 'libelle'
			},{
				libelle: 'comptabilite.planComptable.reference',
				key: 'reference'
			},{
				libelle: 'comptabilite.planComptable.pays',
				key: 'pays',
				type: 'DISPLAY',
				format: 'pays'
			},{
				libelle: 'comptabilite.planComptable.actif',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editPlanComptable(),
				isVisible: () => !this.restriction?.isRestricted && this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editPlanComptable(),
				isVisible: () => !this.restriction?.isRestricted && !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicatePlanComptable(),
				isVisible: () => !this.restriction?.isRestricted && this.rightService.hasRight(null,'creation')
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deletePlanComptable(),
				isVisible: () => !this.restriction?.isRestricted && this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'info',
				title: this.translateService.instant('comptabilite.planComptable.alerte.restriction.title'),
				message: this.translateService.instant('comptabilite.planComptable.alerte.restriction.message'),
				isVisible: () => this.restriction?.isRestricted && !this.pageContentService.isOpened(),
				level: 'INFO'
			}],
			listeElements: [{
				type: 'COMPTE',
				libelle: 'comptabilite.planComptable.elements.compte',
				component: PlanComptableCompteListComponent,
				retrieveComponentData: () => ({
					planComptable: this.planComptable,
					resume: this.resume,
					restriction: this.restriction
				}),
				doAction: () => this.addCompte(),
				libelleAction: this.translateService.instant('comptabilite.planComptable.actions.ajouterCompte'),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted,
				count: () => this.resume?.nbComptes || 0
			},{
				type: 'COMPTE_AUXILIAIRE',
				libelle: 'comptabilite.planComptable.elements.compteAuxiliaire',
				component: PlanComptableCompteAuxiliaireListComponent,
				retrieveComponentData: () => ({
					planComptable: this.planComptable,
					resume: this.resume,
					restriction: this.restriction
				}),
				doAction: () => this.addCompteAuxiliaire(),
				libelleAction: this.translateService.instant('comptabilite.planComptable.actions.ajouterCompteAuxiliaire'),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted,
				count: () => this.resume?.nbComptesAuxiliaires || 0
			}]
		};

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Récupération de l'identifiant du plan comptable à dupliquer
			this.idPlanComptableSource = history.state.idPlanComptableSource;

			//Chargement des données
			this.loadData(this.idPlanComptableSource,this.activatedRoute.snapshot.params.idPlanComptable,history.state.planComptable);
		});
	}

	/**
	 * Edition du plan comptable
	 */
	editPlanComptable() {
		//Ouverture du composant d'édition
		this.pageContentService.open(PlanComptableEditComponent,{
			data: {
				planComptable: this.planComptable.idPlanComptable ? cloneDeep(this.planComptable) : this.planComptable,
				deletePlanComptable: this.deletePlanComptable.bind(this),
				resume: this.resume,
				idPlanComptableSource: this.idPlanComptableSource
			}
		}).subscribe({
			next: planComptable => {
				//Vérification des données
				if (planComptable) {
					//Mise à jour du plan comptable
					this.planComptable = planComptable;

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.planComptable);
				}
			}
		});
	}

	/**
	 * Suppression du plan comptable
	 */
	private deletePlanComptable() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.planComptableService.deletePlanComptable(this.planComptable))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.planComptable;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Duplication du plan comptable
	 */
	private duplicatePlanComptable() {
		//Redirection vers le formulaire pré-rempli
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: 0
			},
			routeData: {
				idPlanComptableSource: this.planComptable.idPlanComptable
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idPlanComptableSource: number,idPlanComptable: number,planComptable: any) {
		//Chargement du plan comptable
		this.planComptableService.loadPlanComptable(idPlanComptableSource || idPlanComptable || 0).subscribe(result => {
			//Vérification du chargement
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Définition du plan comptable
				this.planComptable = result.data?.planComptable || null;

				//Définition du résumé
				this.resume = {
					nbComptes: result.data?.nbComptes || 0,
					nbComptesAuxiliaires : result.data?.nbComptesAuxiliaires || 0
				};

				//Vérification de l'identifiant du plan comptable
				if (!this.planComptable?.idPlanComptable) {
					//Création d'un plan comptable vierge/basé sur les paramètres de routing
					this.planComptable = {
						...(planComptable || {}),
						actif: true,
						pays: this.user.pays
					};
				}

				//Vérification du mode duplication
				if (this.idPlanComptableSource) {
					//Mise à jour des champs du duplicat
					this.planComptable.libelle += ' - ' + this.translateService.instant('comptabilite.planComptable.duplication.copie');
					this.planComptable.reference += ' - ' + this.translateService.instant('comptabilite.planComptable.duplication.copie');
					this.planComptable.actif = true;
					this.planComptable.dateApplication = null;
					this.planComptable.listeSocietes = [];
				} else
					//Initialisation du compteur de pièces-jointes
					this.attachmentService.initAttachments(this);

				//Définition de la restriction
				this.restriction = this.compteService.getRestriction(this.planComptable.listeSocietes);

				//Vérification de l'identifiant
				if (!this.planComptable.idPlanComptable || this.idPlanComptableSource)
					//Edition du plan comptable
					this.editPlanComptable();
			}
		});
	}

	/**
	 * Ajout/modification d'un compte
	 */
	addCompte() {
		//Ouverture du composant d'édition
		this.pageContentService.open(PlanComptableCompteEditComponent,{
			data: {
				compte: {
					planComptable: cloneDeep(this.planComptable)
				}
			}
		},'sub').subscribe((data: { compte: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet
				this.complexPage.setSelectedElementByType('COMPTE',true);

				//Vérification de la création
				if (data.compte?.idCompte)
					//Incrémentation du compteur
					this.resume.nbComptes++;
			}
		});
	}

	/**
	 * Ajout/modification d'un compte auxiliaire
	 */
	addCompteAuxiliaire() {
		//Ouverture du composant d'édition
		this.pageContentService.open(PlanComptableCompteAuxiliaireEditComponent,{
			data: {
				compteAuxiliaire: {
					planComptable: cloneDeep(this.planComptable)
				}
			}
		},'sub').subscribe(data => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet
				this.complexPage.setSelectedElementByType('COMPTE_AUXILIAIRE',true);

				//Vérification de la création
				if (data.idCompteAuxiliaire)
					//Incrémentation du compteur
					this.resume.nbComptesAuxiliaires++;
			}
		});
	}
}