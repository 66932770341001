<div class="card" *ngIf="resume">
	<div class="alert alert-default m-b-0 m-t-15 m-r-15" *ngIf="resume.counterCorrect.count > 0">
		<icon type="icon-venus" name="n-icon-calculator"></icon>
		<div>
			<strong translate>{{ 'comptabilite.lotComptable.check.recapitulatif.elementsInclus.title' | pluralTranslate:resume.counterCorrect.count }}</strong>
			<div [innerHTML]="'comptabilite.lotComptable.check.recapitulatif.elementsInclus.info' | pluralTranslate:resume.counterCorrect.count | translate:{ nb: resume.counterCorrect.count || 0,montant: (resume.counterCorrect.amount || 0 | currency:resume.devise) }"></div>
		</div>
		<button mat-raised-button color="primary" (click)="showDetail(false)">
			<span translate>actions.continuer</span>
		</button>
	</div>
	<div class="alert alert-warning m-b-0 m-t-15 m-r-15" *ngIf="resume.counterAnomalie.count > 0">
		<icon name="warning"></icon>
		<div>
			<strong translate>{{ 'comptabilite.lotComptable.check.recapitulatif.elementsExclus.title' | pluralTranslate:resume.counterAnomalie.count }}</strong>
			<div [innerHTML]="'comptabilite.lotComptable.check.recapitulatif.elementsExclus.info' | pluralTranslate:resume.counterAnomalie.count | translate:{ nb: resume.counterAnomalie.count || 0,montant: (resume.counterAnomalie.amount || 0 | currency:resume.devise) }"></div>
		</div>
		<button mat-raised-button color="primary" (click)="showDetail(true)">
			<span translate>actions.continuer</span>
		</button>
	</div>
</div>