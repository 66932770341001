import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { EquipementAffectationHistoryListComponent } from './equipement-affectation-history-list.component';

@Injectable()
export class EquipementAffectationService {
	/** Liste des types **/
	private readonly listeTypes: Array<string> = ['VEHICULE','COLLABORATEUR'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService) {

	}

	/**
	 * Enregistrement de l'affectation
	 */
	public saveAffectation(affectation: any): Observable<Result> {
		//Enregistrement de l'affectation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Equipement/saveAffectation`,affectation);
	}

	/**
	 * Suppression de l'affectation
	 */
	public deleteAffectation(affectation: any): Observable<Result> {
		//Suppression de l'affectation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Equipement/deleteAffectation/${affectation.idAffectation}`);
	}

	/**
	 * Récupération de la liste des types
	 */
	getListeTypes(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypes.map(type => ({
			code: type,
			libelle: this.translateService.instant('equipement.affectation.type.'+type)
		}));
	}

	/**
	 * Affichage de l'historique des affectations
	 */
	public showAffectationHistory(equipement: any) {
		//Affichage de la popup
		this.bsModalService.show(EquipementAffectationHistoryListComponent,{
			initialState: {
				equipement: cloneDeep(equipement)
			},
			class: 'modal-lg'
		});
	}
}