<div class="pull-left lv-avatar">{{ (data.reference || data.titre).substring(0,2).toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Aide/loadArticle',data.idArticle]">{{ data.titre + (data.reference ? ' (' + data.reference + ')' : '') }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>article.actif.item</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	<li *ngIf="rightService.isRoot()"><span translate>extraction.typeDiffusion.item</span>&#160;:&#160;<span [translate]="'diffusion.'+data.typeDiffusion"></span></li>
	<li><span translate>article.type.item</span>&#160;:&#160;<span [translate]="'article.type.'+data.typeArticle"></span></li>
	<li><span translate>article.perimetre.item</span>&#160;:&#160;<span [translate]="'article.perimetre.'+data.typePerimetre"></span></li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteArticle();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="duplicateArticle()"><span translate>actions.dupliquer</span></a></li>
		<li><a [routerLink]="[]" (click)="previewArticle();"><span translate>actions.previsualiser</span></a></li>
	</ul>
</div>