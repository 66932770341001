
<div class="pull-left lv-avatar"><icon name="warning"></icon></div>
<div class="lv-input">
	<div class="lv-title flex-3">
		<a [routerLink]="[]">{{ data.libelleFournisseur }}</a>
	</div>
	<autocomplete class="flex-2" name="compteAuxiliaire" type="compteAuxiliaire" #compte="ngModel" [(ngModel)]="data.compteAuxiliaire" [filter]="{ idCompte: data.idCompte,isCreation: true,isCompteDisabled: true }" [placeholder]="'comptabilite.lotComptable.check.auxiliaire.comptePlaceholder' | translate"></autocomplete>
</div>
<ul class="lv-attrs">
	<li><span translate>comptabilite.lotComptable.check.planComptable.item</span>&#160;:&#160;<span>{{ data.libellePlanComptable }}</span></li>
	<li><span translate>comptabilite.lotComptable.check.elements</span>&#160;:&#160;<span>{{ data.countItems | number }}</span></li>
	<li><span translate>comptabilite.lotComptable.check.ecritures</span>&#160;:&#160;<span>{{ data.amount | currency:'EUR' }}</span></li>
</ul>
