<div class="modal-header">
	<h4 class="modal-title" translate>comptabilite.lotComptable.structureExport.result.title</h4>
</div>
<div class="modal-body">
	<div class="listview lv-bordered" *ngFor="let result of listeResults">
		<h4 class="p-l-10">{{ result.libelleStructureExport }}</h4>
		<div class="lv-body">
			<div class="lv-item media" *ngFor="let file of result.listeFiles">
				<div class="media-body">
					<div class="pull-left lv-avatar"><i class="zmdi zmdi-download" style="font-size: 1.2em;"></i></div>
					<div class="lv-title">
						<a [routerLink]="[]" (click)="downloadExportFile(result.idStructure,idSession,file,exportDate)">{{ file.fileName }}</a>
					</div>
					<ul class="lv-attrs">
						<li><span translate>comptabilite.lotComptable.structureExport.taille</span>&#160;:&#160;{{ file.length | bytes }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>