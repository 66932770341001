import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TaxeComponent } from './taxe.component';

@NgModule({
	imports: [ShareModule],
	declarations: [TaxeComponent]
})
export class TaxeModule { }
