import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';

@Component({
	templateUrl: './structure-export-rubrique-import.component.html'
})
export class StructureExportRubriqueImportComponent implements OnInit {
	/** Ligne courante **/
	@Input() ligne: any

	/** Fichier courant **/
	@Input() fichier: any;

	/** Modèle d'import des rubriques **/
	importRubrique: { libelles: string,values: string } = {
		libelles: null,
		values: null
	};

	/** Compteurs pour l'affichage des erreurs **/
	compteurs: { nbRubriques: number,nbLibelles: number,nbValues: number };

	/** Liste des erreurs **/
	listeErreurs: Array<string> = [];

	/** Résultat de l'import **/
	result: { isSuccess: boolean };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<StructureExportRubriqueImportComponent>,private translateService: TranslateService,private confirmService: ConfirmService,private messagingService: MessagingService,private toastrService: ToastrService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des compteurs
		this.compteurs = {
			nbLibelles: 0,
			nbValues: 0,
			nbRubriques: this.fichier.nbColonnes
		};
	}

	/**
	 * Interception de la modification des libellés
	 */
	public onLibellesChange() {
		//Vérification de la saisie
		if (this.importRubrique.libelles?.length) {
			//Remplacement des retours à la ligne, tabulation en point virgules
			this.importRubrique.libelles = this.importRubrique.libelles.replace(/\t|\n/g,';');

			//Vérification de l'ajout pour la première fois de libellés et de la présence d'un ';' final
			if (this.importRubrique.libelles.endsWith(';'))
				//Suppression du dernier séparateur
				this.importRubrique.libelles = this.importRubrique.libelles.slice(0,-1);
		}
	}

	/**
	 * Interception de la modification des valeurs
	 */
	public onValeursChange() {
		//Vérification de la saisie
		if (this.importRubrique.values?.length) {
			//Remplacement des retours à la ligne, tabulation en point virgules
			this.importRubrique.values = this.importRubrique.values.replace(/\t|\n/g,';');

			//Vérification de l'ajout pour la première fois de libellés et de la présence d'un ';' final
			if (this.importRubrique.values.endsWith(';'))
				//Suppression du dernier séparateur
				this.importRubrique.values = this.importRubrique.values.slice(0,-1);
		}
	}

	/**
	 * Import des rubriques
	 */
	public saveImportRubriques() {
		let listeValues: Array<string>;
		let listeLibelles: Array<string>;
		let messaging$: MessagingObservables;

		//Réinitialisation des erreurs
		this.listeErreurs = [];

		//Conversion des chaînes en liste
		listeValues = this.importRubrique.values.split(';');
		listeLibelles = this.importRubrique.libelles.split(';');

		//Sauvegarde des compteurs
		this.compteurs.nbValues = listeValues.length;
		this.compteurs.nbLibelles = listeLibelles.length;

		//Vérification de la taille des deux tableaux
		if (listeValues.length != listeLibelles.length)
			//Ajout d'une erreur
			this.listeErreurs.push(this.translateService.instant('structureExport.rubrique.import.anomalie.VALUE_LIBELLE_LENGTHS_MISMATCH',this.compteurs));

		//Vérification du nombre de valeurs
		if (listeValues.length > this.compteurs.nbRubriques)
			//Ajout d'une erreur
			this.listeErreurs.push(this.translateService.instant('structureExport.rubrique.import.anomalie.TOO_MANY_VALUES', this.compteurs));

		//Vérification du nombre de libellés
		if (listeLibelles.length > this.compteurs.nbRubriques)
			//Ajout d'une erreur
			this.listeErreurs.push(this.translateService.instant('structureExport.rubrique.import.anomalie.TOO_MANY_LIBELLES', this.compteurs));

		//Vérification des erreurs
		if (!this.listeErreurs.length) {
			//Confirmation de l'import
			this.confirmService.showConfirm(this.translateService.instant('structureExport.rubrique.import.confirmation'),{ actionColor: 'primary' }).subscribe({
				next: isConfirmed => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Exécution de l'import
						messaging$ = this.messagingService.init({
							entryPoint: `controller/StructureExport/importListeRubriques/${this.ligne.idLigne}`,
							progressConfig: {
								libelle: this.translateService.instant('structureExport.rubrique.import.title'),
								icone: 'autorenew',
								options: {
									canDismiss: false
								}
							},
							params: {
								listeLibelles,
								listeValues
							}
						}).onFinish({
							next: () => {
								//Fermeture des souscriptions
								messaging$.unsubscribe();

								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

								//Mise à jour du résultat
								this.result = {
									isSuccess: true
								};

								//Fermeture de la popup
								this.bsModalRef.hide();
							}
						});
					}
				}
			});
		}
	}
}