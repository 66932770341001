import { Component,OnInit } from '@angular/core';
import { switchMap,filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeVehiculeService } from '../commande/demande-vehicule/demande-vehicule.service';


@Component({
	templateUrl: './conducteur-demande-vehicule-list-item.component.html'
})
export class ConducteurDemandeVehiculeListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Information sur l'élément **/
	public statut: { libelle: string,code: string,icone: string,iconeType?: string };

	/**
	 * Constructeur
	 */
	constructor(public demandeVehiculeService: DemandeVehiculeService,public rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération du statut associé à la demande de véhicule
		this.statut = this.demandeVehiculeService.getListeStatuts().find(s => s.code == this.data.statut);
	}

	/**
	 * Redirection sur la demande de véhicule
	 */
	showDemandeVehicule() {
		//Redirection vers la demande de véhicule
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
			routeParams: {
				idDemandeVehicule: this.data?.idDemandeVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Suppression de la demande de véhicule
	 */
	deleteDemandeVehicule() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.demandeVehiculeService.deleteDemandeVehicule(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'affectation depuis la liste
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}