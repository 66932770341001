import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { LotComptableListItemComponent } from './lot-comptable-list-item.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { mapEntites } from './lot-comptable.entites';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LotComptableService } from './lot-comptable.service';

@Component({
	templateUrl: './lot-comptable-list.component.html'
})
export class LotComptableListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,LotComptableListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,private rightService: RightService,private store: Store<AppState>,public extractionService: ExtractionService,private layoutService: LayoutService,private lotComptableService: LotComptableService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => this.user = user
		});

		//Définition de la liste
		this.liste = new ListView<any,LotComptableListItemComponent>({
			uri: `/controller/LotComptable/filtreLotsComptables`,
			title: this.translateService.instant('comptabilite.lotComptable.liste.title'),
			component: LotComptableListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'numeroLot',
				isDefault: true
			},{
				clef: 'dateLot',
				type: TypeFilter.DATE
			},{
				clef: 'montant',
				title: this.translateService.instant('search.montantTotal'),
				type: TypeFilter.DECIMAL
			}],
			defaultOrder: '-numeroLot',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				let doCreationAction: (selectionItems: any) => void;

				//Création d'un lot comptable
				doCreationAction = (selectionItems: any) => {
					//Redirection vers la création d'un lot comptable
					this.layoutService.goToByState('listeLotsComptables-lotComptable',{
						routeParams: {
							idLotComptable: 0
						},
						routeData: {
							selectionItems
						}
					});
				}

				//Sélection des factures à comptabiliser
				this.lotComptableService.showAdvancedSearch(this.user).subscribe({
					next: selectionItems => {
						//Vérification de présence d'une sélection d'éléments
						if (selectionItems && (selectionItems.listeSelectionsByAggregator || selectionItems.nbSelectedItems || selectionItems.searchSpec)) {
							//Affichage du contrôles des écritures comptables
							this.lotComptableService.showLotComptableChecks(null,selectionItems,null,false,doCreationAction).subscribe({
								next: result => {
									//Vérification du résultat
									if (result?.selectionItems)
										//Création du lot comptable et peuplement de la sélection
										doCreationAction(result.selectionItems);
								}
							});
						}
					}
				});
			}),
			getKeyFieldName: () => 'idLotComptable'
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}