<div class="container-fluid">
	<form #contratFinancementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation')">
			<contrat-financement-content #contratFinancementContent source="CONTRAT_FINANCEMENT" [contratFinancement]="contratFinancement" [getDateFin]="getDateFin" (close)="onContentClose($event)"></contrat-financement-content>
		</fieldset>
		<div class="card full">
			<data-customization entite="com.notilus.data.vehicule.Financement" [object]="contratFinancement" [disabled]="!(null | right:'creation')"></data-customization>
		</div>
		<button mat-button *ngIf="contratFinancement.idContrat" [formDetectChanges]="{ doAction: close,isDirty: contratFinancementForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteContratFinancement();" *ngIf="contratFinancement.idContrat && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="contratFinancementContent.saveContratFinancement();" *ngIf="null | right:'creation'" [disabled]="contratFinancementForm.invalid">
				<span [translate]="'actions.'+(contratFinancement?.idContrat ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
	</form>
</div>