import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './nature-fournisseur-list-item.component.html'
})
export class NatureFournisseurListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Redirection vers l'élément
	 */
	goToFournisseur() {
		//Navigation vers l'élément
		this.layoutService.goToByState('fournisseurReferentiel-listeFournisseurs-fournisseur',{
			routeParams: {
				typeReferentiel: 'STANDARD',
				idFournisseur: this.data?.idFournisseur
			},
			withGoBack: true
		});
	}
}