import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { UserService } from './user.service';

@Component({
	selector: 'user-profil-list',
	templateUrl: './user-profil-list.component.html'
})
export class UserProfilListComponent extends PageContentItem {
	/** Utilisateur **/
	@Input() user: any;

	/** Compte mobile **/
	@Input() userAccountMobile: any;

	/** Liste des profils de l'utilisateur **/
	@Input() listeUserProfil: Array<any>;

	/** Fonction d'édition **/
	@Input() addProfil: Function;

	/** Résumé **/
	@Input() resume: { nbProfils: number };

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private userService: UserService,private confirmService: ConfirmService,private toastrService: ToastrService) {
		//Héritage
		super();

		//Binding
		this.deleteLienProfil = this.deleteLienProfil.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteLienProfil(lienProfil: any,close?: Function,index?: number) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.userService.deleteLienProfil(this.user,lienProfil).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Vérification de l'index
								if (index != null)
									//Suppression de l'objet
									this.listeUserProfil?.splice(index,1)

								//Mise à jour du résumé
								this.resume.nbProfils = this.listeUserProfil?.length || 0

								//Mise à jour de l'utilisateur
								Object.assign(this.user,result?.data?.user);

								//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Activation/désactivation du profil
	 */
	toggleLienProfil(lienProfil: any) {
		//Activation/désactivation du profil
		this.userService.toggleLienProfil(lienProfil).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(lienProfil,result.data?.userProfil);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Transmission des éléments de connexion
	 */
	sendAccount(user: any) {
		//Transmission des éléments de connexion
		this.userService.sendAccount(user).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}