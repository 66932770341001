import { Component,Input } from '@angular/core';
import { ListView } from 'src/app/domain/common/list-view';

import { LotComptableService } from './lot-comptable.service';
import { LotComptableCheckAuxiliaireListItemComponent } from './lot-comptable-check-auxiliaire-list-item.component';

@Component({
	selector: 'lot-compable-check-auxiliaire',
	templateUrl: './lot-comptable-check-auxiliaire.component.html'
})
export class LotComptableCheckAuxiliaireComponent {
	/** Nombre d'anomalies **/
	@Input() nbAnomalies: number;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Données **/
	liste: ListView<any,LotComptableCheckAuxiliaireListItemComponent>;

	/** Liste des comptes **/
	listeComptes: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private lotComptableService: LotComptableService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckAuxiliaireListItemComponent>({
			uri: () => `/controller/LotComptable/filtreFournisseursForControle/AUXILIAIRE_FOURNISSEUR`,
			component: LotComptableCheckAuxiliaireListItemComponent,
			isLocal: true,
			extraOptions: {
				nbAnomalies: this.nbAnomalies
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			defaultOrder: 'libelle',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })],
			onBeforeRefresh: () => {
				//Mémorisation des données
				this.refreshData();
			},
			onRefresh: (liste) => {
				//Vérification du contenu
				if (liste?.data?.content?.length) {
					//Parcours du contenu
					liste.data.content.forEach(item => {
						let compteAuxiliaire: any;

						//Recherche du compte associé
						compteAuxiliaire = this.listeComptes.filter(c => c.idFournisseur == item.idFournisseur && c.idCompte == item.idCompte)[0];

						//Restauration du compte
						item.compteAuxiliaire = compteAuxiliaire?.compteAuxiliaire;
					});
				}
			}
		});
	}

	/**
	 * Rafraichissement des données
	 */
	refreshData() {
		//Vérification du contenu
		if (this.liste?.data) {
			//Parcours du contenu
			this.liste.data.content.forEach(item => {
				let compteAuxiliaire: any;

				//Recherche du compte associé
				compteAuxiliaire = this.listeComptes.filter(c => c.idFournisseur == item.idFournisseur && c.idCompte == item.idCompte)[0];

				//Vérification du compte
				if (!compteAuxiliaire) {
					//Création du compte
					compteAuxiliaire = {
						idFournisseur: item.idFournisseur,
						idCompte: item.idCompte
					};

					//Vérification du compte
					if (item.compteAuxiliaire)
						//Ajout du compte à la liste
						this.listeComptes.push(compteAuxiliaire);
				}

				//Mise à jour du compte
				compteAuxiliaire.compteAuxiliaire = item.compteAuxiliaire;
			});
		}

		//Retour de la liste des comptes
		return this.listeComptes || [];
	}

	/**
	 * Récupération du nombre de corrections
	*/
	getNbCorrections() {
		//Retour du nombre de corrections
		return this.refreshData().filter(compteAuxiliaire => compteAuxiliaire.compteAuxiliaire).length;
	}

	/**
	 * Réalisation des corrections
	 */
	doCorrection() {
		//Rafraichissement des données
		this.refreshData();

		//Envoi des corrections
		return this.lotComptableService.doPlanComptableCorrection('AUXILIAIRE_FOURNISSEUR',this.listeComptes.map(c => ({
			idPlanComptable: c.idPlanComptable,
			idObjectToCorrect: c.idFournisseur,
			idValue: c.compteAuxiliaire.idCompteAuxiliaire
		})));
	}
}
