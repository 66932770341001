<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idReservation">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
			<icon name="question_mark" *ngIf="data.statut == 'EN_ATTENTE'"></icon>
			<icon name="check" *ngIf="data.statut == 'VALIDEE'"></icon>
			<icon name="close" *ngIf="data.statut == 'REJETEE' || data.statut == 'ANNULEE'"></icon>
		</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">
				<span>{{ ('reservation.liste.numeroReservation' | translate:{ identifiant: data.idReservation }) }}</span>&#160;-
				<span *ngIf="data.affectation?.vehicule">{{ data.affectation.vehicule.reference + (data.affectation.vehicule.numeroInterne ? ' - ' + data.affectation.vehicule.numeroInterne : '') }}</span>
				<span *ngIf="data.affectation?.vehicule && data.affectation.vehicule.idVehicule != data.vehicule.idVehicule" class="stroked m-l-5">{{ data.vehicule.reference }}</span>
				<span *ngIf="!data.affectation?.vehicule">{{ data.vehicule.reference }}</span>
				<span>{{ ' (' + data.user.matricule + ' - ' + data.user.nom + ' ' + data.user.prenom + ')' }}</span>
			</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idReservation">
		<li><span translate>reservation.statut.item</span>&#160;:&#160;<span [translate]="'reservation.statut.'+data.statut"></span></li>
		<li><span translate>reservation.dateReservation</span>&#160;:&#160;<span>{{ data.dateCreation | date:'short' }}</span></li>
		<li><span translate>reservation.dateDepart</span>&#160;:&#160;<span>{{ data.dateDepart | date:'short' }}</span></li>
		<li><span translate>reservation.dateRetour</span>&#160;:&#160;<span *ngIf="data.dateRetour">{{ data.dateRetour | date:'short' }}</span><span *ngIf="!data.dateRetour" translate>common.aucune</span></li>
		<li><span translate>reservation.pool</span>&#160;:&#160;<span *ngIf="data.pool">{{ data.pool | display:'pool' }}</span><span *ngIf="!data.pool" translate>common.aucun</span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems && user?.type != 'VIRTUAL'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.statut == 'EN_ATTENTE' && (null | right:'creation')"><a [routerLink]="[]" (click)="doAction('VALIDER',data)" translate>actions.valider</a></li>
			<li *ngIf="data.statut == 'EN_ATTENTE' && (null | right:'creation')"><a [routerLink]="[]" (click)="doAction('REJETER',data)" translate>actions.rejeter</a></li>
			<li *ngIf="data.statut == 'VALIDEE' && (null | right:'creation')"><a [routerLink]="[]" (click)="doAction('ANNULER',data)" translate>actions.annuler</a></li>
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="data.affectation?.idAffectation"><a [routerLink]="[]" (click)="goToAffectation(data)" translate>reservation.actions.accederAffectation</a></li>
			<li *ngIf="data.etatLieux?.idEtatLieux"><a [routerLink]="[]" (click)="goToEtatLieux(data)" translate>reservation.actions.accederEtatLieux</a></li>
			<li *ngIf="data.affectation?.vehicule?.idVehicule && ((TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation'))" ><a [routerLink]="[]" (click)="goToPlanning(data)" translate>reservation.actions.accederPlanning</a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<reservation [reservation]="data" (onObjectChange)="onObjectChange($event)"></reservation>
</div>