<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="lv-title">
	<a [routerLink]="['/EtatLieux/etatLieux',data.idEtatLieux]"><span translate>vehicule.etatLieux.titre</span>&#160;-&#160;{{ data.vehicule | display:'vehicule' }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>vehicule.etatLieux.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.etatLieux.statut.'+data.statut"></span></li>
	<li><span [translate]="'vehicule.etatLieux.'+(data.dateRealisation ? 'realise' : 'prevu')"></span>&#160;:&#160;<span [translate]="(data.dateRealisation || data.datePlanification) | date:'short'"></span></li>
	<li><span translate>vehicule.etatLieux.collaborateurAffecte</span>&#160;:&#160;<span>{{ data.extension?.userAffecte ? data.extension.userAffecte.nom + ' ' + data.extension.userAffecte.prenom + ' (' + data.extension.userAffecte.matricule + (data.extension.userAffecte.societe ? ' - ' + data.extension.userAffecte.societe.libelle : '') + ')' : ('common.nonDefini' | translate) }}</span></li>
	<li *ngIf="data.statut == 'TERMINE' && data.user"><span translate>vehicule.etatLieux.realisePar</span>&#160;:&#160;<span>{{ data.user.nom + ' ' + data.user.prenom + ' (' + data.user.matricule + (data.user.societe ? ' - ' + data.user.societe.libelle : '') + ')' }}</span></li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
		<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
		<li *ngIf="data.reservation?.idReservation"><a [routerLink]="[]" (click)="goToReservation(data)" translate>vehicule.etatLieux.actions.accederReservation</a></li>
		<li *ngIf="data.statut == 'TERMINE'"><a [routerLink]="[]" (click)="generateDocument()"><span translate>actions.genererDocument</span></a></li>
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteEtatLieux();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>