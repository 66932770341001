import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { ReleveCompteurService } from './releve-compteur.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';

@Component({
	selector: 'releve-compteur',
	templateUrl: './releve-compteur.component.html'
})
export class ReleveCompteurComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() releveCompteur: any;

	/** Gestion d'une modification de relevé de compteur **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Copie du relevé de compteur **/
	public releveCompteurCopie: any;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private releveCompteurService: ReleveCompteurService,private store: Store<AppState>,private changeDetectorRef: ChangeDetectorRef,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du relevé de compteur
		this.releveCompteur = Object.assign({},this.releveCompteur);

		//Copie du relevé de compteur
		this.releveCompteurCopie = Object.assign({},this.releveCompteur);

		//Sélection de l'utilisateur connecté et du paramétrage
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur
			this.user = user;

			//Vérification de l'action externe et de la présence d'un identifiant
			if (this.releveCompteur.idCompteur && !this.user.externalAction) {
				//Chargement du relevé de compteur
				this.releveCompteurService.loadReleveCompteur(this.releveCompteur.idCompteur).subscribe(result => {
					//Vérification du code d'erreur
					if (result?.codeErreur === 0) {
						//Mise à jour du relevé de compteur
						Object.assign(this.releveCompteur,result.data.compteur);

						//Copie du relevé de compteur
						Object.assign(this.releveCompteurCopie,this.releveCompteur);
					}
				});
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture du formulaire après enregistrement
	 */
	onContentClose(releveCompteur) {
		//Mise à jour ou suppression du relevé de compteur
		this.onObjectChange.emit(releveCompteur || null);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Vérification de la modification du relevé de compteur
	 */
	isDirty() {
		//Vérification de la modification du relevé de compteur
		return !isEqual(this.releveCompteur,this.releveCompteurCopie);
	}
}