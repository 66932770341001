<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon *ngIf="!data.detail && !data.empty || !data.valid" name="warning"></icon>
	<span *ngIf="data.valid && (data.detail || data.empty)">{{ data.position + 1 }}</span>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showRubrique()">
			<span *ngIf="data.valid && (data.detail || data.empty)">{{ data.libelle }}</span>
			<span *ngIf="!data.detail && !data.empty" translate [translateParams]="{ position: data.position + 1 }">structureExport.ligne.rubiqueNonDefinie</span>
			<span *ngIf="(data.detail || data.empty) && !data.valid" translate [translateParams]="{ position: data.position + 1 }">structureExport.ligne.rubiqueAnomalie</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.vide</span>&#160;:&#160;<span [translate]="'common.'+(data?.empty ? 'oui' : 'non')"></span></li>
		<li *ngIf="extraOptions.ligne?.detail?.fichier?.typeFormat == 'FIXE' && data.longueur !== null"><span translate>structureExport.ligne.longueur</span>&#160;:&#160;<span>{{ data.longueur }}</span></li>
	</ul>
	<div  *ngIf="(null | right:'creation') && !extraOptions.isRestricted && liste.data.totalElements > 1 && !liste.nbSelectedItems" class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="moveRubrique();"><span translate>actions.deplacer</span></a></li>
		</ul>
	</div>
</div>