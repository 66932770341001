<div class="pull-left lv-avatar"><icon name="info_outline"></icon></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.goToLicenceConsommation?.(data)">{{ (data.periode | date:'MMMM YYYY') | capitalizeFirst }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idLicenceConsommation">
		<li *ngFor="let type of data.listeTypes">
			<span [translate]="'tenant.licenceConsommation.type.'+type.type"></span>&#160;:&#160;<span>{{ type.quantite | number:'1.0-0' }}</span>
		</li>
		<li *ngIf="data.dateCalcul"><span translate>tenant.licenceConsommation.dateCalcul</span>&#160;:&#160;<span>{{ data.dateCalcul | date:'short' }}</span></li>
	</ul>
</div>