import { Injectable } from '@angular/core';
import { HttpClient,HttpEventType,HttpRequest } from '@angular/common/http';
import { Subject,Observable,of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { FileWithProgress,FileUploader } from './file-uploader';

@Injectable()
export class FileUploaderService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Upload de fichiers
	 */
	public upload(fileUploader: FileUploader,listeFiles: Array<File>): Array<Observable<number>> {
		//Retour des fichiers
		return listeFiles.map((f: FileWithProgress) => {
			return of(f).pipe(
				flatMap(f => {
					const formData: FormData = new FormData();
					let progress: Subject<number> = new Subject<number>();

					//Ajout du fichier
					formData.append('file',f);

					//Définition de la progression
					f.progress$ = progress.asObservable();

					//Upload du fichier
					this.http.request(new HttpRequest('POST',fileUploader.url,formData,{
						reportProgress: true
					})).subscribe({
						next: event => {
							//Vérification du type d'évènement
							if (event.type == HttpEventType.UploadProgress) {
								//Notification de la progression
								progress.next(Math.round(100 * event.loaded / event.total));
							} else if (event.type == HttpEventType.Response) {
								//Appel du calback de fichier uploadé
								fileUploader.onItemUploaded && fileUploader.onItemUploaded(event.body,f);

								//Fin de l'upload
								progress.next(100);

								//Fin du traitement
								progress.complete();
							}
						},
						error: () => progress.complete()
					});

					return f.progress$;
				})
			)
		});
	}
}