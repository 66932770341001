import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AmenagementModule } from './amenagement/amenagement.module';
import { EquipementModule } from './equipement/equipement.module';

@NgModule({
	imports: [ShareModule,AmenagementModule,EquipementModule],
	exports: [AmenagementModule,EquipementModule]
})
export class MaterielModule {}