<div class="pull-left lv-avatar">{{ data?.pays?.code2?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Fiscalite/loadTaxe/',data.idTaxe]">{{ data?.pays?.libelle + ' (' + data?.pays?.code2 + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.date"><span translate>fiscalite.taxe.dateApplication</span>&#160;:&#160;<span>{{ data.date | date:'shortDate' }}</span></li>
	<li *ngIf="data.listeTaux?.length"><span translate>fiscalite.taxe.taux</span>&#160;:&#160;<span>{{ getListeTauxAsString() }}</span></li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteTaxe();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>