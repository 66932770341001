import { DatePipe,DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,get } from 'lodash-es';
import { Observable,of } from 'rxjs';
import { map,take,tap } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EntiteService {
	/** Cache des données métiers **/
	private mapBusinessDataForEntite: { [entiteWithMailIndicator: string]: any } = {};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private http: HttpClient,private decimalPipe: DecimalPipe,private datePipe: DatePipe) {}

	/**
	 * Traduction d'une entité
	 */
	public translateEntityCode(codeEntite: string) {
		//Retour du libellé de l'entité
		return this.translateService.instant('businessData.'+codeEntite);
	}

	/**
	 * Chargement des données métier d'une entité
	 */
	public loadBusinessData(entite: string,isForMail: boolean = false): Observable<any> {
		let key: string;

		//Création de la clé
		key = `${entite}-${isForMail}`;

		//Vérification de l'absence de l'entité dans le cache
		if (!this.mapBusinessDataForEntite[key]) {
			//Chargement des données métier
			return this.http.post<Result>(`${environment.baseUrl}/controller/Entite/loadBusinessData/${isForMail}?entite=${entite}`,null).pipe(
				map(result => result?.data?.businessData),
				map(businessData => {
					//Ajout du libellé de l'entité
					businessData.libelle = this.translateEntityCode(businessData.code);

					//Traduction des membres de l'entité
					this.translateEntityListeMembers(businessData);

					return businessData;
				}),
				tap(businessData => this.mapBusinessDataForEntite[key] = cloneDeep(businessData))
			);
		} else
			//Retour du cache
			return of(cloneDeep(this.mapBusinessDataForEntite[key]));
	}

	/**
	 * Chargement de la sous-entité
	 */
	public loadSubEntite(entite: string,isForMail: boolean,listePaths: Array<string>): Observable<any> {
		//Chargement de la sous-entité
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entite/loadSubEntite/${isForMail}?entite=${entite}`,listePaths).pipe(
			map(result => result?.data?.enclosedWrapper),
			map(enclosedWrapper => {

				//Ajout du libellé de l'entité
				enclosedWrapper.libelle = this.translateEntityCode(enclosedWrapper.code);

				//Traduction des membres de l'entité
				this.translateEntityListeMembers(enclosedWrapper);

				return enclosedWrapper;
			})
		);
	}

	/**
	 * Chargement d'une entité
	 */
	public loadEntite(entite: string,idObjet: number,path?: string): Observable<any> {
		//Chargement de l'entité
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entite/loadEntite/${idObjet}`,null,{
			params: {
				entite,
				path: path || null
			}
		}).pipe(
			take(1),
			map(result => result?.data?.entity)
		);
	}

	/**
	 * Lecture d'une valeur
	 */
	public readValue(entite: any,field: { name: string,type: string,value?: string }): string {
		let path;
		let value;

		//Vérification de l'absence de valeur
		if (typeof field.value == 'undefined' || field.value === null) {
			//Suppression du premier niveau du chemin
			path = field.name.split('.').splice(1);

			//Vérification du type
			if (field.type == 'boolean' || field.type == 'java.lang.Boolean') {
				//Retrait du 'is'
				path[path.length - 1] = path[path.length - 1].replace('is','');

				//Passage en minuscule du premier caractère
				path[path.length - 1] = path[path.length - 1].substring(0,1).toLowerCase() + path[path.length - 1].substring(1);
			}

			//Création du chemin
			path = path.map(item => item.indexOf('@') != -1 ? item.substring(0,item.indexOf('@')) : item).join('.');

			//Récupération de la valeur dans l'entité
			value = entite && get(entite,path);
		} else
			//Définition de la valeur
			value = field.value;

		//Vérification du type
		if (field.type == 'java.lang.Double' && value !== null && value !== undefined)
			//Formattage du numérique
			return !isNaN(value) ? this.decimalPipe.transform(value,'1.0-2') : '';
		else if (field.type == 'java.lang.Integer' && value !== null && value !== undefined)
			//Formattage du numérique
			return this.decimalPipe.transform(value,'1.0-0');
		else if (field.type == 'java.util.Date')
			//Formattage de la date
			return this.datePipe.transform(value,'shortDate');
		else if (field.type == 'boolean' || field.type == 'java.lang.Boolean')
			//Formattage du booléen
			return this.translateService.instant(`common.${value ? 'oui' : 'non'}`);
		else
			//Retour de la valeur
			return value;
	}

	/**
	 * Vérification du type 'IHistory'
	 */
	public isIEntity(instance: any): instance is IEntity {
		//Vérification du type 'IEntity'
		return (instance as IEntity).getClassName !== undefined && (instance as IEntity).getIdObject !== undefined;
	}

	/**
	 * Vérification de la présence d'une nouvelle entité pour le composant
	 */
	public isNewEntityForComponent(instance: any): boolean {
		//Vérification de la présence d'une nouvelle entité pour le composant
		return this.isIEntity(instance) && (instance as IEntity).getIdObject() == 0;
	}

	/**
	 * Traduction des membres d'une entité
	 */
	private translateEntityListeMembers(businessData: any) {
		//Parcours des membres
		businessData?.listeMembres?.forEach(membre => {
			//Traduction du libellé du membre
			membre.libelle = this.translateEntityCode(membre.translationKey);

			//Vérification de la présence d'une sous-entité
			if (membre.enclosedWrapper) {
				//Traduction de la sous-entité
				membre.enclosedWrapper.libelle = this.translateEntityCode(membre.enclosedWrapper.translationKey);

				//Traduction des membres de la sous-entité
				this.translateEntityListeMembers(membre.enclosedWrapper);
			}
		});
	}
}