import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';

@Component({
	templateUrl: './account-article-list-item.component.html'
})
export class AccountArticleListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private sharedArticleService: SharedArticleService) {
		//Héritage
		super();
	}

	/**
	 * Consultation de l'article
	 */
	public consultArticle() {
		//Ouverture de l'article
		this.sharedArticleService.consultArticle(this.data);
	}
}