<div class="container-fluid">
	<form #conducteurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="conducteur">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>conducteur.title</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
								<label translate>conducteur.collaborateur</label>
								<div>
									<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="conducteur.user" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
								<label translate>conducteur.dateDebut</label>
								<div class="half">
									<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="conducteur.dateDebut" datePicker format="date" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
								<label translate>conducteur.dateFin</label>
								<div class="half">
									<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="conducteur.dateFin" datePicker format="date"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label personalData translate>conducteur.permis.numero</label>
								<div>
									<a [routerLink]="[]" (click)="showPermis();">
										<span *ngIf="conducteur.permisConduire?.reference">{{ conducteur.permisConduire.reference }}</span>
										<span *ngIf="!conducteur.permisConduire?.reference" translate>common.aRenseigner</span>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>conducteur.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" rows="3" [(ngModel)]="conducteur.remarque"></textarea>
						</div>
					</div>
				</div>
				<data-customization entite="com.notilus.data.vehicule.Conducteur" [object]="conducteur" [disabled]="!(null | right:'creation')"></data-customization>
			</fieldset>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteConducteur();" *ngIf="conducteur.idConducteur && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button class="m-l-10" color="primary" [disabled]="conducteurForm.invalid" (click)="saveConducteur();" *ngIf="(null | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')">
				<span [translate]="'actions.'+(conducteur.idConducteur ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="conducteur.idConducteur" [formDetectChanges]="{ doAction: close,isDirty: conducteurForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>