import { Component,NgZone,OnInit } from '@angular/core';
import { cloneDeep,set } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { map,mergeMap,tap } from 'rxjs/operators';

import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { DataCustomizationService } from 'src/app/share/components/entite/data-customization/data-customization.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	templateUrl: './business-data.component.html'
})
export class BusinessDataComponent implements OnInit {
	/** Données métier à afficher */
	businessData: any;

	/** Entité sélectionnée **/
	entite: string;

	/** Options d'affichage **/
	options: { typeContext: 'RULE' | 'DATAFETCH' | 'MAIL_DESTINATAIRE' | 'MAIL' | 'WORKFLOW_VALIDEUR',isSelectEntity?: boolean,isPreviewData?: boolean,preloadedEntity?: any,doBusinessDataSelection?: (businessData: any,path: string) => void };

	/** Liste des entités ouvertes **/
	listeOpenedEntities: Array<any> = [];

	/** Entité à prévisualiser **/
	loadedEntite: any = null;

	/** Options de l'autocomplete associée à l'entité **/
	public autocompleteOptions: AutocompleteOptions;

	/** Cache des objets par chemin **/
	private mapObjectForPath: { [path: string]: any } = {};

	/** Liste des chemins sélectionnés dans la session **/
	private listeSelectedPaths: Array<string> = [];

	/** Résultat de la sélection **/
	result: { startEntity: string,entity?: any,listeEntities?: Array<any>,listeRemovedEntities?: Array<any> };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<BusinessDataComponent>,private entiteService: EntiteService,private dataCustomizationService: DataCustomizationService,private autocompleteService: AutocompleteService,private ngZone: NgZone) {
		//Binding des méthodes
		this.filterListeMembresByContext = this.filterListeMembresByContext.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Ajout de l'entité courante à la liste de celles ouvertes
		this.listeOpenedEntities.push(this.businessData);

		//Définition de l'entité embarquée
		this.businessData.enclosedWrapper = this.businessData;

		//Déselection/Sélection des données métiers
		this.options.doBusinessDataSelection?.(this.businessData.enclosedWrapper,this.businessData.enclosedWrapper.code);

		//Ré-initialisation des indicateurs
		this.mapObjectForPath = {};

		//Vérification de la prévisualisation des données
		if (this.options.isPreviewData) {
			//Récupération des options de l'autocomplete associée à l'entité
			this.autocompleteOptions = this.autocompleteService.getOptionsForEntite(this.entite);

			//Vérification de la présence d'une entité à prévisualiser
			if (this.options.preloadedEntity) {
				//Chargement de l'entité détaillée
				this.autocompleteOptions && this.entiteService.loadEntite(this.entite,this.options.preloadedEntity[this.autocompleteOptions.getKeyFieldName()]).subscribe({
					next: loadedEntite => this.loadedEntite = loadedEntite
				});
			}
		}
	}

	/**
	 * Filtre de la liste des membres par contexte
	 */
	filterListeMembresByContext(membre) {
		//Vérification du contexte
		if (['MAIL_DESTINATAIRE','WORKFLOW_VALIDEUR'].includes(this.options.typeContext))
			//Vérification de la présence du contexte courant dans la liste des contextes exclusivement gérés par le membre
			return membre.listeValidContexts?.includes?.(this.options.typeContext);
		else
			//Vérification de l'absence du contexte courant dans la liste des contextes non gérés par le membre et du type 'Collection' pour les règles
			return (this.options.typeContext != 'RULE' || !membre.collection) && !membre.listeIgnoredContexts?.includes?.(this.options.typeContext) && !membre.listeValidContexts;
	}

	/**
	 * Sélection d'une entité
	 */
	selectItem(item: any) {
		let startEntity: any;
		let path: string = '';
		let pathLibelle: string = '';
		let autoCompleteFilter: string;

		//Vérification de l'interdiction de sélectionner des entités et de la présence d'une sous-entité
		if ((!this.options.isSelectEntity || !item.selectable) && item.enclosedWrapper != null) {
			//Ouverture de l'entité
			this.openEntity(item);
		} else {
			//Vérification du type de contexte
			if (this.options.typeContext == 'DATAFETCH') {
				//Sélection/déselection de l'élément
				item.selected = !item.selected;

				//Ajout de la sélection à la liste courante
				this.onEntiteSelectionChange(item);
			} else {
				//Parcours des entités ouvertes
				this.listeOpenedEntities.forEach(entity => {
					//Vérification de l'entité de départ
					if (!startEntity)
						//Définition de l'entité de départ
						startEntity = entity;

					//Construction du chemin
					path += (path && !entity.operation ? '.' : '') + entity.code.substring(entity.code.lastIndexOf('.') + 1);

					//Récupération des valeurs précédentes de filtre d'autocomplete
					autoCompleteFilter = entity.autocompleteFilter && entity.autocompleteFilter != 'INHERIT' ? entity.autocompleteFilter : '';
				});

				//Vérification de la construction du chemin pour les mails destinataires
				if (['MAIL_DESTINATAIRE','WORKFLOW_VALIDEUR'].indexOf(this.options.typeContext) != -1) {
					//Définition du path avec le code de l'entité
					path = startEntity.code;

					//Ajout au path du code de l'élément sélectionné qui contient toute l'arborescence sans l'entité de départ
					path += (path && !item.operation ? '.' : '') + item.code;
				} else
					//Construction du chemin
					path += (path && !item.operation ? '.' : '') + item.code.substring(item.code.lastIndexOf('.') + 1);

				//Vérification de la valeur du filtre d'autocomplete
				if (item.autocompleteFilter == 'INHERIT')
					//Définition de la valeur depuis les valeurs précédentes
					item.autocompleteFilter = autoCompleteFilter;

				//Vérification de la liste des entités ouvertes
				if (this.listeOpenedEntities.length > 1) {
					//Parcours des entités ouvertes
					for (let idxEntity = 1;idxEntity < this.listeOpenedEntities.length;idxEntity++)
						//Ajout du libellé
						pathLibelle += (pathLibelle != '' ? ' > ' : '') + this.listeOpenedEntities[idxEntity].libelle;
				}

				//Définition du résultat
				this.bsModalRef.content.result = {
					startEntity: startEntity.type,
					entity: Object.assign(item,{
						path,
						pathLibelle: (pathLibelle != '' ? pathLibelle + ' > ' : '') + item.libelle
					})
				}

				//Retour des éléments sélectionnés
				this.bsModalRef.hide();
			}
		}
	}

	/**
	 * Ouverture d'une entité
	 */
	openEntity(item: any) {
		let listePaths: Array<string>;
		let dataCustomizedEntity;

		//Vérification de l'élément
		if (item.enclosedWrapper) {
			//Création de la liste des codes d'entité
			listePaths = this.listeOpenedEntities.map(e => e.code).concat(item.code);

			//Chargement de la sous-entité
			this.entiteService.loadSubEntite(this.entite,['MAIL_DESTINATAIRE','WORKFLOW_VALIDEUR'].includes(this.options.typeContext),listePaths).pipe(
				mergeMap(enclosedWrapper => {
					//Vérification de la présence de données personnalisées
					if (enclosedWrapper.type.includes('DataCustomization') && !enclosedWrapper.type.includes('ComptaDataCustomization') && !enclosedWrapper.type.endsWith('DataCustomizationEntite')) {
						//Récupération de l'entité en supprimant le nom de la casse
						dataCustomizedEntity = enclosedWrapper.type.replace('DataCustomization','');

						//Chargement de la personnalisation de l'entité
						return this.dataCustomizationService.loadDataCustomizationForEntite(dataCustomizedEntity).pipe(
							map(dataCustomization => {
								//Parcours des membres du wrapper
								enclosedWrapper.listeMembres.forEach(m => {
									//Itération sur les 4 champs de chaque type
									['1','2','3','4'].forEach(num => {
										//Parcours des clés de champs
										[['alpha','alpha'],['numeric','numeric'],['date','date'],['customRef','customRefValue']].forEach(keys => {
											//Vérification du type de champ
											if (dataCustomization?.[`${keys[0]}${num}`] && m.code == `${keys[1]}${num}`)
												//Définition du libellé personnalisé
												m.libelle = dataCustomization[`${keys[0]}${num}`];
										});
									});
								});

								return enclosedWrapper;
							})
						);
					} else
						//Retour des données métiers
						return of(enclosedWrapper);
				}),
				tap(() => {
					let path: string;

					//Récupération du chemin
					path = this.listeOpenedEntities.map(e => e.code).concat([item.code]).join('.');

					//Vérification de la valeur sélectionnée
					if (this.loadedEntite && this.autocompleteOptions && this.listeOpenedEntities.length > 1 && !this.mapObjectForPath[path]) {
						//Chargement de la sous-entité détaillée
						this.entiteService.loadEntite(this.entite,this.loadedEntite[this.autocompleteOptions.getKeyFieldName()],path).subscribe({
							next: loadedSubEntite => {
								//Mise à jour de la valeur dans l'entité parente
								set(this.loadedEntite,path.substring(path.indexOf('.')),loadedSubEntite);

								//Mise à jour de l'indicateur
								this.mapObjectForPath[path] = true;
							}
						});
					}
				})
			).subscribe({
				next: enclosedWrapper => {
					let rootPath: string;

					//Réalisation des traitements sur Angular
					this.ngZone.run(() => {
						//Définition des données
						item.enclosedWrapper = enclosedWrapper;

						//Ajout de l'entité à la liste
						this.listeOpenedEntities.push(item);

						//Pré-sélection des données
						this.options.doBusinessDataSelection?.(enclosedWrapper,rootPath = this.listeOpenedEntities.map((e,idx) => idx == 0 ? e.code : (e.operation ? '' : '.') + e.code).join(''));

						//Vérification de la sélection
						if (this.listeSelectedPaths.length && item?.enclosedWrapper?.listeMembres?.length) {
							//Parcours des membres
							item.enclosedWrapper.listeMembres.forEach(membre => {
								//Définition de l'état
								if (!membre.selected && this.listeSelectedPaths.indexOf(rootPath + '.' + membre.code) != -1)
									//Sélection de la donnée
									membre.selected = true;
							});
						}
					});
				}
			});
		}
	}

	/**
	 * Retour en arrière vers l'entité
	 */
	gotBackToEntity(index: number) {
		//Coupage de la liste des entités ouvertes
		this.listeOpenedEntities.length = index;
	}

	/**
	 * Lecture de la valeur
	 */
	readValue(item: any) {
		let currentPath: string;
		let field;

		//Récupération du chemin formé par les niveaux d'entités ouverts
		currentPath = this.listeOpenedEntities.reduce((actual,item) => actual + (actual && !item.operation ? '.' : '') + item.code,'');

		//Construction du champ dont la valeur est lue
		field = {
			name: currentPath + (currentPath && !item.operation ? '.' : '') + item.code,
			type: item.type
		};

		//Lecture de la valeur du champ
		return this.entiteService.readValue(this.loadedEntite,field);
	}

	/**
	 * Changement de sélection de l'entité
	 */
	onEntiteSelectionChange(entite) {
		let code;

		//Définition du code de l'entité
		code = this.listeOpenedEntities.map((e,idx) => idx == 0 ? e.code : (e.operation ? '' : '.') + e.code).join('') + '.' + entite.code;

		//Vérification de l'état de sélection de l'entité
		if (entite.selected)
			//Ajout à la liste
			this.listeSelectedPaths.push(code);
		else if (this.listeSelectedPaths.indexOf(code) != -1)
			//Retrait de la liste
			this.listeSelectedPaths.splice(this.listeSelectedPaths.indexOf(code),1);
	}

	/**
	 * Enregistrement des éléments
	 */
	saveItems() {
		let selectedAndRemovedEntities: { listeEntities?: Array<any>,listeRemovedEntities?: Array<any> } = {};
		let path: string = '';

		//Vérification de l'entité
		if (this.listeOpenedEntities?.length) {
			//Construction du chemin
			path = this.listeOpenedEntities[0].code;

			//Parcours des entités
			selectedAndRemovedEntities = this.retrieveSelectedItems(this.listeOpenedEntities[0],selectedAndRemovedEntities,path);

			//Définition du résultat
			this.bsModalRef.content.result = {
				startEntity: this.listeOpenedEntities[0].type,
				listeEntities: selectedAndRemovedEntities.listeEntities,
				listeRemovedEntities: selectedAndRemovedEntities.listeRemovedEntities
			}

			//Retour des éléments sélectionnés
			this.bsModalRef.hide();
		}
	}

	/**
	 * Récupération des éléments sélectionnés
	 */
	private retrieveSelectedItems(entite: any,selectedAndRemovedEntities: { listeEntities?: Array<any>,listeRemovedEntities?: Array<any> },path: string,libelle?: string) {
		let currentPath;
		let currentLibelle;

		//Parcours des membres de l'entité
		entite.listeMembres?.forEach?.((item) => {
			//Définition du chemin
			currentPath = path + (!item.operation ? '.' : '') + item.code;

			//Définition du libellé
			currentLibelle = (libelle ? libelle + (!item.operation ? ' > ' : '@') : '') + item.libelle;

			//Vérification de la présence d'un wrapper
			if (item.enclosedWrapper) {
				//Appel récursif sur l'entité
				this.retrieveSelectedItems(item.enclosedWrapper,selectedAndRemovedEntities,currentPath,currentLibelle);
			} else if (item.selected) {
				//Vérification et création de la liste des entités sélectionnées (si nécessaire)
				selectedAndRemovedEntities.listeEntities = selectedAndRemovedEntities.listeEntities || [];

				//Ajout de l'entité à la liste
				selectedAndRemovedEntities.listeEntities.push(Object.assign(cloneDeep(item),{
					path: currentPath,
					pathLibelle: currentLibelle
				}));
			} else if (item.selected === false) {
				//Vérification et création de la liste des entités dé-sélectionnées (si nécessaire)
				selectedAndRemovedEntities.listeRemovedEntities = selectedAndRemovedEntities.listeRemovedEntities || [];

				//Ajout de l'entité à la liste
				selectedAndRemovedEntities.listeRemovedEntities.push(Object.assign(cloneDeep(item),{
					path: currentPath,
					pathLibelle: currentLibelle
				}));
			}
		});

		return selectedAndRemovedEntities;
	}
}