import { Directive,ElementRef,HostListener,Input,OnInit,DoCheck,HostBinding } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { ListItem } from 'src/app/domain/common/list-view/list-item';

@Directive({
	selector: '[nSelector]'
})
export class ListViewSelectorDirective implements OnInit,DoCheck {
	/** Données **/
	@Input() nSelector: { liste: ListView<any,any>,item: ListItem };

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la présence d'actions
		if (this.nSelector.liste.listeActions?.length)
			//Définition de l'icône de base
			this.elementRef.nativeElement.setAttribute('data-selected','\uf279');
	}

	/**
	 * Ajout dynamique de la classe CSS 'selectable' selon la présence d'actions visibles
	 */
	@HostBinding('class.selectable')
	get selectable() {
		//Retour de la présence d'actions visibles
		return (this.nSelector.liste.listeActions || []).filter(a => !a.isVisible || a.isVisible()).length > 0;
	}

	/**
	 * Interception d'un clic sur l'élément
	 */
	@HostListener('click')
	onClick() {
		//Vérification de la présence d'actions visibles
		if ((this.nSelector.liste.listeActions || []).filter(a => !a.isVisible || a.isVisible()).length) {
			//Sélection de l'élément
			this.nSelector.item.isSelected = !this.nSelector.item.isSelected;

			//Mise à jour du nombre d'éléments sélectionnés
			this.nSelector.liste.nbSelectedItems = this.nSelector.liste.nbSelectedItems + (this.nSelector.item.isSelected && this.nSelector.liste.typeActionMasse == 'SELECTION' ? 1 : -1);
		}
	}

	/**
	 * Vérification des changements
	 */
	ngDoCheck() {
		//Mise à jour de l'icône
		this.elementRef.nativeElement.setAttribute('data-selected',this.nSelector.item.isSelected && this.nSelector.liste.typeActionMasse == 'SELECTION' || !this.nSelector.item.isSelected && this.nSelector.liste.typeActionMasse == 'FULL' ? '\uf26b' : '\uf279');
	}
}