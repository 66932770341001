import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { MailHistoryService } from './mail-history.service';

@Component({
	templateUrl: './mail-history-list-item.component.html'
})
export class MailHistoryListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private mailHistoryService: MailHistoryService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du détail de l'historique du mail
	 */
	showMailHistoryDetail(idMailHistory: number) {
		//Affichage du détail de l'historique du mail
		this.mailHistoryService.showMailHistoryDetail(idMailHistory);
	}
}