import { Pipe,PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Pipe({
	name: 'fieldKeyValue',
	pure: false
})
export class FieldKeyValuePipe implements PipeTransform {
	/**
	 * Transformation
	 */
	transform(value: any,fieldKey: string | ((data) => string)): string {
		let readValue;

		//Vérification de la présence d'une clé
		if (fieldKey) {
			//Vérification du type de clef
			if (typeof fieldKey == 'string')
				//Itération sur la liste des champs
				readValue = get(value,fieldKey?.split('.'));
			else if (typeof fieldKey == 'function')
				//Appel de la fonction de clef
				readValue = fieldKey(value);
		}

		//Retour de la valeur
		return readValue;
	}
}
