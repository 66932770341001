import { Injectable } from '@angular/core';

@Injectable()
export class StyleService {
	/** Police par défaut **/
	private defaultFontFamily: string;

	/**
	 * Récupération de la police par défaut
	 */
	public getDefaultFontFamily(): string {
		//Vérification du cache
		if (!this.defaultFontFamily)
			//Récupération de la police définie sur l'élément 'body'
			this.defaultFontFamily = window.getComputedStyle(document.querySelector('body'))?.getPropertyValue('font-family');

		//Retour de la police par défaut
		return this.defaultFontFamily;
	}
}