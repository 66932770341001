import { Component,EventEmitter,Input,Output,ViewEncapsulation,AfterViewInit,ViewChildren,ElementRef,QueryList,OnDestroy,ChangeDetectorRef } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of,Subscription } from 'rxjs';
import { filter,switchMap } from 'rxjs/operators';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { GLOBALS } from 'src/app/utils/globals';

@Component({
	selector: 'workflow-detail-branch',
	templateUrl: './workflow-detail-branch.component.html',
	styles: ['workflow-detail-branch { display: contents; }'],
	encapsulation: ViewEncapsulation.None,
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class WorkflowDetailBranchComponent implements AfterViewInit,OnDestroy {
	/** Workflow **/
	@Input() readonly workflow: any;

	/** Etape **/
	@Input() readonly etape: any;

	/** Mode édition **/
	@Input() readonly isEdition: boolean = false;

	/** Index de l'étape courante **/
	@Input() readonly index: number;

	/** Notification de la suppression de la branche **/
	@Output() onBrancheDeletion: EventEmitter<any> = new EventEmitter<any>();

	/** Mémorisation de la branche survolée **/
	branchKeyWithHover: string;

	/** Mémorisation de la branche avec dropdown ouvert **/
	branchKeyWithDropdown: string;

	/** Souscription aux changements du template **/
	private dropdownMenuSubscription: Subscription;

	/** Dropdown (liste des actions) **/
	@ViewChildren('dropdownMenu') dropdownMenu: QueryList<ElementRef<HTMLLIElement>>;

	/**
	 * Constructeur
	 */
	constructor(private workflowService: WorkflowService,private confirmService: ConfirmService,private translateService: TranslateService,private changeDetectorRef: ChangeDetectorRef) { }

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Interception de l'affichage/masquage du template
		this.dropdownMenuSubscription = this.dropdownMenu.changes.subscribe(children => {
			//Parcours des éléments
			children?.forEach((dropdown: ElementRef<HTMLLIElement>) => {
				//Écoute de la fermeture du dropdown
				GLOBALS.$(dropdown.nativeElement).on('hide.bs.dropdown',() => {
					//Notification de la fermeture
					this.branchKeyWithDropdown = null;

					//Détection des changements
					this.changeDetectorRef.detectChanges();
				});
			});
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.dropdownMenuSubscription.unsubscribe();
	}

	/**
	 * Vérification de la nécessité d'afficher l'addon du champ de saisie du libellé
	 */
	isShowAddonForBranch(key: string) {
		//Retour de l'indicateur
		return this.branchKeyWithDropdown == key || this.branchKeyWithHover == key;
	}

	/**
	 * Suppression de la branche
	 */
	deleteBranch(index: number) {
		//Suppression de la branche
		of(true).pipe(
			switchMap(() => {
				//Vérification du nombre de branches
				if (this.etape.listeBranches?.length > 2) {
					//Vérification de la présence de sous-éléments
					if (this.etape.listeBranches?.[index]?.listeEtapes?.length > 0)
						//Message de confirmation
						return this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.suppressionBrancheWithChild'))
					else
						//Poursuite du traitement
						return of(true);
				} else {
					//Vérification de la présence de sous-éléments
					if (this.etape.listeBranches?.[index]?.listeEtapes?.length > 0 && this.etape.listeBranches?.at(-1)?.listeEtapes?.length > 0)
						//Message de confirmation
						return this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.suppressionBrancheAndDefaultWithChild'))
					else if (this.etape.listeBranches?.[index]?.listeEtapes?.length > 0)
						//Message de confirmation
						return this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.suppressionBrancheWithChild'));
					else if (this.etape.listeBranches?.at(-1)?.listeEtapes?.length > 0)
						//Message de confirmation
						return this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.suppressionBrancheDefaultWithChild'));
					else
						//Poursuite du traitement
						return of(true);
				}
			}),
			filter(isConfirmed => !!isConfirmed)
		).subscribe({
			next: () => {
				//Vérification du nombre de branches
				if (this.etape.listeBranches.length > 2)
					//Suppression de la branche
					(this.etape.listeBranches as Array<any>).splice(index,1);
				else
					//Notification pour suppression de la branche
					this.onBrancheDeletion.emit();
			}
		})
	}

	/**
	 * Ouverture de la pop-up des règles
	 */
	editRule(branche: any) {
		//Edition des règles
		this.workflowService.showRuleDefinition(this.workflow.entite,branche,this.isEdition).subscribe({
			next: (result) => {
				//Définition de la règle
				branche.rule = result.rule;
				branche.libelle = result.libelle;
			}
		});
	}
}
