<div class="modal-header">
	<h4 class="modal-title" translate>pleaseWait.title</h4>
</div>
<div class="modal-body">
	<div class="alert alert-info">
		<icon name="info"></icon>
		<strong *ngIf="!message" translate>pleaseWait.message</strong>
		<strong *ngIf="message">{{ message }}</strong>
	</div>
	<div class="text-center">
		<please-wait></please-wait>
	</div>
</div>