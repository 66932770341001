<div class="pull-left lv-avatar" *ngIf="!data.societe"><icon name="warning"></icon></div>
<div class="pull-left lv-avatar" *ngIf="data.societe">{{ data.societe.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addLienFacturation?.(data,deleteLienFacturation)">
			<span *ngIf="data.societe">{{ data.societe.libelle + ' - ' + data.code }}</span>
			<span *ngIf="!data.societe" translate [translateParams]="{ compteFacturation: data.code }">fournisseur.facturation.societeNonIdentifiee</span>
		</a>
	</div>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.FACTURE | right:'creation'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="deleteLienFacturation();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>