<div class="condition-item p-l-20 hoverable">
	<div class="actions pull-right hovered-item p-t-10 p-r-10" *ngIf="(null | right:'creation') && !isRestricted">
		<a [routerLink]="[]" (click)="deleteCondition(parentFonction,idxCondition)">
			<icon name="delete"></icon>
		</a>
	</div>

	<div class="text-left">
		<a [routerLink]="[]" *ngIf="!item.cle && (null | right:'creation') && !isRestricted" (click)="onTypeRubriqueChange.emit({ parent: item,parentKey: 'cle' })" translate>common.aDefinir</a>
		<span *ngIf="!item.cle && (!(null | right:'creation') || isRestricted)" translate>common.nonDefini</span>
		<div *ngIf="item.cle">
			<structure-export-rubrique-detail [detail]="item.cle" [parent]="item" [parentKey]="'cle'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
		</div>
	</div>

	<div class="d-flex">
		<div class="form-group flex-1">
			<selectpicker [(ngModel)]="item.operateur" [disabled]="!(null | right:'creation')">
				<mat-option *ngFor="let operateur of (item.listeOperateurs || initListeOperateurs())" [value]="operateur.code"><span [translate]="'rule.operateur.'+operateur.code"></span></mat-option>
			</selectpicker>
		</div>
		<span class="flex-2"></span>
	</div>

	<div class="text-left" *ngIf="!item.operateur || ['IS_NULL','IS_NOT_NULL'].indexOf(item.operateur) == -1">
		<a [routerLink]="[]" *ngIf="!item.valeur && (null | right:'creation') && !isRestricted" (click)="onTypeRubriqueChange.emit({ parent: item,parentKey: 'valeur' })" translate>common.aDefinir</a>
		<span *ngIf="!item.valeur && (!(null | right:'creation') || isRestricted)" translate>common.nonDefini</span>
		<div *ngIf="item.valeur">
			<structure-export-rubrique-detail [detail]="item.valeur" [parent]="item" [parentKey]="'valeur'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
		</div>
	</div>
</div>