import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';

@Injectable()
export class MarqueService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression de la marque
	 */
	public deleteMarque(marque: any): Observable<Result> {
		//Suppression de la marque
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteMarque/${marque.idMarque}`);
	}

	/**
	 * Enregistrement de la marque
	 */
	public saveMarque(marque: any): Observable<Result> {
		//Enregistrement de la marque
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveMarque`,marque);
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(type: 'HORS_CATALOGUE' | 'CATALOGUE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadDashboardMarque/${type}`,searchSpec);
	}
}