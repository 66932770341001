import { Component,Input,OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { LotComptableFactureListItemComponent } from './lot-comptable-facture-list-item.component';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { LotComptableService } from './lot-comptable.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'lot-comptable-facture-list',
	templateUrl: './lot-comptable-facture-list.component.html'
})
export class LotComptableFactureListComponent extends PageContentItem implements OnInit {
	/** Lot comptable **/
	@Input() lotComptable: any;

	/** Resumé **/
	@Input() resume: { nbFactures: number };

	/** Restriction **/
	@Input() restriction: { isRestricted: boolean };

	/** Données **/
	liste: ListView<any,LotComptableFactureListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService,private lotComptableService: LotComptableService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableFactureListItemComponent>({
			uri: () => `/controller/Facture/filtreFactures`,
			component: LotComptableFactureListItemComponent,
			isLocal: true,
			extraOptions: {
				lotComptable: this.lotComptable,
				restriction: this.restriction,
				resume: this.resume
			},
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'fournisseur.libelle',
				isDefault: true
			}],
			defaultOrder: '-date,reference',
			listeStaticFilters: [{
				clef: 'lotComptable.idLotComptable',
				valeur: this.lotComptable.idLotComptable,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			getKeyFieldName: () => 'idFacture',
			listeActions: [{
				icon: 'delete',
				messagingOptions: {
					entryPoint: `controller/LotComptable/deleteAllItemsFromLot/FACTURE/${this.lotComptable.idLotComptable}`
				},
				isVisible: () => this.rightService.hasRight(null,'creation'),
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => {
					//Suppression des factures
					this.lotComptableService.deleteAllItemsForSelection(messagingOptions,this.liste).subscribe({
						complete: () => {
							//Chargement du lot comptable
							this.lotComptableService.loadLotComptable(this.lotComptable.idLotComptable).pipe(first()).subscribe({
								next: result => {
									//Vérification du résultat
									if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
										//Mise à jour du lot comptable
										Object.assign(this.lotComptable,result.data.lotComptable);

										//Mise à jour du résumé
										this.resume.nbFactures = result.data?.nbFactures || 0;
									}
								}
							})
						}
					});
				}
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idFacture > 0)
			//Mise à jour des compteurs
			this.resume.nbFactures--;
	}
}