/**
 * Enumération des types de dashboard
 */
export enum TypeDashboard {
	/** Enumération **/
	VEHICULE = 'VEHICULE',
	SINISTRE = 'SINISTRE',
	CONTRAVENTION = 'CONTRAVENTION',
	USER = 'USER',
	FINANCEMENT = 'FINANCEMENT',
	FACTURE = 'FACTURE',
	ENTRETIEN = 'ENTRETIEN',
	GRILLE_ATTRIBUTION = 'GRILLE_ATTRIBUTION',
	CONDUCTEUR = 'CONDUCTEUR',
	LOT_COMPTABLE = 'LOT_COMPTABLE',
	DEMANDE_IDENTIFICATION = 'DEMANDE_IDENTIFICATION',
	DEMANDE_VEHICULE = 'DEMANDE_VEHICULE',
	DEMANDE_COTATION = 'DEMANDE_COTATION',
	FACTURE_DETAIL = 'FACTURE_DETAIL',
	VEHICULE_RELEVE_ENERGIE = 'VEHICULE_RELEVE_ENERGIE',
	VEHICULE_COMPTEUR = 'VEHICULE_COMPTEUR',
	VEHICULE_AFFECTATION = 'VEHICULE_AFFECTATION',
	IMPORT = 'IMPORT',
	ETAT_LIEUX = 'ETAT_LIEUX',
	SGI_LOT = 'SGI_LOT',
	VEHICULE_RESERVATION = 'VEHICULE_RESERVATION',
	ARTICLE = 'ARTICLE',
	EQUIPEMENT = 'EQUIPEMENT',
	VEHICULE_AFFECTATION_ECHEANCE = 'VEHICULE_AFFECTATION_ECHEANCE'
}

/**
 * Enumération des types de layout
 */
export enum TypeLayout {
	/** Enumération **/
	WEB = 'WEB',
	TABLETTE = 'TABLETTE',
	MOBILE = 'MOBILE'
}