<form #customRefValueForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>dataCustomization.customRefValue.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label translate>dataCustomization.customRefValue.entite</label>
			<div>
				<selectpicker name="type" #entiteField="ngModel" [(ngModel)]="customRefValue.entite" disabled required>
					<mat-option *ngFor="let entite of listeEntites" [value]="entite.code">{{ entite.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>dataCustomization.customRefValue.libelle</label>
			<div>
				<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="customRefValue.libelle" autofocus required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>dataCustomization.customRefValue.reference</label>
			<div>
				<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="customRefValue.reference" maxlength="100" required/>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="customRefValueForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>