import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeContratFinancementListItemComponent } from './vehicule-contrat-financement-list-item.component';

@Component({
	selector: 'vehicule-contrat-financement-list',
	templateUrl: './vehicule-contrat-financement-list.component.html'
})
export class VehiculeContratFinancementListComponent extends PageContentItem implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Fonction d'édition **/
	@Input() addContratFinancement: () => void;

	/** Résumé **/
	@Input() resume: { nbContratsFinancement: number };

	/** Données **/
	liste: ListView<any,VehiculeContratFinancementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeContratFinancementListItemComponent>({
			uri: () => `/controller/Vehicule/filtreContratsFinancement`,
			component: VehiculeContratFinancementListItemComponent,
			isLocal: true,
			extraOptions: {
				addContratFinancement: this.addContratFinancement
			},
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'fournisseur',
				autocomplete: {
					type: 'fournisseur'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idContrat > 0)
			//Mise à jour des compteurs
			this.resume.nbContratsFinancement--;
	}
}