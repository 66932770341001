<div class="modal-header">
	<h4 class="modal-title">
		<span *ngIf="title">{{ title }}</span>
		<span *ngIf="!title" translate>selectionAction.title</span>
	</h4>
</div>
<div class="modal-body modal-actions-list">
	<ul>
		<li class="action-item" [ngClass]="{ 'clickable': action.counter == undefined || action.counter != undefined && action.counter > 0 }" *ngFor="let action of listeActions" (click)="(action.counter == undefined || action.counter != undefined && action.counter > 0) && close(action)" matRipple>
			<div class="action-icon" *ngIf="action.icon">
				<icon [name]="action.icon" class="icon-hc-5x"></icon>
			</div>
			<div class="action-label">
				<span>{{ action.libelle }}</span>
			</div>
		</li>
	</ul>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>