import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { BasketComponent } from './basket.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ValidatorModule } from 'src/app/share/directive/validate/validator.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,AutocompleteModule,IconModule,ValidatorModule,FilterModule],
	declarations: [BasketComponent],
	exports: [BasketComponent]
})
export class BasketModule { }