<div class="container-fluid">
	<form #demandeIdentificationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="card full">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>demandeIdentification.vehicule</label>
								<div>
									<span>{{ demandeIdentification.vehicule | display:'vehicule' }}</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeIdentification.numeroDossier.long</label>
								<div>
									<span>{{ demandeIdentification.reference }}</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeIdentification.dateInfraction.long</label>
								<div>
									<span>{{ demandeIdentification.dateInfraction | date:'short' }}</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeIdentification.datePremiereDemande</label>
								<div>
									<span>{{ demandeIdentification.dateCreation | date:'short' }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>demandeIdentification.motifInfraction</label>
						<div>
							<span>{{ demandeIdentification.typeInfraction.libelle }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card full" *ngIf="null | right:'creation'">
				<data-customization entite="com.notilus.data.sgi.DemandeIdentification" [object]="demandeIdentification" [disabled]="!(null | right:'creation')"></data-customization>
			</div>
		</fieldset>
		<span class="pull-right">
			<button mat-raised-button color="primary" [disabled]="demandeIdentificationForm.invalid" (click)="saveDemandeIdentification(demandeIdentification,false,close);" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: demandeIdentificationForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>