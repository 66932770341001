import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { RevendeurListItemComponent } from './revendeur-list-item.component';
import { RevendeurListComponent } from './revendeur-list.component';
import { RevendeurComponent } from './revendeur.component';
import { RevendeurService } from './revendeur.service';

@NgModule({
	imports: [ShareModule],
	declarations: [RevendeurListComponent,RevendeurListItemComponent,RevendeurComponent],
	providers: [RevendeurService]
})
export class RevendeurModule { }