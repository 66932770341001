<div class="container-fluid">
	<form #profilForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>profil.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="profil.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>profil.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="profil.reference" maxlength="100" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label translate>profil.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="profil.actif"><span translate>profil.actif.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="card-body card-padding m-t-20">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>profil.restriction.titre</span></h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label><span translate>profil.restriction.typeRestriction</span></label>
								<div>
									<selectpicker name="typeProfil" [(ngModel)]="profil.typeRestriction" (ngModelChange)="profil.listeSocietes = null; profil.typeRestrictionDynamique = null;">
										<mat-option *ngFor="let typeRestriction of listeTypesRestriction" [value]="typeRestriction.code">{{ typeRestriction.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="profil.typeRestriction == 'SOCIETE'">
						<div>
							<basket [model]="profil" propertyName="listeSocietes" colWidthLabel="12" colWidthAutocomplete="6" type="societe" [labelAutocomplete]="'profil.societe.ajout' | translate" [labelListe]="'profil.societe.liste' | translate" [hasError]="hasErrorListeSocietes"></basket>
						</div>
					</div>
					<div class="row" *ngIf="profil.typeRestriction == 'SERVICE' || profil.typeRestriction == 'VEHICULE_POOL'">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': typeRestrictionDynamique.invalid }">
								<label><span translate>profil.restriction.typeRestrictionDynamique</span></label>
								<div class="half">
									<selectpicker name="typeRestrictionDynamique" #typeRestrictionDynamique="ngModel" [(ngModel)]="profil.typeRestrictionDynamique" required>
										<mat-option *ngFor="let typeRestrictionDynamique of listeTypesRestrictionDynamique" [value]="typeRestrictionDynamique.code">{{ typeRestrictionDynamique.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteProfil();" *ngIf="profil?.idProfil && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveProfil(profil,close);" *ngIf="null | right:'creation'" [disabled]="profilForm.invalid">
				<span [translate]="'actions.' + (profil?.idProfil ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="profil?.idProfil" [formDetectChanges]="{ doAction: close,isDirty: profilForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>