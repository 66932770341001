import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { ContratFinancementService } from 'src/app/components/vehicule/contrat/contrat-financement.service';
import { ImmatriculationService } from 'src/app/components/vehicule/immatriculation/immatriculation.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { User } from 'src/app/domain/user/user';

@Component({
	templateUrl: './demande-vehicule-livraison.component.html'
})
export class DemandeVehiculeLivraisonComponent implements OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Livraison **/
	@Input() livraison: any;

	/** Possibilité de dupliquer les pièces jointes vers le véhicule livré **/
	@Input() canDuplicateAttachments: boolean;

	/** Nombre de pièces jointes de la demande **/
	@Input() nbAttachments: number;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Livraison modifiée **/
	public savedLivraison: any;

	/** Liste des types d'affectation  **/
	public listeTypesAffectation: Array<{ code: string,libelle: string }>;

	/** Liste des modes d'affichage de prix **/
	public listeTypesAffichagePrix: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DemandeVehiculeLivraisonComponent>,private vehiculeService: VehiculeService,private affectationService: AffectationService,private contratFinancementService: ContratFinancementService,private immatriculationService: ImmatriculationService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types d'affectation
		this.listeTypesAffectation = this.affectationService.getListeTypesAffectation();

		//Définition de la liste des modes d'affichage de prix
		this.listeTypesAffichagePrix = this.contratFinancementService.getListeTypesAffichagePrix();

		//Gestion initiale de l'indicateur de création d'un contrat
		this.onCreateContratChange();

		//Gestion initiale de l'indicateur de création d'une affectation
		this.onCreateAffectationChange();
	}

	/*
	 * Sélection de l'immatriculation
	 */
	public selectImmatriculation() {
		let oldVehicule: any;

		//Mémorisation du véhicule avant modification
		oldVehicule = this.livraison.vehicule;

		//Sélection de l'immatriculation
		this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any,vehicule: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Mise à jour du véhicule
				this.livraison.vehicule = {
					reference: data.immatriculation.reference,
					immatriculation: data.immatriculation,
					modeleConstructeur: data.params.modele
				};

				//Vérification de l'absence initiale de véhicule
				if (!oldVehicule && this.livraison.financement && !this.livraison.financement.typeAffichagePrix)
					//Définition du mode d'affichage du prix
					this.livraison.financement.typeAffichagePrix = this.livraison.vehicule.immatriculation?.genre?.modeAffichagePrix || null;
			}
		},{
			isDateEntreeVisible: false,
			isWithEngin: false,
			useExistingImmatriculation: true,
			modeleInitial: this.demandeVehicule.modele,
			isSynchro: true
		}).subscribe();
	}

	/**
	 * Affichage du certificat d'immatriculation
	 */
	public showImmatriculation() {
		let immatriculation: any;

		//Récupération de l'immatriculation à afficher
		immatriculation = this.livraison.vehicule?.immatriculation;

		//Vérification du modèle
		if (immatriculation?.modele)
			//Définition de la marque
			immatriculation.marque = immatriculation.modele.marque;

		//Ouverture de la popup d'immatriculation
		this.immatriculationService.showImmatriculation({
			immatriculation,
			vehicule: this.livraison.vehicule,
			options: {
				isConsultationOnly: true
			}
		}).subscribe();
	}

	/**
	 * Interception d'un changement de l'indicateur de création de contrat
	 */
	public onCreateContratChange() {
		let isCreateContrat: boolean;
		let genre: any;

		//Lecture de l'indicateur
		isCreateContrat = this.livraison.createContrat;

		//Vérification de la création d'un contrat de financement
		if (isCreateContrat) {
			//Initialisation du financement
			this.livraison.financement = {};

			//Vérification du financement de la demande de véhicule
			if (this.demandeVehicule.financement) {
				//Création d'un financement type
				this.livraison.financement = cloneDeep(this.demandeVehicule.financement);

				//Mise à jour du prix d'achat
				if (this.demandeVehicule.financement.informationTarifaire)
					//Mise à jour du prix d'achat
					this.livraison.financement.prixAchat = this.demandeVehicule.financement.informationTarifaire.prixTTCRemise || this.demandeVehicule.financement.informationTarifaire.prixTTC;
			} else {
				//Récupération du genre du véhicule
				genre = this.livraison.vehicule && (this.livraison.vehicule.genre || this.livraison.vehicule.immatriculation?.genre);

				//Définition du mode d'affichage du prix par défaut
				this.livraison.financement.typeAffichagePrix = genre?.modeAffichagePrix || null;
			}

			//Vérification de la référence de la commande
			if (this.demandeVehicule.referenceCommande) {
				//Création du financement si nécessaire
				this.livraison.financement = this.livraison.financement || {};

				//Initialisation avec la référence du financement
				this.livraison.financement.reference = this.demandeVehicule.referenceCommande;
			}
		} else {
			//Suppression du financement
			this.livraison.financement = null;
		}
	}

	/**
	 * Interception d'un changement de l'indicateur de création d'affectation
	 */
	public onCreateAffectationChange() {
		let isCreateAffectation: boolean;

		//Lecture de l'indicateur
		isCreateAffectation = this.livraison.createAffectation;

		//Vérification de la création d'une affectation
		if (isCreateAffectation) {
			//Vérification de l'utilisateur affecté à la demande de véhicule
			if (this.demandeVehicule.user)
				//Définition de l'utilisateur
				this.livraison.user = this.demandeVehicule.user;
		} else
			//Suppression de l'utilisateur
			this.livraison.user = null;
	}

	/**
	 * Fermeture (validation) de la pop-up
	 */
	public doContinue() {
		//Définition de la livraison modifiée
		this.savedLivraison = this.livraison;

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}

	/**
	 * Vérification de la date d'affectation
	 */
	public isDateAffectationValid(): boolean {
		//Vérification de la date d'affectation
		return !this.livraison.dateAffectation || !this.livraison.dateEntree || this.livraison.dateEntree <= this.livraison.dateAffectation;
	}
}