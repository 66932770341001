import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratFinancementService } from './contrat/contrat-financement.service';

@Component({
	templateUrl: './vehicule-contrat-financement-list-item.component.html',
})
export class VehiculeContratFinancementListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private contratFinancementService: ContratFinancementService,private store: Store<AppState>,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();

		//Binding
		this.deleteContratFinancement = this.deleteContratFinancement.bind(this)
	}

	/**
	 * Initialisation
 	 */
	ngOnInit() {
		//Sélection du paramétrage
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Récupération de la liste des avenants
	 */
	getListeAvenantsAsString(): string {
		//Liste des avenants séparés par des virgules
		return this.data.listeLoisRoulage?.filter(loiRoulage => loiRoulage.reference && loiRoulage.reference != this.data.reference)?.map(loiRoulage => loiRoulage.reference)?.join(', ');
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteContratFinancement(contratFinancement: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contratFinancementService.deleteContratFinancement(contratFinancement))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Redirection vers le contrat de financement
	 */
	public goToContratFinancement() {
		//Navigation vers le contrat de financement
		this.layoutService.goToByState('listeVehiculeContratsFinancement-loadContratFinancement',{
			routeParams: {
				idContrat: this.data.idContrat
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage du contrat de financement
	 */
	addContratFinancement() {
		//Chargement complet du contrat de financement
		this.contratFinancementService.loadContratFinancement(this.data.idContrat).pipe(first()).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR)
					//Affichage du contrat de financement
					this.extraOptions.addContratFinancement(result.data.contrat,this.deleteContratFinancement);
			}
		});
	}
}