<div class="pull-left lv-avatar">
	<icon [type]="mapNotifications[data.entite].type" [name]="mapNotifications[data.entite].icone"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showScheduler()">{{ data?.libelle }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>account.notification.frequence</span>&#160;:&#160;<span [translate]="'scheduler.frequence.' + data.scheduler?.type"></span></li>
		<li *ngIf="data.profil"><span translate>account.notification.profil</span>&#160;:&#160;<span>{{ data.profil.libelle }}</span></li>
	</ul>
</div>