import { Component,OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { VehiculeImmatriculationListItemComponent } from './vehicule-immatriculation-list-item.component';

@Component({
	templateUrl: './vehicule-immatriculation-list.component.html'
})
export class VehiculeImmatriculationListComponent implements OnInit {
	/** Identifiant du véhicule **/
	idVehicule: number;

	/** Données **/
	liste: ListView<any,VehiculeImmatriculationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<VehiculeImmatriculationListComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeImmatriculationListItemComponent>({
			uri: `${environment.baseUrl}/controller/Vehicule/filtreImmatriculationsByVehicule/${this.idVehicule}`,
			component: VehiculeImmatriculationListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			}],
			generateDisplayedContent: (liste) => {
				//Tri de la liste selon la date de la carte grise
				return liste.data?.content && orderBy(liste.data.content,['dateCarteGrise'],['desc']);
			}
		});
	}
}