import { AfterContentChecked,ChangeDetectorRef,Component,ComponentRef,HostListener,Input,OnChanges,OnInit,SimpleChanges,ViewChild,ViewContainerRef } from '@angular/core';
import { ChartComponent as ApexChartComponent } from 'ng-apexcharts';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Subject,Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ChartAction,ChartOptions,ExternalChartOptions,SubTypeChart,TypeChart } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { ChartMaximizerComponent } from './chart-maximizer.component';
import { ChartTipComponent } from './chart-tip.component';
import { ChartService } from './chart.service';

@Component({
	selector: 'chart',
	templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit,OnChanges,AfterContentChecked {
	/** Options du graphique **/
	@Input() options: ChartOptions;

	/** Données du graphique **/
	@Input() data: Page<any>;

	/** Indicateur d'absence d'actions **/
	@Input() hasNoActions: boolean = false;

	/** Options du graphique externe **/
	public chartOptions: ExternalChartOptions;

	/** Indicateur de masquage du graphique **/
	public isHidden: boolean = false;

	/** Timeout de redimensionnement de la fenêtre **/
	private resizeTimeout: any;

	/** Souscription pour l'écoute du redimensionnement des graphiques **/
	private onChartResizeSubscription: Subscription;

	/** Souscription pour l'écoute du chargement des composantes du graphiques **/
	private onInitSubscription: Subscription;

	/** Sujet sur l'initialisation des données **/
	private initDataSubject: Subject<boolean> = new Subject<boolean>();

	/** Référence de la popup de maximisation du chart **/
	private bsModalRef: BsModalRef<ChartMaximizerComponent>;

	/** Graphique Apex **/
	@ViewChild('apxChart') apxChart: ApexChartComponent;

	/**
	 * Constructeur
	 */
	constructor(private chartService: ChartService,private changeDetectorRef: ChangeDetectorRef,private dashboardService: DashboardService,private viewContainerRef: ViewContainerRef,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.onChartResize = this.onChartResize.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Ecoute du redimensionnement des graphiques
		this.onChartResizeSubscription = this.dashboardService.onChartResize$.subscribe({
			next: ({ isResizing,isMenu }) => {
				//Vérification du redimensionnement
				if (isResizing) {
					//Masquage du chart
					this.isHidden = true;
				} else if (!isMenu)
					//Déclenchement du redimensionnement du graphique
					this.onChartResize();
			}
		});

		//Ecoute du chargement des données
		this.onInitSubscription = this.initDataSubject.asObservable().pipe(filter(isDataReady => !!isDataReady)).subscribe({
			next: () => {
				//Initialisation ou rechargement du graphique
				this.initChart();
			}
		});

		//Vérification du mode d'affichage
		if (this.options.isModal || this.options && this.data?.content?.length)
			//Initialisation des données
			this.initDataSubject.next(true);

		//Vérification de la présence d'une plage de sélection de données
		if (this.options.indexStart !== undefined && this.options.indexEnd !== undefined) {
			//Enregistrement de la méthode de changement de la sélection
			this.options.onIndexesChange = () => {
				//Modification du zoom
				this.apxChart?.zoomX(this.options.indexStart,this.options.indexEnd);
			}
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.onChartResizeSubscription?.unsubscribe?.();
		this.onInitSubscription?.unsubscribe?.();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Détection des changements
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la présence de données
		if (changes?.data?.currentValue?.content) {
			//Parcours des données
			this.data.content.forEach((d,index) => {
				let tipComponent: ComponentRef<ChartTipComponent>;
				let total: number;

				//Définition de l'index
				d.index = index;

				//Vérification de la présence d'un gestionnaire de tip
				if (this.options.tipComponent) {
					//Vérification de la présence d'une sous-liste de données
					if (d.listeDatas?.length) {
						//Récupération du total des données
						total = this.options.subType == SubTypeChart.EMPILE ? d.listeDatas.reduce((accumulator,d) => accumulator + d.value,0) : null;

						//Parcours des données
						d.listeDatas.forEach(child => {
							let t: ComponentRef<ChartTipComponent>;

							//Création du composant
							t = this.viewContainerRef.createComponent(this.options.tipComponent());

							//Définition des données
							t.instance.data = Object.assign(child,{ total });

							//Génération du contenu
							t.instance.generate(this.options,this.options.getLabel(d));

							//Récupération du contenu HTML du tip
							setTimeout(() => child.tip = t.instance.getContent());
						});
					} else {
						//Création du composant
						tipComponent = this.viewContainerRef.createComponent(this.options.tipComponent());

						//Définition des données
						tipComponent.instance.data = d;

						//Génération du contenu
						tipComponent.instance.generate(this.options);

						//Récupération du contenu HTML du tip
						setTimeout(() => d.tip = tipComponent.instance.getContent());
					}
				}
			});

			//Initialisation des données
			this.initDataSubject.next(true);

			//Vérification de la présence d'une popup
			if (this.bsModalRef) {
				//Mise à jour des données
				this.bsModalRef.content.options = { ...this.options,isModal: true };
				this.bsModalRef.content.data = this.data;
			}
		}
	}

	/**
	 * Initialisation du graphique
	 */
	private initChart() {
		//Vérification de la présence de données
		if (this.data?.content?.length) {
			//Vérification de la présence d'un graphique
			if (this.options.type != TypeChart.KPI) {
				//Génération des options de graphique externe
				this.chartOptions = this.chartService.generateOptions(this.options,this.data);
			} else {
				//Suppression des options du graphique externe
				this.chartOptions = null;
			}
		}
	}

	/**
	 * Interception du redimensionnement de la fenêtre
	 */
	@HostListener('window:resize',['$event'])
	onResize() {
		//Annulation du timeout
		clearTimeout(this.resizeTimeout);

		//Masquage du chart
		this.isHidden = true;

		//Mise en cycle avec annulation préalable
		this.resizeTimeout = setTimeout(this.onChartResize,1000);
	}

	/**
	 * Redimensionnement du chart
	 */
	private onChartResize() {
		//Affichage du chart
		this.isHidden = false;
	}

	/**
	 * Récupération de la liste des actions visibles
	 */
	getListeVisibleActions(): Array<ChartAction> {
		//Retour des actions
		return (this.options.getListeActions?.() || []).filter(a => !a.isVisible || a.isVisible());
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Maximisation du graphique
	 */
	maximizeChart() {
		//Affichage du chart agrandi
		this.bsModalRef = this.bsModalService.show(ChartMaximizerComponent,{
			initialState: {
				options: { ...this.options,isModal: true },
				data: this.data
			},
			class: 'modal-max'
		});
	}
}