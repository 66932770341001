import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { CompteAuxiliaireService } from './compte-auxiliaire.service';
import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';

@Component({
	selector: 'compte-auxiliaire-add',
	templateUrl: './compte-auxiliaire-add.component.html'
})
export class CompteAuxiliaireAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Compte auxiliaire **/
	public compteAuxiliaire: any = {};

	/** Filtres de l'autocomplete **/
	public filter: { idCompte: number,isCompteDisabled: boolean };

	/** Taille de la popup **/
	public static modalSize: string = 'modal-xs';

	/**
	 * Constructeur
	 */
	constructor(private compteAuxiliaireService: CompteAuxiliaireService,private compteService: CompteService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<CompteAuxiliaireAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification du compte
		if (this.filter.idCompte) {
			//Chargement du compte collectif
			this.compteService.loadCompte(this.filter.idCompte).subscribe(result => {
				//Vérification du résultat
				if (result?.data) {
					//Mise à jour du plan comptable
					this.compteAuxiliaire.planComptable = result.data.compte.planComptable;

					//Mise à jour du compte collectif
					this.compteAuxiliaire.compte = result.data.compte;
				}
			});
		}
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de l'élément
		this.compteAuxiliaireService.saveCompteAuxiliaire(this.compteAuxiliaire).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Définition du compte collectif
				this.addedItem = result.data.compteAuxiliaire;

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.numero')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
