import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { filter,take } from 'rxjs/operators';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TemplateMailListItemComponent } from './template-mail-list-item.component';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielEntiteList } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { User } from 'src/app/domain/user/user';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { AppState } from 'src/app/domain/appstate';
import { ChartService } from 'src/app/components/chart/chart.service';

@Component({
	templateUrl: './template-mail-list.component.html'
})
export class TemplateMailListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.template.TemplateMail';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/TemplateMail/filtreTemplateMails/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,TemplateMailListItemComponent>;

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(referentielService: ReferentielService,protected rightService: RightService,private translateService: TranslateService,private layoutService: LayoutService,private extractionService: ExtractionService,private store: Store<AppState>,private chartService: ChartService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),take(1)).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,TemplateMailListItemComponent>({
					uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
					title: this.translateService.instant('templateMail.liste.title'),
					component: TemplateMailListItemComponent,
					listeFilters: [{
						clef: 'libelle',
						isDefault: true
					},{
						clef: 'reference',
						isDefault: true
					},{
						clef: 'isActif',
						type: TypeFilter.BOOLEAN
					},{
						clef: 'isCustom',
						type: TypeFilter.BOOLEAN
					},{
						clef: 'entite',
						type: TypeFilter.STRING,
						listeValues: this.extractionService.getListeEntites()
					},(this.rightService.isRoot() || this.user.tenant?.type == 'REVENDEUR') && {
							clef: 'typeDiffusion',
							type: TypeFilter.STRING,
							listeValues: this.chartService.getListeTypesDiffusion()
					}].filter(i => !!i),
					listeSelectors: !this.rightService.isRoot() && [{
						code: TypeReferentiel.LOCAL,
						libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
						count: () => this.referentiel?.nbItemsForLocal || 0
					},{
						code: TypeReferentiel.STANDARD,
						libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
						count: () => this.referentiel?.nbItems || 0
					}],
					selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
					extraOptions: {
						referentiel: this.referentiel,
						user: this.user
					},
					hasMainAction: () => this.rightService.hasRight(null,'creation') && (this.liste.selectedSelector == TypeReferentiel.LOCAL || this.rightService.isRoot()),
					doMainAction:  () => this.layoutService.goToByUrl(`/TemplateMail/${this.liste.selectedSelector}/0`),
					onRefresh: (liste,result) => {
						//Vérification du type de liste
						if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
							//Mise à jour du nombre d'éléments standards
							this.referentiel.nbItems = result?.totalElements || 0;
						else
							//Mise à jour du nombre d'éléments locaux
							this.referentiel.nbItemsForLocal = result?.totalElements || 0;
					},
					getKeyFieldName: () => 'idTemplateMail'
				});

				//Ajout de l'action de suppression
				this.referentielService.addActionSuppressionToListe(this,'controller/TemplateMail/deleteAllTemplateMails');

				//Ajout de l'action d'import
				this.referentielService.addActionImportToListe(this);
			}
		});
	}
}
