import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeService } from './vehicule.service';

@Component({
	selector: 'vehicule-imputation-edit',
	templateUrl: './vehicule-imputation-edit.component.html'
})
export class VehiculeImputationEditComponent extends PageContentItem implements OnInit {
	/** Imputation courante **/
	@Input() imputation: any;

	/** Véhicule courant **/
	@Input() vehicule: any;

	/** Suppression de l'imputation **/
	@Input() deleteImputation: (imputation: any,close: Function) => void;

	/** Première imputation du véhicule **/
	public firstImputation: any;

	/** Etat de chargement **/
	public isLoaded = false;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la première imputation
		this.vehiculeService.getFirstImputation(this.imputation.vehicule).subscribe({
			next: (result: Result) => {
				//Définition de la première imputation
				this.firstImputation = result.data.firstImputation;
			},
			complete: () => {
				//Chargement terminé
				this.isLoaded = true;
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	saveImputation() {
		//Enregistrement de l'imputation
		this.vehiculeService.saveImputation(this.imputation).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.imputation,result.data?.imputation);

					//Fermeture de la fenêtre
					this.close({
						imputation: this.imputation,
						vehicule: result.data?.vehicule
					});
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la date d'application
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}