import { Component,Input,ViewContainerRef,ViewChild,OnInit,ComponentRef,Type,Output,EventEmitter } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	selector: 'list-view-item',
	templateUrl: './list-view-item.component.html'
})
export class ListViewItemComponent<T extends ListItem> implements OnInit {
	/** Elèment à afficher **/
	@Input() data: T;

	/** Liste **/
	@Input() liste: ListView<T,any>;

	/** Composant d'affichage **/
	@Input() component: ListViewItem<T>;

	/** Options supplémentaires **/
	@Input() extraOptions: any;

	/** Interception d'un changement d'affichage **/
	@Output() onDisplayChange: EventEmitter<T> = new EventEmitter<T>();

	/** Interception de la suppression **/
	@Output() onRemove: EventEmitter<T> = new EventEmitter<T>();

	/** Récepteur de composant **/
	@ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let component;

		//Création du composant
		component = this.createComponent(this.holder,<Type<T>><any>this.component);

		//Définition des données
		component.instance.liste = this.liste;
		component.instance.data = this.data;
		component.instance.extraOptions = this.extraOptions;

		//Ajout du composant au DOM
		this.holder.insert(component.hostView);

		//Interception d'un changement d'affichage
		this.data.onDisplayChange = (data: T) => this.onDisplayChange.emit(data);

		//Interception de la suppression
		this.data.onRemove = () => this.onRemove.emit(this.data);
	}

	/**
	 * Création du composant
	 */
	createComponent(viewContainer: ViewContainerRef,type: Type<T>): ComponentRef<T> {
		//Création du composant
		return viewContainer.createComponent(type);
	}
}