<form #amenagementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!amenagement.isDisplayed">
		<div class="tile-sub">
			<amenagement-content #amenagementContent [amenagement]="amenagement" source="AMENAGEMENT" (close)="onObjectChange.emit($event)"></amenagement-content>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: amenagementForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="amenagementContent.deleteAmenagement();" *ngIf="amenagement.idAmenagement && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="amenagementContent.saveAmenagement();" *ngIf="(null | right:'creation')" [disabled]="amenagementForm.invalid || !amenagement.vehicule">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>