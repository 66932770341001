<div class="modal-header">
	<h4 class="modal-title" translate>mailHistory.title</h4>
</div>
<div class="modal-body">
	<div class="form-group" *ngIf="data.nomUser || data.prenomUser">
		<label><span translate>mailHistory.liste.expediteur</span></label>
		<div>
			<span>
				<span *ngIf="data.nomOriginalUser"><span>{{ data.prenomOriginalUser }}</span>&#160;<span>{{ data.nomOriginalUser }}</span>&#160;(<span>{{ data.tenantOriginalUser }}</span>)&#160;<span translate>data.liste.pour</span></span>
				<span>{{ data.nomUser }}</span>&#160;<span>{{ data.prenomUser }}</span><span *ngIf="data.matriculeUser">&#160;(<span>{{ data.matriculeUser }}</span><span *ngIf="data.societeUser">&#160;-&#160;<span>{{ data.societeUser }}</span></span>)</span>
			</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.libelle</span></label>
		<div>
			<span>{{ data.libelle }}</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.objet</span></label>
		<div>
			<span>{{ data.objet }}</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.destinatairesPrincipaux</span></label>
		<div>
			<mat-chip-listbox>
				<mat-chip *ngFor="let destinataire of listeDestinatairesPrincipaux | slice: 0:isShowMoreDestinataires ? listeDestinatairesPrincipaux.length:limitDestinataires">{{ destinataire.email }}</mat-chip>
			</mat-chip-listbox>
			<span *ngIf="!data.nbDestinatairesPrincipaux"><span translate>common.aucun</span></span>
			<a [routerLink]="[]" *ngIf="listeDestinatairesPrincipaux.length > limitDestinataires" class="m-l-5" (click)="isShowMoreDestinataires = !isShowMoreDestinataires">
				<span *ngIf="!isShowMoreDestinataires"><span translate [translateParams]="{ nb: listeDestinatairesPrincipaux.length - limitDestinataires }">mailHistory.showDestinataires</span></span>
				<span *ngIf="isShowMoreDestinataires"><span translate>mailHistory.hideDestinataires</span></span>
			</a>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.destinatairesCopie</span></label>
		<div>
			<mat-chip-listbox>
				<mat-chip *ngFor="let destinataire of listeDestinatairesCopie | slice: 0:isShowMoreCopies ? listeDestinatairesCopie.length:limitDestinataires">{{ destinataire.email }}</mat-chip>
			</mat-chip-listbox>
			<span *ngIf="!data.nbDestinatairesCopie"><span translate>common.aucun</span></span>
			<a [routerLink]="[]" *ngIf="listeDestinatairesCopie.length > limitDestinataires" class="m-l-5" (click)="isShowMoreCopies = !isShowMoreCopies">
				<span *ngIf="!isShowMoreCopies"><span translate [translateParams]="{ nb: listeDestinatairesCopie.length - limitDestinataires }">mailHistory.showDestinataires</span></span>
				<span *ngIf="isShowMoreCopies"><span translate>mailHistory.hideDestinataires</span></span>
			</a>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.piecesJointes</span></label>
		<div>
			<mat-chip-listbox>
				<mat-chip *ngFor="let attachment of data.listeAttachments">{{ attachment.name }}</mat-chip>
			</mat-chip-listbox>
			<span *ngIf="!data.listeAttachments || data.listeAttachments.length == 0"><span translate>common.aucun</span></span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>mailHistory.contenu</span></label>
		<div *ngIf="contenu">
			<div class="media">
				<iframe class="w-100" #iframe frameborder="0" [srcdoc]="contenu" (load)="onLoad(iframe)" scrolling="no"></iframe>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>