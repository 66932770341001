import { CurrencyPipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { ConfigurationService } from 'src/app/components/vehicule/modele/configuration/configuration.service';
import { ModeleDetailsComponent } from 'src/app/components/vehicule/modele/modele-details.component';
import { AppState } from 'src/app/domain/appstate';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionModeleCotationListComponent } from './grille-attribution-modele-cotation-list.component';
import { GrilleAttributionModeleFinancementEditComponent } from './grille-attribution-modele-financement-edit.component';
import { GrilleAttributionModeleFinancementListComponent } from './grille-attribution-modele-financement-list.component';
import { GrilleAttributionModeleRemiseEditComponent } from './grille-attribution-modele-remise-edit.component';
import { GrilleAttributionModeleRemiseListComponent } from './grille-attribution-modele-remise-list.component';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@Component({
	selector: 'grille-attribution-modele',
	templateUrl: './grille-attribution-modele.component.html'
})
export class GrilleAttributionModeleComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Utilisateur courant **/
	user: User;

	/** Modèle de la grille d'attribution courant **/
	public grilleAttributionModele: any;

	/** Résumé **/
	private resume: { nbCotations: number,nbFinancements: number,nbRemises: number } = {
		nbCotations: 0,
		nbFinancements: 0,
		nbRemises: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionModeleService: GrilleAttributionModeleService,private pageContentService: PageContentService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private bsModalService: BsModalService
			,private currencyPipe: CurrencyPipe,private configurationService: ConfigurationService,private store: Store<AppState>,private demandeCotationService: DemandeCotationService,private cotationService: CotationService,private toastrService: ToastrService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.LienGrilleAttributionModele';

	/**
	 * Récupération de l'identifiant du modèle de la grille d'attribution à charger
	 */
	getIdObject: () => number = () => this.grilleAttributionModele?.idGrilleAttribution || 0;

	/**
	 * Récupération de l'identifiant du modèle de la grille d'attribution à charger
	 */
	getIdSecondary: () => number = () => this.grilleAttributionModele?.idModele || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.grilleAttribution.modele.modele',
				key: 'modele',
				type: 'DISPLAY',
				format: 'modele'
			},{
				libelle: 'vehicule.grilleAttribution.modele.finition',
				key: 'modele.finition'
			},{
				libelle: 'vehicule.grilleAttribution.modele.grilleAttribution',
				key: 'grilleAttribution.libelle'
			}],
			listeActions: [],
			listeAlertes: [{
				icon: 'directions_car',
				title: () => this.translateService.instant('vehicule.grilleAttribution.modele.alerte.configuration.title',{ prix: `${this.currencyPipe.transform(this.computeMontantForModele(),this.grilleAttributionModele?.modele?.information?.commercialisationPrix?.devise)} ${this.grilleAttributionModele?.modele?.information?.genre?.modeAffichagePrix}` }),
				message: this.translateService.instant('vehicule.grilleAttribution.modele.alerte.configuration.message'),
				isVisible: () => this.grilleAttributionModele?.modele?.configuration && this.rightService.hasRight(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,'consultation') && !this.pageContentService.isOpened(),
				doAction: () => this.showModeleConstructeur(),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,'consultation')
			},{
				icon: 'attach_money',
				title: this.translateService.instant('vehicule.grilleAttribution.modele.alerte.cotation.title'),
				message: this.translateService.instant('vehicule.grilleAttribution.modele.alerte.cotation.message'),
				isVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'consultation') && this.resume?.nbCotations == 0 && !this.pageContentService.isOpened(),
				doAction: () => this.addCotation(),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'creation')
			},{
				icon: 'attach_money',
				title: this.translateService.instant('vehicule.grilleAttribution.modele.alerte.financement.title'),
				message: this.translateService.instant('vehicule.grilleAttribution.modele.alerte.financement.message'),
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'consultation') && this.resume?.nbFinancements == 0 && !this.pageContentService.isOpened(),
				doAction: () => this.addFinancement(),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'creation')
			}],
			listeElements: [{
				type: 'COTATION',
				libelle: 'vehicule.grilleAttribution.modele.elements.cotation',
				component: GrilleAttributionModeleCotationListComponent,
				retrieveComponentData: () => ({
					grilleAttributionModele: this.grilleAttributionModele,
					resume: this.resume
				}),
				doAction: () => this.addCotation(),
				count: () => this.resume.nbCotations,
				libelleAction: this.translateService.instant('vehicule.grilleAttribution.modele.elements.cotation.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'consultation')
			},{
				type: 'FINANCEMENT',
				libelle: 'vehicule.grilleAttribution.modele.elements.financement',
				component: GrilleAttributionModeleFinancementListComponent,
				retrieveComponentData: () => ({
					grilleAttributionModele: this.grilleAttributionModele,
					resume: this.resume,
					addFinancement: this.addFinancement.bind(this)
				}),
				doAction: () => this.addFinancement(),
				count: () => this.resume.nbFinancements,
				libelleAction: this.translateService.instant('vehicule.grilleAttribution.modele.elements.financement.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'consultation')
			},{
				type: 'REMISE',
				libelle: 'vehicule.grilleAttribution.modele.elements.remise',
				component: GrilleAttributionModeleRemiseListComponent,
				retrieveComponentData: () => ({
					grilleAttributionModele: this.grilleAttributionModele,
					resume: this.resume,
					addRemise: this.addRemise.bind(this)
				}),
				doAction: () => this.addRemise(),
				count: () => this.resume.nbRemises,
				libelleAction: this.translateService.instant('vehicule.grilleAttribution.modele.elements.remise.ajouter'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,'creation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,'consultation')
			}]
		};

		//Récupération de l'identifiant du modèle de la grille d'attribution à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idGrilleAttribution,params.idModele);
			}
		});

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idGrilleAttribution: number,idModele: number) {
		//Enregistrement du modèle de la grille d'attribution
		this.grilleAttributionModeleService.loadGrilleAttributionModele(idGrilleAttribution,idModele).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du modèle de la grille d'attribution
					this.grilleAttributionModele = result.data?.lienGrilleAttributionModele;

					//Définition du résumé
					this.resume = {
						nbCotations: result.data?.nbCotations || 0,
						nbFinancements: result.data?.nbFinancements || 0,
						nbRemises: result.data?.nbRemises || 0
					}
				}
			}
		});
	}

	/**
	 * Calcul du montant de la configuration pour le modèle
	 */
	computeMontantForModele(modele: any = this.grilleAttributionModele?.modele) {
		//Retour du prix formatté
		return modele?.information?.commercialisationPrix ? (modele?.information?.genre?.modeAffichagePrix == 'HT' ? (modele?.configuration?.prixHTRemise ? modele?.configuration?.prixHTRemise : modele?.configuration?.prixHT) : (modele?.configuration?.prixTTCRemise ? modele?.configuration?.prixTTCRemise : modele?.configuration?.prixTTC)) : '';
	}

	/**
	 * Ouverture de la popup du modèle constructeur
	 */
	public showModeleConstructeur() {
		let bsModalRef: BsModalRef<ModeleDetailsComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ModeleDetailsComponent,{
			initialState: {
				modele: cloneDeep(this.grilleAttributionModele?.modele),
				options: {
					canChange: false,
					canSelect: false,
					canConfigure: true
				}
			},
			class: 'modal-max'
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result),
			filter(result => !!result)
		).subscribe({
			next: ({ action }: { action: 'CHANGE_MODELE' | 'CONFIGURE_MODELE',modele: any }) => {
				//Configuration du modèle
				if (action == 'CONFIGURE_MODELE') {
					//Ouverture de la pop-up de configuration
					this.configurationService.showConfiguration({
						type: 'GRILLE_ATTRIBUTION',
						idEntite: this.grilleAttributionModele?.idGrilleAttribution,
						idModele: this.grilleAttributionModele?.idModele,
						isEdition: true
					}).subscribe({
						next: (configuration: any) => {
							//Mise à jour de la configuration
							Object.assign(this.grilleAttributionModele.modele.configuration,configuration);
						}
					});
				}
			}
		});
	}

	/**
	 * Modification du financement
	 */
	protected addFinancement(financement: any = { grilleAttribution: Object.assign({},this.grilleAttributionModele.grilleAttribution),modele: Object.assign({},this.grilleAttributionModele.modele),devise: this.user.devise,typeAffichagePrix: 'TTC' },deleteFinancement?: Function) {
		let addFinancement: Function;

		//Méthode d'édition du financement
		addFinancement = () => {
			//Ouverture du composant d'édition
			this.pageContentService.open(GrilleAttributionModeleFinancementEditComponent,{
				data: {
					financement: cloneDeep(financement),
					deleteFinancement
				}
			},'sub').subscribe({
				next: (data: { financement: any }) => {
					//Vérification de la présence de données
					if (data) {
						//Affichage de l'onglet (si nécessaire)
						this.complexPage.setSelectedElementByType('FINANCEMENT',financement.idFinancement != data.financement?.idFinancement);

						//Vérification de la présence d'une création ou d'une suppression
						if (!financement.idFinancement && data.financement?.idFinancement)
							//Incrémentation du compteur
							this.resume.nbFinancements++;
						else if (financement.idFinancement && !data.financement?.idFinancement)
							//Décrémentation du compteur
							this.resume.nbFinancements--;

						//Mise à jour du financement
						Object.assign(financement,data.financement);
					}
				}
			});
		}

		//Vérification de la présence du financement
		if (financement?.idFinancement) {
			//Chargement complet du financement
			this.grilleAttributionModeleService.loadFinancement(financement.idFinancement).subscribe({
				next: (result: Result) => {
					//Vérification du chargement
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Définition du financement
						Object.assign(financement,result.data?.financement);

						//Définition de la grille d'attribution
						financement.grilleFluidite = result.data?.grilleFluidite;

						//Définition du type de financement LLD
						financement.typeFinancementLLD = result.data?.typeFinancementLLD;

						//Ouverture du formulaire d'édition
						addFinancement();
					}
				}
			})
		} else
			//Ouverture du formulaire d'édition
			addFinancement();
	}

	/**
	 * Modification d'une remise
	 */
	protected addRemise(remise: any = { grilleAttribution: Object.assign({},this.grilleAttributionModele.grilleAttribution),modele: Object.assign({},this.grilleAttributionModele.modele) },deleteRemise?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(GrilleAttributionModeleRemiseEditComponent,{
			data: {
				remise: cloneDeep(remise),
				deleteRemise
			}
		},'sub').subscribe({
			next: (data: { remise: any,configuration:any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('REMISE',remise.idRemise != data.remise?.idRemise);

					//Vérification de la présence d'une création ou d'une suppression
					if (!remise.idRemise && data.remise?.idRemise)
						//Incrémentation du compteur
						this.resume.nbRemises++;
					else if (remise.idRemise && !data.remise?.idRemise)
						//Décrémentation du compteur
						this.resume.nbRemises--;

					//Mise à jour de la remise
					Object.assign(remise,data.remise);

					//Vérification de la présence d'une configuration
					if (data.configuration)
						//Mise à jour de la configuration
						Object.assign(this.grilleAttributionModele.modele.configuration,data.configuration);
				}
			}
		});
	}

	/**
	 * Affichage de la cotation
	 */
	protected addCotation(cotation: any = { modele: this.grilleAttributionModele.modele,demandeCotation: null,lienGrilleAttributionModele: this.grilleAttributionModele }) {
		//Enregistrement de la demande de cotation
		this.demandeCotationService.saveDemandeCotation({ origine: 'GRILLE_ATTRIBUTION' }).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Definition de la demande de cotation
					cotation.demandeCotation = result.data.demandeCotation;

					//Enregistrement de la cotation
					this.cotationService.saveCotation(cotation).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Definition de la cotation
								Object.assign(cotation,result.data?.cotation)

								//Navigation vers la cotation
								this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesCotation-detail',{
									routeParams: {
										idDemandeCotation: cotation.demandeCotation.idDemandeCotation
									},
									routeData: {
										idCotation: cotation.idCotation,
										origine: 'GRILLE_ATTRIBUTION'
									},
									reloadOnSameUrl: true,
									withGoBack: true
								});
							} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						}
					})
				} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		})
	}
}