
import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'profil-dasboard',
	templateUrl: './profil-dashboard-list.component.html'
})
export class ProfilDashboardListComponent extends PageContentItem {
	/** Elément courant **/
	@Input() profil: any;

	/** Liste des dashboards **/
	@Input() listeDashboards: Array<{ type: string,liste: Array<any> }>;

	/** Fonction d'édition **/
	@Input() editProfilDashboard: (dashboard: any) => void;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Passage en mode édition
	 */
	onEdit(dashboard: any) {
		//Affichage du composant d'édition du dashboard
		this.editProfilDashboard(dashboard);
	}
}