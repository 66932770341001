import { Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { ContratFinancementService } from 'src/app/components/vehicule/contrat/contrat-financement.service';
import { User } from 'src/app/domain/user/user';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	selector: 'reponse-cotation-reponse-content',
	templateUrl: './reponse-cotation-reponse-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ReponseCotationReponseContentComponent extends PageContentItem implements OnInit {
	/** Réponse à la cotation **/
	@Input() reponseCotation: any;

	/** Source **/
	@Input() source: string;

	/** Indicateur d'ouverture depuis une demande de cotation **/
	@Input() isFromDemandeCotation: boolean;

	/** Indicateur de lecture seule **/
	@Input() isReadOnly: boolean;

	/** Utilisateur connecté **/
	user: User;

	/** Configuration **/
	public configuration: any;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Liste des modes d'affichage de prix **/
	public listeTypesAffichagePrix: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private modeleService: ModeleService,private contratFinancementService: ContratFinancementService,private loggedUserService: LoggedUserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Mise à jour de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Définition de la liste des modes d'affichage de prix
		this.listeTypesAffichagePrix = this.contratFinancementService.getListeTypesAffichagePrix();

		//Vérification de la source
		if (this.source == 'FOURNISSEUR') {
			//Vérification du statut de la réponse aux cotations
			if (this.reponseCotation.statut == 'A_TRANSMETTRE' || this.reponseCotation.dateReponse && this.reponseCotation.cotation.statut == 'SOLDEE') {
				//Fermeture du rappel
				this.reponseCotation.isCotationToggled = true;

				//Ouverture du bloc de réponse
				this.reponseCotation.isReponseToggled = false;
			} else if (this.reponseCotation.statut != 'A_TRANSMETTRE' && this.reponseCotation.statut != 'A_COMPLETER') {
				//Fermeture du bloc de réponse
				this.reponseCotation.isReponseToggled = true;
			} else if (this.reponseCotation.statut == 'A_COMPLETER') {
				//Définition du modèle
				this.reponseCotation.modele = this.reponseCotation.cotation.modele;
			}
		} else {
			//Vérification du statut de la réponse aux cotations
			if (this.reponseCotation.statut == 'A_TRANSMETTRE' || this.reponseCotation.dateReponse && this.reponseCotation.cotation.statut == 'SOLDEE')
				//Fermeture du rappel
				this.reponseCotation.isCotationToggled = true;

			//Ouverture du bloc de réponse
			this.reponseCotation.isReponseToggled = false;
		}
	}

	/**
	 * Affichage du modèle
	 */
	showModele(modele: any) {
		//Affichage des informations détaillées du modèle
		this.modeleService.showModeleInformations(modele,{
			canSelect: false
		});
	}
}