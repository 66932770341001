import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './vehicule-information-tarifaire.component.html'
})
export class VehiculeInformationTarifaireComponent implements AfterContentChecked {
	/** Informations tarifaires **/
	@Input() informationTarifaire: any;

	/** Informations tarifaires du modèle constructeur **/
	@Input() informationTarifaireConstructeur: any;

	/** Résultat **/
	result: { informationTarifaire: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<VehiculeInformationTarifaireComponent>) {

	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement des informations tarifaires
	 */
	public saveInformationTarifaire() {
		//Vérification de la sélection d'une devise
		if (this.informationTarifaire?.devise?.code)
			//Récupération du code de la devise
			this.informationTarifaire.devise = this.informationTarifaire.devise.code;

		//Définition du résultat
		this.result = {
			informationTarifaire: this.informationTarifaire
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}