<header *ngIf="session?.isLogged" [ngClass]="{ 'connect-as': session?.userOrigine != null }">
	<mat-toolbar>
		<div class="left-container">
			<button mat-icon-button (click)="menuService.toggleMenu();" *ngIf="session?.user?.type != 'VIRTUAL'">
				<mat-icon>menu</mat-icon>
			</button>
			<a [routerLink]="[]" class="logo" (click)="goToHome()" [ngStyle]="{ 'background-image': 'url(controller/Theme/loadLogo/false/'+session?.user?.tenant?.code+')' }"></a>
		</div>
		<div class="middle-container" *ngIf="session?.user?.type != 'VIRTUAL'">
			<global-search (onClose)="onGlobalSearchClosed()"></global-search>
		</div>
		<div class="right-container">
			<ng-container *ngIf="session?.user?.type != 'VIRTUAL'">
				<button mat-icon-button [matMenuTriggerFor]="progressMenu" *ngIf="progressService.isProgressVisible()">
					<mat-icon>file_download</mat-icon>
				</button>
				<mat-menu #progressMenu="matMenu">
					<header-progress></header-progress>
				</mat-menu>
				<button mat-icon-button (click)="todoListService.toggleDisplay();">
					<mat-icon>format_list_bulleted</mat-icon>
				</button>
			</ng-container>
			<button mat-icon-button [matMenuTriggerFor]="accountMenu">
				<mat-icon>person</mat-icon>
			</button>
			<mat-menu #accountMenu="matMenu">
				<header-account></header-account>
			</mat-menu>
		</div>
	</mat-toolbar>
</header>