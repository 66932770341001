<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [type]="statut.iconeType" [name]="statut.icone"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/VehiculeCommande/loadDemandeVehicule/',data.idDemandeVehicule]">
			<span>{{ ('demandeVehicule.liste.item' | translate:{ numero: data.idDemandeVehicule } ) + ' - ' }}</span>
			<span *ngIf="data.user">{{ data.user | display:'user' }}</span>
			<span *ngIf="!data.user && data.service">{{ data.service.libelle + ' ('+ data.societe?.libelle +')' }}</span>
			<span *ngIf="!data.user && !data.service && data.societe">{{ data.societe.libelle }}</span>
			<span *ngIf="!data.user && !data.service && !data.societe">{{ 'demandeVehicule.beneficiaire.aucun' | translate }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idDemandeVehicule">
		<li *ngIf="data.statut"><span translate>demandeVehicule.statut.item</span>&#160;:&#160;<span [translate]="'demandeVehicule.statut.' + data.statut"></span></li>
		<li *ngIf="data.dateCreation"><span translate>demandeVehicule.dateCreation</span>&#160;:&#160;<span>{{ data.dateCreation | date:'shortDate' }}</span></li>
		<li *ngIf="data.vehiculeSource"><span translate>demandeVehicule.vehiculeSource</span>&#160;:&#160;<span>{{ data.vehiculeSource | display:'vehicule' }}</span></li>
		<li *ngIf="data.vehicule && ['EN_COMMANDE','LIVRE','ANNULEE'].indexOf(data.statut) != -1"><span translate>demandeVehicule.vehicule.item</span>&#160;:&#160;<span>{{ data.vehicule | display:'vehicule' }}</span></li>
		<li *ngIf="data.referenceCommande && ['EN_COMMANDE','LIVRE','ANNULEE'].indexOf(data.statut) != -1"><span translate>demandeVehicule.referenceCommande.short</span>&#160;:&#160;<span>{{ data.referenceCommande }}</span></li>
		<li *ngIf="!!modele?.short || !!modele.full" [tooltip]="!!modele.short ? modele.full : ''"><span translate>demandeVehicule.modele.libelle</span>&#160;:&#160;<span>{{ !!modele.short ?  modele.short : modele.full }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idDemandeVehicule && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification()" translate>actions.notifier</a></li>
			<li *ngIf="(null | right:'creation') && isWorkflowAvailable && data.statut != 'VALIDEE' && isActionValid('TRANSMETTRE') && (!isGrilleAttributionActiveAvailable || data.modele)"><a [routerLink]="[]" (click)="doAction('TRANSMETTRE')" translate>actions.transmettre</a></li>
			<li *ngIf="(null | right:'creation') && (isWorkflowAvailable ? data.statut == 'VALIDEE' : !isGrilleAttributionActiveAvailable || data.modele) && demandeVehiculeService.isActionValid(data,'COMMANDER')"><a [routerLink]="[]" (click)="doAction('COMMANDER')" translate>actions.commander</a></li>
			<li *ngIf="(null | right:'creation') && isActionValid('RAPPELER')"><a [routerLink]="[]" (click)="doAction('RAPPELER')" translate>actions.rappeler</a></li>
			<li *ngIf="(null | right:'suppression') && isActionValid('ANNULER')"><a [routerLink]="[]" (click)="doAction('ANNULER')" translate>actions.annuler</a></li>
			<li *ngIf="(null | right:'suppression') && isActionValid('SUPPRIMER')"><a [routerLink]="[]" (click)="deleteDemandeVehicule();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="isWorkflowAvailable"><a [routerLink]="[]" (click)="showWorkflowHistory()" translate>actions.workflow.consulter</a></li>
		</ul>
	</div>
</div>