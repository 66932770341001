import { Component,Input } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './history-list-item.component.html'
})
export class HistoryListItemComponent extends ListViewItem<any> {
	/** Elément courant **/
	@Input() data: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}