import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { EquipementAffectationHistoryListItemComponent } from './equipement-affectation-history-list-item.component';

@Component({
	templateUrl: './equipement-affectation-history-list.component.html'
})
export class EquipementAffectationHistoryListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,EquipementAffectationHistoryListItemComponent>;

	/** Equipement **/
	equipement: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<EquipementAffectationHistoryListItemComponent>,public translateService: TranslateService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,EquipementAffectationHistoryListItemComponent>({
			uri: '/controller/Equipement/filtreAffectations',
			component: EquipementAffectationHistoryListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'vehicule',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule'
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'equipement.idEquipement',
				valeur: this.equipement?.idEquipement,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			}],
			defaultOrder: '-dateDebut'
		});
	}
}