import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TypeInfractionListItemComponent } from './type-infraction-list-item.component';
import { TypeInfractionListComponent } from './type-infraction-list.component';
import { TypeInfractionComponent } from './type-infraction.component';
import { TypeInfractionService } from './type-infraction.service';

@NgModule({
  imports: [ShareModule],
  declarations: [TypeInfractionListComponent,TypeInfractionListItemComponent,TypeInfractionComponent],
	providers: [TypeInfractionService]
})
export class TypeInfractionModule { }
