import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RuleService } from 'src/app/share/components/rule/rule.service';

@Component({
	selector: 'workflow-detail-rule-definition',
	templateUrl: './workflow-detail-rule-definition.component.html'
})
export class WorkflowDetailRuleDefinitionComponent implements AfterContentChecked {
	/** Règle **/
	@Input() rule: any;

	/** Libellé **/
	@Input() libelle: string = '';

	/** Entité **/
	@Input() entite: string;

	/** Edition **/
	@Input() isEdition: boolean;

	/** Résultat **/
	result: { rule: any,libelle: string };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<WorkflowDetailRuleDefinitionComponent>,public ruleService: RuleService) {}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la règle
	 */
	public saveWorkflowDetailRegleDefinition() {
		//Définition du résultat
		this.result = {
			rule: this.ruleService.updateRuleForSave(this.rule),
			libelle: this.libelle
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}