import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des règles de taxe
 */
export default class RegleTaxeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.regleTaxe';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Fiscalite/filtreReglesTaxes';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item?.libelle || ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.fiscalite.RegleTaxe';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idRegle'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idPays && {
			clef: 'pays.idPays',
			valeur: filter.idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'fiscalite.regleTaxe.type.item',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`fiscalite.regleTaxe.type.${item.type}`)
		}];
	}
}