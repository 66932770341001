import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { WorkflowService } from './workflow.service';

@Component({
	selector: 'workflow-list-item',
	templateUrl: './workflow-list-item.component.html'
})
export class WorkflowListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService
			,private workflowService: WorkflowService,public entiteService: EntiteService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression du workflow
	 */
	deleteWorkflow() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du workflow
					this.workflowService.deleteWorkflow(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.workflow.Workflow',this.data.idWorkflow);
	}

	/**
	 * Duplication d'un workflow
	 */
	duplicateWorkflow() {
		//Duplication du workflow
		this.workflowService.duplicateWorkflow(this.data).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Redirection vers le workflow
					this.layoutService.goToByState('listeWorkflows-workflow',{
						routeParams: {
							idWorkflow: result.data.workflow.idWorkflow
						},
						withGoBack: true
					});
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
