import { Component,Input,OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { StructureExportService } from './structure-export.service';
import { LocaleService } from '../locale/locale.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	selector: 'structure-export-edit',
	templateUrl: './structure-export-edit.component.html'
})
export class StructureExportEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() structureExport: any;

	/** Identifiant de la structure à dupliquer **/
	@Input() idStructureSource: number;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Suppression de l'objet **/
	@Input() deleteStructureExport: () => void;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted: boolean;

	/** Liste des séparateurs de décimaux **/
	public listeSeparateursDecimale: Array<{ code: string,libelle: string }>;

	/** Liste des types de cadrage **/
	public listeTypesCadrage: Array<{ code: string,libelle: string }>;

	/** Liste des types de complétion **/
	public listeTypesCompletion: Array<{ code: string,libelle: string }>;

	/** Liste des formats de date **/
	public listeFormatsDate: Array<{ code: string,libelle: string }>;

	/** Copie du nombre de fichiers **/
	private nbFichiers: number = 0;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private structureExportService: StructureExportService,private localeService: LocaleService,private layoutService: LayoutService
			,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private messagingService: MessagingService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de la liste des séparateurs de décimaux
		this.listeSeparateursDecimale = this.localeService.getListeSeparateursDecimale();

		//Liste des types de cadrage
		this.listeTypesCadrage = this.structureExportService.getListeTypesCadrage();

		//Liste des types de complétion
		this.listeTypesCompletion = this.structureExportService.getListeTypesCompletion();

		//Liste des formats de date
		this.listeFormatsDate = this.localeService.getListeFormatsDateCourte();

		//Copie du nombre de fichiers
		this.nbFichiers = this.structureExport?.nbFichiers || 0;
	}

	/**
	 * Détection de modifications sur le type de cadrage
	 */
	onTypeCadrageChange() {
		//Vérification du type de cadrage
		if (this.structureExport.formatNumerique.typeCadrage == 'AUCUN') {
			//Réinitialisation du type de complétion et de la longueur
			this.structureExport.formatNumerique.typeCompletion = null;
			this.structureExport.formatNumerique.longueur = null;
		}
	}

	/**
	 * Sauvegarde de la structure d'export
	 */
	saveStructureExport() {
		//Vérification du mode duplication
		if (!this.idStructureSource) {
			//Vérification du changement de nombre de fichiers
			if (this.nbFichiers > 0 && this.structureExport.nbFichiers < this.nbFichiers) {
				//Affichage d'un message de confirmation
				this.confirmService.showConfirm(this.translateService.instant('structureExport.confirmation.changementNbFichiers'),{ actionColor: 'primary' }).subscribe({
					next: isConfirmed => {
						//Vérification de la confirmation
						if (isConfirmed) {
							//Enregistrement de la structure d'export
							this.doSaveStructureExport();
						} else {
							//Rétablissement du nombre de fichiers
							this.structureExport.nbFichiers = this.nbFichiers;

							//Enregistrement de la structure d'export
							this.doSaveStructureExport();
						}
					}
				});
			} else
				//Lancement de l'enregistrement
				this.doSaveStructureExport();
		} else {
			//Démarrage de la duplication
			this.duplicateStructureExport();
		}
	}

	/**
	 * Enregistrement de l'objet
	 */
	doSaveStructureExport() {
		//Enregistrement de l'objet
		this.structureExportService.saveStructureExport(this.typeReferentiel,this.structureExport).subscribe({
			next: (result: Result) => {
				let listeDoublons: Array<string> = [];

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.structureExport,result.data?.structureExport);

					//Fermeture du formulaire
					this.close(this.structureExport);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,1)) {
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));
						listeDoublons.push(this.translateService.instant('actions.doublon.societe'));
					}

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Lancement de la duplication de la structure d'export
	 */
	duplicateStructureExport() {
		let messaging$: MessagingObservables;

		//Initialisation de la WebSocket
		messaging$ = this.messagingService.init({
			entryPoint: 'controller/StructureExport/duplicateStructureExport/'+this.idStructureSource,
			params:  this.structureExport
		}).onFinish({
			next: (message: any) => {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers la structure
				this.layoutService.goToByState('comptabiliteReferentiel-listeStructuresExport-loadStructureExport',{
					routeParams: {
						idStructureExport: message.reference,
						typeReferentiel: this.typeReferentiel
					},
					reloadOnSameUrl: true
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		})
	}

	/**
	 * Vérification de la présence d'erreurs sur la liste des sociétés
	 */
	isListeSocietesInvalid: () => boolean = () => !this.structureExport.listeSocietes?.length;
}