import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Step } from 'src/app/domain/common/stepper/step';
import { TypeDroit } from 'src/app/domain/security/right';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeVehiculeModeleSelectionGrilleAttributionComponent } from './demande-vehicule-modele-selection-grille-attribution.component';
import { DemandeVehiculeModeleSelectionModeleComponent } from './demande-vehicule-modele-selection-modele.component';
import { DemandeVehiculeService } from './demande-vehicule.service';

@Component({
	selector: 'demande-vehicule-modele-selection',
	templateUrl: './demande-vehicule-modele-selection.component.html'
})
export class DemandeVehiculeModeleSelectionComponent implements AfterContentChecked,OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Grille d'attribution spécifique **/
	@Input() grilleAttributionSpecifique: any;

	/** Résultat **/
	result: {
		grilleAttribution: any,
		modele: any
	};

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Grille d'attribution selectionnée **/
	public selectedGrilleAttribution: any;

	/** Indicateur de disponibilité de grille d'attribution pour le bénéficiaire */
	public isGrilleAvailableForBeneficiaire: any = true;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DemandeVehiculeModeleSelectionComponent>,private layoutService: LayoutService,private demandeVehiculeService: DemandeVehiculeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'GRILLE_ATTRIBUTION',
			libelle: 'demandeVehicule.selection.modele.grilleAttribution.item',
			component: DemandeVehiculeModeleSelectionGrilleAttributionComponent,
			retrieveComponentData: () => ({
				className: this.demandeVehicule.user ? 'com.notilus.data.user.User' : 'com.notilus.data.service.Service',
				idObject: this.demandeVehicule?.user?.idUser || this.demandeVehicule?.service?.idService || this.demandeVehicule?.societe?.idService,
				selectedGrilleAttribution: this.selectedGrilleAttribution,
				bsModalRef: this.bsModalRef,
				grilleAttributionSpecifique: this.grilleAttributionSpecifique,
				onSearchRefresh: isGrilleAvailableForBeneficiaire => {
					//Mise à jour de l'indicateur
					this.isGrilleAvailableForBeneficiaire = isGrilleAvailableForBeneficiaire;
				},
				onGrilleAttributionSelected: grilleAttribution => {
					//Définition de la grille d'attribution selectionnée
					this.selectedGrilleAttribution = grilleAttribution;

					//Navigation vers la sélection d'un modèle
					this.selectedGrilleAttribution && this.stepperComponent.selectStep(this.listeSteps[1]);
				}
			}),
			canActivate: () => true,
			isDone: () => this.selectedGrilleAttribution
		},{
			type: 'MODELE',
			libelle: 'demandeVehicule.selection.modele.modele.item',
			component: DemandeVehiculeModeleSelectionModeleComponent,
			retrieveComponentData: () => ({
				selectedGrilleAttribution: this.selectedGrilleAttribution,
				onModeleSelected: modele => {
					//Définition du modèle sélectionné
					this.result = {
						modele,
						grilleAttribution: this.selectedGrilleAttribution
					};

					//Fermeture de la fenêtre
					this.bsModalRef.hide();
				}
			}),
			canActivate: () => this.selectedGrilleAttribution,
			isDone: () => this.result?.modele,
		}];
	}


	/**
	 * Enregistrement d'une grille d'attribution spécifique
	 */
	saveGrilleAttributionSpecifique() {
		//Enregistrement de la grille d'attribution spécifique
		this.demandeVehiculeService.saveGrilleAttributionSpecifique(this.demandeVehicule).subscribe({
			next: (grilleAttribution: any) => {
				//Femeture de la pop-up
				this.bsModalRef.hide();

				//Navigation vers la grille d'attribution spécifique
				this.goToGrilleAttribution(grilleAttribution);
			}
		})
	}

	/**
	 * Redirection vers la création d'une grille d'attribution spécifique
	 */
	private goToGrilleAttribution(grilleAttribution: any) {
		//Navigation vers la grille d'attribution spécifique
		this.layoutService.goToByState('listeGrillesAttribution-grilleAttribution',{
			routeParams: {
				idGrilleAttribution: grilleAttribution.idGrilleAttribution
			},
			routeData: {
				isOpenModeleSelection: true
			},
			withGoBack: true
		});

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}