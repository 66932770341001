<div class="modal-header">
	<h4 class="modal-title" translate>article.previsualisation</h4>
</div>
<div class="modal-body preview-template">
	<div class="text-center" *ngIf="isLoading">
		<please-wait></please-wait>
	</div>

	<div class="media" *ngIf="article && !isLoading">
		<article-consultation [article]="article" [isModal]="true"></article-consultation>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>