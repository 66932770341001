import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DataCustomizationListItemComponent } from './data-customization-list-item.component';

@Component({
	selector: 'data-customization-list',
	templateUrl: './data-customization-list.component.html'
})
export class DataCustomizationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,DataCustomizationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private extractionService: ExtractionService,private entiteService: EntiteService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DataCustomizationListItemComponent>({
			uri: `/controller/DataCustomizationEntite/filtreDataCustomizationsEntite`,
			title: this.translateService.instant('dataCustomization.liste.title'),
			component: DataCustomizationListItemComponent,
			listeFilters: [{
				clef: 'entite',
				type: TypeFilter.STRING,
				title: this.translateService.instant('search.entite'),
				listeValues: this.extractionService.getListeEntites('CUSTOM_FORM')
			}],
			defaultOrder: 'entite',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/DataCustomization/loadDataCustomization/0')),
			generateDisplayedContent: (liste) => {
				//Tri de la liste selon l'entité
				return liste.data?.content && sortBy(liste.data.content,dataCustomization => this.entiteService.translateEntityCode(dataCustomization.entite.split('.').pop(-1)));
			}
		});
	}
}
