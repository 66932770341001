<div class="modal-header">
	<h4 class="modal-title" translate>structureExport.fichier.nom.title</h4>
</div>
<div class="modal-body">
	<div class="card">
		<div class="form-group" [ngClass]="{ 'has-error': name.invalid }">
			<label translate>structureExport.fichier.nom.item</label>
			<div>
				<input type="text" name="name" #name="ngModel" class="form-control" [(ngModel)]="fichier.name" required [disabled]="isRestricted"/>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="saveName()" *ngIf="!isRestricted">
		<span translate>actions.continuer</span>
	</button>
</div>