import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,combineLatest } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContraventionDesignationComponent } from './contravention-designation.component';
import { ContraventionEditComponent } from './contravention-edit.component';
import { ContraventionService } from './contravention.service';

@Component({
	selector: 'contravention',
	templateUrl: './contravention.component.html'
})
export class ContraventionComponent implements OnInit {
	/** Options de la page complexe **/
	public options: Options;

	/** Contravention **/
	public contravention: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.CONTRAVENTION;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Utilisateur connecté **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private notificationService: NotificationService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private contraventionService: ContraventionService,private attachmentService: AttachmentService,private datePipe: DatePipe
			,private store: Store<AppState>,private pageContentService: PageContentService,private rightService: RightService,private messagingService: MessagingService,private toastrService: ToastrService,private layoutService: LayoutService,private confirmService: ConfirmService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.Contravention';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.contravention?.idContravention || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.contravention;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la présence de notifications et chargement de la contravention
		combineLatest([this.store.select<User>(state => state.session.user),this.notificationService.isNotificationAvailableFor(this.getClassName())]).pipe(first()).subscribe(([user,hasNotification]) => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idContravention).subscribe({
				complete: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [{
							libelle: 'infraction.contravention.type.item',
							key: () => this.translateService.instant('infraction.contravention.type.'+this.contravention?.type)
						},{
							libelle: 'infraction.contravention.reference',
							key: 'reference'
						},{
							libelle: 'infraction.contravention.vehicule',
							key: 'vehicule',
							type: 'DISPLAY',
							format: 'vehicule'
						},{
							libelle: 'infraction.contravention.dateAvis',
							key: 'dateAvis',
							type: 'DATE'
						},{
							libelle: 'infraction.contravention.etat',
							key: () => this.translateService.instant('infraction.contravention.statut.'+this.contravention?.statut)
						},{
							libelle: 'infraction.contravention.dateConstatation',
							key: 'dateContravention',
							type: 'DATE',
							isSecondary: true
						},this.contravention?.type == 'AMENDE' && {
							libelle: 'infraction.contravention.motif',
							key: 'motif.libelle',
							isSecondary: true
						}].filter(f => !!f),
						listeActions: [{
							libelle: 'actions.notifier',
							doAction: () => this.sendNotification(),
							isVisible: () => hasNotification
						},{
							libelle: 'infraction.contravention.actions.accederContraventionOrigine',
							doAction: () => this.goToContravention(this.contravention.contraventionOrigine.idContravention),
							isVisible: () => this.contravention?.contraventionOrigine && this.contravention.majoration
						},{
							libelle: 'infraction.contravention.actions.accederMajoration',
							doAction: () => this.goToContravention(this.contravention.contraventionMajorante.idContravention),
							isVisible: () => this.contravention?.majoree && !!this.contravention?.contraventionMajorante
						},{
							libelle: 'actions.modifier',
							doAction: () => this.editContravention(),
							isVisible: () => this.rightService.hasRight(null,'creation'),
							type: TypeAction.EDITION
						},{
							libelle: 'actions.consulter',
							doAction: () => this.editContravention(),
							isVisible: () => !this.rightService.hasRight(null,'creation'),
							type: TypeAction.CONSULTATION
						},{
							libelle: 'actions.supprimer',
							doAction: () => this.deleteContravention(),
							isVisible: () => this.rightService.hasRight(null,'suppression') && !this.contravention?.majoree,
							type: TypeAction.SUPPRESSION
						}],
						listeAlertes: [{
							icon: 'directions_car',
							title: () => this.translateService.instant('infraction.contravention.alerte.absenceDesignation.title',{ date: this.datePipe.transform(this.contravention?.dateMajoration,'shortDate') }),
							message: () => this.translateService.instant('infraction.contravention.alerte.absenceDesignation.message'),
							isVisible: () => !this.contravention?.designation && !this.pageContentService.isOpened(),
							doAction: () => this.editDesignation(),
							isActionVisible: () => this.rightService.hasRight(null,'creation')
						}],
						listeElements: [{
							type: 'DESIGNATION',
							component: ContraventionDesignationComponent,
							retrieveComponentData: () => ({
								contravention: this.contravention,
								editDesignation: this.editDesignation.bind(this),
								deleteDesignation: this.deleteDesignation.bind(this)
							}),
							isTabHidden: true,
							isVisible: () => this.contravention?.designation?.idDesignation
						}],
						customStyle: {
							elements: {
								body: ' '
							}
						},
						isFormCustomization: true
					};
				}
			});
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idContravention: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de la contravention
		this.contraventionService.loadContravention(idContravention).pipe(first()).subscribe({
			next: result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour de la contravention
					this.contravention = result.data.contravention;

					//Initialisation du compteur de pièces-jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant de la contravention
					if (!this.contravention?.idContravention) {
						//Création d'une contravention vierge
						this.contravention = {
							statut: 'IMPAYEE',
							vehicule: history.state.vehicule || null,
							devise: this.user.devise,
							pays: this.user.pays
						};

						//Edition de la contravention
						this.editContravention();
					}
				}
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Envoi d'une notification
	 */
	private sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite(this.getClassName(),this.getIdObject(),{
			getOwningEntity: () => this.contravention,
			typeAttachment: TypeAttachment.CONTRAVENTION
		});
	}

	/**
	 * Edition de la contravention
	 */
	private editContravention() {
		//Edition de la contravention
		this.pageContentService.open(ContraventionEditComponent,{
			data: {
				contravention: this.contravention.idContravention ? cloneDeep(this.contravention) : this.contravention,
				saveContravention: this.saveContravention.bind(this),
				deleteContravention: this.deleteContravention.bind(this)
			}
		}).subscribe({
			next: contravention => {
				//Mise à jour de la contravention
				Object.assign(this.contravention,contravention);
			}
		});
	}

	/**
	 * Enregistrement de la contravention
	 */
	private saveContravention(contravention: any,isPublish: boolean,close?: Function,isSaveDesignation?: boolean) {
		let messaging$: MessagingObservables;

		//Enregistrement de la contravention par WebSocket
		messaging$ = this.messagingService.init({
			method: 'PUT',
			entryPoint: `controller/Contravention/saveContravention/${isPublish}`,
			params: contravention
		}).onFinish({
			next: () => {
				//Rechargement de la contravention
				this.contraventionService.loadContravention(this.contravention.idContravention).pipe(first()).subscribe(result => {
					//Mise à jour de la contravention
					this.contravention = result.data.contravention;

					//Rechargement de l'URL
					this.layoutService.replaceUrlWith(this.contravention);

					//Fermeture de l'écran (si nécessaire)
					!isSaveDesignation && close?.(this.contravention);
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		}).onResult({
			next: (result: Result) => {
				let listeDoublons: Array<string> = [];

				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de la contravention
					this.contravention = result.data.contravention;
				} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));
					listeDoublons.push(this.translateService.instant('actions.doublon.immatriculation'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				//Fermeture de l'écran (si nécessaire)
				isSaveDesignation && close?.(this.contravention);
			}
		});
	}

	/**
	 * Suppression de la contravention
	 */
	private deleteContravention() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contraventionService.deleteContravention(this.contravention))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.contravention;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Modification de la désignation
	 */
	private editDesignation() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ContraventionDesignationComponent,{
			data: {
				contravention: this.contravention,
				saveContravention: this.saveContravention.bind(this),
				isEdition: true
			}
		},'sub').subscribe({
			next: () => {
				//Sélection de l'onglet
				this.complexPage.setSelectedElementByType('DESIGNATION',true);
			}
		});
	}

	/**
	 * Suppression de la désignation
	 */
	public deleteDesignation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contraventionService.saveContravention(Object.assign(this.contravention,{
				typeDesignation: null,
				designation: null
			}),false))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Mise à jour de la contravention
				this.contravention = result.data.contravention;
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Accès au formulaire d'une contravention
	 */
	private goToContravention(idContravention: number) {
		//Redirection vers la contravention
		this.layoutService.goToByState('infractionReferentiel-listeVehiculeContraventions-contravention',{
			routeParams: {
				idContravention: idContravention || 0
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}
}