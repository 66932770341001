import { AfterContentChecked,AfterViewInit,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,pick } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { filter,switchMap,tap } from 'rxjs/operators';

import { ConducteurService } from 'src/app/components/vehicule/conducteur/conducteur.service';
import { ReservationService } from 'src/app/components/vehicule/pool/reservation/reservation.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AffectationService } from './affectation.service';

@Component({
	selector: 'affectation-content',
	templateUrl: './affectation-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class AffectationContentComponent implements OnInit,AfterContentChecked,AfterViewInit {
	/** Elément courant **/
	@Input() affectation: any;

	/** Source **/
	@Input() source: 'AFFECTATION' | 'VEHICULE' | 'CONDUCTEUR' | 'PLANNING';

	/** Possibilité de modifier le véhicule **/
	@Input() canRemoveVehicule: boolean;

	/** Possibilité de modifier le conducteur **/
	@Input() isCollabDisabled: boolean;

	/** Véhicule affecté **/
	@Input() vehicule: any;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<{ affectation: any,vehicule: any,idAffectationClosed: number }>();

	/** Redirection vers le formulaire du véhicule **/
	@Output() showVehicule = new EventEmitter<any>();

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Période en anomalie **/
	public periodeValidity: { isValid: boolean,affectation?: any } = {
		isValid: true
	}

	/** Véhicule dont la période est en anomalie **/
	public periodeVehiculeError: any = null;

	/** Date du jour **/
	public today: moment.Moment = moment().startOf('day');

	/** Liste des types d'affectation **/
	public listeTypesAffectation: Array<{ code: string,libelle: string }> = [];

	/** Copie de l'affectation **/
	private savedAffectation: any;

	/**
	 * Constructeur
	 */
	constructor(private affectationService: AffectationService,private reservationService: ReservationService,private conducteurService: ConducteurService
			,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef
			,public rightService: RightService,public vehiculeService: VehiculeService ,private attachmentService: AttachmentService,private ngForm: NgForm) {
		//Binding des méthodes
		this.saveAffectation = this.saveAffectation.bind(this);
		this.deleteAffectation = this.deleteAffectation.bind(this);
		this.doActionForReservation = this.doActionForReservation.bind(this);
		this.goToPlanning = this.goToPlanning.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la source
		if (this.source == 'PLANNING')
			//Définition du véhicule courant
			this.vehicule = this.affectation.vehicule;

		//Récupération de la liste des types d'affectation
		this.listeTypesAffectation = this.affectationService.getListeTypesAffectation().filter(type => !this.isCollabDisabled || type.code != 'VEHICULE_IMMOBILISE');

		//Vérification de l'affectation
		if ((!this.affectation.idAffectation || this.affectation.typeAffectation != 'VEHICULE_POOL') && !this.rightService.hasRight(TypeDroit.VEHICULE_RESERVATION,'creation'))
			//Retrait du type d'affectation 'Pool'
			this.listeTypesAffectation.splice(this.listeTypesAffectation.findIndex(t => t.code == 'VEHICULE_POOL'),1);

		//Copie de l'affectation avant modifications
		this.savedAffectation = cloneDeep(this.affectation);
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Détection des modifications de l'affectation
		this.ngForm?.control.valueChanges.pipe(filter(newValues => !!newValues.affectationContent)).subscribe(newValues => {
			//Vérification du changement de conducteur
			if (newValues.affectationContent.collaborateur?.idUser && newValues.affectationContent.collaborateur.idUser != this.affectation.user?.idUser)
				//Recherche du permis de conduire et de l'adresse
				this.searchPermisAndAdresseConducteur(newValues.affectationContent.collaborateur.idUser);
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Suppression de l'affectation
	 */
	deleteAffectation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.affectationService.deleteAffectation(this.affectation))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Vérification de la présence d'un compteur de pièces jointes
				if (this.source == 'VEHICULE')
					//Mise à jour du compteur de pièces jointes
					this.attachmentService.incrementAttachments(-(this.affectation.listeLinks?.length || 0),TypeAttachment.VEHICULE);

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Réalisation d'une action sur la réservation de l'affectation
	 */
	doActionForReservation(typeAction: 'VALIDER' | 'REJETER' | 'ANNULER') {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.annulation.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.reservationService.doAction(typeAction,this.affectation.reservation.idReservation))
		).subscribe({
			next: isSuccess => {
				//Vérification du statut
				if (isSuccess) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.annulation.success'));

					//Vérification de la présence d'un compteur de pièces jointes
					if (this.source == 'VEHICULE')
						//Mise à jour du compteur de pièces jointes
						this.attachmentService.incrementAttachments(-(this.affectation.listeLinks?.length || 0),TypeAttachment.VEHICULE);

					//Fermeture du formulaire
					this.close.emit();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.annulation.error'));
			}
		});
	}

	/**
	 * Redirection vers le planning
	 */
	goToPlanning() {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'une réervation et d'un pool
		if (this.affectation.vehicule?.pool?.idPool && this.affectation.reservation) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [this.affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + this.affectation.vehicule.pool.libelle + ' (' + this.affectation.vehicule.pool.reference + ')'
			});
		}

		//Navigation vers le planning
		this.vehiculeService.goToPlanning(this.affectation.vehicule,{ listeSelectedFilters },null,{ initialDate: this.affectation.dateDebut });
	}

	/**
	 * Sauvegarde de l'affectation
	 */
	saveAffectation() {
		let affectation;
		let vehicule;
		let idAffectationToClose: number;
		let hasChangedForReservation: boolean;
		let isRejetReservation: boolean;

		//Vérification de l'origine de la saisie
		if (this.source == 'CONDUCTEUR')
			//Mise à jour du véhicule
			this.vehicule = vehicule = this.affectation.vehicule;
		else
			//Récupération du véhicule
			vehicule = this.vehicule?.idVehicule ? this.vehicule : this.affectation.vehicule;

		//Vérification des périodes
		this.affectationService.isPeriodeValid(this.affectation,vehicule).subscribe(periodeValidity => {
			//Définition de la validité de la période
			this.periodeValidity = periodeValidity;

			//Vérification de la période du véhicule
			this.periodeVehiculeError = !this.affectationService.isPeriodeVehiculeValid(this.affectation,vehicule) ? vehicule : null;

			//Vérification du chevauchement
			if (!this.periodeVehiculeError && (this.periodeValidity.isValid || !this.periodeValidity.isValid && (!this.periodeValidity.affectation.dateFin || moment().isBefore(this.periodeValidity.affectation.dateFin)) && this.periodeValidity.affectation.dateDebut < this.affectation.dateDebut)) {
				//Vérification d'une erreur avec une affectation à clotûrer
				if (!this.periodeValidity.isValid)
					//Définition de l'identifiant de l'affectation à clotûrer
					idAffectationToClose = this.periodeValidity.affectation.idAffectation;

				//Vérification du changement de date ou de véhicule pour les réservations
				hasChangedForReservation = !!this.affectation.reservation && this.affectation.dateDebut != this.savedAffectation.dateDebut || this.affectation.dateFin != this.savedAffectation.dateFin || this.affectation.vehicule?.idVehicule != this.savedAffectation.vehicule?.idVehicule;

				//Affichage d'un message de confirmation pour les réservations au statut 'VALIDEE' en cas de modification
				(hasChangedForReservation && this.affectation.reservation && this.affectation.reservation.statut == 'VALIDEE' ? this.confirmService.showConfirm(this.translateService.instant('vehicule.affectation.reservation.sendReservationUpdate'),{ actionColor: 'primary' }) : of(false)).pipe(
					tap(isSendUpdate => {
						//Définition de l'indicateur
						this.affectation.sendReservationUpdate = !!isSendUpdate;
					}),
					switchMap(() => {
						//Vérification de la nécessité de clôturer une affectation avant l'enregistrement de la nouvelle affectation
						return idAffectationToClose ? this.confirmService.showConfirm(this.translateService.instant('vehicule.affectation.clotureConfirmation'),{ actionColor: 'primary' }) : of(true);
					}),
					filter(isConfirmed => !!isConfirmed),
					tap(() => {
						//Copie de l'affectation
						affectation = cloneDeep(this.affectation);

						//Modification du véhicule
						affectation.vehicule = pick(affectation.vehicule,['idVehicule']);

						//Vérification de la nécessité de clotûrer une affectation avant l'enregistrement de la nouvelle affectation
						if (idAffectationToClose)
							//Définition de l'identifiant de l'affectation à clotûrer
							affectation.idAffectationToClose = idAffectationToClose;

						//Vérification de la demande de rejet de la réservation
						isRejetReservation = affectation.validateReservation === false;
					}),
					switchMap(() => this.affectationService.saveAffectation(affectation))
				).subscribe(result => {
					//Vérification du code d'erreur
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour de l'affectation
						this.affectation = result.data.affectation;

						//Définition du véhicule
						this.affectation.vehicule = Object.assign({},vehicule);

						//Fermeture du mode édition
						this.close.emit(!isRejetReservation && {
							affectation: this.affectation,
							vehicule: result.data.vehicule,
							idAffectationClosed: result.data.idAffectationClosed
						} || null);

						//Réinitialisation de l'indicateur d'envoi de mail
						this.affectation.sendReservationUpdate = false;

						//Mise à jour de la copie de l'affectation
						this.savedAffectation = cloneDeep(this.affectation);
					} else if (result?.codeErreur === TypeCodeErreur.DOUBLON) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: this.translateService.instant(`actions.doublon.${result.data.doublon ? 'dateApplication' : 'dateDebut'}`)
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				});
			}
		});
	}

	/**
	 * Affichage du formulaire de permis de conduire
	 */
	showPermisConduire() {
		//Affichage de la pop-up de permis de conduire
		this.conducteurService.showPermisConduire(this.affectation.conducteur?.permisConduire || {}).pipe(filter(p => typeof p != 'undefined')).subscribe(permisConduire => {
			//Mise à jour du permis
			this.affectation.conducteur.permisConduire = permisConduire;
		});
	}

	/**
	 * Affichage du véhicule
	 */
	goToVehicule() {
		//Vérification de l'autorisation
		if (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation'))
			//Affichage du véhicule
			this.showVehicule.emit();
	}

	/**
	 * Recherche du permis et de l'adresse d'un conducteur
	 */
	searchPermisAndAdresseConducteur(idUser: number) {
		//Recherche du permis et de l'adresse
		this.conducteurService.findPermisConducteur(idUser).subscribe(result => {
			//Vérification du retour
			if (result?.data) {
				//Initialisation d'un nouveau conducteur si nécessaire
				this.affectation.conducteur = this.affectation.conducteur || {};

				//Initialisation d'un nouveau descriptif d'utilisateur si nécessaire
				this.affectation.user.userDescription = this.affectation.user.userDescription || {};

				//Définition du permis de conduire
				this.affectation.conducteur.permisConduire = result.data.permisConduire || null;

				//Vérification de l'adresse
				if (result.data.user?.userDescription?.adressePerso) {
					//Mise à jour de l'adresse
					this.affectation.user.userDescription.adressePerso = result.data.user.userDescription.adressePerso;
				} else {
					//Suppression de l'adresse
					this.affectation.user.userDescription.adressePerso = null;
				}
			}
		});
	}

	/**
	 * Affichage des affectations du véhicule
	 */
	showListeAffectations(vehicule) {
		//Affichage des affectations du véhicule
		this.affectationService.showListeAffectations(this.vehicule || vehicule).pipe(filter(a => !!a)).subscribe(affectation => {
			//Vérification de la désactivation du collaborateur
			if (!this.isCollabDisabled && !this.affectation.reservation && (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') || this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'creation')))
				//Mise à jour du conducteur
				this.affectation.user = affectation.user;
		});
	}

	/**
	 * Retrait du véhicule
	 */
	removeVehicule() {
		//Suppression du véhicule de l'affectation
		this.affectation.vehicule = null;

		//Retrait du véhicule
		this.vehicule = null;
	}
}