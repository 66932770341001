import { Component,Input,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
	selector: 'account-article',
	templateUrl: './account-article.component.html'
})
export class AccountArticleComponent implements OnInit {
	/** Observable sur l'article **/
	@Input() article$: Observable<any>;

	/** Article **/
	article: any;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Ecoute de l'article
		this.article$.pipe(first()).subscribe(article => this.article = article);
	}
}
