<div class="listview lv-bordered no-search">
	<display-more [listeItems]="taxe.listeTaux" #displayMore>
		<div class="lv-body">
			<div class="lv-item media" *ngFor="let taux of displayMore.getSlice(); let index = index">
				<div class="pull-left lv-avatar">
					<span class="multi">{{ taux?.taux }}</span>
					<span class="multi">%</span>
				</div>
				<div class="media-body">
					<div class="lv-title">
						<a [routerLink]="[]" (click)="addTaux(taux,index)">{{ taux?.libelle }}</a>
					</div>
					<ul class="lv-attrs">
						<li *ngIf="taux.remarque" [tooltip]="taux.remarque"><span translate>fiscalite.taxe.remarque</span>&#160;:&#160;<span>{{ taux.remarque.substring(0,30) + (taux.remarque.length > 30 ? '...': '') }}</span></li>
					</ul>
					<div class="lv-actions actions dropdown" *ngIf="taux?.idTaux">
						<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
						<ul class="dropdown-menu dropdown-menu-right">
							<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteTaux(index);"><span translate>actions.supprimer</span></a></li>
							<li><a [routerLink]="[]" (click)="showHistory(taux)"><span translate>actions.historique.consulter</span></a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="text-center p-10" *ngIf="!taxe.listeTaux?.length">
				<h5><span translate>liste.empty</span></h5>
			</div>
		</div>
	</display-more>
</div>