import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { mapEntites } from './releve-energie.entites';
import { ReleveEnergieListItemComponent } from './releve-energie-list-item.component';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	templateUrl: './releve-energie-list.component.html'
})
export class ReleveEnergieListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ReleveEnergieListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,public dashboardService: DashboardService,public extractionService: ExtractionService) {

	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ReleveEnergieListItemComponent>({
			uri: `/controller/VehiculeReleveEnergie/filtreRelevesEnergie`,
			title: this.translateService.instant('vehicule.releveEnergie.liste.title'),
			component: ReleveEnergieListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			},{
				clef: '*equipement.reference',
				mapEncryptedFields: {
					'*equipement.reference': '*equipement.referenceChiffree'
				},
				isDefault: true
			},{
				clef: '*userSource.nom,*userSource.prenom,*userSource.matricule,*fournisseur.reference,*fournisseur.libelle',
				title: this.translateService.instant('search.origine'),
				isDefault: true
			},{
				clef: 'dateReleve',
				title: this.translateService.instant('search.date'),
				type: TypeFilter.DATE
			},{
				clef: 'volume',
				type: TypeFilter.DECIMAL
			},{
				clef: 'extension.isPriseMultiple',
				title: this.translateService.instant('vehicule.releveEnergie.search.priseMultiple'),
				type: TypeFilter.BOOLEAN
			},{
				clef: 'extension.isDepassementReservoir',
				title: this.translateService.instant('vehicule.releveEnergie.search.depassementReservoir'),
				type: TypeFilter.BOOLEAN
			},{
				clef: 'extension.userAffecte',
				title: this.translateService.instant('vehicule.releveEnergie.search.userAffecte'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				}
			},{
				clef: 'unite',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'unite',
					filter: 'CONTENANCE'
				}
			}],
			defaultOrder: '-dateReleve,vehicule.reference',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(i => !i.idReleve) && this.liste.addItem({
					isDisplayed: true,
					valeurReference: true,
					typeSourceReleve: 'COLLABORATEUR'
				});
			},
			getKeyFieldName: () => 'idReleve'
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}