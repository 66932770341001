<div class="pull-left lv-avatar">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" *ngIf="data.demandeCotation" (click)="['A_COMPLETER','A_TRANSMETTRE','ANNULEE'].includes(data.statut) ? goToDemandeCotation() : (['EMISE_FOURNISSEUR','RETOUR_PARTIEL','RETOUR_COMPLET','SOLDEE'].includes(data.statut) ? showComparaisonForCotation() : null)"><span translate [translateParams]="{ numero: data.demandeCotation.idDemandeCotation }">demandeCotation.liste.item</span></a>
		<a [routerLink]="[]" *ngIf="!data.demandeCotation" translate [translateParams]="{ numero: data.demandeCotation.idDemandeCotation }">demandeCotation.liste.item</a>
	</div>
</div>
<ul class="lv-attrs">
	<li><span translate>demandeCotation.statut.item</span>&#160;:&#160;<span [translate]="'demandeCotation.statut.' + data.statut"></span></li>
	<li *ngIf="data.statut == 'SOLDEE' && data.extensension?.propositionRetenue?.typeFinancement"><span translate>demandeCotation.typeFinancement</span>&#160;:&#160;<span>{{ data?.extension?.propositionRetenue?.typeFinancement?.libelle }}</span></li>
	<li *ngIf="data.statut == 'SOLDEE' && data.extensension?.propositionRetenue">
		<span translate>demandeCotation.loiRoulage</span>&#160;:&#160;
		<span *ngIf="data.extension?.propositionRetenue?.duree && data.extension?.propositionRetenue?.distance"> {{ data?.extension?.propositionRetenue?.duree + ' ' + ('duree.mois' | translate) + '/' + (data.extension?.propositionRetenue?.distance | number) + ' ' + user?.unite?.libelle }} </span>
		<span *ngIf="!data.extension.propositionRetenue.duree || !data.extension.propositionRetenue.distance">{{ 'common.nonCommunique' | translate }}</span>
	</li>
	<li *ngIf="data.statut == 'SOLDEE' && data?.extension?.propositionRetenue?.loyerMensuel"><span translate>demandeCotation.loyerMensuel</span>&#160;:&#160;<span>{{ (data?.extension?.propositionRetenue?.loyerMensuel | currency:data?.extension?.propositionRetenue?.devise) + ' ' + (('vehicule.grilleAttribution.modele.modeAffichagePrix.' + data?.extension?.propositionRetenue?.typeAffichagePrix + '.code') | translate) }}</span></li>
	<li *ngIf="data.statut != 'SOLDEE' && data?.typeFinancement"><span translate>demandeCotation.typeFinancement</span>&#160;:&#160;<span>{{ data?.typeFinancement?.libelle }}</span></li>
	<li *ngIf="data.statut != 'SOLDEE'">
		<span translate>demandeCotation.loiRoulage</span>&#160;:&#160;
		<span *ngIf="data?.duree && data?.distance"> {{ data.duree + ' ' + ('duree.mois' | translate ) + '/' + (data.distance | number) + ' ' + user?.unite?.libelle }} </span>
		<span *ngIf="!data.duree || !data.distance">{{ 'common.nonCommunique' | translate }}</span>
	</li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="data.demandeCotation"><a [routerLink]="[]" (click)="goToDemandeCotation()"><span translate>demandeCotation.actions.accederCotation</span></a></li>
		<li *ngIf="TypeDroit.DEMANDE_COTATION | right:'suppression'"><a [routerLink]="[]" (click)="deleteCotation();"><span translate>actions.supprimer</span></a></li>
		<li *ngIf="(TypeDroit.DEMANDE_COTATION | right:'creation') && ['EMISE_FOURNISSEUR','RETOUR_PARTIEL','RETOUR_COMPLET'].includes(data.statut)"><a [routerLink]="[]" (click)="cancelCotation()"><span translate>actions.annuler</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>