<a [routerLink]="[]" *ngIf="!disabled" [ngClass]="customClass" #popover="bs-popover" [popover]="popoverTemplate" [popoverTitle]="'rule.selector.temporal.title' | translate" containerClass="fit-content" [outsideClick]="true" (onHidden)="onHidden()">
	<span>{{ getLabelForValue() }}</span>
</a>
<span *ngIf="disabled">
	<span>{{ getLabelForValue() }}</span>
</span>
<ng-template #popoverTemplate>
	<div class="d-flex">
		<div class="table-responsive">
			<table class="table" style="width: 200px;">
				<tr>
					<td class="p-0">
						<div class="table-responsive" *ngIf="listeTemporals?.length">
							<table class="table table-hover bgm-white">
								<tbody>
									<tr class="clickable" *ngFor="let t of listeTemporals" (click)="selectTemporal(t)" [ngClass]="{ 'selected': t.code == temporal }">
										<td>{{ t.libelle }}</td>
										<td><icon name="check" [ngClass]="{ 'hidden': t.code !== temporal }"></icon></td>
									</tr>
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="p-0" *ngIf="temporal == 'AUCUNE'">
			<div [(ngModel)]="date" datePicker [inline]="true" [storeDate]="true" (ngModelChange)="hide()"></div>
		</div>
	</div>
</ng-template>