import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ValidationListComponent } from './validation-list.component';
import { ValidationListItemComponent } from './validation-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ValidationListComponent,ValidationListItemComponent]
})
export class ValidationModule {}