import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GroupeEditComponent } from './groupe-edit.component';
import { GroupeRuleComponent } from './groupe-rule.component';
import { GroupeService } from './groupe.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	selector: 'groupe',
	templateUrl: './groupe.component.html'
})
export class GroupeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Groupe courant **/
	public groupe: any;

	/** Indicateur d'utilisation du groupe **/
	isGroupeUsed: boolean = false;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private groupeService: GroupeService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute
		,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
		,private layoutService: LayoutService,private entiteService: EntiteService,private extractionService: ExtractionService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.groupe.Groupe';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.groupe?.idGroupe || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'groupe.libelle',
				key: 'libelle'
			},{
				libelle: 'groupe.reference',
				key: 'reference'
			},{
				libelle: 'groupe.entity',
				key: groupe => this.entiteService.translateEntityCode(groupe.entity.split('.').pop(-1))
			},{
				libelle: 'groupe.actif',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.extraire',
				doAction: () => this.extractionService.selectExtraction(null,this.groupe.entity,{ ruleOverride: this.groupe.rule,baseFilename: this.groupe.reference }),
				isVisible: () => this.groupe?.rule?.listeDetails?.length > 0 && this.rightService.hasRight(null,'creation')
			},{
				libelle: 'actions.modifier',
				doAction: () => this.editGroupe(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editGroupe(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteGroupe(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'filter_list',
				title: this.translateService.instant('groupe.alerte.aucunFiltre.title'),
				message: this.translateService.instant('groupe.alerte.aucunFiltre.message'),
				isVisible: () => !this.groupe?.rule?.listeDetails?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addRule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'FILTRE',
				libelle: 'groupe.elements.filtre',
				component: GroupeRuleComponent,
				retrieveComponentData: () => ({
					getGroupe: () => this.groupe,
					getRule: () => this.groupe?.rule
				}),
				doAction: () => this.addRule(),
				isElementVisible: () => this.groupe?.rule,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				count: () => this.groupe?.rule?.listeDetails?.length ? 1 : 0
			}]
		};

		//Récupération de l'identifiant du groupe à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idGroupe);
			}
		});
	}

	/**
	 * Edition du groupe
	 */
	editGroupe() {
		//Ouverture du composant d'édition
		this.pageContentService.open(GroupeEditComponent,{
			data: {
				groupe: this.groupe,
				deleteGroupe: this.deleteGroupe.bind(this),
				isGroupeUsed: this.isGroupeUsed
			}
		}).subscribe({
			next: groupe => {
				//Vérification des données
				if (groupe) {
					//Mise à jour du groupe
					this.groupe = groupe;

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.groupe);
				}
			}
		});
	}

	/**
	 * Suppression du groupe
	 */
	private deleteGroupe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du groupe
					this.groupeService.deleteGroupe(this.groupe.idGroupe).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								delete this.groupe;

								//Fermeture de la page
								this.layoutService.goBack();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idGroupe: number) {
		//Chargement du groupe
		this.groupeService.loadGroupe(idGroupe).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du groupe
					this.groupe = result.data.groupe;

					//Définition de l'indicateur d'utilisation du groupe
					this.isGroupeUsed = result.data.isGroupeUsed;

					//Vérification de l'identifiant du groupe
					if (!this.groupe?.idGroupe) {
						//Création d'un groupe vierge
						this.groupe = {};

						//Edition du groupe
						this.editGroupe();
					}
				} else if (result.codeErreur == TypeCodeErreur.DROIT) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.acces.accesNonAutorise'));

					//Fermeture de la page
					this.layoutService.goBack();
				}
			}
		});
	}

	/**
	 * Ajout d'une règle
	 */
	private addRule() {
		//Affichage du détail
		this.pageContentService.open(GroupeRuleComponent,{
			data: {
				getGroupe: () => this.groupe,
				getRule: () => this.groupe.rule && cloneDeep(this.groupe.rule) || {},
				isEdition: true
			}
		},'sub').subscribe({
			next: (groupe) => {
				//Vérification du groupe
				if (groupe) {
					//Mise à jour du groupe
					this.groupe = Object.assign(this.groupe,groupe);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FILTRE',true);
				}
			}
		});
	}
}