import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first,map,switchMap,take } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { v4 } from 'uuid';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ArticleLectureComponent } from './article-lecture.component';

@Injectable()
export class ArticleService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService) {

	}

	/**
	 * Chargement de l'article
	 */
	public loadArticle(idArticle: number,isConsultation: boolean = false): Observable<Result> {
		//Chargement de l'article
		return this.http.post<Result>(`${environment.baseUrl}/controller/Aide/loadArticle/${idArticle}`,null,{ params: { isConsultation } }).pipe(first());
	}

	/**
	 * Chargement de l'article par son type
	 */
	public loadTypeArticle(typeArticle: 'CGU' | 'ONBOARDING' | 'STANDARD' | 'NOUVEAUTES' | 'ALERTE'): Observable<Result> {
		//Chargement de l'article
		return this.http.post<Result>(`${environment.baseUrl}/controller/Aide/loadTypeArticle/${typeArticle}`,null);
	}

	/**
	 * Sauvegarde d'un feedback
	 */
	public saveFeedback(idArticle: number,feedback: any): Observable<Result> {
		//Sauvegarde du feedback
		return this.http.put<Result>(`${environment.baseUrl}/controller/Aide/saveFeedback/${idArticle}`,feedback);
	}

	/**
	 * Consultation de l'article
	 */
	public consultArticle(article: any) {
		//Ouverture de l'article en consultation
		window.open(`#/Aide/consultArticle/${article.idArticle}`,`help_view_${v4().toString()}`);
	}

	/**
	 * Affichage d'un type d'article
	 */
	public showTypeArticle(typeArticle: 'CGU' | 'ONBOARDING' | 'STANDARD' | 'NOUVEAUTES' | 'ALERTE'): Observable<{ article: any,result: { isRead?: boolean } }> {
		//Chargement du type d'article
		return this.loadTypeArticle(typeArticle).pipe(
			take(1),
			switchMap(result => {
				let bsModalRef: BsModalRef<ArticleLectureComponent>;

				//Affichage de la popup
				bsModalRef = this.bsModalService.show(ArticleLectureComponent,{
					initialState: {
						article: result.data.article
					},
					class: 'modal-lg',
					ignoreBackdropClick: typeArticle == 'CGU'
				});

				//Retour du résultat
				return bsModalRef.onHidden.pipe(
					take(1),
					map(() => ({
						article: result.data.article,
						result: bsModalRef.content.result
					}))
				);
			})
		)
	}
}