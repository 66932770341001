<div class="container-fluid">
	<form #planComptableForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>comptabilite.planComptable.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="planComptable.libelle" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>comptabilite.planComptable.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="planComptable.reference" required maxlength="100"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>comptabilite.planComptable.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" (ngModelChange)="planComptable.listeSocietes = null" [(ngModel)]="planComptable.pays" [disabled]="resume.nbComptes > 0" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>comptabilite.planComptable.dateApplication</label>
								<div class="half">
									<input class="form-control" type="text" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="planComptable.dateApplication" datePicker required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.planComptable.actif</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="planComptable.actif"><span translate>comptabilite.planComptable.actifDescription</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>comptabilite.planComptable.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" rows="3" [(ngModel)]="planComptable.remarque"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="card-body card-padding m-t-20" *ngIf="planComptable.pays">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>comptabilite.planComptable.listeSocietes.titre</h4>
						</div>
					</div>
					<basket [model]="planComptable" propertyName="listeSocietes" colWidthLabel="12" colWidthAutocomplete="6" type="societe" [labelAutocomplete]="'comptabilite.planComptable.listeSocietes.ajout' | translate" [labelListe]="'comptabilite.planComptable.listeSocietes.liste' | translate" [hasError]="isListeSocietesInvalid" [filter]="{ idPays: planComptable?.pays?.idPays || 0 }"></basket>
				</fieldset>
			</div>
		</div>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deletePlanComptable();" *ngIf="planComptable.idPlanComptable > 0 && !idPlanComptableSource && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="planComptableForm.invalid || !planComptable.listeSocietes?.length" (click)="savePlanComptable();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.' + (!idPlanComptableSource ? planComptable.idPlanComptable ? 'enregistrer' : 'continuer' : 'dupliquer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="planComptable.idPlanComptable && !idPlanComptableSource" [formDetectChanges]="{ doAction: close,isDirty: planComptableForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>