import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { NatureFournisseurListItemComponent } from './nature-fournisseur-list-item.component';

@Component({
	selector: 'nature-fournisseur-list',
	templateUrl: './nature-fournisseur-list.component.html'
})
export class NatureFournisseurListComponent extends PageContentItem implements OnInit {
	/** Nature **/
	@Input() nature: any;

	/** Données **/
	liste: ListView<any,NatureFournisseurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,NatureFournisseurListItemComponent>({
			uri: () => `/controller/Fournisseur/listeFournisseursByMetiers`,
			component: NatureFournisseurListItemComponent,
			isLocal: true,
			listeExtraData: () => [Object.assign(this.nature,{ '_type': 'com.notilus.data.fournisseur.FournisseurNature' })],
			defaultOrder: 'libelle',
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}]
		});
	}
}