import { Injectable,ComponentFactoryResolver,ApplicationRef,Injector,ComponentRef,EmbeddedViewRef } from '@angular/core';
import { Observable,Subscriber } from 'rxjs';

import { ImageEditorComponent } from './image-editor.component';

@Injectable()
export class ImageEditorService {
	/**  Référence vers l'éditeur **/
	private imageEditorRef: ComponentRef<ImageEditorComponent> = null;

	/**
	 * Constructeur
	 */
	constructor(private componentFactoryResolver: ComponentFactoryResolver,private appRef: ApplicationRef,private injector: Injector) {

	}

	/**
	 * Affichage de l'éditeur
	 */
	show(item: File | string,options?: { isConsultation?: boolean }): Observable<File> {
		//Création d'un observable
		return new Observable((subscriber: Subscriber<File>) => {
			//Création du composant
			this.imageEditorRef = this.componentFactoryResolver.resolveComponentFactory(ImageEditorComponent).create(this.injector);

			//Vérification du type d'élément
			if (item instanceof File)
				//Définition de l'image
				this.imageEditorRef.instance.imageFile = item;
			else
				//Définition de la source de l'image
				this.imageEditorRef.instance.imageSource = item;

			//Mode de lecture
			this.imageEditorRef.instance.consultation = options?.isConsultation || false;

			//Interception de la fermeture
			this.imageEditorRef.instance.onClose.subscribe((blob: Blob) => {
				let file: File;

				//Fermeture de l'éditeur
				this.hide();

				//Vérification de l'image
				if (blob && item instanceof File) {
					//Définition du fichier
					file = new File([blob],item?.name,{ type: blob.type });

					//Retour du fichier
					subscriber.next(file);
				} else
					//Aucun retour
					subscriber.next(null);

				//Fermeture de l'observateur
				subscriber.complete();
			});

			//Ajout du composant à l'application
			this.appRef.attachView(this.imageEditorRef.hostView);

			//Ajout du composant à la page
			document.body.appendChild((this.imageEditorRef.hostView as EmbeddedViewRef<ImageEditorComponent>).rootNodes[0] as HTMLElement);
		});
	}

	/**
	 * Masquage de l'éditeur
	 */
	hide() {
		//Vérification de l'affichage de l'éditeur
		if (this.imageEditorRef) {
			//Retrait du composant de l'application
			this.appRef.detachView(this.imageEditorRef.hostView);

			//Suppression du composant
			this.imageEditorRef.destroy();

			//Retrait de la référence
			this.imageEditorRef = null;
		}
	}
}