import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';
import { TypeDroit } from 'src/app/domain/security/right';
import { CompteAddComponent } from 'src/app/components/comptabilite/compte/compte-add.component';

/**
 * Options des comptes
 */
export default class CompteOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.compte';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/Compte/filtreComptes/${filter?.typeReferentiel || TypeReferentiel.LOCAL}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.numero}${item?.libelle ? ' (' + item?.libelle + ')' : ''}` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.numero?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.comptabilite.Compte';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idCompte'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'numero',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idPays && {
			clef: 'pays.idPays',
			valeur: filter.idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		},filter?.idPlanComptable && (filter?.typeReferentiel != TypeReferentiel.STANDARD) && {
			clef: 'planComptable.idPlanComptable',
			valeur: filter.idPlanComptable,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		},filter?.listeTypes?.length && {
			clef: 'type',
			listeObjects: filter.listeTypes,
			typeComparaison: TypeComparaison.IN,
			type: TypeFilter.STRING
		},filter?.idCompteExcluded && {
			clef: 'idCompte',
			valeur: filter.idCompteExcluded,
			typeComparaison: TypeComparaison.NOT_EQUALS,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'comptabilite.compte.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return CompteAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_PLAN_COMPTABLE;

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'comptabilite.compte.type.item',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`comptabilite.compte.type.${item.type}`)
		}];
	}
}