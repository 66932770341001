import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des articles CRM
 */
export default class ArticleCRMOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.articleCRM';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Commerce/filtreArticlesCRMActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.crm.ArticleCRM';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idArticle'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: { idRevendeur?: number }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idRevendeur && {
			clef: 'listeRevendeurs.idRevendeur',
			valeur: filter.idRevendeur,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}
}