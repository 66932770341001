import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { environment } from 'src/environments/environment';

@Injectable()
export class PersonalDataService {
	/** Liste des types de durée **/
	private listeTypesDuree: Array<string> = ['JOURS','MOIS','ANNEES'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Anonymisation d'un utilisateur
	 */
	public anonymize(user: User): Observable<Result> {
		//Anonymisation de l'utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/PersonalData/anonymize/${user.idUser}`,null);
	}

	/**
	 * Chargement du paramétrage d'automatisation
	 */
	public loadAutomatisationAnonymisation(): Observable<Result> {
		//Chargement du paramétrage d'automatisation
		return this.http.post<Result>(`${environment.baseUrl}/controller/PersonalData/loadAutomatisationAnonymisation`,null);
	}

	/**
	 * Enregistrement du paramétrage d'automatisation
	 */
	public saveAutomatisationAnonymisation(automatisationAnonymisation: any): Observable<Result> {
		//Chargement du paramétrage d'automatisation
		return this.http.put<Result>(`${environment.baseUrl}/controller/PersonalData/saveAutomatisationAnonymisation`,automatisationAnonymisation);
	}

	/**
	 * Récupération de la liste des types de durée
	 */
	public getListeTypesDuree(): Array<{ code: string,libelle: string }> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesDuree.map(code => ({
			code,
			libelle: this.translateService.instant('personalData.typeDuree.'+code)
		}));
	}
}