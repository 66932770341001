import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CalendarOptions } from 'src/app/domain/calendar/calendar';

@Component({
	templateUrl: './calendar-maximizer.component.html'
})
export class CalendarMaximizerComponent implements OnInit {
	/** Options du calendrier **/
	@Input() options: CalendarOptions;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<CalendarMaximizerComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let onSelect: (d: any) => void;

		//Définition du mode d'affichage
		this.options.isModal = true;

		//Récupération de l'intercepteur des clics sur les événements
		onSelect = this.options.onSelect;

		//Redéfinition de l'intercepteur des clics sur un événement
		this.options.onSelect = (idItem: string) => {
			//Fermeture de la pop-up
			this.bsModalRef.hide();

			//Exécution de l'intercepteur initial
			onSelect(idItem);
		}
	}
}