<form *ngIf="item" #todoListDetailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="form-content">
		<div class="row">
			<div class="form-group" [ngClass]="{ 'has-error': title.invalid }">
				<label><b translate>todoList.task.libelle</b></label>
				<div>
					<textarea class="form-control" name="title" #title="ngModel" [(ngModel)]="item.title" rows="4" required></textarea>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="form-group" [ngClass]="{ 'has-error': dateEcheance.invalid }">
				<label translate>todoList.task.dateEcheance</label>
				<div>
					<input type="text" class="form-control" name="dateEcheance" #dateEcheance="ngModel" [(ngModel)]="item.dateEcheance" datePicker [storeDate]="true" (ngModelChange)="onDateEcheanceChange()"/>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="item.dateEcheance">
			<div class="form-group">
				<label>
					<span translate>todoList.task.envoiRappel.item</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'todoList.task.envoiRappel.description' | translate" boundariesElement="window"></icon>
				</label>
				<div>
					<mat-checkbox name="envoiRappel" #envoiRappel="ngModel" [(ngModel)]="item.envoiRappel" (change)="hasUntickedEnvoiRappel = !item.envoiRappel"><span translate>todoList.task.envoiRappel.message</span></mat-checkbox>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="listeEntites?.length">
			<div class="form-group" [ngClass]="{ 'has-error': entiteField.invalid }">
				<label>
					<span translate>todoList.task.associer.libelle</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'todoList.task.associer.info' | translate" boundariesElement="window"></icon>
				</label>
				<div>
					<selectpicker name="entite" #entiteField="ngModel" [(ngModel)]="item.entite" (ngModelChange)="onEntiteChange($event);" [placeholder]="'common.aucun' | translate">
						<mat-option *ngFor="let entite of listeEntites" [value]="entite.code">{{ entite.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="entiteAutocomplete?.autocomplete?.name">
			<div class="form-group" [ngClass]="{ 'has-error': entityObject.invalid }">
				<label>{{ entiteAutocomplete.libelle }}</label>
				<div>
					<autocomplete name="entityObject" [type]="entiteAutocomplete?.autocomplete?.name" #entityObject="ngModel" isCreationDisabled="true" [(ngModel)]="item.entityObject" (ngModelChange)="onEntityObjectChange($event);" required></autocomplete>
				</div>
			</div>
		</div>
		<basket [model]="item" propertyName="listeUsers" colWidthLabel="12" colWidthAutocomplete="12" type="user" [isCreationDisabled]="true" [labelAutocomplete]="'todoList.task.partager.libelle' | translate" [tooltipAutocomplete]="'todoList.task.partager.tooltip' | translate" [labelListe]="'todoList.task.partager.liste' | translate"></basket>
	</div>
	<div class="footer">
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: todoListDetailForm.dirty || isListeUsersDirty() }">
			<span translate>actions.fermer</span>
		</button>
		<button mat-stroked-button color="primary" (click)="deleteItem();" *ngIf="item.idItem">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="todoListDetailForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>