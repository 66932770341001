import { Component,Input } from '@angular/core';
import { filter,map,first } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { LotComptableService } from './lot-comptable.service';
import { LotComptableCheckFactureListComponent } from './lot-comptable-check-facture-list.component';

@Component({
	selector: 'lot-compable-check-recapitulatif',
	templateUrl: './lot-comptable-check-recapitulatif.component.html'
})
export class LotComptableCheckRecapitulatifComponent {
	/** Résumé **/
	@Input() resume: any;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Mise à jour de la sélection **/
	@Input() onUpdate: any;

	/** Liste des comptes **/
	listeComptes: Array<any> = [];

	/** Modal d'affichage de la liste des factures **/
	bsModalRef: BsModalRef<LotComptableCheckFactureListComponent>;

	/**
	 * Constructeur
	 */
	constructor(private lotComptableService: LotComptableService,private bsModalService: BsModalService) { }

	/**
	 * Configuration des modes d'authentifications
	 */
	public showDetail(isAnomalie: boolean) {
		//Affichage de la liste des factures
		this.showDetailModal(isAnomalie).subscribe({
			next: (data: any) => {
				//Mise à jour de la sélection
				this.onUpdate(data?.selectionItems,data?.isDeletion,this.bsModalRef);
			}
		});
	}

	/**
	 * Affichage de la popup de détail du lot comptable
	 */
	showDetailModal(isAnomalie: boolean) {
		//Affichage de la popup
		this.bsModalRef = this.bsModalService.show(LotComptableCheckFactureListComponent,{
			initialState: {
				isAnomalie,
				selectionItems: this.selectionItems,
				showLotComptableChecker: this.showLotComptableChecker.bind(this)
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return this.bsModalRef.onHidden.pipe(
			first(),
			map(() => this.bsModalRef.content.result),
			filter(result => !!result)
		);
	}

	/**
	 * Ouverture de la popup de contrôle des écritures en mode édition
	 */
	showLotComptableChecker(selectedItem: any) {
		//Ouverture de la popup de contrôle des écritures
		this.lotComptableService.showLotComptableChecks(null,selectedItem,null,true,null).subscribe(result => {
			//Vérification de la présence de corrections effectuées
			if (result?.isCorrection)
				//Mise à jour de la sélection
				this.onUpdate(result.selectionItems,null,this.bsModalRef);
		});
	}
}