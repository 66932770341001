import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DatabaseListItemComponent } from './database-list-item.component';
import { DatabaseListComponent } from './database-list.component';
import { DatabaseComponent } from './database.component';
import { DatabaseService } from './database.service';

@NgModule({
	imports: [ShareModule],
	declarations: [DatabaseListComponent,DatabaseListItemComponent,DatabaseComponent],
	providers: [DatabaseService]
})
export class DatabaseModule { }