<div class="pull-left lv-avatar">
	<icon name="directions_car" *ngIf="!extraOptions.demandeVehicule.vehicule"></icon>
	<icon name="warning" *ngIf="extraOptions.demandeVehicule.vehicule && extraOptions.demandeVehicule.anomalieModele"></icon>
	<icon name="check" *ngIf="extraOptions.demandeVehicule.vehicule && !extraOptions.demandeVehicule.anomalieModele"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showModeleInformation(data)">
			<span>{{ data.marque?.libelle + ' ' + data.libelle }}</span><span *ngIf="data?.finition">&#160;{{ data.finition }}</span><span *ngIf="extraOptions.demandeVehicule.anomalieModele">&#160;-&#160;(<span [translate]="'demandeVehicule.modele.'+(data.isLivre ? 'livre' : 'commande')"></span>)</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.configuration && data.information?.genre?.modeAffichagePrix == 'HT'"><span translate>demandeVehicule.modele.configuration.prixHT</span>&#160;:&#160;<span>{{ computeMontantForModele() | currency:'.2-2':data.information?.commercialisationPrix?.devise }}</span></li>
		<li *ngIf="data.configuration && data.information?.genre?.modeAffichagePrix == 'TTC'"><span translate>demandeVehicule.modele.configuration.prixTTC</span>&#160;:&#160;<span>{{ computeMontantForModele() | currency:'.2-2':data.information?.commercialisationPrix?.devise }}</span></li>
	</ul>
	<div class="lv-actions actions" *ngIf="(null | right:'creation') && extraOptions.user.type != 'VIRTUAL' && ['A_VALIDER','VALIDEE','EN_COMMANDE','LIVRE','ANNULEE'].indexOf(extraOptions.demandeVehicule.statut) == -1">
		<a [routerLink]="[]" (click)="deleteModele()"><icon name="delete"></icon></a>
	</div>
</div>