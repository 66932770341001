import { CurrencyPipe,DatePipe,DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,combineLatest,defer,of } from 'rxjs';
import { filter,finalize,first,map,switchMap } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { GrilleAttributionModeleService } from 'src/app/components/vehicule/grille-attribution/grille-attribution-modele.service';
import { GrilleAttributionService } from 'src/app/components/vehicule/grille-attribution/grille-attribution.service';
import { GrilleFluiditeService } from 'src/app/components/vehicule/grille-fluidite/grille-fluidite.service';
import { OptionComponent } from 'src/app/components/vehicule/modele/option/option.component';
import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { AdvancedSearchOptions } from 'src/app/domain/advanced-search/advanced-search';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Page } from 'src/app/domain/common/http/list-result';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeComparaison } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { Options } from 'src/app/domain/comparator/comparator';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeDroit } from 'src/app/domain/security/right';
import { SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';
import { User } from 'src/app/domain/user/user';
import { Action,TypeAction,listeStatuts } from 'src/app/domain/vehicule/demande-vehicule/demande-vehicule';
import { AdvancedSearchService } from 'src/app/share/components/advanced-search/advanced-search.service';
import { ComparatorService } from 'src/app/share/components/comparaison/comparator.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { DemandeVehiculeChoixGrilleAttributionModeleComponent } from './demande-vehicule-choix-grille-attribution-modele.component';
import { DemandeVehiculeFinancementSelectionComponent } from './demande-vehicule-financement-selection.component';
import { DemandeVehiculeModeleSelectionComponent } from './demande-vehicule-modele-selection.component';

@Injectable()
export class DemandeVehiculeService {
	/** Utilisateur courant **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe,private decimalPipe: DecimalPipe
			,private bsModalService: BsModalService,private advancedSearchService: AdvancedSearchService,private successiveSearchService: SuccessiveSearchService,private rightService: RightService,private layoutService: LayoutService,private progressService: ProgressService,private messagingService: MessagingService
			,private cotationService: CotationService,private grilleAttributionModeleService: GrilleAttributionModeleService,private demandeCotationService: DemandeCotationService,private comparatorService: ComparatorService
			,private store: Store<AppState>,private grilleFluiditeService: GrilleFluiditeService,private grilleAttributionService: GrilleAttributionService,private workflowService: WorkflowService) {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Chargement d'une demande de véhicule
	 */
	public loadDemandeVehicule(idDemandeVehicule: number): Observable<Result> {
		//Chargement d'une demande de véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/loadDemandeVehicule/${idDemandeVehicule}`,null);
	}

	/**
	 * Suppression d'une demande de véhicule
	 */
	public deleteDemandeVehicule(demandeVehicule: any): Observable<Result> {
		//Suppression d'une demande de véhicule
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeCommande/deleteDemandeVehicule/${demandeVehicule.idDemandeVehicule}`);
	}

	/**
	 * Enregistrement d'une demande de véhicule
	 */
	public saveDemandeVehicule(demandeVehicule: any): Observable<Result> {
		//Enregistrement d'une demande de véhicule
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveDemandeVehicule`,demandeVehicule);
	}

	/**
	 * Enregistrement du véhicule pour la demande de véhicule
	 */
	public saveVehiculeForDemandeVehicule(idDemandeVehicule: number,idImmatriculation: number,idModele: number,idVehicule?: number): Observable<Result> {
		//Enregistrement du véhicule pour la demande de véhicule
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveVehiculeForDemandeVehicule/${idDemandeVehicule}/${idImmatriculation}/${idModele}${idVehicule ? '/'+idVehicule : ''}`,null);
	}

	/**
	 * Récupération de la liste des statuts
	 */
	public getListeStatuts(): Array<{ code: string,libelle: string,icone: string,iconeType?: string }> {
		//Liste des statut
		return listeStatuts.map(({ code,icone,iconeType }) => ({
			code,
			icone,
			iconeType,
			libelle: this.translateService.instant('demandeVehicule.statut.' + code)
		}));
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	public doAction(action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER',demandeVehicule: any): Observable<Result> {
		let subject: Subject<any> = new Subject<any>();
		let confirmation$: Observable<any>;
		let workflowMotifAndConfirmation$: Observable<any>;

		//Vérification de l'action
		if (action == 'ANNULER' && demandeVehicule.vehicule) {
			//Message d'avertissement
			this.toastrService.warning(this.translateService.instant('demandeVehicule.alerte.presenceVehicule'))

			//Fermeture de l'abonnement
			subject.complete();
		} else {
			//Définition du processus de confirmation
			confirmation$ = defer(() => this.confirmService.showConfirm(this.translateService.instant(`demandeVehicule.actions.${action}.confirmation`),{ actionColor: 'primary' }));

			//Vérification du type d'action
			if (action == 'REJETER') {
				//Sélection d'un motif Workflow si existant
				workflowMotifAndConfirmation$ = this.workflowService.selectWorkflowMotif('com.notilus.data.vehicule.cotation.DemandeVehicule').pipe(
					switchMap(({ isMotifAvailable,isCancelled,workflowMotif }) => {
						return combineLatest([of(workflowMotif),!isMotifAvailable && !isCancelled ? confirmation$ : of(!isCancelled)]);
					})
				);
			} else
				//Aucun motif
				workflowMotifAndConfirmation$ = combineLatest([of(null),confirmation$]);

			//Affichage d'un message de confirmation
			workflowMotifAndConfirmation$.pipe(
				first(),
				filter(([,isConfirmed]) => !!isConfirmed),
				switchMap(([workflowMotif]) => this.doUpdateListeDemandesVehicule(action,{
					typeActionMasse: 'SELECTION',
					listeIdObjects: [demandeVehicule.idDemandeVehicule],
					data: workflowMotif != null ? Object.assign(workflowMotif,{
						_type: 'com.notilus.data.workflow.history.WorkflowHistoryMotif'
					}) : undefined
				})),
				finalize(() => subject.complete())
			).subscribe({
				next: (result: Result) => {
					//Vérification du code d'erreur
					if (result.codeErreur == 0 && result.data && (!result.data.listeIgnoredIds || result.data.listeIgnoredIds.length === 0)) {
						//Message d'information
						this.toastrService.success(this.translateService.instant(`demandeVehicule.actions.${action}.success`));

						//Définition du résultat
						subject.next(result);
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant(`actions.enregistrement.error`));
					}
				}
			});
		}

		//Retour du sujet
		return subject;
	}

	/**
	 * Vérification de la validité de l'action
	 */
	public isActionValid(demandeVehicule: any,action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER' | 'SUPPRIMER' | 'LIVRER'): boolean {
		let statut;
		let isValid;

		//Récupération du statut de la demande
		statut = demandeVehicule.statut;

		//Vérification de l'action et du statut
		switch (action) {
		case 'TRANSMETTRE':
			//Transmission
			isValid = statut == 'EN_COURS' || statut == 'REJETEE';
			break;
		case 'SUPPRIMER':
			//Suppression
			isValid = statut == 'EN_COURS' || statut == 'REJETEE' || statut == 'ANNULEE' && !demandeVehicule.vehicule;
			break;
		case 'RAPPELER':
			//Rappel
			isValid = statut == 'A_VALIDER';
			break;
		case 'VALIDER':
			//Modification
			isValid = statut == 'A_VALIDER';
			break;
		case 'LIVRER':
			//Modification
			isValid = statut == 'EN_COMMANDE';
			break;
		case 'REJETER':
			//Modification
			isValid = statut == 'A_VALIDER';
			break;
		case 'COMMANDER':
			//Commande
			isValid = statut == 'EN_COURS' || statut == 'REJETEE' || statut == 'VALIDEE';
			break;
		case 'ANNULER':
			//Annulation
			isValid = statut == 'VALIDEE' || statut == 'EN_COMMANDE';
			break;
		default:
			//Action par défaut
			isValid = false;
			break;
		}

		//Retour de l'indicateur de validité de l'action
		return isValid;
	}

	/**
	 * Exécution de la livraison d'un véhicule
	 */
	public doLivraisonForDemandeVehicule(livraison: any): Observable<{ result: Result,isDismissAllowed: boolean}> {
		let subject: Subject<{ result: Result,isDismissAllowed: boolean}> = new Subject();
		let messaging$: MessagingObservables;
		let setTimeoutLenteur: (delayMillis: number) => any;
		let lenteurTimeout: any;
		let refProgress: string = null;
		let isDismissAllowed: boolean = false;

		//Définition d'un générateur de timeout pour la détection d'une lenteur du traitement
		setTimeoutLenteur = delayMillis => setTimeout(() => {
			//Signalement de l'anomalie
			refProgress && this.progressService.updateProgress(refProgress,{
				canDismiss: true,
				message: this.translateService.instant('demandeVehicule.livraison.anomalieLenteur')
			});

			//Lenteur détectée
			isDismissAllowed = true;
		},delayMillis);

		//Initialisation de la progression
		refProgress = this.progressService.init({
			icone: 'add_shopping_cart',
			libelle: this.translateService.instant('demandeVehicule.livraison.progressTitle'),
			nbTotal: 0,
			options: {
				canDismiss: false
			}
		});

		//Déclenchement initial du détecteur de lenteur
		lenteurTimeout = setTimeoutLenteur(20 * 1000);

		//Enregistrement de la livraison pour la demande de véhicule par websocket
		messaging$ = this.messagingService.init({
			method: 'PUT',
			entryPoint: `controller/VehiculeCommande/doLivraisonForDemandeVehicule`,
			outputPoint: '/messaging/vehiculeCommande/doLivraisonForDemandeVehicule/status',
			params: livraison
		}).onMessage({
			next: message => {
				//Annulation du détecteur de lenteur
				clearTimeout(lenteurTimeout);

				//Vérification du message
				if (message.data?.message) {
					//Ajout du message à la liste
					this.progressService.updateProgress(refProgress,{
						message: message.data.message
					});
				}

				//Vérification du type de message
				if (message.type == 'LOG')
					//Redéclenchement du détecteur de lenteur jusqu'au message suivant
					lenteurTimeout = setTimeoutLenteur(30 * 1000);
			}
		}).onFinish({
			next: message => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Annulation du détecteur de lenteur
				clearTimeout(lenteurTimeout);

				//Actualisation des données de progression
				refProgress && this.progressService.refreshProgress(refProgress,1,0,1);

				//Retour du résultat
				subject.next({
					result: message.data as Result,
					isDismissAllowed
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Annulation du détecteur de lenteur
				clearTimeout(lenteurTimeout);

				//Actualisation des données de progression
				refProgress && this.progressService.refreshProgress(refProgress,1,1,1);

				//Transmission de l'erreur
				subject.error(null);
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Sauvegarde du financement de la demande de véhicule
	 */
	public saveDemandeVehiculeFinancement(idDemandeVehicule: number,financement: any): Observable<Result> {
		//Sauvegarde du financement de la demande de véhicule
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveDemandeVehiculeFinancement/${idDemandeVehicule}`,financement);
	}

	/**
	 * Création du véhicule pour la demande de véhicule
	 */
	public doCreationForDemandeVehicule(creation: any): Observable<Result> {
		//Création du véhicule pour la demande de véhicule
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/doCreationForDemandeVehicule`,creation);
	}

	/**
	 * Récupération de la liste des types d'ajout de financement possible
	 */
	public retrieveListeTypesAjoutFinancement(demandeVehicule: any): Observable<Array<Action>> {
		let listeTypesAjoutSubject = new Subject<Array<any>>();
		let pageCotations$: Observable<Page<any>>;
		let pageFinancement$: Observable<Page<any>>;

		//Définition de la requête de récupération des cotations de la demande de véhicule
		pageCotations$ = this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'creation') ? this.cotationService.filtreCotationsForDemandeVehicule(demandeVehicule?.idDemandeVehicule || 0) : of(null);

		//Définition de la requête de récupération des financements associés au modèle de la grille d'attribution
		pageFinancement$ = this.grilleAttributionModeleService.filtreFinancementsForGrilleAttributionModele(demandeVehicule?.grilleAttribution?.idGrilleAttribution || 0,demandeVehicule?.modele?.idModele || 0);

		//Appel en parallèle des observables
		combineLatest([pageCotations$,pageFinancement$]).pipe(first()).subscribe({
			next: ([pageCotations,pageFinancements]) => {
				let listeTypesAjoutFinancement = new Array<Action>();
				let cotation: any;

				//Vérification de la présence d'une cotation
				if (pageCotations?.totalElements > 0) {
					//Récupération de la cotation
					cotation = pageCotations.content[0];

					//Vérification du statut de la cotation
					if (['RETOUR_PARTIEL','RETOUR_COMPLET'].indexOf(cotation.statut) > -1) {
						//Ajout de l'option de sélection d'une proposition fournisseur
						listeTypesAjoutFinancement.push({
							icon: 'compare',
							libelle: this.translateService.instant('demandeVehicule.financement.selectReponseCotation'),
							doAction: () => {
								let actionSubject: Subject<any> = new Subject();

								//Affichage de l'outil de comparaison
								this.cotationService.showComparaisonForCotation(cotation).subscribe({
									next: (result) => {
										//Emission d'une valeur
										actionSubject.next({ type: TypeAction.SELECT_COTATION,demandeVehicule: result?.demandeVehicule });

										//Fin de traitement
										actionSubject.complete();
									}
								});

								//Retour de l'observable
								return actionSubject.asObservable();
							}
						});
					}
				} else if (this.rightService.hasRight(TypeDroit.DEMANDE_COTATION,'creation') && demandeVehicule.modele) {
					//Ajout de l'option de création d'une demande de cotation
					listeTypesAjoutFinancement.push({
						icon: 'compare',
						libelle: this.translateService.instant('demandeVehicule.financement.createDemandeCotation'),
						doAction: () => {
							let actionSubject: Subject<any> = new Subject();
							let demandeCotation;

							//Enregistrement de la demande de cotation
							this.demandeCotationService.saveDemandeCotation({ origine: 'DEMANDE_VEHICULE' }).subscribe({
								next: (result) => {
									//Vérification du code d'erreur
									if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
										//Récupération de la demande
										demandeCotation = result.data.demandeCotation;

										//Définition d'une cotation associée au modèle de la demande de véhicule
										cotation = {
											modele: demandeVehicule.modele,
											demandeCotation: demandeCotation,
											demandeVehicule: demandeVehicule
										};

										//Enregistrement de la cotation
										this.cotationService.saveCotation(cotation).subscribe({
											next: (result) => {
												//Vérification du résultat
												if (result && result.codeErreur == 0) {
													//Message d'information
													this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

													//Emission d'une valeur
													actionSubject.next({ type: TypeAction.CREATE_DEMANDE_COTATION,cotation: result.data?.cotation })

													//Fin de traitement
													actionSubject.complete();
												} else {
													//Message d'erreur
													this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

													//Emission d'une erreur
													actionSubject.error(null);

													//Fin de traitement
													actionSubject.complete();
												}
											}
										});
									} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
										//Message d'erreur
										this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));

										//Emission d'une erreur
										actionSubject.error(null);

										//Fin de traitement
										actionSubject.complete();
									} else {
										//Message d'erreur
										this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

										//Emission d'une erreur
										actionSubject.error(null);

										//Fin de traitement
										actionSubject.complete();
									}
								}
							});

							//Retour de l'observable
							return actionSubject.asObservable();
						}
					});
				}

				//Vérification de la présence de financements
				if (pageFinancements?.totalElements > 0) {
					//Ajout de l'option de sélection d'un financement validé
					listeTypesAjoutFinancement.push({
						icon: 'check',
						libelle: this.translateService.instant('demandeVehicule.financement.selectValidatedFinancement'),
						doAction: () => {
							let actionSubject: Subject<any> = new Subject();

							//Affichage de la comparaison des financements
							this.showComparaisonForListeFinancements(pageFinancements.content,demandeVehicule).subscribe({
								next: (result) => {
									//Emission d'une valeur
									actionSubject.next({ type: TypeAction.SELECT_FINANCEMENT,financement: result?.financement });

									//Fin de traitement
									actionSubject.complete();
								}
							});

							//Retour de l'observable
							return actionSubject.asObservable();
						}
					});
				}

				//Ajout de l'option par défaut de saisie d'un financement
				listeTypesAjoutFinancement.push({
					icon: 'edit',
					libelle: this.translateService.instant('demandeVehicule.financement.fillInExternalFinancement'),
					doAction: () => {
						//Retour du sujet
						return of({ type: TypeAction.CREATE_FINANCEMENT });
					}
				});

				//Poursuite du traitement
				listeTypesAjoutSubject.next(listeTypesAjoutFinancement);

				//Fin de traitement
				listeTypesAjoutSubject.complete();
			}
		});

		//Retour de l'observable
		return listeTypesAjoutSubject.asObservable();
	}

	/**
	 * Affichage de la pop-up de sélection du type d'ajout de financement
	 */
	public selectActionAjoutFinancement(listeActions: Array<Action>): Observable<Action> {
		let bsModalRef: BsModalRef<DemandeVehiculeFinancementSelectionComponent>;

		//Affichage de la Pop-up de sélection du type d'ajout de financement
		bsModalRef = this.bsModalService.show(DemandeVehiculeFinancementSelectionComponent,{
			initialState: {
				listeActions
			},
			class: 'modal-lg',
			backdrop: 'static'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content.result?.action),
			filter(action => !!action)
		);
	}

	/**
	 * Mise à jour de la demande de vehicule (Workflow)
	 */
	private doUpdateListeDemandesVehicule(action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER',actionMasse: ActionMasse): Observable<Result> {
		//Mise à jour en masse d'une liste de demandes de véhicule
		return this.updateListeDemandesVehicule(action,actionMasse).pipe(first());
	}

	/**
	 * Mise à jour en masse d'une liste de demandes de véhicule
	 */
	private updateListeDemandesVehicule(action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER',actionMasse: ActionMasse): Observable<Result> {
		//Mise à jour en masse d'une liste de demandes de véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/updateListeDemandesVehicule/${action}`,actionMasse);
	}

	/**
	 * Affichage de l'ajout d'un modèle à une demande de véhicule
	 */
	public showAddModele(demandeVehicule: any,options: { isGrilleActiveAvailable: boolean,user: User,grilleAttributionSpecifique: any }): Observable<any> {
		//Retour du traitement
		return defer(() => {
			//Vérification de la présence d'une grille d'attribution active (hors spécifique)
			if (options.isGrilleActiveAvailable) {
				//Ouverture de la pop-up de sélection des modèles via les grilles d'attribution
				return this.showGrilleAttribution(demandeVehicule,options.grilleAttributionSpecifique);
			} else {
				//Retour du traitement
				return defer(() => {
					//Vérification de la présence d'une grille d'attribution spécifique
					if (options.grilleAttributionSpecifique) {
						//Ouverture de la pop-up de sélection des modèles via les grilles d'attribution
						return this.showGrilleAttribution(demandeVehicule,options.grilleAttributionSpecifique);
					} else {
						//Retour du traitement
						return defer(() => {
							//Vérification du droit de création sur la grille d'attribution
							if (this.rightService.hasRight(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION,'creation'))
								//Proposition à l'utilisateur de choisir entre une grille d'attribution spécifique ou la recherche avancée
								return this.showChoixGrilleAttributionModele(demandeVehicule,options.user);
							else
								//Affichage de la recherche avancée
								return this.showAdvancedSearchForModele(options.user).pipe(map((modele: any) => ({ modele })));
						});
					}
				})
			}
		}).pipe(filter(({ modele }: { modele: any,grilleAttribution: any }) => !!modele));
	}

	/**
	 * Affichage de la popup de recherche des modèles
	 */
	public showAdvancedSearchForModele(user: User,subject: Subject<any> = new Subject<any>(),advancedSearchOptions: AdvancedSearchOptions = null): Observable<any> {
		let successiveSearchOptions: SuccessiveSearchOptions;
		let isInit: boolean;

		//Définition de la necéssité d'initialiser la recherche avancée
		isInit = advancedSearchOptions == null;

		//Récupération des options de la recherche avancée
		advancedSearchOptions = advancedSearchOptions || this.getAdvancedSearchOptionsForModele(user);

		//Affichage de la recherche avancée
		this.advancedSearchService.showAdvancedSearch(advancedSearchOptions,isInit).subscribe({
			next: (searchSpec: any) => {
				//Vérification d'un retour
				if (searchSpec) {
					//Récupération des options de la recherche successive
					successiveSearchOptions = this.getSuccessiveSearchOptionsForModele(user,searchSpec);

					//Sélection unique
					successiveSearchOptions.isSingleResult = true;

					//Affichage de la recherche successive
					this.successiveSearchService.showSuccessiveSearch(successiveSearchOptions,{ modalClass: 'modal-max' }).subscribe({
						next: result => {
							let listeSelections;

							//Vérification du résultat
							if (result) {
								//Vérification de la fermeture sans sélection
								if (result.isDismissed) {
									//Ré-ouverture de la pop-up de sélection des filtres
									this.showAdvancedSearchForModele(user,subject,advancedSearchOptions);
								} else if (result.listeSelectionsItems?.length > 0) {
									//Défintion de la sélection d'éléments
									listeSelections = {
										_type: 'com.notilus.model.search.AggregatedItemSelection',
										listeSelectionsByAggregator: result.listeSelectionsItems,
										searchSpec,
										nbSelectedItems: result.nbSelectedItems
									};

									//Retour de la sélection
									subject.next(listeSelections);

									//Fin de traitement
									subject.complete();
								}
							} else {
								//Emission d'une erreur
								subject.error(null);

								//Fin de traitement
								subject.complete();
							}
						},
						error: (erreur) => {
							//Transmission de l'erreur
							subject.error(erreur);

							//Fin de traitement
							subject.complete();
						}
					});
				} else {
					//Fin de traitement
					subject.complete();
				}
			},
			error: (erreur) => {
				//Transmission de l'erreur
				subject.error(erreur);

				//Fin de traitement
				subject.complete();
			}
		});

		//Retour de l'observable
		return subject.asObservable().pipe(
			map((result: any) => result.listeSelectionsByAggregator[0]),
			filter((modele: any) => !!modele)
		);
	}

	/**
	 * Récupération des options de recherche avancée
	 */
	private getAdvancedSearchOptionsForModele(user: User): AdvancedSearchOptions {
		//Retour des options de la recherche avancée
		return {
			title: this.translateService.instant('searchEngine.elements.grilleAttribution.title'),
			subTitle: this.translateService.instant('searchEngine.elements.grilleAttribution.subTitle'),
			results: this.translateService.instant('searchEngine.elements.grilleAttribution.result'),
			uri: `/controller/VehiculeReferentiel/listeModelesByAgregation`,
			listeFiltres: [{
				searchKey: 'marque.libelle',
				type: 'autocomplete',
				libelleType: 'marque',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.marque.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.marque.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.marque.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/MARQUE',
					listeFilters: () => [{
						clef: 'marque.libelle',
						isDefault: true
					}],
					displayItem: (item) => item?.libelleGroupe || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'idGroupe',
					type: 'advanced-search-marque'
				}
			},{
				searchKey: 'modeleNiveau2,marque.libelle',
				type: 'autocomplete',
				libelleType: 'modele',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.modele.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.modele.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleParent + ' ' + item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleParent
						},{
							key: 'modeleNiveau2',
							keyIndex: 'modeleNiveau2.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.modele.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/NIVEAU2',
					listeFilters: () => [{
						clef: 'modeleNiveau2',
						isDefault: true
					},{
						clef: 'marque.libelle',
						isDefault: true
					}],
					displayItem: (item) => item && `${item.libelleParent} ${item.libelleGroupe}` || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'libelleGroupe',
					type: 'advanced-search-modele'
				}
			},{
				searchKey: 'finition,marque.libelle',
				type: 'autocomplete',
				libelleType: 'finition',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.finition.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.finition.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleParent + ' ' + item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleParent
						},{
							key: 'finition',
							keyIndex: 'finition.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.finition.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/FINITION',
					listeFilters: () => [{
						clef: 'finition',
						isDefault: true,
						typeComparaison: TypeComparaison.LIKE
					},{
						clef: 'marque.libelle',
						isDefault: true,
						typeComparaison: TypeComparaison.LIKE
					}],
					displayItem: (item) => item && `${item.libelleParent} ${item.libelleGroupe}` || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'libelleGroupe',
					type: 'advanced-search-finition'
				}
			},{
				listeKeys: [{
					searchKey: 'information.commercialisationPrix.prixHT',
					aggregateKey: 'information.commercialisationPrix.prixHT',
					subType: 'HT'
				},{
					searchKey: 'information.commercialisationPrix.prixTTC',
					aggregateKey: 'information.commercialisationPrix.prixTTC',
					subType: 'TTC'
				}],
				type: 'numeric',
				maxPercent: 95,
				libelleType: 'prix',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'information.carburant.libelle',
				aggregateKey: 'information.carburant.libelle.raw',
				type: 'label',
				libelleType: 'carburant'
			},{
				searchKey: 'information.typeBoite.libelle',
				aggregateKey: 'information.typeBoite.libelle.raw',
				type: 'label',
				libelleType: 'typeBoite'
			},{
				searchKey: 'information.typeTransmission.libelle',
				aggregateKey: 'information.typeTransmission.libelle.raw',
				type: 'label',
				libelleType: 'typeTransmission',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				listeKeys: [{
					searchKey: 'information.commercialisationPrix.avantageNature.montantMensuelAvecCarburant',
					aggregateKey: 'information.commercialisationPrix.avantageNature.montantMensuelAvecCarburant',
					subType: 'AENMensuelAvecCarburant'
				},{
					searchKey: 'information.commercialisationPrix.avantageNature.montantMensuelSansCarburant',
					aggregateKey: 'information.commercialisationPrix.avantageNature.montantMensuelSansCarburant',
					subType: 'AENMensuelSansCarburant'
				}],
				type: 'numeric',
				maxPercent: 95,
				libelleType: 'AENMensuel',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'information.motorisation.puissanceFiscale',
				aggregateKey: 'information.motorisation.puissanceFiscale',
				type: 'numeric',
				libelleType: 'puissanceFiscale',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'nbPortes',
				aggregateKey: 'nbPortes',
				type: 'numeric',
				libelleType: 'nbPortes'
			}]
		};
	}

	/**
	 * Récupération des options de recherche successive
	 */
	private getSuccessiveSearchOptionsForModele(user: User,searchSpec: any): SuccessiveSearchOptions {
		//Retour des options de la recherche successive
		return {
			title: this.translateService.instant('searchEngine.elements.grilleAttribution.title'),
			typeSearchLevel: 'MARQUE',
			search: searchSpec,
			isShowFilter: true,
			groupeKey: 'finition',
			idItemKey: 'idModele',
			listeSearchLevels: [{
				type: 'MARQUE',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.marque'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/MARQUE`,
				getListeFilters: () => [{
					clef: 'marque.libelle',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'marque.idMarque',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'LONG'
					}
				},
				getListeFiltersExtended(options) {
					//Retour des filtres
					return options?.search?.listeFilter || [];
				}
			},{
				type: 'MODELE_NIVEAU_2',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.modele'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/NIVEAU2`,
				getListeFilters: () => [{
					clef: 'modeleNiveau2',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getListeFiltersExtended(options,listeCumulatedFilters) {
					//Retour des filtres
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,1)).concat(options?.search?.listeFilter);

					//Retour des filtres
					return listeFilters;
				},
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'modeleNiveau2',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'STRING'
					}
				}
			},{
				type: 'FINITION',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.finition'),
				emptyLibelle: this.translateService.instant('searchEngine.libelleMissing.nonCommuniquee'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/FINITION`,
				getListeFilters: () => [{
					clef: 'finition',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					//Retour des filtres
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,2)).concat(options?.search?.listeFilter);

					//Retour des filtres
					return listeFilters;
				},
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'finition',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'STRING'
					}
				},
				listeActions: [{
					isSecondary: true,
					isVisible: (options: SuccessiveSearchOptions) => !options.isSingleResult,
					isValid: (_,listeSelectionsItems: Array<any>) => listeSelectionsItems.length == 1,
					libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.afficherOptionsSerie'),
					onPress: this.showListeOptionsModele
				}]
			},{
				type: 'VEHICULE',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.motorisation'),
				uri: () => `/controller/VehiculeReferentiel/filtreModeles/`,
				getListeFilters: () => [{
					clef: 'marque.libelle',
					isDefault: true
				},{
					clef: 'libelle',
					isDefault: true
				},{
					clef: 'reference',
					isDefault: true
				}],
				defaultOrder: 'information.commercialisationPrix.prixTTC,libelle.raw',
				isListView: true,
				getTitle: this.getTitleForModele.bind(this),
				getAvatar: (item) => item.reference.substring(0,3).toUpperCase(),
				getListeItemAttributes: this.getListeAttributesForModele.bind(this),
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,3)).concat(options?.search?.listeFilter);

					//Ajout du filtre sur le type de source
					listeFilters.push({
						clef: 'typeSource.keyword',
						valeur: 'EXTERNE_CATALOGUE',
						typeComparaison: 'EQUAL',
						type: 'STRING'
					});

					//Ajout du filtre sur le statut actif
					listeFilters.push({
						clef: 'actif',
						valeur: true,
						typeComparaison: 'EQUAL',
						type: 'BOOLEAN'
					});

					//Ajout du filtre sur la commercialisation en cours
					listeFilters.push({
						clef: 'information.commercialisationPrix.commercialisationEnCours',
						valeur: true,
						typeComparaison: 'EQUAL',
						type: 'BOOLEAN'
					});

					//Retour des filtres sur la marque, le modèle et la finition adaptés pour ElasticSearch
					return listeFilters.map(filter => {
						//Vérification du type de filtre
						if (filter.type == 'STRING' && filter.clef.slice(-8) != '.keyword') {
							//Ajout du mot clé 'raw' à la clef
							filter.clef += '.raw';
						}
						//Retour du filtre mis en forme
						return filter;
					});
				}
			}]
		};
	}

	/**
	 * Récupération du titre d'un élément de la liste des modèles
	 */
	public getTitleForModele(item: any): string {
		//Retour du titre
		return `${item.libelle} - ${
			item.information?.genre?.modeAffichagePrix == 'TTC' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeTTC',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixTTC,'.2-2',item.information.commercialisationPrix.devise) }) :
				(item.information?.genre?.modeAffichagePrix == 'HT' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeHT',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixHT,'.2-2',item.information.commercialisationPrix.devise) }) : '')
		}`;
	}

	/**
	 * Récupération des attributs d'un modèle
	 */
	private getListeAttributesForModele(item: any): Array<{ key: string,value: string }> {
		//Retour de la liste des attributs
		return [item.information?.carburant && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.carburant'),
			value: item.information?.carburant?.libelle
		},item.information?.typeBoite && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.boiteVitesse'),
			value: item.information?.typeBoite?.libelle
		},item.information?.motorisation && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.puissanceFiscale'),
			value: item.information?.motorisation?.puissanceFiscale
		}].filter(i => !!i);
	}

	/**
	 * Affichage de la liste des options d'un modèle
	 */
	private showListeOptionsModele(options: SuccessiveSearchOptions,listeCumulatedFilters: Array<any>) {
		let searchInfo: any;
		let searchSpec: any;

		//Récupération des informations nécessaires pour recherche des modèles
		searchInfo = options.listeSearchLevels?.find(s => s.type == 'VEHICULE');

		//Création de la recherche associée à la motorisation sélectionnée
		searchSpec = {
			defaultOrder: searchInfo.defaultOrder,
			listeFilter: searchInfo.getListeFiltersExtended(options,listeCumulatedFilters),
			nbObjetsParPage: 1,
			numPage: 0
		}

		//Ouverture de la pop-up
		this.bsModalService.show(OptionComponent,{
			initialState: {
				searchSpec
			},
			backdrop: 'static',
			class: 'modal-max'
		});
	}

	/**
	 * Ouverture de la popup de choix pour la sélection d'un modèle
	 */
	private showChoixGrilleAttributionModele(demandeVehicule: any,user: User): Observable<{ modele: any,grilleAttribution: any }> {
		let bsModalRef: BsModalRef<DemandeVehiculeChoixGrilleAttributionModeleComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(DemandeVehiculeChoixGrilleAttributionModeleComponent);

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.choix),
			filter(choix => !!choix),
			switchMap((choix) => {
				//Vérification du choix effectué
				if (choix == 'MODELE') {
					//Affichage de la recherche d'un modèle
					return this.showAdvancedSearchForModele(user).pipe(
						map((modele: any) => ({ modele }))
					);
				} else {
					//Enregistrement et navigation vers la grille d'attribution spécifique
					return defer(() => this.saveGrilleAttributionSpecifique(demandeVehicule).pipe(
						map((grilleAttribution: any) => {
							//Navigtation vers la grille d'attribution spécifique
							this.layoutService.goToByState('listeGrillesAttribution-grilleAttribution',{
								routeParams: {
									idGrilleAttribution: grilleAttribution.idGrilleAttribution
								},
								withGoBack: true
							});

							//Aucun retour
							return null;
						})
					))
				}
			}),
			filter(({ modele }: { modele: any,grilleAttribution: any }) => !!modele)
		);
	}

	/**
	 * Ouverture de la popup de sélection de grille d'attribution
	 */
	private showGrilleAttribution(demandeVehicule: any,grilleAttributionSpecifique: any): Observable<{ modele: any,grilleAttribution: any }> {
		let bsModalRef: BsModalRef<DemandeVehiculeModeleSelectionComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(DemandeVehiculeModeleSelectionComponent,{
			initialState: {
				demandeVehicule,
				grilleAttributionSpecifique
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => ({ modele: bsModalRef.content?.result?.modele,grilleAttribution: bsModalRef.content?.result?.grilleAttribution })),
			filter(({ modele,grilleAttribution }: { modele: any,grilleAttribution: any }) => !!modele && !!grilleAttribution)
		);
	}

	/**
	 * Ouverture de la pop-up de comparaison des financements
	 */
	public showComparaisonForListeFinancements(listeFinancements: Array<any>,demandeVehicule): Observable<any> {
		let options: Options<any>;

		//Définition des options
		options = {
			typeAttachment: TypeAttachment.GRILLE_ATTRIBUTION_MODELE_FINANCEMENT,
			title: this.translateService.instant('demandeVehicule.financement.comparaison.title'),
			listeSections: [{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.fournisseur'),
				compare: (item: any) => {
					//Affichage du libellé fournisseur
					return { title: item.fournisseur.libelle };
				}
			},{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.validite.title'),
				compare: (item) => {
					//Affichage de la validité
					return { title: item.dateDebut ? this.translateService.instant('demandeVehicule.financement.comparaison.sections.validite.periode',{ debut: this.datePipe.transform(item.dateDebut),fin: this.datePipe.transform(item.dateFin) }) : this.translateService.instant('demandeVehicule.financement.comparaison.sections.validite.vide') };
				}
			},{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.financement'),
				compare: (item) => {
					//Affichage du type de financement
					return { title: item.grilleFluidite ? this.translateService.instant('demandeVehicule.financement.comparaison.typeFinancement.LLD') : item.typeFinancement.libelle };
				}
			},{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.loiRoulage'),
				compare: (item) => {
					//Vérification de l'élément
					if (item.grilleFluidite)
						//Retour du titre
						return { title: this.translateService.instant('demandeVehicule.financement.comparaison.typeFinancement.GRILLE_FLUIDITE') };
					else if (item.duree && item.distance)
						//Affichage de la loi de roulage
						return { title: this.translateService.instant('demandeVehicule.financement.comparaison.subTitleLoiRoulage',{ duree: item.duree,distance: this.decimalPipe.transform(item.distance),unite: this.user?.unite?.libelleCourt }) };
					else
						//Aucun affichage
						return null;
				}
			},{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.loyerMensuel'),
				compare: (item) => {
					//Vérification de la grille de fluidité
					if (item.grilleFluidite)
						//Retour du titre
						return { title: this.translateService.instant('demandeVehicule.financement.comparaison.typeFinancement.GRILLE_FLUIDITE') };
					else if (item.loyerMensuel)
						//Loyer mensuel
						return { title: `${this.currencyPipe.transform(item.loyerMensuel || 2,item.devise)} (${this.translateService.instant(`contrat.typeAffichagePrix.short.${item.typeAffichagePrix}`)})` };
					else
						//Aucun retour
						return null;
				}
			},{
				title: this.translateService.instant('common.piecesJointes'),
				compare: (item) => {
					//Retour des pièces jointes
					return {
						nbAttachments: item?.listeLinks?.length || 0
					}
				}
			},{
				title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.remarque'),
				compare: (item) => {
					//Vérification de la remarque
					if (item.remarque) {
						//Consultation de la remarque
						return {
							title: this.translateService.instant('actions.consulter'),
							showItem: item.remarque && ((item) => {
								//Affichage de la remarque
								this.comparatorService.showMoreInfo({
									title: this.translateService.instant('demandeVehicule.financement.comparaison.sections.remarque'),
									contentTitle: `${item?.reponseDemandeCotation?.contact?.nom} ${item.reponseDemandeCotation?.contact?.prenom} ${item.reponseDemandeCotation?.contact?.fournisseur?.libelle}`,
									contentSubTitle: this.translateService.instant('demandeVehicule.financement.comparaison.sections.remarque'),
									content: item.remarque
								});
							})
						};
					} else
						//Aucun retour
						return null;
				}
			}],
			listeActions: [{
				title: this.translateService.instant('actions.choisir'),
				type: 'primary',
				doAction: (item,onDataChange) => {
					//Exécution de l'action
					this.executeActionForFinancement('CHOISIR',item).subscribe({
						next: (financement: any) => {
							//Vérification du résultat
							if (financement) {
								//Vérification de la grille de fluidité du financement
								if (financement.grilleFluidite) {
									//Affichage de la grille de fluidité
									this.grilleFluiditeService.showGrilleFluidite(financement.grilleFluidite,{ disableDeletion: true,distanceMensuelle: demandeVehicule?.distanceMensuelleEstimee || 0,canAddDetail: true }).subscribe({
										next: (result: any) => {
											//Vérification de la présence d'un détail
											if (result?.detail) {
												//Suppression des actions
												options.listeActions = null;

												//Mise à jour des attributs
												Object.assign(financement,{
													typeFinancement: financement.typeFinancementLLD,
													duree: result.detail.duree,
													distance: result.detail.distance,
													loyerMensuel: result.detail.loyer,
													coutDistance: result.detail.coutKilometrique
												});

												//Duplication de la grille de fluidité
												this.grilleFluiditeService.duplicateGrilleFluidite(financement.grilleFluidite.idGrilleFluidite,'DEMANDE_VEHICULE',demandeVehicule.idDemandeVehicule).subscribe({
													next: (result: Result) => {
														//Mise à jour de la grille de fluidité
														Object.assign(financement.grilleFluidite,result.data.grilleFluidite);

														//Notification des changements
														onDataChange.next({
															financement,
															options,
															listeItems: listeFinancements,
															canHide: true
														});
													}
												});
											}
										}
									});
								} else {
									//Suppression des actions
									options.listeActions = null;

									//Notification des changements
									onDataChange.next({
										financement,
										options,
										listeItems: listeFinancements,
										canHide: true
									});
								}
							}
						}
					});
				},
				isValid: () => true,
				isVisible: () => true
			}],
			isSelected: () => false
		};

		//Affichage de la pop-up de comparaison
		return this.comparatorService.showComparateur<any>(options,listeFinancements);
	}

	/**
	 * Création d'une grille d'attribution spécifique
	 */
	saveGrilleAttributionSpecifique(demandeVehicule: any): Observable<any> {
		let grilleAttribution: any;

		//Définition de la grille d'attribution
		grilleAttribution = {
			specifique: true,
			actif: true,
			demandeVehicule: demandeVehicule,
			libelle: this.translateService.instant('demandeVehicule.catalogueSpecifique',{ idDemandeVehicule: demandeVehicule.idDemandeVehicule }),
			reference: `DV_${demandeVehicule.idDemandeVehicule}`
		}

		//Enregistrement de la grille d'attribution spécifique
		return this.grilleAttributionService.saveGrilleAttribution(grilleAttribution).pipe(
			map((result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(grilleAttribution,result.data?.grilleAttribution);

					//Retour de la grille d'attribution
					return grilleAttribution;
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon)
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				return null;
			}),
			filter(grilleAttribution => !!grilleAttribution)
		);
	}

	/**
	 * Exécution de l'action sur une sélection de financement
	 */
	private executeActionForFinancement(action: string,financement: any): Observable<any>{
		//Demande de confirmation
		return this.confirmService.showConfirm(this.translateService.instant(`demandeVehicule.actions.${action}.confirmation`),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => of(financement))
		);
	}
}