import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-confort',
	templateUrl: './modele-confort.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleConfortComponent {
	/** Confort **/
	@Input() confort: any;
}
