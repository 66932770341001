import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DemandeCotationCotationListItemComponent } from './demande-cotation-cotation-list-item.component';
import { DemandeCotationListItemComponent } from './demande-cotation-list-item.component';
import { DemandeCotationListComponent } from './demande-cotation-list.component';
import { DemandeCotationService } from './demande-cotation.service';
import { DemandeCotationComponent } from './demande-cotation.component';
import { DemandeCotationCotationListComponent } from './demande-cotation-cotation-list.component';
import { DemandeCotationCotationEditComponent } from './demande-cotation-cotation-edit.component';
import { DemandeCotationReponseCotationComponent } from './demande-cotation-reponse-cotation.component';
import { DemandeCotationOptionPayanteListComponent } from './demande-cotation-option-payante-list.component';
import { DemandeCotationContentModule } from './content/demande-cotation-content.module';
import { ReponseDemandeCotationContentModule } from '../reponse-cotation/content/reponse-demande-cotation-content.module';
import { OptionPayanteListComponent } from './option-payante-list.component';

@NgModule({
	imports: [ShareModule,DemandeCotationContentModule,ReponseDemandeCotationContentModule],
	declarations: [DemandeCotationListComponent,DemandeCotationListItemComponent,DemandeCotationComponent,DemandeCotationCotationListComponent,DemandeCotationCotationListItemComponent,DemandeCotationCotationEditComponent,DemandeCotationReponseCotationComponent,DemandeCotationOptionPayanteListComponent,OptionPayanteListComponent],
	providers: [DemandeCotationService]
})
export class DemandeCotationModule {}