<form autocomplete="off">
	<div class="card detail">
		<div class="card-body">
			<h4><span translate>structureExport.listeCompteurs.titre</span></h4>

			<div class="listview lv-bordered">
				<div class="lv-body">
					<div class="lv-item media" *ngFor="let compteur of detail.listeCompteurs; let index = index;">
						<div class="pull-left lv-avatar">{{ compteur.libelle.substring(0,3).toUpperCase() }}</div>
						<div class="media-body">
							<div class="lv-title">
								<a [routerLink]="[]" (click)="showCompteur(compteur,index);">{{ compteur.libelle }}</a>
							</div>
							<ul class="lv-attrs">
								<li><span translate>structureExport.listeCompteurs.compteur.valeurInitiale</span>&#160;:&#160;<span>{{ compteur.valeurInitiale | number:'.2-2' }}</span></li>
								<li><span translate>structureExport.listeCompteurs.compteur.increment</span>&#160;:&#160;<span>{{ compteur.increment | number:'.2-2' }}</span></li>
							</ul>
							<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && !isRestricted">
								<a [routerLink]="[]" (click)="deleteCompteur(index)"><icon name="delete"></icon></a>
							</div>
						</div>
					</div>
					<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="showCompteur();" *ngIf="(null | right:'creation') && !isRestricted">
						<h5><a [routerLink]="[]" translate>structureExport.listeCompteurs.ajouterCompteur</a></h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>