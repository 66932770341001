import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { TypeDroit } from 'src/app/domain/security/right';
import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './planning-affectation.component.html'
})
export class PlanningAffectationComponent implements OnInit {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Affectation en modification **/
	@Input() affectation: any;

	/** Données de sortie de la pop-up **/
	savedData: { affectation: any,idAffectationClosed?: number };

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef,private affectationService: AffectationService,private store: Store<AppState>,private layoutService: LayoutService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	showVehicule(affectation: any) {
		//Fermeture de la popup
		this.bsModalRef.hide();

		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: affectation.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Fermeture de l'affectation après enregistrement
	 */
	onContentClose(data: any) {
		//Vérification de la présence de données
		if (data) {
			//Chargement complet de l'affectation
			this.affectationService.loadAffectation(data.affectation.idAffectation).subscribe(result => {
				//Définition de l'affectation obtenue
				this.savedData = {
					affectation: result.data.affectation,
					idAffectationClosed: data.idAffectationClosed
				};

				//Fermeture de la pop-up
				this.bsModalRef.hide();
			});
		} else {
			//Indication de la suppression de l'affectation
			this.savedData = { affectation: null };

			//Fermeture de la pop-up
			this.bsModalRef.hide();
		}
	}
}