import { TypeAmenagementAddComponent } from 'src/app/components/vehicule/type-amenagement/type-amenagement-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types d'aménagement
 */
export default class TypeAmenagementOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.typeAmenagement';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeReferentiel/listeTypesAmenagementActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle}${item?.reference ? ' (' + item.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,2)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.TypeAmenagement';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTypeAmenagement'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT;

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'vehicule.typeAmenagement.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return TypeAmenagementAddComponent; }
}