import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';

@Component({
	templateUrl: './plan-comptable-compte-list-item.component.html',
})
export class PlanComptableCompteListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression d'un compte
	 */
	deleteCompte() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteService.deleteCompte(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression du compte collectif
				this.onObjectChange(null);
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Accès au compte
	 */
	showCompte() {
		//Redirection vers le compte
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptes-compte',{
			routeParams: {
				idCompte: this.data?.idCompte || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.comptabilite.Compte',this.data.idCompte);
	}
}