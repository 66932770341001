import { DecimalPipe } from '@angular/common';
import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { sortBy,uniq } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { filter,map,switchMap,tap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { GrilleFluiditeDetailComponent } from './grille-fluidite-detail.component';
import { GrilleFluiditeService } from './grille-fluidite.service';


@Component({
	templateUrl: './grille-fluidite.component.html'
})
export class GrilleFluiditeComponent implements OnInit {
	/** Grille de fluidité **/
	@Input() grilleFluidite: any;

	/** Options d'affichage **/
	@Input() options: { disableDeletion?: boolean,loiRoulage?: any,lastEcheance?: any,distanceMensuelle?: number,distanceVehicule?: number,dureeContrat?: number,canAddDetail?: boolean };

	/** Liste des modes d'affichage **/
	listeModesAffichage: Array<{ code: string,libelle: string }>;

	/** Liste des distances **/
	listeDistances: Array<number>;

	/** Liste des durées **/
	listeDurees: Array<number>;

	/** Mode d'affichage **/
	modeAffichage: 'LOYER' | 'PRK' | 'DISTANCE_MENSUELLE' | 'COUT_TOTAL' | 'VARIATION';

	/** Distance à mettre en avant **/
	distanceMensuelleToHighlight: number = 0;

	/** Regroupement des données **/
	mapDetails: { [key: string]: any } = {};

	/** Enumération des types de droit **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Navigation **/
	navigation: { position: number } = { position: 0 };

	/** Résultat du traitement **/
	result: { isDeleted?: boolean,detail?: any };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<GrilleFluiditeComponent>,private grilleFluiditeService: GrilleFluiditeService,private cookieService: CookieService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private decimalPipe: DecimalPipe,private bsModalService: BsModalService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des modes d'affichage
		this.listeModesAffichage = this.grilleFluiditeService.getListeModesAffichage();

		//Définition du mode d'affichage sélectionné
		this.modeAffichage = this.cookieService.get('grilleFluidite.modeAffichage') as any || this.listeModesAffichage[0].code;

		//Définition de la distance mensuelle à mettre en avant
		this.distanceMensuelleToHighlight = this.options.distanceMensuelle || 0;

		//Initialisation de la grille de fluidité
		this.initGrilleFluidite();
	}

	/**
	 * Initialisation de la grille de fluidité
	 */
	private initGrilleFluidite() {
		let numEcheance: number = -1;

		//Vérification de la grille de fluidité
		if (this.grilleFluidite?.listeDetails?.length) {
			//Filtrage de la liste des modes d'affichage (affichage de la variation uniquement si il existe une loi de roulage)
			this.listeModesAffichage = this.listeModesAffichage.filter(modeAffichage => this.options?.loiRoulage || modeAffichage.code != 'VARIATION');

			//Récupération des durées
			this.listeDurees = sortBy(uniq(this.grilleFluidite.listeDetails.map(d => d.duree)));

			//Récupération des distances
			this.listeDistances = sortBy(uniq(this.grilleFluidite.listeDetails.map(d => d.distance)));

			//Vérification de la présence d'une loi de roulage
			if (this.options?.loiRoulage && this.options?.lastEcheance) {
				//Recherche de la position à afficher par rapport à la loi de roulage
				this.navigation.position = this.listeDistances.findIndex(distance => distance >= this.options.loiRoulage.distance) - 6;

				//Vérification de la position
				if (this.navigation.position < 0)
					//Réinitialisation de la position
					this.navigation.position = 0;

				//Calcul du numéro d'échéance (première échéance = 1)
				numEcheance = this.options.lastEcheance.ordreFinancement;
			}

			//Parcours des détails
			this.grilleFluidite.listeDetails.forEach(d => {
				//Vérification du numéro d'échéance
				if (numEcheance >= 0) {
					//Calcul du nombre d'échéances restantes
					d.nombreEcheancesRestantes = d.duree - numEcheance;

					//Calcul du coût restant
					d.coutRestant = d.nombreEcheancesRestantes * d.loyer;

					//Calcul du montant déjà payé
					d.montantDejaPaye = this.options.lastEcheance?.loyerTheoriqueCumul || 0;

					//Calcul de la régularisation
					d.regularisation = d.montantDejaPaye - d.loyer * numEcheance;

					//Coût total estimé
					d.coutTotalEstime = d.coutRestant + d.regularisation + d.montantDejaPaye;

					//Ecart (montant)
					d.ecartMontant = d.coutTotalEstime - this.options.loiRoulage.duree * this.options.loiRoulage.loyerMensuel;

					//Ecart (pourcentage)
					d.ecart = d.ecartMontant / (this.options.loiRoulage.duree * this.options.loiRoulage.loyerMensuel);
				}

				//Ajout du détail à la map
				this.mapDetails[d.duree + '_' + d.distance] = d;
			});

			//Mise à jour des niveaux
			this.onDistanceMensuelleToHighlightChange();
		}
	}

	/**
	 * Interception du changement du mode d'affichage
	 */
	onModeAffichageChange() {
		//Vérification du mode d'affichage
		if (this.modeAffichage != 'VARIATION')
			//Enregistrement du mode d'affichage
			this.cookieService.set('grilleFluidite.modeAffichage',this.modeAffichage);
	}

	/**
	 * Suppression de la grille de fluidité
	 */
	deleteGrilleFluidite() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.grilleFluiditeService.deleteGrilleFluidite(this.grilleFluidite.idGrilleFluidite))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Définition du résultat
				this.result = { isDeleted: true };

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Changement de distance à mettre en avant
	 */
	onDistanceMensuelleToHighlightChange() {
		let data: any;
		let ratio: number;

		//Parcours des données
		for (let keyData in this.mapDetails) {
			//Lecture de la donnée
			data = this.mapDetails[keyData];

			//Vérification de la distance mensuelle
			if (this.distanceMensuelleToHighlight) {
				//Calcul du ratio
				ratio = Math.abs(this.distanceMensuelleToHighlight - data.distanceMensuelle) / Math.abs(this.distanceMensuelleToHighlight);

				//Vérification du ratio
				if (ratio <= 0.1)
					//Définition du niveau
					data.level = 'high';
				else if (ratio <= 0.2)
					//Définition du niveau
					data.level = 'medium';
				else if (ratio <= 0.3)
					//Définition du niveau
					data.level = 'low';
				else
					//Aucun niveau
					data.level = null;
			} else
				//Aucune mise en avant
				data.level = null;
		}
	}

	/**
	 * Affichage d'un élément
	 */
	displayDataForItem(duree: number,distance: number): string {
		//Vérification du type d'affichage
		if (this.modeAffichage == 'LOYER')
			//Affichage du loyer
			return this.decimalPipe.transform(this.mapDetails[duree + '_' + distance].loyer,'.2-2');
		else if (this.modeAffichage == 'PRK')
			//Affichage du prix de revient kilométrique
			return this.decimalPipe.transform(this.mapDetails[duree + '_' + distance].coutKilometrique,'.3-3');
		else if (this.modeAffichage == 'DISTANCE_MENSUELLE')
			//Affichage de la distance mensuelle
			return this.decimalPipe.transform(this.mapDetails[duree + '_' + distance].distanceMensuelle,'.0-0');
		else if (this.modeAffichage == 'COUT_TOTAL')
			//Affichage du coût total
			return this.decimalPipe.transform(this.mapDetails[duree + '_' + distance].coutTotal,'.0-0');
		else if (this.modeAffichage == 'VARIATION')
			//Affichage de la variation
			return this.decimalPipe.transform(Math.round(100 * this.mapDetails[duree + '_' + distance].ecart),'.0-0') + '%';
		else
			//Aucun affichage
			return '';
	}

	/**
	 * Récupération du niveau de mise en avant d'une donnée
	 */
	getLevel(duree: number,distance: number): string {
		//Retour du niveau
		return (this.mapDetails[duree + '_' + distance].level || '') + (this.modeAffichage == 'VARIATION' ? (this.mapDetails[duree + '_' + distance].ecart <= 0 ? ' label-good' : ' label-bad') : '');
	}

	/**
	 * Affichage du détail pour une durée et une distance
	 */
	showDetail(duree: number,distance: number) {
		let bsModalRef: BsModalRef<GrilleFluiditeDetailComponent>;

		//Affichage du détail
		bsModalRef = this.bsModalService.show(GrilleFluiditeDetailComponent,{
			initialState: {
				detail: this.mapDetails[duree + '_' + distance],
				options: this.options,
				duree: duree,
				distance: distance,
				distanceMensuelleToHighlight: this.distanceMensuelleToHighlight,
				ecartDistanceToHighlight: (this.distanceMensuelleToHighlight - this.mapDetails[duree + '_' + distance].distanceMensuelle) / Math.abs(this.distanceMensuelleToHighlight),
				isWithVariation: this.mapDetails[duree + '_' + distance].nombreEcheancesRestantes >= 0 && !(this.options.distanceVehicule && distance < this.options.distanceVehicule || this.options.dureeContrat && duree < this.options.dureeContrat)
			}
		});

		//Détection de la fermeture de la popup
		bsModalRef.onHidden.pipe(
			map(() => this.result = { detail: bsModalRef.content.result?.detail }),
			tap(() => this.result.detail && this.bsModalRef.hide())
		).subscribe();
	}
}