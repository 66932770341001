import { Component,OnInit } from '@angular/core';

import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { ProgressConfig } from 'src/app/domain/progress/progress';

@Component({
	selector: 'header-progress',
	templateUrl: './header-progress.component.html'
})
export class HeaderProgressComponent implements OnInit {
	/** Liste des progressions **/
	listeProgress: Array<ProgressConfig> = [];

	/**
	 * Constructeur
	 */
	constructor(private progressService: ProgressService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des progressions en cas de modification
		this.progressService.onProgressUpdate.subscribe({
			next: () => {
				//Mise à jour de la liste des progressions
				this.listeProgress = this.progressService.getListeProgress();
			}
		});
	}

	/**
	 * Suppression d'une progression
	 */
	deleteProgress(index: number) {
		//Suppression d'une progression
		this.progressService.deleteProgress(index);
	}

	/**
	 * Affichage du détail de la progression
	 */
	showProgressDetail(progress: any) {
		//Affichage du détail de la progression
		this.progressService.showProgressDetail(progress);
	}
}