import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'demande-identification-edit',
	templateUrl: './demande-identification-edit.component.html'
})
export class DemandeIdentificationEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() demandeIdentification: any;

	/** Enregistrement de la demande d'identification **/
	@Input() saveDemandeIdentification: (demandeIdentification: any,isPublish: boolean,close?: Function) => void;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de la demande d'identification
		this.demandeIdentification = cloneDeep(this.demandeIdentification);
	}
}