import { Component,Input,OnInit } from '@angular/core';

@Component({
	selector: 'icon',
	templateUrl: './icon.component.html'
})
export class IconComponent implements OnInit {
	/** Nom de l'icone **/
	@Input() name: string;

	/** Famille d'icone **/
	@Input() type: string = 'material-icons-round';

	/** Classes à ajouter à l'icône **/
	@Input() iconClass: string;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification du type
		this.type = this.type || 'material-icons-round';
	}
}