import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PersonalDataComponent } from './personal-data.component';
import { PersonalDataService } from './personal-data.service';

@NgModule({
	imports: [ShareModule],
	declarations: [PersonalDataComponent],
	providers: [PersonalDataService]
})
export class PersonalDataModule {}