import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DashboardFavorisComponent } from './dashboard-favoris.component';
import { DashboardFavorisService } from './dashboard-favoris.service';

@NgModule({
	imports: [ShareModule],
	declarations: [DashboardFavorisComponent],
	providers: [DashboardFavorisService]
})
export class DashboardFavorisModule {}