<ul class="definition-detail" [ngClass]="{ 'z-depth-1': !isModeLight }">
	<li class="header">
		<div class="actions dropdown pull-right" *ngIf="(null | right:'creation') && !isRestricted">
			<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
			<ul class="dropdown-menu dropdown-menu-right">
				<li (click)="addLine(detail)"><a [routerLink]="[]" translate>structureExport.actions.ajouterTypeLigne</a></li>
				<li *ngIf="parentDetail" (click)="addLine(detail,parentDetail,idxDetail)"><a [routerLink]="[]" translate>structureExport.actions.ajouterGroupeParent</a></li>
				<li *ngIf="parentDetail?.listeItems?.length > 1 && idxDetail > 0" (click)="moveDetail(parentDetail,idxDetail,-1)"><a [routerLink]="[]" translate>actions.monter</a></li>
				<li *ngIf="parentDetail?.listeItems?.length > 1 && idxDetail < parentDetail.listeItems.length - 1" (click)="moveDetail(parentDetail,idxDetail,1)"><a [routerLink]="[]" translate>actions.descendre</a></li>
				<li *ngIf="parentDetail" (click)="removeDetail(parentDetail,idxDetail)"><a [routerLink]="[]" translate>actions.supprimer</a></li>
			</ul>
		</div>
		<h4>
			<a [routerLink]="[]" (click)="showDetail(detail.typeDetail,detail);">{{ detail.libelle || ('structureExport.typeDetail.'+detail.typeDetail | translate) }}</a>
		</h4>
	</li>
	<li class="content">
		<ul class="definition-list">
			<li *ngFor="let item of detail.listeItems; let idxItem = index; trackBy: trackByPosition">
				<structure-export-definition-line *ngIf="!item.detail" [structureExport]="structureExport" [detail]="detail" [fichierDetail]="fichierDetail" [item]="item" [idxItem]="idxItem" [isRestricted]="isRestricted" (onStructureExportUpdate)="onStructureExportUpdate.emit($event)" (onRefresh)="onRefresh.emit($event)"></structure-export-definition-line>
				<structure-export-definition-detail *ngIf="item.detail" [structureExport]="structureExport" [detail]="item.detail" [fichierDetail]="fichierDetail" [parentDetail]="detail" [idxDetail]="idxItem" [isRestricted]="isRestricted" (onStructureExportUpdate)="onStructureExportUpdate.emit($event)" (onRefresh)="onRefresh.emit($event)"></structure-export-definition-detail>
			</li>
			<li *ngIf="!detail.listeItems?.length">
				<div class="definition-item hoverable justify-content-center"><span translate>liste.empty</span></div>
			</li>
		</ul>
	</li>
</ul>