import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'compte-taxe-edit',
	templateUrl: './compte-taxe-edit.component.html'
})
export class CompteTaxeEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() taux: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}
