import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CalendrierEditComponent } from './calendrier-edit.component';
import { CalendrierEntiteListComponent } from './calendrier-entite-list.component';
import { CalendrierEntiteComponent } from './calendrier-entite.component';
import { CalendrierService } from './calendrier.service';

@Component({
	selector: 'calendrier',
	templateUrl: './calendrier.component.html'
})
export class CalendrierComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Calendrier courant **/
	public calendrier: any;

	/** Résumé **/
	public resume: any = null;

	/** Liste des entités disponibles **/
	private listeEntites: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private calendrierService: CalendrierService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute
			,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private extractionService: ExtractionService,private layoutService: LayoutService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.calendrier.Calendrier';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.calendrier?.idCalendrier || 0;

	/**
	 * Initialisation du composant
	 */
	async ngOnInit() {
		//Récupération de la liste des entités disponibles
		this.listeEntites = await this.extractionService.getListeEntites('CALENDRIER').toPromise();

		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'calendrier.libelle',
				key: 'libelle'
			},{
				libelle: 'calendrier.reference',
				key: 'reference'
			},{
				libelle: 'calendrier.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editCalendrier(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editCalendrier(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteCalendrier(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'view_column',
				title: this.translateService.instant('calendrier.alerte.aucuneEntite.title'),
				message: this.translateService.instant('calendrier.alerte.aucuneEntite.message'),
				isVisible: () => !this.calendrier?.listeDetails?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addDetail(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'ENTITES',
				libelle: 'calendrier.elements.entite',
				component: CalendrierEntiteListComponent,
				retrieveComponentData: () => ({
					getCalendrier: () => this.calendrier,
					onCalendrierUpdated: calendrier => this.calendrier = calendrier,
					listeEntites: this.listeEntites
				}),
				doAction: () => this.addDetail(),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				count: () => this.calendrier?.listeDetails?.length || 0
			}]
		};

		//Récupération de l'identifiant du calendrier à charger
		this.activatedRoute.params.pipe(take(1)).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idCalendrier);
			}
		});
	}

	/**
	 * Edition du calendrier
	 */
	editCalendrier() {
		//Ouverture du composant d'édition
		this.pageContentService.open(CalendrierEditComponent,{
			data: {
				calendrier: this.calendrier,
				deleteCalendrier: this.deleteCalendrier.bind(this)
			}
		}).subscribe({
			next: calendrier => {
				//Vérification des données
				if (calendrier) {
					//Mise à jour du calendrier
					this.calendrier = calendrier;

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.calendrier);
				}
			}
		});
	}

	/**
	 * Suppression du calendrier
	 */
	private deleteCalendrier() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du calendrier
					this.calendrierService.deleteCalendrier(this.calendrier.idCalendrier).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								delete this.calendrier;

								//Fermeture de la page
								this.layoutService.goBack();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idCalendrier: number) {
		//Chargement du calendrier
		this.calendrierService.loadCalendrier(idCalendrier).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du calendrier
					this.calendrier = result.data.calendrier;

					//Définition du résumé
					this.resume = {
						nbEntites: this.calendrier?.listeDetails?.length || 0
					}

					//Vérification de l'identifiant du calendrier
					if (!this.calendrier?.idCalendrier) {
						//Création d'un calendrier vierge
						this.calendrier = {
							actif: false
						};

						//Edition du calendrier
						this.editCalendrier();
					}
				}
			}
		});
	}

	/**
	 * Ajout d'un nouveau détail
	 */
	private addDetail() {
		//Affichage du détail
		this.pageContentService.open(CalendrierEntiteComponent,{
			data: {
				calendrier: cloneDeep(this.calendrier),
				detail: {},
				listeEntites: this.listeEntites
			}
		},'sub').subscribe({
			next: (calendrier) => {
				//Vérification du calendrier
				if (calendrier)
					//Mise à jour du calendrier
					this.calendrier = Object.assign(this.calendrier,calendrier);
			}
		});
	}
}