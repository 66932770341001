import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { first } from 'rxjs/operators';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AppState } from 'src/app/domain/appstate';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'reservation',
	templateUrl: './reservation.component.html'
})
export class ReservationComponent implements OnInit {
	/** Elément courant **/
	@Input() reservation: any;

	/** Gsetion d'une modification de la réservation **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private vehiculeService: VehiculeService,private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Duplication de l'objet
		this.reservation = cloneDeep(this.reservation);

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Accès au planning
	 */
	goToPlanning(reservation: any = this.reservation) {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'un pool
		if (reservation?.affectation?.vehicule?.pool?.idPool) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [reservation.affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + reservation.affectation.vehicule.pool.libelle + ' (' + reservation.affectation.vehicule.pool.reference + ')'
			});
		}

		//Accès au planning
		this.vehiculeService.goToPlanning(reservation.affectation?.vehicule || reservation.vehicule,{ listeSelectedFilters },null,{ initialDate: listeSelectedFilters.length ? reservation.dateDepart : reservation.affectation?.dateDebut || reservation.dateDepart });
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule(vehicule: any) {
		//Vérification du droit
		if (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')) {
			//Navigation vers le véhicule
			this.layoutService.goToByState('listeVehicules-loadVehicule',{
				routeParams: {
					idVehicule: vehicule.idVehicule
				},
				withGoBack: true
			});
		}
	}

	/**
	 * Accès à l'état des lieux
	 */
	goToEtatLieux(etatLieux: any) {
		//Vérification du droit
		if (this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation')) {
			//Navigation vers l'état des lieux
			this.layoutService.goToByState('listeEtatsLieux-etatLieux',{
				routeParams: {
					idEtatLieux: etatLieux.idEtatLieux
				},
				withGoBack: true
			});
		}
	}
}