import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PosteChargeCompteListItemComponent } from 'src/app/components/comptabilite/poste-charge/poste-charge-compte-list-item.component';

@Component({
	selector: 'nature-compte-list',
	templateUrl: './nature-compte-list.component.html'
})
export class NatureCompteListComponent extends PageContentItem implements OnInit {
	/** Nature **/
	@Input() nature: any;

	/** Affichage du compte  **/
	@Input() addCompte: any;

	/** Type **/
	@Input() type: 'CHARGE' | 'TAXE';

	/** Association des pays avec leur règle de récupération de taxe standard pour la nature **/
	@Input() mapReglesTaxeForPays: { [idPays: number]: Array<any> };

	/** Résumé **/
	@Input() resume: { nbComptesTaxe: number,nbComptes: number };

	/** Données **/
	liste: ListView<any,PosteChargeCompteListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(protected rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PosteChargeCompteListItemComponent>({
			uri: () => `/controller/Compte/filtreComptesByDetail/NATURE/${this.nature.idNature}`,
			component: PosteChargeCompteListItemComponent,
			isLocal: true,
			extraOptions: {
				addCompte: this.addCompte,
				mapReglesTaxeForPays: this.mapReglesTaxeForPays
			},
			listeStaticFilters: [{
				clef: 'compte.type',
				valeur: this.type,
				typeComparaison: TypeComparaison.EQUAL
			}],
			listeFilters: [{
				clef: 'compte.numero',
				isDefault: true
			},{
				clef: 'compte.libelle',
				isDefault: true
			},{
				clef: 'compte.pays.libelle,compte.pays.code2',
				isDefault: true
			}],
			defaultOrder: 'compte.numero'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.type == 'CHARGE' ? this.resume.nbComptes-- : this.resume.nbComptesTaxe--;
	}
}