<fieldset [disabled]="!(null | right:'creation') || disabled">
	<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
		<label translate>authentification.configuration.type.item</label>
		<div>
			<selectpicker name="type" #typeField="ngModel" [(ngModel)]="configuration.type" required (ngModelChange)="onTypeChange()">
				<mat-option *ngFor="let type of listeTypesAuthentification" [value]="type.code">{{ type.libelle }}</mat-option>
			</selectpicker>
		</div>
	</div>
	<div class="alert alert-info" *ngIf="['ADFS_SAML2','SAML2'].includes(configuration.type) && configuration.idConfig">
		<icon name="info"></icon>
		<div>
			<strong translate>authentification.configuration.metadataUrl.title</strong>
			<div>{{ getMetadataUrl() }}</div>
		</div>
		<button mat-raised-button color="primary" (click)="openMetadataUrl()">
			<span translate>actions.ouvrir</span>
		</button>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
		<label translate>authentification.configuration.actif.item</label>
		<div>
			<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="configuration.actif"><span translate>authentification.configuration.actif.info</span></mat-checkbox>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
		<label translate>authentification.configuration.libelle</label>
		<div>
			<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="configuration.libelle" maxlength="300" required/>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': applicationId.invalid }" *ngIf="['ADFS_OAUTH2','GOOGLE_OAUTH2'].includes(configuration.type)">
		<label translate>authentification.configuration.applicationId</label>
		<div>
			<input type="text" name="applicationId" #applicationId="ngModel" class="form-control" [(ngModel)]="configuration.applicationId" [required]="configuration.type == 'ADFS_OAUTH2'"/>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': saml2MetadataUrl.invalid }" *ngIf="['ADFS_SAML2','SAML2'].includes(configuration.type)">
		<label translate>authentification.configuration.saml2MetadataUrl</label>
		<div>
			<input type="text" name="saml2MetadataUrl" #saml2MetadataUrl="ngModel" class="form-control" [(ngModel)]="configuration.saml2MetadataUrl" required/>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': clientId.invalid }">
		<label translate>authentification.configuration.clientId</label>
		<div>
			<input type="text" name="clientId" #clientId="ngModel" class="form-control" [(ngModel)]="configuration.clientId" required/>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': clientSecret.invalid }" *ngIf="['ADFS_OAUTH2','GOOGLE_OAUTH2','OAUTH2'].includes(configuration.type)">
		<label translate>authentification.configuration.clientSecret</label>
		<div>
			<input type="password" name="clientSecret" #clientSecret="ngModel" class="form-control" [(ngModel)]="configuration.clientSecret" required/>
		</div>
	</div>
	<ng-container *ngIf="configuration.type == 'OAUTH2'">
		<div class="form-group" [ngClass]="{ 'has-error': oauth2AuthorizationUrl.invalid }">
			<label translate>authentification.configuration.oauth2AuthorizationUrl</label>
			<div>
				<input type="text" name="oauth2AuthorizationUrl" #oauth2AuthorizationUrl="ngModel" class="form-control" [(ngModel)]="configuration.oauth2AuthorizationUrl" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': oauth2TokenUrl.invalid }">
			<label translate>authentification.configuration.oauth2TokenUrl</label>
			<div>
				<input type="text" name="oauth2TokenUrl" #oauth2TokenUrl="ngModel" class="form-control" [(ngModel)]="configuration.oauth2TokenUrl" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': oauth2UserInfoUrl.invalid }">
			<label translate>authentification.configuration.oauth2UserInfoUrl</label>
			<div>
				<input type="text" name="oauth2UserInfoUrl" #oauth2UserInfoUrl="ngModel" class="form-control" [(ngModel)]="configuration.oauth2UserInfoUrl"/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': oauth2Scope.invalid }">
			<label translate>authentification.configuration.oauth2Scope</label>
			<div>
				<input type="text" name="oauth2Scope" #oauth2Scope="ngModel" class="form-control" placeholder="openid email" [(ngModel)]="configuration.oauth2Scope"/>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="configuration.type == 'SAML2'">
		<div class="form-group" [ngClass]="{ 'has-error': saml2TypeField.invalid }">
			<label translate>authentification.configuration.saml2Type.item</label>
			<div>
				<selectpicker name="saml2Type" #saml2TypeField="ngModel" [(ngModel)]="configuration.saml2Type" required>
					<mat-option *ngFor="let type of listeSaml2Types" [value]="type.code">{{ type.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': saml2AuthContext.invalid }">
			<label translate>authentification.configuration.saml2AuthContext</label>
			<div>
				<input type="text" name="saml2AuthContext" #saml2AuthContext="ngModel" class="form-control" [(ngModel)]="configuration.saml2AuthContext"/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': saml2Certificate.invalid }">
			<label translate>authentification.configuration.saml2Certificate</label>
			<div>
				<textarea rows="5" name="saml2Certificate" #saml2Certificate="ngModel" class="form-control" [(ngModel)]="configuration.saml2Certificate"></textarea>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': saml2CertificatePrivateKey.invalid }">
			<label translate>authentification.configuration.saml2CertificatePrivateKey</label>
			<div>
				<textarea rows="5" name="saml2CertificatePrivateKey" #saml2CertificatePrivateKey="ngModel" class="form-control" [(ngModel)]="configuration.saml2CertificatePrivateKey"></textarea>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': saml2EncryptedNameId.invalid }">
			<label translate>authentification.configuration.saml2EncryptedNameId.item</label>
			<div>
				<mat-checkbox name="saml2EncryptedNameId" #saml2EncryptedNameId="ngModel" [(ngModel)]="configuration.saml2EncryptedNameId"><span translate>authentification.configuration.saml2EncryptedNameId.info</span></mat-checkbox>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': saml2DefaultClaim.invalid }">
			<label translate>authentification.configuration.saml2DefaultClaim</label>
			<div>
				<input type="text" name="saml2DefaultClaim" #saml2DefaultClaim="ngModel" class="form-control" [(ngModel)]="configuration.saml2DefaultClaim"/>
			</div>
		</div>
	</ng-container>
</fieldset>