import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,get,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { v4 } from 'uuid';

import { RegleTaxeService } from 'src/app/components/fiscalite/regle-taxe/regle-taxe.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { SelectPickerService } from 'src/app/share/components/select-picker/select-picker.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte-poste-charge-edit',
	templateUrl: './compte-poste-charge-edit.component.html'
})
export class ComptePosteChargeEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() lienPosteCharge: any;

	/** Liste des règles de taxe **/
	@Input() listeReglesTaxe: any;

	/** Suppression de l'élement courant **/
	@Input() deleteLienPosteCharge: (lienPosteCharge: any,close: Function) => void;

	/** Paramètres du poste de charge **/
	public posteChargeSettings: { state: string,fullIdentifier: string,identifier: string,typeDroit: TypeDroit };

	/** Règle de taxe associée par défaut au poste de charge **/
	private defaultRegleTaxe: any;

	/** Règle de taxe selectionnée **/
	public selectedRegleTaxe: any;

	/** Liste de détails des règles de taxe (modifiable) **/
	public listeRegleTaxeDetails: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService,private regleTaxeService: RegleTaxeService,public selectPickerService: SelectPickerService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation
		this.onPosteChargeChange();
	}

	/**
	 * Interception d'un changement de poste de charge
	 */
	onPosteChargeChange(posteCharge?: any) {
		//Réinitialisation de la règle
		this.selectedRegleTaxe = null;

		//Vérification de la présence d'une valeur
		if (posteCharge)
			//Définition du type
			this.lienPosteCharge.type = posteCharge?.type;

		//Vérification du type de compte
		if (this.lienPosteCharge?.compte?.type == 'TAXE') {
			//Définition des paramètres
			this.posteChargeSettings = this.compteService.getMappingForPostesCharge()[this.lienPosteCharge?.type];

			//Vérification du paramétrage du poste de charge
			if (this.posteChargeSettings)
				//Récupération de la liste des règles de taxe
				this.regleTaxeService.filtrePostesChargeByRegle({
					listeFilter: [{
						clef: 'regleTaxe.pays.idPays',
						valeur: this.lienPosteCharge?.compte?.pays?.idPays
					},{
						clef: 'type',
						valeur: this.lienPosteCharge?.type
					},{
						clef: this.posteChargeSettings?.fullIdentifier,
						valeur: this.lienPosteCharge?.posteCharge?.idObject || get(this.lienPosteCharge,this.posteChargeSettings?.fullIdentifier)
					}]
				}).subscribe({
					next: (listePostesCharge: Array<any>) => {
						//Définition de la règle
						this.defaultRegleTaxe = listePostesCharge[0]?.regleTaxe || this.listeReglesTaxe[0];
					},
					complete: () => {
						let regleTaxe;

						//Définition de la règle de taxe
						regleTaxe = cloneDeep(this.lienPosteCharge.regleTaxe || this.defaultRegleTaxe);

						//Duplication de la liste des détails
						this.listeRegleTaxeDetails = cloneDeep(regleTaxe?.listeDetails || []);

						//Vérification de la présence d'une règle personnalisée
						if (regleTaxe?.tenant != null) {
							//Vérification de la présence de la règle 'Aucune'
							if (!regleTaxe?.listeDetails?.length)
								//Ajout de la règle personnalisée 'Aucune' à la liste
								this.listeReglesTaxe.unshift({ ...regleTaxe });
							else {
								//Ajout de la règle personnalisée à la liste
								this.listeReglesTaxe.push(regleTaxe);

								//Ajout de la règle 'Aucune'
								this.listeReglesTaxe.unshift({
									listeDetails: null
								});
							}
						} else {
							//Ajout de la règle 'Aucune'
							this.listeReglesTaxe.unshift({
								listeDetails: null
							});
						}

						//Définition de la règle selectionnée
						this.selectedRegleTaxe = regleTaxe;
					}
				});
		}
	}

	/**
	 * Enregistrement du lien
	 */
	saveLienPosteCharge() {
		let saveLienCompteDetail: Function

		//Définition de la fonction d'enregistrement
		saveLienCompteDetail = () => {
			//Enregistrement du lien
			this.compteService.saveLienCompteDetail(this.lienPosteCharge).subscribe({
				next: result => {
					let listeDoublons = new Array<string>();

					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour du lien
						this.lienPosteCharge = Object.assign(this.lienPosteCharge,result.data?.lien);

						//Fermeture de la fenêtre
						this.close({ lienPosteCharge: this.lienPosteCharge });
					} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout du poste de charge
						listeDoublons.push(this.translateService.instant('actions.doublon.posteCharge'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			});
		}

		//Vérification du type de compte
		if (this.lienPosteCharge?.compte?.type == 'TAXE') {
			//Vérification de la présence d'un changement dans la liste de détails
			if (isEqual(this.listeRegleTaxeDetails,this.selectedRegleTaxe?.listeDetails) && this.selectedRegleTaxe?.tenant == null && this.listeRegleTaxeDetails?.length) {
				//Vérification de la personnalisation
				if (this.selectedRegleTaxe?.idRegle == this.defaultRegleTaxe?.idRegle)
					//Application de la règle par défaut
					this.lienPosteCharge.regleTaxe = null;
				else
					//Application d'une nouvelle règle
					this.lienPosteCharge.regleTaxe = this.selectedRegleTaxe;

				//Enregistrement
				saveLienCompteDetail();
			} else {
				//Définition d'un nouvel objet
				this.selectedRegleTaxe = {
					libelle: (this.selectedRegleTaxe?.libelle || this.defaultRegleTaxe?.libelle) + (this.selectedRegleTaxe?.libelle?.includes(` (${this.translateService.instant('common.personnalisee')})`) ? '' : ` (${this.translateService.instant('common.personnalisee')})`),
					listeDetails: this.listeRegleTaxeDetails?.map(({ dateApplication,tauxRecuperation,genre }) => ({ dateApplication,tauxRecuperation,genre: genre || null })) || [],
					pays: this.selectedRegleTaxe.pays || this.lienPosteCharge.compte.pays,
					type: this.selectedRegleTaxe.type || 'DATE',
					idRegle: this.listeReglesTaxe?.find((r: any) => r.tenant != null)?.idRegle || null
				}

				//Enregistrement de la règle
				this.regleTaxeService.saveRegleTaxe(this.selectedRegleTaxe).subscribe({
					next: (result: Result) => {
						//Vérification de la présence d'une erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Définition du résultat
							this.selectedRegleTaxe = Object.assign(this.selectedRegleTaxe,result.data.regleTaxe);

							//Définition de la nouvelle règle
							this.lienPosteCharge.regleTaxe = this.selectedRegleTaxe;

							//Enregistrement
							saveLienCompteDetail();
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				})
			}
		} else
			//Enregistrement
			saveLienCompteDetail();
	}

	/**
	 * Suppression de la règle
	 */
	deleteRule(index: number) {
		//Suppression de la règle
		this.listeRegleTaxeDetails.splice(index,1);
	}

	/**
	 * Ajout d'une règle
	 */
	addRule() {
		//Création d'une nouvelle règle
		this.listeRegleTaxeDetails.unshift({ key: v4().toString() });
	}

	/**
	 * Evènement lors du changement des règles de taxe
	 */
	onRegleTaxeChange(): void {
		//Duplication de la liste des détails
		this.listeRegleTaxeDetails = cloneDeep(this.selectedRegleTaxe?.listeDetails);
	}
}
