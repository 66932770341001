import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeInfractionListItemComponent } from './type-infraction-list-item.component';

@Component({
	templateUrl: './type-infraction-list.component.html'
})
export class TypeInfractionListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TypeInfractionListItemComponent>;

	/** Utilisateur courant **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private store: Store<AppState>) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(take(1)).subscribe(user => {
			//Mise à jour de l'utilisateur
			this.user = user;
		});

		//Définition de la liste
		this.liste = new ListView<any,TypeInfractionListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreTypesInfraction`,
			title: this.translateService.instant('vehicule.typeInfraction.liste.title'),
			component: TypeInfractionListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'pays',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'pays'
				}
			}],
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idTypeInfraction) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					pays: this.user?.tenant?.tenantDescription?.pays
				});
			})
		});
	}
}