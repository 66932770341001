import { Component,Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';

@Component({
	selector: 'equipement-releve-compteur-edit',
	templateUrl: './equipement-releve-compteur-edit.component.html'
})
export class EquipementReleveCompteurEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() releveCompteur: any;

	/** Suppression d'un relevé de compteur **/
	@Input() deleteReleveCompteur: (releveCompteur: any,close: Function) => void;

	/** Utilisateur connecté **/
	public user: User;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => this.user = user);
	}
}