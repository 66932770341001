import { Component,EventEmitter,Input,Output } from '@angular/core';

@Component({
	selector: 'structure-export-rubrique-fonction-remplacer',
	templateUrl: './structure-export-rubrique-fonction-remplacer.component.html'
})
export class StructureExportRubriqueFonctionRemplacerComponent {
	/** Détail **/
	@Input() detail: any;

	/** Parent **/
	@Input() parent: any;

	/** Clé du parent **/
	@Input() parentKey: string;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Changement du type de rubrique **/
	@Output() onTypeRubriqueChange : EventEmitter<{ parent: any,parentKey: string }> = new EventEmitter<{ parent: any,parentKey: string }>();
}