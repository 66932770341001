<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idReleve">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }" [translate]="'vehicule.releveEnergie.liste.avatar.'+(data?.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
		<div class="lv-title">
			<span class="m-l-10" (click)="data.isDisplayed = !data.isDisplayed">
				<a [routerLink]="[]">
					<span *ngIf="data.vehicule">{{ data.vehicule | display:'vehicule' }}</span>
					<span *ngIf="!data.vehicule" [translate]="'vehicule.releveEnergie.vehiculeNonCommunique'"></span>
				</a>
			</span>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idReleve">
		<li><span translate>vehicule.releveEnergie.dateReleve</span>&#160;:&#160;{{ data.dateReleve | date:'short' }}</li>
		<li><span translate>vehicule.releveEnergie.volume</span>&#160;:&#160;{{ data.volume | number:'.2-2' }}&#160;{{ data.unite?.libelleCourt || '' }}</li>
		<li *ngIf="data.extension?.userAffecte"><span translate>vehicule.releveEnergie.userAffecte</span>&#160;:&#160;{{ data.extension.userAffecte.prenom + ' ' + data.extension.userAffecte.nom + ' (' + data.extension.userAffecte.matricule + (data.extension.userAffecte.societe?.libelle ? ' - ' + data.extension.userAffecte.societe?.libelle : '') + ')' }}</li>
		<li *ngIf="data.equipement"><span translate>vehicule.releveEnergie.equipement</span>&#160;:&#160;{{ data.equipement.reference }}</li>
		<li *ngIf="data.extension?.priseMultiple"><span translate>vehicule.releveEnergie.priseMultiple</span>&#160;:&#160;<span translate>common.oui</span></li>
		<li *ngIf="data.extension?.depassementReservoir"><span translate>vehicule.releveEnergie.depassementReservoir</span>&#160;:&#160;<span translate>common.oui</span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data?.idReleve && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression') && !data.readOnly"><a [routerLink]="[]" (click)="deleteReleveEnergie();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<releve-energie [releveEnergie]="data" (onObjectChange)="onObjectChange($event)"></releve-energie>
</div>