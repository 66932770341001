import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';


/**
 * Options des revendeurs
 */
export default class RevendeurOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.revendeur';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Revendeur/listeRevendeursActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.revendeur.Revendeur';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idRevendeur'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des champs supplémentaires de la liste
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'revendeur.email',
			getValue: (item: any) => item.email
		}];
	}
}