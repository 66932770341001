import { Component,Input,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { GrilleFluiditeService } from 'src/app/components/vehicule/grille-fluidite/grille-fluidite.service';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@Component({
	selector: 'grille-attribution-modele-financement-edit',
	templateUrl: './grille-attribution-modele-financement-edit.component.html'
})
export class GrilleAttributionModeleFinancementEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() financement: any;

	/** Elément courant **/
	@Input() deleteFinancement: (financement: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Liste des types d'affichage **/
	public listeTypesAffichagePrix: Array<{ code: string,libelle: string }>;

	/** Utilisateur courant **/
	public user: User;

	/** Copie du financement **/
	public savedFinancement: any;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionService: GrilleAttributionModeleService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService,private store: Store<AppState>,private grilleFluiditeService: GrilleFluiditeService,private grilleAttributionModeleService: GrilleAttributionModeleService,private cotationService: CotationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types d'affichage de prix
		this.listeTypesAffichagePrix = this.grilleAttributionService.getListeTypesAffichagePrix();

		//Initialisation de l'indicateur d'affichage du détail des loyers
		this.financement.isDetailLoyerDisplayed = this.financement.loyerFinancier || this.financement.loyerMaintenance || this.financement.loyerAssistance || this.financement.loyerGarantiePerteFinanciere || this.financement.loyerGestionCarburant || this.financement.loyerGestionSinistres || this.financement.loyerTelematique || this.financement.loyerPneumatiques || this.financement.loyerVehiculeRemplacement || this.financement.loyerAsssurance || this.financement.loyerAutres;

		//Duplication du financement
		this.savedFinancement = this.financement?.idFinancement && cloneDeep(this.financement);

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveFinancement(isWithoutNavigation: boolean = false): Observable<any> {
		let subject = new Subject();

		//Enregistrement du financement
		this.grilleAttributionService.saveFinancement(this.financement).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.financement,result.data?.grilleAttributionModeleFinancement);

					//Vérification de la fermeture du formulaire
					if (!isWithoutNavigation) {
						//Fermeture de la fenêtre
						this.close({ financement: this.financement });
					} else {
						//Duplication du financement
						this.savedFinancement = cloneDeep(this.financement);

						//Poursuite du traitement
						subject.next(this.financement);
					}
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				//Finalisation du traitement
				subject.complete();
			},
			error: () => subject.error(null)
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Calcul du prix de revient au kilomètre
	 */
	computePrixRevientKilometrique() {
		//Vérification des informations obligatoires pour le calcul
		if (this.financement.duree && this.financement.distance && this.financement.loyerMensuel)
			//Calcul du prix de revient
			this.financement.coutDistance = this.financement.duree * this.financement.loyerMensuel / this.financement.distance;
		else
			//Mise à zéro du prix
			this.financement.coutDistance = 0;
	}

	/**
	 * Affichage de la grille de fluidité
	 */
	showGrilleFluidite() {
		//Affichage de la grille de fluidité
		this.grilleFluiditeService.showGrilleFluidite(this.financement.grilleFluidite).subscribe({
			next: (result: any) => {
				//Vérification de l'indicateur de suppression
				if (result?.isDeleted)
					//Suppression de la grille de fluidité
					this.financement.grilleFluidite = null;
			}
		})
	}

	/**
	 * Import d'une grille de fluidité
	 */
	showImportGrilleFluidite() {
		let showGrilleFluiditeImport: Function;

		//Ouverture de la pop-up d'import
		showGrilleFluiditeImport = () => {
			//Ouverture de la pop-up d'import
			this.grilleFluiditeService.showGrilleFluiditeImport('GRILLE_ATTRIBUTION_MODELE_FINANCEMENT',this.financement.fournisseur?.idFournisseur,this.financement.idFinancement).subscribe({
				complete: () => {
					//Chargement du financement
					this.grilleAttributionModeleService.loadFinancement(this.financement.idFinancement).subscribe({
						next: (result: Result) => {
							//Vérification du chargement
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Définition du financement
								Object.assign(this.financement,result.data?.financement);

								//Définition de la grille de fluidité
								this.financement.grilleFluidite = result.data?.grilleFluidite;

								//Définition du type de financement LLD
								this.financement.typeFinancementLLD = result.data?.typeFinancementLLD;

								//Affichage de la grille de fluidité
								this.showGrilleFluidite();
							}
						}
					})
				}
			})
		}

		//Vérification de l'état du financement
		if (!this.financement.idFinancement) {
			//Enregistrement du financement
			this.saveFinancement(true).subscribe({
				next: () => {
					//Ouverture de la pop-up d'import
					showGrilleFluiditeImport();
				}
			});
		} else
			//Ouverture de la pop-up d'import
			showGrilleFluiditeImport();
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Vérification de l'état de financement avant enregistrement
		return !isEqual(omit(this.savedFinancement,['grilleFluidite']),omit(this.financement,['grilleFluidite']))
	}

	/**
	 * Traitement avant l'action de fermeture du formulaire
	 */
	onBeforeAction(action: 'DIRTY_CLOSE' | 'SAVE' | 'CLOSE') {
		//Vérification de l'action
		if (this.savedFinancement?.idFinancement && action == 'DIRTY_CLOSE')
			//Réinitialisation du financement
			this.financement = this.savedFinancement;
	}

	/**
	 * Fermeture du formulaire
	 */
	doClose() {
		//Vérification de l'enregistrement du financement
		if (this.savedFinancement?.idFinancement)
			//Fermeture du formulaire
			this.close?.({ financement: this.financement });
		else
			//Fermeture du formulaire sans mettre à jour la liste
			this.close();
	}

	/**
	 * Affichage de la pop-up de comparaison
	 */
	showComparaisonForCotation(cotation: any = this.financement.reponseCotation?.cotation) {
		//Affichage de la pop-up de comparaison pour la cotation
		this.cotationService.showComparaisonForCotation(cotation).subscribe();
	}
}