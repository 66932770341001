import { RightService } from 'src/app/share/pipe/right/right.service';

/**
 * Modèles représentant la structure d'un référentiel de liste
 */
export class ReferentielList {
	/** Données **/
	url: string;
	type: TypeReferentielList;
	listeItems: Array<ReferentielListItem>;
}

/**
 * Modèle représentant un élément d'un référentiel de liste
 */
export class ReferentielListItem {
	/** Données **/
	icone: string | { libelle: string };
	iconeType?: string;
	libelle: string;
	field: string;
	redirectionUrl: string;
	counter?: number;
	hasRight?: (rightService: RightService) => boolean;
}

/**
 * Type de référentiel de liste
 */
export enum TypeReferentielList {
	/** Enumération **/
	COMMERCE = 'COMMERCE',
	COMPTABILITE = 'COMPTABILITE',
	DEVISE = 'DEVISE',
	FISCALITE = 'FISCALITE',
	FOURNISSEUR = 'FOURNISSEUR',
	GEOGRAPHIE = 'GEOGRAPHIE',
	MATERIEL = 'MATERIEL',
	DEMANDE_IDENTIFICATION = 'DEMANDE_IDENTIFICATION',
	COLLABORATEUR = 'COLLABORATEUR',
	VEHICULE = 'VEHICULE',
	VEHICULE_COMMANDE = 'VEHICULE_COMMANDE',
	VEHICULE_INFRACTION = 'VEHICULE_INFRACTION'
}