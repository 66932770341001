import { Component,OnInit } from '@angular/core';
import moment from 'moment';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './modele-option-contrainte-list-item.component.html'
})
export class ModeleOptionContrainteListItemComponent extends ListViewItem<any> implements OnInit {
	/** Date courante **/
	public today: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Date courante
		this.today = moment().startOf('day');
	}
}