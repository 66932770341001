<div class="lv-item media">
	<div class="pull-left lv-avatar">
		<icon [type]="optionCategorieService.getTypeOptionForCode(detail?.listeSubCategories[0]?.type)?.type" [name]="optionCategorieService.getTypeOptionForCode(detail?.listeSubCategories[0]?.type)?.icone"></icon>
	</div>

	<div class="media-body">
		<div class="lv-title word-break"><a [routerLink]="[]">{{ detail.libelle }}</a></div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="detail?.listeSubCategories?.length"><span translate>vehicule.modele.configuration.categorieOption</span>&#160;:&#160;<span>{{ getListeSubCategories(detail) }}</span></li>
	</ul>
</div>