import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { TemplateMailPreviewComponent } from './template-mail-preview.component';
import { TemplateMailService } from './template-mail.service';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [TemplateMailPreviewComponent],
	exports: [TemplateMailPreviewComponent],
	providers: [TemplateMailService]
})
export class TemplateMailModule {}