import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ArticleEditComponent } from './article-edit.component';
import { ArticleFeedbackListItemComponent } from './article-feedback-list-item.component';
import { ArticleFeedbackListComponent } from './article-feedback-list.component';
import { ArticleFeedback } from './article-feedback.component';
import { ArticleListItemComponent } from './article-list-item.component';
import { ArticleListComponent } from './article-list.component';
import { ArticlePreviewComponent } from './article-preview.component';
import { ArticleRubriqueListEditComponent } from './article-rubrique-list-edit.component';
import { ArticleRubriqueListComponent } from './article-rubrique-list.component';
import { ArticleComponent } from './article.component';
import { ArticleService } from './article.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ArticleListComponent,ArticleListItemComponent,ArticlePreviewComponent,ArticleComponent,ArticleEditComponent
		,ArticleFeedbackListComponent,ArticleFeedbackListItemComponent,ArticleFeedback,ArticleRubriqueListComponent,ArticleRubriqueListEditComponent
	],
	providers: [ArticleService]
})
export class ArticleModule { }