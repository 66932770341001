import { Injectable } from '@angular/core';
import { Router,CanActivate,ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { ExtendedRouteData } from 'src/app/domain/common/route/extended-route';
import { TypeTenant } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoginService } from 'src/app/share/login/login.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

/**
 * Gardien pour l'accès aux composants nécessitant une authentification
 */
@Injectable()
export class LoggedGuardProvider implements CanActivate {
	/**
	 * Constructeur
	 */
	constructor(private router: Router,private translateService: TranslateService,private loginService: LoginService,private rightService: RightService,private layoutService: LayoutService,private workflowService: WorkflowService) {

	}

	/**
	 * Activation du composant
	 */
	async canActivate(route: ActivatedRouteSnapshot) {
		let isLogged: boolean;
		let hasRight: boolean;
		let user: User;
		let data: ExtendedRouteData;

		//Attente du chargement des traductions
		await this.translateService.get('global.actions.continuer').toPromise();

		//Lecture des données de la route
		data = route.data as ExtendedRouteData;

		//Récupération de la connexion
		isLogged = this.loginService.getSession()?.isLogged;

		//Récupération de l'utilisateur
		user = this.loginService.getSession()?.user;

		//Vérification du droit
		hasRight = !data.typeDroit || !user
			|| Array.isArray(data.typeDroit) && data.typeDroit.some(r => this.rightService.hasRight(r,'consultation'))
			|| !Array.isArray(data.typeDroit) && this.rightService.hasRight(data.typeDroit,'consultation')
			|| data.validationClass && this.workflowService.isValideurFor(user,data.validationClass)
			|| false;

		//Vérification du type de tenant
		hasRight = hasRight && (!data.typeTenant || data.typeTenant == user?.tenant?.type || data.typeTenant == TypeTenant.ROOT && (user?.tenant?.type == 'REVENDEUR' && user?.tenant?.revendeur?.type == 'ROOT'));

		//Vérification de la redirection
		if (!isLogged) {
			//Redirection vers l'écran de connexion
			this.router.navigate(['/Login']);
		} else if (!hasRight) {
			//Redirection vers l'écran d'accès interdit
			setTimeout(() => this.layoutService.goToByState('access-forbidden'));
		}

		//Vérification de la connexion
		return isLogged && hasRight;
	}
}