import { NgModule } from '@angular/core';

import { RightModule } from 'src/app/share/pipe/right/right.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { EntiteFieldModule } from '../entite-field/entite-field.module';
import { FormCustomizationEditComponent } from './form-customization-edit.component';
import { FormCustomizationComponent } from './form-customization.component';
import { FormCustomizationService } from './form-customization.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,RightModule,EntiteFieldModule,IconModule],
	declarations: [FormCustomizationComponent,FormCustomizationEditComponent],
	exports: [FormCustomizationComponent],
	providers: [FormCustomizationService]
})
export class FormCustomizationModule { }
