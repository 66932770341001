import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { WorkflowListItemComponent } from './workflow-list-item.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	templateUrl: './workflow-list.component.html'
})
export class WorkflowListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,WorkflowListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private extractionService: ExtractionService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,WorkflowListItemComponent>({
			uri: '/controller/Workflow/filtreWorkflows',
			title: this.translateService.instant('workflow.liste.title'),
			component: WorkflowListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'entite',
				type: TypeFilter.STRING,
				listeValues: this.extractionService.getListeEntites('WORKFLOW')
			},{
				clef: 'dateApplication',
				type: TypeFilter.DATE
			}].filter(i => !!i),
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/Workflow/loadWorkflow/0')
		});
	}
}