import { Component,ElementRef,EventEmitter,Input,OnDestroy,OnInit,Optional,Output,ViewChild } from '@angular/core';
import { DatePipe,DecimalPipe } from '@angular/common';
import { isObservable,Observable } from 'rxjs';
import { ControlContainer,NgForm,NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { GLOBALS } from 'src/app/utils/globals';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AutocompleteComponent } from 'src/app/share/components/autocomplete/autocomplete.component';
import { ListBasketOption } from 'src/app/domain/basket/basket';

@Component({
	selector: 'basket',
	exportAs: 'basket',
	templateUrl: './basket.component.html',
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional,NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class BasketComponent implements OnInit,OnDestroy {
	/** Modèle du formulaire **/
	@Input() model: any;

	/** Nom de l'attribut de type liste associé au panier **/
	@Input() propertyName: string;

	/** Récupération de la clé d'identification d'un élément **/
	@Input() getKey?: (item: any) => any;

	/** Type générique d'autocomplete **/
	@Input() type: string;

	/** Filtres de l'autocomplete **/
	@Input() filter?: any;

	/** Placeholder **/
	@Input() placeholder: string = '';

	/** Libellé du champ **/
	@Input() label: string;

	/** Caractère actif du champ **/
	@Input() disabled?: boolean;

	/** Interdiction de créer un élément à la volée **/
	@Input() isCreationDisabled?: boolean = false;

	/** Largeur de la colonne des libellés **/
	@Input() colWidthLabel?: number = 3;

	/** Largeur de la colonne de l'autocomplete **/
	@Input() colWidthAutocomplete?: number;

	/** Libellé du champ de recherche **/
	@Input() labelAutocomplete: string;

	/** Tooltip du champ de recherche **/
	@Input() tooltipAutocomplete: string;

	/** Libellé de la liste **/
	@Input() labelListe: string;

	/** Vérification de la possibilité d'ajouter un élément **/
	@Input() canAddItem: boolean = true;

	/** Nom du champ autocomplete dans le formulaire **/
	@Input() fieldName: string = 'selectedItem';

	/** Vérification de la validité du champ de recherche **/
	@Input() hasError: () => boolean = () => false;

	/** Affichage d'un élément **/
	@Input() displayItem?: (item: any) => string;

	/** Vérification de la possibilité de supprimer l'élément avant l'exécution de l'action **/
	@Input() confirmRemoveItem?: (item: any,idx: number) => boolean | Observable<boolean>;

	/** Désactivation de la suppression de l'élément **/
	@Input() isItemDisabled?: (item: any,idx: number) => boolean = () => false;

	/** Options de l'autocomplete **/
	@Input() options?: AutocompleteOptions;

	/** Options des listes de panier **/
	@Input() listeBasketsOptions?: Array<ListBasketOption>;

	/** Ajout d'un élément **/
	@Output() onAdd: EventEmitter<any> = new EventEmitter<any>();

	/** Retrait d'un élément **/
	@Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

	/** Valeur de l'autocomplete **/
	@ViewChild('selectedItemModel') selectedItemModel: NgModel;

	/** Composant Autocomplete **/
	@ViewChild('autocomplete') autocomplete: AutocompleteComponent;

	/** Élément sélectionné **/
	selectedItem: any;

	/** Observateur de changements sur le formulaire **/
	private fieldsetMutationObserver: MutationObserver;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private translateService: TranslateService,private datePipe: DatePipe,private decimalPipe: DecimalPipe) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let fieldset;

		//Recherche du formulaire le plus proche
		fieldset = this.elementRef.nativeElement.closest('fieldset');

		//Vérification de la présence d'un formulaire
		if (fieldset) {
			//Lecture du champ de désactivation du formulaire
			this.disabled = GLOBALS.$(fieldset).is(':disabled');

			//Création de l'observateur d'évènements sur le formulaire
			this.fieldsetMutationObserver = new MutationObserver(() => {
				//Lecture du champ de désactivation du formulaire
				this.disabled = GLOBALS.$(fieldset).is(':disabled');
			});

			//Ecoute des changements
			this.fieldsetMutationObserver.observe(fieldset,{
				attributes: true,
				attributeFilter: ['disabled']
			});
		}

		//Vérification de la fonction de récupération de la clé d'identification des éléments
		if (!this.getKey)
			//Définition de la fonction par défaut issue de l'autocomplete
			this.getKey = (item: any) => item[this.autocomplete?.options.getKeyFieldName()];

		//Vérification de la fonction d'affichage des éléments sélectionnées
		if (!this.displayItem)
			//Définition de la fonction par défaut issue de l'autocomplete
			this.displayItem = (item: any) => this.autocomplete?.options.displayItem(item,this.translateService,this.datePipe,this.decimalPipe);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Arrêt de l'observation des changements
		this.fieldsetMutationObserver?.disconnect();
	}

	/**
	 * Ajout d'un élément à la liste
	 */
	onSelectItem(item: any) {
		let liste: Array<any>;

		//Contrôle anti-doublon
		liste = (this.model[this.propertyName] || []).filter(i => this.getKey(i) === this.getKey(item));

		//Vérification que l'élément est absent de la liste
		if (liste.length == 0) {
			//Initialisation de la liste (si nécessaire)
			this.model[this.propertyName] = this.model[this.propertyName] || [];

			//Ajout de l'élément
			this.model[this.propertyName].push(item);

			//Notification de l'ajout d'un élément à la liste
			this.onAdd?.emit(item);

			//Vérification de la validité
			this.checkValidity();
		}

		//Réinitalisation du champ de saisie
		this.autocomplete.writeValue(null);
	}

	/**
	 * Retrait d'un élément de la liste
	 */
	removeItem(item: any,idx: number) {
		let confirmRemoveItemResult: boolean | Observable<boolean>;
		let doRemoveItem: () => void;

		//Suppression de l'élément
		doRemoveItem = () => {
			//Retrait de l'élément de la liste
			this.model[this.propertyName].splice(idx,1);

			//Notification de la suppression de l'objet
			this.onRemove?.emit(item);

			//Vérification de la validité
			this.checkValidity();
		}

		//Vérification de la présence de la fonction de contrôle de suppression
		if (this.confirmRemoveItem) {
			//Déclenchement de la fonction de contrôle de suppression
			confirmRemoveItemResult = this.confirmRemoveItem(item,idx);

			//Vérification du type de retour
			if (isObservable(confirmRemoveItemResult)) {
				//Souscription au retour
				confirmRemoveItemResult.subscribe({
					next: (canRemove) => {
						//Vérification de la suppression de l'élément
						if (canRemove)
							//Suppression de l'élément
							doRemoveItem();
					}
				});
			} else if (confirmRemoveItemResult) {
				//Suppression de l'élément
				doRemoveItem();
			}
		} else {
			//Suppression de l'élément
			doRemoveItem();
		}
	}

	/**
	 * Vérification de la validité
	 */
	checkValidity() {
		//Mise en cycle
		setTimeout(() => {
			//Déclenchement manuel de la validité
			this.selectedItemModel.control.updateValueAndValidity();
		});
	}

	/**
	 * Récupération de l'index d'un élément pour le multi-paniers
	 */
	getIndexFromListe(item): number {
		//Récupération de l'index d'un élément pour le multi-paniers
		return this.model[this.propertyName]?.indexOf(item) || 0;
	}
}