import { Component,Input } from '@angular/core';

import { Alert } from 'src/app/domain/common/complex-page/alert';
import { Options } from 'src/app/domain/common/complex-page/options';

@Component({
	selector: 'complex-page-alerts',
	templateUrl: './complex-page-alerts.component.html'
})
export class ComplexPageAlertsComponent {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/**
	 * Récupération de la liste des alertes visibles
	 */
	getListeVisibleAlertes(): Array<Alert> {
		//Retour de la liste des alertes visibles
		return this.options.listeAlertes?.filter(e => !e.isVisible || e.isVisible()) || [];
	}

	/**
	 * Récupération du niveau de l'alerte
	 */
	getAlerteLevel(alert: Alert): string {
		//Récupération du niveau de l'alerte
		return typeof alert.level == 'function' ? alert.level() : (alert.level || 'INFO');
	}

	/**
	 * Récupération du titre de l'alerte
	 */
	getAlerteTitle(alert: Alert): string {
		//Récupération du titre de l'alerte
		return typeof alert.title == 'function' ? alert.title() : alert.title;
	}

	/**
	 * Récupération du message de l'alerte
	 */
	getAlerteMessage(alert: Alert): string {
		//Récupération du message de l'alerte
		return typeof alert.message == 'function' ? alert.message() : alert.message;
	}
}
