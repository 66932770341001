import { Directive,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { some } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeEntiteWithReferentiel } from './referentiel-entite-list';

/**
 * Classe abstraite représentant une entité issue du référentiel mixte
 */
@Directive()
export abstract class ReferentielEntiteListItem<T extends ListItem> extends ListViewItem<T> implements OnInit {
	/** Nom de l'entité du référentiel **/
	abstract type: string;

	/** Référentiel **/
	referentiel: { nbItems?: number };

	/**
	 * Constructeur
	 */
	constructor(public referentielService: ReferentielService,public toastrService: ToastrService,public translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération du référentiel transmis par la liste
		this.referentiel = this.extraOptions?.referentiel;
	}

	/**
	 * Copie de l'entité en local
	 */
	copyEntiteLocal(idEntite: number,onFinish?: () => any) {
		//Copie de l'entité dans le référentiel local
		this.referentielService.copyEntiteLocal(idEntite,this.type).subscribe(result => {
			let listeDoublons;

			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Rechargement de la liste
				this.liste.refresh();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Finalisation du traitement
				onFinish?.();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Création de la liste
				listeDoublons = [];

				//Vérification du type d'entité
				if (some(this.referentielService.getListeTypeReferentiels()[TypeEntiteWithReferentiel.DEVISE],{ type: this.type })) {
					//Vérification du titre
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.code'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.numero'));
				} else if (some(this.referentielService.getListeTypeReferentiels()[TypeEntiteWithReferentiel.WORKFLOW_MOTIF],{ type: this.type })) {
					//Ajout de la référence
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Ajout de l'entité
					listeDoublons.push(this.translateService.instant('actions.doublon.entite'));
				} else {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));
				}

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Rafraichissement du compteur des entités standard
	 */
	refreshReferentiel() {
		//Dénombrement des éléments
		this.referentielService.countItems([this.type]).subscribe(result => {
			//Mise à jour du nombre d'éléments pour le type
			this.referentiel.nbItems = result?.data[this.type];
		});
	}
}