import { Directive,ElementRef,Input,HostListener,EventEmitter,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Directive({
	selector: '[formDetectChanges]'
})
export class FormDetectChangesDirective {
	/** Données **/
	@Input() formDetectChanges: { doAction: () => void,doSave?: () => void,isDirty: boolean,isDisabled?: boolean };

	/** Traitement avant action **/
	@Output() beforeAction?: EventEmitter<'SAVE' | 'CLOSE' | 'DIRTY_CLOSE'> = new EventEmitter();

	/** Bouton d'enregistrement **/
	private btnSave: HTMLElement;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private translateService: TranslateService,private confirmService: ConfirmService) {}

	/**
	 * Détection de l'affichage de la vue
	 */
	ngAfterViewInit() {
		//Récupération du bouton d'enregistrement dans le formulaire
		this.btnSave = this.elementRef.nativeElement.parentElement.querySelector('.pull-right > button.mat-raised-button.mat-primary:last-child');
	}

	/**
	 * Interception d'un clic sur l'élément
	 */
	@HostListener('click')
	onClick() {
		//Vérification de l'état du formulaire
		if (this.formDetectChanges.isDirty) {
			//Message de confirmation
			this.confirmService.showConfirm(this.translateService.instant(`actions.enregistrement.confirmation${(this.btnSave?.hasAttribute('disabled') || this.formDetectChanges.isDisabled) && 'Edition' || ''}`),{ actionColor: 'primary' }).subscribe(isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Vérification de l'action de sauvegarde
					if (this.formDetectChanges.doSave) {
						//Notification avant éxécution de l'action
						this.beforeAction?.emit('SAVE');

						//Déclenchement de la sauvegarde
						this.formDetectChanges.doSave();
					} else if (this.btnSave)
						//Déclenchement de la sauvegarde via le bouton
						this.btnSave.click();
				} else {
					//Notification avant éxécution de l'action
					this.beforeAction?.emit('DIRTY_CLOSE');

					//Déclenchement de l'action
					this.formDetectChanges.doAction();
				}
			});
		} else {
			//Notification avant éxécution de l'action
			this.beforeAction?.emit('CLOSE');

			//Déclenchement de l'action
			this.formDetectChanges.doAction();
		}
	}
}