<form #vehiculeImputationSelectionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.imputationSelection.title</h4>
	</div>
	<div class="modal-body">
		<div class="p-t-20">
			<mat-radio-group name="selectedSource" [(ngModel)]="selectedSource" (change)="onSourceChange($event.value)">
				<mat-radio-button value="REFERENTIEL">
					<span translate>vehicule.imputationSelection.selectionnerVehiculeReferentiel</span>
				</mat-radio-button>
				<div class="form-group" [ngClass]="{ 'has-error': vehicule.invalid }">
					<label translate>vehicule.imputationSelection.vehicule</label>
					<div>
						<autocomplete name="vehicule" type="vehicule" #vehicule="ngModel" [(ngModel)]="selectedVehicule.referentiel" (ngModelChange)="onSourceChange('REFERENTIEL')" [required]="selectedSource == 'REFERENTIEL'"></autocomplete>
					</div>
				</div>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="LISTE">
					<span translate>vehicule.imputationSelection.selectionnerVehiculeListe</span>
				</mat-radio-button>
				<mat-radio-group name="selectedVehiculeForListe" [(ngModel)]="selectedVehicule.liste" (change)="selectedSource = 'LISTE'; onSourceChange('LISTE')">
					<list-view [liste]="liste" *ngIf="liste"></list-view>
				</mat-radio-group>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveVehiculeImputationSelection()" [disabled]="vehiculeImputationSelectionForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>