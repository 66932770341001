import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';

import { HistoryComponent } from './history.component';
import { HistoryCollectionChangeComponent } from './history-collection-change.component';

/**
 * Service de gestion de l'historique des modifications
 */
@Injectable()
export class HistoryService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) {}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory(className: string,idObject: number,idSecondaryObject: number = null,idTenant: number = null): Observable<any> {
		let bsModalRef: BsModalRef<HistoryComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(HistoryComponent,{
			initialState: {
				className,
				idObject,
				idSecondaryObject,
				idTenant
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}

	/**
	 * Affichage du composant de comparaison des modifications
	 */
	public showCollectionChange(change: any): Observable<any> {
		let bsModalRef: BsModalRef<HistoryCollectionChangeComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(HistoryCollectionChangeComponent,{
			initialState: {
				change
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
 }