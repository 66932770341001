import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ControleReglementaireEditComponent } from './controle-reglementaire-edit.component';
import { ControleReglementaireRuleComponent } from './controle-reglementaire-rule.component';
import { ControleReglementaireService } from './controle-reglementaire.service';

@Component({
	selector: 'controle-reglementaire',
	templateUrl: './controle-reglementaire.component.html'
})
export class ControleReglementaireComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Contrôle réglementaire courant **/
	public controleReglementaire: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private controleReglementaireService: ControleReglementaireService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private rightService: RightService
		,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute,private layoutService: LayoutService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.ControleReglementaire';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.controleReglementaire?.idControleReglementaire || 0;

	/**
	 * Initialisation
	 */
	async ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.controleReglementaire.pays',
				key: 'pays',
				type: 'DISPLAY',
				format: 'pays'
			},{
				libelle: 'vehicule.controleReglementaire.sousType',
				key: (data) => this.translateService.instant('vehicule.controleReglementaire.type.'+data.type)
			},{
				libelle: 'vehicule.controleReglementaire.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editControleReglementaire(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editControleReglementaire(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteControleReglementaire(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'filter_list',
				title: this.translateService.instant('vehicule.controleReglementaire.alerte.aucunFiltre.title'),
				message: this.translateService.instant('vehicule.controleReglementaire.alerte.aucunFiltre.message'),
				isVisible: () => !this.controleReglementaire?.rule?.listeDetails?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addRule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'FILTRE',
				libelle: 'vehicule.controleReglementaire.elements.filtre',
				component: ControleReglementaireRuleComponent,
				retrieveComponentData: () => ({
					getControleReglementaire: () => this.controleReglementaire,
					getRule: () => this.controleReglementaire?.rule
				}),
				doAction: () => this.addRule(),
				isElementVisible: () => this.controleReglementaire?.rule,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				count: () => this.controleReglementaire?.rule?.listeDetails?.length ? 1 : 0
			}]
		};

		//Récupération de l'identifiant du contrôle réglementaire à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idControleReglementaire);
			}
		});
	}

	/**
	 * Edition du contrôle réglementaire
	 */
	editControleReglementaire() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ControleReglementaireEditComponent,{
			data: {
				controleReglementaire: this.controleReglementaire,
				deleteControleReglementaire: this.deleteControleReglementaire.bind(this)
			}
		}).subscribe({
			next: controleReglementaire => {
				//Vérification des données
				if (controleReglementaire) {
					//Mise à jour du contrôle réglementaire
					this.controleReglementaire = controleReglementaire;

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.controleReglementaire);
				}
			}
		});
	}

	/**
	 * Suppression du contrôle réglementaire
	 */
	private deleteControleReglementaire() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du contrôle réglementaire
					this.controleReglementaireService.deleteControleReglementaire(this.controleReglementaire.idControleReglementaire).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								delete this.controleReglementaire;

								//Fermeture de la page
								this.layoutService.goBack();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idControleReglementaire: number) {
		//Chargement du contrôle réglementaire
		this.controleReglementaireService.loadControleReglementaire(idControleReglementaire).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du contrôle réglementaire
					this.controleReglementaire = result.data.controleReglementaire;

					//Vérification de l'identifiant du contrôle réglementaire
					if (!this.controleReglementaire?.idControleReglementaire) {
						//Création d'un contrôle réglementaire vierge
						this.controleReglementaire = {
							actif: false
						};

						//Edition du contrôle réglementaire
						this.editControleReglementaire();
					}
				}
			}
		});
	}

	/**
	 * Ajout d'une règle
	 */
	private addRule() {
		//Affichage du détail
		this.pageContentService.open(ControleReglementaireRuleComponent,{
			data: {
				getControleReglementaire: () => this.controleReglementaire,
				getRule: () => this.controleReglementaire.rule && cloneDeep(this.controleReglementaire.rule) || {},
				isEdition: true
			}
		},'sub').subscribe({
			next: (controleReglementaire) => {
				//Vérification du contrôle réglementaire
				if (controleReglementaire) {
					//Mise à jour du contrôle réglementaire
					this.controleReglementaire = Object.assign(this.controleReglementaire,controleReglementaire);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FILTRE',true);
				}
			}
		});
	}
}