import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './structure-export-fichier-nom.component.html'
})
export class StructureExportFichierNomComponent{
	/** Liste des métiers **/
	@Input() fichier: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Résultat **/
	result: { fichier: any }

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<StructureExportFichierNomComponent>) {}

	/**
	 * Enregistrement du nom de fichier
	 */
	public saveName() {
		//Définition du résultat
		this.result = {
			fichier: this.fichier
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}