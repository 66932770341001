<div class="container-fluid">
	<div class="card planning">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
		<div class="card-body" [ngClass]="{ 'loading': !calendarOptions || (isLoading$ | async) || isPeriodChanging }">
			<ng-container *ngIf="calendarOptions">
				<full-calendar #calendar [options]="calendarOptions"></full-calendar>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #pleaseWaitTemplate>
	<please-wait size="sm" style="display: none;"></please-wait>
</ng-template>

<ng-template #resourceAreaHeaderContentTemplate>
	<div class="form-group">
		<label translate>planning.regroupement.item</label>
		<div>
			<selectpicker name="selectedTypeRegroupement" [(ngModel)]="selectedTypeRegroupement" (ngModelChange)="onTypeRegroupementChange()" required>
				<mat-option *ngFor="let typeRegroupement of listeTypesRegroupement" [value]="typeRegroupement"><span [translate]="'planning.regroupement.vehicule.' + typeRegroupement.code"></span></mat-option>
			</selectpicker>
		</div>
	</div>
</ng-template>

<ng-template #eventTooltipTemplate let-affectation>
	<div style="display: none;">
		<div class="text-left">
			<div><span class="bold" translate>vehicule.affectation.type.itemShort</span>&#160;:&#160;{{ 'vehicule.affectation.type.' + (affectation.type || 'NON_DEFINI') | translate }}</div>
			<div *ngIf="affectation.type != 'VEHICULE_IMMOBILISE' && affectation.user"><span class="bold" translate>vehicule.affectation.conducteur.item</span>&#160;:&#160;{{ affectation.user }}</div>
			<div *ngIf="affectation.type != 'VEHICULE_IMMOBILISE' && affectation.vehicule"><span class="bold" translate>vehicule.affectation.vehicule</span>&#160;:&#160;{{ affectation.vehicule }}</div>
			<div *ngIf="affectation.type != 'VEHICULE_IMMOBILISE' && affectation.carburant"><span class="bold" translate>vehicule.affectation.energie</span>&#160;:&#160;{{ affectation.carburant }}</div>
			<div *ngIf="affectation.type != 'VEHICULE_IMMOBILISE'"><span class="bold" translate>vehicule.affectation.dateDebut.itemShort</span>&#160;:&#160;{{ affectation.start | date:'short' }}</div>
			<div *ngIf="affectation.type != 'VEHICULE_IMMOBILISE' && affectation.end"><span class="bold" translate>vehicule.affectation.dateFin.itemShort</span>&#160;:&#160;{{ affectation.end | date:'short' }}</div>
			<div *ngIf="affectation.remarque"><span class="bold" translate>vehicule.affectation.remarque</span>&#160;:&#160;{{ affectation.remarque }}</div>
		</div>
	</div>
</ng-template>

<ng-template #datePickerButtonTemplate>
	<button type="button" class="fc-datePicker-button fc-button fc-button-primary" #popover="bs-popover" [popover]="datePickerPopoverTemplate" [popoverContext]="getDatePickerPopoverContext()" placement="bottom" containerClass="fit-content" [outsideClick]="true">
		<span class="fc-icon fc-icon-material-icons-round">event</span>
	</button>

	<ng-template #datePickerPopoverTemplate let-selectedDate>
		<div [(ngModel)]="selectedDate.date" datePicker [inline]="true" [storeDate]="true" (ngModelChange)="popover.hide(); onDateSelected($event)"></div>
	</ng-template>
</ng-template>
