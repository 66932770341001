import { Component,Input,OnInit,ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,of } from 'rxjs';
import { first,map,switchMap } from 'rxjs/operators';

import { ControleReglementaireService } from 'src/app/components/vehicule/controle-reglementaire/controle-reglementaire.service';
import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ImageEditorComponent } from 'src/app/share/components/image-editor/image-editor.component';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienService } from '../vehicule/entretien/entretien.service';
import { FactureAnalysisAssociationComponent } from './facture-analysis-association.component';
import { FactureService } from './facture.service';
import { TaxeService } from './taxe/taxe.service';

@Component({
	templateUrl: './facture-analysis.component.html'
})
export class FactureAnalysisComponent implements OnInit {
	/** Identifiant de l'analyse **/
	@Input() idFactureAnalysis: number;

	/** Facture analysée **/
	public factureAnalysis: any = null;

	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Indicateur de présence d'erreur **/
	public hasError: boolean = false;

	/** Liste des types de facture **/
	public listeTypesFacture: Array<{ code: string,libelle: string }>;

	/** Liste des types d'entretien **/
	public listeTypesEntretien: Array<{ code: string,libelle: string }>;

	/** Liste des sous-types de contrôles réglementaires **/
	public listeSousTypes: Array<{ code: string,libelle: string }>;

	/** Utilisateur connecté **/
	public user: User;

	/** Liste des images **/
	public listeImages: Array<{ pageNumber: number,source: string,width: number,height: number }> = [];

	/** Numéro de page de l'image à charger **/
	public pageNumber: number = 0;

	/** Source de l'image courante **/
	public imageSource: any = null;

	/** Vérification de la taxe **/
	public isTaxeValid: boolean = true;

	/** Sujet sur le changement de source d'image */
	public onSourceChangeSubject: Subject<any> = new Subject();

	/** Options personnalisée de l'éditeur d'image **/
	public listeCustomOptions: Array<{ icon: string,disabled: Function,doAction: Function,tooltip: string }>;

	/** Duplication de la facture analysée pour comparaison avant enregistrement **/
	private savedFactureAnalysis: any;

	/** Association **/
	public association: { fournisseur?: boolean,societe?: boolean,nature?: boolean,isDefined?: boolean } = { isDefined: false };

	/** Editeur d'images **/
	@ViewChild('imageEditor') imageEditor: ImageEditorComponent;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<FactureAnalysisComponent>,public factureService: FactureService,private confirmService: ConfirmService,private toastrService: ToastrService,private translateService: TranslateService,private layoutService: LayoutService,private store: Store<AppState>,private taxeService: TaxeService,private rightService: RightService,private entretienService: EntretienService,private bsModalService: BsModalService,private controleReglementaireService: ControleReglementaireService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de facture
		this.listeTypesFacture = this.factureService.getListeTypesFacture();

		//Récupération de la liste des types d'entretien
		this.listeTypesEntretien = this.entretienService.getListeTypesEntretien();

		//Récupération de la liste des sous-types de contrôles réglementaires
		this.listeSousTypes = this.controleReglementaireService.getListeTypes();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});

		//Définition des options personnalisées
		this.listeCustomOptions = [{
			icon: 'arrow_back',
			disabled: () => this.pageNumber == 0,
			doAction: () => this.pageNumber > 0 && this.showPage(this.pageNumber - 1),
			tooltip: this.translateService.instant('imageEditor.actions.previousPage')
		},{
			icon: 'arrow_forward',
			disabled: () => this.pageNumber + 1 == this.listeImages.length,
			doAction: () => this.pageNumber < this.listeImages.length && this.showPage(this.pageNumber + 1),
			tooltip: this.translateService.instant('imageEditor.actions.nextPage')
		}];

		//Chargement de l'analyse
		this.factureService.loadFactureAnalysis(this.idFactureAnalysis).subscribe({
			next: (result: Result) => {
				//Vérification de l'analyse
				if (result.data.factureAnalysis) {
					//Récupération de l'analyse
					this.factureAnalysis = result.data.factureAnalysis;

					//Vérification de la date de la facture analysée
					if (this.factureAnalysis.invoiceDate)
						//Mise à jour de la date de la facture
						this.factureAnalysis.facture.date = this.factureAnalysis.invoiceDate;

					//Définition de la présence d'erreurs
					this.hasError = this.factureAnalysis.multiLicensePlates || this.factureAnalysis.multiInvoiceNumbers;

					//Définition de la liste des images
					this.listeImages = this.factureAnalysis.listeLinks.map(l => ({
						pageNumber: l.pageNumber,
						source: `controller/Blob/readBlob/${l.attachment.idBlob}`,
						width: l.width,
						height: l.height
					}));

					//Récupération de la source de la première image
					this.imageSource = this.listeImages.find(i => i.pageNumber == this.pageNumber)?.source;

					//Vérification de la présence de champs associé aux natures
					if (this.factureAnalysis.natureLAD && this.factureService.hasTypeField(this.factureAnalysis.natureLAD,'RELEVE_CARBURANT'))
						//Définition du l'unité de volume par défaut
						this.factureAnalysis.unite = this.user?.uniteVolume;

					//Duplication de la facture analysée pour comparaison avant enregistrement
					this.savedFactureAnalysis = cloneDeep(this.factureAnalysis);
				}
			}
		});
	}

	/**
	 * Affichage des boîtes englobantes
	 */
	showBoundingBox(typeBoundingBox: string) {
		let boundingBox: any;
		let image: any;
		let addBoundingBox: Function;

		//Récupération de la boîte englobante
		boundingBox = this.factureAnalysis.listeBoundingBoxes?.find(b => b.type == typeBoundingBox);

		//Vérification de la boîte englobante
		if (boundingBox) {
			//Récupération de l'image associée à la boîte
			image = this.listeImages?.find(i => i.pageNumber == boundingBox.pageNumber);

			//Vérification de l'image
			if (image) {
				//Affichage de la bounding box
				addBoundingBox = () => {
					//Ajout d'une boîte englobante agrandie de 4px
					this.imageEditor.addBoundingBox({
						type: typeBoundingBox,
						title: this.translateService.instant(`facture.lad.typeBoundingBox.${typeBoundingBox}`),
						rectangle: {
							left: boundingBox.left - 10,
							top: boundingBox.top - 10,
							width: boundingBox.right - boundingBox.left + 20,
							height: boundingBox.bottom - boundingBox.top + 20
						},
						container: {
							width: image.width,
							height: image.height,
							pageNumber: image.pageNumber
						}
					});
				}

				//Vérification d'un changement de page
				if (this.pageNumber != image.pageNumber) {
					//Redéfinition du numéro de page
					this.pageNumber = image.pageNumber;

					//Redéfinition de la source
					this.imageSource = image?.source;

					//Attente du changement de source
					this.onSourceChangeSubject?.pipe(first()).subscribe({
						next: () => addBoundingBox()
					})
				} else
					//Affichage de la bounding box
					addBoundingBox();
			}
		}
	}

	/**
	 * Vérification de l'existence d'une boîte englobante pour un type
	 */
	hasBoundingBox(typeBoundingBox: string): boolean {
		//Parcours des boxes par type
		return this.factureAnalysis.listeBoundingBoxes?.some(b => b.type == typeBoundingBox);
	}

	/**
	 * Ouverture de la popup d'association
	 */
	public showAssociations(): Observable<unknown> {
		let bsModalRef: BsModalRef<FactureAnalysisAssociationComponent>;

		//Vérification des associations
		this.association.fournisseur = this.savedFactureAnalysis.vendor && (!this.savedFactureAnalysis?.facture?.fournisseur || this.savedFactureAnalysis?.facture?.fournisseur?.idFournisseur != this.factureAnalysis?.facture?.fournisseur?.idFournisseur);
		this.association.societe = this.savedFactureAnalysis.clientName && (!this.savedFactureAnalysis?.facture?.societe || this.savedFactureAnalysis?.facture?.societe?.idService != this.factureAnalysis?.facture?.societe?.idService);
		this.association.nature = !this.factureAnalysis?.facture?.fournisseur?.natureLAD || this.factureAnalysis?.facture?.fournisseur?.natureLAD.idNature != this.factureAnalysis?.natureLAD?.idNature;

		//Vérification de l'état des associations
		if (!this.association.isDefined && (this.association.fournisseur || this.association.societe || this.association.nature)) {
			//Affichage de la popup
			bsModalRef = this.bsModalService.show(FactureAnalysisAssociationComponent,{
				initialState: {
					factureAnalysis: cloneDeep(this.factureAnalysis),
					association: this.association
				},
				class: 'modal-xs'
			});

			//Retour du résultat
			return bsModalRef.onHidden.pipe(
				first(),
				map(() => bsModalRef.content?.result?.options || null),
				map(options => {
					//Vérification de la présence d'options
					if (options)
						//Ajout des options
						this.factureAnalysis = Object.assign(this.factureAnalysis,options);

					//Association définie
					this.association.isDefined = true;

					return this.factureAnalysis;
				})
			);
		} else
			//Retour d'un observable
			return of(this.factureAnalysis);
	}

	/**
	 * Enregistrement de la facture
	 */
	saveAnalysis() {
		//Vérification de la liste des taxes
		if (this.verifyListeTaxes()) {
			//Affichage de la pop-up d'association si besoin
			this.showAssociations().pipe(
				switchMap(() => this.factureService.saveFactureFromAnalysis({
					...this.factureAnalysis.facture,
					analysis: {
						...this.factureAnalysis,
						facture: null
					}
				}))
			).subscribe({
				next: (result: Result) => {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Retour à la liste des factures rechargées
						this.goToListeFactures();
					} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						let listeDoublons: Array<string> = [];

						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0)) {
							//Ajout des libellés
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));
							listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));
							listeDoublons.push(this.translateService.instant('actions.doublon.type'));
						}

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}

					//Vérification de la présence d'une erreur d'association pour le fournisseur
					if (result?.data?.doublonFournisseurAlias)
						//Message d'avertissement
						this.toastrService.warning(this.translateService.instant('facture.lad.doublonFournisseurAlias',{ code: this.factureAnalysis?.vendor,fournisseur: this.factureAnalysis?.facture?.fournisseur?.libelle }));

					//Vérification de la présence d'une erreur d'association pour la société
					if (result?.data?.doublonSocieteAlias)
						//Message d'avertissement
						this.toastrService.warning(this.translateService.instant('facture.lad.doublonSocieteAlias',{ code: this.factureAnalysis?.clientName,societe: this.factureAnalysis?.facture?.societe?.libelle }));

					//Vérification de la présence d'une erreur d'association pour la nature
					if (result?.data?.errorNatureLAD)
						//Message d'avertissement
						this.toastrService.warning(this.translateService.instant('facture.lad.errorNatureLAD',{ code: this.factureAnalysis?.natureLAD?.libelle,fournisseur: this.factureAnalysis?.facture?.fournisseur?.libelle }));
				}
			});
		}
	}

	/**
	 * Suppression d'une facture analysée
	 */
	deleteAnalysis() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la facture
					this.factureService.deleteFacture(this.factureAnalysis.facture).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Retour à la liste des factures rechargées
								this.goToListeFactures();
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Rechargement de la liste des factures
	 */
	goToListeFactures() {
		//fermeture de la popup
		this.bsModalRef.hide();

		//Redirection vers la liste des factures
		this.layoutService.goToByState('listeFactures',{ reloadOnSameUrl: true });
	}

	/**
	 * Affichage des taxes
	 */
	showTaxes(detail = this.factureAnalysis) {
		//Affichage de la popup des taxes
		this.taxeService.showTaxes(detail,{ idPays: detail?.pays?.idPays || this.user?.tenant?.tenantDescription?.pays?.idPays,date: detail?.invoiceDate,disabled: !this.rightService.hasRight(TypeDroit.FACTURE_LAD,'creation') }).subscribe({
			next: (listeTaxes: any) => {
				//Définition du résultat
				detail.listeTaxes = listeTaxes;

				//Recalcul des montants (si besoin)
				this.verifyListeTaxes();
			}
		});
	}

	/**
	 * Calcul du montant de taxe total
	 */
	getSommeTaxe(): number {
		//Calcul de la taxe
		return this.factureAnalysis.listeTaxes.filter((t) => !!t.montant).map((t) => t.montant).reduce((total,r) => total + r,0);
	}

	/**
	 * Détection du changement de nature
	 */
	onNatureChange() {
		//Réinitialisation des champs
		this.factureAnalysis.quantite = null;
		this.factureAnalysis.unite = null;
		this.factureAnalysis.typeEntretien = null;
		this.factureAnalysis.typeEntretienItem = null

		//Annulation de l'association
		this.association.isDefined = false;

		//Vérification de la présence de champs associé aux natures
		if (this.factureService.hasTypeField(this.factureAnalysis.natureLAD,'RELEVE_CARBURANT'))
			//Définition du l'unité de volume par défaut
			this.factureAnalysis.unite = this.factureAnalysis.vehicule?.carburant?.uniteDefaut || this.user?.uniteVolume;
	}

	/**
	 * Vérification de la liste des taxes
	 */
	verifyListeTaxes(): boolean {
		//Vérification de la liste des taxes
		this.isTaxeValid = this.taxeService.verifyListeTaxes(this.factureAnalysis.facture.montant,this.factureAnalysis.listeTaxes);

		//Vérification de la validité de la taxe
		if (this.isTaxeValid)
			//Calcul du montant hors taxe
			this.factureAnalysis.facture.montantHorsTaxe = this.factureAnalysis?.facture?.montant - (this.factureAnalysis.listeTaxes?.reduce((total,r) => total + r.montant,0) || 0);

		//Retour de la validité de la taxe
		return this.isTaxeValid;
	}

	/**
	 * Affichage de la page
	 */
	public showPage(index) {
		//Redéfinition du numéro de page
		this.pageNumber = index;

		//Récupération de la source de la première image
		this.imageSource = this.listeImages.find(i => i.pageNumber == this.pageNumber)?.source;
	}

	/**
	 * Détection d'un changement de véhicule
	 */
	onVehiculeChange() {
		//Vérification du type de véhicule
		if (this.factureAnalysis.vehicule?.typeVehicule == 'ENGIN') {
			//Réinitialisation du type d'entretien
			this.factureAnalysis.typeEntretien = null;

			//Récupération de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien().filter(t => t.code == 'COURANT');
		} else
			//Récupération de la liste des types d'entretien
			this.listeTypesEntretien = this.entretienService.getListeTypesEntretien();

		//Vérification de la présence d'une unité par défaut et de l'absence d'unité sur le relevé d'énergie
		if (this.factureAnalysis.vehicule?.carburant?.uniteDefaut)
			//Mise à jour de l'unité
			this.factureAnalysis.unite = this.factureAnalysis.vehicule.carburant.uniteDefaut;
	}

	/**
	 * Interception d'un changement de type d'entretien
	 */
	onTypeEntretienChange() {
		//Remise à zéro du type d'entretien courant
		this.factureAnalysis.typeEntretienItem = null;

		//Mise à jour du sous-type
		this.factureAnalysis.sousType = this.factureAnalysis.typeEntretien == 'CONTROLE_REGLEMENTAIRE' ? 'TECHNIQUE' : null;
	}
}