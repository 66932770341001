<div class="container-fluid">
	<form #grilleAttributionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>vehicule.grilleAttribution.generalites.titre</span></h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>vehicule.grilleAttribution.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="grilleAttribution.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>vehicule.grilleAttribution.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="grilleAttribution.reference" maxlength="100" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>vehicule.grilleAttribution.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.grilleAttribution.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" [(ngModel)]="grilleAttribution.actif"><span translate>vehicule.grilleAttribution.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label><span translate>vehicule.grilleAttribution.remarque</span></label>
						<div>
							<textarea class="form-control" rows="3" name="remarque" [(ngModel)]="grilleAttribution.remarque"></textarea>
						</div>
					</div>
				</div>
				<div class="card-body card-padding m-t-20" *ngIf="!grilleAttribution.specifique">
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>vehicule.grilleAttribution.generalites.listeGroupes.titre</span></h4>
						</div>
					</div>
					<basket [model]="grilleAttribution" propertyName="listeGroupes" colWidthLabel="2" colWidthAutocomplete="6" type="groupe" [labelAutocomplete]="'vehicule.grilleAttribution.groupe.ajout' | translate" [hasError]="isListeGroupesInvalid" [listeBasketsOptions]="listeSousPaniers"></basket>
				</div>
				<div class="card-body card-padding m-t-20" *ngIf="grilleAttribution.specifique">
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>vehicule.grilleAttribution.demandeVehiculeOrigine.title</span></h4>
						</div>
					</div>
					<div class="form-group">
						<label><span translate>vehicule.grilleAttribution.demandeVehiculeOrigine.beneficiaire</span></label>
						<div *ngIf="grilleAttribution?.demandeVehicule?.typeBeneficiaire === 'COLLABORATEUR'"><a [routerLink]="[]" (click)="goToDemandeVehicule()">{{ grilleAttribution.demandeVehicule.user.prenom + ' ' + grilleAttribution.demandeVehicule.user.nom + ' (' + grilleAttribution.demandeVehicule.idDemandeVehicule + ')' }}</a></div>
						<div *ngIf="grilleAttribution?.demandeVehicule?.typeBeneficiaire === 'SERVICE'"><a [routerLink]="[]" (click)="goToDemandeVehicule()">{{ grilleAttribution.demandeVehicule.societe.libelle + ' - ' + grilleAttribution.demandeVehicule.service.libelle + ' (' + grilleAttribution.demandeVehicule.idDemandeVehicule + ')' }}</a></div>
					</div>
				</div>
			</fieldset>
			<div class="card focus">
				<data-customization entite="com.notilus.data.vehicule.GrilleAttribution" [object]="grilleAttribution" [disabled]="!(null | right:'creation')"></data-customization>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteGrilleAttribution();" *ngIf="grilleAttribution?.idGrilleAttribution && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveGrilleAttribution();" *ngIf="null | right:'creation'" [disabled]="grilleAttributionForm.invalid">
				<span [translate]="'actions.' + (grilleAttribution?.idGrilleAttribution ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="grilleAttribution?.idGrilleAttribution" [formDetectChanges]="{ doAction: close,isDirty: grilleAttributionForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>