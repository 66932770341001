import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ContratCRMOffreListItemComponent } from './contrat-crm-offre-list-item.component';

@Component({
	selector: 'contrat-crm-offre-list',
	templateUrl: './contrat-crm-offre-list.component.html'
})
export class ContratCRMOffreListComponent extends PageContentItem implements OnInit {
	/** Contrat CRM **/
	@Input() contratCRM: any;

	/** Fonction d'édition **/
	@Input() addLienOffre: () => void;

	/** Résumé **/
	@Input() resume: { nbOffres: number };

	/** Données **/
	liste: ListView<any,ContratCRMOffreListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContratCRMOffreListItemComponent>({
			uri: () => `/controller/ContratCRM/filtreOffresForContratCRM/${this.contratCRM.idContrat}`,
			title: this.translateService.instant('contratCRM.elements.offre.liste.title'),
			component: ContratCRMOffreListItemComponent,
			isLocal: true,
			extraOptions: {
				addLienOffre: this.addLienOffre
			},
			listeFilters: [{
				clef: 'offre',
				autocomplete: {
					type: 'offre'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbOffres--;
	}
}