import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './demande-cotation.entites';
import { DemandeCotationService } from './demande-cotation.service';

@Component({
	templateUrl: './demande-cotation-list-item.component.html'
})
export class DemandeCotationListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Statuts des demandes de cotation **/
	mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private mailHistoryService: MailHistoryService,private historyService: HistoryService,public demandeCotationService: DemandeCotationService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des statuts
		this.mapStatuts = this.demandeCotationService.getMapStatuts();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idDemandeCotation,object?.idDemandeCotation && !this.data?.idDemandeCotation);
	}

	/**
	 * Affichage de la demande de cotation
	 */
	showDemandeCotation() {
		//Redirection vers la demande de cotation
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesCotation-detail',{
			routeParams: {
				idDemandeCotation: this.data.idDemandeCotation
			},
			routeData: {
				idCotation: this.data.cotation?.idCotation
			}
		});
	}

	/**
	 * Suppression de l'objet
	 */
	deleteDemandeCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la demande de cotation
					this.demandeCotationService.deleteDemandeCotation(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression depuis la liste
								this.onObjectChange(null);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.cotation.DemandeCotation',this.data.idDemandeCotation);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.cotation.DemandeCotation',this.data.idDemandeCotation);
	}

	/**
	 * Réalisation de l'action
	 */
	doAction(action: 'TRANSMETTRE' | 'RELANCER' | 'RAPPELER' | 'ANNULER') {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.confirmation`,{
			listeContacts: this.demandeCotationService.getListeContactsAsString(this.data.cotation?.listeContacts)
		}),{ actionColor: 'primary' }).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Réalisation de l'action sur la demande de cotation
					this.demandeCotationService.executeActionForDemande(action,this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.success`));

								//Mise à jour de l'objet dans la liste
								this.onObjectChange(result.data.demandeCotation);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('demandeCotation.actions.error'));
						}
					});
				}
			}
		});
	}
}