<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.type.reference.substring(0,3) }}</div>
<div class="lv-title">
	<a [routerLink]="['/ContratFinancement/loadContratFinancement',data.idContrat]">{{ data.reference + ' (' + data.fournisseur.libelle + ' - ' + data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.dateDebut"><span [translate]="'contrat.financement.'+(data.type.mode != 'ACQUISITION' ? 'dateDebut' : 'dateAchat')"></span>&#160;:&#160;{{ data.dateDebut | date:'shortDate' }}</li>
	<li *ngIf="data.dateFin && !data.dateRuptureAnticipee"><span translate>contrat.financement.dateFin</span>&#160;:&#160;{{ data.dateFin | date:'shortDate' }}</li>
	<li *ngIf="data.listeLoisRoulage?.length > 1" [tooltip]="getListeAvenantsAsString()"><span translate>contrat.financement.nbAvenants</span>&#160;:&#160;{{ data.listeLoisRoulage.length - 1 }}</li>
	<li *ngIf="data.dateRuptureAnticipee"><span translate>contrat.financement.dateRuptureAnticipee</span>&#160;:&#160;{{ data.dateRuptureAnticipee | date:'shortDate' }}</li>
	<li *ngIf="data.lastLoiRoulage"><span translate>contrat.financement.loiRoulage.item</span>&#160;:&#160;<span translate [translateParams]="{ duree: data.lastLoiRoulage.duree,distance: (data.vehicule.uniteUsage == 'DISTANCE' ? (data.lastLoiRoulage.distance | number) : (data.lastLoiRoulage.temps | number)),unite: data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt) : ('duree.heure.libelleCourt' | translate) }">contrat.financement.loiRoulage.dureeMois</span></li>
	<li *ngIf="data.etat"><span translate>contrat.financement.etat.item</span>&#160;:&#160;<span [translate]="'contrat.financement.etat.'+data.etat"></span></li>
	<li *ngIf="data.etat == 'ACHAT'"><span translate>contrat.financement.dateAchat</span>&#160;:&#160;{{ data.dateAchat | date:'shortDate' }}</li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteContratFinancement();" translate>actions.supprimer</a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		<li *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation') && data.vehicule"><a [routerLink]="[]" (click)="goToVehicule();"><span translate>contrat.financement.actions.accederVehicule</span></a></li>
		<li *ngIf="(null | right:'creation') && data.etat == 'LOCATION'"><a [routerLink]="[]" (click)="doAchatVehicule();"><span translate>contrat.financement.actions.acheterVehicule</span></a></li>
	</ul>
</div>