import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPairs } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypePlanning } from 'src/app/domain/planning/planning';
import { environment } from 'src/environments/environment';

@Injectable()
export class PlanningService {
	/** Liste des regroupements possibles **/
	private readonly listeTypesRegroupement: Array<{ code: string,listeTypesPlanning: Array<TypePlanning>,mapGroupFilterKeys: { [index: string]: string },getLabel: (groupe: any) => string }> = [{
		code: 'MODELE',
		listeTypesPlanning: [TypePlanning.VEHICULE],
		mapGroupFilterKeys: {
			'idGroupe': '*modele.idModele'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'GENRE',
		listeTypesPlanning: [TypePlanning.VEHICULE],
		mapGroupFilterKeys: {
			'idGroupe': '*genre.idGenre'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'SOCIETE',
		listeTypesPlanning: [TypePlanning.VEHICULE,TypePlanning.USER],
		mapGroupFilterKeys: {
			'idGroupe': '*societe.idService'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'SERVICE',
		listeTypesPlanning: [TypePlanning.VEHICULE,TypePlanning.USER],
		mapGroupFilterKeys: {
			'idGroupe': '*service.idService'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'SERVICE_PARENT',
		listeTypesPlanning: [TypePlanning.VEHICULE,TypePlanning.USER],
		mapGroupFilterKeys: {
			'idGroupe': '*service.*parent.idService'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'POOL',
		listeTypesPlanning: [TypePlanning.VEHICULE],
		mapGroupFilterKeys: {
			'idGroupe': '*pool.idPool'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'CATEGORIE',
		listeTypesPlanning: [TypePlanning.USER],
		mapGroupFilterKeys: {
			'idGroupe': '*categorie.idCategorie'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	},{
		code: 'ENERGIE',
		listeTypesPlanning: [TypePlanning.VEHICULE],
		mapGroupFilterKeys: {
			'idGroupe': '*carburant.idCarburant'
		},
		getLabel: groupe => groupe.libelleParent + ' (' + groupe.libelleGroupe + ')'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Récupération de la liste des types de regroupement
	 */
	public getListeTypesRegroupement(typePlanning: TypePlanning): Array<{ code: string,mapGroupFilterKeys: { [index: string]: string },getLabel: (groupe: any) => string }> {
		//Liste des types d'ordre
		return this.listeTypesRegroupement.filter(t => t.listeTypesPlanning.includes(typePlanning));
	}

	/**
	 * Chargement des véhicules
	 */
	public filtreEntites(typePlanning: TypePlanning,dateDebut: moment.Moment,dateFin: moment.Moment,searchSpec: any): Observable<any> {
		//Recherche des véhicules
		return this.http.post<Result>(`${environment.baseUrl}/controller/Planning/filtreEntites/${typePlanning}/${dateDebut.valueOf()}/${dateFin.valueOf()}`,searchSpec).pipe(map(result => {
			//Retour de la liste des véhicules
			return result?.data || null;
		}));
	}

	/**
	 * Chargement des groupes de véhicule
	 */
	public filtreEntitesBy(typePlanning: TypePlanning,typeRegroupement: string,dateDebut: moment.Moment,dateFin: moment.Moment,searchSpec: any,numPage: number,nbObjetsParPage: number): Observable<Page<any>> {
		//Initialisation de la recherche
		searchSpec = searchSpec || { listeFilter: [] };

		//Page sélectionnée
		searchSpec.numPage = numPage || 0;

		//Nombre d'objets par page
		searchSpec.nbObjetsParPage = nbObjetsParPage || 25;

		//Chargement des groupes de véhicules
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Planning/filtreEntitesBy/${typePlanning}/${typeRegroupement}/${dateDebut.valueOf()}/${dateFin.valueOf()}`,searchSpec);
	}

	/**
	 * Génération des filtres de recherche associés au groupe fourni
	 */
	public generateListeFiltersForGroupe(typeRegroupement: any,groupe: any): Array<any> {
		//Retour de la liste des filtres associés au groupe fourni
		return groupe && toPairs<string>(typeRegroupement.mapGroupFilterKeys).map(pair => {
			//Retour du filtre pour la clé courante
			return {
				clef: groupe[pair[0]] !== null ? pair[1].replace(/\*/g,'') : pair[1],
				valeur: groupe[pair[0]],
				typeComparaison: groupe[pair[0]] !== null ? TypeComparaison.EQUAL : TypeComparaison.IS_NULL,
				type: TypeFilter.STRING
			};
		}) || null;
	}
}