<form #motifForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!motif.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation') || typeReferentiel == 'STANDARD' && !rightService.isRoot()">
				<div class="row" *ngIf="motif.motifOrigine">
					<div class="form-group">
						<label class="col-md-2"><span translate>workflow.motif.personnalise.libelle</span></label>
						<div class="col-md-10">
							<div>
								<mat-checkbox name="custom" #custom="ngModel" [(ngModel)]="motif.custom"><span translate>workflow.motif.personnalise.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
				<fieldset [disabled]="typeReferentiel == 'LOCAL' && motif.motifOrigine && !motif.custom">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': entite.invalid }">
								<label translate>workflow.motif.entite</label>
								<div>
									<selectpicker name="entite" #entite="ngModel" [(ngModel)]="motif.entity" required>
										<mat-option *ngFor="let entite of listeEntites" [value]="entite.code">{{ entite.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>workflow.motif.libelle</label>
								<div>
									<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="motif.libelle" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>workflow.motif.reference</label>
								<div>
									<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="motif.reference" maxlength="100" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label translate>workflow.motif.actif.libelle</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="motif.actif"><span translate>workflow.motif.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: motifForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteMotif();" *ngIf="motif?.idMotif && (null | right:'suppression') && (typeReferentiel == 'LOCAL' || rightService.isRoot())">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveMotif();" *ngIf="(null | right:'creation') && (typeReferentiel != 'STANDARD' || rightService.isRoot())" [disabled]="motifForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>