import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ConversationService } from './conversation.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	templateUrl: './conversation-list-item.component.html'
})
export class ConversationListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private conversationService: ConversationService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idSession,object?.idSession && !this.data?.idSession);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteConversation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la conversation
					this.conversationService.deleteConversation(this.data).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la conversation
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}