import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ChartListItemComponent } from './chart-list-item.component';
import { ChartListComponent } from './chart-list.component';
import { ChartComponent } from './chart.component';
import { ChartService } from './chart.service';
import { ChartEditComponent } from './chart-edit.component';
import { ChartDataListComponent } from './chart-data-list.component';
import { ChartDataEditComponent } from './chart-data-edit.component';
import { ChartGraphiqueListComponent } from './chart-graphique-list.component';
import { ChartGraphiqueEditComponent } from './chart-graphique-edit.component';
import { ChartPreviewComponent } from './chart-preview.component';
import { DashboardModule } from 'src/app/components/dashboard/dashboard.module';

@NgModule({
	imports: [ShareModule,DashboardModule],
	declarations: [ChartListComponent,ChartListItemComponent,ChartComponent,ChartEditComponent,ChartDataListComponent,ChartDataEditComponent
		,ChartGraphiqueListComponent,ChartGraphiqueEditComponent,ChartPreviewComponent
	],
	providers: [ChartService]
})
export class ChartModule { }