import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';

@Component({
	selector: 'sgi-lot',
	templateUrl: './sgi-lot.component.html'
})
export class SgiLotComponent implements OnInit {
	/** Elément courant **/
	@Input() sgiLot: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor() {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du lot sgi
		this.sgiLot = Object.assign({},this.sgiLot);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}