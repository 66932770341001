import { ConducteurAddComponent } from 'src/app/components/vehicule/conducteur/conducteur-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des conducteurs
 */
export default class ConducteurOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.conducteur';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeConducteur/findAllConducteurs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.prenom} ${item.nom} (${item.matricule}${(item.societe ? ' - ' + item.societe.libelle : '')})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item.prenom?.substring(0,1).toUpperCase() + item.nom?.substring(0,1).toUpperCase(); }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.Conducteur';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idConducteur'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'nom',
			isDefault: true
		},{
			clef: 'prenom',
			isDefault: true
		},{
			clef: 'matricule',
			isDefault: true
		}];
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'conducteur.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return ConducteurAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.VEHICULE_CONDUCTEUR;
}