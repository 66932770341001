<form #categorieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!categorie.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>categorie.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="categorie.libelle" required [disabled]="categorie.readOnly" maxlength="300"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>categorie.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="categorie.reference" required [disabled]="categorie.readOnly" maxlength="100"/>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>categorie.actif</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="categorie.actif"><span translate>categorie.actifDescription</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: categorieForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteCategorie();" *ngIf="categorie.idCategorie && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveCategorie();" *ngIf="null | right:'creation'" [disabled]="categorieForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>