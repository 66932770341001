import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TemporalSelectorService {
	/** Liste des temporals **/
	private listeTemporals: Array<string> = ['DATE_TODAY','DATE_WEEK','DATE_MONTH','DATE_QUARTER','DATE_YEAR','DATE_WEEKDAY','AUCUNE'];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private datePipe: DatePipe) {}

	/**
	 * Récupération de la liste des temporals
	 */
	public getListeTemporals(operateur?: 'EQUAL' | 'NOT_EQUAL' | 'GREATER' | 'GREATER_OR_NULL' | 'GREATER_OR_EQUAL' | 'LESS' | 'LESS_OR_NULL' | 'LESS_OR_EQUAL' | 'IS_NULL' | 'IS_NOT_NULL' | 'ROLLING_PERIOD'): Array<{ code: string,libelle: string }> {
		//Retour de la liste
		return this.listeTemporals.filter(code => !operateur || code != 'DATE_WEEKDAY' || ['EQUAL','NOT_EQUAL'].includes(operateur)).map(code => ({
			code,
			libelle: this.translateService.instant(`rule.dateFunction.${code}`)
		}));
	}

	/**
	 * Formatage de la donnée
	 */
	formatValue({ temporal,date }: { temporal: string,date: any }): string {
		//Vérification de la date
		if (date)
			//Formattage de la date
			return this.datePipe.transform(date,'shortDate');
		else if (temporal)
			//Retour de la fonction
			return '@' + this.translateService.instant(`rule.dateFunction.${temporal}`);
		else
			//Sélection d'une valeur
			return this.translateService.instant('rule.selector.temporal.emptyValue');
	}
}