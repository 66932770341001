<div class="tile-inner">
	<div class="pull-left lv-avatar">
		{{ !isAvatarIcon ? extraOptions.displayAvatar?.(data,extraOptions.translateService) : '' }}
		<icon *ngIf="isAvatarIcon" [name]="extraOptions.displayAvatar?.(data)?.icon || ''" [type]="extraOptions.displayAvatar?.(data)?.iconType"></icon>
	</div>
	<div class="lv-title">
		<a [routerLink]="" class="clickable" (click)="extraOptions.close(data)">
			<span>{{ extraOptions.displayItem(data,extraOptions.translateService,extraOptions.datePipe,extraOptions.decimalPipe) }}</span>
		</a>
	</div>

	<ul class="lv-attrs" *ngIf="listeSearchItemFields?.length">
		<li *ngFor="let field of listeSearchItemFields">
			<span [translate]="field.title"></span>&#160;:&#160;<span *ngIf="field.countryCode" class="fi" [ngClass]="'fi-'+(field.countryCode != 'JA' ? field.countryCode.toLowerCase() : 'jp')"></span><span [ngClass]="field.countryCode ? 'm-l-5' : ''">{{ field.value }}</span>
		</li>
	</ul>
</div>