import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ControleReglementaireService } from './controle-reglementaire.service';

@Component({
	selector: 'controle-reglementaire-edit',
	templateUrl: './controle-reglementaire-edit.component.html'
})
export class ControleReglementaireEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() controleReglementaire: any;

	/** Supression du contrôle réglementaire **/
	@Input() deleteControleReglementaire: () => void;

	/** Liste des sous-types **/
	public listeSousTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types de critères **/
	public listeTypesCriteres: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private controleReglementaireService: ControleReglementaireService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des listes des sous-types
		this.listeSousTypes = this.controleReglementaireService.getListeTypes();

		//Récupération de la liste des critères
		this.listeTypesCriteres = this.controleReglementaireService.getListeTypesCriteres();

		//Copie du contrôle réglementaire
		this.controleReglementaire = cloneDeep(this.controleReglementaire);
	}

	/**
	 * Enregistrement du contrôle réglementaire
	 */
	saveControleReglementaire() {
		//Enregistrement du contrôle réglementaire
		this.controleReglementaireService.saveControleReglementaire(this.controleReglementaire).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du contrôle réglementaire
					this.controleReglementaire = result.data.controleReglementaire;

					//Fermeture de l'édition
					this.close(this.controleReglementaire);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}