<form #fournisseurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compteAuxiliaire.elements.fournisseur.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
							<label translate>comptabilite.compteAuxiliaire.fournisseur</label>
							<div>
								<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="lienFournisseur.fournisseur" required></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="p-t-20">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compteAuxiliaire.attribut1</label>
								<div>
									<input type="text" name="attribut1" #attribut1="ngModel" class="form-control" [(ngModel)]="lienFournisseur.attribut1" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compteAuxiliaire.attribut2</label>
								<div>
									<input type="text" name="attribut2" #attribut2="ngModel" class="form-control" [(ngModel)]="lienFournisseur.attribut2" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compteAuxiliaire.attribut3</label>
								<div>
									<input type="text" name="attribut3" #attribut3="ngModel" class="form-control" [(ngModel)]="lienFournisseur.attribut3" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compteAuxiliaire.attribut4</label>
								<div>
									<input type="text" name="attribut4" #attribut4="ngModel" class="form-control" [(ngModel)]="lienFournisseur.attribut4" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteLienFournisseur(lienFournisseur,close);" *ngIf="deleteLienFournisseur && lienFournisseur?.idLien && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienFournisseur();" *ngIf="null | right:'creation'" [disabled]="fournisseurForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: fournisseurForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>