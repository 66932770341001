import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { TypeDashboard } from 'src/app/domain/dashboard/dashboard';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DashboardService } from './dashboard.service';

@Component({
	template: ''
})
export class DashboardRedirectorComponent implements OnInit {
	/** Type de dashboard **/
	private typeDashboard: TypeDashboard;

	/**
	 * Constructeur
	 */
	constructor(private activatedRoute: ActivatedRoute,private dashboardService: DashboardService,private layoutService: LayoutService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Lecture du type de dashboard
		this.activatedRoute.params.pipe(
			first(),
			filter(p => p.typeDashboard),
			map(p => p.typeDashboard),
			tap(typeDashboard => this.typeDashboard = typeDashboard),
			switchMap(typeDashboard => this.dashboardService.hasDashboard(typeDashboard))
		).subscribe({
			next: hasDashboard => {
				//Vérification de la présence d'un dashboard
				if (hasDashboard) {
					//Redirection vers le dashboard
					this.layoutService.goToByState('dashboard-noreset',{
						routeParams: {
							typeDashboard: this.typeDashboard
						}
					});
				} else {
					//Redirection vers la liste
					this.dashboardService.showListe(this.typeDashboard);
				}
			}
		});
	}
}