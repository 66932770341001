import { Component,ElementRef,EventEmitter,Input,OnDestroy,OnInit,Output,ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep,isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';

import { AppState } from 'src/app/domain/appstate';
import { Droit } from 'src/app/domain/droit/droit';
import { TypeGroupe,TypeTenant } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { DroitService } from './droit.service';

@Component({
	selector: 'droits',
	templateUrl: './droits.component.html'
})
export class DroitsComponent implements OnInit,OnDestroy {
	/** Liste des droits utilisé par l'objet **/
	@Input() listeDroits: Array<Droit>;

	/** Autorisation du bouton édition **/
	@Input() canEdit: boolean = false;

	/** Type de cible **/
	@Input() source: 'OFFRE' | 'COMPTE_SERVICE' | 'ARTICLE' | 'TENANT';

	/** Indicateur d'évaluation du type de tenant des droits **/
	@Input() isIgnoreTenantType: boolean = false;

	/** Type de passage en mode édition **/
	@Input() isEditAsPageContent: boolean = false;

	/** Mode édition **/
	@Input() edition: { isEdition: boolean,typeGroupe?: TypeGroupe } = { isEdition: false };

	/** Fonction de restriction des droits **/
	@Input() applyRestriction?: (mapDroits: Map<any,any>) => Map<any,any>;

	/** Notification de l'enregistrement de la liste des droits (complets) **/
	@Output() onSave: EventEmitter<{ close: Function,listeDroits: Array<Droit> }> = new EventEmitter<{ close: Function,listeDroits: Array<Droit> }>();

	/** Notification de la fermeture du mode edition **/
	@Output() onClose: EventEmitter<Array<Droit>> = new EventEmitter<Array<Droit>>();

	/** Notification de la demande d'ouverture du mode édition **/
	@Output() onEdit: EventEmitter<TypeGroupe> = new EventEmitter<TypeGroupe>();

	/** Bouton 'Fermer' **/
	@ViewChild('closeButton') closeButton: ElementRef;

	/** Map des droits affichés **/
	public mapDroits: Map<any,any>;

	/** Copie de la map des droits **/
	private savedMapDroits: Map<any,any>;

	/** Indicateur d'affichage des groupes **/
	public groupeToggled: any = {};

	/** Utilisateur (fictif) */
	private virtualUser: User;

	/** Edition d'un groupe **/
	private onEditGroupeSubscription: Subscription;

	/** Fermeture de la page **/
	private onCloseSubscription: Subscription;

	/** Souscription sur le store **/
	private storeSubscription: Subscription;

	/** Utilisateur connecté **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private menuService: MenuService,private droitService: DroitService,private store: Store<AppState>) {
		//Vérification de la possibilité de fermer le composant
		if (this.close)
			//Binding de la méthode
			this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let typeGroupeToEdit: TypeGroupe;

		//Récupération de l'utilisateur connecté
		this.storeSubscription = this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session?.user,userOrigine: state.session?.userOrigine })).subscribe(({ user }) => {
			//Mise à jour des utilisateurs
			this.user = user;

			//Vérification que le composant n'est pas ouvert directement en mode édition
			if (!this.edition.isEdition) {
				//Souscription à l'abonnement pour afficher/éditer un groupe
				this.onEditGroupeSubscription = this.droitService.onEditGroupe.subscribe({
					next: (typeGroupe: TypeGroupe) => {
						//Vérification du formulaire
						if (this.isDirty()) {
							//Fermeture du composant
							this.closeButton?.nativeElement?.click();

							//Définiton du prochain groupe à éditer
							typeGroupeToEdit = typeGroupe;
						} else
							//Edition du groupe
							this.editGroupe(typeGroupe);
					}
				});

				//Souscription à l'abonnement lors de la fermeture de la page
				this.onCloseSubscription = this.onClose.subscribe({
					next: () => {
						//Vérification d'un groupe à éditer
						if (typeGroupeToEdit) {
							//Passage en mode édition pour le groupe
							this.editGroupe(typeGroupeToEdit);

							//Réinitialisation du groupe à éditer
							typeGroupeToEdit = undefined;
						}
					}
				});
			}

			//Vérification de la source
			if (this.source == 'OFFRE' || this.source == 'COMPTE_SERVICE' || this.source == 'ARTICLE' || this.source == 'TENANT') {
				//Définition de l'utilisateur
				this.virtualUser = <any>{
					type: 'ADMINISTRATEUR',
					tenant: {
						type: TypeTenant.CLIENT
					}
				};
			} else
				//Utilisateur non défini (utilisateur connecté)
				this.virtualUser = undefined;

			//Récupération des droits
			this.mapDroits = this.menuService.getMapDroits(this.listeDroits,this.virtualUser,null,this.isIgnoreTenantType);

			//Application des restrictions
			this.mapDroits = this.applyRestriction?.(this.mapDroits) || this.mapDroits;

			//Duplication de la liste des droits (pour vérification du formulaire)
			this.savedMapDroits = cloneDeep(this.mapDroits);
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.onEditGroupeSubscription?.unsubscribe();
		this.onCloseSubscription?.unsubscribe();
		this.storeSubscription?.unsubscribe();
	}

	/**
	 * Enregistrement de la liste des droits
	 */
	public saveDroits() {
		let listeDroits: Array<Droit>;

		//Conversion des droits en liste
		listeDroits = this.convertMapDroitsToFlatList();

		//Notification de l'enregistrement de l'objet
		this.onSave.emit({ listeDroits,close: this.close });
	}

	/**
	 * Conversion de la map en liste
	 */
	private convertMapDroitsToFlatList(mapDroits: Map<any,any> = this.mapDroits): Array<Droit> {
		let listeDroits: Array<Droit> = [];

		//Itération sur les éléments de map
		mapDroits.forEach((item: Map<any,any> | Droit) => {
			//Vérification de la présence d'une map
			if (item instanceof Map)
				//Concaténation de la liste des droits
				listeDroits = listeDroits.concat(this.convertMapDroitsToFlatList(item));
			else if (item.creation || item.suppression || item.consultation)
				//Ajout du droit à la liste
				listeDroits.push(item);
		});

		//Retour de la liste des droits
		return listeDroits;
	}

	/**
	 * Fermeture du composant d'édition
	 */
	public close(object?: { listeDroits: Array<Droit> }) {
		//Vérification de la présence d'une liste de droits
		if (object?.listeDroits) {
			//Récupération des droits
			this.mapDroits = this.menuService.getMapDroits(object?.listeDroits,this.virtualUser,null,this.isIgnoreTenantType);

			//Duplication de la liste des droits (vérification du formulaire)
			this.savedMapDroits = cloneDeep(this.mapDroits);
		} else
			//Réinitialisation de la liste des droits
			this.mapDroits = cloneDeep(this.savedMapDroits);

		//Réinitialisation du mode édition
		this.edition = { isEdition: false };

		//Notification de la fermeture du composant
		this.onClose.emit();
	}

	/**
	 * Demande de passage en mode édition
	 */
	public editGroupe(typeGroupe: TypeGroupe) {
		//Vérification que l'édition est gérée par le composant lui-même
		if (!this.isEditAsPageContent) {
			//Initialisation du mode édition
			this.edition = {
				isEdition: true,
				typeGroupe: typeGroupe
			};

			//Scroll vers le haut de la page
			window.scrollTo(0,0);
		} else
			//Notification du passage en mode édition
			this.onEdit.emit(typeGroupe);
	}

	/**
	 * Vérification du formulaire
	 */
	public isDirty(): boolean {
		//Comparaison des deux listes
		return !isEqual(this.mapDroits,this.savedMapDroits);
	}

	/**
	 * Définition de l'ordre
	 */
	public sortByOrder(a: any,b: any): number {
		//Définition de l'ordre
		return a.key.order - b.key.order;
	}
}
