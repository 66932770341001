import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AmenagementListItemComponent } from './amenagement-list-item.component';

@Component({
	selector: 'amenagement-list',
	templateUrl: './amenagement-list.component.html'
})
export class AmenagementListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AmenagementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AmenagementListItemComponent>({
			uri: `/controller/Amenagement/filtreAmenagements`,
			title: this.translateService.instant('amenagement.liste.title'),
			component: AmenagementListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'typeAmenagement',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'typeAmenagement'
				},
				isDefault: true
			},{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'vehicule',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					filter: 'VEHICULE_FONCTION_SERVICE'
				},
				isDefault: true
			},{
				clef: '-dateDebut',
				type: TypeFilter.DATE
			},{
				clef: '-dateFin',
				type: TypeFilter.DATE
			}],
			defaultOrder: 'vehicule.reference,typeAmenagement.libelle',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(s => !s.idAmenagement) && this.liste.addItem({
					isDisplayed: true
				});
			})
		});
	}
}