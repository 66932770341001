import { Component,Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurService } from './conducteur.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'conducteur-edit',
	templateUrl: './conducteur-edit.component.html'
})
export class ConducteurEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() conducteur: any;

	/** Suppression du conducteur **/
	@Input() deleteConducteur: () => void;

	/** Types de droit **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private conducteurService: ConducteurService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du conducteur
	 */
	saveConducteur() {
		//Enregistrement du conducteur
		this.conducteurService.saveConducteur(this.conducteur).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Fermeture du mode édition
				this.close(result.data?.conducteur);
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.collaborateur')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Affichage du formulaire de permis de conduire
	 */
	showPermis() {
		//Affichage de la pop-up de permis de conduire
		this.conducteurService.showPermisConduire(this.conducteur?.permisConduire || {}).pipe(filter(p => typeof p != 'undefined')).subscribe(permisConduire => {
			//Mise à jour du permis
			this.conducteur.permisConduire = permisConduire;
		});
	}
}