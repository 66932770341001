<div class="pull-left lv-avatar">{{ data.numero.toString().substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<span><a [routerLink]="[]" (click)="showCompte()"><span>{{ data.numero + ' - ' + data.libelle }}</span></a></span>
	</div>
	<ul class="lv-attrs">
		<li><span translate>comptabilite.compte.type.item</span>&#160;:&#160;<span>{{ ('comptabilite.compte.type.' + data.type) | translate }}</span></li>
	</ul>

	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteCompte();" translate>actions.supprimer</a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>