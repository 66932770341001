<div class="container-fluid">
	<form #typeAmenagementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>vehicule.typeAmenagement.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="typeAmenagement.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>vehicule.typeAmenagement.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="typeAmenagement.reference" maxlength="100" required>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>vehicule.typeAmenagement.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.typeAmenagement.actif.description' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="typeAmenagement.actif" [disabled]="!canActivate()"><span translate>vehicule.typeAmenagement.actif.info</span></mat-checkbox>
								</div>
							</div>

						</div>
					</div>
					<div class="form-group">
						<label translate>vehicule.typeAmenagement.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="typeAmenagement.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteTypeAmenagement();" *ngIf="typeAmenagement?.idTypeAmenagement && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveTypeAmenagement();" *ngIf="null | right:'creation'" [disabled]="typeAmenagementForm.invalid">
				<span [translate]="'actions.' + (typeAmenagement?.idTypeAmenagement ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="typeAmenagement?.idTypeAmenagement" [formDetectChanges]="{ doAction: close,isDirty: typeAmenagementForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>