import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './structure-export-compteur.component.html'
})
export class  StructureExportCompteurComponent {
	/** Compteur **/
	@Input() compteur: any = null;

	/** Détail **/
	@Input() detail: any = null;

	/** Liste types compteurs **/
	@Input() listeTypesCompteur: Array<{ code: string,libelle: string }>;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Résultat **/
	result: { compteur: any }

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<StructureExportCompteurComponent>) {}

	/**
	 * Enregistrement du nom de fichier
	 */
	public saveCompteur() {
		//Définition du résultat
		this.result = {
			compteur: this.compteur
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}