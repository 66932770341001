import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ModeleOptionListItemComponent } from './modele-option-list-item.component';
import { ModeleService } from './modele.service';

@Component({
	selector: 'modele-option-list',
	templateUrl: './modele-option-list.component.html'
})
export class ModeleOptionListComponent extends PageContentItem implements OnInit {
	/** Modèle **/
	@Input() modele: any;

	/** Indicateur de synchronisation des options du modèle **/
	@Input() isOptionsSynced: boolean;

	/** Indicateur d'une synchronisation en cours des options **/
	@Input() isSynchronizing: boolean;

	/** Fonction d'affichage des caractéristiques d'un modèle **/
	@Input() selectCaracteristiques: () => void;

	/** Données **/
	liste: ListView<any,ModeleOptionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private modeleService: ModeleService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la nécessité de resynchroniser les options du modèle
		if (this.modele && !this.isSynchronizing && !this.isOptionsSynced) {
			//Indication que la synchronisation est en cours
			this.isSynchronizing = true;

			//Synchronisation des options
			this.modeleService.synchronizeOptions(this.modele).subscribe({
				next: () => {
					//Mise à jour du flag
					this.isOptionsSynced = true;

					//Initialisation de la liste
					this.initListe();
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.synchronisation.error'));

					//Affichage des caractéristiques
					this.selectCaracteristiques();
				},
				complete: () => {
					//Synchronisation terminée
					this.isSynchronizing = false;
				}
			});
		} else
			//Initialisation de la liste
			this.initListe();
	}

	/**
	 * Initialisation de la liste des options
	 */
	private initListe() {
		//Définition de la liste
		this.liste = new ListView<any,ModeleOptionListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreModeleOptions`,
			component: ModeleOptionListItemComponent,
			isLocal: true,
			extraOptions: {
				modele: this.modele
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'modele.idModele',
				valeur: this.modele.idModele,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			},{
				clef: 'isConfiguration',
				valeur: 'false',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: '-isWithoutFin,libelle,-dateDebut'
		});
	}
}