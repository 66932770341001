import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { ConducteurService } from './conducteur.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './conducteur.entites';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	templateUrl: './conducteur-list-item.component.html'
})
export class ConducteurListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private conducteurService: ConducteurService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public notificationService: NotificationService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Conducteur',this.data.idConducteur);
	}

	/**
	 * Suppression d'un conducteur
	 */
	deleteConducteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du conducteur
					this.conducteurService.deleteConducteur(this.data.idConducteur).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du conducteur
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	goToVehicule(vehicule: any) {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{ routeParams: { idVehicule: vehicule?.idVehicule || 0 },withGoBack: true });
	}

	/**
	 * Redirection vers le collaborateur
	 */
	goToUser(user: any) {
		//Redirection vers le collaborateur
		this.layoutService.goToByState('listeUsers-user',{ routeParams: { idUser: user?.idUser || 0 },withGoBack: true });
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.Conducteur',this.data.idConducteur,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.VEHICULE_CONDUCTEUR
		});
	}

	/**
	 * Accès au planning
	 */
	goToPlanning(user: any) {
		//Accès au planning
		this.conducteurService.goToPlanning(user);
	}
}