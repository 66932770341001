<aside [ngClass]="{ 'toggled': (menuService.isOpened$ | async) }" *ngIf="menuService.user">
	<div class="menu-inner">
		<ul class="main-menu">
			<li *ngFor="let item of menuService.listeItems" [ngClass]="{ 'sub-menu': item.listeItems,'active': item.isSelected,'toggled': item.isToggled }">
				<a *ngIf="!item.isDroitOnly" [routerLink]="[]" [ngClass]="{ 'active': item.isSelected }" class="clickable" (click)="selectItem(item);" matRipple>
					<div>
						<icon [type]="item.iconeType" [name]="item.icone"></icon>
						<span [translate]="item.libelle"></span>
					</div>
					<icon *ngIf="item.listeItems" [name]="!item.isToggled ? 'chevron_right' : 'expand_more'"></icon>
				</a>
				<ul *ngIf="!item.isDroitOnly && item.listeItems && item.isToggled">
					<li *ngFor="let subItem of item.listeItems" [ngClass]="{ 'sub-menu': subItem.listeItems, 'active': subItem.isSelected, 'toggled': subItem.listeItems && subItem.isToggled }">
						<a [routerLink]="[]" [ngClass]="{ 'active': subItem.isSelected }" class="clickable" (click)="selectItem(subItem);">
							<div matRipple>
								<span [translate]="subItem.libelle"></span>
								<icon *ngIf="subItem.listeItems" [name]="!subItem.isToggled ? 'chevron_right' : 'expand_more'"></icon>
							</div>
						</a>
						<ul *ngIf="subItem.listeItems && subItem.isToggled">
							<li *ngFor="let subSubItem of subItem.listeItems" [ngClass]="{ 'active': subSubItem.isSelected }">
								<a [routerLink]="[]" [ngClass]="{ 'active': subSubItem.isSelected }" class="clickable" (click)="selectItem(subSubItem);">
									<div matRipple>
										<span [translate]="subSubItem.libelle"></span>
									</div>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</aside>