<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idGenre">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle+ (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idGenre">
		<li *ngIf="data.type"><span translate>vehicule.genre.type.libelle</span>&#160;:&#160;<span>{{ ('vehicule.genre.type.' + data.type?.toUpperCase()) | translate }}</span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.typeSource"><span translate>vehicule.genre.typeSource.libelle</span>&#160;:&#160;<span>{{ ('vehicule.genre.typeSource.' + (data.typeSource == 'EXTERNE_CATALOGUE' ? 'CONSTRUCTEUR' : 'ADMINISTRATIF')) | translate }}</span></li>
		<li *ngIf="data.pays"><span translate>vehicule.genre.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays?.libelle" [ngClass]="'fi-'+(data.pays?.code2?.toLowerCase() != 'ja' ? data.pays?.code2.toLowerCase() : 'jp')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idGenre && rightService.isRoot()">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && data.typeSource != 'EXTERNE_CATALOGUE'"><a [routerLink]="[]" (click)="deleteGenre();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<genre [genre]="data" (onObjectChange)="onObjectChange($event)"></genre>
</div>