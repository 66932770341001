import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { take,filter } from 'rxjs/operators';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ReferentielEntiteList,TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { ExtractionListItemComponent } from 'src/app/components/extraction/extraction-list-item.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { ChartService } from 'src/app/components/chart/chart.service';

@Component({
	templateUrl: './extraction-list.component.html'
})
export class ExtractionListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.extraction.Extraction';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/Extraction/filtreExtractions/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,ExtractionListItemComponent>;

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(protected referentielService: ReferentielService,private translateService: TranslateService,protected rightService: RightService,private layoutService: LayoutService,private extractionService: ExtractionService,private store: Store<AppState>,private chartService: ChartService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),take(1)).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,ExtractionListItemComponent>({
					uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
					title: this.translateService.instant('extraction.liste.title'),
					component: ExtractionListItemComponent,
					listeFilters: [{
						clef: 'libelle',
						isDefault: true
					},{
						clef: 'reference',
						isDefault: true
					},{
						clef: 'isActif',
						type: TypeFilter.BOOLEAN
					},{
						clef: 'entity',
						type: TypeFilter.STRING,
						title: this.translateService.instant('search.entite'),
						listeValues: this.extractionService.getListeEntites()
					},(this.rightService.isRoot() || this.user.tenant?.type == 'REVENDEUR') && {
						clef: 'typeDiffusion',
						type: TypeFilter.STRING,
						listeValues: this.chartService.getListeTypesDiffusion()
					},{
						clef: 'typeFormat',
						type: TypeFilter.LONG,
						listeValues: this.extractionService.getListeTypesFormat(),
						isListeValuesWithButton: true,
						title: this.translateService.instant('search.format')
					}].filter(i => !!i),
					listeSelectors: !this.rightService.isRoot() && [{
						code: TypeReferentiel.LOCAL,
						libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
						count: () => this.referentiel?.nbItemsForLocal || 0
					},{
						code: TypeReferentiel.STANDARD,
						libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
						count: () => this.referentiel?.nbItems || 0
					}],
					selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
					extraOptions: {
						referentiel: this.referentiel,
						user: this.user
					},
					hasMainAction: () => this.rightService.hasRight(null,'creation') && (this.rightService.isRoot() || this.liste.selectedSelector == TypeReferentiel.LOCAL),
					doMainAction: () => this.layoutService.goToByUrl(`/Extraction/loadExtraction/${this.liste.selectedSelector}/0`),
					onRefresh: (liste,result) => {
						//Vérification du type de liste
						if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
							//Mise à jour du nombre d'éléments standards
							this.referentiel.nbItems = result?.totalElements || 0;
						else
							//Mise à jour du nombre d'éléments locaux
							this.referentiel.nbItemsForLocal = result?.totalElements || 0;
					}
				});
			}
		});
	}
}