import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { BusinessDataModule } from './business-data/business-data.module';
import { DataCustomizationModule } from './data-customization/data-customization.module';
import { EntiteFieldModule } from './entite-field/entite-field.module';
import { EntiteService } from './entite.service';
import { FormCustomizationModule } from './form-customization/form-customization.module';

@NgModule({
	imports: [CoreComponentsModule,BusinessDataModule,DataCustomizationModule,EntiteFieldModule,FormCustomizationModule],
	exports: [BusinessDataModule,DataCustomizationModule,EntiteFieldModule,FormCustomizationModule],
	providers: [EntiteService]
})
export class EntiteModule {}