<div class="pull-left lv-avatar" [translate]="'vehicule.releveEnergie.liste.avatar.'+(data.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addReleveEnergie()">
			<span *ngIf="data?.typeSourceReleve == 'COLLABORATEUR' && data.userSource"> {{ data.userSource | display:'user' }}</span>
			<span *ngIf="data?.typeSourceReleve == 'FOURNISSEUR' && data.fournisseur"> {{ data.fournisseur | display:'fournisseur' }}</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.releveEnergie.date</span>&#160;:&#160;{{ data.dateReleve | date:'short' }}</li>
		<li><span translate>vehicule.releveEnergie.volume</span>&#160;:&#160;{{ data.volume | number:'.2-2' }}&#160;{{ data.unite?.libelleCourt || '' }}</li>
		<li *ngIf="data.extension?.userAffecte"><span translate>vehicule.releveEnergie.userAffecte</span>&#160;:&#160;<span>{{ data.extension?.userAffecte | display:'user' }}</span></li>
		<li *ngIf="data.extension?.priseMultiple"><span translate>vehicule.releveEnergie.priseMultiple</span>&#160;:&#160;<span translate>common.oui</span></li>
		<li *ngIf="data.extension?.depassementReservoir"><span translate>vehicule.releveEnergie.depassementReservoir</span>&#160;:&#160;<span translate>common.oui</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'suppression') && !data.readOnly ">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'suppression') && !data.readOnly"><a [routerLink]="[]" (click)="deleteReleveEnergie();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>