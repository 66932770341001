import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { cloneDeep,isEqual } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PoolService } from './pool.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'pool-edit',
	templateUrl: './pool-edit.component.html'
})
export class PoolEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() pool: any;

	/** Suppression du pool **/
	@Input() deletePool: () => void;

	/** Copie du pool **/
	private savedPool: any;

	/**
	 * Constructeur
	 */
	constructor(private poolService: PoolService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Copie du pool avant modifications
		this.savedPool = cloneDeep(this.pool);
	}

	/**
	 * Enregistrement du pool
	 */
	savePool() {
		//Confirmation de l'enregistrement du pool
		(this.savedPool.idPool && !isEqual(this.pool.etatLieuxAutomatique,this.savedPool.etatLieuxAutomatique) ? this.confirmService.showConfirm(this.translateService.instant('pool.confirmation.changeEtatLieuxAutomatique'),{ actionColor: 'primary' }) : of(true)).subscribe(isConfirmed => {
			//Vérification de la confirmation
			if (isConfirmed)
				//Enregistrement du pool
				this.poolService.savePool(this.pool).subscribe({
					next: (result: Result) => {
						let listeDoublons = new Array<string>();

						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Définition du pool
							this.pool = result.data.pool;

							//Mise à jour du pool et fermeture du formulaire
							this.close(this.pool);
						} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
							//Vérification du libellé
							if (result.data.doublon & Math.pow(2,0))
								//Ajout du libellé
								listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
								field: listeDoublons.join(', ')
							}));
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						}
					}
				});
		});
	}
}