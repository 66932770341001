import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PoolVehiculeListItemComponent } from './pool-vehicule-list-item.component';

@Component({
	selector: 'pool-vehicule-list',
	templateUrl: './pool-vehicule-list.component.html'
})
export class PoolVehiculeListComponent implements OnInit {
	/** Liste **/
	public liste: ListView<any,PoolVehiculeListItemComponent>;

	/** Pool courant **/
	public pool: any;

	/** Résumé courant **/
	public resume: any;

	/**	Modification du résumé **/
	public onResumeChange: (resume: any) => void;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Duplication du résumé
		this.resume = Object.assign({},this.resume);

		//Définition de la liste
		this.liste = new ListView<any,PoolVehiculeListItemComponent>({
			uri: `/controller/VehiculePool/filtreVehicules/${this.pool.idPool}`,
			title: this.translateService.instant('vehicule.pool.vehicule.liste.title'),
			component: PoolVehiculeListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			}],
			onRefresh: (liste,result) => {
				//Mise à jour du compteur
				this.resume.nbVehicules = liste.listeSelectedFilters?.length == 0 ? result.totalElements : this.resume.nbVehicules;

				//Notification d'un changement
				this.onResumeChange(this.resume);
			}
		});
	}

	/**
	 * Interception d'une suppression de véhicule
	 */
	onItemRemove(): void {
		//Mise à jour du compteur
		this.resume.nbVehicules = this.resume.nbVehicules - 1;

		//Notification d'un changement
		this.onResumeChange(this.resume);
	}
}