import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter,switchMap,tap } from 'rxjs/operators';

import { ExtractionRuleComponent } from 'src/app/components/extraction/extraction-rule.component';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ChartDataEditComponent } from './chart-data-edit.component';
import { ChartDataListComponent } from './chart-data-list.component';
import { ChartEditComponent } from './chart-edit.component';
import { ChartGraphiqueEditComponent } from './chart-graphique-edit.component';
import { ChartGraphiqueListComponent } from './chart-graphique-list.component';
import { ChartService } from './chart.service';

@Component({
	selector: 'chart',
	templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Graphique courant **/
	public chart: any;

	/** Résumé **/
	private resume: { nbDatas: number,nbFiltres: number,nbDefinitions: number } = {
		nbDatas: 0,
		nbFiltres: 0,
		nbDefinitions: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private chartService: ChartService,private entiteService: EntiteService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.chart.Chart';

	/**
	 * Récupération de l'identifiant du graphique à charger
	 */
	getIdObject: () => number = () => this.chart?.idChart || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du graphique
		this.loadData(this.activatedRoute.snapshot.params.idChart).subscribe({
			next: () => {
				//Initialisation de la page complexe
				this.options = {
					listeFields: [{
						libelle: 'chart.libelle',
						key: 'libelle'
					},{
						libelle: 'chart.reference',
						key: 'reference'
					},{
						libelle: 'chart.entite',
						key: data => this.entiteService.translateEntityCode(data.extraction?.entity?.split('.').pop())
					},{
						libelle: 'chart.typeDiffusion.itemShort',
						key: data => this.translateService.instant(`chart.typeDiffusion.${data.typeDiffusion}`)
					},{
						libelle: 'chart.actif.item',
						key: 'actif',
						type: 'BOOLEAN'
					}],
					listeActions: [{
						libelle: 'actions.modifier',
						doAction: () => this.editChart(),
						isVisible: () => this.rightService.hasRight(null,'creation'),
						type: TypeAction.EDITION
					},{
						libelle: 'actions.consulter',
						doAction: () => this.editChart(),
						isVisible: () => !this.rightService.hasRight(null,'creation'),
						type: TypeAction.CONSULTATION
					},{
						libelle: 'actions.dupliquer',
						doAction: () => this.duplicateChart(),
						isVisible: () => this.chart.extraction?.listeFields?.length && this.chart.listeDefinitions?.length && this.rightService.hasRight(null,'creation')
					},{
						libelle: 'actions.previsualiser',
						doAction: () => this.previewChart(),
						isVisible: () => this.chart.extraction?.listeFields?.length && this.chart.listeDefinitions?.length
					},{
						libelle: 'actions.supprimer',
						doAction: () => this.deleteChart(),
						isVisible: () => this.rightService.hasRight(null,'suppression')
					}],
					listeAlertes: [{
						icon: 'view_column',
						title: this.translateService.instant('chart.alerte.data.title'),
						message: this.translateService.instant('chart.alerte.data.message'),
						isVisible: () => !this.chart.extraction?.listeFields?.length && !this.pageContentService.isOpened(),
						doAction: () => this.addData(),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					},{
						icon: 'filter_list',
						title: this.translateService.instant('chart.alerte.filtre.title'),
						message: this.translateService.instant('chart.alerte.filtre.message'),
						isVisible: () => !this.chart.extraction?.rule?.listeDetails?.length && !this.pageContentService.isOpened(),
						doAction: () => this.addRule(),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					}],
					listeElements: [{
						type: 'DATA',
						libelle: 'chart.elements.data',
						component: ChartDataListComponent,
						retrieveComponentData: () => ({
							chart: this.chart,
							addData: this.addData.bind(this)
						}),
						doAction: () => this.addData(),
						count: () => this.resume.nbDatas,
						libelleAction: this.translateService.instant('chart.actions.ajouterData'),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					},{
						type: 'FILTRE',
						libelle: 'chart.elements.filtre',
						component: ExtractionRuleComponent,
						retrieveComponentData: () => ({
							getExtraction: () => this.chart.extraction,
							getRule: () => this.chart.extraction.rule
						}),
						doAction: () => this.addRule(),
						count: () => this.resume.nbFiltres,
						libelleAction: this.translateService.instant('chart.actions.ajouterFiltre'),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					},{
						type: 'GRAPHIQUE',
						libelle: 'chart.elements.definition',
						component: ChartGraphiqueListComponent,
						retrieveComponentData: () => ({
							chart: this.chart,
							addDefinition: this.addDefinition.bind(this),
							deleteDefinition: this.deleteDefinition.bind(this),
							resume: this.resume
						}),
						doAction: () => this.addDefinition(),
						count: () => this.resume.nbDefinitions,
						libelleAction: () => this.translateService.instant(`chart.actions.${ !this.resume.nbDefinitions ? 'ajouterGraphiquePrincipal' : 'ajouterGraphiqueSecondaire' }`),
						isActionVisible: () => this.rightService.hasRight(null,'creation') && this.resume.nbDatas > 0 && this.resume.nbDefinitions < 2 && this.chart.listeDefinitions?.[0]?.type != 'KPI'
					}],
					isFormCustomization: true
				};
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idChart: number): Observable<Result> {
		//Chargement du graphique
		return this.chartService.loadChart(idChart).pipe(tap(result => {
			//Vérification du chargement
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Définition de la facture
				this.chart = result.data?.chart;

				//Mise à jour du résumé
				this.resume = {
					nbDatas: this.chart?.extraction?.listeFields?.length || 0,
					nbFiltres: this.chart?.extraction?.rule?.listeDetails?.length || 0,
					nbDefinitions: this.chart?.listeDefinitions?.length || 0
				};

				//Vérification de l'identifiant du graphique
				if (!this.chart?.idChart) {
					//Initialisation d'un objet vierge
					this.chart = {
						actif: false,
						formulaireDefault: false,
						metierDefault: false,
						globalDefault: false,
						extraction: {
							avancee: false,
							typeFormat: 'XLSX'
						}
					};

					//Édition du graphique
					this.editChart();
				}
			}
		}));
	}

	/**
	 * Édition du graphique
	 */
	private editChart() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ChartEditComponent,{
			data: {
				chart: cloneDeep(this.chart),
				doSaveChart: this.saveChart.bind(this),
				deleteChart: this.deleteChart.bind(this)
			}
		}).subscribe((data: { hasEntityChanged: boolean }) => {
			//Vérification des données
			if (data) {
				//Mise à jour de l'identifiant contenu dans l'URL
				this.layoutService.replaceUrlWith(this.chart);

				//Vérification du changement d'entité
				if (data.hasEntityChanged)
					//Affichage du premier onglet
					this.complexPage.setSelectedElementByType('DATA');
			}
		});
	}

	/**
	 * Enregistrement du graphique
	 */
	protected saveChart(chart: any,close?: (data?: any) => void,hasEntityChanged?: boolean) {
		//Enregistrement du graphique
		this.chartService.saveChart(chart).subscribe(result => {
			let listeDoublons: Array<string> = [];

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du graphique
				Object.assign(this.chart,result.data?.chart);

				//Mise à jour du résumé
				Object.assign(this.resume,{
					nbDatas: this.chart.extraction?.listeFields?.length || 0,
					nbFiltres: this.chart.extraction?.rule?.listeDetails?.length || 0,
					nbDefinitions: this.chart.listeDefinitions?.length || 0
				});

				//Vérification de la nécessité de fermer un formulaire
				if (close) {
					//Fermeture du formulaire
					close?.({
						hasEntityChanged
					});
				}
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,1))
					//Ajout de la référence
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Suppression du graphique
	 */
	protected deleteChart() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.chartService.deleteChart(this.chart))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Duplication du graphique
	 */
	private duplicateChart() {
		//Duplication du graphique
		this.chartService.duplicateChart(this.chart).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers le graphique créé
				this.layoutService.replaceUrlWith({
					idChart: result.data.chart.idChart
				},true);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Prévisualisation
	 */
	private previewChart() {
		//Lancement de la prévisualtisation
		this.chartService.previewChart(this.chart);
	}

	/**
	 * Modification de la liste des champs
	 */
	private addData() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ChartDataEditComponent,{
			data: {
				chart: cloneDeep(this.chart)
			}
		},'sub').pipe(filter(data => !!data)).subscribe(data => {
			//Mise à jour de l'extraction
			Object.assign(this.chart.extraction,data.chart.extraction);

			//Mise à jour du résumé
			this.resume.nbDatas = this.chart.extraction?.listeFields?.length || 0;

			//Affichage de l'onglet (si nécessaire)
			this.complexPage.setSelectedElementByType('DATA',true);

			//Vérification de la nécessité de supprimer les définitions
			if (data.isDeleteDefinition) {
				//Réinitialisation du graphique et des interactions
				this.chart.listeDefinitions = [];

				//Mise à jour du résumé
				this.resume.nbDefinitions = 0;

				//Enregistrement du graphique
				this.chartService.saveChart(this.chart).subscribe(result => {
					//Vérification du code d'erreur
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
						//Mise à jour de l'objet
						Object.assign(this.chart,result.data?.chart);
				});
			}
		});
	}

	/**
	 * Ajout d'une règle
	 */
	private addRule() {
		//Affichage du détail
		this.pageContentService.open(ExtractionRuleComponent,{
			data: {
				getExtraction: () => this.chart.extraction,
				getRule: () => cloneDeep(this.chart.extraction?.rule || {}),
				isEdition: true
			}
		},'sub').subscribe(extraction => {
			//Vérification de la présence de données
			if (extraction) {
				//Mise à jour de l'extraction
				Object.assign(this.chart.extraction,extraction);

				//Mise à jour du résumé
				this.resume.nbFiltres = this.chart.extraction?.rule?.listeDetails?.length || 0;

				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('FILTRE',true);

			}
		});
	}

	/**
	 * Ajout d'une définition
	 */
	private addDefinition(index: number = this.resume.nbDefinitions) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ChartGraphiqueEditComponent,{
			data: {
				chart: cloneDeep(this.chart),
				indexDefinition: index,
				saveChart: this.saveChart.bind(this),
				deleteDefinition: this.deleteDefinition.bind(this)
			}
		},'sub').subscribe((data: any) => {
			//Vérification de la présence de données
			if (data)
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('GRAPHIQUE',true);
		});
	}

	/**
	 * Suppression d'une définition
	 */
	private deleteDefinition(close?: (data?: any) => void) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed)
		).subscribe(() => {
			let chart: any;

			//Copie du graphique
			chart = cloneDeep(this.chart);

			//Retrait de la dernière définition
			chart.listeDefinitions.length = chart.listeDefinitions.length - 1;

			//Enregistrement du graphique
			this.saveChart(chart,close);
		});
	}
}