import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { PlanEntretienAddComponent } from 'src/app/components/vehicule/entretien/plan-entretien-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des plans d'entretien
 */
export default class PlanEntretienOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.planEntretien';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Vehicule/listePlansEntretienActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService,datePipe?: DatePipe,decimalPipe?: DecimalPipe) { return item ? translateService.instant('planEntretien.selection',{ libelle: item.libelle,duree: item.duree,distance: decimalPipe.transform(item.distance,'.0-0'),unite: item.unite.libelleCourt }) : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.PlanEntretien';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idPlanEntretien'; }

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'planEntretien.add.item';

	/**
	 * Composant d'ajout
	 */
	addComponent: () => any = () => PlanEntretienAddComponent;

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ENTRETIEN;

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		}];
	}
}