<div class="container-fluid">
	<form #tenantForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<div class="row">
					<div class="col-md-6">
						<fieldset [disabled]="!(null | right:'creation') || hasRestrictionClient()">
							<div class="form-group">
								<label translate>tenant.revendeur</label>
								<div>
									<span>{{ tenant?.tenant?.revendeur?.libelle }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': raisonSociale.invalid }">
								<label translate>tenant.raisonSociale</label>
								<div>
									<input type="text" name="raisonSociale" #raisonSociale="ngModel" class="form-control" [(ngModel)]="tenant.tenant.nom" maxlength="300" required [disabled]="tenant?.deleted"/>
								</div>
							</div>
							<div class="form-group">
								<label translate>tenant.reference</label>
								<div>
									<span>{{ tenant.tenant.code }}</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>tenant.clientCRM.item</label>
								<div>
									<span>{{ tenant?.clientCRM?.reference }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>tenant.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="tenant.pays" required [disabled]="tenant?.deleted"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': localeField.invalid }">
								<label translate>tenant.locale.item</label>
								<div class="half">
									<selectpicker name="locale" #localeField="ngModel" [(ngModel)]="tenant.localeDefaut" [compareWith]="selectPickerService.getComparator('idLocale')" required [disabled]="tenant?.deleted">
										<mat-option *ngFor="let locale of listeLocales" [value]="locale">{{ locale.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': timezoneField.invalid }">
								<label translate>tenant.timezone.item</label>
								<div class="half">
									<selectpicker name="timezone" #timezoneField="ngModel" [(ngModel)]="tenant.timezone" required [disabled]="listeTimezones?.length <= 1 && tenant?.timezone || tenant?.deleted">
										<mat-option *ngFor="let timezone of listeTimezones" [value]="timezone.code">{{ timezone.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': devise.invalid }">
								<label translate>tenant.devise</label>
								<div class="half">
									<autocomplete name="devise" type="devise" #devise="ngModel" [(ngModel)]="tenant.devise" (onSelect)="tenant.devise = $event.code" [filter]="{ referentiel: 'STANDARD' }" required [disabled]="tenant?.deleted"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': unite.invalid }">
								<label translate>tenant.unite</label>
								<div class="half">
									<autocomplete name="unite" type="unite" #unite="ngModel" [(ngModel)]="tenant.unite" filter="DISTANCE" required [disabled]="tenant?.deleted"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': uniteVolume.invalid }">
								<label translate>tenant.uniteVolume</label>
								<div class="half">
									<autocomplete name="uniteVolume" type="unite" #uniteVolume="ngModel" [(ngModel)]="tenant.uniteVolume" filter="CONTENANCE" required [disabled]="tenant?.deleted"></autocomplete>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>tenant.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="tenant.tenant.actif" [disabled]="!(null | right:'creation') || hasRestrictionClient()"><span translate>tenant.actif.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>tenant.transmissionAccount.item</label>
							<div>
								<mat-checkbox name="transmissionAccount" #transmissionAccount="ngModel" [(ngModel)]="tenant.tenant.transmissionAccount" [disabled]="tenant?.deleted || !(null | right:'creation')"><span translate>tenant.transmissionAccount.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" *ngIf="(TypeDroit.ADMIN_COMPTE_SERVICE | right:'consultation') && (null | right:'creation') && !hasRestrictionClient()">
							<label translate>tenant.compteService.item</label>
							<div>
								<a [routerLink]="[]" (click)="showCompteService()"><icon class="icon-hc-2x" name="search"></icon><span [translate]="'tenant.compteService.info' | pluralTranslate:tenant?.listeComptesService?.length || 0" [translateParams]="{ nbComptes: tenant?.listeComptesService?.length || 0 }"></span></a>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': authentificationModeField.invalid }">
							<label translate>tenant.modeAuthentification.item</label>
							<div class="half">
								<selectpicker name="authentificationMode" #authentificationModeField="ngModel" [(ngModel)]="tenant.tenant.authentificationMode" [disabled]="tenant?.deleted || !(null | right:'creation')" required>
									<mat-option *ngFor="let authentificationMode of listeModesAuthentification" [value]="authentificationMode?.code">{{ authentificationMode.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group">
							<label translate>tenant.allowDoublonMatricule.item</label>
							<div>
								<mat-checkbox name="allowDoublonMatricule" #allowDoublonMatricule="ngModel" [(ngModel)]="tenant.allowDoublonMatricule" (ngModelChange)="showAlerteDoublonMatricule($event)" [disabled]="tenant?.deleted"><span translate>tenant.allowDoublonMatricule.info</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || tenant?.deleted">
					<h4 translate>tenant.informationsComplementaires</h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': adminEmail.invalid }">
								<label translate>tenant.adminEmail</label>
								<div>
									<input type="email" email name="adminEmail" #adminEmail="ngModel" class="form-control" [(ngModel)]="tenant.adminEmail" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': database.invalid }">
								<label translate>tenant.database</label>
								<div>
									<autocomplete name="database" type="database" #database="ngModel" [filter]="{ idTenant: tenant.idTenant,idRevendeur: tenant?.tenant?.revendeur?.idRevendeur }" [(ngModel)]="tenant.database" required [disabled]="hasRestrictionClient()"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': replyToEmail.invalid }">
								<label translate>tenant.replyToEmail</label>
								<div>
									<input type="email" email name="replyToEmail" #replyToEmail="ngModel" class="form-control" [(ngModel)]="tenant.replyToEmail" maxlength="300"/>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>tenant.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="tenant.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || tenant?.deleted">
					<h4 translate>tenant.customTheme</h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': themeActif.invalid }">
								<label translate>tenant.theme.actif.item</label>
								<div>
									<mat-checkbox name="themeActif" #themeActif="ngModel" [(ngModel)]="tenant.theme.actif" (ngModelChange)="tenant.theme.primaryColor = tenant?.theme?.primaryColor || '#0046FE'"><span translate>tenant.theme.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="tenant?.theme?.actif">
								<label translate>tenant.theme.customLogo</label>
								<div>
									<div [attachment]="tenant.theme" [typeAttachment]="TypeAttachment.THEME" typeLink="LOGO" queueLimit="1" filters=".jpeg|.jpg|.png"></div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': primaryColor.invalid }" *ngIf="tenant?.theme?.actif">
								<label translate>tenant.theme.customColor</label>
								<div>
									<color-picker name="primaryColor" #primaryColor="ngModel" [(ngModel)]="tenant.theme.primaryColor" required></color-picker>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteTenant();" *ngIf="tenant?.idTenant && (null | right:'suppression') && !tenant?.deleted && !hasRestrictionClient()">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveTenant();" *ngIf="null | right:'creation'" [disabled]="tenantForm.invalid">
				<span [translate]="'actions.' + (tenant?.idTenant ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: tenantForm.dirty }" *ngIf="tenant?.idTenant">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>