<form #compteAuxiliaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>comptabilite.compteAuxiliaire.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': planComptable.invalid }">
			<label translate>comptabilite.compteAuxiliaire.planComptable</label>
			<div>
				<autocomplete name="planComptable" type="planComptable" #planComptable="ngModel" [(ngModel)]="compteAuxiliaire.planComptable" (ngModelChange)="compteAuxiliaire.compte = null;" [disabled]="filter.isCompteDisabled" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': compte.invalid }">
			<label translate>comptabilite.compteAuxiliaire.compteCollectif</label>
			<div>
				<autocomplete name="compte" type="compte" #compte="ngModel" [(ngModel)]="compteAuxiliaire.compte" [filter]="{ listeTypes: ['TIERS'],idPlanComptable: compteAuxiliaire.planComptable?.idPlanComptable || null }" [disabled]="filter.isCompteDisabled || !compteAuxiliaire.planComptable" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
			<label translate>comptabilite.compteAuxiliaire.numero</label>
			<div>
				<input type="text" class="form-control" name="numero" #numero="ngModel" [(ngModel)]="compteAuxiliaire.numero" required maxlength="100"/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>comptabilite.compteAuxiliaire.libelle</label>
			<div>
				<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="compteAuxiliaire.libelle" required/>
			</div>
		</div>
		<div class="form-group" *ngIf="compteAuxiliaire.planComptable">
			<label translate>comptabilite.compteAuxiliaire.pays</label>
			<div>
				<autocomplete name="pays" type="pays" [(ngModel)]="compteAuxiliaire.planComptable.pays" [disabled]="true"></autocomplete>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="compteAuxiliaireForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>