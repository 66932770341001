import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { AutocompleteSearchData } from '../autocomplete';
import { AutocompleteSearchItemComponent } from './autocomplete-search-item.component';

@Component({
	selector: 'autocomplete-search-component',
	templateUrl: './autocomplete-search.component.html'
})
export class AutocompleteSearchComponent implements OnInit {
	/** Données **/
	searchData: AutocompleteSearchData;

	/** Liste **/
	liste: ListView<any,AutocompleteSearchItemComponent>;

	/** Elément sélectionné **/
	selectedItem: any;

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<AutocompleteSearchComponent>,private translateService: TranslateService,private datePipe: DatePipe,private decimalPipe: DecimalPipe) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AutocompleteSearchItemComponent>({
			uri: typeof this.searchData.options.url == 'function' ? this.searchData.options.url(this.searchData.filter) : this.searchData.options.url,
			component: this.searchData.options.searchItemComponent?.() || AutocompleteSearchItemComponent,
			extraOptions: {
				displayItem: this.searchData.options.displayItem,
				displayAvatar: this.searchData.options.displayAvatar,
				listeSearchItemFields: this.searchData.options.listeSearchItemFields,
				translateService: this.translateService,
				datePipe: this.datePipe,
				decimalPipe: this.decimalPipe,
				close: (item) => {
					//Sélection de l'élément
					this.selectedItem = item;

					//Suppression des fonctions héritées
					delete this.selectedItem?.onDisplayChange;
					delete this.selectedItem?.onRemove;

					//Fermeture de la popup
					this.bsModalRef.hide();
				}
			},
			isLocal: true,
			listeFilters: this.searchData.options.listeFilters(this.searchData.filter),
			listeStaticFilters: this.searchData.options.listeStaticFilters?.(this.searchData.filter),
			defaultOrder: this.searchData.options.defaultOrder || this.searchData.options.listeFilters(this.searchData.filter).filter(f => f.isDefault).map(f => f.clef).join(',')
		});
	}

	/**
	 * Fermeture de la popup
	 */
	cancel() {
		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}