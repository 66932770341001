<div class="pull-left lv-avatar">{{ data?.numero?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Comptabilite/loadCompte/',data.idCompte]">{{ data?.numero + (data?.libelle ? ' (' + data?.libelle + ')' : '') }}</a>
</div>
<ul class="lv-attrs" *ngIf="data.idCompte">
	<li *ngIf="data.type"><span translate>comptabilite.compte.type.item</span>&#160;:&#160;<span>{{ ('comptabilite.compte.type.' + data.type) | translate }}</span></li>
	<li *ngIf="data.pays"><span translate>comptabilite.compte.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
	<li *ngIf="data.planComptable"><span translate>comptabilite.compte.planComptable</span>&#160;:&#160;<span>{{ data.planComptable.libelle + ' (' + data.planComptable.reference + ')' }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idCompte">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="data.planComptable"><a [routerLink]="[]" (click)="goToPlanComptable()"><span translate>comptabilite.actions.accederPlanComptable</span></a></li>
		<li *ngIf="(null | right:'suppression') && !compteService.getRestriction(data?.planComptable?.listeSocietes)?.isRestricted"><a [routerLink]="[]" (click)="deleteCompte();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>