import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ConnecteurService } from './connecteur.service';

@Component({
	selector: 'connecteur-execution-history',
	templateUrl: './connecteur-execution-history.component.html'
})
export class ConnecteurExecutionHistoryComponent {
	/** Exécution courante **/
	@Input() execution: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ConnecteurExecutionHistoryComponent>,private connecteurService: ConnecteurService) {}

	/**
	 * Consultation des logs
	 */
	showLogs(data: any) {
		//Ajout de la date d'exécution
		data.dateExecution = data.apiSession.dateCreation;

		//Affichage du résultat de l'exécution
		this.connecteurService.showExecutionResult(data);
	}
}
