<div class="card-padding p-t-20">
	<div class="alert alert-danger" *ngIf="!selectedConnecteurAvailability.isAvailable">
		<icon name="warning"></icon>
		<div>
			<strong translate>connecteur.import.selection.recapitulatif.connecteurIndisponible.message</strong>
			<div translate>connecteur.import.selection.recapitulatif.connecteurIndisponible.description</div>
		</div>
	</div>
	<div class="alert alert-info" *ngIf="selectedConnecteurAvailability.isAvailable">
		<icon name="email"></icon>
		<strong translate>connecteur.import.selection.recapitulatif.transmissionCompteRendu</strong>
	</div>
	<div class="form-group">
		<label><span translate>connecteur.import.selection.fournisseur</span></label>
		<div>
			<span>{{ selectedFournisseur.libelle }}</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>connecteur.import.selection.format</span></label>
		<div>
			<span>{{ selectedConnecteur.type.fournisseurInterface.libelle }}</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>connecteur.import.selection.fichiers</span></label>
		<div>
			<span>
				<mat-chip-listbox>
					<mat-chip *ngFor="let linkAttachment of selectedConnecteur.listeLinks">{{ linkAttachment.attachment.name }}</mat-chip>
				</mat-chip-listbox>
				<span *ngIf="!selectedConnecteur.listeLinks?.length" translate>common.aucun</span>
			</span>
		</div>
	</div>
</div>