import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { SchedulerComponent } from './scheduler.component';
import { SchedulerService } from './scheduler.service';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { SchedulerLinkComponent } from './scheduler-link.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,SelectPickerModule,DatePickerModule,NumberModule,IconModule],
	declarations: [SchedulerComponent,SchedulerLinkComponent],
	providers: [SchedulerService],
	exports: [SchedulerLinkComponent]
})
export class SchedulerModule {}