<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idCategorie">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data.libelle + (data.reference ? ' (' + data.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idCategorie">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data?.idCategorie">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteCategorie();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.user.Categorie',data?.idCategorie)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<user-categorie [categorie]="data" (onObjectChange)="onObjectChange($event)"></user-categorie>
</div>