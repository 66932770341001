'use strict';

var inherits = require('inherits'),
  IframeTransport = require('../iframe'),
  objectUtils = require('../../utils/object');
module.exports = function (transport) {
  function IframeWrapTransport(transUrl, baseUrl) {
    IframeTransport.call(this, transport.transportName, transUrl, baseUrl);
  }
  inherits(IframeWrapTransport, IframeTransport);
  IframeWrapTransport.enabled = function (url, info) {
    if (!global.document) {
      return false;
    }
    var iframeInfo = objectUtils.extend({}, info);
    iframeInfo.sameOrigin = true;
    return transport.enabled(iframeInfo) && IframeTransport.enabled();
  };
  IframeWrapTransport.transportName = 'iframe-' + transport.transportName;
  IframeWrapTransport.needBody = true;
  IframeWrapTransport.roundTrips = IframeTransport.roundTrips + transport.roundTrips - 1; // html, javascript (2) + transport - no CORS (1)

  IframeWrapTransport.facadeTransport = transport;
  return IframeWrapTransport;
};