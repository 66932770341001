import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ArticleCRMService } from './article-crm.service';

@Component({
	selector: 'article-crm',
	templateUrl: './article-crm.component.html'
})
export class ArticleCRMComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() articleCRM: any;

	/** Gestion d'une modification de l'article CRM **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Elément sauvegardé **/
	public savedArticleCRM: any = null;

	/** Récupération de la liste des types d'objets facturables **/
	public listeTypesObjet: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(private articleCRMService: ArticleCRMService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de l'article CRM
		this.articleCRM = Object.assign({},this.articleCRM);
		this.savedArticleCRM = Object.assign({},this.articleCRM);


		//Récupération de la liste des types d'objets facturables
		this.listeTypesObjet = this.articleCRMService.getListeTypesObjet();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked(): void {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de l'article CRM
	 */
	saveArticleCRM() {
		//Enregistrement de l'article CRM
		this.articleCRMService.saveArticleCRM(this.articleCRM).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'article CRM
					this.onObjectChange.emit(result.data.articleCRM);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression de l'article CRM
	 */
	deleteArticleCRM() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'article CRM
					this.articleCRMService.deleteArticleCRM(this.articleCRM).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du article CRM
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Vérification de la présence d'erreurs sur la liste des revendeurs
	 */
	hasErrorListeRevendeurs: () => boolean = () => !this.articleCRM.listeRevendeurs?.length;
}