<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idLot">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
			<icon [name]="mapTypesLot?.[data.typeLot].icone" [type]="mapTypesLot?.[data.typeLot].iconeType"></icon>
		</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed" [translate]="'sgi.lot.typeLot.'+data.typeLot"></a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>sgi.lot.dateCreationApplication</span>&#160;:&#160;{{ data.dateCreationApplication | date:'short' }}</li>
		<li><span translate>sgi.lot.dateCreation</span>&#160;:&#160;{{ data.dateCreation | date:'short' }}</li>
		<li *ngIf="data.typeLot == 'ETAT_PARC' || data.typeLot == 'REPONSE_DESIGNATION'"><span translate>sgi.lot.etat.item</span>&#160;:&#160;<span [translate]="'sgi.lot.etat.' + (data.closed ? 'cloture' : 'ouvert')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && !data.closed && (data.typeLot == 'ETAT_PARC' || data.typeLot == 'REPONSE_DESIGNATION')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="closeLot();"><span translate>actions.cloturer</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<sgi-lot [sgiLot]="data" (onObjectChange)="onObjectChange($event)"></sgi-lot>
</div>