import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { environment } from 'src/environments/environment';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';

@Component({
	templateUrl: './immatriculation-modele-catalogue-selection-modele-list.component.html'
})
export class ImmatriculationModeleCatalogueSelectionModeleListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,any>;

	/** Certificat d'immatriculation **/
	@Input() immatriculation: any;

	/** Modèle sélectionné */
	@Input() selectedModele: any;

	/** Stepper **/
	@Input() stepperComponent: StepperComponent;

	/** Sélection d'un modèle **/
	@Input() onModeleSelected: (modele: any) => void;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private modeleService: ModeleService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,any>({
			uri: `${environment.baseUrl}/controller/Vehicule/filtreImmatriculationModele/${this.immatriculation.idImmatriculation}`,
			component: null,
			isLocal: true,
			isContentHidden: true,
			listeFilters: [{
				clef: 'modeleCatalogue.libelle',
				title: this.translateService.instant('search.libelle'),
				isDefault: true
			},{
				clef: 'modeleCatalogue.reference',
				title: this.translateService.instant('search.reference'),
				isDefault: true
			},{
				clef: 'modeleCatalogue.finition',
				title: this.translateService.instant('search.finition'),
				isDefault: true
			}],
			defaultOrder: 'score,modeleCatalogue.libelle'
		});
	}

	/**
	 * Consultation d'un modèle
	 */
	public showModele(modele: any) {
		//Vérification de la présence et de la source du modèle
		if (modele.typeSource == 'EXTERNE_CATALOGUE') {
			//Affichage des informations détaillées du modèle
			this.modeleService.showModeleInformations(modele,{
				canSelect: true
			}).subscribe({
				next: () => {
					//Sélection du modèle consulté
					this.onModeleSelected(modele);
				}
			});
		}
	}
}