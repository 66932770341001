import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeviseService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private confirmService: ConfirmService,private translateService: TranslateService,private messagingService: MessagingService) { }

	/**
	 * Chargement de la devise
	 */
	public loadDevise(idDevise: number): Observable<Result> {
		//Chargement de la devise
		return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/loadDevise/${idDevise}`,null);
	}

	/**
	 * Suppression de la devise
	 */
	public deleteDevise(devise: any,typeReferentiel: TypeReferentiel): Observable<Result> {
		//Suppression du devise
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Devise/deleteDevise/${typeReferentiel}/${devise.idDevise}`);
	}

	/**
	 * Enregistrement de la devise
	 */
	public saveDevise(devise: any,typeReferentiel: TypeReferentiel): Observable<Result> {
		//Enregistrement de la devise
		return this.http.put<Result>(`${environment.baseUrl}/controller/Devise/saveDevise/${typeReferentiel}`,devise);
	}

	/**
	 * Suppressions des éléments selectionnés
	 */
	public deleteAllForSelection(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmationMasse')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed)
					//Lancement en masse
					this.doDeleteAllForSelection(messagingOptions,subject);
			}
		});

		return subject;
	}

	/**
	 * Déclenchement de la suppression en masse
	 */
	private doDeleteAllForSelection(messagingOptions: MessagingOptions,subject: Subject<any>) {
		let messaging$: MessagingObservables;

		//Démarrage de la suppression par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}
}