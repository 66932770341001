import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { PleaseWaitModalComponent } from './please-wait-modal.component';

@Injectable()
export class PleaseWaitService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) {}

	/**
	 * Affichage de la popup d'attente
	 */
	show({ message }: { message?: string } = {}): BsModalRef<PleaseWaitModalComponent> {
		//Affichage de la popup
		return this.bsModalService.show(PleaseWaitModalComponent,{
			initialState: {
				message
			},
			backdrop: 'static',
			keyboard: false
		});
	}
}