<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.typeSinistre.reference.substring(0,3).toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Sinistre/loadSinistre',data.idSinistre]"><span translate [translateParams]="{ reference: data.reference,vehicule: data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ' (' + (data.vehicule.modele ? data.vehicule.modele.marque.libelle + ' ' + data.vehicule.modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }">vehicule.sinistre.titre</span></a>
</div>
<ul class="lv-attrs">
	<li><span translate>vehicule.sinistre.collaborateur</span>&#160;:&#160;<span [translate]>{{ data.user ? data.user.nom + ' ' + data.user.prenom + ' (' + data.user.matricule + (data.user.societe ? ' - ' + data.user.societe.libelle : '') + ')' : ('common.nonDefini' | translate) }}</span></li>
	<li><span translate>vehicule.sinistre.dateSinistre</span>&#160;:&#160;<span [translate]>{{ data.dateSinistre | date:'short' }}</span></li>
	<li *ngIf="data.statut"><span translate>vehicule.sinistre.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.sinistre.statut.' + data.statut"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li><a [routerLink]="[]" (click)="sendNotification()" translate>actions.notifier</a></li>
		<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteSinistre()"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>