import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';
import { GenreLineItemComponent } from './genre.line-item';

/**
 * Options des genres de véhicule
 */
export default class GenreOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.genre';

	/**
	 * URL
	 */
	url(filter?: { typeSource?: null | 'LOCAL' | 'EXTERNE_CATALOGUE' | 'EXTERNE_IMMATRICULATION',type?: string,idPays?: number }): string {
		//Retour de l'URL
		return `/controller/VehiculeReferentiel/listeGenresActifs${filter?.typeSource ? '/' + filter?.typeSource : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item?.libelle} (${item?.reference} - ${translateService.instant('referentiel.typeReferentiel.'+item?.typeSource)}${item?.pays?.code2?.length ? ' - ' + item.pays.code2 : ''})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.VehiculeGenre';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idGenre'; }

	/**
	 * Composant d'affichage d'une ligne
	 */
	lineItemComponent?: () => any = () => GenreLineItemComponent;

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: { typeSource?: null | 'LOCAL' | 'EXTERNE_CATALOGUE' | 'EXTERNE_IMMATRICULATION',type?: string,idPays?: number }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.type && {
			clef: 'type',
			valeur: filter.type,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},filter?.idPays && {
			clef: 'pays.idPays',
			valeur: filter.idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'vehicule.genre.typeSource.libelle',
			getValue: (item: any,translateService: TranslateService) => item?.typeSource ? translateService.instant('referentiel.typeReferentiel.'+item?.typeSource) : translateService.instant('common.nonDefini')
		},{
			title: 'vehicule.genre.pays',
			getCountryCode: (item: any) => item?.pays?.code2,
			getValue: (item: any) => item?.pays?.libelle
		}];
	}
}