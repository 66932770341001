import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConnecteurService } from './connecteur.service';
import { ImportService } from './import/import.service';

@Component({
	selector: 'connecteur-execution-list-item',
	templateUrl: './connecteur-execution-list-item.component.html'
})
export class ConnecteurExecutionListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private connecteurService: ConnecteurService,private importService: ImportService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du compte-rendu d'exécution
	 */
	consultApiDataResult() {
		//Affichage du résultat de l'exécution
		this.connecteurService.showExecutionResult(this.data);
	}

	/**
	 * Téléchargement des journaux d'exécution
	 */
	downloadLogs() {
		//Téléchargement des journaux d'exécution
		this.importService.generateLogs(this.data.idExecution);
	}
}
