import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';

@Component({
	templateUrl: './vehicule-imputation-selection-item.component.html'
})
export class VehiculeImputationSelectionItemComponent extends ListViewItem<any>  {
	/** Enumération des types de véhicule **/
	public TypeVehicule: typeof TypeVehicule = TypeVehicule;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}