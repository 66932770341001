<div class="modal-header">
	<h4 class="modal-title" translate>businessData.selection.title</h4>
</div>
<div class="modal-body">
	<div class="panel panel-default" *ngIf="businessData">
		<div class="panel-body">
			<div class="card focus m-0" *ngIf="options.isPreviewData && autocompleteOptions?.type && !options.preloadedEntity">
				<div class="card-body">
					<div class="p-b-10">
						<div translate>businessData.selection.donneeReference</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': autocomplete.invalid }">
						<div>
							<autocomplete name="autocomplete" [options]="autocompleteOptions" #autocomplete="ngModel" [(ngModel)]="loadedEntite" [placeholder]="autocompleteOptions?.title | translate"></autocomplete>
						</div>
					</div>
				</div>
			</div>

			<ol class="breadcrumb p-l-0 b-0 m-b-0" *ngIf="listeOpenedEntities.length">
				<li *ngFor="let entite of listeOpenedEntities; let index = index; let last = last">
					<a *ngIf="!last" [routerLink]="[]" (click)="gotBackToEntity(index + 1)">{{ entite.libelle }}</a>
					<span *ngIf="last">{{ entite.libelle }}</span>
				</li>
			</ol>

			<ul class="list-group" *ngIf="listeOpenedEntities.length">
				<li class="list-group-item" *ngFor="let entite of listeOpenedEntities[listeOpenedEntities.length - 1].enclosedWrapper.listeMembres | filterBy:filterListeMembresByContext" [ngClass]="{ 'ellipsis': options.isPreviewData && options.typeContext != 'DATAFETCH' && entite.enclosedWrapper == null }">
					<ng-container *ngIf="options.typeContext == 'DATAFETCH' && entite.enclosedWrapper == null">
						<mat-checkbox name="selected" #selected="ngModel" [(ngModel)]="entite.selected" (change)="onEntiteSelectionChange(entite)"></mat-checkbox>

						<span>
							<a [routerLink]="[]" (click)="selectItem(entite);">{{ entite.libelle }}</a>
							<span *ngIf="loadedEntite && readValue(entite)" class="italic nc-text-other" [tooltip]="readValue(entite)">&nbsp;(<span>{{ readValue(entite) }}</span>)</span>
						</span>
					</ng-container>

					<span *ngIf="options.typeContext != 'DATAFETCH' || entite.enclosedWrapper != null" class="checkbox-padding">
						<a [routerLink]="[]" class="clickable" (click)="selectItem(entite);">{{ entite.libelle }}</a>

						<span *ngIf="entite.enclosedWrapper == null && loadedEntite && readValue(entite)" class="italic nc-text-other" [tooltip]="readValue(entite)">&nbsp;(<span>{{ readValue(entite) }}</span>)</span>
					</span>
					<div *ngIf="entite.enclosedWrapper != null" (click)="openEntity(entite);"><icon name="chevron_right" class="icon-hc-2x clickable"></icon></div>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="options.typeContext == 'DATAFETCH'" (click)="saveItems();">
		<span translate>actions.enregistrer</span>
	</button>
</div>