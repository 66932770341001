import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minBy,pull,pullAll,union,unionBy } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'configuration-contrainte',
	templateUrl: './configuration-contrainte.component.html'
})
export class ConfigurationContrainteComponent implements OnInit {
	/** Configuration **/
	@Input() configuration: any;

	/** Détail **/
	@Input() detail: any;

	/** Contrainte **/
	@Input() contrainteObject: any;

	/** Liste des inclusions à afficher **/
	public listePrimaryInclusionsToDisplay: Array<any>;

	/** Inclusions et exclusions engendrées par les obligations sélectionnées **/
	public impactObligations: any = {
		listeInclusions: [],
		listeExclusions: []
	};

	/** Résultat de la popup **/
	result: { detail: any } = null;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ConfigurationContrainteComponent>,private translateService: TranslateService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Parcours des contraintes d'obligation
		this.contrainteObject?.listeObligations?.forEach(obligation => {
			//Sélection automatique de la contrainte si le détail associé l'était déjà
			obligation.selected = !!obligation?.detail?.selected;

			//Gestion des impacts de la sélection
			obligation?.selected && this.onObligationSelectionChange(obligation);
		})
	}


	/**
	 * Vérification que l'option associée à la contrainte d'obligation est sélectionnée par une inclusion
	 */
	public isObligationIncluded(contrainte: any): number {
		let listeSourcesInclusion: Array<any>;

		//Vérification que l'obligation est sélectionnée
		if (contrainte?.selected) {
			//Récupération de l'ensemble des sources d'inclusion de l'obligation
			listeSourcesInclusion = unionBy(contrainte?.detail?.listeSourcesInclusion,this.impactObligations?.listeInclusions?.filter(inclusion => inclusion?.detail == contrainte?.detail).flatMap(inclusion => inclusion?.listeSourcesInclusion),'detail');

			//Retour de l'inclusion la plus avantageuse qui force la sélection de l'obligation
			return minBy(listeSourcesInclusion,(inclusion: any) => inclusion?.contrainte?.prixHT);
		}

		return null;
	}

	/**
	 * Vérification que l'option associée à la contrainte d'obligation est sélectionnée par une autre obligation
	 */
	public isObligationObliged(contrainte: any): number {
		//Retour de l'obligation la plus avantageuse qui force la sélection de l'obligation
		return contrainte?.selected && minBy(contrainte?.detail?.listeSourcesObligation,(obligation: any) => obligation?.contrainte?.prixHT) || null;
	}

	/**
	 * Récupération du titre du sélecteur d'option
	 */
	public getSelectorTitleForObligation(obligation: any): string {
		let inclusion: any;
		let obligationExterne: any;

		//Récupération de l'inclusion qui force la sélection de l'obligation
		inclusion = this.isObligationIncluded(obligation);

		//Récupération de l'obligation externe qui force la sélection de l'obligation
		obligationExterne = this.isObligationObliged(obligation);

		//Retour du libellé
		return inclusion ? this.translateService.instant('vehicule.modele.configuration.option.incluse',{ option: inclusion?.detail?.option?.libelle }) : obligationExterne ? this.translateService.instant('vehicule.modele.configuration.option.ajoutee',{ option: obligationExterne?.detail?.option?.libelle }) : '';
	}

	/**
	 * Gestion de la sélection/désélection des contraintes d'obligation
	 */
	public onObligationSelectionChange(contrainte: any): void {
		let listeNestedExclusions: Array<any>;

		//Vérification de la désélection
		if (!contrainte?.selected) {
			//Vérification que l'option était déjà sélectionnée avant ouverture de la pop-up
			if (contrainte?.detail?.selected)
				//Ajout de l'option aux futures exclusions
				this.impactObligations?.listeExclusions?.indexOf(contrainte?.detail) == -1 && this.impactObligations?.listeExclusions?.push(contrainte?.detail);

			//Parcours des inclusions engendrées par l'obligation
			contrainte?.detail?.option?.listeContraintes?.filter(c => c?.typeContrainte == 'INCLUSION').forEach(inclusion => {
				let index: number;

				//Récupération de l'index de l'option dans la liste des inclusions générées par les obligations
				index = this.impactObligations?.listeInclusions?.findIndex(i => i?.detail == inclusion?.detail);

				//Vérification que l'option est présente dans la liste des inclusions
				if (index > -1) {
					//Retrait de l'obligation courante de la liste des sources d'inclusion
					this.impactObligations.listeInclusions[index].listeSourcesInclusion = this.impactObligations?.listeInclusions[index]?.listeSourcesInclusion?.filter(inclusion => inclusion?.detail != contrainte?.detail);

					//Vérification que l'option ne possède plus aucune source d'inclusion
					if (!this.impactObligations?.listeInclusions[index]?.listeSourcesInclusion?.length)
						//Retrait complet de l'option de la liste
						this.impactObligations?.listeInclusions?.splice(index,1);
				}
			});

			//Vérification que l'option s'accompagne d'exclusions à retirer
			listeNestedExclusions = contrainte?.detail?.option?.listeContraintes?.filter(c => {
				//Retour du filtre
				return c?.typeContrainte == 'EXCLUSION' && this.impactObligations?.listeExclusions?.indexOf(c.detail) > -1
					&& !this.contrainteObject?.listeObligations?.some(o => o.eqtextId == c.eqtextId)
					&& !this.contrainteObject?.listeObligations?.some(o => o.selected && o.detail?.option?.listeContraintes?.some(c1 => c1.typeContrainte == 'EXCLUSION' && c1.eqtextId == c.eqtextId));
			});

			//Vérification que l'option s'accompagne d'exclusions à retirer
			if (listeNestedExclusions?.length)
				//Retrait des exclusions de la liste
				pullAll(this.impactObligations?.listeExclusions,listeNestedExclusions?.map(nestedExclusion => nestedExclusion?.detail));
		} else {
			//Retrait de l'option des futures exclusions
			pull(this.impactObligations?.listeExclusions,contrainte?.detail);

			//Parcours des inclusions engendrées par l'obligation
			contrainte?.detail?.option?.listeContraintes?.filter(c => c.typeContrainte == 'INCLUSION' && c.detail != this.detail && !c.detail?.listeSourcesInclusion?.some(i => i.detail == contrainte.detail)).forEach(inclusionToAdd => {
				let index: number;

				//Récupération de l'index de l'inclusion dans la liste
				index = this.impactObligations?.listeInclusions?.findIndex(i => i.detail == inclusionToAdd?.detail);

				//Vérification que l'inclusion est absente de la liste
				if (index == -1) {
					//Ajout d'une nouvelle inclusion à la liste
					this.impactObligations?.listeInclusions?.push({
						detail: inclusionToAdd?.detail,
						listeSourcesInclusion: [{
							detail: contrainte?.detail,
							contrainte: inclusionToAdd
						}]
					});
				} else {
					//Ajout de l'obligation à la liste des sources de l'inclusion
					this.impactObligations?.listeInclusions[index].listeSourcesInclusion?.push({
						detail: contrainte?.detail,
						contrainte: inclusionToAdd
					});
				}
			});
		}

		//Vérification que l'option s'accompagne d'exclusions à rajouter
		listeNestedExclusions = contrainte?.detail?.option?.listeContraintes?.filter(c => {
			//Retour du filtre
			return c?.typeContrainte == 'EXCLUSION' && (c.detail?.selected || this.contrainteObject?.listeInclusions?.some(i => i.detail == c.detail))
				&& this.impactObligations?.listeExclusions?.indexOf(c.detail) == -1;
		});

		//Vérification que l'option s'accompagne d'exclusions à rajouter
		if (listeNestedExclusions?.length)
			//Ajout des exclusions à la liste
			listeNestedExclusions.forEach(e => this.impactObligations?.listeExclusions?.push(e.detail));

		//Parcours des contraintes d'obligation
		this.contrainteObject?.listeObligations?.forEach(o => {
			//Vérification que la contrainte d'obligation n'est pas sélectionnée mais est désormais incluse
			if (!o.selected && this.impactObligations.listeInclusions?.some(i => i.detail == o.detail)) {
				//Sélection de l'obligation
				o.selected = true;

				//Gestion en cascade des impacts de la sélection
				this.onObligationSelectionChange(o);
			}

			//Vérification que la contrainte d'obligation est sélectionnée mais est désormais exclue
			if (o.selected && contrainte?.detail?.option?.listeContraintes?.some(c => c.typeContrainte == 'EXCLUSION' && c.eqtextId == o.eqtextId)) {
				//Désélection de l'obligation
				o.selected = false;

				//Gestion en cascade des impacts de la désélection
				this.onObligationSelectionChange(o);
			}
		});
	}

	/**
	 * Récupération de la liste à afficher des inclusions directes de l'option racine de la pop-up
	 */
	public getListePrimaryInclusionsToDisplay(): Array<any> {
		//liste des inclusions à afficher engendrées par l'option racine de la pop-up
		this.listePrimaryInclusionsToDisplay = this.contrainteObject?.listeInclusions?.filter(i => !i.detail?.selected);

		//Retour de la liste
		return this.listePrimaryInclusionsToDisplay;
	}


	/**
	 * Récupération de la liste des inclusions à afficher engendrées par les obligations sélectionnées
	 */
	public getListeSecondaryInclusionsToDisplay(): Array<any> {
		//Retour de la liste des inclusions à afficher engendrées par les obligations sélectionnées
		return this.impactObligations.listeInclusions.filter(i => !i.detail?.selected).map(i => {
			//Retour de la contrainte d'inclusion qui donne le prix le plus faible
			return minBy(i.listeSourcesInclusion,(source: any) => source?.contrainte?.prixHT).contrainte;
		});
	}

	/**
	 * Récupération du prix qui sera effectivement retranché du prix des options par l'exclusion d'un détail
	 */
	public getActualPriceToRemove(detailExclu: any): number {
		//Vérification de la situation de l'option
		if (!detailExclu?.selected) {
			//L'exclusion du détail ne va pas modifier le prix total des options
			return 0;
		} else if (detailExclu?.listeSourcesInclusion) {
			//Retour du prix le plus faible obtenu par inclusion
			return this.configuration.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detailExclu?.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detailExclu?.listeSourcesInclusion,(i: any) => i.contrainte.prixHT)?.contrainte?.prixHT;
		} else if (detailExclu?.listeSourcesObligation) {
			//Retour du prix le plus faible obtenu par obligation
			return this.configuration.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detailExclu?.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detailExclu?.listeSourcesObligation,(o: any) => o.contrainte.prixHT)?.contrainte?.prixHT;
		}

		//Retour du prix hors contrainte de l'option
		return this.configuration.modele.information.genre.modeAffichagePrix == 'TTC' ? detailExclu?.option?.prixTTC : detailExclu?.option?.prixHT;

	}

	/**
	 * Validation des contraintes
	 */
	public validate() {
		let result: any;

		//Initialisation du résultat
		result = {
			listeIncludedOptions: [],
			listeRemoveOptions: [],
			listeObligedOptions: [],
			prixHTOptions: 0,
			prixTTCOptions: 0
		};

		//Vérification de la sélection
		if (this.detail?.selected) {
			//Calcul des prix des options ajoutées
			result.prixHTOptions += this.detail?.option?.prixHT;
			result.prixTTCOptions += this.detail?.option?.prixTTC;
		} else {
			//Calcul des prix des options retirées
			result.prixHTOptions -= this.detail?.option?.prixHT;
			result.prixTTCOptions -= this.detail?.option?.prixTTC;
		}

		//Récupération des inclusions engendrées par les obligations
		result.listeIncludedOptions = this.impactObligations?.listeInclusions;

		//Parcours des inclusions directes de l'option racine de la pop-up
		this.contrainteObject?.listeInclusions?.forEach(contrainte => {
			let index: number;

			//Vérification que l'inclusion n'est pas exclue par l'une des obligations
			if (this.impactObligations?.listeExclusions.indexOf(contrainte?.detail) == -1) {
				//Récupération de l'index de l'inclusion dans la liste
				index = result.listeIncludedOptions.findIndex(i => i.eqtextId == contrainte.eqtextId);

				//Vérification que l'inclusion est absente de la liste
				if (index == -1) {
					//Ajout d'une nouvelle inclusion à la liste
					result.listeIncludedOptions.push({
						detail: contrainte.detail,
						listeSourcesInclusion: [{
							detail: this.detail,
							contrainte
						}]
					});
				} else {
					//Ajout de l'option à la liste des sources de l'inclusion
					result?.listeIncludedOptions[index]?.listeSourcesInclusion?.push({
						detail: this.detail,
						contrainte
					});
				}
			}
		});

		//Parcours des options à cocher par inclusion pour mettre à jour le prix total des options
		result.listeIncludedOptions.forEach(inclusion => {
			let newMinPrixHTInclusion: number;
			let newMinPrixTTCInclusion: number;
			let oldMinPrixHTInclusion: number;
			let oldMinPrixTTCInclusion: number;
			let oldPrixHTObligation: number;
			let oldPrixTTCObligation: number;

			//Récupération du prix le plus faible parmi toutes les nouvelles sources de l'inclusion
			newMinPrixHTInclusion = minBy(inclusion.listeSourcesInclusion,(i: any) => i.contrainte?.prixHT)?.contrainte?.prixHT;
			newMinPrixTTCInclusion = minBy(inclusion.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC)?.contrainte?.prixTTC;

			//Récupération du prix le plus faible parmi les sources existantes de l'inclusion
			oldMinPrixHTInclusion = inclusion.detail?.listeSourcesInclusion ? minBy(inclusion.detail?.listeSourcesInclusion,(i: any) => i.contrainte?.prixHT)?.contrainte?.prixHT : null;
			oldMinPrixTTCInclusion = inclusion.detail?.listeSourcesInclusion ? minBy(inclusion.detail?.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC)?.contrainte?.prixTTC : null;

			//Récupération du prix le plux faible parmi les sources existantes de l'obligation
			oldPrixHTObligation = inclusion.detail?.listeSourcesObligation ? minBy(inclusion.detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixHT)?.contrainte?.prixHT : null;
			oldPrixTTCObligation = inclusion.detail?.listeSourcesObligation ? minBy(inclusion.detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC)?.contrainte?.prixTTC : null;

			//Vérification de la situation initiale de l'option à inclure
			if (!inclusion.detail?.selected) {
				//Ajout du prix le plus faible parmi les nouvelles sources d'inclusion
				result.prixHTOptions += newMinPrixHTInclusion;
				result.prixTTCOptions += newMinPrixTTCInclusion;
			} else if (inclusion?.detail?.listeSourcesInclusion) {
				//Retrait de la différence avec le nouveau prix d'inclusion s'il est inférieur
				result.prixHTOptions -= newMinPrixHTInclusion < oldMinPrixHTInclusion ? oldMinPrixHTInclusion - newMinPrixHTInclusion : 0;
				result.prixTTCOptions -= newMinPrixTTCInclusion < oldMinPrixTTCInclusion ? oldMinPrixTTCInclusion - newMinPrixTTCInclusion : 0;
			} else if (inclusion?.detail?.listeSourcesObligation) {
				//Remplacement du prix issu de l'obligation par celui de l'inclusion
				result.prixHTOptions += newMinPrixHTInclusion - oldPrixHTObligation;
				result.prixTTCOptions += newMinPrixTTCInclusion - oldPrixTTCObligation;
			} else {
				//Remplacement du prix hors contrainte par celui de l'inclusion
				result.prixHTOptions += newMinPrixHTInclusion - inclusion.detail.option.prixHT;
				result.prixTTCOptions += newMinPrixTTCInclusion - inclusion.detail.option.prixTTC;
			}
		});


		//Parcours des exclusions pour mettre à jour le prix total des options
		union(this.contrainteObject?.listeExclusions,this.impactObligations?.listeExclusions).forEach((detail: any) => {
			//Vérification que l'option était déjà sélectionnée
			if (detail?.selected) {
				//Ajout de la référence externe de l'option dans la liste des options à décocher
				result.listeRemoveOptions?.push(detail.option?.eqtextId);

				//Vérification de la situation initiale de l'option à retirer
				if (detail?.listeSourcesInclusion) {
					//Retrait du prix issu de l'inclusion
					result.prixHTOptions -= minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixHT)?.contrainte?.prixHT;
					result.prixTTCOptions -= minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC)?.contrainte?.prixTTC;
				} else if (detail?.listeSourcesObligation) {
					//Retrait du prix issu de l'obligation
					result.prixHTOptions -= minBy(detail.listeSourcesObligation,(o: any) => o.contrainte?.prixHT)?.contrainte?.prixHT;
					result.prixTTCOptions -= minBy(detail.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC)?.contrainte?.prixTTC;
				} else {
					//Retrait du prix hors contrainte de l'option
					result.prixHTOptions -= detail.option?.prixHT;
					result.prixTTCOptions -= detail.option?.prixTTC;
				}
			}
		});

		//Parcours des obligations pour mettre à jour le prix total des options
		this.contrainteObject?.listeObligations?.forEach(obligation => {
			let oldPrixHT: number;
			let oldPrixTTC: number;

			//Vérification que l'obligation n'est pas aussi incluse par ailleurs (auquel cas c'est son prix en tant qu'inclusion qui est comptabilisé)
			if (obligation?.selected && !this.isObligationIncluded(obligation)) {
				//Ajout de la référence externe de l'option dans la liste des options à cocher
				result.listeObligedOptions?.push({
					eqtextId: obligation.eqtextId,
					sourceObligation: {
						detail: this.detail,
						contrainte: obligation
					}
				});

				//Récupération du prix le plux faible parmi les sources existantes de l'obligation
				oldPrixHT = obligation.detail?.listeSourcesObligation ? minBy(obligation.detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixHT).contrainte?.prixHT : null;
				oldPrixTTC = obligation.detail?.listeSourcesObligation ? minBy(obligation.detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC).contrainte?.prixTTC : null;

				//Vérification de la situation initiale de l'option ajoutée
				if (!obligation?.detail?.selected) {
					//Ajout du prix de l'obligation
					result.prixHTOptions += obligation?.prixHT;
					result.prixTTCOptions += obligation?.prixTTC;
				} else if (obligation?.detail?.listeSourcesObligation) {
					//Retrait de la différence avec le nouveau prix s'il est inférieur
					result.prixHTOptions -= obligation.prixHT < oldPrixHT ? oldPrixHT - obligation.prixHT : 0;
					result.prixTTCOptions -= obligation.prixTTC < oldPrixTTC ? oldPrixTTC - obligation.prixTTC : 0;
				} else {
					//Remplacement du prix hors contrainte par celui de l'obligation
					result.prixHTOptions += obligation.prixHT - obligation.detail?.option?.prixHT;
					result.prixTTCOptions += obligation.prixTTC - obligation.detail?.option?.prixTTC;
				}
			}
		});

		//Définition du résultat
		this.result = {
			detail: result
		};

		//Fermeture de la popup avec le résultat
		this.bsModalRef?.hide();
	}
}