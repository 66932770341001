import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdvancedSearchOptions,AggregatedResponse } from 'src/app/domain/advanced-search/advanced-search';
import { environment } from 'src/environments/environment';
import { AdvancedSearchComponent } from './advanced-search.component';

@Injectable()
export class AdvancedSearchService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService) { }

	/**
	 * Affichage de la recherche avancée
	 */
	public showAdvancedSearch(options: AdvancedSearchOptions,isInit: boolean = true): Observable<any> {
		let bsModalRef: BsModalRef<AdvancedSearchComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(AdvancedSearchComponent,{
			initialState: {
				options,
				isInit
			},
			backdrop: 'static',
			class: 'modal-lg'
		});

		//Retour de la sélection
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.searchSpec)
		);
	}

	/**
	 * Chargement des données
	 */
	public loadData(options: AdvancedSearchOptions): Observable<AggregatedResponse> {
		//Chargement des données
		return this.http.post<AggregatedResponse>(environment.baseUrl + options.uri,this.makeSearchSpec(options));
	}

	/**
	 * Construction de la recherche
	 */
	public makeSearchSpec(options: AdvancedSearchOptions) {
		let searchSpec: any;
		let listeAgregats: Array<string> = [];
		let listeFilters: Array<any>;
		let minPercent: number;
		let maxPercent: number;
		let searchHintSuffix: string;

		//Parcours des filtres
		options.listeFiltres.forEach(filtre => {
			//Vérification d'une clef d'agrégat ou d'une liste de clefs
			if (filtre.listeKeys && filtre.type != 'numeric') {
				//Itération sur la liste des clefs
				filtre.listeKeys.forEach(item => {
					//Remise à zéro du suffixe
					searchHintSuffix = '';

					//Vérification du filtre
					if (filtre.libelleMissing)
						//Définition du suffixe de prise en compte des documents sans valeur pour la clé
						searchHintSuffix = '-MISSING';
					else if (filtre.typeAggregate == 'month')
						//Définition du suffixe d'aggrégation par mois
						searchHintSuffix = '-MONTH';

					//Ajout de l'agrégat à la liste
					listeAgregats.push(item.aggregateKey + searchHintSuffix);
				});
			} else if (filtre.aggregateKey && filtre.type != 'numeric') {
				//Remise à zéro du suffixe
				searchHintSuffix = '';

				//Vérification du filtre
				if (filtre.libelleMissing)
					//Définition du suffixe de prise en compte des documents sans valeur pour la clé
					searchHintSuffix = '-MISSING';
				else if (filtre.typeAggregate == 'month')
					//Définition du suffixe d'aggrégation par mois
					searchHintSuffix = '-MONTH';

				//Ajout de l'agrégat à la liste
				listeAgregats.push(filtre.aggregateKey + searchHintSuffix);
			} else if (filtre.listeKeys && filtre.type == 'numeric') {
				//Itération sur la liste des clefs
				filtre.listeKeys.forEach(item => {
					//Définition des valeurs de pourcentage
					minPercent = item.minPercent ? item.minPercent : filtre.minPercent ? filtre.minPercent : 0;
					maxPercent = item.maxPercent ? item.maxPercent : filtre.maxPercent ? filtre.maxPercent : 100;

					//Ajout des agrégats en pourcentiles
					listeAgregats.push(item.aggregateKey + '-PERCENTILMIN-' + minPercent);
					listeAgregats.push(item.aggregateKey + '-PERCENTILMAX-' + maxPercent);
				});
			} else if (filtre.aggregateKey && filtre.type == 'numeric') {
				//Définition des valeurs de pourcentage
				minPercent = filtre.minPercent ? filtre.minPercent : 0;
				maxPercent = filtre.maxPercent ? filtre.maxPercent : 100;

				//Ajout des agrégats en pourcentiles
				listeAgregats.push((filtre.aggregateKey ? filtre.aggregateKey : filtre.listeKeys[0].aggregateKey) + '-PERCENTILMIN-' + minPercent);
				listeAgregats.push((filtre.aggregateKey ? filtre.aggregateKey : filtre.listeKeys[0].aggregateKey) + '-PERCENTILMAX-' + maxPercent);
			}
		});

		//Création de la recherche
		searchSpec = {
			listeAgregats,
			defaultSearch: ''
		};

		//Construction des filtres
		listeFilters = this.buildSearchFilters(options);

		//Ajout des filtres à la recherche
		searchSpec.listeFilter = listeFilters;

		//Ajout des filtres supplémentaires à la recherche
		searchSpec.listeFilter = searchSpec.listeFilter.concat(options.listeFiltersExtended?.() || []);

		return searchSpec;
	}

	/**
	 * Ajout des filtres à la recherche
	 */
	private buildSearchFilters(options: AdvancedSearchOptions) {
		let listeFilters: Array<any> = [];

		//Parcours des filtres
		options.listeFiltres.forEach(filtre => {
			//Vérification de valeurs sélectionnées sur un filtre
			if (filtre.listeSelectedValues?.length > 0
					|| filtre.sliderOptions?.min && filtre.sliderOptions?.min != filtre.sliderOptions?.options.floor
					|| filtre.sliderOptions?.max && filtre.sliderOptions?.max != filtre.sliderOptions?.options.ceil) {
				//Vérification du type de filtre
				switch (filtre.type) {
				case 'autocomplete':
					//Ajout du filtre
					listeFilters.push({
						clef: filtre.searchKey,
						listeObjects: filtre.listeSelectedValues.map(item => {
							//Mapping de la valeur du filtre
							item = filtre.mapSelectedItem?.(item) || item;

							//Ajout du type
							item._type = 'com.notilus.model.search.CombinedCriteria';

							return item;
						}),
						typeComparaison: 'IN'
					});
					break;
				case 'label':
					//Vérification du type d'agrégat
					if (filtre.typeAggregate == 'month') {
						//Ajout du filtre
						listeFilters.push({
							clef: filtre.searchKey,
							listeObjects: filtre.listeSelectedValues.map(v => v || null),
							typeComparaison: 'IN',
							type: 'MONTH',
							_type: 'java.util.Date'
						});
					} else {
						//Ajout du filtre
						listeFilters.push({
							clef: filtre.searchKey,
							listeObjects: filtre.listeSelectedValues.map(v => v || null),
							typeComparaison: 'IN',
							_type: 'java.lang.String'
						});
					}
					break;
				case 'boolean':
					//Ajout du filtre
					listeFilters.push({
						clef: filtre.searchKey,
						valeur: filtre.listeSelectedValues[0],
						typeComparaison: 'EQUAL',
						type: 'BOOLEAN'
					});
					break;
				case 'numeric':
					//Ajout du filtre
					listeFilters.push({
						clef: filtre.searchKey,
						min: filtre.sliderOptions && filtre.sliderOptions.min,
						max: filtre.sliderOptions && filtre.sliderOptions.max,
						typeComparaison: 'BETWEEN',
						type: 'DECIMAL'
					});
					break;
				}
			}
		});

		return listeFilters;
	}
}