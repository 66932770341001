<tr>
	<td>
		<div class="node" [ngClass]="mode">
			<div class="content">
				<div class="title">
					<span>{{ libelle }}</span>
				</div>
			</div>
		</div>
	</td>
</tr>
