import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'vehicule-etat-lieux-edit',
	templateUrl: './vehicule-etat-lieux-edit.component.html'
})
export class VehiculeEtatLieuxEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() etatLieux: any;

	/** Suppression de l'état des lieux **/
	@Input() deleteEtatLieux: (etatLieux: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}