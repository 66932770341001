import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { CalendrierService } from './calendrier.service';

@Component({
	templateUrl: './calendrier-list-item.component.html'
})
export class CalendrierListItemComponent extends ListViewItem<any> {
	/** Calendrier courant **/
	data: any;

	/**
	 * Constructeur
	 */
	constructor(private calendrierService: CalendrierService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject);
	}

	/**
	 * Suppression d'un calendrier
	 */
	deleteCalendrier() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du calendrier
					this.calendrierService.deleteCalendrier(this.data.idCalendrier).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du calendrier
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}