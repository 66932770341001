import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeStatut } from 'src/app/domain/vehicule/infraction/demande-identification';
import { environment } from 'src/environments/environment';

@Injectable()
export class DemandeIdentificationService {
	/** Liste des statuts **/
	private listeStatuts: Array<{ code: TypeStatut,icone: string,iconeType?: string }> = [{
		code: TypeStatut.NOUVELLE_DEMANDE,
		icone: 'question_mark'
	},{
		code: TypeStatut.ANOMALIE,
		icone: 'warning'
	},{
		code: TypeStatut.A_TRANSMETTRE,
		icone: 'edit'
	},{
		code: TypeStatut.TRANSMIS,
		icone: 'swap_horiz'
	},{
		code: TypeStatut.INTEGREE,
		icone: 'check'
	},{
		code: TypeStatut.ENVOI_PAPIER,
		icone: 'email'
	}];

	/** Liste des types de désignation **/
	private listeTypesDesignation: Array<{ code: string,_type?: string }> = [{
		code: 'CONDUCTEUR',
		_type: 'com.notilus.data.sgi.DesignationConducteur'
	},{
		code: 'VEHICULE_VENDU',
		_type: 'com.notilus.data.sgi.DesignationVehicule'
	},{
		code: 'VEHICULE_VOLE',
		_type: 'com.notilus.data.sgi.DesignationVehicule'
	},{
		code: 'VEHICULE_RENDU',
		_type: 'com.notilus.data.sgi.DesignationVehicule'
	},{
		code: 'ENVOI_PAPIER'
	},{
		code: 'SOCIETE',
		_type: 'com.notilus.data.sgi.DesignationSociete'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des statuts
	 */
	public getListeStatuts(): Array<{ code: string,icone: string,iconeType?: string }> { return this.listeStatuts; }

	/**
	 * Récupération de la liste des types de désignation
	 */
	public getListeTypesDesignation(): Array<{ code: string,_type?: string,libelle: string }> {
		//Retour de la liste des types de désignation
		return this.listeTypesDesignation.map(t => ({
			...t,
			libelle: this.translateService.instant(`demandeIdentification.typeDesignation.${t.code}`)
		}));
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(type: 'A_TRAITER' | 'TRAITE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du tableau de bord
		return this.http.post<Result>(`${environment.baseUrl}/controller/DemandeIdentification/loadCounter/${type}`,searchSpec);
	}

	/**
	 * Chargement d'une demande d'identification
	 */
	public loadDemandeIdentification(idIdentification: number): Observable<Result> {
		//Chargement de la demande d'identification
		return this.http.post<Result>(`${environment.baseUrl}/controller/DemandeIdentification/loadDemande/${idIdentification}`,null);
	}

	/**
	 * Enregistrement d'une demande d'identification
	 */
	public saveDemandeIdentification(demandeIdentification: any,isPublish: boolean = false): Observable<Result> {
		//Enregistrement de la demande d'identification
		return this.http.put<Result>(`${environment.baseUrl}/controller/DemandeIdentification/saveIdentification/${isPublish}`,demandeIdentification);
	}

	/**
	 * Modification du statut d'une demande d'identification
	 */
	public updateStatutForDemandeIdentification(idIdentification: number,statut: TypeStatut): Observable<Result> {
		//Modification du statut de la demande d'identification
		return this.http.put<Result>(`${environment.baseUrl}/controller/DemandeIdentification/updateStatutForDemandeIdentification/${idIdentification}/${statut}`,null);
	}
}