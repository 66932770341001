import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first } from 'rxjs';

import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { RegleAutomatiqueCheckResultatComponent } from './regle-automatique-check-resultat.component';
import { RegleAutomatiqueCheckSelectionComponent } from './regle-automatique-check-selection.component';
import { RegleAutomatiqueService } from './regle-automatique.service';
import { Result } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';

@Component({
	selector: 'regle-automatique-check',
	templateUrl: './regle-automatique-check.component.html'
})
export class RegleAutomatiqueCheckComponent implements AfterContentChecked,OnInit {
	/** Liste des types de règles automatiques **/
	@Input() readonly listeTypesComputedField: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }> = null;

	/** Section initialement choisie **/
	public selectedSection: any;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/**	Règle automatique */
	private regleAutomatique = {
		type: null,
		dateApplication: new Date(),
		typeComputedField: {}
	}

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Liste des résultats **/
	private listeRegleAutomatiqueChecks: Array<Array<any>>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<RegleAutomatiqueCheckComponent>,private translateService: TranslateService,private regleAutomatiqueService: RegleAutomatiqueService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'SELECTION',
			component: RegleAutomatiqueCheckSelectionComponent,
			libelle: 'regleAutomatique.check.selection.title',
			retrieveComponentData: () => ({
				regleAutomatique: this.regleAutomatique,
				listeTypesComputedField: this.listeTypesComputedField
			}),
			listeActions: [{
				isPrimary: true,
				libelle: this.translateService.instant('actions.continuer'),
				isValid: () => !!this.regleAutomatique?.type && !!this.regleAutomatique?.dateApplication,
				onPress: () => {
					let messaging$: MessagingObservables;

					//Vérification du type de règle automatique
					messaging$ = this.regleAutomatiqueService.checkTypeRegleAutomatique(this.regleAutomatique.type,this.regleAutomatique.dateApplication).onFinish({
						next: (message) => {
							//Récupération de la liste des règles automatiques contrôlées
							this.regleAutomatiqueService.getTypeRegleAutomatiqueCheckResult(message.idSession).pipe(first()).subscribe({
								next: (result: Result) => {
									//Définitions des résultats
									this.listeRegleAutomatiqueChecks = result?.data?.listeRegleAutomatiqueChecks;

									//Affichage de la section des résultats
									this.stepperComponent?.selectStep(this.listeSteps[1]);
								}
							});

							//Désabonnement
							messaging$.unsubscribe();
						}
					});
				}
			}],
			canActivate: () => true,
			isDone: () => this.listeRegleAutomatiqueChecks?.length > 0
		},{
			type: 'RESULTAT',
			component: RegleAutomatiqueCheckResultatComponent,
			libelle: 'regleAutomatique.check.resultat.title',
			retrieveComponentData: () => ({
				listeRegleAutomatiqueChecks: this.listeRegleAutomatiqueChecks,
				regleAutomatique: this.regleAutomatique
			}),
			canActivate: () => this.listeRegleAutomatiqueChecks?.length > 0,
			isDone: () => this.listeRegleAutomatiqueChecks?.length > 0,
			listeActions: [{
				isPrimary: false,
				libelle: this.translateService.instant('actions.fermer'),
				isValid: () => true,
				onPress: () => this.close()
			}]
		}];

		//Section initialement choisie
		this.selectedSection = this.listeSteps[0];
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la popup de vérification
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}