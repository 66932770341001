import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { LayoutEffects } from './layout.effects';
import { PageTitleComponent } from './page-title.component';
import { MenuModule } from './menu/menu.module';
import { HeaderModule } from './header/header.module';
import { LoaderModule } from './loader/loader.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { TodoListModule } from './todo-list/todo-list.module';
import { PageTitleService } from './page-title.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ChatbotModule } from './chatbot/chatbot.module';
import { AccountModule } from './account/account.module';
import { AccessForbiddenModule } from './access-forbidden/access-forbidden.module';
import { StyleService } from './style.service';

@NgModule({
	imports: [CoreComponentsModule,EffectsModule.forFeature([LayoutEffects]),MenuModule,HeaderModule,LoaderModule,TodoListModule,IconModule,ChatbotModule,AccountModule,AccessForbiddenModule],
	declarations: [PageTitleComponent],
	exports: [MenuModule,PageTitleComponent,HeaderModule,TodoListModule,ChatbotModule,AccessForbiddenModule],
	providers: [PageTitleService,StyleService]
})
export class LayoutModule {}