import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pickBy,sortBy } from 'lodash-es';
import { Observable,of } from 'rxjs';
import { filter,first,map,tap } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataCustomizationService {
	/** Cache des personnalisations d'entité **/
	private mapDataCustomizationsForEntite: { [entite: string]: any } = {};

	/** Cache des champs personnalisés disponibles par entité **/
	private mapDataCustomizations: { [entite: string]: any } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Chargement des champs personnalisés d'une entité
	 */
	public loadDataCustomizationForEntite(entite: string): Observable<any> {
		//Vérification de l'absence de cache
		if (!this.mapDataCustomizationsForEntite[entite]) {
			//Chargement de la personnalisation
			return this.http.post<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/loadDataCustomizationForEntite/${entite}`,null).pipe(
				map(result => result?.data?.dataCustomization),
				tap(dataCustomization => this.mapDataCustomizationsForEntite[entite] = dataCustomization)
			);
		} else
			//Retour du cache
			return of(this.mapDataCustomizationsForEntite[entite]);
	}

	/**
	 * Chargement des champs personnalisés disponibles d'une entité
	 */
	public loadDataCustomization(entite: string): Observable<any> {
		//Vérification de l'absence de cache
		if (!this.mapDataCustomizations[entite]) {
			//Chargement de la personnalisation
			return this.http.post<Result>(`${environment.baseUrl}/controller/EntiteDataCustomization/load`,{
				name: entite
			}).pipe(
				map(result => result?.data?.entiteDataCustomization),
				tap(entiteDataCustomization => this.mapDataCustomizations[entite] = entiteDataCustomization)
			);
		} else
			//Retour du cache
			return of(this.mapDataCustomizations[entite]);
	}

	/**
	 * Enregistrement des champs personnalisés
	 */
	public saveDataCustomization(dataCustomization: any): Observable<any> {
		//Enregistrement de la personnalisation
		return this.http.post<Result>(`${environment.baseUrl}/controller/EntiteDataCustomization/save`,dataCustomization).pipe(
			map(result => result?.data?.entiteDataCustomization),
			tap(entiteDataCustomization => this.mapDataCustomizations[entiteDataCustomization.entite] = entiteDataCustomization)
		);
	}

	/**
	 * Récupération de la liste des champs d'une personnalisation
	 */
	public getListeFields(dataCustomization: any,isKeepAll: boolean): Array<any> {
		let positionKeys: Array<any>;
		let listeFields: Array<any>;

		//Récupération des clés du paramétrage associées à une position
		positionKeys = Object.keys(pickBy(dataCustomization,(value,key) => key.slice(0,8) == 'position' && (isKeepAll || value !== null)));

		//Conversion du paramétrage en une liste de champs à afficher
		listeFields = positionKeys.reduce((acc,key) => {
			let field: any;
			let type: string;

			//Récupération du type de champ personnalisé à partir de la clé (ex: 'positionNumeric1' => 'numeric')
			type = key.slice(8,-1).toLowerCase();

			//Définition du champ
			field = {
				positionKey: key,
				name: (type == 'referentiel' ? 'customRefValue' : type) + key.slice(-1),
				type
			};

			//Définition du libellé du champ
			field.libelle = this.translateService.instant(`dataCustomization.entite.type.${field.type}`,{ index: field.name.slice(-1) });

			//Vérification du type de champ
			if (field.type == 'referentiel')
				//Définition du type de référentiel
				field.typeReferentiel = 'REFERENTIEL_' + field.name.slice(-1);

			//Ajout du champ à la liste
			acc.push(field);

			return acc;
		},[]);

		//Retour de la liste ordonnée des champs (selon le paramétrage ou selon l'ordre alphabétique)
		return sortBy(listeFields,f => isKeepAll ? f.libelle.slice(0,1) : dataCustomization[f.positionKey]);
	}

	/**
	 * Mise à jour de la liste des champs
	 */
	public updateListeFields(entite: string,listeFields: Array<any>) {
		//Récupération de la personnalisation de l'entité
		this.loadDataCustomizationForEntite(entite).pipe(
				first(),
				filter(dataCustomizationForEntite => !!dataCustomizationForEntite)
		).subscribe({
			next: dataCustomizationForEntite => {
				//Parcours des champs
				listeFields.forEach(f => {
					//Itération sur les 4 champs de chaque type
					['1','2','3','4'].forEach(num => {
						//Vérification du champ alphanumérique
						if (dataCustomizationForEntite['alpha' + num] && f.name == 'alpha' + num)
							//Définition du libellé personnalisé
							f.libelle = dataCustomizationForEntite['alpha' + num];

						//Vérification du champ numérique
						if (dataCustomizationForEntite['numeric' + num] && f.name == 'numeric' + num)
							//Définition du libellé personnalisé
							f.libelle = dataCustomizationForEntite['numeric' + num];

						//Vérification du champ date
						if (dataCustomizationForEntite['date' + num] && f.name == 'date' + num)
							//Définition du libellé personnalisé
							f.libelle = dataCustomizationForEntite['date' + num];

						//Vérification du champ référentiel
						if (dataCustomizationForEntite['customRef' + num] && f.name == 'customRefValue' + num)
							//Définition du libellé personnalisé
							f.libelle = dataCustomizationForEntite['customRef' + num];
					});
				});
			}
		})
	}
}