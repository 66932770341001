import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { SocieteService } from './societe.service';

@Component({
	selector: 'societe-add',
	templateUrl: './societe-add.component.html'
})
export class SocieteAddComponent extends AutocompleteAddComponent {
	/** Société **/
	public societe: any = {};

	/**
	 * Constructeur
	 */
	constructor(private societeService: SocieteService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<SocieteAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de l'élément
		this.societeService.saveSociete(this.societe).subscribe(result => {
			let listeDoublons = new Array<string>();

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Définition de la société
				this.addedItem = result.data.societe;

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.siret'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
