<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [type]="extraOptions?.entite?.getListeItemAvatar(data,translateService)?.iconType" [name]="extraOptions?.entite?.getListeItemAvatar(data,translateService)?.icon"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.entite?.onClick(extraOptions?.typeListe,data)">{{ extraOptions?.entite?.getListeItemTitle(data,translateService) }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="extraOptions?.entite?.getListeItemAttributes?.(data,translateService,datePipe).length">
		<li *ngFor="let attribute of extraOptions.entite.getListeItemAttributes(data,translateService,datePipe); trackBy: trackByPosition"><span>{{ attribute.key }}</span>&#160;:&#160;<span>{{ attribute.value }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="extraOptions?.typeListe == TypeListeValidation.A_VALIDER"><a [routerLink]="[]" (click)="doAction('VALIDER');" translate>actions.valider</a></li>
			<li *ngIf="extraOptions?.typeListe == TypeListeValidation.A_VALIDER"><a [routerLink]="[]" (click)="doAction('REJETER');" translate>actions.rejeter</a></li>
			<li><a [routerLink]="[]" (click)="showWorkflowHistory()" translate>actions.workflow.consulter</a></li>
		</ul>
	</div>
</div>