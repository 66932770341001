import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	templateUrl: './extraction-list-item.component.html'
})
export class ExtractionListItemComponent extends ReferentielEntiteListItem<any> {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.extraction.Extraction';

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private historyService: HistoryService,private extractionService: ExtractionService,rightService: RightService,referentielService: ReferentielService,private layoutService: LayoutService,public entiteService: EntiteService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteExtraction() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.extractionService.deleteExtraction(this.liste.selectedSelector,this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Vérification du type de référentiel et de la suppression d'une extraction importée
				if (this.liste.selectedSelector == TypeReferentiel.LOCAL && this.data.extractionOrigine)
					//Rafraichissement du compteur des entités standard
					this.refreshReferentiel();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Duplication de l'extraction
	 */
	duplicateExtraction() {
		//Duplication de l'extraction
		this.extractionService.duplicateExtraction(this.data).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers l'extraction créée
				this.layoutService.goToByState('listeExtractions-extraction',{
					routeParams: {
						typeReferentiel: this.liste.selectedSelector,
						idExtraction: result.data.extraction.idExtraction
					},
					withGoBack: true
				});
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Exécution de l'extraction
	 */
	executeExtraction() {
		//Exécution de l'extraction
		this.extractionService.executeExtraction(this.data);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.extraction.Extraction',this.data.idExtraction);
	}
}