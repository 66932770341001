import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UPDATE_USER } from 'src/app/reducers/session';
import { Action } from 'src/app/domain/action';
import { DashboardService } from './dashboard.service';
import { CalendarService } from 'src/app/share/components/calendar/calendar.service';

@Injectable()
export class DashboardEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private dashboardService: DashboardService,private calendarService: CalendarService) {

	}

	/**
	 * Suppression du cache
	 */
	resetCache$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(UPDATE_USER),
		map(action => {
			//Suppression du cache
			this.dashboardService.evictCache();
			this.calendarService.evictCache();

			//Retour de l'action
			return action;
		})
	),{ dispatch: false });
}