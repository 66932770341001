<form #motifSelectionForm="ngForm" autocomplete="off">
	<div class="modal-header">
		<h4 class="modal-title" translate>workflow.motif.selection.titleRejet</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': motif.invalid }">
			<label translate>workflow.motif.selection.motif</label>
			<div>
				<autocomplete name="motif" type="workflowMotif" #motif="ngModel" [(ngModel)]="data.motif" [filter]="{ entity: entite }" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': description.invalid }">
			<label translate>workflow.motif.selection.description</label>
			<div>
				<textarea name="description" class="form-control" #description="ngModel" [(ngModel)]="data.description" rows="3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="close()" [disabled]="motifSelectionForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>