import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Action } from 'src/app/domain/vehicule/demande-vehicule/demande-vehicule';

@Component({
	templateUrl: './demande-vehicule-financement-selection.component.html'
})
export class DemandeVehiculeFinancementSelectionComponent {
	/** Liste des actions **/
	@Input() listeActions: Array<Action>;

	/** Titre de la pop-up **/
	@Input() title: string;

	/** Résultat **/
	result: { action?: Action } = {};

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DemandeVehiculeFinancementSelectionComponent>) { }

	/**
	 * Fermeture
	 */
	public close(action: Action) {
		//Définition du résultat
		this.result.action = action;

		//Fermeture
		this.bsModalRef.hide();
	}
}