import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxService } from './etat-lieux.service';

@Component({
	selector: 'etat-lieux',
	templateUrl: './etat-lieux.component.html'
})
export class EtatLieuxComponent implements OnInit,IEntity {
	/** Elément courant **/
	public etatLieux: any;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private activatedRoute: ActivatedRoute,private etatLieuxService: EtatLieuxService,private layoutService: LayoutService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.etatLieux.EtatLieux';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.etatLieux?.idEtatLieux || 0;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de l'identifiant de l'état des lieux à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idEtatLieux);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	loadData(idEtatLieux: number) {
		//Chargement de l'état des lieux
		this.etatLieuxService.loadEtatLieux(idEtatLieux).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'état des lieux
					this.etatLieux = result.data?.etatLieux;

					//Vérification de l'identifiant de l'état des lieux
					if (!this.etatLieux?.idEtatLieux) {
						//Création d'un objet vierge
						this.etatLieux = {}
					}
				}
			}
		});
	}

	/**
	 * Fermeture du formulaire
	 */
	close() {
		//Retour en arrière
		this.layoutService.goBack();
	}

	/**
	 * Fermeture du composant
	 */
	onContentClose(etatLieux: any) {
		//Vérification de l'état des lieux
		if (etatLieux)
			//Rechargement de l'URL
			this.layoutService.replaceUrlWith(etatLieux);
		else
			//Retour en arrière
			this.layoutService.goBack();
	}
}