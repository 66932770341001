<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idAutorisation">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.user.nom?.substring?.(0,1)?.toUpperCase() + data.user.prenom?.substring?.(0,1)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ (data.tenant | display:'tenant') + ' - ' + (data.user | display:'user') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAutorisation">
		<li *ngIf="data.dateDebut"><span translate>tenant.autorisation.dateDebut.item</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>tenant.autorisation.dateFin.item</span>&#160;:&#160;<span>{{ data.dateFin | date:'shortDate' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idAutorisation && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown">
			<icon name="more_vert"></icon>
		</a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_TENANT_AUTORISATION | right:'suppression'"><a [routerLink]="[]" (click)="deleteAutorisation()"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<tenant-autorisation [autorisation]="data" (onObjectChange)="onObjectChange($event)"></tenant-autorisation>
</div>