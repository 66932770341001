import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { WorkflowService } from './workflow.service';

@Component({
	selector: 'workflow-history',
	templateUrl: './workflow-history.component.html'
})
export class WorkflowHistoryComponent implements AfterContentChecked,OnInit {
	/** Entité **/
	@Input() entite: string;

	/** Identifiant de l'objet **/
	@Input() idObject: number

	/** Historique des actions Workflow **/
	listeWorkflowHistory: Array<any>;

	/** Liste des responsables */
	listeResponsables: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<WorkflowHistoryComponent>,private workflowService: WorkflowService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement de l'historique du Workflow
		this.workflowService.loadWorkflowHistory(this.entite,this.idObject).subscribe({
			next: (result: Result) => {
				//Vérification de la présence d'une erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'historique
					this.listeWorkflowHistory = result.data.listeWorkflowHistory;

					//Définition des responsables
					this.listeResponsables = result.data.listeResponsables;
				}
			}
		})
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de l'historique
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}