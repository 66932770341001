<div class="modal-header">
	<h4 class="modal-title"><span>{{ progress.libelle }}</span></h4>
</div>
<div class="modal-body progress-modal">
	<h4 translate>progress.listeErreurs.description</h4>
	<div class="log">
		<ul>
			<li *ngFor="let erreur of listeErreurs">{{ erreur }}</li>
		</ul>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>