import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReleveCompteurService {
	/** Liste des types de source **/
	private readonly listeTypesSource: Array<string> = ['COLLABORATEUR','FOURNISSEUR'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private confirmService: ConfirmService,private messagingService: MessagingService) {}

	/**
	 * Récupération de la liste des types de source
	 */
	public getListeTypesSource(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de source
		return this.listeTypesSource.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.releveCompteur.typeSource.${code}`)
		}));
	}

	/**
	 * Chargement d'un relevé de compteur
	 */
	 public loadReleveCompteur(idCompteur: number): Observable<Result> {
		//Chargement du relevé de compteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/loadCompteur/${idCompteur}`,null);
	}

	/**
	 * Enregistrement d'un relevé de compteur
	 */
	public saveReleveCompteur(releveCompteur: any): Observable<Result> {
		//Enregistrement du relevé de compteur
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/saveCompteur`,releveCompteur);
	}

	/**
	 * Suppression d'un relevé de compteur
	 */
	public deleteReleveCompteur(releveCompteur: any): Observable<Result> {
		//Suppression du relevé de compteur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/deleteCompteur/${releveCompteur.idCompteur}`);
	}

	/**
	 * Récupération de l'identifiant de la facture liée au compteur
	 */
	public findIdFactureForCompteur(releveCompteur: any): Observable<Result> {
		//Récupération de l'identifiant de la facture liée au compteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/findIdFactureForCompteur/${releveCompteur.idCompteur}`,null);
	}

	/**
	 * Récupération de l'identifiant de l'état des lieux lié au compteur
	 */
	public findIdEtatLieuxForCompteur(releveCompteur: any): Observable<Result> {
		//Récupération de l'identifiant de l'état des lieux lié au compteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/findIdEtatLieuxForCompteur/${releveCompteur.idCompteur}`,null);
	}

	/**
	 * Chargement d'un véhicule et de son dernier compteur associé
	 */
	public loadVehiculeAndLastCompteur(idVehicule: number): Observable<Result> {
		//Chargement du véhicule et de son dernier compteur associé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCompteur/loadVehiculeAndLastCompteur/${idVehicule}`,null);
	}

	/**
	 * Suppressions des éléments selectionnés
	 */
	public deleteAllForSelection(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmationMasse')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed)
					//Lancement en masse
					this.doDeleteAllForSelection(messagingOptions,subject);
			}
		});

		return subject;
	}

	/**
	 * Déclenchement de la suppression en masse
	 */
	private doDeleteAllForSelection(messagingOptions: MessagingOptions,subject: Subject<any>) {
		let messaging$: MessagingObservables;

		//Démarrage de la suppression par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}
}