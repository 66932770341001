import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ChartListItemComponent } from './chart-list-item.component';
import { ChartService } from './chart.service';

@Component({
	selector: 'chart-list',
	templateUrl: './chart-list.component.html'
})
export class ChartListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ChartListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private extractionService: ExtractionService,private chartService: ChartService) { }

	/**
	 * Initialisation
	 */
	async ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ChartListItemComponent>({
			uri: `/controller/Chart/filtreCharts`,
			title: this.translateService.instant('chart.liste.title'),
			component: ChartListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'firstType',
				type: TypeFilter.LONG,
				title: this.translateService.instant('chart.typeGraphique.item'),
				listeValues: [{
					code: 'KPI',
					libelle: 'chart.typeGraphique.KPI'
				},{
					code: 'LINE',
					libelle: 'chart.typeGraphique.LINE'
				},{
					code: 'AREA',
					libelle: 'chart.typeGraphique.AREA'
				},{
					code: 'BAR',
					libelle: 'chart.typeGraphique.BAR'
				},{
					code: 'PIE',
					libelle: 'chart.typeGraphique.PIE'
				},{
					code: 'DONUT',
					libelle: 'chart.typeGraphique.DONUT'
				}]
			},{
				clef: 'typeDiffusion',
				type: TypeFilter.LONG,
				title: this.translateService.instant('chart.typeDiffusion.item'),
				listeValues: this.chartService.getListeTypesDiffusion()
			},{
				clef: 'extraction.entity',
				type: TypeFilter.STRING,
				title: this.translateService.instant('chart.entite'),
				listeValues: this.extractionService.getListeEntites()
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Chart/loadChart/0'))
		});
	}
}
