import { NgModule } from '@angular/core';

import { SuccessiveSearchComponent } from './successive-search.component';
import { SuccessiveSearchService } from './successive-search.service';
import { CoreComponentsModule } from '../core-components.module';
import { ListViewModule } from '../list-view/list-view.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,IconModule,PleaseWaitModule,FilterModule],
	declarations: [SuccessiveSearchComponent],
	providers: [SuccessiveSearchService]
})
export class SuccessiveSearchModule {}