<button *ngIf="options.closeButton" (click)="remove()" type="button" class="toast-close-button">
	<span>&times;</span>
</button>
<div *ngIf="title" [ngClass]="options.titleClass">
	{{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div *ngIf="message && options.enableHtml" [ngClass]="options.messageClass" [innerHTML]="message"></div>
<div *ngIf="message && !options.enableHtml" [ngClass]="options.messageClass">
	{{ message }}
</div>
<div *ngIf="options.payload && options.payload.buttons" class="p-t-10 p-b-10 col-3">
	<button *ngFor="let button of options.payload.buttons" mat-raised-button color="primary" (click)="doAction(button)">
		<span [translate]="button.key"></span>
	</button>
</div>
<div *ngIf="options.progressBar">
	<div class="toast-progress" [style.width]="width + '%'"></div>
</div>