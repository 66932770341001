import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PageTitleService {
	/** Sujet de modification des sélecteurs **/
	public selectorsSubject: Subject<Array<{ libelle: string,doAction: Function }>> = new Subject();

	/** Sujet de modification des actions **/
	public actionsSubject: Subject<Array<{ libelle: string,icon: string,iconType?: string,doAction: Function }>> = new Subject();

	/**
	 * Définition de la liste des sélecteurs
	 */
	public setListeSelectors(listeSelectors: Array<{ libelle: string,doAction: Function }>) {
		//Envoi de la liste des sélecteurs
		this.selectorsSubject.next(listeSelectors);
	}

	/**
	 * Remise à zéro de la liste des sélecteurs
	 */
	public resetListeSelectors() {
		//Remise à zéro de la liste des sélecteurs
		this.selectorsSubject.next(null);
	}

	/**
	 * Définition de la liste des actions
	 */
	public setListeActions(listeActions: Array<{ libelle: string,icon: string,iconType?: string,doAction: Function }>) {
		//Envoi de la liste des actions
		this.actionsSubject.next(listeActions);
	}

	/**
	 * Remise à zéro de la liste des actions
	 */
	public resetListeActions() {
		//Remise à zéro de la liste des actions
		this.actionsSubject.next(null);
	}
}