import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ContratFinancementLoiRoulageListItemComponent } from './contrat-financement-loi-roulage-list-item.component';

@Component({
	templateUrl: './contrat-financement-loi-roulage-list.component.html'
})
export class ContratFinancementLoiRoulageListComponent extends PageContentItem implements OnInit {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Résumé **/
	@Input() resume: { nbLoisRoulage: number,nbEcheances: number };

	/** Utilisateur connecté **/
	@Input() user: any;

	/** Ajout/modification d'une loi de roulage **/
	@Input() addLoiRoulage: Function;

	/** Données **/
	liste: ListView<any,ContratFinancementLoiRoulageListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContratFinancementLoiRoulageListItemComponent>({
			uri: () => `/controller/Vehicule/filtreLoisRoulage/${this.contratFinancement.idContrat}`,
			component: ContratFinancementLoiRoulageListItemComponent,
			isLocal: true,
			extraOptions: {
				contratFinancement: this.contratFinancement,
				addLoiRoulage: this.addLoiRoulage,
				user: this.user
			},
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'loyerMensuel',
				isDefault: true
			},{
				clef: 'distance',
				isDefault: true
			}],
			defaultOrder: '-date'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idLoiRoulage > 0) {
			//Mise à jour des compteurs
			this.resume.nbLoisRoulage -= 1;

			//Mise à jour des compteurs
			this.resume.nbEcheances = this.contratFinancement?.listeEcheances.length || 0;
		}
	}
}