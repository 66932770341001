<form #accountForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>account.title</h4>
	</div>
	<div class="modal-body p-0 m-0">
		<div class="container-fluid p-0">
			<stepper #stepper="stepper" vertical="true" [listeSteps]="listeSteps" [selectedType]="type"></stepper>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="stepper.selectedStep?.type === 'PERSONAL_DATA'" (click)="stepper?.getComponentInstance()?.extractPersonalData()">
			<span translate>account.personalData.extractData</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="stepper.selectedStep?.type === 'CONTACT_ADMIN' && stepper?.getComponentInstance()?.canShowSendMail()" [disabled]="!stepper?.getComponentInstance()?.canSendMail()" (click)="stepper?.getComponentInstance()?.sendMail()">
			<span translate>actions.envoyer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="stepper.selectedStep?.type === 'PASSWORD'" [disabled]="!stepper?.getComponentInstance()?.canChangePassword()" (click)="stepper?.getComponentInstance()?.doChangePassword()">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>