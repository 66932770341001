<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.libelle.substring(0,3).toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Fournisseur/loadNature',data.idNature]">{{ data.libelle + ' (' + data.idNature + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>nature.liste.reconciliation</span>&#160;:&#160;<span>{{ (extraOptions.listeTypesLien | filterBy:{ code: data.typeReconciliation })[0].libelle }}</span></li>
		<li *ngIf="data.listeMetiers?.length > 0" [tooltip]="getListeMetiers()"><span translate>nature.liste.metiersAssocies</span>&#160;:&#160;<span>{{ data.listeMetiers.length }}</span></li>
		<li><span translate>nature.liste.famille</span>&#160;:&#160;<span>{{ (extraOptions.listeTypesFamille | filterBy:{ code: data.typeFamille })[0].libelle }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="rightService.isRoot() && (null | right:'suppression')"><a [routerLink]="[]" (click)="deleteNature();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.fournisseur.FournisseurNature',data.idNature)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>