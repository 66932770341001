import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TypeCarrosserieListItemComponent } from './type-carrosserie-list-item.component';
import { TypeCarrosserieListComponent } from './type-carrosserie-list.component';
import { TypeCarrosserieComponent } from './type-carrosserie.component';
import { TypeCarrosserieService } from './type-carrosserie.service';

@NgModule({
  imports: [ShareModule],
  declarations: [TypeCarrosserieListComponent,TypeCarrosserieListItemComponent,TypeCarrosserieComponent],
	providers: [TypeCarrosserieService]
})
export class TypeCarrosserieModule { }
