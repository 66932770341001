import { Action } from 'src/app/domain/action';
import { ISelector } from 'src/app/domain/layout/selector';
import { ExtendedRouteData } from 'src/app/domain/common/route/extended-route';

/**
 * Export des actions
 */
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_LISTE_SELECTORS = 'UPDATE_LISTE_SELECTORS';
export const UPDATE_SELECTED_SELECTOR = 'UPDATE_SELECTED_SELECTOR';
export const UPDATE_FIL_ARIANE = 'UPDATE_FIL_ARIANE';
export const UPDATE_ACTIVATED_ROUTE_DATA = 'UPDATE_ACTIVATED_ROUTE_DATA';

/**
 * Interface
 */
export interface LayoutState {
	/** Données **/
	title?: string,
	listeSelectors?: ISelector[],
	selectedSelector?: string,
	filAriane?: string,
	activatedRouteData?: ExtendedRouteData
}

/**
 * Création du reducer
 */
export function layoutReducer(state: LayoutState = {},action: Action): LayoutState {
	//Vérification de l'action
	switch (action.type) {
	case UPDATE_TITLE:
		//Titre de la page
		state = {
			...state,
			title: action.payload
		};
		break;
	case UPDATE_LISTE_SELECTORS:
		//Définition des sélecteurs
		state = {
			...state,
			listeSelectors: action.payload
		};
		break;
	case UPDATE_SELECTED_SELECTOR:
		//Mise à jour de l'état
		state = {
			...state,
			selectedSelector: action.payload
		};
		break;
	case UPDATE_FIL_ARIANE:
		//Mise à jour de l'état
		state = {
			...state,
			filAriane: action.payload
		};
		break;
	case UPDATE_ACTIVATED_ROUTE_DATA:
		//Mise à jour de l'état
		state = {
			...state,
			activatedRouteData: action.payload
		};
		break;
	}

	//Retour de l'état
	return state;
}