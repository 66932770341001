import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TodoListItemComponent } from './todo-list-item.component';
import { TodoListItemEditComponent } from './todo-list-item-edit.component';
import { TodoListService } from './todo-list.service';

@Component({
	selector: 'todo-list',
	templateUrl: './todo-list.component.html'
})
export class TodoListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TodoListItemComponent>;

	/** Statut sélectionné **/
	selectedStatut: 'ALL' | 'TODO' | 'DONE' = 'ALL';

	/** Partage sélectionné **/
	selectedPartage: 'INDIVIDUEL' | 'PARTAGE' = null;

	/** Liste des statuts **/
	listeStatuts: Array<string> = ['ALL','TODO','DONE'];

	/** Liste des partages **/
	listePartages: Array<string> = ['INDIVIDUEL','PARTAGE']

	/**
	 * Constructeur
	 */
	constructor(public todoListService: TodoListService,private translateService: TranslateService,private pageContentService: PageContentService,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Binding des méthodes
		this.createItem = this.createItem.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Affichage du détail
		this.todoListService.isShowDetail = false;

		//Définition de la liste
		this.liste = new ListView<any,TodoListItemComponent>({
			uri: () => `/controller/TodoList/filtreItems${this.selectedStatut != 'ALL' && !this.todoListService.getSavedIdItem() ? '/' + this.selectedStatut : ''}`,
			placeholder: 'todoList.saisissezVotreTache',
			searchAction: (liste: ListView<any,TodoListItemComponent>,searchText: string) => this.createItem(liste,searchText),
			component: TodoListItemComponent,
			isLocal: true,
			extraBodyClass: 'body',
			listeStaticFilters: () => [this.selectedPartage && !this.todoListService.getSavedIdItem() && {
				clef: 'isPartage',
				valeur: this.selectedPartage == 'PARTAGE' ? 'true' : 'false',
				type: TypeFilter.BOOLEAN,
				typeComparaison: TypeComparaison.EQUAL
			},this.todoListService.getSavedIdItem() && {
				clef: 'idItem',
				valeur: this.todoListService.getSavedIdItem(),
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}].filter(f => !!f),
			onRefresh: (liste,result) => {
				//Vérification de la présence d'une tâche
				if (this.todoListService.getSavedIdItem() && result.content.length > 0) {
					//Sélection de la tâche
					this.showDetail(result.content[0]);

					//Suppression de l'élément à afficher
					this.todoListService.resetSavedIdItem();
				}
			}
		});
	}

	/**
	 * Affichage du détail de la tâche
	 */
	showDetail(item) {
		//Affichage du détail
		this.todoListService.isShowDetail = true;

		//Ouverture du composant d'édition
		this.pageContentService.open(TodoListItemEditComponent,{
			data: {
				item: item
			}
		},'todoList').subscribe({
			next: i => {
				//Suppression de l'élément
				this.todoListService.resetSavedIdItem();

				//Vérification des données
				if (i)
					//Mise à jour de la tâche
					item = i;

				//Fermeture du composant d'édition
				this.todoListService.isShowDetail = false;
			}
		});
	}

	/**
	 * Création d'une tâche
	 */
	public createItem(liste: ListView<any,TodoListItemComponent>,searchText: any): void {
		let data: any;

		//Création de l'élément
		data = {
			title: searchText
		}

		//Enregistrement de l'élément
		this.todoListService.saveItem(data).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de l'élément
					data = result.data.item;

					//Ajout de l'élément
					liste.addItem(data);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression des tâches
	 */
	deleteAll(statut: 'ALL' | 'TODO' | 'DONE') {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression des tâches
					this.todoListService.deleteAll(statut).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Rafraichissement de la liste
								this.liste.refresh();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}