import { NgModule } from '@angular/core';
import { ShareModule } from 'src/app/share/share.module';
import { PlanEntretienDetailEditComponent } from './plan-entretien-detail-edit.component';
import { PlanEntretienDetailListItemComponent } from './plan-entretien-detail-list-item.component';
import { PlanEntretienDetailListComponent } from './plan-entretien-detail-list.component';
import { PlanEntretienEditComponent } from './plan-entretien-edit.component';
import { PlanEntretienListItemComponent } from './plan-entretien-list-item.component';
import { PlanEntretienListComponent } from './plan-entretien-list.component';
import { PlanEntretienComponent } from './plan-entretien.component';
import { PlanEntretienService } from './plan-entretien.service';

@NgModule({
	imports: [ShareModule],
	declarations: [PlanEntretienListComponent,PlanEntretienListItemComponent,PlanEntretienComponent,PlanEntretienEditComponent,PlanEntretienDetailListComponent,PlanEntretienDetailListItemComponent,PlanEntretienDetailEditComponent],
	providers: [PlanEntretienService]
})
export class PlanEntretienModule { }
