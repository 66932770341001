import { Component,Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	selector: 'contrat-financement-edit',
	templateUrl: './contrat-financement-edit.component.html'
})
export class ContratFinancementEditComponent extends PageContentItem {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Suppression du contrat de financement **/
	@Input() deleteContratFinancement: Function;

	/** Récupération de la date de fin du contrat **/
	@Input() getDateFin: Function;

	/**
	 * Constructeur
	 */
	constructor(public vehiculeService: VehiculeService,private contratFinancementService: ContratFinancementService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Fermeture du componsant
	 */
	onContentClose(data) {
		//Fermeture de l'édition
		this.close(data);
	}
}