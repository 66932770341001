import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'vehicule-amenagement-edit',
	templateUrl: './vehicule-amenagement-edit.component.html'
})
export class VehiculeAmenagementEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() amenagement: any;

	/** Suppression d'un aménagement **/
	@Input() deleteAmenagement: (amenagement: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}