<form #entiteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>calendrier.entite.titre</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': entite.invalid }">
							<label translate>calendrier.entite.entite</label>
							<div>
								<selectpicker name="entite" #entite="ngModel" [(ngModel)]="detail.entite" required>
									<mat-option *ngFor="let entite of listeEntites" [value]="entite.code">{{ entite.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<span class="pull-right">
		<button mat-stroked-button color="primary" *ngIf="(null | right:'creation') && detail.idDetail" (click)="deleteDetail();">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="null | right:'creation'" (click)="saveDetail();" [disabled]="entiteForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button (click)="close()">
		<span translate>actions.fermer</span>
	</button>
</form>