import { AfterViewInit,Directive,ElementRef,HostListener } from '@angular/core';

@Directive({
	selector: '[scrollFixed]'
})
export class ScrollFixedDirective implements AfterViewInit {
	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef) {}

	/**
	 * Initialisation de la vue du composant
	 */
	ngAfterViewInit() {
		let parentElement: HTMLElement;

		//Récupération de l'élément parent
		parentElement = this.elementRef.nativeElement.parentElement as HTMLElement;

		//Vérification de la présence de l'élément
		if (parentElement) {
			//Ajout d'un intercepteur de scroll
			parentElement.addEventListener('scroll',(event: Event) => {
				//Déclenchement de l'interception du scroll horizontal
				this.onHorizontalScroll(event.target as HTMLElement);
			});
		}
	}

	/**
	 * Ecoute du scroll vertical
	 */
	@HostListener('window:scroll',['$event'])
	public onVerticalScroll(event,element: HTMLElement = this.elementRef.nativeElement) {
		//Définition de la position 'Top'
		element.style.top = `${scrollY >= 10 ? scrollY - 10 : 0}px`;
	}

	/**
	 * Ecoute du scroll horizontal
	 */
	onHorizontalScroll(parentElement: HTMLElement,element: HTMLElement = this.elementRef.nativeElement) {
		//Définition de la position 'Left'
		element.style.left = `${parentElement.scrollLeft >= 0 ? parentElement.scrollLeft : 0}px`;
	}
}
