<div class="container-fluid">
	<form #equipementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<div class="alert alert-danger" *ngIf="!periodeValidity?.isDateDebutValid || !periodeValidity?.isDateFinValid">
					<icon name="warning"></icon>
					<div>
						<strong translate>equipement.chevauchementPeriode.title</strong>
						<div *ngIf="!periodeValidity?.isDateDebutValid" translate>equipement.chevauchementPeriode.messageDebut</div>
						<div *ngIf="!periodeValidity?.isDateFinValid" translate>equipement.chevauchementPeriode.messageFin</div>
					</div>
				</div>
				<fieldset [disabled]="!(null | right:'creation')" [formValidator]="isPeriodeValid()">
					<h4 translate>equipement.description</h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>equipement.type.item</label>
								<div class="half">
									<selectpicker name="typeField" #typeField="ngModel" [(ngModel)]="equipement.type" required>
										<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div *ngIf="equipement.type">
								<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
									<label translate>equipement.fournisseur</label>
									<div class="half">
										<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="equipement.fournisseur" required></autocomplete>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
									<label translate>equipement.reference</label>
									<div>
										<div class="input-group">
											<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="equipement.reference" (ngModelChange)="equipement.referenceUpdated = true" maxlength="100" required [minlength]="['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type) ? 9 : 1" [pattern]="equipement?.referenceUpdated || !equipement?.idEquipement ? '[a-zA-Z0-9]*' : '.*'"/>
											<div class="input-group-addon">
												<icon name="info_outline" [tooltip]="(['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type) ? 'equipement.referenceInfoCarteEquipement' : 'equipement.referenceInfo') | translate"></icon>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }" *ngIf="['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type)">
									<label translate>equipement.numero.item</label>
									<div class="input-group">
										<input type="text" name="numero" #numero="ngModel" class="form-control" [(ngModel)]="equipement.numero" maxlength="6" [required]="equipement?.fournisseur?.fournisseurOrigine?.equipementNumeroObligatoire" pattern="[a-zA-Z0-9]*"/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'equipement.numero.info' | translate"></icon>
										</div>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': statutField.invalid }">
									<label translate>equipement.statut.item</label>
									<div class="half">
										<selectpicker name="statutField" #statutField="ngModel" [(ngModel)]="equipement.statut" required>
											<mat-option *ngFor="let statut of listeStatuts" [value]="statut.code">{{ statut.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
									<label translate>equipement.dateDebut.item</label>
									<div class="half">
										<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="equipement.dateDebut" [maxDate]="equipement.dateFin" datePicker format="date" required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
									<label translate>equipement.dateFin.item</label>
									<div class="half">
										<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="equipement.dateFin" [minDate]="equipement.dateDebut" datePicker format="date" required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': codeConfidentielField.invalid }" *ngIf="['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type)">
									<label translate>equipement.codeConfidentiel</label>
									<div class="input-group">
										<input [type]="!codeConfidentiel?.isShowCode ? 'password' : 'text'" name="codeConfidentiel" #codeConfidentielField="ngModel" class="form-control" [(ngModel)]="equipement.codeConfidentiel" maxlength="100"/>
										<div class="input-group-addon">
											<icon name="visibility" class="clickable" (click)="codeConfidentiel.isShowCode = !codeConfidentiel?.isShowCode"></icon>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" *ngIf="equipement.type">
						<label translate>equipement.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="equipement.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="card full">
			<data-customization entite="com.notilus.data.equipement.Equipement" [object]="equipement" [disabled]="!(null | right:'creation')"></data-customization>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteEquipement();" *ngIf="equipement?.idEquipement && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveEquipement();" *ngIf="null | right:'creation'" [disabled]="equipementForm.invalid">
				<span [translate]="'actions.' + (equipement?.idEquipement ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="equipement?.idEquipement" [formDetectChanges]="{ doAction: close,isDirty: equipementForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>