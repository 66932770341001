import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { GroupeService } from './groupe.service';

@Component({
	selector: 'groupe-rule',
	templateUrl: './groupe-rule.component.html'
})
export class GroupeRuleComponent extends PageContentItem implements OnInit {
	/** Groupe **/
	getGroupe: () => any;

	/** Récupération de la règle **/
	getRule: () => any;

	/** Mode 'Edition' */
	isEdition: boolean;

	/** Règle courante **/
	rule: any;

	/** Groupe courant **/
	groupe: any;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService,private groupeService: GroupeService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la règle
		this.rule = this.getRule?.();

		//Récupération du groupe
		this.groupe = this.getGroupe?.();
	}

	/**
	 * Enregistrement de la rule
	 */
	saveRule() {
		//Mise à jour de la règle
		this.rule = this.ruleService.updateRuleForSave(this.rule);

		//Mise à jour de la règle
		this.groupe = Object.assign(this.groupe,{ rule: this.rule.listeDetails?.length ? this.rule : null });

		//Enregistrement du groupe
		this.groupeService.saveGroupe(this.groupe).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Retour du groupe
					this.close(Object.assign(this.groupe,result.data.groupe));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}