import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ConnecteurListItemComponent } from './connecteur-list-item.component';
import { ConnecteurListComponent } from './connecteur-list.component';
import { ImportModule } from './import/import.module';
import { ConnecteurTypeModule } from './type/connecteur-type.module';
import { ConnecteurComponent } from './connecteur.component';
import { ConnecteurService } from './connecteur.service';
import { ConnecteurEditComponent } from './connecteur-edit.component';
import { ConnecteurExecutionListComponent } from './connecteur-execution-list.component';
import { ConnecteurExecutionListItemComponent } from './connecteur-execution-list-item.component';
import { ConnecteurParamsComponent } from './connecteur-params.component';
import { ConnecteurDestinatairesComponent } from './connecteur-destinataires.component';
import { ConnecteurExecutionResultComponent } from './connecteur-execution-result.component';
import { ConnecteurPasswordsComponent } from './connecteur-passwords.component';
import { ConnecteurExecutionHistoryComponent } from './connecteur-execution-history.component';

@NgModule({
	imports: [ShareModule,ConnecteurTypeModule],
	exports: [ImportModule,ConnecteurTypeModule],
	declarations: [ConnecteurListComponent,ConnecteurListItemComponent,ConnecteurComponent,ConnecteurEditComponent,ConnecteurExecutionListComponent,ConnecteurExecutionListItemComponent,ConnecteurParamsComponent,ConnecteurDestinatairesComponent,ConnecteurExecutionResultComponent,ConnecteurPasswordsComponent,ConnecteurExecutionHistoryComponent],
	providers: [ConnecteurService]
})
export class ConnecteurModule { }