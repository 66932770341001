<fieldset [disabled]="!(TypeDroit.VEHICULE_AMENAGEMENT | right:'creation')">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': !amenagement.vehicule }" *ngIf="source != 'VEHICULE'">
				<label translate>amenagement.vehicule</label>
				<div>
					<ng-container *ngIf="!amenagement.vehicule">
						<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" #vehicule="ngModel" [(ngModel)]="amenagement.vehicule" required></autocomplete>
					</ng-container>
					<ng-container *ngIf="amenagement.vehicule">
						<mat-chip-listbox>
							<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="goToVehicule(amenagement.vehicule)">
								{{ amenagement.vehicule.reference + (amenagement.vehicule.numeroInterne ? ' - ' + amenagement.vehicule.numeroInterne : '') + ' (' + (amenagement.vehicule.modele ? amenagement.vehicule.modele.marque.libelle + ' ' + amenagement.vehicule.modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}
								<button matChipRemove (click)="amenagement.vehicule = null" *ngIf="TypeDroit.VEHICULE_AMENAGEMENT | right:'creation'">
									<mat-icon>cancel</mat-icon>
								</button>
							</mat-chip>
						</mat-chip-listbox>
					</ng-container>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': typeAmenagement.invalid }">
				<label translate>amenagement.typeAmenagement</label>
				<div>
					<autocomplete name="typeAmenagement" #typeAmenagement="ngModel" type="typeAmenagement" [(ngModel)]="amenagement.typeAmenagement" required></autocomplete>
				</div>
			</div>
			<div *ngIf="amenagement.typeAmenagement">
				<div *ngIf="hasTypeAmenagement(amenagement.typeAmenagement,'LIBELLE')">
					<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
						<label>
							<span translate>amenagement.libelle.item</span>
							<icon name="info_outline" class="m-l-5" [tooltip]="'amenagement.libelle.description' | translate"></icon>
						</label>
						<div>
							<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="amenagement.libelle" maxlength="300"/>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }" *ngIf="hasTypeAmenagement(amenagement.typeAmenagement,'FOURNISSEUR')">
					<label>
						<span translate>amenagement.fournisseur.item</span>
						<icon name="info_outline" class="m-l-5" [tooltip]="'amenagement.fournisseur.description' | translate"></icon>
					</label>
					<div>
						<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="amenagement.fournisseur"></autocomplete>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }" *ngIf="hasTypeAmenagement(amenagement.typeAmenagement,'DATE_DEBUT')">
				<label>
					<span translate>amenagement.dateDebut.item</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'amenagement.dateDebut.description' | translate"></icon>
				</label>
				<div class="half">
					<div [ngClass]="{ 'input-group': dateDebut.invalid }">
						<input type="text" name="dateDebut" #dateDebut="ngModel" class="form-control" [(ngModel)]="amenagement.dateDebut" datePicker [maxDate]="amenagement.dateFin"/>
						<div class="input-group-addon" *ngIf="dateDebut.invalid">
							<icon name="info_outline" [tooltip]="'amenagement.dateDebut.alerte' | translate"></icon>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }" *ngIf="hasTypeAmenagement(amenagement.typeAmenagement,'DATE_FIN')">
				<label>
					<span translate>amenagement.dateFin.item</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'amenagement.dateFin.description' | translate"></icon>
				</label>
				<div class="half">
					<div [ngClass]="{ 'input-group': dateFin.invalid }">
						<input type="text" name="dateFin" #dateFin="ngModel" class="form-control" [(ngModel)]="amenagement.dateFin" datePicker [minDate]="amenagement.dateDebut"/>
						<div class="input-group-addon" *ngIf="dateFin.invalid">
							<icon name="info_outline" [tooltip]="'amenagement.dateFin.alerte' | translate"></icon>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label translate>amenagement.piecesJointes</label>
				<div>
					<div [attachment]="amenagement" [typeAttachment]="TypeAttachment.AMENAGEMENT"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group">
		<label translate>amenagement.remarque</label>
		<div>
			<textarea name="remarque" class="form-control" [(ngModel)]="amenagement.remarque" rows="3"></textarea>
		</div>
	</div>
</fieldset>