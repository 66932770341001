<div class="modal-header">
	<h4 class="modal-title"><span translate>chart.preview.title</span>&nbsp;:&nbsp;{{ dashboardChart.chart.libelle + ' ('+ dashboardChart.chart.reference + ')' }}</h4>
</div>
<div class="modal-body">
	<div style="height: 70vh; width: 100%;">
		<gridster [options]="{}">
			<gridster-item [item]="dashboardChart">
				<div class="card detail">
					<div class="card-body">
						<dashboard-chart [dashboardChart]="dashboardChart" [hasNoActions]="true"></dashboard-chart>
					</div>
				</div>
			</gridster-item>
		</gridster>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="close()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="canAddChart" (click)="addChart()">
		<span translate>actions.ajouter</span>
	</button>
</div>