<div class="pull-left lv-avatar">{{ extraOptions.displayAvatar(data) }}</div>
<div class="lv-title">
	<a [routerLink]="" class="clickable" (click)="extraOptions.close(data)">
		<span>{{ extraOptions.displayItem(data,extraOptions.translateService,extraOptions.datePipe,extraOptions.decimalPipe) }}</span>
	</a>
</div>
<ul class="lv-attrs">
	<li><span translate>fournisseur.contact.actif.item</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	<li *ngIf="data.telephone"><span translate>fournisseur.contact.telephone</span>&#160;:&#160;<span class="fi" [ngClass]="'fi-'+(data.telephone.codePays.toLowerCase() != 'ja' ? data.telephone.codePays.toLowerCase() : 'jp')"></span>&#160;(<icon name="add"></icon>{{ data.telephone.indicatif }})&#160;{{ data.telephone.numero }}</li>
	<li *ngIf="data.telephone2"><span translate>fournisseur.contact.telephone2</span>&#160;:&#160;<span class="fi" [ngClass]="'fi-'+(data.telephone2.codePays.toLowerCase() != 'ja' ? data.telephone2.codePays.toLowerCase() : 'jp')"></span>&#160;(<icon name="add"></icon>{{ data.telephone2.indicatif }})&#160;{{ data.telephone2.numero }}</li>
	<li><span translate>fournisseur.contact.email</span>&#160;:&#160;<span>{{ data.email }}</span></li>
	<li *ngIf="data.fonction && data.fonction != ''"><span translate>fournisseur.contact.fonction</span>&#160;:&#160;<span>{{ data.fonction }}</span></li>
</ul>