
<div class="pull-left lv-avatar">{{ (translateEntityCode(data.entite.split('.').pop(-1))).substring(0,3).toLowerCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/DataCustomization/loadDataCustomization/',data.idDataCustomizationEntite]"><span>{{ translateEntityCode(data.entite.split('.').pop(-1)) }}</span></a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>dataCustomization.liste.referentiel</span>&#160;:&#160;<span translate [translateParams]="{ nbPersonnalisations: getNbLabelsForType('customRef') }">dataCustomization.liste.personnalisation</span></li>
		<li><span translate>dataCustomization.liste.alphanumerique</span>&#160;:&#160;<span translate [translateParams]="{ nbPersonnalisations: getNbLabelsForType('alpha') }">dataCustomization.liste.personnalisation</span></li>
		<li><span translate>dataCustomization.liste.numerique</span>&#160;:&#160;<span translate [translateParams]="{ nbPersonnalisations: getNbLabelsForType('numeric') }">dataCustomization.liste.personnalisation</span></li>
		<li><span translate>dataCustomization.liste.date</span>&#160;:&#160;<span translate [translateParams]="{ nbPersonnalisations: getNbLabelsForType('date') }">dataCustomization.liste.personnalisation</span></li>
	</ul>
</div>