import { Component,Input,OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	templateUrl: './article-rubrique-list.component.html'
})
export class ArticleRubriqueListComponent implements OnInit {
	/** Article **/
	@Input() article: any;

	/** Résumé **/
	@Input() resume: { nbRubriques: number };

	/** Modification des rubriques **/
	@Input() editListeRubriques: (isAddNewRubrique?: boolean) => void;

	/**
	 * Constructeur
	 */
	constructor(private domSanitizer: DomSanitizer) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Parcours des rubriques
		this.article.listeContents.forEach(c => {
			//Récupération du contenu brut
			c.safeContent = this.domSanitizer.bypassSecurityTrustHtml(c.content);
		});
	}
}