import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/share/login/login.service';

@Injectable()
export class AccessForbiddenService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private loginService: LoginService) {}

	/**
	 * Vérification des droits de l'utilisateur
	 */
	public checkAccess() {
		//Chargement de l'utilisateur
		this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/getUser`,null).pipe(first()).subscribe({
			next: result => {
				let user: User;
				let isProfilActif: boolean;

				//Récupération de l'utilisateur
				user = result.data?.user;

				//Récupération de l'indicateur de profil actif
				isProfilActif = user.listeUserProfil?.[0]?.profil?.actif || false;

				//Vérification des droits de l'utilisateur
				if (!user || !user.idUser || !user.enabled || user.type == 'UTILISATEUR' && !isProfilActif)
					//Déconnexion de l'utilisateur
					this.loginService.logout(false,true);
			}
		});
	}
}