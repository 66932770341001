<div class="container" *ngIf="etatLieux">
	<form #etatLieuxForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<etat-lieux-content #etatLieuxContent [etatLieux]="etatLieux" source="ETAT_LIEUX" (close)="onContentClose($event)" (refreshEtatLieux)="loadData(etatLieux.idEtatLieux)"></etat-lieux-content>
		<div class="card focus">
			<data-customization entite="com.notilus.data.vehicule.etatLieux.EtatLieux" [object]="etatLieux" [disabled]="!(null | right:'creation')"></data-customization>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="etatLieuxContent.deleteEtatLieux();" *ngIf="etatLieux?.idEtatLieux && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="etatLieuxContent.saveEtatLieux();" *ngIf="null | right:'creation'" [disabled]="etatLieuxForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button *ngIf="etatLieux?.idEtatLieux" [formDetectChanges]="{ doAction: close,isDirty: etatLieuxContent.isDirty() }">
			<span translate>actions.fermer</span>
		</button>
		<div class="clearfix"></div>
	</form>
</div>