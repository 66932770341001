import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DatabaseService } from './database.service';

@Component({
	selector: 'database',
	templateUrl: './database.component.html'
})
export class DatabaseComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() database: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Enumération des types d'attachment **/
	public TypeAttachment = TypeAttachment;

	/** Liste des types de base de données **/
	public listeTypesDatabase: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private databaseService: DatabaseService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
		this.confirmRemoveRevendeur = this.confirmRemoveRevendeur.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de la base de données
		this.database = cloneDeep(this.database);

		//Définition des listes
		this.listeTypesDatabase = this.databaseService.getListeTypesDatabase();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la base de données
	 */
	saveDatabase() {
		//Enregistrement de la base de données
		this.databaseService.saveDatabase(this.database).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de la base de données
					this.onObjectChange.emit(result.data.database);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du schema
					listeDoublons.push(this.translateService.instant('actions.doublon.schema'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression de la base de données
	 */
	deleteDatabase() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la base de données
					this.databaseService.deleteDatabase(this.database).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la base de données
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Vérification de la possibilité de supprimer le revendeur
	 */
	confirmRemoveRevendeur(revendeur: any): Observable<boolean> | boolean {
		//Verification de l'utilisation du revendeur
		return this.databaseService.checkDatabaseForRevendeur(this.database,revendeur).pipe(
			map((result: Result) => {
				//Définition du cache
				revendeur.isUsed = result?.data?.isUsed;

				//Vérification de l'utilisation du revendeur
				if (revendeur.isUsed)
					//Message d'erreur
					this.toastrService.warning(this.translateService.instant('database.warning.databaseUsed'))

				//Retour
				return !revendeur.isUsed;
			})
		);
	}

	/**
	 * Désactivation du bouton de suppression pour un revendeur
	 */
	isRevendeurDisabled: (revendeur: any,index: number) => number = (revendeur) => revendeur.isUsed;
}