<form #adresseForm="ngForm" *ngIf="adresse" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<fieldset [disabled]="!isEdition">
		<div class="modal-header">
			<h4 class="modal-title" translate>adresse.title</h4>
		</div>
		<div class="modal-body">
			<div *ngIf="searchMode != 'VILLE'">
				<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
					<label translate>adresse.numero</label>
					<div class="half">
						<input type="text" class="form-control" name="numero" #numero="ngModel" [(ngModel)]="adresse.numero"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': rue.invalid }">
					<label translate>adresse.rue</label>
					<div>
						<input type="text" class="form-control" name="rue" #rue="ngModel" [(ngModel)]="adresse.rue" maxlength="39" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': complement1.invalid }">
					<label translate>adresse.complement1</label>
					<div>
						<input type="text" class="form-control" name="complement1" #complement1="ngModel" [(ngModel)]="adresse.complement1" maxlength="39"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': complement2.invalid }">
					<label translate>adresse.complement2</label>
					<div>
						<input type="text" class="form-control" name="complement2" #complement2="ngModel" [(ngModel)]="adresse.complement2" maxlength="39"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': complement3.invalid }">
					<label translate>adresse.complement3</label>
					<div>
						<input type="text" class="form-control" name="complement3" #complement3="ngModel" [(ngModel)]="adresse.complement3" maxlength="39"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': codePostal.invalid }">
					<label translate>adresse.codePostal</label>
					<div class="half">
						<input type="text" class="form-control" name="codePostal" #codePostal="ngModel" [(ngModel)]="adresse.codePostal" pattern="[0-9]*" required maxlength="10"/>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': ville.invalid }">
				<label translate>adresse.ville</label>
				<div>
					<input type="text" class="form-control" name="ville" #ville="ngModel" [(ngModel)]="adresse.ville" maxlength="39" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }" *ngIf="adresse.typeSaisie == 'MANUEL'">
				<label translate>adresse.pays</label>
				<div>
					<autocomplete name="pays" #pays="ngModel" type="pays" [(ngModel)]="adresse.paysObject" (ngModelChange)="adresse.pays = adresse.paysObject?.libelle" required></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }" *ngIf="adresse.typeSaisie != 'MANUEL'">
				<label translate>adresse.pays</label>
				<div>
					<input type="text" class="form-control" name="pays" #pays="ngModel" [(ngModel)]="adresse.pays" maxlength="39" required/>
				</div>
			</div>
		</div>
	</fieldset>
	<div class="modal-footer">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="isEdition" (click)="saveItem();" [disabled]="adresseForm.invalid">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>