import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeFactureDetailListItemComponent } from './vehicule-facture-detail-list-item.component';

@Component({
	selector: 'vehicule-facture-detail-list',
	templateUrl: './vehicule-facture-detail-list.component.html'
})
export class VehiculeFactureDetailListComponent extends PageContentItem implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Résumé **/
	@Input() resume: { nbDetailsFacture: number };

	/** Données **/
	liste: ListView<any,VehiculeFactureDetailListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeFactureDetailListItemComponent>({
			uri: () => `/controller/Vehicule/filtreDetailsFacture/${this.vehicule.idVehicule}`,
			component: VehiculeFactureDetailListItemComponent,
			isLocal: true,
			extraOptions: {
				vehicule: this.vehicule
			},
			listeFilters: [{
				clef: 'facture',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'facture'
				},
				isDefault: true
			},{
				clef: 'nature',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurNature'
				},
				isDefault: true
			},{
				clef: 'facture.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-date,nature.libelle'
		});
	}
}