<ng-container *ngFor="let check of listeRegleAutomatiqueChecks">
	<div class="d-flex justify-content-space-between">
		<div>
			<label *ngIf="check.typeCheck == 'UNE_REGLE'">{{ check.libelleValeur }}</label>
			<label *ngIf="check.typeCheck == 'AUCUNE_REGLE'" translate>regleAutomatique.check.typeCheck.AUCUNE_REGLE</label>
			<label *ngIf="check.typeCheck == 'MULTIPLES_REGLES'" translate>regleAutomatique.check.typeCheck.MULTIPLES_REGLES</label>
		</div>
		<div>
			<a [routerLink]="[]" (click)="check.isShowDetail = !check.isShowDetail">
				<span [translateParams]="{ count: check.count }" translate>regleAutomatique.check.countElements</span>
				<icon class="p-l-10" [name]="!check.isShowDetail ? 'chevron_right' : 'expand_more'" *ngIf="check.listeObjets?.length"></icon>
			</a>
		</div>
	</div>
	<div [collapse]="!check.isShowDetail" [isAnimated]="true" *ngIf="check.listeObjets?.length">
		<div class="log m-b-10">
			<display-more [listeItems]="check.listeObjets" #displayMore>
				<div *ngFor="let objet of displayMore.getSlice()">
					<ng-container *ngFor="let item of objet | keyvalue">
						<span>{{ item.value + ' (' + item.key +')' }}</span>
					</ng-container>
				</div>
			</display-more>
		</div>
	</div>
</ng-container>