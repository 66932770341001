<form #compteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>comptabilite.compte.title</h2>
			</div>
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': compteCollectif.invalid }">
								<label translate>comptabilite.compte.compteOrigine</label>
								<div>
									<autocomplete name="compte" type="compte" #compteCollectif="ngModel" [(ngModel)]="compte.compteOrigine" [filter]="{ typeReferentiel: 'STANDARD',idPlanComptable: compte.planComptable.idPlanComptable,idPays: compte.planComptable.pays.idPays }" (onSelectFromCreation)="saveCompte()" required></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveCompte();" [disabled]="compteForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: compteForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>