import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { VehiculeReleveCompteurListItemComponent } from './vehicule-releve-compteur-list-item.component';

@Component({
	templateUrl: './vehicule-releve-compteur-list.component.html'
})
export class VehiculeReleveCompteurListComponent implements OnInit {
	/** Véhicule **/
	vehicule: any;

	/** Données **/
	liste: ListView<any,VehiculeReleveCompteurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<VehiculeReleveCompteurListItemComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeReleveCompteurListItemComponent>({
			uri: `${environment.baseUrl}/controller/VehiculeCompteur/filtreCompteurs`,
			component: VehiculeReleveCompteurListItemComponent,
			isLocal: true,
			defaultOrder: '-dateReleve',
			listeFilters: [{
				clef: '*userSource.nom,*userSource.prenom,*userSource.matricule,*fournisseur.reference,*fournisseur.libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule?.idVehicule,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			},{
				clef: 'isValeurReference',
				valeur: 'true',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.BOOLEAN
			}]
		});
	}
}