import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des demandes de cotation
 */
export default class DemandeCotationOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.demandeCotation';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeCommande/filtreDemandesCotation`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item && `${item.idDemandeCotation}` || ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.idDemandeCotation.toString().substring(0,3) || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.cotation.DemandeCotation';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idDemandeCotation'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'idDemandeCotation',
			isDefault: true
		}];
	}
}