<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.numeroLot.toString().substring(0,3) }}</div>
<div class="lv-title">
	<a [routerLink]="['/Comptabilite/loadLotComptable/',data.idLotComptable]">{{ data.libelle + ' (' + data.numeroLot + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>comptabilite.lotComptable.dateLot</span>&#160;:&#160;<span>{{ data.dateLot | date:'shortDate' }}</span></li>
	<li><span translate>comptabilite.lotComptable.montantTotalTTC</span>&#160;:&#160;<span>{{ data.montant | currency:'.2-2':data.devise }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'creation')"><a [routerLink]="[]" (click)="executeStructureExport();"><span translate>actions.executer</span></a></li>
		<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteLotComptable();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>