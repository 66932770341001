<div class="m-t-10">
	<div class="listview lv-bordered no-search">
		<display-more [listeItems]="listeUserProfil" #displayMore>
			<div class="lv-body">
				<div *ngFor="let lienProfil of displayMore.getSlice(); let index = index">
					<div class="lv-item media">
						<div class="pull-left lv-avatar">
							<icon *ngIf="lienProfil?.profil?.typeProfil == 'WEB'" name="desktop_mac"></icon>
							<icon *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'" name="smartphone"></icon>
						</div>
						<div class="media-body">
							<div class="lv-title">
								<a [routerLink]="[]" (click)="addProfil(lienProfil,deleteLienProfil,index)">
									<span>{{ lienProfil?.profil?.libelle }}</span>
									<span *ngIf="lienProfil?.profil?.typeProfil == 'WEB' && user?.login">&#160;({{ user.login }})</span>
									<span *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE' && userAccountMobile?.login">&#160;({{ userAccountMobile.login }})</span>
								</a>
							</div>
							<ul class="lv-attrs" *ngIf="lienProfil?.idLien">
								<li *ngIf="lienProfil?.profil?.typeProfil == 'WEB'"><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(user?.enabled ? 'oui' : 'non')"></span></li>
								<li *ngIf="lienProfil?.profil?.typeProfil == 'WEB' && user?.usage?.dateLastLogin"><span translate>user.dateLastLogin</span>&#160;:&#160;<span>{{ user?.usage?.dateLastLogin | date:'short' }}</span></li>
								<li *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'"><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(lienProfil?.actif ? 'oui' : 'non')"></span></li>
								<li *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE' && user?.usage?.dateLastLoginMobile"><span translate>user.dateLastLogin</span>&#160;:&#160;<span>{{ user?.usage?.dateLastLoginMobile | date:'short' }}</span></li>
							</ul>
							<div class="lv-actions actions dropdown" *ngIf="lienProfil?.idLien && (TypeDroit.ADMIN_PROFIL | right:'consultation')">
								<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
								<ul class="dropdown-menu dropdown-menu-right">
									<li *ngIf="lienProfil?.profil?.typeProfil == 'MOBILE'"><a [routerLink]="[]" (click)="toggleLienProfil(lienProfil);"><span [translate]="'actions.'+(lienProfil?.actif ? 'desactiver' : 'activer')"></span></a></li>
									<li *ngIf="lienProfil?.profil?.typeProfil == 'WEB' && (TypeDroit.ADMIN_PROFIL | right:'suppression')"><a [routerLink]="[]" (click)="deleteLienProfil(lienProfil,null,index);"><span translate>actions.supprimer</span></a></li>
									<li *ngIf="lienProfil?.profil?.typeProfil == 'WEB'"><a [routerLink]="[]" (click)="sendAccount(lienProfil?.user);"><span translate>user.actions.transmettreElementsConnexion</span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</display-more>
	</div>
</div>