<div class="pull-left lv-avatar">{{ data?.fournisseur.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addFournisseur?.(data,deleteLienFournisseur)">{{ data?.fournisseur | display:'fournisseur' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idLien">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.fournisseur?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data?.fournisseur?.listeMetiers?.length"><span translate>comptabilite.compteAuxiliaire.fournisseurMetier</span>&#160;:&#160;<span>{{ data?.fournisseur?.listeMetiers?.length }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idLien && ((null | right:'suppression') && !extraOptions.restriction.isRestricted || (TypeDroit.ADMIN_FOURNISSEUR | right:'consultation'))">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_FOURNISSEUR | right:'consultation'"><a [routerLink]="[]" (click)="goToFournisseur();"><span translate>comptabilite.compteAuxiliaire.actions.accederFournisseur</span></a></li>
			<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteLienFournisseur();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>