import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeInfractionService } from './type-infraction.service';

@Component({
	selector: 'type-infraction',
	templateUrl: './type-infraction.component.html'
})
export class TypeInfractionComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() typeInfraction: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private typeInfractionService: TypeInfractionService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du type d'infraction
		this.typeInfraction = Object.assign({},this.typeInfraction);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked(): void {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du type d'infraction
	 */
	saveTypeInfraction() {
		//Enregistrement du type d'infraction
		this.typeInfractionService.saveTypeInfraction(this.typeInfraction).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du type d'infraction
					this.onObjectChange.emit(result.data.typeInfraction);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Ajout de la référence
					listeDoublons.push(this.translateService.instant('actions.doublon.pays'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du type d'infraction
	 */
	deleteTypeInfraction() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du type d'infraction
					this.typeInfractionService.deleteTypeInfraction(this.typeInfraction).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du type d'infraction
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}