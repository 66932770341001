<form #fournisseurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>fournisseur.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>fournisseur.libelle</label>
			<div>
				<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="fournisseur.libelle" maxlength="300" autofocus required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>fournisseur.reference</label>
			<div>
				<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="fournisseur.reference" maxlength="100" required/>
			</div>
		</div>
		<div class="form-group">
			<label translate>fournisseur.natureLAD</label>
			<div>
				<autocomplete name="nature" type="fournisseurNature" #nature="ngModel" [(ngModel)]="fournisseur.natureLAD"></autocomplete>
			</div>
		</div>
		<h4 class="m-t-20" translate>fournisseur.listeMetiers.title</h4>
		<basket [model]="fournisseur" propertyName="listeMetiers" type="fournisseurMetier" [labelAutocomplete]="'fournisseur.listeMetiers.ajout' | translate" [labelListe]="'fournisseur.listeMetiers.liste' | translate" [displayItem]="getLibelleMetier" [hasError]="isListeMetiersInvalid" (onAdd)="onAddMetier()" (onRemove)="onRemoveMetier()"></basket>
		<div class="form-group" *ngIf="fournisseur.listeMetiers?.length">
			<label translate>fournisseur.listeNatures.title</label>
			<div>
				<a [routerLink]="[]" (click)="showListeNatures()">
					<icon class="icon-hc-2x" name="search"></icon>
					<span [translate]="'fournisseur.listeNatures' | pluralTranslate:nbNatures" [translateParams]="{ nbNatures }"></span>
				</a>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="fournisseurForm.invalid || isListeMetiersInvalid()">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>