import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class EndToEndTestRequestInterceptor implements HttpInterceptor {
	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification de l'interception des requêtes
		if ((window as any).isInterceptRequests) {
			//Laisser passer la requête
			return next.handle(request).pipe(
				tap(event => {
					//Vérification de l'objet
					if (event instanceof HttpResponse) {
						//Création de la liste des requêtes
						(window as any).httpRequests = (window as any).httpRequests || [];

						//Ajout de la réponse
						(window as any).httpRequests.push(event);
					}
				})
			);
		} else
			//Laisser passer la requête
			return next.handle(request);
	}
}