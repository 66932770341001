<form *ngIf="taux" #tauxForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>fiscalite.taxe.taux</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>fiscalite.taxe.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="taux.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': tauxField.invalid }">
							<label translate>fiscalite.taxe.taux</label>
							<div class="half">
								<input type="text" name="taux" #tauxField="ngModel" class="form-control" [(ngModel)]="taux.taux" required [nNumber]="2"/>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>fiscalite.taxe.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="taux.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteTaux();" *ngIf="taux?.idTaux && (null | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveTaux();" *ngIf="null | right:'creation'" [disabled]="tauxForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: tauxForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>