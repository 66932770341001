import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RollingPeriodSelectorService {
	/** Liste des temporals **/
	private listeTemporals: Array<string> = ['DATE_DAY','DATE_WEEK','DATE_MONTH','DATE_QUARTER','DATE_YEAR'];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private datePipe: DatePipe) {}

	/**
	 * Récupération de la liste des temporals
	 */
	public getListeTemporals(): Array<{ code: string,libelle: string }> {
		//Retour de la liste
		return this.listeTemporals.map(code => ({
			code,
			libelle: this.translateService.instant(`rule.datePeriod.${code}`)
		}));
	}

	/**
	 * Formatage de la donnée
	 */
	formatValue({ temporal,value }: { temporal: string,value: number }): string {
		let libelle: string;

		//Vérification de la date
		if (temporal) {
			//Définition du libellé
			libelle = '@' + this.translateService.instant(`rule.dateFunction.DATE_TODAY`);

			//Complétion du libellé
			libelle += ' ' + (value > 0 ? '+' : '') + value + ' ' + this.translateService.instant(`rule.datePeriod.${temporal}`);

			//Retour du libellé
			return libelle;
		} else
			//Sélection d'une valeur
			return this.translateService.instant('rule.selector.rollingPeriod.emptyValue');
	}
}