import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	templateUrl: './user-extraction-edit.component.html'
})
export class UserExtractionEditComponent extends PageContentItem {
	/** Utilisateur courant **/
	@Input() user: any;

	/** Ordonnancement courant **/
	@Input() userScheduler: any;

	/** Suppression de l'ordonnancement courant **/
	@Input() deleteUserScheduler: Function;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}