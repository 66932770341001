import { Component,EventEmitter,Input,Output } from '@angular/core';

import { AuthentificationConfigurationService } from './authentification-configuration.service';

@Component({
	selector: 'authentification-configuration-list-item',
	templateUrl: './authentification-configuration-list-item.component.html'
})
export class AuthentificationConfigurationListItemComponent {
	/** Configuration **/
	@Input() configuration: any;

	/** Identifiant du tenant **/
	@Input() idTenant: number;

	/** Etat **/
	@Input() disabled?: boolean;

	/** Suppression de l'objet */
	@Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

	/** Mise à jour de l'objet */
	@Output() onObjectChange: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private authentificationConfigurationService: AuthentificationConfigurationService) { }

	/**
	 * Affichage de la configuration
	 */
	showConfiguration() {
		//Ouverture de la pop-up de configuration
		this.authentificationConfigurationService.showConfiguration({
			configuration: this.configuration,
			idTenant: this.idTenant,
			disabled: this.disabled
		}).subscribe({
			next: (configuration: any) => {
				//Mise à jour de l'objet
				this.onObjectChange.emit(configuration);
			}
		});
	}
}