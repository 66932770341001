import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './template-mail-destinataire-list-item.component.html'
})
export class TemplateMailDestinataireListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private templateMailService: TemplateMailService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Suppression du destinataire
	 */
	deleteDestinataire() {
		//Suppression du destinataire
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.templateMailService.deleteDestinataire(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression depuis la liste
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}