import { Pipe,PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'bytes'
})
export class BytesPipe implements PipeTransform {
	/** Liste des unités **/
	private units: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(private decimalPipe: DecimalPipe,private translateService: TranslateService) {
		//Initialisation du tableau des unités
		this.units = ['octets','ko','mo','go'].map(unit => translateService.instant(`taille.${unit}`));
	}

	/**
	 * Transformation
	 */
	transform(szNbBytes: string,precision: number = 2) {
		let nbBytes;
		let numberUnit;
		let nombre;

		//Vérification du nombre d'octets
		if (isNaN(parseFloat(szNbBytes)) || !isFinite(nbBytes = Number(szNbBytes)))
			//Chaîne par défaut
			return '-';

		//Vérification de la taille
		if (nbBytes == 0)
			//Unité de base (octets)
			numberUnit = 0;
		else
			//Récupération du nombre d'unités dans le tableau
			numberUnit = Math.floor(Math.log(nbBytes) / Math.log(1024));

		//Récupération du nombre
		nombre = (nbBytes / Math.pow(1024,Math.floor(numberUnit))).toFixed(precision);

		//Formatage du nombre
		nombre = this.decimalPipe.transform(nombre,`1.0-${numberUnit == 0 ? 0 : precision}`);

		//Retour de la chaîne de caractères
		return `${nombre} ${this.units[numberUnit]}`;
	}
}