import { Component,OnInit } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { User } from 'src/app/domain/user/user';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	templateUrl: './vehicule-entretien-intervention-list-item.component.html'
})
export class VehiculeEntretienInterventionListItemComponent extends ListViewItem<any> implements OnInit {
	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private loggedUserService: LoggedUserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();
	}
}