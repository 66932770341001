<ul class="rule-detail" [ngClass]="{ 'z-depth-1': !isModeLight }">
	<li class="header">
		<div class="actions dropdown pull-right" *ngIf="isEdition">
			<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
			<ul class="dropdown-menu dropdown-menu-right">
				<li (click)="addItem(rule,'rule')"><a [routerLink]="[]" translate>rule.actions.ajouterRule</a></li>
				<li *ngIf="!isModeLight" (click)="addItem(rule,'groupe')"><a [routerLink]="[]" translate>rule.actions.ajouterGroupe</a></li>
				<li (click)="removeItem();"><a [routerLink]="[]" translate>actions.supprimer</a></li>
			</ul>
		</div>
		<mat-button-toggle-group [value]="rule.operateur" (change)="rule.operateur = $event.value">
			<mat-button-toggle value="AND">{{ 'common.et' | translate | uppercase }}</mat-button-toggle>
			<mat-button-toggle value="OR" *ngIf="!isModeLight">{{ 'common.ou' | translate | uppercase }}</mat-button-toggle>
		</mat-button-toggle-group>
	</li>
	<li class="content">
		<ul class="rule-list">
			<li *ngFor="let detail of rule.listeDetails; let idxDetail = index;">
				<rule-item [detail]="detail" [entite]="entite" [isModeLight]="isModeLight" [isEdition]="isEdition" (onDelete)="rule.listeDetails.splice(idxDetail,1)" *ngIf="!detail.rule"></rule-item>
				<rule-detail [rule]="detail.rule" [entite]="entite" [isModeLight]="isModeLight" [isEdition]="isEdition" (onDelete)="rule.listeDetails.splice(idxDetail,1)" *ngIf="detail.rule"></rule-detail>
			</li>
			<li *ngIf="!rule.listeDetails?.length && isEdition"><div class="rule-item hoverable"><a [routerLink]="[]" (click)="initRule()" translate>rule.actions.initRule</a></div></li>
			<li *ngIf="!rule.listeDetails?.length && !isEdition"><div class="rule-item hoverable" translate>liste.empty</div></li>
		</ul>
	</li>
</ul>