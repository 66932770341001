<div class="pull-left lv-avatar" [translate]="'vehicule.releveEnergie.liste.avatar.'+(data.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showReleveEnergie()">{{ data.vehicule | display:'vehicule' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>conducteur.releveEnergie.date</span>&#160;:&#160;{{ data.dateReleve | date:'short' }}</li>
		<li><span translate>conducteur.releveEnergie.volume</span>&#160;:&#160;{{ data.volume | number:'.2-2' }}&#160;{{ data.unite?.libelleCourt || '' }}</li>
		<li *ngIf="data.extension?.priseMultiple"><span translate>conducteur.releveEnergie.priseMultiple</span>&#160;:&#160;<span translate>common.oui</span></li>
		<li *ngIf="data.extension?.depassementReservoir"><span translate>conducteur.releveEnergie.depassementReservoir</span>&#160;:&#160;<span translate>common.oui</span></li>
	</ul>
</div>