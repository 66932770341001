<form autocomplete="off">
	<div class="card detail">
		<div class="card-body">
			<h4><span translate>structureExport.listeRegroupements.titre</span></h4>

			<div class="listview lv-bordered">
				<div class="lv-body">
					<bs-sortable name="listeRegroupements" [(ngModel)]="detail.listeRegroupements" [itemTemplate]="itemTemplate" itemActiveClass="invisible"></bs-sortable>

					<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="selectRegroupement();" *ngIf="(null | right:'creation') && !isRestricted">
						<h5><a [routerLink]="[]" translate>structureExport.listeRegroupements.ajouterRegroupement</a></h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<ng-template #itemTemplate let-field="item" let-index="index">
	<div class="lv-item media p-0">
		<div class="sortable-item">
			<div>
				<span class="handle" *ngIf="(null | right:'creation') && !isRestricted"><icon class="icon-hc-2x" name="open_with"></icon></span>
				<span>{{ field.value.valeur.businessData.libelle }}</span>
			</div>
			<ul *ngIf="(null | right:'creation') && !isRestricted">
				<mat-button-toggle-group [value]="field.value.typeOrder || 'ASCENDING'" (change)="updateTypeOrder(index,$event.value)">
					<mat-button-toggle value="ASCENDING">{{ 'structureExport.listeRegroupements.typeOrder.ASCENDING' | translate | uppercase }}</mat-button-toggle>
					<mat-button-toggle value="DESCENDING">{{ 'structureExport.listeRegroupements.typeOrder.DESCENDING' | translate | uppercase }}</mat-button-toggle>
				</mat-button-toggle-group>
				<li *ngIf="index != detail.listeRegroupements?.length - 1">
					<button mat-icon-button color="primary" (click)="moveRegroupement(index,1);">
						<mat-icon>arrow_circle_down</mat-icon>
					</button>
				</li>
				<li *ngIf="index != 0">
					<button mat-icon-button color="primary" (click)="moveRegroupement(index,-1);">
						<mat-icon>arrow_circle_up</mat-icon>
					</button>
				</li>
				<li>
					<button mat-icon-button color="primary" (click)="deleteRegroupement(index);">
						<mat-icon>delete</mat-icon>
					</button>
				</li>
			</ul>
		</div>
	</div>
</ng-template>