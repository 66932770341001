<div class="pull-left lv-avatar">{{ data.typeEntretien.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addDetail?.(data,deletePlanEntretienDetail)">{{ data.typeEntretien.libelle + ' (' + data.typeEntretien.reference + ')' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idPlanEntretienDetail">
		<li *ngIf="data.duree"><span translate [translateParams]="{ duree: data.duree }">vehicule.planEntretien.liste.duree</span></li>
		<li *ngIf="data.distance"><span translate [translateParams]="{ distance: data.distance | number,unite: data.unite?.libelleCourt }">vehicule.planEntretien.liste.distance</span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idPlanEntretienDetail">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deletePlanEntretienDetail();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>