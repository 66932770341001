import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContratCRMService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Chargement d'un contrat CRM
	 */
	public loadContratCRM(idContratCRM: number): Observable<Result> {
		//Chargement d'un contrat CRM
		return this.http.post<Result>(`${environment.baseUrl}/controller/ContratCRM/loadContratCRM/${idContratCRM}`,null);
	}

	/**
	 * Suppression d'un contrat CRM
	 */
	public deleteContratCRM(contratCRM: any): Observable<Result> {
		//Suppression du contrat CRM
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ContratCRM/deleteContratCRM/${contratCRM.idContrat}`);
	}

	/**
	 * Enregistrement d'un contrat CRM
	 */
	public saveContratCRM(contratCRM: any): Observable<Result> {
		//Enregistrement d'un contrat CRM
		return this.http.put<Result>(`${environment.baseUrl}/controller/ContratCRM/saveContratCRM`,contratCRM);
	}

	/**
	 * Enregistrement d'une offre
	 */
	public saveOffreForContratCRM(lien: any): Observable<Result> {
		//Enregistrement d'une offre
		return this.http.put<Result>(`${environment.baseUrl}/controller/ContratCRM/saveOffreForContratCRM`,lien);
	}

	/**
	 * Suppression d'une offre
	 */
	public deleteOffreForContratCRM(lien: any): Observable<Result> {
		//Suppression d'une offre
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ContratCRM/deleteOffreForContratCRM/${lien.idLien}`);
	}

	/**
	 * Résiliation globale du contrat CRM
	 */
	public doGlobalResiliation(contratCRM: any): Observable<Result> {
		//Résiliation globale du contrat CRM
		return this.http.put<Result>(`${environment.baseUrl}/controller/ContratCRM/doGlobalResiliation/${contratCRM.idContrat}/${contratCRM.dateResiliation}`,null);
	}
}