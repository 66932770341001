import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { Filter } from 'src/app/domain/common/list-view';
import { Action } from 'src/app/domain/common/list-view/action';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

/**
 * Types de liste de validation
 */
export enum TypeListeValidation {
	/** Enumération **/
	A_VALIDER = 'A_VALIDER',
	VALIDES = 'VALIDES'
}

/**
 * Modèle d'une entité validable
 */
export abstract class ValidationEntiteOptions {
	/** Données **/
	abstract type: string
	abstract entite: string
	abstract defaultOrder?: string

	/**
	 * Constructeur
	 */
	constructor(protected injector: Injector,protected translateService: TranslateService,protected datePipe: DatePipe,protected messagingService: MessagingService) {}

	/**
	 * Titre
	 */
	abstract getTitle: (typeListe: TypeListeValidation) => string;

	/**
	 * URL
	 */
	abstract getUri: (typeListe: TypeListeValidation,filter?: any) => string;

	/**
	 * Liste des filtres
	 */
	abstract getListeFilters: (filter?: any) => Array<Filter>;

	/**
	 * Liste des filtres statiques
	 */
	abstract getListeStaticFilters?: (filter?: any) => Array<Filter>;

	/**
	 * Nom de l'identifiant
	 */
	abstract getKeyFieldName: () => string;

	/**
	 * Avatar de l'élément
	 */
	abstract getListeItemAvatar: (item: any) => string | { icon: string,iconType?: string };

	/**
	 * Titre de l'élément
	 */
	abstract getListeItemTitle: (item: any) => string;

	/**
	 * Attributs de l'élément
	 */
	abstract getListeItemAttributes?: (item: any) => Array<{ key: string,value: string }>;

	/**
	 * Réalisation de l'action Workflow
	 */
	abstract doAction: (item: any,action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER',onActionFinished: Subject<any>) => void;

	/**
	 * Liste des actions
	 */
	abstract getListeActions?: (typeListe: TypeListeValidation,onActionFinished: Subject<any>) => Array<Action>;

	/**
	 * Interception du clic sur l'élément
	 */
	abstract onClick: (typeListe: TypeListeValidation,item: any) => void;
}