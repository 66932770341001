import { Component,Input } from '@angular/core';

import { HistoryService } from './history.service';

@Component({
	selector: 'history-change',
	templateUrl: './history-change.component.html'
})
export class HistoryChangeComponent {
	/** Elément courant **/
	@Input() listeChanges: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private historyService: HistoryService) {}

	/**
	 * Affichage du composant de comparaison des modifications
	 */
	showCollectionChange(change: any) {
		//Affichage du composant de comparaison des modifications
		this.historyService.showCollectionChange(change);
	}
}