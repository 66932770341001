import { NgModule } from '@angular/core';
import { RouterModule,UrlHandlingStrategy } from '@angular/router';

import { routes } from './routes';

class RouteHandlingStrategy implements UrlHandlingStrategy {
	/** Liste des routes acceptées **/
	listeAcceptedRoutes: Array<string>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Recherche des routes acceptées par le router
		this.listeAcceptedRoutes = routes.map(r => r.path.indexOf(':') != -1 ? r.path.substr(0,r.path.indexOf(':')) : r.path);
	}

	/**
	 * Vérification du traitement de l'URL
	 */
	shouldProcessUrl(url) {
		let lastPart: string;

		//Conversion de l'URL et retrait du '/'
		lastPart = url.toString().substr(1);

		//Vérification de la présence de la route dans le router
		return this.listeAcceptedRoutes.some(r => lastPart.startsWith(r));
	}

	/**
	 * Extraction de l'URL
	 */
	extract = url => url;

	/**
	 * Fusion de l'URL
	 */
	merge = (url) => url;
}

@NgModule({
	imports: [RouterModule.forRoot(routes,{
		useHash: true,
		enableTracing: false
	})],
	exports: [RouterModule],
	providers: [{
		provide: UrlHandlingStrategy,
		useClass: RouteHandlingStrategy
	}]
})
export class AppRoutingModule { }
