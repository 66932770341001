<div class="modal-header">
	<h4 class="modal-title" [translate]="'article.lecture.title.'+article.typeArticle"></h4>
</div>
<div class="modal-body preview-template">
	<div class="media">
		<article-consultation [article]="article" [isModal]="true"></article-consultation>
	</div>
</div>
<div class="modal-footer align-items-center">
	<ng-container [ngSwitch]="article.typeArticle">
		<ng-container *ngSwitchCase="'CGU'">
			<button mat-raised-button color="accent" (click)="close(false)">
				<span translate>article.lecture.actions.cgu.refuser</span>
			</button>
			<button mat-raised-button color="primary" (click)="close()">
				<span translate>article.lecture.actions.cgu.accepter</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'ONBOARDING'">
			<button mat-button (click)="close()">
				<span translate>actions.fermer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<mat-checkbox name="isRead" #isRead="ngModel" [(ngModel)]="result.isRead"><span translate>article.lecture.nePlusAfficher</span></mat-checkbox>

			<button mat-raised-button color="primary" (click)="bsModalRef.hide()">
				<span translate>article.lecture.actions.accepter</span>
			</button>
		</ng-container>
	</ng-container>
</div>