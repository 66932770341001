import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minBy } from 'lodash-es';

import { OptionCategorieService } from 'src/app/components/vehicule/modele/option-categorie/option-categorie.service';

@Component({
	selector: 'option-list-item',
	templateUrl: './option-list-item.component.html'
})
export class OptionListItemComponent {
	/** Détail **/
	@Input() detail: any;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public optionCategorieService: OptionCategorieService) { }

	/**
	 * Récupération du titre du sélecteur d'option
	 */
	public getSelectorTitleForDetail(detail): string {
		//Vérification de la situation de l'option
		if (detail?.option.type == 'SERIE')
			//Équipement de série
			return this.translateService.instant('vehicule.modele.configuration.option.serie');
		else if (detail?.listeSourcesInclusion)
			//Récupération du parent source de l'inclusion au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.incluse',{ option: minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte.prixHT).detail?.option?.libelle });
		else if (detail?.listeSourcesObligation)
			//Récupération du parent source de l'obligation au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.ajoutee',{ option: minBy(detail.listeSourcesObligation,(o: any) => o.contrainte.prixHT).detail?.option?.libelle });
	}

	/**
	 * Récupération de la liste sérialisée des sous-catégories d'une option
	 */
	public getListeSubCategories(option): string {
		//Retour des libellés des sous-catégories
		return option?.listeSubCategories?.map(subCategorie => subCategorie?.libelle).join(', ');
	}
}