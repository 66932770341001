import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PlanningComponent } from './planning.component';
import { PlanningService } from './planning.service';
import { PlanningAffectationComponent } from './planning-affectation.component';
import { AffectationModule } from 'src/app/components/vehicule/affectation/affectation.module';

@NgModule({
	imports: [ShareModule,AffectationModule],
	declarations: [PlanningComponent,PlanningAffectationComponent],
	providers: [PlanningService]
})
export class PlanningModule { }