import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte-poste-charge-transfert',
	templateUrl: './compte-poste-charge-transfert.component.html'
})
export class ComptePosteChargeTransfertComponent implements AfterContentChecked,OnInit {
	/** Lien vers le poste de charge **/
	@Input() lienPosteCharge: any;

	/** Indicateur d'action de masse */
	@Input() isActionMasse: boolean;

	/** Résultat **/
	result: { lienPosteCharge: any };

	/** Copie du compte initial **/
	savedCompte: any;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ComptePosteChargeTransfertComponent>,private compteService: CompteService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition du compte initial
		this.savedCompte = Object.assign({},this.lienPosteCharge.compte);

		//Suppression du compte
		delete this.lienPosteCharge.compte;
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du lien
	 */
	saveComptePosteCharge() {
		//Vérification de l'action en masse
		if (!this.isActionMasse) {
			//Enregistrement du lien
			this.compteService.saveLienCompteDetail(this.lienPosteCharge).subscribe({
				next: result => {
					let listeDoublons = new Array<string>();

					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour du lien
						this.lienPosteCharge = Object.assign(this.lienPosteCharge,result.data?.lien);

						//Définition du résultat
						this.result = {
							lienPosteCharge: this.lienPosteCharge
						};

						//Fermeture de la modal
						this.bsModalRef.hide();
					} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout du poste de charge
						listeDoublons.push(this.translateService.instant('actions.doublon.posteCharge'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			});
		} else {
			//Définition du résultat
			this.result = {
				lienPosteCharge: this.lienPosteCharge
			};

			//Fermeture de la modal
			this.bsModalRef.hide();
		}

	}

	/**
	 * Fermeture de la modal
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}