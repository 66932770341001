import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './lot-comptable.entites';
import { LotComptableService } from './lot-comptable.service';

@Component({
	templateUrl: './lot-comptable-list-item.component.html'
})
export class LotComptableListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private historyService: HistoryService,private lotComptableService: LotComptableService,public notificationService: NotificationService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.lotComptable.LotComptable',this.data.idLotComptable);
	}

	/**
	 * Suppression d'un lot comptable
	 */
	deleteLotComptable() {
		//Suppression du lot comptable
		this.lotComptableService.deleteLotComptable(this.data.idLotComptable,() => {
			//Rechargement de la page
			this.layoutService.reloadSameUrl();
		});
	}

	/**
	 * Exécution des structures d'export
	 */
	executeStructureExport() {
		//Exécution des structures d'export
		this.lotComptableService.executeStructureExport(this.data);
	}
}