import { Component } from '@angular/core';

import { MenuItem } from 'src/app/domain/menu/menu';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { MenuService } from './menu.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class MenuComponent {
	/**
	 * Constructeur
	 */
	constructor(public menuService: MenuService,private layoutService: LayoutService) {}

	/**
	 * Sélection d'un élément du menu
	 */
	selectItem(item: MenuItem) {
		//Vérification de l'ancien élément sélectionné
		if (this.menuService.selectedMenu)
			//Déselection
			this.menuService.selectedMenu.isSelected = false;

		//Vérification de la présence d'une URL
		if (item.url)
			//Navigation vers l'URL
			this.layoutService.goToByUrl(item.url);

		//Etat de sélection
		item.isSelected = true;

		//Activation de l'élément
		item.isToggled = !item.isToggled;

		//Sélection de l'élément courant
		this.menuService.selectedMenu = item;
	}
}
