import { Component,OnInit,Renderer2 } from '@angular/core';
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

import { TemplateMailService } from './template-mail.service';

@Component({
	templateUrl: './template-mail-preview.component.html'
})
export class TemplateMailPreviewComponent implements OnInit {
	/** Template du mail **/
	templateMail: any;

	/** Identifiant de l'objet pour la prévisualisation du template **/
	idObjet: number;

	/** Contenu du mail **/
	content: SafeHtml;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef,private templateMailService: TemplateMailService,private domSanitizer: DomSanitizer,private renderer: Renderer2) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Création de la prévisualisation
		this.templateMailService.loadPreviewContent(this.templateMail,this.idObjet).pipe(take(1)).subscribe({
			next: result => {
				//Vérification du contenu
				if (result.data.previewTemplateMail != null)
					//Récupération du contenu généré
					this.content = this.domSanitizer.bypassSecurityTrustHtml(result.data.previewTemplateMail);
			}
		});
	}

	/**
	 * Intercepteur du chargement du contenu du mail
	 */
	onLoad(element) {
		//Définition de la taille de la frame à la taille du contenu
		element.style.height = element.contentDocument.body.scrollHeight + 'px';

		//Vérification du contenu
		if (element.contentDocument?.body) {
			//Interception des clics sur le corps de la frame
			this.renderer.listen(element.contentDocument.body,'click',event => {
				//Annulation de l'évènement par défaut
				event.preventDefault();
			});
		}
	}
}