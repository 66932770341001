import { Component,HostBinding,HostListener,Input,OnInit } from '@angular/core';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'confirm-modal',
	templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
	/** Message **/
	@Input() public message: string;

	/** Options d'affichage **/
	@Input() public options?: { withTyping?: boolean,withTitle?: boolean,confirmationTerm?: string,alerteInfo?: string,isCancelDisabled?: boolean,buttonType?: 'CONFIRM_CANCEL' | 'YES_NO',actionColor?: 'primary' | 'accent',listeActions?: Array<{ type: string,libelle?: string,class?: string }> };

	/** Vérification de la dernière popup */
	@Input() public isLastModal: () => boolean;

	/** Ajout de la classe 'bootbox' à l'élément hôte **/
	@HostBinding('class.bootbox') bootboxClass = true;

	/** Message brut **/
	public safeMessage: SafeHtml;

	/** Alerte brute **/
	public safeAlerteInfo: SafeHtml;

	/** Résultat **/
	public isConfirmed: boolean = false;

	/** Résultat (choix effectué en présence d'une liste d'actions personnalisée) **/
	public choice: string;

	/** Confirmation resaisie **/
	confirmation: string;

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<ConfirmComponent>,private domSanitizer: DomSanitizer) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération du message brut
		this.safeMessage = this.domSanitizer.bypassSecurityTrustHtml(this.message);

		//Récupération de l'alerte brute
		this.safeAlerteInfo = this.options?.alerteInfo && this.domSanitizer.bypassSecurityTrustHtml(this.options.alerteInfo) || null;
	}

	/**
	 * Fermeture de la popup
	 */
	close() {
		//Fermeture de la popup
		this.bsModalRef.hide();
	}

	/**
	 * Validation de la popup
	 */
	@HostListener('document:keyup.enter',['$event'])
	confirm(event?: any) {
		//Vérification du déclenchement par la touche entrée et de la dernière pop-up
		if (event  && !this.isLastModal())
			//Ne rien faire
			return;

		//Vérification de la présence d'une liste d'actions personnalisée
		if (this.options?.listeActions?.length && typeof event == 'string')
			//Récupération du choix
			this.choice = event;

		//Confirmation de la question
		this.isConfirmed = true;

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}