import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	selector: 'structure-export-regroupement-list',
	templateUrl: './structure-export-regroupement-list.component.html'
})
export class StructureExportRegroupementListComponent extends PageContentItem {
	/** Détail courant **/
	@Input() detail: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/**
	 * Constructeur
	 */
	constructor(private businessDataService: BusinessDataService,public entiteService: EntiteService) {
		//Héritage
		super();
	}

	/**
	 * Sélection d'un nouveau regroupement
	 */
	public selectRegroupement() {
		//Affichage de la popup des données métier
		this.businessDataService.showBusinessData('com.notilus.data.comptabilite.ecriture.ComptaEcriture',{
			typeContext: 'RULE',
			isPreviewData: false
		}).subscribe({
			next: item => {
				let formatedItem: any;

				//Vérification du résultat
				if (item) {
					//Elément formaté
					formatedItem = {
						valeur: {
							typeDetail: 'BUSINESS_DATA',
							businessData: {
								name: item.entity.path,
								type: item.entity.type,
								libelle: item.entity.pathLibelle || item.entity.libelle
							}
						}
					}

					//Vérification de la liste des regroupements
					if (!this.detail.listeRegroupements?.length)
						//Liste des clés
						this.detail.listeRegroupements = [formatedItem];
					else if (this.detail.listeRegroupements.findIndex(i =>  i.valeur.businessData.name == formatedItem.valeur.businessData.name) == -1)
						//Ajout de la clé à la liste
						this.detail.listeRegroupements.push(formatedItem);

					//Rafraichissement de la liste
					this.detail.listeRegroupements = [...this.detail.listeRegroupements];
				}
			}
		});
	}

	/**
	 * Suppression d'un regroupement
	 */
	public deleteRegroupement(index: number) {
		//Suppression de l'élément
		this.detail.listeRegroupements.splice(index,1);

		//Rafraichissement du de la liste
		this.detail.listeRegroupements = [...this.detail.listeRegroupements];
	}

	/**
	 * Déplacement d'un regroupement
	 */
	public moveRegroupement(index: number,delta: number) {
		let nextItem: any;

		//Récupération de l'élément suivant
		nextItem = this.detail.listeRegroupements[index + delta];

		//Echange avec l'élément suivant
		this.detail.listeRegroupements[index + delta] = this.detail.listeRegroupements[index];
		this.detail.listeRegroupements[index] = nextItem;

		//Rafraichissement de la liste
		this.detail.listeRegroupements = [...this.detail.listeRegroupements];
	}

	/**
	 * Mise à jour du type d'ordre
	 */
	public updateTypeOrder(index: number,value: any) {
		//Mise à jour du type d'ordre
		this.detail.listeRegroupements[index].typeOrder = value;
	}
}