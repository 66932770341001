import { CurrencyPipe } from '@angular/common';
import { Component,Input,OnInit,Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { GrilleAttributionModeleService } from 'src/app/components/vehicule/grille-attribution/grille-attribution-modele/grille-attribution-modele.service';
import { GrilleFluiditeService } from 'src/app/components/vehicule/grille-fluidite/grille-fluidite.service';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeInformationTarifaireComponent } from '../../vehicule-information-tarifaire.component';
import { DemandeVehiculeService } from './demande-vehicule.service';


@Component({
	selector: 'demande-vehicule-financement-edit',
	templateUrl: './demande-vehicule-financement-edit.component.html'
})
export class DemandeVehiculeFinancementEditComponent extends PageContentItem implements OnInit {
	/** Financement courant **/
	@Input() financement: any;

	/** Suppression du financement **/
	@Input() deleteFinancement: (close: Function) => void;

	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Grille de fluidité **/
	@Input() grilleFluidite: any;

	/** Sélection d'une grille de fluidité **/
	@Input() @Output() onGrilleFluiditeSelected: (grilleFluidite) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Liste des types d'affichage **/
	public listeTypesAffichagePrix: Array<{ code: string,libelle: string }>;

	/** Utilisateur courant **/
	public user: User;

	//Copie du financement
	public savedFinancement: any;

	/** Indicateur de chargement du financement **/
	public isFinancementLoaded: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private demandeVehiculeService: DemandeVehiculeService,private translateService: TranslateService,public rightService: RightService
			,private store: Store<AppState>,private grilleFluiditeService: GrilleFluiditeService,private grilleAttributionService: GrilleAttributionModeleService,private cotationService: CotationService
			,private currencyPipe: CurrencyPipe,private bsModalService: BsModalService,private modeleService: ModeleService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types d'affichage de prix
		this.listeTypesAffichagePrix = this.grilleAttributionService.getListeTypesAffichagePrix();

		//Initialisation de l'indicateur d'affichage du détail des loyers
		this.financement.isDetailLoyerDisplayed = this.financement.loyerFinancier || this.financement.loyerMaintenance || this.financement.loyerAssistance || this.financement.loyerGarantiePerteFinanciere || this.financement.loyerGestionCarburant || this.financement.loyerGestionSinistres || this.financement.loyerTelematique || this.financement.loyerPneumatiques || this.financement.loyerVehiculeRemplacement || this.financement.loyerAsssurance || this.financement.loyerAutres;

		//Duplication du financement
		this.savedFinancement = this.financement?.idFinancement && cloneDeep(this.financement);

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveFinancement() {
		//Fermeture de la fenêtre
		this.close({ financement: this.financement });
	}

	/**
	 * Calcul du prix de revient au kilomètre
	 */
	computePrixRevientKilometrique() {
		//Vérification des informations obligatoires pour le calcul
		if (this.financement.duree && this.financement.distance && this.financement.loyerMensuel)
			//Calcul du prix de revient
			this.financement.coutDistance = this.financement.duree * this.financement.loyerMensuel / this.financement.distance;
		else
			//Mise à zéro du prix
			this.financement.coutDistance = 0;
	}

	/**
	 * Récupération du libellé des informations tarifaires
	 */
	public getLibelleInformationTarifaire(): string {
		let libelle: string;

		//Vérification du genre
		if (this.demandeVehicule.vehicule?.genre?.modeAffichagePrix == 'HT' || this.financement?.informationTarifaire?.prixHTRemise > 0) {
			//Vérification du montant HT
			if (this.financement?.informationTarifaire?.prixHTRemise > 0)
				//Montant HT remisé
				libelle = this.translateService.instant('demandeVehicule.financement.informationTarifaire.montantNetHT',{ montant: this.currencyPipe.transform(this.financement.informationTarifaire?.prixHTRemise,this.financement.informationTarifaire?.devise) });
			else
				//Aucun montant HT
				libelle = this.translateService.instant('demandeVehicule.financement.informationTarifaire.aucunMontantHT');
		} else if (this.demandeVehicule.vehicule?.genre?.modeAffichagePrix == 'TTC') {
			//Vérification du montant TTC
			if (this.demandeVehicule.vehicule?.informationTarifaire?.prixTTCRemise > 0)
				//Montant TTC remisé
				libelle = this.translateService.instant('demandeVehicule.financement.informationTarifaire.montantNetTTC',{ montant: this.currencyPipe.transform(this.demandeVehicule.vehicule?.informationTarifaire?.prixTTCRemise,this.demandeVehicule.vehicule?.informationTarifaire?.devise) });
			else
				//Aucun montant TTC
				libelle = this.translateService.instant('demandeVehicule.financement.informationTarifaire.aucunMontantTTC');
		} else
			//Libellé par défaut
			libelle = this.translateService.instant('demandeVehicule.financement.informationTarifaire.aucunMontantHT');

		return libelle;
	}

	/**
	 * Ouverture de la popup des informations tarifaires
	 */
	public showInformationTarifaire(informationTarifaire = cloneDeep(this.financement?.informationTarifaire) || ({ devise: this.user.devise })) {
		let informationTarifaireConstructeur: any;

		//Fonction d'ouverture de la pop-up
		const doShowInformationTarifaire = (informationTarifaireConstructeur?: any) => {
			let bsModalRef: BsModalRef<VehiculeInformationTarifaireComponent>;

			//Affichage de la popup
			bsModalRef = this.bsModalService.show(VehiculeInformationTarifaireComponent,{
				initialState: {
					informationTarifaire: cloneDeep(informationTarifaire),
					informationTarifaireConstructeur
				}
			});

			//Retour du résultat
			bsModalRef.onHidden.pipe(
				first(),
				map(() => bsModalRef.content?.result?.informationTarifaire),
				filter(rInformationTarifaire => !!rInformationTarifaire && !isEqual(rInformationTarifaire,informationTarifaire))
			).subscribe({
				next: (rInformationTarifaire: any) => {
					//Définition du résultat
					this.financement.informationTarifaire = rInformationTarifaire;

					//Définition de la modification manuelle
					this.financement.informationTarifaire.userEdited = true;
				}
			});
		}

		//Vérification du modèle constructeur
		if (this.demandeVehicule?.modele) {
			//Vérification des informations du modèle constructeur
			if (this.demandeVehicule?.modele?.information && this.demandeVehicule?.modele?.configuration) {
				//Initialisation des prix du modèle constructeur
				informationTarifaireConstructeur = this.demandeVehicule.modele.configuration;

				//Ouverture du formulaire des informations tarifaires
				doShowInformationTarifaire(informationTarifaireConstructeur);
			} else if (this.demandeVehicule?.modele?.information?.commercialisationPrix) {
				//Initialisation des prix du modèle constructeur
				informationTarifaireConstructeur = this.demandeVehicule.modele.information.commercialisationPrix;

				//Ouverture du formulaire des informations tarifaires
				doShowInformationTarifaire(informationTarifaireConstructeur);
			} else
				//Chargement du modèle
				this.modeleService.loadModele(this.demandeVehicule?.modele?.idModele).subscribe({
					next: (result: Result) => {
						//Vérification du code d'erreur
						if (result.codeErreur == TypeCodeErreur.NO_ERROR)
							//Initialisation des prix du modèle constructeur
							informationTarifaireConstructeur = result.data.modele.information.commercialisationPrix;

						//Ouverture du formulaire des informations tarifaires
						doShowInformationTarifaire(informationTarifaireConstructeur);
					}
				})
		} else
			//Ouverture du formulaire des informations tarifaires
			doShowInformationTarifaire();
	}

	/**
	 * Affichage de la grille de fluidité
	 */
	public showGrilleFluidite() {
		//Affichage de la grille de fluidité
		this.grilleFluiditeService.showGrilleFluidite(this.grilleFluidite,{ distanceMensuelle: this.demandeVehicule?.distanceMensuelleEstimee || 0,canAddDetail: true }).subscribe({
			next: (result: any) => {
				//Vérification de l'indicateur de suppression
				if (result?.isDeleted) {
					//Suppression de la grille de fluidité
					this.grilleFluidite = null;

					//Transmission de la grille de fluidité
					this.onGrilleFluiditeSelected(null);
				}

				//Vérification de la présence d'un détail
				if (result?.detail) {
					//Mise à jour des attributs
					Object.assign(this.financement,{
						duree: result.detail.duree,
						distance: result.detail.distance,
						loyerMensuel: result.detail.loyer,
						coutDistance: result.detail.coutKilometrique
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'import d'une grille de fluidité
	 */
	public showGrilleFluiditeImport() {
		//Affichage de l'import d'une grille de fluidité
		this.grilleFluiditeService.showGrilleFluiditeImport('DEMANDE_VEHICULE',this.financement.fournisseur?.idFournisseur,this.demandeVehicule.idDemandeVehicule).subscribe({
			complete: () => {
				//Rechargement de la demande de véhicule
				this.demandeVehiculeService.loadDemandeVehicule(this.demandeVehicule.idDemandeVehicule).subscribe({
					next: (result: Result) => {
						//Vérification du chargement
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Définition de la grille de fluidité
							this.financement.grilleFluidite = result.data?.grilleFluidite;

							//Transmission de la grille de fluidité
							this.onGrilleFluiditeSelected(result.data?.grilleFluidite);

							//Mise à jour de la grille de fluidité
							this.grilleFluidite = result.data?.grilleFluidite;

							//Affichage de la grille de fluidité
							this.showGrilleFluidite();
						}
					}
				});
			}
		});
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Vérification de l'état de financement avant enregistrement
		return !isEqual(omit(this.savedFinancement,['grilleFluidite']),omit(this.financement,['grilleFluidite']))
	}

	/**
	 * Traitement avant l'action de fermeture du formulaire
	 */
	onBeforeAction(action: 'DIRTY_CLOSE' | 'SAVE' | 'CLOSE') {
		//Vérification de l'action
		if (this.savedFinancement?.idFinancement && action == 'DIRTY_CLOSE')
			//Réinitialisation du financement
			this.financement = this.savedFinancement;
	}

	/**
	 * Fermeture du formulaire
	 */
	doClose() {
		//Vérification de l'enregistrement du financement
		if (this.savedFinancement?.idFinancement)
			//Fermeture du formulaire
			this.close?.({ financement: this.financement });
		else
			//Fermeture du formulaire sans mettre à jour la liste
			this.close();
	}

	/**
	 * Affichage de la pop-up de comparaison
	 */
	showComparaisonForCotation(cotation: any = this.financement.reponseCotation?.cotation) {
		//Affichage de la pop-up de comparaison pour la cotation
		this.cotationService.showComparaisonForCotation(cotation).subscribe();
	}
}