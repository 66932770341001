import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GlobalSearchService {
	/** Liste des mappings */
	private listeMappings: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {

	}

	/**
	 * Initialisation de la liste des mappings
	 */
	init() {
		//Appel AJAX après vérification de la liste des mappings
		!this.listeMappings && this.http.post<Result>(`${environment.baseUrl}/controller/Search/retrieveMapping`,{}).pipe(
			map((result: Result) => result.data.listeMappings)
		).subscribe({
			next: listeMappings => this.listeMappings = listeMappings
		});
	}

	/**
	 * Ajout des informations de navigation/droits/libellé/liste des champs à un élément du mapping
	 */
	completeItem(item: any,isValidation: boolean): any {
		let liste: Array<any>;
		let newItem: any;

		//Récupération du code i18n
		liste = this.listeMappings.filter(mapping => mapping.type === item.type);

		//Vérification de la liste
		if (liste?.length > 0) {
			//Définition du nouvel élément
			newItem = {
				...item,
				libelle: isValidation ? this.translateService.instant(liste[0].i18nValidation) : this.translateService.instant(liste[0].i18n),
				listeFields: liste[0].listeFields,
				state: isValidation ? liste[0].stateValidation : liste[0].state,
				listeTypeDroits: liste[0].listeTypeDroits,
				mapEncryptedFields: liste[0].mapEncryptedFields,
				validationType: isValidation ? liste[0].type: null
			}
		}

		//Retour de l'élément
		return newItem ? newItem : item;
	}
}