import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { FormValidatorDirective } from './form-validator.directive';
import { ValidatorDirective } from './validator.directive';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [ValidatorDirective,FormValidatorDirective],
	exports: [ValidatorDirective,FormValidatorDirective]
})
export class ValidatorModule {}