<div class="liste-actions">
	<h4>
		<span [translate]="'liste.itemsSelection' | pluralTranslate:liste.nbSelectedItems" [translateParams]="{ nbSelected: liste.nbSelectedItems,nbTotal: liste.data.totalElements }"></span>
		(<a *ngIf="liste.nbSelectedItems != liste.data.totalElements" [routerLink]="[]" (click)="selectAll()" translate>liste.selectAll</a>
		<a *ngIf="liste.nbSelectedItems == liste.data.totalElements" [routerLink]="[]" (click)="unselectAll()" translate>liste.unselectAll</a>)
	</h4>
	<span>
		<ul class="actions">
			<li *ngFor="let action of getListeActions()">
				<a [routerLink]="[]" (click)="doAction(action)">
					<icon [type]="action.iconType" [name]="action.icon"></icon>
				</a>
			</li>
		</ul>
	</span>
</div>