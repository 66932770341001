import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-content',
	templateUrl: './modele-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleContentComponent {
	/** Modèle **/
	@Input() modele: any;

	/** Options d'affichage **/
	@Input() options?: { light?: boolean };
}
