import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthentificationConfigurationService } from './authentification-configuration.service';

@Component({
	selector: 'authentification-configuration',
	templateUrl: './authentification-configuration-list.component.html'
})
export class AuthentificationConfigurationListComponent implements OnInit {
	/** Liste des configurations **/
	listeConfigurations: Array<any>;

	/** Identifiant du tenant **/
	idTenant: number;

	/** Etat du composant **/
	disabled?: boolean;

	/** Résultat **/
	result: { listeConfigurations: Array<any> };

	/**
	 * Constructeur
	 */
	constructor(private authentificationConfigurationService: AuthentificationConfigurationService,public bsModalRef: BsModalRef<AuthentificationConfigurationListComponent>) { }

	/**
	 * Initalisation du composant
	 */
	ngOnInit() {
		//Vérification de l'état de la liste
		if (!this.listeConfigurations)
			//Initalisation de la liste
			this.listeConfigurations = new Array<any>();
	}

	/**
	 * Mise à jour de l'objet
	 */
	onObjectChange(idxConfiguration: number,configuration: any) {
		//Mise à jour de l'objet
		this.listeConfigurations[idxConfiguration] = configuration;
	}

	/**
	 * Suppression de l'élément
	 */
	removeItem(idxConfiguration: number) {
		//Vérification de l'index
		if (idxConfiguration != -1)
			//Suppression de l'élément
			this.listeConfigurations.splice(idxConfiguration,1);
	}

	/**
	 * Affichage de la configuration
	 */
	addConfiguration() {
		//Ouverture de la pop-up de configuration
		this.authentificationConfigurationService.showConfiguration({
			configuration: { actif: true },
			idTenant: this.idTenant
		}).subscribe({
			next: (configuration: any) => {
				//Ajout de la configuration à la liste
				this.listeConfigurations.unshift(configuration);
			}
		});
	}

	/**
	 * Enregistrement de la liste des configurations
	 */
	saveListeConfigurations() {
		//Définition du résultat
		this.result = {
			listeConfigurations: this.listeConfigurations
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}
