import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReponseDemandeCotationComponent } from './reponse-demande-cotation.component';
import { ReponseDemandeCotationService } from './reponse-demande-cotation.service';
import { ReponseCotationListComponent } from './reponse-cotation-list.component';
import { ReponseCotationListItemComponent } from './reponse-cotation-list-item.component';
import { ReponseCotationComponent } from './reponse-cotation.component';
import { DemandeCotationContentModule } from '../demande-cotation/content/demande-cotation-content.module';
import { ReponseDemandeCotationContentModule } from './content/reponse-demande-cotation-content.module';

@NgModule({
	imports: [ShareModule,DemandeCotationContentModule,ReponseDemandeCotationContentModule],
	declarations: [ReponseDemandeCotationComponent,ReponseCotationListComponent,ReponseCotationListItemComponent,ReponseCotationComponent],
	providers: [ReponseDemandeCotationService]
})
export class ReponseDemandeCotationModule { }