import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ServiceVehiculeListItemComponent } from './service-vehicule-list-item.component';

@Component({
	selector: 'service-vehicule-list',
	templateUrl: './service-vehicule-list.component.html'
})
export class ServiceVehiculeListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Résumé **/
	@Input() resume: { nbVehicules: number };

	/** Données **/
	liste: ListView<any,ServiceVehiculeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServiceVehiculeListItemComponent>({
			uri: () => `/controller/Vehicule/filtreVehicules`,
			component: ServiceVehiculeListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'reference',
				title: this.translateService.instant('vehicule.immatriculation.item'),
				isDefault: true
			},{
				clef: 'listeOldReferences',
				title: this.translateService.instant('vehicule.search.listeAnciennesImmatriculations'),
				isDefault: true
			},{
				clef: 'reference',
				title: this.translateService.instant('vehicule.search.numero'),
				isDefault: true
			},{
				clef: 'numeroInterne',
				title: this.translateService.instant('vehicule.search.numeroInterne'),
				isDefault: true
			},{
				clef: '*modele.libelle',
				isDefault: true
			},{
				clef: '*marque.libelle',
				isDefault: true
			},{
				clef: 'vin',
				title: this.translateService.instant('vehicule.search.vin'),
				isDefault: true

			}],
			listeStaticFilters: [{
				clef: `${this.service.type == 'SOCIETE' ? 'societe' : 'service'}.idService`,
				valeur: this.service.idService,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateEntree'
		});
	}
}