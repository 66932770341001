<div class="pull-left lv-avatar">
	<div *ngIf="!extraOptions?.vehicule?.dateSortie || extraOptions?.vehicule?.dateSortie > today">
		<icon name="person" *ngIf="data.typeAffectation == 'VEHICULE_FONCTION'"></icon>
		<icon name="apartment" *ngIf="data.typeAffectation == 'VEHICULE_SERVICE'"></icon>
		<icon name="lock" *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
		<icon name="event" *ngIf="data.typeAffectation == 'VEHICULE_POOL'"></icon>
		<icon name="question_mark" *ngIf="!data.typeAffectation"></icon>
	</div>
	<icon name="logout" *ngIf="extraOptions?.vehicule?.dateSortie && extraOptions?.vehicule?.dateSortie <= today"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showAffectation()">
			<span *ngIf="data.typeAffectation != 'VEHICULE_IMMOBILISE'">{{ data?.user | display:'user' }}</span>
			<span *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'" translate>vehicule.affectation.immobilisation</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAffectation">
		<li><span translate>vehicule.affectation.dateDebut.item</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li><span translate>vehicule.affectation.dateFin.item</span>&#160;:&#160;<span *ngIf="data.dateFin">{{ data.dateFin | date:'short' }}</span><span *ngIf="!data.dateFin" translate>common.aucune</span></li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.statut</span>&#160;:&#160;<span [translate]="'reservation.statut.'+data.reservation.statut"></span></li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.dateReservation</span>&#160;:&#160;{{ data.reservation.dateCreation | date:'short' }}</li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'suppression') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'suppression')) && !data.reservation"><a [routerLink]="[]" (click)="deleteAffectation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="['VEHICULE_FONCTION','VEHICULE_SERVICE'].includes(data.typeAffectation) && (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation')"><a [routerLink]="[]" (click)="showConducteur();"><span translate>vehicule.affectation.actions.accederConducteur</span></a></li>
			<li *ngIf="data.typeAffectation == 'VEHICULE_FONCTION' && (TypeDroit.VEHICULE_AFFECTATION_ECHEANCE | right:'consultation')"><a [routerLink]="[]" (click)="goToAffectationEcheances();"><span translate>vehicule.affectation.actions.accederEcheancierAvantagesNature</span></a></li>
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'consultation')"><a [routerLink]="[]" (click)="goToReservation();"><span translate>vehicule.affectation.actions.accederReservation</span></a></li>
			<li *ngIf="data.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation'))"><a [routerLink]="[]" (click)="goToPlanning();"><span translate>vehicule.affectation.actions.accederPlanning</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>