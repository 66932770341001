<form #demandeVehiculeBeneficiaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>demandeVehicule.beneficiaire.title</h4>
	</div>
	<div class="modal-body">
		<div class="m-t-20 m-b-10">
			<mat-radio-group [(ngModel)]="beneficiaire.source" name="choix">
				<mat-radio-button value="COLLABORATEUR"><span translate>demandeVehicule.beneficiaire.choix.user</span></mat-radio-button>
				<div class="form-group" [ngClass]="{ 'has-error': user.invalid }" *ngIf="beneficiaire.source == 'COLLABORATEUR'">
					<label translate>demandeVehicule.beneficiaire.user</label>
					<div>
						<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="beneficiaire.user" [required]="beneficiaire.source == 'COLLABORATEUR'"></autocomplete>
					</div>
				</div>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="SERVICE"><span translate>demandeVehicule.beneficiaire.choix.service</span></mat-radio-button>
				<ng-container *ngIf="beneficiaire.source == 'SERVICE'">
					<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
						<label translate>demandeVehicule.beneficiaire.societe</label>
						<div>
							<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="beneficiaire.societe" (onSelect)="beneficiaire.service = null" [required]="beneficiaire.source == 'SERVICE'"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
						<label translate>demandeVehicule.beneficiaire.service</label>
						<div>
							<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="beneficiaire.service" [filter]="{ idSociete: beneficiaire.societe?.idService }" [disabled]="!beneficiaire.societe" [required]="!!beneficiaire.societe?.extension?.nbSousServices"></autocomplete>
						</div>
					</div>
				</ng-container>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="demandeVehiculeBeneficiaireForm.invalid" *ngIf="null | right:'creation'" (click)="saveBeneficiaire()">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>