import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AmenagementModule } from '../materiel/amenagement/amenagement.module';
import { EquipementModule } from '../materiel/equipement/equipement.module';
import { AffectationEcheanceModule } from './affectation-echeance/affectation-echeance.module';
import { AffectationModule } from './affectation/affectation.module';
import { CarburantModule } from './carburant/carburant.module';
import { CommandeModule } from './commande/commande.module';
import { ConducteurModule } from './conducteur/conducteur.module';
import { ContratModule } from './contrat/contrat.module';
import { ControleReglementaireModule } from './controle-reglementaire/controle-reglementaire.module';
import { EntretienModule } from './entretien/entretien.module';
import { EtatLieuxModule } from './etat-lieux/etat-lieux.module';
import { GenreModule } from './genre/genre.module';
import { GrilleAttributionModule } from './grille-attribution/grille-attribution.module';
import { GrilleFluiditeModule } from './grille-fluidite/grille-fluidite.module';
import { ImmatriculationModule } from './immatriculation/immatriculation.module';
import { InfractionModule } from './infraction/infraction.module';
import { MarqueModule } from './marque/marque.module';
import { ModeleModule } from './modele/modele.module';
import { PlanEntretienModule } from './plan-entretien/plan-entretien.module';
import { PoolModule } from './pool/pool.module';
import { ReservationModule } from './pool/reservation/reservation.module';
import { ReleveEnergieModule } from './releve-energie/releve-energie.module';
import { ReleveCompteurModule } from './releve-compteur/releve-compteur.module';
import { SinistreModule } from './sinistre/sinistre.module';
import { TypeAmenagementModule } from './type-amenagement/type-amenagement.module';
import { TypeCarrosserieModule } from './type-carrosserie/type-carrosserie.module';
import { TypeEntretienModule } from './type-entretien/type-entretien.module';
import { VehiculeAffectationEditComponent } from './vehicule-affectation-edit.component';
import { VehiculeAffectationListItemComponent } from './vehicule-affectation-list-item.component';
import { VehiculeAffectationListComponent } from './vehicule-affectation-list.component';
import { VehiculeEditComponent } from './vehicule-edit.component';
import { VehiculeEnginComponent } from './vehicule-engin.component';
import { VehiculeImputationEditComponent } from './vehicule-imputation-edit.component';
import { VehiculeImputationListItemComponent } from './vehicule-imputation-list-item.component';
import { VehiculeImputationListComponent } from './vehicule-imputation-list.component';
import { VehiculeInformationTarifaireComponent } from './vehicule-information-tarifaire.component';
import { TypeInfractionModule } from './type-infraction/type-infraction.module';
import { VehiculeAchatComponent } from './vehicule-achat.component';
import { VehiculeAddComponent } from './vehicule-add.component';
import { VehiculeAmenagementEditComponent } from './vehicule-amenagement-edit.component';
import { VehiculeAmenagementListItemComponent } from './vehicule-amenagement-list-item.component';
import { VehiculeAmenagementListComponent } from './vehicule-amenagement-list.component';
import { VehiculeContratFinancementEditComponent } from './vehicule-contrat-financement-edit.component';
import { VehiculeContratFinancementListItemComponent } from './vehicule-contrat-financement-list-item.component';
import { VehiculeContratFinancementListComponent } from './vehicule-contrat-financement-list.component';
import { VehiculeFactureDetailListItemComponent } from './vehicule-facture-detail-list-item.component';
import { VehiculeFactureDetailListComponent } from './vehicule-facture-detail-list.component';
import { VehiculeEntretienEditComponent } from './vehicule-entretien-edit.component';
import { VehiculeEntretienListItemComponent } from './vehicule-entretien-list-item.component';
import { VehiculeEntretienListComponent } from './vehicule-entretien-list.component';
import { VehiculeEquipementEditComponent } from './vehicule-equipement-edit.component';
import { VehiculeEquipementListItemComponent } from './vehicule-equipement-list-item.component';
import { VehiculeEquipementListComponent } from './vehicule-equipement-list.component';
import { VehiculeEtatLieuxEditComponent } from './vehicule-etat-lieux-edit.component';
import { VehiculeEtatLieuxListItemComponent } from './vehicule-etat-lieux-list-item.component';
import { VehiculeEtatLieuxListComponent } from './vehicule-etat-lieux-list.component';
import { VehiculeImmatriculationListItemComponent } from './vehicule-immatriculation-list-item.component';
import { VehiculeImmatriculationListComponent } from './vehicule-immatriculation-list.component';
import { VehiculeImmatriculationSelectionComponent } from './vehicule-immatriculation-selection.component';
import { VehiculeInfractionListItemComponent } from './vehicule-infraction-list-item.component';
import { VehiculeInfractionListComponent } from './vehicule-infraction-list.component';
import { VehiculeListItemComponent } from './vehicule-list-item.component';
import { VehiculeListComponent } from './vehicule-list.component';
import { VehiculeReleveEnergieEditComponent } from './vehicule-releve-energie-edit.component';
import { VehiculeReleveEnergieListItemComponent } from './vehicule-releve-energie-list-item.component';
import { VehiculeReleveEnergieListComponent } from './vehicule-releve-energie-list.component';
import { VehiculeReleveCompteurEditComponent } from './vehicule-releve-compteur-edit.component';
import { VehiculeReleveCompteurListItemComponent } from './vehicule-releve-compteur-list-item.component';
import { VehiculeReleveCompteurListComponent } from './vehicule-releve-compteur-list.component';
import { VehiculeSinistreEditComponent } from './vehicule-sinistre-edit.component';
import { VehiculeSinistreListItemComponent } from './vehicule-sinistre-list-item.component';
import { VehiculeSinistreListComponent } from './vehicule-sinistre-list.component';
import { VehiculeSortieComponent } from './vehicule-sortie.component';
import { VehiculeComponent } from './vehicule.component';
import { VehiculeService } from './vehicule.service';
import { VehiculeImputationSelectionComponent } from './vehicule-imputation-selection.component';
import { VehiculeImputationSelectionItemComponent } from './vehicule-imputation-selection-item.component';

@NgModule({
	imports: [ShareModule,TypeAmenagementModule,PoolModule,ReservationModule,TypeInfractionModule,GenreModule,ConducteurModule,InfractionModule,ReleveCompteurModule,SinistreModule,EtatLieuxModule,AffectationEcheanceModule,ContratModule,MarqueModule,ModeleModule,PlanEntretienModule,TypeCarrosserieModule,CarburantModule,ReleveEnergieModule,AffectationModule,ImmatriculationModule,TypeEntretienModule,GrilleAttributionModule,ControleReglementaireModule,CommandeModule,GrilleFluiditeModule,EntretienModule,AmenagementModule,EquipementModule],
	exports: [TypeAmenagementModule,PoolModule,ReservationModule,TypeInfractionModule,GenreModule,ConducteurModule,InfractionModule,EntretienModule,ReleveCompteurModule,SinistreModule,EtatLieuxModule,AffectationEcheanceModule,ContratModule,MarqueModule,ModeleModule,PlanEntretienModule,TypeCarrosserieModule,CarburantModule,ReleveEnergieModule,AffectationModule,ImmatriculationModule,TypeEntretienModule,GrilleAttributionModule,ControleReglementaireModule,CommandeModule,GrilleFluiditeModule],
	declarations: [VehiculeListComponent,VehiculeListItemComponent,VehiculeImmatriculationListComponent,VehiculeImmatriculationListItemComponent,VehiculeAchatComponent,VehiculeSortieComponent,VehiculeImmatriculationSelectionComponent,VehiculeAddComponent,VehiculeComponent,VehiculeEditComponent,VehiculeInformationTarifaireComponent,VehiculeEnginComponent
		,VehiculeAffectationListComponent,VehiculeAffectationListItemComponent,VehiculeAffectationEditComponent,VehiculeImputationEditComponent,VehiculeImputationListComponent,VehiculeImputationListItemComponent,VehiculeContratFinancementListComponent,VehiculeContratFinancementListItemComponent,VehiculeContratFinancementEditComponent
		,VehiculeSinistreListComponent,VehiculeSinistreListItemComponent,VehiculeSinistreEditComponent,VehiculeInfractionListComponent,VehiculeInfractionListItemComponent,VehiculeEquipementListComponent,VehiculeEquipementListItemComponent,VehiculeEquipementEditComponent
		,VehiculeEntretienListComponent,VehiculeEntretienListItemComponent,VehiculeEntretienEditComponent,VehiculeAmenagementListComponent,VehiculeAmenagementListItemComponent,VehiculeAmenagementEditComponent,VehiculeEtatLieuxListComponent,VehiculeEtatLieuxListItemComponent,VehiculeEtatLieuxEditComponent
		,VehiculeReleveCompteurListComponent,VehiculeReleveCompteurListItemComponent,VehiculeReleveCompteurEditComponent,VehiculeReleveEnergieListComponent,VehiculeReleveEnergieListItemComponent,VehiculeReleveEnergieEditComponent,VehiculeFactureDetailListComponent,VehiculeFactureDetailListItemComponent,VehiculeImputationSelectionComponent,VehiculeImputationSelectionItemComponent
	],
	providers: [VehiculeService]
})
export class VehiculeModule { }