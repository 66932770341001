<div class="card">
	<div class="alert alert-info m-b-0 m-t-15 m-r-15" *ngIf="nbAnomalies == 0">
		<icon name="check"></icon>
		<div>
			<strong translate>comptabilite.lotComptable.check.poste.verification.title</strong>
			<div translate>comptabilite.lotComptable.check.poste.verification.aucuneAnomalie</div>
		</div>
	</div>
	<div class="alert alert-danger m-b-0 m-t-15 m-r-15" *ngIf="nbAnomalies != 0">
		<icon name="warning"></icon>
		<div>
			<strong translate>comptabilite.lotComptable.check.poste.verification.titleAnomalie</strong>
			<div translate [translateParams]="{ nbAnomalies }">{{ 'comptabilite.lotComptable.check.poste.verification.presenceAnomalies' | pluralTranslate:nbAnomalies }}</div>
		</div>
	</div>
	<ng-container *ngIf="nbAnomalies != 0">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
	</ng-container>
</div>