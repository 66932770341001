import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { GrilleAttributionModeleCotationListItemComponent } from './grille-attribution-modele-cotation-list-item.component';
import { GrilleAttributionModeleCotationListComponent } from './grille-attribution-modele-cotation-list.component';
import { GrilleAttributionModeleFinancementEditComponent } from './grille-attribution-modele-financement-edit.component';
import { GrilleAttributionModeleFinancementListItemComponent } from './grille-attribution-modele-financement-list-item.component';
import { GrilleAttributionModeleFinancementListComponent } from './grille-attribution-modele-financement-list.component';
import { GrilleAttributionModeleRemiseEditComponent } from './grille-attribution-modele-remise-edit.component';
import { GrilleAttributionModeleRemiseListItemComponent } from './grille-attribution-modele-remise-list-item.component';
import { GrilleAttributionModeleRemiseListComponent } from './grille-attribution-modele-remise-list.component';
import { GrilleAttributionModeleComponent } from './grille-attribution-modele.component';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@NgModule({
	imports: [ShareModule],
	declarations: [GrilleAttributionModeleComponent,GrilleAttributionModeleCotationListComponent,GrilleAttributionModeleCotationListItemComponent,GrilleAttributionModeleFinancementListComponent,GrilleAttributionModeleFinancementListItemComponent,GrilleAttributionModeleFinancementEditComponent,GrilleAttributionModeleRemiseListComponent,GrilleAttributionModeleRemiseListItemComponent,GrilleAttributionModeleRemiseEditComponent],
	providers: [GrilleAttributionModeleService]
})
export class GrilleAttributionModeleModule { }
