import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { UserCategorieAddComponent } from 'src/app/components/user/categorie/user-categorie-add.component';

/**
 * Options des utilisateurs
 */
export default class UserCategorieOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.userCategorie';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/UserReferentiel/listeCategoriesActives';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? item.libelle + (item.reference ? ' (' + item.reference + ')' : '') : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return (item?.reference?.substring(0,3)?.toUpperCase() || ''); }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.user.Categorie';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idCategorie'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'user.categorie.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return UserCategorieAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_UTILISATEUR_CATEGORIE;
}