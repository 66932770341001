import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

import { RuleService } from './rule.service';

@Component({
	selector: 'rule-detail',
	templateUrl: './rule-detail.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class RuleDetailComponent implements OnInit {
	/** Mode 'Light' **/
	@Input() isModeLight?: boolean;

	/** Mode 'Edition' **/
	@Input() isEdition?: boolean;

	/** Règle **/
	@Input() rule?: any;

	/** Entité correspondant à la règle **/
	@Input() entite: string;

	/** Interception de la suppression **/
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService) {
		//Initialisation de la règle
		this.rule = Object.assign(this.rule || {},{
			_type: 'com.notilus.data.rule.Rule',
			entite: this.entite,
			operateur: 'AND'
		});
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Typage des champs de saisie
		this.ruleService.assignFieldType(this.rule);
	}

	/**
	 * Ajout d'un élément à la règle
	 */
	addItem(rule,type) {
		//Création de la liste de détails
		rule.listeDetails = rule.listeDetails || [];

		//Vérification du type d'ajout
		if (type == 'groupe') {
			//Ajout d'un groupe de règle
			rule.listeDetails.push({
				rule: {
					_type: 'com.notilus.data.rule.Rule',
					operateur: 'AND',
					listeDetails: [{}]
				}
			});
		} else {
			//Ajout d'une règle
			rule.listeDetails.push({});
		}
	}

	/**
	 * Suppression de la règle
	 */
	removeItem() {
		//Vérification du nombre d'observateurs
		if (!this.onDelete.observers.length)
			//Suppression de la règle
			delete this.rule.listeDetails;
		else
			//Notification de la suppression
			this.onDelete.emit();
	}

	/**
	 * Création d'une règle vierge
	 */
	initRule() {
		//Ajout d'un détail
		this.rule.listeDetails = [{}];
	}
}