import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'facture-selection-societe',
	templateUrl: './facture-selection-societe.component.html'
})
export class FactureSelectionSocieteComponent implements AfterContentChecked {
	/** Société **/
	@Input() societe?: any;

	/** Code du compte de facturation **/
	@Input() codeCompteFacturation?: string;

	/** Options **/
	@Input() options: { mode: 'RECONCILIATION' | 'ASSOCIATION' } = { mode: 'ASSOCIATION' };

	/** Résultat **/
	result: { societe: any,codeCompteFacturation?: string };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<FactureSelectionSocieteComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la société
	 */
	public saveSociete() {
		//Définition du résultat
		this.result = {
			societe: this.societe,
			codeCompteFacturation: this.codeCompteFacturation
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}