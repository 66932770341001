import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class OffreService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement de l'offre
	 */
	public loadOffre(idOffre: number): Observable<Result> {
		//Chargement de l'offre
		return this.http.post<Result>(`${environment.baseUrl}/controller/Commerce/loadOffre/${idOffre}`,null);
	}

	/**
	 * Suppression de l'offre
	 */
	public deleteOffre(offre: any): Observable<Result> {
		//Suppression de l'offre
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Commerce/deleteOffre/${offre.idOffre}`);
	}

	/**
	 * Enregistrement de l'offre
	 */
	public saveOffre(offre: any): Observable<Result> {
		//Enregistrement de l'offre
		return this.http.put<Result>(`${environment.baseUrl}/controller/Commerce/saveOffre`,offre);
	}
}