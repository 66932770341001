'use strict';

var inherits = require('inherits'),
  urlUtils = require('../../utils/url'),
  SenderReceiver = require('./sender-receiver');
var debug = function () {};
if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')('sockjs-client:ajax-based');
}
function createAjaxSender(AjaxObject) {
  return function (url, payload, callback) {
    debug('create ajax sender', url, payload);
    var opt = {};
    if (typeof payload === 'string') {
      opt.headers = {
        'Content-type': 'text/plain'
      };
    }
    var ajaxUrl = urlUtils.addPath(url, '/xhr_send');
    var xo = new AjaxObject('POST', ajaxUrl, payload, opt);
    xo.once('finish', function (status) {
      debug('finish', status);
      xo = null;
      if (status !== 200 && status !== 204) {
        return callback(new Error('http status ' + status));
      }
      callback();
    });
    return function () {
      debug('abort');
      xo.close();
      xo = null;
      var err = new Error('Aborted');
      err.code = 1000;
      callback(err);
    };
  };
}
function AjaxBasedTransport(transUrl, urlSuffix, Receiver, AjaxObject) {
  SenderReceiver.call(this, transUrl, urlSuffix, createAjaxSender(AjaxObject), Receiver, AjaxObject);
}
inherits(AjaxBasedTransport, SenderReceiver);
module.exports = AjaxBasedTransport;