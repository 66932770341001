import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EquipementService } from './equipement.service';

@Component({
	selector: 'equipement-edit',
	templateUrl: './equipement-edit.component.html'
})
export class EquipementEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() equipement: any;

	/** Suppression de l'objet **/
	@Input() deleteEquipement: () => void;

	/** Affichage du code confidentiel **/
	public codeConfidentiel: any = { isShowCode: false };

	/** Liste des statuts **/
	public listeStatuts: Array<{ code: string,libelle: string }>

	/** Liste des types d'équipement **/
	public listeTypes: Array<{ code: string,libelle: string }>;

	/** Période en anomalie */
	public periodeValidity: any = {
		isValid: true,
		isDateDebutValid: true,
		isDateFinValid: true
	};

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private equipementService: EquipementService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types d'équipement
		this.listeTypes = this.equipementService.getListeTypes();

		//Définition de la liste des statuts
		this.listeStatuts = this.equipementService.getListeStatuts();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveEquipement() {
		//Enregistrement de l'élément
		this.equipementService.saveEquipement(this.equipement).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.equipement,result.data?.equipement);

					//Fermeture du formulaire
					this.close(this.equipement);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du fournisseur
					listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du numéro
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du numéro
						listeDoublons.push(this.translateService.instant('actions.doublon.numero'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification des périodes
	 */
	public isPeriodeValid() {
		let minDate;
		let maxDate;
		let isDateDebutValid: boolean = true;
		let isDateFinValid: boolean = true;

		//Vérification de la présence d'affectations
		if (this.equipement.listeAffectations?.length) {
			//Récupération des dates de la première affectation
			minDate = this.equipement.listeAffectations[0].dateDebut;
			maxDate = this.equipement.listeAffectations[0].dateFin;

			//Parcours des affectations
			this.equipement.listeAffectations.forEach(affectation => {
				//Date minimum
				minDate = affectation.dateDebut && affectation.dateDebut < minDate ? affectation.dateDebut : minDate;

				//Récupération de la date de fin
				maxDate = affectation.dateFin && maxDate && affectation.dateFin >= maxDate ? affectation.dateFin : maxDate;
			});

			//Validation de la date de début
			isDateDebutValid = this.equipement.dateDebut <= minDate;

			//Validation de la date de fin
			isDateFinValid = !this.equipement.dateFin || maxDate && this.equipement.dateFin >= maxDate;
		}

		//Définition de la période
		this.periodeValidity = {
			isValid: isDateDebutValid && isDateFinValid,
			isDateDebutValid,
			isDateFinValid
		};

		//Retour de la validité
		return this.periodeValidity.isValid;
	}
}