import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Params,Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { map,take } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { LoginService } from './login.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

/**
 * Connexion
 */
@Component({
	selector: 'start-login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
	/** Action demandée **/
	public action: 'Login' | 'Password' | 'Activate' = 'Login';

	/** Code du tenant **/
	public codeTenant?: string;

	/** Route vers laquelle rediriger l'utilisateur après sa connexion **/
	private routeToRedirect?: string;

	/** Paramètres de la route (issus de la requête) **/
	private routeQueryParams: any;

	/** Modes d'authentification disponibles **/
	public modeAuthentification: 'LOGIN_MDP' | 'FEDERATION' | 'BOTH' = 'BOTH';

	/** Mode d'authentification sélectionné **/
	public mode: 'LOGIN_MDP' | 'FEDERATION' = 'LOGIN_MDP';

	/** Indicateur de présence de plusieurs tenants correspondants à l'email (fédération d'identité) **/
	public isSeveralTenantFound: boolean = false;

	/** Liste des configurations d'authentification disponibles **/
	public listeAuthConfigs: Array<any> = [];

	/** Indicateur de chargement **/
	public isLoading: boolean = false;

	/** Indicateur de verrouillage du compte **/
	public isAccountLocked: boolean = false;

	/** Token de captcha **/
	public captchaToken: string = null;

	/** Token de définition de mot de passe **/
	public token: string = null;

	/** Configuration d'authentification sélectionnée **/
	public selectedAuthConfig: any = null;

	/** Indicateur d'utilisation du token **/
	public isTokenNotUsed?: boolean;

	/** Indicateur d'expiration du token **/
	public isTokenNotExpired?: boolean;

	/** Captcha **/
	@ViewChild('loginCaptcha')
	private loginCaptcha: any;

	/** Utilisateur **/
	public user: User = <User>{
		tenant: {
			code: null
		},
		idUser: null
	};

	/**
	 * Constructeur
	 */
	constructor(private loginService: LoginService,private toastrService: ToastrService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private router: Router,private layoutService: LayoutService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Lecture des paramètres de la route active
		combineLatest([this.activatedRoute.params,this.activatedRoute.queryParams]).pipe(
			take(1),
			map((response: Array<any>): { params: Params,queryParams: any } => ({
				params: response[0],
				queryParams: response[1]
			}))
		).subscribe((response: { params: Params,queryParams: any }) => {
			//Récupération du code du tenant
			this.codeTenant = response.params['codeTenant?route'] || response.params['codeTenant'];

			//Définition de la route vers laquelle rediriger l'utilisateur après sa connexion
			this.routeToRedirect = response.queryParams?.route || null;

			//Vérification de la présence d'une route vers laquelle rediriger
			if (this.routeToRedirect)
				//Définition des paramètres de la route issus de la requête
				this.routeQueryParams = response.queryParams || null;

			//Mise à jour du CSS
			this.layoutService.updateCSSForTenant(this.codeTenant);

			//Définition de l'action
			this.action = response.params.action || 'Login';

			//Définition du token
			this.token = response.params.token || null;

			//Mise à jour du code du tenant pour l'utilisateur
			this.user.tenant.code = this.codeTenant;

			//Mise à jour de l'identifiant de l'utilisateur
			this.user.idUser = response.params.idUser || null;

			//Vérification de la présence d'un token
			if (this.token) {
				//Vérification de la validité du token
				this.loginService.checkTokenValidity(this.token).subscribe({
					next: ({ isTokenNotUsed,isTokenNotExpired }: { isTokenNotUsed: boolean,isTokenNotExpired: boolean }) => {
						//Mise à jour des indicateurs
						this.isTokenNotUsed = isTokenNotUsed;
						this.isTokenNotExpired = isTokenNotExpired;
					}
				});
			}

			//Vérification du code du tenant
			if (this.codeTenant) {
				//Vérification des modes d'authentification disponibles
				this.loginService.checkModeAuthentification(this.codeTenant).subscribe({
					next: modeAuthentification => {
						//Mise à jour du mode d'authentification
						this.modeAuthentification = modeAuthentification;

						//Vérification du mode
						if (modeAuthentification == 'BOTH' || modeAuthentification == 'FEDERATION')
							//Activation de la fédération d'identité par défaut
							this.mode = 'FEDERATION';
					}
				});
			}
		});
	}

	/**
	 * Connexion
	 */
	doLogin() {
		//Définition de l'état du chargement
		this.isLoading = true;

		//Vérification du mode de connexion
		if (this.mode == 'LOGIN_MDP') {
			//Connexion de l'utilisateur
			this.loginService.login(this.user,this.routeToRedirect,this.routeQueryParams,this.captchaToken).subscribe({
				next: session => {
					//Vérification de la session
					if (!session || !session.isLogged) {
						//Remise à zéro du captcha
						this.loginCaptcha?.reset();

						//Affichage d'un message d'erreur
						this.toastrService.error(this.translateService.instant('login.message.echecConnexion'));
					}
				},
				error: (response) => {
					//Définition de l'état du chargement
					this.isLoading = false;

					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant('login.message.echecConnexion'));

					//Réinitialisation du token de captcha
					this.captchaToken = null;

					//Définition de l'indicateur de verrouillage du compte
					this.isAccountLocked = response.headers.get('account-locked') === 'true';
				},
				complete: () => {
					//Définition de l'état du chargement
					this.isLoading = false;
				}
			});
		} else {
			//Vérification de la présence d'une configuration d'authentification
			if (this.selectedAuthConfig) {
				//Déclenchement de l'authentification par fédération d'identité
				this.loginService.doFederatedLogin(this.user,this.selectedAuthConfig);
			} else {
				//Vérification des configurations d'authentification disponibles
				this.loginService.checkListeAuthConfigs(this.user,this.selectedAuthConfig,this.captchaToken).subscribe({
					next: ({ isSeveralTenantFound,isCaptchaNeeded,listeAuthConfigs }: { isSeveralTenantFound: boolean,isCaptchaNeeded: boolean,listeAuthConfigs: Array<any> }) => {
						//Mise à jour de l'indicateur de verrouillage de compte
						this.isAccountLocked = isCaptchaNeeded;

						//Vérification de la nécessité de saisir le captcha
						if (isCaptchaNeeded) {
							//Remise à zéro du captcha
							this.loginCaptcha?.reset();

							//Affichage d'un message d'avertissement
							this.toastrService.error(this.translateService.instant('login.message.errorConnexion'));
						} else {
							//Vérification de l'absence de plusieurs tenants
							if (!this.isSeveralTenantFound)
								//Mise à jour de l'indicateur
								this.isSeveralTenantFound = isSeveralTenantFound;

							//Vérification du nombre de configurations trouvées
							if (listeAuthConfigs?.length > 1) {
								//Mise à jour de la liste des configurations
								this.listeAuthConfigs = listeAuthConfigs;

								//Sélection de la première configuration
								this.selectedAuthConfig = listeAuthConfigs[0];
							} else if (listeAuthConfigs?.length == 1) {
								//Connexion de l'utilisateur
								this.loginService.doFederatedLogin(this.user,listeAuthConfigs[0]);
							}

							//Vérification de la présence de plusieurs tenants pour une adresse mail
							if (isSeveralTenantFound) {
								//Affichage d'un message d'avertissement
								this.toastrService.warning(this.translateService.instant('login.message.multiConfigurations'));

								//Suppression de la liste des configurations
								this.listeAuthConfigs = null;
							}
						}
					},
					error: () => {
						//Définition de l'état du chargement
						this.isLoading = false;
					},
					complete: () => {
						//Définition de l'état du chargement
						this.isLoading = false;
					}
				});
			}
		}
	}

	/**
	 * Récupération du mot de passe
	 */
	doRetrievePassword() {
		//Définition de l'état du chargement
		this.isLoading = true;

		//Récupération du mot de passe de l'utilisateur
		this.loginService.retrievePassword(this.user,this.captchaToken).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Remise à zéro du captcha
					this.loginCaptcha?.reset();

					//Affichage d'un message de succès
					this.toastrService.success(this.translateService.instant('login.message.passwordSent'));

					//Redirection vers l'écran d'authentification
					this.action = 'Login';
				} else
					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant('login.message.passwordSendingFailed'));
			},
			error: () => {
				//Définition de l'état du chargement
				this.isLoading = false;

				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('login.message.passwordSendingFailed'));
			},
			complete: () => {
				//Définition de l'état du chargement
				this.isLoading = false;
			}
		});
	}

	/**
	 * Mise à jour du mot de passe
	 */
	updatePassword() {
		//Définition de l'état du chargement
		this.isLoading = true;

		//Mise à jour du mot de passe de l'utilisateur
		this.loginService.updatePassword(this.user,this.token).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Affichage d'un message de succès
					this.toastrService.success(this.translateService.instant('login.message.passwordUpdated'));

					//Redirection vers l'écran d'authentification
					this.router.navigate(['/Login/'+this.user.tenant.code]);
				} else
					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant('login.message.passwordUpdateFailed'));
			},
			error: () => {
				//Définition de l'état du chargement
				this.isLoading = false;

				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('login.message.passwordUpdateFailed'));
			},
			complete: () => {
				//Définition de l'état du chargement
				this.isLoading = false;
			}
		});
	}

	/**
	 * Validation de la complexité du mot de passe
	 */
	isPasswordComplexe() {
		//Validation du mot de passe
		return this.loginService.isPasswordComplexe(this.user.password);
	}

	/**
	 * Vérification de la validité d'une règle pour le mot de passe
	 */
	isRuleValid(rule: 'caracteres' | 'majuscules' | 'minuscules' | 'chiffres' | 'speciaux') {
		//Vérification du type de règle
		return this.user.password && this.loginService.isRuleValid(this.user.password,rule);
	}

	/**
	 * Récupération d'un token de captcha
	 */
	onRecaptchaSuccess(token) {
		//Mise à jour du token
		this.captchaToken = token;
	}

	/**
	 * Expiration du reCaptcha
	 */
	onRecaptchaExpired() {
		//Mise à jour du token de captcha
		this.captchaToken = null;
	}
}