import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { PersonalDataDirective } from './personal-data.directive';
import { PersonalDataComponent } from './personal-data.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [PersonalDataDirective,PersonalDataComponent],
	exports: [PersonalDataDirective]
})
export class PersonalDataModule {}
