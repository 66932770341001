<form #reservationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!reservation.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="true">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" *ngIf="reservation.idReservation">
							<label translate>reservation.numeroReservation</label>
							<div>
								<span>{{ reservation.idReservation }}</span>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reservationForm.controls['user']?.invalid }">
							<label translate>reservation.collaborateur</label>
							<div>
								<autocomplete name="user" type="user" [(ngModel)]="reservation.user" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateDepart.invalid }">
							<label translate>reservation.dateDepart</label>
							<div class="half">
								<input type="text" class="form-control" name="dateDepart" #dateDepart="ngModel" [(ngModel)]="reservation.dateDepart" datePicker format="datetime" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateRetour.invalid }">
							<label translate>reservation.dateRetour</label>
							<div class="half">
								<input type="text" class="form-control" name="dateRetour" #dateRetour="ngModel" [(ngModel)]="reservation.dateRetour" datePicker format="datetime"/>
							</div>
						</div>
						<ng-container *ngIf="reservation.affectation && (reservation.affectation.dateDebut != reservation.dateDepart || reservation.affectation.dateFin != reservation.dateRetour)">
							<div class="form-group">
								<label translate>reservation.dateDebutAffectation</label>
								<div>
									<span>{{ reservation.affectation.dateDebut | date:'short' }}</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>reservation.dateFinAffectation</label>
								<div>
									<span>{{ reservation.affectation.dateFin | date:'short' }}</span>
								</div>
							</div>
						</ng-container>
						<div class="form-group">
							<label translate>reservation.statut.item</label>
							<div>
								<span [translate]="'reservation.statut.'+reservation.statut"></span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': reservationForm.controls['pool']?.invalid }">
							<label translate>reservation.pool</label>
							<div>
								<ng-container *ngIf="!reservation.idReservation || reservation.pool">
									<autocomplete name="pool" type="pool" [(ngModel)]="reservation.pool" required></autocomplete>
								</ng-container>
								<ng-container *ngIf="reservation.idReservation && !reservation.pool">
									<span>
										<span translate>common.nonDefini</span>
									</span>
								</ng-container>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reservationForm.controls['vehicule']?.invalid }">
							<label translate>reservation.vehicule</label>
							<div>
								<ng-container *ngIf="user?.type != 'VIRTUAL' && !reservation.vehicule">
									<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" [(ngModel)]="reservation.vehicule" required></autocomplete>
								</ng-container>
								<ng-container *ngIf="reservation.vehicule">
									<mat-chip-listbox>
										<mat-chip [ngClass]="{ clickable: (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="user?.type != 'VIRTUAL' && goToVehicule(reservation.affectation?.vehicule || reservation.vehicule)">
											{{ reservation.vehicule.reference + (reservation.vehicule.numeroInterne ? ' - ' + reservation.vehicule.numeroInterne : '') + ' (' + (reservation.vehicule.modele ? reservation.vehicule.modele.marque.libelle + ' ' + reservation.vehicule.modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}
										</mat-chip>
									</mat-chip-listbox>
								</ng-container>
							</div>
						</div>
						<div class="form-group">
							<label translate>reservation.vehiculeAffecte</label>
							<div>
								<ng-container *ngIf="reservation.affectation?.vehicule">
									<mat-chip-listbox>
										<mat-chip [class.clickable]="TypeDroit.ADMIN_VEHICULE | right:'consultation'" (click)="user?.type != 'VIRTUAL' && goToVehicule(reservation.affectation.vehicule)">
											{{ reservation.affectation.vehicule.reference + (reservation.affectation.vehicule.numeroInterne ? ' - ' + reservation.affectation.vehicule.numeroInterne : '') + ' (' + (reservation.affectation.vehicule.modele ? reservation.affectation.vehicule.modele.marque.libelle + ' ' + reservation.affectation.vehicule.modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}
										</mat-chip>
									</mat-chip-listbox>
								</ng-container>
								<ng-container *ngIf="!reservation.affectation?.vehicule">
									<span>
										<span translate>common.aucun</span>
									</span>
								</ng-container>
							</div>
						</div>
						<div class="form-group">
							<label translate>reservation.dateReservation</label>
							<div>
								<span [translate]="reservation.dateCreation | date:'short'"></span>
							</div>
						</div>
						<div class="form-group" *ngIf="reservation.etatLieux">
							<label translate>reservation.etatLieux</label>
							<div>
								<mat-chip-listbox>
									<mat-chip [class.clickable]="TypeDroit.ETAT_LIEUX | right:'consultation'" (click)="user?.type != 'VIRTUAL' && goToEtatLieux(reservation.etatLieux)">
										<span><span translate>reservation.etatLieux</span>&#160;(<span [translate]="'vehicule.etatLieux.statut.'+reservation.etatLieux.statut"></span>)</span>
									</mat-chip>
								</mat-chip-listbox>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>reservation.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" [(ngModel)]="reservation.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer" *ngIf="user?.type != 'VIRTUAL'">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: reservationForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')" (click)="goToPlanning()">
				<span translate>reservation.actions.accederPlanning</span>
			</button>
		</div>
	</div>
</form>