import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { PoolService } from './pool.service';

@Component({
	selector: 'pool-list-item',
	templateUrl: './pool-list-item.component.html'
})
export class PoolListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private poolService: PoolService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Suppression de l'objet
	 */
	deletePool() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du pool
					this.poolService.deletePool(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'éxecution
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.pool.Pool',this.data.idPool);
	}

	/**
	 * Récupération des groupes associés à un pool
	 */
	getListeGroupes(entity: string): string {
		//Retour des libellés des groupes
		return this.data.listeGroupes?.filter(groupe => groupe?.entity == entity).map(groupe => groupe?.libelle).join(', ') || '';
	}
}
