import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { CompteAuxiliaireFournisseurListItemComponent } from './compte-auxiliaire-fournisseur-list-item.component';

@Component({
	selector: 'compte-auxiliaire-fournisseur-list',
	templateUrl: './compte-auxiliaire-fournisseur-list.component.html'
})
export class CompteAuxiliaireFournisseurListComponent extends PageContentItem implements OnInit {
	/** Compte auxiliaire **/
	@Input() compteAuxiliaire: any;

	/** Fonction d'édition **/
	@Input() addFournisseur: () => void;

	/** Résumé **/
	@Input() resume: { nbFournisseurs: number };

	/** Restriction sur le plan comptable **/
	@Input() restriction: { isRestricted: boolean } = null;

	/** Données **/
	liste: ListView<any,CompteAuxiliaireFournisseurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CompteAuxiliaireFournisseurListItemComponent>({
			uri: () => `/controller/Compte/filtreDetailsByCompte/FOURNISSEUR/${this.compteAuxiliaire.compte.idCompte}`,
			component: CompteAuxiliaireFournisseurListItemComponent,
			isLocal: true,
			extraOptions: {
				addFournisseur: this.addFournisseur,
				restriction: this.restriction
			},
			listeFilters: [{
				clef: 'fournisseur',
				autocomplete: {
					type: 'fournisseur'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'compteAuxiliaire.idCompteAuxiliaire',
				valeur: this.compteAuxiliaire.idCompteAuxiliaire
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbFournisseurs--;
	}
}