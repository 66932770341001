<div class="pull-left lv-avatar">{{ data?.libelle?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="extraOptions?.addCustomRefValue?.(extraOptions?.num,data,deleteCustomRefValue)"><span>{{ data.libelle + ' (' + data.reference + ')' }}</span></a>
</div>
<ul class="lv-attrs">
	<li><span translate>dataCustomization.customRefValue.actif.item</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idCustomRefValue && (null | right:'creation')">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteCustomRefValue();"><span translate>actions.supprimer</span></a></li>
	</ul>
</div>