import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import moment from 'moment';

@Component({
	templateUrl: './permis-conduire.component.html'
})
export class PermisConduireComponent {
	/** Permis de conduire **/
	permisConduire: any;

	/** Indicateur de lecture seule **/
	readOnly?: boolean;

	/** Permis de conduire modifié **/
	savedPermisConduire: any;

	/** Date maximale d'obtention du permis **/
	dateMax = moment().startOf('day');

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<PermisConduireComponent>) {}

	/**
	 * Suppression du permis de conduire
	 */
	deletePermis() {
		//Suppression du permis
		this.savedPermisConduire = null;

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Enregistrement du permis de conduire
	 */
	save() {
		//Enregistrement du permis
		this.savedPermisConduire = this.permisConduire;

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}