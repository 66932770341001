import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { LicenceConsommationService } from 'src/app/components/licence/consommation/licence-consommation.service';
import { LicenceConsommationListItemComponent } from './licence-consommation-list-item.component';
import { LicenceConsommationListComponent } from './licence-consommation-list.component';
import { LicenceConsommationComponent } from './licence-consommation.component';
import { LicenceConsommationElementListComponent } from './licence-consommation-element-list.component';
import { LicenceConsommationElementListItemComponent } from './licence-consommation-element-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [LicenceConsommationListComponent,LicenceConsommationListItemComponent,LicenceConsommationComponent,LicenceConsommationElementListComponent,LicenceConsommationElementListItemComponent],
	providers: [LicenceConsommationService]
})
export class LicenceConsommationModule { }