import { Component,OnInit } from '@angular/core';
import { DatePipe,TitleCasePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,take } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LicenceConsommationElementListComponent } from './licence-consommation-element-list.component';
import { LicenceConsommationService } from './licence-consommation.service';

@Component({
	selector: 'licence-consommation',
	templateUrl: './licence-consommation.component.html'
})
export class LicenceConsommationComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Consommation courante **/
	public licenceConsommation: any;

	/** Résumé **/
	public resume: { nbVehicules: number,nbDemandesIdentification: number } = {
		nbVehicules: 0,
		nbDemandesIdentification: 0
	}

	/**
	 * Constructeur
	 */
	constructor(private licenceConsommationService: LicenceConsommationService,private toastrService: ToastrService,private confirmService: ConfirmService,private datePipe: DatePipe,private titleCasePipe: TitleCasePipe
			,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.licence.LicenceConsommation';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.licenceConsommation?.idLicenceConsommation || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'licence.consommation.tenant',
				key: () => `${this.licenceConsommation.tenant.nom} (${this.licenceConsommation.tenant.code})`
			},{
				libelle: 'licence.consommation.periode',
				key: () => this.titleCasePipe.transform(this.datePipe.transform(this.licenceConsommation.periode,'MMMM YYYY'))
			},{
				libelle: 'licence.consommation.dateCalcul',
				key: 'dateCalcul',
				type: 'DATE',
				format: 'datetime'
			},{
				libelle: 'licence.consommation.periodeCalcul.title',
				key: () => this.translateService.instant('licence.consommation.periodeCalcul.item',{
					dateDebut: moment(this.licenceConsommation.dateDebut).format('L LT'),
					dateFin: moment(this.licenceConsommation.dateFin).format('L LT')
				})
			}],
			listeActions: [{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteLicenceConsommation(),
				isVisible: () => this.rightService.hasRight(null,'suppression'),
				type: TypeAction.SUPPRESSION
			}],
			listeElements: [{
				type: 'VEHICULE',
				libelle: 'licence.consommation.elements.vehicule',
				component: LicenceConsommationElementListComponent,
				retrieveComponentData: () => ({
					licenceConsommation: this.licenceConsommation,
					type: 'VEHICULE'
				}),
				count: () => this.resume?.nbVehicules || 0
			},{
				type: 'DEMANDE_IDENTIFICATION',
				libelle: 'licence.consommation.elements.demandeIdentification',
				component: LicenceConsommationElementListComponent,
				retrieveComponentData: () => ({
					licenceConsommation: this.licenceConsommation,
					type: 'ANTAI'
				}),
				count: () => this.resume?.nbDemandesIdentification || 0
			}]
		};

		//Récupération de l'identifiant de l'objet à charger
		this.activatedRoute.params.pipe(take(1)).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idLicenceConsommation);
			}
		});
	}

	/**
	 * Suppression de l'objet
	 */
	private deleteLicenceConsommation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.licenceConsommationService.deleteLicenceConsommation(this.licenceConsommation)),
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idLicenceConsommation: number) {
		//Chargement de l'objet
		this.licenceConsommationService.loadLicenceConsommation(idLicenceConsommation).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'objet
					this.licenceConsommation = result.data.licenceConsommation;

					//Définition du résumé
					this.resume = {
						nbVehicules: this.licenceConsommation.listeTypes?.filter(type => type.type === 'VEHICULE')[0]?.quantite || 0,
						nbDemandesIdentification: this.licenceConsommation.listeTypes?.filter(type => type.type === 'ANTAI')[0]?.quantite || 0
					}
				}
			}
		});
	}
}