<form #userForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>user.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label translate>user.civilite</label>
			<div>
				<selectpicker name="civilite" #civilite="ngModel" [(ngModel)]="user.civilite">
					<mat-option *ngFor="let civilite of listeTypesCivilite" [value]="civilite"><span [translate]="'user.genre.' + civilite"></span></mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': nom.invalid }">
			<label personalData translate>user.nom</label>
			<div>
				<input type="text" class="form-control" name="nom" #nom="ngModel" [(ngModel)]="user.nom" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': prenom.invalid }">
			<label personalData translate>user.prenom</label>
			<div>
				<input type="text" class="form-control" name="prenom" #prenom="ngModel" [(ngModel)]="user.prenom" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': matricule.invalid }">
			<label personalData translate>user.matricule</label>
			<div>
				<input type="text" class="form-control" name="matricule" #matricule="ngModel" [(ngModel)]="user.matricule" maxlength="100" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
			<label personalData translate>user.email</label>
			<div>
				<input type="email" email class="form-control" name="email" #email="ngModel" [(ngModel)]="user.email" maxlength="600" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }" *ngIf="user.tenant?.type == 'CLIENT'">
			<label translate>user.societe</label>
			<div>
				<autocomplete name="societe" type="societe" #societe="ngModel" (ngModelChange)="user.service = null" [(ngModel)]="user.societe" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': service.invalid }" *ngIf="user.tenant?.type == 'CLIENT'">
			<label translate>user.serviceAffectation</label>
			<div>
				<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="user.service" [disabled]="!user.societe || user.societe?.extension?.nbSousServices == 0" [filter]="{ idSociete: user.societe?.idService }" [required]="user.societe?.extension?.nbSousServices > 0"></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': categorie.invalid }" *ngIf="user.tenant?.type == 'CLIENT'">
			<label translate>user.categorie.item</label>
			<div>
				<autocomplete name="categorie" type="userCategorie" #categorie="ngModel" [(ngModel)]="user.categorie"></autocomplete>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-stroked-button color="primary" (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="userForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>