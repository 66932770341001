<div class="pull-left lv-avatar">{{ data?.nature?.libelle?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="[]" *ngIf="posteChargeSettings?.typeDroit | right:'consultation'" (click)="goToPosteCharge()">{{ data?.nature?.libelle + ' (' + data?.nature?.idNature + ')' }}</a>
	<span *ngIf="!(posteChargeSettings?.typeDroit | right:'consultation')">{{ data?.nature?.libelle + ' (' + data?.nature?.idNature + ')' }}</span>
</div>
<ul class="lv-attrs" *ngIf="data.idLien">
	<li *ngIf="data.type"><span translate>fiscalite.regleTaxe.posteCharge.typeCharge.item</span>&#160;:&#160;<span [translate]="'fiscalite.regleTaxe.posteCharge.typeCharge.' + data.type"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idLien && (null | right:'suppression')">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteLienRegleTaxeDetail();"><span translate>actions.supprimer</span></a></li>
	</ul>
</div>