import { Component,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { RecaptchaDirective } from 'src/app/share/directive/recaptcha/recaptcha.directive';

@Component({
	selector: 'account-contact-admin',
	templateUrl: './account-contact-admin.component.html'
})
export class AccountContactAdminComponent implements OnInit {
	/** Données du message à l'administrateur **/
	notification: { content: string,isMailSent: boolean } = { content: null,isMailSent: false };

	/** Token du captcha **/
	captchaToken: string;

	/** Options de l'éditeur 'Summernote' **/
	summernoteConfig: any;

	/** Captcha **/
	@ViewChild('mailCaptcha') captcha: RecaptchaDirective;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private notificationService: NotificationService,private toastrService: ToastrService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Configuration de l'éditeur
		this.summernoteConfig = {
			height: 300,
			disableDragAndDrop: true,
			toolbar: [
				['style',['bold','italic','underline','clear','strikethrough']],
				['fontsize',['color','fontsize','height']],
				['para',['ul','ol','paragraph']],
				['table',['table']]
			]
		};
	}

	/**
	 * Récupération d'un token de captcha
	 */
	onRecaptchaSuccess(token: string) {
		//Mise à jour du token de captcha
		this.captchaToken = token;
	}

	/**
	 * Expiration d'un token de captcha
	*/
	onRecaptchaExpired() {
		//Mise à jour du token de captcha
		this.captchaToken = null;
	}

	/**
	 * Etat d'activation du bouton d'envoi
	 */
	public canSendMail(): boolean {
		//Vérification de la possibilité d'envoyé un mail
		return !this.notification?.isMailSent && this.notification?.content?.length > 0 && !!this.captchaToken;
	}

	/**
	 * Affichage du bouton d'envoi
	 */
	public canShowSendMail(): boolean {
		//Vérification de la possibilité d'afficher le bouton d'envoie
		return !this.notification.isMailSent;
	}

	/**
	 * Envoi de la notification à l'administrateur du tenant
	 */
	public sendMail() {
		//Indicateur de mail envoyé
		this.notification.isMailSent = false;

		//Envoi de la notification à l'administrateur du tenant
		this.notificationService.sendMailToAdminTenant(this.captchaToken,this.notification).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Indicateur de mail envoyé
					this.notification.isMailSent = true;

					//Suppression du contenu
					this.notification.content = null;

					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.mail.envoye.success'))

					//Suppression du token
					this.captchaToken = null;

					//Réinitialisation du captcha
					this.captcha.reset();
				} else
					//Message d'information
					this.toastrService.error(this.translateService.instant('actions.mail.envoye.error'))
			}
		})
	}
}