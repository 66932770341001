<form #entretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>conducteur.entretien.titre</h2>
			</div>
			<div class="card-body card-padding">
				<entretien-content #entretienContent [entretien]="entretien" source="CONDUCTEUR" [nbInterventions]="nbInterventions" (close)="onContentClose($event)" [liste]="liste"></entretien-content>
			</div>
		</div>
		<span class="pull-right">
			<button mat-raised-button color="primary" *ngIf="(TypeDroit.ENTRETIEN | right:'creation') && !entretien.readOnly" (click)="entretienContent.saveEntretien();" [disabled]="entretienForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: entretienForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>