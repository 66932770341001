import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des sociétés
 */
export default class SocieteOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.societe';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Analytique/listeSocietesActives';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.service.Service';

	/**
	  * Récupération du nom de la clé
	  */
	getKeyFieldName() { return 'idService'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.listeIdsSociete && {
			clef: 'idService',
			listeObjects: filter.listeIdsSociete,
			typeComparaison: TypeComparaison.IN,
			type: TypeFilter.LONG
		},filter?.idPays && {
			clef: 'pays.idPays',
			valeur: filter.idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}
}