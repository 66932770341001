<div class="pull-left lv-avatar" [translate]="'vehicule.releveCompteur.liste.avatar.'+(data?.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showCompteur()">{{ data.vehicule | display:'vehicule' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.releveCompteur.dateReleve</span>&#160;:&#160;{{ data.dateReleve | date:'shortDate' }}</li>
		<li><span translate>vehicule.releveCompteur.releveCompteur</span>&#160;:&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.releveCompteur | number:'.2-2') : (data.releveHoraire | number) }}&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</li>
		<li><span translate>vehicule.releveCompteur.valeurReference.item</span>&#160;:&#160;<span [translate]="'common.' + (data.valeurReference ? 'oui' : 'non')"></span></li>
	</ul>
</div>