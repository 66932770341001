import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { Options } from 'src/app/domain/common/complex-page/options';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ModeleService } from './modele.service';
import { Result } from 'src/app/domain/common/http/result';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { ModeleOptionListComponent } from './modele-option-list.component';
import { ModeleCaracteristiqueComponent } from './modele-caracteristique.component';

@Component({
	selector: 'modele',
	templateUrl: './modele.component.html'
})
export class ModeleComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Modèle courant **/
	public modele: any;

	/** Indicateur de synchronisation des options **/
	public isOptionsSynced: boolean;

	/** Indicateur d'une synchronisation en cours des options **/
	private isSynchronizing = false;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private modeleService: ModeleService,private activatedRoute: ActivatedRoute,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.modele.libelle',
				key: 'libelle'
			},{
				libelle: 'vehicule.modele.reference',
				key: 'reference'
			},{
				libelle: 'vehicule.modele.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [],
			listeElements: [{
				type: 'CARACTERISTIQUE',
				libelle: 'vehicule.modele.elements.caracteristique',
				component: ModeleCaracteristiqueComponent,
				retrieveComponentData: () => ({
					modele: this.modele
				}),
				count: () => null,
				isActionVisible: () => false,
				isVisible: () => true
			},{
				type: 'OPTION',
				libelle: 'vehicule.modele.elements.option',
				component: ModeleOptionListComponent,
				retrieveComponentData: () => ({
					modele: this.modele,
					isOptionsSynced: this.isOptionsSynced,
					isSynchronizing: this.isSynchronizing,
					selectCaracteristiques: () => this.complexPage.setSelectedElementByType('CARACTERISTIQUE',true)
				}),
				count: () => null,
				isActionVisible: () => false,
				isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_MODELE,'consultation')
			}]
		};

		//Récupération de l'identifiant du modèle à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idModele);
			}
		});
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.VehiculeModele';

	/**
	 * Récupération de l'identifiant du modèle à charger
	 */
	getIdObject: () => number = () => this.modele?.idModele || 0;

	/**
	 * Chargement des données
	 */
	private loadData(idModele: number) {
		//Chargement du modèle
		this.modeleService.loadModele(idModele).subscribe({
			next: (result: Result) => {
				//Vérification du modèle
				if (result.data?.modele) {
					//Mise à jour du modèle
					this.modele = result.data.modele;

					//Récupération de l'indicateur de synchronisation des options du modèle
					this.isOptionsSynced = result.data?.isOptionsSynced;
				} else {
					//Création du modèle
					this.modele = {
						actif: true
					};
				}
			}
		});
	}
}