<div class="card-padding p-t-20">
	<div class="form-group">
		<label><span translate>notification.selection.recapitulatif.courriel</span></label>
		<div>
			<span>{{ selectedMail.libelle }}</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>notification.selection.recapitulatif.objets</span></label>
		<div>
			<span translate [translateParams]="{ count: actionMasse.nbSelected || 1 }">notification.selection.recapitulatif.selectedObjets</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>notification.selection.recapitulatif.destinataires</span></label>
		<div>
			<span>
				<mat-chip-listbox>
					<mat-chip *ngFor="let destinataire of (listeDestinataires | filterBy:{ selected: true,type: 'PRINCIPAL' })">{{ destinataire.libelle }}</mat-chip>
				</mat-chip-listbox>
				<span *ngIf="!isDestinataireSelected(listeDestinataires,'PRINCIPAL')"><span translate>common.aucun</span></span>
			</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>notification.selection.recapitulatif.copie</span></label>
		<div>
			<span>
				<mat-chip-listbox>
					<mat-chip *ngFor="let destinataire of (listeDestinataires | filterBy:{ selected: true,type: 'COPIE' })">{{ destinataire.libelle }}</mat-chip>
				</mat-chip-listbox>
				<span *ngIf="!isDestinataireSelected(listeDestinataires,'COPIE')"><span translate>common.aucun</span></span>
			</span>
		</div>
	</div>
	<div class="form-group">
		<label><span translate>notification.selection.recapitulatif.mailAttachments</span></label>
		<div>
			<span>
				<mat-chip-listbox>
					<mat-chip *ngFor="let link of (selectedMail.listeLinks | filterBy:{ type: 'MAIL_ATTACHMENT' })">{{ link.attachment.name }}</mat-chip>
				</mat-chip-listbox>
				<span *ngIf="!selectedMail.listeLinks || (selectedMail.listeLinks | filterBy:{ type: 'MAIL_ATTACHMENT' }).length == 0"><span translate>common.aucun</span></span>
			</span>
		</div>
	</div>
	<div class="p-t-20 p-b-20 d-flex justify-content-space-between align-items-center">
		<h4><span translate>notification.selection.recapitulatif.addRemarque</span></h4>

		<mat-button-toggle-group [(ngModel)]="isRemarqueAdded" (change)="isRemarqueAdded = $event.value; remarque = $event.value ? null : remarque;">
			<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
			<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div [collapse]="!isRemarqueAdded">
		<div class="template-bloc" *ngIf="isRemarqueAdded">
			<summernote [(ngModel)]="remarque" name="remarque"></summernote>
		</div>
	</div>
</div>