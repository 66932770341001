<div class="modal-header">
	<h4 class="modal-title"><span>{{ destinataire.libelle }}</span></h4>
</div>
<div class="modal-body">
	<div *ngIf="destinataire.check.listeErrors?.length">
		<div class="row">
			<div class="col-xs-12">
				<ul class="pull-right actions m-t-5">
					<li><a [routerLink]="[]" (click)="destinataire.isErrorsDisplayed = !destinataire.isErrorsDisplayed"><icon [name]="!destinataire.isErrorsDisplayed ? 'chevron_right' : 'expand_more'"></icon></a></li>
				</ul>
				<icon name="warning" class="c-warning icon-hc-2x pull-left"></icon>
				<h4 translate>notification.selection.destinataire.check.listeErreurs</h4>
			</div>
		</div>
		<div class="card" [collapse]="!destinataire.isErrorsDisplayed">
			<div class="card-body nc-bloc-content-bg card-padding">
				<ul>
					<li *ngFor="let error of destinataire.check.listeErrors">{{ error }}</li>
				</ul>
			</div>
		</div>
		<div class="card" *ngIf="!destinataire.isErrorsDisplayed">
			<div class="card-body nc-bloc-content-bg text-center card-padding">
				<a [routerLink]="[]" (click)="destinataire.isErrorsDisplayed = true">
					<span [translate]="'notification.selection.destinataire.check.afficherElement' | pluralTranslate:destinataire.check.listeErrors.length" [translateParams]="{ nbElements: destinataire.check.listeErrors.length }"></span>
				</a>
			</div>
		</div>
	</div>
	<div *ngIf="destinataire.check.listeEmails?.length">
		<div class="row">
			<div class="col-xs-12">
				<ul class="pull-right actions m-t-5">
					<li><a [routerLink]="[]" (click)="destinataire.isEmailsDisplayed = !destinataire.isEmailsDisplayed"><icon [name]="!destinataire.isEmailsDisplayed ? 'chevron_right' : 'expand_more'"></icon></a></li>
				</ul>
				<icon name="check_circle" class="icon-hc-2x c-success pull-left"></icon>
				<h4 translate>notification.selection.destinataire.check.listeEmails</h4>
			</div>
		</div>
		<div class="card" [collapse]="!destinataire.isEmailsDisplayed">
			<div class="card-body nc-bloc-content-bg card-padding">
				<ul>
					<li *ngFor="let email of destinataire.check.listeEmails">{{ email }}</li>
				</ul>
			</div>
		</div>
		<div class="card" *ngIf="!destinataire.isEmailsDisplayed">
			<div class="card-body nc-bloc-content-bg text-center card-padding">
				<a [routerLink]="[]" (click)="destinataire.isEmailsDisplayed = true">
					<span [translate]="'notification.selection.destinataire.check.afficherElement' | pluralTranslate:destinataire.check.listeEmails.length" [translateParams]="{ nbElements: destinataire.check.listeEmails.length }"></span>
				</a>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
</div>