import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ReferentielEntiteList,TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { StructureExportListItemComponent } from './structure-export-list-item.component';

@Component({
	selector: 'structure-export-list',
	templateUrl: './structure-export-list.component.html'
})
export class StructureExportListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.export.StructureExport';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/StructureExport/filtreStructuresExport/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,StructureExportListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(referentielService: ReferentielService,private translateService: TranslateService,protected rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Définition de la liste
		this.liste = new ListView<any,StructureExportListItemComponent>({
			uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
			title: this.translateService.instant('structureExport.liste.title'),
			component: StructureExportListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'listeSocietes',
				title: this.translateService.instant('search.societe'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			}],
			listeSelectors: !this.rightService.isRoot() && [{
				code: TypeReferentiel.LOCAL,
				libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
				count: () => this.referentiel?.nbItemsForLocal || 0
			},{
				code: TypeReferentiel.STANDARD,
				libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
				count: () => this.referentiel?.nbItems || 0
			}],
			selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
			extraOptions: {
				referentiel: this.referentiel
			},
			hasMainAction: () => this.rightService.hasRight(null,'creation') && (this.liste.selectedSelector == TypeReferentiel.LOCAL || this.rightService.isRoot()),
			doMainAction: () => this.showStructureExport(),
			onRefresh: (liste,result) => {
				//Vérification du type de liste
				if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
					//Mise à jour du nombre d'éléments standards
					this.referentiel.nbItems = result?.totalElements || 0;
				else
					//Mise à jour du nombre d'éléments locaux
					this.referentiel.nbItemsForLocal = result?.totalElements || 0;
			}
		});
	}

	/**
	 * Affichage de la structure d'export
	 */
	showStructureExport(structureExport?): void {
		//Redirection vers la page
		this.layoutService.goToByUrl(`/StructureExport/${this.liste.selectedSelector}/${structureExport?.idStructure || 0}`);
	}
}