import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-performance',
	templateUrl: './modele-performance.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModelePerformanceComponent {
	/** Performances **/
	@Input() performance: any;
}
