import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { CalendarOptions } from 'src/app/domain/calendar/calendar';
import { DashboardItem } from 'src/app/domain/dashboard/dashboard-item';
import { User } from 'src/app/domain/user/user';
import { CalendarService } from 'src/app/share/components/calendar/calendar.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TodoListService } from 'src/app/share/layout/todo-list/todo-list.service';

@Component({
	selector: 'dashboard-calendar',
	templateUrl: './dashboard-calendar.component.html'
})
export class DashboardCalendarComponent extends DashboardItem implements OnInit {
	/** Options du calendrier **/
	calendarOptions: CalendarOptions = null;

	/** Mapping des entités **/
	private mapEntites: { [key: string]: any } = null;

	/** Liste des événements de la journée sélectionnée **/
	private listeEvents: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private store: Store<AppState>,private calendarService: CalendarService,private layoutService: LayoutService,private todoListService: TodoListService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'utilisateur connecté et du mapping des entités
		combineLatest([this.store.select<User>(state => state.session?.user).pipe(first())
			,this.calendarService.retrieveMapping()
		]).subscribe(( [user,mapping] ) => {
			//Définition de la locale de l'utilisateur
			const locale: string = user?.locale?.code?.replace('_','-') || null;

			//Stockage du mapping
			this.mapEntites = mapping;

			//Définition des options du calendrier
			this.calendarOptions = {
				title: this.dashboardChart.customLibelle || this.dashboardChart.calendrier.libelle,
				getListeActions: () => [{
					libelle: this.translateService.instant('actions.personnaliser'),
					doAction: () => {
						let dashboardChart: any;

						//Copie du calendrier
						dashboardChart = cloneDeep(this.dashboardChart);

						//Filtre des entités sur la visibilité
						dashboardChart.calendrier.listeDetails = dashboardChart.calendrier.listeDetails.filter(d => this.mapEntites[d.entite]);

						//Personnalisation du graphique
						this.onCustomize.emit({
							dashboardChart,
							callback: () => {
								//Rechargement de la page
								this.layoutService.reloadSameUrl();
							}
						});
					},
					isVisible: () => this.dashboardChart.position != null,
				},{
					libelle: this.translateService.instant('actions.supprimer'),
					doAction: () => {
						//Suppression du graphique
						this.onDelete.emit(this.dashboardChart);
					},
					isVisible: () => this.isEdition
				}],
				loadCalendar: this.loadCalendar.bind(this),
				loadDayEvents: this.loadDayEvents.bind(this),
				locale,
				onSelect: (idItem: string) => {
					let item: any;
					let selectedEntite: any;
					let routeKeyValue: any;

					//Récupération de l'élément sélectionné sur la base de son identifiant
					item = this.listeEvents?.find(e => e.idItem == idItem);

					//Vérification de la présence de l'élément
					if (item?.data) {
						//Recherche de l'entité selectionnée
						selectedEntite = this.mapEntites[item.type];

						//Vérification du type d'élément
						if (item.type == 'com.notilus.data.todoList.TodoListItem') {
							//Redirection vers la tâche dans la todo-list
							this.todoListService.showTask(item.data.idItem);
						} else if (selectedEntite.route && selectedEntite.routeKey) {
							//Récupération de la valeur de la clé
							routeKeyValue = get(item.data,selectedEntite.routeKey);

							//Vérification du type de page (formulaire dédié)
							if (selectedEntite?.route?.match(/{}$/)) {
								//Navigation vers l'entité
								this.layoutService.goToByUrl(selectedEntite.route.replace(/{}$/,routeKeyValue).replace('#',''));
							} else if (selectedEntite.state) {
								//Navigation vers l'entité (liste)
								this.layoutService.goToByState(selectedEntite.state,{
									savedSearch: {
										extraData: {
											titre: item.title,
											entite: item.type,
											operateur: 'AND',
											_type: 'com.notilus.data.rule.Rule',
											listeDetails : [{
												filter : {
													filter: item.type.split('.').pop(-1).toLowerCase() + '.' + selectedEntite.routeKey,
													listeValues: [{
														type: 'SIMPLE',
														value: item.idObject
													}],
													operateur: 'EQUAL',
													type: 'java.lang.Long',
													typeAgregation: 'NON_DEFINI',
													typeGroupement: null
												}
											}]
										}
									},
									isOpenFirstItem: true,
									reloadOnSameUrl: true
								});
							}
						}
					}
				}
			};
		});
	}

	/**
	 * Chargement du calendrier
	 */
	private loadCalendar(startDate: Date,endDate: Date): Promise<Array<any>> {
		//Création de la promesse
		return new Promise(resolve => {
			let listeDatas = [];
			let listeEntites: Array<string>;

			//Définition de la liste des entités
			listeEntites = this.dashboardChart.calendrier.listeDetails.map(d => d.entite);

			//Filtre des entités sur la visibilité
			listeEntites = listeEntites.filter(e => !!this.mapEntites[e]);

			//Vérification de la liste des entités personnalisées du calendrier
			if (this.dashboardChart.listeEntites?.length > 0) {
				//Filtre des entités
				listeEntites = listeEntites.filter(e => this.dashboardChart.listeEntites.some(entite => entite.entite == e ));

				//Modification des couleurs
				this.dashboardChart.listeEntites.forEach(e => {
					//Mise à jour de la couleur
					(this.mapEntites[e.entite] || {}).couleur = e.couleur;
				});
			}

			//Appel AJAX
			this.calendarService.searchItemsForPeriode(startDate,endDate,listeEntites).subscribe(result => {
				let listeAgregats: Array<any>;

				//Récupération des agrégats
				listeAgregats = result?.data?.listeAgregats?.[0] || [];

				//Parcours des agrégats
				listeAgregats.forEach(date => {
					//Parcours des entités
					date.listeAgregats.forEach(entite => {
						//Vérification de la possibilité de lire le résultat
						if (this.mapEntites[entite.value]) {
							//Ajout à la liste
							listeDatas.push({
								_type: entite.value,
								_count: entite.nbItems,
								start: date.value,
								end: date.value,
								icone: this.mapEntites[entite.value].icone,
								iconeType: this.mapEntites[entite.value].iconeType,
								color: this.mapEntites[entite.value].couleur ? '#' + this.mapEntites[entite.value].couleur : null
							});
						}
					});
				});

				//Mise à jour des données
				resolve(listeDatas);
			});
		});
	}

	/**
	 * Chargement des évènements d'une journée
	 */
	private loadDayEvents(date: number): Promise<Array<any>> {
		let listeEntites: Array<string>;

		//Création de la promesse
		return new Promise(resolve => {
			//Vérification de la date
			if (date) {
				//Définition de la liste des entités
				listeEntites = this.dashboardChart.calendrier.listeDetails.map(d => d.entite);

				//Filtre des entités sur la visibilité
				listeEntites = listeEntites.filter(e => this.mapEntites[e]);

				//Vérification de la liste des entités personnalisées du calendrier
				if (this.dashboardChart.listeEntites?.length > 0)
					//Filtre des entités
					listeEntites = listeEntites.filter(e => this.dashboardChart.listeEntites.some(entite => entite.entite == e ));

				//Appel AJAX
				this.calendarService.searchItemsForDate(date,listeEntites).subscribe(listeItems => {
					let listeEvents = [];

					//Définition de la liste des événements
					listeEvents = listeItems.map(item => {
						//Ajout de l'icône
						item.icone = this.mapEntites[item.type].icone;

						//Ajout de la famille d'icône
						item.iconeType = this.mapEntites[item.type].iconeType;

						//Définition du titre
						item.title = this.calendarService.getTitleForItem(item);

						return item;
					});

					//Mémorisation de la liste des événements affichés
					this.listeEvents = listeEvents;

					//Retour du résultat
					resolve(listeEvents);
				});
			} else
				//Aucun événement
				resolve([]);
		});
	}
}