<form #releveEnergieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>conducteur.releveEnergie.titre</h2>
			</div>
			<div class="card-body card-padding">
				<releve-energie-content [releveEnergie]="releveEnergie" source="CONDUCTEUR"></releve-energie-content>
			</div>
		</div>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: releveEnergieForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>