import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { BytesPipe } from './bytes.pipe';

@NgModule({
	imports: [StoreModule,CoreComponentsModule],
	exports: [BytesPipe],
	declarations: [BytesPipe],
	providers: [BytesPipe]
})
export class BytesModule {}