import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,sortBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { filter,map,switchMap,tap } from 'rxjs/operators';

import { ChartService } from 'src/app/components/chart/chart.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'template-mail-edit',
	templateUrl: './template-mail-edit.component.html'
})
export class TemplateMailEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() templateMail: any;

	/** Suppression du template **/
	@Input() deleteTemplateMail: () => void;

	/** Résumé **/
	@Input() resume: any;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Indicateur de personnalisation du template de mail **/
	@Input() isCustom: boolean;

	/** Liste des entités **/
	@Input() listeBusinessData: Array<any>;

	/** Mémorisation du template avant modifications **/
	savedTemplateMail: any;

	/** Liste des types de diffusion **/
	listeTypesDiffusion: Array<any> = this.chartService.getListeTypesDiffusion();

	/** Liste des types de mails **/
	listeTypesMail: Array<any> = this.templateMailService.getListeTypesMail();

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(private templateMailService: TemplateMailService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private chartService: ChartService,public rightService: RightService,public notificationService: NotificationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Mémorisation du template avant modifications
		this.savedTemplateMail = cloneDeep(this.templateMail);

		//Tri des entités selon leur libellé
		this.listeBusinessData = sortBy(this.listeBusinessData,'libelle');
	}

	/**
	 * Interception du changement de type de mail
	 */
	onTypeMailChange(typeMail: string) {
		let typeMailObject: any;

		//Vérification du nouveau type de mail
		if (typeMail != 'AUTRE' && (typeMailObject = this.listeTypesMail.find(t => t.code == typeMail))) {
			//Mise à jour du template de mail
			this.templateMail.reference = typeMailObject.reference;
			this.templateMail.entite = typeMailObject.entite;
		}
	}

	/**
	 * Enregistrement du template de mail
	 */
	saveTemplateMail() {
		let templateMail;
		let confirmObservable: Observable<boolean>;

		//Vérification de l'élément
		if (this.templateMail) {
			//Parcours des structures
			this.templateMail.listeTemplateStructure?.forEach((structure,idxStructure) => {
				//Définition de la position
				structure.position = idxStructure;
			});
		}

		//Copie du template de mail
		templateMail = cloneDeep(this.templateMail);

		//Vérification de la dépersonnalisation
		if (this.typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine && this.isCustom && this.isCustom != templateMail.custom) {
			//Message de confirmation
			confirmObservable = this.confirmService.showConfirm(this.translateService.instant('actions.enregistrement.confirmationDepersonnalisation'),{ actionColor: 'primary' }).pipe(tap(isConfirmed => {
				//Vérification du rejet
				if (!isConfirmed)
					//Restauration de l'indicateur de personnalisation
					this.templateMail.custom = this.isCustom;
			}));
		} else {
			//Vérification du changement d'entité
			if (templateMail.idTemplateMail > 0 && templateMail.entite != this.savedTemplateMail.entite && (templateMail.listeTemplateStructure?.length > 0 || this.resume.nbDestinataires > 0)) {
				//Message de confirmation
				confirmObservable = this.confirmService.showConfirm(this.translateService.instant('templateMail.enregistrement.confirmationChangementEntite'),{ actionColor: 'primary' }).pipe(map(isConfirmed => {
					//Vérification du rejet
					if (!isConfirmed)
						//Rétablissement de l'entité
						templateMail.entite = this.savedTemplateMail.entite;

					//Sauvegarde du template de mail dans tous les cas
					return true;
				}));
			} else
				//Sauvegarde du template
				confirmObservable = of(true);
		}

		//Enregistrement du template de mail
		confirmObservable.pipe(filter(isConfirmed => !!isConfirmed),switchMap(() => this.templateMailService.saveTemplateMail(templateMail,this.typeReferentiel))).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du template
				this.templateMail = result.data?.templateMail;

				//Récupération de l'indicateur de personnalisation
				this.isCustom = this.templateMail.custom;

				//Suppression du cache sur les notifications
				this.notificationService.resetNotificationsForEntiteCache();

				//Fermeture de l'édition
				this.close({
					templateMail: this.templateMail,
					nbDestinataires: result.data?.nbDestinataires !== undefined ? result.data.nbDestinataires : this.resume.nbDestinataires
				});
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				let listeDoublons: Array<string> = [];

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Vérification de la possibilité de planifier un envoi automatique pour le type de mail
	 */
	canSchedule(): boolean {
		//Retour de la possibilité de planifier un envoi automatique pour le type de mail
		return this.listeTypesMail.find(t => t.code == this.templateMail.type).scheduling;
	}
}