import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { FactureService } from '../facture.service';
import { mapEntites } from './detail.entites';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './detail-list-item.component.html'
})
export class DetailListItemComponent extends ListViewItem<any> implements IListEntity {
	/**
	 * Constructeur
	 */
	constructor(public factureService: FactureService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Accès au formulaire complexe de la facture
	 */
	consultFacture() {
		//Navigation vers la facture
		this.layoutService.goToByState('listeFactures-load',{
			routeParams: {
				idFacture: this.data.facture?.idFacture || 0
			},
			withGoBack: true
		});
	}
}