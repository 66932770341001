<div class="pull-left lv-avatar">{{ data.code?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addAlias?.(data,deleteAlias)">{{ data.code }}</a>
	</div>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.FACTURE_LAD | right:'suppression'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="deleteAlias();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>