import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleFluiditeService } from 'src/app/components/vehicule/grille-fluidite/grille-fluidite.service';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@Component({
	templateUrl: './grille-attribution-modele-financement-list-item.component.html'
})
export class GrilleAttributionModeleFinancementListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur courant **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionService: GrilleAttributionModeleService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private store: Store<AppState>,private grilleFluiditeService: GrilleFluiditeService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteFinancement = this.deleteFinancement.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteFinancement(financement: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.grilleAttributionService.deleteFinancement(financement))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de la grille de fluidité
	 */
	showGrilleFluidite() {
		//Affichage de la grille de fluidité
		this.grilleFluiditeService.showGrilleFluidite(this.data.grilleFluidite).subscribe({
			next: (result: any) => {
				//Vérification de l'indicateur de suppression
				if (result?.isDeleted)
					//Suppression de la grille de fluidité
					this.data.grilleFluidite = null;
			}
		});
	}
}