import { NgModule } from '@angular/core';

import { PluralTranslatePipe } from './plural-translate.pipe';

@NgModule({
	declarations: [PluralTranslatePipe],
	exports: [PluralTranslatePipe],
	providers: [PluralTranslatePipe]
})
export class PluralTranslateModule {}
