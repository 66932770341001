<div class="extraire">
	<ul class="fonction-detail z-depth-1">
		<li class="header">
			<div *ngIf="(null | right:'creation') && !isRestricted" class="actions dropdown pull-right">
				<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li (click)="parent[parentKey] = null;">
						<a [routerLink]="[]" translate>actions.supprimer</a>
					</li>
				</ul>
			</div>
			<h4><span class="fonction-label head" translate>structureExport.fonction.extraire.title</span></h4>
		</li>
		<li class="content">
			<ul>
				<li>
					<div class="fonction-item text-left" *ngIf="!detail.fonction.field && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: detail.fonction,parentKey: 'field' })" translate>common.aDefinir</a></div>
					<div class="fonction-item" *ngIf="!detail.fonction.field && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
					<div *ngIf="detail.fonction.field" class="rubrique-detail">
						<structure-export-rubrique-detail [detail]="detail.fonction.field" [parent]="detail.fonction" [parentKey]="'field'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
					</div>
				</li>
				<li>
					<h4>
						<span class="fonction-label" [tooltip]="'structureExport.fonction.extraire.depuis.tooltip' | translate">
							<span translate>structureExport.fonction.extraire.depuis.item</span>
							<icon name="info_outline" class="m-l-5"></icon>
						</span>
					</h4>
				</li>
				<li>
					<div class="detail-item d-flex justify-content-space-between">
						<span class="flex-2" translate>structureExport.fonction.extraire.position</span>
						<span class="form-group flex-1">
							<div>
								<input type="text" class="form-control" [(ngModel)]="detail.fonction.debut" nNumber/>
							</div>
						</span>
						<span class="flex-3"></span>
					</div>
				</li>
				<li>
					<h4>
						<span class="fonction-label" [tooltip]="'structureExport.fonction.extraire.jusqua.tooltip' | translate">
							<span translate>structureExport.fonction.extraire.jusqua.item</span>
							<icon name="info_outline" class="m-l-5"></icon>
						</span>
					</h4>
				</li>
				<li>
					<div class="detail-item d-flex justify-content-space-between">
						<span class="flex-2" translate>structureExport.fonction.extraire.position</span>
						<span class="form-group flex-1">
							<div>
								<input type="text" class="form-control" [(ngModel)]="detail.fonction.fin" nNumber/>
							</div>
						</span>
						<span class="flex-3"></span>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>