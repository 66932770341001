import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { DemandeCotationCotationListItemComponent } from './demande-cotation-cotation-list-item.component';

@Component({
	selector: 'demande-cotation-cotation-list',
	templateUrl: './demande-cotation-cotation-list.component.html'
})
export class DemandeCotationCotationListComponent extends PageContentItem implements OnInit {
	/** Demande de cotation **/
	@Input() demandeCotation: any;

	/** Identifiant de la cotation **/
	@Input() idCotation: number;

	/** Résumé **/
	@Input() resume: { nbCotations: number };

	/** Consultation d'une cotation **/
	@Input() consultCotation: Function;

	/** Affichage d'une cotation **/
	@Input() showCotation: Function;

	/** Données **/
	liste: ListView<any,DemandeCotationCotationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DemandeCotationCotationListItemComponent>({
			uri: () => `/controller/VehiculeCommande/filtreCotations`,
			component: DemandeCotationCotationListItemComponent,
			isLocal: true,
			listeStaticFilters: [{
				clef: 'demandeCotation.idDemandeCotation',
				valeur: this.demandeCotation.idDemandeCotation,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: 'statut,modele.marque.libelle,modele.libelle,modele.finition',
			onRefresh: (liste,result) => {
				//Mise à jour du résumé
				this.resume.nbCotations = result.totalElements;

				//Vérification de la necessité d'afficher la cotation
				if (this.idCotation) {
					//Affichage de la cotation
					liste.data?.content.length == 1 && liste.data?.content[0].idCotation == this.idCotation && this.showCotation(this.liste.data?.content[0]);

					//Suppression de l'identifiant
					this.idCotation = null;
				}
			},
			extraOptions: {
				resume: this.resume,
				demandeCotation: this.demandeCotation,
				consultCotation: this.consultCotation,
				showCotation: this.showCotation
			}
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idCotation > 0)
			//Mise à jour des compteurs
			this.resume.nbCotations--;
	}
}