import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ColorPickerComponent } from './color-picker.component';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [ColorPickerComponent],
	exports: [ColorPickerComponent]
})
export class ColorPickerModule {}