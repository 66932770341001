import { Component,Input } from '@angular/core';
import { ListView } from 'src/app/domain/common/list-view';

import { LotComptableCheckChargeListItemComponent } from './lot-comptable-check-charge-list-item.component';
import { LotComptableService } from './lot-comptable.service';

@Component({
	selector: 'lot-compable-check-charge',
	templateUrl: './lot-comptable-check-charge.component.html'
})
export class LotComptableCheckChargeComponent {
	/** Nombre d'anomalies **/
	@Input() nbAnomalies: number;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Données **/
	liste: ListView<any,LotComptableCheckChargeListItemComponent>;

	/** Liste des comptes **/
	listeComptes: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private lotComptableService: LotComptableService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckChargeListItemComponent>({
			uri: () => `/controller/LotComptable/filtrePostesChargeForControle/COMPTE_CHARGE`,
			component: LotComptableCheckChargeListItemComponent,
			isLocal: true,
			extraOptions: {
				nbAnomalies: this.nbAnomalies
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: 'libelle,reference',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })],
			onBeforeRefresh: () => {
				//Mémorisation des données
				this.refreshData();
			},
			onRefresh: (liste) => {
				//Vérification du contenu
				if (liste?.data?.content?.length) {
					//Parcours du contenu
					liste.data.content.forEach(item => {
						let compte: any;

						//Recherche du compte associé
						compte = this.listeComptes.filter(c => c.type == item.posteCharge.type && c.idPosteCharge == item.posteCharge.idObject && c.idPlanComptable == item.idPlanComptable)[0];

						//Restauration du compte
						item.compte = compte?.compte;
					});
				}
			}
		});
	}

	/**
	 * Rafraichissement des données
	 */
	refreshData() {
		//Vérification du contenu
		if (this.liste?.data?.content?.length) {
			//Parcours du contenu
			this.liste.data.content.forEach(item => {
				let compte: any;

				//Recherche du compte associé
				compte = this.listeComptes.filter(c => c.type == item.posteCharge.type && c.idPosteCharge == item.posteCharge.idObject && c.idPlanComptable == item.idPlanComptable)[0];

				//Vérification du compte
				if (!compte) {
					//Création du compte
					compte = {
						type: item.posteCharge.type,
						idPosteCharge: item.posteCharge.idObject,
						idPlanComptable: item.idPlanComptable
					};

					//Vérification du compte
					if (item.compte)
						//Ajout du compte à la liste
						this.listeComptes.push(compte);
				}

				//Mise à jour du compte
				compte.compte = item.compte;
			});
		}

		//Retour de la liste des comptes
		return this.listeComptes || [];
	}

	/**
	 * Récupération du nombre de corrections
	 */
	getNbCorrections() {
		//Retour du nombre de corrections
		return this.refreshData().filter(compte => compte.compte).length;
	}

	/**
	 * Réalisation des corrections
	 */
	doCorrection() {
		//Rafraichissement des données
		this.refreshData();

		//Envoi des corrections
		return this.lotComptableService.doPlanComptableCorrection('COMPTE_CHARGE',this.listeComptes.map(c => ({
			idPlanComptable: c.idPlanComptable,
			idObjectToCorrect: c.idPosteCharge,
			idValue: c.compte.idCompte
		})));
	}
}
