import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeAmenagementService } from './type-amenagement.service';

@Component({
	selector: 'type-amenagement-edit',
	templateUrl: './type-amenagement-edit.component.html'
})
export class TypeAmenagementEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() typeAmenagement: any;

	/** Suppression de l'objet **/
	@Input() deleteTypeAmenagement: () => void;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private typeAmenagementService: TypeAmenagementService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveTypeAmenagement() {
		//Enregistrement de l'objet
		this.typeAmenagementService.saveTypeAmenagement(this.typeAmenagement).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.typeAmenagement,result.data?.typeAmenagement);

					//Fermeture du formulaire
					this.close(this.typeAmenagement);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}

	/**
	 * Vérification de l'autorisation d'activation de l'aménagement
	 */
	canActivate() {
		//Vérification de la présence d'un champ actif
		return this.typeAmenagement?.listeFields?.some(field => field.actif);
	}
}