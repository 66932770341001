import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des devises
 */
export default class DeviseOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.devise';

	/**
	 * Récupération de l'URL
	 */
	url(filter?: { referentiel: string }): string {
		//URL
		return `/controller/Devise/listeDevisesActives${filter?.referentiel ? '/' + filter.referentiel : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? (item?.libelle ? `${item.libelle} (${item.code})` : item) : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.code?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Récupération du type de la classe
	 */
	type = 'com.notilus.data.devise.Devise';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idDevise'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'code',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: { idExcludedDevise: number }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idExcludedDevise && {
			clef: 'idDevise',
			listeObjects: [filter.idExcludedDevise],
			typeComparaison: TypeComparaison.NOT_IN,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}
}