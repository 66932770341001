<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.commercialisation.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
					<label translate>vehicule.modele.commercialisation.dateDebut</label>
					<div class="half">
						<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="commercialisationPrix.dateDebut" datePicker format="date"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': prixTTC.invalid }">
					<label translate>vehicule.modele.commercialisation.prixTTC</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="prixTTC" #prixTTC="ngModel" class="form-control" [(ngModel)]="commercialisationPrix.prixTTC" nNumber="2"/>
							<div class="input-group-addon no-border">{{ commercialisationPrix.devise }}</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': datePrix.invalid }">
					<label translate>vehicule.modele.commercialisation.datePrix</label>
					<div class="half">
						<input type="text" class="form-control" name="datePrix" #datePrix="ngModel" [(ngModel)]="commercialisationPrix.datePrix" datePicker format="date"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': montantMensuelAvecCarburant.invalid }">
					<label translate>vehicule.modele.commercialisation.montantMensuelAvecCarburant</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="montantMensuelAvecCarburant" #montantMensuelAvecCarburant="ngModel" class="form-control" [(ngModel)]="commercialisationPrix.avantageNature.montantMensuelAvecCarburant" nNumber="2"/>
							<div class="input-group-addon no-border">{{ commercialisationPrix.devise }}</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': montantMensuelSansCarburant.invalid }">
					<label translate>vehicule.modele.commercialisation.montantMensuelSansCarburant</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="montantMensuelSansCarburant" #montantMensuelSansCarburant="ngModel" class="form-control" [(ngModel)]="commercialisationPrix.avantageNature.montantMensuelSansCarburant" nNumber="2"/>
							<div class="input-group-addon no-border">{{ commercialisationPrix.devise }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
					<label translate>vehicule.modele.commercialisation.dateFin</label>
					<div class="half">
						<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="commercialisationPrix.dateFin" datePicker format="date"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': prixHT.invalid }">
					<label translate>vehicule.modele.commercialisation.prixHT</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="prixHT" #prixHT="ngModel" class="form-control" [(ngModel)]="commercialisationPrix.prixHT" nNumber="2"/>
							<div class="input-group-addon no-border">{{ commercialisationPrix.devise }}</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': taxe.invalid }">
					<label translate>vehicule.modele.commercialisation.taxe</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="taxe" #taxe="ngModel" class="form-control" [(ngModel)]="commercialisationPrix.taxe" nNumber="2" min="0"/>
							<div class="input-group-addon no-border">%</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>