import { Component,Input,ElementRef,ViewChild,Inject,LOCALE_ID,OnInit } from '@angular/core';
import { DatePipe,DecimalPipe,FormatWidth } from '@angular/common';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { Filter,ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ListViewService } from './list-view.service';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { mapDatePatternFormats } from 'src/app/utils/patterns';

@Component({
	selector: '[list-view-search]',
	templateUrl: './list-view-search.component.html'
})
export class ListViewSearchComponent<T extends ListItem,K extends ListViewItem<T>> implements OnInit {
	/** Liste à afficher **/
	@Input() liste: ListView<T,K>;

	/** Liste des types de filtre **/
	TypeFilter: typeof TypeFilter = TypeFilter;

	/** Champ de saisie pour la recherche **/
	@ViewChild('searchInput',{ static: true }) searchInput: ElementRef;

	/** Filtre sélectionnable par un clic sur un lien dans la zone de saisie **/
	filterSelectableWithLink: Filter;

	/** Liste des type des comparaison **/
	listeTypesComparaison: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private listViewService: ListViewService<T>,private translateService: TranslateService,private datePipe: DatePipe,private decimalPipe: DecimalPipe,@Inject(LOCALE_ID) private locale: string) { }

	/**
	 * Ajout d'un filtre
	 * @param event événement
	 */
	addFilter(event: MatChipInputEvent) {
		let value: string;
		let clef: string;
		let valeur: string;
		let position: number;

		//Lecture de la valeur saisie
		value = (event.value || '').trim();

		//Vérification de la valeur
		if (value) {
			//Vérification de la présence des ':'
			if ((position = value.indexOf(':')) != -1) {
				//Découpage de la clef
				clef = value.substring(0,position);

				//Découpage de la valeur
				valeur = value.substring(position + 1);
			} else {
				//Aucune clef
				clef = null;

				//Valeur complète
				valeur = value;
			}

			//Vérification de la présence d'une action sur l'action de recherche
			if (this.liste.searchAction) {
				//Déclenchement de l'action
				this.liste.searchAction(this.liste,valeur);
			} else {
				//Ajout du filtre
				this.liste.listeSelectedFilters.push({
					clef,
					valeur
				});
			}

			//Suppression de la saisie
			event.input.value = null;

			//Rafraichissement de la liste
			!this.liste.searchAction && this.liste.refresh();

			//Scroll vers le haut de la page
			!this.liste.isLocal && window.scrollTo(0,0);
		}
	}

	/**
	 * Suppression de l'élément
	 */
	removeItem(idxItem) {
		//Suppression de l'élément de la liste
		this.liste.listeSelectedFilters.splice(idxItem,1);

		//Rafraichissement de la liste
		this.liste.refresh();
	}

	/**
	 * Suppression des données supplémentaires
	 */
	removeExtraData() {
		//Suppression des données supplémentaires
		this.liste.extraData = null;

		//Rafraichissement de la liste
		this.liste.refresh();
	}

	/**
	 * Initialisation de la page
	 */
	ngOnInit() {
		//Liste de types de comparaison
		this.listeTypesComparaison = Object.values(TypeComparaison).filter(t => ![TypeComparaison.LIKE,TypeComparaison.NOT_EQUALS,TypeComparaison.IS_NULL,TypeComparaison.IS_NOT_NULL,TypeComparaison.IN,TypeComparaison.NOT_IN].includes(t));

		//Récupération d'un éventuel filtre sélectionnable par un clic sur un lien
		this.filterSelectableWithLink = this.liste?.listeFilters?.find(f => !!f.selectFilterLinkTitle);
	}

	/**
	 * Rafraîchissement de la liste
	 */
	refreshData(numPage: number = 0) {
		//Annulation de l'éventuelle requête en cours
		this.liste.refreshDataSubscription?.unsubscribe();

		//Réinitialisation de la souscription
		this.liste.refreshDataSubscription = null;

		//Vérification du type de liste
		if (!this.liste.redirectToList) {
			//Rafraichissement des données avant chargement
			this.liste.onBeforeRefresh?.(this.liste);

			//Vidage de la liste
			this.liste.data = null;

			//Enregistrement des filtres pour la liste
			!this.liste.isLocal && numPage == 0 && this.listViewService.saveListeFilters(this.liste);

			//Chargement de la liste
			this.liste.refreshDataSubscription = this.listViewService.loadData(this.liste,numPage).subscribe({
				next: (data) => {
					//Mise à jour de la liste
					this.liste.data = data;

					//Rafraichissement des données
					this.liste.onRefresh?.(this.liste,data);
				},
				complete: () => {
					//Chargement terminé
					this.liste.refreshDataSubscription = null;
				}
			});
		}
	}

	/**
	 * Mise à jour de la liste des filtres
	 */
	updateListeFilters() {
		let listeFilters: Array<Filter>;

		//Définition de la liste des filtres sélectionnées
		listeFilters = this.liste.listeFilters.filter(filter => filter.isSelected && (filter.valeur || filter.dateDebut || filter.min !== undefined || filter.listeObjects?.length)).map(filter => {
			let displayedValeur: string = '';
			let displayedFormat: string = '';

			//Vérification du type de filtre
			if (filter.valeur) {
				//Vérification du type de filtre
				if (filter.type == TypeFilter[TypeFilter.AUTOCOMPLETE]) {
					//Définition de la valeur à afficher
					displayedValeur = filter.autocomplete.options.displayItem(filter.valeur,this.translateService,this.datePipe);
				} else if (filter.listeValues && filter.type == TypeFilter[TypeFilter.LONG]) {
					//Vérification du type de liste
					if (this.isArray(filter.listeValues))
						//Définition de la valeur à afficher
						displayedValeur = this.translateService.instant(filter.displayedValeur);
					else if (filter.displayedValeur?.length)
						//Définition de la valeur à afficher
						displayedValeur = filter.displayedValeur;
				} else
					//Définition de la valeur à afficher
					displayedValeur = !filter.listeValues ? filter.valeur.toString() : filter.displayedValeur;
			} else if (filter.listeObjects?.length && filter.type == TypeFilter[TypeFilter.AUTOCOMPLETE]) {
				//Définition de la valeur à afficher
				displayedValeur = filter.listeObjects.map(valeur => filter.autocomplete.options.displayItem(valeur,this.translateService,this.datePipe)).join(', ');
			} else if (filter.type == TypeFilter[TypeFilter.DATE]) {
				//Définition du format de la date
				displayedFormat = `short${filter.date?.format == 'datetime' ? '' : (filter.date?.format[0]?.toUpperCase() + filter.date?.format?.substring(1).toLowerCase() || 'Date')}`;

				//Définition de la date de début
				displayedValeur = this.translateService.instant(`liste.filterOperator.female.displayedValue.${filter.typeComparaison}`,{ value: this.datePipe.transform(filter.dateDebut,displayedFormat),min: this.datePipe.transform(filter.dateDebut,displayedFormat),max: this.datePipe.transform(filter.dateFin,displayedFormat) });

				//Vérification du type de filtre
				if (filter.dateDebut && [TypeComparaison.LESS_EQUAL,TypeComparaison.LESS_EQUAL_OR_NULL].indexOf(filter.typeComparaison) != -1)
					//Mise à jour de la date de début
					filter.dateDebut = moment(filter.dateDebut).endOf('day').toDate();
			} else if (filter.type == TypeFilter[TypeFilter.MONTH]) {
				//Définition du format du mois
				displayedFormat = mapDatePatternFormats[this.locale][FormatWidth.Short]['month'];

				//Définition de la valeur
				displayedValeur = this.translateService.instant(`liste.filterOperator.female.displayedValue.${filter.typeComparaison}`,{ value: this.datePipe.transform(filter.dateDebut,displayedFormat),min: this.datePipe.transform(filter.dateDebut,displayedFormat),max: this.datePipe.transform(filter.dateFin,displayedFormat) });
			} else if (filter.type == TypeFilter[TypeFilter.DECIMAL]) {
				//Définition de la valeur décimale
				displayedValeur = this.translateService.instant(`liste.filterOperator.male.displayedValue.${filter.typeComparaison}`,{ value: this.decimalPipe.transform(filter.min,'1.2-2'),min: this.decimalPipe.transform(filter.min,'1.2-2'),max: this.decimalPipe.transform(filter.max,'1.2-2') });
			} else if (filter.type == TypeFilter[TypeFilter.LONG]) {
				//Définition de la valeur entière
				displayedValeur = this.decimalPipe.transform(filter.min,'1.0-0');
			} else if (filter.type == TypeFilter[TypeFilter.PERIOD]) {
				//Définition de la valeur
				displayedValeur = this.translateService.instant(`liste.filterOperator.periode`,{ min: this.datePipe.transform(filter.dateDebut,'short'),max: this.datePipe.transform(filter.dateFin,'short') });
			}

			return {
				...filter,
				displayedValeur
			};
		});

		//Concaténation des filtres (Simple et avancées)
		this.liste.listeSelectedFilters = [...this.liste.listeSelectedFilters.filter(filter => !listeFilters.some(f => f.clef == filter.clef)),...listeFilters];

		//Masquage des filtres
		this.liste.isFilterShown = false;

		//Remise à zéro du numéro de page courant
		this.liste.numPage = 0;

		//Rafraichissement de la liste
		this.liste.refresh();
	}

	/**
	 * Sélection des éléments
	 */
	selectAll(isSelectAll: boolean,isSelectNotVisible?: boolean) {
		//Définition de la liste des éléments sélectionnés
		this.liste.data.content.forEach(d => d.isSelected = isSelectAll);

		//Mise à jour du compteur
		this.liste.nbSelectedItems = this.liste.data.content.filter(i => i.isSelected).length;

		//Vérification de la sélection des éléments invisibles et définition du mode de sélection
		this.liste.typeActionMasse = this.liste.nbSelectedItems > 0 ? (isSelectNotVisible ? 'FULL' : 'SELECTION') : null;
	}

	/**
	 * Page précédente
	 */
	goToPreviousPage() {
		//Vérification de la possibilité d'aller à la page précédente
		if (this.liste.numPage > 0)
			//Rafraichissement de la liste
			this.refreshData(--this.liste.numPage);
	}

	/**
	 * Page suivante
	 */
	goToNextPage() {
		//Vérification de la possibilité d'aller à la page suivante
		if (!this.liste.data.last)
			//Rafraichissement de la liste
			this.refreshData(++this.liste.numPage);
	}

	/**
	 * Vérification de la présence d'un tableau de données ou d'un observable
	 */
	isArray(listeValues: any): boolean {
		//Vérification de la présence d'un tableau de données
		return Array.isArray(listeValues);
	}

	/**
	 * Vérification de la validité du champ de type panier
	 */
	hasBasketError(f: Filter): () => boolean {
		//Retour de la fonction de vérification
		return () => !f.listeObjects?.length;
	}

	/**
	 * Vérification que le filtre fourni est actuellement appliqué à la recherche
	 */
	isFilterActif(filter: Filter): boolean {
		//Retour de la présence du filtre dans la liste de ceux actifs
		return this.liste?.listeSelectedFilters?.some(f => f.clef == filter.clef);
	}
}