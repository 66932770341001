import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';
import { Page } from 'src/app/domain/common/http/list-result';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ServiceMoveComponent } from './service-move.component';

@Injectable()
export class ServiceService {	/**
	/** Liste des types de gestion des dépendances **/
	private readonly listeTypesGestionDependances = ['TOUT_DEPLACER','SELECTION_PARENT','AFFECTER_PARENT'];

	/** Liste des types de service **/
	private readonly listeTypes: Array<string> = ['SOCIETE','SERVICE']

	/** Liste des types de règle de calcul de l'avantage en nature **/
	private readonly listeTypesCalculAvantageNature: Array<string> = [null,'ACHAT_AVEC_CARBURANT','ACHAT_SANS_CARBURANT','LOCATION_AVEC_CARBURANT','LOCATION_SANS_CARBURANT'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService) { }

	/**
	 * Chargement d'un service
	 */
	public loadService(idService: number): Observable<Result> {
		//Chargement d'un service
		return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/loadService/${idService}`,null);
	}

	/**
	 * Enregistrement d'un service
	 */
	public saveService(service: any): Observable<Result> {
		//Enregistrement d'un service
		return this.http.put<Result>(`${environment.baseUrl}/controller/Analytique/saveService`,service);
	}

	/**
	 * Chargement des sous-services
	 */
	public loadListeSousServices(service: any,nbPages: any): Observable<Page<any>> {
		//Chargement des sous-services
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Analytique/listeSousServices/${nbPages}`,service);
	}

	/**
	 * Suppression d'un service
	 */
	public deleteService(service: any): Observable<Result> {
		//Suppression d'un service
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Analytique/deleteService/${service.idService}`);
	}

	/**
	 * Enregistrement d'un alias de société
	 */
	public saveSocieteAlias(alias: any): Observable<Result> {
		//Enregistrement de l'alias
		return this.http.put<Result>(`${environment.baseUrl}/controller/Analytique/saveSocieteAlias`,alias);
	}

	/**
	 * Suppression d'un alias de société
	 */
	public deleteSocieteAlias(alias: any): Observable<Result> {
		//Suppression de l'alias
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Analytique/deleteSocieteAlias/${alias.idAlias}`);
	}


	/**
	 * Déplacement d'un service
	 */
	public moveService(service: any,deplacement: { idParent: number,gestionDependances: 'TOUT_DEPLACER' | 'AFFECTER_PARENT' | 'SELECTION_PARENT',idParentDependances: number }): Observable<Result> {
		let params: URLSearchParams = new URLSearchParams();

		//Définition des paramètres
		params.append('idParent',(deplacement.idParent || 0).toString());
		params.append('gestionDependances',deplacement.gestionDependances || '');
		params.append('idParentDependances',(deplacement.idParentDependances || 0).toString());

		//Déplacement d'un service
		return this.http.post<Result>(`${environment.baseUrl}/controller/Analytique/moveService/${service.idService}`,params,{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}

	/**
	 * Liste des types de gestion des dépendances
	 */
	public getListeTypesGestionDependances(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de gestions des dépendances
		return this.listeTypesGestionDependances.map(code => ({
			code,
			libelle: this.translateService.instant('service.deplacement.typeGestionDependance.' + code)
		}));
	}

	/**
	* Ouverture de la popup de déplacement des services
	*/
	public showMoveService(service: any): Observable<boolean> {
		let bsModalRef: BsModalRef<ServiceMoveComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ServiceMoveComponent,{
			initialState: {
				service: cloneDeep(service),
				listeTypesGestionDependance: this.getListeTypesGestionDependances()
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.isRefreshNeeded),
			filter(isRefreshNeeded => !!isRefreshNeeded)
		);
	}

	/**
	 * Récupération de la liste des types de service
	 */
	public getListeTypes(): Array<{ code: string,libelle: string }> {
		//Liste des types de service
		return this.listeTypes.map(code => ({
			code,
			libelle: this.translateService.instant(`service.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de règle de calcul de l'avantage en nature
	 */
	public getListeTypesCalculAvantageNature(): Array<{ code: string,libelle: string }> {
		//Liste des types de règle de calcul de l'avantage en nature
		return this.listeTypesCalculAvantageNature.map(code => ({
			code,
			libelle: this.translateService.instant(`service.typeCalculAvantageNature.${code || 'AUCUNE'}`)
		}));
	}
}