import { Component,Input } from '@angular/core';
import { ListView } from 'src/app/domain/common/list-view';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { LotComptableCheckPlanComptableListItemComponent } from './lot-comptable-check-plan-comptable-list-item.component';
import { LotComptableService } from './lot-comptable.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LotComptableCheckComponent } from './lot-comptable-check.component';

@Component({
	selector: 'lot-compable-check-plan-comptable',
	templateUrl: './lot-comptable-check-plan-comptable.component.html'
})
export class LotComptableCheckPlanComptableComponent {
	/** Nombre d'anomalies **/
	@Input() nbAnomalies: number;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Données **/
	liste: ListView<any,LotComptableCheckPlanComptableListItemComponent>;

	/**
	 * Constructeur
	*/
	constructor(private lotComptableService: LotComptableService,private layoutService: LayoutService,private bsModalRef: BsModalRef<LotComptableCheckComponent>) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckPlanComptableListItemComponent>({
			uri: () => `/controller/LotComptable/filtreSocieteWithoutPlanComptable`,
			component: LotComptableCheckPlanComptableListItemComponent,
			isLocal: true,
			extraOptions: {
				nbAnomalies: this.nbAnomalies
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			defaultOrder: 'libelle',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })]
		});
	}

	/**
	 * Génération du plan comptable et redirection vers le plan comptable
	 */
	generatePlanComptable() {
		//Génération du plan comptable
		this.lotComptableService.generatePlanComptableForSelection(this.selectionItems).subscribe(result => {
			//Fermeture de la pop-up
			this.bsModalRef.hide();

			//Navigation vers le plan comptable
			this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
				routeParams: {
					idPlanComptable: 0
				},
				routeData: {
					planComptable: result.data.planComptable
				}
			});
		});
	}
}
