import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { UpgradeLogComponent } from './upgrade-log.component';
import { UpgradeListItemComponent } from './upgrade-list-item.component';
import { UpgradeListComponent } from './upgrade-list.component';
import { UpgradeComponent } from './upgrade.component';
import { UpgradeService } from './upgrade.service';


@NgModule({
	imports: [ShareModule],
	declarations: [UpgradeListComponent,UpgradeListItemComponent,UpgradeComponent,UpgradeLogComponent],
	providers: [UpgradeService]
})
export class UpgradeModule {}