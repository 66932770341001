<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data}">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/GrilleAttribution/loadGrilleAttribution/',data.idGrilleAttribution]">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
</div>
<ul class="lv-attrs" *ngIf="data.idGrilleAttribution">
	<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	<li><span translate>vehicule.grilleAttribution.nbVehicules</span>&#160;:&#160;<span>{{ (data.nbModeles || 0) | number }}</span></li>
	<li *ngIf="!data?.specifique" [tooltip]="getListeGroupes('com.notilus.data.user.User')"><span translate>vehicule.grilleAttribution.nbGroupesCollaborateur</span>&#160;:&#160;<span>{{ ((data.listeGroupes | filterBy:{ entity:'com.notilus.data.user.User' })?.length || 0) | number }}</span></li>
	<li *ngIf="!data?.specifique" [tooltip]="getListeGroupes('com.notilus.data.service.Service')"><span translate>vehicule.grilleAttribution.nbGroupesService</span>&#160;:&#160;<span>{{ ((data.listeGroupes | filterBy:{ entity:'com.notilus.data.service.Service' })?.length || 0) | number }}</span></li>
	<li *ngIf="data?.specifique"><span translate>vehicule.grilleAttribution.specifique</span>&#160;:&#160;<span [translate]="'common.'+(data?.specifique ? 'oui' : 'non')"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idGrilleAttribution && !liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteGrilleAttribution();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>