<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.reference | slice:0:3 }}</div>
<div class="lv-title">
	<a [routerLink]="['/Groupe/loadGroupe/',data.idGroupe]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
	<li *ngIf="data.entity"><span translate>groupe.entity</span>&#160;:&#160;<span>{{ entiteService.translateEntityCode(data.entity.split('.').pop(-1)) }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteGroupe();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>