import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { CompteAuxiliaireService } from 'src/app/components/comptabilite/compte-auxiliaire/compte-auxiliaire.service';

@Component({
	templateUrl: './plan-comptable-compte-auxiliaire-list-item.component.html',
})
export class PlanComptableCompteAuxiliaireListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private compteAuxiliaireService: CompteAuxiliaireService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression d'un compte auxiliaire
	 */
	deleteCompteAuxiliaire() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteAuxiliaireService.deleteCompteAuxiliaire(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression du compte auxiliaire
				this.onObjectChange(null);
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Accès au compte auxiliaire
	 */
	showCompteAuxiliaire() {
		//Redirection vers le compte auxiliaire
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptesAuxiliaires-compteAuxiliaire',{
			routeParams: {
				idCompteAuxiliaire: this.data?.idCompteAuxiliaire || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au compte collectif
	 */
	showCompte() {
		//Redirection vers le compte
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptes-compte',{
			routeParams: {
				idCompte: this.data?.compte?.idCompte || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.comptabilite.CompteAuxiliaire',this.data.idCompteAuxiliaire);
	}
}