import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { CompteServicePasswordComponent } from './compte-service-password.component';

@Injectable()
export class CompteServiceService {
	/** Liste des types de périmètre **/
	private listeTypesPerimetre = ['ROOT','CLIENT'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService) { }

	/**
	 * Récupération de la liste des types de périmètre traduits
	 */
	public getListeTypesPerimetre() {
		//Récupération de la liste des types de périmètre traduits
		return this.listeTypesPerimetre.map(code => ({
			code,
			libelle: this.translateService.instant('compteService.typePerimetre.'+code)
		}));
	}

	/**
	 * Chargement du compte de service
	 */
	public loadCompteService(idCompteService: number): Observable<Result>  {
		//Chargement du compte de service
		return this.http.post<Result>(`${environment.baseUrl}/controller/CompteService/loadCompte/${idCompteService}`,null);
	}

	/**
	 * Enregistrement d'un compte de service
	 */
	public saveCompteService(compteService: any): Observable<Result>  {
		//Enregistrement d'un compte de service
		return this.http.put<Result>(`${environment.baseUrl}/controller/CompteService/saveCompte`,compteService);
	}
	/**
	 * Suppression du compte de service
	 */
	public deleteCompteService(compteService: any): Observable<Result> {
		//Suppression du compte de service
		return this.http.delete<Result>(`${environment.baseUrl}/controller/CompteService/deleteCompte/${compteService.idCompteService}`);
	}

	/**
	 * Affichage du mot de passe du compte de service
	 */
	public showPassword(user: any,password: string): Observable<any> {
		let bsModalRef: BsModalRef<CompteServicePasswordComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(CompteServicePasswordComponent,{
			initialState: {
				user,
				password
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
}