<div class="container-fluid">
	<form #taxeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>fiscalite.taxe.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="taxe.pays" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>fiscalite.taxe.dateApplication</label>
								<div class="half">
									<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="taxe.date" datePicker format="date" [storeDate]="true" required/>
								</div>
							</div>
						</div>
					</div>
						<div class="form-group">
							<label translate>fiscalite.taxe.remarque</label>
							<div>
								<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="taxe.remarque" rows="3"></textarea>
							</div>
						</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteTaxe();" *ngIf="taxe?.idTaxe && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveTaxe(taxe,close);" *ngIf="null | right:'creation'" [disabled]="taxeForm.invalid">
				<span [translate]="'actions.'+(taxe?.idTaxe ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="taxe?.idTaxe" [formDetectChanges]="{ doAction: close,isDirty: taxeForm.dirty }" translate>
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>