import { Component,EventEmitter,Input,Output } from '@angular/core';

@Component({
	selector: 'structure-export-rubrique-fonction-concatener',
	templateUrl: './structure-export-rubrique-fonction-concatener.component.html'
})
export class StructureExportRubriqueFonctionConcatenerComponent {
	/** Détail **/
	@Input() detail: any;

	/** Parent **/
	@Input() parent: any;

	/** Clé du parent **/
	@Input() parentKey: string;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Changement du type de rubrique **/
	@Output() onTypeRubriqueChange : EventEmitter<{ parent: any,parentKey: string }> = new EventEmitter<{ parent: any,parentKey: string }>();

	/**
	 * Ajout d'un élément à la liste
	 */
	addItem() {
		//Ajout d'un nouvel élément à la liste
		this.detail.fonction.listeDetails.push({});
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Suppression d'un élément
	 */
	removeItem(idxItem: number) {
		//Suppression de l'élément
		this.detail.fonction.listeDetails.splice(idxItem,1);
	}
}