import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { MailHistoryDetailComponent } from './mail-history-detail.component';
import { MailHistoryListItemComponent } from './mail-history-list-item.component';
import { MailHistoryComponent } from './mail-history.component';
import { MailHistoryService } from './mail-history.service';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule],
	declarations: [MailHistoryComponent,MailHistoryListItemComponent,MailHistoryDetailComponent],
	exports: [MailHistoryComponent,MailHistoryListItemComponent,MailHistoryDetailComponent],
	providers: [MailHistoryService]
})
export class MailHistoryModule {}