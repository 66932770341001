import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ExtractionEditComponent } from './extraction-edit.component';
import { ExtractionFieldEditComponent } from './extraction-field-edit.component';
import { ExtractionFieldListComponent } from './extraction-field-list.component';
import { ExtractionEnteteListComponent } from './extraction-entete-list.component';
import { ExtractionRuleComponent } from './extraction-rule.component';
import { ExtractionListItemComponent } from './extraction-list-item.component';
import { ExtractionListComponent } from './extraction-list.component';
import { ExtractionComponent } from './extraction.component';
import { ExtractionDiffusionListComponent } from './extraction-diffusion-list.component';
import { ExtractionDiffusionListItemComponent } from './extraction-diffusion-list-item.component';
import { ExtractionDiffusionEditComponent } from './extraction-diffusion-edit.component';
import { ExtractionDiffusionContentComponent } from './extraction-diffusion-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ExtractionListComponent,ExtractionListItemComponent,ExtractionComponent,ExtractionEditComponent,ExtractionRuleComponent,ExtractionFieldListComponent,ExtractionFieldEditComponent,ExtractionDiffusionListComponent,ExtractionDiffusionListItemComponent,ExtractionDiffusionEditComponent,ExtractionDiffusionContentComponent,ExtractionEnteteListComponent],
	exports: [ExtractionListComponent,ExtractionListItemComponent,ExtractionDiffusionContentComponent]
})
export class ExtractionModule {}