<div class="modal-header">
	<h4 class="modal-title" translate>demandeVehicule.vehicule.informationsVehicule.title</h4>
</div>
<div class="modal-body">
	<form #vehiculeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(TypeDroit.ADMIN_VEHICULE | right:'creation') || demandeVehicule.statut == 'LIVRE'">
			<div class="form-group" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') && demandeVehicule.statut != 'LIVRE'">
				<label translate>vehicule.immatriculation.item</label>
				<div>
					<a [routerLink]="[]" (click)="showImmatriculation()">{{ vehicule.reference }}</a>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.capaciteReservoir</label>
				<div class="half">
					<input type="text" name="capaciteReservoir" class="form-control" [(ngModel)]="vehicule.capaciteReservoir" nNumber/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': critAirField.invalid }" *ngIf="configurationPays?.critAir">
				<label translate>vehicule.critAir.item</label>
				<div>
					<selectpicker name="critAir" #critAirField="ngModel" [(ngModel)]="vehicule.critAir">
						<mat-option *ngFor="let critAir of listeTypesCritAir" [value]="critAir.code">{{ critAir.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.numeroInterne</label>
				<div>
					<input type="text" name="numeroInterne" class="form-control" [(ngModel)]="vehicule.numeroInterne" maxlength="100"/>
				</div>
			</div>
		</fieldset>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide();">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="vehiculeForm.invalid" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') && demandeVehicule.statut != 'LIVRE'" (click)="saveVehicule()">
		<span translate>actions.enregistrer</span>
	</button>
</div>