<form #workflowDetailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail workflow global-actions" [ngClass]="{ small: isSmall,edition: isEdition }">
		<div class="global-actions-container d-flex justify-content-space-between flex-direction-row-reverse" scrollFixed>
			<div class="d-flex">
				<button mat-icon-button (click)="isSmall = !isSmall">
					<mat-icon>{{ 'zoom_' + (isSmall ? 'in' : 'out') }}</mat-icon>
				</button>
				<button mat-icon-button (click)="toggleFullScreen()">
					<mat-icon>fullscreen</mat-icon>
				</button>
				<button mat-icon-button [disabled]="isEdition && workflowDetailForm.invalid" (click)="isEdition ? saveWorkflow() : addWorkflowDetail()">
					<mat-icon>{{ isEdition ? 'check' : 'edit' }}</mat-icon>
				</button>
			</div>
			<button mat-button class="m-l-10" *ngIf="isEdition" [formDetectChanges]="{ doAction: close,isDirty: isDirty(),doSave: saveWorkflow }">
				<span translate>actions.annuler</span>
			</button>
		</div>
		<table [cellPadding]="0" [cellSpacing]="0" class="initial">
			<tbody class="statut" *ngFor="let statut of workflow.listeStatuts; let first = first; let last = last; let index = index">
				<!-- Début du Workflow - Titre -->
				<workflow-detail-title *ngIf="first" [entite]="workflow.entite" mode="start"></workflow-detail-title>

				<!-- Ligne horizontale -->
				<workflow-detail-line mode="horizontal" [isChild]="false"></workflow-detail-line>

				<!-- Etape -->
				<ng-container *ngIf="!statut.isCollapsed">
					<workflow-detail-step *ngFor="let etape of statut.branche.listeEtapes; let index = index" [workflow]="workflow" [etape]="etape" [currentBranche]="statut.branche" [index]="index" [isChild]="false" [isEdition]="isEdition"></workflow-detail-step>
				</ng-container>

				<!-- Bouton -->
				<workflow-detail-button *ngIf="!statut.isCollapsed && !last" [workflow]="workflow" [currentBranche]="statut.branche"></workflow-detail-button>

				<!-- Ligne horizontale -->
				<workflow-detail-line *ngIf="!statut.isCollapsed" mode="horizontal" [isChild]="false"></workflow-detail-line>

				<!-- Compteur -->
				<workflow-detail-line *ngIf="statut.isCollapsed" mode="counter" [listeEtapes]="statut.branche?.listeEtapes" [isChild]="false" [index]="index"></workflow-detail-line>

				<!-- Fin du Workflow - Titre -->
				<workflow-detail-title *ngIf="last" [entite]="workflow.entite" mode="end"></workflow-detail-title>

				<!-- Ligne verticale de fin -->
				<workflow-detail-line *ngIf="last" mode="vertical" [isChild]="false"></workflow-detail-line>

				<!-- Container du statut -->
				<tr class="statut-container">
					<td>
						<div class="statut" *ngIf="!last && workflow.listeStatuts.length > 2" (click)="statut.isCollapsed = !statut.isCollapsed">
							<icon [name]="!statut.isCollapsed ? 'expand_less' : 'expand_more'"></icon>
							<span>{{ statut.statut }}</span>
						</div>
						<div class="statut" *ngIf="!last && workflow.listeStatuts.length <= 2">
							<span>{{ statut.statut }}</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</form>
