import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ControleReglementaireListItemComponent } from './controle-reglementaire-list-item.component';
import { ControleReglementaireListComponent } from './controle-reglementaire-list.component';
import { ControleReglementaireComponent } from './controle-reglementaire.component';
import { ControleReglementaireEditComponent } from './controle-reglementaire-edit.component';
import { ControleReglementaireRuleComponent } from './controle-reglementaire-rule.component';
import { ControleReglementaireService } from './controle-reglementaire.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ControleReglementaireListComponent,ControleReglementaireListItemComponent,ControleReglementaireComponent,ControleReglementaireEditComponent,ControleReglementaireRuleComponent],
	providers: [ControleReglementaireService]
})
export class ControleReglementaireModule { }