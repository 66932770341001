import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RuleService } from 'src/app/share/components/rule/rule.service';

@Component({
	selector: 'extraction-rule',
	templateUrl: './extraction-rule.component.html'
})
export class ExtractionRuleComponent extends PageContentItem implements OnInit {
	/** Extraction **/
	getExtraction: () => any;

	/** Récupération de la règle **/
	getRule: () => any;

	/** Mode 'Edition' */
	isEdition: boolean;

	/** Règle courante **/
	rule: any;

	/** Extraction courante **/
	extraction: any;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService,private extractionService: ExtractionService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la règle
		this.rule = this.getRule?.();

		//Récupération de l'extraction
		this.extraction = this.getExtraction?.();
	}

	/**
	 * Enregistrement de la rule
	 */
	saveRule() {
		//Mise à jour de la règle
		this.rule = this.ruleService.updateRuleForSave(this.rule);

		//Vérification de la règle
		if (this.rule) {
			//Mise à jour de la règle
			this.extraction = Object.assign(this.extraction,{ rule: this.rule.listeDetails?.length ? this.rule : null });

			//Enregistrement de l'extraction
			this.extractionService.saveExtraction(this.extraction).subscribe({
				next: (result: Result) => {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Retour de l'extraction
						this.close(Object.assign(this.extraction,result.data.extraction));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			})
		}
	}
}