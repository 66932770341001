<div class="modal-header">
	<h4 class="modal-title" translate>authentification.configuration.liste.title</h4>
</div>
<div class="modal-body">
	<div class="listview lv-bordered">
		<div class="lv-body">
			<div *ngIf="listeConfigurations?.length">
				<authentification-configuration-list-item *ngFor="let configuration of listeConfigurations; let idxConfiguration = index" [configuration]="configuration" [idTenant]="idTenant" (onObjectChange)="onObjectChange(idxConfiguration,$event)" (onRemove)="removeItem(idxConfiguration)" [disabled]="disabled"></authentification-configuration-list-item>
			</div>
			<div class="lv-item media text-center m-t-20 lv-item-add clickable" matRipple *ngIf="(null | right:'creation') && !disabled" (click)="addConfiguration()">
				<h5><a [routerLink]="[]" translate>authentification.configuration.actions.ajouter</a></h5>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="saveListeConfigurations()" *ngIf="(null | right:'creation') && !disabled">
		<span translate>actions.enregistrer</span>
	</button>
</div>