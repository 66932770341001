<div *ngIf="chatbotService.isDisplayed()" class="toolbox-modal chatbot visible" [ngClass]="{ 'minimized': isMinimized,'large': rightService.isRoot() || rightService.isRoot(true) }" cdkDrag [cdkDragDisabled]="isMinimized">
	<div class="header">
		<div class="title" cdkDragHandle>
			<span translate>chatbot.title</span>
			<icon name="close" (click)="chatbotService.toggleDisplay()"></icon>
			<icon [name]="!isMinimized ? 'minimize' : 'web_asset'" class="option-window" [ngClass]="{ 'minimize': !isMinimized }" (click)="toggleWindow();"></icon>
		</div>
		<div class="clearfix"></div>
	</div>
	<div class="body" *ngIf="!isMinimized" #scroller [ngClass]="{ 'has-content': listeExchanges?.length > 1 }">
		<div class="lv-item media">
			<div class="bubble bot">
				<div translate>chatbot.exchange.disclaimer.line1</div>
				<div class="m-t-10 m-b-10"><icon name="warning" class="c-warning m-r-5"></icon><span translate>chatbot.exchange.disclaimer.line2</span><icon name="warning" class="c-warning m-l-5"></icon></div>
				<div translate>chatbot.exchange.disclaimer.line3</div>
				<div class="m-t-10">
					<a [routerLink]="[]" (click)="openConfidentialiteUrl()" translate>chatbot.confidentialite.link</a>&nbsp;-&nbsp;<a [routerLink]="[]" (click)="openCGU()" translate>chatbot.cgu.link</a>
				</div>
			</div>
		</div>
		<div class="lv-item media" *ngFor="let item of listeExchanges" #message>
			<div *ngIf="!item.finished">
				<div class="bubble" [ngClass]="{ 'bot': item.type == 'BOT','user': item.type == 'USER' }">
					<span *ngIf="item.text">{{ item.text }}</span>
					<markdown *ngIf="item.markdown" [data]="item.markdown"></markdown>
					<div class="actions" *ngIf="item.listeActions?.length">
						<div class="btn-group" *ngFor="let action of item.listeActions">
							<button class="btn" (click)="action.onClick()">{{ action.title }}</button>
						</div>
					</div>
					<display-more [listeItems]="item.listeLinks" displayMode="COMPACT" nbItems="5" #displayMore>
						<ul *ngIf="item.listeLinks">
							<li *ngFor="let link of displayMore.getSlice()">
								<a [routerLink]="[]" (click)="link.onClick()">{{ link.text }}</a>
							</li>
						</ul>
					</display-more>
				</div>
			</div>

			<div class="rating bubble bot" *ngIf="item.finished">
				<div translate>chatbot.satisfaction.title</div>
				<div class="text-center">
					<span *ngFor="let star of item.listeStars; let index = index">
						<a [routerLink]="[]" (click)="updateRating(item,index)">
							<icon [name]="star.filled ? 'star' : 'star_outline'"></icon>
						</a>
					</span>
				</div>
			</div>
			<div class="clearfix"></div>
			<div class="comment-container bubble bot" *ngIf="item.rated">
				<div>
					<a class="p-l-10 pull-right" *ngIf="item.commented" [routerLink]="[]" (click)="item.commented = false;"><icon class="icon-hc-lg" name="edit" iconClass="m-t-5"></icon></a>
					<span translate>chatbot.satisfaction.commentaire.item</span>
				</div>
				<div *ngIf="!item.commented" class="tips" translate>chatbot.satisfaction.commentaire.envoyer</div>
				<form *ngIf="!item.commented">
					<textarea elasticTextarea rows="3" class="form-control comment-textarea" name="commentaire" [(ngModel)]="item.comment" (keypress)="sendComment($event,item)"></textarea>
				</form>
				<div *ngIf="item.commented">
					<span class="bold p-t-10">{{ item.comment }}</span>
				</div>
			</div>
		</div>
		<div class="text-center p-10" *ngIf="isLoading">
			<h2><icon name="more_horiz" iconClass="animated infinite fadeInLeft" class="icon-hc-fw"></icon></h2>
		</div>
	</div>
	<div class="footer" *ngIf="!isMinimized">
		<form #messageForm="ngForm" class="new-text" (submit)="sendMessage(newMessage);" autocomplete="off">
			<div class="form-group">
				<div>
					<div class="input-group">
						<textarea rows="2" class="form-control" name="newMessage" [placeholder]="'chatbot.placeholder.item' | translate:{ question: exampleQuestion }" [disabled]="isInputDisabled" #newMessageField="ngModel" [(ngModel)]="newMessage" required maxlength="500" pattern="[^\s]*" (keydown.enter)="!isInputDisabled && sendMessage(newMessage); $event.preventDefault();"></textarea>
						<div class="input-group-addon no-border">
							<a [routerLink]="[]" (click)="!isInputDisabled && sendMessage(newMessage);"><icon name="send" [ngClass]="{ 'disabled': isInputDisabled }"></icon></a>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>