import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';

@Injectable({
	providedIn: 'root'
})
export class LoggedUserService {
	/** Utilisateur connecté **/
	private user: User;

	/** Utilisateur d'origine connecté **/
	private userOrigine: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>) {
		//Sélection de l'utilisateur connecté et de celui d'origine
		this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session?.user,userOrigine: state.session?.userOrigine })).subscribe({
			next: ({ user,userOrigine }) => {
				//Définition des utilisateurs
				this.user = user;
				this.userOrigine = userOrigine;
			}
		});
	}

	/**
	 * Récupération de l'utilisateur
	 */
	getUser(): User { return this.user; }

	/**
	 * Récupération de l'utilisateur d'origine
	 */
	getUserOrigine(): User { return this.userOrigine; }

	/**
	 * Récupération de la devise de l'utilisateur connecté
	 */
	getDevise(user: User = this.user) {
		//Retour de la devise de l'utilisateur
		return user?.devise || 'EUR';
	}

	/**
	 * Récupération de la locale de l'utilisateur connecté
	 */
	getLocale(user: User = this.user): string {
		//Retour de la locale de l'utilisateur
		return user?.locale?.code?.replace('_','-') || 'fr-FR';
	}

	/**
	 * Vérification de la présence de restriction sur le profil de l'utilisateur connecté
	 */
	hasProfilRestriction(typeRestriction: TypeRestriction): boolean {
		//Vérification de l'existence d'un profil avec restriction pour l'utilisateur
		return this.getUser()?.listeUserProfil?.some(lien => lien?.profil?.typeProfil == 'WEB' && lien?.profil?.typeRestriction == typeRestriction);
	}
}