<form #conducteurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>conducteur.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
					<label translate>conducteur.add.collaborateur</label>
					<div>
						<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="conducteur.user" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
					<label translate>conducteur.add.dateDebut</label>
					<div>
						<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="conducteur.dateDebut" datePicker required [maxDate]="conducteur.dateFin"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
					<label translate>conducteur.add.dateFin</label>
					<div>
						<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="conducteur.dateFin" datePicker [minDate]="conducteur.dateDebut"/>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label translate>conducteur.add.remarque</label>
			<div>
				<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="conducteur.remarque" rows="3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="conducteurForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>