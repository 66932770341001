import { Injectable,Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subscriber } from 'rxjs';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentComponent } from './page-content.component';

@Injectable()
export class PageContentService {
	/** Liste des containers **/
	private mapContents: { [key: string]: { pageContentComponent?: PageContentComponent,subscriber?: Subscriber<any>,onClose?: () => void,allowSkipCloseConfirm?: boolean,disableClose?: (() => boolean) } } = {};

	/** Mode actuellement ouvert **/
	private currentOpenedMode: string = null;

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService) { }

	/**
	 * Initialisation du composant
	 */
	onInit(pageContentComponent: PageContentComponent) {
		//Mise à jour du mode ouvert
		this.currentOpenedMode = null;

		//Mise à jour des containers
		this.mapContents[pageContentComponent.mode] = {
			pageContentComponent
		};
	}

	/**
	 * Destruction du composant
	 */
	onDestroy(pageContentComponent: PageContentComponent) {
		//Mise à jour des containers
		this.mapContents[pageContentComponent.mode] = {
			pageContentComponent: null
		};
	}

	/**
	 * Ouverture d'un composant
	 */
	open(type: Type<PageContentItem>,options: { data: any,onClose?: () => void,allowSkipCloseConfirm?: boolean,disableClose?: (() => boolean) },mode: string = 'default'): Observable<any> {
		let openMode: () => void;

		//Création d'un observable
		return new Observable((subscriber: Subscriber<any>) => {
			//Ouverture du mode
			openMode = () => {
				//Définition du mode ouvert
				this.currentOpenedMode = mode;

				//Définition de la méthode de fermeture
				this.mapContents[mode].onClose = options.onClose;

				//Définition de la vérification
				this.mapContents[mode].allowSkipCloseConfirm = options?.allowSkipCloseConfirm || false;
				this.mapContents[mode].disableClose = options?.disableClose || (() => false);

				//Affichage du composant
				this.mapContents[mode].pageContentComponent.toggleView(type,{
					...options,
					close: (data?: any) => this.close(data,mode)
				});

				//Vérification de l'état de l'instance
				if (this.mapContents[mode] != null)
					//Enregistrement du subscriber
					this.mapContents[mode].subscriber = subscriber;
			}

			//Vérification de l'ouverture d'un mode
			if (this.currentOpenedMode) {
				//Vérification de la demande de confirmation
				if (!this.mapContents[this.currentOpenedMode]?.allowSkipCloseConfirm) {
					//Demande de confirmation
					this.confirmService.showConfirm(this.translateService.instant('actions.edition.changeContent'),{ actionColor: 'primary' }).subscribe({
						next: isConfirmed => {
							//Vérification de l'acceptation
							if (isConfirmed) {
								//Fermeture du mode actuel
								this.close(null,this.currentOpenedMode);

								//Ouverture du mode
								openMode();
							} else {
								//Emission d'une erreur
								subscriber.error('cancel');

								//Fermeture de la souscription
								subscriber.unsubscribe();
							}
						}
					})
				} else {
					//Fermeture du mode actuel
					this.close(null,this.currentOpenedMode);

					//Ouverture du mode
					openMode();
				}
			} else
				//Ouverture du mode
				openMode();

			//Navigation vers le haut de l'écran
			window.scrollTo(0,0);
		});
	}

	/**
	 * Fermeture du composant
	 */
	close(data: any,mode: string = 'default'): boolean {
		//Fermeture du mode
		this.currentOpenedMode = null;

		//Vérification du contenu
		if (this.mapContents[mode] && !this.mapContents[mode]?.disableClose()) {
			//Envoi d'un évenement
			this.mapContents[mode].subscriber.next(data);

			//Fermeture du composant
			this.mapContents[mode].pageContentComponent.toggleView();

			//Finalisation du subscriber
			this.mapContents[mode].subscriber.complete();

			//Réinitialisation du subscriber
			this.mapContents[mode].subscriber = null;

			//Déclenchement du callback de fermeture
			this.mapContents[mode].onClose?.();

			//Navigation vers le haut de l'écran
			window.scrollTo(0,0);

			//Fermeture réussie
			return true;
		} else
			//Fermeture stoppée
			return false;
	}

	/**
	 * Vérification du mode ouvert
	 */
	isOpened(mode: 'default' | 'sub' | string = 'sub'): boolean {
		//Vérification du mode ouvert
		return this.currentOpenedMode == mode;
	}

	/**
	 * Récupération du mode ouvert actuel
	 */
	getCurrentOpenedMode(): string {
		//Retour du mode ouvert actuel
		return this.currentOpenedMode;
	}
}
