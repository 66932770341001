import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TemplateMailDestinataireEditComponent } from './template-mail-destinataire-edit.component';
import { TemplateMailDestinataireListItemComponent } from './template-mail-destinataire-list-item.component';
import { TemplateMailDestinataireListComponent } from './template-mail-destinataire-list.component';
import { TemplateMailEditComponent } from './template-mail-edit.component';
import { TemplateMailListItemComponent } from './template-mail-list-item.component';
import { TemplateMailListComponent } from './template-mail-list.component';
import { TemplateMailPreviewSelectionComponent } from './template-mail-preview-selection.component';
import { TemplateMailRubriqueListEditComponent } from './template-mail-rubrique-list-edit.component';
import { TemplateMailRubriqueListComponent } from './template-mail-rubrique-list.component';
import { TemplateMailComponent } from './template-mail.component';

@NgModule({
	imports: [ShareModule],
	declarations: [TemplateMailListComponent,TemplateMailListItemComponent,TemplateMailComponent,TemplateMailEditComponent,TemplateMailPreviewSelectionComponent
		,TemplateMailDestinataireListComponent,TemplateMailDestinataireListItemComponent,TemplateMailDestinataireEditComponent
		,TemplateMailRubriqueListComponent,TemplateMailRubriqueListEditComponent
	]
})
export class TemplateMailModule { }