<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idCours">
		<div class="pull-left lv-avatar">{{ data?.devise?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ '1 ' + data.devise + ' (' + data.deviseObject?.libelle + ') = ' + (data.cours | number) + ' ' + data.deviseContrepartie + ' (' + data.deviseContrepartieObject?.libelle + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idCours">
		<li><span translate>devise.cours.dateChange</span>&#160;:&#160;<span>{{ data.dateEchange | date:'shortDate' }}</span></li>
		<li><span translate>devise.cours.source.libelle</span>&#160;:&#160;<span>{{ ('devise.cours.source.'+data.source?.toUpperCase() | translate) + ' (' + data.source + ')' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idCours">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteCours();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.devise.Cours',data?.idCours)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<cours [cours]="data" (onObjectChange)="onObjectChange($event)"></cours>
</div>