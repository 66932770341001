<div class="container access-forbidden">
	<form>
		<div class="card focus">
			<div class="card-body card-padding">
				<div class="alert alert-warning">
					<icon name="lock"></icon>
					<div>
						<strong translate>accessForbidden.alerte.title</strong>
						<div translate>accessForbidden.alerte.message</div>
					</div>
				</div>

				<div class="d-flex justify-content-center flex-direction-column align-items-center">
					<icon name="lock" class="icon-hc-16x"></icon>
					<div class="text-center">
						<h1 translate>accessForbidden.erreur.title</h1>
						<h2 translate>accessForbidden.erreur.message</h2>
					</div>
				</div>
			</div>
		</div>
		<div class="text-center">
			<button mat-button (click)="goBack()">
				<span translate>accessForbidden.actions.revenirAccueil</span>
			</button>
		</div>
	</form>
</div>