import { Directive,Input,OnChanges } from '@angular/core';
import { AbstractControl,NG_VALIDATORS,ValidationErrors,Validator } from '@angular/forms';

@Directive({
	selector: '[validator]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: ValidatorDirective,
		multi: true
	}]
})
export class ValidatorDirective implements Validator,OnChanges {
	/** Indicateur de validité de l'élément **/
	@Input() validator: boolean;

	/** Contrôle **/
	control: AbstractControl;

	/**
	 * Validation du champ
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		//Définition du control
		this.control = control;

		//Retour de la règle de validation
		return !this.validator && ({
			validator: true
		}) || null;
	}

	/**
	 * Ecoute des changements
	 */
	ngOnChanges(): void {
		//Mise à jour du validator (et des autres composants)
		this.control?.updateValueAndValidity();
	}
}