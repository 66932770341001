<div class="modal-header">
	<h4 class="modal-title" translate>pays.listeScopes</h4>
</div>
<div class="modal-body">
	<div class="profil">
		<div class="liste_menu">
			<li *ngFor="let scope of listeScopes">
				<div class="menu">
					<span class="pull-left clickable" (click)="scope.isSelected = !scope.isSelected" [translate]="'pays.scope.'+scope.scope"></span>
					<div class="pull-right">
						<mat-checkbox [(ngModel)]="scope.isSelected"></mat-checkbox>
					</div>
				</div>
			</li>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="save()">
		<span translate>actions.confirmer</span>
	</button>
</div>