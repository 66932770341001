import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

import { AdvancedSearchOptions,AggregatedResponse } from 'src/app/domain/advanced-search/advanced-search';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { AdvancedSearchService } from './advanced-search.service';

@Component({
	templateUrl: './advanced-search.component.html'
})
export class AdvancedSearchComponent implements OnInit {
	/** Indicateur de chargement **/
	public isSearching: boolean = false;

	/** Options de la recherche avancée **/
	public options: AdvancedSearchOptions;

	/** Indicateur d'initialisation **/
	public isInit: boolean;

	/** Critères de recherche générés par la recherche avancée **/
	searchSpec: any;

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<AdvancedSearchComponent>,private advancedSearchService: AdvancedSearchService,private store: Store<AppState>,private translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(take(1)).subscribe(user => {
			//Mise à jour de l'utilisateur
			this.user = user;
		});

		//Vérification du besoin d'initialiser
		if (this.isInit) {
			//Initialisation
			this.options.totalHits = null;
			this.options.currentHits = 0;

			//Lancement de la recherche
			this.loadData();

			//Parcours des filtres
			this.options.listeFiltres.forEach(filtre => {
				//Vérification de la présence d'une liste de clefs
				if (filtre.listeKeys) {
					//Définition de la première clef par défaut
					filtre.aggregateKey = filtre.listeKeys[0].aggregateKey;
					filtre.searchKey = filtre.listeKeys[0].searchKey;
					filtre.subType = filtre.listeKeys[0].subType;
				}

				//Ajout des libellés dans le filtre
				this.translateFilter(filtre);
			});
		}
	}

	/**
	 * Fermeture de la popup
	 */
	close() {
		//Fermeture de la popup
		this.bsModalRef.hide();
	}

	/**
	 * Ré-initilisation des filtres
	 */
	resetListeFiltres() {
		//Parcours des filtres
		this.options.listeFiltres.forEach(filtre => {
			//Réinitialisation du filtre
			this.clearFiltre(filtre);

			//Indicateur de remise à zéro du filtre
			filtre.isClearingAllFilters = true;
		});

		//Lancement de la recherche
		this.loadData(true);
	}

	/**
	 * Affichage des éléments liés à la recherche avancée
	 */
	public display() {
		let searchSpec: any;

		//Création de la recherche
		searchSpec = this.advancedSearchService.makeSearchSpec(this.options);

		//Définition du résultat
		this.bsModalRef.content.searchSpec = searchSpec;

		//Retour des éléments sélectionnés
		this.bsModalRef.hide();
	}

	/**
	 * Vérification de la présence d'un filtre sélectionné
	 */
	public hasOneFilterSelected(): boolean {
		//Parcours des filtres
		return this.options.listeFiltres.some(filtre => {
			//Vérification de la sélection d'un filtre
			return filtre.listeSelectedValues != undefined && filtre.listeSelectedValues.length
				|| filtre.sliderOptions?.min && filtre.sliderOptions.min != filtre.sliderOptions.options.floor
				|| filtre.sliderOptions?.max && filtre.sliderOptions.max != filtre.sliderOptions.options.ceil;
		});
	}

	/**
	 * Sélection d'un filtre
	 */
	public toggleFiltre(filtre: any) {
		let isOpening;

		//Récupération de l'action sur le filtre
		isOpening = !filtre.isToggled;

		//Vérification de l'action
		if (isOpening)
			//Fermeture de toutes les sections
			this.options.listeFiltres.forEach(filtre => filtre.isToggled = false);

		//Modification de l'état
		filtre.isToggled = !filtre.isToggled
	}

	/**
	 * Réinitialisation des filtres d'une section
	 */
	public clearFiltre(filtre: any) {
		//Fermeture de la section
		filtre.isToggled = false;

		//Suppression des valeurs sélectionnées
		filtre.listeSelectedValues = null;

		//Vérification des extrémas
		if (filtre.sliderOptions) {
			//Retrait des extrémas
			filtre.sliderOptions.min = filtre.sliderOptions.options.floor;
			filtre.sliderOptions.max = filtre.sliderOptions.options.ceil;
		}

		//Lancement de la recherche
		this.loadData();
	}

	/**
	 * Vérification de l'affichage du libellé complémentaire
	 */
	public canShowComplementaryLabel(filtre: any) {
		//Vérification de l'utilisation du slider
		return filtre.sliderOptions && filtre.sliderOptions.options.ceil != null && filtre.sliderOptions.options.floor != null && (filtre.sliderOptions.max != filtre.sliderOptions.options.ceil || filtre.sliderOptions.min != filtre.sliderOptions.options.floor) && filtre.subType;
	}

	/**
	 * Sélection d'une valeur d'un filtre
	 */
	public selectValue(filtre: any,value: any) {
		//Vérification du tableau
		filtre.listeSelectedValues = filtre.listeSelectedValues || [];

		//Vérification du type de filtre
		switch (filtre.type) {
			case 'label':
				//Vérification de la présence de la valeur dans les valeurs selectionnées
				if (filtre.listeSelectedValues.indexOf(!value.isMissingValue ? value.libelle : '') != -1)
					//Suppression de la valeur dans le tableau
					filtre.listeSelectedValues.splice(filtre.listeSelectedValues.indexOf(!value.isMissingValue ? value.libelle : ''),1);
				else
					//Ajout dans les valeurs sélectionnées
					filtre.listeSelectedValues.push(!value.isMissingValue ? value.libelle : '');
				break;
			case 'boolean':
				//Définition de la valeur
				filtre.listeSelectedValues = [value];
				break;
		}

		//Rechargement des données
		this.loadData();
	}

	/**
	 * Définition des libellés du filtre
	 */
	private translateFilter(filtre: any) {
		//Titre de la section
		filtre.libelleSection = this.translateService.instant('searchEngine.section.' + filtre.libelleType);

		//Vérification du type de filtre
		if (filtre.type == 'autocomplete') {
			//Libellé de recherche
			filtre.libelleRecherche = this.translateService.instant('searchEngine.libelleRecherche.' + filtre.libelleType);

			//Libellé de la sélection
			filtre.libelleSelection = this.translateService.instant('searchEngine.libelleSelection.' + filtre.libelleType);
		}
	}

	/**
	 * Chargement des données
	 */
	public loadData(resetTotalHits?: boolean) {
		let isFirstSearch: boolean;
		let isValuesEmpty: boolean;
		let keyPrefix: string;
		let keySuffix: string;
		let isCustomPercent: boolean;

		//Vérification du premier chargement
		isFirstSearch = this.options.totalHits == null;

		//Indicateur de chargement
		this.isSearching = true;

		//Chargement des données
		this.advancedSearchService.loadData(this.options).subscribe({
			next: (result: AggregatedResponse) => {
				//Recherche finie
				this.isSearching = false;

				//Vérification du retour
				if (result) {
					//Initialisation des compteurs
					this.options.currentHits = result.totalHits;

					//Vérification du compteur global
					if (isFirstSearch || resetTotalHits)
						//Définition du nombre total d'éléments
						this.options.totalHits = this.options.currentHits;

					//Vérification des agrégats
					if (result.listeAggregats) {
						//Parcours des agrégats
						result.listeAggregats.forEach(agregat => {
							let clef: string;
							let filtre: any;
							let options: any;

							//Annulation du suffixe
							keySuffix = null;
							isCustomPercent = null;

							//Vérification de la liste
							if (agregat?.length) {
								//Lecture de la clef d'agrégat
								clef = agregat[0].clef;

								//Récupération du préfixe
								keyPrefix = clef.split('-')[0];

								//Vérification de la clé
								if (clef.split('-').length > 1)
									//Séparation de la clé
									keySuffix = clef.split('-')[1];

								//Vérification de la clef
								if (clef.split('-').length > 2)
									//Lecture de la présence d'un pourcentage personnalisé
									isCustomPercent = clef.split('-')[2] == 'PERCENT';

								//Recherche du filtre correspondant à la clef
								filtre = this.options.listeFiltres.filter(f => f.aggregateKey == keyPrefix)[0];

								//Vérification de la présence d'un filtre
								if (filtre) {
									//Vérification de la présence de valeurs
									isValuesEmpty = !filtre.listeValues;

									//Vérification du premier lancement
									if (isFirstSearch || isValuesEmpty)
										//Initialisation de la liste des valeurs
										filtre.listeValues = [];

									//Vérification de la valeur numérique
									if (agregat[0].numericValue != null) {
										//Vérification de la première recherche
										if (isFirstSearch || !filtre.sliderOptions || !filtre.sliderOptions.min || !filtre.sliderOptions.max) {
											//Vérification du type de nmérique
											if (keySuffix == 'MINIMUM') {
												//Vérification du slider
												filtre.sliderOptions = filtre.sliderOptions || {};

												//Définition du minimum
												filtre.sliderOptions.min = agregat[0].numericValue;

												//Vérification des options
												options = filtre.sliderOptions.options || {};

												//Définition du seuil minimum
												options.floor = agregat[0].numericValue;

												//Définition du pourcentage personnalisé
												filtre.sliderOptions.isCustomMinimum = isCustomPercent;

												//Définition des options
												filtre.sliderOptions.options = options;
											} else if (keySuffix == 'MAXIMUM') {
												//Vérification du slider
												filtre.sliderOptions = filtre.sliderOptions || {};

												//Définition du maximum
												filtre.sliderOptions.max = agregat[0].numericValue;

												//Vérification des options
												options = filtre.sliderOptions.options || {};

												//Définition du seuil maximum
												options.ceil = agregat[0].numericValue;

												//Définition du pourcentage personnalisé
												filtre.sliderOptions.isCustomMaximum = isCustomPercent;

												//Définition des options
												filtre.sliderOptions.options = options;
											}
										}
									} else {
										//Parcours des agrégats
										agregat.forEach(bucket => {
											//Vérification du premier lancement
											if (isFirstSearch || isValuesEmpty) {
												//Ajout des valeurs dans le filtre
												filtre.listeValues.push({
													libelle: bucket.value || filtre.libelleMissing,
													isMissingValue: !bucket.value && !!filtre.libelleMissing
												});
											}
										});
									}
								}
							}
						})
					}
				}
			},
			error: () => {
				//Recherche finie
				this.isSearching = false;
			}
		});
	}

	/**
	 * Réactivation de la recherche lors de la sortie de saisie de valeurs du slider
	 */
	public onSliderOptionBlur(type?: any,filter?: any) {
		//Vérification du type de slider mis à jour
		if (type == 'min')
			//Vérification du minimum saisi
			filter.sliderOptions.min = filter.sliderOptions.min < filter.sliderOptions.options.floor ? filter.sliderOptions.options.floor : (filter.sliderOptions.min > filter.sliderOptions.ceil ? filter.sliderOptions.ceil : filter.sliderOptions.min);
		else
			//Vérification du maximum saisi
			filter.sliderOptions.max = filter.sliderOptions.max < filter.sliderOptions.floor ? filter.sliderOptions.floor : (filter.sliderOptions.max > filter.sliderOptions.options.ceil ? filter.sliderOptions.options.ceil : filter.sliderOptions.max);

		//Lancement de la recherche
		this.loadData();
	}

	/**
	 * Changement d'un critère de sélection
	 */
	public onCriteriaItemChange(filtre?: any,item?: any) {
		//Vérification de la présence d'un changement
		if (filtre.subType != item.subType) {
			//Définition du filtre
			filtre.aggregateKey = item.aggregateKey;
			filtre.searchKey = item.searchKey;
			filtre.subType = item.subType;

			//Réinitialisation du slider
			filtre.sliderOptions = null;

			//Lancement de la recherche
			this.loadData();
		}
	}

	/**
	 * Détection de l'ajout d'un élément dans le panier autocomplete
	 */
	public onAutocompleteItemAdded() {
		//Lancement de la recherche
		this.loadData();
	}

	/**
	 * Détection de la suppression d'un élément du panier autocomplete
	 */
	public onAutocompleteItemRemoved() {
		//Lancement de la recherche
		this.loadData();
	}

	/**
	 * Compteur du nombre de valeur sur les filtres
	 */
	public countUsedValuesForFilter(filtre: any) {
		let counter: number = 0;

		//Vérification du type de filtre
		if (filtre.type == 'numeric') {
			//Vérification des paramètres du slider
			counter += filtre.sliderOptions?.options?.floor != filtre.sliderOptions?.min ? 1 : 0;
			counter += filtre.sliderOptions?.options?.ceil != filtre.sliderOptions?.max ? 1 : 0;
		} else
			//Définition du compteur
			counter = filtre.listeSelectedValues?.length || 0;

		return counter;
	}
}