import { Component,EventEmitter,Input,OnInit,Output,ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover'

import { RollingPeriodSelectorService } from './rolling-period-selector.service';

@Component({
	selector: 'rolling-period-selector',
	templateUrl: './rolling-period-selector.component.html'
})
export class RollingPeriodSelectorComponent implements OnInit {
	/** Temporal sélectionné **/
	@Input() temporal: string;

	/** Valeur courante **/
	@Input() value: number;

	/** Etat **/
	@Input() disabled?: boolean;

	/** Classe **/
	@Input() customClass: any;

	/** Gestion des modifications de la période **/
	@Output() onRollingPeriodChange: EventEmitter<{ temporal: string,value: number }> = new EventEmitter<{ temporal: string,value: number }>();

	/** Popover **/
	@ViewChild('popover') popover: PopoverDirective;

	/** Liste des périodes disponibles **/
	listeTemporals: Array<{ code: string,libelle: string }>;

	/** Données sauvegardées **/
	savedData: { temporal: string,value: number };

	/**
	 * Constructeur
	 */
	constructor(private rollingPeriodSelectorService: RollingPeriodSelectorService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des temporals
		this.listeTemporals = this.rollingPeriodSelectorService.getListeTemporals();

		//Mémorisation des données
		this.savedData = { temporal: this.temporal,value: this.value };
	}

	/**
	 * Récupération du libellé pour la valeur sélectionnée
	 */
	getLabelForValue(): string {
		//Formattage du libellé
		return this.rollingPeriodSelectorService.formatValue({ temporal: this.temporal,value: this.value });
	}

	/**
	 * Validation de la sélection
	 */
	select() {
		//Mémorisation des données
		this.savedData = { temporal: this.temporal,value: this.value };

		//Mise à jour des données
		this.onRollingPeriodChange.emit({
			temporal: this.temporal,
			value: this.value
		});

		//Fermeture de la popover
		this.popover.hide();
	}

	/**
	 * Fermeture de la sélection
	 */
	cancel() {
		//Restauration des données
		this.temporal = this.savedData.temporal;
		this.value = this.savedData.value;

		//Fermeture de la popover
		this.popover.hide();
	}
}