import { DatePipe,DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit,Component,ElementRef,EventEmitter,HostBinding,Input,OnChanges,OnInit,Output,SimpleChanges,forwardRef } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { some } from 'lodash-es';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable,Observer } from 'rxjs';
import { debounceTime,filter,finalize,map,switchMap,take,tap } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { AutocompleteOptions } from './autocomplete';
import { AutocompleteService } from './autocomplete.service';

@Component({
	selector: 'autocomplete',
	exportAs: 'autocomplete',
	templateUrl: './autocomplete.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AutocompleteComponent),
		multi: true
	}]
})
export class AutocompleteComponent implements OnInit,ControlValueAccessor,AfterViewInit,OnChanges {
	/** Options de l'autocomplete **/
	@Input() options: AutocompleteOptions;

	/** Type générique d'autocomplete **/
	@Input() type: string;

	/** Filtres de l'autocomplete **/
	@Input() filter?: any;

	/** Placeholder **/
	@Input() placeholder: string = '';

	/** Libellé du champ **/
	@Input() label: string;

	/** Caractère actif du champ **/
	@Input() disabled?: boolean;

	/** Interdiction de créer un élément à la volée **/
	@Input() isCreationDisabled?: boolean = false;

	/** Sélection d'un élément **/
	@Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

	/** Remise à zéro de la sélection **/
	@Output() onReset: EventEmitter<any> = new EventEmitter<any>();

	/** Sélection d'un élément issu d'une création à la volée **/
	@Output() onSelectFromCreation: EventEmitter<any> = new EventEmitter<any>();

	/** Indicateur de focus **/
	@HostBinding('class.focused')
	isFocused: boolean = false;

	/** Données recherchée **/
	searchedValue: string;

	/** Suggestions **/
	suggestions$?: Observable<Array<any>>;

	/** Indicateur de chargement **/
	isLoading: boolean = false;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private http: HttpClient,private autocompleteService: AutocompleteService,private translateService: TranslateService,private rightService: RightService,private datePipe: DatePipe,private decimalPipe: DecimalPipe) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification des données
		if (!this.options && !this.type)
			//Déclenchement d'une exception
			throw 'Le type ou les options doivent être renseignés';

		//Lecture des options si nécessaire
		this.options = this.options || this.autocompleteService.getOptionsForType(this.type);

		//Définition de la liste des suggestions
		this.suggestions$ = new Observable((observer: Observer<string | undefined>) => observer.next(this.searchedValue)).pipe(
			debounceTime(200),
			tap(() => {
				//Indicateur de chargement
				this.isLoading = true;
			}),
			switchMap(query => {
				//Vérification de la recherche
				if (query?.length > 0) {
					//Vérification du pourcentage
					if (query.startsWith('%'))
						//Remplacement du pourcentage
						query = query.replace(/%/g,'*');

					//Réalisation de la recherche
					return this.http.post(environment.baseUrl + (typeof this.options.url == 'function' && this.options.url(this.filter) || this.options.url),{
						listeFilter: [{
							clef: this.options.listeFilters(this.filter).filter(f => f.isDefault).map(f => f.clef).join(','),
							valeur: `${query}*`,
							...(some(this.options.listeFilters(this.filter),f => !!f.mapEncryptedFields) ? {
								mapEncryptedFields: Object.assign({},...this.options.listeFilters(this.filter).map(f => f.mapEncryptedFields || {}))
							} : {})
						},...(this.options.listeStaticFilters?.(this.filter) || [])],
						defaultOrder: this.options.defaultOrder || this.options.listeFilters(this.filter).filter(f => f.isDefault).map(f => f.clef).join(',')
					}).pipe(
						map((result: Page<any>) => {
							let listeResults: Array<any>;

							//Lecture du résultat
							listeResults = result?.content || [];

							//Parcours des résultats
							listeResults.map(i => {
								//Ajout du libellé à afficher
								i._label = this.options.displayItem(i,this.translateService,this.datePipe,this.decimalPipe);

								return i;
							});

							//Vérification de la possibilité de créer un élément à la volée
							if (!this.isCreationDisabled && this.options.creationRight && this.rightService.hasRight(this.options.creationRight,'creation'))
								//Ajout de la possibilité de créer
								listeResults.push('');

							//Retour des résultats
							return listeResults;
						}),
						finalize(() => {
							//Fin du chargement
							this.isLoading = false;
						})
					)
				} else
					//Aucun résultat
					return null;
			})
		);
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;
		});
	}

	/**
	 * Sélection d'une suggestion
	 */
	onSuggestionSelect(match?: TypeaheadMatch) {
		//Définition de l'élément
		this.value = match?.item || null;

		//Vérification de la présence d'un élément
		if (match?.item)
			//Notification de sélection de l'objet
			this.onSelect?.emit(match.item);
		else
			//Notification d'une déselection
			this.onReset?.emit(null);
	}

	/**
	 * Vérification du focus
	 */
	onFocus() {
		//Ajout du focus
		this.isFocused = true;
	}

	/**
	 * Vérification du changement de valeur de la saisie
	 */
	onBlur($event) {
		//Retrait du focus
		this.isFocused = false;

		//Vérification de la valeur saisie
		if (!$event.target.value)
			//Réinitialisation du modèle
			this.onSuggestionSelect(null);
	}

	/**
	 * Détection d'un changement sur les entrées
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la présence d'un changement de type
		if (changes?.type?.previousValue) {
			//Vérification de la présence d'un changement de valeur
			if (this.type != null)
				//Mise à jour des options
				this.options = this.autocompleteService.getOptionsForType(this.type);

			//Mise en cycle
			this.isInit && setTimeout(() => {
				//Réinitialisation des valeurs
				this.searchedValue = null;
				this.value = null;
			});
		}
	}

	/**
	 * Affichage de la popup de recherche
	 */
	showSearch() {
		//Affichage de la popup
		!this.elementRef.nativeElement.closest('fieldset')?.disabled && !this.disabled && this.autocompleteService.showSearch({
			label: this.label || this.translateService.instant(this.options.title),
			options: this.options,
			filter: this.filter
		}).subscribe({
			next: (item) => {
				//Vérification de l'élément sélectionné
				if (item) {
					//Mise à jour de l'option
					this.value = item;

					//Mise à jour de la suggestion
					this.searchedValue = this.options.displayItem(item,this.translateService,this.datePipe,this.decimalPipe);

					//Notification de sélection de l'objet
					this.onSelect?.emit(item);
				}
			}
		});
	}

	/**
	 * Affichage de la création d'un élément à la volée
	 */
	showCreation() {
		//Affichage de la popup de création d'un nouvel élément
		this.autocompleteService.showCreation(this.options,null,this.filter).pipe(take(1),filter(item => !!item)).subscribe(item => {
			//Mise à jour de l'option
			this.value = item;

			//Mise à jour de la suggestion
			this.searchedValue = this.options.displayItem(item,this.translateService,this.datePipe,this.decimalPipe);

			//Notification de sélection de l'objet
			this.onSelect?.emit(item);

			//Notification de la création à la volée
			this.onSelectFromCreation?.emit(item);
		});
	}

	/**
	 * Remise à zéro des données
	 */
	public reset() {
		//Réinitialisation du modèle
		this.onSuggestionSelect(null);
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(value: any) {
		//Mise à jour de la valeur
		this.value = value;

		//Mise à jour de la suggestion
		this.searchedValue = this.options.displayItem(value,this.translateService,this.datePipe,this.decimalPipe);
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);
		}
	}
}