import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import moment from 'moment';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeComparaison,TypeFilter,Filter } from 'src/app/domain/common/list-view';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './conducteur-affectation-list-item.component.html'
})
export class ConducteurAffectationListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/** Date actuelle **/
	today: moment.Moment = moment.utc();

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private affectationService: AffectationService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private layoutService: LayoutService,private datePipe: DatePipe,private vehiculeService: VehiculeService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idAffectation,object?.idAffectation && !this.data?.idAffectation);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteAffectation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.affectationService.deleteAffectation(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'affectation depuis la liste
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Accès à la liste des échéances des affectations
	 */
	goToAffectationEcheances(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeAffectationEcheances',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'affectation.idAffectation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.idAffectation,
					title: this.translateService.instant('vehicule.affectation.avantageNature.title',{ reference: affectation.vehicule.reference + (affectation.vehicule.numeroInterne ? ' - ' + affectation.vehicule.numeroInterne : '') + (affectation.user ? ' (' + affectation.user.nom + ' ' + affectation.user.prenom + ')' : '') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Accès à la réservation
	 */
	goToReservation(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeReservations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idReservation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.reservation.idReservation,
					title: this.translateService.instant('reservation.affectation',{ dateDebut: this.datePipe.transform(affectation.dateDebut,'short') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Redirection vers le planning
	 */
	goToPlanning(affectation: any = this.data) {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'une réervation et d'un pool
		if (affectation.vehicule?.pool?.idPool && affectation.reservation) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + affectation.vehicule.pool.libelle + ' (' + affectation.vehicule.pool.reference + ')'
			});
		}

		//Navigation vers le planning
		this.vehiculeService.goToPlanning(affectation.vehicule,{ listeSelectedFilters },null,{ initialDate: affectation.dateDebut });
	}

	/**
	 * Accès au véhicule
	 */
	showVehicule(vehicule: any) {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: vehicule.idVehicule || 0
			},
			withGoBack: true
		});
	}
}