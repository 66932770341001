import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { UserImputationListItemComponent } from './user-imputation-list-item.component';

@Component({
	selector: 'user-imputation-list',
	templateUrl: './user-imputation-list.component.html'
})
export class UserImputationListComponent extends PageContentItem implements OnInit {
	/** Utilisateur courant **/
	@Input() user: any;

	/** Fonction d'édition **/
	@Input() addImputation: () => void;

	/** Résumé **/
	@Input() resume: { nbImputations: number };

	/** Premère imputation de l'utilisateur **/
	protected firstImputation: any;

	/** Données **/
	liste: ListView<any,UserImputationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UserImputationListItemComponent>({
			uri: `/controller/User/filtreImputations`,
			component: UserImputationListItemComponent,
			isLocal: true,
			defaultOrder: '-dateApplication',
			extraOptions: {
				addImputation: this.addImputation,
				firstImputation: this.firstImputation
			},
			listeFilters: [{
				clef: 'service',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'service'
				}
			},{
				clef: 'societe',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			}],
			listeStaticFilters: [{
				clef: 'user.idUser',
				valeur: this.user.idUser,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idImputation > 0)
			//Mise à jour des compteurs
			this.resume.nbImputations--;
	}
}