import { Observable } from 'rxjs';

/**
 * Interface d'une action de la demande de véhicule
 */
export interface Action {
	/** Données **/
	icon: string,
	libelle: string,
	doAction: () => Observable<any>
}

/**
 * Enumération des types d'action
 */
export enum TypeAction {
	/** Enumération **/
	CREATE_DEMANDE_COTATION = 'CREATE_DEMANDE_COTATION',
	SELECT_COTATION = 'SELECT_COTATION',
	SELECT_FINANCEMENT = 'SELECT_FINANCEMENT',
	CREATE_FINANCEMENT = 'CREATE_FINANCEMENT'
}

/**
 * Liste des statuts
 */
export const listeStatuts: Array<{ code: string,icone: string,iconeType?: string }> = [{
	code: 'EN_COURS',
	icone: 'edit'
},{
	code: 'EN_COMMANDE',
	icone: 'shopping_basket'
},{
	code: 'LIVRE',
	icone: 'check'
},{
	code: 'REJETEE',
	icone: 'close'
},{
	code: 'A_VALIDER',
	icone: 'help_outline'
},{
	code: 'VALIDEE',
	icone: 'assignment_turned_in'
},{
	code: 'ANNULEE',
	icone: 'block'
}];