<form #authentificationConfigurationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>authentification.configuration.title</h4>
	</div>
	<div class="modal-body">
		<authentification-configuration-content [configuration]="configuration" [idTenant]="idTenant" [disabled]="disabled"></authentification-configuration-content>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveConfiguration()" [disabled]="authentificationConfigurationForm.invalid" *ngIf="(null | right:'creation') && !disabled">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>