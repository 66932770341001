import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { GrilleFluiditeDetailComponent } from './grille-fluidite-detail.component';
import { GrilleFluiditeImportComponent } from './grille-fluidite-import.component';
import { GrilleFluiditeComponent } from './grille-fluidite.component';
import { GrilleFluiditeService } from './grille-fluidite.service';

@NgModule({
	imports: [ShareModule],
	declarations: [GrilleFluiditeImportComponent,GrilleFluiditeComponent,GrilleFluiditeDetailComponent],
	providers: [GrilleFluiditeService]
})
export class GrilleFluiditeModule {}