import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { OptionListComponent } from './option-list.component';
import { ConfigurationModule } from '../configuration/configuration.module';
import { OptionComponent } from './option.component';
import { OptionService } from './option.service';
import { OptionListItemComponent } from './option-list-item.component';

@NgModule({
	imports: [ShareModule,ConfigurationModule],
	declarations: [OptionComponent,OptionListComponent,OptionListItemComponent],
	providers: [OptionService]
})
export class OptionModule { }