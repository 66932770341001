import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './profil-user-list-item.component.html'
})
export class ProfilUserListItemComponent extends ListViewItem<any> {
	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Redirection vers l'élément
	 */
	goToUser() {
		//Navigation vers l'élément
		this.layoutService.goToByState('listeUsers-user',{
			routeParams: {
				idUser: this.data.idUser
			},
			withGoBack: true
		});
	}
}