import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ProfilService } from './profil.service';
import { ProfilListItemComponent } from './profil-list-item.component';
import { ProfilListComponent } from './profil-list.component';
import { ProfilComponent } from './profil.component';
import { ProfilEditComponent } from './profil-edit.component';
import { ProfilDroitComponent } from './profil-droit.component';
import { ProfilUserListComponent } from './profil-user-list.component';
import { ProfilUserListItemComponent } from './profil-user-list-item.component';
import { ProfilDashboardListComponent } from './profil-dashboard-list.component';
import { ProfilDashboardListItemComponent } from './profil-dashboard-list-item.component';
import { ProfilExtractionListComponent } from './profil-extraction-list.component';
import { ProfilExtractionListItemComponent } from './profil-extraction-list-item.component';
import { ProfilExtractionEditComponent } from './profil-extraction-edit.component';
import { ExtractionModule } from '../extraction/extraction.module';

@NgModule({
	imports: [ShareModule,ExtractionModule],
	declarations: [ProfilListComponent,ProfilListItemComponent,ProfilComponent,ProfilEditComponent,ProfilDroitComponent,ProfilUserListComponent,ProfilUserListItemComponent,ProfilDashboardListComponent,ProfilDashboardListItemComponent,ProfilExtractionListComponent,ProfilExtractionListItemComponent,ProfilExtractionEditComponent],
	providers: [ProfilService]
})
export class ProfilModule { }