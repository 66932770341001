import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { PleaseWaitModalComponent } from './please-wait-modal.component';
import { PleaseWaitComponent } from './please-wait.component';
import { PleaseWaitService } from './please-wait.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [PleaseWaitModalComponent,PleaseWaitComponent],
	exports: [PleaseWaitModalComponent,PleaseWaitComponent],
	providers: [PleaseWaitService]
})
export class PleaseWaitModule {}