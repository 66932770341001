import { FournisseurContactAddComponent } from 'src/app/components/fournisseur/fournisseur-contact-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { FournisseurContactSearchItemComponent } from './fournisseur-contact.search-item';

/**
 * Options des contacts des fournisseurs
 */
export default class FournisseurContactOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.fournisseurContact';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Fournisseur/filtreContactsActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.nom} ${item?.prenom} (${item?.fournisseur.libelle})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.nom?.substring(0,1).toUpperCase() + item?.prenom?.substring(0,1).toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.fournisseur.Contact';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idContact'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'nom',
			isDefault: true
		},{
			clef: 'prenom',
			isDefault: true
		},{
			clef: 'fournisseur.libelle',
			isDefault: true
		}];
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'fournisseur.contact.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return FournisseurContactAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_FOURNISSEUR;

	/**
	 * Composant d'affichage d'une ligne dans la recherche avancée
	 */
	searchItemComponent?: () => any = () => FournisseurContactSearchItemComponent;
}