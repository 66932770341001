<form #designationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="lv-actions actions pull-right" *ngIf="!isEdition">
		<div class="card-header">
			<ul class="actions">
				<li *ngIf="null | right:'creation'">
					<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
					<ul class="dropdown-menu dropdown-menu-right">
						<li><a [routerLink]="[]" (click)="editDesignation()" translate>actions.modifier</a></li>
						<li><a [routerLink]="[]" (click)="deleteDesignation()" *ngIf="contravention.designation.idDesignation" translate>actions.supprimer</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
	<fieldset [disabled]="!(null | right:'creation') || !isEdition">
		<div class="card" [ngClass]="{ 'full': isEdition }">
			<div class="card-body card-padding">
				<div class="row">
					<div class="col-md-6">
						<div *ngIf="contravention.typeDesignation == 'CONDUCTEUR'">
							<div class="form-group" [ngClass]="{ 'has-error': designationForm.controls['user']?.invalid }">
								<label translate>infraction.contravention.designation.conducteur</label>
								<div *ngIf="isEdition">
									<div class="input-group">
										<autocomplete name="user" type="conducteur" #user="ngModel" [(ngModel)]="contravention.designation.user" (onSelect)="onConducteurChange()" required></autocomplete>
										<div class="input-group-addon" *ngIf="contravention.extension?.vehiculeImmobilise">
											<icon name="warning" class="icon-hc-2x" [tooltip]="'infraction.contravention.designation.tooltip.vehiculeImmobilise' | translate"></icon>
										</div>
										<div class="input-group-addon">
											<icon name="replay_30" class="icon-hc-2x clickable" (click)="showListeAffectations()" [tooltip]="'infraction.contravention.designation.tooltip.historiqueAffectations' | translate"></icon>
										</div>
									</div>
								</div>
								<div *ngIf="!isEdition">
									<span>{{ contravention.designation.user | display:'user' }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': adresse.invalid }">
								<label translate>infraction.contravention.designation.adresse</label>
								<div>
									<adresse name="adresse" #adresse="ngModel" [(ngModel)]="contravention.designation.adresse" [disabled]="!isEdition" required></adresse>
								</div>
							</div>
							<div class="form-group">
								<label translate>infraction.contravention.designation.permisConduire</label>
								<div>
									<a [routerLink]="[]" (click)="showPermisConduire()">
										<span *ngIf="contravention.designation.permisConduire?.reference">{{ contravention.designation.permisConduire.reference }}</span>
										<span *ngIf="!contravention.designation.permisConduire?.reference" translate>common.aRenseigner</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<ng-container *ngIf="isEdition">
		<div class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteDesignation()" *ngIf="contravention.designation?.idDesignation && (null | right:'creation')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveDesignation()" [disabled]="designationForm.invalid" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: designationForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</ng-container>
</form>