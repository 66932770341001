'use strict';

var inherits = require('inherits'),
  Event = require('./event');
function TransportMessageEvent(data) {
  Event.call(this);
  this.initEvent('message', false, false);
  this.data = data;
}
inherits(TransportMessageEvent, Event);
module.exports = TransportMessageEvent;