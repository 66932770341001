import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ConfigurationContrainteComponent } from './configuration-contrainte.component';
import { ConfigurationOptionListItemComponent } from './configuration-option-list-item.component';
import { ConfigurationOptionListComponent } from './configuration-option-list.component';
import { ConfigurationRecapitulatifComponent } from './configuration-recapitulatif.component';
import { ConfigurationComponent } from './configuration.component';
import { ConfigurationService } from './configuration.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ConfigurationComponent,ConfigurationOptionListComponent,ConfigurationOptionListItemComponent,ConfigurationRecapitulatifComponent,ConfigurationContrainteComponent],
	providers: [ConfigurationService]
})
export class ConfigurationModule { }
