import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { WorkflowDetailBranchComponent } from './detail/workflow-detail-branch.component';
import { WorkflowDetailButtonComponent } from './detail/workflow-detail-button.component';
import { WorkflowDetailLineComponent } from './detail/workflow-detail-line.component';
import { WorkflowDetailStepComponent } from './detail/workflow-detail-step.component';
import { WorkflowDetailTitleComponent } from './detail/workflow-detail-title.component';
import { MotifModule } from './motif/motif.module';
import { ValidationModule } from './validation/validation.module';
import { WorkflowDetailComponent } from './workflow-detail.component';
import { WorkflowEditComponent } from './workflow-edit.component';
import { WorkflowHistoryComponent } from './workflow-history.component';
import { WorkflowListItemComponent } from './workflow-list-item.component';
import { WorkflowListComponent } from './workflow-list.component';
import { WorkflowComponent } from './workflow.component';
import { WorkflowService } from './workflow.service';
import { WorkflowDetailValideurSelectionComponent } from './detail/workflow-detail-valideur-selection.component';
import { WorkflowDetailValideurDefinitionComponent } from './detail/workflow-detail-valideur-definition.component';
import { WorkflowDetailRuleDefinitionComponent } from './detail/workflow-detail-rule-definition.component';

@NgModule({
	imports: [ShareModule,MotifModule,ValidationModule],
	exports: [MotifModule,ValidationModule],
	declarations: [WorkflowListComponent,WorkflowListItemComponent,WorkflowHistoryComponent,WorkflowComponent,WorkflowEditComponent,WorkflowDetailComponent,WorkflowDetailTitleComponent,WorkflowDetailLineComponent,WorkflowDetailStepComponent,WorkflowDetailButtonComponent,WorkflowDetailBranchComponent,WorkflowDetailValideurSelectionComponent,WorkflowDetailValideurDefinitionComponent,WorkflowDetailRuleDefinitionComponent],
	providers: [WorkflowService]
})
export class WorkflowModule {}