<div class="pull-left lv-avatar">
	<icon *ngIf="data.typeInfraction == 'DEMANDE_IDENTIFICATION'" [name]="getStatutForCode(data.statutIdentification)?.icone" [type]="getStatutForCode(data.statutIdentification)?.iconeType"></icon>
	<icon *ngIf="data.typeInfraction == 'CONTRAVENTION'" name="check"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="(data.typeInfraction == 'DEMANDE_IDENTIFICATION' && rightService.hasRight(TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,'consultation') || data.typeInfraction == 'CONTRAVENTION' && rightService.hasRight(TypeDroit.VEHICULE_CONTRAVENTION,'consultation')) && goToInfraction();">
			<span *ngIf="extraOptions?.vehicule?.idVehicule && data.typeInfraction == 'CONTRAVENTION' && (data.contravention.designation._type != 'com.notilus.data.vehicule.ContraventionDesignationConducteur' || !data.contravention.designation)">{{ data.reference }}</span>
			<span *ngIf="extraOptions?.vehicule?.idVehicule && data.typeInfraction == 'CONTRAVENTION' && data.contravention.designation._type == 'com.notilus.data.vehicule.ContraventionDesignationConducteur'">{{ data.contravention.designation.user | display:'user' }}</span>
			<span *ngIf="extraOptions?.vehicule?.idVehicule && data.typeInfraction == 'DEMANDE_IDENTIFICATION' && data.demandeIdentification.designation._type == 'com.notilus.data.sgi.DesignationConducteur'">{{ data.demandeIdentification.designation.user | display:'user' }}</span>
			<span *ngIf="extraOptions?.vehicule?.idVehicule && data.typeInfraction == 'DEMANDE_IDENTIFICATION' && (data.demandeIdentification.designation._type != 'com.notilus.data.sgi.DesignationConducteur' || !data.demandeIdentification.designation)">{{ data.reference }}</span>
			<span *ngIf="!extraOptions?.vehicule?.idVehicule">{{ data.vehicule | display:'vehicule' }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idObject">
		<li><span translate>infraction.date</span>&#160;:&#160;{{ data.dateInfraction | date:'short' }}</li>
		<li><span translate>infraction.numero</span>&#160;:&#160;{{ data.reference }}</li>
		<li *ngIf="data.typeInfraction == 'CONTRAVENTION' && data.contravention.majoree && data.contravention.contraventionMajorante"><span translate>infraction.contravention.majoration</span>&#160;:&#160;{{ data.contravention.contraventionMajorante.reference }}</li>
		<li *ngIf="data.typeInfraction == 'CONTRAVENTION'"><span translate>infraction.contravention.etat</span>&#160;:&#160;<span [translate]="'infraction.contravention.statut.'+data.contravention.statut"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idObject">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.typeInfraction == 'DEMANDE_IDENTIFICATION' && (TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION | right:'consultation')"><a [routerLink]="[]" (click)="showHistory('com.notilus.data.sgi.DemandeIdentification');" translate>actions.historique.consulter</a></li>
			<li *ngIf="data.typeInfraction == 'CONTRAVENTION' && (TypeDroit.VEHICULE_CONTRAVENTION | right:'consultation')"><a [routerLink]="[]" (click)="showHistory('com.notilus.data.vehicule.Contravention');" translate>actions.historique.consulter</a></li>
			<li *ngIf="(TypeDroit.VEHICULE_CONTRAVENTION | right:'suppression') && data.typeInfraction == 'CONTRAVENTION' && !data.contravention?.majoree"><a [routerLink]="[]" (click)="deleteContravention();" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>