import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';

@Component({
	templateUrl: './article-preview.component.html'
})
export class ArticlePreviewComponent implements OnInit {
	/** Article courant **/
	@Input() article: any = null;

	/** Indicateur de chargement **/
	isLoading: boolean = true;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef,public shareArticleService: SharedArticleService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de l'article
		this.article?.idArticle && this.shareArticleService.loadArticle(this.article.idArticle).subscribe({
			next: result => {
				//Mise à jour de l'article
				this.article = result.data.article;

				//Fin de chargement
				this.isLoading = false;
			}
		});
	}
}