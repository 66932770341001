<form #customRefForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title"><span translate [translateParams]="{ index: num }">dataCustomization.customRefValue.title</span>
				<span *ngIf="customRefValue?.dataCustomizationEntite?.['customRef'+num]"><span>&nbsp;:&nbsp;</span><span>{{ customRefValue.dataCustomizationEntite['customRef'+num] }}</span></span>
			</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>dataCustomization.customRefValue.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="customRefValue.libelle" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>dataCustomization.customRefValue.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="customRefValue.reference" required/>
							</div>
						</div>
						<div class="form-group">
							<label translate>dataCustomization.customRefValue.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="customRefValue.actif"><span translate>dataCustomization.customRefValue.actif.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteCustomRefValue?.(close);" *ngIf="customRefValue.idCustomRefValue && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveCustomRefValue();" *ngIf="null | right:'creation'" [disabled]="customRefForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: customRefForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>