<form #planEntretienAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>planEntretien.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>planEntretien.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="planEntretien.libelle" maxlength="300" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
			<label translate>planEntretien.duree</label>
			<div class="half">
				<div class="input-group">
					<input type="text" name="duree" #duree="ngModel" class="form-control" [(ngModel)]="planEntretien.duree" nNumber min="0" max="240" required/>
					<div class="input-group-addon no-border">
						<span translate>planEntretien.mois</span>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': distance.invalid || unite.invalid }">
			<label translate>planEntretien.distance</label>
			<div>
				<div class="row">
					<div class="col-xs-6">
						<input type="text" name="distance" #distance="ngModel" class="form-control" [(ngModel)]="planEntretien.distance" nNumber="2" min="0" required/>
					</div>
					<div class="col-xs-6">
						<autocomplete name="unite" type="unite" #unite="ngModel" [(ngModel)]="planEntretien.unite" [filter]="'DISTANCE'" required></autocomplete>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label translate>planEntretien.actif.item</label>
			<div>
				<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="planEntretien.actif"><span translate>planEntretien.actif.info</span></mat-checkbox>
			</div>
		</div>
		<div class="form-group">
			<label translate>planEntretien.remarque</label>
			<div>
				<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="planEntretien.remarque" rows="3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="planEntretienAddForm.invalid" (click)="saveItem()">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>