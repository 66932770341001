import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des demandes d'identification
 */
export default class DemandeIdentificationOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.demandeIdentification';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/DemandeIdentification/filtreDemandes/${filter?.typeListe || ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item && (`${item.reference} - ${item.vehicule?.reference}${item.vehicule.numeroInterne ? ' - ' + item.vehicule.numeroInterne : ''} (${item.vehicule?.modele ? (item.vehicule.modele.marque.libelle + ' ' + item.vehicule.modele.libelle) : translateService.instant('vehicule.modele.nonDefini')})`) || ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) {
		//Statut de la demande
		switch (item?.statut) {
		case 'NOUVELLE_DEMANDE':
			//Retour de l'icone
			return { icon: 'question_mark' };
		case 'ANOMALIE':
			//Retour de l'icone
			return { icon: 'warning' };
		case 'A_TRANSMETTRE':
			//Retour de l'icone
			return { icon: 'edit' };
		case 'TRANSMIS':
			//Retour de l'icone
			return { icon: 'swap_horiz' };
		case 'INTEGREE':
			//Retour de l'icone
			return { icon: 'check' };
		case 'ENVOI_PAPIER':
			//Retour de l'icone
			return { icon: 'email' };
		default:
			//Aucun retour
			return null;
		}
	}

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.sgi.DemandeIdentification';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idIdentification'; }

	/**
	 * Tri par défaut
	 */
	defaultOrder = '-dateInfraction';

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'vehicule.reference',
			isDefault: true
		}];
	}
}