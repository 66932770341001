import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { DemandeVehiculeCotationListItemComponent } from './demande-vehicule-cotation-list-item.component';

@Component({
	selector: 'demande-vehicule-cotation-list',
	templateUrl: './demande-vehicule-cotation-list.component.html'
})
export class DemandeVehiculeCotationListComponent extends PageContentItem implements OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Résumé **/
	@Input() resume: { nbCotations: number };

	/** Consultation d'une cotation **/
	@Input() consultCotation: Function;

	/** Affichage d'une cotation **/
	@Input() showCotation: Function;

	/** Données **/
	liste: ListView<any,DemandeVehiculeCotationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DemandeVehiculeCotationListItemComponent>({
			uri: () => `/controller/VehiculeCommande/filtreCotations`,
			component: DemandeVehiculeCotationListItemComponent,
			isLocal: true,
			listeStaticFilters: [{
				clef: 'demandeVehicule.idDemandeVehicule',
				valeur: this.demandeVehicule.idDemandeVehicule,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-demandeCotation.idDemandeCotation',
			onRefresh: (liste,result) => this.resume.nbCotations = result.totalElements,
			extraOptions: {
				resume: this.resume,
				demandeVehicule: this.demandeVehicule,
				consultCotation: this.consultCotation,
				showCotation: this.showCotation
			}
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idCotation > 0)
			//Mise à jour des compteurs
			this.resume.nbCotations--;
	}
}