import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { first,tap } from 'rxjs/operators';

import { DataChange,Options } from 'src/app/domain/comparator/comparator';
import { ComparatorMoreComponent } from './comparator-more.component';
import { ComparatorComponent } from './comparator.component';

@Injectable()
export class ComparatorService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) { }

	/**
	 * Ouverture de la popup du comparateur
	 */
	public showComparateur<T>(options: Options<T>,listeItems: Array<T>): Observable<DataChange<T>> {
		let onDataChange: Subject<DataChange<T>> = new Subject();
		let bsModalRef: BsModalRef<ComparatorComponent<T>>;

		//Affichage de la popup
		bsModalRef = <any> this.bsModalService.show(ComparatorComponent,{
			initialState: {
				options: cloneDeep(options),
				listeItems: cloneDeep(listeItems),
				onDataChange
			},
			class: `modal-comparator-${listeItems.length < 3 ? listeItems.length : 'max'}`
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			tap(() => {
				//Fermeture de la souscription
				onDataChange.complete();
			})
		);

		return onDataChange;
	}

	/**
	 * Ouverture de la popup d'information
	 */
	public showMoreInfo({ title,contentTitle,contentSubTitle,content }) {
		//Affichage de la popup
		this.bsModalService.show(ComparatorMoreComponent,{
			initialState: {
				title,
				contentTitle,
				contentSubTitle,
				content
			},
			class: 'modal-lg'
		});
	}
}
