import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { ImmatriculationService } from './immatriculation.service';

export abstract class ImmatriculationComponent {
	/** Immatriculation **/
	immatriculation: any;

	/** Type de véhicule */
	typeVehicule?: 'VEHICULE_FONCTION_SERVICE' | 'VEHICULE_PERSO' | 'ENGIN';

	/** Véhicule **/
	vehicule?: any;

	/** Options d'affichage **/
	options?: { isConsultationOnly?: boolean,isSynchro?: boolean,modeleInitial?: any };

	/** Données enregistrées **/
	savedData: { immatriculation?: any,vehicule?: any } = null;

	/**
	 * Constructeur
	 */
	constructor(protected bsModalRef: BsModalRef<ImmatriculationComponent>,protected immatriculationService: ImmatriculationService,protected toastrService: ToastrService,protected translateService: TranslateService,protected confirmService: ConfirmService,protected vehiculeService: VehiculeService,protected layoutService: LayoutService,protected rightService: RightService) {

	}

	/**
	 * Vérification du mode de création ou de synchronisation
	 */
	isModeCreationOrSynchro(): boolean {
		//Vérification de l'absence de véhicule ou de l'indicateur de synchronisation
		return !this.options?.isConsultationOnly && (!this.vehicule || this.options?.isSynchro);
	}

	/**
	 * Enregistrement de l'immatriculation
	 */
	saveImmatriculation() {
		let doSaveImmatriculation: () => void;

		//Déclaration de l'enregistrement de l'immatriculation
		doSaveImmatriculation = () => {
			//Enregistrement de l'immatriculation
			this.immatriculationService.saveImmatriculation(this.immatriculation,this.vehicule?.idVehicule,this.typeVehicule).pipe(first()).subscribe({
				next: result => {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Définition des données enregistrées
						this.savedData = {
							immatriculation: result.data.immatriculation,
							vehicule: result.data.vehicule
						};

						//Fermeture de la popup
						this.bsModalRef.hide();
					} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: this.translateService.instant('actions.doublon.immatriculation')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			});
		}

		//Vérification de l'historique des certificats d'immatriculation du véhicule
		if (this.vehicule?.immatriculation?.reference && this.vehicule?.immatriculation?.reference != this.immatriculation?.reference) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('vehicule.confirmation.changementImmatriculation'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed)).subscribe({
				next: () => doSaveImmatriculation()
			});
		} else
			//Sauvegarde de l'immatriculation
			doSaveImmatriculation();
	}

	/**
	 *	Synchronisation
	 */
	public doSynchronisation() {
		//Affichage de la sélection d'une immatriculation
		this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any,vehicule: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Définition du modèle
				data.vehicule.modeleConstructeur = data.params.modele;
				data.vehicule.reference = data.immatriculation.reference;
				data.vehicule.immatriculation = data.immatriculation;

				//Définition des données enregistrées
				this.savedData = {
					immatriculation: data.immatriculation,
					vehicule: data.vehicule
				}

				//Fermeture de la fenêtre
				this.bsModalRef.hide();
			}
		},{
			isDateEntreeVisible: false,
			isWithEngin: false,
			vehicule: this.vehicule,
			useExistingImmatriculation: true,
			isSynchro: true,
			modeleInitial: this.options?.modeleInitial
		}).subscribe();
	}
}