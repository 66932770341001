import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'vehicule-engin',
	templateUrl: './vehicule-engin.component.html'
})
export class VehiculeEnginComponent implements AfterContentChecked {
	/** Engin **/
	@Input() vehicule: any;

	/** Résultat **/
	result: { vehicule: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<VehiculeEnginComponent>) {

	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de l'engin
	 */
	public saveVehiculeEngin() {
		//Définition du résultat
		this.result = {
			vehicule: this.vehicule
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}