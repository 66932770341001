<div class="pull-left lv-avatar">{{ data.nom.substring(0,1).toUpperCase() + data.prenom.substring(0,1).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="extraOptions.addContact(data,deleteContact);">{{ data.nom + ' ' + data.prenom }}</a></div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>fournisseur.contact.email</span>&#160;:&#160;{{ data.email }}</li>
		<li *ngIf="data.telephone"><span translate>fournisseur.contact.telephone</span>&#160;:&#160;<span class="fi" [ngClass]="'fi-'+(data.telephone.codePays.toLowerCase() != 'ja' ? data.telephone.codePays.toLowerCase() : 'jp')"></span>&#160;(<icon name="add"></icon>{{ data.telephone.indicatif }})&#160;{{ data.telephone.numero }}</li>
		<li *ngIf="data.telephone2"><span translate>fournisseur.contact.telephone2</span>&#160;:&#160;<span class="fi" [ngClass]="'fi-'+(data.telephone2.codePays.toLowerCase() != 'ja' ? data.telephone2.codePays.toLowerCase() : 'jp')"></span>&#160;(<icon name="add"></icon>{{ data.telephone2.indicatif }})&#160;{{ data.telephone2.numero }}</li>
		<li *ngIf="data.fonction?.length > 0"><span translate>fournisseur.contact.fonction</span>&#160;:&#160;{{ data.fonction }}</li>
		<li *ngIf="data.type && data.type != 'NON_DEFINI'"><span translate>fournisseur.contact.type.item</span>&#160;:&#160;<span [translate]="'fournisseur.contact.type.'+data.type"></span></li>
	</ul>

	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteContact()"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>