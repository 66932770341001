import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first,map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { environment } from 'src/environments/environment';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { AccountComponent } from './account.component';

@Injectable()
export class AccountService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private http: HttpClient,private attachmentService: AttachmentService) { }

	/**
	 * Ouverture de la popup de la account
	 */
	public showAccount(type?: 'PERSONAL_DATA' | 'PASSWORD' | 'CONTACT_ADMIN'): Observable<any> {
		//Affichage de la popup
		return this.bsModalService.show(AccountComponent,{
			initialState: {
				type
			},
			class: 'modal-max'
		}).onHidden.pipe(first());
	}

	/**
	 * Chargement des données personnelles
	 */
	public loadPersonalData(user: User): Observable<Result> {
		//Chargement des données personnelles
		return this.http.post<Result>(`${environment.baseUrl}/controller/PersonalData/loadPersonalData/${user.idUser}`,null);
	}

	/**
	 * Extraction des données personnelles
	 */
	public extractPersonalData(user: User): Observable<void> {
		//Extraction des données personnelles
		return this.http.post(`${environment.baseUrl}/controller/PersonalData/extractData/${user.idUser}`,null,{
			responseType: 'blob'
		}).pipe(
			first(),
			map(response => this.attachmentService.downloadAttachment(response,undefined,'text/xml','personal_data.xml'))
		);
	}
}
