<div class="pull-left lv-avatar">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addEtatLieux();">{{ ('vehicule.etatLieux.item' | translate) + ' - ' + (data?.extension?.userAffecte ? (data?.extension?.userAffecte | display:'user') : ('common.nonDefini' | translate)) }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idEtatLieux">
		<li><span translate>vehicule.etatLieux.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.etatLieux.statut.'+data.statut"></span></li>
		<li><span [translate]="'vehicule.etatLieux.'+(data.dateRealisation ? 'realise' : 'prevu')"></span>&#160;:&#160;<span [translate]="(data.dateRealisation || data.datePlanification) | date:'short'"></span></li>
		<li><span translate>vehicule.etatLieux.collaborateurAffecte</span>&#160;:&#160;<span>{{ data.extension?.userAffecte ? (data.extension?.userAffecte | display:'user') : ('common.nonDefini' | translate) }}</span></li>
		<li *ngIf="data.statut == 'TERMINE' && data.user"><span translate>vehicule.etatLieux.realisePar</span>&#160;:&#160;<span>{{ data.user | display:'user' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.ETAT_LIEUX | right:'consultation') && data.statut == 'TERMINE' || (TypeDroit.ETAT_LIEUX | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(TypeDroit.ETAT_LIEUX | right:'consultation') && data.statut == 'TERMINE'"><a [routerLink]="[]" (click)="generateDocument()"><span translate>actions.genererDocument</span></a></li>
			<li *ngIf="TypeDroit.ETAT_LIEUX | right:'suppression'"><a [routerLink]="[]" (click)="deleteEtatLieux();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>