<div class="modal-header">
	<h4 class="modal-title" translate>{{ title }}</h4>
</div>
<div class="modal-body compatator">
	<div class="more-info">
		<h3 class="title">{{ contentTitle }}</h3>
		<h4 class="sub-title">{{ contentSubTitle }}</h4>
		<p class="content">{{ content }}</p>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>