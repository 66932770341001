import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ArticleService } from './article.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './article-list-item.component.html'
})
export class ArticleListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private articleService: ArticleService,private historyService: HistoryService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Suppression d'un article
	 */
	deleteArticle() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'article
					this.articleService.deleteArticle(this.data.idArticle).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'article
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.aide.Article',this.data.idArticle);
	}

	/**
	 * Prévisualisation de l'article
	 */
	previewArticle() {
		//Prévisualisation de l'article
		this.articleService.previewArticle(this.data.idArticle);
	}

	/**
	 * Duplication de l'article
	 */
	duplicateArticle() {
		//Duplication de l'article
		this.articleService.duplicateArticle(this.data).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers l'article dupliqué
				this.layoutService.goToByState('listeArticles-loadArticle',{
					routeParams: {
						idArticle: result.data.article.idArticle
					}
				});
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}
}