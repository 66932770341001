import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TemplateMailDestinataireListItemComponent } from './template-mail-destinataire-list-item.component';

@Component({
	selector: 'template-mail-destinataire-list',
	templateUrl: './template-mail-destinataire-list.component.html'
})
export class TemplateMailDestinataireListComponent extends PageContentItem implements OnInit {
	/** Template **/
	@Input() templateMail: any;

	/** Fonction d'édition **/
	@Input() addDestinataire: () => void;

	/** Résumé **/
	@Input() resume: { nbDestinataires: number };

	/** Données **/
	liste: ListView<any,TemplateMailDestinataireListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TemplateMailDestinataireListItemComponent>({
			uri: () => `/controller/TemplateMail/filtreDestinataires/${this.templateMail.idTemplateMail}`,
			component: TemplateMailDestinataireListItemComponent,
			isLocal: true,
			extraOptions: {
				templateMail: this.templateMail,
				addDestinataire: this.addDestinataire
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			defaultOrder: 'type,libelle'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idDestinataire > 0)
			//Mise à jour des compteurs
			this.resume.nbDestinataires--;
	}
}