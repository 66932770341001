<div class="container-fluid">
	<form #contratCRMForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>contratCRM.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="contratCRM.reference" maxlength="100" required [disabled]="isUsed"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': clientCRM.invalid }">
								<label translate>contratCRM.clientCRM</label>
								<div>
									<autocomplete name="clientCRM" type="clientCRM" #clientCRM="ngModel" [(ngModel)]="contratCRM.clientCRM" (ngModelChange)="contratCRM.revendeur = contratCRM?.clientCRM?.listeContratsCRM?.[0]?.revendeur" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': revendeur.invalid }">
								<label translate>contratCRM.revendeur</label>
								<div>
									<autocomplete name="revendeur" type="revendeur" #revendeur="ngModel" [(ngModel)]="contratCRM.revendeur" required [disabled]="isUsed" (ngModelChange)="contratCRM.articleCRM = null"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': articleCRM.invalid }" *ngIf="contratCRM.revendeur">
								<label translate>contratCRM.articleCRM</label>
								<div>
									<autocomplete name="articleCRM" type="articleCRM" #articleCRM="ngModel" [(ngModel)]="contratCRM.articleCRM" [filter]="{ idRevendeur: contratCRM.revendeur.idRevendeur }" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateEffet.invalid }">
								<label translate>contratCRM.dateEffet</label>
								<div class="half">
									<input type="text" class="form-control" name="dateEffet" #dateEffet="ngModel" [(ngModel)]="contratCRM.dateEffet" datePicker format="date" [storeDate]="true" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>contratCRM.facturation.item</label>
								<div>
									<mat-checkbox name="facturation" #facturation="ngModel" [(ngModel)]="contratCRM.facturation"><span translate>contratCRM.facturation.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="showResiliation(close);" *ngIf="contratCRM?.idContrat && (null | right:'creation') && canDoResiliation && isUsed">
				<span translate>actions.resilier</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteContratCRM();" *ngIf="!isUsed && contratCRM?.idContrat && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveContratCRM();" *ngIf="null | right:'creation'" [disabled]="contratCRMForm.invalid">
				<span [translate]="'actions.'+(contratCRM?.idContrat ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="contratCRM?.idContrat" [formDetectChanges]="{ doAction: close,isDirty: contratCRMForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>