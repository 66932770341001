import { Component,Input,OnInit } from '@angular/core';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ContratFinancementEcheanceDetailListItemComponent } from './contrat-financement-echeance-detail-list-item.component';

@Component({
	templateUrl: './contrat-financement-echeance-detail-list.component.html'
})
export class ContratFinancementEcheanceDetailListComponent implements OnInit {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Echéance courante **/
	@Input() echeance: any;

	/** Données **/
	liste: ListView<any,ContratFinancementEcheanceDetailListItemComponent>;

	/** Résultat **/
	result: { isValidated?: boolean };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ContratFinancementEcheanceDetailListComponent>) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let debut: Date;

		//Calcul de la date de début et de fin
		debut = new Date(this.echeance.annee,this.echeance.mois - 1,1);

		//Définition de la liste
		this.liste = new ListView<any,ContratFinancementEcheanceDetailListItemComponent>({
			uri: () => `/controller/Vehicule/filtreDetailsFacture/${this.contratFinancement.vehicule.idVehicule}`,
			component: ContratFinancementEcheanceDetailListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'referenceNature',
				isDefault: true
			},{
				clef: 'libelleNature',
				isDefault: true
			},{
				clef: 'facture.reference',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'facture.fournisseur.idFournisseur',
				valeur: this.contratFinancement.fournisseur.idFournisseur
			},{
				clef: 'date',
				dateDebut: debut,
				dateFin: moment(debut).endOf('month').toDate(),
				typeComparaison: TypeComparaison.BETWEEN,
				type: TypeFilter.DATE
			},{
				clef: 'nature.typeFamille',
				listeObjects: ['ENERGIE','PEAGE','PARKING'],
				typeComparaison: TypeComparaison.NOT_IN,
				type: TypeFilter.STRING
			}],
			defaultOrder: '-date,libelleNature'
		});
	}

	/**
	 * Validation de l'échéance
	 */
	validate() {
		//Définition du résultat
		this.result = {
			isValidated: true
		};

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}