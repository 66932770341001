<form #affectationEcheanceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!affectationEcheance.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label translate>affectationEcheance.conducteur</label>
							<div>
								<mat-chip-listbox>
									<mat-chip [ngClass]="{ clickable: (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation') }" (click)="goToConducteur()">
										{{ affectationEcheance?.affectation?.user?.prenom + ' ' + affectationEcheance?.affectation?.user?.nom + ' (' + affectationEcheance?.affectation?.user?.matricule + (affectationEcheance?.affectation?.user?.societe ? ' - ' + affectationEcheance?.affectation?.user?.societe.libelle : '') + ')' }}
									</mat-chip>
								</mat-chip-listbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>affectationEcheance.periode</label>
							<div>
								<span>{{ affectationEcheance?.dateEcheance | date:'MMMM YYYY' | titlecase }}</span>
							</div>
						</div>
						<div class="form-group">
							<label translate>affectationEcheance.avantageNatureRenseigne</label>
							<div>
								<span>{{ affectationEcheance.affectation?.montantAvantageNature || 0 | currency:'.2.2':user?.devise }}</span>
							</div>
						</div>
						<div class="form-group">
							<label translate>affectationEcheance.montantParticipationRenseigne</label>
							<div>
								<span>{{ affectationEcheance.affectation?.montantParticipation || 0 | currency:'.2.2':user?.devise }}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>affectationEcheance.vehicule</label>
							<div>
								<mat-chip-listbox>
									<mat-chip [ngClass]="{ clickable: (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="goToVehicule()">
										{{ affectationEcheance.affectation?.vehicule?.reference + (affectationEcheance.affectation?.vehicule?.numeroInterne ? ' - ' + affectationEcheance.affectation.vehicule.numeroInterne : '') + ' (' + (affectationEcheance.affectation?.vehicule?.modele ? affectationEcheance?.affectation?.vehicule?.modele?.marque?.libelle + ' ' + affectationEcheance?.affectation?.vehicule?.modele?.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}
									</mat-chip>
								</mat-chip-listbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>affectationEcheance.typeCalculAvantageNature.item</label>
							<div>
								<span [translate]="'affectationEcheance.typeCalculAvantageNature.' + (affectationEcheance?.typeCalculAvantageNature || 'NON_DEFINI')"></span>
							</div>
						</div>
						<div class="form-group">
							<label translate>affectationEcheance.avantageNatureCalcule</label>
							<div>
								<a [routerLink]="[]" (click)="showDetailCalculAEN()" *ngIf="affectationEcheance.typeCalculAvantageNature">
									<span *ngIf="affectationEcheance.typeCalculAvantageNature == 'ACHAT_AVEC_CARBURANT'">{{ affectationEcheance.montantAvantageNatureAchatAvecCarburant || 0 | currency:'.2-2':user?.devise }}</span>
									<span *ngIf="affectationEcheance.typeCalculAvantageNature == 'ACHAT_SANS_CARBURANT'">{{ affectationEcheance.montantAvantageNatureAchatSansCarburant || 0 | currency:'.2-2':user?.devise }}</span>
									<span *ngIf="affectationEcheance.typeCalculAvantageNature == 'LOCATION_AVEC_CARBURANT'">{{ affectationEcheance.montantAvantageNatureLocationAvecCarburant || 0 | currency:'.2-2':user?.devise }}</span>
									<span *ngIf="affectationEcheance.typeCalculAvantageNature == 'LOCATION_SANS_CARBURANT'">{{ affectationEcheance.montantAvantageNatureLocationSansCarburant || 0 | currency:'.2-2':user?.devise }}</span>
								</a>
								<span *ngIf="!affectationEcheance.typeCalculAvantageNature" translate>common.aucun</span>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: affectationEcheanceForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
		</div>
	</div>
</form>