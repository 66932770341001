import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'progress-erreur',
	templateUrl: './progress-erreur.component.html'
})
export class ProgressErreurComponent {
	/** Progression en erreur **/
	progress: any;

	/** Liste des erreurs de la progression **/
	listeErreurs: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef) {}
}