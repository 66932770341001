<form #etatLieuxForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>vehicule.etatLieux.titre</h2>
			</div>
			<div class="card-body card-padding">
				<etat-lieux-content #etatLieuxContent [etatLieux]="etatLieux" source="CONDUCTEUR" (close)="onContentClose($event)"></etat-lieux-content>
			</div>
		</div>
		<span class="pull-right">
			<button mat-raised-button color="primary" *ngIf="TypeDroit.ETAT_LIEUX | right:'creation'" (click)="etatLieuxContent.saveEtatLieux();" [disabled]="etatLieuxForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: etatLieuxForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>