<form #permisForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>conducteur.permis.titre</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation') || readOnly">
			<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
				<label translate>conducteur.permis.numero</label>
				<div>
					<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="permisConduire.reference" pattern=".{1,20}" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateDelivrance.invalid }">
				<label translate>conducteur.permis.date</label>
				<div class="half">
					<input type="text" class="form-control" name="dateDelivrance" #dateDelivrance="ngModel" [(ngModel)]="permisConduire.dateDelivrance" datePicker [maxDate]="dateMax" [storeDate]="true" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': lieuDelivrance.invalid }">
				<label translate>conducteur.permis.lieu</label>
				<div>
					<input type="text" class="form-control" name="lieuDelivrance" #lieuDelivrance="ngModel" [(ngModel)]="permisConduire.lieuDelivrance" pattern=".{1,39}" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
				<label translate>conducteur.permis.pays</label>
				<div>
					<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="permisConduire.pays" required></autocomplete>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide();">
			<span translate>actions.fermer</span>
		</button>
		<ng-container *ngIf="(null | right:'creation') && !readOnly">
			<button mat-stroked-button color="primary" *ngIf="permisConduire.idPermis" (click)="deletePermis();" [disabled]="permisForm.invalid">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="save()" [disabled]="permisForm.invalid">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
	</div>
</form>