import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep,intersectionWith } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject,combineLatest,of } from 'rxjs';
import { filter,first,map,shareReplay,tap } from 'rxjs/operators';
import { v4 } from 'uuid';

import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ValidationEntiteOptions } from 'src/app/domain/workflow/validation';
import { ValidationService } from 'src/app/share/components/workflow/validation/validation.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { WorkflowDetailRuleDefinitionComponent } from './detail/workflow-detail-rule-definition.component';
import { WorkflowDetailValideurDefinitionComponent } from './detail/workflow-detail-valideur-definition.component';
import { WorkflowDetailValideurSelectionComponent } from './detail/workflow-detail-valideur-selection.component';
import { MotifSelectionComponent } from './motif/motif-selection.component';
import { WorkflowHistoryComponent } from './workflow-history.component';

@Injectable()
export class WorkflowService {
	/** Cache sur la présence de workflows par entité **/
	mapWorkflowAvailability: Map<string,boolean> = new Map<string,boolean>();

	/** Cache sur la présence de motifs par entité **/
	mapMotifsByEntity: Map<string,any> = new Map<string,any>();

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private validationService: ValidationService,private rightService: RightService) { }

	/**
	 * Chargement d'un workflow
	 */
	public loadWorkflow(idWorkflow: number): Observable<Result> {
		//Chargement d'un workflow
		return this.http.post<Result>(`${environment.baseUrl}/controller/Workflow/loadWorkflow/${idWorkflow}`,null);
	}

	/**
	 * Suppression d'un workflow
	 */
	public deleteWorkflow(workflow: any): Observable<Result> {
		//Suppression d'un workflow
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Workflow/deleteWorkflow/${workflow.idWorkflow}`);
	}

	/**
	 * Enregistrement d'un workflow
	 */
	public saveWorkflow(workflow: any): Observable<Result> {
		//Enregistrement d'un workflow
		return this.http.put<Result>(`${environment.baseUrl}/controller/Workflow/saveWorkflow`,workflow);
	}

	/**
	 * Chargement de l'historique workflow pour une entité
	 */
	public loadWorkflowHistory(entite: string,idObject: number): Observable<Result> {
		//Chargement de l'historique workflow d'un objet
		return this.http.post<Result>(`${environment.baseUrl}/controller/Workflow/loadWorkflowHistory/${entite}/${idObject}`,null);
	}

	/**
	 * Duplication d'un workflow
	 */
	public duplicateWorkflow(workflow: any): Observable<Result> {
		//Duplication du workflow
		return this.http.post<Result>(`${environment.baseUrl}/controller/Workflow/duplicateWorkflow/${workflow.idWorkflow}`,null);
	}

	/**
	 * Recherche de la présence d'un workflow
	 */
	public isWorkflowAvailableForEntity(entite): Observable<boolean> {
		//Vérification du cache
		if (!this.mapWorkflowAvailability.has(entite)) {
			//Recherche du workflow
			return this.http.post<Result>(`${environment.baseUrl}/controller/Workflow/isWorkflowAvailableForEntity/${entite}`,null).pipe(
				shareReplay(1),
				map(result => result.data.isAvailable),
				tap((isAvailable: boolean) => this.mapWorkflowAvailability.set(entite,isAvailable))
			);
		} else
			//Lecture du cache
			return of(this.mapWorkflowAvailability.get(entite));
	}

	/**
	 * Ouverture de l'historique du workflow
	 */
	public showWorkflowHistory(entite: string,idObject: number): BsModalRef<WorkflowHistoryComponent> {
		let bsModalRef: BsModalRef<WorkflowHistoryComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(WorkflowHistoryComponent,{
			initialState: {
				entite,
				idObject
			},
			class: 'modal-max'
		});

		return bsModalRef;
	}

	/**
	 * Recherche de la présence de motifs pour une entité
	 */
	private isMotifAvailableFor(entite: string): Observable<boolean> {
		//Vérification du cache
		if (!this.mapMotifsByEntity.has(entite)) {
			//Recherche de la présence de motifs pour l'entité
			return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowReferentiel/isMotifAvailableFor`,{
				listeFilter: [{
					clef: 'entity',
					valeur: entite,
					typeComparaison: TypeComparaison.EQUAL,
					type: 'STRING'
				}]
			}).pipe(
				first(),
				map((result: Result) => result.data.isAvailable),
				tap((isAvailable: boolean) => this.mapMotifsByEntity.set(entite,isAvailable))
			);
		} else
			//Retour de la valeur présente en cache
			return of(this.mapMotifsByEntity.get(entite));
	}

	/**
	 * Recherche de la possibilité de valider une entité
	 */
	public isValideurFor(user: User,entite: string): boolean {
		//Vérification des droits de l'utilisateur
		return user.listeEntitesForValideur?.indexOf(entite) != -1;
	}

	/**
	 * Filtrage des entités visibles
	 */
	public getListeEntites(user: User): Array<ValidationEntiteOptions> {
		//Filtrage des entités visibles
		return user.listeEntitesForValideur?.length ? intersectionWith(this.validationService.getListeEntites(),user.listeEntitesForValideur,(left,right) => left.entite == right) : null;
	}

	/**
	 * Affichage de la pop-up de sélection de motif
	 */
	public selectWorkflowMotif(entite: string): Observable<{ isMotifAvailable: boolean,isCancelled?: boolean,workflowMotif?: any }> {
		let motifSubject: Subject<any> = new Subject<any>();
		let bsModalRef: BsModalRef<MotifSelectionComponent>;

		//Récupération de la disponibilité de motifs pour l'entité
		combineLatest([this.isWorkflowAvailableForEntity(entite),this.isMotifAvailableFor(entite)]).subscribe({
			next: ([isWorkflowAvailable,isMotifAvailable]) => {
				//Vérification de l'existence de motifs
				if (isWorkflowAvailable && isMotifAvailable) {
					//Affichage de la popup
					bsModalRef = this.bsModalService.show(MotifSelectionComponent,{
						initialState: {
							entite
						},
						class: 'modal-lg'
					});

					//Souscription sur la fermeture
					bsModalRef.onHidden.subscribe({
						next: () => {
							//Emission du motif
							motifSubject.next({ isMotifAvailable: true,workflowMotif: bsModalRef.content?.result,isCancelled: !bsModalRef.content?.result?.isSelected });
						},
						error: (err) => {
							//Transmission de l'erreur
							motifSubject.error(err);
						},
						complete: () => motifSubject.complete()
					});
				} else
					//Emission sans motif
					motifSubject.next({ isMotifAvailable: false });
			},
			error: (err) => motifSubject.error(err)
		});

		//Retour de l'observable
		return motifSubject.asObservable();
	}

	/**
	 * Ouverture de la popup de sélection des types de valideurs
	 */
	public showValideurSelection(): Observable<{ typeValideur: 'VALIDEUR_DYNAMIQUE' | 'VALIDEUR_NOMME',key: string,deletable: boolean,action: any }> {
		let bsModalRef: BsModalRef<WorkflowDetailValideurSelectionComponent>;

		//Affichage de la popup
		return (bsModalRef = this.bsModalService.show(WorkflowDetailValideurSelectionComponent,{
			class: 'modal-md'
		})).onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.typeValideur),
			filter(typeValideur => !!typeValideur),
			map(typeValideur => ({
				typeValideur,
				key: v4().toString(),
				deletable: true,
				action: {}
			}))
		);
	}

	/**
	 * Ouverture de la popup de définition des valideurs
	 */
	public showValideurDefinition(entite: string,etape: any,isEdition: boolean = true): Observable<{ listeValideursNommes?: Array<any>,listeValideurs?: Array<any> }> {
		let bsModalRef: BsModalRef<WorkflowDetailValideurDefinitionComponent>;

		//Affichage de la popup
		return (bsModalRef = this.bsModalService.show(WorkflowDetailValideurDefinitionComponent,{
			initialState: {
				etape: cloneDeep(etape),
				isEdition: isEdition && this.rightService.hasRight(TypeDroit.ADMIN_WORKFLOW,'creation'),
				entite
			},
			class: etape.typeValideur == 'VALIDEUR_NOMME' ? 'modal-max' : 'modal-lg'
		})).onHidden.pipe(
			first(),
			map(() => ({
				...etape,
				listeValideursNommes: etape.typeValideur == 'VALIDEUR_NOMME' ? bsModalRef.content?.result?.listeValideursNommes : null,
				listeValideurs: etape.typeValideur == 'VALIDEUR_DYNAMIQUE' ? bsModalRef.content?.result?.listeValideurs : null,
				typeValideur: etape.typeValideur
			})),
			filter(() => !!(etape.typeValideur == 'VALIDEUR_NOMME' && bsModalRef.content?.result?.listeValideursNommes || etape.typeValideur == 'VALIDEUR_DYNAMIQUE' && bsModalRef.content?.result?.listeValideurs))
		);
	}

	/**
	 * Ouverture de la popup de définition d'une règle workflow
	 */
	public showRuleDefinition(entite: string,branche: any,isEdition: boolean = true): Observable<{ rule?: any,libelle?: string }> {
		let bsModalRef: BsModalRef<WorkflowDetailRuleDefinitionComponent>;

		//Affichage de la popup
		return (bsModalRef = this.bsModalService.show(WorkflowDetailRuleDefinitionComponent,{
			initialState: {
				rule: cloneDeep(branche.rule) || {},
				libelle: branche.libelle,
				isEdition: isEdition && this.rightService.hasRight(TypeDroit.ADMIN_WORKFLOW,'creation'),
				entite
			},
			class: 'modal-max'
		})).onHidden.pipe(
			first(),
			map(() => ({
				...branche,
				rule: bsModalRef.content?.result?.rule,
				libelle: bsModalRef.content?.result?.libelle
			})),
			filter(b => !!(b.rule))
		);
	}
}