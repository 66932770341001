<form #financementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>demandeVehicule.financement.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation')">
				<div class="alert alert-info" *ngIf="demandeVehicule.vehiculeSource?.financement && isFinancementLoaded">
					<icon name="info"></icon>
					<div>
						<strong translate>demandeVehicule.financement.rappel.titre</strong>
						<div class="p-t-10">
							<div class="row">
								<div class="col-md-5">
									<div>
										<span translate>demandeVehicule.financement.rappel.fournisseur</span>&#160;:&#160;<span class="bold">{{ demandeVehicule.vehiculeSource.financement.fournisseur?.libelle }}</span>
									</div>
									<div>
										<span translate>demandeVehicule.financement.rappel.typeFinancement</span>&#160;:&#160;<span class="bold">{{ demandeVehicule.vehiculeSource.financement.type?.libelle }}</span>
									</div>
									<div *ngIf="demandeVehicule.vehiculeSource.financement.type?.mode != 'ACQUISITION' && demandeVehicule.vehiculeSource.financement.lastLoiRoulage?.loyerMensuel">
										<span translate>demandeVehicule.financement.rappel.loyerMensuel</span>&#160;:&#160;<span class="bold">{{ demandeVehicule.vehiculeSource.financement.lastLoiRoulage.loyerMensuel | currency:'-2.2':demandeVehicule.vehiculeSource.financement.devise }}</span>
									</div>
									<div *ngIf="demandeVehicule.vehiculeSource.financement.type?.mode != 'ACQUISITION' && demandeVehicule.vehiculeSource.financement.lastLoiRoulage.loyerMensuel">
										<span translate>demandeVehicule.financement.rappel.typeAffichagePrix</span>&#160;:&#160;<span class="bold">{{ 'contrat.typeAffichagePrix.short.' + demandeVehicule.vehiculeSource.financement.lastLoiRoulage.typeAffichagePrix | translate }}></span>
									</div>
								</div>
								<div class="col-md-5" *ngIf="demandeVehicule.vehiculeSource.financement.type.mode != 'ACQUISITION' && demandeVehicule.vehiculeSource.financement.lastLoiRoulage">
									<div>
										<span translate>demandeVehicule.financement.rappel.loiRoulage</span>&#160;:&#160;<span class="bold">{{ demandeVehicule.vehiculeSource.financement.lastLoiRoulage.duree }}</span>&#160;<span translate>duree.mois</span>&#160;/&#160;<span>{{ demandeVehicule.vehiculeSource.financement.lastLoiRoulage.distance | number }}</span>&#160;<span>{{ demandeVehicule.vehiculeSource.unite.libelleCourt }}</span>
									</div>
									<div *ngIf="demandeVehicule.vehiculeSource.financement.extension?.distanceMensuelle">
										<span translate>demandeVehicule.financement.rappel.distanceMensuelleContrat</span>&#160;:&#160;<span class="bold">{{ (demandeVehicule.vehiculeSource.financement.extension.distanceMensuelle | number) + ' ' + demandeVehicule.vehiculeSource.unite.libelleCourt }}</span>
									</div>
									<div *ngIf="demandeVehicule.vehiculeSource.financement.extension?.distanceMensuelleEstimee">
										<span translate>demandeVehicule.financement.rappel.distanceMensuelleEstimee</span>&#160;:&#160;<span class="bold">{{ (demandeVehicule.vehiculeSource.financement.extension.distanceMensuelleEstimee | number) + ' ' + demandeVehicule.vehiculeSource.unite.libelleCourt }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
							<label translate>demandeVehicule.financement.fournisseur</label>
							<div>
								<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="financement.fournisseur" [filter]="{ isShowAll: true }" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
							<label translate>demandeVehicule.financement.typeFinancement</label>
							<div>
								<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="financement.typeFinancement" (ngModelChange)="financement.concessionnaire = null" required></autocomplete>
							</div>
						</div>
						<div *ngIf="financement.typeFinancement && financement.typeFinancement?.mode != 'ACQUISITION'">
							<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
								<label translate>demandeVehicule.financement.duree</label>
								<div class="half">
									<input type="text" name="duree" #duree="ngModel" class="form-control" [(ngModel)]="financement.duree" nNumber min="0" max="300" (ngModelChange)="computePrixRevientKilometrique()" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': distance.invalid }">
								<label translate>demandeVehicule.financement.distance</label>
								<div class="input-group half">
									<input type="text" name="distance" #distance="ngModel" class="form-control" [(ngModel)]="financement.distance" nNumber="0" (ngModelChange)="computePrixRevientKilometrique()" required/>
									<div class="input-group-addon no-border" *ngIf="user.unite">
										<span>{{ user.unite.libelleCourt }}</span>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeAffichagePrix.invalid }">
								<label translate>demandeVehicule.financement.typeAffichagePrix.item</label>
								<div>
									<selectpicker name="typeAffichagePrix" #typeAffichagePrix="ngModel" [(ngModel)]="financement.typeAffichagePrix" required>
										<mat-option *ngFor="let typeAffichagePrix of listeTypesAffichagePrix" [value]="typeAffichagePrix.code">{{ typeAffichagePrix.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': loyerMensuel.invalid }">
								<label translate>demandeVehicule.financement.loyerMensuel</label>
								<div>
									<amount name="loyerMensuel" #loyerMensuel="ngModel" [(ngModel)]="financement.loyerMensuel" [devise]="financement.devise" (onDeviseChange)="financement.devise = $event;" (ngModelChange)="computePrixRevientKilometrique()" required></amount>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ng-container *ngIf="financement.typeFinancement && financement.typeFinancement?.mode != 'ACQUISITION'">
					<div class="form-group">
						<label translate>demandeVehicule.financement.detailLoyers</label>
						<div>
							<a [routerLink]="[]" (click)="financement.isDetailLoyerDisplayed = !financement.isDetailLoyerDisplayed">
								<span translate>demandeVehicule.financement.actions.toggleDetailLoyer</span>
								<icon class="p-l-10" [name]="!financement.isDetailLoyerDisplayed ? 'chevron_right' : 'expand_more'"></icon>
							</a>
						</div>
					</div>
					<div [collapse]="!financement.isDetailLoyerDisplayed" [isAnimated]="true">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerFinancier.invalid }">
									<label translate>demandeVehicule.financement.loyerFinancier</label>
									<div class="half">
										<input type="text" name="loyerFinancier" #loyerFinancier="ngModel" class="form-control" [(ngModel)]="financement.loyerFinancier" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerMaintenance.invalid }">
									<label translate>demandeVehicule.financement.loyerMaintenance</label>
									<div class="half">
										<input type="text" name="loyerMaintenance" #loyerMaintenance="ngModel" class="form-control" [(ngModel)]="financement.loyerMaintenance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAssistance.invalid }">
									<label translate>demandeVehicule.financement.loyerAssistance</label>
									<div class="half">
										<input type="text" name="loyerAssistance" #loyerAssistance="ngModel" class="form-control" [(ngModel)]="financement.loyerAssistance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGarantiePerteFinanciere.invalid }">
									<label translate>demandeVehicule.financement.loyerGarantiePerteFinanciere</label>
									<div class="half">
										<input type="text" name="loyerGarantiePerteFinanciere" #loyerGarantiePerteFinanciere="ngModel" class="form-control" [(ngModel)]="financement.loyerGarantiePerteFinanciere" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGestionCarburant.invalid }">
									<label translate>demandeVehicule.financement.loyerGestionCarburant</label>
									<div class="half">
										<input type="text" name="loyerGestionCarburant" #loyerGestionCarburant="ngModel" class="form-control" [(ngModel)]="financement.loyerGestionCarburant" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGestionSinistres.invalid }">
									<label translate>demandeVehicule.financement.loyerGestionSinistres</label>
									<div class="half">
										<input type="text" name="loyerGestionSinistres" #loyerGestionSinistres="ngModel" class="form-control" [(ngModel)]="financement.loyerGestionSinistres" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerTelematique.invalid }">
									<label translate>demandeVehicule.financement.loyerTelematique</label>
									<div class="half">
										<input type="text" name="loyerTelematique" #loyerTelematique="ngModel" class="form-control" [(ngModel)]="financement.loyerTelematique" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerPneumatiques.invalid }">
									<label translate>demandeVehicule.financement.loyerPneumatiques</label>
									<div class="half">
										<input type="text" name="loyerPneumatiques" #loyerPneumatiques="ngModel" class="form-control" [(ngModel)]="financement.loyerPneumatiques" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerVehiculeRemplacement.invalid }">
									<label translate>demandeVehicule.financement.loyerVehiculeRemplacement</label>
									<div class="half">
										<input type="text" name="loyerVehiculeRemplacement" #loyerVehiculeRemplacement="ngModel" class="form-control" [(ngModel)]="financement.loyerVehiculeRemplacement" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAssurance.invalid }">
									<label translate>demandeVehicule.financement.loyerAssurance</label>
									<div class="half">
										<input type="text" name="loyerAssurance" #loyerAssurance="ngModel" class="form-control" [(ngModel)]="financement.loyerAssurance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAutres.invalid }">
									<label translate>demandeVehicule.financement.loyerAutres</label>
									<div class="half">
										<input type="text" name="loyerAutres" #loyerAutres="ngModel" class="form-control" [(ngModel)]="financement.loyerAutres" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>demandeVehicule.financement.prixRevientKilometrique</label>
						<div>
							<span>{{ ((financement.coutDistance || 0) | currency:financement.devise:undefined:'1.3') + '/' + user.unite.libelleCourt }}</span>
						</div>
					</div>
				</ng-container>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label>
								<span translate>demandeVehicule.financement.informationTarifaire.item</span>
								<icon *ngIf="demandeVehicule?.typeAffectation == 'VEHICULE_FONCTION' && !financement?.informationTarifaire?.prixTTC && !financement?.informationTarifaire?.prixTTCRemise" name="warning" class="c-warning icon-hc-2x m-l-5" [tooltip]="'demandeVehicule.financement.informationTarifaire.informationsNecessairesCalcul' | translate"></icon>
							</label>
							<div>
								<a [routerLink]="[]" (click)="showInformationTarifaire()">{{ getLibelleInformationTarifaire() }}</a>
							</div>
						</div>
						<div class="form-group">
							<label translate>common.piecesJointes</label>
							<div>
								<div [attachment]="financement" [typeAttachment]="TypeAttachment.DEMANDE_VEHICULE_FINANCEMENT"></div>
							</div>
						</div>
						<div class="form-group" *ngIf="financement.fournisseur?.grilleFluidite && financement.typeFinancement?.mode == 'LOCATION_LONGUE_DUREE' && !['LIVRE','ANNULEE'].includes(demandeVehicule.statut) && (TypeDroit.GRILLE_FLUIDITE | right:'creation')">
							<label translate>demandeVehicule.financement.grilleFluidite.item</label>
							<div>
								<a [routerLink]="[]" *ngIf="!grilleFluidite" (click)="showGrilleFluiditeImport();" translate>demandeVehicule.financement.grilleFluidite.showImport</a>
								<a [routerLink]="[]" *ngIf="grilleFluidite" (click)="showGrilleFluidite()" translate>demandeVehicule.financement.grilleFluidite.showGrilleFluidite</a>
							</div>
						</div>
						<div class="form-group" *ngIf="financement.typeFinancement?.mode == 'ACQUISITION' || financement.typeFinancement?.mode == 'LOCATION_OPTION_ACHAT'">
							<label><span translate>demandeVehicule.financement.concessionnaire</span></label>
							<div>
								<autocomplete name="concessionnaire" type="fournisseur" [(ngModel)]="financement.concessionnaire"></autocomplete>
							</div>
						</div>
						<div class="form-group" *ngIf="financement.reponseCotation?.cotation">
							<label translate>vehicule.grilleAttribution.modele.financement.reponseCotation.item</label>
							<div>
								<a [routerLink]="[]" (click)="showComparaisonForCotation()" translate [translateParams]="{ reference: financement.reponseCotation?.cotation?.demandeCotation?.idDemandeCotation }">vehicule.grilleAttribution.modele.financement.reponseCotation.message</a>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>demandeVehicule.financement.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="financement.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteFinancement(close);" *ngIf="deleteFinancement && financement?.idFinancement && !['LIVRE','ANNULEE'].includes(demandeVehicule.statut) && (TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveFinancement();" *ngIf="!['LIVRE','ANNULEE'].includes(demandeVehicule.statut) && (TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation')" [disabled]="financementForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: doClose.bind(this),isDirty: financementForm.dirty && isDirty() }" (beforeAction)="onBeforeAction($event)">
		<span translate>actions.fermer</span>
	</button>
</form>