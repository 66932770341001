import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { mapEntites } from './sgi-lot.entites';
import { SgiLotListItemComponent } from './sgi-lot-list-item.component';
import { SgiLotService } from './sgi-lot.service';

@Component({
	selector: 'sgi-lot-list',
	templateUrl: './sgi-lot-list.component.html'
})
export class SgiLotListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,SgiLotListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private sgiLotService: SgiLotService,public dashboardService: DashboardService,public extractionService: ExtractionService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,SgiLotListItemComponent>({
			uri: `/controller/Sgi/filtreLots`,
			title: this.translateService.instant('sgi.lot.liste.title'),
			component: SgiLotListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'idLot',
				title: this.translateService.instant('sgi.lot.identifiant')
			},{
				clef: 'dateCreationApplication',
				title: this.translateService.instant('sgi.lot.dateCreationApplication'),
				type: TypeFilter.DATE
			},{
				clef: 'dateCreation',
				title: this.translateService.instant('sgi.lot.dateCreation'),
				type: TypeFilter.DATE
			},{
				clef: 'typeLot',
				title: this.translateService.instant('sgi.lot.typeLot.item'),
				type: TypeFilter.LONG,
				listeValues: this.sgiLotService.getListeTypesLot()
			},{
				clef: 'typeFlux',
				title: this.translateService.instant('sgi.lot.typeFlux.item'),
				type: TypeFilter.LONG,
				listeValues: this.sgiLotService.getListeTypesFlux()
			},{
				clef: 'isClosed',
				title: this.translateService.instant('sgi.lot.etat.isClosed'),
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: '-dateCreation,idLot',
			getKeyFieldName: () => 'idLot'
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}