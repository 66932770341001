<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<span *ngIf="data.ordreFinancement && data.typeAnomalieLoyer != 'AVEC_ECART'">{{ data.ordreFinancement }}</span>
	<icon *ngIf="data.ordreFinancement && data.typeAnomalieLoyer == 'AVEC_ECART'" name="warning"></icon>
	<icon name="question_mark" *ngIf="!data.ordreFinancement"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<span>
			<a [routerLink]="[]" (click)="showListeFactureDetailsForEcheance()">
				<span>{{ (data.dateEcheance | date:'MMMM yyyy') | capitalizeFirst }}</span><span *ngIf="!data.ordreFinancement">&#160;(<span translate>contrat.financement.echeance.horsPeriodeContrat</span>)</span>
			</a>
		</span>
	</div>
	<ul class="lv-attrs">
		<li><span translate>contrat.financement.echeance.typeAffichagePrix</span>&#160;:&#160;{{ 'contrat.typeAffichagePrix.short.' + data.typeAffichagePrix | translate }}</li>
		<li><span translate>contrat.financement.echeance.theorique</span>&#160;:&#160;{{ data.loyerTheoriqueMensuel | number:'.2-2' }}</li>
		<li><span translate>contrat.financement.echeance.reel</span>&#160;:&#160;{{ data.loyerReelMensuel | number:'.2-2' }}</li>
		<li><span translate>contrat.financement.echeance.theoriqueCumul</span>&#160;:&#160;{{ data.loyerTheoriqueCumul | number:'.2-2' }}</li>
		<li><span translate>contrat.financement.echeance.reelCumul</span>&#160;:&#160;{{ data.loyerReelCumul | number:'.2-2' }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data.typeAnomalieLoyer == 'AVEC_ECART' && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="doValidateEcheance()" translate>contrat.financement.echeance.actions.validerEcheance</a></li>
		</ul>
	</div>
</div>