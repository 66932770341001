<div class="lv-item media">
	<div class="media-body">
		<div class="lv-title">
			<a [routerLink]="[]" (click)="showMailHistoryDetail(data.idMailHistory);">
				<span>{{ data.dateEnvoi | date:'short' }}</span>&#160;-&#160;<span>{{ data.libelle }}</span>
			</a>
		</div>
		<ul class="lv-attrs">
			<li *ngIf="data.nomUser || data.prenomUser">
				<span translate>mailHistory.liste.expediteur</span>&#160;:&#160;
				<span *ngIf="data.nomOriginalUser"><span>{{ data.prenomOriginalUser }}</span>&#160;<span>{{ data.nomOriginalUser }}</span>&#160;(<span>{{ data.tenantOriginalUser }}</span>)&#160;<span translate>mailHistory.liste.pour</span>&#160;</span>
				<span>{{ data.nomUser }}</span>&#160;<span>{{ data.prenomUser }}</span><span *ngIf="data.matriculeUser">&#160;(<span>{{ data.matriculeUser }}</span><span *ngIf="data.societeUser">&#160;-&#160;<span>{{ data.societeUser }}</span></span>)</span>
			</li>
			<li *ngIf="data.nbDestinatairesPrincipaux"><span translate>mailHistory.liste.nbDestinataires</span>&#160;:&#160;<span>{{ data.nbDestinatairesPrincipaux }}</span></li>
			<li *ngIf="data.nbDestinatairesCopie"><span translate>mailHistory.liste.nbCopies</span>&#160;:&#160;<span>{{ data.nbDestinatairesCopie }}</span></li>
			<li *ngIf="data.nbAttachments"><span translate>mailHistory.liste.nbPiecesJointes</span>&#160;:&#160;<span>{{ data.nbAttachments }}</span></li>
		</ul>
	</div>
</div>