<div class="container-fluid">
	<form #extractionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation') || typeReferentiel == TypeReferentiel.STANDARD && !rightService.isRoot()">
				<div class="card-body card-padding">
					<div class="row" *ngIf="extraction.extractionOrigine">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': custom.invalid }">
								<label translate>extraction.customization.item</label>
								<div>
									<mat-checkbox name="custom" #custom="ngModel" [(ngModel)]="extraction.custom" (ngModelChange)="onCustomChange($event)"><i class="input-helper"></i><span translate>extraction.customization.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<fieldset [disabled]="typeReferentiel == TypeReferentiel.LOCAL && extraction.extractionOrigine && !extraction.custom">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
									<label translate>extraction.libelle</label>
									<div>
										<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="extraction.libelle" maxlength="300" required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
									<label translate>extraction.reference</label>
									<div>
										<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="extraction.reference" maxlength="100" required/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': entiteField.invalid }">
									<label translate>extraction.entite</label>
									<div>
										<selectpicker name="entite" #entiteField="ngModel" [(ngModel)]="extraction.entity" required>
											<mat-option *ngFor="let entite of listeEntities" [value]="entite.code">{{ entite.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': typeDiffusionField.invalid }" *ngIf="rightService.isRoot() || rightService.isRevendeur()">
									<label translate>extraction.typeDiffusion.item</label>
									<div>
										<selectpicker name="typeDiffusion" #typeDiffusionField="ngModel" [(ngModel)]="extraction.typeDiffusion" required>
											<mat-option *ngFor="let typeDiffusion of listeTypeDiffusions" [value]="typeDiffusion.code">{{ typeDiffusion.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
									<label>
										<span translate>extraction.actif.item</span>
										<icon name="info_outline" class="m-l-5" [tooltip]="'extraction.actif.description' | translate"></icon>
									</label>
									<div>
										<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="extraction.actif" [disabled]="typeReferentiel == TypeReferentiel.LOCAL && extraction.extractionOrigine && !extraction.custom || !extraction.listeFields?.length"><i class="input-helper"></i><span translate>extraction.actif.info</span></mat-checkbox>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': typeFormatField.invalid }">
									<label translate>extraction.typeFormat.item</label>
									<div class="half">
										<selectpicker name="typeFormat" #typeFormatField="ngModel" [(ngModel)]="extraction.typeFormat" (ngModelChange)="onTypeFormatChange($event)" required>
											<mat-option *ngFor="let typeFormat of listeTypeFormats" [value]="typeFormat.code">{{ typeFormat.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': avancee.invalid }">
									<label>
										<span translate>extraction.avancee.item</span>
										<icon name="info_outline" class="m-l-5" [tooltip]="'extraction.avancee.description' | translate"></icon>
									</label>
									<div>
										<mat-checkbox name="avancee" #avancee="ngModel" [(ngModel)]="extraction.avancee" [disabled]="typeReferentiel == TypeReferentiel.LOCAL && extraction.extractionOrigine && !extraction.custom || extraction.typeFormat != 'XLSX'"><i class="input-helper"></i><span translate>extraction.avancee.info</span></mat-checkbox>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': !extraction.listeLinks?.length }" *ngIf="extraction.avancee">
									<label translate>extraction.piecesJointes</label>
									<div>
										<div [attachment]="extraction" [typeAttachment]="TypeAttachment.EXTRACTION" [readOnly]="typeReferentiel == TypeReferentiel.LOCAL && extraction.extractionOrigine && !extraction.custom" filters=".xls|.xlsx" queueLimit="1"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': remarque.invalid }">
							<label translate>extraction.remarque</label>
							<div>
								<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="extraction.remarque" rows="3"></textarea>
							</div>
						</div>
					</fieldset>
				</div>
			</fieldset>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteExtraction();" *ngIf="extraction?.idExtraction && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveExtraction();" *ngIf="(null | right:'creation') && (typeReferentiel != TypeReferentiel.STANDARD || rightService.isRoot())" [disabled]="extractionForm.invalid || extraction.avancee && !extraction.listeLinks?.length">
				<span [translate]="'actions.'+(extraction?.idExtraction ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="extraction?.idExtraction" [formDetectChanges]="{ doAction: close,isDirty: extractionForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>