<div class="pull-left lv-avatar">{{ data.typeSinistre.reference.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showSinistre()">
			<span translate [translateParams]="{ reference: data.reference }">vehicule.sinistre.item</span>
			<span *ngIf="data.vehicule">&#160;-&#160;{{ data.vehicule | display:'vehicule' }}</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>conducteur.sinistre.dateSinistre</span>&#160;:&#160;{{ data.dateSinistre | date:'short' }}</li>
		<li *ngIf="data.statut"><span translate>vehicule.sinistre.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.sinistre.statut.' + data.statut"></span></li>
	</ul>
</div>