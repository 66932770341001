import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { HistoryComponent } from './history.component';
import { HistoryService } from './history.service';
import { HistoryListItemComponent } from './history-list-item.component';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { HistoryChangeComponent } from './history-change.component';
import { HistoryCollectionChangeComponent } from './history-collection-change.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';


@NgModule({
	imports: [CoreComponentsModule,ListViewModule,IconModule],
	declarations: [HistoryComponent,HistoryListItemComponent,HistoryChangeComponent,HistoryCollectionChangeComponent],
	exports: [HistoryComponent,HistoryListItemComponent],
	providers: [HistoryService]
})
export class HistoryModule {}