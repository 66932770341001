'use strict';

var EventEmitter = require('events').EventEmitter,
  inherits = require('inherits'),
  urlUtils = require('./utils/url'),
  XDR = require('./transport/sender/xdr'),
  XHRCors = require('./transport/sender/xhr-cors'),
  XHRLocal = require('./transport/sender/xhr-local'),
  XHRFake = require('./transport/sender/xhr-fake'),
  InfoIframe = require('./info-iframe'),
  InfoAjax = require('./info-ajax');
var debug = function () {};
if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')('sockjs-client:info-receiver');
}
function InfoReceiver(baseUrl, urlInfo) {
  debug(baseUrl);
  var self = this;
  EventEmitter.call(this);
  setTimeout(function () {
    self.doXhr(baseUrl, urlInfo);
  }, 0);
}
inherits(InfoReceiver, EventEmitter);

// TODO this is currently ignoring the list of available transports and the whitelist

InfoReceiver._getReceiver = function (baseUrl, url, urlInfo) {
  // determine method of CORS support (if needed)
  if (urlInfo.sameOrigin) {
    return new InfoAjax(url, XHRLocal);
  }
  if (XHRCors.enabled) {
    return new InfoAjax(url, XHRCors);
  }
  if (XDR.enabled && urlInfo.sameScheme) {
    return new InfoAjax(url, XDR);
  }
  if (InfoIframe.enabled()) {
    return new InfoIframe(baseUrl, url);
  }
  return new InfoAjax(url, XHRFake);
};
InfoReceiver.prototype.doXhr = function (baseUrl, urlInfo) {
  var self = this,
    url = urlUtils.addPath(baseUrl, '/info');
  debug('doXhr', url);
  this.xo = InfoReceiver._getReceiver(baseUrl, url, urlInfo);
  this.timeoutRef = setTimeout(function () {
    debug('timeout');
    self._cleanup(false);
    self.emit('finish');
  }, InfoReceiver.timeout);
  this.xo.once('finish', function (info, rtt) {
    debug('finish', info, rtt);
    self._cleanup(true);
    self.emit('finish', info, rtt);
  });
};
InfoReceiver.prototype._cleanup = function (wasClean) {
  debug('_cleanup');
  clearTimeout(this.timeoutRef);
  this.timeoutRef = null;
  if (!wasClean && this.xo) {
    this.xo.close();
  }
  this.xo = null;
};
InfoReceiver.prototype.close = function () {
  debug('close');
  this.removeAllListeners();
  this._cleanup(false);
};
InfoReceiver.timeout = 8000;
module.exports = InfoReceiver;