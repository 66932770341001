<list-view [liste]="liste" *ngIf="liste && !selectedModele"></list-view>

<div class="listview lv-bordered">
	<div class="lv-body">
		<ng-container *ngIf="!selectedModele">
			<ng-container *ngIf="liste.data?.content?.length > 0">
				<div class="lv-item media" *ngFor="let modele of liste.data.content">
					<div class="pull-left lv-avatar">{{ modele.modeleCatalogue.reference.substring(0,3).toUpperCase() }}</div>
					<div class="media-body">
						<div class="lv-title"><a [routerLink]="[]" (click)="onModeleSelected(modele.modeleCatalogue);">{{ modele.modeleCatalogue.marque.libelle + ' ' + modele.modeleCatalogue.libelle + (modele.modeleCatalogue.finition ? ' ' + modele.modeleCatalogue.finition : '') }}</a></div>
						<ul class="lv-attrs">
							<li><span translate>vehicule.immatriculation.selectionModeleCatalogue.step.modele.actif</span>&#160;:&#160;<span [translate]="'common.'+(modele.modeleCatalogue.actif ? 'oui' : 'non')"></span></li>
							<li *ngIf="modele.modeleCatalogue.reference"><span translate>vehicule.immatriculation.selectionModeleCatalogue.step.modele.reference</span>&#160;:&#160;{{ modele.modeleCatalogue.reference }}</li>
							<li *ngIf="modele.modeleCatalogue.modeleNiveau1"><span translate>vehicule.immatriculation.selectionModeleCatalogue.step.modele.categorisation</span>&#160;:&#160;<span>{{ (modele.modeleCatalogue.modeleNiveau1 + '/' + modele.modeleCatalogue.modeleNiveau2 + '/' + modele.modeleCatalogue.modeleNiveau3).substring(0,20) }}&#160;<icon *ngIf="(modele.modeleCatalogue.modeleNiveau1 + '/' + modele.modeleCatalogue.modeleNiveau2 + '/' + modele.modeleCatalogue.modeleNiveau3).length > 20" [tooltip]="modele.modeleCatalogue.modeleNiveau1 + '/' + modele.modeleCatalogue.modeleNiveau2 + '/' + modele.modeleCatalogue.modeleNiveau3" name="more_horiz"></icon></span></li>
						</ul>
						<ul class="lv-actions actions">
							<li><a [routerLink]="[]" (click)="showModele(modele.modeleCatalogue);"><icon name="visibility"></icon></a></li>
						</ul>
					</div>
				</div>
			</ng-container>
			<div class="text-center p-10" *ngIf="!liste.data?.content">
				<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
			</div>
			<div class="text-center p-10" *ngIf="liste.data?.content?.length === 0">
				<h5 translate>liste.empty</h5>
			</div>
		</ng-container>
		<div *ngIf="selectedModele" class="p-t-40">
			<div class="lv-item media">
				<div class="pull-left lv-avatar">{{ selectedModele.reference.substring(0,3).toUpperCase() }}</div>
				<div class="media-body">
					<div class="lv-title"><a [routerLink]="[]" (click)="stepperComponent.selectStep(stepperComponent.listeSteps[2]);">{{ selectedModele.marque.libelle + ' ' + selectedModele.libelle + (selectedModele.finition ? ' ' + selectedModele.finition : '') }}</a></div>

					<ul class="lv-actions actions">
						<li><a [routerLink]="[]" (click)="showModele(selectedModele);"><icon name="visibility"></icon></a></li>
						<li><a [routerLink]="[]" (click)="onModeleSelected(null);"><icon name="close"></icon></a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>