import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { TypeEntretienService } from './type-entretien.service';

@Component({
	templateUrl: './type-entretien-add.component.html'
})
export class TypeEntretienAddComponent extends AutocompleteAddComponent {
	/** Type d'entretien **/
	typeEntretien: any = {
		actif: true
	};

	/** Filtre de l'autocomplete **/
	public filter: { source: 'PLAN_ENTRETIEN' };

	/** Taille de la popup **/
	public static modalSize: string = '';

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<TypeEntretienAddComponent>,private typeEntretienService: TypeEntretienService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du type d'entretien
	 */
	saveItem() {
		//Enregistrement du type d'entretien
		this.typeEntretienService.saveTypeEntretien(this.typeEntretien).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du type d'entretien
					this.typeEntretien = result.data.typeEntretien;

					//Mise à jour du type d'entretien créé
					this.addedItem = this.typeEntretien;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}