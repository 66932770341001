'use strict';

var logObject = {};
['log', 'debug', 'warn'].forEach(function (level) {
  var levelExists;
  try {
    levelExists = global.console && global.console[level] && global.console[level].apply;
  } catch (e) {
    // do nothing
  }
  logObject[level] = levelExists ? function () {
    return global.console[level].apply(global.console, arguments);
  } : level === 'log' ? function () {} : logObject.log;
});
module.exports = logObject;