import { CurrencyPipe,DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { reduce } from 'lodash-es';
import { Observable,of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalendarService {
	/** Cache des entités affichables dans le calendrier **/
	private mapEntites: { [key: string]: any } = null;

	/** Association des entités avec leurs paramètres de traduction **/
	private mapTitleDataEntite: { [key: string]: (itemData: any) => any } = {
		'com.notilus.data.vehicule.Entretien': (itemData) => ({
			data: itemData,
			typeEntretien: this.translateService.instant('entretien.type.'+itemData.type),
			typeEcheance: itemData.dateRealisation ? this.translateService.instant('entretien.typeEcheance.realise') : this.translateService.instant('entretien.typeEcheance.previsionnel')
		}),
		'com.notilus.data.facture.Facture': (itemData) => ({
			typeFacture: this.translateService.instant('facture.type.'+itemData.type),
			reference: itemData.reference,
			montant: this.currencyPipe.transform(itemData.montant,'.2-2',itemData.devise),
			fournisseur: itemData.fournisseur.libelle
		}),
		'com.notilus.data.comptabilite.lot.LotComptable': (itemData) => ({
			numeroLot: itemData.numeroLot,
			montant: this.currencyPipe.transform(itemData.montant,'.2-2',itemData.devise)
		}),
		'com.notilus.data.vehicule.etatLieux.EtatLieux': (itemData) => ({
			data: itemData,
			type: this.translateService.instant('vehicule.etatLieux.type.'+(itemData.planifie ? 'planifie' : 'realise'))
		}),
		'com.notilus.data.vehicule.pool.Reservation': (itemData) => ({
			data: itemData,
			dateDepart: this.datePipe.transform(itemData.dateDepart,'short'),
			dateRetour: this.datePipe.transform(itemData.dateRetour,'short')
		})
	};

	/** Association des entités avec leur variante de traduction **/
	private mapTitlePrefixEntite: { [key: string]: (itemData: any) => 'full' | 'light' } = {
		'com.notilus.data.vehicule.Financement': (itemData) => itemData?.vehicule?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.vehicule.Affectation': (itemData) => itemData?.vehicule?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.vehicule.Vehicule': (itemData) => itemData?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.sgi.DemandeIdentification': (itemData) => itemData?.vehicule?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.vehicule.Contravention': (itemData) => itemData?.vehicule?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.vehicule.Entretien': (itemData) => itemData?.vehicule?.modele?.marque ? 'full' : 'light',
		'com.notilus.data.vehicule.EtatLieux': (itemData) => itemData?.vehicule?.modele ? 'full' : 'light'
	};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private datePipe: DatePipe,private currencyPipe: CurrencyPipe,private http: HttpClient) {}

	/**
	 * Récupération du mapping pour le calendrier
	 */
	public retrieveMapping(): Observable<{ [key: string]: any }> {
		//Vérification du mapping
		if (this.mapEntites == null) {
			//Chargement du mapping
			return this.http.post<Result>(`${environment.baseUrl}/controller/Calendrier/retrieveMapping`,null).pipe(map(listeMappings => {
				//Vérification des données
				if (listeMappings && Array.isArray(listeMappings)) {
					//Création de la map
					this.mapEntites = reduce(listeMappings,(map,entite) => {
						//Ajout de l'entité à la map
						map[entite.type] = entite;

						return map;
					},{});

					//Retour du mapping
					return this.mapEntites;
				} else
					//Aucun mapping
					return null;
			}));
		}

		//Retour du mapping mis en cache
		return of(this.mapEntites);
	}

	/**
	 * Récupération de la traduction des événements
	 */
	public getTitleForItem(calendarItem: any) {
		//Retour du titre de l'événement
		return calendarItem.data ? this.translateService.instant(`dashboard.calendar.item.${calendarItem.type.split('.').pop(-1)}.${this.getTitleKeyPrefix(calendarItem)}.${calendarItem.forEnd ? 'fin' : 'debut'}`,this.getTitleInterpolationParams(calendarItem)) : this.translateService.instant('dashboard.calendar.item.noData');
	}

	/**
	 * Récupération des éléments d'un calendrier pour une période donnée
	 */
	public searchItemsForPeriode(startDate: Date,endDate: Date,listeEntites: Array<string>): Observable<Result> {
		//Retour des éléments d'un calendrier pour la période donnée
		return this.http.post<Result>(`${environment.baseUrl}/controller/Calendrier/searchItemsForPeriode/${startDate.getTime()}/${endDate.getTime()}`,listeEntites);
	}

	/**
	 * Récupération des éléments d'un calendrier pour une date donnée
	 */
	public searchItemsForDate(date: number,listeEntites: Array<string>): Observable<Array<any>> {
		//Retour des éléments d'un calendrier pour la date donnée
		return this.http.post<Array<any>>(`${environment.baseUrl}/controller/Calendrier/searchItemsForDate/${date}`,listeEntites);
	}

	/**
	 * Suppression du cache
	 */
	public evictCache() {
		//Suppression du cache des entités
		this.mapEntites = null;
	}

	/**
	 * Récupération des données à utiliser dans la traduction des événements
	 */
	private getTitleInterpolationParams(calendarItem: any) {
		//Retour des données à utiliser dans la traduction des événements
		return this.mapTitleDataEntite[calendarItem.type] ? this.mapTitleDataEntite[calendarItem.type](calendarItem.data) : { data: calendarItem.data };
	}

	/**
	 * Récupération du préfixe de la clé de traduction à utiliser pour un événement
	 */
	private getTitleKeyPrefix(calendarItem: any): 'full' | 'light' {
		//Retour du préfixe de clé de traduction à utiliser
		return this.mapTitlePrefixEntite[calendarItem.type] ? this.mapTitlePrefixEntite[calendarItem.type](calendarItem.data) : 'full';
	}
}