import { AfterViewInit,Component,ElementRef,Input,OnDestroy,OnInit,QueryList,Renderer2,ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DocumentService } from 'src/app/share/components/document/document.service';
import { ArticleService } from './article.service';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';

@Component({
	selector: 'article-consultation',
	templateUrl: './article-consultation.component.html'
})
export class ArticleConsultationComponent implements OnInit,OnDestroy,AfterViewInit {
	/** Article **/
	@Input() article: any;

	/** Contexte d'affichage de l'article **/
	@Input() isModal: boolean;

	/** Liste des rubriques **/
	@ViewChildren('contentContainer') listeContents: QueryList<ElementRef<HTMLParagraphElement>>;

	/** Feedback de l'article **/
	feedback: any = {};

	/** Liste des intercepteurs **/
	listeInterceptors: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private articleService: ArticleService,private activatedRoute: ActivatedRoute,private translateService: TranslateService,private toastrService: ToastrService
			,private confirmService: ConfirmService,private documentService: DocumentService,private renderer: Renderer2,public domSanitizer: DomSanitizer,private attachmentService: AttachmentService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la nécessité de charger l'article
		if (!this.article?.idArticle) {
			//Chargement de l'article
			this.articleService.loadArticle(this.activatedRoute.snapshot.params.idArticle || 0,true).subscribe(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Définition de l'article
					this.article = result.data?.article || null;

					//Vérification de l'article
					if (this.article)
						//Définition des pièces jointes
						this.article.listeLinks = result.data?.listeLinks || [];

					//Préparation de l'article
					this.initArticle();
				}
			});
		} else {
			//Copie de l'article
			this.article = cloneDeep(this.article);

			//Préparation de l'article
			this.initArticle();
		}
	}

	/**
	 * Préparation de l'article
	 */
	initArticle() {
		//Parcours des rubriques
		this.article?.listeContents?.forEach(c => {
			//Récupération du contenu brut
			c.content = this.domSanitizer.bypassSecurityTrustHtml(c.content);
		});

		//Initialisation du feedback de l'article
		this.feedback = {
			listeStars: [1,2,3,4,5].map(() => ({ filled: false })),
			rated: false,
			commented: false
		};
	}

	/**
	 * Chargement de la vue
	 */
	ngAfterViewInit(): void {
		//Interception de l'affichage des rubriques après le chargement de l'article
		this.listeContents.changes.pipe(take(1)).subscribe(c => {
			//Parcours des rubrique
			c.forEach((content: ElementRef<HTMLParagraphElement>) => {
				//Parcours des liens de la rubrique
				content.nativeElement.querySelectorAll('a').forEach((anchor: HTMLAnchorElement) => {
					//Détection des clics sur le lien
					this.listeInterceptors.push(this.renderer.listen(anchor,'click',event => {
						//Vérification de l'url de destination
						if (anchor.host != location.host) {
							//Arrêt de la redirection
							event.preventDefault();

							//Message de confirmation
							this.confirmService.showConfirm(this.translateService.instant('actions.redirection.confirmation',anchor),{ actionColor: 'primary' }).subscribe(isConfirmed => {
								//Vérification de la confirmation
								if (isConfirmed)
									//Ouverture du lien
									window.open(anchor.href);
							});
						}
					}));
				});
			});
		});
	}

	/**
	 * Mise à jour de l'évaluation
	 */
	updateRating(index: number) {
		//Vérification de l'index
		if (index >= 0) {
			//Parcours de la liste des étoiles
			this.feedback.listeStars.forEach((star,i) => {
				//Mise à jour de l'évaluation
				star.filled = i <= index;
			});

			//Définition du score
			this.feedback.score = index + 1;
		}

		//Mise à jour du score
		this.articleService.saveFeedback(this.article.idArticle,this.feedback).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du feedback
				this.feedback = Object.assign(this.feedback,result.data.feedback);

				//Article évalué
				this.feedback.rated = true;

				//Article commenté
				this.feedback.commented = !!this.feedback.commentaire;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Envoi du commentaire pour l'évaluation de l'article
	 */
	sendComment(event: KeyboardEvent) {
		//Appui sur la touche 'Entrée'
		if (event.code == 'Enter' && !event.shiftKey) {
			//Annulation de l'évènement par défaut
			event.preventDefault();

			//Mise à jour de l'évaluation
			this.updateRating(-1);
		}
	}

	/**
	 * Génération d'un document PDF
	 */
	generateDocument() {
		//Génération du document
		this.documentService.generateDocument('com.notilus.data.aide.Article',this.article.idArticle);
	}

	/**
	 * Scroll vers une rubrique
	 */
	scrollTo(id: string) {
		let target: HTMLElement;
		let body: HTMLBodyElement;

		//Récupération du body
		body = document.querySelector('body');

		//Récupération de la cible du scroll
		target = document.getElementById(id);

		//Déclenchement du scroll
		target?.scrollIntoView();

		//Ajout d'un offset au scroll
		document.documentElement.scrollBy(0,body.classList.contains('scrolled') ? -200 : -250);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Suppression des intercepteurs
		this.listeInterceptors.forEach(listener => listener());
	}

	/**
	 * Affichage des pièces jointes
	 */
	showAttachment() {
		//Affichage de la popup de gestion des pièces jointes
		this.attachmentService.showAttachment({
			owningEntity: this.article,
			typeAttachment: TypeAttachment.ARTICLE,
			readOnly: true
		});
	}
}