<div class="listview lv-bordered local no-search">
	<div class="lv-body" *ngIf="demandeVehicule.financement">
		<div class="lv-item media" *ngFor="let financement of [demandeVehicule.financement]">
			<div class="pull-left lv-avatar">{{ financement.typeFinancement.reference.substr(0,3) }}</div>
			<div class="media-body">
				<div class="lv-title">
					<a [routerLink]="[]" (click)="addFinancement(financement,deleteFinancement);"><span>{{ financement.fournisseur?.libelle }}</span>&#160;(<span>{{ financement.typeFinancement.libelle }}</span>)</a>
				</div>
				<ul class="lv-attrs" *ngIf="financement.typeFinancement.mode != 'ACQUISITION'">
					<li><span translate>demandeVehicule.financement.elements.duree</span>&#160;:&#160;<span>{{ financement.duree || 0 }}</span></li>
					<li><span [translateParams]="{ distance: (financement.distance || 0) | number,unite: user.unite.libelleCourt }" translate>demandeVehicule.financement.elements.distance</span></li>
					<li><span translate>demandeVehicule.financement.elements.loyerMensuel</span>&#160;:&#160;<span>{{ (financement.loyerMensuel || 0) | currency:'.2-2':financement.devise }}</span>&#160;(<span>{{ ('contrat.typeAffichagePrix.short.' + financement.typeAffichagePrix) | translate }}</span>)</li>
				</ul>
				<div class="lv-actions actions" *ngIf="rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'suppression') && !['LIVRE','ANNULEE'].includes(demandeVehicule.statut)">
					<a [routerLink]="[]" (click)="deleteFinancement();"><icon name="delete"></icon></a>
				</div>
			</div>
		</div>
	</div>
	<div class="lv-body text-center p-10" *ngIf="!demandeVehicule.financement">
		<h5><span translate>liste.empty</span></h5>
	</div>
</div>