<div class="listview lv-bordered no-search">
	<div class="lv-body">
		<div class="lv-item media" *ngFor="let detail of getListe()">
			<div class="pull-left lv-avatar">
				<span><icon [name]="getIconeFor(detail).icone" [type]="getIconeFor(detail).iconeType"></icon></span>
			</div>
			<div class="media-body">
				<div class="lv-title"><a [routerLink]="[]" (click)="showDetail(detail);"><span>{{ translateEntityCode(detail.entite.split('.').pop(-1)) }}</span></a></div>

				<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation')">
					<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
					<ul class="dropdown-menu dropdown-menu-right">
						<li><a [routerLink]="[]" (click)="deleteDetail(detail);" *ngIf="(null | right:'creation')"><span translate>actions.supprimer</span></a></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="text-center p-10" *ngIf="!getListe() || getListe().length == 0">
			<h5><span translate>liste.empty</span></h5>
		</div>
	</div>
</div>