import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class TypeAmenagementService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement du type d'aménagement
	 */
	public loadTypeAmenagement(idTypeAmenagement: number): Observable<Result> {
		//Chargement du type d'aménagement
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadTypeAmenagement/${idTypeAmenagement}`,null);
	}

	/**
	 * Suppression du type d'aménagement
	 */
	public deleteTypeAmenagement(typeAmenagement: any): Observable<Result> {
		//Suppression du type d'aménagement
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteTypeAmenagement/${typeAmenagement.idTypeAmenagement}`);
	}

	/**
	 * Enregistrement du type d'aménagement
	 */
	public saveTypeAmenagement(typeAmenagement: any): Observable<Result> {
		//Enregistrement du type d'aménagement
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveTypeAmenagement`,typeAmenagement);
	}
}