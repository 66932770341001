import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'connecteur-destinataires',
	templateUrl: './connecteur-destinataires.component.html'
})
export class ConnecteurDestinatairesComponent implements AfterContentChecked,OnInit {
	/** Liste des adresses mail **/
	@Input() listeEmails: Array<{ value?: string,key?: number }>;

	/** Résultat **/
	result: { listeEmails: Array<string> }

	/** Clé d'ajout d'email **/
	lastKeyUsed: number = 0;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ConnecteurDestinatairesComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la clé d'ajout d'emails
		this.listeEmails.forEach(e => e.key = this.lastKeyUsed++);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Ajout d'une nouvelle adresse
	 */
	public addEmail() {
		//Ajout d'un nouveau mail à la liste
		this.listeEmails.push({ key: this.lastKeyUsed++ });
	}

	/**
	 * Suppression d'une adresse de la liste
	 */
	public deleteEmail(index: number) {
		//Suppression de l'élément
		this.listeEmails.splice(index,1);
	}

	/**
	 * Enregistrement des adresses mail
	 */
	public saveDestinataires() {
		//Définition du résultat
		this.result = {
			listeEmails: this.listeEmails.map(e => e.value).filter(v => !!v)
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la moadal
		this.bsModalRef.hide();
	}
}
