<tr class="lines">
	<td [colSpan]="isChild ? 2 : undefined">
		<table [cellPadding]="0" [cellSpacing]="0">
			<tbody>
				<tr class="lines">
					<td class="startline" [ngClass]="{ 'line-left': mode != 'vertical' }"></td>
					<td class="startline" [ngClass]="{ 'line-right': mode != 'vertical' }"></td>
				</tr>
				<tr class="lines" *ngIf="['counter','counter-branche'].includes(mode) && isChild">
					<td class="startline line-left"></td>
					<td class="startline line-right"></td>
				</tr>
				<tr *ngIf="['counter','counter-branche'].includes(mode)" class="lines">
					<td colspan="2">
						<div class="counter">
							<span>{{ countItemsForListeEtapes() }}</span>
						</div>
					</td>
				</tr>
				<tr *ngIf="['counter','counter-branche'].includes(mode)" class="lines">
					<td class="startline line-left"></td>
					<td class="startline line-right"></td>
				</tr>
			</tbody>
		</table>
	</td>
</tr>