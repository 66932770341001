import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleTaxeService } from './regle-taxe.service';

@Component({
	templateUrl: './regle-taxe-poste-charge-list-item.component.html'
})
export class RegleTaxePosteChargeListItemComponent extends ListViewItem<any> implements OnInit {
	/** Paramètrage du poste de charge **/
	public posteChargeSettings: { state: string,fullIdentifier: string,identifier: string,typeDroit: TypeDroit };

	/**
	 * Constructeur
	 */
	constructor(private regleTaxeService: RegleTaxeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,public rightService: RightService,private compteService: CompteService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition du paramétrage du poste de charge
		this.posteChargeSettings = this.compteService.getMappingForPostesCharge()[this.data?.type];
	}

	/**
	 * Suppression de l'objet
	 */
	deleteLienRegleTaxeDetail() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.regleTaxeService.deleteLienRegleTaxeDetail(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Navigation vers le poste de charge
	 */
	goToPosteCharge() {
		//Navigation vers le poste de charge
		this.layoutService.goToByState(this.posteChargeSettings.state,{
			routeParams: {
				[this.posteChargeSettings.identifier]: get(this.data,this.posteChargeSettings.fullIdentifier)
			},
			withGoBack: true
		});
	}
}