import { Injectable,Renderer2,RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { Subject } from 'rxjs';
import { map,take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment,TypeAttachmentContext } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { FileWithProgress } from 'src/app/share/directive/file-uploader/file-uploader';
import { environment } from 'src/environments/environment';
import { AttachmentComponent } from './attachment.component';
import { AttachmentEntity } from 'src/app/domain/attachment/attachment';

@Injectable()
export class AttachmentService {
	/** Moteur de rendu **/
	private renderer: Renderer2;

	/** Compteur de pièces jointes pour le type d'attachment courant **/
	private attachmentState: { typeAttachment: TypeAttachment,nbAttachments: number };

	/** Sujet de modification du type d'attachment pour lequel dénombrer les pièces jointes **/
	attachmentSubject: Subject<{ typeAttachment: TypeAttachment,nbAttachments: number }> = new Subject();

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private rendererFactory: RendererFactory2,private toastrService: ToastrService,private translateService: TranslateService,private bsModalService: BsModalService) {
		//Création du moteur de rendu
		this.renderer = rendererFactory.createRenderer(null,null);
	}

	/**
	 * Finalisation d'un upload
	 */
	public onItemUploaded(liste: ListView<any,any>,attachmentOptions: AttachmentOptions,result: Result,file: FileWithProgress) {
		let blob: any;
		let isUnsafe: boolean;
		let attachment: any;
		let isAjoutNeeded: boolean;

		//Récupération du blob
		blob = result?.data?.blob || null;

		//Récupération de l'indicateur
		isUnsafe = result?.data?.unsafe;

		//Vérification du blob
		if (blob) {
			//Création de l'attachment
			attachment = {
				name: file.name,
				idBlob: blob.idBlob,
				size: blob.size,
				contentType: blob.contentType,
				extension: blob.extension,
				dateUpdate: blob.dateUpdate,
				type: attachmentOptions.typeAttachment
			}

			//Création du lien
			isAjoutNeeded = !this.createAttachmentLink(attachmentOptions,[attachment]);

			//Vérification de la néessité d'enregistrer l'attachment
			if (!attachmentOptions.withoutAttachmentSaving) {
				//Enregistrement de l'attachment
				this.saveAttachment(attachment).subscribe({
					next: savedAttachment => {
						let idxAttachment: number;

						//Fin du traitement
						file.isFinished = true;

						//Vérification de la nécessité de mettre à jour le compteur de pièces jointes du formulaire complexe
						if (!attachmentOptions.isFromLink || attachmentOptions.parentOwningEntity)
							//Mise à jour du compteur de pièce jointe de l'entité principale
							this.incrementAttachments(1,!attachmentOptions.parentOwningEntity ? attachment.type : TypeAttachmentContext[attachment.type].mainType);

						//Vérification de l'identifiant de l'objet
						if (attachmentOptions.idObjet)
							//Ajout de l'élément à la liste
							liste?.data?.content.push(savedAttachment);

						//Recherche de la pièce-jointe dans l'objet
						idxAttachment = (attachmentOptions.owningEntity.listeLinks || []).findIndex(link => link?.attachment?.idBlob == attachment.idBlob);

						//Vérification de la position
						if (idxAttachment != -1)
							//Mise à jour de la pièce-jointe
							attachmentOptions.owningEntity.listeLinks[idxAttachment].attachment = savedAttachment;

						//Vérification de la navigation
						if (attachmentOptions.navigation && attachmentOptions.owningEntity?.listeLinks)
							//Navigation sur la dernière position
							attachmentOptions.navigation.currentIndex = attachmentOptions.owningEntity.listeLinks.filter(l => !attachmentOptions.typeLink || l.type == attachmentOptions.typeLink).length;

						//Vérification de l'ajout nécessaire à l'objet
						if (isAjoutNeeded || idxAttachment == -1) {
							//Ajout de la pièce jointe
							attachmentOptions.owningEntity.listeLinks.push({
								_type: TypeAttachmentContext[attachmentOptions.typeAttachment].type,
								type: attachmentOptions.typeLink,
								attachment: savedAttachment,
								objet: !isAjoutNeeded && {
									[TypeAttachmentContext[attachmentOptions.typeAttachment].key]: attachmentOptions.owningEntity[TypeAttachmentContext[attachmentOptions.typeAttachment].key],
									objectVersion: attachmentOptions.owningEntity['objectVersion']
								} || null,
								mainObjet: !isAjoutNeeded && attachmentOptions.parentOwningEntity && {
									[TypeAttachmentContext[TypeAttachmentContext[attachmentOptions.typeAttachment].mainType].key]: attachmentOptions.parentOwningEntity[TypeAttachmentContext[TypeAttachmentContext[attachmentOptions.typeAttachment].mainType].key],
									objectVersion: attachmentOptions.parentOwningEntity['objectVersion']
								} || null
							});
						}

						//Vérification de l'auto-upload
						if (attachmentOptions.autoUpload) {
							//Fin du traitement
							file.isFinished = true;

							//Finalisation de l'élément
							attachmentOptions.onCompleteItem?.(file,result,{
								_type: TypeAttachmentContext[attachmentOptions.typeAttachment].type,
								type: attachmentOptions.typeLink,
								attachment: attachment
							});
						}
					}
				});
			} else {
				//Fin du traitement
				file.isFinished = true;

				//Finalisation de l'élément
				attachmentOptions.onCompleteItem?.(file,result,{
					_type: TypeAttachmentContext[attachmentOptions.typeAttachment].type,
					type: attachmentOptions.typeLink,
					attachment: attachment
				});
			}
		} else if (!blob) {
			//Définition du statut 'unsafe'
			file.isUnsafe = isUnsafe;

			//Réinitialisation de la progression
			file.progress$ = of(0);

			//Vérification de l'indicateur 'unsafe'
			if (isUnsafe)
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('attachment.unsafeTrue',{ fileName: file.name }));
			else if (isUnsafe === null)
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('attachment.unsafeNull',{ fileName: file.name }));
			else
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('attachment.errors.contentNotAuthorized'));
		}
	}

	/**
	 * Suppression d'une pièce jointe
	 */
	deleteAttachment(attachment: any): Observable<Result> {
		//Suppression de la pièce jointe
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Attachment/deleteAttachment/${attachment.idAttachment}`,{
			headers: {
				'ATTACHMENT': 'true'
			}
		});
	}

	/**
	 * Téléchargement d'un blob
	 */
	downloadBlob(idBlob: string) {
		//Téléchargement du blob
		return this.http.get(`${environment.baseUrl}/controller/Blob/readBlob/${idBlob}`,{
			responseType: 'arraybuffer'
		});
	}

	/**
	 * Téléchargement de l'attachment dans le navigateur
	 */
	downloadAttachment(data: any,dataAsBase64: string,contentType: string,name: string,target?: string) {
		let blob: any;
		let link: any;

		//Création du Blob
		blob = data ? new Blob([data],{ type: contentType }) : null;

		//Création d'un lien HTML
		link = this.renderer.createElement('a');

		//Définition des attributs du lien
		this.renderer.setProperty(link,'href',blob ? window.URL.createObjectURL(blob) : dataAsBase64);
		this.renderer.setProperty(link,'type',contentType);

		//Vérification de la cible
		if (target)
			//Définition de la cible
			this.renderer.setProperty(link,'target',target);
		else
			//Téléchargement direct du fichier
			this.renderer.setProperty(link,'download',name);

		//Ajout du lien au DOM
		this.renderer.appendChild(document.body,link);

		//Simulation du clic sur le lien
		link.click();

		//Suppression asynchrone du lien
		setTimeout(() => {
			//Suppression du DOM
			link.remove();

			//Libération du blob
			blob && window.URL.revokeObjectURL(blob);
		},100);
	}

	/**
	 * Vérification de la présence d'une image
	 */
	isFileImage(filename: string) {
		let extension;

		//Récupération de l'extension
		extension = filename.split('.').pop().toLowerCase();

		//Vérification de l'extension
		return '|jpg|png|jpeg|bmp|gif|'.indexOf(extension) !== -1;
	}

	/**
	 * Affichage des pièces jointes
	 */
	showAttachment(options: AttachmentOptions) {
		//Vérification de l'entité parent
		if (options.owningEntity)
			//Initialisation des liens
			options.owningEntity.listeLinks = options.owningEntity.listeLinks || [];

		//Affichage de la popup de gestion des pièces jointes
		this.bsModalService.show(AttachmentComponent,{
			class: 'modal-lg',
			initialState: { options }
		});
	}

	/**
	 * Enregistrement d'un attachment
	 */
	private saveAttachment(attachment: any): Observable<any> {
		//Enregistrement de l'attachment
		return this.http.put<Result>(`${environment.baseUrl}/controller/Attachment/saveAttachment`,attachment,{
			headers: {
				'ATTACHMENT': 'true'
			}
		}).pipe(
			take(1),
			map(result => result?.data?.attachment)
		);
	}

	/**
	 * Création du lien avec l'attachment et l'objet
	 */
	private createAttachmentLink(attachmentOptions: AttachmentOptions,listeAttachments: Array<any>): boolean {
		//Vérification de l'identifiant de l'objet
		if (attachmentOptions.idObjet) {
			//Parcours des attachments
			listeAttachments.forEach(attachment => {
				//Création du lien
				attachment.lien = {
					objet: {
						[TypeAttachmentContext[attachmentOptions.typeAttachment].key]: attachmentOptions.owningEntity[TypeAttachmentContext[attachmentOptions.typeAttachment].key],
						objectVersion: attachmentOptions.owningEntity['objectVersion']
					},
					mainObjet: attachmentOptions.parentOwningEntity && {
						[TypeAttachmentContext[TypeAttachmentContext[attachmentOptions.typeAttachment].mainType].key]: attachmentOptions.parentOwningEntity[TypeAttachmentContext[TypeAttachmentContext[attachmentOptions.typeAttachment].mainType].key],
						objectVersion: attachmentOptions.parentOwningEntity['objectVersion']
					} || null,
					type: attachmentOptions.typeLink || null,
					_type: TypeAttachmentContext[attachmentOptions.typeAttachment].type
				}
			});

			//Création du lien réalisé
			return true;
		} else
			//Création du lien impossible (à créer en fallback)
			return false;
	}

	/**
	 * Dénombrement des pièces jointes
	 */
	countAttachments(attachmentEntity: AttachmentEntity): Observable<Result> {
		let idObjet: number;

		//Récupération de l'identifiant de l'objet
		idObjet = attachmentEntity.getOwningEntity()?.[TypeAttachmentContext[attachmentEntity.typeAttachment].key];

		//Dénombrement des pièces jointes
		return idObjet ? this.http.post<Result>(`${environment.baseUrl}/controller/Attachment/countAttachments/${attachmentEntity.typeAttachment}/${idObjet}`,null,{
			params: attachmentEntity.typeLinkClass && {
				typeLinkClass: attachmentEntity.typeLinkClass,
				typeLink: attachmentEntity.typeLink,
			}
		}) : of(null);
	}

	/**
	 * Initialisation du compteur de pièces jointes
	 */
	initAttachments(entity: AttachmentEntity) {
		//Dénombrement des pièces jointes
		this.countAttachments(entity).subscribe(result => {
			//Définition du compteur de pièces jointes pour le type
			this.attachmentState = {
				typeAttachment: entity.typeAttachment,
				nbAttachments: result?.data?.nbAttachments || null
			};

			//Notification de l'initialisation du compteur
			this.attachmentSubject.next(this.attachmentState);
		});
	}

	/**
	 * Mise à jour du nombre de pièces jointes
	 */
	incrementAttachments(delta: number,typeAttachment: TypeAttachment) {
		//Vérification que la demande d'incrément concerne le type de pièce jointe affiché
		if (typeAttachment == this.attachmentState.typeAttachment)
			//Mise à jour du compteur de pièces jointes
			this.attachmentState.nbAttachments = (this.attachmentState.nbAttachments || 0) + delta;
	}

	/**
	 * Remise à zéro du compteur de pièces jointes
	 */
	reset() {
		//Remise à zéro du compteur de pièces jointes
		this.attachmentState = { typeAttachment: null,nbAttachments: null };

		//Notification de la remise à zéro
		this.attachmentSubject.next(this.attachmentState);
	}

	/**
	 * Récupération du nombre de pièces jointes affiché
	 */
	getNbAttachments(): number {
		//Retour du nombre de pièces jointes
		return this.attachmentState?.nbAttachments;
	}

	/**
	 * Mise à jour de la visiblité de la pièce jointe
	 */
	updateVisibility(attachment: any): Observable<Result> {
		//Mise à jour de la visibilité de la pièce jointe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Attachment/updateVisibility/${attachment.idAttachment}`,null,{
			headers: {
				'ATTACHMENT': 'true'
			}
		});
	}
}