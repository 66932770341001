import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { User } from 'src/app/domain/user/user';

@Component({
	selector: 'conducteur-releve-compteur-edit',
	templateUrl: './conducteur-releve-compteur-edit.component.html'
})
export class ConducteurReleveCompteurEditComponent extends PageContentItem {
	/** Relevé de compteur **/
	@Input() releveCompteur: any;

	/** Utilisateur connecté **/
	@Input() user: User;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}