import { Component,Input } from '@angular/core';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment,TypeAttachmentContext } from 'src/app/domain/attachment/type-attachment';
import { AttachmentService } from './attachment.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: '[attachment]',
	templateUrl: './attachment-link.component.html'
})
export class AttachmentLinkComponent {
	/** Objet portant directement la liste des liens **/
	@Input('attachment') owningEntity: LinksOwningEntity;

	/** Objet parent collectant les liens de l'objet **/
	@Input() parentOwningEntity?: LinksOwningEntity;

	/** Type de pièce jointe **/
	@Input() typeAttachment: TypeAttachment;

	/** Extensions autorisées **/
	@Input() filters?: string;

	/** Restriction des caractères **/
	@Input() characterRestrictions?: string;

	/** Limite de queue **/
	@Input() queueLimit: number;

	/** Indicateur de lecture seule **/
	@Input() readOnly: boolean;

	/** Affichage du libellé **/
	@Input() showLabel: boolean = true;

	/** Sous-catégorie d'une pièce-jointe **/
	@Input() typeLink?: string;

	/** Types de droit (utilisation du droit de la route par défaut) **/
	@Input() listeTypesDroit?: Array<TypeDroit>;

	/** Lien public **/
	@Input() public?: boolean;

	/**
	 * Constructor
	 */
	constructor(private attachmentService: AttachmentService) { }

	/**
	 * Affichage des pièces jointes
	 */
	showAttachment() {
		//Affichage de la popup de gestion des pièces jointes
		this.attachmentService.showAttachment({
			owningEntity: this.owningEntity,
			parentOwningEntity: this.parentOwningEntity,
			typeAttachment: this.typeAttachment,
			idObjet: this.owningEntity?.[TypeAttachmentContext[this.typeAttachment].key] || null,
			filters: this.filters,
			characterRestrictions: this.characterRestrictions,
			queueLimit: this.queueLimit,
			readOnly: this.readOnly,
			isFromLink: true,
			typeLink: this.typeLink,
			typeLinkClass: TypeAttachmentContext[this.typeAttachment]?.typeLinkClass || null,
			listeTypesDroit: this.listeTypesDroit || null,
			public: this.public
		});
	}
}