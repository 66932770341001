import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ArticleCRMListItemComponent } from './article-crm-list-item.component';
import { ArticleCRMService } from './article-crm.service';

@Component({
	templateUrl: './article-crm-list.component.html'
})
export class ArticleCRMListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ArticleCRMListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private articleCRMService: ArticleCRMService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ArticleCRMListItemComponent>({
			uri: `/controller/Commerce/filtreArticlesCRM`,
			title: this.translateService.instant('articleCRM.liste.title'),
			component: ArticleCRMListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: 'libelle',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(i => !i.idArticle) && this.liste.addItem({
					isDisplayed: true,
					actif: true
				});
			})
		});
	}
}