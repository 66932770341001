<fieldset #fieldset [disabled]="!(null | right:'creation') || ['COMPTABILISEE','VALIDEE'].includes(facture?.statut) || isConsultation">
	<!-- Alerte - Incohérence sur le montant -->
	<div class="alert alert-warning" *ngIf="isTaxeInvalid">
		<icon name="info"></icon>
		<div>
			<strong translate>facture.detail.alerte.taxeInvalide.title</strong>
			<div translate>facture.detail.alerte.taxeInvalide.message</div>
		</div>
	</div>

	<!-- Alerte - Rejet dans le workflow -->
	<div class="alert alert-warning" *ngIf="detail.statut == 'REJETEE' && detail.workflowHistoryMotif">
		<icon name="info"></icon>
		<div>
			<strong [translateParams]="{ motif: detail?.workflowHistoryMotif?.motif?.libelle }" translate>facture.detail.alerte.rejetee.title</strong>
			<div *ngIf="detail?.workflowHistoryMotif?.description">{{ detail?.workflowHistoryMotif?.description }}</div>
		</div>
	</div>

	<!-- Alerte - Duplication -->
	<div class="alert alert-default alert-warning alert-no-shadow" *ngIf="isDuplication">
		<icon name="content_copy"></icon>
		<strong translate>facture.detail.alerte.duplication.title</strong>
	</div>

	<!-- Liste des anomalies -->
	<div class="alert alert-danger" *ngIf="detail.listeAnomalies && hasTypeAnomalie('TAXE',undefined,true) && reconciliation.isVisible && detail.anomalie">
		<icon name="warning"></icon>
		<div>
			<strong translate>facture.detail.alerte.reconciliation.title</strong>
			<ul class="m-t-5">
				<li *ngFor="let anomalie of detail.listeAnomalies" [hidden]="anomalie.typeAnomalie == 'TAXE'">
					<span>
						<span *ngIf="!anomalie.reference" translate>facture.detail.alerte.reconciliation.inconnue</span>
						<span *ngIf="anomalie.reference" [translate]="'facture.detail.alerte.reconciliation.' + anomalie.typeAnomalie" [translateParams]="{ reference: anomalie.reference }"></span>
						<span *ngIf="reconciliation.selectedTypeReconciliation == null">
							<div translate>facture.detail.alerte.reconciliation.association.message</div>
							<ul>
								<li *ngFor="let typeReconciliation of listeTypesReconciliation">
									<p class="m-0">
										<a [routerLink]="[]" (click)="reconciliation.selectedTypeReconciliation = typeReconciliation.code" [translate]="'facture.detail.alerte.reconciliation.association.' + typeReconciliation.code"></a>
									</p>
								</li>
							</ul>
						</span>
					</span>
				</li>
			</ul>
		</div>
	</div>

	<!-- Alerte - Anomalie sur la taxe -->
	<div class="alert alert-danger" *ngIf="detail.listeAnomalies && hasTypeAnomalie('TAXE',undefined,false) && reconciliation.isVisible && detail.anomalie">
		<icon name="warning"></icon>
		<div>
			<strong translate>facture.detail.alerte.taxeIncorrecte.title</strong>
			<div translate>facture.detail.alerte.taxeIncorrecte.message</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-6">
			<!-- Nature -->
			<div class="form-group" [ngClass]="{ 'has-error': !detail.libelleNature && (detail.nature || !detail.codeFournisseur) && nature.invalid }">
				<label translate>facture.detail.nature</label>
				<div>
					<autocomplete [hidden]="!(!detail.libelleNature && (detail.nature || !detail.codeFournisseur))" name="nature" type="fournisseurNature" [filter]="{ idFournisseur: detail?.facture?.fournisseur?.idFournisseur,typeReconciliation: source }" #nature="ngModel" [(ngModel)]="detail.nature" (ngModelChange)="onNatureChange();" [required]="!(!detail.libelleNature && !detail.nature && detail.codeFournisseur?.libelle)"></autocomplete>
					<div *ngIf="detail.libelleNature">
						<mat-chip>
							{{ (detail.libelleNature || detail.nature?.libelle) + (detail?.codeFournisseur?.libelle ? ' (' + detail.codeFournisseur?.libelle + ')' : '') }}
							<button matChipRemove *ngIf="!fieldset.disabled && !facture?.interfaceFournisseur" (click)="detail.libelleNature = null; detail.nature = null;">
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</div>
				</div>
			</div>

			<!-- Référence -->
			<div class="form-group" *ngIf="detail?.facture && isConsultation && !source">
				<label translate>facture.detail.reference</label>
				<div>
					<span [translate]="'facture.reference.' + facture.type" [translateParams]="{ reference: facture.reference }"></span>
				</div>
			</div>

			<!-- Type de réconciliation -->
			<div class="form-group" [ngClass]="{ 'has-error': typeReconciliationField.invalid }" *ngIf="reconciliation.selectedTypeReconciliation != null && reconciliation.isVisible && (!detail.importe || detail.editable && getAnomalieReconciliation() != null)">
				<label translate>facture.detail.typeReconciliation.item</label>
				<div>
					<selectpicker name="typeReconciliation" #typeReconciliationField="ngModel" [(ngModel)]="reconciliation.selectedTypeReconciliation" (ngModelChange)="onTypeReconciliationChange()" required>
						<mat-option *ngFor="let typeReconciliation of listeTypesReconciliation" [value]="typeReconciliation.code">{{ typeReconciliation.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>

			<!-- Champs spécifiques à la réconciliation -->
			<ng-container *ngIf="(detail.nature || detail.libelleNature || detail.codeFournisseur) && (source != 'VEHICULE' && source != 'EQUIPEMENT' || !isConsultation) || detail.editable && getAnomalieReconciliation() != null">
				<!-- Réconciliation sur le véhicule -->
				<div class="form-group" [ngClass]="{ 'has-error': vehicule.invalid }" *ngIf="reconciliation.selectedTypeReconciliation == 'VEHICULE'">
					<label translate>facture.detail.vehicule</label>
					<div>
						<autocomplete [hidden]="detail.lien?.objet" name="vehicule" type="vehicule" #vehicule="ngModel" [(ngModel)]="detail.lien.objet" [filter]="{ typeVehicule: ['VEHICULE_FONCTION_SERVICE','ENGIN'],isShowAll: true,isWithEngin: true,isWithDemandeVehicule: (TypeDroit.DEMANDE_VEHICULE | right:'creation') }" (ngModelChange)="onVehiculeChange()" required></autocomplete>
						<div *ngIf="detail.lien?.objet">
							<mat-chip-listbox>
								<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') && !(source == 'VEHICULE' && !isConsultation) }" (click)="goToObject()" (removed)="detail.lien.objet = null;">
									{{ detail.lien.objet | display:'vehicule' }}
									<button matChipRemove *ngIf="!fieldset.disabled && (!facture.interfaceFournisseur || !!facture.interfaceFournisseur && detail.editable) && source != 'VEHICULE'">
										<mat-icon>cancel</mat-icon>
									</button>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>

				<!-- Réconciliation sur le collaborateur -->
				<div class="form-group" [ngClass]="{ 'has-error': user.invalid }" *ngIf="reconciliation.selectedTypeReconciliation == 'COLLABORATEUR'">
					<label translate>facture.detail.user</label>
					<div>
						<autocomplete [hidden]="detail?.lien?.objet" name="user" type="user" #user="ngModel" [(ngModel)]="detail.lien.objet" [filter]="{ isShowAll: true }" required></autocomplete>
						<div *ngIf="detail.lien?.objet">
							<mat-chip-listbox>
								<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_UTILISATEUR | right:'consultation') && !isConsultation }" (click)="goToObject()" (removed)="detail.lien.objet = null;">
									{{ detail.lien.objet | display:'user' }}
									<button matChipRemove *ngIf="!fieldset.disabled && (!facture.interfaceFournisseur || !!facture.interfaceFournisseur && detail.editable) && source != 'VEHICULE'">
										<mat-icon>cancel</mat-icon>
									</button>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>

				<!-- Réconciliation sur l'équipement -->
				<div class="form-group" [ngClass]="{ 'has-error': equipement.invalid }" *ngIf="reconciliation.selectedTypeReconciliation == 'EQUIPEMENT'">
					<label translate>facture.detail.equipement</label>
					<div>
						<autocomplete [hidden]="detail.lien?.objet" name="equipement" type="equipement" #equipement="ngModel" [(ngModel)]="detail.lien.objet" [filter]="{ idFournisseur: facture.fournisseur?.idFournisseur,fournisseur: facture.fournisseur,isShowAll: true }" required></autocomplete>
						<div *ngIf="detail.lien?.objet">
							<mat-chip-listbox>
								<mat-chip [ngClass]="{ 'clickable': (TypeDroit.EQUIPEMENT | right:'consultation') && !(source == 'EQUIPEMENT' && !isConsultation) }" (click)="goToObject()" (removed)="detail.lien.objet = null;">
									{{ detail.lien.objet | display:'equipement' }}
									<button matChipRemove *ngIf="!fieldset.disabled && (!facture.interfaceFournisseur || !!facture.interfaceFournisseur && detail.editable) && source != 'EQUIPEMENT'">
										<mat-icon>cancel</mat-icon>
									</button>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>

				<!-- Réconciliation sur la société -->
				<div class="form-group" *ngIf="reconciliation.selectedTypeReconciliation == 'SOCIETE'">
					<label translate>facture.detail.societe</label>
					<div>
						<mat-chip-listbox>
							<mat-chip>
								{{ ((facture.societe || detail.lien?.objet) | display:'societe') || ('common.nonIdentifiee' | translate) }}
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
			</ng-container>

			<!--Champs spécifiques-->
			<ng-container *ngIf="detail.nature || detail.libelleNature || detail.codeFournisseur">
				<!-- Date -->
				<div class="form-group" [ngClass]="{ 'has-error': date.invalid }">
					<label translate>facture.detail.date</label>
					<div class="half">
						<div class="input-group">
							<input type="text" class="form-control" name="date" #date="ngModel" [(ngModel)]="detail.date" datePicker format="date" required [disabled]="!!facture.interfaceFournisseur" (ngModelChange)="onPaysDateChange()"/>
							<div class="input-group-addon" *ngIf="detail.date > dateCourante">
								<icon name="warning" [tooltip]="'facture.detail.dateFuture' | translate"></icon>
							</div>
						</div>
					</div>
				</div>

				<!-- Entretien -->
				<div class="form-group" [ngClass]="{ 'has-error': typeEntretienField.invalid }" *ngIf="!detail.importe && (extraFields.ENTRETIEN || extraFields.REALISATION_CONTROLE_TECHNIQUE || extraFields.REALISATION_CONTROLE_ANTIPOLLUTION) && reconciliation.selectedTypeReconciliation == 'VEHICULE' && (detail.lien?.objet?.typeVehicule == 'VEHICULE_FONCTION_SERVICE' || detail.lien?.objet?.typeVehicule == 'ENGIN') || detail.importe && detail.lien?.entretien">
					<label translate>facture.detail.entretien</label>
					<div>
						<div class="input-group">
							<selectpicker name="entretien" #typeEntretienField="ngModel" [(ngModel)]="detail.typeEntretien" [disabled]="detail.date > dateCourante" (ngModelChange)="onTypeEntretienChange()" [placeholder]="'common.aucun' | translate">
								<mat-option [value]="undefined"><span translate>common.aucun</span></mat-option>
								<mat-option *ngFor="let typeEntretien of listeTypesEntretien" [value]="typeEntretien.code">{{ typeEntretien.libelle }}</mat-option>
							</selectpicker>
							<div class="input-group-addon" *ngIf="detail.date > dateCourante">
								<icon name="warning" [tooltip]="'facture.detail.entretienFutur' | translate"></icon>
							</div>
						</div>
					</div>
				</div>

				<!-- Sous-type -->
				<div class="form-group" [ngClass]="{ 'has-error': sousTypeField.invalid }" *ngIf="detail.typeEntretien == 'CONTROLE_REGLEMENTAIRE' && (!detail.importe && (extraFields.ENTRETIEN || extraFields.REALISATION_CONTROLE_TECHNIQUE || extraFields.REALISATION_CONTROLE_ANTIPOLLUTION) && reconciliation.selectedTypeReconciliation == 'VEHICULE' && (detail.lien?.objet?.typeVehicule == 'VEHICULE_FONCTION_SERVICE' || detail.lien?.objet?.typeVehicule == 'ENGIN') || detail.importe && detail.lien?.entretien)">
					<label translate>facture.detail.sousType</label>
					<div>
						<selectpicker name="sousType" #sousTypeField="ngModel" [(ngModel)]="detail.sousType" [disabled]="true" required>
							<mat-option *ngFor="let sousType of listeSousTypes" [value]="sousType.code">{{ sousType.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>

				<!-- Type d'entretien -->
				<div class="form-group" [ngClass]="{ 'has-error': typeEntretienItem.invalid }" *ngIf="(!detail.importe && (extraFields.ENTRETIEN || extraFields.REALISATION_CONTROLE_TECHNIQUE || extraFields.REALISATION_CONTROLE_ANTIPOLLUTION) && reconciliation.selectedTypeReconciliation == 'VEHICULE' && (detail.lien?.objet?.typeVehicule == 'VEHICULE_FONCTION_SERVICE' || detail.lien?.objet?.typeVehicule == 'ENGIN') || detail.importe && detail.lien?.entretien) && detail.typeEntretien == 'COURANT'">
					<label translate>facture.detail.typeEntretien</label>
					<div>
						<autocomplete name="typeEntretienItem" type="typeEntretien" #typeEntretienItem="ngModel" [(ngModel)]="detail.typeEntretienItem" [disabled]="detail.date > dateCourante"></autocomplete>
					</div>
				</div>

				<!-- Compteurs -->
				<div *ngIf="(!detail.importe && (extraFields.RELEVE_COMPTEUR || extraFields.ENTRETIEN || extraFields.REALISATION_CONTROLE_TECHNIQUE || extraFields.REALISATION_CONTROLE_ANTIPOLLUTION)) && reconciliation.selectedTypeReconciliation == 'VEHICULE' && (detail.lien?.objet?.typeVehicule == 'VEHICULE_FONCTION_SERVICE' || detail.lien?.objet?.typeVehicule == 'ENGIN') || detail.importe && detail.lien?.vehiculeCompteur">
					<!-- Compteur de temps -->
					<div class="form-group" [ngClass]="{ 'has-error': compteurTemps.invalid }" *ngIf="detail?.lien?.objet?.uniteUsage == 'TEMPS'">
						<label translate>facture.detail.compteur</label>
						<div class="half">
							<div class="input-group">
								<input type="text" name="compteurTemps" #compteurTemps="ngModel" class="form-control" [(ngModel)]="detail.releveHoraire" [disabled]="detail.importe || detail.date > dateCourante" [required]="detail.typeEntretien && detail.typeEntretien != 'COURANT'" nNumber/>
								<div class="input-group-addon no-border" *ngIf="detail.lien.objet.idVehicule && detail.lien.objet.unite">
									<span translate>duree.heure.libelleCourt</span>
								</div>
								<div class="input-group-addon" *ngIf="detail.lien?.objet?.idVehicule">
									<icon name="replay_30" class="clickable" (click)="showListeCompteurs();"></icon>
								</div>
								<div class="input-group-addon" *ngIf="detail.date > dateCourante">
									<icon name="warning" [tooltip]="'facture.detail.compteurFutur' | translate"></icon>
								</div>
							</div>
						</div>
					</div>

					<!-- Compteur de distance -->
					<div class="form-group" [ngClass]="{ 'has-error': compteurDistance.invalid }" *ngIf="!detail.lien?.objet || detail.lien?.objet?.uniteUsage == 'DISTANCE'">
						<label translate>facture.detail.compteur</label>
						<div class="half">
							<div class="input-group">
								<input type="text" name="compteurDistance" #compteurDistance="ngModel" class="form-control" [(ngModel)]="detail.releveCompteur" [disabled]="detail.importe || detail.date > dateCourante" [required]="detail.typeEntretien && detail.typeEntretien != 'COURANT'" nNumber="3"/>
								<div class="input-group-addon no-border" *ngIf="detail.lien.objet.idVehicule && detail.lien.objet.unite">
									<span> {{ detail.lien?.objet?.unite?.libelleCourt }}</span>
								</div>
								<div class="input-group-addon" *ngIf="detail.lien?.objet?.idVehicule">
									<icon name="replay_30" class="clickable" (click)="showListeCompteurs();"></icon>
								</div>
								<div class="input-group-addon" *ngIf="detail.date > dateCourante">
									<icon name="warning" [tooltip]="'facture.detail.compteurFutur' | translate"></icon>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Affectation -->
				<div class="form-group" *ngIf="(detail.user || detail.vehiculeImmobilise) && reconciliation.selectedTypeReconciliation != 'COLLABORATEUR'">
					<label translate>facture.detail.userAffecte</label>
					<div>
						<span *ngIf="detail.user">{{ detail.user | display:'user' }}</span>
						<span *ngIf="detail.vehiculeImmobilise" translate>facture.detail.vehiculeImmobilise</span>
					</div>
				</div>

				<!-- Véhicule imputé -->
				<div class="form-group" *ngIf="reconciliation.selectedTypeReconciliation == 'COLLABORATEUR'">
					<label translate>facture.detail.vehiculeImpute</label>
					<div>
						<span *ngIf="detail.vehicule && !detail.userEdited">{{ detail.vehicule | display:'vehicule' }}</span>
						<a *ngIf="!detail.vehicule" [routerLink]="[]" (click)="showSelectionImputation()" translate>common.aDefinir</a>
						<div *ngIf="detail.vehicule && detail.userEdited">
							<mat-chip-listbox>
								<mat-chip (removed)="detail.vehicule = null;">
									{{ detail.vehicule | display:'vehicule' }}
									<button matChipRemove *ngIf="!fieldset.disabled && (!facture.interfaceFournisseur || !!facture.interfaceFournisseur && detail.editable) && source != 'VEHICULE'">
										<mat-icon>cancel</mat-icon>
									</button>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="col-md-6">
			<!-- Détail de la ligne de facture -->
			<ng-container *ngIf="detail.nature || detail.libelleNature || detail.codeFournisseur">
				<!-- Montant -->
				<div class="form-group" [ngClass]="{ 'has-error': montant.invalid }">
					<label translate>facture.detail.montant</label>
					<div class="half">
						<input type="text" name="montant" #montant="ngModel" class="form-control" [(ngModel)]="detail.montant" [disabled]="!!facture.interfaceFournisseur" nNumber="3" required (ngModelChange)="onMontantQuantiteChange()"/>
					</div>
				</div>

				<!-- Montant HT -->
				<div class="form-group" [ngClass]="{ 'has-error': montantHorsTaxe.invalid }">
					<label translate>facture.detail.montantHorsTaxe</label>
					<div class="half">
						<input type="text" name="montantHorsTaxe" #montantHorsTaxe="ngModel" class="form-control" [(ngModel)]="detail.montantHorsTaxe" nNumber="3" disabled required/>
					</div>
				</div>

				<!-- Montant unitaire TTC -->
				<div class="form-group" [ngClass]="{ 'has-error': montantUnitaire.invalid }"  *ngIf="detail.montantUnitaire">
					<label translate>facture.detail.montantUnitaire</label>
					<div class="half">
						<input type="text" name="montantUnitaire" #montantUnitaire="ngModel" class="form-control" [(ngModel)]="detail.montantUnitaire" nNumber="3" disabled/>
					</div>
				</div>

				<!-- Taxe -->
				<div class="form-group" [ngClass]="{ 'has-error': taxeField.invalid }" *ngIf="detail.pays && detail.date">
					<label translate>facture.detail.taxe</label>
					<div>
						<input hidden #taxeField="ngModel" name="taxeField" [(ngModel)]="isTaxeInvalid" [validator]="(isConsultation || !isTaxeInvalid || detail.idDetail && facture.interfaceFournisseur && detail.listeTaxes) && (!detail.editable || !hasTypeAnomalie('TAXE') || detail.editable && hasTypeAnomalie('TAXE') && !isTaxeInvalid)">
						<a [routerLink]="[]" (click)="showTaxe()" *ngIf="!isConsultation && (!detail.idDetail || !facture.interfaceFournisseur || detail.editable && hasTypeAnomalie('TAXE'))">
							<icon class="icon-hc-2x" name="search"></icon>
							<span *ngIf="(detail.listeTaxes && detail.montantTaxe || !detail.montantTaxe && isConsultation)">{{ (detail.montantTaxe || 0) | currency:(detail.devise || facture.devise) }}</span>
							<span *ngIf="(!detail.listeTaxes || detail.listeTaxes.length == 0 || !detail.montantTaxe) && !isConsultation" translate>common.aRenseigner</span>
						</a>
						<span *ngIf="(isConsultation || detail.idDetail && facture.interfaceFournisseur && detail.listeTaxes) && (!detail.editable || !hasTypeAnomalie('TAXE'))">{{ (detail.montantTaxe || 0) | currency:(detail.devise || facture.devise) }}</span>
					</div>
				</div>

				<!-- Quantité -->
				<div class="form-group" [ngClass]="{ 'has-error': quantite.invalid }">
					<label translate>facture.detail.quantite</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="quantite" #quantite="ngModel" class="form-control" [(ngModel)]="detail.quantite" [disabled]="!!facture.interfaceFournisseur || extraFields.RELEVE_ENERGIE && detail.date > dateCourante" nNumber="3" [required]="extraFields.RELEVE_ENERGIE && detail.date <= dateCourante" (ngModelChange)="onMontantQuantiteChange()"/>
							<div class="input-group-addon" *ngIf="detail.date > dateCourante && extraFields.RELEVE_ENERGIE">
								<icon name="warning" [tooltip]="'facture.detail.quantiteFutur' | translate"></icon>
							</div>
						</div>
					</div>
				</div>

				<!-- Unité de volume -->
				<div class="form-group" *ngIf="detail.unite">
					<label translate>facture.detail.uniteVolume</label>
					<div>
						<autocomplete name="unite" type="unite" #unite="ngModel" [(ngModel)]="detail.unite" filter="CONTENANCE" [disabled]="true"></autocomplete>
					</div>
				</div>

				<!-- Pays -->
				<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
					<label translate>facture.detail.pays</label>
					<div>
						<autocomplete name="pays" type="pays" #pays="ngModel" [disabled]="!!facture.interfaceFournisseur" [(ngModel)]="detail.pays" (ngModelChange)="onPaysDateChange()" required></autocomplete>
					</div>
				</div>

				<!-- Devise -->
				<div class="form-group" [ngClass]="{ 'has-error': devise.invalid }">
					<label translate>facture.detail.devise</label>
					<div class="half">
						<autocomplete name="devise" type="devise" #devise="ngModel" [(ngModel)]="detail.devise" [disabled]="!!facture.interfaceFournisseur" required></autocomplete>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<!-- Remarque -->
	<div class="form-group">
		<label translate>facture.detail.remarque</label>
		<div>
			<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="detail.remarque" rows="3"></textarea>
		</div>
	</div>
</fieldset>