import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CarburantListItemComponent } from './carburant-list-item.component';
import { CarburantListComponent } from './carburant-list.component';
import { CarburantComponent } from './carburant.component';
import { CarburantService } from './carburant.service';

@NgModule({
  imports: [ShareModule],
  declarations: [CarburantListComponent,CarburantListItemComponent,CarburantComponent],
	providers: [CarburantService]
})
export class CarburantModule { }
