import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { first,map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { AffectationHistoryListComponent } from './affectation-history-list.component';

@Injectable()
export class AffectationService {
	/** Liste des types d'affectation **/
	private listeTypesAffectation = ['VEHICULE_FONCTION','VEHICULE_SERVICE','VEHICULE_IMMOBILISE','VEHICULE_POOL'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService) {}

	/**
	 * Récupération de la liste des types d'affectation
	 */
	public getListeTypesAffectation(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'affectation
		return this.listeTypesAffectation.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.affectation.type.${code}`)
		}));
	}

	/**
	 * Enregistrement d'une affectation
	 */
	public saveAffectation(affectation: any): Observable<Result> {
		//Enregistrement de l'affectation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveAffectation${affectation?.idAffectation ? '/' + affectation.idAffectation : ''}`,affectation);
	}

	/**
	 * Chargement d'une affectation
	 */
	public loadAffectation(idAffectaton: number): Observable<Result> {
		//Chargement de l'affectation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadAffectation/${idAffectaton}`,null);
	}

	/**
	 * Suppression de l'affectation
	 */
	public deleteAffectation(affectation: any): Observable<Result> {
		//Suppression de l'affectation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteAffectation/${affectation.idAffectation}`);
	}

	/**
	 * Recherche de l'affectation courante pour une date donnée
	 */
	public findAffectationForDate(idVehicule: number,date: Date): Observable<Result> {
		//Recherche de l'affectation courante pour une date donnée
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/findAffectationForDate/${idVehicule}/${date}`,null);
	}

	/**
	 * Vérification des périodes avec la liste d'affectations
	 */
	public isPeriodeValidForVehicule(vehicule: any,currentAffectation?: any): Observable<{ isDateEntreeValid: boolean,isDateSortieValid: boolean }> {
		//Vérification des périodes avec la liste d'affectations
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/findAffectationMinMaxDate/${vehicule.idVehicule}`,null).pipe(
			first(),
			map(result => {
				let minDate: number;
				let maxDate: number;
				let isDateEntreeValid: boolean = true;
				let isDateSortieValid: boolean = true;

				//Lecture des dates
				minDate = result?.data?.periode?.dateMin || null;
				maxDate = result?.data?.periode?.dateMax || null;

				//Validation de la date d'entrée
				isDateEntreeValid = minDate ? (vehicule.dateEntree <= minDate) : true;

				//Validation de la date de sortie
				isDateSortieValid = (minDate || maxDate) ? (!vehicule.dateSortie || maxDate && vehicule.dateSortie >= maxDate || currentAffectation && currentAffectation.dateFin === maxDate && (!currentAffectation.dateFin || vehicule.dateSortie <= currentAffectation.dateFin)) : true;

				//Retour du résultat
				return {
					isDateEntreeValid: isDateEntreeValid,
					isDateSortieValid: isDateSortieValid
				};
			})
		);
	}

	/**
	 * Vérification des périodes
	 */
	public isPeriodeValid(selectedAffectation: any,selectedVehicule: any): Observable<{ isValid: boolean,affectation: any }> {
		let isValid: boolean = true;
		let currentAffectation = null;
		let vehicule;

		//Lecture du véhicule
		vehicule = selectedVehicule || selectedAffectation.vehicule;

		//Vérification de la période
		if (selectedAffectation) {
			//Recherche d'une éventuelle affectation en conflit
			return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/findConflictingAffectation/${vehicule.idVehicule}`,{
				idAffectation: selectedAffectation.idAffectation,
				dateDebut: selectedAffectation.dateDebut,
				dateFin: selectedAffectation.dateFin
			}).pipe(map(result => {
				//Vérification du résultat
				if (result?.data?.affectation) {
					//Modification des indicateurs
					isValid = false;

					//Affectation concernée
					currentAffectation = result.data.affectation;
				}

				//Retour de la validité des périodes
				return {
					isValid: isValid,
					affectation: currentAffectation
				};
			}));
		} else {
			//Retour par défaut
			return of({
				isValid: isValid,
				affectation: currentAffectation
			});
		}
	}

	/**
	 * Vérification de la période du véhicule
	 */
	isPeriodeVehiculeValid(affectation: any,selectedVehicule: any): boolean {
		let vehicule;

		//Lecture du véhicule
		vehicule = selectedVehicule || affectation.vehicule;

		//Vérification de la date de fin du véhicule
		if (vehicule.dateEntree && vehicule.dateSortie) {
			//Vérification de la date de fin de l'affectation
			if (affectation.dateFin) {
				//Vérification des périodes
				return vehicule.dateEntree <= affectation.dateDebut && affectation.dateDebut <= vehicule.dateSortie && vehicule.dateEntree <= affectation.dateFin && affectation.dateFin <= vehicule.dateSortie;
			} else {
				//Période incorrecte
				return false;
			}
		} else if (vehicule.dateEntree) {
			//Vérification des périodes
			return vehicule.dateEntree <= affectation.dateDebut;
		} else
			//Période correcte
			return true;
	}

	/**
	 * Affichage des affectations du véhicule
	 */
	public showListeAffectations(vehicule: any) {
		let bsModalRef: BsModalRef<AffectationHistoryListComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(AffectationHistoryListComponent,{
			initialState: {
				vehicule
			},
			class: 'modal-lg'
		});

		//Retour du permis de conduire
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.selectedAffectation)
		);
	}
}