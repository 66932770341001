import { NgModule } from '@angular/core';

import { CoreComponentsModule } from '../core-components.module';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { DisplayMoreComponent } from './display-more.component';

@NgModule({
	imports: [CoreComponentsModule,PluralTranslateModule],
	declarations: [DisplayMoreComponent],
	exports: [DisplayMoreComponent]
})
export class DisplayMoreModule { }
