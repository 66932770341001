import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { FournisseurService } from './fournisseur.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'fournisseur-contact-add',
	templateUrl: './fournisseur-contact-add.component.html'
})
export class FournisseurContactAddComponent extends AutocompleteAddComponent {
	/** Contact **/
	contact: any = {
		type: 'NON_DEFINI',
		actif: true
	};

	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<FournisseurContactAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement du contact
		this.fournisseurService.saveContact(this.contact).subscribe(result => {
			let listeDoublons: Array<string> = [];

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Définition du contact
				this.addedItem = result.data.contact;

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification de l'email
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.email'));

				//Vérification du type
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.type'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		},() => {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}
}
