<div class="pull-left lv-avatar"><icon name="warning"></icon></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]">{{ data.codeFournisseur.libelle + ' (' + factureService.getLibelleDetailFor(data) + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span [translate]="'facture.type.'+data.facture.type"></span>&#160;<span translate [translateParams]="{ numero: data.facture.reference }">facture.numero</span></li>
		<li><span translate>facture.detail.date</span>&#160;:&#160;<span>{{ data.date | date:'shortDate' }}</span></li>
		<li><span translate>facture.detail.montant</span>&#160;:&#160;<span>{{ data.montant | currency:'.2-2':data.devise }}</span></li>
		<li><span translate>facture.detail.fournisseur</span>&#160;:&#160;<span>{{ data.facture.fournisseur.libelle }}</span></li>
	</ul>
</div>
