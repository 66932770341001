import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from '../compte/compte.service';

@Component({
	selector: 'compte-auxiliaire-fournisseur-edit',
	templateUrl: './compte-auxiliaire-fournisseur-edit.component.html'

})
export class CompteAuxiliaireFournisseurEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() lienFournisseur: any;

	/** Suppression de l'élément courant **/
	@Input() deleteLienFournisseur: (lienFournisseur: any,close: Function) => void;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du lien
	 */
	saveLienFournisseur() {
		//Enregistrement du lien
		this.compteService.saveLienCompteDetail(this.lienFournisseur).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du lien
					this.lienFournisseur = Object.assign(this.lienFournisseur,result.data?.lien);

					//Fermeture de la fenêtre
					this.close({ lienFournisseur: this.lienFournisseur });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du fournisseur
					listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
