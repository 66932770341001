import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { DemandeCotationService } from './demande-cotation.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { CotationService } from 'src/app/components/commande/cotation/cotation.service';

@Component({
	selector: 'demande-cotation-cotation-edit',
	templateUrl: './demande-cotation-cotation-edit.component.html'
})
export class DemandeCotationCotationEditComponent extends PageContentItem implements OnInit {
	/** Cotation **/
	@Input() cotation: any;

	/** Demande de cotation **/
	@Input() demandeCotation: any;

	/** Utilisateur courant **/
	public user: User;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Copie de la cotation */
	private savedCotation: any;

	/**
	 * Constructeur
	 */
	constructor(private cotationService: CotationService,private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService
			,private modeleService: ModeleService,private demandeCotationService: DemandeCotationService,private layoutService: LayoutService,private loggedUserService: LoggedUserService) {
		//Héritage
		super();

		//Binding des méthodes
		this.close = this.close?.bind?.(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Copie de la cotation avant modifications
		this.savedCotation = cloneDeep(this.cotation);
	}

	/**
	 * Consultation d'un modèle
	 */
	public showModeleInformation(modele: any) {
		//Affichage des informations détaillées du modèle
		this.modeleService.showModeleInformations(modele,{ canConfigure: false }).subscribe();
	}

	/**
	 * Sauvegarde de la cotation
	 */
	saveCotation() {
		//Sauvegarde de la cotation
		this.cotationService.saveCotation(this.cotation).subscribe({
			next: (result: Result) => {
				//Vérification du résultat
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour des données
					this.cotation = Object.assign(this.cotation,result.data.cotation);
					this.demandeCotation = Object.assign(this.demandeCotation,result.data.demandeCotation);

					//Fermeture du formulaire
					this.close({ cotation: this.cotation,demandeCotation: this.demandeCotation });

					//Demande de transmission
					this.demandeCotationService.doAction('TRANSMETTRE',this.demandeCotation).subscribe({
						next: () => {
							//Redirection
							this.cotation.lienGrilleAttributionModele ? this.redirectToGrilleAttribution() : this.redirectToDemandeVehicule();
						}
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Vérification de la validité de la liste des contacts
	 */
	isListeContactsInvalid: () => boolean = () => !this.cotation.listeContacts?.length;

	/**
	 * Redirection vers la grille d'attribution
	 */
	redirectToGrilleAttribution() {
		//Redirection vers la grille d'attribution
		this.layoutService.goToByState('listeGrillesAttribution-grilleAttributionModele',{
			routeParams: {
				idGrilleAttribution: this.demandeCotation.cotation.lienGrilleAttributionModele.idGrilleAttribution,
				idModele: this.cotation.modele.idModele
			}
		});
	}

	/**
	 * Redirection vers la demande de véhicule
	 */
	redirectToDemandeVehicule() {
		//Redirection vers la demande de véhicule
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
			routeParams: {
				idDemandeVehicule: this.demandeCotation.cotation.demandeVehicule.idDemandeVehicule
			}
		});
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Comparaison des deux listes
		return !isEqual(this.cotation,this.savedCotation);
	}
}