import { User } from 'src/app/domain/user/user';

/**
 * Modèle représentant une session
 */
export class Session {
	/** Données **/
	isLogged: boolean;
	xAuthToken: string;
	user: User;
	userOrigine?: User;
	codeTenant?: string;
	originalCodeTenant?: string;
	noPropagation?: boolean;
	isRefresh?: boolean;
	route?: string;
	savedPath?: string;
	queryParams?: any;
	isUserForbidden?: boolean;
	appliUrl?: string;
	confidentialiteUrl?: string;
}