<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.pneumatique.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.pneumatique.nbRoues</label>
					<div class="half" *ngIf="pneumatique?.nbRoues">
						<input type="text" name="nbRoues" #nbRoues="ngModel" class="form-control" [(ngModel)]="pneumatique.nbRoues" nNumber/>
					</div>
					<div *ngIf="!pneumatique?.nbRoues">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.pneumatique.typeRoueSecours.item</label>
					<div *ngIf="pneumatique?.typeRoueSecours">
						<selectpicker name="typeRoueSecours" #typeRoueSecoursField="ngModel" [(ngModel)]="pneumatique.typeRoueSecours">
							<mat-option *ngFor="let typeRoueSecours of listeTypesRoueSecours" [value]="typeRoueSecours.code">{{ typeRoueSecours.libelle }}</mat-option>
						</selectpicker>
					</div>
					<div *ngIf="!pneumatique?.typeRoueSecours">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.pneumatique.dimensionPneuAV</label>
					<div *ngIf="pneumatique?.dimensionPneuAV">
						<input type="text" name="dimensionPneuAV" #dimensionPneuAV="ngModel" class="form-control" [(ngModel)]="pneumatique.dimensionPneuAV" maxlength="100"/>
					</div>
					<div *ngIf="!pneumatique?.dimensionPneuAV">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.pneumatique.dimensionPneuAR</label>
					<div *ngIf="pneumatique?.dimensionPneuAR">
						<input type="text" name="dimensionPneuAR" #dimensionPneuAR="ngModel" class="form-control" [(ngModel)]="pneumatique.dimensionPneuAR" maxlength="100"/>
					</div>
					<div *ngIf="!pneumatique?.dimensionPneuAR">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>