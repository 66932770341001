import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'please-wait-modal',
	templateUrl: './please-wait-modal.component.html'
})
export class PleaseWaitModalComponent {
	/** Message de l'attente **/
	message?: string;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<PleaseWaitModalComponent>) {

	}
}