import { AfterContentChecked,ChangeDetectorRef,Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'workflow-detail-valideur-selection',
	templateUrl: './workflow-detail-valideur-selection.component.html'
})
export class WorkflowDetailValideurSelectionComponent implements AfterContentChecked {
	/** Type de valideur **/
	typeValideur: 'VALIDEUR_DYNAMIQUE' | 'VALIDEUR_NOMME' = 'VALIDEUR_DYNAMIQUE';

	/** Résultat **/
	result: { typeValideur: 'VALIDEUR_DYNAMIQUE' | 'VALIDEUR_NOMME' };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<WorkflowDetailValideurSelectionComponent>) {}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la sélection
	 */
	public saveWorkflowDetailValideurSelection() {
		//Définition du résultat
		this.result = {
			typeValideur: this.typeValideur
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}