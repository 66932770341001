<div class="modal-header">
	<h4 class="modal-title" translate>demandeCotation.modele.optionPayante.title</h4>
</div>
<div class="modal-body">
	<div class="listview lv-bordered">
		<div class="listview lv-bordered">
			<div class="lv-body m-t-10">
				<div class="lv-item media" *ngFor="let detail of listeOptionsPayantes">
					<div class="pull-left lv-avatar">
						<icon [type]="optionCategorieService.getTypeOptionForCode(detail?.option?.listeSubCategories[0]?.type)?.iconeType" [name]="optionCategorieService.getTypeOptionForCode(detail?.option?.listeSubCategories[0]?.type)?.icone"></icon>
					</div>
					<div class="media-body">
						<div class="lv-title"><a [routerLink]="[]">{{ detail.option.libelle }}</a></div>
					</div>
					<ul class="lv-attrs">
						<li *ngIf="detail?.option?.listeSubCategories?.length"><span translate>vehicule.modele.configuration.categorieOption</span>&#160;:&#160;<span>{{ getListeSubCategories(detail.option) }}</span></li>
						<li *ngIf="configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT'" [title]="getSelectorTitleForDetail(detail) || ''">
							<span translate>vehicule.modele.configuration.prixHT</span>&#160;:&#160;
							<span *ngIf="getActualPrice(detail) == detail?.option?.prixHT">{{ detail?.option?.prixHT | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							<span *ngIf="getActualPrice(detail) != detail?.option?.prixHT"><s>{{ detail?.option?.prixHT | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</s>&#160;{{ getActualPrice(detail) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
						</li>
						<li *ngIf="configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC'" [title]="getSelectorTitleForDetail(detail) || ''"><span translate>vehicule.modele.configuration.prixTTC</span>&#160;:&#160;
							<span *ngIf="getActualPrice(detail) == detail?.option?.prixTTC">{{ detail?.option?.prixTTC | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							<span *ngIf="getActualPrice(detail) != detail?.option?.prixTTC"><s>{{ detail?.option?.prixTTC | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</s>&#160;{{ getActualPrice(detail) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
						</li>
					</ul>
				</div>
				<div class="text-center p-10" *ngIf="!listeOptionsPayantes || listeOptionsPayantes.length == 0">
					<h5 translate><span translate>liste.empty</span></h5>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>