<div class="concatener">
	<ul class="fonction-detail z-depth-1">
		<li class="header">
			<div *ngIf="(null | right:'creation') && !isRestricted" class="actions dropdown pull-right">
				<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li (click)="parent[parentKey] = null;">
						<a [routerLink]="[]" translate>actions.supprimer</a>
					</li>
					<li (click)="addItem()">
						<a [routerLink]="[]" translate>actions.ajouter</a>
					</li>
				</ul>
			</div>
			<h4>
				<span class="fonction-label head">
					<span translate>structureExport.fonction.concatener.title</span>
				</span>
			</h4>
		</li>
		<li class="content">
			<ul>
				<li *ngFor="let item of detail.fonction.listeDetails; let idxItem = index; trackBy: trackByPosition;" class="hoverable d-flex fonction-item">
					<div class="flex-1">
						<div class="text-left" *ngIf="!item.valeur && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: item,parentKey: 'valeur' })" translate>common.aDefinir</a></div>
						<div *ngIf="!item.valeur && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
						<div *ngIf="item.valeur" class="rubrique-detail">
							<structure-export-rubrique-detail [detail]="item.valeur" [parent]="item" [parentKey]="'valeur'" [isRestricted]="" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
						</div>
					</div>
					<div class="actions dropdown hovered-item" *ngIf="!item.valeur && detail.fonction.listeDetails.length > 2 && (null | right:'creation') && !isRestricted">
						<a [routerLink]="[]" (click)="removeItem(idxItem)"><icon name="delete"></icon></a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>