<form #grilleAttributionSelectionModeleAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.grilleAttribution.modele.selection.title</h4>
	</div>
	<div class="modal-body">
		<div class="m-t-20 m-b-10">
			<mat-radio-group [(ngModel)]="choice" name="choix">
				<mat-radio-button value="RECUPERATION_GRILLE"><span translate>vehicule.grilleAttribution.modele.selection.recuperation</span></mat-radio-button>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="NOUVEAU_MODELE"><span translate>vehicule.grilleAttribution.modele.selection.nouveauModele</span></mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveChoice()" [disabled]="grilleAttributionSelectionModeleAddForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>