import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { first } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DataCustomizationEditComponent } from './data-customization-edit.component';
import { DataCustomizationRefValueEditComponent } from './data-customization-ref-value-edit.component';
import { DataCustomizationRefValueListComponent } from './data-customization-ref-value-list.component';
import { DataCustomizationService } from './data-customization.service';

@Component({
	selector: 'data-customization',
	templateUrl: './data-customization.component.html'
})
export class DataCustomizationComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Entité personnalisée courante **/
	public dataCustomization: any;

	/** Résumé **/
	private resume: { nbCustomRef1: number,nbCustomRef2: number,nbCustomRef3: number,nbCustomRef4: number } = {
		nbCustomRef1: 0,
		nbCustomRef2: 0,
		nbCustomRef3: 0,
		nbCustomRef4: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private dataCustomizationService: DataCustomizationService,private pageContentService: PageContentService,private translateService: TranslateService
			,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private entiteService: EntiteService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.dataCustomizationEntite';

	/**
	 * Récupération de l'identifiant de la entité personnalisée à charger
	 */
	getIdObject: () => number = () => this.dataCustomization?.idDataCustomizationEntite || 0;

	/**
	 * Récupération du nombre de libellés personnalisés
	 */
	getNbLabelsForType(dataCustomization: any,type: 'alpha' | 'numeric' | 'date' | 'customRef') {
		//Retour du nombre de libellés personnalisés
		return [1,2,3,4].filter(num => dataCustomization[type + num]).length;
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let listeElements: Array<any>;

		//Récupération de l'identifiant de la personnalisation à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idDataCustomizationEntite);
			}
		});

		//Définition des éléments
		listeElements = this.getListeElements.bind(this)();

		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'dataCustomization.customRefValue.entite',
				key: (data) => this.entiteService.translateEntityCode(data.entite?.split('.').pop())
			},{
				libelle: 'dataCustomization.liste.referentiel',
				key: (data) =>this.translateService.instant('dataCustomization.liste.personnalisation',{ nbPersonnalisations: this.getNbLabelsForType(data,'customRef') })
			},{
				libelle: 'dataCustomization.liste.alphanumerique',
				key: (data) =>this.translateService.instant('dataCustomization.liste.personnalisation',{ nbPersonnalisations: this.getNbLabelsForType(data,'alpha') })
			},{
				libelle: 'dataCustomization.liste.numerique',
				key: (data) =>this.translateService.instant('dataCustomization.liste.personnalisation',{ nbPersonnalisations: this.getNbLabelsForType(data,'numeric') })
			},{
				libelle: 'dataCustomization.liste.date',
				key: (data) =>this.translateService.instant('dataCustomization.liste.personnalisation',{ nbPersonnalisations: this.getNbLabelsForType(data,'date') })
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editDataCustomization(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editDataCustomization(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			}],
			listeElements
		};
	}

	/**
	 * Edition de la personnalisation
	 */
	private editDataCustomization() {
		//Ouverture du composant d'édition
		this.pageContentService.open(DataCustomizationEditComponent,{
			data: {
				dataCustomization: cloneDeep(this.dataCustomization)
			}
		}).subscribe({
			next: (dataCustomization: any) => {
				//Vérification des données
				if (dataCustomization) {
					//Mise à jour de la personnalisation
					this.dataCustomization = Object.assign(this.dataCustomization,dataCustomization);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.dataCustomization);
				}
			}
		});
	}

	/**
	 * Chargement du résumé
	 */
	private loadResume(idDataCustomization: number) {
		//Chargement du résumé
		idDataCustomization && this.dataCustomizationService.loadResume(idDataCustomization).subscribe({
			next: (result: Result) => {
				//Définition du résumé
				this.resume = {
					nbCustomRef1: result.data.nbCustomRef1 || 0,
					nbCustomRef2: result.data.nbCustomRef2 || 0,
					nbCustomRef3: result.data.nbCustomRef3 || 0,
					nbCustomRef4: result.data.nbCustomRef4 || 0
				};
			}
		});
	}

	/**
	 * Récupération du libellé
	 */
	getLibelleForElement(num: any) {
		//Récupération du libellé
		return this.dataCustomization && this.dataCustomization['customRef' + num] ? this.dataCustomization['customRef' + num] : this.translateService.instant('dataCustomization.listeElements.referentiel',{ index: num });
	}

	/**
	 * Définition des éléments
	 */
	private getListeElements() {
		//Définition des éléments
		return [1,2,3,4].map (num => ({
			type: 'REFERENTIEL_' + num,
			libelle: () => this.getLibelleForElement(num),
			component: DataCustomizationRefValueListComponent,
			retrieveComponentData: () => ({
				dataCustomization: this.dataCustomization,
				resume: this.resume,
				num,
				addCustomRefValue: this.addCustomRefValue.bind(this)
			}),
			count: () =>  this.resume?.['nbCustomRef' + num] || 0,
			doAction: () => this.addCustomRefValue.bind(this)(num),
			libelleAction: () => this.getLibelleForElement(num),
			isVisible: () => this.rightService.hasRight(null,'consultation'),
			isActionVisible: () => this.rightService.hasRight(null,'creation')
		}));
	}

	/**
	 * Chargement des données
	 */
	private loadData(idDataCustomizationEntite: number) {
		//Chargement des données
		this.dataCustomizationService.loadDataCustomization(idDataCustomizationEntite).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la personnalisation
					this.dataCustomization = result.data?.dataCustomization;

					//Chargement du résumé
					this.loadResume(this.dataCustomization?.idDataCustomizationEntite);

					//Vérification de l'identifiant de la personnalisation
					if (!this.dataCustomization?.idDataCustomizationEntite) {
						//Création d'un objet vierge
						this.dataCustomization = {};

						//Edition de la personnalisation
						this.editDataCustomization();
					}
				}
			}
		});
	}

	/**
	 * Sauvegarde de la valeur du référentiel personnalisable
	 */
	private addCustomRefValue(num: number,customRefValue: any = { dataCustomizationEntite: Object.assign({},this.dataCustomization),actif: true },deleteCustomRefValue?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(DataCustomizationRefValueEditComponent,{
			data: {
				num,
				customRefValue: cloneDeep(customRefValue),
				deleteCustomRefValue
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('REFERENTIEL_' + num,data.idCustomRefValue != customRefValue.idCustomRefValue);

					//Vérification de la présence d'une création ou d'une suppression
					if (!customRefValue.idCustomRefValue && data)
						//Incrémentation du compteur
						this.resume['nbCustomRef' + num]++;
					else if (customRefValue.idCustomRefValue && !data.idCustomRefValue)
						//Décrémentation du compteur
						this.resume['nbCustomRef' + num]--;

					//Mise à jour de la valeur
					customRefValue = Object.assign(customRefValue,data);
				}
			}
		});
	}
}