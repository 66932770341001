import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ContratFinancementContentComponent } from './contrat-financement-content.component';
import { ContratFinancementEcheanceDetailListItemComponent } from './contrat-financement-echeance-detail-list-item.component';
import { ContratFinancementEcheanceDetailListComponent } from './contrat-financement-echeance-detail-list.component';
import { ContratFinancementEcheanceListItemComponent } from './contrat-financement-echeance-list-item.component';
import { ContratFinancementEcheanceListComponent } from './contrat-financement-echeance-list.component';
import { ContratFinancementEditComponent } from './contrat-financement-edit.component';
import { ContratFinancementFactureDetailListItemComponent } from './contrat-financement-facture-detail-list-item.component';
import { ContratFinancementFactureDetailListComponent } from './contrat-financement-facture-detail-list.component';
import { ContratFinancementListItemComponent } from './contrat-financement-list-item.component';
import { ContratFinancementListComponent } from './contrat-financement-list.component';
import { ContratFinancementLoiRoulageListItemComponent } from './contrat-financement-loi-roulage-list-item.component';
import { ContratFinancementLoiRoulageListComponent } from './contrat-financement-loi-roulage-list.component';
import { ContratFinancementLoiRoulageTipComponent } from './contrat-financement-loi-roulage-tip.component';
import { ContratFinancementLoiRoulageComponent } from './contrat-financement-loi-roulage.component';
import { ContratFinancementComponent } from './contrat-financement.component';
import { ContratFinancementService } from './contrat-financement.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ContratFinancementListComponent,ContratFinancementListItemComponent,ContratFinancementComponent,ContratFinancementEditComponent,ContratFinancementContentComponent,ContratFinancementLoiRoulageListComponent,ContratFinancementLoiRoulageListItemComponent,ContratFinancementLoiRoulageComponent,ContratFinancementLoiRoulageTipComponent
		,ContratFinancementFactureDetailListComponent,ContratFinancementFactureDetailListItemComponent
		,ContratFinancementEcheanceListComponent,ContratFinancementEcheanceListItemComponent,ContratFinancementEcheanceDetailListComponent,ContratFinancementEcheanceDetailListItemComponent
	],
	exports: [ContratFinancementContentComponent],
	providers: [ContratFinancementService]
})
export class ContratModule {}