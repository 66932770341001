<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showDemandeCotation()">
			<span translate [translateParams]="{ numero: data.idDemandeCotation }">demandeCotation.liste.item</span>
			<span *ngIf="data.cotation">&nbsp;-&nbsp;{{ data.cotation.modele.marque.libelle + ' ' + data.cotation.modele.libelle + (data.cotation.modele.finition ? ' (' + data.cotation.modele.finition + ')' : '') }}</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>demandeCotation.statut.item</span>&#160;:&#160;<span [translate]="'demandeCotation.statut.' + data.statut"></span></li>
		<li><span translate>demandeCotation.origine.item</span>&#160;:&#160;<span [translate]="'demandeCotation.origine.' + data.origine" [translateParams]="{ numero: data.origine == 'GRILLE_ATTRIBUTION' ? data.cotation?.lienGrilleAttributionModele?.grilleAttribution?.reference : data.origine == 'DEMANDE_VEHICULE' ? data.cotation?.demandeVehicule?.idDemandeVehicule : '' }"></span></li>
		<li [tooltip]="demandeCotationService.getListeContactsAsString(data.cotation?.listeContacts)"><span translate>demandeCotation.contacts</span>&#160;:&#160;{{ data.cotation?.listeContacts?.length || 0 }}</li>
		<li><span translate>demandeCotation.dateCreation</span>&#160;:&#160;{{ data.dateCreation | date:'shortDate' }}</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && data.statut == 'A_TRANSMETTRE'"><a [routerLink]="[]" (click)="doAction('TRANSMETTRE')"><span translate>actions.transmettre</span></a></li>
			<li *ngIf="(null | right:'creation') && data.statut == 'EMISE_FOURNISSEUR'"><a [routerLink]="[]" (click)="doAction('RELANCER')"><span translate>actions.relancer</span></a></li>
			<li *ngIf="(null | right:'creation') && data.statut == 'EMISE_FOURNISSEUR'"><a [routerLink]="[]" (click)="doAction('RAPPELER')"><span translate>actions.rappeler</span></a></li>
			<li *ngIf="(null | right:'creation') && data.statut == 'EMISE_FOURNISSEUR'"><a [routerLink]="[]" (click)="doAction('ANNULER')"><span translate>actions.annuler</span></a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteDemandeCotation();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>