import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class DatabaseService {
	/** Liste des types de de base de données */
	private readonly listeTypesDatabase: Array<string> = ['MULTI_TENANT','MONO_TENANT'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Chargement de la base de données
	 */
	public loadDatabase(idDatabase: number): Observable<Result> {
		//Chargement de la base de données
		return this.http.post<Result>(`${environment.baseUrl}/controller/Database/loadDatabase/${idDatabase}`,null);
	}

	/**
	 * Récupération du nombre de clients utilisant la base de données
	 */
	public countTenantByDatabase(database: any): Observable<Result> {
		//Récupération du nombre de clients utilisant la base de données
		return this.http.post<Result>(`${environment.baseUrl}/controller/Database/countTenantByDatabase/${database.idDatabase}`,null);
	}

	/**
	 * Vérification de l'utilisation de la base de données par des clients du revendeur
	 */
	public checkDatabaseForRevendeur(database: any,revendeur: any): Observable<Result> {
		//Vérification de l'utilisation de la base de données par des clients du revendeur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Database/checkDatabaseForRevendeur/${database.idDatabase}/${revendeur.idRevendeur}`,null);
	}

	/**
	 * Suppression de la base de données
	 */
	public deleteDatabase(database: any): Observable<Result> {
		//Suppression de la base de données
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Database/deleteDatabase/${database.idDatabase}`);
	}

	/**
	 * Enregistrement de la base de données
	 */
	public saveDatabase(database: any): Observable<Result> {
		//Enregistrement de la base de données
		return this.http.put<Result>(`${environment.baseUrl}/controller/Database/saveDatabase`,database);
	}

	/**
	 * Liste des types de base de données
	 */
	public getListeTypesDatabase(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de base de données
		return this.listeTypesDatabase.map(code => ({
			code,
			libelle: this.translateService.instant(`database.type.${code}`)
		}));
	}
}