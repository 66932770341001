import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeAmenagementEditComponent } from './type-amenagement-edit.component';
import { TypeAmenagementFieldListComponent } from './type-amenagement-field-list.component';
import { TypeAmenagementService } from './type-amenagement.service';

@Component({
	selector: 'type-amenagement',
	templateUrl: './type-amenagement.component.html'
})
export class TypeAmenagementComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Type d'aménagement courant **/
	public typeAmenagement: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private typeAmenagementService: TypeAmenagementService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.TypeAmenagement';

	/**
	 * Récupération de l'identifiant du type d'aménagement à charger
	 */
	getIdObject: () => number = () => this.typeAmenagement?.idTypeAmenagement || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.typeAmenagement;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.typeAmenagement.libelle',
				key: 'libelle'
			},{
				libelle: 'vehicule.typeAmenagement.reference',
				key: 'reference'
			},{
				libelle: 'vehicule.typeAmenagement.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editTypeAmenagement(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editTypeAmenagement(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteTypeAmenagement(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'label',
				title: this.translateService.instant('vehicule.typeAmenagement.alerte.empty.title'),
				message: this.translateService.instant('vehicule.typeAmenagement.alerte.empty.message'),
				doAction: () => this.addField(),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isVisible: () => !this.typeAmenagement?.listeFields?.some(field => field.actif) && !this.pageContentService.isOpened()
			}],
			listeElements: [{
				type: 'FIELD',
				libelle: 'vehicule.typeAmenagement.elements.field',
				component: TypeAmenagementFieldListComponent,
				retrieveComponentData: () => ({
					typeAmenagement: this.typeAmenagement,
					addField: this.addField.bind(this)
				}),
				doAction: () => this.addField(),
				count: () => this.typeAmenagement?.listeFields?.filter(field => field.actif)?.length,
				libelleAction: this.translateService.instant('vehicule.typeAmenagement.elements.field.ajouter'),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isVisible: () => this.rightService.hasRight(null,'consultation') && this.typeAmenagement?.listeFields?.some(field => field.actif)
			}],
			customStyle: {
				btnAction: {
					icone: 'edit'
				}
			}
		};

		//Récupération de l'identifiant du type d'aménagement à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idTypeAmenagement);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idTypeAmenagement: number) {
		//Chargement du type d'aménagement
		this.typeAmenagementService.loadTypeAmenagement(idTypeAmenagement).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du type d'aménagement
					this.typeAmenagement = result.data?.typeAmenagement;

					//Vérification de l'identifiant du type d'aménagement
					if (!this.typeAmenagement?.idTypeAmenagement) {
						//Création d'un objet vierge
						this.typeAmenagement = {
							actif: false,
							listeFields: this.typeAmenagement.listeFields
						};

						//Edition du type d'aménagement
						this.editTypeAmenagement();
					}
				}
			}
		});
	}

	/**
	 * Edition du type d'aménagement
	 */
	private editTypeAmenagement() {
		//Ouverture du composant d'édition
		this.pageContentService.open(TypeAmenagementEditComponent,{
			data: {
				typeAmenagement: cloneDeep(this.typeAmenagement),
				deleteTypeAmenagement: this.deleteTypeAmenagement.bind(this)
			}
		}).subscribe({
			next: (typeAmenagement: any) => {
				//Vérification des données
				if (typeAmenagement) {
					//Mise à jour du type d'aménagement
					Object.assign(this.typeAmenagement,typeAmenagement);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.typeAmenagement);
				}
			}
		});
	}

	/**
	 * Suppression du type d'aménagement
	 */
	protected deleteTypeAmenagement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.typeAmenagementService.deleteTypeAmenagement(this.typeAmenagement))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout/modification des champs
	 */
	protected addField(typeAmenagement: any = this.typeAmenagement) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TypeAmenagementFieldListComponent,{
			data: {
				typeAmenagement: cloneDeep(typeAmenagement),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data: { typeAmenagement: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Mise à jour des champs
					Object.assign(this.typeAmenagement,data.typeAmenagement);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FIELD',true);
				}
			}
		});
	}
}