import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { OffreComponent } from './offre.component';
import { OffreDroitComponent } from './offre-droit.component';
import { OffreEditComponent } from './offre-edit.component';
import { OffreListItemComponent } from './offre-list-item.component';
import { OffreListComponent } from './offre-list.component';
import { OffreService } from './offre.service';

@NgModule({
	imports: [ShareModule],
	declarations: [OffreListComponent,OffreListItemComponent,OffreComponent,OffreEditComponent,OffreDroitComponent],
	providers: [OffreService]
})
export class OffreModule { }