import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'comparator-more',
	templateUrl: './comparator-more.component.html'
})
export class ComparatorMoreComponent implements AfterContentChecked {
	/** Titre **/
	@Input() title: string;

	/** Titre du contenu **/
	@Input() contentTitle: string;

	/** Sous-titre du contenu **/
	@Input() contentSubTitle: string;

	/** Contenu **/
	@Input() content: string

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ComparatorMoreComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la pop-up
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}