<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [name]="getStatutForCode()?.icone" [type]="getStatutForCode()?.iconeType"></icon>
</div>
<div class="lv-title">
	<a [routerLink]="['/DemandeIdentification/loadDemandeIdentification',data.idIdentification]">{{ data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ' (' + (data.vehicule.modele ? data.vehicule.modele.marque.libelle + ' ' + data.vehicule.modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.reference"><span translate>demandeIdentification.numeroDossier.short</span>&#160;:&#160;{{ data.reference }}</li>
	<li *ngIf="data.dateInfraction"><span translate>demandeIdentification.dateInfraction.short</span>&#160;:&#160;{{ data.dateInfraction | date:'short' }}</li>
	<li *ngIf="data.typeDesignation"><span translate>demandeIdentification.typeDesignation.item</span>&#160;:&#160;<span [translate]="'demandeIdentification.typeDesignation.'+data.typeDesignation"></span></li>
	<li *ngIf="data.typeDesignation == 'CONDUCTEUR' && data.designation?.user"><span translate>demandeIdentification.conducteur</span>&#160;:&#160;{{ data.designation.user.nom + ' ' + data.designation.user.prenom + ' (' + data.designation.user.matricule + (data.designation.user.societe ? ' - ' + data.designation.user.societe.libelle : '') + ')' }}</li>
	<li *ngIf="data.typeDesignation == 'SOCIETE' && data.designation?.societe"><span translate>demandeIdentification.societe</span>&#160;:&#160;{{ data.designation.societe.libelle }}</li>
	<li *ngIf="data.typeDesignation == 'VEHICULE_VENDU' && data.designation?.dateVente"><span translate>demandeIdentification.vendu</span>&#160;:&#160;{{ data.designation.dateVente | date:'shortDate' }}</li>
	<li *ngIf="data.typeDesignation == 'VEHICULE_VOLE' && data.designation?.dateVol"><span translate>demandeIdentification.vole</span>&#160;:&#160;{{ data.designation.dateVol | date:'shortDate' }}</li>
	<li *ngIf="data.typeDesignation == 'VEHICULE_RENDU' && data.designation?.dateSortie"><span translate>demandeIdentification.rendu</span>&#160;:&#160;{{ data.designation.dateSortie | date:'shortDate' }}</li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>