import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep,uniqBy } from 'lodash-es';
import { Observable,filter,of,switchMap } from 'rxjs';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleAutomatiqueService } from './regle-automatique.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'regle-automatique-edit',
	templateUrl: './regle-automatique-edit.component.html'
})
export class RegleAutomatiqueEditComponent extends PageContentItem implements OnInit {
	/** Règle automatique **/
	@Input() regleAutomatique: any;

	/** Identifiant d'origine de la règle à dupliquer **/
	@Input() idRegleAutomatiqueOrigine?: number;

	/** Liste des types de règles automatiques **/
	@Input() readonly listeTypesComputedField: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>;

	/** Suppression de l'objet **/
	@Input() deleteRegleAutomatique: () => void;

	/** Liste des entités disponibles **/
	public listeEntities: Array<{ code: string,libelle: string }>;

	/** Liste des champs disponibles **/
	public listeFields: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>;

	/** Entité sélectionnée **/
	public selectedEntity: string;

	/** Règle sauvegardée **/
	private savedRegleAutomatique: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private regleAutomatiqueService: RegleAutomatiqueService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Mémorisation de la règle automatique avant modifications
		this.savedRegleAutomatique = cloneDeep(this.regleAutomatique);

		//Définition de la liste des entités
		this.listeEntities = uniqBy(this.listeTypesComputedField.map(type => ({ code: type.entity,libelle: type.libelleEntity })),'code');

		//Définition de l'entité sélectionnée
		this.selectedEntity = this.regleAutomatique?.typeComputedField?.entity;

		//Définition de la liste des types de règles automatiques disponibles pour l'entité
		this.onEntityChange();
	}

	/**
	 * Sélection d'une entité
	 */
	public onEntityChange() {
		//Définition de la liste des types de règles automatiques disponibles pour l'entité
		this.listeFields = this.listeTypesComputedField.filter((type: any) => type.entity == this.selectedEntity);

		//Vérification du changement d'entité
		if (this.selectedEntity != this.savedRegleAutomatique?.typeComputedField?.entity) {
			//Suppression des données dépendantes de l'entité sélectionnée
			this.regleAutomatique.type = null;
			this.regleAutomatique.typeComputedField = null;
			this.regleAutomatique.valeur = null;
			this.regleAutomatique.rule = null;

			//Désactivation de la règle
			this.regleAutomatique.actif = false;
		} else
			//Rétablissement des filtres initiaux
			this.regleAutomatique.rule = this.savedRegleAutomatique.rule;
	}

	/**
	 * Sélection d'un type de règle automatique
	 */
	public onTypeFieldChange(code: any) {
		//Mise à jour du type de règle automatique
		this.regleAutomatique.typeComputedField = this.listeTypesComputedField.find(type => type.code == code);

		//Réinitialisation de la valeur
		this.regleAutomatique.valeur = null;
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveRegleAutomatique() {
		let confirm$: Observable<boolean>;

		//Vérification du changement d'entité
		if (this.savedRegleAutomatique?.idRegleAutomatique && this.selectedEntity != this.savedRegleAutomatique.typeComputedField.entity)
			//Affichage d'un message de confirmation
			confirm$ = this.confirmService.showConfirm(this.translateService.instant('regleAutomatique.confirmation.changementEntite'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed));
		else
			//Pas de confirmation requise
			confirm$ = of(true);

		//Enregistrement de l'objet
		confirm$.pipe(switchMap(() => this.regleAutomatiqueService.saveRegleAutomatique(this.regleAutomatique,this.idRegleAutomatiqueOrigine))).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.regleAutomatique,result.data?.regleAutomatique);

					//Fermeture du formulaire
					this.close(this.regleAutomatique);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du champ
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du champ
							listeDoublons.push(this.translateService.instant('actions.doublon.champ'));

						//Vérification de la valeur
						if (result.data.doublon & Math.pow(2,1))
							//Ajout de la valeur
							listeDoublons.push(this.translateService.instant('actions.doublon.valeur'));

						//Vérification de la date d'application
						if (result.data.doublon & Math.pow(2,2))
							//Ajout de la date d'application
							listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

						//Vérification de l'état
						if (result.data.doublon & Math.pow(2,3))
							//Ajout de l'état
							listeDoublons.push(this.translateService.instant('actions.doublon.actif'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}