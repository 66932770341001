import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './affectation-echeance.entites';

@Component({
	templateUrl: './affectation-echeance-list-item.component.html'
})
export class AffectationEcheanceListItemComponent extends ListViewItem<any> implements OnInit,IListEntity {
	/** Droit **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de la session
		this.store?.select<User>(state => state.session.user).subscribe(user => {
			//Définition de l'utilisateur
			this.user = user;
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'échéance d'une affectation
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idAffectationEcheance,object?.idAffectationEcheance && !this.data?.idAffectationEcheance);
	}

	/**
	 * Redirection vers le véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.data.affectation.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers le conducteur
	 */
	goToConducteur() {
		//Navigation vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: this.data.affectation.conducteur.idConducteur
			},
			withGoBack: true
		});
	}
}