<fieldset ngModelGroup="sinistreContent" #sinistreContent="ngModelGroup" [disabled]="!(TypeDroit.VEHICULE_SINISTRE | right:'creation') || source == 'CONDUCTEUR'">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
				<label translate>vehicule.sinistre.reference</label>
				<div>
					<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="sinistre.reference" maxlength="100" required/>
				</div>
			</div>
			<div class="form-group" *ngIf="source != 'VEHICULE'" [ngClass]="{ 'has-error': sinistreContent.control?.controls['vehicule']?.invalid }">
				<label translate>vehicule.sinistre.vehicule</label>
				<div>
					<ng-container *ngIf="!sinistre.vehicule">
						<autocomplete name="vehicule" type="vehicule" [filter]="{ typeVehicule: 'VEHICULE_FONCTION_SERVICE' }" [(ngModel)]="sinistre.vehicule" required></autocomplete>
					</ng-container>
					<ng-container *ngIf="sinistre.vehicule">
						<mat-chip-listbox>
							<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && goToVehicule()">
								{{ sinistre.vehicule | display:'vehicule' }}
								<button matChipRemove *ngIf="(TypeDroit.VEHICULE_SINISTRE | right:'creation') && source != 'CONDUCTEUR'" (click)="sinistre.vehicule = null; $event.stopPropagation();">
									<mat-icon>cancel</mat-icon>
								</button>
							</mat-chip>
							<span>
								<icon name="replay_30" class="clickable p-l-5 icon-hc-2x" *ngIf="sinistre.vehicule.idVehicule && sinistre.vehicule.listeOldReferences?.length > 0" (click)="vehiculeService.showListeImmatriculations(sinistre.vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate"></icon>
							</span>
						</mat-chip-listbox>
					</ng-container>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateSinistre.invalid }">
				<label translate>vehicule.sinistre.dateSinistre</label>
				<div class="half">
					<input type="text" name="dateSinistre" class="form-control" #dateSinistre="ngModel" (ngModelChange)="onDateSinistreChange($event);" [(ngModel)]="sinistre.dateSinistre" datePicker format="datetime" maxDate="today" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': user.invalid }" *ngIf="source != 'CONDUCTEUR'">
				<label translate>vehicule.sinistre.collaborateur</label>
				<div>
					<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="sinistre.user"></autocomplete>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.sinistre.typeResponsabilite.item</label>
				<div class="half">
					<selectpicker name="typeResponsabilite" [(ngModel)]="sinistre.typeResponsabilite">
						<mat-option *ngFor="let type of listeTypesResponsabilite" [value]="type.code">{{ type.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': typeSinistre.invalid }">
				<label translate>vehicule.sinistre.type</label>
				<div>
					<autocomplete name="typeSinistre" type="typeSinistre" #typeSinistre="ngModel" [(ngModel)]="sinistre.typeSinistre" required></autocomplete>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': statutField.invalid }">
				<label translate>vehicule.sinistre.statut.item</label>
				<div class="half">
					<selectpicker name="statut" #statutField="ngModel" [(ngModel)]="sinistre.statut">
						<mat-option *ngFor="let statut of listeStatuts" [value]="statut.code">{{ statut.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.sinistre.nature</label>
				<div>
					<input type="text" name="nature" class="form-control" #nature="ngModel" [(ngModel)]="sinistre.nature"/>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.sinistre.lieu</label>
				<div>
					<input type="text" name="lieu" class="form-control" #lieu="ngModel" [(ngModel)]="sinistre.lieu"/>
				</div>
			</div>
			<div class="form-group">
				<label translate>vehicule.sinistre.provision</label>
				<div class="half">
					<input type="text" name="provision" class="form-control" #provision="ngModel" [(ngModel)]="sinistre.provision" nNumber="2"/>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group" *ngIf="source == 'VEHICULE' || source == 'CONDUCTEUR'">
		<label translate>vehicule.sinistre.piecesJointes</label>
		<div>
			<div [attachment]="sinistre" [typeAttachment]="TypeAttachment.SINISTRE" [listeTypesDroit]="[TypeDroit.VEHICULE_SINISTRE]"></div>
		</div>
	</div>
	<div class="form-group">
		<label translate>vehicule.sinistre.remarque</label>
		<div>
			<textarea class="form-control" name="remarque" [(ngModel)]="sinistre.remarque" rows="3"></textarea>
		</div>
	</div>
</fieldset>