<form #connecteurPasswordsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>connecteur.password.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': passwordValue.invalid }" *ngFor="let password of listePasswords; let idxPassword = index">
				<label translate>connecteur.password.password</label>
				<div class="input-group">
					<input #passwordValue [type]="password.showPassword ? 'text' : 'password'" [name]="'value_'+password.key" #value="ngModel" class="form-control" [(ngModel)]="password.value" (ngModelChange)="password.valueUpdated = true" required [disabled]="password.idConnecteurPassword"/>
					<div *ngIf="!password.idConnecteurPassword" class="input-group-addon clickable" (click)="password.showPassword = !password.showPassword">
						<icon [name]="password.showPassword ? 'visibility_off' : 'visibility'"></icon>
					</div>
					<div class="input-group-addon no-error hovered-item no-border">
						<button mat-icon-button (click)="deletePassword(idxPassword);">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="listview">
				<div class="lv-body">
					<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addPassword();">
						<h5><a [routerLink]="[]" translate>connecteur.password.ajouterPassword</a></h5>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="savePasswords()" [disabled]="connecteurPasswordsForm.invalid || !listePasswords?.length" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>