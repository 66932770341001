<div class="modal-header">
	<h4 class="modal-title"><span translate>notification.selection.destinataire.ajout.title</span></h4>
</div>
<div class="modal-body">
	<form #addDestinataireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="p-t-20">
			<div class="p-b-10">
				<label translate>notification.selection.destinataire.ajout.selectionReferentiel</label>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': !(newUser || newEmail) }">
				<label translate>notification.selection.destinataire.ajout.collaborateur</label>
				<div>
					<autocomplete name="user" type="user" [(ngModel)]="newUser" required [disabled]="newEmail"></autocomplete>
				</div>
			</div>
			<div class="separator">
				<span translate>common.ou</span>
			</div>
			<div class="p-b-10">
				<label translate>notification.selection.destinataire.ajout.saisieEmail</label>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': !(newUser || newEmail) || destinataire.invalid }">
				<label translate>notification.selection.destinataire.ajout.email</label>
				<div>
					<input type="email" email class="form-control" name="destinataire" #destinataire="ngModel" [(ngModel)]="newEmail" [disabled]="newUser"/>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="!(newUser || newEmail)" (click)="close()">
		<span translate>actions.continuer</span>
	</button>
</div>