import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CompteAddComponent } from './compte-add.component';
import { CompteCompteAuxiliaireEditComponent } from './compte-compte-auxiliaire-edit.component';
import { CompteCompteAuxiliaireListItemComponent } from './compte-compte-auxiliaire-list-item.component';
import { CompteCompteAuxiliaireListComponent } from './compte-compte-auxiliaire-list.component';
import { CompteEditComponent } from './compte-edit.component';
import { CompteFournisseurEditComponent } from './compte-fournisseur-edit.component';
import { CompteFournisseurListItemComponent } from './compte-fournisseur-list-item.component';
import { CompteFournisseurListComponent } from './compte-fournisseur-list.component';
import { CompteListItemComponent } from './compte-list-item.component';
import { CompteListComponent } from './compte-list.component';
import { ComptePosteChargeEditComponent } from './compte-poste-charge-edit.component';
import { ComptePosteChargeListItemComponent } from './compte-poste-charge-list-item.component';
import { ComptePosteChargeListComponent } from './compte-poste-charge-list.component';
import { ComptePosteChargeTransfertComponent } from './compte-poste-charge-transfert.component';
import { CompteSocieteEditComponent } from './compte-societe-edit.component';
import { CompteSocieteListItemComponent } from './compte-societe-list-item.component';
import { CompteSocieteListComponent } from './compte-societe-list.component';
import { CompteTaxeEditComponent } from './compte-taxe-edit.component';
import { CompteTaxeListItemComponent } from './compte-taxe-list-item.component';
import { CompteTaxeListComponent } from './compte-taxe-list.component';
import { CompteUserEditComponent } from './compte-user-edit.component';
import { CompteUserListItemComponent } from './compte-user-list-item.component';
import { CompteUserListComponent } from './compte-user-list.component';
import { CompteComponent } from './compte.component';
import { CompteService } from './compte.service';

@NgModule({
	imports: [ShareModule],
	declarations: [CompteListComponent,CompteListItemComponent,CompteAddComponent,CompteComponent,CompteEditComponent,CompteSocieteListComponent,CompteSocieteListItemComponent,CompteSocieteEditComponent,ComptePosteChargeListComponent,ComptePosteChargeListItemComponent,ComptePosteChargeEditComponent,CompteCompteAuxiliaireListComponent,CompteCompteAuxiliaireListItemComponent,CompteCompteAuxiliaireEditComponent,CompteUserListComponent,CompteUserListItemComponent,CompteUserEditComponent,CompteFournisseurListComponent,CompteFournisseurListItemComponent,CompteFournisseurEditComponent,CompteTaxeListComponent,CompteTaxeListItemComponent,CompteTaxeEditComponent,ComptePosteChargeTransfertComponent],
	providers: [CompteService]
})
export class CompteModule { }