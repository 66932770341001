import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { StepperModule } from 'src/app/share/components/stepper/stepper.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { ImportFournisseurListItemComponent } from './import-fournisseur-list-item.component';
import { ImportFournisseurListComponent } from './import-fournisseur-list.component';
import { ImportSelectionComponent } from './import-selection.component';
import { ThumbModule } from 'src/app/share/components/thumb/thumb.module';
import { ImportFormatListComponent } from './import-format-list.component';
import { ImportFormatListItemComponent } from './import-format-list-item.component';
import { ImportAttachmentsComponent } from './import-attachments.component';
import { AttachmentModule } from 'src/app/share/components/attachment/attachment.module';
import { ImportResumeComponent } from './import-resume.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,StepperModule,ThumbModule,AttachmentModule,IconModule,PleaseWaitModule],
	declarations: [ImportSelectionComponent,ImportFournisseurListComponent,ImportFournisseurListItemComponent,ImportFormatListComponent,ImportFormatListItemComponent,ImportAttachmentsComponent,ImportResumeComponent]
})
export class ImportModule {}