<form #groupeRuleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div class="card" [ngClass]="{ 'detail': isEdition }">
		<div class="card-header" *ngIf="isEdition">
			<h2 class="card-title" translate>groupe.rule.titre</h2>
		</div>
		<div class="card-body">
			<fieldset [disabled]="!(null | right:'creation') || !isEdition">
				<rule [entite]="groupe.entity" [rule]="rule" [isEdition]="isEdition"></rule>
			</fieldset>
		</div>
	</div>

	<span class="pull-right" *ngIf="isEdition">
		<button mat-raised-button color="primary" *ngIf="null | right:'creation'" [disabled]="groupeRuleForm.invalid" (click)="saveRule()">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button (click)="close()" *ngIf="isEdition">
		<span translate>actions.fermer</span>
	</button>
</form>