import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalendrierService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Enregistrement d'un calendrier
	 */
	public saveCalendrier(calendrier: any): Observable<Result> {
		//Enregistrement du calendrier
		return this.http.put<Result>(`${environment.baseUrl}/controller/Calendrier/saveCalendrier`,calendrier);
	}

	/**
	 * Chargement d'un calendrier
	 */
	public loadCalendrier(idCalendrier: number): Observable<Result> {
		//Chargement du calendrier
		return this.http.post<Result>(`${environment.baseUrl}/controller/Calendrier/loadCalendrier/${idCalendrier}`,null);
	}

	/**
	 * Suppression d'un calendrier
	 */
	public deleteCalendrier(idCalendrier: number): Observable<Result> {
		//Suppression du calendrier
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Calendrier/deleteCalendrier/${idCalendrier}`);
	}
}