import { TranslateService } from '@ngx-translate/core';

import { VehiculeAddComponent } from 'src/app/components/vehicule/vehicule-add.component';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { VehiculeLineItemComponent } from './vehicule.line-item';

/**
 * Options des véhicules
 */
export default class VehiculeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.vehicule';

	/**
	 * Récupération de l'URL
	 */
	url(filter?: { isShowAll?: boolean,typeVehicule?: string | Array<string>,isWithoutFinancement?: boolean,isWithoutDemandeVehicule?: boolean }) {
		//URL
		return filter?.isWithoutFinancement ? `/controller/Vehicule/filtreVehiculesWithoutFinancement` : filter?.isWithoutDemandeVehicule ? '/controller/Vehicule/filtreVehiculesWithoutDemandeVehicule' : `/controller/Vehicule/listeVehicules${typeof filter?.typeVehicule == 'string' && !Array.isArray(filter?.typeVehicule) ? '/' + filter.typeVehicule : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item.reference}${item.numeroInterne ? ' - ' + item.numeroInterne : ''} (${item.modele?.libelle ? item.modele?.marque?.libelle + ' ' + item.modele?.libelle : item.typeVehicule == 'ENGIN' ? item.marque?.libelle : translateService.instant('vehicule.modele.nonDefini')})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,2)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.Vehicule';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idVehicule'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'numeroInterne',
			isDefault: true
		},{
			clef: 'listeOldReferences',
			isDefault: true
		},{
			clef: '*modele.libelle',
			isDefault: true
		},{
			clef: '*modele.*marque.libelle',
			isDefault: true
		}];
	}

	/**
	 * Tri par défaut
	 */
	defaultOrder = 'reference,*modele.libelle,*modele.marque.libelle';

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: { isShowAll?: boolean,typeVehicule?: string | Array<string>,isWithoutFinancement?: boolean,isWithoutDemandeVehicule?: boolean }): Array<Filter> {
		//Retour des filtres statiques
		return [!filter?.isShowAll && !filter?.isWithoutFinancement && !filter?.isWithoutDemandeVehicule && {
			clef: 'actif',
			valeur: 'true',
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		},!filter?.isShowAll && {
			clef: 'dateEntree',
			valeur: null,
			typeComparaison: TypeComparaison.IS_NOT_NULL,
			type: TypeFilter.DATE
		},Array.isArray(filter?.typeVehicule) && {
			clef: 'typeVehicule',
			typeComparaison: TypeComparaison.IN,
			listeObjects: filter.typeVehicule,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}

	/**
	 * Composant d'affichage d'une ligne de véhicule
	 */
	lineItemComponent?: () => any = () => VehiculeLineItemComponent;

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'vehicule.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return VehiculeAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_VEHICULE;
}