import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des demandes de véhicule
 */
export default class DemandeVehiculeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.demandeVehicule';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeCommande/filtreDemandesVehicule`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) {
		let libelle: string = '';

		//Vérification de l'élément
		if (item) {
			//Définition du libellé de base
			libelle = translateService.instant('demandeVehicule.liste.item',{ numero: item.idDemandeVehicule }) + ' - ';

			//Vérification du bénéficiaire
			if (item.user)
				//Ajout du collaborateur
				libelle += `${item.user.prenom} ${item.user.nom} (${item.user.matricule + (item.societe ? ' - ' + item.societe.libelle : '')})`;
			else if (!item.user && item.service)
				//Ajout du service
				libelle += `${item.service.libelle} (${item.societe.libelle})`;
			else if (!item.user && !item.service && item.societe)
				//Ajout de la société
				libelle += item.societe.libelle;
			else if (!item.user && !item.service && !item.societe)
				//Aucun bénéficiaire
				libelle += translateService.instant('demandeVehicule.beneficiaire.aucun');
		}

		return libelle;
	}

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.statut == 'EN_COURS' ? 'COU' : item?.statut == 'EN_COMMANDE' ? 'CDE' : 'LIV'; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.cotation.DemandeVehicule';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idDemandeVehicule'; }

	/**
	 * Tri par défaut
	 */
	defaultOrder = '-statut,-dateCreation';

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'idDemandeVehicule',
			isDefault: true
		}];
	}
}