import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class GroupeService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement d'un groupe
	 */
	public loadGroupe(idGroupe: number): Observable<Result> {
		//Chargement d'un groupe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Groupe/loadGroupe/${idGroupe}`,null);
	}

	/**
	 * Enregistrement d'un groupe
	 */
	public saveGroupe(groupe: any): Observable<Result> {
		//Enregistrement d'un groupe
		return this.http.put<Result>(`${environment.baseUrl}/controller/Groupe/saveGroupe`,groupe);
	}

	/**
	 * Suppression d'un groupe
	 */
	public deleteGroupe(idGroupe: number): Observable<Result> {
		//Suppression d'un groupe
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Groupe/deleteGroupe/${idGroupe}`);
	}
}
