import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { AttachmentEntity,LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PoolEditComponent } from './pool-edit.component';
import { PoolVehiculeAddComponent } from './pool-vehicule-add.component';
import { PoolVehiculeListComponent } from './pool-vehicule-list.component';
import { PoolService } from './pool.service';

@Component({
	selector: 'pool',
	templateUrl: './pool.component.html'
})
export class PoolComponent implements OnInit,IEntity,AttachmentEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Pool courant **/
	public pool: any;

	/** Résumé **/
	public resume: any = null;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private poolService: PoolService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,private bsModalService: BsModalService,private attachmentService: AttachmentService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.pool.Pool';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.pool?.idPool || 0;

	/** Récupération de l'entité portant les pièces jointes **/
	getOwningEntity: () => LinksOwningEntity = () => this.pool;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.POOL;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'pool.libelle',
				key: 'libelle'
			},{
				libelle: 'pool.reference',
				key: 'reference'
			},{
				libelle: 'pool.reservationAutomatique',
				key: 'reservationAutomatique',
				type: 'BOOLEAN'
			},{
				libelle: 'pool.actif',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editPool(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editPool(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deletePool(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeElements: [{
				type: 'VEHICULE',
				libelle: 'pool.elements.vehicule',
				component: PoolVehiculeListComponent,
				retrieveComponentData: () => ({
					pool: this.pool,
					resume: this.resume,
					onResumeChange: resume => this.resume = resume
				}),
				count: () => this.resume?.nbVehicules || 0,
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation'),
				doAction: () => this.showAddVehicules(),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				libelleAction: 'pool.elements.vehicule.actions.associer'
			}],
			isFormCustomization: true
		};

		//Récupération de l'identifiant du pool à charger
		this.activatedRoute.params.pipe(take(1)).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idPool);
			}
		});
	}

	/**
	 * Edition du pool
	 */
	editPool() {
		//Ouverture du composant d'édition
		this.pageContentService.open(PoolEditComponent,{
			data: {
				pool: this.pool.idPool ? cloneDeep(this.pool) : this.pool,
				deletePool: this.deletePool.bind(this)
			}
		}).subscribe({
			next: data => {
				//Vérification des données
				if (data) {
					//Mise à jour du pool
					this.pool = data;

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.pool);
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idPool: number) {
		//Chargement du pool
		this.poolService.loadPool(idPool).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du pool
					this.pool = result.data.pool;

					//Définition du résumé
					this.resume = {
						nbVehicules: result.data.nbVehicules
					}

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du pool
					if (!this.pool?.idPool) {
						//Création d'un pool vierge
						this.pool = {
							actif: true,
							reservationAutomatique: false,
							listeLinks: []
						};

						//Edition du pool
						this.editPool();
					}
				}
			}
		});
	}

	/**
	 * Suppression de l'objet
	 */
	private deletePool() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du pool
					this.poolService.deletePool(this.pool).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								delete this.pool;

								//Fermeture de la page
								this.layoutService.goBack();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Ajout de nouveaux véhicules au pool
	 */
	private showAddVehicules() {
		let bsModalRef: BsModalRef<PoolVehiculeAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(PoolVehiculeAddComponent,{
			initialState: {
				pool: this.pool
			},
			class: 'modal-lg'
		});

		//Interception du masquage de la popup
		bsModalRef.onHidden.subscribe({
			next: () => {
				let liste: ListView<any,any>;

				//Sélection de la liste des véhicules (si disponible)
				liste = this.complexPage.complexPageElements.getInstance('VEHICULE')?.liste;

				//Mise à jour du résumé
				this.resume.nbVehicules = this.resume.nbVehicules + (bsModalRef.content.nbProcessed || 0);

				//Rafraichissement de la liste
				liste?.refresh();
			}
		});
	}
}
