import { Component,Input,OnInit,ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment,TypeAttachmentContext } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { AttachmentComponent } from 'src/app/share/components/attachment/attachment.component';
import { FileWithProgress } from 'src/app/share/directive/file-uploader/file-uploader';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { AttachmentEntity } from 'src/app/domain/attachment/attachment';

@Component({
	templateUrl: './notification-attachments.component.html'
})
export class NotificationAttachmentsComponent implements OnInit {
	/** Template de mail sélectionné **/
	@Input() selectedMail: any;

	/** Objet parent dont les pièces jointes peuvent être sélectionnées **/
	@Input() attachmentEntity: AttachmentEntity;

	/** Composant de gestion des attachments **/
	@ViewChild('attachment') attachmentComponent: AttachmentComponent;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/** Nombre de pièces jointes de l'objet parent **/
	nbAttachments$: Observable<number>;

	/**
	 * Constructeur
	 */
	constructor(private attachmentService: AttachmentService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des options
		this.attachmentOptions = {
			owningEntity: this.selectedMail,
			typeAttachment: TypeAttachment.TEMPLATE_MAIL,
			typeLink: 'MAIL_ATTACHMENT',
			inline: true,
			withoutAttachmentSaving: true,
			onCompleteItem: (file: File,result: Result,linkAttachment: any) => {
				//Vérification de la liste des liens
				this.selectedMail.listeLinks = this.selectedMail.listeLinks || [];

				//Ajout du lien
				this.selectedMail.listeLinks.push(linkAttachment);
			},
			isFromLink: true,
			context: 'NOTIFICATION'
		};

		//Vérification de la possibilité de récupérer des pièces jointes de l'objet parent
		if (this.attachmentEntity)
			//Récupération du nombre de pièces jointes de l'objet parent
			this.nbAttachments$ = this.attachmentService.countAttachments(this.attachmentEntity).pipe(map(result => result?.data?.nbAttachments || 0));
	}

	/**
	 * Récupération de la queue des fichiers à uploader
	 */
	getUploadQueue(): Array<FileWithProgress> {
		//Retour de la queue
		return this.attachmentComponent?.fileUploader?.queue;
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	uploadAll() {
		//Upload de l'ensemble des fichiers
		this.attachmentComponent.uploadAll();
	}


	/**
	 * Affichage de la pop-up de sélection des pièces jointes de l'objet parent
	 */
	showSelectionAttachments() {
		//Affichage de la pop-up de gestion des pièces jointes
		this.attachmentService.showAttachment({
			owningEntity: this.attachmentEntity.getOwningEntity(),
			typeAttachment: this.attachmentEntity.typeAttachment,
			idObjet: this.attachmentEntity.getOwningEntity()[TypeAttachmentContext[this.attachmentEntity.typeAttachment].key] || null,
			readOnly: true,
			isFromLink: false,
			typeLink: this.attachmentEntity.typeLink,
			typeLinkClass: this.attachmentEntity.typeLinkClass,
			isSelection: true,
			onAttachmentsSelected: listeAttachments => {
				//Mise à jour des liens
				this.selectedMail.listeLinks = (this.selectedMail.listeLinks || []).concat(listeAttachments.filter(attachment => {
					//Vérification de l'absence de la pièce jointe
					return (this.selectedMail.listeLinks || []).findIndex(link => link?.attachment?.idAttachment == attachment.idAttachment) == -1;
				}).map(attachment => ({
					attachment: attachment,
					_type: 'com.notilus.data.attachment.link.LinkTemplateMail',
					type: 'MAIL_ATTACHMENT'
				})));
			}
		});
	}
}