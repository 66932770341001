<div class="container-fluid">
	<form #compteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': planComptable.invalid }" *ngIf="!rightService.isRoot()">
								<label translate>comptabilite.compte.planComptable</label>
								<div>
									<autocomplete name="planComptable" type="planComptable" #planComptable="ngModel" [(ngModel)]="compte.planComptable" (ngModelChange)="compte.pays = $event?.pays;" required [disabled]="compte.idCompte"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
								<label translate>comptabilite.compte.numero</label>
								<div>
									<input type="text" name="numero" #numero="ngModel" class="form-control" [(ngModel)]="compte.numero" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>comptabilite.compte.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="compte.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>comptabilite.compte.type.item</label>
								<div class="half">
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="compte.type" required>
										<mat-option *ngFor="let type of listeTypesComptes" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>comptabilite.compte.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="compte.pays" required [disabled]="!rightService.isRoot()"></autocomplete>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>comptabilite.compte.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="compte.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteCompte();" *ngIf="compte?.idCompte && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveCompte();" *ngIf="null | right:'creation'" [disabled]="compteForm.invalid">
				<span [translate]="'actions.'+(compte?.idCompte ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="compte?.idCompte" [formDetectChanges]="{ doAction: close,isDirty: compteForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>