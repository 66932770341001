import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des contraventions
 */
export default class ContraventionOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.contravention';

	/**
	 * Récupération de l'URL
	 */
	url(): string {
		//URL
		return `/controller/Contravention/filtreContraventions`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item? `${item.reference}${item.vehicule?.reference ? ' (' + item.vehicule.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.type == 'FORFAIT_POST_STATIONNEMENT' ? 'FPS' : 'A'; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.Contravention';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idContravention'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'vehicule.reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.type && {
			clef: 'type',
			valeur: filter.type,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},filter?.majoration !== undefined && {
			clef: 'isMajoration',
			valeur: filter.majoration.toString(),
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		},filter?.majoree !== undefined && {
			clef: 'isMajoree',
			valeur: filter.majoree.toString(),
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		},filter?.idContraventionExcluded && {
			clef: 'idContravention',
			valeur: filter.idContraventionExcluded,
			typeComparaison: TypeComparaison.NOT_EQUALS,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}
}