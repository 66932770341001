import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter,map } from 'rxjs/operators';

import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';

@Component({
	selector: 'workflow-detail-valideur-definition',
	templateUrl: './workflow-detail-valideur-definition.component.html'
})
export class WorkflowDetailValideurDefinitionComponent implements AfterContentChecked {
	/** Etape **/
	@Input() etape: {
		typeValideur?: 'VALIDEUR_DYNAMIQUE' | 'VALIDEUR_NOMME',
		listeValideurs?: Array<any>,
		listeValideursNommes?: Array<any>,
		libelle?: string
	};

	/** Entité **/
	@Input() entite: string;

	/** Edition **/
	@Input() isEdition: boolean;

	/** Résultat **/
	result: { listeValideurs: Array<any>,listeValideursNommes: Array<any> };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<WorkflowDetailValideurDefinitionComponent>,private businessDataService: BusinessDataService) {}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Ajout d'un valideur dynamique
	 */
	addValideurDynamique() {
		//Vérification de la liste des valideurs
		this.etape.listeValideurs = this.etape.listeValideurs || [];

		//Affichage de la pop-up des données métier
		this.businessDataService.showBusinessData(this.entite,{
			typeContext: 'WORKFLOW_VALIDEUR',
			isSelectEntity: false
		}).pipe(
			filter(result => !!result),
			map(({ entity }) => ({ valideurDynamique: entity.path,libelle: entity.pathLibelle }))
		).subscribe({
			next: (result) => {
				//Ajout du valideur dynamique
				this.etape.listeValideurs.push(result);
			}
		})
	}

	/**
	 * Suppression d'un valideur dynamique
	 */
	deleteValideurDynamique = (index: number) => this.etape.listeValideurs.splice(index,1);

	/**
	 * Vérification de la validité de la liste
	 */
	isListeValideursNommesInvalid: () => boolean = () => !(this.etape.listeValideursNommes?.length > 0)

	/**
	 * Enregistrement de la sélection
	 */
	public saveWorkflowDetailValideurDefinition() {
		//Définition du résultat
		this.result = {
			listeValideurs: this.etape.listeValideurs,
			listeValideursNommes: this.etape.listeValideursNommes
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}