import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ImmatriculationAUTREComponent } from './immatriculation-autre.component';
import { ImmatriculationFRComponent } from './immatriculation-fr.component';
import { ImmatriculationModeleCatalogueListItemComponent } from './immatriculation-modele-catalogue-list-item.component';
import { ImmatriculationModeleCatalogueListComponent } from './immatriculation-modele-catalogue-list.component';
import { ImmatriculationModeleCatalogueSelectionChoixComponent } from './immatriculation-modele-catalogue-selection-choix.component';
import { ImmatriculationModeleCatalogueSelectionModeleListComponent } from './immatriculation-modele-catalogue-selection-modele-list.component';
import { ImmatriculationModeleCatalogueSelectionResumeComponent } from './immatriculation-modele-catalogue-selection-resume.component';
import { ImmatriculationModeleCatalogueSelectionComponent } from './immatriculation-modele-catalogue-selection.component';
import { ImmatriculationService } from './immatriculation.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ImmatriculationFRComponent,ImmatriculationAUTREComponent,ImmatriculationModeleCatalogueListComponent,ImmatriculationModeleCatalogueListItemComponent
		,ImmatriculationModeleCatalogueSelectionComponent,ImmatriculationModeleCatalogueSelectionChoixComponent,ImmatriculationModeleCatalogueSelectionModeleListComponent,ImmatriculationModeleCatalogueSelectionResumeComponent
	],
	providers: [ImmatriculationService]
})
export class ImmatriculationModule {}