import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CompteAuxiliaireListItemComponent } from './compte-auxiliaire-list-item.component';
import { CompteAuxiliaireListComponent } from './compte-auxiliaire-list.component';
import { CompteAuxiliaireService } from './compte-auxiliaire.service';
import { CompteAuxiliaireAddComponent } from 'src/app/components/comptabilite/compte-auxiliaire/compte-auxiliaire-add.component';
import { CompteAuxiliaireComponent } from './compte-auxiliaire.component';
import { CompteAuxiliaireEditComponent } from './compte-auxiliaire-edit.component';
import { CompteAuxiliaireUserListComponent } from './compte-auxiliaire-user-list.component';
import { CompteAuxiliaireUserListItemComponent } from './compte-auxiliaire-user-list-item.component';
import { CompteAuxiliaireUserEditComponent } from './compte-auxiliaire-user-edit.component';
import { CompteAuxiliaireFournisseurListComponent } from './compte-auxiliaire-fournisseur-list.component';
import { CompteAuxiliaireFournisseurListItemComponent } from './compte-auxiliaire-fournisseur-list-item.component';
import { CompteAuxiliaireFournisseurEditComponent } from './compte-auxiliaire-fournisseur-edit.component';

@NgModule({
	imports: [ShareModule],
	declarations: [CompteAuxiliaireListComponent,CompteAuxiliaireListItemComponent,CompteAuxiliaireAddComponent,CompteAuxiliaireComponent,CompteAuxiliaireEditComponent
		,CompteAuxiliaireUserListComponent,CompteAuxiliaireUserListItemComponent,CompteAuxiliaireUserEditComponent
		,CompteAuxiliaireFournisseurListComponent,CompteAuxiliaireFournisseurListItemComponent,CompteAuxiliaireFournisseurEditComponent
	],
	providers: [CompteAuxiliaireService]
})
export class CompteAuxiliaireModule { }
