import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,sum,uniq } from 'lodash-es';
import moment from 'moment';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxPartListComponent } from './etat-lieux-part-list.component';
import { EtatLieuxService } from './etat-lieux.service';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';

@Component({
	selector: 'etat-lieux-content',
	templateUrl: './etat-lieux-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class EtatLieuxContentComponent implements OnInit {
	/** Elément courant **/
	@Input() etatLieux: any;

	/** Source **/
	@Input() source: 'ETAT_LIEUX' | 'CONDUCTEUR' | 'VEHICULE';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Rafraichissement de l'état des lieux **/
	@Output() refreshEtatLieux = new EventEmitter<any>();

	/** Récupération de l'entité portant les pièces jointes **/
	public getOwningEntity: () => LinksOwningEntity = () => this.etatLieux;

	/** Type d'attachment **/
	public typeAttachment: TypeAttachment = TypeAttachment.ETAT_LIEUX;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Copie de l'état des lieux **/
	public savedEtatLieux: any;

	/** Utilisateur connecté **/
	user: User;

	/** Date minimum de planification **/
	public datePlanificationMin: moment.Moment = moment.utc();

	/** Liste des parties d'un état des lieux **/
	public listeParts: any;

	/** Nombre de liens **/
	public nbLinks: number = 0;

	/** Date courante **/
	today = moment().startOf('day');

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxService: EtatLieuxService,private translateService: TranslateService,private confirmService: ConfirmService,private layoutService: LayoutService,private toastrService: ToastrService
			,public rightService: RightService,private bsModalService: BsModalService,private datePipe: DatePipe,private decimalPipe: DecimalPipe,private store: Store<AppState>,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.saveEtatLieux = this.saveEtatLieux.bind(this);
		this.deleteEtatLieux = this.deleteEtatLieux.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});

		//Liste des parties d'un état des lieux
		this.listeParts = this.etatLieuxService.getListeParts();

		//Copie de l'état des lieux
		this.savedEtatLieux = cloneDeep(this.etatLieux);

		//Vérification de la présence d'une date de planification
		if (this.etatLieux.datePlanification)
			//Calcul de la date de planification minimum
			this.computeDates();

		//Vérification de la liste des liens
		if (this.etatLieux.listeLinks?.length)
			//Définition du nombre de liens sans la signature
			this.nbLinks = this.etatLieux.listeLinks.filter(l => l.type != 'SIGNATURE').length;
	}

	/**
	 * Calcul des dates
	 */
	private computeDates() {
		let datePlanification: moment.Moment;

		//Définition de la date minimum de planification
		datePlanification = moment.utc(this.etatLieux.datePlanification).startOf('day');

		//Vérification de la date de planification par rapport à la date courante
		if (moment(datePlanification).isBefore(this.datePlanificationMin))
			//Définition de la date minimum de planification
			this.datePlanificationMin = datePlanification;
	}

	/**
	 * Vérification de la présence de toutes les parties d'un état des lieux
	 */
	public hasAllParts(etatLieux: any) {
		let hasAllParts = false;

		//Vérification des parties de l'état des lieux
		if (etatLieux?.listeLinks?.length)
			//Comparaison du nombre de types de pièces jointes avec le nombre de zones d'un état des lieux
			hasAllParts = uniq(etatLieux.listeLinks.filter(l => l.type != 'SIGNATURE').map(l => l.type)).length ==  sum(this.listeParts.map(p => p.listeZones.length));

		return hasAllParts;
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveEtatLieux() {
		let observable: Observable<boolean>;

		//Vérification de la présence de l'ensemble des photos
		observable = this.hasAllParts(this.etatLieux) && this.etatLieux?.statut !== 'TERMINE' ? this.confirmService.showConfirm(this.translateService.instant('vehicule.etatLieux.confirmation.terminer'),{ actionColor: 'primary' }) : of(true);

		//Attente du résultat
		observable.subscribe({
			next: isWithoutBlockStatutUpdate => {
				//Indicateur de mise à jour du statut
				this.etatLieux.blockStatutUpdate = !isWithoutBlockStatutUpdate;

				//Enregistrement de l'objet
				this.etatLieuxService.saveEtatLieux(this.etatLieux).subscribe({
					next: (result: Result) => {
						let listeDoublons = new Array<string>();

						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour de l'objet
							Object.assign(this.etatLieux,result.data?.etatLieux);

							//Récupération de l'état des lieux modifié
							this.savedEtatLieux = cloneDeep(this.etatLieux);

							//Fermeture du formulaire
							this.close.emit(this.etatLieux);
						} else {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
								//Vérification de la date de planification
								if (result.data.doublon & Math.pow(2,0))
									//Ajout du libellé
									listeDoublons.push(this.translateService.instant('actions.doublon.datePlanification'));

								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
									field: listeDoublons.join(', ')
								}));
							} else if (result.data?.dateAndCompteurConflitByReleve) {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('vehicule.releveCompteur.erreur.coherence',{
									dateReleve: this.datePipe.transform(result.data.dateAndCompteurConflitByReleve.date,'shortDate'),
									releveCompteur: this.decimalPipe.transform(result.data.dateAndCompteurConflitByReleve.compteur,'1.0-0')
								}));
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						}
					}
				});
			}
		});
	}

	/**
	 * Affichage des photos de l'état des lieux
	 */
	public showListeParts() {
		let bsModalRef: BsModalRef<EtatLieuxPartListComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(EtatLieuxPartListComponent,{
			initialState: {
				etatLieux: this.etatLieux,
				isReadOnly: this.etatLieux.statut == 'TERMINE'
			},
			class: 'modal-max'
		});

		//Mise à jour du nombre de liens
		bsModalRef.onHidden.pipe(map(() => bsModalRef.content.result?.etatLieux)).subscribe({
			next: etatLieux => {
				//Vérification de l'état des lieux
				if (etatLieux) {
					//Mise à jour de l'état des lieux
					Object.assign(this.etatLieux,etatLieux);

					//Vérification de la liste des liens
					if (this.etatLieux.listeLinks?.length)
						//Définition du nombre de liens sans la signature
						this.nbLinks = this.etatLieux.listeLinks.filter(l => l.type != 'SIGNATURE').length;

					//Rechargement de l'état des lieux
					this.refreshEtatLieux.emit();
				}
			}
		});
	}

	/**
	 * Suppression de l'état des lieux
	 */
	deleteEtatLieux() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.etatLieuxService.deleteEtatLieux(this.etatLieux))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Vérification de la modification de l'objet
	 */
	isDirty(): boolean {
		//Vérification de la modification de l'objet
		return !isEqual(this.savedEtatLieux,this.etatLieux);
	}

	/**
	 * Affichage du véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.etatLieux.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de la réservation
	 */
	goToReservation() {
		//Navigation vers la réservation
		this.layoutService.goToByState('listeReservations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idReservation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: this.etatLieux.reservation.idReservation,
					title: this.translateService.instant('vehicule.etatLieux.navigationReservation',{ reference: this.etatLieux.vehicule.reference })
				}]
			}
		});
	}
}