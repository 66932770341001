<div class="container-fluid">
	<form #regleAutomatiqueForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': entityField.invalid }">
								<label translate>regleAutomatique.entity</label>
								<div class="half">
									<selectpicker name="entity" #entityField="ngModel" [(ngModel)]="selectedEntity" (ngModelChange)="onEntityChange()" [disabled]="idRegleAutomatiqueOrigine" required>
										<mat-option *ngFor="let entity of listeEntities" [value]="entity.code">{{ entity.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>regleAutomatique.champ</label>
								<div>
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="regleAutomatique.type" (ngModelChange)="onTypeFieldChange($event)" [disabled]="!selectedEntity" required>
										<mat-option *ngFor="let type of listeFields" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': valeurField.invalid }">
								<label translate>regleAutomatique.valeur</label>
								<div>
									<selectpicker name="valeur" #valeurField="ngModel" [(ngModel)]="regleAutomatique.valeur" [disabled]="!regleAutomatique.type" required>
										<mat-option *ngFor="let valeur of regleAutomatique?.typeComputedField?.listeValeurs" [value]="valeur.code">{{ valeur.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label>
								<span translate>regleAutomatique.actif.item</span>
								<icon [tooltip]="'regleAutomatique.actif.description' | translate" name="info_outline" class="m-l-10"></icon>
							</label>
							<div>
								<mat-checkbox name="actif" type="actif" #actif="ngModel" [(ngModel)]="regleAutomatique.actif" [disabled]="!regleAutomatique?.rule?.listeDetails?.length"><span translate>regleAutomatique.actif.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
							<label translate>regleAutomatique.dateApplication</label>
							<div class="half">
								<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="regleAutomatique.dateApplication" datePicker format="date" required/>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>regleAutomatique.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="regleAutomatique.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteRegleAutomatique();" *ngIf="regleAutomatique.idRegleAutomatique && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="regleAutomatiqueForm.invalid" (click)="saveRegleAutomatique();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(regleAutomatique?.idRegleAutomatique ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="regleAutomatique.idRegleAutomatique" [formDetectChanges]="{ doAction: close,isDirty: regleAutomatiqueForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>