import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class SgiLotService {
	/** Liste des types de flux **/
	private listeTypesFlux: Array<string> = ['MAJ','FULL'];

	/** Liste des types de lot **/
	private listeTypesLot: Array<{ code: string,icone: string,iconeType?: string }> = [{
		code: 'DEMANDE_IDENTIFICATION',
		icone: 'drafts'
	},{
		code: 'REPONSE_DESIGNATION',
		icone: 'drafts'
	},{
		code: 'ACK_REPONSE_DESIGNATION',
		icone: 'drafts'
	},{
		code: 'ETAT_PARC',
		icone: 'directions_car'
	},{
		code: 'ACK_ETAT_PARC',
		icone: 'directions_car'
	}];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private http: HttpClient) { }

	/**
	 * Récupération des types de flux des lots
	 */
	public getListeTypesFlux() {
		//Retour de la liste des types de flux des lots traduits
		return this.listeTypesFlux.map(code => ({
			code,
			libelle: this.translateService.instant('sgi.lot.typeFlux.'+code)
		}));
	}

	/**
	 * Récupération de la liste des types de lots traduits
	 */
	public getListeTypesLot(): Array<{ code: string,icone: string,iconeType?: string,libelle: string }> {
		//Retour de la liste des types de lots traduits
		return this.listeTypesLot.map(typeLot => ({
			...typeLot,
			libelle: this.translateService.instant('sgi.lot.typeLot.'+typeLot.code)
		}));
	}

	/**
	 * Récupération des icones des types des lots
	 */
	public getMapTypesLot(): { [typeLot: string]: { icone: string,iconeType?: string } } {
		//Retour des icones
		return this.listeTypesLot.reduce((mapTypesLot,typeLot) => {
			//Ajout de l'icone
			mapTypesLot[typeLot.code] = {
				icone: typeLot.icone,
				iconeType: typeLot.iconeType
			};

			return mapTypesLot;
		},{});
	}

	/**
	 * Clôture d'un lot
	 */
	public closeLot(idLot: number): Observable<Result> {
		//Clôture d'un lot
		return this.http.post<Result>(`${environment.baseUrl}/controller/Sgi/closeLot/${idLot}`,null);
	}
}