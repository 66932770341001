import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PlanEntretienService } from './plan-entretien.service';

@Component({
	selector: 'plan-entretien-detail-edit',
	templateUrl: './plan-entretien-detail-edit.component.html'
})
export class PlanEntretienDetailEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() planEntretienDetail: any;

	/** Suppression d'un détail **/
	@Input() deletePlanEntretienDetail: (planEntretienDetail: any,close: Function) => void;

	/**
	 * Constructeur
	 */
	constructor(private planEntretienService: PlanEntretienService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public savePlanEntretienDetail() {
		//Enregistrement du détail de plan d'entretien
		this.planEntretienService.savePlanEntretienDetail(this.planEntretienDetail.planEntretien.idPlanEntretien,this.planEntretienDetail).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.planEntretienDetail,result.data?.planEntretienDetail);

					//Fermeture de la fenêtre
					this.close({ planEntretienDetail: this.planEntretienDetail });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.typeEntretien'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}