<div class="modal-header" *ngIf="options?.withTyping || options?.withTitle">
	<h4 class="modal-title" [innerHtml]="message"></h4>
</div>
<div class="modal-body bootbox-body p-t-20" [innerHtml]="options?.withTitle ? safeAlerteInfo : safeMessage" *ngIf="!options?.withTyping"></div>
<div class="modal-body bootbox-body" *ngIf="options?.withTyping">
	<div class="alert alert-info" [innerHtml]="safeAlerteInfo"></div>
	<form #confirmationForm="ngForm" autocomplete="off">
		<div class="row">
			<div class="form-group" [ngClass]="{ 'has-error': confirmationForm.invalid || confirmation != options?.confirmationTerm }">
				<label class="col-md-12" translate>confirmation.word</label>
				<div class="col-md-12">
					<input type="text" name="confirmation" class="form-control" [(ngModel)]="confirmation" onPaste="event.preventDefault();" required/>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button mat-button *ngIf="!options?.isCancelDisabled" (click)="close()">
		<span [translate]="options?.buttonType == 'YES_NO' ? 'common.non' : 'actions.annuler'"></span>
	</button>
	<button mat-raised-button [color]="options?.actionColor || 'accent'" *ngIf="!options?.listeActions?.length" (click)="confirm()" [disabled]="options?.withTyping && confirmation != options?.confirmationTerm">
		<span [translate]="!options?.isCancelDisabled ? (options?.buttonType == 'YES_NO' ? 'common.oui' : 'actions.confirmer') : 'common.ok'"></span>
	</button>
	<ng-container *ngFor="let action of options?.listeActions">
		<ng-container *ngIf="action.buttonType == 'RAISED' else elseTemplate">
			<button mat-raised-button color="primary" (click)="confirm(action.type)">
				<span [translate]="action.libelle || 'actions.confirmer'"></span>
			</button>
		</ng-container>
		<ng-template #elseTemplate>
			<button mat-stroked-button color="primary" (click)="confirm(action.type)">
				<span [translate]="action.libelle || 'actions.confirmer'"></span>
			</button>
		</ng-template>
	</ng-container>
</div>