<form #accountPasswordForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="p-10">
		<div class="alert alert-info">
			<icon name="info"></icon>
			<div>
				<strong translate>account.password.updatePassword</strong>
				<div translate>login.message.passwordDescription</div>
				<ul class="password-rules">
					<li [ngClass]="{ 'valid': isRuleValid('caracteres') }"><span translate>login.rule.caracteres</span></li>
					<li [ngClass]="{ 'valid': isRuleValid('majuscules') }"><span translate>login.rule.majuscules</span></li>
					<li [ngClass]="{ 'valid': isRuleValid('minuscules') }"><span translate>login.rule.minuscules</span></li>
					<li [ngClass]="{ 'valid': isRuleValid('chiffres') }"><span translate>login.rule.chiffres</span></li>
					<li [ngClass]="{ 'valid': isRuleValid('speciaux') }"><span translate>login.rule.speciaux</span></li>
				</ul>
			</div>
		</div>

		<div class="form-group" [ngClass]="{ 'has-error': oldPassword.invalid }">
			<label translate>account.password.oldPassword</label>
			<div class="half">
				<input type="password" autocomplete="current-password" name="oldPassword" #oldPassword="ngModel" class="form-control" [(ngModel)]="changePassword.oldPassword" [placeholder]="'account.password.oldPassword' | translate" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': password.invalid }">
			<label translate>account.password.newPassword</label>
			<div class="half">
				<div class="input-group">
					<input type="password" autocomplete="new-password" name="password" #password="ngModel" class="form-control" [(ngModel)]="changePassword.password" [validator]="isPasswordComplexe()" [placeholder]="'account.password.newPassword' | translate" required/>
					<div class="input-group-addon">
						<icon class="password-icon" [ngClass]="{ success: password.valid }" [name]="password.valid ? 'check' : 'close'"></icon>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': password2.invalid }">
			<label translate>account.password.passwordConfirmation</label>
			<div class="half">
				<div class="input-group">
					<input type="password" autocomplete="new-password" name="password2" #password2="ngModel" class="form-control" [(ngModel)]="changePassword.password2" [validator]="changePassword.password === changePassword.password2" [placeholder]="'account.password.passwordConfirmation' | translate" required/>
					<div class="input-group-addon">
						<icon class="password-icon" [ngClass]="{ success: password2.valid }" [name]="password2.valid ? 'check' : 'close'"></icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>