import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ContratFinancementService } from './contrat/contrat-financement.service';

@Component({
	templateUrl: './vehicule-achat.component.html'
})
export class VehiculeAchatComponent implements OnInit {
	/** Contrat de financement **/
	contratFinancement: any;

	/** Contrat de financement enregistré **/
	savedContratFinancement: any = null;

	/**
	 * Constructeur
	 */
	constructor(private contratFinancementService: ContratFinancementService,public bsModalRef: BsModalRef<VehiculeAchatComponent>,private toastrService: ToastrService,private translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification du contrat de financement
		if (!this.contratFinancement.objectVersion) {
			//Chargement du contrat de financement
			this.contratFinancementService.loadContratFinancement(this.contratFinancement.idContrat).pipe(first()).subscribe({
				next: result => {
					//Mise à jour du contrat de financement
					this.contratFinancement = result.data.contrat;
				}
			})
		}
	}

	/**
	 * Enregistrement du contrat de financement
	 */
	saveContratFinancement() {
		//Définition de l'achat du véhicule
		this.contratFinancement.vehiculeAchete = true;

		//Enregistrement du contrat de financement
		this.contratFinancementService.saveContratFinancement(this.contratFinancement).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Mise à jour du contrat de financement enregistré
					this.savedContratFinancement = result.data.contrat;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}
}