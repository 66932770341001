import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-motorisation',
	templateUrl: './modele-motorisation.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleMotorisationComponent {
	/** Informations **/
	@Input() information: any;
}
