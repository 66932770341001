<div class="definition-item hoverable">
	<div class="p-l-5">
		<a [routerLink]="[]" [ngClass]="{ 'c-red bold': !item.ligne?.valid }" (click)="showItem();">{{ item.ligne?.libelle }}</a>
		<icon name="warning" *ngIf="!item.ligne?.valid" class="icon-hc-lg c-warning m-l-5" [tooltip]="'structureExport.definition.ligneAnomalie' | translate"></icon>
	</div>
	<div class="actions hovered-item" *ngIf="(null | right:'creation') && !isRestricted">
		<a [routerLink]="[]" (click)="moveItem(1)" *ngIf="detail.typeDetail == 'COMPTABILITE_GENERALE' && detail.listeItems.length > 1 && idxItem < detail.listeItems.length - 1"><icon name="arrow_circle_down"></icon></a>
		<a [routerLink]="[]" (click)="moveItem(-1)" *ngIf="detail.typeDetail == 'COMPTABILITE_GENERALE' && detail.listeItems.length > 1 && idxItem > 0"><icon name="arrow_circle_up"></icon></a>
		<a [routerLink]="[]" (click)="duplicateItem()"><icon name="content_copy"></icon></a>
		<a [routerLink]="[]" (click)="removeItem()"><icon name="delete"></icon></a>
	</div>
</div>