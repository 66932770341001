<form #regleAutomatiqueCheckForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>regleAutomatique.check.title</h4>
	</div>
	<div class="modal-body progress-modal">
		<stepper #stepper="stepper" [listeSteps]="listeSteps" [selectedType]="selectedSection?.type" [isWithIndicators]="true"></stepper>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="stepper.getSelectedStep()?.listeActions?.length">
			<ng-container *ngFor="let action of stepper.getSelectedStep().listeActions">
				<button *ngIf="!action.isPrimary" mat-stroked-button color="primary" (click)="action.onPress()" [disabled]="!action.isValid()">
					<span>{{ action.libelle }}</span>
				</button>
				<button *ngIf="action.isPrimary" mat-raised-button color="primary" (click)="action.onPress()" [disabled]="!action.isValid()">
					<span>{{ action.libelle }}</span>
				</button>
			</ng-container>
		</ng-container>
	</div>
</form>