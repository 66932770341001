import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { LoggedUserService } from 'src/app/share/login/logged-user.service';

/**
 * Gardien pour l'accès aux composants nécessitant l'acceptation des CGU
 */
@Injectable()
export class CguGuardProvider implements CanDeactivate<any> {
	/**
	 * Constructeur
	 */
	constructor(private loggedUserService: LoggedUserService,private toastrService: ToastrService,private translateService: TranslateService) {

	}

	/**
	 * Désactivation du composant
	 */
	canDeactivate() {
		let canDeactivate: boolean = true;

		//Vérification de l'acceptation des CGU
		if (this.loggedUserService.getUser()?.consentementNeeded) {
			//Affichage d'un message d'information
			this.toastrService.warning(this.translateService.instant('article.lecture.absenceConsentementCGU'));

			//Impossibilité de fermer le composant
			canDeactivate = false;
		}

		return canDeactivate;
	}
}