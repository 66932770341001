import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { TypeRestriction } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'tenant-autorisation',
	templateUrl: './autorisation.component.html'
})
export class AutorisationComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() autorisation: any;

	/** Utilisateur restreint **/
	public isProfilRestricted: boolean;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,private rightService: RightService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de l'objet
		this.autorisation = cloneDeep(this.autorisation);

		//Vérification du profil restreint
		this.isProfilRestricted = this.rightService.isRestricted(TypeRestriction.CLIENT);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de l'objet après enregistrement
	 */
	onContentClose(autorisation: any) {
		//Mise à jour de l'objet
		this.onObjectChange.emit(autorisation ? autorisation : null);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}