import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,get } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ComptePosteChargeTransfertComponent } from './compte-poste-charge-transfert.component';
import { CompteService } from './compte.service';

@Component({
	templateUrl: './compte-poste-charge-list-item.component.html'
})
export class ComptePosteChargeListItemComponent extends ListViewItem<any> implements OnInit {
	/** Paramètres du poste de charge **/
	public posteChargeSettings: { state: string,fullIdentifier: string,identifier: string,typeDroit: TypeDroit };

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,public rightService: RightService,private bsModalService: BsModalService) {
		//Héritage
		super();

		//Binding
		this.deleteLienPosteCharge = this.deleteLienPosteCharge.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition des paramètres
		this.posteChargeSettings = this.compteService.getMappingForPostesCharge()[this.data?.type];
	}

	/**
	 * Suppression de l'objet
	 */
	deleteLienPosteCharge(lienPosteCharge: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.compteService.deleteLienCompteDetail(lienPosteCharge).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);

								//Fermeture du formulaire d'édition
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Redirection vers le poste de charge
	 */
	goToObject() {
		//Navigation vers le poste de charge
		this.layoutService.goToByState(this.posteChargeSettings.state,{
			routeParams: {
				[this.posteChargeSettings.identifier]: get(this.data,this.posteChargeSettings.fullIdentifier)
			},
			withGoBack: true
		});
	}

	/**
	 * Ouverture de la popup de transfert des postes de charge
	 */
	showTransfertPosteCharge() {
		let bsModalRef: BsModalRef<ComptePosteChargeTransfertComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ComptePosteChargeTransfertComponent,{
			initialState: {
				lienPosteCharge: cloneDeep(this.data)
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.lienPosteCharge),
			filter(lienPosteCharge => !!lienPosteCharge)
		).subscribe({
			next: (lienPosteCharge: any) => {
				//Définition du résultat
				this.data = lienPosteCharge;

				//Suppression de la liste
				this.onObjectChange(null);
			}
		});
	}
}