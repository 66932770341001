<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ getAvatar(data) }}</div>
<div class="lv-input">
	<div class="lv-title flex-3">
		<a [routerLink]="[]">{{ getTitleForFacture(data) }}</a>
	</div>
	<button class="flex-0" mat-icon-button color="primary" *ngIf="!extraOptions?.isAnomalie" (click)="removeFacture(data.idFacture);">
		<mat-icon>remove_circle_outline</mat-icon>
	</button>
	<button class="flex-0" mat-icon-button color="primary" *ngIf="extraOptions?.isAnomalie" (click)="showLotComptableChecks(data.idFacture);">
		<mat-icon>edit</mat-icon>
	</button>
</div>
<ul class="lv-attrs">
	<li><span translate>comptabilite.lotComptable.check.recapitulatif.date</span>&#160;:&#160;<span>{{ data.date | date:'shortDate' }}</span></li>
	<li><span translate>comptabilite.lotComptable.check.recapitulatif.montant</span>&#160;:&#160;<span>{{ data.montant | currency:data.devise }}</span></li>
	<li><span translate>comptabilite.lotComptable.check.recapitulatif.societe</span>&#160;:&#160;<span>{{ data.societe.libelle }}</span></li>
</ul>