<div class="card focus liste-actions" *ngIf="getListeVisibleElements().length > 0">
	<div class="card-body">
		<ul>
			<li *ngFor="let element of getListeVisibleElements()" (click)="!isTabHidden(element) && selectElement(element)">
				<span>
					<ng-container *ngIf="element.count"><span class="bold">{{ element.count() }}</span>&#160;</ng-container><span>{{ getLibelleForElement(element) }}</span>
				</span>

				<ul class="actions" *ngIf="element.doAction && (!element.isActionVisible || element.isActionVisible?.())">
					<li>
						<a [routerLink]="[]" (click)="scrollToTop(); element.doAction(); $event.stopPropagation();"><icon [name]="options?.customStyle?.btnAction?.icone || 'add'" [type]="options?.customStyle?.btnAction?.iconeType"></icon></a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</div>