<form #affectationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<affectation-content #affectationContent [affectation]="affectation" source="VEHICULE" [vehicule]="vehicule" [user]="user" (close)="close($event)"></affectation-content>
	<span class="pull-right">
		<button mat-button color="primary" *ngIf="affectation.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')) && user.type != 'VIRTUAL'" (click)="affectationContent.goToPlanning();">
			<span translate>vehicule.affectation.actions.accederPlanning</span>
		</button>
		<button mat-stroked-button color="primary" *ngIf="(TypeDroit.VEHICULE_RESERVATION | right:'creation') && affectation.idAffectation > 0 && user.type != 'VIRTUAL' && affectation.reservation && affectation.reservation.statut == 'VALIDEE'" (click)="affectationContent.doActionForReservation('ANNULER');">
			<span translate>actions.annuler</span>
		</button>
		<button mat-stroked-button color="primary" (click)="deleteAffectation(affectation,close);" *ngIf="deleteAffectation && affectation?.idAffectation > 0 && ((TypeDroit.ADMIN_VEHICULE | right:'suppression') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'suppression')) && !affectation.reservation">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="affectationContent.saveAffectation();" *ngIf="(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')" [disabled]="affectationForm.invalid">
			<span  translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: affectationForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>