import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeReleveCompteurListItemComponent } from './vehicule-releve-compteur-list-item.component';

@Component({
	selector: 'vehicule-releve-compteur-list',
	templateUrl: './vehicule-releve-compteur-list.component.html'
})
export class VehiculeReleveCompteurListComponent extends PageContentItem implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Fonction d'édition **/
	@Input() addReleveCompteur: () => void;

	/** Résumé **/
	@Input() resume: { nbRelevesCompteur: number };

	/** Données **/
	liste: ListView<any,VehiculeReleveCompteurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeReleveCompteurListItemComponent>({
			uri: () => `/controller/VehiculeCompteur/filtreCompteurs`,
			component: VehiculeReleveCompteurListItemComponent,
			isLocal: true,
			extraOptions: {
				addReleveCompteur: this.addReleveCompteur
			},
			listeFilters: [{
				clef: '*userSource',
				autocomplete: {
					type: 'user'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			},{
				clef: '*fournisseur',
				autocomplete: {
					type: 'fournisseur'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			},{
				clef: '*equipement.reference',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateReleve,vehicule.reference'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idCompteur > 0)
			//Mise à jour des compteurs
			this.resume.nbRelevesCompteur--;
	}
}