<form #releveEnergieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>equipement.elements.releveEnergie.title</h2>
		</div>
		<div class="card-body card-padding">
			<releve-energie-content #releveEnergieContent [releveEnergie]="releveEnergie" source="EQUIPEMENT" (close)="close({ releveEnergie: $event })"></releve-energie-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteReleveEnergie(releveEnergie,close);" *ngIf="deleteReleveEnergie && releveEnergie?.idReleve && (TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="releveEnergieContent.saveReleveEnergie();" *ngIf="TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'creation'" [disabled]="releveEnergieForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: releveEnergieForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>