import { Component,Input } from '@angular/core';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';

@Component({
	templateUrl: './notification-resume.component.html'
})
export class NotificationResumeComponent {
	/** Template sélectionné **/
	@Input() selectedMail: any;

	/** Liste des destinataires **/
	@Input() listeDestinataires: Array<any>;

	/** Action de masse **/
	@Input() actionMasse: ActionMasse;

	/** Options de messaging **/
	@Input() messagingOptions: MessagingOptions;

	/** Indicateur d'ajout d'une remarque **/
	isRemarqueAdded: boolean = false;

	/** Remarque **/
	remarque: any;

	/**
	 * Vérification de la sélection d'au moins un destinataire
	 */
	isDestinataireSelected(listeDestinataires: Array<any>,type: string): boolean {
		//Vérification de la sélection d'au moins un destinataire
		return listeDestinataires.some(dest => dest.selected && dest.type == type);
	}

	/**
	 * Récupération de la remarque
	 */
	getRemarque() {
		//Retour de la remarque
		return this.remarque;
	}
}