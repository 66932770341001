import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TenantCompteServiceComponent } from './tenant-compte-service.component';
import { TenantContratEditComponent } from './tenant-contrat-edit.component';
import { TenantContratListItemComponent } from './tenant-contrat-list-item.component';
import { TenantContratListComponent } from './tenant-contrat-list.component';
import { TenantContratSelectionComponent } from './tenant-contrat-selection.component';
import { TenantDroitComponent } from './tenant-droit.component';
import { TenantEditComponent } from './tenant-edit.component';
import { TenantLicenceListItemComponent } from './tenant-licence-list-item.component';
import { TenantLicenceListComponent } from './tenant-licence-list.component';
import { TenantListItemComponent } from './tenant-list-item.component';
import { TenantListComponent } from './tenant-list.component';
import { TenantComponent } from './tenant.component';
import { TenantService } from './tenant.service';
import { TenantAutorisationListComponent } from './tenant-autorisation-list.component';
import { TenantAutorisationListItemComponent } from './tenant-autorisation-list-item.component';
import { TenantAutorisationEditComponent } from './tenant-autorisation-edit.component';
import { TenantAuthConfigListComponent } from './tenant-auth-config-list.component';
import { TenantAuthConfigListItemComponent } from './tenant-auth-config-list-item.component';
import { TenantAuthConfigEditComponent } from './tenant-auth-config-edit.component';
import { AuthentificationModule } from 'src/app/components/authentification/authentification.module';
import { AutorisationModule } from './autorisation/autorisation.module';

@NgModule({
	imports: [ShareModule,AuthentificationModule,AutorisationModule],
	declarations: [TenantListComponent,TenantListItemComponent,TenantComponent,TenantEditComponent,TenantContratListComponent,TenantContratListItemComponent,TenantContratSelectionComponent,TenantContratEditComponent,TenantDroitComponent,TenantLicenceListComponent,TenantLicenceListItemComponent,TenantCompteServiceComponent,TenantAutorisationListComponent,TenantAutorisationListItemComponent,TenantAutorisationEditComponent,TenantAuthConfigListComponent,TenantAuthConfigListItemComponent,TenantAuthConfigEditComponent],
	providers: [TenantService]
})
export class TenantModule { }
