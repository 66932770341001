<div class="tile-toggle" *ngIf="data.idService">
	<div class="tile-inner">
		<div class="pull-left lv-avatar bgm-gray" (click)="(data?.extension?.nbSousServices > 0 || data?.listeSousServices?.length > 0) && data.isDisplayed = !data.isDisplayed; data.isDisplayed && !data?.listeSousServices && loadListeSousServices()">
			<icon [name]="data?.extension?.nbSousServices > 0 || data?.listeSousServices?.length > 0 ? 'chevron_right' : null" class="icon-hc-2x" [ngClass]="{ 'icon-hc-rotate-90': data.isDisplayed }"></icon>
		</div>
		<div class="lv-title">
			<a [routerLink]="['/Analytique/loadService/',data.idService]">{{ data?.libelle + (data?.reference ? ' (' + data?.reference +')' : '') }}</a>
		</div>
		<ul class="lv-attrs">
			<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span><span *ngIf="data.dateFin">&#160;({{ data.dateFin | date:'shortDate' }})</span></li>
			<li *ngIf="data.code"><span translate>service.code</span>&#160;:&#160;<span>{{ data.code }}</span></li>
		</ul>
	</div>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="addService();"><span translate>service.actions.addService</span></a></li>
			<li *ngIf="(null | right:'creation') &&  data.type == 'SERVICE'"><a [routerLink]="[]" (click)="showMoveService()"><span translate>actions.move</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteService();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<display-more class="offset-first-service" *ngIf="data.idService && data.isDisplayed" [listeItems]="data?.listeSousServices" [nbItems]="nbObjetsParPage" [nbTotalItems]="nbTotalItems" (onDisplayMore)="loadListeSousServices($event - 1)" #displayMore>
	<div class="tile-collapse" *ngFor="let service of displayMore.getSlice()">
		<service-list-item class="item_service" (onRemove)="removeChildService($event)" [ngClass]="{ 'first-service': data?.listeSousServices?.[0]?.idService == service.idService }" [liste]="liste" [data]="service" isChild="true"></service-list-item>
	</div>
</display-more>