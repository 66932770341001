import { DatePipe } from '@angular/common';
import { Pipe,PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';

@Pipe({
	name: 'display',
	pure: true
})
export class DisplayPipe implements PipeTransform {
	/**
	 * Constructeur
	 */
	constructor(private autocompleteService: AutocompleteService,private translateService: TranslateService,private datePipe: DatePipe) { }

	/**
	 * Transformation
	 */
	transform(object: any,type: string): string {
		let options: AutocompleteOptions;

		//Recherche de l'autocomplete associé
		options = this.autocompleteService.getOptionsForType(type);

		//Affichage de l'objet
		return options?.displayItem(object,this.translateService,this.datePipe);
	}
}