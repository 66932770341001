<form #lotCompableChecksForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>comptabilite.lotComptable.check.title</h4>
	</div>
	<div class="modal-body p-0 m-0">
		<stepper #stepper="stepper" vertical="true" [listeSteps]="listeSteps" [selectedType]="selectedSection?.type" [isWithIndicators]="true"></stepper>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
		<ng-container *ngIf="stepper.getSelectedStep()?.listeActions">
			<ng-container *ngFor="let action of stepper.getSelectedStep().listeActions">
				<button *ngIf="!action.isPrimary" mat-stroked-button color="primary" (click)="action.onPress()" [disabled]="!action.isValid()">
					<span>{{ action.libelle }}</span>
				</button>
				<button *ngIf="action.isPrimary" mat-raised-button color="primary" (click)="action.onPress()" [disabled]="!action.isValid()">
					<span>{{ action.libelle }}</span>
				</button>
			</ng-container>
		</ng-container>
	</div>
</form>