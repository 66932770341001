<form #structureExportRubriqueForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>structureExport.rubrique.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation') || isRestricted">
			<div class="d-flex justify-content-space-between align-items-center">
				<h4 translate>structureExport.rubrique.generalites</h4>
				<button mat-icon-button color="primary" (click)="content.isGeneralitesToggled = !content.isGeneralitesToggled;">
					<mat-icon>{{ content.isGeneralitesToggled ? 'chevron_right' : 'expand_more' }}</mat-icon>
				</button>
			</div>
			<div class="nc-bloc-content-bg d-relative p-10" [collapse]="content.isGeneralitesToggled">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }" *ngIf="!isActionMasse">
							<label translate>structureExport.rubrique.libelle</label>
							<div>
								<input type="text" name="libelle" class="form-control" #libelle="ngModel" [(ngModel)]="rubrique.libelle" required/>
							</div>
						</div>
						<div class="form-group" *ngIf="!isActionMasse">
							<label translate>structureExport.rubrique.rang</label>
							<div>
								<span>{{ rubrique.position + 1 }}</span>
							</div>
						</div>
						<div class="form-group" *ngIf="isActionMasse">
							<label translate>structureExport.rubrique.nbSelections.item</label>
							<div>
								<span translate [translateParams]="{ nbSelected }">structureExport.rubrique.nbSelections.message</span>
							</div>
						</div>
						<ng-container *ngIf="fichier.typeFormat == 'FIXE'">
							<div class="form-group" [ngClass]="{ 'has-error': longueur.invalid }">
								<label translate>structureExport.rubrique.longueur</label>
								<div class="half">
									<input type="text" name="longueur" class="form-control" #longueur="ngModel" [(ngModel)]="rubrique.longueur" nNumber min="0" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeCadrage.invalid }">
								<label translate>structureExport.rubrique.typeCadrage</label>
								<div class="half">
									<selectpicker name="typeCadrage" #typeCadrage="ngModel" [(ngModel)]="rubrique.typeCadrage" required>
										<mat-option *ngFor="let cadrage of listeTypesCadrage" [value]="cadrage.code">{{ cadrage.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeCompletion.invalid }" *ngIf="rubrique.typeCadrage != 'AUCUN'">
								<label translate>structureExport.rubrique.typeCompletion</label>
								<div class="half">
									<selectpicker name="typeCompletion" #typeCompletion="ngModel" [(ngModel)]="rubrique.typeCompletion" required>
										<mat-option *ngFor="let completion of listeTypesCompletion" [value]="completion.code">{{ completion.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>structureExport.rubrique.vide.item</label>
							<div>
								<mat-checkbox name="empty" #empty="ngModel" [(ngModel)]="rubrique.empty" (change)="onRubriqueEmptyChange()"><span translate>structureExport.rubrique.vide.message</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>structureExport.rubrique.formatageSpecifique.item</label>
							<div>
								<mat-checkbox name="formatageSpecifique" #formatageSpecifique="ngModel" [(ngModel)]="rubrique.formatageSpecifique" (change)="onFormatageSpecifiqueChange()"><span translate>structureExport.rubrique.formatageSpecifique.message</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ng-container *ngIf="rubrique.formatageSpecifique">
				<div class="d-flex justify-content-space-between align-items-center">
					<h4 translate>structureExport.rubrique.formatageSpecifique.title</h4>
					<button mat-icon-button color="primary" (click)="content.isFormatageToggled = !content.isFormatageToggled;">
						<mat-icon>{{ content.isFormatageToggled ? 'chevron_right' : 'expand_more' }}</mat-icon>
					</button>
				</div>
				<div class="nc-bloc-content-bg d-relative p-10" [collapse]="content.isFormatageToggled">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': nbDecimales.invalid }">
								<label translate>structureExport.rubrique.nbDecimales</label>
								<div class="half">
									<input type="text" name="nbDecimales" #nbDecimales="ngModel" class="form-control" [(ngModel)]="rubrique.formatNumerique.nbDecimales" nNumber min="0" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': separateurDecimale.invalid }">
								<label translate>structureExport.rubrique.separateurDecimale</label>
								<div class="half">
									<selectpicker name="separateurDecimale" #separateurDecimale="ngModel" [(ngModel)]="rubrique.formatNumerique.separateurDecimale">
										<mat-option *ngFor="let separateur of listeSeparateursDecimale" [value]="separateur.code">{{ separateur.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<ng-container *ngIf="fichier.typeFormat == 'CSV'">
								<div class="form-group" [ngClass]="{ 'has-error': typeCadrage.invalid }">
									<label translate>structureExport.rubrique.typeCadrage</label>
									<div class="half">
										<selectpicker name="typeCadrage" #typeCadrage="ngModel" [(ngModel)]="rubrique.formatNumerique.typeCadrage" (ngModelChange)="onTypeCadrageChange()" required>
											<mat-option *ngFor="let cadrage of listeTypesCadrage" [value]="cadrage.code">{{ cadrage.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': typeCompletion.invalid }" *ngIf="rubrique.formatNumerique.typeCadrage != 'AUCUN'">
									<label translate>structureExport.rubrique.typeCompletion</label>
									<div class="half">
										<selectpicker name="typeCompletion" #typeCompletion="ngModel" [(ngModel)]="rubrique.formatNumerique.typeCompletion" required>
											<mat-option *ngFor="let completion of listeTypesCompletion" [value]="completion.code">{{ completion.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': longueur.invalid }" *ngIf="rubrique.formatNumerique.typeCadrage != 'AUCUN'">
									<div class="half">
										<label translate>structureExport.rubrique.longueur</label>
										<div>
											<input type="text" name="longueur" #longueur="ngModel" class="form-control" [(ngModel)]="rubrique.formatNumerique.longueur" nNumber min="0" required/>
										</div>
									</div>
								</div>
							</ng-container>
							<div class="form-group" [ngClass]="{ 'has-error': formatDate.invalid }">
								<label translate>structureExport.rubrique.formatDate</label>
								<div class="half">
									<selectpicker name="formatDate" #formatDate="ngModel" [(ngModel)]="rubrique.formatDate" required>
										<mat-option *ngFor="let format of listeFormatsDate" [value]="format.code">{{ format.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!rubrique.empty">
				<div class="d-flex justify-content-space-between align-items-center">
					<h4 translate>structureExport.rubrique.definition.title</h4>
					<button mat-icon-button color="primary" (click)="content.isDefinitionToggled = !content.isDefinitionToggled;">
						<mat-icon>{{ content.isDefinitionToggled ? 'chevron_right' : 'expand_more' }}</mat-icon>
					</button>
				</div>
				<div class="nc-bloc-content-bg d-relative">
					<div [collapse]="content.isDefinitionToggled">
						<div class="p-10 text-center" *ngIf="!rubrique.detail && (null | right:'creation') && !isRestricted">
							<a [routerLink]="[]" (click)="selectTypeRubrique(rubrique,'detail');" translate>structureExport.rubrique.actions.ajouterCritere</a>
						</div>
						<div class="p-10 text-center" *ngIf="!rubrique.detail && (!(null | right:'creation') || isRestricted)" translate>structureExport.rubrique.aucuneDefinition</div>
						<div *ngIf="rubrique.detail" class="rubrique-detail">
							<structure-export-rubrique-detail [detail]="rubrique.detail" [parent]="rubrique" [parentKey]="'detail'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="selectTypeRubrique($event.parent,$event.parentKey)"></structure-export-rubrique-detail>
						</div>
					</div>
				</div>
			</ng-container>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide();">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveRubrique()" [disabled]="structureExportRubriqueForm.invalid" *ngIf="(null | right:'creation') && !isRestricted">
			<span [translate]="'actions.'+(isActionMasse ? 'continuer' : 'enregistrer')"></span>
		</button>
	</div>
</form>