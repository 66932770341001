<div class="form-group">
	<label translate>vehicule.modele.configuration.typePrix.item</label>
	<mat-chip-listbox multiple>
		<mat-chip-option class="m-r-5" *ngFor="let type of listeTypesPrix" [value]="type" [selected]="type.isSelected" (click)="toggleTypePrixFilter(type);" (keydown.space)="toggleTypePrixFilter(type);">{{ type.libelle }}</mat-chip-option>
	</mat-chip-listbox>
</div>
<div class="form-group">
	<label translate>vehicule.modele.configuration.typeFamille.item</label>
	<mat-chip-listbox multiple>
		<mat-chip-option class="m-r-5" *ngFor="let type of listeTypesOptions" [value]="type" [selected]="type.isSelected" [disabled]="!type.hasDetails" (click)="toggleTypePrixFilter(type);" (keydown.space)="toggleTypePrixFilter(type);">{{ type.libelle }}</mat-chip-option>
	</mat-chip-listbox>
</div>
<div class="listview lv-bordered">
	<div class="lv-body m-t-10" *ngIf="configuration?.listeDetails?.length">
		<display-more [listeItems]="configuration.listeDetails | filterBy:filterByTypeCategoryAndPrix" #displayMore>
			<configuration-option-list-item *ngFor="let detail of displayMore.getSlice()" [detail]="detail" [isShowCheckbox]="isShowCheckbox" [configuration]="configuration"></configuration-option-list-item>
		</display-more>
	</div>
</div>