import { Component,OnDestroy,OnInit } from '@angular/core';
import { Subject,Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

import { ListView } from 'src/app/domain/common/list-view';
import { ValidationListItemComponent } from './validation-list-item.component';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { AppState } from 'src/app/domain/appstate';
import { WorkflowService } from '../workflow.service';
import { User } from 'src/app/domain/user/user';
import { TypeListeValidation,ValidationEntiteOptions } from 'src/app/domain/workflow/validation';

@Component({
	templateUrl: './validation-list.component.html'
})
export class ValidationListComponent implements OnInit,OnDestroy {
	/** Données **/
	liste: ListView<any,ValidationListItemComponent>;

	/** Type de liste **/
	typeListe: TypeListeValidation;

	/** Type d'entité **/
	typeEntite: any;

	/** Liste des entités validables par l'utilisateur **/
	private listeEntites: Array<ValidationEntiteOptions>;

	/** Entité courante de la liste **/
	private entite: ValidationEntiteOptions;

	/** Souscription sur la fin d'une action **/
	private actionFinishedSubscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor(public layoutService: LayoutService,private activatedRoute: ActivatedRoute,private workflowService: WorkflowService,private store: Store<AppState>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let onActionFinished: Subject<any>;

		//Récupération du type de liste
		this.typeListe = this.activatedRoute.snapshot.params?.typeListe || TypeListeValidation.A_VALIDER;

		//Récupération du type d'entité
		this.typeEntite = this.activatedRoute.snapshot.params?.typeEntite;

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Récupération des entités
			this.listeEntites = this.workflowService.getListeEntites(user);

			//Vérification de la liste des entités
			if (this.listeEntites) {
				//Vérification du nombre d'entités
				if (this.listeEntites.length && this.listeEntites.length > 1) {
					//TODO Gérer un sélecteur pour le multi entité
				} else {
					//Récupération de l'entité courante
					this.entite = this.listeEntites[0];

					//Création d'un sujet pour la fin d'une action
					onActionFinished = new Subject<any>();

					//Souscription sur le sujet de fin d'action
					this.actionFinishedSubscription = onActionFinished.subscribe({
						next: () => {
							//Rafraichissement de la liste
							this.liste?.refresh();
						}
					})

					//Définition de la liste des objets
					this.liste = new ListView<any,ValidationListItemComponent>({
						uri: () => this.entite.getUri(this.typeListe),
						title: this.entite.getTitle(this.typeListe),
						component: ValidationListItemComponent,
						defaultOrder: this.entite.defaultOrder,
						listeFilters: this.entite.getListeFilters(),
						extraOptions: {
							entite: this.entite,
							typeListe: this.typeListe,
							onActionFinished
						},
						listeActions: this.entite.getListeActions(this.typeListe,onActionFinished),
						getKeyFieldName: this.entite.getKeyFieldName
					});
				}
			}
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.actionFinishedSubscription?.unsubscribe?.();
	}
}