/**
 * Enumération des types d'ordonnancement
 */
export enum TypeFrequence {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	QUOTIDIEN = 'QUOTIDIEN',
	HEBDOMADAIRE = 'HEBDOMADAIRE',
	MENSUEL = 'MENSUEL',
	TRIMESTRIEL = 'TRIMESTRIEL',
	ANNUEL = 'ANNUEL',
	MULTI_ANNUEL = 'MULTI_ANNUEL'
}

/**
 * Enumération des types de jours
 */
export enum TypeJour {
	/** Enumération **/
	LUNDI = 'LUNDI',
	MARDI = 'MARDI',
	MERCREDI = 'MERCREDI',
	JEUDI = 'JEUDI',
	VENDREDI = 'VENDREDI',
	SAMEDI = 'SAMEDI',
	DIMANCHE = 'DIMANCHE'
}

/**
 * Enumération des types de mois
 */
export enum TypeMois {
	/** Enumération **/
	JANUARY = 'JANUARY',
	FEBRUARY = 'FEBRUARY',
	MARCH = 'MARCH',
	APRIL = 'APRIL',
	MAY = 'MAY',
	JUNE = 'JUNE',
	JULY = 'JULY',
	AUGUST = 'AUGUST',
	SEPTEMBER = 'SEPTEMBER',
	OCTOBER = 'OCTOBER',
	NOVEMBER = 'NOVEMBER',
	DECEMBER = 'DECEMBER'
}