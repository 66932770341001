<form #remiseForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.grilleAttribution.modele.elements.remise.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': pourcentageRemise.invalid }">
							<label translate>vehicule.grilleAttribution.modele.remise.pourcentageRemise</label>
							<div class="input-group half">
								<input type="text" name="pourcentageRemise" #pourcentageRemise="ngModel" class="form-control" nNumber="2" min="0" max="100" [(ngModel)]="remise.pourcentageRemise" required/>
								<div class="input-group-addon no-border">%</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
							<label translate>vehicule.grilleAttribution.modele.remise.dateApplication.item</label>
							<div class="input-group half">
								<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="remise.dateApplication" datePicker format="date" maxDate="today" required/>
								<div class="input-group-addon">
									<icon name="info_outline" [tooltip]="'vehicule.grilleAttribution.modele.remise.dateApplication.info' | translate"></icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteRemise(remise,close);" *ngIf="deleteRemise && remise?.idRemise && (TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveRemise();" *ngIf="TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'creation'" [disabled]="remiseForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: remiseForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>