import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class PlanEntretienService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression du plan d'entretien
	 */
	public deletePlanEntretien(planEntretien: any): Observable<Result> {
		//Suppression du plan d'entretien
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deletePlanEntretien/${planEntretien.idPlanEntretien}`);
	}

	/**
	 * Enregistrement du plan d'entretien
	 */
	public savePlanEntretien(planEntretien: any,idPlanEntretienOrigine?: number): Observable<Result> {
		//Enregistrement du plan d'entretien
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/savePlanEntretien${idPlanEntretienOrigine ? '/' + idPlanEntretienOrigine : ''}`,planEntretien);
	}

	/**
	 * Chargement d'un plan d'entretien
	 */
	public loadPlanEntretien(idPlanEntretien: number): Observable<Result> {
		//Chargement d'un plan d'entretien
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadPlanEntretien/${idPlanEntretien}`,null);
	}

	/**
	 * Enregistrement d'un détail de plan d'entretien
	 */
	public savePlanEntretienDetail(idPlanEntretien: number,planEntretienDetail: any): Observable<Result> {
		//Enregistrement du détail de plan d'entretien
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/savePlanEntretienDetail/${idPlanEntretien}`,planEntretienDetail);
	}

	/**
	 * Suppression d'un détail de plan d'entretien
	 */
	public deletePlanEntretienDetail(idPlanEntretien: number,planEntretienDetail: any): Observable<Result> {
		//Suppression du détail de plan d'entretien
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deletePlanEntretienDetail/${idPlanEntretien}/${planEntretienDetail.idPlanEntretienDetail}`);
	}
}