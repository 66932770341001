import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,take } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FormatInterfaceCodeEditComponent } from './format-interface-code-edit.component';
import { FormatInterfaceCodeListComponent } from './format-interface-code-list.component';
import { FormatInterfaceEditComponent } from './format-interface-edit.component';
import { FormatInterfaceService } from './format-interface.service';

@Component({
	selector: 'format-interface',
	templateUrl: './format-interface.component.html'
})
export class FormatInterfaceComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Format d'interface courant **/
	public formatInterface: any;

	/** Résumé */
	private resume: { nbCodes: number } = {
		nbCodes: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.FOURNISSEUR_INTERFACE;

	/**
	 * Constructeur
	 */
	constructor(private formatInterfaceService: FormatInterfaceService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.fournisseur.FournisseurInterface';

	/**
	 * Récupération de l'identifiant du format d'interface à charger
	 */
	getIdObject: () => number = () => this.formatInterface?.idInterface || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.formatInterface;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'fournisseur.formatInterface.libelle',
				key: 'libelle'
			},{
				libelle: 'fournisseur.formatInterface.reference',
				key: 'reference'
			},{
				libelle: 'fournisseur.formatInterface.fournisseur',
				key: 'fournisseur.libelle'
			},{
				libelle: 'fournisseur.formatInterface.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			},{
				libelle: 'fournisseur.formatInterface.modeExecution.item',
				key: (item: any) => this.translateService.instant(`fournisseur.formatInterface.modeExecution.${item?.typeExecution || 'NON_DEFINI'}`)
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editFormatInterface(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editFormatInterface(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteFormatInterface(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeElements: [{
				type: 'CODE',
				libelle: 'fournisseur.formatInterface.elements.code',
				component: FormatInterfaceCodeListComponent,
				retrieveComponentData: () => ({
					formatInterface: this.formatInterface,
					resume: this.resume,
					addCode: this.addCode.bind(this)
				}),
				doAction: () => this.addCode(),
				count: () => this.resume.nbCodes,
				libelleAction: this.translateService.instant('fournisseur.formatInterface.actions.ajouterCode'),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}]
		};

		//Récupération de l'identifiant du format d'interface à charger
		this.activatedRoute.params.pipe(take(1)).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idInterface);
			}
		});
	}

	/**
	 * Edition du format d'interface
	 */
	private editFormatInterface() {
		//Ouverture du composant d'édition
		this.pageContentService.open(FormatInterfaceEditComponent,{
			data: {
				formatInterface: this.formatInterface.idInterface ? cloneDeep(this.formatInterface) : this.formatInterface,
				deleteFormatInterface: this.deleteFormatInterface.bind(this)
			}
		}).subscribe({
			next: (formatInterface: any) => {
				//Vérification des données
				if (formatInterface) {
					//Mise à jour du format d'interface
					this.formatInterface = Object.assign(this.formatInterface,formatInterface);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.formatInterface);
				}
			}
		});
	}

	/**
	 * Suppression du format d'interface
	 */
	private deleteFormatInterface() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.formatInterfaceService.deleteInterface(this.formatInterface))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idInterface: number) {
		//Enregistrement du format d'interface
		this.formatInterfaceService.loadInterface(idInterface).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du format d'interface
					this.formatInterface = result.data?.formatInterface;

					//Définition du résumé
					this.resume = {
						nbCodes: result.data?.nbCodes || 0
					}

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du format d'interface
					if (!this.formatInterface?.idInterface) {
						//Création d'un objet vierge
						this.formatInterface = {
							actif: false
						};

						//Edition du format d'interface
						this.editFormatInterface();
					}
				}
			}
		});
	}

	/**
	 * Modification du code
	 */
	private addCode(code: any = { interfaceFournisseur: Object.assign({},this.formatInterface) }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(FormatInterfaceCodeEditComponent,{
			data: {
				code: cloneDeep(code)
			}
		},'sub').subscribe({
			next: (data: { code: any }) => {
				//Vérification de la présence de donnée
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('CODE',code.idCode != data.code?.idCode);

					//Vérification de la présence d'une création ou d'une suppression
					if (!code.idCode && data.code?.idCode)
						//Incrémentation du compteur
						this.resume.nbCodes++;
					else if (code.idCode && !data.code?.idCode)
						//Décrémentation du compteur
						this.resume.nbCodes--;

					//Mise à jour du code
					code = Object.assign(code,data.code);
				}
			}
		});
	}
}