import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FactureDetailListItemComponent } from './facture-detail-list-item.component';
import { mapEntites } from './detail/detail.entites';
import { NotificationService } from 'src/app/share/components/notification/notification.service';

@Component({
	selector: 'facture-detail-list',
	templateUrl: './facture-detail-list.component.html'
})
export class FactureDetailListComponent extends PageContentItem implements OnInit {
	/** Facture **/
	@Input() facture: any;

	/** Fonction d'édition **/
	@Input() addDetail: () => void;

	/** Resumé **/
	@Input() resume: { nbDetails: number };

	/** Données **/
	liste: ListView<any,FactureDetailListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private notificationService: NotificationService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FactureDetailListItemComponent>({
			uri: () => `/controller/Facture/filtreDetailsForFacture/${this.facture.idFacture}`,
			component: FactureDetailListItemComponent,
			isLocal: true,
			extraOptions: {
				facture: this.facture,
				addDetail: this.addDetail,
				resume: this.resume
			},
			listeFilters: [{
				clef: 'nature',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurNature'
				},
				isDefault: true
			},{
				clef: 'vehicule',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule'
				},
				isDefault: true
			},{
				clef: 'user',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				},
				isDefault: true
			}],
			defaultOrder: '-isAnomalie,-date,nature.libelle',
			listeStaticFilters: [{
				clef: 'facture.idFacture',
				valeur: this.facture.idFacture,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			getKeyFieldName: () => 'idDetail',
			listeActions: []
		});

		//Ajout des notifications
		this.notificationService.addActionToListe(this.liste,this.getMapEntites());
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idDetail > 0)
			//Mise à jour des compteurs
			this.resume.nbDetails--;
	}
}