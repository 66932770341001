<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">
			<span *ngIf="data.type === 'CREATION'" translate>history.type.avatar.CREATION</span>
			<span *ngIf="data.type === 'MODIFICATION'" translate>history.type.avatar.MODIFICATION</span>
			<span *ngIf="data.type === 'SUPPRESSION'" translate>history.type.avatar.SUPPRESSION</span>
		</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">
				<span>{{ data.date | date:'short' }}</span>
				<span>-</span>
				<span *ngIf="data.originalUser"><span>{{ data.originalUser.prenom }}</span>&#160;<span>{{ data.originalUser.nom }}</span>&#160;<span *ngIf="data.originalUser.tenant.nom">(<span>{{ data.originalUser.tenant.nom }}</span>)&#160;</span><span translate>history.pour</span>&#160;</span>
				<span>{{ data.user.prenom + ' ' + data.user.nom + ' (' + data.user.matricule + ')' }}</span>
			</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li>
			<span translate>history.type.title</span>&#160;:&#160;<span [translate]="'history.type.' + data.type"></span>
		</li>
		<li *ngIf="data.type == 'MODIFICATION'">
			<span>{{ data.listeChanges.length }}</span>&#160;<span translate>history.listeChanges</span>&#160;:&#160;
			<span *ngFor="let change of data.listeChanges; let isFirst = first">{{ (isFirst ? '' : ', ') + change.field }}</span>
		</li>
	</ul>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<history-change [listeChanges]="data.listeChanges"></history-change>
</div>