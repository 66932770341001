import { Component,ComponentRef,Input,OnDestroy,OnInit,Type,ViewChild,ViewContainerRef } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PageContentService } from './page-content.service';

@Component({
	selector: 'page-content',
	templateUrl: './page-content.component.html'

})
export class PageContentComponent implements OnInit,OnDestroy {
	/** Etat d'affichage **/
	@Input() public isDefaultShown: boolean = true;

	/** Instance cible **/
	@Input() public mode: string = 'default';

	/** Récepteur de composant **/
	@ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;

	/**
	 * Constructeur
	 */
	constructor(private pageContentService: PageContentService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation du service
		this.pageContentService.onInit(this);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Réinitialisation du service
		this.pageContentService.onDestroy(this);
	}

	/**
	 * Ouverture/fermeture du composant
	 */
	public toggleView(type?: Type<PageContentItem> | null,options?: { data: any,close: (data?: any) => void }) {
		let component: ComponentRef<PageContentItem>;

		//Défunition du status
		this.isDefaultShown = !this.isDefaultShown;

		//Vérification de l'état de l'instance et de la présence d'un composant
		if (!this.isDefaultShown && type != null) {
			//Création du composant
			component = this.createComponent(this.holder,type);

			//Définition du container parent
			Object.assign(component.instance,options?.data,{
				close: options?.close
			});
		} else
			//Suppression du composant
			this.holder.clear();
	}

	/**
	 * Création du composant
	 */
	private createComponent(viewContainer: ViewContainerRef,type: Type<PageContentItem>): ComponentRef<PageContentItem> {
		//Création du composant
		return viewContainer.createComponent(type);
	}
}