import { Component,Input } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs/operators';

import { StructureExportService } from './structure-export.service';
import { StructureExportCompteurComponent } from './structure-export-compteur.component';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'structure-export-compteur-list',
	templateUrl: './structure-export-compteur-list.component.html'
})
export class StructureExportCompteurListComponent extends PageContentItem {
	/** Détail courant **/
	@Input() detail: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private structureExportService: StructureExportService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du compteur
	 */
	public showCompteur(compteur?: any,idxCompteur?: number) {
		let bsModalRef: BsModalRef<StructureExportCompteurComponent>;

		//Affichage de la popup de gestion du compteur
		bsModalRef = this.bsModalService.show(StructureExportCompteurComponent,{
			initialState: {
				listeTypesCompteur: this.structureExportService.getListeTypesCompteur(),
				compteur: compteur || {
					valeurInitiale: 1,
					type: 'AUTOMATIQUE'
				},
				detail: this.detail,
				isRestricted: this.isRestricted
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.compteur),
			filter(compteur => !!compteur)
		).subscribe({
			next: (compteur: any) => {
				//Vérification de la liste des compteurs
				if (!this.detail.listeCompteurs?.length)
					//Création du compteur
					this.detail.listeCompteurs = [compteur];
				else if (idxCompteur >= 0)
					//Mise à jour du compteur
					Object.assign(this.detail.listeCompteurs[idxCompteur],compteur);
				else
					//Ajout du compteur à la liste
					this.detail.listeCompteurs.push(compteur);
			}
		});
	}

	/**
	 * Suppression d'un compteur
	 */
	public deleteCompteur(index: number) {
		//Suppression de l'élément
		this.detail.listeCompteurs.splice(index,1);
	}
}