import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { OffreListItemComponent } from './offre-list-item.component';

@Component({
	templateUrl: './offre-list.component.html'
})
export class OffreListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,OffreListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,OffreListItemComponent>({
			uri: `/controller/Commerce/filtreOffres`,
			title: this.translateService.instant('offre.liste.title'),
			component: OffreListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'listeRevendeurs',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'revendeur'
				},
				title: this.translateService.instant('search.revendeur')
			}],
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Offre/loadOffre/0'))
		});
	}
}