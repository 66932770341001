import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { mapEntites } from './facture.entites';
import { FactureService } from './facture.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './facture-list-item.component.html'
})
export class FactureListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService,public factureService: FactureService,private confirmService: ConfirmService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idFacture,object?.idFacture && !this.data?.idFacture);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteFacture() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la facture
					this.factureService.deleteFacture(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression depuis la liste
								this.onObjectChange(null);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.facture.Facture',this.data.idFacture);
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	doAction(typeAction: 'VALIDER' | 'REJETER',facture: any) {
		//Réalisation de l'action Workflow
		this.factureService.doAction(typeAction,facture.idFacture).subscribe({
			next: isSuccess => {
				//Vérification du statut
				if (isSuccess) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Rafraichissement de la liste
					this.liste.refresh();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Accès au lot comptable
	 */
	goToLotComptable(lotComptable: any) {
		//Navigation vers le lot comptable
		this.layoutService.goToByState('listeLotsComptables-lotComptable',{
			routeParams: {
				idLotComptable: lotComptable.idLotComptable
			},
			withGoBack: true
		});
	}
}