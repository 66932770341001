import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des groupes
 */
export default class GroupeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.groupe';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Groupe/listeGroupesActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item.libelle}${item.entity ? ' (' + translateService.instant('common.'+(item.actif ? 'actif' : 'inactif')) + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.groupe.Groupe';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idGroupe'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.entity && {
			clef: 'entity',
			valeur: filter.entity,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}
}