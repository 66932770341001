import { NgModule } from '@angular/core';

import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { CoreComponentsModule } from '../core-components.module';
import { RuleItemComponent } from './rule-item.component';
import { RuleDetailComponent } from './rule-detail.component';
import { RuleComponent } from './rule.component';
import { RuleService } from './rule.service';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { PopoverSelectorModule } from 'src/app/share/components/popover-selector/popover-selector.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,SelectPickerModule,AutocompleteModule,NumberModule,PopoverSelectorModule,IconModule],
	declarations: [RuleComponent,RuleDetailComponent,RuleItemComponent],
	exports: [RuleComponent],
	providers: [RuleService]
})
export class RuleModule {}