<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="extraOptions?.addCode?.(data)">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
</div>
<ul class="lv-attrs" *ngIf="data.idCode">
	<li><span translate>fournisseur.code.nature</span>&#160;:&#160;<span>{{ data.nature ? data.nature.libelle : ('common.aucune' | translate) }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idCode">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'suppression') && rightService.isRoot()"><a [routerLink]="[]" (click)="deleteCode();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>