<div class="listview lv-bordered no-search">
	<div class="lv-body">
		<ng-container *ngIf="user && conducteur.listeAttestationsPermis?.length">
			<div class="lv-item media" *ngFor="let attestation of conducteur.listeAttestationsPermis">
				<div class="pull-left lv-avatar"><icon name="check"></icon></div>
				<div class="media-body">
					<div class="lv-title"><a [routerLink]="[]" (click)="showAttestationPermis(attestation);" translate [translateParams]="{ date: (attestation.date | date:'shortDate') }">conducteur.attestationPermis.liste.title</a></div>

					<div class="lv-actions actions" *ngIf="null | right:'creation'" (click)="deleteAttestationPermis(attestation);">
						<a [routerLink]="[]"><icon name="delete"></icon></a>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="text-center p-10" *ngIf="!user || !conducteur.listeAttestationsPermis">
			<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
		</div>
		<div class="text-center p-10" *ngIf="user && conducteur.listeAttestationsPermis?.length === 0">
			<h5 translate>liste.empty</h5>
		</div>
	</div>
</div>