import { Component,Input,OnInit,Output } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';

@Component({
	templateUrl: './demande-vehicule-modele-selection-modele.component.html'
})
export class DemandeVehiculeModeleSelectionModeleComponent implements OnInit {
	/** Grille d'attribution selectionnée */
	@Input() selectedGrilleAttribution;

	/** Sélection d'un modèle **/
	@Input() @Output() onModeleSelected: (modele) => void;

	/** Données **/
	liste: ListView<any,null>;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,any>({
			uri: () => `${environment.baseUrl}/controller/Vehicule/filtreModelesByGrilleAttribution/${this.selectedGrilleAttribution.idGrilleAttribution}`,
			component: null,
			isLocal: true,
			isContentHidden: true,
			listeFilters: [{
				clef: 'modele',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'modele'
				},
				isDefault: true
			}],
			defaultOrder: 'modele.marque.libelle,modele.libelle,modele.finition'
		})
	}

	/**
	 * Calcul du montant d'une configuration de modèle
	 */
	computeMontantForModele(modele: any) {
		//Retour du prix formatté
		return modele.information?.commercialisationPrix ? (modele.information?.genre?.modeAffichagePrix == 'HT' ? (modele.configuration?.prixHTRemise ? modele.configuration?.prixHTRemise : modele.configuration?.prixHT) : (modele.configuration?.prixTTCRemise ? modele.configuration?.prixTTCRemise : modele.configuration?.prixTTC)) : '';
	}
}