import { PoolAddComponent } from 'src/app/components/vehicule/pool/pool-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des pools de vehicules
 */
export default class PoolOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.pool';

	/**
	 * URL
	 */
	url(filter?: { isShowAll?: boolean }) { return `/controller/VehiculePool/listePools/${filter?.isShowAll || 'false'}`; }

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle} (${item?.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.pool.Pool';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idPool'; }

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'pool.add.item';

	/**
	 * Composant d'ajout
	 */
	addComponent: () => any = () => PoolAddComponent;

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.VEHICULE_POOL;

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}
}