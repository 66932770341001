import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ContratFinancementEcheanceDetailListComponent } from './contrat-financement-echeance-detail-list.component';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	templateUrl: './contrat-financement-echeance-list-item.component.html'
})
export class ContratFinancementEcheanceListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private contratFinancementService: ContratFinancementService,private bsModalService: BsModalService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de la liste des lignes de facture d'une échéance
	 */
	showListeFactureDetailsForEcheance() {
		let bsModalRef: BsModalRef<ContratFinancementEcheanceDetailListComponent>;

		//Affichage de la popup de détail de l'échéance
		bsModalRef = this.bsModalService.show(ContratFinancementEcheanceDetailListComponent,{
			initialState: {
				contratFinancement: this.extraOptions.contratFinancement,
				echeance: this.data
			},
			class: 'modal-lg'
		});

		//Interception de la fermeture de la popup
		bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.result?.isValidated),
			filter(isValidated => !!isValidated)
		).subscribe({
			next: () => {
				//Validation de l'échéance
				this.doValidateEcheance();
			}
		});
	}

	/**
	 * Validation de l'échéance
	 */
	doValidateEcheance() {
		//Mise à jour des échéances
		this.contratFinancementService.doValidationForListeEcheances({
				entryPoint: `controller/Vehicule/doValidationListeEcheances/${this.extraOptions.contratFinancement.idContrat}`,
				params: {
					typeActionMasse: 'SELECTION',
					listeIdObjects: [this.data.idEcheance]
				}
			},this.liste).subscribe({
				next: (result: Result) => {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR)
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));
					else
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				},
				complete: () => {
					//Chargement du résumé
					this.contratFinancementService.loadResumeContratFinancement(this.extraOptions.contratFinancement.idContrat).pipe(first()).subscribe({
						next: result => {
							//Mise à jour du résumé
							this.extraOptions.onResumeChange({
								...this.extraOptions.resume,
								nbEcheancesAnomalie: result?.data?.nbEcheancesAnomalie || 0
							});
						}
					})
				}
			});
	}
}