import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { DataCustomizationModule } from 'src/app/share/components/entite/data-customization/data-customization.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { BusinessDataComponent } from './business-data.component';
import { BusinessDataService } from './business-data.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,DataCustomizationModule,AutocompleteModule,IconModule,FilterModule],
	declarations: [BusinessDataComponent],
	providers: [BusinessDataService]
})
export class BusinessDataModule {}