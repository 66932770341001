<div class="container-fluid">
	<form #personalDataForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-header">
				<h2 class="card-title" translate>personalData.anonymisation.title</h2>
			</div>
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
								<label translate>personalData.user</label>
								<div>
									<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="userToAnonymize" [filter]="{ isShowAll: true }" [isCreationDisabled]="true" required></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	</form>
	<span class="pull-right" *ngIf="null | right:'creation'">
		<button mat-raised-button color="primary" [disabled]="personalDataForm.invalid" (click)="anonymizeUser();">
			<span translate>actions.appliquer</span>
		</button>
	</span>
	<form #personalDataAutomatisationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-header">
				<h2 class="card-title" translate>personalData.automatisation.title</h2>
			</div>
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>personalData.automatisation.anonymisation</label>
								<div>
									<mat-checkbox name="automatique" #automatique="ngModel" [(ngModel)]="automatisationAnonymisation.automatique" (ngModelChange)="onAnonymisationAutomatiqueChange($event)"><span translate>personalData.automatisation.description</span></mat-checkbox>
								</div>
							</div>
							<div *ngIf="automatisationAnonymisation.automatique">
								<div class="form-group" [ngClass]="{ 'has-error': scheduler.invalid }">
									<label translate>personalData.automatisation.frequence</label>
									<div>
										<scheduler name="scheduler" #scheduler="ngModel" [(ngModel)]="automatisationAnonymisation.scheduler" required [options]="options"></scheduler>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': duree.invalid || typeField.invalid }">
									<label translate>personalData.automatisation.critere</label>
									<div class="d-flex gap-1 half">
										<input type="text" name="duree" class="form-control" #duree="ngModel" [(ngModel)]="automatisationAnonymisation.duree" nNumber [min]="1" required/>
										<selectpicker name="typeField" #typeField="ngModel" [(ngModel)]="automatisationAnonymisation.typeDuree" required>
											<mat-option *ngFor="let type of listeTypeDurees" [value]="type.code">{{ type.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	</form>
	<span class="pull-right" *ngIf="null | right:'creation'">
		<button mat-raised-button color="primary" [disabled]="personalDataAutomatisationForm.invalid" (click)="saveAutomatisationAnonymisation();">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
</div>