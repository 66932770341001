import { Component,Input,OnDestroy,OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Droit } from 'src/app/domain/droit/droit';
import { DroitService } from './droit.service';

@Component({
	selector: 'droit-line',
	templateUrl: './droit-line.component.html'
})
export class DroitLineComponent implements OnInit,OnDestroy {
	/** Droit courant **/
	@Input() droit: Droit = <any>{ creation: false,suppression: false,consultation: false };

	/** Métadonnées de la ligne **/
	@Input() extraData: { isGroupe: boolean,hasChild: boolean,libelle: string };

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Indicateur de droit global **/
	@Input() isRoot: boolean = false;

	/** Abonnement au droit unique  **/
	private rootRightSubscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor(public droitService: DroitService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Réception des ordres du droit racine
		!this.isRoot && !this.extraData?.isGroupe && (this.rootRightSubscription = this.droitService.onRootRightChange.subscribe({
			next: (rootRight: { droit: Droit,type: 'consultation' | 'creation' | 'suppression' }) => {
				//Définition des droits
				rootRight.type == 'creation' && (!this.droit.userDroit || this.droit.userDroit.creation) && (this.droit.creation = rootRight.droit?.creation);
				rootRight.type == 'suppression' && (!this.droit.userDroit || this.droit.userDroit.suppression) && (this.droit.suppression = rootRight.droit?.suppression);
				rootRight.type == 'consultation' && (!rootRight.droit?.consultation && !this.droit.creation && !this.droit.suppression || rootRight.droit?.consultation) && (this.droit.consultation = rootRight.droit?.consultation);

				//Mise à jour du droit de consultation si nécéssaire
				rootRight.type != 'consultation' && this.onDroitChange();
			}
		}));
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Destruction de l'abonnenement
		this.rootRightSubscription?.unsubscribe();
	}

	/**
	 * Interception d'un evènement lors de la modification d'un droit
	 */
	onDroitChange(type?: 'consultation' | 'suppression' | 'creation') {
		//Activation du mode 'consultation' lors de l'action de la création ou de la suppression
		!this.isRoot && (this.droit.creation || this.droit.suppression) && (this.droit.consultation = true);

		//Vérification du droit principal
		if (this.isRoot)
			//Emission du droit
			this.droitService.onRootRightChange.emit({ droit: this.droit,type: type });
	}

	/**
	 * Sélection du droit complet
	 */
	onFullRightClick() {
		//Vérification du groupe
		if (this.extraData?.hasChild === undefined && this.isEdition) {
			//Mise à jour des droits
			(!this.droit.userDroit || this.droit.userDroit.creation) && (this.droit.creation = !this.droit.creation);
			(!this.droit.userDroit || this.droit.userDroit.suppression) && (this.droit.suppression = !this.droit.suppression);
			(!this.droit.userDroit || this.droit.userDroit.consultation) && (this.droit.consultation = !this.droit.consultation);

			//Vérification du droit de consultation
			(this.droit.creation || this.droit.suppression) && (this.droit.consultation = true);
		}
	}
}
