<div class="modal-header">
	<h4 class="modal-title" translate>comptabilite.lotComptable.structureExport.anomalie.societesSansStructure.title</h4>
</div>
<div class="modal-body">
	<div class="alert alert-danger" *ngIf="listeSocietes.length > 0">
		<icon name="warning"></icon>
		<div>
			<strong translate>comptabilite.lotComptable.structureExport.anomalie.societesSansStructure.message</strong>
			<div translate>comptabilite.lotComptable.structureExport.anomalie.societesSansStructure.description</div>
			<ul>
				<li *ngFor="let societe of listeSocietes">{{ societe | display:'societe' }}</li>
			</ul>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>