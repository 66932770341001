<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data?.libelle?.substring(0,3) }}</div>
		<div class="lv-title">
			<a [routerLink]="['/CompteService/loadCompteService/',data.idCompteService]">{{ data.libelle }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.user.enabled ? 'oui' : 'non')"></span></li>
		<li *ngFor="let groupeDroit of data.listeGroupeDroits"><span [translate]="'compteService.groupeDroit.'+groupeDroit.typeGroupeDroit?.toLowerCase()"></span>&#160;:&#160;<span>{{ groupeDroit.nbDroits }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteCompteService();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>