import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { StructureExportDefinitionComponent } from './structure-export-definition.component';
import { StructureExportEditComponent } from './structure-export-edit.component';
import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export',
	templateUrl: './structure-export.component.html'
})
export class StructureExportComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Structure d'export courante **/
	public structureExport: any;

	/** Identifiant de la structure à dupliquer **/
	public idStructureSource: number;

	/** Restriction sur la structure d'export **/
	public restriction: { isRestricted: boolean } = null;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.STRUCTURE_EXPORT;

	/** Type de référentiel **/
	private typeReferentiel: TypeReferentiel;

	/** Utilisateur connecté **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService
			,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService, private attachmentService: AttachmentService,private loggedUserService: LoggedUserService,private compteService: CompteService) {
	}

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.export.StructureExport';

	/** Récupération de l'identifiant à charger **/
	getIdObject: () => number = () => this.structureExport?.idStructure || 0;

	/** Récupération de l'entité portant les pièces jointes **/
	getOwningEntity: () => LinksOwningEntity = () => this.structureExport;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Récupération de l'identifiant de la structure à dupliquer
		this.idStructureSource = history.state.idStructureSource;

		//Récupération de l'identifiant à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Récupération du type de référentiel
				this.typeReferentiel = params.typeReferentiel;

				//Chargement des données
				this.loadData(this.idStructureSource || params.idStructure || 0).subscribe({
					complete: () => {
						//Initialisation de la page complexe
						this.options = {
							listeFields: [{
								libelle: 'structureExport.libelle',
								key: 'libelle'
							},{
								libelle: 'structureExport.reference',
								key: 'reference'
							},{
								libelle: 'structureExport.actif.item',
								key: 'actif',
								type: 'BOOLEAN'
							},{
								libelle: 'structureExport.dateApplication',
								key: 'dateApplication',
								type: 'DATE',
								format: 'date',
							}],
							listeActions: [{
								libelle: 'actions.modifier',
								doAction: () => this.editStructureExport(),
								isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted,
								type: TypeAction.EDITION
							},{
								libelle: 'actions.consulter',
								doAction: () => this.editStructureExport(),
								isVisible: () => !this.rightService.hasRight(null,'creation') || this.restriction.isRestricted,
								type: TypeAction.CONSULTATION
							},{
								libelle: 'actions.supprimer',
								doAction: () => this.deleteStructureExport(),
								isVisible: () => this.rightService.hasRight(null,'suppression') && !this.restriction.isRestricted
							}],
							listeElements: [{
								type: 'DEFINITION',
								libelle: 'structureExport.elements.definition',
								component: StructureExportDefinitionComponent,
								retrieveComponentData: () => ({
									structureExport: this.structureExport,
									onStructureExportUpdate: structureExport => this.structureExport = structureExport,
									onRefresh: () => this.complexPage.setSelectedElementByType('DEFINITION',true),
									isRestricted: this.restriction.isRestricted
								}),
								count: () => this.structureExport.listeDetails?.filter(d => d.typeDetail == 'FICHIER')?.length || 0,
								isVisible: () => this.structureExport?.listeDetails?.length,
								isActionVisible: () => false
							}]
						};
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idStructure: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de la structure d'export
		this.structureExportService.loadStructureExport(idStructure).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la structure d'export
					this.structureExport = result.data?.structureExport ? result.data.structureExport : {
						actif: false,
						nbFichiers: 1,
						formatNumerique: {
							nbDecimales: 2,
							separateurDecimale: '.',
							typeCadrage: 'AUCUN'
						},
						formatDate: this.user?.locale?.formatDateCourte ? this.user.locale.formatDateCourte : null
					};

					//Vérification du mode 'Duplication'
					if (this.idStructureSource) {
						//Mise à jour des champs du duplicat
						this.structureExport.libelle += ' - ' + this.translateService.instant('structureExport.duplication.copie');
						this.structureExport.reference += ' - ' +this.translateService.instant('structureExport.duplication.copie');
						this.structureExport.actif = false;
						this.structureExport.dateApplication = null;
						this.structureExport.listeSocietes = [];
					}

					//Définition de la restriction
					this.restriction = this.compteService.getRestriction(this.structureExport.listeSocietes);

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);
				}

				//Vérification de l'identifiant de la structure d'export
				if (!this.structureExport?.idStructure || this.idStructureSource) {
					//Ouverture de la structure d'export en édition
					this.editStructureExport();

					//Finalisation du chargement des données
					subject.complete();
				}
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Edition de la structure d'export
	 */
	private editStructureExport() {
		//Ouverture du composant d'édition
		this.pageContentService.open(StructureExportEditComponent,{
			data: {
				structureExport: cloneDeep(this.structureExport),
				typeReferentiel: this.typeReferentiel,
				idStructureSource: this.idStructureSource,
				deleteStructureExport: this.deleteStructureExport.bind(this),
				isRestricted: this.restriction.isRestricted
			}
		}).subscribe({
			next: (structureExport: any) => {
				//Vérification des données
				if (structureExport) {
					//Mise à jour de la structure d'export
					Object.assign(this.structureExport,structureExport);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.structureExport);

					//Rafraichissement de l'onglet 'Définition'
					this.complexPage.setSelectedElementByType('DEFINITION',true);
				}
			}
		});
	}

	/**
	 * Suppression d'une structure d'export
	 */
	public deleteStructureExport() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.structureExportService.deleteStructureExport(this.typeReferentiel,this.structureExport))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}