<form #attestationPermisForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>conducteur.attestationPermis.title</h2>
		</div>
		<div class="card-body card-padding">
			<div *ngIf="user.type == 'VIRTUAL' && selectedAttestation.idAttestation" class="alert alert-info">
				<icon name="info"></icon>
				<strong translate>conducteur.attestationPermis.attestationValidee</strong>
			</div>
			<fieldset *ngIf="user.type != 'VIRTUAL' || !selectedAttestation.idAttestation" [disabled]="!(null | right:'creation') || selectedAttestation.idAttestation">
				<div class="alert alert-info">
					<icon name="warning"></icon>
					<div>
						<strong translate [translateParams]="{ prenom: selectedAttestation.conducteur.user.prenom,nom: selectedAttestation.conducteur.user.nom }">conducteur.attestationPermis.description.signataire</strong>
						<ul>
							<li translate>conducteur.attestationPermis.description.engagementRenouvellement</li>
							<li translate>conducteur.attestationPermis.description.validitePermis</li>
							<li translate>conducteur.attestationPermis.description.signalementCirconstances</li>
						</ul>
						<div translate>conducteur.attestationPermis.description.demarcheFacultative</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>conducteur.attestationPermis.date</label>
					<div class="half">
						<span><span>{{ (selectedAttestation.date || today) | date:'shortDate' }}</span></span>
					</div>
				</div>
				<div class="form-group">
					<mat-checkbox name="attestationValidee" [(ngModel)]="isAttestationValidee">
						<span translate>conducteur.attestationPermis.valider</span>
					</mat-checkbox>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right" *ngIf="(null | right:'creation') && (selectedAttestation.idAttestation ? user.type != 'VIRTUAL' : user.type == 'VIRTUAL')">
		<button mat-stroked-button color="primary" *ngIf="selectedAttestation.idAttestation" (click)="deleteAttestation(selectedAttestation,close);">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="!selectedAttestation.idAttestation" (click)="saveAttestation();" [disabled]="attestationPermisForm.invalid || !isAttestationValidee">
			<span translate>actions.valider</span>
		</button>
	</span>
	<button mat-button (click)="close()" *ngIf="user.type != 'VIRTUAL'">
		<span translate>actions.fermer</span>
	</button>
	<div class="clearfix"></div>
</form>