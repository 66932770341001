import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class CoursService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Enregistrement d'un cours
	 */
	public saveCours(cours: any): Observable<Result> {
		//Enregistrement du cours
		return this.http.put<Result>(`${environment.baseUrl}/controller/Devise/saveCours`,cours);
	}

	/**
	 * Suppression d'un cours
	 */
	public deleteCours(cours: any): Observable<Result> {
		//Suppression du cours
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Devise/deleteCours/${cours.idCours}`);
	}
}