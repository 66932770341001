import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './grille-fluidite-detail.component.html'
})
export class GrilleFluiditeDetailComponent implements OnInit {
	/** Détail de la grille de fluidité **/
	detail: any;

	/** Options d'affichage **/
	options: { disableDeletion?: boolean,loiRoulage?: any,lastEcheance?: any,distanceMensuelle?: number,distanceVehicule?: number,dureeContrat?: number,canAddDetail?: boolean };

	/** Durée **/
	duree: number;

	/** Distance **/
	distance: number;

	/** Distance mise en avant **/
	distanceMensuelleToHighlight: number;

	/** Ecart par rapport à la distance mise en avant **/
	ecartDistanceToHighlight: number;

	/** Affichage des variations **/
	isWithVariation: boolean;

	/** Indicateur d'affichage du détail **/
	isDetailDisplayed: boolean;

	/** Indicateur d'affichage de la variation **/
	isVariationDisplayed: boolean;

	/** Résultat **/
	result: { detail: any };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<GrilleFluiditeDetailComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de l'affichage du détail
		this.isDetailDisplayed = !this.isWithVariation;

		//Définition de l'affichage de la variation
		this.isVariationDisplayed = this.isWithVariation;
	}

	/**
	 * Sélection du détail
	 */
	selectDetail() {
		//Définition du résultat
		this.result = { detail: this.detail };

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}