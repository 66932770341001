import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'offre-edit',
	templateUrl: './offre-edit.component.html'
})
export class OffreEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() offre: any;

	/** Suppression de l'objet **/
	@Input() deleteOffre: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveOffre: (offre: any,close: Function) => void;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}
}