import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/domain/appstate';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { PlanEntretienService } from '../plan-entretien/plan-entretien.service';

@Component({
	templateUrl: './plan-entretien-add.component.html'
})
export class PlanEntretienAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Plan d'entretien **/
	planEntretien: any;

	/** Utilisateur courant **/
	private user: User;

	/** Taille de la popup **/
	public static modalSize: string = 'modal-lg';

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<PlanEntretienAddComponent>,private planEntretienService: PlanEntretienService,private toastrService: ToastrService,private translateService: TranslateService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisaiton du composant
	 */
	ngOnInit(): void {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => {
			//Mise à jour de l'utilisateur
			this.user = user;

			//Définition du plan d'entretien
			this.planEntretien = {
				actif: true,
				unite: this.user.unite
			};
		});
	}

	/**
	 * Enregistrement du plan d'entretien
	 */
	saveItem() {
		//Enregistrement du plan d'entretien
		this.planEntretienService.savePlanEntretien(this.planEntretien).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du plan d'entretien
					this.planEntretien = result.data.planEntretien;

					//Mise à jour du plan d'entretien créé
					this.addedItem = this.planEntretien;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}