'use strict';

var inherits = require('inherits'),
  Event = require('./event');
function CloseEvent() {
  Event.call(this);
  this.initEvent('close', false, false);
  this.wasClean = false;
  this.code = 0;
  this.reason = '';
}
inherits(CloseEvent, Event);
module.exports = CloseEvent;