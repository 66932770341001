import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FournisseurContactListItemComponent } from './fournisseur-contact-list-item.component';

@Component({
	selector: 'fournisseur-contact-list',
	templateUrl: './fournisseur-contact-list.component.html'
})
export class FournisseurContactListComponent extends PageContentItem implements OnInit {
	/** Fournisseur **/
	@Input() fournisseur: any;

	/** Fonction d'édition **/
	@Input() addContact: Function;

	/** Résumé **/
	@Input() resume: { nbContacts: number };

	/** Données **/
	liste: ListView<any,FournisseurContactListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FournisseurContactListItemComponent>({
			uri: () => `/controller/Fournisseur/filtreContacts/${this.fournisseur.idFournisseur || 0}`,
			component: FournisseurContactListItemComponent,
			isLocal: true,
			extraOptions: {
				addContact: this.addContact
			},
			listeFilters: [{
				clef: 'nom',
				isDefault: true
			},{
				clef: 'prenom',
				isDefault: true
			},{
				clef: 'email',
				isDefault: true
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idContact > 0)
			//Mise à jour des compteurs
			this.resume.nbContacts--;
	}
}