<form #workflowDetailRegleDefinitionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>workflow.detail.regle.definition.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!isEdition">
			<h4 translate>workflow.libelle</h4>
			<div class="form-group" [ngClass]="{ 'has-error': libelleModel.invalid }">
				<div>
					<input type="text" name="libelle" #libelleModel="ngModel" class="form-control" [(ngModel)]="libelle" maxlength="300" required/>
				</div>
			</div>
			<h4 class="p-t-10" translate>workflow.detail.regle.definition.regleGestion</h4>
			<rule [rule]="rule" [isEdition]="isEdition" [entite]="entite"></rule>
			<input hidden name="rule" ngModel [validator]="ruleService.isRuleValid(rule,true)"/>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span [translate]="'actions.' + (isEdition ? 'annuler' : 'fermer')"></span>
		</button>
		<button mat-raised-button color="primary" (click)="saveWorkflowDetailRegleDefinition()" [disabled]="workflowDetailRegleDefinitionForm.invalid" *ngIf="isEdition">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>
