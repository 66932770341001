import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './upgrade-log.component.html'
})
export class UpgradeLogComponent {
	/** Log à afficher **/
	log: any;

	/** Options de l'éditeur **/
	editorOptions: any = {
		lineNumbers: true,
		theme: 'cobalt',
		mode: 'java',
		readOnly: true
	};

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<UpgradeLogComponent>) {}
}