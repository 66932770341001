import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ConversationListComponent } from './conversation-list.component';
import { ConversationListItemComponent } from './conversation-list-item.component';
import { ConversationService } from './conversation.service';
import { ConversationComponent } from './conversation.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ConversationListComponent,ConversationListItemComponent,ConversationComponent],
	providers: [ConversationService]
})
export class ConversationModule { }
