import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DemandeCotationCotationContentComponent } from './demande-cotation-cotation-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [DemandeCotationCotationContentComponent],
	exports: [DemandeCotationCotationContentComponent]
})
export class DemandeCotationContentModule {}