import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { GrilleAttributionService } from 'src/app/components/vehicule/grille-attribution/grille-attribution.service';
import { mapEntites } from './demande-vehicule.entite';
import { DemandeVehiculeService } from './demande-vehicule.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	templateUrl: './demande-vehicule-list-item.component.html'
})
export class DemandeVehiculeListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Information sur l'élément **/
	public statut: { libelle: string,code: string,icone: string,iconeType?: string };

	/** Présence d'une grille d'attribution active **/
	public isGrilleAttributionActiveAvailable: boolean;

	/** Présence d'un workflow **/
	public isWorkflowAvailable: boolean;

	/** Modèle commandé **/
	public modele: { short?: string,full?: string } = {};

	/** Entité **/
	private readonly entite: string = 'com.notilus.data.vehicule.cotation.DemandeVehicule';

	/**
	 * Constructeur
	 */
	constructor(public demandeVehiculeService: DemandeVehiculeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private notificationService: NotificationService,public workflowService: WorkflowService,private grilleAttributionService: GrilleAttributionService) {
		//Héritage
		super();
	}

	/**
	* Récupération du mapping des entités
	*/
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération du statut associé à la demande de véhicule
		this.statut = this.demandeVehiculeService.getListeStatuts().find(s => s.code == this.data.statut);

		//Recherche d'un workflow
		this.workflowService.isWorkflowAvailableForEntity(this.entite).subscribe({
			next: isWorkflowAvailable => {
				//Définition de l'existence d'un Workflow
				this.isWorkflowAvailable = isWorkflowAvailable;
			}
		});

		//Recherche d'une grille d'attribution active
		this.grilleAttributionService.isGrilleAttributionActiveAvailable().subscribe({
			next: isGrilleActiveAvailable => {
				//Définition de l'existence d'une grille d'attribution active
				this.isGrilleAttributionActiveAvailable = isGrilleActiveAvailable;
			}
		});

		//Définition du modèle
		this.modele.full = this.data.modele?.libelle + (this.data.modele?.finition ? ' (' + this.data.modele.finition + ')' : '');

		//Vérification de la longueur du modèle
		if (this.modele.full.length > 40)
			//Réduction de la longueur du modèle
			this.modele.short = this.modele.full.substring(0,40) + '...';
	}

	/**
	 * Suppression de la demande de véhicule
	 */
	deleteDemandeVehicule() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la demande de véhicule
					this.demandeVehiculeService.deleteDemandeVehicule(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la demande de véhicule
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite(this.entite,this.data.idDemandeVehicule,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.DEMANDE_VEHICULE
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(this.entite,this.data.idDemandeVehicule);
	}

	/**
	 * Affichage de l'historique des actions Workflow
	 */
	showWorkflowHistory() {
		//Affichage de l'historique des actions Workflow
		this.workflowService.showWorkflowHistory(this.entite,this.data.idDemandeVehicule);
	}

	/**
	 * Réalisation de l'action Workflow
	 */
	public doAction(action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER') {
		//Réalisation de l'action Workflow
		this.demandeVehiculeService.doAction(action,this.data).subscribe({
			next: () => {
				//Rafraichissement de la liste
				this.liste.refresh();
			}
		});
	}

	/**
	 * Vérification de la disponibilité de l'action
	 */
	public isActionValid: Function = (action: 'TRANSMETTRE' | 'RAPPELER' | 'VALIDER' | 'REJETER' | 'COMMANDER' | 'ANNULER' | 'SUPPRIMER' | 'LIVRER') => this.demandeVehiculeService.isActionValid(this.data,action);
}