<div class="pull-left lv-avatar">{{ data?.nom?.substring(0,1)?.toUpperCase() + data?.prenom?.substring(0,1)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="goToUser();">{{ data | display:'user' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.enabled ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.service"><span translate>profil.elements.user.service</span>&#160;:&#160;<span>{{ data.service.libelle }}</span></li>
		<li *ngIf="data.login"><span translate>profil.elements.user.identifiant</span>&#160;:&#160;<span>{{ data.login }}</span></li>
		<li *ngIf="data.usage?.dateFirstLogin"><span translate>profil.elements.user.dateFirstLogin</span>&#160;:&#160;<span>{{ data.usage.dateFirstLogin | date:'short' }}</span></li>
		<li *ngIf="data.usage?.dateLastLogin"><span translate>profil.elements.user.dateLastLogin</span>&#160;:&#160;<span>{{ data.usage.dateLastLogin | date:'short' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.ADMIN_UTILISATEUR | right:'consultation'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="goToUser();"><span translate>profil.elements.user.actions.accederUser</span></a></li>
		</ul>
	</div>
</div>