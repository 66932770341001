<div class="listview lv-bordered no-search">
	<div class="lv-body">
		<ng-container *ngIf="resume.nbDefinitions > 0">
			<div class="lv-item media" *ngFor="let definition of chart.listeDefinitions; let index = index;">
				<div class="media-body">
					<div class="pull-left lv-avatar"><icon [name]="getTypeGraphiqueForCode(definition.type)?.icon"></icon></div>
					<div class="lv-title">
						<a [routerLink]="[]" (click)="addDefinition(index)" [translate]="'chart.typeGraphique.' + definition.type"></a>
					</div>
					<ul class="lv-attrs">
						<li translate>{{ 'chart.definition.' + (index == 0 ? 'principal' : 'secondaire') + '.item' }}</li>
					</ul>
					<div class="lv-actions actions" *ngIf="(null | right:'creation') && index == 1">
						<a [routerLink]="[]" (click)="deleteDefinition();"><icon name="delete"></icon></a>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="text-center p-10" *ngIf="resume.nbDefinitions == 0">
			<h5 translate><span translate>liste.empty</span></h5>
		</div>
	</div>
</div>