import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des factures
 */
export default class FactureOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.facture';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Facture/filtreFactures`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.reference} (${item.fournisseur.libelle})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any,translateService: TranslateService) { return item?.type ? translateService.instant(`facture.type.${item.type}`).substring(0,1) : ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.facture.Facture';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idFacture'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Tri par défaut
	 */
	defaultOrder: '-date,fournisseur.libelle,reference';
}