<div class="modal-header">
	<h4 class="modal-title"><span translate>templateMail.previewTemplateMail.title</span>&nbsp;:&nbsp;{{ templateMail.libelle }}</h4>
</div>
<div class="modal-body">
	<div class="media" *ngIf="content">
		<iframe class="w-100" #iframe frameborder="0" [srcdoc]="content" (load)="onLoad(iframe)" scrolling="no"></iframe>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
</div>