import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FournisseurService } from './fournisseur.service';

@Component({
	selector: 'fournisseur-edit',
	templateUrl: './fournisseur-edit.component.html'
})
export class FournisseurEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() fournisseur: any;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Suppression de l'objet **/
	@Input() deleteFournisseur: () => void;

	/** Indicateur de rattachement à une interface **/
	@Input() hasInterface: boolean;

	/** Liste des statuts **/
	public listeStatuts: Array<{ code: string,libelle: string }>

	/** Liste des types d'équipement **/
	public listeTypes: Array<{ code: string,libelle: string }>;

	/** Nombre de natures **/
	public nbNatures: number;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Possibilité de supprimer un métier de la liste **/
	private canRemoveMetiers: boolean;

	/** Copie du fournisseur **/
	private savedFournisseur: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private fournisseurService: FournisseurService,private confirmService: ConfirmService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Binding des méthodes
		this.confirmRemoveMetier = this.confirmRemoveMetier.bind(this);
		this.isRemoveMetierDisabled = this.isRemoveMetierDisabled.bind(this);
		this.doClose = this.doClose.bind(this);

		//Copie du fournisseur avant modifications
		this.savedFournisseur = cloneDeep(this.fournisseur);

		//Récupération du nombre de natures
		this.refreshNbNatures();

		//Récupération de la possibilité de supprimer un métier de la liste
		this.canRemoveMetiers = this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL && !this.fournisseur.fournisseurOrigine || this.rightService.isRoot());
	}

	/**
	 * Enregistrement du fournisseur
	 */
	public saveFournisseur() {
		//Enregistrement du fournisseur
		this.fournisseurService.saveFournisseur(this.typeReferentiel,this.fournisseur).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du fournisseur
				Object.assign(this.fournisseur,result.data?.fournisseur);

				//Enregistrement du nouvel état du fournisseur
				this.savedFournisseur = cloneDeep(this.fournisseur);

				//Vérification du type de référentiel
				if (this.typeReferentiel == TypeReferentiel.LOCAL)
					//Fermeture du formulaire
					this.close(this.fournisseur);
				else
					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.fournisseur);
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				let listeDoublons: Array<string> = [];

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Récupération du libellé du métier
	 */
	getLibelleMetier: (metier: any) => string = metier => metier.libelle;

	/**
	 * Vérification de la validité de la liste des métiers du fournisseur
	 */
	isListeMetiersInvalid: () => boolean = () => !this.fournisseur.listeMetiers?.length;

	/**
	 * Désactivation du bouton de suppression des métiers
	 */
	isRemoveMetierDisabled: (metier: any,index: number) => boolean = () => !this.canRemoveMetiers;

	/**
	 * Confirmation de la suppression d'un métier
	 */
	confirmRemoveMetier(): Observable<boolean> {
		//Vérification que le fournisseur est associé à une interface fournisseur
		if (this.hasInterface)
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('fournisseur.listeMetiers.confirmationSuppression'));
		else
			//Suppression du métier sans confirmation
			return of(true);
	}

	/**
	 * Ajout d'un métier au panier
	 */
	onAddMetier() {
		//Parcours des métiers sélectionnés
		this.fournisseur.listeMetiers.forEach(metier => {
			//Définition du type d'entité pour la désérialisation
			metier['_type'] = 'com.notilus.data.fournisseur.FournisseurMetier';
		});

		//Récupération du nombre de natures
		this.refreshNbNatures();
	}

	/**
	 * Suppression d'un métier du panier
	 */
	onRemoveMetier() {
		//Mise à jour du nombre de natures
		this.refreshNbNatures();
	}

	/**
	 * Mise à jour du nombre de natures
	 */
	refreshNbNatures() {
		//Récupération du nombre de natures
		this.fournisseurService.getNbNatures(this.fournisseur).subscribe(nbNatures => {
			//Mise à jour du nombre de natures
			this.nbNatures = nbNatures;
		});
	}

	/**
	 * Affichage de la liste des natures
	 */
	showListeNatures() {
		//Affichage de la liste des natures
		this.fournisseurService.showListeNatures(this.fournisseur);
	}

	/**
	 * Fermeture du formulaire
	 */
	doClose() {
		//Vérification de l'enregistrement du financement
		if (this.typeReferentiel == TypeReferentiel.LOCAL)
			//Fermeture du formulaire
			this.close();
		else
			//Retour à la page précédente
			this.layoutService.goBack();
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Vérification de la modification de l'objet
		return !isEqual(this.savedFournisseur,this.fournisseur);
	}
}