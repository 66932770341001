import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './contrat-financement.entites';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	templateUrl: './contrat-financement-list-item.component.html'
})
export class ContratFinancementListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private historyService: HistoryService,public notificationService: NotificationService,private store: Store<AppState>,private layoutService: LayoutService
			,private contratFinancementService: ContratFinancementService,private confirmService: ConfirmService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection du paramétrage
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Financement',this.data.idContrat);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.Financement',this.data.idContrat,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.FINANCEMENT
		});
	}

	/**
	 * Récupération de la liste des avenants
	 */
	getListeAvenantsAsString(): string {
		//Liste des avenants séparés par des virgules
		return this.data.listeLoisRoulage?.filter(loiRoulage => loiRoulage.reference && loiRoulage.reference != this.data.reference)?.map(loiRoulage => loiRoulage.reference)?.join(', ');
	}

	/**
	 * Suppression d'un contrat de financement
	 */
	deleteContratFinancement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du contrat de financement
					this.contratFinancementService.deleteContratFinancement(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du contrat de financement
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.data.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Réalisation de l'achat du véhicule
	 */
	doAchatVehicule() {
		//Réalisation de l'achat du véhicule
		this.contratFinancementService.doAchatVehicule(this.data).subscribe({
			next: contratFinancement => {
				//Vérification du contrat de financement
				if (contratFinancement)
					//Rafraichissement de la liste
					this.liste.refresh();
			}
		});
	}
}