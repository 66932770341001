import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { CotationService } from './cotation.service';

@NgModule({
	imports: [ShareModule],
	providers: [CotationService,ModeleService]
})
export class CotationModule { }