import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable,of,Subject } from 'rxjs';
import { map,take,tap,filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { ActionMasse,TypeActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { environment } from 'src/environments/environment';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { NotificationComponent } from './notification.component';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { AttachmentEntity } from 'src/app/domain/attachment/attachment';

@Injectable()
export class NotificationService {
	/** Liste des entités pour lesquelles les notifications sont disponibles **/
	private mapNotificationsForEntite: { [key: string]: boolean } = {};

	/** Liste des types de notification **/
	private readonly listeTypesNotification: Array<string> = ['USER','PROFIL','EMAIL'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private messagingService: MessagingService,private translateService: TranslateService) {}

	/**
	 * Vérification de la disponibilité des notifications pour une entité
	 */
	isNotificationAvailableFor(entite: string): Observable<boolean> {
		let params: URLSearchParams = new URLSearchParams();

		//Vérification de la présence d'une disponibilité
		if (this.mapNotificationsForEntite[entite] !== undefined) {
			//Retour de la disponibilité
			return of(this.mapNotificationsForEntite[entite]);
		} else {
			//Définition des données
			params.append('entite',entite);

			//Appel AJAX
			return this.http.post<Result>(`${environment.baseUrl}/controller/TemplateMail/isNotificationAvailableFor`,params,{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}).pipe(
				take(1),
				map(result => result.data?.isAvailable),
				tap(isAvailable => this.mapNotificationsForEntite[entite] = isAvailable)
			);
		}
	}

	/**
	 * Affichage de la popup de sélection du mail
	 */
	showSelectionMail(entite: string,actionMasse: ActionMasse,messagingOptions: MessagingOptions,attachmentEntity?: AttachmentEntity) {
		//Affichage de la popup
		this.bsModalService.show(NotificationComponent,{
			initialState: {
				entite,
				actionMasse,
				messagingOptions,
				attachmentEntity
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Sélection d'un mail pour une seule entité
	 */
	showSelectionMailForEntite(entite: string,idEntite: number,attachmentEntity?: AttachmentEntity) {
		//Affichage de la popup
		this.showSelectionMail(entite,{
			typeActionMasse: 'SELECTION',
			listeIdObjects: [idEntite],
			firstIdObject: idEntite,
			searchSpec: null
		},{
			outputPoint: '/messaging/doMasseAction/status'
		},attachmentEntity);
	}

	/**
	 * Ajout de l'extraction 'Notifier' à la liste (si disponible)
	 */
	addActionToListe(liste: ListView<any,any>,mapEntites: { [entite: string]: string }) {
		//Vérification de l'existence d'une extraction
		this.isNotificationAvailableFor(Object.keys(mapEntites)[0]).pipe(filter(isAvailable => !!isAvailable)).subscribe({
			next: () => {
				//Ajout de l'action à la liste
				liste.listeActions.push({
					icon: 'email',
					typeAction: TypeActionMasse.NOTIFIER,
					onPress: (actionMasse: ActionMasse,messagingOptions: MessagingOptions) => {
						//Affichage de la popup de sélection du mail
						this.showSelectionMail(Object.keys(mapEntites)[0],actionMasse,messagingOptions);
					}
				});
			}
		});
	}

	/**
	 * Récupération de la liste des destinataires d'un template de mail
	 */
	retrieveListeDestinatairesForTemplate(templateMail: any): Observable<Array<any>> {
		//Appel AJAX
		return this.http.post<Array<any>>(`${environment.baseUrl}/controller/TemplateMail/listeDestinatairesForMail/${templateMail.idTemplateMail}`,null).pipe(take(1));
	}

	/**
	 * Vérification des destinataires
	 */
	checkDestinataires(templateMail: any,listeDestinataires: Array<any>,actionMasse: ActionMasse,messagingOptions: MessagingOptions): Subject<Array<any>> {
		let subject: Subject<Array<any>> = new Subject<Array<any>>();
		let messagingObservable$: MessagingObservables;

		//Mise à jour de la liste des destinataires sélectionnés
		templateMail.listeDestinataires = listeDestinataires.filter(d => d.selected);

		//Définition du type pour la désérialisation
		templateMail._type = 'com.notilus.data.template.TemplateMail';

		//Appel de la WebSocket
		messagingObservable$ = this.messagingService.init({
			...messagingOptions,
			entryPoint: `${environment.baseUrl}/controller/Notification/sendMail/${templateMail.idTemplateMail}`,
			params: {
				actionMasse: {
					...actionMasse,
					data: templateMail
				},
				withoutSending: true
			}
		}).onError({
			next: payload => {
				//Envoi de l'erreur
				subject.error(payload);

				//Finalisation du sujet
				subject.complete();

				//Annulation de l'abonnement
				messagingObservable$.unsubscribe();
			}
		}).onFinish({
			next: ({ idSession }) => {
				//Récupération des informations
				this.http.post<Result>(`${environment.baseUrl}/controller/Notification/checkDestinataires/${idSession}/${templateMail.idTemplateMail}`,null).pipe(
					take(1),
					map(result => result?.data?.listeDestinataireChecks)
				).subscribe({
					next: listeDestinataireChecks => {
						//Parcours des contrôles
						listeDestinataireChecks?.forEach(c => {
							let destinataire: any;

							//Récupération du destinataire
							destinataire = listeDestinataires.find(d => d.idDestinataire == c.idDestinataire);

							//Vérification du destinataire
							if (destinataire)
								//Ajout du contrôle
								destinataire.check = c;
						});

						//Envoi des données
						subject.next(listeDestinataires);

						//Finalisation du sujet
						subject.complete();
					},
					error: () => {
						//Annulation de l'abonnement
						messagingObservable$.unsubscribe();
					},
					complete: () => {
						//Annulation de l'abonnement
						messagingObservable$.unsubscribe();
					}
				});
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Envoi d'une notification
	 */
	sendMail(templateMail: any,listeDestinataires: Array<any>,actionMasse: ActionMasse,messagingOptions: MessagingOptions,remarque: string): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messagingObservable$: MessagingObservables;

		//Mise à jour de la liste des destinataires sélectionnés
		templateMail.listeDestinataires = listeDestinataires.filter(d => d.selected);

		//Définition du type pour la désérialisation
		templateMail._type = 'com.notilus.data.template.TemplateMail';

		//Appel de la WebSocket
		messagingObservable$ = this.messagingService.init({
			...messagingOptions,
			entryPoint: `${environment.baseUrl}/controller/Notification/sendMail/${templateMail.idTemplateMail}`,
			params: {
				actionMasse: {
					...actionMasse,
					data: templateMail
				},
				content: remarque
			}
		}).onError({
			next: payload => {
				//Envoi de l'erreur
				subject.error(payload);

				//Finalisation du sujet
				subject.complete();

				//Annulation de l'abonnement
				messagingObservable$.unsubscribe();
			}
		}).onFinish({
			next: (message) => {
				//Vérification du type de message reçu
				if (message.type == 'COMPLETE') {
					//Finalisation du sujet
					subject.complete();

					//Annulation de l'abonnement
					messagingObservable$.unsubscribe();
				} else
					//Envoi du message
					subject.next(message);
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Envoi de la notification à l'administrateur du tenant
	 */
	sendMailToAdminTenant(captchaResponse: string,notification: any): Observable<Result> {
		//Envoi de la notification à l'administrateur du tenant
		return this.http.post<Result>(`${environment.baseUrl}/controller/Notification/sendMailToAdminTenant`,notification,{
			headers: {
				'X-AUTH-CAPTCHA': captchaResponse
			}
		});
	}

	/**
	 * Suppression du cache des entités pour lesquelles les notifications sont disponibles
	 */
	resetNotificationsForEntiteCache() {
		//Suppression du cache
		this.mapNotificationsForEntite = {};
	}

	/**
	 * Récupération de la liste des types de notification
	 */
	public getListeTypesNotification(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de notification
		return this.listeTypesNotification.map(code => ({
			code,
			libelle: this.translateService.instant(`notification.type.${code}`)
		}));
	}
}