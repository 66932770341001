import { ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './chart-preview.component.html'
})
export class ChartPreviewComponent {
	/** Graphique **/
	dashboardChart: { chart: any };

	/** Résultat **/
	result: { isChartAdded: boolean };

	/** Indicateur d'ajout de graphique **/
	@Input() canAddChart: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ChartPreviewComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Ajout du graphique
	 */
	addChart() {
		//Définition du résultat
		this.result = {
			isChartAdded: true
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
 	 * Fermeture de la addChart
 	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}