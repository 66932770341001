<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Equipement/loadEquipement/'+data.idEquipement]"><span>{{ data.reference + (data.fournisseur ? ' (' + data.fournisseur.libelle + ')' : '') }}</span></a>
</div>
<ul class="lv-attrs" *ngIf="data.idEquipement">
	<li *ngIf="data.type"><span translate>equipement.type.item</span>&#160;:&#160;<span [translate]="'equipement.type.'+data.type"></span></li>
	<li *ngIf="data.statut"><span translate>equipement.statut.item</span>&#160;:&#160;<span [translate]="'equipement.statut.'+data.statut"></span></li>
	<li *ngIf="data.dateDebut && data.dateFin"><span translate>equipement.periode.title</span>&#160;:&#160;<span translate [translateParams]="{ debut: data.dateDebut | date:'shortDate',fin: data.dateFin | date:'shortDate' }">equipement.periode.item</span></li>
	<li *ngIf="data.numero"><span translate>equipement.numeroCarte</span>&#160;:&#160;<span>{{ data.numero }}</span></li>
	<li *ngIf="data.extension?.affecte && (data.extension.vehiculeAffecte || data.extension.userAffecte)"><span translate>equipement.affectationCourante</span>&#160;:&#160;<span>{{ data.extension.vehiculeAffecte && (data.extension.vehiculeAffecte | display:'vehicule') || data.extension.userAffecte && (data.extension.userAffecte | display:'user') }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idEquipement && !liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteEquipement();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>