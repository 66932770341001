<div class="modal-header">
	<h4 class="modal-title" translate>mailHistory.liste.titre</h4>
</div>
<div class="modal-body m-0">
	<div class="card">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>