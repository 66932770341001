import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FormatInterfaceCodeListItemComponent } from './format-interface-code-list-item.component';

@Component({
	selector: 'format-interface-code-list',
	templateUrl: './format-interface-code-list.component.html'
})
export class FormatInterfaceCodeListComponent extends PageContentItem implements OnInit {
	/** Format d'interface **/
	@Input() formatInterface: any;

	/** Fonction d'édition **/
	@Input() addCode: () => void;

	/** Résumé **/
	@Input() resume: { nbCodes: number };

	/** Données **/
	liste: ListView<any,FormatInterfaceCodeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FormatInterfaceCodeListItemComponent>({
			uri: () => `/controller/Fournisseur/filtreCodes`,
			title: this.translateService.instant('fournisseur.formatInterface.elements.code.liste.title'),
			component: FormatInterfaceCodeListItemComponent,
			isLocal: true,
			extraOptions: {
				addCode: this.addCode
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'interfaceFournisseur.idInterface',
				valeur: this.formatInterface.idInterface,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item): void {
		//Vérification de l'élément
		if (item.idCode > 0)
			//Mise à jour des compteurs
			this.resume.nbCodes -= 1;
	}
}