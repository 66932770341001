import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { VehiculeImputationSelectionItemComponent } from './vehicule-imputation-selection-item.component';
import { VehiculeService } from './vehicule.service';

@Component({
	selector: 'vehicule-imputation-selection',
	templateUrl: './vehicule-imputation-selection.component.html'
})
export class VehiculeImputationSelectionComponent implements OnInit,AfterContentChecked {
	/** Utilisateur **/
	@Input() user: User;

	/** Résultat */
	public result: { vehicule: any };

	/** Véhicule sélectionné **/
	public selectedVehicule: { liste?: any,referentiel?: any } = {};

	/** Source **/
	public selectedSource: 'REFERENTIEL' | 'LISTE' = 'REFERENTIEL';

	/** Données **/
	liste: ListView<any,VehiculeImputationSelectionItemComponent>;

	/** Enumération des types de véhicule **/
	public TypeVehicule: typeof TypeVehicule = TypeVehicule;

	/** Indicateur de présence de données */
	hasData: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<VehiculeImputationSelectionComponent>,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeImputationSelectionItemComponent>({
			uri: () => `/controller/Vehicule/filtreAffectations`,
			component: VehiculeImputationSelectionItemComponent,
			listeFilters: [{
				clef: 'vehicule',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule'
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'conducteur.user.idUser',
				valeur: this.user.idUser,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			}],
			defaultOrder: '-dateDebut',
			isLocal: true,
			extraOptions: {
				onSourceChange: this.onSourceChange.bind(this),
				selectedVehicule: this.selectedVehicule,
				selectedSource: this.selectedSource
			},
			onRefresh: (liste,result) => {
				//Vérification de la présence de données
				this.hasData = result.content.length > 0;

				//Mise à jour de la source
				!this.hasData && this.onSourceChange('REFERENTIEL');
			}
		})
	}

	/**
	 * Changement de source
	 */
	public onSourceChange(source: 'REFERENTIEL' | 'LISTE' = this.selectedSource) {
		//Vérification de la nouvelle source
		if (source == 'REFERENTIEL')
			//Suppression du véhicule sur la liste
			this.selectedVehicule.liste = null;
		else
			//Suppression du véhicule sur le référentiel
			this.selectedVehicule.referentiel = null
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la sélection
	 */
	public saveVehiculeImputationSelection() {
		//Définition du résultat
		this.result = {
			vehicule: this.selectedVehicule.liste || this.selectedVehicule.referentiel
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}