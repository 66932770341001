import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { AmenagementService } from './amenagement.service';

@Component({
	selector: 'amenagement-list-item',
	templateUrl: './amenagement-list-item.component.html'
})
export class AmenagementListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private amenagementService: AmenagementService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idAmenagement,object?.idAmenagement && !this.data?.idAmenagement);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteAmenagement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la société
					this.amenagementService.deleteAmenagement(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject);
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule(vehicule: any) {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: vehicule.idVehicule
			},
			withGoBack: true
		});
	}
}