<form #contactForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>fournisseur.contact.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': nom.invalid }">
							<label translate>fournisseur.contact.nom</label>
							<div>
								<input type="text" class="form-control" name="nom" #nom="ngModel" [(ngModel)]="contact.nom" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': prenom.invalid }">
							<label translate>fournisseur.contact.prenom</label>
							<div>
								<input type="text" class="form-control" name="prenom" #prenom="ngModel" [(ngModel)]="contact.prenom" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
							<label translate>fournisseur.contact.email</label>
							<div>
								<input type="email" email class="form-control" name="email" #email="ngModel" [(ngModel)]="contact.email" maxlength="600" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': telephone.invalid }">
							<label translate>fournisseur.contact.telephone</label>
							<div>
								<input type="text" class="form-control" name="telephone" #telephone="ngModel" [(ngModel)]="contact.telephone" phoneNumber [required]="contact.mobile"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': telephone2.invalid }">
							<label translate>fournisseur.contact.telephone2</label>
							<div>
								<input type="text" class="form-control" name="telephone2" #telephone2="ngModel" [(ngModel)]="contact.telephone2" phoneNumber/>
							</div>
						</div>
						<div class="form-group">
							<label translate>fournisseur.contact.adresse</label>
							<div>
								<adresse name="adresse" [(ngModel)]="contact.adresse" [withCoords]="true"></adresse>
							</div>
						</div>
						<div class="form-group">
							<label translate>fournisseur.contact.fonction</label>
							<div>
								<input type="text" class="form-control" name="fonction" [(ngModel)]="contact.fonction" maxlength="300"/>
							</div>
						</div>
						<div class="form-group">
							<label translate>fournisseur.contact.type.item</label>
							<div>
								<selectpicker name="type" [(ngModel)]="contact.type" required>
									<mat-option *ngFor="let type of listeTypesContact" [value]="type.code">{{ type.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div class="form-group">
								<label translate>fournisseur.contact.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="contact.actif" [disabled]="contact.mobile"><span translate>fournisseur.contact.actif.info</span></mat-checkbox>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group">
								<label translate>fournisseur.contact.mobile.item</label>
								<div>
									<mat-checkbox name="mobile" #mobile="ngModel" [(ngModel)]="contact.mobile" (ngModelChange)="onContactMobileChange()"><span translate>fournisseur.contact.mobile.info</span></mat-checkbox>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group">
								<label translate>fournisseur.contact.piecesJointes</label>
								<div>
									<div [attachment]="contact" [typeAttachment]="TypeAttachment.CONTACT" [parentOwningEntity]="contact.fournisseur"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>fournisseur.contact.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" [(ngModel)]="contact.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteContact(contact,close);" *ngIf="(null | right:'creation') && contact.idContact > 0">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="contactForm.invalid" (click)="saveContact();" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: contactForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>