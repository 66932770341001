import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { ReleveCompteurService } from 'src/app/components/vehicule/releve-compteur/releve-compteur.service';
import { ConducteurReleveCompteurEditComponent } from './conducteur-releve-compteur-edit.component';


@Component({
	templateUrl: './conducteur-releve-compteur-list-item.component.html'
})
export class ConducteurReleveCompteurListItemComponent extends ListViewItem<any> implements OnInit {
	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private releveCompteurService: ReleveCompteurService,private pageContentService: PageContentService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => this.user = user);
	}

	/**
	 * Affichage du relevé de compteur
	 */
	showCompteur() {
		//Chargement complet du compteur
		this.releveCompteurService.loadReleveCompteur(this.data?.idCompteur || 0).subscribe(result => {
			//Affichage du compteur
			this.pageContentService.open(ConducteurReleveCompteurEditComponent,{
				data: {
					releveCompteur: result?.data?.compteur || this.data,
					user: this.user
				}
			},'sub').subscribe();
		});
	}
}