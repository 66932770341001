import { Component,Input,OnDestroy,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject,Subscription } from 'rxjs';
import { filter,takeUntil } from 'rxjs/operators';

import { ChartOptions,SubTypeChart,TypeChart } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { TypeComparaison } from 'src/app/domain/common/list-view';
import { DashboardItem } from 'src/app/domain/dashboard/dashboard-item';
import { ChartTipComponent } from 'src/app/share/components/chart/chart-tip.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { DashboardService } from './dashboard.service';

@Component({
	selector: 'dashboard-chart',
	templateUrl: './dashboard-chart.component.html'
})
export class DashboardChartComponent extends DashboardItem implements OnInit,OnDestroy {
	/** Options du graphique **/
	@Input() chartOptions: ChartOptions;

	/** Données du chart **/
	@Input() data: Page<any>;

	/** Indicateur d'absence d'actions **/
	@Input() hasNoActions: boolean = false;

	/** Index courant **/
	currentDefinitionIndex: number = 0;

	/** Définition courante **/
	currentDefinition: any;

	/** Indicateur de gestion des données **/
	hasData: boolean;

	/** Numéro de page **/
	private numPage: number = 0;

	/** Souscription sur l'ajout de filtres partagés **/
	private onRuleFilterAddedSubscription: Subscription;

	/** Sujet pour l'annulation du chargement **/
	private cancelSubject: Subject<any> = new Subject<any>();

	/**
	 * Constructeur
	 */
	constructor(private dashboardService: DashboardService,private translateService: TranslateService,private ruleService: RuleService,private extractionService: ExtractionService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de l'absence d'options
		if (!this.chartOptions) {
			//Chargement du graphique
			this.loadGraph(this.currentDefinitionIndex);

			//Ecoute de l'ajout de filtres partagés
			this.onRuleFilterAddedSubscription = this.dashboardService.onRuleFilterAdded$.pipe(filter(({ entity,idChart }) => {
				//Vérification de la nécessité de filtrer le graphique
				return this.dashboardChart.chart.extraction.entity == entity && (!idChart || this.dashboardChart.chart.idChart != idChart);
			})).subscribe({
				next: () => {
					//Vérification de la présence de sélection
					if (!this.currentDefinition.listeSelectedValues)
						//Mise à jour de l'index
						this.currentDefinitionIndex = 0;

					//Rechargement des données
					this.loadGraph(this.currentDefinitionIndex,this.currentDefinition.listeSelectedValues);
				}
			});
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation de la souscription
		this.onRuleFilterAddedSubscription?.unsubscribe();

		//Fin du chargement si nécessaire
		this.cancelSubject.next(null);
	}

	/**
	 * Chargement du graphique
	 */
	private loadGraph(index: number,listeSelectedValues?: Array<any>) {
		//Sélection de la définition courante
		this.currentDefinition = this.dashboardChart.chart.listeDefinitions[index];

		//Définition des options du graphique
		this.chartOptions = {
			type: this.currentDefinition.type,
			subType: this.currentDefinition.subType,
			typeAgregation: this.currentDefinition.typeAgregation,
			title: this.dashboardChart.customLibelle || this.dashboardChart.chart.libelle,
			seuilMin: this.dashboardChart.min != null ? this.dashboardChart.min : 0.0,
			seuilMax: this.dashboardChart.max != null ? this.dashboardChart.max : 0.0,
			grid: 'HORIZONTAL',
			axis: 'BOTH',
			tipComponent: this.currentDefinition.type != TypeChart.KPI ? () => ChartTipComponent as any : null,
			noDataMessage: this.translateService.instant('dashboard.chart.noData'),
			hasInteractions: this.dashboardChart.chart.listeDefinitions.length > 1,
			hasInterGraphiques: this.dashboardChart.interGraphique,
			hasAccesData: this.currentDefinition.data,
			hasNotifications: this.dashboardChart.notification,
			goBack: index > 0 && this.chartOptions?.goBack || null,
			getLabel: (d: any,index: number) => {
				let label: string;
				let day: any;

				//Vérification de l'objet
				if (typeof d != 'object') {
					//Conversion en objet
					d = {
						label: d
					};
				}

				//Vérification de la données
				if (d) {
					//Vérification du type de regroupement
					if (this.currentDefinition.listeCles[index || 0]?.typeGroupement == 'MOIS') {
						//Définition du libellé
						label = d.label ? moment(d.label.length == 7 ? d.label.substring(0,4) + d.label.substring(5,7) : d.label.replace(/([0-9]{4})([0-9]{1})([0-9]{1})/,'$10$3'),'YYYYMM').format('MM/YYYY') : this.translateService.instant('dashboard.chart.notAssigned');
					} else if (this.currentDefinition.listeCles[index || 0]?.typeGroupement == 'TRIMESTRE') {
						//Formatage du trimestre
						label = d.label ? moment(d.label,'YYYYQ').format('(Q) YYYY') : this.translateService.instant('dashboard.chart.notAssigned');
					} else if (this.currentDefinition.listeCles[index || 0]?.typeGroupement == 'JOUR_SEMAINE') {
						//Recherche du libellé du jour
						day = this.ruleService.getListeWeekDays().find(weekDay => weekDay.code == d.label);

						//Retour du libellé
						label = day ? day.libelle : d.label;
					} else {
						//Retour du label brut
						label = d.listeLabels ? this.translateService.instant('dashboard.chart.other') : d.label ? d.label : this.translateService.instant('dashboard.chart.notAssigned');
					}
				} else
					//Aucun label
					label = this.translateService.instant('dashboard.chart.notAssigned');

				return label;
			},
			previous: () => {
				//Vérification de l'index
				if (this.numPage > 0)
					//Chargement des données précédentes
					this.loadData(--this.numPage,listeSelectedValues);
			},
			next: isLast => {
				//Vérification de la dernière page
				if (!isLast)
					//Chargement des données suivantes
					this.loadData(++this.numPage,listeSelectedValues);
			},
			getListeActions: () => [{
				libelle: this.translateService.instant('actions.personnaliser'),
				doAction: () => this.onCustomize.emit({
					dashboardChart: this.dashboardChart,
					callback: () => {
						//Mise à jour de l'index
						this.currentDefinitionIndex = 0;

						//Rechargement des données
						this.loadGraph(this.currentDefinitionIndex);
					}
				}),
				isVisible: () => this.dashboardChart?.position >= 0
			},{
				libelle: this.translateService.instant('actions.supprimer'),
				doAction: () => {
					//Suppression du graphique
					this.onDelete.emit(this.dashboardChart);
				},
				isVisible: () => this.isEdition
			},{
				libelle: this.translateService.instant('actions.extraire'),
				doAction: () => {
					//Réalisation de l'extraction
					//TODO Gestion de l'entité filtrante
					this.extractionService.executeExtraction(this.dashboardChart.chart.extraction,{ dashboardChart: this.dashboardChart });
				},
				isVisible: () => !this.isEdition
			},{
				libelle: this.translateService.instant('dashboard.chart.actions.accederDonnees'),
				doAction: () => {
					//Accès aux données
					this.dashboardService.redirectToList(this.dashboardChart,this.currentDefinition,this.chartOptions,null);
				},
				isVisible: () => this.hasData && !this.isEdition
			}]
		};

		//Indicateur de gestion des accès aux données
		this.hasData = this.currentDefinition.data;

		//Définition de la valeur filtrante
		this.currentDefinition.listeSelectedValues = index > 0 && listeSelectedValues ? listeSelectedValues : null;

		//Vérification de l'accès aux données ou des inter-actions
		if (!this.isEdition && (index == 0 && this.dashboardChart.chart.listeDefinitions.length > 1 || !this.hasNoActions && (this.hasData || this.dashboardChart.interGraphique))) {
			//Définition de l'intercepteur de clic
			this.chartOptions.onSelect = (d: any,isModal?: boolean) => {
				//Vérification du mode
				if (this.isEdition)
					//Ne pas continuer
					return;

				//Interaction possible
				if (!this.dashboardChart.interGraphique && index == 0 && this.dashboardChart.chart.listeDefinitions.length > 1) {
					//Définition de l'index courant
					this.currentDefinitionIndex = 1;

					//Rechargement du graphique
					this.loadGraph(this.currentDefinitionIndex,d);

					//Définition du retour arrière
					this.chartOptions.goBack = () => this.loadGraph(this.currentDefinitionIndex - 1);
				} else if (this.dashboardChart.interGraphique) {
					//Ajout de la règle
					this.dashboardService.addRule(this.dashboardChart.chart.extraction.entity,this.dashboardChart,this.currentDefinition,this.currentDefinition.subType != SubTypeChart.NON_DEFINI ? d : d[0]);

					//Demande de rechargement des graphiques liés
					this.dashboardService.onRuleFilterAddedSubject.next({
						entity: this.dashboardChart.chart.extraction.entity,
						idChart: index == 0 && this.dashboardChart.chart.listeDefinitions.length > 1 && this.dashboardChart.chart.idChart
					});

					//Vérification de la présence du drill-down
					if (index == 0 && this.dashboardChart.chart.listeDefinitions.length > 1) {
						//Définition de l'index courant
						this.currentDefinitionIndex = 1;

						//Rechargement du graphique
						this.loadGraph(this.currentDefinitionIndex,d);

						//Définition du retour arrière
						this.chartOptions.goBack = () => this.loadGraph(this.currentDefinitionIndex - 1);
					}
				} else if (this.hasData && !isModal) {
					//Accès aux données
					this.dashboardService.redirectToList(this.dashboardChart,this.currentDefinition,this.chartOptions,d);
				}
			};
		} else
			//Définition de l'intercepteur de clic
			this.chartOptions.onSelect = null;

		//Chargement des données
		this.loadData(0,listeSelectedValues);
	}

	/**
	 * Chargement des données
	 */
	private loadData(numPage: number = 0,listeSelectedValues: Array<any> = null) {
		//Chargement des données
		this.dashboardService.executeChart(this.dashboardChart.chart?.idChart || 0,this.currentDefinitionIndex,numPage,{
			chart: this.dashboardChart.chart,
			nbValues: this.dashboardChart.nbValues,
			rule: this.dashboardChart.rule,
			sharedRule: this.dashboardService.getRule(this.dashboardChart.chart.extraction.entity),
			listeSelectedValues,
			...(this.idFilteringObject && {
				filteringEntity: this.filteringEntity,
				idFilteringObject: this.idFilteringObject
			} || {})
		}).pipe(takeUntil(this.cancelSubject)).subscribe({
			next: result => {
				//Mise à jour des données
				this.data = result?.data?.data;

				//Vérification du type de chart
				if (this.dashboardChart.chart?.firstType == TypeChart.KPI) {
					//Parcours des données
					this.data.content.forEach(d => {
						//Définition de la couleur personnalisée
						d.color = this.getCustomColor(d.value);

						//Définition de l'icône
						d.icon = this.currentDefinition?.icone;
					});
				} else if (this.dashboardChart.chart?.firstType == TypeChart.BAR || this.dashboardChart.chart?.firstType == TypeChart.LINE)
					//Récupération de la couleur personnalisée
					this.chartOptions.color = this.getCustomColor();
			}
		});
	}

	/**
	 * Récupération de la couleur personnalisée
	 */
	private getCustomColor(value?: any): string {
		let color: string = null;
		let idxCustomColor: number = 0;
		let item: any = null;
		let defaultColor: any;

		//Vérification de la listes des couleurs personnalisées
		if (this.dashboardChart.listeCustomColors?.length) {
			//Vérification de la présence d'une valeur
			if (value) {
				do {
					//Récupération de la couleur personnalisée
					item = this.dashboardChart.listeCustomColors[idxCustomColor];

					//Vérification du type de comparaison
					if (item.typeComparaison == TypeComparaison.EQUAL && value == item.min)
						//Définition de la couleur
						color = item.color;
					else if (item.typeComparaison == TypeComparaison.BETWEEN && value < item.max && value > item.min)
						//Définition de la couleur
						color = item.color;
					else if (item.typeComparaison == TypeComparaison.LESS && value < item.min)
						//Définition de la couleur
						color = item.color;
					else if (item.typeComparaison == TypeComparaison.LESS_EQUAL && value <= item.min)
						//Définition de la couleur
						color = item.color;
					else if (item.typeComparaison == TypeComparaison.GREATER && value > item.min)
						//Définition de la couleur
						color = item.color;
					else if (item.typeComparaison == TypeComparaison.GREATER_EQUAL && value >= item.min)
						//Définition de la couleur
						color = item.color;

					//Incrémentation du compteur
					idxCustomColor++;
				} while (idxCustomColor < this.dashboardChart.listeCustomColors.length && color == null);
			}

			//Vérification de la couleur
			if (color == null) {
				//Recherche de la couleur par défaut
				defaultColor = this.dashboardChart.listeCustomColors.find(color => !color.typeComparaison);

				//Vérification de la couleur par défaut
				if (defaultColor)
					//Définition de la couleur
					color = defaultColor.color;
			}

			//Vérification de la couleur
			if (color && color[0] != '#')
				//Ajout de '#'
				color = '#' + color;

			return color;
		} else
			//Aucune couleur par défaut
			return null;
	}
}