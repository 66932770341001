import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurEntretienListItemComponent } from './conducteur-entretien-list-item.component';

@Component({
	selector: 'conducteur-entretien-list',
	templateUrl: './conducteur-entretien-list.component.html'
})
export class ConducteurEntretienListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Résumé **/
	@Input() resume: { nbEntretiens: number };

	/** Données **/
	liste: ListView<any,ConducteurEntretienListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConducteurEntretienListItemComponent>({
			uri: `/controller/Entretien/filtreEntretiensByUser/${this.conducteur.user.idUser}`,
			component: ConducteurEntretienListItemComponent,
			isLocal: true,
			extraOptions: {
				resume: this.resume
			},
			listeFilters: [{
				clef: '*fournisseur.libelle',
				isDefault: true
			},{
				clef: 'vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			defaultOrder: '-isPrevisionnel,datePrevisionnelle,-dateRealisation,vehicule.reference,type',
			onRefresh: (liste,data) => {
				//Vérification de la remise à zéro de la liste
				if (liste.numPage == 0 && !liste.listeSelectedFilters?.length && !liste.extraData)
					//Mise à jour du nombre d'entretiens
					this.resume.nbEntretiens = data.totalElements;
			}
		});
	}
}