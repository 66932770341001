import { Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-commercialisation-prix',
	templateUrl: './modele-commercialisation-prix.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleCommercialisationPrixComponent implements OnInit {
	/** Commercialisation et prix **/
	@Input() commercialisationPrix: any;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Mise à jour des avantages en nature (si nécessaire)
		this.commercialisationPrix.avantageNature = this.commercialisationPrix.avantageNature || {};
	}
}
