import { Input,EventEmitter,Output,Directive } from '@angular/core';

import { ListView } from './list-view';
import { ListItem } from './list-item';
import { TypeActionMasse } from './action';

/**
 * Interface représentant une liste
 */
@Directive()
export abstract class ListViewItem<T extends ListItem> {
	/** Données **/
	@Input() public data: T;

	/** Données **/
	@Input() public liste: ListView<T,any>;

	/** Options supplémentaires **/
	@Input() public extraOptions: any;

	/** Interception d'un changement d'affichage **/
	@Output() onDisplayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	/** Demande de suppression de l'élément de la liste **/
	@Output() onRemove: EventEmitter<T> = new EventEmitter<T>();

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any,idObject?: number,isAdded?: boolean) {
		//Vérification de la présence d'un identifiant
		if (idObject && object) {
			//Masquage de l'objet
			this.data?.onDisplayChange?.(this.data);

			//Retrait de l'attribut 'isDisplayed'
			delete object.isDisplayed;

			//Mise à jour de l'objet
			Object.assign(this.data,object);
		} else if (isAdded) {
			//Ajout de l'objet
			this.data?.onDisplayChange?.(object);
		} else {
			//Suppression de l'objet dans la liste
			this.data?.onRemove?.(this.data);
		}
	}

	/**
	 * Vérification de la présence de notifications disponibles
	 */
	public hasNotifications(): boolean {
		//Vérification de la présence d'une notification
		return this.liste?.listeActions?.some(a => a.typeAction == TypeActionMasse.NOTIFIER && (!a.isVisible || a.isVisible()));
	}
}