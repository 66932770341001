import { ToastrService } from 'ngx-toastr';
import { Component,Input,Output,EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { Result, TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'profil-dasboard-list-item',
	templateUrl: './profil-dashboard-list-item.component.html'
})
export class ProfilDashboardListItemComponent {
	/** Elément courant **/
	@Input() profil: any;

	/** Dashboard **/
	@Input() item: any;

	/** Notification de la demande d'édition du dashboard **/
	@Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private dashboardService: DashboardService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {

	}

	/**
	 * Affichage de la personnalisation du dashboard
	 */
	public showDashboard() {
		//Notification du passage en mode édition
		this.onEdit.emit(this.item);
	}

	/**
	 * Suppression de la liste des charts du dashboard
	 */
	public deleteProfilDashboard() {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('profil.dashboard.actions.deleteDashboard')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la liste des charts
					this.item.listeDashboardCharts = null

					//Définition du profil
					this.item.profil = this.profil;

					//Sauvegarde du dashboard
					this.dashboardService.saveDashboard('PROFIL',this.item).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Actualisation du dashboard
								Object.assign(this.item,result.data.dashboard);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Réinitialisation du dashboard
	 */
	public resetProfilDashboard() {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('profil.dashboard.actions.resetDashboard')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Appel AJAX
					this.dashboardService.deleteDashboard(this.item.idDashboard).subscribe({
						next: () => {
							//Suppression du profil
							this.item.profil = null;

							//Suppression de l'identifiant
							this.item.idDashboard = null;

							//Initialisation de la liste des charts
							this.item.listeDashboardCharts = [];
						}
					})
				}
			}
		});
	}
}