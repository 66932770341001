import { HttpEvent,HttpHandler,HttpInterceptor,HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';


@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
	/** Expression régulière de vérification de l'URL **/
	private loginRegExp: RegExp;

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private location: Location,private layoutService: LayoutService) {
		//Expression régulière de vérification de l'URL
		this.loginRegExp = new RegExp(/(http|https):\/\/([\w+:.\w+])+([\w\-_.]*)#\/Login([a-zA-Z0-9_\-/]*)?/g);
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		let url: string;

		//Vérification de la présence d'une redirection
		if (sessionStorage?.getItem?.('redirected')) {
			//Mise en cycle
			setTimeout(() => {
				//Récupération de l'URL de la fenêtre
				url = window.location.href;

				//Affichage de la confirmation
				this.confirmService.showConfirm(this.translateService.instant('actions.redirection.confirmationRedirected',{ url: this.loginRegExp.test(url) ? url : this.getBaseUrl() }),{
					isCancelDisabled: true,
					actionColor: 'primary'
				}).subscribe();
			},500);

			//Suppression de l'élément du stockage de session
			sessionStorage.removeItem('redirected');

			//Remplacement de l'URL
			this.layoutService.replaceUrl(this.location.path());

			//Suppression du paramètre
			return next.handle(request.clone({ params: request.params.delete('redirected') }));
		} else
			//Poursuite de la requête
			return next.handle(request);
	}

	/**
	 * Récupération de l'URL de base
	 */
	private getBaseUrl() {
		let url: string;

		//Récupération de l'URL complète (sans les paramètres de routage)
		url = window.location.href.substring(0,window.location.href.lastIndexOf('#') - 1);

		//Récupération de l'URL complète (sans les paramètres de requête)
		url = url.indexOf('?') != -1 ? url.substring(0,url.lastIndexOf('?') - 1) : url;

		return url;
	}
}