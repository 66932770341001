<div class="modal-header">
	<h4 class="modal-title" translate>vehicule.achat.title</h4>
</div>
<div class="modal-body">
	<form #vehiculeAchatForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="form-group" [ngClass]="{ 'has-error': dateAchat.invalid }">
			<label translate>contrat.financement.dateAchat</label>
			<div class="half">
				<input type="text" name="dateAchat" class="form-control" #dateAchat="ngModel" [(ngModel)]="contratFinancement.dateAchat" [disabled]="!contratFinancement.objectVersion" datePicker required/>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="vehiculeAchatForm.invalid || !contratFinancement.objectVersion" (click)="saveContratFinancement()">
		<span translate>actions.enregistrer</span>
	</button>
</div>