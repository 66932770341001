<div class="modal-header">
	<h4 class="modal-title" translate>structureExport.ligneSelection.title</h4>
</div>
<div class="modal-body">
	<div class="panel panel-default">
		<div class="panel-body">
			<ul class="list-group" *ngFor="let typeDetail of listeTypesDetail">
				<h4>{{ typeDetail.libelle }}</h4>
				<li class="list-group-item" *ngFor="let typeLigne of getListeTypesLigneForTypeDetail(typeDetail.code)">
					<mat-checkbox name="selected" #selected="ngModel" [(ngModel)]="typeLigne.isSelected"></mat-checkbox>
					<span>
						<a [routerLink]="[]" class="clickable m-t-0" (click)="typeLigne.isSelected = !typeLigne.isSelected;">{{ typeLigne.libelle }}</a>
					</span>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="selectLines();" [disabled]="!isSelectionValid()">
		<span translate>actions.continuer</span>
	</button>
</div>