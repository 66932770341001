import { Component,Input } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';

@Component({
	selector: 'notification-template-list-item',
	templateUrl: './notification-template-list-item.component.html'
})
export class NotificationTemplateListItemComponent extends ListViewItem<any> {
	/** Indicateur de suppression possible **/
	@Input() canRemove: boolean;

	/**
	 * Constructeur
	 */
	constructor(private templateMailService: TemplateMailService) {
		//Héritage
		super();
	}

	/**
	 * Prévisualisation du template de mail
	 */
	previewTemplateMail() {
		//Prévisualisation du template de mail
		this.templateMailService.previewTemplateMail(this.data,this.extraOptions.idObject);
	}
}