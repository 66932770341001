import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SelectPickerService } from './select-picker.service';
import { SelectPickerComponent } from './select-picker.component';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';

@NgModule({
	imports: [BrowserModule,CoreComponentsModule],
	declarations: [SelectPickerComponent],
	exports: [SelectPickerComponent],
	providers: [SelectPickerService]
})
export class SelectPickerModule {}