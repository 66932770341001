<div class="pull-left lv-avatar">
	<icon name="directions_car" *ngIf="data?.type?.type == 'VEHICULE'"></icon>
	<icon type="icon-venus" name="n-icon-demande-identification" *ngIf="data?.type?.type == 'ANTAI'"></icon>
</div>
<div class="lv-title">
	<span>{{ data.identifiant }}</span>
</div>
<ul class="lv-attrs" *ngIf="data.idLicenceConsommationDetail">
	<li *ngIf="data?.type?.type == 'VEHICULE' && data.date1"><span translate>licence.consommation.elements.vehicule.dateEntree</span>&#160;:&#160;<span>{{ data.date1 | date:'short' }}</span></li>
	<li *ngIf="data?.type?.type == 'VEHICULE' && data.date2"><span translate>licence.consommation.elements.vehicule.dateSortie</span>&#160;:&#160;<span>{{ data.date2 | date:'short' }}</span></li>
	<li *ngIf="data?.type?.type == 'ANTAI' && data.date1"><span translate>licence.consommation.elements.demandeIdentification.dateInfraction</span>&#160;:&#160;<span>{{ data.date1 | date:'short' }}</span></li>
	<li *ngIf="data?.type?.type == 'ANTAI' && data.date2"><span translate>licence.consommation.elements.demandeIdentification.datePremiereDemande</span>&#160;:&#160;<span>{{ data.date2 | date:'short' }}</span></li>
</ul>