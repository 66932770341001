import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des natures des fournisseurs
 */
export default class FournisseurNatureOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.fournisseurNature';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/Fournisseur/listeNaturesByFournisseur${filter?.idFournisseur ? '/' + filter.idFournisseur : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle} (${item?.idNature})` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.fournisseur.FournisseurNature';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idNature'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'idNature',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.typeReconciliation && {
			clef: 'typeReconciliation',
			valeur: filter.typeReconciliation,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}
}