import { AfterViewInit,Directive,ElementRef,forwardRef,Inject,Input,LOCALE_ID,OnChanges,OnInit,SimpleChanges,OnDestroy,HostBinding } from '@angular/core';
import { FormatWidth,getLocaleDateFormat,getLocaleDateTimeFormat,getLocaleTimeFormat } from '@angular/common';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { TempusDominus,DateTime,Namespace,Unit } from '@eonasdan/tempus-dominus';

import { mapDatePatternFormats } from 'src/app/utils/patterns';
import { GLOBALS } from 'src/app/utils/globals';

@Directive({
	selector: '[datePicker]',
	exportAs: 'datePicker',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DatePickerDirective),
		multi: true
	}]
})
export class DatePickerDirective implements  ControlValueAccessor,AfterViewInit,OnInit,OnChanges,OnDestroy {
	/** Format des dates **/
	@Input() format: 'date' | 'datetime' | 'time' = 'date';

	/** Date minimale **/
	@Input() minDate: number | string | Date | moment.Moment | 'today';

	/** Date maximale **/
	@Input() maxDate: number | string | Date | moment.Moment | 'today';

	/** Mode d'affichage **/
	@Input() mode: 'day' | 'month' | 'year' = 'day';

	/** Mode d'affichage du composant (déroulant par défaut, ou incrusté) **/
	@Input() inline: boolean = false;

	/** Affichage des semaines **/
	@Input() showWeeks: boolean = false;

	/** Stockage au format 'Date' **/
	@Input() storeDate: boolean = false;

	/** Désactivation du composant **/
	@Input() disabled: boolean;

	/** Alignement du texte de l'élément hôte **/
	@HostBinding('style.text-align') textAlign = 'center';

	/** Composant du DateTimePicker **/
	datetimePicker: TempusDominus = null;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Valeur initiale **/
	private _initialValue: any;

	/** Liste des souscriptions **/
	private subscriptions: { unsubscribe: Function } | Array<{ unsubscribe: Function }>;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,@Inject(LOCALE_ID) private locale: string) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification du format
		this.format = this.format || 'date';

		//Vérification du mode
		this.mode = this.mode || 'day';
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désinscription
		Array.isArray(this.subscriptions) ? this.subscriptions.forEach(sub => sub.unsubscribe()) : this.subscriptions.unsubscribe();
	}

	/**
	 * Détection des modifications
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification du changement de borne min
		if (changes?.minDate && !changes.minDate.isFirstChange()) {
			//Mise à jour du DatePicker
			this.datetimePicker.updateOptions({
				restrictions: {
					minDate: new DateTime(this.computeTargetDate('min',changes.minDate.currentValue,null))
				}
			});
		}

		//Vérification du changement de borne max
		if (changes?.maxDate && !changes.maxDate.isFirstChange()) {
			//Mise à jour du DatePicker
			this.datetimePicker.updateOptions({
				restrictions: {
					maxDate: new DateTime(this.computeTargetDate('max',changes.maxDate.currentValue,null))
				}
			});
		}

		//Vérification du changement d'état de l'élément
		if (changes?.disabled)
			//Définition de l'état de l'élément
			GLOBALS.$(this.elementRef.nativeElement).prop('disabled',changes.disabled.currentValue);
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation du DateTimePicker
			this.isInit = true;

			//Initialisation du datePicker
			this.initDatePicker(this._initialValue);
		});
	}

	/**
	 * Initialisation du datePicker
	 */
	initDatePicker(defaultDate?: number) {
		//Initialisation du DateTimePicker
		this.datetimePicker = new TempusDominus(this.elementRef.nativeElement,{
			allowInputToggle: false,
			promptTimeOnDateChange: true,
			dateRange: false,
			debug: false,
			defaultDate: defaultDate ? new DateTime(defaultDate) : undefined,
			useCurrent: false,
			display: {
				theme: 'light',
				viewMode: ['date','datetime'].includes(this.format) ? (this.mode == 'day' ? 'calendar' : this.mode == 'month' ? 'months' : 'years') : 'clock',
				inline: this.inline,
				sideBySide: this.format == 'datetime',
				components: {
					decades: ['day','month','year'].includes(this.mode),
					year: ['day','month','year'].includes(this.mode),
					month: ['day','month'].includes(this.mode),
					date: this.mode == 'day',
					clock: ['datetime','time'].includes(this.format),
					hours: ['datetime','time'].includes(this.format),
					minutes: ['datetime','time'].includes(this.format),
					seconds: false
				},
				icons: {
					time: 'zmdi zmdi-time',
					date: 'zmdi zmdi-calendar',
					up: 'zmdi zmdi-long-arrow-up',
					down: 'zmdi zmdi-long-arrow-down',
					previous: 'zmdi zmdi-chevron-left',
					next: 'zmdi zmdi-chevron-right',
					today: 'zmdi zmdi-calendar-check',
					clear: 'zmdi zmdi-delete',
					close: 'zmdi zmdi-close'
				},
				calendarWeeks: this.showWeeks,
				keepOpen: false
			},
			restrictions: {
				minDate: this.minDate ? new DateTime(this.computeTargetDate('min',this.minDate,null)) : undefined,
				maxDate: this.maxDate ? new DateTime(this.computeTargetDate('max',this.maxDate,null)) : undefined
			},
			localization: this.getLocalization()
		});

		//Définition des souscriptions
		this.subscriptions = this.datetimePicker.subscribe([Namespace.events.change,Namespace.events.error,Namespace.events.hide],[(event: { date: DateTime }) => {
			let format: string;

			//Vérification de la manipulation de dates seules
			if (event.date && this.format == 'date')
				//Modification de l'heure
				event.date.startOf(Unit.date);
			else if (event.date)
				//Modification des secondes
				event.date.startOf(Unit.minutes);

			//Définition du format
			format = this.mode == 'day' ? 'YYYY MM DD HH:mm:ss' : this.mode == 'month' ? 'YYYY-MM' : 'YYYY';

			//Vérification de la présence d'une date
			if (event.date && this.mode == 'day' && !this.storeDate)
				//Mise à jour de la valeur
				this.value = moment(event.date).valueOf();
			else if (event.date && (this.mode != 'day' || this.storeDate))
				//Mise à jour de la valeur
				this.value = moment.utc(moment(event.date.valueOf()).format(format),format).local().valueOf();
			else
				//Suppression de la valeur
				this.value = null;
		},(error: any) => {
			//Vérification de la validité de l'ancienne date
			if (error.date.isSame(error.oldDate))
				//Suppression de la valeur
				this.value = null;
		},(event: { date: DateTime }) => {
			//Synchronisation du picker avec le modèle
			this.datetimePicker?.dates.setValue(event.date,this.datetimePicker.dates.lastPickedIndex);
		}] as any);
	}

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any) {
		//Définition de la valeur
		this.value = value;

		//Synchronisation du picker avec le modèle
		this.datetimePicker?.dates.setValue(DateTime.fromString(value,this.getLocalization()),this.datetimePicker.dates.lastPickedIndex);
	}

	/**
	 * Enregistrement de la fonction d'interception du changement
	 */
	registerOnChange(fn: any) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement de la fonction d'interception de l'appui
	 */
	registerOnTouched(fn: any) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Définition de la valeur initiale
		this._initialValue = value;

		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);
		}
	}

	/**
	 * Conversion d'une date en timestamp
	 */
	convertToTimestamp(date: any): number {
		//Vérification de la valeur
		if (moment.isMoment(date))
			//Définition de la valeur
			return date.toDate()?.getTime();
		else if (date instanceof Date)
			//Définition de la valeur
			return date.getTime();
		else if (typeof date == 'string' || typeof 'number')
			//Définition de la valeur
			return date;
	}

	/**
	 * Conversion d'une date en timestamp
	 */
	convertToDate(date: any): Date {
		//Vérification de la valeur
		if (moment.isMoment(date))
			//Définition de la valeur
			return date.toDate();
		else if (date instanceof Date)
			//Définition de la valeur
			return date;
		else if (typeof date == 'string' || typeof 'number')
			//Définition de la valeur
			return moment(date).toDate();
	}

	/**
	 * Calcul de la date cible
	 */
	computeTargetDate(type: 'min' | 'max',targetDate: string | number | Date | moment.Moment | 'today',defaultDate: number,mode: moment.unitOfTime.Base = this.mode): number {
		let result: number = defaultDate;

		//Vérification de la présence d'un date cible
		if (targetDate != null) {
			//Lecture de la date cible
			if (targetDate == 'today')
				//Définition de la date cible
				targetDate = new Date();

			//Conversion de la date
			targetDate = this.convertToTimestamp(targetDate);

			//Comparaison de la date cible avec la date du jour
			if (defaultDate != null && (type == 'min' && targetDate > defaultDate || type == 'max' && targetDate < defaultDate))
				//Définition de la date cible
				result = defaultDate;
			else
				//Définition de la date cible
				result = targetDate;

			//Vérification du format
			if (this.format == 'date')
				//Conversion de la date au début de la période (jour, mois, année,...)
				return type == 'min' ? moment(result)?.startOf(mode)?.toDate()?.getTime() : moment(result)?.endOf(mode)?.toDate()?.getTime();
			else
				//Retour de la date
				return moment(result)?.toDate()?.getTime();
		}

		//Aucune date cible
		return undefined;
	}

	/**
	 * Calcul du format
	 */
	computeFormat(format: 'date' | 'datetime' | 'time',mode: 'day' | 'month' | 'year' = this.mode,formatWidth: FormatWidth = FormatWidth.Short,isMoment: boolean = true,locale: string = this.locale): string {
		//Vérification du type de format
		if (isMoment || mode != 'day') {
			//Définition du format
			return mapDatePatternFormats[locale][formatWidth][mode == 'day' ? format : mode];
		} else {
			//Vérification du format
			if (format == 'date')
				//Définition du format (dd/mm/yyyy)
				return getLocaleDateFormat(locale,formatWidth);
			else if (format == 'datetime')
				//Définition du format (hh:mm)
				return getLocaleDateTimeFormat(locale,formatWidth);
			else if (format == 'time')
				//Définition du format (hh:mm)
				return getLocaleTimeFormat(locale,formatWidth);
			else
				//Retour du format par défaut
				return format;
		}
	}

	/**
	 * Récupération de la localisation
	 */
	getLocalization(): any {
		//Recherche de la localisation
		const { dateFormats,format } = mapDatePatternFormats[this.locale].pickerFormats.filter(f => f.name == this.format)[0];

		//Retour de la localisation du format à utiliser
		return { dateFormats: dateFormats[this.mode],format };
	}
}