<div class="pull-left lv-avatar">{{ data.entity.split('.').pop(-1).substring(0,1).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Extraction/loadExtraction',liste.selectedSelector,data.idExtraction]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.entity"><span translate>extraction.entity</span>&#160;:&#160;<span>{{ entiteService.translateEntityCode(data.entity.split('.').pop(-1)) }}</span></li>
		<li><span translate>extraction.typeFormat.item</span>&#160;:&#160;<span [translate]="'extraction.typeFormat.'+data.typeFormat"></span></li>
		<li *ngIf="rightService.isRoot() || extraOptions.user.tenant?.type == 'REVENDEUR'"><span translate>extraction.typeDiffusion.item</span>&#160;:&#160;<span [translate]="'diffusion.'+data.typeDiffusion"></span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.listeFields?.length"><a [routerLink]="[]" (click)="executeExtraction();"><span translate>actions.executer</span></a></li>
			<li *ngIf="(null | right:'creation') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="duplicateExtraction();"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteExtraction();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idExtraction)"><span translate>actions.importer</span></a></li>
			<li *ngIf="(null | right:'consultation')"><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>