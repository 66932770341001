import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'conducteur-releve-energie-edit',
	templateUrl: './conducteur-releve-energie-edit.component.html'
})
export class ConducteurReleveEnergieEditComponent extends PageContentItem {
	/** Relevé d'énergie **/
	@Input() releveEnergie: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}