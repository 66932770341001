<div class="tile-active-show collapse" [collapse]="!upgrade.isDisplayed">
	<div class="tile-sub">
		<ul class="tab-nav">
			<li [ngClass]="{ 'active': selectedTab == 'PRE' }" (click)="selectedTab = 'PRE';"><a [routerLink]="[]"><span translate>upgrade.item.menu.preRequis</span></a></li>
			<li [ngClass]="{ 'active': selectedTab == 'SCRIPT' }" (click)="selectedTab = 'SCRIPT';"><a [routerLink]="[]"><span translate>upgrade.item.menu.database</span></a></li>
			<li [ngClass]="{ 'active': selectedTab == 'POST' }" (click)="selectedTab = 'POST';"><a [routerLink]="[]"><span translate>upgrade.item.menu.finalisation</span></a></li>
		</ul>

		<div class="tab-content">
			<div class="listview lv-bordered lv-sm">
				<div class="lv-body">
					<div class="lv-item media" *ngFor="let item of getListeItemsForSelectedTab()">
						<div class="media-body">
							<span class="label label-success pull-right" *ngIf="item.success"><span translate>upgrade.item.task.success</span></span>
							<span class="label label-danger pull-right" *ngIf="!item.success"><span translate>upgrade.item.task.echec</span></span>
							<div class="lv-title"><a [routerLink]="[]" (click)="showLog(item);">{{ selectedTab == 'SCRIPT' ? (item.dateExecution | date:'short') : item.name }}</a></div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center p-10" *ngIf="!getListeItemsForSelectedTab()">
				<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
			</div>
			<div class="text-center p-10" *ngIf="getListeItemsForSelectedTab()?.length == 0">
				<h5><span translate>liste.empty</span></h5>
			</div>
		</div>
	</div>
	<div class="tile-footer">
		<button mat-button (click)="close();">
			<span translate>actions.fermer</span>
		</button>
	</div>
</div>