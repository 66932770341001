import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DatabaseListItemComponent } from './database-list-item.component';
import { mapEntites } from './database.entites';
import { DatabaseService } from './database.service';

@Component({
	templateUrl: './database-list.component.html'
})
export class DatabaseListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,DatabaseListItemComponent>;

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private databaseService: DatabaseService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DatabaseListItemComponent>({
			uri: () => `/controller/Database/filtreDatabases`,
			title: this.translateService.instant('database.liste.title'),
			component: DatabaseListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'schema',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'type',
				type: TypeFilter.LONG,
				listeValues: this.databaseService.getListeTypesDatabase(),
				isListeValuesWithButton: true
			}],
			getKeyFieldName: () => 'idDatabase',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(d => !d.idDatabase) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					type: 'MULTI_TENANT'
				});
			}
		})
	}
}