import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConnecteurExecutionListItemComponent } from './connecteur-execution-list-item.component';

@Component({
	selector: 'connecteur-execution-list',
	templateUrl: './connecteur-execution-list.component.html'
})
export class ConnecteurExecutionListComponent extends PageContentItem implements OnInit {
	/** Connecteur courant **/
	@Input() connecteur: any;

	/** Résumé **/
	@Input() resume: { nbAppels: number };

	/** Données **/
	liste: ListView<any,ConnecteurExecutionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste
		this.liste = new ListView<any,ConnecteurExecutionListItemComponent>({
			uri: () => `/controller/Connecteur/filtreConnecteurExecutions/${this.connecteur.idConnecteur}`,
			component: ConnecteurExecutionListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'correlationId',
				isDefault: true
			},{
				clef: '*user',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				},
				isDefault: true
			},{
				clef: '*apiSession.sessionId',
				isDefault: true
			}],
			defaultOrder: '-dateExecution'
		});
	}
}
