<form #typeAmenagementFieldsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" *ngIf="listeFields.length > 0" class="m-t-20">
	<fieldset [disabled]="!(null | right:'creation')">
		<div [ngClass]="{ 'card detail p-10': isEdition }">
			<div class="listview">
				<div class="lv-body">
					<div class="lv-item" *ngFor="let field of listeFields">
						<div class="sortable-item">
							<div>
								<span [translate]="'vehicule.typeAmenagement.typeField.' + field.typeField + '.item'"></span>
								<icon name="info_outline" class="m-l-5" [tooltip]="('vehicule.typeAmenagement.typeField.' + field.typeField + '.info') | translate"></icon>
							</div>
							<ul>
								<li>
									<mat-slide-toggle color="primary" [name]="'actif_'+field.idField" #actif="ngModel" [(ngModel)]="field.actif" [disabled]="!isEdition" [checked]="field.actif"></mat-slide-toggle>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<div *ngIf="isEdition" class="card-footer">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveTypeAmenagement();" *ngIf="null | right:'creation'" [disabled]="typeAmenagementFieldsForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: typeAmenagementFieldsForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>