<form #entiteFieldForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>entiteField.listeColonnes</h2>
		</div>
		<div class="card-body card-padding">
			<div class="listview">
				<div class="lv-body">
					<bs-sortable *ngIf="listeFields" name="listeFields" [(ngModel)]="listeFields" [itemTemplate]="itemTemplate" itemActiveClass="invisible"></bs-sortable>

					<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="showBusinessData()" *ngIf="(null | right:'creation') || isUpdateAvailable">
						<h5><a [routerLink]="[]" translate>entiteField.ajouterColonne</a></h5>
					</div>
				</div>
			</div>
		</div>
	</div>

	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
		<span translate>actions.fermer</span>
	</button>
	<span class="pull-right">
		<button mat-raised-button color="primary" [disabled]="entiteFieldForm.invalid" (click)="save()" *ngIf="(null | right:'creation') || isUpdateAvailable">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
</form>
<ng-template #itemTemplate let-field="item" let-index="index">
	<div class="lv-item media p-0">
		<div class="sortable-item">
			<div>
				<span class="handle" *ngIf="(null | right:'creation') || isUpdateAvailable"><icon class="icon-hc-2x" name="open_with"></icon></span>
				<span>{{ field?.value?.libelle }}</span>
				<span *ngIf="data">&#160;<icon name="arrow_forward" class="bold m-l-10 m-r-10"></icon>&#160;<span>{{ entiteService.readValue(data,field.value) }}</span></span>
			</div>
			<ul *ngIf="(null | right:'creation') || isUpdateAvailable">
				<li *ngIf="index != listeFields?.length - 1">
					<button mat-icon-button color="primary" (click)="moveField(index,1);">
						<mat-icon>arrow_circle_down</mat-icon>
					</button>
				</li>
				<li *ngIf="index != 0">
					<button mat-icon-button color="primary" (click)="moveField(index,-1);">
						<mat-icon>arrow_circle_up</mat-icon>
					</button>
				</li>
				<li>
					<button mat-icon-button color="primary" (click)="deleteField(index);">
						<mat-icon>delete</mat-icon>
					</button>
				</li>
			</ul>
		</div>
	</div>
</ng-template>