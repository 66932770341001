import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'vehicule-equipement-edit',
	templateUrl: './vehicule-equipement-edit.component.html'
})
export class VehiculeEquipementEditComponent extends PageContentItem  {
	/** Elément courant **/
	@Input() affectationEquipement: any;

	/** Suppression de l'affectation **/
	@Input() deleteAffectationEquipement: (affectationEquipement: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}