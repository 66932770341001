import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//Activation du mode 'Production' si nécessaire
environment.production && enableProdMode();

//Démarrage de l'application
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));