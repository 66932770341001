import { Component } from '@angular/core';

@Component({
	template: ''
})
export abstract class AutocompleteAddComponent {
	/** Objet créé à la volée **/
	addedItem: any = null;

	/** Taille de la popup **/
	public static modalSize: string = 'modal-lg';

	/** Filtres de l'autocomplete **/
	public filter: any = null;

	/**
	 * Enregistrement de l'élément
	 */
	abstract saveItem(): void;
}