import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { NatureService } from './nature.service';

@Component({
	templateUrl: './nature-list-item.component.html'
})
export class NatureListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private natureService: NatureService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject);
	}

	/**
	 * Suppression d'une nature
	 */
	deleteNature() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la nature
					this.natureService.deleteNature(this.data.idNature).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la nature
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Récupération des métiers de la nature
	 */
	getListeMetiers() {
		//Retour des libellés des métiers
		return (this.data?.listeMetiers || []).map(metier => metier.libelle).join(', ');
	}
}