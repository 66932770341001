<form #lienCompteDetailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.lienCompteDetail.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': planComptableField.invalid }" *ngIf="!rightService.isRoot()">
							<label translate>comptabilite.lienCompteDetail.planComptable</label>
							<div>
								<autocomplete name="planComptable" type="planComptable" [filter]="{ idSociete: lien.type == 'SOCIETE' ? lien.societe?.idService : null }" #planComptableField="ngModel" [(ngModel)]="localPlanComptable" (ngModelChange)="onPlanComptableChange()" isCreationDisabled="true" [required]="!rightService.isRoot()"></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': compte.invalid }">
							<label translate>comptabilite.lienCompteDetail.compte</label>
							<div>
								<autocomplete name="compte" type="compte" [filter]="{ typeReferentiel: rightService.isRoot() ? 'STANDARD' : 'LOCAL',listeTypes: [source],idPlanComptable: localPlanComptable?.idPlanComptable,isShowPlanComptable: !rightService.isRoot() }" #compte="ngModel" [(ngModel)]="lien.compte" (ngModelChange)="lien.compteAuxiliaire = null; findListeReglesTaxe();" (onSelectFromCreation)="onCreationCompte($event)" [disabled]="!rightService.isRoot() && !localPlanComptable" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': compteAuxiliaire.invalid }" *ngIf="source == 'TIERS'">
							<label translate>comptabilite.lienCompteDetail.compteAuxiliaire</label>
							<div>
								<autocomplete name="compteAuxiliaire" type="compteAuxiliaire" [filter]="{ idCompte: lien.compte?.idCompte,isCompteDisabled: true }" #compteAuxiliaire="ngModel" [(ngModel)]="lien.compteAuxiliaire" [disabled]="!lien.compte"></autocomplete>
							</div>
						</div>
						<div class="form-group" *ngIf="localPlanComptable">
							<label translate>common.pays</label>
							<div>
								<mat-chip-listbox>
									<mat-chip>{{ localPlanComptable.pays | display:'pays' }}</mat-chip>
								</mat-chip-listbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<div class="card detail" *ngIf="source == 'TAXE' && listeReglesTaxe">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.lienCompteDetail.regleTaxe.title</h2>
		</div>
		<div class="card-body card-padding regle-taxe">
			<fieldset [disabled]="!(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'creation') || rightService.isRoot()">
				<div class="alert alert-danger" *ngIf="isDateApplicationError && selectedRegleTaxe">
					<icon name="warning"></icon>
					<div>
						<strong translate>fiscalite.regleTaxe.regle.alerte.dateApplication.title</strong>
						<div *ngIf="selectedRegleTaxe.type == 'DATE'" translate>fiscalite.regleTaxe.regle.alerte.dateApplication.doublonDate</div>
						<div *ngIf="selectedRegleTaxe.type == 'DATE_GENRE'" translate>fiscalite.regleTaxe.regle.alerte.dateApplication.doublonDateGenre</div>
					</div>
				</div>
				<div class="row m-b-20">
					<div class="col-md-6">
						<div class="form-group" *ngIf="selectedRegleTaxe !== undefined" [ngClass]="{ 'has-error': regleTaxeField.invalid }">
							<label translate>comptabilite.lienCompteDetail.regleTaxe.itemShort</label>
							<div>
								<selectpicker name="regleTaxe" #regleTaxeField="ngModel" [(ngModel)]="selectedRegleTaxe" (ngModelChange)="onRegleTaxeChange()" [compareWith]="selectPickerService.getComparator('idRegle')" [disabled]="rightService.isRoot()" [placeholder]="'common.aucune' | translate">
									<mat-option *ngFor="let regleTaxe of listeReglesTaxe" [value]="regleTaxe">{{ regleTaxe?.tenant != null && !regleTaxe?.listeDetails?.length || !regleTaxe?.libelle ? ('common.aucune' | translate) : regleTaxe.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
				<ng-container *ngIf="selectedRegleTaxe">
					<ng-container *ngFor="let detail of listeRegleTaxeDetails; let index = index;">
						<div class="taxe-line hoverable" *ngIf="detail.idDetail || detail.key">
							<div class="form-group date" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>fiscalite.regleTaxe.regle.dateApplication</label>
								<div>
									<input type="text" class="form-control" data-test-id="dateApplication" name="dateApplication-{{ detail.idDetail || detail.key }}" #dateApplication="ngModel" [(ngModel)]="detail.dateApplication" datePicker [storeDate]="true" required/>
								</div>
							</div>
							<div class="form-group genre" *ngIf="selectedRegleTaxe.type == 'DATE_GENRE'" [ngClass]="{ 'has-error': genreField.invalid }">
								<label translate>fiscalite.regleTaxe.regle.genre</label>
								<div>
									<autocomplete name="genre-{{ detail.idDetail || detail.key }}" data-test-id="genre" type="genre" [filter]="{ idPays: lien?.compte?.pays?.idPays }" #genreField="ngModel" [(ngModel)]="detail.genre" required></autocomplete>
								</div>
							</div>
							<div class="taux">
								<div class="form-group" [ngClass]="{ 'has-error': tauxRecuperation.invalid }">
									<label translate>fiscalite.regleTaxe.regle.tauxRecuperation.item</label>
									<div class="input-group">
										<input type="text" name="tauxRecuperation-{{ detail.idDetail || detail.key }}" data-test-id="tauxRecuperation" #tauxRecuperation="ngModel" class="form-control" [(ngModel)]="detail.tauxRecuperation" [nNumber]="3" min="0" max="100" required/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'fiscalite.regleTaxe.regle.tauxRecuperation.info' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="action" *ngIf="(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'creation') && !rightService.isRoot()">
								<button mat-icon-button class="hovered-item" (click)="deleteRule(index); $event.stopPropagation();">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</ng-container>
					<div class="text-center p-10" *ngIf="!listeRegleTaxeDetails?.length">
						<h5 translate>fiscalite.regleTaxe.regle.empty</h5>
					</div>

					<div class="text-center">
						<div class="listview" *ngIf="(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'creation') && !rightService.isRoot()">
							<div class="lv-body">
								<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="lienCompteDetailForm.valid && addRule();">
									<h5><a [routerLink]="[]" translate>fiscalite.regleTaxe.actions.ajouterRule</a></h5>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</fieldset>
		</div>
	</div>

	<span class="pull-right">
		<button mat-stroked-button color="primary" *ngIf="(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'suppression') && lien.idLien > 0" (click)="deleteLienCompteDetail(lien,close);">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="TypeDroit.ADMIN_PLAN_COMPTABLE | right:'creation'" (click)="saveLienCompteDetail();" [disabled]="lienCompteDetailForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: lienCompteDetailForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>