import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { FieldKeyValueModule } from 'src/app/share/pipe/field-key-value/field-key-value.module';
import { PageContentModule } from 'src/app/share/components/page-content/page-content.module';
import { ComplexPageInfoComponent } from './complex-page-info.component';
import { ComplexPageActionsComponent } from './complex-page-actions.component';
import { ComplexPageElementsComponent } from './complex-page-elements.component';
import { ComplexPageComponent } from './complex-page.component';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { ComplexPageAlertsComponent } from './complex-page-alerts.component';
import { FormCustomizationModule } from 'src/app/share/components/entite/form-customization/form-customization.module';
import { DisplayModule } from 'src/app/share/pipe/display/display.module';
import { PersonalDataModule } from 'src/app/share/directive/personal-data/personal-data.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,PageContentModule,FieldKeyValueModule,PluralTranslateModule,FormCustomizationModule,DisplayModule,PersonalDataModule,IconModule],
	declarations: [ComplexPageComponent,ComplexPageElementsComponent,ComplexPageActionsComponent,ComplexPageInfoComponent,ComplexPageAlertsComponent],
	exports: [ComplexPageComponent]
})
export class ComplexPageModule { }
