import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class FormatInterfaceService {
	/** Liste des types de flux **/
	private readonly listeTypesFlux = ['AVENANT_CONTRAT','COMPTEUR_KM','COMPTEUR_KM_API_TNE','CONTRAT','FACTURE','RH','VEHICULE','RELEVE_CARTE','SINISTRE','ENTRETIEN'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Chargement d'une interface
	 */
	public loadInterface(idInterface: number): Observable<Result> {
		//Chargement de l'interface
		return this.http.post<Result>(`${environment.baseUrl}/controller/Fournisseur/loadInterface/${idInterface}`,null);
	}

	/**
	 * Suppression de l'interface
	 */
	public deleteInterface(formatInterface: any): Observable<Result> {
		//Suppression de l'interface
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteInterface/${formatInterface.idInterface}`);
	}

	/**
	 * Enregistrement de l'interface
	 */
	public saveInterface(formatInterface: any): Observable<Result> {
		//Enregistrement de l'interface
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveInterface`,formatInterface);
	}

	/**
	 * Liste des types de flux
	 */
	public getListeTypesFlux(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de flux
		return this.listeTypesFlux.map(code => ({
			code,
			libelle: this.translateService.instant('fournisseur.formatInterface.typeFlux.' + code)
		})).sort((a,b) => a.libelle.localeCompare(b.libelle));
	}
}