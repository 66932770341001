/**
 * Résultat d'une requête http
 */
export interface Result {
	/** Données **/
	codeErreur: TypeCodeErreur;
	data: any;
}

/**
 * Enumération représentant les codes d'erreur
 */
export enum TypeCodeErreur {
	/** Enumération **/
	NO_ERROR = 0,
	SAVE = 1,
	DELETE = 2,
	DOWNLOAD = 3,
	LOAD = 4,
	DOUBLON = 6,
	DROIT = 7
}