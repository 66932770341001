import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	templateUrl: './contrat-financement-loi-roulage-list-item.component.html'
})
export class ContratFinancementLoiRoulageListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private contratFinancementService: ContratFinancementService,private toastrService: ToastrService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteLoiRoulage = this.deleteLoiRoulage.bind(this);
	}

	/**
	 * Suppression de la loi de roulage
	 */
	public deleteLoiRoulage(loiRoulage: any,close?: Function) {
		let idxLoiRoulage: number;

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Recherche de la position de la loi de roulage dans le contrat de financement
					idxLoiRoulage = this.extraOptions.contratFinancement.listeLoisRoulage ? this.extraOptions.contratFinancement.listeLoisRoulage.findIndex(l => l.idLoiRoulage == loiRoulage.idLoiRoulage) : -1;

					//Suppression de la loi de roulage dans le contrat de financement
					this.extraOptions.contratFinancement.listeLoisRoulage.splice(idxLoiRoulage,1);

					//Enregistrement du contrat de financement
					this.contratFinancementService.saveContratFinancement(this.extraOptions.contratFinancement).pipe(first()).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Mise à jour du contrat de financement
								Object.assign(this.extraOptions.contratFinancement,{ ...result.data.contrat,listeEcheances: result.data.listeEcheances });

								//Suppression de l'objet
								this.onObjectChange(null);

								//Fermeture du formulaire d'édition (si nécessaire)
								close?.();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}