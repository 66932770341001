<div class="container-fluid">
	<form #planEntretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>vehicule.planEntretien.description</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>vehicule.planEntretien.libelle</label>
								<div>
									<input class="form-control" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="planEntretien.libelle" maxlength="300" required/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label translate>vehicule.planEntretien.actif.libelle</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="planEntretien.actif"><span translate>vehicule.planEntretien.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label><span translate>vehicule.planEntretien.remarque</span></label>
						<div>
							<textarea class="form-control" rows="3" name="remarque" [(ngModel)]="planEntretien.remarque"></textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<h4>
								<span translate>vehicule.planEntretien.frequence.item</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.planEntretien.frequence.info' | translate"></icon>
							</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
								<label translate>vehicule.planEntretien.duree</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="duree" nNumber min="0" max="240" class="form-control" #duree="ngModel" [(ngModel)]="planEntretien.duree" required/>
										<div class="input-group-addon no-border">
											<span translate>vehicule.planEntretien.mois</span>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': distance.invalid || unite.invalid }">
								<label translate>vehicule.planEntretien.distance</label>
								<div>
									<div class="row">
										<div class="col-xs-6">
											<input type="text" nNumber min="0" class="form-control" name="distance" #distance="ngModel" [(ngModel)]="planEntretien.distance" required/>
										</div>
										<div class="col-xs-6 p-l-5">
											<autocomplete name="unite" type="unite" #unite="ngModel" [(ngModel)]="planEntretien.unite" filter="DISTANCE" required></autocomplete>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deletePlanEntretien();" *ngIf="planEntretien.idPlanEntretien && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="planEntretienForm.invalid" (click)="savePlanEntretien();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(planEntretien?.idPlanEntretien ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="planEntretien.idPlanEntretien" [formDetectChanges]="{ doAction: close,isDirty: planEntretienForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>