import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { AttachmentModule } from 'src/app/share/components/attachment/attachment.module';
import { ComparatorMoreComponent } from './comparator-more.component';
import { ComparatorComponent } from './comparator.component';
import { ComparatorService } from './comparator.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,AttachmentModule,IconModule],
	declarations: [ComparatorComponent,ComparatorMoreComponent],
	providers: [ComparatorService]
})
export class ComparatorModule { }
