import { TranslateService } from '@ngx-translate/core';

import { TypeEntretienAddComponent } from 'src/app/components/vehicule/entretien/type-entretien-add.component';
import { Filter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types d'entretien
 */
export default class TypeEntretienOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.typeEntretien';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeReferentiel/listeTypesEntretienActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item?.libelle}${item?.frequence ? ' - ' + item.frequence + ' ' + translateService.instant('vehicule.typeEntretien.mois') : ''}${item?.reference ? ' (' + item.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,2)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.TypeEntretienItem';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTypeEntretien'; }

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'typeEntretien.add.item';

	/**
	 * Composant d'ajout
	 */
	addComponent: () => any = () => TypeEntretienAddComponent;

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ENTRETIEN;

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}
}