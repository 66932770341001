import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DemandeIdentificationDesignationComponent } from './demande-identification-designation.component';
import { DemandeIdentificationEditComponent } from './demande-identification-edit.component';
import { DemandeIdentificationListItemComponent } from './demande-identification-list-item.component';
import { DemandeIdentificationListComponent } from './demande-identification-list.component';
import { DemandeIdentificationComponent } from './demande-identification.component';
import { DemandeIdentificationService } from './demande-identification.service';

@NgModule({
	imports: [ShareModule],
	declarations: [DemandeIdentificationListComponent,DemandeIdentificationListItemComponent,DemandeIdentificationComponent,DemandeIdentificationEditComponent,DemandeIdentificationDesignationComponent],
	providers: [DemandeIdentificationService]
})
export class DemandeIdentificationModule { }