import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { FormCustomization,FormCustomizationField } from 'src/app/domain/common/entite/form-customization';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FormCustomizationService } from './form-customization.service';

@Component({
	selector: 'form-customization-edit',
	templateUrl: './form-customization-edit.component.html'
})
export class FormCustomizationEditComponent extends PageContentItem implements OnInit {
	/** Fiche personnalisée **/
	@Input() formCustomization: FormCustomization;

	/** Nom de la classe **/
	@Input() className: string;

	/** Données **/
	@Input() data?: any;

	/** Identifiant de l'objet **/
	@Input() idObject?: number;

	/**
	 * Constructeur
	 */
	constructor(private formCustomizationService: FormCustomizationService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la présence d'une fiche personnalisée
		if (!this.formCustomization?.idCustomization) {
			//Initialisation de la fiche personnalisée
			this.formCustomization = {
				entite: this.className,
				listeFields: []
			}
		} else
			//Duplication de l'objet
			this.formCustomization = cloneDeep(this.formCustomization);
	}

	/**
	 * Fermeture de la fiche personnalisée
	 */
	onClose(listeFields?: Array<FormCustomizationField>) {
		//Vérification de la présence d'une liste de champs
		if (listeFields)
			//Enregistrement de la fiche personnalisée
			this.saveFormCustomization(listeFields);
		else
			//Fermeture de la fiche personnalisée
			this.close(null);
	}

	/**
	 * Enregistrement de la fiche personnalisée
	 */
	saveFormCustomization(listeFields: Array<FormCustomizationField>) {
		//Définition des champs de la fiche personnalisée
		this.formCustomization.listeFields = listeFields;

		//Enregistrement de la fiche personnalisée
		this.formCustomizationService.saveFormCustomization(this.formCustomization).subscribe({
			next: (result: Result) => {
				//Vérification de la présence d'une erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la fiche personnalisée
					this.formCustomization = result.data.entiteCustomization;

					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de la fiche personnalisée
					this.close(this.formCustomization);
				}
			}
		});
	}
}
