import pays from './pays';
import tenant from './tenant';
import fournisseurInterface from './fournisseur-interface';
import user from './user';
import vehicule from './vehicule';
import pool from './pool';
import userCategorie from './user-categorie';
import societe from './societe';
import service from './service';
import revendeur from './revendeur';
import clientCRM from './client-crm';
import contratCRM from './contrat-crm';
import articleCRM from './article-crm';
import fournisseurMetier from './fournisseur-metier';
import devise from './devise';
import fournisseurNature from './fournisseur-nature';
import fournisseur from './fournisseur';
import typeAmenagement from './type-amenagement';
import planComptable from './plan-comptable';
import compte from './compte';
import compteAuxiliaire from './compte-auxiliaire';
import affectation from './affectation';
import demandeVehicule from './demande-vehicule';
import contratFinancement from './contrat-financement';
import reservation from './reservation';
import typeEntretien from './type-entretien';
import facture from './facture'
import grilleAttribution from './grille-attribution'
import equipement from './equipement';
import modele from './modele';
import connecteurType from './connecteur-type';
import typeSinistre from './type-sinistre';
import reponseCotation from './reponse-cotation';
import contravention from './contravention';
import demandeIdentification from './demande-identification';
import demandeCotation from './demande-cotation';
import groupe from './groupe';
import typeFinancement from './type-financement';
import unite from './unite';
import marque from './marque';
import carburant from './carburant';
import genre from './genre';
import typeCarrosserie from './type-carrosserie';
import customReferentiel from './custom-referentiel';
import offre from './offre';
import posteCharge from './poste-charge';
import societeReferentiel from './societe-referentiel';
import conducteur from './conducteur';
import typeInfraction from './type-infraction';
import compteService from './compte-service'
import database from './database';
import planEntretien from './plan-entretien';
import typeTransmission from './type-transmission';
import typeBoite from './type-boite';
import workflowMotif from './workflow-motif';
import fournisseurContact from './fournisseur-contact';
import regleTaxe from './regle-taxe'
import structureExportRubrique from './structure-export-rubrique';
import profil from './profil';
import extraction from './extraction';

//Export des options
export {
	pays,
	tenant,
	fournisseurInterface,
	user,
	vehicule,
	pool,
	userCategorie,
	service,
	societe,
	revendeur,
	clientCRM,
	contratCRM,
	articleCRM,
	fournisseurMetier,
	devise,
	fournisseur,
	fournisseurNature,
	typeAmenagement,
	planComptable,
	compte,
	compteAuxiliaire,
	affectation,
	demandeVehicule,
	contratFinancement,
	reservation,
	typeEntretien,
	facture,
	grilleAttribution,
	equipement,
	modele,
	connecteurType,
	typeSinistre,
	reponseCotation,
	contravention,
	demandeIdentification,
	demandeCotation,
	groupe,
	typeFinancement,
	unite,
	marque,
	carburant,
	genre,
	typeCarrosserie,
	customReferentiel,
	offre,
	posteCharge,
	societeReferentiel,
	conducteur,
	typeInfraction,
	compteService,
	database,
	planEntretien,
	typeBoite,
	typeTransmission,
	workflowMotif,
	fournisseurContact,
	regleTaxe,
	structureExportRubrique,
	profil,
	extraction
};