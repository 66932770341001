import { AfterContentChecked,ChangeDetectorRef,Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';

import { AuthentificationConfigurationService } from './authentification-configuration.service';
import { AppState } from 'src/app/domain/appstate';

@Component({
	selector: 'authentification-configuration',
	templateUrl: './authentification-configuration.component.html'
})
export class AuthentificationConfigurationComponent implements OnInit,AfterContentChecked {
	/** Configuration **/
	configuration: any;

	/** Identifiant du tenant **/
	idTenant: number;

	/** Etat du composant **/
	disabled?: boolean;

	/** URL de l'application **/
	appliUrl: string;

	/** Résultat **/
	result: { configuration: any };

	/** Liste des types d'authentification **/
	public listeTypesAuthentification: Array<{ code: string,libelle: string }>;

	/** Liste des modes d'authentification pour le SAML2 **/
	public listeSaml2Types: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<AuthentificationConfigurationComponent>,public authentificationConfigurationService: AuthentificationConfigurationService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de la configuration
		this.configuration = Object.assign({},this.configuration);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la configuration
	 */
	saveConfiguration() {
		//Définition du résultat
		this.result = {
			configuration: this.configuration
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la configuration
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}