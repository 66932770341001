<div class="pull-left lv-avatar">{{ data.type == 'TECHNIQUE' ? 'CT' : (data.type == 'ANTIPOLLUTION' ? 'CA' : '') }}</div>
<div class="lv-title">
	<a [routerLink]="['/Vehicule/loadControleReglementaire',data.idControleReglementaire]">{{ (data.libelle || ('common.nonDefini' | translate)) + ' - ' + ('vehicule.controleReglementaire.type.'+data.type | translate) }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
	<li *ngIf="data.pays"><span translate>vehicule.controleReglementaire.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays?.libelle" [ngClass]="'fi-'+(data.pays?.code2?.toLowerCase() != 'ja' ? data.pays?.code2.toLowerCase() : 'jp')"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idControleReglementaire">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteControleReglementaire();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>