<form #templateMailObjetForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>templateMail.selectionPreview.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': referenceField.invalid }">
			<label for="reference" translate>templateMail.selectionPreview.valeurReference</label>
			<div>
				<autocomplete name="autocomplete" [options]="autocompleteOptions" #referenceField="ngModel" [(ngModel)]="reference" [placeholder]="autocompleteOptions?.title | translate" required></autocomplete>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="reference = null; bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="bsModalRef.hide()" [disabled]="templateMailObjetForm.invalid">
			<span translate>actions.valider</span>
		</button>
	</div>
</form>