import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AutocompleteOptions } from './autocomplete';

@Component({
	selector: 'autocomplete-line-item',
	templateUrl: './autocomplete-line-item.component.html'
})
export class AutocompleteLineItemComponent {
	/** Elèment à afficher **/
	@Input() item: any;

	/** Options de l'autocomplete **/
	@Input() options: AutocompleteOptions;

	/** Texte recherché **/
	@Input() query: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(public translateService: TranslateService,public datePipe: DatePipe,public decimalPipe: DecimalPipe) {}
}