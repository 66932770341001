import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'adresse-add',
	templateUrl: './adresse-add.component.html'
})
export class AdresseAddComponent {
	/** Mode édition **/
	@Input() isEdition: boolean;

	/** Adresse **/
	@Input() adresse?: any = { typeSaisie: 'MANUEL' };

	/** Mode **/
	@Input() searchMode: 'VILLE' | 'ADRESSE' | 'FULL';

	/** Taille de la popup **/
	public static modalSize: string = 'modal-lg';

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<AdresseAddComponent>) { }

	/**
	 * Femeture de la popup
	 */
	close() {
		//Définition de l'adresse
		this.adresse = null;

		//Fermeture de la popup
		this.bsModalRef.hide()
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem(): void {
		let villeLabel: string;
		let adresseLabel: string;

		//Création des libellés
		villeLabel = `${this.adresse?.ville || ''}, ${this.adresse?.pays || ''}`;
		adresseLabel = `${this.adresse?.numero || ''} ${this.adresse?.rue || ''}, ${this.adresse?.codePostal || ''}`;

		//Définition du libellé complet
		this.adresse.label = `${this.searchMode != 'VILLE' ? adresseLabel + ' ' : ''}${villeLabel}`;

		//Définition du code du pays
		this.adresse.codePays = this.adresse.paysObject.code2;

		//Fermeture de la popup
		this.bsModalRef.hide()
	}
}