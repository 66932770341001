import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import moment from 'moment';
import { filter,first } from 'rxjs/operators';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { ContraventionService } from './contravention.service';

@Component({
	selector: 'contravention-edit',
	templateUrl: './contravention-edit.component.html'
})
export class ContraventionEditComponent extends PageContentItem implements OnInit {
	/** Contravention **/
	@Input() contravention: any;

	/** Enregistrement de la contravention **/
	@Input() saveContravention: (contravention: any,isPublish: boolean,close?: Function) => void;

	/** Suppression de la contravention **/
	@Input() deleteContravention: Function;

	/** Liste des types **/
	public listeTypesInfraction: Array<{ code: string,libelle: string }>;

	/** Liste des statuts **/
	public listeStatuts: Array<{ code: string,libelle: string }>;

	/** Types de droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Copie de la contravention **/
	public savedContravention: any;

	/**
	 * Constructeur
	 */
	constructor(private contraventionService: ContraventionService,public vehiculeService: VehiculeService,private autocompleteService: AutocompleteService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types d'infraction
		this.listeTypesInfraction = this.contraventionService.getListeTypesInfraction();

		//Récupération de la liste des statuts
		this.listeStatuts = this.contraventionService.getListeStatuts();

		//Copie de la contravention
		this.savedContravention = cloneDeep(this.contravention);
	}

	/**
	 * Interception d'une modification du pays
	 */
	onPaysChange() {
		//Suppression du motif
		this.contravention.motif = null;
	}

	/**
	 * Vérification de la date d'avis
	 */
	isDateAvisValid(): boolean {
		//Validation de la date d'avis par rapport à la date de la contravention
		return !this.contravention.dateAvis || !this.contravention.dateContravention || this.contravention.dateAvis >= moment.utc(this.contravention.dateContravention).startOf('day').valueOf();
	}

	/**
	 * Affichage de la sélection du motif
	 */
	showMotifSelection() {
		//Affichage de la sélection du type d'infraction
		this.autocompleteService.showSearch({
			label: this.translateService.instant('infraction.contravention.motif'),
			type: 'typeInfraction'
		}).pipe(filter(typeInfraction => !!typeInfraction)).subscribe({
			next: typeInfraction => {
				//Mise à jour de la contravention
				this.contravention.motif = typeInfraction;
			}
		})
	}

	/**
	 * Interception de la modification du type
	 */
	onTypeChange() {
		//Vérification du type
		if (this.contravention.type != 'AMENDE')
			//Suppression du motif
			delete this.contravention.motif;
	}

	/**
	 * Vérification de la modification de la contravention
	 */
	isDirty(): boolean {
		//Vérification de la modification de la contravention
		return !isEqual(this.contravention,this.savedContravention);
	}

	/**
	 * Intereception d'une modification de la contravention d'origine
	 */
	onContraventionOrigineChange() {
		//Vérification de la présence d'une contravention d'origine
		if (this.contravention.contraventionOrigine && this.contravention.majoration) {
			//Chargement de la contravention d'origine
			this.contraventionService.loadContravention(this.contravention.contraventionOrigine.idContravention).pipe(first()).subscribe({
				next: result => {
					//Récupération des données de la contravention grâce à celle d'origine
					this.contravention.vehicule = result.data.contravention.vehicule;
					this.contravention.lieu = result.data.contravention.lieu;
					this.contravention.dateContravention = result.data.contravention.dateContravention;
					this.contravention.motif = result.data.contravention.motif;

					//Vérification de la désignation de la contravention d'origine
					if (result.data.contravention.designation) {
						//Récupération de la désignation d'origine
						this.contravention.designation = result.data.contravention.designation;
						this.contravention.typeDesignation = result.data.contravention.typeDesignation;

						//Réinitialisation de l'identifiant de la désignation
						delete this.contravention.designation.idDesignation;
					}
				}
			})
		} else {
			//Suppression des données
			this.contravention.vehicule = null;
			this.contravention.lieu = null;
			this.contravention.dateContravention = null;
			this.contravention.motif = null;
		}
	}
}