import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TaxeListItemComponent } from './taxe-list-item.component';

@Component({
	templateUrl: './taxe-list.component.html'
})
export class TaxeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TaxeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TaxeListItemComponent>({
			uri: `/controller/Taxe/filtreTaxes`,
			title: this.translateService.instant('fiscalite.taxe.liste.title'),
			component: TaxeListItemComponent,
			listeFilters: [{
				clef: 'pays',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'pays',
					fieldForDefault: 'libelle,code2'
				},
				isDefault: true
			},{
				clef: 'date',
				title: this.translateService.instant('search.dateApplication'),
				type: TypeFilter.DATE
			}],
			defaultOrder: 'pays.libelle,-date',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/Fiscalite/loadTaxe/0')
		});
	}
}