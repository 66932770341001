import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { mapEntites } from './grille-attribution.entites';
import { GrilleAttributionService } from './grille-attribution.service';

@Component({
	templateUrl: './grille-attribution-list-item.component.html'
})
export class GrilleAttributionListItemComponent extends ListViewItem<any> implements IListEntity {
	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionService: GrilleAttributionService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Suppression de l'objet
	 */
	deleteGrilleAttribution() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.grilleAttributionService.deleteGrilleAttribution(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.GrilleAttribution',this.data.idGrilleAttribution);
	}

	/**
	 * Récupération des groupes associés à une grille d'attribution
	 */
	getListeGroupes(entity: string): string {
		//Retour des libellés des groupes
		return this.data.listeGroupes?.filter(groupe => groupe?.entity == entity).map(groupe => groupe?.libelle).join(', ') || '';
	}
}