import { Injectable,Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

import * as validationOptions from './options';
import { ValidationEntiteOptions } from 'src/app/domain/workflow/validation';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

@Injectable()
export class ValidationService {
	/**
	 * Constructeur
	 */
	constructor(private injector: Injector,private translateService: TranslateService,private datePipe: DatePipe,private messagingService: MessagingService) {}

	/**
	 * Récupération de la liste des entités
	 */
	public getListeEntites(): Array<ValidationEntiteOptions> {
		//Parcours des options et création des entités
		return Object.keys(validationOptions).map(key => new validationOptions[key](this.injector,this.translateService,this.datePipe,this.messagingService));
	}
}