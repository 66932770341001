import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { filter,first,map } from 'rxjs/operators';

import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { ConducteurService } from 'src/app/components/vehicule/conducteur/conducteur.service';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ContraventionService } from './contravention.service';

@Component({
	selector: 'contravention-designation',
	templateUrl: './contravention-designation.component.html'
})
export class ContraventionDesignationComponent extends PageContentItem implements OnInit {
	/** Contravention **/
	@Input() contravention: any;

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Enregistrement de la contravention **/
	@Input() saveContravention: (contravention: any,isPublish: boolean,close?: Function,isSaveDesignation?: boolean) => void;

	/** Modification de la désignation **/
	@Input() editDesignation: Function;

	/** Suppression de la désignation **/
	@Input() deleteDesignation: Function;

	/** Liste des types de désignation **/
	public listeTypesDesignation: Array<{ code: string,_type?: string,libelle: string }>;

	/** Indicateurs de présence d'une adresse ou d'un permis de conduire **/
	private mapData: { isAdresse: boolean,isPermisConduire: boolean,adressePerso: any,permisConduire: any } = {
		isAdresse: false,
		isPermisConduire: false,
		adressePerso: null,
		permisConduire: null
	};

	/**
	 * Constructeur
	 */
	constructor(private contraventionService: ContraventionService,private affectationService: AffectationService,private conducteurService: ConducteurService,private confirmService: ConfirmService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de la contravention
		this.contravention = cloneDeep(this.contravention);

		//Récupération de la liste des types de désignation
		this.listeTypesDesignation = this.contraventionService.getListeTypesDesignation();

		//Création de la désignation éventuelle
		this.contravention.designation = this.contravention.designation || {};

		//Définition du type de désignation
		this.contravention.typeDesignation = 'CONDUCTEUR';

		//Recherche de l'utilisateur actuellement affecté
		this.isEdition && this.contravention.typeDesignation == 'CONDUCTEUR' && !this.contravention.designation.user && this.findCurrentConducteur();

		//Recherche du conducteur associé à la désignation
		this.isEdition && this.contravention.typeDesignation == 'CONDUCTEUR' && this.contravention.designation.user && this.searchPermisAndAdresseConducteur(this.contravention.designation.user.idUser,true);
	}

	/**
	 * Affichage de l'historique des affectations
	 */
	showListeAffectations() {
		//Affichage de la liste des affectations du véhicule
		this.affectationService.showListeAffectations(this.contravention.vehicule).subscribe({
			next: affectation => {
				//Vérification de l'affectation sélectionnée
				if (affectation) {
					//Mise à jour de la désignation
					this.contravention.designation.user = affectation.user;

					//Déclenchement d'un changement de conducteur
					this.onConducteurChange();
				}
			}
		});
	}

	/**
	 * Affichage du permis de conduire
	 */
	showPermisConduire() {
		//Affichage du permis de conduire
		this.conducteurService.showPermisConduire(this.contravention.designation?.permisConduire || {},!this.isEdition).pipe(filter(p => !!p)).subscribe(permisConduire => {
			//Mise à jour du permis de conduire
			this.contravention.designation.permisConduire = permisConduire;
		});
	}

	/**
	 * Interception du changement de conducteur
	 */
	onConducteurChange() {
		//Recherche d'un permis de conduire et de l'adresse
		this.contravention.designation.user && this.searchPermisAndAdresseConducteur(this.contravention.designation.user.idUser);
	}

	/**
	 * Recherche du conducteur actuellement affecté au véhicule
	 */
	private findCurrentConducteur() {
		//Recherche de l'affectation du véhicule pour la date de la contravention
		this.affectationService.findAffectationForDate(this.contravention.vehicule.idVehicule,this.contravention.dateContravention).pipe(
				first(),
				filter(result => result.data?.affectation?.user?.idUser),
				map(result => result.data.affectation.user)
			).subscribe({
				next: user => {
					//Mise à jour de la désignation
					this.contravention.designation.user = user;

					//Déclenchement d'un changement de conducteur
					this.onConducteurChange();
				}
			});
	}

	/**
	 * Recherche du permis de conduire et de l'adresse du conducteur
	 */
	searchPermisAndAdresseConducteur(idUser: number,isWithoutUpdate: boolean = false) {
		//Recherche du permis de conduire et de l'adresse
		this.conducteurService.findPermisConducteur(idUser).subscribe({
			next: result => {
				//Vérification du retour
				if (result?.data) {
					//Vérification de la mise à jour
					if (!isWithoutUpdate)
						//Définition du permis de conduire
						this.contravention.designation.permisConduire = result.data.permisConduire;

					//Vérification du permis de conduire
					if (this.contravention.designation.permisConduire) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant
							this.contravention.designation.permisConduire.idPermis = null;

						//Mémorisation du permis de conduire
						this.mapData.permisConduire = result.data.permisConduire;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = true;
					} else {
						//Ré-initialisation du permis de conduire
						this.mapData.permisConduire = null;

						//Indicateur de présence du permis de conduire
						this.mapData.isPermisConduire = false;
					}

					//Vérification de l'adresse du conducteur
					if (result.data.user?.userDescription?.adressePerso) {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Mise à jour de l'adresse
							this.contravention.designation.adresse = result.data.user.userDescription.adressePerso;

						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'identifiant de l'adresse
							delete this.contravention.designation.adresse.idAdresse;

						//Mémorisation de l'adresse
						this.mapData.adressePerso = result.data.user.userDescription.adressePerso;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = true;
					} else {
						//Vérification de la mise à jour
						if (!isWithoutUpdate)
							//Suppression de l'adresse
							this.contravention.designation.adresse = null;

						//Ré-initialisation de l'adresse
						this.mapData.adressePerso = null;

						//Indicateur de présence de l'adresse
						this.mapData.isAdresse = false;
					}
				}
			}
		});
	}

	/**
	 * Enregistrement de la désignation
	 */
	public saveDesignation() {
		let typeDesignation: { code: string,_type?: string,libelle: string };
		let doSave: (isPublish: boolean) => void

		//Recherche du type de désignation
		typeDesignation = this.listeTypesDesignation.find(t => t.code == this.contravention.typeDesignation);

		//Vérification du type de désignation
		if (typeDesignation._type)
			//Définition du type
			this.contravention.designation._type = typeDesignation._type;

		//Définition de la méthode d'enregistrement
		doSave = (isPublish: boolean) => {
			//Enregistrement de la contravention
			this.saveContravention(this.contravention,isPublish,this.close,true);
		}

		//Vérification du mode de désignation
		if (this.contravention.typeDesignation == 'CONDUCTEUR') {
			//Comparaison des adresses
			this.mapData.isAdresse = isEqual(omit(this.contravention.designation.adresse,['idAdresse']),omit(this.mapData.adressePerso,['idAdresse']));

			//Comparaison des permis de conduire
			this.mapData.isPermisConduire = isEqual(omit(this.contravention.designation.permisConduire,['idPermis','user']),omit(this.mapData.permisConduire,['idPermis','user']));
		}

		//Vérification de l'absence d'adresse ou du permis de conduire
		if (this.contravention.typeDesignation == 'CONDUCTEUR' && (!this.mapData.isAdresse || !this.mapData.isPermisConduire)) {
			//Affichage du message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('demandeIdentification.designation.confirmation.title'),{
				withTitle: true,
				buttonType: 'YES_NO',
				actionColor: 'primary',
				alerteInfo: `
					<p></p>
					<div>
						<span>${this.translateService.instant('demandeIdentification.designation.confirmation.message')}</span>
						<ul class="p-t-40">
							${!this.mapData.isAdresse && this.contravention.designation.adresse ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.adressePersonnelle')}</b>&#160;:&#160;<span>${this.contravention.designation.adresse.label}</span></li>` : ''}
							${!this.mapData.isPermisConduire && this.contravention.designation.permisConduire ? `<li><b>${this.translateService.instant('demandeIdentification.designation.confirmation.permisConduire')}</b>&#160;:&#160;<span>${this.contravention.designation.permisConduire.reference}</span></li>` : ''}
						</ul>
					</div>
				`
			}).subscribe({
				next: isConfirmed => {
					//Réalisation de l'enregistrement
					doSave(isConfirmed);
				}
			});
		} else {
			//Enregistrement de la contravention
			doSave(false);
		}
	}
}