import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { DataCustomizationService } from './data-customization.service';
import { DataCustomizationService as SharedDataCustomizationService } from 'src/app/share/components/entite/data-customization/data-customization.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	selector: 'data-customization-ref-value-add',
	templateUrl: './data-customization-ref-value-add.component.html'
})
export class DataCustomizationRefValueAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Valeur **/
	customRefValue: any = {};

	/** Personnalisation **/
	dataCustomization: any;

	/** Liste des entités disponibles **/
	listeEntites: Array<any>;

	/** Taille de la popup **/
	public static modalSize: string = 'modal-xs';

	/**
	 * Constructeur
	 */
	constructor(private dataCustomizationService: DataCustomizationService,private sharedDataCustomizationService: SharedDataCustomizationService,private translateService: TranslateService,private toastrService: ToastrService
			,public bsModalRef: BsModalRef<DataCustomizationRefValueAddComponent>,private extractionService: ExtractionService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération du paramétrage lié à l'entité
		this.sharedDataCustomizationService.loadDataCustomizationForEntite(this.filter.entite).pipe(first()).subscribe({
			next: dataCustomization => this.dataCustomization = dataCustomization
		});

		//Récupération de la liste des entités disponibles
		this.extractionService.getListeEntites('CUSTOM_FORM').pipe(first()).subscribe({
			next: listeEntites => this.listeEntites = listeEntites
		});

		//Mise à jour de la valeur courante
		Object.assign(this.customRefValue,{
			entite: this.filter.entite,
			typeReferentiel: this.filter.typeReferentiel
		});
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de la valeur
		this.dataCustomizationService.saveCustomRefValue(this.dataCustomization?.idDataCustomizationEntite || 0,this.customRefValue).pipe(first()).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de la valeur
					this.addedItem = result.data.customRefValue;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			},
			error: () => {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
