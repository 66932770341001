import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';

import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurTypeService } from './connecteur-type.service';
import { ConnecteurService } from '../connecteur.service'

@Component({
	selector: 'connecteur-type',
	templateUrl: './connecteur-type.component.html'
})
export class ConnecteurTypeComponent implements OnInit {
	/** Elément courant **/
	@Input() connecteurType: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Récupération de la liste des types de connecteur **/
	public listeTypesConnecteur: Array<{ code: string,libelle: string }>;

	/** Récupération de la liste des types d'exécution **/
	public listeTypesExecution: Array<{ code: string,libelle: string }>;

	/** Récupération de la liste des types de visibilité **/
	public listeTypesVisibility: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private connecteurTypeService: ConnecteurTypeService,public rightService: RightService,private connecteurService: ConnecteurService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de connecteur
		this.listeTypesConnecteur = this.connecteurTypeService.getListeTypesConnecteur();

		//Récupération de la liste des types d'exécution
		this.listeTypesExecution = this.connecteurService.getListeTypesExecution();

		//Récupération de la liste des types de Visibilité
		this.listeTypesVisibility = this.connecteurTypeService.getListeTypesVisibility();
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}