<div class="pull-left lv-avatar">{{ (data?.service?.reference || data?.societe?.reference).substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addImputation?.(data,deleteImputation)">{{ data.societe.libelle + (data.service ? ' - ' + data.service.libelle : '') }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idImputation">
		<li><span translate>vehicule.imputation.dateApplication</span>&#160;:&#160;<span *ngIf="data?.dateApplication">{{ data.dateApplication | date:'short' }}</span><span *ngIf="!data?.dateApplication" translate>common.aucune</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') || data?.service && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'consultation')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteImputation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="data?.service && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'consultation')"><a [routerLink]="[]" (click)="goToService()"><span translate>vehicule.actions.accederService</span></a></li>
		</ul>
	</div>
</div>