<form #extractionUserSchedulerForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title">
				<span translate>extraction.diffusion.title</span>
			</h2>
		</div>
		<extraction-diffusion-content #extractionDiffusionContent source="EXTRACTION" [userScheduler]="userScheduler" [extraction]="extraction" [entiteInformations]="entiteInformations" (close)="close($event)"></extraction-diffusion-content>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteUserScheduler?.(close);" *ngIf="userScheduler.idUserScheduler && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="extractionDiffusionContent.saveUserScheduler();" *ngIf="null | right:'creation'" [disabled]="extractionUserSchedulerForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: extractionUserSchedulerForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>