import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ListView } from 'src/app/domain/common/list-view';
import { ConducteurEntretienListItemComponent } from './conducteur-entretien-list-item.component';

@Component({
	selector: 'conducteur-entretien-edit',
	templateUrl: './conducteur-entretien-edit.component.html'
})
export class ConducteurEntretienEditComponent extends PageContentItem {
	/** Entretien **/
	@Input() entretien: any;

	/** Résumé **/
	@Input() resume: { nbEntretiens: number };

	/** Nombre d'interventions **/
	@Input() nbInterventions: number;

	/** Liste **/
	@Input() liste: ListView<any,ConducteurEntretienListItemComponent>;

	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Fermeture de l'entretien après enregistrement
	 */
	onContentClose(data: any) {
		//Fermeture du formulaire d'édition
		this.close({ entretien: data || null });
	}
}