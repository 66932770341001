import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { SocieteAddComponent } from './societe-add.component';
import { SocieteListItemComponent } from './societe-list-item.component';
import { SocieteListComponent } from './societe-list.component';
import { SocieteComponent } from './societe.component';
import { SocieteService } from './societe.service';

@NgModule({
	imports: [ShareModule],
	declarations: [SocieteListComponent,SocieteListItemComponent,SocieteComponent,SocieteAddComponent],
	providers: [SocieteService]
})
export class SocieteModule { }