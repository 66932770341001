<form #posteChargeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.posteCharge.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': posteChargeForm.controls['posteCharge']?.invalid }">
							<label translate>comptabilite.compte.posteCharge</label>
							<div>
								<autocomplete *ngIf="!lienPosteCharge?.idLien" name="posteCharge" type="posteCharge" #posteCharge="ngModel" [(ngModel)]="lienPosteCharge.posteCharge" (ngModelChange)="onPosteChargeChange($event);" required></autocomplete>
								<autocomplete *ngIf="lienPosteCharge?.idLien && lienPosteCharge.nature" name="nature" type="fournisseurNature" #posteCharge="ngModel" [(ngModel)]="lienPosteCharge.nature" [disabled]="true"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="m-t-20">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut1</label>
								<div>
									<input type="text" name="attribut1" #attribut1="ngModel" class="form-control" [(ngModel)]="lienPosteCharge.attribut1" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut2</label>
								<div>
									<input type="text" name="attribut2" #attribut2="ngModel" class="form-control" [(ngModel)]="lienPosteCharge.attribut2" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut3</label>
								<div>
									<input type="text" name="attribut3" #attribut3="ngModel" class="form-control" [(ngModel)]="lienPosteCharge.attribut3" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut4</label>
								<div>
									<input type="text" name="attribut4" #attribut4="ngModel" class="form-control" [(ngModel)]="lienPosteCharge.attribut4" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<div class="card detail" *ngIf="lienPosteCharge?.compte?.type == 'TAXE' && (lienPosteCharge.nature || lienPosteCharge.posteCharge)">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.regleTaxe.title</h2>
		</div>
		<div class="card-body card-padding regle-taxe">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row m-b-20" *ngIf="!!selectedRegleTaxe">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': regleTaxeField.invalid }">
							<label translate>comptabilite.compte.regleTaxe</label>
							<div>
								<selectpicker name="regleTaxe" #regleTaxeField="ngModel" [(ngModel)]="selectedRegleTaxe" (ngModelChange)="onRegleTaxeChange()" [compareWith]="selectPickerService.getComparator('idRegle')" [placeholder]="'common.aucune' | translate">
									<mat-option *ngFor="let regleTaxe of listeReglesTaxe" [value]="regleTaxe">{{ regleTaxe?.tenant != null && !regleTaxe?.listeDetails?.length || !regleTaxe?.libelle ? ('common.aucune' | translate) : regleTaxe.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
				<ng-container *ngIf="selectedRegleTaxe">
					<ng-container *ngFor="let detail of listeRegleTaxeDetails; let index = index;">
						<div class="taxe-line hoverable" *ngIf="detail.idDetail || detail.key">
							<div class="form-group date" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>fiscalite.regleTaxe.regle.dateApplication</label>
								<div>
									<input type="text" class="form-control" data-test-id="dateApplication" name="dateApplication-{{ detail.idDetail || detail.key }}" #dateApplication="ngModel" [(ngModel)]="detail.dateApplication" datePicker format="date" required/>
								</div>
							</div>
							<div class="form-group genre" *ngIf="selectedRegleTaxe.type == 'DATE_GENRE'" [ngClass]="{ 'has-error': genreField.invalid }">
								<label translate>fiscalite.regleTaxe.regle.genre</label>
								<div>
									<autocomplete name="genre-{{ detail.idDetail || detail.key }}" data-test-id="genre" type="genre" [filter]="{ idPays: lienPosteCharge?.compte?.pays?.idPays }" #genreField="ngModel" [(ngModel)]="detail.genre" required></autocomplete>
								</div>
							</div>
							<div class="form-group taux" [ngClass]="{ 'has-error': tauxRecuperation.invalid }">
								<label translate>fiscalite.regleTaxe.regle.tauxRecuperation.item</label>
								<div>
									<div class="input-group">
										<input type="text" name="tauxRecuperation-{{ detail.idDetail || detail.key }}" data-test-id="tauxRecuperation" #tauxRecuperation="ngModel" class="form-control" [(ngModel)]="detail.tauxRecuperation" [nNumber]="3" min="0" max="100" required/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'fiscalite.regleTaxe.regle.tauxRecuperation.info' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="action" *ngIf="selectedRegleTaxe?.idRegle && (null | right:'creation')">
								<button mat-icon-button class="hovered-item" (click)="deleteRule(index); $event.stopPropagation();">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</ng-container>
					<div class="text-center p-10" *ngIf="!listeRegleTaxeDetails?.length && selectedRegleTaxe?.listeDetails?.length">
						<h5 translate>fiscalite.regleTaxe.regle.empty</h5>
					</div>

					<div class="text-center">
						<div class="listview" *ngIf="(selectedRegleTaxe?.listeDetails?.length && !selectedRegleTaxe.tenant) && (null | right:'creation')">
							<div class="lv-body">
								<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="posteChargeForm.valid && addRule();">
									<h5><a [routerLink]="[]" translate>fiscalite.regleTaxe.actions.ajouterRule</a></h5>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteLienPosteCharge(lienPosteCharge,close);" *ngIf="deleteLienPosteCharge && lienPosteCharge?.idLien && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienPosteCharge();" *ngIf="null | right:'creation'" [disabled]="posteChargeForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: posteChargeForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>