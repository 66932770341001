<div class="modal-header">
	<h4 class="modal-title" translate>connecteur.execution.history.title</h4>
</div>
<div class="modal-body">
	<div class="listview lv-bordered m-t-10">
		<div class="lv-body">
			<div class="lv-item media" *ngFor="let history of execution.listeExecutionHistoricals; let index = index;">
				<div class="media-body">
					<div class="pull-left lv-avatar">{{ index + 1 }}</div>
					<div class="lv-title clickable"><a [routerLink]="[]" (click)="showLogs(history)">{{ history.apiSession.dateCreation | date:'short' }}</a></div>
					<ul class="lv-attrs">
						<li *ngIf="history.apiSession?.statut"><span translate>connecteur.import.statut.item</span>&#160;:&#160;<span [translate]="'connecteur.import.statut.'+history.apiSession.statut"></span></li>
						<li *ngIf="history.apiSession?.nbCreations"><span translate>connecteur.import.resultat.nbCreations</span>&#160;:&#160;{{ history.apiSession.nbCreations | number }}</li>
						<li *ngIf="history.apiSession?.nbUpdates"><span translate>connecteur.import.resultat.nbUpdates</span>&#160;:&#160;{{ history.apiSession.nbUpdates | number }}</li>
						<li *ngIf="history.apiSession?.nbIgnores"><span translate>connecteur.import.resultat.nbIgnores</span>&#160;:&#160;{{ history.apiSession.nbIgnores | number }}</li>
						<li *ngIf="history.apiSession?.nbErrors"><span translate>connecteur.import.resultat.errors</span>&#160;:&#160;{{ history.apiSession.nbErrors | number }}</li>
						<li *ngIf="history.apiSession?.nbWarnings"><span translate>connecteur.import.resultat.warnings</span>&#160;:&#160;{{ history.apiSession.nbWarnings | number }}</li>
						<li *ngIf="history.apiSession?.nbInfos"><span translate>connecteur.import.resultat.infos</span>&#160;:&#160;{{ history.apiSession.nbInfos | number }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>