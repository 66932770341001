<form #detailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>facture.elements.detail.title</h2>
		</div>
		<div class="card-body card-padding">
			<facture-detail-content #detailContent [detail]="detail" [isDuplication]="isDuplication" (close)="close($event)"></facture-detail-content>
		</div>
	</div>
	<span class="pull-right" *ngIf="!['COMPTABILISEE','VALIDEE'].includes(detail.facture?.statut) && (!detail.facture.interfaceFournisseur || detail.editable || detail.userEdited)">
		<button mat-stroked-button color="primary" (click)="duplicate();" *ngIf="detail?.idDetail && (null | right:'creation') && !detail.facture?.interfaceFournisseur">
			<span translate>actions.dupliquer</span>
		</button>
		<button mat-stroked-button color="primary" (click)="deleteDetail(detail,close);" *ngIf="deleteDetail && detail?.idDetail && (null | right:'creation') && !detail.facture?.interfaceFournisseur">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="detailContent.saveDetail()" *ngIf="null | right:'creation'" [disabled]="detailForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: detailForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>