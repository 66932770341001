import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserCategorieService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Enregistrement d'une catégorie d'utilisateur
	 */
	public saveCategorie(categorie: any): Observable<Result> {
		//Enregistrement d'une catégorie d'utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/UserReferentiel/saveCategorie`,categorie);
	}

	/**
	 * Suppression d'une catégorie
	 */
	public deleteCategorie(categorie: any): Observable<Result> {
		//Suppression d'une catégorie
		return this.http.delete<Result>(`${environment.baseUrl}/controller/UserReferentiel/deleteCategorie/${categorie.idCategorie}`);
	}
}
