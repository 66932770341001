import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ComptePosteChargeListItemComponent } from './compte-poste-charge-list-item.component';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte-poste-charge-list',
	templateUrl: './compte-poste-charge-list.component.html'
})
export class ComptePosteChargeListComponent extends PageContentItem implements OnInit {
	/** Compte **/
	@Input() compte: any;

	/** Fonction d'édition **/
	@Input() addPosteCharge: (lien: any) => void;

	/** Résumé **/
	@Input() resume: { nbPostesCharge: number };

	/** Restriction sur le plan comptable **/
	@Input() restriction: { isRestricted: boolean } = null;

	/** Données **/
	liste: ListView<any,ComptePosteChargeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService,private compteService: CompteService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ComptePosteChargeListItemComponent>({
			uri: () => `/controller/Compte/filtreDetailsByCompte/POSTE_CHARGE/${this.compte.idCompte}`,
			component: ComptePosteChargeListItemComponent,
			isLocal: true,
			extraOptions: {
				addPosteCharge: this.addPosteCharge,
				restriction: this.restriction
			},
			defaultOrder: 'type,nature.libelle',
			listeFilters: [{
				clef: '*nature',
				autocomplete: {
					type: 'fournisseurNature'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeActions: [{
				icon: 'content_cut',
				messagingOptions: {
					entryPoint: `controller/Compte/moveAllPostesChargeToCompte`
				},
				isVisible: () => this.rightService.hasRight(null,'creation'),
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.compteService.moveAllPostesChargeForSelection(this.compte,messagingOptions,this.liste)
			}],
			getKeyFieldName: () => 'idLien',
			onRefresh: (liste,result) => this.resume.nbPostesCharge = result.totalElements
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbPostesCharge--;
	}
}