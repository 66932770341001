import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { LotComptableCheckPlanComptableComponent } from './lot-comptable-check-plan-comptable.component';
import { LotComptableService } from './lot-comptable.service';
import { LotComptableCheckChargeComponent } from './lot-comptable-check-charge.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LotComptableCheckTaxeComponent } from './lot-comptable-check-taxe.component';
import { LotComptableCheckRegleTaxeComponent } from './lot-comptable-check-regle-taxe.component';
import { LotComptableCheckTiersComponent } from './lot-comptable-check-tiers.component';
import { LotComptableCheckAuxiliaireComponent } from './lot-comptable-check-auxiliaire.component';
import { LotComptableCheckRecapitulatifComponent } from './lot-comptable-check-recapitulatif.component';
import { LotComptableCheckPosteComponent } from './lot-comptable-check-poste.component';


@Component({
	selector: 'lot-comptable-check',
	templateUrl: './lot-comptable-check.component.html'
})
export class LotComptableCheckComponent implements AfterContentChecked,OnInit {
	/** Eléments initialement choisis **/
	@Input() selectionItems: any;

	/** Mode édition **/
	@Input() isEdition: boolean;

	/** Index de la section sélectionnée **/
	@Input() idxSection: number;

	/** Création et peuplement du lot comptable **/
	@Input() doCreationAction: Function;

	/** Section initialement choisie **/
	selectedSection: any;

	/** Résumé **/
	resume: { nbSocietesWithoutPlanComptable?: number,nbCodesWithoutNature?: number,nbPostesChargeWithoutCompte?: number,nbPostesChargeWithoutCompteTaxe?: number,nbPostesChargeWithoutRegleTaxe?: number,nbFournisseursWithoutCompteTiers?: number,nbFournisseursWithoutCompteAuxiliaire?: number,counterCorrect?: { count: number,amount: number },counterAnomalie?: { count: number,amount: number } } = {};

	/** Mode 'correction' **/
	isCorrection: boolean;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Résultat **/
	result: { selectionItems: any,isCorrection?: boolean };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<LotComptableCheckComponent>,private lotComptableService: LotComptableService,private translateService: TranslateService,private confirmService: ConfirmService,private pluralPipe: PluralTranslatePipe) {
		//Binding des méthodes
		this.close = this.close.bind(this);
		this.doSaveCorrections = this.doSaveCorrections.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification des écritures comptables
		this.lotComptableService.verifyEcritures(this.selectionItems).pipe(first()).subscribe({
			next: result => {
				//Mise à jour du résumé
				Object.assign(this.resume,result.data);

				//Définition de la liste des étapes
				this.listeSteps = [{
					type: 'PLAN_COMPTABLE',
					component: LotComptableCheckPlanComptableComponent,
					libelle: 'comptabilite.lotComptable.check.planComptable.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbSocietesWithoutPlanComptable),{ nbAnomalies: this.resume.nbSocietesWithoutPlanComptable }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbSocietesWithoutPlanComptable
					}),
					canActivate: () => true,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0,
					hasError: () => this.resume?.nbSocietesWithoutPlanComptable > 0,
					isVisible: () => !this.isEdition
				},{
					type: 'POSTE_CHARGE',
					component: LotComptableCheckPosteComponent,
					libelle: 'comptabilite.lotComptable.check.poste.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbCodesWithoutNature),{ nbAnomalies: this.resume.nbCodesWithoutNature }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbCodesWithoutNature
					}),
					canActivate: () => this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0 && this.resume?.nbCodesWithoutNature == 0,
					hasError: () => this.resume?.nbCodesWithoutNature > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('POSTE_CHARGE'),
						isValid: () => true
					}]
				},{
					type: 'CHARGE',
					component: LotComptableCheckChargeComponent,
					libelle: 'comptabilite.lotComptable.check.charge.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbPostesChargeWithoutCompte),{ nbAnomalies: this.resume.nbPostesChargeWithoutCompte }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbPostesChargeWithoutCompte
					}),
					canActivate: () => this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0 && this.resume?.nbPostesChargeWithoutCompte == 0,
					hasError: () => this.resume?.nbPostesChargeWithoutCompte > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('CHARGE'),
						isValid: () => (!this.isEdition || this.resume?.nbPostesChargeWithoutCompte == 0 || this.stepperComponent?.getComponentInstance()?.getNbCorrections() == this.resume?.nbPostesChargeWithoutCompte)
					}]
				},{
					type: 'TAXE',
					component: LotComptableCheckTaxeComponent,
					libelle: 'comptabilite.lotComptable.check.taxe.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbPostesChargeWithoutCompteTaxe),{ nbAnomalies: this.resume.nbPostesChargeWithoutCompteTaxe }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbPostesChargeWithoutCompteTaxe
					}),
					canActivate: () => !this.isEdition && this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0 && this.resume?.nbPostesChargeWithoutCompteTaxe == 0,
					hasError: () => this.resume?.nbPostesChargeWithoutCompteTaxe > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('TAXE'),
						isValid: () => (!this.isEdition || this.resume?.nbPostesChargeWithoutCompteTaxe == 0 || this.stepperComponent?.getComponentInstance()?.getNbCorrections() == this.resume?.nbPostesChargeWithoutCompteTaxe)
					}]
				},{
					type: 'REGLE_TAXE',
					component: LotComptableCheckRegleTaxeComponent,
					libelle: 'comptabilite.lotComptable.check.regleTaxe.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbPostesChargeWithoutRegleTaxe),{ nbAnomalies: this.resume.nbPostesChargeWithoutRegleTaxe }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbPostesChargeWithoutRegleTaxe
					}),
					canActivate: () => !this.isEdition && this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0 && this.resume?.nbPostesChargeWithoutRegleTaxe == 0,
					hasError: () => this.resume?.nbPostesChargeWithoutRegleTaxe > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('REGLE_TAXE'),
						isValid: () => (!this.isEdition || this.resume?.nbPostesChargeWithoutRegleTaxe == 0 || this.stepperComponent?.getComponentInstance()?.getNbCorrections() == this.resume?.nbPostesChargeWithoutRegleTaxe)
					}]
				},{
					type: 'TIERS',
					component: LotComptableCheckTiersComponent,
					libelle: 'comptabilite.lotComptable.check.tiers.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbFournisseursWithoutCompteTiers),{ nbAnomalies: this.resume.nbFournisseursWithoutCompteTiers }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbFournisseursWithoutCompteTiers
					}),
					canActivate: () => !this.isEdition && this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0  && this.resume?.nbFournisseursWithoutCompteTiers == 0,
					hasError: () => this.resume?.nbFournisseursWithoutCompteTiers > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('TIERS'),
						isValid: () => (!this.isEdition || this.resume?.nbFournisseursWithoutCompteTiers == 0 || this.stepperComponent?.getComponentInstance()?.getNbCorrections() == this.resume?.nbFournisseursWithoutCompteTiers)
					}]
				},{
					type: 'AUXILIAIRE',
					component: LotComptableCheckAuxiliaireComponent,
					libelle: 'comptabilite.lotComptable.check.auxiliaire.title',
					getSubtitle: () => this.translateService.instant(this.pluralPipe.transform('comptabilite.lotComptable.check.subTitle',this.resume.nbFournisseursWithoutCompteAuxiliaire),{ nbAnomalies: this.resume.nbFournisseursWithoutCompteAuxiliaire }),
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						nbAnomalies: this.resume.nbFournisseursWithoutCompteAuxiliaire
					}),
					canActivate: () => !this.isEdition && this.resume?.nbSocietesWithoutPlanComptable == 0,
					isDone: () => this.resume?.nbSocietesWithoutPlanComptable == 0 && this.resume?.nbFournisseursWithoutCompteTiers == 0,
					hasError: () => this.resume?.nbFournisseursWithoutCompteAuxiliaire > 0,
					isVisible: () => true,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.continuer'),
						onPress: () => this.doSaveCorrections('AUXILIAIRE'),
						isValid: () => (!this.isEdition || this.resume?.nbFournisseursWithoutCompteAuxiliaire == 0 || this.stepperComponent?.getComponentInstance()?.getNbCorrections() == this.resume?.nbFournisseursWithoutCompteAuxiliaire)
					}]
				},{
					type: 'RECAPITULATIF',
					component: LotComptableCheckRecapitulatifComponent,
					libelle: 'comptabilite.lotComptable.check.recapitulatif.title',
					retrieveComponentData: () => ({
						selectionItems: this.selectionItems,
						idxSection: this.listeSteps.length - 1,
						resume: this.resume || result.data,
						onUpdate: this.onUpdate
					}),
					canActivate: () => !this.isEdition && this.resume?.nbSocietesWithoutPlanComptable == 0,
					isVisible: () => !this.isEdition,
					listeActions: [{
						isPrimary: true,
						libelle: this.translateService.instant('actions.terminer'),
						onPress: () => {
							//Définition du résultat
							this.result = {
								selectionItems: Object.assign({},this.selectionItems,{
									nbSelectedItems: this.resume.counterCorrect.count
								})
							};

							//Fermeture de la modal
							this.bsModalRef.hide();
						},
						isValid: () => true
					}]
				}]

				//Section initialement choisie
				this.selectedSection = this.idxSection !== undefined && this.idxSection != null ? this.listeSteps[this.idxSection] : this.listeSteps.filter(step => step.hasError?.())[0] || this.listeSteps[this.listeSteps.length - 1];

				//Chargement de la section
				this.selectSection(this.selectedSection);
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Définition du résultat
		this.result = {
			selectionItems: null,
			isCorrection: this.isCorrection
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Sélection d'une section
	 */
	selectSection(step: Step,isIgnoreVerification?: boolean) {
		//Vérification de la section
		if (step.canActivate() || this.isEdition) {
			//Vérification du nombre de corrections
			if (!isIgnoreVerification && this.selectedSection?.getComponentInstance?.().getNbCorrections?.() > 0) {
				//Affichage d'un message de confirmation
				this.confirmService.showConfirm(this.translateService.instant('comptabilite.lotComptable.check.changementSection'),{ actionColor: 'primary' }).subscribe({
					next: isConfirmed => {
						//Vérification de la confirmation
						if (isConfirmed)
							//Enregistrement des corrections
							this.doSaveCorrections(step.type);
						else
							//Sélection de l'onglet
							this.stepperComponent?.selectStep(step);
					}
				});
			} else
				//Sélection de l'onglet
				this.stepperComponent?.selectStep(step);
		}
	}

	/**
	 * Enregistrement des corrections
	 */
	private doSaveCorrections(targetedSectionType?: string) {
		let listeSections: Array<any>;
		let idxSection: number;

		//Récupération de la liste des sections
		listeSections = this.isEdition ? this.listeSteps.filter(s => s.isVisible?.()) : this.listeSteps;

		//Récupération de l'onglet courant
		idxSection = listeSections.findIndex(s => s.type == targetedSectionType);

		//Vérification du nombre de corrections
		if (this.stepperComponent.getComponentInstance().getNbCorrections() > 0) {
			//Corrections effectuées
			this.isCorrection = true;

			//Correction des lignes en anomalie
			this.stepperComponent.getComponentInstance().doCorrection().subscribe({
				next: () => {
					//Vérification du mode
					if (!this.isEdition) {
						//Retrait de la popup de contrôle du lot comptable
						this.close();

						//Affichage de la popup de contrôle du lot comptable
						this.lotComptableService.showLotComptableChecks(null,this.selectionItems,!targetedSectionType ? idxSection + 1 : idxSection,this.isEdition,this.doCreationAction).subscribe({
							next: result => {
								//Vérification du résultat
								if (result?.selectionItems)
									//Redirection vers la page de création du lot comptable
									this.doCreationAction(result.selectionItems,this.resume);
							}
						});
					} else {
						//Vérification de l'étape
						if (idxSection < listeSections.length - 1)
							//Sélection de l'onglet suivant ou sélectionné
							this.selectSection(listeSections[!targetedSectionType || this.isEdition ? idxSection + 1 : idxSection],true);
						else
							//Fermeture de la fenêtre
							this.close();
					}
				}
			});
		} else {
			//Vérification de l'étape
			if (idxSection < listeSections.length - 1)
				//Sélection de l'onglet suivant
				this.selectSection(listeSections[idxSection + 1],this.isEdition);
			else
				//Fermeture de la fenêtre
				this.close();
		}
	}

	/**
	 * Mise à jour de la sélection d'éléments
	 */
	private onUpdate(selectionItems: any,isDeletion: boolean,bsModalRef: BsModalRef<any>) {
		//Mise à jour de la sélection d'éléments
		Object.assign(this.selectionItems,selectionItems);

		//Vérification de la suppression d'éléments
		if (isDeletion) {
			//Mise à jour des compteurs
			this.lotComptableService.verifyEcritures(this.selectionItems).subscribe({
				next: result => {
					//Mise à jour du résumé
					Object.assign(this.resume,result.data);
				}
			});
		} else {
			//Affichage de la popup de contrôle du lot comptable
			this.lotComptableService.showLotComptableChecks(() => {
				//Retrait de la popup de contrôle du lot comptable de la facture sélectionnée
				bsModalRef.hide();

				//Retrait de la popup de contrôle du lot comptable
				this.bsModalRef.hide();
			},this.selectionItems,this.listeSteps.length - 1,this.isEdition,this.doCreationAction).subscribe({
				next: result => {
					//Vérification du résultat
					if (result?.selectionItems)
						//Redirection vers la page de création du lot comptable
						this.doCreationAction(result.selectionItems);
				}
			});
		}
	}
}