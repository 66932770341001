import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des postes de charge
 */
export default class PosteChargeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.posteCharge';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Compte/filtrePostesCharge`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item?.libelle} (${translateService.instant('comptabilite.compte.typeCharge.' + item.type)})` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.comptabilite.PosteCharge';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idObject'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'comptabilite.compte.typeCharge.item',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`comptabilite.compte.typeCharge.${item.type}`)
		}];
	}
}