import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ConnecteurTypeService } from './connecteur-type.service';
import { ConnecteurTypeListItemComponent } from './connecteur-type-list-item.component';
import { ConnecteurTypeComponent } from './connecteur-type.component';
import { ConnecteurTypeListComponent } from './connecteur-type-list.component';


@NgModule({
	imports: [ShareModule],
	declarations: [ConnecteurTypeListItemComponent,ConnecteurTypeComponent,ConnecteurTypeListComponent],
	providers: [ConnecteurTypeService]
})
export class ConnecteurTypeModule { }