import { Component,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { FactureService } from 'src/app/components/facture/facture.service';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LotComptableService } from './lot-comptable.service';


@Component({
	templateUrl: './lot-comptable-check-facture-list-item.component.html'
})
export class LotComptableCheckFactureListItemComponent extends ListViewItem<any> implements OnInit {
	/** Liste des types de facture **/
	public listeTypesFacture: Array<{ code: string,libelle: string }>;

	/** Résultat **/
	result: { selectionItems: any,isDeletion: boolean };

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,public factureService: FactureService,private lotComptableService: LotComptableService,private bsModalRef: BsModalRef) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	*/
	ngOnInit() {
		//Récupération de la liste des types de facture
		this.listeTypesFacture = this.factureService.getListeTypesFacture();
	}

	/**
	 * Récupération du titre d'un élément de la liste des factures
	 */
	getAvatar(item: any): string {
		//Retour du titre
		return `${this.listeTypesFacture.filter(t => t.code == item.type)[0]?.libelle.substring(0,1).toUpperCase()}`;
	}

	/**
	 * Récupération du titre d'un élément de la liste des factures
	 */
	getTitleForFacture(item: any) {
		//Récupération du titre
		return this.lotComptableService.getTitleForFacture(item);
	}

	/**
	 * Retrait d'une facture
	 */
	removeFacture (idFacture: any) {
		let actionMasse: any;

		///Vérification du type d'action
		if (idFacture) {
			//Création d'un action en masse
			actionMasse = {
				typeActionMasse: 'SELECTION',
				listeIdObjects: [idFacture],
				nbSelected: 1
			};

			//Retrait de la facture
			this.extraOptions.removeListeFactures({ params: actionMasse });
		}

	}

	/**
	 * Affichage de la pop up de contrôle du lot comptable
	 */
	public showLotComptableChecks(idFacture: any) {
		let selectedItem: any;

		//Copie de la sélection d'éléments
		selectedItem = cloneDeep(this.extraOptions.selectionItems);

		//Ajout de l'élément sélectionné
		selectedItem.listeSelectionsByAggregator[0].listeIdItems = [idFacture];

		//Initilisation de la liste des agrégateurs
		selectedItem.listeSelectionsByAggregator[0].nbItemsForAggregator = null;

		//Ouverture de la popup de contrôle des écritures en mode édition
		this.extraOptions.showLotComptableChecker(selectedItem,this.extraOptions.selectionItems);

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}