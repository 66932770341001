import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep,concat,isEqual,omit } from 'lodash-es';
import { v4 } from 'uuid';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './article-rubrique-list-edit.component.html'
})
export class ArticleRubriqueListEditComponent extends PageContentItem implements OnInit {
	/** Article **/
	@Input() article: any;

	/** Ajout d'une rubrique **/
	@Input() isAddNewRubrique: boolean;

	/** Enregistrement de l'article **/
	@Input() saveArticle: (article: any,close: (data?: any) => void) => void;

	/** Configuration de l'éditeur de texte **/
	summernoteConfig: any;

	/** Liste des cibles des clics **/
	private listeMousedownTargets: Array<HTMLElement> = [];

	/** Copie de l'article **/
	private savedArticle: any;

	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Binding des méthodes
		this.close = this.close.bind(this);

		//Copie de l'article
		this.article = cloneDeep(this.article);

		//Parcours des rubriques
		this.article.listeContents.forEach(c => {
			//Ajout d'une clé d'identification temporaire à la rubrique
			c.uuid = v4().toString();
		});

		//Vérification de l'ajout
		if (this.isAddNewRubrique) {
			//Ajout d'un étape
			this.addContent();

			//Exécution au prochain cycle
			setTimeout(() => {
				//Scroll en bas de la page
				document.documentElement.scroll({ top: document.documentElement.scrollHeight,behavior: 'smooth' });
			});
		}

		//Configuration des éditeurs de texte
		this.summernoteConfig = {
			height: 200,
			disableDragAndDrop: true,
			fontNames: ['Arial','Comic Sans MS','Courier New','Helvetica','Times New Roman','Verdana','Century Gothic','Calibri'],
			toolbar: [
				['style',['bold','italic','underline','clear','strikethrough']],
				['fontsize',['color','fontsize','height']],
				['fontname',['fontname']],
				['para',['ul','ol','paragraph']],
				['table',['table']],
				['insert',concat(['link','video'],this.rightService.isRoot() && ['picture'] || [])],
				(this.rightService.isRoot() || this.rightService.isRoot(true)) && ['view',['codeview']] || null
			]
		};

		//Copie de l'article avant modifications
		this.savedArticle = cloneDeep(this.article);
	}

	/**
	 * Ajout d'un nouveau contenu
	 */
	addContent() {
		//Vérification de la liste des contenus
		this.article.listeContents = this.article.listeContents || [];

		//Ajout d'un contenu vierge
		this.article.listeContents.push({ isToggled: true,uuid: v4().toString() });

		//Rafraichissement du composant de tri
		this.article.listeContents = [...this.article.listeContents];
	}

	/**
	 * Déplacement du contenu
	 */
	moveContent(index: number,delta: number) {
		let nextItem: any;

		//Récupération de l'élément suivant
		nextItem = this.article.listeContents[index + delta];

		//Échange avec l'élément suivant
		this.article.listeContents[index + delta] = this.article.listeContents[index];
		this.article.listeContents[index] = nextItem;

		//Rafraichissement du composant de tri
		this.article.listeContents = [...this.article.listeContents];
	}

	/**
	 * Suppression du contenu
	 */
	deleteContent(index: number) {
		//Suppression de l'élément
		this.article.listeContents.splice(index,1);

		//Rafraichissement du composant de tri
		this.article.listeContents = [...this.article.listeContents];
	}

	/**
	 * Détection des clics à l'intérieur d'un élément déplaçable
	 */
	onMousedown(event: MouseEvent,index: number) {
		//Mémorisation de la cible du clic à l'intérieur de l'élément
		this.listeMousedownTargets[index] = event.target as HTMLElement;
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Comparaison des deux listes
		return !isEqual(this.article.listeContents.map(c => omit(c,['isToggled'])),this.savedArticle.listeContents);
	}

	/**
	 * Détection de l'appui sur la touche 'Entrée' dans le formulaire
	 */
	onKeydownEnter(event: KeyboardEvent) {
		//Vérification que l'appui a été effectué en dehors d'une pop-up summernote
		if ((event.target as HTMLElement)?.closest('summernote .note-modal') == null)
			//Annulation de l'événement
			event.preventDefault();
	}
}