<form #fournisseurContactForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>fournisseur.contact.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
			<label translate>fournisseur.contact.fournisseur</label>
			<div>
				<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="contact.fournisseur" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': nom.invalid }">
			<label translate>fournisseur.contact.nom</label>
			<div>
				<input type="text" class="form-control" name="nom" #nom="ngModel" [(ngModel)]="contact.nom" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': prenom.invalid }">
			<label translate>fournisseur.contact.prenom</label>
			<div>
				<input type="text" class="form-control" name="prenom" #prenom="ngModel" [(ngModel)]="contact.prenom" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
			<label translate>fournisseur.contact.email</label>
			<div>
				<input type="email" email class="form-control" name="email" #email="ngModel" [(ngModel)]="contact.email" maxlength="600" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': telephone.invalid }">
			<label translate>fournisseur.contact.telephone</label>
			<div>
				<input type="text" class="form-control" name="telephone" #telephone="ngModel" [(ngModel)]="contact.telephone" phoneNumber [required]="contact.mobile"/>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="fournisseurContactForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>