import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { FactureService } from 'src/app/components/facture/facture.service';


@Component({
	templateUrl: './lot-comptable-check-poste-list-item.component.html'
})
export class LotComptableCheckPosteListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(public factureService: FactureService) {
		//Héritage
		super();
	}
}