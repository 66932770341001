/**
 * Interface représentant les actions d'une fiche détaillée de page complexe
 */
export interface Action {
	/** Libellé **/
	libelle: string;

	/** Action à réaliser **/
	doAction: () => void;

	/** Indicateur de visibilité **/
	isVisible?: () => boolean;

	/** Type d'action **/
	type?: TypeAction;
}

/**
 * Enumération des types d'actions
 */
export enum TypeAction {
	/** Enumération **/
	CONSULTATION = 'CONSULTATION',
	EDITION = 'EDITION',
	SUPPRESSION = 'SUPPRESSION',
	REDIRECTION = 'REDIRECTION'
}