<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idVersion">
		<div class="pull-left lv-avatar">
			<icon class="icon-hc-2x" [name]="data.dateInstallation ? 'check' : 'close'"></icon>
		</div>

		<div class="d-flex justify-content-space-between">
			<div class="lv-title">
				<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data.numero + ' (' + data.dateVersion + ')' }}</a>
			</div>
			<mat-chip-listbox>
				<mat-chip *ngIf="data.dateInstallation">
					<span [translateParams]="{ date: data.dateInstallation | date:'short' }" translate>upgrade.liste.dateInstallation</span>
				</mat-chip>
				<mat-chip color="warn" selected *ngIf="!data.dateInstallation">
					<span translate>upgrade.liste.notFinished</span>
				</mat-chip>
			</mat-chip-listbox>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span [translateParams]="{ date: data.dateDemarrage | date:'short' }" translate>upgrade.liste.dateDemarrage</span></li>
	</ul>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<upgrade [upgrade]="data" (onObjectChange)="onObjectChange($event)"></upgrade>
</div>