import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte-societe-edit',
	templateUrl: './compte-societe-edit.component.html'
})
export class CompteSocieteEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() lienSociete: any;

	/** Liste des identifiants de société du plan comptable **/
	listeIdsSociete: Array<number>;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des identifiants de société du plan comptable
		this.listeIdsSociete = this.lienSociete?.compte?.planComptable?.listeSocietes.map(s => s.idService);
	}

	/**
	 * Enregistrement du lien
	 */
	saveLienSociete() {
		//Enregistrement du lien
		this.compteService.saveLienCompteDetail(this.lienSociete).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du lien
					this.lienSociete = Object.assign(this.lienSociete,result.data?.lien);

					//Fermeture de la fenêtre
					this.close({ lienSociete: this.lienSociete });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.societe'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}