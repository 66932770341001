<div class="pull-left lv-avatar">
	<icon name="check" *ngIf="data.success !== false && data.apiSession?.statut == 'FINISHED'"></icon>
	<icon name="close" *ngIf="data.success === false || data.apiSession?.statut == 'ERROR'"></icon>
	<icon name="hourglass_bottom" *ngIf="data.success !== false && (!data.apiSession || data.apiSession?.statut == 'PENDING')"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="consultApiDataResult()">
			<span>{{ data.dateExecution | date:'short' }}</span>
			<span *ngIf="data.success !== false && data.apiSession?.statut == 'FINISHED'">&#160;-&#160;<span translate>connecteur.execution.statut.termine</span></span>
			<span *ngIf="data.success === false || data.apiSession?.statut == 'ERROR'">&#160;-&#160;<span translate>connecteur.execution.statut.erreur</span></span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li>
			<span translate>connecteur.execution.user</span>&#160;:
			<span *ngIf="data.user">{{ data.user | display:'user' }}</span>
			<span *ngIf="!data.user" translate>connecteur.execution.automatique</span>
		</li>
		<li *ngIf="data.apiSession"><span translate>connecteur.execution.nbCreations</span>&#160;:&#160;<span>{{ data.apiSession.nbCreations }}</span></li>
		<li *ngIf="data.apiSession"><span translate>connecteur.execution.nbIgnores</span>&#160;:&#160;<span>{{ data.apiSession.nbIgnores }}</span></li>
		<li *ngIf="data.apiSession"><span translate>connecteur.execution.nbUpdates</span>&#160;:&#160;<span>{{ data.apiSession.nbUpdates }}</span></li>
		<li *ngIf="data.apiSession"><span translate>connecteur.execution.nbDeletions</span>&#160;:&#160;<span>{{ data.apiSession.nbDeletions }}</span></li>
		<li *ngIf="data.apiSession"><span translate>connecteur.execution.nbWarnings</span>&#160;:&#160;<span>{{ data.apiSession.nbWarnings }}</span></li>
		<ng-template #listeParamsTooltipTemplate>
			<div style="text-align: left;">
				<div *ngFor="let param of data.listeParamsUsed">
					<span>{{ param.connecteurTypeParam.name }}</span>&#160;:&#160;<span>{{ (param.connecteurTypeParam.type == 'date' ? (param.dateValue | date:'short')  : param.connecteurTypeParam.type == 'numeric' ? (param.numericValue | number) : param.connecteurTypeParam.type == 'boolean' ? ('common.'+(param.booleanValue ? 'oui' : 'non') | translate) : param.value) || ('common.nonDefini' | translate) }}</span>
				</div>
			</div>
		</ng-template>
		<li *ngIf="data.listeParamsUsed?.length" [tooltip]="listeParamsTooltipTemplate"><span translate>connecteur.execution.parametresFacultatifs</span>&#160;:&#160;<span>{{ data.listeParamsUsed.length }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.correlationId"><a [routerLink]="[]" (click)="downloadLogs();"><span translate>connecteur.actions.telechargerJournaux</span></a></li>
		</ul>
	</div>
</div>