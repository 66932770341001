import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types d'infraction
 */
export default class TypeInfractionOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.typeInfraction';

	/**
	 * Taille de la recherche
	 */
	size: string = 'max';

	/**
	 * Récupération de l'URL
	 */
	url(): string {
		//Retour de l'URL
		return `/controller/VehiculeReferentiel/listeTypesInfractionActifs`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle}${item?.reference ? ' (' + item.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,2)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.sgi.TypeInfraction';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTypeInfraction'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: { idPays?: number }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idPays && {
			clef: 'pays.idPays',
			valeur: filter.idPays,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'vehicule.typeInfraction.pays',
			getValue: (item: any) => item?.pays?.libelle || ''
		}];
	}
}