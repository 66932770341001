<div class="liste magicsearch active" [ngClass]="{ 'only-input p-l-5': liste?.searchAction }" (click)="searchInput?.['focus']?.()">
	<form autocomplete="off" novalidate>
		<mat-form-field appearance="fill">
			<mat-icon matIconPrefix>search</mat-icon>
			<mat-chip-grid #chipGrid>
				<mat-chip-row *ngIf="filterSelectableWithLink && !isFilterActif(filterSelectableWithLink)" (click)="liste.isFilterShown = true; filterSelectableWithLink.isSelected = true;">
					<a [routerLink]="[]">{{ filterSelectableWithLink.selectFilterLinkTitle }}</a>
				</mat-chip-row>
				<mat-chip-row *ngIf="liste.extraData?.titre" (removed)="removeExtraData();">
					<span class="key"><span class="p-r-0" [innerHtml]="liste.extraData.titre"></span><span *ngIf="liste.extraData.valeur">:</span></span>
					<span class="value" *ngIf="liste.extraData.valeur">{{ liste.extraData.valeur }}</span>
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip-row>
				<mat-chip-row *ngFor="let item of liste.listeSelectedFilters; let idxItem = index" (removed)="removeItem(idxItem)">
					<span *ngIf="item.clef && item.title" class="key m-r-5">{{ item.title + ((item.displayedValeur !== undefined ? item.displayedValeur : item.valeur) ? ' :' : '') }}</span>
					<span class="value">{{ item.displayedValeur !== undefined ? item.displayedValeur : item.valeur }}</span>
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip-row>
				<input #searchInput (matChipInputTokenEnd)="addFilter($event)" [matChipInputFor]="chipGrid" [placeholder]="(liste?.placeholder || 'actions.liste.rechercherListeCourante') | translate">
			</mat-chip-grid>
		</mat-form-field>
		<ul class="pagination filter" *ngIf="!liste.isLocal">
			<li class="sort">
				<button mat-icon-button (click)="liste.isFilterShown = !liste.isFilterShown">
					<mat-icon>{{ liste.isFilterShown ? 'close' : 'filter_list' }}</mat-icon>
				</button>
			</li>
		</ul>
		<ul class="pagination" *ngIf="liste.isLocal && liste.isPagination !== false">
			<li class="previous">
				<button mat-icon-button (click)="goToPreviousPage()" [disabled]="liste.numPage == 0">
					<mat-icon>chevron_left</mat-icon>
				</button>
			</li>
			<li class="next">
				<button mat-icon-button (click)="goToNextPage()" [disabled]="!liste.data || liste.data.last">
					<mat-icon>chevron_right</mat-icon>
				</button>
			</li>
		</ul>
	</form>
</div>
<div [collapse]="!liste.isFilterShown || liste.nbSelectedItems">
	<form #searchForm="ngForm" autocomplete="off" novalidate class="magicsearch-form" [ngStyle]="{ 'overflow-y': (liste.listeFilters | filterBy:{ isSelected: true })?.length > 2 ? 'auto' : 'inherit' }">
		<ul class="liste_fields">
			<li *ngFor="let filter of liste.listeFilters" (click)="filter.isSelected = !filter.isSelected">
				<a [routerLink]="[]" [ngClass]="{ 'current': filter.isSelected }">
					<span [ngClass]="{ 'default': filter.isDefault }">{{ filter.title }}</span>
				</a>
			</li>
		</ul>
		<div class="clearfix filter-separator"></div>
		<div *ngFor="let filter of (liste.listeFilters | filterBy:{ isSelected: true }); let idxFilter = index" class="animated fadeIn">
			<div class="form-group" [ngClass]="{ 'has-error': searchForm.controls['filter_'+idxFilter+'_'+filter.clef]?.invalid || searchForm.controls['filter_min_'+idxFilter+'_'+filter.clef]?.invalid || searchForm.controls['filter_max_'+idxFilter+'_'+filter.clef]?.invalid }">
				<label>{{ filter.title }}</label>
				<div [ngClass]="{ half: !['DATE','MONTH','DECIMAL'].includes(filter.type) }">
					<div *ngIf="(!filter.type || filter.type == 'STRING') && !filter.listeValues">
						<div class="criteria">
							<input [name]="'filter_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.valeur" required/>
						</div>
					</div>
					<div *ngIf="filter.listeValues && (filter.type == 'STRING' || filter.type == 'LONG') && !filter.isListeValuesWithButton">
						<div class="criteria">
							<selectpicker *ngIf="isArray(filter.listeValues)" [name]="'filter_'+idxFilter+'_'+filter.clef" [(ngModel)]="filter.selectedValue" (ngModelChange)="filter.valeur = filter.selectedValue.code; filter.displayedValeur = filter.selectedValue.libelle" required>
								<mat-option *ngFor="let value of filter.listeValues" [value]="value"><span [translate]="value.libelle"></span></mat-option>
							</selectpicker>
							<selectpicker *ngIf="!isArray(filter.listeValues)" [name]="'filter_'+idxFilter+'_'+filter.clef" [(ngModel)]="filter.selectedValue" (ngModelChange)="filter.valeur = filter.selectedValue.code; filter.displayedValeur = filter.selectedValue.libelle" required>
								<mat-option *ngFor="let value of filter.listeValues | async" [value]="value"><span [translate]="value.libelle"></span></mat-option>
							</selectpicker>
						</div>
					</div>
					<div *ngIf="filter.type == 'BOOLEAN' || filter.listeValues && filter.isListeValuesWithButton">
						<div class="criteria">
							<mat-button-toggle-group [value]="filter.valeur">
								<mat-button-toggle *ngFor="let value of filter.listeValues" [value]="value.code" (click)="filter.valeur = value.code; filter.displayedValeur = value.libelle;"><span>{{ value.libelle }}</span></mat-button-toggle>
							</mat-button-toggle-group>
							<input [name]="'filter_'+idxFilter+'_'+filter.clef" type="hidden" [(ngModel)]="filter.valeur" required/>
						</div>
					</div>
					<div *ngIf="filter.type == 'AUTOCOMPLETE'">
						<div class="criteria">
							<ng-container *ngIf="!filter.autocomplete?.isBasket">
								<autocomplete [name]="'filter_'+idxFilter+'_'+filter.clef" [type]="filter.autocomplete.type" [filter]="filter.autocomplete.filter" [(ngModel)]="filter.valeur" isCreationDisabled="true" required></autocomplete>
							</ng-container>
							<ng-container *ngIf="filter.autocomplete?.isBasket">
								<basket [fieldName]="'filter_'+idxFilter+'_'+filter.clef" colWidthLabel="0" colWidthAutocomplete="4" propertyName="listeObjects" [type]="filter.autocomplete.type" [filter]="filter.autocomplete.filter" [model]="filter" isCreationDisabled="true" labelAutocomplete="" [hasError]="hasBasketError(filter)"></basket>
							</ng-container>
						</div>
					</div>
					<div *ngIf="['DATE','MONTH'].includes(filter.type)">
						<div class="criteria date d-flex gap-1">
							<div class="flex-1">
								<selectpicker [name]="'filter_'+idxFilter+'_'+filter.clef" [(ngModel)]="filter.typeComparaison" required>
									<mat-option *ngFor="let typeComparaison of listeTypesComparaison" [value]="typeComparaison"><span [translate]="'liste.filterOperator.female.'+typeComparaison"></span></mat-option>
								</selectpicker>
							</div>
							<div class="flex-1">
								<div class="input-group" *ngIf="filter.typeComparaison == 'BETWEEN'">
									<input [name]="'filter_min_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.dateDebut" datePicker [format]="filter.date?.format" [mode]="filter.date?.mode" [placeholder]="'liste.dateDebut'| translate" required/>
									<div class="input-group-addon no-border" *ngIf="filter.typeComparaison == 'BETWEEN'" translate>liste.and</div>
								</div>
								<input [name]="'filter_min_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.dateDebut" datePicker [format]="filter.date?.format" [mode]="filter.date?.mode" [placeholder]="'liste.date' | translate" *ngIf="filter.typeComparaison != 'BETWEEN'" required/>
							</div>
							<div class="flex-1">
								<ng-container *ngIf="filter.typeComparaison == 'BETWEEN'">
									<input [name]="'filter_max_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.dateFin" datePicker [format]="filter.date?.format" [mode]="filter.date?.mode" [placeholder]="'liste.dateFin' | translate" required/>
								</ng-container>
							</div>
						</div>
					</div>
					<div *ngIf="filter.type == 'DECIMAL'">
						<div class="criteria decimal d-flex gap-1">
							<div class="flex-1">
								<selectpicker [name]="'filter_'+idxFilter+'_'+filter.clef" [(ngModel)]="filter.typeComparaison" required>
									<mat-option *ngFor="let typeComparaison of listeTypesComparaison" [value]="typeComparaison"><span [translate]="'liste.filterOperator.male.'+typeComparaison"></span></mat-option>
								</selectpicker>
							</div>
							<div class="flex-1">
								<div class="input-group" *ngIf="filter.typeComparaison == 'BETWEEN'">
									<input [name]="'filter_min_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.min" nNumber="2" [placeholder]="'liste.min'| translate" required/>
									<div class="input-group-addon no-border" *ngIf="filter.typeComparaison == 'BETWEEN'" translate>liste.and</div>
								</div>
								<input [name]="'filter_min_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.min" nNumber="2" [placeholder]="'liste.valeur' | translate" *ngIf="filter.typeComparaison != 'BETWEEN'" required/>
							</div>
							<div class="flex-1">
								<ng-container *ngIf="filter.typeComparaison == 'BETWEEN'">
									<input [name]="'filter_max_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.max" nNumber="2" [placeholder]="'liste.max' | translate" required/>
								</ng-container>
							</div>
						</div>
					</div>
					<div *ngIf="filter.type == 'PERIOD'">
						<div class="criteria periode d-flex gap-1">
							<div class="flex-1">
								<div class="input-group">
									<input [name]="'filter_min_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.dateDebut" datePicker format="datetime" [placeholder]="'liste.dateDebut'| translate" required/>
									<div class="input-group-addon text-center no-border" translate>liste.and</div>
								</div>
							</div>
							<div class="flex-1">
								<input [name]="'filter_max_'+idxFilter+'_'+filter.clef" type="text" class="form-control" [(ngModel)]="filter.dateFin" datePicker format="datetime" [placeholder]="'liste.dateFin' | translate" required/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="magicsearch-form-action text-right p-10 animated fadeIn" *ngIf="(liste.listeFilters | filterBy:{ isSelected: true }).length">
			<mat-checkbox name="isSearchExtended" #isSearchExtended="ngModel" [(ngModel)]="liste.isSearchExtended"><span translate>actions.liste.etendreRecherche</span></mat-checkbox>

			<button mat-raised-button color="primary" class="m-l-10" (click)="updateListeFilters();" [disabled]="searchForm.invalid">
				<span translate>actions.rechercher</span>
			</button>
		</div>
	</form>
</div>