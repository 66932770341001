import { NgModule } from '@angular/core';

import { CompteModule } from './compte/compte.module';
import { PlanComptableModule } from './plan-comptable/plan-comptable.module';
import { CompteAuxiliaireModule } from './compte-auxiliaire/compte-auxiliaire.module';
import { LotComptableModule } from './lot-comptable/lot-comptable.module';
import { PosteChargeModule } from './poste-charge/poste-charge.module';

@NgModule({
	imports: [PlanComptableModule,CompteModule,CompteAuxiliaireModule,LotComptableModule,PosteChargeModule],
	exports: [PlanComptableModule,CompteModule,CompteAuxiliaireModule,LotComptableModule,PosteChargeModule]
})
export class ComptabiliteModule { }
