import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { DataCustomizationComponent } from './data-customization.component';
import { DataCustomizationService } from './data-customization.service';
import { DataCustomizationFieldListComponent } from './data-customization-field-list.component';
import { DataCustomizationFieldSelectionComponent } from './data-customization-field-selection.component';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,NumberModule,AutocompleteModule,DatePickerModule,IconModule],
	declarations: [DataCustomizationComponent,DataCustomizationFieldListComponent,DataCustomizationFieldSelectionComponent],
	exports: [DataCustomizationComponent],
	providers: [DataCustomizationService]
})
export class DataCustomizationModule {}