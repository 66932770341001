import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PlanEntretienService } from './plan-entretien.service';

@Component({
	selector: 'plan-entretien-edit',
	templateUrl: './plan-entretien-edit.component.html'
})
export class PlanEntretienEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() planEntretien: any;

	/** Suppression de l'objet **/
	@Input() deletePlanEntretien: Function;

	/** Identifiant du plan d'entretien d'origine **/
	@Input() idPlanEntretienOrigine?: number;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private planEntretienService: PlanEntretienService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public savePlanEntretien() {
		//Enregistrement de l'objet
		this.planEntretienService.savePlanEntretien(this.planEntretien,this.idPlanEntretienOrigine).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.planEntretien,result.data?.planEntretien);

					//Fermeture du formulaire
					this.close({
						planEntretien: this.planEntretien,
						nbDetails: result.data?.nbDetails || 0
					});
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}