<div class="container-fluid">
	<form #regleTaxeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>fiscalite.regleTaxe.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="regleTaxe.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>fiscalite.regleTaxe.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="regleTaxe.pays" required></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
								<label translate>fiscalite.regleTaxe.type.item</label>
								<div>
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="regleTaxe.type" required>
										<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>fiscalite.regleTaxe.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="regleTaxe.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteRegleTaxe();" *ngIf="regleTaxe?.idRegle && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveRegleTaxe(regleTaxe,close);" *ngIf="null | right:'creation'" [disabled]="regleTaxeForm.invalid">
				<span [translate]="'actions.'+(regleTaxe?.idRegleTaxe ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="regleTaxe?.idRegle" [formDetectChanges]="{ doAction: close,isDirty: regleTaxeForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>