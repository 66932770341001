<form #autorisationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!autorisation.isDisplayed">
		<div class="tile-sub">
			<autorisation-content #autorisationContent [autorisation]="autorisation" [source]="null" (close)="onContentClose($event)"></autorisation-content>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: autorisationForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="autorisationContent.deleteAutorisation();" *ngIf="autorisation.idAutorisation && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="autorisationForm.invalid" (click)="autorisationContent.saveAutorisation();" *ngIf="(null | right:'creation') && (!isProfilRestricted || !autorisation.idAutorisation)">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>