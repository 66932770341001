<form #structureExportRubriqueMoveForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>structureExport.ligne.moveRubrique.title</h4>
	</div>
	<div class="modal-body">
		<div class="card">
			<div class="form-group">
				<label translate>structureExport.ligne.moveRubrique.currentPosition</label>
				<div class="half">
					<input type="text" name="currentPosition" class="form-control" [(ngModel)]="currentPosition" nNumber [disabled]="true"/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': nouvellePosition.invalid }">
				<div class="half">
					<label translate>structureExport.ligne.moveRubrique.nouvellePosition.item</label>
					<div>
						<div class="input-group">
							<input type="text" name="nouvellePosition" #nouvellePosition="ngModel" class="form-control" [(ngModel)]="newPosition" nNumber min="1" [max]="max" [validator]="isNotEqual()" required/>
							<div class="input-group-addon">
								<icon name="info_outline" [tooltip]="'structureExport.ligne.moveRubrique.nouvellePosition.info' | translate:{ max }"></icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="save()" [disabled]="structureExportRubriqueMoveForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>