<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.user?.prenom?.substring?.(0,1)?.toUpperCase?.() + data.user?.nom?.substring?.(0,1)?.toUpperCase?.() }}</div>
		<div class="lv-title">
			<a [routerLink]="['/VehiculeConducteur/loadConducteur',data.idConducteur]">{{ data.user?.prenom + ' ' + data.user?.nom + ' (' + data.user?.matricule + (data.user?.societe ? ' - ' + data.user.societe.libelle : '') + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.dateDebut"><span translate>conducteur.dateDebut</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>conducteur.dateFin</span>&#160;:&#160;<span>{{ data.dateFin | date:'shortDate' }}</span></li>
		<li><span translate>conducteur.vehiculeAffectes</span>&#160;:&#160;
			<span *ngIf="data.listeVehicules?.length">
				<span>{{ data.listeVehicules[0].reference + (data.listeVehicules[0].numeroInterne ? ' - ' + data.listeVehicules[0].numeroInterne : '') + ' (' + (data.listeVehicules[0].modele ? data.listeVehicules[0].modele.marque.libelle + ' ' + data.listeVehicules[0].modele.libelle : ('vehicule.modele.nonDefini' | translate)) + ')' }}</span>&#160;&#160;
				<span *ngIf="data.listeVehicules.length > 1"><icon name="more_horiz" [tooltip]="vehiculesTooltipTemplate"></icon></span>
				<ng-template #vehiculesTooltipTemplate>
					<div class="text-left">
						<span translate>conducteur.vehiculeAffectes</span>&#160;:
						<div *ngFor="let vehicule of data.listeVehicules"><span>{{ vehicule.reference + ' (' + vehicule.modele.marque.libelle + ' ' + vehicule.modele.libelle + ')' }}</span></div>
					</div>
				</ng-template>
			</span>
			<span *ngIf="!data.listeVehicules || data.listeVehicules.length == 0"><span translate>common.aucun</span></span>
		</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation')"><a [routerLink]="[]" (click)="goToPlanning(data.user);" translate>vehicule.actions.accederPlanning</a></li>
			<li *ngIf="data.listeVehicules?.length && (TypeDroit.ADMIN_VEHICULE | right:'consultation')"><a [routerLink]="[]" (click)="goToVehicule(data.listeVehicules[0]);"><span translate>conducteur.actions.accederVehiculeActuel</span></a></li>
			<li *ngIf="(TypeDroit.ADMIN_UTILISATEUR | right:'consultation')"><a [routerLink]="[]" (click)="goToUser(data.user);"><span translate>conducteur.actions.accederCollaborateur</span></a></li>
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteConducteur();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>