import { Component,ElementRef,Input,Output,EventEmitter,OnInit } from '@angular/core';
import { toNumber } from 'lodash-es';

import { ChartOptions } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { ChartService } from './chart.service';

@Component({
	selector: 'kpi',
	templateUrl: './kpi.component.html'
})
export class KpiComponent implements OnInit {
	/** Options du graphique **/
	@Input() options: ChartOptions;

	/** Données du graphique **/
	@Input() data: Page<any>;

	/** Clic sur la donnée **/
	@Output() select = new EventEmitter<any>();

	/** Couleur personnalisée du graphique **/
	public color: string;

	/** Valeur à afficher **/
	public value: string;

	/** Icône associée à l'indicateur **/
	public icon: string;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private chartService: ChartService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let listeDatas: Array<any>;
		let value: any;
		let color: string;

		//Récupération de la liste des données
		listeDatas = this.data?.content || [];

		//Définition de la valeur
		value = listeDatas[0]?.value || 0;

		//Vérification de la présence de la valeur
		if (value)
			//Limitation de la valeur décimale
			value = (toNumber as any)(value,2).toString();

		//Définition de la valeur
		this.value = value;

		//Récupération de la couleur personnalisée
		color = listeDatas[0]?.color;

		//Récupération de la couleur
		color = color ? this.chartService.hexToRGB(color) : this.chartService.getColorFor('nc-primary');

		//Définition de la couleur
		this.color = color;

		//Définition de l'icône
		this.icon = listeDatas[0]?.icon;
	}

	/**
	 * Calcul de la taille de police adaptée à la taille de la boite
	 */
	getFontSize(isIcon?: boolean): number {
		let numChars: number;
		let baseRatio: number;
		let chartWidth: number;
		let chartHeight: number;
		let chartDimension: number;
		let fontSize: number;

		//Récupération du nombre de caractères
		numChars = this.value?.toString?.()?.length || 0;

		//Définition du ratio entre la taille de la police et la taille de la boite
		baseRatio = isIcon ? 0.35 : numChars < 4 ? 0.5 : numChars < 6 ? 0.33 : numChars < 8 ? 0.25 : 0.18;

		//Récupération des dimensions de la boite
		chartWidth = this.elementRef.nativeElement.closest('chart').offsetWidth;
		chartHeight = this.elementRef.nativeElement.closest('chart').offsetHeight;

		//Récupération de la dimension à utiliser pour le calcul
		chartDimension = Math.min(chartWidth,chartHeight);

		//Calcul de la taille (arrondie) de la police adaptée à la taille de la boite
		fontSize = Math.floor(chartDimension * baseRatio);

		//Mise en place d'une limite inférieure
		return Math.max(fontSize,isIcon ? 20 : 24);
	}
}