import { Component,Input } from '@angular/core';

@Component({
	templateUrl: './immatriculation-modele-catalogue-selection-resume.component.html'
})
export class ImmatriculationModeleCatalogueSelectionResumeComponent {
	/** Certificat d'immatriculation **/
	@Input() immatriculation: any;

	/** Modèle initial **/
	@Input() modeleInitial: any;

	/** Choix effectué **/
	@Input() selectedChoix: { type: 'CONSERVATION_MODELE' | 'CHANGEMENT_MODELE' };

	/** Modèle sélectionné **/
	@Input() selectedModele: any;
}