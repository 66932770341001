import { Directive,Input,OnInit,ElementRef,HostListener,Output,HostBinding,EventEmitter,Renderer2,ContentChild } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { FileUploader,FileWithProgress,Filter } from './file-uploader';
import { FileUploaderService } from './file-uploader.service';

@Directive({
	selector: '[fileUploader]'
})
export class FileUploaderDirective implements OnInit {
	/** Uploader **/
	@Input() fileUploader: FileUploader;

	/** Evènement généré lors du drop de fichiers **/
	@Output('onFilesDrop') onFilesDrop = new EventEmitter<Array<File>>();

	/** Classe CSS ajoutée à l'élément lors du drag sur l'élément **/
	@HostBinding('class.nv-file-over')
	isActif = false;

	/** Zone de sélection des fichiers **/
	private fileSelection: ElementRef;

	/** Dernier élément survolé en drag and drop **/
	private lastDragEnterTarget: EventTarget = null;

	/** Sélecteur de fichiers **/
	inputElement: HTMLInputElement;

	/** Retrait du listener sur le file-dropper **/
	private removeClickListener: Function;

	/**
	 * Constructeur
	 */
	constructor(private element: ElementRef,private fileUploaderService: FileUploaderService,private toastrService: ToastrService,private translateService: TranslateService,private renderer: Renderer2) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création du sélecteur de fichiers
		this.createFileInput();

		//Définition des méthodes de l'uploader
		this.wrapUploaderMethods();
	}

	/**
	 * Interception de la mise à jour de la zone de sélection d'un fichier
	 */
	@ContentChild('fileSelection') set setFileSelectionRef(fileSelectionRef: ElementRef) {
		//Vérification de la référence
		if (fileSelectionRef) {
			//Mise à jour de la référence
			this.fileSelection = fileSelectionRef;

			//Vérification de la présence d'une zone de sélection des fichiers
			if (this.fileSelection?.nativeElement && !this.removeClickListener) {
				//Interception des clics sur la zone de sélection des fichiers
				this.removeClickListener = this.renderer.listen(this.fileSelection.nativeElement,'click',() => {
					//Ouverture du sélecteur de fichiers
					this.inputElement.click();
				});
			}
		}
	}

	/**
	 * Création du sélecteur de fichiers
	 */
	createFileInput() {
		//Création du sélecteur de fichier
		this.inputElement = document.createElement('input');

		//Définition du sélecteur de fichier
		this.inputElement.setAttribute('type','file');
		this.inputElement.setAttribute('multiple',null);

		//Définition du style
		this.inputElement.style.display = 'none';

		//Interception de la sélection de fichiers
		this.inputElement.onchange = () => {
			let file: File;
			let failedFilter: Filter = null;

			//Parcours des fichiers sélectionnés
			for (let idxFile = 0;idxFile < this.inputElement.files.length;idxFile++) {
				//Vérification du type de fichier
				if (this.inputElement.files.item(idxFile) instanceof File) {
					//Vérification de la queue
					this.fileUploader.queue = this.fileUploader.queue || [];

					//Récupération du fichier
					file = this.inputElement.files.item(idxFile);

					//Exécution des filtres fournis
					for (let filter of this.fileUploader.filters || []) {
						//Vérification de la condition
						if (!filter.apply(file)) {
							//Mémorisation du filtre non valide
							failedFilter = filter;

							//Arrêt des contrôles
							break;
						}
					}

					//Vérification de l'absence d'erreur
					if (!failedFilter) {
						//Ajout du fichier à la queue
						this.fileUploader.queue.push(this.inputElement.files.item(idxFile));

						//Déclenchement de l'intercepteur de fichier ajouté
						this.fileUploader.onItemAdded && this.fileUploader.onItemAdded(file);
					} else {
						//Déclenchement de l'intercepteur d'erreur de validation
						this.fileUploader.onWhenAddingFileFailed?.(file,failedFilter);
					}
				}
			}

			//Remise à zéro du sélecteur
			this.inputElement.value = '';
		};

		//Ajout du sélecteur de fichiers
		this.element.nativeElement.append(this.inputElement);
	}

	/**
	 * Gestion du drop
	 */
	@HostListener('drop',['$event'])
	onDrop(event: DragEvent) {
		let listeFiles;

		//Lecture du DataTransfer
		const { dataTransfer } = event;

		//Annulation de l'évènement
		event.preventDefault();

		//Suppression de la classe CSS
		this.isActif = false;

		//Suppression de l'élément survolé
		this.lastDragEnterTarget = null;

		//Vérification des éléments transférés
		if (dataTransfer.items) {
			//Initialisation de la liste de fichiers
			listeFiles = [];

			//Parcours des éléments
			for (let idxItem = 0;idxItem < dataTransfer.items.length;idxItem++) {
				//Vérification du type d'élément droppé
				if (dataTransfer.items[idxItem].kind === 'file')
					//Ajout du fichier dans la liste
					listeFiles.push(dataTransfer.items[idxItem].getAsFile());
			}

			//Suppression des éléments du DataTransfer
			dataTransfer.items.clear();

			//Emission des fichiers
			this.onFilesDrop.emit(listeFiles);
		} else {
			//Récupération de la liste de fichiers
			listeFiles = dataTransfer.files;

			//Suppression des données du DataTransfer
			dataTransfer.clearData();

			//Emission des fichiers
			this.onFilesDrop.emit(Array.from(listeFiles));
		}
	}

	/**
	 * Gestion du dragenter
	 */
	@HostListener('dragenter',['$event'])
	onDragEnter(event: DragEvent) {
		//Ajout de la classe CSS
		this.isActif = true;

		//Mémorisation de l'élément survolé
		this.lastDragEnterTarget = event.target;
	}

	/**
	 * Gestion du dragover
	 */
	@HostListener('dragover',['$event'])
	onDragOver(event: DragEvent) {
		//Annulation de l'événement
		event.preventDefault();

		//Arrêt de la propagation
		event.stopPropagation();

		//Mise à jour de l'évènement
		event.dataTransfer.effectAllowed = 'copy';
		event.dataTransfer.dropEffect = 'copy';
	}

	/**
	 * Gestion du dragleave
	 */
	@HostListener('dragleave',['$event'])
	onDragLeave(event: DragEvent) {
		//Vérification que l'élément quitté était le dernier survolé
		if (event.target === this.lastDragEnterTarget) {
			//Retrait de la classe CSS
			this.isActif = false;

			//Suppression de l'élément survolé
			this.lastDragEnterTarget = null;
		}
	}

	/**
	 * Définition des méthodes de l'uploader
	 */
	wrapUploaderMethods() {
		//Définition des méthodes de l'uploader
		this.fileUploader.addItem = this.inputElement.click.bind(this);
		this.fileUploader.uploadAll = this.uploadAll.bind(this);
		this.fileUploader.uploadItem = this.uploadItem.bind(this);
		this.fileUploader.removeItem = this.removeItem.bind(this);
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	uploadAll(): Array<Observable<number>> {
		//Appel du service d'upload
		return this.fileUploaderService.upload(this.fileUploader,this.fileUploader.queue);
	}

	/**
	 * Upload d'un fichier
	 */
	uploadItem(idxItem: number): Array<Observable<number>> {
		let file: FileWithProgress;

		//Récupération du fichier
		file = this.fileUploader.queue[idxItem];

		//Appel du service d'upload
		return this.fileUploaderService.upload(this.fileUploader,[file]).map(o => o.pipe(
			finalize(() => this.fileUploader.autoRemove && typeof file.isUnsafe == 'undefined' && this.fileUploader.removeItem(idxItem))
		));
	}

	/**
	 * Suppression d'un élément
	 */
	removeItem(idxItem: number) {
		//Suppression de l'élément
		this.fileUploader.queue.splice(idxItem,1);
	}
}