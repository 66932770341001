import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'taxe-taux-edit',
	templateUrl: './taxe-taux-edit.component.html'
})
export class TaxeTauxEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() taux: any;

	/** Taxe courante **/
	@Input() taxe: any;

	/** Index **/
	@Input() readonly index: number;

	/** Enregistrement de la taxe **/
	@Input() saveTaxe: Function

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du taux
	 */
	saveTaux() {
		let taxe: any;
		let hasDoublon;

		//Duplication de l'objet avant enregistrement
		taxe = cloneDeep(this.taxe);

		//Controle anti-doublon
		hasDoublon = taxe.listeTaux.some((taux: any) => taux.idTaux != this.taux.idTaux && taux.taux === this.taux.taux);

		//Vérification de l'absence de doublons
		if (!hasDoublon) {
			//Vérification de la présence d'un index
			if (this.index == null)
				//Insertion du taux
				taxe.listeTaux.unshift(this.taux);
			else
				//Définition du taux
				taxe.listeTaux[this.index] = this.taux;

			//Enregistrement de la taxe
			this.saveTaxe(taxe,this.close);
		} else
			//Affichage d'un message d'erreur
			this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{ field: this.translateService.instant('fiscalite.taxe.taux') }));
	}

	/**
	 * Suppression du taux
	 */
	deleteTaux() {
		let taxe: any;

		//Duplication de l'objet avant enregistrement
		taxe = cloneDeep(this.taxe);

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed)
		).subscribe({
			next: () => {
				//Suppression du taux
				taxe.listeTaux.splice(this.index,1);

				//Enregistrement de la taxe
				this.saveTaxe(taxe,this.close);
			}
		});
	}
}