<div class="lv-item media">
	<div class="pull-left lv-avatar">{{ configuration?.type?.substring(0,2)?.toUpperCase() }}</div>
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showConfiguration()">{{ configuration?.libelle + (configuration?.type ? ' (' + ('authentification.configuration.type.'+ configuration?.type | translate) + ')' : '') }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(configuration?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="configuration.clientId"><span translate>authentification.configuration.clientId</span>&#160;:&#160;<span>{{ configuration.clientId }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && !disabled">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="onRemove.emit(configuration)"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>