<form #entretienInterventionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>entretien.intervention.title</h4>
	</div>
	<div class="modal-body">
		<div class="alert alert-info" *ngIf="entretien.dateLastEntretien && entretien.lastCompteur || entretien.compteurPrevisionnel">
			<icon name="info"></icon>
			<div>
				<strong translate>entretien.recapitulatif</strong>
				<div>
					<ul>
						<li *ngIf="!entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DUREE' && entretien.vehicule.planEntretien.duree"><strong translate>entretien.planEntretienDuree.recapitulatifPremier</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.duree | number }}</span>&#160;<span translate>duree.mois</span></li>
						<li *ngIf="entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DUREE' && entretien.vehicule.planEntretien.duree"><strong translate>entretien.planEntretienDuree.recapitulatif</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.duree | number }}</span>&#160;<span translate>duree.mois</span></li>
						<li *ngIf="!entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DISTANCE' && entretien.vehicule.planEntretien.distance"><strong translate>entretien.planEntretienDistance.recapitulatifPremier</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.distance | number }}</span>&#160;<span>{{ entretien.vehicule.planEntretien.unite.libelleCourt }}</span></li>
						<li *ngIf="entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DISTANCE' && entretien.vehicule.planEntretien.distance"><strong translate>entretien.planEntretienDistance.recapitulatif</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.distance | number }}</span>&#160;<span>{{ entretien.vehicule.planEntretien.unite.libelleCourt }}</span></li>
						<li *ngIf="entretien.dateLastEntretien"><strong translate>entretien.dateLastEntretien</strong>&#160;:&#160;<span>{{ entretien.dateLastEntretien | date:'shortDate' }}</span></li>
						<li *ngIf="entretien.lastCompteur"><strong translate>entretien.lastCompteur</strong>&#160;:&#160;<span>{{ entretien.lastCompteur + ' ' + entretien.vehicule.unite.libelleCourt }}</span></li>
						<li *ngIf="entretien.dateLastEntretien && entretien.compteurPrevisionnel"><strong translate>entretien.compteurPrevisionnel</strong>&#160;:&#160;<span>{{ (entretien.compteurPrevisionnel | number) + ' ' + entretien.vehicule.unite.libelleCourt }}</span></li>
					</ul>
				</div>
			</div>
		</div>
		<fieldset #fieldset [disabled]="!(null | right:'creation') || !entretien.previsionnel && entretien.idEntretien">
			<div class="listview lv-bordered local">
				<div class="lv-body">
					<h4 translate class="bold">entretien.intervention.interventionPrevues</h4>
					<div class="lv-item media" *ngFor="let link of listeLinksVehiculePlanEntretienDetailPrevues; let index = index">
						<div class="pull-left lv-avatar">{{ link.planEntretienDetail?.typeEntretien?.reference?.substring(0,3)?.toUpperCase() }}</div>
						<div class="lv-title">
							<a [routerLink]="[]" (click)="toggleStatut(link)">{{ link.planEntretienDetail?.typeEntretien?.libelle + ' (' + link.planEntretienDetail?.typeEntretien?.reference + ')' }}</a>
						</div>
						<ul class="lv-attrs">
							<li *ngIf="!entretien.previsionnel"><span translate>entretien.intervention.statut.item</span>&#160;:&#160;<span [translate]="'entretien.intervention.statut.' + link.statut"></span></li>
							<li *ngIf="link.planEntretienDetail?.duree"><span translate>entretien.intervention.duree</span>&#160;:&#160;<span>{{ link.planEntretienDetail?.duree + ' ' + ('duree.mois' | translate) }}</span></li>
							<li *ngIf="link.planEntretienDetail?.distance"><span translate>entretien.intervention.distance</span>&#160;:&#160;<span>{{ (link.planEntretienDetail?.distance | number) + ' ' + link.planEntretienDetail?.unite?.libelleCourt }}</span></li>
						</ul>
						<div class="lv-actions actions">
							<div class="d-flex align-items-center">
								<button mat-icon-button [tooltip]="'actions.historique.consulter' | translate" (click)="vehiculeService.showListeInterventions(link.vehicule,link.planEntretienDetail)" *ngIf="entretien.previsionnel">
									<mat-icon>replay_30</mat-icon>
								</button>
								<mat-checkbox [name]="'statut_'+index" [(ngModel)]="link.checked" [disabled]="!entretien?.previsionnel"/>
							</div>
						</div>
					</div>
					<div class="text-center p-10" *ngIf="!listeLinksVehiculePlanEntretienDetailPrevues?.length">
						<h5><span translate>liste.empty</span></h5>
					</div>
				</div>
			</div>
			<div class="listview lv-bordered local p-t-20" *ngIf="listeLinksVehiculePlanEntretienDetailAdded.length">
				<div class="lv-body">
					<h4 translate class="bold">entretien.intervention.interventionAutres</h4>
					<div class="lv-item media" *ngFor="let link of listeLinksVehiculePlanEntretienDetailAdded; let idx = index">
						<div class="pull-left lv-avatar">{{ link.typeEntretien?.reference?.substring(0,3)?.toUpperCase() }}</div>
						<div class="lv-title">
							<a [routerLink]="[]">{{ link.typeEntretien?.libelle + ' (' + link.typeEntretien?.reference + ')' }}</a>
						</div>
						<ul class="lv-attrs">
							<li *ngIf="!entretien.previsionnel && entretien?.idEntretien"><span translate>entretien.intervention.statut.item</span>&#160;:&#160;<span [translate]="'entretien.intervention.statut.' + link.statut"></span></li>
						</ul>
						<div class="lv-actions actions">
							<div class="d-flex align-items-center">
								<button mat-icon-button (click)="listeLinksVehiculePlanEntretienDetailAdded.splice(idx,1)" *ngIf="!entretien.idEntretien || entretien.previsionnel">
									<mat-icon>delete</mat-icon>
								</button>
								<mat-checkbox [name]="'statut_custom_'+idx" [(ngModel)]="link.checked" [disabled]="entretien.idEntretien && !entretien.previsionnel"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="listview m-t-20" *ngIf="(null | right:'creation') && (entretien.previsionnel || !entretien.idEntretien && entretien.type == 'ENTRETIEN')">
				<div class="lv-body">
					<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addIntervention();">
						<h5><a [routerLink]="[]" translate>entretien.intervention.ajoutIntervention.action</a></h5>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="entretienInterventionForm.invalid" (click)="saveListeInterventions();" *ngIf="(null | right:'creation') && (entretien.previsionnel || !entretien.idEntretien && entretien.type == 'ENTRETIEN')">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>