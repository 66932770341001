import { AfterContentChecked,ChangeDetectorRef,Component,Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ModeleService } from './modele.service';

@Component({
	selector: 'modele-details',
	templateUrl: './modele-details.component.html',
	styles: ['.modal-body { overflow-y: scroll; height: 70vh; }']
})
export class ModeleDetailsComponent implements OnInit,AfterContentChecked {
	/** Modèle **/
	@Input() modele: any;

	/** Options **/
	@Input() options?: { canChange?: boolean,canConfigure?: boolean,canSelect?: boolean };

	/** Etat d'initialisation **/
	public isInit: boolean = false;

	/** Résultat **/
	result: { action: 'CONFIGURE_MODELE' | 'CHANGE_MODELE',modele: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ModeleDetailsComponent>,private modeleService: ModeleService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}


	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du modèle
		this.modeleService.loadModele(this.modele.idModele).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR)
					//Définition du modèle
					Object.assign(this.modele,result.data.modele);
			},
			complete: () => {
				//Composant initialisé
				this.isInit = true;
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Réalisation de l'action
	 */
	public doAction(action: 'CONFIGURE_MODELE' | 'CHANGE_MODELE') {
		//Définition du résultat
		this.result = {
			modele: this.modele,
			action
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}