import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpErrorResponse,HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';

import { LayoutService } from 'src/app/share/layout/layout.service';
import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';

@Injectable()
export class ForbiddenRequestInterceptor implements HttpInterceptor {
	/** Données **/
	private session: Session = null;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private store: Store<AppState>) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification de la requête
		if (request.url.endsWith('_login') || !this.session.isLogged) {
			//Laisser passer la requête
			return next.handle(request);
		} else {
			//Vérification du code http 403
			return next.handle(request).pipe(catchError(error => {
				//Vérification d'un code HTTP 403
				if (error instanceof HttpErrorResponse && error.status == HttpStatusCode.Forbidden)
					//Redirection vers l'écran d'accès interdit
					this.layoutService.goToByState('access-forbidden');

				//Retour de la réponse en erreur
				return throwError(error);
			}));
		}
	}
}