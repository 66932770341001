import { Component } from '@angular/core';
import moment from 'moment';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './affectation-history-list-item.component.html'
})
export class AffectationHistoryListItemComponent extends ListViewItem<any> {
	/** Date du jour **/
	today: moment.Moment = moment.utc();

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Sélection de l'affectation
	 */
	select() {
		//Sélection de l'affectation
		this.extraOptions.selectAffectation(this.data);
	}
}