<div class="rule-item hoverable">
	<fieldset>
		<div class="field">
			<color-picker [name]="'color_' + uniqueIdentifier" #colorField="ngModel" [(ngModel)]="color.color"></color-picker>
			<span *ngIf="!isDefault" translate>dashboard.custom.color.siValeur</span>
			<span *ngIf="isDefault" translate>dashboard.custom.color.couleurParDefaut</span>
		</div>

		<div *ngIf="!isDefault" class="operateur">
			<div class="form-group">
				<selectpicker name="typeComparaisonColor" #typeComparaisonColorField="ngModel" [(ngModel)]="color.typeComparaison" [disabled]="!color.color" required>
					<mat-option *ngFor="let typeComparaison of listeTypeComparaisons" [value]="typeComparaison"><span [translate]="'liste.filterOperator.female.' + typeComparaison"></span></mat-option>
				</selectpicker>
			</div>
		</div>

		<div class="value">
			<div *ngIf="!isDefault && color.typeComparaison == TypeComparaison.BETWEEN" class="form-group" [ngClass]="{ 'has-error': minField.invalid || maxField.invalid }">
				<div class="between">
					<input type="text" [name]="'color_min_value_' + uniqueIdentifier" class="form-control" nNumber="2" #minField="ngModel" [(ngModel)]="color.min" [disabled]="!color.typeComparaison || !color.color" [max]="color.max"  [placeholder]="'common.minimum' | translate" required/>
					<div class="bold" translate>common.et</div>
					<input type="text" [name]="'color_max_value_' + uniqueIdentifier" class="form-control" nNumber="2" #maxField="ngModel" [(ngModel)]="color.max" [disabled]="!color.typeComparaison || !color.color" [min]="color.min" [placeholder]="'common.maximum' | translate" required/>
				</div>
			</div>
			<div *ngIf="!isDefault && color.typeComparaison != TypeComparaison.BETWEEN" class="form-group" [ngClass]="{ 'has-error': minField.invalid }">
				<input type="text" [name]="'color_value_' + uniqueIdentifier" class="form-control" nNumber="2" #minField="ngModel" [(ngModel)]="color.min" [disabled]="!color.typeComparaison || !color.color" required/>
			</div>
		</div>
		<div *ngIf="!isDefault" class="action">
			<button mat-icon-button class="hovered-item" (click)="onRemove.emit();">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</fieldset>
</div>