<form #releveCompteurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!releveCompteur.isDisplayed">
		<div class="tile-sub">
			<releve-compteur-content #releveCompteurContent [releveCompteur]="releveCompteur" source="RELEVE_COMPTEUR" [user]="user" (close)="onContentClose($event)"></releve-compteur-content>
		</div>
		<div class="tile-footer">
			<button mat-button *ngIf="user.type != 'VIRTUAL'" [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="releveCompteurContent.deleteReleveCompteur();" *ngIf="(TypeDroit.VEHICULE_COMPTEUR | right:'suppression') && releveCompteur.idCompteur && !releveCompteur.readOnly">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="releveCompteurContent.saveReleveCompteur();" *ngIf="TypeDroit.VEHICULE_COMPTEUR | right:'creation'" [disabled]="releveCompteurForm.invalid || releveCompteur.readOnly && (releveCompteur.valeurReference === releveCompteurCopie.valeurReference)">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>