<div class="pull-left lv-avatar"><icon name="smartphone"></icon></div>
<div class="media-body">
	<div class="lv-title">
		<div>{{ data.manufacturer + ' - ' + data.model + (extraOptions?.login ? ' ('+ extraOptions?.login + ')' : '') }}</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idDevice">
		<li *ngIf="data.lastCheckDate"><span translate>user.device.lastCheckDate</span>&#160;:&#160;<span>{{ data.lastCheckDate | date:'short' }}</span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.systemName"><span translate>user.device.systemName</span>&#160;:&#160;<span>{{ data.systemName }}</span></li>
		<li *ngIf="data.appVersion"><span translate>user.device.appVersion</span>&#160;:&#160;<span>{{ data.appVersion }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idDevice && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="toggleDevice();"><span [translate]="'actions.'+(data?.actif ? 'desactiver' : 'activer')"></span></a></li>
		</ul>
	</div>
</div>