<div class="container-fluid">
	<div class="row" *ngIf="data">
		<div class="col-md-3" *ngIf="!isFullScreen()">
			<complex-page-info [options]="options" [data]="data"></complex-page-info>
			<form-customization *ngIf="options.isFormCustomization" [data]="data"></form-customization>
			<complex-page-actions [options]="options" [data]="data" (onActionSelected)="onActionSelected($event);"></complex-page-actions>
		</div>
		<div [ngClass]="!isFullScreen() ? 'col-md-9' : 'col-md-12'">
			<complex-page-alerts [options]="options" [data]="data"></complex-page-alerts>
			<page-content mode="sub">
				<complex-page-elements #complexPageElements="complexPageElements" [options]="options" [data]="data"></complex-page-elements>
			</page-content>
		</div>
	</div>
</div>