<form #natureFieldsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" *ngIf="listeFields.length" class="m-t-20">
	<fieldset [disabled]="!(null | right:'creation')">
		<div [ngClass]="{ 'card detail p-10': isEdition }">
			<div class="listview">
				<div class="lv-body">
					<div class="lv-item" *ngFor="let field of listeFields">
						<div class="sortable-item">
							<div>
								<span [translate]="'nature.elements.field.type.' + field.typeField"></span>
							</div>
							<ul>
								<li>
									<mat-slide-toggle color="primary" [name]="'actif_'+field.idField" #actif="ngModel" [(ngModel)]="field.actif" [checked]="field.actif" [disabled]="!isEdition"></mat-slide-toggle>
								</li>
							</ul>
						</div>
						<div class="row m-t-10" *ngIf="field.typeField == 'RELEVE_ENERGIE' && field.actif">
							<div class="col-md-6">
								<div *ngIf="isEdition" class="form-group" [ngClass]="{ 'has-error': unite.invalid }">
									<label translate>nature.elements.field.releveEnergie.unite</label>
									<div>
										<autocomplete name="unite" type="unite" #unite="ngModel" filter="CONTENANCE" [(ngModel)]="field.unite" required></autocomplete>
									</div>
								</div>
								<div *ngIf="!isEdition && field.unite" class="form-group">
									<label translate>nature.elements.field.releveEnergie.unite</label>
									<div>
										<span>{{ field.unite.libelle }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<div *ngIf="isEdition" class="card-footer">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveNature();" *ngIf="null | right:'creation'" [disabled]="natureFieldsForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: natureFieldsForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>