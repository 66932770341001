import { Component,Input,OnInit } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ArticleService } from './article.service';
import { ChartService } from '../chart/chart.service';

@Component({
	selector: 'article-edit',
	templateUrl: './article-edit.component.html'
})
export class ArticleEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() article: any;

	/** Suppression de l'article **/
	@Input() deleteArticle: () => void;

	/** Résumé **/
	@Input() resume: any;

	/** Enregistrement de l'article **/
	@Input() saveArticle: (article: any,close: (data?: any) => void) => void;

	/** Liste des types d'articles **/
	public listeTypesArticle: Array<{ code: string,libelle: string }>;

	/** Liste des types de périmètres **/
	public listeTypesPerimetre: Array<{ code: string,libelle: string }>;

	/** Liste des types de diffusion **/
	public listeTypesDiffusion: Array<{ code: string,libelle: string }> = this.chartService.getListeTypesDiffusion();

	/**
	 * Constructeur
	 */
	constructor(private articleService: ArticleService,public rightService: RightService,private chartService: ChartService) {
		//Héritage
		super();

		//Récupération de la liste des types d'articles
		this.listeTypesArticle = this.articleService.getListeTypes(this.rightService.isRoot());
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de périmètre
		this.listeTypesPerimetre = this.articleService.getListePerimetres();

		//Copie de l'article
		this.article = cloneDeep(this.article);

		//Binding des méthodes
		this.close = this.close.bind(this);
	}
}