import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter,switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LotComptableService } from './lot-comptable.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	templateUrl: './lot-comptable-facture-list-item.component.html'
})
export class LotComptableFactureListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private historyService: HistoryService,private translateService: TranslateService,private confirmService: ConfirmService,private lotComptableService: LotComptableService,private toastrService: ToastrService) {
		//Héritage
		super();

		//Binding des méthodes
		this.removeFacture = this.removeFacture.bind(this);
	}

	/**
	 * Affichage de la facture
	 */
	showFacture(idFacture: number) {
		//Navigation vers la facture
		this.layoutService.goToByState('listeFactures-load',{
			routeParams: {
				idFacture
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de l'historique
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.facture.Facture',this.data.idFacture);
	}

	/**
	 * Retrait de la facture
	 */
	removeFacture() {
		let isLastItem: boolean;

		//Vérification du dernier élément
		isLastItem = this.extraOptions.resume?.nbFactures == 1;

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant(isLastItem ? 'comptabilite.lotComptable.actions.suppressionConfirmation' : 'actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.lotComptableService.removeItem('FACTURE',this.data.idFacture,isLastItem))
		).subscribe({
			next: (result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Vérification du dernier élément
					if (!isLastItem) {
						//Mise à jour du lot comptable
						Object.assign(this.extraOptions.lotComptable,result.data.lotComptable);

						//Suppression de l'objet
						this.onObjectChange(null);
					} else
						//Retour à la liste
						this.layoutService.goBack();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}
}