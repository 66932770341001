<div class="container-fluid">
	<form #compteServiceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>compteService.libelle</label>
								<div>
									<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="compteService.libelle" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': login.invalid }">
								<label translate>compteService.login</label>
								<div>
									<input type="text" class="form-control" name="login" #login="ngModel" [(ngModel)]="compteService.user.login" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
								<label translate>compteService.email</label>
								<div>
									<input type="email" class="form-control" name="email" #email="ngModel" [(ngModel)]="compteService.user.email" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': perimetre.invalid }">
								<label translate>compteService.perimetre</label>
								<div class="half">
									<selectpicker name="perimetre" #perimetre="ngModel" [(ngModel)]="compteService.typePerimetre" required>
										<mat-option *ngFor="let typePerimetre of listeTypesPerimetre" [value]="typePerimetre.code">{{ typePerimetre.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>compteService.actif.title</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'compteService.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="compteService.user.enabled" [disabled]="!compteService.listeDroits?.length"><span translate>compteService.actif.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>compteService.defaultCompte.title</label>
								<div>
									<mat-checkbox name="compteDefaut" #compteDefaut="ngModel" [(ngModel)]="compteService.compteDefaut"><span translate>compteService.defaultCompte.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>compteService.activation.title</label>
								<div>
									<mat-checkbox name="transmission" #transmission="ngModel" [(ngModel)]="compteService.user.transmissionAccount" [disabled]="!compteService.user.enabled"><span translate>compteService.activation.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteCompteService();" *ngIf="compteService?.idCompteService && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveCompteService(compteService,close);" *ngIf="null | right:'creation'" [disabled]="compteServiceForm.invalid">
				<span [translate]="'actions.'+(compteService?.idCompteService ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="compteService?.idCompteService" [formDetectChanges]="{ doAction: close,isDirty: compteServiceForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>