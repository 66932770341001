<tr>
	<td class="button-container" [colSpan]="isBranche ? 2 : undefined">
		<div class="menu" [ngClass]="{ opened: isOpened,branch: mode === 'branche' }">
			<div class="button" *ngIf="mode === 'step'" (click)="isOpened = !isOpened"><icon name="add"></icon></div>
			<div class="action-container" *ngIf="mode === 'step'">
				<a [routerLink]="[]" class="button disabled" disabled [tooltip]="'workflow.detail.button.moreOptions' | translate"><icon name="more_horiz"></icon></a>
				<a [routerLink]="[]" class="button" [tooltip]="'workflow.detail.button.addValidation' | translate" (click)="addStep(); isOpened = false"><icon name="check"></icon> </a>
				<a [routerLink]="[]" class="button" [tooltip]="'workflow.detail.button.addRegleGestion' | translate" (click)="addBranch(); isOpened = false"><icon name="shuffle"></icon></a>
			</div>
			<div class="button" *ngIf="mode === 'branche'" (click)="addBranchInLine()"><icon name="close"></icon></div>
		</div>
	</td>
</tr>
