<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idAffectation">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
			<icon name="person" *ngIf="data.typeAffectation == 'VEHICULE_FONCTION'"></icon>
			<icon name="apartment" *ngIf="data.typeAffectation == 'VEHICULE_SERVICE'"></icon>
			<icon name="lock" *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
			<icon name="event" *ngIf="data.typeAffectation == 'VEHICULE_POOL'"></icon>
			<icon name="question_mark" *ngIf="!data.typeAffectation"></icon>
		</div>
		<div class="lv-title">
			<span (click)="data.isDisplayed = !data.isDisplayed">
				<a [routerLink]="[]">{{ data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + (data.user ? ' (' + data.user.nom + ' ' + data.user.prenom + ')' : '') }}</a>
			</span>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAffectation">
		<li *ngIf="data.typeAffectation"><span translate>vehicule.affectation.type.item</span>&#160;:&#160;<span [translate]="'vehicule.affectation.type.'+data.typeAffectation"></span></li>
		<li><span translate>vehicule.affectation.dateDebut.item</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li><span translate>vehicule.affectation.dateFin.item</span>&#160;:&#160;<span *ngIf="data.dateFin">{{ data.dateFin | date:'short' }}</span><span *ngIf="!data.dateFin" translate>common.aucune</span></li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.statut</span>&#160;:&#160;<span [translate]="'reservation.statut.'+data.reservation.statut"></span></li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.dateReservation</span>&#160;:&#160;{{ data.reservation.dateCreation | date:'short' }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="user.type != 'VIRTUAL' && data?.idAffectation && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'creation') && data.reservation.statut == 'EN_ATTENTE'"><a [routerLink]="[]" (click)="doActionForReservation('VALIDER');"><span translate>actions.valider</span></a></li>
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'creation') && data.reservation.statut == 'EN_ATTENTE'"><a [routerLink]="[]" (click)="doActionForReservation('REJETER');"><span translate>actions.rejeter</span></a></li>
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'creation') && data.reservation.statut == 'VALIDEE'"><a [routerLink]="[]" (click)="doActionForReservation('ANNULER');"><span translate>actions.annuler</span></a></li>
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'suppression') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'suppression')) && !data.reservation"><a [routerLink]="[]" (click)="deleteAffectation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="['VEHICULE_FONCTION','VEHICULE_SERVICE'].includes(data.typeAffectation) && (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation')"><a [routerLink]="[]" (click)="showConducteur();"><span translate>vehicule.affectation.actions.accederConducteur</span></a></li>
			<li *ngIf="data.typeAffectation == 'VEHICULE_FONCTION' && (TypeDroit.VEHICULE_AFFECTATION_ECHEANCE | right:'consultation')"><a [routerLink]="[]" (click)="goToAffectationEcheances();"><span translate>vehicule.affectation.actions.accederEcheancierAvantagesNature</span></a></li>
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'consultation')"><a [routerLink]="[]" (click)="goToReservation();"><span translate>vehicule.affectation.actions.accederReservation</span></a></li>
			<li *ngIf="data.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation'))"><a [routerLink]="[]" (click)="goToPlanning();"><span translate>vehicule.affectation.actions.accederPlanning</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<affectation [affectation]="data" (onObjectChange)="onObjectChange($event)" (refreshListe)="refreshListe()"></affectation>
</div>