import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MarqueService } from 'src/app/components/vehicule/marque/marque.service';
import { Result } from 'src/app/domain/common/http/result';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { MarqueListItemComponent } from './marque-list-item.component';

@Component({
	templateUrl: './marque-list.component.html'
})
export class MarqueListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,MarqueListItemComponent>;

	/** Résumé **/
	resume: { nbCatalogue: number,nbHorsCatalogue: number,nbTous: number } = {
		nbCatalogue: 0,
		nbHorsCatalogue: 0,
		nbTous: 0
	};

	/**
	 * Constructeur
	 */
	constructor(private marqueService: MarqueService,private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,MarqueListItemComponent>({
			uri: () => `/controller/VehiculeReferentiel/filtreMarques/${!this.liste?.selectedSelector || this.liste?.selectedSelector == 'TOUS' ? '' : this.liste?.selectedSelector}`,
			title: this.translateService.instant('vehicule.marque.liste.title'),
			component: MarqueListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			getKeyFieldName: () => 'idMarque',
			listeSelectors: [{
				code: 'TOUS',
				libelle: this.translateService.instant('vehicule.marque.typeSource.TOUS'),
				count: () => this.resume.nbTous
			},{
				code: 'HORS_CATALOGUE',
				libelle: this.translateService.instant('vehicule.marque.typeSource.ADMINISTRATIF'),
				count: () => this.resume.nbHorsCatalogue
			},{
				code: 'CATALOGUE',
				libelle: this.translateService.instant('vehicule.marque.typeSource.CONSTRUCTEUR'),
				count: () => this.resume.nbCatalogue
			}],
			selectedSelector: 'TOUS',
			extraOptions: {
				resume: this.resume,
				onMarqueAdd: this.onMarqueAdd
			},
			onRefresh: (liste,result) => {
				//Chargement du résumé
				this.marqueService.loadResume(liste.selectedSelector == 'HORS_CATALOGUE' ? 'CATALOGUE' : 'HORS_CATALOGUE',result.searchSpec).subscribe({
					next: (resultData: Result) => {
						//Vérification du type de liste
						if (liste.selectedSelector === 'CATALOGUE') {
							//Définition des compteurs
							this.resume.nbCatalogue = result?.totalElements || 0;
							this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
						} else if (liste.selectedSelector === 'HORS_CATALOGUE') {
							//Définition des compteurs
							this.resume.nbHorsCatalogue = result?.totalElements || 0;
							this.resume.nbCatalogue = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
						} else if (liste.selectedSelector === 'TOUS') {
							//Définition des compteurs
							this.resume.nbTous = result?.totalElements || 0;
							this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
							this.resume.nbCatalogue = this.resume.nbTous - this.resume.nbHorsCatalogue;
						}
					}
				});
			},
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idMarque) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					typeSource: 'EXTERNE_IMMATRICULATION'
				});
			},
		});
	}

	/**
	 * Interception de la suppression
	 */
	onItemRemove(item) {
		//Vérification de l'élément
		if (item.idMarque) {
			//Mise à jour des compteurs
			this.resume.nbTous -= 1;

			//Vérification du type
			if (item.typeSource === 'EXTERNE_CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue -= 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue -= 1;
		}
	}

	/**
	 * Interception de l'ajout
	 */
	onMarqueAdd(item: any) {
		//Vérification de l'élément
		if (item.idMarque) {
			//Mise à jour des compteurs
			this.resume.nbTous += 1;

			//Vérification du type
			if (item.typeSource === 'EXTERNE_CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue += 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue += 1;
		}
	}
}