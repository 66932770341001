import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ProgressConfig } from 'src/app/domain/progress/progress';

@Component({
	selector: 'progress-modal',
	templateUrl: './progress-modal.component.html'
})
export class ProgressModalComponent {
	/** Configuration de la progression **/
	@Input() progressConfig: ProgressConfig;

	/**
	 * Constructeur
	 */
	constructor(private bsModalRef: BsModalRef<ProgressModalComponent>) { }

	/**
	 * Fermeture de la modal
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}
