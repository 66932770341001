import { AfterViewInit,Component,ElementRef,OnInit,QueryList,ViewChild,ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { tap,switchMap,first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ChatbotService } from './chatbot.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { Exchange } from 'src/app/domain/chatbot/chatbot';
import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AppState } from 'src/app/domain/appstate';

@Component({
	selector: 'chatbot',
	templateUrl: './chatbot.component.html'
})
export class ChatbotComponent implements OnInit,AfterViewInit {
	/** Scénario sélectionné **/
	public selectedScenario: any = null;

	/** Liste des échanges ***/
	public listeExchanges: Array<Exchange> = [];

	/** Attente de la réponse du bot **/
	public isLoading: boolean = false;

	/** Message de l'utilisateur **/
	public newMessage: string;

	/** Indicateur de verrouillage de la zone de saisie utilisateur **/
	public isInputDisabled: boolean = true;

	/** Mode d'affichage de la pop-up **/
	public isMinimized: boolean = false;

	/** Exemple de question à poser **/
	public exampleQuestion: string;

	/** Liste des scénarios possibles **/
	private listeScenarios: Array<any> = [];

	/** Identifiant de corrélation de la session **/
	private correlationId: string;

	/** Élément défilable **/
	private scroller: ElementRef<HTMLElement>;

	/** URL de la politique de confidentialité **/
	public confidentialiteUrl: string;

	/** Liste des messages affichés **/
	@ViewChildren('message') listeMessages: QueryList<any>;

	/**
	 * Constructeur
	 */
	constructor(public chatbotService: ChatbotService,private translateService: TranslateService,private toastrService: ToastrService,private pluralPipe: PluralTranslatePipe,private sharedArticleService: SharedArticleService,private messagingService: MessagingService,public rightService: RightService,private store: Store<AppState>) { }

	/**
	 * Interception de la mise à jour de l'élément défilable
	 */
	@ViewChild('scroller') set setScroller(scroller: ElementRef<HTMLElement>) {
		//Vérification de la référence
		if (scroller && !this.scroller)
			//Mise à jour de la référence
			this.scroller = scroller;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Début d'attente
		this.isLoading = true;

		//Remise à zéro de l'identifiant de corrélation
		this.correlationId = null;

		//Récupération des scénarios possibles
		this.chatbotService.getListeScenarios().pipe(
			tap(listeScenarios => {
				//Mémorisation de la liste des scénarios actifs
				this.listeScenarios = listeScenarios.filter(s => !s.disabled);
			}),
			switchMap(() => {
				//Recherche des scénarios disponibles pour l'utilisateur
				return this.chatbotService.findAllScenariosAvailable();
			})
		).subscribe({
			next: () => {
				//Fin d'attente du bot
				this.isLoading = false;

				//Envoi de la question dans le chat
				this.listeExchanges.push({
					type: 'BOT',
					text: (this.rightService.isRoot() || this.rightService.isRoot(true)) ? this.listeScenarios[0].textRoot : this.listeScenarios[0].text
				});

				//Déverrouillage de la zone de saisie
				this.isInputDisabled = false;
			}
		});

		//Récupération de l'URL de confidentialité
		this.store.select<string>(state => state.session.confidentialiteUrl).pipe(first()).subscribe({
			next: confidentialiteUrl => {
				//Mémorisation de l'URL de confidentialité
				this.confidentialiteUrl = confidentialiteUrl;
			}
		});

		//Définition de la question donnée comme exemple de saisie
		this.exampleQuestion = this.translateService.instant(`chatbot.placeholder.${Math.floor(Math.random() * 5)}`);
	}

	/**
	 * Chargement de la vue
	 */
	ngAfterViewInit(): void {
		//Détection de l'affichage de nouveaux messages
		this.listeMessages.changes.subscribe(() => {
			//Défilement automatique au bas de la fenêtre
			this.scrollToBottom();
		});
	}

	/**
	 * Envoi d'un message
	 */
	public sendMessage(message: string) {
		let messaging$: MessagingObservables;

		//Vérification du message
		if (message?.length) {
			//Ajout du message saisi par l'utilisateur à la liste
			this.listeExchanges.push({
				type: 'USER',
				text: message
			});

			//Remise à zéro du message de l'utilisateur
			this.newMessage = '';

			//Début d'attente (le bot écrit une réponse)
			this.isLoading = true;

			//Démarrage de l'action par websocket
			messaging$ = this.messagingService.init({
				method: 'POST',
				entryPoint: `controller/Chatbot/sendMessage`,
				params: {
					correlationId: this.correlationId,
					text: message
				}
			}).onResult({
				next: result => {
					//Vérification de l'identifiant de corrélation
					if (!this.correlationId)
						//Mémorisation de l'identifiant de corrélation
						this.correlationId = result?.data?.correlationId;
				}
			}).onMessage({
				next: message => {
					let exchange: Exchange = null;

					//Vérification de la référence de partie
					if (message.reference?.length) {
						//Recherche de l'échange existant
						exchange = this.listeExchanges.find(exchange => exchange.partId === message.reference);

						//Vérification de l'existence de l'échange
						if (exchange) {
							//Complétion de l'échange
							exchange.markdown += message.message;

							//Défilement automatique au bas de la fenêtre
							this.scrollToBottom();
						}
					}

					//Vérification de l'absence d'échange existant
					if (!exchange) {
						//Ajout de la réponse du bot
						this.listeExchanges.push({
							type: 'BOT',
							partId: message.reference,
							markdown: message.message
						});
					}
				}
			}).onFinish({
				next: message => {
					let action: any;

					//Fin d'attente du bot
					this.isLoading = false;

					//Récupération de l'action
					action = message?.data?.action;

					//Vérification de la validité de l'action
					if (action?.valid) {
						//Vérification du type de scénario
						switch (action?.typeScenario) {
						case 'FAQ':
							//Vérification de la liste des articles trouvés
							if (action?.listeArticles?.length) {
								//Ajout de l'échange de réponse
								this.listeExchanges.push({
									type: 'BOT',
									text: this.translateService.instant(this.pluralPipe.transform('chatbot.response.FAQ.result',action.listeArticles.length)),
									limitLinks: 5,
									listeLinks: action.listeArticles.map(article => ({
										text: article.titre,
										onClick: () => {
											//Ouverture de l'article
											this.sharedArticleService.consultArticle(article);
										}
									}))
								});
							}
							break;
						default:
							//Ne rien faire
							break;
						}
					} else {
						//Ajout d'un message générique pour l'absence d'action
						this.listeExchanges.push({
							type: 'BOT',
							text: this.translateService.instant('chatbot.exchange.noAction')
						});
					}

					//Ajout d'un message pour l'évaluation
					this.listeExchanges.push({
						type: 'BOT',
						correlationId: this.correlationId,
						listeStars: [1,2,3,4,5].map(() => {
							//Aucune évaluation initiale
							return { filled: false };
						}),
						finished: true
					});

					//Fermeture des souscriptions
					messaging$.unsubscribe();
				}
			}).onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();
				}
			});
		}
	}

	/**
	 * Mise à jour de l'évaluation de la conversation en cours
	 */
	public updateRating(item: Exchange,index: number) {
		//Vérification de l'index
		if (index >= 0) {
			//Parcours de la liste des étoiles
			(item.listeStars as any[]).forEach((star,i) => {
				//Mise à jour de l'évaluation
				star.filled = i <= index;
			});

			//Définition du score
			item.score = index + 1;
		}

		//Mise à jour du score
		this.chatbotService.evaluateJob(item,{
			score: item.score,
			comment: item.comment
		}).subscribe(result => {
			//Vérification du code d'erreur
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Elément évalué
				item.rated = true;

				//Identifiant de l'échange
				item.idChatbotExchange = result.data?.idChatbotExchange;

				//Vérification du commentaire
				if (item.comment) {
					//Ajout du message de remerciement
					this.listeExchanges.push({
						type: 'BOT',
						text: this.translateService.instant('chatbot.exchange.merci')
					});
				} else {
					//Déclenchement différé
					setTimeout(() => {
						//Scroll en bas de la liste des messages
						this.scrollToBottom();
					});
				}

				//Elément commenté
				item.commented = !!item.comment;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Envoi du commentaire pour l'évaluation du chabot
	 */
	public sendComment(event: KeyboardEvent,item: Exchange) {
		//Appui sur la touche 'Entrée'
		if (event.code == 'Enter' && !event.shiftKey) {
			//Annulation de l'évènement par défaut
			event.preventDefault();

			//Mise à jour de l'évaluation
			this.updateRating(item,-1);
		}
	}

	/**
	 * Réduction/Maximisation de la fenêtre
	 */
	public toggleWindow() {
		//Réduction/Maximisation de la fenêtre
		this.isMinimized = !this.isMinimized;
	}

	/**
	 * Scroll en bas de la liste des messages
	 */
	private scrollToBottom() {
		//Scroll en bas de la liste des messages
		this.scroller.nativeElement.scrollTop = this.scroller.nativeElement.scrollHeight;
	}

	/**
	 * Ouverture des conditions générales d'utilisation
	 */
	public openCGU() {
		//Chargement de l'article CGU
		this.sharedArticleService.loadTypeArticle('CGU').subscribe({
			next: result => {
				//Véficication du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.article)
					//Consultation de l'article
					this.sharedArticleService.consultArticle(result.data.article);
				else
					//Erreur de chargement
					this.toastrService.error(this.translateService.instant('chatbot.cgu.errorLoad'));
			}
		});
	}

	/**
	 * Ouverture de la politique de confidentialité
	 */
	public openConfidentialiteUrl() {
		//Ouverture de l'article en consultation
		window.open(this.confidentialiteUrl,'_new');
	}
}