import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { ReponseDemandeCotationService } from './reponse-demande-cotation.service';

@Component({
	templateUrl: './reponse-cotation-list-item.component.html'
})
export class ReponseCotationListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Statuts des demandes de cotation **/
	mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,public cotationService: CotationService,private historyService: HistoryService,public demandeCotationService: DemandeCotationService,private reponseDemandeCotationService: ReponseDemandeCotationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des statuts
		this.mapStatuts = this.reponseDemandeCotationService.getMapStatuts();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.cotation.idCotation,object?.idCotation && !this.data?.idCotation);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.cotation.ReponseCotation',this.data.idReponseCotation);
	}

	/**
	 * Envoi de la réponse à la cotation
	 */
	doSendReponseCotation(reponseCotation) {
		//Modification du statut en fonction du choix du fournisseur
		reponseCotation.statut = 'TRANSMISE';

		//Sauvegarde de la cotation
		this.reponseDemandeCotationService.saveReponseCotation(reponseCotation).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la réponse à la cotation
				this.data = result.data.reponseCotation

				//Mise à jour de la réponse à la cotation
				this.extraOptions.reponseDemandeCotation = result.data.reponseDemandeCotation;

				//Décrémentation du nombre de cotations à transmettre
				this.extraOptions.resume.nbCotationsATransmettre--;

				//Incrément du compteur des cotations transmises
				this.extraOptions.reponseDemandeCotation.extension.avancementTransmission++;

				//Rafraichissement de l'objet
				this.onObjectChange(this.data);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}
}
