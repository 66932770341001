import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { AuthentificationConfigurationService } from 'src/app/components/authentification/configuration/authentification-configuration.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	templateUrl: './tenant-auth-config-list-item.component.html'
})
export class TenantAuthConfigListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private authentificationConfigurationService: AuthentificationConfigurationService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Héritage
		super();

		//Binding de la méthode
		this.deleteAuthConfig = this.deleteAuthConfig.bind(this);
	}

	/**
	 * Suppression de l'offre
	 */
	public deleteAuthConfig(authConfig: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la configuration
					this.authentificationConfigurationService.deleteAuthConfig(authConfig).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la configuration
								this.onObjectChange(null);

								//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}