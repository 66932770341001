import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types entités
 */
export default class ServiceOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.service';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/Analytique/listeServicesActifs${filter?.idSociete ? '/' + filter.idSociete : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.service.Service';

	/**
	 * Récupération du nom de la clé
	 */
	getKeyFieldName() { return 'idService'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'service.parent',
			getValue: (item: any) => item?.parent ? item.parent.libelle + ' (' + item.parent.reference + ')' : null
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.listeExcludedIdsService && {
			clef: 'idService',
			listeObjects: filter.listeExcludedIdsService,
			typeComparaison: TypeComparaison.NOT_IN,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}
}