import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';
import { SuccessiveSearchComponent } from './successive-search.component';

@Injectable()
export class SuccessiveSearchService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) { }

	/**
	 * Affichage de la recherche successive
	 */
	public showSuccessiveSearch(options: SuccessiveSearchOptions,{ modalClass }: { modalClass: string } = { modalClass: 'modal-lg' }): Observable<{ isDismissed?: boolean,listeSelectionsItems?: Array<any>,nbSelectedItems?: number }> {
		let bsModalRef: BsModalRef<SuccessiveSearchComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(SuccessiveSearchComponent,{
			initialState: {
				options
			},
			backdrop: 'static',
			class: modalClass
		});

		//Retour de la sélection
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.result)
		);
	}
}