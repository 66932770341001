import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types de sinistre
 */
export default class StructureExportRubriqueOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.structureExportRubrique';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/StructureExport/filtreRubriques/${filter.idLigne}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${(item?.position || 0) + 1} - ${item.libelle || translateService.instant('common.nonDefini')}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return (item?.position || 0) + 1; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.export.StructureExportRubrique';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idRubrique'; }

	/**
	 * Tri par défaut
	 */
	defaultOrder = 'position';

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		}];
	}
}