<div class="tile-active-show collapse" [collapse]="!feedback.isDisplayed">
	<div class="tile-sub">
		<div class="form-group">
			<label translate>article.feedback.commentaire</label>
			<div>
				<span>
					<div>{{ feedback.commentaire }}</div>
				</span>
			</div>
		</div>
	</div>
	<div class="tile-footer">
		<button mat-button (click)="close();">
			<span translate>actions.fermer</span>
		</button>
	</div>
</div>