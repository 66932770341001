<div class="container-fluid">
	<form #fournisseurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="fournisseur">
			<fieldset [disabled]="!(null | right:'creation') || typeReferentiel == 'STANDARD' && !rightService.isRoot()">
				<div class="card-body card-padding">
					<h4 translate>fournisseur.description</h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>fournisseur.libelle</label>
								<div>
									<input type="text" name="libelle" class="form-control" #libelle="ngModel" [(ngModel)]="fournisseur.libelle" required maxlength="300" [disabled]="typeReferentiel == 'LOCAL' && !!fournisseur.fournisseurOrigine"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>fournisseur.reference</label>
								<div>
									<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="fournisseur.reference" required maxlength="100" [disabled]="typeReferentiel == 'LOCAL' && !!fournisseur.fournisseurOrigine"/>
								</div>
							</div>
							<div class="form-group" *ngIf="typeReferentiel == 'STANDARD' && rightService.isRoot()">
								<label translate>fournisseur.logo</label>
								<div>
									<div [attachment]="fournisseur" [typeAttachment]="TypeAttachment.FOURNISSEUR" typeLink="LOGO" [public]="true" queueLimit="1" filters="jpeg|jpg|png"></div>
								</div>
							</div>
							<div class="form-group">
								<label translate>fournisseur.natureLAD</label>
								<div>
									<autocomplete name="nature" type="fournisseurNature" [(ngModel)]="fournisseur.natureLAD"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>fournisseur.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="fournisseur.actif"><span translate>fournisseur.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="rightService.isRoot()">
								<label translate>fournisseur.grilleFluidite.item</label>
								<div>
									<mat-checkbox name="grilleFluidite" #grilleFluidite="ngModel" [(ngModel)]="fournisseur.grilleFluidite"><span translate>fournisseur.grilleFluidite.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="typeReferentiel == 'STANDARD' && rightService.isRoot()">
								<label translate>fournisseur.equipementNumeroObligatoire.item</label>
								<div>
									<mat-checkbox name="equipementNumeroObligatoire" #equipementNumeroObligatoire="ngModel" [(ngModel)]="fournisseur.equipementNumeroObligatoire"><span translate>fournisseur.equipementNumeroObligatoire.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>fournisseur.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" [(ngModel)]="fournisseur.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>

				<div class="card-body card-padding m-t-20">
					<fieldset [disabled]="typeReferentiel == 'LOCAL' && !!fournisseur.fournisseurOrigine">
						<h4 translate>fournisseur.listeMetiers.title</h4>

						<basket [model]="fournisseur" propertyName="listeMetiers" type="fournisseurMetier" [displayItem]="getLibelleMetier" colWidthLabel="2" colWidthAutocomplete="4" [labelAutocomplete]="'fournisseur.listeMetiers.ajout' | translate" [labelListe]="'fournisseur.listeMetiers.liste' | translate" [hasError]="isListeMetiersInvalid" [canAddItem]="(null | right:'creation') && !fournisseur.fournisseurOrigine" [isItemDisabled]="isRemoveMetierDisabled" [confirmRemoveItem]="confirmRemoveMetier" (onAdd)="onAddMetier()" (onRemove)="onRemoveMetier()"></basket>

						<div class="form-group" *ngIf="fournisseur.listeMetiers?.length > 0">
							<label translate>fournisseur.listeNatures.title</label>
							<div>
								<a [routerLink]="[]" (click)="showListeNatures();">
									<icon class="icon-hc-2x" name="search"></icon>
									<span [translate]="'fournisseur.listeNatures' | pluralTranslate:nbNatures" [translateParams]="{ nbNatures }"></span>
								</a>
							</div>
						</div>
					</fieldset>
				</div>
			</fieldset>
		</div>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteFournisseur();" *ngIf="(null | right:'suppression') && fournisseur.idFournisseur > 0 && (typeReferentiel == 'LOCAL' || rightService.isRoot())">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="fournisseurForm.invalid || isListeMetiersInvalid()" (click)="saveFournisseur();" *ngIf="(null | right:'creation') && (typeReferentiel != 'STANDARD' || rightService.isRoot())">
				<span [translate]="'actions.'+(fournisseur.idFournisseur > 0 || typeReferentiel != 'LOCAL' ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="fournisseur.idFournisseur" [formDetectChanges]="{ doAction: doClose,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>