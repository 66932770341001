import { TranslateService } from '@ngx-translate/core';
import { DataCustomizationRefValueAddComponent } from 'src/app/components/data-customization/data-customization-ref-value-add.component';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des référentiels personnalisés
 */
export default class CustomReferentielOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.customReferentiel';

	/**
	 * Récupération de l'URL
	 */
	url(): string {
		//Retour de l'URL
		return `/controller/DataCustomizationEntite/filtreCustomRefValues`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle}${item?.reference ? ' (' + item.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.customRef.CustomRefValue';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idCustomRefValue'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: { entite: string,typeReferentiel: string,isShowAll?: boolean  }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.entite && {
			clef: 'dataCustomizationEntite.entite',
			valeur: filter.entite,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},filter?.typeReferentiel && {
			clef: 'typeReferentiel',
			valeur: filter.typeReferentiel,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},!filter?.isShowAll && {
			clef: 'isActif',
			valeur: true,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		}].filter(f => !!f);
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'dataCustomization.customRefValue.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return DataCustomizationRefValueAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.DATA_CUSTOMIZATION_ENTITE;

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'dataCustomization.type.item',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`dataCustomization.type.${item.typeReferentiel}`)
		},{
			title: 'dataCustomization.customRefValue.entite',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`businessData.${item.dataCustomizationEntite.entite.split('.').pop(-1)}`)
		}];
	}
}