<form #affectationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<affectation-content #affectationContent [affectation]="affectation" source="CONDUCTEUR" [canRemoveVehicule]="true" [isCollabDisabled]="true" [user]="user" (showVehicule)="showVehicule(affectation);" (close)="onContentClose($event);"></affectation-content>
		<div>
			<span class="pull-right">
				<button mat-button color="primary" *ngIf="affectation.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation'))" (click)="affectationContent.goToPlanning();">
					<span translate>vehicule.affectation.actions.accederPlanning</span>
				</button>
				<button mat-stroked-button color="primary" class="m-l-10" *ngIf="(TypeDroit.VEHICULE_RESERVATION | right:'creation') && affectation.idAffectation > 0 && affectation.reservation?.statut == 'VALIDEE'" (click)="affectationContent.doActionForReservation('ANNULER');">
					<span translate>actions.annuler</span>
				</button>
				<button mat-stroked-button color="primary" class="m-l-10" *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')) && affectation.idAffectation > 0 && !affectation.reservation" (click)="affectationContent.deleteAffectation();">
					<span translate>actions.supprimer</span>
				</button>
				<button mat-raised-button color="primary" class="m-l-10" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')" (click)="affectationContent.saveAffectation();" [disabled]="affectationForm.invalid || affectation.dateFin && (!affectation.dateDebut || affectation.dateFin < affectation.dateDebut)">
					<span translate>actions.enregistrer</span>
				</button>
			</span>
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: affectationForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
		</div>
	</div>
</form>