import { Component,ElementRef,forwardRef,Input,OnDestroy,OnInit } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { some } from 'lodash-es';

import { SchedulerService } from './scheduler.service';
import { TypeFrequence } from 'src/app/domain/scheduler/scheduler';

@Component({
	selector: 'scheduler',
	templateUrl: './scheduler-link.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SchedulerLinkComponent),
		multi: true
	}]
})
export class SchedulerLinkComponent implements ControlValueAccessor,OnInit,OnDestroy {
	/** Interception d'un changement **/
	onChange: (object: any) => {};

	/** Interception d'un appui **/
	onTouch: (object: any) => {};

	/** Indicateur de désactivation du champ **/
	disabled: boolean = false;

	/** Planification **/
	scheduler: any;

	/** Options de paramétrage du scheduler **/
	@Input() options?: { listeTypesFrequence?: Array<TypeFrequence> }

	/** Liste d'observateurs de changements sur les formulaires englobants **/
	listeFieldsetMutationObservers: Array<{ fieldset: HTMLFieldSetElement,observer: MutationObserver }> = [];

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private schedulerService: SchedulerService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let fieldset: HTMLFieldSetElement = null;

		//Parcours progressif des formulaires parents
		do {
			let fieldsetMutationObserver: MutationObserver;

			//Recherche du formulaire parent le plus proche
			fieldset = (fieldset != null ? fieldset.parentElement : this.elementRef.nativeElement)?.closest('fieldset');

			//Vérification de la présence d'un formulaire
			if (fieldset) {
				//Lecture du champ de désactivation du formulaire
				this.disabled = this.disabled || fieldset.disabled;

				//Ajout d'un observateur d'évènements sur le formulaire
				this.listeFieldsetMutationObservers.push({
					fieldset,
					observer: fieldsetMutationObserver = new MutationObserver(() => {
						//Lecture du champ de désactivation du formulaire
						this.disabled = some(this.listeFieldsetMutationObservers,o => o.fieldset.disabled);
					})
				});

				//Ecoute des changements
				fieldsetMutationObserver.observe(fieldset,{
					attributes: true,
					attributeFilter: ['disabled']
				});
			}
		} while (fieldset != null);
	}

	/**
	 * Affichage de la planification
	 */
	showScheduler() {
		//Affichage de la pop-up
		this.schedulerService.showScheduler(this.scheduler,!this.disabled,this.options).subscribe(scheduler => {
			//Vérification du scheduler
			if (scheduler !== undefined) {
				//Mise à jour de la planification
				this.scheduler = scheduler;

				//Propagation de la modification
				this.onChange(this.scheduler);
			}
		});
	}

	/**
	 * Suppression de la planification
	 */
	removeScheduler() {
		//Vérification de l'activation du composant
		if (!this.disabled) {
			//Suppression de la planification
			this.scheduler = null;

			//Propagation de la suppression
			this.onChange(this.scheduler);
		}
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(scheduler: any) {
		//Définition de la planification
		this.scheduler = scheduler;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Arrêt de l'observation des changements
		this.listeFieldsetMutationObservers?.forEach(o => o.observer.disconnect());
	}
}