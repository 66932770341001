<div class="pull-left lv-avatar">{{ data.compte.numero.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="extraOptions.addCompte(data,deleteLienCompteDetail,{ type: data.compte.type });">{{ data.compte | display:'compte' }}</a></div>
	<ul class="lv-attrs">
		<li *ngIf="['NATURE','SOCIETE'].includes(data.type)"><span translate>comptabilite.compte.type.item</span>&#160;:&#160;<span [translate]="'comptabilite.compte.type.' + data.compte.type"></span></li>
		<li *ngIf="['USER','FOURNISSEUR'].includes(data.type)"><span translate>comptabilite.lienCompteDetail.auxiliaire</span>&#160;:&#160;{{ data.compteAuxiliaire?.numero || ('common.aucun' | translate) }}</li>
		<li *ngIf="data.type == 'SOCIETE' || rightService.isRoot()"><span translate>comptabilite.lienCompteDetail.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data?.compte?.pays.libelle" [ngClass]="'fi-'+(data.compte.pays.code2?.toLowerCase() != 'ja' ? data.compte.pays.code2.toLowerCase() : 'jp')"></span></li>
		<li *ngIf="!rightService.isRoot()"><span translate>comptabilite.lienCompteDetail.planComptable</span>&#160;:&#160;{{ data.compte.planComptable | display:'planComptable' }}</li>
		<li *ngIf="data.compte.type == 'TAXE'">
			<span translate>comptabilite.lienCompteDetail.regleTaxe.item</span>&#160;:&#160;
			<span *ngIf="data.regleTaxe ? data.regleTaxe.listeDetails.length : extraOptions?.mapReglesTaxeForPays?.[data.compte.pays.idPays]">
				<span>{{ data.regleTaxe && data.regleTaxe.libelle || extraOptions.mapReglesTaxeForPays?.[data.compte.pays.idPays]?.libelle }}</span>
			</span>
			<span *ngIf="data?.regleTaxe ? !data.regleTaxe.listeDetails.length : !extraOptions.mapReglesTaxeForPays?.[data.compte.pays.idPays]" translate>common.aucune</span>
		</li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="!rightService.isRoot()"><a [routerLink]="[]" (click)="consultPlanComptable();"><span translate>comptabilite.actions.accederPlanComptable</span></a></li>
			<li><a [routerLink]="[]" (click)="consultCompte();"><span translate>comptabilite.actions.accederCompte</span></a></li>
			<li *ngIf="data.compteAuxiliaire"><a [routerLink]="[]" (click)="consultCompteAuxiliaire();"><span translate>comptabilite.actions.accederCompteAuxiliaire</span></a></li>
			<li *ngIf="TypeDroit.ADMIN_PLAN_COMPTABLE | right:'suppression'"><a [routerLink]="[]" (click)="deleteLienCompteDetail();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>