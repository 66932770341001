import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { MotifService } from './motif.service';

@Component({
	templateUrl: './motif-list-item.component.html'
})
export class MotifListItemComponent extends ReferentielEntiteListItem<any> {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.workflow.WorkflowMotif';

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private historyService: HistoryService,private motifService: MotifService,rightService: RightService,referentielService: ReferentielService,private layoutService: LayoutService,public entiteService: EntiteService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Interception d'une modification du motif
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idMotif,object?.idMotif && !this.data?.idMotif);
	}

	/**
	 * Suppression du motif
	 */
	deleteMotif() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du motif
					this.motifService.deleteMotif(this.liste.selectedSelector,this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Suppression du motif
								this.onObjectChange(null);

								//Rafraichissement du compteur des entités standard
								this.refreshReferentiel();

								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Rechargement de la page
								this.layoutService.reloadSameUrl();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.workflow.WorkflowMotif',this.data.idMotif);
	}
}