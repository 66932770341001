import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { SummernoteComponent } from './summernote.component';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [SummernoteComponent],
	exports: [SummernoteComponent],
})
export class SummernoteModule {}