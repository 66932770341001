<div class="modal-header">
	<h4 class="modal-title"><span translate>vehicule.immatriculation.selectionModeleCatalogue.title</span></h4>
</div>
<div class="modal-body">
	<stepper #stepper="stepper" [listeSteps]="listeSteps"></stepper>
</div>
<div class="modal-footer">
	<button mat-button (click)="selectedModele = null; bsModalRef.hide();">
		<span translate>actions.annuler</span>
	</button>
	<ng-container [ngSwitch]="stepper.getSelectedStep()?.type">
		<ng-container *ngSwitchCase="'CHOIX'">
			<button mat-raised-button color="primary" (click)="stepper.selectStep(listeSteps[selectedChoix.type == 'CHANGEMENT_MODELE' ? 1 : 2])" [disabled]="!selectedChoix.type">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'MODELE'">
			<button mat-raised-button color="primary" (click)="stepper.selectStep(listeSteps[2])">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'RECAPITULATIF'">
			<button mat-raised-button color="primary" (click)="bsModalRef.hide();">
				<span translate>actions.enregistrer</span>
			</button>
		</ng-container>
	</ng-container>
</div>