import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ExtractionDiffusionListItemComponent } from './extraction-diffusion-list-item.component';

@Component({
	templateUrl: './extraction-diffusion-list.component.html'
})
export class ExtractionDiffusionListComponent extends PageContentItem implements OnInit {
	/** Extraction courante **/
	@Input() extraction: any;

	/** Affichage d'une diffusion **/
	@Input() addUserScheduler: Function;

	/** Résumé **/
	@Input() resume: { nbDiffusions: number };

	/** Données **/
	liste: ListView<any,ExtractionDiffusionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ExtractionDiffusionListItemComponent>({
			uri: () => `/controller/UserScheduler/filtreUserSchedulers/com.notilus.data.extraction.Extraction/${this.extraction?.idExtraction}`,
			title: this.translateService.instant('extraction.diffusion.liste.title'),
			component: ExtractionDiffusionListItemComponent,
			isLocal: true,
			extraOptions: {
				addUserScheduler: this.addUserScheduler
			},
			listeFilters: [{
				clef: 'email',
				isDefault: true
			},{
				clef: '*user',
				autocomplete: {
					type: 'user'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			},{
				clef: '*profil',
				autocomplete: {
					type: 'profil'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			defaultOrder: 'typeNotification,email,user.nom,user.prenom,user.matricule,profil.libelle,profil.reference'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idUserScheduler > 0)
			//Mise à jour des compteurs
			this.resume.nbDiffusions--;
	}
}