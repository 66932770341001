
import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { UserAutorisationListItemComponent } from './user-autorisation-list-item.component';

@Component({
	selector: 'user-autorisation-list',
	templateUrl: './user-autorisation-list.component.html'
})
export class UserAutorisationListComponent extends PageContentItem implements OnInit {
	/** Utilisateur courant **/
	@Input() user: any;

	/** Fonction d'édition **/
	@Input() addAutorisation: () => void;

	/** Résumé **/
	@Input() resume: { nbAutorisations: number };

	/** Données **/
	liste: ListView<any,UserAutorisationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UserAutorisationListItemComponent>({
			uri: () => `/controller/Tenant/filtreAutorisations/USER/${this.user?.idUser}`,
			component: UserAutorisationListItemComponent,
			listeFilters: [{
				clef: 'tenant',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'tenant'
				},
				isDefault: true
			}],
			isLocal: true,
			extraOptions: {
				addAutorisation: this.addAutorisation,
				resume: this.resume
			},
			defaultOrder: '-dateFin',
			getKeyFieldName: () => 'idAutorisation'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idAutorisation > 0)
			//Mise à jour des compteurs
			this.resume.nbAutorisations--;
	}
}