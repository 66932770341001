import { Component,ComponentRef,Input,Type,ViewChild,ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { Element } from 'src/app/domain/common/complex-page/element';
import { Options } from 'src/app/domain/common/complex-page/options';

@Component({
	selector: 'complex-page-elements',
	exportAs: 'complexPageElements',
	templateUrl: './complex-page-elements.component.html'
})
export class ComplexPageElementsComponent {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/** Récepteur de composant **/
	@ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;

	/** Elément sélectionné **/
	public selectedElement: Element = null;

	/** Composant sélectionné **/
	private selectedComponent: ComponentRef<any>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private pluralPipe: PluralTranslatePipe) { }

	/**
	 * Récupération de la liste des éléments visibles
	 */
	getListeVisibleElements(): Array<Element> {
		//Retour de la liste des éléments visibles
		return this.options.listeElements?.filter(e => !e.isVisible || e.isVisible()).filter(e => !e.isElementVisible || e.isElementVisible()) || [];
	}

	/**
	 * Récupération de la liste des éléments visibles avec action possible
	 */
	getListeVisibleElementsWithAction(): Array<Element> {
		//Retour de la liste des éléments visibles avec action possible
		return this.options.listeElements?.filter(e => (!e.isVisible || e.isVisible()) && e.doAction && (!e.isActionVisible || e.isActionVisible())).filter(e => !e.isElementVisible || e.isElementVisible()) || [];
	}

	/**
	 * Vérification du masquage des actions présentes sur le bouton (+)
	 */
	isTabActionsHidden() {
		//Retour de l'affichage
		return this.options?.isTabActionsHidden instanceof Function && this.options?.isTabActionsHidden() || this.options?.isTabActionsHidden;
	}

	/**
	 * Vérification du masquage de l'onglet
	 */
	isTabHidden(element: Element) {
		//Retour de l'affichage
		return element.isTabHidden instanceof Function && element.isTabHidden() || element.isTabHidden;
	}

	/**
	 * Définition de l'élément sélectionné
	 */
	setSelectedElement(element: Element) {
		let component: ComponentRef<any>;

		//Vérification de la présence d'un élément
		if (element) {
			//Mise à jour de l'élément sélectionné
			this.selectedElement = element;

			//Suppression du contenu éventuel
			this.holder.clear();

			//Création du composant
			component = this.createComponent(this.holder,element.component);

			//Mise à jour des données
			Object.assign(component.instance,element.retrieveComponentData?.() || {});

			//Mise à jour du composant sélectionné
			this.selectedComponent = component;
		}
	}

	/**
	 * Récupération de l'instance du composant sélectionné
	 */
	getInstance(type?: string): any {
		//Retour de l'instance du composant sélectionné
		return this.selectedElement.type == type || !type ? this.selectedComponent?.instance : null;
	}

	/**
	 * Création du composant
	 */
	private createComponent(viewContainer: ViewContainerRef,type: Type<any>): ComponentRef<any> {
		//Création du composant
		return viewContainer.createComponent(type);
	}

	/**
	 * Récupération du libellé de l'élément
	 */
	getLibelleForElement(element: Element) {
		//Récupération du libellé
		return element.libelle ? element.libelle instanceof Function ? element.libelle(element) : this.translateService.instant(this.pluralPipe.transform(element.libelle,element.count?.() || 0)) : null;
	}

	/**
	 * Récupération du libellé de l'action
	 */
	getLibelleActionForElement(element: Element) {
		//Récupération du libellé de l'action
		return element.libelleAction ? element.libelleAction instanceof Function ? element.libelleAction(element) : this.translateService.instant(element.libelleAction) : null;
	}
}
