import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { EquipementReleveEnergieListItemComponent } from './equipement-releve-energie-list-item.component';

@Component({
	selector: 'equipement-releve-energie-list',
	templateUrl: './equipement-releve-energie-list.component.html'
})
export class EquipementReleveEnergieListComponent extends PageContentItem implements OnInit {
	/** Equipement **/
	@Input() equipement: any;

	/** Fonction d'édition **/
	@Input() addReleveEnergie: () => void;

	/** Résumé **/
	@Input() resume: { nbRelevesCarburant: number };

	/** Données **/
	liste: ListView<any,EquipementReleveEnergieListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,EquipementReleveEnergieListItemComponent>({
			uri: () => `/controller/VehiculeReleveEnergie/filtreRelevesEnergie`,
			component: EquipementReleveEnergieListItemComponent,
			isLocal: true,
			extraOptions: {
				addReleveEnergie: this.addReleveEnergie
			},
			listeFilters: [{
				clef: '*userSource',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				},
				isDefault: true
			},{
				clef: '*fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			},{
				clef: '*vehicule',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule'
				},
				isDefault: true
			}
			],
			listeStaticFilters: [{
				clef: 'equipement.idEquipement',
				valeur: this.equipement.idEquipement,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateReleve,vehicule.reference'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idReleve > 0)
			//Mise à jour des compteurs
			this.resume.nbRelevesCarburant -= 1;
	}
}