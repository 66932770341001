<form #poolAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>pool.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>pool.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="pool.libelle" maxlength="300" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>pool.reference</label>
			<div>
				<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="pool.reference" maxlength="100" required/>
			</div>
		</div>
		<div class="form-group">
			<label translate>pool.controle</label>
			<div>
				<mat-checkbox name="reservationAutomatique" #reservationAutomatique="ngModel" [(ngModel)]="pool.reservationAutomatique"><span translate>pool.info.reservationAutomatique</span></mat-checkbox>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide();">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="poolAddForm.invalid" (click)="saveItem();">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>