import { Component,EventEmitter,Input,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { Element } from 'src/app/domain/common/complex-page/element';
import { Options } from 'src/app/domain/common/complex-page/options';

@Component({
	selector: 'complex-page-actions',
	templateUrl: './complex-page-actions.component.html'
})
export class ComplexPageActionsComponent {
	/** Options d'affichage **/
	@Input() options: Options;

	/** Données **/
	@Input() data: any;

	/** Sélection d'une action **/
	@Output() onActionSelected: EventEmitter<Element> = new EventEmitter();

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private pluralPipe: PluralTranslatePipe) { }

	/**
	 * Récupération de la liste des éléments visibles
	 */
	getListeVisibleElements(): Array<Element> {
		//Retour de la liste des éléments visibles
		return this.options.listeElements?.filter(e => !e.isVisible || e.isVisible()).filter(e => e.libelle) || [];
	}

	/**
	 * Sélection de l'élément
	 */
	selectElement(element: Element) {
		//Sélection de l'élément
		(!element.isElementVisible || element.isElementVisible()) && this.onActionSelected.emit(element);
	}

	/**
	 * Vérification du masquage de l'onglet
	 */
	isTabHidden(element: Element) {
		//Masquage de l'onglet
		return element.isTabHidden instanceof Function && element.isTabHidden() || element.isTabHidden;
	}

	/**
	 * Récupération du libellé
	 */
	getLibelleForElement(element: Element) {
		//Récupération du libellé
		return element.libelle ? element.libelle instanceof Function ? element.libelle(element) : this.translateService.instant(this.pluralPipe.transform(element.libelle,element.count?.() || 0)) : null;
	}

	/**
	 * Navigation en haut de l'écran
	 */
	scrollToTop() {
		//Navigation en haut de l'écran
		window.scroll(0,0);
	}
}
