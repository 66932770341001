import { Injectable } from '@angular/core';
import { DatePipe,CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { Observable,Subject } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { AdvancedSearchOptions } from 'src/app/domain/advanced-search/advanced-search';
import { SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';
import { AdvancedSearchService } from 'src/app/share/components/advanced-search/advanced-search.service';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { FactureService } from 'src/app/components/facture/facture.service';
import { User } from 'src/app/domain/user/user';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { ListView } from 'src/app/domain/common/list-view';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { LotComptableStructureExportSocietesAnomalie } from './lot-comptable-structure-export-societes-anomalie.component';
import { LotComptableStructureExportResult } from './lot-comptable-structure-export-result.component';
import { LotComptableCheckComponent } from './lot-comptable-check.component';

@Injectable()
export class LotComptableService {
	/** Liste des types de facture **/
	private listeTypesFacture: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private advancedSearchService: AdvancedSearchService,private factureService: FactureService,private successiveSearchService: SuccessiveSearchService
			,private datePipe: DatePipe,private currencyPipe: CurrencyPipe,private confirmService: ConfirmService,private toastrService: ToastrService,private messagingService: MessagingService,private bsModalService: BsModalService,private attachmentService: AttachmentService) {
		//Récupération de la liste des types de facture
		this.listeTypesFacture = this.factureService.getListeTypesFacture();

		//Binding
		this.getTitleForFacture = this.getTitleForFacture.bind(this);
		this.getListeAttributesForFacture = this.getListeAttributesForFacture.bind(this);
	}

	/**
	 * Chargement d'un lot comptable
	 */
	public loadLotComptable(idLotComptable: number): Observable<Result> {
		//Chargement du lot comptable
		return this.http.post<Result>(`${environment.baseUrl}/controller/LotComptable/loadLotComptable/${idLotComptable}`,null);
	}

	/**
	 * Enregistrement d'un lot comptable
	 */
	public saveLotComptable(lotComptable: any): Observable<Result> {
		//Enregistrement du lot comptable
		return this.http.put<Result>(`${environment.baseUrl}/controller/LotComptable/saveLotComptable`,lotComptable);
	}

	/**
	 * Suppression d'un lot comptable
	 */
	public deleteLotComptable(idLotComptable: number,onSuccess: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				let messaging$: MessagingObservables;

				//Vérification de la confirmation
				if (isConfirmed) {
					//Démarrage de l'action par websocket
					messaging$ = this.messagingService.init({
						method: 'DELETE',
						entryPoint: `controller/LotComptable/deleteLotComptable/${idLotComptable}`,
						progressConfig: {
							libelle: this.translateService.instant('comptabilite.lotComptable.suppression'),
							icone: 'delete'
						}
					}).onFinish({
						next: () => {
							//Fermeture des souscriptions
							messaging$.unsubscribe();

							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.suppression.success'));

							//Finalisation du traitement
							onSuccess();
						}
					}).onError({
						next: () => {
							//Fermeture des souscriptions
							messaging$.unsubscribe();

							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					}).onResult({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.DROIT)
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.errorRestrictionDroits'));
							else if (result.codeErreur != TypeCodeErreur.NO_ERROR)
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Suppression d'un élément du lot comptable
	 */
	public removeItem(type: 'FACTURE',idObjet: number,isLastItem: boolean) {
		//Suppression d'un élément du lot comptable
		return this.http.put<Result>(`${environment.baseUrl}/controller/LotComptable/removeItem/${type}/${idObjet}/${isLastItem}`,null);
	}

	/**
	 * Déclenchement du déplacement des postes de charge en masse
	 */
	public deleteAllItemsForSelection(messagingOptions: MessagingOptions,liste: ListView<any,any>): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage du déplacement par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Rafraichissement de la liste
					liste?.refresh?.();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Arrêt du traitement
					subject.error(null);
				}
			});

		//Retour du sujet
		return subject;
	}

	/**
	 * Affichage de la poup de recherche des éléments à comptabiliser
	 */
	public showAdvancedSearch(user): Observable<any> {
		let subject: Subject<any> = new Subject<any>();
		let advancedSearchOptions: AdvancedSearchOptions;
		let successiveSearchOptions: SuccessiveSearchOptions;

		//Récupération des options de la recherche avancée
		advancedSearchOptions = this.getAdvancedSearchOptions(user);

		//Affichage de la recherche avancée
		this.advancedSearchService.showAdvancedSearch(advancedSearchOptions).subscribe({
			next: (searchSpec: any) => {
				//Vérification d'un retour
				if (searchSpec) {
					//Récupération des options de la recherche successive
					successiveSearchOptions = this.getSuccessiveSearchOptions(user,searchSpec);

					//Affichage de la recherche successive
					this.successiveSearchService.showSuccessiveSearch(successiveSearchOptions).subscribe({
						next: result => {
							let listeSelections;

							//Vérification du résultat
							if (result) {
								//Vérification du résultat obtenu
								if (result.listeSelectionsItems?.length) {
									//Définition de la sélection d'éléments
									listeSelections = {
										_type: 'com.notilus.model.search.AggregatedItemSelection',
										listeSelectionsByAggregator: result.listeSelectionsItems,
										searchSpec: searchSpec,
										nbSelectedItems: result.nbSelectedItems
									};

									//Retour de la sélection de factures
									subject.next(listeSelections);

									//Fin de traitement
									subject.complete();
								} else if (result.isDismissed) {
									//Ré-ouverture de la pop-up de sélection des filtres
									subject.next(this.showAdvancedSearch(user));
								}
							} else {
								//Emission d'une erreur
								subject.error(null);

								//Fin de traitement
								subject.complete();
							}
						},
						error: (erreur) => {
							//Transmission de l'erreur
							subject.error(erreur);

							//Fin de traitement
							subject.complete();
						}
					});
				} else {
					//Emission d'une erreur
					subject.error(null);

					//Fin de traitement
					subject.complete();
				}
			},
			error: (erreur) => {
				//Transmission de l'erreur
				subject.error(erreur);

				//Fin de traitement
				subject.complete();
			}
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Récupération des options de recherche avancée
	 */
	private getAdvancedSearchOptions(user: User,options?: any): AdvancedSearchOptions {
		//Retour des options de la recherche avancée
		return {
			title: this.translateService.instant('searchEngine.elements.comptabilite.title'),
			subTitle: this.translateService.instant('searchEngine.elements.comptabilite.subTitle'),
			results: this.translateService.instant('searchEngine.elements.comptabilite.result'),
			uri: `/controller/LotComptable/listeItemsByAgregation/FACTURE`,
			isAllowNoneFilters: true,
			listeFiltres: [{
				searchKey: '*societe.libelle.keyword',
				aggregateKey: 'societe.libelle.keyword',
				type: 'label',
				libelleType: 'societe',
				libelleMissing: this.translateService.instant('searchEngine.libelleMissing.nonCommuniquee')
			},{
				searchKey: 'type',
				aggregateKey: 'type',
				type: 'label',
				libelleType: 'type',
				getLibelle: (value) => {
					let typeFacture;

					//Récupération du type de facture
					typeFacture = this.factureService.getListeTypesFacture().filter(type => type.code == value.libelle);

					//Retour du libellé du type
					return typeFacture?.libelle || value.libelle;
				}
			},{
				searchKey: 'fournisseur.libelle',
				aggregateKey: 'fournisseur.libelle.keyword',
				type: 'label',
				libelleType: 'fournisseur'
			},{
				searchKey: 'date',
				aggregateKey: 'date',
				type: 'label',
				typeAggregate: 'month',
				libelleType: 'periodeFacturation',
				getLibelle: (value) => {
					//Retour du mois au format de la locale de l'utilisateur
					return moment.utc(parseInt(value.libelle)).local().format(!user.locale || !user.locale.formatDateCourte || user.locale.formatDateCourte.indexOf('/') != -1 ? 'MM/YYYY' : 'YYYY-MM');
				}
			}],
			listeFiltersExtended: () => {
				let listeFilters = [];

				//Vérification de l'absence de filtres supplémentaires
				if (!options || !options.listeFilters) {
					//Ajout du filtre sur le statut
					listeFilters.push({
						clef: 'statut',
						valeur: 'VALIDEE',
						typeComparaison: 'EQUAL',
						type: 'STRING'
					});

					//Vérification de la liste des sociétés visibles
					if (user?.listeSocietes?.length) {
						//Ajout du filtre sur les sociétés
						listeFilters.push({
							clef: 'societe.idService',
							listeObjects: user.listeSocietes,
							typeComparaison: 'IN',
							type: 'LONG'
						});
					}
				} else {
					//Définition des filtres
					listeFilters = options.listeFilters;
				}

				return listeFilters;
			}
		}
	}

	/**
	 * Récupération des options de recherche successive
	 */
	private getSuccessiveSearchOptions(user: User,searchSpec: any): SuccessiveSearchOptions {
		//Retour des options de la recherche successive
		return {
			title: this.translateService.instant('searchEngine.elements.comptabilite.titleFacture'),
			typeSearchLevel: 'SOCIETE',
			search: searchSpec,
			isShowFilter: true,
			groupeKey: 'societe',
			idGroupeKey: 'idService',
			idItemKey: 'idFacture',
			listeSearchLevels: [{
				type: 'SOCIETE',
				libelle: this.translateService.instant('comptabilite.lotComptable.search.societe'),
				emptyLibelle: this.translateService.instant('searchEngine.libelleMissing.nonCommuniquee'),
				uri: () => `/controller/LotComptable/filtreItemsBySociete/FACTURE`,
				getListeFilters: () => [{
					clef: '*societe.libelle',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: value !== null ? 'societe.idService' : 'societe',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'LONG'
					}
				},
				getListeFiltersExtended(options) {
					//Retour des filtres
					return options?.search?.listeFilter || [];
				}
			},{
				type: 'ITEM',
				libelle: this.translateService.instant('comptabilite.lotComptable.search.factures'),
				uri: () => `/controller/Facture/filtreFactures`,
				getListeFilters: () => [{
					clef: 'fournisseur.libelle',
					isDefault: true
				},{
					clef: 'reference',
					isDefault: true
				}],
				defaultOrder: '-date,reference',
				isListView: true,
				getTitle: this.getTitleForFacture,
				getAvatar: (item) => this.listeTypesFacture.filter(t => t.code == item.type)[0]?.libelle.substring(0,1),
				getListeItemAttributes: this.getListeAttributesForFacture,
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,listeCumulatedFilters).slice(0,1)).concat(options?.search?.listeFilter);

					//Vérification de l'absence de filtres prédéfinis
					if (!options || !options.search?.listeFilters) {
						//Ajout du filtre sur le statut
						listeFilters.push({
							clef: 'statut',
							valeur: 'VALIDEE',
							typeComparaison: 'EQUAL',
							type: 'STRING'
						});
					}

					//Retour des filtres
					return listeFilters;
				}
			}]
		}
	}

	/**
	 * Récupération du titre d'un élément de la liste des factures
	 */
	public getTitleForFacture(item: any): string {
		//Retour du titre
		return `${this.listeTypesFacture.filter(t => t.code == item.type)[0]?.libelle} ${this.translateService.instant('facture.numero',{ numero: item.reference })} (${item.fournisseur.libelle})`;
	}

	/**
	 * Récupération des attributs d'une facture
	 */
	private getListeAttributesForFacture(item: any): Array<{ key: string,value: string }> {
		//Retour de la liste des attributs
		return [{
			key: this.translateService.instant('facture.date.item'),
			value: this.datePipe.transform(item.date,'short')
		},{
			key: this.translateService.instant('facture.montant'),
			value: this.currencyPipe.transform(item.montant,'.2-2',item.devise)
		}];
	}

	/**
	 * Exécution des structures d'export sur un lot comptable
	 */
	public executeStructureExport(lotComptable: any) {
		//Recherche des structures d'export associées au lot comptable
		this.findListeStructuresExportForLot(lotComptable.idLotComptable).subscribe({
			next: listeStructuresExport => {
				let messaging$: MessagingObservables;
				let listeIdsStructureExport: Array<number>;

				//Vérification de la liste des structures d'export
				if (!listeStructuresExport.some(s => s.structureExport == null)) {
					//Initialisation de la WebSocket
					messaging$ = this.messagingService.init({
						entryPoint: `controller/StructureExport/executeStructureExport/${lotComptable.idLotComptable}`,
						params: listeIdsStructureExport = [...new Set(listeStructuresExport.map(s => s.structureExport.idStructure))],
						progressConfig: {
							libelle: this.translateService.instant('comptabilite.lotComptable.structureExport.execution.title',{ numeroLot: lotComptable.numeroLot }),
							icone: 'download'
						},
						method: 'POST'
					}).onFinish({
						next: ({ idSession }) => {
							//Récupération du résultat de l'exécution des structures d'export
							this.retrieveStructureExportResult(idSession,listeIdsStructureExport).subscribe({
								next: listeResults => {
									let isMultiFile: boolean;
									let exportDate: string;

									//Récupération de la date de l'export
									exportDate = this.datePipe.transform(new Date(),'yyyyMMdd_HHmmss');

									//Vérification du mode 'multi-fichiers'
									isMultiFile = listeResults.some(r => r.listeFiles?.length > 1);

									//Vérification du nombre de fichiers
									if (listeResults.length == 1 && !isMultiFile) {
										//Téléchargement du fichier
										this.downloadExportFile(listeIdsStructureExport[0],idSession,listeResults[0].listeFiles[0],exportDate);
									} else if (isMultiFile) {
										//Affichage de la popup de téléchargement des fichiers d'export
										this.bsModalService.show(LotComptableStructureExportResult,{
											initialState: {
												listeResults,
												idSession,
												downloadExportFile: this.downloadExportFile.bind(this),
												exportDate
											}
										});
									}
								}
							});
						}
					}).onError({
						next: () => {
							//Annulation de l'abonnement
							messaging$.unsubscribe();
						}
					});
				} else {
					//Affichage de la liste des sociétés sans structure d'export
					this.bsModalService.show(LotComptableStructureExportSocietesAnomalie,{
						initialState: {
							listeSocietes: listeStructuresExport.filter(s => s.structureExport == null).map(s => s.societe)
						},
						class: 'modal-lg'
					});
				}
			}
		});
	}

	/**
	 * Recherche des structures d'export associées à un lot comptable
	 */
	private findListeStructuresExportForLot(idLotComptable: number): Observable<Array<any>> {
		//Recherche des structures d'export associées à un lot comptable
		return this.http.post<Result>(`${environment.baseUrl}/controller/LotComptable/findListeStructuresExportForLot/${idLotComptable}`,null).pipe(
			first(),
			map(result => result.data.listeStructuresExport),
			filter(listeStructuresExport => listeStructuresExport?.length)
		);
	}

	/**
	 * Récupération du résultat de l'exécution des structures d'export
	 */
	private retrieveStructureExportResult(idSession: string,listeIdsStructureExport: Array<number>): Observable<Array<any>> {
		//Récupération du résultat de l'exécution des structures d'export
		return this.http.post<Result>(`${environment.baseUrl}/controller/StructureExport/retrieveStructureExportResult/${idSession}`,listeIdsStructureExport).pipe(
			first(),
			map(result => result.data.listeResults),
			filter(listeResults => listeResults?.length)
		);
	}

	/**
	 * Téléchargement du fichier d'export
	 */
	private downloadExportFile(idStructureExport: number,idSession: string,file: any,exportDate: string): void {
		let fileName: any;
		let fileNameParts: Array<string>;

		//Récupération des éléments constitutifs du nom du fichier
		fileNameParts = file.fileName.split('.');

		//Vérification du nombre d'éléments
		if (fileNameParts.length >= 2)
			//Ajout de la date de l'export avant l'extension
			fileNameParts[fileNameParts.length - 2] = fileNameParts[fileNameParts.length - 2] + '_' + exportDate;

		//Définition du nom du fichier
		fileName = fileNameParts.join('.');

		//Récupération du contenu du fichier
		this.http.post(`${environment.baseUrl}/controller/StructureExport/retrieveStructureExportFile/${idStructureExport}/${idSession}/${file.idDetail}`,null,{
			responseType: 'arraybuffer'
		}).pipe(first()).subscribe({
			next: response => {
				//Téléchargement du contenu
				this.attachmentService.downloadAttachment(response,null,'application/octet-stream',fileName);
			}
		});
	}

	/**
	 * Contrôle des écritures comptables
	 */
	public showLotComptableChecks(onInit: Function,selectionItems: any,idxSection: number,isEdition: boolean,doCreationAction: Function): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Vérification du mode
		if (!isEdition) {
			//Rafraichissement des écritures comptables
			this.generateEcrituresForSelection(selectionItems).subscribe({
				complete: () => {
					//Réalisation du traitement avant initialisation
					onInit?.();

					//Ouverture de la popup de contrôle des écritures
					this.doShowLotComptableChecks(selectionItems,isEdition,idxSection,doCreationAction).subscribe({
						next: result => {
							//Poursuite du traitement
							subject.next(result);

							//Fin du traitement
							subject.complete();
						}
					});
				}
			});
		} else {
			//Ouverture de la popup de contrôle des écritures
			this.doShowLotComptableChecks(selectionItems,isEdition,idxSection,doCreationAction).subscribe({
				next: result => {
					//Poursuite du traitement
					subject.next(result);

					//Fin du traitement
					subject.complete();
				}
			});
		}

		//Fin de traitement
		return subject;
	}

	/**
	 * Affichage du contrôle des écritures comptables
	 */
	private doShowLotComptableChecks(selectionItems: any,isEdition: boolean,idxSection: number,doCreationAction: any) : Observable<any> {
		let bsModalRef: BsModalRef<LotComptableCheckComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(LotComptableCheckComponent,{
			initialState: {
				selectionItems,
				isEdition,
				idxSection,
				doCreationAction
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content.result),
			filter(result => !!result)
		);
	}

	/**
	 * Rafraichissement des écritures comptables
	 */
	generateEcrituresForSelection(selectionItems: any): Observable<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Initialisation de la WebSocket
		messaging$ = this.messagingService.init({
			entryPoint: 'controller/LotComptable/refreshEcritures',
			outputPoint: '/messaging/lotComptable/refreshEcritures/status',
			params: selectionItems,
			method: 'POST',
			progressConfig: {
				icone: 'autorenew',
				libelle: this.translateService.instant('comptabilite.lotComptable.ecritures.generation'),
				options: {
					canDismiss: false
				}
			}
		}).onFinish({
			next: () => {
				//Poursuite du traitement
				subject.complete();

				//Fermeture des souscriptions
				messaging$.unsubscribe();
			}
		}).onError({
			next: () => {
				//Annulation de l'abonnement
				messaging$.unsubscribe();
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Vérification des écritures comptables
	 */
	public verifyEcritures(selectionItems: any): Observable<Result> {
		//Vérification des écritures comptables
		return this.http.post<Result>(`${environment.baseUrl}/controller/LotComptable/verifyEcritures`,selectionItems);
	}

	/**
	 * Génération d'un plan comptable pour la sélection
	 */
	public generatePlanComptableForSelection(selectionItems: any): Observable<Result> {
		//Génération d'un plan comptable pour la sélection
		return this.http.post<Result>(`${environment.baseUrl}/controller/LotComptable/generatePlanComptableForSelection`,selectionItems);
	}

	/**
	 * Réalisation de la correction du plan comptable
	 */
	public doPlanComptableCorrection(typeControle: any,listeCorrections: any): Observable<Result> {
		//Réalisation de la correction du plan comptable
		return this.http.post<Result>(`${environment.baseUrl}/controller/LotComptable/doPlanComptableCorrection/${typeControle}`,listeCorrections);
	}
}
