<div class="container-fluid">
	<form #controleReglementaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>vehicule.controleReglementaire.libelle</label>
								<div>
									<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="controleReglementaire.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>vehicule.controleReglementaire.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="controleReglementaire.pays" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
								<label translate>vehicule.controleReglementaire.sousType</label>
								<div>
									<selectpicker name="type" #type="ngModel" [(ngModel)]="controleReglementaire.type" required [disabled]="controleReglementaire.idControleReglementaire">
										<mat-option *ngFor="let sousType of listeSousTypes" [value]="sousType.code">{{ sousType.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>vehicule.controleReglementaire.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="controleReglementaire.actif"><span translate>vehicule.controleReglementaire.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': remarque.invalid }">
						<label translate>vehicule.controleReglementaire.remarque</label>
						<div>
							<textarea name="remarque" #remarque="ngModel" class="form-control" [(ngModel)]="controleReglementaire.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>
				<div class="card-body card-padding m-t-20">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>vehicule.controleReglementaire.premierControle</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': firstDebut.invalid }">
								<label>
									<span translate>vehicule.controleReglementaire.debut.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.controleReglementaire.debut.description' | translate"></icon>
								</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="firstDebut" #firstDebut="ngModel" class="form-control" [(ngModel)]="controleReglementaire.firstDebut" nNumber required/>
										<div class="input-group-addon no-border" translate>vehicule.controleReglementaire.mois</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': firstFin.invalid }">
								<label>
									<span translate>vehicule.controleReglementaire.fin.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.controleReglementaire.fin.description' | translate"></icon>
								</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="firstFin" #firstFin="ngModel" class="form-control" [(ngModel)]="controleReglementaire.firstFin" nNumber required/>
										<div class="input-group-addon no-border" translate>vehicule.controleReglementaire.mois</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': firstCritere.invalid }">
								<label translate>vehicule.controleReglementaire.critere</label>
								<div>
									<selectpicker name="firstCritere" #firstCritere="ngModel" [(ngModel)]="controleReglementaire.firstCritere" required>
										<mat-option *ngFor="let critere of listeTypesCriteres" [value]="critere.code">{{ critere.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body card-padding m-t-20">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>vehicule.controleReglementaire.controleUlterieur</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': nextDebut.invalid }">
								<label>
									<span translate>vehicule.controleReglementaire.debut.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.controleReglementaire.debut.description' | translate"></icon>
								</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="nextDebut" #nextDebut="ngModel" class="form-control" [(ngModel)]="controleReglementaire.nextDebut" nNumber required/>
										<div class="input-group-addon no-border" translate>vehicule.controleReglementaire.mois</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nextFin.invalid }">
								<label>
									<span translate>vehicule.controleReglementaire.fin.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.controleReglementaire.fin.description' | translate"></icon>
								</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="nextFin" #nextFin="ngModel" class="form-control" [(ngModel)]="controleReglementaire.nextFin" nNumber required/>
										<div class="input-group-addon no-border" translate>vehicule.controleReglementaire.mois</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nextCritere.invalid }">
								<label translate>vehicule.controleReglementaire.critere</label>
								<div>
									<selectpicker name="nextCritere" #nextCritere="ngModel" [(ngModel)]="controleReglementaire.nextCritere" required>
										<mat-option *ngFor="let critere of listeTypesCriteres" [value]="critere.code">{{ critere.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<button mat-button *ngIf="controleReglementaire.idControleReglementaire" [formDetectChanges]="{ doAction: close,isDirty: controleReglementaireForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteControleReglementaire();" *ngIf="controleReglementaire.idControleReglementaire && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveControleReglementaire();" [disabled]="controleReglementaireForm.invalid">
				<span [translate]="'actions.'+(controleReglementaire?.idControleReglementaire ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
	</form>
</div>