import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { DashboardService } from './dashboard.service';

@Component({
	templateUrl: './dashboard-calendar-customizer.component.html'
})
export class DashboardCalendarCustomizerComponent implements OnInit {
	/** Calendrier **/
	@Input() dashboardChart: any;

	/** Dashboard **/
	@Input() dashboard: any;

	/** Profil **/
	@Input() profil: any;

	/** Entités affichables dans le calendrier **/
	listeEntites: Array<any> = [];

	/** Élément sauvegardé **/
	result: { dashboardChart: any };

	/** Indicateur d'affichage des différentes sections **/
	content: { isTitleToggled?: boolean,isEntiteToggled?: boolean } = {};

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DashboardCalendarCustomizerComponent>,private entiteService: EntiteService,private dashboardService: DashboardService,private toastrService: ToastrService,private translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let listeEntites: Array<any>;
		let listeEntitesActives: Array<any>;

		//Copie des données de personnalisation
		this.dashboardChart = cloneDeep(this.dashboardChart);

		//Récupération de la liste des entités
		listeEntites = this.dashboardChart.calendrier?.listeDetails;

		//Récupération des entités actives
		listeEntitesActives = this.dashboardChart.listeEntites || [];

		//Vérification de la liste des entités
		if (Array.isArray(listeEntites)) {
			//Récupération des entités
			this.listeEntites = listeEntites.filter(entite => {
				//Vérification des entités actives
				return !listeEntitesActives.some(e => e.entite === entite.entite);
			}).concat(listeEntitesActives.map(entite => ({ ...entite,actif: true }))).map(entite => {
				//Récupération du libellé de l'entité
				entite.libelle = this.entiteService.translateEntityCode(entite.entite.split('.').pop());

				return entite;
			}).sort((a,b) => a.libelle?.localeCompare(b.libelle));
		}
	}

	/**
	 * Enregistrement des données de personnalisation
	 */
	saveDashboardChart() {
		//Vérification de la présence du dashboard pour le client
		if (this.dashboard?.idDashboard) {
			//Sauvegarde
			this.doSaveDashboardChart();
		} else {
			//Vérification de la présence d'un profil
			if (this.profil)
				//Définition du profil
				this.dashboard.profil = this.profil;

			//Sauvegarde du dashboard
			this.dashboardService.saveDashboard(this.profil ? 'PROFIL' : 'USER',this.dashboard).subscribe(result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Actualisation du dashboard
					Object.assign(this.dashboard,result.data.dashboard);

					//Sauvegarde des données de personnalisation
					this.doSaveDashboardChart();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			});
		}
	}

	/**
	 * Exécution de l'enregistrement
	 */
	private doSaveDashboardChart() {
		//Définition du dashboard
		this.dashboardChart.dashboard = cloneDeep(this.dashboard);

		//Définition des entités à afficher
		this.dashboardChart.listeEntites = Array.isArray(this.listeEntites) ? this.listeEntites.filter(entite => !!entite.actif) : null;

		//Sauvegarde
		this.dashboardService.saveDashboardChart(this.dashboard.idDashboard,this.dashboardChart).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du dashboard
				this.dashboardChart = result.data.dashboardChart;

				//Définition du résultat
				this.result = { dashboardChart: this.dashboardChart };

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}