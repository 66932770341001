import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GenreListItemComponent } from './genre-list-item.component';
import { GenreService } from './genre.service';

@Component({
	templateUrl: './genre-list.component.html'
})
export class GenreListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,GenreListItemComponent>;

	/** Résumé **/
	resume: { nbCatalogue: number,nbHorsCatalogue: number,nbTous: number } = {
		nbCatalogue: 0,
		nbHorsCatalogue: 0,
		nbTous: 0
	};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private genreService: GenreService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,GenreListItemComponent>({
			uri: () => `/controller/VehiculeReferentiel/filtreGenres${!this.liste?.selectedSelector || this.liste?.selectedSelector == 'TOUS' ? '' : '/' + this.liste?.selectedSelector}`,
			title: this.translateService.instant('vehicule.genre.liste.title'),
			component: GenreListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'type',
				listeValues: [{
					code: 'VEHICULE',
					libelle: this.translateService.instant('vehicule.genre.type.VEHICULE')
				},{
					code: 'ENGIN',
					libelle: this.translateService.instant('vehicule.genre.type.ENGIN')
				}],
				type: TypeFilter.STRING,
				title: this.translateService.instant('search.type')
			}],
			listeSelectors: [{
				code: 'TOUS',
				libelle: this.translateService.instant('vehicule.genre.typeSource.TOUS'),
				count: () => this.resume.nbTous
			},{
				code: 'HORS_CATALOGUE',
				libelle: this.translateService.instant('vehicule.genre.typeSource.ADMINISTRATIF'),
				count: () => this.resume.nbHorsCatalogue
			},{
				code: 'CATALOGUE',
				libelle: this.translateService.instant('vehicule.genre.typeSource.CONSTRUCTEUR'),
				count: () => this.resume.nbCatalogue
			}],
			selectedSelector: 'TOUS',
			extraOptions: {
				resume: this.resume,
				onGenreAdd: this.onGenreAdd
			},
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && this.rightService.isRoot() && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idGenre) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					typeSource: 'EXTERNE_IMMATRICULATION',
					type: 'VEHICULE',
					modeAffichagePrix: 'HT'
				});
			}),
			onRefresh: (liste,result) => {
				//Chargement du résumé
				this.genreService.loadDashboardGenre(liste.selectedSelector == 'HORS_CATALOGUE' ? 'CATALOGUE' : 'HORS_CATALOGUE',result.searchSpec).subscribe({
					next: (resultData: Result) => {
						//Vérification du type de liste
						if (liste.selectedSelector === 'CATALOGUE') {
							//Définition des compteurs
							this.resume.nbCatalogue = result?.totalElements || 0;
							this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
						} else if (liste.selectedSelector === 'HORS_CATALOGUE') {
							//Définition des compteurs
							this.resume.nbHorsCatalogue = result?.totalElements || 0;
							this.resume.nbCatalogue = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbHorsCatalogue + this.resume.nbCatalogue;
						} else if (liste.selectedSelector === 'TOUS') {
							//Définition des compteurs
							this.resume.nbTous = result?.totalElements || 0;
							this.resume.nbHorsCatalogue = resultData?.data?.count || 0;
							this.resume.nbCatalogue = this.resume.nbTous - this.resume.nbHorsCatalogue;
						}
					}
				});
			}
		});
	}

	/**
	 * Interception de l'ajout d'un genre
	 */
	onGenreAdd(item: any) {
		//Vérification de l'élément
		if (item.idGenre > 0) {
			//Mise à jour des compteurs
			this.resume.nbTous += 1;

			//Vérification du type
			if (item.typeSource === 'CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue += 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue += 1;
		}
	}

	/**
	 * Interception de la suppression d'un genre
	 */
	onItemRemove(item): void {
		//Vérification de l'élément
		if (item.idGenre > 0) {
			//Mise à jour des compteurs
			this.resume.nbTous -= 1;

			//Vérification du type
			if (item.typeSource === 'CATALOGUE')
				//Mise à jour des compteurs
				this.resume.nbCatalogue -= 1;
			else
				//Mise à jour des compteurs
				this.resume.nbHorsCatalogue -= 1;
		}
	}
}