import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { GrilleFluiditeImportComponent } from './grille-fluidite-import.component';
import { GrilleFluiditeComponent } from './grille-fluidite.component';

@Injectable()
export class GrilleFluiditeService {
	/** Liste des modes d'affichage **/
	private readonly listeModesAffichage: Array<string> = ['LOYER','PRK','DISTANCE_MENSUELLE','COUT_TOTAL','VARIATION'];

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private translateService: TranslateService,private http: HttpClient) {}

	/**
	 * Affichage de l'import d'une grille de fluidité
	 */
	public showGrilleFluiditeImport(typeObjet: 'CONTRAT_FINANCEMENT' | 'GRILLE_ATTRIBUTION_MODELE_FINANCEMENT' | 'DEMANDE_VEHICULE',idFournisseur: number,idObjet: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage de la popup d'import
		this.bsModalService.show(GrilleFluiditeImportComponent,{
			initialState: {
				typeObjet,
				idFournisseur,
				idObjet,
				onFinish: () => subject.complete()
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Affichage de la grille de fluidité
	 */
	public showGrilleFluidite(grilleFluidite: any,options?: { disableDeletion?: boolean,loiRoulage?: any,lastEcheance?: any,distanceMensuelle?: number,distanceVehicule?: number,dureeContrat?: number,canAddDetail?: boolean }): Observable<{ isDeleted?: boolean,detail?: any }> {
		let bsModalRef: BsModalRef<GrilleFluiditeComponent>;

		//Affichage de la popup d'import
		bsModalRef = this.bsModalService.show(GrilleFluiditeComponent,{
			initialState: {
				grilleFluidite,
				options: options || {}
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(map(() => bsModalRef.content.result));
	}

	/**
	 * Récupération de la liste des modes d'affichage
	 */
	public getListeModesAffichage(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des modes d'affichage
		return this.listeModesAffichage.map(code => ({
			code,
			libelle: this.translateService.instant(`grilleFluidite.modeAffichage.${code}`)
		}))
	}

	/**
	 * Suppression de la grille de fluidité
	 */
	public deleteGrilleFluidite(idGrilleFluidite: number): Observable<Result> {
		//Suppression de la grille de fluidité
		return this.http.delete<Result>(`${environment.baseUrl}/controller/GrilleFluidite/deleteGrilleFluidite/${idGrilleFluidite}`);
	}

	/**
	 * Duplication de la grille de fluidité
	 */
	public duplicateGrilleFluidite(idGrilleFluidite: number,typeObjet: string,idObjet: number): Observable<Result> {
		//Duplication de la grille de fluidité
		return this.http.post<Result>(`${environment.baseUrl}/controller/GrilleFluidite/duplicateGrilleFluidite/${idGrilleFluidite}/${typeObjet}/${idObjet}`,null);
	}
}