import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AffectationEcheanceComponent } from 'src/app/components/vehicule/affectation-echeance/affectation-echeance.component';
import { AffectationEcheanceService } from 'src/app/components/vehicule/affectation-echeance/affectation-echeance.service';
import { AffectationEcheanceListItemComponent } from './affectation-echeance-list-item.component';
import { AffectationEcheanceListComponent } from './affectation-echeance-list.component';
import { AffectationEcheanceDetailComponent } from './affectation-echeance-detail.component';

@NgModule({
	imports: [ShareModule],
	declarations: [AffectationEcheanceListComponent,AffectationEcheanceListItemComponent,AffectationEcheanceComponent,AffectationEcheanceDetailComponent],
	providers: [AffectationEcheanceService]
})
export class AffectationEcheanceModule { }
