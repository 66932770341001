import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocieteService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Enregistrement d'une société
	 */
	public saveSociete(societe: any): Observable<Result> {
		//Enregistrement de la société
		return this.http.put<Result>(`${environment.baseUrl}/controller/Societe/saveSociete`,societe);
	}

	/**
	 * Suppression d'une société
	 */
	public deleteSociete(societe: any): Observable<Result> {
		//Suppression de la société
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Societe/deleteSociete/${societe.idSociete}`);
	}
}