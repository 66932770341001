import { Component } from '@angular/core';

import { AutocompleteSearchItemComponent } from 'src/app/share/components/autocomplete/search/autocomplete-search-item.component';

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Pays'
 */
@Component({
	templateUrl: './pays.search-item.html',
})
export class PaysSearchItemComponent extends AutocompleteSearchItemComponent { }