import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

@Component({
	templateUrl: './template-mail-preview-selection.component.html'
})
export class TemplateMailPreviewSelectionComponent {
	/** Valeur de référence sélectionnée **/
	reference: any;

	/** Type d'autocomplete pour la valeur de référence **/
	autocompleteOptions: AutocompleteOptions;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<TemplateMailPreviewSelectionComponent>) {}
}