import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ImportService } from './import.service';
import { ConnecteurService } from '../connecteur.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'import',
	templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() connecteurExecution: any;

	/** Gestion d'une modification de l'exécution **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(private importService: ImportService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef,private connecteurService: ConnecteurService,public rightService: RightService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde des objets
		this.connecteurExecution = cloneDeep(this.connecteurExecution);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Suppression de l'import
	 */
	deleteImport() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'import
					this.importService.deleteImport(this.connecteurExecution).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'import
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Consultation des logs
	 */
	showLogs() {
		//Vérification de l'historique
		if (this.connecteurExecution?.listeExecutionHistoricals?.length) {
			//Vérification de l'exécution
			if (this.connecteurExecution.apiSession != null) {
				//Ajout de l'exécution courante
				this.connecteurExecution.listeExecutionHistoricals.push({
					apiSession: this.connecteurExecution.apiSession,
					correlationId: this.connecteurExecution.correlationId
				});
			}

			//Affichage de l'historique d'exécution
			this.connecteurService.showExecutionHistory(this.connecteurExecution);
		} else
			//Affichage du résultat de l'exécution
			this.connecteurService.showExecutionResult(this.connecteurExecution);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}