<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idCarburant">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + ' (' + data?.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idCarburant">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>vehicule.carburant.source</span>&#160;:&#160;<span [translate]="'vehicule.carburant.typeSource.'+data.typeSource"></span></li>
		<li *ngIf="data.pays"><span translate>vehicule.carburant.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays?.libelle" [ngClass]="'fi-'+(data.pays?.code2?.toLowerCase() != 'ja' ? data.pays?.code2.toLowerCase() : 'jp')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idCarburant && rightService.isRoot()">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && data.typeSource != 'EXTERNE_CATALOGUE'"><a [routerLink]="[]" (click)="deleteCarburant();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<carburant [carburant]="data" (onObjectChange)="onObjectChange($event)"></carburant>
</div>