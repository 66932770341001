import { ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,orderBy } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { ConfigurationOptionListComponent } from './configuration-option-list.component';
import { ConfigurationRecapitulatifComponent } from './configuration-recapitulatif.component';
import { ConfigurationService } from './configuration.service';

@Component({
	templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit {
	/** Identifiant du modèle **/
	@Input() idModele: number;

	/** Identifiant de l'entité **/
	@Input() idEntite: number;

	/** Type **/
	@Input() type: 'VEHICULE_MODELE' | 'VEHICULE' | 'DEMANDE_VEHICULE' | 'GRILLE_ATTRIBUTION';

	/** Mode d'édition **/
	@Input() isEdition: boolean = false;

	/** Etat du chargement **/
	public isLoading: boolean = true;

	/** Configuration **/
	public configuration: any;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Configuration enregistrée **/
	public savedConfiguration: any = null;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ConfigurationComponent>,private configurationService: ConfigurationService,private toastrService: ToastrService,private translateService: TranslateService,private changeDetectorRef: ChangeDetectorRef) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement
		this.isLoading = true;

		//Chargement de la configuration
		this.loadConfiguration().pipe(first()).subscribe({
			next: () => {
				//Fin du chargement
				this.isLoading = false;

				//Définition du stepper
				this.listeSteps = [{
					type: 'OPTION',
					libelle: 'vehicule.modele.configuration.step.options',
					component: ConfigurationOptionListComponent,
					canActivate: () => true,
					isDone: () => false,
					retrieveComponentData: () => ({
						configuration: this.configuration,
						isShowCheckbox: true
					})
				},{
					type: 'RECAPITULATIF',
					libelle: 'vehicule.modele.configuration.step.recapitulatif',
					component: ConfigurationRecapitulatifComponent,
					canActivate: () => true,
					isDone: () => this.isEdition,
					retrieveComponentData: () => ({
						configuration: cloneDeep(this.configuration)
					})
				}];
			}
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Chargement de la configuration
	 */
	private loadConfiguration(): Observable<boolean> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de la configuration
		this.configurationService.loadConfiguration(this.type,this.idModele,this.idEntite).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la configuration
					this.configuration = result.data.configuration;

					//Vérification de la configuration
					if (this.configuration?.initialized && this.configuration?.listeDetails) {
						//Tri des détails par sélection puis libellé d'option
						this.configuration.listeDetails = orderBy(this.configuration.listeDetails,['selected','option.libelle'],['desc','asc']);

						//Vérification d'un blocage de la configuration
						if (this.configuration.locked)
							//Mode édition interdit
							this.isEdition = false;

						//Vérification du type de source
						if (this.configuration?.typeSource == 'GRILLE_ATTRIBUTION' && this.configuration?.type == 'DEMANDE_VEHICULE') {
							//Parcours des détails
							this.configuration.listeDetails = this.configuration.listeDetails.map(detail => ({
								...detail,
								isPreviouslySelected: detail.selected
							}));
						}

						//Finalisation du sujet
						subject.next(null);
					} else if (!this.configuration?.initialized) {
						//Initialisation de la configuration
						this.configurationService.initConfiguration(this.type,this.idModele,this.idEntite).subscribe({
							complete: () => {
								//Rechargement de la configuration
								this.loadConfiguration().pipe(first()).subscribe({
									next: () => subject.next(null)
								});
							}
						});
					}
				}
			}
		});

		return subject;
	}

	/**
	 * Enregistrement de la configuration
	 */
	saveConfiguration(configuration: any) {
		let localConfiguration: any;

		//Copie de la configuration
		localConfiguration = cloneDeep(configuration);

		//Définition des options de la configuration
		localConfiguration.listeDetails?.forEach(detail => {
			let detailCopy: any;

			//Récupération de la copie du détail courant
			detailCopy = cloneDeep(localConfiguration.listeDetails?.find(d => d.idDetail == detail.idDetail));

			//Définition de la sélection du détail
			detail.selected = detailCopy?.selected || false;

			//Vérification de la copie du détail
			if (detailCopy) {
				//Parcours des sources de sélection par inclusion
				detailCopy.listeSourcesInclusion?.forEach(i => {
					//Conservation de l'identifiant de la contrainte
					i.contrainte = { idContrainte: i.contrainte?.idContrainte };

					//Conservation de l'identifiant du détail
					i.detail = { idDetail: i.detail?.idDetail };
				});

				//Parcours des sources de sélection par obligation
				detailCopy.listeSourcesObligation?.forEach(o => {
					//Conservation de l'identifiant de la contrainte
					o.contrainte = { idContrainte: o.contrainte?.idContrainte };

					//Conservation de l'identifiant du détail
					o.detail = { idDetail: o.detail?.idDetail };
				});
			}

			//Définition des liens de sélection
			detail.listeSourcesSelection = (detailCopy?.listeSourcesInclusion || []).concat(detailCopy?.listeSourcesObligation || []);

			//Suppression des listes de sources
			delete detail.listeSourcesInclusion;
			delete detail.listeSourcesObligation;
		});

		//Enregistrement de la configuration
		this.configurationService.saveConfiguration(localConfiguration).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == 0) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du résultat
					this.savedConfiguration = result.data.configuration;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else {
					//Message d'information
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
