import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PlanEntretienDetailEditComponent } from './plan-entretien-detail-edit.component';
import { PlanEntretienDetailListComponent } from './plan-entretien-detail-list.component';
import { PlanEntretienEditComponent } from './plan-entretien-edit.component';
import { PlanEntretienService } from './plan-entretien.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	selector: 'plan-entretien',
	templateUrl: './plan-entretien.component.html'
})
export class PlanEntretienComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Plan d'entretien courant **/
	public planEntretien: any;

	/** Résumé **/
	private resume: { nbDetails: number } = {
		nbDetails: 0
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private planEntretienService: PlanEntretienService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private loggedUserService: LoggedUserService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.PlanEntretien';

	/**
	 * Récupération de l'identifiant du plan d'entretien à charger
	 */
	getIdObject: () => number = () => this.planEntretien?.idPlanEntretien || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.planEntretien;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'vehicule.planEntretien.libelle',
				key: 'libelle'
			},{
				libelle: 'vehicule.planEntretien.duree',
				key: (item) => `${item.duree} ${this.translateService.instant('vehicule.planEntretien.mois')}`
			},{
				libelle: 'vehicule.planEntretien.distance',
				key: (item) => `${item.distance} ${item.unite?.libelleCourt}`
			},{
				libelle: 'planEntretien.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editPlanEntretien(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editPlanEntretien(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deletePlanEntretien(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicatePlanEntretien(),
				isVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeAlertes: [{
				icon: 'filter',
				title: this.translateService.instant('vehicule.planEntretien.alerte.intervention.title'),
				message: this.translateService.instant('vehicule.planEntretien.alerte.intervention.message'),
				isVisible: () => !this.resume.nbDetails && !this.pageContentService.isOpened(),
				doAction: () => this.addDetail(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'DETAIL',
				libelle: 'vehicule.planEntretien.elements.detail',
				component: PlanEntretienDetailListComponent,
				retrieveComponentData: () => ({
					planEntretien: this.planEntretien,
					resume: this.resume,
					addDetail: this.addDetail.bind(this)
				}),
				doAction: () => this.addDetail(),
				count: () => this.resume.nbDetails,
				libelleAction: this.translateService.instant('vehicule.planEntretien.elements.detail.ajouter'),
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isVisible: () => this.rightService.hasRight(null,'consultation')
			}]
		};

		//Récupération de l'identifiant du plan d'entretien à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idPlanEntretien);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idPlanEntretien: number) {
		//Chargement du plan d'entretien
		this.planEntretienService.loadPlanEntretien(idPlanEntretien).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du plan d'entretien
					this.planEntretien = result.data?.planEntretien;

					//Définition du résumé
					this.resume = {
						nbDetails: result.data?.nbDetails || 0
					}

					//Vérification de l'identifiant du plan d'entretien
					if (!this.planEntretien?.idPlanEntretien) {
						//Création d'un objet vierge
						this.planEntretien = {
							actif: false,
							unite: this.loggedUserService.getUser()?.unite,
							libelle: history.state?.planEntretienOrigine?.libelle,
							duree: history.state?.planEntretienOrigine?.duree,
							distance: history.state?.planEntretienOrigine?.distance,
							remarque: history.state?.planEntretienOrigine?.remarque
						};

						//Edition du plan d'entretien
						this.editPlanEntretien();
					}
				}
			}
		});
	}

	/**
	 * Edition du plan d'entretien
	 */
	private editPlanEntretien() {
		//Ouverture du composant d'édition
		this.pageContentService.open(PlanEntretienEditComponent,{
			data: {
				planEntretien: cloneDeep(this.planEntretien),
				deletePlanEntretien: this.deletePlanEntretien.bind(this),
				idPlanEntretienOrigine: history.state?.planEntretienOrigine?.idPlanEntretien
			}
		}).subscribe({
			next: (data: { planEntretien: any,nbDetails: number }) => {
				//Vérification des données
				if (data.planEntretien) {
					//Mise à jour du plan d'entretien
					Object.assign(this.planEntretien,data.planEntretien);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.planEntretien);
				}

				//Vérification de la présence de détails
				if (data.nbDetails)
					//Mise à jour du nombre de détails
					this.resume.nbDetails = data.nbDetails;
			}
		});
	}

	/**
	 * Suppression du plan d'entretien
	 */
	protected deletePlanEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.planEntretienService.deletePlanEntretien(this.planEntretien))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout/mddification d'un détail
	 */
	protected addDetail(planEntretienDetail: any = { planEntretien: Object.assign({},this.planEntretien),unite: this.planEntretien.unite,actif: false },deletePlanEntretienDetail?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(PlanEntretienDetailEditComponent,{
			data: {
				planEntretienDetail: cloneDeep(planEntretienDetail),
				deletePlanEntretienDetail: deletePlanEntretienDetail
			}
		},'sub').subscribe({
			next: (data: { planEntretienDetail: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('DETAIL',planEntretienDetail.idPlanEntretienDetail != data.planEntretienDetail?.idPlanEntretienDetail);

					//Vérification de la présence d'une création ou d'une suppression
					if (!planEntretienDetail.idPlanEntretienDetail && data.planEntretienDetail?.idPlanEntretienDetail)
						//Incrémentation du compteur
						this.resume.nbDetails++;
					else if (planEntretienDetail.idPlanEntretienDetail && !data.planEntretienDetail?.idPlanEntretienDetail)
						//Décrémentation du compteur
						this.resume.nbDetails--;

					//Mise à jour du détail
					Object.assign(planEntretienDetail,data.planEntretienDetail);
				}
			}
		});
	}

	/**
	 * Duplication du plan d'entretien
	 */
	private duplicatePlanEntretien() {
		//Navigation vers la page de duplication
		this.layoutService.goToByState('vehiculeReferentiel-listePlansEntretien-loadPlanEntretien',{
			routeParams: {
				idPlanEntretien: 0
			},
			routeData: {
				planEntretienOrigine: this.planEntretien
			},
			reloadOnSameUrl: true
		});
	}
}