import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { ConducteurService } from './conducteur.service';


@Component({
	selector: 'conducteur-add',
	templateUrl: './conducteur-add.component.html'
})
export class ConducteurAddComponent extends AutocompleteAddComponent {
	/** Conducteur **/
	public conducteur: any = {};

	/**
	 * Constructeur
	 */
	constructor(public conducteurService: ConducteurService,public bsModalRef: BsModalRef<ConducteurAddComponent>,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de l'élément
		this.conducteurService.saveConducteur(this.conducteur).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du conducteur
					this.addedItem = result.data.conducteur;

					//Vérification de la présence du mail de l'utilisateur
					if (result.data.email)
						//Définition du mail de l'utilisateur
						this.addedItem.user.email = result.data.email;

					//Mise à jour du conducteur par l'utilisateur associé
					this.addedItem = this.addedItem.user;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: this.translateService.instant('actions.doublon.collaborateur')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
