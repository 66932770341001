import { Directive,HostListener,Input,OnChanges,SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { omit } from 'lodash-es';

@Directive({
	selector: '[validator]'
})
export class ValidatorDirective implements OnChanges {
	/** Indicateur de validité de l'élément **/
	@Input() validator: boolean;

	/**
	 * Constructeur
	 */
	constructor(private control: NgControl) {

	}

	/**
	 * Interception de la modification de la valeur du champ
	 */
	@HostListener('ngModelChange',['$event'])
	onModelChange() {
		//Mise à jour différée de l'état de validation
		setTimeout(() => this.updateErrors());
	}

	/**
	 * Interception des changements des inputs
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la présence d'un changement
		if (changes?.validator)
			//Mise à jour différée de l'état de validation
			setTimeout(() => this.updateErrors());
	}

	/**
	 * Mise à jour de la présence d'une erreur pour la règle de validation
	 */
	private updateErrors() {
		//Vérification de l'indicateur
		if (this.validator && this.control.control.errors) {
			//Suppression de l'erreur causée par la règle de validation
			this.control.control.setErrors(omit(this.control.control.errors,['validator']));

			//Vérification de la présence d'erreurs résiduelles
			if (Object.keys(this.control.control.errors || {}).length === 0)
				//Remise à zéro des erreurs
				this.control.control.setErrors(null);
		} else if (!this.validator) {
			//Ajout d'une erreur pour la règle de validation
			this.control.control.setErrors({
				...this.control.control.errors,
				'validator': true
			});
		}
	}
}