import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { AffectationEcheanceListItemComponent } from './affectation-echeance-list-item.component';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { mapEntites } from './affectation-echeance.entites';

@Component({
	templateUrl: './affectation-echeance-list.component.html'
})
export class AffectationEcheanceListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AffectationEcheanceListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AffectationEcheanceListItemComponent>({
			uri: `/controller/Vehicule/filtreAffectationEcheances`,
			title: this.translateService.instant('affectationEcheance.liste.title'),
			isTile: true,
			component: AffectationEcheanceListItemComponent,
			listeFilters: [{
				clef: 'affectation.user',
				title: this.translateService.instant('search.user'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				},
				isDefault: true
			},{
				clef: 'affectation.vehicule',
				title: this.translateService.instant('search.vehicule'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule'
				},
				isDefault: true
			},{
				clef: 'dateEcheance',
				title: this.translateService.instant('search.periode'),
				type: TypeFilter.MONTH
			}],
			getKeyFieldName: () => 'idEcheance',
			defaultOrder: '-dateEcheance,affectation.user.nom,affectation.user.prenom,affectation.user.matricule'
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}