import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { DashboardEmbeddedComponent } from 'src/app/components/dashboard/dashboard-embedded.component';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit,TypeGroupe } from 'src/app/domain/security/right';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ProfilDashboardListComponent } from './profil-dashboard-list.component';
import { ProfilDroitComponent } from './profil-droit.component';
import { ProfilEditComponent } from './profil-edit.component';
import { ProfilUserListComponent } from './profil-user-list.component';
import { ProfilService } from './profil.service';
import { ProfilExtractionListComponent } from './profil-extraction-list.component';
import { ProfilExtractionEditComponent } from './profil-extraction-edit.component';

@Component({
	selector: 'profil',
	templateUrl: './profil.component.html'
})
export class ProfilComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Profil courant **/
	public profil: any;

	/** Liste des dashboards **/
	public listeDashboards: Array<{ type: string,liste: Array<any>}>;

	/** Résumé **/
	private resume: { nbDroitsTotal: number,nbCollaborateurs: number,nbExtractions: number,nbDashboards: number } = {
		nbDroitsTotal: 0,
		nbCollaborateurs: 0,
		nbExtractions: 0,
		nbDashboards: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private profilService: ProfilService,private toastrService: ToastrService,private pageContentService: PageContentService
		,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) {
	}

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.profil.Profil';

	/** Récupération de l'identifiant du profil à charger **/
	getIdObject: () => number = () => this.profil?.idProfil || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'identifiant du profil à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idProfil).subscribe({
					complete: () => {
						//Initialisation de la page complexe
						this.options = {
							listeFields: [{
								libelle: 'profil.libelle',
								key: 'libelle'
							},{
								libelle: 'profil.reference',
								key: 'reference'
							},this.profil.typeProfil != 'MOBILE' && {
								libelle: 'profil.typeRestriction.item',
								key: (data) => this.translateService.instant(`profil.typeRestriction.${data.typeRestriction}`)
							},{
								libelle: 'profil.actif.item',
								key: 'actif',
								type: 'BOOLEAN'
							}],
							listeActions: [{
								libelle: 'actions.modifier',
								doAction: () => this.editProfil(),
								isVisible: () => this.rightService.hasRight(null,'creation'),
								type: TypeAction.EDITION
							},{
								libelle: 'actions.consulter',
								doAction: () => this.editProfil(),
								isVisible: () => !this.rightService.hasRight(null,'creation'),
								type: TypeAction.CONSULTATION
							},{
								libelle: 'actions.dupliquer',
								doAction: () => this.duplicateProfil(),
								isVisible: () => this.rightService.hasRight(null,'creation')
							},{
								libelle: 'actions.supprimer',
								doAction: () => this.deleteProfil(),
								isVisible: () => this.rightService.hasRight(null,'suppression')
							}],
							listeElements: [this.profil.typeProfil != 'MOBILE' && {
								type: 'DROIT',
								libelle: 'profil.elements.droit',
								component: ProfilDroitComponent,
								retrieveComponentData: () => ({
									profil: this.profil,
									editDroits: this.editDroits.bind(this),
									saveProfil: this.saveProfil.bind(this)
								}),
								doAction: () => this.editDroits(null),
								libelleAction: this.translateService.instant('profil.actions.modifierDroits'),
								count: () => this.resume?.nbDroitsTotal || 0,
								isActionVisible: () => this.rightService.hasRight(null,'creation')
							},{
								type: 'USER',
								libelle: 'profil.elements.user',
								component: ProfilUserListComponent,
								retrieveComponentData: () => ({
									profil: this.profil,
									resume: this.resume
								}),
								count: () => this.resume?.nbCollaborateurs || 0,
								isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'consultation'),
								isActionVisible: () => false
							},this.profil.typeProfil != 'PROFIL' && {
								type: 'EXTRACTION',
								libelle: 'profil.elements.extraction',
								component: ProfilExtractionListComponent,
								retrieveComponentData: () => ({
									profil: this.profil,
									addUserScheduler: this.addUserScheduler.bind(this),
									resume: this.resume
								}),
								doAction: () => this.addUserScheduler(),
								count: () => this.resume?.nbExtractions || 0,
								libelleAction: this.translateService.instant('profil.actions.ajouterExtraction'),
								isVisible: () => this.profil?.listeDroits?.length,
								isActionVisible: () => this.rightService.hasRight(null,'creation')
							},this.profil.typeProfil != 'MOBILE' && {
								type: 'DASHBOARD',
								libelle: 'profil.elements.dashboard',
								component: ProfilDashboardListComponent,
								retrieveComponentData: () => ({
									profil: this.profil,
									listeDashboards: this.listeDashboards,
									editProfilDashboard: this.editProfilDashboard.bind(this)
								}),
								count: () => this.resume?.nbDashboards || 0,
								isVisible: () => this.profil?.listeDroits?.length,
								isActionVisible: () => false
							}].filter(e => !!e)
						};
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idProfil: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement du profil
		this.profilService.loadProfil(idProfil).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du profil
					this.profil = result.data?.profil;

					//Définition de la liste des dashboards personnalisés
					this.listeDashboards = this.profilService.getListeDashboards(this.profil,result.data?.listeDashboards);

					//Chargement du resumé
					this.profilService.loadResume(this.profil?.idProfil || 0).subscribe({
						next: (result: Result) => {
							//Définition du résumé
							this.resume.nbCollaborateurs = result.data?.nbCollaborateurs || 0;
							this.resume.nbExtractions = result.data?.nbExtractions || 0;
							this.resume.nbDroitsTotal = result.data?.nbDroitsTotal || 0;
							this.resume.nbDashboards = this.listeDashboards?.reduce((nb,dashboard) => nb + dashboard.liste.length,0) || 0;
						}
					});
				}

				//Vérification de l'identifiant du profil
				if (!this.profil?.idProfil) {
					//Création d'un profil vierge
					this.profil = {
						actif: true,
						typeRestriction: 'AUCUNE'
					};

					//Résumé vierge
					this.resume.nbCollaborateurs = 0;
					this.resume.nbExtractions = 0;
					this.resume.nbDroitsTotal = 0;
					this.resume.nbDashboards = 0;

					//Ouverture du profil en édition
					this.editProfil();

					//Finalisation du chargement des données
					subject.complete();
				}
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Modification des droits
	 */
	public editDroits(typeGroupe: TypeGroupe) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ProfilDroitComponent,{
			data: {
				profil: cloneDeep(this.profil),
				edition: {
					isEdition: true,
					typeGroupe
				},
				saveProfil: this.saveProfil.bind(this)
			}
		},'sub').subscribe((data: { profil: any }) => {
			//Vérification de la présence de données
			if (data?.profil)
				//Mise à jour
				this.profil = data.profil;

			//Rafraichissement de l'onglet
			this.complexPage.setSelectedElementByType('DROIT',true);

			//Mise à jour du compteur
			this.resume.nbDroitsTotal = this.profil?.listeDroits?.length || 0;
		});
	}

	/**
	 * Edition du profil
	 */
	private editProfil() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ProfilEditComponent,{
			data: {
				profil: cloneDeep(this.profil),
				deleteProfil: this.deleteProfil.bind(this),
				saveProfil: this.saveProfil.bind(this)
			}
		}).subscribe({
			next: (profil: any) => {
				//Vérification des données
				if (profil) {
					//Mise à jour du profil
					this.profil = Object.assign(this.profil,profil);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.profil);
				}
			}
		});
	}

	/**
	 * Edition d'un dashboard profil
	 */
	public editProfilDashboard(dashboard: any) {
		//Affichage du dashboard
		this.pageContentService.open(DashboardEmbeddedComponent,{
			data: {
				typeDashboard: dashboard.typeDashboard,
				profil: cloneDeep(this.profil),
				isEdition: true,
				idDashboard: dashboard.idDashboard,
				isScopeFormulaire: dashboard.scopeFormulaire
			}
		},'sub').subscribe((editedDashboard: any) => {
				//Vérification du dashboard
				if (editedDashboard) {
					//Rechargement des données et mise à jour des graphiques
					this.loadData(this.profil.idProfil).subscribe({
						complete: () => {
							//Rafraichissement de l'onglet
							this.complexPage.setSelectedElementByType('DASHBOARD',true);
						}
					});
				}
			}
		);
	}

	/**
	 * Suppression d'un profil
	 */
	public deleteProfil() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.profilService.deleteProfil(this.profil))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Duplication du profil
	 */
	public duplicateProfil() {
		//Duplication du profil
		this.profilService.duplicateProfil(this.profil).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == 0) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Naviguation vers le profil
					this.layoutService.goToByState('listeProfils-profil',{
						routeParams: {
							idProfil: result.data.profil.idProfil
						},
						reloadOnSameUrl: true
					});
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveProfil(profil:any,close: Function) {
		//Enregistrement de l'objet
		this.profilService.saveProfil(profil).subscribe({
			next: (result: Result) => {
				let listeDoublons: Array<string> = [];

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(profil,result.data?.profil);

					//Redéfinition du profil
					Object.assign(this.profil,profil);

					//Définition de la liste des dashboards
					Object.assign(this.listeDashboards,result.data?.listeDashboards);

					//Fermeture du formulaire (si nécessaire)
					close?.(profil);

					//Rechargement  du resumé
					this.profilService.loadResume(this.profil?.idProfil || 0).subscribe({
						next: (result: Result) => {
							//Définition du résumé
							this.resume.nbCollaborateurs = result.data?.nbCollaborateurs || 0;
							this.resume.nbExtractions = result.data?.nbExtractions || 0;
							this.resume.nbDroitsTotal = result.data?.nbDroitsTotal || 0;
							this.resume.nbDashboards = this.listeDashboards?.reduce((nb,dashboard) => nb + dashboard.liste.length,0) || 0;
						}
					});
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Ajout/modification d'une extraction
	 */
	private addUserScheduler(userScheduler: any = { typeNotification: 'PROFIL' },deleteUserScheduler?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ProfilExtractionEditComponent,{
			data: {
				profil: this.profil,
				userScheduler: cloneDeep(userScheduler),
				deleteUserScheduler
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('EXTRACTION',data.idUserScheduler != userScheduler.idUserScheduler);

					//Vérification de la présence d'une création ou d'une suppression
					if (!userScheduler.idUserScheduler && data)
						//Incrémentation du compteur
						this.resume.nbExtractions++;
					else if (userScheduler.idUserScheduler && !data.idUserScheduler)
						//Décrémentation du compteur
						this.resume.nbExtractions--;

					//Mise à jour de la valeur
					Object.assign(userScheduler,data);
				}
			}
		});
	}
}