import { Component,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { LoginService } from 'src/app/share/login/login.service';

@Component({
	selector: 'account-password',
	templateUrl: './account-password.component.html'
})
export class AccountPasswordComponent {
	/** Changement du mot de passe **/
	changePassword: { oldPassword: string,password: string,password2: string } = {} as any;

	/** Formulaire **/
	@ViewChild('accountPasswordForm') accountPasswordForm: NgForm;

	/**
	 * Constructeur
	 */
	constructor(private loginService: LoginService,private loggedUserService: LoggedUserService,private translateService: TranslateService,private toastrService: ToastrService) { }

	/**
	 * Vérification de la validité d'une règle pour le mot de passe
	 */
	isRuleValid(rule: 'caracteres' | 'majuscules' | 'minuscules' | 'chiffres' | 'speciaux'): boolean {
		//Vérification du type de règle
		return this.changePassword.password && this.loginService.isRuleValid(this.changePassword.password,rule);
	}

	/**
	 * Validation de la complexité du mot de passe
	 */
	isPasswordComplexe(): boolean {
		//Validation du mot de passe
		return this.loginService.isPasswordComplexe(this.changePassword.password);
	}

	/**
	 * Changement du mot de passe
	 */
	doChangePassword() {
		//Changement du mot de passe
		this.loginService.changePassword(this.loggedUserService.getUser(),this.changePassword).subscribe({
			next: (result: Result) => {
				//Vérification du résultat
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Ré-initialisation du changement de mot de passe
					this.changePassword = {} as any;

					//Message de succès
					this.toastrService.success(this.translateService.instant('account.password.success'))
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('account.password.error'))
			},
			error: () => {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('account.password.error'))
			}
		});
	}

	/**
	 * Vérification de l'état du formulaire
	 */
	public canChangePassword(): boolean {
		//Vérification de l'état du formulaire
		return this.accountPasswordForm?.valid;
	}
}
