<div class="pull-left lv-avatar" [translate]="'extraction.diffusion.liste.avatar.'+data.typeNotification"></div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="extraOptions?.addUserScheduler?.(data,deleteUserScheduler)">
		<span *ngIf="data.typeNotification == 'USER'">{{ data.user | display:'user' }}</span>
		<span *ngIf="data.typeNotification == 'PROFIL'">{{ data.profil | display:'profil' }}</span>
		<span *ngIf="data.typeNotification == 'EMAIL'">{{ data.email }}</span>
	</a>
</div>
<ul class="lv-attrs">
	<li><span translate>extraction.diffusion.frequence</span>&#160;:&#160;<span [translate]="'scheduler.frequence.' + data.scheduler?.type"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idUserScheduler && (null | right:'creation')">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteUserScheduler();"><span translate>actions.supprimer</span></a></li>
	</ul>
</div>