import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { UserService } from './user.service';

@Component({
	selector: 'user-user-responsable-edit',
	templateUrl: './user-user-responsable-edit.component.html'
})
export class UserUserResponsableEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() userResponsable: any;

	/** Suppression de l'objet courant **/
	@Input() deleteUserResponsable: (userResponsable: any,close: Function) => void;

	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des types de responsables **/
	public listeTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types de valideurs */
	public listeTypesValideur: Array<{ code: string,libelle: string }>;

	/** Copie de l'objet **/
	public savedUserResponsable: any;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,public rightService: RightService,private displayPipe: DisplayPipe) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sauvegarde de l'objet
		this.savedUserResponsable = cloneDeep(this.userResponsable);

		//Définition de la liste des types de responsable
		this.listeTypes = this.userService.getListeTypesUserResponsable();

		//Vérification de la présence d'un type
		if (this.userResponsable?.type)
			//Définition de la liste des types de valideur
			this.listeTypesValideur = this.userService.getListeTypesValideur(this.userResponsable.type);

		//Vérification du type de service
		if (this.userResponsable?.service?.type == 'SOCIETE') {
			//Définition de la société
			this.userResponsable.societe = this.userResponsable.service;

			//Suppression du service
			this.userResponsable.service = null;
		}
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveUserResponsable(isForceUpdate: boolean = false): void {
		//Vérification de l'objet sauvegardé
		if (this.savedUserResponsable?.idObject) {
			//Copie des propriétés de l'objet
			this.userResponsable.previousType = this.savedUserResponsable.type;
			this.userResponsable.previousTypeValideur = this.savedUserResponsable.typeValideur;
		}

		//Enregistrement du userResponsable
		this.userService.saveUserResponsable(this.userResponsable,isForceUpdate).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.userResponsable,result.data?.userResponsable);

					//Fermeture de la fenêtre
					this.close({ userResponsable: this.userResponsable });
				} else if (result?.codeErreur == TypeCodeErreur.SAVE) {
					//Affichage d'un message de confirmation
					this.confirmService.showConfirm(this.translateService.instant(`user.userResponsable.confirmation.${this.userResponsable.type}`,{
						type: this.translateService.instant(`user.userResponsable.type.${this.userResponsable.type}.${this.userResponsable.typeValideur}`),
						userExistant: this.displayPipe.transform(result.data?.userExistant,'user'),
						user: this.displayPipe.transform(this.userResponsable?.user,'user'),
						service: this.userResponsable.service?.libelle || this.translateService.instant('common.aucun'),
						societe: this.userResponsable.societe?.libelle,
						pool: this.userResponsable.pool?.libelle
					}),{ actionColor: 'primary' }).pipe(filter(isConfirmed => !!isConfirmed)).subscribe({
						next: () => {
							//Redéclenchement de l'enregistrement forcé
							this.saveUserResponsable(true);
						}
					});
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout d'un message
					listeDoublons.push(this.translateService.instant(`user.userResponsable.doublon.${result?.data?.typeError}`));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Interception d'un changement sur le type
	 */
	public onTypeChange(type: any) {
		//Vérification du type modifié
		if (type == this.savedUserResponsable.type) {
			//Récupération des propriétés du périmètre sauvegardé
			this.userResponsable.societe = this.savedUserResponsable.societe;
			this.userResponsable.service = this.savedUserResponsable.service;
			this.userResponsable.pool = this.savedUserResponsable.pool;
			this.userResponsable.user = this.savedUserResponsable.user;
			this.userResponsable.typeValideur = this.savedUserResponsable.typeValideur;

			//Vérification du type de service
			if (this.userResponsable.service?.type == 'SOCIETE') {
				//Définition de la société
				this.userResponsable.societe = this.userResponsable.service;

				//Suppression du service
				this.userResponsable.service = null;
			}
		} else {
			//Suppression des propriétés
			this.userResponsable.societe = null;
			this.userResponsable.service = null;
			this.userResponsable.pool = null;
			this.userResponsable.user = null;
			this.userResponsable.typeValideur = null;
		}

		//Définition de la liste des types de valideur
		this.listeTypesValideur = this.userService.getListeTypesValideur(this.userResponsable.type);
	}
}