import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';

@Component({
	selector: 'tenant-compte-service',
	templateUrl: './tenant-compte-service.component.html'
})
export class TenantCompteServiceComponent implements AfterContentChecked {
	/** Liste des comptes de service **/
	@Input() listeComptesService: any;

	/** Etat **/
	@Input() disabled: boolean;

	/** Résultat **/
	result: { listeComptesService: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<TenantCompteServiceComponent>,private displayPipe: DisplayPipe) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la liste des comptes de service
	 */
	public saveCompteService() {
		//Définition du résultat
		this.result = {
			listeComptesService: this.listeComptesService
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la liste des comptes de service
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Récupération du libellé
	 */
	getLibelleCompteService: (compteService: any) => string = (compteService) => `${this.displayPipe.transform(compteService,'compteService')}${compteService?.user?.login ? ' (' + compteService?.user?.login + ')' : ''}`;
}