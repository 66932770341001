import { Component,Input,ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'workflow-detail-line',
	templateUrl: './workflow-detail-line.component.html',
	styles: ['workflow-detail-line { display: contents; }'],
	encapsulation: ViewEncapsulation.None
})
export class WorkflowDetailLineComponent {
	/** Liste des étapes **/
	@Input() readonly listeEtapes?: Array<any>;

	/** Mode **/
	@Input() readonly mode: 'vertical' | 'horizontal' | 'counter' | 'counter-branche';

	/** Enfant **/
	@Input() readonly isChild: boolean = true;

	/** Index courant de l'étape */
	@Input() readonly index: number;

	/**
	 * Dénombrement des éléments constitutifs d'une branche
	 */
	public countItemsForListeEtapes(listeEtapes: Array<any> = this.listeEtapes,index: number = this.mode == 'counter-branche' ? 0 : this.index + 1): string {
		//Parcours des étapes
		return listeEtapes?.slice(index).reduce((current,etape) => current + (etape?.listeBranches?.length > 0 ? etape.listeBranches?.reduce((c,branche) => c + (branche?.listeEtapes?.length > 0 ? this.countItemsForListeEtapes(branche?.listeEtapes,0) + 1 : 1),0) : 1),0) || 0;
	}
}