import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ArticleFeedbackListItemComponent } from './article-feedback-list-item.component';

@Component({
	selector: 'article-feedback-list',
	templateUrl: './article-feedback-list.component.html'
})
export class ArticleFeedbackListComponent extends PageContentItem implements OnInit {
	/** Article **/
	@Input() article: any;

	/** Résumé **/
	@Input() resume: { nbFeedbacks: number };

	/** Données **/
	liste: ListView<any,ArticleFeedbackListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ArticleFeedbackListItemComponent>({
			uri: () => `/controller/Aide/filtreFeedbacks/${this.article.idArticle}`,
			component: ArticleFeedbackListItemComponent,
			isTile: true,
			isLocal: true,
			listeFilters: [{
				clef: 'score',
				isDefault: true
			},{
				clef: 'tenant.code',
				isDefault: true
			},{
				clef: 'user.nom',
				isDefault: true
			},{
				clef: 'user.prenom',
				isDefault: true
			}],
			defaultOrder: '-date,user.nom'
		});
	}
}