import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { routes } from 'src/app/routes';
import { TypeDroit,TypeTenant } from 'src/app/domain/security/right';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { User } from 'src/app/domain/user/user';
import { LoginService } from 'src/app/share/login/login.service';

@Injectable()
export class ProfilService {
	/** Liste des types de restriction **/
	private listeTypesRestriction: Array<{ code: string,listeRestrictions?: Array<TypeTenant> }> =[{
		code: 'AUCUNE'
	},{
		code: 'SOCIETE'
	},{
		code: 'SERVICE'
	},{
		code: 'VEHICULE_POOL'
	},{
		code: 'CLIENT',
		listeRestrictions: [TypeTenant.ROOT,TypeTenant.REVENDEUR]
	}];

	/** Liste des types de restriction dynamique **/
	private listeTypesRestrictionDynamique: Array<string> = ['RESPONSABLE_1','RESPONSABLE_2','RESPONSABLE_3','RESPONSABLE_4','ANY'];

	/** Liste des types de dashboard **/
	private listeTypesDashboard: Array<string> = ['GENERAL','METIER','FORMULAIRE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private dashboardService: DashboardService,private entiteService: EntiteService,private loginService: LoginService) { }

	/**
	 * Récupération de la liste des types de restriction en fonction du type de tenant
	 */
	public getListeTypesRestriction(): Array<{ code: string,libelle: string }> {
		let user: User;

		//Récupération de l'utilisateur
		user = this.loginService.getSession()?.user;

		//Retour de la liste des types de restriction
		return this.listeTypesRestriction.filter(typeResctriction => !typeResctriction.listeRestrictions || (user?.tenant && typeResctriction.listeRestrictions.includes(user.tenant.type))).map(type => ({
			code: type.code,
			libelle: this.translateService.instant(`profil.typeRestriction.${type.code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de restriction dynamique
	 */
	public getListeTypesRestrictionDynamique(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de restriction dynamique
		return this.listeTypesRestrictionDynamique.map(code => ({
			code,
			libelle: this.translateService.instant(`profil.typeRestrictionDynamique.${code}`)
		}));
	}

	/**
	 * Chargement du profil
	 */
	public loadProfil(idProfil: number): Observable<Result> {
		//Chargement du profil
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadProfil/${idProfil}`,null);
	}

	/**
	 * Suppression du profil
	 */
	public deleteProfil(profil: any): Observable<Result> {
		//Suppression du profil
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Profil/deleteProfil/${profil.idProfil}`);
	}

	/**
	 * Enregistrement du profil
	 */
	public saveProfil(profil: any): Observable<Result> {
		//Enregistrement du profil
		return this.http.put<Result>(`${environment.baseUrl}/controller/Profil/saveProfil`,profil);
	}

	/**
	 * Chargement du résumé du profil
	 */
	public loadResume(idProfil: number): Observable<Result> {
		//Chargement du résumé pour le profil
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadResume/${idProfil}`,null);
	}

	/**
	 * Duplication du profil
	 */
	public duplicateProfil(profil: any): Observable<Result> {
		//Duplication du profil
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/duplicateProfil/${profil.idProfil}`,null);
	}

	/**
	 * Récupération des dashboards par type
	 */
	public getListeDashboards(profil: any,listeDashboards?: Array<any>): Array<{ type: string,liste: Array<any> }> {
		//Parcours des types de dashboards
		return this.listeTypesDashboard.map(type => ({
			type,
			liste: this.getListeDashboardsByType(profil,type,listeDashboards)
		}));
	}

	/**
	 * Vérification de la présence du droit
	 */
	private hasRightActif(profil: any,droit: TypeDroit): boolean {
		//Vérification de la présence du droit
		return profil.listeDroits?.some(d => d.typeDroit == droit && d.consultation);
	}

	/**
	 * Récupération de la liste des dashboards par type
	 */
	private getListeDashboardsByType(profil: any,type: string,listeDashboards?: Array<any>) {
		let liste: Array<any> = [];
		let listeProfilDashboards: Array<any> = [];
		let dashboard: any;

		//Vérification de la liste des dashboards personnalisés associés au profil
		if (listeDashboards)
			//Définition de la liste des dashboards personnalisés
			listeProfilDashboards = listeDashboards;

		//Vérification du type de dashboard
		if (type == 'GENERAL') {
			//Recherche du dashboard
			dashboard = listeProfilDashboards.find(d => !d.entity);

			//Vérification du dashboard
			if (!dashboard)
				//Initialisation de la liste
				liste = [{ listeDashboardCharts: [],scopeFormulaire: false }];
			else
				//Ajout du dashboard
				liste.push(dashboard);

			//Retour
			return liste;
		} else if (type == 'METIER') {
			//Vérification de la liste des droits
			if (profil?.listeDroits?.length) {
				//Recherche des routes contenant des dashboards
				routes.filter(r => r.data?.typeDashboard && r.data?.typeDroit && typeof r.data?.typeDroit == 'string' && this.hasRightActif(profil,r.data?.typeDroit)).forEach(route => {
					let entite: any;
					let dashboard: any;

					//Récupération de l'entité
					entite = this.dashboardService.getEntityForDashboard(route.data.typeDashboard);

					//Vérification de l'entité
					if (entite) {
						//Récupération du dashboard
						dashboard = listeProfilDashboards.find(d => d.entity == entite && type == 'METIER' && !d.scopeFormulaire);

						//Vérification du dashboard
						if (!dashboard) {
							//Définition du dashboard par défaut
							dashboard = {
								entity : entite,
								listeDashboardCharts: [],
								scopeFormulaire: false
							}
						}

						//Définition de libellé du dashboard
						dashboard.libelle = this.entiteService.translateEntityCode(entite.split('.').pop(-1));

						//Définition du type de dashboard
						dashboard.typeDashboard = route.data.typeDashboard;

						//Ajout du dashboard
						liste.push(dashboard);
					}
				});
			}

			//Retour
			return liste;
		} else {
			//Vérification de la liste des droits
			if (profil?.listeDroits?.length) {
				//Recherche des dashboards formulaire
				this.dashboardService.getListeDashboardsForFormulaire().filter(d => this.hasRightActif(profil,d.typeDroit)).forEach(d => {
					let entite: any;
					let dashboard: any;

					//Récupération de l'entité
					entite = d.type;

					//Vérification de l'entité
					if (entite) {
						//Récupération du dashboard
						dashboard = listeProfilDashboards.find(d => d.entity == entite && type == 'FORMULAIRE' && d.scopeFormulaire);

						//Vérification du dashboard
						if (!dashboard) {
							//Définition du dashboard par défaut
							dashboard = {
								entity : entite,
								listeDashboardCharts: [],
								scopeFormulaire: true
							}
						}

						//Définition de libellé du dashboard
						dashboard.libelle = this.entiteService.translateEntityCode(entite.split('.').pop(-1));

						//Définition du type de dashboard
						dashboard.typeDashboard = d.typeDashboard;

						//Ajout du dashboard
						liste.push(dashboard);
					}
				});
			}

			//Retour
			return liste;
		}
	}
}