'use strict';

function Event(eventType) {
  this.type = eventType;
}
Event.prototype.initEvent = function (eventType, canBubble, cancelable) {
  this.type = eventType;
  this.bubbles = canBubble;
  this.cancelable = cancelable;
  this.timeStamp = +new Date();
  return this;
};
Event.prototype.stopPropagation = function () {};
Event.prototype.preventDefault = function () {};
Event.CAPTURING_PHASE = 1;
Event.AT_TARGET = 2;
Event.BUBBLING_PHASE = 3;
module.exports = Event;