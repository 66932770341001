import { CurrencyPipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,defer,of } from 'rxjs';
import { filter,map,switchMap,tap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FactureDetailEditComponent } from './facture-detail-edit.component';
import { FactureDetailListComponent } from './facture-detail-list.component';
import { FactureEditComponent } from './facture-edit.component';
import { FactureService } from './facture.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'facture',
	templateUrl: './facture.component.html'
})
export class FactureComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Facture courante **/
	public facture: any;

	/** Résumé */
	private resume: { nbDetails: number,nbDetailsAnomalie: number } = {
		nbDetails: 0,
		nbDetailsAnomalie: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	public typeAttachment: TypeAttachment = TypeAttachment.FACTURE;

	/**
	 * Constructeur
	 */
	constructor(private factureService: FactureService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private currencyPipe: CurrencyPipe,private loggedUserService: LoggedUserService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.facture.Facture';

	/**
	 * Récupération de l'identifiant de la facture à charger
	 */
	getIdObject: () => number = () => this.facture?.idFacture || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.facture;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement de la facture
		this.loadData(this.activatedRoute.snapshot.params.idFacture).subscribe({
			next: () => {
				//Initialisation de la page complexe
				this.options = {
					listeFields: [{
						libelle: 'facture.fournisseur',
						key: 'fournisseur.libelle'
					},{
						libelle: 'facture.reference.item',
						key: (data) => this.translateService.instant(`facture.reference.${data.type}`,{ reference: data.reference })
					},{
						libelle: 'facture.dateEmission',
						key: 'date',
						type: 'DATE',
						format: 'shortDate'
					},{
						libelle: 'facture.montantTTC.item',
						key: (data: any) => this.currencyPipe.transform(data.montant,'.2-2',data.devise)
					},{
						libelle: 'facture.dateEcheance',
						key: 'dateEcheance',
						type: 'DATE',
						format: 'shortDate',
						isSecondary: true
					},{
						libelle: 'facture.dateDebut',
						key: 'dateDebut',
						type: 'DATE',
						format: 'shortDate',
						isSecondary: true
					},{
						libelle: 'facture.dateFin',
						key: 'dateFin',
						type: 'DATE',
						format: 'shortDate',
						isSecondary: true
					}],
					listeActions: [{
						libelle: 'actions.modifier',
						doAction: () => this.editFacture(),
						isVisible: () => this.rightService.hasRight(null,'creation') && !this.facture.interfaceFournisseur && !['VALIDEE','COMPTABILISEE'].includes(this.facture.statut),
						type: TypeAction.EDITION
					},{
						libelle: 'actions.consulter',
						doAction: () => this.editFacture(),
						isVisible: () => !this.rightService.hasRight(null,'creation') || ['VALIDEE','COMPTABILISEE'].includes(this.facture.statut) || this.facture.interfaceFournisseur,
						type: TypeAction.CONSULTATION
					},{
						libelle: 'actions.valider',
						doAction: () => this.doAction('VALIDER'),
						isVisible: () => this.rightService.hasRight(null,'creation') && this.facture.statut == 'INTEGREE' && !this.facture.anomalie && !this.facture.exclusionCompta && this.facture.etat != 'BROUILLON'
					},{
						libelle: 'actions.rejeter',
						doAction: () => this.doAction('REJETER'),
						isVisible: () => this.rightService.hasRight(null,'creation') && this.facture.statut == 'VALIDEE' && !this.facture.exclusionCompta && this.facture.etat != 'BROUILLON'
					},{
						libelle: 'facture.actions.accederLotComptable',
						doAction: () => this.goToLotComptable(),
						isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation') && this.facture.lotComptable
					},{
						libelle: 'actions.supprimer',
						doAction: () => this.deleteFacture(),
						isVisible: () => this.rightService.hasRight(null,'suppression') && this.facture.statut == 'INTEGREE'
					}],
					listeAlertes: [{
						icon: 'home',
						title: this.translateService.instant('facture.alerte.anomalieSocieteFacturee.title'),
						message: this.translateService.instant('facture.alerte.anomalieSocieteFacturee.message'),
						isVisible: () => !this.facture?.codeCompteFacturation && !this.facture?.societe && !this.pageContentService.isOpened(),
						doAction: () => this.showSelectionSociete(),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					},{
						icon: 'home',
						title: this.translateService.instant('facture.alerte.anomalieCompteFacturation.title',{ code: this.facture?.codeCompteFacturation }),
						message: this.translateService.instant('facture.alerte.anomalieCompteFacturation.message'),
						isVisible: () => this.facture?.codeCompteFacturation && !this.facture.societe && !this.pageContentService.isOpened(),
						doAction: () => this.showReconciliationCompteSociete(),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					},{
						icon: 'attach_money',
						title: this.translateService.instant('facture.alerte.aucunDetail.title'),
						message: this.translateService.instant('facture.alerte.aucunDetail.message'),
						isVisible: () => this.resume.nbDetails == 0 && !this.pageContentService.isOpened(),
						doAction: () => this.addDetail(),
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.facture.interfaceFournisseur
					},{
						icon: 'warning',
						title: this.translateService.instant('facture.alerte.anomalieDetail.title'),
						message: this.translateService.instant('facture.alerte.anomalieDetail.message'),
						isVisible: () => this.resume?.nbDetailsAnomalie > 0 && !this.pageContentService.isOpened()
					},{
						icon: 'warning',
						level: 'ERROR',
						title: this.translateService.instant('facture.alerte.anomalieFacture.title'),
						message: this.translateService.instant('facture.alerte.anomalieFacture.message'),
						isVisible: () => this.facture.anomalie && this.facture.societe && this.resume?.nbDetailsAnomalie == 0 && !this.pageContentService.isOpened(),
						doAction: () => this.saveFacture()
					},{
						icon: 'info_outline',
						title: this.translateService.instant('facture.alerte.exclusionCompta.title'),
						message: null,
						isVisible: () => this.facture?.exclusionCompta && !this.pageContentService.isOpened()
					}],
					listeElements: [{
						type: 'DETAIL',
						libelle: 'facture.elements.detail',
						component: FactureDetailListComponent,
						retrieveComponentData: () => ({
							facture: this.facture,
							resume: this.resume,
							addDetail: this.addDetail.bind(this)
						}),
						doAction: () => this.addDetail(),
						count: () => this.resume.nbDetails,
						libelleAction: this.translateService.instant('facture.elements.detail.ajouter'),
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.facture.interfaceFournisseur && !['COMPTABILISEE','VALIDEE'].includes(this.facture.statut),
						isVisible: () => this.rightService.hasRight(null,'consultation')
					}],
					isFormCustomization: true
				};
			}
		})
	}

	/**
	 * Chargement des données
	 */
	private loadData(idFacture: number): Observable<Result> {
		//Chargement de la facture
		return this.factureService.loadFacture(idFacture).pipe(tap(result => {
			//Vérification du chargement
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Définition de la facture
				this.facture = result.data?.facture;

				//Définition du résumé
				this.resume = {
					nbDetails: result.data?.nbDetails || 0,
					nbDetailsAnomalie: result.data?.nbDetailsAnomalie || 0
				}

				//Initialisation du compteur de pièces jointes
				this.attachmentService.initAttachments(this);

				//Vérification de l'identifiant de la facture
				if (!this.facture?.idFacture) {
					//Création d'un objet vierge
					this.facture = {
						montant: 0
					};

					//Edition de la facture
					this.editFacture();
				}
			}
		}));
	}

	/**
	 * Edition de la facture
	 */
	private editFacture() {
		//Ouverture du composant d'édition
		this.pageContentService.open(FactureEditComponent,{
			data: {
				facture: this.facture.idFacture ? cloneDeep(this.facture) : this.facture,
				deleteFacture: this.deleteFacture.bind(this)
			}
		}).subscribe({
			next: (facture: any) => {
				//Vérification des données
				if (facture) {
					//Mise à jour de la facture
					Object.assign(this.facture,facture);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.facture);
				}
			}
		});
	}

	/**
	 * Suppression de la facture
	 */
	protected deleteFacture() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.factureService.deleteFacture(this.facture))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
	/**
	 * Modification d'une ligne de facture
	 */
	protected addDetail(detail: any = { facture: Object.assign({},this.facture),date: this.facture.date,pays: this.facture.pays || this.loggedUserService.getUser().pays,devise: this.facture.devise || this.loggedUserService.getUser().devise,quantite: 1 },deleteDetail?: Function,isDuplication: boolean = false) {
		//Vérification du besoin de charger la ligne de détail
		defer(() => detail.idDetail ? this.factureService.loadDetail(detail.idDetail).pipe(
			map((result: Result) => {
				//Mise à jour de la ligne de détail
				Object.assign(detail,result.data.detail);

				//Vérification de l'indicateur de duplication de la ligne de détail
				if (isDuplication) {
					//Duplication de la ligne de détail
					detail = cloneDeep({
						...detail,
						facture: Object.assign({},this.facture)
					});

					//Retrait des informations de la ligne de détail pour permettre une création
					delete detail.idDetail;
					delete detail.objectVersion;
					delete detail.lien?.idDetail;
					delete detail.workflowHistoryMotif;
					delete detail.statut;
				}

				return detail;
			})
		) : of({ ...detail,facture: Object.assign({},this.facture) })).pipe(
			switchMap((detail) => this.pageContentService.open(FactureDetailEditComponent,{
				data: {
					detail: cloneDeep(detail),
					deleteDetail,
					isDuplication,
					addDetail: this.addDetail.bind(this)
				},
				allowSkipCloseConfirm: true
			},'sub'))
		).subscribe({
			next: (data: { detail: any,facture: any,nbDetailsAnomalie: number,isCorrectionAnomalie: boolean }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('DETAIL',detail.idDetail != data.detail?.idDetail || data.isCorrectionAnomalie);

					//Vérification de la présence d'une création ou d'une suppression
					if (!detail.idDetail && data.detail?.idDetail)
						//Incrémentation du compteur
						this.resume.nbDetails++;
					else if (detail.idDetail && !data.detail?.idDetail)
						//Décrémentation du compteur
						this.resume.nbDetails--;

					//Mise à jour de la ligne de facture et la facture
					Object.assign(detail,data.detail);
					Object.assign(this.facture,data.facture || data.detail.facture);

					//Mise à jour du nombre d'anomalies pour les lignes de facture
					this.resume.nbDetailsAnomalie = data.nbDetailsAnomalie;
				}
			}
		});
	}

	/**
	 * Réconciliaton de la société avec le code de compte de facturation
	 */
	public showReconciliationCompteSociete(facture: any = this.facture): void {
		//Réconciliaton de la société avec le code de compte de facturation
		this.factureService.showReconciliationCompteSociete(facture).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de la société
					this.facture.societe = result.data?.compteFacturation?.societe;
				} else if (result?.codeErreur === TypeCodeErreur.DOUBLON) {
					let listeDoublons = [];

					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.code'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		})
	}

	/**
	 * Association de la société à la facture
	 */
	public showSelectionSociete(facture = this.facture): void {
		//Sélection de la société de facturation
		this.factureService.showAssociationSociete(facture).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.facture,result.data?.facture);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0)) {
							//Ajout des champs
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));
							listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));
							listeDoublons.push(this.translateService.instant('actions.doublon.type'));
						}

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		})
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	doAction(typeAction: 'VALIDER' | 'REJETER',facture: any = this.facture) {
		//Réalisation de l'action Workflow
		this.factureService.doAction(typeAction,facture.idFacture).subscribe({
			next: isSuccess => {
				//Vérification du statut
				if (isSuccess) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Retour sur la liste
					this.layoutService.goBack()
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Enregistrement de la facture
	 */
	public saveFacture() {
		//Enregistrement de la facture
		this.factureService.saveFacture(this.facture).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de la facture
					Object.assign(this.facture,result.data?.facture);
			} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Accès au lot comptable
	 */
	goToLotComptable() {
		//Navigation vers le lot comptable
		this.layoutService.goToByState('listeLotsComptables-lotComptable',{
			routeParams: {
				idLotComptable: this.facture.lotComptable.idLotComptable
			},
			withGoBack: true
		});
	}
}