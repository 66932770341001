import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { take,filter } from 'rxjs/operators';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ReferentielEntiteList,TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { MotifListItemComponent } from 'src/app/components/workflow/motif/motif-list-item.component';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';

@Component({
	templateUrl: './motif-list.component.html'
})
export class MotifListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.workflow.WorkflowMotif';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/WorkflowReferentiel/filtreMotifs/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,MotifListItemComponent>;

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(protected referentielService: ReferentielService,private translateService: TranslateService,protected rightService: RightService,private store: Store<AppState>,private extractionService: ExtractionService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(
				filter(user => !!user),
				take(1)
			).subscribe({
				next: user => {
					//Mise à jour de l'utilisateur
					this.user = user;

					//Définition de la liste
					this.liste = new ListView<any,MotifListItemComponent>({
						uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
						title: this.translateService.instant('workflow.motif.liste.title'),
						component: MotifListItemComponent,
						isTile: true,
						listeFilters: [{
							clef: 'libelle',
							isDefault: true
						},{
							clef: 'reference',
							isDefault: true
						},{
							clef: 'isActif',
							type: TypeFilter.BOOLEAN
						},{
							clef: 'entity',
							type: TypeFilter.STRING,
							title: this.translateService.instant('search.entite'),
							listeValues: this.extractionService.getListeEntites('WORKFLOW_MOTIF')
						}].filter(i => !!i),
						listeSelectors: !this.rightService.isRoot() && [{
							code: TypeReferentiel.LOCAL,
							libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
							count: () => this.referentiel?.nbItemsForLocal || 0
						},{
							code: TypeReferentiel.STANDARD,
							libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
							count: () => this.referentiel?.nbItems || 0
						}],
						selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
						extraOptions: {
							referentiel: this.referentiel,
							user: this.user
						},
						hasMainAction: () => this.rightService.hasRight(null,'creation'),
						doMainAction: () => {
							//Ajout d'un nouvel élément
							!this.liste.data?.content?.some(p => !p.idMotif) && this.liste.addItem({
								isDisplayed: true,
								actif: true
							});
						},
						onRefresh: (liste,result) => {
							//Vérification du type de liste
							if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
								//Mise à jour du nombre d'éléments standards
								this.referentiel.nbItems = result?.totalElements || 0;
							else
								//Mise à jour du nombre d'éléments locaux
								this.referentiel.nbItemsForLocal = result?.totalElements || 0;
						}
					});
				}
			});
	}
}