<div class="pull-left lv-avatar">
	<icon name="star"></icon>
</div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="extraOptions?.addUserScheduler?.(data,deleteUserScheduler)">{{ data.libelle }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>extraction.diffusion.frequence</span>&#160;:&#160;<span [translate]="'scheduler.frequence.' + data.scheduler?.type"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idUserScheduler && (null | right:'creation')">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteUserScheduler();"><span translate>actions.supprimer</span></a></li>
	</ul>
</div>