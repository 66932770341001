import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { UpgradeListItemComponent } from './upgrade-list-item.component';

@Component({
	templateUrl: './upgrade-list.component.html'
})
export class UpgradeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,UpgradeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UpgradeListItemComponent>({
			uri: () => `/controller/Upgrader/filtreVersions`,
			title: this.translateService.instant('upgrade.liste.title'),
			component: UpgradeListItemComponent,
			isTile: true,
			defaultOrder: '-dateDemarrage',
			getKeyFieldName: () => 'idUpdate',
			hasMainAction: () => false,
			listeFilters: [{
				clef: 'numero',
				isDefault: true
			}]
		});
	}
}