import { Component,Input,ViewEncapsulation,ViewChildren,QueryList,ElementRef,AfterViewInit,ChangeDetectorRef,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlContainer,NgForm } from '@angular/forms';

import { WorkflowService } from '../workflow.service';
import { GLOBALS } from 'src/app/utils/globals';

@Component({
	selector: 'workflow-detail-step',
	templateUrl: './workflow-detail-step.component.html',
	styles: ['workflow-detail-step{ display: contents; }'],
	encapsulation: ViewEncapsulation.None,
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class WorkflowDetailStepComponent implements AfterViewInit,OnDestroy {
	/** Workflow **/
	@Input() readonly workflow: any;

	/** Etape **/
	@Input() readonly etape: any;

	/** Branche courante **/
	@Input() readonly currentBranche: any;

	/** Enfant **/
	@Input() readonly isChild: boolean = true;

	/** Mode édition **/
	@Input() readonly isEdition: boolean = false;

	/** Index courant **/
	@Input() readonly index: number;

	/** Indicateur du survol de l'étape **/
	isContentHovered: boolean;

	/** Indicateur de l'ouverture du dropdown **/
	isDropdownOpened: boolean;

	/** Souscription aux variables du template **/
	private dropdownMenuSubscription: Subscription;

	/** Dropdown (liste des actions) **/
	@ViewChildren('dropdownMenu') dropdownMenu: QueryList<ElementRef<HTMLLIElement>>;

	/**
	 * Constructeur
	 */
	constructor(private workflowService: WorkflowService,private changeDetectorRef: ChangeDetectorRef) { }

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Interception de l'affichage/masquage du template
		this.dropdownMenuSubscription = this.dropdownMenu.changes.subscribe(children => {
			//Parcours des éléments
			children?.forEach((dropdown: ElementRef<HTMLLIElement>) => {
				//Écoute de la fermeture du dropdown
				GLOBALS.$(dropdown.nativeElement).on('hide.bs.dropdown',() => {
					//Notification de la fermeture
					this.isDropdownOpened = false;

					//Détection des changements
					this.changeDetectorRef.detectChanges();
				});
			});
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.dropdownMenuSubscription.unsubscribe();
	}

	/**
	 * Réduction d'une étape
	 */
	collapseStep(isCollapsed: boolean = this.etape.isCollapsed) {
		//Itération sur les étapes
		(this.currentBranche.listeEtapes as Array<any>).slice(this.index + 1).forEach(etape => {
			//Affichage ou masquage de l'étape
			etape.isHidden = isCollapsed;
		});
	}

	/**
	 * Affichage de la pop-up de définition des valideurs
	 */
	showEtapeDefinition(etape: any = this.etape) {
		//Affichage de la pop-up de définition des valideurs
		this.workflowService.showValideurDefinition(this.workflow.entite,etape,this.isEdition).subscribe({
			next: (e) => {
				//Définition des liste des valideurs
				etape.listeValideurs = e.listeValideurs;
				etape.listeValideursNommes = e.listeValideursNommes;
			}
		});
	}

	/**
	 * Suppression de l'étape
	 */
	deleteEtape = (index: number = this.index) => this.currentBranche.listeEtapes.splice(index,1);
}
