<div class="modal-header">
	<h4 class="modal-title" translate>workflow.history.title</h4>
</div>
<div class="modal-body">
	<div *ngIf="listeResponsables">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>workflow.history.attenteResponsable</h4>
			</div>
		</div>
		<div class="form-group">
			<label [translate]="'workflow.history.responsables' | pluralTranslate:listeResponsables?.length"></label>
			<div>
				<span>
					<display-more class="listview lv-bordered" [listeItems]="listeResponsables" #displayMoreResponsable>
						<span class="badge badge-light" *ngFor="let responsable of displayMoreResponsable?.getSlice?.()">{{ responsable | display:'user' }}</span>
					</display-more>
					<span *ngIf="!listeResponsables?.length" translate>workflow.history.aucunResponsable</span>
				</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12">
			<h4 translate>workflow.history.item</h4>
		</div>
	</div>
	<div class="listview lv-bordered">
		<display-more class="lv-body" [listeItems]="listeWorkflowHistory" #displayMoreHistory>
			<div *ngFor="let data of displayMoreHistory?.getSlice?.(); let index = index">
				<div class="lv-item media">
					<div class="pull-left lv-avatar">{{ index + 1 }}</div>
					<div class="media-body">
						<div class="lv-title">
							<span *ngIf="!data.actionAutomatique && !data.originalUser" translate [translateParams]="{ typeAction: 'workflow.history.action.' + data.typeAction | translate,user: data.user | display:'user' }">workflow.history.message</span>
							<span *ngIf="!data.actionAutomatique && data.originalUser" translate [translateParams]="{ typeAction: 'workflow.history.action.' + data.typeAction | translate,user: data.user | display:'user',originalUser: data.originalUser | display:'user' }">workflow.history.messageOriginalUser</span>
							<span *ngIf="data.actionAutomatique && !data.originalUser" translate [translateParams]="{ typeAction: 'workflow.history.action.' + data.typeAction | translate,user: data.user | display:'user',source: 'workflow.history.source.' + data.sourceActionAutomatique | translate }">workflow.history.messageActionAutomatique</span>
							<span *ngIf="data.actionAutomatique && data.originalUser" translate [translateParams]="{ typeAction: 'workflow.history.action.' + data.typeAction | translate,user: data.user | display:'user',originalUser: data.originalUser | display:'user',source: 'workflow.history.source.' + data.sourceActionAutomatique | translate }">"workflow.history.messageActionAutomatiqueOriginalUser</span>
						</div>
						<ul class="lv-attrs">
							<li *ngIf="data.date"><span translate>workflow.history.date</span>&#160;:&#160;<span>{{ data.date | date:'short' }}</span></li>
							<li *ngIf="data.workflowHistoryMotif"><span [tooltip]="data.workflowHistoryMotif.motif" translate>workflow.history.motif</span>&#160;:&#160;<span>{{ data.workflowHistoryMotif.motif.libelle }}</span></li>
						</ul>
					</div>
				</div>
			</div>
			<h5 class="text-center p-10" *ngIf="!listeWorkflowHistory?.length" translate="liste.empty"></h5>
		</display-more>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>