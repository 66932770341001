<form #tenantCompteServiceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>tenant.compteService.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation') || disabled">
			<basket [model]="this" propertyName="listeComptesService" colWidthLabel="12" colWidthAutocomplete="6" type="compteService" [labelAutocomplete]="'tenant.compteService.ajout' | translate" [displayItem]="getLibelleCompteService"></basket>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()"><span translate>actions.annuler</span></button>
		<button mat-raised-button color="primary" (click)="saveCompteService()" [disabled]="tenantCompteServiceForm.invalid" *ngIf="(null | right:'creation') && !disabled">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>