import { Component,Input,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { PleaseWaitModalComponent } from 'src/app/share/components/please-wait/please-wait-modal.component';
import { PleaseWaitService } from 'src/app/share/components/please-wait/please-wait.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeVehiculeService } from './commande/demande-vehicule/demande-vehicule.service';
import { ImmatriculationService } from './immatriculation/immatriculation.service';
import { VehiculeService } from './vehicule.service';

@Component({
	templateUrl: './vehicule-immatriculation-selection.component.html'
})
export class VehiculeImmatriculationSelectionComponent implements OnInit {
	/** Options de la sélection **/
	@Input() options?: { isDateEntreeVisible?: boolean,isWithEngin?: boolean,pays?: any,typeVehicule?: TypeVehicule,useExistingImmatriculation?: boolean,reference?: any,vehicule?: any,isWithDemandeVehicule?: boolean,isSynchro?: boolean,isImputationVisible?: boolean };

	/** Informations de création du véhicule **/
	creationVehicule: { type: 'VEHICULE' | 'ENGIN' | 'DEMANDE_VEHICULE' } = {
		type: 'VEHICULE'
	};

	/** Type d'erreur **/
	typeErreur: 'DOUBLON' | 'WS_INCORRECT_PLATE' | 'WS_PLATE_NOT_FOUND' | 'WS_CALL_ERROR' = null;

	/** Véhicule existant **/
	existingVehicule: { idVehicule: number } = null;

	/** Immatriculation existante **/
	existingImmatriculation: any = null;

	/** Véhicule **/
	vehicule: any = {};

	/** Immatriculation saisie **/
	immatriculation: string;

	/** Immatriculation recherchée **/
	searchedImmatriculation: string;

	/** Utilisateur connecté **/
	user: User;

	/** Indicateur de chargement **/
	isLoading: boolean = false;

	/** Liste des configurations **/
	listeConfigurations: Array<any>;

	/** Saisie réalisée **/
	savedData: any = null;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<VehiculeImmatriculationSelectionComponent>,private vehiculeService: VehiculeService,private toastrService: ToastrService,private translateService: TranslateService,private store: Store<AppState>,private layoutService: LayoutService,private pleaseWaitService: PleaseWaitService,private immatriculationService: ImmatriculationService,private demandeVehiculeService: DemandeVehiculeService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la présence d'un véhicule
		if (this.options?.vehicule) {
			//Définition des données liées au véhicule
			this.immatriculation = this.options?.vehicule?.reference;
			this.vehicule = this.options.vehicule;
			this.existingVehicule = this.options.vehicule;
			this.existingImmatriculation = this.options.vehicule?.immatriculation;
		}

		//Vérification de la présence de la date d'entrée
		if (this.options?.isDateEntreeVisible)
			//Initalisation de la date d'entrée
			this.vehicule.dateEntree = moment.now();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).subscribe({
			next: user => {
				//Mémorisation de l'utilisateur
				this.user = user;

				//Définition du pays du véhicule si nécessaire
				this.vehicule.pays = this.vehicule.pays || this.options.pays || this.user.pays?.listeScopes?.some(s => s.scope == 'IMMATRICULATION_VEHICULE') && this.user.pays || null;
			}
		});

		//Récupération de la liste des configurations des véhicules
		this.vehiculeService.getListeConfigurations().subscribe({
			next: listeConfigurations => this.listeConfigurations = listeConfigurations
		});
	}

	/**
	 * Poursuite du traitement
	 */
	doContinue() {
		//Retrait du type d'erreur
		this.typeErreur = null;

		//Retrait du véhicule existant
		this.existingVehicule = null;

		//Retrait de l'immatriculation existante
		this.existingImmatriculation = null;

		//Vérification du type de sélection
		if (this.creationVehicule.type == 'VEHICULE')
			//Recherche de l'immatriculation
			this.findImmatriculation();
		else if (this.creationVehicule.type == 'ENGIN')
			//Enregistrement de l'engin
			this.saveEngin();
		else if (this.creationVehicule.type == 'DEMANDE_VEHICULE')
			//Enregistrement de la demande de véhicule
			this.saveDemandeVehicule();
	}

	/**
	 * Gestion du clic sur l'alerte
	 */
	onAlerteClick() {
		//Vérification du type d'erreur
		if (this.typeErreur == 'DOUBLON') {
			//Fermeture de la popup
			this.bsModalRef.hide();

			//Redirection vers le véhicule
			this.layoutService.goToByState('listeVehicules-loadVehicule',{
				routeParams: {
					idVehicule: this.existingVehicule.idVehicule || 0
				},
				routeData: {
					immatriculation: this.existingImmatriculation
				}
			});
		} else if (this.typeErreur == 'WS_PLATE_NOT_FOUND' || this.typeErreur == 'WS_CALL_ERROR') {
			//Saisie du certificat d'immatriculation
			this.showCertificatImmatriculation();
		}
	}

	/**
	 * Enregistrement de l'engin
	 */
	saveEngin() {
		//Chargement en cours
		this.isLoading = true;

		//Recherche du véhicule existant
		this.vehiculeService.findVehicule({
			typeVehicule: TypeVehicule.ENGIN,
			reference: this.vehicule.reference,
			idMarque: this.vehicule.marque?.idMarque,
			idPays: this.vehicule.pays?.idPays
		}).subscribe({
			next: vehicule => {
				//Vérification de la présence d'un véhicule existant
				if (vehicule) {
					//Définition du code d'erreur d'immatriculation
					this.typeErreur = 'DOUBLON';

					//Définition de l'existence du véhicule
					this.existingVehicule = {
						idVehicule: vehicule.idVehicule
					};
				} else {
					//Vérification du mode de création à la volée
					if (!this.options.isImputationVisible) {
						//Fermeture de la popup
						this.bsModalRef.hide();

						//Redirection vers le véhicule (en création)
						this.layoutService.goToByState('listeVehicules-loadVehicule',{
							routeParams: {
								idVehicule: 0
							},
							routeData: {
								vehicule: Object.assign(this.vehicule,{
									actif: true,
									typeVehicule: TypeVehicule.ENGIN
								})
							}
						});
					} else {
						//Définition de l'immatriculation sélectionnée
						this.savedData = {
							...this.vehicule,
							typeVehicule: TypeVehicule.ENGIN
						};

						//Fermeture de la popup
						this.bsModalRef.hide();
					}
				}
			},
			complete: () => {
				//Fin du chargement
				this.isLoading = false;
			}
		})
	}

	/**
	 * Enregistrement de la demande de véhicule
	 */
	saveDemandeVehicule(vehicule = this.vehicule) {
		//Création de la demande de véhicule
		this.demandeVehiculeService.doCreationForDemandeVehicule({
			canCreateVehicule: true,
			societeImputation: vehicule?.societe,
			serviceImputation: vehicule?.service,
			source: 'DEMANDE_VEHICULE',
			vehicule: vehicule,
			immatriculation: vehicule.reference,
			pays: vehicule.pays
		}).subscribe({
			next: (result: Result) => {
				//Vérification du résultat
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Vérification de la présence d'une demande de véhicule
					if (result.data.demandeVehicule) {
						//Définition de l'immatriculation sélectionnée
						this.savedData = {
							demandeVehicule: result.data.demandeVehicule
						};

						//Fermeture de la popup
						this.bsModalRef.hide();
					}
				}
			}
		});
	}

	/**
	 * Récupération du motif d'immatriculation en fonction du pays sélectionné
	 */
	getImmatriculationPatternForPays() {
		//Récupération du pattern du pays
		return this.listeConfigurations?.find(c => c.codePays == this.vehicule?.pays?.code2)?.immatriculationRegexp?.replace(/\^/g,'') || '[^A-Z0-9]*';
	}

	/**
	 * Recherche de l'immatriculation
	 */
	findImmatriculation() {
		let bsModalRef: BsModalRef<PleaseWaitModalComponent>;
		let isModalHidden: boolean = false;

		//Chargement en cours
		this.isLoading = true;

		//Définition de l'immatriculation recherchée
		this.searchedImmatriculation = this.immatriculation;

		//Affichage de la popup d'attente
		bsModalRef = this.pleaseWaitService.show();

		//Recherche de l'immatriculation
		this.vehiculeService.findImmatriculation({
			immatriculation: this.immatriculation,
			typeVehicule: this.options.typeVehicule || this.options.vehicule?.typeVehicule,
			codePays: this.vehicule.pays.code2,
			isSynchro: this.options?.isSynchro
		}).subscribe({
			next: result => {
				//Fermeture de la popup d'attente
				bsModalRef.hide();

				//Indication de la fermeture
				isModalHidden = true;

				//Vérification du résultat
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Vérification de la présence d'un certificat d'immatriculation
					if (result.data.immatriculation)
						//Redirection vers la saisie du certificat d'immatriculation
						this.showCertificatImmatriculation(result.data.immatriculation);
					else if (result.data.erreur)
						//Récupération de l'erreur
						this.typeErreur = result.data.erreur;
					else
						//Redirection vers la saisie du certificat d'immatriculation
						this.showCertificatImmatriculation();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Définition du code d'erreur d'immatriculation
					this.typeErreur = 'DOUBLON';

					//Définition de l'existence du véhicule
					this.existingVehicule = {
						idVehicule: result.data.idVehicule
					};

					//Définition du certificat d'immatriculation existant
					this.existingImmatriculation = result.data.immatriculation;

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: this.translateService.instant('actions.doublon.immatriculation')
					}));
				}
			},
			complete: () => {
				//Fin du chargement
				this.isLoading = false;

				//Fermeture de la popup d'attente si nécessaire
				!isModalHidden && bsModalRef.hide();
			}
		})
	}

	/**
	 * Affichage du certificat d'immatriculation
	 */
	showCertificatImmatriculation(immatriculation?: any) {
		//Définition de l'immatriculation sélectionnée
		this.savedData = {
			immatriculation: immatriculation || {
				reference: this.searchedImmatriculation,
				pays: this.vehicule.pays,
				typeSource: 'SAISIE'
			},
			dateEntree: this.vehicule.dateEntree,
			pays: this.vehicule.pays,
			societe: this.vehicule.societe,
			service: this.vehicule.service,
			type: 'VEHICULE'
		};

		//Fermeture de la popup
		this.bsModalRef.hide();
	}

	/**
	 * Affichage du certificat d'immatriculation en lecture seule
	 */
	showImmatriculation(immatriculation = this.vehicule.immatriculation) {
		//Affichage du certificat d'immatriculation en lecture seule
		this.immatriculationService.showImmatriculation({ immatriculation,options: { isConsultationOnly: true } }).subscribe();
	}

	/**
	 * Affichage de la pop-up de sélection d'une immatriculation pour une demande de véhicule
	 */
	showSelectImmatriculation() {
		//Affichage de la pop-up de sélection d'une immatriculation pour une demande de véhicule
		this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Définition du véhicule
				this.vehicule = {
					...this.vehicule,
					reference: data.immatriculation.reference,
					immatriculation: data.immatriculation,
					modeleConstructeur: data.params.modele
				};
			}
		},{
			...this.options,
			isWithEngin: false,
			isWithDemandeVehicule: false,
			isDateEntreeVisible: false,
			useExistingImmatriculation: true,
			isSynchro: false,
			isImputationVisible: false
		}).subscribe();
	}

	/**
	 * Réinitialisation des champs du véhicule
	 */
	resetVehicule() {
		//Réinitialisation des champs du véhicule
		delete this.vehicule.immatriculation;
		delete this.vehicule.modeleConstructeur;
		delete this.vehicule.reference;
		delete this.vehicule.marque;
		delete this.vehicule.modele;
		delete this.vehicule.genre;

		//Réinitialisation des erreurs
		this.typeErreur = null;
	}
}