import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class NatureService {
	/** Liste des types de lien **/
	private listeTypesLien: Array<any> = [{
		code: 'VEHICULE',
		type: 'com.notilus.data.facture.link.LinkDetailVehicule'
	},{
		code: 'COLLABORATEUR',
		type: 'com.notilus.data.facture.link.LinkDetailUser'
	},{
		code: 'EQUIPEMENT',
		type: 'com.notilus.data.facture.link.LinkDetailEquipement'
	},{
		code: 'SOCIETE',
		type: 'com.notilus.data.facture.link.LinkDetailService'
	}];

	/** Liste des familles de nature **/
	private listeTypesFamille: Array<any> = [{
		code: 'ENERGIE'
	},{
		code: 'ASSURANCE'
	},{
		code: 'ENTRETIEN'
	},{
		code: 'FRAIS_FINANCIER'
	},{
		code: 'PNEUMATIQUE'
	},{
		code: 'PARKING'
	},{
		code: 'PEAGE'
	},{
		code: 'TAXE'
	},{
		code: 'SINISTRE'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des types de liens traduits
	 */
	getListeTypesLien(): Array<any> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesLien.map(lien => ({
			...lien,
			libelle: this.translateService.instant('nature.lien.'+lien.code)
		}));
	}

	/**
	 * Récupération de la liste des types de familles traduits
	 */
	getListeTypesFamille(): Array<any> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesFamille.map(famille => ({
			...famille,
			libelle: this.translateService.instant('nature.famille.'+famille.code)
		})).sort((a,b) => a.libelle.localeCompare(b.libelle));
	}

	/**
	 * Chargement d'une nature
	 */
	loadNature(idNature: number): Observable<Result> {
		//Chargement de la nature
		return this.http.post<Result>(`${environment.baseUrl}/controller/Fournisseur/loadNature/${idNature}`,null);
	}

	/**
	 * Enregistrement d'une nature
	 */
	saveNature(nature: any): Observable<Result> {
		//Sauvegarde d'une nature
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveNature`,nature);
	}

	/**
	 * Suppression d'une nature
	 */
	deleteNature(idNature: number): Observable<Result> {
		//Suppression d'une nature
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteNature/${idNature}`);
	}
}