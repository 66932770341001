import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PosteChargeCompteListItemComponent } from 'src/app/components/comptabilite/poste-charge/poste-charge-compte-list-item.component';

@Component({
	selector: 'service-compte-list',
	templateUrl: './service-compte-list.component.html'
})
export class ServiceCompteListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Fonction d'édition **/
	@Input() addCompte: () => void;

	/** Résumé **/
	@Input() resume: { nbComptes: number };

	/** Données **/
	liste: ListView<any,PosteChargeCompteListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PosteChargeCompteListItemComponent>({
			uri: () => `/controller/Compte/filtreComptesByDetail/${this.service.type}/${this.service.idService}`,
			component: PosteChargeCompteListItemComponent,
			isLocal: true,
			extraOptions: {
				addCompte: this.addCompte
			},
			listeFilters: [{
				clef: 'compte',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'compte'
				},
				isDefault: true
			},{
				clef: 'compte.pays.libelle',
				isDefault: true
			},{
				clef: 'compte.pays.code2',
				isDefault: true
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbComptes--;
	}
}