import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { SinistreContentComponent } from './sinistre-content.component';
import { SinistreListItemComponent } from './sinistre-list-item.component';
import { SinistreListComponent } from './sinistre-list.component';
import { SinistreComponent } from './sinistre.component';
import { SinistreService } from './sinistre.service';

@NgModule({
	imports: [ShareModule],
	declarations: [SinistreListComponent,SinistreListItemComponent,SinistreComponent,SinistreContentComponent],
	exports: [SinistreContentComponent],
	providers: [SinistreService]
})
export class SinistreModule { }