import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';


@Component({
	templateUrl: './lot-comptable-check-plan-comptable-list-item.component.html'
})
export class LotComptableCheckPlanComptableListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private layoutService: LayoutService,private bsModalRef: BsModalRef) {
		//Héritage
		super();
	}

	/**
	 * Accès à la société
	 */
	showSociete(societe: any) {
		//Fermeture de la pop-up
		this.bsModalRef.hide();

		//Vérification des droits
		if (this.rightService.hasRight(TypeDroit.ADMIN_ANALYTIQUE_SERVICE,'consultation')) {
			//Navigation vers la société
			this.layoutService.goToByState('listeServices-service',{
				routeParams: {
					idService: societe.idSociete
				},
				withGoBack: true
			});
		}
	}
}