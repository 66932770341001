<div class="modal-header">
	<h4 class="modal-title" translate>vehicule.immatriculation.title</h4>
</div>
<div class="modal-body">
	<form #immatriculationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="immatriculation.typeSource == 'IMPORT' || options?.isConsultationOnly">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.reference.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.reference.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.reference.libelle' | translate) }}</label>
						<div>
							<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="immatriculation.reference" pattern="[A-Z0-9]*" required disabled/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.marque.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.marque.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.marque.libelle' | translate) }}</label>
						<div>
							<autocomplete name="marque" type="marque" #marque="ngModel" [(ngModel)]="immatriculation.marque" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION' }" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': cnit.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.cnit.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.cnit.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.cnit.libelle' | translate) }}</label>
						<div>
							<input type="text" name="cnit" class="form-control" #cnit="ngModel" [(ngModel)]="immatriculation.codeNationalIdentificationType" pattern="[A-Z0-9]*"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': vin.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.vin.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.vin.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.vin.libelle' | translate) }}</label>
						<div>
							<input type="text" name="vin" class="form-control" #vin="ngModel" [(ngModel)]="immatriculation.numeroIdentificationVehicule" pattern="[A-Z0-9]*"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': poidsAVide.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.poidsAVide.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.poidsAVide.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.poidsAVide.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="poidsAVide" class="form-control" #poidsAVide="ngModel" [(ngModel)]="immatriculation.poidsAVide" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': categorieVehicule.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.categorieVehicule.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.categorieVehicule.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.categorieVehicule.libelle' | translate) }}</label>
						<div>
							<input type="text" name="categorieVehicule" class="form-control" #categorieVehicule="ngModel" [(ngModel)]="immatriculation.categorieVehicule"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeCarrosserie.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.typeCarrosserie.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.typeCarrosserie.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.typeCarrosserie.libelle' | translate) }}</label>
						<div>
							<autocomplete name="typeCarrosserie" type="typeCarrosserie" #typeCarrosserie="ngModel" [(ngModel)]="immatriculation.typeCarrosserie" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION' }"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': puissanceNetteMax.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.puissanceNetteMax.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.puissanceNetteMax.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.puissanceNetteMax.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="puissanceNetteMax" class="form-control" #puissanceNetteMax="ngModel" [(ngModel)]="immatriculation.puissanceNetteMax" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': puissanceFiscale.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.puissanceFiscale.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.puissanceFiscale.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.puissanceFiscale.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="puissanceFiscale" class="form-control" #puissanceFiscale="ngModel" [(ngModel)]="immatriculation.puissanceFiscale" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': tauxCO2.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.tauxCO2.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.tauxCO2.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.tauxCO2.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="tauxCO2" class="form-control" #tauxCO2="ngModel" [(ngModel)]="immatriculation.tauxCO2" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': nbPortes.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.nbPortes.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.nbPortes.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.nbPortes.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="nbPortes" class="form-control" #nbPortes="ngModel" [(ngModel)]="immatriculation.nbPortes" nNumber min="0" max="999"/>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.dateDebut.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.dateDebut.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.dateDebut.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="dateDebut" class="form-control" #dateDebut="ngModel" [(ngModel)]="immatriculation.dateDebut" datePicker [storeDate]="true" required/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeVariante.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.typeVariante.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.typeVariante.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.typeVariante.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="typeVariante" class="form-control" #typeVariante="ngModel" [(ngModel)]="immatriculation.typeVariante"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': modele.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.modele.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.modele.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.modele.libelle' | translate) }}</label>
						<div>
							<autocomplete name="modele" type="modele" #modele="ngModel" [(ngModel)]="immatriculation.modele" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',idMarque: immatriculation.marque?.idMarque }" [disabled]="!immatriculation.marque" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': masseChargeMax.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.masseChargeMax.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.masseChargeMax.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.masseChargeMax.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="masseChargeMax" class="form-control" #masseChargeMax="ngModel" [(ngModel)]="immatriculation.masseChargeMax" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': dateCarteGrise.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.dateCarteGrise.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.dateCarteGrise.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.dateCarteGrise.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="dateCarteGrise" class="form-control" #dateCarteGrise="ngModel" [(ngModel)]="immatriculation.dateCarteGrise" datePicker [storeDate]="true" required/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': genre.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.genre.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.genre.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.genre.libelle' | translate) }}</label>
						<div>
							<autocomplete name="genre" type="genre" #genre="ngModel" [(ngModel)]="immatriculation.genre" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',type: 'VEHICULE',idPays: immatriculation.pays.idPays }" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': cylindree.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.cylindree.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.cylindree.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.cylindree.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="cylindree" class="form-control" #cylindree="ngModel" [(ngModel)]="immatriculation.cylindree" nNumber min="0" max="999999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': carburant.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.carburant.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.carburant.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.carburant.libelle' | translate) }}</label>
						<div>
							<autocomplete name="carburant" type="carburant" #carburant="ngModel" [(ngModel)]="immatriculation.carburant" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',idPays: immatriculation.pays.idPays }"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': nbPlacesAssises.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.nbPlacesAssises.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.nbPlacesAssises.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.nbPlacesAssises.libelle' | translate) }}</label>
						<div class="half">
							<input type="text" name="nbPlacesAssises" class="form-control" #nbPlacesAssises="ngModel" [(ngModel)]="immatriculation.nbPlacesAssises" nNumber min="0" max="999"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': depollution.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.depollution.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.depollution.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.depollution.libelle' | translate) }}</label>
						<div>
							<input type="text" name="depollution" class="form-control" #depollution="ngModel" [(ngModel)]="immatriculation.depollution"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': couleur.invalid }">
						<label [tooltip]="'vehicule.immatriculation.FR.couleur.libelle' | translate">{{ '(' + ('vehicule.immatriculation.FR.couleur.rubrique' | translate) + ') ' + ('vehicule.immatriculation.FR.couleur.libelle' | translate) }}</label>
						<div>
							<input type="text" name="couleur" class="form-control" #couleur="ngModel" [(ngModel)]="immatriculation.couleur"/>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span [translate]="'actions.' + (isModeCreationOrSynchro() && !options?.isConsultationOnly ? 'annuler' : 'fermer')"></span>
	</button>
	<ng-container *ngIf="null | right:'creation'">
		<button mat-raised-button color="primary" *ngIf="!options?.isConsultationOnly && !isModeCreationOrSynchro()" (click)="doSynchronisation()">
			<span translate>actions.synchroniser</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="isModeCreationOrSynchro() || vehicule && immatriculation?.typeSource == 'SAISIE'" [disabled]="immatriculationForm.invalid" (click)="saveImmatriculation()">
			<span [translate]="'actions.' + (isModeCreationOrSynchro() ? 'continuer' : 'enregistrer')"></span>
		</button>
		<button mat-raised-button color="primary" *ngIf="!options?.isConsultationOnly && immatriculation?.typeSource == 'IMPORT' && (rightService.isRoot() || rightService.isRoot(true))" (click)="immatriculation.typeSource = 'SAISIE'">
			<span translate>actions.modifier</span>
		</button>
	</ng-container>
</div>