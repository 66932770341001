<form #typeEntretienAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>typeEntretien.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>typeEntretien.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="typeEntretien.libelle" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>typeEntretien.reference</label>
			<div>
				<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="typeEntretien.reference" maxlength="300" required/>
			</div>
		</div>
		<div *ngIf="filter?.source != 'PLAN_ENTRETIEN'" class="form-group" [ngClass]="{ 'has-error': frequence.invalid }">
			<label>
				<span translate>typeEntretien.frequence.item</span>
				<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.typeEntretien.frequence.description' | translate"></icon>
			</label>
			<div class="half">
				<div class="input-group">
					<input type="text" name="frequence" #frequence="ngModel" class="form-control" [(ngModel)]="typeEntretien.frequence" nNumber min="0"/>
					<div class="input-group-addon no-border" translate>typeEntretien.mois</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide();">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="typeEntretienAddForm.invalid" (click)="saveItem();">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>