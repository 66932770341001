'use strict';

var inherits = require('inherits'),
  AjaxBasedTransport = require('./lib/ajax-based'),
  EventSourceReceiver = require('./receiver/eventsource'),
  XHRCorsObject = require('./sender/xhr-cors'),
  EventSourceDriver = require('eventsource');
function EventSourceTransport(transUrl) {
  if (!EventSourceTransport.enabled()) {
    throw new Error('Transport created when disabled');
  }
  AjaxBasedTransport.call(this, transUrl, '/eventsource', EventSourceReceiver, XHRCorsObject);
}
inherits(EventSourceTransport, AjaxBasedTransport);
EventSourceTransport.enabled = function () {
  return !!EventSourceDriver;
};
EventSourceTransport.transportName = 'eventsource';
EventSourceTransport.roundTrips = 2;
module.exports = EventSourceTransport;