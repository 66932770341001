import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompteAuxiliaireService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement du compte auxiliaire
	 */
	public loadCompteAuxiliaire(idCompteAuxiliaire: number): Observable<Result> {
		//Chargement du compte auxiliaire
		return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/loadCompteAuxiliaire/${idCompteAuxiliaire}`,null);
	}

	/**
	 * Suppression du compte auxiliaire
	 */
	public deleteCompteAuxiliaire(compteAuxiliaire: any): Observable<Result> {
		//Suppression du compte auxiliaire
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Compte/deleteCompteAuxiliaire/${compteAuxiliaire.idCompteAuxiliaire}`);
	}

	/**
	 * Enregistrement du compte auxiliaire
	 */
	public saveCompteAuxiliaire(compteAuxiliaire: any): Observable<Result> {
		//Enregistrement du compte auxiliaire
		return this.http.put<Result>(`${environment.baseUrl}/controller/Compte/saveCompteAuxiliaire`,compteAuxiliaire);
	}
}