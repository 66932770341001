<div class="pull-left lv-avatar"><span>S</span></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showVehiculeInformationsComplementaires()">{{ data | display:'vehicule' }}</a>
	</div>

	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="extraOptions.demandeVehicule.statut == 'LIVRE'"><span translate>vehicule.dateEntree.item</span>&#160;:&#160;<span *ngIf="data.dateEntree">{{ data.dateEntree | date:'short' }}</span><span *ngIf="!data.dateEntree" translate>common.aucune</span></li>
		<li *ngIf="extraOptions.demandeVehicule.statut == 'LIVRE' && data.dateSortie"><span translate>vehicule.dateSortie.item</span>&#160;:&#160;{{ data.dateSortie | date:'short' }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation') || (TypeDroit.ADMIN_VEHICULE | right:'suppression') && extraOptions.demandeVehicule.statut != 'LIVRE'">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_VEHICULE | right:'consultation'"><a [routerLink]="[]" (click)="showVehicule();" translate>demandeVehicule.vehicule.actions.accederVehicule</a></li>
			<li *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'suppression') && extraOptions.demandeVehicule.statut != 'LIVRE'"><a [routerLink]="[]" (click)="deleteVehicule();" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>