import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
	/** Compteur de requêtes en attente **/
	private nbPendingRequests: number = 0;

	/**
	 * Remise à zéro du nombre de requêtes
	 */
	reset() {
		//Remise à zéro du nombre de requêtes
		this.nbPendingRequests = 0;
	}

	/**
	 * Incrémentation du compteur
	 */
	increment() {
		//Incrémentation du compteur
		setTimeout(() => this.nbPendingRequests++);
	}

	/**
	 * Décrémentation du compteur
	 */
	decrement() {
		//Décrémentation du compteur
		this.nbPendingRequests--;
	}

	/**
	 * Retour du statut du chargement
	 */
	isLoading(): boolean {
		//Lecture du compteur
		return this.nbPendingRequests > 0;
	}
}
