import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { FormatInterfaceCodeEditComponent } from './format-interface-code-edit.component';
import { FormatInterfaceCodeListComponent } from './format-interface-code-list.component';
import { FormatInterfaceEditComponent } from './format-interface-edit.component';
import { FormatInterfaceListItemComponent } from './format-interface-list-item.component';
import { FormatInterfaceListComponent } from './format-interface-list.component';
import { FormatInterfaceComponent } from './format-interface.component';
import { FormatInterfaceService } from './format-interface.service';
import { FormatInterfaceCodeListItemComponent } from './format-interface-code-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [FormatInterfaceListComponent,FormatInterfaceListItemComponent,FormatInterfaceComponent,FormatInterfaceEditComponent,FormatInterfaceCodeListComponent,FormatInterfaceCodeEditComponent,FormatInterfaceCodeListItemComponent],
	providers: [FormatInterfaceService]
})
export class FormatInterfaceModule { }
