import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';
import { TypeDroit } from 'src/app/domain/security/right';
import { SocieteAddComponent } from 'src/app/components/societe/societe-add.component';

/**
 * Options des sociétés du référentiel
 */
export default class SocieteReferentielOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.societe';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Societe/listeSocietes';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.siren})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.societe.Societe';

	/**
	  * Récupération du nom de la clé
	  */
	getKeyFieldName() { return 'idSociete'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'siren',
			isDefault: true
		},{
			clef: 'siret',
			isDefault: true
		}];
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'societe.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return SocieteAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_SOCIETE;

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'societe.siret',
			getValue: (item: any) => item.siret
		}];
	}
}