import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ServiceUserListItemComponent } from './service-user-list-item.component';

@Component({
	selector: 'service-user-list',
	templateUrl: './service-user-list.component.html'
})
export class ServiceUserListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Fonction d'édition **/
	@Input() addUser: () => void;

	/** Résumé **/
	@Input() resume: { nbUsers: number };

	/** Données **/
	liste: ListView<any,ServiceUserListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServiceUserListItemComponent>({
			uri: () => `/controller/User/filtreUsers`,
			component: ServiceUserListItemComponent,
			isLocal: true,
			extraOptions: {
				addUser: this.addUser
			},
			listeFilters: [{
				clef: '*',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user',
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: `${this.service.type == 'SOCIETE' ? 'societe' : 'service'}.idService`,
				valeur: this.service.idService,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}
}