import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Result } from 'src/app/domain/common/http/result';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { DemandeIdentificationListItemComponent } from './demande-identification-list-item.component';
import { DemandeIdentificationService } from './demande-identification.service';
import { mapEntites } from './demande-identification.entites';

@Component({
	templateUrl: './demande-identification-list.component.html'
})
export class DemandeIdentificationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,DemandeIdentificationListItemComponent>;

	/** Résumé **/
	resume: { nbTous: number,nbATraiter: number,nbTraitees: number } = {
		nbTous: 0,
		nbATraiter: 0,
		nbTraitees: 0
	};

	/** Liste des statuts **/
	listeStatuts: Array<{ code: string,icone: string,iconeType?: string }>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private demandeIdentificationService: DemandeIdentificationService,public dashboardService: DashboardService,public extractionService: ExtractionService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des statuts
		this.listeStatuts = this.demandeIdentificationService.getListeStatuts();

		//Définition de la liste
		this.liste = new ListView<any,DemandeIdentificationListItemComponent>({
			uri: () => `/controller/DemandeIdentification/filtreDemandes/${!this.liste?.selectedSelector || this.liste?.selectedSelector == 'TOUS' ? '' : this.liste.selectedSelector}`,
			title: this.translateService.instant('demandeIdentification.liste.title'),
			component: DemandeIdentificationListItemComponent,
			listeFilters: [{
				clef: 'vehicule',
				title: this.translateService.instant('search.immatriculation'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					filter: 'VEHICULE_FONCTION_SERVICE'
				},
				isDefault: true
			},{
				clef: 'reference',
				title: this.translateService.instant('demandeIdentification.search.numeroDossier'),
				isDefault: true
			},{
				clef: 'dateInfraction',
				type: TypeFilter.DATE
			},{
				clef: 'statut',
				title: this.translateService.instant('demandeIdentification.search.statut'),
				type: TypeFilter.LONG,
				listeValues: this.listeStatuts.map(s => ({
					code: s.code,
					libelle: this.translateService.instant(`demandeIdentification.statut.${s.code}`)
				}))
			}],
			listeSelectors: [{
				code: 'TOUS',
				libelle: this.translateService.instant('demandeIdentification.typeListe.TOUS'),
				count: () => this.resume.nbTous
			},{
				code: 'A_TRAITER',
				libelle: this.translateService.instant('demandeIdentification.typeListe.A_TRAITER'),
				count: () => this.resume.nbATraiter
			},{
				code: 'TRAITE',
				libelle: this.translateService.instant('demandeIdentification.typeListe.TRAITE'),
				count: () => this.resume.nbTraitees
			}],
			selectedSelector: 'TOUS',
			extraOptions: {
				listeStatuts: this.listeStatuts
			},
			getKeyFieldName: () => 'idIdentification',
			onRefresh: (liste,result) => {
				//Chargement du résumé
				this.demandeIdentificationService.loadResume(liste.selectedSelector == 'A_TRAITER' ? 'TRAITE' : 'A_TRAITER',result.searchSpec).subscribe({
					next: (resultData: Result) => {
						//Vérification du type de liste
						if (liste.selectedSelector === 'A_TRAITER') {
							//Définition des compteurs
							this.resume.nbATraiter = result?.totalElements || 0;
							this.resume.nbTraitees = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbATraiter + this.resume.nbTraitees;
						} else if (liste.selectedSelector === 'TRAITE') {
							//Définition des compteurs
							this.resume.nbTraitees = result?.totalElements || 0;
							this.resume.nbATraiter = resultData?.data?.count || 0;
							this.resume.nbTous = this.resume.nbATraiter + this.resume.nbTraitees;
						} else if (liste.selectedSelector === 'TOUS') {
							//Définition des compteurs
							this.resume.nbTous = result?.totalElements || 0;
							this.resume.nbATraiter = resultData?.data?.count || 0;
							this.resume.nbTraitees = this.resume.nbTous - this.resume.nbATraiter;
						}
					}
				});
			}
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}