import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TenantContratListItemComponent } from './tenant-contrat-list-item.component';

@Component({
	selector: 'tenant-contrat-list',
	templateUrl: './tenant-contrat-list.component.html'
})
export class TenantContratListComponent extends PageContentItem implements OnInit {
	/** Tenant **/
	@Input() tenant: any;

	/** Fonction d'édition **/
	@Input() addContratCRM: () => void;

	/** Données **/
	liste: ListView<any,TenantContratListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantContratListItemComponent>({
			uri: `/controller/ContratCRM/filtreContratsCRM`,
			title: this.translateService.instant('tenant.liste.title'),
			component: TenantContratListItemComponent,
			isLocal: true,
			extraOptions: {
				addContratCRM: this.addContratCRM
			},
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: '-dateEffet',
			listeStaticFilters: [{
				clef: 'clientCRM.idClient',
				valeur: this.tenant?.clientCRM?.idClient || 0,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			}]
		});
	}
}