import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ScrollFixedDirective } from './scroll-fixed.directive';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [ScrollFixedDirective],
	exports: [ScrollFixedDirective]
})
export class ScrollFixedModule { }
