<div class="modal-header">
	<h4 class="modal-title">
		<span>{{ option.libelle }}</span>&#160;(<span *ngIf="option.dateFin" [translateParams]="{ debut: option.dateDebut | date:'shortDate',fin: option.dateFin | date:'shortDate' }" translate>vehicule.modele.option.periode</span><span *ngIf="!option.dateFin" [translateParams]="{ debut: option.dateDebut | date:'shortDate' }" translate>vehicule.modele.option.depuis</span>)
	</h4>
</div>
<div class="modal-body p-0 m-0">
	<stepper #stepper="stepper" [listeSteps]="listeSteps" [vertical]="true"></stepper>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>