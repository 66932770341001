<ng-template #pageTitleTemplate>
	<loader class="z-depth-1" *ngIf="loaderService.isLoading()"></loader>
	<div class="title-container">
		<div class="content" *ngIf="session.isLogged">
			<h2 class="title">
				<ng-container *ngIf="((title$ | async) || getTitleFromComponent()) as title">
					<button mat-icon-button *ngIf="session.user && session.user.type != 'VIRTUAL' && (routeData$ | async)?.isChild && filAriane$ | async as filAriane" (click)="goBack(filAriane)">
						<mat-icon>arrow_back</mat-icon>
					</button>
					<span>{{ getTitleFromComponent() || (title | translate) }}</span>
				</ng-container>
			</h2>
			<div class="actions-container">
				<ng-container *ngIf="session.user && session.user.type != 'VIRTUAL'">
					<button mat-icon-button class="document-generation" *ngIf="(routeData$ | async)?.isDocument && !isNewEntity()" (click)="generateDocument();" [tooltip]="'actions.document.generer' | translate">
						<mat-icon>picture_as_pdf</mat-icon>
					</button>
					<button mat-icon-button class="mail-history" *ngIf="(routeData$ | async)?.isMailHistory && !isNewEntity()" (click)="showMailHistory();" [tooltip]="'actions.mail.historique.consulterLong' | translate">
						<mat-icon>email</mat-icon>
					</button>
					<ng-container *ngIf="(attachmentState$ | async) as attachmentState">
						<button mat-icon-button class="attachment" *ngIf="attachmentState.typeAttachment" class="attachment" (click)="showAttachment();" [attr.data-counter]="attachmentState.nbAttachments || undefined" [tooltip]="'actions.attachment.consulter' | translate">
							<mat-icon>attach_file</mat-icon>
						</button>
					</ng-container>
					<button mat-icon-button class="history" *ngIf="(routeData$ | async)?.isHistory && !isNewEntity()" (click)="showHistory();" [tooltip]="'actions.historique.consulterLong' | translate">
						<mat-icon>history</mat-icon>
					</button>

					<mat-button-toggle-group *ngIf="(routeData$ | async)?.isEmbeddedDashboard && !isNewEntity()" [value]="dashboardService.getEmbeddedDashboardMode()">
						<mat-button-toggle value="LISTE" (click)="dashboardService.setEmbeddedDashboardMode('LISTE');"><span translate>dashboard.mode.liste</span></mat-button-toggle>
						<mat-button-toggle value="GRAPHIQUE" (click)="dashboardService.setEmbeddedDashboardMode('GRAPHIQUE');"><span translate>dashboard.mode.graphique</span></mat-button-toggle>
					</mat-button-toggle-group>
				</ng-container>
				<ng-container *ngIf="(listeSelectors$ | async) as listeSelectors">
					<div class="dropdown">
						<mat-button-toggle-group value="SELECT" class="title-selectors" data-toggle="dropdown">
							<mat-button-toggle value="SELECT">{{ listeSelectors[0].libelle }}</mat-button-toggle>
							<mat-button-toggle><icon name="expand_more"></icon></mat-button-toggle>
						</mat-button-toggle-group>
						<ul class="dropdown-menu dropdown-menu-right">
							<li *ngFor="let selector of listeSelectors">
								<a [routerLink]="[]" (click)="selector.doAction()">{{ selector.libelle }}</a>
							</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngIf="(listeActions$ | async) as listeActions">
					<div class="title-actions">
						<ul class="actions" *ngIf="listeActions.length == 1">
							<li>
								<button mat-mini-fab color="primary" (click)="listeActions[0].doAction()" [tooltip]="listeActions[0].libelle">
									<mat-icon>{{ listeActions[0].icon }}</mat-icon>
								</button>
							</li>
						</ul>
						<div class="actions dropdown" *ngIf="listeActions.length > 1">
							<button mat-mini-fab color="primary" data-toggle="dropdown">
								<mat-icon>add</mat-icon>
							</button>
							<ul class="dropdown-menu dropdown-menu-right">
								<li *ngFor="let action of listeActions"><a [routerLink]="[]" (click)="action.doAction()">{{ action.libelle }}</a></li>
							</ul>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-container #viewContainer></ng-container>