<div class="listview lv-bordered">
	<div class="lv-body" *ngIf="listePersonalData$ | async as listePersonalData">
		<div class="lv-item media" style="min-height: 60px;" *ngFor="let personalData of listePersonalData">
			<div class="media-body">
				<div class="lv-title"><a>{{ personalData.name + (personalData.typeUsage ? ' (' + personalData.typeUsage + ')' : '') }}</a></div>
				<div *ngIf="!personalData.listeDatas?.length" translate>account.personalData.noData</div>
				<div *ngFor="let data of personalData.listeDatas; let count = count; let index = index">
					<div *ngIf="count > 1">
						<div class="pull-left lv-avatar lv-avatar-small">{{ index + 1 }}</div>
					</div>
					<ul *ngIf="count <= 1" class="lv-attrs">
						<li *ngFor="let field of (data.listeFields | filterBy:hasValue); let countData = data">{{ (field.name ? field.name + ' : ' : '') + field.value }}</li>
					</ul>
					<div class="clearfix"></div>
				</div>
			</div>
		</div>
		<div class="text-center p-10" *ngIf="!listePersonalData">
			<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
		</div>
		<div class="text-center p-10" *ngIf="listePersonalData?.length == 0">
			<h5 translate>account.personalData.noData</h5>
		</div>
	</div>
</div>