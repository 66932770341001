import { Component,Input,OnInit } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	selector: 'pool-vehicule-add-item',
	templateUrl: './pool-vehicule-add-item.component.html'
})
export class PoolVehiculeAddItemComponent extends ListViewItem<any> implements OnInit {
	/** Données **/
	@Input() data: any;

	/** Options supplémentaires **/
	@Input() public extraOptions: any;

	/** Etat de sélection **/
	isSelected: boolean = false;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'élément (si nécessaire)
		this.isSelected = this.extraOptions.getListeIdsVehicule().indexOf(this.data.idVehicule) != -1;
	}

	/**
	 * Sélection d'un élément
	 */
	doSelectItem() {
		//Vérification de la sélection
		this.extraOptions.addOrRemoveIdVehiculeToListe(this.data.idVehicule);
	}
}