import { Component } from '@angular/core';
import { animate,state,style,transition,trigger } from '@angular/animations';
import { Toast } from 'ngx-toastr';

@Component({
	selector: '[action-toast]',
	templateUrl: './action-toast.component.html',
	animations: [trigger('flyInOut',[state('inactive',style({ opacity: 0 })),
		state('active',style({ opacity: 1 })),
		state('removed',style({ opacity: 0 })),
		transition('inactive => active',animate('{{ easeTime }}ms {{ easing }}')),
		transition('active => removed',animate('{{ easeTime }}ms {{ easing }}'))
	])],
	preserveWhitespaces: false
})
export class ActionToastComponent extends Toast {
	/**
	 * Déclenchement de l'action sur un bouton
	 */
	doAction(button: IToastButton) {
		//Déclenchement de l'action
		this.toastPackage.triggerAction(button);

		//Fermeture du toast
		this.toastrService.clear();

		return false;
	}
}

/**
 * Interface de définition d'un bouton dans le toast
 */
export interface IToastButton {
	/** Données **/
	key: string;
}