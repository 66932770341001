import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'demande-vehicule-choix-grille-attribution-modele',
	templateUrl: './demande-vehicule-choix-grille-attribution-modele.component.html'
})
export class DemandeVehiculeChoixGrilleAttributionModeleComponent implements AfterContentChecked {
	/** Choix **/
	@Input() choix: 'MODELE' | 'GRILLE_ATTRIBUTION_SPECIFIQUE' = 'MODELE';

	/** Résultat **/
	result: { choix: 'MODELE' | 'GRILLE_ATTRIBUTION_SPECIFIQUE' };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DemandeVehiculeChoixGrilleAttributionModeleComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Sélection du choix
	 */
	public saveDemandeVehiculeChoixGrilleAttributionModele() {
		//Définition du résultat
		this.result = {
			choix: this.choix
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}