import { Component,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ModeleOptionContrainteListItemComponent } from './modele-option-contrainte-list-item.component';

@Component({
	templateUrl: './modele-option-contrainte-list.component.html'
})
export class ModeleOptionContrainteListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ModeleOptionContrainteListItemComponent>;

	/** Option **/
	option: any;

	/** Type de contrainte **/
	typeContrainte: 'EXCLUSION' | 'INCLUSION';

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ModeleOptionContrainteListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreModeleOptionContraintes`,
			component: ModeleOptionContrainteListItemComponent,
			isLocal: true,
			extraOptions: {
				option: this.option
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'option.idOption',
				valeur: this.option.idOption,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			},{
				clef: 'typeContrainte',
				listeObjects: this.typeContrainte == 'INCLUSION' ? ['OBLIGATION','INCLUSION'] : ['EXCLUSION'],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.STRING
			}],
			defaultOrder: 'libelle'
		});
	}
}