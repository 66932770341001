<div class="container-fluid">
	<form #poolForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>pool.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="pool.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>pool.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="pool.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>pool.info.etatLieuxAutomatique.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'pool.info.etatLieuxAutomatique.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="etatLieuxAutomatique" [(ngModel)]="pool.etatLieuxAutomatique"><span translate>pool.info.etatLieuxAutomatique.description</span></mat-checkbox>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>pool.actif</label>
								<div>
									<mat-checkbox name="actif" [(ngModel)]="pool.actif"><span translate>pool.info.actif</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>pool.controle</label>
								<div>
									<mat-checkbox name="reservationAutomatique" [(ngModel)]="pool.reservationAutomatique"><span translate>pool.info.reservationAutomatique</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>pool.info.demandeReleveCompteurAutomatique.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'pool.info.demandeReleveCompteurAutomatique.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="demandeReleveCompteurAutomatique" [(ngModel)]="pool.demandeReleveCompteurAutomatique"><span translate>pool.info.demandeReleveCompteurAutomatique.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>pool.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" [(ngModel)]="pool.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>
				<div class="card-body card-padding m-t-20">
					<h4><span translate>pool.generalites.listeGroupes.titre</span></h4>
					<basket [model]="pool" propertyName="listeGroupes" colWidthLabel="2" colWidthAutocomplete="6" type="groupe" [labelAutocomplete]="'pool.groupe.ajout' | translate" [labelListe]="'pool.generalites.listeGroupes.collaborateur.label' | translate" [filter]="{ entity: 'com.notilus.data.user.User' }"></basket>
				</div>
			</fieldset>
		</div>
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>pool.responsable</h4>
						</div>
					</div>
					<div class="row p-t-20">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>pool.userN1</label>
								<div>
									<autocomplete name="userN1" type="user" #userN1="ngModel" [(ngModel)]="pool.userN1"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>pool.userN2</label>
								<div>
									<autocomplete name="userN2" type="user" #userN2="ngModel" [(ngModel)]="pool.userN2"></autocomplete>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>pool.userN3</label>
								<div>
									<autocomplete name="userN3" type="user" #userN3="ngModel" [(ngModel)]="pool.userN3"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>pool.userN4</label>
								<div>
									<autocomplete name="userN4" type="user" #userN4="ngModel" [(ngModel)]="pool.userN4"></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary"(click)="deletePool();" *ngIf="pool?.idPool && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="poolForm.invalid" (click)="savePool();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(pool?.idPool ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="pool.idPool" [formDetectChanges]="{ doAction: close,isDirty: poolForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>