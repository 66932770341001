import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { CoursService } from './cours.service';

@Component({
	templateUrl: './cours-list-item.component.html'
})
export class CoursListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private coursService: CoursService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idCours,object?.idCours && !this.data?.idCours);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteCours() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du cours
					this.coursService.deleteCours(this.data).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du cours
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject);
	}
}