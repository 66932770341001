<form #carburantForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!carburant.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot() || carburant.typeSource == 'EXTERNE_CATALOGUE'">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>vehicule.carburant.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="carburant.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>vehicule.carburant.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="carburant.reference" maxlength="100" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
							<label translate>vehicule.carburant.pays</label>
							<div>
								<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="carburant.pays" required></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>vehicule.carburant.typeEnergie.item</label>
							<div>
								<selectpicker name="typeEnergie" #typeEnergieField="ngModel" [(ngModel)]="carburant.typeEnergie">
									<mat-option *ngFor="let typeEnergie of listeTypesEnergie" [value]="typeEnergie.code">{{ typeEnergie.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>vehicule.carburant.actif.libelle</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="carburant.actif"><span translate>vehicule.carburant.actif.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: carburantForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<ng-container *ngIf="rightService.isRoot() && carburant.typeSource != 'EXTERNE_CATALOGUE' && ((null | right:'creation') || (null | right:'suppression') && carburant.idCarburant > 0)">
				<button mat-stroked-button color="primary" (click)="deleteCarburant();" *ngIf="carburant?.idCarburant && (null | right:'suppression')">
					<span translate>actions.supprimer</span>
				</button>
				<button mat-raised-button color="primary" (click)="saveCarburant();" *ngIf="null | right:'creation'" [disabled]="carburantForm.invalid">
					<span translate>actions.enregistrer</span>
				</button>
			</ng-container>
		</div>
	</div>
</form>