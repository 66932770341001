import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { filter,switchMap,tap } from 'rxjs/operators';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { SchedulerService } from '../../components/scheduler/scheduler.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from '../../components/confirmation/confirm.service';


@Component({
	templateUrl: './account-notification-list-item.component.html'
})
export class AccountNotificationListItemComponent extends ListViewItem<any> {
	/** Liste des types de notification **/
	mapNotifications: { [key: string]: { icone: string,type?: string } } = {
		'com.notilus.data.extraction.Extraction': {
			icone: 'upload'
		},
		'com.notilus.data.dashboard.DashboardChart': {
			icone: 'pin'
		}
	};

	/**
	 * Constructeur
	 */
	constructor(private schedulerService: SchedulerService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idUserScheduler,object?.idUserScheduler && !this.data?.idUserScheduler);
	}

	/**
	 * Affichage du scheduler
	 */
	showScheduler() {
		//Affichage du scheduler
		this.schedulerService.showScheduler(this.data.scheduler,this.data.profil == null).pipe(
			filter(scheduler => scheduler !== undefined),
			tap(scheduler => scheduler != null),
			switchMap(scheduler => {
				//Vérification de la présence du scheduler
				if (scheduler) {
					//Mise à jour du scheduler
					return this.schedulerService.updateUserScheduler(this.data.idUserScheduler,scheduler).pipe(
						tap((result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Mise à jour du scheduler
								Object.assign(this.data,result.data.userScheduler);

								//Message de succès
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'))
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						})
					);
				} else {
					//Suppression du scheduler
					return this.confirmService.showConfirm(this.translateService.instant('account.notification.suppression.confirmation')).pipe(
						filter(isConfirmed => !!isConfirmed),
						switchMap(() => this.schedulerService.deleteUserScheduler(this.data)),
						tap((result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Mise à jour de la liste
								this.onObjectChange(null);

								//Message de succès
								this.toastrService.success(this.translateService.instant('actions.suppression.success'))
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						})
					);
				}
			})
		).subscribe();
	}
}