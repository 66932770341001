<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.score }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" *ngIf="data.commentaire" (click)="data.isDisplayed = !data.isDisplayed">{{ data.user | display:'user' }}</a>
			<span *ngIf="!data.commentaire">{{ data.user | display:'user' }}</span>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>article.feedback.client</span>&#160;:&#160;<span>{{ data.tenant.code }}</span></li>
		<li><span translate>article.feedback.date</span>&#160;:&#160;<span>{{ data.date | date:'short' }}</span></li>
		<li><span translate>article.feedback.score</span>&#160;:&#160;<span *ngFor="let idx of [1,2,3,4,5]"><icon [name]="idx > data.score ? 'star_outline' : 'star'" class="m-l-5" [ngClass]="idx <= data.score && 'c-warning'"></icon></span></li>
		<li><span translate>article.feedback.commentaire</span>&#160;:&#160;<span [translate]="'common.'+(data?.commentaire ? 'oui' : 'non')"></span></li>
	</ul>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<article-feedback [feedback]="data" (onObjectChange)="onObjectChange($event)"></article-feedback>
</div>