import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { CompteTaxeListItemComponent } from './compte-taxe-list-item.component';

@Component({
	selector: 'compte-taxe-list',
	templateUrl: './compte-taxe-list.component.html'
})
export class CompteTaxeListComponent extends PageContentItem implements OnInit {
	/** Taux **/
	@Input() taux: any;

	/** Compte **/
	@Input() compte: any;

	/** Affichage de la taxe **/
	@Input() addTaxe: any;

	/** Données **/
	liste: ListView<any,CompteTaxeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CompteTaxeListItemComponent>({
			uri: () => `/controller/Compte/filtreTauxForPlanComptable/${this.compte?.planComptable?.idPlanComptable}`,
			component: CompteTaxeListItemComponent,
			extraOptions: {
				addTaxe: this.addTaxe
			},
			isLocal: true
		});
	}
}