import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { TypeDroit } from 'src/app/domain/security/right';
import { CompteAuxiliaireAddComponent } from 'src/app/components/comptabilite/compte-auxiliaire/compte-auxiliaire-add.component';

/**
 * Options des comptes auxiliaires
 */
export default class CompteAuxiliaireOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.compteAuxiliaire';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Compte/filtreComptesAuxiliaires';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.numero}${item.libelle ? ' (' + item.libelle + ')' : ''}` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.numero?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.comptabilite.CompteAuxiliaire';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idCompteAuxiliaire'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'numero',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idCompte && {
			clef: 'compte.idCompte',
			valeur: filter.idCompte,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		}].filter(f => !!f);
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'comptabilite.compteAuxiliaire.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return CompteAuxiliaireAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_PLAN_COMPTABLE;
}