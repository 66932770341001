import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class CodeService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression du code
	 */
	public deleteCode(code: any): Observable<Result> {
		//Suppression du code
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteCode/${code.idCode}`);
	}

	/**
	 * Enregistrement du code
	 */
	public saveCode(code: any): Observable<Result> {
		//Enregistrement du code
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveCode`,code);
	}
}