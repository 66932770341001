<div class="modal-header">
	<h4 class="modal-title"><span translate>vehicule.modele.configuration.title</span></h4>
</div>
<div class="modal-body configurateur">
	<div class="text-center p-10" *ngIf="isLoading">
		<please-wait><h5 translate>vehicule.modele.configuration.chargement.enCours</h5></please-wait>
	</div>
	<div class="text-center p-10" *ngIf="!isLoading && !configuration">
		<h5 translate>vehicule.modele.configuration.chargement.erreur</h5>
	</div>
	<stepper #stepper="stepper" *ngIf="!isLoading && configuration" [listeSteps]="listeSteps"></stepper>
</div>
<div *ngIf="!isLoading" class="modal-footer">
	<button mat-button (click)="bsModalRef?.hide()">
		<span translate>actions.annuler</span>
	</button>
	<ng-container [ngSwitch]="stepperComponent?.getSelectedStep()?.type || 'OPTION'">
		<ng-container *ngSwitchCase="'OPTION'">
			<button mat-raised-button color="primary" (click)="stepperComponent?.selectStep(listeSteps?.[1])">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'RECAPITULATIF'">
			<button mat-raised-button color="primary" (click)="saveConfiguration(configuration)">
				<span translate>actions.terminer</span>
			</button>
		</ng-container>
	</ng-container>
</div>