<form #databaseForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!database.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>database.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="database.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
							<label translate>database.type.item</label>
							<div>
								<div class="input-group">
									<selectpicker name="type" #typeField="ngModel" [(ngModel)]="database.type" required [disabled]="database.idDatabase && database.listeRevendeurs?.length > 1">
										<mat-option *ngFor="let type of listeTypesDatabase" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
									<div class="input-group-addon">
										<icon name="warning" *ngIf="database.idDatabase && database.listeRevendeurs?.length > 1" [tooltip]="'database.type.description' | translate"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': schema.invalid }">
							<label translate>database.schema</label>
							<div>
								<input type="text" name="schema" #schema="ngModel" class="form-control" [(ngModel)]="database.schema" maxlength="100" required/>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>database.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="database.actif"><span translate>database.actif.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>database.pieceJointes</label>
							<div>
								<div [attachment]="database" [typeAttachment]="TypeAttachment.DATABASE"></div>
							</div>
						</div>
					</div>
				</div>

				<basket [model]="database" propertyName="listeRevendeurs" colWidthLabel="2" [canAddItem]="database.type == 'MULTI_TENANT' || !database.listeRevendeurs?.length" colWidthAutocomplete="6" [isItemDisabled]="isRevendeurDisabled" [confirmRemoveItem]="confirmRemoveRevendeur" type="revendeur" [labelAutocomplete]="'database.revendeur.ajout' | translate" [labelListe]="'database.revendeur.liste' | translate"></basket>

				<div class="form-group" [ngClass]="{ 'has-error': remarque.invalid }">
					<label translate>database.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="database.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: databaseForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteDatabase();" *ngIf="database?.idDatabase && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveDatabase();" *ngIf="null | right:'creation'" [disabled]="databaseForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>