import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';

import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export-rubrique-fonction-si-condition-item',
	templateUrl: './structure-export-rubrique-fonction-si-condition-item.component.html'
})
export class StructureExportRubriqueFonctionSiConditionItemComponent implements OnInit {
	/** Fonction parent **/
	@Input() parentFonction: any;

	/** Position de la condition **/
	@Input() idxCondition: number;

	/** Elément courant **/
	@Input() item: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Changement du type de rubrique **/
	@Output() onTypeRubriqueChange : EventEmitter<{ parent: any,parentKey: string }> = new EventEmitter<{ parent: any,parentKey: string }>();

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la liste des opérateurs
		this.initListeOperateurs();
	}

	/**
	 * Suppression de la condition
	 */
	deleteCondition(parent: any,idxItem: number) {
		//Suppression de l'élément
		parent.condition.listeItems.splice(idxItem,1);

		//Vérification de la liste des éléments
		if (!parent.condition.listeItems.length)
			//Suppression de la condition
			parent.condition = null;
	}

	/**
	 * Initialisation de la liste des opérateurs
	 */
	initListeOperateurs() {
		//Définition des opérateurs
		this.item.listeOperateurs = this.structureExportService.initListeComparateurs(this.item);

		//Retour de la liste des opérateurs
		return this.item.listeOperateurs;
	}
}