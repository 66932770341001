
import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { StructureExportService } from './structure-export.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'structure-export-groupe',
	templateUrl: './structure-export-groupe.component.html'
})
export class StructureExportGroupeComponent extends PageContentItem {
	/** Structure d'export courante **/
	@Input() structureExport: any;

	/** Détail courant **/
	@Input() detail: any;

	/** Fichier courant **/
	@Input() fichierDetail: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du fichier
	 */
	saveDetail() {
		//Sauvegarde du détail
		this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Fermeture de la fenêtre
				this.close({
					structureExport: result.data.structureExport,
					detail: result.data.detail
				});
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}
}