import { Component,Input,ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';
import { v4 } from 'uuid';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { WorkflowService } from '../workflow.service';

@Component({
	selector: 'workflow-detail-button',
	templateUrl: './workflow-detail-button.component.html',
	styles: ['workflow-detail-button { display: contents; }'],
	encapsulation: ViewEncapsulation.None
})
export class WorkflowDetailButtonComponent {
	/** Workflow **/
	@Input() readonly workflow: any;

	/** Branche courante **/
	@Input() readonly currentBranche: any;

	/** Mode **/
	@Input() readonly mode: 'branche' | 'step' = 'step';

	/** Présence d'une branche **/
	@Input() readonly isBranche: boolean = false;

	/** Index de l'étape **/
	@Input() readonly index: number;

	/** Etat d'ouverture **/
	isOpened: boolean = false

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private workflowService: WorkflowService) { }

	/**
	 * Ajout d'une branche
	 */
	addBranch(index: number = this.index) {
		//Création d'un observable
		of(index).pipe(
			switchMap((index) => {
				//Vérification de l'index
				if (index === undefined) {
					//Définition du mode
					return of({ mode: 'push' });
				} else {
					//Définition du mode (emplacement de la nouvelle branche)
					return this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.emplacementNouvelleBranche'),{ buttonType: 'YES_NO',actionColor: 'primary' }).pipe(
						map(isConfirmed => isConfirmed ? { mode: 'split',index } : { mode: 'slice',index })
					);
				}
			})
		).subscribe({
			next: (result: { mode: 'push' | 'slice' | 'split',index?: number }) => {
				let etape: any;

				//Définition d'une étape
				etape = {
					deletable: true,
					listeBranches: [{
						key: v4().toString(),
						deletable: true,
						libelle: '',
						listeEtapes: [],
						listeBranches: []
					},{
						libelle: null,
						rule: null,
						listeEtapes: [],
						listeBranches: []
					}]
				};

				//Vérification du mode
				if (result.mode == 'split') {
					//Définition de l'étape
					etape.listeBranches[0].listeEtapes = this.currentBranche.listeEtapes.splice(index);

					//Ajout de l'étape
					(this.currentBranche.listeEtapes as Array<any>).push(etape);
				} else if (result.mode == 'push') {
					//Ajout de l'étape
					(this.currentBranche.listeEtapes as Array<any>).push(etape);
				} else if (result.mode == 'slice') {
					//Ajout de l'étape
					(this.currentBranche.listeEtapes as Array<any>).splice(index,0,etape);
				}
			}
		})
	}

	/**
	 * Ajout d'une branche sur la ligne
	 */
	addBranchInLine(index = this.index) {
		let branche: any;

		//Définition d'une branche
		branche = {
			key: v4().toString(),
			deletable: true,
			libelle: '',
			listeEtapes: [],
			listeBranches: []
		};

		//Ajout d'une étape
		this.currentBranche.listeEtapes?.[index]?.listeBranches?.splice(this.currentBranche?.listeEtapes[index]?.listeBranches?.length - 1,0,branche)
	}

	/**
	 * Ajout d'une étape de validation
	 */
	addStep(index: number = this.index) {
		//Affichage de la pop-up de validation
		this.workflowService.showValideurSelection().pipe(
			switchMap((etape) => this.workflowService.showValideurDefinition(this.workflow.entite,etape,true))
		).subscribe({
			next: (etape) => {
				//Vérification de l'index
				if (index === undefined)
					//Ajout d'une étape
					(this.currentBranche.listeEtapes as Array<any>).push(etape);
				else
					//Insertion d'une étape
					(this.currentBranche.listeEtapes as Array<any>).splice(index,0,etape);
			}
		})
	}
}