import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeAmenagementService } from './type-amenagement.service';

@Component({
	selector: 'type-amenagement-field-list',
	templateUrl: './type-amenagement-field-list.component.html'
})
export class TypeAmenagementFieldListComponent extends PageContentItem implements OnInit {
	/** Type d'aménagement **/
	@Input() typeAmenagement: any;

	/** Mode édition **/
	@Input() isEdition: boolean;

	/** Liste des champs à afficher **/
	public listeFields: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private typeAmenagementService: TypeAmenagementService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation des listes de filtre
		this.listeFields = cloneDeep(this.typeAmenagement.listeFields)?.filter(field => field.actif || this.isEdition);
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveTypeAmenagement() {
		//Affectation de la liste des champs
		this.typeAmenagement.listeFields = this.listeFields;

		//Enregistrement de l'objet
		this.typeAmenagementService.saveTypeAmenagement(this.typeAmenagement).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.typeAmenagement,result.data?.typeAmenagement);

					//Fermeture du formulaire
					this.close({ typeAmenagement: this.typeAmenagement });
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}