import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'demande-vehicule-financement-list',
	templateUrl: './demande-vehicule-financement-list.component.html'
})
export class DemandeVehiculeFinancementListComponent extends PageContentItem {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Utilisateur **/
	@Input() user: any;

	/** Résumé **/
	@Input() resume: { nbFinancements: number };

	/** Suppression du financement **/
	@Input() deleteFinancement: Function;

	/** Affichage du financement **/
	@Input() addFinancement: Function;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}
}