import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { StructureExportService } from './structure-export.service';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './structure-export-list-item.component.html'
})
export class StructureExportListItemComponent extends ReferentielEntiteListItem<any> implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.export.StructureExport';

	/**
	 * Constructeur
	 */
	constructor(rightService: RightService,referentielService: ReferentielService,private structureExportService: StructureExportService,private historyService: HistoryService,private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private layoutService: LayoutService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.export.StructureExport',this.data.idStructure);
	}

	/**
	 * Suppression d'une structure d'export
	 */
	deleteStructureExport() {
		 //Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.structureExportService.deleteStructureExport(this.liste.selectedSelector,this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Vérification du type de référentiel et de la suppression d'une structure d'export importée
				if (this.liste.selectedSelector == TypeReferentiel.LOCAL && this.data.structureExportOrigine)
					//Rafraichissement du compteur des entités standard
					this.refreshReferentiel();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Récupération des sociétés associées à une structure d'export
	 */
	getListeSocietes() {
		//Retour des libellés des sociétés
		return this.data?.listeSocietes?.map?.(societe => societe.libelle).join?.(', ') || '';
	}

	/**
	 * Duplication d'une structure d'export
	 */
	duplicateStructureExport(structureExport: any) {
		//Redirection vers le formulaire
		this.layoutService.goToByState('comptabiliteReferentiel-listeStructuresExport-loadStructureExport',{
			routeParams: {
				idStructureExport: 0,
				typeReferentiel: this.liste.selectedSelector
			},
			reloadOnSameUrl: true,
			routeData: {
				idStructureSource: structureExport.idStructure
			}
		});
	}
}