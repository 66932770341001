<div class="pull-left lv-avatar"><icon name="credit_card"></icon></div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="goToEquipement();">{{ data.equipement.reference }}<span *ngIf="data.equipement.numero">&#160;-&#160;{{ data.equipement.numero }}</span>&#160;({{ data.equipement.fournisseur.libelle }})</a></div>
	<ul class="lv-attrs">
		<li *ngIf="data.type"><span translate>equipement.type.item</span>&#160;:&#160;<span [translate]="'equipement.type.'+data.equipement.type"></span></li>
		<li><span translate>equipement.affectation.dateDebut</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li><span translate>equipement.affectation.dateFin</span>&#160;:&#160;<span *ngIf="data.dateFin">{{ data.dateFin | date:'short' }}</span><span *ngIf="!data.dateFin" translate>common.aucune</span></li>
		<li><span translate>equipement.statut.item</span>&#160;:&#160;<span [translate]="'equipement.statut.'+data.equipement.statut"></span></li>
	</ul>

	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="goToEquipement();" translate>conducteur.equipement.actions.accederEquipement</a></li>
			<li><a [routerLink]="[]" *ngIf="TypeDroit.EQUIPEMENT | right:'creation'" (click)="deleteEquipement();" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>