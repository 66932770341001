import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des métiers des fournisseurs
 */
export default class FournisseurMetierOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.fournisseurMetier';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Fournisseur/listeMetiersActifs';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle}${item?.reference ? ' (' + item.reference + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.fournisseur.FournisseurMetier';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idMetier'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}
}