import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { EtatLieuxContentComponent } from './etat-lieux-content.component';
import { EtatLieuxListItemComponent } from './etat-lieux-list-item.component';
import { EtatLieuxListComponent } from './etat-lieux-list.component';
import { EtatLieuxService } from './etat-lieux.service';
import { EtatLieuxComponent } from './etat-lieux.component';
import { EtatLieuxPartListComponent } from './etat-lieux-part-list.component';

@NgModule({
	imports: [ShareModule],
	declarations: [EtatLieuxListComponent,EtatLieuxListItemComponent,EtatLieuxComponent,EtatLieuxPartListComponent,EtatLieuxContentComponent],
	exports: [EtatLieuxContentComponent],
	providers: [EtatLieuxService]
})
export class EtatLieuxModule { }