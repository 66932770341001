import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';

import { ImmatriculationComponent } from './immatriculation.component';
import { ImmatriculationService } from './immatriculation.service';

@Component({
	templateUrl: './immatriculation-autre.component.html'
})
export class ImmatriculationAUTREComponent extends ImmatriculationComponent {
	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImmatriculationComponent>,protected immatriculationService: ImmatriculationService,protected toastrService: ToastrService,protected translateService: TranslateService,protected confirmService: ConfirmService,protected vehiculeService: VehiculeService,protected layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super(bsModalRef,immatriculationService,toastrService,translateService,confirmService,vehiculeService,layoutService,rightService);
	}
}