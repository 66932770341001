import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ServiceCompteListComponent } from './service-compte-list.component';
import { ServiceEditComponent } from './service-edit.component';
import { ServiceFacturationEditComponent } from './service-facturation-edit.component';
import { ServiceFacturationListItemComponent } from './service-facturation-list-item.component';
import { ServiceFacturationListComponent } from './service-facturation-list.component';
import { ServiceAliasEditComponent } from './service-alias-edit.component';
import { ServiceAliasListItemComponent } from './service-alias-list-item.component';
import { ServiceAliasListComponent } from './service-alias-list.component';
import { ServiceListItemComponent } from './service-list-item.component';
import { ServiceListComponent } from './service-list.component';
import { ServiceMoveComponent } from './service-move.component';
import { ServicePlanComptableListItemComponent } from './service-plan-comptable-list-item.component';
import { ServicePlanComptableListComponent } from './service-plan-comptable-list.component';
import { ServiceServiceListItemComponent } from './service-service-list-item.component';
import { ServiceServiceListComponent } from './service-service-list.component';
import { ServiceUserListItemComponent } from './service-user-list-item.component';
import { ServiceUserListComponent } from './service-user-list.component';
import { ServiceComponent } from './service.component';
import { ServiceService } from './service.service';
import { ServiceVehiculeListComponent } from './service-vehicule-list.component';
import { ServiceVehiculeListItemComponent } from './service-vehicule-list-item.component';
import { ComptabiliteModule } from 'src/app/components/comptabilite/comptabilite.module';

@NgModule({
	imports: [ShareModule,ComptabiliteModule],
	declarations: [ServiceListComponent,ServiceListItemComponent,ServiceMoveComponent,ServiceComponent,ServiceEditComponent,ServicePlanComptableListComponent,ServicePlanComptableListItemComponent,ServiceServiceListComponent,ServiceServiceListItemComponent,ServiceCompteListComponent,ServiceFacturationListComponent,ServiceFacturationListItemComponent,ServiceFacturationEditComponent,ServiceUserListComponent,ServiceUserListItemComponent,ServiceAliasListComponent,ServiceAliasListItemComponent,ServiceAliasEditComponent,ServiceVehiculeListComponent,ServiceVehiculeListItemComponent],
	providers: [ServiceService]
})
export class ServiceModule { }
