<div class="pull-left lv-avatar">
	<icon name="check" *ngIf="data.vehicule"></icon>
	<icon name="question_mark" *ngIf="!data.vehicule"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addReleveCompteur()">
			<span *ngIf="data?.vehicule">{{ data.vehicule | display:'vehicule' }}</span>
			<span *ngIf="!data?.vehicule" translate>vehicule.releveCompteur.vehiculeNonCommunique</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.releveCompteur.date</span>&#160;:&#160;{{ data.dateReleve | date:'shortDate' }}</li>
		<li><span translate>vehicule.releveCompteur.releveCompteur</span>&#160;:&#160;{{ !data.vehicule || data.vehicule.uniteUsage == 'DISTANCE' ? (data.releveCompteur | number:'.2-2') : (data.releveHoraire | number) }}&#160;{{ !data.vehicule || data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</li>
		<li><span translate>vehicule.releveCompteur.valeurReference.item</span>&#160;:&#160;<span [translate]="'common.' + (data.valeurReference ? 'oui' : 'non')"></span></li>
		<li *ngIf="data?.extension?.userAffecte"><span translate>vehicule.releveCompteur.userAffecte</span>&#160;:&#160;<span>{{ data.extension.userAffecte | display:'user' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="(TypeDroit.VEHICULE_COMPTEUR | right:'suppression') && !data.readOnly"><a [routerLink]="[]" (click)="deleteReleveCompteur();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>