<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idTypeAmenagement">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Vehicule/loadTypeAmenagement/',data.idTypeAmenagement]">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idTypeAmenagement">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idTypeAmenagement">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteTypeAmenagement();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>