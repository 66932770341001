import { Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

import { ModeleService } from '../modele.service';

@Component({
	selector: 'modele-pneumatique',
	templateUrl: './modele-pneumatique.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModelePneumatiqueComponent implements OnInit {
	/** Pneumatiques **/
	@Input() pneumatique: any;

	/** Liste des types de roue de secours **/
	public listeTypesRoueSecours: Array<{ code: string,libelle: string }>

	/**
	 * Constructeur
	 */
	constructor(private modeleService: ModeleService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types de roue de secours
		this.listeTypesRoueSecours = this.modeleService.getListeTypesRoueSecours();
	}
}
