<div class="modal-header">
	<h4 class="modal-title">{{ options.title }}</h4>
</div>
<div class="modal-body comparator">
	<div class="head-info" *ngIf="options.headInfo" [innerHtml]="options.headInfo"></div>
	<div class="header" *ngIf="options.itemTitle">
		<ul class="caption">
			<li>{{ options.itemTitle }}</li>
		</ul>
		<div class="content">
			<ul class="items position-{{position}}">
				<li class="title" *ngFor="let item of listeItems">
					<ng-template [ngTemplateOutlet]="titleTemplate" [ngTemplateOutletContext]="{ item,displayTitle: options?.getFormatItemTitle?.(item) }"></ng-template>
				</li>
			</ul>
		</div>
	</div>
	<div class="body" [ngClass]="'body-length-'+((options.listeSections?.length + 1) < 10 ? options.listeSections?.length + 1 : 'max')">
		<ul class="sections">
			<ul>
				<li [ngClass]="{ 'last': last }" *ngFor="let section of options.listeSections; let last = last;">
					<span>{{ section.title }}</span>
					<span [ngClass]="{ 'only-one-title': !section.subTitle && !section.showDetail }"></span>
					<span class="sub-title" *ngIf="section.subTitle">{{ section.subTitle }}</span>
					<span *ngIf="section.showDetail">
						<a class="d-flex align-items-center gap-1" [routerLink]="[]" (click)="section?.showDetail?.()">
							<span translate>comparator.detail</span>
							<icon name="search"></icon>
						</a>
					</span>
				</li>
				<li *ngIf="options.listeActions?.length > 0" class="list-actions"></li>
			</ul>
		</ul>
		<div class="content">
			<ul class="items position-{{position}}">
				<li *ngFor="let item of listeItems; let idxItem = index">
					<ul class="datas" [ngClass]="{ 'item-selected': options.isSelected(item) }">
						<li class="item-content" [ngClass]="{ 'last': last }" *ngFor="let section of options.listeSections; let last = last;">
							<ng-template [ngTemplateOutlet]="dataTemplate" [ngTemplateOutletContext]="{ data: section.compare?.(item),item }"></ng-template>
						</li>
						<li class="item-content list-actions" *ngIf="options.listeActions?.length">
							<ng-template [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ listeActions: getListeVisibleActions(item,options.listeActions),item,onDataChange }"></ng-template>
						</li>
					</ul>
				</li>
			</ul>
			<div class="navigation left" *ngIf="position > 0" (click)="position = position - 1;">
				<icon name="chevron_left" class="icon-hc-5x"></icon>
			</div>
			<div class="navigation right" *ngIf="position < listeItems.length - 3" (click)="position = position + 1;">
				<icon name="chevron_right" class="icon-hc-5x"></icon>
			</div>
		</div>
	</div>
</div>
<div class="clearfix"></div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
</div>

<ng-template #titleTemplate let-item="item" let-displayTitle="displayTitle">
	<span>{{ displayTitle.title?.line1 }}</span>
	<span>{{ displayTitle?.title?.line2 || '&#160;' }}</span>
	<span class="sub-section">
		<span class="sub-text">{{ displayTitle?.subTitle || '&#160;' }}</span>
		<span class="attachment" *ngIf="displayTitle.nbAttachments" [attachment]="item" [typeAttachment]="options?.typeAttachment" [showLabel]="false" readOnly="true"></span>
	</span>
</ng-template>

<ng-template #dataTemplate let-item="item" let-data="data">
	<icon *ngIf="data?.icone" class="icon-hc-3x" [name]="data.icone"></icon>
	<span *ngIf="data?.title && !data?.showItem">{{ data.title }}</span>
	<span *ngIf="data?.htmlTitle" [innerHtml]="data.htmlTitle"></span>
	<span *ngIf="data?.showItem && data?.title">
		<a [routerLink]="[]" (click)="data.showItem(item)">
			<icon class="icon-hc-lg" name="search"></icon><span class="p-l-5">{{ data.title }}</span>
		</a>
	</span>
	<div *ngIf="data?.counter" class="counter">
		<span>{{ data.counter }}</span>
	</div>
	<span class="attachment" *ngIf="data?.nbAttachments" [attachment]="item" [typeAttachment]="options?.typeAttachment" [showLabel]="false" readOnly="true"></span>
</ng-template>

<ng-template #actionsTemplate let-item="item" let-listeActions="listeActions" let-onDataChange="onDataChange">
	<div class="d-flex gap-2 justify-content-center">
		<ng-container *ngFor="let action of listeActions">
			<button *ngIf="action?.type == 'primary'" mat-raised-button color="primary" [disabled]="!action.isValid(item)" (click)="action.doAction(item,onDataChange)">
				<span>{{ action.title }}</span>
			</button>
			<button *ngIf="action?.type != 'primary'" mat-stroked-button color="primary" [disabled]="!action.isValid(item)" (click)="action.doAction(item,onDataChange)">
				<span>{{ action.title }}</span>
			</button>
		</ng-container>
	</div>
	<span *ngIf="!listeActions?.length">&#160;</span>
</ng-template>