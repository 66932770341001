import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { ControleReglementaireService } from './controle-reglementaire.service';

@Component({
	selector: 'controle-reglementaire-rule',
	templateUrl: './controle-reglementaire-rule.component.html'
})
export class ControleReglementaireRuleComponent extends PageContentItem implements OnInit {
	/** Contrôle réglementaire **/
	getControleReglementaire: () => any;

	/** Récupération de la règle **/
	getRule: () => any;

	/** Mode 'Edition' */
	isEdition: boolean;

	/** Règle courante **/
	rule: any;

	/** Contrôle réglementaire courant **/
	controleReglementaire: any;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService,private controleReglementaireService: ControleReglementaireService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la règle
		this.rule = this.getRule?.();

		//Récupération du contrôle réglementaire
		this.controleReglementaire = this.getControleReglementaire?.();
	}

	/**
	 * Enregistrement de la rule
	 */
	saveRule() {
		//Mise à jour de la règle
		this.rule = this.ruleService.updateRuleForSave(this.rule);

		//Mise à jour de la règle
		this.controleReglementaire = Object.assign(this.controleReglementaire,{ rule: this.rule.listeDetails?.length ? this.rule : null });

		//Enregistrement du contrôle réglementaire
		this.controleReglementaireService.saveControleReglementaire(this.controleReglementaire).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Retour du contrôle réglementaire
					this.close(Object.assign(this.controleReglementaire,result.data.controleReglementaire));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}