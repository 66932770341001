import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { UpgradeLogComponent } from './upgrade-log.component';

@Injectable()
export class UpgradeService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService) {}

	/**
	 * Chargement du détail d'une version
	 */
	public loadDetail(version: any): Observable<Result> {
		//Chargement du détail des mises à jour
		return this.http.post<Result>(`${environment.baseUrl}/controller/Upgrader/loadDetail`,version);
	}

	/**
	 * Affichage du log
	 */
	public showLog(item: any): Observable<any> {
		let bsModalRef: BsModalRef<UpgradeLogComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(UpgradeLogComponent,{
			initialState: {
				log: item.log
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
}