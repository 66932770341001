<form #optionCategorieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!optionCategorie.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>vehicule.optionCategorie.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="optionCategorie.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>vehicule.optionCategorie.reference</label>
							<div>
								<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="optionCategorie.reference" maxlength="100" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
							<label translate>vehicule.optionCategorie.pays</label>
							<div>
								<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="optionCategorie.pays" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
							<label translate>vehicule.optionCategorie.typeOption.item</label>
							<div>
								<selectpicker name="type" #typeField="ngModel" [(ngModel)]="optionCategorie.type" required>
									<mat-option *ngFor="let typeOption of listeTypesOption" [value]="typeOption.code">{{ typeOption.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: optionCategorieForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteOptionCategorie();" *ngIf="optionCategorie?.idCategorie && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveOptionCategorie();" *ngIf="null | right:'creation'" [disabled]="optionCategorieForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>