import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep,intersection } from 'lodash-es';
import { BehaviorSubject,Observable } from 'rxjs';

import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { AppState } from 'src/app/domain/appstate';
import { TypeDashboard } from 'src/app/domain/dashboard/dashboard';
import { Droit } from 'src/app/domain/droit/droit';
import { MenuItem } from 'src/app/domain/menu/menu';
import { TypePlanning } from 'src/app/domain/planning/planning';
import { TypeDroit,TypeGroupe,TypeTenant } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	/** Utilisateur connecté **/
	public user: User;

	/** Indicateur d'ouverture du menu **/
	public isOpened$: Observable<boolean>;

	/** Menu sélectionné **/
	public selectedMenu: MenuItem;

	/** Menu visible de l'application **/
	public listeItems: Array<MenuItem> = [];

	/** Sujet sur l'état d'ouverture du menu **/
	private isOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/** Menu de l'application **/
	private readonly originalListeItems: Array<MenuItem> = [{
		libelle: 'menu.dashboard',
		icone: 'home',
		url: '/Dashboard',
		isOnlyMenu: true
	},{
		libelle: 'menu.yourway.item',
		icone: 'directions_car',
		groupe: TypeGroupe.YOURWAY,
		order: 2,
		listeItems: [{
			libelle: 'menu.yourway.planning',
			url: `/Planning/${TypePlanning.VEHICULE}`,
			listeRights: [TypeDroit.ADMIN_VEHICULE,TypeDroit.VEHICULE_CONDUCTEUR],
			listeTenantTypes: [TypeTenant.CLIENT],
			isOnlyMenu: true
		},{
			libelle: 'menu.yourway.vehicules',
			url: `/DashboardRedirector/${TypeDashboard.VEHICULE}`,
			listeRights: [TypeDroit.ADMIN_VEHICULE],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeVehicules'
		},{
			libelle: 'menu.yourway.affectation',
			url: `/DashboardRedirector/${TypeDashboard.VEHICULE_AFFECTATION}`,
			listeRights: [TypeDroit.ADMIN_VEHICULE,TypeDroit.VEHICULE_CONDUCTEUR],
			listeTenantTypes: [TypeTenant.CLIENT],
			isOnlyMenu: true
		},{
			libelle: 'menu.yourway.contrats',
			url: `/DashboardRedirector/${TypeDashboard.FINANCEMENT}`,
			listeRights: [TypeDroit.VEHICULE_CONTRAT_FINANCEMENT],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeVehiculeContratsFinancement-loadContratFinancement'
		},{
			libelle: 'menu.yourway.conducteur',
			url: `/DashboardRedirector/${TypeDashboard.CONDUCTEUR}`,
			listeRights: [TypeDroit.VEHICULE_CONDUCTEUR],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeConducteurs-loadConducteur'
		},{
			libelle: 'menu.yourway.affectationEcheance',
			url: `/DashboardRedirector/${TypeDashboard.VEHICULE_AFFECTATION_ECHEANCE}`,
			listeRights: [TypeDroit.VEHICULE_AFFECTATION_ECHEANCE],
			listeTenantTypes: [TypeTenant.CLIENT]
		},{
			libelle: 'menu.yourway.grilleAttribution',
			url: `/DashboardRedirector/${TypeDashboard.GRILLE_ATTRIBUTION}`,
			listeRights: [TypeDroit.VEHICULE_GRILLE_ATTRIBUTION],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeGrillesAttribution-grilleAttribution'
		},{
			libelle: 'menu.yourway.commandes',
			url: '/VehiculeCommande/listeReferentiels',
			listeRights: [TypeDroit.DEMANDE_COTATION,TypeDroit.DEMANDE_VEHICULE],
			listeTenantTypes: [TypeTenant.CLIENT],
			isDashboard: true,
			creationState: [{
				libelle: 'droits.demande_vehicule',
				listeRights: [TypeDroit.DEMANDE_VEHICULE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'vehiculeCommandeReferentiels-listeDemandesVehicule'
			},{
				libelle: 'droits.demande_cotation',
				listeRights: [TypeDroit.DEMANDE_COTATION],
				listeTenantTypes: [TypeTenant.CLIENT]
			}]
		},{
			libelle: 'menu.yourway.sinistres',
			url: `/DashboardRedirector/${TypeDashboard.SINISTRE}`,
			listeRights: [TypeDroit.VEHICULE_SINISTRE],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeVehiculeSinistres-sinistre'
		},{
			libelle: 'menu.yourway.infraction',
			url: '/Infraction/listeReferentiels',
			listeRights: [TypeDroit.VEHICULE_CONTRAVENTION,TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: [{
				libelle: 'droits.vehicule_contravention',
				listeRights: [TypeDroit.VEHICULE_CONTRAVENTION],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'infractionReferentiel-listeVehiculeContraventions-contravention'
			}],
			isDashboard: true
		},{
			libelle: 'menu.yourway.releve.item',
			listeItems: [{
				libelle: 'menu.yourway.releve.compteur',
				url: `/DashboardRedirector/${TypeDashboard.VEHICULE_COMPTEUR}`,
				listeRights: [TypeDroit.VEHICULE_COMPTEUR],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'listeVehiculeCompteur'
			},{
				libelle: 'menu.yourway.releve.energie',
				url: `/DashboardRedirector/${TypeDashboard.VEHICULE_RELEVE_ENERGIE}`,
				listeRights: [TypeDroit.VEHICULE_RELEVE_ENERGIE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'listeVehiculeReleveEnergie'
			}]
		},{
			libelle: 'menu.yourway.facture',
			url: `/DashboardRedirector/${TypeDashboard.FACTURE}`,
			listeRights: [TypeDroit.FACTURE],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeFactures-load'
		},{
			libelle: 'menu.yourway.factureDetail',
			url: `/DashboardRedirector/${TypeDashboard.FACTURE_DETAIL}`,
			listeRights: [TypeDroit.FACTURE_DETAIL],
			listeTenantTypes: [TypeTenant.CLIENT]
		},{
			listeRights: [TypeDroit.FACTURE_LAD],
			listeTenantTypes: [TypeTenant.CLIENT],
			isDroitOnly: true
		},{
			libelle: 'menu.yourway.materiel',
			url: '/Materiel/listeReferentiels',
			listeRights: [TypeDroit.EQUIPEMENT,TypeDroit.VEHICULE_AMENAGEMENT],
			listeTenantTypes: [TypeTenant.CLIENT],
			isDashboard: true,
			creationState: [{
				libelle: 'droits.equipement',
				url: `/DashboardRedirector/${TypeDashboard.EQUIPEMENT}`,
				listeRights: [TypeDroit.EQUIPEMENT],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'materielReferentiel-listeEquipements-loadEquipement'
			},{
				libelle: 'droits.vehicule_amenagement',
				listeRights: [TypeDroit.VEHICULE_AMENAGEMENT],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'materielReferentiel-listeAmenagements'
			}]
		},{
			libelle: 'menu.yourway.entretien',
			url: `/DashboardRedirector/${TypeDashboard.ENTRETIEN}`,
			listeRights: [TypeDroit.ENTRETIEN],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeEntretiens'
		},{
			libelle: 'menu.yourway.etatLieux',
			url: `/DashboardRedirector/${TypeDashboard.ETAT_LIEUX}`,
			listeRights: [TypeDroit.ETAT_LIEUX],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeEtatsLieux-etatLieux'
		},{
			listeRights: [TypeDroit.GRILLE_FLUIDITE],
			listeTenantTypes: [TypeTenant.CLIENT],
			isDroitOnly: true
		},{
			libelle: 'menu.yourway.reservation',
			url: `/DashboardRedirector/${TypeDashboard.VEHICULE_RESERVATION}`,
			listeRights: [TypeDroit.VEHICULE_RESERVATION],
			listeTenantTypes: [TypeTenant.CLIENT]
		}]
	},{
		libelle: 'menu.comptabilite.item',
		icone: 'n-icon-calculator',
		iconeType: 'icon-venus',
		groupe: TypeGroupe.COMPTABILITE,
		order: 3,
		listeItems: [{
			libelle: 'menu.comptabilite.lotComptable',
			url: `/DashboardRedirector/${TypeDashboard.LOT_COMPTABLE}`,
			listeRights: [{
				right: TypeDroit.ADMIN_PLAN_COMPTABLE
			}],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeLotsComptables',
			isOnlyMenu: true
		},{
			libelle: 'menu.referentiel.item',
			url: '/Comptabilite/listeReferentiels',
			listeRights: [TypeDroit.ADMIN_PLAN_COMPTABLE],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
			isDashboard: true,
			creationState: [{
				libelle: 'droits.admin_plan_comptable',
				listeRights: [TypeDroit.ADMIN_PLAN_COMPTABLE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'comptabiliteReferentiel-listePlanComptables-planComptable'
			},{
				libelle: 'droits.compte',
				listeRights: [TypeDroit.ADMIN_PLAN_COMPTABLE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'comptabiliteReferentiel-listeComptes-compte'
			},{
				libelle: 'droits.compte_auxiliaire',
				listeRights: [TypeDroit.ADMIN_PLAN_COMPTABLE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'comptabiliteReferentiel-listeComptesAuxiliaires-compteAuxiliaire'
			},{
				libelle: 'droits.structure_export',
				listeRights: [TypeDroit.ADMIN_PLAN_COMPTABLE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'comptabiliteReferentiel-listeStructuresExport'
			}]
		}]
	},{
		libelle: 'menu.responsable.item',
		icone: 'people',
		groupe: TypeGroupe.RESPONSABLE,
		listeItems: [{
			libelle: 'menu.responsable.aValider',
			url: '/Validation/listeObjets/A_VALIDER/',
			listeTenantTypes: [TypeTenant.CLIENT],
			validationClass: ['com.notilus.data.vehicule.cotation.DemandeVehicule']
		},{
			libelle: 'menu.responsable.valides',
			url: '/Validation/listeObjets/VALIDES/',
			listeTenantTypes: [TypeTenant.CLIENT],
			validationClass: ['com.notilus.data.vehicule.cotation.DemandeVehicule']
		}]
	},{
		libelle: 'menu.administration.item',
		icone: 'settings',
		groupe: TypeGroupe.ADMIN,
		order: 1,
		listeItems: [{
			libelle: 'menu.administration.users',
			url: `/DashboardRedirector/${TypeDashboard.USER}`,
			listeRights: [TypeDroit.ADMIN_UTILISATEUR],
			creationState: 'listeUsers-user'
		},{
			libelle: 'menu.administration.autorisations',
			url: '/TenantAutorisation/listeAutorisations',
			listeRights: [TypeDroit.ADMIN_TENANT_AUTORISATION],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
			creationState: 'listeTenantAutorisations'
		},{
			libelle: 'menu.administration.groupes',
			url: '/Groupe/listeGroupes',
			listeRights: [TypeDroit.ADMIN_GROUPE],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeGroupes-groupe'
		},{
			libelle: 'menu.administration.bdd',
			url: '/Database/listeDatabases',
			listeRights: [TypeDroit.ADMIN_DATABASE],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeDatabases'
		},{
			libelle: 'menu.administration.revendeur',
			url: '/Revendeur/listeRevendeurs',
			listeRights: [TypeDroit.ADMIN_REVENDEUR],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeRevendeurs'
		},{
			libelle: 'menu.administration.client',
			url: '/Tenant/listeTenants',
			listeRights: [TypeDroit.ADMIN_TENANT,{
				right: TypeDroit.ADMIN_ENVIRONNEMENT,
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
				hidden: true
			},{
				right: TypeDroit.ADMIN_TENANT_AUTORISATION,
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
				hidden: true
			}],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
			creationState: 'listeTenants'
		},{
			libelle: 'menu.administration.licenceConsommation',
			url: '/Licence/listeLicenceConsommations',
			listeRights: [TypeDroit.LICENCE_CONSOMMATION],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeLicenceConsommations'
		},{
			libelle: 'menu.administration.profil',
			url: '/Profil/listeProfils',
			listeRights: [TypeDroit.ADMIN_PROFIL],
			creationState: 'listeProfils-profil'
		},{
			libelle: 'menu.administration.upgrader',
			url: '/Upgrade/listeUpgrades',
			listeRights: [TypeDroit.ADMIN_UPGRADER],
			listeTenantTypes: [TypeTenant.ROOT]
		},{
			libelle: 'menu.administration.internationalisation.item',
			listeItems: [{
				libelle: 'menu.administration.internationalisation.devise',
				url: '/Devise/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_DEVISE],
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT]
			},{
				libelle: 'menu.administration.internationalisation.fiscalite',
				url: '/Fiscalite/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_TAXE,{
					right: TypeDroit.ADMIN_PLAN_COMPTABLE,
					listeTenantTypes: [TypeTenant.ROOT],
					hidden: true
				}],
				listeTenantTypes: [TypeTenant.ROOT]
			}]
		},{
			libelle: 'menu.administration.templateCourriel',
			url: '/TemplateMail/listeTemplateMails',
			listeRights: [TypeDroit.ADMIN_TEMPLATE_MAIL],
			creationState: 'listeTemplateMails'
		},{
			libelle: 'menu.administration.extraction',
			url: '/Extraction/listeExtractions',
			listeRights: [TypeDroit.ADMIN_EXTRACTION],
			creationState: 'listeExtractions-extraction'
		},{
			libelle: 'menu.administration.charting',
			url: '/Chart/listeCharts',
			listeRights: [TypeDroit.ADMIN_CHART],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeCharts-chart'
		},{
			libelle: 'menu.administration.regleAutomatique',
			url: '/RegleAutomatique/listeReglesAutomatiques',
			listeRights: [TypeDroit.REGLE_AUTOMATIQUE],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeReglesAutomatiques-regleAutomatique'
		},{
			libelle: 'menu.administration.calendrier',
			url: '/Calendrier/listeCalendriers',
			listeRights: [TypeDroit.ADMIN_CALENDRIER],
			listeTenantTypes: [TypeTenant.ROOT],
			creationState: 'listeCalendriers-calendrier'
		},{
			libelle: 'menu.analytique.item',
			listeItems: [{
				libelle: 'menu.analytique.organigramme',
				url: '/Analytique/listeServices',
				listeRights: [TypeDroit.ADMIN_ANALYTIQUE_SERVICE],
				listeTenantTypes: [TypeTenant.CLIENT],
				creationState: 'listeServices'
			},{
				libelle: 'menu.analytique.listeProjets1',
				url: '/Analytique/Projet/1/listeProjets',
				listeRights: [TypeDroit.ADMIN_ANALYTIQUE_PROJET_1],
				listeTenantTypes: [TypeTenant.CLIENT],
				isDisabled: true
			},{
				libelle: 'menu.analytique.listeProjets2',
				url: '/Analytique/Projet/2/listeProjets',
				listeRights: [TypeDroit.ADMIN_ANALYTIQUE_PROJET_2],
				listeTenantTypes: [TypeTenant.CLIENT],
				isDisabled: true
			},{
				libelle: 'menu.analytique.listeProjets3',
				url: '/Analytique/Projet/3/listeProjets',
				listeRights: [TypeDroit.ADMIN_ANALYTIQUE_PROJET_3],
				listeTenantTypes: [TypeTenant.CLIENT],
				isDisabled: true
			}]
		},{
			libelle: 'menu.referentiel.item',
			listeItems: [{
				libelle: 'menu.referentiel.geographie',
				url: '/Geographie/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_GEOGRAPHIE_PAYS],
				listeTenantTypes: [TypeTenant.ROOT]
			},{
				libelle: 'menu.referentiel.vehicules',
				url: '/Vehicule/listeReferentiels',
				listeRights: [TypeDroit.VEHICULE_REFERENTIEL_GENRE,TypeDroit.VEHICULE_REFERENTIEL_MARQUE,TypeDroit.VEHICULE_REFERENTIEL_MODELE,TypeDroit.VEHICULE_REFERENTIEL_CARBURANT,TypeDroit.VEHICULE_REFERENTIEL_TYPE_CARROSSERIE,TypeDroit.VEHICULE_REFERENTIEL_TYPE_INFRACTION,TypeDroit.CONTROLE_REGLEMENTAIRE,TypeDroit.VEHICULE_REFERENTIEL_OPTION_CATEGORIE,TypeDroit.VEHICULE_POOL,{
					right: TypeDroit.VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT,
					listeTenantTypes: [TypeTenant.CLIENT]
				},{
					right: TypeDroit.ENTRETIEN,
					listeTenantTypes: [TypeTenant.CLIENT],
					hidden: true
				}],
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
				isDashboard: true,
				creationState: [{
					libelle: 'droits.plan_entretien',
					listeRights: [TypeDroit.ENTRETIEN],
					listeTenantTypes: [TypeTenant.CLIENT],
					creationState: 'vehiculeReferentiel-listePlansEntretien'
				}]
			},{
				libelle: 'menu.referentiel.fournisseur',
				url: '/Fournisseur/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_FOURNISSEUR,{
					right: TypeDroit.ADMIN_FOURNISSEUR_NATURE,
					listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT]
				},{
					right: TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,
					listeTenantTypes: [TypeTenant.ROOT]
				}],
				isDashboard: true,
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
				creationState: [{
					libelle: 'droits.admin_fournisseur',
					listeRights: [TypeDroit.ADMIN_FOURNISSEUR],
					listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
					creationState: 'fournisseurReferentiel-listeFournisseurs'
				},{
					libelle: 'droits.admin_fournisseur_nature',
					listeRights: [TypeDroit.ADMIN_FOURNISSEUR_NATURE],
					listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
					creationState: 'fournisseurReferentiel-listeFournisseurNatures-loadNature'
				}]
			},{
				libelle: 'menu.referentiel.demandesIdentification',
				url: '/Societe/listeReferentiels',
				listeItems: [{
					libelle: 'menu.referentiel.societe',
					url: '/Societe/listeSocietes',
					listeRights: [TypeDroit.ADMIN_SOCIETE],
					listeTenantTypes: [TypeTenant.CLIENT]
				}]
			},{
				libelle: 'menu.referentiel.commerce',
				url: '/Commerce/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_OFFRE,TypeDroit.ADMIN_CLIENT_CRM,TypeDroit.ADMIN_CONTRAT_CRM,TypeDroit.ADMIN_ARTICLE_CRM],
				listeTenantTypes: [TypeTenant.ROOT]
			},{
				libelle: 'menu.referentiel.compteService',
				url: '/CompteService/listeComptes',
				listeRights: [TypeDroit.ADMIN_COMPTE_SERVICE],
				listeTenantTypes: [TypeTenant.ROOT],
				creationState: 'listeComptesService-compteService'
			},{
				libelle: 'menu.referentiel.collaborateur',
				url: '/Collaborateur/listeReferentiels',
				listeRights: [TypeDroit.ADMIN_UTILISATEUR_CATEGORIE],
				listeTenantTypes: [TypeTenant.CLIENT],
				isDashboard: true,
				creationState: [{
					libelle: 'droits.admin_utilisateur_categorie',
					listeRights: [TypeDroit.ADMIN_UTILISATEUR_CATEGORIE],
					listeTenantTypes: [TypeTenant.CLIENT],
					creationState: 'collaborateurReferentiel-listeCategories'
				}]
			},{
				libelle: 'menu.referentiel.champsPersonnalisables',
				url: '/DataCustomization/listeDataCustomizations',
				listeRights: [TypeDroit.DATA_CUSTOMIZATION_ENTITE],
				listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
				creationState: 'dataCustomization-listeDataCustomizations'
			},{
				libelle: 'menu.referentiel.workflowMotif',
				url: '/Workflow/listeMotifs',
				listeRights: [TypeDroit.ADMIN_WORKFLOW_MOTIF],
				creationState: 'workflowReferentiel-listeMotifs'
			}]
		},{
			libelle: 'menu.administration.personalData',
			url: '/PersonalData',
			listeRights: [TypeDroit.ADMIN_PERSONAL_DATA]
		},{
			libelle: 'menu.aide.item',
			listeItems: [{
				libelle: 'menu.aide.articles',
				url: `/DashboardRedirector/${TypeDashboard.ARTICLE}`,
				listeRights: [TypeDroit.ADMIN_AIDE_ARTICLE],
				creationState: 'listeArticles-loadArticle'
			},{
				libelle: 'menu.aide.conversations',
				url: `/Conversation/listeConversations`,
				listeRights: [TypeDroit.ADMIN_CONVERSATION],
				listeTenantTypes: [TypeTenant.ROOT]
			}]
		},{
			libelle: 'menu.administration.workflows',
			url: '/Workflow/listeWorkflows',
			listeRights: [TypeDroit.ADMIN_WORKFLOW],
			listeTenantTypes: [TypeTenant.CLIENT],
			creationState: 'listeWorkflows-workflow'
		},{
			libelle: 'menu.administration.connecteurs',
			url: '/Connecteur/listeConnecteurs',
			listeRights: [TypeDroit.CONNECTEUR],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.CLIENT],
			creationState: 'connecteur-loadConnecteur'
		},{
			libelle: 'menu.administration.imports',
			url: `/DashboardRedirector/${TypeDashboard.IMPORT}`,
			listeRights: [TypeDroit.ADMIN_IMPORT]
		},{
			libelle: 'menu.administration.sgi',
			url: `/DashboardRedirector/${TypeDashboard.SGI_LOT}`,
			listeRights: [TypeDroit.SGI_LOT],
			listeTenantTypes: [TypeTenant.ROOT]
		},{
			listeRights: [TypeDroit.ADMIN_TODO_LIST],
			listeTenantTypes: [TypeTenant.ROOT],
			isDroitOnly: true
		},{
			listeRights: [TypeDroit.ADMIN_ENVIRONNEMENT],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
			isDroitOnly: true
		},{
			listeRights: [TypeDroit.ADMIN_SECRET],
			listeTenantTypes: [TypeTenant.ROOT],
			isDroitOnly: true
		},{
			listeRights: [TypeDroit.ADMIN_AUTHENTIFICATION],
			listeTenantTypes: [TypeTenant.ROOT,TypeTenant.REVENDEUR],
			isDroitOnly: true
		}]
	}];

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private rightService: RightService,private dashboardService: DashboardService) {
		//Sélection de la session
		this.store.select<User>(s => s.session?.user).subscribe(user => {
			//Définition de l'utilisateur
			this.user = user;

			//Definition du menu pour l'utilisateur connecté
			this.listeItems = this.filterStructureMenu(cloneDeep(this.originalListeItems),this.user);
		});

		//Création de l'observable sur l'ouverture/fermeture du menu
		this.isOpened$ = this.isOpenedSubject.asObservable();
	}

	/**
	 * Ouverture/Fermeture du menu
	 */
	public toggleMenu() {
		//Notification de l'ouverture/fermeture du menu
		this.isOpenedSubject.next(!this.isOpenedSubject.value);

		//Masquage des charts
		this.dashboardService.onChartResizeSubject.next({ isResizing: true,isMenu: true });

		//Affichage des charts
		setTimeout(() => this.dashboardService.onChartResizeSubject.next({ isResizing: false,isMenu: true }),300);
	}

	/**
	 * Fermeture du menu
	 */
	public hide() {
		//Fermeture du menu
		this.isOpenedSubject.next(false);
	}

	/**
	 * Filtre récursif de la structure du menu
	 */
	public filterStructureMenu(listeItems: Array<MenuItem> = cloneDeep(this.originalListeItems),user: User = this.user,target: 'menu' | 'droit' = 'menu'): Array<MenuItem> {
		//Itération sur les éléments
		return listeItems.filter((item: MenuItem) => {
			let isItemShown: boolean;

			//Vérification de la présence de sous-éléments
			if (item.listeItems?.length) {
				//Itération sur les sous-éléments
				item.listeItems = this.filterStructureMenu(item.listeItems,user,target);

				//Conservation de l'élément du menu
				return item.listeItems?.length;
			} else {
				//Vérification de la conservation de l'élément du menu
				isItemShown = this.isItemShown(item,user);

				return isItemShown;
			}
		});
	}

	/**
	 * Vérification des propriétés d'un élément du menu
	 */
	private isItemShown(item: MenuItem,user: User,target: 'menu' | 'droit' = 'menu',isIgnoreTenantType: boolean = false): boolean {
		//Verification de la cible de l'élément
		if (target == 'menu' && item.isDroitOnly || target == 'droit' && item.isOnlyMenu || item.isDisabled)
			//Retrait de l'élément du menu
			return false;

		//Vérification des types de tenant
		if (!isIgnoreTenantType && (item.listeTenantTypes?.length && !(item.listeTenantTypes.includes(user?.tenant?.type) || user?.tenant?.type == TypeTenant.REVENDEUR && user?.tenant?.revendeur?.type && item.listeTenantTypes?.includes(user?.tenant?.revendeur?.type))))
			//Retrait de l'élément du menu
			return false;

		//Vérification des droits Workflow
		if (item.validationClass?.length && (!user?.listeEntitesForValideur?.length || user?.listeEntitesForValideur?.length && intersection(user?.listeEntitesForValideur,item.validationClass)?.length == 0))
			//Retrait de l'élément du menu
			return false;

		//Vérification de la présence d'une liste de droits
		if (item.listeRights?.length) {
			//Itération sur les droits
			return item.listeRights.some((right: string | { right?: TypeDroit,listeTenantTypes?: Array<TypeTenant>,hidden?: boolean }) => this.isRightAvailable(right,user,target,isIgnoreTenantType));
		} else
			//Conservation de l'élément du menu
			return true;
	}

	/**
	 * Vérification du droit
	 */
	private isRightAvailable(right: { right?: TypeDroit,listeTenantTypes?: Array<TypeTenant>,hidden?: boolean } | string,user: User,target: 'menu' | 'droit',isIgnoreTenantType: boolean = false): boolean {
		//Vérification du type
		if (typeof right === 'string') {
			//Vérification du droit
			return this.rightService.hasRight(right as TypeDroit,'consultation',user);
		} else {
			//Vérification des types de tenant
			if (!isIgnoreTenantType && (right.listeTenantTypes?.length && !(right.listeTenantTypes.includes(user?.tenant?.type) || user?.tenant?.type == TypeTenant.REVENDEUR && user?.tenant?.revendeur?.type && right.listeTenantTypes?.includes(user?.tenant?.revendeur?.type))))
				//Retrait de l'élément du menu
				return false;

			//Vérification de la propriété 'Masqué'
			if (right.hidden && target == 'droit')
				//Retrait de l'élément du menu
				return false;

			//Vérification du droit
			return this.rightService.hasRight(right.right as TypeDroit,'consultation',user);
		}
	}

	/**
	 * Lecture des droits présent dans le menu
	 */
	public getMapDroits(currentListeDroits?: Array<Droit>,user: User = this.user,listeItems?: Array<MenuItem>,isIgnoreTenantType: boolean = false): Map<{ libelle: string,isGroupe: boolean,hasChild?: boolean,order?: number,typeGroupe: TypeGroupe },Droit | Map<any,any>> {
		const mapDroits = new Map();

		//Vérification de la liste des éléments
		if (!listeItems)
			//Récupération de la liste des éléments d'origine
			listeItems = this.originalListeItems.sort((a,b) => a.order - b.order);

		//Filtrage de la liste des éléments
		listeItems = listeItems.filter((item: MenuItem) => this.isItemShown(item,user,'droit',isIgnoreTenantType));

		//Parcours de la liste des éléments
		listeItems.forEach((item: MenuItem) => {
				let mapSubDroits: Map<{ libelle: string,isGroupe: boolean,hasChild?: boolean,order?: number },Droit | Map<any,any>> = new Map();
				let isGroupe: boolean;

				//Définition de la présence d'un groupe
				isGroupe = item?.listeRights?.length > 1 || item?.listeRights?.length == 1 && (item?.listeItems?.length > 1 || item.isDashboard);

				//Vérification de la présence d'éléments
				if (item?.listeRights?.length) {
					//Itération sur les droits
					item.listeRights.filter((right: string | { right?: TypeDroit,listeTenantTypes?: Array<TypeTenant>,hidden?: boolean }) => this.isRightAvailable(right,user,'droit',isIgnoreTenantType))
						.map((right: (TypeDroit | { right?: TypeDroit })) => typeof right === 'string' ? right : right.right)
						.forEach((typeDroit: TypeDroit) => {
							let droit: Droit;

							//Définition du droit
							droit = new Droit(typeDroit,currentListeDroits?.find((droit: Droit) => droit.typeDroit == typeDroit));

							//Vérification de la présence d'un groupe
							if (!isGroupe)
								//Définition du droit
								mapDroits.set({ libelle: item.libelle || `droits.${typeDroit.toLowerCase()}`,isGroupe: false },droit);
							else
								//Définition du sous-droit
								mapSubDroits.set({ libelle: `droits.${typeDroit.toLowerCase()}`,isGroupe: false },droit);
						});

					//Vérification de la présence d'un groupe
					if (isGroupe)
						//Définition du droit
						mapDroits.set({ libelle: item.libelle,isGroupe: true,hasChild: this.hasChild(mapSubDroits) },mapSubDroits);
				} else if (item?.listeItems?.length > 0) {
					//Définition des droits
					mapSubDroits = this.getMapDroits(currentListeDroits,user,item.listeItems,isIgnoreTenantType);

					//Vérification de la présence de sous-droit
					if (mapSubDroits.size > 0)
						//Récupération de la liste des droits
						mapDroits.set({ libelle: item.libelle,isGroupe: true,hasChild: this.hasChild(mapSubDroits),order: item.order,typeGroupe: item.groupe },mapSubDroits);
				}
			});

		return mapDroits;
	}

	/**
	 * Vérification de la présence d'enfants
	 */
	private hasChild(mapSubDroits: Map<any,any>) {
		//Vérification de la présence d'enfants
		return mapSubDroits.size > 0 && (Array.from(mapSubDroits.keys()).some((key: { hasChild: boolean }) => key.hasChild) || Array.from(mapSubDroits.values()).some((droit: Droit) => droit?.idDroit > 0 || droit.consultation || droit.creation || droit.suppression));
	}
}
