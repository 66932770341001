import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeImputationListItemComponent } from './vehicule-imputation-list-item.component';

@Component({
	selector: 'vehicule-imputation-list',
	templateUrl: './vehicule-imputation-list.component.html'
})
export class VehiculeImputationListComponent extends PageContentItem implements OnInit {
	/** Véhicule courant **/
	@Input() vehicule: any;

	/** Fonction d'édition **/
	@Input() addImputation: () => void;

	/** Fonction de mise à jour du véhicule **/
	@Input() updateVehicule: (vehicule: any) => void;

	/** Résumé **/
	@Input() resume: { nbImputations: number };

	/** Premère imputation du véhicule **/
	protected firstImputation: any;

	/** Données **/
	liste: ListView<any,VehiculeImputationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeImputationListItemComponent>({
			uri: `/controller/Vehicule/filtreImputations`,
			component: VehiculeImputationListItemComponent,
			isLocal: true,
			extraOptions: {
				addImputation: this.addImputation,
				updateVehicule: this.updateVehicule,
				firstImputation: this.firstImputation
			},
			listeFilters: [{
				clef: 'service',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'service'
				}
			},{
				clef: 'societe',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateApplication',
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idImputation > 0)
			//Mise à jour des compteurs
			this.resume.nbImputations--;
	}
}