<div [class]="options?.customStyle?.elements?.header || 'card detail'" [hidden]="!getListeVisibleElements()?.length">
	<div [class]="options?.customStyle?.elements?.body || 'card-body'">
		<div *ngIf="!options.isFullScreen" [class]="options?.customStyle?.elements?.row || ''">
			<ul class="liste_onglets">
				<li *ngFor="let element of getListeVisibleElements()">
					<a [routerLink]="[]" *ngIf="!isTabHidden(element)" [ngClass]="{ 'current': element.type == selectedElement?.type }" (click)="element.type != selectedElement?.type && setSelectedElement(element)">
						<ng-container *ngIf="element.count"><span class="bold">{{ element.count() }}</span>&#160;</ng-container><span>{{ getLibelleForElement(element) }}</span>
					</a>
				</li>

				<div class="lv-actions card-actions actions" [ngClass]="{ 'dropdown': getListeVisibleElementsWithAction()?.length > 1 }" *ngIf="!isTabActionsHidden() && getListeVisibleElementsWithAction()?.length">
					<a [routerLink]="[]" [attr.data-toggle]="getListeVisibleElementsWithAction()?.length > 1 ? 'dropdown' : null" (click)="getListeVisibleElementsWithAction().length == 1 && getListeVisibleElementsWithAction()[0].doAction()"><icon [name]="options?.customStyle?.btnAction?.icone || 'add'" [type]="options?.customStyle?.btnAction?.iconeType"></icon></a>
					<ul class="dropdown-menu dropdown-menu-right">
						<li *ngFor="let element of getListeVisibleElementsWithAction()">
							<a [routerLink]="[]" (click)="element.doAction()"><span>{{ getLibelleActionForElement(element) }}</span></a>
						</li>
					</ul>
				</div>
			</ul>
			<div class="clearfix"></div>
		</div>

		<template #holder></template>
	</div>
</div>