import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep,isEqual } from 'lodash-es';
import { first } from 'rxjs/operators';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { SinistreService } from './sinistre.service';

@Component({
	selector: 'sinistre',
	templateUrl: './sinistre.component.html'
})
export class SinistreComponent implements OnInit,IEntity {
	/** Elément courant **/
	public sinistre: any;

	/** Récupération de l'entité portant les pièces jointes **/
	public getOwningEntity: () => LinksOwningEntity = () => this.sinistre;

	/** Type d'attachment **/
	public typeAttachment: TypeAttachment = TypeAttachment.SINISTRE;

	/** Copie du sinistre **/
	public savedSinistre: any;

	/**
	 * Constructeur
	 */
	constructor(private activatedRoute: ActivatedRoute,private sinistreService: SinistreService,private layoutService: LayoutService,private attachmentService: AttachmentService) {
		//Binding
		this.close = this.close.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.Sinistre';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.sinistre?.idSinistre || 0;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de l'identifiant du sinistre à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idSinistre);
			}
		});
	}

	/**
	 * Fermeture du formulaire
	 */
	close() {
		//Retour en arrière
		this.layoutService.goBack();
	}

	/**
	 * Chargement des données
	 */
	private loadData(idSinistre: number) {
		//Chargement du sinistre
		this.sinistreService.loadSinistre(idSinistre).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du sinistre
					this.sinistre = result.data?.sinistre;

					//Initialisation des pièces-jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du sinistre
					if (!this.sinistre?.idSinistre) {
						//Création d'un objet vierge
						this.sinistre = {
							statut: 'EN_COURS'
						}
					}

					//Copie du sinistre
					this.savedSinistre = cloneDeep(this.sinistre);
				}
			}
		});
	}

	/**
	 * Fermeture du sinistre après enregistrement
	 */
	onContentClose(sinistre: any) {
		//Vérification de la présence de données
		if (sinistre) {
			//Mise à jour de l'objet
			this.sinistre = Object.assign(this.sinistre,sinistre);

			//Rechargement de l'url
			this.layoutService.replaceUrlWith(this.sinistre);

			//Enregistrement du sinistre
			this.savedSinistre = cloneDeep(this.sinistre);
		} else {
			//Retour à la page précédente
			this.layoutService.goBack();
		}
	}

	/**
	 * Vérification de la modification de l'objet
	 */
	isDirty(): boolean {
		//Vérification de la modification de l'objet
		return !isEqual(this.savedSinistre,this.sinistre);
	}
}