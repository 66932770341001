<form #loiRoulageForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" [translate]="'contrat.financement.elements.'+(isLoiInitiale ? 'loiRoulage' : 'avenant')+'.singular'"></h2>
			</div>
			<div class="card-body card-padding">
				<div class="card">
					<fieldset [disabled]="!(null | right:'creation')">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': date.invalid }" *ngIf="contratFinancement.type.mode != 'ACQUISITION'">
									<label translate>contrat.financement.loiRoulage.date</label>
									<div class="half">
										<input type="text" name="date" class="form-control" #date="ngModel" [(ngModel)]="loiRoulage.date" required datePicker [storeDate]="true" [min]="contratFinancement.dateDebut" [disabled]="isLoiInitiale" [validator]="isDateValid()"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
									<label translate>contrat.financement.loiRoulage.reference</label>
									<div>
										<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="loiRoulage.reference" required [disabled]="isLoiInitiale" maxlength="100"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
									<label translate>contrat.financement.loiRoulage.duree</label>
									<div class="half">
										<input type="text" name="duree" class="form-control" #duree="ngModel" [(ngModel)]="loiRoulage.duree" required nNumber min="1" max="300" (change)="doCalculLoiRoulage()"/>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': distance.invalid }" *ngIf="contratFinancement.vehicule.uniteUsage == 'DISTANCE'">
									<label translate>contrat.financement.loiRoulage.distance</label>
									<div class="half">
										<div class="input-group">
											<input type="text" name="distance" class="form-control" #distance="ngModel" [(ngModel)]="loiRoulage.distance" required nNumber min="0" (change)="doCalculLoiRoulage()"/>
											<div class="input-group-addon no-border" *ngIf="contratFinancement.vehicule?.unite">
												<span>{{ contratFinancement.vehicule.unite.libelleCourt }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': temps.invalid }" *ngIf="contratFinancement.vehicule.uniteUsage == 'TEMPS'">
									<label translate>contrat.financement.loiRoulage.temps</label>
									<div class="half">
										<div class="input-group">
											<input type="text" name="temps" class="form-control" #temps="ngModel" [(ngModel)]="loiRoulage.temps" required nNumber min="0" (change)="doCalculLoiRoulage()"/>
											<div class="input-group-addon no-border">
												<span translate>duree.heure.libelle</span>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="contratFinancement.type.mode != 'ACQUISITION'">
									<div class="form-group" [ngClass]="{ 'has-error': typeAffichagePrix.invalid }">
										<label translate>contrat.financement.loiRoulage.typeAffichagePrix</label>
										<div>
											<selectpicker name="typeAffichagePrix" #typeAffichagePrix="ngModel" [(ngModel)]="loiRoulage.typeAffichagePrix" required>
												<mat-option *ngFor="let typeAffichagePrix of listeTypesAffichagePrix" [value]="typeAffichagePrix.code">{{ typeAffichagePrix.libelle }}</mat-option>
											</selectpicker>
										</div>
									</div>
									<div class="form-group" [ngClass]="{ 'has-error': loyerMensuel.invalid }">
										<label translate>contrat.financement.loiRoulage.loyerMensuel</label>
										<div class="half">
											<input type="text" name="loyerMensuel" class="form-control" #loyerMensuel="ngModel" [(ngModel)]="loiRoulage.loyerMensuel" required nNumber="2" min="0" (change)="doCalculLoiRoulage()"/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ng-container *ngIf="contratFinancement.type.mode != 'ACQUISITION'">
							<div class="form-group">
								<label translate>contrat.financement.detailLoyers</label>
								<div>
									<a [routerLink]="[]" (click)="loiRoulage.isDetailLoyerDisplayed = !loiRoulage.isDetailLoyerDisplayed">
										<span translate>contrat.financement.loiRoulage.actions.afficherDetailLoyers</span>
										<icon class="p-l-10" [name]="!loiRoulage.isDetailLoyerDisplayed ? 'chevron_right' : 'expand_more'"></icon>
									</a>
								</div>
							</div>

							<div [collapse]="!loiRoulage.isDetailLoyerDisplayed" [isAnimated]="true">
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerFinancier.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerFinancier</label>
											<div class="half">
												<input type="text" name="loyerFinancier" class="form-control" #loyerFinancier="ngModel" [(ngModel)]="loiRoulage.loyerFinancier" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerMaintenance.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerMaintenance</label>
											<div class="half">
												<input type="text" name="loyerMaintenance" class="form-control" #loyerMaintenance="ngModel" [(ngModel)]="loiRoulage.loyerMaintenance" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerAssistance.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerAssistance</label>
											<div class="half">
												<input type="text" name="loyerAssistance" class="form-control" #loyerAssistance="ngModel" [(ngModel)]="loiRoulage.loyerAssistance" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerGarantiePerteFinanciere.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerGarantiePerteFinanciere</label>
											<div class="half">
												<input type="text" name="loyerGarantiePerteFinanciere" class="form-control" #loyerGarantiePerteFinanciere="ngModel" [(ngModel)]="loiRoulage.loyerGarantiePerteFinanciere" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerGestionCarburant.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerGestionCarburant</label>
											<div class="half">
												<input type="text" name="loyerGestionCarburant" class="form-control" #loyerGestionCarburant="ngModel" [(ngModel)]="loiRoulage.loyerGestionCarburant" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerGestionSinistres.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerGestionSinistres</label>
											<div class="half">
												<input type="text" name="loyerGestionSinistres" class="form-control" #loyerGestionSinistres="ngModel" [(ngModel)]="loiRoulage.loyerGestionSinistres" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerTelematique.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerTelematique</label>
											<div class="half">
												<input type="text" name="loyerTelematique" class="form-control" #loyerTelematique="ngModel" [(ngModel)]="loiRoulage.loyerTelematique" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerPneumatiques.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerPneumatiques</label>
											<div class="half">
												<input type="text" name="loyerPneumatiques" class="form-control" #loyerPneumatiques="ngModel" [(ngModel)]="loiRoulage.loyerPneumatiques" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerVehiculeRemplacement.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerVehiculeRemplacement</label>
											<div class="half">
												<input type="text" name="loyerVehiculeRemplacement" class="form-control" #loyerVehiculeRemplacement="ngModel" [(ngModel)]="loiRoulage.loyerVehiculeRemplacement" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerAssurance.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerAssurance</label>
											<div class="half">
												<input type="text" name="loyerAssurance" class="form-control" #loyerAssurance="ngModel" [(ngModel)]="loiRoulage.loyerAssurance" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" [ngClass]="{ 'has-error': loyerAutres.invalid }">
											<label translate>contrat.financement.loiRoulage.loyerAutres</label>
											<div class="half">
												<input type="text" name="loyerAutres" class="form-control" #loyerAutres="ngModel" [(ngModel)]="loiRoulage.loyerAutres" nNumber="2" min="0"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

						<div class="row">
							<div class="col-md-6">
								<ng-container *ngIf="contratFinancement.type.mode != 'ACQUISITION'">
									<div class="form-group" *ngIf="contratFinancement.vehicule.uniteUsage == 'DISTANCE'">
										<label translate>contrat.financement.loiRoulage.prixRevientKilometrique</label>
										<div>
											<span>{{ loiRoulage.coutDistance | number:'.3-3' }}</span>
										</div>
									</div>
									<div class="form-group" *ngIf="contratFinancement.vehicule.uniteUsage == 'TEMPS'">
										<label translate>contrat.financement.loiRoulage.prixRevientHoraire</label>
										<div>
											<span>{{ loiRoulage.coutHoraire | number:'.3-3' }}</span>
										</div>
									</div>
								</ng-container>
								<div class="form-group">
									<label translate>contrat.financement.loiRoulage.piecesJointes</label>
									<div>
										<div [attachment]="loiRoulage" [typeAttachment]="TypeAttachment.LOI_ROULAGE" [parentOwningEntity]="contratFinancement"></div>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</div>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteLoiRoulage(loiRoulage,close);" *ngIf="loiRoulage.idLoiRoulage && (!isLoiInitiale || contratFinancement.listeLoisRoulage?.length == 1) && (null | right:'creation')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="loiRoulageForm.invalid" (click)="saveLoiRoulage();" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>