<form #paysForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!pays.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>pays.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="pays.libelle" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': code2.invalid }">
							<label translate>pays.codeISO2</label>
							<div>
								<input type="text" class="form-control" name="code2" #code2="ngModel" [ngModel]="pays.code2" (ngModelChange)="pays.code2 = $event.toUpperCase()" maxlength="2" minlength="2" pattern="[A-Z]*" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': code3.invalid }">
							<label translate>pays.codeISO3</label>
							<div>
								<input type="text" class="form-control" name="code3" #code3="ngModel" [ngModel]="pays.code3" (ngModelChange)="pays.code3 = $event.toUpperCase()" maxlength="3" minlength="3" pattern="[A-Z]*" required/>
							</div>
						</div>
						<div class="form-group">
							<label translate>pays.listeScopes</label>
							<div>
								<a [routerLink]="[]" (click)="showListeScopes()">{{ getListeScopes() }}</a>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: paysForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deletePays();" *ngIf="pays.idPays && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="savePays();" *ngIf="(null | right:'creation')" [disabled]="paysForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>