import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeEntretienListItemComponent } from './type-entretien-list-item.component';

@Component({
	templateUrl: './type-entretien-list.component.html'
})
export class TypeEntretienListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TypeEntretienListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TypeEntretienListItemComponent>({
			uri: () => `/controller/VehiculeReferentiel/filtreTypesEntretien`,
			title: this.translateService.instant('vehicule.typeEntretien.liste.title'),
			component: TypeEntretienListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: 'libelle,reference',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idTypeEntretien) && this.liste.addItem({
					isDisplayed: true,
					actif: true
				});
			}
		});
	}
}