import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { cloneDeep,differenceBy,isEqual } from 'lodash-es';

import { FormCustomizationField } from 'src/app/domain/common/entite/form-customization';
import { BusinessDataService } from '../business-data/business-data.service';
import { EntiteService } from '../entite.service';

@Component({
	selector: 'entite-field',
	templateUrl: './entite-field.component.html'
})
export class EntiteFieldComponent implements OnInit {
	/** Liste des champs */
	@Input() listeFields: Array<FormCustomizationField> = [];

	/** Nom de la classe **/
	@Input() className: string;

	/** Données **/
	@Input() data?: any;

	/** Identifiant de l'objet **/
	@Input() idObject?: number;

	/** Indicateur de modification possible **/
	@Input() isUpdateAvailable?: boolean;

	/** Gestion de la fermeture **/
	@Output() onClose: EventEmitter<Array<FormCustomizationField>> = new EventEmitter();

	/** Copie de la liste des champs */
	private listeFieldsCopy: Array<FormCustomizationField> = [];

	/**
	 * Constructeur
	 */
	constructor(public entiteService: EntiteService,private businessDataService: BusinessDataService) {
		//Binding
		this.close = this.close.bind(this);
		this.doBusinessDataSelection = this.doBusinessDataSelection.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Copie de la liste des champs
		this.listeFields = cloneDeep(this.listeFields);
		this.listeFieldsCopy = cloneDeep(this.listeFields);

		//Vérification du nombre de champs
		if (!this.listeFields.length)
			//Affichage de la popup d'ajout de champs
			this.showBusinessData();
	}

	/**
	 * Déplacement d'un champ dans la liste
	 */
	moveField(index: number,incIndex: number) {
		let nextItem;

		//Récupération de l'élément suivant
		nextItem = this.listeFields[index + incIndex];

		//Echange avec l'élément suivant
		this.listeFields[index + incIndex] = this.listeFields[index];
		this.listeFields[index] = nextItem;

		//Rafraichissement du composant de tri
		this.listeFields = [...this.listeFields];
	}

	/**
	 * Suppression d'un champ de la liste
	 */
	deleteField(index: number) {
		//Suppression du champ de la liste
		this.listeFields.splice(index,1);

		//Rafraichissement du composant de tri
		this.listeFields = [...this.listeFields];
	}

	/**
	 * Affichage du catalogue de champs
	 */
	showBusinessData() {
		//Affichage de la popup des données métier
		this.businessDataService.showBusinessData(this.className,{
			typeContext: 'DATAFETCH',
			doBusinessDataSelection: this.doBusinessDataSelection,
			isPreviewData: true
		}).subscribe({
			next: result => {
				let listeNewFields: Array<FormCustomizationField>;
				let listeFields;
				let listeRemovedFields;

				//Vérification du résultat
				if (result) {
					//Vérification de la présence de la liste des entités sélectionnées
					if (Array.isArray(result.listeEntities)) {
						//Récupération des nouveaux champs
						listeNewFields = result.listeEntities.map(entity => ({
							name: entity.path,
							type: entity.type,
							code: entity.code,
							libelle: entity.pathLibelle,
							collection: entity.collection
						}));

						//Récupération de la liste des champs supprimés
						listeRemovedFields = result.listeRemovedEntities?.map?.(e => e.path) || [];

						//Vérification de la liste des champs supprimés
						if (listeRemovedFields.length)
							//Suppression des champs déselectionnés
							this.listeFields = this.listeFields.filter(f => !listeRemovedFields.some(name => name == f.name));

						//Récupération des colonnes sélectionnées avec conservation de l'ordre (ajout des nouvelles colonnes en fin de liste)
						listeFields = this.listeFields.concat(differenceBy(listeNewFields,this.listeFields,'name'));

						//Numérotation des positions des colonnes
						listeFields.forEach((field,idxField) => {
							//Définition de la position
							field.position = idxField;
						});

						//Rafraichissement du composant de tri
						this.listeFields = [...listeFields];
					}
				}
			}
		});
	}

	/**
	 * Déselection des données métier
	 */
	private doBusinessDataSelection(businessData: any,path: string) {
		let currentPath: string;

		//Parcours des données de l'entité
		businessData.listeMembres?.forEach?.(item => {
			//Définition du chemin
			currentPath = path + (!item.operation ? '.' : '') + item.code;

			//Vérification de la présence d'un wrapper
			if (item.enclosedWrapper) {
				//Parcours récursif
				this.doBusinessDataSelection(item.enclosedWrapper,currentPath);
			} else {
				//Définition de la sélection
				item.selected = this.listeFields?.some?.(f => f.name == currentPath);
			}
		});
	}

	/**
	 * Enregistrement de la liste des champs
	 */
	save() {
		//Itération sur la liste des champs
		this.listeFields.forEach((field: FormCustomizationField,index: number) => {
			//Définition de la position
			field.position = index + 1;
		})

		//Emission de la liste des champs
		this.onClose?.emit(this.listeFields);
	}

	/**
	 * Fermeture du composant
	 */
	close() {
		//Fermeture de la fenêtre
		this.onClose?.emit(null);
	}

	/**
	 * Vérification de l'état du formulaire
	 */
	isDirty() {
		//Vérification de l'état de la liste des champs
		return !isEqual(this.listeFields,this.listeFieldsCopy);
	}
}
