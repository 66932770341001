import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ImmatriculationService } from './immatriculation/immatriculation.service';

@Component({
	templateUrl: './vehicule-immatriculation-list-item.component.html'
})
export class VehiculeImmatriculationListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private immatriculationService: ImmatriculationService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de l'immatriculation
	 */
	showImmatriculation() {
		//Affichage de l'immatriculation
		this.immatriculationService.showImmatriculation({
			immatriculation: this.data,
			options: {
				isConsultationOnly: true
			}
		});
	}
}