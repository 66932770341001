import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DataCustomizationService } from './data-customization.service';

@Component({
	templateUrl: './data-customization-field-selection.component.html'
})
export class DataCustomizationFieldSelectionComponent implements OnInit {
	/** Liste des l'ensemble des champs disponibles **/
	listeAllFields: Array<any>;

	/** Paramétrage des champs personnalisés **/
	dataCustomization: any;

	/** Liste des champs sélectionnés **/
	listeFields: Array<any>;

	/** Mise à jour de la liste des champs sélectionnés **/
	savedListeFields: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DataCustomizationFieldSelectionComponent>,private dataCustomizationService: DataCustomizationService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des champs disponibles
		this.listeAllFields = this.dataCustomizationService.getListeFields(this.dataCustomization,true);

		//Parcours des champs
		this.listeAllFields.forEach(field => {
			//Définition de la sélection du champ
			field.selected = this.listeFields.some(f => f.name == field.name);
		});

		//Mise à jour des libellés
		this.dataCustomizationService.updateListeFields(this.dataCustomization.entite,this.listeAllFields);
	}

	/**
	 * Enregistrement des champs personnalisés
	 */
	saveSelection() {
		//Définition de la liste des champs sélectionnés
		this.savedListeFields = this.listeAllFields.filter(f => f.selected);

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}