import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisplayPipe } from './display.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [DisplayPipe],
	exports: [DisplayPipe],
	providers: [DisplayPipe]
})
export class DisplayModule { }