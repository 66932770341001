import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PlanComptableService } from './plan-comptable.service';

@Component({
	templateUrl: './plan-comptable-list-item.component.html'
})
export class PlanComptableListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private planComptableService: PlanComptableService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression du plan comptable
	 */
	deletePlanComptable() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du plan comptable
					this.planComptableService.deletePlanComptable(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du plan comptable
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Duplication du plan comptable
	 */
	duplicatePlanComptable() {
		//Navigation vers le formulaire pré-rempli
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: 0
			},
			routeData: {
				idPlanComptableSource: this.data.idPlanComptable
			}
		})
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.comptabilite.PlanComptable',this.data.idPlanComptable);
	}

	/**
	 * Récupération de la liste des sociétés
	 */
	getListeSocietes() {
		//Retour des libellés des sociétés
		return this.data?.listeSocietes?.map?.(societe => societe.libelle)?.join?.(', ') || '';
	}
}