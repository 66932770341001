import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ConnecteurService as SharedConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { ConnecteurService } from './connecteur.service';

@Component({
	selector: 'connecteur-list-item',
	templateUrl: './connecteur-list-item.component.html'
})
export class ConnecteurListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private sharedConnecteurService: SharedConnecteurService,private connecteurService: ConnecteurService) {
		//Héritage
		super();
	}

	/**
	 * Authentification sur le connecteur sélectionné
	 */
	login() {
		//Tentative de connexion sur le connecteur
		this.sharedConnecteurService.login(this.data).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Message d'information
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.connexion.success'),{ isCancelDisabled: true,actionColor: 'primary' });
			else
				//Message d'erreur
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.connexion.error'),{ isCancelDisabled: true,actionColor: 'primary' });
		});
	}

	/**
	 * Exécution du connecteur sélectionné
	 */
	execute() {
		//Vérification de la présence de paramètres facultatifs
		if (this.data.type.listeParams.some(p => p.optional)) {
			//Afficahge des paramètres optionnels du connecteur
			this.connecteurService.showParams(this.data,{
				isOnlyOptionals: true,
				buttonLabel: 'actions.executer'
			}).pipe(filter(listeParams => !!listeParams?.length)).subscribe({
				next: listeParams => {
					//Exécution du connecteur avec les paramètres optionnels
					this.executeConnecteur(listeParams);
				}
			});
		} else {
			//Exécution directe du connecteur
			this.executeConnecteur();
		}
	}

	/**
	 * Exécution du connecteur
	 */
	executeConnecteur(listeOptionalParams?: Array<any>) {
		//Exécution du connecteur
		this.sharedConnecteurService.execute(Object.assign(this.data,{ listeOptionalParams })).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Message d'information
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.execution.success'),{ isCancelDisabled: true,actionColor: 'primary' });
			else
				//Message d'erreur
				this.confirmService.showConfirm(this.translateService.instant('connecteur.actions.execution.error'),{ isCancelDisabled: true,actionColor: 'primary' });
		});
	}

	/**
	 * Suppression de l'objet
	 */
	deleteConnecteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.connecteurService.deleteConnecteur(this.data.idConnecteur))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.connecteur.Connecteur',this.data.idConnecteur);
	}
}
