import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DetailModule } from './detail/detail.module';
import { FactureAnalysisAssociationComponent } from './facture-analysis-association.component';
import { FactureAnalysisComponent } from './facture-analysis.component';
import { FactureAttachmentsComponent } from './facture-attachments.component';
import { FactureDetailEditComponent } from './facture-detail-edit.component';
import { FactureDetailListItemComponent } from './facture-detail-list-item.component';
import { FactureDetailListComponent } from './facture-detail-list.component';
import { FactureEditComponent } from './facture-edit.component';
import { FactureListItemComponent } from './facture-list-item.component';
import { FactureListComponent } from './facture-list.component';
import { FactureComponent } from './facture.component';
import { FactureService } from './facture.service';
import { TaxeModule } from './taxe/taxe.module';
import { TaxeService } from './taxe/taxe.service';
import { FactureSelectionSocieteComponent } from './facture-selection-societe.component';

@NgModule({
	imports: [ShareModule,DetailModule,TaxeModule],
	declarations: [FactureListComponent,FactureListItemComponent,FactureAttachmentsComponent,FactureAnalysisComponent,FactureAnalysisAssociationComponent,FactureComponent,FactureEditComponent,FactureDetailListComponent,FactureDetailListItemComponent,FactureDetailEditComponent,FactureSelectionSocieteComponent],
	exports: [DetailModule],
	providers: [FactureService,TaxeService]
})
export class FactureModule {}