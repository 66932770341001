import { Component,Input } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';

import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { NotificationAddDestinataireComponent } from './notification-add-destinataire.component';
import { NotificationDestinataireCheckComponent } from './notification-destinataire-check.component';
import { NotificationService } from './notification.service';

@Component({
	templateUrl: './notification-destinataires.component.html'
})
export class NotificationDestinatairesComponent {
	/** Template sélectionné **/
	@Input() selectedMail: any;

	/** Liste des destinataires **/
	@Input() listeDestinataires: Array<any>;

	/** Action de masse **/
	@Input() actionMasse: ActionMasse;

	/** Options de messaging **/
	@Input() messagingOptions: MessagingOptions;

	/** Mise à jour de la liste des destinataires **/
	onListeDestinatairesChange: (listeDestinataires: Array<any>) => void;

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private notificationService: NotificationService) {

	}

	/**
	 * Ajout d'un destinataire
	 */
	addNewDestinataire(type: string) {
		let bsModalRef: BsModalRef<NotificationAddDestinataireComponent>;

		//Affichage de la popup d'ajout de destinataire
		bsModalRef = this.bsModalService.show(NotificationAddDestinataireComponent,{
			initialState: {
				type
			}
		});

		//Interception de la fermeture de la popup
		bsModalRef.onHidden.pipe(filter(() => bsModalRef.content.destinataire)).subscribe({
			next: () => {
				//Ajout du destinataire
				this.listeDestinataires.push(bsModalRef.content.destinataire);

				//Mise à jour de la liste
				this.onListeDestinatairesChange(this.listeDestinataires);
			}
		});
	}

	/**
	 * Vérification des destinataires
	 */
	public checkDestinataires() {
		//Vérification des destinataires
		this.notificationService.checkDestinataires(this.selectedMail,this.listeDestinataires,this.actionMasse,this.messagingOptions).subscribe({
			next: listeDestinataires => this.listeDestinataires = listeDestinataires
		});
	}

	/**
	 * Affichage du détail de la vérification pour un destinataire
	 */
	showDestinataireCheckDetail(destinataire: any) {
		//Affichage de la popup
		this.bsModalService.show(NotificationDestinataireCheckComponent,{
			initialState: {
				destinataire
			}
		});
	}
}