import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,take } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ExtractionEditComponent } from './extraction-edit.component';
import { ExtractionFieldEditComponent } from './extraction-field-edit.component';
import { ExtractionFieldListComponent } from './extraction-field-list.component';
import { ExtractionRuleComponent } from './extraction-rule.component';
import { ExtractionDiffusionListComponent } from './extraction-diffusion-list.component';
import { ExtractionDiffusionEditComponent } from './extraction-diffusion-edit.component';
import { ExtractionEnteteListComponent } from './extraction-entete-list.component';

@Component({
	selector: 'extraction',
	templateUrl: './extraction.component.html'
})
export class ExtractionComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Extraction courante **/
	public extraction: any;

	/** Informations de l'entité **/
	public entiteInformations: any;

	/** Type de référentiel **/
	public typeReferentiel: TypeReferentiel;

	/** Résumé */
	private resume: { nbFields: number,nbFiltres: number,nbDiffusions: number } = {
		nbFields: 0,
		nbFiltres: 0,
		nbDiffusions: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private extractionService: ExtractionService,private referentielService: ReferentielService,private toastrService: ToastrService,private pageContentService: PageContentService
			,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private entiteService: EntiteService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.extraction.Extraction';

	/**
	 * Récupération de l'identifiant de l'extraction à charger
	 */
	getIdObject: () => number = () => this.extraction?.idExtraction || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'extraction.libelle',
				key: 'libelle'
			},{
				libelle: 'extraction.reference',
				key: 'reference'
			},{
				libelle: 'extraction.entite',
				key: data => this.entiteService.translateEntityCode(data.entity?.split('.').pop())
			},{
				libelle: 'extraction.typeFormat.item',
				key: data => this.translateService.instant(`extraction.typeFormat.${data.typeFormat}`)
			},{
				libelle: 'extraction.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.executer',
				doAction: () => this.executeExtraction(),
				isVisible: () => this.resume.nbFields > 0 && this.rightService.hasRight(null,'creation')
			},{
				libelle: 'actions.modifier',
				doAction: () => this.editExtraction(),
				isVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editExtraction(),
				isVisible: () => !this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot()),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicateExtraction(),
				isVisible: () => this.resume.nbFields > 0 && this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())
			},{
				libelle: 'actions.importer',
				doAction: () => this.importExtraction(),
				isVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.STANDARD && !this.rightService.isRoot())
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteExtraction(),
				isVisible: () => this.rightService.hasRight(null,'suppression') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())
			}],
			listeAlertes: [{
				icon: 'view_column',
				title: this.translateService.instant('extraction.alerte.field.title'),
				message: this.translateService.instant('extraction.alerte.field.message'),
				isVisible: () => !this.extraction?.listeFields?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addField(),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())
			},{
				icon: 'filter_list',
				title: this.translateService.instant('extraction.alerte.filtre.title'),
				message: this.translateService.instant('extraction.alerte.filtre.message'),
				isVisible: () => this.extraction?.listeFields?.length && !this.extraction?.rule?.listeDetails?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addRule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel == TypeReferentiel.LOCAL || this.rightService.isRoot())
			}],
			listeElements: [{
				type: 'FIELD',
				libelle: 'extraction.elements.field',
				component: ExtractionFieldListComponent,
				retrieveComponentData: () => ({
					extraction: this.extraction,
					typeReferentiel: this.typeReferentiel,
					addField: this.addField.bind(this)
				}),
				doAction: () => this.addField(),
				count: () => this.resume.nbFields,
				libelleAction: this.translateService.instant('extraction.actions.ajouterField'),
				isVisible: () => this.resume.nbFiltres > 0 || this.resume.nbFields > 0,
				isActionVisible: () => this.resume.nbFields > 0 && this.rightService.hasRight(null,'creation') && (this.typeReferentiel != TypeReferentiel.STANDARD || this.rightService.isRoot()) && (this.extraction.custom || !this.extraction.extractionOrigine)
			},{
				type: 'FILTRE',
				libelle: 'extraction.elements.filtre',
				component: ExtractionRuleComponent,
				retrieveComponentData: () => ({
					getExtraction: () => this.extraction,
					getRule: () => this.extraction.rule
				}),
				doAction: () => this.addRule(),
				count: () => this.resume.nbFiltres,
				isVisible: () => this.resume.nbFiltres > 0 || this.resume.nbFields > 0,
				libelleAction: this.translateService.instant('extraction.actions.ajouterFiltre'),
				isActionVisible: () => this.resume.nbFields > 0 && this.rightService.hasRight(null,'creation') && (this.typeReferentiel != TypeReferentiel.STANDARD || this.rightService.isRoot()) && (this.extraction.custom || !this.extraction.extractionOrigine)
			},{
				type: 'DIFFUSION',
				libelle: 'extraction.elements.diffusion',
				component: ExtractionDiffusionListComponent,
				retrieveComponentData: () => ({
					extraction: this.extraction,
					addUserScheduler: this.addUserScheduler.bind(this),
					resume: this.resume
				}),
				doAction: () => this.addUserScheduler(),
				count: () => this.resume.nbDiffusions,
				libelleAction: this.translateService.instant('extraction.actions.ajouterDiffusion'),
				isVisible: () => this.resume.nbFiltres > 0 || this.resume.nbFields > 0,
				isActionVisible: () => this.resume.nbFields > 0 && this.rightService.hasRight(null,'creation') && (this.typeReferentiel != TypeReferentiel.STANDARD || this.rightService.isRoot()) && (this.extraction.custom || !this.extraction.extractionOrigine)
			},{
				type: 'ENTETE',
				libelle: 'extraction.elements.entete',
				component: ExtractionEnteteListComponent,
				retrieveComponentData: () => ({
					extraction: this.extraction,
					typeReferentiel: this.typeReferentiel
				}),
				doAction: () => this.editEntetes(),
				libelleAction: this.translateService.instant('extraction.actions.editEntetes'),
				isVisible: () => this.resume.nbFields > 0,
				isActionVisible: () => this.rightService.hasRight(null,'creation') && (this.typeReferentiel != TypeReferentiel.STANDARD || this.rightService.isRoot()) && (this.extraction.custom || !this.extraction.extractionOrigine)
			}]
		};

		//Récupération de l'identifiant de l'extraction à charger
		this.activatedRoute.params.pipe(take(1)).subscribe({
			next: params => {
				//Définition du type de référentiel
				this.typeReferentiel = params.typeReferentiel;

				//Chargement des données
				this.loadData(params.idExtraction);
			}
		});
	}

	/**
	 * Duplication de l'extraction
	 */
	private duplicateExtraction() {
		//Duplication de l'extraction
		this.extractionService.duplicateExtraction(this.extraction).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers l'extraction créée
				this.layoutService.replaceUrlWith({
					typeReferentiel: this.typeReferentiel,
					idExtraction: result.data.extraction.idExtraction
				},true);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Exécution de l'extraction
	 */
	private executeExtraction() {
		//Exécution de l'extraction
		this.extractionService.executeExtraction(this.extraction);
	}

	/**
	 * Edition de l'extraction
	 */
	private editExtraction() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ExtractionEditComponent,{
			data: {
				extraction: cloneDeep(this.extraction),
				typeReferentiel: this.typeReferentiel,
				deleteExtraction: this.deleteExtraction.bind(this)
			}
		}).subscribe({
			next: (extraction: any) => {
				//Vérification des données
				if (extraction) {
					//Mise à jour de l'extraction
					Object.assign(this.extraction,extraction);

					//Définition du résumé
					Object.assign(this.resume,{
						nbFields: this.extraction?.listeFields?.length || 0,
						nbFiltres: this.extraction?.rule?.listeDetails?.length || 0
					});

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.extraction);
				}
			}
		});
	}

	/**
	 * Suppression de l'extraction
	 */
	private deleteExtraction() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.extractionService.deleteExtraction(this.typeReferentiel,this.extraction))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Import de l'extraction
	 */
	private importExtraction() {
		//Copie de l'entité en local
		this.referentielService.copyEntiteLocal(this.extraction.idExtraction,this.getClassName()).subscribe({
			next: (result: Result) => {
				let listeDoublons;

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.import.success'));

					//Rechargement de la page
					this.layoutService.replaceUrlWith({ typeReferentiel: TypeReferentiel.LOCAL,idExtraction: result.data.data.idExtraction },true);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Création de la liste
					listeDoublons = [];

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));


					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.import.error'));
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idExtraction: number) {
		//Enregistrement de l'extraction
		this.extractionService.loadExtraction(idExtraction).subscribe({
			next: result => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'extraction
					this.extraction = result.data?.extraction;

					//Définition des informations de l'entité
					this.entiteInformations = result.data?.entite;

					//Définition du résumé
					Object.assign(this.resume,{
						nbFields: this.extraction?.listeFields?.length || 0,
						nbFiltres: this.extraction?.rule?.listeDetails?.length || 0,
						nbDiffusions: result.data?.nbDiffusions || 0
					});

					//Vérification de l'identifiant de l'extraction
					if (!this.extraction?.idExtraction) {
						//Création d'un objet vierge
						this.extraction = {
							actif: false,
							visibilite: 'PUBLIC',
							avancee: false,
							typeFormat: 'XLSX'
						};

						//Edition de l'extraction
						this.editExtraction();
					}
				}
			}
		});
	}

	/**
	 * Modification de la liste des champs
	 */
	private addField() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ExtractionFieldEditComponent,{
			data: {
				extraction: cloneDeep(this.extraction)
			}
		},'sub').subscribe({
			next: (extraction: any) => {
				//Vérification de la présence de donnée
				if (extraction) {
					//Mise à jour de l'extraction
					this.extraction = Object.assign(this.extraction,extraction);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FIELD');

					//Définition du résumé
					Object.assign(this.resume,{
						nbFields: this.extraction?.listeFields?.length || 0,
						nbFiltres: this.extraction?.rule?.listeDetails?.length || 0
					});
				}
			}
		});
	}

	/**
	 * Ajout d'une règle
	 */
	private addRule() {
		//Affichage du détail
		this.pageContentService.open(ExtractionRuleComponent,{
			data: {
				getExtraction: () => this.extraction,
				getRule: () => this.extraction.rule && cloneDeep(this.extraction.rule) || {},
				isEdition: true
			}
		},'sub').subscribe({
			next: (extraction) => {
				//Vérification de l'extraction
				if (extraction) {
					//Mise à jour de l'extraction
					Object.assign(this.extraction,extraction);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FILTRE',true);

					//Définition du résumé
					Object.assign(this.resume,{
						nbFields: this.extraction?.listeFields?.length || 0,
						nbFiltres: this.extraction?.rule?.listeDetails?.length || 0
					});
				}
			}
		});
	}

	/**
	 * Ajout/modification d'une diffusion
	 */
	private addUserScheduler(userScheduler: any = { typeNotification: 'USER' },deleteUserScheduler?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ExtractionDiffusionEditComponent,{
			data: {
				extraction: this.extraction,
				userScheduler: cloneDeep(userScheduler),
				entiteInformations: this.entiteInformations,
				deleteUserScheduler
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('DIFFUSION',data.idUserScheduler != userScheduler.idUserScheduler);

					//Vérification de la présence d'une création ou d'une suppression
					if (!userScheduler.idUserScheduler && data)
						//Incrémentation du compteur
						this.resume.nbDiffusions++;
					else if (userScheduler.idUserScheduler && !data.idUserScheduler)
						//Décrémentation du compteur
						this.resume.nbDiffusions--;

					//Mise à jour de la valeur
					Object.assign(userScheduler,data);
				}
			}
		});
	}

	/**
	 * Edition des entêtes
	 */
	private editEntetes() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ExtractionEnteteListComponent,{
			data: {
				extraction: cloneDeep(this.extraction),
				typeReferentiel: this.typeReferentiel,
				isEdition: true
			}
		},'sub').subscribe({
			next: (extraction: any) => {
				//Vérification de la présence de données
				if (extraction) {
					//Mise à jour de l'extraction
					Object.assign(this.extraction,extraction);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('ENTETE',true);
				}
			}
		});
	}
}