import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions,ofType,createEffect } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter,map,take,tap,withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SESSION_FULFILLED,SESSION_LOGOUT_TO_ORIGINAL_USER,UPDATE_SAVED_PATH } from 'src/app/reducers/session';
import { Action } from 'src/app/domain/action';
import { Session } from 'src/app/domain/security/session';
import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Injectable()
export class LoginEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private http: HttpClient,private router: Router,private store: Store<AppState>,private layoutService: LayoutService) {

	}

	/**
	 * Mise à jour de la session
	 */
	onSessionFulfilled$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(SESSION_FULFILLED),
		filter((action: Action) => !(action.payload as Session)?.isRefresh),
		withLatestFrom(this.store.select(s => ({ codeTenant: s.session.codeTenant,originalCodeTenant: s.session.originalCodeTenant,savedPath: s.session.savedPath }))),
		map(([action,{ codeTenant,originalCodeTenant,savedPath }]) => {
			let session: Session;

			//Lecture de la session
			session = action.payload as Session;

			//Vérification de la présence d'une route
			if (session.route) {
				//Navigation vers la route
				this.layoutService.goToByState(session.route,{
					routeParams: session.queryParams,
					reloadOnSameUrl: true
				});
			} else
				//Redirection vers le dashboard ou l'écran de démarrage
				this.router.navigateByUrl(session.isLogged ? savedPath || '/Dashboard' : `/Login${originalCodeTenant || codeTenant ? '/' + (originalCodeTenant || codeTenant) : ''}`);

			//Suppression de la route mémorisée
			this.store.dispatch({
				type: UPDATE_SAVED_PATH,
				payload: {
					savedPath: null
				}
			});

			//Retour de la connexion
			return action;
		})
	),{ dispatch: false });

	/**
	 * Reconnexion de la session avec l'utilisateur d'origine
	 */
	onSessionLogoutToOriginUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(SESSION_LOGOUT_TO_ORIGINAL_USER),
		map(action => {
			//Requête de déconnexion de l'administrateur du tenant
			this.http.post<Result>('controller/Login/logoutToOriginalUser',null,{
				observe: 'response'
			}).pipe(
				take(1),
				filter(response => response?.body?.codeErreur == TypeCodeErreur.NO_ERROR && response?.body?.data?.user),
				tap(response => {
					//Propagation de la connexion en tant que
					this.store.dispatch({
						type: SESSION_FULFILLED,
						payload: {
							isLogged: true,
							xAuthToken: response.headers.get('X-AUTH-TOKEN'),
							user: response.body?.data?.user,
							userOrigine: null,
							route: 'dashboard'
						}
					});
				})
			).subscribe();

			return action;
		})
	),{ dispatch: false});
}