import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject } from 'rxjs';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { environment } from 'src/environments/environment';
import { DemandeCotationOptionPayanteListComponent } from './demande-cotation-option-payante-list.component';

@Injectable()
export class DemandeCotationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private bsModalService: BsModalService) {}

	/**
	 * Récupération des statuts des demandes de cotation
	 */
	public getMapStatuts(): { [statut: string]: { icone: string,iconeType?: string } } {
		//Retour des statuts
		return {
			'NOUVELLE_DEMANDE': {
				icone: 'question_mark'
			},
			'A_COMPLETER': {
				icone: 'attach_money'
			},
			'A_TRANSMETTRE': {
				icone: 'edit'
			},
			'EMISE_FOURNISSEUR': {
				icone: 'send'
			},
			'RETOUR_PARTIEL': {
				icone: 'notifications_none'
			},
			'RETOUR_COMPLET': {
				icone: 'notifications'
			},
			'SOLDEE': {
				icone: 'check'
			},
			'ANNULEE': {
				icone: 'notifications_off'
			}
		}
	}

	/**
	 * Enregistrement d'une demande de cotation
	 */
	public saveDemandeCotation(demandeCotation: any): Observable<Result> {
		//Enregistrement d'une demande de cotation
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveDemandeCotation`,demandeCotation);
	}

	/**
	 * Récupération de la liste des contacts en chaîne de caractères
	 */
	public getListeContactsAsString(listeContacts: Array<any>): string {
		//Retour de la liste
		return listeContacts?.map(contact => `${contact.prenom} ${contact.nom} (${contact.fournisseur.libelle})`).join(', ');
	}

	/**
	 * Suppression de la demande de cotation
	 */
	public deleteDemandeCotation(demandeCotation: any): Observable<Result> {
		//Suppression de la demande de cotation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeCommande/deleteDemandeCotation/${demandeCotation.idDemandeCotation}`);
	}

	/**
	 * Réalisation d'une action sur la demande de cotation
	 */
	public executeActionForDemande(action: 'TRANSMETTRE' | 'RELANCER' | 'RAPPELER' | 'ANNULER',demandeCotation: any): Observable<Result> {
		//Réalisation d'une action sur la demande de cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/executeActionForDemande/${action}/${demandeCotation.idDemandeCotation}`,null);
	}

	/**
	 * Chargement de la demande de cotation
	 */
	public loadDemandeCotation(idDemandeCotation: number): Observable<Result> {
		//Chargement de la demande de cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/loadDemandeCotation/${idDemandeCotation}`,null);
	}

	/**
	 * Chargement du résumé de la demande
	 */
	public loadResumeForDemande(idDemandeCotation: number): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/loadResumeForDemande/${idDemandeCotation}`,null);
	}

	/**
	 * Réalisation de l'action
	 */
	public doAction(action: 'TRANSMETTRE' | 'RELANCER' | 'RAPPELER' | 'ANNULER',demandeCotation: any): Observable<Result> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.confirmation`,{
			listeContacts: this.getListeContactsAsString(demandeCotation.cotation?.listeContacts)
		}),{ actionColor: 'primary' }).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Réalisation de l'action sur la demande de cotation
					this.executeActionForDemande(action,demandeCotation).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.success`));

								//Définition du résultat
								subject.next(result);

								//Finalisation du traitement
								subject.complete();
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('demandeCotation.actions.error'));

								//Retour de l'erreur
								subject.error(result);

								//Finalisation du traitement
								subject.complete();
							}
						}
					});
				} else {
					//Finalisation du traitement
					subject.complete();
				}
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Affichage de la liste des options payantes
	 */
	public showListeOptionsPayantes(configuration: any,listeOptionsPayantes: Array<any>) {
		let bsModalRef: BsModalRef<DemandeCotationOptionPayanteListComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(DemandeCotationOptionPayanteListComponent,{
			initialState: {
				listeOptionsPayantes,
				configuration
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
}