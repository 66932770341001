import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subject,combineLatest,of } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AffectationService } from '../affectation/affectation.service';
import { ConducteurAffectationEditComponent } from './conducteur-affectation-edit.component';
import { ConducteurAffectationListComponent } from './conducteur-affectation-list.component';
import { ConducteurAttestationPermisListComponent } from './conducteur-attestation-permis-list.component';
import { ConducteurDemandeVehiculeListComponent } from './conducteur-demande-vehicule-list.component';
import { ConducteurEditComponent } from './conducteur-edit.component';
import { ConducteurEntretienListComponent } from './conducteur-entretien-list.component';
import { ConducteurEquipementListComponent } from './conducteur-equipement-list.component';
import { ConducteurEtatLieuxListComponent } from './conducteur-etat-lieux-list.component';
import { ConducteurInfractionListComponent } from './conducteur-infraction-list.component';
import { ConducteurReleveCompteurListComponent } from './conducteur-releve-compteur-list.component';
import { ConducteurReleveEnergieListComponent } from './conducteur-releve-energie-list.component';
import { ConducteurSinistreListComponent } from './conducteur-sinistre-list.component';
import { ConducteurService } from './conducteur.service';

@Component({
	selector: 'conducteur',
	templateUrl: './conducteur.component.html'
})
export class ConducteurComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Conducteur courant **/
	public conducteur: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.VEHICULE_CONDUCTEUR;

	/** Utilisateur connecté **/
	user: User;

	/** Liste des attestations de permis de conduire **/
	listeAttestationsPermis: Array<any>;

	/** Résumé **/
	public resume: { nbVehiculesAffectes: number,nbInfractions: number,nbDemandesVehicule: number,nbRelevesCarburant: number,nbRelevesCompteur: number,nbSinistres: number,nbEntretiens: number,nbEtatsLieux: number,nbAttestationsPermis: number,nbEquipements: number } = {
		nbVehiculesAffectes: 0,
		nbInfractions: 0,
		nbDemandesVehicule: 0,
		nbRelevesCarburant: 0,
		nbRelevesCompteur: 0,
		nbSinistres: 0,
		nbEntretiens: 0,
		nbEtatsLieux: 0,
		nbAttestationsPermis: 0,
		nbEquipements: 0
	};

	/** Page complexe **/
	private complexPage: ComplexPageComponent;

	/** Sujet sur l'initialisation de la page complexe **/
	private initComplexPageSubject: Subject<boolean> = new Subject<boolean>();

	/**
	 * Constructeur
	 */
	constructor(private conducteurService: ConducteurService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute
			,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private attachmentService: AttachmentService,private notificationService: NotificationService,private layoutService: LayoutService,private store: Store<AppState>
			,private datePipe: DatePipe,private affectationService: AffectationService) {

	}

	/**
	 * Interception de la mise à jour de la page complexe
	 */
	@ViewChild('complexPage') set setComplexPage(complexPage: ComplexPageComponent) {
		//Vérification de la référence
		if (complexPage && !this.complexPage) {
			//Mise à jour de la référence
			this.complexPage = complexPage;

			//Container initialisé
			this.initComplexPageSubject.next(true);
		}
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.Conducteur';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.conducteur?.idConducteur || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.conducteur;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		combineLatest([this.store.select<User>(state => state.session?.user).pipe(filter(user => !!user))
			,this.notificationService.isNotificationAvailableFor(this.getClassName())
		]).pipe(first()).subscribe(([user,hasNotification]) => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Initialisation de la page complexe
			this.options = {
				listeFields: [{
					libelle: 'conducteur.collaborateur',
					key: 'user',
					type: 'DISPLAY',
					format: 'user'
				},{
					libelle: 'conducteur.dateDebut',
					key: 'dateDebut',
					type: 'DATE'
				},{
					libelle: 'conducteur.dateFin',
					key: (data) => data?.dateFin ? this.datePipe.transform(data.dateFin,'shortDate') : this.translateService.instant('common.aucune')
				}],
				listeActions: [{
					libelle: 'actions.notifier',
					doAction: () => this.notificationService.showSelectionMailForEntite(this.getClassName(),this.conducteur.idConducteur,{
						getOwningEntity: () => this.conducteur,
						typeAttachment: TypeAttachment.VEHICULE_CONDUCTEUR
					}),
					isVisible: () => hasNotification
				},{
					libelle: 'vehicule.actions.accederPlanning',
					doAction: () => this.goToPlanning(this.conducteur.user),
					isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'consultation') || this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')
				},{
					libelle: 'conducteur.actions.accederCollaborateur',
					doAction: () => this.goToUser(),
					isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'consultation')
				},{
					libelle: 'actions.modifier',
					doAction: () => this.editConducteur(),
					isVisible: () => this.rightService.hasRight(null,'creation'),
					type: TypeAction.EDITION
				},{
					libelle: 'actions.consulter',
					doAction: () => this.editConducteur(),
					isVisible: () => !this.rightService.hasRight(null,'creation'),
					type: TypeAction.CONSULTATION
				},{
					libelle: 'actions.supprimer',
					doAction: () => this.deleteConducteur(),
					isVisible: () => this.rightService.hasRight(null,'suppression')
				}],
				listeElements: [{
					type: 'VEHICULES_AFFECTES',
					libelle: 'conducteur.elements.vehiculeAffecte',
					component: ConducteurAffectationListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume,
						showAffectation: this.showAffectation.bind(this)
					}),
					doAction: () => this.showAffectation(),
					libelleAction: this.translateService.instant('conducteur.actions.ajouterAffectation'),
					count: () => this.resume?.nbVehiculesAffectes || 0,
					isVisible: () => this.user?.type != 'VIRTUAL',
					isActionVisible: () => (this.rightService.hasRight(null,'creation') || this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation')) && this.user?.type != 'VIRTUAL'
				},{
					type: 'INFRACTIONS',
					libelle: 'conducteur.elements.infraction',
					component: ConducteurInfractionListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume
					}),
					count: () => this.resume?.nbInfractions || 0,
					isVisible: () => (this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAVENTION,'consultation') || this.rightService.hasRight(TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,'consultation')) && this.user?.type != 'VIRTUAL'
				},{
					type: 'SINISTRES',
					libelle: 'conducteur.elements.sinistre',
					component: ConducteurSinistreListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur)
					}),
					count: () => this.resume?.nbSinistres || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_SINISTRE,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'RELEVES_COMPTEUR',
					libelle: 'conducteur.elements.releveCompteur',
					component: ConducteurReleveCompteurListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur)
					}),
					count: () => this.resume?.nbRelevesCompteur || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_COMPTEUR,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'RELEVES_CARBURANT',
					libelle: 'conducteur.elements.releveEnergie',
					component: ConducteurReleveEnergieListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur)
					}),
					count: () => this.resume?.nbRelevesCarburant || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_RELEVE_ENERGIE,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'ENTRETIENS',
					libelle: 'conducteur.elements.entretien',
					component: ConducteurEntretienListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume
					}),
					count: () => this.resume.nbEntretiens || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'DEMANDES_VEHICULE',
					libelle: 'conducteur.elements.demandeVehicule',
					component: ConducteurDemandeVehiculeListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume
					}),
					count: () => this.resume.nbDemandesVehicule || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'ETAT_LIEUX',
					libelle: 'conducteur.elements.etatLieux',
					component: ConducteurEtatLieuxListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur)
					}),
					count: () => this.resume.nbEtatsLieux || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation') && this.user?.type != 'VIRTUAL'
				},{
					type: 'ATTESTATION_PERMIS',
					libelle: 'conducteur.elements.attestationPermis',
					component: ConducteurAttestationPermisListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume,
						onConducteurUpdated: (conducteur: any) => this.conducteur = conducteur
					}),
					count: () => this.resume?.nbAttestationsPermis || 0
				},{
					type: 'EQUIPEMENTS',
					libelle: 'conducteur.elements.equipement',
					component: ConducteurEquipementListComponent,
					retrieveComponentData: () => ({
						conducteur: cloneDeep(this.conducteur),
						resume: this.resume
					}),
					count: () => this.resume.nbEquipements || 0,
					isVisible: () => this.rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation') && this.user?.type != 'VIRTUAL'
				}],
				isFormCustomization: true,
				isFullScreen: this.user?.type == 'VIRTUAL'
			};

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idConducteur);
		});
	}

	/**
	 * Edition du conducteur
	 */
	private editConducteur() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ConducteurEditComponent,{
			data: {
				conducteur: this.conducteur.idConducteur ? cloneDeep(this.conducteur) : this.conducteur,
				deleteConducteur: this.deleteConducteur.bind(this)
			}
		}).subscribe((conducteur: any) => {
			//Vérification de la présence de données
			if (conducteur) {
				//Mise à jour du conducteur
				this.conducteur = conducteur;

				//Mise à jour de l'identifiant contenu dans l'URL
				this.layoutService.replaceUrlWith(this.conducteur);
			}
		});
	}

	/**
	 * Suppression du conducteur
	 */
	private deleteConducteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.conducteurService.deleteConducteur(this.conducteur.idConducteur))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.conducteur;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idConducteur: number) {
		//Chargement du conducteur
		this.conducteurService.loadConducteur(idConducteur || 0).subscribe(result => {
			//Vérification du chargement
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Vérification du conducteur
				if (result.data?.conducteur) {
					//Définition du conducteur
					this.conducteur = result.data?.conducteur;
				} else {
					//Création d'un conducteur
					this.conducteur = {
						statut: 'NOUVELLE'
					};
				}

				//Initialisation des compteurs
				this.resume = {
					nbVehiculesAffectes: result.data?.nbVehiculesAffectes || 0,
					nbInfractions: result.data?.nbInfractions || 0,
					nbDemandesVehicule: result.data?.nbDemandesVehicule || 0,
					nbRelevesCarburant: result.data?.nbRelevesCarburant || 0,
					nbRelevesCompteur: result.data?.nbRelevesCompteur || 0,
					nbSinistres: result.data?.nbSinistres || 0,
					nbEntretiens: result.data?.nbEntretiens || 0,
					nbEtatsLieux: result.data?.nbEtatsLieux || 0,
					nbAttestationsPermis: result.data?.conducteur?.listeAttestationsPermis?.length || 0,
					nbEquipements: result.data?.nbAffectationsEquipement || 0
				};

				//Initialisation du compteur de pièces-jointes
				this.user.type != 'VIRTUAL' && this.attachmentService.initAttachments(this);

				//Vérification mode 'Création'
				if (this.user.type != 'VIRTUAL' && !this.conducteur.idConducteur)
					//Affichage du formulaire d'édition
					this.editConducteur();
			}
		});
	}

	/**
	 * Création ou modification d'une affectation
	 */
	private showAffectation(affectation: any = {}) {
		//Chargement complet de l'affectation si nécessaire
		(affectation.idAffectation ? this.affectationService.loadAffectation(affectation.idAffectation).pipe(map(result => result?.data?.affectation)) : of(affectation)).pipe(switchMap(affectation => {
			//Ouverture du composant d'édition
			return this.pageContentService.open(ConducteurAffectationEditComponent,{
				data: {
					affectation: affectation.idAffectation ? cloneDeep(affectation) : {
						user: this.conducteur.user,
						conducteur: this.conducteur,
						synchroImputationUser: true
					}
				}
			},'sub')
		})).subscribe((data: { affectation: any,idAffectationClosed: number }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('VEHICULES_AFFECTES',!!data.idAffectationClosed || affectation.idAffectation != data.affectation?.idAffectation);

				//Vérification de la présence d'une création ou d'une suppression
				if (!affectation.idAffectation && data.affectation?.idAffectation)
					//Incrémentation du compteur
					this.resume.nbVehiculesAffectes++;
				else if (affectation.idAffectation && !data.affectation?.idAffectation)
					//Décrémentation du compteur
					this.resume.nbVehiculesAffectes--;

				//Mise à jour de l'affectation
				affectation = Object.assign(affectation,data.affectation);
			}
		});
	}

	/**
	 * Accès au formulaire du collaborateur
	 */
	private goToUser() {
		//Redirection vers le collaborateur
		this.layoutService.goToByState('listeUsers-user',{
			routeParams: {
				idUser: this.conducteur.user?.idUser || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au planning
	 */
	private goToPlanning(user: any) {
		//Accès au planning
		this.conducteurService.goToPlanning(user);
	}
}