<form #entretienInterventionAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>entretien.intervention.ajoutIntervention.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': typeEntretien.invalid }">
			<label translate>vehicule.planEntretien.typeEntretien</label>
			<div>
				<autocomplete name="typeEntretien" type="typeEntretien" #typeEntretien="ngModel" [filter]="{ source: 'PLAN_ENTRETIEN' }" [(ngModel)]="link.typeEntretien" required></autocomplete>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="entretienInterventionAddForm.invalid" (click)="saveLink();">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>