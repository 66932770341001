<div class="listview lv-bordered no-search">
	<div class="lv-body card">
		<div *ngFor="let dashboard of listeDashboards">
			<div *ngIf="dashboard.liste?.length">
				<div class="p-30">
					<h4 class="bold"><span translate>profil.dashboard.title</span>&#160;<span>{{ "'" + ('profil.elements.dashboard.'+dashboard.type | translate) + "'" }}</span></h4>
				</div>
				<div class="lv-item media" *ngFor="let item of dashboard.liste">
					<profil-dasboard-list-item [profil]="profil" [item]="item" (onEdit)="onEdit($event)"></profil-dasboard-list-item>
				</div>
			</div>
		</div>
	</div>
</div>