<div class="card detail">
	<div class="card-header">
		<h2 class="card-title" [translate]="'extraction.entete.liste.title.'+(isEdition ? 'edition' : 'consultation')"></h2>
	</div>
	<div class="card-body">
		<form #extractionEnteteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
			<fieldset class="listview" [disabled]="!isEdition || !(null | right:'creation') || typeReferentiel == TypeReferentiel.STANDARD && !rightService.isRoot() || !extraction.custom && extraction.extractionOrigine">
				<div class="lv-body">
					<div class="lv-item media" *ngFor="let field of listeFields">
						<div class="row media-body">
							<div class="p-l-0 col-sm-6">
								<div class="form-group">
									<label translate>extraction.entete.libelle</label>
									<div>
										<span [title]="field.libelle">{{ field.libelle }}</span>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group" [ngClass]="{ 'has-error': customLibelle.invalid }">
									<label translate>extraction.entete.customLibelle</label>
									<div>
										<input type="text" name="customLibelle-{{ field.idField }}" #customLibelle="ngModel" class="form-control" [(ngModel)]="field.customLibelle" maxlength="2000"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center p-10" *ngIf="!listeFields?.length">
						<h5 translate>extraction.entete.liste.empty</h5>
					</div>
				</div>
			</fieldset>

			<ng-container *ngIf="isEdition">
				<span class="pull-right">
					<button mat-raised-button color="primary" *ngIf="(null | right:'creation') && (typeReferentiel != TypeReferentiel.STANDARD || rightService.isRoot()) && (extraction.custom || !extraction.extractionOrigine)" (click)="saveListeEntetes();" [disabled]="extractionEnteteForm.invalid">
						<span translate>actions.enregistrer</span>
					</button>
				</span>
				<button mat-button [formDetectChanges]="{ doAction: close,isDirty: extractionEnteteForm.dirty }">
					<span translate>actions.fermer</span>
				</button>
			</ng-container>
			<div class="clearfix"></div>
		</form>
	</div>
</div>