import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeService } from './vehicule.service';

@Component({
	templateUrl: './vehicule-imputation-list-item.component.html'
})
export class VehiculeImputationListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteImputation = this.deleteImputation.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteImputation(imputation: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.vehiculeService.deleteImputation(imputation))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Mise à jour du véhicule si nécessaire
					this.extraOptions.updateVehicule?.(result.data?.vehicule);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.({
						vehicule: result.data?.vehicule
					});
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Redirection vers le service
	 */
	goToService() {
		//Navigation vers le service
		this.layoutService.goToByState('listeServices-service',{
			routeParams: {
				idService: this.data.service.idService
			},
			withGoBack: true
		});
	}
}
