<div class="m-t-20">
	<form #modeleDetailsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="true">
			<modele-information [modele]="modele"></modele-information>
			<modele-categorisation [modele]="modele"></modele-categorisation>
			<modele-motorisation [information]="modele?.information"></modele-motorisation>
			<modele-confort [confort]="modele?.information?.confort"></modele-confort>
			<modele-commercialisation-prix [commercialisationPrix]="modele?.information?.commercialisationPrix"></modele-commercialisation-prix>
			<modele-performance [performance]="modele?.information?.performance"></modele-performance>
			<modele-dimension [dimension]="modele?.information?.dimension"></modele-dimension>
			<modele-pneumatique [pneumatique]="modele?.information?.pneumatique"></modele-pneumatique>
		</fieldset>
	</form>
</div>