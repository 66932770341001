import { Component,Input,OnInit,ViewChild,ElementRef,AfterViewInit,ChangeDetectorRef,Renderer2, HostBinding } from '@angular/core';

@Component({
	selector: 'thumb',
	templateUrl: './thumb.component.html',
	styleUrls: ['./thumb.component.scss'],
	styles: [':host { max-width: 100%; max-height: 100%; }']
})
export class ThumbComponent implements OnInit,AfterViewInit {
	/** Fichier **/
	@Input() file: File | any;

	/** Hauteur **/
	@Input() height: number;

	/** Largeur **/
	@Input() width: number;

	/** Fallback **/
	@Input() fallback?: string;

	/** Ajout de la classe 'd-inline-block' à l'élément hôte **/
	@HostBinding('class.d-inline-block') inlineBlockClass = true;

	/** Canvas **/
	@ViewChild('thumb') thumb: ElementRef;

	/** Type d'icone **/
	iconeType: string = null;

	/** Extension du fichier **/
	extension: string = null;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,private elementRef: ElementRef,private renderer: Renderer2) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let fileReader: FileReader = new FileReader();

		//Vérification du fichier
		if (this.file && this.file instanceof File) {
			//Vérification du type de fichier
			if (this.isFileImage(this.file.type)) {
				//Création du lecteur de fichier
				fileReader.onload = f => this.onLoadFile(f,null);

				//Lecture du fichier
				fileReader.readAsDataURL(this.file);
			} else
				//Création de l'icône associée au fichier
				this.createIconForFile(this.file.name);
		}
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Vérification de la présence d'un attachment
		if (this.file && !(this.file instanceof File)) {
			//Vérification du type d'image
			if (this.isFileImage(this.file.contentType))
				//Définition de l'image de fond
				this.onLoadFile(null,`controller/Blob/readBlob/${this.file.idBlob}?isThumb=true`);
			else
				//Création de l'icône associée au fichier
				this.createIconForFile(this.file.name);
		}

		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Chargement du fichier
	 */
	private onLoadFile(event: ProgressEvent<FileReader>,src: string): void {
		let img = new Image();

		//Chargement de l'image
		img.onload = () => this.onLoadImage(img);

		//Lecture de l'image
		img.src = src ? src : event.target.result as string;
	}

	/**
	 * Chargement de l'image
	 */
	private onLoadImage(img: HTMLImageElement): void {
		let resizedWidth: number;
		let resizedHeight: number;

		//Calcul des dimensions de l'image demandées
		resizedWidth = this.width || img.width / img.height * this.height;
		resizedHeight = this.height || img.height / img.width * this.width;

		//Définition de l'image de fond
		this.renderer.setStyle(this.thumb.nativeElement,'background-image',`url(${img.src})`);

		//Définition de la taille du container
		this.renderer.setStyle(this.elementRef.nativeElement,'width',`${resizedWidth}px`);
		this.renderer.setStyle(this.elementRef.nativeElement,'height',`${resizedHeight}px`);
	}

	/**
	 * Vérification de la présence d'une image
	 */
	private isFileImage(contentType: string) {
		let type;

		//Récupération du type
		type = '|' + contentType.slice(contentType.lastIndexOf('/') + 1) + '|';

		//Vérification de l'extension
		return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
	}

	/**
	 * Création d'une icône associée au fichier
	 */
	private createIconForFile(filename: string) {
		//Récupération de l'extension
		this.extension = filename.split('.').pop().toLowerCase();

		//Vérification de l'extension
		if (this.extension == 'pdf') {
			//Création de l'icône
			this.iconeType = 'fa fa-file-pdf-o';
		} else if (this.extension == 'xls' || this.extension == 'xlsx') {
			//Création de l'icône
			this.iconeType = 'fa fa-file-excel-o';
		} else if (this.extension == 'doc' || this.extension == 'docx') {
			//Création de l'icône
			this.iconeType = 'fa fa-file-word-o';
		} else if (this.extension == 'ppt' || this.extension == 'pptx') {
			//Création de l'icône
			this.iconeType = 'fa fa-file-powerpoint-o';
		} else if (this.extension == 'zip' || this.extension == 'gz') {
			//Création de l'icône
			this.iconeType = 'fa fa-file-archive-o';
		} else {
			//Définition du bloc (Extension par défaut)
			this.iconeType = 'fa fa-file-o';
		}
	}
}