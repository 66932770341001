import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TypeAmenagementAddComponent } from './type-amenagement-add.component';
import { TypeAmenagementEditComponent } from './type-amenagement-edit.component';
import { TypeAmenagementFieldListComponent } from './type-amenagement-field-list.component';
import { TypeAmenagementListItemComponent } from './type-amenagement-list-item.component';
import { TypeAmenagementListComponent } from './type-amenagement-list.component';
import { TypeAmenagementComponent } from './type-amenagement.component';
import { TypeAmenagementService } from './type-amenagement.service';

@NgModule({
	imports: [ShareModule],
	declarations: [TypeAmenagementListComponent,TypeAmenagementListItemComponent,TypeAmenagementComponent,TypeAmenagementEditComponent,TypeAmenagementFieldListComponent,TypeAmenagementAddComponent],
	providers: [TypeAmenagementService]
})
export class TypeAmenagementModule { }
