import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class TaxeService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement de la taxe
	 */
	public loadTaxe(idTaxe: number): Observable<Result> {
		//Chargement de la taxe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Taxe/loadTaxe/${idTaxe}`,null);
	}

	/**
	 * Suppression de la taxe
	 */
	public deleteTaxe(taxe: any): Observable<Result> {
		//Suppression de la taxe
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Taxe/deleteTaxe/${taxe.idTaxe}`);
	}

	/**
	 * Enregistrement de la taxe
	 */
	public saveTaxe(taxe: any): Observable<Result> {
		//Enregistrement de la taxe
		return this.http.put<Result>(`${environment.baseUrl}/controller/Taxe/saveTaxe`,taxe);
	}
}