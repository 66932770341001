import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './service.entite';
import { ServiceService } from './service.service';

@Component({
	selector: 'service-list-item',
	templateUrl: './service-list-item.component.html'
})
export class ServiceListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Service enfant */
	@Input() isChild: boolean = false;

	/** Nombre de sous-services **/
	public nbTotalItems: number;

	/** Nombre d'objets par page **/
	public nbObjetsParPage: number;

	/**
	 * Constructeur
	 */
	constructor(private serviceService: ServiceService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Chargement de la liste des sous-services
	 */
	loadListeSousServices(nbPages: number = 0) {
		//Vérification de l'état de la liste des sous-services
		if (!this.data.listeSousServices)
			//Initialisation de la liste des sous-services
			this.data.listeSousServices = [];

		//Vérification de la présence de sous-services (ou du besoin de charger les éléments suivants)
		if (this.data?.extension?.nbSousServices > 0 && (!this.data.listeSousServices?.length && nbPages == 0 || this.nbTotalItems > this.data.listeSousServices?.length)) {
			//Chargement des sous-services
			this.serviceService.loadListeSousServices(this.data,nbPages).subscribe({
				next: (result: Page<any>) => {
					//Définition du nombre total d'éléments
					this.nbTotalItems = result.totalElements;

					//Définition du nombre d'objets par page
					this.nbObjetsParPage = result.size;

					//Définition de la liste des sous-services
					this.data.listeSousServices.push.apply(this.data.listeSousServices,result.content);
				}
			});
		}
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification du service
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idService,object?.idService && !this.data?.idService);
	}

	/**
	 * Suppression du service
	 */
	deleteService() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du service
					this.serviceService.deleteService(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du service
								this.onObjectChange(null);

								//Vérification du service enfant
								if (this.isChild)
									//Notification de suppression
									this.onRemove.emit(this.data)
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.service.Service',this.data.idService);
	}

	/**
	 * Création d'un sous-service
	 */
	addService() {
		//Chargement du service (récupération du service parent)
		this.serviceService.loadService(this.data.idService).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du service
					this.data = Object.assign(this.data,result.data.service);

					//Navigation vers le service
					this.layoutService.goToByState('listeServices-service',{
						routeParams: {
							idService: 0
						},
						routeData: {
							service: {
								parent: result?.data?.service,
								societe: result?.data?.service?.societe || result?.data?.service,
								type: 'SERVICE'
							}
						},
						withGoBack: true,
						reloadOnSameUrl: true
					});
				}
			}
		});
	}

	/**
	 * Déplacement d'un service
	 */
	showMoveService() {
		//Ouverture de la page de déplacement d'un service
		this.serviceService.showMoveService(this.data).subscribe({
			next: () => {
				//Rafraichissement de la liste des services
				this.liste.refresh();
			}
		});
	}

	/**
	 * Suppression d'un service enfant
	 */
	removeChildService(childService: any) {
		let index: number;

		//Recherche de l'index
		index = this.data?.listeSousServices?.indexOf(childService);

		//Vérification de la présence de l'index
		if (index != -1) {
			//Suppression du sous-service
			this.data?.listeSousServices?.splice(index,1);

			//Décrémentation du nombre de sous-services
			this.data.extension.nbSousServices--;
		}

		//Vérification de la liste des sous-services
		if (this.data?.listeSousServices?.length == 0)
			//Femeture du service
			this.data.isDisplayed = false;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Femeture du service
		this.data.isDisplayed = false;

		//Suppression de la liste des sous-services
		this.data.listeSousServices = null;
	}
}