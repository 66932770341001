<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.dimension.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.dimension.hauteur</label>
					<div class="half" *ngIf="dimension?.hauteur">
						<div class="input-group half">
							<input type="text" name="hauteur" #hauteur="ngModel" class="form-control" [(ngModel)]="dimension.hauteur" nNumber/>
							<div class="input-group-addon no-border">mm</div>
						</div>
					</div>
					<div *ngIf="!dimension?.hauteur">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.hauteurMax</label>
					<div class="half" *ngIf="dimension?.hauteurMax">
						<div class="input-group">
							<input type="text" name="hauteurMax" #hauteurMax="ngModel" class="form-control" [(ngModel)]="dimension.hauteurMax" nNumber/>
							<div class="input-group-addon no-border">mm</div>
						</div>
					</div>
					<div *ngIf="!dimension?.hauteurMax">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.largeur</label>
					<div class="half" *ngIf="dimension?.largeur">
						<div class="input-group">
							<input type="text" name="largeur" #largeur="ngModel" class="form-control" [(ngModel)]="dimension.largeur" nNumber/>
							<div class="input-group-addon no-border">mm</div>
						</div>
					</div>
					<div *ngIf="!dimension?.largeur">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.longueur</label>
					<div class="half" *ngIf="dimension?.longueur">
						<div class="input-group">
							<input type="text" name="longueur" #longueur="ngModel" class="form-control" [(ngModel)]="dimension.longueur" nNumber/>
							<div class="input-group-addon no-border">mm</div>
						</div>
					</div>
					<div *ngIf="!dimension?.longueur">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.empattement</label>
					<div class="half" *ngIf="dimension?.empattement">
						<div class="input-group">
							<input type="text" name="empattement" #empattement="ngModel" class="form-control" [(ngModel)]="dimension.empattement" nNumber/>
							<div class="input-group-addon no-border">mm</div>
						</div>
					</div>
					<div *ngIf="!dimension?.empattement">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.dimension.ptac</label>
					<div class="half" *ngIf="dimension?.ptac">
						<div class="input-group">
							<input type="text" name="ptac" #ptac="ngModel" class="form-control" [(ngModel)]="dimension.ptac" nNumber/>
							<div class="input-group-addon no-border">kg</div>
						</div>
					</div>
					<div *ngIf="!dimension?.ptac">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.ptra</label>
					<div class="half" *ngIf="dimension?.ptra">
						<div class="input-group">
							<input type="text" name="ptra" #ptra="ngModel" class="form-control" [(ngModel)]="dimension.ptra" nNumber/>
							<div class="input-group-addon no-border">kg</div>
						</div>
					</div>
					<div *ngIf="!dimension?.ptra">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.capaciteCoffre</label>
					<div class="half" *ngIf="dimension?.capaciteCoffre">
						<div class="input-group">
							<input type="text" name="capaciteCoffre" #capaciteCoffre="ngModel" class="form-control" [(ngModel)]="dimension.capaciteCoffre" nNumber/>
							<div class="input-group-addon no-border">L</div>
						</div>
					</div>
					<div *ngIf="!dimension?.capaciteCoffre">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.capaciteCoffreMin</label>
					<div class="half" *ngIf="dimension?.capaciteCoffreMin">
						<div class="input-group">
							<input type="text" name="capaciteCoffreMin" #capaciteCoffreMin="ngModel" class="form-control" [(ngModel)]="dimension.capaciteCoffreMin" nNumber/>
							<div class="input-group-addon no-border">L</div>
						</div>
					</div>
					<div *ngIf="!dimension?.capaciteCoffreMin">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.capaciteCoffreMax</label>
					<div class="half" *ngIf="dimension?.capaciteCoffreMax">
						<div class="input-group">
							<input type="text" name="capaciteCoffreMax" #capaciteCoffreMax="ngModel" class="form-control" [(ngModel)]="dimension.capaciteCoffreMax" nNumber/>
							<div class="input-group-addon no-border">L</div>
						</div>
					</div>
					<div *ngIf="!dimension?.capaciteCoffreMax">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.dimension.capaciteChargeToit</label>
					<div class="half" *ngIf="dimension?.capaciteChargeToit">
						<div class="input-group">
							<input type="text" name="capaciteChargeToit" #capaciteChargeToit="ngModel" class="form-control" [(ngModel)]="dimension.capaciteChargeToit" nNumber/>
							<div class="input-group-addon no-border">kg</div>
						</div>
					</div>
					<div *ngIf="!dimension?.capaciteChargeToit">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>