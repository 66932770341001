<list-view [liste]="liste" *ngIf="liste"></list-view>

<div class="listview lv-bordered">
	<div class="lv-body">
		<ng-container *ngIf="liste.data?.content?.length > 0">
			<div class="lv-item media" *ngFor="let lien of liste.data.content">
				<div class="pull-left lv-avatar">
					<icon name="directions_car"></icon>
				</div>
				<div class="media-body">
					<div class="lv-title"><a [routerLink]="[]" (click)="onModeleSelected(lien.modele);">{{ lien.modele | display:'modele' }}</a></div>
					<ul class="lv-attrs">
						<li *ngIf="lien.modele.configuration && lien.modele.information?.genre?.modeAffichagePrix == 'HT'"><span translate>demandeVehicule.modele.configuration.prixHT</span>&#160;:&#160;<span>{{ computeMontantForModele(lien.modele) | currency:'.2-2':lien.modele.information?.commercialisationPrix?.devise }}</span></li>
						<li *ngIf="lien.modele.configuration && lien.modele.information?.genre?.modeAffichagePrix == 'TTC'"><span translate>demandeVehicule.modele.configuration.prixTTC</span>&#160;:&#160;<span>{{ computeMontantForModele(lien.modele) | currency:'.2-2':lien.modele.information?.commercialisationPrix?.devise }}</span></li>
					</ul>
				</div>
			</div>
		</ng-container>
		<div class="text-center p-10" *ngIf="!liste.data?.content">
			<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
		</div>
		<div class="text-center p-10" *ngIf="liste.data?.content?.length === 0">
			<h5 translate>liste.empty</h5>
		</div>
	</div>
</div>