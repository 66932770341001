import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { ImmatriculationService } from 'src/app/components/vehicule/immatriculation/immatriculation.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeVehiculeService } from './demande-vehicule.service';

@Component({
	templateUrl: './demande-vehicule-vehicule-informations-complementaires.component.html'
})
export class DemandeVehiculeVehiculeInformationsComplementairesComponent implements OnInit {
	/** Demande de véhicule **/
	@Input() demandeVehicule: any;

	/** Véhicule **/
	@Input() vehicule: any;

	/** Configuration du pays **/
	@Input() configurationPays: any;

	/** Liste des types de Crit'Air **/
	public listeTypesCritAir: Array<{ code: string,libelle: string }>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Véhicule modifié **/
	public savedVehicule: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DemandeVehiculeVehiculeInformationsComplementairesComponent>,private vehiculeService: VehiculeService,private toastrService: ToastrService,private translateService: TranslateService,private immatriculationService: ImmatriculationService,private layoutService: LayoutService, private demandeVehiculeService: DemandeVehiculeService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types de Crit'Air
		this.listeTypesCritAir = this.vehiculeService.getListeTypesCritAir();
	}

	/**
	 * Enregistrement du véhicule
	 */
	saveVehicule() {
		//Enregistrement du véhicule
		this.vehiculeService.saveVehicule(this.vehicule).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du véhicule
				this.savedVehicule = result.data.vehicule;

				//Fermeture de la pop-up
				this.bsModalRef.hide();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Ouverture de la popup d'immatriculation
	 */
	public showImmatriculation() {
		//Ouverture de la popup d'immatriculation
		this.immatriculationService.showImmatriculation({
			immatriculation: this.vehicule.immatriculation,
			pays: this.vehicule.pays,
			typeVehicule: this.vehicule.typeVehicule,
			vehicule: this.vehicule,
			options: {
				isSynchro: false,
				modeleInitial: this.demandeVehicule.modele
			}
		}).subscribe({
			next: ({ vehicule,immatriculation }) => {
				//Enregistrement de la demande de véhicule
				this.demandeVehiculeService.saveVehiculeForDemandeVehicule(this.demandeVehicule.idDemandeVehicule,immatriculation.idImmatriculation,vehicule?.modeleConstructeur?.idModele || 0,vehicule.idVehicule).subscribe(result => {
					//Vérification du code d'erreur
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR && result?.data?.demandeVehicule) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Mise à jour de la demande de véhicule
						this.demandeVehicule = Object.assign(this.demandeVehicule,result.data.demandeVehicule);
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				});

				//Vérification d'un changement de modèle constructeur
				if (vehicule?.modeleConstructeur?.idModele != this.vehicule?.modeleConstructeur?.idModele) {
					//Mise à jour de l'objet
					Object.assign(this.vehicule,vehicule);

					//Enregistrement du véhicule
					this.saveVehicule();
				} else if (immatriculation) {
					//Rechargement de l'écran
					this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
						routeParams: {
							idDemandeVehicule: this.demandeVehicule.idDemandeVehicule
						},
						reloadOnSameUrl: true
					});

					//Fermeture de la pop-up
					this.bsModalRef.hide();
				}
			}
		});
	}
}