import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash-es';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class EtatLieuxService {
	/**
	 * Liste des statuts
	 */
	private listeStatuts: Array<{ code: string,icone: string,iconeType?: string }> = [{
		code: 'EN_COURS',
		icone: 'edit'
	},{
		code: 'PLANIFIE',
		icone: 'warning'
	},{
		code: 'TERMINE',
		icone: 'check'
	}];

	/** Liste des types de zones des véhicules **/
	private listeParts: Array<{ index: number,type: string,listeZones: Array<{ code: string }> }> = [{
		index: 0,
		type: 'AVANT',
		listeZones: [{
			code: 'AVANT'
		}]
	},{
		index: 1,
		type: 'AVANT',
		listeZones: [{
			code: 'AVANT_GAUCHE'
		},{
			code: 'AVANT_DROIT'
		}]
	},{
		index: 2,
		type: 'LATERAL',
		listeZones: [{
			code: 'LATERAL_GAUCHE'
		},{
			code: 'LATERAL_DROIT'
		}]
	},{
		index: 3,
		type: 'ARRIERE',
		listeZones: [{
			code: 'ARRIERE_GAUCHE'
		},{
			code: 'ARRIERE_DROIT'
		}]
	},{
		index: 4,
		type: 'ARRIERE',
		listeZones: [{
			code: 'ARRIERE'
		}]
	}];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private http: HttpClient) { }

	/**
	 * Récupération de la liste des statuts traduits
	 */
	public getListeStatuts(): Array<{ code: string,icone: string,iconeType?: string,libelle: string }> {
		//Retour de la liste des statuts traduits
		return this.listeStatuts.map(statut => ({
			...statut,
			libelle: this.translateService.instant('vehicule.etatLieux.statut.'+statut.code)
		}));
	}

	/**
	 * Récupération des statuts des états des lieux
	 */
	public getMapStatuts(): { [statut: string]: { icone: string,iconeType?: string } } {
		//Retour des statuts
		return this.listeStatuts.reduce((map,statut) => {
			//Ajout du statut
			map[statut.code] = {
				icone: statut.icone,
				iconeType: statut.iconeType
			}

			return map;
		},{});
	}

	/**
	 * Suppression d'un état des lieux
	 */
	public deleteEtatLieux(etatLieux: any): Observable<Result> {
		//Suppression d'un état des lieux
		return this.http.delete<Result>(`${environment.baseUrl}/controller/EtatLieux/deleteEtatLieux/${etatLieux.idEtatLieux}`);
	}

	/**
	 * Chargement d'un état des lieux
	 */
	public loadEtatLieux(idEtatLieux: number): Observable<Result> {
		//Chargement de l'état des lieux
		return this.http.post<Result>(`${environment.baseUrl}/controller/EtatLieux/loadEtatLieux/${idEtatLieux}`,null);
	}

	/**
	 * Enregistrement d'un état des lieux
	 */
	public saveEtatLieux(etatLieux: any): Observable<Result> {
		//Enregistrement d'un état des lieux
		return this.http.put<Result>(`${environment.baseUrl}/controller/EtatLieux/saveEtatLieux`,etatLieux);
	}

	/**
	 * Récupération de la liste des parties
	 */
	public getListeParts(): Array<{ index: number,type: string,listeZones: Array<{ code: string }> }> {
		//Retour de la liste des parties
		return clone(this.listeParts);
	}
}