import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './pays-scope.component.html'
})
export class PaysScopeComponent implements OnInit {
	/** Liste des scopes disponibles **/
	listeScopes: Array<any> =  [{
		scope: 'IMMATRICULATION_VEHICULE'
	}];

	/** Liste des scopes sélectionnés **/
	listeSelectedScopes: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Mise à jour de la liste des scopes en fonction de ceux sélectionnés sur le pays
		this.listeScopes.forEach(scope => {
			//Mise à jour de la sélection
			scope.isSelected = this.listeSelectedScopes?.length && this.listeSelectedScopes.some(s => s.scope === scope.scope);
		});
	}

	/**
	 * Enregistrement des scopes
	 */
	save() {
		//Mise à jour de la liste des scopes en fonction de ceux sélectionnés
		this.listeSelectedScopes = this.listeScopes.filter(scope => scope.isSelected);

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}