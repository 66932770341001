import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter,first,switchMap,tap,map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { User } from 'src/app/domain/user/user';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ReponseDemandeCotationService } from './reponse-demande-cotation.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ReponseCotationListComponent } from './reponse-cotation-list.component';
import { ReponseCotationComponent } from './reponse-cotation.component';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'reponse-demande-cotation',
	templateUrl: './reponse-demande-cotation.component.html'
})
export class ReponseDemandeCotationComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Réponse à une demande de cotation **/
	public reponseDemandeCotation: any;

	/** Utilisateur connecté **/
	user: User;

	/** Résumé **/
	public resume: { nbCotations: number,nbCotationsATransmettre: number } = {
		nbCotations: 0,
		nbCotationsATransmettre: 0
	}

	/** Demande indisponible **/
	public isDemandeIndisponible = false;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private activatedRoute: ActivatedRoute,private translateService: TranslateService,private rightService: RightService,private reponseDemandeCotationService: ReponseDemandeCotationService,private pageContentService: PageContentService,private loggedUserService: LoggedUserService,private confirmService: ConfirmService,private toastrService: ToastrService) {
		//Binding des méthodes
		this.showReponseCotation = this.showReponseCotation.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.cotation.ReponseCotation';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.reponseDemandeCotation?.idReponse || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		this.activatedRoute.params.pipe(
			first(),
			map(params => params.idReponse)
		).subscribe(idReponse => {
			//Définition de l'utilisateur connecté
			this.user = this.loggedUserService.getUser();

			//Chargement des données
			this.loadData(idReponse).subscribe({
				next: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [{
							libelle: 'reponseDemandeCotation.reference',
							key: 'demandeCotation.idDemandeCotation'
						},{
							libelle: 'reponseDemandeCotation.client',
							key: 'tenant.nom'
						},{
							libelle: 'reponseDemandeCotation.contact',
							key: (reponse) => `${reponse.demandeCotation.user.prenom} ${reponse.demandeCotation.user.nom}`
						},{
							libelle: 'reponseDemandeCotation.avancement',
							key: (reponse) => `${reponse.extension.avancementTransmission}/${reponse.extension.nbCotations}`
						},{
							libelle: 'reponseDemandeCotation.email',
							key: 'demandeCotation.user.email',
							isSecondary: true
						},{
							libelle: 'reponseDemandeCotation.telephone',
							key: (reponse) => reponse.demandeCotation.user.userDescription?.telephonePro?.numero ? reponse.demandeCotation.user.userDescription.telephonePro.numero : this.translateService.instant('reponseDemandeCotation.telephoneAbsent'),
							isSecondary: true
						}],
						listeActions: [],
						listeAlertes: [{
							icon: 'info',
							level: () => 'INFO',
							title: this.translateService.instant('reponseDemandeCotation.alerte.demandeCotationExpiree'),
							message: '',
							isVisible: () => this.isDemandeIndisponible && !this.pageContentService.isOpened()
						},{
							icon: 'send',
							title: this.translateService.instant('reponseDemandeCotation.alerte.aTransmettre.title'),
							message: this.translateService.instant('reponseDemandeCotation.alerte.aTransmettre.message'),
							isVisible: () => this.resume.nbCotationsATransmettre > 0 && !this.pageContentService.isOpened(),
							isActionVisible: () => this.rightService.hasRight(null,'creation'),
							doAction: () => this.doAction('TRANSMETTRE')
						},{
							icon: 'info',
							level: () => 'INFO',
							title: '',
							message: this.translateService.instant('reponseDemandeCotation.alerte.cotationExpiree'),
							isVisible: () => (this.reponseDemandeCotation.demandeCotation.statut == 'ANNULEE' || this.resume.nbCotations == 0) && !this.pageContentService.isOpened()
						}],
						listeElements: [{
							type: 'COTATION',
							component: ReponseCotationListComponent,
							retrieveComponentData: () => ({
								reponseDemandeCotation: this.reponseDemandeCotation,
								resume: this.resume,
								showReponseCotation: this.showReponseCotation
							}),
							count: () => this.resume.nbCotations,
							isActionVisible: () => false,
							isVisible: () => true,
							isTabHidden: true
						}]
					};
				}
			});
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idReponse: number): Observable<any> {
		//Chargement de la réponse à la demande de cotation
		return this.reponseDemandeCotationService.loadReponseDemandeCotation(idReponse).pipe(
			first(),
			tap(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Vérification de la réponse à la demande de cotation
					if (result.data?.reponseDemandeCotation) {
						//Définition de la réponse à la demande de cotation
						this.reponseDemandeCotation = result.data.reponseDemandeCotation;

						//Résumé
						this.resume = {
							nbCotations: result.data.nbCotations || 0,
							nbCotationsATransmettre: result.data.nbCotationsATransmettre
						};
					} else
						//Demande indisponible
						this.isDemandeIndisponible = true;
				}
			})
		);
	}

	/**
	 * Exécution d'une action sur une réponse de demande de cotation
	 */
	private doAction(action: any) {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('reponseDemandeCotation.actions.' + action.toLowerCase() + '.confirmation'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.reponseDemandeCotationService.executeActionForReponse(action,this.reponseDemandeCotation.idReponse))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('reponseDemandeCotation.actions.' + action.toLowerCase() + '.success'));

				//Rafraichissement de l'élément
				this.complexPage.setSelectedElementByType('COTATION',true);

				//Vérification du nombre de réponses aux cotations transmises
				if (result.data && result.data.nbCotationsTransmises !== undefined)
					//Modification du compteur
					this.reponseDemandeCotation.extension.avancementTransmission = result.data.nbCotationsTransmises;

				//Vérification du nombre de réponses aux cotations à transmettre
				if (result.data && result.data.nbCotationsATransmettre !== undefined)
					//Mise à jour du résumé
					this.resume.nbCotationsATransmettre = result.data.nbCotationsATransmettre;
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('reponseDemandeCotation.actions.error'));
			}
		});
	}


	/**
	 * Edition d'une réponse à la cotation
	 */
	showReponseCotation(reponseCotation,reponseDemandeCotation) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ReponseCotationComponent,{
			data: {
				reponseCotation: reponseCotation,
				reponseDemandeCotation: reponseDemandeCotation,
				resume: this.resume,
				source: 'FOURNISSEUR',
				isReadOnly: ['A_COMPLETER','A_TRANSMETTRE'].indexOf(reponseCotation.statut) == -1,
				isFromDemandeCotation: false
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification du nombre de réponses aux cotations transmises
				if (data?.nbCotationsTransmises)
					//Modification du compteur
					this.reponseDemandeCotation.extension.avancementTransmission = data.nbCotationsTransmises;

				//Vérification du nombre de réponses aux cotations à transmettre
				if (data?.nbCotationsATransmettre != undefined)
					//Mise à jour du résumé
					this.resume.nbCotationsATransmettre = data.nbCotationsATransmettre;

				//Rafraichissement de l'élément
				this.complexPage.setSelectedElementByType('COTATION',true);
			}
		});
	}
}