import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { CalendrierService } from './calendrier.service';

@Component({
	templateUrl: './calendrier-entite.component.html'
})
export class CalendrierEntiteComponent extends PageContentItem {
	/** Calendrier **/
	@Input() calendrier: any;

	/** Détail courant **/
	@Input() detail: any;

	/** Liste des entités disponibles **/
	@Input() listeEntites: Array<any>;

	/** Index du détail dans la liste **/
	@Input() idxDetail: number;

	/**
	 * Constructeur
	 */
	constructor(private calendrierService: CalendrierService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Suppression du détail
	 */
	deleteDetail() {
		//Vérification de la position de la ligne
		if (this.idxDetail != null && this.detail.idDetail) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
				next: isConfirmed => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Retrait du détail de la liste
						this.calendrier.listeDetails.splice(this.idxDetail,1);

						//Enregistrement du calendrier
						this.calendrierService.saveCalendrier(this.calendrier).subscribe({
							next: (result: Result) => {
								//Vérification du code d'erreur
								if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
									//Message d'information
									this.toastrService.success(this.translateService.instant('actions.suppression.success'));

									//Fermeture de l'édition
									this.close(result.data.calendrier);
								} else {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('actions.suppression.error'));
								}
							}
						});
					}
				}
			});
		}
	}

	/**
	 * Enregistrement du détail
	 */
	saveDetail() {
		let hasDoublon;

		//Vérification ou création de la liste des détails
		this.calendrier.listeDetails = this.calendrier.listeDetails || [];

		//Vérification de la présence d'un doublon
		hasDoublon = this.calendrier.listeDetails.some(detail => detail.idDetail != this.detail.idDetail && detail.entite == this.detail.entite);

		//Vérification de l'anti-doublon
		if (!hasDoublon) {
			//Vérification de la position de la ligne
			if (this.idxDetail != null) {
				//Vérification de la ligne
				if (this.detail.idDetail)
					//Mise à jour de la ligne
					this.calendrier.listeDetails[this.idxDetail] = this.detail;
				else
					//Suppression de l'élément de la liste
					this.calendrier.listeDetails.splice(this.idxDetail,1);
			} else {
				//Insertion d'un nouvel élément en début de liste
				this.calendrier.listeDetails.splice(0,0,this.detail);
			}

			//Enregistrement du calendrier
			this.calendrierService.saveCalendrier(this.calendrier).subscribe({
				next: (result: Result) => {
					let listeDoublons = new Array<string>();

					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Fermeture de l'édition
						this.close(result.data.calendrier);
					} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			});
		} else {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
				field: this.translateService.instant('actions.doublon.entite')
			}));
		}
	}
}