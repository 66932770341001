import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { delay,first,map,tap } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { ImportSelectionComponent } from './import/import-selection.component';
import { PleaseWaitService } from 'src/app/share/components/please-wait/please-wait.service';
import { PleaseWaitModalComponent } from 'src/app/share/components/please-wait/please-wait-modal.component';

@Injectable()
export class ConnecteurService {
	/** Cache des présences de connecteurs par type de flux **/
	private mapConnecteursForTypeFlux: { [typeFlux: string]: boolean } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private bsModalService: BsModalService,private pleaseWaitService: PleaseWaitService,private translateService: TranslateService) { }

	/**
	 * Synchronisation des connecteurs
	 */
	public syncConnecteurs(): Observable<Result> {
		//Synchronisation des connecteurs
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/syncConnecteurs`,null);
	}

	/**
	 * Authentification sur le connecteur
	 */
	public login(connecteur: any): Observable<Result> {
		//Authentification sur le connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/login`,connecteur);
	}

	/**
	 * Exécution du connecteur
	 */
	public execute(connecteur: any): Observable<Result> {
		//Exécution du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/execute`,connecteur);
	}

	/**
	 * Vérification de la disponibilité d'un import
	 */
	public isImportAvailable(typeFlux?: TypeFlux): Observable<boolean> {
		//Lecture du type de flux (depuis la route si nécessaire)
		typeFlux = typeFlux || this.layoutService.getExtendedRouteData().typeFlux;

		//Vérification de l'absence de cache
		if (this.mapConnecteursForTypeFlux[typeFlux] === undefined) {
			//Exécution de la requête HTTP
			return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/countConnecteurs`,{
				listeFilter: [{
					clef: 'type.typeConnecteur',
					valeur: 'INTERFACE',
					typeFilter: TypeFilter.STRING,
					typeComparaison: TypeComparaison.EQUAL
				},{
					clef: 'type.fournisseurInterface.typeFlux',
					valeur: typeFlux,
					typeFilter: TypeFilter.STRING,
					typeComparaison: TypeComparaison.EQUAL
				}]
			}).pipe(
				first(),
				map(result => !!result?.data?.nbConnecteurs),
				tap(isAvailable => this.mapConnecteursForTypeFlux[typeFlux] = isAvailable)
			);
		} else
			//Retour du cache
			return of(this.mapConnecteursForTypeFlux[typeFlux]);
	}

	/**
	 * Affichage de la popup d'import
	 */
	public showImport(typeFlux?: TypeFlux) {
		//Lecture du type de flux (depuis la route si nécessaire)
		typeFlux = typeFlux || this.layoutService.getExtendedRouteData().typeFlux;

		//Affichage de la popup
		this.bsModalService.show(ImportSelectionComponent,{
			initialState: {
				typeFlux
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Vérification de la disponibilité d'un connecteur
	 */
	public checkConnecteurAvailability(idConnecteur: number): Observable<boolean> {
		let bsModalRef: BsModalRef<PleaseWaitModalComponent>;

		//Affichage du message d'attente
		bsModalRef = this.pleaseWaitService.show({
			message: this.translateService.instant('connecteur.import.selection.verification')
		});

		//Vérification de la disponibilité du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/checkConnecteurAvailability/${idConnecteur}`,null)
			.pipe(
				delay(200),
				map(result => result?.data?.connecteur?.actif),
				tap(() => bsModalRef.hide())
			);
	}

	/**
	 * Exécution de l'interface
	 */
	public executeInterface(connecteur: any): Observable<Result> {
		//Exécution de l'interface
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/executeInterface/${connecteur.idConnecteur}`,connecteur);
	}
}