import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { AuthentificationConfigurationService } from 'src/app/components/authentification/configuration/authentification-configuration.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { SelectPickerService } from 'src/app/share/components/select-picker/select-picker.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TenantCompteServiceComponent } from './tenant-compte-service.component';
import { TenantService } from './tenant.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	selector: 'tenant-edit',
	templateUrl: './tenant-edit.component.html'
})
export class TenantEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() tenant: any;

	/** Suppression de l'objet **/
	@Input() deleteTenant: () => void;

	/** Liste des locales */
	@Input() listeLocales: Array<any>;

	/** Liste des timezones */
	public listeTimezones: Array<any>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Liste des modes d'authentification **/
	public listeModesAuthentification: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private tenantService: TenantService,private bsModalService: BsModalService,public selectPickerService: SelectPickerService,private authentificationConfigurationService: AuthentificationConfigurationService,private loggedUserService: LoggedUserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des timezones;
		this.listeTimezones = this.tenantService.getListeTimezones();

		//Définition de la liste des modes d'authentification
		this.listeModesAuthentification = this.authentificationConfigurationService.getListeModesAuthentification();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveTenant() {
		//Enregistrement de l'objet
		this.tenantService.saveTenant(this.tenant).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.tenant,result.data?.tenant);

					//Fermeture du formulaire
					this.close(this.tenant);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification de la raison sociale
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.raisonSociale'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Vérification du type d'erreur
					if (result?.data?.bddIndisponible)
						//Message d'erreur lié à la disponibilité de la base de données
						this.toastrService.error(this.translateService.instant('tenant.error.bddIndisponible'));
					else if (result?.data?.doublonAdmin)
						//Message d'erreur lié au doublon sur l'email administrateur
						this.toastrService.error(this.translateService.instant('tenant.error.doublonAdmin'));
					else
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Ouverture de la popup des comptes de service
	 */
	public showCompteService() {
		let bsModalRef: BsModalRef<TenantCompteServiceComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(TenantCompteServiceComponent,{
			initialState: {
				listeComptesService: cloneDeep(this.tenant.listeComptesService || []),
				disabled: this.tenant?.deleted
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.listeComptesService),
			filter(listeComptesService => !!listeComptesService)
		).subscribe({
			next: (listeComptesService: any) => {
				//Définition du résultat
				this.tenant.listeComptesService = listeComptesService;
			}
		});
	}

	/**
	 * Déclenchement d'un message d'alerte
	 */
	showAlerteDoublonMatricule(isAllowDoublonMatricule: boolean) {
		//Vérification de l'autorisation des doublons sur les matricules
		if (isAllowDoublonMatricule)
			//Message d'avertissement
			this.toastrService.warning(this.translateService.instant('tenant.allowDoublonMatricule.warning'));
	}

	/**
	 * Vérification de la présence d'une restriction client sur le profil de l'utilisateur
	 */
	public hasRestrictionClient(): boolean {
		//Vérification de l'existence d'un profil avec restriction client pour l'utilisateur
		return this.loggedUserService.hasProfilRestriction(TypeRestriction.CLIENT);
	}
}