import { AfterContentChecked,ChangeDetectorRef,Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ContratCRMService } from './contrat-crm.service';

@Component({
	selector: 'contrat-crm-resiliation',
	templateUrl: './contrat-crm-resiliation.component.html'
})
export class ContratCRMResiliationComponent implements AfterContentChecked {
	/** Contrat CRM **/
	contratCRM: any;

	/** Résultat **/
	result: { contratCRM: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ContratCRMResiliationComponent>,private contratCRMService: ContratCRMService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Résiliation des offres
	 */
	saveContratCRMResiliation() {
		//Résiliation des offres
		this.contratCRMService.doGlobalResiliation(this.contratCRM).subscribe({
			next: (result: Result) => {
				//Vérification de la présence d'une erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du résultat
					this.result = {
						contratCRM: this.contratCRM
					};

					//Fermeture de la modal
					this.bsModalRef.hide();
				}
			}
		})
	}

	/**
	 * Fermeture
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}