<form #contratCRMOffreForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>contratCRM.offre.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': offreField.invalid }">
							<label translate>contratCRM.offre.item</label>
							<div>
								<autocomplete name="offre" type="offre" #offreField="ngModel" [(ngModel)]="lien.offre" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateResiliation.invalid }" *ngIf="lien.idLien">
							<label translate>contratCRM.dateResiliation</label>
							<div class="half">
								<input type="text" class="form-control" name="dateResiliation" #dateResiliation="ngModel" minDate="today" [(ngModel)]="lien.dateResiliation" datePicker [storeDate]="true" format="date"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteLienOffre?.(close);" *ngIf="lien.idLien && deleteLienOffre && (null | right:'creation') && canDelete">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienOffre();" *ngIf="null | right:'creation'" [disabled]="contratCRMOffreForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: contratCRMOffreForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>