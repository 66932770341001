import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Observable,concat } from 'rxjs';
import { first,map,switchMap,take,tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { Action } from 'src/app/domain/action';
import { CHECK_USER_ARTICLE,UPDATE_USER_CONSENTEMENT } from 'src/app/reducers/session';
import { ArticleService } from './article.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { UserService } from 'src/app/components/user/user.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LoginService } from 'src/app/share/login/login.service';
import { AppState } from 'src/app/domain/appstate';

@Injectable()
export class ArticleEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private articleService: ArticleService,private loggedUserService: LoggedUserService,private userService: UserService,private toastrService: ToastrService
			,private translateService: TranslateService,private loginService: LoginService,private store: Store<AppState>) {

	}

	/**
	 * Affichage des articles
	 */
	showArticle$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(CHECK_USER_ARTICLE),
		switchMap((action: Action) => {
			let check: { consentementNeeded: boolean,onboardingNeeded: boolean,alertesNeeded: boolean,nouveautesNeeded: boolean };
			let listeTypesArticle: Array<'CGU' | 'ONBOARDING' | 'STANDARD' | 'NOUVEAUTES' | 'ALERTE'>;

			//Lecture de la session
			check = action.payload as { consentementNeeded: boolean,onboardingNeeded: boolean,alertesNeeded: boolean,nouveautesNeeded: boolean };

			//Définition de la liste des types d'article
			listeTypesArticle = [check.consentementNeeded && 'CGU',check.onboardingNeeded && 'ONBOARDING',check.alertesNeeded && 'ALERTE',check.nouveautesNeeded && 'NOUVEAUTES'].filter(typeArticle => !!typeArticle) as Array<'CGU' | 'ONBOARDING' | 'STANDARD' | 'NOUVEAUTES' | 'ALERTE'>;

			//Affichage des articles de façon successive
			return concat(...listeTypesArticle.map(typeArticle => this.articleService.showTypeArticle(typeArticle).pipe(take(1)))).pipe(
				tap(result => {
					//Vérification de l'utilisateur connecté
					if (this.loggedUserService.getUser()?.idUser && result?.result?.isRead) {
						//Lecture de l'article
						this.userService.readTypeArticle(this.loggedUserService.getUser(),result.article.typeArticle).pipe(first()).subscribe({
							next: result => {
								//Vérification du code d'erreur
								if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
									//Message d'information
									this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

									//Mise à jour du consentement
									this.store.dispatch({
										type: UPDATE_USER_CONSENTEMENT,
										payload: false
									});
								} else
									//Affichage d'un message d'erreur
									this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						});
					} else if (result?.article?.typeArticle == 'CGU' && this.loggedUserService.getUser()?.idUser && result?.result?.isRead === false) {
						//Déconnexion de l'utilisateur
						this.loginService.logout();
					} else if (result?.article?.typeArticle == 'CGU' && this.loggedUserService.getUser()?.idUser && result?.result?.isRead === undefined) {
						//Rechargement complet de l'application
						document.location.reload();
					}
				}),
				map(() => action)
			);
		})
	),{ dispatch: false });
}