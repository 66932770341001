<div class="pull-left lv-avatar">{{ data?.numero?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Comptabilite/loadCompteAuxiliaire/',data.idCompteAuxiliaire]">{{ data?.numero + (data?.libelle ? ' (' + data?.libelle + ')' : '') }}</a>
</div>
<ul class="lv-attrs" *ngIf="data.idCompteAuxiliaire">
	<li *ngIf="data.planComptable"><span translate>comptabilite.compteAuxiliaire.planComptable</span>&#160;:&#160;<span>{{ data.planComptable.libelle + ' (' + data.planComptable.reference + ')' }}</span></li>
	<li *ngIf="data.planComptable"><span translate>comptabilite.compteAuxiliaire.compte</span>&#160;:&#160;<span>{{ data.compte.numero + ' (' + data.compte.libelle + ')' }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idCompteAuxiliaire">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li><a [routerLink]="['/Comptabilite/loadPlanComptable/',data.planComptable.idPlanComptable]"><span translate>comptabilite.actions.accederPlanComptable</span></a></li>
		<li><a [routerLink]="['/Comptabilite/loadCompte/',data.compte.idCompte]"><span translate>comptabilite.actions.accederCompte</span></a></li>
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteCompteAuxiliaire();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>