<div class="row">
	<div class="col-md-12">
		<div class="form-group">
			<div>
				<mat-chip-listbox multiple>
					<mat-chip-option color="primary" *ngFor="let type of listeTypesOptions" (click)="type.hasDetails && toggleTypeOption(type)" (keyup.space)="type.hasDetails && toggleTypeOption(type)" [selected]="type.isSelected" [selectable]="type.hasDetails" [disabled]="!type.hasDetails" [ngClass]="{ 'clickable': type.hasDetails }">{{ type.libelle }}</mat-chip-option>
				</mat-chip-listbox>
			</div>
		</div>
	</div>
</div>
<div class="listview lv-bordered">
	<div class="lv-body m-t-10" *ngIf="listeDetails?.length">
		<display-more [listeItems]="listeDetails | filterBy:filterByTypeCategory" #displayMore>
			<option-list-item *ngFor="let detail of displayMore.getSlice()" [detail]="detail"></option-list-item>
		</display-more>
	</div>
</div>