<div class="card hoverable" *ngIf="!options.isModal">
	<div class="card-header p-0 p-l-20">
		<div>
			<div class="m-r-10 m-t-5">
				<a [routerLink]="[]" *ngIf="!isToday" (click)="goToCurrentDate()"><icon name="arrow_back" class="icon-hc-2x"></icon></a>
			</div>
			<h4 class="card-title">
				<span [title]="options.title">{{ options.title }}</span>
			</h4>
			<div class="actions hovered-item clickable m-t-5">
				<a [routerLink]="" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li *ngFor="let action of getListeVisibleActions(); trackBy: trackByPosition" (click)="action.doAction();"><a [routerLink]="">{{ action.libelle }}</a></li>
				</ul>
				<a [routerLink]="" (click)="maximizeCalendar();"><icon name="fullscreen" class="icon-hc-2x"></icon></a>
			</div>
		</div>
	</div>
	<div class="card-body">
		<ng-template [ngTemplateOutlet]="calendarTemplate"></ng-template>
		<please-wait *ngIf="!calendar" radius="10"></please-wait>
	</div>
</div>
<ng-container *ngIf="options.isModal">
	<ng-template [ngTemplateOutlet]="calendarTemplate"></ng-template>
</ng-container>

<ng-template #calendarTemplate>
	<div class="navigation left m-r-5" (click)="navigatePrevious()"><icon name="chevron_left" class="icon-hc-5x"></icon></div>
	<div class="calendar" style="width: 100%;" [hidden]="!calendar">
		<h4 class="text-center calendar-date">{{ calendarTitle }}</h4>
		<div #container></div>
	</div>
	<div class="navigation right m-l-5" (click)="navigateNext()"><icon name="chevron_right" class="icon-hc-5x"></icon></div>
</ng-template>