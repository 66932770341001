import { Component,EventEmitter,Input,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { v4 } from 'uuid';

import { TypeComparaison } from 'src/app/domain/common/list-view';

@Component({
	selector: 'dashboard-chart-customizer-color',
	templateUrl: './dashboard-chart-customizer-color.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class DashboardChartCustomizerColorComponent {
	/** Couleur par défaut **/
	@Input() isDefault: boolean;

	/** Couleur **/
	@Input() color: any;

	/** Suppression de l'élément **/
	@Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

	/** Identifiant unique **/
	uniqueIdentifier: string;

	/** Liste des types de comparaisons */
	listeTypeComparaisons: Array<TypeComparaison> = [TypeComparaison.GREATER,TypeComparaison.GREATER_EQUAL,TypeComparaison.EQUAL,TypeComparaison.BETWEEN,TypeComparaison.LESS,TypeComparaison.LESS_EQUAL];

	/** Types de comparaison **/
	public TypeComparaison: typeof TypeComparaison = TypeComparaison;

	/**
	 * Constructeur
	 */
	constructor() {
		//Création de l'identifiant unique
		this.uniqueIdentifier = v4().toString();
	}
}