<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon name="warning" *ngIf="data.anomalie"></icon>
	<icon name="add_shopping_cart" *ngIf="!data.anomalie"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" *ngIf="null | right:'consultation'" (click)="consultGrilleAttributionModele()">
			<span>{{ data.modele?.marque?.libelle + ' ' + data.modele?.libelle }}</span><span *ngIf="data.modele?.finition">&#160;{{ data.modele?.finition }}</span><span *ngIf="data.modele?.reference">&#160;({{ data.modele.reference }})</span>
		</a>
		<span class="bold" *ngIf="!(null | right:'consultation')">
			<span>{{ data.modele?.marque?.libelle + ' ' + data.modele?.libelle }}</span><span *ngIf="data.modele?.finition">&#160;{{ data.modele?.finition }}</span><span *ngIf="data.modele?.reference">&#160;({{ data.modele.reference }})</span>
		</span>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.modele?.configuration && data.modele?.information?.genre?.modeAffichagePrix == 'HT'"><span translate>vehicule.grilleAttribution.modele.configuration.prixHT</span>&#160;:&#160;<span>{{ computeMontantForModele() | currency:'.2-2':data.modele?.information?.commercialisationPrix?.devise }}</span></li>
		<li *ngIf="data.modele?.configuration && data.modele?.information?.genre?.modeAffichagePrix == 'TTC'"><span translate>vehicule.grilleAttribution.modele.configuration.prixTTC</span>&#160;:&#160;<span>{{ computeMontantForModele() | currency:'.2-2':data.modele?.information?.commercialisationPrix?.devise }}</span></li>

		<li *ngIf="data.modele?.information?.commercialisationPrix">
			<span translate>vehicule.grilleAttribution.modele.commercialisation.title</span>&#160;:&#160;
			<span *ngIf="data.modele?.information?.commercialisationPrix?.commercialisationEnCours"><span translate>vehicule.grilleAttribution.modele.commercialisation.enCours</span></span>
			<span *ngIf="!data.modele?.information?.commercialisationPrix?.commercialisationEnCours"><span translate [translateParams]="{ date: data.modele?.information?.commercialisationPrix?.dateFin | date:'shortDate' }">vehicule.grilleAttribution.modele.commercialisation.terminee</span></span>
		</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation') && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="showConfiguration()"><span translate>vehicule.grilleAttribution.modele.configurer</span></a></li>
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteModele()"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>