import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './vehicule-facture-detail-list-item.component.html'
})
export class VehiculeFactureDetailListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du détail de la facture
	 */
	showFactureDetail() {
		//Redirection vers la ligne de facture
		this.layoutService.goToByState('listeFactureDetails-detail',{
			routeParams: {
				idDetail: this.data.idDetail
			},
			withGoBack: true
		});
	}

	/**
	 * Accès à la facture
	 */
	goToFacture() {
		//Redirection vers la facture
		this.layoutService.goToByState('listeFactures-load',{
			routeParams: {
				idFacture: this.data.facture.idFacture,
				objetLien: this.extraOptions.vehicule,
				source: 'VEHICULE'
			},
			withGoBack: true
		});
	}
}