import { Component,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ModeleOptionPackListItemComponent } from './modele-option-pack-list-item.component';

@Component({
	templateUrl: './modele-option-pack-list.component.html'
})
export class ModeleOptionPackListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ModeleOptionPackListItemComponent>;

	/** Option **/
	option: any;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ModeleOptionPackListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreModelePackDetails`,
			component: ModeleOptionPackListItemComponent,
			isLocal: true,
			extraOptions: {
				option: this.option
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'option.idOption',
				valeur: this.option.idOption,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			}],
			defaultOrder: 'libelle'
		});
	}
}