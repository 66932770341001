<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idRevendeur">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data}">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idRevendeur">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.email"><span translate>revendeur.email</span>&#160;:&#160;<span>{{ data.email }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idRevendeur && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="!userOrigine && (TypeDroit.ADMIN_ENVIRONNEMENT | right:'consultation') && data.idRevendeur != user.tenant?.revendeur?.idRevendeur"><a [routerLink]="[]" (click)="loginAsAdminRevendeur();"><span translate>actions.seConnecter</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteRevendeur();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<revendeur [revendeur]="data" (onObjectChange)="onObjectChange($event)"></revendeur>
</div>