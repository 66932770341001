import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { switchMap,tap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { WorkflowService } from './workflow.service';

@Component({
	selector: 'workflow-edit',
	templateUrl: './workflow-edit.component.html'
})
export class WorkflowEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() workflow: any;

	/** Suppression de l'objet **/
	@Input() deleteWorkflow: () => void;

	/** Liste des entités **/
	@Input() listeEntites$: Observable<any>;

	/** Copie de l'objet **/
	private savedWorkflow: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private workflowService: WorkflowService,private loggedUserService: LoggedUserService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de l'objet
		this.savedWorkflow = cloneDeep(this.workflow);
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveWorkflow(workflow: any = cloneDeep(this.workflow)) {
		//Réalisation des traitements pré-enregistrement et enregistrement
		of(true).pipe(
			switchMap(() => {
				//Vérification de la désactivation
				if (workflow.idWorkflow && this.savedWorkflow.actif && !workflow.actif) {
					//Affichage d'une pop-up de confirmation
					return this.confirmService.showConfirm(this.translateService.instant('workflow.confirmation.desactivation'),{ actionColor: 'primary' }).pipe(
						tap(isConfirmed => {
							//Vérification de la confirmation
							if (!isConfirmed)
								//Réactivation du workflow
								workflow.actif = true;
						})
					);
				} else
					//Poursuite du traitement
					return of(true);
			}),
			switchMap(() => {
				//Vérification du changement d'entité
				if (workflow.idWorkflow && this.savedWorkflow.entite && workflow.entite != this.savedWorkflow.entite) {
					//Affichage d'une pop-up de confirmation
					return this.confirmService.showConfirm(this.translateService.instant('workflow.confirmation.changementEntite'),{ actionColor: 'primary' }).pipe(
						tap(isConfirmed => {
							//Vérification de la confirmation
							if (!isConfirmed)
								//Réinitialisation de l'entité
								workflow.entite = this.savedWorkflow.entite;
							else
								//Suppression de la liste des statuts
								workflow.listeStatuts = null;
						})
					);
				} else
					//Poursuite du traitement
					return of(true);
			}),
			switchMap(() => this.workflowService.saveWorkflow(workflow))
		).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.workflow,result.data?.workflow);

					//Fermeture du formulaire
					this.close({ workflow: this.workflow,statutInformation: result.data?.statutInformation });
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du reference
							listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}