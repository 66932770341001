import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { filter,map,take } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { ConfigurationComponent } from './configuration.component';

@Injectable()
export class ConfigurationService {
	/** Liste des filtres sur les prix **/
	private readonly listeTypesPrix: Array<string> = ['PAYANTE','SERIE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService,private messagingService: MessagingService) { }

	/**
	 * Chargement de la configuration
	 */
	public loadConfiguration(type: 'VEHICULE_MODELE' | 'VEHICULE' | 'DEMANDE_VEHICULE' | 'GRILLE_ATTRIBUTION',idModele: number,idEntite: number): Observable<Result> {
		//Chargement de la configuration
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadVehiculeModeleConfiguration/${idModele}/${type}/${idEntite}`,null);
	}

	/**
	 * Enregistrement de la configuration
	 */
	public saveConfiguration(configuration: any): Observable<Result> {
		//Enregistrement de la configuration
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveVehiculeModeleConfiguration`,configuration);
	}

	/**
	 * Ouverture de la popup de configuration
	 */
	public showConfiguration(options: { type: 'VEHICULE_MODELE' | 'VEHICULE' | 'DEMANDE_VEHICULE' | 'GRILLE_ATTRIBUTION',idModele: number,idEntite: number,isEdition?: boolean }): Observable<any> {
		let bsModalRef: BsModalRef<ConfigurationComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ConfigurationComponent,{
			initialState: {
				...options
			},
			class: 'modal-max'
		});

		//Retour de la popup
		return bsModalRef.onHidden.pipe(
			take(1),
			map(() => bsModalRef.content.savedConfiguration),
			filter(savedConfiguration => !!savedConfiguration)
		);
	}

	/**
	 * Récupération de la liste des types de prix
	 */
	public getListeTypesPrix(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de prix
		return this.listeTypesPrix.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.modele.configuration.typePrix.${code}`)
		}));
	}

	/**
	 * Initialisation de la configuration
	 */
	public initConfiguration(type: 'VEHICULE_MODELE' | 'VEHICULE' | 'DEMANDE_VEHICULE' | 'GRILLE_ATTRIBUTION',idModele: number,idEntite: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			entryPoint: `/controller/VehiculeReferentiel/initVehiculeModeleConfiguration/${type}/${idEntite}`,
			params: [idModele]
		}).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}
}