<form #etatLieuxPartListForm="ngForm"autocomplete="off" class="etat-lieux">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.etatLieux.title</h4>
	</div>
	<div class="modal-body preview-template" *ngIf="selectedZone">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="row">
				<div class="col-md-6 el-container p-t-20">
					<div class="el-vehicule-container">
						<div class="el-box-container" *ngFor="let part of listeParts; let index = index;">
							<div class="el-half-box clickable" *ngFor="let zone of part.listeZones; trackBy: trackByPosition; let i = index" [ngClass]="{ 'selected': zone.code == selectedZone.code }" (click)="selectZone(zone);" [tooltip]="'vehicule.etatLieux.attachment.type.' + zone.code | translate" [placement]="i == 0 ? 'left' : 'right'">
								<div class="el-icon-container">
									<icon name="photo_camera" class="icon-hc-3x"></icon>
									<div class="el-counter" [ngClass]="{ 'has-error': !zone.listeLinks.length }"><span>{{ zone.listeLinks.length }}</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 p-20">
					<h4><span [translate]="'vehicule.etatLieux.attachment.type.' + selectedZone.code"></span>&#160;(<span>{{ selectedZone.listeLinks.length }}</span>)</h4>
					<div class="p-t-20 p-b-20">
						<div *ngFor="let part of listeParts; trackBy: trackByPosition">
							<div *ngFor="let zone of part.listeZones; trackBy: trackByPosition">
								<div *ngIf="zone.code == selectedZone.code">
									<attachment #attachment="attachment" [options]="attachmentOptions"></attachment>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" *ngIf="!!selectedZone.listeLinks[navigationAttachment.currentIndex || 0]">
						<label><span translate>vehicule.etatLieux.listeParts.remarque</span></label>
						<div>
							<textarea class="form-control" rows="3" name="remarque" [placeholder]="'vehicule.etatLieux.listeParts.remarqueHint' | translate" [(ngModel)]="selectedZone.mapPartsByIdBlob[selectedZone.listeLinks[navigationAttachment.currentIndex || 0].attachment.idBlob].remarque"></textarea>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveEtatLieux();" *ngIf="!isReadOnly && (TypeDroit.ETAT_LIEUX | right:'creation')" [disabled]="etatLieuxPartListForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>