<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showCotation()">
			<span translate [translateParams]="{ numero: data.demandeCotation.idDemandeCotation }">demandeCotation.liste.item</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>demandeVehicule.cotation.statut.item</span>&#160;:&#160;<span [translate]="'demandeCotation.statut.' + data.statut"></span></li>
		<li *ngIf="data.statut == 'SOLDEE' && data.extension?.propositionRetenue?.typeFinancement"><span translate>demandeVehicule.cotation.typeFinancement</span>&#160;:&#160;<span>{{ data.extension.propositionRetenue.typeFinancement.libelle }}</span></li>
		<li *ngIf="data.statut == 'SOLDEE' && data.extension?.propositionRetenue">
			<span><span translate>demandeVehicule.cotation.loiRoulage</span>&#160;:&#160;</span>
			<span *ngIf="data.extension.propositionRetenue.duree && data.extension.propositionRetenue.distance"><span>{{ data.extension.propositionRetenue.duree }}</span>&#160;<span translate>duree.mois</span>/<span>{{ (data.extension.propositionRetenue.distance | number) + ' ' + user.unite.libelle }}</span></span>
			<span *ngIf="!data.extension.propositionRetenue.duree || !data.extension.propositionRetenue.distance"><span translate>demandeVehicule.cotation.nonComuniquee</span></span>
		</li>
		<li *ngIf="data.statut == 'SOLDEE' && data.extension?.propositionRetenue?.loyerMensuel">
			<span translate>demandeVehicule.cotation.loyerMensuel</span>&#160;:&#160;<span>{{ data.extension.propositionRetenue.loyerMensuel | currency:'.2-2':data.extension.propositionRetenue.devise }}</span>&#160;(<span>{{ ('contrat.typeAffichagePrix.short.' + data.extension.propositionRetenue.typeAffichagePrix) | translate }}</span>)
		</li>
		<li *ngIf="data.statut != 'SOLDEE' && data.typeFinancement"><span translate>demandeVehicule.cotation.typeFinancement</span>&#160;:&#160;<span>{{ data.typeFinancement.libelle }}</span></li>
		<li *ngIf="data.statut != 'SOLDEE'">
			<span><span translate>demandeVehicule.cotation.loiRoulage</span>&#160;:&#160;</span>
			<span *ngIf="data.duree && data.distance"><span>{{ data.duree }}</span>&#160;<span translate>duree.mois</span>/<span>{{ (data.distance | number) + ' ' + user.unite.libelle }}</span></span>
			<span *ngIf="!data.duree || !data.distance"><span translate>demandeVehicule.cotation.nonComuniquee</span></span>
		</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="consultCotation();"><span translate>demandeVehicule.cotation.consulter</span></a></li>
			<li *ngIf="(TypeDroit.DEMANDE_COTATION | right:'creation') && cotationService.checkRightCotation('DELETE',data)"><a [routerLink]="[]" (click)="deleteCotation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(TypeDroit.DEMANDE_COTATION | right:'creation') && cotationService.checkRightCotation('CANCEL',data)"><a [routerLink]="[]" (click)="cancelCotation();"><span translate>actions.annuler</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>