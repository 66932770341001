import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'taxe-edit',
	templateUrl: './taxe-edit.component.html'
})
export class TaxeEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() taxe: any;

	/** Suppression de l'objet **/
	@Input() deleteTaxe: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveTaxe: (taxe: any,close: Function) => Function;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}
}