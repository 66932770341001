import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AffectationHistoryListItemComponent } from './affectation-history-list-item.component';

@Component({
	templateUrl: './affectation-history-list.component.html'
})
export class AffectationHistoryListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AffectationHistoryListItemComponent>;

	/** Véhicule **/
	vehicule: any;

	/** Affectation sélectionnée **/
	selectedAffectation: any = null;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<AffectationHistoryListItemComponent>,public translateService: TranslateService) {
		//Binding des méthodes
		this.selectAffectation = this.selectAffectation.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AffectationHistoryListItemComponent>({
			uri: '/controller/Vehicule/filtreAffectations',
			component: AffectationHistoryListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'user.nom,user.prenom,user.matricule',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule?.idVehicule,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			}],
			defaultOrder: '-dateDebut',
			extraOptions: {
				selectAffectation: this.selectAffectation
			}
		});
	}

	/**
	 * Sélection d'une affectation
	 */
	selectAffectation(affectation: any) {
		//Sélection de l'affectation
		this.selectedAffectation = affectation;

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}