import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './lot-comptable-check-charge-list-item.component.html'
})
export class LotComptableCheckChargeListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}