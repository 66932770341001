import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'chart-data-list',
	templateUrl: './chart-data-list.component.html'
})
export class ChartDataListComponent extends PageContentItem {
	/** Graphique **/
	@Input() chart: any;

	/** Fonction d'édition des champs **/
	@Input() addData: () => void;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}
