import { EventEmitter,Injectable } from '@angular/core';

import { Droit } from 'src/app/domain/droit/droit';
import { TypeDroit,TypeGroupe,TypeRestriction } from 'src/app/domain/security/right';

@Injectable({
	providedIn: 'root'
})
export class DroitService {
	/** Notification de modification du droit global **/
	public onRootRightChange: EventEmitter<{ droit: Droit,type: 'consultation' | 'creation' | 'suppression' }> = new EventEmitter<{ droit: Droit,type: 'consultation' | 'creation' | 'suppression' }>();

	/** Notification de modification de groupe */
	public onEditGroupe: EventEmitter<TypeGroupe> = new EventEmitter<TypeGroupe>();

	/** Mapping des restrictions **/
	private mapRestrictions: { [typeDroit: string]: { listeRestrictions: Array<TypeRestriction> }} = {
		[TypeDroit.ADMIN_VEHICULE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.ADMIN_UTILISATEUR]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE]
		},
		[TypeDroit.VEHICULE_CONTRAT_FINANCEMENT]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_CONDUCTEUR]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_CONTRAVENTION]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_SINISTRE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.FACTURE]: {
			listeRestrictions: [TypeRestriction.SOCIETE]
		},
		[TypeDroit.ENTRETIEN]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.ADMIN_PLAN_COMPTABLE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE]
		},
		[TypeDroit.VEHICULE_RELEVE_ENERGIE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_COMPTEUR]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.ADMIN_ANALYTIQUE_SERVICE]: {
			listeRestrictions: [TypeRestriction.SOCIETE]
		},
		[TypeDroit.DEMANDE_VEHICULE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.FACTURE_DETAIL]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_AMENAGEMENT]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.CONNECTEUR]: {
			listeRestrictions: [TypeRestriction.SOCIETE]
		},
		[TypeDroit.ADMIN_AIDE_ARTICLE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.CLIENT]
		},
		[TypeDroit.VEHICULE_AFFECTATION_ECHEANCE]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.VEHICULE_RESERVATION]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.ETAT_LIEUX]: {
			listeRestrictions: [TypeRestriction.SOCIETE,TypeRestriction.SERVICE,TypeRestriction.VEHICULE_POOL]
		},
		[TypeDroit.ADMIN_TENANT]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		},
		[TypeDroit.ADMIN_IMPORT]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		},
		[TypeDroit.ADMIN_UPGRADER]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		},
		[TypeDroit.ADMIN_ENVIRONNEMENT]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		},
		[TypeDroit.ADMIN_AUTHENTIFICATION]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		},
		[TypeDroit.ADMIN_TENANT_AUTORISATION]: {
			listeRestrictions: [TypeRestriction.CLIENT]
		}
	};

	/**
	 * Récupération de la map des restrictions
	 */
	public getMapRestrictions(): { [typeDroit: string]: { listeRestrictions: Array<TypeRestriction> }} {
		//Retour de la map des restrictions
		return this.mapRestrictions;
	}

	/**
	 * Récupération des restrictions pour un type de droit
	 */
	public getListeRestrictions(typeDroit: TypeDroit): Array<TypeRestriction> {
		let listeRestrictions: Array<TypeRestriction> = [];

		//Vérification du type droit
		if (typeDroit && this.mapRestrictions[typeDroit])
			//Récupération de la liste des restrictions pour un type droit
			listeRestrictions = this.mapRestrictions[typeDroit].listeRestrictions;

		return listeRestrictions;
	}
}