<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idCompteur">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }" [translate]="'vehicule.releveCompteur.liste.avatar.'+(data?.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
		<div class="lv-title">
			<span class="m-l-10" (click)="data.isDisplayed = !data.isDisplayed">
				<a [routerLink]="[]">
					<span *ngIf="data.vehicule">{{ data.vehicule | display:'vehicule' }}</span>
					<span *ngIf="!data.vehicule" [translate]="'vehicule.releveCompteur.vehiculeNonCommunique'"></span>
				</a>
			</span>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idCompteur">
		<li><span translate>vehicule.releveCompteur.dateReleve</span>&#160;:&#160;{{ data.dateReleve | date:'short' }}</li>
		<li><span translate>vehicule.releveCompteur.releveCompteur</span>&#160;:&#160;{{ data.vehicule?.uniteUsage == 'DISTANCE' ? (data.releveCompteur | number:'.2-2') : (data.releveHoraire | number) }}&#160;{{ data.vehicule?.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || extraOptions.user?.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</li>
		<li><span translate>vehicule.releveCompteur.valeurReference.item</span>&#160;:&#160;<span [translate]="'common.' + (data.valeurReference ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.equipement"><span translate>vehicule.releveCompteur.equipement</span>&#160;:&#160;{{ data.equipement.reference }}</li>
		<li *ngIf="data.extension?.userAffecte"><span translate>vehicule.releveCompteur.userAffecte</span>&#160;:&#160;{{ data.extension.userAffecte.prenom + ' ' + data.extension.userAffecte.nom + ' (' + data.extension.userAffecte.matricule + (data.extension.userAffecte.societe?.libelle ? ' - ' + data.extension.userAffecte.societe?.libelle : '') + ')' }}</li>
		<li *ngIf="data.valeurVerrouillee"><span translate>vehicule.releveCompteur.valeurVerrouillee</span>&#160;:&#160;<span [translate]="'common.' + (data.valeurVerrouillee ? 'oui' : 'non')"></span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="data?.idCompteur && !liste.nbSelectedItems && extraOptions.user?.type != 'VIRTUAL'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression') && !data.readOnly"><a [routerLink]="[]" (click)="deleteReleveCompteur();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="(TypeDroit.FACTURE | right:'consultation') && data.typeOrigine == 'FACTURE'"><a [routerLink]="[]" (click)="goToFacture();" translate>vehicule.releveCompteur.actions.accederFacture</a></li>
			<li *ngIf="(TypeDroit.ETAT_LIEUX | right:'consultation') && data.typeOrigine == 'ETAT_LIEUX'"><a [routerLink]="[]" (click)="goToEtatLieux();" translate>vehicule.releveCompteur.actions.accederEtatLieux</a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<releve-compteur [releveCompteur]="data" (onObjectChange)="onObjectChange($event)"></releve-compteur>
</div>