<form #imputationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" *ngIf="isLoaded">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>user.elements.imputation.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
							<label translate>user.societe</label>
							<div>
								<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="imputation.societe" (ngModelChange)="imputation.service = null" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
							<label translate>user.serviceAffectation</label>
							<div>
								<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="imputation.service" [filter]="{ idSociete: imputation?.societe?.idService }" [disabled]="!imputation?.societe || imputation?.societe?.extension?.nbSousServices == 0" [required]="imputation?.societe?.extension?.nbSousServices > 0"></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
							<label translate>user.imputation.dateApplication</label>
							<div>
								<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="imputation.dateApplication" datePicker format="datetime" [required]="firstImputation?.idImputation != imputation?.idImputation"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteImputation(imputation,close);" *ngIf="deleteImputation && imputation?.idImputation && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveImputation();" *ngIf="null | right:'creation'" [disabled]="imputationForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: imputationForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>