<div class="modal-header">
	<h4 class="modal-title" translate>structureExport.rubrique.import.title</h4>
</div>
<div class="modal-body">
	<form #structureExportRubriqueImportForm="ngForm" autocomplete="off">
		<div class="alert alert-danger" *ngIf="listeErreurs?.length">
			<icon name="warning"></icon>
			<div>
				<strong translate>structureExport.rubrique.import.anomalie.title</strong>
				<ul>
					<li *ngFor="let erreur of listeErreurs">{{ erreur }}</li>
				</ul>
			</div>
		</div>
		<div class="form-group">
			<label translate>structureExport.rubrique.import.libelle</label>
			<div>
				<textarea name="libelles" #libelles="ngModel" [(ngModel)]="importRubrique.libelles" (change)="onLibellesChange()" class="form-control" rows="5" required></textarea>
			</div>
		</div>
		<div class="form-group">
			<label translate>structureExport.rubrique.import.valeur</label>
			<div>
				<textarea name="values" #values="ngModel" [(ngModel)]="importRubrique.values" (change)="onValeursChange()" class="form-control" rows="5" required></textarea>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="saveImportRubriques()" [disabled]="structureExportRubriqueImportForm.invalid">
		<span translate>actions.continuer</span>
	</button>
</div>