import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,first } from 'rxjs/operators';
import moment from 'moment';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ConducteurService } from 'src/app/components/vehicule/conducteur/conducteur.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConducteurAttestationPermisComponent } from './conducteur-attestation-permis.component';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';

@Component({
	templateUrl: './conducteur-attestation-permis-list.component.html'
})
export class ConducteurAttestationPermisListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Résumé **/
	@Input() resume: { nbAttestationsPermis: number };

	/** Mise à jour du conducteur **/
	@Input() onConducteurUpdated: (conducteur: any) => void;

	/** Date courante **/
	today: moment.Moment = moment.utc();

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private conducteurService: ConducteurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private pageContentService: PageContentService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Tri de la liste des attestations par date décroissante
		this.conducteur.listeAttestationsPermis?.sort((a,b) => a.date < b.date ? 1 : -1);

		//Sélection de l'utilisateur connecté
		this.store.select(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Vérification de l'action externe
			if (this.user.type == 'VIRTUAL' && this.user.externalAction?.idEntite)
				//Création d'une attestation de permis de conduire
				setTimeout(() => this.showAttestationPermis());
		});
	}

	/**
	 * Affichage d'une attestation de permis de conduire
	 */
	showAttestationPermis(attestation?: any) {
		//Vérification de la création (cas d'une action externe)
		if (!attestation)
			//Récupération de l'attestation déjà validée le même jour
			attestation = this.conducteur.listeAttestationsPermis.find(a => this.today.isSame(a.date,'day'));

		//Affichage du détail
		this.pageContentService.open(ConducteurAttestationPermisComponent,{
			data: {
				selectedAttestation: Object.assign({},attestation || {},{
					conducteur: this.conducteur
				}),
				deleteAttestation: this.deleteAttestationPermis.bind(this),
				user: this.user
			}
		},'sub').subscribe();
	}

	/**
	 * Suppression d'une attestation de permis de conduire
	 */
	deleteAttestationPermis(attestation: any,close: Function = null) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.conducteurService.deleteAttestationPermis(attestation))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Mise à jour des compteurs
				this.resume.nbAttestationsPermis--;

				//Suppression de l'attestation de la liste
				this.conducteur.listeAttestationsPermis.splice(this.conducteur.listeAttestationsPermis.findIndex(a => a.idAttestation == attestation.idAttestation),1);

				//Mise à jour du conducteur
				this.onConducteurUpdated(this.conducteur);

				//Fermeture du formulaire (si nécessaire)
				close?.();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}