import { HttpErrorResponse,HttpEvent,HttpHandler,HttpInterceptor,HttpRequest,HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Injectable()
export class ConcurrentAccessInterceptor implements HttpInterceptor {
	/**
	 * Constructeur
	 */
	constructor(private router: Router,private confirmService: ConfirmService,private translateService: TranslateService) { }

	/**
	 * Interception de la réponse HTTP et gestion de l'erreur 409
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification du code http 409
		return next.handle(request).pipe(catchError(error => {
			//Vérification d'un code HTTP 409
			if (error instanceof HttpErrorResponse && error.status == HttpStatusCode.Conflict) {
				//Affichage d'un message de confirmation
				this.confirmService.showConfirm(this.translateService.instant('actions.enregistrement.confirmationConcurrentAccess'),{ actionColor: 'primary' }).subscribe({
					next: async isConfirmed => {
						let url: string;

						//Récupération de l'URL
						url = this.router.url;

						//Vérification de la confirmation
						if (isConfirmed) {
							//Navigation vers la première page pour détruire le composant
							await this.router.navigate(['/'],{ skipLocationChange: true })

							//Navigation vers la page à recharger
							this.router.navigate([url]);
						}
					}
				});
			}

			//Retour de la réponse en erreur
			return throwError(error);
		}));
	}
}
