import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeEntretienService } from './type-entretien.service';

@Component({
	selector: 'type-entretien',
	templateUrl: './type-entretien.component.html'
})
export class TypeEntretienComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() typeEntretien: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private typeEntretienService: TypeEntretienService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du type d'entretien
		this.typeEntretien = Object.assign({},this.typeEntretien);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du type d'entretien
	 */
	saveTypeEntretien() {
		//Enregistrement du type d'entretien
		this.typeEntretienService.saveTypeEntretien(this.typeEntretien).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du type d'entretien
					this.onObjectChange.emit(result.data.typeEntretien);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: this.translateService.instant('actions.doublon.reference')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du type d'entretien
	 */
	deleteTypeEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du type d'entretien
					this.typeEntretienService.deleteTypeEntretien(this.typeEntretien).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du type d'entretien
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}