import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from '../compte/compte.service';

@Component({
	templateUrl: './compte-auxiliaire-user-list-item.component.html'
})
export class CompteAuxiliaireUserListItemComponent extends ListViewItem<any> {
	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private layoutService: LayoutService) {
		//Héritage
		super();

		//Binding
		this.deleteLienUser = this.deleteLienUser.bind(this)
	}

	/**
	 * Suppression de l'objet
	 */
	deleteLienUser(lienUser: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.compteService.deleteLienCompteDetail(lienUser).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);

								//Fermeture du formulaire d'édition
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Redirection vers l'élément
	 */
	goToUser() {
		//Navigation vers l'élément
		this.layoutService.goToByState('listeUsers-user',{
			routeParams: {
				idUser: this.data.user.idUser
			},
			withGoBack: true
		});
	}
}