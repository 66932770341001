<div class="pull-left lv-avatar">{{ data?.typeFinancement?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addFinancement?.(data,deleteFinancement)">{{ data?.fournisseur?.libelle + (data.typeFinancement.mode == 'GRILLE_FLUIDITE' ? ' - ' + ('vehicule.grilleAttribution.modele.financement.grilleFluidite.item' | translate) : '') }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idFinancement">
		<li *ngIf="data.typeFinancement?.mode != 'ACQUISITION' && data?.typeFinancement?.mode != 'GRILLE_FLUIDITE'"><span translate>vehicule.grilleAttribution.modele.financement.loiRoulage</span>&#160;:&#160;<span>{{ data?.duree + ' ' + ('duree.mois' | translate ) + '/' + (data?.distance | number) + ' ' + user?.unite?.libelle }}</span></li>
		<li *ngIf="data.typeFinancement?.mode != 'ACQUISITION' && data?.typeFinancement?.mode != 'GRILLE_FLUIDITE'"><span translate>vehicule.grilleAttribution.modele.financement.loyerMensuel</span>&#160;:&#160;<span>{{ (data?.loyerMensuel | currency:data?.devise) + ' ' + (('vehicule.grilleAttribution.modele.modeAffichagePrix.' + data?.typeAffichagePrix + '.code') | translate) }}</span></li>
		<li *ngIf="data.typeFinancement?.mode == 'ACQUISITION'"><span translate>vehicule.grilleAttribution.modele.financement.prixAchat</span>&#160;:&#160;<span>{{ (data?.prixAchat | currency:data?.devise) }}</span></li>
		<li *ngIf="data.dateDebut || data.dateFin"><span translate>vehicule.grilleAttribution.modele.financement.periodeValidite</span>&#160;:&#160;<span>{{ (data.dateDebut ? (data.dateDebut | date:'shortDate') : ('common.nonDefini' | translate)) + ' - ' + (data.dateFin ? (data.dateFin | date:'shortDate') : ('common.nonDefini' | translate)) }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idFinancement && (data?.grilleFluidite && data?.typeFinancement?.mode == 'GRILLE_FLUIDITE' && (null | right:'consultation') || (TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression'))">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data?.grilleFluidite && data?.typeFinancement?.mode == 'GRILLE_FLUIDITE' && (null | right:'consultation')"><a [routerLink]="[]" (click)="showGrilleFluidite();"><span translate>vehicule.grilleAttribution.modele.financement.actions.consulterGrilleFluidite</span></a></li>
			<li *ngIf="TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression'"><a [routerLink]="[]" (click)="deleteFinancement();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>