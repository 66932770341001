import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AffectationModule } from 'src/app/components/vehicule/affectation/affectation.module';
import { ConducteurListItemComponent } from './conducteur-list-item.component';
import { ConducteurListComponent } from './conducteur-list.component';
import { PermisConduireComponent } from './permis-conduire/permis-conduire.component';
import { ConducteurService } from './conducteur.service';
import { ConducteurAddComponent } from './conducteur-add.component';
import { ConducteurComponent } from './conducteur.component';
import { ConducteurEditComponent } from './conducteur-edit.component';
import { ConducteurAffectationListComponent } from './conducteur-affectation-list.component';
import { ConducteurAffectationListItemComponent } from './conducteur-affectation-list-item.component';
import { ConducteurAffectationEditComponent } from './conducteur-affectation-edit.component';
import { ConducteurAttestationPermisListComponent } from './conducteur-attestation-permis-list.component';
import { ConducteurAttestationPermisComponent } from './conducteur-attestation-permis.component';
import { ConducteurSinistreEditComponent } from './conducteur-sinistre-edit.component';
import { ConducteurSinistreListComponent } from './conducteur-sinistre-list.component';
import { ConducteurSinistreListItemComponent } from './conducteur-sinistre-list-item.component';
import { SinistreModule } from 'src/app/components/vehicule/sinistre/sinistre.module';
import { ConducteurInfractionListComponent } from './conducteur-infraction-list.component';
import { ConducteurInfractionListItemComponent } from './conducteur-infraction-list-item.component';
import { ConducteurEquipementListComponent } from './conducteur-equipement-list.component';
import { ConducteurEquipementListItemComponent } from './conducteur-equipement-list-item.component';
import { ConducteurDemandeVehiculeListComponent } from './conducteur-demande-vehicule-list.component';
import { ConducteurDemandeVehiculeListItemComponent } from './conducteur-demande-vehicule-list-item.component';
import { ConducteurEntretienListComponent } from './conducteur-entretien-list.component';
import { ConducteurEntretienListItemComponent } from './conducteur-entretien-list-item.component';
import { ConducteurEntretienEditComponent } from './conducteur-entretien-edit.component';
import { EntretienModule } from 'src/app/components/vehicule/entretien/entretien.module';
import { ConducteurReleveEnergieListComponent } from './conducteur-releve-energie-list.component';
import { ConducteurReleveEnergieListItemComponent } from './conducteur-releve-energie-list-item.component';
import { ConducteurReleveEnergieEditComponent } from './conducteur-releve-energie-edit.component';
import { ReleveEnergieModule } from 'src/app/components/vehicule/releve-energie/releve-energie.module';
import { ConducteurReleveCompteurListComponent } from './conducteur-releve-compteur-list.component';
import { ConducteurReleveCompteurListItemComponent } from './conducteur-releve-compteur-list-item.component';
import { ConducteurReleveCompteurEditComponent } from './conducteur-releve-compteur-edit.component';
import { ReleveCompteurModule } from 'src/app/components/vehicule/releve-compteur/releve-compteur.module';
import { ConducteurEtatLieuxListComponent } from './conducteur-etat-lieux-list.component';
import { ConducteurEtatLieuxListItemComponent } from './conducteur-etat-lieux-list-item.component';
import { ConducteurEtatLieuxEditComponent } from './conducteur-etat-lieux-edit.component';
import { EtatLieuxModule } from '../etat-lieux/etat-lieux.module';

@NgModule({
	imports: [ShareModule,AffectationModule,SinistreModule,EntretienModule,ReleveEnergieModule,ReleveCompteurModule,EtatLieuxModule],
	declarations: [ConducteurListComponent,ConducteurListItemComponent,PermisConduireComponent,ConducteurAddComponent,ConducteurComponent,ConducteurEditComponent
		,ConducteurAffectationListComponent,ConducteurAffectationListItemComponent,ConducteurAffectationEditComponent,ConducteurAttestationPermisListComponent,ConducteurAttestationPermisComponent
		,ConducteurSinistreListComponent,ConducteurSinistreListItemComponent,ConducteurSinistreEditComponent,ConducteurInfractionListComponent,ConducteurInfractionListItemComponent
		,ConducteurEquipementListComponent,ConducteurEquipementListItemComponent,ConducteurDemandeVehiculeListComponent,ConducteurDemandeVehiculeListItemComponent
		,ConducteurEntretienListComponent,ConducteurEntretienListItemComponent,ConducteurEntretienEditComponent,ConducteurReleveEnergieListComponent,ConducteurReleveEnergieListItemComponent,ConducteurReleveEnergieEditComponent
		,ConducteurReleveCompteurListComponent,ConducteurReleveCompteurListItemComponent,ConducteurReleveCompteurEditComponent,ConducteurEtatLieuxListComponent,ConducteurEtatLieuxListItemComponent,ConducteurEtatLieuxEditComponent
	],
	providers: [ConducteurService]
})
export class ConducteurModule { }