import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { SelectPickerService } from 'src/app/share/components/select-picker/select-picker.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DeviceListComponent } from './device/device-list.component';
import { UserService } from './user.service';

@Component({
	selector: 'user-profil-edit',
	templateUrl: './user-profil-edit.component.html'
})
export class UserProfilEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() lienProfil: any;

	/** Liste des profils **/
	@Input() listeProfils: Array<any>;

	/** Compte mobile **/
	@Input() userAccountMobile: any;

	/** Suppression du profil **/
	@Input() deleteLienProfil: (lienProfil: any,close: Function,index: number) => void;

	/** Détection d'un changement d'email/login **/
	@Input() onLoginChange: (user: any) => void;

	/** Index **/
	@Input() index: number;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService,public selectPickerService: SelectPickerService,private bsModalService: BsModalService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	saveLienProfil() {
		//Enregistrement du lien user/profil
		this.userService.saveLienProfil(this.lienProfil).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.lienProfil,result.data?.userProfil);

					//Définition de l'utilisateur
					Object.assign(this.lienProfil.user,result.data?.user);

					//Fermeture de la fenêtre
					this.close({ lienProfil: this.lienProfil });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la société
					if (result.data.doublon & Math.pow(2,0)) {
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.matricule'));
						listeDoublons.push(this.translateService.instant('actions.doublon.societe'));
					}

					//Vérification de l'identifiant
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.login'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Affichage de la liste des périphériques de l'utilisateur
	 */
	public showListeDevices() {
		//Affichage de la popup
		this.bsModalService.show(DeviceListComponent,{
			initialState: {
				lienProfil: cloneDeep(this.lienProfil),
				userAccountMobile: this.userAccountMobile
			},
			class: 'modal-lg'
		});
	}
}