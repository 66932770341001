<div class="pull-left lv-avatar">{{ data?.numero?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="goToCompteAuxiliaire()">{{ data | display:'compteAuxiliaire' }}</a>
	</div>
	<div class="lv-actions actions dropdown" *ngIf="data?.idCompteAuxiliaire">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteCompteAuxiliaire();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>