<form #factureSelectionSocieteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" *ngIf="options.mode == 'RECONCILIATION'" translate>facture.reconciliationSociete.title</h4>
		<h4 class="modal-title" *ngIf="options.mode == 'ASSOCIATION'" translate>facture.selectionSociete.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" *ngIf="options.mode == 'RECONCILIATION'">
				<label translate>facture.compteFacturation</label>
				<div>
					<span>{{ codeCompteFacturation }}</span>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': societeField.invalid }">
				<label translate>facture.societe</label>
				<div>
					<autocomplete name="societe" type="societe" #societeField="ngModel" [(ngModel)]="societe" required></autocomplete>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveSociete()" [disabled]="factureSelectionSocieteForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>