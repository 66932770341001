import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PoolListItemComponent } from './pool-list-item.component';

@Component({
	selector: 'pool-list',
	templateUrl: './pool-list.component.html'
})
export class PoolListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,PoolListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PoolListItemComponent>({
			uri: `/controller/VehiculePool/filtrePools`,
			title: this.translateService.instant('pool.liste.title'),
			component: PoolListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isReservationAutomatique',
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('pool.reservationAutomatique')
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'isEtatLieuxAutomatique',
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('pool.etatLieuxAutomatique')
			},{
				clef: 'isDemandeReleveCompteurAutomatique',
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('pool.demandeReleveCompteurAutomatique')
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Vehicule/loadPool/0'))
		});
	}
}
