import { NgModule } from '@angular/core';

import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { AmountComponent } from './amount.component';

@NgModule({
	imports: [CoreComponentsModule,AutocompleteModule,NumberModule],
	declarations: [AmountComponent],
	exports: [AmountComponent]
})
export class AmountModule {}