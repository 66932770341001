import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	templateUrl: './structure-export-definition.component.html'
})
export class StructureExportDefinitionComponent extends PageContentItem implements OnInit {
	/** Structure d'export **/
	@Input() structureExport: any;

	/** Fichier courant **/
	@Input() selectedDetail: any;

	/** Mise à jour de la structure d'export **/
	@Input() onStructureExportUpdate: Function;

	/** Rafraichissement de l'onglet 'Définition' **/
	@Input() onRefresh: Function;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Liste des fichiers de la structure **/
	public listeDetails: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération des fichiers de la structure
		this.listeDetails = this.structureExport.listeDetails.filter(d => d.typeDetail == 'FICHIER');

		//Vérification du nombre d'éléments
		if (this.listeDetails.length == 1)
			//Sélection automatique du détail
			this.selectedDetail = this.listeDetails[0];
	}
}