import { Component,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModeleService } from './modele.service';
import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { ModeleOptionContrainteListComponent } from './modele-option-contrainte-list.component';
import { ModeleOptionPackListComponent } from './modele-option-pack-list.component';

@Component({
	templateUrl: './modele-option.component.html'
})
export class ModeleOptionComponent implements OnInit {
	/** Option **/
	option: any = null;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef,public modeleService: ModeleService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition du stepper
		this.listeSteps = [{
			type: 'EXCLUSION',
			libelle: 'vehicule.modele.option.step.exclusion',
			component: ModeleOptionContrainteListComponent,
			canActivate: () => true,
			isDone: () => false,
			retrieveComponentData: () => ({
				option: this.option,
				typeContrainte: 'EXCLUSION'
			})
		},{
			type: 'INCLUSION',
			libelle: 'vehicule.modele.option.step.inclusion',
			component: ModeleOptionContrainteListComponent,
			canActivate: () => true,
			isDone: () => false,
			retrieveComponentData: () => ({
				option: this.option,
				typeContrainte: 'INCLUSION'
			})
		},{
			type: 'PACK',
			libelle: 'vehicule.modele.option.step.pack',
			component: ModeleOptionPackListComponent,
			canActivate: () => true,
			isDone: () => false,
			retrieveComponentData: () => ({
				option: this.option
			})
		}];
	}
}