import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators'

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FournisseurService } from './fournisseur.service';

@Component({
	templateUrl: './fournisseur-alias-list-item.component.html'
})
export class FournisseurAliasListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService) {
		//Héritage
		super();

		//Binding
		this.deleteAlias = this.deleteAlias.bind(this)
	}

	/**
	 * Suppression de l'objet
	 */
	deleteAlias(alias: any = this.data,close?: Function) {
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.fournisseurService.deleteAlias(alias))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				this.onObjectChange(null);

				//Fermeture du formulaire d'édition
				close?.(null);
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}
}