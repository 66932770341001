<div class="container-fluid" *ngIf="sinistre">
	<form #sinistreForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="card full">
				<div class="card-body card-padding">
					<sinistre-content #sinistreContent [sinistre]="sinistre" source="SINISTRE" (close)="onContentClose($event)"></sinistre-content>
				</div>
			</div>
			<div class="card full">
				<data-customization entite="com.notilus.data.vehicule.Sinistre" [object]="sinistre" [disabled]="!(null | right:'creation')"></data-customization>
			</div>
		</fieldset>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="sinistreContent.deleteSinistre();" *ngIf="sinistre?.idSinistre && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="sinistreContent.saveSinistre();" *ngIf="null | right:'creation'" [disabled]="sinistreForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button *ngIf="sinistre?.idSinistre" [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>