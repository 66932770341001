<div class="container-fluid">
	<form #articleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="article">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': titre.invalid }">
								<label translate>article.titre</label>
								<div>
									<input type="text" class="form-control" name="titre" #titre="ngModel" [(ngModel)]="article.titre" required maxlength="300"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>article.reference</label>
								<div>
									<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="article.reference" required maxlength="100"/>
								</div>
							</div>
							<div class="form-group">
								<label>
									<span translate>article.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'article.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="article.actif" [disabled]="resume.nbRubriques == 0"><span translate>article.actif.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeDiffusion.invalid }" *ngIf="rightService.isRoot()">
								<label translate>article.typeDiffusion.itemShort</label>
								<div class="half">
									<selectpicker name="diffusion" #typeDiffusion="ngModel" [(ngModel)]="article.typeDiffusion" required>
										<mat-option *ngFor="let typeDiffusion of listeTypesDiffusion" [value]="typeDiffusion.code">{{ typeDiffusion.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeArticle.invalid }">
								<label translate>article.type.itemLong</label>
								<div class="half">
									<selectpicker name="typeArticle" #typeArticle="ngModel" [(ngModel)]="article.typeArticle" required>
										<mat-option *ngFor="let typeArticle of listeTypesArticle" [value]="typeArticle.code">{{ typeArticle.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typePerimetre.invalid }">
								<label translate>article.perimetre.itemLong</label>
								<div class="half">
									<selectpicker name="typePerimetre" #typePerimetre="ngModel" [(ngModel)]="article.typePerimetre" required>
										<mat-option *ngFor="let typePerimetre of listeTypesPerimetre" [value]="typePerimetre.code">{{ typePerimetre.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" *ngIf="article.typeArticle == 'CGU'">
								<label translate>article.consentement.item</label>
								<div>
									<mat-checkbox name="forceConsentement" #forceConsentement="ngModel" [(ngModel)]="article.forceConsentement" [disabled]="!article.actif"><span translate>article.consentement.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="article.typeArticle == 'ALERTE'">
								<label translate>article.diffusionAlerte.item</label>
								<div>
									<mat-checkbox name="isDiffusionAlertes" #isDiffusionAlertes="ngModel" [(ngModel)]="article.isDiffusionAlertes" [disabled]="!article.actif"><span translate>article.diffusionAlerte.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" *ngIf="article.typeArticle == 'NOUVEAUTES'">
								<label translate>article.diffusionNouveautes.item</label>
								<div>
									<mat-checkbox name="isDiffusionNouveautes" #isDiffusionNouveautes="ngModel" [(ngModel)]="article.isDiffusionNouveautes" [disabled]="!article.actif"><span translate>article.diffusionNouveautes.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="card full">
			<data-customization entite="com.notilus.data.aide.Article" [object]="article" [disabled]="!(null | right:'creation')"></data-customization>
		</div>
		<div class="card-footer">
			<span class="pull-right">
				<button mat-stroked-button color="primary" (click)="deleteArticle();" *ngIf="article.idArticle && (null | right:'suppression')">
					<span translate>actions.supprimer</span>
				</button>
				<button mat-raised-button color="primary" [disabled]="articleForm.invalid" (click)="saveArticle(article,close);" *ngIf="null | right:'creation'">
					<span [translate]="'actions.'+(article.idArticle ? 'enregistrer' : 'continuer')"></span>
				</button>
			</span>
			<button mat-button *ngIf="article.idArticle" [formDetectChanges]="{ doAction: close,isDirty: articleForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
		</div>
	</form>
</div>