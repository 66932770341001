import { NgModule } from '@angular/core';

import { FileUploaderDirective } from './file-uploader.directive';
import { FileUploaderService } from './file-uploader.service';

@NgModule({
	imports: [],
	declarations: [FileUploaderDirective],
	exports: [FileUploaderDirective],
	providers: [FileUploaderService]
})
export class FileUploaderModule {}