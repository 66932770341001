import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { mapEntites } from 'src/app/components/commande/demande-cotation/demande-cotation.entites'
import { User } from 'src/app/domain/user/user';
import { TypeDroit } from 'src/app/domain/security/right';
import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	templateUrl: './demande-cotation-cotation-list-item.component.html'
})
export class DemandeCotationCotationListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Statuts des demandes de cotation **/
	mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private loggedUserService: LoggedUserService
			,public cotationService: CotationService,private historyService: HistoryService,public demandeCotationService: DemandeCotationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Mise à jour de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Récupération des statuts
		this.mapStatuts = this.demandeCotationService.getMapStatuts();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idCotation,object?.idCotation && !this.data?.idCotation);
	}

	/**
	 * Accès à la cotation
	 */
	consultCotation() {
		//Consultation de la cotation
		this.extraOptions.consultCotation(this.data);
	}

	/**
	 * Affichage de la demande de cotation
	 */
	showCotation(isForceComparaison?: boolean) {
		//Affichage de la cotation
		this.extraOptions.showCotation(this.data,isForceComparaison);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la demande de cotation
					this.cotationService.deleteCotation(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression depuis la liste
								this.onObjectChange(null);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Annulation d'une cotation
	 */
	cancelCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.annulation.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Annulation de la cotation
					this.cotationService.cancelCotation(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Mise à jour de la cotation
								Object.assign(this.data,result.data?.cotation);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.cotation.Cotation',this.data.idCotation);
	}
}