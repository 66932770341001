import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FournisseurService } from './fournisseur.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	selector: 'fournisseur-contact-edit',
	templateUrl: './fournisseur-contact-edit.component.html'
})
export class FournisseurContactEditComponent extends PageContentItem implements OnInit {
	/** Contact **/
	@Input() contact: any;

	/** Suppression d'un contact **/
	@Input() deleteContact: (contact: any,close: Function) => void;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Liste des types de contact **/
	listeTypesContact: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des types de contact
		this.listeTypesContact = this.fournisseurService.getListeTypesContact();
	}

	/**
	 * Enregistrement du contact
	 */
	saveContact() {
		//Enregistrement du code
		this.fournisseurService.saveContact(this.contact).subscribe(result => {
			let listeDoublons: Array<string> = [];

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du contact
				Object.assign(this.contact,result.data?.contact);

				//Fermeture de la fenêtre
				this.close({ contact: this.contact });
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification de l'email
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.email'));

				//Vérification du type
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.type'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Interception d'une modification sur le contact mobile
	 */
	onContactMobileChange() {
		//Vérification de l'état
		if (this.contact.mobile)
			//Activation du contact
			this.contact.actif = true;
	}
}
