import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';

import { UpgradeService } from './upgrade.service';

@Component({
	selector: 'upgrade',
	templateUrl: './upgrade.component.html'
})
export class UpgradeComponent implements OnInit {
	/** Elément courant **/
	@Input() upgrade: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Onglet sélectionné **/
	selectedTab: 'PRE' | 'SCRIPT' | 'POST' = 'PRE';

	/** Liste des tâches **/
	private listePreTasks: Array<any> = null;
	private listePostTasks: Array<any> = null;

	/** Liste des scripts **/
	private listeScripts: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private upgradeService: UpgradeService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement du détail de la version
		this.upgradeService.loadDetail(this.upgrade).subscribe({
			next: result => {
				//Mise à jour de la liste des tâches
				this.listePreTasks = result.data.listeTasks?.filter(t => t.type == 'PRE_TASK') || [];
				this.listePostTasks = result.data.listeTasks?.filter(t => t.type == 'POST_TASK') || [];

				//Mise à jour de la liste des scripts
				this.listeScripts = result.data.listeScripts;
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Affichage du log
	 */
	public showLog(item: any) {
		//Affichage du log
		this.upgradeService.showLog(item);
	}

	/**
	 * Chargement de la liste des objets à afficher pour un onglet
	 */
	getListeItemsForSelectedTab() {
		//Vérification de l'onglet sélectionné
		switch (this.selectedTab) {
		case 'PRE':
		default:
			//Retour de la liste des tâches 'PRE'
			return this.listePreTasks;
		case 'SCRIPT':
			//Retour de la liste des scripts
			return this.listeScripts;
		case 'POST':
			//Retour de la liste des tâches 'POST'
			return this.listePostTasks;
		}
	}
}