<div class="container-fluid">
	<form #chartForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="listeEntites">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>chart.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="chart.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>chart.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="chart.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': entiteField.invalid }">
								<label translate>chart.entite</label>
								<div>
									<selectpicker name="entite" #entiteField="ngModel" [(ngModel)]="chart.extraction.entity" required>
										<mat-option *ngFor="let entite of listeEntites" [value]="entite.code">{{ entite.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeDiffusionField.invalid }">
								<label translate>chart.typeDiffusion.itemShort</label>
								<div>
									<selectpicker name="typeDiffusion" #typeDiffusionField="ngModel" [(ngModel)]="chart.typeDiffusion" required>
										<mat-option *ngFor="let typeDiffusion of listeTypeDiffusions" [value]="typeDiffusion.code">{{ typeDiffusion.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label>
									<span translate>chart.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'chart.actif.description' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="chart.actif" [disabled]="!chart.extraction?.listeFields?.length"><span translate>chart.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>chart.defautFormulaire.item</label>
								<div>
									<mat-checkbox name="defautFormulaire" [(ngModel)]="chart.formulaireDefault"><span translate>chart.defautFormulaire.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>chart.defautMetier.item</label>
								<div>
									<mat-checkbox name="defautMetier" [(ngModel)]="chart.metierDefault"><span translate>chart.defautMetier.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>chart.defautGlobal.item</label>
								<div>
									<mat-checkbox name="defautGlobal" [(ngModel)]="chart.globalDefault"><span translate>chart.defautGlobal.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>chart.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="chart.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteChart();" *ngIf="chart?.idChart && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveChart();" *ngIf="null | right:'creation'" [disabled]="chartForm.invalid">
				<span [translate]="'actions.' + (chart?.idChart ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="chart?.idChart" [formDetectChanges]="{ doAction: close,isDirty: chartForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>