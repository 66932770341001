<div class="pull-left lv-avatar">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions.showReponseCotation(data)">
			<span> {{ data.cotation.modele.marque.libelle + ' ' + data.cotation.modele.libelle + (data.cotation.modele.finition ? ' ' + data.cotation.modele.finition : '') + (data.cotation.modele.reference ? ' (' + data.cotation.modele.reference + ')' : '') }}</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>demandeVehicule.cotation.statut.item</span>&#160;:&#160;<span [translate]="'reponseCotation.statut.' + data.statut"></span></li>
		<li *ngIf="data.dateEnvoi && (data.statut == 'A_TRANSMETTRE' || data.statut == 'A_COMPLETER')"><span translate>reponseDemandeCotation.reponseCotation.dateEnvoi</span>&#160;:&#160;<span>{{ data.dateEnvoi | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateReponse && (data.statut == 'RETENUE' || data.statut == 'NON_RETENUE' || data.statut == 'DECLINEE' || data.statut == 'TRANSMISE')"><span translate>reponseDemandeCotation.reponseCotation.dateReponse</span>&#160;:&#160;<span>{{ data.dateReponse | date:'shortDate' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && data.statut == 'A_TRANSMETTRE'"><a [routerLink]="[]" (click)="doSendReponseCotation(data)"><span translate>actions.envoyer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>