import { Component,EventEmitter,Input,Output } from '@angular/core';

@Component({
	selector: 'article-feedback',
	templateUrl: './article-feedback.component.html'
})
export class ArticleFeedback {
	/** Elément courant **/
	@Input() feedback: any;

	/** Gestion d'une modification de feedback **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor() {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}