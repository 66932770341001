import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratCRMService } from './contrat-crm.service';

@Component({
	selector: 'contrat-crm-offre-edit',
	templateUrl: './contrat-crm-offre-edit.component.html'
})
export class ContratCRMOffreEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() lien: any;

	/** Suppression de l'objet **/
	@Input() deleteLienOffre?: (close: Function) => void;

	/** Possibilité de supprimer l'objet */
	public canDelete: boolean;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private contratCRMService: ContratCRMService) {
		//Héritage
		super();

		//Binding des méthodes
		this.close = this.close?.bind?.(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la possibilité de supprimer l'objet
		this.canDelete = !this.lien.dateResiliation;
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveLienOffre() {
		//Enregistrement de l'objet
		this.contratCRMService.saveOffreForContratCRM(this.lien).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.lien = Object.assign(this.lien,result.data?.lien);

					//Fermeture du formulaire
					this.close?.(this.lien);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout de l'offre
						listeDoublons.push(this.translateService.instant('contratCRM.offre.item'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}