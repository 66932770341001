<form #compteAuxiliaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.compteAuxiliaire.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
							<label translate>comptabilite.compte.compteAuxiliaire.numero</label>
							<div>
								<input type="text" name="numero" #numero="ngModel" class="form-control" [(ngModel)]="compteAuxiliaire.numero" maxlength="100" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>comptabilite.compte.compteAuxiliaire.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="compteAuxiliaire.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
							<label translate>comptabilite.compte.compteAuxiliaire.pays</label>
							<div>
								<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="compteAuxiliaire.pays" required [disabled]="true"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>comptabilite.compte.compteAuxiliaire.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="compteAuxiliaire.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" (click)="saveCompteAuxiliaire();" *ngIf="null | right:'creation'" [disabled]="compteAuxiliaireForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: compteAuxiliaireForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>