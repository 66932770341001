<div class="progress-item" *ngFor="let progress of listeProgress; let idxItem = index;">
	<div class="icon-container">
		<icon [name]="progress.icone"></icon>
	</div>
	<div class="content-container">
		<div class="content-top hoverable">
			<span class="libelle" *ngIf="!progress.nbIgnored">{{ progress.libelle }}</span>
			<a class="libelle" *ngIf="progress.nbIgnored" [routerLink]="[]" (click)="showProgressDetail(progress);">{{ progress.libelle }}</a>
			<span [ngClass]="{ 'hovered-hidable': progress.nbTotal == progress.nbProcessed }" *ngIf="progress.nbTotal">
				<div class="label" [ngClass]="{ 'label-warning': progress.nbIgnored > 0,'label-info': progress.nbIgnored == 0 }">
					<span>{{ (progress.nbProcessed - progress.nbIgnored) + '/' + progress.nbTotal }}</span>
					<icon name="warning" *ngIf="progress.nbIgnored"></icon>
				</div>
			</span>
			<span class="hovered-item" *ngIf="progress.nbTotal == progress.nbProcessed">
				<a [routerLink]="[]" (click)="deleteProgress(idxItem); $event.stopPropagation();">
					<icon name="close" class="icon-hc-1-dot-6x"></icon>
				</a>
			</span>
		</div>
		<div class="progress">
			<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': (progress.nbTotal > 0 ? progress.nbProcessed / progress.nbTotal * 100 : 0) + '%' }"></div>
		</div>
	</div>
</div>