<a [routerLink]="[]" *ngIf="!disabled" [ngClass]="customClass" #popover="bs-popover" [popover]="popoverTemplate" [popoverTitle]="'rule.selector.rollingPeriod.title' | translate" containerClass="fit-content">
	<span>{{ getLabelForValue() }}</span>
</a>
<span *ngIf="disabled">
	<span>{{ getLabelForValue() }}</span>
</span>
<ng-template #popoverTemplate>
	<form #rollingPeriodSelectorForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card-body">
			<div class="form-group">
				<label class="text-nowrap" translate>rule.selector.rollingPeriod.de</label>
				<div><span translate>common.today</span></div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': valueField.invalid || temporalField.invalid }">
				<label class="text-nowrap">
					<span translate>rule.selector.rollingPeriod.a.item</span>
					<icon name="info_outline" class="m-l-5" [tooltip]="'rule.selector.rollingPeriod.a.description' | translate"></icon>
				</label>
				<div class="d-flex gap-1">
					<input type="text" name="valueField" class="form-control" #valueField="ngModel" [(ngModel)]="value" nNumber [noZero]="true" required/>
					<selectpicker name="temporalField" #temporalField="ngModel" [(ngModel)]="temporal" required>
						<mat-option *ngFor="let temporal of listeTemporals" [value]="temporal.code">{{ temporal.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
		</div>
		<div class="card-footer p-t-20">
			<span class="pull-right">
				<button mat-raised-button color="primary" (click)="select()" [disabled]="rollingPeriodSelectorForm.invalid">
					<span translate>actions.continuer</span>
				</button>
			</span>
			<button mat-button (click)="cancel()">
				<span translate>actions.annuler</span>
			</button>
		</div>
	</form>
</ng-template>