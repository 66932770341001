import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CompteServicePasswordComponent } from './compte-service-password.component';
import { CompteServiceDroitComponent } from './compte-service-droit.component';
import { CompteServiceEditComponent } from './compte-service-edit.component';
import { CompteServiceListItemComponent } from './compte-service-list-item.component';
import { CompteServiceListComponent } from './compte-service-list.component';
import { CompteServiceComponent } from './compte-service.component';
import { CompteServiceService } from './compte-service.service';

@NgModule({
	imports: [ShareModule],
	declarations: [CompteServiceListComponent,CompteServiceListItemComponent,CompteServiceComponent,CompteServiceEditComponent,CompteServiceDroitComponent,CompteServicePasswordComponent],
	providers: [CompteServiceService]
})
export class CompteServiceModule { }
