import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { UserSchedulerService } from 'src/app/share/components/user-scheduler/user-scheduler.service';

@Component({
	templateUrl: './profil-extraction-list-item.component.html'
})
export class ProfilExtractionListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private userSchedulerService: UserSchedulerService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteUserScheduler = this.deleteUserScheduler.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteUserScheduler(close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.userSchedulerService.deleteUserScheduler(this.data.idUserScheduler))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				this.onObjectChange(null);

				//Fermeture du formulaire d'édition
				close?.(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}