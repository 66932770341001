import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TenantService } from 'src/app/components/tenant/tenant.service';

@Component({
	selector: 'autorisation-content',
	templateUrl: './autorisation-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class AutorisationContentComponent implements OnInit {
	/** Elément courant **/
	@Input() autorisation: any;

	/** Source **/
	@Input() source: 'TENANT' | 'USER';

	/** Utilisateur restreint */
	@Input() isProfilRestricted: boolean;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private tenantService: TenantService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private rightService: RightService) {
		//Binding des méthodes
		this.saveAutorisation = this.saveAutorisation.bind(this);
		this.deleteAutorisation = this.deleteAutorisation.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	public ngOnInit() {
		//Vérification du profil restreint
		this.isProfilRestricted = this.rightService.isRestricted(TypeRestriction.CLIENT);
	}

	/**
	 * Vérification de la validité de la date de fin
	 */
	public isDateFinValid(): boolean {
		let isValid: boolean = true;
		let dateMax: number;

		//Calcul de la date maximum (date de début + 3 mois)
		dateMax = this.autorisation.dateDebut ? moment(this.autorisation.dateDebut).add(3,'month').valueOf() : null;

		//Vérification de la validité
		isValid = this.autorisation.dateFin && this.autorisation.dateDebut && this.autorisation.dateFin >= this.autorisation.dateDebut && this.autorisation.dateFin <= dateMax;

		return isValid;
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteAutorisation(autorisation: any = this.autorisation) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.deleteAutorisation(autorisation))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Fermeture du formulaire
					this.close.emit();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveAutorisation() {
		//Enregistrement de l'autorisation
		this.tenantService.saveAutorisation(this.autorisation).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de la fenêtre
					this.close.emit(result.data?.autorisation);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout de la date
					listeDoublons.push(this.translateService.instant('actions.doublon.dateDebut'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}