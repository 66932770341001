import { Component,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Step } from 'src/app/domain/common/stepper/step';
import { TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { ConnecteurService } from '../connecteur.service';
import { ImportAttachmentsComponent } from './import-attachments.component';
import { ImportFormatListComponent } from './import-format-list.component';
import { ImportFournisseurListComponent } from './import-fournisseur-list.component';
import { ImportResumeComponent } from './import-resume.component';

@Component({
	templateUrl: './import-selection.component.html'
})
export class ImportSelectionComponent implements OnInit {
	/** Type de flux **/
	typeFlux: TypeFlux;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Fournisseur sélectionné **/
	selectedFournisseur: any = null;

	/** Connecteur sélectionné **/
	selectedConnecteur: any = null;

	/** Vérification de la disponibilité du connecteur sélectionné **/
	selectedConnecteurAvailability: { isAvailable: boolean } = null;

	/** Indicateur de désactivation de l'import **/
	isActionDisabled = false;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImportSelectionComponent>,private connecteurService: ConnecteurService,private translateService: TranslateService,private toastrService: ToastrService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'FOURNISSEUR',
			libelle: 'connecteur.import.selection.step.fournisseur',
			component: ImportFournisseurListComponent,
			retrieveComponentData: () => ({
				typeFlux: this.typeFlux,
				selectedFournisseur: this.selectedFournisseur,
				onFournisseurSelected: fournisseur => {
					//Mise à jour du fournisseur sélectionné
					this.selectedFournisseur = fournisseur;

					//Retrait du connecteur sélectionné
					this.selectedConnecteur = null;

					//Passage à l'étape suivante
					this.stepperComponent.selectStep(this.listeSteps[fournisseur ? 1 : 0]);
				}
			}),
			canActivate: () => true,
			isDone: () => !!this.selectedFournisseur
		},{
			type: 'FORMAT',
			libelle: 'connecteur.import.selection.step.format',
			component: ImportFormatListComponent,
			retrieveComponentData: () => ({
				typeFlux: this.typeFlux,
				selectedFournisseur: this.selectedFournisseur,
				selectedConnecteur: this.selectedConnecteur,
				onConnecteurSelected: connecteur => {
					//Retrait du connecteur sélectionné
					this.selectedConnecteur = connecteur;

					//Vérification de la sélection d'un connecteur
					if (connecteur) {
						//Vérification du mode d'exécution du connecteur
						if (connecteur.typeExecution != 'MIDDLEWARE') {
							//Vérification de la disponibilité du connecteur sélectionné
							this.connecteurService.checkConnecteurAvailability(this.selectedConnecteur.idConnecteur).subscribe({
								next: isAvailable => {
									//Mise à jour de la disponibilité du connecteur
									this.selectedConnecteurAvailability = { isAvailable };

									//Passage à l'étape suivante
									this.stepperComponent.selectStep(this.listeSteps[isAvailable ? 2 : 3]);
								},
								error: () => {
									//Mise à jour de la disponibilité du connecteur
									this.selectedConnecteurAvailability = { isAvailable: false };

									//Passage à l'étape suivante
									this.stepperComponent.selectStep(this.listeSteps[3]);
								}
							});
						} else {
							//Mise à jour de la disponibilité du connecteur
							this.selectedConnecteurAvailability = { isAvailable: true };

							//Passage à l'étape suivante
							this.stepperComponent.selectStep(this.listeSteps[2]);
						}
					} else
						//Rafraichissement de la liste
						this.stepperComponent.selectStep(this.listeSteps[1]);
				}
			}),
			canActivate: () => !!this.selectedFournisseur,
			isDone: () => !!this.selectedFournisseur && !!this.selectedConnecteur
		},{
			type: 'ATTACHMENTS',
			libelle: 'connecteur.import.selection.step.fichiers',
			component: ImportAttachmentsComponent,
			retrieveComponentData: () => ({
				selectedConnecteur: this.selectedConnecteur,
				typeFlux: this.typeFlux
			}),
			canActivate: () => !!this.selectedConnecteur && this.selectedConnecteurAvailability?.isAvailable,
			isDone: () => !!this.selectedConnecteur && this.selectedConnecteurAvailability?.isAvailable && this.selectedConnecteur?.listeLinks?.length
		},{
			type: 'RECAPITULATIF',
			libelle: 'connecteur.import.selection.step.recapitulatif',
			component: ImportResumeComponent,
			retrieveComponentData: () => ({
				selectedFournisseur: this.selectedFournisseur,
				selectedConnecteur: this.selectedConnecteur,
				selectedConnecteurAvailability: this.selectedConnecteurAvailability
			}),
			canActivate: () => !!this.selectedConnecteur && !!this.selectedConnecteurAvailability && (!this.selectedConnecteurAvailability.isAvailable || this.selectedConnecteur?.listeLinks?.length),
			isDone: () => false
		}];
	}

	/**
	 * Exécution de l'interface
	 */
	doImport() {
		//Désactivation de l'import
		this.isActionDisabled = true;

		//Mise à jour de la liste des attachments
		this.selectedConnecteur.listeAttachments = this.selectedConnecteur.listeLinks.map(link => link.attachment);

		//Exécution de l'interface
		this.connecteurService.executeInterface(this.selectedConnecteur).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('connecteur.import.selection.success'));

					//Fermeture de la fenêtre
					this.bsModalRef.hide();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('connecteur.import.selection.error'));

					//Ré-activation de l'import
					this.isActionDisabled = false;
				}
			}
		})
	}
}