<div ngModelGroup="releveCompteurContent" #releveCompteurContent="ngModelGroup" *ngIf="user.type != 'VIRTUAL' || user.type == 'VIRTUAL' && releveCompteur.vehicule">
	<fieldset [disabled]="!(TypeDroit.VEHICULE_COMPTEUR | right:'creation') || releveCompteur.readOnly || source == 'CONDUCTEUR'">
		<div class="alert alert-info" *ngIf="user.type == 'VIRTUAL' && releveCompteur.vehicule && releveCompteur.lastCompteur">
			<icon name="info"></icon>
			<div>
				<strong translate>vehicule.releveCompteur.alerte.lastCompteur.title</strong>
				<div translate [translateParams]="{ dateReleve: (releveCompteur.lastCompteur.dateReleve | date:'shortDate'),vehicule: releveCompteur.lastCompteur.vehicule.reference,releveCompteur: releveCompteur.vehicule?.uniteUsage == 'DISTANCE' ? (releveCompteur.lastCompteur.releveCompteur | number:'1.0-0') : (releveCompteur.lastCompteur.releveHoraire | number:'1.0-0'),unite: releveCompteur.vehicule?.uniteUsage == 'DISTANCE' ? (releveCompteur.lastCompteur.vehicule.unite?.libelleCourt || user.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }">vehicule.releveCompteur.alerte.lastCompteur.message</div>
			</div>
		</div>
		<div class="alert alert-info" *ngIf="releveCompteur.readOnly && source != 'CONDUCTEUR'">
			<icon name="info"></icon>
			<div>
				<strong translate>vehicule.releveCompteur.alerte.readOnly.title</strong>
				<div translate>vehicule.releveCompteur.alerte.readOnly.message</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': dateReleve.invalid }" *ngIf="source == 'EQUIPEMENT'">
					<label translate>vehicule.releveCompteur.dateReleve</label>
					<div class="half">
						<input type="text" name="dateReleve" class="form-control" #dateReleve="ngModel" [(ngModel)]="releveCompteur.dateReleve" (ngModelChange)="onDateChange()" datePicker maxDate="today" format="datetime" required [disabled]="user.type == 'VIRTUAL'"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': releveCompteurContent.control?.controls['vehicule']?.invalid }" *ngIf="source != 'VEHICULE'">
					<label translate>vehicule.releveCompteur.vehicule</label>
					<div>
						<ng-container *ngIf="!releveCompteur.vehicule">
							<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" #vehicule="ngModel" [(ngModel)]="releveCompteur.vehicule" required></autocomplete>
						</ng-container>
						<div *ngIf="releveCompteur.vehicule">
							<mat-chip-listbox>
								<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (removed)="releveCompteur.vehicule = null" (click)="rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && showVehicule()">
									{{ releveCompteur.vehicule | display:'vehicule' }}
									<button matChipRemove *ngIf="(TypeDroit.VEHICULE_COMPTEUR | right:'creation') && !releveCompteur.readOnly && source != 'CONDUCTEUR' && user.type != 'VIRTUAL'">
										<mat-icon>cancel</mat-icon>
									</button>
								</mat-chip>
								<icon name="replay_30" class="clickable icon-hc-2x" *ngIf="releveCompteur.vehicule?.listeOldReferences?.length > 0" (click)="vehiculeService.showListeImmatriculations(releveCompteur.vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate"></icon>
							</mat-chip-listbox>
						</div>
					</div>
				</div>
				<div class="form-group" *ngIf="source != 'EQUIPEMENT' && releveCompteur.equipement && user.type != 'VIRTUAL'">
					<label translate>vehicule.releveCompteur.equipement</label>
					<div>
						<mat-chip-listbox>
							<mat-chip [class.clickable]="TypeDroit.EQUIPEMENT | right:'consultation'" (click)="rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation') && redirectToEquipement()">
								{{ releveCompteur.equipement.reference + ' (' + releveCompteur.equipement.fournisseur.libelle + ')' }}
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateReleve.invalid }" *ngIf="source != 'EQUIPEMENT'">
					<label translate>vehicule.releveCompteur.dateReleve</label>
					<div class="half">
						<input type="text" name="dateReleve" class="form-control" #dateReleve="ngModel" [(ngModel)]="releveCompteur.dateReleve" datePicker maxDate="today" format="datetime" required [disabled]="user.type == 'VIRTUAL'"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': releveCompteurContent.control?.controls['releveCompteurField']?.invalid }">
					<label translate>vehicule.releveCompteur.releveCompteur</label>
					<div class="half">
						<div class="input-group">
							<input *ngIf="!releveCompteur.vehicule || releveCompteur.vehicule.uniteUsage == 'DISTANCE'" type="text" name="releveCompteurField" class="form-control" [(ngModel)]="releveCompteur.releveCompteur" nNumber="2" min="0" required/>
							<input *ngIf="releveCompteur.vehicule?.uniteUsage == 'TEMPS'" type="text" name="releveCompteurField" class="form-control" [(ngModel)]="releveCompteur.releveHoraire" nNumber min="0" required/>
							<div class="input-group-addon no-border">
								<span *ngIf="!releveCompteur.vehicule || releveCompteur.vehicule.uniteUsage == 'DISTANCE'">{{ releveCompteur.vehicule?.unite?.libelleCourt || '' }}</span>
								<span *ngIf="releveCompteur.vehicule?.uniteUsage == 'TEMPS'">{{ 'duree.heure.libelleCourt' | translate }}</span>
							</div>
							<div class="input-group-addon" *ngIf="releveCompteur.vehicule?.idVehicule && user.type != 'VIRTUAL'">
								<icon name="replay_30" class="icon-hc-2x clickable" (click)="vehiculeService.showListeCompteurs(releveCompteur.vehicule)"></icon>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group" *ngIf="releveCompteur.idCompteur && source != 'CONDUCTEUR'">
					<label translate>vehicule.releveCompteur.userAffecte</label>
						<div>
							<span *ngIf="releveCompteur.extension?.userAffecte">{{ releveCompteur.extension.userAffecte | display:'user' }}</span>
							<span *ngIf="releveCompteur.extension?.vehiculeImmobilise" translate>vehicule.releveCompteur.vehiculeImmobilise</span>
							<span *ngIf="!releveCompteur.extension?.userAffecte && !releveCompteur.extension?.vehiculeImmobilise" translate>vehicule.releveCompteur.aucunUserAffecte</span>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
	<div class="form-group" *ngIf="user.type != 'VIRTUAL'">
		<label translate>vehicule.releveCompteur.valeurReference.item</label>
		<div>
			<mat-checkbox name="valeurReference" [(ngModel)]="releveCompteur.valeurReference" [disabled]="source == 'CONDUCTEUR' || releveCompteur.valeurVerrouillee"><span translate>vehicule.releveCompteur.valeurReference.info</span></mat-checkbox>
		</div>
	</div>
	<div class="form-group" *ngIf="user.type != 'VIRTUAL'">
		<label>
			<span translate>vehicule.releveCompteur.valeurVerrouillee.item</span>
			<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.releveCompteur.valeurVerrouillee.description' | translate"></icon>
		</label>
		<div>
			<mat-checkbox name="valeurVerrouillee" [disabled]="source == 'CONDUCTEUR'" [(ngModel)]="releveCompteur.valeurVerrouillee" (ngModelChange)="releveCompteur.valeurVerrouillee ? releveCompteur.valeurReference = true : null"><span translate>vehicule.releveCompteur.valeurVerrouillee.info</span></mat-checkbox>
		</div>
	</div>
	<fieldset [disabled]="!(TypeDroit.VEHICULE_COMPTEUR | right:'creation') || releveCompteur.readOnly || source == 'CONDUCTEUR'" *ngIf="user.type != 'VIRTUAL'">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': typeSource.invalid }">
					<label translate>vehicule.releveCompteur.source</label>
					<div class="half">
						<selectpicker name="typeSource" #typeSource="ngModel" [(ngModel)]="releveCompteur.typeSourceReleve" required>
							<mat-option *ngFor="let typeSource of listeTypesSource" [value]="typeSource.code">{{ typeSource.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }" *ngIf="releveCompteur.typeSourceReleve == 'FOURNISSEUR'">
					<label translate>vehicule.releveCompteur.fournisseur</label>
					<div>
						<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="releveCompteur.fournisseur" [disabled]="releveCompteur.typeSource == 'IMPORT'" [required]="releveCompteur.typeOrigine != 'ENTRETIEN'"></autocomplete>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
</div>
<div class="alert alert-danger" *ngIf="releveCompteur.errorLibelle">
	<icon name="warning"></icon>
	<div>
		<strong>{{ releveCompteur.errorLibelle }}</strong>
	</div>
</div>