import { Component,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { mapTypesDroitForTypesFlux,TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { AttachmentComponent } from 'src/app/share/components/attachment/attachment.component';
import { FileWithProgress } from 'src/app/share/directive/file-uploader/file-uploader';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { environment } from 'src/environments/environment';

@Component({
	templateUrl: './facture-attachments.component.html'
})
export class FactureAttachmentsComponent implements OnInit {
	/** Facture vide **/
	private emptyFacture: any = {};

	/** Composant de gestion des attachments **/
	@ViewChild('attachment') attachmentComponent: AttachmentComponent;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/** Indicateur d'analyse d'un fichier */
	isItemCompleted: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<FactureAttachmentsComponent>,private layoutService: LayoutService,private toastrService: ToastrService,private translateService: TranslateService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des options
		this.attachmentOptions = {
			getUrl: () => `${environment.baseUrl}/controller/Facture/saveAnalysisAttachement`,
			owningEntity: this.emptyFacture,
			typeAttachment: TypeAttachment.FACTURE,
			listeTypesDroit: [mapTypesDroitForTypesFlux[TypeFlux.FACTURE]],
			inline: true,
			withoutAttachmentSaving: true,
			filters: '.pdf',
			onCompleteItem: (file: File,result: Result) => {
				//Définition de l'indicateur d'analyse d'un fichier
				this.isItemCompleted = true;

				//Vérification du retour
				if (!result.data.facture && file.name)
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('facture.scan.error.facture',{ fileName: file.name }));
			},
			isFromLink: true,
			onAllItemsCompleted: () => {
				//Fermeture de la popup
				this.bsModalRef.hide();

				//Redirection vers la liste des factures
				this.layoutService.goToByState('listeFactures',{ reloadOnSameUrl: true });
			},
			disableUploadUnitaire: true
		};
	}

	/**
	 * Récupération de la queue des fichiers à uploader
	 */
	getUploadQueue(): Array<FileWithProgress> {
		//Retour de la queue
		return this.attachmentComponent?.fileUploader?.queue || [];
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	uploadAll() {
		//Upload de l'ensemble des fichiers
		this.attachmentComponent.uploadAll();
	}
}