import { AfterViewInit,ChangeDetectorRef,Component,ElementRef,HostListener,Input,Renderer2,ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { v4 } from 'uuid';

import { CalendarOptions } from 'src/app/domain/calendar/calendar';
import { ChartAction } from 'src/app/domain/chart/chart';
import { GLOBALS } from 'src/app/utils/globals';
import { environment } from 'src/environments/environment';
import { CalendarMaximizerComponent } from './calendar-maximizer.component';

@Component({
	selector: 'calendar',
	templateUrl: './calendar.component.html'
})
export class CalendarComponent implements AfterViewInit {
	/** Options du calendrier **/
	@Input() options: CalendarOptions;

	/** Composant calendrier JQuery **/
	calendar: any = null;

	/** Titre affiché en en-tête du calendrier **/
	calendarTitle: string;

	/** Indicateur d'affichage de la date courante **/
	isToday: boolean = true;

	/** Identifiant du calendrier **/
	private idCalendar: string;

	/** Container pour le calendrier **/
	@ViewChild('container') containerRef: ElementRef;

	/**
	 * Constructeur
	 */
	constructor(private renderer: Renderer2,private bsModalService: BsModalService,private changeDetectorRef: ChangeDetectorRef) {}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Initialisation du calendrier
		this.initCalendar();

		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Initialisation du calendrier
	 */
	private initCalendar() {
		let locale: string;
		let thisComponent: CalendarComponent;

		//Génération d'un identifiant unique pour le calendrier
		this.idCalendar = v4().toString();

		//Définition de l'identifiant de l'élément HTML
		this.renderer.setAttribute(this.containerRef.nativeElement,'id',`id_calendar_${this.idCalendar}`);

		//Définition de la langue
		locale = this.options.locale?.indexOf('-') == -1 ? `${this.options.locale}-${this.options.locale.toUpperCase()}` : this.options.locale || 'fr-FR';

		//Mémorisation du scope
		thisComponent = this;

		//Initialisation du calendrier
		this.calendar = GLOBALS.$('#id_calendar_'+this.idCalendar).calendar({
			tmpl_path: `.${environment.production && '/app/browser' || ''}/assets/calendar/`,
			events_source: this.options.loadCalendar,
			events_liste: this.options.loadDayEvents,
			language: locale,
			weekbox: false,
			onAfterViewLoad: function() {
				//Mise à jour du titre en en-tête du calendrier
				thisComponent.calendarTitle = this.getTitle();

				//Mise à jour de l'indicateur d'affichage de la date courante
				thisComponent.isToday = this.isToday();
			},
			views: {
				year: {
					slide_events: 1,
					enable: 0
				},
				month: {
					slide_events: 1,
					enable: 1
				},
				week: {
					enable: 0
				},
				day: {
					enable: 0
				}
			}
		});
	}

	/**
	 * Récupération de la liste des actions visibles
	 */
	getListeVisibleActions(): Array<ChartAction> {
		//Retour des actions
		return (this.options.getListeActions?.() || []).filter(a => !a.isVisible || a.isVisible());
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Changement de date 'précédent'
	 */
	navigatePrevious() {
		//Navigation vers la période précédente
		this.calendar?.navigate('prev');
	}

	/**
	 * Changement de date 'suivant'
	 */
	navigateNext() {
		//Navigation vers la période suivante
		this.calendar?.navigate('next');
	}

	/**
	 * Repositionnement sur la date courante
	 */
	goToCurrentDate() {
		//Navigation vers la date courante
		this.calendar?.navigate('today');

		//Mise à jour de l'indicateur d'affichage de la date courante
		this.isToday = true;
	}

	/**
	 * Maximisation du calendrier
	 */
	maximizeCalendar() {
		//Affichage du calendrier agrandi
		this.bsModalService.show(CalendarMaximizerComponent,{
			initialState: {
				options: cloneDeep(this.options)
			},
			class: 'modal-max'
		});
	}

	/**
	 * Interception du clic sur un événement
	 */
	@HostListener('window:notilus-calendar-item-clicked',['$event'])
	onItemClicked(event: CustomEvent) {
		//Vérification du calendrier ciblé par le clic
		if (event?.detail?.idCalendar == `id_calendar_${this.idCalendar}`)
			//Déclenchement de l'action
			this.options.onSelect(event.detail.idItem);
	}
}