import { NgModule } from '@angular/core';

import { TaxeService } from 'src/app/components/fiscalite/taxe/taxe.service';
import { ShareModule } from 'src/app/share/share.module';
import { TaxeEditComponent } from './taxe-edit.component';
import { TaxeListItemComponent } from './taxe-list-item.component';
import { TaxeListComponent } from './taxe-list.component';
import { TaxeTauxEditComponent } from './taxe-taux-edit.component';
import { TaxeTauxListComponent } from './taxe-taux-list.component';
import { TaxeComponent } from './taxe.component';

@NgModule({
	imports: [ShareModule],
	declarations: [TaxeListComponent,TaxeListItemComponent,TaxeComponent,TaxeEditComponent,TaxeTauxListComponent,TaxeTauxEditComponent],
	providers: [TaxeService]
})
export class TaxeModule { }