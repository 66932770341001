import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';
import { ControlContainer,NgForm } from '@angular/forms';
import moment from 'moment';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { SinistreService } from './sinistre.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	selector: 'sinistre-content',
	templateUrl: './sinistre-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class SinistreContentComponent implements OnInit {
	/** Elément courant **/
	@Input() sinistre: any;

	/** Source **/
	@Input() source: 'SINISTRE' | 'VEHICULE' | 'CONDUCTEUR';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Liste des types de reponsabilités **/
	public listeTypesResponsabilite: Array<{ code: string,libelle: string }>;

	/** Liste des types de statut **/
	public listeStatuts: Array<{ code: string,libelle: string }>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Date courante **/
	public today = moment().startOf('day');

	/** Identifiant du sinistre **/
	private oldIdSinistre: number;

	/**
	 * Constructeur
	 */
	constructor(private sinistreService: SinistreService,private layoutService: LayoutService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private affectationService: AffectationService
			,public rightService: RightService,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.saveSinistre = this.saveSinistre.bind(this);
		this.deleteSinistre = this.deleteSinistre.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des responsabilités
		this.listeTypesResponsabilite = this.sinistreService.getListeTypesResponsabilite();

		//Définition de la liste des statuts
		this.listeStatuts = this.sinistreService.getListeStatuts();

		//Mémorisation de l'identifiant du sinistre
		this.oldIdSinistre = this.sinistre.idSinistre;
	}

	/**
	 * Suppression du sinistre
	 */
	deleteSinistre() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.sinistreService.deleteSinistre(this.sinistre))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Sauvegarde du sinistre
	 */
	saveSinistre() {
		//Enregistrement de l'objet
		this.sinistreService.saveSinistre(this.sinistre).subscribe(result => {
			let listeDoublons = new Array<string>();

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de l'identifiant du sinistre
				this.oldIdSinistre = result.data?.sinistre?.idSinistre;

				//Fermeture du formulaire
				this.close.emit(result.data?.sinistre);
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,0)) {
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));
					listeDoublons.push(this.translateService.instant('actions.doublon.immatriculation'));
				}

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Affichage du véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.sinistre.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Interception d'un changement de date
	 */
	onDateSinistreChange(newDateSinistre: Date) {
		let oldDateSinistre: Date;

		//Récupération de la date avant modification
		oldDateSinistre = this.sinistre.dateSinistre;

		//Vérification de la présence d'une date de sinistre et d'un véhicule
		if (newDateSinistre && newDateSinistre != oldDateSinistre && this.sinistre.vehicule && (oldDateSinistre || !this.sinistre.user) && this.sinistre.idSinistre == this.oldIdSinistre)
			//Récupération du collaborateur affecté au véhicule lors du sinistre
			this.selectCollaborateurFromAffectation(newDateSinistre);
	}

	/**
	 * Recherche du collaborateur affecté au véhicule pour la date donnée
	 */
	private selectCollaborateurFromAffectation(date: Date) {
		//Recherche de l'affectation du véhicule pour la date de la contravention
		this.affectationService.findAffectationForDate(this.sinistre.vehicule.idVehicule,date).subscribe(result => {
			//Vérification de l'affectation
			if (result?.data?.affectation)
				//Mise à jour de l'utilisateur
				this.sinistre.user = result.data.affectation.user;
		});
	}
}