<div class="media-body">
	<div class="hoverable">
		<div class="lv-title task">
			<span class="task-check">
				<mat-checkbox name="done" #done="ngModel" [(ngModel)]="isDone" (change)="onStatutChange()"></mat-checkbox>
			</span>
			<div class="task-content" [ngClass]="{ 'done': data.statut == 'DONE' }">
				<div class="task-title">
					<a [routerLink]="[]" (click)="showDetail()">
						<span>{{ data.title }}</span>
					</a>
				</div>
				<ul class="task-attrs lv-attrs">
					<li *ngIf="data.listeUsers?.length > 0" class="o-hidden"><icon name="people" [tooltip]="getListeUsersAsString()" boundariesElement="window"></icon></li>
					<li *ngIf="data.dateEcheance" class="o-hidden"><span>{{ data.dateEcheance | date:'shortDate' }}</span></li>
					<li *ngIf="data.labelEntite" boundariesElement="window" class="o-hidden info label-link clickable" [ngClass]="{ 'md': data.dateEcheance,'sm': data.dateEcheance && data.listeUsers?.length }" (click)="navigateTo()" matRipple>
						<span>{{ data.labelEntite }}</span>
					</li>
				</ul>
			</div>
			<span class="task-action actions action-icone hovered-item">
				<a [routerLink]="[]" (click)="deleteItem()"><icon name="delete"></icon></a>
			</span>
		</div>
	</div>
</div>