<div class="container-fluid">
	<form #lotComptableForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>comptabilite.lotComptable.libelle</label>
								<div>
									<input type="text" name="libelle" class="form-control" #libelle="ngModel" [(ngModel)]="lotComptable.libelle" required/>
								</div>
							</div>
							<div class="form-group">
								<label translate>comptabilite.lotComptable.numeroLot</label>
								<div>
									<span *ngIf="lotComptable.numeroLot">{{ lotComptable.numeroLot }}</span>
									<span *ngIf="!lotComptable.numeroLot" class="italic">(<span translate>comptabilite.lotComptable.numeroLotAutomatique</span>)</span>
								</div>
							</div>
							<div class="form-group">
								<label translate>comptabilite.lotComptable.dateComptable</label>
								<div class="half">
									<input type="text" name="dateComptable" class="form-control" #dateComptable="ngModel" [(ngModel)]="lotComptable.dateComptable" datePicker/>
								</div>
							</div>
							<div class="form-group">
								<label translate>comptabilite.lotComptable.dateLot</label>
								<div class="half">
									<span>{{ lotComptable.dateLot | date:'shortDate' }}</span>
								</div>
							</div>
							<div class="form-group" *ngIf="lotComptable.dateUpdated">
								<label translate>comptabilite.lotComptable.dateUpdated</label>
								<div class="half">
									<span>{{ lotComptable.dateUpdated | date:'shortDate' }}</span>
								</div>
							</div>
							<div class="form-group" *ngIf="lotComptable.montant">
								<label translate>comptabilite.lotComptable.montantTotalTTC</label>
								<div>
									<span>{{ lotComptable.montant | currency:lotComptable.devise }}</span>
								</div>
							</div>
							<div class="form-group" *ngIf="selectionItems?.nbSelectedItems">
								<label translate>comptabilite.lotComptable.selection.item</label>
								<div>
									<span [translate]="'comptabilite.lotComptable.selection.description' | pluralTranslate:selectionItems.nbSelectedItems" [translateParams]="{ nbSelectedItems: selectionItems.nbSelectedItems }"></span>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>comptabilite.lotComptable.remarque</label>
						<div>
							<textarea name="remarque" class="form-control" rows="3" [(ngModel)]="lotComptable.remarque"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		</div>

		<button mat-button *ngIf="lotComptable.idLotComptable" [formDetectChanges]="{ doAction: close,isDirty: lotComptableForm.dirty }">
			<span translate>actions.fermer</span>
		</button>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteLotComptable();" *ngIf="lotComptable.idLotComptable && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="lotComptableForm.invalid" (click)="saveLotComptable();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(lotComptable?.idLotComptable ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
	</form>
</div>