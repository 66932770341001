import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ServiceServiceListItemComponent } from './service-service-list-item.component';

@Component({
	selector: 'service-service-list',
	templateUrl: './service-service-list.component.html'
})
export class ServiceServiceListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Fonction d'édition **/
	@Input() addService: () => void;

	/** Résumé **/
	@Input() resume: { nbServices: number };

	/** Données **/
	liste: ListView<any,ServiceServiceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServiceServiceListItemComponent>({
			uri: () => `/controller/Analytique/filtreServices/${this.service.idService}`,
			component: ServiceServiceListItemComponent,
			isLocal: true,
			extraOptions: {
				addService: this.addService
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: 'reference'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idService > 0)
			//Mise à jour des compteurs
			this.resume.nbServices--;
	}
}