import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	templateUrl: './poste-charge-compte-list-item.component.html'
})
export class PosteChargeCompteListItemComponent extends ListViewItem<any> {
	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteLienCompteDetail = this.deleteLienCompteDetail.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteLienCompteDetail(lien: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteService.deleteLienCompteDetail(lien))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				this.onObjectChange(null);

				//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
				close?.(null);
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Accès au formulaire complexe du plan comptable
	 */
	consultPlanComptable() {
		//Redirection vers le plan comptable
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: this.data.compte?.planComptable.idPlanComptable || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au formulaire complexe du compte collectif
	 */
	consultCompte() {
		//Redirection vers le compte collectif
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptes-compte',{
			routeParams: {
				idCompte: this.data.compte?.idCompte || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au formulaire complexe du compte auxiliaire
	 */
	consultCompteAuxiliaire() {
		//Redirection vers le compte auxiliaire
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptesAuxiliaires-compteAuxiliaire',{
			routeParams: {
				idCompteAuxiliaire: this.data.compteAuxiliaire?.idCompteAuxiliaire || 0
			},
			withGoBack: true
		});
	}
}