<div class="card focus">
	<div class="card-header">
		<h2 translate>formCustomization.title</h2>
		<ul class="actions" *ngIf="null | right:'consultation'">
			<a [routerLink]="[]" (click)="editFormCustomization()"><icon name="edit"></icon></a>
		</ul>
	</div>
	<div class="card-body">
		<ng-container *ngIf="formCustomization?.listeFields?.length">
			<div *ngFor="let field of formCustomization.listeFields; let index = index">
				<div class="view-group" *ngIf="index < 4 || isShowMore">
					<label>{{ field.libelle }}</label>
					<div>{{ entiteService.readValue(data,field) || '&#160;' }}</div>
				</div>
			</div>
		</ng-container>
		<div *ngIf="!formCustomization?.listeFields?.length" class="text-center">
			<a [routerLink]="[]" (click)="editFormCustomization()" translate>formCustomization.empty</a>
		</div>

		<button mat-stroked-button *ngIf="formCustomization?.listeFields?.length > 4" color="primary" (click)="isShowMore = !isShowMore">
			<span [translate]="'formCustomization.showInfos.' + (isShowMore ? 'less' : 'more')"></span>
		</button>
	</div>
</div>