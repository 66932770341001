import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpErrorResponse,HttpStatusCode } from '@angular/common/http';
import { Observable,Subject } from 'rxjs';
import { catchError,switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';
import { LoginService } from 'src/app/share/login/login.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	/** Entête de sécurité **/
	private xAuthToken: string = null;

	/** Sujet de renouvellement du token **/
	private renewSubject: Subject<any> = new Subject<any>();

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private loginService: LoginService) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.xAuthToken = session?.xAuthToken || null);
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification de la requête
		if (req.url.endsWith('/logout')) {
			//Laisser passer la requête
			return next.handle(req);
		} else {
			//Traitement de la requête
			return next.handle(req).pipe(
				catchError(error => {
					let isRefreshRequest;

					//Vérification de l'action de rafraichissement
					isRefreshRequest = req.url.endsWith('/Login/refresh');

					//Vérification des erreurs de la réponse
					if (error instanceof HttpErrorResponse) {
						//Vérification du token expiré
						if (this.verifyExpiredToken(error)) {
							//Vérification de la requête de rafraichissement
							if (!isRefreshRequest) {
								//Renouvellement du token
								return this.renewExpiredToken().pipe(
									switchMap(() => {
										//Rejouer la requête avec le nouveau header
										return next.handle(this.setHeader(req));
									})
								);
							} else  {
								//Echec du rafraichissement - Déconnexion de l'utilisateur
								this.loginService.logout();
							}
						}
					}

					//Retour de la réponse en erreur
					return throwError(error);
				})
			);
		}
	}

	/**
	 * Mise à jour des headers
	 */
	private setHeader(request: HttpRequest<any>) {
		//Clonage de la requête
		request = request.clone({
			headers: request.headers.set('X-AUTH-TOKEN',this.xAuthToken)
		});

		return request;
	}

	/**
	 * Renouvellement du token d'authentification
	 */
	private renewExpiredToken() {
		//Souscription sur le renouvellement
		this.renewSubject.subscribe({
			complete: () => {
				//Recréation du sujet
				this.renewSubject = new Subject<any>();
			}
		});

		//Vérification du nombre d'observateurs
		if (this.renewSubject.observers.length === 1)
			//Rafraichissement du token
			this.loginService.refreshToken().subscribe(this.renewSubject);

		return this.renewSubject;
	}

	/**
	 * Vérification de l'expiration du token dans la réponse
	 */
	private verifyExpiredToken(error: HttpErrorResponse): boolean {
		//Vérification du statut et du header
		return error.status && error.status === HttpStatusCode.Unauthorized && error.headers.has('TOKEN-EXPIRED');
	}
}