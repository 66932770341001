import { AutocompleteOptions } from '../autocomplete';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';

/**
 * Options des motifs workflow
 */
export default class WorkflowMotifOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.workflowMotif';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/WorkflowReferentiel/filtreMotifsFor';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.workflow.WorkflowMotif';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idMotif'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.entity && {
			clef: 'entity',
			valeur: filter.entity,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}
}