import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { User } from 'src/app/domain/user/user';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	templateUrl: './affectation-echeance-detail.component.html'
})
export class AffectationEcheanceDetailComponent implements OnInit {
	/** Détails de l'échéance de l'avantage en nature **/
	@Input() affectationEcheance: any;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<AffectationEcheanceDetailComponent>,private loggedUserService: LoggedUserService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();
	}
}