import { Component,Input,OnInit,ViewChild } from '@angular/core';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { mapTypesDroitForTypesFlux,TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { AttachmentComponent } from 'src/app/share/components/attachment/attachment.component';
import { FileWithProgress } from 'src/app/share/directive/file-uploader/file-uploader';
import { environment } from 'src/environments/environment';

@Component({
	templateUrl: './import-attachments.component.html'
})
export class ImportAttachmentsComponent implements OnInit {
	/** Connecteur sélectionné **/
	@Input() selectedConnecteur: any;

	/** Type de flux **/
	@Input() typeFlux: TypeFlux;

	/** Composant de gestion des attachments **/
	@ViewChild('attachment') attachmentComponent: AttachmentComponent;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des options
		this.attachmentOptions = {
			getUrl: () => `${environment.baseUrl}/controller/Connecteur/saveAttachement/${this.selectedConnecteur.idConnecteur}`,
			owningEntity: this.selectedConnecteur,
			typeAttachment: TypeAttachment.NON_DEFINI,
			listeTypesDroit: [mapTypesDroitForTypesFlux[this.typeFlux]],
			inline: true,
			withoutAttachmentSaving: true,
			filters: '.csv|.txt|.dat|.xls|.xlsx|.xml',
			onCompleteItem: (file: File,result: Result,linkAttachment: any) => {
				//Vérification de la liste des liens
				this.selectedConnecteur.listeLinks = this.selectedConnecteur.listeLinks || [];

				//Vérification du fichier
				linkAttachment.attachment.isVerified = result?.data?.isVerified;

				//Définition de l'identifiant du fichier externe
				linkAttachment.attachment.fileUid = result?.data?.fileUid;

				//Définition de l'identifiant de la prédiction de format
				linkAttachment.attachment.predictionId = result?.data?.predictionId;

				//Définition du type de retour chariot
				linkAttachment.attachment.typeRetourChariot = result?.data?.typeRetourChariot;

				//Ajout du lien
				this.selectedConnecteur.listeLinks.push(linkAttachment);
			},
			isFromLink: true
		};
	}

	/**
	 * Récupération de la queue des fichiers à uploader
	 */
	getUploadQueue(): Array<FileWithProgress> {
		//Retour de la queue
		return this.attachmentComponent?.fileUploader?.queue;
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	uploadAll() {
		//Upload de l'ensemble des fichiers
		this.attachmentComponent.uploadAll();
	}
}