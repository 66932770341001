<form #accountContactAdminForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="p-10">
		<div class="alert alert-info" *ngIf="notification.isMailSent">
			<icon name="send"></icon>
			<strong translate>account.notification.mailSent</strong>
		</div>
		<div class="template-bloc">
			<summernote [config]="summernoteConfig" #content="ngModel" name="content" [(ngModel)]="notification.content" read-only="true" lang="fr-FR"></summernote>
			<div class="g-recaptcha p-t-10 text-center" recaptcha #mailCaptcha="reCaptcha" (onRecaptchaExpired)="onRecaptchaExpired()" (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
		</div>
	</div>
</form>