import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PlanComptableService } from './plan-comptable.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	selector: 'plan-comptable-edit',
	templateUrl: './plan-comptable-edit.component.html'
})
export class PlanComptableEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() planComptable: any;

	/** Suppression du plan comptable **/
	@Input() deletePlanComptable: () => void;

	/** Résumé **/
	@Input() resume: any;

	/** Identifiant du plan comptable à dupliquer **/
	@Input() idPlanComptableSource: number;

	/**
	 * Constructeur
	 */
	constructor(private planComptableService: PlanComptableService,private translateService: TranslateService,private toastrService: ToastrService,private messagingService: MessagingService,private progressService: ProgressService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du plan comptable
	 */
	savePlanComptable() {
		//Vérification de la duplication du plan comptable
		if (this.idPlanComptableSource)
			//Duplication du plan comptable
			this.duplicatePlanComptable();
		else
			//Enregistrement du plan comptable
			this.doSavePlanComptable();
	}

	/**
	 * Réalisation de la sauvegarde d'un plan comptable
	 */
	doSavePlanComptable() {
		let messaging$: MessagingObservables;
		let refProgress: string = null;

		//Vérification de la création
		if (!this.planComptable.idPlanComptable) {
			//Initialisation de la progression
			refProgress = this.progressService.init({
				icone: 'autorenew',
				libelle: this.translateService.instant('comptabilite.planComptable.enregistrement.message'),
				nbTotal: 1,
				options: {
					canDismiss: false
				}
			});
		}

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'PUT',
			entryPoint: `controller/Compte/savePlanComptable`,
			outputPoint: '/messaging/compte/savePlanComptable/status',
			params: this.planComptable
		}).onStart({
			next: (idSession: string) => {
				//Mise à jour de l'identification de la session de la progression
				refProgress && this.progressService.updateProgress(refProgress,{ idSession });
			}
		}).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Vérification du bon enregistrement du plan comptable
				if (this.planComptable?.idPlanComptable) {
					//Rechargement du plan comptable
					this.planComptableService.loadPlanComptable(this.planComptable.idPlanComptable).subscribe(result => {
						//Vérification du nombre des comptes
						if (result?.data?.nbComptes)
							//Mise à jour du résumé
							this.resume.nbComptes = result.data.nbComptes;

						//Vérification de la présence d'une pop-up de progression
						if (refProgress) {
							//Actualisation des données de progression
							this.progressService.refreshProgress(refProgress,1,0,1);

							//Fermeture du formulaire d'édition
							this.close(result?.data?.planComptable || this.planComptable);
						}
					});
				}
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

				//Actualisation des données de progression
				refProgress && this.progressService.refreshProgress(refProgress,1,1,1);
			}
		}).onResult({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du plan comptable
					this.planComptable = result.data.planComptable;

					//Vérification de l'absence d'une pop-up de progression
					if (!refProgress)
						//Fermeture du formulaire d'édition
						this.close(this.planComptable);
				} else if (result.codeErreur === TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification de la date d'application
					if (result.data.doublon & Math.pow(2,1)) {
						//Ajout des libellés
						listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));
						listeDoublons.push(this.translateService.instant('actions.doublon.societe'));
					}

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				//Vérification de la présence d'une erreur
				if (result.codeErreur !== TypeCodeErreur.NO_ERROR) {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Actualisation des données de progression
					refProgress && this.progressService.refreshProgress(refProgress,1,1,1);
				}
			}
		});
	}

	/**
	 * Lancement de la duplication du plan comptable
	 */
	duplicatePlanComptable() {
		let messaging$: MessagingObservables;
		let refProgress: string;

		//Initialisation de la progression
		refProgress = this.progressService.init({
			icone: 'content_copy',
			libelle: this.translateService.instant('comptabilite.planComptable.duplication.message'),
			nbTotal: 1
		});

		//Exécution de la duplication
		messaging$ = this.messagingService.init({
			entryPoint: `controller/Compte/duplicatePlanComptable/${this.idPlanComptableSource}`,
			outputPoint: '/messaging/planComptable/duplicatePlanComptable/status/',
			params: this.planComptable
		}).onStart({
			next: (idSession: string) => {
				//Mise à jour de l'identification de la session de la progression
				this.progressService.updateProgress(refProgress,{ idSession });
			}
		}).onFinish({
			next: (message) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Actualisation des données de progression
				this.progressService.refreshProgress(refProgress,1,0,1);

				//Vérification du message
				if (message && message.nbProcessed) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Redirection vers le plan comptable
					this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
						routeParams: {
							idPlanComptable: message.reference,
							idPlanComptableSource: null
						},
						preserveSavedRoutes: true,
						reloadOnSameUrl: true
					});
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: [this.translateService.instant('actions.doublon.dateApplication'),this.translateService.instant('actions.doublon.societe')].join(', ')
					}));
				}
			}
		});
	}

	/**
	 * Vérification de la validité de la liste des sociétés du plan comptable
	 */
	isListeSocietesInvalid: () => boolean = () => !this.planComptable.listeSocietes?.length;
}