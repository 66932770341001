<div class="pull-left lv-avatar">{{ data.code2.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="" class="clickable" (click)="extraOptions.close(data)">
		<span class="fi" [ngClass]="'fi-'+(data.code2 != 'JA' ? data.code2.toLowerCase() : 'jp')"></span>
		<span class="m-l-10">
			<span>{{ data.libelle }}</span>
			<span *ngIf="data.libelleNatif">{{ ' - ' + data.libelleNatif }}</span>
		</span>
	</a>
</div>
<ul class="lv-attrs">
	<li><span translate>pays.codeISO2</span>&#160;:&#160;<span>{{ data.code2 }}</span></li>
</ul>