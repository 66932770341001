import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurReleveCompteurListItemComponent } from './conducteur-releve-compteur-list-item.component';

@Component({
	selector: 'conducteur-releve-compteur-list',
	templateUrl: './conducteur-releve-compteur-list.component.html'
})
export class ConducteurReleveCompteurListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Données **/
	liste: ListView<any,ConducteurReleveCompteurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConducteurReleveCompteurListItemComponent>({
			uri: `/controller/VehiculeCompteur/filtreCompteurs`,
			component: ConducteurReleveCompteurListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'extension.userAffecte.idUser',
				type: TypeFilter.LONG,
				valeur: this.conducteur.user.idUser
			}],
			defaultOrder: '-dateReleve,vehicule.reference'
		});
	}
}