import { Pipe,PipeTransform } from '@angular/core';
import { filter } from 'lodash-es';

@Pipe({
	name: 'filterBy',
	pure: false
})
export class FilterPipe implements PipeTransform {
	/**
	 * Filtrage des données
	 */
	transform(value: Array<any>,filtre: any): Array<any> {
		//Filtrage de la liste
		return filter(value,filtre);
	}
}
