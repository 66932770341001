import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { PaysLineItemComponent } from './pays.line-item';
import { PaysSearchItemComponent } from './pays.search-item';

/**
 * Options des types entités
 */
export default class PaysOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.pays';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/Geographie/findPaysListe${filter?.typeScope ? '/' + filter.typeScope : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? item.libelle + ' (' + item.code2 + ')' : ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.geographie.Pays';

	/**
	 * Récupération du nom de la clé
	 */
	getKeyFieldName() { return 'idPays'; }

	/**
	 * Composant d'affichage d'une ligne de pays
	 */
	lineItemComponent?: () => any = () => PaysLineItemComponent;

	/**
	 * Composant d'affichage d'une ligne de pays dans la recherche avancée
	 */
	searchItemComponent?: () => any = () => PaysSearchItemComponent;

	/**
	 * Tri par défaut
	 */
	defaultOrder = 'mapLang.*.libelle,libelle.raw,code2';

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'mapLang.*.libelle',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'code2',
			isDefault: true
		}];
	}
}