<form #autorisationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>tenant.autorisation.item</h2>
		</div>
		<div class="card-body card-padding">
			<autorisation-content #autorisationContent [autorisation]="autorisation" [source]="source" (close)="onContentClose($event)"></autorisation-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteAutorisation(autorisation,close,index);" *ngIf="autorisation?.idAutorisation && (TypeDroit.ADMIN_TENANT_AUTORISATION | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="autorisationContent.saveAutorisation();" *ngIf="TypeDroit.ADMIN_TENANT_AUTORISATION | right:'creation'" [disabled]="autorisationForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: autorisationForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>