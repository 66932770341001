import { Component,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { AccountArticleListItemComponent } from './account-article-list-item.component';

@Component({
	templateUrl: './account-article-list.component.html'
})
export class AccountArticleListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AccountArticleListItemComponent>;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AccountArticleListItemComponent>({
			uri: () => `/controller/Aide/filtreArticles`,
			component: AccountArticleListItemComponent,
			listeFilters: [{
				clef: 'titre',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: '*listeContents.titre',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'isActif',
				valeur: true,
				type: TypeFilter.BOOLEAN
			}],
			isLocal: true
		});
	}
}