<div class="modal-header">
	<h4 class="modal-title" [title]="options.title">{{ options.title }}</h4>
</div>
<div class="modal-body">
	<calendar [options]="options" class="d-flex m-15 m-b-25" style="align-items: center;"></calendar>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>