<div class="lv-item media">
	<div class="pull-left lv-avatar"><icon [type]="optionCategorieService.getTypeOptionForCode(detail?.option?.listeSubCategories[0]?.type)?.iconeType" [name]="optionCategorieService.getTypeOptionForCode(detail?.option?.listeSubCategories[0]?.type)?.icone"></icon></div>
	<span class="pull-right checkbox m-t-20 m-b-0" *ngIf="isShowCheckbox" [title]="getSelectorTitleForDetail(detail) || ''">
		<input type="checkbox" [(ngModel)]="detail.selected" (ngModelChange)="onOptionSelectionChange(detail)" [disabled]="detail?.option?.type == 'SERIE' || detail?.listeSourcesInclusion || detail?.listeSourcesObligation"/><i class="input-helper"></i>
	</span>
	<div class="media-body">
		<div class="lv-title word-break"><a [routerLink]="[]">{{ detail.option.libelle }}</a></div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="detail?.option?.listeSubCategories?.length"><span translate>vehicule.modele.configuration.categorieOption</span>&#160;:&#160;<span>{{ getListeSubCategories(detail.option) }}</span></li>
		<li *ngIf="configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT'" [title]="getSelectorTitleForDetail(detail) || ''">
			<span translate>vehicule.modele.configuration.prixHT</span>&#160;:&#160;
			<span *ngIf="getActualPrice(detail) == detail?.option?.prixHT">{{ detail?.option?.prixHT | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
			<span *ngIf="getActualPrice(detail) != detail?.option?.prixHT"><s>{{ detail?.option?.prixHT | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</s>&#160;{{ getActualPrice(detail) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
		</li>
		<li *ngIf="configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC'" [title]="getSelectorTitleForDetail(detail) || ''"><span translate>vehicule.modele.configuration.prixTTC</span>&#160;:&#160;
			<span *ngIf="getActualPrice(detail) == detail?.option?.prixTTC">{{ detail?.option?.prixTTC | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
			<span *ngIf="getActualPrice(detail) != detail?.option?.prixTTC"><s>{{ detail?.option?.prixTTC | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</s>&#160;{{ getActualPrice(detail) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
		</li>
	</ul>
</div>