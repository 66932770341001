
<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Fournisseur/loadFournisseur/',liste.selectedSelector,data.idFournisseur]">{{ data.libelle }}&#160;({{ data.reference }})</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.listeMetiers?.length > 0" [tooltip]="getListeMetiers()"><span translate>fournisseur.liste.metiersAssocies</span>&#160;:&#160;{{ data.listeMetiers.length }}</li>
		<li *ngIf="liste.selectedSelector == 'LOCAL' && !data.fournisseurOrigine"><span translate>referentiel.nonImporte</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteFournisseur();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idFournisseur)"><span translate>actions.importer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>