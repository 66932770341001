<div class="lv-item media">
	<div class="pull-left lv-avatar">{{ data?.type?.substring(0,2)?.toUpperCase() }}</div>
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions.addAuthConfig(data,deleteAuthConfig)">{{ data?.libelle + (data?.type ? ' (' + ('authentification.configuration.type.'+data?.type | translate) + ')' : '') }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.clientId"><span translate>authentification.configuration.clientId</span>&#160;:&#160;<span>{{ data.clientId }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.ADMIN_AUTHENTIFICATION | right:'suppression'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="deleteAuthConfig()"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>