import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { UserCategorieListItemComponent } from './user-categorie-list-item.component';

@Component({
	templateUrl: './user-categorie-list.component.html'
})
export class UserCategorieListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,UserCategorieListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UserCategorieListItemComponent>({
			uri: `/controller/UserReferentiel/filtreCategories`,
			title: this.translateService.instant('categorie.liste.title'),
			component: UserCategorieListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(i => !i.idCategorie) && this.liste.addItem({
					isDisplayed: true,
					actif: true
				});
			})
		});
	}
}