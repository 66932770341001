import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { WorkflowService } from './workflow.service';

@Component({
	selector: 'workflow-detail',
	templateUrl: './workflow-detail.component.html'
})
export class WorkflowDetailComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() workflow: any;

	/** Activation/désactivation du mode 'Plein écran' **/
	@Input() readonly toggleFullScreen: Function;

	/** Passage en mode édition **/
	@Input() readonly addWorkflowDetail?: Function;

	/** Mode édition **/
	@Input() readonly isEdition?: boolean = false;

	/** Mode small **/
	public isSmall: boolean = false;

	/** Copie du workflow (pour comparaison) **/
	private workflowCopy: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private confirmService: ConfirmService,private workflowService: WorkflowService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Copie du workflow
		this.workflowCopy = cloneDeep(this.workflow);
	}

	/**
	 * Vérification d'un changement
	 */
	isDirty = () => !isEqual(this.workflow,this.workflowCopy);

	/**
	 * Enregistrement du workflow
	 */
	public saveWorkflow() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('workflow.detail.confirmation.enregistrement'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.workflowService.saveWorkflow(this.workflow))
		).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.workflow,result.data?.workflow);

					//Fermeture du formulaire
					this.close({ workflow: this.workflow });
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du reference
							listeDoublons.push(this.translateService.instant('actions.doublon.dateApplication'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}