<list-view [liste]="liste" *ngIf="liste && !selectedGrilleAttribution"></list-view>

<div class="listview lv-bordered">
	<div class="lv-body">
		<ng-container *ngIf="liste.data?.content?.length > 0">
			<div class="lv-item media" *ngFor="let grilleAttribution of liste?.data?.content">
				<div class="pull-left lv-avatar">
					<span class="icon-hc-stack" *ngIf="!grilleAttribution.specifique">
						<icon name="folder" class="icon-hc-stack-2x"></icon>
						<icon name="directions_car" class="icon-hc-stack-1x icon-hc-inverse"></icon>
					</span>
					<icon *ngIf="grilleAttribution.specifique" name="star"></icon>
				</div>
				<div class="media-body">
					<div class="lv-title"><a [routerLink]="[]" (click)="selectGrilleAttribution(grilleAttribution);">{{ grilleAttribution | display:'grilleAttribution' }}</a></div>
					<ul class="lv-attrs">
						<li *ngIf="grilleAttribution.nbModeles"><span translate>demandeVehicule.selection.modele.grilleAttribution.nbModeles</span>&#160;:&#160;<span>{{ grilleAttribution.nbModeles }}</span></li>
					</ul>
					<ul class="lv-actions actions" *ngIf="selectedGrilleAttribution || (TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'consultation')">
						<li *ngIf="selectedGrilleAttribution"><a [routerLink]="[]" (click)="selectGrilleAttribution(null);"><icon name="close"></icon></a></li>
						<li *ngIf="!selectedGrilleAttribution && TypeDroit.VEHICULE_GRILLE_ATTRIBUTION"><a [routerLink]="[]" (click)="goToGrilleAttribution(grilleAttribution);"><icon name="search"></icon></a></li>
					</ul>
				</div>
			</div>
		</ng-container>
		<div class="text-center p-10" *ngIf="!liste.data?.content">
			<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
		</div>
		<div class="text-center p-10" *ngIf="liste.data?.content?.length === 0">
			<h5 translate>liste.empty</h5>
		</div>
	</div>
</div>