<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3) }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Vehicule/loadPool/',data.idPool]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>pool.reservationAutomatique</span>&#160;:&#160;<span [translate]="'common.'+(data?.reservationAutomatique ? 'oui' : 'non')"></span></li>
		<li><span translate>pool.etatLieuxAutomatique</span>&#160;:&#160;<span [translate]="'common.'+(data?.etatLieuxAutomatique ? 'oui' : 'non')"></span></li>
		<li><span translate>pool.demandeReleveCompteurAutomatique</span>&#160;:&#160;<span [translate]="'common.'+(data?.demandeReleveCompteurAutomatique ? 'oui' : 'non')"></span></li>
		<li [tooltip]="getListeGroupes('com.notilus.data.user.User')"><span translate>pool.nbGroupesCollaborateur</span>&#160;:&#160;<span>{{ ((data.listeGroupes | filterBy:{ entity:'com.notilus.data.user.User' })?.length || 0) | number }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deletePool();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>