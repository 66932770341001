import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PaysListItemComponent } from './pays-list-item.component';
import { PaysListComponent } from './pays-list.component';
import { PaysComponent } from './pays.component';
import { PaysService } from './pays.service';
import { PaysScopeComponent } from './scope/pays-scope.component';

@NgModule({
	imports: [ShareModule],
	declarations: [PaysListComponent,PaysListItemComponent,PaysComponent,PaysScopeComponent],
	providers: [PaysService]
})
export class PaysModule {}
