import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';
import { FournisseurAddComponent } from 'src/app/components/fournisseur/fournisseur-add.component';

/**
 * Options des fournisseurs
 */
export default class FournisseurOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.fournisseur';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/Fournisseur/listeFournisseursActifs/${filter?.typeReferentiel || TypeReferentiel.LOCAL}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle} (${item?.reference ? item?.reference : ''})` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.fournisseur.Fournisseur';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idFournisseur'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.typeSaisie && {
			clef: 'typeSource',
			valeur: filter.typeSaisie,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		},!filter?.isShowAll && {
			clef: 'isActif',
			valeur: true,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Liste des champs supplémentaires pour la recherche avancée
		return [{
			title: 'fournisseur.liste.nonImporte',
			getValue: (item: any,translateService: TranslateService) => translateService.instant(`common.${item && !item.fournisseurOrigine ? 'oui' : 'non'}`)
		}];
	}

	/**
	 * Libellé de l'option de création à la volée
	 */
	addItemLibelle?: string = 'fournisseur.add.item';

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return FournisseurAddComponent; }

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.ADMIN_FOURNISSEUR;
}