<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<span *ngIf="data.etat == 'VALIDE'">{{ ('facture.type.'+data.type | translate).substring(0,1) }}</span>
	<span *ngIf="data.etat == 'BROUILLON'"><icon name="question_mark"></icon></span>
</div>
<div class="lv-title">
	<a *ngIf="data.etat == 'VALIDE'" [routerLink]="['/Facture/loadFacture',data.idFacture]">{{ data.reference + ' (' + data.fournisseur?.libelle + ')' }}</a>
	<a *ngIf="data.etat == 'BROUILLON'" class="clickable" [routerLink]="" (click)="factureService.showFactureAnalysis(data)">{{ 'facture.lad.title' | translate:{ id: data.idFacture,date: data.analysis?.dateCreation | date:'shortDate' } }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.montant"><span translate>facture.montant</span>&#160;:&#160;<span>{{ data.montant | currency:'.2-2':data.devise }}</span></li>
	<li *ngIf="data.etat == 'VALIDE' && data.date || data.etat == 'BROUILLON' && data.analysis?.dateEmission"><span translate>facture.date.item</span>&#160;:&#160;<span>{{ (data.etat == 'VALIDE' ? data.date : data.analysis?.invoiceDate) | date:'shortDate' }}</span></li>
	<li *ngIf="data.etat == 'VALIDE'"><span translate>facture.statut.item</span>&#160;:&#160;<span [translate]="'facture.statut.'+data.statut"></span></li>
	<li *ngIf="data.anomalie"><span translate>facture.anomalie</span>&#160;:&#160;<span translate>common.oui</span></li>
</ul>

<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'creation') && data.statut == 'INTEGREE' && !data.anomalie && !data.exclusionCompta && data.etat != 'BROUILLON'"><a [routerLink]="[]" (click)="doAction('VALIDER',data)" translate>actions.valider</a></li>
		<li *ngIf="(null | right:'creation') && data.statut == 'VALIDEE' && !data.exclusionCompta && data.etat != 'BROUILLON'"><a [routerLink]="[]" (click)="doAction('REJETER',data)" translate>actions.rejeter</a></li>
		<li *ngIf="(TypeDroit.ADMIN_PLAN_COMPTABLE | right:'consultation') && data.lotComptable"><a [routerLink]="[]" (click)="goToLotComptable(data.lotComptable)"><span translate>facture.actions.accederLotComptable</span></a></li>
		<li *ngIf="(null | right:'suppression') && data.statut == 'INTEGREE' || (TypeDroit.FACTURE_LAD | right:'suppression') && data.etat == 'BROUILLON'"><a [routerLink]="[]" (click)="deleteFacture()" translate>actions.supprimer</a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>