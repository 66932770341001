import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { NatureCodeListItemComponent } from './nature-code-list-item.component';

@Component({
	selector: 'nature-code-list',
	templateUrl: './nature-code-list.component.html'
})
export class NatureCodeListComponent extends PageContentItem implements OnInit {
	/** Nature **/
	@Input() nature: any;

	/** Données **/
	liste: ListView<any,NatureCodeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,NatureCodeListItemComponent>({
			uri: () => `/controller/Fournisseur/filtreCodes`,
			title: this.translateService.instant('naure.elements.code.title'),
			component: NatureCodeListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'nature.idNature',
				valeur: this.nature.idNature,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}
}