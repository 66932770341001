<div class="container-fluid">
	<form #connecteurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
								<label>
									<span translate>connecteur.title</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'connecteur.modificationImpossible' | translate" *ngIf="resume.nbAppels > 0"></icon>
								</label>
								<div>
									<autocomplete name="type" type="connecteurType" [filter]="!user?.tenant?.tenantDescription?.integrationAvailable && { typeExecution: 'MIDDLEWARE' }" #type="ngModel" [(ngModel)]="connecteur.type" required [disabled]="resume.nbAppels > 0" (ngModelChange)="onConnecteurTypeChange()"></autocomplete>
								</div>
							</div>
							<div class="form-group" *ngIf="connecteur.type?.typeConnecteur == 'API'">
								<label translate>connecteur.parametre.title</label>
								<div>
									<a [routerLink]="[]" (click)="showParams()" [translate]="'connecteur.parametre.nbParams' | pluralTranslate:(connecteur.type?.listeParams?.length || 0)" [translateParams]="{ nbParams: connecteur.type?.listeParams?.length || 0 }"></a>
								</div>
							</div>
							<div class="form-group" *ngIf="connecteur.type">
								<label translate>connecteur.notificationAdmin.item</label>
								<div>
									<mat-checkbox name="notificationAdmin" #actif="ngModel" [(ngModel)]="connecteur.notificationAdmin"><span translate>connecteur.notificationAdmin.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': !connecteur.listeEmails?.length && !connecteur.notificationAdmin }">
								<label translate>connecteur.email.title</label>
								<div>
									<a [routerLink]="[]" (click)="showDestinataires()" [translate]="'connecteur.email.nbEmails' | pluralTranslate:(connecteur.listeEmails?.length || 0)" [translateParams]="{ nbEmails: connecteur.listeEmails?.length || 0 }"></a>
								</div>
							</div>
							<div class="form-group" *ngIf="connecteur.type?.typeConnecteur == 'API'">
								<label translate>connecteur.planification</label>
								<div>
									<scheduler name="scheduler" [(ngModel)]="connecteur.scheduler"></scheduler>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': environnement.invalid }" *ngIf="connecteur.type?.typeConnecteur == 'API' && (loggedUserService.getUserOrigine() || rightService.isRoot())">
								<label translate>connecteur.environnement.title</label>
								<div>
									<selectpicker name="environnement" #environnement="ngModel" [(ngModel)]="connecteur.typeEnvironnement">
										<mat-option *ngFor="let typeEnvironnement of listeTypesEnvironnement" [value]="typeEnvironnement.code">{{ typeEnvironnement.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<ng-container *ngIf="connecteur.type?.typeConnecteur == 'INTERFACE'">
								<div class="form-group" [ngClass]="{ 'has-error': version.invalid }" *ngIf="connecteur.type?.fournisseurInterface?.typeExecution != 'MIDDLEWARE_STABLE'">
									<label translate>connecteur.version</label>
									<div class="half">
										<selectpicker name="version" #version="ngModel" [(ngModel)]="connecteur.version" [required]="connecteur.type.fournisseurInterface.listeVersions?.length">
											<mat-option *ngFor="let version of connecteur.type.fournisseurInterface.listeVersions" [value]="version">{{ version }}</mat-option>
										</selectpicker>
									</div>
								</div>
								<div class="form-group" [ngClass]="{ 'has-error': typeExecution.invalid }" *ngIf="connecteur.type?.fournisseurInterface?.typeExecution != 'NON_DEFINI'">
									<label translate>connecteur.typeExecution.item</label>
									<div class="half">
										<selectpicker name="typeExecution" #typeExecution="ngModel" [(ngModel)]="connecteur.typeExecution" required [disabled]="connecteur.type?.fournisseurInterface?.typeExecution == 'MIDDLEWARE_STABLE'">
											<mat-option *ngFor="let typeExecution of listeTypesExecution" [value]="typeExecution.code">{{ typeExecution.libelle }}</mat-option>
										</selectpicker>
									</div>
								</div>
							</ng-container>
						</div>
						<div class="col-md-6">
							<div class="form-group" *ngIf="connecteur.type?.typeConnecteur == 'INTERFACE'">
								<label>
									<span translate>connecteur.password.title</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'connecteur.password.description' | translate"></icon>
								</label>
								<div>
									<a [routerLink]="[]" (click)="showPasswords()" [translate]="'connecteur.password.nbPasswords' | pluralTranslate:(connecteur.listePasswords?.length || 0)" [translateParams]="{ nbPasswords: connecteur.listePasswords?.length || 0 }"></a>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteConnecteur();" *ngIf="connecteur?.idConnecteur && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="connecteurForm.invalid || !connecteur.listeEmails?.length && !connecteur.notificationAdmin" (click)="saveConnecteur();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.' + (connecteur?.idConnecteur ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="connecteur?.idConnecteur" [formDetectChanges]="{ doAction: close,isDirty: connecteurForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>