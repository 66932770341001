import { Component,EventEmitter,Input,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,tap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { StructureExportLineComponent } from './structure-export-line.component';
import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export-definition-line',
	templateUrl: './structure-export-definition-line.component.html'
})
export class StructureExportDefinitionLineComponent {
	/** Structure d'export **/
	@Input() structureExport: any;

	/** Fichier courant **/
	@Input() fichierDetail: any;

	/** Détail parent **/
	@Input() detail: any;

	/** Elément courant **/
	@Input() item: any;

	/** Position de l'élément **/
	@Input() idxItem: number;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Mise à jour de la structure d'export **/
	@Output() onStructureExportUpdate: EventEmitter<any> = new EventEmitter<any>();

	/** Rafraichissement de l'onglet 'Définition' **/
	@Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private pageContentService: PageContentService) {}

	/**
	 * Duplication de la l'élément
	 */
	duplicateItem() {
		let copiedItem: any;

		//Copie de la ligne
		copiedItem = cloneDeep(this.item);

		//Ajout dans la liste des items du detail
		this.detail.listeItems.splice(this.idxItem + 1,0,copiedItem = Object.assign(copiedItem,{
			idItem: null,
			position: null,
			valid: false
		}));

		//Suppression de l'identifiant de la ligne
		copiedItem.ligne.idLigne = null;

		//Modification du libellé
		copiedItem.ligne.libelle = copiedItem.ligne.libelle + ' - ' + this.translateService.instant('structureExport.duplication.copie');

		//Sauvegarde du détail
		this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la structure d'export
				this.onStructureExportUpdate.emit(result.data.structureExport);

				//Rafraichissement de l'onglet 'Définition'
				this.onRefresh.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Suppression de l'élément
	 */
	removeItem() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			tap(() => {
				//Vérification de la liste
				if (this.detail.listeItems?.length > 1)
					//Retrait de l'objet
					this.detail.listeItems.splice(this.idxItem,1);
				else
					//Ré-initialisation de la liste
					this.detail.listeItems = [];
			}),
			switchMap(() => this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Mise à jour de la structure d'export
				this.onStructureExportUpdate.emit(result.data.structureExport);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Déplacement de l'élément
	 */
	moveItem(delta: number) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.deplacement.confirmation'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			tap(() => {
				let detail: any;

				//Récupération de l'élément
				detail = this.detail.listeItems.splice(this.idxItem,1)[0];

				//Déplacement de l'élément
				this.detail.listeItems.splice(this.idxItem + delta,0,detail);
			}),
			switchMap(() => this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.detail))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la structure d'export
				this.onStructureExportUpdate.emit(result.data.structureExport);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Affichage de l'élément
	 */
	showItem() {
		//Ouverture du composant d'édition
		this.pageContentService.open(StructureExportLineComponent,{
			data: {
				structureExport: this.structureExport,
				fichierDetail: this.fichierDetail,
				ligne: this.item.ligne,
				isRestricted: this.isRestricted,
				onStructureExportUpdate: ({ structureExport,ligne }) => {
					//Mise à jour de la ligne
					ligne && Object.assign(this.item.ligne,ligne);

					//Mise à jour de la structure d'export
					this.onStructureExportUpdate.emit(structureExport);
				}
			}
		},'sub').subscribe({
			next: (result?: { structureExport?: any }) => {
				//Vérification de la structure d'export
				if (result?.structureExport)
					//Mise à jour de la structure d'export
					this.onStructureExportUpdate.emit(result.structureExport);

				//Rafraichissement de l'onglet 'Définition'
				this.onRefresh.emit();
			}
		});
	}
}