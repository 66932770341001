<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<span>{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</span>
</div>
<ul class="lv-attrs" *ngIf="data.idCode">
	<li><span translate>nature.elements.code.liste.format</span>&#160;:&#160;<span>{{ data.interfaceFournisseur?.libelle ? data.interfaceFournisseur.libelle : ('common.aucune' | translate) }}</span></li>
	<li><span translate>nature.elements.code.liste.fournisseur</span>&#160;:&#160;<span>{{ data.interfaceFournisseur?.fournisseur?.libelle ? data.interfaceFournisseur.fournisseur.libelle : ('common.aucun' | translate) }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="rightService.isRoot() && (TypeDroit.ADMIN_FOURNISSEUR_INTERFACE | right:'consultation')">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>