<form #financementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.grilleAttribution.modele.elements.financement.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
							<label translate>vehicule.grilleAttribution.modele.financement.fournisseur</label>
							<div>
								<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="financement.fournisseur" (ngModelChange)="financement.typeFinancement = null;" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
							<label translate>vehicule.grilleAttribution.modele.financement.typeFinancement</label>
							<div>
								<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="financement.typeFinancement" [filter]="{ isWithGrilleFluidite: financement.fournisseur?.grilleFluidite && (TypeDroit.GRILLE_FLUIDITE | right:'creation') }" required></autocomplete>
							</div>
						</div>
						<ng-container *ngIf="financement.typeFinancement && financement.typeFinancement?.mode != 'ACQUISITION' && financement.typeFinancement?.mode != 'GRILLE_FLUIDITE'">
							<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
								<label translate>vehicule.grilleAttribution.modele.financement.duree</label>
								<div class="half">
									<input type="text" name="duree" #duree="ngModel" class="form-control" [(ngModel)]="financement.duree" nNumber min="0" max="300" (ngModelChange)="computePrixRevientKilometrique()" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': distance.invalid }">
								<label translate>vehicule.grilleAttribution.modele.financement.distance</label>
								<div>
									<div class="input-group">
										<input type="text" name="distance" #distance="ngModel" class="form-control" [(ngModel)]="financement.distance" nNumber="0" (ngModelChange)="computePrixRevientKilometrique()" required/>
										<div class="input-group-addon" *ngIf="user.unite">
											<span>{{ user.unite.libelleCourt }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeAffichagePrix.invalid }">
								<label translate>vehicule.grilleAttribution.modele.financement.typeAffichagePrix.item</label>
								<div>
									<selectpicker name="typeAffichagePrix" #typeAffichagePrix="ngModel" [(ngModel)]="financement.typeAffichagePrix" required>
										<mat-option *ngFor="let typeAffichagePrix of listeTypesAffichagePrix" [value]="typeAffichagePrix.code">{{ typeAffichagePrix.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': loyerMensuel.invalid }">
								<label translate>vehicule.grilleAttribution.modele.financement.loyerMensuel</label>
								<div>
									<amount name="loyerMensuel" #loyerMensuel="ngModel" [(ngModel)]="financement.loyerMensuel" [devise]="financement.devise" (onDeviseChange)="financement.devise = $event;" (ngModelChange)="computePrixRevientKilometrique()" required></amount>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
				<ng-container *ngIf="financement.typeFinancement && financement.typeFinancement?.mode != 'ACQUISITION' && financement.typeFinancement?.mode != 'GRILLE_FLUIDITE'">
					<div class="form-group">
						<label translate>vehicule.grilleAttribution.modele.financement.detailLoyers</label>
						<div>
							<a (click)="financement.isDetailLoyerDisplayed = !financement.isDetailLoyerDisplayed" [routerLink]="[]">
								<span translate>vehicule.grilleAttribution.modele.financement.actions.toggleDetailLoyer</span>
								<icon class="p-l-10" [name]="!financement.isDetailLoyerDisplayed ? 'chevron_right' : 'expand_more'"></icon>
							</a>
						</div>
					</div>
					<div [collapse]="!financement.isDetailLoyerDisplayed">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerFinancier.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerFinancier</label>
									<div class="half">
										<input type="text" name="loyerFinancier" #loyerFinancier="ngModel" class="form-control" [(ngModel)]="financement.loyerFinancier" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerMaintenance.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerMaintenance</label>
									<div class="half">
										<input type="text" name="loyerMaintenance" #loyerMaintenance="ngModel" class="form-control" [(ngModel)]="financement.loyerMaintenance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAssistance.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerAssistance</label>
									<div class="half">
										<input type="text" name="loyerAssistance" #loyerAssistance="ngModel" class="form-control" [(ngModel)]="financement.loyerAssistance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGarantiePerteFinanciere.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerGarantiePerteFinanciere</label>
									<div class="half">
										<input type="text" name="loyerGarantiePerteFinanciere" #loyerGarantiePerteFinanciere="ngModel" class="form-control" [(ngModel)]="financement.loyerGarantiePerteFinanciere" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGestionCarburant.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerGestionCarburant</label>
									<div class="half">
										<input type="text" name="loyerGestionCarburant" #loyerGestionCarburant="ngModel" class="form-control" [(ngModel)]="financement.loyerGestionCarburant" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerGestionSinistres.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerGestionSinistres</label>
									<div class="half">
										<input type="text" name="loyerGestionSinistres" #loyerGestionSinistres="ngModel" class="form-control" [(ngModel)]="financement.loyerGestionSinistres" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerTelematique.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerTelematique</label>
									<div class="half">
										<input type="text" name="loyerTelematique" #loyerTelematique="ngModel" class="form-control" [(ngModel)]="financement.loyerTelematique" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerPneumatiques.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerPneumatiques</label>
									<div class="half">
										<input type="text" name="loyerPneumatiques" #loyerPneumatiques="ngModel" class="form-control" [(ngModel)]="financement.loyerPneumatiques" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerVehiculeRemplacement.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerVehiculeRemplacement</label>
									<div class="half">
										<input type="text" name="loyerVehiculeRemplacement" #loyerVehiculeRemplacement="ngModel" class="form-control" [(ngModel)]="financement.loyerVehiculeRemplacement" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAssurance.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerAssurance</label>
									<div class="half">
										<input type="text" name="loyerAssurance" #loyerAssurance="ngModel" class="form-control" [(ngModel)]="financement.loyerAssurance" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [ngClass]="{ 'has-error': loyerAutres.invalid }">
									<label translate>vehicule.grilleAttribution.modele.financement.loyerAutres</label>
									<div class="half">
										<input type="text" name="loyerAutres" #loyerAutres="ngModel" class="form-control" [(ngModel)]="financement.loyerAutres" nNumber="2" min="0"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>vehicule.grilleAttribution.modele.financement.prixRevientKilometrique</label>
						<div>
							<span>{{ ((financement.coutDistance || 0) | currency:financement.devise:undefined:'1.3') + '/' + user.unite.libelleCourt }}</span>
						</div>
					</div>
				</ng-container>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" *ngIf="financement.typeFinancement?.mode == 'ACQUISITION'" [ngClass]="{ 'has-error': prixAchat.invalid }">
							<label translate>vehicule.grilleAttribution.modele.financement.prixAchatTTC</label>
							<div>
								<amount name="prixAchat" #prixAchat="ngModel" [(ngModel)]="financement.prixAchat" [devise]="financement.devise" (onDeviseChange)="financement.devise = $event;" required></amount>
							</div>
						</div>
						<div class="form-group">
							<label translate>common.piecesJointes</label>
							<div>
								<div [attachment]="financement" [typeAttachment]="TypeAttachment.GRILLE_ATTRIBUTION_MODELE_FINANCEMENT"></div>
							</div>
						</div>
						<div class="form-group" *ngIf="financement.fournisseur?.grilleFluidite && financement.typeFinancement?.mode == 'GRILLE_FLUIDITE' && (TypeDroit.GRILLE_FLUIDITE | right:'creation')">
							<label translate>vehicule.grilleAttribution.modele.financement.grilleFluidite.item</label>
							<div>
								<a [routerLink]="[]" *ngIf="!financement.grilleFluidite" (click)="showImportGrilleFluidite();" translate>vehicule.grilleAttribution.modele.financement.grilleFluidite.showImport</a>
								<a [routerLink]="[]" *ngIf="financement.grilleFluidite" (click)="showGrilleFluidite()" translate>vehicule.grilleAttribution.modele.financement.grilleFluidite.showGrilleFluidite</a>
							</div>
						</div>
						<div class="form-group" *ngIf="financement.reponseCotation?.cotation">
							<label translate>vehicule.grilleAttribution.modele.financement.reponseCotation.item</label>
							<div>
								<a [routerLink]="[]" (click)="showComparaisonForCotation()" translate [translateParams]="{ reference: financement.reponseCotation?.cotation?.demandeCotation?.idDemandeCotation }">vehicule.grilleAttribution.modele.financement.reponseCotation.message</a>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.grilleAttribution.modele.financement.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="financement.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.grilleAttribution.modele.financement.valableCommandes</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
							<label translate>vehicule.grilleAttribution.modele.financement.dateDebut.item</label>
							<div class="input-group half">
								<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="financement.dateDebut" datePicker format="date" [maxDate]="financement.dateFin"/>
								<div class="input-group-addon">
									<icon name="info_outline" [tooltip]="'vehicule.grilleAttribution.modele.financement.dateDebut.info' | translate"></icon>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
							<label translate>vehicule.grilleAttribution.modele.financement.dateFin.item</label>
							<div class="input-group half">
								<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="financement.dateFin" datePicker format="date" [minDate]="financement.dateDebut"/>
								<div class="input-group-addon">
									<icon name="info_outline" [tooltip]="'vehicule.grilleAttribution.modele.financement.dateFin.info' | translate"></icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteFinancement(financement,close);" *ngIf="deleteFinancement && financement?.idFinancement && (TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveFinancement();" *ngIf="TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation'" [disabled]="financementForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: doClose.bind(this),isDirty: financementForm.dirty && isDirty() }" (beforeAction)="onBeforeAction($event)">
		<span translate>actions.fermer</span>
	</button>
</form>