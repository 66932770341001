import { Component,Input,EventEmitter,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter,switchMap,tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { StructureExportService } from './structure-export.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { StructureExportFichierComponent } from './structure-export-fichier.component';
import { StructureExportGroupeComponent } from './structure-export-groupe.component';

@Component({
	selector: 'structure-export-definition-detail',
	templateUrl: './structure-export-definition-detail.component.html'
})
export class StructureExportDefinitionDetailComponent {
	/** Structure d'export **/
	@Input() structureExport: any;

	/** Fichier courant **/
	@Input() fichierDetail: any;

	/** Détail courant **/
	@Input() detail: any;

	/** Détail parent **/
	@Input() parentDetail?: any;

	/** Position du détail **/
	@Input() idxDetail?: number;

	/** Mode 'Light' **/
	@Input() isModeLight?: boolean;

	/** Mode 'Edition' **/
	@Input() isEdition?: boolean;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Mise à jour de la structure d'export **/
	@Output() onStructureExportUpdate: EventEmitter<any> = new EventEmitter<any>();

	/** Rafraichissement de l'onglet 'Définition' **/
	@Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,private structureExportService: StructureExportService,private toastrService: ToastrService,private pageContentService: PageContentService) {}

	/**
	 * Ajout d'une ligne
	 */
	addLine(detail: any,parentDetail?: any,idxDetail?: number) {
		let item: any;

		//Récupération de l'élément qui porte le détail
		item = parentDetail?.listeItems?.[idxDetail];

		//Affichage de la popup d'ajout d'un type de détail
		this.structureExportService.showTypeLigneSelection(detail.typeDetail,!!parentDetail).subscribe({
			next: listeSelectedItems => {
				let newParent: any;

				//Parcours des éléments à ajouter
				listeSelectedItems.forEach(selectedItem => this.doAddLigne(parentDetail || detail,selectedItem,idxDetail));

				//Vérification que l'on a ajouté un nouveau parent au détail
				if (parentDetail) {
					//Récupération du nouveau parent
					newParent = parentDetail.listeItems[idxDetail].detail;

					//Ajout du détail dans son nouveau parent
					this.doAddLigne(newParent,item);

					//Sauvegarde du nouveau parent
					this.structureExportService.saveNewParentDetail(this.fichierDetail.idDetail,detail.idDetail,newParent).subscribe((result: Result) => {
						//Vérification du code d'erreur
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour de la structure d'export
							this.onStructureExportUpdate.emit(result.data.structureExport);
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					});
				} else {
					//Sauvegarde du détail
					this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail).subscribe((result: Result) => {
						//Vérification du code d'erreur
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour du détail
							Object.assign(this.fichierDetail,result.data.detail);

							//Mise à jour de la structure d'export
							this.onStructureExportUpdate.emit(result.data.structureExport);
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					});
				}
			}
		});
	}

	/**
	 * Ajout d'une ligne à un détail
	 */
	private doAddLigne(detail: any,item: any,index?: number) {
		let isReplace: boolean;
		let typeLigne: string;

		//Récupération du type d'insertion à effectuer
		isReplace = index !== undefined;

		//Vérification du détail
		if (!detail.listeItems)
			//Initialisation de la liste des éléments
			detail.listeItems = [];

		//Vérification du type d'insertion
		if (!isReplace) {
			//Initialisation de l'index (fin de liste)
			index = detail.listeItems.length;

			//Récupération du type de ligne
			typeLigne = item.ligne && item.ligne.typeLigne || null;

			//Vérification du type de détail
			if (detail.typeDetail == 'FICHIER') {
				//Vérification du type de ligne
				if (typeLigne == 'FICHIER_ENTETE') {
					//Recherche de la fin des entêtes
					index = detail.listeItems.findIndex(item => !item.ligne || item.ligne && item.ligne.typeLigne != 'FICHIER_ENTETE');
				} else if (typeLigne != 'FICHIER_PIED') {
					//Recherche du premier pied
					index = detail.listeItems.findIndex(item => item.ligne && item.ligne.typeLigne == 'FICHIER_PIED');

					//Vérification de l'index
					index = index != -1 ? index : detail.listeItems.length;
				}
			} else if (detail.typeDetail == 'GROUPE') {
				//Vérification du type de ligne
				if (typeLigne == 'GROUPE_ENTETE') {
					//Recherche de la fin des entêtes
					index = detail.listeItems.findIndex(item => !item.ligne || item.ligne && item.ligne.typeLigne != 'GROUPE_ENTETE');
				} else if (typeLigne != 'GROUPE_PIED') {
					//Recherche du premier pied
					index = detail.listeItems.findIndex(item => item.ligne && item.ligne.typeLigne == 'GROUPE_PIED');

					//Vérification de l'index
					index = index != -1 ? index : detail.listeItems.length;
				}
			}
		}

		//Ajout de l'élément à la liste
		detail.listeItems.splice(index,isReplace ? 1 : 0,item);
	}

	/**
	 * Déplacement du détail
	 */
	moveDetail(parentDetail: any,idxDetail: number,delta: number) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.deplacement.confirmation'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			tap(() => {
				let detail: any;

				//Récupération de l'élément
				detail = parentDetail.listeItems.splice(idxDetail,1)[0];

				//Déplacement de l'élément
				parentDetail.listeItems.splice(idxDetail + delta,0,detail);
			}),
			switchMap(() => this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,parentDetail))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de la structure d'export
				this.onStructureExportUpdate.emit(result.data.structureExport);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Suppression du détail
	 */
	removeDetail(parentDetail: any,idxDetail: number) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			tap(() => {
				//Vérification de la liste
				if (parentDetail.listeItems?.length > 1)
					//Retrait de l'objet
					parentDetail.listeItems.splice(idxDetail,1);
				else
					//Ré-initialisation de la liste
					parentDetail.listeItems = [];
			}),
			switchMap(() => this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.fichierDetail))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Mise à jour du détail
				Object.assign(this.fichierDetail,result.data.detail);

				//Mise à jour de la structure d'export
				this.onStructureExportUpdate.emit(result.data.structureExport);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Affichage du détail
	 */
	showDetail(typeDetail: string,detail: any) {
		//Ouverture du composant d'édition
		this.pageContentService.open(typeDetail == 'FICHIER' ? StructureExportFichierComponent : StructureExportGroupeComponent,{
			data: {
				structureExport: this.structureExport,
				fichierDetail: this.fichierDetail,
				detail,
				isRestricted: this.isRestricted
			}
		},'sub').subscribe({
			next: (result?: { structureExport?: any,detail?: any,isDeleted?: boolean }) => {
				//Vérification de la structure d'export
				if (result?.structureExport)
					//Mise à jour de la structure d'export
					this.onStructureExportUpdate.emit(result.structureExport);

				//Vérification de la suppression
				if (result?.isDeleted || result.detail)
					//Rafraichissement de l'onglet 'Définition'
					this.onRefresh.emit();
			}
		});
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}
}