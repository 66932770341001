import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DemandeVehiculeAddComponent } from './demande-vehicule-add.component';
import { DemandeVehiculeBeneficiaireComponent } from './demande-vehicule-beneficiaire.component';
import { DemandeVehiculeEditComponent } from './demande-vehicule-edit.component';
import { DemandeVehiculeListItemComponent } from './demande-vehicule-list-item.component';
import { DemandeVehiculeListComponent } from './demande-vehicule-list.component';
import { DemandeVehiculeModeleListItemComponent } from './demande-vehicule-modele-list-item.component';
import { DemandeVehiculeModeleListComponent } from './demande-vehicule-modele-list.component';
import { DemandeVehiculeModeleSelectionComponent } from './demande-vehicule-modele-selection.component';
import { DemandeVehiculeLivraisonComponent } from './demande-vehicule-livraison.component';
import { DemandeVehiculeVehiculeInformationsComplementairesComponent } from './demande-vehicule-vehicule-informations-complementaires.component';
import { DemandeVehiculeVehiculeListItemComponent } from './demande-vehicule-vehicule-list-item.component';
import { DemandeVehiculeVehiculeListComponent } from './demande-vehicule-vehicule-list.component';
import { DemandeVehiculeComponent } from './demande-vehicule.component';
import { DemandeVehiculeService } from './demande-vehicule.service';
import { DemandeVehiculeModeleSelectionModeleComponent } from './demande-vehicule-modele-selection-modele.component';
import { DemandeVehiculeModeleSelectionGrilleAttributionComponent } from './demande-vehicule-modele-selection-grille-attribution.component';
import { DemandeVehiculeChoixGrilleAttributionModeleComponent } from './demande-vehicule-choix-grille-attribution-modele.component';
import { DemandeVehiculeFinancementSelectionComponent } from './demande-vehicule-financement-selection.component';
import { DemandeVehiculeCotationListComponent } from './demande-vehicule-cotation-list.component';
import { DemandeVehiculeCotationListItemComponent } from './demande-vehicule-cotation-list-item.component';
import { DemandeVehiculeFinancementListComponent } from './demande-vehicule-financement-list.component';
import { DemandeVehiculeFinancementEditComponent } from './demande-vehicule-financement-edit.component';

@NgModule({
	imports: [ShareModule],
	declarations: [DemandeVehiculeListComponent,DemandeVehiculeListItemComponent,DemandeVehiculeAddComponent,DemandeVehiculeComponent,DemandeVehiculeEditComponent,DemandeVehiculeBeneficiaireComponent,DemandeVehiculeLivraisonComponent
		,DemandeVehiculeVehiculeListComponent,DemandeVehiculeVehiculeListItemComponent,DemandeVehiculeVehiculeInformationsComplementairesComponent,DemandeVehiculeModeleListComponent,DemandeVehiculeModeleListItemComponent,DemandeVehiculeModeleSelectionComponent,DemandeVehiculeModeleSelectionModeleComponent,DemandeVehiculeModeleSelectionGrilleAttributionComponent,DemandeVehiculeChoixGrilleAttributionModeleComponent
		,DemandeVehiculeFinancementListComponent,DemandeVehiculeCotationListComponent,DemandeVehiculeCotationListItemComponent,DemandeVehiculeFinancementSelectionComponent,DemandeVehiculeFinancementEditComponent
	],
	providers: [DemandeVehiculeService]
})
export class DemandeVehiculeModule { }