<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idMotif">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3).toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + ' (' + data?.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idMotif">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.entity"><span translate>workflow.motif.entite</span>&#160;:&#160;<span>{{ entiteService.translateEntityCode(data.entity.split('.').pop(-1)) }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idMotif">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteMotif();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idMotif)"><span translate>actions.importer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<motif [motif]="data" [typeReferentiel]="liste.selectedSelector" (onObjectChange)="onObjectChange($event)"></motif>
</div>