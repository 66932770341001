import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { HeaderComponent } from './header.component';
import { HeaderAccountComponent } from './header-account.component';
import { HeaderProgressComponent } from './header-progress.component';
import { GlobalSearchModule } from 'src/app/share/layout/global-search/global-search.module';
import { ProgressModule } from 'src/app/share/layout/progress/progress.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,GlobalSearchModule,ProgressModule,IconModule],
	declarations: [HeaderComponent,HeaderAccountComponent,HeaderProgressComponent],
	exports: [HeaderComponent,HeaderAccountComponent,HeaderProgressComponent]
})
export class HeaderModule {}
