import { AfterViewInit,Component,ElementRef,EventEmitter,Input,OnChanges,Output,Renderer2,RendererFactory2,SimpleChanges,ViewChild } from '@angular/core';
import Cropper from 'cropperjs';

import { BoundingBox } from 'src/app/domain/image-editor/image-editor';
@Component({
	selector: 'image-editor',
	exportAs: 'imageEditor',
	templateUrl: './image-editor.component.html'
})
export class ImageEditorComponent implements AfterViewInit,OnChanges {
	/** Référence vers l'image **/
	@ViewChild('imageEditor',{ static: false })
	public imageEditor: ElementRef;

	/** Image à modifier **/
	@Input() imageFile?: File;

	/** Source de l'image **/
	@Input() imageSource?: string;

	/** Mode 'Consultation' **/
	@Input() consultation: boolean;

	/** Mode 'Embarqué' **/
	@Input() inline: boolean = false;

	/** Indicateur de rotation **/
	@Input() canRotate?: boolean = true;

	/** Options personnalisées **/
	@Input() customOptions: Array<{ icon: string,disabled: Function,doAction: Function,tooltip: string }>;

	/** Fermeture du composant **/
	@Output() onClose: EventEmitter<any> = new EventEmitter<any>();

	/** Changement de la source **/
	@Output() onSourceChange: EventEmitter<any> = new EventEmitter<any>();

	/** Cropper **/
	private cropper: Cropper;

	/** Image embarquée dans le cropper **/
	private cropperImage: any;

	/** Moteur de rendu **/
	private renderer: Renderer2;

	/** Container de boxes **/
	private boxesContainer: any;

	/** Liste des bounding boxes **/
	public listeBoundingBoxes: Array<{ boundingBox: BoundingBox,isRendered: boolean }> = [];

	/**
	 * Constructeur
	 */
	constructor(private rendererFactory: RendererFactory2,private element: ElementRef) {
		//Création du moteur de rendu
		this.renderer = rendererFactory.createRenderer(null,null);
	}

	/**
	 * Interception des changements des inputs
	 */
	ngOnChanges(changes: SimpleChanges): void {
		//Vérification de la présence d'un changement
		if (changes?.imageSource?.previousValue && changes.imageSource.previousValue != changes.imageSource.currentValue) {
			//Réinitialisation des bounding boxes
			this.resetListeBoundingBoxes(true);

			//Destruction du cropper
			this.cropper.destroy()

			//Notification du changement de source après rechargement du cropper
			this.waitForElement('.cropper-canvas').then(() => this.onSourceChange.emit());
		}
	}

	/**
	 * Chargement de la vue
	 */
	ngAfterViewInit() {
		const fileReader = new FileReader();

		//Vérification de l'image
		if (this.imageFile) {
			//Définition de l'action lors du chargement
			fileReader.onload = event => {
				//Définition de l'image
				this.imageEditor.nativeElement.src = event.target.result;

				//Création du cropper
				this.createCropper();
			}
			//Chargement de l'image
			fileReader.readAsDataURL(this.imageFile);
		} else {
			//Interception du chargement de l'image
			this.imageEditor.nativeElement.onload = () => {
				//Création du cropper
				this.createCropper();
			}
		}
	}

	/**
	 * Rotation de l'image
	 */
	rotate(mode: 'LEFT' | 'RIGHT') {
		//Rotation de l'image
		this.cropper.rotate(mode == 'LEFT' ? -90 : 90);
	}

	/**
	 * Zoom sur l'image
	 */
	zoom(mode: 'IN' | 'OUT') {
		//Zoom sur l'image
		this.cropper.zoom(mode == 'IN' ? .1 : -.1);

		//Rendu des boites
		this.renderBoundingBoxes(true);
	}

	/**
	 * Rognage de l'image
	 */
	crop() {
		//Rognage de l'image
		this.cropper.crop()?.getCroppedCanvas()?.toBlob(blob => {
			//Fermeture de l'éditeur avec l'image rognée
			this.onClose.emit(blob);
		},this.imageFile.type);
	}

	/**
	 * Création du cropper
	 */
	private createCropper() {
		//Création du cropper
		this.cropper = new Cropper(this.imageEditor.nativeElement,{
			dragMode: 'move',
			background: false,
			modal: false,
			checkCrossOrigin: false,
			rotatable: this.canRotate,
			wheelZoomRatio: 0.1
		});
	}

	/**
	 * Ajout d'une bounding box
	 */
	public addBoundingBox(boundingBox: BoundingBox) {
		//Vérification de la présence d'une boîte du même type
		if (this.listeBoundingBoxes.findIndex(b => b.boundingBox.type == boundingBox.type) == -1) {
			//Ajout de la boîte à la liste
			this.listeBoundingBoxes.push({ boundingBox,isRendered: false });

			//Affichage de la boîte englobante
			this.renderBoundingBoxes(false);
		} else {
			//Suppression de la boîte
			this.deleteBoundingBox(boundingBox);

			//Suppression de la liste des boîtes
			this.listeBoundingBoxes.splice(this.listeBoundingBoxes.findIndex(b => b.boundingBox.type == boundingBox.type),1);
		}
	}

	/**
	 * Suppression d'une bounding box
	 */
	public deleteBoundingBox(boundingBox: BoundingBox) {
		let boxElement: any;

		//Sélection de la boîte
		boxElement = this.element.nativeElement.querySelector(`#${boundingBox.type}`);

		//Vérification de l'élément
		if (boxElement)
			//Suppression de l'élément
			boxElement.remove();
	}

	/**
	 * Suppression de toutes les bounding boxes
	 */
	public resetListeBoundingBoxes(removeContainer: boolean = false) {
		//Parcours de la liste des bounding boxes
		this.listeBoundingBoxes.forEach(b => {
			//Suppression de la boîte
			this.deleteBoundingBox(b.boundingBox);
		});

		//Suppression du contenu de la liste
		this.listeBoundingBoxes = [];

		//Vérification de la suppression du container
		if (removeContainer)
			//Réinitialisation de la référence du container
			this.boxesContainer = null;
	}

	/**
	 * Affichage des boîtes englobantes
	 */
	public renderBoundingBoxes(isForceRerender: boolean) {
		//Vérification du container de boxes
		if (!this.boxesContainer) {
			//Création du container
			this.boxesContainer = this.renderer.createElement('div');

			//Définition de la classe
			this.renderer.addClass(this.boxesContainer,'cropper-box-container');

			//Définition du style
			this.renderer.setStyle(this.boxesContainer,'width','100%');
			this.renderer.setStyle(this.boxesContainer,'height','100%');

			//Ajout du container au cropper
			this.renderer.appendChild(this.element.nativeElement.querySelector('.cropper-canvas'),this.boxesContainer);

			//Sélection de l'image
			this.cropperImage = this.element.nativeElement.querySelector('.cropper-canvas img');
		}

		//Parcours des boîtes englobantes
		this.listeBoundingBoxes.forEach(b => {
			let boxElement: any;
			let titleElement: any;
			let oldElement: any;

			//Vérification de la nécessité de faire le rendu de la box
			if (!b.isRendered || isForceRerender) {
				//Vérification du forçage de rendu
				if (isForceRerender) {
					//Sélection de l'ancienne boîte
					oldElement = this.element.nativeElement.querySelector(`#${b.boundingBox.type}`);

					//Vérification d'un ancien élément
					if (oldElement)
						//Suppression de l'ancien élément
						oldElement.remove();
				}

				//Sélection de l'image
				this.cropperImage = this.element.nativeElement.querySelector('.cropper-canvas img');

				//Création du rectangle
				boxElement = this.renderer.createElement('div');

				//Définition de la classe
				this.renderer.addClass(boxElement,'cropper-box');

				//Définition de l'identifiant
				this.renderer.setAttribute(boxElement,'id',b.boundingBox.type);

				//Définition du rectangle
				this.renderer.setStyle(boxElement,'position','absolute');
				this.renderer.setStyle(boxElement,'left',`${b.boundingBox.rectangle.left * this.cropperImage.width / b.boundingBox.container.width}px`);
				this.renderer.setStyle(boxElement,'top',`${b.boundingBox.rectangle.top * this.cropperImage.height / b.boundingBox.container.height}px`);
				this.renderer.setStyle(boxElement,'width',`${b.boundingBox.rectangle.width * this.cropperImage.width / b.boundingBox.container.width}px`);
				this.renderer.setStyle(boxElement,'height',`${b.boundingBox.rectangle.height * this.cropperImage.height / b.boundingBox.container.height}px`);

				//Ajout du rectangle au container
				this.renderer.appendChild(this.boxesContainer,boxElement);

				//Définition du statut de rendu
				b.isRendered = true;

				//Initialisation du title
				titleElement = this.renderer.createElement('div');

				//Définition de la classe
				this.renderer.addClass(titleElement,'cropper-box-title');

				//Définition du titre
				this.renderer.setStyle(titleElement,'left',`-1px`);
				this.renderer.setStyle(titleElement,'top',`${(b.boundingBox.rectangle.height * this.cropperImage.height / b.boundingBox.container.height) - 1}px`);

				//Ajout du texte du titre
				let title = this.renderer.createText(b.boundingBox.title);

				//Ajout du titre
				this.renderer.appendChild(titleElement,title);

				//Ajout du titre à la boîte
				this.renderer.appendChild(boxElement,titleElement);
			}
		});
	}

	/**
	 * Gestion du zoom à la souris
	 */
	onWheel() {
		//Rendu des boites
		this.renderBoundingBoxes(true);
	}


	/**
	 * Attente du chargement d'un élément
	 */
	private waitForElement(selector: string): Promise<any> {
		let observer;

		//Création d'une promesse
		return new Promise(resolve => {
			//Vérification de la présence de l'élément
			if (document.querySelector(selector))
				//Résolution de la promesse
				return resolve(document.querySelector(selector));

			//Création d'un observer
			observer = new MutationObserver(() => {
				//Vérification de la présence de l'élément
				if (document.querySelector(selector)) {
					//Résolution de la promesse
					resolve(document.querySelector(selector));

					//Desctruction de la promesse
					observer.disconnect();
				}
			});

			//Définition de l'observable
			observer.observe(document.body,{
				childList: true,
				subtree: true
			});
		});
	}
}