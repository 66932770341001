import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';

import { AuthentificationConfigurationListComponent } from './authentification-configuration-list.component';
import { AuthentificationConfigurationComponent } from './authentification-configuration.component';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthentificationConfigurationService {
	/** Liste des modes d'authentification **/
	private readonly listeModesAuthentification = ['LOGIN_MDP','FEDERATION','BOTH'];

	/** Liste des types d'authentification externe **/
	private readonly listeTypesAuthentification = ['ADFS_OAUTH2','ADFS_SAML2','GOOGLE_OAUTH2','OAUTH2','SAML2'];

	/** Liste des modes d'authentification SAML2 **/
	private readonly listeSaml2Types = ['SP_INITIATED','IDP_INITIATED'];

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private translateService: TranslateService,private http: HttpClient) { }

	/**
	 * Ouverture de la popup des configurations
	 */
	public showListeConfigurations({ listeConfigurations,idTenant,disabled }: { listeConfigurations: Array<any>,idTenant: number,disabled?: boolean }): Observable<Array<any>> {
		let bsModalRef: BsModalRef<AuthentificationConfigurationListComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(AuthentificationConfigurationListComponent,{
			initialState: {
				listeConfigurations: cloneDeep(listeConfigurations),
				idTenant,
				disabled
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content.result?.listeConfigurations),
			filter(listeConfigurations => !!listeConfigurations)
		);
	}

	/**
	 * Ouverture de la popup d'une configuration
	 */
	public showConfiguration({ configuration,idTenant,disabled }: { configuration: any,idTenant?: number,disabled?: boolean } = { configuration: null }): Observable<any> {
		let bsModalRef: BsModalRef<AuthentificationConfigurationComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(AuthentificationConfigurationComponent,{
			initialState: {
				configuration: cloneDeep(configuration),
				idTenant,
				disabled
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content.result?.configuration),
			filter(configuration => !!configuration)
		);
	}

	/**
	 * Liste des types d'authentification externe
	 */
	public getListeTypesAuthentification(): Array<{ code: string,libelle: string }> {
		//Liste des types d'authentification externe
		return this.listeTypesAuthentification.map(code => ({
			code,
			libelle: this.translateService.instant(`authentification.configuration.type.${code}`)
		}));
	}

	/**
	 * Liste des modes d'authentification
	 */
	public getListeModesAuthentification(): Array<{ code: string,libelle: string }> {
		//Liste des modes d'authentification
		return this.listeModesAuthentification.map(code => ({
			code,
			libelle: this.translateService.instant(`authentification.configuration.mode.${code}`)
		}));
	}

	/**
	 * Liste des modes d'authentification pour le SAML2
	 */
	public getListeSaml2Types(): Array<{ code: string,libelle: string }> {
		//Liste des modes d'authentification
		return this.listeSaml2Types.map(code => ({
			code,
			libelle: this.translateService.instant(`authentification.configuration.saml2Type.${code}`)
		}));
	}

	/**
	 * Enregistrement de la configuration d'authentification
	 */
	public saveAuthConfig(authConfig: any): Observable<Result> {
		//Enregistrement de la configuration d'authentification
		return this.http.put<Result>(`${environment.baseUrl}/controller/AuthConfig/saveAuthConfig`,authConfig);
	}

	/**
	 * Suppression de la configuration d'authentification
	 */
	public deleteAuthConfig(authConfig: any): Observable<Result> {
		//Suppression de la configuration d'authentification
		return this.http.delete<Result>(`${environment.baseUrl}/controller/AuthConfig/deleteAuthConfig/${authConfig.idConfig}`);
	}
}