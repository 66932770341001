<div class="pull-left lv-avatar">{{ data?.pays?.code2?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Fiscalite/loadRegleTaxe/',data?.idRegle || 0]">{{ data?.libelle }}</a>
</div>
<ul class="lv-attrs">
	<li><span translate>fiscalite.regleTaxe.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
	<li><span translate>fiscalite.regleTaxe.critere</span>&#160;:&#160;<span [translate]="'fiscalite.regleTaxe.type.'+data.type"></span></li>
</ul>
<div class="lv-actions actions dropdown">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'suppression') && rightService.isRoot()"><a [routerLink]="[]" (click)="deleteRegleTaxe();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>