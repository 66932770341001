
import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minBy } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { OptionCategorieService } from 'src/app/components/vehicule/modele/option-categorie/option-categorie.service';

@Component({
	templateUrl: './demande-cotation-option-payante-list.component.html'
})
export class DemandeCotationOptionPayanteListComponent {
	/** Liste des options payantes **/
	@Input() listeOptionsPayantes: Array<any>;

	/** Configuration **/
	@Input() configuration: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DemandeCotationOptionPayanteListComponent>,public optionCategorieService: OptionCategorieService,private translateService: TranslateService) {}

	/**
	 * Récupération du titre du sélecteur d'option
	 */
	public getSelectorTitleForDetail(detail): string {
		//Vérification de la situation de l'option
		if (detail?.option.type == 'SERIE')
			//Équipement de série
			return this.translateService.instant('vehicule.modele.configuration.option.serie');
		else if (detail?.listeSourcesInclusion)
			//Récupération du parent source de l'inclusion au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.incluse',{ option: minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte.prixHT).detail?.option?.libelle });
		else if (detail?.listeSourcesObligation)
			//Récupération du parent source de l'obligation au prix le plus faible
			return this.translateService.instant('vehicule.modele.configuration.option.ajoutee',{ option: minBy(detail.listeSourcesObligation,(o: any) => o.contrainte.prixHT).detail?.option?.libelle });
	}

	/**
	 * Récupération du prix effectif d'un détail en tenant compte de ses liens d'inclusion et d'obligation
	 */
	public getActualPrice(detail: any): number {
		//Vérification de la situation de l'option
		if (detail?.listeSourcesInclusion)
			//Retour du prix le plus faible obtenu par inclusion
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detail.listeSourcesInclusion,(i: any) => i.contrainte?.prixHT).contrainte?.prixHT;
		else if (detail?.listeSourcesObligation)
			//Retour du prix le plus faible obtenu par obligation
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? minBy(detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixTTC).contrainte?.prixTTC : minBy(detail?.listeSourcesObligation,(o: any) => o.contrainte?.prixHT).contrainte?.prixHT;
		else
			//Retour du prix hors contrainte de l'option
			return this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'TTC' ? detail?.option?.prixTTC : detail?.option?.prixHT;
	}

	/**
	 * Récupération de la liste sérialisée des sous-catégories d'une option
	 */
	public getListeSubCategories(option): string {
		//Retour des libellés des sous-catégories
		return option?.listeSubCategories?.map(subCategorie => subCategorie?.libelle).join(', ');
	}
}