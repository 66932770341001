<div class="card detail" *ngIf="reponseCotation.answered || reponseCotation.statut != 'A_COMPLETER'">
	<div class="card-header" *ngIf="isFromDemandeCotation || source == 'FOURNISSEUR'">
		<h2 class="card-title" *ngIf="!isFromDemandeCotation" translate>reponseDemandeCotation.reponseCotation.reponse.title</h2>
		<h2 class="card-title" *ngIf="isFromDemandeCotation"><span translate>reponseDemandeCotation.reponseCotation.reponse.titreRetenue</span>&#160;:&#160;<span>{{ reponseCotation.reponseDemandeCotation.contact.nom + ' ' + reponseCotation.reponseDemandeCotation.contact.prenom + ' (' + reponseCotation.reponseDemandeCotation.contact.fournisseur.libelle + ')' }}</span></h2>
		<div class="row">
			<ul class="pull-right actions m-t-0">
				<li>
					<a [routerLink]="[]" (click)="reponseCotation.isReponseToggled = !reponseCotation.isReponseToggled">
						<icon [name]="reponseCotation.isReponseToggled ? 'chevron_right' : 'expand_more'"></icon>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="card-body card-padding" [collapse]="reponseCotation.isReponseToggled">
		<fieldset [disabled]="!(null | right:'creation') || isReadOnly || isFromDemandeCotation">
			<div class="row">
				<div class="col-xs-12">
					<h4 translate>reponseDemandeCotation.reponseCotation.reponse.informationsGenerales</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label translate>reponseDemandeCotation.reponseCotation.reponse.modele</label>
						<div>
							<mat-chip-listbox>
								<mat-chip class="clickable" (click)="showModele(reponseCotation.cotation.modele)">
									<span>{{ reponseCotation.cotation.modele.marque?.libelle + ' ' + reponseCotation.cotation.modele.libelle }}</span><span *ngIf="reponseCotation?.cotation.modele.finition">&#160;({{ reponseCotation.cotation.modele.finition }})</span>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label translate>reponseDemandeCotation.reponseCotation.reponse.piecesJointes</label>
						<div>
							<div [attachment]="reponseCotation" [typeAttachment]="TypeAttachment.REPONSE_COTATION" [readOnly]="isReadOnly"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<h4 translate>reponseDemandeCotation.reponseCotation.reponse.detailFinancement</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
						<label translate>reponseDemandeCotation.reponseCotation.reponse.typeFinancement</label>
						<div>
							<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="reponseCotation.typeFinancement" required></autocomplete>
						</div>
					</div>
					<ng-container *ngIf="reponseCotation.typeFinancement.mode != 'ACQUISITION'">
						<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
							<label>
								<span translate>reponseDemandeCotation.reponseCotation.reponse.loiRoulage.item</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'reponseDemandeCotation.reponseCotation.reponse.loiRoulage.message' | translate"></icon>
							</label>
							<div class="input-group">
								<input type="text" name="duree" #duree="ngModel" class="form-control" [(ngModel)]="reponseCotation.duree" nNumber min="0" max="300" [required]="reponseCotation.typeFinancement && ['LOCATION_LONGUE_DUREE','LOCATION_MOYENNE_DUREE','LOCATION_OPTION_ACHAT'].includes(reponseCotation.typeFinancement.mode)"/>
								<div class="align-self-center m-l-10 m-r-10">/</div>
								<input type="text" name="distance" #distance="ngModel" class="form-control" [(ngModel)]="reponseCotation.distance" nNumber="0" [required]="reponseCotation.typeFinancement && ['LOCATION_LONGUE_DUREE','LOCATION_MOYENNE_DUREE','LOCATION_OPTION_ACHAT'].includes(reponseCotation.typeFinancement.mode)"/>
								<div class="input-group-addon no-border" *ngIf="user.unite">
									<span>{{ user.unite.libelleCourt }}</span>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeAffichagePrix.invalid }">
							<label translate>reponseDemandeCotation.reponseCotation.reponse.typeAffichagePrix</label>
							<div>
								<selectpicker name="typeAffichagePrix" #typeAffichagePrix="ngModel" [(ngModel)]="reponseCotation.typeAffichagePrix" required>
									<mat-option *ngFor="let type of listeTypesAffichagePrix" [value]="type.code">{{ type.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': loyerMensuel.invalid }">
							<label translate>reponseDemandeCotation.reponseCotation.reponse.loyerMensuel</label>
							<div>
								<amount name="loyerMensuel" #loyerMensuel="ngModel" [(ngModel)]="reponseCotation.loyerMensuel" [devise]="reponseCotation.devise" [required]="reponseCotation.typeFinancement && ['LOCATION_LONGUE_DUREE','LOCATION_MOYENNE_DUREE','LOCATION_OPTION_ACHAT'].includes(reponseCotation.typeFinancement.mode)"></amount>
							</div>
						</div>
					</ng-container>
					<div class="form-group" *ngIf="reponseCotation.typeFinancement.mode == 'ACQUISITION'" [ngClass]="{ 'has-error': prixAchat.invalid }">
						<label translate>reponseDemandeCotation.reponseCotation.reponse.prixVenteTTC</label>
						<div>
							<amount name="prixAchat" #prixAchat="ngModel" [(ngModel)]="reponseCotation.prixAchat" [devise]="reponseCotation.devise" required></amount>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<h4 translate>reponseDemandeCotation.reponseCotation.reponse.remarque</h4>
				</div>
			</div>
			<div class="form-group">
				<label translate>reponseDemandeCotation.reponseCotation.reponse.remarque</label>
				<div>
					<textarea class="form-control" name="remarque" [(ngModel)]="reponseCotation.remarque" rows="3"></textarea>
				</div>
			</div>
		</fieldset>
	</div>
</div>