<form #typeAmenagementAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.typeAmenagement.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>vehicule.typeAmenagement.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="typeAmenagement.libelle" maxlength="300" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>vehicule.typeAmenagement.reference</label>
			<div>
				<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="typeAmenagement.reference" maxlength="100" required/>
			</div>
		</div>
		<div class="form-group">
			<label translate>vehicule.typeAmenagement.remarque</label>
			<div>
				<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="typeAmenagement.remarque" rows="3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide();">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="typeAmenagementAddForm.invalid" (click)="saveItem();">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>