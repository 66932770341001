import { Component,Input,OnInit } from '@angular/core';

import { OptionCategorieService } from 'src/app/components/vehicule/modele/option-categorie/option-categorie.service';

@Component({
	selector: 'option-list',
	templateUrl: './option-list.component.html'
})
export class OptionListComponent implements OnInit {
	/** Liste des options **/
	@Input() listeDetails: Array<any>;

	/** Liste des types d'options **/
	listeTypesOptions: Array<{ code: string,libelle: string,isSelected?: boolean,hasDetails?: boolean }>;

	/**
	 * Constructeur
	 */
	constructor(private optionCategorieService: OptionCategorieService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Binding
		this.filterByTypeCategory = this.filterByTypeCategory.bind(this);

		//Liste des types d'options
		this.listeTypesOptions = this.initTypesOptions(this.listeDetails);

		//Définition de l'indicateur de la présence d'options dans les catégories
		this.refreshCategoryHasDetailFlags();
	}

	/**
	 * Initialisation des filtres sur les catégories d'options
	 */
	private initTypesOptions(listeDetails: Array<any>): Array<{ code: string,libelle: string,isSelected?: boolean,hasDetails?: boolean }> {
		//Vérification de la présence du code dans les options
		return this.optionCategorieService.getListeTypesOption().filter(option => {
			//Définition du filtre
			return option.code == 'ACCESSOIRE' && listeDetails?.some?.(detail => !detail?.listeSubCategories?.length || detail?.listeSubCategories?.some?.(subCategorie => !subCategorie?.type))
				|| listeDetails?.some?.(detail => detail?.listeSubCategories?.some?.(subCategorie => subCategorie?.type == option?.code));
		});
	}

	/**
	 * Filtrage des options par type de catégorie d'options et de prix
	 */
	public filterByTypeCategory(detail: any): boolean {
		let listeFilteredCategories: Array<any>;
		let isTypeCategoryOk: boolean;

		//Liste des types filtrés
		listeFilteredCategories = this.listeTypesOptions.filter(typeOption => typeOption.isSelected).map(typeOption => typeOption.code);

		//Vérification que la catégorie de l'option correspond aux filtres
		isTypeCategoryOk = listeFilteredCategories.length == 0 || this.isDetailInCategories(detail,listeFilteredCategories);

		//Retour de la conformité de l'option aux filtres sélectionnés
		return isTypeCategoryOk;
	}

	/**
	 * Réactualisation de l'indicateur de la présence d'options dans les catégories
	 */
	private refreshCategoryHasDetailFlags() {
		//Parcours des catégories d'option disponibles
		this.listeTypesOptions.forEach(typeOption => {
			//Vérification que la catégorie contient des options valides pour les types de prix sélectionnés
			typeOption.hasDetails = this.listeDetails?.some(detail => this.isDetailInCategories(detail,[typeOption.code]));

			//Vérification de la catégorie
			if (!typeOption.hasDetails && typeOption.isSelected)
				//Désélection de la catégorie
				typeOption.isSelected = false;
		});
	}

	/**
	 * Vérification que l'une des sous-catégories de l'option correspond à l'une des catégories fournies
	 */
	private isDetailInCategories(detail: any,listeValidCategories: Array<string>): boolean {
		//Vérification que l'une des sous-catégories de l'option correspond à l'une des catégories fournies
		return (detail?.listeSubCategories?.length ? detail?.listeSubCategories : [{ type: 'ACCESSOIRE' }]).some(subCategorie => {
			//Retour de la présence de la sous-catégorie courante dans la liste des catégories valides
			return listeValidCategories.indexOf(subCategorie.type || 'ACCESSOIRE') != -1;
		});
	}

	/**
	 * Sélection/Désélection d'une catégorie d'option
	 */
	toggleTypeOption(type: any) {
		//Modification de l'indicateur de sélection
		type.isSelected = !type.isSelected;
	}
}