import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	templateUrl: './extraction-diffusion-edit.component.html'
})
export class ExtractionDiffusionEditComponent extends PageContentItem {
	/** Extraction courante **/
	@Input() extraction: any;

	/** Diffusion courante **/
	@Input() userScheduler: any;

	/** Informations de l'entité **/
	@Input() entiteInformations: any;

	/** Suppression de la diffusion courante **/
	@Input() deleteUserScheduler: Function;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}