import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { FournisseurService } from './fournisseur.service';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'fournisseur-add',
	templateUrl: './fournisseur-add.component.html'
})
export class FournisseurAddComponent extends AutocompleteAddComponent {
	/** Fournisseur **/
	fournisseur: any = {
		actif: true
	};

	/** Nombre de natures **/
	nbNatures: number = null;

	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<FournisseurAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement du fournisseur
		this.fournisseurService.saveFournisseur(this.filter?.typeReferentiel || TypeReferentiel.LOCAL,this.fournisseur).subscribe(result => {
			let listeDoublons = new Array<string>();

			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Définition du fournisseur
				this.addedItem = result.data.fournisseur;

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,0))
					//Ajout de la référence
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification du libellé
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		},() => {
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Récupération du libellé du métier
	 */
	getLibelleMetier: (metier: any) => string = (metier) => metier.libelle;

	/**
	 * Vérification de la validité de la liste des métiers du fournisseur
	 */
	isListeMetiersInvalid: () => boolean = () => !this.fournisseur.listeMetiers?.length;

	/**
	 * Ajout d'un métier au panier
	 */
	onAddMetier() {
		//Parcours des métiers sléectionnés
		this.fournisseur.listeMetiers.forEach(metier => {
			//Définition du type d'entité pour la désérialisation
			metier['_type'] = 'com.notilus.data.fournisseur.FournisseurMetier';
		});

		//Récupération du nombre de natures
		this.fournisseurService.getNbNatures(this.fournisseur).subscribe(nbNatures => {
			//Mise à jour du nombre de natures
			this.nbNatures = nbNatures;
		});
	}

	/**
	 * Suppression d'un métier du panier
	 */
	onRemoveMetier() {
		//Récupération du nombre de natures
		this.fournisseurService.getNbNatures(this.fournisseur).subscribe(nbNatures => {
			//Mise à jour du nombre de natures
			this.nbNatures = nbNatures;
		});
	}

	/**
	 * Affichage de la liste des natures
	 */
	showListeNatures() {
		//Affichage de la liste des natures
		this.fournisseurService.showListeNatures(this.fournisseur);
	}
}
