import { Component,Input,OnInit } from '@angular/core';

@Component({
	selector: 'regle-automatique-check-resultat',
	templateUrl: './regle-automatique-check-resultat.component.html'
})
export class RegleAutomatiqueCheckResultatComponent implements OnInit {
	/** Règle automatique **/
	@Input() readonly regleAutomatique: any;

	/** Liste des types de champs calculé **/
	@Input() readonly listeTypesComputedField: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>;

	/** Liste des règles automatiques **/
	@Input() listeRegleAutomatiqueChecks: Array<Array<any>>;

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Parcours des règles automatiques
		this.listeRegleAutomatiqueChecks.forEach((regleAutomatique: any) => {
			//Définition du libellé de la valeur
			regleAutomatique.libelleValeur = this.regleAutomatique?.typeComputedField?.listeValeurs?.find(value => value.code == regleAutomatique.valeur)?.libelle || regleAutomatique.valeur;
		});
	}
}
