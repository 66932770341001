<div class="alert alert-danger" *ngIf="!periodeValidity?.isValid">
	<icon name="warning"></icon>
	<div>
		<strong translate>vehicule.equipement.chevauchementPeriode.title</strong>
		<div translate>vehicule.equipement.chevauchementPeriode.message</div>
		<div>{{ (periodeValidity?.affectation?.dateDebut | date:'shortDate') + (periodeValidity?.affectation?.dateFin ? ' - ' + (periodeValidity?.affectation?.dateFin | date:'shortDate') : '') }}</div>
	</div>
</div>
<fieldset [formValidator]="isPeriodeValid()" [disabled]="!(TypeDroit.EQUIPEMENT | right:'creation')">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }" *ngIf="source == 'VEHICULE'">
				<label translate>equipement.type.item</label>
				<div class="half">
					<selectpicker name="type" #typeField="ngModel" [(ngModel)]="searchEquipement.type" (ngModelChange)="affectationEquipement.equipement = null" required>
						<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }" *ngIf="searchEquipement.type && source == 'VEHICULE'">
				<label translate>equipement.fournisseur</label>
				<div>
					<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="searchEquipement.fournisseur" (ngModelChange)="affectationEquipement.equipement = null" required></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': equipement.invalid }" *ngIf="searchEquipement?.fournisseur && source == 'VEHICULE'">
				<label translate>equipement.item</label>
				<div class="input-group">
					<autocomplete name="equipement" type="equipement" #equipement="ngModel" [(ngModel)]="affectationEquipement.equipement" (ngModelChange)="affectationEquipement.dateDebut = null; affectationEquipement.dateFin = null;" [filter]="{ source,fournisseur: searchEquipement?.fournisseur,  idFournisseur: searchEquipement?.fournisseur?.idFournisseur, type: searchEquipement?.type }" required></autocomplete>
					<div class="input-group-addon">
						<icon name="replay_30" class="clickable" (click)="showAffectationHistory()" *ngIf="affectationEquipement?.equipement?.idEquipement"></icon>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': typeAffectationField.invalid }" *ngIf="affectationEquipement?.equipement?.type && ['TELEPEAGE','CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(affectationEquipement.equipement.type) && source != 'VEHICULE'">
				<label translate>equipement.affectation.typeAffectation.item</label>
				<div class="half">
					<selectpicker name="typeAffectation" #typeAffectationField="ngModel" [(ngModel)]="affectationEquipement.type" required>
						<mat-option *ngFor="let typeAffectation of listeTypesAffectation" [value]="typeAffectation.code">{{ typeAffectation.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': ngForm.controls['vehicule']?.invalid }" *ngIf="source != 'VEHICULE' && affectationEquipement.type == 'VEHICULE'">
				<label translate>equipement.affectation.vehicule</label>
				<div>
					<ng-container *ngIf="!affectationEquipement.vehicule">
						<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" [(ngModel)]="affectationEquipement.vehicule" required></autocomplete>
					</ng-container>
					<div *ngIf="affectationEquipement.vehicule">
						<mat-chip-listbox>
							<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="vehiculeService.goToVehicule(affectationEquipement.vehicule)" (removed)="affectationEquipement.vehicule = null">
								{{ affectationEquipement.vehicule | display:'vehicule' }}
								<button matChipRemove *ngIf="null | right:'creation'">
									<mat-icon>cancel</mat-icon>
								</button>
							</mat-chip>
							<icon name="replay_30" class="clickable" *ngIf="affectationEquipement.vehicule?.listeOldReferences?.length" (click)="vehiculeService.showListeImmatriculations(affectationEquipement.vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate"></icon>
						</mat-chip-listbox>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': user.invalid }" *ngIf="source != 'VEHICULE' && affectationEquipement.type == 'COLLABORATEUR'">
				<label translate>equipement.affectation.user</label>
				<div>
					<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="affectationEquipement.user" required></autocomplete>
				</div>
			</div>
			<div *ngIf="affectationEquipement?.equipement?.idEquipement">
				<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
					<label translate>equipement.dateDebut.item</label>
					<div class="half">
						<div class="input-group">
							<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="affectationEquipement.dateDebut" [validator]="isDateDebutValid()" (ngModelChange)="isPeriodeValid()" [minDate]="affectationEquipement?.equipement?.dateDebut" [maxDate]="affectationEquipement?.equipement?.dateFin" datePicker format="datetime" required/>
							<div class="input-group-addon">
								<icon name="info_outline" [tooltip]="'equipement.dateDebut.info' | translate"></icon>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
					<label translate>equipement.dateFin.item</label>
					<div class="half">
						<div class="input-group">
							<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="affectationEquipement.dateFin" [validator]="isDateFinValid()" (ngModelChange)="isPeriodeValid()" [minDate]="affectationEquipement.dateDebut || affectationEquipement?.equipement?.dateDebut" [maxDate]="affectationEquipement?.equipement?.dateFin" datePicker format="datetime" required/>
							<div class="input-group-addon">
								<icon name="info_outline" [tooltip]="'equipement.dateFin.info' | translate"></icon>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="source == 'EQUIPEMENT'">
				<label translate>common.piecesJointes</label>
				<div>
					<div [attachment]="affectationEquipement" [typeAttachment]="TypeAttachment.EQUIPEMENT_AFFECTATION" [parentOwningEntity]="affectationEquipement.equipement" [listeTypesDroit]="[TypeDroit.EQUIPEMENT]"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group" *ngIf="affectationEquipement?.equipement?.idEquipement">
		<label translate>equipement.remarque</label>
		<div>
			<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="affectationEquipement.remarque" rows="3"></textarea>
		</div>
	</div>
</fieldset>