import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map,mergeMap,take } from 'rxjs/operators';

import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { BusinessDataComponent } from './business-data.component';

@Injectable()
export class BusinessDataService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private entiteService: EntiteService) {}

	/**
	 * Affichage de la popup des données métier
	 */
	public showBusinessData(entite: string,options?: { typeContext: 'RULE' | 'DATAFETCH' | 'MAIL_DESTINATAIRE' | 'MAIL' | 'WORKFLOW_VALIDEUR',isSelectEntity?: boolean,isPreviewData?: boolean,preloadedEntity?: any,doBusinessDataSelection?: (businessData: any,path: string) => void }): Observable<{ startEntity: string,entity?: any,listeEntities?: Array<any>,listeRemovedEntities?: Array<any> }> {
		//Chargement des données métiers
		return this.entiteService.loadBusinessData(entite,['MAIL_DESTINATAIRE','MAIL','WORKFLOW_VALIDEUR'].includes(options?.typeContext)).pipe(
			take(1),
			mergeMap(businessData => {
				let bsModalRef: BsModalRef<BusinessDataComponent>;

				//Affichage de la popup
				bsModalRef = this.bsModalService.show(BusinessDataComponent,{
					initialState: {
						businessData,
						entite,
						options
					}
				});

				//Retour de la sélection
				return bsModalRef.onHidden.pipe(
					map(() => bsModalRef.content.result)
				);
			})
		);
	}
}