import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AutorisationContentComponent } from './autorisation-content.component';
import { AutorisationListItemComponent } from './autorisation-list-item.component';
import { AutorisationListComponent } from './autorisation-list.component';
import { AutorisationComponent } from './autorisation.component';

@NgModule({
	imports: [ShareModule],
	declarations: [AutorisationListComponent,AutorisationListItemComponent,AutorisationContentComponent,AutorisationComponent],
	exports: [AutorisationContentComponent]
})
export class AutorisationModule { }
