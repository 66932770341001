import { TypeDroit } from 'src/app/domain/security/right';

/**
 * Enumération des types de flux
 */
export enum TypeFlux {
	/** Enumération **/
	FACTURE = 'FACTURE',
	RH = 'RH',
	SINISTRE = 'SINISTRE',
	ENTRETIEN = 'ENTRETIEN'
}

/**
 * Mapping des types de droits par rapport au type de flux
 */
export const mapTypesDroitForTypesFlux: { [typeFlux: TypeFlux | string]: TypeDroit } = {
	/** Données **/
	[TypeFlux.FACTURE]: TypeDroit.FACTURE,
	[TypeFlux.RH]: TypeDroit.ADMIN_UTILISATEUR,
	[TypeFlux.SINISTRE]: TypeDroit.VEHICULE_SINISTRE,
	[TypeFlux.ENTRETIEN]: TypeDroit.ENTRETIEN
}