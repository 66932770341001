import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
	templateUrl: './notification-destinataire-check.component.html'
})
export class NotificationDestinataireCheckComponent {
	/** Destinataire **/
	@Input() destinataire: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<NotificationDestinataireCheckComponent>) {

	}
}