import { HttpClient } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridsterConfig } from 'angular-gridster2';
import { cloneDeep,concat } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject,combineLatest,of } from 'rxjs';
import { filter,first,map,tap } from 'rxjs/operators';

import { ChartService } from 'src/app/components/chart/chart.service';
import { FactureService } from 'src/app/components/facture/facture.service';
import { ChartOptions,TypeChart } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { TypeDashboard,TypeLayout } from 'src/app/domain/dashboard/dashboard';
import { IEmbeddedDashboard } from 'src/app/domain/dashboard/embedded-dashboard';
import { TypeDroit,TypeTenant } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { DashboardEmbeddedComponent } from './dashboard-embedded.component';

@Injectable()
export class DashboardService {
	/** Enumération des dashboards **/
	private mapDashboards: { [typeDashboard: string]: { type: string,listeTenantTypes?: Array<TypeTenant>,creation?: { state: string,params?: any },isFormulaire?: boolean,getListeActions?: (rightService: RightService,injector: Injector) => Observable<Array<{ icone: string,libelleKey: string,doAction: Function }>>,mapRedirectionForRight?: { [typeDroit: string]: { state: string,mainObject: string } } } } = {
		[TypeDashboard.VEHICULE]: {
			type: 'com.notilus.data.vehicule.Vehicule',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeVehicules'
			},
			isFormulaire: true
		},
		[TypeDashboard.SINISTRE]: {
			type: 'com.notilus.data.vehicule.Sinistre',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeVehiculeSinistres-sinistre'
			},
			getListeActions: (rightService: RightService,injector: Injector) => combineLatest([of(rightService.hasRight(TypeDroit.VEHICULE_SINISTRE,'creation')),injector.get(ConnecteurService).isImportAvailable(TypeFlux.SINISTRE)]).pipe(map(([hasRight,isAvailable]) => hasRight && isAvailable && [{
				icone: 'upload',
				libelleKey: 'actions.importer',
				doAction: () => injector.get(ConnecteurService).showImport(TypeFlux.SINISTRE)
			}] || []))
		},
		[TypeDashboard.CONTRAVENTION]: {
			type: 'com.notilus.data.vehicule.Contravention',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'infractionReferentiel-listeVehiculeContraventions-contravention'
			}
		},
		[TypeDashboard.USER]: {
			type: 'com.notilus.data.user.User',
			creation: {
				state: 'listeUsers-user'
			},
			getListeActions: (rightService: RightService,injector: Injector) => combineLatest([of(rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'creation')),injector.get(ConnecteurService).isImportAvailable(TypeFlux.RH)]).pipe(map(([hasRight,isAvailable]) => hasRight && isAvailable && [{
				icone: 'upload',
				libelleKey: 'actions.importer',
				doAction: () => injector.get(ConnecteurService).showImport(TypeFlux.RH)
			}] || []))
		},
		[TypeDashboard.FINANCEMENT]: {
			type: 'com.notilus.data.vehicule.Financement',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeVehiculeContratsFinancement-loadContratFinancement'
			},
			isFormulaire: true
		},
		[TypeDashboard.FACTURE]: {
			type: 'com.notilus.data.facture.Facture',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeFactures-load'
			},
			getListeActions: (rightService: RightService,injector: Injector) => combineLatest([of([rightService.hasRight(TypeDroit.FACTURE,'creation'),rightService.hasRight(TypeDroit.FACTURE_LAD,'creation')]),injector.get(ConnecteurService).isImportAvailable(TypeFlux.FACTURE)]).pipe(map(([[hasRightFacture,hasRightFactureLAD],isAvailable]) => (hasRightFacture && isAvailable && [{
				icone: 'upload',
				libelleKey: 'actions.importer',
				doAction: () => injector.get(ConnecteurService).showImport(TypeFlux.FACTURE)
			}] || []).concat(hasRightFactureLAD && [{
				icone: 'insert_drive_file',
				libelleKey: 'actions.scanner',
				doAction: () => injector.get(FactureService).showScanFacture()
			}] || []))),
			isFormulaire: true
		},
		[TypeDashboard.ENTRETIEN]: {
			type: 'com.notilus.data.vehicule.Entretien',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeEntretiens'
			},
			getListeActions: (rightService: RightService,injector: Injector) => combineLatest([of(rightService.hasRight(TypeDroit.ENTRETIEN,'creation')),injector.get(ConnecteurService).isImportAvailable(TypeFlux.ENTRETIEN)]).pipe(map(([hasRight,isAvailable]) => hasRight && isAvailable && [{
				icone: 'upload',
				libelleKey: 'actions.importer',
				doAction: () => injector.get(ConnecteurService).showImport(TypeFlux.ENTRETIEN)
			}] || []))
		},
		[TypeDashboard.GRILLE_ATTRIBUTION]: {
			type: 'com.notilus.data.vehicule.GrilleAttribution',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeGrillesAttribution-grilleAttribution'
			}
		},
		[TypeDashboard.CONDUCTEUR]: {
			type: 'com.notilus.data.vehicule.Conducteur',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeConducteurs-loadConducteur'
			}
		},
		[TypeDashboard.LOT_COMPTABLE]: {
			type: 'com.notilus.data.comptabilite.lot.LotComptable',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeLotsComptables'
			},
			isFormulaire: true
		},
		[TypeDashboard.DEMANDE_IDENTIFICATION]: {
			type: 'com.notilus.data.sgi.DemandeIdentification',
			listeTenantTypes: [TypeTenant.CLIENT]
		},
		[TypeDashboard.DEMANDE_VEHICULE]: {
			type: 'com.notilus.data.vehicule.cotation.DemandeVehicule',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'vehiculeCommandeReferentiels-listeDemandesVehicule',
				params: {
					isCreation: true
				}
			}
		},
		[TypeDashboard.DEMANDE_COTATION]: {
			type: 'com.notilus.data.vehicule.cotation.DemandeCotation',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'vehiculeCommandeReferentiels-listeDemandesCotation'
			}
		},
		[TypeDashboard.FACTURE_DETAIL]: {
			type: 'com.notilus.data.facture.Detail',
			listeTenantTypes: [TypeTenant.CLIENT],
			mapRedirectionForRight: {
				[TypeDroit.FACTURE_DETAIL]: {
					state: 'listeFactureDetails',
					mainObject: null
				},
				[TypeDroit.FACTURE]: {
					state: 'listeFactures',
					mainObject: 'facture'
				}
			}
		},
		[TypeDashboard.VEHICULE_RELEVE_ENERGIE]: {
			type: 'com.notilus.data.vehicule.VehiculeReleveEnergie',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeVehiculeReleveEnergie'
			}
		},
		[TypeDashboard.VEHICULE_COMPTEUR]: {
			type: 'com.notilus.data.vehicule.VehiculeCompteur',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeVehiculeCompteur'
			}
		},
		[TypeDashboard.VEHICULE_AFFECTATION]: {
			type: 'com.notilus.data.vehicule.Affectation',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeAffectations'
			}
		},
		[TypeDashboard.IMPORT]: {
			type: 'com.notilus.data.connecteur.ConnecteurExecution'
		},
		[TypeDashboard.ETAT_LIEUX]: {
			type: 'com.notilus.data.vehicule.etatLieux.EtatLieux',
			listeTenantTypes: [TypeTenant.CLIENT],
			creation: {
				state: 'listeEtatsLieux-etatLieux'
			}
		},
		[TypeDashboard.SGI_LOT]: {
			type: 'com.notilus.data.vehicule.sgi.Lot',
			listeTenantTypes: [TypeTenant.ROOT]
		},
		[TypeDashboard.VEHICULE_RESERVATION]: {
			type: 'com.notilus.data.vehicule.pool.Reservation',
			listeTenantTypes: [TypeTenant.CLIENT]
		},
		[TypeDashboard.ARTICLE]: {
			type: 'com.notilus.data.aide.Article',
			creation: {
				state: 'listeArticles-loadArticle'
			}
		},
		[TypeDashboard.EQUIPEMENT]: {
			type: 'com.notilus.data.equipement.Equipement',
			creation: {
				state: 'materielReferentiel-listeEquipements-loadEquipement'
			}
		},
		[TypeDashboard.VEHICULE_AFFECTATION_ECHEANCE]: {
			type: 'com.notilus.data.vehicule.AffectationEcheance',
			listeTenantTypes: [TypeTenant.CLIENT]
		}
	};

	/** Liste des types de layout **/
	private mapTypesLayout: { [typeLayout: string]: { minWidth?: number,maxWidth?: number } } = {
		[TypeLayout.WEB]: {
			minWidth: 1025
		},
		[TypeLayout.TABLETTE]: {
			minWidth: 631,
			maxWidth: 1024
		},
		[TypeLayout.MOBILE]: {
			maxWidth: 630
		}
	};

	/** Cache des dashboards **/
	private mapDashboardsCache: { [typeDashboard: string]: boolean } = {};

	/** Map des règles de filtrage par entité **/
	private mapRulesForEntite: { [entite: string]: any } = {};

	/** Cache de la liste des dashboards disponibles **/
	private listeAvailableDashboards: Array<{ type: string,typeDashboard: TypeDashboard,url?: string,listeTenantTypes?: Array<TypeTenant> }> = null;

	/** Sujet de redimensionnement **/
	public onChartResizeSubject: Subject<{ isResizing: boolean,isMenu?: boolean }> = new Subject<{ isResizing: boolean,isMenu?: boolean }>();

	/** Ecoute du redimensionnement de graphiques **/
	public onChartResize$: Observable<{ isResizing: boolean,isMenu?: boolean }>;

	/** Sujet d'ajout de filtre partagé **/
	public onRuleFilterAddedSubject: Subject<{ entity: string,idChart?: number }> =  new Subject<{ entity: string,idChart: number }>();

	/** Ecoute de l'ajout de filtre partagé **/
	public onRuleFilterAdded$: Observable<{ entity: string,idChart?: number }>;

	/** Mode de dashboard embarqué sélectionné **/
	embeddedDashboardMode: 'LISTE' | 'GRAPHIQUE' = 'LISTE';

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private successiveSearchService: SuccessiveSearchService,private translateService: TranslateService,private entiteService: EntiteService,private chartService: ChartService
			,private extractionService: ExtractionService,private rightService: RightService,private ruleService: RuleService,private bsModalService: BsModalService,private pageContentService: PageContentService) {
		//Création de l'observable sur le redimensionnement des graphiques
		this.onChartResize$ = this.onChartResizeSubject.asObservable();

		//Création de l'observable sur l'ajout de filtre partagé
		this.onRuleFilterAdded$ = this.onRuleFilterAddedSubject.asObservable();
	}

	/**
	 * Récupération de la liste des types de layout
	 */
	public getListeTypesLayout(): Array<TypeLayout> {
		//Retour des types de layout
		return Object.keys(this.mapTypesLayout).map(t => t as TypeLayout);
	}

	/**
	 * Vérification de la présence d'un dashboard
	 */
	public hasDashboard(typeDashboard?: TypeDashboard): Observable<boolean> {
		let type: string;

		//Lecture du type de dashboard (depuis la route si nécessaire)
		typeDashboard = typeDashboard || this.layoutService.getExtendedRouteData().typeDashboard;

		//Récupération du type
		type = this.mapDashboards[typeDashboard]?.type

		//Vérification de la présence d'un type
		if (type) {
			//Vérification du cache
			if (this.mapDashboardsCache[typeDashboard] == null) {
				//Recherche de la présence d'un dashboard pour le type
				return this.http.post(`${environment.baseUrl}/controller/Dashboard/hasDashboard`,{ name: type }).pipe(
					first(),
					map((result: Result) => {
						//Mise en cache
						this.mapDashboardsCache[typeDashboard] = result.data.hasDashboard;

						//Retour de la présence de dashboard pour l'entité
						return this.mapDashboardsCache[typeDashboard];
					})
				)
			} else
				//Retour de la présence de dashboard pour l'entité
				return of(this.mapDashboardsCache[typeDashboard] || false);
		} else
			//Aucun dashboard
			return of(false);
	}

	/**
	 * Récupération du dashboard pour un type
	 */
	public getDashboardForType(typeDashboard: TypeDashboard): Observable<{ type: string,listeTenantTypes?: Array<TypeTenant>,creation?: { state: string,params?: any },typeDroit?: TypeDroit | Array<TypeDroit>,getListeActions?: (rightService: RightService,injector: Injector) => Observable<Array<{ icone: string,libelleKey: string,doAction: Function }>> }> {
		//Retour du dashboard
		return of({
			...this.mapDashboards[typeDashboard],
			typeDroit: this.layoutService.getListeRoutesByExtendedDataField('typeDashboard',typeDashboard)?.filter(r => !r.data?.isEmbeddedDashboard)?.[0]?.data?.typeDroit as (TypeDroit | Array<TypeDroit>)
		});
	}

	/**
	 * Affichage d'un dashboard
	 */
	public showDashboard() {
		//Navigation vers le dashboard
		this.layoutService.goToByState('dashboard-noreset',{
			routeParams: {
				typeDashboard: this.layoutService.getExtendedRouteData().typeDashboard
			},
			routeData: {
				isForceEdition: true
			}
		});
	}

	/**
	 * Affichage de la liste
	 */
	public showListe(typeDashboard: TypeDashboard) {
		let path: string;

		//Récupération du chemin
		path = this.layoutService.getListeRoutesByExtendedDataField('typeDashboard',typeDashboard)?.filter(r => !r.data?.isEmbeddedDashboard)?.[0]?.path;

		//Vérification de l'existence d'une liste
		if (path) {
			//Navigation vers la liste
			this.layoutService.goToByUrl(path);
		} else {
			//AngularJS - Navigation vers le state (de création qui est identique à celui de la liste)
			this.getDashboardForType(typeDashboard).subscribe({
				next: dashboard => this.layoutService.goToByState(dashboard?.creation?.state)
			})
		}
	}

	/**
	 * Chargement d'un dashboard
	 */
	public loadDashboard(typeDashboard: TypeDashboard,isDefault: boolean,isIncludeAll: boolean,isScopeFormulaire: boolean,idDashboard: number,idProfil: number): Observable<Result> {
		let params: URLSearchParams = new URLSearchParams();

		//Définition des données
		params.append('entite',typeDashboard && this.mapDashboards[typeDashboard]?.type || '');
		params.append('isDefault',isDefault != null ? isDefault.toString() : '');
		params.append('isIncludeAll',isIncludeAll != null ? isIncludeAll.toString() : '');
		params.append('isScopeFormulaire',isScopeFormulaire != null ? isScopeFormulaire.toString() : '');
		params.append('idDashboard',(idDashboard || 0).toString());
		params.append('idProfil',(idProfil || 0).toString());

		//Chargement du dashboard
		return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/loadDashboard`,params,{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
	}

	/**
	 * Enregistrement du dashboard
	 */
	public saveDashboard(typeOrigine: 'USER' | 'PROFIL',dashboard: any): Observable<Result> {
		//Enregistrement du dashboard
		return this.http.put<Result>(`${environment.baseUrl}/controller/Dashboard/saveDashboard/${typeOrigine}`,dashboard);
	}

	/**
	 * Suppression du dashboard
	 */
	public deleteDashboard(idDashboard: number): Observable<Result> {
		//Suppression du dashboard
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Dashboard/deleteDashboard/${idDashboard}`);
	}

	/**
	 * Récupération de l'entité pour un dashboard
	 */
	public getEntityForDashboard(typeDashboard: TypeDashboard): string {
		//Retour de l'entité du dashboard
		return this.mapDashboards[typeDashboard].type;
	}

	/**
	 * Récupération de la liste des dashboards typés 'Formulaire'
	 */
	public getListeDashboardsForFormulaire(): Array<{ type: string,listeTenantTypes?: Array<TypeTenant>,creation?: { state: string,params?: any },isFormulaire?: boolean,getListeActions?: (rightService: RightService,injector: Injector) => Observable<Array<{ icone: string,libelleKey: string,doAction: Function }>>,mapRedirectionForRight?: { [typeDroit: string]: { state: string,mainObject: string } },typeDroit: TypeDroit,typeDashboard: string }> {
		//Retour de la liste des dashboards typés 'Formulaire'
		return Object.entries(this.mapDashboards).filter(([,dashboard]) => dashboard.isFormulaire).map(([typeDashboard,dashboard]) => ({
			...dashboard,
			typeDroit: this.layoutService.getListeRoutesByExtendedDataField('typeDashboard',typeDashboard)?.filter(r => typeof r.data?.typeDroit == 'string')?.[0]?.data?.typeDroit as TypeDroit,
			typeDashboard: typeDashboard
		}));
	}

	/**
	 * Récupération du layout correspondant à la taille de l'écran
	 */
	public getCurrentLayout(): TypeLayout {
		let width: number;

		//Récupération de la largeur de la fenêtre
		width = (window as any).innerWidth;

		//Retour du type de layout associé à la largeur de la fenêtre
		return Object.entries(this.mapTypesLayout).filter(([,value]) => (width >= value.minWidth || value.minWidth == null) && (width <= value.maxWidth || value.maxWidth == null)).map(([typeLayout]) => typeLayout as TypeLayout)?.[0];
	}

	/**
	 * Initialisation du layout
	 */
	public initLayout(dashboard: any,typeLayout: TypeLayout,gridOptions: GridsterConfig) {
		let currentX: number = 0;
		let currentY: number = 0;

		//Parcours des graphiques
		dashboard?.listeDashboardCharts?.forEach((dashboardChart,idxLien) => {
			let chartLayout: any;

			//Récupération du layout spécifique
			chartLayout = dashboardChart.listeChartLayout?.find(l => l.typeLayout == typeLayout);

			//Vérification de la présence d'un layout
			if (chartLayout) {
				//Mise à jour du graphique
				dashboardChart.x = chartLayout.positionX;
				dashboardChart.y = chartLayout.positionY;
				dashboardChart.cols = chartLayout.sizeX;
				dashboardChart.rows = chartLayout.sizeY;
			} else if (gridOptions) {
				//Positionnement du graphique par défaut
				dashboardChart.x = currentX;
				dashboardChart.y = currentY;
				dashboardChart.cols = gridOptions.defaultItemCols;
				dashboardChart.rows = gridOptions.defaultItemRows;

				//Ajout d'une colonne
				currentX += gridOptions.defaultItemCols;

				//Vérification de la position en X
				if (currentX >= gridOptions.maxCols) {
					//Remise à zéro
					currentX = 0;

					//Ajout d'une ligne
					currentY += gridOptions.defaultItemRows;
				}
			}

			//Définition de la position
			dashboardChart.position = idxLien;
		});
	}

	/**
	 * Récupération de la liste des dashboards disponibles
	 */
	public getListeAvailableDashboards(user: User): Observable<Array<{ type: string,typeDashboard: TypeDashboard,url?: string,listeTenantTypes?: Array<TypeTenant> }>> {
		//Vérification de la liste des dashboards disponibles
		if (this.listeAvailableDashboards == null) {
			//Recherche des dashboards disponibles
			return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/findAllAvailableDashboards`,null).pipe(
				first(),
				map(result => {
					let listeAvailableEntites: Array<string>;
					let mapDashboards: { [type: string]: { type: string,typeDashboard: TypeDashboard,url?: string,listeTenantTypes?: Array<TypeTenant> } } = {};

					//Récupération de la liste des entités
					listeAvailableEntites = result?.data?.listeEntites || [];

					//Parcours des dashboards disponibles
					Object.entries(this.mapDashboards).forEach(([typeDashboard,dashboard]) => {
						//Vérification de la lisibilité de l'entité
						if (!dashboard.listeTenantTypes || dashboard.listeTenantTypes.includes(user.tenant.type) || user.tenant.type == TypeTenant.REVENDEUR && dashboard.listeTenantTypes.includes(user.tenant.revendeur.type)) {
							//Définition du dashboard
							mapDashboards[dashboard.type] = {
								...dashboard,
								typeDashboard: typeDashboard as TypeDashboard
							};
						}
					});

					//Retour de la liste des dashboards disponibles
					return listeAvailableEntites.filter(e => mapDashboards[e]).map(e => mapDashboards[e]);
				}),
				tap(listeAvailableDashboards => this.listeAvailableDashboards = listeAvailableDashboards)
			);
		} else
			//Retour de la liste des dashboards disponibles
			return of(this.listeAvailableDashboards);
	}

	/**
	 * Exécution d'un graphique
	 */
	public executeChart(idChart: number,index: number,numPage: number,options: { chart: any,rule?: any,nbValues: number,sharedRule?: any,listeSelectedValues?: Array<any>,filteringEntity?: string,idFilteringObject?: number }): Observable<Result> {
		//Exécution du graphique
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chart/executeChart/${idChart}/${index}?numPage=${numPage}`,options);
	}

	/**
	 * Suppression du cache
	 */
	public evictCache() {
		//Suppression du cache des dashboards
		this.mapDashboardsCache = {};

		//Suppression du cache des dashboard disponibles
		this.listeAvailableDashboards = null;
	}

	/**
	 * Sélection d'un graphique
	 */
	public selectChart(typeDashboard: TypeDashboard,idObjet: number,idProfil: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Sélection du type de dashboard (si nécessaire)
		(typeDashboard ? this.getDashboardForType(typeDashboard) : of(null)).subscribe((dashboard) => {
			let listeFilters: Array<string> = [];

			//Vérification du type de dashboard
			if (dashboard)
				//Ajout du filtre sur l'entité
				listeFilters.push(`entite=${dashboard.type}`);

			//Vérification du filtrage des entités disponibles par rapport à une entité parente
			if (idObjet)
				//Ajout du filtre sur le scope
				listeFilters.push(`isScopeFormulaire=true`);

			//Vérification du profil
			if (idProfil)
				//Ajout du filtre sur le profil
				listeFilters.push(`idProfil=${idProfil}`);

			//Affichage de la recherche successive
			this.successiveSearchService.showSuccessiveSearch({
				title: this.translateService.instant('dashboard.chart.selection.title'),
				typeSearchLevel: 'ENTITE',
				search: {
					listeFilter: []
				},
				isShowFilter: false,
				groupeKey: null,
				idGroupeKey: 'entite',
				idItemKey: 'idExtraction',
				isSingleResult: true,
				listeSearchLevels: [(typeDashboard == null || idObjet) && {
					type: 'ENTITE',
					libelle: this.translateService.instant('dashboard.chart.selection.entite'),
					getLibelleDynamique: (groupe: any) => this.entiteService.translateEntityCode(groupe.entite.split('.').pop()),
					uri: () => `/controller/Dashboard/filtreItemsByEntite${listeFilters.length > 0 ? '?' + listeFilters.join('&') : ''}`,
					getListeFilters: () => [{
						clef: 'entity',
						isDefault: true
					}],
					isListView: false,
					getTitle: (item) => this.entiteService.translateEntityCode(item.entite.split('.').pop()),
					getAssociatedFilter: (value,groupe) => {
						//Vérification de l'utilisation d'un regroupement par calendrier
						if (groupe.entite == 'com.notilus.data.calendrier.Calendrier') {
							//Retour du filtre sur les calendriers
							return {
								clef: 'calendrier',
								typeComparaison: TypeComparaison.IS_NOT_NULL,
								type: TypeFilter.DECIMAL
							};
						} else {
							//Retour du filtre pour l'entité
							return {
								clef: 'entite',
								valeur: groupe.entite,
								typeComparaison: TypeComparaison.EQUAL,
								type: TypeFilter.STRING
							};
						}
					},
					getListeFiltersExtended(options) {
						//Retour des filtres
						return options?.search?.listeFilter || [];
					}
				},{
					type: 'ITEM',
					libelle: this.translateService.instant('dashboard.chart.selection.indicateur'),
					uri: () => `/controller/Dashboard/filtreItems${listeFilters.length > 0 ? '?' + listeFilters.join('&') : ''}`,
					getListeFilters: () => [{
						clef: 'libelle',
						isDefault: true
					},{
						clef: 'reference',
						isDefault: true
					}],
					defaultOrder: 'libelle,reference',
					isListView: true,
					getTitle: (item: any) => item.libelle + ' (' + item.reference + ')',
					getAvatarIcon: (item) => item.calendrier ? 'event' : this.chartService.getListeTypesGraphique().find(t => t.type == item.chart.firstType).icon,
					getAvatarIconType: (item) => !item.calendrier ? this.chartService.getListeTypesGraphique().find(t => t.type == item.chart.firstType).iconType : null,
					getListeItemAttributes: (item: any) => [item.entite && {
						key: this.translateService.instant('dashboard.chart.selection.entite'),
						value: this.entiteService.translateEntityCode(item.entite.split('.').pop())
					},item.chart && {
						key: this.translateService.instant('dashboard.chart.selection.typeGraphique'),
						value: this.getTypeChartLibelle(item.chart)
					}].filter(f => !!f),
					getListeFiltersExtended: (options,listeCumulatedFilters) => {
						//Retour des filtres
						return (listeCumulatedFilters || []).concat([]);
					},
					getItemAction: () => ({
						icon: 'visibility',
						doAction: (item: any) => {
							//Ouverture de la pop-up de prévisualisation
							this.chartService.previewChart(item.chart,true).pipe(filter(isChartAdded => !!isChartAdded)).subscribe({
								next: () => {
									//Ajout du graphique
									subject.next(item);
								}
							});
						}
					})
				}].filter(l => !!l)
			}).subscribe({
				next: result => {
					//Vérification du résultat
					if (result) {
						//Vérification du résultat obtenu
						if (result.listeSelectionsItems?.length) {
							//Retour de la sélection
							subject.next(result.listeSelectionsItems[0]);

							//Fin de traitement
							subject.complete();
						} else if (result.isDismissed) {
							//Aucune sélection
							subject.next(null);
						}
					} else {
						//Emission d'une erreur
						subject.error(null);

						//Fin de traitement
						subject.complete();
					}
				},
				error: (erreur) => {
					//Transmission de l'erreur
					subject.error(erreur);

					//Fin de traitement
					subject.complete();
				}
			});
		});

		return subject;
	}

	/**
	 * Récupération du libellé d'un type de graphique
	 */
	private getTypeChartLibelle(chart: any) {
		//Récupération de la traduction
		return chart.listeDefinitions?.length ? this.translateService.instant('chart.typeGraphique.'+chart.listeDefinitions[0].type) + (chart.listeDefinitions.length > 1 ? ' -> ' + this.translateService.instant('chart.typeGraphique.'+chart.listeDefinitions[1].type) : '') : this.translateService.instant('common.nonDefini');
	}

	/**
	 * Redirection vers la liste
	 */
	public redirectToList(dashboardChart: any,currentDefinition: any,chartOptions: ChartOptions,listeSelectedValues: Array<any>) {
		let rule: any;
		let definition: any;
		let cle: any;
		let parentDefinitionDetail: any;
		let sharedRule: any;
		let listeSubRules: Array<any>;
		let listeSelectedDetails: Array<any> = [];

		//Fermeture des popups éventuelles
		this.bsModalService.hide();

		//Récupération des règles
		rule = dashboardChart.chart.extraction.rule;

		//Vérification des règles
		if (rule && dashboardChart.rule?.listeDetails?.length) {
			//Création d'une sous-règle contenant la règle initiale
			listeSubRules = [{ rule: cloneDeep(rule) }];

			//Définition en mode 'AND'
			rule.operateur = 'AND';

			//Ajout des filtres
			rule.listeDetails = concat(listeSubRules,dashboardChart.rule.listeDetails);
		} else if (!rule || !rule.listeDetails)
			//Définition des règles personnalisées
			rule = dashboardChart.rule;

		//Vérification de la règle
		rule = rule || {
			_type: 'com.notilus.data.rule.Rule',
			operateur: 'AND',
			listeDetails: []
		};

		//Vérification du type de dashboard
		if (dashboardChart.chart.extraction.entity) {
			//Récupération de la règle partagée
			sharedRule = this.getRule(dashboardChart.chart.extraction.entity);

			//Vérification de la règle partagée
			if (sharedRule && Array.isArray(sharedRule.listeDetails) && sharedRule.listeDetails.length)
				//Ajout des filtres supplémentaires
				rule.listeDetails = rule.listeDetails.concat(sharedRule.listeDetails);
		}

		//Obtention de la définition
		definition = dashboardChart.chart.listeDefinitions[0];

		//Vérification du filtre du graphique parent
		if (definition && currentDefinition?.listeSelectedValues) {
			//Définition du détail associé à la première clé du graphique parent
			parentDefinitionDetail = {
				filter: {
					filter: definition.listeCles[0].cle,
					type: definition.listeCles[0].typeCle,
					typeAgregation: 'NON_DEFINI',
					typeGroupement: definition.listeCles[0].typeGroupement,
					enum: this.ruleService.getFieldType(definition.listeCles[0].typeCle)?.type == 'ENUM'
				}
			}

			//Vérification du type du graphique parent
			if ([TypeChart.PIE,TypeChart.DONUT].includes(definition.type) && currentDefinition.listeSelectedValues.length > 1) {
				//Complétion du filtre
				parentDefinitionDetail.filter = Object.assign(parentDefinitionDetail.filter,{
					operateur: 'IN',
					listeValues: currentDefinition.listeSelectedValues.map(v => ({ type: 'SIMPLE',value: v })),
					label: currentDefinition.listeSelectedValues.join(', ')
				});
			} else {
				//Complétion du filtre
				parentDefinitionDetail.filter = Object.assign(parentDefinitionDetail.filter,{
					operateur: 'EQUAL',
					listeValues: [{
						type: 'SIMPLE',
						value: currentDefinition.listeSelectedValues[0]
					}],
					label: currentDefinition.listeSelectedValues[0]
				});
			}

			//Ajout du détail associé à la première clé du graphique parent
			rule.listeDetails.push(parentDefinitionDetail);

			//Vérification du mode
			if (definition.listeCles.length > 1 && currentDefinition.listeSelectedValues.length > 1) {
				//Ajout du détail associé à la deuxième clé du graphique parent
				rule.listeDetails.push({
					filter: {
						filter: definition.listeCles[1].cle,
						type: definition.listeCles[1].typeCle,
						operateur: 'EQUAL',
						typeAgregation: 'NON_DEFINI',
						typeGroupement: definition.listeCles[1].typeGroupement,
						listeValues: [{
							type: 'SIMPLE',
							value: currentDefinition.listeSelectedValues[1]
						}],
						enum: this.ruleService.getFieldType(definition.listeCles[1].typeCle)?.type == 'ENUM',
						label: currentDefinition.listeSelectedValues[1]
					}
				});
			}
		}

		//Vérification des valeurs sélectionnées
		if (listeSelectedValues) {
			//Création d'une sous-règle contenant la règle initiale
			listeSubRules = rule.listeDetails?.length ? [{ rule: cloneDeep(rule) }] : [];

			//Définition en mode 'AND'
			rule.operateur = 'AND';

			//Vérification du type de graphique
			if ([TypeChart.PIE,TypeChart.DONUT].includes(currentDefinition.type) && listeSelectedValues.length > 1) {
				//Récupération de la clé
				cle = currentDefinition.listeCles[0];

				//Ajout du détail
				listeSelectedDetails.push({
					filter: {
						filter: cle.cle,
						type: cle.typeCle,
						operateur: 'IN',
						typeAgregation: 'NON_DEFINI',
						typeGroupement: cle.typeGroupement,
						listeValues: listeSelectedValues.map(v => ({ type: 'SIMPLE',value: v })),
						enum: this.ruleService.getFieldType(cle.typeCle)?.type == 'ENUM',
						label: listeSelectedValues.map((v,i) => chartOptions.getLabel(v,i)).join(', ')
					}
				});
			} else {
				//Parcours des clés
				currentDefinition.listeCles.forEach((cle,i) => {
					//Ajout du détail
					listeSelectedDetails.push({
						filter: {
							filter: cle.cle,
							type: cle.typeCle,
							operateur: 'EQUAL',
							typeAgregation: 'NON_DEFINI',
							typeGroupement: cle.typeGroupement,
							listeValues: [{
								type: 'SIMPLE',
								value: listeSelectedValues[i]
							}],
							enum: this.ruleService.getFieldType(cle.typeCle)?.type == 'ENUM',
							label: chartOptions.getLabel(listeSelectedValues[i],i)
						}
					});
				});
			}

			//Ajout des filtres
			rule.listeDetails = listeSubRules.concat(listeSelectedDetails);
		}

		//Récupération des informations relative à l'entité du graphique
		this.extractionService.getListeEntites().pipe(
			map(listeEntites => listeEntites.find(e => e.code == dashboardChart.chart.extraction.entity))
		).subscribe({
			next: information => {
				let dashboardDetail: { type: string,listeTenantTypes?: Array<TypeTenant>,creation?: { state: string,params?: any },getListeActions?: (rightService: RightService,injector: Injector) => Observable<Array<{ icone: string,libelleKey: string,doAction: Function }>>,mapRedirectionForRight?: { [typeDroit: string]: { state: string,mainObject: string } } };

				//Définition du titre
				rule.titre = dashboardChart.customLibelle || dashboardChart.chart.libelle;
				rule.valeur = rule.listeDetails.filter(d => d.filter?.label).map(d => d.filter?.label).join(', ');
				rule.entite = dashboardChart.chart.extraction.entity;

				//Récupération des paramètres de redirection du dashboard
				dashboardDetail = Object.entries(this.mapDashboards).find(([,dashboard]) => dashboard.type == dashboardChart.chart.extraction.entity)?.[1];

				//Vérification du détail de l'entité
				if (dashboardDetail?.mapRedirectionForRight) {
					//Parcours des droits
					for (let droit in dashboardDetail.mapRedirectionForRight) {
						//Vérification du droit de l'utilisateur
						if (this.rightService.hasRight(droit as TypeDroit,'consultation')) {
							//Surcharge des paramètres de redirection
							information.mainObject = dashboardDetail.mapRedirectionForRight[droit].mainObject;
							information.state = dashboardDetail.mapRedirectionForRight[droit].state;

							//Arrêt
							break;
						}
					}
				}

				//Définition de l'objet principal
				rule.mainObject = information.mainObject;

				//Sauvegarde de la recherche
				sessionStorage.setItem('savedSearch.'+information.state,JSON.stringify({
					extraData: rule
				}));

				//Scroll vers le haut de la page
				window.scrollTo(0,0);

				//Redirection vers la page de liste
				this.layoutService.goToByState(information.state);
			}
		});
	}

	/**
	 * Ajout d'une règle de filtrage
	 */
	public addRule(entite: string,dashboardChart: any,definition: any,listeKeys: any) {
		let idChart: number;
		let listeRuleDetails: Array<any>;

		//Lecture de l'identifiant du chart
		idChart = dashboardChart.chart.idChart;

		//Vérification des clés
		if (!Array.isArray(listeKeys))
			//Définition de la liste de clés
			listeKeys = [listeKeys];

		//Vérification de la liste des entités
		if (!this.mapRulesForEntite[entite]) {
			//Ajout d'une nouvelle règle
			this.mapRulesForEntite[entite] = this.createRule(entite,dashboardChart,definition,listeKeys);
		} else {
			//Vérification de l'absence de doublons
			if (!this.mapRulesForEntite[entite].listeDetails.some(detail => detail.idChart == idChart)) {
				//Création de la liste des filtres
				listeRuleDetails = this.createListeRuleDetails(dashboardChart,definition,listeKeys);

				//Concaténation des filtres
				this.mapRulesForEntite[entite].listeDetails = this.mapRulesForEntite[entite].listeDetails.concat(listeRuleDetails);

				//Création d'un nouvel objet
				this.mapRulesForEntite[entite] = Object.assign({},this.mapRulesForEntite[entite]);
			}
		}
	}

	/**
	 * Récupération de la règle associée à une entité
	 */
	public getRule(entite: string): any {
		//Retour de la règle
		return this.mapRulesForEntite[entite] || null;
	}

	/**
	 * Suppression d'une règle correspondant à un graphique
	 */
	public removeFilterByIdChart(entity: string,idChart: number) {
		//Vérification de la présence d'une règle
		if (this.mapRulesForEntite[entity]) {
			//Filtrage de la liste des détails
			this.mapRulesForEntite[entity].listeDetails = this.mapRulesForEntite[entity].listeDetails.filter(detail => detail.idChart != idChart);

			//Création d'un nouvel objet
			this.mapRulesForEntite[entity] = Object.assign({},this.mapRulesForEntite[entity]);

			//Vérification de la liste des détails
			if (!this.mapRulesForEntite[entity].listeDetails?.length)
				//Retrait de l'entité
				this.mapRulesForEntite[entity] = null;
		}
	}

	/**
	 * Création d'une règle de filtrage
	 */
	private createRule(entite: string,dashboardChart: any,definition: any,listeKeys: Array<any>): any {
		let listeDetails: Array<any>;

		//Création de la liste des détails
		listeDetails = this.createListeRuleDetails(dashboardChart,definition,listeKeys);

		//Retour de la règle
		return {
			operateur: 'AND',
			_type: 'com.notilus.data.rule.Rule',
			entite,
			listeDetails
		}
	}

	/**
	 * Création de la liste des détails de règle
	 */
	private createListeRuleDetails(dashboardChart: any,definition: any,listeKeys: Array<any>): Array<any> {
		let listeRuleDetails: Array<any> = [];
		let idChart: number;

		//Lecture de l'identifiant du chart
		idChart = dashboardChart.chart.idChart;

		//Vérification de la présence d'une règle de base
		if (dashboardChart?.chart?.extraction?.rule?.listeDetails?.length)
			//Ajout de la règle de base
			listeRuleDetails = listeRuleDetails.concat(dashboardChart.chart.extraction.rule.listeDetails);

		//Vérification de la présence d'une règle personnalisée
		if (dashboardChart.rule?.listeDetails?.length)
			//Ajout de la règle personnalisée
			listeRuleDetails = listeRuleDetails.concat(dashboardChart.rule.listeDetails);

		//Vérification de la liste des clés
		if (Array.isArray(listeKeys) && listeKeys.length > 1 && definition.listeCles.length > 1) {
			//Ajout du premier filtre sélectionné
			listeRuleDetails.push({
				idChart,
				isSelected: true,
				libelle: listeKeys[0] + ', ' + listeKeys[1],
				filter: {
					filter: definition.listeCles[0].cle,
					type: definition.listeCles[0].typeCle,
					typeGroupement: definition.listeCles[0].typeGroupement,
					operateur: 'EQUAL',
					typeAgregation: 'NON_DEFINI',
					listeValues: [{ type: 'SIMPLE',value: listeKeys[0] }]
				}
			});

			//Ajout du second filtre sélectionné
			listeRuleDetails.push({
				idChart: idChart,
				filter: {
					filter: definition.listeCles[1].cle,
					type: definition.listeCles[1].typeCle,
					typeGroupement: definition.listeCles[1].typeGroupement,
					operateur: 'EQUAL',
					typeAgregation: 'NON_DEFINI',
					listeValues: [{ type: 'SIMPLE',value: listeKeys[1] }]
				}
			});
		} else {
			//Ajout du filtre sélectionné
			listeRuleDetails.push({
				idChart: idChart,
				isSelected: true,
				libelle: listeKeys.join(', '),
				filter: {
					filter: definition.listeCles[0].cle,
					type: definition.listeCles[0].typeCle,
					typeGroupement: definition.listeCles[0].typeGroupement,
					operateur: 'EQUAL',
					typeAgregation: 'NON_DEFINI',
					listeValues: Array.isArray(listeKeys) && listeKeys.length && listeKeys.map((key) => ({ type: 'SIMPLE',value: key })) || [{ type: 'SIMPLE',value: null }]
				}
			});
		}

		//Retour de la liste des détails
		return listeRuleDetails.map(detail => ({ ...detail,idChart }));
	}

	/**
	 * Chargement d'une personnalisation de graphique
	 */
	public loadDashboardChart(idLien: number): Observable<Result> {
		//Chargement de la personnalisation du graphique
		return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/loadDashboardChart/${idLien}`,null);
	}

	/**
	 * Enregistrement d'un graphique
	 */
	public saveDashboardChart(idDashboard: number,dashboardChart: any): Observable<Result> {
		//Retrait de la liste des graphiques du dashboard
		delete dashboardChart.dashboard?.listeDashboardCharts;

		//Enregistrement du graphique
		return this.http.put<Result>(`${environment.baseUrl}/controller/Dashboard/saveDashboardChart/${idDashboard}`,dashboardChart);
	}

	/**
	 * Mise à jour du mode de dashboard embarqué sélectionné
	 */
	public setEmbeddedDashboardMode(embeddedDashboardMode: 'LISTE' | 'GRAPHIQUE') {
		let embeddedDashboard: IEmbeddedDashboard;
		let listeStaticCharts: Array<{ options: ChartOptions,data: Page<any>,x: number,y: number,cols: number,rows: number }>;
		let isEdition: boolean = false;

		//Vérification du mode
		if (this.pageContentService?.getCurrentOpenedMode() == null && embeddedDashboardMode == 'GRAPHIQUE' && this.embeddedDashboardMode != 'GRAPHIQUE') {
			//Vérification du type de composant instancié
			if ((embeddedDashboard = this.layoutService.getRouteComponentInstance() as IEmbeddedDashboard).getListeStaticCharts !== undefined)
				//Récupération des graphiques statiques
				listeStaticCharts = embeddedDashboard.getListeStaticCharts();

			//Affichage du dashboard
			this.pageContentService.open(DashboardEmbeddedComponent,{
				data: {
					typeDashboard: this.layoutService.getExtendedRouteData().typeDashboard,
					idObjet: this.layoutService.getRouteComponentInstance().getIdObject(),
					listeStaticCharts,
					onSwitchEdition: (_isEdition: any) => isEdition = _isEdition
				},
				allowSkipCloseConfirm: true,
				disableClose: () => isEdition
			},'sub').subscribe({
				error: (() => {
					//Réinitialisation du mode
					this.resetEmbeddedDashboardMode()
				}),
				complete: () => {
					//Réinitialisation du mode
					this.resetEmbeddedDashboardMode();
				}
			});

			//Mise à jour du mode
			this.embeddedDashboardMode = embeddedDashboardMode;
		} else if (this.pageContentService?.getCurrentOpenedMode() == 'sub' && embeddedDashboardMode == 'LISTE' && this.embeddedDashboardMode != 'LISTE') {
			//Affichage de la liste des éléments
			if (this.pageContentService.close(null,'sub'))
				//Mise à jour du mode
				this.embeddedDashboardMode = embeddedDashboardMode;
		}
	}

	/**
	 * Remise à zéro du mode de dashboard embarqué
	 */
	public resetEmbeddedDashboardMode() {
		//Mise à jour du mode (en liste par défaut)
		this.embeddedDashboardMode = 'LISTE';
	}

	/**
	 * Récupération du mode de dashboard embarqué sélectionné
	 */
	public getEmbeddedDashboardMode(): 'LISTE' | 'GRAPHIQUE' {
		//Mise à jour du mode
		return this.embeddedDashboardMode;
	}
}
