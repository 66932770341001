import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';

@Injectable()
export class CarburantService {
	/** Liste des types d'énergie **/
	private readonly listeTypesEnergie: Array<string> = ['NON_DEFINI','BIODIESEL','DIESEL','ELECTRIQUE_HYDROGENE','ESSENCE','GAZ','HYBRIDE_RECHARGEABLE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Suppression du carburant
	 */
	public deleteCarburant(carburant: any): Observable<Result> {
		//Suppression du carburant
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteCarburant/${carburant.idCarburant}`);
	}

	/**
	 * Enregistrement du carburant
	 */
	public saveCarburant(carburant: any): Observable<Result> {
		//Enregistrement du carburant
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveCarburant`,carburant);
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(type: 'TOUS' | 'HORS_CATALOGUE' | 'CATALOGUE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadDashboardCarburant/${type}`,searchSpec);
	}

	/**
	 * Récupération de la liste des types d'énergie
	 */
	public getListeTypesEnergie(): Array<{ code: string,libelle: string }> {
		//Liste des types d'énergie
		return this.listeTypesEnergie.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.carburant.typeEnergie.' + code)
		}));
	}
}