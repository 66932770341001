import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { ModeleCategorisationComponent } from './content/modele-categorisation.component';
import { ModeleCommercialisationPrixComponent } from './content/modele-commercialisation-prix.component';
import { ModeleConfortComponent } from './content/modele-confort.component';
import { ModeleDimensionComponent } from './content/modele-dimension.component';
import { ModeleInformationComponent } from './content/modele-information.component';
import { ModeleMotorisationComponent } from './content/modele-motorisation.component';
import { ModelePerformanceComponent } from './content/modele-performance.component';
import { ModelePneumatiqueComponent } from './content/modele-pneumatique.component';
import { ModeleContentComponent } from './modele-content.component';
import { ModeleDetailsComponent } from './modele-details.component';
import { ModeleListItemComponent } from './modele-list-item.component';
import { ModeleListComponent } from './modele-list.component';
import { ModeleListItemContentComponent } from './modele-list-item-content.component';
import { ModeleService } from './modele.service';
import { OptionCategorieModule } from './option-categorie/option-categorie.module';
import { OptionModule } from './option/option.module';
import { ModeleComponent } from './modele.component';
import { ModeleOptionListComponent } from './modele-option-list.component';
import { ModeleOptionListItemComponent } from './modele-option-list-item.component';
import { ModeleCaracteristiqueComponent } from './modele-caracteristique.component';
import { ModeleOptionComponent } from './modele-option.component';
import { ModeleOptionContrainteListComponent } from './modele-option-contrainte-list.component';
import { ModeleOptionContrainteListItemComponent } from './modele-option-contrainte-list-item.component';
import { ModeleOptionPackListComponent } from './modele-option-pack-list.component';
import { ModeleOptionPackListItemComponent } from './modele-option-pack-list-item.component';

@NgModule({
	imports: [ShareModule,OptionCategorieModule,ConfigurationModule,OptionModule],
	exports: [OptionCategorieModule,ConfigurationModule,OptionModule],
	declarations: [ModeleListComponent,ModeleListItemComponent,ModeleListItemContentComponent,ModeleContentComponent,ModelePneumatiqueComponent,ModeleDetailsComponent,ModeleDimensionComponent,ModeleInformationComponent,ModeleCategorisationComponent,ModeleMotorisationComponent,ModeleConfortComponent,ModeleCommercialisationPrixComponent,
		ModelePerformanceComponent,ModeleComponent,ModeleOptionListComponent,ModeleOptionListItemComponent,ModeleCaracteristiqueComponent,ModeleOptionComponent,ModeleOptionContrainteListComponent,ModeleOptionContrainteListItemComponent,ModeleOptionPackListComponent,ModeleOptionPackListItemComponent],
	providers: [ModeleService]
})
export class ModeleModule { }