/**
 * Types de filtre
 */
export enum TypeFilter {
	/** Enumération **/
	STRING = 'STRING',
	BOOLEAN = 'BOOLEAN',
	LONG = 'LONG',
	DATE = 'DATE',
	MONTH = 'MONTH',
	DECIMAL = 'DECIMAL',
	AUTOCOMPLETE = 'AUTOCOMPLETE',
	PERIOD = 'PERIOD'
}