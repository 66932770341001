<form #releveCompteurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>equipement.elements.releveCompteur.title</h2>
		</div>
		<div class="card-body card-padding">
			<releve-compteur-content #releveCompteurContent source="EQUIPEMENT" [releveCompteur]="releveCompteur" [user]="user" (close)="close({ releveCompteur: $event })"></releve-compteur-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteReleveCompteur(releveCompteur,close);" *ngIf="deleteReleveCompteur && releveCompteur?.idCompteur && (TypeDroit.VEHICULE_COMPTEUR | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="releveCompteurContent.saveReleveCompteur();" *ngIf="TypeDroit.VEHICULE_COMPTEUR | right:'creation'" [disabled]="releveCompteurForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: releveCompteurForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>