<form #facturationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>fournisseur.facturation.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.FACTURE | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
							<label translate>fournisseur.facturation.societe</label>
							<div>
								<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="lienFacturation.societe" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': code.invalid }">
							<label translate>fournisseur.facturation.code.item</label>
							<div class="half">
								<div class="input-group">
									<input type="text" name="code" #code="ngModel" class="form-control" [(ngModel)]="lienFacturation.code" maxlength="100" required/>
									<div class="input-group-addon">
										<icon name="info_outline" [tooltip]="'fournisseur.facturation.code.info' | translate"></icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteLienFacturation(lienFacturation,close);" *ngIf="lienFacturation?.idLien && (TypeDroit.FACTURE | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienFacturation();" *ngIf="TypeDroit.FACTURE | right:'creation'" [disabled]="facturationForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: facturationForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>