import { Component,HostBinding,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { first,take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { ListView } from 'src/app/domain/common/list-view';
import { FileUploader,Filter } from 'src/app/share/directive/file-uploader/file-uploader';
import { environment } from 'src/environments/environment';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { AttachmentService } from './attachment.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ImageEditorService } from 'src/app/share/components/image-editor/image-editor.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeAttachmentContext } from 'src/app/domain/attachment/type-attachment';

@Component({
	selector: 'attachment',
	exportAs: 'attachment',
	templateUrl: './attachment.component.html'
})
export class AttachmentComponent implements OnInit {
	/** Options du composant **/
	@Input() options: AttachmentOptions;

	/** Ajout de la classe 'attachment' à l'élément hôte **/
	@HostBinding('class.attachment') attachmentClass = true;

	/** Upload de fichiers **/
	fileUploader: FileUploader = <FileUploader>{};

	/** Mode d'affichage **/
	modeAffichage: 'grid' | 'liste';

	/** Liste des pièces jointes **/
	liste: ListView<any,any>;

	/** Liste des filtres sur les fichiers uploadés **/
	private listeFilters: Array<Filter>;

	/** Contextes des types d'attachment **/
	public TypeAttachmentContext: typeof TypeAttachmentContext = TypeAttachmentContext;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,private bsModalRef: BsModalRef<AttachmentComponent>
			,private attachmentService: AttachmentService,private confirmService: ConfirmService,private imageEditorService: ImageEditorService
			,private cookieService: CookieService,private rightService: RightService) {
		//Récupération du mode d'affichage
		this.modeAffichage = (cookieService.get('attachment.modeAffichage') as 'liste' | 'grid') || 'grid';

		//Définition des contrôles sur les fichiers uploadés
		this.listeFilters = [{
			name: 'extension',
			apply: (file) => {
				//Contrôle de l'extension
				return new RegExp('('+this.options.filters.replace(/\./g,'\\.')+')$').test(file.name.toLowerCase());
			}
		},{
			name: 'maxSize',
			apply: (file) => {
				//Contrôle de la taille du fichier uploadé (15 Mo)
				return file.size <= 15728640;
			}
		},{
			name: 'characterRestriction',
			apply: (file) => {
				//Contrôle du nom de fichier
				return !(new RegExp('['+this.options.characterRestrictions.replace(/\|/g,'').replace(/\\/g,'\\')+']').test(file.name));
			}
		},{
			name: 'queueLimit',
			apply: () => {
				//Vérification du nombre d'éléments
				return typeof this.options.queueLimit != 'number' || (((this.liste?.data?.content?.length || 0) + (this.fileUploader?.queue?.length || 0)) < this.options.queueLimit);
			}
		}];
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification des extensions
		this.options.filters = this.options.filters || '.csv|.jpg|.jpeg|.pdf|.png|.txt|.xls|.xlsx|.doc|.docx';

		//Vérification des caractères autorisés
		this.options.characterRestrictions = this.options.characterRestrictions || '\\|"|;|=';

		//Définition de l'upload de fichiers
		this.fileUploader = {
			url: this.options.getUrl?.() || `${environment.baseUrl}/controller/Blob/saveBlob/${this.options.typeAttachment}${this.options.public ? '?isPublic=true' : ''}`,
			onItemUploaded: this.onItemUploaded.bind(this),
			autoRemove: true,
			filters: this.listeFilters,
			onItemAdded: () => {
				//Upload du fichier en automatique
				this.options?.autoUpload && this.uploadItem(this.fileUploader.queue.length - 1);
			},
			onWhenAddingFileFailed: (file,filter) => {
				//Vérification du filtre
				if (filter?.name == 'extension')
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('attachment.errors.extensionNotAuthorized'));
				else if (filter?.name == 'queueLimit')
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('attachment.errors.queueLimit'));
				else if (filter?.name == 'maxSize')
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('attachment.errors.maxSize'));
				else if (filter?.name == 'characterRestriction')
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('attachment.errors.characterNotAuthorized'));
			}
		};

		//Définition de la liste
		this.liste = new ListView<any,any>({
			uri: () => {
				let url: string;

				//Définition de l'URL
				url = `/controller/Attachment/filtreAttachments/${this.options.typeAttachment}/${this.options.idObjet || 0}`;

				//Vérification du filtrage sur le type de lien
				if (this.options.typeLinkClass && this.options.typeLink)
					//Ajout du filtre sur le type de lien
					url = `${url}?typeLinkClass=${this.options.typeLinkClass}&typeLink=${this.options.typeLink}`;

				return url;
			},
			component: null,
			isLocal: true,
			isContentHidden: true,
			defaultOrder: '-dateUpdate',
			listeFilters: [{
				clef: 'name',
				isDefault: true
			}],
			onRefresh: (liste: ListView<any,any>) => {
				//Vérification de la présence d'attachments non enregistrés
				if (this.options.owningEntity.listeLinks?.some(l => l.attachment && !l.attachment.idAttachment)) {
					//Ajout des attachments non enregistrés
					this.options.owningEntity.listeLinks.filter(l => l.attachment && !l.attachment.idAttachment).forEach(l => {
						//Ajout de l'attachment courant
						liste.data.content.push(l.attachment);
					});
				}
			}
		});
	}

	/**
	 * Finalisation de l'upload d'un fichier
	 */
	onItemUploaded(result,file) {
		//Gestion de la finalisation d'un upload de fichier
		this.attachmentService.onItemUploaded(this.liste,this.options,result,file);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de la popup
		this.bsModalRef.hide();
	}

	/**
	 * Sélection du mode d'affichage
	 */
	selectModeAffichage(modeAffichage: 'grid' | 'liste') {
		//Mise à jour du mode d'affichage
		this.modeAffichage = modeAffichage;

		//Stockage en cookie
		this.cookieService.set('attachment.modeAffichage',modeAffichage);
	}

	/**
	 * Ajout de fichiers
	 */
	onFilesDrop(files: Array<any>) {
		let hasError: boolean = false;

		//Vérification des droits
		if (this.rightService.hasAnyRight(this.options.listeTypesDroit || [null],'consultation') && !this.options.readOnly) {
			//Parcours des fichiers sélectionnés
			for (const file of files) {
				//Vérification du type de fichier
				if (file instanceof File) {
					//Vérification de la queue
					this.fileUploader.queue = this.fileUploader.queue || [];

					//Exécution des filtres sur le fichier uploadé
					for (let filter of this.listeFilters) {
						//Vérification de la condition
						if (!filter.apply(file)) {
							//Mémorisation de la présence d'une erreur de validation
							hasError = true;

							//Vérification du filtre
							if (filter?.name == 'extension')
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('attachment.errors.extensionNotAuthorized'));
							else if (filter?.name == 'queueLimit')
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('attachment.errors.queueLimit'));
							else if (filter?.name == 'maxSize')
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('attachment.errors.maxSize'));
							else if (filter?.name == 'characterRestriction')
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('attachment.errors.characterNotAuthorized'));

							//Arrêt des contrôles
							break;
						}
					}

					//Vérification de l'absence d'erreur
					if (!hasError) {
						//Ajout du fichier à la queue
						this.fileUploader.queue.push(file);

						//Déclenchement de l'intercepteur de fichier ajouté
						this.fileUploader.onItemAdded && this.fileUploader.onItemAdded(file);
					}
				}
			}
		}
	}

	/**
	 * Récupération du libellé de l'alerte
	 */
	getBlobAlertTitle(isUnsafe: boolean,fileName: string = '') {
		//Récupération du libellé
		return isUnsafe ? this.translateService.instant('attachment.unsafeTrue',{ fileName }) : this.translateService.instant('attachment.unsafeNull',{ fileName });
	}

	/**
	 * Vérification de la présence d'une image
	 */
	isFileImage(filename: string) {
		//Vérification de la présence d'une image
		return this.attachmentService.isFileImage(filename);
	}

	/**
	 * Upload d'un fichier
	 */
	uploadItem(idxFile: number) {
		//Upload du fichier
		forkJoin(this.fileUploader.uploadItem(idxFile)).pipe(first()).subscribe();
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	public uploadAll() {
		//Upload du fichier
		forkJoin(this.fileUploader.uploadAll()).pipe(first()).subscribe({
			complete: () => {
				//Réinitialisation de la queue
				this.fileUploader.queue = [];

				//Finalisation de tous les uploads
				this.options.onAllItemsCompleted?.();
			}
		});
	}

	/**
	 * Suppression d'une pièce jointe
	 */
	deleteAttachment(attachment: any,idxAttachment: number) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				let idxAttachmentFromEntity: number;

				//Vérification de la confirmation
				if (isConfirmed) {
					//Vérification de l'identifiant de la pièce jointe
					if (attachment?.idAttachment) {
						//Suppression de la pièce jointe
						this.attachmentService.deleteAttachment(attachment).pipe(take(1)).subscribe({
							next: result => {
								let deletedLink: any;

								//Vérification du code d'erreur
								if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
									//Message d'information
									this.toastrService.success(this.translateService.instant('actions.suppression.success'));

									//Suppression de l'élément dans la liste
									this.liste?.data?.content.splice(idxAttachment,1);

									//Récupération de la position de l'élément dans l'entité parente
									idxAttachmentFromEntity = this.options.owningEntity.listeLinks?.findIndex(l => l.attachment && l.attachment.idAttachment == attachment.idAttachment);

									//Vérification de la position
									if (idxAttachmentFromEntity != -1)
										//Suppression du lien dans l'entité parente
										deletedLink = this.options.owningEntity.listeLinks.splice(idxAttachmentFromEntity,1)?.[0];

									//Vérification de la nécessité de mettre à jour le compteur de pièces jointes du formulaire complexe
									if (!this.options.isFromLink || this.options.parentOwningEntity)
										//Mise à jour du compteur de pièces jointes
										this.attachmentService.incrementAttachments(-1,!this.options.parentOwningEntity ? attachment.type : TypeAttachmentContext[attachment.type].mainType);

									//Finalisation de l'élément
									this.options.onDeleteItem?.(deletedLink);
								} else
									//Affichage d'un message d'erreur
									this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						});
					} else {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.suppression.success'));

						//Suppression de l'élément dans la liste
						this.liste.data.content.splice(idxAttachment,1);

						//Récupération de la position de l'élément dans l'entité parente
						idxAttachmentFromEntity = this.options.owningEntity.listeLinks?.findIndex(l => l.attachment && l.attachment === attachment);

						//Vérification de la position
						if (idxAttachmentFromEntity != -1)
							//Suppression du lien dans l'entité parente
							this.options.owningEntity.listeLinks.splice(idxAttachmentFromEntity,1);
					}
				}
			}
		});
	}

	/**
	 * Retrait d'une pièce jointe non persistée de la liste
	 */
	removeLink(link: any,idxLink: number) {
		//Retrait de la pièce jointe de la liste
		this.options.owningEntity.listeLinks.splice(idxLink,1);

		//Vérification de la nécessité de mettre à jour le compteur de pièces jointes du formulaire complexe
		if (!this.options.isFromLink || this.options.parentOwningEntity)
			//Mise à jour du compteur de pièces jointes
			this.attachmentService.incrementAttachments(-1,!this.options.parentOwningEntity ? link.attachment.type : TypeAttachmentContext[link.attachment.type].mainType);
	}

	/**
	 * Téléchargement d'une pièce jointe
	 */
	downloadAttachment(attachment: any) {
		//Téléchargement de la pièce jointe
		this.attachmentService.downloadBlob(attachment.idBlob).pipe(take(1)).subscribe({
			next: response => {
				//Téléchargement du contenu
				this.attachmentService.downloadAttachment(response,null,attachment.contentType,attachment.name);
			}
		});
	}

	/**
	 * Edition de l'image
	 */
	editImage(item: File | any,index: number = null) {
		//Visualisation de l'image
		this.imageEditorService.show(item instanceof File ? item : `${environment.baseUrl}/controller/Blob/readBlob/${item.idBlob}`,{ isConsultation: !(item instanceof File) }).subscribe({
			next: (file: File) => {
				//Vérification de la présence d'un fichier
				if (file && index != null) {
					//Modification de l'image
					this.fileUploader.queue.splice(index,1,file);

					//Déclenchement de l'intercepteur de fichier ajouté
					this.fileUploader.onItemAdded && this.fileUploader.onItemAdded(file);
				}
			}
		});
	}

	/**
	 * Vérification que des pièces jointes sont sélectionnées
	 */
	hasSelectedAttachments(): boolean {
		//Retour de la présence d'une pièce jointe sélectionnée
		return (this.liste?.data?.content || []).some(a => a.isSelected);
	}

	/**
	 * Validation de la sélection des pièces jointes
	 */
	selectAttachments() {
		//Validation de la sélection des pièces jointes
		this.options.onAttachmentsSelected((this.liste?.data?.content || []).filter(a => a.isSelected));

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Récupération des liens associés à un type
	 */
	getListeLinksForType(): Array<any> {
		//Retour des liens associés au type
		return this.options.owningEntity?.listeLinks?.filter(l => !this.options.typeLink || l.type == this.options.typeLink) || [];
	}

	/**
	 * Mise à jour de la visiblité de la pièce jointe
	 */
	updateVisibility(attachment: any,index: number) {
		//Mise à jour de la visibilité de la pièce jointe
		this.attachmentService.updateVisibility(attachment).pipe(first()).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'attachment
					this.liste.data.content[index] = result.data.attachment;
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}