<div class="pull-left lv-avatar">
	<icon [name]="statut.icone" [type]="statut.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<div class="lv-title"><a [routerLink]="[]" (click)="showEtatLieux();"><span translate>vehicule.etatLieux.titre</span>&#160;-&#160;{{ data.vehicule | display:'vehicule' }}</a></div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idEtatLieux > 0">
		<li><span translate>vehicule.etatLieux.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.etatLieux.statut.'+data.statut"></span></li>
		<li><span [translate]="'vehicule.etatLieux.'+(data.dateRealisation ? 'realise' : 'prevu')"></span>&#160;:&#160;<span [translate]="(data.dateRealisation || data.datePlanification) | date:'short'"></span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.ETAT_LIEUX | right:'consultation') && data.statut == 'TERMINE'">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="generateDocument();"><span translate>actions.genererDocument</span></a></li>
		</ul>
	</div>
</div>