import { Component,Input,OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';

@Component({
	selector: 'configuration-recapitulatif',
	templateUrl: './configuration-recapitulatif.component.html'
})
export class ConfigurationRecapitulatifComponent implements OnInit {
	/** Configuration **/
	@Input() configuration: any;

	/** Récapitulatif **/
	public recapitulatif: { isPrixToggled: boolean,isListeOptionsToggled: boolean } = {
		isPrixToggled: false,
		isListeOptionsToggled: false
	};

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la liste des détails sélectionnés
		this.configuration.listeDetails = orderBy(this.configuration?.listeDetails?.filter(detail => detail?.selected),['option.libelle'],['asc']);
	}

	/**
	 * Récupération du prix net de la configuration
	 */
	public getPrixNet(): number {
		//Vérification du mode d'affichage des prix
		if (this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT')
			//Affichage du prix net HT
			return this.configuration?.prixHT - this.getMontantRemise();
		else
			//Affichage du prix net TTC
			return this.configuration?.prixTTC - this.getMontantRemise();
	}

	/**
	 * Récupération du montant de la remise
	 */
	public getMontantRemise(): number {
		let montantRemise: number = 0;
		let prixTTCRemise: number = 0;
		let prixHTRemise: number = 0;

		//Vérification de la valeur d'un pourcentage de remise
		if (this.configuration?.pourcentageRemise) {
			//Vérification du mode HT/TTC
			if (this.configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT') {
				//Calcul de la remise sur le prix total HT
				montantRemise = this.configuration?.prixHT * this.configuration?.pourcentageRemise / 100;
			} else {
				//Calcul du prix HT remisé
				prixHTRemise = this.configuration?.prixHT - (this.configuration?.prixHT * this.configuration?.pourcentageRemise / 100);

				//Calcul du prix TTC remisé (avec taxe à 20%)
				prixTTCRemise = prixHTRemise + (prixHTRemise * 0.2);

				//Calcul de la remise exprimée en TTC
				montantRemise = this.configuration?.prixTTC - prixTTCRemise;
			}
		}

		return montantRemise;
	}
}
