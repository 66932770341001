import { TranslateService } from '@ngx-translate/core';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des marques de véhicule
 */
export default class MarqueOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.marque';

	/**
	 * URL
	 */
	url(filter: { typeSource: null | 'LOCAL' | 'EXTERNE_CATALOGUE' | 'EXTERNE_IMMATRICULATION' } = { typeSource: 'EXTERNE_IMMATRICULATION' }): string {
		//Retour de l'URL
		return `/controller/VehiculeReferentiel/listeMarquesActives${filter?.typeSource ? '/' + filter?.typeSource : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item?.libelle} (${item?.reference} - ${translateService.instant('referentiel.typeReferentiel.'+item?.typeSource)})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.VehiculeMarque';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idMarque'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'vehicule.marque.typeSource.item',
			getValue: (item: any,translateService: TranslateService) => item?.typeSource ? translateService.instant('referentiel.typeReferentiel.'+item?.typeSource) : translateService.instant('common.nonDefini')
		}];
	}
}