import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { CompteService } from './compte.service';
import { PlanComptableService } from 'src/app/components/comptabilite/plan-comptable/plan-comptable.service';

@Component({
	selector: 'compte-add',
	templateUrl: './compte-add.component.html'
})
export class CompteAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Compte collectif **/
	public compte: any = {};

	/** Liste des types de compte **/
	public listeTypesCompte: Array<{ code: string,libelle: string }> = this.compteService.getListeTypesCompte();

	/** Filtres de l'autocomplete **/
	public filter: { idPlanComptable: number,isShowPlanComptable: boolean,listeTypes: Array<string> };

	/** Taille de la popup **/
	public static modalSize: string = 'modal-xs';

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private planComptableService: PlanComptableService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<CompteAddComponent>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification du plan comptable
		if (this.filter.idPlanComptable) {
			//Chargement du plan comptable
			this.planComptableService.loadPlanComptable(this.filter.idPlanComptable).subscribe(result => {
				//Vérification du résultat
				if (result?.data) {
					//Mise à jour du plan comptable
					this.compte.planComptable = result.data.planComptable;

					//Mise à jour du pays
					this.compte.pays = result.data.planComptable.pays;
				}
			});
		}

		//Vérification du type du compte
		if (this.filter.listeTypes?.length == 1)
			//Définition du type du compte
			this.compte.type = this.filter.listeTypes[0];
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de l'élément
		this.compteService.saveCompte(this.compte).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Définition du compte collectif
				this.addedItem = result.data.compte;

				//Fermeture de la popup
				this.bsModalRef.hide();
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.numero')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
