import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { ExtractionService } from './extraction.service';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule],
	providers: [ExtractionService]
})
export class ExtractionModule {}