import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'extraction-edit',
	templateUrl: './extraction-edit.component.html'
})
export class ExtractionEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() extraction: any;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Suppression de l'objet **/
	@Input() deleteExtraction: () => void;

	/** Liste des entités **/
	public listeEntities: Array<any> = [];

	/** Liste des types de diffusion **/
	public listeTypeDiffusions: Array<any> = [];

	/** Liste des types de format **/
	public listeTypeFormats: Array<any> = [];

	/** Type d'attachment **/
	readonly TypeAttachment = TypeAttachment;

	/** Enumération des type de référentiel **/
	readonly TypeReferentiel = TypeReferentiel;

	/** Copie de l'extraction **/
	private savedExtraction: any;

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private extractionService: ExtractionService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie de l'extraction
		this.savedExtraction = cloneDeep(this.extraction);

		//Chargement des entités
		this.extractionService.getListeEntites().subscribe({
			next: listeEntities => {
				//Définition de la liste des entités
				this.listeEntities = listeEntities;
			}
		});

		//Définition des types de diffusion
		this.listeTypeDiffusions = this.extractionService.getListeTypesDiffusion();

		//Définition des types de format
		this.listeTypeFormats = this.extractionService.getListeTypesFormat();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveExtraction() {
		//Vérification de la dépersonnalisation de l'extraction
		this.checkUncustomizationBeforeSave().subscribe({
			next: isReset => {
				//Vérification de la réinitialisation de l'extraction
				if (isReset) {
					//Enregistrement de l'extraction
					this.doSaveExtraction(false);
				} else {
					//Vérification du changement d'entité
					this.checkEntiteBeforeSave().subscribe({
						next: isChanged => {
							//Enregistrement de l'extraction
							this.doSaveExtraction(isChanged);
						}
					});
				}
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	private doSaveExtraction(hasEntiteChanged: boolean) {
		//Enregistrement de l'extraction
		this.extractionService.saveExtraction(this.extraction).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'extraction
					this.extraction = Object.assign(this.extraction,result.data?.extraction);

					//Fermeture du formulaire
					this.close(this.extraction);
				} else {
					//Vérification du changement d'entité
					if (hasEntiteChanged) {
						//Redéfinition des colonnes et des filtres
						this.extraction.listeFields = this.savedExtraction.listeFields;
						this.extraction.rule = this.savedExtraction.rule;
					}

					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du reference
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}

	/**
	 * Vérification du changement de l'entité
	 */
	private checkEntiteBeforeSave(): Observable<boolean> {
		//Vérification de la modification de l'entité
		if (this.extraction.idExtraction && this.extraction.entity != this.savedExtraction?.entity) {
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('extraction.confirmation.changementEntite'),{ actionColor: 'primary' }).pipe(
				map((isConfirmed: boolean) => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Suppression des champs et de la règle
						this.extraction.listeFields = null;
						this.extraction.rule = null;

						//Retour de la vérification
						return true;
					} else
						//Réinitialisation de l'entité
						this.extraction.entity = this.savedExtraction.entity;

					//Retour de la vérification
					return false;
				}))
		} else
			//Retour d'un observable
			return of(false);
	}

	/**
	 * Vérification du changement sur la personnalisation
	 */
	private checkUncustomizationBeforeSave(): Observable<boolean> {
		//Vérification de la modification de la personnalisation
		if (this.savedExtraction.custom && !this.extraction.custom) {
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('extraction.confirmation.customization'),{ actionColor: 'primary' });
		} else
			//Retour d'un observable
			return of(false);
	}

	/**
	 * Changement de statut de la personnalisation
	 */
	public onCustomChange(isCustom: boolean) {
		//Vérification de statut de la personnalisation
		if (!isCustom)
			//Réinitialisation de l'extraction
			this.extraction = cloneDeep(this.savedExtraction);
	}

	/**
	 * Changement du format de sortie
	 */
	public onTypeFormatChange(typeFormat: 'XLSX' | 'CSV') {
		//Vérification du format de sortie
		if (typeFormat != 'XLSX')
			//Mise à jour de l'indicateur d'extraction avancée
			this.extraction.avancee = false;
	}
}