import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { PatternModule } from 'src/app/share/directive/pattern/pattern.module';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { AdresseComponent } from './adresse.component';
import { AdresseService } from './adresse.service';
import { AdresseAddComponent } from './adresse-add.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,AutocompleteModule,PatternModule,RightModule,IconModule],
	declarations: [AdresseComponent,AdresseAddComponent],
	providers: [AdresseService],
	exports: [AdresseComponent]
})
export class AdresseModule { }