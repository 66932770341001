import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurDemandeVehiculeListItemComponent } from './conducteur-demande-vehicule-list-item.component';

@Component({
	selector: 'conducteur-demande-vehicule-list',
	templateUrl: './conducteur-demande-vehicule-list.component.html'
})
export class ConducteurDemandeVehiculeListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Résumé **/
	@Input() resume: { nbDemandesVehicule: number };

	/** Données **/
	liste: ListView<any,ConducteurDemandeVehiculeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConducteurDemandeVehiculeListItemComponent>({
			uri: `/controller/VehiculeCommande/filtreDemandesVehicule`,
			component: ConducteurDemandeVehiculeListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'vehicule',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					fieldForDefault: '*reference'
				}
			},{
				clef: 'vehiculeSource',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					fieldForDefault: '*reference'
				}
			},{
				clef: 'referenceCommande',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'user.idUser',
				type: TypeFilter.LONG,
				valeur: this.conducteur.user.idUser
			}],
			defaultOrder: 'statut,-dateCreation'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idDemandeVehicule > 0) {
			//Mise à jour des compteurs
			this.resume.nbDemandesVehicule -= 1;

			//Rafraichissement de la liste des demandes de vehicules
			this.liste.refresh();
		}
	}
}