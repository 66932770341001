<form #contratFinancementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.elements.contratFinancement.title</h2>
		</div>
		<div class="card-body card-padding">
			<contrat-financement-content #contratFinancementContent source="VEHICULE" [contratFinancement]="contratFinancement" [getDateFin]="getDateFin" source="VEHICULE" (close)="close({ contratFinancement: $event?.contratFinancement })"></contrat-financement-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-button [formDetectChanges]="{ doAction: goToContratFinancement,isDirty: contratFinancementForm.dirty }" *ngIf="contratFinancement.idContrat">
			<span translate>vehicule.actions.accederContratFinancement</span>
		</button>
		<button mat-stroked-button color="primary" (click)="deleteContratFinancement(contratFinancement,close);" *ngIf="deleteContratFinancement && contratFinancement?.idContrat && (TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="contratFinancementContent.saveContratFinancement();" *ngIf="TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'creation'" [disabled]="contratFinancementForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: contratFinancementForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>