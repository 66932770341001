import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { NotificationTemplateListItemComponent } from './notification-template-list-item.component';

@Component({
	templateUrl: './notification-template-list.component.html'
})
export class NotificationTemplateListComponent implements OnInit {
	/** Liste des templates de mails **/
	liste: ListView<any,NotificationTemplateListItemComponent>;

	/** Entité courante **/
	@Input() entite: string;

	/** Template sélectionné **/
	@Input() selectedMail: any;

	/** Identifiant de l'objet pour la prévisualisation **/
	@Input() idObject: number;

	/** Sélection d'un template de mail **/
	onTemplateSelected: (templateMail: any) => void;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,NotificationTemplateListItemComponent>({
			uri: `/controller/Notification/filtreMailsFor?entite=${this.entite}`,
			component: NotificationTemplateListItemComponent,
			isLocal: true,
			extraOptions: {
				idObject: this.idObject,
				onTemplateSelected: this.onTemplateSelected
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}]
		});
	}
}