import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './upgrade-list-item.component.html'
})
export class UpgradeListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idVersion,object?.idVersion && !this.data?.idVersion);
	}
}