import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { switchMap,tap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratCRMService } from './contrat-crm.service';

@Component({
	selector: 'contrat-crm-edit',
	templateUrl: './contrat-crm-edit.component.html'
})
export class ContratCRMEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() contratCRM: any;

	/** Suppression de l'objet **/
	@Input() deleteContratCRM: () => void;

	/** Indicateur sur l'utilisation du contrat CRM **/
	@Input() isUsed: boolean = false;

	/** Fonction de résiliation **/
	@Input() showResiliation: Function;

	/** Indicateur de résiliation possible **/
	@Input() canDoResiliation: boolean;

	/** Copie du contrat CRM **/
	private savedContratCRM: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private contratCRMService: ContratCRMService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Copie du contrat CRM
		this.savedContratCRM = cloneDeep(this.contratCRM);
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveContratCRM() {
		//Vérification d'un changement de revendeur (si nécessaire)
		(!this.contratCRM?.idContrat || isEqual(this.savedContratCRM.revendeur,this.contratCRM.revendeur) ? of(true) : this.confirmService.showConfirm(this.translateService.instant('contratCRM.confirmation.changementRevendeur'),{ actionColor: 'primary' }).pipe(
			tap((isConfirmed: boolean) => {
				//Vérification de la confirmation
				if (isConfirmed)
					//Suppression des offres
					this.contratCRM.listeOffres = null;
				else
					//Conservation du revendeur
					this.contratCRM.revendeur = this.savedContratCRM.revendeur;
			})
		)).pipe(switchMap(() => this.contratCRMService.saveContratCRM(this.contratCRM))).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.contratCRM = Object.assign(this.contratCRM,result.data?.contratCRM);

					//Fermeture du formulaire
					this.close(this.contratCRM);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,0))
							//Ajout de la référence
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}