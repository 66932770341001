import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { filter,first,map,tap } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { environment } from 'src/environments/environment';
import { StructureExportLineSelectionComponent } from './structure-export-line-selection.component';
import { StructureExportRubriqueComponent } from './structure-export-rubrique.component';
import { StructureExportRubriqueTypeSelectionComponent } from './structure-export-rubrique-type-selection.component';
import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

@Injectable()
export class StructureExportService {
	/** Liste des types de cadrage **/
	private readonly listeTypesCadrage: Array<string> = ['AUCUN','GAUCHE','DROITE'];

	/** Liste des types de complétion **/
	private readonly listeTypesCompletion: Array<string> = ['ZERO','ESPACE'];

	/** Liste des types de format du fichier **/
	private readonly listeTypesFormat: Array<string> = ['CSV','FIXE'];

	/** Liste des types d'échappement du fichier **/
	private readonly listeTypeEchappement: Array<string> = ['DEFAUT','AUCUN'];

	/** Liste des types d'encodage du fichier **/
	private readonly listeTypesEncodage: Array<string> = ['ANSI','UTF8'];

	/** Liste des types de compteur **/
	private readonly listeTypesCompteur: Array<string> = ['AUTOMATIQUE','CONDITIONNEL'];

	/** Liste des types de détail **/
	private readonly listeTypesDetail: Array<string> = ['FICHIER','GROUPE','COMPTABILITE_GENERALE'];

	/** Liste des types de ligne **/
	private readonly listeTypesLigne: Array<{ code: string,typeDetail: string }> = [{
		code: 'FICHIER_ENTETE',
		typeDetail: 'FICHIER'
	},{
		code: 'FICHIER_PIED',
		typeDetail: 'FICHIER'
	},{
		code: 'GROUPE',
		typeDetail: 'GROUPE'
	},{
		code: 'GROUPE_ENTETE',
		typeDetail: 'GROUPE'
	},{
		code: 'GROUPE_PIED',
		typeDetail: 'GROUPE'
	},{
		code: 'TIERS_ACHAT',
		typeDetail: 'COMPTABILITE_GENERALE'
	},{
		code: 'CHARGE',
		typeDetail: 'COMPTABILITE_GENERALE'
	},{
		code: 'TAXE',
		typeDetail: 'COMPTABILITE_GENERALE'
	},{
		code: 'ANALYTIQUE',
		typeDetail: 'COMPTABILITE_GENERALE'
	},{
		code: 'TIERS_PAIEMENT',
		typeDetail: 'COMPTABILITE_GENERALE'
	},{
		code: 'BANQUE',
		typeDetail: 'COMPTABILITE_GENERALE'
	}];

	/** Liste des types de critères **/
	private readonly listeTypesRubrique: Array<{ code: string,listeGroupes?: Array<{ code: string,listeItems: Array<{ code: string,subCode?: string,_type?: string,tooltip?: boolean }> }> }> = [{
		code: 'COMPTEUR'
	},{
		code: 'CONSTANTE',
		listeGroupes: [{
			code: 'CONSTANTE',
			listeItems: [{
				code: 'ALPHANUMERIQUE',
				subCode: 'CONSTANTE'
			},{
				code: 'NUMERIQUE',
				subCode: 'CONSTANTE'
			},{
				code: 'DATE',
				subCode: 'CONSTANTE'
			},{
				code: 'VIDE',
				subCode: 'CONSTANTE'
			}]
		},{
			code: 'VARIABLE',
			listeItems: [{
				code: 'DATE_GENERATION',
				subCode: 'VARIABLE'
			},{
				code: 'NOM_FICHIER',
				subCode: 'VARIABLE'
			}]
		}]
	},{
		code: 'BUSINESS_DATA'
	},{
		code: 'FONCTION',
		listeGroupes: [{
			code: 'LOGIQUE',
			listeItems : [{
				code: 'SI',
				tooltip: true,
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionSi'
			}]
		},{
			code: 'TEXTE',
			listeItems: [{
				code: 'CONCATENER',
				tooltip: true,
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionConcatener'
			},{
				code: 'EXTRAIRE',
				tooltip: true,
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionExtraire'
			},{
				code: 'REMPLACER',
				tooltip: true,
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionRemplacer'
			}]
		},{
			code: 'MATH',
			listeItems: [{
				code: 'ADDITIONNER',
				subCode: 'MATH',
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionMath'
			},{
				code: 'SOUSTRAIRE',
				subCode: 'MATH',
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionMath'
			},{
				code: 'DIVISER',
				subCode: 'MATH',
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionMath'
			},{
				code: 'MULTIPLIER',
				subCode: 'MATH',
				_type: 'com.notilus.data.export.fonction.StructureExportFonctionMath'
			}]
		}]
	},{
		code: 'MEMOIRE'
	}];

	/** Liste des opérateurs **/
	private readonly listeOperateurs: Array<{ code: string,listeTypes?: Array<string> }> = [{
		code: 'EQUAL'
	},{
		code: 'NOT_EQUAL'
	},{
		code: 'GREATER',
		listeTypes: ['java.lang.Double','NUMERIQUE','java.util.Date','DATE']
	},{
		code: 'GREATER_OR_EMPTY',
		listeTypes: ['java.util.Date','DATE']
	},{
		code: 'LESSER',
		listeTypes: ['java.lang.Double','NUMERIQUE','java.util.Date','DATE']
	},{
		code: 'LESSER_OR_EMPTY',
		listeTypes: ['java.util.Date','DATE']
	},{
		code: 'CONTAINS',
		listeTypes: ['ALPHANUMERIQUE','java.lang.String']
	},{
		code: 'NOT_CONTAINS',
		listeTypes: ['ALPHANUMERIQUE','java.lang.String']
	},{
		code: 'STARTS_WITH',
		listeTypes: ['ALPHANUMERIQUE','java.lang.String']
	},{
		code: 'NOT_STARTS_WITH',
		listeTypes: ['ALPHANUMERIQUE','java.lang.String']
	},{
		code: 'IS_NULL'
	},{
		code: 'IS_NOT_NULL'
	}];

	/** Liste des types d'extraction **/
	private readonly listeTypesExtraction: Array<string> = ['NON_DEFINI','ANNEE','MOIS','JOUR','HEURE','MINUTE','SECONDE','DERNIER_JOUR_MOIS','NUMERO_SEMAINE','NUMERO_JOUR'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService,private businessDataService: BusinessDataService,private messagingService: MessagingService) {}

	/**
	 * Récupération de la liste des types de cadrage
	 */
	public getListeTypesCadrage(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de cadrage
		return this.listeTypesCadrage.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.format.cadrage.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de complétion
	 */
	public getListeTypesCompletion(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de complétion
		return this.listeTypesCompletion.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.format.completion.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de format
	 */
	public getListeTypesFormatFichier(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de format
		return this.listeTypesFormat.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.fichier.typeFormat.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'échappement du fichier
	 */
	public getListeTypesEchappement(): Array<{ code: string,libelle: string }> {
		//Liste des types d'échappement du fichier
		return this.listeTypeEchappement.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.fichier.typeEchappement.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'encodage du fichier
	 */
	public getListeTypesEncodage(): Array<{ code: string,libelle: string }> {
		//Liste des types d'encodage du fichier
		return this.listeTypesEncodage.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.fichier.typeEncodage.${code}`)
		}));
	}

	/**
	 * Liste des types de compteur
	 */
	public getListeTypesCompteur(): Array<{ code: string,libelle: string }> {
		//Liste des types de compteur
		return this.listeTypesCompteur.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.typeCompteur.${code}`)
		}));
	}

	/**
	 * Liste des types de détail
	 */
	public getListeTypesDetail(): Array<{ code: string,libelle: string }> {
		//Liste des types de détail
		return this.listeTypesDetail.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.typeDetail.${code}`)
		}));
	}

	/**
	 * Liste des types de ligne
	 */
	public getListeTypesLigne(): Array<{ code: string,typeDetail: string,libelle: string }> {
		//Liste des types de ligne
		return this.listeTypesLigne.map(typeLigne => ({
			...typeLigne,
			libelle: this.translateService.instant(`structureExport.typeLigne.${typeLigne.code}`)
		}));
	}

	/**
	 * Liste des types de rubrique
	 */
	public getListeTypesRubrique(): Array<{ code: string,libelle: string,listeGroupes?: Array<{ code: string,libelle: string,listeItems: Array<{ code: string,libelle: string,tooltip?: string,subCode?: string,_type?: string }> }> }> {
		//Liste types de rubrique
		return this.listeTypesRubrique.map(typeRubrique => ({
			code: typeRubrique.code,
			libelle: this.translateService.instant(`structureExport.rubrique.typeRubrique.${typeRubrique.code}.item`),
			listeGroupes: (typeRubrique.listeGroupes || []).map(groupe => ({
				code: groupe.code,
				libelle: this.translateService.instant(`structureExport.rubrique.typeRubrique.${typeRubrique.code}.${groupe.code}.item`),
				listeItems: (groupe.listeItems || []).map(item => ({
					code: item.code,
					subCode: item.subCode,
					_type: item._type,
					libelle: this.translateService.instant(`structureExport.rubrique.typeRubrique.${typeRubrique.code}.${groupe.code}.${item.code}.item`),
					tooltip: item.tooltip && this.translateService.instant(`structureExport.rubrique.typeRubrique.${typeRubrique.code}.${groupe.code}.${item.code}.tooltip`)
				}))
			}))
		}));
	}

	/**
	 * Récupération de la liste des types d'extraction
	 */
	public getListeTypesExtraction(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'extraction
		return this.listeTypesExtraction.map(code => ({
			code,
			libelle: this.translateService.instant(`structureExport.rubrique.typeExtraction.${code}`)
		}));
	}

	/**
	 * Chargement de la structure d'export
	 */
	public loadStructureExport(idStructureExport: number): Observable<Result> {
		//Chargement de la structure d'export
		return this.http.post<Result>(`${environment.baseUrl}/controller/StructureExport/loadStructureExport/${idStructureExport}`,null);
	}

	/**
	 * Sauvegarde de la structure d'export
	 */
	public saveStructureExport(typeReferentiel: TypeReferentiel,structureExport: any): Observable<Result> {
		//Sauvegarde de la structure d'export
		return this.http.put<Result>(`${environment.baseUrl}/controller/StructureExport/saveStructureExport/${typeReferentiel}`,structureExport);
	}

	/**
	 * Sauvegarde du détail de la structure d'export
	 */
	public saveStructureExportDetail(idStructureExport: number,detail: any): Observable<Result> {
		//Sauvegarde du détail de la structure d'export
		return this.http.put<Result>(`${environment.baseUrl}/controller/StructureExport/saveStructureExportDetail/${idStructureExport}`,detail);
	}

	/**
	 * Sauvegarde d'un détail parent
	 */
	public saveNewParentDetail(idDetailFichier: number,idDetail: number,detail: any): Observable<Result> {
		//Sauvegarde du détail de la structure d'export
		return this.http.post<Result>(`${environment.baseUrl}/controller/StructureExport/saveNewParentDetail/${idDetailFichier}/${idDetail}`,detail);
	}

	/**
	 * Suppression d'une structure d'export
	 */
	public deleteStructureExport(typeReferentiel: TypeReferentiel,structureExport: any): Observable<Result> {
		//Suppression de la structure d'export
		return this.http.delete<Result>(`${environment.baseUrl}/controller/StructureExport/deleteStructureExport/${typeReferentiel}/${structureExport.idStructure}`);
	}

	/**
	 * Suppression du détail de la structure d'export
	 */
	public deleteStructureExportDetail(detail: any): Observable<Result> {
		//Suppression du détail de la structure d'export
		return this.http.delete<Result>(`${environment.baseUrl}/controller/StructureExport/deleteStructureExportDetail/${detail.idDetail}`);
	}

	/**
	 * Sauvegarde d'une rubrique
	 */
	public saveRubrique(objectVersion: number,idLigne: number,rubrique: any): Observable<Result> {
		//Sauvegarde de la rubrique
		return this.http.put<Result>(`${environment.baseUrl}/controller/StructureExport/saveRubrique/${objectVersion}/${idLigne}`,rubrique);
	}

	/**
	 * Récupération des compteurs disponibles pour une ligne
	 */
	public findAllCompteursForLigne(idLigne: number): Observable<Array<any>> {
		//Récupération des compteurs disponibles pour une ligne
		return this.http.post<Result>(`${environment.baseUrl}/controller/StructureExport/findAllCompteursForLigne/${idLigne}`,null).pipe(
			first(),
			filter(result => result?.data?.listeCompteurs),
			map(result => result?.data?.listeCompteurs)
		);
	}

	/**
	 * Déplacement de rubriques
	 */
	public moveRubrique(idLigne: number,currentPosition: number,newPosition: number): Observable<Result> {
		//Déplacement des rubriques
		return this.http.post<Result>(`${environment.baseUrl}/controller/StructureExport/moveRubrique/${idLigne}/${currentPosition}/${newPosition}`,null);
	}

	/**
	 * Affichage de la popup de sélection d'un type de ligne
	 */
	public showTypeLigneSelection(typeDetailParent: string,isAddGroupeParent: boolean): Observable<Array<any>> {
		let bsModalRef: BsModalRef<StructureExportLineSelectionComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(StructureExportLineSelectionComponent,{
			initialState: {
				typeDetailParent,
				isAddGroupeParent
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			filter(() => !!bsModalRef.content.result?.listeSelectedItems?.length),
			map(() => bsModalRef.content.result?.listeSelectedItems)
		);
	}

	/**
	 * Affichage de la rubrique
	 */
	public showRubrique(structureExport: any,fichier: any,ligne: any,listeCompteurs: Array<any>,isActionMasse: boolean,rubrique: any,nbSelected: number,isRestricted: boolean): Observable<{ structureExport?: any,ligne?: any,rubrique: any }> {
		let bsModalRef: BsModalRef<StructureExportRubriqueComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(StructureExportRubriqueComponent,{
			initialState: {
				structureExport,
				fichier,
				ligne,
				listeCompteurs,
				rubrique,
				isActionMasse,
				nbSelected,
				isRestricted
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.result)
		);
	}

	/**
	 * Affichage de la sélection d'un type de rubrique
	 */
	public showTypeRubriqueSelection(structureExport: any,ligne: any,root: any,key: string,listeCompteurs: Array<any>): Observable<{ typeDetail: string,type: string,code: string,object: any,_type: string }> {
		let bsModalRef: BsModalRef<StructureExportRubriqueTypeSelectionComponent>;

		//Affichage de la popup de sélection du type de rubrique
		bsModalRef = this.bsModalService.show(StructureExportRubriqueTypeSelectionComponent,{
			initialState: {
				structureExport,
				ligne,
				listeCompteurs
			}
		});

		//Interception de la fermeture
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.result),
			filter(result => !!result),
			tap(typeLigne => {
				//Vérification du type de ligne sélectionné
				if (typeLigne.typeDetail == 'COMPTEUR') {
					//Ajout du détail
					root[key] = {
						typeDetail: typeLigne.typeDetail,
						compteur: typeLigne.object
					};
				} else if (typeLigne.typeDetail == 'CONSTANTE') {
					//Ajout du détail
					root[key] = {
						typeDetail: typeLigne.typeDetail,
						constante: {
							typeConstante: typeLigne.code == 'CONSTANTE' && typeLigne.type || null,
							typeVariable: typeLigne.code == 'VARIABLE' && typeLigne.type || null
						}
					};
				} else if (typeLigne.typeDetail == 'BUSINESS_DATA') {
					//Affichage des données métier
					this.businessDataService.showBusinessData('com.notilus.data.comptabilite.ecriture.ComptaEcriture',{
						typeContext: 'RULE',
						isPreviewData: false
					}).subscribe({
						next: item => {
							//Vérification du résultat
							if (item) {
								//Ajout du détail
								root[key] = {
									typeDetail: typeLigne.typeDetail,
									businessData: {
										name: item.entity.path,
										type: item.entity.type,
										typeAgregation: 'NON_DEFINI',
										libelle: item.entity.pathLibelle || item.entity.libelle
									}
								}
							}
						}
					});
				} else if (typeLigne.typeDetail == 'FONCTION') {
					//Ajout du détail
					root[key] = {
						typeDetail: typeLigne.typeDetail,
						fonction: {
							typeFonction: typeLigne.code == 'MATH' ? typeLigne.code : typeLigne.type,
							_type: typeLigne._type
						}
					};

					//Vérification du type de ligne
					if (typeLigne.type == 'CONCATENER')
						//Ajout de 2 détails
						root[key].fonction.listeDetails = [{},{}]
					else if (typeLigne.code == 'MATH')
						//Ajout de l'opérateur
						root[key].fonction.operateur = typeLigne.type;
				} else if (typeLigne.typeDetail == 'MEMOIRE') {
					//Ajout du détail
					root[key] = {
						typeDetail: typeLigne.typeDetail,
						memoire: {
							ligne: typeLigne.object
						}
					};
				}

				//Initialisation des comparateurs de l'élément
				typeLigne.typeDetail != 'BUSINESS_DATA' && this.initListeComparateurs(root);
			})
		);
	}

	/**
	 * Initialisation de la liste des comparateurs
	 */
	public initListeComparateurs(item: any): Array<any> {
		let type: string;

		//Vérification la présence d'une clé
		if (!item.cle)
			//Ne rien faire
			return;

		//Vérification du type de détail
		switch (item.cle.typeDetail) {
		case 'BUSINESS_DATA':
			//Récupération du type de la donnée métier
			type = item.cle.businessData.type;
			break;
		case 'CONSTANTE':
			//Récupération du type
			type = item.cle.constante.typeConstante;
			break;
		case 'FONCTION':
			//Vérification du type de fonction
			if (['CONCATENER','EXTRAIRE','REMPLACER'].indexOf(item.cle.fonction.typeFonction) != -1)
				//Type alpha-numérique
				type = 'ALPHANUMERIQUE';
			else if(['ADDITIONNER','SOUSTRAIRE','DIVISER','MULTIPLIER'].indexOf(item.cle.fonction.typeFonction) != -1)
				//Type numérique
				type = 'NUMERIQUE';
			break;
		case 'MEMOIRE':
			//Type alpha-numérique
			type = 'ALPHANUMERIQUE';
			break;
		default:
			//Ne rien faire
			return;
		}

		//Initialisation des comparateurs de l'élément par rapport au type
		item.listeOperateurs = this.getListeOperateursForType(type);

		//Retour de la liste des opérateurs
		return item.listeOperateurs;
	}

	/**
	 * Récupération de la liste des opérateurs pour un type
	 */
	private getListeOperateursForType(type: string): Array<any> {
		//Vérification de la présence d'un type
		if (!type)
			//Retour de l'ensemble des opérateurs
			return this.listeOperateurs;

		//Parcours de la liste d'opérateurs
		return this.listeOperateurs.filter(operateur => !operateur.listeTypes || operateur.listeTypes.indexOf(type) != -1);
	}

	/**
	 * Déclenchement d'une mise à jour de masse des rubriques
	 */
	public updateAllRubriques(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});

		return subject;
	}
}