import { DatePipe } from '@angular/common';
import { Component,Input,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { AffectationService } from './affectation/affectation.service';
import { VehiculeService } from './vehicule.service';
import { HistoryService } from 'src/app/share/components/history/history.service';

@Component({
	templateUrl: './vehicule-affectation-list-item.component.html'
})
export class VehiculeAffectationListItemComponent extends ListViewItem<any> implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Date courante **/
	public today: any;

	/** Enumération des droits **/
	public TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private affectationService: AffectationService,private confirmService: ConfirmService,private store: Store<AppState>,private translateService: TranslateService,private vehiculeService: VehiculeService,private layoutService: LayoutService,private datePipe: DatePipe,private historyService: HistoryService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteAffectation = this.deleteAffectation.bind(this)
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Date courante
		this.today = moment().startOf('day');

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Accès à la réservation
	 */
	goToReservation(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeReservations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idReservation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.reservation.idReservation,
					title: this.translateService.instant('reservation.affectation',{ dateDebut: this.datePipe.transform(affectation.dateDebut,'short') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Redirection vers le planning
	 */
	goToPlanning(affectation: any = this.data) {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'une réservation et d'un pool
		if (affectation.vehicule?.pool?.idPool && affectation.reservation) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + affectation.vehicule.pool.libelle + ' (' + affectation.vehicule.pool.reference + ')'
			});
		}

		//Navigation vers le planning
		this.vehiculeService.goToPlanning(affectation.vehicule,{ listeSelectedFilters },null,{ initialDate: affectation.dateDebut });
	}

	/**
	 * Accès à la liste des échéances des affectations
	 */
	goToAffectationEcheances(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeAffectationEcheances',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'affectation.idAffectation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.idAffectation,
					title: this.translateService.instant('vehicule.affectation.avantageNature.title',{ reference: affectation.vehicule.reference + (affectation.vehicule.numeroInterne ? ' - ' + affectation.vehicule.numeroInterne : '') + (affectation.user ? ' (' + affectation.user.nom + ' ' + affectation.user.prenom + ')' : '') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteAffectation(affectation: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.affectationService.deleteAffectation(affectation))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Accès au conducteur
	 */
	showConducteur(affectation: any = this.data) {
		//Redirection vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: affectation.conducteur.idConducteur,
				typeListe: ''
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de l'affectation
	 */
	showAffectation() {
		//Chargement complet de l'affectation
		this.affectationService.loadAffectation(this.data.idAffectation).pipe(first()).subscribe({
			next: result => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Mise à jour de l'affectation
					Object.assign(this.data,result.data?.affectation);

					//Affichage de l'affectation
					this.extraOptions?.addAffectation?.(this.data,this.deleteAffectation);
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Affectation',this.data.idAffectation);
	}
}