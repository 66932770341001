<div class="card-body card-padding">
	<fieldset [disabled]="!(null | right:'creation')">
		<div class="row">
			<div class="col-md-6">
				<ng-container *ngIf="source == 'EXTRACTION'">
					<div class="form-group" [ngClass]="{ 'has-error': typeNotificationField.invalid }">
						<label translate>extraction.diffusion.type</label>
						<div>
							<selectpicker name="typeNotification" #typeNotificationField="ngModel" [(ngModel)]="userScheduler.typeNotification" required>
								<mat-option *ngFor="let typeNotification of listeTypesNotification" [value]="typeNotification.code">{{ typeNotification.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<ng-container *ngIf="userScheduler.typeNotification == 'USER'">
						<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
							<label translate>extraction.diffusion.user</label>
							<div>
								<autocomplete type="user" name="user" #user="ngModel" [(ngModel)]="userScheduler.user" required></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>extraction.diffusion.restrictionProfil.item</label>
							<div>
								<mat-checkbox name="restrictionProfil" #restrictionProfil="ngModel" [(ngModel)]="userScheduler.restrictionProfil"><span translate>extraction.diffusion.restrictionProfil.description</span></mat-checkbox>
							</div>
						</div>
					</ng-container>
					<div class="form-group" [ngClass]="{ 'has-error': profil.invalid }" *ngIf="userScheduler.typeNotification == 'PROFIL'">
						<label translate>extraction.diffusion.profil</label>
						<div>
							<autocomplete type="profil" [filter]="{ listeTypesDroit: entiteInformations?.listeTypesDroit }" name="profil" #profil="ngModel" [(ngModel)]="userScheduler.profil" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': email.invalid }" *ngIf="userScheduler.typeNotification == 'EMAIL'">
						<label translate>extraction.diffusion.email</label>
						<div>
							<input type="email" email class="form-control" name="email" #email="ngModel" [(ngModel)]="userScheduler.email" required/>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="source == 'PROFIL' || source == 'USER'">
					<div class="form-group" [ngClass]="{ 'has-error': extraction.invalid }">
						<label translate>extraction.diffusion.extraction</label>
						<div>
							<autocomplete type="extraction" [filter]="{ listeTypesDroit: getListeTypesDroit() }" name="extraction" #extraction="ngModel" [(ngModel)]="userScheduler.entityObject" required></autocomplete>
						</div>
					</div>
					<div class="form-group" *ngIf="userScheduler.typeNotification == 'USER'">
						<label translate>extraction.diffusion.restrictionProfil.item</label>
						<div>
							<mat-checkbox name="restrictionProfil" #restrictionProfil="ngModel" [(ngModel)]="userScheduler.restrictionProfil"><span translate>extraction.diffusion.restrictionProfil.description</span></mat-checkbox>
						</div>
					</div>
				</ng-container>
				<div class="form-group" [ngClass]="{ 'has-error': scheduler.invalid }">
					<label translate>extraction.diffusion.frequence</label>
					<div>
						<scheduler name="scheduler" #scheduler="ngModel" [(ngModel)]="userScheduler.scheduler" required></scheduler>
					</div>
				</div>
			</div>
		</div>
	</fieldset>
</div>