import { ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { DeviceListItemComponent } from './device-list-item.component';

@Component({
	templateUrl: './device-list.component.html'
})
export class DeviceListComponent implements OnInit {
	/** Profil utilisateur **/
	@Input() lienProfil: any;

	/** Compte mobile **/
	@Input() userAccountMobile: any;

	/** Données **/
	liste: ListView<any,DeviceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DeviceListItemComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DeviceListItemComponent>({
			uri: () => `/controller/Device/filtreDevicesForUser/${this.lienProfil?.user?.idUser}/${this.lienProfil?.profil?.idProfil}`,
			isLocal: true,
			component: DeviceListItemComponent,
			extraOptions: {
				login: this.userAccountMobile?.login
			},
			listeFilters: [{
				clef: 'manufacturer',
				isDefault: true
			},{
				clef: 'model',
				isDefault: true
			}],
			defaultOrder: '-lastCheckDate'
		})
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la popup
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}
