<div class="card detail">
	<div class="card-header">
		<h2 class="card-title"><span translate>reponseCotation.demande.titre</span></h2>
		<div class="row">
			<div class="lv-actions actions">
				<a [routerLink]="[]" (click)="reponseCotation.isCotationToggled = !reponseCotation.isCotationToggled">
					<icon [name]="reponseCotation.isCotationToggled ? 'chevron_right' : 'expand_more'"></icon>
				</a>
			</div>
		</div>
	</div>
	<div class="card-body card-padding" [collapse]="reponseCotation.isCotationToggled">
		<div class="row">
			<div class="col-xs-12">
				<h4><span translate>reponseCotation.demande.informationsGenerales</span></h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label><span translate>reponseCotation.demande.modele</span></label>
					<div>
						<mat-chip-listbox>
							<mat-chip class="clickable" (click)="showModeleInformation(reponseCotation.cotation.modele)">
								<span>{{ reponseCotation?.cotation?.modele?.marque?.libelle + ' ' + reponseCotation?.cotation?.modele?.libelle + ' ' + (reponseCotation?.cotation?.modele?.finition ? '(' + reponseCotation?.cotation?.modele?.finition + ')' : '') }}</span>
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="form-group">
					<label><span translate>reponseCotation.demande.typeFinancement</span></label>
					<div>
						<mat-chip-listbox>
							<mat-chip>
								<span>{{ reponseCotation?.cotation?.typeFinancement?.libelle }}</span>
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="form-group">
					<label>
						<span translate>reponseCotation.demande.loiRoulage.title</span>
						<icon class="m-l-5" name="info_outline" [tooltip]="'reponseCotation.demande.loiRoulage.info' | translate"></icon>
					</label>
					<div>
						<mat-chip-listbox>
							<mat-chip *ngIf="reponseCotation.cotation.duree != null && reponseCotation.cotation.distance != null">
								<span>{{ reponseCotation.cotation.duree }}</span>&#160;/&#160;<span>{{ (reponseCotation.cotation.distance | number) + ' ' + user.unite.libelle }}</span>
							</mat-chip>
							<mat-chip *ngIf="reponseCotation.cotation.duree == null || reponseCotation.cotation.distance == null">
								<span translate>reponseCotation.demande.loiRoulageNonRenseignee</span>
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>reponseCotation.demande.optionPayante.title</label>
					<div>
						<mat-chip-listbox>
							<mat-chip *ngIf="listeOptionsPayantes.length" class="clickable" (click)="showListeOptionsPayantes()">
								<span translate [translateParams]="{ count: listeOptionsPayantes.length || 0 }">reponseCotation.demande.optionPayante.libelle</span>
							</mat-chip>
							<mat-chip *ngIf="!listeOptionsPayantes.length">
								<span translate>reponseCotation.demande.optionPayante.none</span>
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="form-group">
					<label translate>reponseCotation.demande.piecesJointes</label>
					<div>
						<div [attachment]="reponseCotation.cotation" [typeAttachment]="TypeAttachment.COTATION" [readOnly]="true"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="reponseCotation.cotation.remarque">
			<div class="col-xs-12">
				<h4><span translate>reponseCotation.demande.remarque</span></h4>
			</div>
		</div>
		<div class="row" *ngIf="reponseCotation.cotation.remarque">
			<div class="col-md-6">
				<div class="row">
					<div class="form-group">
						<div class="col-md-12">
							<span>{{ reponseCotation.cotation.remarque }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>