import { Injectable } from '@angular/core';

@Injectable()
export class SelectPickerService {
	/**
	 * Génération d'un comparateur
	 */
	public getComparator(key: string): (o1: any,o2: any) => boolean {
		//Génération de la fonction de comparaison
		return (o1: any,o2: any) => o1?.[key] == o2?.[key];
	}
}