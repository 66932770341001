import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PersonalDataService } from './personal-data.service';
import { TypeFrequence } from 'src/app/domain/scheduler/scheduler';

@Component({
	selector: 'personal-data',
	templateUrl: './personal-data.component.html'
})
export class PersonalDataComponent implements OnInit {
	/** Utilisateur à anonymiser **/
	userToAnonymize: User;

	/** Paramétrage d'anonymisation des utilisateurs **/
	automatisationAnonymisation: any = {};

	/** Options d'ordonnancement **/
	options = { listeTypesFrequence: [TypeFrequence.MENSUEL,TypeFrequence.TRIMESTRIEL,TypeFrequence.ANNUEL] };

	/** Paramétrage d'anonymisation des utilisateurs - Copie **/
	private savedAutomatisationAnonymisation: any;

	/** Liste des types de durée **/
	public listeTypeDurees: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private personalDataService: PersonalDataService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {}

	/**
	 * Initialisation
	 */
	public ngOnInit(): void {
		//Définition de la liste des types de durée
		this.listeTypeDurees = this.personalDataService.getListeTypesDuree();

		//Chargement des données
		this.loadData();
	}

	/**
	 * Anonymisation de l'utilisateur
	 */
	anonymizeUser() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('personalData.anonymisation.confirmation.title'),{
			withTyping: true,
			confirmationTerm: this.translateService.instant('personalData.anonymisation.confirmation.confirmationTerm'),
			alerteInfo: `
				<i class="material-icons-round">warning</i>
				<div>
					<strong>${this.translateService.instant('personalData.anonymisation.confirmation.message.1')}</strong>
					<div>${this.translateService.instant('personalData.anonymisation.confirmation.message.2')}</div>
					<div>${this.translateService.instant('personalData.anonymisation.confirmation.message.3',{ confirmationTerm: this.translateService.instant('personalData.anonymisation.confirmation.confirmationTerm') })}</div>
				</div>
			`
		}).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.personalDataService.anonymize(this.userToAnonymize))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('personalData.anonymisation.success'));
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('personalData.anonymisation.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(): void {
		//Chargement du paramétrage d'automatisation
		this.personalDataService.loadAutomatisationAnonymisation().subscribe({
			next: result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
					//Définition du paramétrage d'automatisation
					this.automatisationAnonymisation = result.data?.automatisationAnonymisation || { automatique: false };
			}
		});
	}

	/**
	 * Enregistrement du paramétrage d'automatisation
	 */
	public saveAutomatisationAnonymisation(): void {
		//Enregistrement du paramétrage d'automatisation
		this.personalDataService.saveAutomatisationAnonymisation(this.automatisationAnonymisation).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour du paramétrage d'automatisation
					this.automatisationAnonymisation = result.data?.automatisationAnonymisation;

					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Changement de l'automatisation d'anonymisation
	 */
	public onAnonymisationAutomatiqueChange(isEnabled: boolean): void {
		//Vérification de la désactivation de l'automatisation
		if (!isEnabled) {
			//Enregistrement des informations
			this.savedAutomatisationAnonymisation = cloneDeep(this.automatisationAnonymisation);

			//Suppression des données
			this.automatisationAnonymisation.scheduler = null;
			this.automatisationAnonymisation.duree = null;
			this.automatisationAnonymisation.typeDuree = null;
		} else {
			//Restauration des informations
			this.automatisationAnonymisation.scheduler = this.savedAutomatisationAnonymisation?.scheduler;
			this.automatisationAnonymisation.duree = this.savedAutomatisationAnonymisation?.duree;
			this.automatisationAnonymisation.typeDuree = this.savedAutomatisationAnonymisation?.typeDuree;
		}
	}
}