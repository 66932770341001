import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import moment from 'moment';

import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { entites,TypeEntiteValue } from './rule.entites';

@Injectable()
export class RuleService {
	/** Liste des opérateurs d'agrégation **/
	private listeOperatorsAgregation: Array<string> = ['EQUAL','NOT_EQUAL','GREATER','LESS'];

	/** Liste des types d'agrégation **/
	private listeTypesAgregation: Array<string> = ['AVERAGE','COUNT','DISTINCT_COUNT','MAX','MIN','SUM'];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private autocompleteService: AutocompleteService) {}

	/**
	 * Récupération de la liste des opérateurs d'agrégation
	 */
	public getListeOperatorsAgregations(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des opérateurs d'agrégation traduits
		return this.listeOperatorsAgregation.map(code => ({
			code,
			libelle: this.translateService.instant(`rule.operateur.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'agrégation
	 */
	public getListeTypesAgregation(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'agrégation traduits
		return this.listeTypesAgregation.map(code => ({
			code,
			libelle: this.translateService.instant(`rule.agregation.${code}`)
		}));
	}

	/**
	 * Récupération du type de champ
	 */
	public getFieldType(type: string,entity?: any): { type: string,label?: string,autocomplete?: string,autocompleteFilter?: string,listeOperators: Array<{ code: string,libelle: string }>,listeTypesAgregation?: Array<{ code: string,libelle: string }>,rootKey?: string,listeValues?: Array<string> } {
		let fieldType: { type: string,label?: string,autocomplete?: string,autocompleteFilter?: string,listeOperators: Array<{ code: string,libelle: string }>,listeTypesAgregation?: Array<{ code: string,libelle: string }>,rootKey?: string,listeValues?: Array<string> };
		let autocompleteType: string;
		let autocompleteFilter: string;
		let typeEntite: TypeEntiteValue;

		//Récupération du type d'entité
		typeEntite = get(entites,type) as TypeEntiteValue;

		//Vérification de la présence du type
		if (typeEntite) {
			//Définition du champ
			fieldType = {
				...typeEntite,
				listeOperators: typeEntite.listeOperators?.map(code => ({
					code,
					libelle: this.translateService.instant(`rule.operateur.${code}`)
				}))
			};

			//Vérification du type d'élément
			if (fieldType.type == 'ENUM') {
				//Vérification de l'absence des opérateurs
				if (!fieldType.listeOperators) {
					//Ajout des opérateurs par défaut
					fieldType.listeOperators = ['EQUAL','NOT_EQUAL','IS_NULL','IS_NOT_NULL'].map(code => ({
						code,
						libelle: this.translateService.instant(`rule.operateur.${code}`)
					}));
				}
			}

			//Récupération des informations d'autocomplete de l'élément
			autocompleteType = entity?.autocompleteType;
			autocompleteFilter = entity?.autocompleteFilter;

			//Vérification de la présence d'un autocomplete spécifique
			if (autocompleteType) {
				//Définition du type
				fieldType.type = 'AUTOCOMPLETE';
				fieldType.autocomplete = autocompleteType;

				//Remise à zéro des opérateurs
				fieldType.listeOperators = null;
			}

			//Vérification de la présence d'un filtre d'autocomplete spécifique
			if (autocompleteFilter)
				//Mise à jour du filtre de l'autocomplete
				fieldType.autocompleteFilter = autocompleteFilter.startsWith('{') ? JSON.parse(autocompleteFilter) : autocompleteFilter;

			//Vérification du type de champs
			if (fieldType.autocomplete) {
				//Récupération du libellé
				fieldType.label = this.translateService.instant('autocomplete.'+fieldType.autocomplete);

				//Vérification de l'absence des opérateurs
				if (!fieldType.listeOperators?.length) {
					//Ajout des opérateurs par défaut
					fieldType.listeOperators = ['EQUAL','NOT_EQUAL','IS_NULL','IS_NOT_NULL'].map(code => ({
						code,
						libelle: this.translateService.instant(`rule.operateur.${code}`)
					}));
				}
			}

			//Définition des types d'agrégation
			fieldType.listeTypesAgregation = fieldType.type != 'NUMBER' ? this.getListeTypesAgregation().filter(t => ['COUNT','DISTINCT_COUNT'].includes(t.code)) : this.getListeTypesAgregation();
		} else {
			//Définition d'un type générique
			fieldType = {
				type: 'LONG',
				listeOperators: ['IS_NULL','IS_NOT_NULL'].map(code => ({
					code,
					libelle: this.translateService.instant(`rule.operateur.${code}`)
				}))
			};
		}

		//Retour du type de champs pour le type d'entité fourni en entrée
		return fieldType;
	}

	/**
	 * Détermination du type de champs de saisie des filtres de la règle
	 */
	public assignFieldType(rule: any) {
		//Vérification de la présence de détails sur la règle
		if (rule?.listeDetails?.length) {
			//Parcours des détails de la règle
			rule.listeDetails.forEach(detail => {
				//Vérification de la présence d'un filtre
				if (detail.filter) {
					//Détermination du type de champs de saisie
					detail.filter.fieldType = this.getFieldType(detail.filter.type,detail.filter);

					//Vérification de la présence d'une énumération
					detail.filter.enum = detail.filter.fieldType && detail.filter.fieldType.type == 'ENUM';
				} else
					//Détermination du type de champs de saisie des règles internes
					this.assignFieldType(detail.rule);
			});
		}
	}

	/**
	 * Mise à jour de la règle pour l'enregistrement
	 */
	public updateRuleForSave(rule: any): any {
		//Vérification de la présence d'une règle
		if (rule?.listeDetails?.length) {
			//Définition de la date de mise à jour
			rule.dateUpdate = moment.utc().toDate();

			//Parcours des détails de la règle
			rule.listeDetails.forEach((detail,index) => {
				//Numérotation du détail
				detail.position = index;

				//Vérification du filtre
				if (detail.filter) {
					//Parcours des valeurs
					detail.filter.listeValues.forEach((value: any,index: number) => {
						let autocompleteOptions: AutocompleteOptions;

						//Définition du type de valeur
						value.type = value.object ? 'OBJECT' : 'SIMPLE';

						//Définition de la fonction et de la position
						value.fonction = value.fonction || 'AUCUNE';
						value.position = index + 1;

						//Vérification de l'objet issu d'un autocomplete
						if (value.type == 'OBJECT' && detail.filter.fieldType.autocomplete) {
							//Récupération de l'autocomplete associé au type de filtre
							autocompleteOptions = this.autocompleteService.getOptionsForType(detail.filter.fieldType.autocomplete);

							//Vérification de l'information sur l'identifiant de l'objet
							if (autocompleteOptions)
								//Définition de l'identifiant de l'objet
								value.value = value.object[autocompleteOptions.getKeyFieldName()];
						}
					});
				}

				//Vérification du type de détail
				if (detail.rule)
					//Numérotation des règles internes
					this.updateRuleForSave(detail.rule);
			});
		}

		//Retour de la règle
		return rule;
	}

	/**
	 * Récupération de la liste des jours de la semaine
	 */
	public getListeWeekDays(): Array<{ code: string,libelle: string }> {
		//Retour de la liste
		return [{
			code: '2',
			libelle: this.translateService.instant('scheduler.jours.LUNDI')
		},{
			code: '3',
			libelle: this.translateService.instant('scheduler.jours.MARDI')
		},{
			code: '4',
			libelle: this.translateService.instant('scheduler.jours.MERCREDI')
		},{
			code: '5',
			libelle: this.translateService.instant('scheduler.jours.JEUDI')
		},{
			code: '6',
			libelle: this.translateService.instant('scheduler.jours.VENDREDI')
		},{
			code: '7',
			libelle: this.translateService.instant('scheduler.jours.SAMEDI')
		},{
			code: '1',
			libelle: this.translateService.instant('scheduler.jours.DIMANCHE')
		}];
	}

	/**
	 * Vérification de la règle
	 */
	public isRuleValid(rule: any,isRequired?: boolean) {
		//Vérification de la règle
		return isRequired ? rule.listeDetails && this.hasFilter(rule.listeDetails) : !rule?.listeDetails || rule.listeDetails && this.hasFilter(rule.listeDetails);

	}

	/**
	 * Vérification de la présence d'un filtre
	 */
	private hasFilter(listeDetails: Array<any>) {
		let isOk: boolean;

		//Vérification des détails
		isOk = listeDetails.some(detail => {
			//Vérification du détail
			if (detail?.filter && Object.keys(detail?.filter)?.length)
				//Validation de la règle
				return true;
			else if (!isOk && detail.rule?.listeDetails)
				//Validation du filtre
				return this.hasFilter(detail.rule?.listeDetails);
		});

		return isOk;
	}
}