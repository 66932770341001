import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des contrats CRM
 */
export default class ContratCRMOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.contratCRM';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/ContratCRM/searchContrat`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.reference} (${item.clientCRM.libelle})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.crm.ContratCRM';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idContrat'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'clientCRM.libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}
}