<div class="container-fluid">
	<form #workflowForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>workflow.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="workflow.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>workflow.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="workflow.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<label translate>workflow.dateApplication</label>
								<div class="half">
									<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="workflow.dateApplication" datePicker format="date" [storeDate]="true" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': entiteField.invalid }">
								<label translate>workflow.entite.item</label>
								<div class="half">
									<selectpicker name="entite" #entiteField="ngModel" [(ngModel)]="workflow.entite" required>
										<mat-option *ngFor="let entite of listeEntites$ | async" [value]="entite.code">{{ entite.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>workflow.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="workflow.actif"><span translate>workflow.actif.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>workflow.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" [(ngModel)]="workflow.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteWorkflow()" *ngIf="workflow?.idWorkflow && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" class="m-l-10" (click)="saveWorkflow()" *ngIf="null | right:'creation'" [disabled]="workflowForm.invalid">
				<span [translate]="'actions.' + (workflow?.idWorkflow ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="workflow?.idWorkflow" [formDetectChanges]="{ doAction: close,isDirty: workflowForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>
