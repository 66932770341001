import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class TypeEntretienService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression du type d'entretien
	 */
	public deleteTypeEntretien(typeEntretien: any): Observable<Result> {
		//Suppression du type d'entretien
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteTypeEntretien/${typeEntretien.idTypeEntretien}`);
	}

	/**
	 * Enregistrement du type d'entretien
	 */
	public saveTypeEntretien(typeEntretien: any): Observable<Result> {
		//Enregistrement du type d'entretien
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveTypeEntretien`,typeEntretien);
	}
}