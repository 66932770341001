import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './compte-service-password.component.html'
})
export class CompteServicePasswordComponent  {
	/** Utilisateur du compte de service **/
	@Input() user: any;

	/** Clef de connexion **/
	@Input() password: string;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<CompteServicePasswordComponent>) {}
}