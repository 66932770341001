import { Component,OnInit } from '@angular/core';
import { switchMap,filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ContraventionService } from 'src/app/components/vehicule/infraction/contravention/contravention.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DemandeIdentificationService } from 'src/app/components/vehicule/infraction/demande-identification/demande-identification.service';

@Component({
	templateUrl: './conducteur-infraction-list-item.component.html'
})
export class ConducteurInfractionListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des statuts des demandes d'identification **/
	private listeStatuts: Array<{ code: string,icone: string,iconeType?: string }>;

	/**
	 * Constructeur
	 */
	constructor(private contraventionService: ContraventionService,private demandeIdentificationService: DemandeIdentificationService,private pageContentService: PageContentService,public rightService: RightService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des statuts
		this.listeStatuts = this.demandeIdentificationService.getListeStatuts();
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,this.data.idObject);
	}

	/**
	 * Redirection vers l'infraction (Demande d'identification / Contravention)
	 */
	showDetailInfraction() {
		//Vérification du type d'infraction
		if (this.data.typeInfraction == 'DEMANDE_IDENTIFICATION')
			//Affichage du formulaire de la demande d'identification
			this.goToDemandeIdentification();
		else if (this.data.typeInfraction == 'CONTRAVENTION')
			//Affichage du formulaire de la contravention
			this.goToContravention();
	}

	/**
	 * Suppression de la contravention
	 */
	deleteContravention() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contraventionService.deleteContravention(this.data.contravention))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de la contravention depuis la liste
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Récupération du statut de la demande d'identification par rapport au code
	 */
	getStatutForCode(code: string): { code: string,icone: string,iconeType?: string } {
		//Retour du statut
		return this.listeStatuts?.find(s => s.code == code);
	}

	/**
	 * Accès au formulaire de la demande d'identification
	 */
	goToDemandeIdentification() {
		//Redirection vers la demande d'identification
		this.layoutService.goToByState('infractionReferentiel-listeDemandesIdentification-identification',{
			routeParams: {
				idIdentification: this.data.idObject
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au formulaire de la contravention
	 */
	private goToContravention() {
		//Redirection vers la contravention
		this.layoutService.goToByState('infractionReferentiel-listeVehiculeContraventions-contravention',{
			routeParams: {
				idContravention: this.data.contravention?.idContravention || 0
			},
			withGoBack: true
		});
	}
}