import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { FournisseurNatureListItemComponent } from './fournisseur-nature-list-item.component';

@Component({
	templateUrl: './fournisseur-nature-list.component.html'
})
export class FournisseurNatureListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,FournisseurNatureListItemComponent>;

	/** Liste des métiers **/
	listeMetiers: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<FournisseurNatureListComponent>,public translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FournisseurNatureListItemComponent>({
			uri: '/controller/Fournisseur/listeNaturesByMetiers',
			component: FournisseurNatureListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'idNature',
				title: this.translateService.instant('search.identifiant'),
				isDefault: true
			}],
			listeExtraData: () => {
				//Génération de la liste des métiers
				return this.listeMetiers.map(m => ({
					...m,
					_type: 'com.notilus.data.fournisseur.FournisseurMetier'
				}));
			}
		});
	}
}