import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeEquipementListItemComponent } from './vehicule-equipement-list-item.component';

@Component({
	selector: 'vehicule-equipement-list',
	templateUrl: './vehicule-equipement-list.component.html'
})
export class VehiculeEquipementListComponent extends PageContentItem implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Fonction d'édition **/
	@Input() addEquipement: () => void;

	/** Résumé **/
	@Input() resume: { nbAffectationsEquipement: number };

	/** Données **/
	liste: ListView<any,VehiculeEquipementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeEquipementListItemComponent>({
			uri: () => `/controller/Equipement/filtreAffectations`,
			component: VehiculeEquipementListItemComponent,
			isLocal: true,
			extraOptions: {
				addEquipement: this.addEquipement
			},
			listeFilters: [{
				clef: 'equipement',
				autocomplete: {
					type: 'equipement'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateDebut,-dateFin'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idAffectation > 0)
			//Mise à jour des compteurs
			this.resume.nbAffectationsEquipement--;
	}
}