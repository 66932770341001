<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idPays">
		<div class="pull-left lv-avatar">{{ data?.code2?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed"><span class="fi m-r-10" [ngClass]="'fi-'+data.code2?.toLowerCase()"></span>{{ data.libelle + (data.mapLang[user?.langue?.code]?.libelle ? (' (' + data.mapLang[user?.langue?.code]?.libelle + ')') : '' ) }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idPays">
		<li><span translate>pays.codeISO2</span>&#160;:&#160;<span>{{ data.code2 }}</span></li>
		<li><span translate>pays.codeISO3</span>&#160;:&#160;<span>{{ data.code3 }}</span></li>
		<li *ngIf="data.listeDevises?.length"><span translate>pays.devise</span>&#160;:&#160;<span *ngFor="let devise of data.listeDevises" class="m-r-5">{{ devise.devise }}</span></li>
		<li *ngIf="(data.mapLang | keyvalue)?.length"><span translate>pays.traductions</span>&#160;:&#160;<span *ngFor="let item of data.mapLang | keyvalue" class="fi m-r-5" [tooltip]="item.value.libelle" [ngClass]="'fi-'+(item.key != 'ja' ? item.key : 'jp')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idPays">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deletePays();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.geographie.Pays',data?.idPays)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<pays [pays]="data" (onObjectChange)="onObjectChange($event)"></pays>
</div>