
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeviceService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Activation/désactivation de l'appareil
	 */
	public toggleDevice(device: any): Observable<Result> {
		//Activation/désactivation de l'appareil
		return this.http.post<Result>(`${environment.baseUrl}/controller/Device/toggleDevice/${device.idDevice}`,null);
	}
}
