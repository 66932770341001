import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EquipementListItemComponent } from './equipement-list-item.component';
import { EquipementService } from './equipement.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { mapEntites } from './equipement.entites';

@Component({
	selector: 'equipement-list',
	templateUrl: './equipement-list.component.html'
})
export class EquipementListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,EquipementListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private equipementService: EquipementService,private layoutService: LayoutService,public extractionService: ExtractionService,public dashboardService: DashboardService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,EquipementListItemComponent>({
			uri: `/controller/Equipement/filtreEquipements`,
			title: this.translateService.instant('equipement.liste.title'),
			component: EquipementListItemComponent,
			listeFilters: [{
				clef: 'reference',
				mapEncryptedFields: {
					reference: 'referenceChiffree'
				},
				isDefault: true
			},{
				clef: '*extension.*vehiculeAffecte',
				title: this.translateService.instant('search.vehicule'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					filter: 'VEHICULE_FONCTION_SERVICE'
				},
				isDefault: true
			},{
				clef: '*extension.*userAffecte',
				title: this.translateService.instant('search.user'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				},
				isDefault: true
			},{
				clef: 'fournisseur',
				title: this.translateService.instant('search.fournisseur'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				}
			},{
				clef: 'type',
				title: this.translateService.instant('search.typologie'),
				listeValues: this.equipementService.getListeTypes(),
				type: TypeFilter.STRING
			},{
				clef: 'numero',
				title: this.translateService.instant('search.numeroCarte'),
			}],
			defaultOrder: 'reference',
			getKeyFieldName: () => 'idEquipement',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Redirection vers la création d'un équipement
				this.layoutService.goToByUrl('/Equipement/loadEquipement/0');
			})
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}