import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of,ReplaySubject } from 'rxjs';
import { filter,switchMap,first } from 'rxjs/operators';
import { ControlContainer,NgForm } from '@angular/forms';
import moment from 'moment';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ReleveEnergieService } from './releve-energie.service';
import { EquipementService } from 'src/app/components/materiel/equipement/equipement.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

@Component({
	selector: 'releve-energie-content',
	templateUrl: './releve-energie-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ReleveEnergieContentComponent implements OnInit {
	/** Elément courant **/
	@Input() releveEnergie: any;

	/** Source **/
	@Input() source: 'RELEVE_CARBURANT' | 'VEHICULE' | 'CONDUCTEUR' | 'EQUIPEMENT';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Liste des types de source **/
	listeTypesSource: Array<{ code: string,libelle: string }>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Date courante **/
	public today = moment().startOf('day');

	/** Date du relevé précédente **/
	private previousDateReleve: Date = null;

	/**
	 * Constructeur
	 */
	constructor(private releveEnergieService: ReleveEnergieService,private layoutService: LayoutService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private equipementService: EquipementService,private messagingService: MessagingService
			,public rightService: RightService,public vehiculeService: VehiculeService) {
		//Binding des méthodes
		this.saveReleveEnergie = this.saveReleveEnergie.bind(this);
		this.deleteReleveEnergie = this.deleteReleveEnergie.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de source
		this.listeTypesSource = this.releveEnergieService.getListeTypesSource();

		//Exécution initiale des traitements sur la date du relevé pour les équipements
		this.source == 'EQUIPEMENT' && this.onDateChange();
	}

	/**
	 * Détection d'un changement de date de relevé
	 */
	onDateChange() {
		//Vérification du changement de date de relevé
		if (this.previousDateReleve != this.releveEnergie.dateReleve) {
			//Recherche du véhicule affecté à l'équipement à la date donnée
			this.equipementService.findVehiculeForDate(this.releveEnergie.equipement.idEquipement,this.releveEnergie.dateReleve).subscribe(result => {
				//Mise à jour du véhicule
				this.releveEnergie.vehicule = result?.data?.vehicule || null;
			});
		}

		//Mise à jour de la date du relevé précédente
		this.previousDateReleve = this.releveEnergie.dateReleve;
	}

	/**
	 * Suppression du relevé d'énergie
	 */
	deleteReleveEnergie() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.releveEnergieService.deleteReleveEnergie(this.releveEnergie))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture du formulaire
				this.close.emit();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Enregistrement du relevé d'énergie
	 */
	saveReleveEnergie() {
		let messaging$: MessagingObservables;
		let releveEnergie: any;
		let idReleveSubject: ReplaySubject<number> = new ReplaySubject<number>(1);

		//Copie du relevé d'énergie avant sauvegarde
		releveEnergie = Object.assign({},this.releveEnergie);

		//Vérification de la provenance du relevé d'énergie
		if (releveEnergie.typeSourceReleve == 'COLLABORATEUR')
			//Suppression des données liées au fournisseur
			delete releveEnergie.fournisseur;

		//Enregistrement du relevé d'énergie avec rafraichissement des données calculées
		messaging$ = this.messagingService.init({
			method: 'PUT',
			entryPoint: 'controller/VehiculeReleveEnergie/saveReleveEnergie',
			outputPoint: '/messaging/vehiculeReleveEnergie/saveReleveEnergie/status',
			params: releveEnergie
		}).onFinish({
			next: () => {
				//Récupération de l'identifiant du relevé d'énergie
				(releveEnergie.idReleve ? of(releveEnergie.idReleve) : idReleveSubject).pipe(
					first(),
					switchMap(idReleve => this.releveEnergieService.loadReleveEnergie(idReleve))
				).subscribe({
					next: result => {
						//Mise à jour du relevé d'énergie
						this.close.emit(result.data.releveEnergie);
					},
					complete: () => {
						//Fermeture des souscriptions
						messaging$.unsubscribe();
					}
				});
			}
		}).onResult({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Transmission de l'identifiant du relevé (utile en cas de création)
					idReleveSubject.next(result.data.releveEnergie.idReleve);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	showVehicule() {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.releveEnergie.vehicule?.idVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers l'équipement
	 */
	redirectToEquipement() {
		//Redirection vers l'équipement
		this.layoutService.goToByState('materielReferentiel-listeEquipements-loadEquipement',{
			routeParams: {
				idEquipement: this.releveEnergie.equipement.idEquipement
			},
			withGoBack: true
		});
	}

	/**
	 * Accès à la facture du relevé d'énergie
	 */
	showFacture() {
		//Vérification des droits de l'utilisateur
		if (this.rightService.hasRight(TypeDroit.FACTURE_DETAIL,'consultation')) {
			//Redirection vers la ligne de détail
			this.layoutService.goToByState('listeFactureDetails-detail',{
				routeParams: {
					idDetail: this.releveEnergie.detail.idDetail
				},
				withGoBack: true
			});
		} else {
			//Redirection vers la facture
			this.layoutService.goToByState('listeFactures-load',{
				routeParams: {
					idFacture: this.releveEnergie.detail.facture.idFacture
				},
				withGoBack: true
			});
		}
	}

	/**
	 * Interception d'un changement de véhicule
	 */
	onVehiculeChange() {
		//Vérification de la présence d'une unité par défaut et de l'absence d'unité sur le relevé d'énergie
		if (this.releveEnergie?.vehicule?.carburant?.uniteDefaut && !this.releveEnergie.unite)
			//Mise à jour de l'unité
			this.releveEnergie.unite = this.releveEnergie.vehicule.carburant.uniteDefaut;
	}
}