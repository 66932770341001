import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DetailContentComponent } from './detail-content.component';
import { DetailListItemComponent } from './detail-list-item.component';
import { DetailListComponent } from './detail-list.component';
import { DetailComponent } from './detail.component';

@NgModule({
	imports: [ShareModule],
	declarations: [DetailListComponent,DetailListItemComponent,DetailContentComponent,DetailComponent],
	exports: [DetailContentComponent]
})
export class DetailModule {}