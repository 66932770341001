import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './tenant-contrat-selection.component.html'
})
export class TenantContratSelectionComponent {
	/** Contrat sélectionné **/
	contrat: any;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<TenantContratSelectionComponent>) {}
}