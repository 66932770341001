import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { DeviceService } from './device.service';

@Component({
	templateUrl: './device-list-item.component.html'
})
export class DeviceListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private deviceService: DeviceService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Activation/désactivation de l'appareil
	 */
	toggleDevice() {
		//Activation/désactivation de l'appareil
		this.deviceService.toggleDevice(this.data).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.data,result.data?.device);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}