import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ServicePlanComptableListItemComponent } from './service-plan-comptable-list-item.component';

@Component({
	selector: 'service-plan-comptable-list',
	templateUrl: './service-plan-comptable-list.component.html'
})
export class ServicePlanComptableListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Fonction d'édition **/
	@Input() addPlanComptable: () => void;

	/** Résumé **/
	@Input() resume: { nbPlansComptable: number };

	/** Données **/
	liste: ListView<any,ServicePlanComptableListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServicePlanComptableListItemComponent>({
			uri: () => `/controller/Compte/filtrePlanComptables`,
			component: ServicePlanComptableListItemComponent,
			isLocal: true,
			extraOptions: {
				addPlanComptable: this.addPlanComptable
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: '-dateApplication',
			listeStaticFilters: [{
				clef: 'listeSocietes.idService',
				valeur: this.service.idService,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idPlanComptable > 0)
			//Mise à jour des compteurs
			this.resume.nbPlansComptable--;
	}
}