import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { Filter } from './filter';

/**
 * Type représentant une action
 */
export type Action = {
	/** Données **/
	icon: string,
	iconType?: string,
	typeAction?: TypeActionMasse,
	title?: string,
	messagingOptions?: MessagingOptions,
	onPress: (actionMasse: ActionMasse,messagingOptions?: MessagingOptions) => void,
	onFinish?: () => void,
	isVisible?: () => boolean
}

/**
 * Classe représentant une action de masse
 */
export class ActionMasse {
	/** Données **/
	typeActionMasse: 'SELECTION' | 'FULL';
	listeIdObjects: Array<number>;
	searchSpec?: { listeFilter: Array<Filter>,extraData?: any };
	nbSelected?: number;
	firstIdObject?: number;
	data?: { _type: string,[name: string]: any };
}

/**
 * Enumération des types d'action de masse
 */
export enum TypeActionMasse {
	/** Enumération **/
	NOTIFIER = 'NOTIFIER'
}