import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { CotationService } from 'src/app/components/commande/cotation/cotation.service';
import { mapEntites } from 'src/app/components/commande/demande-cotation/demande-cotation.entites';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';
import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './grille-attribution-modele-cotation-list-item.component.html'
})
export class GrilleAttributionModeleCotationListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Statuts des demandes de cotation **/
	public mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/** Utilisateur courant **/
	public user: User;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService,private historyService: HistoryService,public demandeCotationService: DemandeCotationService,private layoutService: LayoutService,private store: Store<AppState>,private cotationService: CotationService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des statuts
		this.mapStatuts = this.demandeCotationService.getMapStatuts();

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idDemandeCotation,object?.idDemandeCotation && !this.data?.idDemandeCotation);
	}

	/**
	 * Redirection vers la demande de cotation
	 */
	goToDemandeCotation() {
		//Redirection vers la demande de cotation
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesCotation-detail',{
			routeParams: {
				idDemandeCotation: this.data.demandeCotation.idDemandeCotation
			},
			routeData: {
				idCotation: this.data.idCotation
			},
			withGoBack: true
		});
	}

	/**
	 * Suppression de l'objet
	 */
	deleteCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la demande de cotation
					this.cotationService.deleteCotation(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression depuis la liste
								this.onObjectChange(null);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Annulation d'une cotation
	 */
	cancelCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.annulation.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Annulation de la cotation
					this.cotationService.cancelCotation(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Mise à jour de la cotation
								Object.assign(this.data,result.data?.cotation);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.cotation.Cotation',this.data.idCotation);
	}

	/**
	 * Affichage de la pop-up de comparaison
	 */
	showComparaisonForCotation(cotation: any = this.data) {
		//Affichage de la pop-up de comparaison
		this.cotationService.showComparaisonForCotation(cotation).subscribe({
			next: (result) => {
				//Vérification de la présence de la cotation
				if (result?.cotation)
					//Définition de la cotation
					Object.assign(cotation,result?.cotation);

				//Vérification de la présence d'une grille d'attribution
				if (result?.grilleAttributionModeleFinancement)
					//Mise à jour du compteur
					this.extraOptions.resume.nbFinancements++;
			}
		});
	}
}