<div class="image-editor" [ngClass]="{ 'read-only': consultation,'inline': inline }" (wheel)="onWheel()">
	<div class="actions">
		<ul>
			<li *ngFor="let option of customOptions">
				<a [routerLink]="[]" *ngIf="!option.disabled()" (click)="option?.doAction()" [tooltip]="option.tooltip">
					<icon [name]="option.icon"></icon>
				</a>
			</li>
			<li *ngIf="listeBoundingBoxes?.length">
				<a [routerLink]="[]" (click)="resetListeBoundingBoxes();" [tooltip]="'imageEditor.actions.marqueurs.supprimer' | translate">
					<icon name="visibility_off"></icon>
				</a>
			</li>
			<li *ngIf="canRotate">
				<a [routerLink]="[]" (click)="rotate('LEFT');" [tooltip]="'imageEditor.actions.rotation.gauche' | translate">
					<icon name="rotate_left"></icon>
				</a>
			</li>
			<li *ngIf="canRotate">
				<a [routerLink]="[]" (click)="rotate('RIGHT');" [tooltip]="'imageEditor.actions.rotation.droite' | translate">
					<icon name="rotate_right"></icon>
				</a>
			</li>
			<li>
				<a [routerLink]="[]" (click)="zoom('IN');" [tooltip]="'imageEditor.actions.zoom.agrandir' | translate">
					<icon name="zoom_in"></icon>
				</a>
			</li>
			<li>
				<a [routerLink]="[]" (click)="zoom('OUT');" [tooltip]="'imageEditor.actions.zoom.reduire' | translate">
					<icon name="zoom_out"></icon>
				</a>
			</li>
			<li *ngIf="!consultation">
				<a [routerLink]="[]" (click)="crop()" [tooltip]="'imageEditor.actions.rogner' | translate">
					<icon name="check" class="c-green"></icon>
				</a>
			</li>
			<li [tooltip]="'actions.fermer' | translate" *ngIf="!inline">
				<a [routerLink]="[]" (click)="onClose.emit()">
					<icon name="close" class="c-red"></icon>
				</a>
			</li>
		</ul>
	</div>

	<img #imageEditor [src]="imageSource" [hidden]="true"/>
</div>