import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { isEqual } from 'lodash-es';

import { ReleveEnergieService } from './releve-energie.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'releve-energie',
	templateUrl: './releve-energie.component.html'
})
export class ReleveEnergieComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() releveEnergie: any;

	/** Gestion d'une modification de relevé d'énergie **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Copie du relevé d'énergie **/
	public releveEnergieCopie: any;

	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private releveEnergieService: ReleveEnergieService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du relevé d'énergie
		this.releveEnergie = Object.assign({},this.releveEnergie);

		//Copie du relevé d'énergie
		this.releveEnergieCopie = Object.assign({},this.releveEnergie);

		//Vérification de l'identifiant du relevé d'énergie
		if (this.releveEnergie.idReleve) {
			//Chargement du relevé d'énergie
			this.releveEnergieService.loadReleveEnergie(this.releveEnergie.idReleve).subscribe(result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === 0) {
					//Mise à jour du relevé d'énergie
					Object.assign(this.releveEnergie,result.data.releveEnergie);

					//Copie du relevé d'énergie
					Object.assign(this.releveEnergieCopie,this.releveEnergie);
				}
			});
		}
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture du formulaire après enregistrement
	 */
	onContentClose(releveEnergie) {
		//Mise à jour ou suppression du relevé d'énergie
		this.onObjectChange.emit(releveEnergie || null);
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Vérification de la modification du relevé d'énergie
	 */
	isDirty() {
		//Vérification de la modification du relevé d'énergie
		return !isEqual(this.releveEnergie,this.releveEnergieCopie);
	}
}