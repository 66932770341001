<div class="pull-left lv-avatar">{{ data.facture.fournisseur.reference.substring(0,3) }}</div>
<div class="media-body">
	<div class="lv-title">
		<span>{{ data.nature?.libelle || data.libelleNature || data.codeFournisseur?.libelle }}</span>&#160;({{ data.montant | currency:'.2-2':data.devise }})
	</div>
	<ul class="lv-attrs">
		<li><span translate>contrat.financement.facture.date</span>&#160;:&#160;{{ data.date | date:'shortDate' }}</li>
		<li><span translate>contrat.financement.facture.montantHorsTaxe</span>&#160;:&#160;{{ data.montantHorsTaxe | currency:'.2-2':data.devise }}</li>
		<li><span translate>contrat.financement.facture.fournisseur</span>&#160;:&#160;{{ data.facture.fournisseur.libelle }}</li>
		<li><span translate>contrat.financement.facture.reference</span>&#160;:&#160;{{ ('facture.type.' + data.facture.type | translate) + ' ' + data.facture.reference }}</li>
		<li *ngIf="data.lien?.objet?.idEquipement"><span translate>contrat.financement.facture.equipement</span>&#160;:&#160;{{ data.lien.objet.reference }}</li>
	</ul>
</div>