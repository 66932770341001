import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ClientCRMListComponent } from './client-crm-list.component';
import { ClientCRMListItemComponent } from './client-crm-list-item.component';
import { ClientCRMService } from './client-crm.service';
import { ClientCRM } from './client-crm.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ClientCRMListComponent,ClientCRMListItemComponent,ClientCRM],
	providers: [ClientCRMService]
})
export class ClientCRMModule {

}