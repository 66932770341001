import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { MenuItem } from 'src/app/domain/menu/menu';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardFavorisService } from './dashboard-favoris.service';

@Component({
	templateUrl: './dashboard-favoris.component.html'
})
export class DashboardFavorisComponent implements OnInit {
	/** Liste des favoris **/
	listeFavoris: Array<any>;

	/** Liste des favoris sélectionnés **/
	listeSelectedFavoris: Array<string>;

	/** Liste des éléments du menu **/
	listeMenuItems: Array<MenuItem>;

	/** Liste des menus ouverts **/
	listeOpenedMenuItems: Array<MenuItem> = [];

	/** Indicateur de changement des favoris **/
	hasChanged: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private menuService: MenuService,public bsModalRef: BsModalRef<DashboardFavorisComponent>,private rightService: RightService,private dashboardFavorisService: DashboardFavorisService,private toastrService: ToastrService,private translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération du menu
		this.listeMenuItems = this.menuService.filterStructureMenu();

		//Définition de la liste des favoris sélectionnées
		this.listeSelectedFavoris = this.listeFavoris.map(f => f.state);
	}

	/**
	 * Récupération de la liste des éléments du menu filtrés
	 */
	getListeFilteredMenuItems(listeMenuItems: Array<MenuItem>): Array<MenuItem> {
		//Vérification de la présence d'un libellé
		return listeMenuItems.filter(i => i.libelle && !i.url);
	}

	/**
	 * Ouverture d'un menu
	 */
	openMenuItem(menuItem: MenuItem) {
		//Ajout à la liste des menu ouverts
		this.listeOpenedMenuItems.push(menuItem);
	}

	/**
	 * Gestion du retour arrière
	 */
	goBackTo(index) {
		//Retour sur la liste des menus ouverts au niveau choisi
		this.listeOpenedMenuItems.length = index;
	}

	/**
	 * Récupération de la liste des éléments du menu visibles
	 */
	getListeVisibleMenuItems(menuItem: MenuItem): Array<MenuItem> {
		//Vérification de la présence d'un libellé
		return (menuItem.listeItems || Array.isArray(menuItem.creationState) && (menuItem.creationState as Array<MenuItem>) || []).filter(i => (i.creationState && typeof i.creationState == 'string' || i.listeItems || Array.isArray(i.creationState)) && this.checkRightForCreation(i));
	}

	/**
	 * Sélection d'un favoris
	 */
	selectFavoris(menuItem: MenuItem) {
		let idxFavoris: number;

		//Recherche du favoris
		idxFavoris = this.listeSelectedFavoris.indexOf(menuItem.creationState as string);

		//Enregistrement ou suppression du favoris
		this.dashboardFavorisService.saveFavorisCreation({
			libelle: this.translateService.instant(menuItem.libelle),
			state: menuItem.creationState
		},idxFavoris != -1).pipe(first()).subscribe({
			next: result => {
				//Modification effectuée
				this.hasChanged = true;

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Vérification de l'existence du favoris
					if (idxFavoris != -1)
						//Retrait du favoris
						this.listeSelectedFavoris.splice(idxFavoris,1);
					else
						//Ajout du favoris
						this.listeSelectedFavoris.push(menuItem.creationState as string);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		})
	}

	/**
	 * Création d'un élément
	 */
	doCreation(menuItem: MenuItem) {
		//Fermeture de la fenêtre
		this.bsModalRef.hide();

		//Déclenchement de la création
		this.dashboardFavorisService.doActionForFavoris({
			libelle: this.translateService.instant(menuItem.libelle),
			state: menuItem.creationState
		});
	}

	/**
	* Vérification des droits de création
	*/
	private checkRightForCreation(menuItem: MenuItem) {
		//Vérification des droits
		if (menuItem.listeRights && !menuItem.listeItems)
			//Vérification des droits
			return this.rightService.hasRight(typeof menuItem.listeRights[0] == 'string' && menuItem.listeRights[0] || (menuItem.listeRights[0] as any).right,'creation');
		else if (menuItem.listeItems?.length)
			//Appel récursif
			return menuItem.listeItems.some(i => (i.creationState && typeof i.creationState == 'string' || i.listeItems || Array.isArray(i.creationState)) && this.checkRightForCreation(i));
		else if (Array.isArray(menuItem.creationState))
			//Appel récursif
			return menuItem.creationState.some(i => i.creationState && typeof i.creationState == 'string' && this.checkRightForCreation(i));
		else
			//Aucune création accordée
			return false;
	}
}