import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'motif-selection',
	templateUrl: './motif-selection.component.html'
})
export class MotifSelectionComponent implements AfterContentChecked {
	/** Entité **/
	@Input() entite: string;

	/** Données **/
	data: { motif: any,description: string } = {
		motif: null,
		description: null
	}

	/** Résultat **/
	result: { isSelected: boolean,motif: any,description?: string };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<MotifSelectionComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la modal
	 */
	close() {
		//Alimentation du résultat
		this.result = {
			isSelected: true,
			motif: this.data.motif,
			description: this.data.description
		}

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}