import { NgModule } from '@angular/core';

import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { AutocompleteSearchItemComponent } from './autocomplete-search-item.component';
import { AutocompleteSearchComponent } from './autocomplete-search.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,IconModule],
	declarations: [AutocompleteSearchComponent,AutocompleteSearchItemComponent]
})
export class AutocompleteSearchModule {}