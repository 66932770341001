import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap,take } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LoginService } from 'src/app/share/login/login.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { mapEntites } from './tenant.entites';
import { TenantService } from './tenant.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	templateUrl: './tenant-list-item.component.html'
})
export class TenantListItemComponent extends ListViewItem<any> implements OnInit,IListEntity {
	/** Tenant courant **/
	data: any;

	/** Utilisateur courant **/
	user: User;

	/** Utilisateur d'origine **/
	userOrigine: User;

	/**
	 * Constructeur
	 */
	constructor(private historyService: HistoryService,private mailHistoryService: MailHistoryService,public notificationService: NotificationService
			,private translateService: TranslateService,public rightService: RightService,private store: Store<AppState>,private loginService: LoginService
			,private confirmService: ConfirmService,private tenantService: TenantService,private toastrService: ToastrService,private loggedUserService: LoggedUserService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session.user,userOrigine: state.session.userOrigine })).pipe(take(1)).subscribe(({ user,userOrigine }) => {
			//Mise à jour des utilisateurs
			this.user = user;
			this.userOrigine = userOrigine;
		});
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idTenant,object?.idTenant && !this.data?.idTenant);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.tenant.Tenant',this.data.idTenant);
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.tenant.Tenant',this.data.idTenant);
	}

	/**
	 * Vérification de la possibilité de se connecter à la place du tenant
	 */
	canConnectAs(): boolean {
		//Vérification de la possibilité de se connecter à la place du tenant
		return (!this.userOrigine || this.user.tenant.type == 'REVENDEUR' && ['ADMINISTRATEUR','UTILISATEUR_AUTO'].indexOf(this.user.type) > -1)
			&& this.rightService.hasRight(TypeDroit.ADMIN_ENVIRONNEMENT,'consultation')
			&& this.data.idTenant != this.user.tenant.idTenant
			&& this.data.adminEmail?.length;
	}

	/**
	 * Connexion à la place du tenant
	 */
	loginAsAdminTenant() {
		//Connexion en tant qu'administrateur pour le tenant
		this.loginService.loginAsAdminTenant(this.data);
	}

	/**
	 * Suppression du tenant
	 */
	deleteTenant() {
		//Affichage du message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('tenant.suppression.title'),{
			withTyping: true,
			confirmationTerm: this.translateService.instant('tenant.suppression.confirmationTerm'),
			alerteInfo: `
				<i class="material-icons-round">warning</i>
				<div>
					<strong>${this.translateService.instant('tenant.suppression.attention.1')}</strong>
					<div>${this.translateService.instant('tenant.suppression.attention.2')}</div>
					<div>${this.translateService.instant('tenant.suppression.attention.3',{ confirmationTerm: this.translateService.instant('tenant.suppression.confirmationTerm') })}</div>
				</div>
			`
		}).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Exécution de la suppression des données
				this.tenantService.deleteTenant(this.data).subscribe({
					next: result => {
						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.suppression.success'));

							//Rafraichissement de la liste
							this.liste.refresh();
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					}
				});
			}
		});
	}

	/**
	 * Clôture du tenant
	 */
	closeTenant() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('tenant.cloture.title'),{
			withTyping: true,
			confirmationTerm: this.translateService.instant('tenant.cloture.confirmationTerm'),
			alerteInfo: `
				<i class="material-icons-round">warning</i>
				<div>
					<strong>${this.translateService.instant('tenant.cloture.attention.1')}</strong>
					<div>${this.translateService.instant('tenant.cloture.attention.2')}</div>
					<div>${this.translateService.instant('tenant.cloture.attention.3',{ confirmationTerm: this.translateService.instant('tenant.cloture.confirmationTerm') })}</div>
				</div>
			`
		}).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.closeTenant(this.data)),
			first()
		).subscribe((closureResult) => {
			//Vérification du code d'erreur
			if (closureResult?.result?.data?.tenant) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.cloture.success'));

				//Rafraichissement de la liste
				this.liste.refresh();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.cloture.error'));
		});
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.tenant.Tenant',this.data.idTenant,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.TENANT
		});
	}

	/**
	 * Vérification de la présence de restriction client sur le profil utilisateur
	 */
	public hasRestrictionClient(): boolean {
		//Vérification de l'existence d'un profil avec restriction client pour l'utilisateur
		return this.loggedUserService.hasProfilRestriction(TypeRestriction.CLIENT);
	}
}