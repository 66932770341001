import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class GrilleAttributionModeleService {
	/** Liste des types d'affichage **/
	private readonly listeTypesAffichagePrix: Array<any> = ['TTC','HT'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Chargement d'un modèle de la grille d'attribution
	 */
	public loadGrilleAttributionModele(idGrilleAttribution: number,idModele: number): Observable<Result> {
		//Chargement d'un modèle de la grille d'attribution
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadGrilleAttributionModele/${idGrilleAttribution}/${idModele}`,null);
	}

	/**
	 * Suppression d'une remise du modèle de la grille d'attribution
	 */
	public deleteRemise(remise: any): Observable<Result> {
		//Suppression d'une remise du modèle de la grille d'attribution
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteGrilleAttributionModeleRemise/${remise.idRemise}`);
	}

	/**
	 * Enregistrement d'une remise d'un modèle de la grille d'attribution
	 */
	public saveRemise(remise: any): Observable<Result> {
		//Enregistrement d'une remise d'un modèle de la grille d'attribution
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveGrilleAttributionModeleRemise`,remise);
	}

	/**
	 * Chargement d'un financement d'un modèle de la grille d'attribution
	 */
	public loadFinancement(idFinancement: number): Observable<Result> {
		//Chargement d'un financement d'un modèle de la grille d'attribution
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadGrilleAttributionModeleFinancement/${idFinancement}`,null);
	}

	/**
	 * Enregistrement du financement d'un modèle de la grille d'attribution
	 */
	public saveFinancement(financement: any): Observable<Result> {
		//Enregistrement du financement d'un modèle de la grille d'attribution
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveGrilleAttributionModeleFinancement`,financement);
	}

	/**
	 * Suppression d'un financement du modèle de la grille d'attribution
	 */
	public deleteFinancement(financement: any): Observable<Result> {
		//Suppression d'un financement du modèle de la grille d'attribution
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteGrilleAttributionModeleFinancement/${financement.idFinancement}`);
	}

	/**
	 * Récupération de la liste des types d'affichage du prix
	 */
	public getListeTypesAffichagePrix(): Array<{ code: string,libelle: string }> {
		//Liste des types d'affichage du prix
		return this.listeTypesAffichagePrix.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.grilleAttribution.modele.modeAffichagePrix.${code}.libelle`)
		}));
	}
}