<div class="container-fluid">
	<form #contraventionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="card full">
				<div class="card-body card-padding">
					<div class="row" *ngIf="contravention.contraventionOrigine != null && (!savedContravention?.contraventionOrigine || contravention.contraventionOrigine.idContravention != savedContravention.contraventionOrigine.idContravention || contravention.statut != savedContravention.statut)">
						<div class="col-sm-12">
							<div class="alert alert-info">
								<icon name="info"></icon>
								<strong translate [translateParams]="{ statut: ('infraction.contravention.statut.'+contravention.statut | translate) }">infraction.contravention.alerte.changementStatutMajoration</strong>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
								<label translate>infraction.contravention.type.item</label>
								<div>
									<selectpicker name="type" #type="ngModel" [(ngModel)]="contravention.type" (ngModelChange)="onTypeChange()" required>
										<mat-option *ngFor="let type of listeTypesInfraction" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>infraction.contravention.reference</label>
								<div>
									<div class="input-group">
										<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="contravention.reference" pattern="[a-zA-Z0-9]*" required/>
										<div class="input-group-addon">
											<icon name="info_outline" [tooltip]="'infraction.contravention.tooltip.autorisation' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
								<label translate>infraction.contravention.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="contravention.pays" (onSelect)="onPaysChange()" required></autocomplete>
								</div>
							</div>
							<div class="form-group" *ngIf="!contravention.majoree">
								<label translate>infraction.contravention.majoration</label>
								<div>
									<mat-checkbox name="majoration" [(ngModel)]="contravention.majoration" (change)="contravention.contraventionOrigine = null" [disabled]="contravention.majoree"><span translate>infraction.contravention.avisMajore</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': contraventionOrigine.invalid }" *ngIf="contravention.majoration && contravention.type">
								<label translate>infraction.contravention.contraventionOrigine</label>
								<div>
									<autocomplete name="contraventionOrigine" type="contravention" [filter]="{ majoration: false,type: contravention.type,majoree: false,idContraventionExcluded: contravention.idContravention }" #contraventionOrigine="ngModel" [(ngModel)]="contravention.contraventionOrigine" (onSelect)="onContraventionOrigineChange()" (onReset)="onContraventionOrigineChange()" required></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>infraction.contravention.etat</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'infraction.contravention.etatInfo' | translate" *ngIf="contravention.majoree"></icon>
								</label>
								<div class="half">
									<selectpicker name="statut" #type="ngModel" [(ngModel)]="contravention.statut" [disabled]="contravention.majoree" required>
										<mat-option *ngFor="let statut of listeStatuts" [value]="statut.code">{{ statut.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateAvis.invalid }">
								<label translate>infraction.contravention.dateAvis</label>
								<div class="half">
									<div class="input-group">
										<input type="text" name="dateAvis" class="form-control" #dateAvis="ngModel" [(ngModel)]="contravention.dateAvis" datePicker maxDate="today" required/>
										<div class="input-group">
											<icon name="warning" *ngIf="!isDateAvisValid()" [tooltip]="'infraction.contravention.tooltip.dateAvisAnterieure' | translate"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateMajoration.invalid }">
								<label translate>infraction.contravention.dateMajoration</label>
								<div class="half">
									<input type="text" name="dateMajoration" class="form-control" #dateMajoration="ngModel" [(ngModel)]="contravention.dateMajoration" datePicker [minDate]="contravention.dateContravention" [storeDate]="true" required/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card full">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': montant.invalid }">
								<label translate>infraction.contravention.montant</label>
								<div>
									<amount name="montant" #montant="ngModel" [(ngModel)]="contravention.montantContravention" [devise]="contravention.devise" (onDeviseChange)="contravention.devise = $event;" required></amount>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateContravention.invalid }">
								<label translate>infraction.contravention.dateConstatation</label>
								<div class="half">
									<input type="text" name="dateContravention" class="form-control" #dateContravention="ngModel" [(ngModel)]="contravention.dateContravention" datePicker format="datetime" maxDate="today" required [disabled]="contravention.majoration"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': contraventionForm.controls['vehicule']?.invalid }">
								<label translate>infraction.contravention.vehicule</label>
								<div>
									<ng-container *ngIf="!contravention.vehicule">
										<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" [(ngModel)]="contravention.vehicule" [disabled]="contravention.majoration" required></autocomplete>
									</ng-container>
									<div *ngIf="contravention.vehicule">
										<mat-chip-listbox>
											<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="vehiculeService.goToVehicule(contravention.vehicule)">
												{{ contravention.vehicule | display:'vehicule' }}
												<button matChipRemove *ngIf="(null | right:'creation') && !contravention.majoration" (click)="contravention.vehicule = null">
													<mat-icon>cancel</mat-icon>
												</button>
											</mat-chip>
											<span>
												<icon name="replay_30" class="icon-hc-2x clickable" *ngIf="contravention.vehicule?.listeOldReferences?.length" (click)="vehiculeService.showListeImmatriculations(contravention.vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate"></icon>
											</span>
										</mat-chip-listbox>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label translate>infraction.contravention.lieu</label>
								<div>
									<adresse name="lieu" #lieu="ngModel" [(ngModel)]="contravention.lieu" [withCoords]="true" [disabled]="contravention.majoration"></adresse>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': !contravention.motif }" *ngIf="contravention.type == 'AMENDE' && contravention.pays">
						<label translate>infraction.contravention.motif</label>
						<div>
							<a [routerLink]="[]" (click)="showMotifSelection();">
								<span *ngIf="!contravention.motif" translate>infraction.contravention.actions.selectionnerMotif</span>
								<span *ngIf="contravention.motif">{{ contravention.motif | display:'typeInfraction' }}</span>
							</a>
						</div>
					</div>
					<div class="form-group">
						<label translate>infraction.contravention.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" [(ngModel)]="contravention.remarque" rows="3"></textarea>
						</div>
					</div>
				</div>
			</div>

			<div class="card full">
				<data-customization entite="com.notilus.data.vehicule.Contravention" [object]="contravention" [disabled]="!(null | right:'creation')"></data-customization>
			</div>
		</fieldset>
		<div class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteContravention();" *ngIf="contravention?.idContravention && (null | right:'suppression') && !contravention.majoree">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="contraventionForm.invalid || contravention.type == 'AMENDE' && !contravention.motif" (click)="saveContravention(contravention,false,close);" *ngIf="null | right:'creation'">
				<span [translate]="'actions.' + (contravention?.idContravention ? 'enregistrer' : 'continuer')"></span>
			</button>
		</div>
		<button mat-button *ngIf="contravention?.idContravention" [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>