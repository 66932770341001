import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CodeListItemComponent } from './code-list-item.component';
import { CodeListComponent } from './code-list.component';
import { CodeComponent } from './code.component';
import { CodeService } from './code.service';

@NgModule({
	imports: [ShareModule],
	declarations: [CodeListItemComponent,CodeComponent,CodeListComponent],
	providers: [CodeService]
})
export class CodeModule { }
