<div class="math">
	<ul class="fonction-detail z-depth-1">
		<li class="header">
			<div *ngIf="(null | right:'creation') && !isRestricted" class="actions dropdown pull-right">
				<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li (click)="parent[parentKey] = null;">
						<a [routerLink]="[]" translate>actions.supprimer</a>
					</li>
				</ul>
			</div>
			<h4>
				<span class="fonction-label head">
					<span [translate]="'structureExport.rubrique.typeRubrique.FONCTION.MATH.'+detail.fonction.operateur+'.item'"></span>
				</span>
			</h4>
		</li>
		<li class="content">
			<ul>
				<li>
					<div class="fonction-item text-left" *ngIf="!detail.fonction.field1 && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: detail.fonction,parentKey: 'field1' })" translate>common.aDefinir</a></div>
					<div class="fonction-item" *ngIf="!detail.fonction.field1 && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
					<div *ngIf="detail.fonction.field1" class="rubrique-detail">
						<structure-export-rubrique-detail [detail]="detail.fonction.field1" [parent]="detail.fonction" [parentKey]="'field1'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
					</div>
				</li>
				<li>
					<h4>
						<span class="fonction-label">
							<span [translate]="'structureExport.fonction.math.operateur.'+detail.fonction.operateur"></span>
						</span>
					</h4>
				</li>
				<li>
					<div class="fonction-item text-left" *ngIf="!detail.fonction.field2 && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: detail.fonction,parentKey: 'field2' })" translate>common.aDefinir</a></div>
					<div class="fonction-item" *ngIf="!detail.fonction.field2 && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
					<div *ngIf="detail.fonction.field2" class="rubrique-detail">
						<structure-export-rubrique-detail [detail]="detail.fonction.field2" [parent]="detail.fonction" [parentKey]="'field2'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>