import { Subject } from 'rxjs';

import { Filter,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeListeValidation,ValidationEntiteOptions } from 'src/app/domain/workflow/validation';
import { listeStatuts } from 'src/app/domain/vehicule/demande-vehicule/demande-vehicule';
import { DemandeVehiculeService } from 'src/app/components/vehicule/commande/demande-vehicule/demande-vehicule.service';
import { Action,ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { WorkflowService } from 'src/app/components/workflow/workflow.service';
import { LayoutService } from 'src/app/share/layout/layout.service';


/**
 * Options de validation des demandes de véhicule
 */
export class DemandeVehiculeOptions extends ValidationEntiteOptions {
	/**
	 * Type
	 */
	type = 'DEMANDE_VEHICULE';

	/**
	 * Entité
	 */
	entite = 'com.notilus.data.vehicule.cotation.DemandeVehicule';

	/**
	 * Ordre de tri
	 */
	defaultOrder = '-idDemandeVehicule';

	/**
	 * Titre
	 */
	getTitle = (typeListe: TypeListeValidation) => {
		//Titre
		return this.translateService.instant(`workflow.validation.demandeVehicule.title.${typeListe}`)
	}

	/**
	 * Récupération de l'URL
	 */
	getUri = (typeListe: TypeListeValidation) => {
		//URL
		return typeListe == TypeListeValidation.A_VALIDER ? '/controller/VehiculeCommande/filtreDemandesVehiculeForValidation' : '/controller/VehiculeCommande/filtreDemandesVehiculeValidees';
	}

	/**
	 * Filtres disponibles
	 */
	getListeFilters = (): Array<Filter> => {
		//Retour de la liste des filtres
		return [{
			clef: 'user',
			isDefault: true,
			type: TypeFilter.AUTOCOMPLETE,
			autocomplete: {
				type: 'user',
				fieldForDefault: '*nom,*prenom'
			}
		},{
			clef: 'service',
			isDefault: true,
			type: TypeFilter.AUTOCOMPLETE,
			autocomplete: {
				type: 'service',
				fieldForDefault: '*reference,*libelle'
			}
		}];
	}

	/**
	 * Récupération de la liste des filtres statiques
	 */
	getListeStaticFilters?: (filter?: any) => Array<Filter>;

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName = () => 'idDemandeVehicule';


	/**
	 * Récupération de l'avatar pour un élément de la liste
	 */
	getListeItemAvatar = (item: any) => {
		//Récupération de l'avatar pour un élément de la liste
		return listeStatuts.filter(statut => statut.code == item.statut).map(s => ({ icon: s.icone,iconType: s.iconeType }))?.[0];
	}

	/**
	 * Récupération du titre pour un élément de la liste
	 */
	getListeItemTitle = (item: any) => {
		let title: string = '';

		//Définition du libellé de base
		title = this.translateService.instant('demandeVehicule.liste.item',{ numero: item.idDemandeVehicule }) + ' - ';

		//Vérification du bénéficiaire
		if (item.user)
			//Ajout du collaborateur
			title += `${item.user.prenom} ${item.user.nom} (${item.user.matricule + (item.user.societe ? ' - ' + item.user.societe.libelle : '')})`;
		else if (!item.user && item.service)
			//Ajout du service
			title += `${item.service.libelle} (${item.societe.libelle})`;
		else if (!item.user && !item.service && item.societe)
			//Ajout de la société
			title += item.societe.libelle;
		else if (!item.user && !item.service && !item.societe)
			//Aucun bénéficiaire
			title += this.translateService.instant('demandeVehicule.beneficiaire.aucun');

		return title;
	}

	/**
	 * Récupération de la liste des attributs
	 */
	getListeItemAttributes = (item: any): Array<{ key: string,value: string }> => {
		//Retour de la liste des attributs
		return [item.workflowHistory && {
			key: this.translateService.instant('demandeVehicule.dateDerniereValidation'),
			value: this.datePipe.transform(item.workflowHistory.date,'shortDate')
		},{
			key: this.translateService.instant('demandeVehicule.statut.item'),
			value: this.translateService.instant(`demandeVehicule.statut.${item.statut}`)
		},{
			key: this.translateService.instant('demandeVehicule.dateCreation'),
			value: this.datePipe.transform(item.dateCreation,'shortDate')
		},item.vehiculeSource && {
			key: this.translateService.instant('demandeVehicule.vehiculeSource'),
			value: item.vehiculeSource.reference + (item.vehiculeSource.numeroInterne ? ` - ${item.vehiculeSource.numeroInterne}` : '') + ' (' + (item.vehiculeSource.modele ? `${item.vehiculeSource.modele.marque.libelle} ${item.vehiculeSource.modele.libelle}` : this.translateService.instant('demandeVehicule.modele.nonDefini')) + ')'
		}].filter(i => !!i);
	}

	/**
	 * Réalisation d'une action pour un élément
	 */
	doAction = (item: any,action: 'VALIDER' | 'REJETER',onActionFinished: Subject<any>) => {
		//Réalisation de l'action Workflow
		this.injector.get(DemandeVehiculeService).doAction(action,item).subscribe({
			next: () => {
				//Emission de la fin de l'action
				onActionFinished.next(null);
			}
		});
	}

	/**
	 * Récupération de la liste des actions
	 */
	getListeActions = (typeListe: TypeListeValidation,onActionFinished: Subject<any>): Array<Action> => {
		let listeActions: Array<Action> = [];

		//Vérification du type de liste
		if (typeListe == TypeListeValidation.A_VALIDER) {
			//Ajout de l'action de validation
			listeActions.push({
				icon: 'check_circle',
				title: this.translateService.instant('demandeVehicule.liste.validation'),
				messagingOptions: {
					entryPoint: 'controller/VehiculeCommande/updateListeDemandesVehicule/VALIDER'
				},
				onPress: (_,messagingOptions) => this.updateListeDemandesVehicule(messagingOptions).subscribe({
					//Emission d'une valeur pour rafraichir la liste
					complete: () => onActionFinished.next(null)
				})
			});

			//Ajout de l'action de rejet
			listeActions.push({
				icon: 'cancel',
				title: this.translateService.instant('demandeVehicule.liste.rejet'),
				messagingOptions: {
					entryPoint: 'controller/VehiculeCommande/updateListeDemandesVehicule/REJETER'
				},
				onPress: (_,messagingOptions) => {
					let actionMasse: ActionMasse;

					//Récupération de l'action de masse
					actionMasse = messagingOptions.params as ActionMasse;

					//Sélection d'un motif de rejet
					this.injector.get(WorkflowService).selectWorkflowMotif(this.entite).subscribe({
						next: ({ isMotifAvailable,isCancelled,workflowMotif }) => {
							//Alimentation des données
							actionMasse.data = workflowMotif != null ? Object.assign(workflowMotif,{
								_type: 'com.notilus.data.workflow.history.WorkflowHistoryMotif'
							}) : undefined;

							//Déclenchement du traitement
							(!isMotifAvailable || !isCancelled) && this.updateListeDemandesVehicule(messagingOptions).subscribe({
								//Emission d'une valeur pour rafraichir la liste
								complete: () => onActionFinished.next(null)
							});
						}
					});
				}
			});
		}

		//Retour de la liste des actions
		return listeActions;
	}

	/**
	 * Sélection d'un élément de la liste
	 */
	onClick = (typeListe: TypeListeValidation,item: any): void => {
		//Redirection vers la demande de véhicule
		this.injector.get(LayoutService).goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
			routeParams: {
				idDemandeVehicule: item.idDemandeVehicule,
				typeListe
			},
			withGoBack: true,
			goBackParams: {
				queryParams: {
					typeListe
				}
			}
		});
	}


	/**
	 * Déclenchement d'un traitement en masse
	 */
	private updateListeDemandesVehicule(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init(messagingOptions).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		//Retour du sujet
		return subject;
	}
}