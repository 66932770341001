import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { RegleAutomatiqueCheckResultatComponent } from './regle-automatique-check-resultat.component';
import { RegleAutomatiqueCheckSelectionComponent } from './regle-automatique-check-selection.component';
import { RegleAutomatiqueCheckComponent } from './regle-automatique-check.component';
import { RegleAutomatiqueEditComponent } from './regle-automatique-edit.component';
import { RegleAutomatiqueListItemComponent } from './regle-automatique-list-item.component';
import { RegleAutomatiqueListComponent } from './regle-automatique-list.component';
import { RegleAutomatiqueRuleComponent } from './regle-automatique-rule.component';
import { RegleAutomatiqueComponent } from './regle-automatique.component';
import { RegleAutomatiqueService } from './regle-automatique.service';

@NgModule({
	imports: [ShareModule],
	declarations: [RegleAutomatiqueListComponent,RegleAutomatiqueListItemComponent,RegleAutomatiqueComponent,RegleAutomatiqueEditComponent,RegleAutomatiqueRuleComponent,RegleAutomatiqueCheckComponent,RegleAutomatiqueCheckSelectionComponent,RegleAutomatiqueCheckResultatComponent],
	providers: [RegleAutomatiqueService]
})
export class RegleAutomatiqueModule { }