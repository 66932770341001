import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PosteChargeCompteEditComponent } from '../../comptabilite/poste-charge/poste-charge-compte-edit.component';
import { NatureCodeListComponent } from './nature-code-list.component';
import { NatureCompteListComponent } from './nature-compte-list.component';
import { NatureEditComponent } from './nature-edit.component';
import { NatureFieldListComponent } from './nature-field-list.component';
import { NatureFournisseurListComponent } from './nature-fournisseur-list.component';
import { NatureService } from './nature.service';

@Component({
	selector: 'nature',
	templateUrl: './nature.component.html'
})
export class NatureComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Nature courante **/
	public nature: any;

	/** Résumé **/
	private resume: { nbCodes: number,nbComptes: number,nbComptesTaxe: number, nbFournisseurs: number } = {
		nbCodes: 0,
		nbComptes: 0,
		nbComptesTaxe: 0,
		nbFournisseurs: 0
	}

	/** Association des pays avec leur règle de récupération de taxe standard pour la nature **/
	public mapReglesTaxeForPays: { [idPays: number]: Array<any> } = {};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Liste des familles de nature **/
	public listeTypesFamille: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(private natureService: NatureService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService
			,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private displayPipe: DisplayPipe,private bsModalService: BsModalService) {

	}

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.fournisseur.FournisseurNature';

	/** Récupération de l'identifiant de la nature à charger **/
	getIdObject: () => number = () => this.nature?.idNature || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération des types de famille de nature
		this.listeTypesFamille = this.natureService.getListeTypesFamille();

		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'nature.identifiant',
				key: 'idNature'
			},{
				libelle: 'nature.libelle',
				key: 'libelle'
			},{
				libelle: 'common.actif',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editNature(),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.rightService.isRoot(),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editNature(),
				isVisible: () => !this.rightService.hasRight(null,'creation') || !this.rightService.isRoot(),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteNature(),
				isVisible: () => this.rightService.hasRight(null,'suppression') && this.rightService.isRoot(),
				type: TypeAction.SUPPRESSION
			}],
			listeAlertes: [{
				icon: 'n-icon-compte',
				iconType: 'icon-venus',
				title: this.translateService.instant(`nature.alerte.charge.title.${this.rightService.isRoot() ? 'admin' : 'client'}`),
				message: this.translateService.instant('nature.alerte.charge.message'),
				isVisible: () => !this.resume?.nbComptes && !this.pageContentService.isOpened(),
				doAction: () => this.addCompte(null,null,{ type: 'CHARGE' }),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			},{
				icon: 'n-icon-recuperation-tva',
				iconType: 'icon-venus',
				title: this.translateService.instant(`nature.alerte.taxe.title.${this.rightService.isRoot() ? 'admin' : 'client'}`),
				message: this.translateService.instant('nature.alerte.taxe.message'),
				isVisible: () => !this.resume?.nbComptesTaxe && !this.pageContentService.isOpened(),
				doAction: () => this.addCompte(null,null,{ type: 'TAXE' }),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			}],
			listeElements: [{
				type: 'FIELD',
				libelle: 'nature.elements.field',
				component: NatureFieldListComponent,
				retrieveComponentData: () => ({
					nature: this.nature,
					addField: this.addField.bind(this)
				}),
				doAction: () => this.addField(),
				count: () => this.nature?.listeFields?.filter(field => field.actif)?.length || 0,
				libelleAction: this.translateService.instant('nature.elements.field.ajouter'),
				isVisible: () => this.rightService.isRoot(),
				isActionVisible: () => this.rightService.isRoot() && this.rightService.hasRight(null,'creation')
			},{
				type: 'FOURNISSEUR',
				libelle: 'nature.elements.fournisseur',
				component: NatureFournisseurListComponent,
				retrieveComponentData: () => ({
					nature: this.nature
				}),
				count: () => this.resume.nbFournisseurs,
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR,'consultation'),
				isActionVisible: () => false
			},{
				type: 'FOURNISSEUR_CODE',
				libelle: 'nature.elements.code',
				component: NatureCodeListComponent,
				retrieveComponentData: () => ({
					nature: this.nature
				}),
				count: () => this.resume.nbCodes,
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,'creation'),
				isActionVisible: () => false
			},{
				type: 'CHARGE',
				libelle: 'nature.elements.compte.charge',
				component: NatureCompteListComponent,
				retrieveComponentData: () => ({
					nature: this.nature,
					resume: this.resume,
					type: 'CHARGE',
					addCompte: this.addCompte.bind(this)
				}),
				doAction: () => this.addCompte(null,null,{ type: 'CHARGE' }),
				libelleAction: this.translateService.instant('nature.elements.compte.charge.ajouter'),
				count: () => this.resume.nbComptes,
				isActionVisible: () =>  this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			},{
				type: 'TAXE',
				libelle: 'nature.elements.compte.taxe',
				component: NatureCompteListComponent,
				retrieveComponentData: () => ({
					nature: this.nature,
					resume: this.resume,
					type: 'TAXE',
					addCompte: this.addCompte.bind(this),
					mapReglesTaxeForPays: this.mapReglesTaxeForPays
				}),
				doAction: () => this.addCompte(null,null,{ type: 'TAXE' }),
				libelleAction: this.translateService.instant('nature.elements.compte.taxe.ajouter'),
				count: () => this.resume.nbComptesTaxe,
				isActionVisible: () =>  this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			}]
		};

		//Récupération de l'identifiant de la nature à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idNature);
			}
		});
	}

	/**
	 * Edition de la nature
	 */
	private editNature() {
		//Ouverture du composant d'édition
		this.pageContentService.open(NatureEditComponent,{
			data: {
				nature: cloneDeep(this.nature),
				deleteNature: this.deleteNature.bind(this)
			}
		}).subscribe({
			next: (data: any) => {
				//Vérification des données
				if (data) {
					//Vérification des données
					if (data.nature) {
						//Mise à jour de la nature
						Object.assign(this.nature,data.nature);

						//Mise à jour de l'identifiant contenu dans l'URL
						this.layoutService.replaceUrlWith(this.nature);
					}

					//Vérification du nombre de fournisseurs
					if (data.nbFournisseurs)
						//Nombre de fournisseurs
						this.resume.nbFournisseurs = data.nbFournisseurs;
				}
			}
		});
	}

	/**
	 * Suppression de la nature
	 */
	private deleteNature() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.natureService.deleteNature(this.nature.idNature))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idNature: number) {
		//Chargement de la nature
		this.natureService.loadNature(idNature).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la nature
					this.nature = result.data?.nature || {
						_type: 'com.notilus.data.fournisseur.FournisseurNature',
						actif: true
					};

					//Définition du résumé
					this.resume = {
						nbCodes: result.data.nbCodes || 0,
						nbComptes: result.data.nbComptes || 0,
						nbComptesTaxe: result.data.nbComptesTaxe || 0,
						nbFournisseurs: result.data.nbFournisseurs || 0
					};

					//Définition du référentiel par pays des règles de récupération de taxe standard associées à la nature
					this.mapReglesTaxeForPays = result.data.mapReglesTaxeByIdPays;

					//Vérification de l'identifiant de la nature
					if (!this.nature?.idNature)
						//Edition de la nature
						this.editNature();
				}
			}
		});
	}

	/**
	 * Ajout/modification des champs
	 */
	protected addField(nature: any = this.nature) {
		//Ouverture du composant d'édition
		this.pageContentService.open(NatureFieldListComponent,{
			data: {
				nature: cloneDeep(nature),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data: { nature: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Mise à jour des champs
					Object.assign(this.nature,data.nature);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FIELD',true);
				}
			}
		});
	}

	/**
	 * Modification du compte
	 */
	private addCompte(lien: any,deleteLienCompteDetail?: Function,options?: { type: string }) {
		//Initialisation du lien si nécessaire
		lien = lien || {
			nature: { ...this.nature },
			type: 'NATURE'
		};

		//Ouverture du composant d'édition
		this.pageContentService.open(PosteChargeCompteEditComponent,{
			data: {
				lien: cloneDeep(lien),
				deleteLienCompteDetail,
				source: options?.type,
				mapReglesTaxeForPays: this.mapReglesTaxeForPays
			}
		},'sub').subscribe({
			next: (data: { lien: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType(options?.type,lien.idLien != data.lien?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lien?.idLien && data.lien?.idLien)
						//Incrémentation du compteur
						options?.type == 'TAXE' ? this.resume.nbComptesTaxe++ : this.resume.nbComptes++;
					else if (lien?.idLien && !data.lien?.idLien)
						//Décrémentation du compteur
						options?.type == 'TAXE' ? this.resume.nbComptesTaxe-- : this.resume.nbComptes--;

					//Mise à jour du lien
					Object.assign(lien,data.lien);
				}
			}
		});
	}
}