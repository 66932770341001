<form #releveEnergieForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!releveEnergie.isDisplayed">
		<div class="tile-sub">
			<releve-energie-content #releveEnergieContent [releveEnergie]="releveEnergie" source="RELEVE_CARBURANT" (close)="onContentClose($event)"></releve-energie-content>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="releveEnergieContent.deleteReleveEnergie();" *ngIf="(TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'suppression') && releveEnergie.idReleve && !releveEnergie.readOnly">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="releveEnergieContent.saveReleveEnergie();" *ngIf="TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'creation'" [disabled]="releveEnergieForm.invalid || releveEnergie.readOnly">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>