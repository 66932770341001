import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { StructureExportListItemComponent } from './structure-export-list-item.component';
import { StructureExportListComponent } from './structure-export-list.component';
import { StructureExportService } from './structure-export.service';
import { StructureExportComponent } from './structure-export.component';
import { StructureExportEditComponent } from './structure-export-edit.component';
import { StructureExportDefinitionComponent } from './structure-export-definition.component';
import { StructureExportDefinitionDetailComponent } from './structure-export-definition-detail.component';
import { StructureExportDefinitionLineComponent } from './structure-export-definition-line.component';
import { StructureExportFichierComponent } from './structure-export-fichier.component';
import { StructureExportFichierNomComponent } from './structure-export-fichier-nom.component';
import { StructureExportLineSelectionComponent } from './structure-export-line-selection.component';
import { StructureExportGroupeComponent } from './structure-export-groupe.component';
import { StructureExportCompteurComponent } from './structure-export-compteur.component';
import { StructureExportCompteurListComponent } from './structure-export-compteur-list.component';
import { StructureExportRegroupementListComponent } from './structure-export-regroupement-list.component';
import { StructureExportLineComponent } from './structure-export-line.component';
import { StructureExportLineItemComponent } from './structure-export-line-item.component';
import { StructureExportRubriqueComponent } from './structure-export-rubrique.component';
import { StructureExportRubriqueTypeSelectionComponent } from './structure-export-rubrique-type-selection.component';
import { StructureExportRubriqueDetailComponent } from './structure-export-rubrique-detail.component';
import { StructureExportRubriqueMoveComponent } from './structure-export-rubrique-move.component';
import { StructureExportRubriqueFonctionExtraireComponent } from './structure-export-rubrique-fonction-extraire.component';
import { StructureExportRubriqueFonctionRemplacerComponent } from './structure-export-rubrique-fonction-remplacer.component';
import { StructureExportRubriqueFonctionConcatenerComponent } from './structure-export-rubrique-fonction-concatener.component';
import { StructureExportRubriqueFonctionMathComponent } from './structure-export-rubrique-fonction-math.component';
import { StructureExportRubriqueFonctionSiComponent } from './structure-export-rubrique-fonction-si.component';
import { StructureExportRubriqueFonctionSiConditionComponent } from './structure-export-rubrique-fonction-si-condition.component';
import { StructureExportRubriqueFonctionSiConditionItemComponent } from './structure-export-rubrique-fonction-si-condition-item.component';
import { StructureExportRubriqueImportComponent } from './structure-export-rubrique-import.component';

@NgModule({
	imports: [ShareModule],
	declarations: [StructureExportListComponent,StructureExportListItemComponent,StructureExportComponent,StructureExportEditComponent,StructureExportDefinitionComponent,StructureExportDefinitionDetailComponent,StructureExportDefinitionLineComponent,StructureExportFichierComponent,StructureExportFichierNomComponent
		,StructureExportLineSelectionComponent,StructureExportGroupeComponent,StructureExportCompteurListComponent,StructureExportCompteurComponent,StructureExportRegroupementListComponent
		,StructureExportLineComponent,StructureExportLineItemComponent,StructureExportRubriqueComponent,StructureExportRubriqueTypeSelectionComponent,StructureExportRubriqueDetailComponent,StructureExportRubriqueMoveComponent,StructureExportRubriqueImportComponent
		,StructureExportRubriqueFonctionExtraireComponent,StructureExportRubriqueFonctionRemplacerComponent,StructureExportRubriqueFonctionConcatenerComponent,StructureExportRubriqueFonctionMathComponent,StructureExportRubriqueFonctionSiComponent,StructureExportRubriqueFonctionSiConditionComponent,StructureExportRubriqueFonctionSiConditionItemComponent
	],
	providers: [StructureExportService]
})
export class StructureExportModule { }