<div class="input-group">
	<input class="form-control" [(ngModel)]="searchedValue" typeaheadOptionField="_label" [typeahead]="suggestions$" [typeaheadAsync]="true" [placeholder]="placeholder" (typeaheadOnSelect)="onSuggestionSelect($event)" [optionsListTemplate]="listeItemsTemplate" (focus)="onFocus()" (blur)="onBlur($event)" (keydown)="reset()" [disabled]="disabled"/>
	<span class="input-group-addon clickable" (click)="showSearch()">
		<icon name="search"></icon>
	</span>
</div>
<ng-template #listeItemsTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
	<ul class="dropdown-menu">
		<li *ngFor="let match of matches" [class.active]="typeaheadTemplateMethods.isActive(match)" (click)="typeaheadTemplateMethods.selectMatch(match,$event); match?.item === '' && showCreation()" (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
			<a href="#" tabindex="-1" [class.add-item]="match?.item === ''">
				<autocomplete-line [item]="match.item" [options]="options" [query]="query"></autocomplete-line>
			</a>
		</li>
	</ul>
</ng-template>