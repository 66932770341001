import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter,first,switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

import { AppState } from 'src/app/domain/appstate';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { User } from 'src/app/domain/user/user';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { mapEntites } from './vehicule.entites';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { VehiculeService } from './vehicule.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	templateUrl: './vehicule-list-item.component.html'
})
export class VehiculeListItemComponent extends ListViewItem<any> implements OnInit,IListEntity {
	/** Date du jour **/
	today: moment.Moment = moment.utc();

	/** Utilisateur connecté **/
	user: User;

	/** Types de droit **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des configurations de véhicule **/
	listeConfigurations: Array<any>;

	/** Enumération des types de véhicule **/
	public TypeVehicule: typeof TypeVehicule = TypeVehicule;

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private store: Store<AppState>,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private historyService: HistoryService,private mailHistoryService: MailHistoryService,private notificationService: NotificationService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => this.user = user
		});

		//Récupération de la liste des configurations de véhicule
		this.vehiculeService.getListeConfigurations().subscribe({
			next: listeConfigurations => this.listeConfigurations = listeConfigurations
		});
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idVehicule,object?.idVehicule && !this.data?.idVehicule);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteVehicule() {
		//Vérification de l'absence d'acquittement ANTAI
		if (!this.data.extension?.dateLastAck) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
				filter(isConfirmed => !!isConfirmed),
				switchMap(() => this.vehiculeService.deleteVehicule(this.data))
			).subscribe(result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression du véhicule
					this.onObjectChange(null);
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			});
		} else {
			//Affichage d'un message d'avertissement
			this.toastrService.error(this.translateService.instant('vehicule.suppression.impossibleSynchronisationSGI'))
		}
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.Vehicule',this.data.idVehicule);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Vehicule',this.data.idVehicule);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.Vehicule',this.data.idVehicule,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.VEHICULE
		});
	}

	/**
	 * Accès au planning
	 */
	goToPlanning() {
		//Accès au planning
		this.vehiculeService.goToPlanning(this.data);
	}

	/**
	 * Accès à la demande de véhicule
	 */
	goToDemandeVehicule() {
		//Accès à la demande de véhicule
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
			routeParams: {
				idDemandeVehicule: this.data.demandeVehicule.idDemandeVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Sortie du véhicule
	 */
	takeOutVehicule() {
		//Réalisation de la sortie du véhicule
		this.vehiculeService.takeOutVehicule(this.data,this.listeConfigurations?.find(c => c.codePays == this.data.pays?.code2)).subscribe({
			next: vehicule => {
				//Vérification du véhicule
				if (vehicule)
					//Rechargement de la liste
					this.liste.refresh();
			}
		});
	}

	/**
	 * Renouvellement du véhicule
	 */
	 doVehiculeRenouvellement() {
		//Réalisation du renouvellement du véhicule
		this.vehiculeService.doVehiculeRenouvellement(this.data);
	}
}