import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'connecteur-passwords',
	templateUrl: './connecteur-passwords.component.html'
})
export class ConnecteurPasswordsComponent implements AfterContentChecked,OnInit {
	/** Liste des mots de passe **/
	@Input() listePasswords: Array<{ value?: string,key?: number,idConnecteurPassword?: number }>;

	/** Résultat **/
	result: { listePasswords: Array<{ value?: string,key?: number,idConnecteurPassword?: number }> }

	/** Clé d'ajout **/
	private lastKeyUsed: number = 0;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ConnecteurPasswordsComponent>) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la clé d'ajout des mots de passe
		this.listePasswords.forEach(e => e.key = this.lastKeyUsed++);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Ajout d'un nouveau mot de passe
	 */
	public addPassword() {
		//Ajout d'un nouveau mail à la liste
		this.listePasswords.push({ key: this.lastKeyUsed++ });
	}

	/**
	 * Suppression des mots de passe de la liste
	 */
	public deletePassword(index: number) {
		//Suppression de l'élément
		this.listePasswords.splice(index,1);
	}

	/**
	 * Enregistrement des mots de passe
	 */
	public savePasswords() {
		//Définition du résultat
		this.result = {
			listePasswords: this.listePasswords.filter(v => !!v.value)
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la moadal
		this.bsModalRef.hide();
	}
}
