import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,combineLatest,of } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { GrilleFluiditeService } from 'src/app/components/vehicule/grille-fluidite/grille-fluidite.service';
import { AppState } from 'src/app/domain/appstate';
import { AttachmentEntity,LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { ChartOptions,TypeChart } from 'src/app/domain/chart/chart';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Page } from 'src/app/domain/common/http/list-result';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEmbeddedDashboard } from 'src/app/domain/dashboard/embedded-dashboard';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratFinancementEcheanceListComponent } from './contrat-financement-echeance-list.component';
import { ContratFinancementEditComponent } from './contrat-financement-edit.component';
import { ContratFinancementFactureDetailListComponent } from './contrat-financement-facture-detail-list.component';
import { ContratFinancementLoiRoulageListComponent } from './contrat-financement-loi-roulage-list.component';
import { ContratFinancementLoiRoulageTipComponent } from './contrat-financement-loi-roulage-tip.component';
import { ContratFinancementLoiRoulageComponent } from './contrat-financement-loi-roulage.component';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	selector: 'contrat-financement',
	templateUrl: './contrat-financement.component.html'
})
export class ContratFinancementComponent implements OnInit,IEntity,AttachmentEntity,IEmbeddedDashboard {
	/** Options de la page complexe **/
	public options: Options;

	/** Contrat de financement **/
	public contratFinancement: any;

	/** Liste des échéances **/
	private listeEcheances: Array<any>;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.FINANCEMENT;

	/** Utilisateur connecté **/
	private user: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Résumé **/
	resume: { nbLoisRoulage: number,nbEcheances: number,nbCouts: number,nbEcheancesAnomalie: number };

	/** Indicateur de disponibilité d'une grille de fluidité **/
	private isGrilleFluiditeAvailable: boolean = false;

	/** Grille de fluidité **/
	private grilleFluidite: any;

	/** Indicateur d'affichage des anomalies pour les échéances **/
	private isShowAnomaliesForEcheances: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private activatedRoute: ActivatedRoute,private contratFinancementService: ContratFinancementService,private translateService: TranslateService,private rightService: RightService,private displayPipe: DisplayPipe,private decimalPipe: DecimalPipe,private grilleFluiditeService: GrilleFluiditeService
			,private notificationService: NotificationService,private confirmService: ConfirmService,private toastrService: ToastrService,private pageContentService: PageContentService,private datePipe: DatePipe,private attachmentService: AttachmentService,private layoutService: LayoutService,private pluralTranslatePipe: PluralTranslatePipe) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.Financement';

	 /**
	  * Récupération de l'identifiant de l'objet
	  */
	getIdObject: () => number = () => this.contratFinancement?.idContrat || 0;

	 /**
	  * Récupération de l'entité portant les pièces jointes
	  */
	getOwningEntity: () => LinksOwningEntity = () => this.contratFinancement;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		combineLatest([this.store.select<User>(state => state.session.user),this.notificationService.isNotificationAvailableFor(this.getClassName())]).pipe(first()).subscribe(([user,hasNotification]) => {
			//Stockage de l'utilisateur
			this.user = user;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idContrat).subscribe({
				complete: () => {
					//Initialisation de la page complexe
					this.options = {
						listeFields: [{
							libelle: 'contrat.financement.type',
							key: 'type',
							type: 'DISPLAY',
							format: 'typeFinancement'
						},{
							libelle: 'contrat.financement.vehicule',
							key: 'vehicule',
							type: 'DISPLAY',
							format: 'vehicule'
						},{
							libelle: 'contrat.financement.fournisseur',
							key: 'fournisseur',
							type: 'DISPLAY',
							format: 'fournisseur'
						},{
							libelle: 'contrat.financement.conducteur',
							key: () => this.contratFinancement?.vehicule?.extension?.userAffecte && this.displayPipe.transform(this.contratFinancement.vehicule.extension.userAffecte,'user') || this.translateService.instant('common.aucun')
						},this.contratFinancement?.type?.mode == 'ACQUISITION' && {
							libelle: 'contrat.financement.dateAchat',
							key: 'dateAchat',
							type: 'DATE'
						},this.contratFinancement?.type?.mode != 'ACQUISITION' && {
							libelle: 'contrat.financement.dateDebut',
							key: 'dateDebut',
							type: 'DATE'
						},this.contratFinancement?.type?.mode != 'ACQUISITION' && {
							libelle: 'contrat.financement.dateFinMaximum',
							key: () => this.datePipe.transform(this.getDateFin()?.toDate() || null,'shortDate'),
							isSecondary: true
						},{
							libelle: 'contrat.financement.dureeMaximum',
							key: 'lastLoiRoulage.duree',
							isSecondary: true
						},this.contratFinancement?.lastLoiRoulage && {
							libelle: 'contrat.financement.loiRoulage.item',
							key: () => this.translateService.instant('contrat.financement.loiRoulage.dureeMois',{ duree: this.contratFinancement.lastLoiRoulage.duree,distance: (this.contratFinancement.vehicule.uniteUsage == 'DISTANCE' ? this.decimalPipe.transform(this.contratFinancement.lastLoiRoulage.distance,'1.0-0') : this.decimalPipe.transform(this.contratFinancement.lastLoiRoulage.temps,'1.0-0')),unite: this.contratFinancement.vehicule.uniteUsage == 'DISTANCE' ? (this.contratFinancement.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt) : this.translateService.instant('duree.heure.libelleCourt') }),
							isSecondary: true
						}].filter(f => !!f),
						listeActions: [{
							libelle: 'actions.notifier',
							doAction: () => this.sendNotification(),
							isVisible: () => hasNotification
						},{
							libelle: 'actions.modifier',
							doAction: () => this.editContratFinancement(),
							isVisible: () => this.rightService.hasRight(null,'creation'),
							type: TypeAction.EDITION
						},{
							libelle: 'actions.consulter',
							doAction: () => this.editContratFinancement(),
							isVisible: () => !this.rightService.hasRight(null,'creation'),
							type: TypeAction.CONSULTATION
						},{
							libelle: 'actions.supprimer',
							doAction: () => this.deleteContratFinancement(),
							isVisible: () => this.rightService.hasRight(null,'suppression'),
							type: TypeAction.SUPPRESSION
						},{
							libelle: 'contrat.financement.actions.accederVehicule',
							doAction: () => this.goToVehicule(),
							isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation'),
							type: TypeAction.REDIRECTION
						},{
							libelle: 'contrat.financement.actions.acheterVehicule',
							doAction: () => this.doAchatVehicule(),
							isVisible: () => this.rightService.hasRight(null,'creation') && this.contratFinancement?.etat == 'LOCATION'
						}],
						listeAlertes: [{
							icon: 'directions_car',
							title: this.translateService.instant('contrat.financement.alerte.absenceLoiRoulage.title'),
							message: this.translateService.instant('contrat.financement.alerte.absenceLoiRoulage.message'),
							isVisible: () => !this.contratFinancement.listeLoisRoulage?.length && !this.pageContentService.isOpened(),
							doAction: () => this.addLoiRoulage(),
							isActionVisible: () => this.rightService.hasRight(null,'creation')
						},{
							icon: 'attach_money',
							title: this.translateService.instant('contrat.financement.alerte.ecartLoyer.title'),
							message: this.translateService.instant('contrat.financement.alerte.ecartLoyer.message'),
							isVisible: () => this.resume?.nbEcheancesAnomalie && this.contratFinancement.type.mode != 'ACQUISITION' && !this.pageContentService.isOpened(),
							doAction: () => {
								//Définition de l'indicateur d'affichage des anomalies
								this.isShowAnomaliesForEcheances = true;

								//Affichage de la liste des échéances
								this.complexPage.setSelectedElementByType('ECHEANCE',true);
							},
							isActionVisible: () => true
						},{
							icon: 'grid_on',
							title: this.translateService.instant('contrat.financement.alerte.grilleFluiditeAbsente.title'),
							message: this.translateService.instant('contrat.financement.alerte.grilleFluiditeAbsente.message'),
							isVisible: () => this.isGrilleFluiditeAvailable && this.contratFinancement.type.mode == 'LOCATION_LONGUE_DUREE' && this.rightService.hasRight(TypeDroit.GRILLE_FLUIDITE,'creation') && !this.grilleFluidite && !this.pageContentService.isOpened(),
							doAction: () => this.showGrilleFluiditeImport(),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.GRILLE_FLUIDITE,'creation')
						},{
							icon: 'grid_on',
							title: this.translateService.instant('contrat.financement.alerte.grilleFluiditeConsultation.title'),
							message: this.translateService.instant('contrat.financement.alerte.grilleFluiditeConsultation.message'),
							isVisible: () => this.isGrilleFluiditeAvailable && this.contratFinancement.type.mode == 'LOCATION_LONGUE_DUREE' && this.rightService.hasRight(TypeDroit.GRILLE_FLUIDITE,'consultation') && this.grilleFluidite && !this.pageContentService.isOpened(),
							doAction: () => this.showGrilleFluidite(),
							isActionVisible: () => this.rightService.hasRight(TypeDroit.GRILLE_FLUIDITE,'consultation')
						}],
						listeElements: [{
							type: 'LOI_ROULAGE',
							libelle: () => this.translateService.instant(this.pluralTranslatePipe.transform('contrat.financement.elements.' + (this.resume?.nbLoisRoulage > 1 && this.contratFinancement.type.mode != 'ACQUISITION' ? 'avenant' : 'loiRoulage'),this.resume?.nbLoisRoulage || 0)),
							component: ContratFinancementLoiRoulageListComponent,
							retrieveComponentData: () => ({
								contratFinancement: this.contratFinancement,
								resume: this.resume,
								user: this.user,
								addLoiRoulage: this.addLoiRoulage.bind(this)
							}),
							count: () => this.resume?.nbLoisRoulage || 0,
							isVisible: () => true,
							isActionVisible: () => this.rightService.hasRight(null,'creation') && (this.contratFinancement.type.mode != 'ACQUISITION' || !this.resume?.nbLoisRoulage),
							doAction: () => this.addLoiRoulage()
						},{
							type: 'ECHEANCE',
							libelle: 'contrat.financement.elements.echeance',
							component: ContratFinancementEcheanceListComponent,
							retrieveComponentData: () => ({
								contratFinancement: this.contratFinancement,
								resume: this.resume,
								isShowAnomaliesForEcheances: this.isShowAnomaliesForEcheances,
								onResumeChange: resume => this.resume = resume
							}),
							count: () => this.resume?.nbEcheances || 0,
							isVisible: () => this.contratFinancement.type?.mode != 'ACQUISITION' && (this.resume.nbLoisRoulage || this.resume.nbEcheances) > 0
						},{
							type: 'FACTURE_DETAIL',
							libelle: 'contrat.financement.elements.cout',
							component: ContratFinancementFactureDetailListComponent,
							retrieveComponentData: () => ({
								contratFinancement: this.contratFinancement,
								resume: this.resume
							}),
							count: () => this.resume?.nbCouts || 0,
							isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'consultation') || this.rightService.hasRight(TypeDroit.FACTURE_DETAIL,'consultation')
						}],
						isFormCustomization: true
					};
				}
			});
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idContrat: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement du contrat de financement
		combineLatest([this.contratFinancementService.loadContratFinancement(idContrat),idContrat ? this.contratFinancementService.loadResumeContratFinancement(idContrat) : of({} as Result)]).pipe(first()).subscribe({
			next: ([result,resumeResult]) => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour du contrat de financement
					this.contratFinancement = result.data.contrat;

					//Initialisation du compteur de pièces-jointes
					this.attachmentService.initAttachments(this);

					//Mise à jour du résumé
					this.resume = {
						nbLoisRoulage: resumeResult?.data?.nbLoisRoulage || 0,
						nbEcheances: resumeResult?.data?.nbEcheances || 0,
						nbCouts: resumeResult?.data?.nbCouts || 0,
						nbEcheancesAnomalie: resumeResult?.data?.nbEcheancesAnomalie || 0
					};

					//Mise à jour de l'indicateur de possibilité de disposer d'une grille de fluidité
					this.isGrilleFluiditeAvailable = result.data.hasGrilleFluidite;

					//Définition de la grille de fluidité
					this.grilleFluidite = result.data.grilleFluidite;

					//Définition de la liste des échéances
					this.listeEcheances = result.data.listeEcheances;

					//Vérification du contrat
					if (!this.contratFinancement?.idContrat) {
						//Création d'un contrat de financement vierge
						this.contratFinancement = {
							typeContrat: 'FINANCEMENT',
							typeDuree: 'MOIS',
							typeDureeAmortissement: 'MOIS',
							listeLoisRoulage: [],
							unite: this.user.unite,
							devise: this.user.devise,
							vehicule: history.state.vehicule?.idVehicule && history.state.vehicule || null
						}

						//Edition du contrat de financement
						this.editContratFinancement();
					}

					//Poursuite du traitement
					subject.next(null);
				}
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Calcul de la date de fin
	 */
	private getDateFin(): moment.Moment {
		//Date de début du contrat + Nombre de mois du dernier avenant
		return this.contratFinancementService.getDateFin(this.contratFinancement);
	}

	/**
	 * Envoi d'une notification
	 */
	private sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite(this.getClassName(),this.getIdObject(),{
			getOwningEntity: () => this.contratFinancement,
			typeAttachment: TypeAttachment.FINANCEMENT
		});
	}

	/**
	 * Modification du contrat de financement
	 */
	private editContratFinancement() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ContratFinancementEditComponent,{
			data: {
				contratFinancement: this.contratFinancement.idContrat ? cloneDeep(this.contratFinancement) : this.contratFinancement,
				deleteContratFinancement: this.deleteContratFinancement.bind(this),
				getDateFin: this.getDateFin.bind(this)
			}
		}).subscribe({
			next: (data?: { contratFinancement: any,listeEcheances: Array<any> }) => {
				//Vérification du contrat de financement
				if (data?.contratFinancement) {
					//Mise à jour du contrat de financement
					Object.assign(this.contratFinancement,data.contratFinancement);

					//Mise à jour de la liste des échéances
					this.listeEcheances = data.listeEcheances;

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.contratFinancement);
				}
			}
		});
	}

	/**
	 * Suppression du contrat de financement
	 */
	private deleteContratFinancement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contratFinancementService.deleteContratFinancement(this.contratFinancement))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.contratFinancement;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Accès au véhicule
	 */
	private goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.contratFinancement.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Réalisation de l'achat du véhicule
	 */
	private doAchatVehicule() {
		//Réalisation de l'achat du véhicule
		this.contratFinancementService.doAchatVehicule(this.contratFinancement).subscribe({
			next: contratFinancement => {
				//Vérification du contrat de financement
				if (contratFinancement)
					//Mise à jour du contrat de financement
					Object.assign(this.contratFinancement,contratFinancement);
			}
		});
	}

	/**
	 * Ajout/modification d'une loi de roulage
	 */
	protected addLoiRoulage(loiRoulage: any = { financement: this.contratFinancement,typeAffichagePrix: 'HT' },deleteLoiRoulage?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ContratFinancementLoiRoulageComponent,{
			data: {
				loiRoulage: cloneDeep(loiRoulage),
				contratFinancement: this.contratFinancement,
				deleteLoiRoulage
			}
		},'sub').subscribe({
			next: (data: { contratFinancement: any,loiRoulage: any,listeEcheances: Array<any> }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('LOI_ROULAGE',loiRoulage.idLoiRoulage != data.loiRoulage?.idLoiRoulage);

					//Vérification de la présence d'une création ou d'une suppression
					if (!loiRoulage.idLoiRoulage && data.loiRoulage?.idLoiRoulage)
						//Incrémentation du compteur
						this.resume.nbLoisRoulage++;
					else if (loiRoulage.idLoiRoulage && !data.loiRoulage?.idLoiRoulage)
						//Décrémentation du compteur
						this.resume.nbLoisRoulage--;

					//Mise à jour de la loi de roulage
					Object.assign(loiRoulage,data.loiRoulage);

					//Mise à jour du contrat de financement
					Object.assign(this.contratFinancement,data.contratFinancement);

					//Mise à jour des échéances
					this.listeEcheances = data.listeEcheances;

					//Mise à jour du résumé
					Object.assign(this.resume,{
						nbEcheances: this.listeEcheances.length
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'import d'une grille de fluidité
	 */
	protected showGrilleFluiditeImport() {
		//Affichage de l'import d'une grille de fluidité
		this.grilleFluiditeService.showGrilleFluiditeImport('CONTRAT_FINANCEMENT',this.contratFinancement.fournisseur.idFournisseur,this.contratFinancement.idContrat).subscribe({
			complete: () => {
				//Rechargement du contrat de financement et affichage de la grille de fluidité
				this.loadData(this.contratFinancement.idContrat).subscribe({
					next: () => this.showGrilleFluidite()
				});
			}
		});
	}

	/**
	 * Affichage de la grille de fluidité
	 */
	protected showGrilleFluidite() {
		let listePreviousEcheances: Array<any>;

		//Récupération de la liste des échéances précédentes
		listePreviousEcheances = (this.listeEcheances || []).filter(echeance => moment.utc().add(-1,'month').isAfter(moment.utc(echeance.dateEcheance)));

		//Affichage de la grille de fluidité
		this.grilleFluiditeService.showGrilleFluidite(this.grilleFluidite,{
			loiRoulage: this.contratFinancement.lastLoiRoulage,
			lastEcheance: listePreviousEcheances[listePreviousEcheances.length - 1],
			distanceMensuelle: this.contratFinancement.vehicule?.extension?.moyenneDistanceLastThreeMonths || 0,
			distanceVehicule: this.contratFinancement.vehicule?.extension?.lastCompteur || 0,
			dureeContrat: this.contratFinancement.dateDebut && moment.utc().diff(moment.utc(this.contratFinancement.dateDebut),'M') || 0,
			canAddDetail: true
		}).subscribe({
			next: (data?: { isDeleted?: boolean,detail?: any }) => {
				//Vérification de la suppression ou du détail sélectionné
				if (data?.isDeleted) {
					//Mise à jour de la grille de fluidité
					this.grilleFluidite = null;
				} else if (data?.detail) {
					//Affichage de la création d'une loi de roulage
					this.addLoiRoulage({
						duree: data.detail.duree,
						distance: data.detail.distance,
						loyerMensuel: data.detail.loyer,
						typeAffichagePrix: 'HT',
						coutDistance: data.detail.coutKilometrique
					});
				}
			}
		});
	}

	/**
	 * Récupération de la liste des graphiques statiques
	 */
	public getListeStaticCharts(): Array<{ options: ChartOptions,data: Page<any>,x: number,y: number,cols: number,rows: number }> {
		let options: ChartOptions;
		let data: Page<any>;
		let listeEcheances: Array<any>;

		//Récupération de la liste des échéances
		listeEcheances = this.listeEcheances;

		//Vérification de la présence d'échéances
		if (listeEcheances?.length) {
			//Définition des options du graphique
			options = {
				type: TypeChart.LINE,
				title: this.translateService.instant(`contrat.financement.chart.${this.contratFinancement.vehicule?.uniteUsage == 'DISTANCE' ? 'evolutionLoiRoulageTitle' : 'evolutionLoiRoulageHoraireTitle'}`),
				grid: 'VERTICAL',
				axis: 'BOTH',
				isShowLegend: true,
				seuilLegend: this.translateService.instant(`contrat.financement.chart.legende.${this.contratFinancement.vehicule?.uniteUsage == 'DISTANCE' ? 'tempsContrat' : 'distanceContrat'}`),
				seuilColor: '#E4E3E0',
				isCustomTooltip: true,
				formatValue: value => this.decimalPipe.transform(value,'.0-0'),
				getColorStroke: d => d?.isPrevisionnelle && !d?.isEstimation ? 'nc-text-other' : 'nc-secondary',
				getColorFill: d => d?.isEstimation ? '#FFFFFF' : d?.isPrevisionnelle ? 'nc-text-other' : 'nc-secondary',
				tipComponent: () => ContratFinancementLoiRoulageTipComponent as any,
				tipOffset: {
					y: '-100px'
				},
				noDataMessage: this.translateService.instant('dashboard.chart.noData'),
				getLabel: (d: any) => d?.label || null
			};

			//Définition des indexes
			options.indexEnd = listeEcheances.length;
			options.indexStart = listeEcheances.length - 24 > 0 ? listeEcheances.length - 24 : 1;

			//Création des données
			data = { content: [],first: listeEcheances.length <= 24,last: true };

			//Mise à jour des options de la loi de roulage
			Object.assign(options,{
				next: (isLast) => {
					//Vérification de la dernière page
					if (!isLast) {
						//Définition des indexes
						options.indexEnd = options.indexEnd + 24;
						options.indexStart = options.indexEnd - 24 > 0 ? options.indexEnd - 24 : 1;

						//Mise à jour des indicateurs
						data.first = options.indexStart == 1;
						data.last = options.indexEnd == listeEcheances.length;

						//Mise à jour de la sélection
						options.onIndexesChange?.();
					}
				},
				previous: () => {
					//Vérification de l'index
					if (!data.first) {
						//Définition des indexes
						options.indexEnd = options.indexEnd - 24;
						options.indexStart = options.indexEnd - 24 > 0 ? options.indexEnd - 24 : 1;

						//Mise à jour des indicateurs
						data.first = options.indexStart == 1;
						data.last = options.indexEnd == listeEcheances.length;

						//Mise à jour de la sélection
						options.onIndexesChange?.();
					}
				}
			});

			//Définition des données
			listeEcheances.forEach((e,index) => {
				//Vérification de l'unité d'usage du véhicule
				if (this.contratFinancement.vehicule.uniteUsage == 'DISTANCE') {
					//Ajout de l'échéance kilométrique
					data.content.push({
						listeDatas: [{
							value: e.distanceEstimeeCumul > 0 ? e.distanceEstimeeCumul : e.distanceReelleCumul > 0 ? e.distanceReelleCumul : null,
							label: this.translateService.instant('contrat.financement.echeance.reelOuEstime'),
							metadata: Object.assign(e,{
								ecart: e.distancePrevisionnelleCumul != 0 || e.distanceReelleCumul != 0 || e.distanceEstimeeCumul != 0 ? ((e.distancePrevisionnelleCumul > 0 ? e.distancePrevisionnelleCumul : e.distanceEstimeeCumul > 0 ? e.distanceEstimeeCumul : e.distanceReelleCumul) - e.distanceTheoriqueCumul) / e.distanceTheoriqueCumul : e.distanceTheoriqueCumul ? -1 : null,
								unite: 'DISTANCE'
							})
						},{
							value: e.distanceEstimeeCumul > 0 ? null : e.distancePrevisionnelleCumul > 0 ? e.distancePrevisionnelleCumul : null,
							label: this.translateService.instant('contrat.financement.echeance.previsionnel'),
							metadata: Object.assign(e,{
								ecart: e.distancePrevisionnelleCumul != 0 || e.distanceReelleCumul != 0 || e.distanceEstimeeCumul != 0 ? ((e.distancePrevisionnelleCumul > 0 ? e.distancePrevisionnelleCumul : e.distanceEstimeeCumul > 0 ? e.distanceEstimeeCumul : e.distanceReelleCumul) - e.distanceTheoriqueCumul) / e.distanceTheoriqueCumul : e.distanceTheoriqueCumul ? -1 : null,
								unite: 'DISTANCE'
							})
						},{
							value: e.distanceTheoriqueCumul,
							label: this.translateService.instant('contrat.financement.echeance.contrat'),
							metadata: Object.assign(e,{
								ecart: e.distancePrevisionnelleCumul != 0 || e.distanceReelleCumul != 0 || e.distanceEstimeeCumul != 0 ? ((e.distancePrevisionnelleCumul > 0 ? e.distancePrevisionnelleCumul : e.distanceEstimeeCumul > 0 ? e.distanceEstimeeCumul : e.distanceReelleCumul) - e.distanceTheoriqueCumul) / e.distanceTheoriqueCumul : e.distanceTheoriqueCumul ? -1 : null,
								unite: 'DISTANCE'
							})
						}],
						label: index + 1,
						legend: this.translateService.instant('contrat.financement.chart.legende.distance'),
						isPrevisionnelle: e.distancePrevisionnelleCumul > 0,
						isEstimation: e.distanceEstimeeCumul > 0
					});
				} else {
					//Ajout de l'échéance horaire
					data.content.push({
						value: e.tempsPrevisionnelCumul > 0 ? e.tempsPrevisionnelCumul : e.tempsEstimeCumul > 0 ? e.tempsEstimeCumul : e.tempsReelCumul > 0 ? e.tempsReelCumul : null,
						listeDatas: [{
							value: e.tempsEstimeCumul > 0 ? e.tempsEstimeCumul : e.tempsReelCumul > 0 ? e.tempsReelCumul : null,
							label: this.translateService.instant('contrat.financement.echeance.reelOuEstime'),
							metadata: Object.assign(e,{
								ecart: e.tempsPrevisionnelCumul != 0 || e.tempsReelCumul != 0 || e.tempsEstimeCumul != 0 ? ((e.tempsPrevisionnelCumul > 0 ? e.tempsPrevisionnelCumul : e.tempsEstimeCumul > 0 ? e.tempsEstimeCumul : e.tempsReelCumul) - e.tempsTheoriqueCumul) / e.tempsTheoriqueCumul : e.tempsTheoriqueCumul ? -1 : null,
								unite: 'TEMPS'
							})
						},{
							value: e.tempsEstimeCumul > 0 ? null : e.tempsPrevisionnelCumul > 0 ? e.tempsPrevisionnelCumul : null,
							label: this.translateService.instant('contrat.financement.echeance.previsionnel'),
							metadata: Object.assign(e,{
								ecart: e.tempsPrevisionnelCumul != 0 || e.tempsReelCumul != 0 || e.tempsEstimeCumul != 0 ? ((e.tempsPrevisionnelCumul > 0 ? e.tempsPrevisionnelCumul : e.tempsEstimeCumul > 0 ? e.tempsEstimeCumul : e.tempsReelCumul) - e.tempsTheoriqueCumul) / e.tempsTheoriqueCumul : e.tempsTheoriqueCumul ? -1 : null,
								unite: 'TEMPS'
							})
						},{
							value: e.tempsTheoriqueCumul,
							label: this.translateService.instant('contrat.financement.echeance.contrat'),
							metadata: Object.assign(e,{
								ecart: e.tempsPrevisionnelCumul != 0 || e.tempsReelCumul != 0 || e.tempsEstimeCumul != 0 ? ((e.tempsPrevisionnelCumul > 0 ? e.tempsPrevisionnelCumul : e.tempsEstimeCumul > 0 ? e.tempsEstimeCumul : e.tempsReelCumul) - e.tempsTheoriqueCumul) / e.tempsTheoriqueCumul : e.tempsTheoriqueCumul ? -1 : null,
								unite: 'TEMPS'
							})
						}],
						label: index + 1,
						legend: this.translateService.instant('contrat.financement.chart.legende.temps'),
						isPrevisionnelle: e.tempsPrevisionnelCumul > 0,
						isEstimation: e.tempsEstimeCumul > 0
					});
				}
			});

			//Retour de la liste des graphiques
			return [{ options,data,x: 0,y: 0,cols: 12,rows: 4 }];
		} else
			//Aucun retour
			return null;
	}
}