import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AuthentificationConfigurationListItemComponent } from './authentification-configuration-list-item.component';
import { AuthentificationConfigurationListComponent } from './authentification-configuration-list.component';
import { AuthentificationConfigurationComponent } from './authentification-configuration.component';
import { AuthentificationConfigurationService } from './authentification-configuration.service';
import { AuthentificationConfigurationContentComponent } from './authentification-configuration-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [AuthentificationConfigurationListComponent,AuthentificationConfigurationComponent,AuthentificationConfigurationListItemComponent,AuthentificationConfigurationContentComponent],
	exports: [AuthentificationConfigurationContentComponent],
	providers: [AuthentificationConfigurationService]
})
export class AuthentificationConfigurationModule { }