<div class="container-fluid">
	<form #compteAuxiliaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': planComptable.invalid }">
								<label translate>comptabilite.compteAuxiliaire.planComptable</label>
								<div>
									<autocomplete name="planComptable" type="planComptable" #planComptable="ngModel" [(ngModel)]="compteAuxiliaire.planComptable" (ngModelChange)="compteAuxiliaire.pays = $event?.pays;" required [disabled]="compteAuxiliaire.idCompteAuxiliaire"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': compte.invalid }">
								<label translate>comptabilite.compteAuxiliaire.compteCollectif</label>
								<div>
									<autocomplete name="compte" type="compte" #compte="ngModel" [(ngModel)]="compteAuxiliaire.compte" [filter]="{ listeTypes: ['BANQUE','TIERS'],idPlanComptable: compteAuxiliaire.planComptable?.idPlanComptable }" required [disabled]="!compteAuxiliaire.planComptable || compteAuxiliaire.idCompteAuxiliaire"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
								<label translate>comptabilite.compteAuxiliaire.numero</label>
								<div>
									<input type="text" name="numero" #numero="ngModel" class="form-control" [(ngModel)]="compteAuxiliaire.numero" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>comptabilite.compteAuxiliaire.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="compteAuxiliaire.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" *ngIf="compteAuxiliaire.planComptable">
								<label translate>comptabilite.compteAuxiliaire.pays</label>
								<div>
									<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="compteAuxiliaire.planComptable.pays" [disabled]="true"></autocomplete>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>comptabilite.compteAuxiliaire.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="compteAuxiliaire.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteCompteAuxiliaire();" *ngIf="compteAuxiliaire?.idCompteAuxiliaire && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveCompteAuxiliaire();" *ngIf="null | right:'creation'" [disabled]="compteAuxiliaireForm.invalid">
				<span [translate]="'actions.'+(compteAuxiliaire?.idCompteAuxiliaire ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="compteAuxiliaire?.idCompteAuxiliaire" [formDetectChanges]="{ doAction: close,isDirty: compteAuxiliaireForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>