import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';

@Injectable()
export class DocumentService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private messagingService: MessagingService,private translateService: TranslateService,private attachmentService: AttachmentService) {}

	/**
	 * Génération d'un document PDF
	 */
	public generateDocument(entite: string,idEntite: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Document/generateDocument/${entite}/${idEntite}`,
			progressConfig: {
				libelle: this.translateService.instant('actions.document.title'),
				icone: 'download'
			}
		}).onFinish({
			next: ({ idSession }) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();

				//Téléchargement du document
				this.downloadDocument(idEntite,idSession).pipe(first()).subscribe({
					next: response => {
						//Téléchargement du contenu
						this.attachmentService.downloadAttachment(response,null,'application/pdf','document.pdf','_blank');
					}
				});
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Téléchargement du document
	 */
	private downloadDocument(idEntite: number,idSession: string) {
		//Téléchargement du document
		return this.http.post(`${environment.baseUrl}/controller/Document/downloadDocument/${idEntite}/${idSession}`,null,{
			responseType: 'arraybuffer'
		});
	}
}