<div class="pull-left lv-avatar">
	<div *ngIf="!data.vehicule.dateSortie || data.vehicule.dateSortie > today">
		<icon name="person" *ngIf="data.typeAffectation == 'VEHICULE_FONCTION'"></icon>
		<icon name="apartment" *ngIf="data.typeAffectation == 'VEHICULE_SERVICE'"></icon>
		<icon name="lock" *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
		<icon name="event" *ngIf="data.typeAffectation == 'VEHICULE_POOL'"></icon>
		<icon name="question_mark" *ngIf="!data.typeAffectation"></icon>
	</div>
	<icon name="logout" *ngIf="data.vehicule.dateSortie && data.vehicule.dateSortie <= today"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="select();">
			<span *ngIf="data.typeAffectation != 'VEHICULE_IMMOBILISE'">{{ data.user.nom + ' ' + data.user.prenom + ' (' + data.user.matricule + (data.user.societe ? ' - ' + data.user.societe.libelle : '') + ')' }}</span>
			<span *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'" translate>vehicule.affectation.immobilisation</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.affectation.dateDebut.item</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li><span translate>vehicule.affectation.dateFin.item</span>&#160;:&#160;<span *ngIf="data.dateFin">{{ data.dateFin | date:'short' }}</span><span *ngIf="!data.dateFin" translate>common.aucune</span></li>
	</ul>
</div>