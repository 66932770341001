import { Component,Input } from '@angular/core';
import { filter,first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { LotComptableService } from './lot-comptable.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	templateUrl: './lot-comptable-edit.component.html'
})
export class LotComptableEditComponent extends PageContentItem {
	/** Lot comptable courant **/
	@Input() lotComptable: any;

	/** Suppression du lot comptable **/
	@Input() deleteLotComptable: Function;

	/** Enregistrement et peuplement du lot comptable **/
	@Input() saveLotComptableAndPopulate: Function;

	/** Sélection courante **/
	@Input() selectionItems: any;

	/**
	 * Constructeur
	 */
	constructor(private lotComptableService: LotComptableService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du lot comptable
	 */
	public saveLotComptable() {
		//Vérification de l'identifiant du lot comptable
		if (this.lotComptable.idLotComptable) {
			//Enregistrement du lot comptable
			this.lotComptableService.saveLotComptable(this.lotComptable).pipe(first()).subscribe({
				next: result => {
					//Vérification du code d'erreur
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Fermeture de l'écran d'édition
						this.close(result.data.lotComptable);
					} else if (result.codeErreur == TypeCodeErreur.SAVE)
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			});
		} else {
			//Enregistrement et peuplement du lot comptable
			this.saveLotComptableAndPopulate(this.lotComptable,this.selectionItems).pipe(filter((data: any) => data?.lotComptable)).subscribe({
				next: data => {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de l'écran d'édition
					this.close(data);
				}
			});
		}
	}
}