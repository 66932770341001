import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des bases de données
 */
export default class DatabaseOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.database';

	/**
	 * Récupération de l'URL
	 */
	url(filter?: { idRevendeur: number,idTenant: number }): string {
		//URL
		return `/controller/Database/listeDatabaseForRevendeur/${filter?.idRevendeur || 0}/${filter?.idTenant || 0}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.schema})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.type?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Récupération du type de la classe
	 */
	type = 'com.notilus.data.database.Database';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idDatabase'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'schema',
			isDefault: true
		}];
	}

	/**
	 * Liste des éléments à afficher
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des éléments à afficher
		return [{
			title: 'database.type.item',
			getValue: (item,translateService?) => translateService.instant(`database.type.${item.type}`)
		},{
			title: 'database.revendeur.liste',
			getValue: item => item.listeRevendeurs.map(revendeur => revendeur.libelle).join(', ')
		}]
	}
}