import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { RuleService } from 'src/app/share/components/rule/rule.service';
import { RegleAutomatiqueService } from './regle-automatique.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'regle-automatique-rule',
	templateUrl: './regle-automatique-rule.component.html'
})
export class RegleAutomatiqueRuleComponent extends PageContentItem implements OnInit {
	/** Récupération de la règle automatique **/
	getRegleAutomatique: () => any;

	/** Récupération de la règle **/
	getRule: () => any;

	/** Mode 'Edition' */
	isEdition: boolean;

	/** Règle courante **/
	rule: any;

	/** Règle automatique courante **/
	regleAutomatique: any;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService,private regleAutomatiqueService: RegleAutomatiqueService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la règle
		this.rule = this.getRule?.();

		//Récupération de la règle automatique
		this.regleAutomatique = this.getRegleAutomatique?.();

		//Vérification de l'entité de la règle
		if (!this.rule?.entite) {
			//Mise à jour de l'entité de la règle
			Object.assign(this.rule,{
				entite: this.regleAutomatique.typeComputedField.entity
			});
		}
	}

	/**
	 * Enregistrement de la règle
	 */
	saveRule() {
		//Mise à jour de la règle
		this.rule = this.ruleService.updateRuleForSave(this.rule);

		//Mise à jour de la règle
		this.regleAutomatique = Object.assign(this.regleAutomatique,{ rule: this.rule.listeDetails?.length ? this.rule : null });

		//Enregistrement de la règle automatique
		this.regleAutomatiqueService.saveRegleAutomatique(this.regleAutomatique).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Retour de la règle automatique
					this.close(Object.assign(this.regleAutomatique,result.data.regleAutomatique));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
