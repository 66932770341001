import { NgModule } from '@angular/core';

import { AdvancedSearchComponent } from './advanced-search.component';
import { AdvancedSearchService } from './advanced-search.service';
import { CoreComponentsModule } from '../core-components.module';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { BasketModule } from 'src/app/share/components/basket/basket.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,NumberModule,BasketModule,IconModule],
	declarations: [AdvancedSearchComponent],
	providers: [AdvancedSearchService],
	exports: [AdvancedSearchComponent]
})
export class AdvancedSearchModule {}