import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RegleTaxePosteChargeListItemComponent } from './regle-taxe-poste-charge-list-item.component';

@Component({
	selector: 'regle-taxe-poste-charge-list',
	templateUrl: './regle-taxe-poste-charge-list.component.html'
})
export class RegleTaxePosteChargeListComponent extends PageContentItem implements OnInit {
	/** Règle de taxe **/
	@Input() regleTaxe: any;

	/** Fonction d'édition **/
	@Input() addPosteCharge: () => void;

	/** Résumé **/
	@Input() resume: { nbPostesCharge: number };

	/** Données **/
	liste: ListView<any,RegleTaxePosteChargeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,RegleTaxePosteChargeListItemComponent>({
			uri: () => `/controller/Fiscalite/filtrePostesChargeByRegle`,
			component: RegleTaxePosteChargeListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: '*nature.libelle',
				isDefault: true
			}],
			defaultOrder: 'type,nature.libelle',
			listeStaticFilters: [{
				clef: 'regleTaxe.idRegle',
				valeur: this.regleTaxe.idRegle,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbPostesCharge--;
	}
}