import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ServiceService } from './service.service';

@Component({
	selector: 'service-alias-edit',
	templateUrl: './service-alias-edit.component.html'
})
export class ServiceAliasEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() alias: any;

	/** Suppression de l'élément **/
	@Input() deleteAlias: (alias: any,close: Function) => void;

	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private serviceService: ServiceService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	saveAlias() {
		//Enregistrement de l'alias
		this.serviceService.saveSocieteAlias(this.alias).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.alias,result.data?.alias);

					//Fermeture de la fenêtre
					this.close({ alias: this.alias });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du code
					listeDoublons.push(this.translateService.instant('actions.doublon.code'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}