import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReleveEnergieService } from 'src/app/components/vehicule/releve-energie/releve-energie.service';

@Component({
	templateUrl: './equipement-releve-energie-list-item.component.html'
})
export class EquipementReleveEnergieListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private releveEnergieService: ReleveEnergieService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private historyService: HistoryService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteReleveEnergie = this.deleteReleveEnergie.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteReleveEnergie(releveEnergie: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.releveEnergieService.deleteReleveEnergie(releveEnergie))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Edition du relevé d'énergie
	 */
	addReleveEnergie() {
		//Chargement complet du relevé d'énergie
		this.releveEnergieService.loadReleveEnergie(this.data.idReleve).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du relevé d'énergie
					Object.assign(this.data,result?.data?.releveEnergie);

					//Edition du compteur
					this.extraOptions?.addReleveEnergie?.(this.data,this.deleteReleveEnergie);
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.VehiculeReleveEnergie',this.data.idReleve);
	}
}