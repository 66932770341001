<div class="modal-header">
	<h4 class="modal-title"><span translate>equipement.history.titre</span>&#160;:&#160;{{ equipement.reference }}</h4>
</div>
<div class="modal-body">
	<list-view [liste]="liste" *ngIf="liste"></list-view>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>