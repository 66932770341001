import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { cloneDeep,isEqual } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DeviseService } from './devise.service';

@Component({
	selector: 'devise',
	templateUrl: './devise.component.html'
})
export class DeviseComponent implements OnInit,IEntity {
	/** Devise courante **/
	public devise: any;

	/** Copie de la devise **/
	private savedDevise: any;

	/** Type de référence **/
	public typeReferentiel: TypeReferentiel;

	/** Indicateur de personnalisation de la devise **/
	public isCustom: boolean = null;

	/**
	 * Constructeur
	 */
	constructor(private activatedRoute: ActivatedRoute,private deviseService: DeviseService,private layoutService: LayoutService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,public rightService: RightService) {
		//Binding
		this.close = this.close.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.devise.Devise';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.devise?.idDevise || 0;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de l'identifiant de la devise
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Lecture du type de référentiel
				this.typeReferentiel = params.typeReferentiel;

				//Chargement des données
				this.loadData(params.idDevise);
			}
		});
	}

	/**
	 * Fermeture du formulaire
	 */
	close() {
		//Retour en arrière
		this.layoutService.goBack();
	}

	/**
	 * Chargement des données
	 */
	private loadData(idDevise: number) {
		//Chargement de la devise
		this.deviseService.loadDevise(idDevise).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la devise
					this.devise = result.data?.devise;

					//Vérification de l'identifiant de la devise
					if (!this.devise?.idDevise) {
						//Création d'un objet vierge
						this.devise = {
							actif: true
						};
					}

					//Copie de la devise
					this.savedDevise = cloneDeep(this.devise);

					//Récupération de l'indicateur de personnalisation
					this.isCustom = this.devise.custom;
				}
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveDevise() {
		let doSaveDevise: Function;

		//Enregistrement de l'objet
		doSaveDevise = () => this.deviseService.saveDevise(this.devise,this.typeReferentiel).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.devise = Object.assign(this.devise,result.data?.devise);

					//Rechargement de l'url
					this.layoutService.replaceUrlWith(this.devise);

					//Enregistrement du nouvel état de la devise
					this.savedDevise = cloneDeep(this.devise);

					//Récupération de l'indicateur de personnalisation
					this.isCustom = this.devise.custom;
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du code
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du code
							listeDoublons.push(this.translateService.instant('actions.doublon.code'));

						//Vérification du numéro
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du numéro
							listeDoublons.push(this.translateService.instant('actions.doublon.numero'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});

		//Vérification de la dépersonnalisation
		if (this.typeReferentiel == TypeReferentiel.LOCAL && this.devise.deviseOrigine && this.isCustom && this.isCustom != this.devise.custom) {
			//Message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('actions.personnalisation.confirmationDepersonnalisation'),{ actionColor: 'primary' }).subscribe({
				next: isConfirmed => {
					let deviseOrigine: any;

					//Vérification de la confirmation
					if (isConfirmed) {
						//Récupération de la devise cible
						deviseOrigine = cloneDeep(this.devise.deviseOrigine);

						//Suppression des données conservées dans la devise dépersonnalisée
						delete deviseOrigine.idDevise;
						delete deviseOrigine.tenant;
						delete deviseOrigine.deviseOrigine;

						//Synchronisation avec la devise d'origine
						Object.assign(this.devise,deviseOrigine);

						//Sauvegarde de la devise
						doSaveDevise();
					} else {
						//Restauration de l'indicateur de personnalisation
						this.devise.custom = this.isCustom;
					}
				}
			});
		} else
			//Sauvegarde de la devise
			doSaveDevise();
	}

	/**
	 * Suppression de la devise
	 */
	public deleteDevise() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.deviseService.deleteDevise(this.devise,this.typeReferentiel))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Vérification de la modification de l'objet
	 */
	isDirty(): boolean {
		//Vérification de la modification de l'objet
		return !isEqual(this.savedDevise,this.devise);
	}
}
