import { AfterContentChecked,ChangeDetectorRef,Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'demande-vehicule-add',
	templateUrl: './demande-vehicule-add.component.html'
})
export class DemandeVehiculeAddComponent implements AfterContentChecked {
	/** Demande de véhicule **/
	demandeVehiculeAdded: any = { source: 'RENOUVELLEMENT' };

	/** Résultat **/
	result: { demandeVehiculeAdded: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<DemandeVehiculeAddComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la demande de véhicule
	 */
	saveDemandeVehiculeAdd() {
		//Définition du résultat
		this.result = {
			demandeVehiculeAdded: this.demandeVehiculeAdded
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la popup
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}