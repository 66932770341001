import { Component,Input } from '@angular/core';

@Component({
	templateUrl: './immatriculation-modele-catalogue-selection-choix.component.html'
})
export class ImmatriculationModeleCatalogueSelectionChoixComponent {
	/** Choix effectué **/
	@Input() selectedChoix: { type: 'CONSERVATION_MODELE' | 'CHANGEMENT_MODELE' };

	/** Sélection d'un type de choix **/
	@Input() onChoixSelected: (typeChoix: string) => void;
}