import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { Droit } from 'src/app/domain/droit/droit';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Injectable()
export class RightService {
	/** Utilisateur connecté **/
	private user: User;

	/** Utilisateur d'origine **/
	private userOrigine: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private layoutService: LayoutService) {
		//Récupération de l'utilisateur connecté
		this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session?.user,userOrigine: state.session?.userOrigine })).subscribe(({ user,userOrigine }) => {
			//Mise à jour des utilisateurs
			this.user = user;
			this.userOrigine = userOrigine;
		});
	}

	/**
	 * Récupération d'un droit
	 */
	public getRight(typeDroit: TypeDroit,user: User = this.user): { consultation: boolean,creation: boolean,suppression: boolean } {
		let defaultRight: TypeDroit;
		let droit: Droit;

		//Vérification de la présence de droits sur l'utilisateur
		if (user?.listeDroits?.length) {
			//Récupération du droit par défaut
			defaultRight = Array.isArray(this.layoutService.getExtendedRouteData()?.typeDroit) ? this.layoutService.getExtendedRouteData()?.typeDroit[0] as TypeDroit : this.layoutService.getExtendedRouteData()?.typeDroit as TypeDroit;

			//Recherche du droit correspondant
			return user.listeDroits.filter(r => r.typeDroit == (typeDroit || !typeDroit && defaultRight))?.[0];
		} else if (user?.type == 'ADMINISTRATEUR') {
			//Recherche du droit de l'utilisateur d'origine
			droit = this.userOrigine?.listeDroits?.find(d => d.typeDroit == TypeDroit.ADMIN_ENVIRONNEMENT);

			//Retour du droit
			return droit || {
				consultation: true,
				creation: true,
				suppression: true
			};
		}
	}

	/**
	 * Vérification de la présence du droit
	 */
	public hasRight(typeDroit: TypeDroit,mode: 'consultation' | 'creation' | 'suppression',user: User = this.user): boolean {
		//Vérification du droit de l'utilisateur connecté
		return this.getRight(typeDroit,user)?.[mode];
	}

	/**
	 * Vérification de la présence d'au moins un droit
	 */
	public hasAnyRight(listeTypesDroit: Array<TypeDroit>,mode: 'consultation' | 'creation' | 'suppression'): boolean {
		//Vérification du droit de l'utilisateur connecté
		return (listeTypesDroit?.length ? listeTypesDroit : [null]).some(typeDroit => this.getRight(typeDroit)?.[mode]);
	}

	/**
	 * Vérification de la présence d'un compte Root
	 */
	public isRoot(isForUserOrigine: boolean = false): boolean {
		let user: User;

		//Définition de l'utilisateur
		user = isForUserOrigine ? this.userOrigine : this.user;

		//Vérification de la présence d'un compte Root
		return user?.tenant?.type == 'REVENDEUR' && user?.tenant?.revendeur?.type == 'ROOT';
	}

	/**
	 * Vérification de la présence d'un compte revendeur
	 */
	public isRevendeur(): boolean {
		//Vérification de la présence d'un compte revendeur
		return this.user?.tenant?.type == 'REVENDEUR'
	}

	/**
	 * Vérification de la présence d'un profil restreint
	 */
	public isRestricted(typeRestriction: TypeRestriction): boolean {
		//Vérification de la présence d'un profil restreint
		return this.user?.listeUserProfil?.some(up => up?.profil?.typeRestriction == typeRestriction);
	}
}