<form #globalSearchForm="ngForm" autocomplete="off">
	<div class="global-search" [ngClass]="{ 'focus': focused || mouseEntered }" (click)="focused = true;">
		<div class="input-search-wrapper">
			<icon class="icon" *ngIf="!isSearching" name="search"></icon>
			<please-wait class="wait" *ngIf="isSearching" radius="14"></please-wait>
			<input type="text" name="globalSearch" [formControl]="autocompleteControl" [placeholder]="'actions.rechercherGlobal' | translate" (keydown)="onKeyDown($event)" (blur)="focused = false;"/>
		</div>
		<div class="suggestion-wrapper" *ngIf="globalSearchText?.length">
			<ul class="liste-agregats" (mouseenter)="mouseEntered = true;" (mouseleave)="mouseEntered = false;">
				<li [id]="'item_menu-' + index" *ngFor="let agregat of listeAgregats; let index = index" class="agregat" [ngClass]="{ current: currentAgregat == index }" (click)="selectAgregat(agregat)">
					<a [routerLink]="[]">
						<span class="libelle">{{ agregat.libelle }}</span>
						<span class="counter">{{ agregat.nbTotal }}</span>
					</a>
				</li>
			</ul>
			<div *ngIf="globalSearchText?.length && !isSearching && !listeAgregats?.length" class="empty">
				<div translate>liste.empty</div>
			</div>
		</div>
	</div>
</form>