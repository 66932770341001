import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { StructureExportRubriqueMoveComponent } from './structure-export-rubrique-move.component';
import { StructureExportService } from './structure-export.service';

@Component({
	templateUrl: './structure-export-line-item.component.html'
})
export class StructureExportLineItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private bsModalService: BsModalService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Affichage de la rubrique
	 */
	showRubrique() {
		//Affichage de la rubrique
		this.structureExportService.showRubrique(this.extraOptions.structureExport,this.extraOptions.fichier,this.extraOptions.ligne,this.extraOptions.listeCompteurs,false,cloneDeep(this.data),null,this.extraOptions.isRestricted).pipe(
			filter(result => !!result)
		).subscribe({
			next: result => {
				//Mise à jour de la rubrique
				Object.assign(this.data,result.rubrique);

				//Mise à jour de la ligne et de la structure
				this.extraOptions.onStructureExportUpdate({
					structureExport: result.structureExport,
					ligne: result.ligne
				});
			}
		});
	}

	/**
	 * Déplacement de la rubrique
	 */
	moveRubrique() {
		let bsModalRef: BsModalRef<StructureExportRubriqueMoveComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(StructureExportRubriqueMoveComponent,{
			initialState: {
				idLigne: this.data.ligne.idLigne,
				currentPosition: this.data.position + 1,
				max: this.extraOptions?.fichier?.nbColonnes
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.isMoved),
			filter(isMoved => !!isMoved)
		).subscribe({
			next: () => {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Rafraichissement de la liste
				this.liste.refresh();
			}
		});
	}
}