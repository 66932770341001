<form #connecteurDestinatairesForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>connecteur.destinataire.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': emailValue.invalid }" *ngFor="let email of listeEmails; let idxEmail = index">
				<label translate>connecteur.destinataire.email</label>
				<div class="input-group">
					<input type="email" class="form-control" [name]="'email_' + email.key" #emailValue="ngModel" [(ngModel)]="email.value" required/>
					<div class="input-group-addon no-error hovered-item no-border">
						<button mat-icon-button (click)="deleteEmail(idxEmail);">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="listview">
				<div class="lv-body">
					<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addEmail();">
						<h5><a [routerLink]="[]" translate>connecteur.destinataire.ajouterDestinataire</a></h5>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDestinataires()" [disabled]="connecteurDestinatairesForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>