import { Component } from '@angular/core';
import { filter,switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TenantService } from './tenant.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	templateUrl: './tenant-autorisation-list-item.component.html'
})
export class TenantAutorisationListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private confirmService: ConfirmService,private toastrService: ToastrService,private tenantService: TenantService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteAutorisation = this.deleteAutorisation.bind(this);
	}

	/**
	 * Récupération des profils correspondants au type souhaité
	 */
	getProfilForType(typeProfil: any) {
		//Recherche des profils du type
		return this.data.user.listeUserProfil?.length && this.data.user.listeUserProfil.filter(p => p.profil && p.profil.typeProfil == typeProfil) || [];
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteAutorisation(autorisation: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.deleteAutorisation(autorisation))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}
}