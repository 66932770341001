<div class="card" [ngClass]="{ 'detail': isEdition }">
	<div class="card-header">
		<h2 class="card-title" translate>fiscalite.regleTaxe.regle.liste.title</h2>
	</div>
	<div class="card-body">
		<form #regleTaxeRuleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
			<fieldset [disabled]="!isEdition" class="listview">
				<div class="lv-body">
					<div class="text-center p-10" *ngIf="!listeDetails?.length">
						<h5 translate>fiscalite.regleTaxe.regle.empty</h5>
					</div>
					<div class="lv-item media hoverable" *ngFor="let detail of listeDetails; let index = index;">
						<div class="row" *ngIf="detail.idDetail || detail.key">
							<div class="p-l-0" [ngClass]="regleTaxe.type == 'DATE_GENRE' ? 'col-sm-3' : 'col-sm-4'">
								<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
									<label translate>fiscalite.regleTaxe.regle.dateApplication</label>
									<div>
										<input type="text" class="form-control" data-test-id="dateApplication" name="dateApplication-{{ detail.idDetail || detail.key }}" #dateApplication="ngModel" [(ngModel)]="detail.dateApplication" datePicker [storeDate]="true" format="date" required/>
									</div>
								</div>
							</div>
							<div *ngIf="regleTaxe.type == 'DATE_GENRE'" [ngClass]="regleTaxe.type == 'DATE_GENRE' ? 'col-sm-5' : 'col-sm-6'">
								<div class="form-group" [ngClass]="{ 'has-error': genreField.invalid }">
									<label translate>fiscalite.regleTaxe.regle.genre</label>
									<div>
										<autocomplete name="genre-{{ detail.idDetail || detail.key }}" data-test-id="genre" type="genre" [filter]="{ idPays: regleTaxe?.pays?.idPays }" #genreField="ngModel" [(ngModel)]="detail.genre" required></autocomplete>
									</div>
								</div>
							</div>
							<div class="p-r-0" [ngClass]="regleTaxe.type == 'DATE_GENRE' ? 'col-sm-3' : 'col-sm-4'">
								<div class="form-group" [ngClass]="{ 'has-error': tauxRecuperation.invalid }">
									<label translate>fiscalite.regleTaxe.regle.tauxRecuperation.item</label>
									<div>
										<div class="input-group">
											<input type="text" name="tauxRecuperation-{{ detail.idDetail || detail.key }}" data-test-id="tauxRecuperation" #tauxRecuperation="ngModel" class="form-control" [(ngModel)]="detail.tauxRecuperation" [nNumber]="3" min="0" max="100" required/>
											<div class="input-group-addon">
												<icon name="info_outline" [tooltip]="'fiscalite.regleTaxe.regle.tauxRecuperation.info' | translate"></icon>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="lv-actions actions hovered-item" *ngIf="isEdition">
							<a [routerLink]="[]" (click)="deleteRule(index); $event.stopPropagation();"><icon name="delete" class="icon-hc-1x"></icon></a>
						</div>
					</div>
					<div class="p-t-20 p-b-20" *ngIf="isEdition">
						<div class="lv-item media text-center lv-item-add" matRipple (click)="regleTaxeRuleForm.valid && addRule();">
							<h5>
								<a [routerLink]="[]" [ngClass]="{ 'c-gray': regleTaxeRuleForm.invalid }" translate>fiscalite.regleTaxe.actions.ajouterRule</a>
							</h5>
						</div>
					</div>
				</div>
			</fieldset>

			<div *ngIf="isEdition">
				<span class="pull-right">
					<button mat-raised-button color="primary" (click)="saveListeDetails();" *ngIf="null | right:'creation'" [disabled]="regleTaxeRuleForm.invalid">
						<span translate>actions.enregistrer</span>
					</button>
				</span>
				<button mat-button *ngIf="regleTaxe?.idRegle" [formDetectChanges]="{ doAction: close,isDirty: regleTaxeRuleForm.dirty }">
					<span translate>actions.fermer</span>
				</button>
			</div>
		</form>
	</div>
</div>