<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idSociete">
		<div class="pull-left lv-avatar">{{ data?.libelle?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data.libelle + ' (' + data.siren + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idSociete">
		<li><span translate>societe.siret</span>&#160;:&#160;<span>{{ data.siret }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idSociete">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteSociete();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.societe.Societe',data?.idSociete)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<societe [societe]="data" (onObjectChange)="onObjectChange($event)"></societe>
</div>