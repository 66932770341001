<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.nom?.substring?.(0,1)?.toUpperCase() + data.prenom?.substring?.(0,1)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/User/loadUser/',data.idUser]">{{ data.nom + ' ' + data.prenom + ' (' + data.matricule + (data.societe ? ' - ' + data.societe.libelle : '') + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.login"><span translate>user.identifiant</span>&#160;:&#160;<span>{{ data.login }}</span></li>
	<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.enabled ? 'oui' : 'non')"></span></li>
	<li *ngIf="data.service"><span translate>user.service</span>&#160;:&#160;<span>{{ data.service.libelle }}</span></li>
	<li *ngIf="getProfilForType('WEB')[0]?.profil?.libelle"><span translate>user.profil</span>&#160;:&#160;<span>{{ getProfilForType('WEB')[0].profil.libelle }}</span></li>
	<li *ngIf="data.dateSortie"><span translate>user.sortie</span>&#160;:&#160;<span>{{ data.dateSortie | date:'shortDate' }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
		<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
		<li *ngIf="(TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation') && data.idConducteur"><a [routerLink]="[]" (click)="goToConducteur(data.idConducteur);"><span translate>user.actions.accederConducteur</span></a></li>
		<li *ngIf="(null | right:'suppression') && data.type != 'ADMINISTRATEUR'"><a [routerLink]="[]" (click)="deleteUser();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		<li *ngIf="['ADMINISTRATEUR','UTILISATEUR_AUTO'].indexOf(user.type) > -1 && data.listeUserProfil?.length"><a [routerLink]="[]" (click)="loginAsUser();"><span translate>user.actions.seConnecterEnTantQue</span></a></li>
	</ul>
</div>