import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { TypeEntretienListItemComponent } from './type-entretien-list-item.component';
import { TypeEntretienListComponent } from './type-entretien-list.component';
import { TypeEntretienComponent } from './type-entretien.component';
import { TypeEntretienService } from './type-entretien.service';

@NgModule({
  imports: [ShareModule],
  declarations: [TypeEntretienListComponent,TypeEntretienListItemComponent,TypeEntretienComponent],
	providers: [TypeEntretienService]
})
export class TypeEntretienModule { }
