import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PaysService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Enregistrement d'un pays
	 */
	public savePays(pays: any): Observable<Result> {
		//Enregistrement du pays
		return this.http.put<Result>(`${environment.baseUrl}/controller/Geographie/savePays`,pays);
	}

	/**
	 * Suppression d'un pays
	 */
	public deletePays(pays: any): Observable<Result> {
		//Suppression du pays
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Geographie/deletePays/${pays.idPays}`);
	}

	/**
	 * Synchronisation du référentiel
	 */
	public synchronize(): Observable<Result> {
		//Synchronisation du référentiel
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/synchronize`,null);
	}
}