import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';

@Component({
	templateUrl: './immatriculation-modele-catalogue-list-item.component.html'
})
export class ImmatriculationModeleCatalogueListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private modeleService: ModeleService) {
		//Héritage
		super();
	}

	/**
	 * Sélection du modèle
	 */
	selectModele() {
		//Sélection du modèle
		this.extraOptions.onModeleSelected(this.data.modeleCatalogue);
	}

	/**
	 * Affichage du modèle
	 */
	showModele() {
		//Vérification de la présence et de la source du modèle
		if (this.data.modeleCatalogue?.typeSource == 'EXTERNE_CATALOGUE') {
			//Affichage des informations détaillées du modèle
			this.modeleService.showModeleInformations(this.data.modeleCatalogue,{
				canSelect: true
			}).subscribe(() => {
				//Sélection du modèle
				this.extraOptions.onModeleSelected(this.data.modeleCatalogue);
			});
		}
	}
}