import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

import { ListViewComponent } from './list-view.component';
import { ListViewSearchComponent } from './list-view-search.component';
import { ListViewService } from './list-view.service';
import { ListViewItemComponent } from './list-view-item.component';
import { ListViewSelectorDirective } from './list-view-selector.directive';
import { InfiniteScrollComponent } from './infinite-scroll.component';
import { ListViewHeaderComponent } from './list-view-header.component';
import { ListViewFooterComponent } from './list-view-footer.component';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { ListViewActionsComponent } from './list-view-actions.component';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';
import { BasketModule } from 'src/app/share/components/basket/basket.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CommonModule,FormsModule,RouterModule,TranslateModule,CollapseModule,MatButtonModule,MatIconModule,MatButtonToggleModule,MatChipsModule,MatFormFieldModule,MatCheckboxModule,AutocompleteModule,BasketModule,PluralTranslateModule,SelectPickerModule,DatePickerModule,NumberModule,IconModule,PleaseWaitModule,FilterModule,MatSelectModule],
	declarations: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent,InfiniteScrollComponent,ListViewActionsComponent],
	exports: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent],
	providers: [ListViewService]
})
export class ListViewModule {}