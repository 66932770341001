import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { environment } from 'src/environments/environment';


@Injectable()
export class TenantService {
	/** Liste des timezones **/
	private readonly listeTimezones: Array<string> = ['EUROPE_PARIS'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private messagingService: MessagingService,private displayPipe: DisplayPipe,private progressService: ProgressService) {

	}

	/**
	 * Enregistrement d'un tenant
	 */
	public saveTenant(tenant: any): Observable<Result> {
		//Enregistrement du tenant
		return this.http.put<Result>(`${environment.baseUrl}/controller/Tenant/saveTenant`,tenant);
	}

	/**
	 * Chargement d'un tenant
	 */
	public loadTenant(idTenant: number,idContrat: number = 0): Observable<Result> {
		//Enregistrement du tenant
		return this.http.post<Result>(`${environment.baseUrl}/controller/Tenant/loadTenant/${idTenant}/${idContrat}`,null);
	}

	/**
	 * Suppression d'un tenant
	 */
	public deleteTenant(tenant: any): Observable<Result> {
		//Suppression du tenant
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Tenant/deleteTenant/${tenant.idTenant}`);
	}

	/**
	 * Clôture d'un tenant
	 */
	public closeTenant(tenant: any): Observable<{ result: Result }> {
		let subject: Subject<{ result: Result }> = new Subject();
		let messaging$: MessagingObservables;
		let refProgress: string = null;

		//Initialisation de la progression
		refProgress = this.progressService.init({
			icone: 'lock',
			libelle: this.translateService.instant('tenant.cloture.progression.title',{ tenant: this.displayPipe.transform(tenant.tenant,'tenant') }),
			nbTotal: 0,
			options: {
				canDismiss: false
			}
		});

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Tenant/closeTenant/${tenant.idTenant}`,
			outputPoint: `/messaging/Tenant/closeTenant/${tenant.idTenant}/status`
		}).onMessage({
			next: message => {
				//Vérification du message
				if (message.data?.message) {
					//Ajout du message à la liste
					this.progressService.updateProgress(refProgress,{
						message: message.data.message
					});
				}
			}
		}).onFinish({
			next: (message: any) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Actualisation des données de progression
				refProgress && this.progressService.refreshProgress(refProgress,1,0,1);

				//Retour du résultat
				subject.next({
					result: message.data as Result
				});

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Actualisation des données de progression
				refProgress && this.progressService.refreshProgress(refProgress,0,1,1);

				//Génération d'une erreur
				subject.error(null);

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Récupération de la liste des timezones
	 */
	public getListeTimezones(): Array<{ code: string,libelle: string }> {
		//Récupération de la liste des timezones
		return this.listeTimezones.map(code => ({
			code,
			libelle: this.translateService.instant('tenant.timezone.' + code)
		}));
	}

	/**
	 * Synchronisation des connecteurs pour un tenant
	 */
	public syncListeConnecteursInterface(tenant: any): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Tenant/syncListeConnecteursInterface/${tenant.idTenant}`,
			progressConfig: {
				libelle: this.translateService.instant('tenant.connecteur.synchronisation.progression',{ tenant: this.displayPipe.transform(tenant.tenant,'tenant') }),
				icone: 'autorenew'
			}
		}).onFinish({
			next: (message: any) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Retour des données
				subject.next(message.data);

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: (data: any) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Génération d'une erreur
				subject.error({ statut: data?.nbProcessed == 1 && data?.nbIgnored == 0 ? 'NOT_ESTABLISHED' : data?.nbIgnored == 1 && data?.nbProcessed == 0 ? 'DISABLED' : 'ERROR' });

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Création de l'environnement d'intégration d'un tenant
	 */
	public createEnvironnementIntegration(tenant: any): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init({
			method: 'POST',
			entryPoint: `controller/Tenant/createEnvironnementIntegration/${tenant.idTenant}`,
			progressConfig: {
				libelle: this.translateService.instant('tenant.connecteur.creation.progression',{ tenant: this.displayPipe.transform(tenant.tenant,'tenant') }),
				icone: 'autorenew'
			}
		}).onFinish({
			next: (message: any) => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Retour des données
				subject.next(message.data);

				//Finalisation du traitement
				subject.complete();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Génération d'une erreur
				subject.error(null);

				//Finalisation du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Enregistrement d'une autorisation
	 */
	public saveAutorisation(autorisation: any): Observable<Result> {
		//Enregistrement d'une autorisation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Tenant/saveAutorisation`,autorisation);
	}

	/**
	 * Suppression d'une autorisation
	 */
	public deleteAutorisation(autorisation: any): Observable<Result> {
		//Supression d'une autorisation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Tenant/deleteAutorisation/${autorisation.idAutorisation}`);
	}
}