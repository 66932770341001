<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.categorisation.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }">
					<label translate>vehicule.modele.categorisation.marque</label>
					<div>
						<autocomplete name="marque" type="marque" #marque="ngModel" [(ngModel)]="modele.marque"></autocomplete>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': modeleNiveau1.invalid }">
					<label translate>vehicule.modele.categorisation.modeleNiveau1</label>
					<div>
						<input type="text" name="modeleNiveau1" #modeleNiveau1="ngModel" class="form-control" [(ngModel)]="modele.modeleNiveau1"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': modeleNiveau2.invalid }">
					<label translate>vehicule.modele.categorisation.modeleNiveau2</label>
					<div>
						<input type="text" name="modeleNiveau2" #modeleNiveau2="ngModel" class="form-control" [(ngModel)]="modele.modeleNiveau2"/>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': modeleNiveau3.invalid }">
					<label translate>vehicule.modele.categorisation.modeleNiveau3</label>
					<div>
						<input type="text" name="modeleNiveau3" #modeleNiveau3="ngModel" class="form-control" [(ngModel)]="modele.modeleNiveau3"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': modeleNiveau4.invalid }">
					<label translate>vehicule.modele.categorisation.modeleNiveau4</label>
					<div>
						<input type="text" name="modeleNiveau4" #modeleNiveau4="ngModel" class="form-control" [(ngModel)]="modele.modeleNiveau4"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>