<fieldset ngModelGroup="modeleInformationFieldset" #modeleInformationFieldset="ngModelGroup">
	<div class="card focus">
		<div class="card-body card-padding">
			<div class="row">
				<div class="col-xs-12">
					<h4 translate>vehicule.modele.information.title</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': finition.invalid }">
						<label translate>vehicule.modele.information.finition</label>
						<div>
							<input type="text" name="finition" #finition="ngModel" class="form-control" [(ngModel)]="modele.finition" maxlength="300"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': carburant.invalid }">
						<label translate>vehicule.modele.information.carburant</label>
						<div>
							<autocomplete name="carburant" type="carburant" #carburant="ngModel" [filter]="{ typeSource: modele.typeSource }" [(ngModel)]="modele.information.carburant"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': genre.invalid }">
						<label translate>vehicule.modele.information.genre</label>
						<div>
							<autocomplete name="genre" type="genre" #genre="ngModel" [filter]="{ typeSource: modele.typeSource }" [(ngModel)]="modele.information.genre"></autocomplete>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': nbPortes.invalid }">
						<label translate>vehicule.modele.information.nbPortes</label>
						<div class="half">
							<input type="text" name="nbPortes" #nbPortes="ngModel" class="form-control" [(ngModel)]="modele.nbPortes" nNumber/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeCarrosserie.invalid }">
						<label translate>vehicule.modele.information.typeCarrosserie</label>
						<div>
							<autocomplete name="typeCarrosserie" type="typeCarrosserie" #typeCarrosserie="ngModel" [(ngModel)]="modele.information.typeCarrosserie"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': segmentation.invalid }">
						<label translate>vehicule.modele.information.segmentation</label>
						<div>
							<input type="text" name="segmentation" #segmentation="ngModel" class="form-control" [(ngModel)]="modele.information.segmentation"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</fieldset>