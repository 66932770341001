import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { Result } from 'src/app/domain/common/http/result';
import { ConfigurationService } from 'src/app/components/vehicule/modele/configuration/configuration.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './grille-attribution-modele-list-item.component.html'
})
export class GrilleAttributionModeleListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionModeleService: GrilleAttributionModeleService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private configurationService: ConfigurationService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteModele = this.deleteModele.bind(this);
	}

	/**
	 * Suppression d'un modèle
	 */
	deleteModele(lien: any = this.data,close?: Function) {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Vérification des cotations non annulées
					this.grilleAttributionModeleService.checkCotationsNonAnnulees(lien.idGrilleAttribution,lien.idModele).subscribe({
						next: (result: Result) => {
							//Vérification du retour
							if (result && result.data && result.data.nbCotationsNonAnnulees > 0) {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('vehicule.grilleAttribution.modele.cotationNonAnnulee'));
							} else {
								//Suppression du modèle
								this.grilleAttributionModeleService.deleteGrilleAttributionModele(lien.idGrilleAttribution,lien.idModele).subscribe({
									next: (result: Result) => {
										//Vérification du code d'erreur
										if (result.codeErreur == 0) {
											//Message d'information
											this.toastrService.success(this.translateService.instant('actions.suppression.success'));

											//Suppression de l'objet
											this.onObjectChange(null);

											//Fermeture du formulaire d'édition
											close?.(null);
										} else {
											//Message d'erreur
											this.toastrService.error(this.translateService.instant('actions.suppression.error'));
										}
									}
								});
							}
						}
					})
				}
			}
		});
	}

	/**
	 * Accès au formulaire complexe du modèle de la grille d'attribution
	 */
	consultGrilleAttributionModele() {
		//Redirection vers le modèle
		this.layoutService.goToByState('listeGrillesAttribution-grilleAttributionModele',{
			routeParams: {
				idGrilleAttribution: this.data.idGrilleAttribution,
				idModele: this.data.idModele
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage de la configuration du modèle
	 */
	showConfiguration() {
		//Affichage de la configuration du modèle
		this.configurationService.showConfiguration({
			type: 'GRILLE_ATTRIBUTION',
			idModele: this.data.idModele,
			idEntite: this.data.idGrilleAttribution,
			isEdition: true
		}).subscribe();
	}

	/**
	 * Calcul du montant d'une configuration de modèle
	 */
	computeMontantForModele() {
		//Retour du prix formatté
		return this.data.modele?.information?.commercialisationPrix ? (this.data.modele?.information?.genre?.modeAffichagePrix == 'HT' ? (this.data.modele?.configuration?.prixHTRemise ? this.data.modele?.configuration?.prixHTRemise : this.data.modele?.configuration?.prixHT) : (this.data.modele?.configuration?.prixTTCRemise ? this.data.modele?.configuration?.prixTTCRemise : this.data.modele?.configuration?.prixTTC)) : '';
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.LienGrilleAttributionModele',this.data.idGrilleAttribution,this.data.idModele);
	}
}