import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AppState } from 'src/app/domain/appstate';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AffectationEcheanceDetailComponent } from './affectation-echeance-detail.component';

@Component({
	selector: 'affectation-echeance',
	templateUrl: './affectation-echeance.component.html'
})
export class AffectationEcheanceComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() affectationEcheance: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,private layoutService: LayoutService,private store: Store<AppState>,private rightService: RightService,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde de l'échéance de l'affectation
		this.affectationEcheance = Object.assign({},this.affectationEcheance);

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).subscribe(user => {
			//Définition de l'utilisateur
			this.user = user;
		});
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	goToVehicule(): void {
		//Navigation vers le véhicule
		this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.affectationEcheance.affectation.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers le conducteur
	 */
	goToConducteur(): void {
		//Navigation vers le conducteur
		this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'consultation') && this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: this.affectationEcheance.affectation.conducteur.idConducteur
			},
			withGoBack: true
		});
	}

	/**
	 * Affichage du détail du calcul d'avantage en nature
	 */
	showDetailCalculAEN() {
		//Affichage du détail du calcul d'avantage en nature
		this.bsModalService.show(AffectationEcheanceDetailComponent,{
			initialState: {
				affectationEcheance: this.affectationEcheance
			}
		});
	}
}