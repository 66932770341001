<form #compteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>comptabilite.compte.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': planComptable.invalid }" *ngIf="filter.isShowPlanComptable">
			<label translate>comptabilite.compte.planComptable</label>
			<div>
				<autocomplete name="planComptable" type="planComptable" #planComptable="ngModel" [(ngModel)]="compte.planComptable" (ngModelChange)="compte.pays = compte.planComptable?.pays" required></autocomplete>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
			<label translate>comptabilite.compte.numero</label>
			<div>
				<input type="text" class="form-control" name="numero" #numero="ngModel" [(ngModel)]="compte.numero" required maxlength="100"/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>comptabilite.compte.libelle</label>
			<div>
				<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="compte.libelle" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
			<label translate>comptabilite.compte.type.item</label>
			<div>
				<selectpicker name="type" #type="ngModel" [(ngModel)]="compte.type" required [disabled]="filter.listeTypes?.length == 1">
					<mat-option *ngFor="let type of listeTypesCompte" [value]="type.code">{{ type.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
			<label translate>comptabilite.compte.pays</label>
			<div>
				<autocomplete name="pays" type="pays" #pays="ngModel" [(ngModel)]="compte.pays" required [disabled]="filter.idPlanComptable"></autocomplete>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveItem();" [disabled]="compteForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>