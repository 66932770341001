<form class="m-t-20">
	<div class="text-center">
		<div class="listview" *ngIf="(null | right:'creation') && (typeReferentiel != TypeReferentiel.STANDARD || rightService.isRoot()) && (extraction.custom || !extraction.extractionOrigine) && extraction.listeFields?.length > 0">
			<div class="lv-body">
				<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="addField()">
					<h5><a [routerLink]="[]" translate>extraction.actions.editFields</a></h5>
				</div>
			</div>
		</div>
	</div>
	<div class="lv-sm lv-bordered p-t-25">
		<div class="lv-body">
			<div class="lv-item media p-t-0 m-t-0" *ngFor="let field of extraction.listeFields">
				<div class="media-body d-flex align-items-center">
					<div class="pull-left lv-avatar">{{ field.position }}</div>
					<div class="lv-title">{{ field.libelle }}</div>
				</div>
			</div>
			<div class="text-center p-10" *ngIf="!extraction.listeFields?.length">
				<h5 translate>liste.empty</h5>
			</div>
		</div>
	</div>
</form>