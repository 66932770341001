import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ArticleCRMService {
	/** Liste des types d'objets facturables **/
	private readonly listeTypesObjet: Array<string> = ['ANTAI','VEHICULE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des types d'objets facturables traduits
	 */
	getListeTypesObjet(): Array<any> {
		//Création de la correspondance entre un code et son libellé
		return this.listeTypesObjet.map(code => ({
			code,
			libelle: this.translateService.instant('articleCRM.objet.'+code)
		}));
	}

	/**
	 * Enregistrement d'un article CRM
	 */
	public saveArticleCRM(articleCRM: any): Observable<Result> {
		//Enregistrement de l'article CRM
		return this.http.put<Result>(`${environment.baseUrl}/controller/Commerce/saveArticleCRM`,articleCRM);
	}

	/**
	 * Suppression d'un article CRM
	 */
	public deleteArticleCRM(articleCRM: any): Observable<Result> {
		//Suppression de l'article CRM
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Commerce/deleteArticleCRM/${articleCRM.idArticle}`);
	}
}