<div>
	<input *ngIf="!_value" class="form-control" [(ngModel)]="searchedValue" typeaheadOptionField="label" [typeahead]="suggestions$" [typeaheadAsync]="true" [placeholder]="placeholder" (typeaheadOnSelect)="onSuggestionSelect($event)" [optionsListTemplate]="listeItemsTemplate" (blur)="onBlur($event)" (keydown)="reset()" [disabled]="disabled"/>

	<div *ngIf="_value" class="adresse-content">
		<span>
			<a [routerLink]="[]" (click)="showCreation(searchMode,!disabled,_value)">{{ _value?.label }}</a>
		</span>
		<icon name="delete" class="icon-hc-2x m-l-10 clickable" *ngIf="!disabled" (click)="removeAdresse()"></icon>
	</div>
</div>
<ng-template #listeItemsTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
	<ul class="dropdown-menu">
		<li *ngFor="let match of matches" [class.active]="typeaheadTemplateMethods.isActive(match)" (click)="typeaheadTemplateMethods.selectMatch(match,$event);" (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
			<a href="#" tabindex="-1" [class.add-item]="match?.item?.typeSaisie === 'MANUEL'">
				<span>{{ match.value }}</span>
			</a>
		</li>
	</ul>
</ng-template>