import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { UserUserResponsableListItemComponent } from './user-user-responsable-list-item.component';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { UserService } from 'src/app/components/user/user.service';

@Component({
	selector: 'user-user-responsable-list',
	templateUrl: './user-user-responsable-list.component.html'
})
export class UserUserResponsableListComponent extends PageContentItem implements OnInit {
	/** Utilisateur **/
	@Input() user: any;

	/** Fonction d'édition **/
	@Input() addUserResponsable: Function;

	/** Résumé **/
	@Input() resume: { nbUsersResponsable: number };

	/** Rafraichissement de l'onglet **/
	@Input() onRefresh: Function;

	/** Données **/
	liste: ListView<any,UserUserResponsableListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,UserUserResponsableListItemComponent>({
			uri: () => `/controller/User/filtreUsersResponsable/${this.user.idUser}`,
			component: UserUserResponsableListItemComponent,
			isLocal: true,
			onRefresh: (liste,result) => {
				//Vérification du nombre de filtres
				if (liste.listeSelectedFilters?.length === 0) {
					//Alimentation du résumé
					this.resume.nbUsersResponsable = result.totalElements;
				} else {
					//Chargement du résumé
					this.userService.loadResume(this.user.idUser).subscribe({
						next: (result: Result) => {
							//Vérification de la présence d'une erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR)
								//Définition du résumé
								Object.assign(this.resume,{ nbUsersResponsable: result.data?.nbUsersResponsable || 0 });
						}
					});
				}
			},
			extraOptions: {
				addUserResponsable: this.addUserResponsable
			},
			listeFilters: [{
				clef: '*service',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'service'
				}
			},{
				clef: '*societe',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe'
				}
			},{
				clef: '*user',
				isDefault: true,
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user'
				}
			}],
			defaultOrder: 'type,user.nom,user.prenom,user.matricule,societe.libelle,service.libelle'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Raffraichissement de l'onglet
		this.onRefresh();
	}
}