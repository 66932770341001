import { Component,OnInit } from '@angular/core';

import { LayoutService } from 'src/app/share/layout/layout.service';
import { AccessForbiddenService } from './access-forbidden.service';

@Component({
	templateUrl: './access-forbidden.component.html'
})
export class AccessForbiddenComponent implements OnInit {
	/**
	 * Constructeur
	 */
	constructor(private accessForbiddenService: AccessForbiddenService,private layoutService: LayoutService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de l'utilisateur
		this.accessForbiddenService.checkAccess();
	}

	/**
	 * Retour à l'écran d'accueil
	 */
	goBack() {
		//Navigation vers le dashboard
		this.layoutService.goToByState('dashboard');
	}
}