import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { CalendarService } from './calendar.service';
import { CalendarComponent } from './calendar.component';
import { CalendarMaximizerComponent } from './calendar-maximizer.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule,PleaseWaitModule],
	declarations: [CalendarComponent,CalendarMaximizerComponent],
	exports: [CalendarComponent,CalendarMaximizerComponent],
	providers: [CalendarService]
})
export class CalendarModule {}