<form #workflowDetailValideurSelectionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>workflow.detail.valideur.selection.title</h4>
	</div>
	<div class="modal-body">
		<div class="p-t-20 p-b-20">
			<mat-radio-group [(ngModel)]="typeValideur" name="choix">
				<mat-radio-button value="VALIDEUR_DYNAMIQUE"><span translate>workflow.detail.valideur.dynamique</span></mat-radio-button>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="VALIDEUR_NOMME"><span translate>workflow.detail.valideur.nomme</span></mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveWorkflowDetailValideurSelection()" [disabled]="workflowDetailValideurSelectionForm.invalid">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>
