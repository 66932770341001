import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteAuxiliaireEditComponent } from './compte-auxiliaire-edit.component';
import { CompteAuxiliaireFournisseurEditComponent } from './compte-auxiliaire-fournisseur-edit.component';
import { CompteAuxiliaireFournisseurListComponent } from './compte-auxiliaire-fournisseur-list.component';
import { CompteAuxiliaireUserEditComponent } from './compte-auxiliaire-user-edit.component';
import { CompteAuxiliaireUserListComponent } from './compte-auxiliaire-user-list.component';
import { CompteAuxiliaireService } from './compte-auxiliaire.service';

@Component({
	selector: 'compte-auxiliaire',
	templateUrl: './compte-auxiliaire.component.html'
})
export class CompteAuxiliaireComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Compte auxiliaire courant **/
	public compteAuxiliaire: any;

	/** Résumé **/
	private resume: { nbFournisseurs: number,nbUsers: number } = {
		nbFournisseurs: 0,
		nbUsers: 0
	}

	/** Restriction sur le compte **/
	public restriction: { isRestricted: boolean } = null;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private compteAuxiliaireService: CompteAuxiliaireService,private compteService: CompteService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) {

	}

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.comptabilite.CompteAuxiliaire';

	/** Récupération de l'identifiant du compte auxiliaire à charger **/
	getIdObject: () => number = () => this.compteAuxiliaire?.idCompteAuxiliaire || 0;

	/** Récupération de l'entité portant les pièces jointes **/
	getOwningEntity: () => LinksOwningEntity = () => this.compteAuxiliaire;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'comptabilite.compteAuxiliaire.numero',
				key: 'numero'
			},{
				libelle: 'comptabilite.compteAuxiliaire.libelle',
				key: 'libelle'
			},{
				libelle: 'comptabilite.compteAuxiliaire.compteCollectif',
				key: (data) => `${data.compte.numero} (${data.compte.libelle})`
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editCompteAuxiliaire(),
				isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editCompteAuxiliaire(),
				isVisible: () => !this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
				type: TypeAction.CONSULTATION
			},{
				libelle: 'comptabilite.compteAuxiliaire.actions.accederPlanComptable',
				doAction: () => this.goToPlanComptable(),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation'),
				type: TypeAction.REDIRECTION
			},{
				libelle: 'comptabilite.compteAuxiliaire.actions.accederCompteCollectif',
				doAction: () => this.goToCompteCollectif(),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation'),
				type: TypeAction.REDIRECTION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteCompteAuxiliaire(),
				isVisible: () => this.rightService.hasRight(null,'suppression') && !this.restriction?.isRestricted,
				type: TypeAction.SUPPRESSION
			}],
			listeElements: [{
				type: 'USER',
				libelle: 'comptabilite.compteAuxiliaire.elements.user',
				component: CompteAuxiliaireUserListComponent,
				retrieveComponentData: () => ({
					compteAuxiliaire: this.compteAuxiliaire,
					resume: this.resume,
					restriction: this.restriction,
					addUser: this.addUser.bind(this)
				}),
				doAction: () => this.addUser(),
				count: () => this.resume.nbUsers,
				libelleAction: this.translateService.instant('comptabilite.compteAuxiliaire.elements.user.ajouter'),
				isVisible: () => this.compteAuxiliaire.compte?.type == 'TIERS',
				isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
			},{
				type: 'FOURNISSEUR',
				libelle: 'comptabilite.compteAuxiliaire.elements.fournisseur',
				component: CompteAuxiliaireFournisseurListComponent,
				retrieveComponentData: () => ({
					compteAuxiliaire: this.compteAuxiliaire,
					resume: this.resume,
					restriction: this.restriction,
					addFournisseur: this.addFournisseur.bind(this)
				}),
				doAction: () => this.addFournisseur(),
				count: () => this.resume.nbFournisseurs,
				libelleAction: this.translateService.instant('comptabilite.compteAuxiliaire.elements.fournisseur.ajouter'),
				isVisible: () => this.compteAuxiliaire.compte?.type == 'TIERS',
				isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
			}]
		};

		//Récupération de l'identifiant du compte auxiliaire à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idCompteAuxiliaire);
			}
		});
	}

	/**
	 * Redirection vers le plan comptable
	 */
	private goToPlanComptable() {
		//Navigation vers le plan comptable
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: this.compteAuxiliaire.planComptable.idPlanComptable
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers le compte collectif
	 */
	private goToCompteCollectif() {
		//Navigation vers le compte collectif
		this.layoutService.goToByState('comptabiliteReferentiel-listeComptes-compte',{
			routeParams: {
				idCompte: this.compteAuxiliaire.compte.idCompte
			},
			withGoBack: true
		});
	}

	/**
	 * Edition du compte auxiliaire
	 */
	private editCompteAuxiliaire() {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteAuxiliaireEditComponent,{
			data: {
				compteAuxiliaire: cloneDeep(this.compteAuxiliaire),
				deleteCompteAuxiliaire: this.deleteCompteAuxiliaire.bind(this)
			}
		}).subscribe({
			next: (compteAuxiliaire: any) => {
				//Vérification des données
				if (compteAuxiliaire) {
					//Mise à jour du compte auxiliaire
					this.compteAuxiliaire = Object.assign(this.compteAuxiliaire,compteAuxiliaire);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.compteAuxiliaire);
				}
			}
		});
	}

	/**
	 * Suppression du compte auxiliaire
	 */
	private deleteCompteAuxiliaire() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteAuxiliaireService.deleteCompteAuxiliaire(this.compteAuxiliaire))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idCompteAuxiliaire: number = this.compteAuxiliaire.idCompteAuxiliaire) {
		//Chargement du compte auxiliaire
		this.compteAuxiliaireService.loadCompteAuxiliaire(idCompteAuxiliaire).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du compte auxiliaire
					this.compteAuxiliaire = result.data?.compteAuxiliaire;

					//Définition du résumé
					this.resume = {
						nbFournisseurs: result.data?.nbFournisseurs || 0,
						nbUsers: result.data?.nbUsers || 0
					}

					//Définition de la restriction
					this.restriction = this.compteService.getRestriction(this.compteAuxiliaire?.planComptable?.listeSocietes);

					//Vérification de l'identifiant du compte auxiliaire
					if (!this.compteAuxiliaire?.idCompteAuxiliaire) {
						//Création d'un objet vierge
						this.compteAuxiliaire = {};

						//Edition du compte auxiliaire
						this.editCompteAuxiliaire();
					}
				}
			}
		});
	}

	/**
	 * Ajout/modification d'un lien vers un fournisseur
	 */
	private addFournisseur(lienFournisseur: any = { compte: Object.assign({},this.compteAuxiliaire.compte),compteAuxiliaire: Object.assign({},this.compteAuxiliaire),type: 'FOURNISSEUR' },deleteLienFournisseur?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteAuxiliaireFournisseurEditComponent,{
			data: {
				lienFournisseur: cloneDeep(lienFournisseur),
				deleteLienFournisseur
			}
		},'sub').subscribe({
			next: (data: { lienFournisseur: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FOURNISSEUR',lienFournisseur.idLien != data.lienFournisseur?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienFournisseur.idLien && data.lienFournisseur?.idLien)
						//Incrémentation du compteur
						this.resume.nbFournisseurs++;
					else if (lienFournisseur.idLien && !data.lienFournisseur?.idLien)
						//Décrémentation du compteur
						this.resume.nbFournisseurs--;

					//Mise à jour du lien
					lienFournisseur = Object.assign(lienFournisseur,data.lienFournisseur);
				}
			}
		});
	}

	/**
	 * Ajout/modification d'un lien vers un collaborateur
	 */
	private addUser(lienUser: any = { compte: Object.assign({},this.compteAuxiliaire.compte),compteAuxiliaire: Object.assign({},this.compteAuxiliaire),type: 'USER' },deleteLienUser?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteAuxiliaireUserEditComponent,{
			data: {
				lienUser: cloneDeep(lienUser),
				deleteLienUser
			}
		},'sub').subscribe({
			next: (data: { lienUser: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('USER',lienUser.idLien != data.lienUser?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienUser.idLien && data.lienUser?.idLien)
						//Incrémentation du compteur
						this.resume.nbUsers++;
					else if (lienUser.idLien && !data.lienUser?.idLien)
						//Décrémentation du compteur
						this.resume.nbUsers++;

					//Mise à jour du lien
					lienUser = Object.assign(lienUser,data.lienUser);
				}
			}
		});
	}
}