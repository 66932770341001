import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AppState } from 'src/app/domain/appstate';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { UserService } from './user.service';

@Component({
	selector: 'user-add',
	templateUrl: './user-add.component.html'
})
export class UserAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Utilisateur **/
	public user: any;

	/** Liste des civilitées */
	public listeTypesCivilite: Array<string> = this.userService.getListeTypesCivilite();

	/** Taille de la popup **/
	public static modalSize: string = 'modal-xs';

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<AutocompleteAddComponent>,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection du tenant
		this.store.select(state => state.session.user?.tenant).subscribe({
			next: tenant => {
				//Définition de l'utilisateur
				this.user = {
					tenant,
					enabled: true
				}
			}
		})
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem(): void {
		//Enregistrement de l'élément
		this.userService.saveUser(this.user).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de l'utilisateur
					this.addedItem = result.data.user;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du matricule
					if (result.data.doublon & Math.pow(2,0)) {
						//Ajout du matricule
						listeDoublons.push(this.translateService.instant('actions.doublon.matricule'));

						//Ajout de la société
						listeDoublons.push(this.translateService.instant('actions.doublon.societe'));
					}

					//Vérification de l'identifiant
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.identifiant'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
