<div class="pull-left lv-avatar">
	<icon name="directions_car"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="selectModele()">{{ data.modeleCatalogue.marque.libelle + ' ' + data.modeleCatalogue.libelle + (data.modeleCatalogue.finition ? ' (' + data.modeleCatalogue.finition + ')' : '') }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.modeleCatalogue.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.modeleCatalogue.reference"><span translate>vehicule.immatriculation.modele.reference</span>&#160;:&#160;{{ data.modeleCatalogue.reference }}</li>
		<li *ngIf="data.modeleCatalogue.modeleNiveau1"><span translate>vehicule.immatriculation.modele.categorisation</span>&#160;:&#160;<span>{{ (data.modeleCatalogue.modeleNiveau1 + '/' + data.modeleCatalogue.modeleNiveau2 + '/' + data.modeleCatalogue.modeleNiveau3).substring(0,20) }}&#160;<icon *ngIf="(data.modeleCatalogue.modeleNiveau1 + '/' + data.modeleCatalogue.modeleNiveau2 + '/' + data.modeleCatalogue.modeleNiveau3).length > 20" [tooltip]="data.modeleCatalogue.modeleNiveau1 + '/' + data.modeleCatalogue.modeleNiveau2 + '/' + data.modeleCatalogue.modeleNiveau3" name="more_horiz"></icon></span></li>
	</ul>
	<div class="lv-actions actions">
		<a [routerLink]="[]" (click)="showModele();"><icon name="visibility"></icon></a>
	</div>
</div>