<div class="account">
	<div class="header">
		<icon name="person" class="icon-hc-4x"></icon>
		<div *ngIf="!session?.userOrigine && session?.user">
			<p class="user">
				<span>{{ session?.user.prenom + ' ' + session?.user.nom }}</span>
			</p>
			<span class="type" *ngIf="session?.user.type == 'ADMINISTRATEUR'" translate>layout.header.account.administrateur</span>
			<span class="societe" *ngIf="session?.user.service">{{ session?.user.service.libelle }}</span>
		</div>
		<div *ngIf="session?.userOrigine && session?.user?.type != 'VIRTUAL'">
			<p class="user">
				<span>{{ session?.userOrigine.prenom + ' ' + session?.userOrigine.nom }}</span>
			</p>
			<span class="type" *ngIf="session?.userOrigine.type == 'ADMINISTRATEUR'" translate>layout.header.account.administrateur</span>
			<span class="societe" *ngIf="session?.userOrigine.service">{{ session?.userOrigine.service.libelle }}</span>
		</div>
	</div>
	<div class="clearfix"></div>
	<ng-container *ngIf="session?.user && session?.user?.type != 'VIRTUAL'">
		<div class="d-flex justify-content-center" *ngIf="!session?.userOrigine">
			<button mat-stroked-button (click)="showAccount('PERSONAL_DATA');"><span translate>layout.header.account.manageAccount</span></button>
		</div>

		<ul *ngIf="!session?.userOrigine">
			<li (click)="showAccount('PASSWORD');" matRipple>
				<icon name="lock"></icon>
				<span translate>layout.header.account.changePassword</span>
			</li>
			<li (click)="showAccount('CONTACT_ADMIN');" *ngIf="session?.user?.type != 'ADMINISTRATEUR'" matRipple>
				<icon name="admin_panel_settings"></icon>
				<span translate>layout.header.account.contactAdmin</span>
			</li>
		</ul>
		<div class="connect" *ngIf="session?.userOrigine != null && session?.user">
			<div class="connect-as">
				<p><span translate>layout.header.account.connectedAs</span></p>
				<p class="user" data-test-id="userConnectedAsTestId">
					<span>{{ session?.user.prenom + ' ' + session?.user.nom }}</span>
				</p>
			</div>
			<div class="d-flex justify-content-center">
				<button mat-stroked-button (click)="logoutToOriginalUser();"><span translate>layout.header.account.goBack</span></button>
			</div>
		</div>
	</ng-container>
	<div class="logout m-5 m-b-10">
		<button mat-button (click)="logout(true);">
			<mat-icon class="m-r-5">logout</mat-icon>
			<span translate>layout.header.account.logout</span>
		</button>
	</div>
</div>