import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContraventionService {
	/** Liste des statuts **/
	private listeStatuts: Array<string> = ['IMPAYEE','PAYEE'];

	/** Liste des types d'infraction **/
	private listeTypesInfraction: Array<string> = ['FORFAIT_POST_STATIONNEMENT','AMENDE'];

	/** Liste des types de désignation **/
	private listeTypesDesignation: Array<{ code: string,_type?: string }> = [{
		code: 'CONDUCTEUR',
		_type: 'com.notilus.data.vehicule.ContraventionDesignationConducteur'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Récupération de la liste des statuts
	 */
	public getListeStatuts(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des statuts
		return this.listeStatuts.map(code => ({
			code,
			libelle: this.translateService.instant(`infraction.contravention.statut.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'infraction
	 */
	public getListeTypesInfraction(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'infraction
		return this.listeTypesInfraction.map(code => ({
			code,
			libelle: this.translateService.instant(`infraction.contravention.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de désignation
	 */
	public getListeTypesDesignation(): Array<{ code: string,_type?: string,libelle: string }> {
		//Retour de la liste des types de désignation
		return this.listeTypesDesignation.map(t => ({
			...t,
			libelle: this.translateService.instant(`infraction.contravention.designation.type.${t.code}`)
		}));
	}

	/**
	 * Chargement de la contravention
	 */
	public loadContravention(idContravention: number): Observable<Result> {
		//Chargement de la contravention
		return this.http.post<Result>(`${environment.baseUrl}/controller/Contravention/loadContravention/${idContravention}`,null);
	}

	/**
	 * Suppression de la contravention
	 */
	public deleteContravention(contravention: any): Observable<Result> {
		//Suppression de la contravention
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Contravention/deleteContravention/${contravention.idContravention}`);
	}

	/**
	 * Enregistrement de la contravention
	 */
	public saveContravention(contravention: any,isPublish: boolean): Observable<Result> {
		//Enregistrement de la contravention
		return this.http.put<Result>(`${environment.baseUrl}/controller/Contravention/saveContravention/${isPublish}`,contravention);
	}
}