import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReferentielListComponent } from './referentiel-list.component';
import { ReferentielListService } from './referentiel-list.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ReferentielListComponent],
	providers: [ReferentielListService]
})
export class ReferentielListModule {}
