<div class="card-padding p-t-20">
	<div class="listview lv-bordered">
		<div class="lv-body">
			<div class="lv-item media">
				<div class="media-body">
					<div class="lv-title clickable pull-left" (click)="recapitulatif.isPrixToggled = !recapitulatif?.isPrixToggled; recapitulatif.isListeOptionsToggled = false;">
						<span class="pull-left" [translate]="'vehicule.modele.configuration.recapitulatif.prixTotal' + (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? 'HT' : 'TTC')"></span>
						<span class="label label-info pull-left p-5 p-l-10 p-r-10 m-l-5">{{ getPrixNet() | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
					</div>
					<ul class="pull-right actions m-t-0">
						<li><a [routerLink]="[]" (click)="recapitulatif.isPrixToggled = !recapitulatif?.isPrixToggled; recapitulatif.isListeOptionsToggled = false;"><icon [name]="!recapitulatif?.isPrixToggled ? 'chevron_right' : 'expand_more'"></icon></a></li>
					</ul>
				</div>
				<div [collapse]="!recapitulatif?.isPrixToggled" class="collapse p-t-10">
					<div class="listview lv-bordered">
						<div class="lv-body" style="padding-left: 100px; padding-right: 100px;">
							<div class="lv-item media">
								<div class="media-body">
									<div class="lv-title">
										<span class="pull-left" [translate]="'vehicule.modele.configuration.recapitulatif.prixBase' + (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? 'HT' : 'TTC')"></span>
										<span class="label label-info pull-right p-5 p-l-10 p-r-10 m-l-5">{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? configuration?.prixHTHorsOption : configuration?.prixTTCHorsOption) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
									</div>
								</div>
							</div>
							<div class="lv-item media">
								<div class="media-body">
									<div class="lv-title">
										<span class="pull-left" [translate]="'vehicule.modele.configuration.recapitulatif.prixOption' + (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? 'HT' : 'TTC')"></span>
										<span class="label label-info pull-right p-5 p-l-10 p-r-10 m-l-5">{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? (configuration?.prixHT - configuration?.prixHTHorsOption) : (configuration?.prixTTC - configuration?.prixTTCHorsOption)) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
									</div>
								</div>
							</div>
							<div class="lv-item media" *ngIf="!configuration?.pourcentageRemise">
								<div class="media-body">
									<div class="lv-title">
										<span class="pull-left" translate>vehicule.modele.configuration.recapitulatif.aucuneRemise</span>
										<span class="label price-info-exclusion pull-right p-5 p-l-10 p-r-10 m-l-5"><icon name="remove"></icon>&nbsp;<span>{{ getMontantRemise() | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span></span>
									</div>
								</div>
							</div>
							<div class="lv-item media" *ngIf="configuration?.pourcentageRemise > 0">
								<div class="media-body">
									<div class="lv-title">
										<span class="pull-left" *ngIf="configuration?.pourcentageRemise > 0" translate [translateParams]="{remise: configuration?.pourcentageRemise}">vehicule.modele.configuration.recapitulatif.pourcentageRemise</span>
										<span class="label price-info-exclusion pull-right p-5 p-l-10 p-r-10 m-l-5"><icon name="remove"></icon>&nbsp;<span>{{ getMontantRemise() | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="lv-item media">
				<div class="media-body">
					<div class="lv-title clickable pull-left" (click)="recapitulatif.isListeOptionsToggled = !recapitulatif?.isListeOptionsToggled; recapitulatif.isPrixToggled = false;">
						<span class="pull-left" translate [translateParams]="{ nb: configuration?.listeDetails?.length || 0 }">vehicule.modele.configuration.recapitulatif.optionsIntegrees</span>
					</div>
					<ul class="pull-right actions m-t-0">
						<li><a [routerLink]="[]" (click)="recapitulatif.isListeOptionsToggled = !recapitulatif?.isListeOptionsToggled; recapitulatif.isPrixToggled = false;"><icon [name]="!recapitulatif?.isListeOptionsToggled ? 'chevron_right' : 'expand_more'"></icon></a></li>
					</ul>
				</div>
				<div [collapse]="!recapitulatif?.isListeOptionsToggled" class="collapse p-t-10">
					<configuration-option-list [configuration]="configuration" [isShowCheckbox]="false"></configuration-option-list>
				</div>
			</div>
		</div>
	</div>
</div>