import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ReferentielEntiteList,TypeEntiteWithReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from '../messaging/messaging.service';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { Page } from 'src/app/domain/common/http/list-result';

/**
 * Service de gestion des référentiels mixtes
 */
@Injectable()
export class ReferentielService {
	/** Liste des types de référentiel mixte organisés par groupes **/
	private listeTypes: { [key: string | TypeEntiteWithReferentiel | string]: Array<{ code: TypeEntiteWithReferentiel,type: string,droit: TypeDroit,state?: string,url?: string }> } = {
		[TypeEntiteWithReferentiel.DEVISE]: [{
			code: TypeEntiteWithReferentiel.DEVISE,
			type: 'com.notilus.data.devise.Devise',
			droit: TypeDroit.ADMIN_DEVISE,
			state: 'listeDevises'
		}],
		[TypeEntiteWithReferentiel.FOURNISSEUR]: [{
			code: TypeEntiteWithReferentiel.FOURNISSEUR,
			type: 'com.notilus.data.fournisseur.Fournisseur',
			droit: TypeDroit.ADMIN_FOURNISSEUR,
			url: '/Fournisseur/listeFournisseurs'
		}],
		[TypeEntiteWithReferentiel.TEMPLATE_MAIL]: [{
			code: TypeEntiteWithReferentiel.TEMPLATE_MAIL,
			type: 'com.notilus.data.template.TemplateMail',
			droit: TypeDroit.ADMIN_TEMPLATE_MAIL,
			url: '/TemplateMail/listeTemplateMails'
		}],
		[TypeEntiteWithReferentiel.STRUCTURE_EXPORT]: [{
			code: TypeEntiteWithReferentiel.STRUCTURE_EXPORT,
			type: 'com.notilus.data.export.StructureExport',
			droit: TypeDroit.ADMIN_PLAN_COMPTABLE,
			url: '/StructureExport/listeStructuresExport'
		}],
		[TypeEntiteWithReferentiel.WORKFLOW_MOTIF]: [{
			code: TypeEntiteWithReferentiel.WORKFLOW_MOTIF,
			type: 'com.notilus.data.workflow.WorkflowMotif',
			droit: TypeDroit.ADMIN_WORKFLOW_MOTIF,
			url: '/Workflow/listeMotifs'
		}]
	};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private messagingService: MessagingService,private rightService: RightService) {}

	/**
	 * Récupération de la liste des types de référentiel mixte
	 */
	getListeTypeReferentiels() {
		//Retour de la liste des types organisés en groupes
		return this.listeTypes;
	}

	/**
	 * Dénombrement des éléments à synchroniser
	 */
	countItems(listeEntites: string[]): Observable<Result> {
		//Dénombrement des éléments à synchroniser
		return this.http.post<Result>(`${environment.baseUrl}/controller/Referentiel/countItems`,listeEntites);
	}

	/**
	 * Dénombrement des éléments locaux
	 */
	countItemsForLocal(url: string): Observable<Page<any>> {
		//Dénombrement des éléments locaux
		return this.http.post<Page<any>>(environment.baseUrl + url,{
			nbObjetsParPage: 1
		});
	}

	/**
	 * Copie de l'entité en local
	 */
	copyEntiteLocal(idEntite: number,type: string): Observable<Result> {
		//Copie en local de l'entité
		return this.http.post<Result>(`${environment.baseUrl}/controller/Referentiel/copyEntiteLocal/`+idEntite,null,{
			params: {
				classType: type
			}
		});
	}

	/**
	 * Ajout de l'action d'import en masse à la liste
	 */
	addActionImportToListe(referentielEntiteListe: ReferentielEntiteList) {
		//Ajout de l'action d'import en masse à la liste
		referentielEntiteListe.liste.listeActions.push({
			icon: 'download',
			messagingOptions: {
				entryPoint: `${environment.baseUrl}/controller/Referentiel/copyAllEntiteLocal/${referentielEntiteListe.type}`,
			},
			onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.doActionReferentiel(messagingOptions).subscribe({
				complete: () => {
					//Rechargement de la liste
					referentielEntiteListe.liste.refresh();

					//Dénombrement des éléments
					this.countItems([referentielEntiteListe.type]).subscribe(result => {
						//Mise à jour du nombre d'éléments pour le type
						referentielEntiteListe.referentiel.nbItems = result?.data[referentielEntiteListe.type];
					});
				}
			}),
			isVisible: () => {
				//Retour de la possibilité d'effectuer l'action
				return (referentielEntiteListe.liste.selectedSelector == 'STANDARD' && !this.rightService.isRoot()) && this.rightService.hasRight(null,'creation');
			}
		});
	}

	/**
	 * Ajout de l'action de suppression en masse à la liste
	 */
	addActionSuppressionToListe(referentielEntiteListe: ReferentielEntiteList,url: string) {
		//Ajout de l'action de suppression en masse à la liste
		referentielEntiteListe.liste.listeActions.push({
			icon: 'delete',
			messagingOptions: {
				entryPoint: url,
			},
			onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.doActionReferentiel(messagingOptions).subscribe({
				complete: () => {
					//Rechargement de la liste
					referentielEntiteListe.liste.refresh();

					//Dénombrement des éléments
					this.countItems([referentielEntiteListe.type]).subscribe(result => {
						//Mise à jour du nombre d'éléments pour le type
						referentielEntiteListe.referentiel.nbItems = result?.data[referentielEntiteListe.type];
					});
				}
			}),
			isVisible: () => {
				//Retour de la possibilité d'effectuer l'action
				return (referentielEntiteListe.liste.selectedSelector == 'LOCAL' || this.rightService.isRoot()) && this.rightService.hasRight(null,'suppression');
			}
		});
	}

	/**
	 * Déclenchement d'une action de masse
	 */
	private doActionReferentiel(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});

		return subject;
	}
}