import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ReleveEnergieService } from 'src/app/components/vehicule/releve-energie/releve-energie.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConducteurReleveEnergieEditComponent } from './conducteur-releve-energie-edit.component';


@Component({
	templateUrl: './conducteur-releve-energie-list-item.component.html'
})
export class ConducteurReleveEnergieListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private releveEnergieService: ReleveEnergieService,private pageContentService: PageContentService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du relevé d'énergie
	 */
	showReleveEnergie() {
		//Chargement complet du relevé d'énergie
		this.releveEnergieService.loadReleveEnergie(this.data?.idReleve || 0).subscribe(result => {
			//Affichage d'un relevé d'énergie
			this.pageContentService.open(ConducteurReleveEnergieEditComponent,{
				data: {
					releveEnergie: result?.data?.releve || this.data
				}
			},'sub').subscribe();
		});
	}
}