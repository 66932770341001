import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GrilleAttributionModeleService } from './grille-attribution-modele.service';
import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionModeleListItemComponent } from './grille-attribution-modele-list-item.component';

@Component({
	selector: 'grille-attribution-modele-list',
	templateUrl: './grille-attribution-modele-list.component.html'
})
export class GrilleAttributionModeleListComponent extends PageContentItem implements OnInit {
	/** Grille d'attribution **/
	@Input() grilleAttribution: any;

	/** Résumé **/
	@Input() resume: { nbModeles: number };

	/** Indicateur d'affichage des anomalies pour les modèles **/
	@Input() isShowAnomaliesForModeles: boolean;

	/** Données **/
	liste: ListView<any,GrilleAttributionModeleListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private grilleAttributionModeleService: GrilleAttributionModeleService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,GrilleAttributionModeleListItemComponent>({
			uri: () => `/controller/Vehicule/filtreModelesByGrilleAttribution/${this.grilleAttribution.idGrilleAttribution}`,
			component: GrilleAttributionModeleListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'modele.marque.libelle',
				type: TypeFilter.STRING,
				isDefault: true
			},{
				clef: 'modele.libelle',
				type: TypeFilter.STRING,
				isDefault: true
			},{
				clef: 'modele.reference',
				type: TypeFilter.STRING,
				isDefault: true
			}],
			getKeyFieldName: () => 'idModele',
			listeActions: [this.rightService.hasRight(null,'creation') && {
				icon: 'delete',
				messagingOptions: {
					entryPoint: `controller/Vehicule/deleteAllModelesForGrilleAttribution/${this.grilleAttribution.idGrilleAttribution}`,
				},
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.deleteAllModeles(messagingOptions)
			}].filter(i => !!i),
			defaultOrder: '-isAnomalie,modele.marque.libelle,modele.libelle,modele.reference',
			onRefresh: (liste,result) => this.resume.nbModeles = result.totalElements
		});

		//Vérification de l'affichage des anomalies
		if (this.isShowAnomaliesForModeles) {
			//Définition de la liste des filtres sélectionnés
			this.liste.listeSelectedFilters.push({
				clef: 'isAnomalie',
				valeur: true,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('vehicule.grilleAttribution.anomalie'),
				displayedValeur: this.translateService.instant('common.oui')
			});
		}
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idModele > 0)
			//Mise à jour des compteurs
			this.resume.nbModeles--;
	}

	/**
	 * Suppressions des modèles de la grille
	 */
	deleteAllModeles(messagingOptions: MessagingOptions) {
		//Suppression de la sélection
		this.grilleAttributionModeleService.deleteAllForSelection(messagingOptions).subscribe({
			complete: () => this.liste.refresh()
		});
	}
}