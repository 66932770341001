import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { ArticlePreviewComponent } from './article-preview.component';

@Injectable()
export class ArticleService {
	/**
	 * Liste des types
	 */
	private listeTypes: Array<{ code: string,listeTypesTenant: Array<'ROOT' | 'CLIENT'> }> = [{
		code: 'STANDARD',
		listeTypesTenant: ['ROOT','CLIENT']
	},{
		code: 'CGU',
		listeTypesTenant: ['ROOT']
	},{
		code: 'NOUVEAUTES',
		listeTypesTenant: ['ROOT']
	},{
		code: 'ALERTE',
		listeTypesTenant: ['ROOT']
	},{
		code: 'ONBOARDING',
		listeTypesTenant: ['ROOT']
	}];

	/**
	 * Liste des périmètres
	 */
	private listePerimetres = ['WEB','MOBILE','WEB_MOBILE'];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private http: HttpClient,private bsModalService: BsModalService) {

	}

	/**
	 * Récupération de la liste des types traduits
	 */
	getListeTypes(isRoot?: boolean) {
		//Retour de la liste des types traduits
		return this.listeTypes.filter(type => typeof isRoot == 'undefined' || isRoot || type.listeTypesTenant.includes('CLIENT')).map(type => ({
			code: type.code,
			libelle: this.translateService.instant('article.type.'+type.code)
		}));
	}

	/**
	 * Récupération de la liste des périmètres traduits
	 */
	getListePerimetres() {
		//Récupération de la liste des périmètres traduits
		return this.listePerimetres.map(code => ({
			code,
			libelle: this.translateService.instant('article.perimetre.'+code)
		}));
	}

	/**
	 * Sauvegarde de l'article
	 */
	public saveArticle(article: any): Observable<Result> {
		//Sauvegarde de l'article
		return this.http.put<Result>(`${environment.baseUrl}/controller/Aide/saveArticle`,article).pipe(first());
	}

	/**
	 * Suppression d'un article
	 */
	public deleteArticle(idArticle: number): Observable<Result> {
		//Suppression d'un article
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Aide/deleteArticle/${idArticle}`);
	}

	/**
	 * Prévisualisation de l'article
	 */
	public previewArticle(idArticle: number) {
		//Prévisualisation de l'article
		let bsModalRef: BsModalRef<ArticlePreviewComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(ArticlePreviewComponent,{
			initialState: {
				article: {
					idArticle
				}
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}

	/**
	 * Duplication d'un article
	 */
	public duplicateArticle(article: any): Observable<Result> {
		//Duplication d'un article
		return this.http.post<Result>(`${environment.baseUrl}/controller/Aide/duplicateArticle/${article.idArticle}`,null);
	}
}