<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.nature?.libelle?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addPosteCharge?.(data,deleteLienPosteCharge)">
			<span *ngIf="data.nature">{{ data.nature | display:'fournisseurNature' }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idLien">
		<li *ngIf="data.type"><span translate>comptabilite.compte.typeCharge.item</span>&#160;:&#160;<span [translate]="'comptabilite.compte.typeCharge.' + data.type"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idLien && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="posteChargeSettings.typeDroit | right:'consultation'"><a [routerLink]="[]" (click)="goToObject();"><span translate>actions.accederObjet</span></a></li>
			<li *ngIf="(null | right:'creation') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="showTransfertPosteCharge();"><span translate>actions.transferer</span></a></li>
			<li *ngIf="(null | right:'creation') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteLienPosteCharge();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>