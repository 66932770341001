<div class="pull-left lv-avatar">{{ data.typeSinistre.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addSinistre()">
			<span translate [translateParams]="{ reference: data.reference }">vehicule.sinistre.item</span>
			<span *ngIf="data.user">&#160;-&#160;{{ data.user | display:'user' }}</span>
			<span *ngIf="!data.user">&#160;-&#160;{{ 'vehicule.sinistre.collaborateurNonDefini' | translate }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idSinistre">
		<li><span translate>vehicule.sinistre.dateSinistre</span>&#160;:&#160;{{ data.dateSinistre | date:'short' }}</li>
		<li *ngIf="data.statut"><span translate>vehicule.sinistre.statut.item</span>&#160;:&#160;<span [translate]="'vehicule.sinistre.statut.' + data.statut"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.VEHICULE_SINISTRE | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(TypeDroit.VEHICULE_SINISTRE | right:'suppression')"><a [routerLink]="[]" (click)="deleteSinistre();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>