import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { Filter,ListView } from 'src/app/domain/common/list-view';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { VehiculeAchatComponent } from '../vehicule-achat.component';

@Injectable()
export class ContratFinancementService {
	/** Liste des modes d'affichage du prix **/
	private readonly listeTypesAffichagePrix: Array<string> = ['HT','TTC'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService,private messagingService: MessagingService) {}

	/**
	 * Chargement du contrat de financement
	 */
	public loadContratFinancement(idContrat: number): Observable<Result> {
		//Chargement du contrat de financement
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadContratFinancement/${idContrat}`,null);
	}

	/**
	 * Enregistrement du contrat de financement
	 */
	public saveContratFinancement(contratFinancement: any): Observable<Result> {
		//Enregistrement du contrat de financement
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveContratFinancement`,contratFinancement);
	}

	/**
	 * Suppression du contrat de financement
	 */
	public deleteContratFinancement(contratFinancement: any): Observable<Result> {
		//Suppression d'un contrat de financement
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteContratFinancement/${contratFinancement.idContrat}`);
	}

	/**
	 * Chargement du résumé
	 */
	 public loadResume(type: 'EN_COURS' | 'ECHU',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du tableau de bord
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadFinancementDashboard/${type}`,searchSpec);
	}

	/**
	 * Chargement du résumé du contrat de financement
	 */
	public loadResumeContratFinancement(idContrat: number): Observable<Result> {
		//Chargement du résumé du contrat de financement
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadResumeFinancement/${idContrat}`,null);
	}

	/**
	 * Enregistrement de la loi de roulage
	 */
	public saveLoiRoulage(idContrat: number,objectVersion: number,loiRoulage: any): Observable<Result> {
		//Enregistrement de la loi de roulage
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveLoiRoulage/${idContrat}/${objectVersion}`,loiRoulage);
	}

	/**
	 * Réalisation de l'achat du véhicule
	 */
	doAchatVehicule(contratFinancement: any): Observable<any> {
		let bsModalRef: BsModalRef<VehiculeAchatComponent>;

		//Affichage de la popup d'achat du véhicule
		bsModalRef = this.bsModalService.show(VehiculeAchatComponent,{
			initialState: {
				contratFinancement
			}
		});

		//Retour du contrat de financement enregistré
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.savedContratFinancement)
		);
	}

	/**
	 * Récupération de la liste des modes d'affichage du prix
	 */
	public getListeTypesAffichagePrix(): Array<{ code: string,libelle: string }> {
		//Retour de la liste
		return this.listeTypesAffichagePrix.map(code => ({
			code,
			libelle: this.translateService.instant(`contrat.typeAffichagePrix.long.${code}`)
		}));
	}

	/**
	 * Déclenchement du déplacement des postes de charge en masse
	 */
	public doValidationForListeEcheances(messagingOptions: MessagingOptions,liste: ListView<any,any>): Observable<any> {
		let subject: Subject<Result> = new Subject<Result>();
		let messaging$: MessagingObservables;

		//Démarrage du déplacement par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onResult({
				next: result => {
					//Poursuite du traitement
					subject.next(result);
				}
			})
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Rafraichissement de la liste
					liste?.refresh?.();

					//Finalisation du sujet
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du sujet
					subject.complete();
				}
			});

		return subject;
	}

	/**
	 * Calcul de la date de fin d'un contrat de financement
	 */
	public getDateFin(contratFinancement: any): moment.Moment {
		//Date de début du contrat + Nombre de mois du dernier avenant
		return contratFinancement.lastLoiRoulage &&  contratFinancement.dateDebut != null ? moment(contratFinancement.dateDebut).add(contratFinancement.lastLoiRoulage.duree,'M') : null;
	}
}