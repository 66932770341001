import { Pipe,PipeTransform } from '@angular/core';

@Pipe({
	name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {
	/**
	 * Transformation
	 */
	transform(value: string): string {
		//Retour de la valeur
		return value?.length ? value.charAt(0).toUpperCase() + value.substring(1) : value;
	}
}
