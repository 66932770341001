<div class="pull-left lv-avatar">
	<icon name="shopping_cart" *ngIf="!data.dateFin || data.dateFin > today"></icon>
	<icon name="close" *ngIf="data.dateFin && data.dateFin <= today"></icon>
</div>
<div class="media-body">
	<div class="lv-title word-break"><a [routerLink]="[]" (click)="showOption()">{{ data.libelle }}</a></div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.modele.option.liste.categorie</span>&#160;:&#160;<span>{{ getListeCategories() }}</span></li>
		<li><span translate>vehicule.modele.option.liste.prixTTC</span>&#160;:&#160;<span>{{ data.prixTTC | currency:'.2-2':'EUR' }}</span></li>
		<li><span translate>vehicule.modele.option.liste.dateDebut</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>vehicule.modele.option.liste.dateFin</span>&#160;:&#160;<span>{{ data.dateFin | date:'shortDate' }}</span></li>
	</ul>
</div>