import { Input,Component } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'demande-cotation-reponse-cotation',
	templateUrl: './demande-cotation-reponse-cotation.component.html'
})
export class DemandeCotationReponseCotationComponent extends PageContentItem {
	/** Cotation **/
	@Input() cotation: any;

	/** Réponse à la cotation */
	@Input() reponseCotation: any;

	/** Indicateur de provenance **/
	@Input() isFromDemandeCotation: boolean;

	/** Indicateur de lecture seule **/
	@Input() isReadOnly: boolean;

	/** Source **/
	public source: string;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();

		//Binding des méthodes
		this.close = this.close?.bind?.(this);
	}
}