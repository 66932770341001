import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AmenagementContentComponent } from './amenagement-content.component';
import { AmenagementListItemComponent } from './amenagement-list-item.component';
import { AmenagementListComponent } from './amenagement-list.component';
import { AmenagementComponent } from './amenagement.component';
import { AmenagementService } from './amenagement.service';

@NgModule({
	imports: [ShareModule],
	declarations: [AmenagementListComponent,AmenagementListItemComponent,AmenagementComponent,AmenagementContentComponent],
	exports: [AmenagementContentComponent],
	providers: [AmenagementService]
})
export class AmenagementModule { }