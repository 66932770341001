import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ServiceService } from './service.service';

@Component({
	templateUrl: './service-move.component.html'
})
export class ServiceMoveComponent implements AfterContentChecked {
	/** Liste des types de gestion des dépendances **/
	@Input() listeTypesGestionDependance: Array<{ code: string,libelle: string }>;

	/** Service **/
	@Input() service: any;

	/** Options */
	options: { newServiceParent?: any,parent?: any,gestionDependances?: 'TOUT_DEPLACER' | 'AFFECTER_PARENT' | 'SELECTION_PARENT' } = { gestionDependances: 'TOUT_DEPLACER' };

	/** Résultat **/
	result: { isRefreshNeeded: boolean };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ServiceMoveComponent>,private serviceService: ServiceService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du déplacement
	 */
	saveServiceDeplacement() {
		//Déplacement du service
		this.serviceService.moveService(this.service,{
			gestionDependances: this.options?.gestionDependances,
			idParent: this.options?.newServiceParent?.idService,
			idParentDependances: this.options?.parent?.idService
		}).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Rafraichissement de la liste
					this.result = {
						isRefreshNeeded: true
					}

					//Fermeture de la modal
					this.bsModalRef.hide()
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Fermeture de la service
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}