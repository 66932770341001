import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { StructureExportService } from './structure-export.service';

@Component({
	templateUrl: './structure-export-rubrique-type-selection.component.html'
})
export class StructureExportRubriqueTypeSelectionComponent implements OnInit {
	/** Structure d'export courante **/
	@Input() structureExport: any;

	/** Ligne courante **/
	@Input() ligne: any;

	/** Liste des compteurs disponibles **/
	@Input() listeCompteurs: Array<any>;

	/** Liste des types de rubrique **/
	listeTypesRubrique: Array<{ code: string,libelle: string,listeGroupes?: Array<{ code: string,libelle: string,listeItems: Array<{ code: string,libelle: string,tooltip?: string,subCode?: string,_type?: string }> }> }>;

	/** Niveau sélectionné courant **/
	selectedType: any;

	/** Résultat **/
	result: { typeDetail: string,type: string,code: string,object: any,_type: string };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<StructureExportRubriqueTypeSelectionComponent>,private structureExportService: StructureExportService,private translateService: TranslateService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des types de rubrique
		this.listeTypesRubrique = this.structureExportService.getListeTypesRubrique();

		//Vérification de la liste des compteurs
		if (this.listeCompteurs?.length) {
			//Mise à jour des compteurs
			this.listeTypesRubrique.find(typeRubrique => typeRubrique.code == 'COMPTEUR').listeGroupes = [{
				code: 'COMPTEUR',
				libelle: this.translateService.instant('structureExport.rubrique.typeRubrique.COMPTEUR.item'),
				listeItems: this.listeCompteurs
			}];
		} else
			//Retrait des compteurs
			this.listeTypesRubrique = this.listeTypesRubrique.filter(typeRubrique => typeRubrique.code != 'COMPTEUR');

		//Initialisation de la liste des mémoires
		this.initListeMemoires();
	}

	/**
	 * Sélection du type
	 */
	selectType(type: any) {
		//Vérification de la présence d'une liste de groupes
		if (type.listeGroupes?.length) {
			//Sélection du type
			this.selectedType = type;
		} else {
			//Définition du résultat
			this.result = {
				typeDetail: this.selectedType ? this.selectedType.code : type.code,
				type: this.selectedType ? type.code : null,
				code: this.selectedType ? type.subCode : null,
				object: this.selectedType ? type : null,
				_type: this.selectedType ? type._type : null
			};

			//Fermeture de la popup
			this.bsModalRef.hide();
		}
	}

	/**
	 * Récupération de la liste des types de rubrique
	 */
	getListeTypesRubrique() {
		//Retour de la liste des types de rubrique
		return this.selectedType ? this.selectedType.listeGroupes : this.listeTypesRubrique;
	}

	/**
	 * Initialisation des mémoires
	 */
	private initListeMemoires() {
		let idFichier: number;
		let detail: any;
		let listeLignes: Array<any> = [];
		let doAddLigne: Function;

		//Récupération du fichier courant
		idFichier = this.ligne.detail.fichier.idFichier;

		//Récupération du détail de base courant
		detail = this.structureExport.listeDetails.find(d => d?.fichier?.idFichier == idFichier);

		//Méthode d'ajout des lignes
		doAddLigne = (label: string,d: any) => {
			//Vérification du détail
			if (d?.listeItems?.length) {
				//Parcours des éléments
				d.listeItems.forEach(i => {
					//Vérification du type d'élément
					if (i.ligne && i.ligne.idLigne != this.ligne.idLigne) {
						//Ajout de la ligne
						listeLignes.push(Object.assign(cloneDeep(i.ligne),{
							computedLibelle: (label ? label + ' > ' : '') + i.ligne.libelle
						}));
					} else if (i.detail)
						//Traitement du détail
						doAddLigne(i.detail.libelle || this.translateService.instant('structureExport.typeDetail.GROUPE'),i.detail);
				});
			}
		}

		//Traitement du détail de base
		doAddLigne(null,detail);

		//Mise à jour des mémoires
		this.listeTypesRubrique.find(typeRubrique => typeRubrique.code == 'MEMOIRE').listeGroupes = [{
			code: 'MEMOIRE',
			libelle: this.translateService.instant('structureExport.rubrique.typeRubrique.MEMOIRE.item'),
			listeItems: listeLignes
		}];
	}
}