import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { GroupeService } from './groupe.service';
import { mapEntites } from './groupe.entites';
import { EntiteService } from 'src/app/share/components/entite/entite.service';

@Component({
	templateUrl: './groupe-list-item.component.html'
})
export class GroupeListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private groupeService: GroupeService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService
			,private toastrService: ToastrService,public entiteService: EntiteService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.groupe.Groupe',this.data.idGroupe);
	}

	/**
	 * Suppression d'un groupe
	 */
	deleteGroupe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du groupe
					this.groupeService.deleteGroupe(this.data.idGroupe).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du groupe
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}
}