import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ChartService } from 'src/app/components/chart/chart.service';
import { CalendrierListItemComponent } from './calendrier-list-item.component';

@Component({
	templateUrl: './calendrier-list.component.html'
})
export class CalendrierListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,CalendrierListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private chartService: ChartService,private extractionService: ExtractionService,private layoutService: LayoutService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CalendrierListItemComponent>({
			uri: `/controller/Calendrier/filtreCalendriers`,
			title: this.translateService.instant('calendrier.liste.title'),
			component: CalendrierListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				title: this.translateService.instant('search.libelle'),
				isDefault: true
			},{
				clef: 'reference',
				title: this.translateService.instant('search.reference'),
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'typeDiffusion',
				type: TypeFilter.LONG,
				listeValues: this.chartService.getListeTypesDiffusion()
			},{
				clef: 'listeDetails.entite',
				title: this.translateService.instant('search.entite'),
				type: TypeFilter.LONG,
				listeValues: this.extractionService.getListeEntites('CALENDRIER')
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Calendrier/loadCalendrier/0')),
			getKeyFieldName: () => 'idCalendrier'
		});
	}
}