<form #informationTarifaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.informationTarifaire.title</h4>
	</div>
	<div class="modal-body">
		<div class="alert alert-info">
			<icon name="info"></icon>
			<div>
				<strong translate>vehicule.informationTarifaire.informationAvantageNature.title</strong>
				<div translate>vehicule.informationTarifaire.informationAvantageNature.message</div>
			</div>
		</div>
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': devise.invalid }">
				<label translate>vehicule.informationTarifaire.devise</label>
				<div class="half">
					<autocomplete name="devise" type="devise" #devise="ngModel" [(ngModel)]="informationTarifaire.devise" [required]="informationTarifaire.prixTTC || informationTarifaire.prixHT"></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': prixTTC.invalid }">
				<label translate>vehicule.informationTarifaire.prixTTC</label>
				<div>
					<div class="input-group">
						<input type="text" name="prixTTC" #prixTTC="ngModel" class="form-control" [(ngModel)]="informationTarifaire.prixTTC" nNumber="2" min="0"/>
						<div *ngIf="informationTarifaireConstructeur?.prixTTC" class="input-group-addon clickable" [tooltip]="'vehicule.informationTarifaire.modeleConstructeur.prixTTC' | translate:{ prixTTC:(informationTarifaireConstructeur.prixTTC | currency:informationTarifaireConstructeur.devise) }" (click)="informationTarifaire.prixTTC = informationTarifaireConstructeur.prixTTC;">
							<icon name="info_outline"></icon>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': prixHT.invalid }">
				<label translate>vehicule.informationTarifaire.prixHT</label>
				<div>
					<div class="input-group">
						<input type="text" name="prixHT" #prixHT="ngModel" class="form-control" [(ngModel)]="informationTarifaire.prixHT" nNumber="2" min="0"/>
						<div *ngIf="informationTarifaireConstructeur?.prixHT" class="input-group-addon clickable" [tooltip]="'vehicule.informationTarifaire.modeleConstructeur.prixHT' | translate:{ prixHT:(informationTarifaireConstructeur.prixHT | currency:informationTarifaireConstructeur.devise) }" (click)="informationTarifaire.prixHT = informationTarifaireConstructeur.prixHT;">
							<icon name="info_outline"></icon>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': pourcentageRemise.invalid }">
				<label translate>vehicule.informationTarifaire.pourcentageRemise</label>
				<div class="input-group">
					<input type="text" name="pourcentageRemise" #pourcentageRemise="ngModel" class="form-control" [(ngModel)]="informationTarifaire.pourcentageRemise" nNumber="2" min="0" max="100"/>
					<div class="input-group-addon no-border">%</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': prixTTCRemise.invalid }">
				<label translate>vehicule.informationTarifaire.prixTTCRemise</label>
				<div class="input-group">
					<input type="text" name="prixTTCRemise" #prixTTCRemise="ngModel" class="form-control" [(ngModel)]="informationTarifaire.prixTTCRemise" nNumber="2" min="0"/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': prixHTRemise.invalid }">
				<label translate>vehicule.informationTarifaire.prixHTRemise</label>
				<div class="input-group">
					<input type="text" name="prixHTRemise" #prixHTRemise="ngModel" class="form-control" [(ngModel)]="informationTarifaire.prixHTRemise" nNumber="2" min="0"/>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveInformationTarifaire()" [disabled]="informationTarifaireForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>