import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfigurationService } from './configuration/configuration.service';
import { mapEntites } from './modele.entites';
import { ModeleService } from './modele.service';

@Component({
	templateUrl: './modele-list-item.component.html'
})
export class ModeleListItemComponent extends ListViewItem<any> implements IListEntity {
	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private modeleService: ModeleService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,private configurationService: ConfigurationService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idModele,object?.idModele && !this.data?.idModele);

		//Vérification de la création
		if (object?.idModele && !this.data?.idModele)
			//Mise à jour des compteurs
			this.liste.extraOptions?.onModeleAdd?.(object);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteModele() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.modeleService.deleteModele(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de la configuration du modèle
	 */
	showConfiguration() {
		//Affichage de la configuration du modèle
		this.configurationService.showConfiguration({
			type: 'VEHICULE_MODELE',
			idModele: this.data.idModele,
			idEntite: this.data.idModele
		}).subscribe();
	}

	/**
	 * Redirection vers le modèle
	 */
	goToModele() {
		//Navigation vers le modèle
		this.layoutService.goToByState('vehiculeReferentiel-listeModeles-modele',{
			routeParams: {
				idModele: this.data.idModele
			},
			withGoBack: true
		});
	}
}