<div class="pull-left lv-avatar">
	<span *ngIf="data.vehicule?.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE" translate>vehicule.liste.avatar.service</span>
	<span *ngIf="data.vehicule.typeVehicule == TypeVehicule.VEHICULE_PERSO" translate>vehicule.liste.avatar.perso</span>
	<span *ngIf="data.vehicule.typeVehicule == TypeVehicule.ENGIN" translate>vehicule.liste.avatar.engin</span>
</div>
<div class="media-body">
	<div class="lv-title">
		<div class="radio">
			<span>{{ data.vehicule | display:'vehicule' }}</span>
			<label class="pull-right">
				<mat-radio-button [value]="data.vehicule" [required]="extraOptions.selectedSource == 'LISTE'"></mat-radio-button>
			</label>
		</div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.dateDebut"><span translate>vehicule.affectation.dateDebut.item</span>&#160;:&#160;<span>{{ data.dateDebut | date:'short' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>vehicule.affectation.dateFin.item</span>&#160;:&#160;<span>{{ data.dateFin | date:'short' }}</span></li>
	</ul>
</div>