import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';

@Injectable()
export class TypeCarrosserieService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Suppression du type carrosserie
	 */
	public deleteTypeCarrosserie(typeCarrosserie: any): Observable<Result> {
		//Suppression du type carrosserie
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteTypeCarrosserie/${typeCarrosserie.idTypeCarrosserie}`);
	}

	/**
	 * Enregistrement du type carrosserie
	 */
	public saveTypeCarrosserie(typeCarrosserie: any): Observable<Result> {
		//Enregistrement du type carrosserie
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveTypeCarrosserie`,typeCarrosserie);
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(type: 'TOUS' | 'HORS_CATALOGUE' | 'CATALOGUE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadDashboardTypeCarrosserie/${type}`,searchSpec);
	}
}