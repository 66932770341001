import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ChartModule } from 'src/app/components/chart/chart.module';
import { CalendrierEditComponent } from './calendrier-edit.component';
import { CalendrierEntiteListComponent } from './calendrier-entite-list.component';
import { CalendrierEntiteComponent } from './calendrier-entite.component';
import { CalendrierListItemComponent } from './calendrier-list-item.component';
import { CalendrierListComponent } from './calendrier-list.component';
import { CalendrierComponent } from './calendrier.component';
import { CalendrierService } from './calendrier.service';

@NgModule({
	imports: [ShareModule,ChartModule],
	declarations: [CalendrierListComponent,CalendrierListItemComponent,CalendrierComponent,CalendrierEditComponent,CalendrierEntiteListComponent,CalendrierEntiteComponent],
	providers: [CalendrierService]
})
export class CalendrierModule { }
