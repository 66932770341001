import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './article-feedback-list-item.component.html'
})
export class ArticleFeedbackListItemComponent extends ListViewItem<any> {
	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idFeedback,object?.idFeedback && !this.data?.idFeedback);
	}
}