import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DeviceListItemComponent } from './device-list-item.component';
import { DeviceListComponent } from './device-list.component';
import { DeviceService } from './device.service';

@NgModule({
	imports: [ShareModule],
	declarations: [DeviceListComponent,DeviceListItemComponent],
	providers: [DeviceService]
})
export class DeviceModule {}