import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './fournisseur-nature-list-item.component.html'
})
export class FournisseurNatureListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Récupération des métiers de la nature
	 */
	getListeMetiers() {
		//Retour des libellés des métiers
		return (this.data?.listeMetiers as Array<any> || []).map(m => m.libelle).join(', ');
	}
}