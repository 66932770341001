import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContraventionService } from './infraction/contravention/contravention.service';
import { DemandeIdentificationService } from './infraction/demande-identification/demande-identification.service';

@Component({
	templateUrl: './vehicule-infraction-list-item.component.html'
})
	export class VehiculeInfractionListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des statuts des demandes d'identification **/
	private listeStatuts: Array<{ code: string,icone: string,iconeType?: string }>;

	/**
	 * Constructeur
	 */
	constructor(private contraventionService: ContraventionService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,public rightService: RightService,private layoutService: LayoutService,private demandeIdentificationService: DemandeIdentificationService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteContravention = this.deleteContravention.bind(this)
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des statuts
		this.listeStatuts = this.demandeIdentificationService.getListeStatuts();
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteContravention(contravention: any = this.data?.contravention,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contraventionService.deleteContravention(contravention))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory(className: string) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,this.data.idObject);
	}

	/**
	 * Redirection vers l'infraction (Demande d'identification / Contravention)
	 */
	public goToInfraction() {
		//Vérification du type d'infraction
		if (this.data?.typeInfraction == 'DEMANDE_IDENTIFICATION')
			//Affichage du formulaire de la demande d'identification
			this.goToDemandeIdentification();
		else if (this.data?.typeInfraction == 'CONTRAVENTION')
			//Affichage du formulaire de la contravention
			this.goToContravention();
	}

	/**
	 * Récupération du statut de la demande d'identification par rapport au code
	 */
	getStatutForCode(code: string): { code: string,icone: string,iconeType?: string } {
		//Retour du statut
		return this.listeStatuts?.find(s => s.code == code);
	}

	/**
	 * Accès au formulaire de la demande d'identification
	 */
	private goToDemandeIdentification() {
		//Redirection vers la demande d'identification
		this.layoutService.goToByState('infractionReferentiel-listeDemandesIdentification-identification',{
			routeParams: {
				idIdentification: this.data.idObject
			},
			withGoBack: true
		});
	}

	/**
	 * Accès au formulaire de la contravention
	 */
	private goToContravention() {
		//Redirection vers la contravention
		this.layoutService.goToByState('infractionReferentiel-listeVehiculeContraventions-contravention',{
			routeParams: {
				idContravention: this.data.contravention?.idContravention
			},
			withGoBack: true
		});
	}
}