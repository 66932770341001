import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { ModeleDetailsComponent } from './modele-details.component';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { ModeleOptionComponent } from './modele-option.component';

@Injectable()
export class ModeleService {
	/** Liste des type de roues de secours **/
	private readonly listeTypesRoueSecours: Array<string> = ['AUCUNE','ROUE_SECOURS','GALETTE','KIT_REPARATION'];

	/** Cache des modèles dont les options sont synchronisées **/
	private mapOptionsForModele: { [idModele: number]: boolean } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService,private messagingService: MessagingService) { }

	/**
	 * Suppression du modèle
	 */
	public deleteModele(modele: any): Observable<Result> {
		//Suppression du modèle
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteModele/${modele.idModele}`);
	}

	/**
	 * Enregistrement du modèle
	 */
	public saveModele(modele: any): Observable<Result> {
		//Enregistrement du modèle
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveModele`,modele);
	}

	/**
	 * Chargement du résumé
	 */
	public loadResume(type: 'HORS_CATALOGUE' | 'CATALOGUE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du résumé
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadDashboardModele/${type}`,searchSpec);
	}

	/**
	 * Chargement du modèle
	 */
	public loadModele(idModele: number): Observable<Result> {
		//Chargement du modèle
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadModele/${idModele}`,null);
	}

	/**
	 * Récupération de la liste des types de roue de secours
	 */
	public getListeTypesRoueSecours(): Array<{ code: string,libelle: string }> {
		//Liste des types de roue de secours
		return this.listeTypesRoueSecours.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.modele.pneumatique.typeRoueSecours.' + code)
		}));
	}

	/**
	 * Affichage des informations détaillées du modèle
	 */
	showModeleInformations(modele: any,{ canChange,canSelect,canConfigure }: { canChange?: boolean,canSelect?: boolean,canConfigure?: boolean } = {}): Observable<{ action: 'CHANGE_MODELE' | 'CONFIGURE_MODELE',modele: any }> {
		let bsModalRef: BsModalRef<ModeleDetailsComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ModeleDetailsComponent,{
			initialState: {
				modele,
				options: {
					canChange,
					canSelect,
					canConfigure
				}
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result),
			filter(result => !!result)
		);
	}

	/**
	 * Synchronisation des options
	 */
	public synchronizeOptions(modele: any): Observable<any> {
		let syncSubject = new Subject<any>();
		let messaging$: MessagingObservables;

		//Vérification de l'indicateur de synchronisation des options pour le modèle
		if (!this.mapOptionsForModele[modele.idModele]) {
			//Synchronisation des options du modèle par websocket
			messaging$ = this.messagingService.init({
				entryPoint: `controller/VehiculeReferentiel/synchronizeModeleOptions/${modele.idModele}`,
				outputPoint: '/messaging/modele/synchronizeModeleOptions/status',
				method: 'POST'
			}).onFinish({
				next: () => {
					//Synchronisation effectuée
					this.mapOptionsForModele[modele.idModele] = true;

					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					syncSubject.next(null);
					syncSubject.complete();
				}
			}).onError({
				next: (error: any) => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					syncSubject.error(error);
					syncSubject.complete();
				}
			});
		} else {
			//Finalisation du traitement
			syncSubject.next(null);
			syncSubject.complete();
		}

		//Retour de l'observable
		return syncSubject.asObservable();
	}

	/**
	 * Affichage d'une option
	 */
	public showOption(option:any) {
		let bsModalRef: BsModalRef<ModeleOptionComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(ModeleOptionComponent,{
			initialState: {
				option
			},
			class: 'modal-max'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}
}