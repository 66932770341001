import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class SinistreService {
	/** Liste des types de responsabilités **/
	private readonly listeTypesResponsabilite: Array<string> = ['PERCENT_0','PERCENT_50','PERCENT_100'];

	/** Liste des statuts **/
	private readonly listeStatuts: Array<string> = ['NON_DEFINI','OUVERT','EN_COURS','FERME'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Chargement d'un sinistre
	 */
	public loadSinistre(idSinistre: number): Observable<Result> {
		//Chargement d'un sinistre
		return this.http.post<Result>(`${environment.baseUrl}/controller/Sinistre/loadSinistre/${idSinistre}`,null);
	}

	/**
	 * Suppression d'un sinistre
	 */
	public deleteSinistre(sinistre: any): Observable<Result> {
		//Suppression d'un sinistre
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Sinistre/deleteSinistre/${sinistre.idSinistre}`);
	}

	/**
	 * Enregistrement d'un sinistre
	 */
	public saveSinistre(sinistre: any): Observable<Result> {
		//Enregistrement d'un sinistre
		return this.http.put<Result>(`${environment.baseUrl}/controller/Sinistre/saveSinistre`,sinistre);
	}

	/**
	 * Liste des types de responsabilités
	 */
	getListeTypesResponsabilite(): Array<{ code: string,libelle: string }> {
		//Liste des types de responsabilités
		return this.listeTypesResponsabilite.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.sinistre.typeResponsabilite.${code}`)
		}));
	}
	/**
	 * Récupération de la liste des types de statut
	 */
	public getListeStatuts(): Array<{ code: string,libelle: string }> {
		//Liste des types de statut
		return this.listeStatuts.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.sinistre.statut.' + code)
		}));
	}
}