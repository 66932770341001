import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { EquipementAffectationService } from './equipement-affectation.service';

@Component({
	templateUrl: './equipement-affectation-list-item.component.html'
})
export class EquipementAffectationListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private equipementAffectationService: EquipementAffectationService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,private vehiculeService: VehiculeService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteAffectation = this.deleteAffectation.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteAffectation(affectation: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.equipementAffectationService.deleteAffectation(affectation))
		).subscribe({
			next: (result: Result) => {
				let index: number;

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);

					//Recherche de l'index de l'affectation
					index = this.extraOptions?.equipement?.listeAffectations?.findIndex(a => a.idAffectation == affectation.idAffectation);

					//Vérification de l'index
					if (index != -1)
						//Suppression de l'affectation
						this.extraOptions?.equipement?.listeAffectations?.splice(index,1);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Redirection vers le conducteur
	 */
	public goToConducteur(conducteur: any = this.data?.conducteur) {
		//Navigation vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: conducteur.idConducteur
			},
			withGoBack: true
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	public goToVehicule(vehicule: any = this.data.vehicule) {
		//Accès au véhicule
		this.vehiculeService.goToVehicule(vehicule);
	}
}