import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { MotifListItemComponent } from './motif-list-item.component';
import { MotifListComponent } from './motif-list.component';
import { MotifComponent } from './motif.component';
import { MotifService } from './motif.service';
import { MotifSelectionComponent } from './motif-selection.component';

@NgModule({
	imports: [ShareModule],
	declarations: [MotifListComponent,MotifListItemComponent,MotifComponent,MotifSelectionComponent],
	providers: [MotifService]
})
export class MotifModule {}