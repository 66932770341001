import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { StepperModule } from 'src/app/share/components/stepper/stepper.module';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { AccountArticleListItemComponent } from './account-article-list-item.component';
import { AccountArticleListComponent } from './account-article-list.component';
import { AccountArticleComponent } from './account-article.component';
import { AccountContactAdminComponent } from './account-contact-admin.component';
import { AccountNotificationListItemComponent } from './account-notification-list-item.component';
import { AccountNotificationListComponent } from './account-notification-list.component';
import { AccountPasswordComponent } from './account-password.component';
import { AccountPersonalDataComponent } from './account-personal-data.component';
import { AccountComponent } from './account.component';
import { AccountService } from './account.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { SummernoteModule } from 'src/app/share/components/summernote/summernote.module';
import { RecaptchaModule } from 'src/app/share/directive/recaptcha/recaptcha.module';
import { ValidatorModule } from 'src/app/share/directive/validator/validator.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';
import { ArticleModule } from 'src/app/share/components/article/article.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,RightModule,StepperModule,ListViewModule,IconModule,SummernoteModule,RecaptchaModule,ValidatorModule,PleaseWaitModule,ArticleModule,FilterModule],
	declarations: [AccountComponent,AccountPasswordComponent,AccountPersonalDataComponent,AccountContactAdminComponent,AccountArticleComponent,AccountArticleListComponent,AccountArticleListItemComponent,AccountNotificationListComponent,AccountNotificationListItemComponent],
	providers: [AccountService]
})
export class AccountModule { }
