import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { UserService } from './user.service';

@Component({
	selector: 'user-edit',
	templateUrl: './user-edit.component.html'
})
export class UserEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() user: any;

	/** Suppression de l'objet **/
	@Input() deleteUser: () => void;

	/** Liste des profils **/
	@Input() listeProfils: Array<any>;

	/** Liste des locales **/
	@Input() listeLocales: Array<any>;

	/** Détection d'un changement d'email/login **/
	@Input() onLoginChange: (user: any) => void;

	/** Liste des types de civilité **/
	public listeTypesCivilite: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private userService: UserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types de civilité
		this.listeTypesCivilite = this.userService.getListeTypesCivilite();

		//Création des données supplémentaires (si nécessaire)
		this.user.userDescription = this.user.userDescription || {};
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveUser() {
		//Enregistrement de l'élément
		this.userService.saveUser(this.user).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.user = Object.assign(this.user,result.data?.user);

					//Fermeture du formulaire
					this.close(this.user);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du matricule
					if (result.data.doublon & Math.pow(2,0)) {
						//Ajout du matricule
						listeDoublons.push(this.translateService.instant('actions.doublon.matricule'));

						//Vérification de l'autorisation de doublon de matricules
						if (this.user.tenant.tenantDescription?.allowDoublonMatricule)
							//Ajout de la société
							listeDoublons.push(this.translateService.instant('actions.doublon.societe'));
					}

					//Vérification de l'identifiant
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.identifiant'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}