import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteServiceListItemComponent } from './compte-service-list-item.component';

@Component({
	selector: 'compte-service-list',
	templateUrl: './compte-service-list.component.html'
})
export class CompteServiceListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,CompteServiceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CompteServiceListItemComponent>({
			uri: `/controller/CompteService/filtreComptes`,
			title: this.translateService.instant('compteService.liste.title'),
			component: CompteServiceListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'user.login',
				isDefault: true,
				title: this.translateService.instant('search.login')
			},{
				clef: 'user.isEnabled',
				type: TypeFilter.BOOLEAN,
				title: this.translateService.instant('search.isActif')
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/CompteService/loadCompteService/0'))
		});
	}
}