import { NgModule } from '@angular/core';


import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { FileUploaderModule } from 'src/app/share/directive/file-uploader/file-uploader.module';
import { ThumbModule } from 'src/app/share/components/thumb/thumb.module';
import { AttachmentLinkComponent } from './attachment-link.component';
import { AttachmentComponent } from './attachment.component';
import { AttachmentService } from './attachment.service';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { ImageEditorModule } from 'src/app/share/components/image-editor/image-editor.module';
import { BytesModule } from 'src/app/share/pipe/bytes/bytes.module';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,FileUploaderModule,ThumbModule,RightModule,ListViewModule,ImageEditorModule,BytesModule,PluralTranslateModule,IconModule,FilterModule],
	declarations: [AttachmentLinkComponent,AttachmentComponent],
	exports: [AttachmentLinkComponent,AttachmentComponent],
	providers: [AttachmentService]
})
export class AttachmentModule {}