<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<div *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE">
		<div *ngIf="!data.dateSortie || data.dateSortie > today">
			<icon name="person" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_FONCTION'"></icon>
			<icon name="apartment" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_SERVICE'"></icon>
			<icon name="lock" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
			<icon name="event" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_POOL'"></icon>
			<icon name="question_mark" *ngIf="!data.extension?.affectationCourante?.typeAffectation"></icon>
		</div>
		<icon name="logout" *ngIf="data.dateSortie && data.dateSortie <= today"></icon>
	</div>
	<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_PERSO" translate>vehicule.liste.avatar.perso</span>
	<span *ngIf="data.typeVehicule == TypeVehicule.ENGIN" translate>vehicule.liste.avatar.engin</span>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Vehicule/loadVehicule',data.idVehicule]">{{ data.reference + (data.numeroInterne ? ' - ' + data.numeroInterne : '') + ' (' + (data.typeVehicule != TypeVehicule.ENGIN ? (data.modele ? (data.modele.libelle + ' ' + data.modele.marque?.libelle) : ('vehicule.modele.nonDefini' | translate)) : (data.marque?.libelle || ('vehicule.marque.nonDefinie' | translate)) + ' - ' + (data.modele ? data.modele.libelle :  ('vehicule.modele.nonDefini' | translate))) + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.typeVehicule != TypeVehicule.ENGIN"><span translate>vehicule.affectation.item</span>&#160;:
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_PERSO">{{ data.user.nom + ' ' + data.user.prenom + ' (' + data.user.matricule + (data.user.societe ? ' - ' + data.user.societe.libelle : '') + ')' }}</span>
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE && (!data.extension?.affectationCourante || data.extension?.affectationCourante?.typeAffectation != 'VEHICULE_IMMOBILISE')">
				<span *ngIf="data.extension?.userAffecte">{{ data.extension.userAffecte.nom + ' ' + data.extension.userAffecte.prenom + ' (' + data.extension.userAffecte.matricule + (data.extension.userAffecte.societe ? ' - ' + data.extension.userAffecte.societe.libelle : '') + ')' }}</span>
				<span *ngIf="!data.extension?.userAffecte" translate>common.aucune</span>
			</span>
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE && data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_IMMOBILISE'" translate>vehicule.vehiculeImmobilise</span>
		</li>
		<li *ngIf="data.dateEntree"><span translate>vehicule.dateEntree.item</span>&#160;:&#160;{{ data.dateEntree | date:'short' }}</li>
		<li *ngIf="data.dateSortie"><span translate>vehicule.dateSortie.item</span>&#160;:&#160;{{ data.dateSortie | date:'short' }}</li>
		<li *ngIf="data.pays && data.pays.code2 != user?.pays?.code2"><span translate>vehicule.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification();" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')) && data.typeVehicule != TypeVehicule.ENGIN"><a [routerLink]="[]" (click)="goToPlanning();" translate>vehicule.actions.accederPlanning</a></li>
			<li *ngIf="(TypeDroit.DEMANDE_VEHICULE | right:'consultation') && data.demandeVehicule"><a [routerLink]="[]" (click)="goToDemandeVehicule();" translate>vehicule.actions.accederDemandeVehicule</a></li>
			<li *ngIf="(null | right:'creation') && !data.dateSortie && data.typeVehicule != TypeVehicule.ENGIN"><a [routerLink]="[]" (click)="takeOutVehicule();"><span translate>actions.sortir</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteVehicule();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(TypeDroit.DEMANDE_VEHICULE | right:'creation') && !data.demandeVehicule && data.typeVehicule != TypeVehicule.ENGIN && (listeConfigurations | filterBy:{ codePays: data.pays.code2 })?.[0]?.demandeVehicule"><a [routerLink]="[]" (click)="doVehiculeRenouvellement();"><span translate>actions.renouveler</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>