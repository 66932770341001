import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,of } from 'rxjs';
import { map,tap } from 'rxjs/operators';
import moment from 'moment';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class LocaleService {
	/** Liste des locales **/
	private listeLocales: Array<any>;

	/** Liste des séparateurs de décimaux **/
	private readonly listeSeparateursDecimale: Array<{ code: string,libelle: string }> = [{
		code: '.',
		libelle: 'POINT'
	},{
		code: ',',
		libelle: 'VIRGULE',
	},{
		code: '',
		libelle: 'AUCUN'
	}];

	/** Liste des formats de date **/
	private readonly listeFormatsDateCourte: Array<{ code: string,libelle: string }> = [{
		code: 'DD/MM/YYYY',
		libelle: 'DD/MM/YYYY - ' + moment().format('DD/MM/YYYY')
	},{
		code: 'YYYY/MM/DD',
		libelle: 'YYYY/MM/DD - ' + moment().format('YYYY/MM/DD')
	},{
		code: 'M/D/YYYY',
		libelle: 'M/D/YYYY - ' + moment().format('M/D/YYYY')
	},{
		code: 'D/M/YYYY',
		libelle: 'D/M/YYYY - ' + moment().format('D/M/YYYY')
	},{
		code: 'DD-MM-YYYY',
		libelle: 'DD-MM-YYYY - ' + moment().format('DD-MM-YYYY')
	},{
		code: 'YYYY-MM-DD',
		libelle: 'YYYY-MM-DD - ' + moment().format('YYYY-MM-DD')
	},{
		code: 'DD.MM.YYYY',
		libelle: 'DD.MM.YYYY - ' + moment().format('DD.MM.YYYY')
	},{
		code: 'DDMMYYYY',
		libelle: 'DDMMYYYY - ' + moment().format('DDMMYYYY')
	},{
		code: 'YYYYMMDD',
		libelle: 'YYYYMMDD - ' + moment().format('YYYYMMDD')
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {}

	/**
	 * Récupération de la liste des séparateurs de décimaux
	 */
	public getListeSeparateursDecimale(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des séparateurs de décimaux
		return this.listeSeparateursDecimale.map(item => ({
			code: item.code,
			libelle: this.translateService.instant(`structureExport.separateur.${item.libelle}`)
		}));
	}

	/**
	 * Récupération de la liste des formats de date
	 */
	public getListeFormatsDateCourte(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des formats de date
		return this.listeFormatsDateCourte;
	}

	/**
	 * Liste des locales du tenant
	 */
	public getListeLocalesForTenant(): Observable<Array<any>> {
		//Vérification de la présence de la liste des locales
		if (!this.listeLocales)
			//Liste des locales du tenant
			return this.http.post<Result>(`${environment.baseUrl}/controller/Locale/getListeLocalesForTenant`,null).pipe(
				map(result => result.data?.listeLocales),
				tap(listeLocales => this.listeLocales = listeLocales)
			);
		else
			//Retour de la liste des locales
			return of<Array<any>>(this.listeLocales);
	}
}