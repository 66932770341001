import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { DetailListItemComponent } from './detail-list-item.component';
import { mapEntites } from './detail.entites';

@Component({
	templateUrl: './detail-list.component.html'
})
export class DetailListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,DetailListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,public extractionService: ExtractionService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DetailListItemComponent>({
			uri: `/controller/Facture/filtreDetails`,
			title: this.translateService.instant('facture.detail.liste.title'),
			component: DetailListItemComponent,
			listeFilters: [{
				clef: 'facture.reference',
				title: this.translateService.instant('search.facture'),
				isDefault: true
			},{
				clef: 'facture.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				title: this.translateService.instant('search.fournisseur'),
				isDefault: true
			},{
				clef: '*vehicule.reference',
				title: this.translateService.instant('search.immatriculation'),
				isDefault: true
			},{
				clef: '-date',
				type: TypeFilter.DATE
			},{
				clef: 'montant',
				type: TypeFilter.DECIMAL
			},{
				clef: 'nature',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurNature'
				}
			}],
			defaultOrder: 'idDetail',
			getKeyFieldName: () => 'idDetail',
			listeActions: []
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}
}