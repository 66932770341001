import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { VehiculeService } from './vehicule.service';

@Component({
	selector: 'vehicule-add',
	template: ''
})
export class VehiculeAddComponent extends AutocompleteAddComponent implements OnInit {
	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<VehiculeAddComponent>,private vehiculeService: VehiculeService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Affichage de la sélection d'une immatriculation
		this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any,vehicule: any,demandeVehicule: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Vérification l'absence de véhicule
				if (!data.demandeVehicule) {
					//Enregistrement du véhicule
					this.vehiculeService.saveVehicule(data.immatriculation ? {
						typeVehicule: 'VEHICULE_FONCTION_SERVICE',
						reference: data.immatriculation.reference,
						actif: true,
						immatriculation: data.immatriculation,
						modele: data.params.modele,
						dateEntree: data.params.dateEntree,
						societe: data.params?.societe,
						service: data.params?.service
					} : data.vehicule,false,!!data.params?.societe).subscribe({
						next: result => {
							//Vérification du résultat
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Mémorisation du véhicule enregistré
								this.addedItem = result.data.vehicule;

								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

								//Fermeture de la popup
								this.bsModalRef.hide();
							} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
									field: this.translateService.instant('actions.doublon.immatriculation')
								}));
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						}
					});
				} else {
					//Définition du véhicule
					this.addedItem = data.demandeVehicule.vehicule;

					//Fermeture de la popup
					this.bsModalRef.hide();
				}
			} else {
				//Fermeture de la popup
				this.bsModalRef.hide();
			}
		},{
			isDateEntreeVisible: true,
			isWithEngin: !!this.filter?.isWithEngin,
			isWithDemandeVehicule: !!this.filter?.isWithDemandeVehicule,
			isImputationVisible: true
		}).subscribe({
			error: () => this.bsModalRef.hide()
		});
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem(): void {
		//Non implementé
		throw new Error('Methode non implémentée');
	}
}
