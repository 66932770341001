import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result } from 'src/app/domain/common/http/result';
import { UPDATE_OPEN_CREATION } from 'src/app/reducers/navigation';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { environment } from 'src/environments/environment';
import { DashboardFavorisComponent } from './dashboard-favoris.component';

@Injectable()
export class DashboardFavorisService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private store: Store<AppState>,private layoutService: LayoutService,private bsModalService: BsModalService) {}

	/**
	 * Chargement de la liste des favoris
	 */
	public findAllFavorisCreation(): Observable<Array<any>> {
		//Chargement de la liste des favoris
		return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/findFavorisCreation`,null).pipe(map(result => result?.data?.listeFavoris || []));
	}

	/**
	 * Enregistrement/Suppression du favoris
	 */
	public saveFavorisCreation(favoris: any,isDeletion: boolean): Observable<Result> {
		//Enregistrement du favoris
		return this.http.put<Result>(`${environment.baseUrl}/controller/Dashboard/saveFavorisCreation/${isDeletion}`,favoris);
	}

	/**
	 * Réalisation de l'action liée au favoris
	 */
	public doActionForFavoris(favoris: any) {
		//Définition de l'indicateur de création
		this.store.dispatch({
			type: UPDATE_OPEN_CREATION,
			payload: favoris.state
		});

		//Navigation vers la route
		this.layoutService.goToByState(favoris.state);
	}

	/**
	 * Affichage de la liste des favoris
	 */
	public showListeFavoris(listeFavoris: Array<any>): Observable<boolean> {
		let bsModalRef: BsModalRef<DashboardFavorisComponent>;

		//Affichage de la popup de sélection des favoris
		bsModalRef = this.bsModalService.show(DashboardFavorisComponent,{
			initialState: {
				listeFavoris
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(map(() => bsModalRef.content.hasChanged));
	}
}