<ul class="condition-detail">
	<li class="header">
		<div *ngIf="(null | right:'creation') && !isRestricted" class="actions dropdown pull-right">
			<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
			<ul class="dropdown-menu dropdown-menu-right">
				<li *ngIf="parentFonction.condition?.listeItems?.length == 1" (click)="parentFonction.condition.type = 'AND'; initCondition(parentFonction,'condition');">
					<a [routerLink]="[]" translate>structureExport.fonction.si.actions.ajouterCritereEt</a>
				</li>
				<li *ngIf="parentFonction.condition?.listeItems?.length == 1" (click)="parentFonction.condition.type = 'OR'; initCondition(parentFonction,'condition');">
					<a [routerLink]="[]" translate>structureExport.fonction.si.actions.ajouterCritereOu</a>
				</li>
				<li *ngIf="!parentFonction.condition?.listeItems?.length || parentFonction.condition?.listeItems?.length > 1" (click)="initCondition(parentFonction,'condition');">
					<a [routerLink]="[]" translate>structureExport.fonction.si.actions.ajouterCritere</a>
				</li>
				<li (click)="initGroupeConditions(parentFonction,'condition');">
					<a [routerLink]="[]" translate>structureExport.fonction.si.actions.ajouterGroupeConditions</a>
				</li>
				<li (click)="deleteCondition(rootFonction,idxCondition)" *ngIf="rootFonction">
					<a [routerLink]="[]" translate>actions.supprimer</a>
				</li>
			</ul>
		</div>
	</li>
	<li class="content">
		<ul class="condition-list">
			<ng-container *ngFor="let item of parentFonction.condition?.listeItems; let idxCondition = index; let isLast = last;">
				<li>
					<div *ngIf="!item.condition">
						<structure-export-rubrique-fonction-si-condition-item [item]="item" [parentFonction]="parentFonction" [idxCondition]="idxCondition" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-fonction-si-condition-item>
					</div>
					<div *ngIf="item.condition">
						<structure-export-rubrique-fonction-si-condition [parentFonction]="item" [rootFonction]="parentFonction" [idxCondition]="idxCondition" [isRestricted]="isRestricted"></structure-export-rubrique-fonction-si-condition>
					</div>
				</li>
				<li *ngIf="!isLast">
					<h4 *ngIf="parentFonction.condition?.type == 'AND' || !parentFonction.condition.type"><span class="fonction-label" translate>common.et</span></h4>
					<h4 *ngIf="parentFonction.condition?.type == 'OR'"><span class="fonction-label" translate>common.ou</span></h4>
				</li>
			</ng-container>
			<li *ngIf="(null | right:'creation') && !isRestricted && !parentFonction.condition?.listeItems?.length">
				<div class="condition-item hoverable">
					<a [routerLink]="[]" (click)="initCondition(parentFonction,'condition')" translate>structureExport.fonction.si.actions.definirCritere</a>
				</div>
			</li>
			<li *ngIf="(!(null | right:'creation') || isRestricted) && !parentFonction.condition?.listeItems?.length">
				<div class="condition-item hoverable" translate>structureExport.fonction.si.critereNonDefini</div>
			</li>
		</ul>
	</li>
</ul>