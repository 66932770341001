import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TodoListItemEditComponent } from './todo-list-item-edit.component';
import { TodoListService } from './todo-list.service';

@Component({
	templateUrl: './todo-list-item.component.html'
})
export class TodoListItemComponent extends ListViewItem<any> implements OnInit {
	/** Liste des entités **/
	listeEntites?: Array<any>;

	/** Statut de la tâche */
	isDone: boolean;

	/**
	 * Constructeur
	 */
	constructor(private todoListService: TodoListService,private translateService: TranslateService,private toastrService: ToastrService,private pageContentService: PageContentService,private layoutService: LayoutService,private entiteService: EntiteService,private extractionService: ExtractionService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation du statut
		this.isDone = this.data.statut === 'DONE';

		//Chargement de la liste des entités
		this.extractionService.getListeEntites().subscribe({
			next: (listeEntites: any) => {
				//Récupération des entités ayant un autocomplete
				listeEntites = listeEntites.filter(entite => entite?.autocomplete != null)

				//Ajout d'une option vide
				listeEntites.unshift({
					code: null,
					libelle: this.translateService.instant('common.aucun')
				});

				//Définition de la liste des entités
				this.listeEntites = listeEntites;
			}
		});
	}

	/**
	 * Affichage du détail de la tâche
	 */
	showDetail() {
		//Affichage du détail
		this.todoListService.isShowDetail = true;

		//Ouverture du composant d'édition
		this.pageContentService.open(TodoListItemEditComponent,{
			data: {
				item: this.data
			}
		},'todoList').subscribe({
			next: item => {
				//Vérification des données
				if (item) {
					//Mise à jour de la tâche
					this.onObjectChange(item,item.idItem);
				} else if (item === null)
					//Suppression de l'élément
					this.onObjectChange(item);

				//Affichage du détail
				this.todoListService.isShowDetail = false;
			}
		});
	}

	/**
	 * Suppression de l'élément
	 */
	deleteItem() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'élément
					this.todoListService.deleteItem(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Génération de la liste des utilisateurs
	 */
	getListeUsersAsString(): string {
		//Fabrication du libellé
		return this.data.listeUsers.map(u => `${u.prenom} ${u.nom} (${u.matricule})`).join(', ');
	}

	/**
	 * Changement de statut et enregistrement
	 */
	onStatutChange() {
		//Définition du nouveau statut
		this.data.statut = this.isDone ? 'DONE' : 'TODO';

		//Enregistrement de la tâche
		this.todoListService.saveItem(this.data).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de la tâche
					this.onObjectChange(result.data.item,this.data.idItem);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Navigation vers l'entité liée à la tâche
	 */
	navigateTo() {
		let selectedEntite;

		//Recherche de l'entité selectionnée
		selectedEntite = this.listeEntites?.find(e => e.code === this.data.entite);

		//Vérification du type de page (formulaire dédié)
		if (selectedEntite?.route?.match(/{}$/)) {
			//Navigation vers l'entité
			this.layoutService.goToByUrl(selectedEntite?.route?.replace(/{}$/,this.data.idEntite).replace('#',''));
		} else if (selectedEntite.state) {
			//Navigation vers l'entité (liste)
			this.layoutService.goToByState(selectedEntite.state,{
				savedSearch: {
					extraData: {
						entite: selectedEntite.code,
						operator: 'AND',
						_type: 'com.notilus.data.rule.Rule',
						listeDetails: [{
							filter: {
								filter: selectedEntite.code.split('.').pop(-1) + '.' + selectedEntite.routeKey,
								listeValues: [{
									type: 'SIMPLE',
									value: this.data.idEntite
								}],
								operateur: 'EQUAL',
								type: 'java.lang.Long',
								typeAgregation: 'NON_DEFINI'
							}
						}],
						titre: this.data.labelEntite
					}
				},
				isOpenFirstItem: true,
				reloadOnSameUrl: true
			});
		}
	}
}