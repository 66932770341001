<fieldset [disabled]="!(TypeDroit.ADMIN_TENANT_AUTORISATION | right:'creation') || isProfilRestricted && autorisation?.idAutorisation">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" *ngIf="source != 'TENANT'" [ngClass]="{ 'has-error': tenant.invalid }">
				<label translate>tenant.autorisation.tenant</label>
				<div>
					<autocomplete name="tenant" type="tenant" #tenant="ngModel" [(ngModel)]="autorisation.tenant" required></autocomplete>
				</div>
			</div>
			<div class="form-group" *ngIf="source != 'USER'" [ngClass]="{ 'has-error': user.invalid }">
				<label translate>tenant.autorisation.user</label>
				<div>
					<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="autorisation.user" [filter]="{ typeRestrictionProfil: 'CLIENT' }" [isCreationDisabled]="true" required [disabled]="isProfilRestricted"></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
				<label translate>tenant.autorisation.dateDebut.item</label>
				<div class="half">
					<div class="input-group">
						<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="autorisation.dateDebut" datePicker format="date" [minDate]="'today'" [storeDate]="true" required/>
						<div class="input-group-addon no-border">
							<icon name="info_outline" [tooltip]="'tenant.autorisation.dateDebut.description' | translate"></icon>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid || !isDateFinValid() }">
				<label translate>tenant.autorisation.dateFin.item</label>
				<div class="half">
					<div class="input-group">
						<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="autorisation.dateFin" datePicker format="date" [minDate]="autorisation.dateDebut" [storeDate]="true" required/>
						<div class="input-group-addon no-border">
							<icon name="info_outline" [tooltip]="'tenant.autorisation.dateFin.description' | translate"></icon>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</fieldset>