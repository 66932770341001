import { Component,Input } from '@angular/core';
import { ListView } from 'src/app/domain/common/list-view';

import { LotComptableService } from './lot-comptable.service';
import { LotComptableCheckRegleTaxeListItemComponent } from './lot-comptable-check-regle-taxe-list-item.component';

@Component({
	selector: 'lot-compable-check-regle-taxe',
	templateUrl: './lot-comptable-check-regle-taxe.component.html'
})
export class LotComptableCheckRegleTaxeComponent {
	/** Nombre d'anomalies **/
	@Input() nbAnomalies: number;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Données **/
	liste: ListView<any,LotComptableCheckRegleTaxeListItemComponent>;

	/** Mémorisation des règles de taxe **/
	listeReglesTaxe: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private lotComptableService: LotComptableService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckRegleTaxeListItemComponent>({
			uri: () => `/controller/LotComptable/filtrePostesChargeForControle/REGLE_TAXE`,
			component: LotComptableCheckRegleTaxeListItemComponent,
			isLocal: true,
			extraOptions: {
				nbAnomalies: this.nbAnomalies
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			defaultOrder: 'libelle,reference',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })],
			onBeforeRefresh: () => {
				//Mémorisation des données
				this.refreshData();
			},
			onRefresh: (liste) => {
				//Vérification du contenu
				if (liste?.data?.content?.length) {
					//Parcours du contenu
					liste.data.content.forEach(item => {
						let compte: any;

						//Recherche du compte associé
						compte = this.listeReglesTaxe.filter(c => c.type == item.posteCharge.type && c.idPosteCharge == item.posteCharge.idObject && c.idPlanComptable == item.idPlanComptable)[0];

						//Restauration du compte
						item.regleTaxe = compte?.regleTaxe;
					});
				}
			}
		});
	}

	/**
	 * Rafraichissement des données
	 */
	refreshData() {
		//Vérification du contenu
		if (this.liste?.data) {
			//Parcours du contenu
			this.liste.data.content.forEach(item => {
				let compte: any;

				//Recherche du compte associé
				compte = this.listeReglesTaxe.filter(c => c.type == item.posteCharge.type && c.idPosteCharge == item.posteCharge.idObject && c.idPlanComptable == item.idPlanComptable)[0];

				//Vérification du compte
				if (!compte) {
					//Création du compte
					compte = {
						type: item.posteCharge.type,
						idPosteCharge: item.posteCharge.idObject,
						idPlanComptable: item.idPlanComptable,
						idPays: item.idPays
					};

					//Vérification du compte
					if (item.regleTaxe)
						//Ajout du compte à la liste
						this.listeReglesTaxe.push(compte);
				}

				//Mise à jour du compte
				compte.regleTaxe = item.regleTaxe;
			});
		}

		//Retour de la liste des comptes
		return this.listeReglesTaxe || [];
	}

	/**
	 * Récupération du nombre de corrections
	 */
	getNbCorrections() {
		//Retour du nombre de corrections
		return this.refreshData().filter(compte => compte.regleTaxe).length;

	}

	/**
	 * Réalisation des corrections
	 */
	doCorrection() {
		//Rafraichissement des données
		this.refreshData();

		//Envoi des corrections
		return this.lotComptableService.doPlanComptableCorrection('REGLE_TAXE',this.listeReglesTaxe.map(c => ({
			idPlanComptable: c.idPlanComptable,
			idPays: c.idPays,
			idObjectToCorrect: c.idPosteCharge,
			idValue: c.regleTaxe.idRegle
		})));
	}
}
