import { TypeListeValidation } from 'src/app/domain/workflow/validation';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { WorkflowService } from '../workflow.service';

@Component({
	selector: 'validation-list-item',
	templateUrl: './validation-list-item.component.html'
})
export class ValidationListItemComponent extends ListViewItem<any> {
	/** Type de liste de validation **/
	public TypeListeValidation: typeof TypeListeValidation = TypeListeValidation;

	/**
	 * Constructeur
	 */
	constructor(public translateService: TranslateService,public datePipe: DatePipe,private workflowService: WorkflowService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Déclenchement d'une action
	 */
	doAction(action: any) {
		//Déclenchement d'une action
		this.extraOptions?.entite.doAction(this.data,action,this.extraOptions?.onActionFinished);
	}

	/**
	 * Affichage de l'historique des actions Workflow
	 */
	showWorkflowHistory() {
		//Affichage de l'historique des actions Workflow
		this.workflowService.showWorkflowHistory(this.extraOptions?.entite.entite,this.data[this.extraOptions?.entite.getKeyFieldName()]);
	}
}
