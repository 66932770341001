import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeAmenagementListItemComponent } from './type-amenagement-list-item.component';

@Component({
	templateUrl: './type-amenagement-list.component.html'
})
export class TypeAmenagementListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TypeAmenagementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TypeAmenagementListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreTypesAmenagement`,
			title: this.translateService.instant('vehicule.typeAmenagement.liste.title'),
			component: TypeAmenagementListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Vehicule/loadTypeAmenagement/0'))
		});
	}
}