<div class="pull-left lv-avatar">
	<div *ngIf="!data.vehicule.dateSortie || data.vehicule.dateSortie > today">
		<icon name="person" *ngIf="data.typeAffectation == 'VEHICULE_FONCTION'"></icon>
		<icon name="apartment" *ngIf="data.typeAffectation == 'VEHICULE_SERVICE'"></icon>
		<icon name="lock" *ngIf="data.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
		<icon name="event" *ngIf="data.typeAffectation == 'VEHICULE_POOL'"></icon>
		<icon name="question_mark" *ngIf="!data.typeAffectation"></icon>
	</div>
	<icon name="logout" *ngIf="data.vehicule.dateSortie && data.vehicule.dateSortie <= today"></icon>
</div>

<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="(rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') || rightService.hasRight(null,'creation')) && extraOptions?.showAffectation(data);">{{ data.vehicule | display:'vehicule' }}</a></div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.affectation.dateDebut.item</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li *ngIf="data.dateFin"><span translate>vehicule.affectation.dateFin.item</span>&#160;:&#160;{{ data.dateFin | date:'short' }}</li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.statut</span>&#160;:&#160;<span [translate]="'reservation.statut.'+data.reservation.statut"></span></li>
		<li *ngIf="data.reservation"><span translate>vehicule.affectation.reservation.dateReservation</span>&#160;:&#160;{{ data.reservation.dateCreation | date:'short' }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation') || (TypeDroit.ADMIN_VEHICULE | right:'creation') || (null | right:'creation')">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'creation') || (null | right:'creation')) && !data.reservation"><a [routerLink]="[]" (click)="deleteAffectation();" translate>actions.supprimer</a></li>
			<li *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'consultation')"><a [routerLink]="[]" (click)="showVehicule(data.vehicule);" translate>conducteur.affectation.actions.accederVehicule</a></li>
			<li *ngIf="data.typeAffectation == 'VEHICULE_FONCTION' && (TypeDroit.VEHICULE_AFFECTATION_ECHEANCE | right:'consultation')"><a [routerLink]="[]" (click)="goToAffectationEcheances();" translate>conducteur.affectation.actions.accederEcheancierAvantagesNature</a></li>
			<li *ngIf="data.reservation && (TypeDroit.VEHICULE_RESERVATION | right:'consultation')"><a [routerLink]="[]" (click)="goToReservation();" translate>conducteur.affectation.actions.accederReservation</a></li>
			<li *ngIf="data.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation'))"><a [routerLink]="[]" (click)="goToPlanning();" translate>conducteur.affectation.actions.accederPlanning</a></li>
		</ul>
	</div>
</div>