import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';
import { pick } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PlanEntretienService } from './plan-entretien.service';

@Component({
	templateUrl: './plan-entretien-list-item.component.html'
})
export class PlanEntretienListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private planEntretienService: PlanEntretienService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression de l'objet
	 */
	deletePlanEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.planEntretienService.deletePlanEntretien(this.data))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.PlanEntretien',this.data.idPlanEntretien);
	}

	/**
	 * Duplication du plan d'entretien
	 */
	duplicatePlanEntretien() {
		//Navigation vers la page de duplication
		this.layoutService.goToByState('vehiculeReferentiel-listePlansEntretien-loadPlanEntretien',{
			routeParams: {
				idPlanEntretien: 0
			},
			routeData: {
				planEntretienOrigine: pick(this.data,['idPlanEntretien','libelle','actif','duree','distance','objectVersion'])
			},
			reloadOnSameUrl: true
		});
	}
}