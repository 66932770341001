import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CoursListItemComponent } from './cours-list-item.component';
import { CoursListComponent } from './cours-list.component';
import { CoursComponent } from './cours.component';
import { CoursService } from './cours.service';

@NgModule({
	imports: [ShareModule],
	declarations: [CoursListComponent,CoursListItemComponent,CoursComponent],
	providers: [CoursService]
})
export class CoursModule { }