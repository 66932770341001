import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	/** Entête de sécurité **/
	private xAuthToken: string = null;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.xAuthToken = session?.xAuthToken || null);
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification du token de sécurité
		if (this.xAuthToken && !request.url.endsWith('/Login/refresh'))
			//Ajout de l'entête d'authentification
			return next.handle(request.clone({ headers: request.headers.set('X-AUTH-TOKEN',this.xAuthToken) }));
		else
			//Laisser passer la requête
			return next.handle(request);
	}
}