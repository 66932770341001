import { Component,Input,OnInit } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { SearchItemField } from '../autocomplete';

@Component({
	templateUrl: './autocomplete-search-item.component.html'
})
export class AutocompleteSearchItemComponent extends ListViewItem<any> implements OnInit {
	/** Elément courant **/
	@Input() data: any;

	/** Options supplémentaires **/
	@Input() extraOptions: any;

	/** Liste des champs suppplémentaires **/
	public listeSearchItemFields: Array<SearchItemField>;

	/** Indicateur de présence d'une icone pour l'avatar **/
	public isAvatarIcon: boolean = false;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des champs supplémentaires
		this.listeSearchItemFields = this.getListeSearchItemFieldsAvailable(this.extraOptions.listeSearchItemFields?.() || []);

		//Vérification de la présence d'une icone pour l'avatar
		this.isAvatarIcon = typeof this.extraOptions?.displayAvatar?.(this.data) == 'object';
	}

	/**
	 * Récupération de la liste des champs supplémentaires visibles
	 */
	private getListeSearchItemFieldsAvailable(listeFields: Array<SearchItemField>): Array<SearchItemField> {
		//Récupération de la valeur et filtrage de la liste
		return listeFields.map(f => {
			//Définition de la valeur
			f.value = f.getValue(this.data,this.extraOptions.translateService);
			f.countryCode = f.getCountryCode?.(this.data);

			return f;
		}).filter(f => f.value);
	}
}