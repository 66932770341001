import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RuleService } from 'src/app/share/components/rule/rule.service';
import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export-rubrique-detail',
	templateUrl: './structure-export-rubrique-detail.component.html'
})
export class StructureExportRubriqueDetailComponent implements OnInit {
	/** Détail **/
	@Input() detail: any;

	/** Parent **/
	@Input() parent: any;

	/** Clé du parent **/
	@Input() parentKey: string;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Changement du type de rubrique **/
	@Output() onTypeRubriqueChange : EventEmitter<{ parent: any,parentKey: string }> = new EventEmitter<{ parent: any,parentKey: string }>();

	/** Liste des types d'agrégation **/
	listeTypesAgregation: Array<{ code: string,libelle: string }>;

	/** Liste des types d'extraction **/
	listeTypesExtraction: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService,private translateService: TranslateService,private structureExportService: StructureExportService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types d'agrégation
		this.listeTypesAgregation = [{
			code: 'NON_DEFINI',
			libelle: this.translateService.instant('rule.agregation.NON_DEFINI')
		}].concat(this.ruleService.getListeTypesAgregation());

		//Récupération de la liste des types d'extraction
		this.listeTypesExtraction = this.structureExportService.getListeTypesExtraction();
	}
}