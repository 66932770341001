import { Component,Input,OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	selector: 'conducteur-affectation-edit',
	templateUrl: './conducteur-affectation-edit.component.html'
})
export class ConducteurAffectationEditComponent extends PageContentItem implements OnInit {
	/** Affectation **/
	@Input() affectation: any;

	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Accès au véhicule
	 */
	showVehicule(affectation: any) {
		//Redirection vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: affectation.vehicule?.idVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Fermeture de l'affectation après enregistrement
	 */
	onContentClose(data: any) {
		//Fermeture du formulaire d'édition
		this.close(data || { affectation: null });
	}
}