import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserSchedulerService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Enregistrement d'un ordonnancement pour un utilisateur
	 */
	public saveUserScheduler(userScheduler: any): Observable<Result> {
		//Enregistrement de l'ordonnancement pour un utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/UserScheduler/saveUserScheduler`,userScheduler);
	}

	/**
	 * Suppression d'un ordonnancement pour un utilisateur
	 */
	public deleteUserScheduler(idUserScheduler: number): Observable<Result> {
		//Suppression de l'ordonnancement pour un utilisateur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/UserScheduler/deleteUserScheduler/${idUserScheduler}`);
	}
}