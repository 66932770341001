import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter,map,take } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { AdresseAddComponent } from './adresse-add.component';

@Injectable()
export class AdresseService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService) { }

	/**
	 * Recherche de la localisation
	 */
	public searchLocation(location: string,sessionToken: string,searchMode?: 'VILLE' | 'ADRESSE' | 'FULL',withCoords?: boolean): Observable<Array<any>> {
		//Recherche de la localisation
		return this.http.post<Array<any>>(`${environment.baseUrl}/controller/Map/searchLocation${searchMode ? '/' + searchMode : ''}`,{
			location
		},{
			params: {
				sessionToken,
				withCoords
			}
		});
	}

	/**
	 * Recherche de la localisation détaillée
	 */
	public findLocationDetail(placeId: string,sessionToken: string,searchMode?: 'VILLE' | 'ADRESSE' | 'FULL',withCoords?: boolean): Observable<any> {
		//Recherche de la localisation détaillée
		return this.http.post<Result>(`${environment.baseUrl}/controller/Map/findLocationDetail/${placeId}${searchMode ? '/' + searchMode : ''}`,null,{
			params: {
				sessionToken,
				withCoords
			}
		}).pipe(
			map(result => result?.data?.adresse)
		);
	}

	/**
	 * Affichage de la pop-up de création d'une adresse
	 */
	public showCreation(searchMode: 'VILLE' | 'ADRESSE' | 'FULL',isEdition?: boolean,adresse?: any): Observable<any | null> {
		let bsModalRef: BsModalRef<AdresseAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(AdresseAddComponent,{
			initialState: {
				isEdition,
				adresse,
				searchMode
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			take(1),
			map(() => bsModalRef.content.adresse),
			filter(item => item?.label && isEdition)
		);
	}
}