<list-view [liste]="liste" *ngIf="liste && !selectedFournisseur"></list-view>

<div class="row attachment-inline" *ngIf="liste && (selectedFournisseur || liste?.data?.content?.length)">
	<div class="col-md-4 attachment" [ngClass]="{ 'col-md-offset-4': selectedFournisseur }" *ngFor="let fournisseur of (selectedFournisseur ? [selectedFournisseur] : liste.data.content)">
		<import-fournisseur-list-item [data]="fournisseur" [selectedFournisseur]="selectedFournisseur" (onFournisseurSelected)="onFournisseurSelected($event)"></import-fournisseur-list-item>
	</div>
</div>
<div class="text-center p-10" *ngIf="!selectedFournisseur && !liste?.data?.content">
	<please-wait><h5 translate>liste.loading</h5></please-wait>
</div>
<div class="text-center p-10" *ngIf="!selectedFournisseur && liste?.data?.content?.length == 0">
	<h5><span translate>liste.empty</span></h5>
</div>