<div class="modal-header">
	<h4 class="modal-title"><span translate>grilleFluidite.import.title</span></h4>
</div>
<div class="modal-body">
	<attachment #attachment="attachment" [options]="attachmentOptions"></attachment>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="!grilleFluidite.listeLinks?.length" (click)="doImport()">
		<span translate>actions.envoyer</span>
	</button>
</div>