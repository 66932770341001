import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './lot-comptable-structure-export-result.component.html'
})
export class LotComptableStructureExportResult {
	/** Liste des résultats **/
	@Input() listeResults: Array<any>;

	/** Identifiant de la session **/
	@Input() idSession: string;

	/** Méthode de téléchargement d'un fichier **/
	@Input() downloadExportFile: Function;

	/** Date d'export **/
	@Input() exportDate: string;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<LotComptableStructureExportResult>) {}
}