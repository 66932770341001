/**
 * Enumération des types d'attachment
 */
export enum TypeAttachment {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	CLIENT_CRM = 'CLIENT_CRM',
	IMPORT = 'IMPORT',
	TEMPLATE_MAIL = 'TEMPLATE_MAIL',
	POOL = 'POOL',
	FOURNISSEUR_INTERFACE = 'FOURNISSEUR_INTERFACE',
	AMENAGEMENT = 'AMENAGEMENT',
	ENTRETIEN = 'ENTRETIEN',
	REVENDEUR = 'REVENDEUR',
	THEME = 'THEME',
	EXTRACTION = 'EXTRACTION',
	DATABASE = 'DATABASE',
	VEHICULE = 'VEHICULE',
	VEHICULE_AFFECTATION = 'VEHICULE_AFFECTATION',
	SINISTRE = 'SINISTRE',
	CONTRAT_CRM = 'CONTRAT_CRM',
	PLAN_COMPTABLE = 'PLAN_COMPTABLE',
	ARTICLE = 'ARTICLE',
	DEMANDE_IDENTIFICATION = 'DEMANDE_IDENTIFICATION',
	CONTRAVENTION = 'CONTRAVENTION',
	FACTURE = 'FACTURE',
	USER = 'USER',
	TENANT = 'TENANT',
	FINANCEMENT = 'FINANCEMENT',
	LOI_ROULAGE = 'LOI_ROULAGE',
	GRILLE_FLUIDITE = 'GRILLE_FLUIDITE',
	VEHICULE_CONDUCTEUR = 'VEHICULE_CONDUCTEUR',
	VEHICULE_IMPUTATION = 'VEHICULE_IMPUTATION',
	ETAT_LIEUX = 'ETAT_LIEUX',
	EQUIPEMENT = 'EQUIPEMENT',
	EQUIPEMENT_AFFECTATION = 'EQUIPEMENT_AFFECTATION',
	GRILLE_ATTRIBUTION = 'GRILLE_ATTRIBUTION',
	GRILLE_ATTRIBUTION_MODELE_FINANCEMENT = 'GRILLE_ATTRIBUTION_MODELE_FINANCEMENT',
	COTATION = 'COTATION',
	DEMANDE_VEHICULE = 'DEMANDE_VEHICULE',
	DEMANDE_VEHICULE_FINANCEMENT = 'DEMANDE_VEHICULE_FINANCEMENT',
	FOURNISSEUR = 'FOURNISSEUR',
	CONTACT = 'CONTACT',
	REPONSE_COTATION = 'REPONSE_COTATION',
	LOT_COMPTABLE = 'LOT_COMPTABLE',
	STRUCTURE_EXPORT = 'STRUCTURE_EXPORT'
}

/**
 * Contexte des types d'attachment
 */
export const TypeAttachmentContext: { [typeAttachment: string]: { type: string,key: string,mainType?: TypeAttachment,typeLinkClass?: string,isMobile?: boolean } } = {
	/** Données **/
	[TypeAttachment.NON_DEFINI]: {
		type: null,
		key: null
	},
	[TypeAttachment.CLIENT_CRM]: {
		type: 'com.notilus.data.attachment.link.LinkClientCRM',
		key: 'idClient'
	},
	[TypeAttachment.IMPORT]: {
		type: 'com.notilus.data.attachment.link.LinkConnecteurExecution',
		key: 'idExecution'
	},
	[TypeAttachment.TEMPLATE_MAIL]: {
		type: 'com.notilus.data.attachment.link.LinkTemplateMail',
		key: 'idTemplateMail'
	},
	[TypeAttachment.POOL]: {
		type: 'com.notilus.data.attachment.link.LinkPool',
		key: 'idPool'
	},
	[TypeAttachment.FOURNISSEUR_INTERFACE]: {
		type: 'com.notilus.data.attachment.link.LinkFournisseurInterface',
		key: 'idInterface'
	},
	[TypeAttachment.AMENAGEMENT]: {
		type: 'com.notilus.data.attachment.link.LinkAmenagement',
		key: 'idAmenagement'
	},
	[TypeAttachment.ENTRETIEN]: {
		type: 'com.notilus.data.attachment.link.LinkEntretien',
		key: 'idEntretien',
		isMobile: true
	},
	[TypeAttachment.REVENDEUR]: {
		type: 'com.notilus.data.attachment.link.LinkRevendeur',
		key: 'idRevendeur'
	},
	[TypeAttachment.THEME]: {
		type: 'com.notilus.data.attachment.link.LinkTheme',
		key: 'idTheme',
		typeLinkClass: 'com.notilus.data.attachment.link.LinkTheme$TypeLinkTheme'
	},
	[TypeAttachment.EXTRACTION]: {
		type: 'com.notilus.data.attachment.link.LinkExtraction',
		key: 'idExtraction'
	},
	[TypeAttachment.DATABASE]: {
		type: 'com.notilus.data.attachment.link.LinkDatabase',
		key: 'idDatabase'
	},
	[TypeAttachment.VEHICULE]: {
		type: 'com.notilus.data.attachment.link.LinkVehicule',
		key: 'idVehicule',
		typeLinkClass: 'com.notilus.data.attachment.link.LinkVehicule$TypeLinkVehicule',
		isMobile: true
	},
	[TypeAttachment.VEHICULE_AFFECTATION]: {
		type: 'com.notilus.data.attachment.link.LinkVehiculeAffectation',
		key: 'idAffectation'
	},
	[TypeAttachment.SINISTRE]: {
		type: 'com.notilus.data.attachment.link.LinkSinistre',
		key: 'idSinistre',
		isMobile: true
	},
	[TypeAttachment.CONTRAT_CRM]: {
		type: 'com.notilus.data.attachment.link.LinkContratCRM',
		key: 'idContratCRM'
	},
	[TypeAttachment.PLAN_COMPTABLE]: {
		type: 'com.notilus.data.attachment.link.LinkPlanComptable',
		key: 'idPlanComptable'
	},
	[TypeAttachment.ARTICLE]: {
		type: 'com.notilus.data.attachment.link.LinkArticle',
		key: 'idArticle'
	},
	[TypeAttachment.DEMANDE_IDENTIFICATION]: {
		type: 'com.notilus.data.attachment.link.LinkDemandeIdentification',
		key: 'idIdentification'
	},
	[TypeAttachment.CONTRAVENTION]: {
		type: 'com.notilus.data.attachment.link.LinkContravention',
		key: 'idContravention'
	},
	[TypeAttachment.FACTURE]: {
		type: 'com.notilus.data.attachment.link.LinkFacture',
		key: 'idFacture'
	},
	[TypeAttachment.USER]: {
		type: 'com.notilus.data.attachment.link.LinkUser',
		key: 'idUser'
	},
	[TypeAttachment.TENANT]: {
		type: 'com.notilus.data.attachment.link.LinkTenant',
		key: 'idTenant'
	},
	[TypeAttachment.FINANCEMENT]: {
		type: 'com.notilus.data.attachment.link.LinkFinancement',
		key: 'idContrat'
	},
	[TypeAttachment.LOI_ROULAGE]: {
		type: 'com.notilus.data.attachment.link.LinkFinancementLoiRoulage',
		key: 'idLoiRoulage',
		mainType: TypeAttachment.FINANCEMENT
	},
	[TypeAttachment.GRILLE_FLUIDITE]: {
		type: null,
		key: 'idGrilleFluidite'
	},
	[TypeAttachment.VEHICULE_CONDUCTEUR]: {
		type: 'com.notilus.data.attachment.link.LinkVehiculeConducteur',
		key: 'idConducteur'
	},
	[TypeAttachment.VEHICULE_IMPUTATION]: {
		type: 'com.notilus.data.attachment.link.LinkVehiculeImputation',
		key: 'idImputation'
	},
	[TypeAttachment.ETAT_LIEUX]: {
		type: 'com.notilus.data.attachment.link.LinkEtatLieux',
		key: 'idEtatLieux'
	},
	[TypeAttachment.EQUIPEMENT]: {
		type: 'com.notilus.data.attachment.link.LinkEquipement',
		key: 'idEquipement'
	},
	[TypeAttachment.EQUIPEMENT_AFFECTATION]: {
		type: 'com.notilus.data.attachment.link.LinkEquipementAffectation',
		key: 'idAffectation',
		mainType: TypeAttachment.EQUIPEMENT
	},
	[TypeAttachment.GRILLE_ATTRIBUTION]: {
		type: 'com.notilus.data.attachment.link.LinkGrilleAttribution',
		key: 'idGrilleAttribution'
	},
	[TypeAttachment.GRILLE_ATTRIBUTION_MODELE_FINANCEMENT]: {
		type: 'com.notilus.data.attachment.link.LinkGrilleAttributionModeleFinancement',
		key: 'idFinancement'
	},
	[TypeAttachment.COTATION]: {
		type: 'com.notilus.data.attachment.link.LinkCotation',
		key: 'idCotation'
	},
	[TypeAttachment.DEMANDE_VEHICULE]: {
		type: 'com.notilus.data.attachment.link.LinkDemandeVehicule',
		key: 'idDemandeVehicule'
	},
	[TypeAttachment.DEMANDE_VEHICULE_FINANCEMENT]: {
		type: 'com.notilus.data.attachment.link.LinkDemandeVehiculeFinancement',
		key: 'idFinancement'
	},
	[TypeAttachment.FOURNISSEUR]: {
		type: 'com.notilus.data.attachment.link.LinkFournisseur',
		key: 'idFournisseur'
	},
	[TypeAttachment.CONTACT]: {
		type: 'com.notilus.data.attachment.link.LinkContact',
		key: 'idContact',
		mainType: TypeAttachment.FOURNISSEUR
	},
	[TypeAttachment.REPONSE_COTATION]: {
		type: 'com.notilus.data.attachment.link.LinkReponseCotation',
		key: 'idReponseCotation'
	},
	[TypeAttachment.LOT_COMPTABLE]: {
		type: 'com.notilus.data.attachment.link.LinkLotComptable',
		key: 'idLotComptable'
	},
	[TypeAttachment.STRUCTURE_EXPORT]: {
		type: 'com.notilus.data.attachment.link.LinkStructureExport',
		key: 'idStructure'
	}
}