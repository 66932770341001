<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addService?.(data,deleteService)">{{ data | display:'service' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idService">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idService">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteService();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>