import { Component,EventEmitter,Input,OnChanges,OnInit,Output,SimpleChanges,ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { TemporalSelectorService } from './temporal-selector.service';

@Component({
	selector: 'temporal-selector',
	templateUrl: './temporal-selector.component.html'
})
export class TemporalSelectorComponent implements OnInit,OnChanges {
	/** Opérateur sélectionné **/
	@Input() operateur?: 'EQUAL' | 'NOT_EQUAL' | 'GREATER' | 'GREATER_OR_NULL' | 'GREATER_OR_EQUAL' | 'LESS' | 'LESS_OR_NULL' | 'LESS_OR_EQUAL' | 'IS_NULL' | 'IS_NOT_NULL' | 'ROLLING_PERIOD';

	/** Temporal sélectionné **/
	@Input() temporal: string;

	/** Date courante **/
	@Input() date: any;

	/** Etat **/
	@Input() disabled?: boolean;

	/** Classe **/
	@Input() customClass: any;

	/** Gestion des modifications du temporal **/
	@Output() onTemporalChange: EventEmitter<{ temporal: string,date: any }> = new EventEmitter<{ temporal: string,date: any }>();

	/** Popover **/
	@ViewChild('popover') popover: PopoverDirective;

	/** Liste des fonctions disponibles **/
	listeTemporals: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private temporalSelectorService: TemporalSelectorService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des temporals
		this.listeTemporals = this.temporalSelectorService.getListeTemporals(this.operateur);
	}

	/**
	 * Interception des modifications
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la modification
		if (changes.operateur && !changes.operateur.isFirstChange())
			//Définition de la liste des temporals
			this.listeTemporals = this.temporalSelectorService.getListeTemporals(changes.operateur.currentValue);
	}

	/**
	 * Sélection d'un temporal
	 */
	selectTemporal(temporal: { code: string,libelle: string }) {
		//Mise à jour du temporal
		this.temporal = temporal.code;

		//Vérification du type
		if (this.temporal != 'AUCUNE') {
			//Suppression de la date
			this.date = null;

			//Fermeture de la popover
			this.popover.hide();
		}
	}

	/**
	 * Fermeture du sélecteur
	 */
	hide() {
		//Fermeture de la popover
		this.popover.hide();
	}

	/**
	 * Interception de la fermeture du popover
	 */
	onHidden() {
		//Mise à jour des données
		this.onTemporalChange.emit({
			temporal: this.temporal,
			date: this.date
		});
	}

	/**
	 * Récupération du libellé pour la valeur sélectionnée
	 */
	getLabelForValue(): string {
		//Formattage du libellé
		return this.temporalSelectorService.formatValue({ temporal: this.temporal,date: this.date });
	}
}