import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReservationListItemComponent } from './reservation-list-item.component';
import { ReservationListComponent } from './reservation-list.component';
import { ReservationComponent } from './reservation.component';
import { ReservationService } from './reservation.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ReservationListComponent,ReservationListItemComponent,ReservationComponent],
	providers: [ReservationService]
})
export class ReservationModule {}