<div class="modal-header">
	<h4 class="modal-title"><span translate>dataCustomization.fields.selection.title</span></h4>
</div>
<div class="modal-body">
	<div class="panel panel-default">
		<div class="panel-body">
			<ul class="list-group">
				<li class="list-group-item" *ngFor="let field of listeAllFields">
					<mat-checkbox name="selected" #selected="ngModel" [(ngModel)]="field.selected"></mat-checkbox>

					<span>
						<a [routerLink]="[]" (click)="field.selected = !field.selected;">{{ field.libelle }}</a>
					</span>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="saveSelection()">
		<span translate>actions.valider</span>
	</button>
</div>