import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './lot-comptable-structure-export-societes-anomalie.component.html'
})
export class LotComptableStructureExportSocietesAnomalie {
	/** Liste des sociétés sans structure d'export **/
	@Input() listeSocietes: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<LotComptableStructureExportSocietesAnomalie>) {}
}