import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ConnecteurTypeService } from './connecteur-type.service';
import { ConnecteurTypeListItemComponent } from './connecteur-type-list-item.component';
import { ConnecteurService } from '../connecteur.service';

@Component({
	templateUrl: './connecteur-type-list.component.html'
})
export class ConnecteurTypeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ConnecteurTypeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private connecteurTypeService: ConnecteurTypeService,private connecteurService: ConnecteurService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConnecteurTypeListItemComponent>({
			uri: `/controller/Connecteur/filtreConnecteurTypes`,
			title: this.translateService.instant('connecteur.connecteurType.liste.title'),
			component: ConnecteurTypeListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'typeConnecteur',
				type: TypeFilter.LONG,
				title: this.translateService.instant('search.typeConnecteur'),
				listeValues: this.connecteurTypeService.getListeTypesConnecteur()
			},{
				clef: 'typeExecution',
				type: TypeFilter.LONG,
				title: this.translateService.instant('search.typeExecution'),
				listeValues: this.connecteurService.getListeTypesExecution()
			}]
		});
	}
}