import { AfterViewInit,Component,forwardRef,Input,OnInit } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'summernote',
	templateUrl: './summernote.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SummernoteComponent),
		multi: true
	}]
})
export class SummernoteComponent implements OnInit,ControlValueAccessor,AfterViewInit {
	/** Mise à jour de la configuration summernote par défaut **/
	@Input() config: any;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Valeur initiale du modèle **/
	_initialValue: any;

	/** Configuration summernote par défaut **/
	summernoteConfig: any = {
		height: 300,
		toolbar: [
			['style',['bold','italic','underline','clear','strikethrough']],
			['fontsize',['color','fontsize','height']],
			['para',['ul','ol','paragraph']],
			['table',['table']]
		],
		lang: 'fr-FR'
	};

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Vérification de la présence d'une configuration
		if (this.config)
			//Mise à jour de la configuration
			this.summernoteConfig = { ...this.summernoteConfig,...this.config };
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation du DateTimePicker
			this.isInit = true;
		});
	}

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any): void {
		//Définition de la valeur
		this.value = value;
	}

	/**
	 * Enregistrement de la fonction d'interception du changement
	 */
	registerOnChange(fn: any): void {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement de la fonction d'interception de l'appui
	 */
	registerOnTouched(fn: any): void {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Définition de la valeur initiale
		this._initialValue = value;

		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);
		}
	}

	/**
	 * Détection d'un changement de valeur dans le composant summernote
	 */
	onValueChanged(event) {
		//Mise à jour de la valeur
		this.value = event;
	}
}