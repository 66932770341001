import { Directive,ElementRef,HostListener,Input,OnChanges,OnInit,SimpleChanges } from '@angular/core';

@Directive({
	selector: '[pattern]'
})
export class PatternDirective implements OnInit,OnChanges {
	/** Pattern à respecter **/
	@Input() pattern: string;

	/** Pattern complet à respecter **/
	@Input() fullPattern?: string;

	/** Expression régulière correspondant au pattern **/
	private regexp: RegExp;

	/** Expression régulière correspondant au pattern complet **/
	private fullPatternRegexp: RegExp;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création de l'expression régulière
		this.regexp = new RegExp(this.pattern.replace(/\*/g,''));

		//Création de l'expression régulère pour le pattern complet
		this.fullPatternRegexp = this.fullPattern && new RegExp(this.fullPattern);
	}

	/**
	 * Détection des modifications
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la mise à jour du pattern
		if (changes?.pattern && !changes.pattern.isFirstChange())
			//Création de l'expression régulière
			this.regexp = new RegExp(changes.pattern.currentValue.replace(/\*/g,''));

		//Vérification de la mise à jour du pattern complet
		if (changes?.fullPattern && !changes.fullPattern.isFirstChange())
			//Création de l'expression régulière
			this.fullPatternRegexp = new RegExp(changes.fullPattern.currentValue);
	}

	/**
	 * Interception d'un changement de valeur
	 */
	@HostListener('keypress',['$event'])
	onKeyPress(event: KeyboardEvent) {
		//Vérification de l'expression régulière
		if (!this.regexp.test(event.key) && !this.regexp.test(event.key.toLowerCase()) && !this.regexp.test(event.key.toUpperCase()))
			//Annulation de l'évènement
			event.preventDefault();

		//Vérification du pattern complet (si défini)
		if (this.fullPatternRegexp && !this.fullPatternRegexp.test((event.target as any || '').value + event.key.toLowerCase()) && !this.fullPatternRegexp.test((event.target as any || '').value + event.key.toUpperCase()))
			//Annulation de l'évènement
			event.preventDefault();
	}
}