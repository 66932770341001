<div class="grille-fluidite">
	<div class="modal-header">
		<div>
			<h4 class="modal-title"><span translate>grilleFluidite.title</span></h4>
			<div class="form-group d-flex flex-direction-row gap-2 align-items-center" style="min-width: 300px;">
				<span><span translate>actions.afficher</span>&#160;:&#160;</span>
				<selectpicker name="modeAffichageField" #modeAffichageField="ngModel" [(ngModel)]="modeAffichage" (ngModelChange)="onModeAffichageChange()">
					<mat-option *ngFor="let modeAffichage of listeModesAffichage" [value]="modeAffichage.code">{{ modeAffichage.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group m-l-10 d-flex flex-direction-row gap-2 align-items-center">
					<span translate>grilleFluidite.distanceMensuelleToHighlight</span>
					<div>
						<input type="text" name="distanceMensuelleToHighlightField" class="form-control" #distanceMensuelleToHighlightField="ngModel" [(ngModel)]="distanceMensuelleToHighlight" nNumber min="0" (ngModelChange)="onDistanceMensuelleToHighlightChange()"/>
					</div>
				</div>
			</div>
		</div>
		<div class="header">
			<ul class="caption">
				<li translate>grilleFluidite.duree</li>
			</ul>
			<div class="content">
				<ul class="items" [ngClass]="'position-'+navigation.position">
					<li class="title" *ngFor="let distance of listeDistances">
						<span>{{ distance | number:'.0-0' }}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="body" [ngClass]="'body-length-'+(listeDurees.length < 50 ? listeDurees.length : 'max')">
			<ul class="sections">
				<li *ngFor="let duree of listeDurees">
					<span class="only-one-title">{{ duree | number:'.0-0' }}</span>
				</li>
			</ul>
			<div class="content">
				<ul class="items" [ngClass]="'position-'+navigation.position">
					<li *ngFor="let distance of listeDistances; let currentIndex = index">
						<ul class="datas">
							<li class="item-content {{ getLevel(duree,distance) }}" *ngFor="let duree of listeDurees" [ngClass]="{ 'current-loi-roulage': duree == options.loiRoulage?.duree && distance <= options.loiRoulage?.distance || distance == options.loiRoulage?.distance && duree <= options.loiRoulage?.duree,'outdated': distance < options.distanceVehicule || duree < options.dureeContrat }">
								<span>
									<a [routerLink]="[]" (click)="showDetail(duree,distance)">
										<span>{{ displayDataForItem(duree,distance) }}</span>
									</a>
								</span>
							</li>
						</ul>
					</li>
				</ul>
				<div class="navigation left" *ngIf="navigation.position > 0" (click)="navigation.position = navigation.position - 1;">
					<icon name="chevron_left" class="icon-hc-5x"></icon>
				</div>
				<div class="navigation right" *ngIf="navigation.position < listeDistances.length - 10" (click)="navigation.position = navigation.position + 1;">
					<icon name="chevron_right" class="icon-hc-5x"></icon>
				</div>
			</div>
		</div>
		<div class="clearfix"></div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" (click)="deleteGrilleFluidite()" *ngIf="(TypeDroit.GRILLE_FLUIDITE | right:'suppression') && !options?.disableDeletion">
			<span translate>actions.supprimer</span>
		</button>
	</div>
</div>