import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { SocieteService } from './societe.service';

@Component({
	selector: 'societe',
	templateUrl: './societe.component.html'
})
export class SocieteComponent implements OnInit {
	/** Elément courant **/
	@Input() societe: any;

	/** Gestion d'une modification **/
	@Output() onObjectChange = new EventEmitter<any>();

	/**
	 * Constructeur
	 */
	constructor(private societeService: SocieteService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du pays
		this.societe = Object.assign({},this.societe);
	}

	/**
	 * Enregistrement de la société
	 */
	saveSociete() {
		//Enregistrement de la société
		this.societeService.saveSociete(this.societe).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de la société
					this.onObjectChange.emit(result.data.societe);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du SIRET
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.siret'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression de la société
	 */
	deleteSociete() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la société
					this.societeService.deleteSociete(this.societe).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la société
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}