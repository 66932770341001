import { Component,Input,ViewContainerRef,ViewChild,OnInit,ComponentRef,Type } from '@angular/core';

import { AutocompleteOptions } from './autocomplete';
import { AutocompleteLineItemComponent } from './autocomplete-line-item.component';

@Component({
	selector: 'autocomplete-line',
	templateUrl: './autocomplete-line.component.html'
})
export class AutocompleteLineComponent implements OnInit {
	/** Elèment à afficher **/
	@Input() item: any;

	/** Options de l'autocomplete **/
	@Input() options: AutocompleteOptions;

	/** Texte recherché **/
	@Input() query: Array<string>;

	/** Récepteur de composant **/
	@ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let component;

		//Création du composant
		component = this.createComponent(this.holder,this.options.lineItemComponent?.() || AutocompleteLineItemComponent);

		//Définition des données
		component.instance.item = this.item;
		component.instance.options = this.options;
		component.instance.query = this.query;

		//Ajout du composant au DOM
		this.holder.insert(component.hostView);
	}

	/**
	 * Création du composant
	 */
	createComponent(viewContainer: ViewContainerRef,type: Type<AutocompleteLineItemComponent>): ComponentRef<AutocompleteLineItemComponent> {
		//Création du composant
		return viewContainer.createComponent(type);
	}
}