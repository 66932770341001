import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RecaptchaDirective } from './recaptcha.directive';

@NgModule({
	imports: [BrowserModule],
	declarations: [RecaptchaDirective],
	exports: [RecaptchaDirective]
})
export class RecaptchaModule {}