import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { StepperComponent } from './stepper.component';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [StepperComponent],
	exports: [StepperComponent]
})
export class StepperModule {}