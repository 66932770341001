import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConnecteurTypeService {
	/** Liste des types de connecteur **/
	private readonly listeTypesConnecteur: Array<string> = ['API','INTERFACE'];

	/** Liste des types de visibilité **/
	private readonly listeTypesVisibility: Array<string> = ['CLIENT','ROOT','BOTH'];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Récupération de la liste des types de connecteur
	 */
	public getListeTypesConnecteur(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de connecteur
		return this.listeTypesConnecteur.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types de visibilité
	 */
	public getListeTypesVisibility(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de visibilités
		return this.listeTypesVisibility.map(code => ({
			code,
			libelle: this.translateService.instant(`connecteur.typeVisibility.${code}`)
		}));
	}
}