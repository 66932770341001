import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { mapEntites } from './releve-compteur.entites';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { ReleveCompteurService } from './releve-compteur.service';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './releve-compteur-list-item.component.html'
})
export class ReleveCompteurListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private releveCompteurService: ReleveCompteurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private mailHistoryService: MailHistoryService,private notificationService: NotificationService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idCompteur,object?.idCompteur && !this.data?.idCompteur);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteReleveCompteur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.releveCompteurService.deleteReleveCompteur(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression du relevé d'énergie
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.VehiculeCompteur',this.data.idCompteur);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.VehiculeCompteur',this.data.idCompteur);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.VehiculeCompteur',this.data.idCompteur);
	}

	/**
	 * Accès à la facture
	 */
	goToFacture() {
		//Récupération de l'identifiant de la facture liée au compteur
		this.releveCompteurService.findIdFactureForCompteur(this.data).pipe(filter(result => result.data?.idFacture)).subscribe({
			next: result => {
				//Navigation vers la facture
				this.layoutService.goToByState('listeFactures-load',{
					routeParams: {
						idFacture: result.data.idFacture
					},
					withGoBack: true
				});
			}
		});
	}

	/**
	 * Accès à l'état des lieux
	 */
	goToEtatLieux() {
		//Récupération de l'identifiant de l'état des lieux lié au compteur
		this.releveCompteurService.findIdEtatLieuxForCompteur(this.data).pipe(filter(result => result.data?.idEtatLieux)).subscribe({
			next: result => {
				//Navigation vers l'état des lieux
				this.layoutService.goToByState('listeEtatsLieux-etatLieux',{
					routeParams: {
						idEtatLieux: result.data.idEtatLieux
					},
					withGoBack: true
				});
			}
		});
	}
}