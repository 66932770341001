import { Component,ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { TodoListService } from 'src/app/share/layout/todo-list/todo-list.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent {
	/** Session **/
	session: Session = null;

	/** Indicateur d'affichage de la GlobalSearch (en mode réduit) **/
	isSearchOpened: boolean = false;

	/** Vue sur le composant enfant de gestion des progressions **/
	@ViewChild('progress')
	progress: any;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,public menuService: MenuService,private layoutService: LayoutService,public progressService: ProgressService,public todoListService: TodoListService) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
	}

	/**
	 * Evènement de fermeture de la GlobalSearch
	 */
	onGlobalSearchClosed() {
		//Fermeture de la GlobalSearch
		this.isSearchOpened = false;
	}

	/**
	 * Navigation vers le tableau de bord
	 */
	goToHome() {
		//Vérification du type d'utiliser
		if (this.session?.user?.type != 'VIRTUAL')
			//Redirection vers le tableau de bord
			this.layoutService.goToByUrl('/Dashboard');
	}
}