<form #structureExportCompteurForm="ngForm" autocomplete="off">
	<div class="modal-header">
		<h4 class="modal-title" translate>structureExport.listeCompteurs.compteur.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation') || isRestricted">
			<div class="card">
				<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
					<label translate>structureExport.listeCompteurs.compteur.libelle</label>
					<div>
						<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="compteur.libelle" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': valeurInitiale.invalid }">
					<label translate>structureExport.listeCompteurs.compteur.valeurInitiale</label>
					<div class="half">
						<input type="text" name="valeurInitiale" #valeurInitiale="ngModel" class="form-control" [(ngModel)]="compteur.valeurInitiale" nNumber="2" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': increment.invalid }">
					<label translate>structureExport.listeCompteurs.compteur.increment</label>
					<div class="half">
						<input type="text" name="increment" #increment="ngModel" class="form-control" [(ngModel)]="compteur.increment" nNumber="2" required/>
					</div>
				</div>
				<div class="form-group" *ngIf="detail.typeDetail == 'GROUPE'">
					<label translate>structureExport.listeCompteurs.compteur.type</label>
					<div class="half">
						<selectpicker name="type" [(ngModel)]="compteur.type" required>
							<mat-option *ngFor="let type of listeTypesCompteur" [value]="type.code">{{ type.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': positionRubrique.invalid }" *ngIf="detail.typeDetail == 'GROUPE' && compteur.type == 'CONDITIONNEL'">
					<label>
						<span translate>structureExport.listeCompteurs.compteur.positionRubrique.item</span>
						<icon name="info_outline" class="m-l-5" [tooltip]="'structureExport.listeCompteurs.compteur.positionRubrique.info' | translate"></icon>
					</label>
					<div class="half">
						<input type="text" name="positionRubrique" #positionRubrique="ngModel" class="form-control" [(ngModel)]="compteur.positionRubrique" nNumber="2" min="0" required/>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveCompteur()" [disabled]="structureExportCompteurForm.invalid" *ngIf="(null | right:'creation') && !isRestricted">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>