import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataCustomizationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Récupération du nombre de libellés personnalisés
	 */
	getNbLabelsForType(dataCustomization: any,type: 'alpha' | 'numeric' | 'date' | 'customRef') {
		//Retour du nombre de libellés personnalisés
		return ['1','2','3','4'].filter(num => dataCustomization[type + num]).length;
	}

	/**
	 * Enregistrement d'une valeur de référentiel personnalisé
	 */
	public saveCustomRefValue(idDataCustomizationEntite: number,customRefValue: any): Observable<Result> {
		//Enregistrement de la valeur de référentiel personnalisé
		return this.http.put<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/saveCustomRefValue/${idDataCustomizationEntite}`,customRefValue);
	}

	/**
	 * Chargement d'un résumé d'un référentiel personnalisé
	 */
	public loadResume(idDataCustomizationEntite: number): Observable<Result> {
		//Chargement du résumé d'une entité personnalisée
		return this.http.post<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/loadResume/${idDataCustomizationEntite}`,null);
	}

	/**
	 * Chargement d'un référentiel personnalisé
	 */
	public loadDataCustomization(idDataCustomizationEntite: number): Observable<Result> {
		//Chargement d'un référentiel personnalisé
		return this.http.post<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/loadDataCustomizationEntite/${idDataCustomizationEntite}`,null);
	}

	/**
	 * Enregistrement d'une entité personnalisée
	 */
	public saveDataCustomization(dataCustomization: any): Observable<Result> {
		//Enregistrement d'une entité personnalisée
		return this.http.put<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/saveDataCustomizationEntite`,dataCustomization);
	}

	/**
	 * Suppression de la valeur du référentiel personnalisable
	 */
	public deleteCustomRefValue(customRefValue: any): Observable<Result> {
		//Suppression de la valeur du référentiel personnalisable
		return this.http.delete<Result>(`${environment.baseUrl}/controller/DataCustomizationEntite/deleteCustomRefValue/${customRefValue.idCustomRefValue}`);
	}

	/**
	 * Filtre de la liste des personnalisations d'entités
	 */
	public filtreDataCustomizationsEntite(searchSpec: any) : Observable<Page<any>> {
		//Chargement du résumé d'une entité personnalisée
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/DataCustomizationEntite/filtreDataCustomizationsEntite`,searchSpec);
	}
}