import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { DataCustomizationService } from './data-customization.service';

@Component({
	selector: 'data-customization-list-item',
	templateUrl: './data-customization-list-item.component.html'
})
export class DataCustomizationListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private entiteService: EntiteService,private dataCustomizationService: DataCustomizationService) {
		//Héritage
		super();
	}

	/**
	 * Récupération de la traduction d'une entité
	 */
	translateEntityCode(entite) {
		//Traduction de l'entité
		return this.entiteService.translateEntityCode(entite);
	}

	/**
	 * Récupération du nombre de libellés personnalisés
	 */
	getNbLabelsForType(type: 'alpha' | 'numeric' | 'date' | 'customRef') {
		//Retour du nombre de libellés personnalisés
		return this.dataCustomizationService.getNbLabelsForType(this.data,type);
	}
}
