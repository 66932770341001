import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map,shareReplay } from 'rxjs/operators';

import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';
import { Result } from 'src/app/domain/common/http/result';
import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { AccountArticleListComponent } from './account-article-list.component';
import { AccountArticleComponent } from './account-article.component';
import { AccountContactAdminComponent } from './account-contact-admin.component';
import { AccountNotificationListComponent } from './account-notification-list.component';
import { AccountPasswordComponent } from './account-password.component';
import { AccountPersonalDataComponent } from './account-personal-data.component';
import { AccountService } from './account.service';

@Component({
	selector: 'account',
	templateUrl: './account.component.html'
})
export class AccountComponent implements AfterContentChecked,OnInit {
	/** Onglet à afficher en premier **/
	@Input() type?: 'PERSONAL_DATA' | 'PASSWORD' | 'CONTACT_ADMIN';

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Données personnelles **/
	listePersonalData$: Observable<Array<any>>;

	/** Article 'On-Boarding' **/
	articleOnBoarding$: Observable<any>;

	/** Article 'CGU' **/
	articleCgu$: Observable<any>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<AccountComponent>,public accountService: AccountService,private loggedUser: LoggedUserService,private sharedArticleService: SharedArticleService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement des données personnelles de l'utilisateur
		this.listePersonalData$ = this.accountService.loadPersonalData(this.loggedUser.getUser()).pipe(
			shareReplay(),
			map((result: Result) => result.data?.listePersonalData?.filter(p => !!p.name))
		);

		//Chargement de l'article CGU
		this.articleCgu$ = this.sharedArticleService.loadTypeArticle('CGU').pipe(
			shareReplay(),
			map((result: Result) => result?.data?.article)
		);

		//Chargement de l'article 'On-Boarding'
		this.articleOnBoarding$ = this.sharedArticleService.loadTypeArticle('ONBOARDING').pipe(
			shareReplay(),
			map((result: Result) => result?.data?.article)
		);

		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'PERSONAL_DATA',
			component: AccountPersonalDataComponent,
			libelle: 'account.personalData.title',
			retrieveComponentData: () => ({
				listePersonalData$: this.listePersonalData$
			}),
			canActivate: () => true,
			isDone: () => true
		},{
			type: 'NOTIFICATION',
			component: AccountNotificationListComponent,
			libelle: 'account.notification.title',
			retrieveComponentData: () => ({}),
			canActivate: () => true,
			isDone: () => true
		},{
			type: 'PASSWORD',
			component: AccountPasswordComponent,
			libelle: 'account.password.title',
			retrieveComponentData: () => ({}),
			canActivate: () => true,
			isDone: () => true
		},{
			type: 'ARTICLE',
			component: AccountArticleListComponent,
			libelle: 'account.article.title',
			retrieveComponentData: () => ({}),
			canActivate: () => true,
			isDone: () => true
		},this.loggedUser.getUser()?.type != 'ADMINISTRATEUR' && {
			type: 'CONTACT_ADMIN',
			component: AccountContactAdminComponent,
			libelle: 'account.contactAdmin.title',
			retrieveComponentData: () => ({}),
			canActivate: () => true,
			isDone: () => true
		},{
			type: 'PREMIER_PAS',
			component: AccountArticleComponent,
			libelle: 'account.premierPas.title',
			retrieveComponentData: () => ({
				article$: this.articleOnBoarding$
			}),
			canActivate: () => true,
			isDone: () => true
		},{
			type: 'CGU',
			component: AccountArticleComponent,
			libelle: 'account.cgu.title',
			retrieveComponentData: () => ({
				article$: this.articleCgu$
			}),
			canActivate: () => true,
			isDone: () => true
		}].filter(s => !!s);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}