<div class="card">
	<div class="alert alert-info m-b-0 m-t-15 m-r-15" *ngIf="nbAnomalies == 0">
		<icon name="check"></icon>
		<div>
			<strong translate>comptabilite.lotComptable.check.planComptable.verification.title</strong>
			<div translate>comptabilite.lotComptable.check.planComptable.verification.aucuneAnomalie</div>
		</div>
	</div>
	<div class="alert alert-danger m-b-0 m-t-15 m-r-15" *ngIf="nbAnomalies != 0">
		<icon name="warning"></icon>
		<strong translate>comptabilite.lotComptable.check.planComptable.verification.titleAnomalie</strong>
		<button mat-raised-button color="primary" [tooltip]="'comptabilite.lotComptable.check.planComptable.verification.presenceAnomalies' | pluralTranslate:nbAnomalies | translate:{ nbAnomalies }" (click)="generatePlanComptable()">
			<span translate>actions.continuer</span>
		</button>
	</div>
	<ng-container *ngIf="nbAnomalies != 0">
		<list-view [liste]="liste" *ngIf="liste"></list-view>
	</ng-container>
</div>