import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,flatMap,isEqual,keyBy,remove,some } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxService } from './etat-lieux.service';

@Component({
	templateUrl: './etat-lieux-part-list.component.html'
})
export class EtatLieuxPartListComponent implements OnInit {
	/** Etat des lieux courant **/
	@Input() etatLieux: any;

	/** Indicateur de lecture seule **/
	@Input() isReadOnly: boolean;

	/** Résultat **/
	result: { etatLieux: any };

	/** Liste des zones d'un véhicule **/
	listeParts: Array<{ index: number,type: string,listeZones: Array<{ code: string,listeLinks?: Array<any>,mapPartsByIdBlob?: any }> }>;

	/** Zone du véhicule sélectionnée **/
	selectedZone: { code: string,listeLinks?: Array<any>,mapPartsByIdBlob?: any } = null;

	/** Navigation dans la liste des pièces jointes de la zone **/
	navigationAttachment = {
		currentIndex: 0
	};

	/** Copie de l'état des lieux fourni **/
	private savedEtatLieux = null;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des remarques */
	private listeSavedRemarques: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public rightService: RightService,public bsModalRef: BsModalRef,public etatLieuxService: EtatLieuxService
			,private confirmService: ConfirmService,private toastrService: ToastrService,private datePipe: DatePipe,private decimalPipe: DecimalPipe) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let etatLieuxCopy: any;

		//Liste des zones d'un véhicule
		this.listeParts = this.etatLieuxService.getListeParts();

		//Copie de l'état des lieux fourni
		etatLieuxCopy = cloneDeep(this.etatLieux);

		//Initialisation de la liste des pièces jointes de l'état des lieux
		etatLieuxCopy.listeLinks = etatLieuxCopy.listeLinks || [];

		//Initialisation de la liste des parties de l'état des lieux
		etatLieuxCopy.listeParts = etatLieuxCopy.listeParts || [];

		//Parcours des parties de l'état des lieux
		etatLieuxCopy.listeParts.forEach(p => {
			//Mémorisation de l'identifiant de la pièce jointe associée à la partie
			p.idBlob = p.attachment.idBlob;
		});

		//Exposition de la copie
		this.savedEtatLieux = etatLieuxCopy;

		//Synchronisation des parties de l'état des lieux avec la liste des pièces jointes
		this.syncListePartsWithListeLinks();

		//Répartition des pièces jointes dans leur zone respective
		this.populateZones();

		//Définition des options
		this.attachmentOptions = {
			owningEntity: this.savedEtatLieux,
			idObjet: this.etatLieux.idEtatLieux,
			typeAttachment: TypeAttachment.ETAT_LIEUX,
			typeLink: this.selectedZone.code,
			listeTypesDroit: [TypeDroit.ETAT_LIEUX],
			inline: true,
			imageSwiper: true,
			withoutAttachmentSaving: false,
			filters: '.jpg|.jpeg|.png',
			navigation: this.navigationAttachment,
			readOnly: this.isReadOnly,
			autoUpload: true,
			onCompleteItem: () => {
				//Synchronisation des parties de l'état des lieux avec la liste des pièces jointes
				this.syncListePartsWithListeLinks();

				//Répartition des pièces jointes dans leur zone respective
				this.populateZones();
			},
			onDeleteItem: (deletedLink) => {
				//Suppression de la partie correspondante de l'état des lieux fourni
				deletedLink && remove(this.savedEtatLieux.listeParts,{ attachment: { idBlob: deletedLink.attachment.idBlob }});

				//Répartition des pièces jointes dans leur zone respective
				this.populateZones();

				//Remise à zéro de la navigation
				this.navigationAttachment.currentIndex = 0;
			},
			isFromLink: true
		};

		//Sauvegarde de la liste des remarques
		this.listeSavedRemarques = this.savedEtatLieux.listeParts.map(p => ({
			idBlob: p.idBlob,
			remarque: p.remarque
		}));
	}

	/**
	 * Sélection d'une zone du véhicule
	 */
	selectZone(zone: any) {
		//Mise à jour de la zone actuellement sélectionnée
		this.selectedZone = zone;

		//Sélection de l'objet portant les liens
		this.attachmentOptions.typeLink = zone.code;

		//Remise à zéro de la navigation
		this.navigationAttachment.currentIndex = 0;
	}

	/**
	 * Récupération du suivi de l'affichage d'un élément par sa position
	 */
	trackByPosition(index: number) {
		//Retour de la position
		return index;
	}

	/**
	 * Génération des parties de l'état des lieux à partir de la liste des pièces jointes
	 */
	private syncListePartsWithListeLinks() {
		let mapPartsByIdBlob: any;
		let mapLinksByIdBlob: any;

		//Construction d'une map associant les parties de l'état des lieux à l'identifiant de leur pièce jointe
		mapPartsByIdBlob = keyBy(this.savedEtatLieux.listeParts,'idBlob');

		//Construction d'une map associant les pièces jointes à leur identifiant
		mapLinksByIdBlob = keyBy(this.savedEtatLieux.listeLinks,'attachment.idBlob');

		//Parcours des pièces jointes de l'état des lieux
		this.savedEtatLieux.listeLinks.forEach((link) => {
			let part: any;

			//Récupération de la partie de l'état des lieux correspondant à la pièce jointe courante
			part = mapPartsByIdBlob[link.attachment.idBlob];

			//Vérification de la nécessité de créer une partie supplémentaire
			if (!part) {
				//Ajout d'une partie à l'état des lieux
				this.savedEtatLieux.listeParts.push({
					idBlob: link.attachment.idBlob
				});
			}
		});

		//Suppression des parties de l'état des lieux associées à une pièce jointe supprimée
		remove(this.savedEtatLieux.listeParts,(p: any) => !mapLinksByIdBlob[p.idBlob]);
	}

	/**
	 * Répartition des pièces jointes dans leur zone respective
	 */
	private populateZones(isUpdateZoneNedeed?: boolean) {
		let mapPartsByIdBlob: any;

		//Construction d'une map reliant les parties de l'état des lieux à l'identifiant de leur pièce jointe
		mapPartsByIdBlob = keyBy(this.savedEtatLieux.listeParts,'idBlob');

		//Parcours des types de zones d'un véhicule
		this.listeParts.forEach((part,idxPart) => {
			//Parcours des zones
			part.listeZones.forEach((zone,idxZone) => {
				//Définition des pièces jointes associées à la zone courante
				zone.listeLinks = (this.savedEtatLieux.listeLinks || []).filter(link => link.type == zone.code);

				//Définition des parties de l'état des lieux associées à la zone courante
				zone.mapPartsByIdBlob = keyBy(zone.listeLinks.map(l => mapPartsByIdBlob[l.attachment.idBlob]).filter(p => !!p),'idBlob');

				//Vérification de la nécessité de définir la zone sélectionnée par défaut
				if (!this.selectedZone && idxPart === 0 && idxZone === 0)
					//Déinition de la zone sélectionnée par défaut
					this.selectedZone = zone;

				//Vérification de la nécessité de la mise à jour
				if (isUpdateZoneNedeed && zone.code == this.selectedZone.code)
					//Sélection d'une zone
					this.selectZone(zone);
			});
		});
	}

	/**
	 * Enregistrement de l'état des lieux et fermeture de la pop-up
	 */
	public saveEtatLieux() {
		let observable: Observable<boolean>;

		//Vérification que l'une des zones ne contient pas de pièces jointes
		if (some(flatMap(this.listeParts,'listeZones'),zone => !zone.listeLinks?.length))
			//Affichage d'un message de confirmation
			observable = this.confirmService.showConfirm(this.translateService.instant('vehicule.etatLieux.confirmation.photoManquante'),{ actionColor: 'primary' });
		else
			//Création d'un observable toujours vrai
			observable = of(true);

		//Attente de la confirmation (si nécessaire)
		observable.subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Parcours des liens pour en supprimer l'objet
					this.savedEtatLieux.listeLinks && this.savedEtatLieux.listeLinks.forEach(link => {
						//Suppression de l'objet
						delete link.objet;
					});

					//Indicateur de non mise à jour du statut
					this.savedEtatLieux.blockStatutUpdate = true;

					//Appel AJAX
					this.etatLieuxService.saveEtatLieux(this.savedEtatLieux).subscribe({
						next: (result: Result) => {
							let listeDoublons = new Array<string>();

							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

								//Définition du résultat
								this.result = {
									etatLieux: result.data?.etatLieux
								};

								//Fermeture de la modal
								this.bsModalRef.hide();
							} else {
								//Vérification du code d'erreur
								if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
									//Vérification de la référence
									if (result.data.doublon & Math.pow(2,0))
										//Ajout de la référence
										listeDoublons.push(this.translateService.instant('actions.doublon.datePlanification'));

									//Message d'erreur
									this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
										field: listeDoublons.join(', ')
									}));
								} else if (result.data?.dateAndCompteurConflitByReleve) {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('vehicule.releveCompteur.erreur.coherence',{
										dateReleve: this.datePipe.transform(result.data.dateAndCompteurConflitByReleve.date,'shortDate'),
										releveCompteur: this.decimalPipe.transform(result.data.dateAndCompteurConflitByReleve.compteur,'1.0-0')
									}));
								} else {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
								}
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de la popup
	 */
	public close() {
		let listeRemarques: Array<any>;

		//Récupération de la liste des remarques
		listeRemarques = this.savedEtatLieux.listeParts.map(part => ({
			idBlob: part.idBlob,
			remarque: part.remarque
		}));

		//Vérification de la liste des remarques
		if (!isEqual(listeRemarques,this.listeSavedRemarques)) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('vehicule.etatLieux.confirmation.remarqueNonEnregistree'),{ actionColor: 'primary' }).subscribe({
				next: isConfirmed => {
					//Vérification de la confirmation
					if (isConfirmed)
						//Fermeture de la popup
						this.bsModalRef.hide();
				}
			});
		} else
			//Fermeture de la popup
			this.bsModalRef.hide();
	}
}