<form #typeEntretienForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!typeEntretien.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>vehicule.typeEntretien.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="typeEntretien.libelle" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>vehicule.typeEntretien.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="typeEntretien.reference" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': frequence.invalid }">
							<label>
								<span translate>vehicule.typeEntretien.frequence.item</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.typeEntretien.frequence.description' | translate"></icon>
							</label>
							<div class="half">
								<div class="input-group">
									<input type="text" name="frequence" #frequence="ngModel" class="form-control" [(ngModel)]="typeEntretien.frequence" nNumber min="0"/>
									<div class="input-group-addon no-border" translate>vehicule.typeEntretien.mois</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>vehicule.typeEntretien.actif.libelle</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="typeEntretien.actif"><span translate>vehicule.typeEntretien.actif.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: typeEntretienForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteTypeEntretien();" *ngIf="typeEntretien?.idTypeEntretien && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveTypeEntretien();" *ngIf="null | right:'creation'" [disabled]="typeEntretienForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>