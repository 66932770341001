import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { DataCustomizationListItemComponent } from './data-customization-list-item.component';
import { DataCustomizationListComponent } from './data-customization-list.component';
import { DataCustomizationRefValueAddComponent } from './data-customization-ref-value-add.component';
import { DataCustomizationComponent } from './data-customization.component';
import { DataCustomizationEditComponent } from './data-customization-edit.component';
import { DataCustomizationService } from './data-customization.service';
import { DataCustomizationRefValueListComponent } from './data-customization-ref-value-list.component';
import { DataCustomizationRefValueListItemComponent } from './data-customization-ref-value-list-item.component';
import { DataCustomizationRefValueEditComponent } from './data-customization-ref-value-edit.component';

@NgModule({
	imports: [ShareModule],
	declarations: [DataCustomizationListComponent,DataCustomizationListItemComponent,DataCustomizationRefValueAddComponent,DataCustomizationComponent,DataCustomizationEditComponent,DataCustomizationRefValueListComponent,DataCustomizationRefValueListItemComponent,DataCustomizationRefValueEditComponent],
	providers: [DataCustomizationService]
})
export class DataCustomizationModule { }