import { NgModule } from '@angular/core';

import { FormDetectChangesModule } from 'src/app/share/directive/form-detect-changes/form-detect-changes.module';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { EntiteFieldComponent } from './entite-field.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,RightModule,FormDetectChangesModule,IconModule],
	declarations: [EntiteFieldComponent],
	exports: [EntiteFieldComponent]
})
export class EntiteFieldModule { }
