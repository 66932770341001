import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PaysService } from './pays.service';
import { PaysScopeComponent } from './scope/pays-scope.component';

@Component({
	selector: 'pays',
	templateUrl: './pays.component.html'
})
export class PaysComponent implements OnInit {
	/** Elément courant **/
	@Input() pays: any;

	/** Gsetion d'une modification de pays **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Elément sauvegardé **/
	public savedPays: any = null;

	/**
	 * Constructeur
	 */
	constructor(private paysService: PaysService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du pays
		this.pays = Object.assign({},this.pays);
		this.savedPays = Object.assign({},this.pays);
	}

	/**
	 * Enregistrement du pays
	 */
	savePays() {
		//Enregistrement du pays
		this.paysService.savePays(this.pays).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du pays
					this.onObjectChange.emit(result.data.pays);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Vérification du code ISO 2
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du code ISO 2
						listeDoublons.push(this.translateService.instant('actions.doublon.codeISO2'));

					//Vérification du code ISO 3
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du code ISO 3
						listeDoublons.push(this.translateService.instant('actions.doublon.codeISO3'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du pays
	 */
	deletePays() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du pays
					this.paysService.deletePays(this.pays).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du pays
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}

	/**
	 * Affichage de la liste des scopes sélectionnés
	 */
	getListeScopes() {
		let label: string;

		//Vérification de la présence d'une liste de scopes
		if (this.pays.listeScopes?.length)
			//Définition du libellé
			label = this.pays.listeScopes.map(scope => this.translateService.instant(`pays.scope.${scope.scope}`)).join(', ');
		else
			//Définition du libellé
			label = this.translateService.instant('common.nonDefini');

		//Retour du libellé
		return label;
	}

	/**
	 * Affichage des scopes
	 */
	showListeScopes() {
		let bsModalRef: BsModalRef<PaysScopeComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(PaysScopeComponent,{
			initialState: {
				listeSelectedScopes: Object.assign([],this.pays.listeScopes)
			}
		});

		//Interception du masquage de la popup
		bsModalRef.onHidden.subscribe({
			next: () => {
				//Itération sur les nouveaux scopes
				bsModalRef.content.listeSelectedScopes.forEach(scope => {
					//Recherche de l'existance d'un scope
					scope.idScope = this.savedPays?.listeScopes?.find(savedScope => savedScope.scope == scope.scope)?.idScope;
				});

				//Mise à jour de la liste des scopes
				this.pays.listeScopes = bsModalRef.content.listeSelectedScopes;
			}
		});
	}
}