import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReleveEnergieListComponent } from './releve-energie-list.component';
import { ReleveEnergieListItemComponent } from './releve-energie-list-item.component';
import { ReleveEnergieService } from './releve-energie.service';
import { ReleveEnergieComponent } from './releve-energie.component';
import { ReleveEnergieContentComponent } from './releve-energie-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ReleveEnergieListComponent,ReleveEnergieListItemComponent,ReleveEnergieComponent,ReleveEnergieContentComponent],
	exports: [ReleveEnergieContentComponent],
	providers: [ReleveEnergieService]
})
export class ReleveEnergieModule {

}