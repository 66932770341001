import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

@Component({
	selector: 'plan-comptable-compte-edit',
	templateUrl: './plan-comptable-compte-edit.component.html'
})
export class PlanComptableCompteEditComponent extends PageContentItem {
	/** Compte **/
	@Input() compte: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService,private messagingService: MessagingService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du compte
	 */
	saveCompte() {
		let messaging$: MessagingObservables;

		//Enregistrement du compte par websocket
		messaging$ = this.messagingService.init({
			entryPoint: 'controller/Compte/saveCompte',
			outputPoint: '/messaging/compte/saveCompte/status',
			params: this.compte,
			method: 'PUT'
		}).onResult({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Retour du compte
					this.close({ compte: result.data.compte });
				} else if (result?.codeErreur === TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: this.translateService.instant('actions.doublon.numero')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}

				//Fermeture des souscriptions
				messaging$.unsubscribe();
			}
		});
	}
}
