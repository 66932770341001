<div class="pull-left lv-avatar">{{ data?.fournisseur?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addLienFacturation?.(data,deleteLienFacturation)">{{ (data?.fournisseur | display:'fournisseur') + ' - ' + data.code }}</a>
	</div>
	<div class="lv-actions actions dropdown" *ngIf="data?.idLien && (TypeDroit.FACTURE | right:'creation')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.FACTURE | right:'creation'"><a [routerLink]="[]" (click)="deleteLienFacturation();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>