import { HttpEvent,HttpHandler,HttpInterceptor,HttpRequest } from '@angular/common/http';
import { Injectable,NgZone } from '@angular/core';
import { NavigationStart,Router } from '@angular/router';
import { filter,finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { LoaderService } from 'src/app/share/layout/loader/loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	/**
	 * Constructeur
	 */
	constructor(private loaderService: LoaderService,private router: Router,private ngZone: NgZone) {
		//Interception des évènements du router
		this.router.events.pipe(
			filter(e => e instanceof NavigationStart)
		).subscribe(() => {
			//Remise à zéro du nombre de requête
			this.loaderService.reset();
		});
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Incrémentation du compteur
		this.loaderService.increment();

		//Laisser passer la requête
		return next.handle(request).pipe(
			finalize(() => {
				//Décrémentation du compteur
				setTimeout(() => this.ngZone.run(() => this.loaderService.decrement()),250);
			})
		);
	}
}