import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ProfilListItemComponent } from './profil-list-item.component';
import { ProfilService } from './profil.service';

@Component({
	templateUrl: './profil-list.component.html'
})
export class ProfilListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ProfilListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private profilService: ProfilService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ProfilListItemComponent>({
			uri: `/controller/Profil/filtreProfils`,
			title: this.translateService.instant('profil.liste.title'),
			component: ProfilListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'extension.nbUsers',
				title: this.translateService.instant('profil.nbUsers'),
				type: TypeFilter.DECIMAL

			},{
				clef: 'typeRestriction',
				title: this.translateService.instant('profil.typeRestriction.item'),
				type: TypeFilter.LONG,
				listeValues: this.profilService.getListeTypesRestriction()
			},{
				clef: 'typeRestrictionDynamique',
				type: TypeFilter.LONG,
				title: this.translateService.instant('profil.typeRestrictionDynamique.item'),
				listeValues: this.profilService.getListeTypesRestrictionDynamique()
			}],
			getKeyFieldName: () => 'idProfil',
			defaultOrder: 'libelle,reference',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Profil/loadProfil/0'))
		});
	}
}