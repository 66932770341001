import { Component,Input } from '@angular/core';
import { Observable } from 'rxjs';

import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { AccountService } from './account.service';

@Component({
	selector: 'account-personal-data',
	templateUrl: './account-personal-data.component.html'
})
export class AccountPersonalDataComponent {
	/** Données personnelles **/
	@Input() listePersonalData$: Observable<Array<any>>;

	/**
	 * Constructeur
	 */
	constructor(private accountService: AccountService,private loggedUserService: LoggedUserService) { }

	/**
	 * Téléchargement des données de l'utilisateur connecté
	 */
	public extractPersonalData() {
		//Téléchargement des données de l'utilisateur connecté
		this.accountService.extractPersonalData(this.loggedUserService.getUser()).subscribe();
	}

	/**
	 * Vérification de la présence d'une valeur
	 */
	public hasValue(item: any): boolean {
		//Vérification de la présence d'une valeur
		return item?.value != null;
	}
}
