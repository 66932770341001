<form #fournisseurAliasForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>fournisseur.alias.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(TypeDroit.FACTURE_LAD | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': code.invalid }">
							<label translate>fournisseur.alias.code</label>
							<div>
								<input type="text" name="code" #code="ngModel" class="form-control" [(ngModel)]="alias.code" maxlength="100" required/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteAlias(alias,close);" *ngIf="alias?.idAlias && (TypeDroit.FACTURE_LAD | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveAlias();" *ngIf="TypeDroit.FACTURE_LAD | right:'creation'" [disabled]="fournisseurAliasForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: fournisseurAliasForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>