import { Component,Input,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { AttachmentComponent } from 'src/app/share/components/attachment/attachment.component';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { environment } from 'src/environments/environment';

@Component({
	templateUrl: './grille-fluidite-import.component.html'
})
export class GrilleFluiditeImportComponent implements OnInit {
	/** Type d'objet **/
	@Input() typeObjet: 'CONTRAT_FINANCEMENT' | 'GRILLE_ATTRIBUTION_MODELE_FINANCEMENT' | 'DEMANDE_VEHICULE';

	/** Identifiant du fournisseur **/
	@Input() idFournisseur: number;

	/** Identifiant de l'objet **/
	@Input() idObjet: number;

	/** Finalisation du traitement **/
	@Input() onFinish: Function;

	/** Composant de gestion des attachments **/
	@ViewChild('attachment') attachmentComponent: AttachmentComponent;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/** Grille de fluidité virtuelle **/
	grilleFluidite: any = {};

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<GrilleFluiditeImportComponent>,private messagingService: MessagingService,private translateService: TranslateService,private progressService: ProgressService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des options
		this.attachmentOptions = {
			getUrl: () => `${environment.baseUrl}/controller/Blob/saveBlob/GRILLE_FLUIDITE?isAutoDeletion=true`,
			owningEntity: this.grilleFluidite,
			typeAttachment: TypeAttachment.GRILLE_FLUIDITE,
			inline: true,
			withoutAttachmentSaving: true,
			filters: '.csv|.xls|.xlsx',
			queueLimit: 1,
			onCompleteItem: (file: File,result: Result,linkAttachment: any) => {
				//Vérification de la liste des liens
				this.grilleFluidite.listeLinks = this.grilleFluidite.listeLinks || [];

				//Ajout du lien
				this.grilleFluidite.listeLinks.push(linkAttachment);
			},
			isFromLink: true
		};
	}

	/**
	 * Réalisation de l'import
	 */
	doImport() {
		let messagingOptions: MessagingOptions;
		let messaging$: MessagingObservables;

		//Fermeture de la popup
		this.bsModalRef.hide();

		//Import de la grille de fluidité par websocket
		messaging$ = this.messagingService.init(messagingOptions = {
			method: 'PUT',
			entryPoint: 'controller/GrilleFluidite/importGrilleFluidite/'+this.typeObjet+'/'+this.idFournisseur+'/'+this.idObjet,
			params: this.grilleFluidite.listeLinks.map(l => l.attachment),
			progressConfig: {
				libelle: this.translateService.instant('grilleFluidite.import.title'),
				icone: 'grid_on'
			}
		}).onMessage({
			next: ({ message }) => {
				//Mise à jour de la progression
				this.progressService.updateProgress(messagingOptions.progressConfig.reference,{ message });
			}
		}).onFinish({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();

				//Finalisation du traitement
				this.onFinish();
			}
		}).onError({
			next: () => {
				//Fermeture des souscriptions
				messaging$.unsubscribe();
			}
		});
	}
}