import { EquipementAddComponent } from 'src/app/components/materiel/equipement/equipement-add.component';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des équipements
 */
export default class EquipementOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.equipement';

	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return filter?.isShowAll ? '/controller/Equipement/filtreEquipements' : '/controller/Equipement/listeEquipementsActifs';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? item.reference : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,2)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.equipement.Equipement';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idEquipement'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			mapEncryptedFields: {
				reference: 'referenceChiffree'
			},
			isDefault: true
		},{
			clef: 'numero',
			isDefault: true
		},{
			clef: 'fournisseur.libelle',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idFournisseur && {
			clef: 'fournisseur.idFournisseur',
			valeur: filter.idFournisseur,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		},filter?.type && {
			clef: 'type',
			valeur: filter.type,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Liste des champs supplémentaires pour la recherche avancée
		return [{
			title: 'equipement.numeroCarte',
			getValue: (item: any) => item?.numero
		}];
	}

	/**
	 * Droit de création
	 */
	creationRight?: TypeDroit = TypeDroit.EQUIPEMENT;

	/**
	 * Composant de création à la volée
	 */
	addComponent(): any { return EquipementAddComponent; }
}