import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { VehiculeEntretienInterventionListItemComponent } from './vehicule-entretien-intervention-list-item.component';

@Component({
	templateUrl: './vehicule-entretien-intervention-list.component.html'
})
export class VehiculeEntretienInterventionListComponent implements OnInit {
	/** Véhicule **/
	vehicule: any;

	/** Intervention **/
	planEntretienDetail: any;

	/** Données **/
	liste: ListView<any,VehiculeEntretienInterventionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<VehiculeEntretienInterventionListItemComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeEntretienInterventionListItemComponent>({
			uri: `${environment.baseUrl}/controller/Entretien/filtreLinksVehiculePlanEntretienDetail/${this.vehicule?.idVehicule}`,
			component: VehiculeEntretienInterventionListItemComponent,
			isLocal: true,
			defaultOrder: '-entretien.dateRealisation',
			listeFilters: [{
				clef: 'entretien.*vehiculeCompteur.*userSource.nom,entretien.*vehiculeCompteur.*userSource.prenom,entretien.*vehiculeCompteur.*userSource.matricule,entretien.*fournisseur.reference,entretien.*fournisseur.libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'planEntretienDetail.idPlanEntretienDetail',
				valeur: this.planEntretienDetail?.idPlanEntretienDetail,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			},{
				clef: 'statut',
				valeur: 'REALISE',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.STRING
			}],
			extraOptions: {
				planEntretienDetail: this.planEntretienDetail
			}
		});
	}
}