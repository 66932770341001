
<form #structureExportGroupeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div class="card detail">
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || isRestricted">
				<h4><span [translate]="'structureExport.'+(detail.typeDetail == 'GROUPE' ? 'groupe' : 'comptabiliteGenerale')+'.title'"></span></h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label [translate]="'structureExport.'+(detail.typeDetail == 'GROUPE' ? 'groupe' : 'comptabiliteGenerale')+'.libelle'"></label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="detail.libelle" required/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<structure-export-regroupement-list [detail]="detail" [isRestricted]="isRestricted"></structure-export-regroupement-list>

	<structure-export-compteur-list [detail]="detail" [isRestricted]="isRestricted"></structure-export-compteur-list>

	<span class="pull-right" *ngIf="detail">
		<button mat-raised-button color="primary" (click)="saveDetail();" *ngIf="(null | right:'creation') && !isRestricted" [disabled]="structureExportGroupeForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: structureExportGroupeForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>