import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap,first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { mapEntites } from './affectation.entites';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { AffectationService } from './affectation.service';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { AppState } from 'src/app/domain/appstate';
import { User } from 'src/app/domain/user/user';
import { TypeDroit } from 'src/app/domain/security/right';
import { ReservationService } from 'src/app/components/vehicule/pool/reservation/reservation.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeComparaison,TypeFilter,Filter } from 'src/app/domain/common/list-view';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	templateUrl: './affectation-list-item.component.html'
})
export class AffectationListItemComponent extends ListViewItem<any> implements IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private affectationService: AffectationService,private reservationService: ReservationService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private mailHistoryService: MailHistoryService,private notificationService: NotificationService,private layoutService: LayoutService,private datePipe: DatePipe,private vehiculeService: VehiculeService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idAffectation,object?.idAffectation && !this.data?.idAffectation);
	}

	/**
	 * Rechargement de la liste
	 */
	refreshListe() {
		//Rechargement de la liste
		this.liste.refresh();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteAffectation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.affectationService.deleteAffectation(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'affectation
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Réalisation d'une action Workflow pour une réservation
	 */
	doActionForReservation(typeAction: 'VALIDER' | 'REJETER' | 'ANNULER') {
		//Réalisation de l'action Workflow
		this.reservationService.doAction(typeAction,this.data.reservation.idReservation).subscribe({
			next: isSuccess => {
				//Vérification du statut
				if (isSuccess) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Vérification du type d'action
					if (typeAction == 'VALIDER') {
						//Mise à jour de l'affectation
						this.data.reservation.statut = 'VALIDEE';
					} else {
						//Rafraichissement de la liste
						this.liste.refresh();
					}
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Accès au conducteur
	 */
	showConducteur(affectation: any = this.data) {
		//Redirection vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: affectation.conducteur.idConducteur,
				typeListe: ''
			},
			withGoBack: true
		});
	}

	/**
	 * Accès à la liste des échéances des affectations
	 */
	goToAffectationEcheances(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeAffectationEcheances',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'affectation.idAffectation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.idAffectation,
					title: this.translateService.instant('vehicule.affectation.avantageNature.title',{ reference: affectation.vehicule.reference + (affectation.vehicule.numeroInterne ? ' - ' + affectation.vehicule.numeroInterne : '') + (affectation.user ? ' (' + affectation.user.nom + ' ' + affectation.user.prenom + ')' : '') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Accès à la réservation
	 */
	goToReservation(affectation: any = this.data) {
		//Redirection vers la page
		this.layoutService.goToByState('listeReservations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idReservation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: affectation.reservation.idReservation,
					title: this.translateService.instant('reservation.affectation',{ dateDebut: this.datePipe.transform(affectation.dateDebut,'short') }),
					displayedValeur: ''
				}]
			}
		});
	}

	/**
	 * Redirection vers le planning
	 */
	goToPlanning(affectation: any = this.data) {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'une réervation et d'un pool
		if (affectation.vehicule?.pool?.idPool && affectation.reservation) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + affectation.vehicule.pool.libelle + ' (' + affectation.vehicule.pool.reference + ')'
			});
		}

		//Navigation vers le planning
		this.vehiculeService.goToPlanning(affectation.vehicule,{ listeSelectedFilters },null,{ initialDate: affectation.dateDebut });
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.Affectation',this.data.idAffectation);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Affectation',this.data.idAffectation);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.Affectation',this.data.idAffectation,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.VEHICULE_AFFECTATION
		});
	}
}