import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DocumentService } from 'src/app/share/components/document/document.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EtatLieuxService } from './etat-lieux/etat-lieux.service';

@Component({
	templateUrl: './vehicule-etat-lieux-list-item.component.html'
})
export class VehiculeEtatLieuxListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Statuts des états des lieux **/
	mapStatuts: { [statut: string]: { icone: string,iconeType?: string } };

	/**
	 * Constructeur
	 */
	constructor(private etatLieuxService: EtatLieuxService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private documentService: DocumentService,public rightService: RightService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteEtatLieux = this.deleteEtatLieux.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des statuts
		this.mapStatuts = this.etatLieuxService.getMapStatuts();
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteEtatLieux(etatLieux: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.etatLieuxService.deleteEtatLieux(etatLieux))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Génération d'un document PDF
	 */
	public generateDocument() {
		//Génération d'un document PDF
		this.documentService.generateDocument('com.notilus.data.vehicule.etatLieux.EtatLieux',this.data.idEtatLieux);
	}

	/**
	 * Edition de l'état des lieux
	 */
	public addEtatLieux() {
		//Chargement de l'état des lieux
		this.etatLieuxService.loadEtatLieux(this.data?.idEtatLieux).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'état des lieux
					Object.assign(this.data,result.data?.etatLieux);

					//Edition de l'état des lieux
					this.extraOptions?.addEtatLieux?.(this.data,this.deleteEtatLieux);
				}
			}
		})
	}
}