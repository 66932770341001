<div class="text-center">
	<div class="listview" *ngIf="(null | right:'creation') && (typeReferentiel == 'LOCAL' || rightService.isRoot()) && resume.nbRubriques > 0 && (!templateMail.templateMailOrigine || templateMail.custom)">
		<div class="lv-body">
			<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="editListeRubriques()">
				<h5><a [routerLink]="[]" translate>templateMail.actions.modifierRubriques</a></h5>
			</div>
		</div>
	</div>
</div>
<div class="listview lv-bordered no-search">
	<div class="lv-body">
		<ng-container *ngIf="resume.nbRubriques > 0">
			<div class="lv-item media" *ngFor="let content of templateMail.listeTemplateStructure; let index = index;">
				<div class="media-body clickable" (click)="content.isToggled = !content.isToggled;">
					<div class="pull-left lv-avatar">{{ index + 1 }}</div>
					<div class="lv-title"></div>
					<ul class="pull-right actions m-t-0" *ngIf="null | right:'creation'">
						<li><a [routerLink]="[]"><icon [name]="!content.isToggled ? 'chevron_right' : 'expand_more'"></icon></a></li>
					</ul>
				</div>
				<div [collapse]="!content.isToggled" class="p-t-10 collapse">
					<div class="row">
						<div class="col-md-12">
							<span [innerHtml]="content.listeTemplateBloc[0].safeContenu"></span>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="text-center p-10" *ngIf="resume.nbRubriques == 0">
			<h5 translate><span translate>liste.empty</span></h5>
		</div>
	</div>
</div>