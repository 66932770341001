import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PoolService } from './pool.service';

@Component({
	templateUrl: './pool-vehicule-list-item.component.html'
})
export class PoolVehiculeListItemComponent extends ListViewItem<any> {
	/** Données **/
	@Input() data: any;

	/**
	 * Constructeur
	 */
	constructor(private poolService: PoolService,private translateService: TranslateService,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Retrait du véhicule du pool
	 */
	removeVehicule() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.dissociation.confirmation'),{ actionColor: 'primary' }).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du véhicule du pool
					this.poolService.removeVehicule(this.data.idVehicule).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.dissociation.success'));

								//Suppression du véhicule de la liste
								this.onObjectChange(this.data);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.dissociation.error'));
							}
						}
					});
				}
			}
		});
	}
}