import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { NatureService } from './nature.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'nature-field-list',
	templateUrl: './nature-field-list.component.html'
})
export class NatureFieldListComponent extends PageContentItem implements OnInit {
	/** Nature **/
	@Input() nature: any;

	/** Mode édition **/
	@Input() isEdition: boolean;

	/** Liste des champs à afficher **/
	public listeFields: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private natureService: NatureService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation des listes de champs
		this.listeFields = cloneDeep(this.nature.listeFields)?.filter(field => field.actif || this.isEdition);
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveNature() {
		let oldFieldReleveEnergie: any;
		let newFieldReleveEnergie: any;
		let isReleveEnergieFieldChanged: boolean;
		let listeFields: Array<any>;

		//Récupération du champ relevé d'énergie
		oldFieldReleveEnergie = this.nature.listeFields.find(field => field.typeField == 'RELEVE_ENERGIE');

		//Affectation de la liste des champs
		listeFields = this.listeFields;

		//Suppression des unités des champs inactifs
		listeFields.filter(field => !field.actif).forEach(field => field.unite = null);

		//Récupération du champ relevé d'énergie
		newFieldReleveEnergie = listeFields.find(field => field.typeField == 'RELEVE_ENERGIE');

		//Vérification de la modification du champ additionnel sur le relevé d'énergie
		isReleveEnergieFieldChanged = newFieldReleveEnergie && oldFieldReleveEnergie && (newFieldReleveEnergie.actif != oldFieldReleveEnergie.actif || newFieldReleveEnergie.unite?.idUnite != oldFieldReleveEnergie.unite?.idUnite);

		//Vérification du message de confirmation
		(isReleveEnergieFieldChanged ? this.confirmService.showConfirm(this.translateService.instant('nature.elements.field.releveEnergie.confirmation')) : of(true)).subscribe(isConfirmed => {
			//Vérification de la confirmation
			if (isConfirmed) {
				//Affectation de la liste des champs
				this.nature.listeFields = listeFields;

				//Enregistrement de l'objet
				this.natureService.saveNature(this.nature).subscribe({
					next: (result: Result) => {
						//Vérification du code d'erreur
						if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

							//Mise à jour de l'objet
							Object.assign(this.nature,result.data?.nature);

							//Fermeture du formulaire
							this.close({ nature: this.nature });
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						}
					}
				});
			} else {
				//Rétablissement de l'unité
				newFieldReleveEnergie.unite = oldFieldReleveEnergie.unite;
			}
		});
	}
}