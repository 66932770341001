import { Component,Input, OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ProfilService } from './profil.service';

@Component({
	selector: 'profil-edit',
	templateUrl: './profil-edit.component.html'
})
export class ProfilEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() profil: any;

	/** Suppression de l'objet **/
	@Input() deleteProfil: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveProfil: (profil: any,close: Function) => void;

	/** Liste des types de restrictions **/
	public listeTypesRestriction: Array<{ code: string,libelle: string }>;

	/** Liste des types de restrictions dynamiques **/
	public listeTypesRestrictionDynamique: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private profilService: ProfilService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de la liste des types de restrictions
		this.listeTypesRestriction = this.profilService.getListeTypesRestriction();

		//Chargement de la liste des types de restrictions dynamiques
		this.listeTypesRestrictionDynamique = this.profilService.getListeTypesRestrictionDynamique();
	}

	/**
	 * Vérification de la présence d'erreurs sur la liste des sociétés
	 */
	hasErrorListeSocietes: () => boolean = () => !this.profil.listeSocietes?.length;
}
