<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Calendrier/loadCalendrier',data.idCalendrier]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>calendrier.liste.typeDiffusion</span>&#160;:&#160;<span [translate]="'diffusion.'+data.typeDiffusion"></span></li>
		<li><span translate>calendrier.liste.nbEntites</span>&#160;:&#160;<span>{{ data.listeDetails?.length || 0 }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteCalendrier();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory('com.notilus.data.calendrier.Calendrier',data.idCalendrier)"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>