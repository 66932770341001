import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { GroupeListComponent } from './groupe-list.component';
import { GroupeListItemComponent } from './groupe-list-item.component';
import { GroupeService } from './groupe.service';
import { GroupeComponent } from './groupe.component';
import { GroupeEditComponent } from './groupe-edit.component';
import { GroupeRuleComponent } from './groupe-rule.component';

@NgModule({
	imports: [ShareModule],
	declarations: [GroupeListComponent,GroupeListItemComponent,GroupeComponent,GroupeEditComponent,GroupeRuleComponent],
	providers: [GroupeService]
})
export class GroupeModule { }
