import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { Droit } from 'src/app/domain/droit/droit';

@Component({
	selector: 'tenant-droit',
	templateUrl: './tenant-droit.component.html'
})
export class TenantDroitComponent extends PageContentItem implements OnInit {
	/** Droits **/
	@Input() mapDroits: { [key: string]: Array<Droit> };

	/** Liste des droits **/
	public listeDroits: Array<any>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Conversion d'une map de droits en une liste
		this.listeDroits = Object.entries(this.mapDroits).flatMap(([,listeDroits]) => listeDroits);
	}
}