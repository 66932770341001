'use strict';

var EventEmitter = require('events').EventEmitter,
  inherits = require('inherits'),
  utils = require('./utils/event'),
  IframeTransport = require('./transport/iframe'),
  InfoReceiverIframe = require('./info-iframe-receiver');
var debug = function () {};
if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')('sockjs-client:info-iframe');
}
function InfoIframe(baseUrl, url) {
  var self = this;
  EventEmitter.call(this);
  var go = function () {
    var ifr = self.ifr = new IframeTransport(InfoReceiverIframe.transportName, url, baseUrl);
    ifr.once('message', function (msg) {
      if (msg) {
        var d;
        try {
          d = JSON.parse(msg);
        } catch (e) {
          debug('bad json', msg);
          self.emit('finish');
          self.close();
          return;
        }
        var info = d[0],
          rtt = d[1];
        self.emit('finish', info, rtt);
      }
      self.close();
    });
    ifr.once('close', function () {
      self.emit('finish');
      self.close();
    });
  };

  // TODO this seems the same as the 'needBody' from transports
  if (!global.document.body) {
    utils.attachEvent('load', go);
  } else {
    go();
  }
}
inherits(InfoIframe, EventEmitter);
InfoIframe.enabled = function () {
  return IframeTransport.enabled();
};
InfoIframe.prototype.close = function () {
  if (this.ifr) {
    this.ifr.close();
  }
  this.removeAllListeners();
  this.ifr = null;
};
module.exports = InfoIframe;