import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ServiceAliasListItemComponent } from './service-alias-list-item.component';

@Component({
	selector: 'service-alias-list',
	templateUrl: './service-alias-list.component.html'
})
export class ServiceAliasListComponent extends PageContentItem implements OnInit {
	/** Service **/
	@Input() service: any;

	/** Fonction d'édition **/
	@Input() addAlias: () => void;

	/** Résumé **/
	@Input() resume: { nbAlias: number };

	/** Données **/
	liste: ListView<any,ServiceAliasListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ServiceAliasListItemComponent>({
			uri: () => `/controller/Analytique/filtreSocieteAlias`,
			component: ServiceAliasListItemComponent,
			isLocal: true,
			extraOptions: {
				addAlias: this.addAlias
			},
			listeFilters: [{
				clef: 'code',
				type: TypeFilter.STRING,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'societe.idService',
				valeur: this.service.idService,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idAlias > 0)
			//Mise à jour des compteurs
			this.resume.nbAlias--;
	}
}