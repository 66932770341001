import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { StructureExportService } from './structure-export.service';

@Component({
	templateUrl: './structure-export-line-selection.component.html'
})
export class StructureExportLineSelectionComponent implements OnInit {
	/** Type de détail du parent **/
	@Input() typeDetailParent: string;

	/** Indicateur d'ajout d'un groupe parent **/
	@Input() isAddGroupeParent: boolean;

	/** Liste des types de détail **/
	listeTypesDetail: Array<{ code: string,libelle: string }>;

	/** Liste des types de ligne **/
	listeTypesLigne: Array<{ code: string,typeDetail: string,libelle: string,isSelected?: boolean }>;

	/** Résultat **/
	result: { listeSelectedItems: Array<any> };

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,public bsModalRef: BsModalRef<StructureExportLineSelectionComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des types de détail
		this.listeTypesDetail = this.structureExportService.getListeTypesDetail();

		//Récupération des types de ligne
		this.listeTypesLigne = this.structureExportService.getListeTypesLigne();


		//Vérification du type d'ajout
		if (!this.isAddGroupeParent) {
			//Vérification du type de détail
			if (this.typeDetailParent == 'GROUPE')
				//Suppression des fichiers
				this.listeTypesDetail = this.listeTypesDetail.filter(typeDetail => typeDetail.code != 'FICHIER');
			else if (this.typeDetailParent == 'COMPTABILITE_GENERALE')
				//Suppression des fichiers et des groupes
				this.listeTypesDetail = this.listeTypesDetail.filter(typeDetail => typeDetail.code != 'FICHIER' && typeDetail.code != 'GROUPE');
		} else
			//Conservation des groupes uniquement
			this.listeTypesDetail = this.listeTypesDetail.filter(typeDetail => typeDetail.code == 'GROUPE');
	}

	/**
	 * Récupération de la liste des types de ligne pour le type de détail
	 */
	getListeTypesLigneForTypeDetail(typeDetail: string): Array<{ code: string,typeDetail: string,libelle: string }> {
		//Retour de la liste des types de ligne
		return this.listeTypesLigne.filter(typeLigne => typeLigne.typeDetail == typeDetail);
	}

	/**
	 * Vérification de la sélection
	 */
	isSelectionValid(): boolean {
		//Vérification de la sélection d'un type de ligne
		return this.listeTypesLigne.some(typeLigne => typeLigne.isSelected);
	}

	/**
	 * Sélection des lignes
	 */
	selectLines() {
		let listeSelectedItems: Array<any>;
		let listeGroupes: Array<any>;
		let listeLignes: Array<any>;
		let listeItems: Array<any>;
		let groupe: any;
		let ligne: any;
		let index: number;

		//Ajout des lignes de type 'Fichier'
		listeSelectedItems = this.listeTypesLigne.filter(typeLigne => typeLigne.typeDetail == 'FICHIER' && typeLigne.isSelected).map(typeLigne => ({
			ligne: {
				typeLigne: typeLigne.code,
				libelle: typeLigne.libelle
			}
		}));

		//Sélection des groupes
		listeGroupes = this.listeTypesLigne.filter(typeLigne => typeLigne.typeDetail == 'GROUPE' && typeLigne.isSelected);

		//Sélection des lignes de comptabilité générale
		listeLignes = this.listeTypesLigne.filter(typeLigne => typeLigne.typeDetail == 'COMPTABILITE_GENERALE' && typeLigne.isSelected);

		//Vérification de la présence de groupes
		if (listeGroupes.length > 0) {
			//Définition des éléments à ajouter
			listeItems = listeGroupes.filter(typeLigne => typeLigne.code != 'GROUPE').map(typeLigne => ({
				ligne: {
					typeLigne: typeLigne.code,
					libelle: typeLigne.libelle
				}
			}));

			//Vérification de la nécessité de créer un nouveau groupe
			if (this.isAddGroupeParent || this.typeDetailParent != 'GROUPE' || listeGroupes.some(typeLigne => typeLigne.code == 'GROUPE')) {
				//Ajout des lignes dans un nouveau groupe
				listeSelectedItems.splice(1,0,groupe = {
					detail: {
						typeDetail: 'GROUPE',
						listeItems
					}
				});
			} else
				//Ajout des lignes dans le groupe existant
				listeSelectedItems = listeSelectedItems.concat(listeItems);

			//Vérification de la présence de lignes
			if (listeLignes.length > 0) {
				//Définition de l'élément associé aux lignes
				ligne = {
					detail: {
						typeDetail: 'COMPTABILITE_GENERALE',
						listeItems: listeLignes.map(typeLigne => ({
							ligne: {
								typeLigne: typeLigne.code,
								libelle: typeLigne.libelle
							}
						}))
					}
				};

				//Vérification de la création d'un nouveau groupe
				if (groupe) {
					//Recherche de l'index du pied de groupe
					index = groupe.detail.listeItems.findIndex(item => item.ligne && item.ligne.typeLigne == 'GROUPE_PIED');

					//Ajout des lignes dans le nouveau groupe
					groupe.detail.listeItems.splice(index != -1 ? index : groupe.detail.listeItems.length,0,ligne);
				} else
					//Ajout des lignes dans le groupe existant
					listeSelectedItems.splice(1,0,ligne);
			}
		} else if (listeLignes.length > 0) {
			//Définition des éléments à ajouter
			listeItems = listeLignes.map(typeLigne => ({
				ligne: {
					typeLigne: typeLigne.code,
					libelle: typeLigne.libelle
				}
			}));

			//Vérification de la nécessité de créer un nouveau détail
			if (this.typeDetailParent != 'COMPTABILITE_GENERALE') {
				//Ajout des lignes dans un nouveau détail
				listeSelectedItems.splice(1,0,{
					detail: {
						typeDetail: 'COMPTABILITE_GENERALE',
						listeItems: listeItems
					}
				});
			} else
				//Ajout des lignes dans le détail existant
				listeSelectedItems = listeSelectedItems.concat(listeItems);
		}

		//Définition du résultat
		this.result = {
			listeSelectedItems
		};

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}