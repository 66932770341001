<form #userForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.user.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': user.invalid }">
							<label translate>comptabilite.compte.user</label>
							<div>
								<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="lienUser.user" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': compteAuxiliaire.invalid }">
							<label translate>comptabilite.compte.compteAuxiliaire.item</label>
							<div>
								<autocomplete name="compteAuxiliaire" type="compteAuxiliaire" #compteAuxiliaire="ngModel" [(ngModel)]="lienUser.compteAuxiliaire" [filter]="{ idCompte: lienUser?.compte?.idCompte,isCompteDisabled: true  }"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="p-t-20">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut1</label>
								<div>
									<input type="text" name="attribut1" #attribut1="ngModel" class="form-control" [(ngModel)]="lienUser.attribut1" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut2</label>
								<div>
									<input type="text" name="attribut2" #attribut2="ngModel" class="form-control" [(ngModel)]="lienUser.attribut2" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut3</label>
								<div>
									<input type="text" name="attribut3" #attribut3="ngModel" class="form-control" [(ngModel)]="lienUser.attribut3" maxlength="100"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>comptabilite.compte.attribut4</label>
								<div>
									<input type="text" name="attribut4" #attribut4="ngModel" class="form-control" [(ngModel)]="lienUser.attribut4" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteLienUser(lienUser,close);" *ngIf="deleteLienUser && lienUser?.idLien && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLienUser();" *ngIf="null | right:'creation'" [disabled]="userForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: userForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>