import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'extraction-field-list',
	templateUrl: './extraction-field-list.component.html'
})
export class ExtractionFieldListComponent extends PageContentItem {
	/** Extraction **/
	@Input() extraction: any;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Fonction d'édition des champs **/
	@Input() addField: () => void;

	/** Enumération des types de référentiel **/
	readonly TypeReferentiel = TypeReferentiel;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}
}
