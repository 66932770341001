<form #chatbotExchangeForm="ngForm" autocomplete="off">
	<div class="tile-active-show collapse chatbot" [collapse]="!chatbotSession?.isDisplayed">
		<div class="tile-sub">
			<div class="text-center p-10" *ngIf="listChatbotExchanges == null"><h2><i class="zmdi zmdi-more animated infinite fadeInLeft zmdi-hc-fw"></i></h2></div>
			<div class="text-center p-10" *ngIf="listChatbotExchanges != null && listChatbotExchanges.length == 0"><span translate>liste.empty</span></div>

			<div class="body" *ngIf="listChatbotExchanges != null && listChatbotExchanges.length" style="height: auto;">
				<div class="media" *ngFor="let exchange of listChatbotExchanges">
					<div class="bubble" [ngClass]="{ 'bot': exchange.type == 'BOT','user': exchange.type == 'USER' }" [tooltip]="exchange.date | date:'short'" [placement]="exchange.type == 'BOT' ? 'right' : 'left'">
						<markdown *ngIf="exchange.type == 'BOT'" [data]="exchange.contenu"></markdown>
						<span *ngIf="exchange.type == 'USER'">{{ exchange.contenu }}</span>
						<display-more [listeItems]="exchange.listeLinks" displayMode="COMPACT" nbItems="5" #displayMore>
							<ul *ngIf="exchange.listeLinks">
								<li *ngFor="let link of displayMore.getSlice()">
									<a [routerLink]="[]" (click)="link.onClick()">{{ link.text }}</a>
								</li>
							</ul>
						</display-more>
					</div>
				</div>
			</div>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: chatbotExchangeForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteConversation();" *ngIf="chatbotSession.idSession && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
		</div>
	</div>
</form>