import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { PosteChargeCompteEditComponent } from 'src/app/components/comptabilite/poste-charge/poste-charge-compte-edit.component';
import { AppState } from 'src/app/domain/appstate';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FournisseurAliasEditComponent } from './fournisseur-alias-edit.component';
import { FournisseurAliasListComponent } from './fournisseur-alias-list.component';
import { FournisseurCompteListComponent } from './fournisseur-compte-list.component';
import { FournisseurContactEditComponent } from './fournisseur-contact-edit.component';
import { FournisseurContactListComponent } from './fournisseur-contact-list.component';
import { FournisseurEditComponent } from './fournisseur-edit.component';
import { FournisseurFacturationEditComponent } from './fournisseur-facturation-edit.component';
import { FournisseurFacturationListComponent } from './fournisseur-facturation-list.component';
import { FournisseurFormatInterfaceListComponent } from './fournisseur-format-interface-list.component';
import { FournisseurService } from './fournisseur.service';

@Component({
	selector: 'fournisseur',
	templateUrl: './fournisseur.component.html'
})
export class FournisseurComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Fournisseur courant **/
	public fournisseur: any;

	/** Type de référentiel **/
	public typeReferentiel: TypeReferentiel;

	/** Résumé **/
	private resume: { nbContacts: number,nbInterfaces: number,nbComptes: number,nbComptesFacturation: number,nbAlias: number } = {
		nbContacts: 0,
		nbInterfaces: 0,
		nbComptes: 0,
		nbComptesFacturation: 0,
		nbAlias: 0
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.FOURNISSEUR;

	/** Type de pièce jointe de l'entité **/
	readonly typeLink: string = 'STANDARD';

	/** Classe du type de pièce jointe pour l'entité **/
	readonly typeLinkClass: string = 'com.notilus.data.attachment.link.LinkFournisseur$TypeLinkFournisseur';

	/** Liste des comptes de facturation sans société **/
	public listeComptesFacturationWithoutSociete: Array<any> = [];

	/** Utilisateur connecté **/
	private user: User;

	/** Indicateur de rattachement à une interface **/
	private hasInterface: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private store: Store<AppState>
			,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.fournisseur.Fournisseur';

	/**
	 * Récupération de l'identifiant du fournisseur à charger
	 */
	getIdObject: () => number = () => this.fournisseur?.idFournisseur || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.fournisseur;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'fournisseur.libelle',
				key: 'libelle'
			},{
				libelle: 'fournisseur.reference',
				key: 'reference'
			},{
				libelle: 'common.actif',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editFournisseur(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editFournisseur(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteFournisseur(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'people',
				title: this.translateService.instant('fournisseur.alerte.contact.title'),
				message: this.translateService.instant('fournisseur.alerte.contact.message'),
				isVisible: () => !this.resume.nbContacts && !this.pageContentService.isOpened(),
				doAction: () => this.addContact(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			},{
				icon: 'warning',
				title: this.translateService.instant('fournisseur.alerte.compteFacturation.title'),
				message: this.translateService.instant('fournisseur.alerte.compteFacturation.message'),
				isVisible: () => this.rightService.hasAnyRight([TypeDroit.FACTURE,TypeDroit.ADMIN_PLAN_COMPTABLE],'consultation') && !this.rightService.isRoot() && this.fournisseur?.actif && this.listeComptesFacturationWithoutSociete.length > 0,
				doAction: () => this.addLienFacturation(this.listeComptesFacturationWithoutSociete[0]),
				isActionVisible: () => this.rightService.hasAnyRight([TypeDroit.FACTURE,TypeDroit.ADMIN_PLAN_COMPTABLE],'creation')
			},{
				icon: 'n-icon-compte p-t-10',
				iconType: 'icon-venus',
				title: this.translateService.instant('fournisseur.alerte.compteTiers.title'),
				message: this.translateService.instant('fournisseur.alerte.compteTiers.message'),
				isVisible: () => !this.rightService.isRoot() && this.fournisseur?.actif && !this.resume.nbComptes,
				doAction: () => this.addCompte(),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			}],
			listeElements: [{
				type: 'CONTACT',
				libelle: 'fournisseur.elements.contact',
				component: FournisseurContactListComponent,
				retrieveComponentData: () => ({
					fournisseur: this.fournisseur,
					resume: this.resume,
					addContact: this.addContact.bind(this)
				}),
				doAction: () => this.addContact(),
				count: () => this.resume.nbContacts,
				libelleAction: this.translateService.instant('fournisseur.elements.contact.ajouter'),
				isVisible: () => this.rightService.hasRight(null,'consultation'),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			},{
				type: 'INTERFACE',
				libelle: 'fournisseur.elements.interface',
				component: FournisseurFormatInterfaceListComponent,
				retrieveComponentData: () => ({
					fournisseur: this.fournisseur
				}),
				count: () => this.resume.nbInterfaces
			},{
				type: 'COMPTE',
				libelle: 'fournisseur.elements.compte',
				component: FournisseurCompteListComponent,
				retrieveComponentData: () => ({
					fournisseur: this.fournisseur,
					resume: this.resume,
					addCompte: this.addCompte.bind(this)
				}),
				doAction: () => this.addCompte(),
				count: () => this.resume.nbComptes,
				libelleAction: this.translateService.instant('fournisseur.elements.compte.ajouter'),
				isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'creation')
			},{
				type: 'FACTURATION',
				libelle: 'fournisseur.elements.facturation',
				component: FournisseurFacturationListComponent,
				retrieveComponentData: () => ({
					fournisseur: this.fournisseur,
					resume: this.resume,
					addLienFacturation: this.addLienFacturation.bind(this)
				}),
				doAction: () => this.addLienFacturation(),
				count:  () => this.resume.nbComptesFacturation,
				libelleAction: this.translateService.instant('fournisseur.elements.facturation.ajouter'),
				isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'consultation'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'creation')
			},{
				type: 'ALIAS',
				libelle: 'fournisseur.elements.alias',
				component: FournisseurAliasListComponent,
				retrieveComponentData: () => ({
					fournisseur: this.fournisseur,
					resume: this.resume,
					addAlias: this.addAlias.bind(this)
				}),
				doAction: () => this.addAlias(),
				count: () => this.resume.nbAlias,
				libelleAction: this.translateService.instant('fournisseur.elements.alias.ajouter'),
				isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE_LAD,'consultation'),
				isActionVisible: () => this.rightService.hasRight(TypeDroit.FACTURE_LAD,'creation')
			}]
		};

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Définition du type de référentiel
			this.typeReferentiel = this.activatedRoute.snapshot.params.typeReferentiel;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idFournisseur);
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idFournisseur: number) {
		//Vérification de l'identifiant du fournisseur
		if (idFournisseur > 0) {
			//Chargement du fournisseur
			this.fournisseurService.loadFournisseur(idFournisseur).subscribe(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Définition du fournisseur
					this.fournisseur = result.data?.fournisseur;

					//Définition du résumé
					this.resume = {
						nbContacts: result.data?.nbContacts || 0,
						nbInterfaces: result.data?.nbInterfaces || 0,
						nbComptes: result.data?.nbComptes || 0,
						nbComptesFacturation: result.data?.nbComptesFacturation || 0,
						nbAlias: result.data?.nbAlias || 0
					};

					//Récupération de l'indicateur de rattachement à une interface
					this.hasInterface = result.data?.hasInterface;

					//Récupération des comptes de facturation sans société
					this.listeComptesFacturationWithoutSociete = result.data?.listeComptesFacturationWithoutSociete;

					//Vérification du fournisseur
					if (this.fournisseur.tenant)
						//Initialisation du compteur de pièces jointes
						this.attachmentService.initAttachments(this);

					//Vérification du mode
					if (this.typeReferentiel == TypeReferentiel.STANDARD)
						//Passage en mode 'Edition'
						this.editFournisseur();
				}
			});
		} else {
			//Création d'un fournisseur vierge
			this.fournisseur = {
				actif: true,
				tenant: this.user.tenant
			};

			//Initialisation du compteur de pièces jointes
			this.attachmentService.initAttachments(this);

			//Déclenchement au prochain cycle
			setTimeout(() => {
				//Passage en mode 'Édition'
				this.editFournisseur();
			});
		}
	}

	/**
	 * Édition du fournisseur
	 */
	private editFournisseur() {
		//Ouverture du composant d'édition
		this.pageContentService.open(FournisseurEditComponent,{
			data: {
				fournisseur: this.fournisseur.idFournisseur ? cloneDeep(this.fournisseur) : this.fournisseur,
				typeReferentiel: this.typeReferentiel,
				hasInterface: this.hasInterface,
				deleteFournisseur: this.deleteFournisseur.bind(this)
			}
		}).subscribe(fournisseur => {
			//Vérification des données
			if (fournisseur) {
				//Mise à jour du fournisseur
				Object.assign(this.fournisseur,fournisseur);

				//Mise à jour de l'identifiant contenu dans l'URL
				this.layoutService.replaceUrlWith(this.fournisseur);
			}
		});
	}

	/**
	 * Suppression du fournisseur
	 */
	protected deleteFournisseur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.fournisseurService.deleteFournisseur(this.fournisseur,this.typeReferentiel))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Modification du contact
	 */
	addContact(contact: any = { fournisseur: { ...this.fournisseur },type: 'NON_DEFINI',actif: true },deleteContact?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(FournisseurContactEditComponent,{
			data: {
				contact: cloneDeep(contact),
				deleteContact
			}
		},'sub').subscribe((data: { contact: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('CONTACT',contact.idContact != data.contact?.idContact);

				//Vérification de la présence d'une création ou d'une suppression
				if (!contact.idContact && data.contact?.idContact)
					//Incrémentation du compteur
					this.resume.nbContacts++;
				else if (contact.idContact && !data.contact?.idContact)
					//Décrémentation du compteur
					this.resume.nbContacts--;

				//Mise à jour du contact
				Object.assign(contact,data.contact);
			}
		});
	}

	/**
	 * Modification du lien avec un compte collectif
	 */
	addCompte(lien: any = { fournisseur: { ...this.fournisseur },type: 'FOURNISSEUR' },deleteLienCompteDetail?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(PosteChargeCompteEditComponent,{
			data: {
				lien: cloneDeep(lien),
				deleteLienCompteDetail,
				source: 'TIERS'
			}
		},'sub').subscribe((data: { lien: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('COMPTE',lien.idLien != data.lien?.idLien);

				//Vérification de la présence d'une création ou d'une suppression
				if (!lien.idLien && data.lien?.idLien)
					//Incrémentation du compteur
					this.resume.nbComptes++;
				else if (lien.idLien && !data.lien?.idLien)
					//Décrémentation du compteur
					this.resume.nbComptes--;

				//Mise à jour du lien
				Object.assign(lien,data.lien);
			}
		});
	}

	/**
	 * Modification d'un compte de facturation
	 */
	addLienFacturation(lienFacturation: any = { fournisseur: { ...this.fournisseur } },deleteLienFacturation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(FournisseurFacturationEditComponent,{
			data: {
				lienFacturation: cloneDeep(lienFacturation),
				deleteLienFacturation
			}
		},'sub').subscribe((data: { lienFacturation: any }) => {
			let idxWithoutSociete: number;

			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('FACTURATION',lienFacturation.idLien != data.lienFacturation?.idLien);

				//Vérification de la présence d'une création ou d'une suppression
				if (!lienFacturation.idLien && data.lienFacturation?.idLien)
					//Incrémentation du compteur
					this.resume.nbComptesFacturation++;
				else if (lienFacturation.idLien && !data.lienFacturation?.idLien)
					//Décrémentation du compteur
					this.resume.nbComptesFacturation--;

				//Vérification que le compte de facturation n'était pas associé à une société et qu'il a été supprimé ou que sa société a été renseignée
				if (lienFacturation.idLien && (idxWithoutSociete = this.listeComptesFacturationWithoutSociete.findIndex(c => c.idLien == lienFacturation.idLien)) > -1 && (!data.lienFacturation || data.lienFacturation.societe))
					//Suppression de l'élément de la liste des comptes de facturation sans société
					this.listeComptesFacturationWithoutSociete.splice(idxWithoutSociete,1);

				//Mise à jour du compte de facturation
				Object.assign(lienFacturation,data.lienFacturation);
			}
		});
	}

	/**
	 * Modification d'un alias
	 */
	addAlias(alias: any = { fournisseur: { ...this.fournisseur } },deleteAlias?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(FournisseurAliasEditComponent,{
			data: {
				alias: cloneDeep(alias),
				deleteAlias
			}
		},'sub').subscribe((data: { alias: any }) => {
			//Vérification de la présence de données
			if (data) {
				//Affichage de l'onglet (si nécessaire)
				this.complexPage.setSelectedElementByType('ALIAS',alias.idAlias != data.alias?.idAlias);

				//Vérification de la présence d'une création ou d'une suppression
				if (!alias.idAlias && data.alias?.idAlias)
					//Incrémentation du compteur
					this.resume.nbAlias++;
				else if (alias.idAlias && !data.alias?.idAlias)
					//Décrémentation du compteur
					this.resume.nbAlias--;

				//Mise à jour de l'alias
				Object.assign(alias,data.alias);
			}
		});
	}
}