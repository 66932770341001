import { Component,Input,OnInit,Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual,omit } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { v4 } from 'uuid';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { BusinessDataService } from 'src/app/share/components/entite/business-data/business-data.service';
import { TemplateMailService } from 'src/app/share/components/template-mail/template-mail.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GLOBALS } from 'src/app/utils/globals';

@Component({
	templateUrl: './template-mail-rubrique-list-edit.component.html'
})
export class TemplateMailRubriqueListEditComponent extends PageContentItem implements OnInit {
	/** Template de mail **/
	@Input() templateMail: any;

	/** Ajout d'une rubrique **/
	@Input() isAddNewRubrique: boolean;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Indicateur de personnalisation du template de mail **/
	@Input() isCustom: boolean;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Utilisateur d'origine **/
	@Input() userOrigine: User;

	/** Configuration de l'éditeur de texte **/
	summernoteConfig: any;

	/** Autorisation de la modification du template **/
	isTemplateEditable: boolean;

	/** Liste des cibles des clics **/
	private listeMousedownTargets: Array<HTMLElement> = [];

	/** Copie du template de mail **/
	private savedTemplateMail: any;

	/**
	 * Constructeur
	 */
	constructor(private templateMailService: TemplateMailService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService,private renderer: Renderer2,private businessDataService: BusinessDataService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let user: User;

		//Copie du template de mail
		this.templateMail = cloneDeep(this.templateMail);

		//Parcours des rubriques
		this.templateMail.listeTemplateStructure.forEach(ts => {
			//Ajout d'une clé d'identification temporaire à la rubrique
			ts.uuid = v4().toString();
		});

		//Vérification de l'ajout
		if (this.isAddNewRubrique) {
			//Ajout d'un étape
			this.addStructure();

			//Exécution au prochain cycle
			setTimeout(() => {
				//Scroll en bas de la page
				document.documentElement.scroll({ top: document.documentElement.scrollHeight,behavior: 'smooth' });
			});
		}

		//Configuration des éditeurs de texte
		this.summernoteConfig = {
			height: 300,
			disableDragAndDrop: true,
			fontNames: ['Arial','Comic Sans MS','Courier New','Helvetica','Times New Roman','Verdana','Century Gothic','Calibri'],
			toolbar: [
				['custom',['btn_business_data']],
				['style',['bold','italic','underline','clear','strikethrough']],
				['fontsize',['color','fontsize','height']],
				['fontname',['fontname']],
				['para',['ul','ol','paragraph']],
				['table',['table']],
				['insert',['link']],
				(this.rightService.isRoot() || this.rightService.isRoot(true)) && ['view',['codeview']] || null
			]
		};

		//Récupération de l'utilisateur connecté
		user = this.userOrigine || this.user;

		//Définition de la langue de l'éditeur
		this.summernoteConfig.lang = user.langue ? user.langue.code.toLowerCase() + '-' + user.langue.code.toUpperCase() : 'fr-FR';

		//Vérification de l'option de regroupement des notifications
		if (!this.templateMail.regroupementNotification) {
			//Ajout du bouton d'affichage des données métier
			this.summernoteConfig.buttons = {
				'btn_business_data': this.onBusinessDataButtonClick.bind(this)
			};
		}

		//Vérification de l'autorisation à modifier le template
		this.isTemplateEditable = this.rightService.hasRight(TypeDroit.ADMIN_TEMPLATE_MAIL,'creation') && (this.typeReferentiel == 'STANDARD' && this.rightService.isRoot() || this.typeReferentiel == 'LOCAL' && (!this.templateMail.templateMailOrigine || this.templateMail.custom));

		//Copie du template de mail avant modifications
		this.savedTemplateMail = cloneDeep(this.templateMail);
	}

	/**
	 * Gestion des structures (ajout/modification)
	 */
	addStructure() {
		let listeBlocs: Array<{ position: number,langue:string }>;

		//Récupération/Initialisation de la liste des structures
		this.templateMail.listeTemplateStructure = this.templateMail.listeTemplateStructure || [];

		//Initialisation des blocs de contenu
		listeBlocs = [];

		//Ajout du premier bloc
		listeBlocs.push({
			position: 1,
			langue: this.templateMail.langue
		});

		//Ajout d'une nouvelle structure
		this.templateMail.listeTemplateStructure.push({
			type: 'UN_ETENDU',
			listeTemplateBloc: listeBlocs,
			position: this.templateMail.listeTemplateStructure.length + 1,
			isToggled : true,
			uuid: v4().toString()
		});

		//Rafraichissement du composant de tri
		this.templateMail.listeTemplateStructure = [...this.templateMail.listeTemplateStructure];
	}

	/**
	 * Déplacement du contenu
	 */
	moveStructure(index: number,delta: number) {
		let nextItem: any;

		//Récupération de l'élément suivant
		nextItem = this.templateMail.listeTemplateStructure[index + delta];

		//Échange avec l'élément suivant
		this.templateMail.listeTemplateStructure[index + delta] = this.templateMail.listeTemplateStructure[index];
		this.templateMail.listeTemplateStructure[index] = nextItem;

		//Rafraichissement du composant de tri
		this.templateMail.listeTemplateStructure = [...this.templateMail.listeTemplateStructure];
	}

	/**
	 * Suppression de la structure
	 */
	deleteStructure(index: number) {
		//Suppression de l'élément
		this.templateMail.listeTemplateStructure.splice(index,1);

		//Rafraichissement du composant de tri
		this.templateMail.listeTemplateStructure = [...this.templateMail.listeTemplateStructure];
	}

	/**
	 * Sauvegarde du template de mail
	 */
	saveTemplateMail() {
		//Numérotation des positions des structures
		this.templateMail.listeTemplateStructure?.forEach((structure,idxStructure) => {
			//Définition de la position
			structure.position = idxStructure + 1;
		});

		//Vérification de l'absence de rubriques
		if (this.templateMail.listeTemplateStructure?.length == 0)
			//Désactivation du template
			this.templateMail.actif = false;

		//Parcours de la structure pour supprimer le contenu HTML 'Safe'
		this.templateMail.listeTemplateStructure.forEach(structure => {
			//Parcours des blocs
			structure.listeTemplateBloc?.forEach(bloc => {
				//Suppression du contenu HTML 'Safe'
				delete bloc.safeContenu;
			});
		});

		//Enregistrement du template
		this.templateMailService.saveTemplateMail(this.templateMail,this.typeReferentiel).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du template de mail
				this.templateMail = result.data.templateMail;

				//Fermeture du formulaire
				this.close({ templateMail: result.data.templateMail });
			} else if (result.codeErreur == TypeCodeErreur.DOUBLON) {
				let listeDoublons: Array<string> = [];

				//Vérification du titre
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Gestion du bouton des données métiers dans Summernote
	 */
	private onBusinessDataButtonClick(editorContext: any) {
		let ui: any;
		let button: any;

		//Récupération du composant summernote
		ui = GLOBALS.$.summernote.ui;

		//Création du bouton
		button = ui.button({
			contents: '<i class="material-icons-round p-l-5 p-r-5">message</i>',
			tooltip: this.translateService.instant('templateMail.btnBusinessData'),
			container: 'body',
			click: () => {
				//Enregistrement de la position du curseur dans l'éditeur
				editorContext.invoke('saveRange');

				//Ouverture de la popup de catalogue des données métier
				this.businessDataService.showBusinessData(this.templateMail.entite,{
					typeContext: 'MAIL',
					isPreviewData: this.user.tenant.type == 'CLIENT',
					preloadedEntity: false
				}).pipe(filter(item => !!item)).subscribe(item => {
					let range;

					//Restauration de la position du curseur
					editorContext.invoke('focus');
					editorContext.invoke('restoreRange');

					//Vérification de l'absence d'une entité
					if (!this.templateMail.entite)
						//Récupération de l'entité sur la valeur métier sélectionnée
						this.templateMail.entite = item.startEntity;

					//Récupération de la sélection actuelle
					range = editorContext.invoke('createRange');

					//Absence de sélection
					if (range)
						//Déplacement du curseur après l'élément
						range.collapse(false);

					//Insertion de la donnée métier dans l'éditeur
					editorContext.invoke('editor.pasteHTML','{{' + item.entity.path + '}}');
				});
			}
		});

		//Affichage du bouton dans l'éditeur
		return button.render();
	}

	/**
	 * Détection des clics à l'intérieur d'un élément déplaçable
	 */
	onMousedown(event: MouseEvent,index: number) {
		//Mémorisation de la cible du clic à l'intérieur de l'élément
		this.listeMousedownTargets[index] = event.target as HTMLElement;
	}

	/**
	 * Détection du démarrage d'un glisser-déposer à l'intérieur d'un élément déplaçable
	 */
	onDragstart(event: Event,index: number,draggableElement: HTMLElement) {
		let handle: HTMLElement;

		//Récupération du seul élément permettant le drag & drop
		handle = draggableElement.querySelector('.handle');

		//Vérification qu'un autre élément a été saisi pour le drag & drop
		if (!handle.contains(this.listeMousedownTargets[index]))
			//Désactivation de la transmission de l'évènement au composant de tri
			event.stopPropagation();
	}

	/**
	 * Détection du survol (entrée) d'un champ de saisie (nécessaire à cause d'un bug Firefox sur les éléments '[draggable="true"] input')
	 */
	onMouseenter(draggableElement: HTMLElement) {
		let outerDraggableElement: HTMLElement;

		//Désactivation de la possibilté de drag & drop
		this.renderer.setAttribute(draggableElement,'draggable','false');

		//Récupération de l'élément déplaçable parent
		outerDraggableElement = draggableElement.closest('[draggable="true"]');

		//Désactivation de la possibilté de drag & drop
		this.renderer.setAttribute(outerDraggableElement,'draggable','false');
	}

	/**
	 * Détection du survol (sortie) d'un champ de saisie (nécessaire à cause d'un bug Firefox sur les éléments '[draggable="true"] input')
	 */
	onMouseleave(draggableElement: HTMLElement) {
		let outerDraggableElement: HTMLElement;

		//Activation de la possibilté de drag & drop
		this.renderer.setAttribute(draggableElement,'draggable','true');

		//Récupération de l'élément déplaçable parent
		outerDraggableElement = draggableElement.closest('[draggable="false"]');

		//Activation de la possibilté de drag & drop
		this.renderer.setAttribute(outerDraggableElement,'draggable','true');
	}

	/**
	 * Vérification du formulaire
	 */
	isDirty(): boolean {
		//Comparaison des deux listes
		return !isEqual(this.templateMail.listeTemplateStructure.map(s => omit(s,['isToggled'])),this.savedTemplateMail.listeTemplateStructure);
	}

	/**
	 * Détection de l'appui sur la touche 'Entrée' dans le formulaire
	 */
	onKeydownEnter(event: KeyboardEvent) {
		//Vérification que l'appui a été effectué en dehors d'une pop-up summernote
		if ((event.target as HTMLElement)?.closest('summernote .note-modal') == null)
			//Annulation de l'événement
			event.preventDefault();
	}
}