import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';
import { AffectationSearchItemComponent } from './affectation.search-item';

/**
 * Options des affectations
 */
export default class AffectationOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.affectation';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Vehicule/filtreAffectations`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService,datePipe: DatePipe) { return item && (item?.user ? `${item.user.prenom} ${item.user.nom} - ${item.vehicule.reference}` : item?.vehicule ? item.vehicule.reference : '') + ' - ' + datePipe.transform(item.dateDebut,'short') + (item.dateFin ? ' - ' + datePipe.transform(item.dateFin,'short') : '') || ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return (item?.user?.nom?.substring(0,1)?.toUpperCase() || '') + (item?.user?.prenom?.substring(0,1)?.toUpperCase() || ''); }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.Affectation';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idAffectation'; }

	/**
	 * Composant d'affichage d'une ligne d'affectation dans la recherche avancée
	 */
	searchItemComponent?: () => any = () => AffectationSearchItemComponent;

	/**
	 * Tri par défaut
	 */
	defaultOrder = '-dateDebut,-dateFin';

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'user.prenom',
			isDefault: true
		},{
			clef: 'user.nom',
			isDefault: true
		},{
			clef: 'user.matricule',
			isDefault: true
		},{
			clef: 'vehicule.reference',
			isDefault: true
		}];
	}
}