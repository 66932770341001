<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addPlanComptable?.(data)">{{ data | display:'planComptable' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idPlanComptable">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.pays"><span translate>comptabilite.planComptable.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
		<li *ngIf="data.dateApplication"><span translate>comptabilite.planComptable.dateApplication</span>&#160;:&#160;<span>{{ data.dateApplication | date:'shortDate' }}</span></li>
		<li><span translate>{{ 'comptabilite.planComptable.compte' | pluralTranslate: data.extension?.nbComptes }}</span>&#160;:&#160;<span>{{ data.extension?.nbComptes || 0 }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idPlanComptable">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_PLAN_COMPTABLE | right:'suppression'"><a [routerLink]="[]" (click)="deletePlanComptable();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>