import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteCompteAuxiliaireEditComponent } from './compte-compte-auxiliaire-edit.component';
import { CompteCompteAuxiliaireListComponent } from './compte-compte-auxiliaire-list.component';
import { CompteEditComponent } from './compte-edit.component';
import { CompteFournisseurEditComponent } from './compte-fournisseur-edit.component';
import { CompteFournisseurListComponent } from './compte-fournisseur-list.component';
import { ComptePosteChargeEditComponent } from './compte-poste-charge-edit.component';
import { ComptePosteChargeListComponent } from './compte-poste-charge-list.component';
import { CompteSocieteEditComponent } from './compte-societe-edit.component';
import { CompteSocieteListComponent } from './compte-societe-list.component';
import { CompteTaxeEditComponent } from './compte-taxe-edit.component';
import { CompteTaxeListComponent } from './compte-taxe-list.component';
import { CompteUserEditComponent } from './compte-user-edit.component';
import { CompteUserListComponent } from './compte-user-list.component';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte',
	templateUrl: './compte.component.html'
})
export class CompteComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Compte courant **/
	public compte: any;

	/** Liste des règles de taxes */
	private listeReglesTaxe: any;

	/** Résumé */
	private resume: { nbPostesCharge: number,nbComptesAuxiliaires: number,nbFournisseurs: number,nbSocietes: number,nbTaux: number,nbUsers: number } = {
		nbPostesCharge: 0,
		nbComptesAuxiliaires: 0,
		nbFournisseurs: 0,
		nbSocietes: 0,
		nbTaux: 0,
		nbUsers: 0
	}

	/** Restriction sur le compte **/
	public restriction: { isRestricted: boolean } = null;

	/** Utilisateur connecté **/
	private user: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private store: Store<AppState>) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.comptabilite.Compte';

	/**
	 * Récupération de l'identifiant du compte à charger
	 */
	getIdObject: () => number = () => this.compte?.idCompte || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.compte;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => {
				//Définition de l'utilisateur connecté
				this.user = user;

				//Initialisation de la page complexe
				this.options = {
					listeFields: [{
						libelle: 'comptabilite.compte.numero',
						key: 'numero'
					},{
						libelle: 'comptabilite.compte.libelle',
						key: 'libelle'
					},{
						libelle: 'comptabilite.compte.type.item',
						key: (data) => this.translateService.instant(`comptabilite.compte.type.${data.type}`)
					},!this.rightService.isRoot() && {
						libelle: 'comptabilite.compte.planComptable',
						key: 'planComptable',
						type: 'DISPLAY',
						format: 'planComptable'
					},{
						libelle: 'comptabilite.compte.pays',
						key: 'pays',
						type: 'DISPLAY',
						format: 'pays'
					}].filter(f => !!f),
					listeActions: [{
						libelle: 'actions.modifier',
						doAction: () => this.editCompte(),
						isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
						type: TypeAction.EDITION
					},{
						libelle: 'actions.consulter',
						doAction: () => this.editCompte(),
						isVisible: () => !this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
						type: TypeAction.CONSULTATION
					},{
						libelle: 'comptabilite.compte.actions.accederPlanComptable',
						doAction: () => this.goToPlanComptable(),
						isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation') && !this.rightService.isRoot(),
						type: TypeAction.REDIRECTION
					},{
						libelle: 'actions.supprimer',
						doAction: () => this.deleteCompte(),
						isVisible: () => this.rightService.hasRight(null,'suppression') && !this.restriction?.isRestricted,
						type: TypeAction.SUPPRESSION
					}],
					listeAlertes: [{
						icon: 'info',
						title: this.translateService.instant('comptabilite.planComptable.alerte.restriction.title'),
						message: this.translateService.instant('comptabilite.planComptable.alerte.restriction.message'),
						isVisible: () => this.restriction?.isRestricted && !this.pageContentService.isOpened(),
						level: 'INFO'
					}],
					listeElements: [{
						type: 'SOCIETE',
						libelle: 'comptabilite.compte.elements.societe',
						component: CompteSocieteListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addSociete: this.addSociete.bind(this)
						}),
						doAction: () => this.addSociete(),
						count: () => this.resume.nbSocietes,
						libelleAction: this.translateService.instant('comptabilite.compte.elements.societe.ajouter'),
						isVisible: () => !this.rightService.isRoot() && this.compte?.type == 'BANQUE',
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
					},{
						type: 'POSTE_CHARGE',
						libelle: 'comptabilite.compte.elements.posteCharge',
						component: ComptePosteChargeListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addPosteCharge: this.addPosteCharge.bind(this)
						}),
						doAction: () => this.addPosteCharge(),
						count: () => this.resume.nbPostesCharge,
						libelleAction: this.translateService.instant('comptabilite.compte.elements.posteCharge.ajouter'),
						isVisible: () => this.compte?.type == 'CHARGE' || this.compte?.type == 'TAXE',
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
					},{
						type: 'COMPTE_AUXILIAIRE',
						libelle: 'comptabilite.compte.elements.compteAuxiliaire',
						component: CompteCompteAuxiliaireListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addCompteAuxiliaire: this.addCompteAuxiliaire.bind(this)
						}),
						doAction: () => this.addCompteAuxiliaire(),
						count: () => this.resume.nbComptesAuxiliaires,
						libelleAction: this.translateService.instant('comptabilite.compte.elements.compteAuxiliaire.ajouter'),
						isVisible: () => !this.rightService.isRoot() && this.compte?.type == 'TIERS',
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
					},{
						type: 'USER',
						libelle: 'comptabilite.compte.elements.user',
						component: CompteUserListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addUser: this.addUser.bind(this)
						}),
						doAction: () => this.addUser(),
						count: () => this.resume.nbUsers,
						libelleAction: this.translateService.instant('comptabilite.compte.elements.user.ajouter'),
						isVisible: () => !this.rightService.isRoot() && this.compte?.type == 'TIERS',
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
					},{
						type: 'FOURNISSEUR',
						libelle: 'comptabilite.compte.elements.fournisseur',
						component: CompteFournisseurListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addFournisseur: this.addFournisseur.bind(this)
						}),
						doAction: () => this.addFournisseur(),
						count: () => this.resume.nbFournisseurs,
						libelleAction: this.translateService.instant('comptabilite.compte.elements.fournisseur.ajouter'),
						isVisible: () => !this.rightService.isRoot() && this.compte?.type == 'TIERS',
						isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted
					},{
						type: 'TAXE',
						libelle: 'comptabilite.compte.elements.taxe',
						component: CompteTaxeListComponent,
						retrieveComponentData: () => ({
							compte: this.compte,
							resume: this.resume,
							restriction: this.restriction,
							addTaxe: this.addTaxe.bind(this)
						}),
						count: () => this.resume.nbTaux,
						isVisible: () => !this.rightService.isRoot() && this.compte?.type == 'TAXE'
					}]
				};

				//Récupération de l'identifiant du compte à charger
				this.activatedRoute.params.pipe(first()).subscribe({
					next: params => {
						//Chargement des données
						this.loadData(params.idCompte);
					}
				});
			}
		});
	}

	/**
	 * Redirection vers le plan comptable
	 */
	private goToPlanComptable() {
		//Navigation vers le plan comptable
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: this.compte.planComptable.idPlanComptable
			},
			withGoBack: true
		});
	}

	/**
	 * Edition du compte
	 */
	private editCompte() {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteEditComponent,{
			data: {
				compte: cloneDeep(this.compte),
				deleteCompte: this.deleteCompte.bind(this)
			}
		}).subscribe({
			next: (compte: any) => {
				let hasTypeChanged: boolean;

				//Vérification des données
				if (compte) {
					//Vérification du changement de type
					hasTypeChanged = this.compte.type && this.compte.type !== compte.type;

					//Mise à jour du compte
					this.compte = Object.assign(this.compte,compte);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.compte);

					//Rafraichissement de l'élément selectionné (lors du changement de type)
					hasTypeChanged && this.complexPage.setSelectedElementByType(this.options.listeElements?.find(e => e?.isVisible() || true)?.type);
				}
			}
		});
	}

	/**
	 * Suppression du compte
	 */
	private deleteCompte() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteService.deleteCompte(this.compte))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idCompte: number = this.compte.idCompte): Subject<void> {
		let subject: Subject<void> = new Subject<void>();

		//Enregistrement du compte
		this.compteService.loadCompte(idCompte).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du compte
					this.compte = result.data?.compte;

					//Définition de la listes des règles de taxe
					this.listeReglesTaxe = result?.data?.listeReglesTaxe || [];

					//Définition du résumé
					this.resume = {
						nbPostesCharge: result.data?.nbPostesCharge || 0,
						nbComptesAuxiliaires: result.data?.nbComptesAuxiliaires || 0,
						nbFournisseurs: result.data?.nbFournisseurs || 0,
						nbSocietes: result.data?.nbSocietes || 0,
						nbTaux: result.data?.nbTaux || 0,
						nbUsers: result.data?.nbUsers || 0
					}

					//Définition de la restriction
					this.restriction = this.compteService.getRestriction(this.compte?.planComptable?.listeSocietes);

					//Vérification de l'identifiant du compte
					if (!this.compte?.idCompte) {
						//Création d'un objet vierge
						this.compte = {
							pays: this.user?.pays
						};

						//Edition du compte
						this.editCompte();
					}
				}

				//Notification du traitement
				subject.next();
			},
			complete: () => {
				//Fermeture
				subject.complete();
			}
		});

		//Retour du sujet
		return subject;
	}

	/**
	 * Modification de la société
	 */
	private addSociete(lienSociete: any = { compte: Object.assign({},this.compte),type: 'SOCIETE' }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteSocieteEditComponent,{
			data: {
				lienSociete: cloneDeep(lienSociete)
			}
		},'sub').subscribe({
			next: (data: { lienSociete: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('SOCIETE',lienSociete.idLien != data.lienSociete?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienSociete.idLien && data.lienSociete?.idLien)
						//Incrémentation du compteur
						this.resume.nbSocietes++;
					else if (lienSociete.idLien && !data.lienSociete?.idLien)
						//Décrémentation du compteur
						this.resume.nbSocietes--;

					//Mise à jour du lien
					lienSociete = Object.assign(lienSociete,data.lienSociete);
				}
			}
		});
	}

	/**
	 * Modification du poste de charge
	 */
	private addPosteCharge(lienPosteCharge: any = { compte: Object.assign({},this.compte),type: 'POSTE_CHARGE' },deleteLienPosteCharge?: Function) {
		let showContentPage: Function;

		//Définition de la fonction
		showContentPage = () => {
			//Ouverture du composant d'édition
			this.pageContentService.open(ComptePosteChargeEditComponent,{
				data: {
					lienPosteCharge: cloneDeep(lienPosteCharge),
					listeReglesTaxe: cloneDeep(this.listeReglesTaxe),
					deleteLienPosteCharge
				}
			},'sub').subscribe({
				next: (data: { lienPosteCharge: any }) => {
					//Vérification de la présence de données
					if (data) {
						//Affichage de l'onglet (si nécessaire)
						this.complexPage.setSelectedElementByType('POSTE_CHARGE',lienPosteCharge.idLien != data.lienPosteCharge?.idLien);

						//Vérification de la présence d'une création ou d'une suppression
						if (!lienPosteCharge.idLien && data.lienPosteCharge?.idLien)
							//Incrémentation du compteur
							this.resume.nbPostesCharge++;
						else if (lienPosteCharge.idLien && !data.lienPosteCharge?.idLien)
							//Décrémentation du compteur
							this.resume.nbPostesCharge--;

						//Mise à jour du lien
						lienPosteCharge = Object.assign(lienPosteCharge,data.lienPosteCharge);
					}
				}
			});
		}

		//Vérification de l'absence d'une liste de règles de taxe
		if (this.listeReglesTaxe === undefined) {
			//Chargement des données
			this.loadData().subscribe({
				complete: () => showContentPage()
			});
		} else
			//Execution de la fonction
			showContentPage();
	}

	/**
	 * Modification du compte auxiliaire
	 */
	private addCompteAuxiliaire(compteAuxiliaire: any = { compte: Object.assign({},this.compte),pays: this.compte.pays,planComptable: this.compte.planComptable }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteCompteAuxiliaireEditComponent,{
			data: {
				compteAuxiliaire: cloneDeep(compteAuxiliaire)
			}
		},'sub').subscribe({
			next: (data: { compteAuxiliaire: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('COMPTE_AUXILIAIRE',compteAuxiliaire.idCompteAuxiliaire != data.compteAuxiliaire?.idCompteAuxiliaire);

					//Vérification de la présence d'une création ou d'une suppression
					if (!compteAuxiliaire.idCompteAuxiliaire && data.compteAuxiliaire?.idCompteAuxiliaire)
						//Incrémentation du compteur
						this.resume.nbComptesAuxiliaires++;
					else if (compteAuxiliaire.idCompteAuxiliaire && !data.compteAuxiliaire?.idCompteAuxiliaire)
						//Décrémentation du compteur
						this.resume.nbComptesAuxiliaires--;

					//Mise à jour du compte auxiliaire
					compteAuxiliaire = Object.assign(compteAuxiliaire,data.compteAuxiliaire);
				}
			}
		});
	}

	/**
	 * Modification du fournisseur
	 */
	private addFournisseur(lienFournisseur: any = { compte: Object.assign({},this.compte),type: 'FOURNISSEUR' },deleteLienFournisseur?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteFournisseurEditComponent,{
			data: {
				lienFournisseur: cloneDeep(lienFournisseur),
				deleteLienFournisseur
			}
		},'sub').subscribe({
			next: (data: { lienFournisseur: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FOURNISSEUR',lienFournisseur.idLien != data.lienFournisseur?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienFournisseur.idLien && data.lienFournisseur?.idLien)
						//Incrémentation du compteur
						this.resume.nbFournisseurs++;
					else if (lienFournisseur.idLien && !data.lienFournisseur?.idLien)
						//Décrémentation du compteur
						this.resume.nbFournisseurs--;

					//Mise à jour du lien
					lienFournisseur = Object.assign(lienFournisseur,data.lienFournisseur);
				}
			}
		});
	}

	/**
	 * Modification du collaborateur
	 */
	private addUser(lienUser: any = { compte: Object.assign({},this.compte),type: 'USER' },deleteLienUser?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteUserEditComponent,{
			data: {
				lienUser: cloneDeep(lienUser),
				deleteLienUser
			}
		},'sub').subscribe({
			next: (data: { lienUser: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('USER',lienUser.idLien != data.lienUser?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienUser.idLien && data.lienUser?.idLien)
						//Incrémentation du compteur
						this.resume.nbUsers++;
					else if (lienUser.idLien && !data.lienUser?.idLien)
						//Décrémentation du compteur
						this.resume.nbUsers--;

					//Mise à jour du lienUser
					lienUser = Object.assign(lienUser,data.lienUser);
				}
			}
		});
	}

	/**
	 * Modification de la taxe
	 */
	private addTaxe(taux: any) {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteTaxeEditComponent,{
			data: {
				taux: cloneDeep(taux)
			}
		},'sub').subscribe();
	}
}