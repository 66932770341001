<form #codeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!code.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot()">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>fournisseur.code.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="code.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>fournisseur.code.reference</label>
							<div>
								<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="code.reference" maxlength="100" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': interfaceFournisseur.invalid }">
							<label translate>fournisseur.code.formatInterface</label>
							<div>
								<autocomplete name="interfaceFournisseur" type="fournisseurInterface" #interfaceFournisseur="ngModel" [(ngModel)]="code.interfaceFournisseur" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': nature.invalid }">
							<label translate>fournisseur.code.nature</label>
							<div [ngClass]="{ 'input-group': code.naturePrediction?.listePredictions?.length }">
								<autocomplete name="nature" type="fournisseurNature" #nature="ngModel" [(ngModel)]="code.nature" required></autocomplete>
								<div class="input-group-addon" *ngIf="code.naturePrediction?.listePredictions?.length">
									<ng-template #predictionTooltipTemplate>
										<div style="text-align: left;"><strong translate>fournisseur.code.predictions</strong></div>
										<div style="text-align: left;" class="p-t-10">
											<div *ngFor="let prediction of code.naturePrediction.listePredictions"><strong>{{ prediction.value }}</strong>&#160;:&#160;<span>{{ prediction.score | number:'.2-2' }}</span></div>
										</div>
									</ng-template>
									<icon name="info_outline" [tooltip]="predictionTooltipTemplate"></icon>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>fournisseur.code.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="code.remarque" rows="3"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: codeForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<ng-container *ngIf="rightService.isRoot()">
				<button mat-stroked-button color="primary" (click)="deleteCode();" *ngIf="code?.idCode && (null | right:'suppression')">
					<span translate>actions.supprimer</span>
				</button>
				<button mat-raised-button color="primary" (click)="saveCode();" *ngIf="null | right:'creation'" [disabled]="codeForm.invalid">
					<span translate>actions.enregistrer</span>
				</button>
			</ng-container>
		</div>
	</div>
</form>