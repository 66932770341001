import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { OptionService } from './option.service';

@Component({
	templateUrl: './option.component.html'
})
export class OptionComponent implements OnInit {
	/** Critère de recherche du modèle **/
	@Input() searchSpec: any;

	/** Etat de synchronisation des options **/
	public isOptionsSynced: boolean = false;

	/** Liste des détails **/
	public listeDetails: Array<any> = null;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<OptionComponent>,private optionService: OptionService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du modèle pour les critères de recherche
		this.optionService.filtreModeles(this.searchSpec).subscribe({
			next: result => {
				//Vérification du modèle
				if (result?.content?.[0]) {
					//Synchronisation des options
					this.optionService.synchronizeOptions(result.content[0].idModele).subscribe({
						next: (isSynced: boolean) => {
							//Chargement des options du modèle
							this.optionService.loadModeleOptions(result.content[0].idModele).subscribe({
								next: result => {
									//Définition de la liste des détails
									this.listeDetails = result.content;
								}
							});

							//Définition de l'état de la synchronisation des options
							this.isOptionsSynced = isSynced;
						}
					});
				}
			}
		});
	}
}
