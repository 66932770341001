import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { CompteService } from './compte.service';

@Component({
	templateUrl: './compte-list-item.component.html'
})
export class CompteListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(public compteService: CompteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Suppression du compte collectif
	 */
	deleteCompte() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du compte collectif
					this.compteService.deleteCompte(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du compte collectif
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.comptabilite.Compte',this.data.idCompte);
	}

	/**
	 * Redirection vers le plan comptable
	 */
	goToPlanComptable() {
		//Navigation vers le plan comptable
		this.layoutService.goToByState('comptabiliteReferentiel-listePlanComptables-planComptable',{
			routeParams: {
				idPlanComptable: this.data.planComptable.idPlanComptable
			},
			withGoBack: true
		});
	}
}