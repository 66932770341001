/* eslint-disable */
/* jscs: disable */
'use strict';

// pulled specific shims from https://github.com/es-shims/es5-shim
var ArrayPrototype = Array.prototype;
var ObjectPrototype = Object.prototype;
var FunctionPrototype = Function.prototype;
var StringPrototype = String.prototype;
var array_slice = ArrayPrototype.slice;
var _toString = ObjectPrototype.toString;
var isFunction = function (val) {
  return ObjectPrototype.toString.call(val) === '[object Function]';
};
var isArray = function isArray(obj) {
  return _toString.call(obj) === '[object Array]';
};
var isString = function isString(obj) {
  return _toString.call(obj) === '[object String]';
};
var supportsDescriptors = Object.defineProperty && function () {
  try {
    Object.defineProperty({}, 'x', {});
    return true;
  } catch (e) {
    /* this is ES3 */
    return false;
  }
}();

// Define configurable, writable and non-enumerable props
// if they don't exist.
var defineProperty;
if (supportsDescriptors) {
  defineProperty = function (object, name, method, forceAssign) {
    if (!forceAssign && name in object) {
      return;
    }
    Object.defineProperty(object, name, {
      configurable: true,
      enumerable: false,
      writable: true,
      value: method
    });
  };
} else {
  defineProperty = function (object, name, method, forceAssign) {
    if (!forceAssign && name in object) {
      return;
    }
    object[name] = method;
  };
}
var defineProperties = function (object, map, forceAssign) {
  for (var name in map) {
    if (ObjectPrototype.hasOwnProperty.call(map, name)) {
      defineProperty(object, name, map[name], forceAssign);
    }
  }
};
var toObject = function (o) {
  if (o == null) {
    // this matches both null and undefined
    throw new TypeError("can't convert " + o + ' to object');
  }
  return Object(o);
};

//
// Util
// ======
//

// ES5 9.4
// http://es5.github.com/#x9.4
// http://jsperf.com/to-integer

function toInteger(num) {
  var n = +num;
  if (n !== n) {
    // isNaN
    n = 0;
  } else if (n !== 0 && n !== 1 / 0 && n !== -(1 / 0)) {
    n = (n > 0 || -1) * Math.floor(Math.abs(n));
  }
  return n;
}
function ToUint32(x) {
  return x >>> 0;
}

//
// Function
// ========
//

// ES-5 15.3.4.5
// http://es5.github.com/#x15.3.4.5

function Empty() {}
defineProperties(FunctionPrototype, {
  bind: function bind(that) {
    // .length is 1
    // 1. Let Target be the this value.
    var target = this;
    // 2. If IsCallable(Target) is false, throw a TypeError exception.
    if (!isFunction(target)) {
      throw new TypeError('Function.prototype.bind called on incompatible ' + target);
    }
    // 3. Let A be a new (possibly empty) internal list of all of the
    //   argument values provided after thisArg (arg1, arg2 etc), in order.
    // XXX slicedArgs will stand in for "A" if used
    var args = array_slice.call(arguments, 1); // for normal call
    // 4. Let F be a new native ECMAScript object.
    // 11. Set the [[Prototype]] internal property of F to the standard
    //   built-in Function prototype object as specified in 15.3.3.1.
    // 12. Set the [[Call]] internal property of F as described in
    //   15.3.4.5.1.
    // 13. Set the [[Construct]] internal property of F as described in
    //   15.3.4.5.2.
    // 14. Set the [[HasInstance]] internal property of F as described in
    //   15.3.4.5.3.
    var binder = function () {
      if (this instanceof bound) {
        // 15.3.4.5.2 [[Construct]]
        // When the [[Construct]] internal method of a function object,
        // F that was created using the bind function is called with a
        // list of arguments ExtraArgs, the following steps are taken:
        // 1. Let target be the value of F's [[TargetFunction]]
        //   internal property.
        // 2. If target has no [[Construct]] internal method, a
        //   TypeError exception is thrown.
        // 3. Let boundArgs be the value of F's [[BoundArgs]] internal
        //   property.
        // 4. Let args be a new list containing the same values as the
        //   list boundArgs in the same order followed by the same
        //   values as the list ExtraArgs in the same order.
        // 5. Return the result of calling the [[Construct]] internal
        //   method of target providing args as the arguments.

        var result = target.apply(this, args.concat(array_slice.call(arguments)));
        if (Object(result) === result) {
          return result;
        }
        return this;
      } else {
        // 15.3.4.5.1 [[Call]]
        // When the [[Call]] internal method of a function object, F,
        // which was created using the bind function is called with a
        // this value and a list of arguments ExtraArgs, the following
        // steps are taken:
        // 1. Let boundArgs be the value of F's [[BoundArgs]] internal
        //   property.
        // 2. Let boundThis be the value of F's [[BoundThis]] internal
        //   property.
        // 3. Let target be the value of F's [[TargetFunction]] internal
        //   property.
        // 4. Let args be a new list containing the same values as the
        //   list boundArgs in the same order followed by the same
        //   values as the list ExtraArgs in the same order.
        // 5. Return the result of calling the [[Call]] internal method
        //   of target providing boundThis as the this value and
        //   providing args as the arguments.

        // equiv: target.call(this, ...boundArgs, ...args)
        return target.apply(that, args.concat(array_slice.call(arguments)));
      }
    };

    // 15. If the [[Class]] internal property of Target is "Function", then
    //     a. Let L be the length property of Target minus the length of A.
    //     b. Set the length own property of F to either 0 or L, whichever is
    //       larger.
    // 16. Else set the length own property of F to 0.

    var boundLength = Math.max(0, target.length - args.length);

    // 17. Set the attributes of the length own property of F to the values
    //   specified in 15.3.5.1.
    var boundArgs = [];
    for (var i = 0; i < boundLength; i++) {
      boundArgs.push('$' + i);
    }

    // XXX Build a dynamic function with desired amount of arguments is the only
    // way to set the length property of a function.
    // In environments where Content Security Policies enabled (Chrome extensions,
    // for ex.) all use of eval or Function costructor throws an exception.
    // However in all of these environments Function.prototype.bind exists
    // and so this code will never be executed.
    var bound = Function('binder', 'return function (' + boundArgs.join(',') + '){ return binder.apply(this, arguments); }')(binder);
    if (target.prototype) {
      Empty.prototype = target.prototype;
      bound.prototype = new Empty();
      // Clean up dangling references.
      Empty.prototype = null;
    }

    // TODO
    // 18. Set the [[Extensible]] internal property of F to true.

    // TODO
    // 19. Let thrower be the [[ThrowTypeError]] function Object (13.2.3).
    // 20. Call the [[DefineOwnProperty]] internal method of F with
    //   arguments "caller", PropertyDescriptor {[[Get]]: thrower, [[Set]]:
    //   thrower, [[Enumerable]]: false, [[Configurable]]: false}, and
    //   false.
    // 21. Call the [[DefineOwnProperty]] internal method of F with
    //   arguments "arguments", PropertyDescriptor {[[Get]]: thrower,
    //   [[Set]]: thrower, [[Enumerable]]: false, [[Configurable]]: false},
    //   and false.

    // TODO
    // NOTE Function objects created using Function.prototype.bind do not
    // have a prototype property or the [[Code]], [[FormalParameters]], and
    // [[Scope]] internal properties.
    // XXX can't delete prototype in pure-js.

    // 22. Return F.
    return bound;
  }
});

//
// Array
// =====
//

// ES5 15.4.3.2
// http://es5.github.com/#x15.4.3.2
// https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/isArray
defineProperties(Array, {
  isArray: isArray
});
var boxedString = Object('a');
var splitString = boxedString[0] !== 'a' || !(0 in boxedString);
var properlyBoxesContext = function properlyBoxed(method) {
  // Check node 0.6.21 bug where third parameter is not boxed
  var properlyBoxesNonStrict = true;
  var properlyBoxesStrict = true;
  if (method) {
    method.call('foo', function (_, __, context) {
      if (typeof context !== 'object') {
        properlyBoxesNonStrict = false;
      }
    });
    method.call([1], function () {
      'use strict';

      properlyBoxesStrict = typeof this === 'string';
    }, 'x');
  }
  return !!method && properlyBoxesNonStrict && properlyBoxesStrict;
};
defineProperties(ArrayPrototype, {
  forEach: function forEach(fun /*, thisp*/) {
    var object = toObject(this),
      self = splitString && isString(this) ? this.split('') : object,
      thisp = arguments[1],
      i = -1,
      length = self.length >>> 0;

    // If no callback function or if callback is not a callable function
    if (!isFunction(fun)) {
      throw new TypeError(); // TODO message
    }
    while (++i < length) {
      if (i in self) {
        // Invoke the callback function with call, passing arguments:
        // context, property value, property key, thisArg object
        // context
        fun.call(thisp, self[i], i, object);
      }
    }
  }
}, !properlyBoxesContext(ArrayPrototype.forEach));

// ES5 15.4.4.14
// http://es5.github.com/#x15.4.4.14
// https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Array/indexOf
var hasFirefox2IndexOfBug = Array.prototype.indexOf && [0, 1].indexOf(1, 2) !== -1;
defineProperties(ArrayPrototype, {
  indexOf: function indexOf(sought /*, fromIndex */) {
    var self = splitString && isString(this) ? this.split('') : toObject(this),
      length = self.length >>> 0;
    if (!length) {
      return -1;
    }
    var i = 0;
    if (arguments.length > 1) {
      i = toInteger(arguments[1]);
    }

    // handle negative indices
    i = i >= 0 ? i : Math.max(0, length + i);
    for (; i < length; i++) {
      if (i in self && self[i] === sought) {
        return i;
      }
    }
    return -1;
  }
}, hasFirefox2IndexOfBug);

//
// String
// ======
//

// ES5 15.5.4.14
// http://es5.github.com/#x15.5.4.14

// [bugfix, IE lt 9, firefox 4, Konqueror, Opera, obscure browsers]
// Many browsers do not split properly with regular expressions or they
// do not perform the split correctly under obscure conditions.
// See http://blog.stevenlevithan.com/archives/cross-browser-split
// I've tested in many browsers and this seems to cover the deviant ones:
//    'ab'.split(/(?:ab)*/) should be ["", ""], not [""]
//    '.'.split(/(.?)(.?)/) should be ["", ".", "", ""], not ["", ""]
//    'tesst'.split(/(s)*/) should be ["t", undefined, "e", "s", "t"], not
//       [undefined, "t", undefined, "e", ...]
//    ''.split(/.?/) should be [], not [""]
//    '.'.split(/()()/) should be ["."], not ["", "", "."]

var string_split = StringPrototype.split;
if ('ab'.split(/(?:ab)*/).length !== 2 || '.'.split(/(.?)(.?)/).length !== 4 || 'tesst'.split(/(s)*/)[1] === 't' || 'test'.split(/(?:)/, -1).length !== 4 || ''.split(/.?/).length || '.'.split(/()()/).length > 1) {
  (function () {
    var compliantExecNpcg = /()??/.exec('')[1] === void 0; // NPCG: nonparticipating capturing group

    StringPrototype.split = function (separator, limit) {
      var string = this;
      if (separator === void 0 && limit === 0) {
        return [];
      }

      // If `separator` is not a regex, use native split
      if (_toString.call(separator) !== '[object RegExp]') {
        return string_split.call(this, separator, limit);
      }
      var output = [],
        flags = (separator.ignoreCase ? 'i' : '') + (separator.multiline ? 'm' : '') + (separator.extended ? 'x' : '') + (
        // Proposed for ES6
        separator.sticky ? 'y' : ''),
        // Firefox 3+
        lastLastIndex = 0,
        // Make `global` and avoid `lastIndex` issues by working with a copy
        separator2,
        match,
        lastIndex,
        lastLength;
      separator = new RegExp(separator.source, flags + 'g');
      string += ''; // Type-convert
      if (!compliantExecNpcg) {
        // Doesn't need flags gy, but they don't hurt
        separator2 = new RegExp('^' + separator.source + '$(?!\\s)', flags);
      }
      /* Values for `limit`, per the spec:
       * If undefined: 4294967295 // Math.pow(2, 32) - 1
       * If 0, Infinity, or NaN: 0
       * If positive number: limit = Math.floor(limit); if (limit > 4294967295) limit -= 4294967296;
       * If negative number: 4294967296 - Math.floor(Math.abs(limit))
       * If other: Type-convert, then use the above rules
       */
      limit = limit === void 0 ? -1 >>> 0 :
      // Math.pow(2, 32) - 1
      ToUint32(limit);
      while (match = separator.exec(string)) {
        // `separator.lastIndex` is not reliable cross-browser
        lastIndex = match.index + match[0].length;
        if (lastIndex > lastLastIndex) {
          output.push(string.slice(lastLastIndex, match.index));
          // Fix browsers whose `exec` methods don't consistently return `undefined` for
          // nonparticipating capturing groups
          if (!compliantExecNpcg && match.length > 1) {
            match[0].replace(separator2, function () {
              for (var i = 1; i < arguments.length - 2; i++) {
                if (arguments[i] === void 0) {
                  match[i] = void 0;
                }
              }
            });
          }
          if (match.length > 1 && match.index < string.length) {
            ArrayPrototype.push.apply(output, match.slice(1));
          }
          lastLength = match[0].length;
          lastLastIndex = lastIndex;
          if (output.length >= limit) {
            break;
          }
        }
        if (separator.lastIndex === match.index) {
          separator.lastIndex++; // Avoid an infinite loop
        }
      }
      if (lastLastIndex === string.length) {
        if (lastLength || !separator.test('')) {
          output.push('');
        }
      } else {
        output.push(string.slice(lastLastIndex));
      }
      return output.length > limit ? output.slice(0, limit) : output;
    };
  })();

  // [bugfix, chrome]
  // If separator is undefined, then the result array contains just one String,
  // which is the this value (converted to a String). If limit is not undefined,
  // then the output array is truncated so that it contains no more than limit
  // elements.
  // "0".split(undefined, 0) -> []
} else if ('0'.split(void 0, 0).length) {
  StringPrototype.split = function split(separator, limit) {
    if (separator === void 0 && limit === 0) {
      return [];
    }
    return string_split.call(this, separator, limit);
  };
}

// ECMA-262, 3rd B.2.3
// Not an ECMAScript standard, although ECMAScript 3rd Edition has a
// non-normative section suggesting uniform semantics and it should be
// normalized across all browsers
// [bugfix, IE lt 9] IE < 9 substr() with negative value not working in IE
var string_substr = StringPrototype.substr;
var hasNegativeSubstrBug = ''.substr && '0b'.substr(-1) !== 'b';
defineProperties(StringPrototype, {
  substr: function substr(start, length) {
    return string_substr.call(this, start < 0 ? (start = this.length + start) < 0 ? 0 : start : start, length);
  }
}, hasNegativeSubstrBug);