import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReleveEnergieService {
	/** Liste des types de source **/
	private readonly listeTypesSource: Array<string> = ['COLLABORATEUR','FOURNISSEUR'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) {

	}

	/**
	 * Récupération de la liste des types de source
	 */
	public getListeTypesSource(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de source
		return this.listeTypesSource.map(code => ({
			code,
			libelle: this.translateService.instant(`vehicule.releveEnergie.typeSource.${code}`)
		}));
	}

	/**
	 * Chargement d'un relevé d'énergie
	 */
	public loadReleveEnergie(idReleve: number): Observable<Result> {
		//Chargement du relevé d'énergie
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReleveEnergie/loadReleveEnergie/${idReleve}`,null);
	}

	/**
	 * Enregistrement d'un relevé d'énergie
	 */
	public saveReleveEnergie(releveEnergie: any): Observable<Result> {
		//Enregistrement du relevé d'énergie
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReleveEnergie/saveReleveEnergie`,releveEnergie);
	}

	/**
	 * Suppression d'un relevé d'énergie
	 */
	public deleteReleveEnergie(releveEnergie: any): Observable<Result> {
		//Suppression du relevé d'énergie
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReleveEnergie/deleteReleveEnergie/${releveEnergie.idReleve}`);
	}
}