import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './interceptors/auth-interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token-interceptor';
import { ForbiddenRequestInterceptor } from './interceptors/forbidden-request-interceptor';
import { FirewallInterceptor } from './interceptors/firewall-interceptor';
import { BadRequestInterceptor } from './interceptors/bad-request-interceptor';
import { ConcurrentAccessInterceptor } from './interceptors/concurrent-access-interceptor';
import { LoaderInterceptor } from './interceptors/loader-interceptor';
import { EndToEndTestRequestInterceptor } from './interceptors/e2e-test-request-interceptor';
import { RedirectInterceptor } from './interceptors/redirect-interceptor';

/** Export des intercepteurs de façon ordonnée (FILO pour la requête - LIFO pour la réponse) **/
export const httpInterceptorProviders = [{
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: RefreshTokenInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: ForbiddenRequestInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: FirewallInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: BadRequestInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: ConcurrentAccessInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: LoaderInterceptor,
	multi: true
},sessionStorage?.getItem?.('loadInterceptor') && {
	provide: HTTP_INTERCEPTORS,
	useClass: EndToEndTestRequestInterceptor,
	multi: true
},sessionStorage?.getItem?.('redirected') && {
	provide: HTTP_INTERCEPTORS,
	useClass: RedirectInterceptor,
	multi: true
}].filter(i => !!i);