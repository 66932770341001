<form #factureAnalysisAssociationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>facture.lad.association.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<p class="m-b-10" translate>facture.lad.association.description</p>
			<div class="p-l-40">
				<div class="form-group" *ngIf="association.fournisseur">
					<div>
						<mat-checkbox name="fournisseur" #fournisseur="ngModel" [(ngModel)]="options.fournisseurAssociation">
							<span [innerHTML]="'facture.lad.association.fournisseur' | translate: { code: factureAnalysis?.vendor,fournisseur: factureAnalysis?.facture?.fournisseur?.libelle }"></span>
						</mat-checkbox>
					</div>
				</div>
				<div class="form-group" *ngIf="association.societe">
					<div>
						<mat-checkbox name="societe" #societe="ngModel" [(ngModel)]="options.societeAssociation">
							<span [innerHTML]="'facture.lad.association.societe' | translate: { code: factureAnalysis?.clientName,societe: factureAnalysis?.facture?.societe?.libelle }"></span>
						</mat-checkbox>
					</div>
				</div>
				<div class="form-group" *ngIf="association.nature">
					<div>
						<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="options.natureAssociation">
							<span [innerHTML]="'facture.lad.association.nature' | translate: { code: factureAnalysis?.natureLAD?.libelle,fournisseur: factureAnalysis?.facture?.fournisseur?.libelle }"></span>
						</mat-checkbox>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-raised-button color="primary" (click)="saveFactureAnalysisAssociation()" [disabled]="factureAnalysisAssociationForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>