import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GroupeListItemComponent } from './groupe-list-item.component';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { mapEntites } from './groupe.entites';

@Component({
	templateUrl: './groupe-list.component.html'
})
export class GroupeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,GroupeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public extractionService: ExtractionService) {}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,GroupeListItemComponent>({
			uri: `/controller/Groupe/filtreGroupes`,
			title: this.translateService.instant('groupe.liste.title'),
			component: GroupeListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'entity',
				title: this.translateService.instant('search.entite'),
				type: TypeFilter.STRING,
				listeValues: this.extractionService.getListeEntites()
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Groupe/loadGroupe/0')),
			getKeyFieldName: () => 'idGroupe'
		});
	}
}