import { AfterContentChecked,ChangeDetectorRef,Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'facture-analysis-association',
	templateUrl: './facture-analysis-association.component.html'
})
export class FactureAnalysisAssociationComponent implements AfterContentChecked {
	/** Facture analysée **/
	@Input() factureAnalysis: any;

	/** Options d'affichage en entrée */
	@Input() association: { fournisseur?: boolean,societe?: boolean,nature?: boolean }

	/** Résultat **/
	result: { options: { fournisseurAssociation: boolean,societeAssociation: boolean,natureAssociation: boolean } };

	/** Options choisies */
	public options: { fournisseurAssociation: boolean,societeAssociation: boolean,natureAssociation: boolean } = { fournisseurAssociation: false,societeAssociation: false,natureAssociation: false };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<FactureAnalysisAssociationComponent>) {

	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement des choix effectués
	 */
	public saveFactureAnalysisAssociation() {
		//Définition du résultat
		this.result = {
			options: this.options
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}