import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { AffectationService } from './affectation.service';
import { AffectationListComponent } from './affectation-list.component';
import { AffectationListItemComponent } from './affectation-list-item.component';
import { AffectationComponent } from './affectation.component';
import { AffectationContentComponent } from './affectation-content.component';
import { AffectationHistoryListComponent } from './affectation-history-list.component';
import { AffectationHistoryListItemComponent } from './affectation-history-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [AffectationListComponent,AffectationListItemComponent,AffectationComponent,AffectationContentComponent,AffectationHistoryListComponent,AffectationHistoryListItemComponent],
	exports: [AffectationContentComponent],
	providers: [AffectationService]
})
export class AffectationModule {}