<div class="pull-left lv-avatar">S</div>
<div class="media-body">
	<div class="lv-title">
		<span *ngIf="item.libelle" translate [translateParams]="{ libelle: item.libelle }">profil.dashboard.libelle</span>
		<span *ngIf="!item.libelle" translate>profil.dashboard.libelleGeneral</span>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="item.profil && item.listeDashboardCharts?.length"><span translate [translateParams]="{ nb: item.listeDashboardCharts?.length }">profil.dashboard.elementsDefinis</span></li>
		<li *ngIf="item.profil && !item.listeDashboardCharts?.length"><span translate>profil.dashboard.aucunDashboard</span></li>
		<li *ngIf="!item.profil"><span translate>profil.dashboard.aucunePersonnalisation</span></li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(null | right:'suppression') || (null | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="showDashboard();" translate>actions.modifier</a></li>
			<li *ngIf="(null | right:'creation') && item.profil"><a [routerLink]="[]" (click)="resetProfilDashboard();" translate>actions.reinitialiser</a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteProfilDashboard();" translate>actions.supprimer</a></li>
		</ul>
	</div>
</div>