import { Component,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { AccountNotificationListItemComponent } from './account-notification-list-item.component';

@Component({
	templateUrl: './account-notification-list.component.html'
})
export class AccountNotificationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,AccountNotificationListItemComponent>;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,AccountNotificationListItemComponent>({
			uri: () => `/controller/Notification/filtreUserSchedulers`,
			component: AccountNotificationListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			isLocal: true
		});
	}
}