import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { xorWith } from 'lodash-es';
import { Observable,of } from 'rxjs';
import { first,map,tap } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { Filter } from 'src/app/domain/common/list-view/filter';
import { environment } from 'src/environments/environment';

@Injectable()
export class RegleTaxeService {
	/** Liste des types **/
	private listeTypes: Array<string> = ['DATE','DATE_GENRE'];

	/**  Map des règles de récupération de taxe par pays **/
	private mapReglesTaxeForPays: { [idPays: number]: Array<any> } = {};

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private http: HttpClient) { }

	/**
	 * Récupération de la liste des types traduits
	 */
	getListeTypes() {
		//Retour de la liste des types traduits
		return this.listeTypes.map(code => ({
			code,
			libelle: this.translateService.instant('fiscalite.regleTaxe.type.'+code)
		}));
	}

	/**
	 * Chargement de la règle de récupération de taxe
	 */
	public loadRegleTaxe(idRegle: number): Observable<Result> {
		//Chargement de la règle de récupération de taxe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Fiscalite/loadRegleTaxe/${idRegle}`,null);
	}

	/**
	 * Suppression de la règle de récupération de taxe
	 */
	public deleteRegleTaxe(regleTaxe: any): Observable<Result> {
		//Suppression de la règle de récupération de taxe
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fiscalite/deleteRegleTaxe/${regleTaxe.idRegle}`);
	}

	/**
	 * Enregistrement de la règle de récupération de taxe
	 */
	public saveRegleTaxe(regleTaxe: any): Observable<Result> {
		//Enregistrement de la règle de récupération de taxe
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fiscalite/saveRegleTaxe`,regleTaxe);
	}

	/**
	 * Liste des postes de charges par règle
	 */
	public filtrePostesChargeByRegle(searchSpec: { listeFilter?: Array<Filter>,defaultSearch?: string }): Observable<Array<any>> {
		//Chargement de la règle de récupération de taxe
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Fiscalite/filtrePostesChargeByRegle`,searchSpec).pipe(
			map(page => page?.content || [])
		);
	}

	/**
	 * Suppression du lien avec un poste de charge
	 */
	public deleteLienRegleTaxeDetail(lienRegleTaxeDetail: any): Observable<Result> {
		//Suppression du lien avec un poste de charge
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fiscalite/deleteLienRegleTaxeDetail/${lienRegleTaxeDetail.idLien}`);
	}

	/**
	 * Enregistrement du lien avec un poste de charge
	 */
	public saveLienRegleTaxeDetail(lienRegleTaxeDetail: any): Observable<Result> {
		//Enregistrement du lien avec le poste de charge
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fiscalite/saveLienRegleTaxeDetail`,lienRegleTaxeDetail);
	}

	/**
	 * Recherche des règles de taxe
	 */
	public filtreReglesTaxes(searchSpec: any): Observable<Page<any>> {
		//Recherche des règles de taxe
		return this.http.post<any>(`${environment.baseUrl}/controller/Fiscalite/filtreReglesTaxes`,searchSpec);
	}

	/**
	 * Recherche des règles de taxe pour le pays donné
	 */
	public findListeReglesTaxeForPays(idPays: any): Observable<Array<any>> {
		let searchSpec: any;

		//Vérification du chargement de la liste de règles
		if (!this.mapReglesTaxeForPays[idPays]) {
			//Initialisation de la recherche
			searchSpec = { listeFilter: [] };

			//Ajout du filtre sur le pays
			searchSpec.listeFilter.push({
				clef: 'pays.idPays',
				valeur: idPays,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.LONG
			});

			//Définition du nombre d'objets par page
			searchSpec.nbObjetsParPage = 9999;
			searchSpec.defaultOrder = 'libelle';

			//Recherche des règles de taxe pour le pays donné
			return this.filtreReglesTaxes(searchSpec).pipe(
				first(),
				map(result => result.content || []),
				tap(result => {
					//Mise à jour de la map des règles de taxe
					this.mapReglesTaxeForPays[idPays] = result;
				})
			);
		} else
			//Retour de la liste de règles de taxe du pays
			return of(this.mapReglesTaxeForPays[idPays]);
	}

	/**
	 * Contrôle de validité des dates d'application de la liste des détails d'une règle de taxe
	 */
	checkDateApplicationGenre(typeRegleTaxe: 'DATE' | 'DATE_GENRE',listeDetails: Array<any>): boolean {
		let isValid: boolean = true;

		//Parcours des détails
		listeDetails?.forEach((detail,index) => {
			//Vérification de l'absence d'erreur
			if (isValid) {
				//Vérification des détails en erreur suivant le type de règle
				isValid = !listeDetails.some((d,idx) => {
					//Vérification du type de règle
					if (typeRegleTaxe == 'DATE') {
						//Comparaison des dates
						return idx != index && d.dateApplication == detail.dateApplication;
					} else {
						//Compraraison des dates et genre
						return idx != index && d.dateApplication == detail.dateApplication && d.genre.idGenre == detail.genre.idGenre;
					}
				});
			}
		});

		//Retour de l'absence d'erreur
		return isValid;
	}

	/**
	 * Vérification de l'égalité de la liste des détails de deux règles de taxe
	 */
	compareListesDetails(liste1: any,liste2: any): boolean {
		let differenceSymetrique: Array<any>;

		//Récupération de la différence symétrique (éléments qui appartiennent à l'une ou à l'autre des listes, mais pas aux deux à la fois)
		differenceSymetrique = xorWith(liste1 || [],liste2 || [],(d1,d2) => {
			//Retour de l'égalité des détails
			return d1.dateApplication == d2.dateApplication && d1.tauxRecuperation == d2.tauxRecuperation && d1.genre?.idGenre === d2.genre?.idGenre;
		});

		//Retour de l'égalité des deux listes (absence d'éléments dans la différence symétrique)
		return differenceSymetrique.length == 0;
	}
}