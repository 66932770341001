import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types de connecteur
 */
export default class ConnecteurTypeOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.connecteurType';

	/**
	 * URL
	 */
	url(filter?: { isShowAll?: boolean }) { return `/controller/Connecteur/listeConnecteurTypes/${filter?.isShowAll || 'false'}`; }

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.connecteur.ConnecteurType';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idConnecteurType'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.typeExecution && {
			clef: 'typeExecution',
			valeur: filter.typeExecution,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}
}