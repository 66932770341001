<div class="pull-left lv-avatar">{{ data?.offre?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="extraOptions?.addLienOffre?.(data,deleteLienOffre)">{{ data.offre | display:'offre' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.dateResiliation"><span translate>contratCRM.dateResiliation</span>&#160;:&#160;<span>{{ data.dateResiliation | date:'shortDate' }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="data?.idLien">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'creation') && !data.dateResiliation"><a [routerLink]="[]" (click)="deleteLienOffre();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>