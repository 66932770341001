
import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { StructureExportFichierNomComponent } from './structure-export-fichier-nom.component';
import { StructureExportService } from './structure-export.service';

@Component({
	selector: 'structure-export-fichier',
	templateUrl: './structure-export-fichier.component.html'
})
export class StructureExportFichierComponent extends PageContentItem implements OnInit {
	/** Structure d'export courante **/
	@Input() structureExport: any;

	/** Fichier courant **/
	@Input() detail: any;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Liste des types de format du fichier **/
	public listeTypesFormat: Array<{ code: string,libelle: string }>;

	/** Liste des types d'échappement du fichier **/
	public listeTypesEchappement: Array<{ code: string,libelle: string }>;

	/** Liste des types d'encodage du fichier **/
	public listeTypesEncodage: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private bsModalService: BsModalService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification du détail
		if (!this.detail.fichier) {
			//Création du fichier vierge
			this.detail.fichier = {
				typeFormat: 'CSV',
				separateur: ';',
				structureCommune: true,
				typeEncodage: 'ANSI',
				typeEchappement: 'AUCUN'
			};
		}

		//Liste des types de format du fichier
		this.listeTypesFormat = this.structureExportService.getListeTypesFormatFichier();

		//Liste des types d'échappement du fichier
		this.listeTypesEchappement = this.structureExportService.getListeTypesEchappement();

		//Liste des types d'encodage du fichier
		this.listeTypesEncodage = this.structureExportService.getListeTypesEncodage();
	}

	/**
	 * Affichage du nom du fichier
	 */
	showName() {
		let bsModalRef: BsModalRef<StructureExportFichierNomComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(StructureExportFichierNomComponent,{
			initialState: {
				fichier: cloneDeep(this.detail.fichier),
				isRestricted: this.isRestricted
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.fichier),
			filter(fichier => !!fichier)
		).subscribe({
			next: (fichier: any) => {
				//Définition du fichier
				this.detail.fichier = fichier;
			}
		});
	}

	/**
	 * Enregistrement du fichier
	 */
	saveDetail() {
		//Sauvegarde du détail
		this.structureExportService.saveStructureExportDetail(this.structureExport.idStructure,this.detail).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Fermeture de la fenêtre
				this.close({
					structureExport: result.data.structureExport,
					detail: result.data.detail
				});
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}

	/**
	 * Suppression du détail de la structure d'export
	 */
	deleteDetail() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.structureExportService.deleteStructureExportDetail(this.detail))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture de la fenêtre
				this.close({
					structureExport: result.data.structureExport,
					isDeleted: true
				});
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}