import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ContratFinancementFactureDetailListItemComponent } from './contrat-financement-facture-detail-list-item.component';

@Component({
	templateUrl: './contrat-financement-facture-detail-list.component.html'
})
export class ContratFinancementFactureDetailListComponent extends PageContentItem implements OnInit {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Résumé **/
	@Input() resume: { nbCouts: number };

	/** Données **/
	liste: ListView<any,ContratFinancementFactureDetailListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContratFinancementFactureDetailListItemComponent>({
			uri: () => `/controller/Vehicule/filtreDetailsFacture/${this.contratFinancement.vehicule.idVehicule}`,
			component: ContratFinancementFactureDetailListItemComponent,
			isLocal: true,
			extraOptions: {
				contratFinancement: this.contratFinancement
			},
			listeFilters: [{
				clef: 'facture',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'facture'
				},
				isDefault: true
			},{
				clef: 'nature',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurNature'
				},
				isDefault: true
			},{
				clef: 'facture.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'facture.fournisseur.idFournisseur',
				valeur: this.contratFinancement.fournisseur.idFournisseur,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-date,nature.libelle'
		});
	}
}