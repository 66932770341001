import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap,tap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AffectationService } from './affectation/affectation.service';
import { ImmatriculationService } from './immatriculation/immatriculation.service';
import { VehiculeService } from './vehicule.service';

@Component({
	templateUrl: './vehicule-sortie.component.html'
})
export class VehiculeSortieComponent implements OnInit {
	/** Véhicule **/
	vehicule: any;

	/** Configuration des véhicules pour le pays **/
	configurationPays?: any;

	/** Véhicule enregistré **/
	savedVehicule: any = null;

	/** Affectation courante **/
	currentAffectation: any;

	/** Mémorisation de l'affectation courante **/
	savedCurrentAffectation: any;

	/** Validité de la période **/
	periodeValidity: { isDateEntreeValid: boolean,isDateSortieValid: boolean } = {
		isDateEntreeValid: true,
		isDateSortieValid: true
	};

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private affectationService: AffectationService,public bsModalRef: BsModalRef<VehiculeSortieComponent>,private toastrService: ToastrService,private translateService: TranslateService,private immatriculationService: ImmatriculationService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de l'affectation courante
		this.onDateSortieChange();
	}

	/**
	 * Interception d'un changement de date
	 */
	onDateSortieChange(dateSortie?: any) {
		//Récupération de l'affectation courante du véhicule
		this.affectationService.findAffectationForDate(this.vehicule.idVehicule,dateSortie || moment.utc().valueOf()).pipe(first()).subscribe({
			next: result => {
				//Vérification de l'affectation
				if (result?.data?.affectation) {
					//Définition de l'affectation courante
					this.currentAffectation = result.data.affectation;

					//Mémorisation de l'affectation courante
					this.savedCurrentAffectation = cloneDeep(this.currentAffectation);

					//Définition de la date de fin
					this.currentAffectation.dateFin = dateSortie;
				}
			}
		});
	}

	/**
	 * Enregistrement du véhicule
	 */
	saveVehicule() {
		//Vérification de la validité de la période
		this.affectationService.isPeriodeValidForVehicule(this.vehicule,this.savedCurrentAffectation).pipe(
			tap(periodeValidity => this.periodeValidity = periodeValidity),
			filter(periodeValidity => periodeValidity.isDateEntreeValid && periodeValidity.isDateSortieValid),
			switchMap(() => this.vehiculeService.saveVehicule(this.vehicule)),
			tap(rVehicule => {
				//Vérification du résultat
				if (rVehicule?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Vérification de la présence d'une affectation
					if (this.currentAffectation) {
						//Enregistrement de l'affectation
						this.affectationService.saveAffectation(Object.assign(this.currentAffectation,{
							vehicule: this.vehicule
						})).pipe(first()).subscribe({
							next: rAffectation => {
								//Vérification du résultat
								if (rAffectation?.codeErreur == TypeCodeErreur.NO_ERROR) {
									//Mémorisation du véhicule enregistré
									this.savedVehicule = rVehicule.data.vehicule;

									//Message d'information
									this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

									//Fermeture de la popup
									this.bsModalRef.hide();
								} else
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						});
					} else {
						//Mémorisation du véhicule enregistré
						this.savedVehicule = rVehicule.data.vehicule;

						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Fermeture de la popup
						this.bsModalRef.hide();
					}
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			})
		).subscribe();
	}

	/**
	 * Affichage de l'immatriculation
	 */
	showImmatriculation() {
		//Vérification du modèle
		if (this.vehicule.immatriculation.modele)
			//Définition de la marque
			this.vehicule.immatriculation.marque = this.vehicule.immatriculation.modele.marque;

		//Ouverture de la popup d'immatriculation
		this.immatriculationService.showImmatriculation({
			immatriculation: this.vehicule.immatriculation,
			pays: this.vehicule.pays,
			typeVehicule: this.vehicule.typeVehicule,
			vehicule: this.vehicule,
			options: {
				isConsultationOnly: true
			}
		});
	}
}