<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/StructureExport/',liste.selectedSelector,data.idStructure]" *ngIf="liste.selectedSelector == 'LOCAL' || rightService.isRoot()">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		<span *ngIf="liste.selectedSelector != 'LOCAL' && !rightService.isRoot()">{{ data.libelle + ' (' + data.reference + ')' }}</span>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.dateApplication"><span translate>structureExport.dateApplication</span>&#160;:&#160;<span>{{ data.dateApplication | date:'shortDate' }}</span></li>
		<li [tooltip]="getListeSocietes()"><span translate>structureExport.societe</span>&#160;:&#160;<span>{{ data.listeSocietes?.length || 0 }}</span></li>
		<li *ngIf="data.fichier?.name"><span translate>structureExport.liste.fichierNom</span>&#160;:&#160;<span>{{ data.fichier.name }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="((null | right:'consultation') || (null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot()) || (null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()) && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="duplicateStructureExport(data);"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteStructureExport();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idStructure)"><span translate>actions.importer</span></a></li>
			<li *ngIf="null | right:'consultation'"><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>