import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { sessionReducer } from './session';
import { layoutReducer } from './layout';
import { navigationReducer } from './navigation';

/**
 * Export des reducers
 */
export const reducers = {
	session: sessionReducer,
	layout: layoutReducer,
	navigation: navigationReducer
};

/**
 * Export de la configuration
 */
export function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	//Configuration de la persistance
	return localStorageSync({ keys: ['session'],rehydrate: true,storage: localStorage })(reducer);
}