<div class="container" *ngIf="detail">
	<form #detailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card focus">
			<div class="card-body card-padding">
				<facture-detail-content #detailContent [detail]="detail" [isConsultation]="true"></facture-detail-content>
			</div>
		</div>
		<button mat-button *ngIf="detail?.idDetail" [formDetectChanges]="{ doAction: close,isDirty: detailForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>