<div class="row" *ngIf="!disabled && canAddItem">
	<div class="form-group" [ngClass]="{ 'has-error': selectedItemModel.invalid }">
		<label *ngIf="colWidthLabel > 0" class="col-md-{{ colWidthLabel }}">
			<span>{{ labelAutocomplete }}</span>
			<icon *ngIf="tooltipAutocomplete" name="info_outline" class="m-l-5" [tooltip]="tooltipAutocomplete" boundariesElement="window"></icon>
		</label>
		<div [ngClass]="colWidthAutocomplete ? ('col-md-' + colWidthAutocomplete) : ('col-md-' + (9 - colWidthLabel))">
			<autocomplete [name]="fieldName" #autocomplete [type]="type" #selectedItemModel="ngModel" [(ngModel)]="selectedItem" [filter]="filter" [placeholder]="placeholder" [label]="label" [isCreationDisabled]="isCreationDisabled" (onSelect)="onSelectItem($event)" [options]="options" [validator]="!hasError()"></autocomplete>
		</div>
	</div>
</div>
<div>
	<div *ngIf="!listeBasketsOptions?.length" class="form-group">
		<label *ngIf="labelListe?.length">{{ labelListe }}</label>
		<div>
			<span class="empty" *ngIf="!model[propertyName]?.length" translate>basket.empty</span>
			<mat-chip-listbox>
				<mat-chip *ngFor="let item of model?.[propertyName]; let idxItem = index" (removed)="removeItem(item,idxItem);">
					{{ displayItem(item) }}
					<button matChipRemove *ngIf="!disabled && !this.isItemDisabled?.(item,idxItem)">
						<mat-icon>cancel</mat-icon>
					</button>
				</mat-chip>
			</mat-chip-listbox>
		</div>
	</div>
	<ng-container *ngIf="listeBasketsOptions?.length">
		<ng-container *ngFor="let basket of listeBasketsOptions">
			<div class="form-group">
				<label *ngIf="basket?.label?.length">{{ basket.label }}</label>
				<div>
					<span class="empty" *ngIf="!model[propertyName]?.length || !(model[propertyName] | filterBy:basket.filterItems)?.length">{{ basket.emptyLabel }}</span>
					<mat-chip-listbox *ngIf="(model[propertyName] | filterBy:basket.filterItems)?.length">
						<mat-chip *ngFor="let item of model[propertyName] | filterBy:basket.filterItems" (removed)="removeItem(item,getIndexFromListe(item));">
							{{ basket.displayItem(item) }}
							<button matChipRemove *ngIf="!disabled && !this.isItemDisabled?.(item,getIndexFromListe(item))">
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</mat-chip-listbox>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>