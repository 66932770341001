import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class PoolService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement d'un pool
	 */
	public loadPool(idPool: number): Observable<Result> {
		//Chargement du pool
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculePool/loadPool/${idPool}`,null);
	}

	/**
	 * Suppression du pool
	 */
	public deletePool(pool: any): Observable<Result> {
		//Suppression du pool
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculePool/deletePool/${pool.idPool}`);
	}

	/**
	 * Enregistrement d'un pool
	 */
	public savePool(pool: any): Observable<Result> {
		//Enregistrement du pool
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculePool/savePool`,pool);
	}

	/**
	 * Suppression du véhicule du pool
	 */
	public removeVehicule(idVehicule: number): Observable<Result> {
		//Retrait du véhicule
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculePool/removeVehicule/${idVehicule}`,null);
	}

	/**
	 * Ajout d'une liste de véhicules au pool
	 */
	public addListeVehiculesToPool(idPool: number,listeIdsVehicule: Array<number>): Observable<Result> {
		//Ajout des véhicules au pool
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculePool/addListeVehiculesToPool/${idPool}`,listeIdsVehicule);
	}
}