<div class="login-container">
	<div class="login-card">
		<img [src]="'controller/Theme/loadLogo/false' + (codeTenant?.length ? '/' + codeTenant : '')" class="logo"/>

		<!-- Login -->
		<form #loginForm="ngForm" (submit)="doLogin()" autocomplete="off" *ngIf="action == 'Login'">
			<h5 class="title" translate>login.signIn</h5>

			<!-- Fédération -->
			<mat-button-toggle-group class="federation" *ngIf="modeAuthentification == 'BOTH'" name="federation" [value]="mode" (change)="mode = $event.value">
				<mat-button-toggle value="LOGIN_MDP"><span translate>login.federation.loginPassword</span></mat-button-toggle>
				<mat-button-toggle value="FEDERATION"><span translate>login.federation.annuaireExterne</span></mat-button-toggle>
			</mat-button-toggle-group>

			<!-- Login/Mot de passe -->
			<div *ngIf="mode == 'LOGIN_MDP'">
				<div class="form-group" *ngIf="!codeTenant">
					<input type="text" name="societe" #societe="ngModel" class="form-control" [(ngModel)]="user.tenant.code" [placeholder]="'login.societe' | translate" required/>
				</div>
				<div class="form-group">
					<input type="text" name="login" #login="ngModel" class="form-control" [(ngModel)]="user.login" [placeholder]="'login.login' | translate" required/>
				</div>
				<div class="form-group">
					<input type="password" name="password" #password="ngModel" class="form-control" [(ngModel)]="user.password" [placeholder]="'login.password' | translate" required/>
				</div>
			</div>

			<!-- Fédération -->
			<div *ngIf="mode == 'FEDERATION'">
				<div class="form-group">
					<input type="email" email name="email" #email="ngModel" class="form-control" [(ngModel)]="user.login" [placeholder]="'login.email' | translate" required/>
				</div>
				<div class="form-group" *ngIf="isSeveralTenantFound">
					<input type="text" name="societe" #societe="ngModel" class="form-control" [(ngModel)]="user.tenant.code" [placeholder]="'login.societe' | translate" required/>
				</div>
				<div class="form-group" *ngIf="listeAuthConfigs?.length > 1">
					<selectpicker name="auth_config" [(ngModel)]="selectedAuthConfig" required>
						<mat-option *ngFor="let authConfig of listeAuthConfigs" [value]="authConfig">{{ authConfig.libelle + ' (' + authConfig.type + ')' }}</mat-option>
					</selectpicker>
				</div>
			</div>

			<div *ngIf="isAccountLocked">
				<div class="re-captcha" recaptcha #loginCaptcha="reCaptcha" (onRecaptchaExpired)="onRecaptchaExpired()" (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
			</div>
			<input name="recaptcha" ngModel class="hidden" [validator]="!isAccountLocked || captchaToken != null"/>

			<button type="submit" mat-flat-button color="primary" [disabled]="loginForm.invalid"><span translate>login.actions.seConnecter</span></button>
			<button *ngIf="mode == 'LOGIN_MDP'" class="redirect" mat-button color="primary" (click)="action = 'Password'"><span translate>login.passwordForgotten</span></button>
		</form>

		<!-- Mot de passe oublié -->
		<form #retrievePasswordForm="ngForm" (submit)="doRetrievePassword()" autocomplete="off" *ngIf="action == 'Password' && !token">
			<h5 class="title" translate>login.passwordForgotten</h5>
			<p translate>login.message.passwordForgotten</p>

			<div class="form-group" *ngIf="!codeTenant">
				<input type="text" name="societe" #societe="ngModel" class="form-control" [(ngModel)]="user.tenant.code" [placeholder]="'login.societe' | translate" required/>
			</div>

			<div class="form-group">
				<input type="text" name="login" #login="ngModel" class="form-control" [(ngModel)]="user.login" [placeholder]="'login.loginEmail' | translate" required/>
			</div>

			<div class="re-captcha" recaptcha #loginCaptcha="reCaptcha" (onRecaptchaExpired)="onRecaptchaExpired()" (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
			<input name="recaptcha" ngModel class="hidden" [validator]="captchaToken != null"/>

			<button type="submit" mat-flat-button color="primary" [disabled]="retrievePasswordForm.invalid"><span translate>login.actions.recupererPassword</span></button>

			<button class="redirect" mat-button color="primary" (click)="action = 'Login'; token = null; isTokenNotExpired = true; isTokenNotExpired = true"><span translate>login.backToLogin</span></button>
		</form>

		<!-- Changement de mot de passe -->
		<form #updatePasswordForm="ngForm" autocomplete="off" *ngIf="token && (action == 'Password' || action == 'Activate')" (ngSubmit)="updatePassword()">
			<h5 class="title" *ngIf="action == 'Password'" translate>login.message.updatePassword</h5>
			<h5 class="title" *ngIf="action == 'Activate'" translate>login.message.initPassword</h5>

			<p translate>login.message.passwordDescription</p>
			<ul class="password-rules">
				<li [ngClass]="{ valid: isRuleValid('caracteres') }"><span translate>login.rule.caracteres</span></li>
				<li [ngClass]="{ valid: isRuleValid('majuscules') }"><span translate>login.rule.majuscules</span></li>
				<li [ngClass]="{ valid: isRuleValid('minuscules') }"><span translate>login.rule.minuscules</span></li>
				<li [ngClass]="{ valid: isRuleValid('chiffres') }"><span translate>login.rule.chiffres</span></li>
				<li [ngClass]="{ valid: isRuleValid('speciaux') }"><span translate>login.rule.speciaux</span></li>
			</ul>

			<div class="form-group">
				<div class="input-group">
					<input type="password" autocomplete="new-password" name="password" #password="ngModel" class="form-control" [(ngModel)]="user.password" [placeholder]="'login.password' | translate" [validator]="isPasswordComplexe()" required/>
					<div class="input-group-addon">
						<icon class="password-icon" [ngClass]="{ success: password.valid }" [name]="password.valid ? 'check' : 'close'"></icon>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="input-group">
					<input type="password" autocomplete="new-password" name="passwordConfirmation" #passwordConfirmation="ngModel" class="form-control" [(ngModel)]="user.password2" [placeholder]="'login.confirmation' | translate" [validator]="user.password == user.password2" required/>
					<div class="input-group-addon">
						<icon class="password-icon" [ngClass]="{ success: passwordConfirmation.valid }" [name]="passwordConfirmation.valid ? 'check' : 'close'"></icon>
					</div>
				</div>
			</div>

			<button type="submit" mat-flat-button color="primary" [disabled]="updatePasswordForm.invalid"><span translate>login.actions.definirPassword</span></button>
		</form>
	</div>
</div>