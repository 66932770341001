<div class="pull-left lv-avatar"><icon name="trending_down"></icon></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addRemise?.(data,deleteRemise)"translate [translateParams]="{ remise: data.pourcentageRemise | number: '1.2' }">vehicule.grilleAttribution.modele.remise.title</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idRemise">
		<li *ngIf="data.dateApplication"><span translate>vehicule.grilleAttribution.modele.remise.dateApplication.item</span>&#160;:&#160;<span>{{ data.dateApplication | date:'shortDate' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idRemise && (TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'suppression'"><a [routerLink]="[]" (click)="deleteRemise();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>