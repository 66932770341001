import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject,of } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { TaxeComponent } from './taxe.component';

@Injectable()
export class TaxeService {
	/** Map des taxes par pays **/
	private mapTaxesByPays: { [key: string]: any } = {};

	/** Tolérance pour le contrôle de taxe **/
	private static readonly TOLERANCE_TAXE: number = 0.02;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService) { }

	/**
	 * Chargement des taxes pour le pays
	 */
	private findTaxeForPays(idPays: number): Observable<any> {
		let searchSpec: { listeFilter?: Array<Filter>,nbObjetsParPage?: number,defaultOrder?: string };

		//Vérification du cache
		if (!this.mapTaxesByPays[idPays]) {
			//Définition de la recherche
			searchSpec = {
				listeFilter: [{
					clef: 'pays.idPays',
					valeur: idPays,
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG
				}],
				nbObjetsParPage: 9999,
				defaultOrder: '-date'
			}

			//Chargement des taxes pour le pays
			return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Taxe/filtreTaxes`,searchSpec).pipe(
				map((result: Page<any>) => {
					//Mise en cache
					this.mapTaxesByPays[idPays] = result.content[0] || [];

					//Retour de la liste
					return result.content[0] || [];
				})
			);
		} else
			//Retour du cache
			return of(cloneDeep(this.mapTaxesByPays[idPays]));
	}

	/**
	 * Ouverture de la popup des taxes
	 */
	public showTaxes(detail: { listeTaxes: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }>,montantTTC?: number,totalAmount?: number,montant?: number },options?: { idPays: number,date: any,disabled: boolean }): Observable<any> {
		const subject: Subject<any> = new Subject();
		let bsModalRef: BsModalRef<TaxeComponent>;

		//Chargement des taxes associées au pays
		this.findTaxeForPays(options?.idPays).subscribe({
			next: (taxe: any) => {
				//Vérification de la taxe
				if (taxe?.listeTaux?.length) {
					//Vérification de la présence d'une taxe existante
					if (detail?.listeTaxes?.length) {
						//Itération sur la liste des taux
						taxe?.listeTaux.forEach((taux: any) => {
							let t: any;

							//Récupération de la taxe
							t = detail?.listeTaxes?.find((t: any) => t?.taux == taux?.taux)

							//Vérification de la présence d'une taxe
							if (t != null) {
								//Définition du montant
								taux.montant = t?.montant || null;

								//Vérification de la présence d'un identifiant de taxe
								if (t.idTaxe)
									//Définition de l'identifiant de taxe
									taux.idTaxe = t.idTaxe;
							}
						});
					}

					//Affichage de la popup
					bsModalRef = this.bsModalService.show(TaxeComponent,{
						initialState: {
							listeTaxes: cloneDeep(taxe?.listeTaux),
							disabled: options?.disabled,
							montantTTC: detail?.montantTTC || detail?.totalAmount || detail?.montant
						}
					});

					//Retour du résultat
					bsModalRef.onHidden.pipe(
						first(),
						map(() => bsModalRef.content?.result?.listeTaxes),
						filter(listeTaxes => !!listeTaxes)
					).subscribe({
						next: (listeTaxes: Array<any>) => {
							//Retour de la liste des taxes
							subject.next(listeTaxes.filter((t: any) => t.montant != null));
						},
						complete: () => subject.complete()
					})
				} else {
					//Aucune action
					subject.complete();
				}
			}
		});

		//Retour du résultat
		return subject;
	}

	/**
	 * Vérification du montant TTC avec les taxes
	 */
	public verifyListeTaxes(montantTTC: number,listeTaxes: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }>): boolean {
		let listeTaxesWithMontant: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }>;
		let listeRates: Array<any>;
		let sumHT,sumTaxe,sumTTC: number;

		//Vérification du montant et des taxes
		if (montantTTC && listeTaxes) {
			//Récupération de la liste des taxes dont le montant est différent de zéro
			listeTaxesWithMontant = listeTaxes.filter(t => !!t.montant);

			//Vérification que le signe de l'un des montants de taxe est différent de celui du montant TTC
			if (listeTaxesWithMontant.some(t => Math.sign(montantTTC) != Math.sign(t.montant)))
				//Taxes incohérentes
				return false;

			//Conversion des taux
			listeRates = listeTaxesWithMontant.map(t => {
				let taxe;

				//Copie de la taxe
				taxe = Object.assign({},t);

				//Mise à jour du montant
				taxe.montant = Math.abs(taxe.montant) - TaxeService.TOLERANCE_TAXE;

				//Calcul du taux
				taxe.taux = taxe.taux / 100.00;

				return taxe;
			});

			//Récupération de la somme des montants HT
			sumHT = listeRates.map(r => (r.montant / r.taux)).reduce((total,value) => total + value,0);

			//Récupération de la somme des montants de taxe
			sumTaxe = listeRates.reduce((total,rate) => total + rate.montant,0);

			//Récupération du TTC
			sumTTC = sumHT + sumTaxe;

			//Vérification des valeurs TTC
			return sumTTC == 0.0 || sumTTC <= Math.abs(montantTTC);
		} else
			//Taxes cohérentes
			return true;
	}

	/**
	 * Calcul du montant TTC avec les taxes
	 */
	public computeTotalTaxe(listeTaxes: Array<{ idTaxe: number,montant: number,taux: number,valide: boolean }>): number {
		//Calcul du montant total des taxes
		return listeTaxes.filter(d => d.montant != null).map(d => d.montant).reduce((accumulator,currentValue) => accumulator + currentValue,0);
	}
}
