<div class="pull-left lv-avatar">{{ data.user.nom?.substring?.(0,1)?.toUpperCase() + data.user.prenom?.substring?.(0,1)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addAutorisation?.(data,deleteAutorisation)">{{ data.user | display:'user' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.user?.idUser">
		<li *ngIf="data.dateDebut"><span translate>tenant.autorisation.dateDebut.item</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>tenant.autorisation.dateFin.item</span>&#160;:&#160;<span>{{ data.dateFin | date:'shortDate' }}</span></li>
		<li *ngIf="getProfilForType('WEB')[0]?.profil?.libelle"><span translate>tenant.autorisation.profil</span>&#160;:&#160;<span>{{ getProfilForType('WEB')[0].profil.libelle }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.ADMIN_TENANT_AUTORISATION | right:'suppression')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.ADMIN_TENANT_AUTORISATION | right:'suppression'"><a [routerLink]="[]" (click)="deleteAutorisation()"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>