<form #demandeVehiculeAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>demandeVehicule.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="m-t-20 m-b-10">
			<mat-radio-group [(ngModel)]="demandeVehiculeAdded.source" name="choix">
				<mat-radio-button value="RENOUVELLEMENT"><span translate>demandeVehicule.add.renouvellement</span></mat-radio-button>
				<div class="form-group" [ngClass]="{ 'has-error': vehiculeSource.invalid }">
					<label translate>demandeVehicule.add.immatriculation</label>
					<div>
						<autocomplete name="vehiculeSource" type="vehicule" [filter]="{ isWithoutDemandeVehicule: true }" #vehiculeSource="ngModel" [(ngModel)]="demandeVehiculeAdded.vehiculeSource" [required]="demandeVehiculeAdded.source == 'RENOUVELLEMENT'" [disabled]="demandeVehiculeAdded.source != 'RENOUVELLEMENT'"></autocomplete>
					</div>
				</div>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="NOUVEAU_VEHICULE"><span translate>demandeVehicule.add.nouveauVehicule</span></mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDemandeVehiculeAdd()" [disabled]="demandeVehiculeAddForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>