import { HttpClient } from '@angular/common/http';
import { Injectable,Type } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { filter,map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { environment } from 'src/environments/environment';
import { ImmatriculationAUTREComponent } from './immatriculation-autre.component';
import { ImmatriculationFRComponent } from './immatriculation-fr.component';
import { ImmatriculationModeleCatalogueListComponent } from './immatriculation-modele-catalogue-list.component';
import { ImmatriculationModeleCatalogueSelectionComponent } from './immatriculation-modele-catalogue-selection.component';
import { ImmatriculationComponent } from './immatriculation.component';

@Injectable()
export class ImmatriculationService {
	/** Configurations des immatriculations par pays **/
	private mapConfigurationForPays: { [pays: string]: { type: string,component: Type<ImmatriculationComponent> } } = {
		'FR': {
			type: 'com.notilus.data.vehicule.immatriculation.ImmatriculationFR',
			component: ImmatriculationFRComponent
		},
		'AUTRE': {
			type: 'com.notilus.data.vehicule.immatriculation.ImmatriculationAUTRE',
			component: ImmatriculationAUTREComponent
		}
	}

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private http: HttpClient) {}

	/**
	 * Affichage de l'immatriculation
	 */
	public showImmatriculation({ immatriculation,typeVehicule,vehicule,pays,options }: { immatriculation: any,typeVehicule?: TypeVehicule,vehicule?: any,pays?: any,options?: { isConsultationOnly?: boolean,isSynchro?: boolean,modeleInitial?: any } }): Observable<{ immatriculation?: any,vehicule?: any }> {
		let configurationForPays: { type: string,component: Type<ImmatriculationComponent> };
		let bsModalRef: BsModalRef<ImmatriculationComponent>;

		//Vérification du pays
		pays = pays || immatriculation.pays;

		//Récupération de la configuration pour le pays
		configurationForPays = this.mapConfigurationForPays[pays?.code2] || this.mapConfigurationForPays['AUTRE'];

		//Vérification du type
		if (!immatriculation._type)
			//Définition du type
			immatriculation._type = configurationForPays.type;

		//Vérification de la marque de l'immatriculation
		if (immatriculation.modele?.marque)
			//Définition de la marque
			immatriculation.marque = immatriculation.modele.marque;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(configurationForPays.component,{
			initialState: {
				immatriculation,
				typeVehicule,
				vehicule,
				options
			},
			class: 'modal-max'
		});

		//Retour des données enregistrées
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.savedData),
			filter((data) => !!data)
		)
	}

	/**
	 * Enregistrement d'une immatriculation
	 */
	public saveImmatriculation(immatriculation: any,idVehicule?: number,typeVehicule?: 'VEHICULE_FONCTION_SERVICE' | 'VEHICULE_PERSO' | 'ENGIN'): Observable<Result> {
		//Enregistrement de l'immatriculation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveImmatriculation${idVehicule ? '/' + idVehicule : ''}`,immatriculation,{
			params: {
				typeVehicule: typeVehicule || 'VEHICULE_FONCTION_SERVICE'
			}
		});
	}

	/**
	 * Affichage de la liste des modèles d'une immatriculation
	 */
	public showListeModelesForImmatriculation(immatriculation: any): Observable<any> {
		let bsModalRef: BsModalRef<ImmatriculationModeleCatalogueListComponent>;

		//Affichage de la liste des modèles
		bsModalRef = this.bsModalService.show(ImmatriculationModeleCatalogueListComponent,{
			initialState: {
				idImmatriculation: immatriculation.idImmatriculation
			},
			class: 'modal-max'
		});

		//Retour du modèle sélectionné
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.selectedModele)
		);
	}

	/**
	 * Affichage de la pop-up de décision quant au modèle catalogue de l'immatriculation
	 */
	public showModeleCatalogueSelection(immatriculation: any,modeleInitial: any): Observable<any> {
		let bsModalRef: BsModalRef<ImmatriculationModeleCatalogueSelectionComponent>;

		//Affichage de la pop-up de décision quant au modèle catalogue
		bsModalRef = this.bsModalService.show(ImmatriculationModeleCatalogueSelectionComponent,{
			initialState: {
				immatriculation,
				modeleInitial
			},
			class: 'modal-max'
		});

		//Retour du modèle sélectionné
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.selectedModele)
		);
	}
}