import { Component,Input,ChangeDetectorRef } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'vehicule-entretien-edit',
	templateUrl: './vehicule-entretien-edit.component.html'
})
export class VehiculeEntretienEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() entretien: any;

	/** Suppression d'un entretien **/
	@Input() deleteEntretien: (entretien: any,close: Function) => void;

	/** Dernier compteur **/
	@Input() lastCompteur: any;

	/** Nombre d'interventions **/
	@Input() nbInterventions: number;

	/** Mise à jour du véhicule **/
	@Input() updateVehicule: (vehicule: any) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef) {
		//Héritage
		super();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked(): void {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}
}