<form #planEntretienDetailForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.planEntretien.elements.detail.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-xs-12">
						<h4 translate>vehicule.planEntretien.description</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeEntretien.invalid }">
							<label translate>vehicule.planEntretien.typeEntretien</label>
							<div>
								<autocomplete name="typeEntretien" type="typeEntretien" #typeEntretien="ngModel" [filter]="{ source: 'PLAN_ENTRETIEN' }" [(ngModel)]="planEntretienDetail.typeEntretien" required></autocomplete>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>vehicule.planEntretien.actif.libelle</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="planEntretienDetail.actif"><span translate>vehicule.planEntretien.detail.actif.description</span></mat-checkbox>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label><span translate>vehicule.planEntretien.remarque</span></label>
					<div>
						<textarea class="form-control" rows="3" name="remarque" [(ngModel)]="planEntretienDetail.remarque"></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12">
						<h4>
							<span translate>vehicule.planEntretien.elements.detail.frequence.item</span>
							<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.planEntretien.elements.detail.frequence.info' | translate"></icon>
						</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': duree.invalid }">
							<label translate>vehicule.planEntretien.duree</label>
							<div class="half">
								<div class="input-group">
									<input type="text" name="duree" nNumber min="0" max="240" class="form-control" #duree="ngModel" [(ngModel)]="planEntretienDetail.duree" [required]="!planEntretienDetail.distance"/>
									<div class="input-group-addon no-border">
										<span translate>vehicule.planEntretien.mois</span>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': distance.invalid || unite.invalid }">
							<label translate>vehicule.planEntretien.distance</label>
							<div>
								<div class="row">
									<div class="col-xs-6">
										<input type="text" nNumber min="0" class="form-control" name="distance" #distance="ngModel" [(ngModel)]="planEntretienDetail.distance" [required]="!planEntretienDetail.duree"/>
									</div>
									<div class="col-xs-6 p-l-5">
										<autocomplete name="unite" type="unite" #unite="ngModel" [(ngModel)]="planEntretienDetail.unite" filter="DISTANCE" [required]="planEntretienDetail.duree"></autocomplete>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deletePlanEntretienDetail(planEntretienDetail,close);" *ngIf="planEntretienDetail.idPlanEntretienDetail && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="planEntretienDetailForm.invalid" (click)="savePlanEntretienDetail();" *ngIf="null | right:'creation'">
			<span [translate]="'actions.'+(planEntretienDetail?.idPlanEntretienDetail ? 'enregistrer' : 'continuer')"></span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: planEntretienDetailForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>