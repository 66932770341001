import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { ReferentielEntiteList,TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DeviseListItemComponent } from './devise-list-item.component';
import { DeviseService } from './devise.service';

@Component({
	templateUrl: './devise-list.component.html'
})
export class DeviseListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.devise.Devise';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/Devise/filtreDevises/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,DeviseListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(protected referentielService: ReferentielService,private translateService: TranslateService,protected rightService: RightService,private layoutService: LayoutService,private deviseService: DeviseService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Définition de la liste
		this.liste = new ListView<any,DeviseListItemComponent>({
			uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
			title: this.translateService.instant('devise.liste.title'),
			component: DeviseListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'code',
				isDefault: true
			},{
				clef: 'numeroIso',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'custom',
				type: TypeFilter.BOOLEAN
			}],
			listeSelectors: !this.rightService.isRoot() && [{
				code: TypeReferentiel.LOCAL,
				libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
				count: () => this.referentiel?.nbItemsForLocal || 0
			},{
				code: TypeReferentiel.STANDARD,
				libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
				count: () => this.referentiel?.nbItems || 0
			}],
			selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
			extraOptions: {
				referentiel: this.referentiel
			},
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl(`/Devise/loadDevise/${this.liste.selectedSelector}/0`)),
			onRefresh: (liste,result) => {
				//Vérification du type de liste
				if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
					//Mise à jour du nombre d'éléments standards
					this.referentiel.nbItems = result?.totalElements || 0;
				else
					//Mise à jour du nombre d'éléments locaux
					this.referentiel.nbItemsForLocal = result?.totalElements || 0;
			},
			listeActions: [this.rightService.hasRight(null,'suppression') && {
				icon: 'delete',
				messagingOptions: {
					entryPoint: 'controller/Devise/deleteAllDevises'
				},
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.deleteAllDevises(messagingOptions)
			}].filter(i => !!i),
			getKeyFieldName: () => 'idDevise'
		});
	}

	/**
	 * Suppressions des devises
	 */
	deleteAllDevises(messagingOptions: MessagingOptions) {
		//Suppression de la sélection
		this.deviseService.deleteAllForSelection(messagingOptions).subscribe({
			complete: () => this.liste.refresh()
		});
	}
}