<form #demandeVehiculeGrilleAttributionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>demandeVehicule.selection.modele.title</h4>
	</div>
	<div class="modal-body">
		<stepper #stepper="stepper" [listeSteps]="listeSteps" *ngIf="isGrilleAvailableForBeneficiaire"></stepper>
		<div class="text-center p-10" *ngIf="!isGrilleAvailableForBeneficiaire">
			<h5 translate>demandeVehicule.selection.modele.grilleAttribution.notAvailableForThisUser</h5>
		</div>
		<div class="text-center p-10" *ngIf="!grilleAttributionSpecifique && (TypeDroit.VEHICULE_GRILLE_ATTRIBUTION | right:'creation') && (!isGrilleAvailableForBeneficiaire || stepperComponent?.getSelectedStep()?.type == 'GRILLE_ATTRIBUTION')">
			<h5><a [routerLink]="[]" (click)="saveGrilleAttributionSpecifique()" translate>demandeVehicule.selection.modele.grilleAttribution.addGrilleAttributionSpecifique</a></h5>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
	</div>
</form>