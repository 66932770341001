<div class="row">
	<div class="col-md-6" *ngFor="let change of listeChanges">
		<div class="form-group p-10">
			<label class="bold">{{ change.field }}</label>
			<div>
				<span>
					<div *ngIf="!change.changed">
						<span *ngIf="!change.collection" class="d-flex">
							<span class="f-500 d-inline-block hide-overflow" [title]="change.oldValue?.value || '-'">{{ change.oldValue?.value || '-' }}</span>
							<icon name="arrow_forward" class="m-l-10 m-r-10 d-inline-block"></icon>
							<span class="f-500 d-inline-block hide-overflow" [title]="change.newValue?.value || '-'">{{ change.newValue?.value || '-' }}</span>
						</span>
						<a *ngIf="change.collection" [routerLink]="[]" (click)="showCollectionChange(change);" class="m-t-0">
							<span translate>history.change.accederDetail</span>&#160;<icon name="open_in_new"></icon>
						</a>
					</div>
					<span *ngIf="change.changed" translate>history.change.dataChanged</span>
				</span>
			</div>
		</div>
	</div>
</div>