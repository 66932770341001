import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TenantLicenceListItemComponent } from './tenant-licence-list-item.component';

@Component({
	selector: 'tenant-licence-list',
	templateUrl: './tenant-licence-list.component.html'
})
export class TenantLicenceListComponent extends PageContentItem implements OnInit {
	/** tenant **/
	@Input() tenant: any;

	/** Fonction d'affichage **/
	@Input() goToLicenceConsommation: () => void;

	/** Données **/
	liste: ListView<any,TenantLicenceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantLicenceListItemComponent>({
			uri: () => `/controller/Licence/filtreLicenceConsommations/${this.tenant.idTenant}`,
			component: TenantLicenceListItemComponent,
			isLocal: true,
			extraOptions: {
				goToLicenceConsommation: this.goToLicenceConsommation
			},
			defaultOrder: '-periode'
		});
	}
}