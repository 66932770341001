<div class="modal-header">
	<h4 class="modal-title" translate>vehicule.immatriculation.title</h4>
</div>
<div class="modal-body">
	<form #immatriculationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="immatriculation.typeSource == 'IMPORT' || options?.isConsultationOnly">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': numeroImmatriculation.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.numeroImmatriculation</label>
						<div>
							<input type="text" name="numeroImmatriculation" class="form-control" #numeroImmatriculation="ngModel" [(ngModel)]="immatriculation.reference" pattern="[A-Z0-9]*" required disabled/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.marque</label>
						<div>
							<autocomplete name="marque" type="marque" #marque="ngModel" [(ngModel)]="immatriculation.marque" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION' }" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': modele.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.modele</label>
						<div>
							<autocomplete name="modele" type="modele" #modele="ngModel" [(ngModel)]="immatriculation.modele" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',idMarque: immatriculation.marque?.idMarque }" [disabled]="!immatriculation.marque" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': vin.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.vin</label>
						<div>
							<input type="text" name="vin" class="form-control" #vin="ngModel" [(ngModel)]="immatriculation.numeroIdentificationVehicule" pattern="[A-Z0-9]*"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.dateDebut</label>
						<div class="half">
							<input type="text" name="dateDebut" class="form-control" #dateDebut="ngModel" [(ngModel)]="immatriculation.dateDebut" datePicker [storeDate]="true"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': carburant.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.energie</label>
						<div>
							<autocomplete name="carburant" type="carburant" #carburant="ngModel" [(ngModel)]="immatriculation.carburant" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',idPays: immatriculation.pays.idPays }"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': genre.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.genre</label>
						<div>
							<autocomplete name="genre" type="genre" #genre="ngModel" [(ngModel)]="immatriculation.genre" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',type: 'VEHICULE',idPays: immatriculation.pays.idPays }"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': tauxCO2.invalid }">
						<label translate>vehicule.immatriculation.AUTRE.tauxCO2</label>
						<div class="half">
							<input type="text" name="tauxCO2" class="form-control" #tauxCO2="ngModel" [(ngModel)]="immatriculation.tauxCO2" nNumber min="0" max="999999"/>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="form-group" *ngIf="immatriculation.pays">
						<label translate>vehicule.immatriculation.AUTRE.pays</label>
						<div>
							<span>{{ immatriculation.pays.libelle + ' (' + immatriculation.pays.code2 + ')' }}</span>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span [translate]="'actions.' + (isModeCreationOrSynchro() && !options?.isConsultationOnly ? 'annuler' : 'fermer')"></span>
	</button>
	<ng-container *ngIf="null | right:'creation'">
		<button mat-raised-button color="primary" *ngIf="!options?.isConsultationOnly && !isModeCreationOrSynchro()" (click)="doSynchronisation()">
			<span translate>actions.synchroniser</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="isModeCreationOrSynchro() || vehicule && immatriculation?.typeSource == 'SAISIE'" [disabled]="immatriculationForm.invalid" (click)="saveImmatriculation()">
			<span [translate]="'actions.' + (isModeCreationOrSynchro() ? 'continuer' : 'enregistrer')"></span>
		</button>
		<button mat-raised-button color="primary" *ngIf="!options?.isConsultationOnly && immatriculation?.typeSource == 'IMPORT' && (rightService.isRoot() || rightService.isRoot(true))" (click)="immatriculation.typeSource = 'SAISIE'">
			<span translate>actions.modifier</span>
		</button>
	</ng-container>
</div>