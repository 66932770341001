<div class="card" [ngClass]="{ 'full': source == 'CONTRAT_FINANCEMENT' }">
	<div class="card-body" [ngClass]="{ 'card-padding': source == 'CONTRAT_FINANCEMENT' }">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>contrat.financement.description</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
					<label translate>contrat.financement.type</label>
					<div>
						<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="contratFinancement.type" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
					<label translate>contrat.financement.fournisseur</label>
					<div>
						<autocomplete name="fournisseur" type="fournisseur" [filter]="{ isShowAll: true }" #fournisseur="ngModel" [(ngModel)]="contratFinancement.fournisseur" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
					<label translate>contrat.financement.reference</label>
					<div>
						<input type="text" name="reference" class="form-control" #reference="ngModel" [(ngModel)]="contratFinancement.reference" required/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': vehicule.invalid }" *ngIf="source != 'VEHICULE'">
					<label translate>contrat.financement.vehicule</label>
					<div>
						<div class="input-group">
							<autocomplete name="vehicule" type="vehicule" [filter]="{ isWithoutFinancement: true }" #vehicule="ngModel" [(ngModel)]="contratFinancement.vehicule" required></autocomplete>
							<div class="input-group-addon" *ngIf="contratFinancement.vehicule?.idVehicule && contratFinancement.vehicule?.listeOldReferences?.length">
								<icon name="replay_30" class="icon-hc-2x clickable" (click)="vehiculeService.showListeImmatriculations(contratFinancement.vehicule.idVehicule);"></icon>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': devise.invalid }">
					<label translate>contrat.financement.devise</label>
					<div class="half">
						<autocomplete name="devise" type="devise" #devise="ngModel" [(ngModel)]="contratFinancement.devise" (onSelect)="contratFinancement.devise = $event.code" required></autocomplete>
					</div>
				</div>
				<div class="form-group" *ngIf="source != 'CONTRAT_FINANCEMENT'">
					<label translate>contrat.financement.piecesJointes</label>
					<div>
						<div [attachment]="contratFinancement" [typeAttachment]="TypeAttachment.FINANCEMENT"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label translate>contrat.financement.remarque</label>
			<div>
				<textarea name="remarque" class="form-control" rows="3" [(ngModel)]="contratFinancement.remarque"></textarea>
			</div>
		</div>
		<div class="row" *ngIf="contratFinancement.type">
			<div class="col-xs-12">
				<h4 translate>contrat.financement.financement</h4>
			</div>
		</div>
		<div class="row" *ngIf="contratFinancement?.type?.mode == 'ACQUISITION'">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': dateAchat.invalid }">
					<label translate>contrat.financement.dateAchat</label>
					<div class="half">
						<input type="text" name="dateAchat" class="form-control" #dateAchat="ngModel" [(ngModel)]="contratFinancement.dateAchat" datePicker [storeDate]="true" required [disabled]="contratFinancement.listeLoisRoulage?.length && oldContratType == contratFinancement.type.mode"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': concessionnaire.invalid }">
					<label translate>contrat.financement.concessionnaire</label>
					<div>
						<autocomplete name="concessionnaire" type="fournisseur" #concessionnaire="ngModel" [(ngModel)]="contratFinancement.concessionnaire"></autocomplete>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="contratFinancement?.type && contratFinancement.type.mode != 'ACQUISITION'">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
						<label translate>contrat.financement.dateDebut</label>
						<div class="half">
							<input type="text" name="dateDebut" class="form-control" #dateDebut="ngModel" [(ngModel)]="contratFinancement.dateDebut" datePicker [storeDate]="true" [max]="dateDebutMax" required [disabled]="contratFinancement.listeLoisRoulage?.length && oldContratType == contratFinancement.type.mode"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': dateRuptureAnticipee.invalid }">
						<label translate>contrat.financement.dateRuptureAnticipee</label>
						<div class="half">
							<input type="text" name="dateRuptureAnticipee" class="form-control" #dateRuptureAnticipee="ngModel" [(ngModel)]="contratFinancement.dateRuptureAnticipee" datePicker [storeDate]="true" [min]="contratFinancement.dateDebut" [max]="getDateFin()"/>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>
							<span translate>contrat.financement.dateFin</span>
							<icon name="info_outline" class="m-l-5" [tooltip]="'contrat.financement.tip.dateFin' | translate"></icon>
						</label>
						<div *ngIf="contratFinancement.listeLoisRoulage?.length">
							<span *ngIf="getDateFin() != null">{{ getDateFin() | date:'shortDate' }}</span>
							<span *ngIf="getDateFin() == null" translate>common.nc</span>
						</div>
						<div *ngIf="!contratFinancement.listeLoisRoulage?.length">
							<span translate>common.nc</span>
						</div>
					</div>
					<div class="form-group">
						<label>
							<span translate>contrat.financement.duree</span>
							<icon name="info_outline" class="m-l-5" [tooltip]="'contrat.financement.tip.duree' | translate"></icon>
						</label>
						<div *ngIf="contratFinancement.lastLoiRoulage?.duree">
							<span>{{ contratFinancement.lastLoiRoulage.duree }}</span>
						</div>
						<div *ngIf="!contratFinancement.listeLoisRoulage?.length">
							<span translate>common.nc</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': optionAchatHt.invalid }" *ngIf="contratFinancement.type.mode == 'LOCATION_OPTION_ACHAT'">
						<label translate>contrat.financement.optionAchatHt</label>
						<div class="half">
							<input type="text" name="optionAchatHt" class="form-control" #optionAchatHt="ngModel" [(ngModel)]="contratFinancement.optionAchatHT" nNumber="2" min="0"/>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': concessionnaire.invalid }" *ngIf="contratFinancement.type.mode == 'LOCATION_OPTION_ACHAT'">
						<label translate>contrat.financement.concessionnaire</label>
						<div>
							<autocomplete name="concessionnaire" type="fournisseur" #concessionnaire="ngModel" [(ngModel)]="contratFinancement.concessionnaire"></autocomplete>
						</div>
					</div>
					<div class="form-group">
						<label translate>contrat.financement.vehiculeAchete.item</label>
						<div>
							<mat-checkbox name="vehiculeAchete" [(ngModel)]="contratFinancement.vehiculeAchete"><span translate>contrat.financement.vehiculeAchete.description</span></mat-checkbox>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="contratFinancement.type.mode == 'LOCATION_OPTION_ACHAT'">
					<div class="form-group" [ngClass]="{ 'has-error': optionAchatTtc.invalid }">
						<label translate>contrat.financement.optionAchatTtc</label>
						<div class="half">
							<input type="text" name="optionAchatTtc" class="form-control" #optionAchatTtc="ngModel" [(ngModel)]="contratFinancement.optionAchatTTC" nNumber="2" min="0"/>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': dateAchat.invalid }" *ngIf="contratFinancement.vehiculeAchete">
						<label translate>contrat.financement.dateAchat</label>
						<div class="half">
							<input type="text" name="dateAchat" class="form-control" #dateAchat="ngModel" [(ngModel)]="contratFinancement.dateAchat" datePicker [storeDate]="true" required/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>