import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FormatInterfaceService } from './format-interface.service';

@Component({
	selector: 'format-interface-edit',
	templateUrl: './format-interface-edit.component.html'
})
export class FormatInterfaceEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() formatInterface: any;

	/** Suppression de l'objet **/
	@Input() deleteFormatInterface: () => void;

	/** Liste des types de flux **/
	public listeTypesFlux: Array<any>;

	/** Enumération des types de référentiel **/
	public readonly TypeReferentiel = TypeReferentiel;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private formatInterfaceService: FormatInterfaceService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de flux
		this.listeTypesFlux = this.formatInterfaceService.getListeTypesFlux();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveFormatInterface() {
		//Enregistrement de l'objet
		this.formatInterfaceService.saveInterface(this.formatInterface).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.formatInterface = Object.assign(this.formatInterface,result.data?.formatInterface);

					//Fermeture du formulaire
					this.close(this.formatInterface);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du reference
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}