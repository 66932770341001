<form #affectationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!affectation.isDisplayed">
		<div class="tile-sub">
			<affectation-content #affectationContent [affectation]="affectation" source="AFFECTATION" [canRemoveVehicule]="true" [user]="user" (showVehicule)="showVehicule();" (close)="onContentClose($event);"></affectation-content>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: affectationForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-button color="primary" *ngIf="affectation.reservation && ((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')) && user.type != 'VIRTUAL'" (click)="affectationContent.goToPlanning();">
				<span translate>vehicule.affectation.actions.accederPlanning</span>
			</button>
			<button mat-stroked-button color="primary" *ngIf="(TypeDroit.VEHICULE_RESERVATION | right:'creation') && affectation.idAffectation > 0 && user.type != 'VIRTUAL' && affectation.reservation && affectation.reservation.statut == 'VALIDEE'" (click)="affectationContent.doActionForReservation('ANNULER');">
				<span translate>actions.annuler</span>
			</button>
			<button mat-stroked-button color="primary" *ngIf="((TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')) && affectation.idAffectation > 0 && user.type != 'VIRTUAL' && !affectation.reservation" (click)="affectationContent.deleteAffectation();">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" *ngIf="(TypeDroit.ADMIN_VEHICULE | right:'creation') || (TypeDroit.VEHICULE_CONDUCTEUR | right:'creation')" (click)="affectationContent.saveAffectation();" [disabled]="affectationForm.invalid || affectation.dateFin && (!affectation.dateDebut || affectation.dateFin < affectation.dateDebut)">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>