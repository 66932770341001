import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TaxeEditComponent } from './taxe-edit.component';
import { TaxeTauxEditComponent } from './taxe-taux-edit.component';
import { TaxeTauxListComponent } from './taxe-taux-list.component';
import { TaxeService } from './taxe.service';

@Component({
	selector: 'taxe',
	templateUrl: './taxe.component.html'
})
export class TaxeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Taxe courante **/
	public taxe: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private taxeService: TaxeService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.taxe.Taxe';

	/**
	 * Récupération de l'identifiant de la taxe à charger
	 */
	getIdObject: () => number = () => this.taxe?.idTaxe || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.taxe;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'fiscalite.taxe.pays',
				key: 'pays',
				type: 'DISPLAY',
				format: 'pays'
			},{
				libelle: 'fiscalite.taxe.dateApplication',
				key: 'date',
				type: 'DATE'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editTaxe(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editTaxe(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteTaxe(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeElements: [{
				type: 'TAUX',
				libelle: 'fiscalite.taxe.elements.taux',
				component: TaxeTauxListComponent,
				retrieveComponentData: () => ({
					taxe: this.taxe,
					addTaux: this.addTaux.bind(this),
					saveTaxe: this.saveTaxe.bind(this)
				}),
				doAction: () => this.addTaux(),
				count: () => this.taxe.listeTaux?.length || 0,
				libelleAction: this.translateService.instant('fiscalite.taxe.actions.ajouterTaux'),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}]
		};

		//Récupération de l'identifiant de la taxe à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idTaxe);
			}
		});
	}

	/**
	 * Edition de la taxe
	 */
	private editTaxe() {
		//Ouverture du composant d'édition
		this.pageContentService.open(TaxeEditComponent,{
			data: {
				taxe: cloneDeep(this.taxe),
				deleteTaxe: this.deleteTaxe.bind(this),
				saveTaxe: this.saveTaxe.bind(this)
			}
		}).subscribe({
			next: (taxe: any) => {
				//Vérification des données
				if (taxe) {
					//Mise à jour de la taxe
					this.taxe = Object.assign(this.taxe,taxe);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.taxe);
				}
			}
		});
	}

	/**
	 * Suppression de la taxe
	 */
	private deleteTaxe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.taxeService.deleteTaxe(this.taxe))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Retour à la page précédente
					this.layoutService.goBack();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idTaxe: number) {
		//Chargement de la taxe
		this.taxeService.loadTaxe(idTaxe).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la taxe
					this.taxe = result.data?.taxe;

					//Vérification de l'identifiant de la taxe
					if (!this.taxe?.idTaxe) {
						//Création d'un objet vierge
						this.taxe = {};

						//Edition de la taxe
						this.editTaxe();
					}
				}
			}
		});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveTaxe(taxe: any,close?: Function) {
		//Enregistrement de l'objet
		this.taxeService.saveTaxe(taxe).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.taxe = Object.assign(this.taxe,result.data?.taxe);

					//Fermeture du formulaire
					close?.(this.taxe);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.pays'));

						//Ajout de la date
						listeDoublons.push(this.translateService.instant('actions.doublon.date'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}

	/**
	 * Modification du taux
	 */
	private addTaux(taux: any = {},index?: number) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TaxeTauxEditComponent,{
			data: {
				taxe: cloneDeep(this.taxe),
				taux: cloneDeep(taux),
				index,
				saveTaxe: this.saveTaxe.bind(this)
			}
		},'sub').subscribe();
	}
}