import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RegleAutomatiqueEditComponent } from './regle-automatique-edit.component';
import { RegleAutomatiqueRuleComponent } from './regle-automatique-rule.component';
import { RegleAutomatiqueService } from './regle-automatique.service';

@Component({
	selector: 'regle-automatique',
	templateUrl: './regle-automatique.component.html'
})
export class RegleAutomatiqueComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Règle automatique courante **/
	public regleAutomatique: any;

	/** Liste des types de règles automatiques **/
	private listeTypesComputedField: any;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private regleAutomatiqueService: RegleAutomatiqueService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private extractionService: ExtractionService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.regleAutomatique.RegleAutomatique';

	/**
	 * Récupération de l'identifiant de la règle automatique à charger
	 */
	getIdObject: () => number = () => this.regleAutomatique?.idRegleAutomatique || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement de la liste des types
		this.regleAutomatiqueService.retrieveListeTypesComputedField().pipe(
			first(),
			tap(listeTypes => {
				//Mise à jour de la liste des règles automatiques
				this.listeTypesComputedField = listeTypes;
			}),
			switchMap((() =>
				//Chargement des données
				this.activatedRoute.params.pipe(
					first(),
					switchMap((params) => {
						//Chargement des données
						return this.loadData(params.idRegleAutomatique);
					})
				)
			))
		).subscribe({
			next: () => {
				//Initialisation de la page complexe
				this.options = {
					listeFields: [{
						libelle: 'regleAutomatique.entity',
						key: (data) => data.typeComputedField.libelleEntity
					},{
						libelle: 'regleAutomatique.champ',
						key: (data) => data.typeComputedField.libelle
					},{
						libelle: 'regleAutomatique.valeur',
						key: (data) => data.typeComputedField?.listeValeurs?.find(value => value.code == data.valeur)?.libelle || data.valeur
					},{
						libelle: 'regleAutomatique.dateApplication',
						key: 'dateApplication',
						type: 'DATE'
					},{
						libelle: 'regleAutomatique.actif.item',
						key: 'actif',
						type: 'BOOLEAN'
					}],
					listeActions: [{
						libelle: 'actions.extraire',
						doAction: () => this.extractionService.selectExtraction(null,this.regleAutomatique.typeComputedField.entity,{ ruleOverride: this.regleAutomatique.rule,baseFilename: `${this.regleAutomatique.typeComputedField?.libelle}_${this.translateService.instant(this.regleAutomatique.typeComputedField?.i18nRootKey + '.' + this.regleAutomatique.valeur)}` }),
						isVisible: () => this.regleAutomatique?.rule?.listeDetails?.length > 0 && this.rightService.hasRight(null,'creation')
					},{
						libelle: 'actions.modifier',
						doAction: () => this.editRegleAutomatique(),
						isVisible: () => this.rightService.hasRight(null,'creation'),
						type: TypeAction.EDITION
					},{
						libelle: 'actions.consulter',
						doAction: () => this.editRegleAutomatique(),
						isVisible: () => !this.rightService.hasRight(null,'creation'),
						type: TypeAction.CONSULTATION
					},{
						libelle: 'actions.dupliquer',
						doAction: () => this.duplicateRegleAutomatique(),
						isVisible: () => this.regleAutomatique?.rule?.listeDetails?.length > 0 && this.rightService.hasRight(null,'creation')
					},{
						libelle: 'actions.supprimer',
						doAction: () => this.deleteRegleAutomatique(),
						isVisible: () => this.rightService.hasRight(null,'suppression')
					}],
					listeAlertes: [{
						icon: 'filter_list',
						title: this.translateService.instant('regleAutomatique.alerte.filtre.title'),
						message: this.translateService.instant('regleAutomatique.alerte.filtre.message'),
						isVisible: () => !this.regleAutomatique?.rule?.listeDetails?.length && !this.pageContentService.isOpened(),
						doAction: () => this.addRule(),
						isActionVisible: () => this.rightService.hasRight(null,'creation')
					}],
					listeElements: [{
						type: 'FILTRE',
						libelle: 'regleAutomatique.elements.filtre',
						component: RegleAutomatiqueRuleComponent,
						retrieveComponentData: () => ({
							getRegleAutomatique: () => this.regleAutomatique,
							getRule: () => this.regleAutomatique?.rule
						}),
						doAction: () => this.addRule(),
						isElementVisible: () => this.regleAutomatique?.rule,
						isActionVisible: () => this.rightService.hasRight(null,'creation'),
						count: () => this.regleAutomatique?.rule?.listeDetails?.length ? 1 : 0
					}]
				};
			}
		});



	}

	/**
	 * Edition de la règle automatique
	 */
	private editRegleAutomatique() {
		//Ouverture du composant d'édition
		this.pageContentService.open(RegleAutomatiqueEditComponent,{
			data: {
				regleAutomatique: cloneDeep(this.regleAutomatique),
				idRegleAutomatiqueOrigine: history?.state?.idRegleAutomatiqueOrigine,
				listeTypesComputedField: this.listeTypesComputedField,
				deleteRegleAutomatique: this.deleteRegleAutomatique.bind(this)
			}
		}).subscribe({
			next: regleAutomatique => {
				//Vérification des données
				if (regleAutomatique) {
					//Mise à jour de la règle automatique
					Object.assign(this.regleAutomatique,regleAutomatique);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.regleAutomatique);
				}
			}
		});
	}

	/**
	 * Suppression de la règle automatique
	 */
	private deleteRegleAutomatique() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.regleAutomatiqueService.deleteRegleAutomatique(this.regleAutomatique))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idRegleAutomatique: number) {
		//Chargement de la règle automatique
		return this.regleAutomatiqueService.loadRegleAutomatique(history?.state?.idRegleAutomatiqueOrigine || idRegleAutomatique).pipe(
			map(result => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la regle automatique
					this.regleAutomatique = result.data?.regleAutomatique;

					//Vérification de l'identifiant de la règle automatique
					if (!this.regleAutomatique?.idRegleAutomatique && !history?.state?.idRegleAutomatiqueOrigine) {
						//Création d'un objet vierge
						this.regleAutomatique = {
							typeComputedField: {},
							actif: false
						}

						//Edition de la règle automatique
						this.editRegleAutomatique();
					} else if (history?.state?.idRegleAutomatiqueOrigine) {
						//Duplication de la règle automatique
						this.regleAutomatique = {
							idRegleAutomatique: null,
							type: this.regleAutomatique.type,
							valeur: this.regleAutomatique.valeur,
							dateApplication: this.regleAutomatique.dateApplication,
							typeComputedField: this.listeTypesComputedField.find(type => type.code == this.regleAutomatique?.type),
							remarque: this.regleAutomatique.remarque,
							actif: false
						}

						//Edition de la règle automatique
						this.editRegleAutomatique();
					} else
						//Définition de l'énumération rattaché à l'entité
						this.regleAutomatique.typeComputedField = this.listeTypesComputedField.find(type => type.code == this.regleAutomatique?.type);
				}

				return this.regleAutomatique;
			})
		);
	}

	/**
	 * Duplication de la règle automatique
	 */
	private duplicateRegleAutomatique() {
		//Navigation vers la page de duplication
		this.layoutService.goToByState('listeReglesAutomatiques-regleAutomatique',{
			routeParams: {
				idRegleAutomatique: 0
			},
			routeData: {
				idRegleAutomatiqueOrigine: this.regleAutomatique.idRegleAutomatique
			},
			reloadOnSameUrl: true
		});
	}

	/**
	 * Ajout d'une règle
	 */
	private addRule() {
		//Affichage du détail
		this.pageContentService.open(RegleAutomatiqueRuleComponent,{
			data: {
				getRegleAutomatique: () => this.regleAutomatique,
				getRule: () => this.regleAutomatique.rule && cloneDeep(this.regleAutomatique.rule) || {},
				isEdition: true
			}
		},'sub').subscribe({
			next: (regleAutomatique) => {
				//Vérification de la règle automatique
				if (regleAutomatique) {
					//Mise à jour de la règle automatique
					Object.assign(this.regleAutomatique,regleAutomatique);

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('FILTRE',true);
				}
			}
		});
	}
}