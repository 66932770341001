import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleTaxeService } from './regle-taxe.service';

@Component({
	selector: 'regle-taxe-edit',
	templateUrl: './regle-taxe-edit.component.html'
})
export class RegleTaxeEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() regleTaxe: any;

	/** Suppression de l'objet **/
	@Input() deleteRegleTaxe: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveRegleTaxe: (regleTaxe: any,close: Function) => void;

	/** Liste des types **/
	public listeTypes: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private regleTaxeService: RegleTaxeService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la liste des types
		this.listeTypes = this.regleTaxeService.getListeTypes();
	}
}