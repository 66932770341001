<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3) }}</div>
		<div class="lv-title">
			<a [routerLink]="['/Fournisseur/loadInterface/',data.idInterface]">{{ data.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.typeFlux"><span translate>fournisseur.formatInterface.typeFlux.item</span>&#160;:&#160;<span [translate]="'fournisseur.formatInterface.typeFlux.' + data.typeFlux"></span></li>
		<li *ngIf="data.fournisseur"><span translate>fournisseur.formatInterface.fournisseur</span>&#160;:&#160;<span>{{ data.fournisseur.libelle }}</span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.typeExecution != 'NON_DEFINI'"><span translate>fournisseur.formatInterface.modeExecution.item</span>&#160;:&#160;<span [translate]="'fournisseur.formatInterface.modeExecution.'+data.typeExecution"></span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && rightService.isRoot()"><a [routerLink]="[]" (click)="deleteFormatInterface();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>