<div class="container-fluid">
	<form #dataCustomizationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" *ngIf="listeEntities?.length">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="card full">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>dataCustomization.title</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': entite.invalid }">
								<label translate>dataCustomization.customRefValue.entite</label>
								<div>
									<selectpicker name="entite" #entite="ngModel" [(ngModel)]="dataCustomization.entite" [disabled]="dataCustomization.idDataCustomizationEntite" required>
										<mat-option *ngFor="let entite of listeEntities" [value]="entite.code">{{ entite.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card full">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>dataCustomization.personnalisationLibelles</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('customRef1') }">
								<label translate [translateParams]="{ index: 1 }">dataCustomization.entite.type.referentiel</label>
								<div>
									<input type="text" name="customRef1" #customRef1="ngModel" class="form-control"[(ngModel)]="dataCustomization.customRef1" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('customRef2') }">
								<label translate [translateParams]="{ index: 2 }">dataCustomization.entite.type.referentiel</label>
								<div>
									<input type="text" name="customRef2" #customRef2="ngModel" class="form-control"[(ngModel)]="dataCustomization.customRef2" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('customRef3') }">
								<label translate [translateParams]="{ index: 3 }">dataCustomization.entite.type.referentiel</label>
								<div>
									<input type="text" name="customRef3" #customRef3="ngModel" class="form-control"[(ngModel)]="dataCustomization.customRef3" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('customRef4')}">
								<label translate [translateParams]="{ index: 4 }">dataCustomization.entite.type.referentiel</label>
								<div>
									<input type="text" name="customRef4" #customRef4="ngModel" class="form-control"[(ngModel)]="dataCustomization.customRef4" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row m-t-25">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('alpha1') }">
								<label translate [translateParams]="{ index: 1 }">dataCustomization.entite.type.alpha</label>
								<div>
									<input type="text" name="alpha1" #alpha1="ngModel" class="form-control"[(ngModel)]="dataCustomization.alpha1" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('alpha2') }">
								<label translate [translateParams]="{ index: 2 }">dataCustomization.entite.type.alpha</label>
								<div>
									<input type="text" name="alpha2" #alpha2="ngModel" class="form-control"[(ngModel)]="dataCustomization.alpha2" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('alpha3') }">
								<label translate [translateParams]="{ index: 3 }">dataCustomization.entite.type.alpha</label>
								<div>
									<input type="text" name="alpha3" #alpha3="ngModel" class="form-control"[(ngModel)]="dataCustomization.alpha3" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('alpha4') }">
								<label translate [translateParams]="{ index: 4 }">dataCustomization.entite.type.alpha</label>
								<div>
									<input type="text" name="alpha4" #alpha4="ngModel" class="form-control"[(ngModel)]="dataCustomization.alpha4" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row m-t-25">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('numeric1') }">
								<label translate [translateParams]="{ index: 1 }">dataCustomization.entite.type.numeric</label>
								<div>
									<input type="text" name="numeric1" #numeric1="ngModel" class="form-control"[(ngModel)]="dataCustomization.numeric1" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('numeric2') }">
								<label translate [translateParams]="{ index: 2 }">dataCustomization.entite.type.numeric</label>
								<div>
									<input type="text" name="numeric2" #numeric2="ngModel" class="form-control"[(ngModel)]="dataCustomization.numeric2" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('numeric3') }">
								<label translate [translateParams]="{ index: 3 }">dataCustomization.entite.type.numeric</label>
								<div>
									<input type="text" name="numeric3" #numeric3="ngModel" class="form-control" [(ngModel)]="dataCustomization.numeric3" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('numeric4') }">
								<label translate [translateParams]="{ index: 4 }">dataCustomization.entite.type.numeric</label>
								<div>
									<input type="text" name="numeric4" #numeric4="ngModel" class="form-control" [(ngModel)]="dataCustomization.numeric4" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row m-t-25">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('date1') }">
								<label translate [translateParams]="{ index: 1 }">dataCustomization.entite.type.date</label>
								<div>
									<input type="text" name="date1" #date1="ngModel" class="form-control"[(ngModel)]="dataCustomization.date1" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('date2') }">
								<label translate [translateParams]="{ index: 2 }">dataCustomization.entite.type.date</label>
								<div>
									<input type="text" name="date2" #date2="ngModel" class="form-control"[(ngModel)]="dataCustomization.date2" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('date3') }">
								<label translate [translateParams]="{ index: 3 }">dataCustomization.entite.type.date</label>
								<div>
									<input type="text" name="date3" #date3="ngModel" class="form-control" [(ngModel)]="dataCustomization.date3" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': checkDoublon('date4') }">
								<label translate [translateParams]="{ index: 4 }">dataCustomization.entite.type.date</label>
								<div>
									<input type="text" name="date4" #date4="ngModel" class="form-control" [(ngModel)]="dataCustomization.date4" maxlength="100" pattern="[^>]*"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveDataCustomization();" *ngIf="null | right:'creation'" [disabled]="dataCustomizationForm.invalid || checkDoublon()">
				<span [translate]="'actions.'+(dataCustomization?.idDataCustomizationEntite ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="dataCustomization?.idDataCustomizationEntite" [formDetectChanges]="{ doAction: close,isDirty: dataCustomizationForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>