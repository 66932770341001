import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class RevendeurService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement du tenant associé au revendeur
	 */
	public loadTenantForRevendeur(idRevendeur: number): Observable<Result> {
		//Chargement du tenant associé au revendeur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Tenant/loadTenantForRevendeur/${idRevendeur}`,null);
	}

	/**
	 * Suppression d'un revendeur
	 */
	public deleteRevendeur(revendeur: any): Observable<Result> {
		//Suppression d'un revendeur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Revendeur/deleteRevendeur/${revendeur.idRevendeur}`);
	}

	/**
	 * Enregistrement d'un revendeur
	 */
	public saveRevendeur(revendeur: any): Observable<Result> {
		//Enregistrement d'un revendeur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Revendeur/saveRevendeur`,revendeur);
	}

	/**
	 * Vérification de l'activation du compte Administrateur
	 */
	public isAdminAccountEnabled(revendeur): Observable<Result> {
		//Vérification de l'activation du compte Administrateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Revendeur/isAdminAccountEnabled`,revendeur);
	}
}