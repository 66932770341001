import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des grilles d'attribution
 */
export default class GrilleAttributionOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.grilleAttribution';

	/**
	 * Récupération de l'URL
	 */
	url(filter?: any): string {
		//URL
		return `/controller/Vehicule/listeGrillesAttributionActives${filter ? '/' + filter : ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle.substring(0,3); }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.GrilleAttribution';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idGrilleAttribution'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}
}