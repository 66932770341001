import { Injectable,NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { ApexAxisChartSeries } from 'ng-apexcharts';

import { ChartOptions,ExternalChartOptions,SubTypeChart,TypeChart } from 'src/app/domain/chart/chart';
import { GLOBALS } from 'src/app/utils/globals';
import { Page } from 'src/app/domain/common/http/list-result';
import { StyleService } from 'src/app/share/layout/style.service';

@Injectable()
export class ChartService {
	/** Cache des couleurs associées aux classes **/
	private mapColorsForClass: { [key: string]: string } = {};

	/**
	 * Constructeur
	 */
	constructor(public translateService: TranslateService,private decimalPipe: DecimalPipe,private styleService: StyleService,private ngZone: NgZone) {}

	/**
	 * Récupération de la couleur associée à un code ou une classe
	 */
	public getColorFor(hexColorOrClass: string): string {
		let node: any;

		//Vérification de la couleur
		if (!hexColorOrClass.startsWith('#')) {
			//Vérification de l'absence de couleur dans le cache
			if (!this.mapColorsForClass[hexColorOrClass]) {
				//Création de l'élément dans le DOM
				node = GLOBALS.$(`<div class="chart color ${hexColorOrClass}"></div>`).hide().appendTo('body');

				//Récupération de la couleur liée à la classe
				this.mapColorsForClass[hexColorOrClass] = node.css('color');

				//Suppression de l'élément
				node.remove();
			}

			//Retour de la couleur
			return this.mapColorsForClass[hexColorOrClass];
		} else
			//Retour de la couleur directement
			return hexColorOrClass;
	}

	/**
	 * Conversion d'une couleur héxadécimale en RGB
	 */
	public hexToRGB(hex: string) {
		let rgb: Array<number> = [];
		let bytes: number;
		let max: number;

		//Vérification de la couleur
		if (typeof hex != 'string' || !hex.match(/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/)) {
			//Retour de la couleur
			return hex;
		} else {
			//Retrait du premier caractère
			hex = hex.replace(/^#/,'');

			//Longeur des paires
			bytes = hex.length / 3;

			//Calcul du max
			max = Math.pow(16,bytes) - 1;

			//Calcul des couleurs
			rgb.push(Math.min(parseInt(hex.slice(0,bytes),16),max));
			rgb.push(Math.min(parseInt(hex.slice(bytes,bytes * 2),16),max));
			rgb.push(Math.min(parseInt(hex.slice(bytes * 2),16),max));

			//Retour de la valeur RGB
			return 'rgb('+rgb.join(',')+')';
		}
	}

	/**
	 * Génération des options pour le graphique externe
	 */
	public generateOptions(options: ChartOptions,data: Page<any>): ExternalChartOptions {
		let chartOptions: ExternalChartOptions;
		let series: ApexAxisChartSeries;
		let listeColors: Array<string>;
		let listeSubLabels: Array<string>;
		let listeCategories: Array<string>;
		let listeLabels: Array<string>;

		//Parcours des données principales pour extraire les libellés des séries
		listeSubLabels = data.content.reduce((listeLabels,d) => {
			//Parcours des données
			listeLabels = listeLabels.concat(d.listeDatas?.filter(subData => !listeLabels.includes(subData.label)).map(subData => subData.label).filter(label => !!label) || []);

			return listeLabels;
		},[]);

		//Vérification du type de graphique
		if ([TypeChart.PIE,TypeChart.DONUT].includes(options.type)) {
			//Création de la série
			series = data.content.map(d => d.value);

			//Création de la liste de libellés
			listeLabels = data.content.map(d => d.label || ' ');
		} else {
			//Vérification de l'absence de sous-série
			if (listeSubLabels?.length) {
				//Création de la série unique
				series = listeSubLabels.map(name => ({
					name,
					data: data.content.map(d => d.listeDatas.filter(subData => subData.label == name)?.[0]?.value || 0)
				}));
			} else {
				//Création de la série unique
				series = [{
					name: this.translateService.instant('liste.valeur'),
					data: data.content.filter(d => d.value != null).map(d => d.value)
				}];
			}
		}

		//Définition de la liste des catégories
		listeCategories = data.content.map(d => options.getLabel(d,0) || '');

		//Génération de la liste des couleurs
		listeColors = ['nc-primary','nc-secondary','nc-list-avatar','#065632','#D39926','#A5BDFD','#DCB5FF','#FFD98E','#8B104E','#C7B198','#7B3C3C','nc-text-other'].map(code => this.getColorFor(code));

		//Définition des options du graphique externe
		chartOptions = {
			chart: {
				width: '100%',
				height: '100%',
				type: options.type == TypeChart.BAR ? 'bar' : options.type == TypeChart.LINE ? 'line' : options.type == TypeChart.AREA ? 'area' : options.type == TypeChart.PIE ? 'pie' : 'donut',
				stacked: options.subType == SubTypeChart.EMPILE,
				toolbar: {
					show: false
				},
				zoom: {
					enabled: false
				},
				events: {
					dataPointSelection: (e: any,chart: any,dataPointOptions: any) => {
						//Vérification du type de graphique
						if ([TypeChart.PIE,TypeChart.DONUT].includes(options.type))
							//Déclenchement de l'appel dans le contexte angular
							this.ngZone.run(() => options.onSelect?.([data.content[dataPointOptions.dataPointIndex].label],options.isModal));
						else
							//Déclenchement de l'appel dans le contexte angular
							this.ngZone.run(() => options.onSelect?.([data.content[dataPointOptions.dataPointIndex].label,series[dataPointOptions.seriesIndex].name]));
					},
					markerClick: (e: any,chart: any,dataPointOptions: any) => {
						//Vérification de la présence de sous-séries
						if (listeSubLabels?.length)
							//Déclenchement de l'appel dans le contexte angular
							this.ngZone.run(() => options.onSelect?.([data.content[dataPointOptions.dataPointIndex].label,series[dataPointOptions.seriesIndex].name]));
						else
							//Déclenchement de l'appel dans le contexte angular
							this.ngZone.run(() => options.onSelect?.([data.content[dataPointOptions.dataPointIndex].label]));
					}
				},
				fontFamily: this.styleService.getDefaultFontFamily()
			},
			series,
			xAxis: {
				categories: listeCategories,
				labels: {
					rotate: 0,
					trim: true,
					hideOverlappingLabels: false
				},
				min: options.indexStart,
				max: options.indexEnd
			},
			yAxis: {
				labels: {
					formatter: (value: any) => {
						//Formattage du numérique
						return this.decimalPipe.transform(value,'.0-2');
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: (value: any) => {
					//Formattage du numérique
					return this.decimalPipe.transform(value,'.0-2') + ([TypeChart.PIE,TypeChart.DONUT].includes(options.type) ? '%' : '');
				},
				...(options.type == TypeChart.BAR && options.subType != SubTypeChart.EMPILE && {
					offsetY: -20,
					style: {
						colors: ['#002C52']
					}
				})
			},
			fill: {
				colors: listeColors
			},
			legend: (options.type == TypeChart.BAR && options.subType == SubTypeChart.EMPILE || options.type != TypeChart.BAR) && options.isModal || options.isShowLegend ? {
				show: true,
				position: [TypeChart.PIE,TypeChart.DONUT].includes(options.type) ? 'right' : 'bottom',
				showForSingleSeries: true,
				onItemClick: {
					toggleDataSeries: true
				}
			} : {
				show: false
			},
			stroke: ![TypeChart.PIE,TypeChart.DONUT].includes(options.type) ? {
				curve: 'smooth',
				colors: listeColors
			} : null,
			colors: listeColors,
			tooltip: options.isCustomTooltip ? {
				custom: ({ seriesIndex,dataPointIndex }) => {
					//Retour du tooltip
					return data.content[dataPointIndex].tip || data.content[dataPointIndex].listeDatas?.[seriesIndex]?.tip;
				}
			} : [TypeChart.PIE,TypeChart.DONUT].includes(options.type) ? {
				y: {
					formatter: (value,opts) => {
						let percent: number;

						//Récupéraction de la valeur exprimée en pourcentage
						percent = Number(opts.globals.seriesPercent[opts.seriesIndex]);

						//Retour du contenu du tooltip
						return `${this.decimalPipe.transform(percent,'.0-2')}% (${value})`;
					}
				}
			} : null,
			...([TypeChart.PIE,TypeChart.DONUT].includes(options.type) && { labels: listeLabels }),
			plotOptions: {
				bar: {
					borderRadius: options.subType != SubTypeChart.EMPILE ? 6 : 0,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: options.subType != SubTypeChart.EMPILE ? 'top' : 'center',
						total: {
							enabled: true
						}
					}
				}
			}
		};

		//Retour des options
		return chartOptions;
	}
}