import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first,map } from 'rxjs';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleAutomatiqueListItemComponent } from './regle-automatique-list-item.component';
import { RegleAutomatiqueService } from './regle-automatique.service';

@Component({
	templateUrl: './regle-automatique-list.component.html'
})
export class RegleAutomatiqueListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,RegleAutomatiqueListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public regleAutomatiqueService: RegleAutomatiqueService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,RegleAutomatiqueListItemComponent>({
			uri: () => `/controller/RegleAutomatique/filtreReglesAutomatiques`,
			title: this.translateService.instant('regleAutomatique.liste.title'),
			component: RegleAutomatiqueListItemComponent,
			listeFilters: [{
				clef: 'valeur',
				type: TypeFilter.STRING,
				listeValues: this.regleAutomatiqueService.retrieveListeTypesComputedField().pipe(first(),map(listeTypes => listeTypes.flatMap(type => type.listeValeurs))),
				isDefault: true
			},{
				clef: 'type',
				type: TypeFilter.LONG,
				listeValues: this.regleAutomatiqueService.retrieveListeTypesComputedField().pipe(first())
			},{
				clef: 'dateApplication',
				type: TypeFilter.DATE
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			defaultOrder: 'type,valeur,-dateApplication,',
			getKeyFieldName: () => 'idRegleAutomatique',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/RegleAutomatique/loadRegleAutomatique/0')
		});
	}
}