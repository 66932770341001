<div class="pull-left lv-avatar">
	<icon name="warning" *ngIf="data.previsionnel"></icon>
	<icon name="check" *ngIf="!data.previsionnel"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showEntretien();">{{ (data.typeEntretienItem?.libelle || (data.type == 'CONTROLE_REGLEMENTAIRE' ? ('vehicule.controleReglementaire.type.'+data.sousType | translate) : ('entretien.type.'+data.type | translate))) }}&#160;-&#160;{{ data.vehicule | display:'vehicule' }}</a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="!data.previsionnel"><span translate>entretien.realisation</span>&#160;:&#160;{{ data.dateRealisation | date:'shortDate' }}</li>
		<li *ngIf="data.previsionnel && data.type != 'CONTROLE_REGLEMENTAIRE'"><span translate>entretien.prevision</span>&#160;:&#160;{{ data.datePrevisionnelle | date:'shortDate' }}</li>
		<li *ngIf="(data.type == 'COURANT' || !data.previsionnel) && data.fournisseur"><span translate>entretien.fournisseur</span>&#160;:&#160;{{ data.fournisseur.libelle }}</li>
		<li *ngIf="!data.previsionnel && (data.compteur || data.compteurHoraire)"><span translate>entretien.releveCompteur</span>&#160;:&#160;<span>{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.compteur | number) : (data.compteurHoraire | number) }}&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule.unite?.libelleCourt || user.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</span></li>
		<li *ngIf="data.type == 'CONTROLE_REGLEMENTAIRE' && data.dateDebut && data.previsionnel"><span translate>entretien.periodeRealisation.item</span>&#160;:&#160;<span translate [translateParams]="{ dateDebut: data.dateDebut | date:'shortDate',dateFin: data.datePrevisionnelle | date:'shortDate' }">entretien.periodeRealisation.message</span></li>
		<li *ngIf="data.dateRdv"><span translate>entretien.dateRdv</span>&#160;:&#160;<span>{{ data.dateRdv | date:'short' }}</span></li>
	</ul>
</div>