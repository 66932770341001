import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { DataCustomizationRefValueListItemComponent } from './data-customization-ref-value-list-item.component';

@Component({
	selector: 'data-customization-ref-value-list',
	templateUrl: './data-customization-ref-value-list.component.html'
})
export class DataCustomizationRefValueListComponent extends PageContentItem implements OnInit {
	/** Référentiel personnalisable **/
	@Input() dataCustomization: any;

	/** Fonction d'édition **/
	@Input() addCustomRefValue: () => void;

	/** Résumé **/
	@Input() resume:{ nbCustomRef1: number,nbCustomRef2: number,nbCustomRef3: number,nbCustomRef4: number };

	/** N° de référentiel **/
	@Input() num: number;

	/** Données **/
	liste: ListView<any,DataCustomizationRefValueListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,DataCustomizationRefValueListItemComponent>({
			uri: () => `/controller/DataCustomizationEntite/filtreCustomRefValues/${this.dataCustomization?.idDataCustomizationEntite}`,
			title: this.translateService.instant('dataCustomization.elements.custumRef.liste.title'),
			component: DataCustomizationRefValueListItemComponent,
			isLocal: true,
			extraOptions: {
				addCustomRefValue: this.addCustomRefValue,
				num: this.num
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'typeReferentiel',
				valeur: 'REFERENTIEL_'+this.num,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.STRING
			}],
			defaultSearch: 'libelle,reference'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idCustomRefValue > 0)
			//Mise à jour des compteurs
			this.resume['nbCustomRef' + this.num]--;
	}
}