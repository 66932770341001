import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ReferentielList,TypeReferentielList } from 'src/app/domain/referentiel-list/referentiel-list';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ReferentielListService } from './referentiel-list.service';

@Component({
	selector: 'referentiel-list',
	templateUrl: './referentiel-list.component.html'
})
export class ReferentielListComponent implements OnInit,OnDestroy {
	/** Type de référentiel de liste **/
	typeReferentielList: TypeReferentielList;

	/** Structure du référentiel de liste **/
	referentielList: ReferentielList;

	/**
	 * Constructeur
	 */
	constructor(private referentielListService: ReferentielListService,private route: ActivatedRoute,public layoutService: LayoutService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération du type de référentiel de liste
		this.typeReferentielList = this.layoutService.getExtendedRouteData()?.typeReferentielList;

		//Vérification de la présence d'un type de référentiel de liste
		if (this.typeReferentielList) {
			//Récupération du référentiel de liste
			this.referentielListService.retrieveReferentielList(this.typeReferentielList).subscribe((referentielList: ReferentielList) => {
				//Définition du référentiel
				this.referentielList = referentielList;
			});
		} else
			//Génération d'une erreur
			throw new Error(`Le type de référentiel de liste n'est pas défini dans les données de la route`);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Destruction du référentiel de liste
		this.typeReferentielList = null;
	}
}
