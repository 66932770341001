import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { Droit } from 'src/app/domain/droit/droit';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoginService } from 'src/app/share/login/login.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TenantContratEditComponent } from './tenant-contrat-edit.component';
import { TenantContratListComponent } from './tenant-contrat-list.component';
import { TenantDroitComponent } from './tenant-droit.component';
import { TenantEditComponent } from './tenant-edit.component';
import { TenantLicenceListComponent } from './tenant-licence-list.component';
import { TenantService } from './tenant.service';
import { TenantAutorisationListComponent } from './tenant-autorisation-list.component';
import { TenantAutorisationEditComponent } from './tenant-autorisation-edit.component';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { TenantAuthConfigListComponent } from './tenant-auth-config-list.component';
import { TenantAuthConfigEditComponent } from './tenant-auth-config-edit.component';

@Component({
	selector: 'tenant',
	templateUrl: './tenant.component.html'
})
export class TenantComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Tenant courant **/
	public tenant: any;

	/** Contrat CRM **/
	public contratCRM: any;

	/** Liste des locales */
	public listeLocales: Array<any>;

	/** Liste des droits */
	public mapDroits: { [key: string]: Array<Droit> };

	/** Résumé **/
	private resume: { nbContrats: number,nbLicenceConsommations: number,nbDroits: number,nbAutorisations: number,nbAuthConfigs: number } = {
		nbContrats: 0,
		nbLicenceConsommations: 0,
		nbDroits: 0,
		nbAutorisations: 0,
		nbAuthConfigs: 0
	}

	/** Utilisateur courant **/
	user: User;

	/** Utilisateur d'origine **/
	userOrigine: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.TENANT;

	/**
	 * Constructeur
	 */
	constructor(private tenantService: TenantService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private loginService: LoginService,private store: Store<AppState>,private notificationService: NotificationService,private datePipe: DatePipe,private displayPipe: DisplayPipe,private loggedUserService: LoggedUserService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.tenant.Tenant';

	/**
	 * Récupération de l'identifiant du tenant à charger
	 */
	getIdObject: () => number = () => this.tenant?.idTenant || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.tenant;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté et vérification de la présence de notifications
		combineLatest([this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session.user,userOrigine: state.session.userOrigine }))
			,this.notificationService.isNotificationAvailableFor(this.getClassName())
		]).pipe(first()).subscribe({
			next: ([{ user,userOrigine },hasNotification]) => {
				//Mise à jour des utilisateurs
				this.user = user;
				this.userOrigine = userOrigine;

				//Initialisation de la page complexe
				this.options = {
					listeFields: [{
						libelle: 'tenant.raisonSociale',
						key: 'tenant.nom'
					},{
						libelle: 'tenant.reference',
						key: 'tenant.code'
					},{
						libelle: 'tenant.clientCRM.item',
						key: 'clientCRM.reference'
					},{
						libelle: 'tenant.revendeur',
						key: 'tenant.revendeur.libelle'
					},{
						libelle: 'tenant.emailFacturation',
						key: 'emailFacturation'
					},{
						libelle: 'tenant.actif.item',
						key: 'tenant.actif',
						type: 'BOOLEAN'
					},{
						libelle: 'tenant.dateCreation',
						key: 'dateCreation',
						type: 'DATE'
					}],
					listeAlertes: [{
						icon: 'info',
						level: 'INFO',
						title: this.translateService.instant('tenant.alerte.suppression.title'),
						message: () => this.translateService.instant('tenant.alerte.suppression.message',{ user: this.displayPipe.transform(this.tenant?.userDeletion,'user'),date: this.datePipe.transform(this.tenant?.dateDeletion,'short') }),
						isVisible: () => this.tenant?.deleted && !this.pageContentService.isOpened()
					},{
						icon: 'info',
						level: 'INFO',
						title: this.translateService.instant('tenant.alerte.cloture.title'),
						message: () => this.translateService.instant('tenant.alerte.cloture.message',{ user: this.displayPipe.transform(this.tenant?.userClosure,'user'),date: this.datePipe.transform(this.tenant?.dateClosure,'short') }),
						isVisible: () => this.tenant?.closed && !this.pageContentService.isOpened()
					}],
					listeActions: [{
						libelle: 'actions.notifier',
						doAction: () => this.notificationService.showSelectionMailForEntite(this.getClassName(),this.tenant.idTenant,{
							getOwningEntity: () => this.tenant,
							typeAttachment: TypeAttachment.TENANT
						}),
						isVisible: () => hasNotification
					},{
						libelle: 'actions.modifier',
						doAction: () => this.editTenant(),
						isVisible: () => this.rightService.hasRight(null,'creation'),
						type: TypeAction.EDITION
					},{
						libelle: 'actions.consulter',
						doAction: () => this.editTenant(),
						isVisible: () => !this.rightService.hasRight(null,'creation'),
						type: TypeAction.CONSULTATION
					},{
						libelle: 'actions.supprimer',
						doAction: () => this.deleteTenant(),
						isVisible: () => this.rightService.hasRight(null,'suppression') && !this.tenant?.deleted && this.rightService.isRoot() && !this.loggedUserService.hasProfilRestriction(TypeRestriction.CLIENT),
						type: TypeAction.SUPPRESSION
					},{
						libelle: 'actions.seConnecter',
						doAction: () => this.loginAsAdminTenant(),
						isVisible: () => this.canConnectAs() && (!this.tenant?.deleted || !this.tenant?.closed)
					},{
						libelle: 'tenant.actions.synchroniserConnecteurs',
						doAction: () => this.syncListeConnecteursInterface(),
						isVisible: () => this.rightService.isRoot() && !this.tenant?.deleted && !this.tenant?.closed && this.mapDroits?.['ADMIN']?.find((droit: Droit) => droit.typeDroit == TypeDroit.CONNECTEUR && droit.creation) != null
					},{
						libelle: 'actions.cloturer',
						doAction: () => this.closeTenant(),
						isVisible: () => this.rightService.hasRight(null,'suppression') && !this.tenant?.deleted && !this.tenant?.closed && this.rightService.isRoot() && !this.loggedUserService.hasProfilRestriction(TypeRestriction.CLIENT),
						type: TypeAction.SUPPRESSION
					}],
					listeElements: [{
						type: 'CONTRAT',
						libelle: 'tenant.elements.contratCRM',
						component: TenantContratListComponent,
						retrieveComponentData: () => ({
							tenant: this.tenant,
							addContratCRM: this.addContratCRM.bind(this)
						}),
						count: () => this.resume.nbContrats,
						isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_CONTRAT_CRM,'consultation')
					},{
						type: 'DROIT',
						libelle: 'tenant.elements.droit',
						component: TenantDroitComponent,
						retrieveComponentData: () => ({
							mapDroits: this.mapDroits
						}),
						count: () => this.resume.nbDroits,
						isVisible: () => this.rightService.hasRight(null,'consultation')
					},{
						type: 'LICENCE_CONSOMMATION',
						libelle: 'tenant.elements.licenceConsommation',
						component: TenantLicenceListComponent,
						retrieveComponentData: () => ({
							tenant: this.tenant,
							goToLicenceConsommation: this.goToLicenceConsommation.bind(this)
						}),
						count: () => this.resume.nbLicenceConsommations,
						isVisible: () => this.rightService.hasRight(TypeDroit.LICENCE_CONSOMMATION,'consultation')
					},{
						type: 'AUTORISATION',
						libelle: 'tenant.elements.autorisation',
						component: TenantAutorisationListComponent,
						retrieveComponentData: () => ({
							tenant: this.tenant,
							resume: this.resume,
							addAutorisation: this.addAutorisation.bind(this)
						}),
						count: () => this.resume.nbAutorisations,
						isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_TENANT_AUTORISATION,'consultation'),
						libelleAction: this.translateService.instant('tenant.elements.autorisation.ajouter'),
						doAction: () => this.addAutorisation(),
						isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_TENANT_AUTORISATION,'creation')
					},{
						type: 'AUTHENTIFICATION_CONFIGURATION',
						libelle: 'tenant.elements.authConfiguration',
						component: TenantAuthConfigListComponent,
						retrieveComponentData: () => ({
							tenant: this.tenant,
							resume: this.resume,
							addAuthConfig: this.addAuthConfig.bind(this)
						}),
						count: () => this.resume.nbAuthConfigs,
						isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_AUTHENTIFICATION,'consultation'),
						libelleAction: this.translateService.instant('tenant.elements.authConfiguration.ajouter'),
						doAction: () => this.addAuthConfig(),
						isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_AUTHENTIFICATION,'creation')
					}]
				};
			}
		});

		//Récupération de l'identifiant du tenant à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idTenant,params.idContrat);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idTenant: number,idContrat: number = 0,checkSynchronisation: boolean = false) {
		//Enregistrement du tenant
		this.tenantService.loadTenant(idTenant,idContrat).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du tenant
					this.tenant = result.data?.tenant;

					//Liste des locales
					this.listeLocales = result.data?.listeLocale;

					//Liste des droits
					this.mapDroits = result.data?.mapDroits;

					//Définition du résumé
					this.resume = {
						nbContrats: result.data?.nbContrats || 0,
						nbLicenceConsommations: result.data?.nbLicenceConsommations || 0,
						nbDroits: Object.entries(this.mapDroits).flatMap(([,listeDroits]) => listeDroits)?.length || 0,
						nbAutorisations: result.data?.nbAutorisations || 0,
						nbAuthConfigs: result.data?.nbAuthConfigs || 0
					}

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du tenant
					if (!this.tenant?.idTenant) {
						//Définition du contrat CRM
						this.contratCRM = result.data?.contrat;

						//Création d'un objet vierge
						this.tenant = {
							tenant: {
								actif: true,
								revendeur: this.contratCRM?.revendeur,
								type: 'CLIENT',
								transmissionAccount: true,
								authentificationMode: 'LOGIN_MDP',
								nom: this.contratCRM?.clientCRM?.libelle || null,
								code: this.contratCRM?.clientCRM?.reference || null
							},
							theme: { actif: false },
							timezone: 'EUROPE_PARIS',
							clientCRM: this.contratCRM?.clientCRM
						};

						//Edition du tenant
						this.editTenant();
					} else if (checkSynchronisation) {
						//Vérification de la présence d'un environnement d'intégration
						if (this.tenant?.integrationAvailable)
							//Message d'information
							this.toastrService.success(this.translateService.instant('actions.synchronisation.success'));
						else
							//Proposition de création de l'environnement du tenant pour intégration
							this.createEnvironnementIntegration();
					}
				}
			}
		});
	}

	/**
	 * Edition du tenant
	 */
	private editTenant() {
		//Vérification de la présence d'un thème
		if (!this.tenant?.theme)
			//Initialisation du thème
			this.tenant.theme = {};

		//Ouverture du composant d'édition
		this.pageContentService.open(TenantEditComponent,{
			data: {
				tenant: this.tenant.idTenant ? cloneDeep(this.tenant) : this.tenant,
				deleteTenant: this.deleteTenant.bind(this),
				listeLocales: cloneDeep(this.listeLocales)
			}
		}).subscribe({
			next: (tenant: any) => {
				//Vérification des données
				if (tenant) {
					//Mise à jour du tenant
					Object.assign(this.tenant,tenant);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.tenant);
				}
			}
		});
	}
	/**
	 * Connexion à la place du tenant
	 */
	private loginAsAdminTenant() {
		//Connexion en tant qu'administrateur pour le tenant
		this.loginService.loginAsAdminTenant(this.tenant);
	}

	/**
	 * Vérification de la possibilité de se connecter à la place du tenant
	 */
	private canConnectAs(): boolean {
		//Vérification de la possibilité de se connecter à la place du tenant
		return (!this.userOrigine || this.user?.tenant?.type == 'REVENDEUR' && ['ADMINISTRATEUR','UTILISATEUR_AUTO'].indexOf(this.user.type) != -1)
			&& this.rightService.hasRight(TypeDroit.ADMIN_ENVIRONNEMENT,'consultation')
			&& this.tenant?.idTenant != this.user.tenant.idTenant
			&& this.tenant?.adminEmail?.length;
	}

	/**
	 * Suppression du tenant
	 */
	protected deleteTenant() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation'),{
			withTyping: true,
			confirmationTerm: this.translateService.instant('tenant.suppression.confirmationTerm'),
			alerteInfo: `
				<i class="material-icons-round">warning</i>
				<div>
					<strong>${this.translateService.instant('tenant.suppression.attention.1')}</strong>
					<div>${this.translateService.instant('tenant.suppression.attention.2')}</div>
					<div>${this.translateService.instant('tenant.suppression.attention.3',{ confirmationTerm: this.translateService.instant('tenant.suppression.confirmationTerm') })}</div>
				</div>
			`
		}).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.deleteTenant(this.tenant))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Clôture du tenant
	 */
	protected closeTenant() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('tenant.cloture.title'),{
			withTyping: true,
			confirmationTerm: this.translateService.instant('tenant.cloture.confirmationTerm'),
			alerteInfo: `
				<i class="material-icons-round">warning</i>
				<div>
					<strong>${this.translateService.instant('tenant.cloture.attention.1')}</strong>
					<div>${this.translateService.instant('tenant.cloture.attention.2')}</div>
					<div>${this.translateService.instant('tenant.cloture.attention.3',{ confirmationTerm: this.translateService.instant('tenant.cloture.confirmationTerm') })}</div>
				</div>
			`
		}).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.closeTenant(this.tenant)),
			first()
		).subscribe((closureResult) => {
			//Vérification du code d'erreur
			if (closureResult?.result?.data?.tenant) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.cloture.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.cloture.error'));
		});
	}

	/**
	 * Synchronisation des connecteurs
	 */
	private syncListeConnecteursInterface() {
		//Synchronisation des connecteurs
		this.tenantService.syncListeConnecteursInterface(this.tenant).subscribe({
			next: () => {
				//Chargement du tenant
				this.loadData(this.tenant?.idTenant,this.contratCRM?.idContrat,true);
			},
			error: (data: { statut: any }) => {
				//Vérification de la présence d'un statut
				if (data.statut != null) {
					//Vérification du statut
					if (data.statut == 'NOT_ESTABLISHED')
						//Demande pour la création d'un environnement
						this.createEnvironnementIntegration();
					else
						//Affichage du message d'erreur
						this.toastrService.error(this.translateService.instant(`tenant.connecteur.statutEnvironnement.${data.statut}`));
				}
			}
		})
	}

	/**
	 * Proposition de création de l'environnement du tenant pour intégration
	 */
	private createEnvironnementIntegration() {
		//Demande de confirmation pour la création d'un environnement
		this.confirmService.showConfirm(this.translateService.instant('tenant.connecteur.creation.title'),{ withTitle: true,alerteInfo: this.translateService.instant('tenant.connecteur.creation.message'),actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.tenantService.createEnvironnementIntegration(this.tenant))
		).subscribe({
			next: () => {
				//Rechargement du tenant
				this.loadData(this.tenant?.idTenant,this.contratCRM?.idContrat);
			},
			error: () => {
				//Affichage du message d'erreur
				this.toastrService.error(this.translateService.instant(`tenant.connecteur.statutEnvironnement.ERROR`));
			}
		});
	}

	/**
	 * Modification du contrat CRM
	 */
	protected addContratCRM(contratCRM: any = { tenant: Object.assign({},this.tenant) }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TenantContratEditComponent,{
			data: {
				contratCRM: cloneDeep(contratCRM)
			}
		},'sub').subscribe({
			next: (data: { contratCRM: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('CONTRAT',contratCRM.idContrat != data.contratCRM?.idContrat);

					//Vérification de la présence d'une création ou d'une suppression
					if (!contratCRM.idContrat && data.contratCRM?.idContrat)
						//Incrémentation du compteur
						this.resume.nbContrats++;
					else if (contratCRM.idContrat && !data.contratCRM?.idContrat)
						//Décrémentation du compteur
						this.resume.nbContrats--;

					//Mise à jour du contrat
					Object.assign(contratCRM,data.contratCRM);
				}
			}
		});
	}

	/**
	 * Ajout d'une autorisation
	 */
	protected addAutorisation(autorisation: any = { tenant: Object.assign({},this.tenant) },deleteAutorisation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TenantAutorisationEditComponent,{
			data: {
				autorisation: cloneDeep(autorisation),
				deleteAutorisation,
				source: 'TENANT'
			}
		},'sub').subscribe({
			next: (data: { autorisation: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AUTORISATION',autorisation.idAutorisation != data.autorisation?.idAutorisation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!autorisation.idAutorisation && data.autorisation?.idAutorisation)
						//Incrémentation du compteur
						this.resume.nbAutorisations++;
					else if (autorisation.idAutorisation && !data.autorisation?.idAutorisation)
						//Décrémentation du compteur
						this.resume.nbAutorisations--;

					//Mise à jour de l'autorisation
					Object.assign(autorisation,data.autorisation);
				}
			}
		});
	}

	/**
	 * Redirection vers la consommation de licence
	 */
	protected goToLicenceConsommation(licenceConsommation: any) {
		//Navigation vers la consommation de licence
		this.layoutService.goToByState('listeLicenceConsommations-licenceConsommation',{
			routeParams: {
				idLicenceConsommation: licenceConsommation?.idLicenceConsommation || 0
			},
			reloadOnSameUrl: true,
			withGoBack: true
		});
	}

	/**
	 * Ajout/modification d'une configuration d'authentification
	 */
	protected addAuthConfig(authConfig: any = { tenant: Object.assign({},this.tenant),actif: false },deleteAuthConfig?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TenantAuthConfigEditComponent,{
			data: {
				authConfig: cloneDeep(authConfig),
				deleteAuthConfig,
				idTenant: this.tenant?.idTenant
			}
		},'sub').subscribe({
			next: (data: { authConfig: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AUTHENTIFICATION_CONFIGURATION',authConfig.idConfig != data.authConfig?.idConfig);

					//Vérification de la présence d'une création ou d'une suppression
					if (!authConfig.idConfig && data.authConfig?.idConfig)
						//Incrémentation du compteur
						this.resume.nbAuthConfigs++;
					else if (authConfig.idConfig && !data.authConfig?.idConfig)
						//Décrémentation du compteur
						this.resume.nbAuthConfigs--;

					//Mise à jour de la configuration
					Object.assign(authConfig,data.authConfig);
				}
			}
		});
	}
}