<form #affectationEquipementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.elements.equipement.title</h2>
		</div>
		<div class="card-body card-padding">
			<equipement-affectation-content #equipementAffectationContent [affectationEquipement]="affectationEquipement" source="VEHICULE" (close)="close({ affectationEquipement: $event })"></equipement-affectation-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" (click)="equipementAffectationContent.saveAffectationEquipement();" *ngIf="TypeDroit.EQUIPEMENT | right:'creation'" [disabled]="affectationEquipementForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: affectationEquipementForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>