<div *ngIf="detail.typeDetail == 'BUSINESS_DATA'" class="detail-item business-data">
	<div>
		<a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent,parentKey });"><span>{{ detail.businessData.libelle }}</span></a>

		<div class="dropdown" *ngIf="['java.lang.Double','java.lang.Long','java.lang.Integer'].indexOf(detail.businessData.type) != -1">
			<mat-button-toggle-group value="SELECT" data-toggle="dropdown">
				<mat-button-toggle value="SELECT"><span [translate]="'rule.agregation.'+detail.businessData.typeAgregation"></span></mat-button-toggle>
				<mat-button-toggle><icon name="expand_more"></icon></mat-button-toggle>
			</mat-button-toggle-group>
			<ul class="dropdown-menu dropdown-menu-right">
				<li *ngFor="let typeAgregation of listeTypesAgregation">
					<a [routerLink]="[]" (click)="detail.businessData.typeAgregation = typeAgregation.code">{{ typeAgregation.libelle }}</a>
				</li>
			</ul>
		</div>

		<div class="dropdown" *ngIf="['java.util.Date'].indexOf(detail.businessData.type) != -1">
			<mat-button-toggle-group value="SELECT" data-toggle="dropdown">
				<mat-button-toggle value="SELECT"><span [translate]="'structureExport.rubrique.typeExtraction.'+detail.businessData.typeExtraction"></span></mat-button-toggle>
				<mat-button-toggle><icon name="expand_more"></icon></mat-button-toggle>
			</mat-button-toggle-group>
			<ul class="dropdown-menu dropdown-menu-right">
				<li *ngFor="let typeExtraction of listeTypesExtraction">
					<a [routerLink]="[]" (click)="detail.businessData.typeExtraction = typeExtraction.code">{{ typeExtraction.libelle }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>
<div *ngIf="detail.typeDetail == 'CONSTANTE'" class="detail-item constante">
	<div>
		<a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent,parentKey });" class="flex-1">
			<span *ngIf="detail.constante.typeConstante == 'ALPHANUMERIQUE'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.ALPHANUMERIQUE.item</span></span>
			<span *ngIf="detail.constante.typeConstante == 'NUMERIQUE'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.NUMERIQUE.item</span></span>
			<span *ngIf="detail.constante.typeConstante == 'DATE'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.DATE.item</span></span>
			<span *ngIf="detail.constante.typeConstante == 'VIDE'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.CONSTANTE.VIDE.item</span></span>
			<span *ngIf="detail.constante.typeVariable == 'DATE_GENERATION'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.VARIABLE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.VARIABLE.DATE_GENERATION.item</span></span>
			<span *ngIf="detail.constante.typeVariable == 'NOM_FICHIER'"><span translate>structureExport.rubrique.typeRubrique.CONSTANTE.VARIABLE.item</span>&#160;>&#160;<span translate>structureExport.rubrique.typeRubrique.CONSTANTE.VARIABLE.NOM_FICHIER.item</span></span>
		</a>
		<span *ngIf="detail.constante.typeConstante" class="form-group flex-1">
			<input type="text" name="constante" class="form-control" [(ngModel)]="detail.constante.value" *ngIf="detail.constante.typeConstante == 'ALPHANUMERIQUE'"/>
			<input type="text" name="constante" class="form-control" [(ngModel)]="detail.constante.numericValue" nNumber="2" *ngIf="detail.constante.typeConstante == 'NUMERIQUE'"/>
			<input type="text" name="constante" class="form-control" [(ngModel)]="detail.constante.dateValue" datePicker *ngIf="detail.constante.typeConstante == 'DATE'"/>
		</span>
		<div class="flex-1">
			<span class="dropdown" *ngIf="detail.constante.typeConstante == 'DATE' || detail.constante.typeVariable == 'DATE_GENERATION'">
				<mat-button-toggle-group value="SELECT" data-toggle="dropdown">
					<mat-button-toggle value="SELECT"><span [translate]="'structureExport.rubrique.typeExtraction.'+(detail.constante.typeExtraction || 'NON_DEFINI')"></span></mat-button-toggle>
					<mat-button-toggle><icon name="expand_more"></icon></mat-button-toggle>
				</mat-button-toggle-group>
				<ul class="dropdown-menu dropdown-menu-right">
					<li *ngFor="let typeExtraction of listeTypesExtraction">
						<a [routerLink]="[]" (click)="detail.constante.typeExtraction = typeExtraction.code">{{ typeExtraction.libelle }}</a>
					</li>
				</ul>
			</span>
		</div>
	</div>
</div>
<div *ngIf="detail.typeDetail == 'COMPTEUR'" class="detail-item compteur">
	<div>
		<a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent,parentKey });"><span>{{ detail.compteur.libelle }}</span></a>
	</div>
</div>
<div *ngIf="detail.typeDetail == 'MEMOIRE'" class="detail-item memoire">
	<div>
		<a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent,parentKey });" class="flex-1">{{ detail.memoire.ligne.libelle }}</a>
		<span class="form-group" class="flex-1">
			<div>
				<autocomplete name="memoire" type="structureExportRubrique" [filter]="{ idLigne: detail.memoire.ligne.idLigne }" [(ngModel)]="detail.memoire.rubrique"></autocomplete>
			</div>
		</span>
		<span class="flex-1"></span>
	</div>
</div>
<div *ngIf="detail.typeDetail == 'FONCTION' && detail.fonction.typeFonction == 'SI'" class="detail-item fonction si">
	<structure-export-rubrique-fonction-si [detail]="detail" [parent]="parent" [parentKey]="parentKey" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey });"></structure-export-rubrique-fonction-si>
</div>
<div *ngIf="detail.typeDetail == 'FONCTION' && detail.fonction.typeFonction == 'EXTRAIRE'" class="detail-item fonction extraire">
	<structure-export-rubrique-fonction-extraire [detail]="detail" [parent]="parent" [parentKey]="parentKey" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey });"></structure-export-rubrique-fonction-extraire>
</div>
<div *ngIf="detail.typeDetail == 'FONCTION' && detail.fonction.typeFonction == 'REMPLACER'" class="detail-item fonction remplacer">
	<structure-export-rubrique-fonction-remplacer [detail]="detail" [parent]="parent" [parentKey]="parentKey" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey });"></structure-export-rubrique-fonction-remplacer>
</div>
<div *ngIf="detail.typeDetail == 'FONCTION' && detail.fonction.typeFonction == 'CONCATENER'" class="detail-item fonction concatener">
	<structure-export-rubrique-fonction-concatener [detail]="detail" [parent]="parent" [parentKey]="parentKey" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey });"></structure-export-rubrique-fonction-concatener>
</div>
<div *ngIf="detail.typeDetail == 'FONCTION' && detail.fonction.typeFonction == 'MATH'" class="detail-item fonction math">
	<structure-export-rubrique-fonction-math [detail]="detail" [parent]="parent" [parentKey]="parentKey" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey });"></structure-export-rubrique-fonction-math>
</div>