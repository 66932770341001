import { Component,ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChartOptions } from 'src/app/domain/chart/chart';

@Component({
	templateUrl: './chart-tip.component.html'
})
export class ChartTipComponent {
	/** Données **/
	data: any;

	/** Libellé **/
	libelle: string;

	/** Valeur **/
	value: string;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private translateService: TranslateService) {

	}

	/**
	 * Génération des valeurs
	 */
	public generate(options: ChartOptions,labelParent?: string) {
		let value: string;

		//Formattage des décimaux
		value = this.data.value != null && typeof this.data.value == 'number' && !Number.isInteger(this.data.value) ? this.data.value.toFixed(2) : this.data.value;

		//Définition de la valeur
		this.value = this.data.total ? this.translateService.instant('dashboard.chart.tooltip.total',{ count: value,total: this.data.total }) : value;

		//Vérification de la présence de plusieurs libellés
		if (this.data.listeLabels)
			//Définition du libellé multiple
			this.libelle = this.data.listeLabels.map(label => options.getLabel({ label },labelParent ? 1 : 0)).join(', ');
		else
			//Définition du libellé standard
			this.libelle = (labelParent ? labelParent + ' > ' : '') + options.getLabel(this.data,labelParent ? 1 : 0);
	}

	/**
	 * Récupération du contenu HTML
	 */
	public getContent(): string {
		let content: string;

		//Récupération du contenu
		content = (this.elementRef.nativeElement as HTMLElement).querySelector('.tooltip-inner').outerHTML;

		//Suppression de l'élément
		setTimeout(() => (this.elementRef.nativeElement as HTMLElement).remove());

		//Retour du contenu HTML
		return content;
	}
}