import { Directive,Output,EventEmitter,ElementRef,NgZone,Inject,Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable,Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

@Directive({
	selector: '[recaptcha]',
	exportAs: 'reCaptcha'
})
export class RecaptchaDirective {
	/** Evènement en cas de récupération d'un token **/
	@Output() onRecaptchaSuccess = new EventEmitter<string>();

	/** Evènement en cas d'expiration du token **/
	@Output() onRecaptchaExpired = new EventEmitter<string>();

	/** Version du recaptcha à utiliser **/
	@Input() isVersion3: boolean = false;

	/** Indicateur d'exécution automatique de l'action du captcha v3 **/
	@Input() isAuto: boolean = true;

	/** Identifiant du captcha **/
	private readonly scriptId = 'script-recaptcha';

	/** Identifiant du widget **/
	widgetId: number | null = null;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private ngZone: NgZone,@Inject(DOCUMENT) private readonly document: Document) {}

	/**
	 * Initialisation de la directive
	 */
	ngOnInit() {
		//Initialisation du captcha
		this.registerCaptchaCallback();

		//Ajout du script au DOM
		this.loadScript();
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Réinitialisation de l'identifiant du widget
		this.widgetId = null;
	}

	/**
	 * Initialisation du captcha
	 */
	private registerCaptchaCallback() {
		//Création de la méthode de callback appelée après le chargement du script
		(window as any).recaptchaCallback = this.onLoadCallback.bind(this);
	}

	/**
	 * Finalisation du chargement
	 */
	private onLoadCallback() {
		//Vérification de la version
		if (!this.isVersion3) {
			//Définition du widget
			this.widgetId = this.renderCaptcha({
				'sitekey': environment.recaptchaKeyV2,
				'callback': this.onSuccessCallback.bind(this),
				'expired-callback': this.onExpiredCallback.bind(this)
			});
		} else
			//Exécution de l'action en mode automatique
			this.isAuto && this.executeAction();
	}

	/**
	 * Création du script reCaptcha
	 */
	private loadScript() {
		let script;

		//Vérification de la présence du script
		if (!this.document.getElementById(this.scriptId)) {
			//Création du script
			script = this.document.createElement('script');

			//Définition du script
			script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=${this.isVersion3 ? environment.recaptchaKeyV3 : 'explicit'}`;
			script.id = this.scriptId;
			script.async = true;
			script.defer = true;

			//Ajout du script au DOM
			this.document.body.appendChild(script);
		} else
			//Affichage du captcha
			this.onLoadCallback();

	}

	/**
	 * Remise à zéro du captcha
	 */
	reset() {
		//Remise à zéro du captcha
		(window as any).grecaptcha.reset();
	}

	/**
	 * Recaptcha réussi avec succès
	 */
	private onSuccessCallback(token: string) {
		//Mise en cycle
		this.ngZone.run(() => {
			//Emission du token
			this.onRecaptchaSuccess.emit(token);
		});
	}

	/**
	 * Recaptcha expiré
	 */
	private onExpiredCallback() {
		//Mise en cycle
		this.ngZone.run(() => {
			//Emission d'une notification
			this.onRecaptchaExpired.emit();
		});
	}

	/**
	 * Affichage du captcha
	 */
	private renderCaptcha(config: any): number {
		//Affichage du captcha dans le DOM
		return (window as any).grecaptcha.render(this.elementRef.nativeElement,config);
	}

	/**
	 * Récupération du token v3 en mode manuel
	 */
	getResponse(): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Vérification de l'état du captcha
		(window as any).grecaptcha.ready(() => {
			//Exécution de l'action
			(window as any).grecaptcha.execute(environment.recaptchaKeyV3,{ action: 'submit' }).then(token => {
				//Emission du token
				subject.next(token);
			});
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Exécution de l'action
	 */
	public executeAction() {
		let callback;

		//Définition du callback
		callback = ((token) => {
			//Mise en cycle
			this.ngZone.run(() => {
				//Emission du token
				this.onRecaptchaSuccess.emit(token);
			});
		}).bind(this);

		//Vérification de l'état du captcha
		(window as any).grecaptcha.ready(() => {
			//Exécution de l'action
			(window as any).grecaptcha.execute(environment.recaptchaKeyV3,{ action: 'submit' }).then(callback);
		});
	}
}