import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FournisseurFormatInterfaceListItemComponent } from './fournisseur-format-interface-list-item.component';

@Component({
	selector: 'fournisseur-format-interface-list',
	templateUrl: './fournisseur-format-interface-list.component.html'
})
export class FournisseurFormatInterfaceListComponent extends PageContentItem implements OnInit {
	/** Fournisseur **/
	@Input() fournisseur: any;

	/** Données **/
	liste: ListView<any,FournisseurFormatInterfaceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FournisseurFormatInterfaceListItemComponent>({
			uri: () => `/controller/Fournisseur/filtreInterfacesForFournisseur/${this.fournisseur.idFournisseur || 0}`,
			component: FournisseurFormatInterfaceListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			}]
		});
	}
}