<div class="pull-left lv-avatar">{{ data?.type?.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addContratFinancement()">{{ data | display:'contratFinancement' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idContrat">
		<li *ngIf="data.dateDebut"><span [translate]="'contrat.financement.'+(data.type.mode != 'ACQUISITION' ? 'dateDebut' : 'dateAchat')"></span>&#160;:&#160;{{ data.dateDebut | date:'shortDate' }}</li>
		<li *ngIf="data.dateFin && !data.dateRuptureAnticipee"><span translate>contrat.financement.dateFin</span>&#160;:&#160;{{ data.dateFin | date:'shortDate' }}</li>
		<li *ngIf="data.listeLoisRoulage?.length > 1" [tooltip]="getListeAvenantsAsString()"><span translate>contrat.financement.nbAvenants</span>&#160;:&#160;{{ data.listeLoisRoulage.length - 1 }}</li>
		<li *ngIf="data.dateRuptureAnticipee"><span translate>contrat.financement.dateRuptureAnticipee</span>&#160;:&#160;{{ data.dateRuptureAnticipee | date:'shortDate' }}</li>
		<li *ngIf="data.lastLoiRoulage"><span translate>contrat.financement.loiRoulage.item</span>&#160;:&#160;<span translate [translateParams]="{ duree: data.lastLoiRoulage.duree,distance: (data.vehicule.uniteUsage == 'DISTANCE' ? (data.lastLoiRoulage.distance | number) : (data.lastLoiRoulage.temps | number)),unite: data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt) : ('duree.heure.libelleCourt' | translate) }">contrat.financement.loiRoulage.dureeMois</span></li>
		<li *ngIf="data.etat"><span translate>contrat.financement.etat.item</span>&#160;:&#160;<span [translate]="'contrat.financement.etat.'+data.etat"></span></li>
		<li *ngIf="data.etat == 'ACHAT'"><span translate>contrat.financement.dateAchat</span>&#160;:&#160;{{ data.dateAchat | date:'shortDate' }}</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idContrat">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'suppression'"><a [routerLink]="[]" (click)="deleteContratFinancement();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="TypeDroit.VEHICULE_CONTRAT_FINANCEMENT | right:'consultation'"><a [routerLink]="[]" (click)="goToContratFinancement();"><span translate>vehicule.elements.contratFinancement.acceder</span></a></li>
		</ul>
	</div>
</div>