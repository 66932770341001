import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { AccessForbiddenComponent } from './access-forbidden.component';
import { AccessForbiddenService } from './access-forbidden.service';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [AccessForbiddenComponent],
	providers: [AccessForbiddenService]
})
export class AccessForbiddenModule {}