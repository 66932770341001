<form #reponseForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div *ngIf="source == 'FOURNISSEUR' || isFromDemandeCotation">
		<demande-cotation-cotation-content [cotation]="reponseCotation.cotation" [reponseCotation]="reponseCotation"></demande-cotation-cotation-content>
	</div>
	<div class="card detail" *ngIf="reponseCotation.answered === undefined && reponseCotation.statut == 'A_COMPLETER'">
		<div class="card-header">
			<div class="row p-t-20">
				<div class="col-xs-12">
					<div class="form-group">
						<label translate>reponseDemandeCotation.reponseCotation.repondre</label>
						<div>
							<mat-button-toggle-group name="answered" [(ngModel)]="reponseCotation.answered" (ngModelChange)="!reponseCotation?.answered && doDeclineCotation()">
								<mat-button-toggle [value]="true"><span translate>common.oui</span></mat-button-toggle>
								<mat-button-toggle [value]="false"><span translate>common.non</span></mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="reponseCotation.statut != 'DECLINEE'">
		<reponse-cotation-reponse-content [isFromDemandeCotation]="isFromDemandeCotation" [source]="source" [reponseCotation]="reponseCotation" [isReadOnly]="isReadOnly"></reponse-cotation-reponse-content>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" (click)="saveReponseCotation();" [disabled]="reponseForm.invalid" *ngIf="(null | right:'creation') && !isReadOnly && (source == 'COTATION_SOLDEE' || reponseCotation.statut == 'A_TRANSMETTRE' || reponseCotation.answered)">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: reponseForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>