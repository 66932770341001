import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { assign,cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { WorkflowDetailComponent } from './workflow-detail.component';
import { WorkflowEditComponent } from './workflow-edit.component';
import { WorkflowService } from './workflow.service';

@Component({
	selector: 'workflow',
	templateUrl: './workflow.component.html'
})
export class WorkflowComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Workflow courant **/
	public workflow: any;

	/** Liste des entités **/
	public listeEntites$: Observable<any>;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Mode plein écran **/
	protected isFullScreen: boolean = false;

	/** Informations sur le statut **/
	private statutInformation: any;

	/**
	 * Constructeur
	 */
	constructor(private workflowService: WorkflowService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private entiteService: EntiteService,private extractionService: ExtractionService,private menuService: MenuService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.workflow.Workflow';

	/**
	 * Récupération de l'identifiant du workflow à charger
	 */
	getIdObject: () => number = () => this.workflow?.idWorkflow || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.workflow;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Liste des entités
		this.listeEntites$ = this.extractionService.getListeEntites('WORKFLOW');

		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'workflow.libelle',
				key: 'libelle'
			},{
				libelle: 'workflow.reference',
				key: 'reference'
			},{
				libelle: 'workflow.entite.item',
				key: data => this.entiteService.translateEntityCode(data.entite.split('.').pop(-1)),
			},{
				libelle: 'workflow.dateApplication',
				key: 'dateApplication',
				type: 'DATE',
				format: 'date'
			},{
				libelle: 'workflow.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editWorkflow(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editWorkflow(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicateWorkflow(),
				isVisible: () => this.rightService.hasRight(null,'creation')
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteWorkflow(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeElements: [{
				type: 'DETAIL',
				component: WorkflowDetailComponent,
				retrieveComponentData: () => ({
					workflow: this.workflow,
					toggleFullScreen: this.toggleFullScreen.bind(this),
					addWorkflowDetail: this.addWorkflowDetail.bind(this)
				}),
				isTabHidden: true,
				isVisible: () => true
			}],
			isTabActionsHidden: true,
			isFullScreen: () => this.isFullScreen,
			customStyle: {
				elements: {
					header: ' ',
					body: ' ',
					row: ' '
				}
			}
		};

		//Récupération de l'identifiant du workflow à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idWorkflow);
			}
		});
	}

	/**
	 * Modification du mode 'Plein écran'
	 */
	protected toggleFullScreen() {
		//Vérification du plein écran
		this.isFullScreen = !this.isFullScreen;

		//Fermeture du menu
		this.isFullScreen && this.menuService.hide()
	}

	/**
	 * Chargement des données
	 */
	private loadData(idWorkflow: number) {
		//Chargement du workflow
		this.workflowService.loadWorkflow(idWorkflow).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du workflow
					this.workflow = result.data?.workflow;

					//Définition des informations du statut
					this.statutInformation = result.data?.statutInformation

					//Vérification de l'identifiant du workflow
					if (!this.workflow?.idWorkflow) {
						//Création d'un objet vierge
						this.workflow = {
							actif: false
						};

						//Edition du workflow
						this.editWorkflow();
					} else
						//Initialisation du workflow
						this.initWorkflow();
				}
			}
		});
	}

	/**
	 * Initialisation du workflow
	 */
	private initWorkflow(workflow: any = this.workflow) {
		let listeStatuts: Array<any>;

		//Définition de la liste des statuts
		listeStatuts = cloneDeep(workflow.listeStatuts) || [];

		//Itération sur les statuts
		this.statutInformation?.listeStatuts?.forEach(infoStatut => {
			let statut: any;

			//Recherche du statut
			statut = listeStatuts.find(s => s.statut == infoStatut);

			//Vérification de l'existance du statut
			if (statut == null) {
				//Ajout d'un nouveau statut à la liste
				listeStatuts.push({
					statut: infoStatut,
					branche: {
						listeEtapes: []
					},
					statutType: this.statutInformation.type
				});
			}
		});

		//Vérification de la branche initiale
		if (this.statutInformation.initialBranche && listeStatuts?.[0]?.branche?.listeEtapes?.length == 0)
			//Définition de la branche initiale
			listeStatuts[0].branche = this.statutInformation.initialBranche;

		//Mise à jour des statuts
		this.workflow.listeStatuts = listeStatuts;
	}

	/**
	 * Edition du workflow
	 */
	private editWorkflow() {
		//Ouverture du composant d'édition
		this.pageContentService.open(WorkflowEditComponent,{
			data: {
				workflow: cloneDeep(this.workflow),
				deleteWorkflow: this.deleteWorkflow.bind(this),
				listeEntites$: this.listeEntites$
			}
		}).subscribe({
			next: (result: { workflow: any,statutInformation: any }) => {
				//Vérification de la présence des informations du statut
				if (result?.statutInformation)
					//Définition des informations du statut
					this.statutInformation = result.statutInformation;

				//Vérification des données
				if (result?.workflow) {
					//Mise à jour du Workflow
					Object.assign(this.workflow,result.workflow);

					//Initialisation du Workflow
					this.initWorkflow();

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.workflow);
				}
			}
		});
	}

	/**
	 * Suppression du workflow
	 */
	protected deleteWorkflow() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.workflowService.deleteWorkflow(this.workflow))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Duplication du workflow
	 */
	protected duplicateWorkflow(workflow: any = this.workflow) {
		//Duplication du workflow
		this.workflowService.duplicateWorkflow(workflow).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Navigation vers le workflow dupliqué
					this.layoutService.goToByState('listeWorkflows-workflow',{
						routeParams: {
							idWorkflow: result.data.workflow?.idWorkflow
						},
						reloadOnSameUrl: true,
						withGoBack: true
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		})
	}

	/**
	 * Modification d'un détail du workflow
	 */
	protected addWorkflowDetail(workflow: any = this.workflow) {
		//Ouverture du composant d'édition
		this.pageContentService.open(WorkflowDetailComponent,{
			data: {
				workflow: cloneDeep(workflow),
				toggleFullScreen: this.toggleFullScreen.bind(this),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data: { workflow: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Mise à jour du workflow
					assign(workflow,data.workflow)

					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('DETAIL',true);
				}
			}
		});
	}
}