import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { User } from 'src/app/domain/user/user';

@Component({
	selector: 'demande-vehicule-beneficiaire',
	templateUrl: './demande-vehicule-beneficiaire.component.html'
})
export class DemandeVehiculeBeneficiaireComponent {
	/** Données de la sélection **/
	beneficiaire: { source: 'COLLABORATEUR' | 'SERVICE',user?: User,societe?: any,service?: any,isUpdated: boolean } = {
		source: 'COLLABORATEUR',
		isUpdated: false
	}

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DemandeVehiculeBeneficiaireComponent>) {}

	/**
	 * Enregistrement du bénéficiaire
	 */
	saveBeneficiaire() {
		//Mise à jour du bénéficiaire
		this.beneficiaire.isUpdated = true;

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}