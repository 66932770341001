<form #demandeVehiculeChoixGrilleAttributionModeleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>demandeVehicule.choix.grilleAttributionModele.title</h4>
	</div>
	<div class="modal-body">
		<div class="m-t-20 m-b-10">
			<mat-radio-group [(ngModel)]="choix" name="choix">
				<mat-radio-button value="MODELE"><span translate>demandeVehicule.choix.grilleAttributionModele.modele</span></mat-radio-button>
				<div class="separator">
					<span translate>common.ou</span>
				</div>
				<mat-radio-button value="GRILLE_ATTRIBUTION_SPECIFIQUE"><span translate>demandeVehicule.choix.grilleAttributionModele.grilleAttributionSpecifique</span></mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDemandeVehiculeChoixGrilleAttributionModele()" [disabled]="demandeVehiculeChoixGrilleAttributionModeleForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.confirmer</span>
		</button>
	</div>
</form>