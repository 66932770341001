import { NgModule } from '@angular/core';

import { IconComponent } from './icon.component';
import { CoreComponentsModule } from 'src/app/share/components/core-components.module';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [IconComponent],
	exports: [IconComponent]
})
export class IconModule {}