import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';

import { TypeSecret } from 'src/app/domain/security/secret';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Injectable()
export class SecurityService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private rightService: RightService,private loggedUserService: LoggedUserService) {}

	/**
	 * Récupération de la valeur d'un secret pour un type de secret
	 */
	public readSecretForType(type: TypeSecret,idEntite: number,path: string): Observable<Result> {
		//Vérification du droit
		if (this.rightService.hasRight(TypeDroit.ADMIN_SECRET,'consultation') || this.rightService.hasRight(TypeDroit.ADMIN_SECRET,'consultation',this.loggedUserService.getUserOrigine())) {
			//Récupération d'un secret pour un type
			return this.http.post<Result>(`${environment.baseUrl}/controller/Security/readSecretForType/${type}`,{
				idEntite,
				path
			});
		} else
			//Aucune lecture effectuée
			return of(null);
	}
}