import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des types entités
 */
export default class TenantOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.client';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return '/controller/Tenant/listeTenantsActifs';
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.nom}${item?.code ? ' (' + item.code + ')' : ''}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.code?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.tenant.Tenant';

	/**
	 * Récupération du nom de la clé
	 */
	getKeyFieldName() { return 'idTenant'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'nom',
			isDefault: true
		},{
			clef: 'code',
			isDefault: true
		}];
	}
}