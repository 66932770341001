import { TypeDroit } from '../security/right';

/**
 * Modèle représentant un droit
 */
export class Droit {
	/** Données **/
	idDroit?: number;
	typeDroit: TypeDroit;
	consultation: boolean;
	suppression: boolean;
	creation: boolean;
	userDroit?: { consultation: boolean,suppression: boolean,creation: boolean };

	/**
	 * Constructeur par arguments
	 */
	constructor(typeDroit: TypeDroit,droit: { idDroit?: number,consultation: boolean,creation: boolean,suppression: boolean } = { consultation: false,creation: false,suppression: false },userDroit: { consultation: boolean,suppression: boolean,creation: boolean } = null) {
		//Définition des données
		this.typeDroit = typeDroit;
		this.idDroit = droit.idDroit;
		this.consultation = droit.consultation;
		this.creation = droit.creation;
		this.suppression = droit.suppression;
		this.userDroit = userDroit;
	}
}