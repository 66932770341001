<div class="modal-header">
	<h4 class="modal-title" translate>pool.elements.vehicule.liste.title</h4>
</div>
<div class="modal-body">
	<list-view [liste]="liste" *ngIf="liste"></list-view>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="addListeVehicules()">
		<span translate>actions.associer</span>
	</button>
</div>