import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { map,take } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReservationService {
	/**
	 * Constructeur
	 */
	constructor(private messagingService: MessagingService,private http: HttpClient) {}

	/**
	 * Déclenchement d'un import en masse
	 */
	public updateListeReservations(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});

		return subject;
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	doAction(typeAction: 'VALIDER' | 'REJETER' | 'ANNULER',idReservation: number): Observable<boolean> {
		//Appel AJAX
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReservation/updateListeReservations/${typeAction}`,{
			typeActionMasse: 'SELECTION',
			listeIdObjects: [idReservation]
		}).pipe(
			take(1),
			map(result => !result.data.listeIgnoredIds?.length)
		);
	}

	/**
	 * Chargement de la réservation
	 */
	public loadReservation(idReservation: any): Observable<Result> {
		//Chargement de la réservation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReservation/loadReservation/${idReservation}`,null);
	}
}