import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { LocaleService } from 'src/app/components/locale/locale.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { StructureExportService } from './structure-export.service';

@Component({
	templateUrl: './structure-export-rubrique.component.html'
})
export class StructureExportRubriqueComponent implements OnInit {
	/** Structure d'export **/
	@Input() structureExport: any;

	/** Fichier **/
	@Input() fichier: any;

	/** Ligne courante **/
	@Input() ligne: any;

	/** Liste des compteurs **/
	@Input() listeCompteurs: Array<any>;

	/** Rubrique courante **/
	@Input() rubrique: any;

	/** Action de masse **/
	@Input() isActionMasse: boolean;

	/** Nombres de rubriques sélectionnées **/
	@Input() nbSelected: number;

	/** Restriction sur la structure d'export **/
	@Input() isRestricted?: boolean;

	/** Affichage du contenu **/
	content: { isGeneralitesToggled?: boolean,isFormatageToggled?: boolean,isDefinitionToggled?: boolean } = {};

	/** Liste des séparateurs de décimaux **/
	public listeSeparateursDecimale: Array<{ code: string,libelle: string }>;

	/** Liste des types de cadrage **/
	public listeTypesCadrage: Array<{ code: string,libelle: string }>;

	/** Liste des types de complétion **/
	public listeTypesCompletion: Array<{ code: string,libelle: string }>;

	/** Liste des formats de date **/
	public listeFormatsDate: Array<{ code: string,libelle: string }>;

	/** Résultat **/
	result: { structureExport?: any,ligne?: any,rubrique: any };

	/**
	 * Constructeur
	 */
	constructor(private structureExportService: StructureExportService,private translateService: TranslateService,private localeService: LocaleService,public bsModalRef: BsModalRef<StructureExportRubriqueComponent>,private toastrService: ToastrService,private rightService: RightService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Chargement de la liste des séparateurs de décimaux
		this.listeSeparateursDecimale = this.localeService.getListeSeparateursDecimale();

		//Liste des types de cadrage
		this.listeTypesCadrage = this.structureExportService.getListeTypesCadrage();

		//Liste des types de complétion
		this.listeTypesCompletion = this.structureExportService.getListeTypesCompletion();

		//Liste des formats de date
		this.listeFormatsDate = this.localeService.getListeFormatsDateCourte();
	}

	/**
	 * Interception de la modification du remplissage de la rubrique
	 */
	onRubriqueEmptyChange() {
		//Vérification de la rubrique
		if (!this.rubrique.libelle && this.rubrique.empty)
			//Mise à jour du titre de la rubrique
			this.rubrique.libelle = this.translateService.instant('structureExport.rubrique.vide.item');
	}

	/**
	 * Interception de la modification du formatage spécifique
	 */
	onFormatageSpecifiqueChange() {
		//Vérification de la structure d'export
		if (this.rubrique.formatageSpecifique) {
			//Initialisation du format de date
			this.rubrique.formatDate = cloneDeep(this.structureExport.formatDate);

			//Initialisation du format de numérique
			this.rubrique.formatNumerique = cloneDeep(this.structureExport.formatNumerique);

			//Suppression de l'identifiant
			delete this.rubrique.formatNumerique.idFormat;
		} else {
			//Remise à zéro du formatage spécifique
			this.rubrique.formatDate = null;
			this.rubrique.formatNumerique = null;
		}
	}

	/**
	 * Détection de modifications sur le type de cadrage
	 */
	onTypeCadrageChange() {
		//Vérification du type de cadrage
		if (this.rubrique.formatNumerique.typeCadrage == 'AUCUN') {
			//Réinitialisation du type de complétion et de la longueur
			this.rubrique.formatNumerique.typeCompletion = null;
			this.rubrique.formatNumerique.longueur = null;
		}
	}

	/**
	 * Enregistrement de la rubrique
	 */
	saveRubrique() {
		//Vérification de l'action de masse
		if (!this.isActionMasse) {
			//Enregistrement de la rubrique
			this.structureExportService.saveRubrique(this.structureExport.objectVersion,this.ligne.idLigne,this.rubrique).subscribe({
				next: (result: Result) => {
					//Vérification du code d'erreur
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Définition du résultat
						this.result = {
							structureExport: result.data.structureExport,
							ligne: result.data.ligne,
							rubrique: result.data.rubrique
						};

						//Fermeture de la fenêtre
						this.bsModalRef.hide();
					} else
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			});
		} else {
			//Définition du résultat
			this.result = {
				rubrique: this.rubrique
			};

			//Fermeture de la fenêtre
			this.bsModalRef.hide();
		}
	}

	/**
	 * Sélection d'un type de définition
	 */
	selectTypeRubrique(root: any,key: string) {
		//Affichage de la sélection du type de rubrique
		!this.isRestricted && this.rightService.hasRight(null,'creation') && this.structureExportService.showTypeRubriqueSelection(this.structureExport,this.ligne,root,key,this.listeCompteurs).subscribe();
	}
}