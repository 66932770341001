<form #listeRubriquesForm="ngForm" autocomplete="off" (keydown.enter)="onKeydownEnter($event)">
	<div>
		<div class="card detail">
			<div class="card-header">
				<h2 class="card-title" translate>templateMail.rubrique.liste.title</h2>
			</div>
			<div class="card-body card-padding">
				<div class="listview">
					<div class="lv-body">
						<bs-sortable name="listeTemplateStructure" [(ngModel)]="templateMail.listeTemplateStructure" [itemTemplate]="itemTemplate" itemActiveClass="invisible"></bs-sortable>

						<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="addStructure();" *ngIf="isTemplateEditable;">
							<h5><a [routerLink]="[]" translate>templateMail.rubrique.ajoutContenu</a></h5>
						</div>
					</div>
				</div>
			</div>
		</div>

		<span class="pull-right" *ngIf="(null | right:'creation') && (typeReferentiel == 'LOCAL' || rightService.isRoot())">
			<button mat-raised-button color="primary" (click)="saveTemplateMail();" [disabled]="listeRubriquesForm.invalid  || typeReferentiel == 'LOCAL' && templateMail.templateMailOrigine && !templateMail.custom && !isCustom" *ngIf="null | right:'creation'">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</div>

	<ng-template #itemTemplate let-content="item" let-index="index">
		<div class="lv-item media p-0" #draggable draggable="true" (mousedown)="onMousedown($event,index)" (dragstart)="onDragstart($event,index,draggable)">
			<div class="sortable-item">
				<div>
					<span *ngIf="isTemplateEditable" class="pull-left handle m-l-10 m-r-10 clickable"><icon class="pull-left icon-hc-2x" name="open_with"></icon></span>
				</div>
				<ul>
					<li *ngIf="isTemplateEditable && templateMail.listeTemplateStructure?.length && index != (templateMail.listeTemplateStructure.length - 1)">
						<button mat-icon-button color="primary" (click)="moveStructure(index,1);">
							<mat-icon>arrow_circle_down</mat-icon>
						</button>
					</li>
					<li *ngIf="isTemplateEditable && index != 0">
						<button mat-icon-button color="primary" (click)="moveStructure(index,-1);">
							<mat-icon>arrow_circle_up</mat-icon>
						</button>
					</li>
					<li>
						<button mat-icon-button color="primary" (click)="content.value.isToggled = !content.value.isToggled;">
							<mat-icon>{{ !content.value.isToggled ? 'chevron_right' : 'expand_more' }}</mat-icon>
						</button>
					</li>
					<li *ngIf="isTemplateEditable">
						<button mat-icon-button color="primary" (click)="deleteStructure(index);">
							<mat-icon>delete</mat-icon>
						</button>
					</li>
				</ul>
			</div>
			<div class="row m-0" [collapse]="!content.value.isToggled">
				<div class="col-md-12 p-0" (mouseenter)="onMouseenter(draggable)" (mouseleave)="onMouseleave(draggable)">
					<div class="template-bloc">
						<summernote [config]="summernoteConfig" [name]="'content_'+content.value.uuid" [(ngModel)]="content.value.listeTemplateBloc[0].contenu" lang="fr-FR"></summernote>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</form>