import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { EquipementAffectationService } from './equipement-affectation.service';
import { EquipementService } from './equipement.service';

@Component({
	selector: 'equipement-affectation-content',
	templateUrl: './equipement-affectation-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class EquipementAffectationContentComponent implements OnInit {
	/** Affectation courante **/
	@Input() affectationEquipement: any;

	/** Source **/
	@Input() source: 'VEHICULE' | 'EQUIPEMENT' | any = 'EQUIPEMENT';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Recherche de l'équipement **/
	public searchEquipement: any;

	/** Période en anomalie */
	periodeValidity: { isValid: boolean,affectation?: any } = {
		isValid: true
	};

	/** Liste des types d'équipements **/
	public listeTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types d'affectation **/
	public listeTypesAffectation: Array<{ code: string,libelle: string }>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(public ngForm: NgForm,public vehiculeService: VehiculeService,private equipementAffectationService: EquipementAffectationService,private equipementService: EquipementService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste des types
		this.listeTypes = this.equipementService.getListeTypes();

		//Définition de la liste des types d'affectation
		this.listeTypesAffectation = this.equipementAffectationService.getListeTypes();

		//Définition de l'équipement recherché
		this.searchEquipement = Object.assign({},this.affectationEquipement?.equipement);
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveAffectationEquipement() {
		//Enregistrement de l'affectation
		this.equipementAffectationService.saveAffectation(this.affectationEquipement).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.affectationEquipement,result.data?.affectation);

					//Fermeture du formulaire
					this.close.emit(this.affectationEquipement);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification de la date de début
	 */
	isDateDebutValid() {
		let dateFin: any;
		let dateDebut: any;
		let equipement: any;

		//Récupération de la date
		dateDebut = this.affectationEquipement.dateDebut;
		dateFin = this.affectationEquipement.dateFin;

		//Récupération de l'équipement
		equipement = this.affectationEquipement?.equipement;

		//Vérification de la validité
		return dateDebut == null || dateDebut == '' || (dateFin != null ? dateDebut <= dateFin : true) && dateDebut >= equipement?.dateDebut && dateDebut <= equipement?.dateFin;
	}

	/**
	 * Vérification de la date de fin
	 */
	isDateFinValid() {
		let dateFin: any;
		let dateDebut: any;
		let equipement: any;

		//Récupération des dates
		dateDebut = this.affectationEquipement.dateDebut;
		dateFin = this.affectationEquipement.dateFin;

		//Récupération de l'équipement
		equipement = this.affectationEquipement?.equipement;

		//Vérification de la validité
		return dateFin == null || dateFin == '' || (dateDebut != null ? dateFin >= dateDebut : true) && dateFin >= equipement?.dateDebut && dateFin <= equipement?.dateFin;
	}

	/**
	 * Vérification des périodes
	 */
	isPeriodeValid() {
		let equipement: any;
		let isValid: boolean = true;
		let currentAffectation: any = null;

		//Récupération de l'équipement
		equipement = this.affectationEquipement?.equipement;

		//Vérification de la période
		if (this.affectationEquipement?.dateDebut || this.affectationEquipement?.dateFin) {
			//Parcours des affectations
			equipement?.listeAffectations?.forEach(affectation => {
				//Vérification de l'identifiant de l'affectation
				if (affectation.idAffectation != this.affectationEquipement.idAffectation) {
					//Vérification de la présence d'une date de début
					if (affectation.dateDebut) {
						//Vérification de la date de début
						if (affectation.dateDebut <= this.affectationEquipement.dateDebut && (!affectation.dateFin || affectation.dateFin >= this.affectationEquipement.dateDebut)) {
							//Période invalide
							isValid = false;

							//Affectation courante
							currentAffectation = affectation;
						} else if (this.affectationEquipement.dateDebut <= affectation.dateDebut && (!this.affectationEquipement.dateFin || this.affectationEquipement.dateFin >= affectation.dateDebut)) {
							//Période invalide
							isValid = false;

							//Affectation courante
							currentAffectation = affectation;
						} else if (affectation.dateFin && this.affectationEquipement.dateDebut <= affectation.dateFin && (!this.affectationEquipement.dateDebut || this.affectationEquipement.dateDebut >= affectation.dateDebut)) {
							//Période invalide
							isValid = false;

							//Affectation courante
							currentAffectation = affectation;
						} else if (this.affectationEquipement.dateFin && this.affectationEquipement.dateFin >= affectation.dateDebut && this.affectationEquipement.dateDebut <= affectation.dateDebut) {
							//Période invalide
							isValid = false;

							//Affectation courante
							currentAffectation = affectation;
						}
					}
				}
			});
		}

		//Définition de la periode
		this.periodeValidity = {
			isValid: isValid,
			affectation: currentAffectation
		};

		//Retour de la validité
		return this.periodeValidity.isValid;
	}

	/**
	 * Affichage de l'historique des affectations
	 */
	showAffectationHistory() {
		//Affichage de l'historique
		this.equipementAffectationService.showAffectationHistory(this.affectationEquipement.equipement);
	}
}