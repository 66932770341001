import { Component,Input,OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep,isEqual } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteComponent } from 'src/app/share/components/autocomplete/autocomplete.component';
import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { EntiteService } from 'src/app/share/components/entite/entite.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { TodoListService } from './todo-list.service';

@Component({
	selector: 'todo-list-item-edit',
	templateUrl: './todo-list-item-edit.component.html'
})
export class TodoListItemEditComponent extends PageContentItem implements OnInit {
	/** Données **/
	@Input() item: any;

	/** Liste des entités **/
	public listeEntites: Array<any>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Champ fictif pour l'autocomplete **/
	public entiteAutocomplete: any;

	/** Détection de la désactivation du rappel par l'utilisateur **/
	public hasUntickedEnvoiRappel: boolean = false;

	/** Enregistrement de la liste des utilisteurs (Détection d'une modification dans le formulaire) **/
	private savedListeUsers: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private extractionService: ExtractionService,public entiteService: EntiteService,public autocompleteService: AutocompleteService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private todoListService: TodoListService,private datePipe: DatePipe) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement de la liste des entités
		this.extractionService.getListeEntites().subscribe({
			next: (listeEntites: any) => {
				//Récupération des entités ayant un autocomplete
				listeEntites = listeEntites.filter(entite => entite?.autocomplete != null);

				//Ajout d'une option vide
				listeEntites.unshift({
					code: null,
					libelle: this.translateService.instant('common.aucun')
				});

				//Définition de la liste des entités
				this.listeEntites = listeEntites;
			}
		});

		//Vérification de la présence d'un objet
		if (this.item.entite && this.item.idEntite) {
			//Chargement de l'objet
			this.entiteService.loadEntite(this.item.entite,this.item.idEntite).subscribe({
				next: (result) => {
					//Définition de l'entité
					this.item.entityObject = result;

					//Récupération de l'autocomplete associé
					this.entiteAutocomplete = this.listeEntites.find(e => e.code === this.item.entite);

					//Définition du type de l'entité
					this.item.entityObject._type = this.entiteAutocomplete?.autocomplete?.type;
				}
			})
		}

		//Enregistrement de la liste des utilisateurs
		this.savedListeUsers = cloneDeep(this.item.listeUsers);
	}

	/**
	 * Changement de l'entité
	 */
	onEntiteChange(entite) {
		//Réinitialisation
		this.item.idEntite = null;
		this.item.labelEntite = null;
		this.entiteAutocomplete = null;

		//Vérification de la présence d'une entité
		if (entite)
			//Récupération de l'autocomplete associé
			this.entiteAutocomplete = this.listeEntites.find(e => e.code === entite);
	}

	/**
	 * Changement de l'entité associée à la tâche
	 */
	onEntityObjectChange(entityObject) {
		//Réinitialisation
		this.item.idEntite = null;
		this.item.labelEntite = null;

		//Vérification de la présence d'une entité
		if (entityObject) {
			//Définition du libellé de l'entité
			this.item.labelEntite = this.autocompleteService.getOptionsForType(this.entiteAutocomplete?.autocomplete?.name).displayItem?.(entityObject,this.translateService,this.datePipe);

			//Définition du type de l'entité
			this.item.entityObject._type = this.entiteAutocomplete?.autocomplete.type;
		}
	}

	/**
	 * Changement de la date d'échéance
	 */
	onDateEcheanceChange() {
		//Mise à jour de l'indicateur d'envoi d'un rappel
		this.item.envoiRappel = this.item.dateEcheance ? !this.hasUntickedEnvoiRappel : false;
	}

	/**
	 * Suppression d'un utilisateur de la liste
	 */
	removeUser(user) {
		let index;

		//Recherche de l'index de l'utilisateur
		index = this.item.listeUsers.indexOf(user);

		//Vérification de l'index
		if (index != -1)
			//Suppression de l'utilisateur
			this.item.listeUsers.splice(index,1);
	}

	/**
	 * Suppression de la tâche
	 */
	deleteItem() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la tâche
					this.todoListService.deleteItem(this.item).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la tâche
								this.close(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Enregistrement de la tâche
	 */
	saveItem(): void {
		//Enregistrement de la tâche
		this.todoListService.saveItem(this.item).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de la tâche
					this.item = result.data.item;

					//Fermeture de la fenêtre
					this.close(this.item);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification de la validité du formulaire (liste des utilisateurs)
	 */
	isListeUsersDirty(): boolean {
		//Vérification de l'égalité des listes
		return !isEqual(this.savedListeUsers,this.item.listeUsers);
	}

	/**
	 * Sélection d'un utilisateur
	 */
	onSelectUser(user,autocomplete: AutocompleteComponent) {
		//Vérification de liste
		if (!this.item?.listeUsers)
			//Initalisation de la liste
			this.item.listeUsers = [];

		//Controle anti-doublon
		if (this.item.listeUsers.find(u => u.idUser == user.idUser) == null && this.item.user.idUser != user.idUser)
			//Ajout de l'utilisateur
			this.item.listeUsers.push(Object.assign({},user));

		//Réinitalisation du champ de saisie
		autocomplete.searchedValue = '';
	}
}
