import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';

@Component({
	selector: 'extraction-entete-list',
	templateUrl: './extraction-entete-list.component.html',
	styles: ['.lv-item .form-group > div { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }']
})
export class ExtractionEnteteListComponent extends PageContentItem implements OnInit {
	/** Extraction **/
	@Input() extraction: any;

	/** Type de référentiel **/
	@Input() typeReferentiel: TypeReferentiel;

	/** Mode 'Edition' **/
	@Input() isEdition: boolean = false;

	/** Enumération des types de référentiel **/
	readonly TypeReferentiel: typeof TypeReferentiel = TypeReferentiel;

	/** Liste des champs **/
	public listeFields: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService,private extractionService: ExtractionService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Duplication de la liste des champs
		this.listeFields = cloneDeep(this.extraction?.listeFields);
	}

	/**
	 * Enregistrement des entêtes
	 */
	saveListeEntetes() {
		let extraction: any;

		//Duplication de l'objet avant enregistrement
		extraction = cloneDeep(this.extraction);

		//Définition de la liste des champs
		extraction.listeFields = this.listeFields;

		//Enregistrement de l'extraction
		this.extractionService.saveExtraction(extraction).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'extraction
					Object.assign(this.extraction,result.data?.extraction);

					//Fermeture de la fenêtre
					this.close(this.extraction);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du reference
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}
