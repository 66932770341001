import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { StructureExportService } from './structure-export.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	templateUrl: './structure-export-rubrique-move.component.html'
})
export class StructureExportRubriqueMoveComponent {
	/** Identifiant de la ligne **/
	@Input() idLigne: number;

	/** Position actuelle **/
	@Input() currentPosition: number;

	/** Position maximale **/
	@Input() max: number;

	/** Nouvelle position **/
	newPosition: number;

	/** Résultat **/
	result: { isMoved: boolean };

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<StructureExportRubriqueMoveComponent>,private structureExportService: StructureExportService) {}

	/**
	 * Enregistrement du déplacement
	 */
	public save() {
		//Déplacement des rubriques
		this.structureExportService.moveRubrique(this.idLigne,this.currentPosition - 1,this.newPosition - 1).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR)
				//Définition du résultat
				this.result = { isMoved: true };

			//Fermeture de la modal
			this.bsModalRef.hide();
		});
	}

	/**
	 * Vérification des 2 positions
	 */
	public isNotEqual() {
		//Comparaison des 2 positions
		return this.currentPosition != this.newPosition;
	}
}