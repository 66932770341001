<form #importRelanceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>connecteur.import.relance.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': typeImportField.invalid }">
				<label translate>connecteur.import.relance.typeImport.item</label>
				<div>
					<selectpicker name="typeImport" #typeImportField="ngModel" [(ngModel)]="typeImport" required>
						<mat-option *ngFor="let typeImport of listeTypesImport" [value]="typeImport.code">{{ typeImport.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="importRelanceForm.invalid" (click)="saveTypeImport();">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>