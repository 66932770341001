<div *ngIf="selectedChoix.type == 'CHANGEMENT_MODELE'" class="alert alert-info">
	<icon name="warning"></icon>
	<div>
		<strong translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.alerte.title</strong>
		<div translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.alerte.message</div>
	</div>
</div>
<div class="card-padding p-t-20">
	<div class="form-group">
		<label class="bold" translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.vehicule</label>
		<div>
			<span>{{ immatriculation.reference }}</span>
		</div>
	</div>
	<div class="form-group">
		<label class="bold" translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.modele</label>
		<div>
			<span>{{ selectedModele.marque.libelle + ' ' + selectedModele.libelle + (selectedModele.finition ? ' ' + selectedModele.finition : '') }}</span>
		</div>
	</div>
	<ng-container *ngIf="selectedChoix.type == 'CHANGEMENT_MODELE'">
		<div class="separator separator-text p-t-5 p-b-10">
			<span translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.remplacera</span>
		</div>
		<div class="form-group">
			<label class="bold" translate>vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.modele</label>
			<div>
				<span>{{ modeleInitial.marque.libelle + ' ' + modeleInitial.libelle + (modeleInitial.finition ? ' ' + modeleInitial.finition : '') }}</span>
			</div>
		</div>
	</ng-container>
</div>