import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';
import { filter,first,map } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { ComptePosteChargeTransfertComponent } from './compte-poste-charge-transfert.component';

@Injectable()
export class CompteService {
	/** Utilisateur courant **/
	private user: User;

	/** Liste des types de comptes **/
	private readonly listeTypesCompte: Array<string> = ['BANQUE','CHARGE','TIERS','TAXE'];

	/** Mapping des postes de charge **/
	private readonly mapPostesCharge: { [key: string]: { state: string,fullIdentifier: string,identifier: string,typeDroit: TypeDroit } } = {
		'NATURE': {
			state: 'fournisseurReferentiel-listeFournisseurNatures-loadNature',
			fullIdentifier: 'nature.idNature',
			identifier: 'idNature',
			typeDroit: TypeDroit.ADMIN_FOURNISSEUR_NATURE
		}
	}

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private messagingService: MessagingService,private bsModalService: BsModalService,private store: Store<AppState>) {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => {
				//Définition de l'utilisateur connecté
				this.user = user;
			}
		});
	}

	/**
	 * Sauvegarde d'un compte
	 */
	public saveCompte(compte: any): Observable<Result> {
		//Sauvegarde du compte
		return this.http.put<Result>(`${environment.baseUrl}/controller/Compte/saveCompte`,compte);
	}

	/**
	 * Chargement d'un compte
	 */
	public loadCompte(idCompte: number): Observable<Result> {
		//Chargement du compte
		return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/loadCompte/${idCompte}`,null);
	}

	/**
	 * Suppression du compte
	 */
	public deleteCompte(compte: any): Observable<Result> {
		//Suppression du compte
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Compte/deleteCompte/${compte.idCompte}`);
	}

	/**
	 * Liste des types de compte
	 */
	public getListeTypesCompte(): Array<{ code: string,libelle: string }> {
		//Liste des types de compte
		return this.listeTypesCompte.map(code => ({
			code,
			libelle: this.translateService.instant(`comptabilite.compte.type.${code}`)
		}));
	}

	/**
	 * Récupération du mapping des postes de charge
	 */
	public getMappingForPostesCharge(): { [key: string]: { state: string,fullIdentifier: string,identifier: string,typeDroit: TypeDroit } } {
		//Retour du mapping
		return this.mapPostesCharge;
	}

	/**
	 * Suppression du lien avec le compte
	 */
	public deleteLienCompteDetail(lien: any): Observable<Result> {
		//Suppression du lien avec le compte
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Compte/deleteLienCompteDetail/${lien.idLien}`);
	}

	/**
	 * Enregistrement du lien avec le compte
	 */
	public saveLienCompteDetail(lien: any): Observable<Result> {
		//Enregistrement du lien avec le compte
		return this.http.put<Result>(`${environment.baseUrl}/controller/Compte/saveLienCompteDetail`,lien);
	}

	/**
	 * Chargement du compte de facturation pour un fournisseur
	 */
	public loadCompteFacturationForFournisseur(idFournisseur: number,code: string): Observable<Result> {
		//Chargement du compte de facturation pour un fournisseur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/loadCompteFacturationForFournisseur/${idFournisseur}`,{ code });
	}

	/**
	 * Déplacement des postes de charge selectionnés
	 */
	public moveAllPostesChargeForSelection(compte: any,messagingOptions: MessagingOptions,liste: ListView<any,any>): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let bsModalRef: BsModalRef<ComptePosteChargeTransfertComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ComptePosteChargeTransfertComponent,{
			initialState: {
				isActionMasse: true,
				lienPosteCharge: {
					compte: cloneDeep(compte)
				}
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.lienPosteCharge),
			filter(lienPosteCharge => !!lienPosteCharge)
		).subscribe({
			next: (lienPosteCharge: any) => {
				//Modification du endpoint
				messagingOptions.entryPoint += `/${compte.idCompte}/${lienPosteCharge?.compte?.idCompte}`

				//Lancement en masse
				this.doMoveAllPostesChargeForSelection(messagingOptions,subject,liste);
			}
		});

		return subject;
	}

	/**
	 * Déclenchement du déplacement des postes de charge en masse
	 */
	private doMoveAllPostesChargeForSelection(messagingOptions: MessagingOptions,subject: Subject<any>,liste: ListView<any,any>) {
		let messaging$: MessagingObservables;

		//Démarrage du déplacement par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();

					//Rafraichissement de la liste
					liste?.refresh?.();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}

	/**
	 * Récupération des restrictions liées aux sociétés visibles par l'utilisateur
	 */
	getRestriction(listeSocietes: Array<any>) {
		//Retour de la restriction
		return {
			isRestricted: this.user.listeSocietes?.length && listeSocietes?.some(societe => {
				//Vérification de la présence de la société dans les sociétés visibles de l'utilisateur
				return !this.user.listeSocietes.includes(societe.idService);
			}) || false
		};
	}

	/**
	 * Sauvegarde d'un compte de facturation
	 */
	public saveCompteFacturation(lienFacturation: any): Observable<Result> {
		//Sauvegarde du compte de facturation
		return this.http.put<Result>(`${environment.baseUrl}/controller/Compte/saveCompteFacturation`,lienFacturation);
	}

	/**
	 * Suppression du compte de facturation
	 */
	public deleteCompteFacturation(lienFacturation: any): Observable<Result> {
		//Suppression du compte de facturation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Compte/deleteCompteFacturation/${lienFacturation.idLien}`);
	}
}