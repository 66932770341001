import { Component,Input } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

@Component({
	selector: 'modele-information',
	templateUrl: './modele-information.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ModeleInformationComponent {
	/** Modèle **/
	@Input() modele: any;
}
