import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'equipement-affectation-edit',
	templateUrl: './equipement-affectation-edit.component.html'
})
export class EquipementAffectationEditComponent extends PageContentItem {
	/** Affectation courante **/
	@Input() affectation: any;

	/** Suppression d'une affectation **/
	@Input() deleteAffectation: (affectation: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}