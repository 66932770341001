import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './article-lecture.component.html'
})
export class ArticleLectureComponent {
	/** Article courant **/
	@Input() article: any = null;

	/** Résultat **/
	result: { isRead?: boolean } = {}

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef) {}

	/**
	 * Fermeture de la popup
	 */
	close(isRead: boolean = true) {
		//Définition du résultat
		this.result.isRead = isRead;

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}