import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratCRMEditComponent } from './contrat-crm-edit.component';
import { ContratCRMOffreEditComponent } from './contrat-crm-offre-edit.component';
import { ContratCRMOffreListComponent } from './contrat-crm-offre-list.component';
import { ContratCRMResiliationComponent } from './contrat-crm-resiliation.component';
import { ContratCRMService } from './contrat-crm.service';

@Component({
	selector: 'contrat-crm',
	templateUrl: './contrat-crm.component.html'
})
export class ContratCRMComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Contrat CRM courant **/
	public contratCRM: any;

	/** Résumé */
	private resume: { nbOffres: number,isUsed?: boolean } = {
		nbOffres: 0
	}

	/** Indicateur d'utilisation du contrat CRM **/
	private isUsed: boolean;

	/** Indicateur de résiliation possible **/
	private canDoResiliation: boolean;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Récupération de la classe */
	getClassName: () => string = () => 'com.notilus.data.crm.ContratCRM';

	/** Récupération de l'identifiant du contrat CRM à charger **/
	getIdObject: () => number = () => this.contratCRM?.idContrat || 0;

	/** Récupération de l'entité portant les pièces jointes **/
	getOwningEntity: () => LinksOwningEntity = () => this.contratCRM;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.CONTRAT_CRM;

	/**
	 * Constructeur
	 */
	constructor(private contratCRMService: ContratCRMService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService
			,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private displayPipe: DisplayPipe,private bsModalService: BsModalService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'contratCRM.reference',
				key: 'reference'
			},{
				libelle: 'contratCRM.clientCRM',
				key: (data) => this.displayPipe.transform(data.clientCRM,'clientCRM')
			},{
				libelle: 'contratCRM.revendeur',
				key: (data) => this.displayPipe.transform(data.revendeur,'revendeur')
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editContratCRM(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editContratCRM(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.resilier',
				doAction: () => this.showResiliation(),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.canDoResiliation && this.isUsed
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteContratCRM(),
				isVisible: () => this.rightService.hasRight(null,'suppression') && !this.isUsed,
				type: TypeAction.SUPPRESSION
			}],
			listeAlertes: [{
				icon: 'people',
				title: this.translateService.instant('contratCRM.alerte.offre.title'),
				message: this.translateService.instant('contratCRM.alerte.offre.message'),
				isVisible: () => !this.resume?.nbOffres && !this.pageContentService.isOpened(),
				doAction: () => this.addLienOffre(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'OFFRE',
				libelle: 'contratCRM.offre',
				component: ContratCRMOffreListComponent,
				retrieveComponentData: () => ({
					contratCRM: this.contratCRM,
					resume: this.resume,
					addLienOffre: this.addLienOffre.bind(this)
				}),
				doAction: () => this.addLienOffre(),
				count: () => this.resume.nbOffres,
				libelleAction: this.translateService.instant('contratCRM.actions.ajouterOffre'),
				isVisible: () => this.resume?.nbOffres > 0,
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}]
		};

		//Récupération de l'identifiant du contrat CRM à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idContrat);
			}
		});
	}

	/**
	 * Edition du contrat CRM
	 */
	private editContratCRM() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ContratCRMEditComponent,{
			data: {
				contratCRM: this.contratCRM.idContrat ? cloneDeep(this.contratCRM) : this.contratCRM,
				deleteContratCRM: this.deleteContratCRM.bind(this),
				isUsed: this.isUsed,
				showResiliation: this.showResiliation.bind(this),
				canDoResiliation: this.canDoResiliation
			}
		}).subscribe({
			next: (contratCRM: any) => {
				//Vérification des données
				if (contratCRM) {
					//Vérification du changement des offres (suite au changement de revendeur)
					if (this.contratCRM.idContrat && !isEqual(this.contratCRM.listeOffres,contratCRM.listeOffres)) {
						//Affichage de l'onglet (si nécessaire)
						this.complexPage.setSelectedElementByType('OFFRE',true);

						//Réinitialisation du compteur
						this.resume.nbOffres = 0;
					}

					//Mise à jour du contrat CRM
					this.contratCRM = Object.assign(this.contratCRM,contratCRM);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.contratCRM);
				}
			}
		});
	}

	/**
	 * Ouverture de la popup de résiliation
	 */
	public showResiliation(close?: Function) {
		let bsModalRef: BsModalRef<ContratCRMResiliationComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(ContratCRMResiliationComponent,{
			initialState: {
				contratCRM: cloneDeep(this.contratCRM)
			}
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.contratCRM),
			filter(contratCRM => !!contratCRM)
		).subscribe({
			next: () => {
				//Définition de l'indicateur
				this.canDoResiliation = false;

				//Fermeture du formulaire d'édition
				close?.();

				//Rechargement de la liste des offres
				this.complexPage.setSelectedElementByType('OFFRE',true);
			}
		});
	}

	/**
	 * Suppression du contrat CRM
	 */
	private deleteContratCRM() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.contratCRMService.deleteContratCRM(this.contratCRM))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idContrat: number) {
		//Enregistrement du contrat CRM
		this.contratCRMService.loadContratCRM(idContrat).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du contrat CRM
					this.contratCRM = result.data?.contratCRM;

					//Définition du résumé
					this.resume = {
						nbOffres: result.data?.nbOffres || 0
					};

					//Indicateur sur l'utilisation
					this.isUsed = result.data?.isUsed

					//Indicateur sur la posibilié de résilier le contrat
					this.canDoResiliation = result.data?.canDoResiliation

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du contrat CRM
					if (!this.contratCRM?.idContrat) {
						//Création d'un objet vierge
						this.contratCRM = {};

						//Edition du contrat CRM
						this.editContratCRM();
					}
				}
			}
		});
	}

	/**
	 * Modification de l'offre
	 */
	private addLienOffre(lien: any = { contratCRM: Object.assign({},this.contratCRM) },deleteLienOffre?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ContratCRMOffreEditComponent,{
			data: {
				lien: cloneDeep(lien),
				deleteLienOffre
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification de la présence de donnée
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('OFFRE',data.idLien != lien.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lien.idLien && data.idLien)
						//Incrémentation du compteur
						this.resume.nbOffres++;
					else if (lien.idLien && !data.idLien)
						//Décrémentation du compteur
						this.resume.nbOffres--;

					//Mise à jour de l'offre
					lien = Object.assign(lien,data);
				}
			}
		});
	}
}