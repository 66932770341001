import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	templateUrl: './tenant-autorisation-edit.component.html'
})
export class TenantAutorisationEditComponent extends PageContentItem {
	/** Autorisation **/
	@Input() autorisation: any;

	/** Source **/
	@Input() source: 'TENANT' | 'USER';

	/** Index **/
	@Input() index: number;

	/** Suppression d'une autorisation **/
	@Input() deleteAutorisation: (autorisation: any,close: Function,index: number) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Fermeture de l'autorisation après enregistrement
	 */
	onContentClose(autorisation: any) {
		//Fermeture du formulaire d'édition
		this.close({ autorisation: autorisation || null });
	}
}
