import { Component,Input } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LotComptableCheckPosteListItemComponent } from './lot-comptable-check-poste-list-item.component';

@Component({
	selector: 'lot-compable-check-poste',
	templateUrl: './lot-comptable-check-poste.component.html'
})
export class LotComptableCheckPosteComponent {
	/** Nombre d'anomalies **/
	@Input() nbAnomalies: number;

	/** Éléments sélectionnés **/
	@Input() selectionItems: any;

	/** Données **/
	liste: ListView<any,LotComptableCheckPosteListItemComponent>;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,LotComptableCheckPosteListItemComponent>({
			uri: () => `/controller/LotComptable/filtreFactureDetailsForControle`,
			component: LotComptableCheckPosteListItemComponent,
			isLocal: true,
			extraOptions: {
				nbAnomalies: this.nbAnomalies
			},
			listeFilters: [{
				clef: 'facture.reference',
				isDefault: true
			},{
				clef: 'facture.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			},{
				clef: '*vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			defaultOrder: '-date,facture.reference',
			listeExtraData: () => [Object.assign(this.selectionItems,{ _type: 'com.notilus.model.search.AggregatedItemSelection' })]
		});
	}

	/**
	 * Récupération du nombre de corrections
	 */
	getNbCorrections() {
		//Aucune correction manuelle possible
		return 0;
	}
}
