import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';

import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
import { LoginEffects } from './login.effects';
import { AutoFocusModule } from 'src/app/share/directive/autofocus/autofocus.module';
import { RecaptchaModule } from 'src/app/share/directive/recaptcha/recaptcha.module';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ValidatorModule } from 'src/app/share/directive/validate/validator.module';

@NgModule({
	imports: [BrowserModule,RouterModule,FormsModule,TranslateModule,EffectsModule.forFeature([LoginEffects]),MatFormFieldModule,MatInputModule,MatButtonModule,MatFormFieldModule,MatInputModule,MatButtonToggleModule,MatSelectModule
		,AutoFocusModule,RecaptchaModule,SelectPickerModule,IconModule,ValidatorModule
	],
	declarations: [LoginComponent],
	exports: [LoginComponent],
	providers: [LoginService]
})
export class LoginModule {}