<div class="pull-left lv-avatar">
	<icon [name]="mapStatuts?.[data.statut]?.icone" [type]="mapStatuts?.[data.statut]?.iconeType"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="showCotation()">
			<span *ngIf="data.modele?.marque">{{ data.modele.marque.libelle }}</span>&#160;<span *ngIf="data.modele">{{ data.modele.libelle }}</span><span *ngIf="data.modele?.finition">&#160;{{ data.modele.finition }}</span><span *ngIf="data.modele?.reference">&#160;({{ data.modele.reference }})</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>demandeVehicule.cotation.statut.item</span>&#160;:&#160;<span [translate]="'demandeCotation.statut.' + data.statut"></span></li>
		<li *ngIf="data.listeContacts?.length"><span translate>demandeCotation.contacts</span>&#160;:&#160;<span>{{ (data.listeContacts.length || 0) | number }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.DEMANDE_COTATION | right:'creation'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(TypeDroit.DEMANDE_COTATION | right:'creation') && cotationService.checkRightCotation('DELETE',data)"><a [routerLink]="[]" (click)="deleteCotation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(TypeDroit.DEMANDE_COTATION | right:'creation') && cotationService.checkRightCotation('CANCEL',data)"><a [routerLink]="[]" (click)="cancelCotation();"><span translate>actions.annuler</span></a></li>
			<li *ngIf="data.statut == 'SOLDEE'"><a [routerLink]="[]" (click)="showCotation(true)"><span translate>demandeCotation.actions.consulterComparaison</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>