import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des plans comptable
 */
export default class PlanComptableOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.planComptable';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Compte/filtrePlanComptables`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item?.libelle}${item?.reference ? ' (' + item?.reference + ')' : ''}` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.comptabilite.PlanComptable';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idPlanComptable'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'reference',
			isDefault: true
		},{
			clef: 'libelle',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: any): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.idSociete && {
			clef: 'listeSocietes.idService',
			valeur: filter.idSociete,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.LONG
		},!filter?.isShowAll && {
			clef: 'isActif',
			valeur: true,
			typeComparaison: TypeComparaison.EQUAL,
			type: TypeFilter.BOOLEAN
		}].filter(f => !!f);
	}
}