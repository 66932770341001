import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PlanEntretienListItemComponent } from './plan-entretien-list-item.component';

@Component({
	templateUrl: './plan-entretien-list.component.html'
})
export class PlanEntretienListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,PlanEntretienListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PlanEntretienListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtrePlansEntretien`,
			title: this.translateService.instant('vehicule.planEntretien.liste.title'),
			component: PlanEntretienListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'duree',
				type: TypeFilter.DECIMAL
			},{
				clef: 'distance',
				type: TypeFilter.DECIMAL
			},{
				clef: 'unite',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'unite',
					filter: 'DISTANCE'
				}
			}],
			getKeyFieldName: () => 'idPlanEntretien',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/Vehicule/loadPlanEntretien/0')
		})
	}
}