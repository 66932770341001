import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { StepperModule } from 'src/app/share/components/stepper/stepper.module';
import { NotificationAddDestinataireComponent } from './notification-add-destinataire.component';
import { NotificationDestinatairesComponent } from './notification-destinataires.component';
import { NotificationTemplateListItemComponent } from './notification-template-list-item.component';
import { NotificationTemplateListComponent } from './notification-template-list.component';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';
import { NotificationDestinataireCheckComponent } from './notification-destinataire-check.component';
import { PluralTranslateModule } from 'src/app/share/pipe/plural-translate/plural-translate.module';
import { NotificationAttachmentsComponent } from './notification-attachments.component';
import { NotificationResumeComponent } from './notification-resume.component';
import { AttachmentModule } from 'src/app/share/components/attachment/attachment.module';
import { RecaptchaModule } from 'src/app/share/directive/recaptcha/recaptcha.module';
import { SummernoteModule } from 'src/app/share/components/summernote/summernote.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { FilterModule } from 'src/app/share/pipe/filter/filter.module';

@NgModule({
	imports: [CoreComponentsModule,ListViewModule,StepperModule,AutocompleteModule,PluralTranslateModule,AttachmentModule,RecaptchaModule,SummernoteModule,IconModule,FilterModule],
	declarations: [NotificationComponent,NotificationTemplateListComponent,NotificationTemplateListItemComponent,NotificationDestinatairesComponent,NotificationAddDestinataireComponent,NotificationDestinataireCheckComponent,NotificationAttachmentsComponent,NotificationResumeComponent],
	providers: [NotificationService]
})
export class NotificationModule {}