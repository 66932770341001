import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class TodoListService {
	/** Etat de la Todo-List **/
	private _isDisplayed: boolean = false;

	/** Indentifiant de la tâche enregistrée pour l'affichage **/
	private savedIdItem: number = null;

	/** Etat de l'affichage **/
	public isShowDetail: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Récupération de l'état de la Todo-List
	 */
	public isDisplayed(): boolean {
		//Récupération de l'état de la Todo-List
		return this._isDisplayed;
	}

	/**
	 * Affichage de la Todo-List
	 */
	public toggleDisplay() {
		//Mise à jour de l'état de la Todo-List
		this._isDisplayed = !this._isDisplayed;
	}

	/**
	 * Affichage de la tâche
	 */
	public showTask(idItem: any) {
		//Masquage de la Todo-List
		this._isDisplayed = false;

		//Enregistrement de l'identifiant de la tâche
		this.savedIdItem = idItem;

		//Affichage de la Todo-List
		this._isDisplayed = true;
	}

	/**
	 * Récupération de la tâche enregistrée
	 */
	public getSavedIdItem(): any {
		//Récupération de l'identifiant de la tâche enregistrée
		return this.savedIdItem;
	}

	/**
	 * Réinitialisation de la tâche enregistrée
	 */
	public resetSavedIdItem() {
		//Réinitialisation de la tâche enregistrée
		this.savedIdItem = null;
	}

	/**
	 * Suppression de la tâche
	 */
	public deleteItem(item: any): Observable<Result> {
		//Suppression de la tâche
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TodoList/deleteItem/${item.idItem}`);
	}

	/**
	 * Suppression des tâches
	 */
	public deleteAll(statut: 'ALL' | 'TODO' | 'DONE'): Observable<Result> {
		//Suppression des tâches
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TodoList/deleteAll/${statut}`);
	}

	/**
	 * Enregistrement de la tâche
	 */
	public saveItem(item: any): Observable<Result> {
		//Enregistrement de la tâche
		return this.http.put<Result>(`${environment.baseUrl}/controller/TodoList/saveItem`,item);
	}
}