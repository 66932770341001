import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { UserCategorieAddComponent } from './user-categorie-add.component';
import { UserCategorieService } from './user-categorie.service';
import { UserCategorieListComponent } from './user-categorie-list.component';
import { UserCategorieListItemComponent } from './user-categorie-list-item.component';
import { UserCategorie } from './user-categorie.component';

@NgModule({
	imports: [ShareModule],
	declarations: [UserCategorieAddComponent,UserCategorieListComponent,UserCategorieListItemComponent,UserCategorie],
	providers: [UserCategorieService]
})
export class UserCategorieModule { }
