import { HttpClient } from '@angular/common/http';
import { Component,EventEmitter,OnDestroy,OnInit,Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription,of } from 'rxjs';
import { catchError,debounceTime,filter,finalize,switchMap,tap } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';
import { GlobalSearchService } from './global-search.service';

@Component({
	selector: 'global-search',
	templateUrl: './global-search.component.html'
})
export class GlobalSearchComponent implements OnInit,OnDestroy {
	/** Evènement en cas de fermeture de la GlobalSearch **/
	@Output() onClose: EventEmitter<any> = new EventEmitter<any>();

	/** FromControl sur l'input **/
	autocompleteControl = new UntypedFormControl();

	/** Liste des agrégats **/
	listeAgregats: Array<any>;

	/** Indicateur de chargement **/
	isSearching: boolean = false;

	/** Agrégat courant **/
	currentAgregat: number;

	/** Texte saisi **/
	globalSearchText: string;

	/** Etat du focus de la GlobalSearch **/
	focused: boolean = false;

	/** Etat de la souris présente dans dans le bloc de suggestions **/
	mouseEntered: boolean = false;

	/** Liste des souscriptions **/
	listeSouscriptions: Array<Subscription> = new Array<Subscription>();

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private rightService: RightService,private globalSearchService: GlobalSearchService,private layoutService: LayoutService) {}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Souscription sur la saisie de texte
		this.listeSouscriptions.push(this.autocompleteControl.valueChanges.pipe(
			filter(i => i?.length > 0),
			tap(() => {
				//Indicateur de chargement
				this.isSearching = true;
			}),
			debounceTime(500),
			tap((value) => {
				//Suppression des valeurs existantes
				this.listeAgregats = [];

				//Texte saisi
				this.globalSearchText = value;
			}),
			switchMap(value => this.http.post<Result>(`${environment.baseUrl}/controller/Search/doSearch`,{
				query: `${value.replace(/%/g,'*')}*`
			}).pipe(
				catchError(() => {
					//Aucun résultat
					return of([]);
				}),
				finalize(() => {
					//Recherche finie
					this.isSearching = false;
				})
			))
		).subscribe({
			next: (result: Result) => {
				//Vérification du résultat
				if (result?.data) {
					//Ajout des agrégats du point de vue collaborateur à la GlobalSearch
					this.feedGlobalSearch(result.data.listeAgregats,null);

					//Ajout des agrégats du point de vue responsable à la GlobalSearch
					this.feedGlobalSearch(result.data.listeAgregatsValideur,true);
				} else {
					//Suppression des valeurs existantes
					this.listeAgregats = null;

					//Suppression de la valeur recherchée
					this.globalSearchText = '';
				}
			}
		}));

		//Souscription sur l'absence de texte
		this.listeSouscriptions.push(this.autocompleteControl.valueChanges.pipe(
			filter(i => i == null || i.length == 0),
			tap(() => {
				//Recherche finie
				this.isSearching = false;

				//Suppression des valeurs existantes
				this.listeAgregats = null;

				//Suppression de la valeur recherchée
				this.globalSearchText = '';
			})
		).subscribe());
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.listeSouscriptions.forEach(s => s.unsubscribe());
	}

	/**
	 * Sélection d'un agrégat
	 */
	public selectAgregat(agregat: any) {
		let listeItems = [];
		let clef = '';

		//Vérification de la liste des champs
		if (agregat.listeFields) {
			//Parcours des champs
			for (let idxField = 0;idxField < agregat.listeFields.length;idxField++)
				//Ajout du champ
				clef += (idxField > 0 ? ',' : '') + agregat.listeFields[idxField];
		}

		//Découpage du texte recherché
		this.globalSearchText.split(' ').filter(valeur => valeur?.length).forEach(valeur => {
			//Ajout du critère à la liste
			listeItems.push({
				clef,
				valeur: (this.globalSearchText.match(/[\s-]/) ? '*' : '') + valeur,
				displayedValeur: valeur,
				mapEncryptedFields: agregat.mapEncryptedFields
			});
		})

		//Stockage de la recherche
		sessionStorage.setItem('savedSearch.' + agregat.state,JSON.stringify({
			listeFiltres: listeItems,
			listeSelectedFilters: listeItems
		}));

		//Redirection vers la route
		this.layoutService.goToByState(agregat.state,{ reloadOnSameUrl: true });

		//Réinitialisation de la GlobalSearch
		this.autocompleteControl.setValue('');
		this.listeAgregats = null;
		this.focused = false;
		this.mouseEntered = false;
	}

	/**
	 * Alimentation des résultats de la GlobalSearch à partir d'une liste d'agrégats
	 */
	private feedGlobalSearch(listeAgregats: Array<any>,isValidation: boolean) {
		//Vérification de la liste
		if (listeAgregats?.[0]) {
			//Définition de la liste
			this.listeAgregats = this.listeAgregats || [];

			//Définition de l'index courant
			this.currentAgregat = 0;

			//Parcours des agrégats
			listeAgregats[0].forEach(agregat => {
				let newAgregat: any;

				//Lecture des données
				newAgregat = {
					type: agregat.value,
					nbTotal: agregat.nbItems
				};

				//Vérification de la présence de sous-agrégats
				if (agregat.listeAgregats) {
					//Parcours des sous-agrégats
					agregat.listeAgregats.forEach(subAgregat => {
						//Vérification de la clef
						if (subAgregat.value == '1')
							//Définition du nombre d'éléments actifs
							newAgregat.nbActif = subAgregat.nbItems;
					});
				}

				//Récupération des informations depuis les mapping
				newAgregat = this.globalSearchService.completeItem(newAgregat,isValidation);

				//Vérification des droits
				if (isValidation || newAgregat.listeTypeDroits?.[0] && this.rightService.hasRight(newAgregat.listeTypeDroits[0],'consultation'))
					//Ajout de l'agrégat à la liste
					this.listeAgregats.push(newAgregat);
			});
		}
	}

	/**
	 * Gestion de la saisie clavier
	 */
	public onKeyDown(event: any) {
		//Vérification de la touche
		if (event.key == 'ArrowUp') {
			//Annulation de l'évènement
			event.preventDefault();

			//Vérification de l'index
			if (this.currentAgregat > 0) {
				//Définition de l'agrégat sélectionné
				this.currentAgregat--;

				//Scroll vers l'élément
				this.scrollIntoView(`item_menu-${this.currentAgregat}`);
			}
		} else if (event.key == 'ArrowDown') {
			//Annulation de l'évènement
			event.preventDefault();

			//Vérification de l'index
			if (this.currentAgregat < this.listeAgregats.length - 1) {
				//Définition de l'agrégat sélectionné
				this.currentAgregat++;

				//Scroll vers l'élément
				this.scrollIntoView(`item_menu-${this.currentAgregat}`);
			}
		} else if (event.key == 'Enter') {
			//Vérification de la valeur
			if (this.globalSearchText != '') {
				//Touche 'Entrée'
				event.preventDefault();

				//Vérification des agrégats
				if (this.listeAgregats)
					//Sélection de l'agrégat
					this.selectAgregat(this.listeAgregats[this.currentAgregat]);
			}
		}
	}

	/**
	 * Scroll vers un élément
	 */
	private scrollIntoView(id: string) {
		let element: Element;

		//Recherche de l'élément par son identifiant
		element = document.querySelector(`#${id}`);

		//Scroll vers l'élément
		element?.scrollIntoView({ behavior: 'smooth',block: 'center' });
	}
}