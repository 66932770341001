import { Component,Input,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'vehicule-releve-compteur-edit',
	templateUrl: './vehicule-releve-compteur-edit.component.html'
})
export class VehiculeReleveCompteurEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() releveCompteur: any;

	/** Suppression du relevé de compteur **/
	@Input() deleteReleveCompteur: (releveCompteur: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	public user: User;

	/** Sauvegarde du compteur d'origine **/
	public savedReleveCompteur: any;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sauvegarde du compteur d'origine
		this.savedReleveCompteur = cloneDeep(this.releveCompteur);

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => this.user = user);
	}
}