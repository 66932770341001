import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DataCustomizationService } from './data-customization.service';

@Component({
	selector: 'data-customization-edit',
	templateUrl: './data-customization-edit.component.html'
})
export class DataCustomizationEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() dataCustomization: any;

	/** Liste des entités **/
	public listeEntities: Array<any> = [];

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private dataCustomizationService: DataCustomizationService,private extractionService: ExtractionService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Copie du référentiel personnalisé
		this.dataCustomization = cloneDeep(this.dataCustomization);

		//Recherche de la liste des entités disponibles
		combineLatest([this.extractionService.getListeEntites('CUSTOM_FORM')
				,this.dataCustomizationService.filtreDataCustomizationsEntite({ nbObjetsParPage: 999 })
			]).pipe(first()).subscribe(([listeEntites,pageDataCustomizationEntites]) => {
				//Vérification du mode 'Création'
				if (!this.dataCustomization.idDataCustomizationEntite)
					//Suppression des entités déja utilisées
					listeEntites = listeEntites.filter(e => !pageDataCustomizationEntites?.content?.find(d => d.entite == e.code));

				//Liste des entités
				this.listeEntities = listeEntites;
			});
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveDataCustomization() {
		//Enregistrement de l'objet
		this.dataCustomizationService.saveDataCustomization(this.dataCustomization).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.dataCustomization = Object.assign(this.dataCustomization,result.data?.dataCustomization);

					//Fermeture du formulaire
					this.close(this.dataCustomization);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,0))
							//Ajout de la référence
							listeDoublons.push(this.translateService.instant('actions.doublon.entite'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}

	/**
	 * Contrôle anti-doublon des libellés
	 */
	public checkDoublon(field?: any): boolean {
		let mapDoublons: any = {};

		//Parcours des référentiels
		['customRef','alpha','numeric','date'].forEach(type => {
			//Parcours des indices
			[1,2,3,4].forEach(num => {
				//Vérification des champs
				if (this.dataCustomization[type+num])
					//Mise à jour du compteur des libellés des champs
					mapDoublons[this.dataCustomization[type+num]] = (mapDoublons[this.dataCustomization[type+num]] || 0) + 1;
			})
		});

		//Vérification des doublons
		return Object.keys(mapDoublons).filter(k => field == null || field != null && this.dataCustomization[field] == k).filter(k => mapDoublons[k] > 1).length > 0;
	}
}