import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';
import { TypeTransmissionLineItemComponent } from './type-transmission.line-item';

/**
 * Options des types de transmission
 */
export default class TypeTransmissionOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.typeTransmission';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/VehiculeReferentiel/listeTypeTransmissionsActives`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item?.libelle} (${item?.reference} - ${translateService.instant('referentiel.typeReferentiel.'+item?.typeSource)}${item?.pays?.code2?.length ? ' - ' + item.pays.code2 : ''})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.TypeTransmission';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTypeTransmission'; }

	/**
	 * Composant d'affichage d'une ligne
	 */
	lineItemComponent?: () => any = () => TypeTransmissionLineItemComponent;

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'businessData.typeSource',
			getValue: (item: any,translateService: TranslateService) => item?.typeSource ? translateService.instant('referentiel.typeReferentiel.'+item?.typeSource) : translateService.instant('common.nonDefini')
		},{
			title: 'businessData.pays',
			getCountryCode: (item: any) => item?.pays?.code2,
			getValue: (item: any) => item?.pays?.libelle
		}];
	}
}