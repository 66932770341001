import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { LoginService } from 'src/app/share/login/login.service';
import { mapEntites } from './revendeur.entites';
import { RevendeurService } from './revendeur.service';

@Component({
	templateUrl: './revendeur-list-item.component.html'
})
export class RevendeurListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur **/
	user: User;

	/** Utilisateur d'origine **/
	userOrigine: User;

	/**
	 * Constructeur
	 */
	constructor(private revendeurService: RevendeurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private store: Store<AppState>,private loginService: LoginService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation des composants
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<{ user: User,userOrigine: User }>(state => ({
			user: state.session?.user,
			userOrigine: state.session?.userOrigine
		})).pipe(first()).subscribe({
			next: ({ user,userOrigine }) => {
				//Définition des utilisateurs
				this.user = user;
				this.userOrigine = userOrigine;
			}
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification du revendeur
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idRevendeur,object?.idRevendeur && !this.data?.idRevendeur);
	}

	/**
	 * Suppression du revendeur
	 */
	deleteRevendeur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du revendeur
					this.revendeurService.deleteRevendeur(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du revendeur
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.revendeur.Revendeur',this.data.idRevendeur);
	}

	/**
	 * Connexion en tant qu'administrateur du revendeur
	 */
	loginAsAdminRevendeur() {
		//Chargement du tenant du revendeur
		this.revendeurService.loadTenantForRevendeur(this.data.idRevendeur).subscribe({
			next: (result: Result) => {
				//Vérification de la présence du tenant
				if (result.data.tenant)
					//Connexion en tant qu'administrateur du revendeur
					this.loginService.loginAsAdminRevendeur(result.data.tenant);
				else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('revendeur.loginAsAdminRevendeur.error'));
			}
		});
	}
}