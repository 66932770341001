import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutocompleteOptions } from './autocomplete';
import { AutocompleteSearchComponent } from './search/autocomplete-search.component';
import * as options from './options';
import { AutocompleteAddComponent } from './autocomplete-add.component';

@Injectable()
export class AutocompleteService {
	/** Cache des options par type **/
	private mapOptionsForEntite: { [entite: string]: AutocompleteOptions } = null;

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) {}

	/**
	 * Récupération des options d'autocomplete d'un type générique
	 */
	public getOptionsForType(type: string): AutocompleteOptions {
		//Retour d'une instance de la classe des options de l'autocomplete
		return new options[type];
	}

	/**
	 * Récupération des options d'autocomplete pour une entité donnée
	 */
	public getOptionsForEntite(entite: string): AutocompleteOptions {
		//Vérification du cache
		if (!this.mapOptionsForEntite) {
			//Création du cache
			this.mapOptionsForEntite = Object.keys(options).reduce((actual,key) => {
				let autocompleteOptions: AutocompleteOptions;

				//Création des options
				autocompleteOptions = new options[key];

				//Vérification des options
				if (autocompleteOptions?.type)
					//Ajout des options au cache
					actual[autocompleteOptions.type] = autocompleteOptions;

				return actual;
			},{});
		}

		//Retour des options liées à l'entité
		return this.mapOptionsForEntite[entite];
	}

	/**
	 * Affichage de la recherche
	 */
	showSearch({ label,options,type,filter }: { label?: string,options?: AutocompleteOptions,type?: string,filter?: any }): Observable<any> {
		let bsModalRef: BsModalRef<AutocompleteSearchComponent>;

		//Vérification des options
		options = options || this.getOptionsForType(type);

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(AutocompleteSearchComponent,{
			initialState: {
				searchData: {
					title: label,
					options: options,
					filter: filter
				}
			},
			class: `modal-${options?.size || 'lg'}`
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.selectedItem)
		);
	}

	/**
	 * Affichage de la pop-up de création d'un élément
	 */
	showCreation<T extends AutocompleteAddComponent>(options?: AutocompleteOptions,type?: string,filter?: any): Observable<any> {
		let bsModalRef: BsModalRef<T>;

		//Vérification des options
		options = options || this.getOptionsForType(type);

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(options.addComponent?.(),{
			initialState: {
				filter
			} as any,
			class: options.addComponent?.()?.['modalSize']
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.addedItem)
		);
	}

	/**
	 * Recherche de l'autocomplete associé à une entité
	 */
	findAutocompleteForEntite(entite: string): any {
		let name: string;
		let option: AutocompleteOptions;

		//Recherche de l'autocomplete associé à l'entité
		name = Object.keys(options).find(key => (new options[key])?.type === entite)

		//Vérification de l'autocomplete
		if (name) {
			//Chargement des options
			option = new options[name];

			//Retour des options
			return {
				...option,
				name: name
			};
		} else
			//Aucun retour
			return null;
	}
}