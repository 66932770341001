import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { ActionToastComponent } from './action-toast.component';

@NgModule({
	imports: [CommonModule,TranslateModule,MatButtonModule],
	declarations: [ActionToastComponent]
})
export class ActionToastModule {}