<div class="modal-header">
	<h4 class="modal-title"><span translate>vehicule.modele.configuration.contrainte.title</span></h4>
</div>
<div class="modal-body configurateur">
	<div class="card-body card-padding">
		<div class="constraint-detail">
			<h4 translate>vehicule.modele.configuration.contrainte.choice</h4>
			<div class="detail media">
				<span class="label label-info pull-right">
					<icon name="add"></icon>&nbsp;<span>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? detail?.option?.prixHT : detail?.option?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
				</span>
				<div class="media-body">
					<div class="word-break"><span class="bold">{{ detail?.option?.libelle }}</span></div>
				</div>
			</div>
			<div class="detail-description" *ngIf="contrainteObject?.packDetail">
				<span>{{ contrainteObject?.packDetail }}</span>
			</div>
		</div>
		<div class="constraint-detail" *ngIf="contrainteObject?.listeExclusions?.length || getListePrimaryInclusionsToDisplay()?.length">
			<h4 translate>vehicule.modele.configuration.contrainte.impacts</h4>
			<div class="detail">
				<div class="listview lv-bordered">
					<div class="lv-body">
						<div class="lv-item media p-l-0 p-r-0" *ngFor="let contrainte of contrainteObject?.listeExclusions">
							<div class="pull-left lv-avatar lv-avatar-small">
								<icon name="delete"></icon>
							</div>
							<div class="label pull-right price-info-exclusion">
								<icon name="remove"></icon>&nbsp;<span>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? contrainte?.option?.prixHT : contrainte?.option?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							</div>
							<div class="media-body">
								<div class="lv-title word-break"><span>{{ contrainte?.option?.libelle }}</span></div>
							</div>
						</div>
						<div class="lv-item media p-l-0 p-r-0" *ngFor="let contrainte of listePrimaryInclusionsToDisplay">
							<div class="pull-left lv-avatar lv-avatar-small">
								<icon name="add"></icon>
							</div>
							<div class="label label-info pull-right">
								<icon name="add"></icon>&nbsp;<span>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? contrainte?.prixHT : contrainte?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							</div>
							<div class="media-body">
								<div class="lv-title word-break"><span>{{ contrainte?.libelle }}</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="constraint-detail" *ngIf="contrainteObject?.listeObligations?.length">
			<h4 translate>vehicule.modele.configuration.contrainte.complements</h4>
			<div class="detail">
				<div class="listview lv-bordered">
					<div class="lv-body">
						<div class="lv-item media p-l-0 p-r-0" *ngFor="let contrainte of contrainteObject?.listeObligations">
							<div *ngIf="!isObligationIncluded(contrainte)" class="label pull-right" [ngClass]="contrainte?.selected ? 'label-info' : 'label-default'">
								<icon name="add"></icon>&nbsp;<span>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? contrainte?.prixHT : contrainte?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							</div>
							<div *ngIf="isObligationIncluded(contrainte)" class="label pull-right" [ngClass]="contrainte?.selected ? 'label-info' : 'label-default'">
								<icon name="add"></icon>&nbsp;<s>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? contrainte?.prixHT : contrainte?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</s>
							</div>
							<div class="media-body">
								<div class="lv-title word-break checkbox">
									<label [title]="getSelectorTitleForObligation(contrainte)">
										<input type="checkbox" [(ngModel)]="contrainte.selected" (ngModelChange)="onObligationSelectionChange(contrainte)" [disabled]="contrainte?.detail?.option?.type == 'SERIE' || isObligationIncluded(contrainte) || isObligationObliged(contrainte)"/>
										<i class="input-helper"></i>
										<span>{{ contrainte?.libelle }}</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="constraint-detail" *ngIf="impactObligations?.listeExclusions?.length || getListeSecondaryInclusionsToDisplay()?.length">
			<h4 translate>vehicule.modele.configuration.contrainte.impacts</h4>
			<div class="detail">
				<div class="listview lv-bordered">
					<div class="lv-body">
						<div class="lv-item media p-l-0 p-r-0" *ngFor="let obligation of impactObligations?.listeExclusions">
							<div class="pull-left lv-avatar lv-avatar-small">
								<icon name="delete"></icon>
							</div>
							<div class="label pull-right price-info-exclusion">
								<icon name="remove"></icon>&nbsp;<span>{{ getActualPriceToRemove(obligation) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							</div>
							<div class="media-body">
								<div class="lv-title word-break"><span>{{ obligation?.option?.libelle }}</span></div>
							</div>
						</div>
						<div class="lv-item media p-l-0 p-r-0" *ngFor="let contrainte of getListeSecondaryInclusionsToDisplay()">
							<div class="pull-left lv-avatar lv-avatar-small">
								<icon name="add"></icon>
							</div>
							<div class="label label-info pull-right">
								<icon name="add"></icon>&nbsp;<span>{{ (configuration?.modele?.information?.genre?.modeAffichagePrix == 'HT' ? contrainte?.prixHT : contrainte?.prixTTC) | currency:configuration?.modele?.information?.commercialisationPrix?.devise }}</span>
							</div>
							<div class="media-body">
								<div class="lv-title word-break"><span>{{ contrainte?.libelle }}</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef?.hide()">
		<span translate>actions.annuler</span>
	</button>
	<button mat-raised-button color="primary" (click)="validate()">
		<span translate>actions.valider</span>
	</button>
</div>