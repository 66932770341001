import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,of,Subject } from 'rxjs';
import { map,tap } from 'rxjs/operators';

import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { environment } from 'src/environments/environment';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';

@Injectable()
export class GrilleAttributionService {
	/** Présence d'une grille active **/
	private isGrilleAttributionActiveAvailableCache: boolean;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private successiveSearchService: SuccessiveSearchService,private currencyPipe: CurrencyPipe,private grilleAttributionModeleService: GrilleAttributionModeleService) {
		//Binding des méthodes
		this.getTitleForModele = this.getTitleForModele.bind(this);
	}

	/**
	 * Chargement de la grille d'attribution
	 */
	public loadGrilleAttribution(idGrilleAttribution: number): Observable<Result> {
		//Chargement de la grille d'attribution
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadGrilleAttribution/${idGrilleAttribution}`,null);
	}

	/**
	 * Suppression de la grille d'attribution
	 */
	public deleteGrilleAttribution(grilleAttribution: any): Observable<Result> {
		//Suppression de la grille d'attribution
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteGrilleAttribution/${grilleAttribution.idGrilleAttribution}`);
	}

	/**
	 * Enregistrement de la grille d'attribution
	 */
	public saveGrilleAttribution(grilleAttribution: any): Observable<Result> {
		//Enregistrement de la grille d'attribution
		return this.http.put<Result>(`${environment.baseUrl}/controller/Vehicule/saveGrilleAttribution`,grilleAttribution);
	}

	/**
	 * Recherche d'une grille d'attribution active
	 */
	public isGrilleAttributionActiveAvailable(): Observable<boolean> {
		//Vérification de la présence du cache
		if (this.isGrilleAttributionActiveAvailableCache === undefined) {
			//Initialisation du cache
			this.isGrilleAttributionActiveAvailableCache = false;

			//Recherche d'une grille d'attribution active
			return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Vehicule/listeGrillesAttributionActives`,{ nbObjetsParPage: 1 }).pipe(
				map(page => page.totalElements > 0),
				tap(isGrilleAttributionActiveAvailable => this.isGrilleAttributionActiveAvailableCache = isGrilleAttributionActiveAvailable)
			);
		} else
			//Lecture du cache
			return of(this.isGrilleAttributionActiveAvailableCache);
	}

	/**
	 * Récupération de la liste des modèles par grille d'attribution
	 */
	public getListeModelesByGrilleAttribution(grilleAttribution: any): Observable<any> {
		let successiveSearchOptions: SuccessiveSearchOptions;
		let subject: Subject<any> = new Subject<any>();

		//Récupération des options de la recherche successive
		successiveSearchOptions = this.getSuccessiveSearchOptions(grilleAttribution);

		//Affichage de la recherche successive
		this.successiveSearchService.showSuccessiveSearch(successiveSearchOptions,{ modalClass: 'modal-max' }).subscribe({
			next: result => {
				let listeSelections;

				//Vérification du résultat
				if (result) {
					//Vérification du résultat obtenu
					if (result.listeSelectionsItems?.length) {
						//Définition de la sélection d'éléments
						listeSelections = {
							_type: 'com.notilus.model.search.AggregatedItemSelection',
							listeSelectionsByAggregator: result.listeSelectionsItems,
							nbSelectedItems: result.nbSelectedItems
						};

						//Retour de la sélection de modèles
						subject.next(listeSelections);

						//Fin de traitement
						subject.complete();
					}
				} else {
					//Emission d'une erreur
					subject.error(null);

					//Fin de traitement
					subject.complete();
				}
			},
			error: (erreur) => {
				//Transmission de l'erreur
				subject.error(erreur);

				//Fin de traitement
				subject.complete();
			}
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Récupération du titre d'un élément de la liste des modèles
	 */
	private getTitleForModele(item: any): string {
		//Retour du titre
		return `${item.marque?.libelle || ''} ${item.libelle} - ${
			item.information?.genre?.modeAffichagePrix == 'TTC' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeTTC',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixTTC,'.2-2',item.information.commercialisationPrix.devise) }) :
				(item.information?.genre?.modeAffichagePrix == 'HT' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeHT',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixHT,'.2-2',item.information.commercialisationPrix.devise) }) : '')
		}`;
	}

	/**
	 * Récupération des options de recherche successive
	 */
	private getSuccessiveSearchOptions(grilleAttribution: any): SuccessiveSearchOptions {
		let searchSpec;

		//Définition de la recherche
		searchSpec = {
			listeFilter: [{
				clef: 'grilleAttribution.idGrilleAttribution',
				valeur: grilleAttribution.idGrilleAttribution,
				typeComparaison: TypeComparaison.NOT_EQUALS,
				type: TypeFilter.LONG
			},{
				clef: 'grilleAttribution.isActif',
				valeur: true,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.BOOLEAN
			}]
		}

		//Retour des options de la recherche successive
		return {
			title: this.translateService.instant('vehicule.grilleAttribution.modele.search.title'),
			typeSearchLevel: 'GRILLE',
			search: searchSpec,
			isShowFilter: true,
			groupeKey: 'grilleAttribution',
			idGroupeKey: 'idGrilleAttribution',
			idItemKey: 'idGrilleAttribution',
			idSecondaryItemKey: 'idModele',
			listeSearchLevels: [{
				type: 'GRILLE',
				libelle: this.translateService.instant('vehicule.grilleAttribution.modele.search.item'),
				emptyLibelle: this.translateService.instant('searchEngine.libelleMissing.nonCommuniquee'),
				uri: () => `/controller/Vehicule/filtreModelesGroupedByGrilleAttribution`,
				getListeFilters: () => [{
					clef: 'grilleAttribution.libelle',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'grilleAttribution.idGrilleAttribution',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'LONG'
					}
				},
				getListeFiltersExtended(options) {
					//Retour des filtres
					return options?.search?.listeFilter || [];
				}
			},{
				type: 'MODELE',
				libelle: this.translateService.instant('vehicule.grilleAttribution.modele.search.modele'),
				uri: () => `/controller/Vehicule/filtreModelesByGrilleAttribution`,
				getListeFilters: () => [{
					clef: 'modele.marque.libelle',
					type: TypeFilter.STRING,
					isDefault: true
				},{
					clef: 'modele.libelle',
					type: TypeFilter.STRING,
					isDefault: true
				},{
					clef: 'modele.reference',
					type: TypeFilter.STRING,
					isDefault: true
				}],
				defaultOrder: 'modele.marque.libelle,modele.libelle',
				isListView: true,
				getTitle: (item)=> this.getTitleForModele(item.modele),
				getAvatar: (item) => item.modele.reference.substring(0,3).toUpperCase(),
				getListeItemAttributes: (item) => this.grilleAttributionModeleService.getListeAttributesForModele(item.modele),
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,listeCumulatedFilters).slice(0,1)).concat(options?.search?.listeFilter);

					//Retour des filtres
					return listeFilters;
				}
			}]
		}
	}
}