import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DeviseService } from './devise.service';

@Component({
	selector: 'devise-list-item',
	templateUrl: './devise-list-item.component.html'
})
export class DeviseListItemComponent extends ReferentielEntiteListItem<any> implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.devise.Devise';

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private historyService: HistoryService,private deviseService: DeviseService,rightService: RightService,referentielService: ReferentielService,private layoutService: LayoutService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteDevise() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.deviseService.deleteDevise(this.data,this.liste.selectedSelector))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Vérification du type de référentiel et de la suppression d'une devise importée
				if (this.liste.selectedSelector == TypeReferentiel.LOCAL && this.data.deviseOrigine)
					//Rafraichissement du compteur des entités standard
					this.refreshReferentiel();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.devise.Devise',this.data.idDevise);
	}
}
