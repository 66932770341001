import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView,TypeComparaison } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ContratFinancementEcheanceListItemComponent } from './contrat-financement-echeance-list-item.component';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	templateUrl: './contrat-financement-echeance-list.component.html'
})
export class ContratFinancementEcheanceListComponent extends PageContentItem implements OnInit {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Résumé **/
	@Input() resume: { nbEcheances: number,nbEcheancesAnomalie: number };

	/** Indicateur d'affichage des anoamlies **/
	@Input() isShowAnomaliesForEcheances: boolean;

	/** Mise à jour du résumé **/
	@Input() onResumeChange: (resume: any) => void;

	/** Données **/
	liste: ListView<any,ContratFinancementEcheanceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService,private contratFinancementService: ContratFinancementService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ContratFinancementEcheanceListItemComponent>({
			uri: () => `/controller/Vehicule/filtreContratEcheances/${this.contratFinancement.idContrat}`,
			component: ContratFinancementEcheanceListItemComponent,
			isLocal: true,
			extraOptions: {
				contratFinancement: this.contratFinancement,
				resume: this.resume,
				onResumeChange: this.onResumeChange
			},
			listeFilters: [{
				clef: 'ordreFinancement',
				isDefault: true
			}],
			defaultOrder: '-isAnomalieLoyer,-ordre',
			listeActions: [{
				icon: 'check',
				messagingOptions: {
					entryPoint: `controller/Vehicule/doValidationListeEcheances/${this.contratFinancement.idContrat}`
				},
				isVisible: () => this.rightService.hasRight(null,'creation'),
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => {
					//Mise à jour des échéances
					this.contratFinancementService.doValidationForListeEcheances(messagingOptions,this.liste).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR)
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));
							else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
						},
						complete: () => {
							//Chargement du résumé
							this.contratFinancementService.loadResumeContratFinancement(this.contratFinancement.idContrat).pipe(first()).subscribe({
								next: result => {
									//Mise à jour du résumé
									this.onResumeChange({
										...this.resume,
										nbEcheancesAnomalie: result?.data?.nbEcheancesAnomalie || 0
									});
								}
							})
						}
					});
				}
			}],
			getKeyFieldName: () => 'idEcheance'
		});

		//Vérification de l'affichage des anomalies
		if (this.isShowAnomaliesForEcheances) {
			//Définition de la liste des filtres sélectionnés
			this.liste.listeSelectedFilters = [{
				clef: 'typeAnomalieLoyer',
				valeur: 'AVEC_ECART',
				typeComparaison: TypeComparaison.EQUAL,
				displayedValeur: this.translateService.instant('contrat.financement.echeance.enAnomalie')
			}];
		}
	}
}