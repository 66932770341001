<form #etatLieuxForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>vehicule.elements.etatLieux.title</h2>
		</div>
		<div class="card-body card-padding">
			<etat-lieux-content #etatLieuxContent [etatLieux]="etatLieux" source="VEHICULE" (close)="close({ etatLieux: $event })"></etat-lieux-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteEtatLieux(etatLieux,close);" *ngIf="deleteEtatLieux && etatLieux?.idEtatLieux && (TypeDroit.ETAT_LIEUX | right:'suppression')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="etatLieuxContent.saveEtatLieux();" *ngIf="TypeDroit.ETAT_LIEUX | right:'creation'" [disabled]="etatLieuxForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: etatLieuxForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>