<div class="tile-toggle">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3) }}</div>
		<div class="lv-title">
			<a [routerLink]="['/ContratCRM/loadContratCRM/',data.idContrat]">{{ data.clientCRM?.libelle + ' (' + data.reference + ')' }}</a>
		</div>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>contratCRM.revendeur</span>&#160;:&#160;<span [translate]="data.revendeur.libelle"></span></li>
		<li *ngIf="data.articleCRM"><span translate>contratCRM.articleCRM</span>&#160;:&#160;<span>{{ data.articleCRM.libelle }}</span></li>
		<li *ngIf="data.dateEffet"><span translate>contratCRM.dateEffet</span>&#160;:&#160;{{ data.dateEffet | date:'shortDate' }}</li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression')"><a [routerLink]="[]" (click)="deleteContratCRM();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>