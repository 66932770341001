import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'compte-service-edit',
	templateUrl: './compte-service-edit.component.html'
})
export class CompteServiceEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() compteService: any;

	/** Liste des types de périmètre **/
	@Input() listeTypesPerimetre: Array<{ code: string,libelle: string }>;

	/** Suppression de l'objet **/
	@Input() deleteCompteService: () => void;

	/** Enregistrement de l'objet **/
	@Input() saveCompteService: (compteService: any,close: Function) => void;

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}
}