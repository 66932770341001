<div class="container-fluid">
	<form #structureExportForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || isRestricted">
					<h4><span translate>structureExport.informationsGenerales</span></h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>structureExport.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="structureExport.libelle" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>structureExport.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="structureExport.reference" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
								<div class="half">
									<label translate>structureExport.dateApplication</label>
									<div>
										<input type="text" name="dateApplication" #dateApplication="ngModel" class="form-control" [(ngModel)]="structureExport.dateApplication" datePicker format="date" [storeDate]="true" required/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>structureExport.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'structureExport.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="structureExport.actif" [disabled]="!structureExport.valid"><span translate>structureExport.actif.description</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nbFichiers.invalid }">
								<div class="half">
									<label translate>structureExport.nbFichiers.item</label>
									<div>
										<div class="input-group">
											<input type="text" name="nbFichiers" #nbFichiers="ngModel" class="form-control" [(ngModel)]="structureExport.nbFichiers" nNumber min="1" max="3" required/>
											<div class="input-group-addon">
												<icon name="info_outline" [tooltip]="'structureExport.nbFichiers.info' | translate"></icon>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>calendrier.remarque</label>
						<div>
							<textarea class="form-control" rows="3" name="remarque" [(ngModel)]="structureExport.remarque"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="card-body card-padding m-t-20" *ngIf="!rightService.isRoot()">
				<fieldset [disabled]="!(null | right:'creation') || isRestricted">
					<h4><span translate>structureExport.listeSocietes.title</span></h4>
					<div>
						<div>
							<basket [model]="structureExport" propertyName="listeSocietes" colWidthLabel="12" colWidthAutocomplete="6" type="societe" [labelAutocomplete]="'structureExport.listeSocietes.ajout' | translate" [labelListe]="'structureExport.listeSocietes.liste' | translate" [hasError]="isListeSocietesInvalid"></basket>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="card-body card-padding m-t-20" *ngIf="!rightService.isRoot()">
				<fieldset [disabled]="!(null | right:'creation') || isRestricted">
					<h4><span translate>structureExport.montants.title</span></h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': nbDecimales.invalid }">
								<div class="half">
									<label translate>structureExport.montants.nbDecimales</label>
									<div class="half">
										<input type="text" name="nbDecimales" #nbDecimales="ngModel" class="form-control" [(ngModel)]="structureExport.formatNumerique.nbDecimales" nNumber min="0" required/>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': separateurDecimale.invalid }">
								<label translate>structureExport.montants.separateurDecimale</label>
								<div class="half">
									<selectpicker name="separateurDecimale" #separateurDecimale="ngModel" [(ngModel)]="structureExport.formatNumerique.separateurDecimale">
										<mat-option *ngFor="let separateur of listeSeparateursDecimale" [value]="separateur.code">{{ separateur.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeCadrage.invalid }">
								<label translate>structureExport.montants.typeCadrage</label>
								<div class="half">
									<selectpicker name="typeCadrage" #typeCadrage="ngModel" [(ngModel)]="structureExport.formatNumerique.typeCadrage" (ngModelChange)="onTypeCadrageChange()" required>
										<mat-option *ngFor="let cadrage of listeTypesCadrage" [value]="cadrage.code">{{ cadrage.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeCompletion.invalid }" *ngIf="structureExport.formatNumerique.typeCadrage != 'AUCUN'">
								<label translate>structureExport.montants.typeCompletion</label>
								<div class="half">
									<selectpicker name="typeCompletion" #typeCompletion="ngModel" [(ngModel)]="structureExport.formatNumerique.typeCompletion" required>
										<mat-option *ngFor="let completion of listeTypesCompletion" [value]="completion.code">{{ completion.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': longueur.invalid }" *ngIf="structureExport.formatNumerique.typeCadrage != 'AUCUN'">
								<div class="half">
									<label translate>structureExport.montants.longueur</label>
									<div>
										<input type="text" name="longueur" #longueur="ngModel" class="form-control" [(ngModel)]="structureExport.formatNumerique.longueur" nNumber min="0" required/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="card-body card-padding m-t-20">
				<fieldset [disabled]="!(null | right:'creation') || isRestricted">
					<h4><span translate>structureExport.gestionDates.title</span></h4>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': formatDate.invalid }">
								<label translate>structureExport.gestionDates.format</label>
								<div class="half">
									<selectpicker name="formatDate" #formatDate="ngModel" [(ngModel)]="structureExport.formatDate">
										<mat-option *ngFor="let format of listeFormatsDate" [value]="format.code">{{ format.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteStructureExport();" *ngIf="structureExport?.idStructure && !idStructureSource && (null | right:'suppression') && !isRestricted">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveStructureExport();" *ngIf="(null | right:'creation') && !isRestricted" [disabled]="structureExportForm.invalid">
				<span [translate]="'actions.' + (!idStructureSource ? (structureExport?.idStructure ? 'enregistrer' : 'continuer') : 'dupliquer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="structureExport?.idStructure && !idStructureSource" [formDetectChanges]="{ doAction: close,isDirty: structureExportForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>