import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';
import { LoginService } from 'src/app/share/login/login.service';
import { AccountService } from 'src/app/share/layout/account/account.service';

@Component({
	selector: 'header-account',
	templateUrl: './header-account.component.html'
})
export class HeaderAccountComponent {
	/** Session **/
	session: Session = null;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private loginService: LoginService,private accountService: AccountService) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
	}

	/**
	 * Affichage du profil
	 */
	showAccount(type?: 'PERSONAL_DATA' | 'PASSWORD' | 'CONTACT_ADMIN') {
		//Affichage du profil
		this.accountService.showAccount(type).subscribe();
	}

	/**
	 * Déconnexion de l'utilisateur
	 */
	logout(isCrossTabLogout) {
		//Déconnexion de l'utilisateur
		this.loginService.logout(isCrossTabLogout);
	}

	/**
	 * Reconnexion vers l'utilisateur d'orgine
	 */
	logoutToOriginalUser() {
		//Reconnexion vers l'utilisateur d'orgine
		this.loginService.logoutToOriginalUser();
	}
}