import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { AutocompleteModule } from 'src/app/share/components/autocomplete/autocomplete.module';
import { ListViewModule } from 'src/app/share/components/list-view/list-view.module';
import { PageContentModule } from 'src/app/share/components/page-content/page-content.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { FormDetectChangesModule } from 'src/app/share/directive/form-detect-changes/form-detect-changes.module';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';
import { RightModule } from 'src/app/share/pipe/right/right.module';
import { TodoListItemComponent } from './todo-list-item.component';
import { TodoListItemEditComponent } from './todo-list-item-edit.component';
import { TodoListComponent } from './todo-list.component';
import { TodoListService } from './todo-list.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { BasketModule } from 'src/app/share/components/basket/basket.module';

@NgModule({
	imports: [CommonModule,DragDropModule,RouterModule,TranslateModule,FormsModule,TooltipModule,MatChipsModule,MatInputModule,MatButtonModule,MatButtonToggleModule,MatCheckboxModule,MatRippleModule,ListViewModule,PageContentModule,SelectPickerModule,MatSelectModule,RightModule,AutocompleteModule,DatePickerModule,FormDetectChangesModule,IconModule,BasketModule],
	declarations: [TodoListComponent,TodoListItemComponent,TodoListItemEditComponent],
	exports: [TodoListComponent],
	providers: [TodoListService]
})
export class TodoListModule { }