<div class="modal-header">
	<h4 class="modal-title"><span translate>compteService.password.title</span></h4>
</div>
<div class="modal-body">
	<div class="alert alert-info">
		<icon name="lock"></icon>
		<div>
			<strong translate>compteService.password.alerte</strong>
			<div [innerHtml]="'compteService.password.description' | translate:{ mail: user.email }"></div>
		</div>
	</div>
		<div class="form-group">
			<label translate>compteService.password.login</label>
			<div><span class="bold" data-test-id="login">{{ user.login }}</span></div>
		</div>
		<div class="form-group">
			<label translate>compteService.password.password</label>
			<div><span class="bold" data-test-id="password">{{ password }}</span></div>
		</div>
	<div class="row m-t-20 p-t-20">
		<div class="col-xs-12 text-center">
			(<span class="italic" translate>compteService.password.regeneration</span>)
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>