import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { MailHistoryListItemComponent } from './mail-history-list-item.component';

@Component({
	templateUrl: './mail-history.component.html'
})
export class MailHistoryComponent implements OnInit {
	/** Nom de la classe **/
	className: string;

	/** Identifiant de l'objet **/
	idObject: number;

	/** Identifiant secondaire de l'objet **/
	idSecondaryObject?: number;

	/** Données **/
	liste: ListView<any,MailHistoryListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,MailHistoryListItemComponent>({
			uri: `${environment.baseUrl}/controller/MailHistory/filtreHistory?className=${this.className}&idObjet=${this.idObject}`,
			component: MailHistoryListItemComponent,
			isLocal: true,
			defaultOrder: '-dateEnvoi,libelle',
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'nomUser',
				isDefault: true
			},{
				clef: 'prenomUser',
				isDefault: true
			},{
				clef: 'matriculeUser',
				isDefault: true
			}]
		});
	}
}