<fieldset [disabled]="!(null | right:'creation')">
	<div class="form-group" [ngClass]="{ 'has-error': entityField.invalid }">
		<label translate>regleAutomatique.entity</label>
		<div>
			<selectpicker name="entity" #entityField="ngModel" [(ngModel)]="regleAutomatique.typeComputedField.entity" (ngModelChange)="onEntityChange($event)" required>
				<mat-option *ngFor="let entity of listeEntities" [value]="entity.code">{{ entity.libelle }}</mat-option>
			</selectpicker>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
		<label translate>regleAutomatique.champ</label>
		<div>
			<selectpicker name="type" #typeField="ngModel" [(ngModel)]="regleAutomatique.type" (ngModelChange)="onTypeFieldChange($event)" [disabled]="!regleAutomatique?.typeComputedField?.entity" required>
				<mat-option *ngFor="let type of listeFields" [value]="type.code">{{ type.libelle }}</mat-option>
			</selectpicker>
		</div>
	</div>
	<div class="form-group" [ngClass]="{ 'has-error': dateApplication.invalid }">
		<label translate>regleAutomatique.dateApplication</label>
		<div class="half">
			<input type="text" class="form-control" name="dateApplication" #dateApplication="ngModel" [(ngModel)]="regleAutomatique.dateApplication" datePicker format="date" required/>
		</div>
	</div>
</fieldset>