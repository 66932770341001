import { Directive,ElementRef,OnInit,Renderer2,ViewContainerRef } from '@angular/core';

import { PersonalDataComponent } from './personal-data.component';

@Directive({
	selector: '[personalData]'
})
export class PersonalDataDirective implements OnInit {
	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private renderer: Renderer2,private viewContainerRef: ViewContainerRef) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Ajout de l'élément
		this.renderer.insertBefore(this.elementRef.nativeElement,this.viewContainerRef.createComponent(PersonalDataComponent).location.nativeElement,this.elementRef.nativeElement.firstChild);
	}
}
