import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { User } from 'src/app/domain/user/user';
import { DemandeVehiculeBeneficiaireComponent } from './demande-vehicule-beneficiaire.component';
import { DemandeVehiculeService } from './demande-vehicule.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';

@Component({
	selector: 'demande-vehicule-edit',
	templateUrl: './demande-vehicule-edit.component.html'
})
export class DemandeVehiculeEditComponent extends PageContentItem implements OnInit {
	/** Demande de véhicule courante **/
	@Input() demandeVehicule: any;

	/** Mode 'Validation' **/
	@Input() isValidationMode: boolean;

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Suppression de la demande de véhicule **/
	@Input() deleteDemandeVehicule: Function;

	/** Vérification de la validité de l'action Workflow **/
	@Input() isActionValid: Function;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Liste des types d'affectation **/
	listeTypesAffectation: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,public rightService: RightService,private affectationService: AffectationService,private bsModalService: BsModalService,private demandeVehiculeService: DemandeVehiculeService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types d'affectation
		this.listeTypesAffectation = this.affectationService.getListeTypesAffectation().filter(a => !['VEHICULE_IMMOBILISE','VEHICULE_POOL'].includes(a.code));

		//Vérification de la création sans bénéficiaire par défaut
		if (!this.demandeVehicule.idDemandeVehicule && this.rightService.hasRight(null,'creation') && this.demandeVehicule.user == null && this.demandeVehicule.societe == null)
			//Affichage de la pop-up de sélection du bénéficiaire
			this.showSelectionBeneficiaire();
	}

	/**
	 * Affichage de la sélection du bénéficiaire
	 */
	showSelectionBeneficiaire() {
		let bsModalRef: BsModalRef<DemandeVehiculeBeneficiaireComponent>;

		//Affichage de la popup de sélection du bénéficiaire
		bsModalRef = this.bsModalService.show(DemandeVehiculeBeneficiaireComponent);

		//Interception de la fermeture
		bsModalRef.onHidden.pipe(
			filter(() => bsModalRef.content.beneficiaire.isUpdated),
			map(() => bsModalRef.content.beneficiaire)
		).subscribe({
			next: beneficiaire => {
				//Mise à jour de la demande
				this.demandeVehicule.typeBeneficiaire = beneficiaire.source;
				this.demandeVehicule.user = beneficiaire.user;
				this.demandeVehicule.societe = beneficiaire.societe;
				this.demandeVehicule.service = beneficiaire.service;

				//Mise à jour de l'imputation
				this.demandeVehicule.societeImputation = beneficiaire.user?.societe || beneficiaire.societe;
				this.demandeVehicule.serviceImputation = beneficiaire.user?.service || beneficiaire.service;

				//Vérification de la sélection d'un service
				if (!beneficiaire.user)
					//Désactivation de la sélection du bénéficiaire
					this.demandeVehicule.selectionBeneficiaire = false;
			}
		});
	}

	/**
	 * Affichage du véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.demandeVehicule.vehiculeSource.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Enregistrement de la demande de véhicule
	 */
	saveDemandeVehicule() {
		//Enregistrement de la demande de véhicule
		this.demandeVehiculeService.saveDemandeVehicule(this.demandeVehicule).pipe(first()).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de l'édition
					this.close({ demandeVehicule: result.data.demandeVehicule,isGrilleActiveAvailable: result.data?.isGrilleActiveAvailable });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}