import { Component,Input,OnInit,Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { environment } from 'src/environments/environment';
import { DemandeVehiculeModeleSelectionComponent } from './demande-vehicule-modele-selection.component';

@Component({
	selector: 'demande-vehicule-modele-selection-grille-attribution',
	templateUrl: './demande-vehicule-modele-selection-grille-attribution.component.html',
})
export class DemandeVehiculeModeleSelectionGrilleAttributionComponent implements OnInit {
	/** Nom de la classe **/
	@Input() className: string;

	/** Identifiant de l'objet **/
	@Input() idObject: number;

	/** Grille d'attribution selectionnée **/
	@Input() selectedGrilleAttribution: any;

	/** Présence d'une grille d'attribution spécifique **/
	@Input() grilleAttributionSpecifique: any;

	/** Sélection de la grille d'attribution **/
	@Input() @Output() onGrilleAttributionSelected: (grilleAttribution) => void;

	/** Indicateur de disponibilité de grille d'attribution pour le bénéficiaire **/
	@Input() @Output() onSearchRefresh: (isGrilleAvailableForBeneficiaire) => void;

	/** Popup courante **/
	@Input() bsModalRef: BsModalRef<DemandeVehiculeModeleSelectionComponent>;

	/** Données **/
	liste: ListView<any,null>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,any>({
			uri: () => `${environment.baseUrl}/controller/Vehicule/listeGrillesAttributionFor/${this.className}/${this.idObject}`,
			component: null,
			isLocal: true,
			isContentHidden: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			defaultOrder: 'isSpecifique,libelle',
			onRefresh: (liste) => {
				let isGrilleAvailableForBeneficiaire: boolean = true;

				//Vérification de la présence d'une grille d'attribution spécifique
				if (this.grilleAttributionSpecifique)
					//Ajout de la grille d'attribution spécifique au contenu de la liste
					liste.data.content.unshift(this.grilleAttributionSpecifique);

				//Vérification de la liste
				if (liste.listeSelectedFilters?.length === 0 && liste.data?.content?.length === 0)
					//Changement de l'indicateur
					isGrilleAvailableForBeneficiaire = false

				//Notification
				this.onSearchRefresh(isGrilleAvailableForBeneficiaire);
			}
		});

		//Vérification de la présence d'une grille d'attribution
		if (this.selectedGrilleAttribution) {
			//Définition de la grille d'attribution dans la liste
			this.liste.data = {
				content: [this.selectedGrilleAttribution]
			}
		}
	}

	/**
	 * Sélection de la grille d'attribution
	 */
	selectGrilleAttribution(grilleAttribution: any) {
		//Définition de la grille d'attribution sélectionnée
		this.selectedGrilleAttribution = grilleAttribution;

		//Notification de la sélection de la grille d'attribution
		this.onGrilleAttributionSelected(this.selectedGrilleAttribution);
	}

	/**
	 * Redirection vers la grille d'attribution
	 */
	public goToGrilleAttribution(grilleAttribution: any) {
		//Navigation vers la grille d'attribution
		this.layoutService.goToByState('listeGrillesAttribution-grilleAttribution',{
			routeParams: {
				idGrilleAttribution: grilleAttribution.idGrilleAttribution
			},
			withGoBack: true
		});

		//Fermeture de la pop-up
		this.bsModalRef.hide();
	}
}
