import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { GlobalSearchComponent } from './global-search.component';
import { GlobalSearchService } from './global-search.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule,PleaseWaitModule],
	declarations: [GlobalSearchComponent],
	providers: [GlobalSearchService,LayoutService],
	exports: [GlobalSearchComponent]
})
export class GlobalSearchModule {}