import { TranslateService } from '@ngx-translate/core';

import { Filter, TypeComparaison, TypeFilter } from 'src/app/domain/common/list-view';
import { TypeDroit } from 'src/app/domain/security/right';
import { AutocompleteOptions,SearchItemField } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des extractions
 */
export default class ExtractionOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.extraction';

	/**
	 * Récupération de l'URL
	 */
	url() {
		//URL
		return `/controller/Extraction/listeExtractionsActives`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item ? `${item.libelle} (${item.reference})` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.reference?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.extraction.Extraction';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idExtraction'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'reference',
			isDefault: true
		}];
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(filter?: { listeTypesDroit?: Array<TypeDroit> }): Array<Filter> {
		//Retour des filtres statiques
		return [filter?.listeTypesDroit?.length && {
			clef: 'typeDroit',
			listeObjects: filter.listeTypesDroit,
			typeComparaison: TypeComparaison.IN,
			type: TypeFilter.STRING
		}].filter(f => !!f);
	}

	/**
	 * Liste des champs supplémentaires pour la recherche avancée
	 */
	listeSearchItemFields(): Array<SearchItemField> {
		//Retour de la liste des champs
		return [{
			title: 'extraction.entity',
			getValue: (item: any,translateService: TranslateService) => translateService.instant('businessData.'+item.entity.split('.').pop(-1))
		}];
	}
}