import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DemandeVehiculeService } from './demande-vehicule.service';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { ConfigurationService } from 'src/app/components/vehicule/modele/configuration/configuration.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './demande-vehicule-modele-list-item.component.html'
})
export class DemandeVehiculeModeleListItemComponent extends ListViewItem<any> {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private demandeVehiculeService: DemandeVehiculeService,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private modeleService: ModeleService,private configurationService: ConfigurationService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteModele = this.deleteModele.bind(this);
	}

	/**
	 * Suppression d'un modèle
	 */
	deleteModele(close?: Function) {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Retrait du modèle et de la grille d'attribution
					this.extraOptions.demandeVehicule.modele = null;
					this.extraOptions.demandeVehicule.grilleAttribution = null

					//Enregistrement de la demande de véhicule
					this.demandeVehiculeService.saveDemandeVehicule(this.extraOptions.demandeVehicule).pipe(first()).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

								//Suppression de l'objet
								this.onObjectChange(null);

								//Mise à jour de la demande de véhicule
								this.extraOptions.updateDemandeVehicule(result.data.demandeVehicule);

								//Fermeture du formulaire d'édition
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
							}
						}
					});

				}
			}
		});
	}

	/**
	 * Consultation d'un modèle
	 */
	public showModeleInformation(modele: any) {
		let canConfigure: boolean;

		//Définition de l'option de configuration
		canConfigure = this.extraOptions.user.type != 'VIRTUAL';

		//Affichage des informations détaillées du modèle
		this.modeleService.showModeleInformations(modele,{
			canConfigure
		}).subscribe({
			next: ({ action }: { action,modele: any }) => {
				//Vérification de l'action effectuée
					if (action == 'CONFIGURE_MODELE') {
					//Ouverture de la pop-up de configuration
					this.configurationService.showConfiguration({
						type: 'DEMANDE_VEHICULE',
						idEntite: this.extraOptions.demandeVehicule.idDemandeVehicule,
						idModele: this.extraOptions.demandeVehicule.modele.idModele,
						isEdition: this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'creation') && ['LIVRE','ANNULEE'].indexOf(this.extraOptions.demandeVehicule.statut) == -1 && !this.extraOptions.isValidationMode
					}).subscribe({
						next: (configuration: any) => {
							//Mise à jour de la configuration
							this.data.configuration = configuration;
						}
					});
				}
			}
		});
	}

	/**
	 * Calcul du montant d'une configuration de modèle
	 */
	computeMontantForModele() {
		//Retour du prix formatté
		return this.data.information?.commercialisationPrix ? (this.data.information?.genre?.modeAffichagePrix == 'HT' ? (this.data.configuration?.prixHTRemise ? this.data.configuration?.prixHTRemise : this.data.configuration?.prixHT) : (this.data.configuration?.prixTTCRemise ? this.data.configuration?.prixTTCRemise : this.data.configuration?.prixTTC)) : '';
	}
}