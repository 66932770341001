import { Component,Input } from '@angular/core';

@Component({
	templateUrl: './import-resume.component.html'
})
export class ImportResumeComponent {
	/** Fournisseur sélectionné **/
	@Input() selectedFournisseur: any;

	/** Connecteur sélectionné **/
	@Input() selectedConnecteur: any;

	/** Vérification de la disponibilité du connecteur sélectionné **/
	@Input() selectedConnecteurAvailability: { isAvailable: boolean };
}