import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,differenceBy,intersectionBy,pickBy } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first,map } from 'rxjs/operators';

import { DataCustomizationFieldSelectionComponent } from './data-customization-field-selection.component';
import { DataCustomizationService } from './data-customization.service';

@Component({
	templateUrl: './data-customization-field-list.component.html'
})
export class DataCustomizationFieldListComponent implements OnInit {
	/** Paramétrage des champs personnalisés **/
	dataCustomization: any;

	/** Mise à jour du paramétrage des champs personnalisés **/
	savedDataCustomization: any;

	/** Liste des champs **/
	listeFields: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<DataCustomizationFieldListComponent>,private dataCustomizationService: DataCustomizationService,private toastrService: ToastrService,private translateService: TranslateService,private bsModalService: BsModalService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la liste des champs
		if (!this.listeFields?.length)
			//Affichage de la popup de sélection des champs
			this.showListeFields();
	}

	/**
	 * Déplacement d'un champ dans la liste
	 */
	moveField(index: number,incIndex: number) {
		let nextItem;

		//Récupération de l'élément suivant
		nextItem = this.listeFields[index + incIndex];

		//Echange avec l'élément suivant
		this.listeFields[index + incIndex] = this.listeFields[index];
		this.listeFields[index] = nextItem;

		//Rafraichissement du composant de tri
		this.listeFields = [...this.listeFields];
	}

	/**
	 * Suppression d'un champ de la liste
	 */
	deleteField(index: number) {
		//Suppression du champ de la liste
		this.listeFields.splice(index,1);

		//Rafraichissement du composant de tri
		this.listeFields = [...this.listeFields];
	}

	/**
	 * Affichage de la liste des champs disponibles
	 */
	showListeFields() {
		let bsModalRef: BsModalRef<DataCustomizationFieldSelectionComponent>;

		//Affichage de la popup de sélection des champs
		bsModalRef = this.bsModalService.show(DataCustomizationFieldSelectionComponent,{
			initialState: {
				dataCustomization: this.dataCustomization,
				listeFields: cloneDeep(this.listeFields)
			}
		});

		//Interception de la fermeture de la popup
		bsModalRef.onHidden.pipe(map(() => bsModalRef.content.savedListeFields)).subscribe({
			next: listeFields => {
				//Vérification de la sélection
				if (listeFields)
					//Mise à jour de la liste des champs
					this.listeFields = intersectionBy(this.listeFields,listeFields,'name').concat(differenceBy(listeFields,this.listeFields,'name'));
			}
		});
	}

	/**
	 * Enregistrement des champs personnalisés
	 */
	saveDataCustomization() {
		let positionKeys;

		//Parcours de la liste des champs
		this.listeFields.forEach((field,index) => this.dataCustomization[field.positionKey] = index + 1);

		//Récupération des clés du paramétrage associées à une position
		positionKeys = Object.keys(pickBy(this.dataCustomization,(value,key) => key.slice(0,8) == 'position'));

		//Parcours de la liste des positions du paramétrage
		positionKeys.forEach(key => {
			//Vérification de l'absence du champ associé dans la liste ordonnée
			if (!this.listeFields.some(field => field.positionKey == key))
				//Indication que le champ est masqué
				this.dataCustomization[key] = null;
		});

		//Enregistrement des donnés
		this.dataCustomizationService.saveDataCustomization(this.dataCustomization).pipe(first()).subscribe({
			next: dataCustomization => {
				//Vérification de la personnalisation
				if (dataCustomization) {
					//Mise à jour des champs personnalisés
					this.savedDataCustomization = dataCustomization;

					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}