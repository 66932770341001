<div class="tile-toggle" *ngIf="data.idVehicule">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
		<div class="lv-title" (click)="isSelected = !isSelected; doSelectItem();">
			<a [routerLink]="[]">{{ data.reference + (data.numeroInterne ? ' - ' + data.numeroInterne : '') + (data.modele ? ' (' + data.modele?.marque?.libelle + ' ' +data.modele?.libelle : ' (' + ('vehicule.modele.nonDefini' | translate)) + ')' }}</a>
		</div>
		<span class="pull-right">
			<mat-checkbox [(ngModel)]="isSelected" (change)="doSelectItem()"></mat-checkbox>
		</span>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.societe"><span translate>vehicule.societe</span>&#160;:&#160;<span>{{ data.societe.libelle }}</span></li>
		<li *ngIf="data.service"><span translate>vehicule.service</span>&#160;:&#160;<span>{{ data.service.libelle }}</span></li>
	</ul>
</div>