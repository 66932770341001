import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { pick } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { UserSchedulerService } from 'src/app/share/components/user-scheduler/user-scheduler.service';

@Component({
	selector: 'extraction-diffusion-content',
	templateUrl: './extraction-diffusion-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ExtractionDiffusionContentComponent implements OnInit,AfterContentChecked {
	/** Source **/
	@Input() source: 'EXTRACTION' | 'PROFIL' | 'USER';

	/** Extraction courante **/
	@Input() extraction: any;

	/** Profil courant **/
	@Input() profil: any;

	/** Utilisateur courant **/
	@Input() user: any;

	/** Diffusion courante **/
	@Input() userScheduler: any;

	/** Informations de l'entité **/
	@Input() entiteInformations: any;

	/** Suppression de la diffusion courante **/
	@Input() deleteUserScheduler: Function;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Liste des types de notification **/
	listeTypesNotification: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private notificationService: NotificationService,private userSchedulerService: UserSchedulerService,private toastrService: ToastrService,private translateService: TranslateService,private changeDetectorRef: ChangeDetectorRef) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des types de notification
		this.listeTypesNotification = this.notificationService.getListeTypesNotification();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la diffusion
	 */
	public saveUserScheduler() {
		//Vérification de la source
		if (this.source == 'EXTRACTION') {
			//Mise à jour de l'extraction sur la diffusion
			this.userScheduler.entityObject = this.extraction;
		} else if (this.source == 'PROFIL') {
			//Définition du profil
			this.userScheduler.profil = this.profil;
			this.userScheduler.typeNotification = 'PROFIL';
		} else if (this.source == 'USER') {
			//Définition de l'utilisateur
			this.userScheduler.user = this.user;
			this.userScheduler.typeNotification = 'USER';
		}

		//Définition du type
		this.userScheduler.entityObject._type = 'com.notilus.data.extraction.Extraction';

		//Mise à jour de l'extraction
		this.userScheduler.entityObject = pick(this.userScheduler.entityObject,['idExtraction','_type','libelle','reference']);

		//Vérification du type de notification
		if (this.userScheduler.typeNotification == 'USER') {
			//Remise à zéro des données
			this.userScheduler.profil = null;
			this.userScheduler.email = null;
		} else if (this.userScheduler.typeNotification == 'PROFIL') {
			//Remise à zéro des données
			this.userScheduler.user = null;
			this.userScheduler.email = null;
			this.userScheduler.restrictionProfil = false;
		} else if (this.userScheduler.typeNotification == 'EMAIL') {
			//Remise à zéro des données
			this.userScheduler.profil = null;
			this.userScheduler.user = null;
			this.userScheduler.restrictionProfil = false;
		}

		//Enregistrement de la diffusion
		this.userSchedulerService.saveUserScheduler(this.userScheduler).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture du formulaire
					this.close.emit(result.data.userScheduler);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du profil
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.profil'));

					//Vérification de l'utilisateur
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.user'));

					//Vérification de l'e-mail
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.email'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Récupération de la liste des types de droit
	 */
	public getListeTypesDroit(): Array<TypeDroit> {
		//Retour de la liste des types de droit du profil
		return this.profil?.listeDroits?.map(d => d.typeDroit) || [];
	}
}