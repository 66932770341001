import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { SgiLotListItemComponent } from './sgi-lot-list-item.component';
import { SgiLotListComponent } from './sgi-lot-list.component';
import { SgiLotService } from './sgi-lot.service';
import { SgiLotComponent } from './sgi-lot.component';

@NgModule({
	imports: [ShareModule],
	declarations: [SgiLotListComponent,SgiLotListItemComponent,SgiLotComponent],
	providers: [SgiLotService]
})
export class SgiModule { }