'use strict';

var inherits = require('inherits'),
  AjaxBasedTransport = require('./lib/ajax-based'),
  XhrReceiver = require('./receiver/xhr'),
  XHRCorsObject = require('./sender/xhr-cors'),
  XHRLocalObject = require('./sender/xhr-local');
function XhrPollingTransport(transUrl) {
  if (!XHRLocalObject.enabled && !XHRCorsObject.enabled) {
    throw new Error('Transport created when disabled');
  }
  AjaxBasedTransport.call(this, transUrl, '/xhr', XhrReceiver, XHRCorsObject);
}
inherits(XhrPollingTransport, AjaxBasedTransport);
XhrPollingTransport.enabled = function (info) {
  if (info.nullOrigin) {
    return false;
  }
  if (XHRLocalObject.enabled && info.sameOrigin) {
    return true;
  }
  return XHRCorsObject.enabled;
};
XhrPollingTransport.transportName = 'xhr-polling';
XhrPollingTransport.roundTrips = 2; // preflight, ajax

module.exports = XhrPollingTransport;