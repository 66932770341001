<form #structureExportLineForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="alert alert-danger" *ngIf="!ligne.valid">
		<icon name="warning" class="icon-hc-2x pull-left"></icon>
		<div class="p-l-10 pull-left">
			<strong translate>structureExport.ligne.anomalie.detail</strong>
			<p><span translate>structureExport.ligne.anomalie.description</span></p>
		</div>
		<div class="clearfix"></div>
	</div>

	<div class="card detail">
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || isRestricted">
				<h4 class="card-title" [translate]="'structureExport.typeLigne.'+ligne.typeLigne"></h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>structureExport.ligne.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="ligne.libelle" required/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="showImportRubriques()" *ngIf="fichierDetail.fichier?.typeFormat == 'CSV' && (null | right:'creation') && !isRestricted">
			<span translate>actions.importer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveLigne();" *ngIf="(null | right:'creation') && !isRestricted" [disabled]="structureExportLineForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: structureExportLineForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>

<div class="p-t-20 card detail" *ngIf="ligne.idLigne && listeRubriques">
	<list-view [liste]="listeRubriques"></list-view>
</div>