import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ProfilExtractionListItemComponent } from './profil-extraction-list-item.component';

@Component({
	templateUrl: './profil-extraction-list.component.html'
})
export class ProfilExtractionListComponent extends PageContentItem implements OnInit {
	/** Profil courant **/
	@Input() profil: any;

	/** Affichage d'un ordonnancement **/
	@Input() addUserScheduler: Function;

	/** Résumé **/
	@Input() resume: { nbExtractions: number };

	/** Données **/
	liste: ListView<any,ProfilExtractionListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ProfilExtractionListItemComponent>({
			uri: () => `/controller/UserScheduler/filtreUserSchedulers/com.notilus.data.extraction.Extraction`,
			title: this.translateService.instant('extraction.diffusion.liste.title'),
			component: ProfilExtractionListItemComponent,
			isLocal: true,
			extraOptions: {
				addUserScheduler: this.addUserScheduler,
				profil: this.profil
			},
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'profil.idProfil',
				type: TypeFilter.LONG,
				valeur: this.profil.idProfil
			}],
			defaultOrder: 'libelle'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idUserScheduler > 0)
			//Mise à jour des compteurs
			this.resume.nbExtractions--;
	}
}