import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { ImmatriculationModeleCatalogueListItemComponent } from './immatriculation-modele-catalogue-list-item.component';

@Component({
	templateUrl: './immatriculation-modele-catalogue-list.component.html'
})
export class ImmatriculationModeleCatalogueListComponent implements OnInit {
	/** Identifiant de l'immatriculation **/
	idImmatriculation: number;

	/** Données **/
	liste: ListView<any,ImmatriculationModeleCatalogueListItemComponent>;

	/** Modèle sélectionné **/
	selectedModele: any = null;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImmatriculationModeleCatalogueListComponent>,private translateService: TranslateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ImmatriculationModeleCatalogueListItemComponent>({
			uri: `${environment.baseUrl}/controller/Vehicule/filtreImmatriculationModele/${this.idImmatriculation}`,
			component: ImmatriculationModeleCatalogueListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'modeleCatalogue.libelle',
				title: this.translateService.instant('search.libelle'),
				isDefault: true
			},{
				clef: 'modeleCatalogue.reference',
				title: this.translateService.instant('search.reference'),
				isDefault: true
			}],
			extraOptions: {
				onModeleSelected: (modele: any) => {
					//Définition du modèle sélectionné
					this.selectedModele = modele;

					//Fermeture de la popup
					this.bsModalRef.hide();
				}
			},
			defaultOrder: 'score,modeleCatalogue.libelle'
		});
	}
}