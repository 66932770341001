<div class="tile-toggle" *ngIf="data.idVehicule">
	<div class="tile-inner">
		<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
		<div class="lv-title">
			<span>{{ data.reference + (data.vehicule?.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + (data.modele ? ' (' + data.modele?.marque?.libelle + ' ' +data.modele?.libelle : ' (' + ('vehicule.modele.nonDefini' | translate)) + ')' }}</span>
		</div>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.societe"><span translate>vehicule.societe</span>&#160;:&#160;<span>{{ data.societe.libelle }}</span></li>
		<li *ngIf="data.service"><span translate>vehicule.service</span>&#160;:&#160;<span>{{ data.service.libelle }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(null | right:'creation')">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="removeVehicule();"><span translate>actions.dissocier</span></a></li>
		</ul>
	</div>
</div>