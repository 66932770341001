<div class="card hoverable m-b-0 chart">
	<div class="card-header p-0">
		<div>
			<div class="m-r-10 m-t-5 m-l-10">
				<a [routerLink]="[]" (click)="options.goBack()" [hidden]="!options.goBack"><icon name="arrow_back" class="icon-hc-2x"></icon></a>
			</div>
			<h4 class="card-title" *ngIf="!options.isModal">
				<span [title]="options.title">{{ options.title }}</span>
			</h4>
			<ng-container *ngIf="!options.isModal && !options.readOnly && !hasNoActions && getListeVisibleActions() as listeVisibleActions">
				<div class="actions hovered-item clickable dropdown m-t-5" *ngIf="listeVisibleActions.length">
					<a [routerLink]="" data-toggle="dropdown"><icon name="more_vert"></icon></a>
					<ul class="dropdown-menu dropdown-menu-right">
						<li *ngFor="let action of listeVisibleActions; trackBy: trackByPosition" (click)="action.doAction();"><a [routerLink]="">{{ action.libelle }}</a></li>
					</ul>
				</div>
				<div class="actions hovered-item clickable m-t-5">
					<a [routerLink]="" (click)="maximizeChart();"><icon name="fullscreen" class="icon-hc-2x"></icon></a>
				</div>
			</ng-container>
		</div>
		<div class="indicators" *ngIf="!options.readOnly && !options.isModal">
			<icon name="format_list_bulleted" class="nc-secondary" [title]="'dashboard.chart.indicateur.accesData' | translate" *ngIf="options.hasAccesData && !options.hasInterGraphiques"></icon>
			<icon name="insert_chart" class="nc-secondary" [title]="'dashboard.chart.indicateur.interactions' | translate" *ngIf="options.hasInteractions"></icon>
			<icon name="dashboard" class="nc-primary" [title]="'dashboard.chart.indicateur.interGraphiques' | translate" *ngIf="options.hasInterGraphiques"></icon>
			<icon name="email" class="nc-primary" [title]="'dashboard.chart.indicateur.notification' | translate" *ngIf="options.hasNotifications"></icon>
		</div>
	</div>
	<div class="card-body">
		<kpi *ngIf="data?.content?.length && options.type == 'KPI' && !isHidden" class="kpi" [data]="data" [options]="options" (select)="options.onSelect?.($event)"></kpi>
		<apx-chart #apxChart style="width: 100%;" *ngIf="data?.content?.length && chartOptions" [hidden]="isHidden"
			[chart]="chartOptions.chart" [series]="chartOptions.series" [xaxis]="chartOptions.xAxis" [yaxis]="chartOptions.yAxis" [dataLabels]="chartOptions.dataLabels" [fill]="chartOptions.fill"
			[legend]="chartOptions.legend" [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [markers]="chartOptions?.markers" [grid]="chartOptions?.grid"
			[tooltip]="chartOptions?.tooltip" [labels]="chartOptions?.labels" [plotOptions]="chartOptions.plotOptions" [container]="null" containerClass="hide-ngx-bootstrap-tooltip">
		</apx-chart>
		<div *ngIf="data && !data?.content?.length && !isHidden" class="no-data">
			<div><i class="material-icons-round icon-hc-2x">info_outline</i></div>
			<span translate>dashboard.chart.noData</span>
		</div>

		<div *ngIf="data && chartOptions">
			<div class="navigation left" *ngIf="!data.first" (click)="options.previous?.()">
				<icon name="chevron_left" class="icon-hc-5x"></icon>
			</div>
			<div class="navigation right" *ngIf="!data.last" (click)="options.next?.(data.last)">
				<icon name="chevron_right" class="icon-hc-5x"></icon>
			</div>
		</div>

		<div *ngIf="!data || isHidden" class="no-data">
			<please-wait radius="10"></please-wait>
		</div>
	</div>
</div>