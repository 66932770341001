import { Component } from '@angular/core';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { mapEntites } from './demande-identification.entites';

@Component({
	templateUrl: './demande-identification-list-item.component.html'
})
export class DemandeIdentificationListItemComponent extends ListViewItem<any> implements IListEntity {
	/**
	 * Contructeur
	 */
	constructor(private historyService: HistoryService,public notificationService: NotificationService) {
		//Héritage
		super();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.sgi.DemandeIdentification',this.data.idIdentification);
	}

	/**
	 * Récupération du statut par rapport au code
	 */
	getStatutForCode(): { code: string,icone: string,iconeType?: string } {
		//Retour du statut
		return this.extraOptions.listeStatuts?.find(s => s.code == this.data.statut);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.sgi.DemandeIdentification',this.data.idIdentification,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.DEMANDE_IDENTIFICATION
		});
	}
}