import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RevendeurListItemComponent } from './revendeur-list-item.component';
import { mapEntites } from './revendeur.entites';

@Component({
	templateUrl: './revendeur-list.component.html'
})
export class RevendeurListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,RevendeurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,public extractionService: ExtractionService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,RevendeurListItemComponent>({
			uri: () => `/controller/Revendeur/filtreRevendeurs`,
			title: this.translateService.instant('revendeur.liste.title'),
			component: RevendeurListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'email'
			}],
			getKeyFieldName: () => 'idRevendeur',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idRevendeur) && this.liste.addItem({
					isDisplayed: true,
					actif: true,
					transmissionAccount: true,
					updateCompteService: true,
					authentificationMode: 'LOGIN_MDP',
					theme: {
						actif: false
					}
				});
			}
		});
	}
}