import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,uniqueId } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';
import { RegleTaxeService } from 'src/app/components/fiscalite/regle-taxe/regle-taxe.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { SelectPickerService } from 'src/app/share/components/select-picker/select-picker.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	selector: 'poste-charge-compte-edit',
	templateUrl: './poste-charge-compte-edit.component.html'
})
export class PosteChargeCompteEditComponent extends PageContentItem implements OnInit {
	/** Lien entre le poste de charge et un compte collectif **/
	@Input() lien: any;

	/** Suppression d'un lien **/
	@Input() deleteLienCompteDetail: (compte: any,close: Function) => void;

	/** Type de compte collectif à associer au poste de charge **/
	@Input() source: 'CHARGE' | 'TIERS' | 'TAXE' | 'BANQUE';

	/** Association des pays avec leur règle de récupération de taxe standard pour le poste de charge **/
	@Input() mapReglesTaxeForPays: { [idPays: number]: Array<any> };

	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Plan comptable **/
	public localPlanComptable: any;

	/** Liste des règles de taxe sélectionnables **/
	public listeReglesTaxe: Array<any>;

	/** Règle de récupération de taxe sélectionnée **/
	public selectedRegleTaxe: any;

	/** Détails de la règle de récupération de taxe **/
	public listeRegleTaxeDetails: Array<any>;

	/** Indicateur de doublon sur les dates d'application **/
	public isDateApplicationError: boolean = false;

	/** Règle de taxe standard associée par défaut au poste de charge pour le pays **/
	private regleTaxeStandard: any;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService,private compteService: CompteService,private regleTaxeService: RegleTaxeService,public rightService: RightService,public selectPickerService: SelectPickerService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification du type de compte
		if (this.source == 'TAXE')
			//Recherche des règles de récupération de taxe pour le pays du plan comptable
			this.findListeReglesTaxe();

		//Définition du plan comptable
		this.localPlanComptable = this.lien?.compte?.planComptable;
	}

	/**
	 * Recherche des règles de taxe standard pour le pays du plan comptable
	 */
	public findListeReglesTaxe() {
		let regleTaxe;

		//Remise à zéro des règles de taxe
		this.listeReglesTaxe = null;
		this.regleTaxeStandard = null;

		//Réinitialisation de la règle sélectionnée
		this.selectedRegleTaxe = null;

		//Vérification du compte
		if (this.lien.compte) {
			//Récupération de la règle standard associée au poste de charge pour le pays
			this.regleTaxeStandard = this.mapReglesTaxeForPays?.[this.lien.compte.pays.idPays] || null;

			//Recherche des règles de taxe pour le pays du compte
			this.regleTaxeService.findListeReglesTaxeForPays(this.lien.compte.pays.idPays).subscribe(listeReglesForPays => {
				//Définition de la règle appliquée
				regleTaxe = cloneDeep(this.lien.regleTaxe && this.lien.regleTaxe.pays.idPays == this.lien.compte.pays.idPays ? this.lien.regleTaxe.listeDetails.length > 0 && this.lien.regleTaxe || null : this.regleTaxeStandard);

				//Mise en cycle
				setTimeout(() => {
					//Duplication de la liste des détails
					this.listeRegleTaxeDetails = cloneDeep(regleTaxe?.listeDetails || []);

					//Récupération des règles de taxe standard pour le pays
					this.listeReglesTaxe = cloneDeep(listeReglesForPays);

					//Ajout de la règle 'Aucune'
					this.listeReglesTaxe.splice(0,0,null);

					//Vérification de la présence d'une règle de récupération personnalisée pour le pays
					if (this.lien.regleTaxe?.listeDetails.length > 0 && this.lien.regleTaxe.tenant && this.lien.regleTaxe.pays.idPays == this.lien.compte.pays.idPays)
						//Ajout de la règle personnalisée à la liste
						this.listeReglesTaxe.push(this.lien.regleTaxe);

					//Définition de la règle selectionnée
					this.selectedRegleTaxe = regleTaxe;
				});
			});
		}
	}

	/**
	 * Enregistrement du lien
	 */
	public saveLienCompteDetail() {
		let regleTaxe: any;
		let libelleRegle: string;
		let isRegleStandardSelected: boolean;

		//Vérification du type de compte
		if (this.source == 'TAXE') {
			//Vérification de la validité des détails de la règle de taxe
			this.isDateApplicationError = !this.regleTaxeService.checkDateApplicationGenre(this.selectedRegleTaxe?.type,this.listeRegleTaxeDetails);

			//Vérification de la validité de la règle de taxe
			if (!this.isDateApplicationError) {
				//Récupération de l'indicateur de sélection de la règle standard pour le poste de charge
				isRegleStandardSelected = this.selectedRegleTaxe?.idRegle === this.regleTaxeStandard?.idRegle;

				//Vérification que la liste des détails n'a pas été personnalisée
				if (this.regleTaxeService.compareListesDetails(this.listeRegleTaxeDetails,this.selectedRegleTaxe?.listeDetails) && (this.selectedRegleTaxe || isRegleStandardSelected)) {
					//Application de la règle sélectionnée
					this.lien.regleTaxe = isRegleStandardSelected ? null : this.selectedRegleTaxe;

					//Enregistrement
					this.doSaveLienCompteDetail();
				} else {
					//Récupération du libellé initial à personnaliser
					libelleRegle = this.selectedRegleTaxe ? this.selectedRegleTaxe.libelle : this.regleTaxeStandard.libelle;

					//Définition d'une règle de taxe personnalisée
					regleTaxe = {
						idRegle: this.lien.regleTaxe?.tenant ? this.lien.regleTaxe.idRegle : null,
						libelle: libelleRegle + (libelleRegle?.includes(` (${this.translateService.instant('common.personnalisee')})`) ? '' : ` (${this.translateService.instant('common.personnalisee')})`),
						listeDetails: this.listeRegleTaxeDetails?.map(({ dateApplication,tauxRecuperation,genre }) => ({ dateApplication,tauxRecuperation,genre: genre || null })) || [],
						pays: this.lien.compte.pays,
						type: this.selectedRegleTaxe?.type || 'DATE',
						objectVersion: this.lien.regleTaxe?.tenant ? this.lien.regleTaxe.objectVersion : null
					};

					//Enregistrement de la règle
					this.regleTaxeService.saveRegleTaxe(regleTaxe).subscribe(result => {
						//Vérification de la présence d'une erreur
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Définition de la nouvelle règle personnalisée
							this.lien.regleTaxe = result.data.regleTaxe;

							//Enregistrement du lien
							this.doSaveLienCompteDetail();
						} else
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					});
				}
			}
		} else
			//Enregistrement du lien
			this.doSaveLienCompteDetail();
	}

	/**
	 * Exécution de l'enregistrement du lien
	 */
	private doSaveLienCompteDetail() {
		//Enregistrement du lien
		this.compteService.saveLienCompteDetail(this.lien).subscribe(result => {
			let listeDoublons: Array<string> = [];

			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour du lien
				Object.assign(this.lien,result.data?.lien);

				//Fermeture de la fenêtre
				this.close({ lien: this.lien });
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Ajout du plan comptable
				listeDoublons.push(this.translateService.instant(this.rightService.isRoot() ? 'actions.doublon.pays' : 'actions.doublon.planComptable'));

				//Vérification du type de lien
				if (['NATURE','SOCIETE'].includes(this.lien?.type))
					//Ajout du type de compte
					listeDoublons.push(this.translateService.instant('actions.doublon.typeCompte'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Interception du changement de plan comptable
	 */
	public onPlanComptableChange() {
		//Vérification que le plan comptable sélectionné n'est plus compatible avec celui du compte
		if (this.localPlanComptable?.idPlanComptable != this.lien.compte?.planComptable.idPlanComptable) {
			//Remise à zéro des comptes
			this.lien.compte = null;
			this.lien.compteAuxiliaire = null;
		}
	}

	/**
	 * Interception de la création à la volée d'un compte collectif
	 */
	public onCreationCompte(compte) {
		//Mise à jour du plan comptable
		this.localPlanComptable = compte.planComptable;
	}

	/**
	 * Suppression d'un détail de la règle
	 */
	public deleteRule(index: number) {
		//Suppression de la règle
		this.listeRegleTaxeDetails.splice(index,1);
	}

	/**
	 * Ajout d'un détail à la règle
	 */
	public addRule() {
		//Création d'une nouvelle règle
		this.listeRegleTaxeDetails.unshift({ key: uniqueId() });
	}

	/**
	 * Interception du changement de règle de taxe sélectionnée
	 */
	public onRegleTaxeChange(): void {
		//Duplication de la liste des détails
		this.listeRegleTaxeDetails = cloneDeep(this.selectedRegleTaxe?.listeDetails);
	}
}
