import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash-es';
import moment from 'moment';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ModeleService } from './modele.service';


@Component({
	templateUrl: './modele-option-list-item.component.html'
})
export class ModeleOptionListItemComponent extends ListViewItem<any> implements OnInit {
	/** Modèle **/
	@Input() modele: any;

	/** Date courante **/
	public today: any;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private modeleService: ModeleService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Date courante
		this.today = moment().startOf('day');
	}

	/**
	 * Affichage d'une option
	 */
	public showOption() {
		//Affichage de l'option
		this.modeleService.showOption(this.data).subscribe();
	}

	/**
	 * Récupération de la liste sérialisée des catégories d'une option
	 */
	public getListeCategories() {
		//Retour des libellés des catégories
		return uniq((this.data.listeSubCategories.length ? this.data.listeSubCategories : [{ type: 'ACCESSOIRE' }]).map(subCategorie => {
			//Retour de la traduction de la catégorie
			return this.translateService.instant('vehicule.modele.option.categorie.' + (subCategorie.type || 'ACCESSOIRE').toLowerCase());
		})).join(', ');
	}
}