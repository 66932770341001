<div class="pull-left lv-avatar">{{ data?.societe?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="goToSociete()">{{ data.societe | display:'societe' }}</a>
	</div>
	<div class="lv-actions actions dropdown" *ngIf="data?.idLien && (null | right:'creation') && !extraOptions.restriction.isRestricted">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="deleteLienSociete();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>