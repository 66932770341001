<div class="pull-left lv-avatar">
	<icon [type]="statut.iconeType" [name]="statut.icone"></icon>
</div>
<div class="media-body">
	<div class="lv-title"><a [routerLink]="[]" (click)="showDemandeVehicule()" [translate]="'demandeVehicule.statut.'+data.statut"></a></div>
	<ul class="lv-attrs">
		<li><span translate>demandeVehicule.dateCreation</span>&#160;:&#160;{{ data.dateCreation | date:'shortDate' }}</li>
		<li *ngIf="data.vehicule && ['EN_COMMANDE','LIVRE','ANNULEE'].indexOf(data.statut) != -1"><span translate>demandeVehicule.vehicule.item</span>&#160;:&#160;{{ data.vehicule | display:'vehicule' }}</li>
		<li *ngIf="['EN_COMMANDE','LIVRE','ANNULEE'].indexOf(data.statut) != -1"><span translate>demandeVehicule.referenceCommande.short</span>&#160;:&#160;{{ data.referenceCommande }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.DEMANDE_VEHICULE | right:'suppression') && data.statut != 'LIVRE' && data.statut != 'ANNULEE'">
		<a [routerLink]="[]" (click)="deleteDemandeVehicule()"><icon name="delete"></icon></a>
	</div>
</div>