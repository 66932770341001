import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionEditComponent } from './grille-attribution-edit.component';
import { GrilleAttributionModeleListComponent } from './grille-attribution-modele-list.component';
import { GrilleAttributionModeleService } from './grille-attribution-modele.service';
import { GrilleAttributionSelectionAddModeleComponent } from './grille-attribution-selection-add-modele.component';
import { GrilleAttributionService } from './grille-attribution.service';

@Component({
	selector: 'grille-attribution',
	templateUrl: './grille-attribution.component.html'
})
export class GrilleAttributionComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Grille d'attribution courante **/
	public grilleAttribution: any;

	/** Liste des entités de départ des règles d'une grille d'attribution **/
	public listeEntitesGrilleAttribution: Array<any> = [];

	/** Indicateur d'affichage des anomalies pour les modèles **/
	private isShowAnomaliesForModeles: boolean = false;

	/** Résumé **/
	private resume: { nbModeles: number } = {
		nbModeles: 0
	}

	/** Utilisateur connecté **/
	private user: User;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.GRILLE_ATTRIBUTION;

	/**
	 * Constructeur
	 */
	constructor(private grilleAttributionService: GrilleAttributionService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService
			,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private store: Store<AppState>
			,private attachmentService: AttachmentService,private grilleAttributionModeleService: GrilleAttributionModeleService,private bsModalService: BsModalService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.GrilleAttribution';

	/**
	 * Récupération de l'identifiant de la grille d'attribution à charger
	 */
	getIdObject: () => number = () => this.grilleAttribution?.idGrilleAttribution || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.grilleAttribution;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: (user: User) => {
				//Définition de l'utilisateur connecté
				this.user = user;

				//Récupération de l'identifiant de la grille d'attribution à charger
				this.activatedRoute.params.pipe(first()).subscribe({
					next: params => {
						//Chargement des données
						this.loadData(params.idGrilleAttribution).subscribe({
							complete: () => {
								//Initialisation de la page complexe
								this.options = {
									listeFields: [{
										libelle: 'vehicule.grilleAttribution.libelle',
										key: 'libelle'
									},{
										libelle: 'vehicule.grilleAttribution.reference',
										key: 'reference'
									},{
										libelle: 'vehicule.grilleAttribution.actif.item',
										key: 'actif',
										type: 'BOOLEAN'
									}],
									isFormCustomization: true,
									listeActions: [{
										libelle: 'actions.modifier',
										doAction: () => this.editGrilleAttribution(),
										isVisible: () => this.rightService.hasRight(null,'creation'),
										type: TypeAction.EDITION
									},{
										libelle: 'actions.consulter',
										doAction: () => this.editGrilleAttribution(),
										isVisible: () => !this.rightService.hasRight(null,'creation'),
										type: TypeAction.CONSULTATION
									},{
										libelle: 'actions.supprimer',
										doAction: () => this.deleteGrilleAttribution(),
										isVisible: () => this.rightService.hasRight(null,'suppression')
									},{
										libelle: 'vehicule.grilleAttribution.actions.accederDemandeVehicule',
										doAction: () => this.goToDemandeVehicule(),
										isVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'consultation') && this.grilleAttribution.specifique,
										type: TypeAction.REDIRECTION
									}],
									listeAlertes: [{
										icon: 'directions_car',
										title: this.translateService.instant('vehicule.grilleAttribution.alerte.emptyModele.title'),
										message: this.translateService.instant('vehicule.grilleAttribution.alerte.emptyModele.message'),
										isVisible: () => !this.pageContentService.isOpened() && !this.resume.nbModeles,
										isActionVisible: () => !this.pageContentService.isOpened() && !this.resume.nbModeles && this.rightService.hasRight(null,'creation'),
										doAction: () => this.addModele()
									},{
										icon: 'attach_money',
										title: this.translateService.instant('vehicule.grilleAttribution.alerte.modeleAnomalie.title'),
										message: this.translateService.instant('vehicule.grilleAttribution.alerte.modeleAnomalie.message'),
										isVisible: () => !this.pageContentService.isOpened() && this.grilleAttribution.anomalie,
										isActionVisible: () => !this.pageContentService.isOpened() && this.grilleAttribution.anomalie && this.rightService.hasRight(null,'consultation'),
										doAction: () => {
											//Définition de l'indicateur d'affichage des anomalies
											this.isShowAnomaliesForModeles = true;

											//Affichage de la liste des modèles
											this.complexPage.setSelectedElementByType('MODELE',true);
										}
									}],
									listeElements: [{
										type: 'MODELE',
										libelle: 'vehicule.grilleAttribution.elements.modele',
										component: GrilleAttributionModeleListComponent,
										retrieveComponentData: () => ({
											grilleAttribution: this.grilleAttribution,
											resume: this.resume,
											isShowAnomaliesForModeles: this.isShowAnomaliesForModeles
										}),
										doAction: () => this.addModele(),
										count: () => this.resume.nbModeles,
										libelleAction: this.translateService.instant('vehicule.grilleAttribution.elements.modele.ajouter'),
										isActionVisible: () => this.rightService.hasRight(null,'creation'),
										isVisible: () => this.rightService.hasRight(null,'consultation')
									}]
								};

								//Vérification de l'ouverture des modèles demandée
								if (history.state.isOpenModeleSelection)
									//Ouverture de la sélection des modèles
									this.addModele();
							}
						});
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idGrilleAttribution: number): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de la grille d'attribution
		this.grilleAttributionService.loadGrilleAttribution(idGrilleAttribution).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Ajout de l'entité à la liste
					result.data?.entite && this.listeEntitesGrilleAttribution.push(result.data.entite);

					//Définition de la grille d'attribution
					this.grilleAttribution = result.data?.grilleAttribution;

					//Définition du résumé
					this.resume = {
						nbModeles: result.data?.nbModeles || 0
					}

					//Vérification de l'identifiant de la grille d'attribution
					if (!this.grilleAttribution?.idGrilleAttribution) {
						//Création d'un objet vierge
						this.grilleAttribution = {
							actif: history.state?.specifique || false,
							specifique: history.state?.specifique || false,
							demandeVehicule: history.state?.demandeVehicule || null
						};

						//Edition de la grille d'attribution
						this.editGrilleAttribution();
					}

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);
				}

				//Finalisation du chargement des données
				subject.complete();
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Edition de la grille d'attribution
	 */
	private editGrilleAttribution() {
		//Ouverture du composant d'édition
		this.pageContentService.open(GrilleAttributionEditComponent,{
			data: {
				grilleAttribution: this.grilleAttribution.idGrilleAttribution ? cloneDeep(this.grilleAttribution) : this.grilleAttribution,
				deleteGrilleAttribution: this.deleteGrilleAttribution.bind(this),
				goToDemandeVehicule: this.goToDemandeVehicule.bind(this)
			}
		}).subscribe({
			next: (grilleAttribution: any) => {
				//Vérification des données
				if (grilleAttribution) {
					//Mise à jour de la grille d'attribution
					this.grilleAttribution = Object.assign(this.grilleAttribution,grilleAttribution);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.grilleAttribution);
				}
			}
		});
	}

	/**
	 * Suppression de la grille d'attribution
	 */
	public deleteGrilleAttribution() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.grilleAttributionService.deleteGrilleAttribution(this.grilleAttribution))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout d'un modèle dans la grille d'attribution
	 */
	private addModele() {
		let bsModalRef: BsModalRef<GrilleAttributionSelectionAddModeleComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(GrilleAttributionSelectionAddModeleComponent,{
			class: 'modal-md',
			backdrop: 'static'
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content?.result?.choice),
			filter(choice => !!choice)
		).subscribe({
			next: (choice: 'NOUVEAU_MODELE' | 'RECUPERATION_GRILLE') => {
				//Vérification de la sélection
				if (choice == 'NOUVEAU_MODELE') {
					//Affichage de la recherche avancée pour la sélection d'un modèle
					this.grilleAttributionModeleService.showAdvancedSearch(this.user).subscribe({
						next: selectionItems => {
							//Association des modèles de véhicules à la grille d'attribution
							this.grilleAttributionModeleService.linkModelesToGrilleAttribution(this.grilleAttribution.idGrilleAttribution,selectionItems).subscribe({
								next: (result: Result) => {
									//Mise à jour de la grille d'attribution
									this.updateGrilleAtribution(result);
								}
							});
						}
					});
				} else {
					//Récupération de la liste des modèles associés à d'autres grilles d'attribution
					this.grilleAttributionService.getListeModelesByGrilleAttribution(this.grilleAttribution).subscribe({
						next: selectionItems => {
							//Association des modèles des grilles sélectionnées à la grille d'attribution courante
							this.grilleAttributionModeleService.linkModelesFromGrillesAttributionToAnother(this.grilleAttribution.idGrilleAttribution,selectionItems).subscribe({
								next: (result: Result) => {
									//Mise à jour de la grille d'attribution
									this.updateGrilleAtribution(result);
								}
							});
						}
					});
				}
			}
		});
	}

	/**
	 * Accéder à la demande de véhicule
	 */
	goToDemandeVehicule() {
		//Redirection vers la demande de véhicule
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
			routeParams: {
				idDemandeVehicule: this.grilleAttribution.demandeVehicule?.idDemandeVehicule || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Mise à jour de la grille d'attribution
	 */
	private updateGrilleAtribution(result: Result) {
		//Vérification du code d'erreur
		if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
			//Message d'information
			this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

			//Vérification des données
			if (result.data.grilleAttribution) {
				//Mise à jour de la grille d'attribution
				Object.assign(this.grilleAttribution,result.data.grilleAttribution);

				//Mise à jour du nombre de modèles
				this.resume.nbModeles = result.data.grilleAttribution.nbModeles;

				//Rafraichissement de l'onglet
				this.complexPage.setSelectedElementByType('MODELE',true);
			}
		} else
			//Message d'erreur
			this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
	}
}