import { Component,EventEmitter,Input,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AmenagementService } from './amenagement.service';

@Component({
	selector: 'amenagement-content',
	templateUrl: './amenagement-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class AmenagementContentComponent {
	/** Elément courant **/
	@Input() amenagement: any;

	/** Source **/
	@Input() source: 'AMENAGEMENT' | 'VEHICULE';

	/** Gestion d'une modification **/
	@Output() close = new EventEmitter<any>();

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(private amenagementService: AmenagementService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private layoutService: LayoutService,private rightService: RightService) { }

	/**
	 * Enregistrement de l'aménagement
	 */
	public saveAmenagement() {
		//Enregistrement de la société
		this.amenagementService.saveAmenagement(this.amenagement).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'aménagement
					this.close.emit(result.data.amenagement);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression de l'aménagement
	 */
	public deleteAmenagement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la société
					this.amenagementService.deleteAmenagement(this.amenagement).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la société
								this.close.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule(vehicule: any) {
		//Navigation vers le véhicule
		this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Vérification de la présence du champ pour le type d'aménagement
	 */
	hasTypeAmenagement(typeAmenagement: any,field: 'LIBELLE' | 'FOURNISSEUR' | 'DATE_DEBUT' | 'DATE_FIN') {
		//Vérification de la présence du champ
		return typeAmenagement?.listeFields?.some(f => f.typeField == field && f.actif);
	}
}