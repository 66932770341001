<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Workflow/loadWorkflow',data.idWorkflow]"><span>{{ data?.libelle + ' (' + data?.reference + ')' }}</span></a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>workflow.entite.item</span>&#160;:&#160;<span>{{ entiteService.translateEntityCode(data.entite.split('.').pop(-1)) }}</span></li>
		<li><span translate>workflow.dateApplication</span>&#160;:&#160;{{ data.dateApplication | date:'shortDate' }}</li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="duplicateWorkflow()"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteWorkflow()"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>