import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { UserService } from './user.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './user.entites';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { LoginService } from 'src/app/share/login/login.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

@Component({
	templateUrl: './user-list-item.component.html'
})
export class UserListItemComponent extends ListViewItem<any> implements OnInit,IListEntity {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur courant **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private historyService: HistoryService,private confirmService: ConfirmService,private translateService: TranslateService
			,private toastrService: ToastrService,public notificationService: NotificationService,private layoutService: LayoutService
			,private mailHistoryService: MailHistoryService,private loginService: LoginService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<{ user: User }>(state => ({ user: state.session.user })).pipe(take(1)).subscribe(({ user }) => {
			//Mise à jour de l'utilisateur
			this.user = user;
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.user.User',this.data.idUser);
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.user.User',this.data.idUser);
	}

	/**
	 * Suppression d'un utilisateur
	 */
	deleteUser() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'utilisateur
					this.userService.deleteUser(this.data.idUser).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'utilisateur
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Redirection vers le conducteur
	 */
	goToConducteur(idConducteur: any) {
		//Redirection vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur: idConducteur
			},
			withGoBack: true
		});
	}

	/**
	 * Récupération des profils correspondants au type souhaité
	 */
	getProfilForType(typeProfil: any) {
		//Recherche des profils du type
		return this.data.listeUserProfil?.length && this.data.listeUserProfil.filter(p => p.profil && p.profil.typeProfil == typeProfil) || [];
	}

	/**
	 * Connexion en tant qu'un utilisateur
	 */
	loginAsUser() {
		//Connexion en tant qu'un utilisateur
		this.loginService.loginAsUser(this.data);
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.user.User',this.data.idUser,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.USER
		});
	}
}