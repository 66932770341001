<form #societeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.societe.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
							<label translate>comptabilite.compte.societe</label>
							<div>
								<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="lienSociete.societe" [filter]="{ listeIdsSociete }" required></autocomplete>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" (click)="saveLienSociete();" *ngIf="null | right:'creation'" [disabled]="societeForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: societeForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>