<div class="rule-item hoverable" [ngClass]="{ light: isModeLight }">
	<fieldset [disabled]="!isEdition">
		<div class="field">
			<a [routerLink]="[]" (click)="selectEntity();" [ngClass]="{ 'c-red bold': !detail?.filter?.filter }" *ngIf="isEdition">
				<span *ngIf="!detail?.filter?.filter" translate>rule.selectionnerBusinessData</span>
				<span *ngIf="detail?.filter?.filter">{{ detail.filter.libelle }}</span>
			</a>
			<span *ngIf="detail?.filter?.filter && !isEdition">{{ detail.filter.libelle }}</span>
		</div>

		<div *ngIf="isModeLight && detail.filter?.fieldType?.listeTypesAgregation?.length" class="agregation">
			<div class="form-group">
				<selectpicker name="typeAgregation" [(ngModel)]="detail.filter.typeAgregation" [disabled]="!detail?.filter?.filter || !isEdition" [placeholder]="'rule.agregation.NON_DEFINI' | translate">
					<mat-option [value]=""><span translate>rule.agregation.NON_DEFINI</span></mat-option>
					<mat-option *ngFor="let type of detail.filter?.fieldType?.listeTypesAgregation" [value]="type.code">{{ type.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>

		<div class="operateur" *ngIf="detail.filter">
			<div class="form-group">
				<selectpicker [name]="key + '-operateur'" data-test-id="operateur" [(ngModel)]="detail.filter.operateur" [disabled]="!detail.filter.filter || !isEdition" (ngModelChange)="onOperateurChange()">
					<mat-option *ngFor="let operateur of detail.filter.listeOperateurs" [value]="operateur.code">{{ operateur.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>

		<div class="value" *ngIf="detail.filter?.fieldType">
			<div class="form-group m-0" [ngClass]="{ 'has-error': !detail.filter.listeValues[0].value && detail.filter.listeValues[0].numberValue === undefined && detail.filter.listeValues[0].booleanValue === undefined && detail.filter.listeValues[0].object === undefined }" *ngIf="detail.filter.operateur != 'IS_NULL' && detail.filter.operateur != 'IS_NOT_NULL'">
				<div *ngIf="!isTypeAgregation() && detail.filter.fieldType.type == 'CHAINE'">
					<input type="text" [name]="key" data-test-id="value" class="form-control" [(ngModel)]="detail.filter.listeValues[0].value" required/>
				</div>
				<div class="text-left" *ngIf="!isTypeAgregation() && detail.filter.fieldType.type == 'DATE'">
					<div *ngIf="detail.filter.operateur != 'ROLLING_PERIOD'">
						<temporal-selector [operateur]="detail.filter.operateur" [temporal]="detail.filter.listeValues[0].fonction" [date]="detail.filter.listeValues[0].dateValue" (onTemporalChange)="onTemporalChange($event)" [disabled]="!isEdition" [customClass]="{ 'c-red bold': !(detail.filter.listeValues[0].dateValue || detail.filter.listeValues[0].fonction) }"></temporal-selector>
					</div>
					<div *ngIf="detail.filter.operateur == 'ROLLING_PERIOD'">
						<rolling-period-selector [temporal]="detail.filter.listeValues[1]?.fonction" [value]="detail.filter.listeValues[1]?.numberValue" (onRollingPeriodChange)="onRollingPeriodChange($event)" [disabled]="!isEdition" [customClass]="{ 'c-red bold': !(detail.filter.listeValues[1]?.fonction || detail.filter.listeValues[1]?.numberValue) }"></rolling-period-selector>
					</div>
				</div>
				<div *ngIf="isTypeAgregation() || detail.filter.fieldType.type == 'NUMBER'">
					<input type="text" class="form-control" [name]="key" data-test-id="value" nNumber="2" [(ngModel)]="detail.filter.listeValues[0].numberValue" required/>
				</div>
				<div *ngIf="!isTypeAgregation() && detail.filter.fieldType.type == 'BOOLEAN'">
					<input type="hidden" [name]="key" data-test-id="value" [(ngModel)]="detail.filter.listeValues[0].booleanValue" required/>
					<mat-button-toggle-group [value]="detail.filter.listeValues[0].booleanValue">
						<mat-button-toggle [value]="true" (click)="detail.filter.listeValues[0].booleanValue = true"><span translate>common.oui</span></mat-button-toggle>
						<mat-button-toggle [value]="false" (click)="detail.filter.listeValues[0].booleanValue = false"><span translate>common.non</span></mat-button-toggle>
					</mat-button-toggle-group>
				</div>
				<div *ngIf="!isTypeAgregation() && detail.filter.fieldType.type == 'ENUM'">
					<selectpicker [name]="key" data-test-id="value" [(ngModel)]="detail.filter.listeValues[0].value" [disabled]="!isEdition" required>
						<mat-option *ngFor="let value of detail.filter.fieldType.listeValues" [value]="value"><span [translate]="detail.filter.fieldType.rootKey + '.' + value"></span></mat-option>
					</selectpicker>
				</div>
				<div *ngIf="!isTypeAgregation() && detail.filter.fieldType.type == 'AUTOCOMPLETE' && detail.filter.fieldType.autocomplete">
					<div *ngIf="detail.filter.type != 'java.lang.String'">
						<autocomplete [name]="key" data-test-id="value" [type]="detail.filter.fieldType.autocomplete" [filter]="detail.filter.fieldType.autocompleteFilter" [(ngModel)]="detail.filter.listeValues[0].object" required></autocomplete>
					</div>
					<div *ngIf="detail.filter.type == 'java.lang.String'">
						<autocomplete [name]="key" data-test-id="value" [type]="detail.filter.fieldType.autocomplete" [filter]="detail.filter.fieldType.autocompleteFilter" [(ngModel)]="detail.filter.listeValues[0].value" required></autocomplete>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="detail.filter?.fieldType?.type == 'DATE' && detail.filter.listeValues[0].fonction == 'DATE_WEEKDAY' && detail.filter.operateur != 'IS_NULL' && detail.filter.operateur != 'IS_NOT_NULL'" class="date-day">
			<div class="form-group">
				<selectpicker [name]="key + '-week-day'" data-test-id="week-day" [(ngModel)]="detail.filter.listeValues[0].value" [disabled]="!isEdition" required>
					<mat-option *ngFor="let weekDay of listeWeekDays" [value]="weekDay.code">{{ weekDay.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="action">
			<button mat-icon-button class="hovered-item" *ngIf="isEdition" (click)="removeItem()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</fieldset>
</div>