import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { Session } from 'src/app/domain/security/session';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { PaysService } from './pays.service';

@Component({
	templateUrl: './pays-list-item.component.html'
})
export class PaysListItemComponent extends ListViewItem<any> implements OnInit {
	/** Pays courant **/
	data: any;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private paysService: PaysService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de la session
		this.store?.select<Session>(s => s.session).subscribe(session => {
			//Définition de l'utilisateur
			this.user = session.user;
		});
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idPays,object?.idPays && !this.data?.idPays);
	}

	/**
	 * Suppression de l'objet
	 */
	deletePays() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du pays
					this.paysService.deletePays(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du pays
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject);
	}
}