import { Directive,Input,OnChanges,OnInit,SimpleChanges } from '@angular/core';
import { UntypedFormControl,NgForm } from '@angular/forms';

@Directive({
	selector: '[formValidator]'
})
export class FormValidatorDirective implements OnChanges,OnInit {
	/** Indicateur de validité de l'élément **/
	@Input() formValidator: boolean;

	/** Constante - Nom du contrôle */
	private formValidatorName: string = 'formValidator';

	/**
	 * Constructeur
	 */
	constructor(private ngForm: NgForm) { }

	/**
	 * Initialisation de la directive
	 */
	ngOnInit() {
		//Ajout d'un contrôle
		this.ngForm.form.addControl(this.formValidatorName,new UntypedFormControl());
	}

	/**
	 * Détection des changements
	 */
	ngOnChanges(changes: SimpleChanges) {
		//Vérification de la présence d'un changement
		if (changes?.formValidator && !changes.formValidator?.firstChange) {
			//Mise en cycle
			setTimeout(() => {
				//Vérification de l'indicateur
				if (this.formValidator)
					//Suppression des erreurs
					this.ngForm?.form?.controls?.[this.formValidatorName].setErrors(null);
				else
					//Ajout d'une erreur dans le formulaire
					this.ngForm?.form?.controls?.[this.formValidatorName].setErrors({ [this.formValidatorName]: true });
			});
		}
	}
}