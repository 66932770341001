import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PosteChargeCompteEditComponent } from './poste-charge-compte-edit.component';
import { PosteChargeCompteListItemComponent } from './poste-charge-compte-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [PosteChargeCompteListItemComponent,PosteChargeCompteEditComponent]
})
export class PosteChargeModule { }
