<div class="pull-left lv-avatar">{{ data?.typeAmenagement?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addAmenagement?.(data,deleteAmenagement)">{{ data.typeAmenagement.libelle + ' (' + data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ')' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAmenagement">
		<li *ngIf="data.libelle"><span translate>amenagement.libelle.item</span>&#160;:&#160;<span>{{ data.libelle }}</span></li>
		<li *ngIf="data.fournisseur"><span translate>amenagement.fournisseur.item</span>&#160;:&#160;<span>{{ data.fournisseur.libelle }}</span></li>
		<li *ngIf="data.dateDebut"><span translate>amenagement.dateDebut.item</span>&#160;:&#160;<span>{{ data.dateDebut | date:'shortDate' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>amenagement.dateFin.item</span>&#160;:&#160;<span>{{ data.dateFin | date:'shortDate' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.VEHICULE_AMENAGEMENT | right:'suppression'"><a [routerLink]="[]" (click)="deleteAmenagement();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>