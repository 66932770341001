<tr [ngClass]="{ nodes: isChild }">
	<td [ngClass]="{ node: isChild }" [colSpan]="isChild ? 2 : undefined">
		<table [cellPadding]="0" [cellSpacing]="0">
			<tbody *ngIf="!etape.isHidden && (!etape.listeBranches || etape.listeBranches?.length == 0)">
				<!-- Bouton -->
				<workflow-detail-button [workflow]="workflow" [currentBranche]="currentBranche" [index]="index"></workflow-detail-button>

				<!-- Ligne horizontale -->
				<workflow-detail-line mode="horizontal"></workflow-detail-line>

				<!-- Détail de l'étape -->
				<ng-template [ngTemplateOutlet]="workflowNodeEtape"></ng-template>

				<!-- Ligne horizontale -->
				<workflow-detail-line mode="horizontal"></workflow-detail-line>
			</tbody>
			<tbody *ngIf="!etape.isHidden && etape.listeBranches?.length > 0">
				<!-- Bouton -->
				<workflow-detail-button *ngIf="!etape.readOnly" [workflow]="workflow" [currentBranche]="currentBranche" [index]="index"></workflow-detail-button>

				<!-- Ligne horizontale -->
				<workflow-detail-line mode="horizontal"></workflow-detail-line>

				<!-- Bouton de la branche -->
				<workflow-detail-button *ngIf="!etape.readOnly" [workflow]="workflow" [currentBranche]="currentBranche" mode="branche" [index]="index"></workflow-detail-button>

				<!-- Branche -->
				<workflow-detail-branch [etape]="etape" [workflow]="workflow" [isEdition]="isEdition" [index]="index" (onBrancheDeletion)="currentBranche?.listeEtapes?.splice?.(index,1)"></workflow-detail-branch>

				<!-- Ligne horizontale -->
				<workflow-detail-line mode="horizontal"></workflow-detail-line>
			</tbody>
			<tbody *ngIf="etape.isCollapsed">
				<!-- Ligne horizontale -->
				<workflow-detail-line mode="counter" [listeEtapes]="currentBranche?.listeEtapes" [index]="index"></workflow-detail-line>
			</tbody>
		</table>
	</td>
</tr>

<!-- Détail de l'étape -->
<ng-template #workflowNodeEtape ngModelGroup="workflowDetailStep">
	<tr class="nodes">
		<td class="node">
			<div class="node etape" [ngClass]="{ 'has-error': etapeLibelle.invalid }">
				<div class="content" (mouseenter)="isContentHovered = true" (mouseleave)="isContentHovered = false">
					<div class="title">
						<div class="form-group">
							<div [ngClass]="{ 'input-group': isContentHovered || isDropdownOpened }">
								<input type="text" [name]="'etape_libelle_' + (etape.idEtape || etape.key?.split('-').pop(-1))" #etapeLibelle="ngModel" data-test-id="etapeLibelleTestId" class="form-control" [(ngModel)]="etape.libelle" maxlength="300" [disabled]="!isEdition" required/>
								<div class="input-group-addon no-border" *ngIf="isContentHovered || isDropdownOpened">
									<ul class="actions">
										<li #dropdownMenu (click)="isDropdownOpened = true">
											<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
											<ul class="dropdown-menu dropdown-menu-right">
												<li>
													<a [routerLink]="[]" (click)="showEtapeDefinition()">
														<span [translate]="'actions.'+(isEdition ? 'modifier' : 'consulter')"></span>
													</a>
												</li>
												<li *ngIf="isEdition">
													<a [routerLink]="[]" (click)="deleteEtape()">
														<span translate>actions.supprimer</span>
													</a>
												</li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="extra-content">
						<div class="counter" *ngIf="etape.nbItems > 0" [tooltip]="'workflow.detail.etape.nbItems' | translate">
							<span>{{ etape.nbItems > 99 ? '+99' : etape.nbItems }}</span>
						</div>
					</div>
				</div>
				<div class="collapse-container" (click)="etape.isCollapsed = !etape.isCollapsed; collapseStep()">
					<icon [name]="!etape.isCollapsed ? 'expand_less' : 'expand_more'"></icon>
				</div>
			</div>
		</td>
	</tr>
</ng-template>
