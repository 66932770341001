<form #taxeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compte.elements.taxe.title</h2>
		</div>
		<div class="card-body card-padding">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label translate>comptabilite.compte.taxe.libelle</label>
						<div>
							<mat-chip-listbox>
								<mat-chip>
									<span>{{ taux.libelle }}</span>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
					<div class="form-group">
						<label translate>comptabilite.compte.taxe.taux</label>
						<div>
							<mat-chip-listbox>
								<mat-chip>
									<span>{{ (taux.taux | number:'.2-2') + ' %' }}</span>
								</mat-chip>
							</mat-chip-listbox>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<button mat-button [formDetectChanges]="{ doAction: close }">
		<span translate>actions.fermer</span>
	</button>
</form>