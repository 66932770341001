import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Observable,Subject } from 'rxjs';
import { filter,first,tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { Options } from 'src/app/domain/common/complex-page/options';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { LotComptableService } from './lot-comptable.service';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { CompteService } from '../compte/compte.service';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LotComptableFactureListComponent } from './lot-comptable-facture-list.component';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { IEntity } from 'src/app/domain/entity/entity';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LotComptableEditComponent } from './lot-comptable-edit.component';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';

@Component({
	selector: 'lot-comptable',
	templateUrl: './lot-comptable.component.html'
})
export class LotComptableComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Lot comptable courant **/
	public lotComptable: any;

	/** Sélection courante **/
	public selectionItems: any;

	/** Résumé */
	private resume: { nbFactures: number } = {
		nbFactures: 0
	}

	/** Restriction sur le lot comptable **/
	public restriction: { isRestricted: boolean } = null;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	public typeAttachment: TypeAttachment = TypeAttachment.LOT_COMPTABLE;

	/**
	 * Constructeur
	 */
	constructor(private activatedRoute: ActivatedRoute,private lotComptableService: LotComptableService,private compteService: CompteService,private rightService: RightService,private layoutService: LayoutService,private loggedUserService: LoggedUserService
			,private currencyPipe: CurrencyPipe,private translateService: TranslateService,private attachmentService: AttachmentService,private confirmService: ConfirmService,private pageContentService: PageContentService,private messagingService: MessagingService) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.comptabilite.lot.LotComptable';

	/**
	 * Récupération de l'identifiant du lot comptable à charger
	 */
	getIdObject: () => number = () => this.lotComptable?.idLotComptable || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.lotComptable;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de l'identifiant du lot comptable à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Récupération de la sélection courante
				this.selectionItems = history.state.selectionItems;

				//Chargement des données
				this.loadData(params.idLotComptable).subscribe({
					complete: () => {
						//Initialisation de la page complexe
						this.options = {
							listeFields: [{
								libelle: 'comptabilite.lotComptable.libelle',
								key: 'libelle'
							},{
								libelle: 'comptabilite.lotComptable.numeroLot',
								key: 'numeroLot'
							},{
								libelle: 'comptabilite.lotComptable.dateLot',
								key: 'dateLot',
								type: 'DATE'
							},this.lotComptable?.dateUpdated && {
								libelle: 'comptabilite.lotComptable.dateUpdated',
								key: 'dateUpdated',
								type: 'DATE'
							},this.lotComptable?.montant !== undefined && {
								libelle: 'comptabilite.lotComptable.montantTotalTTC',
								key: (data: any) => this.currencyPipe.transform(data.montant,'.2-2',data.devise)
							}].filter(f => !!f),
							listeActions: [{
								libelle: 'actions.modifier',
								doAction: () => this.editLotComptable(),
								isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
								type: TypeAction.EDITION
							},{
								libelle: 'actions.consulter',
								doAction: () => this.editLotComptable(),
								isVisible: () => !this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
								type: TypeAction.CONSULTATION
							},{
								libelle: 'actions.actualiser',
								doAction: () => this.refreshEcrituresComptables(),
								isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
								type: TypeAction.SUPPRESSION
							},{
								libelle: 'actions.executer',
								doAction: () => this.executeStructureExport(),
								isVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction?.isRestricted,
								type: TypeAction.SUPPRESSION
							},{
								libelle: 'actions.supprimer',
								doAction: () => this.deleteLotComptable(),
								isVisible: () => this.rightService.hasRight(null,'suppression') && !this.restriction?.isRestricted,
								type: TypeAction.SUPPRESSION
							}],
							listeAlertes: [{
								icon: 'info',
								title: this.translateService.instant('comptabilite.lotComptable.alerte.restriction.title'),
								message: this.translateService.instant('comptabilite.lotComptable.alerte.restriction.message'),
								isVisible: () => this.restriction?.isRestricted && !this.pageContentService.isOpened(),
								level: 'INFO'
							}],
							listeElements: [{
								type: 'FACTURE',
								libelle: 'comptabilite.lotComptable.elements.facture',
								component: LotComptableFactureListComponent,
								retrieveComponentData: () => ({
									lotComptable: this.lotComptable,
									resume: this.resume,
									restriction: this.restriction
								}),
								count: () => this.resume.nbFactures,
								isActionVisible: () => this.rightService.hasRight(null,'creation') && !this.restriction.isRestricted,
								isVisible: () => this.rightService.hasRight(null,'consultation'),
								doAction: () => {
									let doCreationAction: (selectionItems: any) => void;

									//Création d'un lot comptable
									doCreationAction = (selectionItems: any) => {
										//Sauvegarde du lot comptable avec ajout des éléments
										this.saveLotComptableAndPopulate(this.lotComptable,selectionItems,true);
									}

									//Sélection des factures à comptabiliser
									this.lotComptableService.showAdvancedSearch(this.loggedUserService.getUser()).subscribe({
										next: selectionItems => {
											//Affichage du contrôles des écritures comptables
											this.lotComptableService.showLotComptableChecks(null,selectionItems,null,false,doCreationAction).subscribe({
												next: result => {
													//Vérification du résultat
													if (result?.selectionItems)
														//Création du lot comptable et peuplement de la sélection
														doCreationAction(result.selectionItems);
												}
											});
										}
									});
								}
							}]
						};
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idLotComptable: number): Observable<Result> {
		//Chargement du lot comptable
		return this.lotComptableService.loadLotComptable(idLotComptable).pipe(
			first(),
			tap((result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du lot comptable
					this.lotComptable = result.data?.lotComptable;

					//Définition du résumé
					this.resume = {
						nbFactures: result.data?.nbFactures || 0
					};

					//Définition de la restriction
					this.restriction = this.compteService.getRestriction(this.lotComptable?.listeSocietes);

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de l'identifiant du lot comptable
					if (!this.lotComptable?.idLotComptable) {
						//Création d'un objet vierge
						this.lotComptable = {
							dateLot: moment.utc().toDate(),
							dateUpdated: moment.utc().toDate(),
							montant: 0
						};

						//Edition du lot comptable
						this.editLotComptable();
					}
				}
			})
		);
	}

	/**
	 * Edition du lot comptable
	 */
	private editLotComptable() {
		//Ouverture du composant d'édition
		this.pageContentService.open(LotComptableEditComponent,{
			data: {
				lotComptable: this.lotComptable,
				deleteLotComptable: this.deleteLotComptable.bind(this),
				saveLotComptableAndPopulate: this.saveLotComptableAndPopulate.bind(this),
				selectionItems: this.selectionItems
			}
		}).subscribe({
			next: ({ lotComptable,nbFactures }) => {
				//Vérification des données
				if (lotComptable) {
					//Mise à jour du lot comptable
					Object.assign(this.lotComptable,lotComptable);

					//Vérification de la présence du nombre de factures
					if (nbFactures)
						//Mise à jour du nombre de factures
						this.resume.nbFactures = nbFactures;

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.lotComptable);
				}
			}
		});
	}

	/**
	 * Enregistrement et peuplement du lot comptable
	 */
	protected saveLotComptableAndPopulate(lotComptable: any,selectionItems: any,isRemovePreviousItems: boolean = false): Subject<any> {
		let lotComptableSubject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Définition du type du lot comptable
		lotComptable._type = 'com.notilus.data.comptabilite.lot.LotComptable';

		//Définition de l'objet de la sélection
		selectionItems.entityObject = lotComptable;

		//Initialisation de la WebSocket
		messaging$ = this.messagingService.init({
			entryPoint: `controller/LotComptable/saveLotComptableAndPopulate?isRemovePreviousItems=${isRemovePreviousItems}`,
			params: selectionItems,
			progressConfig: {
				libelle: this.translateService.instant('comptabilite.lotComptable.generation'),
				icone: 'refresh'
			},
			method: 'PUT'
		}).onFinish({
			next: ({ reference }) => {
				//Vérification du rafraichissement
				if (!isRemovePreviousItems) {
					//Rechargement du lot comptable
					this.lotComptableService.loadLotComptable(parseInt(reference,10)).pipe(
							first(),
							filter(result => result?.data)
						).subscribe({
							next: result => {
								//Poursuite du traitement
								lotComptableSubject.next(result.data);
							}
						});
				} else {
					//Rechargement complet de l'écran
					this.layoutService.reloadSameUrl();
				}
			}
		}).onError({
			next: () => {
				//Annulation de l'abonnement
				messaging$.unsubscribe();
			}
		});

		return lotComptableSubject;
	}

	/**
	 * Suppression du lot comptable
	 */
	private deleteLotComptable() {
		//Suppression du lot comptable
		this.lotComptableService.deleteLotComptable(this.lotComptable.idLotComptable,() => {
			//Retour arrière
			this.layoutService.goBack();
		});
	}

	/**
	 * Exécution des structures d'export
	 */
	private executeStructureExport() {
		//Exécution des structures d'export
		this.lotComptableService.executeStructureExport(this.lotComptable);
	}

	/**
	 * Rafraichissement des écritures comptables
	 */
	private refreshEcrituresComptables() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('comptabilite.lotComptable.actions.actualisationConfirmation'),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed)
		).subscribe({
			next: () => {
				let doCreationAction: (selectionItems: any) => void;

				//Création d'un lot comptable
				doCreationAction = (selectionItems: any) => {
					//Sauvegarde du lot comptable avec ajout des éléments
					this.saveLotComptableAndPopulate(this.lotComptable,selectionItems,true);
				}

				//Affichage du contrôles des écritures comptables
				this.lotComptableService.showLotComptableChecks(null,{
					_type: 'com.notilus.model.search.AggregatedItemSelection',
					searchSpec: {
						listeFilter: [{
							clef: 'lotComptable.idLotComptable',
							valeur: this.lotComptable.idLotComptable,
							typeComparaison: TypeComparaison.EQUAL,
							type: TypeFilter.LONG
						}]
					}
				},null,false,doCreationAction).subscribe({
					next: result => {
						//Vérification du résultat
						if (result?.selectionItems)
							//Mise à jour du lot comptable et peuplement de la sélection
							doCreationAction(result.selectionItems);
					}
				});
			}
		});
	}
}