import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from './compte.service';

@Component({
	selector: 'compte-edit',
	templateUrl: './compte-edit.component.html'
})
export class CompteEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() compte: any;

	/** Suppression de l'objet **/
	@Input() deleteCompte: () => void;

	/** Liste des types de comptes */
	listeTypesComptes: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private compteService: CompteService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types de compte
		this.listeTypesComptes = this.compteService.getListeTypesCompte();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveCompte() {
		//Enregistrement de l'objet
		this.compteService.saveCompte(this.compte).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.compte = Object.assign(this.compte,result.data?.compte);

					//Fermeture du formulaire
					this.close(this.compte);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout de la référence
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}