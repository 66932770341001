<form #societeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!societe.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>societe.libelle.item</label>
							<div class="input-group">
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="societe.libelle" maxlength="40" pattern="^(?![<&]).*" required/>
								<div class="input-group-addon">
									<icon name="info_outline" [tooltip]="'societe.libelle.description' | translate"></icon>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': siren.invalid }">
							<label translate>societe.siren</label>
							<div>
								<input type="text" class="form-control" name="siren" #siren="ngModel" [(ngModel)]="societe.siren" minlength="9" maxlength="9" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': siret.invalid }">
							<label translate>societe.siret</label>
							<div>
								<input type="text" class="form-control" name="siret" #siret="ngModel" [(ngModel)]="societe.siret" minlength="14" maxlength="14" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': !societe.adresse }">
							<label translate>societe.adresse</label>
							<div>
								<adresse name="adresse" #adresse="adresse" [(ngModel)]="societe.adresse" required></adresse>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: societeForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteSociete();" *ngIf="societe.idSociete && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveSociete();" *ngIf="(null | right:'creation')" [disabled]="societeForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>