import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,combineLatest } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit, TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoginService } from 'src/app/share/login/login.service';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { UserEditComponent } from './user-edit.component';
import { UserImputationEditComponent } from './user-imputation-edit.component';
import { UserImputationListComponent } from './user-imputation-list.component';
import { UserProfilEditComponent } from './user-profil-edit.component';
import { UserProfilListComponent } from './user-profil-list.component';
import { UserUserResponsableEditComponent } from './user-user-responsable-edit.component';
import { UserUserResponsableListComponent } from './user-user-responsable-list.component';
import { UserService } from './user.service';
import { UserAutorisationListComponent } from './user-autorisation-list.component';
import { TenantAutorisationEditComponent } from 'src/app/components/tenant/tenant-autorisation-edit.component';
import { UserExtractionEditComponent } from './user-extraction-edit.component';
import { UserExtractionListComponent } from './user-extraction-list.component';

@Component({
	selector: 'user',
	templateUrl: './user.component.html'
})
export class UserComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Utilisateur courant **/
	public user: User | any;

	/** Résumé **/
	private resume: { nbProfils: number,nbImputations: number,nbExtractions: number,nbUsersResponsable: number,nbAutorisations: number } = {
		nbProfils: 0,
		nbImputations: 0,
		nbExtractions: 0,
		nbUsersResponsable: 0,
		nbAutorisations: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.USER;

	/** Utilisateur connecté **/
	private loggedUser: User;

	/** Liste des locales disponibles **/
	private listeLocales: Array<any>;

	/** Liste des profils disponibles **/
	private listeProfils: Array<any>;

	/** Liste des profils disponibles **/
	private listeUserProfil: Array<any>;

	/** Compte utilisateur mobile **/
	private userAccountMobile: any;

	/** Société par défaut **/
	private societeDefaut: any;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private displayPipe: DisplayPipe,private datePipe: DatePipe
			,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private loginService: LoginService,private notificationService: NotificationService,private store: Store<AppState>) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.user.User';

	/**
	 * Récupération de l'identifiant de l'utilisateur à charger
	 */
	getIdObject: () => number = () => this.user?.idUser || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.user;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		combineLatest([this.notificationService.isNotificationAvailableFor('com.notilus.data.user.User')
			,this.store.select<User>(state => state.session?.user)
		]).pipe(first()).subscribe({
			next: ([isNotificationAvailable,loggedUser]) => {
				//Définition de l'utilisateur connecté
				this.loggedUser = loggedUser;

				//Récupération de l'identifiant de l'utilisateur à charger
				this.activatedRoute.params.pipe(first()).subscribe({
					next: params => {
						//Chargement des données
						this.loadData(params.idUser).subscribe({
							complete: () => {
								//Initialisation de la page complexe
								this.options = {
									listeFields: [{
										libelle: 'user.nomPrenom',
										key: (data) => `${data.nom} ${data.prenom}`,
										isPersonalData: true
									},{
										libelle: 'user.matricule',
										key: 'matricule',
										isPersonalData: true
									},{
										libelle: 'user.enabled.item',
										key: 'enabled',
										type: 'BOOLEAN'
									},{
										libelle: 'user.email',
										key: 'email',
										isPersonalData: true
									},this.user?.tenant?.type != 'REVENDEUR' && {
										libelle: 'user.categorie.item',
										key: 'categorie',
										type: 'DISPLAY',
										format: 'userCategorie',
										isSecondary: true
									},this.user?.tenant?.type != 'REVENDEUR' && {
										libelle: 'user.societeAffectation',
										key: 'societe',
										type: 'DISPLAY',
										format: 'societe',
										isSecondary: true
									},this.user?.tenant?.type != 'REVENDEUR' && {
										libelle: 'user.serviceAffectation',
										key: 'service',
										type: 'DISPLAY',
										format: 'service',
										isSecondary: true
									},this.user?.tenant?.type != 'REVENDEUR' && {
										libelle: 'user.telephonePro',
										key: 'userDescription.telephonePro.numero',
										isPersonalData: true,
										isSecondary: true
									},this.user?.tenant?.type != 'REVENDEUR' && {
										libelle: 'user.telephonePerso',
										key: 'userDescription.telephonePerso.numero',
										isPersonalData: true,
										isSecondary: true
									}].filter(f => !!f),
									isFormCustomization: true,
									listeActions: [{
										libelle: 'actions.notifier',
										doAction: () => this.notificationService.showSelectionMailForEntite(this.getClassName(),this.user.idUser,{
											getOwningEntity: () => this.user,
											typeAttachment: TypeAttachment.USER
										}),
										isVisible: () => isNotificationAvailable
									},{
										libelle: 'user.actions.accederConducteur',
										doAction: () => this.goToConducteur(this.user?.idConducteur),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'consultation') && this.user?.idConducteur
									},{
										libelle: 'actions.modifier',
										doAction: () => this.editUser(),
										isVisible: () => this.rightService.hasRight(null,'creation'),
										type: TypeAction.EDITION
									},{
										libelle: 'actions.consulter',
										doAction: () => this.editUser(),
										isVisible: () => !this.rightService.hasRight(null,'creation'),
										type: TypeAction.CONSULTATION
									},{
										libelle: 'actions.supprimer',
										doAction: () => this.deleteUser(),
										isVisible: () => this.rightService.hasRight(null,'suppression')
									},{
										libelle: 'user.actions.seConnecterEnTantQue',
										doAction: () => this.loginService.loginAsUser(this.user),
										isVisible: () => ['ADMINISTRATEUR','UTILISATEUR_AUTO'].includes(this.loggedUser.type) && this.user.listeUserProfil?.length
									}],
									listeAlertes: [{
										icon: 'info',
										level: 'INFO',
										title: this.translateService.instant('user.alerte.anonymisation.title'),
										message: this.translateService.instant('user.alerte.anonymisation.message',{
											user: this.displayPipe.transform(this.user?.userDescription?.userAnonymisation,'user'),
											date: this.datePipe.transform(this.user?.userDescription?.dateAnonymisation,'shortDate')
										}),
										isVisible: () => this.user?.userDescription?.userAnonymisation && this.user?.userDescription?.dateAnonymisation && !this.pageContentService.isOpened()
									},{
										icon: 'people',
										title: this.translateService.instant('user.alerte.profil.title'),
										message: this.translateService.instant('user.alerte.profil.message'),
										isVisible: () => !this.user?.userDescription?.dateAnonymisation && this.rightService.hasRight(TypeDroit.ADMIN_PROFIL,'consultation') && !this.listeUserProfil?.some(lienProfil => lienProfil?.profil?.typeProfil == 'WEB') && !this.pageContentService.isOpened(),
										doAction: () => this.addProfil(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_PROFIL,'creation')
									}],
									listeElements: [{
										type: 'PROFIL',
										libelle: 'user.elements.profil',
										component: UserProfilListComponent,
										retrieveComponentData: () => ({
											user: this.user,
											resume: this.resume,
											listeUserProfil: this.listeUserProfil,
											userAccountMobile: this.userAccountMobile,
											addProfil: this.addProfil.bind(this)
										}),
										count: () => this.resume.nbProfils,
										isVisible: () => this.rightService.hasRight(null,'consultation') && this.resume.nbProfils > 0
									},{
										type: 'IMPUTATION',
										libelle: 'user.elements.imputation',
										component: UserImputationListComponent,
										retrieveComponentData: () => ({
											user: this.user,
											resume: this.resume,
											addImputation: this.addImputation.bind(this)
										}),
										count: () => this.resume.nbImputations,
										isVisible: () => this.rightService.hasRight(null,'consultation'),
										libelleAction: this.translateService.instant('user.elements.imputation.ajouter'),
										doAction: () => this.addImputation(),
										isActionVisible: () => this.rightService.hasRight(null,'creation')
									},{
										type: 'EXTRACTION',
										libelle: 'user.elements.extraction',
										component: UserExtractionListComponent,
										retrieveComponentData: () => ({
											user: this.user,
											addUserScheduler: this.addUserScheduler.bind(this),
											resume: this.resume
										}),
										doAction: () => this.addUserScheduler(),
										count: () => this.resume?.nbExtractions || 0,
										libelleAction: this.translateService.instant('user.elements.extraction.ajouter'),
										isVisible: () => this.rightService.hasRight(null,'consultation'),
										isActionVisible: () => this.rightService.hasRight(null,'creation')
									},{
										type: 'USER_RESPONSABLE',
										libelle: 'user.elements.userResponsable',
										component: UserUserResponsableListComponent,
										retrieveComponentData: () => ({
											user: this.user,
											resume: this.resume,
											addUserResponsable: this.addUserResponsable.bind(this),
											onRefresh: () => this.complexPage.setSelectedElementByType('USER_RESPONSABLE',true),
										}),
										count: () => this.resume.nbUsersResponsable,
										isVisible: () => this.rightService.hasRight(null,'consultation'),
										libelleAction: this.translateService.instant('user.elements.userResponsable.ajouter'),
										doAction: () => this.addUserResponsable(),
										isActionVisible: () => this.rightService.hasRight(null,'creation') || this.rightService.hasRight(TypeDroit.ADMIN_ANALYTIQUE_SERVICE,'creation')

									},{
										type: 'AUTORISATION',
										libelle: 'user.elements.autorisation',
										component: UserAutorisationListComponent,
										retrieveComponentData: () => ({
											user: this.user,
											resume: this.resume,
											addAutorisation: this.addAutorisation.bind(this)
										}),
										count: () => this.resume.nbAutorisations,
										isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_TENANT_AUTORISATION,'consultation') && this.listeUserProfil?.some(lienProfil => lienProfil?.profil?.typeProfil == 'WEB' && lienProfil?.profil?.typeRestriction == TypeRestriction.CLIENT)
									}]
								};
							}
						});
					}
				});
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idUser: number): Observable<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement de l'utilisateur
		this.userService.loadUser(idUser).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de l'utilisateur
					this.user = result.data?.user;

					//Définition du résumé
					this.resume = {
						nbProfils: result.data?.user?.listeUserProfil?.length || 0,
						nbImputations: result.data?.nbImputations || 0,
						nbExtractions: result.data?.nbExtractions || 0,
						nbUsersResponsable: result.data?.nbUsersResponsable || 0,
						nbAutorisations: result.data?.nbAutorisations || 0
					}

					//Définition des listes des profils et des locales
					this.listeProfils = result?.data?.listeProfils;
					this.listeLocales = result?.data?.listeLocales;
					this.listeUserProfil = result?.data?.listeUserProfil;
					this.userAccountMobile = (result?.data?.listeAccounts as Array<any>)?.find(a => ['MOBILE','MOBILE_TEMPORAIRE'].indexOf(a.type) != -1);

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Définition de la société par défaut
					this.societeDefaut = result?.data?.societeDefaut;

					//Vérification de l'identifiant de l'utilisateur
					if (!this.user?.idUser) {
						//Création d'un objet vierge
						this.user = {
							enabled: true,
							transmissionAccount: true,
							tenant: this.loggedUser?.tenant,
							societe: this.societeDefaut,
							listeUserProfil: [],
							userDescription: {},
							locale: null
						};

						//Edition de l'utilisateur
						this.editUser();
					}
				}

				//Fin du traitement
				subject.complete();
			}
		});

		return subject;
	}

	/**
	 * Edition de l'utilisateur
	 */
	private editUser() {
		//Ouverture du composant d'édition
		this.pageContentService.open(UserEditComponent,{
			data: {
				user: this.user.idUser ? cloneDeep(this.user) : this.user,
				deleteUser: this.deleteUser.bind(this),
				listeProfils: cloneDeep(this.listeProfils),
				listeLocales: cloneDeep(this.listeLocales),
				onLoginChange: this.onLoginChange.bind(this)
			}
		}).subscribe({
			next: (user: any) => {
				//Vérification des données
				if (user) {
					//Mise à jour de l'utilisatur
					this.user = Object.assign(this.user,user);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.user);
				}
			}
		});
	}

	/**
	 * Suppression de l'utilisateur
	 */
	protected deleteUser() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.userService.deleteUser(this.user.idUser))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Redirection vers le conducteur
	 */
	private goToConducteur(idConducteur: any) {
		//Redirection vers le conducteur
		this.layoutService.goToByState('listeConducteurs-loadConducteur',{
			routeParams: {
				idConducteur
			},
			withGoBack: true
		});
	}

	/**
	 * Modification du profil
	 */
	protected addProfil(lienProfil: any = {},deleteLienProfil?: Function,index?: number) {
		//Ouverture du composant d'édition
		this.pageContentService.open(UserProfilEditComponent,{
			data: {
				lienProfil: cloneDeep({
					...lienProfil,
					user: {
						...this.user,
						login: this.user?.login || this.user?.email,
						transmissionAccount: !lienProfil.idLien
					}
				}),
				deleteLienProfil,
				listeProfils: this.listeProfils?.filter(profil => profil?.typeProfil == 'WEB'),
				userAccountMobile: this.userAccountMobile,
				onLoginChange: this.onLoginChange.bind(this),
				index
			}
		},'sub').subscribe({
			next: (data: { lienProfil: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('PROFIL',lienProfil.idLien != data?.lienProfil?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lienProfil.idLien && data.lienProfil?.idLien) {
						//Incrémentation du compteur
						this.resume.nbProfils++;

						//Ajout du profil à la liste
						this.listeUserProfil?.push(lienProfil);
					}

					//Mise à jour du profil
					lienProfil = Object.assign(lienProfil,data.lienProfil);

					//Vérfication du user
					if (lienProfil?.user) {
						//Définition du login
						this.user = Object.assign(this.user,lienProfil?.user);

						//Suppression de l'utilisateur dans le lien
						delete lienProfil.user;
					}

					//Mise à jour de la liste des profils de l'utilisateur
					this.user.listeUserProfil = this.listeUserProfil;
				}
			}
		});
	}

	/**
	 * Modification de l'imputation
	 */
	protected addImputation(imputation: any = { user: Object.assign({},this.user) },deleteImputation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(UserImputationEditComponent,{
			data: {
				imputation: cloneDeep(imputation),
				deleteImputation
			}
		},'sub').subscribe({
			next: (data: { imputation: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('IMPUTATION',imputation.idImputation != data.imputation?.idImputation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!imputation.idImputation && data.imputation?.idImputation)
						//Incrémentation du compteur
						this.resume.nbImputations++;
					else if (imputation.idImputation && !data.imputation?.idImputation)
						//Décrémentation du compteur
						this.resume.nbImputations--;

					//Mise à jour de l'imputation
					imputation = Object.assign(imputation,data.imputation);
				}
			}
		});
	}

	/**
	 * Modification du responsable
	 */
	protected addUserResponsable(userResponsable: any = { responsable: Object.assign({},this.user) },deleteUserResponsable?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(UserUserResponsableEditComponent,{
			data: {
				userResponsable: cloneDeep(userResponsable),
				deleteUserResponsable
			}
		},'sub').subscribe(() => {
			//Rafraichissement de l'onglet
			this.complexPage.setSelectedElementByType('USER_RESPONSABLE',true);
		});
	}

	/**
	 * Détection d'un changement de login
	 */
	public onLoginChange(user: any) {
		let oldLogin: string;

		//Récupération de l'ancien login
		oldLogin = this.user?.login;

		//Vérification de la modification pour un utilisateur existant seulement
		if (user?.idUser)
			//Détection d'un changement de login / profil (depuis le login pour les clients, ou l'email pour les revendeurs)
			user.transmissionAccount = oldLogin != (user?.tenant?.type == 'CLIENT' ? user?.login : user?.email);
	}

	/**
	 * Ajout d'une autorisation
	 */
	protected addAutorisation(autorisation: any,deleteAutorisation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(TenantAutorisationEditComponent,{
			data: {
				autorisation: cloneDeep(autorisation),
				deleteAutorisation,
				source: 'USER'
			}
		},'sub').subscribe({
			next: (data: { autorisation: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AUTORISATION',autorisation.idAutorisation != data.autorisation?.idAutorisation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!autorisation.idAutorisation && data.autorisation?.idAutorisation)
						//Incrémentation du compteur
						this.resume.nbAutorisations++;
					else if (autorisation.idAutorisation && !data.autorisation?.idAutorisation)
						//Décrémentation du compteur
						this.resume.nbAutorisations--;

					//Mise à jour de l'autorisation
					Object.assign(autorisation,data.autorisation);
				}
			}
		});
	}

	/**
	 * Ajout/modification d'une extraction
	 */
	private addUserScheduler(userScheduler: any = { typeNotification: 'USER' },deleteUserScheduler?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(UserExtractionEditComponent,{
			data: {
				user: this.user,
				userScheduler: cloneDeep(userScheduler),
				deleteUserScheduler
			}
		},'sub').subscribe({
			next: (data: any) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('EXTRACTION',data.idUserScheduler != userScheduler.idUserScheduler);

					//Vérification de la présence d'une création ou d'une suppression
					if (!userScheduler.idUserScheduler && data)
						//Incrémentation du compteur
						this.resume.nbExtractions++;
					else if (userScheduler.idUserScheduler && !data.idUserScheduler)
						//Décrémentation du compteur
						this.resume.nbExtractions--;

					//Mise à jour de la valeur
					Object.assign(userScheduler,data);
				}
			}
		});
	}
}