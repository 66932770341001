import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { OptionCategorieListItemComponent } from './option-categorie-list-item.component';
import { OptionCategorieListComponent } from './option-categorie-list.component';
import { OptionCategorieComponent } from './option-categorie.component';
import { OptionCategorieService } from './option-categorie.service';

@NgModule({
	imports: [ShareModule],
	declarations: [OptionCategorieListComponent,OptionCategorieListItemComponent,OptionCategorieComponent],
	providers: [OptionCategorieService]
})
export class OptionCategorieModule { }