<form #compteAuxiliaireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>comptabilite.compteAuxiliaire.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': compte.invalid }" *ngIf="!isCompteHidden">
							<label translate>comptabilite.compteAuxiliaire.compteCollectif</label>
							<div>
								<autocomplete name="compte" type="compte" #compte="ngModel" [(ngModel)]="compteAuxiliaire.compte" [filter]="{ idPlanComptable: compteAuxiliaire.planComptable?.idPlanComptable,listeTypes: ['BANQUE','TIERS','TAXE'] }" [isCreationDisabled]="true" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
							<label translate>comptabilite.compteAuxiliaire.numero</label>
							<div>
								<input type="text" name="numero" class="form-control" #numero="ngModel" [(ngModel)]="compteAuxiliaire.numero" required maxlength="100"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>comptabilite.compteAuxiliaire.libelle</label>
							<div>
								<input type="text" name="libelle" class="form-control" #libelle="ngModel" [(ngModel)]="compteAuxiliaire.libelle" required/>
							</div>
						</div>
						<div class="form-group">
							<label translate>comptabilite.compteAuxiliaire.pays</label>
							<div>
								<autocomplete name="pays" type="pays" [(ngModel)]="compteAuxiliaire.planComptable.pays" disabled="true"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label translate>comptabilite.compteAuxiliaire.remarque</label>
					<div>
						<textarea name="remarque" class="form-control" rows="3" [(ngModel)]="compteAuxiliaire.remarque"></textarea>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" (click)="saveCompteAuxiliaire();" [disabled]="compteAuxiliaireForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: compteAuxiliaireForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>