import { Component,EventEmitter,Input,OnChanges,OnInit,Output,SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash-es';

@Component({
	selector: 'display-more',
	exportAs: 'displayMore',
	templateUrl: './display-more.component.html',
})
export class DisplayMoreComponent implements OnInit,OnChanges {
	/** Liste d'éléments **/
	@Input() listeItems: Array<any>;

	/** Nombre d'éléments par page **/
	@Input() nbItems: number = 10;

	/** Déclenchement d'un evènement **/
	@Output() onDisplayMore: EventEmitter<number> = new EventEmitter();

	/** Nombre total d'éléments **/
	@Input() nbTotalItems: number;

	/** Mode d'affichage **/
	@Input() displayMode: 'DEFAULT' | 'COMPACT' = 'DEFAULT';

	/** Index courant **/
	index: number = 0;

	/** Numéro de la page courante **/
	numPage: number = 1;

	/** Nombre d'éléments restants **/
	nbRemainingItems: number = 0;

	/**
	 * Initialisatlion
	 */
	ngOnInit() {
		//Définition du nombre d'éléments restants
		this.nbRemainingItems = (this.nbTotalItems || this.listeItems?.length) - this.numPage * this.nbItems;
	}

	/**
	 * Détection des changements
	 */
	ngOnChanges(changes: SimpleChanges): void {
		//Vérification de la présence d'un changement
		if (!isEqual(changes?.listeItems?.previousValue,changes?.listeItems?.currentValue)) {
			//Réinitialisation du numéro de page
			this.numPage = 1;

			//Réinitialisation du nombre d'éléments restants
			this.nbRemainingItems = (this.nbTotalItems || this.listeItems?.length) - this.numPage * this.nbItems;
		} else if (changes?.nbTotalItems?.previousValue !== changes?.nbTotalItems?.currentValue)
			//Réinitialisation du nombre d'éléments restants
			this.nbRemainingItems = (this.nbTotalItems || this.listeItems?.length) - this.numPage * this.nbItems;
	}

	/**
	 * Affichage des items
	 */
	displayMore() {
		//Incrémentation du numéro de page
		this.numPage++;

		//Définition du nombre d'éléments restants
		this.nbRemainingItems = (this.nbTotalItems || this.listeItems?.length) - this.numPage * this.nbItems;

		//Emission de l'évènement
		this.onDisplayMore.emit(this.numPage);
	}

	/**
	 * Récupération du minimum entre le nombre d'éléments restants et la taille de page
	 */
	public getNextItemsCount(): number {
		//Récupération du minimum entre le nombre d'éléments restants et la taille de page
		return Math.min(this.nbItems,this.nbRemainingItems || 0);
	}

	/**
	 * Récupération de la tranche
	 */
	public getSlice(): Array<any> {
		//Retour de la tranche
		return this.listeItems?.slice?.(this.index,this.nbItems * this.numPage);
	}
}
