import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { first } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { VehiculeService } from '../vehicule/vehicule.service';

@Component({
	templateUrl: './service-vehicule-list-item.component.html'
})
export class ServiceVehiculeListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des types de véhicule **/
	public TypeVehicule: typeof TypeVehicule = TypeVehicule;

	/** Date du jour **/
	today: moment.Moment = moment.utc();

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Redirection vers le véhicule
	 */
	public goToVehicule() {
		//Redirection vers le véhicule
		this.vehiculeService.goToVehicule(this.data);
	}
}