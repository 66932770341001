import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { NatureModule } from './nature/nature.module';
import { FournisseurListItemComponent } from './fournisseur-list-item.component';
import { FournisseurListComponent } from './fournisseur-list.component';
import { FournisseurService } from './fournisseur.service';
import { CodeModule } from './code/code.module';
import { FormatInterfaceModule } from './format-interface/format-interface.module';
import { FournisseurAddComponent } from './fournisseur-add.component';
import { FournisseurContactAddComponent } from './fournisseur-contact-add.component';
import { FournisseurNatureListComponent } from './fournisseur-nature-list.component';
import { FournisseurNatureListItemComponent } from './fournisseur-nature-list-item.component';
import { FournisseurComponent } from './fournisseur.component';
import { FournisseurEditComponent } from './fournisseur-edit.component';
import { FournisseurContactListComponent } from './fournisseur-contact-list.component';
import { FournisseurContactListItemComponent } from './fournisseur-contact-list-item.component';
import { FournisseurContactEditComponent } from './fournisseur-contact-edit.component';
import { FournisseurFormatInterfaceListComponent } from './fournisseur-format-interface-list.component';
import { FournisseurFormatInterfaceListItemComponent } from './fournisseur-format-interface-list-item.component';
import { FournisseurCompteListComponent } from './fournisseur-compte-list.component';
import { ComptabiliteModule } from 'src/app/components/comptabilite/comptabilite.module';
import { FournisseurFacturationListComponent } from './fournisseur-facturation-list.component';
import { FournisseurFacturationListItemComponent } from './fournisseur-facturation-list-item.component';
import { FournisseurFacturationEditComponent } from './fournisseur-facturation-edit.component';
import { FournisseurAliasListComponent } from './fournisseur-alias-list.component';
import { FournisseurAliasListItemComponent } from './fournisseur-alias-list-item.component';
import { FournisseurAliasEditComponent } from './fournisseur-alias-edit.component';

@NgModule({
	imports: [ShareModule,NatureModule,CodeModule,FormatInterfaceModule,ComptabiliteModule],
	exports: [NatureModule,CodeModule,FormatInterfaceModule],
	declarations: [FournisseurListComponent,FournisseurListItemComponent,FournisseurAddComponent,FournisseurNatureListComponent,FournisseurNatureListItemComponent,FournisseurComponent,FournisseurEditComponent
		,FournisseurContactListComponent,FournisseurContactListItemComponent,FournisseurContactEditComponent,FournisseurFormatInterfaceListComponent,FournisseurFormatInterfaceListItemComponent
		,FournisseurCompteListComponent,FournisseurFacturationListComponent,FournisseurFacturationListItemComponent,FournisseurFacturationEditComponent
		,FournisseurAliasListComponent,FournisseurAliasListItemComponent,FournisseurAliasEditComponent,FournisseurContactAddComponent
	],
	providers: [FournisseurService]
})
export class FournisseurModule {}
