import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';

@Component({
	selector: 'vehicule-affectation-edit',
	templateUrl: './vehicule-affectation-edit.component.html'
})
export class VehiculeAffectationEditComponent extends PageContentItem {
	/** Affectation courante **/
	@Input() affectation: any;

	/** Véhicule courant **/
	@Input() vehicule: any;

	/** Utilisateur courant **/
	@Input() user: User;

	/** Fonction de suppression **/
	@Input() deleteAffectation: Function;

	/** Types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}