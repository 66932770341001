import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ConducteurAffectationListItemComponent } from './conducteur-affectation-list-item.component';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	templateUrl: './conducteur-affectation-list.component.html'
})
export class ConducteurAffectationListComponent extends PageContentItem implements OnInit {
	/** Conducteur **/
	@Input() conducteur: any;

	/** Résumé **/
	@Input() resume: { nbVehiculesAffectes: number };

	/** Affichage de l'affectation **/
	@Input() showAffectation: (affectation: any) => void;

	/** Données **/
	liste: ListView<any,ConducteurAffectationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConducteurAffectationListItemComponent>({
			uri: `/controller/Vehicule/filtreAffectations`,
			component: ConducteurAffectationListItemComponent,
			isLocal: true,
			extraOptions: {
				showAffectation: this.showAffectation
			},
			listeFilters: [{
				clef: 'vehicule',
				autocomplete: {
					type: 'vehicule'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'conducteur.idConducteur',
				type: TypeFilter.LONG,
				valeur: this.conducteur.idConducteur
			}],
			defaultOrder: '-dateDebut'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idAffectation > 0)
			//Mise à jour des compteurs
			this.resume.nbVehiculesAffectes -= 1;
	}
}