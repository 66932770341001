<form #droitForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div *ngFor="let groupe of mapDroits | keyvalue:sortByOrder">
		<div class="card profil m-t-10" [ngClass]="{ 'detail profil-edit': edition.isEdition }" *ngIf="!edition.isEdition || !edition.typeGroupe || groupe?.key?.typeGroupe == edition.typeGroupe">
			<div class="card-header">
				<h2 class="card-title">{{ ('right.title' | translate) + ' ' + (groupe?.key?.libelle | translate) }}</h2>
				<div class="row" *ngIf="!edition.isEdition || !edition.typeGroupe">
					<div class="lv-actions actions">
						<a [routerLink]="[]" (click)="groupeToggled[groupe?.key?.typeGroupe] = !groupeToggled?.[groupe?.key?.typeGroupe]"><icon [name]="groupeToggled?.[groupe?.key?.typeGroupe] ? 'chevron_right' : 'expand_more'"></icon></a>
						<a *ngIf="canEdit && !edition.isEdition" [routerLink]="[]" (click)="editGroupe(groupe?.key?.typeGroupe)"><icon name="edit"></icon></a>
					</div>
				</div>
			</div>
			<div class="card-body card-padding collapse" [ngClass]="{ 'in': edition.isEdition && edition.typeGroupe || !groupeToggled?.[groupe?.key?.typeGroupe] }">
				<div class="alert alert-info" *ngIf="!edition.isEdition && !groupe?.key?.hasChild">
					<icon name="lock"></icon>
					<strong translate>right.aucunDroitDefini.title</strong>
					<button *ngIf="canEdit" mat-raised-button color="primary" (click)="editGroupe(groupe?.key?.typeGroupe)" [tooltip]="'right.aucunDroitDefini.message' | translate">
						<span translate>actions.continuer</span>
					</button>
				</div>

				<fieldset [disabled]="!edition.isEdition">
					<ul class="liste_menu" *ngIf="groupe.key.hasChild || edition.isEdition">
						<droit-line *ngIf="edition.isEdition" [isRoot]="true"></droit-line>

						<li *ngFor="let levelOne of groupe?.value | keyvalue">
							<droit-line [extraData]="levelOne?.key" [droit]="levelOne?.value" [isEdition]="edition.isEdition"></droit-line>

							<ul class="clist" *ngIf="levelOne?.key?.isGroupe">
								<li *ngFor="let levelTwo of levelOne?.value | keyvalue">
									<droit-line [extraData]="levelTwo?.key" [droit]="levelTwo?.value" [isEdition]="edition.isEdition"></droit-line>

									<ul class="clist" *ngIf="levelTwo?.key?.isGroupe">
										<li *ngFor="let levelThree of levelTwo?.value | keyvalue">
											<droit-line [extraData]="levelThree?.key" [droit]="levelThree?.value" [isEdition]="edition.isEdition"></droit-line>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</fieldset>
			</div>
		</div>
	</div>
	<div *ngIf="edition.isEdition">
		<span class="pull-right">
			<button mat-raised-button color="primary" (click)="saveDroits();" *ngIf="null | right:'creation'" [disabled]="droitForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</span>
		<button mat-button #closeButton [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>