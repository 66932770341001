<form #revendeurForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!revendeur.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>revendeur.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="revendeur.libelle" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': revendeurForm.controls['reference']?.invalid }">
							<label translate>revendeur.code</label>
							<div>
								<ng-container *ngIf="!revendeur.idRevendeur || revendeur.isAdminAccountEnabled === false">
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="revendeur.reference" maxlength="100" required/>
								</ng-container>
								<div *ngIf="revendeur.idRevendeur && (revendeur.isAdminAccountEnabled == null || revendeur.isAdminAccountEnabled)">
									<mat-chip-listbox>
										<mat-chip (removed)="isAdminAccountEnabled()">
											{{ revendeur.reference }}
											<button matChipRemove>
												<mat-icon>cancel</mat-icon>
											</button>
										</mat-chip>
									</mat-chip-listbox>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': localeDefaut.invalid }">
							<label translate>revendeur.localePrincipale</label>
							<div class="half">
								<selectpicker name="localeDefaut" #localeDefaut="ngModel" [(ngModel)]="revendeur.localeDefaut" [compareWith]="selectPickerService.getComparator('idLocale')" required>
									<mat-option *ngFor="let locale of listeLocales" [value]="locale">{{ locale.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': email.invalid }">
							<label translate>revendeur.email</label>
							<div>
								<input type="text" name="email" #email="ngModel" class="form-control" [(ngModel)]="revendeur.email" maxlength="300" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': modeAuthentificationField.invalid }">
							<label translate>revendeur.modeAuthentification</label>
							<div>
								<selectpicker name="modeAuthentification" #modeAuthentificationField="ngModel" [(ngModel)]="revendeur.authentificationMode" required>
									<mat-option *ngFor="let mode of listeModesAuthentification" [value]="mode.code">{{ mode.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" *ngIf="revendeur?.authentificationMode != 'LOGIN_MDP'">
							<label translate>revendeur.configuration</label>
							<div>
								<a [routerLink]="[]" (click)="showListeConfigurations()">
									<icon class="icon-hc-2x" name="search"></icon><span [translate]="'revendeur.typeAuthentification' | pluralTranslate:(revendeur.listeAuthConfigs?.length || 0)" [translateParams]="{ nb: revendeur.listeAuthConfigs?.length || 0 }"></span>
								</a>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
							<label translate>revendeur.actif.item</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="revendeur.actif"><span translate>revendeur.actif.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': transmissionAccount.invalid }">
							<label translate>revendeur.connexion.item</label>
							<div>
								<mat-checkbox name="transmissionAccount" #transmissionAccount="ngModel" [(ngModel)]="revendeur.transmissionAccount"><span translate>revendeur.connexion.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': updateCompteService.invalid }">
							<label translate>revendeur.compteService.item</label>
							<div>
								<mat-checkbox name="updateCompteService" #updateCompteService="ngModel" [(ngModel)]="revendeur.updateCompteService"><span translate>revendeur.compteService.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>revendeur.piecesJointes</label>
							<div>
								<div [attachment]="revendeur" [typeAttachment]="TypeAttachment.REVENDEUR"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': remarque.invalid }">
					<label translate>revendeur.remarque</label>
					<div>
						<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="revendeur.remarque" rows="3"></textarea>
					</div>
				</div>
				<div class="row" *ngIf="revendeur.theme">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': theme.invalid }">
							<label translate>revendeur.theme.item</label>
							<div>
								<div class="checkbox">
									<label><input type="checkbox" name="theme" #theme="ngModel" [(ngModel)]="revendeur.theme.actif" (click)="revendeur.theme.primaryColor = revendeur.theme?.primaryColor || '#0046FE'"/><i class="input-helper"></i><span translate>revendeur.theme.info</span></label>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="revendeur?.theme?.actif">
							<label translate>revendeur.logoPersonnalise</label>
							<div>
								<div [attachment]="revendeur.theme" [typeAttachment]="TypeAttachment.THEME" typeLink="LOGO" queueLimit="1" filters="jpeg|jpg|png"></div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': primaryColor.invalid }" *ngIf="revendeur?.theme?.actif">
							<label translate>revendeur.couleurPersonnalisee</label>
							<div>
								<color-picker name="primaryColor" #primaryColor="ngModel" [(ngModel)]="revendeur.theme.primaryColor" required></color-picker>
							</div>
						</div>
					</div>
					<div class="col-md-6" *ngIf="revendeur?.theme?.actif">
						<div class="form-group" [ngClass]="{ 'has-error': marqueBlanche.invalid }">
							<label translate>revendeur.marqueBlanche.item</label>
							<div>
								<mat-checkbox name="marqueBlanche" #marqueBlanche="ngModel" [(ngModel)]="revendeur.theme.marqueBlanche"><span translate>revendeur.marqueBlanche.info</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': marqueBlanche.invalid }" *ngIf="revendeur?.theme?.actif && revendeur?.theme?.marqueBlanche">
							<label translate>revendeur.backgroundPersonnalise</label>
							<div>
								<div [attachment]="revendeur.theme" [typeAttachment]="TypeAttachment.THEME" typeLink="BACKGROUND" queueLimit="1" filters="jpeg|jpg|png"></div>
							</div>
						</div>
						<div class="form-group" *ngIf="revendeur?.theme?.actif && revendeur?.theme?.marqueBlanche">
							<label translate>revendeur.headerPersonnalise</label>
							<div>
								<div [attachment]="revendeur.theme" [typeAttachment]="TypeAttachment.THEME" typeLink="HEADER" queueLimit="1" filters="jpeg|jpg|png"></div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': secondaryColor.invalid }" *ngIf="revendeur?.theme?.actif && revendeur?.theme?.marqueBlanche">
							<label translate>revendeur.couleurSecondaire</label>
							<div>
								<color-picker name="secondaryColor" #secondaryColor="ngModel" [(ngModel)]="revendeur.theme.secondaryColor"></color-picker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: revendeurForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteRevendeur();" *ngIf="revendeur?.idRevendeur && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveRevendeur();" *ngIf="null | right:'creation'" [disabled]="revendeurForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>