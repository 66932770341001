<form #contratCRMResiliationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>contratCRM.resiliation.title</h4>
	</div>
	<div class="modal-body">
		<div class="alert alert-info">
			<icon name="info"></icon>
			<strong translate>contratCRM.resiliation.message</strong>
		</div>
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': dateResiliation.invalid }">
				<label translate>contratCRM.dateResiliation</label>
				<div class="half">
					<input type="text" class="form-control" name="dateResiliation" #dateResiliation="ngModel" minDate="today" [(ngModel)]="contratCRM.dateResiliation" datePicker format="date" [storeDate]="true" required/>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveContratCRMResiliation()" [disabled]="contratCRMResiliationForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>