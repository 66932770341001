import { Pipe,PipeTransform } from '@angular/core';

import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from './right.service';

@Pipe({
	name: 'right'
})
export class RightPipe implements PipeTransform {
	/**
	 * Constructeur
	 */
	constructor(private rightService: RightService) {

	}

	/**
	 * Transformation
	 */
	transform(typeDroit: TypeDroit | Array<TypeDroit>,mode: 'consultation' | 'creation' | 'suppression') {
		//Vérification du droit de l'utilisateur connecté
		return Array.isArray(typeDroit) ? this.rightService.hasAnyRight(typeDroit,mode) : this.rightService.hasRight(typeDroit,mode);
	}
}