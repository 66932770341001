import { Component,Input,OnInit } from '@angular/core';

import { OptionCategorieService } from '../option-categorie/option-categorie.service';
import { ConfigurationService } from './configuration.service';

@Component({
	selector: 'configuration-option-list',
	templateUrl: './configuration-option-list.component.html'
})
export class ConfigurationOptionListComponent implements OnInit {
	/** Configuration **/
	@Input() configuration: any;

	/** Indicateur d'affichage de la checkbox **/
	@Input() isShowCheckbox: boolean;

	/** Liste des types de prix **/
	listeTypesPrix: Array<{ code: string,libelle: string,isSelected?: boolean }>;

	/** Liste des types d'options **/
	listeTypesOptions: Array<{ code: string,libelle: string,isSelected?: boolean,hasDetails?: boolean }>;

	/**
	 * Constructeur
	 */
	constructor(private configurationService: ConfigurationService,private optionCategorieService: OptionCategorieService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Binding
		this.filterByTypeCategoryAndPrix = this.filterByTypeCategoryAndPrix.bind(this);

		//Initalisation de la configuration
		this.configuration?.listeDetails?.forEach(detail => {
			//Redéfinition des détails parents qui forcent la sélection du détail courant
			detail.listeSourcesSelection = detail?.listeSourcesSelection?.map(source => ({
				...source,
				detail: this.configuration?.listeDetails?.find(d => d.idDetail == source?.detail?.idDetail)
			}));

			//Redéfinition des détails parents qui forcent la sélection du détail courant
			detail.listeSourcesInclusion = detail?.listeSourcesSelection?.filter(source => source?.contrainte?.typeContrainte == 'INCLUSION');

			//Vérification de la liste des inclusions et remise à zéro si nécessaire
			detail.listeSourcesInclusion = detail?.listeSourcesInclusion?.length ? detail.listeSourcesInclusion : null;

			//Redéfinition des détails parents qui forcent la sélection du détail courant
			detail.listeSourcesObligation = detail?.listeSourcesSelection?.filter(source => source?.contrainte?.typeContrainte == 'OBLIGATION');

			//Vérification de la liste des inclusions et remise à zéro si nécessaire
			detail.listeSourcesObligation = detail?.listeSourcesObligation?.lenth ? detail.listeSourcesObligation : null;
		})

		//Liste des types d'options
		this.listeTypesOptions = this.initTypesOptions(this.configuration?.listeDetails);

		//Liste des types de prix
		this.listeTypesPrix = this.initTypesPrix(this.configuration?.listeDetails);

		//Définition de l'indicateur de la présence d'options dans les catégories
		this.refreshCategoryHasDetailFlags();
	}

	/**
	 * Initialisation des filtres sur les catégories d'options
	 */
	private initTypesOptions(listeDetails: Array<any>): Array<{ code: string,libelle: string,isSelected?: boolean,hasDetails?: boolean }> {
		//Vérification de la présence du code dans les options
		return this.optionCategorieService.getListeTypesOption().filter(option => {
			//Définition du filtre
			return option.code == 'ACCESSOIRE' && listeDetails?.some?.(detail => !detail?.option?.listeSubCategories?.length || detail?.option?.listeSubCategories?.some?.(subCategorie => !subCategorie?.type))
				|| listeDetails?.some?.(detail => detail?.option?.listeSubCategories?.some?.(subCategorie => subCategorie?.type == option?.code));
		});
	}

	/**
	 * Initialisation des filtres sur les prix des options
	 */
	private initTypesPrix(listeDetails: Array<any>): Array<{ code: string,libelle: string,isSelected?: boolean }> {
		let listeTypesPrix: Array<any>;

		//Vérification du type de source
		if (this.configuration?.typeSource == 'GRILLE_ATTRIBUTION' && this.configuration?.type == 'DEMANDE_VEHICULE')
			//Filtre des détails sélectionnés
			listeDetails = listeDetails?.filter(detail => detail?.selected);

		//Initialisation des filtres sur les prix des options
		listeTypesPrix = this.configurationService.getListeTypesPrix().filter(typePrix => typePrix?.code == 'PAYANTE' ? listeDetails?.some(detail => detail?.option?.type != 'SERIE') : listeDetails?.some(detail => detail?.option?.type == 'SERIE'));

		//Vérification de la présence d'un filtre
		if (listeTypesPrix?.length)
			//Pré-sélection du premier filtre
			listeTypesPrix[0].isSelected = true;

		//Retour de la liste des types de prix disponibles
		return listeTypesPrix;
	}

	/**
	 * Réactualisation de l'indicateur de la présence d'options dans les catégories
	 */
	private refreshCategoryHasDetailFlags() {
		let listeFilteredPrix: Array<any>;

		//Récupération de la liste des filtres de prix sélectionnés
		listeFilteredPrix = this.listeTypesPrix.filter(typePrix => typePrix.isSelected).map(typePrix => typePrix.code);

		//Parcours des catégories d'option disponibles
		this.listeTypesOptions.forEach(typeOption => {
			//Vérification que la catégorie contient des options valides pour les types de prix sélectionnés
			typeOption.hasDetails = listeFilteredPrix.length == 0 || listeFilteredPrix.length == this.listeTypesPrix.length || this.configuration?.listeDetails?.filter(detail => this.isDetailInPrix(detail,listeFilteredPrix)).some(detail => this.isDetailInCategories(detail,[typeOption.code]));

			//Vérification de la catégorie
			if (!typeOption.hasDetails && typeOption.isSelected)
				//Désélection de la catégorie
				typeOption.isSelected = false;
		});
	}

	/**
	 * Interception de la modification des filtres sur le type de prix
	 */
	public toggleTypePrixFilter(typePrix: any) {
		//Inversion de la sélection du filtre
		typePrix.isSelected = !typePrix.isSelected;

		//Réactualisation de l'indicateur de la présence d'options dans les catégories
		this.refreshCategoryHasDetailFlags();
	}

	/**
	 * Filtrage des options par type de catégorie d'options et de prix
	 */
	public filterByTypeCategoryAndPrix(detail: any): boolean {
		let listeFilteredCategories: Array<any>;
		let listeFilteredPrix: Array<string>;
		let isTypeCategoryOk: boolean;
		let isTypePrixOk: boolean;

		//Vérification du type de source et de la sélection de l'option
		if (!(this.configuration?.typeSource == 'GRILLE_ATTRIBUTION' && this.configuration?.type == 'DEMANDE_VEHICULE' && !detail.selected && !detail.isPreviouslySelected)) {
			//Liste des types filtrés
			listeFilteredCategories = this.listeTypesOptions.filter(typeOption => typeOption.isSelected).map(typeOption => typeOption.code);

			//Liste des prix filtrés
			listeFilteredPrix = this.listeTypesPrix.filter(typePrix => typePrix.isSelected).map(typeOption => typeOption.code);

			//Vérification que la catégorie de l'option correspond aux filtres
			isTypeCategoryOk = listeFilteredCategories.length == 0 || this.isDetailInCategories(detail,listeFilteredCategories);

			//Vérification que le type de prix de l'option correspond aux filtres
			isTypePrixOk = listeFilteredPrix.length == 0 || this.isDetailInPrix(detail,listeFilteredPrix);

			//Retour de la conformité de l'option aux filtres sélectionnés
			return isTypeCategoryOk && isTypePrixOk;
		} else
			//Option masquée
			return false;
	}

	/**
	 * Vérification que l'une des sous-catégories de l'option correspond à l'une des catégories fournies
	 */
	private isDetailInCategories(detail: any,listeValidCategories: Array<string>): boolean {
		//Vérification que l'une des sous-catégories de l'option correspond à l'une des catégories fournies
		return detail?.option != null && (detail?.option?.listeSubCategories?.length ? detail?.option?.listeSubCategories : [{ type: 'ACCESSOIRE' }]).some(subCategorie => {
			//Retour de la présence de la sous-catégorie courante dans la liste des catégories valides
			return listeValidCategories.indexOf(subCategorie.type || 'ACCESSOIRE') != -1;
		});
	}

	/**
	 * Vérification que le type de prix de l'option correspond à l'un des types fournis
	 */
	private isDetailInPrix(detail: any,listeValidTypePrix: Array<string>): boolean {
		//Vérification que le type de prix de l'option correspond à l'un des types fournis
		return detail?.option != null && listeValidTypePrix?.indexOf(detail.option.type == 'SERIE' ? 'SERIE' : 'PAYANTE') != -1;
	}
}