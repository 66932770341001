import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TenantContratSelectionComponent } from './tenant-contrat-selection.component';
import { TenantListItemComponent } from './tenant-list-item.component';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { TypeRestriction } from 'src/app/domain/security/right';

@Component({
	templateUrl: './tenant-list.component.html'
})
export class TenantListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,TenantListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private bsModalService: BsModalService,private router: Router,private loggedUserService: LoggedUserService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantListItemComponent>({
			uri: `/controller/Tenant/filtreTenants`,
			title: this.translateService.instant('tenant.liste.title'),
			component: TenantListItemComponent,
			listeFilters: [{
				clef: 'nom',
				title: this.translateService.instant('search.raisonSociale'),
				isDefault: true
			},{
				clef: 'code',
				title: this.translateService.instant('search.reference'),
				isDefault: true
			},{
				clef: 'tenantDescription.adminEmail',
				title: this.translateService.instant('search.eMail'),
				isDefault: true
			},{
				clef: 'tenantDescription.clientCRM.facturationId',
				title: this.translateService.instant('search.facturationId'),
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'tenantDescription.clientCRM',
				title: this.translateService.instant('search.clientCRM'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: { type: 'clientCRM' }
			},{
				clef: 'revendeur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: { type: 'revendeur' }
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Affichage de la sélection d'un contrat CRM
				this.showContratCRMSelection();
			}),
			getKeyFieldName: () => 'idTenant',
			hasMainAction: () => this.rightService.hasRight(null,'creation') && !this.loggedUserService.hasProfilRestriction(TypeRestriction.CLIENT)
		});
	}

	/**
	 * Affichage de la sélection d'un contrat CRM
	 */
	showContratCRMSelection() {
		let bsModalRef: BsModalRef<TenantContratSelectionComponent>;

		//Affichage de la popup de sélection du contrat CRM
		bsModalRef = this.bsModalService.show(TenantContratSelectionComponent);

		//Interception de la fermeture de la popup
		bsModalRef.onHidden.subscribe({
			next: () => {
				//Vérification de la sélection
				if (bsModalRef.content.contrat)
					//Redirection vers la création d'un tenant
					this.router.navigate(['/Tenant',0,bsModalRef.content.contrat.idContrat]);
			}
		});
	}
}