import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'facture-detail-edit',
	templateUrl: './facture-detail-edit.component.html'
})
export class FactureDetailEditComponent extends PageContentItem {
	/** Ligne de facture **/
	@Input() detail: any;

	/** Suppression d'une ligne de facture **/
	@Input() deleteDetail: (detail: any,close: Function) => void;

	/** Edition/création/duplication d'une ligne de facture **/
	@Input() addDetail: Function;

	/** Indicateur de duplication **/
	@Input() isDuplication: boolean;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Duplication de la ligne de facture
	 */
	public duplicate(detail: any = this.detail) {
		//Duplication de la ligne de facture
		this.addDetail(detail,undefined,true);
	}
}