import { Component } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { SinistreService } from 'src/app/components/vehicule/sinistre/sinistre.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConducteurSinistreEditComponent } from './conducteur-sinistre-edit.component';


@Component({
	templateUrl: './conducteur-sinistre-list-item.component.html'
})
export class ConducteurSinistreListItemComponent extends ListViewItem<any> {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private sinistreService: SinistreService,private pageContentService: PageContentService) {
		//Héritage
		super();
	}

	/**
	 * Affichage du sinistre
	 */
	showSinistre() {
		//Chargement complet du sinistre
		this.sinistreService.loadSinistre(this.data.idSinistre).pipe(switchMap(result => {
			//Ouverture du composant d'édition
			return this.pageContentService.open(ConducteurSinistreEditComponent,{
				data: {
					sinistre: result?.data?.sinistre
				}
			},'sub');
		})).subscribe();
	}
}