import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,of,Subject } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';

@Injectable()
export class OptionService {
	/** Cache des modèles synchronisés **/
	private mapOptionsForModele: { [idModele: number]: boolean } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private messagingService: MessagingService) {}

	/**
	 * Recherche de modèles
	 */
	public filtreModeles(searchSpec: any): Observable<any> {
		//Recherche de modèles
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/filtreModeles/`,searchSpec);
	}

	/**
	 * Synchronisation des options d'un modèle
	 */
	public synchronizeOptions(idModele: number): Observable<boolean> {
		let syncSubject: Subject<boolean>;
		let messaging$: MessagingObservables;

		//Vérification d'un indicateur de synchronisation des options pour le modèle
		if (!this.mapOptionsForModele[idModele]) {
			//Initialisation du sujet de réponse
			syncSubject = new Subject<boolean>();

			//Synchronisation des options du modèle par websocket
			messaging$ = this.messagingService.init({
				entryPoint: `controller/VehiculeReferentiel/synchronizeModeleOptions/${idModele}`,
				outputPoint: '/messaging/modele/synchronizeModeleOptions/status',
				method: 'POST'
			}).onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Conservation de l'état de synchronisation
					this.mapOptionsForModele[idModele] = true;

					//Retour de l'état de synchronisation
					syncSubject.next(true);
				}
			}).onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Emission d'une erreur de synchronisation
					syncSubject.next(false);
				}
			});

			//Retour du sujet
			return syncSubject.asObservable();
		} else
			//Retour d'une valeur
			return of(true);
	}

	/**
	 * Chargement des options pour un modèle
	 */
	public loadModeleOptions(idModele: number): Observable<any> {
		//Chargement des options pour un modèle
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/filtreModeleOptions`,{
			defaultOrder: 'libelle',
			listeFilter: [{
				clef: 'modele.idModele',
				valeur: idModele,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.STRING
			},{
				clef: 'type',
				type: TypeFilter.STRING,
				typeComparaison: TypeComparaison.EQUAL,
				valeur: 'SERIE'
			},{
				clef: 'dateFin',
				type: TypeFilter.DATE,
				typeComparaison: TypeComparaison.IS_NULL
			},{
				clef: 'isConfiguration',
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.BOOLEAN,
				valeur: false
			}],
			nbObjetsParPage: 1000
		});
	}
}