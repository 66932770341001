import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { EntretienInterventionAddComponent } from './entretien-intervention-add.component';

@Component({
	selector: 'entretien-intervention',
	templateUrl: './entretien-intervention.component.html'
})
export class EntretienInterventionComponent implements AfterContentChecked,OnInit {
	/** Liste des intervention **/
	@Input() listeLinksVehiculePlanEntretienDetail: Array<any>;

	/** Entretien **/
	@Input() readonly entretien: any;

	/** Liste des interventions prévues **/
	listeLinksVehiculePlanEntretienDetailPrevues: Array<any> = [];

	/** Liste des interventions non prévues et ajoutées **/
	listeLinksVehiculePlanEntretienDetailAdded: Array<any> = [];

	/** Résultat **/
	result: { listeLinksVehiculePlanEntretienDetail: Array<any> };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<EntretienInterventionComponent>,public vehiculeService: VehiculeService,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation des statuts
		this.listeLinksVehiculePlanEntretienDetail.forEach(link => link.checked = link.statut == 'REALISE');

		//Récupération des interventions prévues
		this.listeLinksVehiculePlanEntretienDetailPrevues = this.listeLinksVehiculePlanEntretienDetail.filter(link => link.planEntretienDetail);

		//Récupération des interventions ajoutées
		this.listeLinksVehiculePlanEntretienDetailAdded = this.listeLinksVehiculePlanEntretienDetail.filter(link => !link.planEntretienDetail);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement des intervention
	 */
	public saveListeInterventions() {
		//Mise à jour des statuts
		this.listeLinksVehiculePlanEntretienDetail.forEach(link => link.statut = link.checked ? 'REALISE' : 'A_REALISER');

		//Définition du résultat
		this.result = {
			listeLinksVehiculePlanEntretienDetail: [...this.listeLinksVehiculePlanEntretienDetailPrevues,...this.listeLinksVehiculePlanEntretienDetailAdded.filter(l => l.checked)]
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la modal
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Mise à jour du statut
	 */
	toggleStatut(link: any) {
		//Vérification de l'entretien prévisionnel
		if (this.entretien.previsionnel)
			//Inversion du statut
			link.checked = !link.checked;
	}

	/**
	 * Ajout d'une intervention
	 */
	addIntervention() {
		let bsModalRef: BsModalRef<EntretienInterventionAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(EntretienInterventionAddComponent,{
			initialState: {
				entretien: this.entretien
			},
			class: 'modal-md'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.link),
			filter(link => !!link)
		).subscribe({
			next: (link: any) => {
				//Définition du résultat
				this.listeLinksVehiculePlanEntretienDetailAdded.push(link);
			}
		});
	}
}