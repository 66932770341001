import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { mapEntites } from './grille-attribution.entites';
import { GrilleAttributionListItemComponent } from './grille-attribution-list-item.component';

@Component({
	templateUrl: './grille-attribution-list.component.html'
})
export class GrilleAttributionListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,GrilleAttributionListItemComponent>;

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,public dashboardService: DashboardService,public extractionService: ExtractionService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,GrilleAttributionListItemComponent>({
			uri: () => `/controller/Vehicule/filtreGrillesAttribution`,
			title: this.translateService.instant('vehicule.grilleAttribution.liste.title'),
			component: GrilleAttributionListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'listeGroupes',
				title: this.translateService.instant('search.groupe'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'groupe'
				}
			},{
				clef: 'isSpecifique',
				type: TypeFilter.BOOLEAN
			}],
			getKeyFieldName: () => 'idGrilleAttribution',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => { this.layoutService.goToByUrl('/GrilleAttribution/loadGrilleAttribution/0'); },
		});
	}
}