import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';


@Injectable()
export class ReponseDemandeCotationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Récupération des statuts des réponses à la demande de cotation
	 */
	public getMapStatuts(): { [statut: string]: { icone: string,iconeType?: string } } {
		//Retour des statuts
		return {
			'A_COMPLETER': {
				icone: 'attach_money'
			},
			'A_TRANSMETTRE': {
				icone: 'edit'
			},
			'TRANSMISE': {
				icone: 'send'
			},
			'RETENUE': {
				icone: 'check'
			},
			'NON_RETENUE': {
				icone: 'close'
			},
			'DECLINEE': {
				icone: 'call_end'
			},
		}
	}

	/**
	 * Chargement d'une réponse à la demande de cotation
	 */
	public loadReponseDemandeCotation(idReponse: number): Observable<Result> {
		//Chargement d'une réponse à la demande de cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/loadReponseDemandeCotation/${idReponse}`,null);
	}

	/**
	 * Sauvegarde d'une réponse à la demande de cotation
	 */
	public saveReponseCotation(reponseCotation): Observable<Result>{
		//Sauvegarde d'une réponse à la demande de cotation
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveReponseCotation`,reponseCotation);
	}

	/**
	 * Exécution de l'action pour la réponse à la demande de cotation
	 */
	public executeActionForReponse(typeAction: string,idReponse: any): Observable<Result> {
		//Exécution de l'action pour la réponse à la demande de cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/executeActionForReponse/${typeAction}/${idReponse}`,null);
	}
}