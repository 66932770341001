import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { FournisseurService } from './fournisseur.service';
import { ReferentielEntiteListItem } from 'src/app/domain/referentiel/referentiel-entite-list-item';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';

@Component({
	selector: 'fournisseur-list-item',
	templateUrl: './fournisseur-list-item.component.html'
})
export class FournisseurListItemComponent extends ReferentielEntiteListItem<any> implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.fournisseur.Fournisseur';

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,translateService: TranslateService,toastrService: ToastrService,private historyService: HistoryService,private fournisseurService: FournisseurService,rightService: RightService,referentielService: ReferentielService,private layoutService: LayoutService) {
		//Héritage
		super(referentielService,toastrService,translateService,rightService);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Héritage
		super.ngOnInit();
	}

	/**
	 * Suppression de l'objet
	 */
	deleteFournisseur() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.fournisseurService.deleteFournisseur(this.data,this.liste.selectedSelector))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Vérification du type de référentiel et de la suppression d'un fournisseur importé
				if (this.liste.selectedSelector == TypeReferentiel.LOCAL && this.data.fournisseurOrigine)
					//Rafraichissement du compteur des entités standard
					this.refreshReferentiel();

				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Rechargement de la page
				this.layoutService.reloadSameUrl();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.fournisseur.Fournisseur',this.data.idFournisseur);
	}

	/**
	 * Récupération des métiers d'un fournisseur
	 */
	getListeMetiers() {
		//Retour des libellés métier
		return (this.data?.listeMetiers || []).map(metier => metier.libelle).join(', ');
	}
}
