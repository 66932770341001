import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ClientCRMListItemComponent } from './client-crm-list-item.component';

@Component({
	templateUrl: './client-crm-list.component.html'
})
export class ClientCRMListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ClientCRMListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ClientCRMListItemComponent>({
			uri: `/controller/ClientCRM/filtreClientsCRM`,
			title: this.translateService.instant('clientCRM.liste.title'),
			component: ClientCRMListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				title: this.translateService.instant('clientCRM.raisonSociale'),
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'facturationId',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(i => !i.idClient) && this.liste.addItem({
					isDisplayed: true,
					actif: true
				});
			})
		});
	}
}