import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ChartService } from './chart.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'chart-edit',
	templateUrl: './chart-edit.component.html'
})
export class ChartEditComponent extends PageContentItem implements OnInit {
	/** Graphique **/
	@Input() chart: any;

	/** Enregistrement de l'objet **/
	@Input() doSaveChart: (chart: any,close?: Function,hasEntityChanged?: boolean) => void;

	/** Suppression de l'objet **/
	@Input() deleteChart: () => void;

	/** Liste des entités disponibles **/
	public listeEntites: Array<any> = [];

	/** Liste des types de diffusion **/
	public listeTypeDiffusions: Array<any> = [];

	/** Copie de l'entité **/
	private savedEntity: string;

	/**
	 * Constructeur
	 */
	constructor(private extractionService: ExtractionService,private translateService: TranslateService,private chartService: ChartService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la liste des entités
		this.extractionService.getListeEntites().subscribe(listeEntites => {
			//Mise à jour de la liste des entités
			this.listeEntites = listeEntites.filter(e => e.listeTypesDroit?.length);
		});

		//Copie de l'entité avant modifications
		this.savedEntity = this.chart.extraction?.entity || '';

		//Définition des types de diffusion
		this.listeTypeDiffusions = this.chartService.getListeTypesDiffusion();
	}

	/**
	 * Enregistrement du graphique
	 */
	public saveChart() {
		//Vérification du changement d'entité
		this.checkEntiteBeforeSave().subscribe(isChanged => {
			//Exécution de l'enregistrement du graphique
			this.doSaveChart(this.chart,this.close.bind(this),isChanged);
		});
	}

	/**
	 * Vérification du changement de l'entité
	 */
	private checkEntiteBeforeSave(): Observable<boolean> {
		//Vérification de la modification de l'entité
		if (this.chart.extraction.idExtraction && this.savedEntity && this.chart.extraction.entity != this.savedEntity) {
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('extraction.confirmation.changementEntite'),{ actionColor: 'primary' }).pipe(tap(isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Réinitialisation de l'extraction
					this.chart.extraction.listeFields = null;
					this.chart.extraction.rule = null;

					//Réinitialisation du graphique et des interactions
					this.chart.listeDefinitions = [];
				} else
					//Réinitialisation de l'entité
					this.chart.extraction.entity = this.savedEntity;
			}));
		} else
			//Retour d'un observable
			return of(false);
	}
}