import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { TypePlanning } from 'src/app/domain/planning/planning';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { environment } from 'src/environments/environment';
import { PermisConduireComponent } from './permis-conduire/permis-conduire.component';

@Injectable()
export class ConducteurService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private layoutService: LayoutService,private translateService: TranslateService,private pluralTranslatePipe: PluralTranslatePipe) { }

	/**
	 * Suppression d'un conducteur
	 */
	public deleteConducteur(idConducteur: number): Observable<Result> {
		//Suppression du conducteur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/deleteConducteur/${idConducteur}`);
	}

	/**
	 * Enregistrement d'un conducteur
	 */
	public saveConducteur(conducteur: any): Observable<Result> {
		//Enregistrement du conducteur
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/saveConducteur`,conducteur);
	}

	/**
	 * Chargement d'un conducteur
	 */
	public loadConducteur(idConducteur: number): Observable<Result> {
		//Chargement du conducteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/loadConducteur/${idConducteur}`,null);
	}

	/**
	 * Enregistrement de l'attestation de permis de conduire
	 */
	public saveAttestationPermis(attestation: any): Observable<Result> {
		//Enregistrement de l'attestation de permis de conduire
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/saveAttestationPermis`,attestation);
	}

	/**
	 * Suppression de l'attestation de permis de conduire
	 */
	public deleteAttestationPermis(attestation: any): Observable<Result> {
		//Suppression de l'attestation de permis de conduire
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/deleteAttestationPermis/${attestation.idAttestation}`);
	}

	/**
	 * Recherche du permis de conduire d'un conducteur
	 */
	public findPermisConducteur(idUser: number): Observable<Result> {
		//Recherche du permis de conduire du conducteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeConducteur/findPermisConducteur/${idUser}`,null);
	}

	/**
	 * Affichage du formulaire de permis de conduire
	 */
	showPermisConduire(permisConduire: any,readOnly: boolean = false): Observable<any> {
		let bsModalRef: BsModalRef<PermisConduireComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(PermisConduireComponent,{
			initialState: {
				permisConduire: Object.assign({},permisConduire),
				readOnly
			},
			class: 'modal-md'
		});

		//Retour du permis de conduire
		return bsModalRef.onHidden.pipe(
			map(() => bsModalRef.content.savedPermisConduire)
		);
	}

	/**
 	 * Accès au planning
	 */
	public goToPlanning(user?: any,searchSpec: { listeSelectedFilters?: Array<Filter> } = {},listeIdsUser?: Array<number>,routeData?: { initialDate?: any },typePlanning?: TypePlanning) {
		//Vérification de la présence de filtres
		if (!searchSpec?.listeSelectedFilters)
			//Initialisation de la liste de filtres
			searchSpec.listeSelectedFilters = [];

		//Vérification de la recherche
		if (listeIdsUser?.length || user) {
			//Ajout du filtre sur les identifiants
			searchSpec.listeSelectedFilters.push({
				clef: 'idUser',
				listeObjects: listeIdsUser || [user.idUser],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				isKeptForSelector: TypePlanning.VEHICULE,
				displayedValeur: user ? user?.prenom + ' ' + user?.nom + ' (' + user?.matricule + (user?.societe ? ' - ' + user.societe.libelle : '') + ')' : this.translateService.instant(this.pluralTranslatePipe.transform('user.planning.selection',listeIdsUser?.length),{ nbUsers: listeIdsUser?.length })
			});
		}

		//Itération sur les filtres sans libellé
		searchSpec?.listeSelectedFilters?.filter(f => !f.displayedValeur).forEach(f => {
			//Définition de la valeur affichée
			f.displayedValeur = this.translateService.instant(this.pluralTranslatePipe.transform(`user.planning.${f.typeComparaison == TypeComparaison.NOT_IN ? 'selection' : 'deselection'}`,f.listeObjects?.length),{ nbUsers: f.listeObjects?.length });
		});

		//Navigation vers le planning
		this.layoutService.goToByState('planning',{
			savedSearch: searchSpec,
			routeParams: {
				typePlanning: typePlanning || TypePlanning.USER
			},
			routeData,
			withGoBack: true
		});
	}
}