import { AfterViewInit,Component,OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as CodeMirror from 'codemirror';

@Component({
	templateUrl: './history-collection-change.component.html'
})
export class HistoryCollectionChangeComponent implements OnInit,AfterViewInit {
	/** Référence de l'élément qui contiendra le composant 'CodeMirror' **/
	@ViewChild('codemirror') codemirrorRef: any;

	/** Changement courant **/
	change: any;

	/** Ancien modèle **/
	private oldModel: any;

	/** Nouveau modèle **/
	private newModel: any;

	/** Options d'affichage **/
	private options: any = {
		lineNumbers: true,
		smartIndent: true,
		autofocus: true,
		theme: 'cobalt',
		mode: 'application/ld+json',
		revertButtons: false,
		showDifferences: true,
		readOnly: true,
		autoCloseBrackets: true,
		matchBrackets: true,
		gutters: ['CodeMirror-linenumbers','CodeMirror-foldgutter'],
		collapseIdentical: true,
		foldGutter: true
	};

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<HistoryCollectionChangeComponent>) {}

	/**
	 * Initialisation après chargement de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Chargement du composant 'MergeView' dans le composant 'CodeMirror'
			CodeMirror.MergeView(this.codemirrorRef.nativeElement,{
				...this.options,
				origLeft: this.oldModel,
				value: this.newModel
			});
		});
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de l'ancien modèle
		this.oldModel = this.change.oldValue?.value ? JSON.stringify(JSON.parse(this.change.oldValue?.value),null,2) : null;

		//Définition du nouveau modèle
		this.newModel = this.change.newValue?.value ? JSON.stringify(JSON.parse(this.change.newValue?.value),null,2) : null;
	}
}