import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ControleReglementaireService {
	/**
	 * Liste des types
	 */
	private readonly listeTypes: Array<string> = ['TECHNIQUE','ANTIPOLLUTION'];

	/**
	 * Liste des critères
	 */
	private readonly listeTypesCritere: Array<string> = ['MISE_EN_CIRCULATION','REALISATION_DERNIER_CONTROLE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Enregistrement du contrôle réglementaire
	 */
	public saveControleReglementaire(controleReglementaire: any): Observable<Result> {
		//Enregistrement du contrôle réglementaire
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveControleReglementaire`,controleReglementaire);
	}

	/**
	 * Chargement d'un contrôle réglementaire
	 */
	public loadControleReglementaire(idControleReglementaire: number): Observable<Result> {
		//Chargement du contrôle réglementaire
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadControleReglementaire/${idControleReglementaire}`,null);
	}

	/**
	 * Suppression du contrôle réglementaire
	 */
	public deleteControleReglementaire(idControleReglementaire: number): Observable<Result> {
		//Suppression du contrôle réglementaire
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteControleReglementaire/${idControleReglementaire}`);
	}

	/**
	 * Récupération de la liste des sous-types
	 */
	getListeTypes() {
		//Retour de la liste des types traduits
		return this.listeTypes.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.controleReglementaire.type.'+code)
		}));
	}

	/**
	 * Récupération de la liste des critères
	 */
	getListeTypesCriteres() {
		//Retour de la liste des critères traduits
		return this.listeTypesCritere.map(code => ({
			code,
			libelle: this.translateService.instant('vehicule.controleReglementaire.typeCritere.'+code)
		}));
	}
}