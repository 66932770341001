<div class="card focus">
	<div class="card-header">
		<h2 translate>complexPage.informations.title</h2>
		<ul class="actions">
			<li *ngIf="getListeVisibleActions().length == 1">
				<a [routerLink]="[]" *ngFor="let action of getListeVisibleActions()" (click)="action.doAction()">
					<icon [name]="getListeVisibleActions()[0].type == 'CONSULTATION' ? 'visibility' : getListeVisibleActions()[0].type == 'SUPPRESSION' ? 'delete' : getListeVisibleActions()[0].type == 'EDITION' ? 'edit' : getListeVisibleActions()[0].type == 'REDIRECTION' ? 'forward' : ''"></icon>
				</a>
			</li>
			<li *ngIf="getListeVisibleActions().length > 1">
				<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li *ngFor="let action of getListeVisibleActions()">
						<a [routerLink]="[]" (click)="action.doAction()" [translate]="action.libelle"></a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	<div class="card-body">
		<div *ngFor="let field of options.listeFields">
			<div class="view-group" *ngIf="(!field?.isSecondary || isDisplaySecondary) && isFieldVisible(field)">
				<label *ngIf="!field.isPersonalData"><span [translate]="field.libelle"></span></label>
				<label personalData *ngIf="field.isPersonalData"><span [translate]="field.libelle"></span></label>
				<div [ngSwitch]="field.type">
					<div *ngSwitchCase="'DATE'">{{ data | fieldKeyValue:field.key | date:(field.format == 'datetime' ? 'short' : field.format == 'time' ? 'shortTime' : 'shortDate') }}</div>
					<div *ngSwitchCase="'BOOLEAN'" [translate]="'common.'+ ((data | fieldKeyValue:field.key) ? 'oui' : 'non')"></div>
					<div *ngSwitchCase="'DISPLAY'">{{ data | fieldKeyValue:field.key | display:field.format }}</div>
					<div *ngSwitchDefault>{{ data | fieldKeyValue:field.key }}</div>
				</div>
			</div>
		</div>

		<button mat-stroked-button *ngIf="hasSecondaryFields()" color="primary" (click)="isDisplaySecondary = !isDisplaySecondary">
			<span *ngIf="!isDisplaySecondary" translate="common.moreInfo"></span>
			<span *ngIf="isDisplaySecondary" translate="common.lessInfo"></span>
		</button>
	</div>
</div>