import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConducteurService } from './conducteur.service';
import { User } from 'src/app/domain/user/user';

@Component({
	templateUrl: './conducteur-attestation-permis.component.html'
})
export class ConducteurAttestationPermisComponent extends PageContentItem implements OnInit {
	/** Attestation courante **/
	@Input() selectedAttestation: any;

	/** Suppression d'une attestation **/
	@Input() deleteAttestation: (attestation: any,close: Function) => {};

	/** Utilisateur connecté **/
	@Input() user: User;

	/** Date courante **/
	today: moment.Moment = moment.utc();

	/** Indicateur de validation de l'attestation **/
	isAttestationValidee: boolean;

	/**
	 * Constructeur
	 */
	constructor(private conducteurService: ConducteurService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la validation de l'attestation
		this.isAttestationValidee = this.selectedAttestation.idAttestation > 0;
	}

	/**
	 * Sauvegarde de l'attestation de permis de conduire
	 */
	saveAttestation() {
		//Enregistrement de l'attestation
		this.conducteurService.saveAttestationPermis(this.selectedAttestation).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de l'attestation
				Object.assign(this.selectedAttestation,result.data?.attestation);
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.date')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}