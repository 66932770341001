<div class="modal-header">
	<h4 class="modal-title" translate [translateParams]="{ date: echeance.dateEcheance | date:'MMMM yyyy' | capitalizeFirst }">contrat.financement.echeance.detail.title</h4>
</div>
<div class="modal-body">
	<list-view [liste]="liste" *ngIf="liste"></list-view>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="echeance.typeAnomalieLoyer == 'AVEC_ECART'" (click)="validate()">
		<span translate>actions.valider</span>
	</button>
</div>