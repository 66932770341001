<form #serviceDeplacementForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>service.deplacement.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group" [ngClass]="{ 'has-error': newServiceParent.invalid }">
				<label translate>service.deplacement.service</label>
				<div>
					<autocomplete name="newServiceParent" type="service" [filter]="{listeExcludedIdsService:[service.idService]}" #newServiceParent="ngModel" [(ngModel)]="options.newServiceParent" required></autocomplete>
				</div>
			</div>
			<div *ngIf="service?.extension?.nbSousServices > 0">
				<div class="row m-t-20">
					<div class="col-xs-12">
						<h4 translate>service.deplacement.typeGestionDependance.item</h4>
					</div>
				</div>
				<div class="alert alert-info">
					<icon name="info"></icon>
					<div>
						<strong translate>service.deplacement.hasDependances.title</strong>
						<div translate>service.deplacement.hasDependances.message</div>
					</div>
				</div>
				<mat-radio-group name="typeGestionDependance" [(ngModel)]="options.gestionDependances" (ngModelChange)="options.parent = null">
					<ng-container *ngFor="let typeGestionDependance of listeTypesGestionDependance">
						<mat-radio-button [value]="typeGestionDependance.code">{{ typeGestionDependance.libelle }}</mat-radio-button>
						<div class="form-group" [ngClass]="{ 'has-error': parentField.invalid }" *ngIf="typeGestionDependance.code == 'SELECTION_PARENT' && options.gestionDependances == 'SELECTION_PARENT'">
							<label translate>service.deplacement.parent</label>
							<div>
								<autocomplete name="parent" type="service" #parentField="ngModel" [(ngModel)]="options.parent" required></autocomplete>
							</div>
						</div>
					</ng-container>
				</mat-radio-group>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveServiceDeplacement()" [disabled]="serviceDeplacementForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>