import { Component } from '@angular/core';
import { switchMap,filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { EquipementAffectationService } from 'src/app/components/materiel/equipement/equipement-affectation.service';


@Component({
	templateUrl: './conducteur-equipement-list-item.component.html'
})
export class ConducteurEquipementListItemComponent extends ListViewItem<any> {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private equipementAffectationService: EquipementAffectationService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Accès au formulaire complexe de l'équipement
	 */
	goToEquipement() {
		//Redirection vers l'équipement
		this.layoutService.goToByState('materielReferentiel-listeEquipements-loadEquipement',{
			routeParams: {
				idEquipement: this.data?.equipement?.idEquipement || 0
			},
			withGoBack: true
		});
	}

	/**
	 * Suppression d'un équipement
	 */
	deleteEquipement() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.equipementAffectationService.deleteAffectation(this.data))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'affectation depuis la liste
				this.onObjectChange(null);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}