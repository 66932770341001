import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageContentComponent } from './page-content.component';
import { PageContentService } from './page-content.service';

@NgModule({
	imports: [CommonModule],
	declarations: [PageContentComponent],
	exports: [PageContentComponent],
	providers: [PageContentService]
})
export class PageContentModule { }
