'use strict';

var inherits = require('inherits'),
  AjaxBasedTransport = require('./lib/ajax-based'),
  XdrStreamingTransport = require('./xdr-streaming'),
  XhrReceiver = require('./receiver/xhr'),
  XDRObject = require('./sender/xdr');
function XdrPollingTransport(transUrl) {
  if (!XDRObject.enabled) {
    throw new Error('Transport created when disabled');
  }
  AjaxBasedTransport.call(this, transUrl, '/xhr', XhrReceiver, XDRObject);
}
inherits(XdrPollingTransport, AjaxBasedTransport);
XdrPollingTransport.enabled = XdrStreamingTransport.enabled;
XdrPollingTransport.transportName = 'xdr-polling';
XdrPollingTransport.roundTrips = 2; // preflight, ajax

module.exports = XdrPollingTransport;