import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpErrorResponse,HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveToast,ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { catchError,switchMap,take } from 'rxjs/operators';
import { race,throwError } from 'rxjs';

import { IToastButton,ActionToastComponent } from 'src/app/share/components/action-toast/action-toast.component';

@Injectable()
export class FirewallInterceptor implements HttpInterceptor {
	/** Boutons du toast **/
	listeToastButtons: Array<IToastButton>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService) {
		//Définition de la liste des boutons
		this.listeToastButtons = [{
			key: 'http.errors.406.retryAction'
		}];
	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification de la présence du header
		if (request.headers.has('X-AUTH-REQ')) {
			//Laisser passer la requête
			return next.handle(request);
		} else {
			//Vérification du retour
			return next.handle(request).pipe(catchError(error => {
				let isBlockedByBackend = false;
				let toastRef: ActiveToast<any>;
				let token: string;

				//Vérification d'un code HTTP 406
				if (error instanceof HttpErrorResponse && error.status == HttpStatusCode.NotAcceptable) {
					//Vérification d'un blockage par le backend
					isBlockedByBackend = error.headers.has('X-BLOCKED-BY-BACKEND');

					//Vérification de l'absence de blocage réalisé par le backend
					if (!isBlockedByBackend) {
						//Récupération du token d'authentification de l'utilisateur
						token = request.headers.get('X-AUTH-TOKEN');

						//Affichage du toast
						toastRef = this.toastrService.error(this.translateService.instant('http.errors.406.message'),this.translateService.instant('http.errors.406.title'),token && {
							timeOut: 10000,
							progressBar: true,
							progressAnimation: 'decreasing',
							toastComponent: ActionToastComponent,
							payload: {
								buttons: this.listeToastButtons
							}
						} || {
							timeOut: 5000
						});

						//Vérification du mode connecté
						if (token) {
							//Attente du résultat de la course entre le retry de l'action ou le masquage du toast
							return race(toastRef.onAction,toastRef.onHidden).pipe(
								take(1),
								switchMap((input: IToastButton | void) => {
									//Vérification du bouton
									if (input && input.key === 'http.errors.406.retryAction')
										//Modification du header pour rejouer la requête
										return next.handle(this.setHeader(request,'X-AUTH-REQ',token));
									else
										//Retour de la réponse en erreur
										return throwError(error);
								}
							));
						}
					}
				}

				//Retour de la réponse en erreur
				return throwError(error);
			}));
		}
	}

	/**
	 * Ajout d'un header dans une requête
	 */
	private setHeader(request: HttpRequest<any>,headerName: string,headerValue: string) {
		//Clonage de la requête
		return request.clone({
			headers: request.headers.set(headerName,headerValue)
		});
	}
}