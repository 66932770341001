import { Component,Input } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { Droit } from 'src/app/domain/droit/droit';
import { TypeGroupe } from 'src/app/domain/security/right';

@Component({
	selector: 'compte-service-droit',
	templateUrl: './compte-service-droit.component.html'
})
export class CompteServiceDroitComponent extends PageContentItem {
	/** Elément courant **/
	@Input() compteService: any;

	/** Enregistrement de l'objet **/
	@Input() saveCompteService: (compteService: any,close?: Function) => void;

	/** Type de groupe **/
	TypeGroupe: typeof TypeGroupe = TypeGroupe;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Enregistrement des droits
	 */
	onSave(event: { listeDroits: Array<Droit>,close: Function }) {
		let compteService: any;

		//Duplication du compte de service
		compteService = cloneDeep(this.compteService);

		//Définition des nouveaux droits
		compteService.listeDroits = event?.listeDroits;

		//Enregistrement des droits
		this.saveCompteService(compteService,event?.close);
	}
}