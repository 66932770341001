import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { NatureService } from './nature.service';

@Component({
	selector: 'nature-edit',
	templateUrl: './nature-edit.component.html'
})
export class NatureEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() nature: any;

	/** Suppression de l'objet **/
	@Input() deleteNature: () => void;

	/** Liste des familles de nature */
	public listeTypesFamille: Array<string>;

	/** Récupération de la liste des types de liens traduits */
	public listeTypesLien: Array<string>;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private natureService: NatureService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la liste des familles de nature
		this.listeTypesFamille = this.natureService.getListeTypesFamille();

		//Récupération de la liste des types de liens
		this.listeTypesLien = this.natureService.getListeTypesLien();

		//Copie de la nature
		this.nature = cloneDeep(this.nature);
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveNature() {
		//Enregistrement de la nature
		this.natureService.saveNature(this.nature).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.nature,result.data?.nature);

					//Fermeture du formulaire
					this.close({ nature: this.nature,nbFournisseurs: result?.data?.nbFournisseurs });
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Récupération du libellé du métier
	 */
	getLibelleMetier: (metier: any) => string = (metier) => metier.libelle;

	/**
	 * Vérification de la validité de la liste des métiers
	 */
	isListeMetiersInvalid: () => boolean = () => !this.nature.listeMetiers?.length;

	/**
	 * Ajout d'un métier au panier
	 */
	onAddMetier() {
		//Parcours des métiers sélectionnés
		this.nature.listeMetiers.forEach(metier => {
			//Définition du type d'entité pour la désérialisation JSON
			metier['_type'] = 'com.notilus.data.fournisseur.FournisseurMetier';
		});
	}
}