<div class="modal-header">
	<h4 class="modal-title">{{ options.title }}</h4>
</div>
<div class="modal-body p-0 m-0">
	<div class="advanced-search">
		<div class="alerte" [ngClass]="{ 'searching': isSearching }">
			<div class="result-counter"><span class="counter">{{ options.currentHits }}</span>&nbsp;<span>{{ options.results }}</span>&nbsp;<span>{{ options.totalHits }}</span></div>
			<div class="subtitle"><span>{{ options.subTitle }}</span></div>
		</div>
		<div class="liste-sections">
			<div class="section" *ngFor="let filtre of options.listeFiltres; let idxFilter = index;" [ngClass]="{ 'filtered': filtre.listeSelectedValues?.length || filtre.sliderOptions?.min != filtre.sliderOptions?.options.floor || filtre.sliderOptions?.max != filtre.sliderOptions?.options.ceil }">
				<div class="header" (click)="toggleFiltre(filtre);">
					<span>{{ filtre.libelleSection }}<span *ngIf="canShowComplementaryLabel(filtre)">{{ ' - ' + ('searchEngine.section.' + filtre.subType | translate) }}</span></span>
					<div class="end">
						<div class="badge" *ngIf="countUsedValuesForFilter(filtre) > 0 " (click)="clearFiltre(filtre);">{{ countUsedValuesForFilter(filtre) }}</div>
						<a [routerLink]="[]">
							<icon [name]="!filtre.isToggled ? 'chevron_right' : 'expand_more'"></icon>
						</a>
					</div>
				</div>
				<div class="row collapse" [collapse]="!filtre.isToggled">
					<div class="content form-group" *ngIf="filtre.type == 'boolean'">
						<div class="boolean-button">
							<mat-button-toggle-group name="boolean" [value]="filtre.listeSelectedValues?.[0]">
								<mat-button-toggle (change)="selectValue(filtre,true)" [value]="true">{{ 'common.oui' | translate }}</mat-button-toggle>
								<mat-button-toggle (change)="selectValue(filtre,false)" [value]="false">{{ 'common.non' | translate }}</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</div>
					<div class="content form-group" *ngIf="filtre.type == 'label'">
						<mat-chip-listbox multiple>
							<mat-chip-option class="clickable" color="primary" *ngFor="let value of filtre.listeValues" (selectionChange)="selectValue(filtre,value)" (click)="value.selected = !value.selected" [selected]="value.selected">
								{{ filtre.getLibelle?.(value) || value.libelle }}
							</mat-chip-option>
						</mat-chip-listbox>
					</div>
					<div class="content form-group" *ngIf="filtre.type == 'numeric'">
						<div class="optional-button" *ngIf="filtre.listeKeys?.length">
							<mat-button-toggle-group name="numeric" [value]="filtre.subType" [ngStyle]="{ width: '100%',flex: filtre.listeKeys.length }">
								<mat-button-toggle *ngFor="let item of filtre.listeKeys" (change)="onCriteriaItemChange(filtre,item)" [value]="item.subType">{{ 'searchEngine.section.' + item.subType | translate }}</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
						<div class="slider" *ngIf="filtre.isToggled && filtre.sliderOptions && filtre.sliderOptions?.min !== undefined && filtre.sliderOptions?.max !== undefined">
							<div class="slider-data">
								<mat-form-field>
									<mat-label>
										<span *ngIf="!filtre.fromLabel" translate>searchEngine.numeric.from</span>
										<span *ngIf="filtre.fromLabel">{{ filtre.fromLabel }}</span>
									</mat-label>
									<input matInput type="text" [name]="'slider_min_'+idxFilter" nNumber [(ngModel)]="filtre.sliderOptions.min" (blur)="onSliderOptionBlur('min',filtre)"/>
									<div *ngIf="filtre.sliderOptions.isCustomMinimum && filtre.sliderOptions?.min == filtre.sliderOptions?.options?.floor" matTextSuffix translate>searchEngine.slider.lessValue</div>
								</mat-form-field>
								<mat-form-field>
									<mat-label>
										<span *ngIf="!filtre.toLabel" translate>searchEngine.numeric.to</span>
										<span *ngIf="filtre.toLabel">{{ filtre.toLabel }}</span>
									</mat-label>
									<input matInput type="text" [name]="'slider_max_'+idxFilter" nNumber [(ngModel)]="filtre.sliderOptions.max" (blur)="onSliderOptionBlur('max',filtre)"/>
									<div *ngIf="filtre.sliderOptions.isCustomMaximum && filtre.sliderOptions?.max == filtre.sliderOptions?.options?.ceil" matTextSuffix translate>searchEngine.slider.moreValue</div>
								</mat-form-field>
							</div>
							<mat-slider [min]="filtre.sliderOptions?.options?.floor" [max]="filtre.sliderOptions?.options?.ceil" [discrete]="true">
								<input matSliderStartThumb [name]="'slider_min_'+idxFilter" [(ngModel)]="filtre.sliderOptions.min" (valueChange)="loadData()">
								<input matSliderEndThumb [name]="'slider_max_'+idxFilter" [(ngModel)]="filtre.sliderOptions.max" (valueChange)="loadData()">
							</mat-slider>
						</div>
					</div>
					<div class="container-fluid" *ngIf="filtre.type == 'autocomplete'">
						<basket colWidthAutocomplete="6" [options]="filtre.options" [model]="filtre" propertyName="listeSelectedValues" [displayItem]="filtre.displayBasketItem" [labelAutocomplete]="filtre.labelAutocomplete" [labelListe]="filtre.labelListe" [getKey]="filtre.getKey" [filter]="filtre.filter" (onAdd)="onAutocompleteItemAdded()" (onRemove)="onAutocompleteItemRemoved()"></basket>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="close();">
		<span translate>actions.fermer</span>
	</button>
	<button mat-stroked-button color="primary" [disabled]="!hasOneFilterSelected()" (click)="resetListeFiltres();">
		<span translate>actions.reinitialiser</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="!options.isAllowNoneFilters && !hasOneFilterSelected() || options.currentHits == 0" (click)="display()">
		<span translate>actions.afficher</span>
		<span *ngIf="options.isAllowNoneFilters || hasOneFilterSelected()">&#160;(<span>{{ options.currentHits }}</span>)</span>
	</button>
</div>