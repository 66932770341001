import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter,first } from 'rxjs/operators';

import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ControleReglementaireService } from 'src/app/components/vehicule/controle-reglementaire/controle-reglementaire.service';
import { AppState } from 'src/app/domain/appstate';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { User } from 'src/app/domain/user/user';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienListItemComponent } from './entretien-list-item.component';
import { mapEntites } from './entretien.entites';
import { EntretienService } from './entretien.service';

@Component({
	templateUrl: './entretien-list.component.html'
})
export class EntretienListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,EntretienListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Présence d'imports **/
	hasImports: boolean = false;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private translateService: TranslateService,private rightService: RightService,public dashboardService: DashboardService,private entretienService: EntretienService,public extractionService: ExtractionService,private controleReglementaireService: ControleReglementaireService,public connecteurService: ConnecteurService) { }

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),first()).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,EntretienListItemComponent>({
					uri: `/controller/Entretien/filtreEntretiens`,
					title: this.translateService.instant('entretien.liste.title'),
					component: EntretienListItemComponent,
					isTile: true,
					listeFilters: [{
						clef: 'vehicule',
						type: TypeFilter.AUTOCOMPLETE,
						autocomplete: {
							type: 'vehicule',
							filter: 'VEHICULE_FONCTION_SERVICE'
						},
						isDefault: true
					},{
						clef: '*fournisseur.libelle',
						isDefault: true
					},{
						clef: 'type',
						type: TypeFilter.LONG,
						listeValues: this.entretienService.getListeTypesEntretien()
					},{
						clef: 'sousType',
						title: this.translateService.instant('vehicule.controleReglementaire.sousType'),
						type: TypeFilter.LONG,
						listeValues: this.controleReglementaireService.getListeTypes()
					},{
						clef: 'datePrevisionnelle',
						type: TypeFilter.DATE
					},{
						clef: 'dateRealisation',
						type: TypeFilter.DATE
					},{
						clef: 'isPrevisionnel',
						type: TypeFilter.BOOLEAN
					}],
					noSearch: this.user.type == 'VIRTUAL',
					isLoadingDisabled: this.user.type == 'VIRTUAL',
					defaultOrder: '-isPrevisionnel,-dateRealisation,datePrevisionnelle,vehicule.reference,type',
					getKeyFieldName: () => 'idEntretien',
					doMainAction: this.user?.type != 'VIRTUAL' && this.rightService.hasRight(null,'creation') && (() => {
						//Ajout d'un nouvel élément
						!this.liste.data?.content?.some(p => !p.idEntretien) && this.liste.addItem({
							isDisplayed: true,
							typeSource: 'COLLABORATEUR'
						});
					})
				});

				//Vérification du type d'utilisateur
				if (this.user?.type != 'VIRTUAL') {
					//Vérification de la présence d'un dashboard
					this.dashboardService.hasDashboard().subscribe({
						next: (hasDashboard: boolean) => {
							//Définition de la présence d'un dashboard
							this.hasDashboard = hasDashboard;
						}
					});
				} else if (this.user?.externalAction?.idEntite) {
					//Chargement de l'entretien
					this.entretienService.loadEntretien(this.user.externalAction.idEntite).pipe(first()).subscribe({
						next: result => {
							//Définition de la liste
							this.liste.data = {
								content: [Object.assign(result.data.entretien,{
									isDisplayed: true
								})]
							};
						}
					});
				}

				//Vérification de la présence d'imports
				this.rightService.hasRight(null,'creation') && this.connecteurService.isImportAvailable().subscribe({
					next: (isImportAvailable: boolean) => {
						//Définition de la présence d'imports
						this.hasImports = isImportAvailable;
					}
				});
			}
		});
	}
}