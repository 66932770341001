import { NgModule } from '@angular/core';

import { CoreComponentsModule } from '../core-components.module';
import { DatePickerModule } from 'src/app/share/directive/date-picker/date-picker.module';
import { TemporalSelectorComponent } from './temporal-selector.component';
import { TemporalSelectorService } from './temporal-selector.service';
import { RollingPeriodSelectorComponent } from './rolling-period-selector.component';
import { RollingPeriodSelectorService } from './rolling-period-selector.service';
import { NumberModule } from 'src/app/share/directive/number/number.module';
import { SelectPickerModule } from 'src/app/share/components/select-picker/select-picker.module';

@NgModule({
	imports: [CoreComponentsModule,DatePickerModule,NumberModule,SelectPickerModule],
	declarations: [TemporalSelectorComponent,RollingPeriodSelectorComponent],
	exports: [TemporalSelectorComponent,RollingPeriodSelectorComponent],
	providers: [TemporalSelectorService,RollingPeriodSelectorService]
})
export class PopoverSelectorModule {}