import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeAmenagementService } from './type-amenagement.service';

@Component({
	templateUrl: './type-amenagement-add.component.html'
})
export class TypeAmenagementAddComponent extends AutocompleteAddComponent {
	/** Type d'aménagement **/
	typeAmenagement: any = {
		actif: true
	};

	/** Taille de la popup **/
	public static modalSize: string = '';

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private typeAmenagementService: TypeAmenagementService,public bsModalRef: BsModalRef) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveItem() {
		//Enregistrement de l'objet
		this.typeAmenagementService.saveTypeAmenagement(this.typeAmenagement).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.typeAmenagement,result.data?.typeAmenagement);

					//Mise à jour de l'objet ajouté
					this.addedItem = this.typeAmenagement;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Vérification de la référence
						if (result.data.doublon & Math.pow(2,1))
							//Ajout du libellé
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}