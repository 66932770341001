import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ProfilUserListItemComponent } from './profil-user-list-item.component';

@Component({
	selector: 'profil-user-list',
	templateUrl: './profil-user-list.component.html'
})
export class ProfilUserListComponent extends PageContentItem implements OnInit {
	/** Profil **/
	@Input() profil: any;

	/** Résumé **/
	@Input() resume: { nbUsers: number };

	/** Données **/
	liste: ListView<any,ProfilUserListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ProfilUserListItemComponent>({
			uri: () => `/controller/Profil/filtreUsers/${this.profil.idProfil}`,
			component: ProfilUserListItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'nom',
				isDefault: true
			},{
				clef: 'prenom',
				isDefault: true
			},{
				clef: 'matricule',
				isDefault: true
			}],
			listeStaticFilters: () => [this.profil.typeProfil == 'MOBILE' && {
				clef: 'listeUserProfil.isActif',
				valeur: true,
				type: TypeFilter.BOOLEAN,
				typeComparaison: TypeComparaison.EQUAL
			}].filter(f => !!f),
			defaultOrder: 'nom,prenom,matricule'
		});
	}
}