import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ProgressErreurComponent } from './progress-erreur.component';
import { ProgressService } from './progress.service';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ProgressModalComponent } from './progress-modal.component';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule,PleaseWaitModule],
	declarations: [ProgressErreurComponent,ProgressModalComponent],
	providers: [ProgressService]
})
export class ProgressModule {}