import { Component,Input,OnInit } from '@angular/core';

import { FormCustomization } from 'src/app/domain/common/entite/form-customization';
import { Result } from 'src/app/domain/common/http/result';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { FormCustomizationEditComponent } from './form-customization-edit.component';
import { FormCustomizationService } from './form-customization.service';
import { EntiteService } from '../entite.service';

@Component({
	selector: 'form-customization',
	templateUrl: './form-customization.component.html'
})
export class FormCustomizationComponent implements OnInit {
	/** Données **/
	@Input() data: any;

	/** Nom de la classe **/
	className: string;

	/** Identifiant de l'objet **/
	idObject: number;

	/** Fiche personalisée **/
	formCustomization: FormCustomization = null;

	/** Affichage de  plus d'informations **/
	isShowMore: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private formCustomizationService: FormCustomizationService,private pageContentService: PageContentService,public entiteService: EntiteService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		let routeComponent: any;

		//Récupération de l'instance du composant de la route courante
		routeComponent = this.layoutService.getRouteComponentInstance();

		//Vérification du type de l'objet instancié dans la route
		if (this.entiteService.isIEntity(routeComponent)) {
			//Récupération de la classe et l'identifiant de l'objet
			this.className = routeComponent.getClassName();
			this.idObject = routeComponent.getIdObject();

			//Chargement de la fiche personalisée
			this.formCustomizationService.loadFormCustomization(this.className,this.idObject).subscribe({
				next: (result: Result) => {
					//Définition de la fiche personnalisée
					this.formCustomization = result.data.entiteCustomization;
				}
			});
		} else
			//Génération d'une erreur
			throw `Impossible de récupérer la classe et l'identifiant de l'objet`;
	}

	/**
	 * Edition de la fiche personnalisée
	 */
	editFormCustomization() {
		//Ouverture du mode édition de la fiche personalisée
		this.pageContentService.open(FormCustomizationEditComponent,{
				data: {
					data: this.data,
					formCustomization: this.formCustomization,
					className: this.className,
					idObject: this.idObject
				}
			},'sub').subscribe({
				next: (formCustomization) => {
					//Vérification de la présence d'un résultat
					if (formCustomization) {
						//Définition de la fiche personalisée mise à jour
						this.formCustomization = formCustomization;

						//Rechargement de la fiche personalisée
						this.formCustomizationService.loadFormCustomization(this.className,this.idObject).subscribe({
							next: (result: Result) => {
								//Définition de la fiche personnalisée
								this.formCustomization = result.data.entiteCustomization;
							}
						});
					}
				}
			});
	}
}
