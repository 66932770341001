import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { PleaseWaitService } from 'src/app/share/components/please-wait/please-wait.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { PaysListItemComponent } from './pays-list-item.component';
import { PaysService } from './pays.service';

@Component({
	templateUrl: './pays-list.component.html'
})
export class PaysListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,PaysListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private pleaseWaitService: PleaseWaitService,private paysService: PaysService,private toastrService: ToastrService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PaysListItemComponent>({
			uri: `/controller/Geographie/filtrePays`,
			title: this.translateService.instant('pays.liste.title'),
			component: PaysListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'mapLang.*.libelle',
				isDefault: true
			},{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'code2',
				isDefault: true
			},{
				clef: 'code3',
				isDefault: true
			}],
			defaultOrder: 'mapLang.*.libelle,libelle.raw,code2,code3',
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idPays) && this.liste.addItem({
					isDisplayed: true
				});
			})
		});
	}

	/**
	 * Synchronisation du référentiel
	 */
	synchronize() {
		let bsModalRef: BsModalRef;

		//Affichage de la popup d'attente
		bsModalRef = this.pleaseWaitService.show();

		//Synchronisation du référentiel
		this.paysService.synchronize().pipe(first()).subscribe({
			next: result => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.synchronisation.success'));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.synchronisation.error'));
				}
			},
			error: () => {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.synchronisation.error'));
			},
			complete: () => bsModalRef.hide()
		})
	}
}