import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ContratCRMListItemComponent } from './contrat-crm-list-item.component';
import { ContratCRMListComponent } from './contrat-crm-list.component';
import { ContratCRMService } from './contrat-crm.service';
import { ContratCRMComponent } from './contrat-crm.component';
import { ContratCRMEditComponent } from './contrat-crm-edit.component';
import { ContratCRMOffreListComponent } from './contrat-crm-offre-list.component';
import { ContratCRMOffreListItemComponent } from './contrat-crm-offre-list-item.component';
import { ContratCRMOffreEditComponent } from './contrat-crm-offre-edit.component';
import { ContratCRMResiliationComponent } from './contrat-crm-resiliation.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ContratCRMListComponent,ContratCRMListItemComponent,ContratCRMComponent,ContratCRMEditComponent,ContratCRMOffreListComponent,ContratCRMOffreListItemComponent,ContratCRMOffreEditComponent,ContratCRMResiliationComponent],
	providers: [ContratCRMService]
})
export class ContratCRMModule { }
