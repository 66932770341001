import { FormatWidth } from '@angular/common';

/**
 * Référentiel de formats de date
 */
export const mapDatePatternFormats = {
	'fr-FR': {
		[FormatWidth.Short]: {
			'datetime': 'DD/MM/YYYY HH:mm',
			'date': 'DD/MM/YYYY',
			'time': 'HH:mm',
			'month': 'MMMM YYYY',
			'year': 'YYYY'
		},
		pickerFormats: [{
			name: 'date',
			dateFormats: {
				day: {
					L: 'dd/MM/yyyy'
				},
				month: {
					L: 'MM/yyyy'
				},
				year: {
					L: 'yyyy'
				}
			},
			format: 'L'
		},{
			name: 'datetime',
			dateFormats: {
				day: {
					L: 'dd/MM/yyyy',
					LT: 'HH:mm'
				},
				month: {
					L: 'MM/yyyy',
					LT: 'HH:mm'
				},
				year: {
					L: 'yyyy',
					LT: 'HH:mm'
				}
			},
			format: 'L LT'
		},{
			name: 'time',
			dateFormats: {
				day: {
					LT: 'HH:mm'
				},
				month: {
					LT: 'HH:mm'
				},
				year: {
					LT: 'HH:mm'
				}
			},
			format: 'LT'
		}]
	}
}