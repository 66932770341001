import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'vehicule-releve-energie-edit',
	templateUrl: './vehicule-releve-energie-edit.component.html'
})
export class VehiculeReleveEnergieEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() releveEnergie: any;

	/** Suppression du relevé d'énergie **/
	@Input() deleteReleveEnergie: (releveEnergie: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}