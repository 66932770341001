import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpErrorResponse,HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BadRequestInterceptor implements HttpInterceptor {
	/**
	 * Constructeur
	 */
	constructor(private toasterService: ToastrService,private translateService: TranslateService) {

	}

	/**
	 * Interception d'une requête HTTP
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification du code http 400
		return next.handle(request).pipe(catchError(error => {
			//Vérification d'un code HTTP 400
			if (error instanceof HttpErrorResponse && error.status == HttpStatusCode.BadRequest)
				//Affichage du toaster
				this.toasterService.error(this.translateService.instant('http.errors.400.message'));

			//Retour de la réponse en erreur
			return throwError(error);
		}));
	}
}