import { NgModule } from '@angular/core';

import { CoreComponentsModule } from '../core-components.module';
import { ImageEditorService } from './image-editor.service';
import { ImageEditorComponent } from './image-editor.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [ImageEditorComponent],
	exports: [ImageEditorComponent],
	providers: [ImageEditorService]
})
export class ImageEditorModule {}