import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { CoursModule } from './cours/cours.module';
import { DeviseListItemComponent } from './devise-list-item.component';
import { DeviseListComponent } from './devise-list.component';
import { DeviseComponent } from './devise.component';
import { DeviseService } from './devise.service';

@NgModule({
	imports: [ShareModule,CoursModule],
	exports: [CoursModule],
	declarations: [DeviseListComponent,DeviseListItemComponent,DeviseComponent],
	providers: [DeviseService]
})
export class DeviseModule { }