import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { FournisseurService } from './fournisseur.service';

@Component({
	templateUrl: './fournisseur-contact-list-item.component.html'
})
export class FournisseurContactListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private fournisseurService: FournisseurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteContact = this.deleteContact.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteContact(contact: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.fournisseurService.deleteContact(contact))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				this.onObjectChange(null);

				//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
				close?.(null);
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.fournisseur.Contact',this.data.idContact);
	}
}