import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des réponses à une cotation
 */
export default class ReponseCotationOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.reponseCotation';

	/**
	 * Récupération de l'URL
	 */
	url(): string {
		//URL
		return `/controller/VehiculeCommande/filtreReponsesCotation`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) { return item?.cotation ? `${item.cotation.modele.marque.libelle} ${item.cotation.modele.libelle}` : ''; }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.cotation?.modele?.libelle?.substring?.(0,3) || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.vehicule.cotation.ReponseCotation';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idReponseCotation'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'cotation.modele.libelle',
			isDefault: true
		},{
			clef: 'cotation.modele.marque.libelle',
			isDefault: true
		}];
	}
}