import { NextObserver,Observable,Subscription } from 'rxjs';

import { Result } from '../common/http/result';
import { Message } from './messaging';

/**
 * Modèle permettant de souscrire aux observables d'une websocket
 */
export class MessagingObservables {
	/** Liste des souscriptions sur les observables **/
	private listeSubscriptions: Array<Subscription>;

	/**
	 * Constructeur
	 */
	constructor(private result$: Observable<Result>,private start$: Observable<string>,private message$: Observable<any>,private error$: Observable<any>,private finish$: Observable<Message>) {
		//Initialisation de la liste des souscriptions
		this.listeSubscriptions = new Array<Subscription>();
	}

	/**
	 * Souscription sur 'onResult' (réponse de la requête HTTP du traitement)
	 */
	public onResult(onResultObserver: NextObserver<Result>): MessagingObservables {
		//Souscription
		this.listeSubscriptions.push(this.result$.subscribe(onResultObserver));

		//Retour de l'instance
		return this;
	}

	/**
	 * Souscription sur 'onStart' (démarrage du traitement)
	 */
	public onStart(onStartObserver: NextObserver<string>): MessagingObservables {
		//Souscription
		this.listeSubscriptions.push(this.start$.subscribe(onStartObserver));

		//Retour de l'instance
		return this;
	}

	/**
	 * Souscription sur 'onMessage' (message du traitement)
	 */
	public onMessage(onMessageObserver: NextObserver<any>): MessagingObservables {
		//Souscription
		this.listeSubscriptions.push(this.message$.subscribe(onMessageObserver));

		//Retour de l'instance
		return this;
	}

	/**
	 * Souscription sur 'onError' (erreur du traitement)
	 */
	public onError(onErrorObserver: NextObserver<any>): MessagingObservables {
		//Souscription
		this.listeSubscriptions.push(this.error$.subscribe(onErrorObserver));

		//Retour de l'instance
		return this;
	}

	/**
	 * Souscription sur 'onFinish' (fin du traitement)
	 */
	public onFinish(onFinishObserver: NextObserver<Message>): MessagingObservables {
		//Souscription
		this.listeSubscriptions.push(this.finish$.subscribe(onFinishObserver));

		//Retour de l'instance
		return this;
	}

	/**
	 * Fermeture des souscriptions
	 */
	public unsubscribe(): MessagingObservables {
		//Parcours de la liste des souscriptions
		this.listeSubscriptions.forEach((subscription) => {
			//Fermeture de la souscription
			subscription.unsubscribe();
		})

		//Retour de l'instance
		return this;
	}
}