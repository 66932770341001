<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<span>{{ ('facture.type.'+data.type | translate).substring(0,1) }}</span>
</div>
<div class="lv-title">
	<a [routerLink]="[]" (click)="showFacture(data.idFacture)" [translateParams]="{ type: ('facture.type.'+data.type | translate),numero: data.reference,fournisseur: data.fournisseur.libelle }" translate>comptabilite.lotComptable.facture.title</a>
</div>
<ul class="lv-attrs">
	<li><span translate>facture.date.item</span>&#160;:&#160;<span>{{ data.date | date:'shortDate' }}</span></li>
	<li><span translate>facture.montant</span>&#160;:&#160;<span>{{ data.montant | currency:'.2-2':data.devise }}</span></li>
</ul>

<div class="lv-actions actions dropdown" *ngIf="data?.idFacture && !liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="(null | right:'suppression') && !extraOptions.restriction.isRestricted"><a [routerLink]="[]" (click)="removeFacture()" translate>actions.retirer</a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>