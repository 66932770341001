import { Component,OnInit,Input,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Step } from 'src/app/domain/common/stepper/step';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { ImmatriculationModeleCatalogueSelectionChoixComponent } from './immatriculation-modele-catalogue-selection-choix.component';
import { ImmatriculationModeleCatalogueSelectionModeleListComponent } from './immatriculation-modele-catalogue-selection-modele-list.component';
import { ImmatriculationModeleCatalogueSelectionResumeComponent } from './immatriculation-modele-catalogue-selection-resume.component';

@Component({
	templateUrl: './immatriculation-modele-catalogue-selection.component.html'
})
export class ImmatriculationModeleCatalogueSelectionComponent implements OnInit {
	/** Certificat d'immatriculation **/
	@Input() immatriculation: any;

	/** Modèle initial **/
	@Input() modeleInitial: any;

	/** Type de sélection du modèle **/
	public selectedChoix: { type: 'CONSERVATION_MODELE' | 'CHANGEMENT_MODELE' } = {
		type: null
	};

	/** Modèle choisi **/
	public selectedModele: any = null;

	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ImmatriculationModeleCatalogueSelectionComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'CHOIX',
			libelle: 'vehicule.immatriculation.selectionModeleCatalogue.step.choix.item',
			component: ImmatriculationModeleCatalogueSelectionChoixComponent,
			retrieveComponentData: () => ({
				selectedChoix: this.selectedChoix,
				onChoixSelected: typeChoix => {
					//Définition du choix effectué
					this.selectChoix(typeChoix);
				}
			}),
			canActivate: () => true,
			isDone: () => !!this.selectedChoix.type
		},{
			type: 'MODELE',
			libelle: 'vehicule.immatriculation.selectionModeleCatalogue.step.modele.item',
			component: ImmatriculationModeleCatalogueSelectionModeleListComponent,
			retrieveComponentData: () => ({
				immatriculation: this.immatriculation,
				selectedModele: this.selectedModele,
				stepperComponent: this.stepperComponent,
				onModeleSelected: modele => {
					//Sélection du modèle
					this.selectModele(modele);
				}
			}),
			canActivate: () => !!this.selectedChoix.type,
			isDone: () => !!this.selectedModele
		},{
			type: 'RECAPITULATIF',
			libelle: 'vehicule.immatriculation.selectionModeleCatalogue.step.recapitulatif.item',
			component: ImmatriculationModeleCatalogueSelectionResumeComponent,
			retrieveComponentData: () => ({
				immatriculation: this.immatriculation,
				modeleInitial: this.modeleInitial,
				selectedChoix: this.selectedChoix,
				selectedModele: this.selectedModele
			}),
			canActivate: () => !!this.selectedModele,
			isDone: () => false
		}];
	}

	/**
	 * Sélection d'un type de choix
	 */
	private selectChoix(typeChoix: 'CONSERVATION_MODELE' | 'CHANGEMENT_MODELE') {
		//Définition du choix effectué
		this.selectedChoix.type = typeChoix;

		//Vérification du choix effectué
		if (typeChoix == 'CONSERVATION_MODELE') {
			//Sélection du modèle initial et accès au récapitulatif
			this.selectModele(this.modeleInitial);
		} else if (typeChoix == 'CHANGEMENT_MODELE') {
			//Remise à zéro du modèle sélectionné
			this.selectedModele = null;

			//Passage à l'étape suivante
			this.stepperComponent.selectStep(this.listeSteps[1]);
		}
	}

	/**
	 * Sélection d'un modèle
	 */
	private selectModele(modele: any) {
		//Définition du modèle sélectionné
		this.selectedModele = modele;

		//Vérification du modèle sélectionné
		if (modele)
			//Passage à l'étape de récapitulatif
			this.stepperComponent.selectStep(this.listeSteps[2]);
		else
			//Modification du choix effectué
			this.selectChoix('CHANGEMENT_MODELE');
	}
}