import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ArticleCRMListComponent } from './article-crm-list.component';
import { ArticleCRMListItemComponent } from './article-crm-list-item.component';
import { ArticleCRMService } from './article-crm.service';
import { ArticleCRMComponent } from './article-crm.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ArticleCRMListComponent,ArticleCRMListItemComponent,ArticleCRMComponent],
	providers: [ArticleCRMService]
})
export class ArticleCRMModule {

}