import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { TaxeService } from './taxe.service';

@Component({
	templateUrl: './taxe-list-item.component.html'
})
export class TaxeListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private taxeService: TaxeService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private decimalPipe: DecimalPipe) {
		//Héritage
		super();
	}

	/**
	 * Suppression de la taxe
	 */
	deleteTaxe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de la taxe
					this.taxeService.deleteTaxe(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de la taxe
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.taxe.Taxe',this.data.idTaxe);
	}

	/**
	 * Récupération du libellé des taux
	 */
	getListeTauxAsString() {
		//Retour de la liste des libellés des taux
		return this.data.listeTaux?.map(t => this.decimalPipe.transform(t.taux,'.2-2') + '%').join(', ');
	}
}