<div class="modal-header">
	<h4 class="modal-title" translate>progress.title</h4>
</div>
<div class="modal-body progress-modal">
	<div class="listview" *ngIf="progressConfig">
		<div class="lv-body">
			<div class="lv-item">
				<div class="pull-left lv-avatar">
					<icon [name]="progressConfig.icone"></icon>
				</div>
				<div class="media-body">
					<div class="lv-title">
						<span class="pull-right" *ngIf="progressConfig.nbTotal">
							<div class="label label-info">{{ progressConfig?.nbProcessed + '/' + progressConfig.nbTotal }}</div>
						</span>
						<span class="pull-right" *ngIf="!progressConfig.nbTotal">
							<please-wait size="hc-md"></please-wait>
						</span>
						<span>{{ progressConfig?.libelle }}</span>
					</div>
					<div *ngIf="progressConfig.nbTotal">
						<div class="progress m-t-10">
							<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': (progressConfig.nbTotal > 0 ? progressConfig.nbProcessed / progressConfig.nbTotal * 100 : 0) + '%' }"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clearfix"></div>
	</div>
	<div class="text-center p-10" *ngIf="!progressConfig">
		<h5 translate>liste.empty</h5>
	</div>
	<div *ngIf="progressConfig?.listeMessages?.length" class="log">
		<ul>
			<li *ngFor="let message of progressConfig.listeMessages">{{ message }}</li>
		</ul>
	</div>
</div>
<div class="modal-footer">
	<button mat-button *ngIf="progressConfig.options.canDismiss" (click)="close()">
		<span translate>actions.reduire</span>
	</button>
</div>