import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientCRMService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Enregistrement d'un client CRM
	 */
	public saveClientCRM(clientCRM: any): Observable<Result> {
		//Enregistrement du client CRM
		return this.http.put<Result>(`${environment.baseUrl}/controller/ClientCRM/saveClientCRM`,clientCRM);
	}

	/**
	 * Suppression d'un client CRM
	 */
	public deleteClientCRM(clientCRM: any): Observable<Result> {
		//Suppression du client CRM
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ClientCRM/deleteClientCRM/${clientCRM.idClient}`);
	}
}