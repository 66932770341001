<div style="display: none;">
	<div class="tooltip-inner">
		<div><strong translate>contrat.financement.chart.legende.date</strong>&#160;:&#160;<span>{{ data.metadata.dateEcheance | date:'shortDate' }}</span></div>
		<ng-container *ngIf="data.metadata.unite == 'DISTANCE'">
			<div *ngIf="data.metadata.distancePrevisionnelleCumul > 0 && !data.metadata.distanceEstimeeCumul"><strong translate>contrat.financement.chart.legende.previsionnel</strong>&#160;:&#160;<span>{{ data.metadata.distancePrevisionnelleCumul | number:'.0-0' }}</span></div>
			<div *ngIf="data.metadata.distanceEstimeeCumul > 0"><strong translate>contrat.financement.chart.legende.estimation</strong>&#160;:&#160;<span>{{ data.metadata.distanceEstimeeCumul | number:'.0-0' }}</span></div>
			<div *ngIf="data.metadata.distancePrevisionnelleCumul <= 0 && data.metadata.distanceEstimeeCumul == 0"><strong translate>contrat.financement.chart.legende.reel</strong>&#160;:&#160;<span>{{ data.metadata.distanceReelleCumul | number:'.0-0' }}</span></div>
			<div><strong translate>contrat.financement.chart.legende.contrat</strong>&#160;:&#160;<span>{{ data.metadata.distanceTheoriqueCumul | number:'.0-0' }}</span></div>
			<div><strong translate>contrat.financement.chart.legende.ecart</strong>&#160;:&#160;<span>{{ (data.metadata.distancePrevisionnelleCumul > 0 ? data.metadata.distancePrevisionnelleCumul - data.metadata.distanceTheoriqueCumul : data.metadata.distanceEstimeeMensuel > 0 ? data.metadata.distanceEstimeeCumul - data.metadata.distanceTheoriqueCumul : data.metadata.distanceReelleCumul - data.metadata.distanceTheoriqueCumul) | number:'1.0-0' }}</span>&#160;(<span *ngIf="data.metadata.ecart >= 0">+</span><span>{{ (100 * data.metadata.ecart | number:'.2-2') + ' %' }}</span>)</div>
		</ng-container>
		<ng-container *ngIf="data.metadata.unite == 'TEMPS'">
			<div *ngIf="data.metadata.tempsPrevisionnelCumul > 0 && !data.metadata.tempsEstimeCumul"><strong translate>contrat.financement.chart.legende.previsionnel</strong>&#160;:&#160;<span>{{ data.metadata.tempsPrevisionnelCumul | number:'.0-0' }}</span></div>
			<div *ngIf="data.metadata.tempsEstimeCumul > 0"><strong translate>contrat.financement.chart.legende.estimation</strong>&#160;:&#160;<span>{{ data.metadata.tempsEstimeCumul | number:'.0-0' }}</span></div>
			<div *ngIf="data.metadata.tempsPrevisionnelCumul <= 0 && data.metadata.tempsEstimeCumul == 0"><strong translate>contrat.financement.chart.legende.reel</strong>&#160;:&#160;<span>{{ data.metadata.tempsReelCumul | number:'.0-0' }}</span></div>
			<div><strong translate>contrat.financement.chart.legende.contrat</strong>&#160;:&#160;<span>{{ data.metadata.tempsTheoriqueCumul | number:'.0-0' }}</span></div>
			<div><strong translate>contrat.financement.chart.legende.ecart</strong>&#160;:&#160;<span>{{ (data.metadata.tempsPrevisionnelCumul > 0 ? data.metadata.tempsPrevisionnelCumul - data.metadata.tempsTheoriqueCumul : data.metadata.tempsEstimeMensuel > 0 ? data.metadata.tempsEstimeCumul - data.metadata.tempsTheoriqueCumul : data.metadata.tempsReelCumul - data.metadata.tempsTheoriqueCumul) | number:'1.0-0' }}</span>&#160;(<span *ngIf="data.metadata.ecart >= 0">+</span><span>{{ (100 * data.metadata.ecart | number:'.2-2') + ' %' }}</span>)</div>
		</ng-container>
	</div>
</div>