import { Component,Input,OnInit,ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'workflow-detail-title',
	templateUrl: './workflow-detail-title.component.html',
	styles: ['workflow-detail-title { display: contents; }'],
	encapsulation: ViewEncapsulation.None
})
export class WorkflowDetailTitleComponent implements OnInit {
	/** Entité **/
	@Input() readonly entite: string;

	/** Mode **/
	@Input() readonly mode: 'start' | 'end';

	/** Libellé **/
	public libelle: string;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition du libellé
		this.libelle = this.translateService.instant(`workflow.entite.${(this.entite.split('.') as any).pop(-1)}.${this.mode}`);
	}
}
