import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeGroupe } from 'src/app/domain/security/right';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { DroitService } from 'src/app/share/components/droit/droit.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteServiceDroitComponent } from './compte-service-droit.component';
import { CompteServiceEditComponent } from './compte-service-edit.component';
import { CompteServiceService } from './compte-service.service';

@Component({
	selector: 'compte-service',
	templateUrl: './compte-service.component.html'
})
export class CompteServiceComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Compte de service courant **/
	public compteService: any;

	/** Liste des types de périmètre **/
	private listeTypesPerimetre: Array<{ code: string,libelle: string }> = null;

	/** Résumé **/
	private resume: { nbDroitsAdmin: number,nbDroitsYourway: number,nbDroitsComptabilite: number } = {
		nbDroitsAdmin: 0,
		nbDroitsYourway: 0,
		nbDroitsComptabilite: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private compteServiceService: CompteServiceService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private droitService: DroitService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.user.CompteService';

	/**
	 * Récupération de l'identifiant du compte de service à charger
	 */
	getIdObject: () => number = () => this.compteService?.idCompteService || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.compteService;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'compteService.libelle',
				key: 'libelle'
			},{
				libelle: 'compteService.identifiant',
				key: 'user.login'
			},{
				libelle: 'compteService.actif.title',
				key: 'user.enabled',
				type: 'BOOLEAN'
			}],
			customStyle: {
				elements: {
					header: 'card profil m-t-10',
					body: ' ',
					row: ' '
				}
			},
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editCompteService(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editCompteService(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteCompteService(),
				isVisible: () => this.rightService.hasRight(null,'suppression'),
				type: TypeAction.SUPPRESSION
			}],
			isTabActionsHidden: () => true,
			listeElements: [{
				type: 'DROIT',
				libelle: `compteService.elements.droit.${TypeGroupe.ADMIN}`,
				component: CompteServiceDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					compteService: this.compteService,
					saveCompteService: this.saveCompteService.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.ADMIN),
				count: () => this.resume.nbDroitsAdmin,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			},{
				type: 'DROIT',
				libelle: `compteService.elements.droit.${TypeGroupe.YOURWAY}`,
				component: CompteServiceDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					compteService: this.compteService,
					saveCompteService: this.saveCompteService.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.YOURWAY),
				count: () => this.resume.nbDroitsYourway,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			},{
				type: 'DROIT',
				libelle: `compteService.elements.droit.${TypeGroupe.COMPTABILITE}`,
				component: CompteServiceDroitComponent,
				retrieveComponentData: () => ({
					resume: this.resume,
					compteService: this.compteService,
					saveCompteService: this.saveCompteService.bind(this)
				}),
				doAction: () => this.droitService.onEditGroupe.emit(TypeGroupe.COMPTABILITE),
				count: () => this.resume.nbDroitsComptabilite,
				isActionVisible: () => this.rightService.hasRight(null,'creation'),
				isTabHidden: true
			}]
		};

		//Récupération de l'identifiant du compte de service à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idCompteService);
			}
		});

		//Récupération des types de périmètre
		this.listeTypesPerimetre = this.compteServiceService.getListeTypesPerimetre();
	}

	/**
	 * Edition du compte de service
	 */
	private editCompteService() {
		//Ouverture du composant d'édition
		this.pageContentService.open(CompteServiceEditComponent,{
			data: {
				compteService: cloneDeep(this.compteService),
				deleteCompteService: this.deleteCompteService.bind(this),
				listeTypesPerimetre: this.listeTypesPerimetre,
				saveCompteService: this.saveCompteService.bind(this)
			}
		}).subscribe({
			next: (compteService: any) => {
				//Vérification des données
				if (compteService) {
					//Mise à jour du compte de service
					this.compteService = Object.assign(this.compteService,compteService);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.compteService);
				}
			}
		});
	}

	/**
	 * Suppression du compte de service
	 */
	private deleteCompteService() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.compteServiceService.deleteCompteService(this.compteService))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Enregistrement du compte de service
	 */
	private saveCompteService(compteService: any,close: Function) {
		//Enregistrement du compte de service
		this.compteServiceService.saveCompteService(compteService).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du compte de service
					compteService = Object.assign(compteService,result.data?.compteService);

					//Redéfinition du compte de service
					this.compteService = Object.assign(this.compteService,compteService);

					//Définition du résumé
					this.resume = {
						nbDroitsAdmin: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.ADMIN)?.nbDroits || 0,
						nbDroitsYourway: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.YOURWAY)?.nbDroits || 0,
						nbDroitsComptabilite: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.COMPTABILITE)?.nbDroits || 0
					}

					//Vérification de la présence d'un mot de passe
					if (result.data.password) {
						//Affichage des informations de connexion
						this.compteServiceService.showPassword(this.compteService.user,result.data.password).subscribe({
							next: () => close?.(this.compteService)
						});
					} else
						//Fermeture du formulaire
						close?.(this.compteService);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de l'identifiant
					if (result.data.doublon & Math.pow(2,1))
						//Ajout des libellés
						listeDoublons.push(this.translateService.instant('actions.doublon.identifiant'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idCompteService: number) {
		//Chargement du compte de service
		this.compteServiceService.loadCompteService(idCompteService).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du compte de service
					this.compteService = result.data?.compteService;

					//Définition du résumé
					this.resume = {
						nbDroitsAdmin: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.ADMIN)?.nbDroits || 0,
						nbDroitsYourway: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.YOURWAY)?.nbDroits || 0,
						nbDroitsComptabilite: this.compteService?.listeGroupeDroits?.find(groupe => groupe.typeGroupeDroit == TypeGroupe.COMPTABILITE)?.nbDroits || 0
					}

					//Vérification de l'identifiant du compte de service
					if (!this.compteService?.idCompteService) {
						//Création d'un objet vierge
						this.compteService = {
							user: {
								enabled: false
							}
						};

						//Edition du compte de service
						this.editCompteService();
					}
				}
			}
		});
	}
}