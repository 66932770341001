<form #equipementAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>equipement.add.title</h4>
	</div>
	<div class="modal-body">
		<div class="form-group" [ngClass]="{ 'has-error': typeField.invalid }">
			<label translate>equipement.type.item</label>
			<div class="half">
				<selectpicker name="typeField" #typeField="ngModel" [(ngModel)]="equipement.type" [disabled]="filter?.source == 'VEHICULE'" required>
					<mat-option *ngFor="let type of listeTypes" [value]="type.code">{{ type.libelle }}</mat-option>
				</selectpicker>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }" *ngIf="equipement.type">
			<label translate>equipement.fournisseur</label>
			<div>
				<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="equipement.fournisseur" (ngModelChange)="equipement.equipement = null" [disabled]="true" required></autocomplete>
			</div>
		</div>
		<div *ngIf="equipement.fournisseur">
			<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
				<label translate>equipement.reference</label>
				<div class="input-group">
					<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="equipement.reference" (ngModelChange)="equipement.referenceUpdated = true" maxlength="100" required [minlength]="['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type) ? 9 : 1" pattern="[a-zA-Z0-9]*"/>
					<div class="input-group-addon">
						<icon name="info_outline" [tooltip]="(equipement.type == 'CARTE_CARBURANT' ? 'equipement.referenceInfoCarteEquipement' : 'equipement.referenceInfo') | translate"></icon>
					</div>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }" *ngIf="equipement?.type && ['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type)">
				<label translate>equipement.numero.item</label>
				<div class="input-group">
					<input type="text" name="numero" #numero="ngModel" class="form-control" [(ngModel)]="equipement.numero" maxlength="6" [required]="equipement?.fournisseur?.fournisseurOrigine?.equipementNumeroObligatoire" pattern="[a-zA-Z0-9]*"/>
					<div class="input-group-addon">
						<icon name="info_outline" [tooltip]="'equipement.numero.info' | translate"></icon>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="filter?.source != 'FACTURE'" [ngClass]="{ 'has-error': statutField.invalid }">
				<label translate>equipement.statut.item</label>
				<div class="half">
					<selectpicker name="statutField" #statutField="ngModel" [(ngModel)]="equipement.statut" [disabled]="filter.statut != null" required>
						<mat-option *ngFor="let statut of listeStatuts" [value]="statut.code">{{ statut.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateDebut.invalid }">
				<label translate>equipement.dateDebut.item</label>
				<div class="half">
					<input type="text" class="form-control" name="dateDebut" #dateDebut="ngModel" [(ngModel)]="equipement.dateDebut" [maxDate]="equipement.dateFin" datePicker format="date" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateFin.invalid }">
				<label translate>equipement.dateFin.item</label>
				<div class="half">
					<input type="text" class="form-control" name="dateFin" #dateFin="ngModel" [(ngModel)]="equipement.dateFin" [minDate]="equipement.dateDebut" datePicker format="date" required/>
				</div>
			</div>
			<div *ngIf="filter?.source == 'FACTURE'">
				<div>
					<h4 translate>equipement.affectation.title</h4>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': typeAffectationField.invalid }" *ngIf="['CARTE_CARBURANT','CARTE_ENTRETIEN','CARTE_ENERGIE'].includes(equipement.type)">
					<label translate>equipement.affectation.type.item</label>
					<div class="half">
						<selectpicker name="typeAffectationField" #typeAffectationField="ngModel" [(ngModel)]="equipement.affectation.type" (ngModelChange)="equipement.affectation.vehicule = null; equipement.affectation.user = null;" required>
							<mat-option *ngFor="let typeAffectation of listeTypesAffectation" [value]="typeAffectation.code">{{ typeAffectation.libelle }}</mat-option>
						</selectpicker>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': vehicule.invalid }" *ngIf="equipement.affectation.type == 'VEHICULE'">
					<label translate>equipement.affectation.vehicule</label>
					<div>
						<autocomplete name="vehicule" type="vehicule" #vehicule="ngModel" [(ngModel)]="equipement.affectation.vehicule" [filter]="{ typeVehicule: 'VEHICULE_FONCTION_SERVICE' }" required></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': user.invalid }" *ngIf="equipement.affectation.type == 'COLLABORATEUR'">
					<label translate>equipement.affectation.user</label>
					<div>
						<autocomplete name="user" type="user" #user="ngModel" [(ngModel)]="equipement.affectation.user" required></autocomplete>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button  (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary"  (click)="saveItem();" [disabled]="equipementAddForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>