<form #demandeCotationCotationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>demandeCotation.cotation.title</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || cotation.statut == 'SOLDEE'">
				<div class="row">
					<div class="col-xs-12">
						<h4><span translate>demandeCotation.cotation.informationsGenerales</span></h4>
					</div>
				</div>
				<div class="form-group">
					<label translate>demandeCotation.cotation.modele</label>
					<div>
						<mat-chip-listbox>
							<mat-chip class="clickable" (click)="showModeleInformation(cotation.modele)">
								<span>{{ cotation.modele.marque.libelle + ' ' + cotation.modele.libelle }}</span><span *ngIf="cotation.modele.finition">&#160;({{ cotation.modele.finition }})</span>
							</mat-chip>
						</mat-chip-listbox>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeFinancement.invalid }">
							<label translate>demandeCotation.cotation.typeFinancement</label>
							<div>
								<autocomplete name="typeFinancement" type="typeFinancement" #typeFinancement="ngModel" [(ngModel)]="cotation.typeFinancement" (ngModelChange)="cotation.duree = null;cotation.distance = null" required></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>demandeCotation.cotation.piecesJointes</label>
							<div>
								<div [attachment]="cotation" [typeAttachment]="TypeAttachment.COTATION"></div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="cotation.typeFinancement">
					<div *ngIf="cotation.typeFinancement.mode != 'ACQUISITION'">
						<div class="row">
							<div class="col-xs-12">
								<h4><span translate>demandeCotation.cotation.financement</span></h4>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group" [class]="{ 'has-error': duree.invalid }">
									<label translate>demandeCotation.cotation.duree</label>
									<div class="half">
										<input type="text" name="duree" #duree="ngModel" class="form-control" [(ngModel)]="cotation.duree" nNumber min="0" max="300" [required]="cotation.typeFinancement && (cotation.typeFinancement.mode == 'LOCATION_LONGUE_DUREE' || cotation.typeFinancement.mode == 'LOCATION_MOYENNE_DUREE')"/>
									</div>
								</div>
								<div class="form-group" [class]="{ 'has-error': distance.invalid }">
									<label translate>demandeCotation.cotation.distance</label>
									<div class="input-group half">
										<input type="text" name="distance" #distance="ngModel" class="form-control" nNumber="0" [(ngModel)]="cotation.distance" n-number min="0" [required]="cotation.typeFinancement && (cotation.typeFinancement.mode == 'LOCATION_LONGUE_DUREE' || cotation.typeFinancement.mode == 'LOCATION_MOYENNE_DUREE')"/>
										<div class="input-group-addon no-border" *ngIf="user.unite">
											<span>{{ user.unite.libelleCourt }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>demandeCotation.cotation.contactsASolliciter</span></h4>
						</div>
					</div>
					<basket [model]="cotation" propertyName="listeContacts" colWidthLabel="2" colWidthAutocomplete="4" type="fournisseurContact" [labelAutocomplete]="'demandeCotation.cotation.addContact' | translate" [labelListe]="'demandeCotation.cotation.contacts' | translate" [hasError]="isListeContactsInvalid"></basket>
					<div class="row">
						<div class="col-xs-12">
							<h4><span translate>demandeCotation.cotation.remarque</span></h4>
						</div>
					</div>
					<div class="form-group">
						<label translate>demandeCotation.cotation.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" rows="3" [(ngModel)]="cotation.remarque"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-raised-button color="primary" *ngIf="(null | right:'creation') && (cotation.statut == 'A_COMPLETER' || cotation.statut == 'A_TRANSMETTRE')" (click)="saveCotation();" [disabled]="demandeCotationCotationForm.invalid || !cotation.listeContacts?.length">
			<span translate>actions.transmettre</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: isDirty() }">
		<span translate>actions.fermer</span>
	</button>
</form>