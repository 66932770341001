import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { Filter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';

@Injectable()
export class GenreService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Chargement du genre
	 */
	public loadGenre(idGenre: number): Observable<Result> {
		//Chargement du genre
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadGenre/${idGenre}`,null);
	}

	/**
	 * Suppression du genre
	 */
	public deleteGenre(genre: any): Observable<Result> {
		//Suppression du genre
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteGenre/${genre.idGenre}`);
	}

	/**
	 * Enregistrement du genre
	 */
	public saveGenre(genre: any): Observable<Result> {
		//Enregistrement du genre
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveGenre`,genre);
	}

	/**
	 * Chargement du tableau de bord
	 */
	public loadDashboardGenre(type: 'HORS_CATALOGUE' | 'CATALOGUE',searchSpec: { listeFilter: Array<Filter>,extraData?: any }): Observable<Result> {
		//Chargement du tableau de bord
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/loadDashboardGenre/${type}`,searchSpec);
	}
}