<div class="pull-left lv-avatar">{{ data.reference.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="!canRemove && extraOptions.onTemplateSelected(data)">{{ data.libelle + ' (' + data.reference + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.templateMailOrigine"><span translate>notification.selection.template.personnalise</span>&#160;:&#160;<span [translate]="'common.'+(data.custom ? 'oui' : 'non')"></span></li>
	</ul>

	<div class="lv-actions actions">
		<a [routerLink]="[]" *ngIf="!canRemove" (click)="previewTemplateMail();"><icon name="visibility" class="icon-hc-1x"></icon></a>
		<a [routerLink]="[]" *ngIf="canRemove" (click)="extraOptions.onTemplateSelected(null)"><icon name="close" class="icon-hc-1x"></icon></a>
	</div>
</div>