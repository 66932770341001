<div class="pull-left lv-avatar">{{ data?.equipement.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="goToEquipement();">{{ data.equipement?.reference }}<span *ngIf="data.equipement?.numero">&#160;-&#160;{{ data.equipement?.numero }}</span>&#160;({{ data.equipement?.fournisseur?.libelle }})</a>
	</div>
	<ul class="lv-attrs">
		<li *ngIf="data.type"><span translate>equipement.type.item</span>&#160;:&#160;<span>{{ 'equipement.type.' + data.equipement.type | translate }}</span></li>
		<li><span translate>equipement.affectation.dateDebut</span>&#160;:&#160;{{ data.dateDebut | date:'short' }}</li>
		<li><span translate>equipement.affectation.dateFin</span>&#160;:&#160;<span *ngIf="data.dateFin">{{ data.dateFin | date:'short' }}</span><span *ngIf="!data.dateFin" translate>common.aucune</span></li>
		<li><span translate>equipement.statut.item</span>&#160;:&#160;<span [translate]="'equipement.statut.'+data.equipement.statut"></span></li>
	</ul>
	<div class="lv-actions actions dropdown">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.EQUIPEMENT | right:'creation'"><a [routerLink]="[]" (click)="deleteAffectationEquipement();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="goToEquipement();" translate>vehicule.equipement.actions.accederEquipement</a></li>
		</ul>
	</div>
</div>