<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.type == 'FORFAIT_POST_STATIONNEMENT' ? 'FPS' : 'A' }}</div>
<div class="lv-title">
	<a [routerLink]="['/Contravention/loadContravention',data.idContravention]">{{ data.vehicule?.reference + (data.vehicule?.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ' (' + (data.vehicule?.modele ? (data.vehicule.modele?.libelle + ' ' + data.vehicule.modele.marque?.libelle) : ('vehicule.modele.nonDefini' | translate)) + ')' }}</a>
</div>
<ul class="lv-attrs">
	<li *ngIf="data.designation?.user"><span translate>infraction.contravention.collaborateur</span>&#160;:&#160;<span>{{ data.designation.user.nom + ' ' + data.designation.user.prenom + ' (' + data.designation.user.matricule + (data.designation.user.societe?.libelle ? ' - ' + data.designation.user.societe?.libelle : '') + ')' }}</span></li>
	<li *ngIf="data.montantContravention"><span translate>infraction.contravention.montant</span>&#160;:&#160;<span>{{ data.montantContravention | currency:'.2-2':data.devise }}</span></li>
	<li *ngIf="data.dateContravention"><span translate>infraction.contravention.dateContravention</span>&#160;:&#160;<span>{{ data.dateContravention | date:'short' }}</span></li>
	<li *ngIf="data.reference"><span translate>infraction.contravention.reference</span>&#160;:&#160;<span>{{ data.reference }}</span></li>
	<li *ngIf="data.majoree && data.contraventionMajorante"><span translate>infraction.contravention.majoration</span>&#160;:&#160;<span>{{ data.contraventionMajorante.reference }}</span></li>
	<li *ngIf="data.pays"><span translate>infraction.contravention.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification()" translate>actions.notifier</a></li>
		<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
		<li *ngIf="(null | right:'suppression') && !data.majoree"><a [routerLink]="[]" (click)="deleteContravention()"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>