<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idModele">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data.marque?.libelle + ' ' + data.libelle + (data.reference ? ' (' + data.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idModele">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.finition"><span translate>vehicule.modele.finition</span>&#160;:&#160;<span>{{ data.finition }}</span></li>
		<li *ngIf="data.modeleNiveau1 || data.modeleNiveau2 || data.modeleNiveau3"><span translate>vehicule.modele.categorisation.item</span>&#160;:&#160;<span>{{ (data.modeleNiveau1 + '/' + data.modeleNiveau2 + '/' + data.modeleNiveau3).substring(0,20) }}&#160;<icon *ngIf="(data.modeleNiveau1 + '/' + data.modeleNiveau2 + '/' + data.modeleNiveau3).length > 20" [tooltip]="data.modeleNiveau1 + '/' + data.modeleNiveau2 + '/' + data.modeleNiveau3" name="more_horiz"></icon></span></li>
		<li *ngIf="data.typeSource && rightService.isRoot()"><span translate>vehicule.modele.typeSource.item</span>&#160;:&#160;<span [translate]="'vehicule.modele.typeSource.'+(data.typeSource == 'EXTERNE_CATALOGUE' ? 'CONSTRUCTEUR' : 'ADMINISTRATIF')"></span></li>
		<li *ngIf="data.information?.commercialisationPrix"><span translate>vehicule.modele.commercialisation.item</span>&#160;:&#160;<span>{{ data.information?.commercialisationPrix?.commercialisationEnCours ? ('vehicule.modele.commercialisation.EN_COURS' | translate) : ('vehicule.modele.commercialisation.TERMINE' | translate:{ dateFin: (data.information?.commercialisationPrix?.dateFin | date:'shortDate') }) }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idModele && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data.typeSource == 'EXTERNE_CATALOGUE'"><a [routerLink]="[]" (click)="goToModele();"><span translate>vehicule.modele.actions.accederModele</span></a></li>
			<li *ngIf="data.typeSource == 'EXTERNE_CATALOGUE'"><a [routerLink]="[]" (click)="showConfiguration();"><span translate>vehicule.modele.actions.configurer</span></a></li>
			<li *ngIf="data.typeSource != 'EXTERNE_CATALOGUE' && (null | right:'suppression')"><a [routerLink]="[]" (click)="deleteModele();"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<modele-list-item-content [modele]="data" (onObjectChange)="onObjectChange($event)"></modele-list-item-content>
</div>