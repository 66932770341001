import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class OptionCategorieService {
	/** Liste des types d'options **/
	private readonly listeTypesOption: Array<{ code: string,icone: string,iconeType?: string }> = [{
		code: 'ACCESSOIRE',
		icone: 'code'
	},{
		code: 'CONDUITE',
		icone: 'airline_seat_recline_normal'
	},{
		code: 'CONFORT',
		icone: 'event_seat'
	},{
		code: 'DESIGN',
		icone: 'directions_car'
	},{
		code: 'GARANTIE',
		icone: 'description'
	},{
		code: 'MEDIA',
		icone: 'cast'
	},{
		code: 'PACK',
		icone: 'local_mall'
	},{
		code: 'PEINTURE',
		icone: 'format_paint'
	},{
		code: 'SECURITE',
		icone: 'security'
	}];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService) { }

	/**
	 * Suppression de la catégorie d'option
	 */
	public deleteOptionCategorie(optionCategorie: any): Observable<Result> {
		//Suppression de la catégorie d'option
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/deleteOptionCategorie/${optionCategorie.idCategorie}`);
	}

	/**
	 * Enregistrement de la catégorie d'option
	 */
	public saveOptionCategorie(optionCategorie: any): Observable<Result> {
		//Enregistrement de la catégorie d'option
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/saveOptionCategorie`,optionCategorie);
	}

	/**
	 * Récupération de la liste des types d'option
	 */
	public getListeTypesOption(): Array<{ code: string,icone: string,libelle: string }> {
		//Retour de la liste des types d'option
		return this.listeTypesOption.map(option => ({
			...option,
			libelle: this.translateService.instant(`vehicule.optionCategorie.typeOption.${option.code}`)
		}));
	}

	/**
	 * Récupération du type d'option pour une code
	 */
	public getTypeOptionForCode(code: string): { code: string,icone: string,libelle: string,iconeType?: string } {
		//Retour du type d'options correspondant au code
		return this.getListeTypesOption().find(option => option.code === code);
	}
}