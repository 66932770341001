<div class="modal-header">
	<h4 class="modal-title" translate [translateParams]="{ dateExecution: execution.dateExecution | date:'short' }">connecteur.execution.title</h4>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-xs-12">
			<div #codemirror></div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>