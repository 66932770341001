<form #workflowDetailValideurDefinitionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<fieldset [disabled]="!isEdition">
		<div class="modal-header">
			<h4 class="modal-title">{{ ('workflow.detail.definition.' + etape.typeValideur + '.title' | translate) + (etape.libelle ? ' : ' + etape.libelle : '') }}</h4>
		</div>
		<div class="modal-body" *ngIf="etape.typeValideur === 'VALIDEUR_DYNAMIQUE'">
			<div class="listview">
				<div class="lv-body">
					<input hidden name="listeValideurs" ngModel [validator]="etape.listeValideurs?.length > 0"/>
					<div class="lv-item media hoverable" *ngFor="let valideur of etape.listeValideurs; let index = index">
						<div class="media-body">
							<div class="pull-left lv-avatar">{{ index + 1 }}</div>
							<div class="lv-title clickable handle">{{ valideur.libelle }}</div>
							<ul class="pull-right actions m-t-0 hovered-item" *ngIf="isEdition">
								<li>
									<a [routerLink]="[]" (click)="deleteValideurDynamique(index)"><icon name="delete"></icon></a>
								</li>
							</ul>
						</div>
					</div>
					<div class="p-20 m-t-20" *ngIf="isEdition">
						<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addValideurDynamique()">
							<h5>
								<a [routerLink]="[]" translate>workflow.detail.definition.VALIDEUR_DYNAMIQUE.ajouter</a>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-body" *ngIf="etape.typeValideur === 'VALIDEUR_NOMME'">
			<basket [model]="etape" propertyName="listeValideursNommes" colWidthLabel="2" colWidthAutocomplete="6" type="user" [disabled]="!isEdition" [isCreationDisabled]="true" [labelAutocomplete]="'workflow.detail.definition.VALIDEUR_NOMME.ajouter' | translate" [labelListe]="'workflow.detail.definition.VALIDEUR_NOMME.listeValideurs' | translate" [hasError]="isListeValideursNommesInvalid"></basket>
		</div>
		<div class="modal-footer">
			<button mat-button (click)="bsModalRef.hide()">
				<span [translate]="'actions.' + (isEdition ? 'annuler' : 'fermer')"></span>
			</button>
			<button mat-raised-button color="primary" (click)="saveWorkflowDetailValideurDefinition()" [disabled]="workflowDetailValideurDefinitionForm.invalid" *ngIf="isEdition">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</fieldset>
</form>
