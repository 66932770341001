<form class="m-t-20">
	<div class="text-center">
		<div class="listview" *ngIf="(null | right:'creation') && resume.nbRubriques > 0">
			<div class="lv-body">
				<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="editListeRubriques();">
					<h5><a [routerLink]="[]" translate>article.actions.modifierRubriques</a></h5>
				</div>
			</div>
		</div>
	</div>

	<div class="listview lv-bordered m-t-10">
		<div class="lv-body">
			<ng-container *ngIf="resume.nbRubriques > 0">
				<div class="lv-item media" *ngFor="let content of article.listeContents; let index = index;">
					<div class="media-body" (click)="content.isToggled = !content.isToggled;">
						<div class="pull-left lv-avatar">{{ index + 1 }}</div>
						<div class="lv-title clickable"><span>{{ content.titre }}</span></div>
						<ul class="pull-right actions m-t-0" *ngIf="null | right:'creation'">
							<li><a [routerLink]="[]"><icon [name]="!content.isToggled ? 'chevron_right' : 'expand_more'"></icon></a></li>
						</ul>
					</div>
					<div [collapse]="!content.isToggled" class="p-t-10 collapse">
						<div class="row">
							<div class="col-md-12">
								<span [innerHtml]="content.safeContent"></span>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="text-center p-10" *ngIf="resume.nbRubriques == 0">
				<h5 translate><span translate>liste.empty</span></h5>
			</div>
		</div>
	</div>
</form>