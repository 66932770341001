<div class="row">
	<div class="col-md-6">
		<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
			<label translate>vehicule.modele.libelle</label>
			<div>
				<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="modele.libelle" maxlength="300" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
			<label translate>vehicule.modele.reference</label>
			<div>
				<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="modele.reference" maxlength="100" required/>
			</div>
		</div>
		<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }" *ngIf="!options?.light">
			<label translate>vehicule.modele.marque</label>
			<div>
				<autocomplete name="marque" type="marque" #marque="ngModel" [(ngModel)]="modele.marque" required></autocomplete>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
			<label translate>vehicule.modele.actif.item</label>
			<div>
				<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="modele.actif">
					<span translate>vehicule.modele.actif.info</span>
				</mat-checkbox>
			</div>
		</div>
	</div>
</div>