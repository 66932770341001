<form #profilExtractionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title">
				<span translate>profil.extraction.title</span>
			</h2>
		</div>
		<extraction-diffusion-content #profilExtractionContent source="PROFIL" [userScheduler]="userScheduler" [profil]="profil" (close)="close($event)"></extraction-diffusion-content>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteUserScheduler?.(close);" *ngIf="userScheduler.idUserScheduler && (null | right:'creation')">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="profilExtractionContent.saveUserScheduler();" *ngIf="null | right:'creation'" [disabled]="profilExtractionForm.invalid">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: profilExtractionForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>