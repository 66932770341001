import { AfterContentChecked,Component,Input,ChangeDetectorRef,ViewChild,AfterViewInit } from '@angular/core';
import CodeMirror from 'codemirror';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'connecteur-execution-result',
	templateUrl: './connecteur-execution-result.component.html'
})
export class ConnecteurExecutionResultComponent implements AfterContentChecked,AfterViewInit {
	/** Exécution courante **/
	@Input() execution: any;

	/** Résultat **/
	@Input() dataResult: any;

	/** Référence de l'élément qui contiendra le composant 'CodeMirror' **/
	@ViewChild('codemirror') codemirrorRef: any;

	/** Options d'affichage **/
	private options: any = {
		theme: 'cobalt',
		mode: 'javascript',
		readOnly: true,
		revertButtons: false,
		lineNumbers: true,
		smartIndent: true,
		lineWrapping: true,
		chunkClassLocation: ''
	};

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<ConnecteurExecutionResultComponent>) {}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Interception du chargement du composant
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Affichage du CodeMirror
			CodeMirror(this.codemirrorRef.nativeElement,{
				...this.options,
				value: JSON.stringify(this.dataResult,null,'\t')
			});
		});
	}
}
