import { DatePipe,DecimalPipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { User } from 'src/app/domain/user/user';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CotationService } from '../cotation/cotation.service';
import { DemandeCotationCotationEditComponent } from './demande-cotation-cotation-edit.component';
import { DemandeCotationCotationListComponent } from './demande-cotation-cotation-list.component';
import { DemandeCotationReponseCotationComponent } from './demande-cotation-reponse-cotation.component';
import { DemandeCotationService } from './demande-cotation.service';

@Component({
	selector: 'demande-cotation',
	templateUrl: './demande-cotation.component.html'
})
export class DemandeCotationComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Demande de cotation courante **/
	public demandeCotation: any;

	/** Identifiant de la cotation **/
	private idCotation: number;

	/** Utilisateur connecté **/
	user: User;

	/** Résumé **/
	public resume: { nbCotations: number } = {
		nbCotations: 0
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private demandeCotationService: DemandeCotationService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute
			,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private layoutService: LayoutService,private datePipe: DatePipe,private decimalPipe: DecimalPipe,private cotationService: CotationService,private loggedUserService: LoggedUserService) {
		//Binding des méthodes
		this.consultCotation = this.consultCotation.bind(this);
	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.cotation.DemandeCotation';

	/**
	 * Récupération de l'identifiant
	 */
	getIdObject: () => number = () => this.demandeCotation?.idDemandeCotation || 0;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération d'un identifiant de cotation
		this.idCotation = history.state.idCotation;

		//Suppression de l'identifiant dans l'historique
		history.state.idCotation = null;

		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'demandeCotation.reference',
				key: 'idDemandeCotation'
			},{
				libelle: 'demandeCotation.statut.item',
				key: (data) => this.translateService.instant(`demandeCotation.statut.${data.statut}`)
			},{
				libelle: 'demandeCotation.dateCreation',
				key: 'dateCreation',
				type: 'DATE',
				format: 'date'
			},{
				libelle: 'demandeCotation.avancementFinancement',
				key: (data) => `${this.decimalPipe.transform(data.extension?.avancementFinancement || 0,'.0-2')} %`,
				isVisible: () => this.hasStatut(['A_COMPLETER','A_TRANSMETTRE'])
			}],
			listeActions: [{
				libelle: 'actions.transmettre',
				doAction: () => this.doAction('TRANSMETTRE'),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.hasStatut(['A_TRANSMETTRE'])
			},{
				libelle: 'actions.relancer',
				doAction: () => this.doAction('RELANCER'),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.hasStatut(['EMISE_FOURNISSEUR'])
			},{
				libelle: 'actions.annuler',
				doAction: () => this.doAction('ANNULER'),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.hasStatut(['EMISE_FOURNISSEUR'])
			},{
				libelle: 'actions.rappeler',
				doAction: () => this.doAction('RAPPELER'),
				isVisible: () => this.rightService.hasRight(null,'creation') && this.hasStatut(['EMISE_FOURNISSEUR'])
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteDemandeCotation(),
				isVisible: () => this.rightService.hasRight(null,'suppression') && ((!this.rightService.hasRight(null,'creation') || !this.hasStatut(['A_TRANSMETTRE','EMISE_FOURNISSEUR'])) || this.rightService.hasRight(null,'creation') && this.hasStatut(['A_TRANSMETTRE','EMISE_FOURNISSEUR'])),
				type: TypeAction.SUPPRESSION
			}],
			listeAlertes: [{
				icon: 'send',
				title: this.translateService.instant('demandeCotation.alerte.aTransmettreAction.title'),
				message: this.translateService.instant('demandeCotation.alerte.aTransmettreAction.message'),
				doAction: () => this.doAction('TRANSMETTRE'),
				isVisible: () => this.demandeCotation?.statut == 'A_TRANSMETTRE' && !this.pageContentService.isOpened()
			},{
				icon: 'info',
				level: 'INFO',
				title: this.translateService.instant('demandeCotation.alerte.emiseFournisseur.title'),
				message: () => this.translateService.instant('demandeCotation.alerte.emiseFournisseur.message',{ date: this.datePipe.transform(this.demandeCotation?.dateDerniereRelance ? this.demandeCotation?.dateDerniereRelance : this.demandeCotation?.datePremierEnvoi,'short') }),
				isVisible: () => this.demandeCotation?.statut == 'EMISE_FOURNISSEUR' && !this.pageContentService.isOpened()
			}],
			listeElements: [{
				type: 'COTATION',
				libelle: 'demandeCotation.elements.cotation',
				component: DemandeCotationCotationListComponent,
				retrieveComponentData: () => ({
					demandeCotation: cloneDeep(this.demandeCotation),
					resume: this.resume,
					showCotation: this.showCotation.bind(this),
					doAction: this.doAction.bind(this),
					idCotation: this.getIdCotation()
				}),
				count: () => this.resume?.nbCotations || 0,
				isElementVisible: () => this.rightService.hasRight(null,'consultation'),
				isActionVisible: () => false
			}]
		};

		//Récupération de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Chargement des données
		this.loadData(this.activatedRoute.snapshot.params.idDemandeCotation);
	}

	/**
	 * Récupération de l'identifiant de la cotation
	 */
	private getIdCotation(): number {
		let idCotation;

		//Lecture de l'identifiant de la cotation
		idCotation = this.idCotation;

		//Suppression de l'identifiant de cotation récupéré depuis les données de la route
		this.idCotation = null;

		return idCotation;
	}

	/**
	 * Vérification de la présence du statut pour une demande de cotation
	 */
	public hasStatut(listeStatuts: Array<string>): boolean {
		//Vérification du statut de la demande de cotation
		return listeStatuts.indexOf(this.demandeCotation.statut) > -1;
	}

	/**
	 * Réalisation de l'action
	 */
	public doAction(action: 'TRANSMETTRE' | 'RELANCER' | 'RAPPELER' | 'ANNULER') {
		//Déclenchement de l'action
		this.demandeCotationService.doAction(action,this.demandeCotation).subscribe({
			//Rafraichissement de l'onglet
			next: (result: Result) => {
				//Vérification du formulaire ouvert
				if (this.pageContentService.getCurrentOpenedMode() == 'sub')
					//Fermeture du formulaire
					this.pageContentService.close(null,'sub');

				//Mise à jour de la demande de cotation
				this.demandeCotation = Object.assign(this.demandeCotation,result.data.demandeCotation);

				//Rechargement des éléments
				this.complexPage.setSelectedElementByType('COTATION',true);
			}
		});
	}

	/**
	 * Accès à la cotation
	 */
	private consultCotation(cotation) {
		//Redirection vers la demande de cotation
		this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesCotation-detail',{
			routeParams: {
				idDemandeCotation: cotation.demandeCotation?.idDemandeCotation
			},
			routeData: {
				idCotation: cotation.idCotation,
				origine: 'DEMANDE_VEHICULE'
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Affichage d'une cotation
	 */
	private showCotation(cotation: any,isForceComparaison? : boolean) {
		//Vérification du statut de la cotation
		if (!isForceComparaison && ['EMISE_FOURNISSEUR','RETOUR_COMPLET','RETOUR_PARTIEL','SOLDEE'].indexOf(cotation.statut) == -1) {
			//Edition de la cotation
			this.editCotation(cotation);
		} else if (!isForceComparaison && cotation.statut == 'SOLDEE') {
			//Edition de la réponse à la cotation
			this.editReponseCotation(cotation);
		} else {
			//Affichage de l'outil de comparaison
			this.cotationService.showComparaisonForCotation(cotation).subscribe({
				next: (result) => {
					//Vérification de la présence de la cotation
					if (result?.cotation)
						//Définition de la cotation
						Object.assign(cotation,result?.cotation);
				}
			});
		}
	}

	/**
	 * Edition de la cotation
	 */
	editCotation(cotation: any) {
		//Ouverture du composant d'édition
		this.pageContentService.open(DemandeCotationCotationEditComponent,{
			data: {
				cotation: cloneDeep(cotation),
				demandeCotation: this.demandeCotation
			}
		},'sub').subscribe((data: { cotation: any,demandeCotation: any }) => {
			//Vérification de la présence d'une demande de cotation
			if (data.demandeCotation)
				//Mise à jour de la demande de cotation
				this.demandeCotation = Object.assign(this.demandeCotation,data.demandeCotation);

			//Rafraichissement de l'onglet cotation
			this.complexPage.setSelectedElementByType('COTATION',true);
		});
	}

	/**
	 * Edition de la réponse à la cotation
	 */
	editReponseCotation(cotation: any) {
		//Récupération de la liste des réponses aux demandes de cotation pour une cotation
		this.cotationService.filtreReponsesCotationsForCotation(cotation).subscribe((listeReponsesDemandeCotation: Array<any>) => {
			//Ouverture de la réponse à la cotation
			this.pageContentService.open(DemandeCotationReponseCotationComponent,{
				data: {
					cotation: cloneDeep(cotation),
					reponseCotation: listeReponsesDemandeCotation.filter(r => r.statut == 'RETENUE')[0],
					isFromDemandeCotation: true,
					isReadOnly: true
				}
			},'sub').subscribe();
		});
	}

	/**
	 * Suppression de la demande de cotation
	 */
	private deleteDemandeCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.demandeCotationService.deleteDemandeCotation(this.demandeCotation.idDemandeCotation))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.demandeCotation;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idDemandeCotation: number) {
		//Vérification de l'identifiant
		if (idDemandeCotation > 0) {
			//Chargement de la demande de cotation
			this.demandeCotationService.loadDemandeCotation(idDemandeCotation).subscribe(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise à jour des données affichées
					this.demandeCotation = result.data?.demandeCotation || null;

					//Initialisation des compteurs
					this.initResume(idDemandeCotation);
				}
			});
		} else {
			//Création de la demande de cotation vierge
			this.demandeCotation = {
				origine: history.state.origine
			};

			//Définition du résumé
			this.resume = {
				nbCotations: 0
			};
		}
	}

	/**
	 * Initialisation du résumé
	 */
	private initResume(idDemandeCotation: number): void {
		//Récupération du résumé
		this.demandeCotationService.loadResumeForDemande(idDemandeCotation).subscribe(result => {
			//Définition du résumé
			this.resume = {
				nbCotations: result.data.nbCotations || 0
			};
		});
	}
}