<form #modeleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!modele.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="modele.typeSource == 'EXTERNE_CATALOGUE' || !(null | right:'creation')">
				<modele-content [modele]="modele"></modele-content>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: modeleForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteModele();" *ngIf="modele.typeSource != 'EXTERNE_CATALOGUE' && modele?.idModele && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveModele();" *ngIf="modele.typeSource != 'EXTERNE_CATALOGUE' && (null | right:'creation')" [disabled]="modeleForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>