import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot } from '@angular/router';

import { LayoutService } from 'src/app/share/layout/layout.service';
import { LoginService } from 'src/app/share/login/login.service';

/**
 * Gardien pour l'accès aux composants ne nécessitant pas d'authentification
 */
@Injectable()
export class NotLoggedGuardProvider implements CanActivate {
	/**
	 * Constructeur
	 */
	constructor(private loginService: LoginService,private layoutService: LayoutService) {

	}

	/**
	 * Activation du composant
	 */
	canActivate(route: ActivatedRouteSnapshot) {
		let isLogged;

		//Récupération de la connexion
		isLogged = this.loginService.getSession()?.isLogged;

		//Vérification de la connexion
		if (isLogged) {
			//Redirection vers la route demandée (ou le dashboard principal)
			this.layoutService.goToByState(route.queryParams.route || 'dashboard',{
				routeParams: route.queryParams
			});
		}

		//Vérification de la connexion
		return !isLogged;
	}
}