<div class="modal-header">
	<h4 class="modal-title" translate>structureExport.rubrique.selection.title</h4>
</div>
<div class="modal-body">
	<div class="panel panel-default">
		<div class="panel-body">
			<ol class="breadcrumb p-l-0 b-0 m-b-0" *ngIf="selectedType">
				<li>
					<a [routerLink]="[]" (click)="selectedType = null">{{ selectedType.libelle }}</a>
				</li>
			</ol>
			<span class="list-group" *ngFor="let type of getListeTypesRubrique()">
				<h4 *ngIf="type.libelle && type.listeItems?.length">{{ type.libelle }}</h4>

				<div class="list-group-item" *ngIf="!type.listeItems?.length" (click)="selectType(type)">
					<a [routerLink]="[]" *ngIf="type.libelle && type.code" class="d-flex justify-content-space-between flex-1">
						<span>{{ type.libelle }}</span>
						<icon name="chevron_right" class="icon-hc-2x" *ngIf="type.listeGroupes?.length"></icon>
					</a>
				</div>
				<span *ngFor="let item of type.listeItems">
					<div class="p-t-10 list-group-item">
						<a [routerLink]="[]" *ngIf="item.libelle" (click)="selectType(item)">
							<span>{{ item.computedLibelle || item.libelle }}</span>
							<icon *ngIf="item.tooltip" name="info_outline" [tooltip]="item.tooltip" class="m-l-5"></icon>
						</a>
					</div>
				</span>
			</span>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>
</div>