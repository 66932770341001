<div class="si">
	<ul class="fonction-detail z-depth-1">
		<li class="header">
			<div *ngIf="(null | right:'creation') && !isRestricted" class="actions dropdown pull-right">
				<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
				<ul class="dropdown-menu dropdown-menu-right">
					<li (click)="parent[parentKey] = null;">
						<a [routerLink]="[]" translate>actions.supprimer</a>
					</li>
				</ul>
			</div>
			<h4>
				<span class="fonction-label head">
					<span translate>structureExport.fonction.si.title</span>
				</span>
			</h4>
		</li>
		<li class="content">
			<ul>
				<li class="condition">
					<structure-export-rubrique-fonction-si-condition [parentFonction]="detail.fonction" [parent]="detail.fonction" [parentKey]="'alors'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-fonction-si-condition>
				</li>
				<li>
					<h4>
						<span class="fonction-label">
							<span translate>structureExport.fonction.si.alors</span>
						</span>
					</h4>
				</li>
				<li>
					<div class="fonction-item text-left" *ngIf="!detail.fonction.alors && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: detail.fonction,parentKey: 'alors' })" translate>common.aDefinir</a></div>
					<div class="fonction-item" *ngIf="!detail.fonction.alors && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
					<div *ngIf="detail.fonction.alors" class="rubrique-detail">
						<structure-export-rubrique-detail [detail]="detail.fonction.alors" [parent]="detail.fonction" [parentKey]="'alors'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
					</div>
				</li>
				<li>
					<h4>
						<span class="fonction-label">
							<span translate>structureExport.fonction.si.sinon</span>
						</span>
					</h4>
				</li>
				<li>
					<div class="fonction-item text-left" *ngIf="!detail.fonction.sinon && (null | right:'creation') && !isRestricted"><a [routerLink]="[]" (click)="onTypeRubriqueChange.emit({ parent: detail.fonction,parentKey: 'sinon' })" translate>common.aDefinir</a></div>
					<div class="fonction-item" *ngIf="!detail.fonction.sinon && (!(null | right:'creation') || isRestricted)"><span translate>common.nonDefini</span></div>
					<div *ngIf="detail.fonction.sinon" class="rubrique-detail">
						<structure-export-rubrique-detail [detail]="detail.fonction.sinon" [parent]="detail.fonction" [parentKey]="'sinon'" [isRestricted]="isRestricted" (onTypeRubriqueChange)="onTypeRubriqueChange.emit({ parent: $event.parent,parentKey: $event.parentKey })"></structure-export-rubrique-detail>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>