import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter,first } from 'rxjs/operators';

import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { AppState } from 'src/app/domain/appstate';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReservationListItemComponent } from './reservation-list-item.component';
import { ReservationService } from './reservation.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { mapEntites } from './reservation.entites';

@Component({
	templateUrl: './reservation-list.component.html'
})
export class ReservationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ReservationListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private translateService: TranslateService,private rightService: RightService,public dashboardService: DashboardService,private reservationService: ReservationService,public extractionService: ExtractionService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(filter(user => !!user),first()).subscribe({
			next: user => {
				//Mise à jour de l'utilisateur
				this.user = user;

				//Définition de la liste
				this.liste = new ListView<any,ReservationListItemComponent>({
					uri: `/controller/VehiculeReservation/filtreReservations`,
					title: this.translateService.instant('reservation.liste.title'),
					component: ReservationListItemComponent,
					isTile: true,
					listeFilters: [{
						clef: 'vehicule',
						type: TypeFilter.AUTOCOMPLETE,
						title: this.translateService.instant('reservation.vehiculeAffecte'),
						autocomplete: {
							type: 'vehicule'
						},
						isDefault: true
					},{
						clef: 'user',
						type: TypeFilter.AUTOCOMPLETE,
						title: this.translateService.instant('search.user'),
						autocomplete: {
							type: 'user'
						},
						isDefault: true
					},{
						clef: 'idReservation',
						title: this.translateService.instant('reservation.numeroReservation'),
						isDefault: true
					},{
						clef: 'pool',
						type: TypeFilter.AUTOCOMPLETE,
						title: this.translateService.instant('search.pool'),
						autocomplete: {
							type: 'pool',
							filter: {
								isShowAll: true
							}
						},
						isDefault: true
					},{
						clef: 'dateDepart',
						type: TypeFilter.DATE,
						title: this.translateService.instant('search.dateDepart')
					},{
						clef: 'dateRetour',
						type: TypeFilter.DATE,
						title: this.translateService.instant('search.dateRetour')
					},{
						clef: 'dateCreation',
						type: TypeFilter.DATE,
						title: this.translateService.instant('search.dateReservation')
					},{
						clef: 'statut',
						type: TypeFilter.LONG,
						title: this.translateService.instant('search.statut'),
						listeValues: [{
							code: 'EN_ATTENTE',
							libelle: 'reservation.statut.EN_ATTENTE'
						},{
							code: 'VALIDEE',
							libelle: 'reservation.statut.VALIDEE'
						},{
							code: 'REJETEE',
							libelle: 'reservation.statut.REJETEE'
						},{
							code: 'ANNULEE',
							libelle: 'reservation.statut.ANNULEE'
						}]
					}],
					noSearch: this.user.type == 'VIRTUAL',
					isLoadingDisabled: this.user.type == 'VIRTUAL',
					getKeyFieldName: () => 'idReservation',
					defaultOrder: 'statut,-dateDepart,-dateRetour',
					listeActions: this.user?.type != 'VIRTUAL' && [this.rightService.hasRight(TypeDroit.VEHICULE_RESERVATION,'creation') && {
						icon: 'check_circle',
						messagingOptions: {
							entryPoint: 'controller/VehiculeReservation/updateListeReservations/VALIDER'
						},
						onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.updateListeReservations(messagingOptions)
					},this.rightService.hasRight(TypeDroit.VEHICULE_RESERVATION,'creation') && {
						icon: 'cancel',
						messagingOptions: {
							entryPoint: 'controller/VehiculeReservation/updateListeReservations/REJETER'
						},
						onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.updateListeReservations(messagingOptions)
					}].filter(i => !!i)
				});


				//Vérification du type d'utilisateur
				if (this.user?.type != 'VIRTUAL') {
					//Vérification de la présence d'un dashboard
					this.dashboardService.hasDashboard().subscribe({
						next: (hasDashboard: boolean) => {
							//Définition de la présence d'un dashboard
							this.hasDashboard = hasDashboard;
						}
					});
				} else if (this.user?.externalAction?.idEntite) {
					//Chargement de la réservation
					this.reservationService.loadReservation(this.user.externalAction.idEntite).pipe(first()).subscribe({
						next: result => {
							//Définition de la liste
							this.liste.data = {
								content: [Object.assign(result.data.reservation,{
									isDisplayed: true
								})]
							};
						}
					});
				}
			}
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Changement de statut d'une liste de réservations
	 */
	private updateListeReservations(messagingOptions: MessagingOptions) {
		//Réalisation de l'action
		this.reservationService.updateListeReservations(messagingOptions).subscribe({
			complete: () => this.liste.refresh()
		});
	}
}