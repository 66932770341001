import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { RegleTaxeEditComponent } from './regle-taxe-edit.component';
import { RegleTaxeListItemComponent } from './regle-taxe-list-item.component';
import { RegleTaxeListComponent } from './regle-taxe-list.component';
import { RegleTaxePosteChargeEditComponent } from './regle-taxe-poste-charge-edit.component';
import { RegleTaxePosteChargeListItemComponent } from './regle-taxe-poste-charge-list-item.component';
import { RegleTaxePosteChargeListComponent } from './regle-taxe-poste-charge-list.component';
import { RegleTaxeRuleListComponent } from './regle-taxe-rule-list.component';
import { RegleTaxeComponent } from './regle-taxe.component';
import { RegleTaxeService } from './regle-taxe.service';

@NgModule({
	imports: [ShareModule],
	declarations: [RegleTaxeListComponent,RegleTaxeListItemComponent,RegleTaxeComponent,RegleTaxeEditComponent,RegleTaxeRuleListComponent,RegleTaxePosteChargeListComponent,RegleTaxePosteChargeListItemComponent,RegleTaxePosteChargeEditComponent],
	providers: [RegleTaxeService]
})
export class RegleTaxeModule { }