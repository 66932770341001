<div class="container-fluid">
	<form #natureForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot()">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>nature.description</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>nature.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="nature.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" *ngIf="nature.idNature">
								<label translate>nature.identifiant</label>
								<div>
									<span>{{ nature.idNature }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeFamilleField.invalid }">
								<label translate>nature.typeFamille</label>
								<div>
									<selectpicker name="typeFamille" #typeFamilleField="ngModel" [(ngModel)]="nature.typeFamille" required>
										<mat-option *ngFor="let typeFamille of listeTypesFamille" [value]="typeFamille.code">{{ typeFamille.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeReconciliationField.invalid }">
								<label translate>nature.typeReconciliation</label>
								<div>
									<selectpicker name="typeReconciliation" #typeReconciliationField="ngModel" [(ngModel)]="nature.typeReconciliation" required>
										<mat-option *ngFor="let typeReconciliation of listeTypesLien" [value]="typeReconciliation.code">{{ typeReconciliation.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>nature.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="nature.actif"><span translate>nature.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>nature.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="nature.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="card full">
			<div class="card-body card-padding m-t-20">
				<fieldset [disabled]="!(null | right:'creation') || !rightService.isRoot()">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>nature.listeMetiers.title</h4>
						</div>
					</div>
					<basket [model]="nature" propertyName="listeMetiers" type="fournisseurMetier" [labelAutocomplete]="'nature.listeMetiers.ajout' | translate" [labelListe]="'nature.listeMetiers.liste' | translate" [displayItem]="getLibelleMetier" [hasError]="isListeMetiersInvalid" (onAdd)="onAddMetier()"></basket>
				</fieldset>
			</div>
		</div>
		<span class="pull-right" *ngIf="rightService.isRoot()">
			<button mat-stroked-button color="primary" (click)="deleteNature();" *ngIf="nature?.idNature && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="natureForm.invalid" (click)="saveNature();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(nature?.idNature ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="nature?.idNature" [formDetectChanges]="{ doAction: close,isDirty: natureForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>