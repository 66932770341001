import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CodeListItemComponent } from './code-list-item.component';

@Component({
	templateUrl: './code-list.component.html'
})
export class CodeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,CodeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CodeListItemComponent>({
			uri: `/controller/Fournisseur/filtreCodes`,
			title: this.translateService.instant('fournisseur.code.liste.title'),
			component: CodeListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'interfaceFournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurInterface',
				},
				title: this.translateService.instant('search.formatInterface')
			},{
				clef: 'nature',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurNature',
				}
			},{
				clef: 'interfaceFournisseur.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur',
					filter: {
						typeReferentiel: TypeReferentiel.STANDARD
					}
				},
				title: this.translateService.instant('search.fournisseur')
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idCode) && this.liste.addItem({
					isDisplayed: true
				});
			})
		});
	}
}