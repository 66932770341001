<div class="menu" *ngIf="extraData?.hasChild || droit?.idDroit || droit?.consultation || droit?.creation || droit?.suppression || isEdition || isRoot">
	<span class="pull-left" [ngClass]="{ 'clickable': extraData?.hasChild === undefined && isEdition }" [translate]="extraData?.libelle" (click)="onFullRightClick()"></span>
	<div class="pull-right" *ngIf="!extraData?.isGroupe">
		<div class="checkbox" [ngClass]="{ 'checkbox-all': isRoot,'disabled': isEdition && droit?.userDroit && !droit?.userDroit.creation }" [tooltip]="(isRoot ? 'right.selectAll' : 'right.modification') | translate">
			<label>
				<input [disabled]="isEdition && droit?.userDroit && !droit?.userDroit.creation" type="checkbox" name="creation" #creation="ngModel" [(ngModel)]="droit.creation" (ngModelChange)="onDroitChange('creation')"/>
				<i class="input-circle-helper" [ngClass]="{ 'edit': !isRoot }"></i>
			</label>
		</div>
		<div class="checkbox" [ngClass]="{ 'checkbox-all': isRoot,'disabled': isEdition && droit?.userDroit && !droit?.userDroit.suppression }" [tooltip]="(isRoot ? 'right.selectAll' : 'right.suppression') | translate">
			<label>
				<input [disabled]="isEdition && droit?.userDroit && !droit?.userDroit.suppression" type="checkbox" name="suppression" #suppression="ngModel" [(ngModel)]="droit.suppression" (ngModelChange)="onDroitChange('suppression')"/>
				<i class="input-circle-helper" [ngClass]="{ 'delete': !isRoot }"></i>
			</label>
		</div>
		<div class="checkbox" [tooltip]="(isRoot ? 'right.selectAll' : 'right.consultation') | translate" [ngClass]="{ 'checkbox-all': isRoot,'disabled': isEdition && (this.droit.creation || this.droit.suppression) }">
			<label>
				<input [disabled]="isEdition && (droit.creation || droit.suppression)" type="checkbox" name="consultation" #consultation="ngModel" [(ngModel)]="droit.consultation" (ngModelChange)="onDroitChange('consultation')"/>
				<i class="input-circle-helper" [ngClass]="{ 'consult': !isRoot }"></i>
			</label>
		</div>
	</div>
</div>