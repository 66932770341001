<fieldset ngModelGroup="releveEnergieContent" #releveEnergieContent="ngModelGroup" [disabled]="!(TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'creation') || releveEnergie.typeSource == 'IMPORT' && releveEnergie.typeSourceReleve == 'FOURNISSEUR' || releveEnergie.readOnly || source == 'CONDUCTEUR'">
	<div class="alert alert-info" *ngIf="releveEnergie.readOnly && source != 'CONDUCTEUR'">
		<icon name="info"></icon>
		<div>
			<strong translate>vehicule.releveEnergie.alertes.nonModifiable.message</strong>
			<div translate>vehicule.releveEnergie.alertes.nonModifiable.description</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': dateReleve.invalid }" *ngIf="source == 'EQUIPEMENT'">
				<label translate>vehicule.releveEnergie.dateReleve</label>
				<div class="half">
					<input type="text" name="dateReleve" class="form-control" #dateReleve="ngModel" [(ngModel)]="releveEnergie.dateReleve" (ngModelChange)="onDateChange()" datePicker maxDate="today" format="datetime" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': releveEnergieContent.control?.controls['vehicule']?.invalid }" *ngIf="source != 'VEHICULE'">
				<label translate>vehicule.releveEnergie.vehicule</label>
				<div>
					<ng-container *ngIf="!releveEnergie.vehicule">
						<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" [(ngModel)]="releveEnergie.vehicule" (onSelect)="onVehiculeChange()" required></autocomplete>
					</ng-container>
					<div *ngIf="releveEnergie.vehicule">
						<mat-chip-listbox>
							<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (removed)="releveEnergie.vehicule = null" (click)="rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && showVehicule()">
								{{ releveEnergie.vehicule | display:'vehicule' }}
								<button matChipRemove *ngIf="(TypeDroit.VEHICULE_RELEVE_ENERGIE | right:'creation') && !releveEnergie.readOnly && (releveEnergie.typeSource != 'IMPORT' || releveEnergie.typeSourceReleve != 'FOURNISSEUR') && source != 'CONDUCTEUR'">
									<mat-icon>cancel</mat-icon>
								</button>
							</mat-chip>
							<icon name="replay_30" class="clickable icon-hc-2x p-l-5" *ngIf="releveEnergie.vehicule?.idVehicule && releveEnergie.vehicule.listeOldReferences?.length" (click)="vehiculeService.showListeImmatriculations(releveEnergie.vehicule.idVehicule)"></icon>
						</mat-chip-listbox>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="source != 'EQUIPEMENT' && releveEnergie.equipement">
				<label translate>vehicule.releveEnergie.equipement</label>
				<div>
					<mat-chip-listbox>
						<mat-chip [class.clickable]="TypeDroit.EQUIPEMENT | right:'consultation'" (click)="rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation') && redirectToEquipement()">
							{{ releveEnergie.equipement.reference + ' (' + releveEnergie.equipement.fournisseur.libelle + ')' }}						</mat-chip>
					</mat-chip-listbox>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateReleve.invalid }" *ngIf="source != 'EQUIPEMENT'">
				<label translate>vehicule.releveEnergie.dateReleve</label>
				<div class="half">
					<input type="text" name="dateReleve" class="form-control" #dateReleve="ngModel" [(ngModel)]="releveEnergie.dateReleve" datePicker maxDate="today" format="datetime" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': !(releveEnergie.volume > 0) || unite.invalid }">
				<label translate>vehicule.releveEnergie.volume</label>
				<div class="d-flex gap-1">
					<div class="flex-3">
						<input type="text" name="volume" class="form-control" [(ngModel)]="releveEnergie.volume" nNumber="3" min="0" required/>
					</div>
					<div class="flex-2">
						<autocomplete name="unite" type="unite" filter="CONTENANCE" #unite="ngModel" [(ngModel)]="releveEnergie.unite" required></autocomplete>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="releveEnergie.idReleve && source != 'CONDUCTEUR'">
				<label translate>vehicule.releveEnergie.userAffecte</label>
				<div>
					<span *ngIf="releveEnergie.extension?.userAffecte">{{ releveEnergie.extension.userAffecte | display:'user' }}</span>
					<span *ngIf="releveEnergie.extension?.vehiculeImmobilise" translate>vehicule.releveEnergie.liste.detail.vehiculeImmobilise</span>
					<span *ngIf="!releveEnergie.extension?.userAffecte && !releveEnergie.extension?.vehiculeImmobilise" translate>vehicule.releveEnergie.liste.detail.aucunUserAffecte</span>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': typeSource.invalid }">
				<label translate>vehicule.releveEnergie.source</label>
				<div class="half">
					<selectpicker name="typeSource" #typeSource="ngModel" [(ngModel)]="releveEnergie.typeSourceReleve" required>
						<mat-option *ngFor="let typeSource of listeTypesSource" [value]="typeSource.code">{{ typeSource.libelle }}</mat-option>
					</selectpicker>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }" *ngIf="releveEnergie.typeSourceReleve == 'FOURNISSEUR'">
				<label translate>vehicule.releveEnergie.fournisseur</label>
				<div>
					<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="releveEnergie.fournisseur" [disabled]="releveEnergie.typeSource == 'IMPORT' && releveEnergie.typeSourceReleve == 'FOURNISSEUR'" required></autocomplete>
				</div>
			</div>
			<div class="form-group" *ngIf="releveEnergie.detail && source != 'CONDUCTEUR'">
				<label translate>vehicule.releveEnergie.detailFacture</label>
				<div>
					<mat-chip-listbox>
						<mat-chip [class.clickable]="(TypeDroit.FACTURE | right:'consultation') || (TypeDroit.FACTURE_DETAIL | right:'consultation')" (click)="(rightService.hasRight(TypeDroit.FACTURE,'consultation') || rightService.hasRight(TypeDroit.FACTURE_DETAIL,'consultation')) && showFacture()">
							{{ (releveEnergie.detail?.nature ? releveEnergie.detail?.nature?.libelle : releveEnergie.detail?.libelleNature ? releveEnergie.detail?.libelleNature : releveEnergie.detail?.codeFournisseur?.libelle) + (releveEnergie.detail?.nature || releveEnergie.detail?.libelleNature ? ' (' + releveEnergie.detail?.codeFournisseur?.libelle + ')' : '') + ' : ' + (releveEnergie.detail.montant | currency:'.2-2':releveEnergie.detail.devise) + ' (' + releveEnergie.fournisseur.libelle + ' - ' + ('facture.numero' | translate:{ numero: releveEnergie.detail.facture.reference }) + ')' }}
						</mat-chip>
					</mat-chip-listbox>
				</div>
			</div>
			<div class="form-group" *ngIf="releveEnergie.idReleve">
				<label translate>vehicule.releveEnergie.priseMultiple</label>
				<div>
					<span *ngIf="releveEnergie.extension?.priseMultiple">
						<span translate>common.oui</span>
						<icon name="info_outline" class="m-l-5" [tooltip]="releveEnergie.extension.listeRelevesForDate?.join(' - ')"></icon>
					</span>
					<span *ngIf="!releveEnergie.extension?.priseMultiple" translate>common.non</span>
				</div>
			</div>
			<div class="form-group" *ngIf="releveEnergie.idReleve">
				<label translate>vehicule.releveEnergie.depassementReservoir</label>
				<div>
					<span *ngIf="releveEnergie.extension?.depassementReservoir">
						<span translate>common.oui</span>
						<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.releveEnergie.liste.detail.capaciteReservoir' | translate:{ capaciteReservoir: releveEnergie.vehicule.capaciteReservoir }"></icon>
					</span>
					<span *ngIf="!releveEnergie.extension?.depassementReservoir" translate>common.non</span>
				</div>
			</div>
		</div>
	</div>
</fieldset>