import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ContraventionModule } from './contravention/contravention.module';
import { DemandeIdentificationModule } from './demande-identification/demande-identification.module';

@NgModule({
	imports: [ShareModule,DemandeIdentificationModule,ContraventionModule],
	exports: [DemandeIdentificationModule,ContraventionModule]
})
export class InfractionModule { }