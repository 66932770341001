import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ListBasketOption } from 'src/app/domain/basket/basket';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GrilleAttributionService } from './grille-attribution.service';

@Component({
	selector: 'grille-attribution-edit',
	templateUrl: './grille-attribution-edit.component.html'
})
export class GrilleAttributionEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() grilleAttribution: any;

	/** Suppression de l'objet **/
	@Input() deleteGrilleAttribution: Function;

	/** Redirection vers la demande de véhicule d'origine **/
	@Input() goToDemandeVehicule: Function;

	/** Options pour le multi-paniers **/
	listeSousPaniers = new Array<ListBasketOption>({
		label: this.translateService.instant('vehicule.grilleAttribution.generalites.listeGroupes.collaborateur.label'),
		emptyLabel: this.translateService.instant('vehicule.grilleAttribution.generalites.listeGroupes.collaborateur.emptyLabel'),
		filterItems: item => item.entity === 'com.notilus.data.user.User',
		displayItem: item => item.libelle + ' (' + this.translateService.instant('common.'+(item.actif ? 'actif' : 'inactif')) + ')'
	},{
		label: this.translateService.instant('vehicule.grilleAttribution.generalites.listeGroupes.service.label'),
		emptyLabel: this.translateService.instant('vehicule.grilleAttribution.generalites.listeGroupes.service.emptyLabel'),
		filterItems: item => item.entity === 'com.notilus.data.service.Service',
		displayItem: item => item.libelle + ' (' + this.translateService.instant('common.'+(item.actif ? 'actif' : 'inactif')) + ')'
	});

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private grilleAttributionService: GrilleAttributionService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement d'une grille d'attribution
	 */
	saveGrilleAttribution() {
		//Enregistrement de la grille d'attribution
		this.grilleAttributionService.saveGrilleAttribution(this.grilleAttribution).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.grilleAttribution,result.data?.grilleAttribution);

					//Fermeture du formulaire
					this.close(this.grilleAttribution);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon)
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Vérification de la validité de la liste des groupes
	 */
	isListeGroupesInvalid: () => boolean = () => !this.grilleAttribution.listeGroupes?.length;
}