import { Component,Input,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AppState } from 'src/app/domain/appstate';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { AutocompleteAddComponent } from 'src/app/share/components/autocomplete/autocomplete-add.component';
import { EquipementAffectationService } from './equipement-affectation.service';
import { EquipementService } from './equipement.service';

@Component({
	selector: 'equipement-add',
	templateUrl: './equipement-add.component.html'
})
export class EquipementAddComponent extends AutocompleteAddComponent implements OnInit {
	/** Equipement **/
	@Input() equipement: any;

	/** Liste des statuts **/
	public listeStatuts: Array<{ code: string,libelle: string }>

	/** Liste des types d'équipement **/
	public listeTypes: Array<{ code: string,libelle: string }>;

	/** Liste des types d'affectation **/
	public listeTypesAffectation: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private equipementService: EquipementService,private equipementAffectationService: EquipementAffectationService,private translateService: TranslateService,private toastrService: ToastrService,public bsModalRef: BsModalRef<AutocompleteAddComponent>,private store: Store<AppState>) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des types d'équipement
		this.listeTypes = this.equipementService.getListeTypes();

		//Définition de la liste des statuts
		this.listeStatuts = this.equipementService.getListeStatuts();

		//Définition de la liste des types d'affectation
		this.listeTypesAffectation = this.equipementAffectationService.getListeTypes();

		//Sélection du tenant
		this.store.select(state => state.session.user?.tenant).subscribe({
			next: tenant => {
				//Création de l'équipement vierge
				this.equipement = {
					tenant,
					reference: this.filter.reference,
					fournisseur: this.filter.fournisseur,
					type: this.filter.type,
					statut: this.filter.statut,
					enabled: true,
					affectation: this.filter.source == 'FACTURE' && {} || null
				};
			}
		});
	}

	/**
	 * Enregistrement de l'élément
	 */
	saveItem() {
		//Enregistrement de l'élément
		this.equipementService.saveEquipement(this.equipement).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition de l'équipement ajouté
					this.addedItem = result.data.equipement;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du fournisseur
					listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));

					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du numéro
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du numéro
						listeDoublons.push(this.translateService.instant('actions.doublon.numero'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}