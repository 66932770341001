import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RegleAutomatiqueService } from './regle-automatique.service';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './regle-automatique-list-item.component.html'
})
export class RegleAutomatiqueListItemComponent extends ListViewItem<any> implements OnInit {
	/**
	 * Constructeur
	 */
	constructor(private regleAutomatiqueService: RegleAutomatiqueService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types
		this.regleAutomatiqueService.retrieveListeTypesComputedField().pipe(first()).subscribe({
			next: (listeTypes: any) => {
				//Définition des informations du type
				this.data.typeComputedField = listeTypes.find(type => type.code == this.data.type);
			}
		})
	}

	/**
	 * Suppression de l'objet
	 */
	deleteRegleAutomatique() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.regleAutomatiqueService.deleteRegleAutomatique(this.data))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.regleAutomatique.RegleAutomatique',this.data.idRegleAutomatique);
	}

	/**
	 * Duplication de la règle automatique
	 */
	public duplicateRegleAutomatique() {
		//Navigation vers la page de duplication
		this.layoutService.goToByState('listeReglesAutomatiques-regleAutomatique',{
			routeParams: {
				idRegleAutomatique: 0
			},
			routeData: {
				idRegleAutomatiqueOrigine: this.data.idRegleAutomatique
			},
			reloadOnSameUrl: true
		});
	}
}