<div class="modal-header">
	<h4 class="modal-title"><span translate>dataCustomization.fields.title</span></h4>
</div>
<div class="modal-body">
	<div class="listview">
		<div class="lv-body">
			<bs-sortable *ngIf="listeFields" name="listeFields" [(ngModel)]="listeFields" [itemTemplate]="itemTemplate" itemActiveClass="invisible"></bs-sortable>

			<div class="lv-item media text-center m-t-20 lv-item-add" matRipple (click)="showListeFields()">
				<h5><a [routerLink]="[]" translate>dataCustomization.fields.actions.ajouterField</a></h5>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()"><span translate>actions.annuler</span></button>
	<button mat-raised-button color="primary" (click)="saveDataCustomization()"><span translate>actions.enregistrer</span></button>
</div>
<ng-template #itemTemplate let-field="item" let-index="index">
	<div class="lv-item media p-0">
		<div class="sortable-item">
			<div>
				<span class="handle"><icon class="icon-hc-2x" name="open_with"></icon></span>
				<span>{{ field.value.libelle }}</span>
			</div>
			<ul>
				<li *ngIf="index != listeFields?.length - 1">
					<button mat-icon-button color="primary" (click)="moveField(index,1);">
						<mat-icon>arrow_circle_down</mat-icon>
					</button>
				</li>
				<li *ngIf="index != 0">
					<button mat-icon-button color="primary" (click)="moveField(index,-1);">
						<mat-icon>arrow_circle_up</mat-icon>
					</button>
				</li>
				<li>
					<button mat-icon-button color="primary" (click)="deleteField(index);">
						<mat-icon>delete</mat-icon>
					</button>
				</li>
			</ul>
		</div>
	</div>
</ng-template>