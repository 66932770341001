<form #clientCRMForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!clientCRM.isDisplayed">
		<div class="tile-sub">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>clientCRM.raisonSociale</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="clientCRM.libelle" (ngModelChange)="onLibelleChange($event)" [disabled]="clientCRM.readOnly" required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': libelleCourt.invalid }">
							<label translate>clientCRM.libelleCourt.item</label>
							<div>
								<div class="input-group">
									<input type="text" class="form-control" name="libelleCourt" #libelleCourt="ngModel" [(ngModel)]="clientCRM.libelleCourt" required maxlength="50" [disabled]="clientCRM.readOnly && savedClientCRM.libelleCourt?.length" pattern="[^\s]*"/>
									<div class="input-group-addon">
										<icon name="info_outline" [tooltip]="'clientCRM.libelleCourt.info' | translate"></icon>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
							<label translate>clientCRM.reference</label>
							<div>
								<input type="text" class="form-control" name="reference" #reference="ngModel" [(ngModel)]="clientCRM.reference" required maxlength="100" [disabled]="clientCRM.readOnly"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': facturationId.invalid }">
							<label translate>clientCRM.facturationId</label>
							<div>
								<input type="text" class="form-control" name="facturationId" #facturationId="ngModel" [(ngModel)]="clientCRM.facturationId" maxlength="100" required/>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label translate>clientCRM.actif</label>
							<div>
								<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="clientCRM.actif"><span translate>clientCRM.actifDescription</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group">
							<label translate>clientCRM.piecesJointes</label>
							<div>
								<div [attachment]="clientCRM" [typeAttachment]="TypeAttachment.CLIENT_CRM"></div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: clientCRMForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
			<button mat-stroked-button color="primary" (click)="deleteClientCRM();" *ngIf="clientCRM.idClient && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveClientCRM();" *ngIf="(null | right:'creation')" [disabled]="clientCRMForm.invalid">
				<span translate>actions.enregistrer</span>
			</button>
		</div>
	</div>
</form>