import { Directive,Input,Output,EventEmitter } from '@angular/core';

@Directive()
export abstract class DashboardItem {
	/** Graphique courant **/
	@Input() dashboardChart: any;

	/** Indicateur d'édition **/
	@Input() isEdition: boolean = false;

	/** Identifiant de l'objet filtrant **/
	@Input() idFilteringObject?: number;

	/** Classe de l'entité filtrante **/
	@Input() filteringEntity?: string;

	/** Gestion de la suppression du graphique **/
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

	/** Gestion de la personnalisation du graphique **/
	@Output() onCustomize: EventEmitter<any> = new EventEmitter<any>();
}