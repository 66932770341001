import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ChartService } from './chart.service';
import { ChartComponent } from './chart.component';
import { ChartTipComponent } from './chart-tip.component';
import { ChartMaximizerComponent } from './chart-maximizer.component';
import { AttachmentModule } from 'src/app/share/components/attachment/attachment.module';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { PleaseWaitModule } from 'src/app/share/components/please-wait/please-wait.module';
import { KpiComponent } from './kpi.component';

@NgModule({
	imports: [CoreComponentsModule,AttachmentModule,IconModule,PleaseWaitModule],
	declarations: [ChartComponent,ChartTipComponent,ChartMaximizerComponent,KpiComponent],
	exports: [ChartComponent],
	providers: [ChartService]
})
export class ChartModule {}