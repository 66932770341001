import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { DataCustomizationService } from './data-customization.service';

@Component({
	selector: 'datadata-customization-ref-value-edit',
	templateUrl: './data-customization-ref-value-edit.component.html'
})
export class DataCustomizationRefValueEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() customRefValue: any;

	/** Suppression de l'objet **/
	@Input() deleteCustomRefValue?: (close: Function) => void;

	/** Numéro de référentiel **/
	@Input() num: number;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private dataCustomizationService: DataCustomizationService) {
		//Héritage
		super();

		//Binding des méthodes
		this.close = this.close?.bind?.(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Vérification de la présence de l'entité
		if (!this.customRefValue.entite && this.customRefValue.dataCustomizationEntite)
			//Ajout de l'entité
			this.customRefValue.entite = this.customRefValue.dataCustomizationEntite.entite;

		//Vérification de la présence du type de référentiel
		if (!this.customRefValue.idCustomRefValue && !this.customRefValue.typeReferentiel)
			//Ajout du type de référentiel
			this.customRefValue.typeReferentiel = 'REFERENTIEL_'+this.num;
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveCustomRefValue() {
		//Enregistrement de l'objet
		this.dataCustomizationService.saveCustomRefValue(this.customRefValue?.dataCustomizationEntite?.idDataCustomizationEntite,this.customRefValue).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					this.customRefValue = Object.assign(this.customRefValue,result.data?.customRefValue);

					//Fermeture du formulaire
					this.close?.(this.customRefValue);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Ajout de l'offre
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}