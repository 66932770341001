<div class="modal-header">
	<h4 class="modal-title" translate>dashboard.actions.ajouterElement</h4>
</div>
<div class="modal-body">
	<div class="panel panel-default" *ngIf="listeMenuItems.length">
		<div class="panel-body">
			<ol class="breadcrumb p-l-0 b-0 m-b-0" *ngIf="listeOpenedMenuItems.length">
				<li *ngFor="let menuItem of listeOpenedMenuItems; let idxMenuItem = index;">
					<a [routerLink]="[]" (click)="goBackTo(idxMenuItem)" [translate]="menuItem.libelle"></a>
				</li>
			</ol>
			<ul class="list-group" *ngIf="listeOpenedMenuItems.length">
				<li class="list-group-item" *ngFor="let menuItem of getListeVisibleMenuItems(listeOpenedMenuItems[listeOpenedMenuItems.length - 1])">
					<ng-container *ngIf="menuItem.listeItems?.length || menuItem.creationState?.[0]?.creationState">
						<span class="clickable" (click)="openMenuItem(menuItem);">
							<a [routerLink]="[]">{{ (menuItem.libelle | translate) + ' (' + (getListeVisibleMenuItems(menuItem)?.length || 0) + ')' }}</a>
						</span>
						<div class="clickable" (click)="openMenuItem(menuItem);">
							<icon name="chevron_right" class="icon-hc-2x clickable"></icon>
						</div>
					</ng-container>
					<ng-container *ngIf="!menuItem.listeItems?.length && !menuItem.creationState?.[0]?.creationState">
						<span>
							<a [routerLink]="[]" (click)="doCreation(menuItem)" [translate]="menuItem.libelle"></a>
						</span>
						<div>
							<a [routerLink]="[]" (click)="selectFavoris(menuItem);">
								<icon class="icon-hc-2x clickable" [name]="listeSelectedFavoris.indexOf(menuItem.creationState) == -1 ? 'star_outline' : 'star'"></icon>
							</a>
						</div>
					</ng-container>
				</li>
			</ul>
			<ul class="list-group" *ngIf="listeOpenedMenuItems.length == 0">
				<li class="list-group-item clickable" *ngFor="let menuItem of getListeFilteredMenuItems(listeMenuItems)" (click)="openMenuItem(menuItem);">
					<span>
						<a [routerLink]="[]">{{ (menuItem.libelle | translate) + ' (' + (menuItem.listeItems?.length || 0) + ')' }}</a>
					</span>
					<div>
						<icon name="chevron_right" class="icon-hc-2x clickable"></icon>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="tile text-center p-10" *ngIf="!listeMenuItems?.length">
		<h5 translate>liste.empty</h5>
	</div>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
</div>