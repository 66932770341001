<form #sgiLotForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="tile-active-show collapse" [collapse]="!sgiLot.isDisplayed">
		<div class="tile-sub">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label translate>sgi.lot.typeLot.item</label>
						<div>
							<span [translate]="'sgi.lot.typeLot.' + sgiLot.typeLot"></span>
						</div>
					</div>
					<div class="form-group">
						<label translate>sgi.lot.dateCreationApplication</label>
						<div>
							<span>{{ sgiLot.dateCreationApplication | date:'short' }}</span>
						</div>
					</div>
					<div class="form-group">
						<label translate>sgi.lot.identifiant</label>
						<div>
							<span>{{ sgiLot.idLot }}</span>
						</div>
					</div>
					<div class="form-group" *ngIf="sgiLot.closed">
						<label translate>sgi.lot.etat.item</label>
						<div>
							<span translate>sgi.lot.etat.cloture</span>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label translate>sgi.lot.reference</label>
						<div>
							<span>{{ sgiLot.reference }}</span>
						</div>
					</div>
					<div class="form-group">
						<label translate>sgi.lot.dateCreation</label>
						<div>
							<span>{{ sgiLot.dateCreation | date:'short' }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tile-footer">
			<button mat-button [formDetectChanges]="{ doAction: close,isDirty: sgiLotForm.dirty }">
				<span translate>actions.fermer</span>
			</button>
		</div>
	</div>
</form>