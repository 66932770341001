import { Component,EventEmitter,forwardRef,Input,OnInit,Output } from '@angular/core';
import { ControlValueAccessor,UntypedFormControl,NG_VALIDATORS,NG_VALUE_ACCESSOR } from '@angular/forms';

import { AutocompleteService } from 'src/app/share/components/autocomplete/autocomplete.service';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

@Component({
	selector: 'amount',
	templateUrl: './amount.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AmountComponent),
		multi: true
	},{
		provide: NG_VALIDATORS,
		useExisting: AmountComponent,
		multi: true
	}]
})
export class AmountComponent implements ControlValueAccessor,OnInit {
	/** Devise **/
	@Input() devise: string;

	/** Interception de la modification de devise **/
	@Output() onDeviseChange: EventEmitter<string> = new EventEmitter<string>();

	/** Valeur **/
	public _amountValue: number;

	/** Devise sélectionnée **/
	public _deviseValue: any;

	/** Options du sélecteur de devises **/
	public deviseOptions: AutocompleteOptions;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	private isInit: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private autocompleteService: AutocompleteService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des options des devises
		this.deviseOptions = this.autocompleteService.getOptionsForType('devise');

		//Définition de la devise sélectionnée
		this._deviseValue = this.devise && {
			code: this.devise
		} || null;

		//Modification de l'affichage
		this.deviseOptions.displayItem = (item: any) => item?.code;
	}

	/**
	 * Ecriture de la valeur
	 */
	writeValue(value: any): void {
		//Définition de la valeur
		this.value = value;
	}

	/**
	 * Enregistrement de la fonction d'interception du changement
	 */
	registerOnChange(fn: any): void {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement de la fonction d'interception de l'appui
	 */
	registerOnTouched(fn: any): void {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Lecture de la valeur
	 */
	get value(): any {
		//Retour de la valeur
		return this._amountValue;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Mise à jour de la valeur
		setTimeout(() => {
			//Définition de la valeur interne
			this._amountValue = value;

			//Définition de l'indicateur d'initialisation du composant
			this.isInit = true;
		});
	}

	/**
	 * Interception d'un changement de valeur
	 */
	onValueChange() {
		//Vérification de l'initialisation
		if (this.isInit) {
			//Mise à jour de la valeur
			this.value = this._amountValue;

			//Déclenchement de la modification vers le modèle parent
			this.onChange(this.value);
		}
	}

	/**
	 * Interception d'un changement de devise
	 */
	onInternalDeviseChange() {
		//Déclenchement de la modification vers le modèle parent
		this.onChange(this.value);

		//Déclenchement du changement de devise
		this.onDeviseChange.emit(this._deviseValue.code);
	}

	/**
	 * Validation des données
	 */
	validate({ value }: UntypedFormControl) {
		//Validation de la valeur
		return (value === null || value === undefined || this._deviseValue === null || this._deviseValue === undefined) && {
			invalid: true
		};
	}
}