import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { CompteCompteAuxiliaireListItemComponent } from './compte-compte-auxiliaire-list-item.component';

@Component({
	selector: 'compte-compte-auxiliaire-list',
	templateUrl: './compte-compte-auxiliaire-list.component.html'
})
export class CompteCompteAuxiliaireListComponent extends PageContentItem implements OnInit {
	/** Compte **/
	@Input() compte: any;

	/** Résumé **/
	@Input() resume: { nbComptesAuxiliaires: number };

	/** Restriction sur le plan comptable **/
	@Input() restriction: { isRestricted: boolean } = null;

	/** Données **/
	liste: ListView<any,CompteCompteAuxiliaireListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,CompteCompteAuxiliaireListItemComponent>({
			uri: () => `/controller/Compte/filtreComptesAuxiliaires`,
			component: CompteCompteAuxiliaireListItemComponent,
			isLocal: true,
			extraOptions: {
				restriction: this.restriction
			},
			listeFilters: [{
				clef: 'numero',
				isDefault: true
			},{
				clef: 'libelle',
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'compte.idCompte',
				type: TypeFilter.LONG,
				valeur: this.compte.idCompte
			}]
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idCompteAuxiliaire > 0)
			//Mise à jour des compteurs
			this.resume.nbComptesAuxiliaires--;
	}
}