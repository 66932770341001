<div class="pull-left lv-avatar">
	<icon [name]="data.type == 'VEHICULE' ? 'directions_car' : 'person'"></icon>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addAffectation?.(data,deleteAffectation)">
			<span *ngIf="data.type == 'VEHICULE'">{{ data.vehicule | display:'vehicule' }}</span>
			<span *ngIf="data.type == 'COLLABORATEUR'">{{ data.user | display:'user' }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idAffectation">
		<li *ngIf="data.dateDebut"><span translate>equipement.affectation.dateDebut</span>&#160;:&#160;<span>{{ data.dateDebut | date:'short' }}</span></li>
		<li *ngIf="data.dateFin"><span translate>equipement.affectation.dateFin</span>&#160;:&#160;<span>{{ data.dateFin | date:'short' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idAffectation">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="TypeDroit.EQUIPEMENT | right:'creation'"><a [routerLink]="[]" (click)="deleteAffectation();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="data.type == 'VEHICULE' && (TypeDroit.ADMIN_VEHICULE | right:'consultation')"><a [routerLink]="[]" (click)="goToVehicule();"><span translate>equipement.affectation.actions.accederVehicule</span></a></li>
			<li *ngIf="data.type == 'COLLABORATEUR' && (TypeDroit.VEHICULE_CONDUCTEUR | right:'consultation')"><a [routerLink]="[]" (click)="goToConducteur();"><span translate>equipement.affectation.actions.accederConducteur</span></a></li>
		</ul>
	</div>
</div>