import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleTaxeListItemComponent } from './regle-taxe-list-item.component';
import { RegleTaxeService } from './regle-taxe.service';

@Component({
	templateUrl: './regle-taxe-list.component.html'
})
export class RegleTaxeListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,RegleTaxeListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private regleTaxeService: RegleTaxeService,private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,RegleTaxeListItemComponent>({
			uri: `/controller/Fiscalite/filtreReglesTaxes`,
			title: this.translateService.instant('fiscalite.regleTaxe.liste.title'),
			component: RegleTaxeListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'pays',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'pays',
					fieldForDefault: 'libelle,code2'
				},
				isDefault: true
			},{
				clef: 'type',
				type: TypeFilter.LONG,
				title: this.translateService.instant('search.criteres'),
				listeValues: this.regleTaxeService.getListeTypes()
			}],
			defaultOrder: 'libelle',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => this.layoutService.goToByUrl('/Fiscalite/loadRegleTaxe/0')
		});
	}
}