<div class="pull-left lv-avatar">
	<div *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE">
		<div *ngIf="!data.dateSortie || data.dateSortie > today">
			<icon name="person" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_FONCTION'"></icon>
			<icon name="apartment" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_SERVICE'"></icon>
			<icon name="lock" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_IMMOBILISE'"></icon>
			<icon name="event" *ngIf="data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_POOL'"></icon>
			<icon name="question_mark" *ngIf="!data.extension?.affectationCourante?.typeAffectation"></icon>
		</div>
		<icon name="logout" *ngIf="data.dateSortie && data.dateSortie <= today"></icon>
	</div>
	<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_PERSO" translate>vehicule.liste.avatar.perso</span>
	<span *ngIf="data.typeVehicule == TypeVehicule.ENGIN" translate>vehicule.liste.avatar.engin</span>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="goToVehicule();">{{ data | display:'vehicule' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.typeVehicule != TypeVehicule.ENGIN"><span translate>vehicule.affectation.item</span>&#160;:
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_PERSO">{{ data.user | display:'user' }}</span>
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE && (!data.extension?.affectationCourante || data.extension?.affectationCourante?.typeAffectation != 'VEHICULE_IMMOBILISE')">
				<span *ngIf="data.extension?.userAffecte">{{ data.extension.userAffecte | display:'user' }}</span>
				<span *ngIf="!data.extension?.userAffecte" translate>common.aucune</span>
			</span>
			<span *ngIf="data.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE && data.extension?.affectationCourante?.typeAffectation == 'VEHICULE_IMMOBILISE'" translate>vehicule.vehiculeImmobilise</span>
		</li>
		<li *ngIf="data.dateEntree"><span translate>vehicule.dateEntree.item</span>&#160;:&#160;{{ data.dateEntree | date:'short' }}</li>
		<li *ngIf="data.dateSortie"><span translate>vehicule.dateSortie.item</span>&#160;:&#160;{{ data.dateSortie | date:'short' }}</li>
		<li *ngIf="data.pays && data.pays.code2 != user?.pays?.code2"><span translate>vehicule.pays</span>&#160;:&#160;<span class="fi m-r-5" [tooltip]="data.pays.libelle" [ngClass]="'fi-'+(data.pays.code2?.toLowerCase() != 'ja' ? data.pays.code2.toLowerCase() : 'jp')"></span></li>
	</ul>
</div>