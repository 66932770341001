import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

import { MailHistoryComponent } from './mail-history.component';
import { Result } from 'src/app/domain/common/http/result';
import { MailHistoryDetailComponent } from './mail-history-detail.component';
import { environment } from 'src/environments/environment';

/**
 * Service de gestion de l'historique des mails
 */
@Injectable()
export class MailHistoryService {
	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService,private http: HttpClient) {}

	/**
	 * Affichage de l'historique des mails
	 */
	public showMailHistory(className: string,idObject: number,idSecondaryObject: number = null): Observable<any> {
		let bsModalRef: BsModalRef<MailHistoryComponent>;

		//Ouverture de la pop-up
		bsModalRef = this.bsModalService.show(MailHistoryComponent,{
			initialState: {
				className,
				idObject,
				idSecondaryObject
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden;
	}

	/**
	 * Chargement d'un historique de mail
	 */
	public loadMailHistory(idMailHistory: number): Observable<Result> {
		//Récupération d'un historique de mail
		return this.http.post<Result>(`${environment.baseUrl}/controller/MailHistory/loadHistory/${idMailHistory}`,null);
	}

	/**
	 * Chargement du contenu d'un mail
	 */
	public loadMailHistoryContent(idMailHistory: number): Observable<Result> {
		//Récupération du contenu d'un mail
		return this.http.post<Result>(`${environment.baseUrl}/controller/MailHistory/loadContent/${idMailHistory}`,null);
	}

	/**
	 * Affichage du détail de l'historique du mail
	 */
	public showMailHistoryDetail(idMailHistory: number) {
		//Chargement de l'historique du mail
		this.loadMailHistory(idMailHistory).pipe(take(1)).subscribe({
			next: result => {
				//Vérification du résultat
				if (result.data.mailHistory) {
					//Ouverture de la pop-up
					this.bsModalService.show(MailHistoryDetailComponent,{
						initialState: {
							data: result.data.mailHistory,
							listeDestinatairesPrincipaux: result.data.mailHistory.listeDestinataires.filter(d => d.type === 'PRINCIPAL').sort((a,b) => a.email > b.email ? 1 : -1),
							listeDestinatairesCopie: result.data.mailHistory.listeDestinataires.filter(d => d.type === 'COPIE').sort((a,b) => a.email > b.email ? 1 : -1)
						},
						class: 'modal-max'
					});
				}
			}
		})
	}
 }