import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { GrilleAttributionModeleFinancementListItemComponent } from './grille-attribution-modele-financement-list-item.component';

@Component({
	selector: 'grille-attribution-modele-financement-list',
	templateUrl: './grille-attribution-modele-financement-list.component.html'
})
export class GrilleAttributionModeleFinancementListComponent extends PageContentItem implements OnInit {
	/** Modèle de la grille d'attribution **/
	@Input() grilleAttributionModele: any;

	/** Fonction d'édition **/
	@Input() addFinancement: () => void;

	/** Resumé **/
	@Input() resume: { nbFinancements: number };

	/** Données **/
	liste: ListView<any,GrilleAttributionModeleFinancementListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,GrilleAttributionModeleFinancementListItemComponent>({
			uri: () => `/controller/Vehicule/filtreFinancementsForGrilleAttributionModele/${this.grilleAttributionModele.idGrilleAttribution}/${this.grilleAttributionModele.idModele}`,
			component: GrilleAttributionModeleFinancementListItemComponent,
			isLocal: true,
			extraOptions: {
				addFinancement: this.addFinancement
			},
			defaultOrder: 'fournisseur.libelle'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idFinancement > 0)
			//Mise à jour des compteurs
			this.resume.nbFinancements -= 1;
	}
}