import { ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output,TemplateRef,ViewChild } from '@angular/core';

@Component({
	selector: 'floating-button-item',
	templateUrl: './floating-button-item.component.html',
	styleUrls: ['./floating-button-item.component.scss']
})
export class FloatingButtonItemComponent implements OnInit {
	/** Libellé **/
	@Input() label: string;

	/** Icône par défaut **/
	@Input() icon: string;

	/** Famille d'icône par défaut **/
	@Input() iconType?: string;

	/** Gestion du clic **/
	@Output() click: EventEmitter<any> = new EventEmitter<any>();

	/** Template de l'élément **/
	@ViewChild('floatingButtonItemTemplate')
	public template: TemplateRef<any>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}
}