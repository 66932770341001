<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data?.tenant?.code?.substring(0,3)?.toUpperCase() }}</div>
<div class="lv-title">
	<a [routerLink]="['/Licence/loadLicenceConsommation',data.idLicenceConsommation]">{{ data?.tenant?.nom + ' - ' + data?.tenant?.code + ' (' + (data.periode | date:'MMMM YYYY') + ')' }}</a>
</div>
<ul class="lv-attrs" *ngIf="data.idLicenceConsommation">
	<li *ngFor="let type of data.listeTypes"><span [translate]="'licence.consommation.type.' + type.type"></span>&#160;:&#160;<span>{{ type.quantite | number }}</span></li>
	<li *ngIf="data.dateCalcul"><span translate>licence.consommation.dateCalcul</span>&#160;:&#160;<span>{{ data.dateCalcul | date:'short' }}</span></li>
</ul>
<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
	<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
	<ul class="dropdown-menu dropdown-menu-right">
		<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteLicenceConsommation();"><span translate>actions.supprimer</span></a></li>
		<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
	</ul>
</div>