import { Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ChartOptions } from 'src/app/domain/chart/chart';
import { Page } from 'src/app/domain/common/http/list-result';

@Component({
	templateUrl: './chart-maximizer.component.html'
})
export class ChartMaximizerComponent implements OnInit {
	/** Options du graphique **/
	@Input() options: ChartOptions;

	/** Données **/
	@Input() data: Page<any>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<ChartMaximizerComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition du mode d'affichage
		this.options.isModal = true;
	}
}