<div class="modal-header">
	<h4 class="modal-title" translate>scheduler.title</h4>
</div>
<div class="modal-body">
	<form #schedulerForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!isModification">
			<div class="form-group" [ngClass]="{ 'has-error': frequence.invalid }">
				<label translate>scheduler.frequence.item</label>
				<selectpicker name="frequence" #frequence="ngModel" [(ngModel)]="scheduler.type" required>
					<mat-option *ngFor="let frequence of listeFrequences" [value]="frequence"><span [translate]="'scheduler.frequence.'+frequence"></span></mat-option>
				</selectpicker>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': startTime.invalid }" *ngIf="scheduler.type != TypeFrequence.NON_DEFINI">
				<label translate>scheduler.demarrage</label>
				<div class="half">
					<input type="text" class="form-control" name="startTime" #startTime="ngModel" [(ngModel)]="scheduler.startTime" datePicker format="datetime" required/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': !isListeJoursValid() }" *ngIf="scheduler.type == TypeFrequence.HEBDOMADAIRE">
				<label translate>scheduler.jours.item</label>
				<div *ngFor="let jour of listeJours; let idxJour = index;">
					<mat-checkbox [name]="'jour_'+idxJour" [(ngModel)]="jour.isSelected"><span [translate]="'scheduler.jours.'+jour.code"></span></mat-checkbox>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': !isListeMoisValid() }" *ngIf="scheduler.type == TypeFrequence.MULTI_ANNUEL">
				<label translate>scheduler.mois.item</label>
				<div *ngFor="let mois of listeMois; let idxMois = index;">
					<mat-checkbox [name]="'mois_'+idxMois" [(ngModel)]="mois.isSelected"><span [translate]="'scheduler.mois.'+mois.code"></span></mat-checkbox>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': mois.invalid }" *ngIf="scheduler.type == TypeFrequence.ANNUEL">
				<label translate>scheduler.enMois</label>
				<div>
					<selectpicker name="mois" #mois="ngModel" [(ngModel)]="scheduler.mois" (ngModelChange)="scheduler.numJour = null" required>
						<mat-option *ngFor="let mois of listeMoisForSelection" [value]="mois"><span [translate]="'scheduler.mois.'+mois"></span></mat-option>
					</selectpicker>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': numJour.invalid }" *ngIf="scheduler.type == TypeFrequence.MENSUEL || scheduler.type == TypeFrequence.ANNUEL || scheduler.type == TypeFrequence.MULTI_ANNUEL">
				<label translate>scheduler.leJour</label>
				<div class="half">
					<input type="text" class="form-control" name="numJour" #numJour="ngModel" [(ngModel)]="scheduler.numJour" nNumber min="1" [max]="getMaxNumJour()" required/>
				</div>
			</div>
		</fieldset>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" (click)="saveScheduler()" *ngIf="isModification" [disabled]="schedulerForm.invalid || !isListeJoursValid()">
		<span translate>actions.continuer</span>
	</button>
</div>