import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteLineComponent } from './autocomplete-line.component';
import { AutocompleteLineItemComponent } from './autocomplete-line-item.component';
import { AutocompleteService } from './autocomplete.service';
import { PaysLineItemComponent } from './options/pays.line-item';
import { PaysSearchItemComponent } from './options/pays.search-item';
import { AffectationSearchItemComponent } from './options/affectation.search-item';
import { ReservationSearchItemComponent } from './options/reservation.search-item';
import { VehiculeLineItemComponent } from './options/vehicule.line-item';
import { FournisseurContactSearchItemComponent } from './options/fournisseur-contact.search-item';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { GenreLineItemComponent } from './options/genre.line-item';
import { CarburantLineItemComponent } from './options/carburant.line-item';
import { TypeCarrosserieLineItemComponent } from './options/type-carrosserie.line-item';
import { TypeTransmissionLineItemComponent } from './options/type-transmission.line-item';
import { TypeBoiteLineItemComponent } from './options/type-boite.line-item';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [AutocompleteComponent,AutocompleteLineComponent,AutocompleteLineItemComponent
		,PaysLineItemComponent,PaysSearchItemComponent,AffectationSearchItemComponent,ReservationSearchItemComponent
		,VehiculeLineItemComponent,FournisseurContactSearchItemComponent,GenreLineItemComponent,CarburantLineItemComponent
		,TypeCarrosserieLineItemComponent,TypeTransmissionLineItemComponent,TypeBoiteLineItemComponent
	],
	exports: [AutocompleteComponent],
	providers: [AutocompleteService]
})
export class AutocompleteModule {}