import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { Action,ActionMasse } from 'src/app/domain/common/list-view/action';
import { ListItem } from 'src/app/domain/common/list-view/list-item';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ListViewService } from './list-view.service';

@Component({
	selector: 'list-view-actions',
	templateUrl: './list-view-actions.component.html'
})
export class ListViewActionsComponent<T extends ListItem,K extends ListViewItem<T>> {
	/** Liste à afficher **/
	@Input() liste: ListView<T,K>;

	/**
	 * Constructeur
	 */
	constructor(private listViewService: ListViewService<T>,private translateService: TranslateService,private layoutService: LayoutService) {
	}

	/**
	 * Sélection de tous les éléments
	 */
	selectAll() {
		//Définition du mode 'FULL'
		this.liste.typeActionMasse = 'FULL';

		//Mise à jour du compteur
		this.liste.nbSelectedItems = this.liste.data.totalElements;

		//Déselection des éléments sélectionnés
		this.liste.data.content.forEach(item => item.isSelected = false);
	}

	/**
	 * Désélection de tout les éléments
	 */
	unselectAll() {
		//Définition du mode 'SELECTION'
		this.liste.typeActionMasse = 'SELECTION';

		//Mise à jour du compteur
		this.liste.nbSelectedItems = 0;

		//Déselection des éléments sélectionnés
		this.liste.data.content.forEach(item => item.isSelected = false);
	}

	/**
	 * Récupération de la liste des actions de masse visibles
	 */
	getListeActions(): Array<Action> {
		//Retour de la liste des actions de masse visibles
		return this.liste.listeActions.filter(a => !a.isVisible || a.isVisible());
	}

	/**
	 * Réalisation d'une action
	 */
	doAction(action: Action) {
		let actionMasse: ActionMasse;
		let messagingOptions: MessagingOptions;

		//Génération de l'action de masse
		actionMasse = this.listViewService.getActionMasse(this.liste);

		//Vérification de la présence d'une WebSocket
		messagingOptions = action.messagingOptions || {};

		//Définition de l'action de masse en tant que paramètre pour l'appel à la WebSocket
		messagingOptions.params = actionMasse;

		//Définition du point de réception des messages
		messagingOptions.outputPoint = messagingOptions.outputPoint || '/messaging/doMasseAction/status';

		//Définition de la configuration de la progression
		messagingOptions.progressConfig = {
			icone: action.icon,
			libelle: action.title || this.liste.title || this.layoutService.getExtendedRouteData()?.title && typeof this.layoutService.getExtendedRouteData()?.title == 'string' && this.translateService.instant(this.layoutService.getExtendedRouteData()?.title as string)
		}

		//Réalisation de l'action
		action.onPress(actionMasse,messagingOptions);
	}
}
