import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteService } from 'src/app/components/comptabilite/compte/compte.service';

@Component({
	selector: 'service-facturation-edit',
	templateUrl: './service-facturation-edit.component.html'
})
export class ServiceFacturationEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() lienFacturation: any;

	/** Fonction de suppression **/
	@Input() deleteLienFacturation: (lienFacturation: any,close: Function) => void;

	/** Enumération des types de droit **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private compteService: CompteService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement de l'objet
	 */
	saveLienFacturation() {
		//Enregistrement du facturation
		this.compteService.saveCompteFacturation(this.lienFacturation).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.lienFacturation,result.data?.compteFacturation);

					//Fermeture de la fenêtre
					this.close({ lienFacturation: this.lienFacturation });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout du code
					listeDoublons.push(this.translateService.instant(`actions.doublon.code`));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}