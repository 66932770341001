import { Type } from '@angular/core';
import { ApexAxisChartSeries,ApexChart,ApexDataLabels,ApexFill,ApexGrid,ApexLegend,ApexMarkers,ApexPlotOptions,ApexStroke,ApexTooltip,ApexXAxis,ApexYAxis } from 'ng-apexcharts';

import { ChartTipComponent } from 'src/app/share/components/chart/chart-tip.component';

/**
 * Modèle représéntant les options d'un chart
 */
export interface ChartOptions {
	/** Type de chart **/
	type: TypeChart;

	/** Sous-type de chart **/
	subType?: SubTypeChart;

	/** Type d'agrégation **/
	typeAgregation?: TypeAgregation;

	/** Titre **/
	title: string;

	/** Lecture seule **/
	readOnly?: boolean;

	/** Couleur à utiliser **/
	color?: string;

	/** Seuil minimum **/
	seuilMin?: number;

	/** Seuil maximum **/
	seuilMax?: number;

	/** Agencement de la grille **/
	grid: 'HORIZONTAL' | 'VERTICAL' | 'BOTH';

	/** Affichage des axes **/
	axis: 'HORIZONTAL' | 'VERTICAL' | 'BOTH';

	/** Indicateur d'absence de données **/
	hasNoData?: boolean;

	/** Message d'absence de données **/
	noDataMessage: string;

	/** Présence d'inter-actions **/
	hasInteractions?: boolean;

	/** Inter-actions entre graphiques **/
	hasInterGraphiques?: boolean;

	/** Accès aux données **/
	hasAccesData?: boolean;

	/** Présence de notifications **/
	hasNotifications?: boolean;

	/** Affichage en modal **/
	isModal?: boolean;

	/** Affichage de la légende **/
	isShowLegend?: boolean;

	/** Légende du seuil **/
	seuilLegend?: string;

	/** Couleur du seuil **/
	seuilColor?: string;

	/** Index de début **/
	indexStart?: number;

	/** Index de fin **/
	indexEnd?: number;

	/** Offset du tooltip **/
	tipOffset?: { x?: string,y?: string };

	/** Indicateur de tooltip personnalisé **/
	isCustomTooltip?: boolean;

	/** Génération du label de la donnée **/
	getLabel: (d: any,index?: number) => string;

	/** Affichage d'un tip **/
	tipComponent?: <T extends ChartTipComponent> () => Type<T>;

	/** Formattage de la valeur **/
	formatValue?: (d: any) => string;

	/** Liste des actions **/
	getListeActions?: () => Array<ChartAction>

	/** Sélection d'une donnée **/
	onSelect?: (d: any,isModal?: boolean) => void

	/** Page précédente **/
	previous?: () => void;

	/** Page suivante **/
	next?: (isLast: boolean) => void;

	/** Retour arrière **/
	goBack?: () => void;

	/** Récupération de la couleur **/
	getColorStroke?: (data?: any) => string;

	/** Récupération de la couleur de remplissage **/
	getColorFill?: (data?: any) => string;

	/** Mise à jour de la sélection des données par rapport aux indexes **/
	onIndexesChange?: () => void;
}

/**
 * Options du graphique externe
 */
export interface ExternalChartOptions {
	/** Données **/
	chart: ApexChart,
	series: ApexAxisChartSeries,
	xAxis: ApexXAxis,
	yAxis: ApexYAxis,
	dataLabels: ApexDataLabels,
	fill: ApexFill,
	legend: ApexLegend,
	plotOptions: ApexPlotOptions,
	stroke?: ApexStroke,
	colors?: Array<string>,
	markers?: ApexMarkers,
	grid?: ApexGrid,
	tooltip?: ApexTooltip,
	labels?: Array<string>
}

/**
 * Modèle représentant une action sur le graphique
 */
export interface ChartAction {
	/** Libellé de l'action **/
	libelle: string;

	/** Réalisation de l'action **/
	doAction: Function;

	/** Indicateur de visibilité de l'action **/
	isVisible?: () => boolean;
}

/**
 * Types de graphique
 */
export enum TypeChart {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	PIE = 'PIE',
	BAR = 'BAR',
	LINE = 'LINE',
	KPI = 'KPI',
	AREA = 'AREA',
	DONUT = 'DONUT'
}

/**
 * Sous-types de graphique
 */
export enum SubTypeChart {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	GROUPE = 'GROUPE',
	EMPILE = 'EMPILE',
	MULTIPLE = 'MULTIPLE'
}

/**
 * Type d'agrégation
 */
export enum TypeAgregation {
	/** Enumération **/
	NON_DEFINI = 'NON_DEFINI',
	AVERAGE = 'AVERAGE',
	COUNT = 'COUNT',
	MAX = 'MAX',
	MIN = 'MIN',
	SUM = 'SUM',
	DISTINCT_COUNT = 'DISTINCT_COUNT'
}