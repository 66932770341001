import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { MenuComponent } from './menu.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [MenuComponent],
	exports: [MenuComponent]
})
export class MenuModule {}
