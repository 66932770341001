<div class="modal-header">
	<h4 class="modal-title" translate>planning.affectation.title</h4>
</div>
<div class="modal-body">
	<form #affectationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<affectation-content #affectationContent [affectation]="affectation" source="PLANNING" [user]="user" [canRemoveVehicule]="true" (showVehicule)="showVehicule(affectation);" (close)="onContentClose($event)"></affectation-content>
	</form>
</div>
<div class="modal-footer">
	<button mat-button [formDetectChanges]="{ doAction: bsModalRef.hide,isDirty: affectationForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
	<button mat-stroked-button color="primary" *ngIf="(TypeDroit.VEHICULE_RESERVATION | right:'creation') && affectation.idAffectation > 0 && affectation.reservation?.statut == 'VALIDEE'" (click)="affectationContent.doActionForReservation('ANNULER');">
		<span translate>actions.annuler</span>
	</button>
	<button mat-stroked-button color="primary" *ngIf="((TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')) && affectation.idAffectation > 0 && !affectation.reservation" (click)="affectationContent.deleteAffectation();">
		<span translate>actions.supprimer</span>
	</button>
	<button mat-raised-button color="primary" *ngIf="(TypeDroit.VEHICULE_CONDUCTEUR | right:'creation') || (TypeDroit.ADMIN_VEHICULE | right:'creation')" (click)="affectationContent.saveAffectation();" [disabled]="affectationForm.invalid">
		<span translate>actions.enregistrer</span>
	</button>
</div>