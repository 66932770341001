import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ArticleService } from './article.service';
import { ArticleConsultationComponent } from './article-consultation.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';
import { ArticleEffects } from './article.effects';
import { ArticleLectureComponent } from './article-lecture.component';

@NgModule({
	imports: [CoreComponentsModule,IconModule,EffectsModule.forFeature([ArticleEffects])],
	declarations: [ArticleConsultationComponent,ArticleLectureComponent],
	exports: [ArticleConsultationComponent,ArticleLectureComponent],
	providers: [ArticleService]
})
export class ArticleModule {}