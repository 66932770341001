import { NgModule } from '@angular/core';

import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { FloatingButtonItemComponent } from './floating-button-item.component';
import { FloatingButtonComponent } from './floating-button.component';
import { IconModule } from 'src/app/share/components/icon/icon.module';

@NgModule({
	imports: [CoreComponentsModule,IconModule],
	declarations: [FloatingButtonComponent,FloatingButtonItemComponent],
	exports: [FloatingButtonComponent,FloatingButtonItemComponent]
})
export class FloatingButtonModule {

}