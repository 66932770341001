import { Component,ContentChildren,Input,QueryList } from '@angular/core';

import { FloatingButtonItemComponent } from './floating-button-item.component';

@Component({
	selector: 'floating-button',
	templateUrl: './floating-button.component.html',
	styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {
	/** Indicateur d'ouverture du bouton flottant **/
	isOpened: boolean = false;

	/** Icône par défaut **/
	@Input() defaultIcon: string = 'more_vert';

	/** Famille d'icône par défaut **/
	@Input() iconType?: string;

	/** Indicateur d'affichage permanent **/
	@Input() isAlwaysShown: boolean = false;

	/** Indicateur d'affichage global **/
	@Input() isGlobal: boolean = false;

	/** Liste des éléments **/
	@ContentChildren(FloatingButtonItemComponent)
	listeItems: QueryList<FloatingButtonItemComponent>;
}