<form #factureAnalysisForm="ngForm" *ngIf="factureAnalysis?.idFactureAnalysis" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title"><span translate>facture.scan.title</span></h4>
	</div>
	<div class="modal-body">
		<div *ngIf="hasError">
			<div *ngIf="factureAnalysis.multiLicensePlates" class="alert alert-danger">
				<icon name="warning"></icon>
				<div>
					<strong translate>facture.lad.alerte.multiLicensePlates.title</strong>
					<div translate>facture.lad.alerte.multiLicensePlates.description</div>
				</div>
			</div>
			<div *ngIf="factureAnalysis.multiInvoiceNumbers" class="alert alert-danger">
				<icon name="warning"></icon>
				<div>
					<strong translate>facture.lad.alerte.multiInvoiceNumbers.title</strong>
					<div translate>facture.lad.alerte.multiInvoiceNumbers.description</div>
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div class="d-flex" style="min-height: 50vh;" class="flex-2">
				<div *ngIf="imageSource" style="position: relative; height: 100%;" class="flex-1">
					<image-editor #imageEditor="imageEditor" [consultation]="true" [inline]="true" [imageSource]="imageSource" [canRotate]="false" [customOptions]="listeCustomOptions" (onSourceChange)="onSourceChangeSubject.next($event)"></image-editor>
				</div>
			</div>
			<div *ngIf="!hasError" class="flex-1">
				<div class="tile-active-show">
					<div class="alert alert-warning alert-no-shadow" *ngIf="!isTaxeValid">
						<icon name="info"></icon>
						<div>
							<strong translate>facture.alerte.warningMontant.title</strong>
							<div translate>facture.alerte.warningMontant.message</div>
						</div>
					</div>
					<div class="tile-sub p-l-40 p-r-40">
						<fieldset [disabled]="!(TypeDroit.FACTURE_LAD | right:'creation')">
							<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
								<label translate>facture.lad.type</label>
								<div>
									<selectpicker name="type" #type="ngModel" [(ngModel)]="factureAnalysis.facture.type" required>
										<mat-option *ngFor="let type of listeTypesFacture" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
								<label translate>facture.lad.numero</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('REFERENCE') }">
										<input type="text" class="form-control" name="numero" #numero="ngModel" [(ngModel)]="factureAnalysis.facture.reference" required/>
										<div class="input-group-addon" *ngIf="hasBoundingBox('REFERENCE')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('REFERENCE')"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
								<label translate>facture.lad.fournisseur</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('FOURNISSEUR') || factureAnalysis.vendor }">
										<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="factureAnalysis.facture.fournisseur" (ngModelChange)="factureAnalysis?.natureLAD == null && (factureAnalysis.natureLAD = factureAnalysis?.facture?.fournisseur?.natureLAD); this.association.isDefined = false;" required></autocomplete>
										<div class="input-group-addon" *ngIf="hasBoundingBox('FOURNISSEUR')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('FOURNISSEUR')"></icon>
										</div>
										<div class="input-group-addon" *ngIf="factureAnalysis.vendor">
											<icon name="info_outline" [tooltip]="'facture.lad.tooltip.analyse' | translate:{ data: factureAnalysis.vendor }"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
								<label translate>facture.lad.societe</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('SOCIETE') || factureAnalysis.clientName }">
										<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="factureAnalysis.facture.societe" (ngModelChange)="this.association.isDefined = false;" required></autocomplete>
										<div class="input-group-addon" *ngIf="hasBoundingBox('SOCIETE')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('SOCIETE')"></icon>
										</div>
										<div class="input-group-addon" *ngIf="factureAnalysis.clientName">
											<icon name="info_outline" [tooltip]="'facture.lad.tooltip.analyse' | translate:{ data: factureAnalysis.clientName }"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': invoiceDate.invalid }">
								<label translate>facture.lad.dateEmission</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('DATE_FACTURE') }">
										<input type="text" name="invoiceDate" class="form-control" #invoiceDate="ngModel" [(ngModel)]="factureAnalysis.facture.date" datePicker required/>
										<div class="input-group-addon" *ngIf="hasBoundingBox('DATE_FACTURE')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('DATE_FACTURE')"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': vehicule.invalid }">
								<label translate>facture.lad.vehicule</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('VEHICULE') || factureAnalysis.licensePlate }">
										<autocomplete name="vehicule" type="vehicule" #vehicule="ngModel" [(ngModel)]="factureAnalysis.vehicule" required (ngModelChange)="onVehiculeChange()"></autocomplete>
										<div class="input-group-addon" *ngIf="hasBoundingBox('VEHICULE')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('VEHICULE')"></icon>
										</div>
										<div class="input-group-addon" *ngIf="factureAnalysis.licensePlate">
											<icon name="info_outline" [tooltip]="'facture.lad.tooltip.analyse' | translate:{ data: factureAnalysis.licensePlate }"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nature.invalid }">
								<label translate>facture.lad.nature</label>
								<div>
									<autocomplete name="nature" type="fournisseurNature" #nature="ngModel" [(ngModel)]="factureAnalysis.natureLAD" (ngModelChange)="onNatureChange()" required></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': montantTotal.invalid }">
								<label translate>facture.lad.montantTotal</label>
								<div>
									<div class="input-group">
										<input type="text" name="montantTotal" class="form-control" #montantTotal="ngModel" [(ngModel)]="factureAnalysis.facture.montant" nNumber="2" min="0" required/>
										<div class="input-group-addon no-border"><span>{{ factureAnalysis.facture.devise }}</span></div>
										<div class="input-group-addon" *ngIf="hasBoundingBox('MONTANT')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('MONTANT')"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label translate>facture.lad.taxe.item</label>
								<div>
									<div class="input-group">
										<a [routerLink]="[]" (click)="showTaxes()"><span><icon name="search"></icon>{{ (getSommeTaxe() | number:'.2-2') + ' ' + factureAnalysis.facture.devise }}</span></a>
										<div class="input-group-addon" *ngIf="hasBoundingBox('TAXE')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('TAXE')"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': quantite.invalid }" *ngIf="factureService.hasTypeField(factureAnalysis.natureLAD,'RELEVE_CARBURANT')">
								<label translate>facture.lad.quantite</label>
								<div>
									<input type="text" name="quantite" #quantite="ngModel" class="form-control" [(ngModel)]="factureAnalysis.quantite" nNumber="2" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': unite.invalid }" *ngIf="factureService.hasTypeField(factureAnalysis.natureLAD,'RELEVE_CARBURANT')">
								<label translate>facture.lad.unite</label>
								<div>
									<autocomplete name="unite" type="unite" #unite="ngModel" filter="CONTENANCE" [(ngModel)]="factureAnalysis.unite" required></autocomplete>
								</div>
							</div>
							<div class="form-group" *ngIf="factureService.hasTypeField(factureAnalysis.natureLAD,'ENTRETIEN') || factureService.hasTypeField(factureAnalysis.natureLAD,'REALISATION_CONTROLE_TECHNIQUE') || factureService.hasTypeField(factureAnalysis.natureLAD,'REALISATION_CONTROLE_ANTIPOLLUTION')">
								<label translate>facture.lad.typeEntretien</label>
								<div>
									<selectpicker name="typeEntretien" #typeEntretienField="ngModel" [(ngModel)]="factureAnalysis.typeEntretien" (ngModelChange)="onTypeEntretienChange()" [placeholder]="'common.aucun' | translate">
										<mat-option [value]="null"><span translate>common.aucun</span></mat-option>
										<mat-option *ngFor="let typeEntretien of listeTypesEntretien" [value]="typeEntretien.code">{{ typeEntretien.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': sousTypeField.invalid }" *ngIf="factureAnalysis.typeEntretien == 'CONTROLE_REGLEMENTAIRE'">
								<label translate>facture.lad.sousType</label>
								<div>
									<selectpicker name="sousType" #sousTypeField="ngModel" [(ngModel)]="factureAnalysis.sousType" [disabled]="true" required>
										<mat-option *ngFor="let sousType of listeSousTypes" [value]="sousType.code">{{ sousType.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" *ngIf="(factureService.hasTypeField(factureAnalysis.natureLAD,'ENTRETIEN') || factureService.hasTypeField(factureAnalysis.natureLAD,'REALISATION_CONTROLE_TECHNIQUE') || factureService.hasTypeField(factureAnalysis.natureLAD,'REALISATION_CONTROLE_ANTIPOLLUTION')) && factureAnalysis.typeEntretien == 'COURANT'">
								<label translate>facture.lad.typeEntretienItem</label>
								<div>
									<autocomplete name="typeEntretienItem" type="typeEntretien" #typeEntretienItem="ngModel" [(ngModel)]="factureAnalysis.typeEntretienItem"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': compteur.invalid }">
								<label translate>facture.lad.compteur</label>
								<div>
									<div [ngClass]="{ 'input-group': hasBoundingBox('COMPTEUR') }">
										<input type="text" class="form-control" name="compteur" #compteur="ngModel" [(ngModel)]="factureAnalysis.releveCompteur" nNumber min="0" [required]="factureAnalysis.typeEntretien && factureAnalysis.typeEntretien != 'COURANT'"/>
										<div class="input-group-addon" *ngIf="hasBoundingBox('COMPTEUR')">
											<icon name="visibility" class="clickable" (click)="showBoundingBox('COMPTEUR')"></icon>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-stroked-button color="primary" *ngIf="TypeDroit.FACTURE_LAD | right:'suppression'" (click)="deleteAnalysis()">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="!hasError && (TypeDroit.FACTURE_LAD | right:'creation')" [disabled]="factureAnalysisForm.invalid" (click)="saveAnalysis()">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>