<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.confort.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.confort.nbSieges</label>
					<div class="half" *ngIf="confort?.nbSieges">
						<input type="text" name="nbSieges" #nbSieges="ngModel" class="form-control" [(ngModel)]="confort.nbSieges" nNumber/>
					</div>
					<div *ngIf="!confort?.nbSieges">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.confort.nbSiegesMax</label>
					<div class="half" *ngIf="confort?.nbSiegesMax">
						<input type="text" name="nbSiegesMax" #nbSiegesMax="ngModel" class="form-control" [(ngModel)]="confort.nbSiegesMax" nNumber/>
					</div>
					<div *ngIf="!confort?.nbSiegesMax">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>