import { Component,Input } from '@angular/core';

@Component({
	selector: 'please-wait',
	templateUrl: './please-wait.component.html'
})
export class PleaseWaitComponent {
	/** Taille du SVG **/
	@Input() size: 'hc-md' | 'hc-lg' | 'hc-2x' | 'hc-3x' | 'hc-5x' | 'xs' | 'sm' | 'lg' | 'xl' | 'xxl';

	/** Message **/
	@Input() message: string;

	/** Rayon du cercle **/
	@Input() radius: number = 20;
}