<form #controleReglementaireRuleForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card" [ngClass]="{ 'detail': isEdition }">
		<div class="card-header" *ngIf="isEdition">
			<h2 class="card-title" translate>vehicule.controleReglementaire.rule.titre</h2>
		</div>
		<div class="card-body">
			<fieldset [disabled]="!(null | right:'creation') || !isEdition">
				<rule entite="com.notilus.data.vehicule.Vehicule" [rule]="rule" [isEdition]="isEdition"></rule>
			</fieldset>
		</div>
	</div>
	<ng-container *ngIf="isEdition">
		<button class="pull-right" mat-raised-button color="primary" [disabled]="controleReglementaireRuleForm.invalid" (click)="saveRule()">
			<span translate>actions.enregistrer</span>
		</button>
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
	</ng-container>
</form>