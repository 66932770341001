import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ReleveEnergieModule } from 'src/app/components/vehicule/releve-energie/releve-energie.module';
import { ReleveCompteurModule } from 'src/app/components/vehicule/releve-compteur/releve-compteur.module';
import { EquipementAddComponent } from './equipement-add.component';
import { EquipementAffectationContentComponent } from './equipement-affectation-content.component';
import { EquipementAffectationEditComponent } from './equipement-affectation-edit.component';
import { EquipementAffectationHistoryListItemComponent } from './equipement-affectation-history-list-item.component';
import { EquipementAffectationHistoryListComponent } from './equipement-affectation-history-list.component';
import { EquipementAffectationListItemComponent } from './equipement-affectation-list-item.component';
import { EquipementAffectationListComponent } from './equipement-affectation-list.component';
import { EquipementAffectationService } from './equipement-affectation.service';
import { EquipementEditComponent } from './equipement-edit.component';
import { EquipementFactureDetailListItemComponent } from './equipement-facture-detail-list-item.component';
import { EquipementFactureDetailListComponent } from './equipement-facture-detail-list.component';
import { EquipementListItemComponent } from './equipement-list-item.component';
import { EquipementListComponent } from './equipement-list.component';
import { EquipementReleveEnergieEditComponent } from './equipement-releve-energie-edit.component';
import { EquipementReleveEnergieListItemComponent } from './equipement-releve-energie-list-item.component';
import { EquipementReleveEnergieListComponent } from './equipement-releve-energie-list.component';
import { EquipementReleveCompteurEditComponent } from './equipement-releve-compteur-edit.component';
import { EquipementReleveCompteurListItemComponent } from './equipement-releve-compteur-list-item.component';
import { EquipementReleveCompteurListComponent } from './equipement-releve-compteur-list.component';
import { EquipementComponent } from './equipement.component';
import { EquipementService } from './equipement.service';

@NgModule({
	imports: [ShareModule,ReleveCompteurModule,ReleveEnergieModule],
	declarations: [EquipementListComponent,EquipementListItemComponent,EquipementAddComponent,EquipementAffectationContentComponent,EquipementAffectationHistoryListComponent,EquipementAffectationHistoryListItemComponent,EquipementComponent,EquipementEditComponent,EquipementAffectationListComponent,EquipementAffectationListItemComponent,EquipementAffectationEditComponent,EquipementReleveCompteurListComponent,EquipementReleveCompteurListItemComponent,EquipementReleveCompteurEditComponent,EquipementReleveEnergieListComponent,EquipementReleveEnergieListItemComponent,EquipementReleveEnergieEditComponent,EquipementFactureDetailListComponent,EquipementFactureDetailListItemComponent],
	providers: [EquipementService,EquipementAffectationService],
	exports: [EquipementAffectationContentComponent]
})
export class EquipementModule { }