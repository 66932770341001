import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FournisseurListItemComponent } from './fournisseur-list-item.component';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielEntiteList } from 'src/app/domain/referentiel/referentiel-entite-list';
import { ReferentielService } from 'src/app/share/components/referentiel/referentiel.service';

@Component({
	selector: 'fournisseur-list',
	templateUrl: './fournisseur-list.component.html'
})
export class FournisseurListComponent extends ReferentielEntiteList implements OnInit {
	/** Type d'entité du référentiel mixte **/
	type: string = 'com.notilus.data.fournisseur.Fournisseur';

	/** Endpoint de rafraichissement de la liste **/
	getUrlForTypeListe: (typeReferentiel: TypeReferentiel) => string = (typeReferentiel: TypeReferentiel) => `/controller/Fournisseur/filtreFournisseurs/${typeReferentiel}`;

	/** Données **/
	liste: ListView<any,FournisseurListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(referentielService: ReferentielService,protected rightService: RightService,private translateService: TranslateService,private layoutService: LayoutService) {
		//Héritage
		super(referentielService,rightService);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Héritage
		super.init();

		//Définition de la liste
		this.liste = new ListView<any,FournisseurListItemComponent>({
			uri: () => this.getUrlForTypeListe(this.liste.selectedSelector),
			title: this.translateService.instant('fournisseur.liste.title'),
			component: FournisseurListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'listeMetiers',
				title: this.translateService.instant('search.fournisseurMetier'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurMetier'
				}
			}],
			listeSelectors: !this.rightService.isRoot() && [{
				code: TypeReferentiel.LOCAL,
				libelle: this.translateService.instant('referentiel.typeReferentiel.LOCAL'),
				count: () => this.referentiel?.nbItemsForLocal || 0
			},{
				code: TypeReferentiel.STANDARD,
				libelle: this.translateService.instant('referentiel.typeReferentiel.STANDARD'),
				count: () => this.referentiel?.nbItems || 0
			}],
			selectedSelector: this.rightService.isRoot() ? TypeReferentiel.STANDARD : TypeReferentiel.LOCAL,
			extraOptions: {
				referentiel: this.referentiel
			},
			hasMainAction: () => this.rightService.hasRight(null,'creation') && (this.liste.selectedSelector == TypeReferentiel.LOCAL || this.rightService.isRoot()),
			doMainAction:  () => this.showFournisseur(),
			onRefresh: (liste,result) => {
				//Vérification du type de liste
				if (this.liste.selectedSelector == TypeReferentiel.STANDARD)
					//Mise à jour du nombre d'éléments standards
					this.referentiel.nbItems = result?.totalElements || 0;
				else
					//Mise à jour du nombre d'éléments locaux
					this.referentiel.nbItemsForLocal = result?.totalElements || 0;
			}
		});
	}

	/**
	 * Affichage du fournisseur
	 */
	showFournisseur(fournisseur?) {
		//Redirection vers la page
		this.layoutService.goToByUrl(`/Fournisseur/loadFournisseur/${this.liste.selectedSelector}/${fournisseur?.idFournisseur || 0}`);
	}
}
