import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { ExtractionService } from 'src/app/share/components/extraction/extraction.service';
import { GroupeService } from './groupe.service';

@Component({
	selector: 'groupe-edit',
	templateUrl: './groupe-edit.component.html'
})
export class GroupeEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() groupe: any;

	/** Suppression du groupe **/
	@Input() deleteGroupe: () => void;

	/** Indicateur d'utilisation du groupe **/
	@Input() isGroupeUsed: boolean;

	/** Liste des entités disponibles **/
	listeEntites: Array<any>;

	/** Groupe sauvegardé **/
	private savedGroupe: any;

	/**
	 * Constructeur
	 */
	constructor(private groupeService: GroupeService,private translateService: TranslateService,private toastrService: ToastrService,private extractionService: ExtractionService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie du groupe
		this.groupe = cloneDeep(this.groupe);
		this.savedGroupe = cloneDeep(this.groupe);

		//Récupération des entités disponibles
		this.extractionService.getListeEntites('GROUPE').subscribe({
			next: listeEntites => this.listeEntites = listeEntites
		});
	}

	/**
	 * Enregistrement du groupe
	 */
	saveGroupe() {
		let doSaveGroupe: () => void;

		//Enregistrement du groupe
		doSaveGroupe = () => this.groupeService.saveGroupe(this.groupe).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du groupe
					this.groupe = result.data.groupe;

					//Fermeture de l'édition
					this.close(this.groupe);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});

		//Vérification du changement d'entité
		if (this.savedGroupe?.idGroupe && this.savedGroupe.entity != this.groupe.entity) {
			//Affichage d'un message de confirmation
			this.confirmService.showConfirm(this.translateService.instant('groupe.confirmation.changementEntite'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed)).subscribe({
				next: () => {
					//Retrait de la règle
					this.groupe.rule = null;

					//Sauvegarde du groupe
					doSaveGroupe();
				}
			});
		} else
			//Sauvegarde du groupe
			doSaveGroupe();
	}
}