import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { User } from 'src/app/domain/user/user';
import { ConfigurationService } from 'src/app/components/vehicule/modele/configuration/configuration.service';
import { ModeleService } from 'src/app/components/vehicule/modele/modele.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { DemandeCotationService } from 'src/app/components/commande/demande-cotation/demande-cotation.service';

@Component({
	selector: 'demande-cotation-cotation-content',
	templateUrl: './demande-cotation-cotation-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class DemandeCotationCotationContentComponent implements OnInit {
	/** Cotation **/
	@Input() cotation: any;

	/** Réponse à la cotation **/
	@Input() reponseCotation: any;

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Liste des options payantes */
	public listeOptionsPayantes: Array<any> = [];

	/** Configuration du modèle **/
	private configuration: any;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Utilisateur courant **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private loggedUserService: LoggedUserService,private configurationService: ConfigurationService,private modeleService: ModeleService,private demandeCotationService: DemandeCotationService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();

		//Chargement de la configuration
		this.configurationService.loadConfiguration(this.reponseCotation.cotation?.demandeCotation?.origine,this.reponseCotation.cotation?.modele?.idModele,this.reponseCotation.cotation?.demandeCotation?.origine == 'GRILLE_ATTRIBUTION' ? this.reponseCotation.cotation?.lienGrilleAttributionModele?.idGrilleAttribution : this.reponseCotation.cotation?.demandeVehicule?.idDemandeVehicule).subscribe((result) => {
			//Définition de la configuration
			this.configuration = result?.data?.configuration;

			//Définition de la liste des options payantes
			this.listeOptionsPayantes = this.configuration?.listeDetails?.filter((detail) =>  detail.selected && detail.option.type != 'SERIE') || [];
		});
	}

	/**
	 * Affichage des informations détaillées du modèle
	 */
	public showModeleInformation(modele: any) {
		//Affichage des informations détaillées du modèle
		this.modeleService.showModeleInformations(modele,{ canConfigure: false }).subscribe();
	}

	/**
	 * Affichage des options payantes
	 */
	public showListeOptionsPayantes() {
		//Affichage des options payantes
		this.demandeCotationService.showListeOptionsPayantes(this.configuration,this.listeOptionsPayantes).subscribe();
	}
}