import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareModule } from 'src/app/share/share.module';
import { LoggedGuardProvider } from './security/logged-guard.provider';
import { NotLoggedGuardProvider } from './security/not-logged-guard.provider';
import { httpInterceptorProviders } from './security/http-interceptor-providers';
import { CguGuardProvider } from './security/cgu-guard.provider';

@NgModule({
	declarations: [],
	providers: [httpInterceptorProviders,LoggedGuardProvider,NotLoggedGuardProvider,CguGuardProvider],
	imports: [CommonModule,ShareModule]
})
export class CoreModule {}