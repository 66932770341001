<div class="alert alert-info">
	<icon name="warning"></icon>
	<div>
		<strong translate>vehicule.immatriculation.selectionModeleCatalogue.step.choix.alerte.title</strong>
		<div translate>vehicule.immatriculation.selectionModeleCatalogue.step.choix.alerte.message</div>
	</div>
</div>
<div class="p-t-20 p-b-20">
	<mat-radio-group name="selectedChoix" [(ngModel)]="selectedChoix.type" (change)="onChoixSelected(selectedChoix.type)">
		<mat-radio-button value="CONSERVATION_MODELE">
			<span translate>vehicule.immatriculation.selectionModeleCatalogue.step.choix.conservation</span>
		</mat-radio-button>
		<div>
			<div class="separator">
				<span translate>common.ou</span>
			</div>
		</div>
		<mat-radio-button value="CHANGEMENT_MODELE">
			<span translate>vehicule.immatriculation.selectionModeleCatalogue.step.choix.changement</span>
		</mat-radio-button>
	</mat-radio-group>
</div>