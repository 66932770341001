import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CompteAuxiliaireService } from 'src/app/components/comptabilite/compte-auxiliaire/compte-auxiliaire.service';

@Component({
	selector: 'plan-comptable-compte-auxiliaire-edit',
	templateUrl: './plan-comptable-compte-auxiliaire-edit.component.html'
})
export class PlanComptableCompteAuxiliaireEditComponent extends PageContentItem {
	/** Compte **/
	@Input() compteAuxiliaire: any;

	/** Masquage du champ 'Compte collectif' **/
	@Input() isCompteHidden: boolean;

	/**
	 * Constructeur
	 */
	constructor(private compteAuxiliaireService: CompteAuxiliaireService,private toastrService: ToastrService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du compte auxiliaire
	 */
	saveCompteAuxiliaire() {
		//Enregistrement du compte auxiliaire
		this.compteAuxiliaireService.saveCompteAuxiliaire(this.compteAuxiliaire).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Retour du compte
				this.close(result.data.compteAuxiliaire);
			} else if (result?.codeErreur === TypeCodeErreur.DOUBLON) {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: this.translateService.instant('actions.doublon.numero')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}
