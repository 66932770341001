import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class AmenagementService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {

	}

	/**
	 * Enregistrement de l'aménagement
	 */
	public saveAmenagement(amenagement: any): Observable<Result> {
		//Enregistrement de l'aménagement
		return this.http.put<Result>(`${environment.baseUrl}/controller/Amenagement/saveAmenagement`,amenagement);
	}

	/**
	 * Suppression de l'aménagement
	 */
	public deleteAmenagement(amenagement: any): Observable<Result> {
		//Suppression de l'aménagement
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Amenagement/deleteAmenagement/${amenagement.idAmenagement}`);
	}
}