import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { Filter,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { ReservationService } from './reservation.service';
import { mapEntites } from './reservation.entites';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { HistoryService } from 'src/app/share/components/history/history.service';

@Component({
	templateUrl: './reservation-list-item.component.html'
})
export class ReservationListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private toastrService: ToastrService,private translateService: TranslateService,private mailHistoryService: MailHistoryService,public notificationService: NotificationService,private reservationService: ReservationService
			,private layoutService: LayoutService,private vehiculeService: VehiculeService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idReservation,object?.idReservation && !this.data?.idReservation);
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.pool.Reservation',this.data.idReservation);
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	doAction(typeAction: 'VALIDER' | 'REJETER' | 'ANNULER',reservation: any) {
		//Réalisation de l'action Workflow
		this.reservationService.doAction(typeAction,reservation.idReservation).subscribe({
			next: isSuccess => {
				//Vérification du statut
				if (isSuccess) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Rafraichissement de la liste
					this.liste.refresh();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Accéder à l'affectation
	 */
	goToAffectation(reservation: any = this.data) {
		//Navigation vers l'affectation
		this.layoutService.goToByState('listeAffectations',{
			savedSearch: {
				listeSelectedFilters: [{
					clef: 'idAffectation',
					typeComparaison: TypeComparaison.EQUAL,
					type: TypeFilter.LONG,
					valeur: reservation.affectation.idAffectation,
					title: this.translateService.instant('reservation.navigationAffectation',{ reference: reservation.affectation.vehicule.reference })
				}]
			}
		});
	}

	/**
	 * Accéder à l'état des lieux
	 */
	goToEtatLieux(reservation: any = this.data) {
		//Navigation vers l'état des lieux
		this.layoutService.goToByState('listeEtatsLieux-etatLieux',{
			routeParams: {
				idEtatLieux: reservation.etatLieux.idEtatLieux
			},
			withGoBack: true
		});
	}

	/**
	 * Accéder au planning
	 */
	goToPlanning(reservation: any = this.data) {
		let listeSelectedFilters: Array<Filter> = [];

		//Vérification de la présence d'un pool
		if (reservation?.affectation?.vehicule?.pool?.idPool) {
			//Définition du filtre
			listeSelectedFilters.push({
				clef: 'pool.idPool',
				listeObjects: [reservation.affectation.vehicule.pool.idPool],
				typeComparaison: TypeComparaison.IN,
				type: TypeFilter.LONG,
				displayedValeur: this.translateService.instant('autocomplete.pool') + ' : ' + reservation.affectation.vehicule.pool.libelle + ' (' + reservation.affectation.vehicule.pool.reference + ')'
			});
		}

		//Navigation vers le planning
		this.vehiculeService.goToPlanning(reservation.affectation?.vehicule || reservation.vehicule,{ listeSelectedFilters },null,{ initialDate: listeSelectedFilters.length ? reservation.dateDepart : reservation.affectation?.dateDebut || reservation.dateDepart });
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.pool.Reservation',this.data.idReservation);
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.pool.Reservation',this.data.idReservation);
	}
}