'use strict';

var inherits = require('inherits'),
  EventTarget = require('./eventtarget');
function EventEmitter() {
  EventTarget.call(this);
}
inherits(EventEmitter, EventTarget);
EventEmitter.prototype.removeAllListeners = function (type) {
  if (type) {
    delete this._listeners[type];
  } else {
    this._listeners = {};
  }
};
EventEmitter.prototype.once = function (type, listener) {
  var self = this,
    fired = false;
  function g() {
    self.removeListener(type, g);
    if (!fired) {
      fired = true;
      listener.apply(this, arguments);
    }
  }
  this.on(type, g);
};
EventEmitter.prototype.emit = function () {
  var type = arguments[0];
  var listeners = this._listeners[type];
  if (!listeners) {
    return;
  }
  // equivalent of Array.prototype.slice.call(arguments, 1);
  var l = arguments.length;
  var args = new Array(l - 1);
  for (var ai = 1; ai < l; ai++) {
    args[ai - 1] = arguments[ai];
  }
  for (var i = 0; i < listeners.length; i++) {
    listeners[i].apply(this, args);
  }
};
EventEmitter.prototype.on = EventEmitter.prototype.addListener = EventTarget.prototype.addEventListener;
EventEmitter.prototype.removeListener = EventTarget.prototype.removeEventListener;
module.exports.EventEmitter = EventEmitter;