import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ReleveCompteurService } from 'src/app/components/vehicule/releve-compteur/releve-compteur.service';

@Component({
	templateUrl: './equipement-releve-compteur-list-item.component.html'
})
export class EquipementReleveCompteurListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private releveCompteurService: ReleveCompteurService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,public rightService: RightService,private historyService: HistoryService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteReleveCompteur = this.deleteReleveCompteur.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => this.user = user);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteReleveCompteur(releveCompteur: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.releveCompteurService.deleteReleveCompteur(releveCompteur))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Edition du compteur
	 */
	addReleveCompteur() {
		//Chargement complet du compteur
		this.releveCompteurService.loadReleveCompteur(this.data.idCompteur).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du compteur
					Object.assign(this.data,result?.data?.compteur);

					//Edition du compteur
					this.extraOptions?.addReleveCompteur?.(this.data,this.deleteReleveCompteur);
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.VehiculeCompteur',this.data.idCompteur);
	}
}