import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { PosteChargeCompteListItemComponent } from 'src/app/components/comptabilite/poste-charge/poste-charge-compte-list-item.component';

@Component({
	selector: 'fournisseur-compte-list',
	templateUrl: './fournisseur-compte-list.component.html'
})
export class FournisseurCompteListComponent extends PageContentItem implements OnInit {
	/** Fournisseur **/
	@Input() fournisseur: any;

	/** Fonction d'édition **/
	@Input() addCompte: Function;

	/** Résumé **/
	@Input() resume: { nbComptes: number };

	/** Données **/
	liste: ListView<any,PosteChargeCompteListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PosteChargeCompteListItemComponent>({
			uri: () => `/controller/Compte/filtreComptesByDetail/FOURNISSEUR/${this.fournisseur.idFournisseur || 0}`,
			component: PosteChargeCompteListItemComponent,
			isLocal: true,
			extraOptions: {
				addCompte: this.addCompte
			},
			listeFilters: [{
				clef: 'compte.numero',
				isDefault: true
			},{
				clef: 'compte.libelle',
				isDefault: true
			},{
				clef: 'compte.pays.libelle,compte.pays.code2',
				isDefault: true
			}],
			defaultOrder: 'compte.numero'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idLien > 0)
			//Mise à jour des compteurs
			this.resume.nbComptes--;
	}
}