<form #contratForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>tenant.contratCRM.item</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="true">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label translate>tenant.contratCRM.reference</label>
							<div>
								<input type="text" name="reference" class="form-control" [(ngModel)]="contratCRM.reference"/>
							</div>
						</div>
						<div class="form-group">
							<label translate>tenant.clientCRM.item</label>
							<div>
								<input type="text" name="clientCRM" class="form-control" [(ngModel)]="contratCRM.clientCRM.libelle"/>
							</div>
						</div>
						<div class="form-group">
							<label translate>tenant.revendeur</label>
							<div>
								<input type="text" name="revendeur" class="form-control" [(ngModel)]="contratCRM.revendeur.reference"/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': articleCRM.invalid }" *ngIf="contratCRM.revendeur">
							<label translate>tenant.articleCRM</label>
							<div>
								<autocomplete name="articleCRM" type="articleCRM" #articleCRM="ngModel" [(ngModel)]="contratCRM.articleCRM" [filter]="{ idRevendeur: contratCRM.revendeur.idRevendeur }"></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateEffet.invalid }">
							<label translate>tenant.dateEffet</label>
							<div class="half">
								<input type="text" class="form-control" name="dateEffet" #dateEffet="ngModel" [(ngModel)]="contratCRM.dateEffet" datePicker format="date" [storeDate]="true"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: contratForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>