import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { UserService } from './user.service';

@Component({
	templateUrl: './user-user-responsable-list-item.component.html'
})
export class UserUserResponsableListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private userService: UserService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super();

		//Binding
		this.deleteUserResponsable = this.deleteUserResponsable.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteUserResponsable(userResponsable: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'objet
					this.userService.deleteUserResponsable(userResponsable).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'objet
								this.onObjectChange(null);

								//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
								close?.(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Redirection vers l'utilisateur
	 */
	public goToUser() {
		//Navigation vers l'utilisateur
		this.layoutService.goToByState('listeUsers-user',{
			routeParams: {
				idUser: this.data.user.idUser
			},
			withGoBack: true,
			reloadOnSameUrl: true
		});
	}

	/**
	 * Redirection vers le service
	 */
	public goToService() {
		//Navigation vers le service
		this.layoutService.goToByState('listeServices-service',{
			routeParams: {
				idService: this.data.service.idService
			},
			withGoBack: true
		});
	}
}