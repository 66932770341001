import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { TypeEntretienService } from './type-entretien.service';

@Component({
	templateUrl: './type-entretien-list-item.component.html'
})
export class TypeEntretienListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor(private typeEntretienService: TypeEntretienService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification du type d'entretien
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idTypeEntretien,object?.idTypeEntretien && !this.data?.idTypeEntretien);
	}

	/**
	 * Suppression du type d'entretien
	 */
	deleteTypeEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du type d'entretien
					this.typeEntretienService.deleteTypeEntretien(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du type d'entretien
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.TypeEntretienItem',this.data.idTypeEntretien);
	}
}