import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormDetectChangesDirective } from './form-detect-changes.directive';

@NgModule({
	imports: [BrowserModule],
	declarations: [FormDetectChangesDirective],
	exports: [FormDetectChangesDirective]
})
export class FormDetectChangesModule {}