import { Component,OnInit,ElementRef,ViewChild,OnDestroy,Output,EventEmitter } from '@angular/core';

@Component({
	selector: 'infinite-scroll',
	template: '<ng-content></ng-content><div #infiniteScrollEnd style="height: 1px; width: 100%; background-color: transparent;"></div>'
})
export class InfiniteScrollComponent implements OnInit,OnDestroy {
	/** Événement de scroll sur le composant **/
	@Output() scrolled = new EventEmitter();

	/** Élément situé à l'extrémité du composant **/
	@ViewChild('infiniteScrollEnd',{ static: true }) infiniteScrollEnd: ElementRef<HTMLElement>;

	/**  Observateur de l'apparition de l'extrémité du composant dans la fenêtre **/
	private observer: IntersectionObserver;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de l'observateur
		this.observer = new IntersectionObserver(([entry]) => {
			//Notification du scroll lorsque l'élément observé apparait
			entry.isIntersecting && this.scrolled.emit();
		},{});

		//Détection de l'apparition de l'extrémité du composant
		this.observer.observe(this.infiniteScrollEnd.nativeElement);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Déconnexion de l'observateur
		this.observer.disconnect();
	}
}