import { Component,ElementRef,EventEmitter,Input,Output,forwardRef,ContentChildren,QueryList,AfterViewInit,OnDestroy,OnInit,OnChanges,SimpleChanges,ContentChild,HostBinding } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect,MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { some } from 'lodash-es';

@Component({
	selector: 'selectpicker',
	exportAs: 'selectpicker',
	templateUrl: './select-picker.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SelectPickerComponent),
		multi: true
	}]
})
export class SelectPickerComponent implements OnInit,AfterViewInit,OnChanges,OnDestroy,ControlValueAccessor {
	/** Placeholder **/
	@Input() placeholder: string = '';

	/** Caractère actif du champ **/
	@Input() disabled?: boolean;

	/** Fonction de comparaison de la valeur des options avec la valeur sélectionnée **/
	@Input() compareWith?: (o1: any, o2: any) => boolean;

	/** Sélection d'un élément **/
	@Output() onSelect: EventEmitter<any> = new EventEmitter<MatSelectChange>();

	/** Ajout de la classe 'form-control' à l'élément hôte **/
	@HostBinding('class.form-control') formControlClass = true;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Caractère actif du champ interne au composant **/
	localDisabled?: boolean;

	/** Fonction de comparaison interne au composant **/
	localComparator?: (o1: any, o2: any) => boolean;

	/** Liste des options du sélecteur **/
	listeOptions: any[];

	/** Option sélectionnée **/
	_selectedOption: any;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Liste d'observateurs de changements sur les formulaires englobants **/
	private listeFieldsetMutationObservers: Array<{ fieldset: HTMLFieldSetElement,observer: MutationObserver }> = [];

	/** Souscription pour l'écoute des modififcations de la liste des options **/
	private onListeOptionsChangeSubscription: Subscription;

	/** Liste des options fournies par le composant parent **/
	@ContentChildren(MatOption) queryListeOptions: QueryList<MatOption>;

	/** Composant MatSelect **/
	@ContentChild(MatSelect) matSelect: MatSelect;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let fieldset: HTMLFieldSetElement = null;

		//Initialisation de la désactivation du champ
		this.localDisabled = this.disabled;

		//Parcours progressif des formulaires parents
		do {
			let fieldsetMutationObserver: MutationObserver;

			//Recherche du formulaire parent le plus proche
			fieldset = (fieldset != null ? fieldset.parentElement : this.elementRef.nativeElement)?.closest('fieldset');

			//Vérification de la présence d'un formulaire
			if (fieldset) {
				//Lecture du champ de désactivation du formulaire
				this.localDisabled = this.localDisabled || fieldset.disabled;

				//Ajout d'un observateur d'évènements sur le formulaire
				this.listeFieldsetMutationObservers.push({
					fieldset,
					observer: fieldsetMutationObserver = new MutationObserver(() => {
						//Lecture du champ de désactivation du formulaire
						this.localDisabled = this.disabled || some(this.listeFieldsetMutationObservers,o => o.fieldset.disabled);
					})
				});

				//Ecoute des changements
				fieldsetMutationObserver.observe(fieldset,{
					attributes: true,
					attributeFilter: ['disabled']
				});
			}
		} while (fieldset != null);

		//Définition d'un comparator par défaut
		this.localComparator = this.compareWith || ((o1,o2) => o1 == o2);
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Définition des options du sélecteur
		this.listeOptions = this.queryListeOptions.map(o => ({
			value: o.value,
			viewValue: o.viewValue
		}));

		//Écoute des changements
		this.onListeOptionsChangeSubscription = this.queryListeOptions.changes.subscribe(listeOptions => {
			//Mise à jour des options du sélecteur
			this.listeOptions = listeOptions.map(o => ({
				value: o.value,
				viewValue: o.viewValue
			}));
		});

		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;
		});
	}

	/**
	 * Détection des changements
	 */
	ngOnChanges(changes: SimpleChanges): void {
		//Vérification de la présence d'un changement sur l'état de désactivation
		if (changes?.disabled)
			//Mise à jour de l'état de désactivation du champ
			this.localDisabled = this.disabled || some(this.listeFieldsetMutationObservers,o => o.fieldset.disabled);
	}

	/**
	 * Interception de la sélection d'une option
	 */
	onSelectionChange(event: MatSelectChange) {
		//Notification du changement
		this.onSelect.next(event);
	}

	/**
	 * Définition de la valeur
	 */
	set value(selectedOption) {
		//Vérification de la valeur
		if (selectedOption !== undefined && this._selectedOption !== selectedOption) {
			//Mise à jour de l'option sélectionnée
			this._selectedOption = selectedOption;

			//Vérification que l'initialisation du composant est terminée
			if (this.isInit) {
				//Déclenchement des intercepteurs (marque le formulaire 'dirty')
				this.onChange?.(selectedOption);
				this.onTouch?.(selectedOption);
			}
		}
	}

	/**
	 * Lecture de la valeur
	 */
	get value(): any {
		//Retour de la valeur
		return this._selectedOption;
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(value: any) {
		//Mise à jour de la valeur
		this.value = value;
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Arrêt de l'observation des changements
		this.listeFieldsetMutationObservers.forEach(o => o.observer.disconnect());

		//Annulation de la souscription
		this.onListeOptionsChangeSubscription?.unsubscribe?.();
	}
}