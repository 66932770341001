import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { VehiculeAffectationListItemComponent } from './vehicule-affectation-list-item.component';

@Component({
	selector: 'vehicule-affectation-list',
	templateUrl: './vehicule-affectation-list.component.html'
})
export class VehiculeAffectationListComponent extends PageContentItem implements OnInit {
	/** Véhicule **/
	@Input() vehicule: any;

	/** Fonction d'édition **/
	@Input() addAffectation: () => void;

	/** Résumé **/
	@Input() resume: { nbAffectations: number };

	/** Données **/
	liste: ListView<any,VehiculeAffectationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,VehiculeAffectationListItemComponent>({
			uri: () => `/controller/Vehicule/filtreAffectations`,
			component: VehiculeAffectationListItemComponent,
			isLocal: true,
			extraOptions: {
				addAffectation: this.addAffectation,
				vehicule: this.vehicule
			},
			listeFilters: [{
				clef: 'user',
				autocomplete: {
					type: 'user'
				},
				type: TypeFilter.AUTOCOMPLETE,
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'vehicule.idVehicule',
				valeur: this.vehicule.idVehicule,
				type: TypeFilter.LONG,
				typeComparaison: TypeComparaison.EQUAL
			}],
			defaultOrder: '-dateDebut,-dateFin'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any) {
		//Vérification de l'élément
		if (item.idAffectation > 0)
			//Mise à jour des compteurs
			this.resume.nbAffectations--;
	}
}