'use strict';

module.exports = {
  isOpera: function () {
    return global.navigator && /opera/i.test(global.navigator.userAgent);
  },
  isKonqueror: function () {
    return global.navigator && /konqueror/i.test(global.navigator.userAgent);
  }

  // #187 wrap document.domain in try/catch because of WP8 from file:///
  ,
  hasDomain: function () {
    // non-browser client always has a domain
    if (!global.document) {
      return true;
    }
    try {
      return !!global.document.domain;
    } catch (e) {
      return false;
    }
  }
};