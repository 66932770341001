<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.motorisation.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': cylindree.invalid }">
					<label translate>vehicule.modele.motorisation.cylindree</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="cylindree" #cylindree="ngModel" class="form-control" [(ngModel)]="information.motorisation.cylindree" nNumber/>
							<div class="input-group-addon no-border">cm<sup>3</sup></div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': nbCylindres.invalid }">
					<label translate>vehicule.modele.motorisation.nbCylindres</label>
					<div class="half">
						<input type="text" name="nbCylindres" #nbCylindres="ngModel" class="form-control" [(ngModel)]="information.motorisation.nbCylindres" nNumber/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': nbRapports.invalid }">
					<label translate>vehicule.modele.motorisation.nbRapports</label>
					<div class="half">
						<input type="text" name="nbRapports" #nbRapports="ngModel" class="form-control" [(ngModel)]="information.motorisation.nbRapports" nNumber/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': puissanceFiscale.invalid }">
					<label translate>vehicule.modele.motorisation.puissanceFiscale</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="puissanceFiscale" #puissanceFiscale="ngModel" class="form-control" [(ngModel)]="information.motorisation.puissanceFiscale" nNumber/>
							<div class="input-group-addon no-border">cv</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': coupleMax.invalid }">
					<label translate>vehicule.modele.motorisation.coupleMax</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="coupleMax" #coupleMax="ngModel" class="form-control" [(ngModel)]="information.motorisation.coupleMax" nNumber/>
							<div class="input-group-addon no-border">Nm</div>
						</div>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': puissanceKW.invalid }">
					<label translate>vehicule.modele.motorisation.puissanceKW</label>
					<div class="half">
						<div class="input-group">
							<input type="text" name="puissanceKW" #puissanceKW="ngModel" class="form-control" [(ngModel)]="information.motorisation.puissanceKW" nNumber/>
							<span class="input-group-addon no-border">Kw</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{ 'has-error': typeTransmission.invalid }">
					<label translate>vehicule.modele.motorisation.typeTransmission</label>
					<div>
						<autocomplete name="typeTransmission" type="typeTransmission" #typeTransmission="ngModel" [(ngModel)]="information.typeTransmission"></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': typeBoite.invalid }">
					<label translate>vehicule.modele.motorisation.typeBoite</label>
					<div>
						<autocomplete name="typeBoite" type="typeBoite" #typeBoite="ngModel" [(ngModel)]="information.typeBoite"></autocomplete>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': normePollution.invalid }">
					<label translate>vehicule.modele.motorisation.normePollution</label>
					<div>
						<input type="text" name="normePollution" #normePollution="ngModel" class="form-control" [(ngModel)]="information.motorisation.normePollution"/>
					</div>
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': systemeRecyclageGaz.invalid }">
					<label translate>vehicule.modele.motorisation.systemeRecyclageGaz</label>
					<div>
						<input type="text" name="systemeRecyclageGaz" #systemeRecyclageGaz="ngModel" class="form-control" [(ngModel)]="information.motorisation.systemeRecyclageGaz"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>