<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data.code.substring(0,3).toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Devise/loadDevise/',liste.selectedSelector,data.idDevise]">{{ data.libelle + ' (' + data.code + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.numeroIso"><span translate>devise.numeroIso</span>&#160;:&#160;<span>{{ data.numeroIso }}</span></li>
		<li *ngIf="data.deviseOrigine"><span translate>devise.personnalisee.libelle</span>&#160;:&#160;<span [translate]="'common.'+(data?.custom ? 'oui' : 'non')"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="((null | right:'consultation') || (null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot()) || (null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()) && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && (liste.selectedSelector == 'LOCAL' || rightService.isRoot())"><a [routerLink]="[]" (click)="deleteDevise();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && liste.selectedSelector == 'STANDARD' && !rightService.isRoot()"><a [routerLink]="[]" (click)="copyEntiteLocal(data.idDevise)"><span translate>actions.importer</span></a></li>
			<li *ngIf="(null | right:'consultation')"><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>