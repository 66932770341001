import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable,Subject,of } from 'rxjs';
import { filter,finalize,first,map,switchMap,tap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { CompteService } from '../comptabilite/compte/compte.service';
import { FactureAnalysisComponent } from './facture-analysis.component';
import { FactureAttachmentsComponent } from './facture-attachments.component';
import { FactureSelectionSocieteComponent } from './facture-selection-societe.component';

@Injectable()
export class FactureService {
	/** Liste des types de facture **/
	private readonly listeTypesFacture: Array<string> = ['FACTURE','AVOIR'];

	/** Liste des statuts de facture **/
	private readonly listeStatuts: Array<string> = ['INTEGREE','VALIDEE','COMPTABILISEE'];

	/** Liste des états de facture **/
	private readonly listeEtats: Array<string> = ['BROUILLON','VALIDE'];

	/** Liste des types de réconciliation **/
	private readonly listeTypesReconciliation: Array<string> = ['VEHICULE','COLLABORATEUR','EQUIPEMENT','SOCIETE'];

	/**
	 * Constructeur
	 */
	constructor(private messagingService: MessagingService,private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService,private compteService: CompteService,private toastrService: ToastrService,private confirmService: ConfirmService) { }

	/**
	 * Chargement d'une facture
	 */
	public loadFacture(idFacture: number): Observable<Result> {
		//Chargement de la facture
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadFacture/${idFacture}`,null);
	}

	/**
	 * Suppression d'une facture
	 */
	public deleteFacture(facture: any): Observable<Result> {
		//Suppression de la facture
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Facture/deleteFacture/${facture.idFacture}`);
	}

	/**
	 * Enregistrement d'une facture
	 */
	public saveFacture(facture: any): Observable<Result> {
		//Enregistrement de la facture
		return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/saveFacture`,facture);
	}

	/**
	 * Dénombrement des anomalies identiques
	 */
	public countAnomalie(facture: any,anomalie: any) {
		//Dénombrement des anomalies identiques
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/countAnomalie/${facture.idFacture}`,anomalie);
	}

	/**
	 * Enregistrement d'une ligne de détail
	 */
	private saveDetail(detail: any): Observable<Result> {
		//Enregistrement d'une ligne de détail
		return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/saveDetail/${detail.facture.idFacture}/${detail.facture.objectVersion}`,detail);
	}

	/**
	 * Suppression d'une ligne de détail
	 */
	public deleteDetail(detail: any): Observable<Result> {
		//Suppression de la ligne de détail
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Facture/deleteDetail/${detail.facture.idFacture}/${detail.facture.objectVersion}/${detail.idDetail}`);
	}

	/**
	 * Chargement d'une ligne de détail
	 */
	public loadDetail(idDetail: number): Observable<Result> {
		//Chargement d'une ligne de détail
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadDetail/${idDetail}`,null);
	}

	/**
	 * Récupération de la liste des types de facture
	 */
	public getListeTypesFacture(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de facture
		return this.listeTypesFacture.map(code => ({
			code,
			libelle: this.translateService.instant(`facture.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des statuts de facture
	 */
	public getListeStatuts(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des statuts de facture
		return this.listeStatuts.map(code => ({
			code,
			libelle: this.translateService.instant(`facture.statut.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des états de facture
	 */
	public getListeEtats(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des statuts de facture
		return this.listeEtats.map(code => ({
			code,
			libelle: this.translateService.instant(`facture.etat.${code}`)
		}));
	}

	/**
	 * Chargement de l'analyse d'une facture
	 */
	public loadFactureAnalysis(idFactureAnalysis: number): Observable<Result> {
		//Chargement de l'analyse d'une facture
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/loadFactureAnalysis/${idFactureAnalysis}`,null);
	}

	/**
	 * Déclenchement d'un traitement en masse
	 */
	public updateListeFactures(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();
		let messaging$: MessagingObservables;

		//Démarrage de l'action par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});

		return subject;
	}

	/**
	 * Réalisation d'une action Workflow
	 */
	doAction(typeAction: 'VALIDER' | 'REJETER',idFacture: number): Observable<boolean> {
		//Appel AJAX
		return this.http.post<Result>(`${environment.baseUrl}/controller/Facture/updateListeFactures/${typeAction}`,{
			typeActionMasse: 'SELECTION',
			listeIdObjects: [idFacture]
		}).pipe(
			first(),
			map(result => !result.data.listeIgnoredIds?.length)
		);
	}

	/**
	 * Récupération du libellé du détail
	 */
	public getLibelleDetailFor(detail: any): string {
		let libelle: string = null;

		//Vérification du lien
		if (detail.lien?.objet?.idVehicule || detail.lien?.objet?.idEquipement || detail.lien?.objet?.idService) {
			//Référence du véhicule, de l'équipement ou de la société
			libelle = detail.lien.objet.reference + (detail.lien.objet.idVehicule && detail.lien.objet.numeroInterne ? ' - ' + detail.lien.objet.numeroInterne : '') + (detail.lien.objet.idVehicule && detail.lien.objet.typeVehicule == 'ENGIN' && detail.lien.objet.marque ? ' - ' + detail.lien.objet.marque.libelle : '');
		} else if (detail.lien?.objet?.idUser) {
			//Collaborateur
			libelle = detail.lien.objet.prenom + ' ' + detail.lien.objet.nom + ' (' + detail.lien.objet.matricule + (detail.lien.objet.societe ? ' - ' + detail.lien.objet.societe.libelle : '') + ')';
		} else if (detail.nature && detail.lien) {
			//Vérification du type de réconciliation
			switch (detail.nature.typeReconciliation) {
			case 'VEHICULE':
				//Immatriculation du véhicule
				libelle = detail.lien.objet.reference + (detail.lien.objet.numeroInterne ? ' - ' + detail.lien.objet.numeroInterne : '');
				break;
			case 'COLLABORATEUR':
				//Collaborateur
				libelle = detail.lien.objet.prenom + ' ' + detail.lien.objet.nom + ' (' + detail.lien.objet.matricule + (detail.lien.objet.societe ? ' - ' + detail.lien.objet.societe.libelle : '') + ')';
				break;
			case 'EQUIPEMENT':
				//Référence de l'équipement
				libelle = detail.lien.objet.reference;
				break;
			default:
				//Aucun
				libelle = this.translateService.instant('common.nonDefini');
				break;
			}
		}

		//Vérification du libellé
		if (!libelle)
			//libellé non défini
			libelle = this.translateService.instant('common.nonDefini');

		return libelle;
	}

	/**
	 * Affichage de la pop-up de scan des factures (LAD)
	 */
	public showScanFacture() {
		//Affichage de la popup
		this.bsModalService.show(FactureAttachmentsComponent,{
			initialState: {},
			class: 'modal-lg'
		});
	}

	/**
	 * Affichage de la pop-up d'analyse d'une facture
	 */
	public showFactureAnalysis(facture: any) {
		//Vérification de la présence d'une analyse de facture
		if (facture.analysis?.idFactureAnalysis) {
			//Affichage de la popup
			this.bsModalService.show(FactureAnalysisComponent,{
				initialState: {
					idFactureAnalysis: facture.analysis?.idFactureAnalysis
				},
				class: 'modal-max'
			});
		}
	}

	/**
	 * Enregistrement d'une facture depuis une analyse
	 */
	public saveFactureFromAnalysis(facture: any): Observable<Result> {
		//Enregistrement d'une facture depuis une analyse
		return this.http.put<Result>(`${environment.baseUrl}/controller/Facture/saveFactureFromAnalysis`,facture);
	}

	/**
	 * Vérification de la nature en fonction d'un type de champs
	 */
	public hasTypeField(nature: { listeFields },searchTypeField: 'RELEVE_CARBURANT' | 'ENTRETIEN' | 'ENTRETIEN' | 'REALISATION_CONTROLE_TECHNIQUE' | 'REALISATION_CONTROLE_ANTIPOLLUTION'): boolean {
		//Vérification de la facture et du type de champ associé à la nature de la ligne de détail
		return nature?.listeFields?.some(f => f.actif && f.typeField == searchTypeField);
	}

	/**
	 * Ouverture de la popup de la sélection de société
	 */
	private showSelectionSociete(facture: any,mode: 'ASSOCIATION' | 'RECONCILIATION' = 'ASSOCIATION'): Observable<{ societe: any,codeCompteFacturation?: string }> {
		let bsModalRef: BsModalRef<FactureSelectionSocieteComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(FactureSelectionSocieteComponent,{
			initialState: {
				societe: cloneDeep(facture.societe),
				codeCompteFacturation: cloneDeep(facture.codeCompteFacturation),
				options: {
					mode
				}
			}
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result),
			filter(result => !!result?.societe)
		);
	}

	/**
	 * Réconciliation d'un code de compte de facturation avec une société
	 */
	public showReconciliationCompteSociete(facture: any): Observable<Result> {
		let compteFacturation: any;

		//Chargement du compte de facturation pour le fournisseur
		return this.compteService.loadCompteFacturationForFournisseur(facture.fournisseur.idFournisseur,facture.codeCompteFacturation).pipe(
			tap((result: Result) => compteFacturation = result?.data?.compteFacturation),
			switchMap(() => this.showSelectionSociete(facture,'RECONCILIATION')),
			map(({ societe }) => ({ ...compteFacturation,fournisseur: facture.fournisseur,code: facture.codeCompteFacturation,societe })),
			switchMap((compteFacturation: any) => this.compteService.saveCompteFacturation(compteFacturation))
		);
	}

	/**
	 * Association de la société à la facture
	 */
	public showAssociationSociete(facture: any): Observable<Result> {
		//Affichage de la sélection de la société pour la facture
		return this.showSelectionSociete(facture).pipe(
			filter(result => result?.societe),
			map(({ societe }) => {
				//Mise à jour de la société
				facture.societe = societe;

				return facture;
			}),
			switchMap((facture: any) => this.saveFacture(facture))
		);
	}

	/**
	 * Récupération de la liste des types de réconciliation
	 */
	public getListeTypesReconciliation(): Array<{ code: string,libelle: string }> {
		//Liste des types de réconciliation
		return this.listeTypesReconciliation.map(code => ({
			code,
			libelle: this.translateService.instant('facture.detail.typeReconciliation.' + code)
		}));
	}

	/**
	 * Enregistrement d'une ligne de facture
	 */
	doSaveDetail(detail: any): Observable<{ detail: any,facture?: any,isCorrectionAnomalie?: boolean,nbDetailsAnomalie?: number }> {
		//Retour d'un observable
		return of(detail?.listeAnomalies?.find((anomalie: { typeAnomalie: string }) => anomalie.typeAnomalie?.startsWith(detail.lien?.typeDetail == 'SERVICE' ? 'SOCIETE' : detail.lien?.typeDetail)) || detail.listeAnomalies?.[0] || null).pipe(
			switchMap((anomalieReconciliation: any) => {
				//Vérification de la présence d'une anomalie
				if (anomalieReconciliation) {
					//Dénombrement des anomalies identiques
					return this.countAnomalie(detail.facture,anomalieReconciliation).pipe(
						map((result: Result) => result.data?.nbLignes),
						switchMap((nbLignes: number) => {
							//Vérification de la présence d'une anomalie
							if (nbLignes > 0) {
								//Confirmation
								return this.confirmService.showConfirm(this.translateService.instant('facture.detail.correctionReconciliation.confirmation',{ nbLignes }),{ buttonType: 'YES_NO',actionColor: 'primary' }).pipe(
									switchMap((doReconciliation: boolean) => {
										let messaging$: MessagingObservables;
										let subject: Subject<any>;

										//Vérification de la réconciliation
										if (doReconciliation) {
											//Création d'un sujet
											subject = new Subject<any>();

											//Initialisation de la WebSocket
											messaging$ = this.messagingService.init({
												entryPoint: `controller/Facture/saveDetail/${detail.facture.idFacture}/${detail.facture.objectVersion}`,
												outputPoint: '/messaging/Facture/saveDetail/status',
												params: { ...detail,correctionAnomalie: true },
												progressConfig: {
													libelle: this.translateService.instant('facture.detail.correctionReconciliation.progress'),
													icone: 'autorenew'
												},
												method: 'PUT'
											}).onResult({
												next: (result: Result) => subject.next(result)
											}).onFinish({
												next: () => {
													//Rechargement de la facture
													this.loadFacture(detail.facture?.idFacture).pipe(
														finalize(() => {
															//Finalisation du traitement
															subject.complete();

															//Annulation de l'abonnement
															messaging$.unsubscribe();
														})
													).subscribe({
														next: (result: Result) => subject.next({ ...result,isCorrectionAnomalie: true })
													})
												}
											}).onError({
												next: () => {
													//Finalisation du traitement
													subject.complete();

													//Annulation de l'abonnement
													messaging$.unsubscribe();
												}
											});

											return subject;
										} else
											//Enregistrement de la ligne de facture
											return this.saveDetail(detail);
									}))
							} else
								//Enregistrement de la ligne de facture
								return this.saveDetail(detail);
						}))
				} else
					//Enregistrement de la ligne de facture
					return this.saveDetail(detail);
			}),
			map((result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR && result?.data?.detail)
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));
				else if (result?.data?.detail)
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

				//Retour des informations de la ligne de facture
				return {
					detail: result?.data?.detail,
					facture: result?.data?.facture,
					isCorrectionAnomalie: (result as any)?.isCorrectionAnomalie,
					nbDetailsAnomalie: result?.data?.nbDetailsAnomalie
				};
			}))
	}

	/**
	 * Récupération d'une anomalie de réconciliation
	 */
	public getAnomalieReconciliation(detail: { [key: string]: any,listeAnomalies: Array<any> }): { typeAnomalie: 'EQUIPEMENT_NUMERO' | 'EQUIPEMENT' | 'VEHICULE' | 'COLLABORATEUR' | 'SOCIETE' | null } {
		//Récupération d'une anomalie de réconciliation
		return detail.listeAnomalies?.find((anomalie) => anomalie.typeAnomalie != 'TAXE') || null;
	}
}