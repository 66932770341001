import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { LotComptableListComponent } from './lot-comptable-list.component';
import { LotComptableListItemComponent } from './lot-comptable-list-item.component';
import { LotComptableService } from './lot-comptable.service';
import { LotComptableComponent } from './lot-comptable.component';
import { LotComptableFactureListComponent } from './lot-comptable-facture-list.component';
import { LotComptableFactureListItemComponent } from './lot-comptable-facture-list-item.component';
import { LotComptableStructureExportSocietesAnomalie } from './lot-comptable-structure-export-societes-anomalie.component';
import { LotComptableStructureExportResult } from './lot-comptable-structure-export-result.component';
import { LotComptableEditComponent } from './lot-comptable-edit.component';
import { LotComptableCheckComponent } from './lot-comptable-check.component';
import { LotComptableCheckPlanComptableComponent } from './lot-comptable-check-plan-comptable.component';
import { LotComptableCheckPlanComptableListItemComponent } from './lot-comptable-check-plan-comptable-list-item.component';
import { LotComptableCheckChargeComponent } from './lot-comptable-check-charge.component';
import { LotComptableCheckChargeListItemComponent } from './lot-comptable-check-charge-list-item.component';
import { LotComptableCheckTaxeComponent } from './lot-comptable-check-taxe.component';
import { LotComptableCheckTaxeListItemComponent } from './lot-comptable-check-taxe-list-item.component';
import { LotComptableCheckRegleTaxeComponent } from './lot-comptable-check-regle-taxe.component';
import { LotComptableCheckRegleTaxeListItemComponent } from './lot-comptable-check-regle-taxe-list-item.component';
import { LotComptableCheckTiersComponent } from './lot-comptable-check-tiers.component';
import { LotComptableCheckTiersListItemComponent } from './lot-comptable-check-tiers-list-item.component';
import { LotComptableCheckAuxiliaireComponent } from './lot-comptable-check-auxiliaire.component';
import { LotComptableCheckAuxiliaireListItemComponent } from './lot-comptable-check-auxiliaire-list-item.component';
import { LotComptableCheckRecapitulatifComponent } from './lot-comptable-check-recapitulatif.component';
import { LotComptableCheckFactureListComponent } from './lot-comptable-check-facture-list.component';
import { LotComptableCheckFactureListItemComponent } from './lot-comptable-check-facture-list-item.component';
import { LotComptableCheckPosteComponent } from './lot-comptable-check-poste.component';
import { LotComptableCheckPosteListItemComponent } from './lot-comptable-check-poste-list-item.component';

@NgModule({
	imports: [ShareModule],
	declarations: [LotComptableListComponent,LotComptableListItemComponent,LotComptableComponent,LotComptableEditComponent,LotComptableFactureListComponent,LotComptableFactureListItemComponent,LotComptableStructureExportSocietesAnomalie,LotComptableStructureExportResult
		,LotComptableCheckComponent,LotComptableCheckPlanComptableComponent,LotComptableCheckPlanComptableListItemComponent,LotComptableCheckChargeComponent,LotComptableCheckChargeListItemComponent
		,LotComptableCheckTaxeComponent,LotComptableCheckTaxeListItemComponent,LotComptableCheckRegleTaxeComponent,LotComptableCheckRegleTaxeListItemComponent,LotComptableCheckTiersComponent,LotComptableCheckTiersListItemComponent,LotComptableCheckAuxiliaireComponent,LotComptableCheckAuxiliaireListItemComponent
		,LotComptableCheckRecapitulatifComponent,LotComptableCheckFactureListComponent,LotComptableCheckFactureListItemComponent,LotComptableCheckPosteComponent,LotComptableCheckPosteListItemComponent
	],
	providers: [LotComptableService]
})
export class LotComptableModule { }
