<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idCode">
		<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + (data?.reference ? ' (' + data?.reference + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idCode">
		<li *ngIf="data.interfaceFournisseur"><span translate>fournisseur.code.format</span>&#160;:&#160;<span>{{ data.interfaceFournisseur.libelle }}</span></li>
		<li><span translate>fournisseur.code.nature</span>&#160;:&#160;<span>{{ data.nature ? data.nature.libelle : ('common.aucune' | translate) }}</span></li>
		<li *ngIf="data.interfaceFournisseur?.fournisseur"><span translate>fournisseur.code.fournisseur</span>&#160;:&#160;<span>{{ data.interfaceFournisseur.fournisseur.libelle }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idCode">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'suppression') && rightService.isRoot()"><a [routerLink]="[]" (click)="deleteCode();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data?.isDisplayed">
	<fournisseur-code [code]="data" (onObjectChange)="onObjectChange($event)"></fournisseur-code>
</div>