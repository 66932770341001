import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ChartService } from '../chart/chart.service';
import { CalendrierService } from './calendrier.service';

@Component({
	selector: 'calendrier-edit',
	templateUrl: './calendrier-edit.component.html'
})
export class CalendrierEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() calendrier: any;

	/** Suppression du calendrier **/
	@Input() deleteCalendrier: () => void;

	/** Liste des types de diffusion **/
	listeTypesDiffusion: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private calendrierService: CalendrierService,private chartService: ChartService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Copie du calendrier
		this.calendrier = cloneDeep(this.calendrier);

		//Récupération de la liste des types de diffusion
		this.listeTypesDiffusion = this.chartService.getListeTypesDiffusion();
	}

	/**
	 * Enregistrement du calendrier
	 */
	saveCalendrier() {
		//Enregistrement du calendrier
		this.calendrierService.saveCalendrier(this.calendrier).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du calendrier
					this.calendrier = result.data.calendrier;

					//Fermeture de l'édition
					this.close(this.calendrier);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}