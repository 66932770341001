<div class="container-fluid">
	<form #vehiculeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="card full">
				<div class="card-body card-padding">
					<div class="alert alert-danger" *ngIf="!periode?.isDateEntreeValid || !periode?.isDateSortieValid">
						<icon name="warning"></icon>
						<div>
							<strong translate>vehicule.chevauchementPeriode.title</strong>
							<div *ngIf="!periode?.isDateEntreeValid" translate>vehicule.chevauchementPeriode.affectationEntree</div>
							<div *ngIf="!periode?.isDateSortieValid" translate>vehicule.chevauchementPeriode.affectationSortie</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>vehicule.description</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label [translate]="vehicule?.typeVehicule != TypeVehicule.ENGIN ? 'vehicule.immatriculation.item' : 'vehicule.numero'"></label>
								<div *ngIf="vehicule?.immatriculation?.typeSource == 'IMPORT' && configurationPays?.synchroSIV || vehicule?.idVehicule && vehicule?.listeOldReferences?.length">
									<div class="input-group">
										<a [routerLink]="[]" (click)="vehicule?.typeVehicule != TypeVehicule.ENGIN ? showImmatriculation(false) : showEngin()">{{ vehicule.reference }}</a>
										<div class="input-group-addon no-border" *ngIf="vehicule?.immatriculation?.typeSource == 'IMPORT' && configurationPays?.synchroSIV">
											<span translate>vehicule.immatriculation.SIV</span>
										</div>
										<div class="input-group-addon clickable" *ngIf="vehicule?.idVehicule && vehicule?.listeOldReferences?.length" (click)="vehiculeService.showListeImmatriculations(vehicule.idVehicule)" [tooltip]="'vehicule.immatriculation.historique.title' | translate">
											<icon name="replay_30"></icon>
										</div>
									</div>
								</div>
								<div *ngIf="!(vehicule?.immatriculation?.typeSource == 'IMPORT' && configurationPays?.synchroSIV || vehicule?.idVehicule && vehicule?.listeOldReferences?.length)">
									<a [routerLink]="[]" (click)="vehicule?.typeVehicule != TypeVehicule.ENGIN ? showImmatriculation(false) : showEngin()">{{ vehicule.reference }}</a>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule?.modeleConstructeur?.typeSource == 'EXTERNE_CATALOGUE' || vehicule?.immatriculation && filtreModeleConstructeurForImmatriculation(vehicule?.immatriculation)?.length">
								<label translate>vehicule.modeleConstructeur</label>
								<div>
									<a *ngIf="vehicule?.modeleConstructeur || (null | right:'creation')" [routerLink]="[]" (click)="this.vehicule?.modeleConstructeur ? showModeleConstructeur() : doChangeModele()">{{ vehicule.modeleConstructeur ? ((vehicule.modeleConstructeur | display:'modele') + (vehicule?.modeleConstructeur?.finition ? ' (' + vehicule?.modeleConstructeur?.finition + ')' : '')) : ('common.aSelectionner' | translate) }}</a>
									<span *ngIf="!vehicule?.modeleConstructeur &&  !(null | right:'creation')" translate>common.aucun</span>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE || vehicule.typeVehicule == TypeVehicule.ENGIN" [ngClass]="{ 'has-error': dateEntree.invalid || !periode?.isDateEntreeValid }">
								<label translate>vehicule.dateEntree.item</label>
								<div class="half">
									<div class="input-group">
										<input type="text" class="form-control" name="dateEntree" #dateEntree="ngModel" [(ngModel)]="vehicule.dateEntree" (ngModelChange)="isPeriodeValidForVehicule()" datePicker format="datetime" [maxDate]="vehicule.dateSortie" required/>
										<div class="input-group-addon" [tooltip]="'vehicule.dateEntree.description' | translate">
											<icon name="info_outline"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE || vehicule.typeVehicule == TypeVehicule.ENGIN" [ngClass]="{ 'has-error': dateSortie.invalid || !periode?.isDateSortieValid }">
								<label translate>vehicule.dateSortie.item</label>
								<div class="half">
									<div class="input-group">
										<input type="text" class="form-control" name="dateSortie" #dateSortie="ngModel" [(ngModel)]="vehicule.dateSortie" (ngModelChange)="isPeriodeValidForVehicule()" datePicker format="datetime" [minDate]="vehicule.dateEntree"/>
										<div class="input-group-addon" [tooltip]="'vehicule.dateSortie.description' | translate">
											<icon name="info_outline"></icon>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
								<label translate>vehicule.societeImputation</label>
								<div>
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="vehicule.societe" (ngModelChange)="vehicule.service = null" [required]="!vehicule?.idVehicule && user?.listeSocietes != null" [disabled]="vehicule?.idVehicule"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
								<label translate>vehicule.serviceImputation</label>
								<div>
									<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="vehicule.service" [filter]="{ idSociete: vehicule?.societe?.idService }" [disabled]="vehicule.idVehicule || !vehicule.societe || vehicule?.societe?.extension?.nbSousServices == 0" [required]="vehicule?.societe?.extension?.nbSousServices > 0"></autocomplete>
								</div>
							</div>
							<div class="form-group" *ngIf="(TypeDroit.ENTRETIEN | right:'consultation') && vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE" [ngClass]="{ 'has-error': planEntretien.invalid }">
								<label translate>vehicule.planEntretien.item</label>
								<div>
									<autocomplete name="planEntretien" type="planEntretien" #planEntretien="ngModel" [(ngModel)]="vehicule.planEntretien"></autocomplete>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule?.pays?.idPays != user?.pays?.idPays">
								<label translate>vehicule.pays</label>
								<div>
									<span>{{ vehicule.pays | display:'pays' }}</span>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE" [ngClass]="{ 'has-error': pool.invalid }">
								<label translate>vehicule.pool.item</label>
								<div>
									<autocomplete name="pool" type="pool" #pool="ngModel" [(ngModel)]="vehicule.pool" [required]="isPoolRequired()"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label translate>vehicule.actif.item</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="vehicule.actif"><span translate>vehicule.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>vehicule.imagePerso.item</label>
								<div [attachment]="vehicule" [typeAttachment]="TypeAttachment.VEHICULE" typeLink="PRESENTATION" queueLimit="1" filters="jpeg|jpg|png|heic"></div>
							</div>
							<div class="form-group">
								<label>
									<span translate>vehicule.informationTarifaire.item</span>
									<icon *ngIf="vehicule?.typeVehicule == 'VEHICULE_FONCTION_SERVICE' && !vehicule?.informationTarifaire?.prixTTC && !vehicule?.informationTarifaire?.prixTTCRemise" name="warning" class="c-warning icon-hc-2x m-l-5" [tooltip]="'vehicule.informationTarifaire.informationsNecessairesCalcul' | translate"></icon>
								</label>
								<div>
									<a [routerLink]="[]" (click)="showInformationTarifaire()">{{ getLibelleInformationTarifaire() }}</a>
								</div>
							</div>
							<div class="form-group">
								<label translate>vehicule.numeroInterne</label>
								<div>
									<input type="text" name="numeroInterne" #numeroInterne="ngModel" class="form-control" [(ngModel)]="vehicule.numeroInterne" maxlength="100"/>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE">
								<label translate>vehicule.capaciteReservoir</label>
								<div class="half">
									<input type="text" name="capaciteReservoir" #capaciteReservoir="ngModel" class="form-control" [(ngModel)]="vehicule.capaciteReservoir" nNumber/>
								</div>
							</div>
							<div class="form-group" *ngIf="vehicule.typeVehicule != TypeVehicule.ENGIN && configurationPays?.critAir">
								<label>
									<span translate>vehicule.critAir.item</span>
									<icon *ngIf="vehicule?.extension?.critAirComputed" name="info_outline" class="m-l-5" [tooltip]="'vehicule.critAir.description' | translate:{ critAir: ('vehicule.critAir.'+vehicule?.extension?.critAirComputed) | translate }"></icon>
									<icon *ngIf="vehicule?.idVehicule && !vehicule?.extension?.critAirComputed" name="info_outline" class="m-l-5" [tooltip]="'vehicule.critAir.descriptionEmpty' | translate"></icon>
								</label>
								<div>
									<selectpicker name="critAir" #critAirField="ngModel" [(ngModel)]="vehicule.critAir">
										<mat-option *ngFor="let critAir of listeTypesCritAir" [value]="critAir.code">{{ critAir.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': uniteUsageField.invalid }">
								<label translate>vehicule.uniteUsage.item</label>
								<div>
									<selectpicker name="uniteUsage" #uniteUsageField="ngModel" [(ngModel)]="vehicule.uniteUsage">
										<mat-option *ngFor="let uniteUsage of listeTypesUniteUsage" [value]="uniteUsage.code">{{ uniteUsage.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" *ngIf="userOrigine && vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE">
								<label translate>vehicule.disableSynchroSGI.item</label>
								<div>
									<mat-checkbox name="disableSynchroSGI" #disableSynchroSGI="ngModel" [(ngModel)]="vehicule.disableSynchroSGI"><span translate>vehicule.disableSynchroSGI.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>vehicule.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="vehicule.remarque" rows="3"></textarea>
						</div>
					</div>
					<div class="form-group" *ngIf="vehicule.pool">
						<label>
							<span translate>vehicule.remarqueConducteur.item</span>
							<icon name="info_outline" class="m-l-5" [tooltip]="'vehicule.remarqueConducteur.description' | translate"></icon>
						</label>
						<div>
							<textarea class="form-control" name="remarqueConducteur" #remarqueConducteur="ngModel" [(ngModel)]="vehicule.remarqueConducteur" rows="3"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="card full">
				<data-customization entite="com.notilus.data.vehicule.Vehicule" [object]="vehicule" [disabled]="!(null | right:'creation')"></data-customization>
			</div>
		</fieldset>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteVehicule();" *ngIf="vehicule?.idVehicule && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" (click)="saveVehicule();" *ngIf="null | right:'creation'" [disabled]="vehiculeForm.invalid || !periode?.isDateSortieValid || !periode?.isDateEntreeValid">
				<span [translate]="'actions.' + (vehicule?.idVehicule ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="vehicule?.idVehicule" [formDetectChanges]="{ doAction: close,isDirty: vehiculeForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>