import { NgModule } from '@angular/core';


import { CoreComponentsModule } from 'src/app/share/components/core-components.module';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';

@NgModule({
    imports: [CoreComponentsModule],
    declarations: [ConfirmComponent],
    providers: [ConfirmService]
})
export class ConfirmModule {}