import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ReponseCotationListItemComponent } from './reponse-cotation-list-item.component';

@Component({
	selector: 'reponse-cotation-list',
	templateUrl: './reponse-cotation-list.component.html'
})
export class ReponseCotationListComponent extends PageContentItem implements OnInit {
	/** Réponse à la demande de cotation **/
	@Input() reponseDemandeCotation: any;

	/** Résumé **/
	@Input() resume: { nbCotations: number };

	/** Affichage d'une cotation **/
	@Input() showReponseCotation: Function

	/** Données **/
	liste: ListView<any,ReponseCotationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ReponseCotationListItemComponent>({
			uri: () => `/controller/VehiculeCommande/filtreReponsesCotationsForReponse/${this.reponseDemandeCotation.idReponse}`,
			component: ReponseCotationListItemComponent,
			isLocal: true,
			onRefresh: (liste,result) => this.resume.nbCotations = result.totalElements,
			extraOptions: {
				resume: this.resume,
				reponseDemandeCotation: this.reponseDemandeCotation,
				showReponseCotation: this.showReponseCotation
			}
		});
	}
}