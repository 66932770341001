<div class="pull-left lv-avatar">{{ (data.type == 'RESPONSABLE' ? 'R' : 'N+') + data.typeValideur.slice(-1) }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addUserResponsable?.(data,deleteUserResponsable)">
			<span *ngIf="data.type == 'RESPONSABLE' && data.service">{{ data.service.libelle + (data.societe ? ' (' + data.societe.libelle + ')' : '') }}</span>
			<span *ngIf="data.type == 'RESPONSABLE' && !data.service">{{ data.societe.libelle }}</span>
			<span *ngIf="data.type == 'VALIDEUR'">{{ data.user | display:'user' }}</span>
			<span *ngIf="data.type == 'RESPONSABLE_POOL'">{{ data.pool | display:'pool' }}</span>
		</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idObject">
		<li *ngIf="data.type"><span translate>user.userResponsable.type.item</span>&#160;:&#160;<span [translate]="'user.userResponsable.type.' + data.type + '.item'"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idObject && (data.user || data?.service && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'consultation') || (null | right:'creation'))">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="data?.service && (TypeDroit.ADMIN_ANALYTIQUE_SERVICE | right:'consultation')"><a [routerLink]="[]" (click)="goToService()"><span translate>user.actions.accederService</span></a></li>
			<li *ngIf="data.user"><a [routerLink]="[]" (click)="goToUser()"><span translate>user.actions.accederUser</span></a></li>
			<li *ngIf="null | right:'creation'"><a [routerLink]="[]" (click)="deleteUserResponsable()"><span translate>actions.supprimer</span></a></li>
		</ul>
	</div>
</div>