import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { PoolAddComponent } from './pool-add.component';
import { PoolEditComponent } from './pool-edit.component';
import { PoolListItemComponent } from './pool-list-item.component';
import { PoolListComponent } from './pool-list.component';
import { PoolVehiculeAddItemComponent } from './pool-vehicule-add-item.component';
import { PoolVehiculeAddComponent } from './pool-vehicule-add.component';
import { PoolVehiculeListItemComponent } from './pool-vehicule-list-item.component';
import { PoolVehiculeListComponent } from './pool-vehicule-list.component';
import { PoolComponent } from './pool.component';
import { PoolService } from './pool.service';

@NgModule({
	imports: [ShareModule],
	declarations: [PoolComponent,PoolEditComponent,PoolListComponent,PoolListItemComponent,PoolVehiculeListComponent,PoolVehiculeListItemComponent,PoolVehiculeAddComponent,PoolVehiculeAddItemComponent,PoolAddComponent],
	providers: [PoolService]
})
export class PoolModule { }
