<div class="container-fluid">
	<form #calendrierForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<fieldset [disabled]="!(null | right:'creation')">
				<div class="card-body card-padding">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>calendrier.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="calendrier.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>calendrier.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="calendrier.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': diffusion.invalid }">
								<label translate>calendrier.diffusion</label>
								<div class="half">
									<selectpicker name="diffusion" #diffusion="ngModel" [(ngModel)]="calendrier.typeDiffusion" required>
										<mat-option *ngFor="let type of listeTypesDiffusion" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>
									<span translate>calendrier.actif.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'calendrier.actif.info' | translate"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="calendrier.actif" [disabled]="!calendrier.listeDetails || calendrier.listeDetails.length == 0"><span translate>calendrier.actif.description</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>calendrier.remarque</label>
						<div>
							<textarea class="form-control" rows="3" name="remarque" [(ngModel)]="calendrier.remarque"></textarea>
						</div>
					</div>
				</div>
			</fieldset>
		</div>

		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteCalendrier();" *ngIf="calendrier.idCalendrier && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="calendrierForm.invalid" (click)="saveCalendrier();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(calendrier?.idCalendrier ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="calendrier.idCalendrier" [formDetectChanges]="{ doAction: close,isDirty: calendrierForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>