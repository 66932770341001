import { CurrencyPipe,DatePipe,DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter,first,map,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Page } from 'src/app/domain/common/http/list-result';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { Options } from 'src/app/domain/comparator/comparator';
import { User } from 'src/app/domain/user/user';
import { ComparatorService } from 'src/app/share/components/comparaison/comparator.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CotationService {
	/** Utilisateur courant **/
	private user: User;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private comparatorService: ComparatorService,private translateService: TranslateService,private datePipe: DatePipe,private currencyPipe: CurrencyPipe,private decimalPipe: DecimalPipe,private store: Store<AppState>,private toastrService: ToastrService,private confirmService: ConfirmService) {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;
		});
	}

	/**
	 * Chargement d'une cotation
	 */
	public loadCotation(idCotation: number): Observable<Result> {
		//Chargement d'une cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/loadCotation/${idCotation}`,null);
	}

	/**
	 * Suppression d'une cotation
	 */
	public deleteCotation(cotation: any): Observable<Result> {
		//Suppression d'une cotation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeCommande/deleteCotation/${cotation.idCotation}`);
	}

	/**
	 * Enregistrement d'une cotation
	 */
	public saveCotation(cotation: any): Observable<Result> {
		//Enregistrement d'une cotation
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeCommande/saveCotation`,cotation);
	}

	/**
	 * Annulation d'une cotation
		*/
	public cancelCotation(cotation: any): Observable<Result> {
		//Annulation d'une cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/cancelCotation/${cotation.idCotation}`,null);
	}
	/**
	 * Exécution d'une action pour une réponse à une cotation
	 */
	public executeActionForReponseCotation(typeAction: string,reponseCotation: any): Observable<Result> {
		//Exécution d'une action pour une réponse à une cotation
		return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeCommande/executeActionForReponseCotation/${typeAction}/${reponseCotation.idReponseCotation}`,null);
	}

	/**
	 * Récupération de la liste des cotations pour une demande de véhicule
	 */
	public filtreCotationsForDemandeVehicule(idDemandeVehicule: number): Observable<Page<any>> {
		let searchSpec: any;

		//Initialisation de la recherche
		searchSpec = {
			listeFilter: [{
				clef: 'demandeVehicule.idDemandeVehicule',
				valeur: idDemandeVehicule,
				typeComparaison: TypeComparaison.EQUAL,
				type: TypeFilter.STRING
			}],
			defaultOrder: '-demandeCotation.idDemandeCotation'
		};

		//Exécution de la recherche
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/VehiculeCommande/filtreCotations`,searchSpec);
	}

	/**
	 * Récupération de la liste des réponses aux demandes de cotation pour une cotation
	 */
	public filtreReponsesCotationsForCotation(cotation: any): Observable<Array<any>> {
		//Récupération de la liste des réponses aux demandes de cotation pour une cotation
		return this.http.post<Array<any>>(`${environment.baseUrl}/controller/VehiculeCommande/filtreReponsesCotationsForCotation/${cotation.idCotation}`,null);
	}

	/**
	 * Exécution de l'action sur une réponse à la cotation
	 */
	private executeActionForReponse(action: 'RELANCER' | 'VALIDER',reponseCotation: any): Observable<any> {
		//Demande de confirmation
		return this.confirmService.showConfirm(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.confirmation`),{ actionColor: 'primary' }).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.executeActionForReponseCotation(action,reponseCotation)),
			map((result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR)
					//Affichage d'un message de succès
					this.toastrService.success(this.translateService.instant(`demandeCotation.actions.${action.toLowerCase()}.success`));
				else
					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant(`demandeCotation.actions.error`));

				return result;
			})
		);
	}

	/**
	 * Ouverture de la pop-up de comparaison des cotations
	 */
	public showComparaisonForCotation(cotation: any): Observable<any> {
		let options: Options<any>;
		let listeStatuts: Array<string>;

		//Liste des statuts ordonnés
		listeStatuts = ['TRANSMISE','A_TRANSMETTRE','A_COMPLETER','DECLINEE'];

		//Chargement des réponses pour la cotation
		return this.filtreReponsesCotationsForCotation(cotation).pipe(
			switchMap((listeReponsesDemandeCotation: Array<any>) => {
				//Tri de la liste par statut
				listeReponsesDemandeCotation = sortBy(listeReponsesDemandeCotation,[i => listeStatuts.indexOf(i.statut),'idReponseCotation']);

				//Définition des options
				options = {
					typeAttachment: TypeAttachment.COTATION,
					title: this.translateService.instant('demandeCotation.comparaison.title'),
					headInfo: this.translateService.instant('demandeCotation.comparaison.nbReponses',{
						nbReponses: listeReponsesDemandeCotation.length,
						nbCompletes: listeReponsesDemandeCotation.filter(r => r.statut != 'A_COMPLETER').length
					}),
					itemTitle: this.translateService.instant('demandeCotation.comparaison.contact'),
					getFormatItemTitle: (item: any) => ({
						nbAttachments: item?.listeLinks?.length || 0,
						subTitle: item?.statut == 'DECLINEE' && item?.dateReponse ? this.translateService.instant('demandeCotation.comparaison.dateDeclinaison',{ dateDeclinaison: this.datePipe.transform(item?.dateReponse,'shortDate') }) : ['TRANSMISE','RETENUE','NON_RETENUE'].includes(item?.statut) && this.translateService.instant('demandeCotation.comparaison.dateEnvoi',{ dateEnvoi: this.datePipe.transform(item?.dateEnvoi,'shortDate') }) || null,
						title: {
							line1: `${item?.reponseDemandeCotation?.contact?.nom} ${item?.reponseDemandeCotation?.contact?.prenom}`,
							line2: item?.reponseDemandeCotation?.contact?.fournisseur?.libelle
						}
					}),
					listeSections: [{
						title: this.translateService.instant('demandeCotation.comparaison.sections.modele'),
						compare: (item: any) => {
							//Vérification des modèles
							if (item.modele && (!['DECLINEE','A_COMPLETER'].includes(item.statut) && ['RETOUR_PARTIEL','RETOUR_COMPLET'].includes(cotation.statut) || cotation.statut == 'SOLDEE' && item.dateReponse))
								//Comparaison des modèles
								return { icone: 'done' };
							else if (item.statut == 'DECLINEE')
								//Affichage d'un tiret
								return { icone: 'remove' };
							else
								//Aucun retour
								return null;
						}
					},{
						title: this.translateService.instant('demandeCotation.comparaison.sections.financement'),
						subTitle: cotation.typeFinancement.libelle,
						compare: (item) => {
							//Vérification des types de financement
							if (item.typeFinancement  && (!['DECLINEE','A_COMPLETER'].includes(item.statut) && ['RETOUR_PARTIEL','RETOUR_COMPLET'].includes(cotation.statut) || cotation.statut == 'SOLDEE' && item.dateReponse && item.modele))
								//Comparaison des types de financement
								return cotation.typeFinancement.idTypeFinancement == item.typeFinancement.idTypeFinancement ? { icone: 'done' } : { title: item.typeFinancement.libelle };
							else if (item.statut == 'DECLINEE')
								//Affichage d'un tiret
								return { icone: 'remove' };
							else
								//Aucun retour
								return null;
						}
					},{
						title: this.translateService.instant('demandeCotation.comparaison.sections.loiRoulage'),
						subTitle: cotation.duree && cotation.distance && this.translateService.instant('demandeCotation.comparaison.subTitleLoiRoulage',{ duree: cotation.duree,distance: this.decimalPipe.transform(cotation.distance),unite: this.user?.unite?.libelleCourt }),
						compare: (item) => {
							//Vérification de la durée et de la distance
							if (item.duree && item.distance && (!['DECLINEE','A_COMPLETER'].includes(item.statut) && ['RETOUR_PARTIEL','RETOUR_COMPLET'].includes(cotation.statut) || cotation.statut == 'SOLDEE' && item.dateReponse && item.modele))
								//Comparaison des lois de roulage
								return cotation.duree == item.duree && cotation.distance == item.distance ? { icone: 'done' } : { title: this.translateService.instant('demandeCotation.comparaison.subTitleLoiRoulage',{ duree: item.duree,distance: this.decimalPipe.transform(item.distance || 0),unite: this.user?.unite?.libelleCourt }) };
							else if (item.statut == 'DECLINEE')
								//Affichage d'un tiret
								return { icone: 'remove' };
							else
								//Aucun retour
								return null;
						}
					},{
						title: this.translateService.instant('demandeCotation.comparaison.sections.loyerMensuel'),
						compare: (item) => {
							//Vérification du prix HT
							if (item.typeFinancement.mode != 'ACQUISITION' && (!['DECLINEE','A_COMPLETER'].includes(item.statut) || cotation.statut == 'SOLDEE' && item.dateReponse && item.modele))
								//Prix
								return { title: `${this.currencyPipe.transform(item.loyerMensuel || 0,item.devise)} (${this.translateService.instant(`vehicule.grilleAttribution.modele.modeAffichagePrix.${item.typeAffichagePrix}.code`)})` };
							else if (item.statut == 'DECLINEE')
								//Affichage d'un tiret
								return { icone: 'remove' };
							else
								//Aucun retour
								return null;
						}
					},{
						title: this.translateService.instant('demandeCotation.comparaison.sections.prixAchat'),
						compare: (item) => {
							//Vérification du type de financement
							if (item.typeFinancement.mode == 'ACQUISITION' && (!['DECLINEE','A_COMPLETER'].includes(item.statut) || cotation.statut == 'SOLDEE' && item.dateReponse && item.modele)) {
								//Prix TTC
								return { title: this.currencyPipe.transform(item.prixAchat || 0,item.devise) };
							} else if (item.statut == 'DECLINEE') {
								//Affichage d'un tiret
								return { icone: 'remove' };
							} else
								//Aucun retour
								return null;
						}
					},{
						title: this.translateService.instant('demandeCotation.comparaison.sections.remarque'),
						compare: (item) => {
							//Vérification des types de financement
							if (item.statut != 'DECLINEE' && item.remarque) {
								return {
									title: ['RETOUR_PARTIEL','RETOUR_COMPLET'].includes(cotation.statut) && this.translateService.instant('actions.consulter'),
									showItem: (['RETOUR_PARTIEL','RETOUR_COMPLET','SOLDEE'].includes(cotation.statut) && !['DECLINEE','A_COMPLETER'].includes(item.statut) || cotation.statut == 'SOLDEE' && item.dateReponse && item.modele) && item.remarque && ((item) => {
										//Affichage de la remarque
										this.comparatorService.showMoreInfo({
											title: this.translateService.instant('demandeCotation.comparaison.sections.remarque'),
											contentTitle: `${item?.reponseDemandeCotation?.contact?.nom} ${item.reponseDemandeCotation?.contact?.prenom} ${item.reponseDemandeCotation?.contact?.fournisseur?.libelle}`,
											contentSubTitle: this.translateService.instant('demandeCotation.comparaison.sections.remarque'),
											content: item.remarque
										});
									})
								};
							} else if (item.statut == 'DECLINEE') {
								//Affichage d'un tiret
								return { icone: 'remove' };
							} else
								//Aucun retour
								return null;
						}
					}],
					listeActions: [{
						title: this.translateService.instant('actions.relancer'),
						type: 'danger',
						doAction: (item,onDataChange) => {
							//Exécution de l'action
							this.executeActionForReponse('RELANCER',item).subscribe({
								next: (result: Result) => {
									//Vérification du résultat
									if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
										//Vérification de la présence de la cotation
										if (result.data?.cotation)
											//Mise à jour de la cotation
											Object.assign(cotation,result.data.cotation);

										//Vérification de la liste de réponses
										if (result.data?.listeReponses) {
											//Mise à jour de l'élément
											Object.assign(listeReponsesDemandeCotation[listeReponsesDemandeCotation.findIndex(r => r.idReponseCotation == item.idReponseCotation)],result.data?.listeReponses.find(r => r.idReponseCotation == item.idReponseCotation));

											//Tri de la liste des réponses
											listeReponsesDemandeCotation = sortBy(listeReponsesDemandeCotation,[i => listeStatuts.indexOf(i.statut),'idReponseCotation']);

											//Suppression des actions
											options.listeActions = null;
										}

										//Notification des changements
										onDataChange.next({
											listeItems: listeReponsesDemandeCotation,
											cotation,
											demandeCotation: result.data.demandeCotation,
											options
										});
									}
								}
							})
						},
						isValid: () => true,
						isVisible: (item) => ['A_TRANSMETTRE','A_COMPLETER'].includes(item.statut)
					},{
						title: this.translateService.instant('actions.valider'),
						type: 'primary',
						doAction: (item,onDataChange) => {
							//Exécution de l'action
							this.executeActionForReponse('VALIDER',item).subscribe({
								next: (result: Result) => {
									//Vérification du résultat
									if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
										//Vérification de la présence de la cotation
										if (result.data?.cotation)
											//Mise à jour de la cotation
											Object.assign(cotation,result.data.cotation);

										//Vérification de la liste de réponses
										if (result.data?.listeReponses) {
											//Mise à jour de l'élément
											Object.assign(listeReponsesDemandeCotation[listeReponsesDemandeCotation.findIndex(r => r.idReponseCotation == item.idReponseCotation)],result.data?.listeReponses.find(r => r.idReponseCotation == item.idReponseCotation));

											//Tri de la liste des réponses
											listeReponsesDemandeCotation = sortBy(listeReponsesDemandeCotation,[i => listeStatuts.indexOf(i.statut),'idReponseCotation']);

											//Suppression des actions
											options.listeActions = null;
										}

										//Notification des changements
										onDataChange.next({
											listeItems: listeReponsesDemandeCotation,
											cotation,
											grilleAttributionModeleFinancement: result.data.grilleAttributionModeleFinancement,
											demandeVehicule: result.data.demandeVehicule,
											options
										});
									}
								}
							});
						},
						isValid: (item) => item.dateReponse,
						isVisible: (item) => item.statut == 'TRANSMISE' && ['RETOUR_PARTIEL','RETOUR_COMPLET'].includes(cotation.statut)
					}],
					isSelected: (item: any) => item.statut == 'RETENUE'
				}

				//Affichage de la pop-up de comparaison
				return this.comparatorService.showComparateur<any>(options,listeReponsesDemandeCotation);
			})
		);
	}

	/**
	 * Vérification des droits d'une cotation
	 */
	public checkRightCotation(action: 'DELETE' | 'CANCEL',cotation: any) {
		//Vérification de l'action
		switch (action) {
		case 'DELETE':
			//Vérification des statuts
			return true;
		case 'CANCEL':
			//Vérification des statuts
			return ['EMISE_FOURNISSEUR','RETOUR_PARTIEL','RETOUR_COMPLET'].indexOf(cotation.statut) > -1;
		default:
			//Action non autorisée
			return false;
		}
	}
}