<div class="pull-left lv-avatar">{{ data.facture.fournisseur.reference.substring(0,3) }}</div>
<div class="media-body">
	<div class="lv-title">
		<span>
			<a [routerLink]="[]" (click)="showFactureDetail()" *ngIf="TypeDroit.FACTURE_DETAIL | right:'consultation'">
				<span>{{ (data.nature ? data.nature.libelle : data.libelleNature ? data.libelleNature : data.codeFournisseur.libelle) + ' (' + (data.montant | currency:'1.2-2':data.devise) + ')' }}</span>
			</a>
			<span *ngIf="!(TypeDroit.FACTURE_DETAIL | right:'consultation')">{{ (data.nature ? data.nature.libelle : data.libelleNature ? data.libelleNature : data.codeFournisseur.libelle) + ' (' + (data.montant | currency:'1.2-2':data.devise) + ')' }}</span>
		</span>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.facture.date</span>&#160;:&#160;{{ data.date | date:'shortDate' }}</li>
		<li><span translate>vehicule.facture.fournisseur</span>&#160;:&#160;{{ data.facture.fournisseur.libelle }}</li>
		<li><span translate>vehicule.facture.reference</span>&#160;:&#160;{{ ('facture.type.' + data.facture.type | translate) + ' ' + data.facture.reference }}</li>
		<li *ngIf="data.lien?.objet?.idEquipement"><span translate>vehicule.facture.equipement</span>&#160;:&#160;{{ data.lien.objet.reference }}</li>
		<li *ngIf="data.user"><span translate>vehicule.facture.userAffecte</span>&#160;:&#160;{{ data.user | display:'user' }}</li>
		<li><span translate>vehicule.facture.statut</span>&#160;:&#160;{{ 'facture.detail.statut.' + data.statut | translate }}</li>
	</ul>

	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.FACTURE | right:'consultation'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="goToFacture()" translate>vehicule.facture.actions.accederFacture</a></li>
		</ul>
	</div>
</div>