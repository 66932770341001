import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable,first,map,of,shareReplay,switchMap,tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Result } from 'src/app/domain/common/http/result';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { RegleAutomatiqueCheckComponent } from './regle-automatique-check.component';

@Injectable()
export class RegleAutomatiqueService {
	/** Enumération des types */
	private listeTypesComputedField: Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }> = null;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private messagingService: MessagingService,private bsModalService: BsModalService) { }

	/**
	 * Chargement d'une règle automatique
	 */
	public loadRegleAutomatique(idRegleAutomatique: number): Observable<Result> {
		//Chargement d'une règle automatique
		return this.http.post<Result>(`${environment.baseUrl}/controller/RegleAutomatique/loadRegleAutomatique/${idRegleAutomatique}`,null);
	}

	/**
	 * Suppression d'une règle automatique
	 */
	public deleteRegleAutomatique(regleAutomatique: any): Observable<Result> {
		//Suppression d'une règle automatique
		return this.http.delete<Result>(`${environment.baseUrl}/controller/RegleAutomatique/deleteRegleAutomatique/${regleAutomatique.idRegleAutomatique}`);
	}

	/**
	 * Enregistrement d'une règle automatique
	 */
	public saveRegleAutomatique(regleAutomatique: any,idRegleAutomatiqueOrigine?: number): Observable<Result> {
		//Enregistrement d'une règle automatique
		return this.http.put<Result>(`${environment.baseUrl}/controller/RegleAutomatique/saveRegleAutomatique${idRegleAutomatiqueOrigine ? '/' + idRegleAutomatiqueOrigine : ''}`,regleAutomatique);
	}

	/**
	 * Chargement des résultats de la vérification des règles automatiques
	 */
	public getTypeRegleAutomatiqueCheckResult(idSession: string): Observable<Result> {
		//Chargement des résultats de vérification d'une règle automatique
		return this.http.get<Result>(`${environment.baseUrl}/controller/RegleAutomatique/getTypeRegleAutomatiqueCheckResult/${idSession}`);
	}

	/**
	 * Récupération de la liste des types de règles automatiques
	 */
	public retrieveListeTypesComputedField(): Observable<Array<{ code: string,libelle: string,entity: string,libelleEntity: string,valueClass: string,listeValeurs: Array<{ code: string,libelle: string }> }>> {
		//Vérification du cache
		if (this.listeTypesComputedField == null) {
			//Récupération de la liste des champs
			return this.http.get<Result>(`${environment.baseUrl}/controller/RegleAutomatique/retrieveListeTypes`).pipe(
				map((result: Result) => result.data?.listeTypes.map(type => ({
					code: type.code,
					libelle: this.translateService.instant(`regleAutomatique.${type.code}.item`),
					entity: type.entity,
					libelleEntity: this.translateService.instant(`businessData.${type.entity.split('.').pop()}`),
					i18nRootKey: type.i18nRootKey,
					listeValeurs: type.listeValeurs?.map(code => ({
						code,
						libelle: this.translateService.instant(`${type.i18nRootKey}.${code}`)
					}))
				}))),
				shareReplay(1),
				tap(listeTypes => {
					//Mise en cache
					this.listeTypesComputedField = listeTypes;
				})
			);
		} else {
			//Retour du cache
			return of(this.listeTypesComputedField);
		}
	}

	/**
	 * Vérification du type de règle automatique pour une date donnée
	 */
	public checkTypeRegleAutomatique(type: any,dateApplication: Date): MessagingObservables {
		let messaging$: MessagingObservables;

		//Vérification du type de règle automatique par WebSocket
		messaging$ = this.messagingService.init({
			entryPoint: 'controller/RegleAutomatique/checkTypeRegleAutomatique',
			params: {
				type,
				dateApplication
			},
			progressConfig: {
				icone: 'check',
				libelle: this.translateService.instant('regleAutomatique.check.progess')
			}
		});

		return messaging$;
	}

	/**
	 * Ouverture de la popup de vérification
	 */
	public showRegleAutomatiqueChecks(): void {
		//Chargement de la liste des types et affichage de la popup de vérification
		this.retrieveListeTypesComputedField().pipe(
			switchMap(listeTypesComputedField => this.bsModalService.show(RegleAutomatiqueCheckComponent,{
					initialState: {
						listeTypesComputedField
					},
				}).onHidden.pipe(first())
			)).subscribe();
	}
}