import { Component,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { CodeService } from '../code/code.service';

@Component({
	selector: 'format-interface-code-edit',
	templateUrl: './format-interface-code-edit.component.html'
})
export class FormatInterfaceCodeEditComponent extends PageContentItem {
	/** Code **/
	@Input() code: any;

	/**
	 * Constructeur
	 */
	constructor(private codeService: CodeService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Enregistrement du code
	 */
	saveCode() {
		//Enregistrement du code
		this.codeService.saveCode(this.code).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du code
					this.code = Object.assign(this.code,result.data?.code);

					//Fermeture de la fenêtre
					this.close({ code: this.code });
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout de la référence
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du code
	 */
	deleteCode() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.codeService.deleteCode(this.code))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Fermeture de la fenêtre
				this.close({ code: null });
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}
}
