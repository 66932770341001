import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable,Subject } from 'rxjs';

import { OptionComponent } from 'src/app/components/vehicule/modele/option/option.component';
import { AdvancedSearchOptions } from 'src/app/domain/advanced-search/advanced-search';
import { Page } from 'src/app/domain/common/http/list-result';
import { Result } from 'src/app/domain/common/http/result';
import { TypeComparaison } from 'src/app/domain/common/list-view';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { SuccessiveSearchOptions } from 'src/app/domain/successive-search/successive-search';
import { User } from 'src/app/domain/user/user';
import { AdvancedSearchService } from 'src/app/share/components/advanced-search/advanced-search.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { SuccessiveSearchService } from 'src/app/share/components/successive-search/successive-search.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class GrilleAttributionModeleService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private advancedSearchService: AdvancedSearchService,private successiveSearchService: SuccessiveSearchService
			,private currencyPipe: CurrencyPipe,private confirmService: ConfirmService,private messagingService: MessagingService,private bsModalService: BsModalService) {
		//Binding des méthodes
		this.getTitleForModele = this.getTitleForModele.bind(this);
		this.getListeAttributesForModele = this.getListeAttributesForModele.bind(this);
		this.showListeOptionsModele = this.showListeOptionsModele.bind(this);
	}

	/**
	 * Chargement d'un modèle de la grille d'attribution
	 */
	public loadGrilleAttributionModele(idGrilleAttribution: number,idModele: number): Observable<Result> {
		//Chargement d'un modèle de la grille d'attribution
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/loadGrilleAttributionModele/${idGrilleAttribution}/${idModele}`,null);
	}

	/**
	 * Suppression d'une liaison entre une grille d'attribution et un modèle
	 */
	public deleteGrilleAttributionModele(idGrilleAttribution: number,idModele: number): Observable<Result> {
		//Suppression d'une liaison entre une grille d'attribution et un modèle
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Vehicule/deleteModeleForGrilleAttribution/${idGrilleAttribution}/${idModele}`);
	}

	/**
	 * Vérification de la présence de cotations non annulées
	 */
	public checkCotationsNonAnnulees(idGrilleAttribution: number,idModele: number): Observable<Result> {
		//Vérification de la présence de cotations non annulées
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/checkCotationsNonAnnulees/${idGrilleAttribution}/${idModele}`,null);
	}

	/**
	 * Association des modèles de véhicules à une grille d'attribution
	 */
	public linkModelesToGrilleAttribution(idGrilleAttribution: number,listeSelections: any): Observable<Result> {
		//Association des modèles de véhicules à une grille d'attribution
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/linkModelesToGrilleAttribution/${idGrilleAttribution}`,listeSelections);
	}

	/**
	 * Association des modèles de véhicules de grilles d'attribution à une autre
	 */
	public linkModelesFromGrillesAttributionToAnother(idGrilleAttribution: number,listeSelections: any,): Observable<Result> {
		//Association des modèles de véhicules de grilles d'attribution à une autre
		return this.http.post<Result>(`${environment.baseUrl}/controller/Vehicule/linkModelesFromGrillesAttributionToAnother/${idGrilleAttribution}`,listeSelections);
	}

	/**
	 * Suppressions des éléments selectionnés
	 */
	public deleteAllForSelection(messagingOptions: MessagingOptions): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmationMasse')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed)
					//Lancement en masse
					this.doDeleteAllForSelection(messagingOptions,subject);
			}
		});

		return subject;
	}

	/**
	 * Déclenchement de la suppression en masse
	 */
	private doDeleteAllForSelection(messagingOptions: MessagingOptions,subject: Subject<any>) {
		let messaging$: MessagingObservables;

		//Démarrage de la suppression par websocket
		messaging$ = this.messagingService.init(messagingOptions)
			.onFinish({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			})
			.onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();

					//Finalisation du traitement
					subject.complete();
				}
			});
	}

	/**
	 * Affichage de la popup de recherche des modèles
	 */
	public showAdvancedSearch(user: User,subject: Subject<any> = new Subject<any>(),advancedSearchOptions: AdvancedSearchOptions = null): Observable<any> {
		let successiveSearchOptions: SuccessiveSearchOptions;
		let isInit: boolean;

		//Définition de la necéssité d'initialiser la recherche avancée
		isInit = advancedSearchOptions == null;

		//Récupération des options de la recherche avancée
		advancedSearchOptions = advancedSearchOptions || this.getAdvancedSearchOptions(user);

		//Affichage de la recherche avancée
		this.advancedSearchService.showAdvancedSearch(advancedSearchOptions,isInit).subscribe({
			next: (searchSpec: any) => {
				//Vérification d'un retour
				if (searchSpec) {
					//Récupération des options de la recherche successive
					successiveSearchOptions = this.getSuccessiveSearchOptions(user,searchSpec);

					//Affichage de la recherche successive
					this.successiveSearchService.showSuccessiveSearch(successiveSearchOptions,{ modalClass: 'modal-max' }).subscribe({
						next: result => {
							let listeSelections;

							//Vérification du résultat
							if (result) {
								//Vérification de la fermeture sans sélection
								if (result.isDismissed) {
									//Ré-ouverture de la pop-up de sélection des filtres
									this.showAdvancedSearch(user,subject,advancedSearchOptions);
								} else if (result.listeSelectionsItems?.length > 0) {
									//Définition de la sélection d'éléments
									listeSelections = {
										_type: 'com.notilus.model.search.AggregatedItemSelection',
										listeSelectionsByAggregator: result.listeSelectionsItems,
										searchSpec: searchSpec,
										nbSelectedItems: result.nbSelectedItems
									};

									//Retour de la sélection
									subject.next(listeSelections);

									//Fin de traitement
									subject.complete();
								}
							} else {
								//Emission d'une erreur
								subject.error(null);

								//Fin de traitement
								subject.complete();
							}
						},
						error: (erreur) => {
							//Transmission de l'erreur
							subject.error(erreur);

							//Fin de traitement
							subject.complete();
						}
					});
				} else {
					//Fin de traitement
					subject.complete();
				}
			},
			error: (erreur) => {
				//Transmission de l'erreur
				subject.error(erreur);

				//Fin de traitement
				subject.complete();
			}
		});

		//Retour de l'observable
		return subject.asObservable();
	}

	/**
	 * Récupération des options de recherche avancée
	 */
	private getAdvancedSearchOptions(user: User): AdvancedSearchOptions {
		//Retour des options de la recherche avancée
		return {
			title: this.translateService.instant('searchEngine.elements.grilleAttribution.title'),
			subTitle: this.translateService.instant('searchEngine.elements.grilleAttribution.subTitle'),
			results: this.translateService.instant('searchEngine.elements.grilleAttribution.result'),
			uri: `/controller/VehiculeReferentiel/listeModelesByAgregation`,
			listeFiltres: [{
				searchKey: 'marque.libelle',
				type: 'autocomplete',
				libelleType: 'marque',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.marque.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.marque.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.marque.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/MARQUE',
					listeFilters: () => [{
						clef: 'marque.libelle',
						isDefault: true
					}],
					displayItem: (item) => item?.libelleGroupe || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'idGroupe',
					type: 'advanced-search-marque'
				}
			},{
				searchKey: 'modeleNiveau2,marque.libelle',
				type: 'autocomplete',
				libelleType: 'modele',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.modele.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.modele.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleParent + ' ' + item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleParent
						},{
							key: 'modeleNiveau2',
							keyIndex: 'modeleNiveau2.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.modele.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/NIVEAU2',
					listeFilters: () => [{
						clef: 'modeleNiveau2',
						isDefault: true
					},{
						clef: 'marque.libelle',
						isDefault: true
					}],
					displayItem: (item) => item && `${item.libelleParent} ${item.libelleGroupe}` || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'libelleGroupe',
					type: 'advanced-search-modele'
				}
			},{
				searchKey: 'finition,marque.libelle',
				type: 'autocomplete',
				libelleType: 'finition',
				labelAutocomplete: this.translateService.instant('searchEngine.autocomplete.finition.labelAutocomplete'),
				labelListe: this.translateService.instant('searchEngine.autocomplete.finition.labelListe'),
				mapSelectedItem: (item) => {
					//Conversion de l'élément
					return {
						libelle: item.libelleParent + ' ' + item.libelleGroupe,
						listeCriteres: [{
							key: 'marque.libelle',
							keyIndex: 'marque.libelle.raw',
							value: item.libelleParent
						},{
							key: 'finition',
							keyIndex: 'finition.raw',
							value: item.libelleGroupe
						}]
					};
				},
				options: {
					title: 'searchEngine.autocomplete.finition.labelListe',
					url: '/controller/VehiculeReferentiel/filtreModelesBy/FINITION',
					listeFilters: () => [{
						clef: 'finition',
						isDefault: true,
						typeComparaison: TypeComparaison.LIKE
					},{
						clef: 'marque.libelle',
						isDefault: true,
						typeComparaison: TypeComparaison.LIKE
					}],
					displayItem: (item) => item && `${item.libelleParent} ${item.libelleGroupe}` || null,
					displayAvatar: (item) => item?.libelleGroupe?.substring(0,3).toUpperCase() || null,
					getKeyFieldName: () => 'libelleGroupe',
					type: 'advanced-search-finition'
				}
			},{
				listeKeys: [{
					searchKey: 'information.commercialisationPrix.prixHT',
					aggregateKey: 'information.commercialisationPrix.prixHT',
					subType: 'HT'
				},{
					searchKey: 'information.commercialisationPrix.prixTTC',
					aggregateKey: 'information.commercialisationPrix.prixTTC',
					subType: 'TTC'
				}],
				type: 'numeric',
				maxPercent: 95,
				libelleType: 'prix',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'information.carburant.libelle',
				aggregateKey: 'information.carburant.libelle.raw',
				type: 'label',
				libelleType: 'carburant'
			},{
				searchKey: 'information.typeBoite.libelle',
				aggregateKey: 'information.typeBoite.libelle.raw',
				type: 'label',
				libelleType: 'typeBoite'
			},{
				searchKey: 'information.typeTransmission.libelle',
				aggregateKey: 'information.typeTransmission.libelle.raw',
				type: 'label',
				libelleType: 'typeTransmission',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				listeKeys: [{
					searchKey: 'information.commercialisationPrix.avantageNature.montantMensuelAvecCarburant',
					aggregateKey: 'information.commercialisationPrix.avantageNature.montantMensuelAvecCarburant',
					subType: 'AENMensuelAvecCarburant'
				},{
					searchKey: 'information.commercialisationPrix.avantageNature.montantMensuelSansCarburant',
					aggregateKey: 'information.commercialisationPrix.avantageNature.montantMensuelSansCarburant',
					subType: 'AENMensuelSansCarburant'
				}],
				type: 'numeric',
				maxPercent: 95,
				libelleType: 'AENMensuel',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'information.motorisation.puissanceFiscale',
				aggregateKey: 'information.motorisation.puissanceFiscale',
				type: 'numeric',
				libelleType: 'puissanceFiscale',
				isShowFilter: () => user.type != 'VIRTUAL'
			},{
				searchKey: 'nbPortes',
				aggregateKey: 'nbPortes',
				type: 'numeric',
				libelleType: 'nbPortes'
			}]
		};
	}

	/**
	 * Récupération des options de recherche successive
	 */
	private getSuccessiveSearchOptions(user: User,searchSpec: any): SuccessiveSearchOptions {
		//Retour des options de la recherche successive
		return {
			title: this.translateService.instant('searchEngine.elements.grilleAttribution.title'),
			typeSearchLevel: 'MARQUE',
			search: searchSpec,
			isShowFilter: true,
			groupeKey: 'finition',
			idItemKey: 'idModele',
			listeSearchLevels: [{
				type: 'MARQUE',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.marque'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/MARQUE`,
				getListeFilters: () => [{
					clef: 'marque.libelle',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'marque.idMarque',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'LONG'
					}
				},
				getListeFiltersExtended(options) {
					//Retour des filtres
					return options?.search?.listeFilter || [];
				}
			},{
				type: 'MODELE_NIVEAU_2',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.modele'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/NIVEAU2`,
				getListeFilters: () => [{
					clef: 'modeleNiveau2',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getListeFiltersExtended(options,listeCumulatedFilters) {
					//Retour des filtres
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,1)).concat(options?.search?.listeFilter);

					//Retour des filtres
					return listeFilters;
				},
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'modeleNiveau2',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'STRING'
					}
				}
			},{
				type: 'FINITION',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.finition'),
				emptyLibelle: this.translateService.instant('searchEngine.libelleMissing.nonCommuniquee'),
				uri: () => `/controller/VehiculeReferentiel/filtreModelesBy/FINITION`,
				getListeFilters: () => [{
					clef: 'finition',
					isDefault: true
				}],
				isListView: false,
				getTitle: (item) => item.libelleGroupe,
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					//Retour des filtres
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,2)).concat(options?.search?.listeFilter);

					//Retour des filtres
					return listeFilters;
				},
				getAssociatedFilter: (value) => {
					//Retour du filtre pour la valeur
					return {
						clef: 'finition',
						valeur: value,
						typeComparaison: 'EQUAL',
						type: 'STRING'
					}
				},
				listeActions: [{
					isSecondary: true,
					isVisible: (options: SuccessiveSearchOptions) => !options.isSingleResult,
					isValid: (_,listeSelectionsItems: Array<any>) => listeSelectionsItems.length == 1,
					libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.afficherOptionsSerie'),
					onPress: this.showListeOptionsModele
				}]
			},{
				type: 'VEHICULE',
				libelle: this.translateService.instant('searchEngine.elements.grilleAttribution.motorisation'),
				uri: () => `/controller/VehiculeReferentiel/filtreModeles/`,
				getListeFilters: () => [{
					clef: 'marque.libelle',
					isDefault: true
				},{
					clef: 'libelle',
					isDefault: true
				},{
					clef: 'reference',
					isDefault: true
				}],
				defaultOrder: 'information.commercialisationPrix.prixTTC,libelle.raw',
				isListView: true,
				getTitle: this.getTitleForModele,
				getAvatar: (item) => item.reference.substring(0,3).toUpperCase(),
				getListeItemAttributes: this.getListeAttributesForModele,
				getListeFiltersExtended: (options,listeCumulatedFilters) => {
					let listeFilters: Array<any> = [];

					//Récupération des filtres applicables à la liste des éléments
					listeFilters = (Object.assign(listeFilters,cloneDeep(listeCumulatedFilters)).slice(0,3)).concat(options?.search?.listeFilter);

					//Ajout du filtre sur le type de source
					listeFilters.push({
						clef: 'typeSource.keyword',
						valeur: 'EXTERNE_CATALOGUE',
						typeComparaison: 'EQUAL',
						type: 'STRING'
					});

					//Ajout du filtre sur le statut actif
					listeFilters.push({
						clef: 'actif',
						valeur: true,
						typeComparaison: 'EQUAL',
						type: 'BOOLEAN'
					});

					//Ajout du filtre sur la commercialisation en cours
					listeFilters.push({
						clef: 'information.commercialisationPrix.commercialisationEnCours',
						valeur: true,
						typeComparaison: 'EQUAL',
						type: 'BOOLEAN'
					});

					//Retour des filtres sur la marque, le modèle et la finition adaptés pour ElasticSearch
					return listeFilters.map(filter => {
						//Vérification du type de filtre
						if (filter.type == 'STRING' && filter.clef.slice(-8) != '.keyword') {
							//Ajout du mot clé 'raw' à la clef
							filter.clef += '.raw';
						}
						//Retour du filtre mis en forme
						return filter;
					});
				}
			}]
		};
	}

	/**
	 * Récupération du titre d'un élément de la liste des modèles
	 */
	public getTitleForModele(item: any): string {
		//Retour du titre
		return `${item.libelle} - ${
			item.information?.genre?.modeAffichagePrix == 'TTC' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeTTC',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixTTC,'.2-2',item.information.commercialisationPrix.devise) }) :
				(item.information?.genre?.modeAffichagePrix == 'HT' ? this.translateService.instant('vehicule.grilleAttribution.modele.configuration.aPartirDeHT',{ montant: this.currencyPipe.transform(item.information.commercialisationPrix.prixHT,'.2-2',item.information.commercialisationPrix.devise) }) : '')
		}`;
	}

	/**
	 * Récupération des attributs d'un modèle
	 */
	public getListeAttributesForModele(item: any): Array<{ key: string,value: string }> {
		//Retour de la liste des attributs
		return [item.information?.carburant && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.carburant'),
			value: item.information?.carburant?.libelle
		},item.information?.typeBoite && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.boiteVitesse'),
			value: item.information?.typeBoite?.libelle
		},item.information?.motorisation && {
			key: this.translateService.instant('vehicule.grilleAttribution.modele.liste.puissanceFiscale'),
			value: item.information?.motorisation?.puissanceFiscale
		}].filter(i => !!i);
	}

	/**
	 * Affichage de la liste des options d'un modèle
	 */
	public showListeOptionsModele(options: SuccessiveSearchOptions,listeCumulatedFilters: Array<any>) {
		let searchInfo: any;
		let searchSpec: any;

		//Récupération des informations nécessaires pour recherche des modèles
		searchInfo = options.listeSearchLevels?.find(s => s.type == 'VEHICULE');

		//Création de la recherche associée à la motorisation sélectionnée
		searchSpec = {
			defaultOrder: searchInfo.defaultOrder,
			listeFilter: searchInfo.getListeFiltersExtended(options,listeCumulatedFilters),
			nbObjetsParPage: 1,
			numPage: 0
		}

		//Ouverture de la pop-up
		this.bsModalService.show(OptionComponent,{
			initialState: {
				searchSpec
			},
			backdrop: 'static',
			class: 'modal-max'
		});
	}

	/**
	 * Filtre de la liste des financements d'un modèle de grille d'attribution
	 */
	public filtreFinancementsForGrilleAttributionModele(idGrilleAttribution: number,idModele: number,searchSpec?: any): Observable<Page<any>> {
		//Liste des financements pour un modèle de grille d'attribution
		return this.http.post<Page<any>>(`${environment.baseUrl}/controller/Vehicule/filtreFinancementsForGrilleAttributionModele/${idGrilleAttribution}/${idModele}`,searchSpec || {});
	}
}