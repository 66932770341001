import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,uniqueId } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	selector: 'regle-taxe-rule-list',
	templateUrl: './regle-taxe-rule-list.component.html'
})
export class RegleTaxeRuleListComponent extends PageContentItem implements OnInit {
	/** Règle de taxe **/
	@Input() regleTaxe: { listeDetails: Array<any>,[key: string]: any };

	/** Indicateur du mode édition **/
	@Input() isEdition: any = false;

	/** Enregistrement de l'objet **/
	@Input() saveRegleTaxe: (regleTaxe: any,close: Function) => void;

	/** Liste des détails **/
	public listeDetails: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Duplication de la liste des détails
		this.listeDetails = cloneDeep(this.regleTaxe.listeDetails);

		//Vérification du mode édition et de la liste
		if (this.isEdition && !this.listeDetails.length)
			//Ajout d'une ligne
			this.addRule();
	}

	/**
	 * Enregistrement de la liste de détails
	 */
	saveListeDetails() {
		let regleTaxe: any;

		//Duplication de l'objet avant enregistrement
		regleTaxe = cloneDeep(this.regleTaxe);

		//Définition de la liste des détails
		regleTaxe.listeDetails = this.listeDetails;

		//Enregistrement
		this.saveRegleTaxe(regleTaxe,this.close);
	}

	/**
	 * Suppression de la règle
	 */
	deleteRule(index: number) {
		//Suppression de la règle
		this.listeDetails.splice(index,1);
	}

	/**
	 * Ajout d'une règle
	 */
	addRule() {
		//Création d'une nouvelle règle
		this.listeDetails.unshift({ key: uniqueId() });
	}
}