import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ContraventionService } from 'src/app/components/vehicule/infraction/contravention/contravention.service';
import { ContraventionListItemComponent } from './contravention-list-item.component';
import { ContraventionListComponent } from './contravention-list.component';
import { ContraventionComponent } from './contravention.component';
import { ContraventionEditComponent } from './contravention-edit.component';
import { ContraventionDesignationComponent } from './contravention-designation.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ContraventionListComponent,ContraventionListItemComponent,ContraventionComponent,ContraventionEditComponent,ContraventionDesignationComponent],
	providers: [ContraventionService]
})
export class ContraventionModule { }