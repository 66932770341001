import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListView } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { HistoryListItemComponent } from './history-list-item.component';

@Component({
	templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
	/** Nom de la class **/
	className: string;

	/** Identifiant de l'objet **/
	idObject: number;

	/** Identifiant secondaire de l'objet **/
	idSecondaryObject?: number;

	/** Identifiant du tenant **/
	idTenant?: number;

	/** Données **/
	liste: ListView<any,HistoryListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<HistoryComponent>) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,HistoryListItemComponent>({
			uri: `${environment.baseUrl}/controller/Tracking/filtreHistory?className=${this.className}&idObjet=${this.idObject}&idSecondaryObjet=${this.idSecondaryObject || ''}&idTenant=${this.idTenant || ''}`,
			component: HistoryListItemComponent,
			isTile: true,
			isLocal: true,
			defaultOrder: '-date',
			listeFilters: []
		});
	}
}