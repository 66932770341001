import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable,of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Result } from 'src/app/domain/common/http/result';
import { TypeReferentiel } from 'src/app/domain/referentiel/referentiel-entite-list';
import { environment } from 'src/environments/environment';
import { FournisseurNatureListComponent } from './fournisseur-nature-list.component';

@Injectable()
export class FournisseurService {
	/** Liste des types de contacts **/
	private readonly listeTypesContact: Array<string> = ['NON_DEFINI','CONTACT_1','CONTACT_2'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private bsModalService: BsModalService,private translateService: TranslateService) { }

	/**
	 * Récupération de la liste des types de contacts
	 */
	public getListeTypesContact(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types de contacts
		return this.listeTypesContact.map(code => ({
			code,
			libelle: this.translateService.instant(`fournisseur.contact.type.${code}`)
		}));
	}

	/**
	 * Enregistrement d'un fournisseur
	 */
	public saveFournisseur(typeReferentiel: TypeReferentiel,fournisseur: any): Observable<Result> {
		//Enregistrement d'un fournisseur
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveFournisseur/${typeReferentiel}`,fournisseur);
	}

	/**
	 * Suppression du fournisseur
	 */
	public deleteFournisseur(fournisseur: any,typeReferentiel: TypeReferentiel): Observable<Result> {
		//Suppression du fournisseur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteFournisseur/${typeReferentiel}/${fournisseur.idFournisseur}`);
	}

	/**
	 * Chargement du fournisseur
	 */
	public loadFournisseur(idFournisseur: number): Observable<Result> {
		//Chargement du fournisseur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Fournisseur/loadFournisseur/${idFournisseur}`,null);
	}

	/**
	 * Enregistrement d'un alias de fournisseur
	 */
	public saveAlias(alias: any): Observable<Result> {
		//Enregistrement de l'alias
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveAlias`,alias);
	}

	/**
	 * Suppression d'un alias de fournisseur
	 */
	public deleteAlias(alias: any): Observable<Result> {
		//Suppression de l'alias
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteAlias/${alias.idAlias}`);
	}

	/**
	 * Enregistrement d'un contact
	 */
	public saveContact(contact: any): Observable<Result> {
		//Enregistrement du contact
		return this.http.put<Result>(`${environment.baseUrl}/controller/Fournisseur/saveContact`,contact);
	}

	/**
	 * Suppression d'un contact
	 */
	public deleteContact(contact: any): Observable<Result> {
		//Suppression du contact
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Fournisseur/deleteContact/${contact.idContact}`);
	}

	/**
	 * Récupération du nombre de natures correspondantes aux métiers du fournisseur
	 */
	public getNbNatures(fournisseur: any): Observable<number> {
		//Vérification de la présence de métiers
		if (fournisseur?.listeMetiers?.length > 0)
			//Récupération du nombre de natures
			return this.http.post<Result>(`${environment.baseUrl}/controller/Fournisseur/countNatureByMetier`,fournisseur.listeMetiers).pipe(map(result => result?.data?.nbNatures || 0));
		else
			//Aucune nature par défaut
			return of(0);
	}

	/**
	 * Affichage de la liste des natures correspondant aux métiers du fournisseur
	 */
	public showListeNatures(fournisseur: any) {
		//Affichage de la popup
		this.bsModalService.show(FournisseurNatureListComponent,{
			initialState: {
				listeMetiers: cloneDeep(fournisseur.listeMetiers)
			},
			class: 'modal-lg'
		});
	}
}