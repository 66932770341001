'use strict';

var inherits = require('inherits'),
  HtmlfileReceiver = require('./receiver/htmlfile'),
  XHRLocalObject = require('./sender/xhr-local'),
  AjaxBasedTransport = require('./lib/ajax-based');
function HtmlFileTransport(transUrl) {
  if (!HtmlfileReceiver.enabled) {
    throw new Error('Transport created when disabled');
  }
  AjaxBasedTransport.call(this, transUrl, '/htmlfile', HtmlfileReceiver, XHRLocalObject);
}
inherits(HtmlFileTransport, AjaxBasedTransport);
HtmlFileTransport.enabled = function (info) {
  return HtmlfileReceiver.enabled && info.sameOrigin;
};
HtmlFileTransport.transportName = 'htmlfile';
HtmlFileTransport.roundTrips = 2;
module.exports = HtmlFileTransport;