import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,switchMap } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { AmenagementService } from '../materiel/amenagement/amenagement.service';

@Component({
	templateUrl: './vehicule-amenagement-list-item.component.html'
})
export class VehiculeAmenagementListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private amenagementService: AmenagementService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,public rightService: RightService) {
		//Héritage
		super();

		//Binding des méthodes
		this.deleteAmenagement = this.deleteAmenagement.bind(this);
	}

	/**
	 * Suppression de l'objet
	 */
	public deleteAmenagement(amenagement: any = this.data,close?: Function) {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.amenagementService.deleteAmenagement(amenagement))
		).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.suppression.success'));

					//Suppression de l'objet
					this.onObjectChange(null);

					//Fermeture du formulaire d'édition (si suppression depuis le formulaire)
					close?.(null);
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.suppression.error'));
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	public showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Amenagement',this.data.idAmenagement);
	}
}