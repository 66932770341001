<form #comptePosteChargeTransfertForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>comptabilite.compte.elements.posteCharge.transfert.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="form-group">
				<label translate>comptabilite.compte.planComptable</label>
				<div>
					<autocomplete name="planComptable" type="planComptable" #planComptable="ngModel" [(ngModel)]="savedCompte.planComptable" [disabled]="true"></autocomplete>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': compte.invalid }">
				<label translate>comptabilite.compte.item</label>
				<div>
					<autocomplete name="compte" type="compte" #compte="ngModel" [(ngModel)]="lienPosteCharge.compte" [filter]="{ idPlanComptable: savedCompte?.planComptable?.idPlanComptable,listeTypes: [savedCompte?.type],idCompteExcluded: savedCompte?.idCompte }" required></autocomplete>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveComptePosteCharge()" [disabled]="comptePosteChargeTransfertForm.invalid" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</div>
</form>