import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListView } from 'src/app/domain/common/list-view';
import { PoolVehiculeAddItemComponent } from './pool-vehicule-add-item.component';
import { PoolService } from './pool.service';

@Component({
	selector: 'pool-vehicule-add',
	templateUrl: './pool-vehicule-add.component.html',
})
export class PoolVehiculeAddComponent implements OnInit {
	/** Liste **/
	liste: ListView<any,PoolVehiculeAddItemComponent>;

	/** Pool courant **/
	public pool: any;

	/** Liste des identifiants de véhicules **/
	public listeIdsVehicule: Array<number> = [];

	/** Nombre d'objets traités **/
	public nbProcessed: number;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public bsModalRef: BsModalRef<PoolVehiculeAddComponent>,private poolService: PoolService,private toastrService: ToastrService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,PoolVehiculeAddItemComponent>({
			uri: `/controller/VehiculePool/filtreVehicules/${this.pool.idPool}/true`,
			title: this.translateService.instant('vehicule.pool.elements.vehicule.liste.title'),
			component: PoolVehiculeAddItemComponent,
			isLocal: true,
			listeFilters: [{
				clef: 'reference',
				isDefault: true
			}],
			extraOptions: {
				getListeIdsVehicule: () => this.listeIdsVehicule,
				addOrRemoveIdVehiculeToListe: (idVehicule: number) => {
					//Vérification de l'identifiant
					if (idVehicule) {
						//Vérification de la présence de l'identifiant
						if (this.listeIdsVehicule?.indexOf(idVehicule) == -1)
							//Ajout de l'identifiant
							this.listeIdsVehicule.push(idVehicule);
						else
							//Suppression de l'identifiant
							this.listeIdsVehicule.splice(this.listeIdsVehicule.indexOf(idVehicule),1);
					}
				}
			}
		});
	}

	/**
	 * Association des véhicules sélectionnés au pool
	 */
	public addListeVehicules() {
		//Ajout de la liste des véhicules au pool
		this.poolService.addListeVehiculesToPool(this.pool.idPool,this.listeIdsVehicule).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du nombre d'objets traités
					this.nbProcessed = result.data.nbProcessed;

					//Fermeture de la popup
					this.bsModalRef.hide();
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}
}