import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';

@Component({
	templateUrl: './tenant-contrat-edit.component.html'
})
export class TenantContratEditComponent extends PageContentItem {
	/** Contrat CRM **/
	@Input() contratCRM: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}
}