import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { mapEntites } from './sgi-lot.entites';
import { SgiLotService } from './sgi-lot.service';

@Component({
	selector: 'sgi-lot-list-item',
	templateUrl: './sgi-lot-list-item.component.html'
})
export class SgiLotListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Map des icones des types de lot **/
	mapTypesLot: { [typeLot: string]: { icone: string,iconeType?: string } };

	/**
	 * Constructeur
	 */
	constructor(public sgiLotService: SgiLotService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des icones des types de lot
		this.mapTypesLot = this.sgiLotService.getMapTypesLot();
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping des entités
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idLot,object?.idLot && !this.data?.idLot);
	}

	/**
	 * Clôture d'un lot
	 */
	closeLot() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.cloture.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Clôture du lot
					this.sgiLotService.closeLot(this.data.idLot).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.cloture.success'));

								//Mise à jour du lot dans la liste
								this.onObjectChange(result.data.lot);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.cloture.error'));
							}
						}
					});
				}
			}
		});
	}
}