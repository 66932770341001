import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { User } from 'src/app/domain/user/user';
import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ArticleEditComponent } from './article-edit.component';
import { ArticleFeedbackListComponent } from './article-feedback-list.component';
import { ArticleRubriqueListEditComponent } from './article-rubrique-list-edit.component';
import { ArticleRubriqueListComponent } from './article-rubrique-list.component';
import { ArticleService } from './article.service';

@Component({
	selector: 'article',
	templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Article courant **/
	public article: any;

	/** Type d'attachment **/
	readonly typeAttachment: TypeAttachment = TypeAttachment.ARTICLE;

	/** Utilisateur connecté **/
	user: User;

	/** Résumé **/
	public resume: { nbRubriques: number,nbFeedbacks: number } = {
		nbRubriques: 0,
		nbFeedbacks: 0
	};

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private articleService: ArticleService,private sharedArticleService: SharedArticleService,private pageContentService: PageContentService,private activatedRoute: ActivatedRoute
			,private rightService: RightService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService
			,private attachmentService: AttachmentService,private layoutService: LayoutService,private store: Store<AppState>) {

	}

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.aide.Article';

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getIdObject: () => number = () => this.article?.idArticle || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.article;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'article.titre',
				key: 'titre'
			},{
				libelle: 'article.reference',
				key: 'reference'
			},{
				libelle: 'article.perimetre.item',
				key: (data) => this.translateService.instant(`article.perimetre.${data.typePerimetre}`)
			},{
				libelle: 'article.actif.item',
				key: 'actif',
				type: 'BOOLEAN'
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editArticle(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editArticle(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.dupliquer',
				doAction: () => this.duplicateArticle(),
				isVisible: () => this.rightService.hasRight(null,'creation')
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteArticle(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			},{
				libelle: 'actions.previsualiser',
				doAction: () => this.previewArticle(),
				isVisible: () => this.rightService.hasRight(null,'consultation')
			}],
			listeAlertes: [{
				icon: 'bookmark',
				title: this.translateService.instant('article.alerte.aucuneRubrique.title'),
				message: this.translateService.instant('article.alerte.aucuneRubrique.message'),
				doAction: () => this.editListeRubriques(true),
				isVisible: () => this.resume.nbRubriques == 0 && !this.pageContentService.isOpened()
			}],
			listeElements: [{
				type: 'RUBRIQUE',
				libelle: 'article.elements.rubrique',
				component: ArticleRubriqueListComponent,
				retrieveComponentData: () => ({
					article: cloneDeep(this.article),
					resume: this.resume,
					editListeRubriques: this.editListeRubriques.bind(this)
				}),
				doAction: () => this.editListeRubriques(true),
				libelleAction: this.translateService.instant('article.actions.ajouterRubrique'),
				count: () => this.resume?.nbRubriques || 0,
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			},{
				type: 'FEEDBACK',
				libelle: 'article.elements.feedback',
				component: ArticleFeedbackListComponent,
				retrieveComponentData: () => ({
					article: this.article,
					resume: this.resume
				}),
				count: () => this.resume?.nbFeedbacks || 0,
				isVisible: () => this.rightService.isRoot()
			}],
			isFormCustomization: true
		};

		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe(user => {
			//Définition de l'utilisateur connecté
			this.user = user;

			//Chargement des données
			this.loadData(this.activatedRoute.snapshot.params.idArticle);
		});
	}

	/**
	 * Edition de l'article
	 */
	editArticle() {
		//Ouverture du composant d'édition
		this.pageContentService.open(ArticleEditComponent,{
			data: {
				article: this.article,
				resume: this.resume,
				deleteArticle: this.deleteArticle.bind(this),
				saveArticle: this.saveArticle.bind(this)
			}
		}).subscribe((data: { article: any }) => {
			//Vérification de la présence de données
			if (data?.article) {
				//Mise à jour de l'article
				this.article = data.article;

				//Mise à jour de l'identifiant contenu dans l'URL
				this.layoutService.replaceUrlWith(this.article);
			}
		});
	}

	/**
	 * Suppression de l'article
	 */
	private deleteArticle() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.articleService.deleteArticle(this.article.idArticle))
		).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Suppression de l'objet
				delete this.article;

				//Fermeture de la page
				this.layoutService.goBack();
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
			}
		});
	}

	/**
	 * Prévisualisation d'un article
	 */
	previewArticle() {
		//Prévisualisation de l'article
		this.articleService.previewArticle(this.article.idArticle);
	}

	/**
	 * Chargement des données
	 */
	private loadData(idArticle: number) {
		//Vérification de l'identifiant
		if (idArticle > 0) {
			//Chargement de l'article
			this.sharedArticleService.loadArticle(idArticle || 0).subscribe(result => {
				//Vérification du chargement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Définition de l'article
					this.article = result.data?.article || null;

					//Initialisation des compteurs
					this.resume = {
						nbRubriques: this.article?.listeContents?.length || 0,
						nbFeedbacks: result.data?.nbFeedbacks || 0
					};

					//Initialisation du compteur de pièces-jointes
					this.attachmentService.initAttachments(this);
				}
			});
		} else {
			//Création d'un article vierge
			this.article = {
				actif: false,
				typeArticle: 'STANDARD',
				forceConsentement: false,
				isDiffusionNouveautes: false
			};

			//Initialisation des compteurs
			this.resume = {
				nbRubriques: 0,
				nbFeedbacks: 0
			};

			//Déclenchement au prochain cycle
			setTimeout(() => {
				//Edition
				this.editArticle();
			});
		}
	}

	/**
	 * Modification des rubriques
	 */
	editListeRubriques(isAddNewRubrique: boolean = false) {
		//Ouverture du composant d'édition
		this.pageContentService.open(ArticleRubriqueListEditComponent,{
			data: {
				article: cloneDeep(this.article),
				isAddNewRubrique,
				saveArticle: this.saveArticle.bind(this)
			}
		},'sub').subscribe((data: { article: any }) => {
			//Vérification de l'article
			if (data?.article)
				//Mise à jour
				this.article = data.article;

			//Rafraichissement de l'onglet
			this.complexPage.setSelectedElementByType('RUBRIQUE',true);

			//Mise à jour du compteur
			this.resume.nbRubriques = this.article.listeContents?.length;
		});
	}

	/**
	 * Enrgistrement de l'article
	 */
	saveArticle(article: any,close: (data?: any) => void) {
		//Parcours du contenu
		article.listeContents?.forEach((content,idxContent) => {
			//Définition de la position
			content.position = idxContent + 1;
		});

		//Vérification de l'absence de rubriques
		if (article.listeContents?.length == 0)
			//Désactivation de l'article
			article.actif = false;

		//Enregistrement de l'article
		this.articleService.saveArticle(article).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Mise à jour de l'article
				article = result.data?.article;

				//Fermeture du formulaire d'édition
				close({ article });
			} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
				let listeDoublons: Array<string> = [];

				//Vérification du titre
				if (result.data.doublon & Math.pow(2,0))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.titre'));

				//Vérification de la référence
				if (result.data.doublon & Math.pow(2,1))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

				//Vérification des CGU
				if (result.data.doublon & Math.pow(2,2))
					//Ajout du libellé
					listeDoublons.push(this.translateService.instant('actions.doublon.CGUNouveautes'));

				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
					field: listeDoublons.join(', ')
				}));
			} else {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			}
		});
	}

	/**
	 * Duplication de l'article
	 */
	private duplicateArticle() {
		//Duplication de l'article
		this.articleService.duplicateArticle(this.article).subscribe(result => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

				//Redirection vers l'article créé
				this.layoutService.replaceUrlWith({
					idArticle: result.data.article.idArticle
				},true);
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
		});
	}
}