<div class="card focus">
	<div class="card-body card-padding">
		<div class="row">
			<div class="col-xs-12">
				<h4 translate>vehicule.modele.performance.title</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.performance.vitesseMax</label>
					<div class="half" *ngIf="performance?.vitesseMax">
						<div class="input-group">
							<input type="text" name="vitesseMax" #vitesseMax="ngModel" class="form-control" [(ngModel)]="performance.vitesseMax" nNumber/>
							<div class="input-group-addon no-border">km/h</div>
						</div>
					</div>
					<div *ngIf="!performance?.vitesseMax">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.acceleration</label>
					<div class="half" *ngIf="performance?.acceleration">
						<div class="input-group">
							<input type="text" name="acceleration" #acceleration="ngModel" class="form-control" [(ngModel)]="performance.acceleration" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">s (0-100 km/h)</div>
						</div>
					</div>
					<div *ngIf="!performance?.acceleration">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.kilometreArrete</label>
					<div class="half" *ngIf="performance?.kilometreArrete">
						<div class="input-group">
							<input type="text" name="kilometreArrete" #kilometreArrete="ngModel" class="form-control" [(ngModel)]="performance.kilometreArrete" nNumber="2"/>
							<div class="input-group-addon no-border">s</div>
						</div>
					</div>
					<div *ngIf="!performance?.kilometreArrete">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.coefficientTraineeAerodynamique</label>
					<div class="half" *ngIf="performance?.coefficientTraineeAerodynamique">
						<div class="input-group">
							<input type="text" name="coefficientTraineeAerodynamique" #coefficientTraineeAerodynamique="ngModel" class="form-control" [(ngModel)]="performance.coefficientTraineeAerodynamique" nNumber="2"/>
							<div class="input-group-addon no-border">Cx</div>
						</div>
					</div>
					<div *ngIf="!performance?.coefficientTraineeAerodynamique">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.emissionCO2Min</label>
					<div class="half" *ngIf="performance?.emissionCO2Min !== null">
						<div class="input-group">
							<input type="text" name="emissionCO2Min" #emissionCO2Min="ngModel" class="form-control" [(ngModel)]="performance.emissionCO2Min" nNumber/>
							<div class="input-group-addon no-border">g/km</div>
						</div>
					</div>
					<div *ngIf="performance.emissionCO2Min === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.emissionCO2Max</label>
					<div class="half" *ngIf="performance?.emissionCO2Max !== null">
						<div class="input-group">
							<input type="text" name="emissionCO2Max" #emissionCO2Max="ngModel" class="form-control" [(ngModel)]="performance.emissionCO2Max" nNumber/>
							<div class="input-group-addon no-border">g/km</div>
						</div>
					</div>
					<div *ngIf="performance.emissionCO2Max === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.emissionCO2MinGaz</label>
					<div class="half" *ngIf="performance?.emissionCO2MinGaz !== null">
						<div class="input-group">
							<input type="text" name="emissionCO2MinGaz" #emissionCO2MinGaz="ngModel" class="form-control" [(ngModel)]="performance.emissionCO2MinGaz" nNumber="2"/>
							<div class="input-group-addon no-border">g/km</div>
						</div>
					</div>
					<div *ngIf="performance.emissionCO2MinGaz === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.emissionCO2MaxGaz</label>
					<div class="half" *ngIf="performance?.emissionCO2MaxGaz !== null">
						<div class="input-group">
							<input type="text" name="emissionCO2MaxGaz" #emissionCO2MaxGaz="ngModel" class="form-control" [(ngModel)]="performance.emissionCO2MaxGaz" nNumber="2"/>
							<div class="input-group-addon no-border">g/km</div>
						</div>
					</div>
					<div *ngIf="performance.emissionCO2MaxGaz === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationCarburantMin</label>
					<div class="half" *ngIf="performance?.consommationCarburantMin !== null">
						<div class="input-group">
							<input type="text" name="consommationCarburantMin" #consommationCarburantMin="ngModel" class="form-control" [(ngModel)]="performance.consommationCarburantMin" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">L/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationCarburantMin === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationCarburantMax</label>
					<div class="half" *ngIf="performance?.consommationCarburantMax !== null">
						<div class="input-group">
							<input type="text" name="consommationCarburantMax" #consommationCarburantMax="ngModel" class="form-control" [(ngModel)]="performance.consommationCarburantMax" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">L/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationCarburantMax === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationGazMin</label>
					<div class="half" *ngIf="performance?.consommationGazMin !== null">
						<div class="input-group">
							<input type="text" name="consommationGazMin" #consommationGazMin="ngModel" class="form-control" [(ngModel)]="performance.consommationGazMin" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">L/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationGazMin === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationGazMax</label>
					<div class="half" *ngIf="performance?.consommationGazMax !== null">
						<div class="input-group">
							<input type="text" name="consommationGazMax" #consommationGazMax="ngModel" class="form-control" [(ngModel)]="performance.consommationGazMax" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">L/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationGazMax === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationElectriqueMin</label>
					<div class="half" *ngIf="performance?.consommationElectriqueMin !== null">
						<div class="input-group">
							<input type="text" name="consommationElectriqueMin" #consommationElectriqueMin="ngModel" class="form-control" [(ngModel)]="performance.consommationElectriqueMin" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">KWh/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationElectriqueMin === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.consommationElectriqueMax</label>
					<div class="half" *ngIf="performance?.consommationElectriqueMax !== null">
						<div class="input-group">
							<input type="text" name="consommationElectriqueMax" #consommationElectriqueMax="ngModel" class="form-control" [(ngModel)]="performance.consommationElectriqueMax" nNumber="2"/>
							<div class="input-group-addon no-border extend-2">KWh/100km</div>
						</div>
					</div>
					<div *ngIf="performance.consommationElectriqueMax === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.autonomieElectrique</label>
					<div class="half" *ngIf="performance?.autonomieElectrique !== null">
						<div class="input-group">
							<input type="text" name="autonomieElectrique" #autonomieElectrique="ngModel" class="form-control" [(ngModel)]="performance.autonomieElectrique" nNumber/>
							<div class="input-group-addon no-border">km</div>
						</div>
					</div>
					<div *ngIf="performance.autonomieElectrique === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
				<div class="form-group">
					<label translate>vehicule.modele.performance.autonomieElectriqueUrbain</label>
					<div class="half" *ngIf="performance?.autonomieElectriqueUrbain !== null">
						<div class="input-group">
							<input type="text" name="autonomieElectriqueUrbain" #autonomieElectriqueUrbain="ngModel" class="form-control" [(ngModel)]="performance.autonomieElectriqueUrbain" nNumber/>
							<div class="input-group-addon no-border">km</div>
						</div>
					</div>
					<div *ngIf="performance.autonomieElectriqueUrbain === null">
						<span translate>common.nonCommunique</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>