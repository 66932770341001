import { Component,Input } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ContratFinancementService } from './contrat/contrat-financement.service';

@Component({
	selector: 'vehicule-contrat-financement-edit',
	templateUrl: './vehicule-contrat-financement-edit.component.html'
})
export class VehiculeContratFinancementEditComponent extends PageContentItem {
	/** Elément courant **/
	@Input() contratFinancement: any;

	/** Suppression d'un contrat de financement **/
	@Input() deleteContratFinancement: (contratFinancement: any,close: Function) => void;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,private contratFinancementService: ContratFinancementService) {
		//Héritage
		super();

		//Binding des méthodes
		this.goToContratFinancement = this.goToContratFinancement.bind(this);
	}

	/**
	 * Redirection vers le contrat de financement
	 */
	goToContratFinancement() {
		//Redirection vers le contrat de financement
		this.layoutService.goToByState('listeVehiculeContratsFinancement-loadContratFinancement',{
			routeParams: {
				idContrat: this.contratFinancement.idContrat
			},
			withGoBack: true
		});
	}

	/**
	 * Calcul de la date de fin du contrat de financement
	 */
	getDateFin(): moment.Moment {
		//Date de début du contrat + Nombre de mois du dernier avenant
		return this.contratFinancementService.getDateFin(this.contratFinancement);
	}
}