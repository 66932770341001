import { AfterContentChecked,ChangeDetectorRef,Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'grille-attribution-selection-add-modele',
	templateUrl: './grille-attribution-selection-add-modele.component.html'
})
export class GrilleAttributionSelectionAddModeleComponent implements AfterContentChecked {
	/** Choix **/
	choice: 'RECUPERATION_GRILLE' | 'NOUVEAU_MODELE' = 'RECUPERATION_GRILLE';

	/** Résultat **/
	result: { choice: 'RECUPERATION_GRILLE' | 'NOUVEAU_MODELE' };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<GrilleAttributionSelectionAddModeleComponent>) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la sélection
	 */
	saveChoice() {
		//Définition du résultat
		this.result = {
			choice: this.choice
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la popup
	 */
	close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}