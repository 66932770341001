import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormCustomization } from 'src/app/domain/common/entite/form-customization';
import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class FormCustomizationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	  * Chargement de la fiche personnalisée
	  */
	public loadFormCustomization(className: string,idObject?: number): Observable<Result> {
		//Chargement de la fiche personnalisée
		return this.http.post<Result>(`${environment.baseUrl}/controller/EntiteCustomization/load/${className}${idObject ? '/' + idObject : ''}`,null);
	}

	/**
	  * Enregistrement d'une fiche personnalisée
	  */
	public saveFormCustomization(formCustomization: FormCustomization): Observable<Result> {
		//Enregistrement de la fiche personnalisée
		return this.http.post<Result>(`${environment.baseUrl}/controller/EntiteCustomization/save`,formCustomization);
	}
}
