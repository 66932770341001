<div class="container-fluid">
	<form #demandeVehiculeForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full" *ngIf="demandeVehicule">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation') || ['VALIDEE','LIVRE','ANNULEE'].indexOf(demandeVehicule.statut) > -1 || isValidationMode">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" *ngIf="demandeVehicule.idDemandeVehicule">
								<label translate>demandeVehicule.reference</label>
								<mat-chip-listbox>
									<mat-chip>
										<span>{{ demandeVehicule.idDemandeVehicule }}</span>
									</mat-chip>
								</mat-chip-listbox>
							</div>
							<div class="form-group" *ngIf="demandeVehicule.vehiculeSource">
								<label translate>demandeVehicule.vehiculeARenouveler</label>
								<mat-chip-listbox>
									<mat-chip [class.clickable]="rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')" (click)="goToVehicule()" (removed)="demandeVehicule.vehiculeSource = null">
										<span>{{ demandeVehicule.vehiculeSource | display:'vehicule' }}</span>
										<button matChipRemove *ngIf="(null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode">
											<mat-icon>cancel</mat-icon>
										</button>
									</mat-chip>
								</mat-chip-listbox>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': demandeVehicule.user == null && demandeVehicule.societe == null && (null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode }">
								<label translate>demandeVehicule.beneficiaire.item</label>
								<mat-chip-listbox>
									<mat-chip *ngIf="demandeVehicule.user == null && demandeVehicule.societe == null && (!(null | right:'creation') || ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) > -1 || isValidationMode)">
										<span translate>common.aucun</span>
									</mat-chip>
									<mat-chip *ngIf="demandeVehicule.user != null" (removed)="demandeVehicule.user = null">
										<span>{{ demandeVehicule.user | display:'user' }}</span>
										<button matChipRemove *ngIf="(null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode">
											<mat-icon>cancel</mat-icon>
										</button>
									</mat-chip>
									<mat-chip *ngIf="demandeVehicule.societe != null" [removable]="(null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode" (removed)="demandeVehicule.societe = null; demandeVehicule.service = null">
										<span *ngIf="demandeVehicule.service">{{ demandeVehicule.service | display:'service' }}</span>
										<span *ngIf="!demandeVehicule.service">{{ demandeVehicule.societe | display:'societe' }}</span>
										<button matChipRemove>
											<mat-icon>cancel</mat-icon>
										</button>
									</mat-chip>
								</mat-chip-listbox>
								<div>
									<a [routerLink]="[]" *ngIf="demandeVehicule.user == null && demandeVehicule.societe == null && (null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE','A_VALIDER'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode" (click)="showSelectionBeneficiaire()">
										<span translate>demandeVehicule.beneficiaire.aDefinir</span>
									</a>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeAffectationField.invalid }" *ngIf="demandeVehicule.typeBeneficiaire == 'COLLABORATEUR'">
								<label translate>demandeVehicule.type</label>
								<div>
									<selectpicker name="type" #typeAffectationField="ngModel" [(ngModel)]="demandeVehicule.typeAffectation" required>
										<mat-option *ngFor="let type of listeTypesAffectation" [value]="type.code">{{ type.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': montantAvantageNature.invalid }" *ngIf="demandeVehicule.typeBeneficiaire == 'COLLABORATEUR' && demandeVehicule.typeAffectation == 'VEHICULE_FONCTION'">
								<label translate>demandeVehicule.montantAvantageNature</label>
								<div class="input-group half">
									<input type="text" name="montantAvantageNature" class="form-control" #montantAvantageNature="ngModel" [(ngModel)]="demandeVehicule.montantAvantageNature" nNumber="2" min="0"/>
									<span class="input-group-addon no-border">{{ user.devise }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': montantParticipation.invalid }" *ngIf="demandeVehicule.typeBeneficiaire == 'COLLABORATEUR' && demandeVehicule.typeAffectation == 'VEHICULE_FONCTION'">
								<label translate>demandeVehicule.montantParticipation</label>
								<div class="input-group half">
									<input type="text" name="montantParticipation" class="form-control" #montantParticipation="ngModel" [(ngModel)]="demandeVehicule.montantParticipation" nNumber="2" min="0"/>
									<span class="input-group-addon no-border">{{ user.devise }}</span>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': societeImputation.invalid }">
								<label translate>demandeVehicule.societeImputation</label>
								<div>
									<autocomplete name="societeImputation" type="societe" #societeImputation="ngModel" [(ngModel)]="demandeVehicule.societeImputation" (ngModelChange)="demandeVehicule.serviceImputation = null" [required]="(null | right:'creation') && ['VALIDEE','LIVRE','ANNULEE'].indexOf(demandeVehicule.statut) == -1 && !isValidationMode"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': serviceImputation.invalid }">
								<label translate>demandeVehicule.serviceImputation</label>
								<div>
									<autocomplete name="serviceImputation" type="service" #serviceImputation="ngModel" [(ngModel)]="demandeVehicule.serviceImputation" [filter]="{ idSociete: demandeVehicule.societeImputation?.idService }" [disabled]="!demandeVehicule.societeImputation" [required]="!!demandeVehicule?.societeImputation?.extension?.nbSousServices"></autocomplete>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" *ngIf="demandeVehicule.statut">
								<label translate>demandeVehicule.statut.item</label>
								<mat-chip-listbox>
									<mat-chip>
										<span [translate]="'demandeVehicule.statut.'+demandeVehicule.statut"></span>
									</mat-chip>
								</mat-chip-listbox>
							</div>
							<div class="form-group" *ngIf="demandeVehicule.typeBeneficiaire == 'COLLABORATEUR'">
								<label translate>demandeVehicule.selectionModele.item</label>
								<div>
									<mat-checkbox name="selectionBeneficiaire" #selectionBeneficiaire="ngModel" [(ngModel)]="demandeVehicule.selectionBeneficiaire"><span translate>demandeVehicule.selectionModele.message</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label translate>demandeVehicule.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" rows="3" [(ngModel)]="demandeVehicule.remarque"></textarea>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="card-body card-padding m-t-20">
				<fieldset [disabled]="!(null | right:'creation') || demandeVehicule.statut == 'LIVRE' || demandeVehicule.statut == 'ANNULEE'">
					<div class="row">
						<div class="col-xs-12">
							<h4 translate>demandeVehicule.informationsCommande.title</h4>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label translate>demandeVehicule.dateLivraisonPrevue</label>
								<div class="half">
									<input type="text" class="form-control" name="dateLivraison" [(ngModel)]="demandeVehicule.dateLivraison" datePicker [storeDate]="true"/>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeVehicule.adresseLivraisonPrevue</label>
								<div>
									<adresse name="adresseLivraison" [(ngModel)]="demandeVehicule.adresseLivraison" [withCoords]="true"></adresse>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': distanceMensuelleEstimee.invalid }">
								<label translate>demandeVehicule.distanceMensuelleEstimee</label>
								<div class="input-group half">
									<input type="text" class="form-control" name="distanceMensuelleEstimee" #distanceMensuelleEstimee="ngModel" [(ngModel)]="demandeVehicule.distanceMensuelleEstimee" nNumber min="0"/>
									<span class="input-group-addon no-border" *ngIf="user.unite">{{ user.unite.libelleCourt }}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': dateCommande.invalid }">
								<label translate>demandeVehicule.dateCommande</label>
								<div class="half">
									<input type="text" class="form-control" name="dateCommande" #dateCommande="ngModel" [(ngModel)]="demandeVehicule.dateCommande" datePicker [storeDate]="true"/>
								</div>
							</div>
							<div class="form-group">
								<label translate>demandeVehicule.referenceCommande.long</label>
								<div>
									<input type="text" class="form-control" name="referenceCommande" [(ngModel)]="demandeVehicule.referenceCommande" maxlength="100"/>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<data-customization entite="com.notilus.data.vehicule.cotation.DemandeVehicule" [object]="demandeVehicule" [disabled]="!(null | right:'creation') || ['LIVRE','ANNULEE'].includes(demandeVehicule.statut)"></data-customization>
		</div>
		<div class="pull-right" *ngIf="demandeVehicule.statut != 'LIVRE' && demandeVehicule.statut != 'ANNULEE' && !isValidationMode">
			<button mat-stroked-button color="primary" (click)="deleteDemandeVehicule();" *ngIf="demandeVehicule.idDemandeVehicule && !isValidationMode && (null | right:'suppression') && isActionValid('SUPPRIMER',demandeVehicule)">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="demandeVehiculeForm.invalid || demandeVehicule.user == null && demandeVehicule.societe == null && (null | right:'creation') && demandeVehicule.statut != 'VALIDEE'" (click)="saveDemandeVehicule();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(demandeVehicule.idDemandeVehicule ? 'enregistrer' : 'continuer')"></span>
			</button>
		</div>
		<button mat-button *ngIf="demandeVehicule.idDemandeVehicule" [formDetectChanges]="{ doAction: close,isDirty: demandeVehiculeForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>