import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable,of } from 'rxjs';
import { filter,first,map,switchMap,tap } from 'rxjs/operators';

import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { RegleTaxeEditComponent } from './regle-taxe-edit.component';
import { RegleTaxePosteChargeEditComponent } from './regle-taxe-poste-charge-edit.component';
import { RegleTaxePosteChargeListComponent } from './regle-taxe-poste-charge-list.component';
import { RegleTaxeRuleListComponent } from './regle-taxe-rule-list.component';
import { RegleTaxeService } from './regle-taxe.service';

@Component({
	selector: 'regle-taxe',
	templateUrl: './regle-taxe.component.html'
})
export class RegleTaxeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Règle de taxe courante **/
	public regleTaxe: any;

	/** Résumé */
	private resume: { nbPostesCharge: number } = {
		nbPostesCharge: 0
	}

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/**
	 * Constructeur
	 */
	constructor(private regleTaxeService: RegleTaxeService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService) { }

	/** Récupération de la classe **/
	getClassName: () => string = () => 'com.notilus.data.fiscalite.RegleTaxe';

	/** Récupération de l'identifiant de la règle de taxe **/
	getIdObject: () => number = () => this.regleTaxe?.idRegle || 0;

	/** Récupération de l'entité portant les pièces jointes **/
	getOwningEntity: () => LinksOwningEntity = () => this.regleTaxe;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la page complexe
		this.options = {
			listeFields: [{
				libelle: 'fiscalite.regleTaxe.libelle',
				key: 'libelle'
			},{
				libelle: 'fiscalite.regleTaxe.pays',
				key: 'pays',
				type: 'DISPLAY',
				format: 'pays'
			},{
				libelle: 'fiscalite.regleTaxe.type.item',
				key: (data) => this.translateService.instant(`fiscalite.regleTaxe.type.${data.type}`),
			}],
			listeActions: [{
				libelle: 'actions.modifier',
				doAction: () => this.editRegleTaxe(),
				isVisible: () => this.rightService.hasRight(null,'creation'),
				type: TypeAction.EDITION
			},{
				libelle: 'actions.consulter',
				doAction: () => this.editRegleTaxe(),
				isVisible: () => !this.rightService.hasRight(null,'creation'),
				type: TypeAction.CONSULTATION
			},{
				libelle: 'actions.supprimer',
				doAction: () => this.deleteRegleTaxe(),
				isVisible: () => this.rightService.hasRight(null,'suppression')
			}],
			listeAlertes: [{
				icon: 'warning',
				title: this.translateService.instant('fiscalite.regleTaxe.alerte.rule.title'),
				message: this.translateService.instant('fiscalite.regleTaxe.alerte.rule.message'),
				isVisible: () => !this.regleTaxe?.listeDetails?.length && !this.pageContentService.isOpened(),
				doAction: () => this.addRule(),
				isActionVisible: () => this.rightService.hasRight(null,'creation')
			}],
			listeElements: [{
				type: 'RULE',
				libelle: 'fiscalite.regleTaxe.elements.rule',
				component: RegleTaxeRuleListComponent,
				retrieveComponentData: () => ({
					regleTaxe: this.regleTaxe,
					addRule: this.addRule.bind(this)
				}),
				doAction: () => this.addRule(),
				count: () => this.regleTaxe?.listeDetails?.length || 0,
				libelleAction: this.translateService.instant('fiscalite.regleTaxe.actions.definirRule'),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && this.rightService.isRoot()
			},{
				type: 'POSTE_CHARGE',
				libelle: 'fiscalite.regleTaxe.elements.posteCharge',
				component: RegleTaxePosteChargeListComponent,
				retrieveComponentData: () => ({
					regleTaxe: this.regleTaxe,
					resume: this.resume,
				}),
				doAction: () => this.addLienPosteCharge(),
				count: () => this.resume.nbPostesCharge,
				libelleAction: this.translateService.instant('fiscalite.regleTaxe.actions.ajouterPosteCharge'),
				isActionVisible: () => this.rightService.hasRight(null,'creation') && this.rightService.isRoot()
			}]
		};

		//Récupération de l'identifiant de la règle de taxe à charger
		this.activatedRoute.params.pipe(first()).subscribe({
			next: params => {
				//Chargement des données
				this.loadData(params.idRegle);
			}
		});
	}

	/**
	 * Edition de la règle de taxe
	 */
	private editRegleTaxe() {
		//Ouverture du composant d'édition
		this.pageContentService.open(RegleTaxeEditComponent,{
			data: {
				regleTaxe: cloneDeep(this.regleTaxe),
				deleteRegleTaxe: this.deleteRegleTaxe.bind(this),
				saveRegleTaxe: this.saveRegleTaxe.bind(this)
			}
		}).subscribe({
			next: (regleTaxe: any) => {
				//Vérification des données
				if (regleTaxe) {
					//Mise à jour de la règle de taxe
					this.regleTaxe = Object.assign(this.regleTaxe,regleTaxe);

					//Mise à jour de l'identifiant contenu dans l'URL
					this.layoutService.replaceUrlWith(this.regleTaxe);
				}
			}
		});
	}

	/**
	 * Suppression de la règle de taxe
	 */
	protected deleteRegleTaxe() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.regleTaxeService.deleteRegleTaxe(this.regleTaxe))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idRegle: number) {
		//Enregistrement de la règle de taxe
		this.regleTaxeService.loadRegleTaxe(idRegle).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition de la règle de taxe
					this.regleTaxe = result.data?.regleTaxe;

					//Définition du résumé
					this.resume = {
						nbPostesCharge: result.data?.nbPostesCharge || 0
					}

					//Vérification de l'identifiant de la règle de taxe
					if (!this.regleTaxe?.idRegle) {
						//Création d'un objet vierge
						this.regleTaxe = {};

						//Edition de la règle de taxe
						this.editRegleTaxe();
					}
				}
			}
		});
	}

	/**
	 * Vérification du changement de l'entité
	 */
	private checkTypeBeforeSave(regleTaxe: any): Observable<boolean> {
		//Vérification de la modification de l'entité
		if (this.regleTaxe.idRegle && this.regleTaxe.type != regleTaxe?.type) {
			//Affichage d'un message de confirmation
			return this.confirmService.showConfirm(this.translateService.instant('fiscalite.regleTaxe.confirmation.changementType'),{ actionColor: 'primary' }).pipe(
				map((isConfirmed: boolean) => {
					//Vérification de la confirmation
					if (isConfirmed) {
						//Suppression des règles
						regleTaxe.listeDetails = null;

						//Retour de la vérification
						return true;
					} else
						//Réinitialisation du type
						regleTaxe.entity = this.regleTaxe.type;

					//Retour de la vérification
					return false;
				}))
		} else
			//Non modification du type de règle
			return of(false);
	}

	/**
	 * Enregistrement de l'objet
	 */
	protected saveRegleTaxe(regleTaxe: any,close: Function) {
		let forceRefresh: boolean;

		//Vérification d'un changement de règle
		this.checkTypeBeforeSave(regleTaxe).pipe(
			tap((isRefresh: boolean) => forceRefresh = isRefresh),
			switchMap(() => this.regleTaxeService.saveRegleTaxe(regleTaxe))
		).subscribe({
			next: (result: Result) => {
				let listeDoublons: Array<any> = [];

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					regleTaxe = Object.assign(regleTaxe,result.data?.regleTaxe);

					//Fermeture du formulaire
					close?.(regleTaxe);

					//Rafraichissement de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RULE',forceRefresh);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification du libellé
					if (result.data.doublon & Math.pow(2,0)) {
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.libelle'));

						//Ajout du pays
						listeDoublons.push(this.translateService.instant('actions.doublon.pays'));
					}

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Modification de la règle
	 */
	private addRule(regleTaxe: any = this.regleTaxe) {
		//Ouverture du composant d'édition
		this.pageContentService.open(RegleTaxeRuleListComponent,{
			data: {
				regleTaxe: cloneDeep(regleTaxe),
				saveRegleTaxe: this.saveRegleTaxe.bind(this),
				isEdition: true
			}
		},'sub').subscribe({
			next: (data: { listeDetails: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RULE',!isEqual(regleTaxe.listeDetails,data.listeDetails));

					//Mise à jour de la règle de taxe
					regleTaxe = Object.assign(regleTaxe,data);
				}
			}
		});
	}

	/**
	 * Ajout d'un lien avec les postes de charges
	 */
	private addLienPosteCharge(lien: any = { regleTaxe: Object.assign({},this.regleTaxe) }) {
		//Ouverture du composant d'édition
		this.pageContentService.open(RegleTaxePosteChargeEditComponent,{
			data: {
				lien: cloneDeep(lien)
			}
		},'sub').subscribe({
			next: (data: { lien: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('POSTE_CHARGE',lien.idLien != data.lien?.idLien);

					//Vérification de la présence d'une création ou d'une suppression
					if (!lien.idLien && data.lien?.idLien)
						//Incrémentation du compteur
						this.resume.nbPostesCharge++;
					else if (lien.idLien && !data.lien?.idLien)
						//Décrémentation du compteur
						this.resume.nbPostesCharge--;

					//Mise à jour du lien
					lien = Object.assign(lien,data.lien);
				}
			}
		});
	}
}