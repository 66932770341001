import { AfterContentChecked,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { CoursService } from './cours.service';

@Component({
	selector: 'cours',
	templateUrl: './cours.component.html'
})
export class CoursComponent implements OnInit,AfterContentChecked {
	/** Elément courant **/
	@Input() cours: any;

	/** Gestion d'une modification de cours **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Elément sauvegardé **/
	public savedCours: any = null;

	/** Liste des sources **/
	public listeSources: Array<string> = ['BCE','FED','BDF'];

	/** Mode édition **/
	public isEdition: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private coursService: CoursService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService,private changeDetectorRef: ChangeDetectorRef) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sauvegarde du cours
		this.cours = Object.assign({},this.cours);
		this.savedCours = Object.assign({},this.cours);

		//Définition du mode édition
		this.isEdition = this.cours.idCours > 0;
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement du cours
	 */
	saveCours() {
		//Enregistrement du cours
		this.coursService.saveCours(this.cours).subscribe({
			next: result => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour du cours
					this.onObjectChange.emit(result.data.cours);
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout de la source
					listeDoublons.push(this.translateService.instant('devise.cours.source.libelle'));

					//Ajout de la devise
					listeDoublons.push(this.translateService.instant('devise.cours.devise'));

					//Ajout de la devise de contrepartie
					listeDoublons.push(this.translateService.instant('devise.cours.deviseContrepartie'));

					//Ajout de la date de change
					listeDoublons.push(this.translateService.instant('devise.cours.dateChange'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}

	/**
	 * Suppression du cours
	 */
	deleteCours() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression du cours
					this.coursService.deleteCours(this.cours).subscribe({
						next: result => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression du cours
								this.onObjectChange.emit(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}