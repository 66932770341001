import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ContratFinancementService } from './contrat-financement.service';

@Component({
	selector: 'contrat-financement-content',
	templateUrl: './contrat-financement-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ContratFinancementContentComponent implements OnInit {
	/** Contrat de financement **/
	@Input() contratFinancement: any;

	/** Récupération de la date de fin du contrat **/
	@Input() getDateFin: Function;

	/** Source **/
	@Input() source: 'CONTRAT_FINANCEMENT' | 'VEHICULE';

	/** Fermeture du formulaire **/
	@Output() close = new EventEmitter<any>();

	/** Ancien type de contrat **/
	oldContratType: string = null;

	/** Date de début maximale **/
	dateDebutMax: moment.Moment = null;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/**
	 * Constructeur
	 */
	constructor(public vehiculeService: VehiculeService,private contratFinancementService: ContratFinancementService,private toastrService: ToastrService,private translateService: TranslateService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let listeLoisRoulage: Array<any>;

		//Récupération de l'ancien mode de financement
		this.oldContratType = this.contratFinancement?.idContrat && this.contratFinancement.type.mode || null;

		//Liste ordonnée des lois de roulage du contrat
		listeLoisRoulage = sortBy(this.contratFinancement.listeLoisRoulage || [],['date']);

		//Date maximale de début du contrat (basée sur la date du premier avenant)
		this.dateDebutMax = listeLoisRoulage.length > 1 ? moment(listeLoisRoulage[1].date).subtract(1,'months').endOf('month') : null;
	}

	/**
	 * Enregistrement du contrat de financement
	 */
	saveContratFinancement() {
		//Vérification du type de contrat
		if (this.contratFinancement.type.mode == 'ACQUISITION')
			//Définition de la date de début comme la date d'achat
			this.contratFinancement.dateDebut = this.contratFinancement.dateAchat;

		//Calcul et définition de la date de fin
		this.contratFinancement.dateFin = this.getDateFin(this.contratFinancement.dateDebut);

		//Enregistrement du contrat de financement
		this.contratFinancementService.saveContratFinancement(this.contratFinancement).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Définition du contrat de financement
					this.contratFinancement = result.data.contrat;

					//Fermeture de l'édition
					this.close.emit({
						contratFinancement: this.contratFinancement,
						listeEcheances: result.data.listeEcheances
					});
				} else if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Vérification de la référence
					if (result.data.doublon & Math.pow(2,0))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.reference'));

					//Vérification du fournisseur
					if (result.data.doublon & Math.pow(2,1))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));

					//Vérification du véhicule
					if (result.data.doublon & Math.pow(2,2))
						//Ajout du libellé
						listeDoublons.push(this.translateService.instant('actions.doublon.vehicule'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		});
	}
}