<form #destinataireForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-header">
			<h2 class="card-title" translate>templateMail.destinataire.titre</h2>
		</div>
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || isReadOnly">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': typeSourceField.invalid }">
							<label translate>templateMail.destinataire.source.item</label>
							<div class="half">
								<selectpicker name="typeSource" #typeSourceField="ngModel" [(ngModel)]="typeSource" (ngModelChange)="destinataire.destinataireDynamique = null; destinataire.emailStatique = null; destinataire.userStatique = null;" required>
									<mat-option *ngFor="let typeSource of listeTypesSourceDestinataire" [value]="typeSource.code">{{ typeSource.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" *ngIf="typeSource == 'DYNAMIQUE'" [ngClass]="{ 'has-error': !destinataire.destinataireDynamique }">
							<label translate>templateMail.destinataire.destinataireDynamique.item</label>
							<div>
								<a [routerLink]="[]" *ngIf="!destinataire.destinataireDynamique" (click)="rightService.hasRight(null,'creation') && !isReadOnly && showCatalogDestinataires()" translate>templateMail.destinataire.destinataireDynamique.vide</a>
								<a [routerLink]="[]" *ngIf="destinataire.destinataireDynamique" (click)="rightService.hasRight(null,'creation') && !isReadOnly && showCatalogDestinataires()"><span>{{ destinataire.libelleOrigine }}</span></a>
							</div>
						</div>
						<div class="form-group" *ngIf="typeSource == 'EMAIL'" [ngClass]="{ 'has-error': email.invalid }">
							<label translate>templateMail.destinataire.email</label>
							<div>
								<input type="email" class="form-control" name="email" #email="ngModel" email="true" [ngModel]="destinataire.emailStatique" (ngModelChange)="onEmailChange($event)" required/>
							</div>
						</div>
						<div class="form-group" *ngIf="typeSource == 'COLLABORATEUR'" [ngClass]="{ 'has-error': userStatique.invalid }">
							<label translate>templateMail.destinataire.user</label>
							<div>
								<autocomplete name="userStatique" type="user" #userStatique="ngModel" [(ngModel)]="destinataire.userStatique" (ngModelChange)="onUserStatiqueChange($event)" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
							<label translate>templateMail.destinataire.type.item</label>
							<div class="half">
								<selectpicker name="type" #type="ngModel" [(ngModel)]="destinataire.type" required>
									<mat-option *ngFor="let type of listeTypesDestinataire" [value]="type.code">{{ type.libelleLong }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>templateMail.destinataire.libelle</label>
							<div>
								<input type="text" class="form-control" name="libelle" #libelle="ngModel" [(ngModel)]="destinataire.libelle" required maxlength="300"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" *ngIf="(null | right:'suppression') && destinataire.idDestinataire > 0" (click)="deleteDestinataire();">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDestinataire();" [disabled]="destinataireForm.invalid || typeSource == 'DYNAMIQUE' && !destinataire.destinataireDynamique" *ngIf="null | right:'creation'">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: destinataireForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>