<div class="pull-left lv-avatar" [translate]="'vehicule.releveCompteur.liste.avatar.'+(data?.typeSourceReleve == 'COLLABORATEUR' ? 'collaborateur' : 'fournisseur')"></div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="addReleveCompteur()">
			<span *ngIf="data?.typeSourceReleve == 'COLLABORATEUR' && data.userSource">{{ data.userSource | display:'user' }}</span>
			<span *ngIf="data?.typeSourceReleve == 'FOURNISSEUR' && data.fournisseur">{{ data.fournisseur | display:'fournisseur' }}</span>
		</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>vehicule.releveCompteur.date</span>&#160;:&#160;{{ data.dateReleve | date:'shortDate' }}</li>
		<li><span translate>vehicule.releveCompteur.releveCompteur</span>&#160;:&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.releveCompteur | number:'.2-2') : (data.releveHoraire | number) }}&#160;{{ data.vehicule.uniteUsage == 'DISTANCE' ? (data.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt || '') : ('duree.heure.libelleCourt' | translate) }}</li>
		<li><span translate>vehicule.releveCompteur.valeurReference.item</span>&#160;:&#160;<span [translate]="'common.' + (data.valeurReference ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.equipement"><span translate>vehicule.releveCompteur.equipement</span>&#160;:&#160;<span>{{ data.equipement | display:'equipement' }}</span></li>
		<li *ngIf="data?.extension?.userAffecte"><span translate>vehicule.releveCompteur.userAffecte</span>&#160;:&#160;<span>{{ data.extension.userAffecte | display:'user' }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="(TypeDroit.VEHICULE_COMPTEUR | right:'suppression') && !data.readOnly || (TypeDroit.FACTURE | right:'consultation') && data.typeOrigine == 'FACTURE' || (TypeDroit.ETAT_LIEUX | right:'consultation') && data.typeOrigine == 'ETAT_LIEUX'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(TypeDroit.VEHICULE_COMPTEUR | right:'suppression') && !data.readOnly"><a [routerLink]="[]" (click)="deleteReleveCompteur();"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(TypeDroit.FACTURE | right:'consultation') && data.typeOrigine == 'FACTURE'"><a [routerLink]="[]" (click)="goToFacture();"><span translate>vehicule.releveCompteur.actions.accederFacture</span></a></li>
			<li *ngIf="(TypeDroit.ETAT_LIEUX | right:'consultation') && data.typeOrigine == 'ETAT_LIEUX'"><a [routerLink]="[]" (click)="goToEtatLieux();"><span translate>vehicule.releveCompteur.actions.accederEtatLieux</span></a></li>
		</ul>
	</div>
</div>