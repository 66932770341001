<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idDatabase">
		<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data}">{{ data?.type == 'MULTI_TENANT' ? 'M' : 'S' }}</div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed">{{ data?.libelle + (data?.schema ? ' (' + data?.schema + ')' : '') }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idDatabase">
		<li *ngIf="data.type"><span translate>database.type.item</span>&#160;:&#160;<span [translate]="'database.type.' + data.type"></span></li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data?.actif ? 'oui' : 'non')"></span></li>
		<li *ngIf="data.listeRevendeurs?.length"><span translate>database.revendeur.item</span>&#160;:&#160;<span>{{ getListeRevendeursAsString() }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idDatabase && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="null | right:'suppression'"><a [routerLink]="[]" (click)="deleteDatabase();"><span translate>actions.supprimer</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<database [database]="data" (onObjectChange)="onObjectChange($event)"></database>
</div>