<div class="p-t-10">
	<div *ngIf="(nbAttachments$ | async) > 0" class="attachment-inline m-b-20">
		<div class="description alert clickable" (click)="showSelectionAttachments();" matRipple>
			<icon name="download"></icon>
			<div class="pull-left p-l-10" style="width: 90%;">
				<div><strong translate>attachment.mail.recuperation.title</strong></div>
				<div>
					<div translate>attachment.mail.recuperation.description</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
	<attachment #attachment="attachment" [options]="attachmentOptions"></attachment>
</div>