import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { FactureService } from './facture.service';

@Component({
	selector: 'facture-edit',
	templateUrl: './facture-edit.component.html'
})
export class FactureEditComponent extends PageContentItem implements OnInit {
	/** Facture **/
	@Input() facture: any;

	/** Suppression de l'objet **/
	@Input() deleteFacture: () => void;

	/** Liste des types de factures **/
	public listeTypes: Array<{ libelle: string,code: string }>;

	/** Date courante **/
	public dateCourante: Date;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,private translateService: TranslateService,private factureService: FactureService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des types de facture
		this.listeTypes = this.factureService.getListeTypesFacture();

		//Date courante
		this.dateCourante = moment().endOf('day').toDate();
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveFacture() {
		//Enregistrement de l'objet
		this.factureService.saveFacture(this.facture).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.facture,result.data?.facture);

					//Fermeture du formulaire
					this.close(this.facture);
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du libellé
						if (result.data.doublon & Math.pow(2,0)) {
							//Ajout des champs
							listeDoublons.push(this.translateService.instant('actions.doublon.reference'));
							listeDoublons.push(this.translateService.instant('actions.doublon.fournisseur'));
							listeDoublons.push(this.translateService.instant('actions.doublon.type'));
						}

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			}
		});
	}
}