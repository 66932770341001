'use strict';

var inherits = require('inherits'),
  iframeUtils = require('../../utils/iframe'),
  urlUtils = require('../../utils/url'),
  EventEmitter = require('events').EventEmitter,
  random = require('../../utils/random');
var debug = function () {};
if (process.env.NODE_ENV !== 'production') {
  debug = require('debug')('sockjs-client:receiver:htmlfile');
}
function HtmlfileReceiver(url) {
  debug(url);
  EventEmitter.call(this);
  var self = this;
  iframeUtils.polluteGlobalNamespace();
  this.id = 'a' + random.string(6);
  url = urlUtils.addQuery(url, 'c=' + decodeURIComponent(iframeUtils.WPrefix + '.' + this.id));
  debug('using htmlfile', HtmlfileReceiver.htmlfileEnabled);
  var constructFunc = HtmlfileReceiver.htmlfileEnabled ? iframeUtils.createHtmlfile : iframeUtils.createIframe;
  global[iframeUtils.WPrefix][this.id] = {
    start: function () {
      debug('start');
      self.iframeObj.loaded();
    },
    message: function (data) {
      debug('message', data);
      self.emit('message', data);
    },
    stop: function () {
      debug('stop');
      self._cleanup();
      self._close('network');
    }
  };
  this.iframeObj = constructFunc(url, function () {
    debug('callback');
    self._cleanup();
    self._close('permanent');
  });
}
inherits(HtmlfileReceiver, EventEmitter);
HtmlfileReceiver.prototype.abort = function () {
  debug('abort');
  this._cleanup();
  this._close('user');
};
HtmlfileReceiver.prototype._cleanup = function () {
  debug('_cleanup');
  if (this.iframeObj) {
    this.iframeObj.cleanup();
    this.iframeObj = null;
  }
  delete global[iframeUtils.WPrefix][this.id];
};
HtmlfileReceiver.prototype._close = function (reason) {
  debug('_close', reason);
  this.emit('close', null, reason);
  this.removeAllListeners();
};
HtmlfileReceiver.htmlfileEnabled = false;

// obfuscate to avoid firewalls
var axo = ['Active'].concat('Object').join('X');
if (axo in global) {
  try {
    HtmlfileReceiver.htmlfileEnabled = !!new global[axo]('htmlfile');
  } catch (x) {
    // intentionally empty
  }
}
HtmlfileReceiver.enabled = HtmlfileReceiver.htmlfileEnabled || iframeUtils.iframeEnabled;
module.exports = HtmlfileReceiver;