import { Component,Input,OnInit } from '@angular/core';
import { ControlContainer,NgForm } from '@angular/forms';

import { RuleService } from './rule.service';

@Component({
	selector: 'rule',
	templateUrl: './rule.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class RuleComponent implements OnInit {
	/** Mode 'Light' **/
	@Input() isModeLight?: boolean;

	/** Mode 'Edition' **/
	@Input() isEdition?: boolean;

	/** Règle **/
	@Input() rule?: any;

	/** Entité correspondant à la règle **/
	@Input() entite: string;

	/**
	 * Constructeur
	 */
	constructor(private ruleService: RuleService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la règle
		this.rule = Object.assign(this.rule || {},{
			_type: 'com.notilus.data.rule.Rule',
			entite: this.entite,
			operateur: this.rule?.operateur || 'AND'
		});

		//Typage des champs de saisie
		this.ruleService.assignFieldType(this.rule);
	}
}