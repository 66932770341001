import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { OptionCategorieService } from 'src/app/components/vehicule/modele/option-categorie/option-categorie.service';
import { AppState } from 'src/app/domain/appstate';
import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { User } from 'src/app/domain/user/user';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { OptionCategorieListItemComponent } from './option-categorie-list-item.component';

@Component({
	templateUrl: './option-categorie-list.component.html'
})
export class OptionCategorieListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,OptionCategorieListItemComponent>;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private optionCategorieService: OptionCategorieService,private store: Store<AppState>) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session?.user).pipe(first()).subscribe({
			next: user => this.user = user
		});

		//Définition de la liste
		this.liste = new ListView<any,OptionCategorieListItemComponent>({
			uri: `/controller/VehiculeReferentiel/filtreOptionsCategories`,
			title: this.translateService.instant('vehicule.optionCategorie.liste.title'),
			component: OptionCategorieListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'type',
				type: TypeFilter.LONG,
				listeValues: this.optionCategorieService.getListeTypesOption()
			}],
			getKeyFieldName: () => 'idCategorie',
			hasMainAction: () => this.rightService.hasRight(null,'creation'),
			doMainAction: () => {
				//Ajout d'un nouvel élément
				!this.liste.data?.content?.some(p => !p.idCategorie) && this.liste.addItem({
					isDisplayed: true,
					pays: this.user.pays
				});
			},
		});
	}
}