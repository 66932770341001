<form #modeleDetailsForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>vehicule.modele.details.title</h4>
	</div>
	<div class="modal-body" *ngIf="isInit">
		<fieldset [disabled]="true">
			<div class="card focus">
				<div class="card-body card-padding">
					<modele-content [modele]="modele" [options]="{ light: true }"></modele-content>
				</div>
			</div>
			<modele-information [modele]="modele"></modele-information>
			<modele-categorisation [modele]="modele"></modele-categorisation>
			<modele-motorisation [information]="modele?.information"></modele-motorisation>
			<modele-confort [confort]="modele?.information?.confort"></modele-confort>
			<modele-commercialisation-prix [commercialisationPrix]="modele?.information?.commercialisationPrix"></modele-commercialisation-prix>
			<modele-performance [performance]="modele?.information?.performance"></modele-performance>
			<modele-dimension [dimension]="modele?.information?.dimension"></modele-dimension>
			<modele-pneumatique [pneumatique]="modele?.information?.pneumatique"></modele-pneumatique>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.annuler</span>
		</button>
		<button mat-stroked-button color="primary" *ngIf="(null | right:'creation') && options?.canConfigure" (click)="doAction('CONFIGURE_MODELE')">
			<span translate>actions.configurer</span>
		</button>
		<button mat-raised-button color="primary" *ngIf="(null | right:'creation') && (options?.canChange || options?.canSelect)" (click)="doAction('CHANGE_MODELE')">
			<span [translate]="'vehicule.modele.details.actions.'+(options?.canChange ? 'modifierModele' : 'selectionnerModele')"></span>
		</button>
	</div>
</form>