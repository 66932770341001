<div class="modal-header">
	<h4 class="modal-title">
		<span *ngIf="options?.isWithEngin || options?.isWithDemandeVehicule" translate>vehicule.immatriculation.selection.title.avecEngin</span>
		<span *ngIf="!options?.isWithEngin && !options?.isWithDemandeVehicule" translate>vehicule.immatriculation.selection.title.sansEngin</span>
	</h4>
</div>
<div class="modal-body">
	<div class="alert alert-info" *ngIf="typeErreur">
		<icon name="warning"></icon>
		<ng-container *ngIf="typeErreur == 'WS_INCORRECT_PLATE'">
			<div>
				<strong translate [translateParams]="{ immatriculation: searchedImmatriculation }">vehicule.immatriculation.selection.erreur.incorrectPlate.message</strong>
				<div translate>vehicule.immatriculation.selection.erreur.incorrectPlate.description</div>
			</div>
		</ng-container>
		<ng-container *ngIf="typeErreur == 'WS_PLATE_NOT_FOUND'">
			<div>
				<strong translate [translateParams]="{ immatriculation: searchedImmatriculation }">vehicule.immatriculation.selection.erreur.plateNotFound.message</strong>
				<div translate>vehicule.immatriculation.selection.erreur.plateNotFound.description</div>
			</div>
			<button mat-raised-button color="primary" (click)="onAlerteClick()">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngIf="typeErreur == 'WS_CALL_ERROR'">
			<div>
				<strong translate>vehicule.immatriculation.selection.erreur.webServiceUnavailable.message</strong>
				<div translate>vehicule.immatriculation.selection.erreur.webServiceUnavailable.description</div>
			</div>
			<button mat-raised-button color="primary" (click)="onAlerteClick()">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngIf="typeErreur == 'DOUBLON' && creationVehicule.type != 'ENGIN'">
			<div>
				<strong translate [translateParams]="{ immatriculation: searchedImmatriculation }">vehicule.immatriculation.selection.erreur.doublon.vehicule.message</strong>
				<div translate>vehicule.immatriculation.selection.erreur.doublon.vehicule.description</div>
			</div>
			<button mat-raised-button color="primary" (click)="onAlerteClick()">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngIf="typeErreur == 'DOUBLON' && creationVehicule.type == 'ENGIN'">
			<strong translate [translateParams]="{ numero: vehicule.reference,marque: vehicule.marque.libelle }">vehicule.immatriculation.selection.erreur.doublon.engin.message</strong>
			<div>
				<div *ngIf="!options?.useExistingImmatriculation || existingVehicule" translate>vehicule.immatriculation.selection.erreur.doublon.engin.description</div>
				<div *ngIf="options?.useExistingImmatriculation && !existingVehicule" translate>vehicule.immatriculation.selection.erreur.doublon.engin.descriptionWithImmatriculation</div>
			</div>
			<button mat-raised-button color="primary" (click)="onAlerteClick()">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
	</div>

	<form #vehiculeImmatriculationSelectionForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<fieldset [disabled]="isLoading">
			<mat-radio-group #radioGroup="matRadioGroup" name="choice" [(ngModel)]="creationVehicule.type" (ngModelChange)="resetVehicule();">
				<mat-radio-button *ngIf="options?.isWithEngin || options?.isWithDemandeVehicule" value="VEHICULE">
					<span translate>vehicule.immatriculation.selection.creation.vehicule</span>
				</mat-radio-button>
				<div *ngIf="creationVehicule.type == 'VEHICULE'">
					<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
						<label translate>vehicule.immatriculation.selection.pays</label>
						<div>
							<autocomplete type="pays" name="pays" [filter]="{ typeScope: 'IMMATRICULATION_VEHICULE' }" #pays="ngModel" [(ngModel)]="vehicule.pays" required></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': immatriculationField.invalid }">
						<label translate>vehicule.immatriculation.selection.immatriculation.item</label>
						<div class="input-group">
							<input type="text" name="immatriculationField" class="form-control" #immatriculationField="ngModel" [(ngModel)]="immatriculation" (ngModelChange)="immatriculation = $event.toUpperCase()" pattern="[A-Z0-9]*" [fullPattern]="getImmatriculationPatternForPays()" [disabled]="!vehicule.pays" required/>
							<div class="input-group-addon">
								<icon name="info_outline" [tooltip]="'vehicule.immatriculation.selection.immatriculation.info' | translate"></icon>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': dateEntree.invalid }" *ngIf="options?.isDateEntreeVisible">
						<label translate>vehicule.immatriculation.selection.dateEntree</label>
						<div>
							<input type="text" name="dateEntree" class="form-control" #dateEntree="ngModel" [(ngModel)]="vehicule.dateEntree" datePicker format="datetime" required/>
						</div>
					</div>
					<div *ngIf="options?.isImputationVisible">
						<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
							<label translate>vehicule.societeImputation</label>
							<div>
								<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="vehicule.societe" (ngModelChange)="vehicule.service = null"></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
							<label translate>vehicule.serviceImputation</label>
							<div>
								<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="vehicule.service" [filter]="{ idSociete: vehicule?.societe?.idService }" [disabled]="!vehicule.societe || vehicule?.societe?.extension?.nbSousServices == 0" [required]="vehicule?.societe?.extension?.nbSousServices > 0"></autocomplete>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="options?.isWithDemandeVehicule">
					<div class="separator">
						<span translate>common.ou</span>
					</div>
					<mat-radio-button value="DEMANDE_VEHICULE">
						<span translate>vehicule.immatriculation.selection.creation.demandeVehicule</span>
					</mat-radio-button>
					<div *ngIf="creationVehicule.type == 'DEMANDE_VEHICULE'">
						<div class="form-group" [ngClass]="{'has-error': vehiculeImmatriculation.invalid }">
							<label translate>vehicule.immatriculation.selection.immatriculation.item</label>
							<div>
								<input type="text" [hidden]="true" name="vehiculeImmatriculationField" #vehiculeImmatriculation="ngModel" [(ngModel)]="vehicule.immatriculation" required/>
								<a [routerLink]="[]" *ngIf="!vehicule.immatriculation" (click)="showSelectImmatriculation()" translate>common.aRenseigner</a>
								<a [routerLink]="[]" *ngIf="vehicule.immatriculation" (click)="showImmatriculation()">{{ vehicule.reference }}</a>
							</div>
						</div>
						<div *ngIf="options?.isImputationVisible">
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
								<label translate>vehicule.societeImputation</label>
								<div>
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="vehicule.societe" (ngModelChange)="vehicule.service = null"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
								<label translate>vehicule.serviceImputation</label>
								<div>
									<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="vehicule.service" [filter]="{ idSociete: vehicule?.societe?.idService }" [disabled]="!vehicule.societe || vehicule?.societe?.extension?.nbSousServices == 0" [required]="vehicule?.societe?.extension?.nbSousServices > 0"></autocomplete>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="options?.isWithEngin">
					<div class="separator">
						<span translate>common.ou</span>
					</div>
					<mat-radio-button value="ENGIN">
						<span translate>vehicule.immatriculation.selection.creation.engin</span>
					</mat-radio-button>
					<div *ngIf="creationVehicule.type == 'ENGIN'">
						<div class="form-group" [ngClass]="{ 'has-error': pays.invalid }">
							<label translate>vehicule.immatriculation.selection.pays</label>
							<div>
								<autocomplete type="pays" name="pays" [filter]="{ typeScope: 'IMMATRICULATION_VEHICULE' }" #pays="ngModel" [(ngModel)]="vehicule.pays" (onSelect)="vehicule.marque = null;" required></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': numero.invalid }">
							<label translate>vehicule.immatriculation.selection.numero.item</label>
							<div class="input-group">
								<input type="text" name="numero" class="form-control" #numero="ngModel" [(ngModel)]="vehicule.reference" (ngModelChange)="vehicule.reference = $event.toUpperCase()" pattern="[A-Z0-9]*" required/>
								<div class="input-group-addon">
									<icon name="info_outline" [tooltip]="'vehicule.immatriculation.selection.numero.info' | translate"></icon>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': marque.invalid }">
							<label translate>vehicule.immatriculation.selection.marque</label>
							<div>
								<autocomplete type="marque" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION' }" name="marque" #marque="ngModel" [(ngModel)]="vehicule.marque" (onSelect)="vehicule.modele = null;" required></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>vehicule.immatriculation.selection.modele</label>
							<div>
								<autocomplete type="modele" [filter]="{ idMarque: vehicule.marque?.idMarque }" name="modele" #modele="ngModel" [(ngModel)]="vehicule.modele" [disabled]="!vehicule.marque"></autocomplete>
							</div>
						</div>
						<div class="form-group">
							<label translate>vehicule.immatriculation.selection.genre</label>
							<div>
								<autocomplete type="genre" [filter]="{ typeSource: 'EXTERNE_IMMATRICULATION',type: 'ENGIN' }" name="genre" #genre="ngModel" [(ngModel)]="vehicule.genre"></autocomplete>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': dateEntree.invalid }" *ngIf="options?.isDateEntreeVisible">
							<label translate>vehicule.immatriculation.selection.dateEntree</label>
							<div>
								<input type="text" name="dateEntree" class="form-control" #dateEntree="ngModel" [(ngModel)]="vehicule.dateEntree" datePicker format="datetime" required/>
							</div>
						</div>
						<ng-container *ngIf="options?.isImputationVisible">
							<div class="form-group" [ngClass]="{ 'has-error': societe.invalid }">
								<label translate>vehicule.societeImputation</label>
								<div>
									<autocomplete name="societe" type="societe" #societe="ngModel" [(ngModel)]="vehicule.societe" (ngModelChange)="vehicule.service = null"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': service.invalid }">
								<label translate>vehicule.serviceImputation</label>
								<div>
									<autocomplete name="service" type="service" #service="ngModel" [(ngModel)]="vehicule.service" [filter]="{ idSociete: vehicule?.societe?.idService }" [disabled]="!vehicule.societe || vehicule?.societe?.extension?.nbSousServices == 0" [required]="vehicule?.societe?.extension?.nbSousServices > 0"></autocomplete>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</mat-radio-group>
		</fieldset>
	</form>
</div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.fermer</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="vehiculeImmatriculationSelectionForm.invalid" (click)="doContinue()" [disabled]="isLoading">
		<span translate>actions.continuer</span>
	</button>
</div>