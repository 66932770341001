import { Component,Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './notification-add-destinataire.component.html'
})
export class NotificationAddDestinataireComponent {
	/** Type de destinataire **/
	@Input() type: string;

	/** Destinataire ajouté **/
	destinataire: any;

	/** Utilisateur sélectionné **/
	newUser: any;

	/** e-Mail saisie **/
	newEmail: string;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<NotificationAddDestinataireComponent>) {

	}

	/**
	 * Fermeture de la popup avec sélection du destinataire
	 */
	close() {
		//Définition du destinataire
		this.destinataire = this.newUser && {
			libelle: this.newUser.prenom + ' ' + this.newUser.nom,
			userStatique: this.newUser,
			type: this.type,
			selected: true
		} || this.newEmail && {
			libelle: this.newEmail,
			emailStatique: this.newEmail,
			type: this.type,
			selected: true
		} || null;

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}