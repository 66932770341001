
<form #structureExportFichierForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="m-t-20">
	<div class="card detail">
		<div class="card-body card-padding">
			<fieldset [disabled]="!(null | right:'creation') || isRestricted">
				<h4><span translate>structureExport.fichier.title</span></h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
							<label translate>structureExport.fichier.libelle</label>
							<div>
								<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="detail.libelle" required/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="card-body card-padding" *ngIf="detail">
			<fieldset [disabled]="!(null | right:'creation') || isRestricted">
				<h4><span translate>structureExport.fichier.info</span></h4>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label translate>structureExport.fichier.format</label>
							<div class="half">
								<selectpicker name="format" [(ngModel)]="detail.fichier.typeFormat" required>
									<mat-option *ngFor="let typeFormat of listeTypesFormat" [value]="typeFormat.code">{{ typeFormat.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': !detail.fichier?.name }">
							<label translate>structureExport.fichier.nom.item</label>
							<div>
								<a [routerLink]="[]" (click)="showName()">
									<span *ngIf="detail.fichier?.name">{{ detail.fichier.name }}</span>
									<span *ngIf="!detail.fichier?.name" translate>common.aRenseigner</span>
								</a>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': extension.invalid }">
							<label>
								<span translate>structureExport.fichier.extension.item</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'structureExport.fichier.extension.info' | translate"></icon>
							</label>
							<div class="half">
								<input type="text" name="extension" #extension="ngModel" class="form-control" [(ngModel)]="detail.fichier.extension" pattern="[^\.]*"required/>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': separateur.invalid }" *ngIf="detail.fichier.typeFormat == 'CSV'">
							<label translate>structureExport.fichier.separateur</label>
							<div class="half">
								<input type="text" name="separateur" #separateur="ngModel" class="form-control" [(ngModel)]="detail.fichier.separateur" max="1" required/>
							</div>
						</div>
						<div class="form-group" *ngIf="detail.fichier.typeFormat == 'CSV'" [ngClass]="{ 'has-error': typeEchappement.invalid }">
							<label translate>structureExport.fichier.echappement</label>
							<div class="half">
								<selectpicker name="typeEchappement" #typeEchappement="ngModel" [(ngModel)]="detail.fichier.typeEchappement" required>
									<mat-option *ngFor="let typeEchappement of listeTypesEchappement" [value]="typeEchappement.code">{{ typeEchappement.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label>
								<span translate>structureExport.fichier.structureCommune.item</span>
								<icon name="info_outline" class="m-l-5" [tooltip]="'structureExport.fichier.structureCommune.info' | translate"></icon>
							</label>
							<div>
								<mat-checkbox name="structureCommune" #structureCommune="ngModel" [(ngModel)]="detail.fichier.structureCommune" [disabled]="true"><span translate>structureExport.fichier.structureCommune.description</span></mat-checkbox>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': nbColonnes.invalid }">
							<div class="half">
								<label>
									<span translate>structureExport.fichier.nbColonnes.item</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="'structureExport.fichier.nbColonnes.info' | translate"></icon>
								</label>
								<div>
									<input type="text" name="nbColonnes" #nbColonnes="ngModel" class="form-control" [(ngModel)]="detail.fichier.nbColonnes" nNumber min="0" max="299" required/>
								</div>
							</div>
						</div>
						<div class="form-group" [ngClass]="{ 'has-error': typeEncodage.invalid }">
							<label translate>structureExport.fichier.encodage</label>
							<div class="half">
								<selectpicker name="typeEncodage" #typeEncodage="ngModel" [(ngModel)]="detail.fichier.typeEncodage" required>
									<mat-option *ngFor="let typeEncodage of listeTypesEncodage" [value]="typeEncodage.code">{{ typeEncodage.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	</div>

	<span class="pull-right" *ngIf="detail">
		<button mat-stroked-button color="primary" (click)="deleteDetail();" *ngIf="(null | right:'creation') && !isRestricted">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" (click)="saveDetail();" *ngIf="(null | right:'creation') && !isRestricted" [disabled]="structureExportFichierForm.invalid || !detail.fichier?.name">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: structureExportFichierForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>

